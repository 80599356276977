function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Notification from '@/components/Molecules/Notification';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewListTile from '@/components/Molecules/PreviewList/PreviewListTile';
import CatalogueHeader from '@/components/Molecules/CatalogueHeader/CatalogueHeader';
import ExpandedCard from '@/components/Molecules/Cards/ExpandedCard';
import { CommonAssetsMixin } from '@/mixins/CommonAssetsMixin';
import { RiskListMixin } from '@/components/Pages/Risks/RiskListMixin';
import { getDataControls } from '@/api/controls/controls';
import { getDailyTeamRisks as _getDailyTeamRisks } from '@/api/risk/risk';
import { getCurrentScoring } from '@/api/risk/scoring';
import { MODULES } from '@/constants/modules';
import { MY_RISKS_VIEWS } from '@/constants/ViewTypes';
import { viewTypes } from '@/constants/ListViewType';
import { checkERMByProjectId } from '@/utils/risks/check';
import { check403AccessError } from '@/utils/handleAPIErrors';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'MyRisks',
  introduction: 'Information risk management / My risks',
  description: 'Information risk management / My risks',
  token: '<my-risks />',
  components: {
    RismaTitle: RismaTitle,
    ExpandedCard: ExpandedCard,
    Notification: Notification,
    PreviewListTile: PreviewListTile,
    CatalogueHeader: CatalogueHeader,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  mixins: [CommonAssetsMixin, RiskListMixin],
  setup: function setup() {
    return {
      currentView: getLocalStorageComputed('my_risks_view_storage', viewTypes.EXPANDED)
    };
  },
  data: function data() {
    return {
      isLoaded: false,
      isLoadedError: false,
      activityTypes: [],
      risks: [],
      MODULES: MODULES,
      viewTypes: viewTypes,
      MY_RISKS_VIEWS: MY_RISKS_VIEWS,
      // ERM only
      scoring: {},
      scoringLoaded: false,
      dailyTeamRisks: [],
      sections: [],
      overviewOptionsState: false,
      isOverviewOptionsExpanded: false,
      translate: {
        thereWasAnErrorPleaseTryAgain: this.$trans('There was an error, please, try again'),
        myRisks: this.$trans('My risks'),
        dailyTeamRisks: this.$trans('Daily team risks')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    isERM: function isERM() {
      return checkERMByProjectId(this.projectId, this.currentUser);
    },
    viewClass: function viewClass() {
      if (this.currentView == 'expanded') {
        return 'preview-wide';
      }
      if (this.currentView == 'tiles') {
        return 'preview';
      }
      if (this.currentView == 'compact') {
        return 'preview-compact';
      }
      return '';
    },
    periodOrScoringTitle: function periodOrScoringTitle() {
      if (this.isERM) {
        return 'ERM';
      }
      return this.periodTitle;
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'risk');
    }
  }),
  watch: {
    $route: function $route(to, from) {
      var _this = this;
      if (to.name !== from.name) return;
      var period = this.riskProject.projectPeriods.find(function (period) {
        return period.isCurrent;
      });
      var periodId = period ? period.id : null;
      this.getRisks(this.projectId, periodId, 'filters[my]=1').then(function (risks) {
        _this.risks = _this.formatRisks(risks, _this.threats, _this.levelOfThreats, _this.vulnerabilities, _this.probabilities, _this.controls, _this.initiatives);
      });
      if (this.isERM) {
        this.getDailyTeamRisks().then(function (dailyTeamRisks) {
          _this.dailyTeamRisks = _this.formatRisks(dailyTeamRisks, _this.threats, _this.levelOfThreats, _this.vulnerabilities, _this.probabilities, _this.controls, _this.initiatives);
        });
      }
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    Promise.all([this.getInitiatives(), this.getRiskProjects(), this.getScoringForERM()]).then(function () {
      return Promise.all([_this2.getRisks(_this2.projectId, _this2.periodId, 'filters[my]=1'), _this2.isERM ? _this2.getDailyTeamRisks() : Promise.resolve([])]);
    }).then(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        risks = _ref2[0],
        dailyTeamRisks = _ref2[1];
      return new Promise(function (resolve) {
        getDataControls().then(function (data) {
          _this2.controls = data.list;
          _this2.risks = _this2.formatRisks(risks, _this2.threats, _this2.levelOfThreats, _this2.vulnerabilities, _this2.probabilities, _this2.controls, _this2.initiatives);
          _this2.risksBackup = _toConsumableArray(_this2.risks);
          _this2.dailyTeamRisks = _this2.formatRisks(dailyTeamRisks, _this2.threats, _this2.levelOfThreats, _this2.vulnerabilities, _this2.probabilities, _this2.controls, _this2.initiatives);
          resolve();
        });
      });
    }).then(function () {
      _this2.isLoaded = true;
    }).catch(function () {
      _this2.isLoadedError = true;
    });
  },
  methods: {
    getDailyTeamRisks: function getDailyTeamRisks() {
      return new Promise(function (resolve, reject) {
        _getDailyTeamRisks().then(function (_ref3) {
          var list = _ref3.list;
          return resolve(list);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    getScoringForERM: function getScoringForERM() {
      var _this3 = this;
      return getCurrentScoring().then(function (scoring) {
        _this3.scoring = scoring;
        _this3.scoringLoaded = true;
      }).catch(function (error) {
        return new Error(error);
      });
    },
    updateFromPreviewMode: function updateFromPreviewMode(updatedRisk) {
      var updatedRiskIndex = this.risks.findIndex(function (risk) {
        return +risk.id === +updatedRisk.id;
      });
      if (updatedRiskIndex !== -1) {
        this.risks[updatedRiskIndex] = _objectSpread(_objectSpread({}, this.risks[updatedRiskIndex]), updatedRisk);
      }
      var updatedDailyTeamRiskIndex = this.dailyTeamRisks.findIndex(function (risk) {
        return +risk.id === +updatedRisk.id;
      });
      if (updatedDailyTeamRiskIndex !== -1) {
        var formatedDailyTeamRisk = this.formatRisk(updatedRisk, this.threats, this.levelOfThreats, this.vulnerabilities, this.probabilities, this.controls, this.initiatives);
        this.dailyTeamRisks[updatedDailyTeamRiskIndex] = _objectSpread(_objectSpread({}, this.dailyTeamRisks[updatedRiskIndex]), formatedDailyTeamRisk);
      }
    },
    deleteRiskFromList: function deleteRiskFromList(risk) {
      var riskIndex = this.risks.findIndex(function (riskItem) {
        return riskItem.id === risk.id;
      });
      this.risks.splice(riskIndex, 1);
    }
  }
};