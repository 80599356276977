function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaSwitch from '@/components/Atoms/Inputs/RismaSwitch';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import { ControlTypeFields } from '@/constants/ControlTypeFields';
export default {
  name: 'ControlType',
  components: {
    RismaToggle: RismaToggle,
    RismaTitle: RismaTitle,
    RismaSwitch: RismaSwitch
  },
  props: {
    control: {
      type: Object,
      required: true,
      note: 'Main control data object.'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['changed'],
  data: function data() {
    return {
      transObject: {},
      keyControl: {},
      switchOptions: {},
      translate: getTranslate['ControlType']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isControlTypeShown: function isControlTypeShown() {
      return Object.values(this.showOptions).some(function (item) {
        return item;
      });
    },
    title: function title() {
      return this.translate.controlType;
    },
    showOptions: function showOptions() {
      var _ref;
      return _ref = {}, _defineProperty(_ref, ControlTypeFields.KEY_CONTROL, this.getSettingValue('risma.show_key_control') == undefined || this.getSettingValue('risma.show_key_control')), _defineProperty(_ref, ControlTypeFields.KEY_AUTOMATIC_MANUAL, this.getSettingValue('risma.show_automatic_manual') == undefined || this.getSettingValue('risma.show_automatic_manual')), _defineProperty(_ref, ControlTypeFields.KEY_PREVENTIVE_DETECTIVE, this.getSettingValue('risma.show_preventive_detective') == undefined || this.getSettingValue('risma.show_preventive_detective')), _defineProperty(_ref, ControlTypeFields.KEY_HIGH_LOW_COMPLEXITY, this.getSettingValue('risma.show_high_complexity_low_complexity') == undefined || this.getSettingValue('risma.show_high_complexity_low_complexity')), _ref;
    }
  }),
  mounted: function mounted() {
    this.initState();
  },
  methods: {
    initState: function initState() {
      this.keyControl = {
        label: this.translate.keyControl,
        key: ControlTypeFields.KEY_CONTROL,
        selected: this.isActive(ControlTypeFields.KEY_CONTROL)
      };
      this.switchOptions = {
        type: {
          selected: this.isActive(ControlTypeFields.KEY_AUTOMATIC_MANUAL),
          key: ControlTypeFields.KEY_AUTOMATIC_MANUAL,
          options: [{
            label: this.translate.automatic,
            value: true
          }, {
            label: this.translate.manual,
            value: false
          }]
        },
        measure: {
          selected: this.isActive(ControlTypeFields.KEY_PREVENTIVE_DETECTIVE),
          key: ControlTypeFields.KEY_PREVENTIVE_DETECTIVE,
          options: [{
            label: this.translate.preventive,
            value: true
          }, {
            label: this.translate.detective,
            value: false
          }]
        },
        complexity: {
          selected: this.isActive(ControlTypeFields.KEY_HIGH_LOW_COMPLEXITY),
          key: ControlTypeFields.KEY_HIGH_LOW_COMPLEXITY,
          options: [{
            label: this.translate.highComplexity,
            value: true
          }, {
            label: this.translate.lowComplexity,
            value: false
          }]
        }
      };
    },
    isActive: function isActive(key) {
      return this.control[key] === 1;
    },
    toggleType: function toggleType(item) {
      this.$emit('changed', this.handleChangedType({
        key: item.key,
        state: item.selected
      }));
    },
    handleChangedType: function handleChangedType(_ref2) {
      var key = _ref2.key,
        state = _ref2.state;
      return _defineProperty({}, key, +state);
    }
  }
};