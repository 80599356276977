export var AssetsTypes = {
  CONTROLLERS: 'controllers',
  PROCESSORS: 'processors',
  SYSTEMS: 'systems',
  INFORMATION_ASSETS: 'information_asset',
  INFORMATION_ASSETS_API: 'informationAssets',
  DATA_PROCESSOR_MANAGEMENT: 'dpm',
  CUSTOM: 'custom',
  LIA_NODES: 'lia_nodes',
  TIA_NODES: 'tia_nodes',
  TIA_PROC: 'tia_proc'
};