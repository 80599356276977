import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-ec8e1ce8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "ia-information-assets"
};
var _hoisted_2 = {
  class: "font-semibold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_delegation_well = _resolveComponent("delegation-well");
  var _component_governance_review_section = _resolveComponent("governance-review-section");
  var _component_information_assets_details_relations = _resolveComponent("information-assets-details-relations");
  var _component_dep_well = _resolveComponent("dep-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.companyStatusNotification ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(_ctx.beAwareThatThisCompanyHasStatus), 1 /* TEXT */), _createElementVNode("span", _hoisted_2, _toDisplayString($data.companyStatusNotification), 1 /* TEXT */)])];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_dep_well, {
    item: $options.preparedInformationAssetsItem,
    "dep-header-props": _ctx.depHeaderProps,
    "local-storage-key": "IACustom",
    "is-loaded": _ctx.loaded,
    onUpdatingAttachments: _ctx.updatingAttachments,
    onFileUpdate: _ctx.onFileUpdate,
    onPropertyChanged: _ctx.informationAssetPropertyChanged,
    onSaveStatusClicked: _ctx.saveStatusClicked,
    onGetActivityData: _ctx.getActivityData,
    onChangeTab: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changeTab', $event);
    }),
    onCompanyStatusChange: _cache[1] || (_cache[1] = function ($event) {
      return $data.companyStatusNotification = $event;
    }),
    "onUpdate:isPageWithCustomUpdateDirty": _ctx.handleUpdateForCustomPageUpdate
  }, {
    delegation: _withCtx(function () {
      return [_ctx.informationAsset ? (_openBlock(), _createBlock(_component_delegation_well, {
        key: 0,
        "model-value": _ctx.delegationData,
        organisations: _ctx.organisations,
        "current-activity-type": $options.currentActivityType,
        onPropertyChanged: _ctx.saveChangesImmediately
      }, null, 8 /* PROPS */, ["model-value", "organisations", "current-activity-type", "onPropertyChanged"])) : _createCommentVNode("v-if", true)];
    }),
    details: _withCtx(function () {
      return [_ctx.informationAsset ? (_openBlock(), _createBlock(_component_governance_review_section, {
        key: 0,
        class: "mb-4 pb-6 border-b-2 border-gray-250",
        review: _ctx.review,
        "project-name": $options.iaActivityTypeName,
        users: _ctx.usersWithIAAccess,
        organisations: _ctx.organisations,
        onUpdate: _ctx.changeReview
      }, null, 8 /* PROPS */, ["review", "project-name", "users", "organisations", "onUpdate"])) : _createCommentVNode("v-if", true), _ctx.informationAsset ? (_openBlock(), _createBlock(_component_information_assets_details_relations, {
        key: 1,
        "information-asset": _ctx.informationAsset,
        type: "custom",
        questionnaires: _ctx.questionnaires,
        onChanged: _ctx.informationAssetPropertyChanged
      }, null, 8 /* PROPS */, ["information-asset", "questionnaires", "onChanged"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["item", "dep-header-props", "is-loaded", "onUpdatingAttachments", "onFileUpdate", "onPropertyChanged", "onSaveStatusClicked", "onGetActivityData", "onUpdate:isPageWithCustomUpdateDirty"])]);
}