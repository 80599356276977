import { calculateUTCDifference } from '@/utils/date';

/**
 * Compares two dates using moment
 *
 * @param a
 * @param b
 * @returns {number}
 */
export function compareDates(a, b) {
  var reverse = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!a) a = 0;
  if (!b) b = 0;
  var result = calculateUTCDifference(a, b);
  if (result > 0) return reverse ? -1 : 1;
  if (result < 0) return reverse ? 1 : -1;
  return 0;
}

/**
 * Compares strings starting with an integer and containing some string afterwards. For instance "10.A, 10.B" etc.
 *
 * @param a
 * @param b
 * @returns {number}
 */
export function comparePathStrings(a, b) {
  if (parseInt(a) === parseInt(b)) {
    var x = a.toLowerCase(),
      y = b.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  }
  return parseInt(a) - parseInt(b);
}

/**
 * Compares two strings
 *
 * @param a {String}
 * @param b {String}
 * @param reverse {Boolean}
 * @param locales {String|String[]|undefined}
 * @returns {number} -1 -0 0 1 (-0 === 0)
 */
export function compareStrings(a, b) {
  var reverse = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var locales = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;
  a = getDefault(a);
  b = getDefault(b);
  var reverseMulti = reverse ? -1 : 1;
  if (a === b) return 0;
  return reverseMulti * a.localeCompare(b, locales, {
    numeric: true
  });
}
export function compareNumbers(a, b) {
  var reverse = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return reverse ? b - a : a - b;
}
function getDefault(element) {
  var data = '';
  if (typeof element === 'string') {
    data = element;
  } else if (typeof element === 'number') {
    data = element + '';
  }
  return data.toLowerCase().trim();
}