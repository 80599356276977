import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import HelpText from '@/components/Atoms/HelpText/HelpText';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { IATitles } from '@/constants/compliance/ComplianceControllers';
export default {
  components: {
    RismaTitle: RismaTitle,
    HelpText: HelpText
  },
  mixins: [ProcessLibraryTitleMixin],
  props: {
    data: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'list of questions'
    }
  },
  data: function data() {
    return {
      iaTitles: IATitles,
      translate: {
        question: this.$trans('Question')
      }
    };
  },
  methods: {
    getList: function getList(list) {
      return list.map(function (item) {
        return item.title;
      }).join(', ');
    }
  }
};