function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelText as _vModelText, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-21a15a96"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "security-groups"
};
var _hoisted_2 = {
  class: "security-groups-section"
};
var _hoisted_3 = {
  key: 1,
  class: "security-groups-section"
};
var _hoisted_4 = {
  class: "security-groups-row security-groups-row-header"
};
var _hoisted_5 = {
  class: "security-groups-row"
};
var _hoisted_6 = {
  class: "security-groups-column"
};
var _hoisted_7 = ["onUpdate:modelValue", "title", "onInput"];
var _hoisted_8 = {
  class: "security-groups-column"
};
var _hoisted_9 = ["onUpdate:modelValue", "onInput"];
var _hoisted_10 = {
  class: "security-groups-column"
};
var _hoisted_11 = {
  class: "security-groups-column"
};
var _hoisted_12 = {
  class: "security-groups-column"
};
var _hoisted_13 = {
  class: "security-groups-column"
};
var _hoisted_14 = {
  class: "security-groups-column"
};
var _hoisted_15 = {
  class: "security-groups-column"
};
var _hoisted_16 = {
  class: "security-groups-column"
};
var _hoisted_17 = {
  class: "security-groups-column security-groups-column-locked"
};
var _hoisted_18 = ["checked", "onClick"];
var _hoisted_19 = {
  class: "security-groups-projects"
};
var _hoisted_20 = {
  class: "security-groups-projects-actions"
};
var _hoisted_21 = ["onClick"];
var _hoisted_22 = ["onClick"];
var _hoisted_23 = ["onClick"];
var _hoisted_24 = {
  key: 0
};
var _hoisted_25 = {
  class: "security-groups-actions security-groups-actions-fixed"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_ad_settings = _resolveComponent("ad-settings");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_security_group_new = _resolveComponent("security-group-new");
  var _component_single_select = _resolveComponent("single-select");
  var _component_security_group_projects = _resolveComponent("security-group-projects");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_main_well = _resolveComponent("main-well");
  var _component_security_groups_modal = _resolveComponent("security-groups-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.singleSignonSettings
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_ad_settings), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : _createCommentVNode("v-if", true), _withDirectives(_createElementVNode("div", _hoisted_2, [_createVNode(_component_security_group_new, {
        onCreated: $options.handleCreateNewGroup
      }, null, 8 /* PROPS */, ["onCreated"])], 512 /* NEED_PATCH */), [[_vShow, !$data.isLoading]]), !$data.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.columns, function (column, i) {
        return _openBlock(), _createBlock(_component_risma_title, {
          key: "column".concat(i),
          title: column,
          type: "medium",
          class: "security-groups-column"
        }, null, 8 /* PROPS */, ["title"]);
      }), 128 /* KEYED_FRAGMENT */))]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.groups, function (group) {
        var _group$complianceProj, _group$riskProjects;
        return _openBlock(), _createElementBlock("div", {
          key: "group".concat(group.id),
          class: "security-groups-group"
        }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return group.objectId = $event;
          },
          title: group.objectId,
          type: "text",
          class: "form-control w-full border-2 border-gray-350 px-3 py-2 rounded-md min-h-40px transition duration-300 text-base text-gray-750 placeholder-italic placeholder-gray-550 hover:border-gray-450 focus:outline-none focus:border-blue-450 disabled:text-gray-650",
          onInput: function onInput($event) {
            return $options.updateField(group.id, 'objectId', $event.target.value);
          }
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_7), [[_vModelText, group.objectId, void 0, {
          lazy: true
        }]])]), _createElementVNode("div", _hoisted_8, [_withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return group.name = $event;
          },
          type: "text",
          class: "form-control w-full border-2 border-gray-350 px-3 py-2 rounded-md min-h-40px transition duration-300 text-base text-gray-750 placeholder-italic placeholder-gray-550 hover:border-gray-450 focus:outline-none focus:border-blue-450 disabled:text-gray-650",
          onInput: function onInput($event) {
            return $options.updateField(group.id, 'name', $event.target.value);
          }
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_9), [[_vModelText, group.name, void 0, {
          lazy: true
        }]])]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_single_select, {
          options: $data.adminLevels,
          "model-value": group.levelAdmin,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelAdmin', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_single_select, {
          options: $data.accessLevels,
          "model-value": group.levelControls,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelControls', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_single_select, {
          options: $data.accessLevels,
          "model-value": group.levelExecution,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelExecution', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_single_select, {
          options: $data.accessLevels,
          "model-value": group.levelIncidents,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelIncidents', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_single_select, {
          options: $data.accessLevels,
          "model-value": group.levelRisk,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelRisk', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_15, [_createVNode(_component_single_select, {
          options: $data.accessLevels,
          "model-value": group.levelContracts,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelContracts', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_16, [_createVNode(_component_single_select, {
          options: $data.accessLevelsBase,
          "model-value": group.levelInformationAssets,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelInformationAssets', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_17, [_createElementVNode("input", {
          checked: group.lockedFields,
          class: "security-groups-checkbox",
          type: "checkbox",
          onClick: function onClick($event) {
            return $options.updateField(group.id, 'lockedFields', $event.target.checked);
          }
        }, null, 8 /* PROPS */, _hoisted_18)])]), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, [_createElementVNode("a", {
          href: "#",
          onClick: _withModifiers(function ($event) {
            return $options.openModal({
              securitygroupId: group.id,
              type: _ctx.typesAccessProjects.compliance
            });
          }, ["stop", "prevent"])
        }, _toDisplayString($data.translate.createNewComplianceAccess), 9 /* TEXT, PROPS */, _hoisted_21), _createElementVNode("a", {
          href: "#",
          onClick: _withModifiers(function ($event) {
            return $options.openModal({
              securitygroupId: group.id,
              type: _ctx.typesAccessProjects.risk
            });
          }, ["stop", "prevent"])
        }, _toDisplayString($data.translate.createNewRiskAccess), 9 /* TEXT, PROPS */, _hoisted_22), (_group$complianceProj = group.complianceProjects) !== null && _group$complianceProj !== void 0 && _group$complianceProj.length || (_group$riskProjects = group.riskProjects) !== null && _group$riskProjects !== void 0 && _group$riskProjects.length ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: "#",
          onClick: _withModifiers(function ($event) {
            return group.isProjectsShown = !group.isProjectsShown;
          }, ["stop", "prevent"])
        }, _toDisplayString($options.getProjectShowTitle(group.isProjectsShown)), 9 /* TEXT, PROPS */, _hoisted_23)) : _createCommentVNode("v-if", true)]), group.isProjectsShown ? (_openBlock(), _createElementBlock("div", _hoisted_24, [_createVNode(_component_security_group_projects, {
          projects: [].concat(_toConsumableArray(group.complianceProjects), _toConsumableArray(group.riskProjects)),
          onSubmit: $options.handleOpenModal
        }, null, 8 /* PROPS */, ["projects", "onSubmit"])])) : _createCommentVNode("v-if", true)])]);
      }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", _hoisted_25, [_createVNode(_component_risma_button, {
        text: "".concat($data.translate.update),
        type: "save",
        onClick: $options.update
      }, null, 8 /* PROPS */, ["text", "onClick"])])])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }), $data.isModalShow ? (_openBlock(), _createBlock(_component_security_groups_modal, {
    key: 0,
    "type-access-projects": $data.modalData.type,
    "project-access-id": $data.modalData.projectAccessId,
    "project-id": $data.modalData.projectId,
    "security-group-id": $data.modalData.securitygroupId,
    "access-level": $data.modalData.accessLevel,
    onSubmit: $options.handleSubmitModal,
    onClose: $options.closeModal
  }, null, 8 /* PROPS */, ["type-access-projects", "project-access-id", "project-id", "security-group-id", "access-level", "onSubmit", "onClose"])) : _createCommentVNode("v-if", true)]);
}