import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import TabList from '@/components/Atoms/TabList';
import { TabsDepRelations } from '@/constants/Tabs';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'DepRelations',
  introduction: 'This is the right part of each edit page.',
  description: 'it contains two tabs and can be collapsed by click on the icon',
  token: '<dep-relations local-storage-key="" :tabs="[]"> <div v-slot:description> smth </div> <div v-slot:details> smth </div> </dep-relations>',
  components: {
    FeatherIcon: FeatherIcon,
    TabList: TabList
  },
  props: {
    localStorageKey: {
      type: String,
      required: false,
      default: 'dep_relations',
      note: 'Name of localStorage key'
    },
    tabs: {
      type: Array,
      default: TabsDepRelations,
      note: 'Tabs list'
    }
  },
  emits: ['changedViewMode'],
  setup: function setup(props) {
    return {
      isExpanded: getLocalStorageComputed(props.localStorageKey, false, true)
    };
  },
  data: function data() {
    return {
      currentTab: this.tabs[0].slotName,
      currentTabKey: '_tab',
      transitionDuration: 400,
      animationTimer: null
    };
  },
  computed: {
    IconClass: function IconClass() {
      return this.isExpanded ? 'chevron-right' : 'chevron-left';
    },
    tabsTranslated: function tabsTranslated() {
      var _this = this;
      return this.tabs.map(function (tab) {
        return Object.assign({}, tab, {
          name: _this.$trans(tab.name)
        });
      });
    },
    transitionVars: function transitionVars() {
      return {
        '--transition-duration': this.transitionDuration + 'ms'
      };
    }
  },
  mounted: function mounted() {
    this.$emit('changedViewMode', this.isExpanded);
  },
  methods: {
    changeViewMode: function changeViewMode() {
      var _this2 = this;
      if (this.animationTimer) {
        clearTimeout(this.animationTimer);
        return;
      }
      this.isExpanded = !this.isExpanded;
      if (!this.isExpanded) {
        this.animationTimer = setTimeout(function () {
          _this2.$emit('changedViewMode', _this2.isExpanded);
          _this2.animationTimer = null;
        }, this.transitionDuration + 100);
        return;
      }
      this.$nextTick(function () {
        return _this2.$emit('changedViewMode', _this2.isExpanded);
      });
    }
  }
};