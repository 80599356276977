import DpmAuditPage from '@/components/Pages/InformationAssets/Dpm/DpmAuditPage';
export default function getDpmRoutes() {
  return [{
    path: '/dpm/:token',
    component: DpmAuditPage,
    props: true,
    meta: {
      skipLoginCheck: true
    }
  }];
}