import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-d2248428"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["question-id", "number"];
var _hoisted_2 = {
  class: "question-text"
};
var _hoisted_3 = {
  key: 1,
  class: "questionSection collapse show"
};
var _hoisted_4 = {
  key: 10,
  class: "w-75"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$myInformation, _$props$myInformation2, _$props$question;
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_help_text = _resolveComponent("help-text");
  var _component_single_choice = _resolveComponent("single-choice");
  var _component_multi_choice = _resolveComponent("multi-choice");
  var _component_information_assets_choice = _resolveComponent("information-assets-choice");
  var _component_free_text_choice = _resolveComponent("free-text-choice");
  var _component_attachment_choice = _resolveComponent("attachment-choice");
  var _component_single_asset_choice = _resolveComponent("single-asset-choice");
  var _component_process_library_choice = _resolveComponent("process-library-choice");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_new_choice = _resolveComponent("new-choice");
  return $props.question ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    "question-id": $props.question.id,
    number: $props.question.questionnumber,
    class: "question"
  }, [$props.showHeadline ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    class: _normalizeClass(["text-21px mb-8", {
      'text-gray-175': !$props.question.answeredDependencies
    }]),
    title: "".concat($props.question.headline)
  }, null, 8 /* PROPS */, ["class", "title"])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [_createVNode(_component_risma_title, {
    title: "".concat($props.question.questionnumber, "  ").concat($props.question.question),
    class: _normalizeClass(["text-base font-semibold", {
      'text-gray-175': !$props.question.answeredDependencies
    }]),
    type: "small",
    truncate: false
  }, null, 8 /* PROPS */, ["title", "class"]), _createElementVNode("p", _hoisted_2, [_createVNode(_component_help_text, {
    text: $props.question.help,
    class: _normalizeClass(["text-sm", {
      'text-gray-175': !$props.question.answeredDependencies
    }]),
    "count-truncate": 260
  }, null, 8 /* PROPS */, ["text", "class"])])]), $props.question.answeredDependencies ? (_openBlock(), _createElementBlock("div", _hoisted_3, [$props.question.questiontype == 1 ? (_openBlock(), _createBlock(_component_single_choice, {
    key: 0,
    "read-only": $props.readOnly,
    options: $props.question.optionDetails,
    answers: $props.question.answers_options || {},
    "radio-button-focus": !$options.showFullQuestionsList,
    onAnswered: $options.getSingleAnswers
  }, null, 8 /* PROPS */, ["read-only", "options", "answers", "radio-button-focus", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype == 2 ? (_openBlock(), _createBlock(_component_multi_choice, {
    key: 1,
    "read-only": $props.readOnly,
    options: $props.question.optionDetails,
    answers: $props.question.answers_options || {},
    onAnswered: $options.getAnswers,
    onForceUpdate: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "options", "answers", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype == 4 ? (_openBlock(), _createBlock(_component_information_assets_choice, {
    key: 2,
    "read-only": $props.readOnly,
    assets: $options.informationAssetsWithFilteredProcessors,
    "selected-assets": $options.filteredIAQuestion || {},
    onAnswered: $options.getIA,
    onNewAsset: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('newAsset', $event);
    }),
    onForceUpdate: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "assets", "selected-assets", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype == 3 || $props.question.questiontype == 7 ? (_openBlock(), _createBlock(_component_free_text_choice, {
    key: 3,
    "read-only": $props.readOnly,
    "free-text": $props.question.freeText,
    onAnswered: $options.getFreeText,
    onForceUpdate: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "free-text", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype == 5 || $props.question.questiontype == 7 ? (_openBlock(), _createBlock(_component_attachment_choice, {
    key: 4,
    "read-only": $props.readOnly,
    files: $props.question.files,
    onAnswered: $options.getFiles
  }, null, 8 /* PROPS */, ["read-only", "files", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === 9 ? (_openBlock(), _createBlock(_component_single_asset_choice, {
    key: 5,
    "read-only": $props.readOnly,
    assets: $props.informationAssets.systems,
    "my-assets": ((_$props$myInformation = $props.myInformationAssetsByType) === null || _$props$myInformation === void 0 ? void 0 : _$props$myInformation.systems) || [],
    selected: $options.selectedSystems,
    type: $data.AssetsTypes.SYSTEMS,
    onAnswered: $options.getIA,
    onNewAsset: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('newAsset', $event);
    }),
    onForceUpdate: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "assets", "my-assets", "selected", "type", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === 10 ? (_openBlock(), _createBlock(_component_single_asset_choice, {
    key: 6,
    "read-only": $props.readOnly,
    assets: $options.filteredProcessors,
    "my-assets": $options.myFilteredProcessorsIds,
    selected: $options.filteredProcessorsQuestion || [],
    type: $data.AssetsTypes.PROCESSORS,
    onAnswered: $options.getIA,
    onNewAsset: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.$emit('newAsset', $event);
    }),
    onForceUpdate: _cache[7] || (_cache[7] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "assets", "my-assets", "selected", "type", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === 11 ? (_openBlock(), _createBlock(_component_single_asset_choice, {
    key: 7,
    "read-only": $props.readOnly,
    assets: $props.informationAssets.controllers,
    "my-assets": ((_$props$myInformation2 = $props.myInformationAssetsByType) === null || _$props$myInformation2 === void 0 ? void 0 : _$props$myInformation2.controllers) || [],
    selected: $options.selectedControllers,
    type: $data.AssetsTypes.CONTROLLERS,
    onAnswered: $options.getIA,
    onNewAsset: _cache[8] || (_cache[8] = function ($event) {
      return _ctx.$emit('newAsset', $event);
    }),
    onForceUpdate: _cache[9] || (_cache[9] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "assets", "my-assets", "selected", "type", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === 12 ? (_openBlock(), _createBlock(_component_process_library_choice, {
    key: 8,
    "read-only": $props.readOnly,
    selected: $props.question.processLibrary || [],
    "node-element": $props.nodeElement,
    onAnswered: $options.getAnswers,
    onForceUpdate: _cache[10] || (_cache[10] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "selected", "node-element", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === 13 ? (_openBlock(), _createBlock(_component_single_asset_choice, {
    key: 9,
    "read-only": $props.readOnly,
    assets: $options.filteredCustomIA,
    "my-assets": $options.myFilteredCustomIA,
    selected: $options.selectedCustomIA || [],
    "type-id": (_$props$question = $props.question) === null || _$props$question === void 0 || (_$props$question = _$props$question.questionobject) === null || _$props$question === void 0 ? void 0 : _$props$question.informationAssetActivityType,
    "activity-types": $props.activityTypes,
    type: $data.AssetsTypes.INFORMATION_ASSETS_API,
    onAnswered: $options.getIA,
    onNewAsset: _cache[11] || (_cache[11] = function ($event) {
      return _ctx.$emit('newAsset', $event);
    }),
    onForceUpdate: _cache[12] || (_cache[12] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "assets", "my-assets", "selected", "type-id", "activity-types", "type", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === 14 || $props.question.questiontype === 15 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_date_time_picker, {
    "model-value": $options.dateTime,
    disabled: $props.readOnly,
    "plugin-options": $options.datePickerOptions,
    "delete-enabled": true,
    onValueUpdated: $options.getDate,
    onClose: _cache[13] || (_cache[13] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "plugin-options", "onValueUpdated"])])) : _createCommentVNode("v-if", true), $props.question.allowNewAnswerOption == 1 && ($props.question.questiontype == 1 || $props.question.questiontype == 2) && !$props.readOnly ? (_openBlock(), _createBlock(_component_new_choice, {
    key: 11,
    "read-only": $props.readOnly,
    options: $props.question.options,
    onNew: $options.addOption
  }, null, 8 /* PROPS */, ["read-only", "options", "onNew"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_1)) : _createCommentVNode("v-if", true);
}