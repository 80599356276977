import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-17ecf818"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "data-lane flex ml-5/100 mr-5/100 mb-0"
};
var _hoisted_2 = {
  class: "w-1/4"
};
var _hoisted_3 = {
  class: "ml-100px flex flex-grow"
};
var _hoisted_4 = {
  class: "ml-100px w-3/10 flex"
};
var _hoisted_5 = {
  class: "text-blue-750"
};
var _hoisted_6 = {
  class: "text-blue-750"
};
var _hoisted_7 = {
  class: "text-orange-50"
};
var _hoisted_8 = {
  class: "bg-white w-1/4 min-h-40px relative inline-block"
};
var _hoisted_9 = {
  class: "w-10/12 float-left text-left"
};
var _hoisted_10 = {
  class: "inline-block w-3/4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_pop_up_menu = _resolveComponent("pop-up-menu");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_text_box = _resolveComponent("text-box");
  return _openBlock(), _createElementBlock("div", null, [$data.showWarningAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    body: $data.warningText.body,
    header: $data.warningText.header,
    "button-ok-text": $data.warningText.okButton,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: _ctx.changeCallback,
    onDismiss: $options.toggleWarningAlert
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), $data.showChangeSystemTitleModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 1,
    header: $data.translate.updateTitle,
    "button-dismiss-text": $data.translate.cancel,
    "header-truncate": false,
    "overflow-visible": true,
    onAccept: $options.onChangeSystemTitle,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return $data.showChangeSystemTitleModal = false;
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.emptySystemTitleError ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.emptySystemTitleError), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        title: $data.translate.enterNewHeadlineForSystems,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
        modelValue: $data.newSystemTitle,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.newSystemTitle = $event;
        }),
        modelModifiers: {
          trim: true
        },
        placeholder: $data.translate.title,
        "focus-on-mount": true,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "placeholder"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-dismiss-text", "onAccept"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    class: "break-words text-left",
    truncate: false,
    title: $options.dataFlowStakeholdersTitle
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    class: "w-3/10 text-left",
    title: $data.translate.dataTypes
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
    editable: false,
    title: $options.dataFlowSystemsTitle,
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), !$props.isProjectLocked ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    width: "20",
    height: "20",
    class: "ml-2 cursor-pointer fill-primaryDark self-center",
    icon: "edit-2",
    onClick: $options.showSystemTitleChangeModal
  }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]), $options.showComments ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    class: "ml-6",
    title: $data.translate.comments
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true)])]), _createElementVNode("div", {
    class: _normalizeClass(['relative flex items-start justify-center ml-5/100 mr-5/100', {
      'ml-32': !$options.showComments
    }])
  }, [$data.displayPopUp ? (_openBlock(), _createBlock(_component_pop_up_menu, {
    key: 0,
    onDismiss: _cache[5] || (_cache[5] = function ($event) {
      return $data.displayPopUp = false;
    })
  }, {
    menu: _withCtx(function () {
      return [_createElementVNode("div", {
        class: "menuItem flex items-center p-2",
        onClick: _cache[2] || (_cache[2] = function () {
          return $options.onChangeDataflowDirection && $options.onChangeDataflowDirection.apply($options, arguments);
        })
      }, [_createVNode(_component_feather_icon, {
        icon: $props.flow.direction === $data.RECEIVE_DIRECTION ? 'arrow-right' : 'arrow-left',
        class: "text-blue-750 mr-2"
      }, null, 8 /* PROPS */, ["icon"]), _createElementVNode("span", _hoisted_5, _toDisplayString($data.translate.changeDirectionsOfDataflow), 1 /* TEXT */)]), _createElementVNode("div", {
        class: "menuItem flex items-center p-2",
        onClick: _cache[3] || (_cache[3] = function () {
          return $options.onNewDataFlow && $options.onNewDataFlow.apply($options, arguments);
        })
      }, [_createVNode(_component_feather_icon, {
        icon: "plus",
        class: "text-blue-750 mr-2"
      }), _createElementVNode("span", _hoisted_6, _toDisplayString($data.translate.createANewDataFlowFromThisStakeholder), 1 /* TEXT */)]), _createElementVNode("div", {
        class: "menuItem flex items-center p-2",
        onClick: _cache[4] || (_cache[4] = function () {
          return $options.onDeleteStakeholder && $options.onDeleteStakeholder.apply($options, arguments);
        })
      }, [_createVNode(_component_feather_icon, {
        icon: "x",
        class: "text-red-50 mr-2"
      }), _createElementVNode("span", _hoisted_7, _toDisplayString($data.translate.deleteTheEntireDataFlow), 1 /* TEXT */)])];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_single_select, {
    options: $options.stakeholdersOptions,
    "model-value": $props.flow.stakeholder.uniqueId,
    disabled: $props.isProjectLocked,
    "onUpdate:modelValue": $options.onChangeStakeholder
  }, null, 8 /* PROPS */, ["options", "model-value", "disabled", "onUpdate:modelValue"])]), !$props.isProjectLocked ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "w-1/6 py-1 three-dots-button cursor-pointer border-2 border-gray-350 rounded-md absolute right-0 h-full",
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return $data.displayPopUp = true;
    })
  })) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.flow.subFlows, function (subFlow, idx) {
    return _openBlock(), _createElementBlock("div", {
      key: idx,
      class: "lane-column relative ml-100px flex"
    }, [_createVNode(_component_risma_select, {
      class: _normalizeClass([$options.showComments ? 'w-3/10 min-w-3/10' : 'w-5/12 min-w-5/12', "arrow-line"]),
      options: $props.dataTypes,
      "selected-options": subFlow.dataTypes,
      "max-visible-tags": 1000,
      disabled: $props.isProjectLocked,
      "is-selected-sorted": true,
      "label-key": "text",
      "track-by": "uniqueId",
      onSelected: function onSelected($event) {
        return $options.updateSingleFlow($event, 'dataTypes', subFlow.id);
      }
    }, null, 8 /* PROPS */, ["class", "options", "selected-options", "disabled", "onSelected"]), !($options.flowDirection === 'left' && idx > 0) ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass("datatype-arrow arrow-".concat($options.flowDirection))
    }, null, 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
      class: _normalizeClass(["relative ml-100px", $options.showComments ? 'w-3/10 min-w-3/10' : 'w-5/12 min-w-5/12'])
    }, [_createVNode(_component_risma_select, {
      class: "arrow-line always-first-arrow-line",
      options: $props.systems,
      "selected-options": subFlow.systems,
      "max-visible-tags": 1000,
      disabled: $props.isProjectLocked,
      "is-selected-sorted": true,
      "label-key": "text",
      "track-by": "uniqueId",
      "selected-label": "",
      "select-label": "",
      "deselect-label": "",
      onSelected: function onSelected($event) {
        return $options.updateSingleFlow($event, 'systems', subFlow.id);
      }
    }, null, 8 /* PROPS */, ["options", "selected-options", "disabled", "onSelected"]), _createElementVNode("div", {
      class: _normalizeClass("system-arrow arrow-".concat($options.flowDirection))
    }, null, 2 /* CLASS */)], 2 /* CLASS */), $options.showComments && !$props.isProjectLocked ? (_openBlock(), _createBlock(_component_text_box, {
      key: 1,
      rows: 3,
      text: subFlow.comments,
      "max-length": 10000,
      class: "ml-6 w-3/10 mr-2",
      onUpdated: function onUpdated($event) {
        return $options.updateSingleFlow($event, 'comments', subFlow.id);
      }
    }, null, 8 /* PROPS */, ["text", "onUpdated"])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [!$props.isProjectLocked ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      class: "cursor-pointer text-red-50",
      width: "35",
      height: "35",
      icon: "x",
      onClick: function onClick($event) {
        return $options.onDeleteDataFlow(subFlow.id);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)])]);
  }), 128 /* KEYED_FRAGMENT */))])], 2 /* CLASS */)]);
}