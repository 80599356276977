import Mypage from '@/components/Pages/Mypage/Mypage';
import UserGuide from '@/components/Pages/UserGuide/UserGuide';
export default function getMypageRoute() {
  return [{
    path: '/business2/mypage',
    redirect: '/mypage'
  }, {
    path: '/mypage',
    component: Mypage
  }, {
    path: '/userguide',
    component: UserGuide
  }];
}