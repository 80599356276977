export var ControlCookies = {
  LIST_SHOW: 'controls2_list_show',
  RISMA1_LIST_SHOW: 'list_c_show',
  LIST_COMPACT_HIDDEN: 'controls2_hidden_columns',
  SORT_BY: 'controls2_sort_by',
  SORT_BY_CATALOGUE: 'controls2_sort_by_catalogue',
  SEARCH_CATALOGUE: 'controls2_search_catalogue',
  SEARCH_MY_CONTROLS: 'controls2_search_my_controls',
  SORT_BY_MY: 'controls2_sort_by_my',
  DEADLINE: 'controls2_deadline',
  MOTHER: 'controls2_mother',
  TAGS: 'controls2_tags',
  GROUP_BY: 'controls2_group_by',
  EXPAND_OVERVIEW_CATALOGUE: 'controls2_expand_overview_catalogue',
  RISMA1_DEADLINE: 'list_c_deadline_days',
  ORGANISATIONS: 'controls2_orgs',
  RISMA1_ORGANISATIONS: 'my_c_selected_org_id',
  REPORTS_DEACTIVATED: 'controls2_reports_deactivated',
  CATALOGUE_DEACTIVATED: 'controls2_catalogue_deactivated',
  USERS: 'controls2_catalogue_users'
};