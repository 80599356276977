function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
var PredefinedIcons = {
  confidential: {
    icon: 'alert-triangle',
    class: 'text-red-50 stroke-2 ml-1 self-center'
  }
};
export default {
  name: 'RismaTitleWithIcon',
  introduction: 'Title followed by icon(confidential by default), RISMA style',
  description: 'The risma-title-with-icon component is used to create titles with styled icons',
  token: '<risma-title-with-icon title="title for XXX"/>',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle
  },
  props: {
    title: {
      required: true,
      type: String,
      note: 'The text followed by icon'
    },
    icon: {
      required: false,
      type: [String, Object],
      default: '',
      note: 'Predefined type of icon with feather icon and styles',
      validator: function validator(icon) {
        if (!icon) return true;
        if (_typeof(icon) === 'object') {
          return icon === null || icon === void 0 ? void 0 : icon.icon;
        }
        return Object.keys(PredefinedIcons).includes(icon);
      }
    },
    displayIcon: {
      required: false,
      type: [Boolean, Number],
      default: false,
      validator: function validator(value) {
        if (typeof value === 'boolean') return true;
        return typeof value === 'number' && (value === 1 || value === 0);
      }
    },
    type: {
      required: false,
      type: String,
      default: '',
      note: 'Type for risma title if it`s necessary to have'
    },
    titleClass: {
      required: false,
      type: String,
      default: ''
    },
    titleAttribute: {
      required: false,
      type: String,
      default: '',
      note: 'The extra information about an element, shows when the mouse moves over the element'
    },
    titleIsRawHtml: {
      required: false,
      type: Boolean,
      default: false
    },
    truncate: {
      required: false,
      default: true,
      type: Boolean,
      note: 'A boolean to determine if the title should be truncated'
    }
  },
  data: function data() {
    return {
      predefinedIcons: PredefinedIcons
    };
  },
  computed: {
    iconData: function iconData() {
      return _typeof(this.icon) === 'object' ? this.icon : this.predefinedIcons[this.icon];
    }
  }
};