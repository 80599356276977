function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useTagsStore } from '@/Store/tagsStore';
import * as ProcessTreeApi from '@/api/compliance/processtree';
import { getControls as _getControls, getInitiatives as _getInitiatives } from '@/api/risma/data';
import { getAllByType } from '@/api/compliance/informationAssets';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import HierarchicalList from '@/components/Molecules/HierarchicalList';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { mapToCommaListWithLinks } from '@/utils/format/mapTo';
import { formatDateWithTimezone } from '@/utils/date';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { SortDirection } from '@/constants/SortDirection';
import { ReportTypes } from '@/constants/reports';
import { DateFormats } from '@/constants/DateFormats';
import { Colors } from '@/Colors';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export default {
  name: 'ProcessTreeReport',
  introduction: 'Process Tree Reports',
  description: 'This is Page component for /compliance/processtree/report/',
  token: '<process-tree-report></process-tree-report>',
  components: {
    RismaDatatable: RismaDatatable,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    HierarchicalList: HierarchicalList,
    Trafficlight: Trafficlight,
    SaveReport: SaveReport,
    PreviewModalLink: PreviewModalLink
  },
  mixins: [RismaDatatableDefaultMixin, RaciTitleMixin, ProcessLibraryTitleMixin, SaveReportMixin],
  data: function data() {
    return {
      currentUser: {},
      isLoaded: false,
      isIE: false,
      pageLength: 50,
      order: [{
        index: 'title',
        dir: SortDirection.DESC
      }],
      dataset: [],
      dataTableContainerSelector: '.processtree-datatable',
      dataTableScrollBodySelector: '.table-wrapper-body',
      decoupleFilterFields: [{
        key: 'userIds.responsible'
      }, {
        key: 'userIds.accountable'
      }, {
        key: 'userIds.consulted'
      }, {
        key: 'userIds.informed'
      }, {
        key: 'organisations'
      }, {
        key: 'systems'
      }, {
        key: 'initiatives'
      }, {
        key: 'controls'
      }, {
        key: 'tags'
      }, {
        key: 'processLibrabyLinks'
      }, {
        key: 'attachedFiles'
      }],
      disabledFilterByColumns: ['trafficLight', 'description'],
      initiatives: [],
      controls: [],
      systems: [],
      processLibraryNodes: [],
      columnsMeta: [{
        targets: ['title'],
        width: '400px'
      }, {
        targets: ['description'],
        width: '600px'
      }],
      reportNodes: [],
      slotLinkFields: ['systems', 'initiatives', 'controls', 'processLibrabyLinks'],
      customFieldsColumns: {},
      customFieldsDefault: {},
      customFieldsById: {},
      updatedItem: null,
      updatedGovernanceReview: null,
      reportType: ReportTypes.PROCESS_LIBRARY,
      stateLocal: 'process_library_report_table_state',
      translate: getTranslate['ProcessTreeReport']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useTagsStore, ['tags'])), mapState(useActivityTypesStore, {
    activities: 'activityTypes',
    processNodeActivityTypes: 'processNodeActivityTypes'
  })), {}, {
    columns: function columns() {
      var result = {
        id: this.translate.id,
        trafficLight: this.translate.trafficLight,
        title: this.translate.title,
        description: this.translate.description,
        activityType: this.translate.activityType,
        responsible: this.responsibleTitle(true),
        accountable: this.accountableTitle(true),
        consulted: this.consultedTitle(true),
        informed: this.informedTitle(true),
        organisations: this.translate.organisation,
        systems: this.translate.linkedSystems,
        initiatives: this.translate.linkedInitiatives,
        controls: this.translate.linkedControls,
        tags: this.translate.tags,
        governanceReview: this.translate.governanceReview,
        completionDate: this.translate.governanceReviewLastCompleted,
        nextReviewDate: this.translate.nextGovernanceReviewDate,
        attachedFiles: this.translate.attachedFiles
      };
      if (this.allowProcessLibraryLinks) {
        result.processLibrabyLinks = "".concat(this.translate.linked, " ").concat(this.processLibraryTitle);
      }
      return _objectSpread(_objectSpread({}, result), this.customFieldsColumns);
    },
    allowProcessLibraryLinks: function allowProcessLibraryLinks() {
      return this.getSettingValue('feature.allow_links_between_process_library_nodes') === 1;
    },
    headline: function headline() {
      return "".concat(this.processLibraryTitle, " ").concat(this.translate.report);
    }
  }),
  created: function created() {
    var _this = this;
    this.getData().then(function () {
      return _this.loadSavedReport();
    });
  },
  methods: {
    getInitiatives: function getInitiatives() {
      var _this2 = this;
      return _getInitiatives().then(function (_ref) {
        var list = _ref.list;
        return _this2.initiatives = list;
      });
    },
    getControls: function getControls() {
      var _this3 = this;
      return _getControls().then(function (_ref2) {
        var list = _ref2.list;
        return _this3.controls = list;
      });
    },
    getSystems: function getSystems() {
      var _this4 = this;
      return getAllByType(AssetsTypes.SYSTEMS).then(function (_ref3) {
        var list = _ref3.list;
        return _this4.systems = list;
      });
    },
    getAllTrees: function getAllTrees() {
      var _this5 = this;
      return ProcessTreeApi.getAllTrees().then(function (response) {
        if (response[0].id !== null) {
          _this5.treeData = response[0];
          _this5.isLoaded = false;
          _this5.dataset = [];
          return ProcessTreeApi.getReportById(_this5.treeData.id).then(function (_ref4) {
            var list = _ref4.list,
              allProcesses = _ref4.allProcesses;
            _this5.processLibraryNodes = allProcesses;
            _this5.reportNodes = list;
          }).catch(function (error) {
            _this5.isLoaded = true;
            throw error;
          });
        } else {
          _this5.error = _this5.translate.noProcessesFound;
        }
      });
    },
    getData: function getData() {
      var _this6 = this;
      return Promise.all([this.getAllTrees(), this.getInitiatives(), this.getControls(), this.getSystems()]).then(function () {
        _this6.prepareCustomFieldOptions(_this6.reportNodes);
        _this6.dataset = _this6.prepareDataset(_this6.reportNodes, _this6.processLibraryNodes);
        _this6.enhanceDataset(_this6.dataset, _this6.columns);
        _this6.isLoaded = true;
      });
    },
    prepareDataset: function prepareDataset(list, allProcesses) {
      var _this7 = this;
      return list.map(function (element) {
        var _element$links, _element$links2, _element$links3, _element$links4;
        var customFieldsData = _objectSpread(_objectSpread({}, _this7.customFieldsDefault), _this7.customFieldsById[element.id]);
        element.organisationIds = element.organisationIds.map(function (id) {
          return parseInt(id, 10);
        });
        var trafficLight = Colors.getTrafficlightByNumber(element.trafficLight);
        var governanceReview = (_element$links = element.links) !== null && _element$links !== void 0 && _element$links.review ? _this7.controls.find(function (control) {
          return +control.id === +element.links.review[0];
        }) : null;
        var fields = {
          id: {
            field: element.uniqueId || ''
          },
          trafficLight: {
            field: DatatableRenderer.prepareTrafficLightField(element.trafficLight),
            fieldText: (trafficLight === null || trafficLight === void 0 ? void 0 : trafficLight.number) || '',
            color: (trafficLight === null || trafficLight === void 0 ? void 0 : trafficLight.color) || ''
          },
          title: {
            field: {
              node: _this7.buildHierarchicalStructure(element, allProcesses)
            },
            fieldText: element.title
          },
          description: {
            field: element.description
          },
          activityType: {
            field: _this7.getActivityTypeName(element.activityTypeId)
          },
          responsible: {
            field: _this7.mapToCommaList(_this7.filterUsers(element.responsibleUserIds), 'display_name')
          },
          accountable: {
            field: _this7.mapToCommaList(_this7.filterUsers(element.accountableUserIds), 'display_name')
          },
          consulted: {
            field: _this7.mapToCommaList(_this7.filterUsers(element.consultedUserIds), 'display_name')
          },
          informed: {
            field: _this7.mapToCommaList(_this7.filterUsers(element.informedUserIds), 'display_name')
          },
          organisations: {
            field: _this7.mapToCommaList(_this7.organisations.filter(function (o) {
              return element.organisationIds.indexOf(o.id) !== -1;
            }), 'visible_name')
          },
          systems: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links2 = element.links) === null || _element$links2 === void 0 ? void 0 : _element$links2.compliance_ia_system, _this7.systems)),
          initiatives: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links3 = element.links) === null || _element$links3 === void 0 ? void 0 : _element$links3.execution, _this7.initiatives, 'path')),
          controls: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links4 = element.links) === null || _element$links4 === void 0 ? void 0 : _element$links4.control, _this7.controls, 'path')),
          tags: {
            field: _this7.mapToCommaList(_this7.tags.filter(function (t) {
              return element.tagIds.includes(t.id);
            }), 'tag')
          },
          governanceReview: {
            activity: governanceReview,
            item: {
              id: element.id,
              links: element.links
            },
            fieldText: governanceReview ? governanceReview === null || governanceReview === void 0 ? void 0 : governanceReview.title : ''
          },
          completionDate: {
            field: _this7.formatDate(governanceReview, 'latestCompletion')
          },
          nextReviewDate: {
            field: _this7.formatDate(governanceReview, 'deadline')
          },
          attachedFiles: {
            field: mapToCommaListWithLinks(element.attachments, 'url', 'filename', 'id', false),
            fieldText: _this7.mapToCommaList(element.attachments, 'filename')
          }
        };
        if (_this7.allowProcessLibraryLinks) {
          var _element$links5;
          fields.processLibrabyLinks = _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links5 = element.links) === null || _element$links5 === void 0 ? void 0 : _element$links5.compliance_process_tree_node, Object.values(_this7.processLibraryNodes)));
        }
        return _objectSpread(_objectSpread({}, fields), customFieldsData);
      });
    },
    onDismissModal: function onDismissModal() {
      if (!this.updatedItem) return;
      var id = this.updatedItem.id;
      var idx = this.reportNodes.findIndex(function (node) {
        return node.id === id;
      });
      if (idx === -1) return;
      var limitedNode = this.getLimitedObject(this.updatedItem);
      this.reportNodes[idx] = Object.assign(this.reportNodes[idx], this.updatedItem);
      this.prepareCustomFieldOptions(this.reportNodes);
      this.processLibraryNodes[id] = Object.assign(this.processLibraryNodes[id], limitedNode);
      this.dataset = this.prepareDataset(this.reportNodes, this.processLibraryNodes);
      this.enhanceDataset(this.dataset, this.columns);
      this.updatedItem = null;
    },
    buildHierarchicalStructure: function buildHierarchicalStructure(node, allProcesses) {
      var structure = {
        title: node.title,
        url: node.url,
        objType: node.objType,
        id: node.id,
        processTreeId: node.processTreeId
      };
      node.ancestorIds && node.ancestorIds.reverse().forEach(function (id) {
        var parent = allProcesses[parseInt(id, 10)];
        var parentStructure = {
          title: parent.title,
          url: parent.url,
          objType: parent.objType,
          id: parent.id,
          processTreeId: parent.processTreeId
        };
        parentStructure.children = [_objectSpread({}, structure)];
        structure = JSON.parse(JSON.stringify(parentStructure));
      });
      return structure;
    },
    mapToCommaList: function mapToCommaList(arr) {
      var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
      return arr && arr.length > 0 ? arr.map(function (obj) {
        return obj[field];
      }).join(', ') : '';
    },
    filterUsers: function filterUsers(userIds) {
      return this.users.filter(function (user) {
        return userIds.indexOf(user.id) !== -1;
      });
    },
    formatDate: function formatDate(review, field) {
      if (!(review !== null && review !== void 0 && review[field])) return '';
      return formatDateWithTimezone(review[field].split('+')[0], review.deadlineTimezone, DateFormats.DATE_FORMAT);
    },
    prepareCustomFieldOptions: function prepareCustomFieldOptions(list) {
      var _this$getPropertiesWi = this.getPropertiesWithCustomFields(list, this.activities),
        extendedColumns = _this$getPropertiesWi.extendedColumns,
        defaultCustomFields = _this$getPropertiesWi.defaultCustomFields,
        customFieldsValuesByEntity = _this$getPropertiesWi.customFieldsValuesByEntity,
        columnFilterOptions = _this$getPropertiesWi.columnFilterOptions;
      this.customFieldsColumns = extendedColumns;
      this.customFieldsDefault = defaultCustomFields;
      this.customFieldsById = customFieldsValuesByEntity;
      this.enhanceColumnFilters(columnFilterOptions, this.disabledFilterByColumns, this.decoupleFilterFields);
    },
    getActivityTypeName: function getActivityTypeName(id) {
      if (!id) return '';
      var activityType = this.processNodeActivityTypes.find(function (at) {
        return +at.id === +id;
      });
      if (!activityType) return '';
      return activityType.label;
    },
    getLimitedObject: function getLimitedObject(node) {
      return {
        id: node.id,
        processTreeId: node.processTreeId,
        title: node.title,
        trafficLight: node.trafficLight,
        links: node.links
      };
    },
    prepareFilterByLabel: function prepareFilterByLabel(value, columnKey) {
      return this.prepareFilterForTrafficLight(value, columnKey, this.trafficLightRismaDatatableFields);
    },
    updateGovernanceReview: function updateGovernanceReview(govReview, updatedItem) {
      this.updatedGovernanceReview = govReview;
      this.updatedItem = updatedItem;
      this.updateControlInControlsList(govReview);
      this.updateItemLinksReview(govReview.id);
    },
    updateGovReviewFromItemPreview: function updateGovReviewFromItemPreview(govReview) {
      this.updateControlInControlsList(govReview);
      this.updateItemLinksReview(govReview.id);
    },
    updateItemLinksReview: function updateItemLinksReview(id) {
      var _this$updatedItem;
      if ((_this$updatedItem = this.updatedItem) !== null && _this$updatedItem !== void 0 && _this$updatedItem.links && id) {
        this.updatedItem.links.review = [+id];
      }
    },
    onDismissGovReviewModal: function onDismissGovReviewModal(updatedItem) {
      if (!this.updatedGovernanceReview) return;
      var idx = this.reportNodes.findIndex(function (node) {
        return node.id === updatedItem.id;
      });
      this.reportNodes[idx] = Object.assign(this.reportNodes[idx], {
        links: updatedItem.links
      });
      this.updateControlInControlsList(this.updatedGovernanceReview);
      this.updateDatasetAndEnhance();
      this.clearUpdatedItems();
    },
    updateDatasetAndEnhance: function updateDatasetAndEnhance() {
      this.dataset = this.prepareDataset(this.reportNodes, this.processLibraryNodes);
      this.enhanceDataset(this.dataset, this.columns);
    },
    clearUpdatedItems: function clearUpdatedItems() {
      this.updatedGovernanceReview = null;
      this.updatedItem = null;
    },
    updateControlInControlsList: function updateControlInControlsList(newControl) {
      var controlToUpdate = this.controls.find(function (control) {
        return +control.id === +newControl.id;
      });
      if (controlToUpdate) {
        controlToUpdate.title = newControl.title;
        controlToUpdate.latestCompletion = newControl.latestCompletion;
        controlToUpdate.deadline = newControl.deadline;
      } else {
        this.controls.push(newControl);
      }
    }
  }
};