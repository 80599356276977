function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import Notification from '@/components/Molecules/Notification';
import DelegationWell from '@/components/Organisms/General/coreWells/DelegationWell';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import InformationAssetsDetailsRelations from '@/components/Organisms/InformationAssets/InformationAssetsDetailsRelations';
import GovernanceReviewSection from '@/components/Organisms/Compliance/GovernanceReview/GovernanceReviewSection';
import { createTabsList } from '@/utils/base/tabs';
import { getLinksLabel } from '@/utils/links';
import { MODULES } from '@/constants/modules';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { DepRelationsTabDelegation, Tabs, TabTypes } from '@/constants/Tabs';
import { InformationAssetsEntryMixin } from '@/components/Pages/InformationAssets/InformationAssetsEntryMixin';
import { GovernanceReviewMixin } from '@/mixins/GovernanceReviewMixin';
export default {
  name: 'InformationAssetsIA',
  components: {
    DelegationWell: DelegationWell,
    DepWell: DepWell,
    InformationAssetsDetailsRelations: InformationAssetsDetailsRelations,
    GovernanceReviewSection: GovernanceReviewSection,
    Notification: Notification
  },
  mixins: [InformationAssetsEntryMixin, GovernanceReviewMixin],
  props: {
    id: {
      required: true,
      type: [String, Number]
    }
  },
  emits: ['changeTab'],
  data: function data() {
    return {
      tabTitle: '',
      titleLoaded: false,
      informationAssetType: AssetsTypes.INFORMATION_ASSETS_API,
      titleField: 'title',
      module: MODULES.COMPLIANCE_INFORMATION_ASSETS,
      uniqueIdReadOnly: true,
      uniqueIdField: 'id',
      moduleId: this.id,
      companyStatusNotification: '',
      translate: {
        beAwareThatThisCompanyHasStatus: this.$trans('Be aware that this company has status')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, ['usersWithIAAccess'])), {}, {
    baseLink: function baseLink() {
      return "/compliance/informationassets/informationassets/".concat(this.id);
    },
    tabs: function tabs() {
      var DESCRIPTION = TabTypes.DESCRIPTION,
        AUDIT = TabTypes.AUDIT,
        LINKS = TabTypes.LINKS,
        CHANGELOG = TabTypes.CHANGELOG;
      var arr = [DESCRIPTION];
      if (this.isQuestionnaireAvailable) {
        this.activeQuestionnaires.forEach(function (questionnaire) {
          arr.push(_objectSpread(_objectSpread({}, Tabs[TabTypes.QUESTIONNAIRE]), {}, {
            url: questionnaire.url,
            slicedUrl: "".concat(Tabs[TabTypes.QUESTIONNAIRE].url, "/").concat(questionnaire.questionnaireId),
            label: questionnaire.tabLabel
          }));
        });
      }
      if (this.auditQuestionnaires && this.auditQuestionnaires.length) {
        arr.push(AUDIT);
      }
      arr.push(LINKS, CHANGELOG);
      return createTabsList(arr, this.baseLink, getLinksLabel(this.informationAsset));
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.activityTypes.find(function (at) {
        return _this.informationAsset.activityTypeId === at.id;
      });
    },
    iaActivityTypeName: function iaActivityTypeName() {
      var _this$currentActivity;
      return (_this$currentActivity = this.currentActivityType) === null || _this$currentActivity === void 0 ? void 0 : _this$currentActivity.label;
    },
    preparedInformationAssetsItem: function preparedInformationAssetsItem() {
      return _objectSpread(_objectSpread({}, this.informationAsset), {}, {
        auditQuestionnaires: this.auditQuestionnaires
      });
    }
  }),
  methods: {
    depRelationTabs: function depRelationTabs() {
      return [DepRelationsTabDelegation()];
    }
  }
};