import UserEntry from '@/components/Pages/User/UserEntry';
import UserDescription from '@/components/Pages/User/UserDescription';
import UserRightsTableNew from '@/components/Molecules/UserRightsTableNew';
import { TabTypes } from '@/constants/Tabs';
import { isAdmin } from '@/routes/auth';
export default (function () {
  return [{
    path: '/user/:id/',
    component: UserEntry,
    redirect: {
      name: 'UserDescription'
    },
    meta: {
      userRightsFunction: isAdmin
    },
    props: true,
    children: [{
      path: TabTypes.DESCRIPTION,
      name: 'UserDescription',
      component: UserDescription
    }, {
      path: TabTypes.RIGHTS,
      component: UserRightsTableNew
    }]
  }];
});