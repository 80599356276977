import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex flex-col flex-grow"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_links_box = _resolveComponent("links-box");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_links_box, {
    title: $data.translate.reports,
    links: $options.reports
  }, null, 8 /* PROPS */, ["title", "links"])]);
}