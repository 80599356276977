import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_wizard_flow = _resolveComponent("wizard-flow");
  return _openBlock(), _createElementBlock("div", null, [$data.stakeholderOptions.length ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "activity",
    width: "30",
    height: "30",
    class: "rounded-full border-2 border-gray-35 cursor-pointer absolute right-25px",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.isOpened = true;
    })
  })) : _createCommentVNode("v-if", true), $data.isOpened ? (_openBlock(), _createBlock(_component_wizard_flow, {
    key: 1,
    items: $options.items,
    "stakeholder-options": $data.stakeholderOptions,
    "current-data-subject": $data.currentDataSubject,
    onAccept: _cache[1] || (_cache[1] = function ($event) {
      return $data.isOpened = false;
    }),
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return $data.isOpened = false;
    }),
    "onUpdate:dataSubject": _cache[3] || (_cache[3] = function ($event) {
      return $data.currentDataSubject = $event.value;
    })
  }, null, 8 /* PROPS */, ["items", "stakeholder-options", "current-data-subject"])) : _createCommentVNode("v-if", true)]);
}