var _excluded = ["components"];
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import ComplianceStatsWidget from '@/components/Organisms/Compliance/Overview/ComplianceStatsWidget';
import ComplianceStatusOverview from '@/components/Organisms/Compliance/Overview/ComplianceStatusOverview';
import ComplianceReportsBox from '@/components/Organisms/Compliance/Overview/ComplianceReportsBox';
import ComplianceGeneralList from '@/components/Organisms/Compliance/Overview/ComplianceGeneralList';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import overviewJSON from '@/constants/compliance/overview';
export default {
  name: 'ComplianceOverview',
  components: {
    RismaTitle: RismaTitle,
    NotificationPopUp: NotificationPopUp,
    ComplianceReportsBox: ComplianceReportsBox,
    ComplianceGeneralList: ComplianceGeneralList,
    ComplianceStatusOverview: ComplianceStatusOverview,
    ComplianceStatsWidget: ComplianceStatsWidget
  },
  mixins: [NotificationPopUpMixin],
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'ID of current project'
    }
  },
  data: function data() {
    return {
      notificationMessage: '',
      notificationType: 'error',
      translate: {
        yourActivityHasBeenCreated: this.$trans('Your activity has been created')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, {
    currentUserComplianceProjectById: 'complianceProjectById'
  })), {}, {
    currentProject: function currentProject() {
      return this.currentUserComplianceProjectById(this.projectId);
    },
    projectTypeId: function projectTypeId() {
      return this.currentProject.type;
    },
    pageJSON: function pageJSON() {
      var _overviewJSON$find,
        _this = this;
      return (_overviewJSON$find = overviewJSON.find(function (item) {
        return +item.projectTypeId === +_this.projectTypeId;
      })) === null || _overviewJSON$find === void 0 ? void 0 : _overviewJSON$find.page;
    },
    pageStructure: function pageStructure() {
      var _this2 = this;
      return this.pageJSON.map(function (_ref) {
        var components = _ref.components,
          rest = _objectWithoutProperties(_ref, _excluded);
        var visibleComponents = components.filter(function (item) {
          return item.settingToShow ? !!_this2.getSettingValue(item.settingToShow) : item;
        });
        return _objectSpread(_objectSpread({}, rest), {}, {
          components: visibleComponents
        });
      });
    }
  }),
  methods: {
    showSuccessNotification: function showSuccessNotification(item) {
      this.setNotificationData({
        title: this.translate.yourActivityHasBeenCreated,
        name: item.name,
        url: item.url
      });
    }
  }
};