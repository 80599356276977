export function searchByFields(array, fields, value) {
  // if value is array then some  functionality could be implemented here
  if (!Array.isArray(value)) {
    //if was passed only one param as string
    if (!Array.isArray(fields)) {
      fields = [fields];
    }
    return array.filter(function (item) {
      return fields.some(function (field) {
        return isRightItem(item, field, value);
      });
    });
  }
  return array;
}
function isRightItem(item, field, value) {
  return item[field] && item[field].toString().toLowerCase().trim().indexOf(value.toString().toLowerCase().trim()) !== -1;
}