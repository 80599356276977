import ToolTip from '@/components/Atoms/Containers/ToolTip';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
export default {
  name: 'TrafficLightToolTip',
  introduction: 'Selector for traffic lights',
  description: "TrafficLightToolTip is a little tool tip box with either traffic light or gap colors. This is adjusted with the type prop.\n                      the tool tip also has a hidden prop which dictates if the tool tip should be visible. If a traffic light is clicked, the tool tip\n                      will return a selected event with the traffic light element as payload",
  token: '<traffic-light-tool-tip :hidden="false" type="gaps"/>',
  components: {
    TrafficLight: TrafficLight,
    ToolTip: ToolTip
  },
  props: {
    hidden: {
      type: Boolean,
      default: false,
      note: 'Should the tool tip be hidden?'
    },
    trafficLightsData: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['selected'],
  computed: {
    trafficLights: function trafficLights() {
      var _this$trafficLightsDa;
      return ((_this$trafficLightsDa = this.trafficLightsData) === null || _this$trafficLightsDa === void 0 ? void 0 : _this$trafficLightsDa.lights) || [];
    }
  },
  methods: {
    emitSelected: function emitSelected(item) {
      this.$emit('selected', item);
    }
  }
};