export var MenuKeys = {
  incidents: 'incidents',
  myIncidents: 'myIncidents',
  incidentsArchived: 'IncidentsArchived',
  reports: 'reports',
  controls: 'controls',
  myControls: 'myControls',
  dashboards: 'dashboards',
  initiatives: 'initiatives',
  myInitiatives: 'myInitiatives',
  annualOverview: 'annualOverview',
  statusOverview: 'statusOverview',
  erm: 'erm',
  awareness: 'awareness',
  ia: 'ia',
  processLib: 'processLib',
  myIA: 'myIA',
  myRisks: 'myRisks',
  riskOverview: 'overview',
  admin: 'admin'
};