import RismaPieChart from '@/components/Molecules/Charts/RismaPieChart';
export default {
  components: {
    RismaPieChart: RismaPieChart
  },
  data: function data() {
    return {
      chartOptions: {
        hoverBorderWidth: 20
      },
      chartData: [1, 5, 10],
      chartLabels: ['Green', 'Red', 'Blue'],
      colors: ['#41B883', '#E46651', '#00D8FF']
    };
  },
  methods: {
    clickCh: function clickCh(e) {
      alert('index of clicked slice is: ' + e + ' (' + this.chartLabels[e] + ')');
    }
  }
};