import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3d95d5f6"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "container-full margin-auto risk-entry-container"
};
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = {
  key: 1,
  class: "mb-4 pb-6 border-b-2 border-gray-250"
};
var _hoisted_4 = {
  key: 2,
  class: "mb-4 pb-6 border-b-2 border-gray-250"
};
var _hoisted_5 = {
  key: 3,
  class: "mb-4 pb-6 border-b-2 border-gray-250"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_delegation_well = _resolveComponent("delegation-well");
  var _component_risk_relations_section_details = _resolveComponent("risk-relations-section-details");
  var _component_solution_id_relation_details = _resolveComponent("solution-id-relation-details");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_activity_type_selector = _resolveComponent("activity-type-selector");
  var _component_single_select = _resolveComponent("single-select");
  var _component_link_item = _resolveComponent("link-item");
  var _component_dep_well = _resolveComponent("dep-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.isLoaded && ($setup.risk.closed || $options.showClosedPeriodNotification) ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "info",
    innerHTML: $options.infoNotification
  }, null, 8 /* PROPS */, ["innerHTML"])) : _createCommentVNode("v-if", true), $options.isRiskLocked ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    closeable: false,
    type: "info"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.thisActivityIsReadonlyAsYourUserIsConfigu), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.isNotificationShow ? (_openBlock(), _createBlock(_component_notification, {
    key: 2,
    type: $data.notificationType,
    onDismiss: $options.handleDismissNotification
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.notificationMessage), 1 /* TEXT */), _createElementVNode("div", {
        innerHTML: $data.notificationDetails
      }, null, 8 /* PROPS */, _hoisted_2)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type", "onDismiss"])) : _createCommentVNode("v-if", true), !$data.loadedWithError ? (_openBlock(), _createBlock(_component_dep_well, {
    key: 3,
    "is-loaded": $data.isLoaded,
    "dep-header-props": $options.depHeaderProps,
    "read-only": $options.isReadOnlyAccess,
    item: $setup.risk,
    "local-storage-key": "risk_dep_relations",
    onFileUpdate: $options.onFileUpdate,
    onPropertyChanged: $options.riskPropertyChanged,
    onGetActivityData: _cache[3] || (_cache[3] = function ($event) {
      return $options.riskPropertyChanged({
        property: 'attachments',
        value: undefined
      });
    }),
    onSaveStatusClicked: $options.saveStatusClicked,
    onChangeTab: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('changeTab', $event);
    }),
    onUpdateUnderlyingRisk: $options.updateUnderlyingRisk,
    "onDelete:risk": $options.onDeleteRisk
  }, {
    delegation: _withCtx(function () {
      return [_createVNode(_component_delegation_well, {
        "model-value": $options.delegationData,
        organisations: _ctx.organisations,
        "risk-management-levels": $data.managementLevels,
        "read-only": $options.isReadOnlyAccess,
        "current-activity-type": $options.currentActivityType,
        onAddNewItem: _ctx.updateDelegationList,
        onPropertyChanged: $options.saveChangesImmediately
      }, null, 8 /* PROPS */, ["model-value", "organisations", "risk-management-levels", "read-only", "current-activity-type", "onAddNewItem", "onPropertyChanged"])];
    }),
    details: _withCtx(function () {
      return [$options.isERM ? (_openBlock(), _createBlock(_component_risk_relations_section_details, {
        key: 0,
        risk: $setup.risk,
        "risk-types": $data.riskTypes,
        disabled: $options.isReadOnlyAccess,
        onPropertyChanged: $options.riskPropertyChanged
      }, null, 8 /* PROPS */, ["risk", "risk-types", "disabled", "onPropertyChanged"])) : _createCommentVNode("v-if", true), _createVNode(_component_solution_id_relation_details, {
        list: $setup.risk.solutionIds,
        "read-only": $options.isReadOnlyAccess,
        onPropertyChanged: $options.riskPropertyChanged
      }, null, 8 /* PROPS */, ["list", "read-only", "onPropertyChanged"]), !$options.isERM && _ctx.activityTypes.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
        class: "w-11/12",
        type: "medium",
        title: $data.translate.activityType
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_activity_type_selector, {
        "activity-types": _ctx.activityTypes,
        "model-value": $setup.risk.activityTypeId,
        disabled: $options.isReadOnlyAccess,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $options.riskPropertyChanged({
            property: 'activityTypeId',
            value: $event || -1
          });
        })
      }, null, 8 /* PROPS */, ["activity-types", "model-value", "disabled"])])) : _createCommentVNode("v-if", true), !$options.isERM && $setup.risk.children && $setup.risk.children.length === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_single_select, {
        modelValue: $setup.risk.parent,
        "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
          return $setup.risk.parent = $event;
        }), _cache[2] || (_cache[2] = function ($event) {
          return $options.riskPropertyChanged({
            property: 'parent',
            value: $event ? $event : 0
          });
        })],
        title: $data.translate.parentRisk,
        options: $setup.risksFormatted,
        disabled: $options.isReadOnlyAccess || !$options.isParentRiskAvailable,
        "un-sorted": true,
        "with-reset": !$options.isReadOnlyAccess && $options.isParentRiskAvailable,
        placeholder: $setup.risk.parent ? $data.translate.parentRiskIsHidden : $data.translate.choose
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "disabled", "with-reset", "placeholder"])])) : _createCommentVNode("v-if", true), !$options.isERM && $setup.risk.children && $setup.risk.children.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_title, {
        type: "medium",
        title: $data.translate.underlyingRisks
      }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.risk.children, function (child, key) {
        return _openBlock(), _createBlock(_component_link_item, {
          key: key,
          title: "".concat(child.rno, ". ").concat(child.title),
          link: child.url,
          module: 'risk'
        }, null, 8 /* PROPS */, ["title", "link"]);
      }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-loaded", "dep-header-props", "read-only", "item", "onFileUpdate", "onPropertyChanged", "onSaveStatusClicked", "onUpdateUnderlyingRisk", "onDelete:risk"])) : _createCommentVNode("v-if", true)]);
}