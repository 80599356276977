function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import * as Cookies from 'tiny-cookie';
import clip from 'text-clipper';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { getColumnLabelName } from '@/utils/CustomFields';
export function truncate(text, stop, clamp) {
  if (text && text.length > 0) {
    text = text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
  }
  return text;
}
export function truncateHtml(htmlString, stop, settings) {
  return clip(htmlString, stop, settings);
}
export function sanitize(text) {
  return text.replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&#039;');
}
export function pushChildrenToParent(rootNode, data, childCallback, childLevel) {
  if (rootNode && rootNode.children) {
    if (typeof childLevel === 'number') {
      childLevel += 1;
    }
    rootNode.children.forEach(function (child) {
      if (child) {
        data.push(child);
        if (typeof childCallback === 'function') {
          childCallback(child, childLevel);
        }
        if (child.children) {
          pushChildrenToParent(child, data, childCallback, childLevel);
        }
      }
    });
  }
}
export function getLanguage() {
  var locale = Cookies.get('risma_lang');
  locale = locale ? locale.split('_')[0] : 'en'; // For jest default: 'en'
  return locale;
}
export function hasValue(value) {
  return value || value === 0 || value === '';
}
export function findTimeOffset(timezones, value) {
  return timezones.find(function (item) {
    return item.value === value;
  });
}
export function getProperSelectedOptions(options) {
  var result = options;

  // UI fallback to array
  if (!Array.isArray(options) && _typeof(options) === 'object' && options !== null) {
    result = Object.values(options);
  }
  return result;
}
export function findSelectedOptionId(field) {
  var options = getProperSelectedOptions(field.options);
  var result = options.find(function (option) {
    return option.value === field.selectedValue;
  });
  if (result) {
    return result.id;
  } else {
    return null;
  }
}
export function isIE() {
  var ua = navigator.userAgent;

  /* MSIE used to detect old browsers and Trident used to newer ones*/
  return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
}
export function findPropById(id, data, prop) {
  var result = '';
  if (id) {
    var value = data.find(function (item) {
      return +item.id === +id;
    });
    result = value ? value[prop] : result;
  }
  return result;
}
export function findPropsByIds(ids, data, prop) {
  var delimiter = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : ', ';
  var result = [];
  if (ids && ids.length) {
    ids.map(function (id) {
      data.map(function (item) {
        if (+item.id === +id) {
          result.push(item[prop]);
        }
      });
    });
  }
  result = result.join(delimiter);
  return result;
}
export function findIdsInArray(ids, data) {
  if (!ids) return [];
  return ids.reduce(function (arr, item) {
    var element = data.find(function (company) {
      return company.id === item;
    });
    if (element) arr.push(element);
    return arr;
  }, []);
}
export function findProps(data, prop) {
  var delimiter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ', ';
  var result = [];
  if (data && data.length) {
    data.map(function (item) {
      result.push(item[prop]);
    });
  }
  result = result.join(delimiter);
  return result;
}
export function removeDuplicates(data) {
  var obj = {};
  var result = [];
  for (var i = 0, len = data.length; i < len; i++) {
    obj[data[i].id] = data[i];
  }
  for (var key in obj) {
    result.push(obj[key]);
  }
  return result;
}
export function removeHtmlTags(data) {
  return data.replace(/&nbsp;|<\/?[^>]+(>|$)/g, '').trim();
}
export function purifyItemField(item, toLowerCase) {
  var val = getCorrectRowField(item);
  var newLineState = '&newLineWrapper';
  val = val.replace(new RegExp('</div>', 'g'), "</div>".concat(newLineState)); //removeHTMLTags would remove \n and \r

  val = removeHtmlTags(val);
  val = val.replace(new RegExp(newLineState, 'g'), ' \r\n');
  val = val.replace(new RegExp('&nbsp;', 'g'), ' ');
  val = val.trim();
  if (toLowerCase) {
    val = val.toLowerCase();
  }
  return val;
}
export function getCorrectRowField(item) {
  var _result;
  var result = Object.keys(item).includes('fieldText') ? item.fieldText : item.field;
  result = (_result = result) !== null && _result !== void 0 ? _result : '';
  if (result && _typeof(result) === 'object') {
    if (Array.isArray(result)) {
      result = result.map(function (item) {
        if (_typeof(item) === 'object') {
          return getRowDataAsObject(item);
        }
        if (typeof item === 'string' || typeof item === 'number') {
          return item;
        }
      }).join(',');
    } else {
      result = Object.hasOwnProperty.call(result, 'activities') ? getRowDataAsObject(result.activities, 'textField') : getRowDataAsObject(result);
    }
  }
  result = result.toString().trim();
  return result;
}
export function getRowDataAsObject(rowData) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'title';
  var separator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ',';
  if (Array.isArray(rowData)) {
    rowData = rowData.map(function (item) {
      return item[key];
    }).filter(function (item) {
      return item === 0 || !!item;
    }).join(separator);
  } else {
    rowData = rowData[key] || '';
  }
  return rowData;
}
export function getOnlyUniqueAndExist(data) {
  var result = Array.from(new Set(data));
  return result.filter(function (el) {
    return el;
  });
}
export function setMaxlength(e, maxlength) {
  var result = e;
  if (typeof result === 'string') {
    result = result.substr(0, maxlength);
  }
  return result;
}
export function getBase64Content(e) {
  return e.target.result ? e.target.result.substring(e.target.result.indexOf('base64,') + 7) : '';
}
export function findValueById(data, id, key) {
  var result = data.find(function (item) {
    return item.id === id;
  });
  if (result) {
    return result[key];
  }
  return '';
}
export function findLabelValue(currentItem, rows) {
  var result = '';
  rows.map(function (item) {
    if (item.id === currentItem.id) {
      if (currentItem.value > 0 && item.options[currentItem.value]) {
        result = item.options[currentItem.value].value;
      }
    }
  });
  return result;
}
export function filterByIAAccess(users) {
  return users.filter(function (item) {
    return item.iaAccess;
  });
}
export function addHyphensBeforeTitle(item, n) {
  var symbolsToAdd = '--',
    hyphenPart = symbolsToAdd;
  if (typeof n === 'number') {
    try {
      while (n) {
        hyphenPart += symbolsToAdd;
        n--;
      }
    } catch (e) {
      // nothing
    }
  }
  if (item.title) {
    item.title = "".concat(hyphenPart, " ").concat(item.title);
  }
}
export function filterArrayByListOfPropValues(itemsList, listOfPropValues) {
  var prop = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
  if (!itemsList || !itemsList.length || !listOfPropValues || !listOfPropValues.length) return [];
  return itemsList.filter(function (item) {
    return listOfPropValues.includes(item[prop]);
  });
}
export function getUserLanguage(user) {
  var _user$lang;
  return user === null || user === void 0 || (_user$lang = user.lang) === null || _user$lang === void 0 ? void 0 : _user$lang.split('_')[0];
}
export function findListByIds() {
  var ids = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return ids.map(function (item) {
    return list.find(function (l) {
      return l.id === item;
    });
  }).filter(Boolean);
}
export function getThreatLabelWithCategory(threat) {
  var _threat$category;
  var categoryTitle = threat === null || threat === void 0 || (_threat$category = threat.category) === null || _threat$category === void 0 ? void 0 : _threat$category.title;
  if (categoryTitle) {
    return "".concat(categoryTitle, " > ").concat(threat.title, "\n");
  } else {
    return "".concat(threat.title, "\n");
  }
}
export function getThreatsLabelWithCategories(threatIds, threats) {
  var threatsFilteredById = threats.filter(function (threat) {
    return threatIds.indexOf(threat.id) !== -1;
  });
  var label = '';
  threatsFilteredById.forEach(function (threat) {
    label += getThreatLabelWithCategory(threat);
  });
  return label;
}
export function isFieldInOptionalFields(activityType, field) {
  var _activityType$optiona;
  if (!activityType) return true;
  return !!((_activityType$optiona = activityType.optionalFields) !== null && _activityType$optiona !== void 0 && _activityType$optiona.find(function (item) {
    return item == field;
  }));
}
export function isHexColor(str) {
  var regex = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/g;
  return regex.test(str);
}

// key => userIds.responsible
export function getDeep(item, key) {
  if (!key.length) return null;
  var keyArr = key.split('.');
  var result = item;
  var currentKeyIdx = 0;
  while (keyArr.length > currentKeyIdx && result[keyArr[currentKeyIdx]]) {
    result = result[keyArr[currentKeyIdx]];
    currentKeyIdx++;
  }
  return keyArr.length === currentKeyIdx ? result : null;
}
export function prepareLink(url, title) {
  var result = "<a href=\"".concat(url, "\" class=\"block\" target=\"_blank\"");
  var isAbsolutePath = url.startsWith('https://') || url.startsWith('http://');
  if (isAbsolutePath) {
    result += ' rel="noopener noreferrer"';
  }
  result += ">".concat(title, "</a>");
  return result;
}
export function prepareInactiveHtml(data) {
  return data ? "<span class=\"text-gray-550\">".concat(data, "</span>") : '';
}
export var getCustomFieldsColsFromActivityTypes = function getCustomFieldsColsFromActivityTypes(activityTypes) {
  var unitPrefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Unit';
  if (!activityTypes || !activityTypes.length) return {};
  var result = {};
  var fieldsIdsAdded = [];
  activityTypes.forEach(function (_ref) {
    var fields = _ref.fields,
      deleted = _ref.deleted;
    if (deleted || !fields || !fields.length) return;
    fields.forEach(function (field) {
      var isFieldAlreadyAdded = fieldsIdsAdded.indexOf(field.id) !== -1;
      var isRowType = field.typeId === CustomFieldTypes.ROW_GROUP;
      if (isFieldAlreadyAdded || isRowType) return;
      var label = getColumnLabelName(field.id);
      result[label] = {
        label: field.label,
        fieldType: field.typeId
      };
      if (field.typeId === CustomFieldTypes.NUMERICAL) {
        var unityLabel = getColumnLabelName(field.id + unitPrefix);
        result[unityLabel] = {
          label: field.label + ' ' + unitPrefix
        };
      }
      fieldsIdsAdded.push(field.id);
    });
  });
  return result;
};
export function generateKey() {
  return Math.random().toString(20);
}