var _Colors;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { ObjectTypes } from '@/constants/ObjectTypes';
import { useSettingsStore } from '@/Store/settingsStore';
var cachedOverdueControlColor = null;
var getOverdueControlColor = function getOverdueControlColor() {
  if (cachedOverdueControlColor) return cachedOverdueControlColor;
  var color = useSettingsStore().settings['feature.controls_overdue_completed_color'] || '#A80000';
  cachedOverdueControlColor = {
    color: color,
    label: Colors.getTrafficLabelByColor(color)
  };
  return cachedOverdueControlColor;
};
export var Colors = (_Colors = {
  high: '#B03D00',
  medium: '#ffc103',
  low: '#007c87',
  notSet: '#807a77',
  noGap: '#57aa74',
  na: '#bbbbbb',
  white: '#ffffff',
  answered: '#4cb3c0',
  unanswered: '#B03D00',
  trafficLightStroke: '#dedede',
  primaryGreen: '#347382',
  noTrafficLight: '#aaa',
  blueLight: '#4cb3c0',
  red: '#a80000',
  yellow: '#ffbf00',
  lightYellow: '#F7F59A',
  dullGreen: '#5aa774',
  dullGreen2: '#5aa744',
  greenTea: '#AFDE9A',
  lilacGray: '#79A1D2',
  lightRed: '#E47F7F',
  system: {
    white: '#ffffff',
    black: '#000000',
    BG: '#F4F4F4',
    lightGray: '#BEBEBE',
    lightGray2: '#807c79',
    blueGray: '#637994',
    blueGrayActive: '#afd7ed',
    gray: '#737373',
    darkGray: '#4D4D4D',
    primaryLight: '#4CB3C0',
    primaryDark: '#347480',
    warning: '#B03D00',
    blueMain: '#0e2954',
    red: '#a80000' // tailwind red-50
  },

  trafficLights: [{
    number: 0,
    color: '#BEBEBE',
    label: 'grey'
  }, {
    number: 1,
    color: '#A80000',
    label: 'red'
  }, {
    number: 2,
    color: '#FFBF00',
    label: 'yellow'
  }, {
    number: 3,
    color: '#5AA744',
    label: 'green'
  }],
  incidentSeverity: [{
    number: 0,
    color: '#BEBEBE',
    label: 'grey'
  }, {
    number: 1,
    color: '#5AA744',
    label: 'green'
  }, {
    number: 2,
    color: '#FFBF00',
    label: 'yellow'
  }, {
    number: 3,
    color: '#A80000',
    label: 'red'
  }],
  gaps: [{
    number: 0,
    color: '#737373',
    label: 'Not set'
  }, {
    number: 1,
    color: '#5AA744',
    label: 'No gap'
  }, {
    number: 2,
    color: '#347480',
    label: 'Gap Low'
  }, {
    number: 3,
    color: '#FFBF00',
    label: 'Gap Medium'
  }, {
    number: 4,
    color: '#A80000',
    label: 'Gap High'
  }, {
    number: 5,
    color: '#BEBEBE',
    label: 'Gap N/A'
  }]
}, _defineProperty(_Colors, ObjectTypes.COMPANY, [{
  number: 0,
  color: '#BEBEBE',
  label: 'Unknown'
}, {
  number: 1,
  color: '#5aa744',
  label: 'Normal'
}, {
  number: 2,
  color: '#ffbf00',
  label: 'Under bankruptcy '
}, {
  number: 3,
  color: '#ffbf00',
  label: 'During termination'
}, {
  number: 4,
  color: '#a80000',
  label: 'Bankrupt'
}, {
  number: 5,
  color: '#a80000',
  label: 'Terminated'
}]), _defineProperty(_Colors, "trafficlightColor", function trafficlightColor(number) {
  var safe = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!this.trafficLights[number] && safe) {
    return this.trafficLights[0];
  }
  return this.trafficLights[number];
}), _defineProperty(_Colors, "getControlTrafficLightByNumber", function getControlTrafficLightByNumber(number) {
  if (+number === 1) {
    return _objectSpread({
      number: 1
    }, getOverdueControlColor());
  }
  if (!this.trafficLights[number]) {
    return {
      number: 0,
      color: '#BEBEBE',
      label: 'grey'
    };
  }
  return this.trafficLights[number];
}), _defineProperty(_Colors, "findIncidentSeverity", function findIncidentSeverity(number) {
  if (!this.incidentSeverity[number]) {
    return {
      number: 0,
      color: '#BEBEBE',
      label: 'grey'
    };
  }
  return this.incidentSeverity[number];
}), _defineProperty(_Colors, "getTrafficlightByNumber", function getTrafficlightByNumber(number) {
  return this.trafficLights.filter(function (trafficLight) {
    return trafficLight.number === parseInt(number, 10);
  })[0];
}), _defineProperty(_Colors, "getTrafficlightByLabel", function getTrafficlightByLabel(label) {
  return this.trafficLights.find(function (trafficLight) {
    return trafficLight.label === label;
  });
}), _defineProperty(_Colors, "getCompanyTrafficlightByNumber", function getCompanyTrafficlightByNumber(number) {
  return this[ObjectTypes.COMPANY].find(function (trafficLight) {
    return trafficLight.number === number;
  });
}), _defineProperty(_Colors, "gapColor", function gapColor(number) {
  if (!this.gaps[number]) {
    return this.gaps[0];
  }
  return this.gaps[number];
}), _defineProperty(_Colors, "findGapByLabel", function findGapByLabel(label) {
  return this.gaps.filter(function (item) {
    return item.label === label;
  })[0];
}), _defineProperty(_Colors, "getTrafficLightColor", function getTrafficLightColor(item) {
  if (+item.id === 0) return;
  if (item.objType === ObjectTypes.INCIDENTS_INCIDENT || item.objType === ObjectTypes.INCIDENTS_INCIDENT_LIMITED) {
    return this.findIncidentSeverity(item.severity).color;
  }
  if (item.objType === ObjectTypes.COMPANY) return this.getCompanyTrafficlightByNumber(item.vatStatus).color;
  if (item.trafficLight) return this.getTrafficlightByNumber(item.trafficLight).color;
  if (item.trafficLightText) return this.getTrafficlightByLabel(item.trafficLightText).color;
  return this.getTrafficlightByNumber(0).color;
}), _defineProperty(_Colors, "getTrafficLabelByColor", function getTrafficLabelByColor(color) {
  if (!color) return 'grey';
  var colors = {
    '#A80000': 'red',
    '#3CDFFF': 'blue',
    '#FF6D0A': 'orange',
    '#9300FF': 'purple',
    '#BEBEBE': 'grey',
    '#FFBF00': 'yellow',
    '#5AA744': 'green'
  };
  return (colors === null || colors === void 0 ? void 0 : colors[color.toUpperCase()]) || 'grey';
}), _Colors);