import i18n from '@/i18n';
export var getTranslate = {
  DashboardCreationModal: function DashboardCreationModal() {
    return {
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      createNewDashboard: i18n.t('Create new dashboard'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty')
    };
  },
  KPICreationModal: function KPICreationModal() {
    return {
      createKpi: i18n.t('Create KPI'),
      cancel: i18n.t('Cancel'),
      addNewKpi: i18n.t('Add new KPI'),
      pleaseSelectAKpi: i18n.t('Please select a KPI')
    };
  },
  KPIStaticTable: function KPIStaticTable() {
    return {
      sortBy: i18n.t('Sort by'),
      kpiParameters: i18n.t('KPI parameters'),
      weight: i18n.t('Weight'),
      previewScore: i18n.t('Preview score'),
      enable: i18n.t('Enable'),
      kpiDisabled: i18n.t('Kpi disabled')
    };
  }
};