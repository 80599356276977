import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-26bb4544"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "card-wrapper"
};
var _hoisted_2 = {
  class: "card card-compliance"
};
var _hoisted_3 = {
  class: "trafficChangeWrap"
};
var _hoisted_4 = {
  class: "trafficlight_placer"
};
var _hoisted_5 = ["href", "title"];
var _hoisted_6 = {
  class: "subtitle"
};
var _hoisted_7 = {
  class: "card-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_trafficlight = _resolveComponent("trafficlight");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_router_link = _resolveComponent("router-link");
  var _component_risma_progress_bar = _resolveComponent("risma-progress-bar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_trafficlight, {
    color: $data.Colors.getTrafficlightByNumber($props.activity.traffic_light).color
  }, null, 8 /* PROPS */, ["color"])]), _createElementVNode("span", {
    class: _normalizeClass("header-placer truncate ".concat($props.activity.traffic_light === 1 ? 'red' : ''))
  }, [!$props.isRouterUse ? (_openBlock(), _createElementBlock("a", {
    key: 0,
    href: $props.activity.url,
    title: $props.activity.title
  }, [_createVNode(_component_risma_title, {
    title: $props.activity.title
  }, null, 8 /* PROPS */, ["title"])], 8 /* PROPS */, _hoisted_5)) : _createCommentVNode("v-if", true), $props.isRouterUse ? (_openBlock(), _createBlock(_component_router_link, {
    key: 1,
    to: $props.activity.url
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $props.activity.title
      }, null, 8 /* PROPS */, ["title"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)]), _createElementVNode("span", _hoisted_6, _toDisplayString($props.activity.project.title), 1 /* TEXT */), _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$truncate($options.stripHtml($props.activity.description), 100)), 1 /* TEXT */), $props.activity.values.questions_count > 0 ? (_openBlock(), _createBlock(_component_risma_progress_bar, {
    key: 0,
    value: $props.activity.values.percentage_questions_answered,
    label: $props.activity.values.questions_answered + ' / ' + $props.activity.values.questions_count + ' ' + $data.translate.questionsAnswered
  }, null, 8 /* PROPS */, ["value", "label"])) : _createCommentVNode("v-if", true)])]);
}