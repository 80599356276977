function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import 'regenerator-runtime/runtime'; //regenerator runtime fix related to i18n (and maybe another modules)
import { createApp } from 'vue';
import { createPinia, storeToRefs } from 'pinia';
import vueScrollto from 'vue-scrollto';
import { Chart, registerables } from 'chart.js';
import chanel from './chanel.vue';
/* eslint-disable import/extensions */
import '@/assets/css/chanel.css';
import '@/assets/css/flags.css';
/* eslint-enable */
import i18n, { loadi18nMessages } from '@/i18n';
import * as Utils from '@/utils/Utils';
import { useFakeMethods } from '@/api';
import { humioEvent } from '@/api/humio';
import { getRouter } from '@/routes';
import { cacheItem } from '@/routes/storeCache';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useSolutionsStore } from '@/Store/solutionsStore';
import { useTagsStore } from '@/Store/tagsStore';
Chart.register.apply(Chart, _toConsumableArray(registerables));
chanel.router = getRouter();
var pinia = createPinia();
var app = createApp(chanel);
app.use(pinia);
var settingsStore = useSettingsStore();
var _storeToRefs = storeToRefs(settingsStore),
  settings = _storeToRefs.settings;
var userStore = useUserStore();
Promise.all([userStore.loadLoginInfo().then(function (loginInfo) {
  if (loginInfo.userIsLoggedIn) {
    return Promise.all([userStore.load(), useSolutionsStore().load(), useTagsStore().load(), cacheItem('users', function () {
      return useUsersStore().load();
    }, 180), cacheItem('organisations', function () {
      return useOrganisationsStore().load();
    }, 180), cacheItem('activityTypes', function () {
      return useActivityTypesStore().load();
    }, 180)]);
  }
}), cacheItem('settings', function () {
  return settingsStore.load();
}, 180), loadi18nMessages()]).then(function () {
  if (settings.value['app.fake_http_methods']) {
    useFakeMethods();
  }
  app.use(getRouter()).use(vueScrollto);
  app.config.globalProperties.$trans = function (string, values) {
    return i18n.t(string, values);
  };
  app.config.globalProperties.$truncate = function (string, length) {
    return Utils.truncate(string, length);
  };
  app.config.globalProperties.$sanitize = function (text) {
    return Utils.sanitize(text);
  };
  app.config.globalProperties.$debounceTimeout = 3000;
  app.config.globalProperties.$autoSaveDebounceTime = 3000;
  app.config.unwrapInjectedRef = true;
  app.config.warnHandler = function (msg, vm, trace) {
    //warnings are only in dev (they are not in prod)
    var name = vm ? vm.$options.name : 'N/A';
    // eslint-disable-next-line no-console
    console.error(msg, trace, name);
    humioEvent('error', name, msg + trace);
  };
  app.config.errorHandler = function (err, vm) {
    var name = vm ? vm.$options.name : 'N/A';
    // eslint-disable-next-line no-console
    console.error(err);
    humioEvent('error', name, err.stack);
  };
  app.mount('#risma');
});