function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _objectDestructuringEmpty(obj) { if (obj == null) throw new TypeError("Cannot destructure " + obj); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { useTagsStore } from '@/Store/tagsStore';
import { prepareCompactTitleData } from '@/components/Molecules/Actions/ActionsCardsWrapperUtils';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import LoadMoreWrapper from '@/components/Molecules/LoadMoreWrapper';
import StaticTable from '@/components/Molecules/StaticTable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import CompactTitleDataComponent from '@/components/Atoms/CompactTitleDataComponent';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import * as Utils from '@/utils/Utils';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { isSignoffPeriodPending } from '@/utils/controls/utils';
import { getIncidentStateName } from '@/utils/incident';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import * as formatDate from '@/utils/date';
import { ActionVisibleProps, getControlVisibleProps, MotherControlsVisibleProps, getRiskVisibleProps, IncidentVisibleProps } from '@/constants/ActivityVisibleProps';
import { MODULES } from '@/constants/modules';
import { Colors } from '@/Colors';
import { DateFormats } from '@/constants/DateFormats';
import { ControlButtonTypes } from '@/constants/ControlButtonTypes';
import { ControlTypeFields } from '@/constants/ControlTypeFields';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'CompactCard',
  introduction: 'Compact view of activities',
  description: 'Shows a table with activities',
  token: '<compact-card :activities="activities"></compact-card>',
  components: {
    LoadMoreWrapper: LoadMoreWrapper,
    StaticTable: StaticTable,
    PreviewModalLink: PreviewModalLink,
    CompactTitleDataComponent: CompactTitleDataComponent,
    RismaButton: RismaButton,
    TrafficLight: TrafficLight,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  props: {
    module: {
      type: String,
      required: true,
      note: 'Module name'
    },
    activities: {
      type: Array,
      required: true,
      note: 'Activity objects - must all be same type'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma users'
    },
    organisations: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma organisations'
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'A list of all activity types'
    },
    watermarkImage: {
      type: String,
      required: false,
      default: 'none',
      note: 'Url of background watermark image'
    },
    controlButtonType: {
      type: String,
      required: false,
      default: '',
      note: 'Type of button for controls'
    },
    isMotherControls: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is mother controls module'
    },
    isApprovalFlowEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    scoring: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Scoring period for ERM risks'
    },
    riskTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'list of all risk types'
    },
    visibilityStorageName: {
      type: String,
      required: false,
      default: '',
      note: 'Storage name to store hidden columns'
    },
    excludeActivityVisibleProps: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of keys what activityVisibleProps will exclude'
    }
  },
  emits: ['deleteItem', 'updateFromPreviewMode', 'onDismissModal'],
  data: function data() {
    return {
      Colors: Colors,
      MODULES: MODULES,
      ControlButtonTypes: ControlButtonTypes,
      isHeadersTruncated: false,
      translate: getTranslate['CompactCard']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), mapState(useTagsStore, ['tags'])), {}, {
    attrs: function attrs() {
      var _ref = this.$attrs || {},
        listeners = _extends({}, (_objectDestructuringEmpty(_ref), _ref));
      delete listeners.class;
      return listeners;
    },
    activityVisibleProps: function activityVisibleProps() {
      var _this = this;
      var props;
      if (this.isMotherControls) {
        props = MotherControlsVisibleProps;
      } else if (this.module === MODULES.INCIDENTS) {
        var _this$activities$;
        var hasArchived = (_this$activities$ = this.activities[0]) === null || _this$activities$ === void 0 ? void 0 : _this$activities$.archived;
        props = hasArchived ? IncidentVisibleProps : IncidentVisibleProps.filter(function (item) {
          return item.key !== 'incidentArchived';
        });
      } else if (this.module === MODULES.CONTROLS) {
        props = getControlVisibleProps(this.getSettingValue);
      } else if (this.module === MODULES.RISK && this.isApprovalFlowEnabled) {
        var _this$activities$2;
        props = getRiskVisibleProps(this.getSettingValue, (_this$activities$2 = this.activities[0]) === null || _this$activities$2 === void 0 ? void 0 : _this$activities$2.projectId, this.currentUser);
      } else if (this.module === MODULES.RISK && !this.isApprovalFlowEnabled) {
        var _this$activities$3;
        props = getRiskVisibleProps(this.getSettingValue, (_this$activities$3 = this.activities[0]) === null || _this$activities$3 === void 0 ? void 0 : _this$activities$3.projectId).filter(function (item) {
          return item.key !== 'approvalStatus';
        }, this.currentUser);
      } else {
        props = ActionVisibleProps;
      }
      return props.filter(function (prop) {
        return !_this.excludeActivityVisibleProps.includes(prop.key);
      });
    },
    columns: function columns() {
      var _this2 = this;
      return this.activityVisibleProps.map(function (item) {
        return _this2.$trans(item.text);
      });
    },
    dataset: function dataset() {
      var _this3 = this;
      var result = [];
      result = this.activities.map(function (activity) {
        var preparedItem = {};
        _this3.activityVisibleProps.forEach(function (column) {
          preparedItem[column.key] = _this3.prepareCompactHtml(column.key, activity);
        });
        return preparedItem;
      });
      return result;
    },
    visibilityDefaultColumns: function visibilityDefaultColumns() {
      var _this4 = this;
      return this.activityVisibleProps.filter(function (item) {
        return item.default;
      }).map(function (item) {
        return _this4.$trans(item.text);
      });
    }
  }),
  methods: {
    prepareCompactHtml: function prepareCompactHtml(column, activity) {
      var result = '';
      switch (column) {
        case 'title':
          result = activity.title;
          break;
        case '_custom-title':
          if (this.module === MODULES.EXECUTION) {
            result = prepareCompactTitleData(activity);
            break;
          }
          if (this.module === MODULES.CONTROLS) {
            result = this.prepareCompactTitleForControl(activity);
            break;
          }
          if (this.module === MODULES.RISK) {
            result = {
              data: _objectSpread(_objectSpread({}, activity), {}, {
                tableDisplayTitle: activity.displayHeadline,
                hoverTitle: DatatableRenderer.getItemHoverTitle(activity)
              })
            };
            break;
          }
          if (this.module === MODULES.INCIDENTS) {
            result = {
              data: _objectSpread(_objectSpread({}, activity), {}, {
                tableDisplayTitle: "".concat(activity.path, ". ").concat(activity.title),
                hoverTitle: DatatableRenderer.getItemHoverTitle(activity)
              })
            };
            break;
          }
          result = this.prepareTitle(activity);
          break;
        case 'organisations':
        case 'organisationIds':
          result = Utils.findPropsByIds(activity.organisations || activity.organisationIds, this.organisations, 'visible_name');
          break;
        case 'tags':
          result = this.prepareTags(activity);
          break;
        case 'responsible':
          result = Utils.findPropsByIds(activity.userIds.responsible, this.users, 'display_name') || this.translate.none;
          break;
        case 'responsibleUserIds':
          result = Utils.findPropsByIds(activity.responsibleUserIds, this.users, 'display_name') || this.translate.none;
          break;
        case 'accountableUserIds':
          result = Utils.findPropsByIds(activity.accountableUserIds, this.users, 'display_name');
          break;
        case 'informedUserIds':
          result = Utils.findPropsByIds(activity.informedUserIds, this.users, 'display_name');
          break;
        case 'reviewers':
          result = Utils.findPropsByIds(activity.userIds.review, this.users, 'display_name');
          break;
        case 'primaryOwner':
          result = Utils.findPropById(activity.userIds.accountable, this.users, 'display_name');
          break;
        case 'dailyOwner':
          result = Utils.findPropById(activity.userIds.responsible, this.users, 'display_name');
          break;
        case 'escalation':
          result = Utils.findPropsByIds(activity.userIds.escalation, this.users, 'display_name');
          break;
        case 'activityType':
          result = this.getActivityTypeLabel(activity.activityType || activity.activityTypeId);
          break;
        case 'accounts':
          result = Utils.findProps(activity.accounts, 'title');
          if (!result) result = this.translate.none;
          break;
        case 'risks':
          result = Utils.findProps(activity.risks, 'title');
          if (!result) result = this.translate.none;
          break;
        case 'processes':
          result = Utils.findProps(activity.processes, 'title');
          if (!result) result = this.translate.none;
          break;
        case ControlTypeFields.KEY_CONTROL:
          result = activity[ControlTypeFields.KEY_CONTROL] ? this.translate.yes : this.translate.no;
          break;
        case ControlTypeFields.KEY_AUTOMATIC_MANUAL:
          result = activity[ControlTypeFields.KEY_AUTOMATIC_MANUAL] ? this.translate.automatic : this.translate.manual;
          break;
        case ControlTypeFields.KEY_PREVENTIVE_DETECTIVE:
          result = activity[ControlTypeFields.KEY_PREVENTIVE_DETECTIVE] ? this.translate.preventive : this.translate.detective;
          break;
        case ControlTypeFields.KEY_HIGH_LOW_COMPLEXITY:
          result = activity[ControlTypeFields.KEY_HIGH_LOW_COMPLEXITY] ? this.translate.high : this.translate.low;
          break;
        case 'controlDeadline':
          result = formatDate.prepareDateTimezone(activity.deadline, activity.deadlineTimezone, DateFormats.DATE_TIME_FORMAT);
          break;
        case 'reviewDeadline':
          result = activity.userIds && activity.userIds.review && activity.userIds.review.length ? formatDate.prepareDateTimezone(activity.reviewDeadline, activity.deadlineTimezone, DateFormats.DATE_TIME_FORMAT) : '';
          break;
        case 'frequency':
          result = this.$trans(RismaControlsUtils.frequency2text(activity.frequency));
          break;
        case 'rismaState':
          result = this.getActivityStatus(activity);
          break;
        case 'approvalStatus':
          result = activity.approved ? this.translate.yes : this.translate.no;
          break;
        case '_custom-button':
          result = this.prepareCompactButton(activity);
          break;
        case 'likelihood':
          result = this.scoring.likelihood_values[activity.likelihood_score];
          break;
        case 'riskType':
          result = Utils.findPropById(activity.riskTypeId, this.riskTypes, 'title');
          break;
        case 'threat':
          result = activity._threatValueAsString;
          break;
        case 'levelOfThreat':
          result = activity._threatLevelValueAsString;
          break;
        case 'vulnerability':
          result = activity._vulnerabilityValueAsString;
          break;
        case 'severity':
          if (activity.severity === null) return null;
          result = {
            color: Colors.findIncidentSeverity(activity.severity, true).color
          };
          break;
        case 'createdAt':
          result = formatDate.toLocalDateTime(activity.createdAt);
          break;
        case 'incidentHappenedAt':
          result = formatDate.toLocalDateTime(activity.incidentHappenedAt) || this.translate.notSet;
          break;
        case 'incidentArchived':
          result = formatDate.toLocalDateTime(activity.updatedAt);
          break;
        case 'status':
        case 'description':
        case 'target':
        case 'comment':
          result = this.showOptionalField(activity, column) && activity[column] ? activity[column] : '';
          break;
        default:
          result = activity[column] || '';
      }
      return result;
    },
    showOptionalField: function showOptionalField(activity, field) {
      var _activityType$optiona;
      if (activity.objType !== ObjectTypes.EXECUTION_INITIATIVE && activity.objType !== ObjectTypes.EXECUTION_INITIATIVE_LIMITED) {
        return true;
      }
      var activityType = this.activityTypes.find(function (at) {
        return +at.id === +activity.activityType;
      });
      if (!activityType) return true;
      return (_activityType$optiona = activityType.optionalFields) === null || _activityType$optiona === void 0 ? void 0 : _activityType$optiona.find(function (item) {
        return item === field;
      });
    },
    getActivityStatus: function getActivityStatus(activity) {
      switch (activity.objType) {
        case ObjectTypes.CONTROLS_CONTROL:
        case ObjectTypes.CONTROLS_CONTROL_LIMITED:
          return this.$trans(RismaControlsUtils.state2text(activity.rismaState));
        case ObjectTypes.INCIDENTS_INCIDENT:
        case ObjectTypes.INCIDENTS_INCIDENT_LIMITED:
          return getIncidentStateName(activity.rismaState).name;
        default:
          return activity.rismaState;
      }
    },
    getActivityTypeLabel: function getActivityTypeLabel(id) {
      if (id === 0) return '';
      var activityType = this.activityTypes.find(function (type) {
        return type.id === id;
      });
      return activityType ? activityType.label : '';
    },
    extractResponsible: function extractResponsible(ids) {
      return Utils.findPropsByIds(ids, this.users, 'display_name');
    },
    prepareTags: function prepareTags(activity) {
      if (!Object.prototype.hasOwnProperty.call(activity, 'tags')) {
        return Utils.findPropsByIds(activity.tagIds, this.tags, 'tag');
      }
      if (activity.tags && activity.tags.length) {
        return activity.tags.map(function (item) {
          return item.tag;
        }).join(', ');
      }
    },
    getVisibilityStorageName: function getVisibilityStorageName() {
      return this.visibilityStorageName || this.module + '_hidden_columns';
    },
    getWatermarkImageUrl: function getWatermarkImageUrl() {
      var type = this.module;
      return "/assets/svg/ikon_".concat(type, ".svg");
    },
    prepareTitle: function prepareTitle(activity) {
      return {
        url: activity.url,
        path: activity.path,
        title: activity.title
      };
    },
    prepareRiskTitle: function prepareRiskTitle(activity) {
      var additionalCSS = activity.closed ? 'line-through' : '';
      return {
        additionalCSS: additionalCSS,
        url: activity.url,
        title: activity.displayHeadline
      };
    },
    prepareCompactTitleForControl: function prepareCompactTitleForControl(item) {
      var trafficColor = Colors.getTrafficlightByNumber(item.trafficLight).color;
      var hasTrafficLight = !item._withoutTrafficLight && !item.isMothercontrol; // Custom prop
      var additionalCSS = item.trafficLight === 1 && hasTrafficLight ? 'text-red-50 ' : '';
      additionalCSS += !item.active ? 'line-through' : '';
      var result = _objectSpread(_objectSpread({}, item), {}, {
        showPreview: true,
        hasTrafficLight: hasTrafficLight,
        additionalCSS: additionalCSS,
        color: trafficColor,
        url: RismaControlsUtils.getControlLink(item),
        title: RismaControlsUtils.getControlTitle(item),
        hoverTitle: ''
      });
      result.hoverTitle = DatatableRenderer.getItemHoverTitle(item, result.title);
      return result;
    },
    prepareCompactButton: function prepareCompactButton(control) {
      if (!this.controlButtonType) return '';
      if (this.controlButtonType === ControlButtonTypes.REVIEW_CONTROL) return this.prepareCompactButtonReview(control);
      return this.prepareButton(control);
    },
    prepareCompactButtonReview: function prepareCompactButtonReview(item) {
      return {
        text: this.translate.review,
        id: item.id,
        cpath: item.cpath,
        actionType: ControlButtonTypes.REVIEW_CONTROL,
        completeAgain: undefined,
        customButton: true,
        emit: 'onReviewButtonClick'
      };
    },
    prepareButton: function prepareButton(control) {
      if (!isSignoffPeriodPending(control.signoffStart, control.frequency, control.trafficLight, this.getSettingValue)) {
        return this.prepareCompactButtonComplete(control);
      }
      return this.prepareCompactButtonSignoffPending();
    },
    prepareCompactButtonComplete: function prepareCompactButtonComplete(item) {
      var isCompleteAgain = this.module.buttonType === ControlButtonTypes.COMPLETE_CONTROL_AGAIN ? 1 : 0;
      return {
        text: this.translate.completion,
        id: item.id,
        cpath: item.cpath,
        actionType: ControlButtonTypes.COMPLETE_CONTROL,
        completeAgain: isCompleteAgain,
        customButton: true,
        emit: 'onCompleteButtonClick'
      };
    },
    prepareCompactButtonSignoffPending: function prepareCompactButtonSignoffPending() {
      var text = this.translate.signoffPeriodPending;
      return {
        customButton: false,
        text: text
      };
    },
    onCustomButtonClick: function onCustomButtonClick(data) {
      if (data.emit) {
        this.$emit(data.emit, data);
      }
    }
  }
};