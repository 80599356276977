export var percentageNormalizer = function percentageNormalizer(dataset, precision, numberField) {
  var activeField = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  // sum of visible values in bars
  var sum = dataset.reduce(function (acc, cur) {
    return !activeField || cur[activeField] ? acc + cur[numberField] : acc;
  }, 0);
  var normalizedMap = new Map();
  var diff = null;
  dataset.forEach(function (item) {
    if (!activeField || item[activeField]) {
      if (item[numberField] === 0) {
        normalizedMap.set(item, '0%');
        return;
      }
      var percentage = +(item[numberField] / sum * 100).toFixed(precision + 1) + diff;
      var roundedPercentage = +percentage.toFixed(precision) === percentage ? +percentage.toFixed(0) : +percentage.toFixed(precision);
      diff = percentage - roundedPercentage;
      normalizedMap.set(item, roundedPercentage + '%');
    }
  });
  return normalizedMap;
};