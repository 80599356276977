import { Http, HttpLegacy } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getFileById(id) {
  return handleRequestResponse(Http.get("/files/".concat(id)));
}
export function editFile(data) {
  return handleRequestResponse(Http.patch("/files/".concat(data.id), data));
}
export function getFiles(params) {
  return handleRequestResponse(Http.get('/files', {
    params: params
  }));
}
export function uploadFiles(params, config) {
  return handleRequestResponse(Http.post('/files', params, config));
}
export function deleteFile(id) {
  return handleRequestResponse(Http.delete("/files/".concat(id)));
}

// Risma1 API
export function deleteFileLegacy(id) {
  var formData = new FormData();
  formData.append('id', id);
  return handleRequestResponse(HttpLegacy({
    method: 'post',
    url: '/attachments/delete',
    data: formData
  }));
}
export function searchFiles(params) {
  return handleRequestResponse(Http.get('/find/files' + params));
}