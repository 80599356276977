function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import InputField from '@/components/Atoms/Inputs/InputField';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import RiskModule from '@/components/Organisms/RiskModule';
import QuestionnaireStats from '@/components/Molecules/Compliance/QuestionnaireStats';
import LinkedCompany from '@/components/Molecules/LinkedCompany/LinkedCompany';
import LinkCompany from '@/components/Molecules/LinkedCompany/LinkCompany';
import RiskAssessmentsIsms from '@/components/Organisms/Risk/RiskAssessmentsIsms';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RiskGrid from '@/components/Organisms/RiskGrid';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { generateComputed } from '@/utils/base/generateComputed';
import { MODULES } from '@/constants/modules';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { ActivityTypeMixin } from '@/mixins/ActivityTypeMixin';
import { InformationAssetsCommonMixin } from '../InformationAssetsCommonMixin';
import { CustomFieldsMixin } from '@/mixins/CustomFieldsMixin';
import { RiskAssessmentMixin } from '@/mixins/RiskAssessmentMixin';
import InformationAssetsRiskGridMixin from '@/components/Pages/InformationAssets/InformationAssetsRiskGridMixin';
import { LinkedCompanyMixin } from '@/mixins/LinkedCompanyMixin';
export default {
  name: 'InformationAssetsControllerDetails',
  components: {
    RismaTitle: RismaTitle,
    InputField: InputField,
    RiskModule: RiskModule,
    TinyMceBox: TinyMceBox,
    ActivityCustomFields: ActivityCustomFields,
    QuestionnaireStats: QuestionnaireStats,
    RiskAssessmentsIsms: RiskAssessmentsIsms,
    SingleSelect: SingleSelect,
    RiskGrid: RiskGrid,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    LinkedCompany: LinkedCompany,
    LinkCompany: LinkCompany
  },
  mixins: [InformationAssetsCommonMixin, ActivityTypeMixin, CustomFieldsMixin, RiskAssessmentMixin, InformationAssetsRiskGridMixin, LinkedCompanyMixin],
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  emits: ['propertyChanged', 'companyStatusChange'],
  data: function data() {
    return {
      type: AssetsTypes.CONTROLLERS,
      module: MODULES.COMPLIANCE_IA_CONTROLLER,
      pageLoaded: false,
      translate: getTranslate['InformationAssetsControllerDetails']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), generateComputed(['address', 'description', 'countryCode', 'email', 'phoneNumber', 'vatNo', 'regNo', 'companyId'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    ownerId: function ownerId() {
      return this.currentUser.id;
    },
    showRiskOnControllers: function showRiskOnControllers() {
      return !!this.getSettingValue('feature.risk_assessment_on_controllers');
    },
    riskProjectId: function riskProjectId() {
      return this.getSettingValue('feature.controllers_r_projects_id');
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      var promises = [this.getRiskData(), this.getRisksData(), this.getCompany()];
      if (this.showRtoRpoOnAssets) {
        promises.push(this.getRtoFields(), this.getRpoFields());
      }
      Promise.all(promises).then(function () {
        return _this.pageLoaded = true;
      });
    }
  }
};