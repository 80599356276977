function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
export function updateUser(data, id) {
  return handleRequestResponse(Http.patch("admin/users/user/".concat(id), data)).then(function (user) {
    useUsersStore().update(user);
    useUserStore().updateUser(user);
    return user;
  });
}
export function createNewUser(data) {
  return handleRequestResponse(Http.post('admin/users/user', data)).then(function (data) {
    useUsersStore().add(data);
    return data;
  });
}
export function changeUserActiveStatus(id, data) {
  var previousData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return handleRequestResponse(Http.delete("admin/users/user/".concat(id), {
    data: data
  })).then(function (data) {
    var finalData = _objectSpread(_objectSpread({}, previousData), data);
    useUsersStore().update(finalData);
    return finalData;
  });
}
export function getUsers(query) {
  return handleRequestResponse(Http.get('admin/users/user' + (query ? "?".concat(query) : '')));
}