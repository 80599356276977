import MonthlyOverview from '@/components/Molecules/MonthlyOverview';
export default {
  components: {
    MonthlyOverview: MonthlyOverview
  },
  data: function data() {
    return {
      dataset: [0, 2, 3, 56, 1333, 43, 53, 0, 0, 344444444777744]
    };
  }
};