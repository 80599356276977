function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import { getAll } from '@/api/compliance/gaps';
import { getGapDashboard as _getGapDashboard } from '@/api/compliance/dashboard';
import { getAllGapSchemasByProjectId } from '@/api/compliance/gapschema';
import { Colors } from '@/Colors';
import { domainTemplate, ragToLabel } from '@/constants/compliance/GapDashboard';
import GapDashboardData from '@/models/compliance/GapDashboardData';
import { sortArrayByAnotherArray } from '@/utils/sort';
import { getErrorMessage } from '@/utils/handleAPIErrors';
export var ComplianceDashboardMixin = {
  data: function data() {
    return {
      sliceSelected: {},
      gapSchemas: [],
      nodes: [],
      gapData: null,
      gapDataMap: {},
      loading: true,
      newGapData: null,
      loadingStats: true,
      loadingGaps: false,
      errorMessage: '',
      gapDataDownloadedAtOnce: false,
      currentFilter: null,
      lastAppliedFilters: {},
      includeChildToggleOption: true
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useTagsStore, ['tags'])), {}, {
    predefinedFilters: function predefinedFilters() {
      var _this = this;
      var filters = {};
      if (this.filterString) {
        var filterStrings = this.filterString.split('&');
        filterStrings.forEach(function (filterString) {
          var _filterString$split = filterString.split('='),
            _filterString$split2 = _slicedToArray(_filterString$split, 2),
            key = _filterString$split2[0],
            val = _filterString$split2[1];
          key = key.substring(7, key.length - 3);

          // Select all should not be here
          if (val === '0') return;
          if (filters[key] === undefined) {
            filters[key] = [];
          }
          if (key === 'analysisRAG') {
            var _Colors$findGapByLabe = Colors.findGapByLabel(val),
              id = _Colors$findGapByLabe.number,
              color = _Colors$findGapByLabe.color,
              label = _Colors$findGapByLabe.label;
            val = {
              id: id,
              color: color,
              label: _this.$trans(label)
            };
          }
          if (key === 'nodeIds') {
            if (!_this.allNodeIds.includes(+val)) return;
          }
          filters[key].push(val);
        });
      }
      return filters;
    },
    predefinedNodesFilter: function predefinedNodesFilter() {
      if (this.predefinedFilters['nodeIds'] === undefined) {
        return [];
      } else {
        return this.predefinedFilters['nodeIds'].map(function (item) {
          return {
            id: parseInt(item),
            title: item
          };
        });
      }
    },
    predefinedOrganisationsFilter: function predefinedOrganisationsFilter() {
      if (this.predefinedFilters['organisationIds'] === undefined) {
        return [];
      } else {
        return this.predefinedFilters['organisationIds'].map(function (item) {
          return {
            id: parseInt(item),
            title: item
          };
        });
      }
    },
    predefinedUsersFilter: function predefinedUsersFilter() {
      if (this.predefinedFilters['responsibleUserIds'] === undefined) {
        return [];
      } else {
        return this.predefinedFilters['responsibleUserIds'].map(function (item) {
          return {
            id: parseInt(item),
            title: item
          };
        });
      }
    },
    predefinedTagsFilter: function predefinedTagsFilter() {
      var _this2 = this;
      if (this.predefinedFilters['tagIds'] === undefined) return [];
      return this.predefinedFilters['tagIds'].map(function (item) {
        return _this2.tags.find(function (tag) {
          return +tag.id === +item;
        });
      }).filter(function (item) {
        return item;
      });
    },
    gapDashboardData: function gapDashboardData() {
      var _this3 = this;
      var gaps = this.gapData || [];
      if (this.gapDataDownloadedAtOnce) {
        gaps = gaps.filter(function (gap) {
          return gap.analysis_rag === _this3.sliceSelected.number;
        });
      }
      return new GapDashboardData(this.nodes, gaps, this.gapSchemas);
    },
    formattedGaps: function formattedGaps() {
      var _this4 = this;
      if (!this.gapDashboardData.nodesWithSelectedGaps) return [];
      var allGaps = [];
      this.gapDashboardData.nodesWithSelectedGaps.forEach(function (node) {
        _this4.addOrganisationToNode(node);
        node.gaps.forEach(function (gap) {
          gap.node = node;
        });
        allGaps.push.apply(allGaps, _toConsumableArray(node.gaps));
      });
      return allGaps;
    },
    gapTypesBarChart: function gapTypesBarChart() {
      var _this5 = this;
      if (!this.newGapData || !this.newGapData.byType || !this.newGapData.gapTypes) {
        return {
          datasets: [],
          labels: []
        };
      }
      var data = {};
      Object.keys(this.newGapData.gapTypes).forEach(function (gapKey) {
        data[gapKey] = _this5.newGapData.byType[gapKey] || 0;
      });
      var dataSorted = sortArrayByAnotherArray(Object.keys(data), Object.keys(this.newGapData.byType)).map(function (id) {
        return data[id];
      });
      var labelsSorted = sortArrayByAnotherArray(Object.keys(this.newGapData.gapTypes), Object.keys(this.newGapData.byType)).map(function (id) {
        return _this5.newGapData.gapTypes[id];
      });
      var datasets = {
        data: dataSorted,
        backgroundColor: '#4eb1b2'
      };
      return {
        labels: labelsSorted,
        datasets: [datasets]
      };
    },
    gapOverviewData: function gapOverviewData() {
      var data = [];
      var backgroundColor = [];
      this.gapOverview.forEach(function (item) {
        data.push(item.value);
        backgroundColor.push(item.color);
      });
      return [{
        data: data,
        backgroundColor: backgroundColor,
        total: data.reduce(function (total, item) {
          return total + item;
        }, 0)
      }];
    },
    gapOverviewLabels: function gapOverviewLabels() {
      var _this6 = this;
      return this.gapOverview.map(function (item) {
        return _this6.$trans(item.title);
      });
    },
    gapOverview: function gapOverview() {
      if (!this.newGapData || !this.newGapData.byGap) {
        return [];
      }
      var overview = [];
      var counts = this.newGapData.byGap;
      Object.keys(counts).forEach(function (rag) {
        if (counts[rag] !== 0) {
          overview.push({
            title: ragToLabel[rag],
            traffic_light: parseInt(rag),
            value: counts[rag],
            color: Colors.gaps[rag].color
          });
        }
      });
      return overview;
    },
    pieSliceColors: function pieSliceColors() {
      var gapOverview = this.filteredOverview || this.gapOverview;
      return gapOverview.map(function (item) {
        return item.color;
      });
    },
    gapDomains: function gapDomains() {
      return this.getGapByDomainData(this.gapFilter, this.newGapData);
    },
    isTableShown: function isTableShown() {
      return !!(this.formattedGaps.length && Number.isInteger(this.sliceSelected.number));
    },
    isChildIncluded: function isChildIncluded() {
      return this.includeChildToggleOption;
    },
    selectedNodeIds: function selectedNodeIds() {
      var _this$lastAppliedFilt;
      return ((_this$lastAppliedFilt = this.lastAppliedFilters.filter) === null || _this$lastAppliedFilt === void 0 ? void 0 : _this$lastAppliedFilt.nodes.map(function (node) {
        return node.id;
      })) || [];
    },
    selectedOrganisationIds: function selectedOrganisationIds() {
      var _this$lastAppliedFilt2;
      return ((_this$lastAppliedFilt2 = this.lastAppliedFilters.filter) === null || _this$lastAppliedFilt2 === void 0 ? void 0 : _this$lastAppliedFilt2.organisations.map(function (organisations) {
        return organisations.id;
      })) || [];
    },
    selectedUserIds: function selectedUserIds() {
      var _this$lastAppliedFilt3;
      return ((_this$lastAppliedFilt3 = this.lastAppliedFilters.filter) === null || _this$lastAppliedFilt3 === void 0 ? void 0 : _this$lastAppliedFilt3.users.map(function (user) {
        return user.id;
      })) || [];
    },
    selectedTagIds: function selectedTagIds() {
      var _this$lastAppliedFilt4;
      return ((_this$lastAppliedFilt4 = this.lastAppliedFilters.filter) === null || _this$lastAppliedFilt4 === void 0 || (_this$lastAppliedFilt4 = _this$lastAppliedFilt4.tags) === null || _this$lastAppliedFilt4 === void 0 ? void 0 : _this$lastAppliedFilt4.map(function (tag) {
        return tag.id;
      })) || [];
    },
    childNodeIds: function childNodeIds() {
      return this.isChildIncluded ? this.getChildNodeIds(this.selectedNodeIds, this.nodes) : [];
    },
    filterString: function filterString() {
      return this.lastAppliedFilters.filterString || '';
    },
    filterStringWithChildNodes: function filterStringWithChildNodes() {
      var filterString = this.lastAppliedFilters.filterString || '';
      if (this.childNodeIds.length) {
        this.childNodeIds.forEach(function (nodeId) {
          filterString += '&filter[nodeIds][]=' + nodeId;
        });
      }
      return filterString;
    }
  }),
  mounted: function mounted() {
    this.setFiltersFromLocalStorage();
  },
  methods: {
    fetchData: function fetchData(filterObj) {
      var _this$lastAppliedFilt5;
      if (filterObj) {
        this.lastAppliedFilters = filterObj;
      }
      this.saveState({
        filterObj: this.lastAppliedFilters,
        includeChildNodes: this.isChildIncluded
      });
      var filterString = ((_this$lastAppliedFilt5 = this.lastAppliedFilters) === null || _this$lastAppliedFilt5 === void 0 ? void 0 : _this$lastAppliedFilt5.filterString) || '';
      if (this.isChildIncluded && this.childNodeIds) {
        filterString += '&filter[includeUnderlyingNodes]=true';
      }
      this.currentFilter = filterString;
      this.loadDashboardData(filterString);
    },
    clearSliceSelected: function clearSliceSelected() {
      this.sliceSelected.number = null;
      this.sliceSelected.label = '';
      this.sliceSelected.color = '';
      this.sliceSelected.width = '';
    },
    handleClickSlice: function handleClickSlice(index) {
      var _this$getGapDataByIdx = this.getGapDataByIdx(index),
        label = _this$getGapDataByIdx.label,
        number = _this$getGapDataByIdx.number,
        color = _this$getGapDataByIdx.color;
      this.sliceSelected = {
        number: number,
        label: label,
        color: color,
        width: "".concat(this.$el.clientWidth - 30, "px")
      };
      if (!this.gapDataDownloadedAtOnce) {
        var filter = "filter[analysisRAG][]=".concat(label);
        if (this.filterStringWithChildNodes) {
          filter += "&".concat(this.filterStringWithChildNodes);
        }
        if (this.gapDataMap[filter]) {
          this.gapData = this.gapDataMap[filter];
        } else {
          this.getGaps(filter);
        }
      }
    },
    getGaps: function getGaps() {
      var _this7 = this;
      var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      this.loadingGaps = true;
      return getAll(this.projectId, filter).then(function (_ref) {
        var list = _ref.list;
        _this7.gapData = list;
        _this7.gapDataMap[filter] = list;
        _this7.loadingGaps = false;
      });
    },
    getGapDataByIdx: function getGapDataByIdx(idx) {
      var gapByIndex = this.gapOverview[idx];
      if (!gapByIndex) return {};
      return {
        label: gapByIndex.title,
        number: gapByIndex.traffic_light,
        color: gapByIndex.color
      };
    },
    addOrganisationToNode: function addOrganisationToNode(node) {
      var _this8 = this;
      var orgString = '';
      node.organisationIds && node.organisationIds.length > 0 && node.organisationIds.forEach(function (orgId) {
        var org = _this8.orgs.find(function (o) {
          return o.id == orgId;
        });
        if (org) {
          orgString += org.visible_name + ', ';
        }
      });
      node.organisation = orgString.slice(0, orgString.length - 2);
    },
    getGapSchemas: function getGapSchemas() {
      var _this9 = this;
      return getAllGapSchemasByProjectId(this.projectId, '?data[]=articleDetails').then(function (_ref2) {
        var list = _ref2.list;
        return _this9.gapSchemas = list;
      }).catch(function (error) {
        return _this9.handleDashboardError(error);
      });
    },
    getGapDashboard: function getGapDashboard() {
      var _this10 = this;
      var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      return _getGapDashboard(this.projectId, filter).then(function (response) {
        return _this10.newGapData = response;
      }).catch(function (error) {
        return _this10.handleDashboardError(error);
      });
    },
    handleDashboardError: function handleDashboardError(error) {
      var _this11 = this;
      if (error.status === 414 || error.isAxiosError) {
        this.errorMessage = 'You have too many filter parameters selected, please deselect some.';
      } else {
        this.errorMessage = getErrorMessage(error);
      }
      setTimeout(function () {
        _this11.errorMessage = '';
      }, this.$debounceTimeout * 2);
    },
    getGapByDomainData: function getGapByDomainData(filterRags, gapData) {
      var _this12 = this;
      if (!gapData || !gapData.byDomain || !gapData.domains) {
        return {
          datasets: [],
          labels: []
        };
      }
      var finalDomainTemplate = _objectSpread({}, domainTemplate);
      if (filterRags.length > 0) {
        Object.keys(finalDomainTemplate).forEach(function (key) {
          if (!filterRags.includes(+key)) {
            delete finalDomainTemplate[key];
          }
        });
      }
      var datasets = [];
      var gapStats = gapData.byDomain;
      var domainMap = gapData.domains;
      var relevantColors = Colors.gaps.filter(function (gapColor) {
        return filterRags.length === 0 || filterRags.indexOf(gapColor.number) !== -1;
      });
      var colors = relevantColors.map(function (gapColor) {
        return gapColor.color;
      });
      Object.keys(finalDomainTemplate).map(function (key, idx) {
        var datasetObject = {
          label: _this12.$trans(finalDomainTemplate[key]),
          data: Object.values(gapStats).map(function (domainObject) {
            return domainObject[key];
          }),
          backgroundColor: colors[idx]
        };
        datasetObject.data.some(function (item) {
          return !!item;
        }) && datasets.push(datasetObject);
      });
      return {
        datasets: datasets,
        labels: Object.values(domainMap)
      };
    },
    getChildNodeIds: function getChildNodeIds(selectedNodeIds, nodes) {
      var filteredNodes = nodes.filter(function (node) {
        return selectedNodeIds.find(function (id) {
          return node.parentIds.includes(id);
        });
      });
      return filteredNodes.map(function (node) {
        return node.id;
      }).filter(function (id) {
        return !selectedNodeIds.includes(id);
      });
    },
    onIncludeChildNodesToggle: function onIncludeChildNodesToggle(event) {
      this.includeChildToggleOption = event;
      this.fetchData();
    }
  }
};