import DpmSendQuestionnaireModal from '@/components/Molecules/Modal/DpmSendQuestionnaireModal';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { getAllQuestionnaires, getQuestionnaireTypes } from '@/api/compliance/questionnaire';
export default {
  components: {
    RismaButton: RismaButton,
    DpmSendQuestionnaireModal: DpmSendQuestionnaireModal
  },
  data: function data() {
    return {
      newProcesses: [],
      allQuestionnaireTypes: {},
      allQuestionnaires: {},
      processorType: '',
      showQuestionnaireModal: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    getQuestionnaireTypes().then(function (response) {
      _this.allQuestionnaireTypes = response.list;
    });
    getAllQuestionnaires().then(function (response) {
      _this.allQuestionnaires = response.list;
    }).then(function () {
      return _this.filterToProcessors();
    });
  },
  methods: {
    showModal: function showModal() {
      this.showQuestionnaireModal = true;
    },
    filterToProcessors: function filterToProcessors() {
      var _this2 = this;
      this.processorType = this.allQuestionnaireTypes.filter(function (element) {
        return element.type === 'IA_DATAPROCESSOR';
      })[0].id;
      this.allQuestionnaires = this.allQuestionnaires.filter(function (element) {
        return element.questionnaireType === _this2.processorType;
      });
      this.allQuestionnaires.forEach(function (element) {
        return _this2.newProcesses.push({
          value: element.id,
          label: element.title
        });
      });
    },
    getData: function getData(data) {
      alert(JSON.stringify(data));
    },
    dismissQuestionnaireModal: function dismissQuestionnaireModal() {
      this.showQuestionnaireModal = false;
    }
  }
};