import { ControlTypeFields } from './ControlTypeFields';
export var ControlVisibleProps = [{
  key: '_custom-title',
  // Title with path & trafficLight (always visible)
  text: 'Title',
  default: true
}, {
  key: 'description',
  text: 'Description',
  default: true
}, {
  key: 'expectedEvidence',
  text: 'Expected Evidence'
}, {
  key: 'risks',
  text: 'Risk'
}, {
  key: 'accounts',
  text: 'Accounts',
  default: true
}, {
  key: 'processes',
  text: 'Process',
  default: true
}, {
  key: 'frequency',
  text: 'Frequency'
}, {
  key: ControlTypeFields.KEY_CONTROL,
  text: 'Key control'
}, {
  key: ControlTypeFields.KEY_AUTOMATIC_MANUAL,
  text: 'Manual vs automated'
}, {
  key: ControlTypeFields.KEY_PREVENTIVE_DETECTIVE,
  text: 'Preventive vs detective'
}, {
  key: ControlTypeFields.KEY_HIGH_LOW_COMPLEXITY,
  text: 'Complexity'
}, {
  key: 'userIds.responsible',
  text: 'Responsible',
  default: true
}, {
  key: 'userIds.review',
  text: 'Reviewers'
}, {
  key: 'userIds.escalation',
  text: 'Escalation'
}, {
  key: 'organisationIds',
  text: 'Organisations'
}, {
  key: 'deadline',
  text: 'Deadline',
  default: true
},
// Ask Laus about newDeadline
// {
//     key: 'newDeadline',
//     text: 'New deadline'
// },
// {
//     key: 'completed',
//     text: 'Completed'
// },
{
  key: 'reviewDeadline',
  text: 'Review deadline'
}, {
  key: 'rismaState',
  text: ' Control status'
}, {
  key: 'tags',
  text: 'Tags',
  default: true
}, {
  key: '_custom-button',
  // Button
  text: 'Action',
  default: true
}];