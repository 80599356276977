import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0c4184be"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "relative"
};
var _hoisted_2 = {
  class: "flex justify-between"
};
var _hoisted_3 = {
  class: "flex items-center"
};
var _hoisted_4 = {
  key: 0,
  class: "flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_tile_card = _resolveComponent("tile-card");
  var _component_load_more_wrapper = _resolveComponent("load-more-wrapper");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "draggable", {}, undefined, true), $props.title ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $options.fullTitle,
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
    class: "flex items-center pt-1 cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.changeShowState(null);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-down",
    width: "30",
    height: "30",
    class: _normalizeClass(['transform duration-500 text-gray-610 mr-1 stroke-2', {
      'rotate-180': $data.isShowAll
    }])
  }, null, 8 /* PROPS */, ["class"]), _createElementVNode("span", null, _toDisplayString($options.showStateTitle), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true)]), _renderSlot(_ctx.$slots, "filter", {
    isShowAll: $data.isShowAll
  }, undefined, true)]), _withDirectives(_createVNode(_component_load_more_wrapper, {
    activities: $props.activities
  }, {
    default: _withCtx(function (_ref) {
      var activitiesLimited = _ref.activitiesLimited;
      return [_createVNode(_TransitionGroup, {
        name: "fade",
        tag: "div",
        class: "grid grid-cols-4 place-items-auto py-8 -mx-2"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activitiesLimited, function (activity, index) {
            return _openBlock(), _createBlock(_component_tile_card, _mergeProps({
              key: "tile-card-".concat(index, "-").concat(activity.id),
              activity: activity,
              "disable-preview": $props.disablePreview,
              "internal-link-enabled": $props.internalLinkEnabled,
              "watermark-image": typeof $props.watermarkImage === 'function' ? $props.watermarkImage(activity) : $props.watermarkImage,
              "is-approval-flow-enabled": $props.isApprovalFlowEnabled,
              "activity-status": $options.getActivityStatus(activity),
              "activity-types": $props.activityTypes,
              class: "tile-card-item"
            }, _ctx.$attrs), {
              deleteButton: _withCtx(function (slotProps) {
                return [_renderSlot(_ctx.$slots, "deleteButton", {
                  activity: slotProps.activity
                }, undefined, true)];
              }),
              button: _withCtx(function (slotProps) {
                return [_renderSlot(_ctx.$slots, "button", {
                  activity: slotProps.activity
                }, undefined, true)];
              }),
              footer: _withCtx(function (slotProps) {
                return [_renderSlot(_ctx.$slots, "footer", {
                  activity: slotProps.activity
                }, undefined, true)];
              }),
              _: 2 /* DYNAMIC */
            }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["activity", "disable-preview", "internal-link-enabled", "watermark-image", "is-approval-flow-enabled", "activity-status", "activity-types"]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),

        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)];
    }),

    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["activities"]), [[_vShow, $data.isShowAll]])]);
}