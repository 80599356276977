import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'StaticTableRow',
  introduction: 'child component for static-table',
  description: 'This component renders the rows for static-table',
  token: "<static-table-row :item=\"item\">\n              <template v-slot:[name]=\"{slotData}\"></template>\n            </static-table-row>",
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    item: {
      type: Object,
      required: true,
      note: 'The item that the row, should display'
    },
    preventFullPageUpdates: {
      type: Boolean,
      required: false,
      default: false,
      note: 'needed if its user rights table to prevent random full page updates'
    },
    hoverTitles: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'The visible titles that are shown on hover over the table cell '
    },
    additionalRowClassesText: {
      type: String,
      required: false,
      default: '',
      note: 'if you have additionalRowClasses and this row should have mouseover text'
    },
    cellsMaxWidth: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Cells with max width'
    },
    isChildrenIconShown: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Show icon in front of first cell'
    },
    hiddenColumnIndexes: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      },
      note: 'Array of column indexes to hide'
    },
    itemPropsToShowChildren: {
      type: String,
      require: false,
      default: '',
      note: 'Property name that should contain showChildren property that will control children displaying'
    },
    showChildDefaultValue: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  emits: ['click'],
  data: function data() {
    return {
      icon: 'angle-down',
      isChildrenShown: this.showChildDefaultValue
    };
  },
  computed: {
    isEmpty: function isEmpty() {
      return Object.values(this.item).every(function (val) {
        return val === '';
      });
    },
    isHasChildren: function isHasChildren() {
      return this.item.children && this.item.children.length > 0;
    },
    columnsNumber: function columnsNumber() {
      var columns = Object.keys(this.item);
      var columnsWithoutChildren = this.getColumnsWithoutChildren(columns);
      return columnsWithoutChildren.length;
    },
    showChildren: function showChildren() {
      if (this.itemPropsToShowChildren) {
        var _this$item$this$itemP;
        return ((_this$item$this$itemP = this.item[this.itemPropsToShowChildren]) === null || _this$item$this$itemP === void 0 ? void 0 : _this$item$this$itemP.showChildren) || false;
      }
      return this.isChildrenShown;
    },
    firstColData: function firstColData() {
      if (!this.item || !Object.keys(this.item).length) return null;
      var firstKey = Object.keys(this.item)[0];
      return {
        key: firstKey,
        value: this.item[firstKey]
      };
    }
  },
  methods: {
    toggleChildren: function toggleChildren() {
      if (!this.preventFullPageUpdates && !this.itemPropsToShowChildren) {
        this.isChildrenShown = !this.isChildrenShown;
      }
    },
    getHoverTitle: function getHoverTitle(key, val) {
      if (val === '') return '';
      if (this.additionalRowClassesText) {
        return this.additionalRowClassesText;
      }
      if (!this.hoverTitles[key]) {
        return '';
      }
      if (typeof this.hoverTitles[key] === 'function') {
        return this.hoverTitles[key](val);
      }
      return this.hoverTitles[key];
    },
    isTitleKey: function isTitleKey(key) {
      var titleKeys = ['titlePreview', 'title', '_custom-title', 'name'];
      return titleKeys.includes(key);
    },
    getCellStyles: function getCellStyles(key) {
      var value = this.cellsMaxWidth[key];
      if (!value) return '';
      return "width: ".concat(value, "; max-width: ").concat(value, "; word-break: break-word;");
    },
    isCellHidden: function isCellHidden(index) {
      return this.hiddenColumnIndexes.indexOf(index) !== -1;
    },
    getColumnsWithoutChildren: function getColumnsWithoutChildren(columns) {
      return columns.filter(function (column) {
        return column !== 'children';
      });
    }
  }
};