function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useSettingsStore } from '@/Store/settingsStore';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Notification from '@/components/Molecules/Notification';
import { UserLevels } from '@/constants/UserLevels';
export default {
  name: 'CreateInitiativeModal',
  components: {
    Notification: Notification,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    InputField: InputField,
    RismaTitle: RismaTitle,
    Modal: Modal,
    TextBox: TextBox
  },
  introduction: 'Modal for creating initiatives',
  description: "This modal is helps to speed up the process of creating an initiative. when the form is filled out and the create button is pressed\n                      the modal will emit and create event, with the data needed to create an initiative. when closed it also emits a dismiss event, for prop handling",
  token: '<create-initiative-modal :show="true"></create-initiative-modal>',
  props: {
    show: {
      type: Boolean,
      default: false,
      note: 'should the modal be shown?'
    },
    initiatives: {
      required: true,
      type: Array,
      note: 'List of initiatives'
    },
    preset: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      },
      note: 'Preset fields from activity'
    },
    showAllFields: {
      required: false,
      type: Boolean,
      default: false,
      note: 'If to show all available fields'
    }
  },
  emits: ['accept', 'dismiss'],
  data: function data() {
    return {
      errors: [],
      initiativeItem: {
        parent: '',
        responsible: [],
        description: ''
      },
      isRismaSelectOpen: false,
      translate: getTranslate['CreateInitiativeModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), {}, {
    usersWithAccessToActions: function usersWithAccessToActions() {
      return this.users.filter(function (user) {
        return user.is_admin || user.level_execution > UserLevels.NONE;
      });
    },
    formattedInitiative: function formattedInitiative() {
      return {
        title: this.initiativeItem.title || this.preset.title,
        userIds: {
          responsible: this.initiativeItem.responsible.map(function (user) {
            return user.id;
          })
        },
        parent: this.initiativeItem.parent,
        description: this.initiativeItem.description || this.preset.description || '',
        activityType: this.getSettingValue('feature.actions_default_activity_type_id')
      };
    },
    formattedInitiatives: function formattedInitiatives() {
      return this.initiatives.map(function (initiative) {
        return {
          label: "".concat(initiative.ipath, " ").concat(initiative.title),
          value: initiative.id
        };
      });
    },
    defaultInitiative: function defaultInitiative() {
      return this.initiatives.length > 0 ? this.initiatives[0].id : '';
    }
  }),
  mounted: function mounted() {
    this.initiativeItem.parent = this.defaultInitiative;
  },
  beforeUnmount: function beforeUnmount() {
    this.clearSelection();
  },
  methods: {
    setResponsible: function setResponsible(users) {
      this.initiativeItem.responsible = users;
    },
    handleSelection: function handleSelection() {
      this.errors = [];
      if (!this.initiativeItem.title && !this.preset.title) {
        this.errors.push(this.translate.youNeedToFillTitleToContinue);
      }
      if (this.initiativeItem.responsible.length === 0) {
        this.errors.push(this.translate.youNeedToFillResponsibleToContinue);
      }
      if (!this.initiativeItem.parent) {
        this.errors.push(this.translate.pleaseCreateAParentInitiativeInActionsAndT);
      }
      if (this.errors.length > 0) {
        return;
      }
      this.$emit('accept', this.formattedInitiative);
      this.clearSelection();
    },
    handleDismiss: function handleDismiss() {
      this.$emit('dismiss');
    },
    clearSelection: function clearSelection() {
      this.initiativeItem = {
        parent: this.defaultInitiative,
        responsible: [],
        description: ''
      };
      var inputField = this.$el.querySelector('.input-field input');
      if (inputField) {
        this.$el.querySelector('.input-field input').value = '';
      }
    },
    setDescription: function setDescription(e) {
      this.initiativeItem.description = e;
    }
  }
};