import { sortArrayOfObjectsByArrayOfValues } from '@/utils/sort';
export var SaveReportMixin = {
  data: function data() {
    return {
      isReportSaved: false
    };
  },
  methods: {
    loadReportByReplacingQueryParams: function loadReportByReplacingQueryParams(report) {
      this.$router.replace({
        query: {
          query: JSON.stringify({
            query: report.query
          })
        }
      });
    },
    applySettingsFromLoadedReport: function applySettingsFromLoadedReport(data) {
      var state = this.getTableSettings();
      var filters = data.query.filters;
      var columns = data.query.display ? data.query.display.columns : [];
      var sorting = data.query.display ? data.query.display.sorting : {};
      if (!state || !state.columns) return;
      state === null || state === void 0 ? void 0 : state.columns.forEach(function (column) {
        var key = column.key;
        column.visible = !columns.length || columns.includes(key);
        if (filters[key]) {
          column.search = filters[key];
        } else {
          column.search = '';
          column.css = '';
        }
        if (sorting[key]) {
          column.sort = {
            dir: sorting[key],
            index: key
          };
        } else {
          column.sort = null;
        }
      });
      if (state !== null && state !== void 0 && state.columns) {
        state.columns = sortArrayOfObjectsByArrayOfValues(state.columns, columns);
      }
      this.updateRismaDatatableState(state);
    },
    updateRismaDatatableState: function updateRismaDatatableState(state) {
      var datatable = this.$refs.reportsDataTable;
      datatable.resetState();
      datatable.handleStateSafely(state);
      datatable.showAppliedFilters();
    },
    afterReportSave: function afterReportSave() {
      var _this = this;
      this.isReportSaved = true;
      setTimeout(function () {
        _this.isReportSaved = false;
      }, 3000);
    },
    getTableSettings: function getTableSettings() {
      try {
        return JSON.parse(localStorage.getItem(this.stateLocal));
      } catch (e) {
        return null;
      }
    },
    loadSavedReport: function loadSavedReport() {
      var _this$$route$query,
        _this2 = this;
      if (!((_this$$route$query = this.$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.query)) return;
      this.$nextTick(function () {
        return _this2.applySettingsFromLoadedReport(JSON.parse(_this2.$route.query.query));
      });
    },
    resetSavedReportQuery: function resetSavedReportQuery() {
      if (this.savedReportOptions) {
        this.$router.replace({
          query: {}
        });
      }
    }
  }
};