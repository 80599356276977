function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps } from "vue";
var _hoisted_1 = ["title"];
var _hoisted_2 = {
  key: 0,
  class: "mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$nodeActions;
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  var _component_mini_tree_branch = _resolveComponent("mini-tree-branch", true);
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["my-2 relative mini-tree-branch vertical-arrow-last ml-5", [{
      'vertical-arrow-last-selected': $props.parentId === $options.lastIdInSelectedChain || $options.beforeSelected && !$options.isSelected || $props.searchEnabled
    }, {
      'vertical-arrow-last-headless': $options.level > 0 && $options.index === 0 && $options.hasChildren
    }]])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["flex items-center cursor-pointer relative", [{
      'hori-arrow': $options.level > 0 && $options.index === 0
    }, {
      'hori-arrow hori-arrow-on-hidden-children': !$options.hasChildren && !($options.level > 0 && $options.index === 0)
    }, {
      'hori-arrow-selected': $options.isElementHighlighted || $options.beforeSelected && $options.index === 0
    }]]),
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.handleClick && $options.handleClick.apply($options, arguments);
    })
  }, [$options.hasChildren ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(['transform duration-500 stroke-2 mr-2 icon-position', $options.isElementHighlighted || $options.beforeSelected ? 'icon-blue' : 'icon-gray', {
      'rotate-90': $options.isOpened
    }]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
      return _ctx.$emit('update:opened', $props.item);
    }, ["stop"]))
  }, null, 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass([[$options.isElementHighlighted ? 'text-blue-750 font-semibold' : 'text-neutral-500', {
      'bg-blue-60 rounded-md': $options.lastIdInSelectedChain === $props.item.id
    }, {
      'vertical-arrow-first-selected': $options.beforeSelected || $props.parentId === $options.lastIdInSelectedChain || $props.searchEnabled
    }, {
      'first-child-vertical-arrow': $options.index === 0 && $options.level > 0
    }, {
      'vertical-arrow-first': $options.index > 0 || $options.level > 0
    }, {
      'vertical-arrow-first-hidden': $options.index === 0
    }], "w-fit ml-1 flex items-center p-1"]),
    title: $props.item.title
  }, [_createElementVNode("span", null, _toDisplayString($props.item.title), 1 /* TEXT */), $props.item.accessible && $props.item.id === $options.lastIdInSelectedChain && (_$options$nodeActions = $options.nodeActions) !== null && _$options$nodeActions !== void 0 && _$options$nodeActions.length ? (_openBlock(), _createBlock(_component_toggle_menu, {
    key: 0,
    class: "ml-4 font-normal",
    position: "fixed",
    "menu-style": "minimal",
    options: $options.nodeActions
  }, null, 8 /* PROPS */, ["options"])) : _createCommentVNode("v-if", true)], 10 /* CLASS, PROPS */, _hoisted_1)], 2 /* CLASS */), $options.hasChildren && $options.isOpened ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item.children, function (data, ind) {
    return _openBlock(), _createBlock(_component_mini_tree_branch, _mergeProps({
      key: "mini-tree-branch-".concat(data.id, "-").concat(ind),
      path: [].concat(_toConsumableArray($props.path), [ind]),
      "selected-path": $props.selectedPath,
      "opened-array": $props.openedArray,
      item: data,
      "parent-id": $props.item.id,
      "node-emits": $props.nodeEmits,
      "search-enabled": $props.searchEnabled
    }, _ctx.$attrs, {
      "onUpdate:selected": _cache[2] || (_cache[2] = function ($event) {
        return _ctx.$emit('update:selected', [{
          id: $props.item.id,
          index: $options.index
        }].concat(_toConsumableArray($event)));
      }),
      "onUpdate:opened": _cache[3] || (_cache[3] = function ($event) {
        return _ctx.$emit('update:opened', $event);
      })
    }), null, 16 /* FULL_PROPS */, ["path", "selected-path", "opened-array", "item", "parent-id", "node-emits", "search-enabled"]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}