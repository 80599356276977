export default {
  name: 'HelpText',
  introduction: 'Shows text with Show more / less button',
  description: 'Shows text with Show more / less button',
  token: '<help-text text="text" />',
  props: {
    text: {
      type: String,
      required: true,
      note: 'the text'
    },
    countTruncate: {
      type: Number,
      default: 120,
      note: 'Length of string that is always shown'
    }
  },
  data: function data() {
    return {
      isShowFull: false
    };
  },
  computed: {
    isTextTruncatable: function isTextTruncatable() {
      // Uses 3 because adds ... in the end of truncated text
      if (this.text === null) {
        return false;
      }
      return this.text.length > this.countTruncate + 3;
    },
    textTruncated: function textTruncated() {
      return "".concat(this.text.slice(0, this.countTruncate), "...");
    },
    textShown: function textShown() {
      if (!this.isTextTruncatable) {
        return this.text;
      }
      return this.isShowFull ? this.text : this.textTruncated;
    },
    seeMoreOrLess: function seeMoreOrLess() {
      return this.$trans(this.isShowFull ? 'See less' : 'See more');
    }
  }
};