import RismaTree from '@/components/Atoms/RismaTree';
import { treeData } from '@/test/data/rismaTreeData';
export default {
  name: 'RismaTreeKitchen',
  components: {
    RismaTree: RismaTree
  },
  data: function data() {
    return {
      treeData: treeData
    };
  },
  methods: {
    move: function move(_ref) {
      var newTreeData = _ref.newTreeData;
      this.treeData.children = newTreeData;
    }
  }
};