function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { isLockedControlUser } from '@/utils/access';
import { getAccountsWithChildren } from '@/utils/controls/accounts';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import ControlType from '@/components/Molecules/Controls/ControlType';
import Alert from '@/components/Molecules/Modal/Alert';
import ControlDetailsAssociations from '@/components/Molecules/Controls/ControlDetailsAssociations';
import SolutionIdRelationDetails from '@/components/Molecules/SolutionIdRelationDetails';
import ActivityTypeSelector from '@/components/Molecules/ActivityTypeSelector';
export default {
  name: 'ControlRelationsSectionDetails',
  description: 'Controls side bar details section',
  token: '<control-relations-section-details />',
  components: {
    ActivityTypeSelector: ActivityTypeSelector,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    ControlType: ControlType,
    ControlDetailsAssociations: ControlDetailsAssociations,
    SolutionIdRelationDetails: SolutionIdRelationDetails,
    Alert: Alert
  },
  props: {
    control: {
      type: Object,
      required: true
    },
    activityTypes: {
      type: Array,
      required: true,
      note: 'Array of activity types'
    },
    accounts: {
      type: Array,
      required: true,
      note: 'Array of accounts'
    },
    processes: {
      type: Array,
      required: true,
      note: 'Array of processes'
    },
    risks: {
      type: Array,
      required: true,
      note: 'Array of risks'
    },
    motherControls: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of mother controls'
    }
  },
  emits: ['propertyChanged'],
  data: function data() {
    return {
      isMotherConfirmationShow: false,
      selectedMotherControl: null,
      translate: getTranslate['ControlRelationsSectionDetails']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    showControlType: 'showControlType'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    controlsFormatted: function controlsFormatted() {
      return this.motherControls.map(function (item) {
        return {
          label: item.path + '. ' + item.title,
          value: item.cno
        };
      });
    },
    accountsWithChildren: function accountsWithChildren() {
      return getAccountsWithChildren(this.accounts);
    },
    associationAccounts: function associationAccounts() {
      return {
        items: this.accountsWithChildren,
        selectedItems: this.control.accounts
      };
    },
    associationRisks: function associationRisks() {
      return {
        items: this.risks,
        selectedItems: this.control.risks
      };
    },
    associationProcesses: function associationProcesses() {
      return {
        items: this.processes,
        selectedItems: this.control.processes
      };
    },
    inputsDisabled: function inputsDisabled() {
      return !this.control.active || isLockedControlUser(this.currentUser);
    },
    isDaughter: function isDaughter() {
      return this.control.mothercontrolId > 0;
    }
  }),
  methods: {
    activityTypeChanged: function activityTypeChanged(activityType) {
      this.$emit('propertyChanged', {
        property: 'activityTypeId',
        value: activityType
      });
    },
    propertyChanged: function propertyChanged(type) {
      this.$emit('propertyChanged', type);
    },
    associationsChanged: function associationsChanged(associations) {
      this.$emit('propertyChanged', {
        accounts: associations.accounts,
        processes: associations.processes,
        risks: associations.risks
      });
    },
    handleMotherControlChange: function handleMotherControlChange(event) {
      if (event !== null) {
        this.isMotherConfirmationShow = true;
        this.selectedMotherControl = event;
      } else {
        this.propertyChanged({
          property: 'mothercontrolId',
          value: 0
        });
      }
    },
    acceptMotherConfirmation: function acceptMotherConfirmation() {
      this.isMotherConfirmationShow = false;
      this.propertyChanged({
        property: 'mothercontrolId',
        value: this.selectedMotherControl
      });
    }
  }
};