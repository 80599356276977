import i18n from '@/i18n';
export var getTranslate = {
  GanttChart: function GanttChart() {
    return {
      sortBy: i18n.t('Sort by'),
      number: i18n.t('Number'),
      deadline: i18n.t('Deadline'),
      title: i18n.t('Title'),
      from: i18n.t('From'),
      to: i18n.t('To')
    };
  },
  GanttChartCommon: function GanttChartCommon() {
    return {
      sortBy: i18n.t('Sort by'),
      number: i18n.t('Number'),
      deadline: i18n.t('Deadline'),
      title: i18n.t('Title')
    };
  }
};