import { getTranslate } from './translate';
import RismaTitle from '../../../Atoms/Titles/RismaTitle';
export default {
  name: 'CardRisk',
  introduction: '',
  description: '',
  token: '<card-risk :card="card"></card-risk>',
  components: {
    RismaTitle: RismaTitle
  },
  props: {
    activity: {
      type: Object,
      required: true,
      note: 'Risk object'
    }
  },
  data: function data() {
    return {
      translate: getTranslate['CardRisk']()
    };
  }
};