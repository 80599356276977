import i18n from '@/i18n';
export var getTranslate = {
  RiskReportsFilters: function RiskReportsFilters() {
    return {
      period: i18n.t('Period'),
      includeUnderlyingRisks: i18n.t('Include underlying risks'),
      riskAssessment: i18n.t('Risk assessment'),
      selectRiskAssessment: i18n.t('Select risk assessment'),
      threat: i18n.t('Threat'),
      selectThreat: i18n.t('Select threat'),
      levelOfThreat: i18n.t('Level of threat'),
      selectLevelOfThreat: i18n.t('Select level of threat'),
      vulnerability: i18n.t('Vulnerability'),
      selectVulnerability: i18n.t('Select vulnerability'),
      linkedSystems: i18n.t('Linked systems'),
      selectSystem: i18n.t('Select system'),
      responsible: i18n.t('Responsible'),
      selectResponsible: i18n.t('Select responsible'),
      tags: i18n.t('Tags'),
      selectTags: i18n.t('Select tags'),
      organisations: i18n.t('Organisations'),
      selectOrganisations: i18n.t('Select organisations'),
      parentRisk: i18n.t('Parent risk'),
      selectParentRisk: i18n.t('Select parent risk'),
      activityType: i18n.t('Activity type'),
      selectActivityType: i18n.t('Select activity type'),
      resetFilter: i18n.t('Reset filter'),
      createReport: i18n.t('Create report'),
      pleaseSelectOne: i18n.t('Please select one')
    };
  },
  RiskReportsFiltersErm: function RiskReportsFiltersErm() {
    return {
      period: i18n.t('Period'),
      xaxisImpactType: i18n.t('X-Axis, Impact type'),
      yaxisLikelihood: i18n.t('Y-Axis, Likelihood'),
      managementLevel: i18n.t('Management level'),
      selectManagementLevel: i18n.t('Select management level'),
      selectRiskType: i18n.t('Select risk type'),
      dailyRiskOwner: i18n.t('Daily risk owner'),
      selectDailyOwner: i18n.t('Select daily owner'),
      primaryRiskOwner: i18n.t('Primary risk owner'),
      selectPrimaryOwner: i18n.t('Select primary owner'),
      approvalStatus: i18n.t('Approval status'),
      selectApprovalStatus: i18n.t('Select approval status'),
      tags: i18n.t('Tags'),
      selectTags: i18n.t('Select tags'),
      organisations: i18n.t('Organisations'),
      selectOrganisations: i18n.t('Select organisations'),
      riskStatus: i18n.t('Risk status'),
      selectRiskStatus: i18n.t('Select risk status'),
      riskUpdated: i18n.t('Risk updated'),
      selectRiskUpdated: i18n.t('Select risk updated'),
      filterTopNRisks: i18n.t('Filter top N risks'),
      selectTopRisks: i18n.t('Select top risks'),
      customRiskFields: i18n.t('Custom risk fields'),
      select: i18n.t('Select'),
      resetFilter: i18n.t('Reset filter'),
      createReport: i18n.t('Create report'),
      all: i18n.t('All'),
      pleaseSelectOne: i18n.t('Please select one')
    };
  }
};