var _LinkTabTitles, _Tabs;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { ObjectTypes } from '@/constants/ObjectTypes';
export var TabTypes = {
  ASSESSMENT: 'assessment',
  AWARENESS: 'awareness',
  CHANGELOG: 'changelog',
  DATAFLOW: 'dataFlow',
  DESCRIPTION: 'description',
  GAPLINKS: 'gapLinks',
  GAPANALYSIS: 'gapAnalysis',
  HISTORY: 'history',
  LINKS: 'links',
  QUESTIONNAIRE: 'questionnaire',
  DPM: 'dpm',
  AUDIT: 'audit',
  AUDIT_QUESTIONNAIRE: 'auditQuestionnaire',
  CONTRACT_PARTY: 'party',
  RELATED_CONTRACTS: 'relate',
  DOCUMENTS: 'documents',
  CONTACTS: 'contacts',
  RIGHTS: 'rights'
};
export var LinkTabTitles = (_LinkTabTitles = {}, _defineProperty(_LinkTabTitles, ObjectTypes.EXECUTION_INITIATIVE, 'Actions'), _defineProperty(_LinkTabTitles, ObjectTypes.CONTROLS_CONTROL, 'Control'), _defineProperty(_LinkTabTitles, ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE, 'Node'), _defineProperty(_LinkTabTitles, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, 'Asset'), _defineProperty(_LinkTabTitles, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, 'Asset'), _defineProperty(_LinkTabTitles, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, 'Asset'), _defineProperty(_LinkTabTitles, ObjectTypes.INFORMATION_ASSET, 'Asset'), _defineProperty(_LinkTabTitles, ObjectTypes.RISK_RISK, 'Risk'), _defineProperty(_LinkTabTitles, ObjectTypes.COMPLIANCE_NODE, 'Node'), _defineProperty(_LinkTabTitles, ObjectTypes.COMPANY, 'Company'), _LinkTabTitles);
export var Tabs = (_Tabs = {}, _defineProperty(_Tabs, TabTypes.DESCRIPTION, {
  url: 'description',
  label: 'Home',
  icon: 'home'
}), _defineProperty(_Tabs, TabTypes.QUESTIONNAIRE, {
  url: 'questionnaire',
  label: 'Questionnaire',
  icon: 'help-circle'
}), _defineProperty(_Tabs, TabTypes.HISTORY, {
  url: 'history',
  label: 'History',
  icon: 'clock'
}), _defineProperty(_Tabs, TabTypes.ASSESSMENT, {
  url: 'assessment',
  label: 'Assessment',
  icon: 'sliders'
}), _defineProperty(_Tabs, TabTypes.GAPANALYSIS, {
  url: 'gap',
  label: 'Gap analysis',
  icon: 'crosshair'
}), _defineProperty(_Tabs, TabTypes.GAPLINKS, {
  url: 'gap',
  label: 'Gaps',
  icon: 'crosshair'
}), _defineProperty(_Tabs, TabTypes.DATAFLOW, {
  url: 'dataflow',
  label: 'Data flow',
  icon: 'git-pull-request',
  style: 'transform scale-x-100 -scale-y-100 rotate-90'
}), _defineProperty(_Tabs, TabTypes.CHANGELOG, {
  url: 'changelog',
  label: 'Changelog',
  icon: 'layers'
}), _defineProperty(_Tabs, TabTypes.LINKS, {
  url: 'links',
  label: 'Links',
  icon: 'link'
}), _defineProperty(_Tabs, TabTypes.DPM, {
  url: 'dpm',
  label: 'Data Processor Management',
  icon: 'database'
}), _defineProperty(_Tabs, TabTypes.AUDIT, {
  url: 'dpm',
  label: 'Audit',
  icon: 'database'
}), _defineProperty(_Tabs, TabTypes.AUDIT_QUESTIONNAIRE, {
  url: 'dpm',
  label: 'Send audit',
  icon: 'database'
}), _defineProperty(_Tabs, TabTypes.CONTRACT_PARTY, {
  url: 'party',
  label: 'Contract party',
  icon: 'server'
}), _defineProperty(_Tabs, TabTypes.RELATED_CONTRACTS, {
  url: 'related',
  label: 'Related contracts',
  icon: 'copy'
}), _defineProperty(_Tabs, TabTypes.DOCUMENTS, {
  url: 'documents',
  label: 'Documents',
  icon: 'file-text'
}), _defineProperty(_Tabs, TabTypes.AWARENESS, {
  url: 'awareness',
  label: 'Awareness',
  icon: 'bell'
}), _defineProperty(_Tabs, TabTypes.CONTACTS, {
  url: 'contacts',
  label: 'Contacts',
  icon: 'users'
}), _defineProperty(_Tabs, TabTypes.RIGHTS, {
  url: 'rights',
  label: 'Rights',
  icon: 'tool'
}), _Tabs);
export var DepRelationsTabDelegation = function DepRelationsTabDelegation() {
  for (var _len = arguments.length, conditions = new Array(_len), _key = 0; _key < _len; _key++) {
    conditions[_key] = arguments[_key];
  }
  return {
    name: 'Delegation',
    slotName: 'delegation',
    isEnabled: !conditions.length || conditions.some(function (value) {
      return value;
    })
  };
};
export var DepRelationsTabDetails = function DepRelationsTabDetails() {
  for (var _len2 = arguments.length, conditions = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    conditions[_key2] = arguments[_key2];
  }
  return {
    name: 'Details',
    slotName: 'details',
    isEnabled: !conditions.length || conditions.some(function (value) {
      return value;
    })
  };
};
export var TabsDepRelations = function TabsDepRelations() {
  return [DepRelationsTabDelegation(), DepRelationsTabDetails()];
};