function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import ColorPicker from '@/components/Molecules/ColorPicker';
import Notification from '@/components/Molecules/Notification';
import { ColorSettings, LogoSettings } from '@/constants/admin/CustomizationSettings';
import { patchUtilSettings } from '@/api/risma/settings';
import * as imageAPI from '@/api/admin/image';
import { cacheItem } from '@/routes/storeCache';
export default {
  name: 'LookFeelPage',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    RismaButton: RismaButton,
    ColorPicker: ColorPicker,
    RismaFileUpload: RismaFileUpload,
    Notification: Notification
  },
  data: function data() {
    return {
      colorSettings: [ColorSettings.settingsBgColor, ColorSettings.settingsTextColor, ColorSettings.settingsSubBgColor, ColorSettings.settingsSubTextColor, ColorSettings.settingsSubActiveBgColor, ColorSettings.settingsSubActiveTextColor],
      colorSettingsFull: ColorSettings,
      logoSettings: LogoSettings,
      errorMessage: null,
      imageTypes: ['JPEG', 'SVG', 'PNG'],
      translate: getTranslate['LookFeelPage']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    getAllSettings: 'getAllSettings'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    logoBackgroundColor: function logoBackgroundColor() {
      var _this = this;
      var setting = this.colorSettings.find(function (item) {
        return item.settingName === _this.colorSettingsFull.settingsBgColor.settingName;
      });
      return setting.color || setting.default;
    },
    imgSrc: function imgSrc() {
      var hasCustomLogo = this.getSettingValue(this.logoSettings.SETTING);
      if (hasCustomLogo) {
        return this.logoSettings.CUSTOM + "?".concat(new Date().getTime()); //date is for forcing upd;
      }

      return this.logoSettings.DEFAULT;
    }
  }),
  mounted: function mounted() {
    this.prepareColors();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useSettingsStore, {
    setSettings: 'set',
    loadSettings: 'load'
  })), {}, {
    handleLoad: function handleLoad(fileList) {
      var _this2 = this;
      var file = fileList.slice(fileList.length - 1);
      file[0].name = file[0].filename;
      file[0].type = 'logo';
      file[0].creator = this.currentUser.id;
      file[0].name = file[0].filename;
      imageAPI.postImage(file).then(function () {
        return _this2.updateSetting(_this2.logoSettings.SETTING, true);
      }).catch(function (error) {
        return _this2.setError(error);
      });
    },
    onColorChanged: function onColorChanged(item, color) {
      item.color = color;
      this.updateState(_defineProperty({}, item.settingName, color));
    },
    uploadClick: function uploadClick() {
      this.$el.querySelector('.input-load').click();
    },
    prepareColors: function prepareColors() {
      var _this3 = this;
      this.colorSettings.forEach(function (item) {
        // fixes bug with saved data in cache (change tab and reenter the page)
        if (!item.default) {
          item.default = item.color;
        }
        var color = _this3.getSettingValue(item.settingName);
        if (color && color.length) {
          item.color = color;
        }
      });
    },
    resetColors: function resetColors() {
      var _this4 = this;
      this.colorSettings.forEach(function (item) {
        item.color = item.default;
        _this4.updateSetting(item.settingName, item.default);
      });
    },
    updateState: function updateState(payload) {
      //to update only vuex and wont send api request every 0.1 sec if chose color
      this.setSettings(_objectSpread(_objectSpread({}, this.getAllSettings), payload));
    },
    updateSetting: function updateSetting(settingName, value) {
      var _this5 = this;
      // send api upd colors or logo
      patchUtilSettings(_defineProperty({}, settingName, value)).then(function () {
        return cacheItem('settings', function () {
          return _this5.loadSettings();
        }, 180);
      }).catch(function (error) {
        return _this5.setError(error);
      });
    },
    setError: function setError(error) {
      var _this6 = this;
      if (error.status === 415) {
        this.errorMessage = "".concat(this.$trans(error.response.error), ": ").concat(this.imageTypes.join(', '));
      } else {
        this.errorMessage = error.response.error;
      }
      setTimeout(function () {
        _this6.errorMessage = null;
      }, 5000);
    }
  })
};