import Matrix from '@/components/Molecules/Risk/Matrix';
import { RiskGridHelperMixin } from '@/mixins/RiskGridHelperMixin';
import { RiskMatrixDefaultSettings, GradientTypes } from '@/constants/admin/RiskMatrixSettings';
export default {
  name: 'RiskGridDisplay',
  introduction: 'Risk grid matrix component',
  description: 'RiskGridDisplay allows to click on each cell and get $emit("onStateChanged", $event)',
  token: '<risk-grid-display :risk-grid-data="riskGridData" />',
  components: {
    Matrix: Matrix
  },
  mixins: [RiskGridHelperMixin],
  props: {
    riskGridData: {
      note: 'This is an object with all the data needed for the risk matrix to work',
      type: Object,
      required: true
    },
    colorDirection: {
      note: 'Change the color arrangement. Can either be "diagonal", "vertical" or "horizontal"',
      type: String,
      default: GradientTypes.diagonal
    },
    gridBoxSize: {
      type: Number,
      default: 50,
      note: 'The size of the individual boxes in the grid'
    },
    showArrows: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Show x and y arrows?'
    },
    startColor: {
      type: String,
      required: false,
      default: RiskMatrixDefaultSettings.startColor
    },
    endColor: {
      type: String,
      required: false,
      default: RiskMatrixDefaultSettings.endColor
    },
    isMatrixClickable: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Should the matrix be clickable?'
    },
    customHeatmapOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['onStateChanged'],
  data: function data() {
    return {
      grid: [],
      xLabels: [],
      yLabels: []
    };
  },
  computed: {
    computedLabels: function computedLabels() {
      return {
        xAxisLabel: this.riskGridData.xLabel,
        yAxisLabel: this.riskGridData.yLabel,
        xLabels: this.xLabels,
        yLabels: this.yLabels
      };
    }
  },
  watch: {
    riskGridData: {
      handler: function handler() {
        this.refreshGrid();
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.refreshGrid();
  },
  methods: {
    refreshGrid: function refreshGrid() {
      this.yLabels = this.riskGridData.gridSizeY;
      this.xLabels = this.riskGridData.gridSizeX;
      if (this.riskGridData.grid && this.riskGridData.grid.length) {
        this.setGrid(this.riskGridData.grid);
      } else {
        this.initiateGrid();
      }
    },
    setGrid: function setGrid(grid) {
      if (!grid.length) return;
      this.grid = grid;
    }
  }
};