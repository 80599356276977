import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0fb7503a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  class: "ml-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["expansion-panel", {
      'expanded': $data.completeIsExpanded
    }]),
    tabindex: 0,
    onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(function ($event) {
      return $data.completeIsExpanded = !$data.completeIsExpanded;
    }, ["stop"]), ["enter"]))
  }, [_createElementVNode("div", {
    class: _normalizeClass(["expansion-panel-header cursor-pointer flex flex-row align-items-center", $options.classListByType[$props.type].header]),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.completeIsExpanded = !$data.completeIsExpanded;
    })
  }, [_renderSlot(_ctx.$slots, "header", {}, undefined, true), _createVNode(_component_feather_icon, {
    icon: "chevron-down",
    class: _normalizeClass(['transform duration-500 cursor-pointer stroke-3 ml-3', {
      'rotate-180': $data.completeIsExpanded
    }, $options.classListByType[$props.type].chevron])
  }, null, 8 /* PROPS */, ["class"]), _createVNode(_Transition, {
    name: "fade"
  }, {
    default: _withCtx(function () {
      return [!$data.completeIsExpanded ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "collapsed-header", {}, undefined, true)])) : _createCommentVNode("v-if", true)];
    }),
    _: 3 /* FORWARDED */
  })], 2 /* CLASS */), _createVNode(_Transition, {
    name: "fade"
  }, {
    default: _withCtx(function () {
      return [$data.completeIsExpanded ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["expansion-panel-content bg-gray-75 p-2", $options.classListByType[$props.type].content])
      }, [_renderSlot(_ctx.$slots, "content", {}, undefined, true)], 2 /* CLASS */)) : _createCommentVNode("v-if", true)];
    }),
    _: 3 /* FORWARDED */
  })], 34 /* CLASS, HYDRATE_EVENTS */);
}