// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/svg/chevron_solid-blue.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/svg/chevron_solid-gray.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* vertical line is divided on 2 parts, vertical-arrow-first and vertical-arrow-last so they could show different colors */\n.vertical-arrow-first::before,  .vertical-arrow-last::before,  .hori-arrow::after,  .vertical-arrow-first-hidden::after {\n  position: absolute;\n  --tw-bg-opacity: 1;\n  background-color: rgb(229 233 235 / var(--tw-bg-opacity));\n  content: '';\n}\n.vertical-arrow-first.vertical-arrow-first-selected::before,  .vertical-arrow-last.vertical-arrow-last-selected::before,  .hori-arrow.hori-arrow-selected::after {\n  --tw-bg-opacity: 1;\n  background-color: rgb(14 41 84 / var(--tw-bg-opacity));\n}\n.hori-arrow::after {\n  width: 22px;\n  height: 2px;\n  left: -47px;\n  z-index: 0;\n}\n.vertical-arrow-first::before {\n  width: 2px;\n  z-index: 2;\n  left: -27px;\n  top: 0px;\n  height: calc(50% + 1px);\n}\n.vertical-arrow-first:not(.vertical-arrow-first-selected)::before {\n  z-index: 0;\n}\n.first-child-vertical-arrow::before {\n  left: -47px;\n  top: -36px;\n  height: calc(50% + 37px);\n}\n.vertical-arrow-last::before {\n  height: 100%;\n  width: 2px;\n  left: -27px;\n  top: 11px;\n  z-index: 1;\n}\n.vertical-arrow-last-headless::before {\n  top: 20px;\n}\n.vertical-arrow-first-hidden::after {\n  height: calc(50% - 1px);\n  width: 2px;\n  left: -27px;\n  top: 0;\n  z-index: 3;\n  background-color: #FFFFFF;\n}\n.vertical-arrow-last:last-child::before {\n  height: 0 !important;\n}\n.hori-arrow-on-hidden-children::after {\n  left: -27px;\n}\n.icon-position {\n  position: absolute;\n  left: -36.5px;\n  z-index: 10;\n  height: 22px;\n  width: 20px;\n  background-color: #FFFFFF;\n  background-size: 100%;\n}\n.icon-blue {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.icon-gray {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
