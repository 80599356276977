function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { addChildToNode } from '@/api/compliance/tree';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
export default {
  name: 'ComplianceCreationModal',
  introduction: 'ComplianceCreationModal multiselect popup',
  token: '<compliance-creation-modal />',
  components: {
    Modal: Modal,
    InputField: InputField,
    Notification: Notification
  },
  props: {
    projectId: {
      type: [Number, String],
      required: true,
      default: null
    },
    parentId: {
      type: [Number, String],
      required: false,
      default: null
    },
    nodeTitle: {
      type: String,
      required: false,
      default: ''
    },
    createParams: {
      type: Object,
      required: false,
      default: null
    }
  },
  emits: ['dismiss', 'create'],
  data: function data() {
    return {
      title: '',
      errorMessage: '',
      translate: getTranslate['ComplianceCreationModal']()
    };
  },
  beforeMount: function beforeMount() {
    this.title = this.nodeTitle || '';
  },
  methods: {
    createNode: function createNode(params) {
      var _this = this;
      return addChildToNode(this.projectId, params).then(function (response) {
        if (typeof response.headers !== 'undefined') {
          _this.errorMessage = _this.translate.errorDuringCreation;
          return;
        }
        _this.$emit('create', response);
      }).catch(function (response) {
        var _response$response;
        _this.errorMessage = _this.translate.couldNotCreateNode + ' - timeout? (' + (response === null || response === void 0 || (_response$response = response.response) === null || _response$response === void 0 ? void 0 : _response$response.error) + ')';
      });
    },
    handleOnSubmit: function handleOnSubmit() {
      var data = _objectSpread({
        title: this.title
      }, this.createParams);
      if (this.parentId && this.parentId !== -1) {
        data.appendTo = +this.parentId;
      }
      if (!this.handleValidation(data)) {
        return;
      }
      return this.createNode(data);
    },
    handleValidation: function handleValidation(data) {
      var errors = '';
      if (!data.title) {
        errors = this.translate.titleCannotBeEmpty;
      }
      this.errorMessage = errors;
      return !errors;
    }
  }
};