import { Pie as PieChart } from 'vue-chartjs';
import { Colors } from '@/Colors';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ExportFrame from '@/components/Atoms/Containers/ExportFrame';
export default {
  name: 'RismaPieChart',
  introduction: 'simple PieChart with using the vue-chartjs',
  description: '',
  token: "<risma-pie-chart\n      :data=\"chartData\"\n      :labels=\"chartLabels\"\n      :options=\"chartOptions\"\n      :colors=\"colors\"\n      @chartClicked=\"clickCh\"/> ",
  components: {
    PieChart: PieChart,
    ExportFrame: ExportFrame
  },
  props: {
    data: {
      required: true,
      type: Array,
      default: function _default() {},
      note: 'the data to create the component'
    },
    labels: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'labels that are shown'
    },
    colors: {
      required: false,
      type: Array,
      default: function _default() {
        return [Colors.notSet, Colors.unanswered, Colors.answered];
      },
      note: 'colors of the fields in piechart'
    },
    useDefaultExport: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  emits: ['chartClicked'],
  data: function data() {
    return {
      defaultPlugins: [ChartDataLabels],
      defaultOptions: {
        onClick: this.handleClick,
        plugins: {
          legend: {
            position: 'bottom',
            align: 'start'
          },
          datalabels: {
            color: '#fff',
            formatter: function formatter(value, ctx) {
              var sum = ctx.dataset.data.reduce(function (total, item) {
                return total + item;
              }, 0);
              if (!sum || !value) return '';
              return (value * 100 / sum).toFixed(2) + '%';
            }
          }
        },
        maintainAspectRatio: false
      }
    };
  },
  computed: {
    chartData: function chartData() {
      return {
        labels: this.labels,
        datasets: [{
          backgroundColor: this.colors,
          data: this.data
        }]
      };
    }
  },
  methods: {
    handleClick: function handleClick(point, event) {
      if (event.length) {
        this.$emit('chartClicked', event[0].index);
      }
    }
  }
};