function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { i18n } from '@/i18n';
import { SettingsNav } from '@/constants/menu/navigations';
import { ObjectTypes } from '@/constants/ObjectTypes';
export function userSettingsNav(user, settings, riskProjectsItems) {
  return [{
    groupTitle: i18n.t('Personal settings'),
    rights: true,
    groupItems: [{
      path: SettingsNav.user.path,
      title: i18n.t(SettingsNav.user.title)
    }, {
      path: SettingsNav.notifications.path,
      title: i18n.t(SettingsNav.notifications.title)
    }]
  }, {
    groupTitle: i18n.t(SettingsNav.general.title),
    rights: SettingsNav.controlPanel.rights(user, settings),
    groupItems: [{
      path: SettingsNav.userAdmin.path,
      title: i18n.t(SettingsNav.userAdmin.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.adsettings.path,
      title: i18n.t(SettingsNav.adsettings.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.organisations.path,
      title: i18n.t(SettingsNav.organisations.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.activityTypes.path,
      title: i18n.t(SettingsNav.activityTypes.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.tags.path,
      title: i18n.t(SettingsNav.tags.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.nonWorkingDays.path,
      title: i18n.t(SettingsNav.nonWorkingDays.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.lookFeel.path,
      title: i18n.t(SettingsNav.lookFeel.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.controlPanel.path,
      title: i18n.t(SettingsNav.controlPanel.title),
      rights: SettingsNav.controlPanel.rights(user, settings)
    }, {
      path: SettingsNav.auditReports.path,
      title: i18n.t(SettingsNav.auditReports.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.importData.path,
      title: i18n.t(SettingsNav.importData.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.bulkUpdate.path,
      title: i18n.t(SettingsNav.bulkUpdate.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.admin.path,
      title: i18n.t(SettingsNav.admin.title),
      rights: SettingsNav.general.rights(user) && settings['servername'] !== undefined
    }]
  }, {
    groupTitle: i18n.t(SettingsNav.actionsSettings.title),
    rights: SettingsNav.actionsSettings.rights(user, settings),
    groupItems: [{
      path: SettingsNav.actionControlPanel.path,
      title: i18n.t(SettingsNav.actionControlPanel.title)
    }]
  }, {
    groupTitle: i18n.t(SettingsNav.contract.title),
    rights: SettingsNav.contractsControlPanel.rights(user, settings),
    groupItems: [{
      path: SettingsNav.workflowStatuses.path,
      title: i18n.t(SettingsNav.workflowStatuses.title),
      rights: SettingsNav.contract.rights(user, settings)
    }, {
      path: SettingsNav.contractsControlPanel.path,
      title: i18n.t(SettingsNav.contractsControlPanel.title),
      rights: SettingsNav.contractsControlPanel.rights(user, settings)
    }, {
      path: SettingsNav.documentTypes.path,
      title: i18n.t(SettingsNav.documentTypes.title)
    }]
  }, {
    groupTitle: i18n.t(SettingsNav.controlsSettings.title),
    rights: SettingsNav.controlsSettings.rights(user, settings),
    groupItems: [{
      path: SettingsNav.controlsAdmin.path,
      title: i18n.t(SettingsNav.controlsAdmin.title)
    }, {
      path: SettingsNav.controlControlPanel.path,
      title: i18n.t(SettingsNav.controlControlPanel.title)
    }]
  }, {
    groupTitle: i18n.t(SettingsNav.complianceSettings.title),
    rights: SettingsNav.complianceControlPanel.rights(user, settings),
    groupItems: [{
      path: SettingsNav.complianceProjects.path,
      title: i18n.t(SettingsNav.complianceProjects.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceRiskAssessment.path,
      title: i18n.t(SettingsNav.complianceRiskAssessment.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceAssets.path,
      title: i18n.t(SettingsNav.complianceAssets.title),
      rights: SettingsNav.complianceAssets.rights(user, settings)
    }, {
      path: SettingsNav.complianceTemplateNodes.path,
      title: i18n.t(SettingsNav.complianceTemplateNodes.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceQuestionnaires.path,
      title: i18n.t(SettingsNav.complianceQuestionnaires.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceLiaTia.path,
      title: i18n.t(SettingsNav.complianceLiaTia.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceGapSchemas.path,
      title: i18n.t(SettingsNav.complianceGapSchemas.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceRismaRules.path,
      title: i18n.t(SettingsNav.complianceRismaRules.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceRelevantQuestions.path,
      title: i18n.t(SettingsNav.complianceRelevantQuestions.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceControlPanel.path,
      title: i18n.t(SettingsNav.complianceControlPanel.title),
      rights: SettingsNav.complianceControlPanel.rights(user, settings)
    }, {
      path: SettingsNav.suggestedActivities.path,
      title: i18n.t(SettingsNav.suggestedActivities.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }]
  }, {
    groupTitle: i18n.t(SettingsNav.riskSettings.title),
    rights: SettingsNav.riskSettings.rights(user, settings) || !!SettingsNav.riskPeriod.rights(user, settings),
    groupItems: [{
      path: SettingsNav.riskProjects.path,
      title: i18n.t(SettingsNav.riskProjects.title),
      rights: SettingsNav.riskProjects.rights(user, settings)
    }].concat(_toConsumableArray(riskProjectsItems), [{
      path: SettingsNav.riskThreatsVulnerability.path,
      title: i18n.t(SettingsNav.riskThreatsVulnerability.title),
      rights: !!(settings['app.module_risks2_enabled'] && user.is_admin && user.projects.find(function (project) {
        return project.objType === ObjectTypes.RISK_PROJECT && project.enabled;
      }))
    }, {
      path: SettingsNav.riskControlPanel.path,
      title: i18n.t(SettingsNav.riskControlPanel.title),
      rights: SettingsNav.riskControlPanel.rights(user, settings)
    }, {
      path: SettingsNav.riskHeatMap.path,
      title: i18n.t(SettingsNav.riskHeatMap.title),
      rights: SettingsNav.riskHeatMap.rights(user, settings)
    }])
  }, {
    groupTitle: i18n.t(SettingsNav.incident.title),
    rights: SettingsNav.incident.rights(user, settings),
    groupItems: [{
      path: SettingsNav.incidentsControlPanel.path,
      title: i18n.t(SettingsNav.incidentsControlPanel.title)
    }]
  }, {
    groupTitle: i18n.t(SettingsNav.appSettings.title),
    rights: SettingsNav.appSettings.rights(user, settings),
    groupItems: [{
      path: SettingsNav.appInvitation.path,
      title: i18n.t(SettingsNav.appInvitation.title)
    }, {
      path: SettingsNav.appControlPanel.path,
      title: i18n.t(SettingsNav.appControlPanel.title)
    }]
  }, {
    groupTitle: i18n.t(SettingsNav.companiesSettings.title),
    rights: SettingsNav.companiesSettings.rights(),
    groupItems: [{
      path: SettingsNav.companies.path,
      title: i18n.t(SettingsNav.companies.title)
    }, {
      path: SettingsNav.bankruptcy.path,
      title: i18n.t(SettingsNav.bankruptcy.title),
      id: 'bankruptcy-list'
    }]
  }];
}