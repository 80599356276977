import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue";
var _hoisted_1 = {
  class: "py-2 flex-grow min-w-200px max-w-500px"
};
var _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.activities, function (activity) {
    return _openBlock(), _createElementBlock("div", {
      key: activity.id,
      class: "mb-2 flex"
    }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_tiny_mce_box, {
      type: "comment",
      height: "150px",
      "initial-content": activity.activity_description,
      onChanged: function onChanged() {
        return $options.handleDescriptionChange(arguments.length <= 1 ? undefined : arguments[1], _ctx.activity_description, activity.id, 'activity_description');
      }
    }, {
      headline: _withCtx(function () {
        return [_createVNode(_component_risma_title, {
          title: activity.activity_header,
          editable: true,
          "use-calculated-width": false,
          class: "w-11/12",
          type: "medium",
          onTitleChangeLazy: function onTitleChangeLazy($event) {
            return _ctx.$emit('update', $event, activity.id, 'activity_header');
          }
        }, null, 8 /* PROPS */, ["title", "onTitleChangeLazy"])];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["initial-content", "onChanged"])]), _createElementVNode("button", {
      class: "focus:outline-none",
      onClick: _withModifiers(function ($event) {
        return _ctx.$emit('delete', activity.id);
      }, ["stop"])
    }, [_createVNode(_component_feather_icon, {
      icon: "x",
      class: "stroke-2 text-red-50 cursor-pointer"
    })], 8 /* PROPS */, _hoisted_2)]);
  }), 128 /* KEYED_FRAGMENT */))]);
}