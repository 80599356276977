import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import { Colors } from '@/Colors';
export default {
  name: 'GapLogicInformationBoxQuestion',
  introduction: 'component to represent question/answer',
  description: 'gap-logic-information-box-question displays gap color, detailed question information and answer',
  token: '<gap-logic-information-box-question :question="{}" />',
  components: {
    TrafficLight: TrafficLight
  },
  props: {
    question: {
      type: Object,
      required: true,
      note: 'question'
    }
  },
  data: function data() {
    return {
      expanded: false,
      Colors: Colors,
      translate: {
        seeMore: this.$trans('See more'),
        seeLess: this.$trans('See less'),
        notAnswered: this.$trans('Not answered')
      }
    };
  }
};