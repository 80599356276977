import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import { Bar as BarChart } from 'vue-chartjs';
import { toLocalDate } from '@/utils/date';
import ExportFrame from '@/components/Atoms/Containers/ExportFrame';
export default {
  name: 'GanttChartJs',
  introduction: 'horizontal bar chart with using the vue-chartjs',
  description: 'base horizontal bar chart component',
  token: "<gantt-chart-js\n      :datasets=\"[{\n                    backgroundColor: '#5AA744',\n                    data: [\n                        ['2002-03-15', '2008-04-22'],\n                    ]\n                }]\"\n      :labels=\"['Label 1']\"\n      :time-unit=\"year\"\n      :startDate=\"2022-03-22\" /> ",
  components: {
    BarChart: BarChart,
    ExportFrame: ExportFrame
  },
  props: {
    datasets: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'the data to create the component'
    },
    labels: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'labels that are shown'
    },
    startDate: {
      required: true,
      type: String,
      note: 'User defined minimum value for the scale'
    },
    timeUnit: {
      require: false,
      type: String,
      default: 'year'
    },
    height: {
      require: false,
      type: Number,
      default: 400
    },
    useDefaultExport: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  emits: ['chartClicked'],
  data: function data() {
    return {
      selectedIndex: null,
      translate: {
        from: this.$trans('From'),
        to: this.$trans('To')
      }
    };
  },
  computed: {
    defaultOptions: function defaultOptions() {
      var _this = this;
      return {
        indexAxis: 'y',
        onClick: this.handleClick,
        scales: {
          x: {
            type: 'time',
            position: 'top',
            time: {
              unit: this.timeUnit,
              parser: 'YYYY'
            },
            min: new Date(this.startDate)
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function label(ctx) {
                var array = JSON.parse(ctx.formattedValue);
                return "".concat(_this.translate.from, " ").concat(toLocalDate(array[0]), " - ").concat(_this.translate.to, " ").concat(toLocalDate(array[1]));
              }
            }
          }
        },
        maintainAspectRatio: false,
        hoverBorderWidth: 5
      };
    },
    chartData: function chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets
      };
    }
  },
  methods: {
    handleClick: function handleClick(point, event) {
      if (event.length) {
        this.$emit('chartClicked', event[0].index);
      }
    }
  }
};