import i18n from '@/i18n';
export var getTranslate = {
  OptionalFieldsTable: function OptionalFieldsTable() {
    return {
      optionalFields: i18n.t('Optional fields'),
      title: i18n.t('Title'),
      dontShow: i18n.t('Don\'t show'),
      active: i18n.t('Active')
    };
  },
  GapAttributesList: function GapAttributesList() {
    return {
      addOption: i18n.t('Add option'),
      attributeCategory: i18n.t('Attribute category'),
      attributeValues: i18n.t('Attribute values'),
      delete: i18n.t('Delete'),
      cancel: i18n.t('Cancel'),
      areYouSureYouWantToDeleteAttributeValue: i18n.t('Are you sure you want to delete this attribute value?'),
      areYouSureYouWantToDeleteAttributeCategory: i18n.t('Are you sure you want to delete this attribute category?')
    };
  }
};