export var RiskProjects = {
  ENTERPRISE_RISK_MANAGEMENT: {
    id: 1,
    title: 'Enterprise risk management',
    type: 1
  },
  IRM: {
    id: 2,
    title: 'Information risk management',
    type: 2
  },
  ARTICLE32: {
    id: 3,
    title: 'Article 32',
    type: 3
  }
};