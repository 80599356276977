import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['notification', $props.type, {
      'with-close': $props.closeable
    }])
  }, [$props.closeable ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "x",
    class: "stroke-2 cursor-pointer risma-icon",
    onClick: $options.dismiss
  }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])], 2 /* CLASS */);
}