import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-45294dac"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "flex flex-wrap"
};
var _hoisted_2 = {
  key: 0,
  class: "flex-auto w-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risk_grid = _resolveComponent("risk-grid");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.heatMapForConsequenceParameter,
    type: "small",
    class: "text-blue-750 font-semibold"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_single_select, {
    modelValue: $data.consequenceId,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $data.consequenceId = $event;
    }), $options.onConsequenceChanged],
    options: $options.consequenceOptions,
    "un-sorted": true
  }, null, 8 /* PROPS */, ["modelValue", "options", "onUpdate:modelValue"]), $data.consequenceId ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.matrices, function (matrix) {
    return _openBlock(), _createElementBlock("div", {
      key: matrix.title,
      class: "col-risk-matrix col-12 w-full"
    }, [_createVNode(_component_risma_title, {
      title: matrix.title,
      type: "small",
      class: "text-blue-750 mt-2 font-semibold"
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risk_grid, {
      "risk-grid-data": matrix.data,
      "without-z-axis": true,
      "grid-box-size": $data.gridBoxSize,
      "enable-prev-position": false,
      "is-grid-clickable": false,
      "axis-with-labels": false,
      "dot-label-hover-function": $options.dotHoverFunction
    }, null, 8 /* PROPS */, ["risk-grid-data", "grid-box-size", "dot-label-hover-function"])]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)]);
}