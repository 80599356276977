import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import MainWell from '@/components/Atoms/Containers/MainWell';
import SaveButton from '@/components/Atoms/Buttons/SaveButton';
import InputField from '@/components/Atoms/Inputs/InputField';
import QuestionnaireScoringAssessments from '@/components/Pages/Admin/QuestionnaireScoringAssessments';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import * as questionnaireApi from '@/api/compliance/questionnaire';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
import { saveStatus } from '@/constants/SaveStatuses';
export default {
  name: 'QuestionnaireAdminScoring',
  introduction: '',
  description: '',
  token: '<questionnaire-admin-score></questionnaire-admin-score>',
  components: {
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    MainWell: MainWell,
    SaveButton: SaveButton,
    InputField: InputField,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    QuestionnaireScoringAssessments: QuestionnaireScoringAssessments
  },
  props: {
    questionnaireId: {
      type: [String, Number],
      required: true
    }
  },
  data: function data() {
    return {
      isLoaded: false,
      allQuestionnaires: [],
      currentAssessments: null,
      assessmentsDataUpdate: null,
      questionsAll: [],
      typesWhiteList: [QuestionTypes().singleChoice.id, QuestionTypes().multipleChoice.id],
      dataUpdate: {},
      initialLoad: false,
      updateScoresTimer: null,
      updateAssessmentsTimer: null,
      saveStatus: saveStatus.SAVED,
      maxlength: 10,
      translate: {
        complianceAdministration: this.$trans('Compliance administration'),
        back: this.$trans('Back'),
        scoring: this.$trans('Scoring')
      }
    };
  },
  computed: {
    mainTitle: function mainTitle() {
      var result = this.translate.scoring;
      if (this.currentQuestionnaire) {
        result += " - ".concat(this.currentQuestionnaire.title);
      }
      return result;
    },
    currentQuestionnaire: function currentQuestionnaire() {
      var _this = this;
      if (!this.allQuestionnaires) {
        return null;
      }
      return this.allQuestionnaires.find(function (item) {
        return item.id === +_this.questionnaireId;
      }) || null;
    },
    questions: function questions() {
      if (!this.questionsAll) {
        return [];
      }
      return this.filterByType(this.questionsAll).filter(function (question) {
        return question.hidden === false;
      });
    }
  },
  watch: {
    questions: {
      handler: function handler() {
        if (this.isLoaded && this.initialLoad) {
          this.setUpdateScoresTimer();
        }
      },
      deep: true
    }
  },
  beforeMount: function beforeMount() {
    var _this2 = this;
    this.getData().then(function () {
      _this2.initialLoad = true;
    });
  },
  methods: {
    getData: function getData() {
      this.isLoaded = false;
      var promises = [this.getScores(), this.getQuestionnaires(), this.getCurrentAssessments()];
      return Promise.all(promises);
    },
    readableType: function readableType(typeId) {
      var result = '';
      var possible = Object.values(QuestionTypes(this.$trans));
      var found = possible.find(function (item) {
        return item.id === +typeId;
      });
      if (found) {
        result = found.option;
      }
      return result;
    },
    getScores: function getScores() {
      var _this3 = this;
      return questionnaireApi.getQuestionnaireScores(this.questionnaireId).then(function (_ref) {
        var list = _ref.list;
        _this3.questionsAll = list;
        _this3.isLoaded = true;
      });
    },
    getQuestionnaires: function getQuestionnaires() {
      var _this4 = this;
      return questionnaireApi.getAllQuestionnaires().then(function (_ref2) {
        var list = _ref2.list;
        _this4.allQuestionnaires = list;
      });
    },
    getCurrentAssessments: function getCurrentAssessments() {
      var _this5 = this;
      return questionnaireApi.getQuestionnaireAssessments(this.questionnaireId).then(function (_ref3) {
        var list = _ref3.list;
        _this5.currentAssessments = list;
      });
    },
    filterByType: function filterByType(questions) {
      var _this6 = this;
      var result = questions.filter(function (question) {
        return _this6.typesWhiteList.includes(question.questionType);
      });
      result.forEach(function (question) {
        question.optionDetails.forEach(function (option) {
          option.score = option.score + '';
        });
      });
      return result;
    },
    updateScores: function updateScores() {
      var _this7 = this;
      var dataUpdate = this.preparePayload();
      if (Object.keys(dataUpdate).length) {
        this.saveStatus = saveStatus.SAVING;
        questionnaireApi.updateQuestionnaireScores(this.questionnaireId, {
          options: dataUpdate
        }).then(function () {
          _this7.saveStatus = saveStatus.SAVED;
        }).catch(function (e) {
          _this7.saveStatus = saveStatus.NOT_SAVED;
          throw e;
        });
      }
    },
    setUpdateScoresTimer: function setUpdateScoresTimer(timeout) {
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateScoresTimer);
      this.updateScoresTimer = setTimeout(this.updateScores, timeout || this.$debounceTimeout);
    },
    updateFromSaveButton: function updateFromSaveButton(timeout) {
      this.setUpdateScoresTimer(timeout);
      this.setUpdateAssessmentsTimer(timeout);
    },
    preparePayload: function preparePayload() {
      var result = {};
      this.questions.map(function (item) {
        if (item.optionDetails) {
          item.optionDetails.map(function (option) {
            if (!result[item.id]) {
              result[item.id] = {};
            }
            result[item.id][option.id] = option.score;
          });
        }
      });
      return result;
    },
    backToPrevPage: function backToPrevPage() {
      this.$router.push("/settings/compliancequestionnaires?questionnaireType=".concat(this.$route.query.questionnaireType));
    },
    onAssessmentsChanged: function onAssessmentsChanged(e) {
      this.assessmentsDataUpdate = e;
      this.setUpdateAssessmentsTimer();
    },
    setUpdateAssessmentsTimer: function setUpdateAssessmentsTimer(timeout) {
      if (!this.assessmentsDataUpdate) {
        return;
      }
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateAssessmentsTimer);
      this.updateAssessmentsTimer = setTimeout(this.updateAssessments, timeout || this.$debounceTimeout);
    },
    updateAssessments: function updateAssessments() {
      var _this8 = this;
      questionnaireApi.updateQuestionnaireAssessments(this.questionnaireId, this.assessmentsDataUpdate).then(function () {
        _this8.saveStatus = saveStatus.SAVED;
        _this8.assessmentsDataUpdate = null;
      }).catch(function (e) {
        _this8.saveStatus = saveStatus.NOT_SAVED;
        throw e;
      });
    }
  }
};