function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RiskAssessmentRow from '@/components/Molecules/Risk/RiskAssessmentRow';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Notification from '@/components/Molecules/Notification';
import { RiskBaseModelProps, scoresNameByField } from '@/constants/risks/RiskBaseModelProps';
import { getProbabilityLabel, getConsequenceLabel } from '@/utils/risks/riskPeriod';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'RiskAssessmentsIsms',
  components: {
    RismaTitle: RismaTitle,
    RiskAssessmentRow: RiskAssessmentRow,
    Notification: Notification
  },
  props: {
    activity: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Current risk with row scoring data'
    },
    objType: {
      type: String,
      require: true,
      default: ''
    },
    period: {
      type: Object,
      required: false,
      default: function _default() {},
      note: ''
    },
    threats: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    },
    customThreats: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    },
    customThreatsEnabled: {
      type: Boolean,
      default: false,
      required: false
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Is read only mode switch on'
    },
    inherentRisksEnabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['changed', 'updateCustomThreat', 'updateUnderlyingRisk'],
  data: function data() {
    return {
      rowsData: this.setRowsData(this.period, this.activity),
      translate: getTranslate['RiskAssessmentsIsms']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    hasThreats: function hasThreats() {
      var _this$activity;
      return (_this$activity = this.activity) === null || _this$activity === void 0 || (_this$activity = _this$activity.project) === null || _this$activity === void 0 ? void 0 : _this$activity.threatsEnabled;
    },
    hasAccumulatedData: function hasAccumulatedData() {
      var _this$activity2, _this$activity3;
      return ((_this$activity2 = this.activity) === null || _this$activity2 === void 0 || (_this$activity2 = _this$activity2.children) === null || _this$activity2 === void 0 ? void 0 : _this$activity2.length) && ((_this$activity3 = this.activity) === null || _this$activity3 === void 0 || (_this$activity3 = _this$activity3.project) === null || _this$activity3 === void 0 ? void 0 : _this$activity3.accumulatedRisks);
    },
    panelsStartExpanded: function panelsStartExpanded() {
      return !!this.getSettingValue('feature.risk_assessment_start_expanded');
    },
    riskAssessmentGroups: function riskAssessmentGroups() {
      var _this$period,
        _this = this;
      var groupsByRowIds = {};
      (((_this$period = this.period) === null || _this$period === void 0 ? void 0 : _this$period.consequenceRows) || []).map(function (row) {
        if (!groupsByRowIds[row.id]) groupsByRowIds[row.id] = [];
        if (_this.hasAccumulatedData) {
          groupsByRowIds[row.id].push({
            title: "".concat(_this.translate.underlyingRisksFor, " ").concat(row.label),
            tableHeadTitle: _this.translate.riskTitle,
            items: _this.underlyingRiskRows[row.id] || []
          });
        }
        if (_this.hasThreats) {
          groupsByRowIds[row.id].push({
            title: "".concat(_this.translate.relevantThreatsFor, " ").concat(row.label),
            tableHeadTitle: _this.translate.threat,
            items: _this.threatRows[row.id] || [],
            isCurrentRisk: true,
            isThreat: true
          });
        }
      });
      return groupsByRowIds;
    },
    threatRows: function threatRows() {
      var _this2 = this;
      var result = {};
      this.threats.forEach(function (threat) {
        var _this2$period;
        (((_this2$period = _this2.period) === null || _this2$period === void 0 ? void 0 : _this2$period.consequenceRows) || []).forEach(function (row) {
          var threatAssessment = threat.assessments.find(function (assessment) {
            return assessment.label === row.label;
          });
          if (!threatAssessment) return;
          var customThreat = _this2.customThreats.find(function (ct) {
            return +ct.threatId === +threat.id && +ct.riskAssessmentRowId === +row.id;
          });
          var resultObj = {
            label: threat.title,
            consequence: customThreat ? customThreat.impact : threatAssessment.impact,
            probability: customThreat ? customThreat.likelihood : threatAssessment.likelihood,
            id: threat.id,
            relevant: customThreat ? !!customThreat.relevant : true,
            customThreatId: customThreat ? customThreat.id : null
          };
          result[row.id] ? result[row.id].push(resultObj) : result[row.id] = [resultObj];
        });
      });
      return result;
    },
    underlyingRiskRows: function underlyingRiskRows() {
      var _this$activity4,
        _this3 = this;
      var result = {};
      (_this$activity4 = this.activity) === null || _this$activity4 === void 0 ? void 0 : _this$activity4.children.forEach(function (childRisk) {
        var _this3$period;
        (((_this3$period = _this3.period) === null || _this3$period === void 0 ? void 0 : _this3$period.consequenceRows) || []).forEach(function (row) {
          var residualConsequence = _this3.getUnderlyingRiskRowValue(childRisk, row.id, RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES);
          var residualProbability = _this3.getUnderlyingRiskRowValue(childRisk, row.id, RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES);
          var inherentConsequence = _this3.getUnderlyingRiskRowValue(childRisk, row.id, RiskBaseModelProps.INHERENT_CONSEQUENCE_ROW_SCORES);
          var inherentProbability = _this3.getUnderlyingRiskRowValue(childRisk, row.id, RiskBaseModelProps.INHERENT_PROBABILITY_ROW_SCORES);
          var resultObj = _objectSpread(_objectSpread({}, childRisk), {}, {
            residualConsequence: residualConsequence,
            residualProbability: residualProbability,
            inherentConsequence: inherentConsequence,
            inherentProbability: inherentProbability
          });
          result[row.id] ? result[row.id].push(resultObj) : result[row.id] = [resultObj];
        });
      });
      return result;
    },
    riskAssessmentsRows: function riskAssessmentsRows() {
      var _this$period2,
        _this4 = this;
      return (((_this$period2 = this.period) === null || _this$period2 === void 0 ? void 0 : _this$period2.consequenceRows) || []).map(function (item) {
        var probability = _this4.period.probabilityRows.find(function (probability) {
          return probability.id === item.id;
        });
        return {
          id: item.id,
          label: item.label,
          consequences: item.impactColumns.map(_this4.createItemOption),
          consequenceOrigin: item.impactOrigin,
          consequenceLabel: getConsequenceLabel(_this4.period),
          probabilityLabel: getProbabilityLabel(_this4.period),
          probabilities: probability.likelihoodColumns.map(_this4.createItemOption),
          probabilityOrigin: probability.likelihoodOrigin
        };
      });
    },
    relationNotificationTitle: function relationNotificationTitle() {
      var _activityTitleMap;
      var activityTitleMap = (_activityTitleMap = {}, _defineProperty(_activityTitleMap, ObjectTypes.COMPLIANCE_NODE, 'Compliance node'), _defineProperty(_activityTitleMap, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, 'System'), _defineProperty(_activityTitleMap, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, 'Controller'), _defineProperty(_activityTitleMap, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, 'Processor'), _defineProperty(_activityTitleMap, ObjectTypes.INFORMATION_ASSET, 'Custom Asset'), _activityTitleMap);
      return "".concat(this.translate.thisIsTheRiskAssessmentFor, " ").concat(activityTitleMap[this.activity.riskAssessmentActivity.objType], ":\n                 ").concat(this.activity.riskAssessmentActivity.title);
    }
  }),
  methods: {
    updateCustomThreat: function updateCustomThreat(data, rowId, updateList) {
      this.$emit('updateCustomThreat', data, rowId, updateList);
    },
    onValueChange: function onValueChange(event, type) {
      var _this$updateRowDataBy;
      this.updateRowDataById(event.id, (_this$updateRowDataBy = {}, _defineProperty(_this$updateRowDataBy, type, event.value), _defineProperty(_this$updateRowDataBy, "".concat(type, "Comment"), event.comment), _this$updateRowDataBy));
      var result = this.prepareValueToSave(event, type);
      this.$emit('changed', _defineProperty({}, scoresNameByField[type], result));
    },
    prepareValueToSave: function prepareValueToSave(newValue, type) {
      var _this5 = this;
      return this.riskAssessmentsRows.map(function (item) {
        if (item.id === newValue.id) {
          delete newValue.id;
          newValue.riskAssessmentsRowId = item.id;
          return newValue;
        }
        var result = {
          riskAssessmentsRowId: item.id,
          label: item.label,
          value: _this5.rowsData[item.id][type],
          comment: _this5.rowsData[item.id]["".concat(type, "Comment")]
        };
        return result;
      });
    },
    updateRowDataById: function updateRowDataById(id, value) {
      this.rowsData[id] = _objectSpread(_objectSpread({}, this.rowsData[id]), value);
    },
    createItemOption: function createItemOption(item) {
      return {
        label: "".concat(item.value, ". ").concat(item.label),
        value: item.value,
        title: item.label
      };
    },
    setRowsData: function setRowsData(period, activity) {
      var _this6 = this;
      var result = {};
      period === null || period === void 0 ? void 0 : period.consequenceRows.forEach(function (item) {
        result[item.id] = _this6.getRowData(activity, item.id);
      });
      return result;
    },
    getRowData: function getRowData(activity, itemId) {
      var _this$getValueX = this.getValueX(activity[RiskBaseModelProps.INHERENT_CONSEQUENCE_ROW_SCORES], itemId, 'impactOriginFromRow'),
        inherentConsequence = _this$getValueX.value,
        inherentConsequenceOrigin = _this$getValueX.origin,
        inherentConsequenceComment = _this$getValueX.comment;
      var _this$getValueX2 = this.getValueX(activity[RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES], itemId, 'impactOriginFromRow'),
        residualConsequence = _this$getValueX2.value,
        residualConsequenceOrigin = _this$getValueX2.origin,
        residualConsequenceComment = _this$getValueX2.comment;
      var _this$getValueX3 = this.getValueX(activity[RiskBaseModelProps.INHERENT_PROBABILITY_ROW_SCORES], itemId, 'likelihoodOriginFromRow'),
        inherentProbability = _this$getValueX3.value,
        inherentProbabilityOrigin = _this$getValueX3.origin,
        inherentProbabilityComment = _this$getValueX3.comment;
      var _this$getValueX4 = this.getValueX(activity[RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES], itemId, 'likelihoodOriginFromRow'),
        residualProbability = _this$getValueX4.value,
        residualProbabilityOrigin = _this$getValueX4.origin,
        residualProbabilityComment = _this$getValueX4.comment;
      return {
        inherentConsequence: inherentConsequence,
        inherentConsequenceOrigin: inherentConsequenceOrigin,
        inherentProbability: inherentProbability,
        inherentProbabilityOrigin: inherentProbabilityOrigin,
        residualConsequence: residualConsequence,
        residualConsequenceOrigin: residualConsequenceOrigin,
        residualProbability: residualProbability,
        residualProbabilityOrigin: residualProbabilityOrigin,
        inherentConsequenceComment: inherentConsequenceComment,
        residualConsequenceComment: residualConsequenceComment,
        inherentProbabilityComment: inherentProbabilityComment,
        residualProbabilityComment: residualProbabilityComment
      };
    },
    getValueX: function getValueX(scores, itemId, name) {
      var _row$column$value, _row$column, _row$comment;
      if (!scores) return 0;
      var row = scores.find(function (score) {
        return score.rowId === itemId;
      });
      return {
        value: (_row$column$value = row === null || row === void 0 || (_row$column = row.column) === null || _row$column === void 0 ? void 0 : _row$column.value) !== null && _row$column$value !== void 0 ? _row$column$value : 0,
        origin: row ? row[name] : 0,
        comment: (_row$comment = row === null || row === void 0 ? void 0 : row.comment) !== null && _row$comment !== void 0 ? _row$comment : ''
      };
    },
    getUnderlyingRiskRowValue: function getUnderlyingRiskRowValue(childRisk, rowId, scoreType) {
      var _childRisk$scoreType, _score$column;
      if (!((_childRisk$scoreType = childRisk[scoreType]) !== null && _childRisk$scoreType !== void 0 && _childRisk$scoreType.length)) return null;
      var score = childRisk[scoreType].find(function (score) {
        return score.rowId === rowId;
      });
      return (score === null || score === void 0 || (_score$column = score.column) === null || _score$column === void 0 ? void 0 : _score$column.value) || null;
    }
  }
};