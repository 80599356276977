import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_question_creation = _resolveComponent("question-creation");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [_ctx.editQuestionModalShown ? (_openBlock(), _createBlock(_component_question_creation, {
    key: 0,
    ref: "questioCreation",
    "questionnaire-id": 1,
    "questionnaire-type": 1,
    questions: [],
    onCanceled: $options.toggleModal
  }, null, 8 /* PROPS */, ["onCanceled"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_button, {
    text: "Toggle QuestionCreation",
    onClick: $options.toggleModal
  }, null, 8 /* PROPS */, ["onClick"])]);
}