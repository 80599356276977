import { Colors } from '../../../../Colors';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
export default {
  name: 'GapTableRow',
  components: {
    TrafficLight: TrafficLight,
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink
  },
  introduction: '',
  description: '',
  token: '<gap-table-row></gap-table-row>',
  props: {
    gap: {
      required: true,
      type: Object,
      note: 'A gap object. needs to have a node property, with the associated node item'
    },
    gapArticle: {
      required: true,
      type: Object,
      node: 'A gap article object'
    },
    showRiskAssessment: {
      type: Boolean,
      required: true,
      default: true,
      note: 'defines if risk assessment info should be displayed'
    }
  },
  data: function data() {
    return {
      translate: {
        thisHasNotBeenAnsweredYet: this.$trans('This has not been answered yet'),
        requirement: this.$trans('Requirement')
      }
    };
  },
  computed: {
    gapColor: function gapColor() {
      return Colors.gapColor(this.gap.analysis_rag);
    }
  }
};