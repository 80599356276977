function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { Colors } from '@/Colors';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { MODULES, getModuleTitles } from '@/constants/modules';
import { getAssessmentProjectsOptions } from '@/constants/links/LinksModalSettings';
import { questionHasSomeDependencyChosen } from '@/utils/questionnaires';
import { sortNumericByPropComplex, sortByIndex } from '@/utils/sort';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { generateComputed } from '@/utils/base/generateComputed';
import { getInitiatives as _getInitiatives } from '@/api/risma/data';
import { getAll as _getControls } from '@/api/controls/controls';
import { getAllQuestionAnswersForNode, getQuestionnaireAssessments } from '@/api/compliance/questionnaire';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import SelectorTags from '@/components/Molecules/SelectorTags';
import AssessmentIndicators from '@/components/Molecules/Compliance/AssessmentIndicators';
import LinksModal from '@/components/Organisms/Links/LinksModal';
export default {
  name: 'QuestionnaireAssessmentPage',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    MainWell: MainWell,
    TrafficLight: TrafficLight,
    LinksModal: LinksModal,
    SelectorTags: SelectorTags,
    FeatherIcon: FeatherIcon,
    AssessmentIndicators: AssessmentIndicators,
    TinyMceBox: TinyMceBox
  },
  introduction: '',
  description: '',
  token: '<questionnaire-assessment-page></questionnaire-assessment-page>',
  props: {
    data: {
      required: true,
      type: Object,
      note: 'Current node object'
    }
  },
  emits: ['propertyChanged'],
  data: function data() {
    return {
      colors: Colors,
      loaded: false,
      questions: null,
      currentAssessments: [],
      assessmentIndicatorData: null,
      controls: [],
      initiatives: [],
      showLinksModal: false,
      preselectedLinks: [],
      addedControls: [],
      addedInitiatives: [],
      selectedModule: null,
      ModuleTitles: getModuleTitles(),
      MODULES: MODULES,
      isSortedByScore: false,
      translate: getTranslate['QuestionnaireAssessmentPage']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    allSettings: 'settings'
  })), mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserComplianceProjectById: 'complianceProjectById'
  })), generateComputed(['assessment'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    projectsOptions: function projectsOptions() {
      return getAssessmentProjectsOptions(this.allSettings, this.currentUser);
    },
    currentProject: function currentProject() {
      return this.data.compliance_projects_id ? this.currentUserComplianceProjectById(this.data.compliance_projects_id) : {};
    },
    answeredQuestions: function answeredQuestions() {
      var _this = this;
      return this.questions.filter(function (item) {
        return Object.keys(item.answers_options).length && questionHasSomeDependencyChosen(item, _this.questions);
      });
    },
    linksDataSet: function linksDataSet() {
      var _ref;
      return _ref = {}, _defineProperty(_ref, MODULES.EXECUTION, sortByIndex([].concat(_toConsumableArray(this.initiatives), _toConsumableArray(this.addedInitiatives)), 'path')), _defineProperty(_ref, MODULES.CONTROLS, [].concat(_toConsumableArray(this.controls), _toConsumableArray(this.addedControls))), _ref;
    },
    links: function links() {
      var _this2 = this;
      var links = {};
      Object.keys(this.linksDataSet).forEach(function (module) {
        links[module] = _this2.linksDataSet[module].filter(function (item) {
          return _this2.data.links[module] && _this2.data.links[module].find(function (id) {
            return item.id == id;
          });
        });
      });
      return links;
    },
    filteredLinkModules: function filteredLinkModules() {
      var _this3 = this;
      return Object.keys(this.links).filter(function (module) {
        return _this3.links[module].length;
      });
    },
    linksOptions: function linksOptions() {
      return this.linksDataSet[this.selectedModule] || [];
    },
    filteredAnsweredQuestions: function filteredAnsweredQuestions() {
      var list = _toConsumableArray(this.answeredQuestions);
      this.isSortedByScore && list.sort(function (a, b) {
        return b._totalScore - a._totalScore;
      });
      return list;
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this4 = this;
      this.getAll().then(function () {
        return _this4.getCurrentAssessments();
      }).then(function () {
        if (_this4.currentAssessments.length) {
          _this4.sortByValues(_this4.currentAssessments);
          _this4.assessmentIndicatorData = _this4.prepareIndicatorData();
        }
        _this4.loaded = true;
      }).catch(function (e) {
        throw e;
      });
    },
    getAll: function getAll() {
      var _this5 = this;
      return Promise.all([getAllQuestionAnswersForNode(this.data.compliance_projects_id, this.data.id, this.data.questionnaire.id).then(function (response) {
        return _this5.questions = _this5.addAdditionalFields(response.list);
      }), this.getInitiatives(), this.getControls()]);
    },
    getCurrentAssessments: function getCurrentAssessments() {
      var _this6 = this;
      return getQuestionnaireAssessments(this.data.questionnaire.id).then(function (_ref2) {
        var list = _ref2.list;
        _this6.currentAssessments = list.map(function (item) {
          return _objectSpread(_objectSpread({}, item), {}, {
            score: item.score + ''
          });
        });
      });
    },
    getInitiatives: function getInitiatives() {
      var _this7 = this;
      return _getInitiatives().then(function (_ref3) {
        var list = _ref3.list;
        return _this7.initiatives = sortByIndex(RismaControlsUtils.cloneEntities(list), 'path');
      });
    },
    getControls: function getControls() {
      var _this8 = this;
      return _getControls().then(function (_ref4) {
        var list = _ref4.list;
        _this8.controls = sortByIndex(RismaControlsUtils.cloneEntities(list), 'path');
      });
    },
    prepareIndicatorData: function prepareIndicatorData() {
      var _this9 = this;
      var result = [];
      this.currentAssessments.forEach(function (item, index) {
        if (index === 0) return;
        var nextIndex = index + 1;
        var next = _this9.currentAssessments[nextIndex];
        var oneStepInPercents = 100 / (_this9.currentAssessments.length - 1);
        result.push({
          title: next ? item.score : ">= ".concat(item.score),
          score: +item.score,
          minScore: _this9.currentAssessments[index - 1].score,
          color: item.color,
          isFilled: +item.score <= _this9.data.questionnaireScore.totalScore,
          width: oneStepInPercents
        });
      });
      return result;
    },
    addAdditionalFields: function addAdditionalFields(data) {
      var _this10 = this;
      return data.map(function (item) {
        var answersOptions = Object.keys(item.answers_options).reduce(function (prev, curr) {
          prev += _this10.getAnswereScore(item.questionobject, curr);
          return prev;
        }, 0);
        return _objectSpread(_objectSpread({}, item), {}, {
          _totalScore: answersOptions,
          _expanded: true
        });
      });
    },
    toggleVisibility: function toggleVisibility(item) {
      item._expanded = !item._expanded;
    },
    sortByValues: function sortByValues(data) {
      if (data && data.length) {
        sortNumericByPropComplex(data, 'score');
      }
      return data;
    },
    updateAssessment: function updateAssessment(e) {
      this.assessment = e;
    },
    getAnswereScore: function getAnswereScore(_ref5, answerId) {
      var optionDetails = _ref5.optionDetails;
      var option = optionDetails.find(function (answer) {
        return answer.id === +answerId;
      });
      return option.score;
    },
    toggleLinksModal: function toggleLinksModal() {
      this.showLinksModal = !this.showLinksModal;
    },
    setLinksOption: function setLinksOption(module) {
      this.selectedModule = module;
      this.preselectedLinks = _toConsumableArray(this.links[module]);
    },
    changeLinks: function changeLinks(_ref6) {
      var module = _ref6.module,
        links = _ref6.links;
      var value = _objectSpread(_objectSpread({}, this.data.links), {}, _defineProperty({}, module, links));
      this.$emit('propertyChanged', {
        property: 'links',
        value: value
      });
      this.toggleLinksModal();
    },
    deleteLink: function deleteLink(module, linkId) {
      var value = _objectSpread(_objectSpread({}, this.data.links), {}, _defineProperty({}, module, this.data.links[module].filter(function (id) {
        return id != linkId;
      })));
      this.$emit('propertyChanged', {
        property: 'links',
        value: value
      });
    },
    addNewItem: function addNewItem(item) {
      switch (item.objType) {
        case ObjectTypes.CONTROLS_CONTROL:
          this.addedControls.push(item);
          break;
        case ObjectTypes.EXECUTION_INITIATIVE:
          this.addedInitiatives.push(item);
          break;
      }
    }
  }
};