import { getTranslate } from './translate';
import { Colors } from '@/Colors';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { comparePathStrings } from '@/utils/compare';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import * as Utils from '@/utils/Utils';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import StaticTable from '@/components/Molecules/StaticTable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { customizeColumnsWithGapsFn, customizeDatasetWithGapsFn } from '@/components/Atoms/RismaDatatable/ExportUtils';
import { getItemGaps } from './SupervisorUtils';
import { prepareDateTimezone } from '@/utils/date';
import { DateFormats } from '@/constants/DateFormats';
export default {
  name: 'SupervisorControls',
  introduction: 'Supervisor controls dashboard',
  description: 'This sub-page shows the supervisor controls dashboards',
  token: '<supervisor-controls></supervisor-controls>',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    RismaDoughnutChart: RismaDoughnutChart,
    StaticTable: StaticTable,
    RismaDatatable: RismaDatatable,
    PreviewModalLink: PreviewModalLink,
    Trafficlight: Trafficlight
  },
  mixins: [RismaDatatableDefaultMixin],
  props: {
    controls: {
      type: Array,
      required: true
    },
    users: {
      type: Array,
      required: true,
      note: 'list of all selectable users'
    },
    organisations: {
      type: Array,
      required: true,
      note: 'list of all selectable organisations'
    },
    gapArticleMap: {
      type: Map,
      required: true,
      note: 'A list of gap articles'
    }
  },
  emits: ['dismissModal'],
  data: function data() {
    return {
      colors: [Colors.noGap, Colors.high, Colors.low, Colors.medium],
      colorsPending: [Colors.noGap, Colors.medium, Colors.high, Colors.notSet],
      colorsCompleted: [Colors.noGap, Colors.high],
      showDataControlstitle: '',
      showDataControls: [],
      columnSorting: [{
        key: 'cpath',
        sortingFunc: this.sortingByCpathObject
      }],
      showEmptyText: false,
      translate: getTranslate['SupervisorControls']()
    };
  },
  computed: {
    columns: function columns() {
      return {
        trafficlight: this.translate.trafficLight,
        cpath: this.translate.controlNumber,
        title: this.translate.title,
        description: this.translate.description,
        gaps: this.translate.gaps,
        organisation: this.translate.organisation,
        escalation: this.translate.escalation,
        responsible: this.translate.responsible,
        reviewers: this.translate.reviewers,
        frequency: this.translate.frequency,
        deadline: this.translate.deadline,
        review_deadline: this.translate.reviewDeadline,
        control_status: this.translate.controlStatus
      };
    },
    tableData: function tableData() {
      var _this = this;
      return this.controls.map(function (control) {
        return {
          trafficlight: {
            field: DatatableRenderer.prepareTrafficLightField(control.trafficLight),
            fieldText: Colors.trafficLights[control.trafficLight].label
          },
          cpath: {
            field: control.cpath
          },
          title: {
            activity: getFieldsFromObjectForPreview(control),
            fieldText: control.title
          },
          description: {
            field: control.description
          },
          gaps: {
            activities: getItemGaps(control.gap, _this.gapArticleMap)
          },
          organisation: {
            field: Utils.findPropsByIds(control.organisationIds, _this.organisations, 'visible_name')
          },
          escalation: {
            field: Utils.findPropsByIds(control.userIds.escalation, _this.users, 'display_name')
          },
          responsible: {
            field: Utils.findPropsByIds(control.userIds.responsible, _this.users, 'display_name')
          },
          reviewers: {
            field: Utils.findPropsByIds(control.userIds.review, _this.users, 'display_name')
          },
          frequency: {
            field: _this.$trans(RismaControlsUtils.frequency2text(control.frequency))
          },
          deadline: {
            field: prepareDateTimezone(control.deadline, control.deadlineTimezone, DateFormats.DATE_TIME_FORMAT)
          },
          review_deadline: {
            field: prepareDateTimezone(control.reviewDeadline, control.deadlineTimezone, DateFormats.DATE_TIME_FORMAT)
          },
          control_status: {
            field: _this.$trans(RismaControlsUtils.state2text(control.rismaState))
          }
        };
      });
    },
    chartData: function chartData() {
      return {
        controls_status: {
          title: this.translate.controlStatus,
          labels: [this.translate.completed, this.translate.pending + '/' + this.translate.rejected, this.translate.awaitingReview],
          datasets: [{
            backgroundColor: this.colors,
            data: [RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED).length, RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING).length, RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_READY_FOR_REVIEW).length]
          }],
          controls: [RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED), RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_READY_FOR_REVIEW)]
        },
        controls_reviews: {
          title: this.translate.reviews,
          labels: [this.translate.reviewCompleted, this.translate.rejected, this.translate.pendingReview],
          datasets: [{
            backgroundColor: this.colors,
            data: [RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED).length, RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_REVIEW_REJECTED).length, RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_READY_FOR_REVIEW).length]
          }],
          controls: [RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED), RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_REVIEW_REJECTED), RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_READY_FOR_REVIEW)]
        },
        controls_pending: {
          title: this.translate.pendingControls,
          labels: [this.translate.greenTrafficLight, this.translate.yellowTrafficLight, this.translate.redTrafficLight, this.translate.rejectedControls],
          datasets: [{
            backgroundColor: this.colorsPending,
            data: [RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.trafficLights.green).length, RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.trafficLights.yellow).length, RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.trafficLights.red).length, RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.states.STATE_REVIEW_REJECTED).length]
          }],
          controls: [RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.trafficLights.green), RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.trafficLights.yellow), RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.trafficLights.red), RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.states.STATE_REVIEW_REJECTED)]
        },
        controls_completed: {
          title: this.translate.completedControls,
          labels: [this.translate.completedOnTime, this.translate.completedAfterDeadline],
          datasets: [{
            backgroundColor: this.colorsCompleted,
            data: [RismaControlsUtils.filterControlsByCompletion(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED), 'ok').length, RismaControlsUtils.filterControlsByCompletion(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED), 'overdue').length]
          }],
          controls: [RismaControlsUtils.filterControlsByCompletion(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED), 'ok'), RismaControlsUtils.filterControlsByCompletion(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED), 'overdue')]
        }
      };
    },
    customExport: function customExport() {
      return {
        columns: customizeColumnsWithGapsFn,
        dataset: customizeDatasetWithGapsFn(this.gapArticleMap)
      };
    }
  },
  watch: {
    tableData: {
      deep: true,
      handler: function handler() {
        this.enhanceDataset(this.tableData, this.columns);
      }
    }
  },
  mounted: function mounted() {
    this.$forceUpdate();
    this.enhanceDataset(this.tableData, this.columns);
  },
  methods: {
    showData: function showData(item) {
      var _item$controls,
        _this2 = this;
      var segment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      this.showEmptyText = false;
      if ((_item$controls = item.controls) !== null && _item$controls !== void 0 && _item$controls.length) {
        this.showDataControlstitle = item.title;
        this.showDataControls = [];
        item.controls[segment].forEach(function (control) {
          _this2.showDataControls.push({
            trafficlight: {
              color: Colors.trafficLights[control.trafficLight].color
            },
            title: {
              data: getFieldsFromObjectForPreview(control),
              title: control.title,
              cpath: control.cpath
            },
            deadline: control.deadline
          });
        });
      } else {
        this.showDataControls = [];
        this.showEmptyText = true;
      }
    },
    sortingByCpathObject: function sortingByCpathObject(data, key) {
      return data.sort(function (a, b) {
        var x = Utils.purifyItemField(a[key], true);
        var y = Utils.purifyItemField(b[key], true);
        return comparePathStrings(x, y);
      });
    }
  }
};