function sortByTag(sections) {
  return sections.map(function (section) {
    var risksSorted = section.list.sort(function (a, b) {
      var aTag = a.tags[0];
      var bTag = b.tags[0];
      if (!aTag && !bTag) {
        return undefined;
      } else if (aTag && !bTag) {
        return -1;
      } else if (!aTag && bTag) {
        return 1;
      } else {
        return aTag.tag.localeCompare(bTag.tag);
      }
    });
    return {
      title: section.title,
      list: risksSorted
    };
  });
}
function isInt(n) {
  return n % 1 === 0;
}
function sortByRNO(a, b) {
  var isIntEqual = parseInt(a.rno) === parseInt(b.rno);
  var areBothRnoNumbers = typeof a.rno === 'number' && typeof b.rno === 'number';
  if (!isIntEqual || areBothRnoNumbers) {
    return Number(a.rno) - Number(b.rno);
  } else {
    var isARNOInt = isInt(a.rno);
    var isBRNOInt = isInt(b.rno);
    if (isARNOInt && !isBRNOInt) {
      return -1;
    } else if (!isARNOInt && isBRNOInt) {
      return 1;
    } else {
      var aFloatPart = a.rno.toString().split('.')[1];
      var bFloatPart = b.rno.toString().split('.')[1];
      return Number(aFloatPart) - Number(bFloatPart);
    }
  }
}
function sortByRiskNumber(sections) {
  return sections.map(function (section) {
    return {
      title: section.title,
      list: section.list.sort(sortByRNO)
    };
  });
}
function sortByUserId(sections, users, property) {
  return sections.map(function (section) {
    var risksSorted = section.list.sort(function (a, b) {
      var aUserId = a[property][0];
      var bUserId = b[property][0];
      if (!aUserId && !bUserId) {
        return undefined;
      } else if (aUserId && !bUserId) {
        return -1;
      } else if (!aUserId && bUserId) {
        return 1;
      } else {
        var aUserName = users.find(function (user) {
          return user.id === aUserId;
        }).display_name;
        var bUserName = users.find(function (user) {
          return user.id === bUserId;
        }).display_name;
        return aUserName.localeCompare(bUserName);
      }
    });
    return {
      title: section.title,
      list: risksSorted
    };
  });
}
export var sortBy = function sortBy(sections, _sortBy, assets) {
  if (_sortBy === 'tags') {
    return sortByTag(sections);
  } else if (_sortBy === 'responsible') {
    return sortByUserId(sections, assets.users, 'responsibleUserIds');
  } else if (_sortBy === 'accountable') {
    return sortByUserId(sections, assets.users, 'accountableUserIds');
  } else {
    return sortByRiskNumber(sections);
  }
};