import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-21da13f4"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "ia-processor-dpm"
};
var _hoisted_2 = {
  class: "row mb-4"
};
var _hoisted_3 = {
  class: "col-10"
};
var _hoisted_4 = {
  key: 0,
  class: "col-2"
};
var _hoisted_5 = {
  key: 0,
  class: "flex"
};
var _hoisted_6 = {
  class: "w-3/10 mr-12"
};
var _hoisted_7 = {
  class: "flex-grow"
};
var _hoisted_8 = {
  key: 1,
  class: "actions-panel"
};
var _hoisted_9 = {
  key: 2
};
var _hoisted_10 = {
  key: 1,
  class: "row mb-4"
};
var _hoisted_11 = {
  key: 0,
  class: "col-6"
};
var _hoisted_12 = {
  key: 1,
  class: "col-6"
};
var _hoisted_13 = {
  class: "row"
};
var _hoisted_14 = {
  class: "col-12 mb-4 dpm-questionnaire"
};
var _hoisted_15 = {
  class: "row"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_notification = _resolveComponent("notification");
  var _component_dpm_send_questionnaire_modal = _resolveComponent("dpm-send-questionnaire-modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_vertical_menu = _resolveComponent("vertical-menu");
  var _component_risma_step_progress_bar = _resolveComponent("risma-step-progress-bar");
  var _component_dpm_action_modal = _resolveComponent("dpm-action-modal");
  var _component_questionnaire = _resolveComponent("questionnaire");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    body: $data.translate.theRecipientOfTheAuditWillReceiveAnEmail,
    header: $data.translate.areYouSureYouWantToDeleteThisAudit,
    "button-ok-text": $data.translate.approve,
    onAccept: $options.deleteAudit,
    onDismiss: $options.dismissDeletion
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), $data.notificationError ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: "error",
    class: "mb-2"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("p", null, _toDisplayString($data.notificationError), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.showQuestionnaireModal ? (_openBlock(), _createBlock(_component_dpm_send_questionnaire_modal, {
    key: 2,
    "audit-questionnaires": $options.modalQuestionnaires,
    "prefill-company": $props.data.name || $props.data.title,
    "prefill-email": $props.data.email,
    "prefill-name": $props.data.contactName,
    onUpdate: $options.createNewDpmRequest,
    onDismiss: $options.dismissQuestionnaireModal
  }, null, 8 /* PROPS */, ["audit-questionnaires", "prefill-company", "prefill-email", "prefill-name", "onUpdate", "onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $options.auditTitle,
    type: "big"
  }, null, 8 /* PROPS */, ["title"])]), !$props.readOnly ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_button, {
    text: $data.translate.startNewAudit,
    class: "pull-right",
    type: "save",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.showQuestionnaireModal = true;
    })
  }, null, 8 /* PROPS */, ["text"])])) : _createCommentVNode("v-if", true)]), $options.existingAudits.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_title, {
    title: $data.translate.auditList,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_vertical_menu, {
    items: $data.menuItems,
    "selected-id": $data.currentAudit.id,
    removable: !$props.readOnly && !$props.data.archived,
    onChangeItem: $options.loadAudit,
    onRemoveItem: $options.showDeleteAlert
  }, null, 8 /* PROPS */, ["items", "selected-id", "removable", "onChangeItem", "onRemoveItem"])]), _createElementVNode("div", _hoisted_7, [$data.progressData.firstItem ? (_openBlock(), _createBlock(_component_risma_step_progress_bar, {
    key: 0,
    data: $data.progressData
  }, null, 8 /* PROPS */, ["data"])) : _createCommentVNode("v-if", true), $options.showActionPanel ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: $data.translate.actions,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), $data.progressData.secondItem.state === 'declined' ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $data.translate.extendDeadline,
    type: "save",
    class: "save-button",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.showActionModal('extendDeadline');
    })
  }, null, 8 /* PROPS */, ["text"])) : _createCommentVNode("v-if", true), $data.progressData.thirdItem.state === 'approved' && $data.currentAudit.moreInfoRequested === 0 ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 1,
    text: $data.translate.askForMoreInfo,
    type: "save",
    class: "save-button",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $options.showActionModal('askForMoreInfo');
    })
  }, null, 8 /* PROPS */, ["text"])) : _createCommentVNode("v-if", true), $data.currentAudit.moreInfoRequested === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("i", null, _toDisplayString($data.translate.nbYouHaveAskedForMoreInfoOnThisQuestionn), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $data.isModalOpened ? (_openBlock(), _createBlock(_component_dpm_action_modal, {
    key: 3,
    expires: $data.currentAudit.expires,
    message: $data.currentAudit.message,
    header: $options.dpmActionGroup[$data.dpmActionType].header,
    "main-text": $options.dpmActionGroup[$data.dpmActionType].mainText,
    onAcceptModal: $options.dpmActionGroup[$data.dpmActionType].accept,
    onDismiss: _cache[3] || (_cache[3] = function ($event) {
      return $data.isModalOpened = false;
    })
  }, null, 8 /* PROPS */, ["expires", "message", "header", "main-text", "onAcceptModal"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)])])) : _createCommentVNode("v-if", true), $options.existingAudits.length === 0 && $data.pageLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_10, [$props.readOnly ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("p", null, _toDisplayString($data.translate.nothingToShow), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("p", null, _toDisplayString($data.translate.youHaveNotCreatedAnyAuditsYetClickTheBut), 1 /* TEXT */)]))])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [$data.questions && $data.pageLoaded ? (_openBlock(), _createBlock(_component_questionnaire, {
    key: 0,
    questionnaire: $options.dataQuestionnaire,
    "information-assets": $data.informationAssets,
    "local-storage-key": "ia_processor_dpm_".concat($props.id, "_").concat($options.dataQuestionnaire.id),
    questions: $data.questions,
    "need-save-button": false,
    "read-only": true,
    title: $props.data.title || ''
  }, null, 8 /* PROPS */, ["questionnaire", "information-assets", "local-storage-key", "questions", "title"])) : _createCommentVNode("v-if", true)])]), _createElementVNode("div", _hoisted_15, [_createVNode(_component_router_view)])])]);
}