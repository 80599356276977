function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import VerticalMenu from '@/components/Molecules/VerticalMenu/VerticalMenu';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { KpiChartColors } from '@/constants/performance/dashboard';
import { DateFormats } from '@/constants/DateFormats';
import { createKpiDashboard, getKpiDashboards, getKpiOverview } from '@/api/reports/performance';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { formatTime } from '@/utils/date';
export default {
  name: 'PerformanceReport',
  components: {
    VerticalMenu: VerticalMenu,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  setup: function setup() {
    return {
      dailyMonthlyState: getLocalStorageComputed('monthly_daily_performance_flag', 'daily')
    };
  },
  data: function data() {
    return {
      topLinks: [{
        path: '/performance-report/dashboard',
        title: 'GRC Intelligence Center',
        icon: 'pie-chart'
      }],
      dashboards: [],
      performanceData: [],
      performanceLineData: {},
      isLoading: true,
      translate: {
        settings: this.$trans('Settings'),
        daily: this.$trans('Daily'),
        monthly: this.$trans('Monthly')
      }
    };
  },
  computed: {
    settingLink: function settingLink() {
      return [{
        path: '/performance-report/settings',
        title: this.translate.settings,
        icon: 'settings',
        routeName: 'PerformanceReportSettings'
      }];
    },
    dashboardLinks: function dashboardLinks() {
      return this.dashboards.reduce(function (acc, dashboard) {
        if (dashboard.showInMenu) {
          return [].concat(_toConsumableArray(acc), [{
            path: '/performance-report/dashboard/' + dashboard.id,
            title: dashboard.title,
            icon: 'target'
          }]);
        }
        return acc;
      }, []);
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.isLoading = true;
    this.getData().then(function () {
      return _this.isLoading = false;
    });
  },
  methods: {
    getAllDashboards: function getAllDashboards() {
      var _this2 = this;
      return getKpiDashboards().then(function (res) {
        return _this2.dashboards = res.list;
      });
    },
    handleDashboardCreation: function handleDashboardCreation(newItem) {
      var _this3 = this;
      return createKpiDashboard(newItem).then(function (newDashboard) {
        _this3.dashboards.push(newDashboard);
      });
    },
    getData: function getData() {
      var promises = [this.getPerformanceDashboard(), this.getAllDashboards()];
      return Promise.all(promises);
    },
    handleProgressTypeChanged: function handleProgressTypeChanged(value) {
      this.dailyMonthlyState = value;
      this.getPerformanceDashboard(true);
    },
    getPerformanceDashboard: function getPerformanceDashboard() {
      var _this4 = this;
      var isOnlyLineDataUpdated = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      return getKpiOverview("?progressType=".concat(this.dailyMonthlyState)).then(function (_ref) {
        var _ref$list = _ref.list,
          list = _ref$list === void 0 ? [] : _ref$list;
        var formattedList = _this4.formatKpiData(list, KpiChartColors);
        var filteredList = formattedList.filter(function (item) {
          return item.showOnDashboard;
        });
        if (!isOnlyLineDataUpdated) {
          _this4.performanceData = _this4.preparePerformanceData(formattedList);
        }
        _this4.performanceLineData = _this4.preparePerformanceLine(filteredList);
      });
    },
    preparePerformanceLine: function preparePerformanceLine(list) {
      var dateList = list.reduce(function (acc, dataItem) {
        return acc.concat(dataItem.kpiProgress.map(function (item) {
          return item.date_recorded;
        }));
      }, []);
      var uniqueDates = _toConsumableArray(new Set(dateList)).sort(function (a, b) {
        return new Date(a) - new Date(b);
      });
      return {
        labels: this.preparePerformanceLineLabels(uniqueDates),
        data: this.preparePerformanceLineData(list, uniqueDates)
      };
    },
    preparePerformanceLineLabels: function preparePerformanceLineLabels(dateList) {
      var labels = {
        labels: [],
        customLabels: []
      };
      dateList.forEach(function (date) {
        labels.labels.push(formatTime(date, DateFormats.DATE_DAY_FORMAT));
        labels.customLabels.push(formatTime(date, DateFormats.DATE_LONG_FORMAT));
      });
      return labels;
    },
    preparePerformanceLineData: function preparePerformanceLineData(dataList, dateList) {
      return dataList.map(function (item) {
        return {
          label: item.title,
          data: dateList.map(function (date) {
            var kpi = item.kpiProgress.find(function (item) {
              return item.date_recorded === date;
            });
            return kpi === null || kpi === void 0 ? void 0 : kpi.kpi_value;
          }),
          borderColor: item._colors[0],
          backgroundColor: item._colors[0]
        };
      });
    },
    formatKpiData: function formatKpiData(array, colorList) {
      return array.map(function (item, index) {
        var itemCopy = _objectSpread({}, item);
        itemCopy._colors = colorList[index % colorList.length] || colorList[0];
        return itemCopy;
      });
    },
    preparePerformanceData: function preparePerformanceData(list) {
      var ignoreVisibilityFlags = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      return list.reduce(function (acc, item) {
        if (!item.showOnDashboard && !ignoreVisibilityFlags) {
          return acc;
        }
        var kpiPercentRound = item.kpiPercent ? Math.round(item.kpiPercent) : 0;
        acc.push({
          id: item.id,
          title: item.title,
          description: item.kpiPercentDescription || '',
          data: [kpiPercentRound, 100 - kpiPercentRound],
          backgroundColor: item._colors
        });
        return acc;
      }, []);
    }
  }
};