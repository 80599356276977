function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaProgressBar from '@/components/Atoms/ProgressBar/RismaProgressBar';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { TileCardMixin } from '@/mixins/TileCardMixin';
import { ObjectTypes } from '@/constants/ObjectTypes';
import * as formatDate from '@/utils/date';
import { getIncidentStateName } from '@/utils/incident';
import { getReportTypeByURL } from '@/utils/report';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { getUsersFromIds } from '@/utils/format/objectsFromIds';
import { ComplianceControllers } from '@/constants/compliance/ComplianceControllers';
import { viewTypes as InitiativeViews } from '@/constants/ListViewType';
import { Colors } from '@/Colors';
import { getProcessLibraryTitle } from '@/utils/TitleFromSetting';
import { truncateHtml } from '@/utils/Utils';
export default {
  name: 'TileCard',
  introduction: 'Tile view of an activity',
  description: 'Shows a shadowed box of one activity with description, e.g. a control',
  token: '<tile-card :activity="activity"></tile-card>',
  components: {
    FeatherIcon: FeatherIcon,
    TrafficLight: TrafficLight,
    RismaTitle: RismaTitle,
    RismaProgressBar: RismaProgressBar,
    PreviewModalLink: PreviewModalLink,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  mixins: [TileCardMixin],
  props: {
    activity: {
      type: Object,
      required: true,
      note: 'Activity object'
    },
    watermarkImage: {
      type: String,
      required: false,
      default: 'none',
      note: 'Url of background watermark image'
    },
    disablePreview: {
      required: false,
      type: Boolean,
      default: false,
      note: 'if it has PreviewModalLink'
    },
    internalLinkEnabled: {
      required: false,
      type: Boolean,
      default: false,
      note: 'If it has link as title. Has lower than PreviewModalLink priority'
    },
    isApprovalFlowEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    activityStatus: {
      type: String,
      required: false,
      default: '',
      note: 'Determine status for activity'
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'A list of all activity types'
    }
  },
  emits: ['onCompleteControl', 'updateFromPreviewMode', 'onDismissModal', 'deleteItem', 'reloadRootPage'],
  data: function data() {
    return {
      Colors: Colors,
      InitiativeViews: InitiativeViews,
      objectTypes: ObjectTypes,
      translate: getTranslate['TileCard']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), {}, {
    title: function title() {
      if (this.activity.displayHeadline) {
        return this.activity.displayHeadline;
      }
      if (this.activity.title === undefined) {
        return this.activity.request.title;
      }
      return (this.activityIdTitle ? this.activityIdTitle + ' ' : '') + this.activity.title;
    },
    hoverTitle: function hoverTitle() {
      if (this.activity.confidential) {
        return DatatableRenderer.getItemHoverTitle(this.activity);
      }
      return this.title;
    },
    activityUrl: function activityUrl() {
      if (this.activity.request) {
        return '/' + this.activity.url.split('/').slice(3).join('/');
      }
      return this.activity.url || this.activity.reportUrl;
    },
    createdWithLabel: function createdWithLabel() {
      return "".concat(this.translate.created, ": ").concat(formatDate.toLocalDateTime(this.activity.createdAt));
    },
    occurredWithLabel: function occurredWithLabel() {
      return "".concat(this.translate.incidentOccurred, ": ").concat(formatDate.toLocalDateTime(this.activity.incidentHappenedAt) || this.translate.notSet);
    },
    accountableWithLabel: function accountableWithLabel() {
      var users = getUsersFromIds(this.activity.accountableUserIds, this.users);
      if (!users.length) return '';
      return "".concat(this.translate.accountable, ": ").concat(users.map(function (user) {
        return user.display_name;
      }).join(', '));
    },
    archivedWithLabel: function archivedWithLabel() {
      if (!this.activity.archived) return null;
      return "".concat(this.translate.incidentArchived, ": ").concat(formatDate.toLocalDateTime(this.activity.updatedAt));
    },
    expirationDateWithLabel: function expirationDateWithLabel() {
      var endDateTime = this.translate.notSet;
      if (this.activity.endDate) {
        endDateTime = formatDate.formatTimeToDateFormat(this.activity.endDate);
      }
      return "".concat(this.translate.expirationDate, ": ").concat(endDateTime);
    },
    isControl: function isControl() {
      return this.activity.objType === ObjectTypes.CONTROLS_CONTROL || this.activity.objType === ObjectTypes.CONTROLS_CONTROL_LIMITED;
    },
    isIncident: function isIncident() {
      return this.activity.objType === ObjectTypes.INCIDENTS_INCIDENT;
    },
    isContract: function isContract() {
      return this.activity.objType === ObjectTypes.CONTRACT;
    },
    isRisk: function isRisk() {
      return this.activity.objType === ObjectTypes.RISK_RISK || this.activity.objType === ObjectTypes.RISK_RISK_LIMITED;
    },
    isAction: function isAction() {
      return this.activity.objType === ObjectTypes.EXECUTION_INITIATIVE || this.activity.objType === ObjectTypes.EXECUTION_INITIATIVE_LIMITED;
    },
    reportType: function reportType() {
      if (!this.activity.reportUrl) return '';
      var reportType = getReportTypeByURL(this.activity.reportUrl);
      if (reportType === 'processlibrary') {
        return "RISMA".concat(getProcessLibraryTitle());
      }
      return "RISMA".concat(reportType);
    },
    status: function status() {
      if (this.isIncident) {
        return this.$trans(getIncidentStateName(this.activity.rismaState).name);
      }
      return this.activity.status || this.activityStatus;
    },
    statusTruncated: function statusTruncated() {
      return truncateHtml(this.status, 40, {
        html: true,
        maxLines: 3
      });
    },
    assetType: function assetType() {
      return ComplianceControllers[this.activity.objType];
    },
    projectTitle: function projectTitle() {
      if (!this.activity.project) return '';
      return this.activity.project.title == 'ERM' ? 'Enterprise risk management' : this.activity.project.title;
    },
    activityTitleLineThrough: function activityTitleLineThrough() {
      return this.isControl && !this.activity.active || this.isRisk && this.activity.closed;
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.activityTypes.find(function (at) {
        return +at.id === +_this.activity.activityType;
      });
    },
    descriptionTruncated: function descriptionTruncated() {
      return truncateHtml(this.activity.description, 40, {
        html: true,
        maxLines: 3
      });
    }
  }),
  methods: {
    showOptionalField: function showOptionalField(field) {
      var _this$currentActivity;
      if (!this.isAction || !this.currentActivityType) return true;
      return (_this$currentActivity = this.currentActivityType.optionalFields) === null || _this$currentActivity === void 0 ? void 0 : _this$currentActivity.find(function (item) {
        return item === field;
      });
    }
  }
};