import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-a0c450da"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "simple-view"
};
var _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_trafficlight = _resolveComponent("trafficlight");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.deleteIA ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    body: _ctx.deleteBody,
    header: _ctx.deleteHeader,
    "button-ok-text": $data.translate.delete,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.handleDelete,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.deleteIA = null;
    })
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept"])) : _createCommentVNode("v-if", true), _createVNode(_component_static_table, {
    dataset: $options.dataset,
    columns: $options.columns,
    "hover-titles": $data.hoverTitles
  }, {
    name: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createVNode(_component_preview_modal_link, {
        activity: slotData.data,
        onUpdated: _cache[1] || (_cache[1] = function ($event) {
          return $data.updated = true;
        }),
        onDismissModal: $options.dismissPreviewModal
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(slotData.name), 1 /* TEXT */)];
        }),

        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onDismissModal"])];
    }),
    actions: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [slotData.removable ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "x",
        class: "stroke-red stroke-3 cursor-pointer",
        onClick: function onClick($event) {
          return $options.deleteItem(slotData);
        }
      }, null, 8 /* PROPS */, ["onClick"])) : (_openBlock(), _createElementBlock("span", _hoisted_2))];
    }),
    trafficlight: _withCtx(function (_ref3) {
      var slotData = _ref3.slotData;
      return [_createVNode(_component_trafficlight, {
        color: slotData.color
      }, null, 8 /* PROPS */, ["color"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset", "columns", "hover-titles"])]);
}