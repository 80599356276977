function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import VLazyImage from 'v-lazy-image';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { Export } from '@/utils';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import ContactCards from '../ContactCards';
import HierarchicalList from '@/components/Molecules/HierarchicalList';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import Modal from '@/components/Molecules/Modal/Modal';
import { ModalDismissTypes } from '@/constants/ModalDismissTypes';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import FilePreviewModal from '@/components/Molecules/FilePreviewModal/FilePreviewModal';
import { getHtmlContent } from '@/utils/export';
import { getHierarchicalList, buildHierarchicalStructure } from '@/utils/format/hierarchicalList';
import { getInformationAssetsType } from '@/utils/InformationAssets';
import { getProjectDescriptionLabel, getProjectPurposeLabel, getProjectStatusLabel } from '@/utils/Compliance/complianceProject';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
export default {
  name: 'Article30Table',
  description: 'Component for displaying a table with the Article 30 and IA questions and question answers',
  components: {
    FilePreviewModal: FilePreviewModal,
    HierarchicalList: HierarchicalList,
    ContactCards: ContactCards,
    RismaButton: RismaButton,
    RismaDatatable: RismaDatatable,
    Modal: Modal,
    VLazyImage: VLazyImage,
    SaveReport: SaveReport
  },
  mixins: [RaciTitleMixin, RismaDatatableDefaultMixin, SaveReportMixin],
  props: {
    projectId: {
      type: [Number, String],
      required: false,
      default: null,
      note: 'The ID of the project'
    },
    rows: {
      type: Array,
      required: true
    },
    mode: {
      type: String,
      required: false,
      default: 'article30',
      note: 'Table mode'
    },
    reportUrl: {
      type: String,
      required: false,
      default: ''
    },
    reportOptions: {
      type: Object,
      default: null,
      note: 'Report options use to save the report'
    },
    reportType: {
      type: String,
      required: true,
      note: 'The type of report to display'
    },
    showControllersAndProcessors: {
      type: Boolean,
      required: false,
      default: false
    },
    includeDataFlow: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Show data flow column'
    },
    isAnsweredOnly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Show only answered questions'
    },
    includeRaciEmails: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Show RACI\'s emails columns'
    },
    isExtendedViewOnLoad: {
      type: Boolean,
      required: false,
      default: false
    },
    includeHelpText: {
      type: Boolean,
      required: false,
      default: false
    },
    stateLocal: {
      type: String,
      default: '',
      note: 'The name of a key in localStorage to save rismadatatable state'
    },
    nameColumnTitle: {
      type: String,
      default: getTranslate['node']().node,
      note: 'The title on the name\'s column'
    }
  },
  emits: ['dismissModal', 'setExtendedView', 'updateFromContactCards', 'dismissContractCardModal'],
  data: function data() {
    return {
      extendedView: false,
      wordModal: false,
      previewFile: null,
      buildHierarchicalList: buildHierarchicalStructure,
      questionsKeyPrefix: 'q',
      translate: getTranslate['Table']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, {
    currentUserComplianceProjectById: 'complianceProjectById'
  })), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    currentProject: function currentProject() {
      return this.currentUserComplianceProjectById(this.projectId);
    },
    isArticle30: function isArticle30() {
      return this.mode === 'article30';
    },
    isProcessorAudit: function isProcessorAudit() {
      return this.mode === 'processorAudit';
    },
    noDataFlowMessage: function noDataFlowMessage() {
      return this.translate.youHaveNoDataFlowsInThisProcess;
    },
    includeSubprocessors: function includeSubprocessors() {
      return this.getSettingValue('feature.ia_questions_include_subprocessors') !== 0;
    },
    descriptionLabel: function descriptionLabel() {
      return getProjectDescriptionLabel(this.currentProject);
    },
    purposeLabel: function purposeLabel() {
      return getProjectPurposeLabel(this.currentProject);
    },
    columns: function columns() {
      var _this = this;
      var columns = {
        node: this.nameColumnTitle
      };
      if (this.isProcessorAudit) {
        columns = _objectSpread(_objectSpread({}, columns), {}, {
          name: this.translate.name,
          email: this.translate.email
        });
      }
      if (this.isArticle30) {
        columns = _objectSpread(_objectSpread({}, columns), {}, {
          description: this.descriptionLabel,
          purpose: this.purposeLabel,
          status: getProjectStatusLabel(this.currentProject),
          responsibleUsers: this.translatablesWithRaci('responsible'),
          accountableUsers: this.translatablesWithRaci('accountable'),
          consultedUsers: this.translatablesWithRaci('consulted'),
          informedUsers: this.translatablesWithRaci('informed'),
          organisations: this.translate.organisations,
          tags: this.translate.tags
        });
        if (this.includeRaciEmails) {
          columns.responsibleEmails = this.translatablesWithRaci('responsible') + ' e-mails';
          columns.accountableEmails = this.translatablesWithRaci('accountable') + ' e-mails';
          columns.consultedEmails = this.translatablesWithRaci('consulted') + ' e-mails';
          columns.informedEmails = this.translatablesWithRaci('informed') + ' e-mails';
        }
        if (this.showControllersAndProcessors) {
          columns.dataProcessors = this.translate.dataProcessors;
          columns.dataControllers = this.translate.dataControllers;
        }
        if (this.includeDataFlow) {
          columns.dataFlow = this.translate.dataFlow;
        }
      }
      this.rows[0].cells.forEach(function (c, i) {
        var uniqueKey = _this.questionsKeyPrefix + i;
        if (_this.includeHelpText) {
          columns[uniqueKey] = "".concat(c.number, ". ").concat(c.question, " ").concat(c.helpText ? "(".concat(c.helpText, ")") : '');
        } else {
          columns[uniqueKey] = c.number + '. ' + c.question;
        }
      });
      return columns;
    },
    columnsMeta: function columnsMeta() {
      var result = [{
        targets: ['description'],
        width: '600px'
      }];
      if (this.includeHelpText) {
        result.push({
          targets: this.uniqueKeys,
          width: '300px'
        });
      }
      return result;
    },
    dataset: function dataset() {
      var _this2 = this;
      var dataset = this.rows.map(function (node) {
        var data = {
          node: {
            field: {
              node: buildHierarchicalStructure(node)
            },
            fieldText: getHierarchicalList(node)
          }
        };
        if (_this2.isProcessorAudit) {
          data = _objectSpread(_objectSpread({}, data), {}, {
            name: {
              field: "<span>".concat(node.fullname, "</span>"),
              fieldText: node.fullname
            },
            email: {
              field: "<span>".concat(node.email, "</span>"),
              fieldText: node.email
            }
          });
        }
        if (_this2.isArticle30) {
          data = _objectSpread(_objectSpread({}, data), {}, {
            description: {
              field: node.description
            },
            purpose: {
              field: node.purpose
            },
            status: {
              field: node.status
            },
            tags: {
              field: node.tags
            }
          });
          if (_this2.includeRaciEmails) {
            ['responsibleUsers', 'responsibleEmails', 'accountableUsers', 'accountableEmails', 'consultedUsers', 'consultedEmails', 'informedUsers', 'informedEmails', 'organisations'].forEach(function (item) {
              return _this2.addNodeToTheData(node, item, data);
            });
          } else {
            ['responsibleUsers', 'accountableUsers', 'consultedUsers', 'informedUsers', 'organisations'].forEach(function (item) {
              return _this2.addNodeToTheData(node, item, data);
            });
          }
          if (_this2.showControllersAndProcessors) {
            var _node$dataControllers;
            data.dataProcessors = _this2.getDataProcessorsField(node.dataProcessors, node.companiesLinkedToDataProcessors);
            data.dataControllers = {
              field: {
                hasData: node.dataControllers && node.dataControllers.length,
                data: {
                  controllers: node.dataControllers
                }
              },
              fieldText: (_node$dataControllers = node.dataControllers) !== null && _node$dataControllers !== void 0 && _node$dataControllers.length ? node.dataControllers.map(function (dc) {
                return dc.title;
              }).join(', ') : ''
            };
          }
          if (_this2.includeDataFlow) {
            data.dataFlow = {
              field: node.dataFlowUrl
            };
          }
        }
        node.cells.forEach(function (cell, i) {
          var uniqueKey = _this2.questionsKeyPrefix + i;
          var field = '';
          if (cell.answers && cell.answers.length) {
            field += '<ul>';
            Object.keys(cell.answers).forEach(function (key) {
              field += "<li>".concat(cell.answers[key], "</li>");
            });
            field += '</ul>';
          }
          data[uniqueKey] = {
            field: {
              value: field,
              id: node.id
            },
            fieldText: cell.answers.join(', ')
          };
          if (_this2.hasValues(cell.informationAssets)) {
            data[uniqueKey].field = _objectSpread(_objectSpread({}, data[uniqueKey].field), {}, {
              data: _this2.prepareIA(cell.informationAssets)
            });
            data[uniqueKey].fieldText = _this2.formatIaFieldText(cell.informationAssets);
          }
        });
        return data;
      });
      return dataset;
    },
    uniqueKeys: function uniqueKeys() {
      var _this3 = this;
      return this.rows[0].cells.map(function (c, i) {
        return _this3.questionsKeyPrefix + i;
      });
    }
  }),
  watch: {
    extendedView: function extendedView() {
      if (this.extendedView) {
        this.enhanceDataset(this.dataset, this.columns);
        this.loadSavedReport();
      }
      this.$emit('setExtendedView', this.extendedView);
    }
  },
  mounted: function mounted() {
    if (this.isExtendedViewOnLoad) this.extendedView = this.isExtendedViewOnLoad;
  },
  methods: {
    prepareIA: function prepareIA(dataObject) {
      if (this.includeSubprocessors) {
        return dataObject;
      }
      return _objectSpread(_objectSpread({}, dataObject), {}, _defineProperty({}, AssetsTypes.PROCESSORS, dataObject[AssetsTypes.PROCESSORS] ? dataObject[AssetsTypes.PROCESSORS].filter(function (processor) {
        return !processor.parents.length;
      }) : []));
    },
    getDataProcessorsField: function getDataProcessorsField() {
      var dataProcessors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var companies = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var hasData = (dataProcessors === null || dataProcessors === void 0 ? void 0 : dataProcessors.length) || companies.length;
      var dataProcessorsString = dataProcessors === null || dataProcessors === void 0 ? void 0 : dataProcessors.map(function (dp) {
        return dp.title;
      });
      var companiesString = companies === null || companies === void 0 ? void 0 : companies.map(function (dp) {
        return dp.name;
      });
      return {
        field: {
          hasData: hasData,
          data: {
            processors: dataProcessors,
            companies: companies
          }
        },
        fieldText: [].concat(_toConsumableArray(dataProcessorsString), _toConsumableArray(companiesString)).join(', ')
      };
    },
    hasValues: function hasValues(item) {
      var iaArray = Object.keys(item || {});
      var procIndex = iaArray.indexOf(AssetsTypes.PROCESSORS);
      if (this.includeSubprocessors || procIndex === -1) {
        return item ? Object.values(item).some(function (a) {
          return a.length > 0;
        }) : false;
      }
      iaArray.splice(procIndex, 1);
      return item[AssetsTypes.PROCESSORS].length && item[AssetsTypes.PROCESSORS].some(function (item) {
        return !item.parents.length;
      }) || iaArray.some(function (iaType) {
        return item[iaType].length > 0;
      });
    },
    setPreviewFile: function setPreviewFile(imageUrl) {
      this.previewFile = {
        fileSrc: imageUrl,
        fileType: 'image'
      };
    },
    formatIaFieldText: function formatIaFieldText(informationAssets) {
      var _this4 = this;
      var result = '';
      var keys = Object.keys(informationAssets);
      keys.forEach(function (key) {
        if (!informationAssets[key].length) return;
        informationAssets[key].forEach(function (ia) {
          result += _this4.iaFieldCardText(ia, key) + '\n';
        });
        result = result.slice(0, -2);
        result += '; ';
      });
      result = result.slice(0, -2);
      return result;
    },
    iaFieldCardText: function iaFieldCardText(ia, type) {
      var config = {
        title: {
          value: ia.name ? ia.name : ia.title
        },
        type: {
          label: this.translate.type,
          value: getInformationAssetsType(ia, type)
        },
        description: {
          label: this.translate.description,
          value: ia.description
        },
        address: {
          label: this.translate.address,
          value: ia.address
        },
        regNo: {
          label: this.translate.regNumber,
          value: ia.regNo
        },
        vatNo: {
          label: this.translate.vatNumber,
          value: ia.countryCode && ia.vatNo ? "".concat(ia.countryCode, "-").concat(ia.vatNo) : ''
        }
      };
      return Object.values(config).filter(function (item) {
        return item.value;
      }).map(function (item) {
        return "".concat(item.label ? item.label + ': ' : '').concat(item.value);
      }).join('\n');
    },
    fnDrawCallback: function fnDrawCallback(e) {
      if (!e) {
        return;
      }
      var container = e.nTableWrapper;
      var windowHeight = window.innerHeight;
      var topWrapperHeight = document.querySelector('.topwrapper').offsetHeight;
      var mainContentTopMargin = parseInt(getComputedStyle(document.querySelector('.main-content')).marginTop, 10);
      var scrollHeadHeight = container.querySelector('.dataTables_scrollHead').offsetHeight;
      var dataTableFilter = container.querySelector('.dataTables_filter').offsetHeight;
      var footerInfo = container.querySelector('.dataTables_info').offsetHeight;
      var height = windowHeight - topWrapperHeight - mainContentTopMargin;
      height = height - scrollHeadHeight - dataTableFilter - footerInfo;
      if (height > 0) {
        container.querySelector('.dataTables_scrollBody').style.maxHeight = height + 'px';
      }
    },
    // It is needed to add delegation elements to DataTable.vue
    addNodeToTheData: function addNodeToTheData(node, name, data) {
      var field = '';
      if (node[name]) {
        field += '<ul>';
        node[name].forEach(function (el) {
          field += '<li>' + el + '</li>';
        });
        field += '</ul>';
        data[name] = {
          field: field,
          fieldText: node[name].join(', ')
        };
      }
    },
    /**
     * Method for exporting the table data to excel
     */
    handleExportToExcel: function handleExportToExcel() {
      var _this5 = this;
      var htmlContent = getHtmlContent('table');
      htmlContent.querySelectorAll('.dataFlowColumn').forEach(function (column) {
        column.remove();
      });
      htmlContent.querySelectorAll('.hierarchical-list-data').forEach(function (column, index) {
        column.innerHTML = getHierarchicalList(_this5.rows[index]);
      });
      return Export.toExcel(htmlContent);
    },
    /**
     * Method for exporting the table data to word
     */
    handleExportToWordButton: function handleExportToWordButton(clickedButton) {
      var url = '/api/2.0' + this.reportUrl + '&format=ooxml';
      if (clickedButton === ModalDismissTypes.ICON) {
        // if close icon clicked
        this.toogleExportToWordModal();
        return;
      } else if (clickedButton === ModalDismissTypes.BUTTON) {
        //if no clicked (for yes clickedButtom == undefined)
        url += '&includeNodesWithoutAnswers=true';
      }
      url += '&includeDataFlow=' + (this.includeDataFlow ? 'true' : 'false');
      url += '&onlyAnsweredQuestions=' + (this.isAnsweredOnly ? 'true' : 'false');
      window.open(url, 'download');
      this.toogleExportToWordModal();
    },
    /**
     * Method for printing the table data
     */
    handlePrintTable: function handlePrintTable() {
      return Export.toPrint('tableContainer');
    },
    replaceHtml: function replaceHtml(string) {
      return string.replace(/div/g, 'section');
    },
    translatablesWithRaci: function translatablesWithRaci(column) {
      if (column == 'responsible') {
        return this.responsibleTitle(true);
      }
      if (column == 'accountable') {
        return this.accountableTitle(true);
      }
      if (column == 'consulted') {
        return this.consultedTitle(true);
      }
      if (column == 'informed') {
        return this.informedTitle(true);
      }
      return this.translatables[column] || column;
    },
    getLinkToRoute: function getLinkToRoute(answer) {
      var startOfTheLink = answer.indexOf('to=') + 4;
      var endOfTheLink = answer.indexOf('"', startOfTheLink);
      var cut = answer.slice(startOfTheLink, endOfTheLink);
      return cut;
    },
    getTitleToRoute: function getTitleToRoute(answer) {
      var startOfTheTitle = answer.indexOf('>') + 1;
      var endOfTheTitle = answer.indexOf('</router-link');
      var cut = answer.slice(startOfTheTitle, endOfTheTitle);
      return cut;
    },
    toogleExportToWordModal: function toogleExportToWordModal() {
      this.wordModal = !this.wordModal;
    }
  }
};