export var STANDARD_VIEWS = [{
  title: 'Tiles view',
  name: 'tiles'
}, {
  title: 'Compact view',
  name: 'compact'
}, {
  title: 'Expanded view',
  name: 'expanded'
}];
export var INCIDENTS_VIEWS = [{
  title: 'Tiles view',
  name: 'tiles'
}, {
  title: 'Compact view',
  name: 'compact'
}];
export var CONTROLS_VIEWS = [].concat(STANDARD_VIEWS, [{
  title: 'Table view',
  name: 'rismatable'
}, {
  title: 'Tree view',
  name: 'tree'
}]);
export var MY_RISKS_VIEWS = [{
  title: 'Tiles view',
  name: 'tiles'
}, {
  title: 'Expanded view',
  name: 'expanded'
}];
export var MY_ACTIONS_VIEWS = [{
  title: 'Tiles view',
  name: 'tiles'
}, {
  title: 'Expanded view',
  name: 'expanded'
}, {
  title: 'Roadmap view',
  name: 'roadmap'
}];
export var OVERALL_INITIATIVES_VIEWS = [{
  title: 'Tiles view',
  name: 'tiles'
}, {
  title: 'Expanded view',
  name: 'expanded'
}, {
  title: 'Compact view',
  name: 'compact'
}, {
  title: 'Roadmap view',
  name: 'roadmap'
}, {
  title: 'Tree view',
  name: 'tree'
}];
export var AWARENESS_DASHBOARDS_VIEWS = [{
  title: 'Chart view',
  name: 'tiles'
}, {
  title: 'Table view',
  name: 'compact'
}];
export var OVERVIEW_CONTRACTS_VIEWS = [{
  title: 'Compact view',
  name: 'compact'
}, {
  title: 'Datatable view',
  name: 'datatable'
}];
export var MINI_TREE_VIEWS = [{
  title: 'Compact view',
  name: 'compact'
}, {
  title: 'Expanded view',
  name: 'expanded'
}];