function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import * as Utils from '@/utils/Utils';
import { getAssociationName } from '@/utils/controls/associations';
export default {
  name: 'ControlFilters',
  description: '',
  token: '<control-filters />',
  components: {
    RismaToggle: RismaToggle,
    RismaTitle: RismaTitle,
    RismaSelect: RismaSelect,
    SingleSelect: SingleSelect,
    InputField: InputField,
    FeatherIcon: FeatherIcon
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    modelValue: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    stateLocal: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['update:modelValue', 'groupControls', 'resetFilters'],
  data: function data() {
    return {
      searchTimeout: null,
      translate: getTranslate['ControlFilters']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    showOverviewOptionsTitle: function showOverviewOptionsTitle() {
      return this.$trans("".concat(this.modelValue.showOverviewOptions ? 'Hide' : 'Show', " overview options"));
    },
    accountTitle: function accountTitle() {
      return getAssociationName(this.getSettingValue('risma.account_to_name'), 'Accounts');
    },
    processesTitle: function processesTitle() {
      return getAssociationName(this.getSettingValue('risma.process_to_name'), 'Processes');
    },
    risksTitle: function risksTitle() {
      return getAssociationName(this.getSettingValue('risma.risk_to_name'), 'Control risks');
    }
  }),
  mounted: function mounted() {
    this.setFiltersFromState();
  },
  methods: {
    filterByEntity: function filterByEntity(e, entity) {
      var _this = this;
      this.$emit('update:modelValue', _objectSpread(_objectSpread({}, this.modelValue), {}, _defineProperty({}, entity, e)));
      this.$nextTick(function () {
        _this.saveState();
      });
    },
    prepareState: function prepareState() {
      var _this$data$accounts, _this$modelValue$acco, _this$data$processes, _this$modelValue$proc, _this$data$risks, _this$modelValue$risk;
      var result = {
        organisations: this.modelValue.organisations.map(function (item) {
          return item.id;
        }),
        tags: this.modelValue.tags.map(function (item) {
          return item.id;
        }),
        mother: this.modelValue.mother,
        showOverviewOptions: this.modelValue.showOverviewOptions
      };
      if (this.data.users) result.responsible = this.modelValue.responsible.map(function (item) {
        return item.id;
      });
      if (this.data.showDeactivated) result.showDeactivated = this.modelValue.showDeactivated;
      if (this.data.deadline) result.deadline = this.modelValue.deadline;
      if (this.data.groupByOptions) result.groupBy = this.modelValue.groupBy;
      if (this.data.sortByOptions) result.sortBy = this.modelValue.sortBy;
      if ((_this$data$accounts = this.data.accounts) !== null && _this$data$accounts !== void 0 && _this$data$accounts.length) result.accounts = ((_this$modelValue$acco = this.modelValue.accounts) === null || _this$modelValue$acco === void 0 ? void 0 : _this$modelValue$acco.map(function (item) {
        return item.id;
      })) || [];
      if ((_this$data$processes = this.data.processes) !== null && _this$data$processes !== void 0 && _this$data$processes.length) result.processes = ((_this$modelValue$proc = this.modelValue.processes) === null || _this$modelValue$proc === void 0 ? void 0 : _this$modelValue$proc.map(function (item) {
        return item.id;
      })) || [];
      if ((_this$data$risks = this.data.risks) !== null && _this$data$risks !== void 0 && _this$data$risks.length) result.risks = ((_this$modelValue$risk = this.modelValue.risks) === null || _this$modelValue$risk === void 0 ? void 0 : _this$modelValue$risk.map(function (item) {
        return item.id;
      })) || [];
      return JSON.stringify(result);
    },
    saveState: function saveState() {
      var data = this.prepareState();
      if (this.stateLocal) {
        localStorage.setItem(this.stateLocal, data);
      }
    },
    setFiltersFromState: function setFiltersFromState() {
      var state = JSON.parse(localStorage.getItem(this.stateLocal));
      if (!state) return;
      var result = {
        organisations: [],
        tags: [],
        mother: null,
        responsible: [],
        search: '',
        deadline: null,
        showDeactivated: false,
        groupBy: null,
        sortBy: null,
        showOverviewOptions: false,
        processes: []
      };
      if (state.organisations && state.organisations.length) result.organisations = Utils.filterArrayByListOfPropValues(this.data.organisations, state.organisations);
      if (state.responsible && state.responsible.length) result.responsible = Utils.filterArrayByListOfPropValues(this.data.users, state.responsible);
      if (state.tags && state.tags.length) result.tags = Utils.filterArrayByListOfPropValues(this.data.tags, state.tags);
      if (state.mother) result.mother = state.mother;
      if (state.showDeactivated) result.showDeactivated = state.showDeactivated;
      if (state.deadline) result.deadline = state.deadline;
      if (state.groupBy) result.groupBy = state.groupBy;
      if (state.sortBy) result.sortBy = state.sortBy;
      if (state.showOverviewOptions) result.showOverviewOptions = state.showOverviewOptions;
      if (state.accounts && state.accounts.length) result.accounts = Utils.filterArrayByListOfPropValues(this.data.accounts, state.accounts);
      if (state.processes && state.processes.length) result.processes = Utils.filterArrayByListOfPropValues(this.data.processes, state.processes);
      if (state.risks && state.risks.length) result.risks = Utils.filterArrayByListOfPropValues(this.data.risks, state.risks);
      this.$emit('update:modelValue', result);
      this.$emit('groupControls');
    },
    groupBy: function groupBy(e) {
      this.filterByEntity(e, 'groupBy');
      this.$emit('groupControls');
    },
    resetFilters: function resetFilters() {
      var _this2 = this;
      this.$emit('resetFilters');
      this.$nextTick(function () {
        _this2.saveState();
      });
    },
    handleSearch: function handleSearch(value) {
      var _this3 = this;
      this.searchTimeout && clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(function () {
        _this3.modelValue.search = value;
      }, 200);
    }
  }
};