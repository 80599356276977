import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-564c1ac8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "list-options"
};
var _hoisted_2 = {
  class: "list-options-body"
};
var _hoisted_3 = {
  class: "list-options-content"
};
var _hoisted_4 = {
  class: "flex mb-5"
};
var _hoisted_5 = {
  class: "font-bold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_Transition, {
    name: "fade"
  }, {
    default: _withCtx(function () {
      return [_withDirectives(_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        class: "flex items-center cursor-pointer",
        onClick: _cache[0] || (_cache[0] = function () {
          return $options.resetOptions && $options.resetOptions.apply($options, arguments);
        })
      }, [_createVNode(_component_feather_icon, {
        icon: "rotate-ccw",
        class: "text-gray-610 stroke-2 mr-1"
      }), _createElementVNode("span", _hoisted_5, _toDisplayString($data.translate.resetOptions), 1 /* TEXT */)])])], 512 /* NEED_PATCH */), [[_vShow, $props.isExpanded]])];
    }),
    _: 3 /* FORWARDED */
  })]);
}