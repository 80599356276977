import { accessLevels } from '@/constants/compliance/SecurityGroups';
import { typesAccessProjects } from '@/constants/compliance/TypesAccessProjects';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
export default {
  name: 'SecurityGroupProjects',
  components: {
    RismaTitle: RismaTitle,
    InputField: InputField,
    RismaButton: RismaButton,
    SingleSelect: SingleSelect
  },
  props: {
    projects: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['submit'],
  data: function data() {
    return {
      accessLevels: accessLevels,
      translate: {
        update: this.$trans('Update')
      }
    };
  },
  computed: {
    projectsFormatted: function projectsFormatted() {
      return this.projects.map(function (project) {
        return {
          projectAccessId: project.id,
          accessLevel: project.accessLevel,
          securityGroupId: project.usersSecuritygroupsId,
          projectId: project.complianceProjectsId || project.riskProjectsId,
          projectTitle: project.complianceProjectsTitle || project.riskProjectsTitle,
          typeAccessProjects: project.complianceProjectsId ? typesAccessProjects.compliance : typesAccessProjects.risk
        };
      });
    },
    complianceProjects: function complianceProjects() {
      return this.projectsFormatted.filter(function (project) {
        return project.typeAccessProjects === typesAccessProjects.compliance;
      });
    },
    riskProjects: function riskProjects() {
      return this.projectsFormatted.filter(function (project) {
        return project.typeAccessProjects === typesAccessProjects.risk;
      });
    },
    projectsGrouped: function projectsGrouped() {
      return [{
        headline: 'Compliance',
        projects: this.complianceProjects
      }, {
        headline: 'Risk',
        projects: this.riskProjects
      }];
    }
  },
  methods: {
    handleUpdate: function handleUpdate(data) {
      this.$emit('submit', Object.assign({}, data));
    }
  }
};