function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { getContacts as _getContacts, addContact as _addContact, editContact as _editContact, deleteContact as _deleteContact } from '@/api/admin/companies';
import CompanyContactModal from '@/components/Organisms/Companies/CompanyContactModal';
import StaticTable from '@/components/Molecules/StaticTable';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Alert from '@/components/Molecules/Modal/Alert';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
export default {
  name: 'CompanyContacts',
  components: {
    Alert: Alert,
    CompanyContactModal: CompanyContactModal,
    StaticTable: StaticTable,
    FeatherIcon: FeatherIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['propertyChanged', 'validationError', 'editContact'],
  data: function data() {
    return {
      isLoaded: false,
      contacts: [],
      showContactModal: false,
      showDeleteContactAlert: false,
      contactToEdit: {},
      contactToDelete: {},
      columnsMaxWidth: {
        delete: '30px'
      },
      translate: getTranslate['CompanyContacts']()
    };
  },
  computed: {
    columns: function columns() {
      return ['#', this.translate.name, this.translate.title, this.translate.email, this.translate.phone, ''];
    },
    dataset: function dataset() {
      return this.contacts.map(function (item) {
        return {
          id: item.id,
          name: {
            name: item.name,
            id: item.id
          },
          title: item.title,
          email: item.email,
          phone: item.phone,
          delete: {
            id: item.id
          }
        };
      });
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.getContacts().finally(function () {
      return _this.isLoaded = true;
    });
  },
  methods: {
    getContacts: function getContacts() {
      var _this2 = this;
      return _getContacts(this.data.id).then(function (_ref) {
        var list = _ref.list;
        return _this2.contacts = list;
      }).catch(function (error) {
        _this2.$emit('validationError', 'error', error.fullError.message);
      });
    },
    addContact: function addContact(contactData) {
      var _this3 = this;
      _addContact(this.data.id, contactData).then(function (response) {
        _this3.contacts.push(response);
        _this3.$emit('editContact', response);
        _this3.toggleShowContactModal();
      });
    },
    editContact: function editContact(contactData) {
      var _this4 = this;
      _editContact(contactData).then(function (contact) {
        var contactIndex = _this4.contacts.findIndex(function (c) {
          return c.id == contact.id;
        });
        if (contactIndex !== -1) {
          _this4.contacts[contactIndex] = contact;
        }
        _this4.$emit('editContact', contact);
        _this4.toggleShowContactModal();
      });
    },
    deleteContact: function deleteContact() {
      var _this5 = this;
      _deleteContact(this.contactToDelete.id).then(function (response) {
        _this5.toggleDeleteContactAlert();
        _this5.contacts = _this5.contacts.filter(function (contact) {
          return contact.id !== response.id;
        });
      }).catch(function () {
        _this5.toggleDeleteContactAlert();
        _this5.$emit('validationError', 'error', 'Contact person can’t be deleted due to relation to existing contract');
      });
    },
    handleAddContact: function handleAddContact() {
      this.contactToEdit = {};
      this.toggleShowContactModal();
    },
    handleEditContact: function handleEditContact(id) {
      var contact = this.contacts.find(function (contact) {
        return contact.id === id;
      });
      this.contactToEdit = _objectSpread({}, contact);
      this.showContactModal = true;
    },
    handleDeleteContact: function handleDeleteContact(id) {
      this.showDeleteContactAlert = true;
      this.contactToDelete = this.contacts.find(function (contact) {
        return contact.id === id;
      });
    },
    handleSaveContact: function handleSaveContact(contactData) {
      if (!contactData.id) {
        this.addContact(contactData);
        return;
      }
      this.editContact(contactData);
    },
    toggleShowContactModal: function toggleShowContactModal() {
      this.showContactModal = !this.showContactModal;
    },
    toggleDeleteContactAlert: function toggleDeleteContactAlert() {
      this.showDeleteContactAlert = !this.showDeleteContactAlert;
    }
  }
};