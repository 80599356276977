import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
export var LiaTiaGeneric = {
  lia: {
    title: 'LIA',
    id: 2,
    projectType: ProjectTypes.GDPR,
    enabled: {
      setting: 'feature.lia_enabled',
      value: false
    },
    questionnaire: {
      setting: 'feature.lia_questionnaire_id',
      value: ''
    },
    question: {
      setting: 'feature.lia_question_id',
      value: '',
      options: []
    },
    questionAnswer: {
      setting: 'feature.lia_question_answer_id',
      value: [],
      valueArr: [],
      options: [],
      multiple: true
    },
    gapSchema: {
      setting: 'feature.lia_gap_schema_id',
      value: ''
    },
    gapArticle: {
      setting: 'feature.lia_gap_article_id',
      value: '',
      options: []
    },
    assessmentQuestionnaire: {
      setting: 'feature.lia_assessment_questionnaire_id',
      value: '',
      options: []
    }
  },
  tia: {
    title: 'TIA (nodes)',
    id: 1,
    projectType: ProjectTypes.GDPR,
    enabled: {
      setting: 'feature.tia_node_enabled',
      value: false
    },
    questionnaire: {
      setting: 'feature.tia_node_questionnaire_id',
      value: ''
    },
    question: {
      setting: 'feature.tia_node_question_id',
      value: '',
      options: []
    },
    questionAnswer: {
      setting: 'feature.tia_node_question_answer_id',
      value: [],
      valueArr: [],
      options: [],
      multiple: true
    },
    gapSchema: {
      setting: 'feature.tia_node_gap_schema_id',
      value: ''
    },
    gapArticle: {
      setting: 'feature.tia_node_gap_article_id',
      value: '',
      options: []
    },
    assessmentQuestionnaire: {
      setting: 'feature.tia_node_assessment_questionnaire_id',
      value: '',
      options: []
    }
  },
  tiaProc: {
    title: 'TIA (processors)',
    id: 3,
    projectType: ProjectTypes.DATAPROCESSOR,
    enabled: {
      setting: 'feature.tia_processor_enabled',
      value: false
    },
    questionnaire: {
      setting: 'feature.tia_processor_questionnaire_id',
      value: ''
    },
    question: {
      setting: 'feature.tia_processor_question_id',
      value: '',
      options: []
    },
    questionAnswer: {
      setting: 'feature.tia_processor_question_answer_id',
      value: [],
      valueArr: [],
      options: [],
      multiple: true
    },
    gapSchema: {
      setting: 'feature.tia_processor_gap_schema_id',
      value: ''
    },
    gapArticle: {
      setting: 'feature.tia_processor_gap_article_id',
      value: '',
      options: []
    },
    assessmentQuestionnaire: {
      setting: 'feature.tia_processor_assessment_questionnaire_id',
      value: '',
      options: []
    }
  }
};
export var LiaTiaModalText = {
  lia: {
    headline: 'Legitimate Interest Assessment (LIA)',
    firstParagraph1: 'Due to the answer stated in question no.',
    firstParagraph2: 'you are required to document the balance of interests that the data controller is obliged to predict before processing personal data can take place on the basis of GDPR art. ',
    secondParagraph: 'By clicking “Apply” a new tab will open with a LIA specific questionnaire, which enables you to document the balance of interests.',
    buttonText: 'LIA required'
  },
  tia: {
    headline: 'Transfer Impact Assessment (TIA)',
    firstParagraph1: 'Due to the answers stated in question no.',
    firstParagraph2: 'you are required to document the assessment of transferring personal data to a third party country outside EU/EEA (“third country”).',
    secondParagraph: 'By clicking “Apply” a new tab will open with a TIA specific questionnaire, which enables you to document your assessment.',
    buttonText: 'TIA required'
  },
  tiaProc: {
    headline: 'Transfer Impact Assessment (TIA)',
    firstParagraph1: 'Due to the answers stated in question no.',
    firstParagraph2: 'you are required to document the assessment of transferring personal data to a third party country outside EU/EEA (“third country”).',
    secondParagraph: 'By clicking “Apply” a new tab will open with a TIA specific questionnaire, which enables you to document your assessment.',
    buttonText: 'TIA required'
  }
};
export var LiaTiaType = {
  1: {
    key: 'lia',
    projectType: ProjectTypes.GDPR,
    title: 'LIA'
  },
  2: {
    key: 'tia',
    projectType: ProjectTypes.GDPR,
    title: 'TIA (nodes)'
  },
  3: {
    key: 'tiaProc',
    projectType: ProjectTypes.DATAPROCESSOR,
    title: 'TIA (processors)'
  }
};
export var LIA_TIA_TYPES = {
  LIA: 1,
  TIA: 2,
  TIA_PROC: 3
};