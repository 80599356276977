import { Colors } from '@/Colors';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
export function getItemGaps(gapList, gapArticleMap) {
  return gapList.map(function (gap) {
    var gapArticle = gapArticleMap.get(gap.articleId);
    var gapColor = Colors.gaps[gap.analysis_rag];
    if (!gapArticle || !gapColor) return null;
    return {
      color: gapColor.color,
      activity: getFieldsFromObjectForPreview(gap),
      fieldText: "".concat(gap.nodeTitle, " - ").concat(gapArticle.article)
    };
  });
}