import { Http } from '../index';
import { flushCache, getPromiseWithCacheTimeout } from '@/api/HttpCache';
import { handleRequestResponse, throwError } from '../httpUtils';
export function getMyNodes(projectId, filter) {
  var filterParam = filter ? '&' + filter : '';
  return Http.get("/compliance/projects/".concat(projectId, "/nodes?format=flat").concat(filterParam)).then(function (response) {
    return response.data;
  }).catch(function (err) {
    if (err && err.response && err.response.data && err.response.data.error === 'No nodes found') {
      return err.response.data;
    } else {
      return throwError(err);
    }
  });
}
export function getTreeByProjectId(projectId) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return getPromiseWithCacheTimeout("/compliance/projects/".concat(projectId, "/nodes").concat(params), 10).catch(function (err) {
    if (err && err.response && err.response.data && err.response.data.error === 'No nodes found') {
      return err.response.data;
    } else {
      return throwError(err);
    }
  });
}
export function getSlimTree(projectId) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return handleRequestResponse(Http.get("/compliance/projects/".concat(projectId, "/tree").concat(params)));
}
function flushTreeCache(projectId) {
  flushCache("/compliance/projects/".concat(projectId, "/nodes"));
}
export function addChildToNode(projectId, params) {
  flushTreeCache(projectId);
  return handleRequestResponse(Http.post("/compliance/projects/".concat(projectId, "/nodes"), params));
}
export function duplicateNode(projectId, params) {
  flushTreeCache(projectId);
  return handleRequestResponse(Http.post("/compliance/projects/".concat(projectId, "/nodes"), params));
}
export function getComplianceMy() {
  return handleRequestResponse(Http.get('/compliance/my'));
}
export function getRecursiveLinks(projectId) {
  return handleRequestResponse(Http.get("/compliance/projects/".concat(projectId, "/recursive-links")));
}