function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "max-w-screen-xxl mx-auto"
};
var _hoisted_2 = {
  class: "flex mb-12 -mx-6"
};
var _hoisted_3 = {
  class: "flex justify-between"
};
var _hoisted_4 = {
  key: 0,
  class: "flex flex-wrap w-11/12 justify-end content-center"
};
var _hoisted_5 = {
  class: "font-semibold"
};
var _hoisted_6 = {
  key: 1,
  class: "text-4xl font-semibold"
};
var _hoisted_7 = {
  class: "flex mb-6 -mx-6"
};
var _hoisted_8 = {
  class: "w-2/3 px-6 flex flex-col border-t-4 border-transparent"
};
var _hoisted_9 = {
  key: 1,
  class: "flex items-center justify-center flex-grow"
};
var _hoisted_10 = {
  class: "w-1/3 px-6 flex flex-col"
};
var _hoisted_11 = {
  key: 1,
  class: "flex items-center justify-center flex-grow"
};
var _hoisted_12 = {
  class: "flex mb-3 -mx-6"
};
var _hoisted_13 = {
  key: 0,
  class: "w-full px-6 flex flex-col"
};
var _hoisted_14 = {
  key: 1,
  class: "mt-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_info_box = _resolveComponent("info-box");
  var _component_light_tab_item = _resolveComponent("light-tab-item");
  var _component_router_view = _resolveComponent("router-view");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_risma_doughnut_chart = _resolveComponent("risma-doughnut-chart");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_contract_generic_table = _resolveComponent("contract-generic-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.topPanels, function (box) {
    return _openBlock(), _createElementBlock("div", {
      key: box.title,
      class: "w-1/3 px-6"
    }, [_createVNode(_component_info_box, {
      title: box.title,
      class: "h-full"
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("div", _hoisted_3, [_createVNode(_component_feather_icon, {
          width: "60",
          height: "60",
          icon: box.icon,
          class: _normalizeClass(box.iconStyle)
        }, null, 8 /* PROPS */, ["icon", "class"]), box.value && _typeof(box.value) === 'object' ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(box.value, function (value, key) {
          return _openBlock(), _createElementBlock("li", {
            key: key,
            class: "pl-4 w-1/2 text-xl"
          }, [_createElementVNode("span", _hoisted_5, _toDisplayString(key) + ": " + _toDisplayString(value), 1 /* TEXT */)]);
        }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(box.value), 1 /* TEXT */))])];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["title"])]);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("div", {
    class: "flex -mb-px",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.changeTab && $options.changeTab.apply($options, arguments);
    })
  }, [_createVNode(_component_light_tab_item, {
    to: "".concat($data.statuses.expiring)
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.expiring), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"]), _createVNode(_component_light_tab_item, {
    to: "".concat($data.statuses.renewal)
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.renewal), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])]), _createVNode(_component_info_box, {
    class: "flex-grow flex flex-col"
  }, {
    default: _withCtx(function () {
      return [$data.isLoaded ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0
      }, {
        default: _withCtx(function (_ref) {
          var Component = _ref.Component;
          return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            datasets: $data.monthlyDataset,
            onActive: $options.setStatusAndMonth
          }, null, 8 /* PROPS */, ["datasets", "onActive"]))];
        }),
        _: 1 /* STABLE */
      })) : (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_loading_indicator_small)]))];
    }),
    _: 1 /* STABLE */
  })]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_info_box, {
    class: "flex-grow",
    title: $data.translate.statusOverview
  }, {
    default: _withCtx(function () {
      return [$data.isLoaded ? (_openBlock(), _createBlock(_component_risma_doughnut_chart, {
        key: 0,
        class: "w-full",
        datasets: $data.chartDatasets,
        labels: $data.chartLabels,
        "show-data-labels": true,
        "percentage-value": false
      }, null, 8 /* PROPS */, ["datasets", "labels"])) : (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_loading_indicator_small)]))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"])])]), _createElementVNode("div", _hoisted_12, [$options.tableTitle ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createVNode(_component_risma_title, {
    title: $options.tableTitle,
    class: "mb-4"
  }, null, 8 /* PROPS */, ["title"]), $options.sortedContracts.length ? (_openBlock(), _createBlock(_component_contract_generic_table, {
    key: 0,
    "input-data": $options.sortedContracts,
    "hidden-columns": ['title', 'delete', 'starred'],
    companies: $data.companies,
    "workflow-statuses": $data.workflowStatuses
  }, null, 8 /* PROPS */, ["input-data", "companies", "workflow-statuses"])) : (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("span", null, _toDisplayString($data.translate.nothingToShow), 1 /* TEXT */)]))])) : _createCommentVNode("v-if", true)])]);
}