import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "flex mx-auto w-fit content-center"
};
var _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_inline_select = _resolveComponent("inline-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.totalDataset, function (item, idx) {
    return _openBlock(), _createElementBlock("div", {
      key: idx,
      class: "flex content-center"
    }, [_createElementVNode("div", {
      class: "mr-2 rounded-full w-5 h-5 cursor-pointer",
      style: _normalizeStyle({
        backgroundColor: $data.toggledDatasets[idx] ? $props.appliedColors[Math.min($props.appliedColors.length - 1, idx)] : '#eee'
      }),
      onClick: function onClick($event) {
        return $options.toggleDataset(idx);
      }
    }, null, 12 /* STYLE, PROPS */, _hoisted_2), !Array.isArray(item) ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      style: _normalizeStyle($options.optionStyles)
    }, _toDisplayString(item.label), 5 /* TEXT, STYLE */)) : (_openBlock(), _createBlock(_component_inline_select, {
      key: 1,
      "model-value": $data.selectedMultipleDatasets[idx],
      options: item,
      "option-styles": $options.optionStyles,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.selectMultipleDataset($event, idx);
      }
    }, null, 8 /* PROPS */, ["model-value", "options", "option-styles", "onUpdate:modelValue"]))]);
  }), 128 /* KEYED_FRAGMENT */))]);
}