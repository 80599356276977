function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import * as Utils from '@/utils/Utils';
import { sortByIndex } from '@/utils/sort';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { getProbabilityLabel, getConsequenceLabel } from '@/utils/risks/riskPeriod';
import { toLocalDateTime } from '@/utils/date';
import { MODULES } from '@/constants/modules';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { RiskBaseModelProps, columnsTypeByScoresName } from '@/constants/risks/RiskBaseModelProps';
import { ControlViews } from '@/constants/ControlViews';
import { RiskCookies } from '@/constants/risks/RiskCookies';
import { RiskStatusLabel } from '@/constants/risks/RisksReports';
import { SortDirection } from '@/constants/SortDirection';
import { ReportTypes } from '@/constants/reports';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { RiskDegreeTitleMixin } from '@/mixins/RiskDegreeTitleMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
export default {
  name: 'RiskReportDatatable',
  introduction: '',
  description: '',
  token: '<risk-report-datatable />',
  components: {
    RismaDatatable: RismaDatatable,
    PreviewModalLink: PreviewModalLink,
    Trafficlight: Trafficlight,
    RismaTitleWithIcon: RismaTitleWithIcon,
    SaveReport: SaveReport
  },
  mixins: [RismaDatatableDefaultMixin, RaciTitleMixin, ProcessLibraryTitleMixin, RiskDegreeTitleMixin, SaveReportMixin],
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Id of the Risk Project'
    },
    currentPeriod: {
      required: true,
      type: Object,
      note: 'Current risk period'
    },
    isCurrentPeriodSelected: {
      type: Boolean,
      required: false,
      default: true,
      note: 'If it is current period or scoring selected'
    },
    consequenceList: {
      required: true,
      type: Array,
      note: 'List of selected consequences'
    },
    risks: {
      required: true,
      type: Array,
      note: 'Array of risks (from /risks/reports)'
    },
    riskApprovalEnabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is Risk Approval feature enabled?'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma users'
    },
    organisations: {
      type: Array,
      required: true,
      note: 'list of all selectable organisations'
    },
    threats: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk threats'
    },
    levelOfThreats: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Level of threats'
    },
    vulnerabilities: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk vulnerabilities'
    },
    probabilities: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk vulnerabilities'
    },
    controls: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All controls, uses for linked activities'
    },
    initiatives: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All initiatives, uses for linked activities'
    },
    systems: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All systems, uses for linked assets'
    },
    processLibraryNodes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All process library nodes, uses for linked assets'
    },
    complianceNodes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All compliance nodes, uses for linked assets'
    },
    tags: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'tags to preview risk list to choose from'
    },
    managementLevels: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'managementLevels list'
    },
    isErm: {
      type: Boolean,
      required: false,
      default: false
    },
    riskTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All riskTypes, uses in risk table'
    },
    parentRisks: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All parent risks'
    },
    inherentRisksEnabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is inherent vs. residual risk enabled'
    },
    reportOptions: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['dismissModal', 'updated'],
  data: function data() {
    return {
      dataset: [],
      columns: {},
      linkedColumnsForPreview: ['linkedInitiatives', 'linkedControls', 'linkedSystems', 'linkedProcessLibrary', 'linkedComplianceNodes'],
      disabledFilterByColumns: ['description', RiskBaseModelProps.THREAT_COMMENT, RiskBaseModelProps.THREAT_LEVEL_COMMENT, RiskBaseModelProps.VULNERABILITY_COMMENT, RiskBaseModelProps.PROBABILITY_COMMENT, RiskBaseModelProps.CONSEQUENCE_COMMENT],
      decoupleFilterFields: [{
        key: RiskBaseModelProps.RESPONSIBLE_USER_IDS
      }, {
        key: RiskBaseModelProps.ACCOUNTABLE_USER_IDS
      }, {
        key: RiskBaseModelProps.CONSULTED_USER_IDS
      }, {
        key: RiskBaseModelProps.INFORMED_USER_IDS
      }, {
        key: RiskBaseModelProps.ORGANISATION_IDS,
        separator: ';'
      }, {
        key: RiskBaseModelProps.TAGS
      }, {
        key: 'linkedInitiatives'
      }, {
        key: 'linkedControls'
      }, {
        key: 'linkedSystems'
      }, {
        key: 'linkedProcessLibrary'
      }, {
        key: 'linkedComplianceNodes'
      }],
      pageLength: 50,
      risksSelectedSections: [{
        title: '',
        list: []
      }],
      controlViews: ControlViews,
      columnFiltersSorting: [{
        key: 'id',
        sortingFunc: this.sortingById
      }],
      columnSorting: [{
        key: 'id',
        sortingFunc: this.sortingByIdObject
      }],
      orderDefault: [{
        index: 'id',
        dir: SortDirection.ASC
      }],
      customFieldsDefault: {},
      RiskCookies: RiskCookies,
      columnsMeta: [{
        targets: ['id'],
        width: '60px'
      }, {
        targets: ['title', 'organisationIds', 'attachments', 'parent', 'threatIds', 'threatComment', 'threatLevelValue', 'threatLevelComment', 'vulnerabilityIds', 'vulnerabilityComment'],
        width: '250px'
      }, {
        targets: ['activityType', 'responsible'],
        width: '180px'
      }, {
        targets: ['description'],
        width: '600px'
      }, {
        targets: ['linkedInitiatives', 'linkedControls', 'linkedSystems', 'linkedProcessLibrary', 'linkedComplianceNodes'],
        width: '400px'
      }, {
        targets: [RiskBaseModelProps.GRID_STATUS],
        width: '150px'
      }],
      RiskBaseModelProps: RiskBaseModelProps,
      reportType: ReportTypes.RISK,
      stateLocal: "".concat(RiskCookies.RISK_REPORT_DATATABLE, "-").concat(this.projectId),
      translate: getTranslate['RiskReportDatatable'](),
      updated: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    isSystemsEnabled: 'isSystemsEnabled'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'riskActivityTypes'
  })), {}, {
    consequenceName: function consequenceName() {
      return this.inherentRisksEnabled ? "".concat(this.translate.residualRisk, " - ").concat(this.consequenceLabel.toLowerCase()) : this.consequenceLabel;
    },
    probabilityName: function probabilityName() {
      return this.inherentRisksEnabled ? "".concat(this.translate.residualRisk, " - ").concat(this.probabilityLabel.toLowerCase()) : this.probabilityLabel;
    },
    additionalColumnsErm: function additionalColumnsErm() {
      var _additionalColumns;
      var additionalColumns = (_additionalColumns = {}, _defineProperty(_additionalColumns, RiskBaseModelProps.MANAGEMENT_LEVEL_ID, this.translate.managementLevel), _defineProperty(_additionalColumns, RiskBaseModelProps.STATUS, this.translate.riskStatus), _defineProperty(_additionalColumns, "likelihoodExposure", this.translate.likelihoodExposure), _defineProperty(_additionalColumns, RiskBaseModelProps.LIKELIHOOD_SCORE, this.currentPeriod.likelihood_label || this.translate.likelihoodValue), _defineProperty(_additionalColumns, RiskBaseModelProps.LIKELIHOOD_COMMENT, this.currentPeriod.likelihood_label ? "".concat(this.currentPeriod.likelihood_label, " ").concat(this.translate.comment) : this.translate.likelihoodComment), _defineProperty(_additionalColumns, RiskBaseModelProps.IMPACT_SCORE, this.currentPeriod.impact_label || this.translate.impactValue), _defineProperty(_additionalColumns, RiskBaseModelProps.IMPACT_COMMENT, this.currentPeriod.impact_label ? "".concat(this.currentPeriod.impact_label, " ").concat(this.translate.comment) : this.translate.impactComment), _defineProperty(_additionalColumns, RiskBaseModelProps.CLOSED, this.translate.status), _defineProperty(_additionalColumns, "".concat(RiskBaseModelProps.PREVIOUS_RISK, "_").concat(RiskBaseModelProps.IMPACT_SCORE), this.translate.previousImpact), _defineProperty(_additionalColumns, "".concat(RiskBaseModelProps.PREVIOUS_RISK, "_").concat(RiskBaseModelProps.LIKELIHOOD_SCORE), this.translate.previousLikelihood), _defineProperty(_additionalColumns, RiskBaseModelProps.RPI, this.riskDegreeTitle), _additionalColumns);
      if (this.riskCalculationsEnabled) {
        var _objectSpread2;
        additionalColumns = _objectSpread(_objectSpread({}, additionalColumns), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, RiskBaseModelProps.RISK_GAP, this.translate.riskGap), _defineProperty(_objectSpread2, RiskBaseModelProps.GROSS_LIKELIHOOD, this.translate.likelihoodGross), _defineProperty(_objectSpread2, RiskBaseModelProps.GROSS_IMPACT, this.translate.impactGross), _objectSpread2));
        if (!this.hideTolerance) {
          var _objectSpread3;
          additionalColumns = _objectSpread(_objectSpread({}, additionalColumns), {}, (_objectSpread3 = {}, _defineProperty(_objectSpread3, RiskBaseModelProps.TOLERANCE_LIKELIHOOD, this.translate.likelihoodTolerance), _defineProperty(_objectSpread3, RiskBaseModelProps.TOLERANCE_IMPACT, this.translate.impactTolerance), _defineProperty(_objectSpread3, RiskBaseModelProps.CALCULATION_COMMENT, this.translate.calculationComment), _objectSpread3));
        }
      }
      return additionalColumns;
    },
    additionalColumnsNotErm: function additionalColumnsNotErm() {
      return {
        confidential: this.translate.confidential
      };
    },
    riskCalculationsEnabled: function riskCalculationsEnabled() {
      return this.getSettingValue('risma.risk_calculations_enabled');
    },
    hideTolerance: function hideTolerance() {
      return this.getSettingValue('feature.risk_hide_tolerance');
    },
    consequenceLabel: function consequenceLabel() {
      return getConsequenceLabel(this.currentPeriod);
    },
    probabilityLabel: function probabilityLabel() {
      return getProbabilityLabel(this.currentPeriod);
    }
  }),
  beforeMount: function beforeMount() {
    this.initColumns();
  },
  mounted: function mounted() {
    this.updateInitSettings();
    this.isErm ? this.extendColumnsErmByCustomFields(this.currentPeriod) : this.extendColumnsByCustomFields(this.risks);
    this.columns = this.extendColumns(this.columns);
    this.dataset = this.prepareDataset(this.risks);
    this.enhanceDataset(this.dataset, this.columns);
    this.loadSavedReport();
  },
  methods: {
    initColumns: function initColumns() {
      var _this$columns;
      this.columns = (_this$columns = {
        id: '#',
        title: this.translate.title,
        open_closed: this.translate.status,
        activityType: this.translate.activityType
      }, _defineProperty(_this$columns, RiskBaseModelProps.ORGANISATION_IDS, this.translate.organisation), _defineProperty(_this$columns, "description", this.translate.description), _defineProperty(_this$columns, RiskBaseModelProps.GRID_STATUS, this.translate.gridStatus), _defineProperty(_this$columns, RiskBaseModelProps.THREAT_IDS, this.translate.threat), _defineProperty(_this$columns, RiskBaseModelProps.THREAT_COMMENT, this.translate.threat + ' ' + this.translate.comment), _defineProperty(_this$columns, RiskBaseModelProps.THREAT_LEVEL_VALUE, this.translate.levelOfThreat), _defineProperty(_this$columns, RiskBaseModelProps.THREAT_LEVEL_COMMENT, this.translate.levelOfThreat + ' ' + this.translate.comment), _defineProperty(_this$columns, RiskBaseModelProps.VULNERABILITY_IDS, this.translate.vulnerability), _defineProperty(_this$columns, RiskBaseModelProps.VULNERABILITY_COMMENT, this.translate.vulnerability + ' ' + this.translate.comment), _defineProperty(_this$columns, RiskBaseModelProps.CONSEQUENCE_COMMENT, this.consequenceLabel + ' ' + this.translate.comment), _defineProperty(_this$columns, "linkedInitiatives", this.translate.linkedActions), _defineProperty(_this$columns, "linkedControls", this.translate.linkedControls), _defineProperty(_this$columns, "linkedSystems", this.translate.linkedSystems), _defineProperty(_this$columns, "linkedComplianceNodes", this.translate.linkedComplianceNodes), _defineProperty(_this$columns, RiskBaseModelProps.TAGS, this.translate.tags), _defineProperty(_this$columns, RiskBaseModelProps.PARENT, this.translate.parentRisk), _defineProperty(_this$columns, "threatCategory", this.translate.threatCategory), _this$columns);
    },
    updateInitSettings: function updateInitSettings() {
      if (this.riskApprovalEnabled) {
        this.columns[RiskBaseModelProps.APPROVAL_STATUS] = this.translate.approvalStatus;
        this.columns[RiskBaseModelProps.APPROVAL_DATE] = this.translate.approvalDate, this.columns[RiskBaseModelProps.APPROVED_BY_USER_ID] = this.translate.approvedBy, this.decoupleFilterFields.push({
          key: RiskBaseModelProps.APPROVAL_STATUS
        });
      }
      this.columns.linkedProcessLibrary = "".concat(this.translate.linked, " ").concat(this.processLibraryTitle);
    },
    onEntityUpdated: function onEntityUpdated() {
      this.updated = true;
      this.$emit('updated');
    },
    onDismissModal: function onDismissModal() {
      this.$emit('dismissModal', this.updated);
      this.updated = false;
    },
    sortingById: function sortingById(data) {
      return sortByIndex(data);
    },
    sortingByIdObject: function sortingByIdObject(data, key) {
      return data.sort(function (a, b) {
        var x = Utils.purifyItemField(a[key], true);
        var y = Utils.purifyItemField(b[key], true);
        return x - y;
      });
    },
    getAllCustomFields: function getAllCustomFields(risks) {
      var customFields = [];
      risks.map(function (risk) {
        if (!risk.customFieldValues.length) return;
        customFields.push.apply(customFields, _toConsumableArray(risk.customFields));
      });
      return customFields;
    },
    extendColumnsErmByCustomFields: function extendColumnsErmByCustomFields(currentPeriod) {
      var _this = this;
      currentPeriod.fields.forEach(function (field) {
        if (field.module !== MODULES.SCORING_ZAXIS && field.fieldWeight === -1 && field.id !== currentPeriod.customImpactAxis) {
          _this.columns['p-' + field.label] = field.label;
        }
      });
    },
    extendColumnsByCustomFields: function extendColumnsByCustomFields(risks) {
      var extendedColumns = this.getAllCustomFields(risks);
      var extendedColumnsUniqueById = _toConsumableArray(new Map(extendedColumns.map(function (item) {
        return [item.id, item];
      })).values());
      this.setCustomFieldsColumns(extendedColumnsUniqueById);
    },
    setCustomFieldsColumns: function setCustomFieldsColumns(customFields) {
      var _this2 = this;
      customFields.forEach(function (customField) {
        var customFieldLabel = _this2.getColumnLabelName(customField.id);
        _this2.columns[customFieldLabel] = customField.label;
        _this2.customFieldsDefault[customFieldLabel] = {
          field: '',
          fieldType: customField.fieldtype
        };
        if (customField.typeId === CustomFieldTypes.NUMERICAL) {
          var unitLabel = _this2.getColumnLabelName(customField.id + _this2.customUnitPrefix);
          _this2.columns[unitLabel] = customField.label + _this2.customUnitPrefix;
          _this2.customFieldsDefault[unitLabel] = {
            field: '',
            fieldType: customField.fieldtype
          };
        }
        _this2.extendFilterOptions(customField.typeId, customFieldLabel);
      });
    },
    getColumnLabelName: function getColumnLabelName(id) {
      return "cf-".concat(id).toLowerCase();
    },
    extendFilterOptions: function extendFilterOptions(customFieldTypeId, label) {
      var fieldTypeIdsAvoidCustomFilter = [CustomFieldTypes.TEXT_LINE, CustomFieldTypes.TEXT_FIELD];
      if (fieldTypeIdsAvoidCustomFilter.includes(customFieldTypeId)) {
        this.disabledFilterByColumns.push(label);
      } else {
        this.decoupleFilterFields.push({
          key: label
        });
      }
    },
    getIAColumns: function getIAColumns() {
      var columns = {};
      if (this.isSystemsEnabled) {
        columns.linkedSystems = this.$trans('Linked systems');
      }
      return columns;
    },
    extendColumns: function extendColumns(data) {
      var _raci,
        _this3 = this,
        _this$currentPeriod$c,
        _this$currentPeriod$p;
      var rpi = {};
      var raci = (_raci = {}, _defineProperty(_raci, RiskBaseModelProps.RESPONSIBLE_USER_IDS, this.isErm ? this.translate.dailyRiskOwner : this.responsibleTitle()), _defineProperty(_raci, RiskBaseModelProps.ACCOUNTABLE_USER_IDS, this.isErm ? this.translate.primaryRiskOwner : this.accountableTitle()), _defineProperty(_raci, RiskBaseModelProps.CONSULTED_USER_IDS, this.consultedTitle()), _defineProperty(_raci, RiskBaseModelProps.INFORMED_USER_IDS, this.informedTitle()), _raci);
      this.consequenceList.forEach(function (cons) {
        return rpi["rpi".concat(cons.id)] = "".concat(_this3.translate.rpi, " ").concat(_this3.consequenceLabel, ": ").concat(cons.label);
      });
      var iaColumns = this.getIAColumns();
      var result = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, data), raci), rpi), iaColumns);
      if (!this.isErm) {
        result = _objectSpread(_objectSpread({}, result), this.additionalColumnsNotErm);
      }
      if (!this.isErm && this.currentPeriod.consequenceRows && this.currentPeriod.consequenceRows.length) {
        this.currentPeriod.consequenceRows.map(function (item) {
          result[_this3.prepareCRLabel(item.id)] = "".concat(_this3.consequenceName, ": ").concat(item.label);
          result[_this3.prepareCRComment(item.id)] = "".concat(_this3.consequenceName, ": ").concat(item.label, " ").concat(_this3.translate.comment);
        });
      }
      if (!this.isErm && this.currentPeriod.probabilityRows && this.currentPeriod.probabilityRows.length) {
        this.currentPeriod.probabilityRows.map(function (item) {
          result[_this3.preparePRLabel(item.id)] = "".concat(_this3.probabilityName, ": ").concat(item.label);
          result[_this3.preparePRComment(item.id)] = "".concat(_this3.probabilityName, ": ").concat(item.label, " ").concat(_this3.translate.comment);
        });
      }
      if (this.inherentRisksEnabled && (_this$currentPeriod$c = this.currentPeriod.consequenceRows) !== null && _this$currentPeriod$c !== void 0 && _this$currentPeriod$c.length) {
        this.currentPeriod.consequenceRows.map(function (item) {
          var inherentConsequenceName = "".concat(_this3.translate.inherentRisk, " - ").concat(_this3.consequenceLabel, ": ").concat(item.label);
          result[_this3.prepareInherentCRLabel(item.id)] = inherentConsequenceName;
          result[_this3.prepareInherentCRComment(item.id)] = "".concat(inherentConsequenceName, ": ").concat(item.label, " ").concat(_this3.translate.comment);
        });
      }
      if (this.inherentRisksEnabled && (_this$currentPeriod$p = this.currentPeriod.probabilityRows) !== null && _this$currentPeriod$p !== void 0 && _this$currentPeriod$p.length) {
        this.currentPeriod.probabilityRows.map(function (item) {
          var inherentProbabilityName = "".concat(_this3.translate.inherentRisk, " - ").concat(_this3.probabilityLabel, ": ").concat(item.label);
          result[_this3.prepareInherentPRLabel(item.id)] = inherentProbabilityName;
          result[_this3.prepareInherentPRComment(item.id)] = "".concat(inherentProbabilityName, ": ").concat(item.label, " ").concat(_this3.translate.comment);
        });
      }
      if (this.isErm && this.currentPeriod.impactOther1InUse) {
        result[RiskBaseModelProps.IMPACT_OTHER1_SCORE] = this.currentPeriod.impactOther1Label;
        result[RiskBaseModelProps.IMPACT_OTHER1_COMMENT] = "".concat(this.currentPeriod.impactOther1Label, " ").concat(this.translate.comment);
      }
      if (this.isErm && this.currentPeriod.impactOther2InUse) {
        result[RiskBaseModelProps.IMPACT_OTHER2_SCORE] = this.currentPeriod.impactOther2Label;
        result[RiskBaseModelProps.IMPACT_OTHER2_COMMENT] = "".concat(this.currentPeriod.impactOther2Label, " ").concat(this.translate.comment);
      }
      if (this.isErm) {
        result = _objectSpread(_objectSpread(_objectSpread({}, result), this.additionalColumnsErm), {}, _defineProperty({}, RiskBaseModelProps.RISK_TYPE_ID, this.getSettingValue('risma.risktype_name')));
      }
      if (this.isErm && this.currentPeriod[RiskBaseModelProps.CUSTOM_IMPACT]) {
        this.currentPeriod[RiskBaseModelProps.CUSTOM_IMPACT].combinedFields.forEach(function (row) {
          result[_this3.prepareCILabel(row.id)] = row.label;
        });
      }
      if (this.isErm) delete result.activityType;
      return result;
    },
    prepareLabel: function prepareLabel(string, id) {
      return "".concat(string, "-").concat(id);
    },
    prepareCILabel: function prepareCILabel(id) {
      return this.prepareLabel('_custom-impact', id);
    },
    prepareCRLabel: function prepareCRLabel(id) {
      return this.prepareLabel('_consequence-row', id);
    },
    prepareCRComment: function prepareCRComment(id) {
      return this.prepareLabel('_consequence-row-comment', id);
    },
    preparePRLabel: function preparePRLabel(id) {
      return this.prepareLabel('_probability-row', id);
    },
    preparePRComment: function preparePRComment(id) {
      return this.prepareLabel('_probability-row-comment', id);
    },
    prepareInherentCRLabel: function prepareInherentCRLabel(id) {
      return this.prepareLabel('_inherent-consequence-row', id);
    },
    prepareInherentCRComment: function prepareInherentCRComment(id) {
      return this.prepareLabel('_inherent-consequence-row-comment', id);
    },
    prepareInherentPRLabel: function prepareInherentPRLabel(id) {
      return this.prepareLabel('_inherent-probability-row', id);
    },
    prepareInherentPRComment: function prepareInherentPRComment(id) {
      return this.prepareLabel('_inherent-probability-row-comment', id);
    },
    prepareDataset: function prepareDataset(data) {
      var _this4 = this;
      return data.map(function (item) {
        var _objectSpread5;
        var rpiFields = {};
        var responsibleUserId = item[RiskBaseModelProps.USER_IDS] && item[RiskBaseModelProps.USER_IDS][RiskBaseModelProps.RESPONSIBLE];
        var accountableUserId = item[RiskBaseModelProps.USER_IDS] && item[RiskBaseModelProps.USER_IDS][RiskBaseModelProps.ACCOUNTABLE];
        !_this4.isErm && _this4.currentPeriod.consequenceRows.forEach(function (consequence) {
          rpiFields["rpi".concat(consequence.id)] = {
            field: _this4.calculateRpi(item, consequence.id)
          };
        });
        var customFieldsValue = {};
        if (_this4.isErm) {
          _this4.currentPeriod.fields.forEach(function (field) {
            if (field.module !== MODULES.SCORING_ZAXIS && field.fieldWeight === -1 && field.id !== _this4.currentPeriod.customImpactAxis) {
              _this4.prepareCustomFieldValue(customFieldsValue, item.customFieldValues, field, 'p-' + field.label);
            }
          });
        } else {
          item.customFields && item.customFields.forEach(function (customField) {
            var customFieldLabel = _this4.getColumnLabelName(customField.id);
            _this4.prepareCustomFieldValue(customFieldsValue, item.customFieldValues, customField, customFieldLabel);
          });
        }
        var customFields = _objectSpread(_objectSpread({}, _this4.customFieldsDefault), customFieldsValue);
        var obj = _objectSpread(_objectSpread((_objectSpread5 = {
          id: {
            field: item.rno || ''
          },
          title: {
            activity: getFieldsFromObjectForPreview(item),
            fieldText: item.title,
            hoverTitle: DatatableRenderer.getItemHoverTitle(item),
            confidential: item.confidential
          },
          open_closed: {
            field: _this4.$trans(!item.closed && _this4.isCurrentPeriodSelected ? 'Open' : 'Closed')
          }
        }, _defineProperty(_objectSpread5, RiskBaseModelProps.RESPONSIBLE_USER_IDS, {
          field: _this4.isErm ? Utils.findValueById(_this4.users, +responsibleUserId, 'display_name') : Utils.findPropsByIds(item[RiskBaseModelProps.RESPONSIBLE_USER_IDS], _this4.users, 'display_name')
        }), _defineProperty(_objectSpread5, RiskBaseModelProps.ORGANISATION_IDS, {
          field: Utils.findPropsByIds(item[RiskBaseModelProps.ORGANISATION_IDS], _this4.organisations, 'visible_name')
        }), _objectSpread5), rpiFields), {}, _defineProperty({
          description: {
            field: item.description || ''
          }
        }, RiskBaseModelProps.GRID_STATUS, {
          field: _this4.$trans(RiskStatusLabel[item.gridStatus]) || ''
        }));
        if (!_this4.isErm) {
          var _this4$activityTypes$;
          obj.activityType = {
            field: ((_this4$activityTypes$ = _this4.activityTypes.find(function (at) {
              return at.id === item.activityTypeId;
            })) === null || _this4$activityTypes$ === void 0 ? void 0 : _this4$activityTypes$.label) || ''
          };
          obj.confidential = {
            field: item.confidential ? _this4.translate.yes : _this4.translate.no
          };
        }
        if (_this4.isErm) {
          var _objectSpread7;
          obj = _objectSpread(_objectSpread({}, obj), {}, (_objectSpread7 = {}, _defineProperty(_objectSpread7, RiskBaseModelProps.ACCOUNTABLE_USER_IDS, {
            field: Utils.findValueById(_this4.users, +accountableUserId, 'display_name')
          }), _defineProperty(_objectSpread7, RiskBaseModelProps.MANAGEMENT_LEVEL_ID, {
            field: Utils.findValueById(_this4.managementLevels, item[RiskBaseModelProps.MANAGEMENT_LEVEL_ID], 'name')
          }), _defineProperty(_objectSpread7, RiskBaseModelProps.STATUS, {
            field: Utils.removeHtmlTags(item[RiskBaseModelProps.STATUS] || '')
          }), _defineProperty(_objectSpread7, "likelihoodExposure", {
            field: _this4.findLikelihood(_this4.currentPeriod, item[RiskBaseModelProps.LIKELIHOOD_SCORE])
          }), _defineProperty(_objectSpread7, RiskBaseModelProps.LIKELIHOOD_SCORE, {
            field: item[RiskBaseModelProps.LIKELIHOOD_SCORE] || ''
          }), _defineProperty(_objectSpread7, RiskBaseModelProps.LIKELIHOOD_COMMENT, {
            field: Utils.removeHtmlTags(item[RiskBaseModelProps.LIKELIHOOD_COMMENT] || '')
          }), _defineProperty(_objectSpread7, RiskBaseModelProps.IMPACT_SCORE, {
            field: item[RiskBaseModelProps.IMPACT_SCORE] || ''
          }), _defineProperty(_objectSpread7, RiskBaseModelProps.IMPACT_COMMENT, {
            field: Utils.removeHtmlTags(item[RiskBaseModelProps.IMPACT_COMMENT] || '')
          }), _defineProperty(_objectSpread7, "".concat(RiskBaseModelProps.PREVIOUS_RISK, "_").concat(RiskBaseModelProps.IMPACT_SCORE), {
            field: item[RiskBaseModelProps.PREVIOUS_RISK] ? _this4.findImpact(_this4.currentPeriod, item[RiskBaseModelProps.PREVIOUS_RISK][RiskBaseModelProps.IMPACT_SCORE]) : ''
          }), _defineProperty(_objectSpread7, "".concat(RiskBaseModelProps.PREVIOUS_RISK, "_").concat(RiskBaseModelProps.LIKELIHOOD_SCORE), {
            field: item[RiskBaseModelProps.PREVIOUS_RISK] ? _this4.findLikelihood(_this4.currentPeriod, item[RiskBaseModelProps.PREVIOUS_RISK][RiskBaseModelProps.LIKELIHOOD_SCORE]) : ''
          }), _defineProperty(_objectSpread7, RiskBaseModelProps.RISK_TYPE_ID, {
            field: Utils.findValueById(_this4.riskTypes, item[RiskBaseModelProps.RISK_TYPE_ID], 'title')
          }), _defineProperty(_objectSpread7, RiskBaseModelProps.RPI, {
            field: item[RiskBaseModelProps.RPI] || ''
          }), _objectSpread7));
          if (_this4.riskCalculationsEnabled) {
            var _objectSpread8;
            obj = _objectSpread(_objectSpread({}, obj), {}, (_objectSpread8 = {}, _defineProperty(_objectSpread8, RiskBaseModelProps.RISK_GAP, {
              field: item.riskCalculations[RiskBaseModelProps.RISK_GAP] || ''
            }), _defineProperty(_objectSpread8, RiskBaseModelProps.GROSS_LIKELIHOOD, {
              field: _this4.findLikelihood(_this4.currentPeriod, item.riskCalculations[RiskBaseModelProps.GROSS_LIKELIHOOD])
            }), _defineProperty(_objectSpread8, RiskBaseModelProps.GROSS_IMPACT, {
              field: _this4.findImpact(_this4.currentPeriod, item.riskCalculations[RiskBaseModelProps.GROSS_IMPACT])
            }), _objectSpread8));
            if (!_this4.hideTolerance) {
              var _objectSpread9;
              obj = _objectSpread(_objectSpread({}, obj), {}, (_objectSpread9 = {}, _defineProperty(_objectSpread9, RiskBaseModelProps.TOLERANCE_LIKELIHOOD, {
                field: _this4.findLikelihood(_this4.currentPeriod, item.riskCalculations[RiskBaseModelProps.TOLERANCE_LIKELIHOOD])
              }), _defineProperty(_objectSpread9, RiskBaseModelProps.TOLERANCE_IMPACT, {
                field: _this4.findImpact(_this4.currentPeriod, item.riskCalculations[RiskBaseModelProps.TOLERANCE_IMPACT])
              }), _defineProperty(_objectSpread9, RiskBaseModelProps.CALCULATION_COMMENT, {
                field: item.riskCalculations[RiskBaseModelProps.CALCULATION_COMMENT] || ''
              }), _objectSpread9));
            }
          }
          if (_this4.currentPeriod.impactOther1InUse) {
            var field = item[RiskBaseModelProps.IMPACT_OTHER1_SCORE] || '';
            if (_this4.currentPeriod.impactOther1Labels[item[RiskBaseModelProps.IMPACT_OTHER1_SCORE]]) {
              field += " (".concat(_this4.currentPeriod.impactOther1Labels[item[RiskBaseModelProps.IMPACT_OTHER1_SCORE]], ")");
            }
            obj[RiskBaseModelProps.IMPACT_OTHER1_SCORE] = {
              field: field
            };
            obj[RiskBaseModelProps.IMPACT_OTHER1_COMMENT] = {
              field: Utils.removeHtmlTags(item[RiskBaseModelProps.IMPACT_OTHER1_COMMENT] || '')
            };
          }
          if (_this4.currentPeriod.impactOther2InUse) {
            var _field = item[RiskBaseModelProps.IMPACT_OTHER2_SCORE] || '';
            if (_this4.currentPeriod.impactOther2Labels[item[RiskBaseModelProps.IMPACT_OTHER2_SCORE]]) {
              _field += " (".concat(_this4.currentPeriod.impactOther2Labels[item[RiskBaseModelProps.IMPACT_OTHER2_SCORE]], ")");
            }
            obj[RiskBaseModelProps.IMPACT_OTHER2_SCORE] = {
              field: _field
            };
            obj[RiskBaseModelProps.IMPACT_OTHER2_COMMENT] = {
              field: Utils.removeHtmlTags(item[RiskBaseModelProps.IMPACT_OTHER1_COMMENT] || '')
            };
          }
          if (_this4.isErm && _this4.currentPeriod[RiskBaseModelProps.CUSTOM_IMPACT]) {
            _this4.currentPeriod[RiskBaseModelProps.CUSTOM_IMPACT].combinedFields.forEach(function (row) {
              var result = item.customFieldValues.find(function (field) {
                return field.fieldId === row.id;
              });
              obj[_this4.prepareCILabel(row.id)] = {
                field: result ? result.fieldValue : ''
              };
            });
          }
        } else {
          var _Utils$filterArrayByL, _objectSpread10;
          var threats = Utils.filterArrayByListOfPropValues(_this4.threats, item[RiskBaseModelProps.THREAT_IDS]);
          obj = _objectSpread(_objectSpread({}, obj), {}, (_objectSpread10 = {}, _defineProperty(_objectSpread10, RiskBaseModelProps.PARENT, {
            field: item.parent ? (_Utils$filterArrayByL = Utils.filterArrayByListOfPropValues(_this4.parentRisks, [+item.parent], 'rno')[0]) === null || _Utils$filterArrayByL === void 0 ? void 0 : _Utils$filterArrayByL.title : ''
          }), _defineProperty(_objectSpread10, RiskBaseModelProps.THREAT_IDS, {
            field: threats.map(function (item) {
              return item.title;
            }).join(', ')
          }), _defineProperty(_objectSpread10, "threatCategory", {
            field: _this4.getCategoriesThreats(threats)
          }), _defineProperty(_objectSpread10, RiskBaseModelProps.THREAT_COMMENT, {
            field: item[RiskBaseModelProps.THREAT_COMMENT] || ''
          }), _defineProperty(_objectSpread10, RiskBaseModelProps.THREAT_LEVEL_VALUE, {
            field: Utils.findValueById(_this4.levelOfThreats, item[RiskBaseModelProps.THREAT_LEVEL_VALUE], 'label')
          }), _defineProperty(_objectSpread10, RiskBaseModelProps.THREAT_LEVEL_COMMENT, {
            field: item[RiskBaseModelProps.THREAT_LEVEL_COMMENT] || ''
          }), _defineProperty(_objectSpread10, RiskBaseModelProps.VULNERABILITY_IDS, {
            field: Utils.findPropsByIds(item[RiskBaseModelProps.VULNERABILITY_IDS], _this4.vulnerabilities, 'label')
          }), _defineProperty(_objectSpread10, RiskBaseModelProps.VULNERABILITY_COMMENT, {
            field: item[RiskBaseModelProps.VULNERABILITY_COMMENT] || ''
          }), _defineProperty(_objectSpread10, RiskBaseModelProps.CONSEQUENCE_COMMENT, {
            field: item[RiskBaseModelProps.CONSEQUENCE_COMMENT] || ''
          }), _defineProperty(_objectSpread10, RiskBaseModelProps.ACCOUNTABLE_USER_IDS, {
            field: Utils.findPropsByIds(item[RiskBaseModelProps.ACCOUNTABLE_USER_IDS], _this4.users, 'display_name')
          }), _defineProperty(_objectSpread10, RiskBaseModelProps.CONSULTED_USER_IDS, {
            field: Utils.findPropsByIds(item[RiskBaseModelProps.CONSULTED_USER_IDS], _this4.users, 'display_name')
          }), _defineProperty(_objectSpread10, RiskBaseModelProps.INFORMED_USER_IDS, {
            field: Utils.findPropsByIds(item[RiskBaseModelProps.INFORMED_USER_IDS], _this4.users, 'display_name')
          }), _objectSpread10));
        }
        obj = _objectSpread(_objectSpread(_objectSpread({}, obj), {}, {
          linkedInitiatives: _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links.execution, _this4.initiatives, 'path')), {}, {
            id: obj.id
          }),
          linkedControls: _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links.control, _this4.controls, 'path')), {}, {
            id: obj.id
          })
        }, _this4.getIACells(item.links, obj.id)), {}, _defineProperty({
          linkedProcessLibrary: _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links.compliance_process_tree_node, _this4.processLibraryNodes)), {}, {
            id: obj.id
          }),
          linkedComplianceNodes: _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links.compliance, _this4.complianceNodes)), {}, {
            id: obj.id
          })
        }, RiskBaseModelProps.TAGS, {
          field: item[RiskBaseModelProps.TAGS].map(function (tagItem) {
            return tagItem.tag;
          }).join(', ')
        }), customFields);
        if (_this4.riskApprovalEnabled) {
          obj[RiskBaseModelProps.APPROVAL_STATUS] = {
            field: item[RiskBaseModelProps.APPROVAL_STATUS] ? _this4.translate.approved : _this4.translate.notApproved
          };
          obj[RiskBaseModelProps.APPROVED_BY_USER_ID] = {
            field: Utils.findValueById(_this4.users, item[RiskBaseModelProps.APPROVED_BY_USER_ID], 'display_name')
          };
          obj[RiskBaseModelProps.APPROVAL_DATE] = {
            field: toLocalDateTime(item[RiskBaseModelProps.APPROVAL_DATE])
          };
        }

        // Values given to the row-headline
        if (!_this4.isErm) {
          _this4.currentPeriod.consequenceRows.map(function (row) {
            var rowData = _this4.getRowData(item, row, RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES);
            obj[_this4.prepareCRLabel(row.id)] = rowData.label;
            obj[_this4.prepareCRComment(row.id)] = rowData.comment;
          });
          _this4.currentPeriod.probabilityRows.map(function (row) {
            var rowData = _this4.getRowData(item, row, RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES);
            obj[_this4.preparePRLabel(row.id)] = rowData.label;
            obj[_this4.preparePRComment(row.id)] = rowData.comment;
          });
        }
        if (_this4.inherentRisksEnabled) {
          _this4.currentPeriod.consequenceRows.map(function (row) {
            var rowData = _this4.getRowData(item, row, RiskBaseModelProps.INHERENT_CONSEQUENCE_ROW_SCORES);
            obj[_this4.prepareInherentCRLabel(row.id)] = rowData.label;
            obj[_this4.prepareInherentCRComment(row.id)] = rowData.comment;
          });
          _this4.currentPeriod.probabilityRows.map(function (row) {
            var rowData = _this4.getRowData(item, row, RiskBaseModelProps.INHERENT_PROBABILITY_ROW_SCORES);
            obj[_this4.prepareInherentPRLabel(row.id)] = rowData.label;
            obj[_this4.prepareInherentPRComment(row.id)] = rowData.comment;
          });
        }
        return obj;
      });
    },
    getIACells: function getIACells(links, objId) {
      var row = {};
      if (this.isSystemsEnabled) {
        row.linkedSystems = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(links.compliance_ia_system, this.systems)), {}, {
          id: objId
        });
      }
      return row;
    },
    getRowData: function getRowData(item, row, rowScoresType) {
      var _entity$column$label, _entity$column, _entity$comment, _entity$column2;
      var columnsType = columnsTypeByScoresName[rowScoresType];
      var entity = item[rowScoresType].find(function (r) {
        return r.rowId === row.id;
      });
      var label = (_entity$column$label = entity === null || entity === void 0 || (_entity$column = entity.column) === null || _entity$column === void 0 ? void 0 : _entity$column.label) !== null && _entity$column$label !== void 0 ? _entity$column$label : '';
      var comment = (_entity$comment = entity === null || entity === void 0 ? void 0 : entity.comment) !== null && _entity$comment !== void 0 ? _entity$comment : '';
      if (entity !== null && entity !== void 0 && (_entity$column2 = entity.column) !== null && _entity$column2 !== void 0 && _entity$column2.inherited) {
        var _row$columnsType$find;
        label = label || ((_row$columnsType$find = row[columnsType].find(function (col) {
          return col.value === entity.column.value;
        })) === null || _row$columnsType$find === void 0 ? void 0 : _row$columnsType$find.label) || '';
      }
      return {
        label: {
          field: label
        },
        comment: {
          field: comment
        }
      };
    },
    calculateRpi: function calculateRpi(item, _calculateRpi) {
      // Probability * current Consequence value
      var scores = item._scores && item._scores[_calculateRpi] ? item._scores[_calculateRpi].scores : null;
      return scores ? scores.x * scores.y : '';
      // return item._scores && item._scores[calculateRpi] ? item._scores[calculateRpi].scores.x * item._scores[calculateRpi].scores.y : '';
    },
    findLikelihood: function findLikelihood(currentPeriod, value) {
      if (!value) return '';
      var likelihoodLabel = currentPeriod.likelihood_labels.find(function (labelItem) {
        return labelItem.likelihood_number == value;
      }) || {};
      var likelihoodValue = currentPeriod.likelihood_values ? currentPeriod.likelihood_values[value] : '';
      return "".concat(likelihoodValue, " (").concat(likelihoodLabel.description, ")");
    },
    findImpact: function findImpact(currentPeriod, value) {
      if (!value) return '';
      return currentPeriod.impact_values[value];
    },
    getCategoriesThreats: function getCategoriesThreats(threats) {
      return threats.reduce(function (acc, threat) {
        var _threat$category;
        var categoryTitle = threat === null || threat === void 0 || (_threat$category = threat.category) === null || _threat$category === void 0 ? void 0 : _threat$category.title;
        if (categoryTitle) {
          acc += (acc ? ', ' : '') + categoryTitle;
        }
        return acc;
      }, '');
    }
  }
};