import CardInformationAssets from '@/components/Organisms/General/Cards/CardInformationAssets';
import CompactTitleDataComponent from '@/components/Atoms/CompactTitleDataComponent';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import StaticTable from '@/components/Molecules/StaticTable';
import LoadMoreWrapper from '@/components/Molecules/LoadMoreWrapper';
import { viewTypes } from '@/constants/ListViewType';
import { prepareTitleWithTrafficLightData } from '@/utils/format/templateWrappers';
import { ComplianceControllers } from '@/constants/compliance/ComplianceControllers';
import { IATitleMixin } from '@/mixins/IATitleMixin';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'MyInformationAssestsList',
  token: '<my-information-assests-list />',
  components: {
    PreviewList: PreviewList,
    CardInformationAssets: CardInformationAssets,
    StaticTable: StaticTable,
    LoadMoreWrapper: LoadMoreWrapper,
    CompactTitleDataComponent: CompactTitleDataComponent
  },
  mixins: [IATitleMixin],
  props: {
    viewMode: {
      required: false,
      type: String,
      default: ''
    },
    informationAssets: {
      required: true,
      type: Array
    },
    activityTypes: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['changeShowState'],
  data: function data() {
    return {
      viewTypes: viewTypes,
      columns: [{
        key: 'name',
        text: 'Title'
      }, {
        key: 'objType',
        text: 'Assets Type'
      }, {
        key: 'description',
        text: 'Description'
      }],
      translate: {
        dataController: this.$trans('Data controller'),
        dataProcessor: this.$trans('Data processor'),
        system: this.$trans('System')
      }
    };
  },
  computed: {
    truncateLength: function truncateLength() {
      return this.viewMode === this.viewTypes.EXPANDED ? 340 : 120;
    },
    columnsTrans: function columnsTrans() {
      var _this = this;
      return this.columns.map(function (item) {
        return _this.$trans(item.text);
      });
    },
    dataset: function dataset() {
      var _this2 = this;
      var result = [];
      result = this.informationAssets.map(function (process) {
        var preparedItem = {};
        _this2.columns.forEach(function (_ref) {
          var key = _ref.key;
          preparedItem[key] = _this2.prepareCompactHtml(key, process);
        });
        return preparedItem;
      });
      return result;
    }
  },
  methods: {
    prepareCompactHtml: function prepareCompactHtml(column, informationAsset) {
      var result = '';
      switch (column) {
        case 'name':
          return result = this.prepareTitle(informationAsset);
        case 'objType':
          return result = this.getAssetType(informationAsset);
        case 'description':
        default:
          result = informationAsset[column] || '';
      }
      return result;
    },
    prepareTitle: function prepareTitle(informationAsset) {
      var assetType = ComplianceControllers[informationAsset.objType] || 'informationassets';
      return prepareTitleWithTrafficLightData(informationAsset, "/compliance/informationassets/".concat(assetType, "/").concat(informationAsset.id));
    },
    getAssetType: function getAssetType(informationAsset) {
      if (informationAsset.objType === ObjectTypes.INFORMATION_ASSET && informationAsset.activityTypeId) {
        var activityType = this.activityTypes.find(function (item) {
          return item.id === informationAsset.activityTypeId;
        });
        return activityType.label;
      }
      switch (informationAsset.objType) {
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER:
          return this.translate.dataController;
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR:
          return this.translate.dataProcessor;
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM:
          return this.translate.system;
      }
    }
  }
};