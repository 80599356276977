import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex mb-3 items-center justify-between"
};
var _hoisted_2 = {
  class: "font-bold mr-2"
};
var _hoisted_3 = {
  key: 1
};
var _hoisted_4 = {
  class: "font-bold"
};
var _hoisted_5 = {
  class: "grid grid-cols-3 auto-rows-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.addDeadline,
    "button-ok-text": $data.translate.save,
    "button-dismiss-text": $data.translate.cancel,
    "overflow-visible": true,
    "dismiss-on-click-outside": false,
    "modal-fixed-width": "590px",
    onAccept: $options.onModalAccept,
    onDismiss: $options.onModalDismiss
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.addDeadlineError ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.addDeadlineError), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, _toDisplayString($data.translate.controlDeadline) + ": ", 1 /* TEXT */), _createVNode(_component_date_time_picker, {
        "model-value": $data.selectedDeadline,
        "plugin-options": $data.dateTimeOptions,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.selectedDeadline = $event;
        })
      }, null, 8 /* PROPS */, ["model-value", "plugin-options"]), _createElementVNode("div", {
        class: "flex items-center cursor-pointer ml-2",
        onClick: _cache[1] || (_cache[1] = function () {
          return $options.onAddDeadline && $options.onAddDeadline.apply($options, arguments);
        })
      }, [_createVNode(_component_feather_icon, {
        icon: "plus",
        class: "stroke-2 mr-2"
      }), _createElementVNode("span", null, _toDisplayString($data.translate.addDate), 1 /* TEXT */)])]), $data.allDeadlines.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("p", _hoisted_4, _toDisplayString($data.translate.customDeadlines), 1 /* TEXT */), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.allDeadlines, function (deadline, idx) {
        return _openBlock(), _createElementBlock("div", {
          key: idx,
          class: "flex items-center"
        }, [_createElementVNode("p", null, _toDisplayString(deadline.deadline), 1 /* TEXT */), _createVNode(_component_feather_icon, {
          icon: "x",
          class: "stroke-3 text-red-50 cursor-pointer ml-1 mr-2",
          onClick: function onClick($event) {
            return $options.removeDeadline(idx);
          }
        }, null, 8 /* PROPS */, ["onClick"])]);
      }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"]);
}