import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-fa847122"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "control-traffic"
};
var _hoisted_2 = {
  key: 0,
  class: "control-switch"
};
var _hoisted_3 = {
  class: "control-switch-left"
};
var _hoisted_4 = {
  class: "control-switch-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.showCreateRemoteTickets ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_toggle, {
    "model-value": $options.createRemoteTickets,
    disabled: $props.disabled || $options.disableCreateRemoteTickets,
    "onUpdate:modelValue": $options.onCreateRemoteTicketsChange
  }, null, 8 /* PROPS */, ["model-value", "disabled", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_4, _toDisplayString($data.translate.createRemoteTickets), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]);
}