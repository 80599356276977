import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "row"
};
var _hoisted_2 = {
  class: "col-7"
};
var _hoisted_3 = {
  class: "flex mt-2"
};
var _hoisted_4 = {
  class: "w-4/12 pr-8 my-1"
};
var _hoisted_5 = {
  class: "w-4/12 pr-8 my-1"
};
var _hoisted_6 = {
  class: "validation-error text-red-50"
};
var _hoisted_7 = {
  class: "w-4/12 pr-8 my-1"
};
var _hoisted_8 = {
  class: "validation-error text-red-50"
};
var _hoisted_9 = {
  class: "risk-additional-filters"
};
var _hoisted_10 = {
  key: 0,
  class: "flex flex-wrap justify-start"
};
var _hoisted_11 = {
  class: "w-4/12 pr-8 my-1"
};
var _hoisted_12 = {
  class: "w-4/12 pr-8 my-1"
};
var _hoisted_13 = {
  class: "w-4/12 pr-8 my-1"
};
var _hoisted_14 = {
  key: 0,
  class: "w-4/12 pr-8 my-1"
};
var _hoisted_15 = {
  class: "w-4/12 pr-8 my-1"
};
var _hoisted_16 = {
  class: "w-4/12 pr-8 my-1"
};
var _hoisted_17 = {
  class: "w-4/12 pr-8 my-1"
};
var _hoisted_18 = {
  class: "w-4/12 pr-8 my-1"
};
var _hoisted_19 = {
  class: "w-4/12 pr-8 my-1"
};
var _hoisted_20 = {
  key: 1,
  class: "mt-2 w-full"
};
var _hoisted_21 = {
  class: "flex flex-wrap justify-start"
};
var _hoisted_22 = {
  class: "flex justify-end py-4 pr-8 border-b-2 border-gray-250"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
    modelValue: $props.modelValue.periodId,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $props.modelValue.periodId = $event;
    }), _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('changePeriod');
    })],
    title: $data.translate.period,
    options: $props.options.periods,
    "un-sorted": $data.unSorted
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "un-sorted"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_single_select, {
    modelValue: $props.modelValue.xAxis,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $props.modelValue.xAxis = $event;
    }),
    title: $data.translate.xaxisImpactType,
    options: $props.options.impact,
    class: _normalizeClass({
      'border border-red-50 rounded-md': $data.xAxisValidation
    })
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "class"]), _createElementVNode("span", _hoisted_6, _toDisplayString($data.xAxisValidation), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_single_select, {
    modelValue: $props.modelValue.yAxis,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $props.modelValue.yAxis = $event;
    }),
    title: $data.translate.yaxisLikelihood,
    options: $props.options.likelihood
  }, null, 8 /* PROPS */, ["modelValue", "title", "options"])])]), _createElementVNode("div", {
    class: _normalizeClass([{
      'error': $data.managementLevelsValidation
    }, "w-4/12 pr-8 my-1"])
  }, [_createVNode(_component_risma_title, {
    title: $data.translate.managementLevel,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $props.options.managementLevels,
    "selected-options": $props.modelValue.managementLevels,
    "un-sorted": $data.unSorted,
    placeholder: $data.translate.selectManagementLevel,
    "label-key": "name",
    onSelected: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.setFilterValue('managementLevels', $event);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "un-sorted", "placeholder"]), _createElementVNode("span", _hoisted_8, _toDisplayString($data.managementLevelsValidation), 1 /* TEXT */)], 2 /* CLASS */), _createElementVNode("div", _hoisted_9, [_createElementVNode("a", {
    href: "#",
    class: "expand-additional-filters py-2 inline-block",
    onClick: _cache[5] || (_cache[5] = _withModifiers(function ($event) {
      return _ctx.additionalVisible = !_ctx.additionalVisible;
    }, ["prevent"]))
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-down",
    class: _normalizeClass(['transform duration-500 text-blue-750 cursor-pointer stroke-3 mr inline-block', {
      'rotate-180': _ctx.additionalVisible
    }])
  }, null, 8 /* PROPS */, ["class"]), _createElementVNode("span", null, _toDisplayString(_ctx.expandLinkText), 1 /* TEXT */)]), _ctx.additionalVisible ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createVNode(_component_risma_title, {
    title: $options.riskTypeLabel,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $props.options.riskTypes,
    "selected-options": $props.modelValue.riskTypes,
    "un-sorted": $data.unSorted,
    placeholder: $data.translate.selectRiskType,
    "label-key": "title",
    onSelected: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.setFilterValue('riskTypes', $event);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "un-sorted", "placeholder"])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_risma_title, {
    title: $data.translate.dailyRiskOwner,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $props.options.users,
    "selected-options": $props.modelValue.dailyOwner,
    "un-sorted": $data.unSorted,
    placeholder: $data.translate.selectDailyOwner,
    "label-key": "display_name",
    onSelected: _cache[7] || (_cache[7] = function ($event) {
      return _ctx.setFilterValue('dailyOwner', $event);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "un-sorted", "placeholder"])]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_risma_title, {
    title: $data.translate.primaryRiskOwner,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $props.options.users,
    "selected-options": $props.modelValue.primaryOwner,
    "un-sorted": $data.unSorted,
    placeholder: $data.translate.selectPrimaryOwner,
    "label-key": "display_name",
    onSelected: _cache[8] || (_cache[8] = function ($event) {
      return _ctx.setFilterValue('primaryOwner', $event);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "un-sorted", "placeholder"])]), $props.options.approval ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_component_risma_title, {
    title: $data.translate.approvalStatus,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $props.options.approval,
    "selected-options": $props.modelValue.approval,
    "un-sorted": $data.unSorted,
    placeholder: $data.translate.selectApprovalStatus,
    "label-key": "label",
    onSelected: _cache[9] || (_cache[9] = function ($event) {
      return _ctx.setFilterValue('approval', $event);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "un-sorted", "placeholder"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_15, [_createVNode(_component_risma_title, {
    title: $data.translate.tags,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $props.options.tags,
    "selected-options": _ctx.tagsSelected,
    "un-sorted": $data.unSorted,
    placeholder: $data.translate.selectTags,
    "label-key": "tag",
    onSelected: _cache[10] || (_cache[10] = function ($event) {
      return _ctx.setFilterValue('tags', $event);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "un-sorted", "placeholder"])]), _createElementVNode("div", _hoisted_16, [_createVNode(_component_risma_title, {
    title: $data.translate.organisations,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    "selected-options": $props.modelValue.organisationsList,
    options: $props.options.organisationsList,
    "un-sorted": $data.unSorted,
    "label-key": "visible_name",
    placeholder: $data.translate.selectOrganisations,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = function ($event) {
      return _ctx.setFilterValue('organisationsList', $event);
    })
  }, null, 8 /* PROPS */, ["selected-options", "options", "un-sorted", "placeholder"])]), _createElementVNode("div", _hoisted_17, [_createVNode(_component_single_select, {
    title: $data.translate.riskStatus,
    "model-value": $props.modelValue.status,
    options: $props.options.status,
    placeholder: $data.translate.selectRiskStatus,
    "onUpdate:modelValue": _cache[12] || (_cache[12] = function ($event) {
      return _ctx.setFilterValue('status', $event);
    })
  }, null, 8 /* PROPS */, ["title", "model-value", "options", "placeholder"])]), _createElementVNode("div", _hoisted_18, [_createVNode(_component_single_select, {
    title: $data.translate.riskUpdated,
    "model-value": $props.modelValue.updated,
    options: $props.options.updated,
    placeholder: $data.translate.selectRiskUpdated,
    "onUpdate:modelValue": _cache[13] || (_cache[13] = function ($event) {
      return _ctx.setFilterValue('updated', $event);
    })
  }, null, 8 /* PROPS */, ["title", "model-value", "options", "placeholder"])]), _createElementVNode("div", _hoisted_19, [_createVNode(_component_single_select, {
    title: $data.translate.filterTopNRisks,
    "model-value": $props.modelValue.topRisks,
    options: $props.options.topRisks,
    placeholder: $data.translate.selectTopRisks,
    "onUpdate:modelValue": _cache[14] || (_cache[14] = function ($event) {
      return _ctx.setFilterValue('topRisks', $event);
    })
  }, null, 8 /* PROPS */, ["title", "model-value", "options", "placeholder"])]), $options.customFields.length ? (_openBlock(), _createElementBlock("div", _hoisted_20, [_createVNode(_component_risma_title, {
    title: $data.translate.customRiskFields,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_21, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.customFields, function (field) {
    return _openBlock(), _createElementBlock("div", {
      key: field.id,
      class: "w-4/12 pr-8 my-1"
    }, [_createVNode(_component_single_select, {
      title: _ctx.$trans(field.label),
      "model-value": $props.modelValue.customFields && ($props.modelValue.customFields[field.label] || $props.modelValue.customFields[field.id]) || '',
      options: $options.prepareOptionsList(field.options),
      placeholder: $data.translate.select + ' ' + field.label,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.setCustomFields('customFields', field, $event);
      }
    }, null, 8 /* PROPS */, ["title", "model-value", "options", "placeholder", "onUpdate:modelValue"])]);
  }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_22, [_createVNode(_component_risma_button, {
    text: $data.translate.resetFilter,
    type: "util",
    onClick: _ctx.handleResetFilters
  }, null, 8 /* PROPS */, ["text", "onClick"]), _createVNode(_component_risma_button, {
    text: $data.translate.createReport,
    type: "save",
    onClick: _ctx.handleCreateReport
  }, null, 8 /* PROPS */, ["text", "onClick"])])])]);
}