function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TabList from '@/components/Atoms/TabList';
import SelectorTags from '@/components/Molecules/SelectorTags';
import LinksModal from '@/components/Organisms/Links/LinksModal';
import LinksDepPreview from '@/components/Organisms/Links/LinksDepPreview';
import QuestionPreviewList from '@/components/Organisms/Links/QuestionPreviewList';
import Notification from '@/components/Molecules/Notification';
import Alert from '@/components/Molecules/Modal/Alert';
import ActionGap from '@/components/Pages/Actions/ActionGap';
import * as DataAPI from '@/api/risma/data';
import { getContract } from '@/api/contracts/contracts';
import { getAllForNode } from '@/api/compliance/gaps';
import { getWorkflowStatusesByModule } from '@/api/risma/data';
import { getSingleInitiative } from '@/api/execution/initiative';
import { getRiskData, getRiskProjects, getReports } from '@/api/risk/risk';
import { getAll as _getIncidents } from '@/api/incident/incident';
import { getControlById } from '@/api/controls/controls';
import { getCurrentScoring } from '@/api/risk/scoring';
import { getNodeById } from '@/api/compliance/processtree';
import { getAllCustomInformationAssets, getSingleByType } from '@/api/compliance/informationAssets';
import { getByUrl } from '@/api/httpUtils';
import { getAllQuestionAnswersForNode } from '@/api/compliance/questionnaire';
import { getById as getComplianceNode } from '@/api/compliance/node';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import * as Utils from '@/utils/Utils';
import * as RisksUtils from '@/components/Pages/Risks/RisksUtils';
import { LinkTabTitles, TabTypes } from '@/constants/Tabs';
import { getModuleFromObjType, ObjectTypes } from '@/constants/ObjectTypes';
import { itemTitleByModule, MODULES } from '@/constants/modules';
import { getProjectsOptionsLinksTab, GovernanceReviewOptionsLinksTab, getProjectRiskAssessmentLinksTab } from '@/constants/links/LinksModalSettings';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import { INCIDENT_STATES } from '@/constants/IncidentStateCodes';
import { RiskProjects } from '@/constants/risks/RiskProjects';
import { ComplianceControllers } from '@/constants/compliance/ComplianceControllers';
import { WrongModules } from '@/constants/WrongModules';
import { checkERMByProjectId } from '@/utils/risks/check';
import { Colors } from '@/Colors';
import { comparePathStrings, compareStrings } from '@/utils/compare';
import { isLockedActionUser, isLockedControlUser, isLockedIncidentUser, isLockedRiskUser, userHasProjectAccess, isLockedProcessLibrary } from '@/utils/access';
import { formatNodes } from '@/utils/format/Compliance';
import * as formatDate from '@/utils/date';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { InformationasstesOverviewMixin } from '@/mixins/InformationAssetsOverview';
import { getProjectDescriptionLabel } from '@/utils/Compliance/complianceProject';
var LinkTypes = {
  MITIGATING_ACTIVITIES: 'mitigatingActivities',
  GAP_ANALYSIS: 'gapAnalysis'
};
export default {
  name: 'LinksTab',
  introduction: 'Universal tab with links',
  description: 'A component used in all DEPs to show all the linked items',
  token: '<links-tab></links-tab>',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    LinksModal: LinksModal,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    SelectorTags: SelectorTags,
    LinksDepPreview: LinksDepPreview,
    QuestionPreviewList: QuestionPreviewList,
    Notification: Notification,
    TabList: TabList,
    ActionGap: ActionGap,
    Alert: Alert
  },
  mixins: [InformationasstesOverviewMixin],
  props: {
    data: {
      type: Object,
      required: true,
      note: 'data object'
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['propertyChanged'],
  data: function data() {
    var _anotherLinks;
    return {
      isLoaded: false,
      showLinksModal: false,
      linksOptions: [],
      workflows: {},
      // Dataset keys must be initialized with keys for reactivity to work
      dataset: Object.keys(MODULES).reduce(function (acc, module) {
        acc[module] = null;
        return acc;
      }, {}),
      loadedModulesMap: [],
      preselectedLinks: [],
      activityList: [],
      projects: [],
      MODULES: MODULES,
      itemTitleByModule: itemTitleByModule(this.$trans),
      previewData: {
        data: null,
        list: null,
        type: null
      },
      scoring: [],
      isLoadingPreview: false,
      processTreeId: 0,
      hasErrorFromBackend: false,
      errorMessage: '',
      projectsOptions: [],
      projectOptionsWithDefault: [],
      currentTab: TabTypes.LINKS,
      anotherLinks: (_anotherLinks = {}, _defineProperty(_anotherLinks, TabTypes.GAPLINKS, {}), _defineProperty(_anotherLinks, TabTypes.QUESTIONNAIRE, {}), _anotherLinks),
      tabTypes: TabTypes,
      selectedTag: {},
      complianceNodes: [],
      controls: [],
      GovernanceReviewOptionsLinksTab: GovernanceReviewOptionsLinksTab,
      itemDeletionData: null,
      unfilteredModules: [MODULES.COMPLIANCE_PROCESS_TREE_NODE, MODULES.CONTROLS, MODULES.RISK, MODULES.COMPLIANCE, MODULES.COMPLIANCE_INFORMATION_ASSETS],
      LinkTypes: LinkTypes,
      translate: getTranslate['LinksTab']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    allSettings: 'getAllSettings'
  })), mapState(useSettingsStore, ['isSystemsEnabled', 'isDataControllersEnabled', 'isDataProcessorsEnabled', 'isIACustomEnabled'])), mapState(useUserStore, {
    currentUserRiskProjectById: 'riskProjectById',
    currentUserComplianceProjectById: 'complianceProjectById'
  })), mapState(useUserStore, ['isAdmin', 'currentUser'])), mapState(useOrganisationsStore, ['organisations'])), mapState(useUsersStore, {
    users: 'activeUsers',
    unfilteredUsers: 'users'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'complianceIACustomActivityTypes'
  })), {}, {
    ProjectsOptionsLinksTab: function ProjectsOptionsLinksTab() {
      return getProjectsOptionsLinksTab(this.allSettings, this.currentUser);
    },
    disabledOptions: function disabledOptions() {
      var _ref;
      return _ref = {}, _defineProperty(_ref, MODULES.CONTRACTS, !this.getSettingValue('app.module_contracts_enabled')), _defineProperty(_ref, MODULES.COMPLIANCE_IA_SYSTEM, !this.isSystemsEnabled), _defineProperty(_ref, MODULES.COMPLIANCE_IA_CONTROLLER, !this.isDataControllersEnabled), _defineProperty(_ref, MODULES.COMPLIANCE_IA_PROCESSOR, !this.isDataProcessorsEnabled), _defineProperty(_ref, MODULES.COMPLIANCE_INFORMATION_ASSETS, !this.isIACustomEnabled), _ref;
    },
    currentProject: function currentProject() {
      if (this.module === MODULES.RISK) {
        return this.currentUserRiskProjectById(this.data.projectId);
      } else if (this.module === MODULES.COMPLIANCE) {
        return this.currentUserComplianceProjectById(this.data.compliance_projects_id);
      } else {
        return {};
      }
    },
    isReadOnlyAccess: function isReadOnlyAccess() {
      if (this.readOnly) {
        return this.readOnly;
      }
      switch (this.module) {
        case MODULES.INCIDENTS:
          return isLockedIncidentUser(this.currentUser);
        case MODULES.RISK:
          return isLockedRiskUser(this.currentUser);
        case MODULES.EXECUTION:
          return !!(this.data.closed || isLockedActionUser(this.currentUser));
        case MODULES.CONTROLS:
          return isLockedControlUser(this.currentUser) || !this.data.active;
        case MODULES.COMPLIANCE_PROCESS_TREE_NODE:
          return isLockedProcessLibrary(this.currentUser);
        default:
          return !!this.currentProject.locked;
      }
    },
    hasTabs: function hasTabs() {
      return !!(Object.keys(this.anotherLinks[TabTypes.QUESTIONNAIRE]).length || Object.keys(this.anotherLinks[TabTypes.GAPLINKS]).length);
    },
    listTitle: function listTitle() {
      switch (this.currentTab) {
        case TabTypes.GAPLINKS:
          return this.translate.compliance;
        default:
          return this.data && (this.data.title || this.data.name) || '';
      }
    },
    linkTabs: function linkTabs() {
      var list = [{
        name: this.$trans(LinkTabTitles[this.data.objType]),
        slotName: TabTypes.LINKS
      }];
      if (Object.keys(this.anotherLinks[TabTypes.QUESTIONNAIRE]).length) {
        list.push({
          name: this.translate.questionnaire,
          slotName: TabTypes.QUESTIONNAIRE
        });
      }
      if (Object.keys(this.anotherLinks[TabTypes.GAPLINKS]).length) {
        list.push({
          name: this.translate.gapAnalysis,
          slotName: TabTypes.GAPLINKS
        });
      }
      return list;
    },
    itemsByTab: function itemsByTab() {
      if (this.currentTab === TabTypes.LINKS) {
        return this.linkItems;
      } else {
        return this.anotherLinks[this.currentTab];
      }
    },
    filteredItems: function filteredItems() {
      var _this = this;
      var keys = Object.keys(this.itemsByTab);
      var result = {};
      keys.forEach(function (key) {
        if (_this.itemsByTab[key].items && _this.itemsByTab[key].items.length) {
          result[key] = _this.itemsByTab[key];
        }
      });
      return result;
    },
    sortedItems: function sortedItems() {
      return new Map(Object.entries(this.filteredItems).sort(function (a, b) {
        return compareStrings(a[1].moduleTitle, b[1].moduleTitle);
      }));
    },
    module: function module() {
      return getModuleFromObjType(this.data.objType);
    },
    links: {
      get: function get() {
        var links = _objectSpread({}, this.data.links);
        WrongModules.forEach(function (wrongModule) {
          delete links[wrongModule];
        });
        return links;
      },
      set: function set(links) {
        this.$emit('propertyChanged', {
          property: 'links',
          value: links
        });
      }
    },
    linkItems: function linkItems() {
      var _this2 = this;
      var linkItems = {};
      Object.keys(this.links).forEach(function (module) {
        var _linkItems$module, _linkItems$module2;
        if (!_this2.projectOptionExistsByModule(module) && module !== MODULES.RISK_ASSESSMENT) {
          return;
        }
        if (_this2.links[module].length && module === MODULES.CONTROLS) {
          linkItems[module] = {
            items: _this2.getLinkItems(_this2.links[module], _this2.controls)
          };
        } else if (_this2.links[module].length && _this2.dataset[module]) {
          linkItems[module] = {
            items: _this2.getLinkItems(_this2.links[module], _this2.dataset[module])
          };
        }
        if ((module === MODULES.COMPLIANCE || module === MODULES.RISK) && (_linkItems$module = linkItems[module]) !== null && _linkItems$module !== void 0 && _linkItems$module.items) {
          var additionalContent = _this2.prepareDatasetByAdditionalContent(linkItems[module].items, module, 'compliance_projects_id', 'projectId');
          Object.keys(additionalContent).forEach(function (key) {
            linkItems[key] = {
              items: additionalContent[key]
            };
          });

          //transform compliance[3] => compliance-1[2], compliance-2[1]
          delete linkItems[module];
        }
        if (module === MODULES.COMPLIANCE_INFORMATION_ASSETS && (_linkItems$module2 = linkItems[module]) !== null && _linkItems$module2 !== void 0 && _linkItems$module2.items) {
          var _additionalContent = _this2.prepareDatasetByAdditionalContent(linkItems[module].items, module, 'activity_id');
          Object.keys(_additionalContent).forEach(function (key) {
            linkItems[key] = {
              items: _additionalContent[key]
            };
          });
          delete linkItems[module];
        }
        if (module === MODULES.COMPANY) {
          var _linkItems$module3;
          if ((_linkItems$module3 = linkItems[module]) !== null && _linkItems$module3 !== void 0 && _linkItems$module3.items) {
            linkItems[module].items = linkItems[module].items.map(function (item) {
              return _objectSpread(_objectSpread({}, item), {}, {
                trafficLight: item.trafficLight ? item.trafficLight : {}
              });
            });
          }
        }
      });
      if (this.governanceReviewLinkId) {
        linkItems.governanceReviewLinks = {
          items: this.getGovernanceReviewLinkNode()
        };
      }
      Object.keys(linkItems).forEach(function (linkKey) {
        linkItems[linkKey] = _objectSpread(_objectSpread({}, linkItems[linkKey]), {}, {
          module: linkKey,
          moduleTitle: _this2.getProjectOptionByModule(linkKey).label,
          hasTrafficLight: _this2.isModuleHasTrafficLight(_this2.getPurifiedModuleName(linkKey)),
          hasDeleteOption: _this2.hasDeleteOptions(linkKey) && !_this2.isReadOnlyAccess
        });
      });
      return linkItems;
    },
    governanceReviewLinkModule: function governanceReviewLinkModule() {
      if (!this.data.governanceReviewLinks) return;
      return Object.keys(this.data.governanceReviewLinks)[0];
    },
    governanceReviewLinkId: function governanceReviewLinkId() {
      if (!this.governanceReviewLinkModule) return;
      return this.data.governanceReviewLinks[this.governanceReviewLinkModule][0];
    },
    isNotFiltered: function isNotFiltered() {
      return this.unfilteredModules.includes(this.module);
    }
  }),
  mounted: function mounted() {
    var _this3 = this;
    this.getProjects().then(this.setExpandedProjectsOptionsList).then(this.expandDataset).then(this.getWorkflowStatuses(MODULES.CONTRACTS)).then(this.preloadSelectedItems).then(this.getScoringForERM).then(this.loadAdditionalData).finally(function () {
      _this3.isLoaded = true;
    });
  },
  methods: {
    setCurrentTab: function setCurrentTab(tab) {
      this.currentTab = tab;
      this.previewData.data = null;
      this.previewData.list = null;
      this.selectedTag = {};
    },
    isModuleHasTrafficLight: function isModuleHasTrafficLight(module) {
      if (module === MODULES.RISK || module === MODULES.CONTRACTS) {
        return false;
      }
      if (module === MODULES.CONTROLS) {
        return function (control) {
          return !control.isMothercontrol;
        };
      }
      return true;
    },
    prepareGapData: function prepareGapData(data) {
      var _this4 = this;
      var gapLinks = {};
      data.forEach(function (dataItem) {
        var objType = _this4.getObjectTypeByGap(dataItem);
        var projectKey = getModuleFromObjType(objType);
        if (projectKey === MODULES.COMPLIANCE) {
          projectKey += "-".concat(dataItem.activityProjectId);
        }
        if (!gapLinks[projectKey]) {
          gapLinks[projectKey] = {
            items: [],
            module: projectKey,
            moduleTitle: _this4.getProjectOptionByModule(projectKey).label,
            hasTrafficLight: _this4.isModuleHasTrafficLight(_this4.getPurifiedModuleName(projectKey)),
            hasDeleteOption: false
          };
        }
        var gapItem = _this4.createGapItem(dataItem, objType);
        var existedNode = gapLinks[projectKey].items.find(function (nodeItem) {
          return nodeItem.nodeId === dataItem.nodeId;
        });
        if (existedNode) {
          existedNode.gapList.push(dataItem);
        } else {
          gapLinks[projectKey].items.push(gapItem);
        }
      });
      this.anotherLinks[TabTypes.GAPLINKS] = gapLinks;
    },
    prepareMitigatingActions: function prepareMitigatingActions(gapAnalysis) {
      var _this5 = this;
      var gapLinks = {};
      gapAnalysis.forEach(function (analysis) {
        var projects = Object.keys(analysis.links).filter(function (key) {
          return analysis.links[key].length;
        });
        var article = analysis.article;
        projects.forEach(function (projectKey) {
          if (!gapLinks[projectKey]) {
            gapLinks[projectKey] = {
              items: [],
              module: MODULES.COMPLIANCE,
              moduleTitle: _this5.getProjectOptionByModule(projectKey).label,
              hasDeleteOption: false,
              hasTrafficLight: true
            };
          }
          gapLinks[projectKey].items.push({
            title: article.article + ' ' + article.articleTitle,
            trafficLightColor: Colors.gapColor(analysis.analysis_rag).color,
            projectId: _this5.data.compliance_projects_id,
            nodeId: _this5.data.id,
            mitigatingItems: analysis.links[projectKey],
            gapAnalysis: analysis
          });
        });
      });
      this.anotherLinks[TabTypes.GAPLINKS] = gapLinks;
    },
    prepareQuestionnaireData: function prepareQuestionnaireData(data) {
      var _this6 = this;
      data.forEach(function (item) {
        var linksObject = _this6.anotherLinks[TabTypes.QUESTIONNAIRE];
        var itemModule = getModuleFromObjType(item.objType);
        if (itemModule === MODULES.COMPLIANCE) {
          itemModule += "-".concat(item.compliance_projects_id);
        }
        if (linksObject[itemModule]) {
          linksObject[itemModule].items.push(item);
        } else {
          linksObject[itemModule] = {
            items: [item]
          };
        }
      });
    },
    createGapItem: function createGapItem(gap, objType) {
      return {
        dataProcessorId: gap.data_processor_id,
        nodeId: gap.nodeId,
        trafficLight: gap.activityTrafficLight,
        title: gap.activityTitle,
        projectId: gap.activityProjectId,
        gapList: [gap],
        objType: objType
      };
    },
    getObjectTypeByGap: function getObjectTypeByGap(item) {
      if (item.data_processor_id) {
        return ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR;
      }
      if (item.nodeId) {
        return ObjectTypes.COMPLIANCE_NODE;
      }
    },
    getDefaultProjectsOptions: function getDefaultProjectsOptions() {
      var _this7 = this;
      var availableProjectOptions = this.ProjectsOptionsLinksTab.filter(function (_ref2) {
        var value = _ref2.value;
        return !_this7.disabledOptions[value];
      });
      var filteredProjects = this.filterSelfModules(availableProjectOptions);
      return this.translateDefaultProjectsOptions(filteredProjects);
    },
    filterSelfModules: function filterSelfModules(projects) {
      var _this8 = this;
      return this.isNotFiltered ? projects : projects.filter(function (project) {
        return project.value !== _this8.module;
      });
    },
    translateDefaultProjectsOptions: function translateDefaultProjectsOptions(options) {
      var _this9 = this;
      return options.map(function (_ref3) {
        var value = _ref3.value,
          label = _ref3.label,
          rights = _ref3.rights;
        return {
          value: value,
          label: _this9.$trans(label),
          rights: rights
        };
      });
    },
    preloadSelectedItems: function preloadSelectedItems() {
      var _this10 = this;
      var modulesToLoad = Object.keys(this.links);
      var promises = [];
      if (this.data.linksFromGaps) {
        modulesToLoad = _toConsumableArray(new Set([].concat(_toConsumableArray(Object.keys(this.data.linksFromGaps)), _toConsumableArray(modulesToLoad))));
      }
      if (this.governanceReviewLinkId && !Object.keys(this.links).includes(this.governanceReviewLinkModule)) {
        modulesToLoad.push(this.governanceReviewLinkModule);
      }
      modulesToLoad.forEach(function (module) {
        if (_this10.disabledOptions[module]) return;
        var getAllItems = module !== MODULES.CONTRACTS;
        promises.push(_this10.getPromise(module, getAllItems));
      });
      return Promise.all(promises);
    },
    loadAdditionalData: function loadAdditionalData() {
      var _this11 = this;
      var promises = [];
      if (this.module === MODULES.COMPLIANCE) {
        promises.push(this.getGapAnalysisWithMitigatingActions().then(function (_ref4) {
          var list = _ref4.list;
          return _this11.prepareMitigatingActions(list);
        }));
      }
      if (this.data.gapListUrl) {
        promises.push(getByUrl(this.data.gapListUrl).then(function (_ref5) {
          var list = _ref5.list;
          return _this11.prepareGapData(list);
        }));
      }
      if (this.data.nodeListUrl) {
        promises.push(getByUrl(this.data.nodeListUrl).then(function (_ref6) {
          var list = _ref6.list;
          return _this11.prepareQuestionnaireData(list);
        }));
      }
      return Promise.all(promises);
    },
    getPromise: function getPromise(link) {
      var getAllItems = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (this.disabledOptions[link]) {
        return;
      }
      if (getAllItems && !this.loadedModulesMap.includes(link)) {
        this.loadedModulesMap.push(link);
      }
      switch (link) {
        case MODULES.CONTRACTS:
          // right now only contracts could preload only necessary items
          return this.getContracts(getAllItems);
        // but if user opens links modal he wants to see all of them
        case MODULES.COMPANY:
          return this.getCompanies();
        case MODULES.COMPLIANCE:
          return this.getComplianceNodes();
        case MODULES.CONTROLS:
          return this.getControls();
        case MODULES.EXECUTION:
          return this.getActions();
        case MODULES.RISK:
          return this.getRisks();
        case MODULES.COMPLIANCE_PROCESS_TREE_NODE:
          return this.getNodeElementAllData();
        case MODULES.COMPLIANCE_IA_SYSTEM:
          return this.getInformationAssetsSystems();
        case MODULES.COMPLIANCE_IA_PROCESSOR:
          return this.getInformationAssetsProcessors();
        case MODULES.COMPLIANCE_IA_CONTROLLER:
          return this.getInformationAssetsControllers();
        case MODULES.INCIDENTS:
          return this.getIncidents();
        case MODULES.COMPLIANCE_INFORMATION_ASSETS:
          return this.getCustomInformationAssets();
        case MODULES.RISK_ASSESSMENT:
          return this.getRiskAssessment();
      }
    },
    getContracts: function getContracts() {
      var _this12 = this;
      var getAllItems = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var ids = null;
      if (!getAllItems) {
        ids = this.links[MODULES.CONTRACTS];
      }
      return DataAPI.getContracts(true, ids).then(function (_ref7) {
        var list = _ref7.list;
        return _this12.dataset[MODULES.CONTRACTS] = list;
      }).catch(function () {});
    },
    getCompanies: function getCompanies() {
      var _this13 = this;
      return _getCompanies().then(function (_ref8) {
        var list = _ref8.list;
        return _this13.dataset[MODULES.COMPANY] = list;
      });
    },
    getRiskAssessment: function getRiskAssessment() {
      var _this14 = this;
      var id = this.data.links[MODULES.RISK_ASSESSMENT][0];
      var params = "?filters[id]=".concat(id);
      return getReports(params).then(function (_ref9) {
        var list = _ref9.list;
        return _this14.dataset[MODULES.RISK_ASSESSMENT] = list || [];
      }).catch(function () {});
    },
    getWorkflowStatuses: function getWorkflowStatuses(module) {
      var _this15 = this;
      return getWorkflowStatusesByModule(module).then(function (_ref10) {
        var list = _ref10.list;
        return _this15.workflows[module] = list;
      }).catch(function () {});
    },
    getIncidents: function getIncidents() {
      var _this16 = this;
      //doestn`t have analogue in dataapi
      return _getIncidents().then(function (response) {
        return _this16.dataset[MODULES.INCIDENTS] = response;
      });
    },
    getNodeElementAllData: function getNodeElementAllData() {
      return DataAPI.getAllTrees().then(this.setIdToProccessTreeIdAndGetNodes);
    },
    getGapAnalysisWithMitigatingActions: function getGapAnalysisWithMitigatingActions() {
      var query = '?filters[]=onlyItemsWithLinks&data[]=article';
      var projectId = this.data.compliance_projects_id;
      var nodeId = this.data.id;
      return getAllForNode(projectId, nodeId, query);
    },
    setIdToProccessTreeIdAndGetNodes: function setIdToProccessTreeIdAndGetNodes(response) {
      var _this17 = this;
      if (response[0].id !== null) {
        this.processTreeId = response[0].id;
      }
      return DataAPI.getNodesByProcessTreeId(this.processTreeId).then(function (rootNode) {
        var processLibraryNodes = [];
        var nodesWithFormattedTitle = formatNodes(rootNode.children);
        Utils.pushChildrenToParent({
          children: nodesWithFormattedTitle
        }, processLibraryNodes);
        _this17.dataset[MODULES.COMPLIANCE_PROCESS_TREE_NODE] = processLibraryNodes;
      });
    },
    getLinkItems: function getLinkItems() {
      var items = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      return items.map(function (item) {
        return list.find(function (l) {
          return l.id === item;
        });
      }).filter(Boolean);
    },
    getControls: function getControls() {
      var _this18 = this;
      return DataAPI.getControls().then(function (_ref11) {
        var list = _ref11.list;
        _this18.controls = list;
        var controlsFiltered = list.filter(function (control) {
          return control.active && !control.isGovernanceReview;
        });
        var controls = controlsFiltered.sort(function (a, b) {
          return comparePathStrings(a.path, b.path);
        });
        _this18.dataset[MODULES.CONTROLS] = controls;
      });
    },
    getActions: function getActions() {
      var _this19 = this;
      return DataAPI.getInitiatives().then(function (_ref12) {
        var list = _ref12.list;
        _this19.dataset[MODULES.EXECUTION] = list;
      });
    },
    getRisks: function getRisks() {
      var _this20 = this;
      return DataAPI.getRisks().then(function (_ref13) {
        var list = _ref13.list;
        var riskProjectsIds = _this20.projects.filter(function (project) {
          return project.objType === ObjectTypes.RISK_PROJECT;
        }).map(function (project) {
          return +project.id;
        });
        var risks = list.filter(function (risk) {
          return riskProjectsIds.includes(+risk.projectId);
        });
        var additionalContent = _this20.prepareDatasetByAdditionalContent(risks, MODULES.RISK, 'compliance_projects_id', 'projectId');
        Object.keys(additionalContent).forEach(function (key) {
          _this20.dataset[key] = additionalContent[key];
        });
      });
    },
    getComplianceNodes: function getComplianceNodes() {
      var _this21 = this;
      return DataAPI.getNodes([], false).then(function (_ref14) {
        var list = _ref14.list;
        var nodesFlat = [];
        list.forEach(function (compliance) {
          var nodesWithFormattedTitle = formatNodes(compliance.children);
          Utils.pushChildrenToParent({
            children: nodesWithFormattedTitle
          }, nodesFlat);
        });
        nodesFlat = _this21.formatListToDataset(nodesFlat);
        _this21.complianceNodes = nodesFlat;
        var additionalContent = _this21.prepareDatasetByAdditionalContent(nodesFlat, MODULES.COMPLIANCE, 'compliance_projects_id', 'projectId');
        Object.keys(additionalContent).forEach(function (key) {
          _this21.dataset[key] = additionalContent[key];
        });
      });
    },
    formatListToDataset: function formatListToDataset(list) {
      return list.map(function (item) {
        if (item.traffic_light !== undefined) {
          item.trafficLight = item.traffic_light;
        }
        return item;
      });
    },
    getInformationAssetsSystems: function getInformationAssetsSystems() {
      var _this22 = this;
      return DataAPI.getInformationAssetsSystems().then(function (_ref15) {
        var list = _ref15.list;
        _this22.dataset[MODULES.COMPLIANCE_IA_SYSTEM] = list;
      });
    },
    getInformationAssetsProcessors: function getInformationAssetsProcessors() {
      var _this23 = this;
      return DataAPI.getInformationAssetsProcessors().then(function (_ref16) {
        var list = _ref16.list;
        _this23.dataset[MODULES.COMPLIANCE_IA_PROCESSOR] = list;
      });
    },
    getInformationAssetsControllers: function getInformationAssetsControllers() {
      var _this24 = this;
      return DataAPI.getInformationAssetsControllers().then(function (_ref17) {
        var list = _ref17.list;
        _this24.dataset[MODULES.COMPLIANCE_IA_CONTROLLER] = list;
      });
    },
    getCustomInformationAssets: function getCustomInformationAssets() {
      var _this25 = this;
      return getAllCustomInformationAssets().then(function (_ref18) {
        var customIA = _ref18.list;
        var previewActivityList = _this25.filterByObjTypes({
          customIA: customIA
        }, _this25.activityTypes).filter(function (item) {
          return item.list.length;
        });
        var preparedIAList = _this25.prepareIAObject(customIA, previewActivityList);
        var additionalContent = _this25.prepareDatasetByAdditionalContent(preparedIAList, MODULES.COMPLIANCE_INFORMATION_ASSETS, 'activity_id');
        Object.keys(additionalContent).forEach(function (key) {
          _this25.dataset[key] = additionalContent[key];
        });
        _this25.dataset[MODULES.COMPLIANCE_INFORMATION_ASSETS] = preparedIAList;
        _this25.activityList = previewActivityList;
      }).catch(function () {});
    },
    getProjects: function getProjects() {
      var _this26 = this;
      var promises = [Promise.all([DataAPI.getComplianceProjects(), getRiskProjects()]).then(function (_ref19) {
        var _ref20 = _slicedToArray(_ref19, 2),
          compliance = _ref20[0],
          risk = _ref20[1];
        _this26.projects = [].concat(_toConsumableArray(compliance.list), _toConsumableArray(risk.list));
      })];
      if (this.currentUser.iaAccess || this.isAdmin) {
        promises.push(this.getCustomInformationAssets());
      }
      return Promise.all(promises);
    },
    expandDataset: function expandDataset() {
      var _this27 = this;
      this.projects.forEach(function (item) {
        var module = _this27.getPurifiedModuleName(item.objType);
        _this27.dataset["".concat(module, "-").concat(item.id)] = null;
      });
      this.activityList.forEach(function (item) {
        var module = item.objType;
        _this27.dataset["".concat(module, "-").concat(item.id)] = null;
      });
    },
    prepareIAObject: function prepareIAObject(IAList, activityList) {
      return IAList.map(function (ia) {
        var newIA = {};
        activityList.forEach(function (activity) {
          var containsCurrentIA = activity.list.some(function (item) {
            return item.id === ia.id;
          });
          if (containsCurrentIA) {
            newIA = _objectSpread(_objectSpread({}, ia), {}, {
              activity_id: activity.id
            });
          }
        });
        return newIA;
      });
    },
    prepareDatasetByAdditionalContent: function prepareDatasetByAdditionalContent(data, module) {
      for (var _len = arguments.length, props = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        props[_key - 2] = arguments[_key];
      }
      var result = {};
      data.forEach(function (item) {
        var property = props.find(function (prop) {
          return !!item[prop];
        });
        var key = "".concat(module, "-").concat(item[property]);
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push(item);
      });
      result[module] = data;
      return result;
    },
    setLinksOption: function setLinksOption(datasetKey) {
      var _this28 = this;
      var module = this.getPurifiedModuleName(datasetKey);
      if (WrongModules.indexOf(module) !== -1) {
        return this.linksOptions = [];
      }
      var promise = this.loadedModulesMap.includes(module) ? Promise.resolve() : this.getPromise(module);
      promise.then(function () {
        var linksOptions = _this28.dataset[datasetKey] || [];
        if (module === MODULES.COMPANY) {
          linksOptions = linksOptions.map(function (item) {
            return _objectSpread(_objectSpread({}, item), {}, {
              trafficLight: item.trafficLight ? item.trafficLight : {}
            });
          });
        }
        if (_this28.module === module) {
          linksOptions = linksOptions.filter(function (item) {
            return item.id !== _this28.data.id;
          });
        }
        _this28.linksOptions = linksOptions;
      });
      this.setPreselectedLinks(datasetKey);
    },
    getScoringForERM: function getScoringForERM() {
      var _this29 = this;
      return getCurrentScoring().then(function (scoring) {
        return _this29.scoring = scoring;
      });
    },
    setPreselectedLinks: function setPreselectedLinks(module) {
      if (!this.linkItems[module]) {
        this.preselectedLinks = [];
        return;
      }
      this.preselectedLinks = _toConsumableArray(this.linkItems[module].items);
    },
    toggleLinksModal: function toggleLinksModal() {
      this.preselectedLinks = [];
      this.showLinksModal = !this.showLinksModal;
    },
    onUpdateLinks: function onUpdateLinks(_ref21) {
      var _this30 = this;
      var module = _ref21.module,
        links = _ref21.links;
      var moduleName = this.getPurifiedModuleName(module);
      this.toggleLinksModal();
      var temporaryLinks = [];
      if (moduleName !== module && this.links[moduleName]) {
        //code for compl and risk projects and their update handle
        this.links[moduleName].forEach(function (id) {
          if (_this30.dataset[module].find(function (item) {
            return id === item.id;
          })) {
            var index = links.indexOf(id);
            if (index !== -1) {
              temporaryLinks.push(id);
              links.splice(index, 1);
            }
          } else {
            temporaryLinks.push(id);
          }
        });
      }
      temporaryLinks = temporaryLinks.concat(links);
      this.links = _objectSpread(_objectSpread({}, this.links), {}, _defineProperty({}, moduleName, temporaryLinks));
    },
    setExpandedProjectsOptionsList: function setExpandedProjectsOptionsList() {
      this.projectsOptions = this.getExpandedProjectsOptionsList();
      this.projectOptionsWithDefault = this.getExpandedProjectsOptionsList(true);
    },
    getExpandedProjectsOptionsList: function getExpandedProjectsOptionsList() {
      var _this31 = this;
      var withDefaultProjects = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var defaultProjects = this.getDefaultProjectsOptions();
      var expandedProjects = [];
      defaultProjects.forEach(function (project) {
        if (project.value === MODULES.COMPLIANCE || project.value === MODULES.RISK) {
          var additionalProjects = _this31.projects.filter(function (item) {
            return item.objType.indexOf(project.value) !== -1;
          }).map(function (item) {
            return {
              label: "".concat(project.label, " - ").concat(item.title),
              value: "".concat(project.value, "-").concat(item.id),
              rights: project.rights && (item.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type && item.enabled && item.objType === ObjectTypes.RISK_PROJECT || userHasProjectAccess(_this31.currentUser, item.id, item.objType === ObjectTypes.RISK_PROJECT ? ObjectTypes.RISK_PROJECT : ObjectTypes.COMPLIANCE_PROJECT))
            };
          });
          if (withDefaultProjects) {
            expandedProjects.push(project);
          }
          expandedProjects.push.apply(expandedProjects, _toConsumableArray(additionalProjects));
        } else if (project.value === MODULES.COMPLIANCE_INFORMATION_ASSETS) {
          var _additionalProjects = _this31.activityList.filter(function (item) {
            return item.list.length;
          }).map(function (item) {
            return {
              label: item.label,
              value: "".concat(project.value, "-").concat(item.id),
              rights: project.rights
            };
          });
          if (withDefaultProjects) {
            expandedProjects.push(project);
          }
          expandedProjects.push.apply(expandedProjects, _toConsumableArray(_additionalProjects));
        } else {
          expandedProjects.push(project);
        }
      });
      return expandedProjects;
    },
    deleteLink: function deleteLink(module, linkId) {
      var purifiedModuleName = this.getPurifiedModuleName(module);
      var itemTypeTitle = this.itemTitleByModule[purifiedModuleName].toLowerCase();
      this.itemDeletionData = {
        module: module,
        linkId: linkId,
        header: "".concat(this.translate.remove, " ").concat(itemTypeTitle),
        body: "".concat(this.translate.areYouSureYouWantToRemoveThisLinked, " ").concat(itemTypeTitle, "?")
      };
    },
    handleDeleteLink: function handleDeleteLink(module, linkId) {
      var moduleName = this.getPurifiedModuleName(module);
      var updatedLinks = this.links[moduleName].filter(function (id) {
        return id !== linkId;
      });
      this.links = _objectSpread(_objectSpread({}, this.links), {}, _defineProperty({}, moduleName, updatedLinks));
      this.itemDeletionData = null;
    },
    getPurifiedModuleName: function getPurifiedModuleName(module) {
      return module.split('-')[0];
    },
    handlePreview: function handlePreview(item) {
      var _this32 = this;
      this.selectedTag = item;
      this.hasErrorFromBackend = false;
      this.previewData = {
        data: null,
        list: null,
        type: 'default'
      };
      switch (this.currentTab) {
        case TabTypes.LINKS:
          this.handleLinkPreview(item);
          break;
        case TabTypes.QUESTIONNAIRE:
          this.handleQuestionnairePreview(item);
          this.handleLinkPreview(item);
          break;
        case TabTypes.GAPLINKS:
          if (item.mitigatingItems) {
            this.previewData.list = item;
            this.previewData.type = this.LinkTypes.GAP_ANALYSIS;
            this.previewData.data = [];
            item.mitigatingItems.forEach(function (item) {
              _this32.previewData.data.push(_this32.preparePreviewData(item));
            });
          }
          if (item.gapList) {
            this.previewData.list = item;
            this.previewData.type = this.LinkTypes.MITIGATING_ACTIVITIES;
            this.handleLinkPreview({
              id: item.nodeId || item.dataProcessorId,
              projectId: item.projectId,
              objType: item.objType,
              title: item.title
            });
            return;
          }
      }
    },
    handleQuestionnairePreview: function handleQuestionnairePreview(item) {
      var _this33 = this;
      this.isLoadingPreview = true;
      return getAllQuestionAnswersForNode(item.compliance_projects_id, item.id, item.questionnaireId).then(function (_ref22) {
        var list = _ref22.list;
        return _this33.setPreviewQuestionnaire(list);
      });
    },
    setPreviewQuestionnaire: function setPreviewQuestionnaire(questions) {
      var _this34 = this;
      this.previewData.list = [];
      questions.forEach(function (question) {
        var found = null;
        if (_this34.isIA(_this34.module)) {
          var type = ComplianceControllers[_this34.data.objType];
          found = question.informationAssets[type].find(function (item) {
            return item.id === _this34.data.id;
          });
        } else {
          found = question.processLibrary.find(function (item) {
            return item.id === _this34.data.id;
          });
        }
        if (found) {
          _this34.previewData.list.push(question);
        }
      });
      this.isLoadingPreview = false;
    },
    isIA: function isIA(module) {
      return module === MODULES.COMPLIANCE_IA_SYSTEM || module === MODULES.COMPLIANCE_IA_CONTROLLER || module === MODULES.COMPLIANCE_IA_PROCESSOR;
    },
    handleLinkPreview: function handleLinkPreview(item) {
      var _this35 = this;
      this.isLoadingPreview = true;
      var apiPromise = this.getSingleItemPromise(item);
      var responseError = 'responseError';
      if (apiPromise) {
        apiPromise.then(function (response) {
          if (_this35.isResponseError(response)) {
            throw new Error(responseError);
          }
          _this35.previewData.data = _this35.preparePreviewData(response);
        }).catch(function (error) {
          _this35.isLoadingPreview = false;
          if (error.message === responseError) {
            var _item$path;
            var displayHeadline = ((_item$path = item.path) !== null && _item$path !== void 0 ? _item$path : '') + ' ' + item.title;
            _this35.displayErrorMessage("".concat(_this35.translate.youDontHaveRightsToSee, " ").concat(displayHeadline));
            return;
          }
          _this35.previewData.data = _this35.preparePreviewData(item);
        });
      } else {
        this.previewData.data = this.preparePreviewData(item);
      }
    },
    displayErrorMessage: function displayErrorMessage(message) {
      this.errorMessage = message;
      this.hasErrorFromBackend = true;
    },
    isResponseError: function isResponseError(response) {
      if (response.error || response.fullError || response.status === 403) return true;
      if (response.data && response.data.error) return true;
      if (response.response && response.response.error) return true;
      return false;
    },
    getSingleItemPromise: function getSingleItemPromise(item) {
      switch (item.objType) {
        case ObjectTypes.EXECUTION_INITIATIVE_LIMITED:
          return getSingleInitiative(item.slug);
        case ObjectTypes.RISK_RISK_LIMITED:
          return getRiskData(item.projectId, item.projectPeriodId, item.rno, '', true);
        case ObjectTypes.CONTROLS_CONTROL_LIMITED:
          return getControlById(item.cpath);
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER:
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM:
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR:
          return getSingleByType(ComplianceControllers[item.objType], item.id);
        case ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE_LIMITED:
          return getNodeById(this.processTreeId, item.id);
        case ObjectTypes.CONTRACT_LIMITED:
          return getContract(item.id);
        case ObjectTypes.COMPLIANCE_NODE:
        case ObjectTypes.COMPLIANCE_NODE_LIMITED:
          return getComplianceNode(item.projectId || item.compliance_projects_id, item.id, '?data[]');
        default:
          return null;
      }
    },
    preparePreviewData: function preparePreviewData(item) {
      var result = {
        // case compliance is the same as this object
        id: item.id,
        title: item.title,
        description: item.description || '',
        organisationIds: item.organisationIds || [],
        responsibleIds: item.responsibleUserIds || [],
        hoverTitle: DatatableRenderer.getItemHoverTitle(item),
        confidential: (item === null || item === void 0 ? void 0 : item.confidential) || false,
        url: item.url || item.apiUrl,
        objType: item.objType
      };
      switch (item.objType) {
        case ObjectTypes.CONTRACT_LIMITED:
        case ObjectTypes.CONTRACT:
          {
            result = _objectSpread(_objectSpread({}, result), {}, {
              start: formatDate.toLocalDate(item.startDate),
              expirationDate: formatDate.toLocalDate(item.endDate)
            });
            if (item.workflowStatusId) {
              var status = this.workflows[MODULES.CONTRACTS].find(function (status) {
                return status.id === item.workflowStatusId;
              });
              result.status = status ? this.$trans(status.title) : '';
            }
            break;
          }
        case ObjectTypes.EXECUTION_INITIATIVE_LIMITED:
        case ObjectTypes.EXECUTION_INITIATIVE:
          result = _objectSpread(_objectSpread({}, result), {}, {
            trafficLight: item.trafficLight,
            closed: item.closed,
            path: item.path,
            prStatus: item.status,
            start: item.start,
            deadline: item.deadline,
            responsibleIds: item.userIds && item.userIds.responsible || [],
            slug: item.slug
          });
          break;
        case ObjectTypes.CONTROLS_CONTROL_LIMITED:
        case ObjectTypes.CONTROLS_CONTROL:
          result = _objectSpread(_objectSpread({}, result), {}, {
            trafficLight: item.trafficLight || 0,
            cpath: item.cpath,
            path: item.cpath,
            deadline: formatDate.toLocalDate(item.deadline),
            reviewDeadline: formatDate.toLocalDate(item.reviewDeadline),
            responsibleIds: item.userIds && item.userIds.responsible || [],
            reviewIds: item.userIds && item.userIds.review || [],
            active: item.active,
            isMothercontrol: item.isMothercontrol
          });
          break;
        case ObjectTypes.RISK_RISK_LIMITED:
        case ObjectTypes.RISK_RISK:
          result = _objectSpread(_objectSpread({}, result), {}, {
            path: item.rno,
            rno: item.rno,
            responsibleIds: item.userIds && [+item.userIds.responsible] || +item.responsibleUserIds || [],
            status: item.status,
            projectId: item.projectId,
            projectPeriodId: item.projectPeriodId,
            scoringId: item.scoringId
          });
          if (checkERMByProjectId(item.projectId, this.currentUser)) {
            result.likelyhood = item.likelihood_score;
            result.impact = item.impact_score;
          } else {
            var project = this.projects.find(function (project) {
              return project.id === item.projectId && project.objType === ObjectTypes.RISK_PROJECT;
            });
            var period = project.projectPeriods.find(function (period) {
              return period.isCurrent;
            });
            var threats = RisksUtils.formatThreats(project.threats);
            var levelOfThreats = RisksUtils.formatThreatLevels(period.threatLevels);
            var vulnerabilities = RisksUtils.formatVulnerabilities(project.vulnerabilities);
            // const probabilities = RisksUtils.formatProbabilities(period.probabilityLabels);

            result.threat = Utils.findPropsByIds(item[RiskBaseModelProps.THREAT_IDS], threats, 'label');
            result.threatLevel = Utils.findValueById(levelOfThreats, item[RiskBaseModelProps.THREAT_LEVEL_VALUE], 'label');
            result.vulnerability = Utils.findPropsByIds(item[RiskBaseModelProps.VULNERABILITY_IDS], vulnerabilities, 'label');
            //this.previewData.data.probability = Utils.findValueById(probabilities, item[RiskBaseModelProps.PROBABILITY_SCORE], 'label');
          }

          break;
        case ObjectTypes.INCIDENTS_INCIDENT:
          result = _objectSpread(_objectSpread({}, result), {}, {
            incOccurred: formatDate.toLocalDateTime(item.incidentHappenedAt) || this.translate.notSet,
            severity: item.severity,
            status: Object.values(INCIDENT_STATES).find(function (state) {
              return state.value === item.rismaState;
            }).name
          });
          break;
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER:
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM:
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR:
          result = _objectSpread(_objectSpread({}, result), {}, {
            trafficLight: item.trafficLight || item.traffic_light || 0,
            path: item.uniqueId || '',
            compliance_projects_id: item.compliance_projects_id
          });
          break;
        case ObjectTypes.COMPLIANCE_NODE:
        case ObjectTypes.COMPLIANCE_NODE_LIMITED:
          result = _objectSpread(_objectSpread({}, result), {}, {
            trafficLight: item.trafficLight || item.traffic_light || 0,
            path: item.uniqueId || '',
            compliance_projects_id: item.compliance_projects_id,
            descriptionLabel: getProjectDescriptionLabel(this.currentUserComplianceProjectById(item.compliance_projects_id))
          });
          break;
        case ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE_LIMITED:
        case ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE:
          result = _objectSpread(_objectSpread({}, result), {}, {
            trafficLight: item.trafficLight || 0,
            processTreeId: item.processTreeId
          });
          break;
        case ObjectTypes.COMPANY:
          result = {
            id: item.id,
            title: item.name,
            vatNo: item.vatNo,
            countryCode: item.countryCode,
            city: item.city,
            address: item.address,
            phone: item.phone,
            zipCode: item.zipCode,
            email: item.email,
            url: item.url || "/company/".concat(item.id, "/"),
            vatStatus: item.vatStatus,
            objType: item.objType
          };
          break;
      }
      this.isLoadingPreview = false;
      return result;
    },
    getProjectOptionByModule: function getProjectOptionByModule(linkModule) {
      var defaultOptions = this.projectOptionsWithDefault;
      var options = [].concat(_toConsumableArray(defaultOptions), [this.GovernanceReviewOptionsLinksTab, getProjectRiskAssessmentLinksTab()]);
      var projectOption = options.find(function (item) {
        return item.value === linkModule;
      });
      if (!projectOption) {
        throw new Error("Could not find project option for ".concat(linkModule));
      }
      return projectOption;
    },
    projectOptionExistsByModule: function projectOptionExistsByModule(linkModule) {
      var _this36 = this;
      // split is needed for risk and compl -> their linkModule names like risk-1, risk-2, etc.
      return this.projectsOptions.some(function (item) {
        return _this36.getPurifiedModuleName(item.value) === linkModule;
      });
    },
    addNewItem: function addNewItem(item) {
      item.id = +item.id;
      switch (item.objType) {
        case ObjectTypes.CONTROLS_CONTROL:
          this.dataset[MODULES.CONTROLS].push(item);
          this.controls.push(item);
          break;
        case ObjectTypes.EXECUTION_INITIATIVE:
          this.dataset[MODULES.EXECUTION].push(item);
          break;
      }
    },
    getGovernanceReviewLinkNode: function getGovernanceReviewLinkNode() {
      var _this37 = this;
      var nodes = [];
      if (this.governanceReviewLinkModule === MODULES.COMPLIANCE) {
        nodes = this.complianceNodes;
      } else if (this.governanceReviewLinkModule === MODULES.COMPLIANCE_PROCESS_TREE_NODE) {
        nodes = this.dataset[MODULES.COMPLIANCE_PROCESS_TREE_NODE];
      } else if (this.governanceReviewLinkModule === MODULES.COMPLIANCE_INFORMATION_ASSETS) {
        nodes = this.dataset[MODULES.COMPLIANCE_INFORMATION_ASSETS];
      }
      var node = nodes.find(function (node) {
        return node.id == _this37.governanceReviewLinkId;
      });
      return node && [node];
    },
    hasDeleteOptions: function hasDeleteOptions(module) {
      return this.currentTab === this.tabTypes.LINKS && module !== this.GovernanceReviewOptionsLinksTab.value;
    }
  }
};