var _excluded = ["query", "filterType"];
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useUserStore } from '@/Store/userStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import * as API from '@/api/compliance/reports/nodes';
import { getInformationAssetsSystems, getAllTrees as _getAllTrees, getNodesByProcessTreeId as _getNodesByProcessTreeId, getRisks as _getRisks, getControls as _getControls, getInitiatives as _getInitiatives, getInformationAssetsProcessors, getInformationAssetsControllers } from '@/api/risma/data';
import { getAllCustomInformationAssets } from '@/api/compliance/informationAssets';
import { getDataflowReport } from '@/api/compliance/reports/dataflows';
import { getContracts as _getContracts } from '@/api/contracts/contracts';
import { getAll as _getIncidents } from '@/api/incident/incident';
import { getRiskProjects } from '@/api/risk/risk';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import HierarchicalList from '@/components/Molecules/HierarchicalList';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import { ReportSearchForm, NoSearchResults } from '../index';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { filterAsset } from '@/utils/format/objectsFromIds';
import { mapToCommaListWithLinks, mapToCommaList } from '@/utils/format/mapTo';
import { getHierarchicalList, buildHierarchicalStructure } from '@/utils/format/hierarchicalList';
import { MODULES } from '@/constants/modules';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { LiaTiaType } from '@/constants/compliance/LiaTiaGeneric';
import * as Utils from '@/utils/Utils';
import { ReportTypes } from '@/constants/reports';
import { getProjectDescriptionLabel, getProjectPurposeLabel, getProjectStatusLabel } from '@/utils/Compliance/complianceProject';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'ReportNodes',
  introduction: 'A wrapper for the compliance node report',
  description: 'A wrapper for the compliance report to handle search in Nodes. This will display a input-field along with a report-search-form-component',
  token: '<report-nodes />',
  components: {
    ReportSearchForm: ReportSearchForm,
    NoSearchResults: NoSearchResults,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    InputField: InputField,
    RismaDatatable: RismaDatatable,
    PreviewModalLink: PreviewModalLink,
    HierarchicalList: HierarchicalList,
    Trafficlight: Trafficlight,
    SaveReport: SaveReport
  },
  mixins: [RaciTitleMixin, RismaDatatableDefaultMixin, SaveReportMixin],
  props: {
    projectId: {
      type: [Number, String],
      required: true,
      note: 'The Project ID which is passed by VueRouter'
    },
    savedReportOptions: {
      type: Object,
      default: null
    }
  },
  setup: function setup(props) {
    var localStorageKey = "node_report_filters_".concat(props.projectId);
    return {
      searchArgs: getLocalStorageComputed(localStorageKey, {}, true)
    };
  },
  data: function data() {
    return {
      loading: false,
      controllers: [],
      dataset: [],
      noResults: false,
      updatedNode: null,
      queryInput: '',
      systems: [],
      actions: [],
      contracts: [],
      controls: [],
      informationAssets: [],
      processors: [],
      incidents: [],
      processNodes: [],
      risks: [],
      dataflowReport: [],
      decoupleFilterFields: [{
        key: 'responsible'
      }, {
        key: 'accountable'
      }, {
        key: 'consulted'
      }, {
        key: 'informed'
      }, {
        key: 'controllers'
      }, {
        key: 'organisations'
      }, {
        key: 'systems'
      }],
      disabledFilterByColumns: ['process', 'attachedFiles', 'questionnaireAnsweredPercentage', 'description', 'purpose', 'processStatus'],
      customFields: [],
      columnSorting: [{
        key: 'questionnaireAnsweredPercentage',
        sortingFunc: this.sortNumerically
      }],
      liaTiaNodes: [],
      allNodes: [],
      ProjectTypes: ProjectTypes,
      stateLocal: "compliance_nodes_search_report_".concat(this.projectId),
      processTreeId: 0,
      scoringIncludedInDatatable: false,
      customFieldsColumns: {},
      customFieldsDefault: {},
      customFieldsById: {},
      serverError: null,
      reportType: ReportTypes.COMPLIANCE_NODE,
      initialFilters: {
        scoringIncluded: true
      },
      translate: getTranslate['ReportNodes']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useActivityTypesStore, {
    activityTypes: 'complianceActivityTypes',
    customIA: 'complianceIACustomActivityTypes'
  })), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useTagsStore, ['tags'])), mapState(useUserStore, {
    currentUserComplianceProjectById: 'complianceProjectById'
  })), {}, {
    currentProject: function currentProject() {
      return this.currentUserComplianceProjectById(this.projectId);
    },
    rpoFieldId: function rpoFieldId() {
      return this.getSettingValue('feature.isms_rpo_field_id');
    },
    rtoFieldId: function rtoFieldId() {
      return this.getSettingValue('feature.isms_rto_field_id');
    },
    columns: function columns() {
      var columns = this.getSettingValue('feature.show_node_id') ? {
        id: this.translate.id
      } : {};
      columns = _objectSpread(_objectSpread({}, columns), {}, {
        process: this.translate.process,
        description: getProjectDescriptionLabel(this.currentProject),
        purpose: getProjectPurposeLabel(this.currentProject),
        processStatus: getProjectStatusLabel(this.currentProject),
        questionnaire: this.translate.questionnaire,
        questionnaireAnsweredPercentage: this.translate.completionDegreeIn,
        gap_schema: this.translate.gapSchemaName,
        activityType: this.translate.activityType,
        responsible: this.responsibleTitle(true),
        accountable: this.accountableTitle(true),
        consulted: this.consultedTitle(true),
        informed: this.informedTitle(true),
        controllers: this.translate.dataControllers,
        organisations: this.translate.organisations,
        systems: this.translate.systems,
        actions: this.translate.actions,
        contracts: this.translate.contracts,
        controls: this.translate.controls,
        processors: this.translate.processors,
        incidents: this.translate.incidents,
        processNodes: this.translate.processLibrary,
        risks: this.translate.risks,
        dataflow: this.translate.dataFlow,
        attachedFiles: this.translate.attachedFiles,
        tags: this.translate.tags
      });
      if (this.getSettingValue('feature.questionnaire_scoring') && this.scoringIncludedInDatatable) {
        columns.score = this.translate.score;
        columns.scoreHelp = this.translate.assessmentScoring;
      }
      if (this.getSettingValue('feature.risk_assessment_on_systems')) {
        columns.rto = this.translate.rto;
        columns.rpo = this.translate.rpo;
      }
      columns = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, columns), this.customInformationAssetsColumns), this.risksColumns), this.customFieldsColumns);
      return columns;
    },
    customInformationAssetsColumns: function customInformationAssetsColumns() {
      var informationAssetsColumns = {};
      this.informationAssets.forEach(function (ia) {
        informationAssetsColumns["".concat(MODULES.COMPLIANCE_INFORMATION_ASSETS, "-").concat(ia.id)] = ia.label;
      });
      return informationAssetsColumns;
    },
    risksColumns: function risksColumns() {
      var columns = {};
      this.risks.forEach(function (project) {
        columns["".concat(MODULES.RISK, "-").concat(project.id)] = project.label;
      });
      return columns;
    },
    showActivityType: function showActivityType() {
      return this.getSettingValue('feature.compliance_activity_types');
    },
    linkedColumnsForPreview: function linkedColumnsForPreview() {
      return ['systems', 'controllers', 'actions', 'contracts', 'controls', 'processors', 'incidents', 'processNodes', 'risks'].concat(_toConsumableArray(Object.keys(this.customInformationAssetsColumns)), _toConsumableArray(Object.keys(this.risksColumns)));
    },
    columnsMeta: function columnsMeta() {
      return [{
        targets: ['process', 'organisations', 'attachments'],
        width: '300px'
      }, {
        targets: ['questionnaire', 'gap_schema'],
        width: '250px'
      }, {
        targets: ['questionnaireAnsweredPercentage', 'responsible', 'accountable', 'consulted', 'informed'],
        width: '180px'
      }, {
        targets: ['description'],
        width: '340px'
      }, {
        targets: ['actions', 'contracts', 'controls', 'informationAssets', 'processors', 'incidents', 'processNodes', 'systems', 'risks'].concat(_toConsumableArray(Object.keys(this.customInformationAssetsColumns)), _toConsumableArray(Object.keys(this.risksColumns))),
        width: '340px',
        visible: false
      }];
    }
  }),
  watch: {
    savedReportOptions: function savedReportOptions() {
      if (this.savedReportOptions) {
        this.setAndLoadSavedReport(this.savedReportOptions);
      }
    }
  },
  mounted: function mounted() {
    if (!this.showActivityType) {
      delete this.columns.activityType;
    }
    this.setInitialState();
    this.getData();
  },
  methods: {
    getData: function getData() {
      return getDataflowReport(this.projectId).then(this.setDataFlowReport);
    },
    getProcessNodes: function getProcessNodes() {
      return this.getAllTrees().then(this.getNodesByProcessTreeId);
    },
    getAllTrees: function getAllTrees() {
      var _this = this;
      return _getAllTrees().then(function (response) {
        if (response[0].id !== null) {
          _this.processTreeId = response[0].id;
        }
      });
    },
    getNodesByProcessTreeId: function getNodesByProcessTreeId() {
      var _this2 = this;
      return _getNodesByProcessTreeId(this.processTreeId).then(function (rootNode) {
        var processLibraryNodes = [];
        Utils.pushChildrenToParent({
          children: rootNode.children
        }, processLibraryNodes);
        _this2.processNodes = processLibraryNodes;
      });
    },
    getCustomInformationAssets: function getCustomInformationAssets() {
      var _this3 = this;
      return getAllCustomInformationAssets().then(function (_ref) {
        var customIA = _ref.list;
        _this3.informationAssets = _this3.splitActivitiesByType(_this3.customIA, customIA, 'label', 'activityTypeId');
      });
    },
    getRisks: function getRisks() {
      var _this4 = this;
      return Promise.all([getRiskProjects(), _getRisks()]).then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
          riskProjects = _ref3[0].list,
          risks = _ref3[1].list;
        _this4.risks = _this4.splitActivitiesByType(riskProjects, risks, 'title', 'projectId');
      });
    },
    getControls: function getControls() {
      var _this5 = this;
      return _getControls().then(function (_ref4) {
        var list = _ref4.list;
        return _this5.controls = list || [];
      });
    },
    getContracts: function getContracts() {
      var _this6 = this;
      return _getContracts().then(function (_ref5) {
        var list = _ref5.list;
        return _this6.contracts = list || [];
      });
    },
    getIncidents: function getIncidents() {
      var _this7 = this;
      return _getIncidents().then(function (list) {
        return _this7.incidents = list || [];
      });
    },
    getInitiatives: function getInitiatives() {
      var _this8 = this;
      return _getInitiatives().then(function (_ref6) {
        var list = _ref6.list;
        return _this8.actions = list || [];
      });
    },
    getProcessors: function getProcessors() {
      var _this9 = this;
      return getInformationAssetsProcessors().then(function (_ref7) {
        var list = _ref7.list;
        return _this9.processors = list || [];
      });
    },
    getControllers: function getControllers() {
      var _this10 = this;
      return getInformationAssetsControllers().then(function (_ref8) {
        var list = _ref8.list;
        return _this10.controllers = list || [];
      });
    },
    getSystems: function getSystems() {
      var _this11 = this;
      return getInformationAssetsSystems().then(function (_ref9) {
        var list = _ref9.list;
        return _this11.systems = list || [];
      });
    },
    splitActivitiesByType: function splitActivitiesByType(types, activities, labelName, typeIdName) {
      var result = [];
      types.forEach(function (type) {
        var filteredActivities = activities.filter(function (a) {
          return +type.id === +a[typeIdName];
        });
        result.push({
          id: type.id,
          label: type[labelName],
          activities: filteredActivities
        });
      });
      return result;
    },
    setInitialState: function setInitialState() {
      if (this.savedReportOptions) {
        this.setAndLoadSavedReport(this.savedReportOptions);
      } else {
        this.initializeFiltersAndQueryInput(this.searchArgs);
      }
    },
    handleDoSearch: function handleDoSearch(_ref10) {
      var _this12 = this;
      var _ref10$organisations = _ref10.organisations,
        organisations = _ref10$organisations === void 0 ? [] : _ref10$organisations,
        _ref10$users = _ref10.users,
        users = _ref10$users === void 0 ? [] : _ref10$users,
        _ref10$scoringInclude = _ref10.scoringIncluded,
        scoringIncluded = _ref10$scoringInclude === void 0 ? true : _ref10$scoringInclude,
        _ref10$filterType = _ref10.filterType,
        filterType = _ref10$filterType === void 0 ? 0 : _ref10$filterType,
        _ref10$tags = _ref10.tags,
        tags = _ref10$tags === void 0 ? [] : _ref10$tags;
      var resetQuery = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      this.loading = true;
      this.noResults = false;
      this.serverError = undefined;
      this.scoringIncludedInDatatable = scoringIncluded;
      this.searchArgs = {
        organisations: organisations,
        users: users,
        tags: tags,
        query: this.queryInput,
        filterType: filterType,
        scoringIncluded: scoringIncluded
      };
      resetQuery && this.resetSavedReportQuery();
      var promises = [];
      promises.push(API.searchNodes(this.projectId, this.buildQuery(this.searchArgs), scoringIncluded).then(function (response) {
        return _this12.allNodes = response.list.filter(function (node) {
          return !(node.rootNode && node.rootNode.id === node.id);
        });
      }).then(this.loadAdditionalData));
      if (filterType > 0 && +this.projectId === ProjectTypes.GDPR) {
        promises.push(API.searchReports(this.projectId, "?filters[".concat(LiaTiaType[filterType].key, "Nodes]=1")).then(function (_ref11) {
          var list = _ref11.list;
          return _this12.liaTiaNodes = list;
        }));
      }
      return Promise.all(promises).then(function () {
        return _this12.generateDataset(_this12.allNodes, filterType);
      }).then(function () {
        _this12.noResults = _this12.dataset.length === 0;
      }).catch(function (error) {
        var _error$response;
        _this12.dataset = [];
        _this12.noResults = true;
        _this12.serverError = error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.error;
      }).finally(function () {
        _this12.loading = false;
        _this12.dataset.length && _this12.loadSavedReport();
      });
    },
    loadAdditionalData: function loadAdditionalData() {
      var _this13 = this;
      if (!this.allNodes.length) return Promise.resolve();
      var promises = {};
      this.allNodes.forEach(function (node) {
        var _node$links$MODULES$I, _node$links$MODULES$R, _node$links$MODULES$C, _node$links$MODULES$C2, _node$links$MODULES$E, _node$links$MODULES$C3, _node$links$MODULES$C4, _node$links$MODULES$C5, _node$links$dataproce, _node$links$MODULES$C6;
        if (node.links && (_node$links$MODULES$I = node.links[MODULES.INCIDENTS]) !== null && _node$links$MODULES$I !== void 0 && _node$links$MODULES$I.length && !promises[MODULES.INCIDENTS] && !_this13.incidents.length) {
          promises[MODULES.INCIDENTS] = _this13.getIncidents();
        }
        if (node.links && (_node$links$MODULES$R = node.links[MODULES.RISK]) !== null && _node$links$MODULES$R !== void 0 && _node$links$MODULES$R.length && !promises[MODULES.RISK] && !_this13.risks.length) {
          promises[MODULES.RISK] = _this13.getRisks();
        }
        if (node.links && (_node$links$MODULES$C = node.links[MODULES.CONTROLS]) !== null && _node$links$MODULES$C !== void 0 && _node$links$MODULES$C.length && !promises[MODULES.CONTROLS] && !_this13.controls.length) {
          promises[MODULES.CONTROLS] = _this13.getControls();
        }
        if (node.links && (_node$links$MODULES$C2 = node.links[MODULES.CONTRACTS]) !== null && _node$links$MODULES$C2 !== void 0 && _node$links$MODULES$C2.length && !promises[MODULES.CONTRACTS] && !_this13.contracts.length) {
          promises[MODULES.CONTRACTS] = _this13.getContracts();
        }
        if (node.links && (_node$links$MODULES$E = node.links[MODULES.EXECUTION]) !== null && _node$links$MODULES$E !== void 0 && _node$links$MODULES$E.length && !promises[MODULES.EXECUTION] && !_this13.actions.length) {
          promises[MODULES.EXECUTION] = _this13.getInitiatives();
        }
        if (node.links && (_node$links$MODULES$C3 = node.links[MODULES.COMPLIANCE_INFORMATION_ASSETS]) !== null && _node$links$MODULES$C3 !== void 0 && _node$links$MODULES$C3.length && !promises[MODULES.COMPLIANCE_INFORMATION_ASSETS] && !_this13.informationAssets.length) {
          promises[MODULES.COMPLIANCE_INFORMATION_ASSETS] = _this13.getCustomInformationAssets();
        }
        if (node.links && (_node$links$MODULES$C4 = node.links[MODULES.COMPLIANCE_IA_PROCESSOR]) !== null && _node$links$MODULES$C4 !== void 0 && _node$links$MODULES$C4.length && !promises[MODULES.COMPLIANCE_IA_PROCESSOR] && !_this13.processors.length) {
          promises[MODULES.COMPLIANCE_IA_PROCESSOR] = _this13.getProcessors();
        }
        if (node.links && (_node$links$MODULES$C5 = node.links[MODULES.COMPLIANCE_IA_CONTROLLER]) !== null && _node$links$MODULES$C5 !== void 0 && _node$links$MODULES$C5.length && !promises[MODULES.COMPLIANCE_IA_CONTROLLER] && !_this13.controllers.length) {
          promises[MODULES.COMPLIANCE_IA_CONTROLLER] = _this13.getControllers();
        }
        if (node.links && (_node$links$dataproce = node.links.dataprocessor_system) !== null && _node$links$dataproce !== void 0 && _node$links$dataproce.length && !promises.dataprocessor_system && !_this13.systems.length) {
          promises.dataprocessor_system = _this13.getSystems();
        }
        if (node.links && (_node$links$MODULES$C6 = node.links[MODULES.COMPLIANCE_PROCESS_TREE_NODE]) !== null && _node$links$MODULES$C6 !== void 0 && _node$links$MODULES$C6.length && !promises[MODULES.COMPLIANCE_PROCESS_TREE_NODE] && !_this13.processNodes.length) {
          promises[MODULES.COMPLIANCE_PROCESS_TREE_NODE] = _this13.getProcessNodes();
        }
      });
      return Promise.all(Object.values(promises));
    },
    handleMapResults: function handleMapResults(nodes) {
      var _this14 = this;
      var rows = [];
      nodes.forEach(function (node) {
        var _node$questionnaire, _node$gap_schema;
        var organisations = [],
          responsibleUsers = [],
          accountableUsers = [],
          consultedUsers = [],
          informedUsers = [],
          controllers = [],
          systems = [],
          actions = [],
          contracts = [],
          controls = [],
          informationAssets = {},
          processors = [],
          incidents = [],
          processNodes = [],
          risks = [],
          tags = filterAsset(_this14.tags, node.tagIds);
        if (node.organisationIds) {
          organisations = filterAsset(_this14.organisations, node.organisationIds);
        }
        if (node.responsibleUserIds) {
          responsibleUsers = filterAsset(_this14.users, node.responsibleUserIds);
        }
        if (node.accountableUserIds) {
          accountableUsers = filterAsset(_this14.users, node.accountableUserIds);
        }
        if (node.consultedUserIds) {
          consultedUsers = filterAsset(_this14.users, node.consultedUserIds);
        }
        if (node.informedUserIds) {
          informedUsers = filterAsset(_this14.users, node.informedUserIds);
        }
        if (node.links && node.links[MODULES.COMPLIANCE_IA_CONTROLLER]) {
          controllers = filterAsset(_this14.controllers, node.links[MODULES.COMPLIANCE_IA_CONTROLLER]);
        }
        if (node.links && node.links.dataprocessor_system) {
          systems = filterAsset(_this14.systems, node.links.dataprocessor_system);
        }
        if (node.links && node.links[MODULES.EXECUTION]) {
          actions = filterAsset(_this14.actions, node.links[MODULES.EXECUTION]);
        }
        if (node.links && node.links[MODULES.CONTRACTS]) {
          contracts = filterAsset(_this14.contracts, node.links[MODULES.CONTRACTS]);
        }
        if (node.links && node.links[MODULES.CONTROLS]) {
          controls = filterAsset(_this14.controls, node.links[MODULES.CONTROLS]);
        }
        if (node.links && node.links[MODULES.COMPLIANCE_INFORMATION_ASSETS]) {
          informationAssets = _this14.getFormattedData(_this14.informationAssets, node.links, node.id, MODULES.COMPLIANCE_INFORMATION_ASSETS);
        }
        if (node.links && node.links[MODULES.COMPLIANCE_IA_PROCESSOR]) {
          processors = filterAsset(_this14.processors, node.links[MODULES.COMPLIANCE_IA_PROCESSOR]);
        }
        if (node.links && node.links[MODULES.INCIDENTS]) {
          incidents = filterAsset(_this14.incidents, node.links[MODULES.INCIDENTS]);
        }
        if (node.links && node.links[MODULES.COMPLIANCE_PROCESS_TREE_NODE]) {
          processNodes = filterAsset(_this14.processNodes, node.links[MODULES.COMPLIANCE_PROCESS_TREE_NODE]);
        }
        if (node.links && node.links[MODULES.RISK]) {
          risks = _this14.getFormattedData(_this14.risks, node.links, node.id, MODULES.RISK);
        }
        var obj = _this14.getSettingValue('feature.show_node_id') ? {
          id: {
            field: node.id
          }
        } : {};
        var activityType = _this14.activityTypes.find(function (item) {
          return node.activityTypeId === item.id;
        });
        var purposeEnabled = activityType ? activityType.optionalFields.includes('process_purpose') : true;
        var processStatusEnabled = activityType ? activityType.optionalFields.includes('process_status') : true;
        var customFields = _objectSpread(_objectSpread({}, _this14.customFieldsDefault), _this14.customFieldsById[node.id]);
        obj = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, obj), {}, {
          process: {
            node: buildHierarchicalStructure(node),
            fieldText: getHierarchicalList(node)
          },
          description: {
            field: node.description || ''
          },
          purpose: {
            field: node.purpose && purposeEnabled ? node.purpose : ''
          },
          processStatus: {
            field: node.status && processStatusEnabled ? node.status : ''
          },
          questionnaire: {
            field: (_node$questionnaire = node.questionnaire) === null || _node$questionnaire === void 0 ? void 0 : _node$questionnaire.title
          },
          questionnaireAnsweredPercentage: {
            field: node.questionnaireAnsweredPercentage || ''
          },
          gap_schema: {
            field: (_node$gap_schema = node.gap_schema) === null || _node$gap_schema === void 0 ? void 0 : _node$gap_schema.title
          },
          activityType: {
            field: activityType ? activityType.label : ''
          },
          responsible: {
            field: mapToCommaList(responsibleUsers, 'display_name')
          },
          accountable: {
            field: mapToCommaList(accountableUsers, 'display_name')
          },
          consulted: {
            field: mapToCommaList(consultedUsers, 'display_name')
          },
          informed: {
            field: mapToCommaList(informedUsers, 'display_name')
          },
          controllers: _this14.getActivitiesForPreview(controllers, obj.id, 'name'),
          organisations: {
            field: mapToCommaList(organisations, 'visible_name')
          },
          systems: _this14.getActivitiesForPreview(systems, obj.id, 'name'),
          actions: _this14.getActivitiesForPreview(actions, obj.id),
          contracts: _this14.getActivitiesForPreview(contracts, obj.id),
          controls: _this14.getActivitiesForPreview(controls, obj.id)
        }, informationAssets), {}, {
          processors: _this14.getActivitiesForPreview(processors, obj.id),
          incidents: _this14.getActivitiesForPreview(incidents, obj.id),
          processNodes: _this14.getActivitiesForPreview(processNodes, obj.id, 'title', {
            processTreeId: _this14.processTreeId
          })
        }, risks), customFields);
        obj.dataflow = {
          field: _this14.hasDataFlow(node.id, _this14.dataflowReport) ? _this14.translate.yes : _this14.translate.no
        };
        obj.attachedFiles = {
          field: mapToCommaListWithLinks(node.attachments, 'url', 'filename', 'id', false),
          fieldText: mapToCommaList(node.attachments, 'filename')
        };
        obj.tags = {
          field: mapToCommaList(tags, 'tag')
        };
        if (!_this14.showActivityType) {
          delete obj.activityType;
        }
        if (_this14.getSettingValue('feature.questionnaire_scoring') && _this14.scoringIncludedInDatatable) {
          if (node.questionnaireScore) {
            obj.score = {
              field: node.questionnaireScore.totalScore
            };
            obj.scoreHelp = {
              field: "<span style=\"color: ".concat(node.questionnaireScore.helpText.color, "\">").concat(node.questionnaireScore.helpText.assessmentText, "</span>"),
              fieldText: node.questionnaireScore.helpText.assessmentText
            };
          } else {
            obj.score = {
              field: ''
            };
            obj.scoreHelp = {
              field: ''
            };
          }
        }
        if (_this14.getSettingValue('feature.risk_assessment_on_systems')) {
          obj.rto = {
            field: _this14.getRtoValue(node)
          };
          obj.rpo = {
            field: _this14.getRpoValue(node)
          };
        }
        rows.push(obj);
      });
      return rows;
    },
    getCustomFieldValueId: function getCustomFieldValueId(data, fieldId) {
      if (data.customFieldValues) {
        var valueObject = data.customFieldValues.find(function (f) {
          return +f.fieldId === +fieldId;
        });
        if (valueObject) return valueObject.value;
      }
      return null;
    },
    getCustomFieldValue: function getCustomFieldValue(data, fieldId) {
      var valueId = this.getCustomFieldValueId(data, fieldId);
      if (valueId === null) {
        return '';
      }
      var _ref12 = data.customFields.find(function (item) {
          return +item.id === +fieldId;
        }) || {},
        _ref12$options = _ref12.options,
        options = _ref12$options === void 0 ? {} : _ref12$options;
      var option = Object.values(options).find(function (option) {
        return +option.id === +valueId;
      });
      return (option === null || option === void 0 ? void 0 : option.value) || '';
    },
    getRtoValue: function getRtoValue(data) {
      return this.getCustomFieldValue(data, this.rtoFieldId);
    },
    getRpoValue: function getRpoValue(data) {
      return this.getCustomFieldValue(data, this.rpoFieldId);
    },
    getFormattedData: function getFormattedData(items, links, objId, module) {
      var _this15 = this;
      var result = {};
      items.forEach(function (item) {
        var activities = filterAsset(item.activities, links[module]);
        var column = "".concat(module, "-").concat(item.id);
        result[column] = _this15.getActivitiesForPreview(activities, objId);
      });
      return result;
    },
    getActivitiesForPreview: function getActivitiesForPreview(activities, objId) {
      var field = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'title';
      var addToActivity = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var fieldRow = {
        activities: activities.map(function (activity) {
          return {
            activity: _objectSpread(_objectSpread({}, activity), addToActivity),
            fieldText: activity[field]
          };
        }),
        fieldText: mapToCommaList(activities, field),
        id: objId
      };
      return _objectSpread({
        field: fieldRow
      }, fieldRow);
    },
    hasDataFlow: function hasDataFlow(id, report) {
      if (report.length === 0) {
        return false;
      }
      var filtered = report.filter(function (item) {
        return item.id == id;
      });
      if (filtered.length && filtered[0].dataFlows.length) {
        return true;
      }
      return false;
    },
    handleClearQuery: function handleClearQuery() {
      this.queryInput = '';
      this.noResults = false;
      this.searchArgs = {};
      this.resetSavedReportQuery();
    },
    buildQuery: function buildQuery(args) {
      var query = '';
      if (args.query.length > 0) {
        query = query + 'title=' + args.query + ':';
      }
      if (args.organisations.length > 0) {
        query = query + 'organisation=' + this.convertArrayToString(args.organisations) + ':';
      }
      if (args.users.length > 0) {
        query = query + 'owner=' + this.convertArrayToString(args.users) + ':';
      }
      if (args.tags.length) {
        query += "tag_id=".concat(args.tags.join(','), ":");
      }
      query = query.replace(/'/g, '\\\'');
      return query.substr(0, query.length - 1);
    },
    convertArrayToString: function convertArrayToString(array) {
      return array.join();
    },
    setDataFlowReport: function setDataFlowReport(response) {
      this.dataflowReport = response.list;
    },
    sortNumerically: function sortNumerically(data, key) {
      return data.sort(function (a, b) {
        return a[key].field - b[key].field;
      });
    },
    onDismissModal: function onDismissModal() {
      if (this.updatedNode) {
        this.refreshTable();
        this.updatedNode = null;
      }
    },
    refreshTable: function refreshTable() {
      var _this16 = this;
      this.getData().then(function () {
        return _this16.handleDoSearch(_this16.searchArgs);
      });
    },
    prepareCustomFieldOptions: function prepareCustomFieldOptions(nodes) {
      var _this$getPropertiesWi = this.getPropertiesWithCustomFields(nodes, this.activityTypes),
        extendedColumns = _this$getPropertiesWi.extendedColumns,
        defaultCustomFields = _this$getPropertiesWi.defaultCustomFields,
        customFieldsValuesByEntity = _this$getPropertiesWi.customFieldsValuesByEntity,
        columnFilterOptions = _this$getPropertiesWi.columnFilterOptions;
      this.customFieldsColumns = extendedColumns;
      this.customFieldsDefault = defaultCustomFields;
      this.customFieldsById = customFieldsValuesByEntity;
      this.enhanceColumnFilters(columnFilterOptions, this.disabledFilterByColumns, this.decoupleFilterFields);
    },
    generateDataset: function generateDataset(allNodes, filterType) {
      var _this17 = this;
      var nodes = _toConsumableArray(allNodes);
      if (filterType > 0 && +this.projectId === ProjectTypes.GDPR) {
        nodes = nodes.filter(function (item) {
          return _this17.liaTiaNodes.find(function (node) {
            return node.id === item.id;
          });
        });
      }
      this.prepareCustomFieldOptions(nodes);
      this.dataset = this.handleMapResults(nodes);
      this.enhanceDataset(this.dataset, this.columns);
      this.linkedColumnsForPreview.forEach(function (previewColumnKey) {
        _this17.decoupleFilterFields.push({
          key: previewColumnKey
        });
      });
    },
    setAndLoadSavedReport: function setAndLoadSavedReport(savedReportOptions) {
      var _this18 = this;
      this.initializeFiltersAndQueryInput(savedReportOptions);
      return this.handleDoSearch(savedReportOptions, false).then(function () {
        _this18.dataset.length && _this18.loadSavedReport();
      });
    },
    initializeFiltersAndQueryInput: function initializeFiltersAndQueryInput(_ref13) {
      var _ref13$query = _ref13.query,
        query = _ref13$query === void 0 ? '' : _ref13$query,
        filterType = _ref13.filterType,
        filters = _objectWithoutProperties(_ref13, _excluded);
      this.initialFilters = _objectSpread(_objectSpread(_objectSpread({}, this.initialFilters), filters), {}, {
        selectedRadio: filterType
      });
      this.queryInput = query;
    }
  }
};