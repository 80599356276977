var _CONTROL_ACTIONS_MESS;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var ControlActions = {
  complete: 'complete',
  review: 'review',
  reject: 'reject',
  skip: 'skip'
};
export var CONTROL_ACTIONS_MESSAGES = (_CONTROL_ACTIONS_MESS = {}, _defineProperty(_CONTROL_ACTIONS_MESS, ControlActions.complete, 'The control was successfully completed'), _defineProperty(_CONTROL_ACTIONS_MESS, ControlActions.skip, 'The control was successfully skipped'), _defineProperty(_CONTROL_ACTIONS_MESS, ControlActions.review, 'The control was successfully reviewed'), _defineProperty(_CONTROL_ACTIONS_MESS, ControlActions.reject, 'The control was successfully rejected'), _CONTROL_ACTIONS_MESS);