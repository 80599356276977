function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import InputField from '@/components/Atoms/Inputs/InputField';
import Modal from '@/components/Molecules/Modal/Modal';
export default {
  name: 'DocumentTypeModal',
  description: 'Document Type Modal page',
  components: {
    InputField: InputField,
    Modal: Modal
  },
  props: {
    documentType: {
      type: Object,
      require: false,
      default: function _default() {
        return {};
      }
    },
    allDocumentTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['dismiss', 'accept'],
  data: function data() {
    return {
      title: '',
      isTitleValid: true,
      translate: getTranslate['DocumentTypeModal']()
    };
  },
  computed: {
    creatingMode: function creatingMode() {
      var _this$documentType;
      return !((_this$documentType = this.documentType) !== null && _this$documentType !== void 0 && _this$documentType.id);
    },
    header: function header() {
      return this.creatingMode ? this.translate.addDocumentType : this.translate.updateDocumentType;
    },
    okButtonText: function okButtonText() {
      return this.creatingMode ? this.translate.create : this.translate.save;
    },
    existingNames: function existingNames() {
      return this.allDocumentTypes.map(function (type) {
        return type.name;
      });
    },
    nameExists: function nameExists() {
      return this.existingNames.includes(this.title);
    },
    titleError: function titleError() {
      if (!this.title) return this.translate.titleCannotBeEmpty;
      if (this.nameExists) return this.translate.theDocumentTypeWithThisNameAlreadyExists;
      return '';
    }
  },
  mounted: function mounted() {
    var _this$documentType2;
    this.title = ((_this$documentType2 = this.documentType) === null || _this$documentType2 === void 0 ? void 0 : _this$documentType2.name) || '';
  },
  methods: {
    acceptModal: function acceptModal() {
      if (!this.titleError) {
        this.$emit('accept', _objectSpread(_objectSpread({}, this.documentType), {}, {
          name: this.title
        }));
      } else {
        this.isTitleValid = false;
      }
    }
  }
};