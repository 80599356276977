function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { getDocumentTypesAll } from '@/api/admin/documentTypes';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { toLocalDateTimeFull } from '@/utils/date';
import { SortDirection } from '@/constants/SortDirection';
import { compareDates } from '@/utils/compare';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
export default {
  name: 'ContractDocuments',
  description: 'This is the Page component for /contracts/contract/:id/documents',
  token: '<contract-documents data="" />',
  components: {
    FeatherIcon: FeatherIcon,
    PreviewList: PreviewList,
    RismaTitle: RismaTitle,
    RismaDatatable: RismaDatatable
  },
  mixins: [RismaDatatableDefaultMixin],
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Contract object'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['triggerAttachmentMethod'],
  data: function data() {
    return {
      documentTypes: [],
      disabledFilterByColumns: ['date', 'pinned', 'showVersions', 'newVersion', 'edit', 'download', 'delete'],
      disabledSortByColumns: ['pinned', 'showVersions', 'newVersion', 'edit', 'download', 'delete'],
      defaultOrder: [{
        index: 'date',
        dir: SortDirection.DESC
      }],
      columnsMeta: [{
        targets: ['pinned', 'showVersions', 'newVersion', 'edit', 'delete', 'download'],
        width: '25px'
      }, {
        targets: ['docTitle'],
        width: '250px'
      }],
      translate: getTranslate['ContractDocuments']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), {}, {
    documents: function documents() {
      return this.data.files.filter(function (attachment) {
        return attachment.mimeType;
      });
    },
    documentsByDate: function documentsByDate() {
      return _toConsumableArray(this.documents).sort(function (a, b) {
        return compareDates(a.created, b.created);
      });
    },
    columns: function columns() {
      return {
        docTitle: this.translate.title,
        uploader: this.translate.uploaderName,
        date: this.translate.uploadDate,
        type: this.translate.type,
        version: this.translate.versions,
        pinned: '',
        showVersions: '',
        newVersion: '',
        edit: '',
        download: '',
        delete: ''
      };
    },
    dataset: function dataset() {
      var _this = this;
      return this.documentsByDate.map(function (document) {
        var typeFieldTextString = '';
        var typeString = '';
        _this.documentTypes.forEach(function (docType) {
          if (document.docTypeIds.includes(docType.id)) {
            typeFieldTextString += docType.name + ', ';
            typeString += "<span class=\"whitespace-pre-line\">".concat(docType.name, ",</span> ");
          }
        });
        if (typeString) {
          var lastTagRegEx = /,<\/span>\s$/gmi;
          typeString = typeString.replace(lastTagRegEx, '</span>');
        } else {
          typeString = _this.translate.notSpecified;
          typeFieldTextString = _this.translate.notSpecified;
        }
        return {
          docTitle: {
            title: document.title || '',
            filename: document.filename,
            url: document.url,
            fieldText: document.title
          },
          uploader: {
            field: _this.getUserById(document.owner)
          },
          date: {
            field: toLocalDateTimeFull(document.created)
          },
          type: {
            field: typeString,
            fieldText: typeFieldTextString
          },
          version: {
            field: document.version
          },
          pinned: {
            data: document
          },
          showVersions: {
            data: document.id
          },
          newVersion: {
            data: document.id
          },
          edit: {
            data: document
          },
          download: {
            data: document
          },
          delete: {
            data: document
          }
        };
      });
    }
  }),
  watch: {
    dataset: function dataset() {
      this.enhanceDataset(this.dataset, this.columns);
    }
  },
  mounted: function mounted() {
    this.enhanceDataset(this.dataset, this.columns);
    this.getDocumentTypes();
  },
  methods: {
    getDocumentTypes: function getDocumentTypes() {
      var _this2 = this;
      return getDocumentTypesAll().then(function (_ref) {
        var list = _ref.list;
        _this2.documentTypes = list.map(function (item) {
          return _objectSpread(_objectSpread({}, item), {}, {
            name: _this2.$trans(item.name)
          });
        });
      });
    },
    toggleCreateModal: function toggleCreateModal() {
      if (this.readOnly) {
        return;
      }
      this.$emit('triggerAttachmentMethod', 'toggleModal');
    },
    deleteFile: function deleteFile(id) {
      this.$emit('triggerAttachmentMethod', 'openConfirmModal', id);
    },
    showVersions: function showVersions(fileId) {
      this.$emit('triggerAttachmentMethod', 'onShowVersions', fileId);
    },
    addNewVersion: function addNewVersion(fileId) {
      this.$emit('triggerAttachmentMethod', 'onCreateNewVersion', fileId);
    },
    onEditFile: function onEditFile(file) {
      this.$emit('triggerAttachmentMethod', 'openEditModal', file);
    },
    togglePin: function togglePin(id) {
      this.$emit('triggerAttachmentMethod', 'togglePin', id);
    },
    getUserById: function getUserById(id) {
      var _this$users$find;
      return ((_this$users$find = this.users.find(function (user) {
        return user.id == id;
      })) === null || _this$users$find === void 0 ? void 0 : _this$users$find.display_name) || '';
    }
  }
};