import { getTranslate } from './translate';
import * as formatDate from '@/utils/date';
import { Colors } from '@/Colors';
import { getIncidentStateName } from '@/utils/incident';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
export default {
  name: 'PreviewIncident',
  components: {
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight
  },
  props: {
    incident: {
      type: Object,
      required: true,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      translate: getTranslate['PreviewIncident']()
    };
  },
  computed: {
    status: function status() {
      return getIncidentStateName(this.incident.rismaState);
    },
    severity: function severity() {
      return Colors.findIncidentSeverity(this.incident.severity, true);
    },
    createdWithLabel: function createdWithLabel() {
      return "".concat(this.translate.created, ": ").concat(formatDate.toLocalDateTime(this.incident.createdAt));
    },
    occurredWithLabel: function occurredWithLabel() {
      return "".concat(this.translate.incidentOccurred, ": ").concat(formatDate.toLocalDateTime(this.incident.incidentHappenedAt) || this.translate.notSet);
    },
    archivedWithLabel: function archivedWithLabel() {
      if (!this.incident.archived) return null;
      return "".concat(this.translate.incidentArchived, ": ").concat(formatDate.toLocalDateTime(this.incident.updatedAt));
    }
  }
};