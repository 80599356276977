import * as processTreeApi from '@/api/compliance/processtree';
import * as DataAPI from '@/api/risma/data';
export function getProcessTreeNodesForSelectBox() {
  return processTreeApi.getAllTrees().then(function (response) {
    if (!response || response[0].id === null) {
      return [];
    }
    return processTreeApi.getNodesByProcessTreeId(response[0].id).then(function (rootNode) {
      return pushChildrenToOptions(rootNode);
    });
  });
}
export function getProcessTreeNodesForSelectBoxFromDataApi() {
  return DataAPI.getAllTrees().then(function (response) {
    if (!response || response[0].id === null) {
      return [];
    }
    return DataAPI.getNodesByProcessTreeId(response[0].id).then(function (rootNode) {
      return pushChildrenToOptions(rootNode);
    });
  });
}
function pushChildrenToOptions(rootNode) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  if (!rootNode.children) return [];
  rootNode.children.forEach(function (child) {
    options.push(child);
    if (child.children) {
      pushChildrenToOptions(child, options);
    }
  });
  return options;
}