function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useSolutionsStore } from '@/Store/solutionsStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { getNodesFlat, getInitiatives as _getInitiatives, getControls as _getControls, getRisks, getInformationAssets } from '@/api/risma/data';
import { getAll as _getAllComplianceProjects } from '@/api/compliance/projects';
import { getAllQuestionnaires } from '@/api/compliance/questionnaire';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import { getAllGapSchemasByGapSchemaType } from '@/api/compliance/gapschema';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { ExtendedViewCustomFieldsMixin } from '@/components/Pages/InformationAssets/ExtendedViewCustomFieldsMixin';
import { generateFiltersQueryString } from '@/api/httpUtils';
import { getProcessTreeNodesForSelectBoxFromDataApi } from '@/utils/format/ProcessTree';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { mapToCommaListWithLinks, mapToCommaList } from '@/utils/format/mapTo';
import * as utils from '@/utils/Utils';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { vatStatusLabelByValue } from '@/constants/companies/vatStatuses';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export default {
  name: 'InformationAssetsExtendedView',
  components: {
    RismaDatatable: RismaDatatable,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Trafficlight: Trafficlight,
    PreviewModalLink: PreviewModalLink
  },
  mixins: [RaciTitleMixin, RismaDatatableDefaultMixin, ExtendedViewCustomFieldsMixin],
  props: {
    typeId: {
      type: [Number, String],
      required: false,
      default: null,
      note: 'Id of information asset type'
    },
    informationAssets: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of information assets'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of all users'
    },
    confirmExportMessage: {
      required: false,
      type: String,
      default: '',
      note: 'Confirmation popup message to display before exporting to excel or print'
    },
    confirmPrintMessage: {
      required: false,
      type: String,
      default: '',
      note: 'Confirmation popup message to display before printing'
    }
  },
  emits: ['refreshData'],
  data: function data() {
    return {
      dataset: [],
      columns: {},
      isLoaded: false,
      nodes: [],
      initiatives: [],
      controls: [],
      processes: [],
      questionnaires: [],
      allInformationAssets: [],
      companies: [],
      updatedFromPreviewModal: false,
      hasBackendSorting: true,
      //RismaDatatableDefaultMixin backend sort
      firstInit: true,
      //RismaDatatableDefaultMixin backend sort
      disabledFilterByColumnsDefault: ['linkedNodes', 'systems', 'controls', 'initiatives', 'attachments', 'children'],
      complianceProjects: [],
      usePagination: false,
      gapSchemas: [],
      translate: getTranslate['InformationAssetsExtendedView']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useActivityTypesStore, ['getActivityTypeById'])), mapState(useSolutionsStore, {
    solutions: 'enabledSolutions'
  })), mapState(useTagsStore, ['tags'])), {}, {
    columnsMeta: function columnsMeta() {
      var targets = Object.keys(this.columns);
      return [{
        targets: targets,
        width: '170px'
      }, {
        targets: ['name', 'description', 'initiatives', 'controls', 'risks'],
        width: '340px'
      }, {
        targets: ['linkedNodes'],
        width: '500px'
      }];
    },
    disabledFilterByColumns: function disabledFilterByColumns() {
      var list = this.disabledFilterByColumnsDefault.slice();
      Object.keys(this.customFieldsColumns).forEach(function (item) {
        list.push(item);
      });
      if (this.typeId === AssetsTypes.SYSTEMS) {
        var complianceLinkColumns = this.getComplianceProjectColumns();
        Object.keys(complianceLinkColumns).forEach(function (column) {
          list.push(column);
        });
      }
      return list;
    },
    systemsEnabled: function systemsEnabled() {
      return this.getSettingValue('feature.enable_systems') == undefined || this.getSettingValue('feature.enable_systems');
    },
    showRiskAssessment: function showRiskAssessment() {
      return !this.getSettingValue('feature.hide_ia_risk_assessments');
    },
    isDataProcessorManagementEnabled: function isDataProcessorManagementEnabled() {
      return !!this.getSettingValue('feature.data_processor_management');
    },
    commonColumnsForAll: function commonColumnsForAll() {
      return {
        activityType: this.translate.activityType,
        solutionId: this.translate.solutionId,
        questionnaireId: this.translate.questionnaire,
        completionDegree: this.translate.completionDegreeIn,
        questionnaireScoring: this.translate.questionnaireScoring,
        assessmentScoring: this.translate.assessmentScoring,
        tags: this.translate.tags,
        responsible: this.responsibleTitle(true),
        accountable: this.accountableTitle(true),
        consulted: this.consultedTitle(true),
        informed: this.informedTitle(true),
        organisations: this.translate.organisations
      };
    }
  }),
  watch: {
    informationAssets: function informationAssets() {
      this.getExtendedData();
    }
  },
  mounted: function mounted() {
    window.$router = this.$router; //jquery access to router, didn`t find other way
    this.getData(this.typeId);
  },
  methods: {
    getData: function getData() {
      var _this = this;
      return Promise.all([this.getAllInformationAssets(), this.getNodes(), this.getInitiatives(), this.getControls(), this.getRisk(), this.getProcessLibrary(), this.getAllComplianceProjects(), this.getGapProcSchemas(), this.getQuestionnaires()]).then(function () {
        _this.getExtendedData();
      });
    },
    getAllComplianceProjects: function getAllComplianceProjects() {
      var _this2 = this;
      return _getAllComplianceProjects().then(function (_ref) {
        var list = _ref.list;
        return _this2.complianceProjects = list;
      });
    },
    getAllInformationAssets: function getAllInformationAssets() {
      var _this3 = this;
      return getInformationAssets().then(function (_ref2) {
        var list = _ref2.list;
        return _this3.allInformationAssets = list;
      });
    },
    getNodes: function getNodes() {
      var _this4 = this;
      return getNodesFlat().then(function (_ref3) {
        var list = _ref3.list;
        return _this4.nodes = list;
      });
    },
    getInitiatives: function getInitiatives() {
      var _this5 = this;
      return _getInitiatives().then(function (_ref4) {
        var list = _ref4.list;
        return _this5.initiatives = list;
      });
    },
    getControls: function getControls() {
      var _this6 = this;
      return _getControls().then(function (_ref5) {
        var list = _ref5.list;
        return _this6.controls = list;
      });
    },
    getRisk: function getRisk() {
      var _this7 = this;
      return getRisks().then(function (_ref6) {
        var list = _ref6.list;
        return _this7.risks = list;
      });
    },
    getProcessLibrary: function getProcessLibrary() {
      var _this8 = this;
      return getProcessTreeNodesForSelectBoxFromDataApi().then(function (response) {
        return _this8.processes = response;
      });
    },
    getGapProcSchemas: function getGapProcSchemas() {
      var _this9 = this;
      return getAllGapSchemasByGapSchemaType(ProjectTypes.DATAPROCESSOR).then(function (_ref7) {
        var list = _ref7.list;
        return _this9.gapSchemas = list;
      });
    },
    getQuestionnaires: function getQuestionnaires() {
      var _this10 = this;
      return getAllQuestionnaires().then(function (_ref8) {
        var list = _ref8.list;
        return _this10.questionnaires = list;
      });
    },
    getCompanies: function getCompanies() {
      var _this11 = this;
      var filteredIds = this.informationAssets.map(function (ia) {
        return ia.companyId;
      }).filter(Boolean);
      if (!filteredIds.length) {
        this.companies = [];
        return Promise.resolve();
      }
      var query = generateFiltersQueryString({
        id: filteredIds
      });
      return _getCompanies(query).then(function (_ref9) {
        var list = _ref9.list;
        return _this11.companies = list;
      });
    },
    getExtendedData: function getExtendedData() {
      var _this12 = this;
      this.isLoaded = false;
      return this.getCompanies().then(function () {
        _this12.columns = _this12.getColumns();
        _this12.dataset = _this12.getDataset(_this12.informationAssets, _this12.typeId);
        _this12.enhanceDataset(_this12.dataset, _this12.columns);
        _this12.isLoaded = true;
      });
    },
    getColumns: function getColumns() {
      switch (this.typeId) {
        case AssetsTypes.CONTROLLERS:
          return this.getControllersColumns();
        case AssetsTypes.PROCESSORS:
          return this.getProcessorsColumns();
        case AssetsTypes.SYSTEMS:
          return this.getSystemsColumns();
      }
      return this.getActivityTypesColumns();
    },
    getCommonColumns: function getCommonColumns() {
      return _objectSpread(_objectSpread({
        uniqueId: 'ID',
        name: this.translate.name,
        address: this.translate.address,
        countryCode: this.translate.countryCode,
        phoneNumber: this.translate.phoneNumber,
        vatNo: this.translate.vatNumber,
        regNo: this.translate.regNumber,
        email: this.translate.email
      }, this.commonColumnsForAll), {}, {
        linkedNodes: this.translate.nodesLinkedThroughQuestionnaire,
        description: this.translate.description
      });
    },
    getRiskAssessmentsColumns: function getRiskAssessmentsColumns() {
      return {
        before_likelihood: "".concat(this.translate.likelihood, " (").concat(this.translate.before, ")"),
        before_impact: "".concat(this.translate.impact, " (").concat(this.translate.before, ")"),
        before_rpi: "".concat(this.translate.rpi, " (").concat(this.translate.before, ")"),
        after_likelihood: "".concat(this.translate.likelihood, " (").concat(this.translate.after, ")"),
        after_impact: "".concat(this.translate.impact, " (").concat(this.translate.after, ")"),
        after_rpi: "".concat(this.translate.rpi, " (").concat(this.translate.after, ")")
      };
    },
    getControllersColumns: function getControllersColumns() {
      var riskAssessmentsColumns = this.showRiskAssessment ? this.getRiskAssessmentsColumns() : {};
      return _objectSpread(_objectSpread(_objectSpread({
        trafficLight: this.translate.trafficLight
      }, this.getCommonColumns()), riskAssessmentsColumns), this.customFieldsColumns);
    },
    getProcessorsColumns: function getProcessorsColumns() {
      var linkedSystemsColumn = this.systemsEnabled ? {
        systems: this.translate.linkedSystems
      } : {};
      var riskAssessmentsColumns = this.showRiskAssessment ? this.getRiskAssessmentsColumns() : {};
      return _objectSpread(_objectSpread(_objectSpread(_objectSpread({
        trafficLight: this.translate.trafficLight
      }, this.getCommonColumns()), riskAssessmentsColumns), {}, {
        questionnaireId: this.translate.questionnaire,
        processes: this.translate.processLibrary
      }, linkedSystemsColumn), {}, {
        gapSchema: this.translate.gapSchema,
        controls: this.translate.linkedControls,
        initiatives: this.translate.linkedActions,
        children: this.translate.underlyingProcessors,
        attachments: this.translate.attachments,
        companyName: this.translate.companyName,
        vatStatus: this.translate.vatStatus,
        zipCode: this.translate.zipcode,
        city: this.translate.city,
        contactName: this.translate.contactName
      }, this.customFieldsColumns);
    },
    getSystemsColumns: function getSystemsColumns() {
      var riskAssessmentsColumns = this.showRiskAssessment ? this.getRiskAssessmentsColumns() : {};
      return _objectSpread(_objectSpread(_objectSpread(_objectSpread({
        trafficLight: this.translate.trafficLight,
        uniqueId: 'ID',
        name: this.translate.name,
        children: this.translate.underlyingSystems,
        location: this.translate.location,
        description: this.translate.description,
        purpose: this.translate.purpose,
        linkedNodes: this.translate.nodesLinkedThroughQuestionnaire
      }, riskAssessmentsColumns), {}, {
        email: this.translate.email,
        processors: this.translate.linkedProcessors,
        processes: this.translate.processLibrary,
        risks: this.translate.risks,
        initiatives: this.translate.linkedActions,
        controls: this.translate.linkedControls
      }, this.commonColumnsForAll), this.getComplianceProjectColumns()), {}, {
        attachments: this.translate.attachments
      }, this.customFieldsColumns);
    },
    getComplianceProjectColumns: function getComplianceProjectColumns() {
      var _this13 = this;
      var result = {};
      this.complianceProjects.forEach(function (project) {
        var label = _this13.getCompliancePropLabel(project.id);
        result[label] = "".concat(project.title, " links");
      });
      return result;
    },
    getActivityTypesColumns: function getActivityTypesColumns() {
      return _objectSpread(_objectSpread({
        trafficLight: this.translate.trafficLight,
        name: this.translate.name,
        description: this.translate.description,
        address: this.translate.address,
        postcode: this.translate.zipcode,
        city: this.translate.city,
        email: this.translate.email
      }, this.customFieldsColumns), this.commonColumnsForAll);
    },
    getDataset: function getDataset(informationAssets, type) {
      var _this14 = this;
      var dataset = [];
      informationAssets.forEach(function (ia) {
        var item = _this14.getInformationAssetData(type, ia);
        if (item) dataset.push(item);
      });
      return dataset;
    },
    getInformationAssetData: function getInformationAssetData(objType, element) {
      switch (objType) {
        case AssetsTypes.CONTROLLERS:
          return this.getControllersData(element);
        case AssetsTypes.PROCESSORS:
          if (element.parents && element.parents.length > 0) {
            return null;
          }
          return this.getProcessorsData(element);
        case AssetsTypes.SYSTEMS:
          return this.getSystemsData(element);
      }
      return this.getActivityTypesData(element);
    },
    getCommonData: function getCommonData(element) {
      var _element$activityType, _this$getActivityType;
      var company = this.companies.find(function (company) {
        return company.id === element.companyId;
      }) || {};
      var data = {
        uniqueId: {
          field: element.uniqueId
        },
        name: this.getName(element),
        address: {
          field: company.address || element.address
        },
        countryCode: {
          field: company.countryCode || element.countryCode
        },
        phoneNumber: {
          field: company.phone || element.phoneNumber
        },
        vatNo: {
          field: company.vatNo || element.vatNo
        },
        regNo: {
          field: element.regNo
        },
        email: {
          field: company.email || element.email
        }
      };
      if (this.typeId === AssetsTypes.PROCESSORS) {
        data.companyName = {
          field: company.name || ''
        };
        data.vatStatus = {
          field: vatStatusLabelByValue[company.vatStatus] || ''
        };
        data.zipCode = {
          field: company.zipCode || ''
        };
        data.city = {
          field: company.city || ''
        };
        data.contactName = {
          field: element.contactName || ''
        };
      }
      return _objectSpread(_objectSpread({}, data), {}, {
        activityType: {
          field: ((_element$activityType = element.activityType) === null || _element$activityType === void 0 ? void 0 : _element$activityType.label) || ((_this$getActivityType = this.getActivityTypeById(element.activityTypeId)) === null || _this$getActivityType === void 0 ? void 0 : _this$getActivityType.label) || ''
        },
        linkedNodes: {
          field: mapToCommaListWithLinks(this.filterItems(this.nodes, element.complianceNodes || []), '', 'title', 'url', false)
        },
        description: {
          field: element.description || ''
        }
      });
    },
    getControllersData: function getControllersData(element) {
      var riskAssessmentsData = this.showRiskAssessment ? this.extractRiskAssessments(element.riskAssessments) : {};
      return _objectSpread(_objectSpread(_objectSpread(_objectSpread({
        trafficLight: {
          field: DatatableRenderer.prepareTrafficLightField(element.trafficLight, true),
          fieldText: element.trafficLight
        }
      }, this.getCommonData(element)), {}, {
        tags: {
          field: utils.findPropsByIds(element.tagIds, this.tags, 'tag')
        }
      }, riskAssessmentsData), this.getCustomFieldsData(element)), this.getCommonFieldsData(element));
    },
    getProcessorsData: function getProcessorsData(element) {
      var _this$gapSchemas$find, _element$links, _element$links2, _element$links3;
      var riskAssessmentsData = this.showRiskAssessment ? this.extractRiskAssessments(element.riskAssessments) : {};
      var gapSchemaData = this.isDataProcessorManagementEnabled ? {
        gapSchema: {
          field: ((_this$gapSchemas$find = this.gapSchemas.find(function (schema) {
            return schema.id === element.gapSchemaId;
          })) === null || _this$gapSchemas$find === void 0 ? void 0 : _this$gapSchemas$find.title) || ''
        }
      } : {};
      var systemsData = this.systemsEnabled ? {
        systems: {
          field: mapToCommaListWithLinks(this.filterInformationAssets(element.systems, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM), '/compliance/informationassets/systems/', 'name', 'id', false)
        }
      } : {};
      return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
        trafficLight: {
          field: DatatableRenderer.prepareTrafficLightField(element.trafficLight, true),
          fieldText: element.trafficLight
        }
      }, this.getCommonData(element)), {}, {
        name: {
          fieldText: element.name,
          field: {
            activity: getFieldsFromObjectForPreview(element),
            title: element.name
          }
        }
      }, gapSchemaData), riskAssessmentsData), {}, {
        processes: {
          field: mapToCommaListWithLinks(this.filterItems(this.processes, ((_element$links = element.links) === null || _element$links === void 0 ? void 0 : _element$links.compliance_process_tree_node) || []), '', 'title', 'url', false)
        },
        tags: {
          field: utils.findPropsByIds(element.tagIds, this.tags, 'tag')
        }
      }, systemsData), {}, {
        controls: {
          field: mapToCommaListWithLinks(this.filterItems(this.controls, ((_element$links2 = element.links) === null || _element$links2 === void 0 ? void 0 : _element$links2.control) || []), '/controls2/controls/', 'title', 'cpath', false)
        },
        initiatives: {
          field: mapToCommaListWithLinks(this.filterItems(this.initiatives, ((_element$links3 = element.links) === null || _element$links3 === void 0 ? void 0 : _element$links3.execution) || []), '/actions/i/-/', 'title', 'slug', false)
        },
        children: this.getChildrenIAPreviewLinks(element.childrenObjects, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR),
        attachments: {
          field: mapToCommaListWithLinks(element.attachments, '', 'filename', 'url', false)
        }
      }, this.getCustomFieldsData(element)), this.getCommonFieldsData(element));
    },
    getSystemsData: function getSystemsData(element) {
      var _element$links4, _element$links5, _element$activityType2, _this$getActivityType2;
      var riskAssessmentsData = this.showRiskAssessment ? this.extractRiskAssessments(element.riskAssessments) : {};
      return _objectSpread(_objectSpread(_objectSpread(_objectSpread({
        trafficLight: {
          field: DatatableRenderer.prepareTrafficLightField(element.trafficLight, true),
          fieldText: element.trafficLight
        },
        uniqueId: {
          field: element.uniqueId
        },
        name: {
          fieldText: element.name,
          field: {
            activity: getFieldsFromObjectForPreview(element),
            title: element.name
          }
        },
        children: this.getChildrenIAPreviewLinks(element.childrenObjects, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM),
        location: {
          field: element.location
        },
        description: {
          field: element.description
        },
        purpose: {
          field: element.purpose
        },
        linkedNodes: {
          field: mapToCommaListWithLinks(this.filterItems(this.nodes, element.complianceNodes || []), '', 'title', 'url', false)
        }
      }, riskAssessmentsData), {}, {
        email: {
          field: element.email
        },
        tags: {
          field: utils.findPropsByIds(element.tagIds, this.tags, 'tag')
        },
        processors: {
          field: mapToCommaListWithLinks(this.filterInformationAssets(element.processors, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR), '/compliance/informationassets/processors/', 'name', 'id', false)
        },
        processes: {
          field: mapToCommaListWithLinks(this.filterItems(this.processes, element.processes || []), '', 'title', 'url', false)
        },
        risks: {
          field: mapToCommaListWithLinks(this.filterItems(this.risks, ((_element$links4 = element.links) === null || _element$links4 === void 0 ? void 0 : _element$links4.risk) || []), '', 'title', 'url', false)
        },
        initiatives: {
          field: mapToCommaListWithLinks(this.filterItems(this.initiatives, element.initiatives || []), '/actions/i/-/', 'title', 'slug', false)
        },
        controls: {
          field: mapToCommaListWithLinks(this.filterItems(this.controls, element.controls || []), '/controls2/controls/', 'title', 'cpath', false)
        }
      }, this.getComplianceProjectsData(element.links && ((_element$links5 = element.links) === null || _element$links5 === void 0 ? void 0 : _element$links5.compliance) || [])), {}, {
        attachments: {
          field: mapToCommaListWithLinks(element.attachments, '', 'filename', 'url', false)
        },
        activityType: {
          field: ((_element$activityType2 = element.activityType) === null || _element$activityType2 === void 0 ? void 0 : _element$activityType2.label) || ((_this$getActivityType2 = this.getActivityTypeById(element.activityTypeId)) === null || _this$getActivityType2 === void 0 ? void 0 : _this$getActivityType2.label) || ''
        }
      }, this.getCustomFieldsData(element)), this.getCommonFieldsData(element));
    },
    getComplianceProjectsData: function getComplianceProjectsData(complianceIds) {
      var _this15 = this;
      var filteredItems = this.filterItems(this.nodes, complianceIds);
      var result = {};
      filteredItems.forEach(function (item) {
        var label = _this15.getCompliancePropLabel(item.compliance_projects_id);
        Array.isArray(result[label]) ? result[label].push(item) : result[label] = [item];
      });
      this.complianceProjects.forEach(function (project) {
        var label = _this15.getCompliancePropLabel(project.id);
        if (!result[label]) {
          result[label] = [];
        }
      });
      Object.keys(result).forEach(function (key) {
        result[key] = {
          field: mapToCommaListWithLinks(result[key], '', 'title', 'url', false)
        };
      });
      return result;
    },
    getActivityTypesData: function getActivityTypesData(element) {
      var company = this.companies.find(function (company) {
        return company.id === element.companyId;
      }) || {};
      return _objectSpread(_objectSpread({
        trafficLight: {
          field: DatatableRenderer.prepareTrafficLightField(element.trafficLight, true),
          fieldText: element.trafficLight
        },
        name: this.getName(element),
        description: {
          field: element.description
        },
        tags: {
          field: utils.findPropsByIds(element.tagIds, this.tags, 'tag')
        },
        address: {
          field: company.address || element.address
        },
        postcode: {
          field: company.zipCode || element.postcode
        },
        city: {
          field: company.city || element.city
        },
        email: {
          field: company.email || element.email
        }
      }, this.getCommonFieldsData(element)), this.getCustomFieldsData(element));
    },
    getCommonFieldsData: function getCommonFieldsData(element) {
      var _element$questionnair, _element$questionnair2, _element$questionnair3, _element$questionnair4;
      var data = {
        responsible: {
          field: mapToCommaList(this.filterItems(this.users, element.responsibleUserIds || []), 'display_name')
        },
        accountable: {
          field: mapToCommaList(this.filterItems(this.users, element.accountableUserIds || []), 'display_name')
        },
        consulted: {
          field: mapToCommaList(this.filterItems(this.users, element.consultedUserIds || []), 'display_name')
        },
        informed: {
          field: mapToCommaList(this.filterItems(this.users, element.informedUserIds || []), 'display_name')
        },
        organisations: {
          field: mapToCommaList(this.filterItems(this.organisations, element.organisationIds), 'visible_name')
        },
        completionDegree: {
          field: this.getCompletionDegree((_element$questionnair = element.questionnaires) === null || _element$questionnair === void 0 ? void 0 : _element$questionnair[0])
        },
        questionnaireId: {
          field: utils.findPropsByIds((_element$questionnair2 = element.questionnaires) === null || _element$questionnair2 === void 0 ? void 0 : _element$questionnair2.map(function (item) {
            return item.questionnaireId;
          }), this.questionnaires, 'title')
        },
        questionnaireScoring: {
          field: this.getQuestionnaireScore((_element$questionnair3 = element.questionnaires) === null || _element$questionnair3 === void 0 ? void 0 : _element$questionnair3[0])
        },
        assessmentScoring: {
          field: this.getAssessmentScoring((_element$questionnair4 = element.questionnaires) === null || _element$questionnair4 === void 0 ? void 0 : _element$questionnair4[0])
        }
      };
      if (this.getSettingValue('feature.performance_report_enabled') > 0) {
        data.solutionId = {
          field: mapToCommaList(this.filterItems(this.solutions, element.solutionIds || []), 'title')
        };
      }
      return data;
    },
    extractRiskAssessments: function extractRiskAssessments(riskArray) {
      var risk = {
        before_likelihood: {
          field: 0
        },
        before_impact: {
          field: 0
        },
        before_rpi: {
          field: 0
        },
        after_likelihood: {
          field: 0
        },
        after_impact: {
          field: 0
        },
        after_rpi: {
          field: 0
        }
      };
      if (riskArray && riskArray.length > 0) {
        riskArray.forEach(function (riskElement) {
          if (riskElement.riskIndex == 0) {
            risk.before_likelihood = {
              field: riskElement.likelihood
            };
            risk.before_impact = {
              field: riskElement.impact
            };
            risk.before_rpi = {
              field: riskElement.rpi
            };
          }
          if (riskElement.riskIndex == 1) {
            risk.after_likelihood = {
              field: riskElement.likelihood
            };
            risk.after_impact = {
              field: riskElement.impact
            };
            risk.after_rpi = {
              field: riskElement.rpi
            };
          }
        });
      }
      return risk;
    },
    getTrafficLightField: function getTrafficLightField(value) {
      return {
        field: DatatableRenderer.prepareTrafficLightField(value),
        fieldText: value
      };
    },
    getName: function getName(element) {
      return {
        fieldText: element.name || element.title,
        field: {
          activity: getFieldsFromObjectForPreview(element),
          title: element.name || element.title
        }
      };
    },
    filterItems: function filterItems() {
      var itemsToFilter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var items = arguments.length > 1 ? arguments[1] : undefined;
      if (!items || !Array.isArray(itemsToFilter)) return [];
      return itemsToFilter.filter(function (element) {
        return items.indexOf(element.id) !== -1;
      });
    },
    filterInformationAssets: function filterInformationAssets(ids, type) {
      if (!ids) return [];
      return this.allInformationAssets.filter(function (asset) {
        return ids.indexOf(asset.id) !== -1 && asset.objType === type;
      });
    },
    dismissPreviewModal: function dismissPreviewModal() {
      if (this.updatedFromPreviewModal) {
        this.$emit('refreshData');
        this.updatedFromPreviewModal = false;
      }
    },
    getCompliancePropLabel: function getCompliancePropLabel(id) {
      return id ? 'compliance-' + id : '';
    },
    getCompletionDegree: function getCompletionDegree(questionnaire) {
      return questionnaire ? "".concat(questionnaire.questionnaireAnsweredPercentage, " %") : '-';
    },
    getQuestionnaireScore: function getQuestionnaireScore(questionnaire) {
      return questionnaire !== null && questionnaire !== void 0 && questionnaire.questionnaireScore ? questionnaire.questionnaireScore.totalScore : '-';
    },
    getAssessmentScoring: function getAssessmentScoring(questionnaire) {
      var _questionnaire$questi;
      return questionnaire !== null && questionnaire !== void 0 && (_questionnaire$questi = questionnaire.questionnaireScore) !== null && _questionnaire$questi !== void 0 && _questionnaire$questi.helpText ? questionnaire.questionnaireScore.helpText.assessmentText : '-';
    },
    getChildrenIAPreviewLinks: function getChildrenIAPreviewLinks(childrenObjects) {
      return {
        field: childrenObjects ? childrenObjects.map(function (child) {
          return {
            activity: getFieldsFromObjectForPreview(child),
            title: child.name
          };
        }) : [],
        fieldText: childrenObjects ? childrenObjects.map(function (child) {
          return child.name;
        }).join(', ') : ''
      };
    }
  }
};