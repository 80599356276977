import { getDeadlines } from '@/api/controls/controls';
import { toLocalDate, toDateTimeFormatWithTimezone } from '@/utils/date';
export default {
  name: 'DeadlinePreview',
  introduction: 'Deadlines preview',
  description: 'A list of deadlines preview',
  token: '<deadline-preview />',
  props: {
    deadline: {
      type: [String, Date],
      required: true,
      note: 'the deadline that will be calculated from'
    },
    frequency: {
      type: Number,
      required: true,
      note: 'the frequency of future deadlines'
    },
    startDate: {
      type: [String, Date],
      default: '',
      note: 'when should future deadline start'
    },
    beforeAfter: {
      type: String,
      default: 'after',
      note: 'Should the deadlines be calculated before/after start date'
    },
    days: {
      type: Number,
      default: 0,
      note: 'How many workdays should future deadlines be offset by'
    },
    timezone: {
      type: String,
      required: false,
      default: 'UTC'
    }
  },
  data: function data() {
    return {
      deadlines: []
    };
  },
  computed: {
    start: function start() {
      return this.startDate === '' ? toLocalDate(this.deadline) : toLocalDate(this.startDate);
    },
    formattedDeadline: function formattedDeadline() {
      return toDateTimeFormatWithTimezone(this.deadline, this.timezone);
    }
  },
  watch: {
    deadline: function deadline() {
      this.getDeadlinePreviews();
    },
    frequency: function frequency() {
      this.getDeadlinePreviews();
    },
    startDate: function startDate() {
      this.getDeadlinePreviews();
    },
    beforeAfter: function beforeAfter() {
      this.getDeadlinePreviews();
    },
    days: function days() {
      this.getDeadlinePreviews();
    }
  },
  mounted: function mounted() {
    this.getDeadlinePreviews();
  },
  methods: {
    getDeadlinePreviews: function getDeadlinePreviews() {
      var _this = this;
      var deadlines = [];
      getDeadlines({
        deadline: this.formattedDeadline,
        deadlineDays: this.days,
        deadlineBeforeAfter: this.beforeAfter,
        freq: this.frequency,
        timezone: this.timezone
      }).then(function (data) {
        deadlines.push(data.list[0]);
        deadlines.push(data.list[1]);
        deadlines.push(data.list[2]);
        _this.deadlines = deadlines;
      });
    }
  }
};