export var generateComputed = function generateComputed() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    object = _ref.object,
    event = _ref.event;
  return props.reduce(function (obj, property) {
    var propModel = property;
    var computedProp = {
      get: function get() {
        return object ? this[object][property] : this[property];
      },
      set: function set(value) {
        if (event) {
          this.$emit(event, {
            property: property,
            value: value
          });
        } else {
          this.$emit(property, value);
        }
      }
    };
    obj[propModel] = computedProp;
    return obj;
  }, {});
};