function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getContract as _getContract, editContract, deleteContract as _deleteContract } from '@/api/contracts/contracts';
import { getFiles, getFileById } from '@/api/risma/file';
import { getDocumentTypesAll } from '@/api/admin/documentTypes';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import DelegationWell from '@/components/Organisms/General/coreWells/DelegationWell';
import Notification from '@/components/Molecules/Notification';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SolutionIdRelationDetails from '@/components/Molecules/SolutionIdRelationDetails';
import ActivityTypeSelector from '@/components/Molecules/ActivityTypeSelector';
import { MODULES } from '@/constants/modules';
import { saveStatus } from '@/constants/SaveStatuses';
import { TabTypes } from '@/constants/Tabs';
import { DocumentTypesIds } from '@/constants/contracts/DocumentTypes';
import { AuditGenericData } from '@/constants/AuditGenericData';
import { createTabsList } from '@/utils/base/tabs';
import { getLinksLabel } from '@/utils/links';
import { getCustomFieldValues, customFieldHasValue } from '@/utils/CustomFields';
import { isLockedContractUser } from '@/utils/access';
import { catch403 } from '@/utils/handleAPIErrors';
import { DelegationWellMixin } from '@/mixins/DelegationWellMixin';
export default {
  name: 'ContractEntry',
  description: 'Common page for show contract',
  token: '<contract-entry />',
  components: {
    DepWell: DepWell,
    DelegationWell: DelegationWell,
    Notification: Notification,
    ActivityTypeSelector: ActivityTypeSelector,
    RismaTitle: RismaTitle,
    SolutionIdRelationDetails: SolutionIdRelationDetails
  },
  mixins: [DelegationWellMixin],
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  emits: ['updated', 'deleteItem', 'accessError', 'changeTab'],
  data: function data() {
    return {
      isLoaded: false,
      contract: {},
      saveStatus: saveStatus.SAVED,
      updateTimer: 0,
      notificationMessage: '',
      notificationType: 'info',
      files: [],
      auditQuestionnaires: [],
      auditRequests: [],
      documentTypes: [],
      translate: getTranslate['ContractEntry']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useUserStore, ['currentUser'])), mapState(useOrganisationsStore, ['organisations'])), mapState(useActivityTypesStore, {
    activityTypes: 'contractActivityTypes'
  })), {}, {
    enhancementContract: function enhancementContract() {
      return _objectSpread(_objectSpread({}, this.contract), {}, {
        files: this.files,
        auditQuestionnaires: this.auditQuestionnaires,
        auditRequests: this.auditRequests
      });
    },
    genericData: function genericData() {
      if (!this.contract || !this.contract.objType) return;
      return AuditGenericData[this.contract.objType];
    },
    depHeaderProps: function depHeaderProps() {
      if (!this.isLoaded) {
        return {};
      }
      return {
        id: this.contract.uniqueId || '',
        title: this.contract.title,
        editableId: !this.isReadOnly,
        editableTitle: !this.isReadOnly,
        filesList: this.files,
        trafficLightSettings: this.trafficLightSettings,
        moduleId: this.contract.id,
        module: MODULES.CONTRACTS,
        titleField: 'title',
        users: this.users,
        tabs: this.tabs,
        saveStatus: this.saveStatus,
        showCheckmark: false,
        idField: 'uniqueId',
        hasVersionButtons: true,
        singleFileLoad: true,
        attachmentHasPin: true,
        attachmentHasTitle: true,
        attachmentsFilesAndLinksSeparated: false,
        attachmentFileTypesEnabled: true,
        attachmentEditFileEnabled: false,
        fileTypeOptions: this.typeOptions,
        editableAttachments: !this.isReadOnly
      };
    },
    trafficLightSettings: function trafficLightSettings() {
      return {
        value: this.contract.trafficLight,
        lightType: 'trafficLights',
        field: 'trafficLight',
        readOnly: true
      };
    },
    tabs: function tabs() {
      return createTabsList([TabTypes.DESCRIPTION, TabTypes.CONTRACT_PARTY, TabTypes.RELATED_CONTRACTS, TabTypes.DOCUMENTS, TabTypes.HISTORY, TabTypes.AUDIT, TabTypes.LINKS, TabTypes.CHANGELOG], "/contracts/contract/".concat(this.id), getLinksLabel(this.contract));
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.activityTypes.find(function (type) {
        return +type.id === +_this.contract.activityTypeId;
      });
    },
    typeOptions: function typeOptions() {
      var _this2 = this;
      return this.documentTypes.map(function (type) {
        return {
          id: type.id,
          label: _this2.$trans(type.name)
        };
      });
    },
    isUserLocked: function isUserLocked() {
      return isLockedContractUser(this.currentUser);
    },
    isReadOnly: function isReadOnly() {
      return this.isUserLocked || !!this.contract.archived;
    },
    delegationData: function delegationData() {
      return {
        objType: this.contract.objType,
        organisationIds: this.contract.organisationIds || [],
        responsibleUserIds: this.contract.responsibleUserIds || [],
        accountableUserIds: this.contract.accountableUserIds || [],
        consultedUserIds: this.contract.consultedUserIds || [],
        informedUserIds: this.contract.informedUserIds || [],
        tagIds: this.contract.tags || [],
        module: MODULES.CONTRACTS
      };
    },
    missingRequiredFields: function missingRequiredFields() {
      var _this$contract,
        _this3 = this;
      if (!((_this$contract = this.contract) !== null && _this$contract !== void 0 && (_this$contract = _this$contract.customFields) !== null && _this$contract !== void 0 && _this$contract.length)) return [];
      return this.contract.customFields.filter(function (field) {
        var _this3$currentActivit, _this3$contract$custo;
        var isRequired = (_this3$currentActivit = _this3.currentActivityType) === null || _this3$currentActivit === void 0 || (_this3$currentActivit = _this3$currentActivit.fields.find(function (f) {
          return +f.id === +field.id;
        })) === null || _this3$currentActivit === void 0 ? void 0 : _this3$currentActivit.required;
        if (!isRequired) return false;
        var customField = (_this3$contract$custo = _this3.contract.customFieldValues) === null || _this3$contract$custo === void 0 ? void 0 : _this3$contract$custo.find(function (f) {
          return +f.fieldId === +field.id;
        });
        return !customFieldHasValue(customField, field.typeId);
      }).map(function (field) {
        return field.label;
      });
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this4 = this;
      return Promise.all([this.getContract(), this.getAttachmentFiles()]).then(function () {
        _this4.getAuditQuestionnaires();
        _this4.getAllRequests();
        _this4.getDocumentTypes();
      }).then(function () {
        _this4.isLoaded = true;
      }).catch(function () {
        if (_this4.isPreview) {
          _this4.$emit('accessError');
          return;
        }
        catch403(_this4.$router, 'MyContracts');
      });
    },
    getAuditQuestionnaires: function getAuditQuestionnaires() {
      var _this5 = this;
      if (!this.genericData) {
        this.auditQuestionnaires = [];
        return;
      }
      return this.genericData.getAllQuestionnairesAPI(this.genericData.questionnaireType).then(function (response) {
        _this5.auditQuestionnaires = response;
      });
    },
    getAllRequests: function getAllRequests() {
      var _this6 = this;
      if (!this.genericData) {
        this.auditRequests = [];
        return;
      }
      return this.genericData.getAllRequestsAPI(MODULES.CONTRACTS, this.id).then(function (response) {
        _this6.auditRequests = response.list;
      });
    },
    getAttachmentFiles: function getAttachmentFiles() {
      var _this7 = this;
      return getFiles({
        module: MODULES.CONTRACTS,
        moduleId: this.id
      }).then(function (response) {
        return _this7.files = _toConsumableArray(response.list);
      });
    },
    getContract: function getContract() {
      var _this8 = this;
      return _getContract(this.id).then(function (data) {
        _this8.contract = data;
      });
    },
    getDocumentTypes: function getDocumentTypes() {
      var _this9 = this;
      return getDocumentTypesAll().then(function (_ref) {
        var list = _ref.list;
        return _this9.documentTypes = list;
      });
    },
    onFileUpdate: function onFileUpdate(fileId) {
      var _this10 = this;
      getFileById(fileId).then(function (response) {
        var _response$docTypeIds;
        if ((_response$docTypeIds = response.docTypeIds) !== null && _response$docTypeIds !== void 0 && _response$docTypeIds.includes(DocumentTypesIds.MAIN)) {
          return _this10.getAttachmentFiles();
        }
        var index = _this10.files.findIndex(function (file) {
          return +file.id === response.id;
        });
        if (index !== -1) {
          _this10.files[index] = response;
        } else {
          _this10.files.push(response);
        }
      }).then(function () {
        return _this10.saveStatus = saveStatus.SAVED;
      });
    },
    contractPropertyChanged: function contractPropertyChanged(_ref2) {
      var property = _ref2.property,
        value = _ref2.value;
      if (property === 'tagIds') {
        this.contract.tags = value;
      } else if (property === 'responsibleUserIds') {
        if (!value.length && this.contract.confidential) {
          this.contract.confidential = 0;
        }
        this.contract[property] = value;
      } else {
        this.contract[property] = value;
      }
      this.setUpdateTimer();
    },
    saveChangesImmediately: function saveChangesImmediately(updatedProperty) {
      this.contractPropertyChanged(updatedProperty);
      this.saveStatusClicked();
    },
    setUpdateTimer: function setUpdateTimer() {
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(this.saveUpdates, this.$autoSaveDebounceTime);
    },
    saveStatusClicked: function saveStatusClicked() {
      if (this.saveStatus !== saveStatus.SAVED) {
        clearTimeout(this.updateTimer);
        this.saveUpdates();
      }
    },
    saveUpdates: function saveUpdates() {
      var _this11 = this;
      this.saveStatus = saveStatus.SAVING;
      var timerId = this.updateTimer;
      var customFieldValues = getCustomFieldValues(this.contract);
      var data = _objectSpread(_objectSpread({}, this.contract), {}, {
        customFieldValues: customFieldValues
      });
      editContract(data).then(function (response) {
        if (_this11.contract.confidential && !_this11.contract.responsibleUserIds.includes(_this11.currentUser.id)) {
          _this11.$router.push('/contracts/overview/all');
          return;
        }
        // update the contract if there were no new changes during the save time
        if (timerId === _this11.updateTimer) {
          _this11.contract = response;
        }
        _this11.saveStatus = saveStatus.SAVED;
        _this11.$emit('updated', _this11.contract);
        _this11.handleDismissNotification();
      }).catch(function () {
        _this11.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    handleShowNotification: function handleShowNotification(type, message, timeout) {
      this.notificationType = type;
      this.notificationMessage = this.$trans(message);
      this.$scrollTo(document.body, 100);
      if (timeout) setTimeout(this.handleDismissNotification, timeout);
    },
    handleDismissNotification: function handleDismissNotification() {
      this.notificationMessage = '';
    },
    activityTypeChanged: function activityTypeChanged(value) {
      this.contractPropertyChanged({
        property: 'activityTypeId',
        value: value
      });
    },
    deleteContract: function deleteContract() {
      var _this12 = this;
      _deleteContract(this.id).then(function () {
        if (_this12.isPreview) {
          _this12.$emit('deleteItem', _this12.id);
        } else {
          _this12.$router.replace('/contracts/overview/all');
        }
      });
    },
    updatingAttachments: function updatingAttachments() {
      this.saveStatus = saveStatus.SAVING;
    },
    getActivityData: function getActivityData() {
      var _this13 = this;
      this.getAttachmentFiles().then(function () {
        return _this13.saveStatus = saveStatus.SAVED;
      });
    }
  }
};