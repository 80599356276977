export var ControlFields = {
  REVIEWERS: 'Reviewers',
  COMPLETED: 'Completed',
  REVIEW_DEADLINE: 'Review deadline',
  RESPONSIBLE: 'Responsible'
};
export var ControlsEfficiencyTypes = {
  default: 0,
  efficiencyAssessment: 1,
  extendedEfficiencyAssessment: 2
};