import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "absolute right-0 mt-2 p-4 bg-white shadow-modal-sm z-10 max-w-500px"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_attachment_item = _resolveComponent("attachment-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$props.readOnly ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    class: "block overflow-hidden focus:outline-none focus:border-white leading-5 pt-2 whitespace-nowrap",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('triggerModal', $data.ATTACHMENT_MODAL_MODES.FILE);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "transform text-blue-750 stroke-2 mr-2 inline-block align-top"
  }), _createVNode(_component_risma_title, {
    title: $data.translate.addAttachment,
    type: "medium",
    class: "text-blue-750 inline-block"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), !$props.readOnly && $props.addLinkButtonEnabled ? (_openBlock(), _createElementBlock("button", {
    key: 1,
    class: "block overflow-hidden focus:outline-none focus:border-white leading-5 pt-2 whitespace-nowrap",
    "data-test-id": "add-link",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('triggerModal', $data.ATTACHMENT_MODAL_MODES.LINK);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "transform text-blue-750 stroke-2 mr-2 inline-block align-top"
  }), _createVNode(_component_risma_title, {
    title: $data.translate.addLink,
    type: "medium",
    class: "text-blue-750 inline-block"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.filesList, function (file) {
    return _openBlock(), _createBlock(_component_attachment_item, _mergeProps({
      key: file.id,
      file: file,
      "has-version-buttons": $props.hasVersionButtons,
      "read-only": $props.readOnly,
      "edit-file-enabled": $props.editFileEnabled
    }, _ctx.$attrs), null, 16 /* FULL_PROPS */, ["file", "has-version-buttons", "read-only", "edit-file-enabled"]);
  }), 128 /* KEYED_FRAGMENT */))]);
}