function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import $ from 'jquery';
import { Http } from '@/api/index';

/**
 * Check the status of the api response
 * @param response
 * @returns {*}
 */
export function checkHttpStatus(response) {
  var status = response.status;
  switch (status) {
    case response.status >= 400 && response.status <= 499:
      throw new Error('Client made a fuckup');
    case response.status >= 500 && response.status <= 599:
      throw new Error('Server made a fuckup');
    default:
      return response;
  }
}

/**
 * Throw an Error if the response fails
 * If status code is 401 the user will be redirected to '/' to have a login prompt displayed
 * @param error
 * @returns {string}
 */
export function throwError(error) {
  var skip401Check = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (error && error.response) {
    // Redirect to login page
    if (error.response.status === 401 && !skip401Check) {
      return window.location.href = '/?returnUrl=' + window.location.pathname + '&reason=throwError401';
    }
    throw {
      status: error.response.status,
      statusText: error.response.statusText,
      response: error.response.data,
      fullError: error
    };
  }
  throw error;
}
export function objectToQueryString(object) {
  return $.param(object);
}
export function getDataQueryString(value) {
  return "data[]=".concat(value);
}
export function getDataQueryStringFromArray(array) {
  return array.reduce(function (acc, item) {
    if (acc) acc += '&';
    acc += getDataQueryString(item);
    return acc;
  }, '');
}
export function jsonToRismaFilterQueryString(json) {
  return '?' + Object.keys(json).map(function (key) {
    return 'filters[' + encodeURIComponent(key) + ']=' + encodeURIComponent(json[key]);
  }).join('&');
}
export function getByUrlAndParams(url, params) {
  var queryString = objectToQueryString(params);
  return getByUrl("".concat(url, "?").concat(queryString));
}
export function getByUrl(url) {
  return Http.get(url).then(function (response) {
    return response.data;
  }).catch(function (err) {
    return throwError(err);
  });
}
export function generateDataQueryString(dataItems) {
  var result = dataItems.map(getDataQueryString).join('&');
  if (result.endsWith('&')) {
    result = result.slice(0, -1);
  }
  return result;
}
export function generateIdsQueryString(ids) {
  var result = ids.map(function (id) {
    return getQueryStringByKey('ids', id);
  }).join('&');
  if (result.endsWith('&')) {
    result = result.slice(0, -1);
  }
  return result;
}
export function generateFiltersQueryString(entries) {
  var result = '';
  Object.entries(entries).forEach(function (item) {
    var _item = _slicedToArray(item, 2),
      key = _item[0],
      value = _item[1];
    result += getFiltersQueryString(key, value) + '&';
  });
  if (result.endsWith('&')) {
    result = result.slice(0, -1);
  }
  return result;
}
export function getFiltersQueryString(key, value) {
  var include = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var filterKey = include ? 'filters' : 'excludes';
  if (typeof value === 'string' || typeof value === 'number') {
    return "".concat(filterKey, "[").concat(key, "]=").concat(value);
  }
  if (Array.isArray(value)) {
    var result = value.map(function (item) {
      return "".concat(filterKey, "[").concat(key, "][]=").concat(item);
    }).join('&');
    if (result.endsWith('&')) {
      result = result.slice(0, -1);
    }
    return result;
  }
  return '';
}
export function getQueryStringByKey(key, value) {
  return "".concat(key, "[]=").concat(value);
}
export function handleRequestResponse(promise) {
  var skip401Check = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return promise.then(function (response) {
    return checkHttpStatus(response);
  }).then(function (response) {
    return response.data;
  }).catch(function (err) {
    return throwError(err, skip401Check);
  });
}