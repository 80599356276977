import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import TrafficLightToolTip from '@/components/Molecules/ToolTips/TrafficLightToolTip';
import { Colors } from '@/Colors';
import { clickOutside } from '@/utils/directives/clickOutside';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'TrafficLightSelector',
  components: {
    TrafficLightToolTip: TrafficLightToolTip,
    TrafficLight: TrafficLight
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
      note: 'Preselected light. will default to the gray light'
    },
    lightType: {
      type: String,
      required: true,
      note: 'Which type of light? gaps, trafficLights or incidents'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If traffic light is not editable'
    },
    lightWithAttribute: {
      required: false,
      type: Boolean,
      default: false,
      note: 'If extra information about an traffic light shows when the mouse moves over the element'
    },
    customTrafficLights: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    responsive: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Determine if responsive design should be enabled/disabled'
    }
  },
  emits: ['update:modelValue'],
  data: function data() {
    return {
      selectedLight: null,
      colors: Colors,
      tooltip: false
    };
  },
  computed: {
    colorKey: function colorKey() {
      switch (this.lightType) {
        case 'incidents':
          return 'incidentSeverity';
        default:
          return this.lightType;
      }
    },
    colorTitle: function colorTitle() {
      var _this$colors$this$col;
      return this.lightWithAttribute ? (_this$colors$this$col = this.colors[this.colorKey][this.selectedLight]) === null || _this$colors$this$col === void 0 ? void 0 : _this$colors$this$col.label : '';
    },
    trafficLightColor: function trafficLightColor() {
      var _this = this;
      if (this.customTrafficLights.length) {
        return this.customTrafficLights.find(function (option) {
          return +option.number === +_this.selectedLight;
        }).color;
      }
      return this.colors[this.colorKey].find(function (option) {
        return +option.number === +_this.selectedLight;
      }).color;
    },
    trafficLightsData: function trafficLightsData() {
      var lights = [];
      if (this.lightType === 'gaps') {
        lights = Colors.gaps;
      }
      if (this.lightType === 'trafficLights') {
        lights = this.customTrafficLights.length ? this.customTrafficLights : Colors.trafficLights;
      }
      if (this.lightType === 'incidents') {
        lights = Colors.incidentSeverity;
      }
      if (this.lightType === ObjectTypes.COMPANY) {
        lights = Colors[ObjectTypes.COMPANY];
      }
      return {
        lights: lights,
        showBan: this.lightType === 'gaps',
        showLabels: this.lightType === ObjectTypes.COMPANY
      };
    }
  },
  watch: {
    modelValue: function modelValue(newValue) {
      this.selectedLight = newValue;
    }
  },
  mounted: function mounted() {
    this.selectedLight = this.modelValue;
  },
  methods: {
    handleSelect: function handleSelect(light) {
      this.selectedLight = light.number;
      this.tooltip = false;
      this.$emit('update:modelValue', light.number);
    },
    toggleToolTip: function toggleToolTip() {
      if (this.readOnly) return;
      this.tooltip = !this.tooltip;
    },
    hideTooltip: function hideTooltip() {
      this.tooltip = false;
    }
  }
};