function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getKpiPreviewScoreById } from '@/api/kpi/kpi';
import CollapsiblePanel from '@/components/Molecules/CollapsiblePanel/CollapsiblePanel';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'KpiRelevantActivities',
  introduction: 'The component is used to display Activities that are/aren\'t compliant with KPI',
  description: 'Uses a CollapsiblePanel inside',
  token: '<kpi-relevant-activities kpi-id="1" />',
  components: {
    CollapsiblePanel: CollapsiblePanel,
    RismaTitle: RismaTitle,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    PreviewModalLink: PreviewModalLink
  },
  props: {
    kpiId: {
      type: Number,
      required: true
    }
  },
  data: function data() {
    return {
      matchedEntries: {
        title: this.$trans('Activities that are compliant with KPI'),
        activities: []
      },
      notMatchedEntries: {
        title: this.$trans('Activities that aren\'t compliant with KPI'),
        activities: []
      },
      loaded: false
    };
  },
  computed: _objectSpread({}, mapState(useActivityTypesStore, {
    activityTypesById: 'getActivityTypeById'
  })),
  methods: {
    togglePanel: function togglePanel(isOpen) {
      if (isOpen) {
        this.getCalculation(this.kpiId);
      }
    },
    getCalculation: function getCalculation(id) {
      var _this = this;
      this.loaded = false;
      return getKpiPreviewScoreById(id, '?data[]=allEntries').then(function (response) {
        if (Array.isArray(response.matchedEntries)) {
          _this.matchedEntries.activities = _this.prepareActivitiesList(response.matchedEntries);
          var notMatchedEntries = _this.prepareNotMatchedEntiries(response.totalEntries, response.matchedEntries);
          _this.notMatchedEntries.activities = _this.prepareActivitiesList(notMatchedEntries);
        }
      }).finally(function () {
        _this.loaded = true;
      });
    },
    prepareActivitiesList: function prepareActivitiesList(activities) {
      var _this2 = this;
      return activities.map(function (activity) {
        return {
          id: activity.id,
          uniqueId: activity.uniqueId,
          url: activity.url,
          title: _this2.userFriendlyName(activity),
          objType: activity.objType,
          objTypeName: _this2.userFriendlyActivityName(activity),
          activity: activity
        };
      });
    },
    prepareNotMatchedEntiries: function prepareNotMatchedEntiries(totalEntries, matchedEntries) {
      if (!Array.isArray(matchedEntries)) return [];
      if (totalEntries.length === matchedEntries.length) return [];
      var notMatched = totalEntries;

      // Filter all those activities from notMatched, which are also present in matched
      // Make sure to only remove one non-match for each match, thus the return false
      matchedEntries.forEach(function (match) {
        notMatched.every(function (noMatch, index) {
          if (noMatch.id === match.id && noMatch.objType === match.objType) {
            notMatched.splice(index, 1);
            return false;
          }
          return true;
        });
      });
      return notMatched;
    },
    updatedFromPreview: function updatedFromPreview(event, activity) {
      if (activity.title !== event.title) {
        activity.title = event.title;
      }
      if (activity.uniqueId !== event.uniqueId) {
        activity.uniqueId = event.uniqueId;
      }
    },
    userFriendlyName: function userFriendlyName(activity) {
      if (activity.objType === ObjectTypes.CONTROLS_CONTROL) {
        return activity.cpath + ' ' + activity.title + '. ' + this.$trans('Deadline') + ': ' + activity.deadlineUtc.substring(0, 10);
      }
      return activity.title || activity.name;
    },
    userFriendlyActivityName: function userFriendlyActivityName(activity) {
      var objType = activity.objType;
      if (objType === ObjectTypes.COMPLIANCE_NODE) {
        return this.$trans('Node') + ': ';
      }
      if (objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM) {
        return this.$trans('System') + ': ';
      }
      if (objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR) {
        return this.$trans('Processor') + ': ';
      }
      if (objType === ObjectTypes.INFORMATION_ASSET) {
        var activityType = this.activityTypesById(activity.activityTypeId);
        return activityType.label + ': ';
      }
      return '';
    }
  }
};