import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-51b5165a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("span", {
    class: _normalizeClass({
      isShowFull: $data.isShowFull
    })
  }, _toDisplayString($options.textShown), 3 /* TEXT, CLASS */), $options.isTextTruncatable ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", {
    class: "help-text-action",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.isShowFull = !$data.isShowFull;
    })
  }, _toDisplayString($options.seeMoreOrLess), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]);
}