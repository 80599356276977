import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "relative w-9/12 h-40 mb-4"
};
var _hoisted_2 = ["value", "disabled", "placeholder"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $data.text !== null ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("textarea", {
    value: $data.text,
    disabled: $props.readOnly,
    placeholder: $options.placeholder,
    class: _normalizeClass([{
      'cursor-not-allowed': $props.readOnly
    }, "w-full h-full p-1 border-l-2 border-gray-610 outline-none resize-none"]),
    onInput: _cache[0] || (_cache[0] = function () {
      return $options.setFreeText && $options.setFreeText.apply($options, arguments);
    }),
    onBlur: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_2)])) : _createCommentVNode("v-if", true);
}