import StaticTable from '@/components/Molecules/StaticTable';
export default {
  name: '',
  components: {
    StaticTable: StaticTable
  },
  emits: ['table'],
  data: function data() {
    return {
      testData: [{
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem2',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: '<feather-icon icon="x" color="red" @click="$emit(\'trigger\', $event)"/>'
      }, {
        title: 'TestItem3',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: '',
        children: [{
          title: 'SubTestItem1',
          desc: 'this is a subtest item, you know',
          type: 'SubTestItem',
          button: ''
        }, {
          title: 'SubTestItem2',
          desc: 'this is a subtest item, you know',
          type: 'SubTestItem',
          button: ''
        }]
      }, {
        title: 'TestItem4',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem5',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: '<feather-icon icon="x" color="red" @click="$emit(\'trigger\', $event)"/>'
      }],
      testColumns2: ['Title', 'Description', 'Type']
    };
  }
};