import i18n from '@/i18n';
export var getTranslate = {
  ContactCard: function ContactCard() {
    return {
      type: i18n.t('Type'),
      description: i18n.t('Description'),
      location: i18n.t('Location'),
      address: i18n.t('Address'),
      phone: i18n.t('Phone'),
      regNumber: i18n.t('Reg. number'),
      vatNumber: i18n.t('VAT number'),
      contactName: i18n.t('Contact name')
    };
  }
};