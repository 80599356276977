function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import isEqual from 'lodash/isEqual';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getCustomIAModules } from '@/constants/admin/userActivityModules';
import { getAvailableUserActivityModules } from '@/utils/questionnaires';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { MODULES } from '@/constants/modules';
import { isFieldInOptionalFields } from '@/utils/Utils';
export var BulkUpdateMixin = {
  props: {
    complianceProjects: {
      type: Array,
      required: true
    },
    riskProjects: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      modules: {},
      tableData: [],
      checkGroup: [],
      selectedModules: [],
      hasNoRecordsTitle: false,
      selectAllActivities: false,
      notification: {
        type: 'error',
        message: ''
      },
      itemToUpdate: null
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), {}, {
    allModules: function allModules() {
      var allModules = [];
      for (var item in this.modules) {
        var fullName = item;
        if (this.modules[item].objType === ObjectTypes.INFORMATION_ASSET_LIMITED) {
          fullName = MODULES.COMPLIANCE_INFORMATION_ASSETS;
        }
        allModules.push(_objectSpread(_objectSpread({}, this.modules[item]), {}, {
          id: allModules.length + 1,
          option: this.modules[item].defaultLanguage ? this.modules[item].name : this.$trans(this.modules[item].name),
          fullName: fullName
        }));
      }
      return allModules;
    }
  }),
  mounted: function mounted() {
    this.modules = _objectSpread(_objectSpread({}, getAvailableUserActivityModules()), getCustomIAModules());
  },
  methods: {
    deleteFromPreviewModal: function deleteFromPreviewModal() {
      this.findData();
    },
    updateFromPreviewModal: function updateFromPreviewModal() {
      var _this = this;
      if (!this.itemToUpdate) return;
      var idx = this.tableData.findIndex(function (tableItem) {
        return tableItem.id === _this.itemToUpdate.id && tableItem.objType.includes(_this.itemToUpdate.objType);
      });
      if (idx === -1) return;
      if (this.sensitiveFields && this.sensitiveFields.length) {
        var sensitiveDataChanged = this.sensitiveFields.find(function (field) {
          if (!_this.itemToUpdate[field] && !_this.tableData[idx][field]) return;
          return !isEqual(_this.itemToUpdate[field], _this.tableData[idx][field]);
        });
        if (sensitiveDataChanged) {
          this.findData();
          return;
        }
      }
      this.tableData[idx] = this.itemToUpdate;
      this.itemToUpdate = null;
    },
    setTableData: function setTableData(list) {
      this.tableData = list || [];
      this.hasNoRecordsTitle = !(list && list.length);
      this.setEmptyCheckGroup();
    },
    getArrayOfPatchChunks: function getArrayOfPatchChunks(array) {
      return array.reduce(function (resultArray, item, index) {
        var chunkIndex = Math.floor(index / 10);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);
    },
    setEmptyCheckGroup: function setEmptyCheckGroup() {
      this.checkGroup.length = this.tableData.length;
      this.checkGroup.fill(false);
      this.selectAllActivities = false;
      this.$forceUpdate();
    },
    toggleAll: function toggleAll(isChecked) {
      this.checkGroup = new Array(this.checkGroup.length).fill(isChecked);
    },
    onActivityToggle: function onActivityToggle(isChecked, index) {
      this.checkGroup[index] = isChecked;
    },
    filterBySelectedModules: function filterBySelectedModules(list, activityTypes) {
      var newList = list.slice(0);
      var selectedIAIds = [];
      this.selectedModules.forEach(function (module) {
        if (module.fullName === MODULES.COMPLIANCE_INFORMATION_ASSETS) {
          selectedIAIds.push(module.activityTypeId);
        }
      });
      if (this.selectedModules.find(function (module) {
        return module.fullName === MODULES.INCIDENTS;
      })) {
        newList = newList.map(function (item) {
          if ([ObjectTypes.INCIDENTS_INCIDENT, ObjectTypes.INCIDENTS_INCIDENT_LIMITED].includes(item.objType)) {
            var copyIncident = _objectSpread({}, item);
            var activityType = activityTypes.find(function (item) {
              return item.id === copyIncident.activityTypeId;
            });
            if (!isFieldInOptionalFields(activityType, 'description')) {
              copyIncident.description = '';
            }
            return copyIncident;
          }
          return item;
        });
      }
      if (selectedIAIds && selectedIAIds.length) {
        newList = newList.filter(function (item) {
          return !(item.objType === ObjectTypes.INFORMATION_ASSET_LIMITED && !selectedIAIds.includes(item.activityTypeId));
        });
      }
      return newList;
    },
    getActivityIdsWithModules: function getActivityIdsWithModules(isCheckedArray, dataItems, modules) {
      var _this2 = this;
      var activities = [];
      isCheckedArray.forEach(function (item, index) {
        if (!item) {
          return;
        }
        var module = _this2.getModuleIndex(dataItems[index], modules);
        if (dataItems[index].objType === ObjectTypes.INFORMATION_ASSET_LIMITED) {
          module = MODULES.COMPLIANCE_INFORMATION_ASSETS;
        }
        activities.push({
          id: dataItems[index].id,
          module: module,
          title: _this2.getTitle(dataItems[index])
        });
      });
      return activities;
    },
    getModuleIndex: function getModuleIndex(data, modules) {
      var compareFunc = function compareFunc(item) {
        return modules[item].objType.startsWith(data.objType);
      };
      if (ObjectTypes.INFORMATION_ASSET_LIMITED.startsWith(data.objType)) {
        compareFunc = function compareFunc(item) {
          return modules[item].objType.startsWith(data.objType) && data.activityTypeId === modules[item].activityTypeId;
        };
      }
      return Object.keys(modules).find(compareFunc);
    },
    getTitle: function getTitle(item) {
      if (item.objType === ObjectTypes.CONTROLS_CONTROL_LIMITED || item.objType === ObjectTypes.CONTROLS_CONTROL) {
        return "".concat(item.cpath, " ").concat(item.title);
      }
      return item.displayHeadline || item.title || item.name;
    },
    hasEmptyFields: function hasEmptyFields(activitiesBySelectedIds) {
      if (activitiesBySelectedIds.length == 0) {
        this.notification.message = this.$trans('Choose activities');
        return true;
      }
      this.notification.message = '';
      return false;
    },
    handleSuccessfulPatch: function handleSuccessfulPatch(list) {
      this.setTableData(list);
      this.setEmptyCheckGroup();
      this.setNotificationSuccess();
      this.resetSuccessMessageAfterInterval();
    },
    handleErrorPatch: function handleErrorPatch(error, item) {
      this.notification.type = 'error';
      if (this.notification.message.indexOf(error.response.error) === -1) {
        this.notification.message = error.response.error + ' \n';
        this.notification.message += this.$trans('Found problem in:') + '\n';
      }
      this.notification.message += "".concat(this.modules[item.module].name, ":  ").concat(item.title, " \n");
    },
    resetSuccessMessageAfterInterval: function resetSuccessMessageAfterInterval() {
      var _this3 = this;
      setTimeout(function () {
        _this3.notification.message = '';
      }, this.$debounceTimeout * 2);
    },
    setNotificationSuccess: function setNotificationSuccess(text) {
      this.notification.type = 'success';
      this.notification.message = text || this.translate.activitiesWereAssigned;
    },
    findProjectTitleById: function findProjectTitleById(id, projects) {
      var project = projects.find(function (pj) {
        return +pj.id === +id;
      });
      if (project) {
        return project.title;
      }
      return '';
    },
    enhanceData: function enhanceData(items) {
      var _this4 = this;
      var result = [];
      items.forEach(function (item) {
        var _this4$allModules$fin, _this4$modules$_this;
        var moduleTitle = '';
        if (item.objType.startsWith(ObjectTypes.COMPLIANCE_NODE)) {
          moduleTitle = _this4.findProjectTitleById(item.compliance_projects_id, _this4.complianceProjects);
        } else if (item.objType.startsWith(ObjectTypes.RISK_RISK)) {
          moduleTitle = _this4.findProjectTitleById(item.projectId, _this4.riskProjects);
        }
        if (item.objType === ObjectTypes.INCIDENTS_INCIDENT) {
          item.objType = ObjectTypes.INCIDENTS_INCIDENT_LIMITED;
        }
        var module = ((_this4$allModules$fin = _this4.allModules.find(function (module) {
          return module.objType.startsWith(item.objType);
        })) === null || _this4$allModules$fin === void 0 ? void 0 : _this4$allModules$fin.fullName) || '';
        moduleTitle = moduleTitle || ((_this4$modules$_this = _this4.modules[_this4.getModuleIndex(item, _this4.modules)]) === null || _this4$modules$_this === void 0 ? void 0 : _this4$modules$_this.name) || '';
        result.push(_objectSpread(_objectSpread({}, item), {}, {
          moduleTitle: moduleTitle,
          module: module
        }));
      });
      return result;
    }
  }
};