function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
var _excluded = ["articleId", "module"];
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getTranslate } from './translate';
import { getAllArticlesByGapSchemaId, updateGapArticles as _updateGapArticles, getAllGapSchemas } from '@/api/compliance/gapschema';
import { getAvailableGapSchemasTypes as _getGapSchemasTypes } from '@/utils/gapSchemas';
import SuggestedActivitiesModal from '@/components/Organisms/Admin/SuggestedActivitiesModal';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import Alert from '@/components/Molecules/Modal/Alert';
import SuggestedActivitiesCell from '@/components/Pages/Admin/SuggestedActivities/SuggestedActivitiesCell';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { MODULES } from '@/constants/modules';
export default {
  name: 'AdminSuggestedActivities',
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    FeatherIcon: FeatherIcon,
    SuggestedActivitiesModal: SuggestedActivitiesModal,
    RismaButton: RismaButton,
    RismaDatatable: RismaDatatable,
    Alert: Alert,
    SuggestedActivitiesCell: SuggestedActivitiesCell
  },
  mixins: [RismaDatatableDefaultMixin],
  data: function data() {
    return {
      articles: [],
      gapSchemaTypes: [],
      gapSchemas: [],
      gapSchemaType: '',
      gapSchema: '',
      showCreateModal: false,
      showAlert: false,
      activityToDelete: {},
      MODULES: MODULES,
      mediaPrintCss: "td:nth-last-child(-n+2) {\n                max-width: 150px;\n                overflow-wrap: break-word;\n            }",
      translate: getTranslate['AdminSuggestedActivities']()
    };
  },
  computed: {
    gapSchemaTypesOptions: function gapSchemaTypesOptions() {
      var uniqueTypesIds = _toConsumableArray(new Set(this.gapSchemas.map(function (item) {
        return item.gapSchemaType;
      })));
      return this.gapSchemaTypes.filter(function (type) {
        return uniqueTypesIds.indexOf(type.id) !== -1;
      }).map(function (gapSchema) {
        return {
          label: gapSchema.type,
          value: gapSchema.id
        };
      });
    },
    gapSchemasOptions: function gapSchemasOptions() {
      var _this = this;
      return this.gapSchemas.reduce(function (acc, questionnaire) {
        if (questionnaire.gapSchemaType === _this.gapSchemaType) {
          acc.push({
            label: questionnaire.title,
            value: questionnaire.id
          });
        }
        return acc;
      }, []);
    },
    articlesBySuggestedActivities: function articlesBySuggestedActivities() {
      return this.articles.filter(function (item) {
        return item.suggestedActivities && !Array.isArray(item.suggestedActivities) && Object.values(item.suggestedActivities).some(function (item) {
          var _item$suggested_activ;
          return (_item$suggested_activ = item.suggested_activities) === null || _item$suggested_activ === void 0 ? void 0 : _item$suggested_activ.length;
        });
      });
    },
    columns: function columns() {
      return {
        article: this.translate.article,
        articleTtitle: this.translate.articleTitle,
        requirement: this.translate.requirement,
        suggestedActions: this.translate.suggestedActions,
        suggestedControls: this.translate.suggestedControls
      };
    },
    dataset: function dataset() {
      var _this2 = this;
      return this.articlesBySuggestedActivities.map(function (item) {
        var _item$suggestedActivi, _item$suggestedActivi2, _item$suggestedActivi3, _item$suggestedActivi4;
        return {
          article: {
            field: item.article
          },
          articleTtitle: {
            field: item.articleTitle
          },
          requirement: {
            field: item.requirement
          },
          suggestedActions: {
            activities: ((_item$suggestedActivi = item.suggestedActivities) === null || _item$suggestedActivi === void 0 || (_item$suggestedActivi = _item$suggestedActivi[MODULES.EXECUTION]) === null || _item$suggestedActivi === void 0 ? void 0 : _item$suggestedActivi.suggested_activities) || [],
            id: item.id,
            field: _this2.formatSuggestedActivities((_item$suggestedActivi2 = item.suggestedActivities) === null || _item$suggestedActivi2 === void 0 || (_item$suggestedActivi2 = _item$suggestedActivi2[MODULES.EXECUTION]) === null || _item$suggestedActivi2 === void 0 ? void 0 : _item$suggestedActivi2.suggested_activities)
          },
          suggestedControls: {
            activities: ((_item$suggestedActivi3 = item.suggestedActivities) === null || _item$suggestedActivi3 === void 0 || (_item$suggestedActivi3 = _item$suggestedActivi3[MODULES.CONTROLS]) === null || _item$suggestedActivi3 === void 0 ? void 0 : _item$suggestedActivi3.suggested_activities) || [],
            id: item.id,
            fieldText: _this2.formatSuggestedActivities((_item$suggestedActivi4 = item.suggestedActivities) === null || _item$suggestedActivi4 === void 0 || (_item$suggestedActivi4 = _item$suggestedActivi4[MODULES.CONTROLS]) === null || _item$suggestedActivi4 === void 0 ? void 0 : _item$suggestedActivi4.suggested_activities)
          }
        };
      });
    }
  },
  watch: {
    articles: {
      deep: true,
      handler: function handler() {
        this.enhanceDataset(this.dataset, this.columns);
      }
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      return Promise.all([this.getGapSchemasTypes(), this.getGapSchemas()]);
    },
    getGapSchemas: function getGapSchemas() {
      var _this3 = this;
      return getAllGapSchemas('data[]=all').then(function (_ref) {
        var list = _ref.list;
        _this3.gapSchemas = list;
      });
    },
    getGapSchemasTypes: function getGapSchemasTypes() {
      var _this4 = this;
      return _getGapSchemasTypes().then(function (_ref2) {
        var list = _ref2.list;
        _this4.gapSchemaTypes = list;
      });
    },
    selectGapSchemaType: function selectGapSchemaType() {
      this.gapSchema = '';
    },
    openCreateModal: function openCreateModal() {
      this.showCreateModal = true;
    },
    getGapArticles: function getGapArticles(gapSchemaId) {
      var _this5 = this;
      var data = ['suggestedActivities'];
      return getAllArticlesByGapSchemaId(gapSchemaId, data).then(function (_ref3) {
        var list = _ref3.list;
        _this5.articles = list;
      });
    },
    selectGapSchema: function selectGapSchema(value) {
      var _this6 = this;
      if (value === this.gapSchema) {
        return;
      }
      this.gapSchema = value;
      return this.getGapArticles(this.gapSchema).then(function () {
        _this6.enhanceDataset(_this6.dataset, _this6.columns);
      });
    },
    updateGapArticles: function updateGapArticles(data) {
      var _this$articles$articl,
        _this7 = this;
      var articleId = data.articleId,
        module = data.module,
        newActivity = _objectWithoutProperties(data, _excluded);
      var articleIndex = this.articles.findIndex(function (article) {
        return article.id === articleId;
      });
      var suggestedActivities = this.prepareNewDataForSave((_this$articles$articl = this.articles[articleIndex]) === null || _this$articles$articl === void 0 ? void 0 : _this$articles$articl.suggestedActivities, module, newActivity);
      _updateGapArticles(this.gapSchema, articleId, {
        suggestedActivities: suggestedActivities
      }).then(function (response) {
        _this7.showCreateModal = false;
        _this7.articles[articleIndex] = response;
      });
    },
    prepareNewDataForSave: function prepareNewDataForSave() {
      var activities = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var module = arguments.length > 1 ? arguments[1] : undefined;
      var newData = arguments.length > 2 ? arguments[2] : undefined;
      var preparedData = this.prepareDataForSave(activities);
      if (!preparedData[module]) {
        preparedData[module] = [];
      }
      preparedData[module].push(newData);
      return preparedData;
    },
    prepareDataForSave: function prepareDataForSave(activities) {
      if (Array.isArray(activities) && !activities.length) {
        return {};
      }
      return Object.entries(activities).reduce(function (acc, _ref4) {
        var _ref5 = _slicedToArray(_ref4, 2),
          key = _ref5[0],
          value = _ref5[1];
        acc[key] = value.suggested_activities.map(function (item) {
          return {
            id: item.id,
            header: item.activity_header,
            description: item.activity_description
          };
        });
        return acc;
      }, {});
    },
    handleChangeData: function handleChangeData(_ref6, module, articleId) {
      var _this8 = this;
      var _ref7 = _slicedToArray(_ref6, 3),
        event = _ref7[0],
        activityId = _ref7[1],
        property = _ref7[2];
      var articleIndex = this.articles.findIndex(function (article) {
        return article.id === articleId;
      });
      var suggestedActivitiesByModule = this.articles[articleIndex].suggestedActivities[module].suggested_activities;
      var activity = suggestedActivitiesByModule.find(function (activity) {
        return activity.id === activityId;
      });
      activity[property] = event;
      var suggestedActivities = this.prepareDataForSave(this.articles[articleIndex].suggestedActivities);
      _updateGapArticles(this.gapSchema, articleId, {
        suggestedActivities: suggestedActivities
      }).then(function (response) {
        _this8.showCreateModal = false;
        _this8.articles[articleIndex] = response;
      });
    },
    handleDelete: function handleDelete(activityId, module, articleId) {
      this.activityToDelete = {
        articleId: articleId,
        module: module,
        activityId: activityId
      };
      this.showAlert = true;
    },
    deleteActivity: function deleteActivity() {
      var _this9 = this,
        _activities$this$acti;
      var article = this.articles.find(function (article) {
        return article.id === _this9.activityToDelete.articleId;
      });
      var activities = _objectSpread({}, article.suggestedActivities);
      var activitiesByModule = ((_activities$this$acti = activities[this.activityToDelete.module]) === null || _activities$this$acti === void 0 ? void 0 : _activities$this$acti.suggested_activities) || [];
      activities[this.activityToDelete.module].suggested_activities = activitiesByModule.filter(function (activity) {
        return activity.id !== _this9.activityToDelete.activityId;
      });
      var suggestedActivities = this.prepareDataForSave(activities);
      return _updateGapArticles(this.gapSchema, this.activityToDelete.articleId, {
        suggestedActivities: suggestedActivities
      }).then(function () {
        _this9.closeAlert();
      });
    },
    closeAlert: function closeAlert() {
      this.showAlert = false;
      this.activityToDelete = {};
    },
    formatSuggestedActivities: function formatSuggestedActivities(activities) {
      if (!activities) {
        return '';
      }
      return activities.reduce(function (acc, activity) {
        acc += "".concat(activity.activity_header, " ").concat(activity.activity_description, " \n");
        return acc;
      }, '');
    }
  }
};