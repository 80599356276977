function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import * as Utils from '@/utils/Utils';
import { Colors } from '@/Colors';
export default {
  name: 'LinksDepPreview',
  introduction: 'LinksDepPreview is a component for linkstab',
  description: 'LinksDepPreview is a component to show the module data',
  token: "<links-dep-preview\n        v-if=\"previewData !== null\"\n        :organisations=\"organisations\"\n        :users=\"users\"\n        :data=\"previewData\"\n        :scoring=\"scoring\" />",
  components: {
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight,
    FeatherIcon: FeatherIcon,
    RismaTitleWithIcon: RismaTitleWithIcon,
    PreviewModalLink: PreviewModalLink
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: function _default() {},
      note: 'data object'
    },
    users: {
      type: Array,
      required: true,
      note: 'A list of all users'
    },
    organisations: {
      type: Array,
      required: true,
      note: 'A list of all organisations'
    },
    scoring: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      colors: Colors,
      translate: getTranslate['LinksDepPreview']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    trafficLightColor: function trafficLightColor() {
      if (this.data.vatStatus !== undefined) {
        return Colors.getCompanyTrafficlightByNumber(this.data.vatStatus).color;
      }
      if (this.data.isMothercontrol || this.data.trafficLight === undefined || this.data.trafficLight === null) {
        return '';
      }
      return Colors.getTrafficlightByNumber(this.data.trafficLight).color;
    },
    severityColor: function severityColor() {
      if (this.data.severity === undefined) {
        return '';
      }
      return Colors.findIncidentSeverity(this.data.severity).color;
    },
    organisationsAssigned: function organisationsAssigned() {
      return Utils.findPropsByIds(this.data.organisationIds, this.organisations, 'visible_name');
    },
    usersResponsible: function usersResponsible() {
      return Utils.findPropsByIds(this.data.responsibleIds, this.users, 'display_name');
    },
    usersReview: function usersReview() {
      return Utils.findPropsByIds(this.data.reviewIds, this.users, 'display_name');
    },
    likelyhoodScore: function likelyhoodScore() {
      if (!this.getSettingValue('risma.likelihood_percentage_disabled') && this.scoring.likelihood_values[this.data.likelihood]) {
        return this.scoring.likelihood_values[this.data.likelihood];
      }
      if (this.scoring.likelihood_labels && this.data.likelyhood > 0) {
        return this.scoring.likelihood_labels[this.data.likelyhood - 1].description;
      }
      return '';
    },
    impactLabel: function impactLabel() {
      if (this.data.impact) {
        var _this$scoring, _this$scoring2;
        return "".concat(((_this$scoring = this.scoring) === null || _this$scoring === void 0 ? void 0 : _this$scoring.impact_label) || '', " ").concat(this.data.impact, " ").concat(((_this$scoring2 = this.scoring) === null || _this$scoring2 === void 0 || (_this$scoring2 = _this$scoring2.impact_labels) === null || _this$scoring2 === void 0 ? void 0 : _this$scoring2[this.data.impact].value) || '');
      }
      return '';
    },
    lineThroughTitle: function lineThroughTitle() {
      if (this.data.active === undefined) {
        return true;
      }
      return this.data.active;
    }
  })
};