function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useTagsStore } from '@/Store/tagsStore';
import * as ControlsApi from '@/api/controls/controls';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import NoSearchResults from '@/components/Pages/Compliance/Reports/NoSearchResults';
import Notification from '@/components/Molecules/Notification';
import ControlFilters from '@/components/Organisms/Controls/ControlFilters';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { Colors } from '@/Colors';
import { ControlLocalStorage } from '@/constants/ControlLocalStorage';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import * as Utils from '@/utils/Utils';
import { sortByIndex } from '@/utils/sort';
import { checkControlsOrgAccess } from '@/utils/access';
import { UserLevels } from '@/constants/UserLevels';
import { DateFormats } from '@/constants/DateFormats';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { formatCurrentDateTime, getYearAfterAddingYears, isDateBefore, formatTimeUtc, formatTime } from '@/utils/date';
var monthsDefault = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
var MONTHS_IN_YEAR = 12;
export default {
  name: 'ControlYearWheelDashboard',
  token: '<control-year-wheel-dashboard />',
  components: {
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    NoSearchResults: NoSearchResults,
    Notification: Notification,
    ControlFilters: ControlFilters,
    FeatherIcon: FeatherIcon,
    PreviewModalLink: PreviewModalLink,
    SingleSelect: SingleSelect,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  data: function data() {
    return {
      isLoaded: false,
      isDashboardCreated: false,
      controls: [],
      updatedControl: null,
      allYears: [],
      controlStatusesUI: {
        COMPLETED_BEFORE: 'completed-before',
        NOT_COMPLETED_BEFORE: 'not-completed-before',
        NOT_COMPLETED_YET: 'not-completed-yet',
        NOT_PERFORMED: 'not-performed',
        NONE: 'none'
      },
      completedAfterDeadlineColor: Colors.getControlTrafficLightByNumber(1).color,
      noSearchText: 'Adjust your search criterias to view search results',
      stateLocal: ControlLocalStorage.ANNUAL_OVERVIEW,
      isError: false,
      errorMessage: '',
      motherControls: [],
      filters: {
        organisations: [],
        tags: [],
        mother: null,
        responsible: [],
        search: '',
        processes: []
      },
      processes: [],
      firstMonthOnTimeline: 0,
      firstYearOnTimeline: +formatCurrentDateTime(DateFormats.DATE_YEAR_FORMAT),
      lastYearOnTimeline: getYearAfterAddingYears(3),
      yearsToDisplay: 1,
      showOverview: true,
      translate: getTranslate['ControlYearWheelDashboard']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useTagsStore, ['tags'])), {}, {
    yearsToDisplayOptions: function yearsToDisplayOptions() {
      return [{
        label: "1 ".concat(this.translate.year),
        value: 1
      }, {
        label: "2 ".concat(this.translate.years),
        value: 2
      }, {
        label: "3 ".concat(this.translate.years),
        value: 3
      }];
    },
    legends: function legends() {
      return [{
        label: this.translate.completedOnTime,
        color: Colors.noGap
      }, {
        label: this.translate.completedAfterDeadline,
        color: Colors.getControlTrafficLightByNumber(1).color
      }, {
        label: this.translate.controlNotPerformed,
        color: Colors.high
      }, {
        label: this.translate.notCompletedYet,
        color: Colors.system.lightGray
      }];
    },
    totalMonthsDisplayed: function totalMonthsDisplayed() {
      return this.yearsToDisplay * MONTHS_IN_YEAR;
    },
    displayMonths: function displayMonths() {
      var _this = this;
      return Array.from(Array(this.totalMonthsDisplayed)).map(function (value, index) {
        var monthName = monthsDefault[(index + _this.firstMonthOnTimeline) % MONTHS_IN_YEAR];
        var yearsAfterFirst = Math.floor((index + _this.firstMonthOnTimeline) / MONTHS_IN_YEAR);
        var shortYear = (_this.firstYearOnTimeline + yearsAfterFirst) % 100;
        return "".concat(monthName, "/").concat(shortYear);
      });
    },
    downloadUrl: function downloadUrl() {
      var result = '/api/2.0/controls/export/annualoverview';
      if (this.filteredControls.length) {
        result += '?';
        this.filteredControls.forEach(function (control) {
          result += "&controls[]=".concat(control.cpath);
        });
        if (this.currentTimeline) {
          result += "&year=".concat(this.firstYearOnTimeline);
          result += "&month=".concat(this.firstMonthOnTimeline + 1);
          result += "&monthsDisplayed=".concat(this.totalMonthsDisplayed);
        }
      }
      return result;
    },
    filtersData: function filtersData() {
      return {
        organisations: this.organisations,
        tags: this.tags,
        users: this.users.filter(function (user) {
          return checkControlsOrgAccess(user, UserLevels.NORMAL);
        }),
        mother: this.motherControls.map(function (control) {
          return {
            label: "".concat(control.cpath, " ").concat(control.title),
            value: +control.cpath,
            cpath: control.cpath
          };
        }),
        processes: this.processes
      };
    },
    filteredControls: function filteredControls() {
      var _this2 = this;
      return this.controls.filter(function (control) {
        if (_this2.filters.mother && control.mothercontrolId != _this2.filters.mother) return;
        var result = true;
        if (_this2.filters.search) {
          result = _this2.searchByEntity("".concat(control.cpath, " ").concat(control.title));
        }
        if (_this2.filters.organisations.length) {
          result = result && Utils.filterArrayByListOfPropValues(_this2.filters.organisations, control.organisationIds).length;
        }
        if (_this2.filters.responsible.length) {
          var responsibleIds = control.userIds && control.userIds.responsible && control.userIds.responsible.map(function (id) {
            return +id;
          });
          result = result && Utils.filterArrayByListOfPropValues(_this2.filters.responsible, responsibleIds).length;
        }
        if (_this2.filters.tags.length) {
          var _control$tags;
          var tagIds = control.tagIds || ((_control$tags = control.tags) === null || _control$tags === void 0 ? void 0 : _control$tags.map(function (tag) {
            return tag.id;
          }));
          result = result && Utils.filterArrayByListOfPropValues(_this2.filters.tags, tagIds).length;
        }
        if (_this2.filters.processes && _this2.filters.processes.length) {
          var processesIds = control.processes ? control.processes.map(function (item) {
            return item.id;
          }) : [];
          result = result && Utils.filterArrayByListOfPropValues(_this2.filters.processes, processesIds).length;
        }
        return result;
      });
    },
    startMonthIndex: function startMonthIndex() {
      var currentYearIndex = this.allYears.indexOf(this.firstYearOnTimeline);
      return MONTHS_IN_YEAR * currentYearIndex + this.firstMonthOnTimeline;
    },
    currentTimeline: function currentTimeline() {
      var _this3 = this;
      return this.yearWheelAllYears.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          months: _this3.constructProperMonths(item.months)
        });
      });
    },
    yearWheelAllYears: function yearWheelAllYears() {
      var _this4 = this;
      return this.filteredControls.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          title: "".concat(item.cpath, " ").concat(item.title),
          months: _this4.prepareMonths(item)
        });
      });
    },
    isFutureMonthLimit: function isFutureMonthLimit() {
      return this.firstMonthOnTimeline === 0 && this.firstYearOnTimeline + this.yearsToDisplay > this.lastYearOnTimeline;
    },
    isFutureYearLimit: function isFutureYearLimit() {
      return this.isOverYearLimit(this.yearsToDisplay);
    },
    isPastMonthLimit: function isPastMonthLimit() {
      return this.isPastYearLimit && this.firstMonthOnTimeline === 0;
    },
    isPastYearLimit: function isPastYearLimit() {
      return !this.allYears.includes(this.firstYearOnTimeline - 1);
    }
  }),
  watch: {
    filters: function filters() {
      var _this5 = this;
      this.showOverview = false;
      this.$nextTick(function () {
        return _this5.showOverview = true;
      });
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this6 = this;
      this.isLoaded = false;
      this.isError = false;
      this.errorMessage = '';
      return Promise.all([this.getMotherControls(), this.getProcesses(), this.getAllControls()]).then(this.createDashboard).catch(function (e) {
        _this6.isError = true;
        if (e && e.response && e.response.error) {
          _this6.errorMessage = e.response.error;
        } else {
          _this6.errorMessage = _this6.translate.anErrorOccurred;
        }
      });
    },
    getProcesses: function getProcesses() {
      var _this7 = this;
      return ControlsApi.getProcesses().then(function (_ref) {
        var list = _ref.list;
        return _this7.processes = list;
      });
    },
    getMotherControls: function getMotherControls() {
      var _this8 = this;
      return ControlsApi.getAllMotherControls().then(function (_ref2) {
        var list = _ref2.list;
        return _this8.motherControls = list;
      });
    },
    getAllControls: function getAllControls() {
      var _this9 = this;
      var params = this.prepareParams();
      return ControlsApi.getControlsReports(params).then(function (_ref3) {
        var list = _ref3.list;
        _this9.controls = sortByIndex(list, 'cpath');
      });
    },
    getControlByCpath: function getControlByCpath(cpath) {
      var params = this.prepareParams() + "&filters[cpath][]=".concat(cpath);
      return ControlsApi.getControlsReports(params).then(function (_ref4) {
        var list = _ref4.list;
        return list[0];
      });
    },
    createDashboard: function createDashboard() {
      this.createAllYears();
      this.isLoaded = true;
      this.isDashboardCreated = true;
    },
    goYearBack: function goYearBack() {
      if (this.isPastYearLimit) return;
      this.firstYearOnTimeline--;
    },
    goMonthBack: function goMonthBack() {
      if (this.isPastMonthLimit) return;
      if (this.firstMonthOnTimeline === 0) {
        this.firstMonthOnTimeline = 11;
        this.firstYearOnTimeline--;
        return;
      }
      this.firstMonthOnTimeline--;
    },
    goMonthForward: function goMonthForward() {
      if (this.isFutureMonthLimit) return;
      if (this.firstMonthOnTimeline === 11) {
        this.firstMonthOnTimeline = 0;
        this.firstYearOnTimeline++;
        return;
      }
      this.firstMonthOnTimeline++;
    },
    gotYearForward: function gotYearForward() {
      if (this.isFutureYearLimit) return;
      this.firstYearOnTimeline++;
    },
    prepareAllMonths: function prepareAllMonths(item, monthsByYear) {
      var result = [];
      this.allYears.forEach(function (year) {
        result = [].concat(_toConsumableArray(result), _toConsumableArray(monthsByYear[year]));
      });
      return result;
    },
    updateFromPreviewMode: function updateFromPreviewMode(item) {
      this.updatedControl = item;
    },
    onDismissModal: function onDismissModal() {
      var _this10 = this;
      if (!this.updatedControl) return;
      this.getControlByCpath(this.updatedControl.cpath).then(function (control) {
        var index = _this10.controls.findIndex(function (control) {
          return +control.id === +_this10.updatedControl.id;
        });
        _this10.controls[index] = control;
        _this10.updatedControl = null;
      });
    },
    prepareParams: function prepareParams() {
      var neededData = ['organisationIds', 'tags', 'futureDeadlines', 'history', 'reviews', 'associations'];
      var result = '?';
      neededData.map(function (item) {
        result += "data[]=".concat(item, "&");
      });
      result = this.addParamFilters(result);
      result += "deadlineEndDate=".concat(this.lastYearOnTimeline, "-12-31");
      return result;
    },
    addParamFilters: function addParamFilters(data) {
      var result = this.addFilterParamsByStates(data);
      result = this.addFilterParamsByFrequency(result);
      return result;
    },
    addFilterParamsByStates: function addFilterParamsByStates(data) {
      var result = data;
      [RismaControlsUtils.states.STATE_PENDING].map(function (item) {
        result += "filters[states][]=".concat(item, "&");
      });
      return result;
    },
    addFilterParamsByFrequency: function addFilterParamsByFrequency(data) {
      var result = data;
      var possibleFilterFrequencies = [RismaControlsUtils.frequencies.FREQ_MONTHLY, RismaControlsUtils.frequencies.FREQ_QUARTERLY, RismaControlsUtils.frequencies.FREQ_BIANNUALLY, RismaControlsUtils.frequencies.FREQ_ANNUALLY, RismaControlsUtils.frequencies.FREQ_TWO_YEARS, RismaControlsUtils.frequencies.FREQ_THREE_YEARS, RismaControlsUtils.frequencies.FREQ_FOUR_YEARS, RismaControlsUtils.frequencies.FREQ_FIVE_YEARS, RismaControlsUtils.frequencies.FREQ_CUSTOM];
      possibleFilterFrequencies.map(function (item) {
        result += "filters[frequencies][]=".concat(item, "&");
      });
      return result;
    },
    createAllYears: function createAllYears() {
      var minYear = this.getMinYear();
      var maxYear = this.lastYearOnTimeline;
      var result = [];
      for (var i = minYear; i <= maxYear; i++) {
        result.push(i);
      }
      this.allYears = result;
    },
    getMinYear: function getMinYear() {
      var result = +formatCurrentDateTime(DateFormats.DATE_YEAR_FORMAT);
      var allPossibleDeadlines = [];
      if (this.controls.length) {
        this.controls.map(function (item) {
          allPossibleDeadlines.push(item.deadline);
          if (item.history && item.history.length) {
            item.history.map(function (itemHistory) {
              allPossibleDeadlines.push(itemHistory.deadline);
            });
          }
        });
        var minDeadline = allPossibleDeadlines.reduce(function (prev, current) {
          return isDateBefore(prev, current) ? prev : current;
        });
        if (minDeadline) {
          result = +formatTime(minDeadline, DateFormats.DATE_YEAR_FORMAT);
        }
      }
      return result;
    },
    constructProperMonths: function constructProperMonths(monthsInYears) {
      return monthsInYears.slice(this.startMonthIndex, this.startMonthIndex + this.totalMonthsDisplayed);
    },
    prepareMonths: function prepareMonths(item) {
      var _this11 = this;
      var months = MONTHS_IN_YEAR;
      var result = {};
      this.allYears.map(function (year) {
        result[year] = Array(months).fill().map(function (month, index) {
          return {
            item: _this11.cloneControlData(item),
            // Default item is the pending control
            month: ++index,
            year: year,
            status: _this11.controlStatusesUI.NONE
          };
        });
      });

      // Populate completed items
      if (item.history && item.history.length) {
        this.allYears.map(function (year) {
          _this11.populateCompleted(item, result[year], year);
        });
      }

      // Populate future items
      if (item.frequencyInfo && item.frequencyInfo.futureDeadlines) {
        this.allYears.map(function (year) {
          var deadlines = item.frequencyInfo.futureDeadlines.filter(function (item) {
            return item.indexOf(year + '') === 0;
          });
          if (deadlines.length) {
            _this11.populateFutureDeadlines(deadlines, result[year]);
          }
        });
      }

      // Populate pending control/item
      var yearCurrentDeadline = formatTimeUtc(item.deadline, DateFormats.DATE_YEAR_FORMAT);
      var currentPendingControl = this.findCurrentItemByMonthAndYearInUTC(result[yearCurrentDeadline], item.deadline);
      if (currentPendingControl) {
        currentPendingControl.item.deadline = item.deadline.substring(0, 10);
        if (item.completed === null) {
          currentPendingControl.status = this.controlStatusesUI.NOT_COMPLETED_YET;
        } else {
          isDateBefore(currentPendingControl.completed, item.deadline) ? this.completedBefore(currentPendingControl) : this.notCompletedBefore(currentPendingControl);
        }
      }
      return this.prepareAllMonths(item, result);
    },
    populateCompleted: function populateCompleted(item, data, year) {
      var _this12 = this;
      item.history.map(function (historyItem) {
        var yearHistoryDeadline = formatTimeUtc(historyItem.deadline, DateFormats.DATE_YEAR_FORMAT);
        var currentMonth = _this12.findCurrentItemByMonthAndYearInUTC(data, historyItem.deadline);
        if (yearHistoryDeadline == year && currentMonth) {
          _this12.updateCompletedStatus(historyItem, currentMonth);
        }
      });
    },
    updateCompletedStatus: function updateCompletedStatus(historyItem, currentMonth) {
      // Default
      currentMonth.status = this.controlStatusesUI.NOT_COMPLETED_YET;
      // Completed control data
      currentMonth.item = historyItem;
      if (currentMonth.item.rismaState === RismaControlsUtils.states.STATE_NOT_PERFORMED || currentMonth.item.rismaState === RismaControlsUtils.states.STATE_REVIEW_NOT_PERFORMED) {
        currentMonth.status = this.controlStatusesUI.NOT_PERFORMED;
      } else {
        isDateBefore(currentMonth.item.completed, currentMonth.item.deadline) ? this.completedBefore(currentMonth) : this.notCompletedBefore(currentMonth);
      }
    },
    completedBefore: function completedBefore(item) {
      item.status = this.controlStatusesUI.COMPLETED_BEFORE;
    },
    notCompletedBefore: function notCompletedBefore(item) {
      item.status = this.controlStatusesUI.NOT_COMPLETED_BEFORE;
    },
    populateFutureDeadlines: function populateFutureDeadlines(futureDeadlines, data) {
      var _this13 = this;
      futureDeadlines.map(function (futureItem) {
        var currentMonth = _this13.findCurrentItemByMonthAndYearInUTC(data, futureItem);
        if (currentMonth) {
          currentMonth.item.deadline = futureItem.substring(0, 10);
          currentMonth.status = _this13.controlStatusesUI.NOT_COMPLETED_YET;
        }
      });
    },
    findCurrentItemByMonthAndYearInUTC: function findCurrentItemByMonthAndYearInUTC(list, date) {
      var month = formatTimeUtc(date, DateFormats.DATE_FORMAT_M);
      var year = formatTimeUtc(date, DateFormats.DATE_YEAR_FORMAT);
      return this.findCurrentItemByMonthAndYear(list, month, year);
    },
    findCurrentItemByMonthAndYear: function findCurrentItemByMonthAndYear(data, month, year) {
      if (!data) {
        return null;
      }
      return data.find(function (item) {
        return item.month == month && item.year == year;
      });
    },
    cloneControlData: function cloneControlData(item) {
      return {
        id: item.id,
        cno: item.cno,
        cpath: item.cpath,
        title: item.title,
        deadline: item.deadline,
        completed: item.completed,
        rismaState: item.rismaState
      };
    },
    getMonthEntryTitle: function getMonthEntryTitle(month) {
      if (!month.item) {
        return '';
      }
      var deadLine = month.item.deadline.substring(0, 10);
      var completed = month.item.completed ? ' Completed=' + month.item.completed.substring(0, 10) : '';
      if (month.status === 'not-performed') {
        return month.item.cpath + ' ' + month.item.title + ' (' + month.item.id + ')\nNot performed! Deadline=' + deadLine;
      }
      return month.item.cpath + ' ' + month.item.title + ' (' + month.item.id + ')\nDeadline=' + deadLine + completed;
    },
    searchByEntity: function searchByEntity(item) {
      return item.toLowerCase().trim().indexOf(this.filters.search.toLowerCase().trim()) !== -1;
    },
    resetFilters: function resetFilters() {
      this.filters = {
        organisations: _toConsumableArray(this.organisations),
        tags: [],
        mother: null,
        responsible: [],
        search: '',
        showOverviewOptions: true,
        processes: []
      };
    },
    yearsToDisplaySelected: function yearsToDisplaySelected(years) {
      this.yearsToDisplay = years;
      if (this.isOverYearLimit(years - 1)) this.moveToEndOfTimeline();
    },
    isOverYearLimit: function isOverYearLimit(additionalYears) {
      var nextDisplayedYear = this.firstYearOnTimeline + additionalYears + Math.ceil(this.firstMonthOnTimeline / 12);
      return nextDisplayedYear > this.lastYearOnTimeline;
    },
    moveToEndOfTimeline: function moveToEndOfTimeline() {
      this.firstMonthOnTimeline = 0;
      this.firstYearOnTimeline = this.lastYearOnTimeline - this.yearsToDisplay + 1;
    },
    getItemHoverTitle: function getItemHoverTitle(item) {
      return DatatableRenderer.getItemHoverTitle(item);
    }
  }
};