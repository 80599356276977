var riskLevels = [{
  className: 'grey',
  min: 0,
  max: 0
}, {
  className: 'green',
  min: 1,
  max: 5
}, {
  className: 'blue',
  min: 6,
  max: 14
}, {
  className: 'yellow',
  min: 15,
  max: 19
}, {
  className: 'red',
  min: 20,
  max: 25
}];
export default {
  name: 'RiskIndicator',
  props: {
    riskLevel: {
      required: false,
      type: Number,
      default: 0,
      note: 'A number indicating the current risk level',
      validator: function validator(value) {
        return value >= riskLevels[0].min && value <= riskLevels[riskLevels.length - 1].max;
      }
    }
  },
  data: function data() {
    return {
      riskValues: [{
        label: '0',
        value: '0'
      }, {
        label: '1',
        value: '1'
      }, {
        label: '2',
        value: '2'
      }, {
        label: '3',
        value: '3'
      }, {
        label: '4',
        value: '4'
      }, {
        label: '5',
        value: '5'
      }]
    };
  },
  computed: {
    riskIndicatorClassName: function riskIndicatorClassName() {
      var _this = this;
      return riskLevels.find(function (level) {
        return _this.riskLevel >= level.min && _this.riskLevel <= level.max;
      }).className;
    }
  }
};