import i18n from '@/i18n';
import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
export default {
  name: 'GapSchemasTabs',
  components: {
    LightTabItem: LightTabItem
  },
  data: function data() {
    return {
      translate: {
        articles: i18n.t('Articles'),
        attributes: i18n.t('Attributes'),
        changelog: i18n.t('Changelog')
      }
    };
  }
};