export var VAT_STATUSES = {
  UNKNOWN: {
    value: 0,
    label: 'Unknown'
  },
  NORMAL: {
    value: 1,
    label: 'Normal'
  },
  UNDER_BANKRUPTCY: {
    value: 2,
    label: 'Under bankruptcy'
  },
  DURING_TERMINATION: {
    value: 3,
    label: 'During termination'
  },
  BANKRUPT: {
    value: 4,
    label: 'Bankrupt'
  },
  TERMINATED: {
    value: 5,
    label: 'Terminated'
  }
};
export var vatStatusLabelByValue = Object.fromEntries(Object.values(VAT_STATUSES).map(function (_ref) {
  var value = _ref.value,
    label = _ref.label;
  return [value, label];
}));