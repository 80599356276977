import VerticalMenu from '@/components/Molecules/VerticalMenu/VerticalMenu';
export default {
  name: '',
  components: {
    VerticalMenu: VerticalMenu
  },
  data: function data() {
    return {
      links: [{
        path: '/dev/kitchensink/atoms',
        title: 'Atoms',
        color: '#000'
      }, {
        path: '/dev/kitchensink/molecules',
        title: 'Molecules',
        color: 'green'
      }, {
        path: '/dev/kitchensink/organisms',
        title: 'Organisms'
      }]
    };
  }
};