import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0b365e86"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "select-wrapper"
};
var _hoisted_2 = {
  class: "select-item"
};
var _hoisted_3 = {
  class: "select-item"
};
var _hoisted_4 = {
  class: "select-item"
};
var _hoisted_5 = {
  class: "select-mode"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_switch = _resolveComponent("risma-switch");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.questionnaireTypes !== null ? (_openBlock(), _createBlock(_component_single_select, {
    key: 0,
    modelValue: $options.selectedQuestionnaireTypeId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $options.selectedQuestionnaireTypeId = $event;
    }),
    title: $data.translate.chooseQuestionnaireType,
    options: $props.questionnaireTypes,
    placeholder: $data.translate.chooseQuestionnaireType
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_3, [$props.questionnaires !== null ? (_openBlock(), _createBlock(_component_single_select, {
    key: 0,
    modelValue: $options.selectedQuestionnaireId,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $options.selectedQuestionnaireId = $event;
    }),
    title: $data.translate.chooseQuestionnaire,
    options: $props.questionnaires,
    placeholder: $data.translate.chooseQuestionnaire
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_4, [$props.gapSchemas !== null ? (_openBlock(), _createBlock(_component_single_select, {
    key: 0,
    modelValue: $options.selectedGapSchemaId,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $options.selectedGapSchemaId = $event;
    }),
    title: $data.translate.chooseGapSchema,
    options: $props.gapSchemas,
    placeholder: $data.translate.chooseGapSchema
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.mode,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_switch, {
    modelValue: $data.textShown,
    "onUpdate:modelValue": [_cache[3] || (_cache[3] = function ($event) {
      return $data.textShown = $event;
    }), _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('update:showText', $event);
    })],
    "is-labels-show": true,
    options: $options.switchOptions
  }, null, 8 /* PROPS */, ["modelValue", "options"])])]);
}