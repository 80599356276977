import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_preview_list_tile = _resolveComponent("preview-list-tile");
  var _component_preview_list_expanded = _resolveComponent("preview-list-expanded");
  var _component_compact_card = _resolveComponent("compact-card");
  var _component_preview_list = _resolveComponent("preview-list");
  return _openBlock(), _createElementBlock("div", null, [$props.viewType === $data.viewTypes.TILES ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_preview_list_tile, {
    activities: $props.activities,
    title: $props.groupLabel,
    "watermark-image": "url('/assets/svg/ikon_controls.svg')",
    onReloadRootPage: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('reloadRootPage');
    }),
    onUpdateFromPreviewMode: $options.onUpdateFromPreviewMode,
    onOnCompleteControl: $options.onCompleteControl,
    onOnDismissModal: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('dismissModal');
    })
  }, null, 8 /* PROPS */, ["activities", "title", "onUpdateFromPreviewMode", "onOnCompleteControl"])])) : _createCommentVNode("v-if", true), $props.viewType === $data.viewTypes.EXPANDED ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_preview_list_expanded, {
    activities: $props.activities,
    title: $props.groupLabel,
    users: _ctx.users,
    "header-offset": "0",
    organisations: $props.filteredOrganisations,
    "additional-fields": $props.additionalFields,
    "watermark-image": "url('/assets/svg/ikon_controls.svg')",
    "cookie-hidden-name": "rismahider-controls-list-expanded".concat($props.cookieHiddenKey),
    onUpdateFromPreviewMode: $options.onUpdateFromPreviewMode,
    onOnCompleteControl: $options.onCompleteControl,
    onOnDismissModal: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('dismissModal');
    })
  }, null, 8 /* PROPS */, ["activities", "title", "users", "organisations", "additional-fields", "cookie-hidden-name", "onUpdateFromPreviewMode", "onOnCompleteControl"])])) : _createCommentVNode("v-if", true), $props.viewType === $data.viewTypes.COMPACT ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_preview_list, {
    length: $props.activities.length || '0',
    title: $props.groupLabel,
    "cookie-hidden-name": "rismahider-controls-list-compact".concat($props.cookieHiddenKey)
  }, {
    default: _withCtx(function () {
      return [$props.activities.length ? (_openBlock(), _createBlock(_component_compact_card, {
        key: 0,
        module: $data.MODULE_NAME,
        activities: $props.activities,
        users: _ctx.users,
        "exclude-activity-visible-props": ['_custom-button'],
        organisations: $props.filteredOrganisations,
        "is-mother-controls": $props.isMotherControls,
        "visibility-storage-name": "control_hidden_columns_".concat($props.cookieHiddenKey),
        "watermark-image": "url('/assets/svg/ikon_controls.svg')",
        onUpdateFromPreviewMode: $options.onUpdateFromPreviewMode,
        onOnCompleteControl: $options.onCompleteControl,
        onOnDismissModal: _cache[3] || (_cache[3] = function ($event) {
          return _ctx.$emit('dismissModal');
        })
      }, null, 8 /* PROPS */, ["module", "activities", "users", "organisations", "is-mother-controls", "visibility-storage-name", "onUpdateFromPreviewMode", "onOnCompleteControl"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["length", "title", "cookie-hidden-name"])])) : _createCommentVNode("v-if", true)]);
}