function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { Colors } from '@/Colors';
import { TabTypes } from '@/constants/Tabs';
import { useMainStore } from '@/Store/mainStore';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
export default {
  name: 'ActionGap',
  description: 'This is a preview component for links page - gap tab',
  components: {
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight,
    PreviewModalLink: PreviewModalLink
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: function _default() {}
    },
    gapList: {
      requred: true,
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      Colors: Colors,
      TabTypes: TabTypes,
      translate: getTranslate['ActionGap']()
    };
  },
  computed: {
    linkToNodeGapTab: function linkToNodeGapTab() {
      return "/compliance/".concat(this.data.projectId, "/edit/").concat(this.data.nodeId, "/gap/");
    },
    enhancedGapList: function enhancedGapList() {
      var _this = this;
      return this.gapList.map(function (gap) {
        return _objectSpread(_objectSpread({}, gap), {}, {
          url: _this.linkToNodeGapTab + gap.articleId,
          compliance_projects_id: _this.data.projectId,
          route: {
            tab: TabTypes.GAPANALYSIS,
            id: gap.articleId
          }
        });
      });
    }
  },
  methods: {
    generateTargetRoute: function generateTargetRoute(articleId) {
      return {
        path: this.linkToNodeGapTab + articleId,
        force: true
      };
    },
    onLinkClick: function onLinkClick(articleId) {
      if ("".concat(this.linkToNodeGapTab).concat(articleId).startsWith(this.$router.currentRoute.value.fullPath)) {
        useMainStore().closeAllPreviews();
      }
    }
  }
};