import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "font-semibold"
};
var _hoisted_3 = {
  key: 1
};
var _hoisted_4 = {
  class: "flex justify-between mb-1"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
  key: 1
};
var _hoisted_7 = ["onClick"];
var _hoisted_8 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_notification_pop_up = _resolveComponent("notification-pop-up");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  var _component_main_well = _resolveComponent("main-well");
  var _component_document_type_modal = _resolveComponent("document-type-modal");
  var _component_alert = _resolveComponent("alert");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: $data.notificationType,
    onDismiss: $options.resetNotification
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.notificationMessage), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type", "onDismiss"])) : _createCommentVNode("v-if", true), _ctx.hasNotificationPopUp ? (_openBlock(), _createBlock(_component_notification_pop_up, {
    key: 1,
    type: _ctx.notificationData.type,
    onDismiss: _ctx.dismissNotificationPopUp
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.notificationData.header), 1 /* TEXT */), _createElementVNode("div", null, _toDisplayString(_ctx.notificationData.message), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type", "onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.documentTypesAdministration,
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
        title: $data.translate.documentTypes
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
        icon: "plus",
        height: "35",
        width: "35",
        class: "stroke-3 text-blue-350 cursor-pointer",
        onClick: $options.toggleCreateModal
      }, null, 8 /* PROPS */, ["onClick"])]), _createVNode(_component_static_table, {
        columns: $options.columns,
        dataset: $options.dataset
      }, {
        edit: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [slotData.item !== null ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "focus:outline-none mr-5",
            onClick: function onClick($event) {
              return $options.toggleEditModal(slotData.item);
            }
          }, [_createVNode(_component_feather_icon, {
            icon: "edit",
            class: "stroke-2 text-blue-350",
            title: $data.translate.edit
          }, null, 8 /* PROPS */, ["title"])], 8 /* PROPS */, _hoisted_5)) : (_openBlock(), _createElementBlock("span", _hoisted_6))];
        }),
        delete: _withCtx(function (_ref2) {
          var slotData = _ref2.slotData;
          return [slotData.id !== null ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "focus:outline-none mr-5",
            onClick: function onClick($event) {
              return $options.toggleDeleteModal(slotData.id, slotData.name);
            }
          }, [_createVNode(_component_feather_icon, {
            class: "text-red-50 stroke-2 cursor-pointer",
            icon: "x",
            title: $data.translate.delete
          }, null, 8 /* PROPS */, ["title"])], 8 /* PROPS */, _hoisted_7)) : (_openBlock(), _createElementBlock("span", _hoisted_8))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["columns", "dataset"])]))])];
    }),
    _: 1 /* STABLE */
  }), $data.showDocTypeModal ? (_openBlock(), _createBlock(_component_document_type_modal, {
    key: 2,
    "document-type": _ctx.docTypeSelected,
    "all-document-types": $data.documentTypes,
    onAccept: $options.acceptDocTypeModal,
    onDismiss: $options.dismissModal
  }, null, 8 /* PROPS */, ["document-type", "all-document-types", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), $data.docTypeIdToDelete ? (_openBlock(), _createBlock(_component_alert, {
    key: 3,
    body: "".concat($data.translate.areYouSureYouWantToDelete, " ").concat($data.docTypeNameToDelete, "?"),
    header: $data.translate.deleteDocumentType,
    "button-ok-text": $data.translate.delete,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.handleAcceptAlert,
    onDismiss: $options.dismissAlert
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true)]);
}