import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4e562c4c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 1,
  class: "flex flex-wrap -mx-6"
};
var _hoisted_2 = {
  key: 0,
  class: "w-1/2 mb-3 px-6"
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_controls_dashboard_filters = _resolveComponent("controls-dashboard-filters");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_doughnut_chart = _resolveComponent("risma-doughnut-chart");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_router_link = _resolveComponent("router-link");
  var _component_paginated_table = _resolveComponent("paginated-table");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.completion,
    type: "big",
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_controls_dashboard_filters, {
    "local-storage-key": "controls_dashboard_completion",
    onApplyFilters: $options.applyFilters,
    onApplyFiltersOnLoad: $options.getData
  }, null, 8 /* PROPS */, ["onApplyFilters", "onApplyFiltersOnLoad"]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.charts, function (chartKey, i) {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: "chart-".concat(i)
    }, [$data.datasets[chartKey] ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
      title: _ctx.$trans($data.datasets[chartKey].title),
      class: "mb-4"
    }, null, 8 /* PROPS */, ["title"]), $data.datasets[chartKey].empty ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($data.translate.noResultsFoundYouMayWantToWidenYourSearc), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createVNode(_component_risma_doughnut_chart, {
      datasets: $data.datasets[chartKey].datasets,
      labels: $data.datasets[chartKey].labels,
      "show-data-labels": true,
      "percentage-value": false,
      onChartClicked: function onChartClicked($event) {
        return $options.clickSlice(chartKey, $event);
      }
    }, null, 8 /* PROPS */, ["datasets", "labels", "onChartClicked"]), _createVNode(_Transition, {
      name: "fade"
    }, {
      default: _withCtx(function () {
        return [$data.pieChartsStaticTable[chartKey] ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("strong", null, _toDisplayString($data.translate.controls) + ": " + _toDisplayString(_ctx.$trans($data.pieChartsStaticTable[chartKey].title)), 1 /* TEXT */), (_openBlock(), _createBlock(_component_paginated_table, {
          key: $data.pieChartsStaticTable[chartKey].title,
          columns: $data.pieChartsStaticTable[chartKey].table.columns,
          dataset: $data.pieChartsStaticTable[chartKey].table.dataset,
          "page-size": $options.getPieChartTableSize($data.pieChartsStaticTable[chartKey].table.dataset.length)
        }, {
          text: _withCtx(function (_ref) {
            var slotData = _ref.slotData;
            return [_createVNode(_component_router_link, {
              to: slotData.url || ''
            }, {
              default: _withCtx(function () {
                return [_createVNode(_component_risma_title_with_icon, {
                  icon: "confidential",
                  title: slotData.title,
                  "title-attribute": slotData.titleParam,
                  "title-is-raw-html": true,
                  "display-icon": slotData.confidential
                }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
              }),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["columns", "dataset", "page-size"]))])) : _createCommentVNode("v-if", true)];
      }),
      _: 2 /* DYNAMIC */
    }, 1024 /* DYNAMIC_SLOTS */)])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
  }), 128 /* KEYED_FRAGMENT */))]))]);
}