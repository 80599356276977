import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mb-6 p-6 border border-gray-350"
};
var _hoisted_2 = {
  class: "mb-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_company_generic_table = _resolveComponent("company-generic-table");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_input_field, {
    modelValue: $data.searchField,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.searchField = $event;
    }),
    placeholder: $data.translate.search,
    type: "text",
    class: "w-350px ml-auto mb-8"
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.myCompanies
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_2, _toDisplayString($data.translate.aListOfAllCompaniesWhichArePartOfYourCon), 1 /* TEXT */), _createVNode(_component_company_generic_table, {
    companies: $options.filteredCompanies,
    onUpdate: $options.updateFromPreviewMode,
    onDismiss: $options.dismissModal
  }, null, 8 /* PROPS */, ["companies", "onUpdate", "onDismiss"])])]);
}