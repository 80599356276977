import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-76b37fec"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "highlight"
};
var _hoisted_2 = {
  key: 0,
  class: "codeDesc"
};
var _hoisted_3 = ["data-language"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.desc ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.desc), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("pre", {
    "data-language": $props.type
  }, [_createElementVNode("code", null, _toDisplayString($props.code), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_3)]);
}