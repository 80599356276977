function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { Colors } from '@/Colors';
export default {
  name: 'ControlsAccountsTree',
  description: 'Component for AdminControls to display the account tree',
  token: '<controls-accounts-list :list="[]"></controls-accounts-list>',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    list: {
      type: Array,
      required: true,
      note: 'list of accounts'
    }
  },
  emits: ['editAccount', 'deleteAccount'],
  data: function data() {
    return {
      Colors: Colors
    };
  },
  computed: {
    preparedList: function preparedList() {
      var _this = this;
      return this.list.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          isInUse: _this.isItemInUse(item)
        });
      });
    }
  },
  methods: {
    isItemInUse: function isItemInUse(account) {
      var _this2 = this;
      if (account.inUse) return true;
      var value = false;
      account.children.forEach(function (item) {
        if (_this2.isItemInUse(item)) {
          value = true;
          return;
        }
      });
      return value;
    }
  }
};