import PDFDocument from '@/components/Atoms/PDFDocument/PDFDocument';
import Modal from '@/components/Molecules/Modal/Modal';
import { Colors } from '@/Colors';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { FileFormats } from '@/constants/FileFormats';
export default {
  name: 'FilePreviewModal',
  components: {
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    PDFDocument: PDFDocument
  },
  props: {
    fileSrc: {
      type: String,
      required: true
    },
    fileSrcType: {
      type: String,
      required: true,
      validator: function validator(val) {
        return [FileFormats.image, FileFormats.pdf].includes(val);
      }
    },
    downloadable: {
      type: Boolean,
      required: false,
      default: true
    },
    pdfModalWidth: {
      type: String,
      required: false,
      default: '40vw'
    },
    pdfModalHeight: {
      type: String,
      required: false,
      default: '30vw'
    }
  },
  emits: ['dismiss'],
  data: function data() {
    return {
      colors: Colors,
      translate: {
        previewFile: this.$trans('Preview file')
      }
    };
  },
  computed: {
    isPDF: function isPDF() {
      return this.fileSrcType === 'pdf';
    },
    isImage: function isImage() {
      return this.fileSrcType === 'image';
    }
  }
};