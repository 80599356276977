import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withKeys as _withKeys, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5f5f3d21"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "input-field"
};
var _hoisted_2 = ["id", "type", "placeholder", "required", "disabled", "maxlength", "max", "min", "autocomplete", "step", "tabindex", "accept"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _directive_focus = _resolveDirective("focus");
  return _openBlock(), _createElementBlock("div", null, [$props.title ? (_openBlock(), _createBlock(_component_risma_label, {
    key: 0,
    title: $props.title,
    for: $options.id
  }, null, 8 /* PROPS */, ["title", "for"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_withDirectives(_createElementVNode("input", {
    id: $options.id,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.rawText = $event;
    }),
    type: $props.type,
    placeholder: $props.placeholder,
    required: $props.required,
    disabled: !$props.enabled,
    maxlength: $props.maxlength,
    max: $props.max,
    min: $props.min,
    autocomplete: $props.autocomplete,
    step: $props.step,
    class: _normalizeClass([$options.baseStyles, $options.interactionStyles]),
    style: _normalizeStyle($props.externalStyles),
    tabindex: $props.tabindex,
    accept: $props.accept,
    onKeypress: _cache[1] || (_cache[1] = function () {
      return $options.validateNumber && $options.validateNumber.apply($options, arguments);
    }),
    onInput: _cache[2] || (_cache[2] = function () {
      return $options.emit && $options.emit.apply($options, arguments);
    }),
    onFocus: _cache[3] || (_cache[3] = function () {
      return $options.onFocus && $options.onFocus.apply($options, arguments);
    }),
    onClick: _cache[4] || (_cache[4] = function () {
      return $options.onClick && $options.onClick.apply($options, arguments);
    }),
    onBlur: _cache[5] || (_cache[5] = function () {
      return $options.onBlur && $options.onBlur.apply($options, arguments);
    }),
    onKeyup: _cache[6] || (_cache[6] = _withKeys(function () {
      return $options.onEnter && $options.onEnter.apply($options, arguments);
    }, ["enter"]))
  }, null, 46 /* CLASS, STYLE, PROPS, HYDRATE_EVENTS */, _hoisted_2), [[_vModelDynamic, $data.rawText, void 0, {
    trim: true
  }], [_directive_focus, $props.focusOnMount]])])]);
}