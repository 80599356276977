function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { cacheItem } from '@/routes/storeCache';
import { patchUtilSettings } from '@/api/risma/settings';
import { editActivityType } from '@/api/risma/activityTypes';
import { getAllQuestionnaires } from '@/api/compliance/questionnaire';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import { MODULES } from '@/constants/modules';
import { QuestionnaireTypes } from '@/constants/compliance/QuestionnaireTypes';
export default {
  name: 'ComplianceAssetsSettings',
  description: 'Page with compliance assets settings',
  token: '<compliance-assets-settings />',
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    RismaButton: RismaButton,
    Notification: Notification
  },
  data: function data() {
    return {
      data: {
        'feature.systems_default_questionnaire_id': null,
        'feature.controllers_default_questionnaire_id': null,
        'feature.processors_default_questionnaire_id': null,
        'feature.systems_default_activity_type_id': null,
        'feature.data_controllers_default_activity_type_id': null,
        'feature.data_processors_default_activity_type_id': null
      },
      questionnaires: [],
      questionnaireTypes: [],
      isNotificationShow: false,
      notificationType: 'error',
      notificationMessage: '',
      translate: getTranslate['ComplianceAssetsSettings']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), mapState(useActivityTypesStore, {
    customInformationAssetsModules: 'complianceIACustomActivityTypes',
    getActivityTypesByModule: 'getActivityTypesByModule'
  })), {}, {
    settingsData: function settingsData() {
      return [{
        name: 'System',
        questionnaireId: 'feature.systems_default_questionnaire_id',
        questionnairesOptions: this.systemQuestionnaireOptions,
        defaultActivityType: 'feature.systems_default_activity_type_id',
        activityTypeOptions: this.getActivityTypeOptions(this.getActivityTypesByModule(MODULES.COMPLIANCE_IA_SYSTEM))
      }, {
        name: 'Controllers',
        questionnaireId: 'feature.controllers_default_questionnaire_id',
        questionnairesOptions: this.controllersQuestionnaireOptions,
        defaultActivityType: 'feature.data_controllers_default_activity_type_id',
        activityTypeOptions: this.getActivityTypeOptions(this.getActivityTypesByModule(MODULES.COMPLIANCE_IA_CONTROLLER))
      }, {
        name: 'Processors',
        questionnaireId: 'feature.processors_default_questionnaire_id',
        questionnairesOptions: this.processorsQuestionnaireOptions,
        defaultActivityType: 'feature.data_processors_default_activity_type_id',
        activityTypeOptions: this.getActivityTypeOptions(this.getActivityTypesByModule(MODULES.COMPLIANCE_IA_PROCESSOR))
      }];
    },
    availableQuestionnaires: function availableQuestionnaires() {
      return this.questionnaires.filter(function (questionnaire) {
        return questionnaire.selectable;
      });
    },
    systemQuestionnaireOptions: function systemQuestionnaireOptions() {
      return this.availableQuestionnaires.filter(function (questionnaire) {
        return questionnaire.questionnaireType === QuestionnaireTypes.SYSTEM;
      }).map(function (questionnaire) {
        return {
          value: questionnaire.id,
          label: questionnaire.title
        };
      });
    },
    controllersQuestionnaireOptions: function controllersQuestionnaireOptions() {
      return this.availableQuestionnaires.filter(function (questionnaire) {
        return questionnaire.questionnaireType === QuestionnaireTypes.DATACONTROLLER;
      }).map(function (questionnaire) {
        return {
          value: questionnaire.id,
          label: questionnaire.title
        };
      });
    },
    processorsQuestionnaireOptions: function processorsQuestionnaireOptions() {
      return this.availableQuestionnaires.filter(function (questionnaire) {
        return questionnaire.questionnaireType === QuestionnaireTypes.DATAPROCESSOR || questionnaire.questionnaireType === QuestionnaireTypes.SUBPROCESSOR;
      }).map(function (questionnaire) {
        return {
          value: questionnaire.id,
          label: questionnaire.title
        };
      });
    },
    customQuestionnaireOptions: function customQuestionnaireOptions() {
      return this.availableQuestionnaires.filter(function (questionnaire) {
        return questionnaire.questionnaireType === QuestionnaireTypes.IA_CUSTOM;
      }).map(function (questionnaire) {
        return {
          value: questionnaire.id,
          label: questionnaire.title
        };
      });
    }
  }),
  mounted: function mounted() {
    this.getValuesFromSettings();
    this.getQuestionnaires();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useSettingsStore, {
    loadSettings: 'load'
  })), {}, {
    getQuestionnaires: function getQuestionnaires() {
      var _this = this;
      return getAllQuestionnaires().then(function (_ref) {
        var list = _ref.list;
        return _this.questionnaires = list;
      });
    },
    getValuesFromSettings: function getValuesFromSettings() {
      var _this2 = this;
      Object.keys(this.data).forEach(function (key) {
        return _this2.addSettingToData(_this2.getSettingValue(key), key);
      });
    },
    getActivityTypeOptions: function getActivityTypeOptions(activityTypes) {
      return activityTypes.map(function (item) {
        return {
          value: item.id,
          label: item.label
        };
      });
    },
    addSettingToData: function addSettingToData(value, name) {
      if (value === undefined) return;
      this.data[name] = value;
    },
    saveSettings: function saveSettings() {
      var _this3 = this;
      return Promise.all([this.patchSettings(), this.saveSettingsForCustomIA()]).then(function () {
        _this3.handleShowNotify({
          type: 'success',
          message: _this3.translate.settingsAreSaved
        });
      }).catch(function (error) {
        _this3.handleShowNotify({
          type: 'error',
          message: _this3.$trans(error.response.error)
        });
      });
    },
    patchSettings: function patchSettings() {
      var _this4 = this;
      return patchUtilSettings(this.data).then(function () {
        return cacheItem('settings', function () {
          return _this4.loadSettings();
        }, 180);
      });
    },
    saveSettingsForCustomIA: function saveSettingsForCustomIA() {
      var promises = [];
      this.customInformationAssetsModules.forEach(function (module) {
        var data = {
          defaultQuestionnaireId: module.defaultQuestionnaireId
        };
        promises.push(editActivityType(module.id, data));
      });
      return Promise.all(promises);
    },
    handleShowNotify: function handleShowNotify(_ref2) {
      var _this5 = this;
      var type = _ref2.type,
        message = _ref2.message;
      this.notificationType = type;
      this.notificationMessage = message;
      this.isNotificationShow = true;
      setTimeout(function () {
        _this5.handleDismissNotification();
      }, 3000);
    },
    handleDismissNotification: function handleDismissNotification() {
      this.notificationMessage = '';
      this.isNotificationShow = false;
    }
  })
};