import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-104c437f"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "card card-execution"
};
var _hoisted_2 = {
  class: "trafficChangeWrap"
};
var _hoisted_3 = {
  class: "trafficlight_placer"
};
var _hoisted_4 = {
  class: "header-placer truncate"
};
var _hoisted_5 = ["href", "title"];
var _hoisted_6 = {
  class: "subtitle"
};
var _hoisted_7 = {
  class: "card-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_trafficlight = _resolveComponent("trafficlight");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_progress_bar = _resolveComponent("risma-progress-bar");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_trafficlight, {
    color: $data.Colors.getTrafficlightByNumber($props.activity.traffic_light).color
  }, null, 8 /* PROPS */, ["color"])]), _createElementVNode("span", _hoisted_4, [_createElementVNode("a", {
    href: $props.activity.url,
    title: $props.activity.title
  }, [_createVNode(_component_risma_title, {
    title: $props.activity.ipath + '. ' + $props.activity.title
  }, null, 8 /* PROPS */, ["title"])], 8 /* PROPS */, _hoisted_5), _createElementVNode("span", _hoisted_6, _toDisplayString($data.translate.deadline) + ": " + _toDisplayString($props.activity.deadline), 1 /* TEXT */)])]), _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$truncate($props.activity.status, 100)), 1 /* TEXT */), $props.activity.completion_percentage ? (_openBlock(), _createBlock(_component_risma_progress_bar, {
    key: 0,
    value: $props.activity.completion_percentage,
    label: $props.activity.completion_percentage + '% ' + $data.translate.done
  }, null, 8 /* PROPS */, ["value", "label"])) : _createCommentVNode("v-if", true)])]);
}