import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getAll(module, module_id) {
  return handleRequestResponse(Http.get('riskassessments', {
    params: {
      module: module,
      moduleId: module_id
    }
  }));
}
export function create(params) {
  return handleRequestResponse(Http.post('riskassessments', params));
}
export function update(params) {
  return handleRequestResponse(Http.patch("riskassessments/".concat(params.id), params));
}
export function getCustomThreats(projectId, periodId, rno) {
  return handleRequestResponse(Http.get("/risks/projects/".concat(projectId, "/periods/").concat(periodId, "/risk/").concat(rno, "/assessments")));
}
export function createCustomThreat(projectId, periodId, rno, data) {
  return handleRequestResponse(Http.post("/risks/projects/".concat(projectId, "/periods/").concat(periodId, "/risk/").concat(rno, "/assessments"), data));
}
export function updateCustomThreat(projectId, periodId, rno, id, data) {
  return handleRequestResponse(Http.patch("/risks/projects/".concat(projectId, "/periods/").concat(periodId, "/risk/").concat(rno, "/assessments/").concat(id), data));
}