import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue";
var _hoisted_1 = {
  class: "mb-10 w-3/5"
};
var _hoisted_2 = {
  class: "flex items-center"
};
var _hoisted_3 = {
  class: "underline"
};
var _hoisted_4 = {
  key: 1
};
var _hoisted_5 = {
  key: 0,
  class: "w-full"
};
var _hoisted_6 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showConfirmationAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    header: $data.translate.deleteRiskPeriod,
    body: $data.translate.areYouSureYouWantToDeleteThisRiskPeriod,
    "alert-type": "confirmation",
    onAccept: $options.onDeleteConfirm,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showConfirmationAlert = false;
    })
  }, null, 8 /* PROPS */, ["header", "body", "onAccept"])) : _createCommentVNode("v-if", true), _createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
        title: $options.entityMainTitle,
        type: "big"
      }, null, 8 /* PROPS */, ["title"]), !$options.isLocked ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: $options.prepareDetailUrl('new'),
        class: "flex items-center ml-auto"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_feather_icon, {
            class: "text-blue-750",
            icon: "plus"
          }), _createElementVNode("span", _hoisted_3, _toDisplayString($data.translate.createNewPeriod), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"])) : (_openBlock(), _createElementBlock("div", _hoisted_4))]), $options.dataset.length ? (_openBlock(), _createElementBlock("table", _hoisted_5, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.columns, function (field, key) {
        return _openBlock(), _createElementBlock("th", {
          key: key,
          class: _normalizeClass($data.cellCssStyles)
        }, [_createVNode(_component_risma_title, {
          title: field,
          type: "medium"
        }, null, 8 /* PROPS */, ["title"])], 2 /* CLASS */);
      }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("th", {
        class: _normalizeClass($data.cellCssStyles)
      }, null, 2 /* CLASS */)])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.dataset, function (field) {
        return _openBlock(), _createElementBlock("tr", {
          key: field.id
        }, [_createElementVNode("td", {
          class: _normalizeClass($data.cellCssStyles)
        }, [_createVNode(_component_router_link, {
          to: field.url
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(field.title), 1 /* TEXT */)];
          }),

          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])], 2 /* CLASS */), _createElementVNode("td", {
          class: _normalizeClass($data.cellCssStyles)
        }, _toDisplayString(field.risks), 3 /* TEXT, CLASS */), _createElementVNode("td", {
          class: _normalizeClass($data.cellCssStyles)
        }, _toDisplayString(field.currentState), 3 /* TEXT, CLASS */), _createElementVNode("td", {
          class: _normalizeClass($data.cellCssStyles)
        }, [!$options.isLocked && field.status === 0 ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: "#",
          onClick: _withModifiers(function ($event) {
            return $options.deleteEntity(field.id);
          }, ["prevent"])
        }, [_createVNode(_component_feather_icon, {
          class: "text-red-50 stroke-2",
          icon: "x"
        })], 8 /* PROPS */, _hoisted_6)) : _createCommentVNode("v-if", true)], 2 /* CLASS */)]);
      }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })]);
}