function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import InputField from '@/components/Atoms/Inputs/InputField';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import QuestionnaireStats from '@/components/Molecules/Compliance/QuestionnaireStats';
import LinkedCompany from '@/components/Molecules/LinkedCompany/LinkedCompany';
import LinkCompany from '@/components/Molecules/LinkedCompany/LinkCompany';
import RiskAssessmentsIsms from '@/components/Organisms/Risk/RiskAssessmentsIsms';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RiskGrid from '@/components/Organisms/RiskGrid';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { ActivityTypeMixin } from '@/mixins/ActivityTypeMixin';
import { LinkedCompanyMixin } from '@/mixins/LinkedCompanyMixin';
import { CustomFieldsMixin } from '@/mixins/CustomFieldsMixin';
import { RiskAssessmentMixin } from '@/mixins/RiskAssessmentMixin';
import InformationAssetsRiskGridMixin from '@/components/Pages/InformationAssets/InformationAssetsRiskGridMixin';
import { generateComputed } from '@/utils/base/generateComputed';
import { MODULES } from '@/constants/modules';
export default {
  name: 'InformationAssetsIADetails',
  components: {
    InputField: InputField,
    TinyMceBox: TinyMceBox,
    ActivityCustomFields: ActivityCustomFields,
    QuestionnaireStats: QuestionnaireStats,
    RiskAssessmentsIsms: RiskAssessmentsIsms,
    SingleSelect: SingleSelect,
    RiskGrid: RiskGrid,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    LinkedCompany: LinkedCompany,
    LinkCompany: LinkCompany
  },
  mixins: [ActivityTypeMixin, CustomFieldsMixin, RiskAssessmentMixin, InformationAssetsRiskGridMixin, LinkedCompanyMixin],
  props: {
    id: {
      required: true,
      type: [String, Number]
    },
    data: {
      type: Object,
      required: true
    }
  },
  emits: ['propertyChanged', 'companyStatusChange'],
  data: function data() {
    return {
      informationAssets: null,
      pageLoaded: false,
      module: MODULES.COMPLIANCE_INFORMATION_ASSETS,
      translate: getTranslate['InformationAssetsIADetails']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), generateComputed(['trafficLight', 'address', 'description', 'postcode', 'email', 'city', 'companyId'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    showRiskOnCustomIA: function showRiskOnCustomIA() {
      var _this$currentActivity;
      return (_this$currentActivity = this.currentActivityType) === null || _this$currentActivity === void 0 ? void 0 : _this$currentActivity.riskEnabled;
    },
    riskProjectId: function riskProjectId() {
      var _this$currentActivity2;
      return (_this$currentActivity2 = this.currentActivityType) === null || _this$currentActivity2 === void 0 ? void 0 : _this$currentActivity2.riskProjectId;
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      var promises = [this.getRisksData(), this.getCompany()];
      if (this.getSettingValue('feature.show_rto_rpo_on_assets')) {
        promises.push(this.getRtoFields(), this.getRpoFields());
      }
      return Promise.all(promises).then(function () {
        return _this.pageLoaded = true;
      });
    }
  }
};