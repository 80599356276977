import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "create-initiative-modal"
};
var _hoisted_2 = {
  class: "mb-3"
};
var _hoisted_3 = {
  key: 1,
  class: "mb-3"
};
var _hoisted_4 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_text_box = _resolveComponent("text-box");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_single_select = _resolveComponent("single-select");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.show ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $data.translate.createAction,
    "overflow-visible": true,
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.dismiss,
    "accept-on-enter": !$data.isRismaSelectOpen,
    "dismiss-on-click-outside": false,
    onDismiss: $options.handleDismiss,
    onAccept: $options.handleSelection
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.errors.length > 0 ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errors, function (error, key) {
            return _openBlock(), _createElementBlock("p", {
              key: key
            }, _toDisplayString(error), 1 /* TEXT */);
          }), 128 /* KEYED_FRAGMENT */))];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_input_field, {
        title: $data.translate.title,
        "pre-text": $props.preset.title,
        enabled: !$props.preset.title,
        "focus-on-mount": true,
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.initiativeItem.title = $event;
        })
      }, null, 8 /* PROPS */, ["title", "pre-text", "enabled"])]), $props.showAllFields ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
        title: $data.translate.description,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_text_box, {
        text: $data.initiativeItem.description || $props.preset.description,
        rows: 2,
        placeholder: $data.translate.description,
        class: "w-full",
        onUpdated: $options.setDescription
      }, null, 8 /* PROPS */, ["text", "placeholder", "onUpdated"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
        title: $data.translate.responsible,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
        options: $options.usersWithAccessToActions,
        "selected-options": $data.initiativeItem.responsible,
        "label-key": "display_name",
        onSelected: $options.setResponsible,
        onOpen: _cache[1] || (_cache[1] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[2] || (_cache[2] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["options", "selected-options", "onSelected"])]), _createElementVNode("div", null, [_createVNode(_component_single_select, {
        modelValue: $data.initiativeItem.parent,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.initiativeItem.parent = $event;
        }),
        title: $data.translate.parent,
        "un-sorted": true,
        options: $options.formattedInitiatives,
        "with-search": true
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "accept-on-enter", "onDismiss", "onAccept"])) : _createCommentVNode("v-if", true)]);
}