import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import { getData } from '@/test/data/tableData';
import { generateKey } from '@/utils/Utils';
export default {
  name: '',
  components: {
    RismaTable: RismaTable
  },
  data: function data() {
    return {
      query: '',
      numberOfCalls: 0,
      stateKey: 'kitchensink_table',
      tableGeneratedKey: generateKey()
    };
  },
  methods: {
    apiMock: function apiMock(params) {
      this.query = params;
      this.numberOfCalls++;
      return new Promise(function (resolve) {
        return resolve(getData(params));
      });
    },
    onDataStarred: function onDataStarred(_ref) {
      var id = _ref.id,
        starred = _ref.starred;
      alert('Item with id ' + id + ' should be starred:' + starred);
    },
    onIconClicked: function onIconClicked(_ref2) {
      var id = _ref2.id,
        data = _ref2.data;
      alert('Item with id ' + id + '. Data: ' + data);
    }
  }
};