import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-187f0ae2"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "preview-risk-list"
};
var _hoisted_2 = {
  class: "preview-risk-list-header"
};
var _hoisted_3 = {
  class: "preview-risk-list-header-view"
};
var _hoisted_4 = {
  class: "preview-risk-list-compact-header"
};
var _hoisted_5 = {
  class: "compact-header-title"
};
var _hoisted_6 = {
  class: "compact-header-description"
};
var _hoisted_7 = {
  class: "compact-header-organisations"
};
var _hoisted_8 = {
  class: "compact-header-tags"
};
var _hoisted_9 = {
  key: 0,
  class: "compact-header-tags"
};
var _hoisted_10 = {
  class: "preview-risk-list-body"
};
var _hoisted_11 = {
  key: 1,
  class: "preview-risk-list-empty"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_view_switcher = _resolveComponent("view-switcher");
  var _component_preview_risk = _resolveComponent("preview-risk");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.display,
    type: "medium",
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_view_switcher, {
    view: $setup.viewSelected,
    "onUpdate:view": _cache[0] || (_cache[0] = function ($event) {
      return $setup.viewSelected = $event;
    }),
    views: $data.STANDARD_VIEWS
  }, null, 8 /* PROPS */, ["view", "views"])])]), ($props.isErm ? $data.scoringLoaded : true) ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList($options.sortedSections, function (section, i) {
    var _section$listShown;
    return _openBlock(), _createElementBlock("div", {
      key: "section-".concat(i)
    }, [_createVNode(_component_risma_title, {
      title: section.title,
      class: "review-risk-list-section-title"
    }, null, 8 /* PROPS */, ["title"]), _withDirectives(_createElementVNode("div", _hoisted_4, [_createElementVNode("h4", _hoisted_5, _toDisplayString($data.translate.title), 1 /* TEXT */), _createElementVNode("h4", _hoisted_6, _toDisplayString($data.translate.description), 1 /* TEXT */), _createElementVNode("h4", _hoisted_7, _toDisplayString($data.translate.organisation), 1 /* TEXT */), _createElementVNode("h4", _hoisted_8, _toDisplayString($data.translate.tags), 1 /* TEXT */), $props.isApprovalFlowEnabled ? (_openBlock(), _createElementBlock("h4", _hoisted_9, _toDisplayString($data.translate.approvalStatus), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 512 /* NEED_PATCH */), [[_vShow, $options.showCompactHeader]]), _createElementVNode("div", _hoisted_10, [(_section$listShown = section.listShown) !== null && _section$listShown !== void 0 && _section$listShown.length ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 0
    }, _renderList(section.listShown, function (risk) {
      return _openBlock(), _createBlock(_component_preview_risk, {
        key: "preview-my-risk".concat(risk.id),
        risk: risk,
        scoring: $data.scoring,
        organisations: $props.organisations,
        mode: $setup.viewSelected,
        "is-risk2": !$props.isErm,
        "is-approval-flow-enabled": $props.isApprovalFlowEnabled,
        "is-show-linked-items": $props.isShowRiskLinkedItems,
        onUpdateFromPreviewMode: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('updateFromPreviewMode', $event);
        })
      }, null, 8 /* PROPS */, ["risk", "scoring", "organisations", "mode", "is-risk2", "is-approval-flow-enabled", "is-show-linked-items"]);
    }), 128 /* KEYED_FRAGMENT */)) : (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString($data.translate.thereAreNoRisksToDisplay), 1 /* TEXT */))])]);
  }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true)]);
}