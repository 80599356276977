import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-08f60508"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "security-groups-modal"
};
var _hoisted_2 = {
  key: 1,
  class: "security-groups-modal-body"
};
var _hoisted_3 = {
  class: "security-groups-modal-section mb-3"
};
var _hoisted_4 = {
  class: "security-groups-modal-section mb-3"
};
var _hoisted_5 = {
  class: "security-groups-modal-section"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_modal, {
    "show-buttons": false,
    "overflow-visible": true,
    header: $data.translate.project,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('close');
    })
  }, {
    body: _withCtx(function () {
      return [!$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
        key: 0
      })) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
        options: $data.projects,
        "model-value": $data.projectIdActive,
        disabled: !!$props.projectId,
        "un-sorted": true,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function (value) {
          return $data.projectIdActive = value;
        })
      }, null, 8 /* PROPS */, ["options", "model-value", "disabled"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
        options: $data.accessLevels,
        "model-value": $data.accessLevelActive,
        "un-sorted": true,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function (value) {
          return $data.accessLevelActive = value;
        })
      }, null, 8 /* PROPS */, ["options", "model-value"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_button, {
        class: "ml-auto",
        type: "save",
        text: _ctx.$trans($options.submitText),
        onClick: $options.handleSubmit
      }, null, 8 /* PROPS */, ["text", "onClick"])])])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header"])]);
}