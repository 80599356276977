import CodeHighlight from '@/components/Atoms/Text/CodeHighlight';
export default {
  name: 'KitchenComponentFooter',
  components: {
    CodeHighlight: CodeHighlight
  },
  props: {
    component: {
      type: Object,
      required: true,
      note: 'Component entity (with all needed meta data)'
    }
  }
};