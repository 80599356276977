import RismaStepProgressBar from '@/components/Molecules/RismaStepProgressBar';
export default {
  name: '',
  components: {
    RismaStepProgressBar: RismaStepProgressBar
  },
  data: function data() {
    return {
      data: {
        firstItem: {
          state: 'approved',
          //can be approved|wait|declined
          titles: ['Questionnaire sent (2019-04-04 12:44:40)', 'To Anders Andersen (anders@andersen.com)', 'Deadline: 1 month']
        },
        secondItem: {
          state: 'wait',
          titles: ['Awaiting questionnaire to be opened']
        },
        thirdItem: {
          state: 'declined',
          titles: ['Deadline exceeded. Questionnaire answer not received']
        }
      }
    };
  }
};