import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risk_row = _resolveComponent("risk-row");
  return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rismaRisks, function (risk) {
    return _openBlock(), _createBlock(_component_risk_row, {
      key: risk.id,
      tense: _ctx.rismaTense[risk.riskIndex],
      impact: risk.impact,
      likelihood: risk.likelihood,
      "risk-level": risk.rpi
    }, null, 8 /* PROPS */, ["tense", "impact", "likelihood", "risk-level"]);
  }), 128 /* KEYED_FRAGMENT */))]);
}