import SingleAssetChoice from '@/components/Molecules/Questionnaire/SingleAssetChoice';
export default {
  name: '',
  components: {
    SingleAssetChoice: SingleAssetChoice
  },
  data: function data() {
    return {
      singleassetchoice: {
        assets: [{
          id: '1',
          name: 'Hello'
        }, {
          id: '2',
          name: 'To'
        }, {
          id: '3',
          name: 'Why'
        }, {
          id: '4',
          name: 'Do'
        }, {
          id: '5',
          name: 'Biiiiiiiiiirds'
        }],
        selected: [{
          id: '2',
          name: 'To'
        }]
      }
    };
  }
};