import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaCarousel from '@/components/Molecules/RismaCarousel/RismaCarousel';
import ActivityFeedCard from '@/components/Molecules/Cards/ActivityFeedCard';
import TextSelector from '@/components/Atoms/Inputs/TextSelector';
export default {
  name: 'PreviewListCurrentActivity',
  introduction: 'PreviewListCurrentActivity is used in Mypage /mypage',
  description: 'PreviewListCurrentActivity is used to display list of current activities',
  token: '<preview-list-current-activity />',
  components: {
    TextSelector: TextSelector,
    RismaTitle: RismaTitle,
    ActivityFeedCard: ActivityFeedCard,
    RismaCarousel: RismaCarousel
  },
  props: {
    activities: {
      type: Array,
      required: true,
      note: 'List of activities'
    },
    deadlineFilterValue: {
      type: Number,
      required: true,
      note: 'value of filter'
    },
    deadlineFilterOptions: {
      type: Array,
      required: true,
      note: 'List of options for filter'
    },
    recentFilterValue: {
      type: Number,
      required: true,
      note: 'value of filter'
    },
    recentFilterOptions: {
      type: Array,
      required: true,
      note: 'List of options for filter'
    },
    projects: {
      type: Array,
      required: true,
      note: 'List of projects'
    }
  },
  emits: ['changeDeadlineFilter', 'changeRecentFilter'],
  data: function data() {
    return {
      translate: getTranslate['PreviewListCurrentActivity']()
    };
  },
  computed: {
    currentActivitiesTitle: function currentActivitiesTitle() {
      return "".concat(this.translate.currentActivities, " (").concat(this.activities.length, ")");
    },
    computedDeadlineFilterValue: {
      get: function get() {
        return this.deadlineFilterValue;
      },
      set: function set(value) {
        this.$emit('changeDeadlineFilter', value);
      }
    },
    computedRecentFilterValue: {
      get: function get() {
        return this.recentFilterValue;
      },
      set: function set(value) {
        this.$emit('changeRecentFilter', value);
      }
    }
  }
};