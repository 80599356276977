function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import InputField from '@/components/Atoms/Inputs/InputField';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import Notification from '@/components/Molecules/Notification';
import Alert from '@/components/Molecules/Modal/Alert';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import { getModuleTitles, MODULES } from '@/constants/modules';
import { solutionModules } from '@/constants/admin/SolutionProjects';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { capitalize } from '@/utils';
import { getSolutions as _getSolutions, updateSolution, deleteSolution as _deleteSolution, createSolution, runAutoDetectSolutionActivities, runCreateDashboardAndKpisService } from '@/api/admin/solutions';
export default {
  name: 'Solutions',
  components: {
    RismaToggle: RismaToggle,
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    InputField: InputField,
    MainWell: MainWell,
    RismaDatatable: RismaDatatable,
    Notification: Notification,
    FeatherIcon: FeatherIcon,
    Alert: Alert,
    RismaButton: RismaButton,
    Modal: Modal,
    NotificationPopUp: NotificationPopUp
  },
  mixins: [RismaDatatableDefaultMixin],
  data: function data() {
    return {
      stateLocal: 'solution-matrix-table',
      description: 'Solution ID\'s are essential for identifing which activities belongs to specific solutions. These ID\'s are essential for making sure that the KPI\'s are measured correct, and thereby give you and your organisation the precise performance indication for each solution as well as other reporting for cross-solution purposes.',
      isLoading: false,
      solutions: [],
      modules: {},
      dataset: [],
      successMessage: '',
      notificationMessage: '',
      createErrorMessage: '',
      newSolutionTitle: '',
      showRemoveModal: false,
      showCreateModal: false,
      removeModalBody: 'Are you sure you want delete this?',
      selectedSolution: null,
      showIdsSwitchOption: false,
      translate: getTranslate['Solutions']()
    };
  },
  computed: {
    columns: function columns() {
      var _this = this;
      var columns = {
        solutionModule: this.translate.solutionmodule
      };
      Object.keys(this.modules).forEach(function (moduleKey) {
        columns[moduleKey] = _this.modules[moduleKey];
      });
      return columns;
    }
  },
  watch: {
    showIdsSwitchOption: function showIdsSwitchOption() {
      this.updateDataset();
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      this.isLoading = true;
      return Promise.all([this.getSolutions(), this.getSolutionModuleTitles()]).then(function () {
        _this2.updateDataset();
        _this2.isLoading = false;
      });
    },
    getSolutions: function getSolutions() {
      var _this3 = this;
      return _getSolutions().then(function (_ref) {
        var list = _ref.list;
        _this3.solutions = list;
      });
    },
    getSolutionModuleTitles: function getSolutionModuleTitles() {
      var _this4 = this;
      var modules = solutionModules();
      var allModulesTitles = getModuleTitles();
      modules.forEach(function (module) {
        _this4.modules[module] = allModulesTitles[module];
      });
    },
    onSolutionChange: function onSolutionChange(value, property, solution) {
      var _this5 = this;
      if (solution[property] !== undefined && solution[property] !== value) {
        var updatedSolution = _defineProperty({
          id: solution.id
        }, property, value);
        if (property === 'enabled') {
          updatedSolution[property] = +value;
        }
        this.isLoading = true;
        return updateSolution(updatedSolution).then(function (updatedSolution) {
          _this5.solutions = _this5.solutions.map(function (s) {
            return +s.id === +updatedSolution.id ? updatedSolution : s;
          });
        }).catch(function (err) {
          var _err$response;
          _this5.notificationMessage = _this5.$trans(err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.error) || err;
        }).finally(function () {
          _this5.isLoading = false;
        });
      }
    },
    runSolutionAutoDetect: function runSolutionAutoDetect(solutionName) {
      var _this6 = this;
      var solutionParam = capitalize(solutionName).replace('management', 'Management').replace('controls', 'Controls').replace('risk', 'Risk').replaceAll(' ', '');
      this.isLoading = true;
      this.successMessage = '';
      this.notificationMessage = '';
      return runAutoDetectSolutionActivities(solutionParam).then(function (response) {
        _this6.successMessage = response;
        _this6.getData();
      }).catch(function (err) {
        var _err$response2;
        _this6.notificationMessage = _this6.$trans(err === null || err === void 0 || (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.error) || err;
      }).finally(function () {
        _this6.isLoading = false;
      });
    },
    runCreateDashboardAndKpis: function runCreateDashboardAndKpis(solutionName) {
      var _this7 = this;
      this.isLoading = true;
      this.successMessage = '';
      this.notificationMessage = '';
      return runCreateDashboardAndKpisService(solutionName).then(function (response) {
        _this7.successMessage = response;
      }).catch(function (err) {
        var _err$response3;
        _this7.notificationMessage = _this7.$trans(err === null || err === void 0 || (_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : _err$response3.error) || err;
      }).finally(function () {
        _this7.isLoading = false;
      });
    },
    updateDataset: function updateDataset() {
      this.prepareDataset();
      this.enhanceDataset(this.dataset, this.columns);
    },
    prepareDataset: function prepareDataset() {
      var _this8 = this;
      this.dataset = this.solutions.map(function (item) {
        var datasetItem = {
          solutionModule: {
            field: item.title
          }
        };
        Object.keys(_this8.modules).forEach(function (moduleKey) {
          var _item$usage;
          var idsArr = ((_item$usage = item.usage) === null || _item$usage === void 0 || (_item$usage = _item$usage.modules) === null || _item$usage === void 0 ? void 0 : _item$usage[moduleKey]) || [];
          if (moduleKey === MODULES.COMPLIANCE_INFORMATION_ASSETS) {
            var _item$usage2, _item$usage3, _item$usage4;
            idsArr = idsArr.concat(((_item$usage2 = item.usage) === null || _item$usage2 === void 0 || (_item$usage2 = _item$usage2.modules) === null || _item$usage2 === void 0 ? void 0 : _item$usage2[MODULES.COMPLIANCE_IA_SYSTEM]) || []);
            idsArr = idsArr.concat(((_item$usage3 = item.usage) === null || _item$usage3 === void 0 || (_item$usage3 = _item$usage3.modules) === null || _item$usage3 === void 0 ? void 0 : _item$usage3[MODULES.COMPLIANCE_IA_PROCESSOR]) || []);
            idsArr = idsArr.concat(((_item$usage4 = item.usage) === null || _item$usage4 === void 0 || (_item$usage4 = _item$usage4.modules) === null || _item$usage4 === void 0 ? void 0 : _item$usage4[MODULES.COMPLIANCE_IA_CONTROLLER]) || []);
          }
          datasetItem[moduleKey] = {
            field: _this8.showIdsSwitchOption ? idsArr : idsArr.length || ''
          };
        });
        return datasetItem;
      });
    },
    toggleRemoveModal: function toggleRemoveModal() {
      this.showRemoveModal = !this.showRemoveModal;
    },
    toggleShowCreateModal: function toggleShowCreateModal() {
      this.newSolutionTitle = '';
      this.createErrorMessage = '';
      this.showCreateModal = !this.showCreateModal;
    },
    onDeleteIconClick: function onDeleteIconClick(solution) {
      this.selectedSolution = solution;
      this.toggleRemoveModal();
    },
    onCreateNewSolutionClick: function onCreateNewSolutionClick() {
      var _this9 = this;
      var newSolution = {
        title: this.newSolutionTitle.trim()
      };
      if (!newSolution.title) {
        this.createErrorMessage = this.translate.titleCannotBeEmpty;
        return;
      }
      this.toggleShowCreateModal();
      createSolution(newSolution).then(function (solution) {
        _this9.solutions.push(solution);
      });
    },
    deleteSolution: function deleteSolution() {
      var _this10 = this;
      return _deleteSolution(this.selectedSolution.id).then(function () {
        _this10.solutions = _this10.solutions.filter(function (s) {
          return +s.id !== _this10.selectedSolution.id;
        });
        _this10.selectedSolution = null;
      }).catch(function (err) {
        var _err$response4;
        _this10.notificationMessage = _this10.$trans(err === null || err === void 0 || (_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : _err$response4.error) || err;
      }).finally(function () {
        _this10.showRemoveModal = false;
      });
    }
  }
};