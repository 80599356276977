import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "compact-title-wrap flex items-center"
};
var _hoisted_2 = {
  key: 0,
  class: "compact-title-traffic mr-2"
};
var _hoisted_3 = {
  class: "flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.slotData.hasTrafficLight ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_traffic_light, {
    color: $props.slotData.color
  }, null, 8 /* PROPS */, ["color"])])) : _createCommentVNode("v-if", true), $props.slotData.showPreview ? (_openBlock(), _createBlock(_component_preview_modal_link, _mergeProps({
    key: 1,
    activity: $props.slotData
  }, $options.attrs), {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_3, [$options.isAction && $props.slotData.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "check",
        class: "mr-2"
      })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title_with_icon, {
        icon: "confidential",
        title: $props.slotData.title,
        "title-attribute": $props.slotData.hoverTitle,
        "title-class": $options.titleClass,
        "display-icon": $props.slotData.confidential
      }, null, 8 /* PROPS */, ["title", "title-attribute", "title-class", "display-icon"]), $options.isControl && $props.slotData.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 1,
        icon: "check",
        class: "ml-2"
      })) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["activity"])) : (_openBlock(), _createBlock(_component_router_link, {
    key: 2,
    to: $props.slotData.url,
    class: _normalizeClass([$props.slotData.additionalCSS || '', "activity-list-link flex items-center"])
  }, {
    default: _withCtx(function () {
      return [$options.isAction && $props.slotData.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "check",
        class: "mr-2"
      })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title_with_icon, {
        icon: "confidential",
        title: $props.slotData.title,
        "title-attribute": $props.slotData.hoverTitle,
        "title-class": $options.titleClass,
        "display-icon": $props.slotData.confidential
      }, null, 8 /* PROPS */, ["title", "title-attribute", "title-class", "display-icon"]), $options.isControl && $props.slotData.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 1,
        icon: "check",
        class: "ml-2"
      })) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to", "class"]))]);
}