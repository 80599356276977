function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getFiles } from '@/api/risma/file';
import { deleteNodeById, getById, updateNodeByProjectIdAndNodeId } from '@/api/compliance/node';
import { getSingle as getSingleProject } from '@/api/compliance/projects';
import { getQuestionnairesByQuestionnaireTypeId } from '@/api/compliance/questionnaire';
import { getAllGapSchemasByProjectId } from '@/api/compliance/gapschema';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import ActivityTypeSelector from '@/components/Molecules/ActivityTypeSelector';
import MultipleQuestionnaireSelector from '@/components/Molecules/QuestionnaireSelector/MultipleQuestionnaireSelector';
import Notification from '@/components/Molecules/Notification';
import Alert from '@/components/Molecules/Modal/Alert';
import SolutionIdRelationDetails from '@/components/Molecules/SolutionIdRelationDetails';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import DelegationWell from '@/components/Organisms/General/coreWells/DelegationWell';
import GovernanceReviewSection from '@/components/Organisms/Compliance/GovernanceReview/GovernanceReviewSection';
import NodeBreadcrumbs from '@/components/Pages/Compliance/NodeEdit/NodeBreadcrumbs';
import { TabTypes, Tabs } from '@/constants/Tabs';
import { saveStatus } from '@/constants/SaveStatuses';
import { MODULES } from '@/constants/modules';
import { NodeFields } from '@/constants/compliance/NodeFields';
import { GovernanceReviewMixin } from '@/mixins/GovernanceReviewMixin';
import { createTabsList } from '@/utils/base/tabs';
import { getLinksLabel } from '@/utils/links';
import { getCustomFieldValues } from '@/utils/CustomFields';
import { updateFileLocally } from '@/utils/Attachments';
import { catch403 } from '@/utils/handleAPIErrors';
export default {
  name: 'NodePage',
  components: {
    SolutionIdRelationDetails: SolutionIdRelationDetails,
    DepWell: DepWell,
    RismaTitle: RismaTitle,
    DelegationWell: DelegationWell,
    ActivityTypeSelector: ActivityTypeSelector,
    GovernanceReviewSection: GovernanceReviewSection,
    Notification: Notification,
    SingleSelect: SingleSelect,
    MultipleQuestionnaireSelector: MultipleQuestionnaireSelector,
    NodeBreadcrumbs: NodeBreadcrumbs,
    Alert: Alert
  },
  mixins: [GovernanceReviewMixin],
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    nodeId: {
      required: true,
      type: Number,
      note: 'The node that needs to be edited'
    },
    projectId: {
      type: Number,
      required: true,
      note: 'project id for the current project'
    }
  },
  emits: ['changeTab', 'updated', 'deleteItem', 'accessError'],
  data: function data() {
    return {
      confirmCallback: null,
      confirmMessage: '',
      showConfirmationAlert: false,
      saveStatus: saveStatus.SAVED,
      updateTimer: 0,
      node: null,
      project: null,
      questionnaires: null,
      gaps: null,
      isLoaded: false,
      files: [],
      module: MODULES.COMPLIANCE,
      moduleId: this.nodeId,
      notificationType: '',
      notificationMessage: '',
      notificationDetails: '',
      isNotificationShow: false,
      isDirty: false,
      isPageWithCustomUpdateDirty: false,
      translate: getTranslate['NodePage']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserComplianceProjectById: 'complianceProjectById',
    currentUserShowGapByProjectId: 'showGapByProjectId'
  })), mapState(useUsersStore, ['users'])), mapState(useOrganisationsStore, ['organisations'])), mapState(useActivityTypesStore, {
    activityTypes: 'complianceActivityTypes'
  })), {}, {
    currentProject: function currentProject() {
      return this.currentUserComplianceProjectById(this.projectId);
    },
    tabs: function tabs() {
      var tabsList = [TabTypes.DESCRIPTION];
      var endTabs = [TabTypes.LINKS, TabTypes.CHANGELOG];
      if (this.questionnaireEnabled && this.node.questionnaires && this.node.questionnaires.length) {
        this.node.questionnaires.forEach(function (questionnaire) {
          if (!questionnaire.tabLabel) return;
          tabsList.push(_objectSpread(_objectSpread({}, Tabs[TabTypes.QUESTIONNAIRE]), {}, {
            url: questionnaire.url,
            slicedUrl: "".concat(Tabs[TabTypes.QUESTIONNAIRE].url, "/").concat(questionnaire.questionnaireId),
            label: questionnaire.tabLabel
          }));
        });
      }
      if (this.assessmentEnabled) tabsList.push(TabTypes.ASSESSMENT);
      if (this.gapAnalysisEnabled && this.node.gapSchemaId) tabsList.push(TabTypes.GAPANALYSIS);
      if (this.dataFlowEnabled) tabsList.push(TabTypes.DATAFLOW);
      tabsList.push.apply(tabsList, endTabs);
      return createTabsList(tabsList, "/compliance/".concat(this.projectId, "/edit/").concat(this.nodeId), getLinksLabel(this.node));
    },
    questionnaireEnabled: function questionnaireEnabled() {
      if (this.hasActivityTypeWithOptionalFields) {
        if (this.node.activityType.optionalFields.indexOf('questionnaire') === -1) {
          return false;
        }
      }
      return true;
    },
    assessmentEnabled: function assessmentEnabled() {
      if (!this.hasActivityTypeWithOptionalFields) return false;
      return this.node.activityType.optionalFields.indexOf('assessment') !== -1;
    },
    gapAnalysisEnabled: function gapAnalysisEnabled() {
      if (!this.project) return false;
      var levelAdmin = this.currentUser.level_admin;
      var userShowGap = this.currentUserShowGapByProjectId(this.project.id);
      if (this.hasActivityTypeWithOptionalFields && this.node.activityType.optionalFields.indexOf('gap_schema') === -1) {
        return false;
      }
      return levelAdmin === 1 || userShowGap;
    },
    hasActivityTypeWithOptionalFields: function hasActivityTypeWithOptionalFields() {
      return this.node && this.node.activityType && this.node.activityType.optionalFields;
    },
    showActivityTypeSelector: function showActivityTypeSelector() {
      return this.getSettingValue('feature.compliance_activity_types') && this.activityTypes.length > 0;
    },
    dataFlowEnabled: function dataFlowEnabled() {
      if (!this.project) return false;
      return parseInt(this.project.type) === 1 && this.getSettingValue('feature.dataflow') && this.questionnaireEnabled;
    },
    trafficLightSettings: function trafficLightSettings() {
      return {
        value: this.node.traffic_light,
        lightType: 'trafficLights',
        field: 'traffic_light',
        readOnly: !!this.currentProject.locked
      };
    },
    depHeaderProps: function depHeaderProps() {
      var _this$currentProject$;
      if (!this.isLoaded) {
        return {};
      }
      return {
        title: this.node.title,
        editableId: false,
        editableTitle: !this.currentProject.locked && !((_this$currentProject$ = this.currentProject.lockedFields) !== null && _this$currentProject$ !== void 0 && _this$currentProject$.includes(NodeFields.TITLE)),
        filesList: this.files,
        trafficLightSettings: this.trafficLightSettings,
        moduleId: this.node.id,
        id: this.node.id,
        module: MODULES.COMPLIANCE,
        titleField: 'title',
        saveStatus: this.saveStatus,
        users: this.users,
        tabs: this.tabs,
        editableAttachments: !this.currentProject.locked
      };
    },
    delegationData: function delegationData() {
      return {
        responsibleUserIds: this.node.responsibleUserIds,
        accountableUserIds: this.node.accountableUserIds,
        consultedUserIds: this.node.consultedUserIds,
        informedUserIds: this.node.informedUserIds,
        organisationIds: this.node.organisationIds
      };
    },
    showQuestionnaire: function showQuestionnaire() {
      var activityType = this.node.activityType;
      return !(this.hasActivityTypeWithOptionalFields && activityType.optionalFields.indexOf('questionnaire') === -1);
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.activityTypes.find(function (at) {
        var _this$node$activityTy;
        return ((_this$node$activityTy = _this.node.activityType) === null || _this$node$activityTy === void 0 ? void 0 : _this$node$activityTy.id) === at.id;
      });
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      return Promise.all([getSingleProject(this.projectId).then(function (response) {
        return _this2.project = response;
      }), this.getNode(), this.getAttachmentFiles(), this.getGapsList()]).then(function () {
        return _this2.getQuestionnairesList();
      }).then(function () {
        return _this2.isLoaded = true;
      });
    },
    getNode: function getNode() {
      var _this3 = this;
      return getById(this.projectId, this.nodeId).then(function (response) {
        var _response$rootNode;
        if (((_response$rootNode = response.rootNode) === null || _response$rootNode === void 0 ? void 0 : _response$rootNode.id) === _this3.nodeId) {
          _this3.$router.replace({
            name: 'TreeOverview',
            params: {
              projectId: _this3.projectId
            }
          });
        }
        _this3.node = response;
      }).catch(function () {
        if (_this3.isPreview) {
          _this3.$emit('accessError');
          return;
        }
        catch403(_this3.$router, 'TreeOverview');
      });
    },
    getQuestionnairesList: function getQuestionnairesList() {
      var _this4 = this;
      return getQuestionnairesByQuestionnaireTypeId(this.project.questionnaireType).then(function (response) {
        _this4.questionnaires = response.list.filter(function (item) {
          return item.selectable;
        }).map(function (item) {
          item.label = item.title;
          item.value = item.id;
          return item;
        });
      });
    },
    getGapsList: function getGapsList() {
      var _this5 = this;
      return getAllGapSchemasByProjectId(this.projectId, '?data[]=articleDetails').then(function (response) {
        _this5.gaps = response.list.map(function (item) {
          item.label = item.title;
          item.value = item.id;
          return item;
        });
      });
    },
    updateValue: function updateValue(_ref) {
      var property = _ref.property,
        value = _ref.value;
      this.node[property] = value;
      this.$emit('updated', _objectSpread({}, this.node));
    },
    nodePropertyChanged: function nodePropertyChanged(_ref2) {
      var property = _ref2.property,
        value = _ref2.value;
      this.isDirty = true;
      this.node[property] = value;
      this.setUpdateTimer();
    },
    setUpdateTimer: function setUpdateTimer() {
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(this.saveUpdates, this.$autoSaveDebounceTime);
    },
    saveStatusClicked: function saveStatusClicked() {
      if (this.saveStatus !== saveStatus.SAVED) {
        clearTimeout(this.updateTimer);
        this.saveUpdates();
      }
    },
    saveChangesImmediately: function saveChangesImmediately(updatedProperty) {
      this.nodePropertyChanged(updatedProperty);
      this.saveStatusClicked();
    },
    saveUpdates: function saveUpdates() {
      var _this6 = this;
      this.saveStatus = saveStatus.SAVING;
      var timerId = this.updateTimer;
      if (!this.isDirty) return;
      var customFieldValues = getCustomFieldValues(this.node);
      var data = _objectSpread(_objectSpread({}, this.node), {}, {
        customFieldValues: customFieldValues
      });
      updateNodeByProjectIdAndNodeId(this.projectId, data.id, data).then(function (response) {
        var tab = _this6.tabs.find(function (tab) {
          return tab.url === _this6.$router.currentRoute.value.path;
        });
        if (!tab && !_this6.isPreview) {
          _this6.$router.push(_this6.tabs[0].url);
        }
        if (!_this6.isPageWithCustomUpdateDirty) {
          _this6.saveStatus = saveStatus.SAVED;
        }

        // update the node if there were no new changes during the save time
        if (timerId !== _this6.updateTimer) return;
        _this6.isDirty = false;
        if (response.questionnaireId !== data.questionnaireId) {
          _this6.node.questionnaire = response.questionnaire;
          _this6.node.values = response.values;
        }
        if (response.questionnaires) {
          _this6.node.questionnaires = response.questionnaires;
        }
        if (response.activityType && data.activityType) {
          if (response.activityType.id !== data.activityType.id) {
            _this6.node.activityType = response.activityType;
          }
        } else {
          _this6.node.activityType = response.activityType;
        }
        _this6.$emit('updated', _objectSpread({}, _this6.node));
      }).catch(function (e) {
        console.log('saveUpdates failed...'); // eslint-disable-line
        console.log(e); // eslint-disable-line
        _this6.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    getAttachmentFiles: function getAttachmentFiles() {
      var _this7 = this;
      return getFiles({
        module: MODULES.COMPLIANCE,
        moduleId: this.nodeId
      }).then(function (response) {
        return _this7.files = _toConsumableArray(response.list);
      });
    },
    addQuestionnaire: function addQuestionnaire(id) {
      var questionnaire = this.questionnaires.find(function (item) {
        return +item.id === +id;
      });
      if (!questionnaire) return;
      if (questionnaire.defaultGapSchemaId && questionnaire.defaultGapSchemaId !== this.node.gapSchemaId) {
        this.showConfirmationAlert = true;
        this.confirmCallback = this.setDefaultGapSchemaForQuestionnaire;
        this.confirmMessage = this.translate.updateGapSchemaMessage;
      }
      this.nodePropertyChanged({
        property: 'questionnaires',
        value: [].concat(_toConsumableArray(this.node.questionnaires), [{
          questionnaireId: id
        }])
      });
    },
    removeQuestionnaire: function removeQuestionnaire(id) {
      this.nodePropertyChanged({
        property: 'questionnaires',
        value: this.node.questionnaires.filter(function (q) {
          return q.questionnaireId != id;
        })
      });
    },
    onConfirm: function onConfirm() {
      this.confirmCallback();
      this.showConfirmationAlert = false;
      this.confirmCallback = null;
    },
    setDefaultGapSchemaForQuestionnaire: function setDefaultGapSchemaForQuestionnaire() {
      var _this$node$questionna;
      var questionnaireId = (_this$node$questionna = this.node.questionnaires.at(-1)) === null || _this$node$questionna === void 0 ? void 0 : _this$node$questionna.questionnaireId;
      var questionnaire = this.questionnaires.find(function (item) {
        return +item.id === +questionnaireId;
      });
      var gapSchemaId = questionnaire.defaultGapSchemaId;
      if (gapSchemaId && this.node.gapSchemaId !== gapSchemaId) {
        this.nodePropertyChanged({
          property: 'gap_schema_id',
          value: gapSchemaId
        });
        this.nodePropertyChanged({
          property: 'gapSchemaId',
          value: gapSchemaId
        });
      }
    },
    setGapSchema: function setGapSchema(gapSchemaId) {
      var _this8 = this;
      if (this.node.gapSchemaId === gapSchemaId) {
        return false;
      }
      this.confirmMessage = this.node.values.gap_records_answered > 0 ? this.translate.youHaveAlreadyAnsweredGapQuestionsOnThisNo : this.translate.areYouSureYouWantToChangeTheGapSchema;
      this.confirmCallback = function () {
        _this8.nodePropertyChanged({
          property: 'gap_schema_id',
          value: gapSchemaId
        });
        _this8.nodePropertyChanged({
          property: 'gapSchemaId',
          value: gapSchemaId
        });
      };
      this.showConfirmationAlert = true;
    },
    handleShowNotify: function handleShowNotify(_ref3) {
      var type = _ref3.type,
        message = _ref3.message,
        details = _ref3.details;
      this.notificationType = type;
      this.notificationMessage = message;
      this.notificationDetails = details;
      this.isNotificationShow = true;
      this.$scrollTo(document.body, 100);
      setTimeout(this.handleDismissNotification, 5000);
    },
    handleDismissNotification: function handleDismissNotification() {
      this.notificationMessage = '';
      this.notificationDetails = '';
      this.isNotificationShow = false;
    },
    deleteNode: function deleteNode() {
      var _this9 = this;
      return deleteNodeById(this.projectId, this.node.id).then(function (response) {
        if (typeof response.headers !== 'undefined') {
          _this9.handleShowNotify({
            type: 'error',
            message: _this9.translate.couldNotDeleteNode
          });
          return;
        }
        if (_this9.isPreview) {
          _this9.$emit('deleteItem', _this9.node.id);
        } else {
          _this9.$router.replace('/compliance/tree/' + _this9.projectId);
        }
      });
    },
    onFileUpdate: function onFileUpdate(fileId) {
      updateFileLocally(fileId, this.files);
      this.saveStatus = saveStatus.SAVED;
    },
    handleUpdateForCustomPageUpdate: function handleUpdateForCustomPageUpdate(data) {
      this.isPageWithCustomUpdateDirty = data.value;
      if (data.value) {
        this.setUpdateTimer();
        if (data.isForced) {
          this.saveStatusClicked();
        }
      } else if (!this.isDirty) {
        this.saveStatus = saveStatus.SAVED;
      }
    },
    questionAnswered: function questionAnswered() {
      if (this.isPreview) {
        // Fetch node fresh after a question has been answered to update questionnaire stats.
        this.getNode();
      }
    },
    updatingAttachments: function updatingAttachments() {
      this.saveStatus = saveStatus.SAVING;
    },
    getActivityData: function getActivityData() {
      this.getData();
    }
  }
};