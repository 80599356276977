import { getTranslate } from './translate';
import { forgotPassword } from '@/api/risma/password';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import { LogoSettings } from '@/constants/admin/CustomizationSettings';
export default {
  name: 'ForgotPassword',
  components: {
    InputField: InputField,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    Notification: Notification
  },
  introduction: '',
  description: 'The page for resetting password if the user forgets',
  token: '<forgot-password />',
  data: function data() {
    return {
      email: '',
      isResetSuccess: false,
      notificationMessages: '',
      isLocked: false,
      translate: getTranslate['ForgotPassword']()
    };
  },
  computed: {
    successSubtitle: function successSubtitle() {
      return "".concat(this.translate.youHaveRequestedANewPasswordAnEmailWithI, " ").concat(this.email);
    },
    logoSrc: function logoSrc() {
      return LogoSettings.DEFAULT_WITH_BLUE_TEXT;
    },
    pageData: function pageData() {
      if (this.isResetSuccess) {
        return {
          mainText: this.translate.newPasswordRequested,
          subText: this.successSubtitle,
          buttonText: this.translate.returnToLoginPage,
          buttonEvent: this.goToLogin
        };
      }
      return {
        mainText: this.translate.forgotYourPassword,
        subText: this.translate.pleaseEnterYourEmailAddressAnEmailExplaini,
        buttonText: this.translate.resetPassword,
        buttonEvent: this.onResetPassword
      };
    }
  },
  methods: {
    onResetPassword: function onResetPassword() {
      var _this = this;
      if (this.isLocked) return;
      this.notificationMessages = '';
      if (!this.isValidEmail(this.email)) {
        return;
      }
      this.isLocked = true;
      forgotPassword({
        email: this.email
      }).then(function () {
        _this.isResetSuccess = true;
      }).catch(function (error) {
        _this.notificationMessages = error.response;
      }).finally(function () {
        return _this.isLocked = false;
      });
    },
    goToLogin: function goToLogin() {
      this.$router.push('/');
    },
    isValidEmail: function isValidEmail(email) {
      var regExp = /\S+@\S+\.\S+/;
      var isValid = regExp.test(email.toLowerCase()) && !!email.length;
      if (!isValid) {
        this.notificationMessages = this.getEmailErrorNotification();
      }
      return isValid;
    },
    getEmailErrorNotification: function getEmailErrorNotification() {
      if (this.email) {
        return this.email + this.translate.isAnInvalidEmailAddress;
      } else {
        return this.translate.pleaseFillOutThisField;
      }
    }
  }
};