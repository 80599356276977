import StackedBarAmChart from '@/components/Molecules/Charts/StackedBarAmChart';
export default {
  components: {
    StackedBarAmChart: StackedBarAmChart
  },
  data: function data() {
    return {
      dataProvider: [{
        controls_rejected: 1,
        green_traffic_light: 3,
        label: 'Contact management',
        red_traffic_light: 1,
        yellow_traffic_light: 4
      }, {
        controls_rejected: 2,
        green_traffic_light: 1,
        label: 'Proces 1',
        red_traffic_light: 2,
        yellow_traffic_light: 0
      }, {
        controls_rejected: 1,
        green_traffic_light: 1,
        label: 'Proces 2',
        red_traffic_light: 1,
        yellow_traffic_light: 1
      }],
      graphsInfo: [{
        color: '#A80000',
        field: 'red_traffic_light',
        title: 'Red traffic light'
      }, {
        color: '#FFBF00',
        field: 'yellow_traffic_light',
        title: 'Yellow traffic light'
      }, {
        color: '#5AA744',
        field: 'green_traffic_light',
        title: 'Green traffic light'
      }, {
        color: 'blue',
        field: 'controls_rejected',
        title: 'Rejected controls'
      }]
    };
  }
};