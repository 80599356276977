import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
export default {
  name: 'DataFlowWizardStep1',
  introduction: 'component is used to display WizardFlow step 1',
  description: 'This is the new data flow wizard. From here you fill out the questionnaire and map your systems, datatypes and data subjects at once',
  token: '<modal-wizard-example-step-1 />',
  components: {
    RismaTitle: RismaTitle
  },
  data: function data() {
    return {
      translate: {
        thisIsNew: this.$trans('This is the new data flow wizard. From here you fill out the questionnaire and map your systems, datatypes and data subjects at once'),
        nextStepIsTo: this.$trans('Next step is to choose the asset where the information about your data subject(s) are stored.'),
        ifYouHaveMultipleAssets: this.$trans('If you have multiple assets, then you can run the wizard again for each asset.')
      }
    };
  }
};