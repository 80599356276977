import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risk_management = _resolveComponent("risk-management");
  return _openBlock(), _createBlock(_component_risk_management, {
    title: $options.riskTypeLabel,
    columns: $options.columns,
    dataset: $options.dataset,
    "create-item-fn": $options.createRiskType,
    "update-item-fn": $options.updateRiskType,
    "delete-item-fn": $options.deleteRiskType,
    onDelete: $options.deleteRiskType
  }, null, 8 /* PROPS */, ["title", "columns", "dataset", "create-item-fn", "update-item-fn", "delete-item-fn", "onDelete"]);
}