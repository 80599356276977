function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { getTranslate } from './translate';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { createReport as _createReport, getSuggestedDataForTopLevelNode } from '@/api/compliance/reports/outsourcing';
export default {
  name: 'CreateNewReport',
  components: {
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    RismaSelect: RismaSelect
  },
  props: {
    projectId: {
      type: [Number, String],
      required: true
    },
    nodeId: {
      type: [Number, String],
      required: false,
      default: null
    }
  },
  emits: ['error'],
  data: function data() {
    return {
      loading: false,
      registerNodeId: null,
      selectedRegisterQuestions: [],
      preliminaryAnalysisNodeId: null,
      selectedPreliminaryAnalysisGapArticles: [],
      assessmentNodeId: null,
      selectedAssessmentNodeGapArticles: [],
      importantOrCriticalNodeId: null,
      topLevelNodes: [],
      topLevelNode: null,
      details: {},
      translate: getTranslate['CreateNewReport']()
    };
  },
  computed: {
    childrenNodesForSelect: function childrenNodesForSelect() {
      if (!this.details.childrenNodes) {
        return [];
      }
      return this.details.childrenNodes.map(function (childNode) {
        return {
          value: childNode.id,
          label: childNode.title
        };
      });
    },
    childrenNodeMap: function childrenNodeMap() {
      var map = new Map();
      if (!this.details.childrenNodes) {
        return map;
      }
      this.details.childrenNodes.forEach(function (child) {
        map.set(child.id, child);
      });
      return map;
    },
    preliminaryAnalysisNodeGapArticles: function preliminaryAnalysisNodeGapArticles() {
      return this.getGapsByNodeId(this.preliminaryAnalysisNodeId);
    },
    assessmentNodeGapArticles: function assessmentNodeGapArticles() {
      return this.getGapsByNodeId(this.assessmentNodeId);
    },
    registerQuestions: function registerQuestions() {
      return this.getQuestionsByNodeId(this.registerNodeId);
    },
    registerNodeQuestionIds: function registerNodeQuestionIds() {
      return this.selectedRegisterQuestions.map(function (question) {
        return question.id;
      });
    },
    preliminaryAnalysisNodeGapIds: function preliminaryAnalysisNodeGapIds() {
      return this.selectedPreliminaryAnalysisGapArticles.map(function (gapArticle) {
        return gapArticle.id;
      });
    },
    assessmentNodeGapIds: function assessmentNodeGapIds() {
      return this.selectedAssessmentNodeGapArticles.map(function (gapArticle) {
        return gapArticle.id;
      });
    },
    blocksQuery: function blocksQuery() {
      var nodeId = this.nodeId,
        registerNodeId = this.registerNodeId,
        registerNodeQuestionIds = this.registerNodeQuestionIds,
        preliminaryAnalysisNodeGapIds = this.preliminaryAnalysisNodeGapIds,
        preliminaryAnalysisNodeId = this.preliminaryAnalysisNodeId,
        assessmentNodeId = this.assessmentNodeId,
        assessmentNodeGapIds = this.assessmentNodeGapIds,
        importantOrCriticalNodeId = this.importantOrCriticalNodeId;
      return {
        topNodeId: nodeId,
        registerNodeId: registerNodeId,
        registerNodeQuestionIds: registerNodeQuestionIds,
        preliminaryAnalysisNodeId: preliminaryAnalysisNodeId,
        preliminaryAnalysisNodeGapIds: preliminaryAnalysisNodeGapIds,
        assessmentNodeId: assessmentNodeId,
        assessmentNodeGapIds: assessmentNodeGapIds,
        importantOrCriticalNodeId: importantOrCriticalNodeId
      };
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.loading = true;
    this.fetchTopLevelNode().then(function () {
      return _this.loading = false;
    }).catch(function (error) {
      if (error.response && error.response.error) {
        _this.$emit('error', error.response.error);
      }
    });
  },
  methods: {
    fetchTopLevelNode: function fetchTopLevelNode() {
      var _this2 = this;
      return getSuggestedDataForTopLevelNode(this.projectId, this.nodeId).then(function (response) {
        _this2.details = response;
        _this2.registerNodeId = response.registerNodeId;
        _this2.selectedRegisterQuestions = _this2.registerQuestions.filter(function (question) {
          return response.registerQuestionIds.indexOf(question.id) !== -1;
        });
        _this2.preliminaryAnalysisNodeId = response.preliminaryAnalysisNodeId;
        _this2.selectedPreliminaryAnalysisGapArticles = _this2.preliminaryAnalysisNodeGapArticles.filter(function (gap) {
          return response.preliminaryAnalysisGapIds.indexOf(gap.id) !== -1;
        });
        _this2.assessmentNodeId = response.assessmentNodeId;
        _this2.selectedAssessmentNodeGapArticles = _this2.assessmentNodeGapArticles.filter(function (gap) {
          return response.assessmentNodeGapIds.indexOf(gap.id) !== -1;
        });
        _this2.importantOrCriticalNodeId = +response.importantOrCriticalNodeId;
      });
    },
    getGapsByNodeId: function getGapsByNodeId(nodeId) {
      return this.getQuestionsOrGapsByNodeId(nodeId, true);
    },
    getQuestionsByNodeId: function getQuestionsByNodeId(nodeId) {
      return this.getQuestionsOrGapsByNodeId(nodeId, false);
    },
    getQuestionsOrGapsByNodeId: function getQuestionsOrGapsByNodeId(nodeId, isGap) {
      var _ref = isGap ? ['gapSchemaId', 'gapArticles'] : ['questionnaireId', 'questions'],
        _ref2 = _slicedToArray(_ref, 2),
        idProperty = _ref2[0],
        dataProperty = _ref2[1];
      var node = this.childrenNodeMap.get(nodeId);
      if (!node || !node[idProperty]) {
        return [];
      }
      var id = node[idProperty];
      return this.details[dataProperty][id] || [];
    },
    questionLabelFunction: function questionLabelFunction(question) {
      if (question.id === '0') {
        return question.question; // Select all hack
      }

      return question.questionnumber + ' ' + question.question;
    },
    gapLabelFunction: function gapLabelFunction(gap) {
      if (gap.id === '0') {
        return gap.article; // Select all hack
      }

      return gap.article + ' ' + gap.articleTitle;
    },
    createReport: function createReport() {
      var _this3 = this;
      _createReport(this.projectId, this.nodeId, this.blocksQuery).then(function (report) {
        _this3.$router.push({
          name: 'OutsourcingReportShow',
          params: {
            reportId: report.id
          }
        });
      });
    }
  }
};