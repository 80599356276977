import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "dnd-area-text"
};
var _hoisted_2 = ["multiple"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_progress_bar = _resolveComponent("progress-bar");
  var _component_files_list = _resolveComponent("files-list");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.selectFile,
    class: "mt-4",
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
    class: _normalizeClass(["dnd-area mb-2 bg-white border border-solid border-gray-450 h-10 rounded-lg flex justify-center items-center", {
      'bg-gray-150 border-blue-50': $data.isDragOver
    }])
  }, [_createElementVNode("p", _hoisted_1, [_createTextVNode(_toDisplayString($data.translate.dragAndDropOr) + " ", 1 /* TEXT */), _createElementVNode("u", {
    class: "text-blue-450 cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.clickInput && $options.clickInput.apply($options, arguments);
    })
  }, _toDisplayString($data.translate.browse), 1 /* TEXT */)])], 2 /* CLASS */), _createElementVNode("input", {
    ref: "fileInput",
    class: "input-load hidden",
    multiple: $props.multiple,
    type: "file",
    onChange: _cache[1] || (_cache[1] = function (e) {
      return $options.handleLoad(e.target.files);
    })
  }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2), $data.uploading && $props.showProgressBar ? (_openBlock(), _createBlock(_component_progress_bar, {
    key: 0,
    value: $data.uploadingMax / $data.uploadingValue * 100
  }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), !$data.existingFiles.length ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 1,
    class: "text-center text-gray-550",
    title: $options.supportExtensionTitle,
    truncate: false,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), $props.showUploadedFiles ? (_openBlock(), _createBlock(_component_files_list, {
    key: 2,
    files: $data.existingFiles,
    onDelete: $options.deleteFile
  }, null, 8 /* PROPS */, ["files", "onDelete"])) : _createCommentVNode("v-if", true)]);
}