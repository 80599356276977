export var UserNotificationSettings = function UserNotificationSettings(getSettingValue) {
  return [{
    id: 1,
    module: 'All',
    notification: 'When added as responsible'
  }, {
    id: 2,
    module: 'All',
    notification: 'When added on daily team'
  }, {
    id: 3,
    module: 'All',
    notification: 'When message is received on a RISMA board'
  }, {
    id: 7,
    module: 'RISMAcontrols',
    notification: 'When a control is ready for you to review'
  }, {
    id: 10,
    module: 'RISMAcontrols',
    notification: 'Control review is approved'
  }, {
    id: 21,
    module: 'RISMAcontrols',
    notification: 'Control review is rejected'
  }, {
    id: 11,
    module: 'RISMAactions',
    notification: 'Initiative traffic light is set to red (manually)'
  }, {
    id: 13,
    module: 'RISMAactions',
    notification: 'Initiative has a new precursor'
  }, {
    id: 14,
    module: 'RISMAactions',
    notification: 'Initiative traffic light is set to red on precursor'
  }, {
    id: 15,
    module: 'RISMAactions',
    notification: 'Initiative has a new deadline on precursor'
  }, {
    id: 23,
    module: 'Contracts',
    notification: 'Notification upon expiration date'
  }, {
    id: 24,
    module: 'Contracts',
    notification: 'N days before expiration date'
  }, {
    id: 25,
    module: 'Contracts',
    notification: 'N days before renewal deadline'
  }, {
    id: 26,
    module: 'Contracts',
    notification: 'Termination deadline notification'
  }, {
    id: 27,
    module: 'Contracts',
    notification: 'N days before termination deadline'
  }].filter(function (setting) {
    if (getSettingValue('app.module_contracts_enabled')) return true;
    return setting.module !== 'Contracts';
  });
};