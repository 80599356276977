import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.saveCurrentReport,
    "button-ok-text": $data.translate.saveReport,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.doSaveReport,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [Object.values($data.errors).length ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values($data.errors), function (error) {
            return _openBlock(), _createElementBlock("div", {
              key: error
            }, _toDisplayString(error), 1 /* TEXT */);
          }), 128 /* KEYED_FRAGMENT */))];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        title: $data.translate.title,
        invalid: !!$data.errors.title,
        "model-value": $data.saveReportTitle,
        placeholder: $data.translate.enterYourOwnTitleForThisReportHere,
        "focus-on-mount": "",
        type: "text",
        "onUpdate:modelValue": $options.updateSaveReportTitle
      }, null, 8 /* PROPS */, ["title", "invalid", "model-value", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", null, [_createVNode(_component_input_field, {
        title: $data.translate.comments,
        invalid: !!$data.errors.description,
        "model-value": $data.saveReportDescription,
        placeholder: $data.translate.enterYourCommentsHere,
        type: "text",
        "onUpdate:modelValue": $options.updateSaveReportDescription
      }, null, 8 /* PROPS */, ["title", "invalid", "model-value", "placeholder", "onUpdate:modelValue"])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}