import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
var _hoisted_2 = {
  key: 0,
  class: "pl-3 text-sm text-red-50"
};
var _hoisted_3 = {
  class: "relative mb-3"
};
var _hoisted_4 = {
  key: 0,
  class: "mb-3 border-t border-gray-450"
};
var _hoisted_5 = {
  class: "py-2 font-bold text-gray-650"
};
var _hoisted_6 = {
  class: "flex items-baseline px-1 py-1 rounded-md cursor-default hover:bg-gray-350"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_selector = _resolveComponent("selector");
  var _component_category_modal = _resolveComponent("category-modal");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $options.modalHeaderTitle,
    "button-ok-text": $options.buttonOkLabel,
    "button-dismiss-text": $data.translate.close,
    "overflow-visible": true,
    onAccept: $options.handleAccept,
    onDismiss: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        modelValue: $props.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $props.title = $event;
        }),
        title: $data.translate.title,
        "focus-on-mount": true,
        "pre-text": $props.title,
        placeholder: $data.translate.title,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "pre-text", "placeholder"]), !$data.isTitleValid ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($data.translate.titleCannotBeEmpty), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
        class: "absolute right-4 flex cursor-pointer",
        onClick: _cache[1] || (_cache[1] = function () {
          return $options.openCreationModal && $options.openCreationModal.apply($options, arguments);
        })
      }, [_createVNode(_component_feather_icon, {
        icon: "plus"
      }), _createVNode(_component_risma_title, {
        title: $data.translate.createNew,
        type: "small"
      }, null, 8 /* PROPS */, ["title"])]), _createVNode(_component_selector, {
        items: $options.selectableCategories,
        title: $data.translate.selectCategory,
        "search-placeholder": $data.translate.clickToSelectCategory,
        "search-fields": ['title'],
        onSelected: $options.selectCategory
      }, {
        displayedName: _withCtx(function (_ref) {
          var item = _ref.item;
          return [_createElementVNode("span", null, _toDisplayString(item.title), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["items", "title", "search-placeholder", "onSelected"])]), $options.selectedCategory ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString($data.translate.addedCategory), 1 /* TEXT */), _createElementVNode("div", _hoisted_6, [_createElementVNode("p", null, _toDisplayString($options.selectedCategory.title), 1 /* TEXT */), _createVNode(_component_feather_icon, {
        icon: "x",
        width: "12",
        height: "12",
        class: _normalizeClass('stroke-gray stroke-3 hover:stroke-red-50 cursor-pointer'),
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return $options.deselect($options.selectedCategory);
        })
      })])])) : _createCommentVNode("v-if", true)]), $data.categoryModalShown ? (_openBlock(), _createBlock(_component_category_modal, {
        key: 0,
        onCreate: $options.handleCreateCategory,
        onDismiss: _cache[3] || (_cache[3] = function ($event) {
          return $data.categoryModalShown = false;
        })
      }, null, 8 /* PROPS */, ["onCreate"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}