import { useSettingsStore } from '@/Store/settingsStore';
import i18n from '@/i18n';
export function getProcessLibraryTitle() {
  var title = useSettingsStore().getValue('feature.process_library_name') || '';
  var pl = 'Process Library';
  if (!title) return i18n.t(pl);
  return title === pl ? i18n.t(title) : title;
}
export function getTitleFromSetting(setting) {
  return useSettingsStore().getValue(setting) || '';
}