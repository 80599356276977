function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var adminUser = {
  'objType': 'user',
  'id': 1,
  'username': 'ADM',
  'initials': 'ADM',
  'fullname': 'Test Site',
  'show': 'username',
  'display_name': 'ADM',
  'email': 'admSome@bigGDRPSystems.com',
  'level_execution': 4,
  'active': 1,
  'level_controls': 4,
  'level_risk': 4,
  'level_incidents': 4,
  'level_contracts': 4,
  'level_information_assets': 4,
  'is_admin': true,
  'lang': 'en_GB',
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'tag': 'GDPR',
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'enabled': 1,
    'access_level': 4,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 11,
    'title': 'ISO27001',
    'type': 2,
    'tag': 'ISO27001',
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'enabled': 1,
    'access_level': 3,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 19,
    'title': 'GDPR NY',
    'type': 1,
    'tag': 'GDPR',
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'enabled': 1,
    'access_level': 4,
    'supervisor': 0
  }],
  'policiesAccess': true,
  'iaAccess': true,
  'level_e_locked': 0,
  'level_r_locked': 0,
  'level_c_locked': 0,
  'level_incidents_locked': 0,
  'userHasProfileImage': false,
  'performance_report_access': 0
};
export var adminUserWithRisksProjects = _objectSpread(_objectSpread({}, adminUser), {}, {
  projects: [adminUser.projects, {
    id: 1,
    objType: 'risk-project',
    threats: [],
    threatsEnabled: 0,
    title: 'ERM',
    type: 1,
    vulnerabilities: [],
    customHeatmapEnabled: 0,
    heatmapOptions: [],
    access_level: 0,
    accumulatedRisks: false,
    confidentialRisks: 0,
    defaultActivityTypeId: null,
    enabled: 1,
    inherentRisksEnabled: false,
    riskApproval: 1,
    solutionId: null
  }, {
    id: 2,
    objType: 'risk-project',
    threats: [],
    threatsEnabled: 0,
    title: 'Information risk management',
    type: 2,
    vulnerabilities: [],
    customHeatmapEnabled: 0,
    heatmapOptions: [],
    access_level: 4,
    accumulatedRisks: false,
    confidentialRisks: 0,
    defaultActivityTypeId: null,
    enabled: 1,
    inherentRisksEnabled: false,
    riskApproval: 1,
    solutionId: null
  }]
});
export var regularUser = {
  'iaAccess': false
};
export var singleItem = {
  'active': true,
  'objType': 'user',
  'id': 45,
  'username': 'AAN',
  'initials': 'AAN',
  'fullname': 'Arne Andersen',
  'display_name': 'AAN',
  'email': 'aan2@risma.dk',
  'level_execution': 1,
  'level_controls': 1,
  'level_incidents': 1,
  'level_risk': 1,
  'is_admin': false,
  'lang': null,
  'orgs': ',179,',
  'organisations': [179],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
};
export var itemList = [{
  active: true,
  'objType': 'user',
  'id': 45,
  'username': 'AAN',
  'initials': 'AAN',
  'fullname': 'Arne Andersen',
  'display_name': 'AAN',
  'email': 'aan2@risma.dk',
  'level_execution': 1,
  'level_controls': 1,
  'level_incidents': 1,
  'level_risk': 1,
  'is_admin': false,
  'iaAccess': true,
  'lang': null,
  'orgs': ',179,',
  'organisations': [179],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1,
  'username': 'ADM',
  'initials': 'NAS',
  'fullname': 'BusinessDemo Template',
  'display_name': 'ADM',
  'email': 'template@rismasystems.com',
  'level_execution': 4,
  'level_controls': 4,
  'level_incidents': 4,
  'level_risk': 4,
  'is_admin': true,
  'iaAccess': true,
  'lang': 'en_GB',
  'orgs': ',197,',
  'organisations': [197],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 4,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 4,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 4,
    'supervisor': 0
  }],
  'policiesAccess': true
}, {
  active: true,
  'objType': 'user',
  'id': 1000,
  'username': 'ADMOLD',
  'initials': 'ADM_OLD',
  'fullname': 'Demo Administrator X',
  'display_name': 'ADMOLD',
  'email': 'adm@rismasystems.com',
  'level_execution': 4,
  'level_controls': 4,
  'level_risk': 4,
  'is_admin': true,
  'iaAccess': true,
  'lang': 'da_DK',
  'orgs': ',172,181,189,',
  'organisations': [172, 181, 189],
  'organisationsReadonly': [178, 194],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 4,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 4,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 4,
    'supervisor': 0
  }],
  'policiesAccess': true
}, {
  active: true,
  'objType': 'user',
  'id': 43,
  'username': 'ANM',
  'initials': 'ANM',
  'fullname': 'Anders Madsen',
  'display_name': 'ANM',
  'email': 'anm2@risma.dk',
  'level_execution': 2,
  'level_controls': 0,
  'level_risk': 0,
  'is_admin': false,
  'iaAccess': true,
  'lang': null,
  'orgs': ',59,',
  'organisations': [59],
  'organisationsReadonly': [170],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 44,
  'username': 'BBI',
  'initials': 'BBI',
  'fullname': 'Bjarne Binderkranz',
  'display_name': 'BBI',
  'email': 'bbi2@risma.dk',
  'level_execution': 2,
  'level_controls': 0,
  'level_risk': 2,
  'is_admin': false,
  'lang': null,
  'orgs': ',173,',
  'organisations': [173],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 46,
  'username': 'CCA',
  'initials': 'CCA',
  'fullname': 'Claus Carlsen',
  'display_name': 'CCA',
  'email': 'cca2@risma.dk',
  'level_execution': 2,
  'level_controls': 2,
  'level_risk': 2,
  'is_admin': false,
  'lang': null,
  'orgs': ',172,',
  'organisations': [172],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 42,
  'username': 'CEO',
  'initials': 'CEO',
  'fullname': 'Carl Erik Olsen',
  'display_name': 'CEO',
  'email': 'ceo2@risma.dk',
  'level_execution': 1,
  'level_controls': 1,
  'level_risk': 1,
  'is_admin': false,
  'lang': null,
  'orgs': ',53,',
  'organisations': [53],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 58,
  'username': 'CFO',
  'initials': 'CFO',
  'fullname': 'Christina Forchhammer',
  'display_name': 'CFO',
  'email': 'cfo2@risma.dk',
  'level_execution': 0,
  'level_controls': 0,
  'level_risk': 3,
  'is_admin': false,
  'lang': null,
  'orgs': ',171,',
  'organisations': [171],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 49,
  'username': 'CPO',
  'initials': 'CPO',
  'fullname': 'Christian Poulsen',
  'display_name': 'CPO',
  'email': 'cpo2@risma.dk',
  'level_execution': 2,
  'level_controls': 3,
  'level_risk': 2,
  'is_admin': false,
  'lang': null,
  'orgs': ',180,',
  'organisations': [180],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1439,
  'username': 'CRNtest',
  'initials': 'XYZ',
  'fullname': 'CRN test',
  'display_name': 'CRNtest',
  'email': 'crntest@risma.dk',
  'level_execution': 1,
  'level_controls': 1,
  'level_risk': 1,
  'is_admin': false,
  'lang': 'da_DK',
  'orgs': ',,',
  'organisations': [],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1164,
  'username': 'DBL',
  'initials': 'DBL',
  'fullname': 'Diego',
  'display_name': 'DBL',
  'email': 'dbl@rismasystems.com',
  'level_execution': 4,
  'level_controls': 4,
  'level_risk': 4,
  'is_admin': true,
  'lang': null,
  'orgs': ',172,',
  'organisations': [172],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 4,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 4,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 4,
    'supervisor': 0
  }],
  'policiesAccess': true
}, {
  active: true,
  'objType': 'user',
  'id': 47,
  'username': 'DDI',
  'initials': 'DDI',
  'fullname': 'Dorthe Didriksen',
  'display_name': 'DDI',
  'email': 'ddi2@risma.dk',
  'level_execution': 2,
  'level_controls': 1,
  'level_risk': 2,
  'is_admin': false,
  'lang': null,
  'orgs': ',177,',
  'organisations': [177],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1485,
  'username': 'ENG',
  'initials': '',
  'fullname': 'Eng sprog',
  'display_name': 'ENG',
  'email': 'eng@risma.com',
  'level_execution': 2,
  'level_controls': 2,
  'level_risk': 2,
  'is_admin': false,
  'lang': null,
  'orgs': ',,',
  'organisations': [],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1486,
  'username': 'ENG1',
  'initials': '',
  'fullname': 'xxx',
  'display_name': 'ENG1',
  'email': 'xc@risma.dk',
  'level_execution': 2,
  'level_controls': 2,
  'level_risk': 2,
  'is_admin': false,
  'lang': null,
  'orgs': ',,',
  'organisations': [],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 50,
  'username': 'GOG',
  'initials': 'GOG',
  'fullname': 'Gorm Gregersen',
  'display_name': 'GOG',
  'email': 'gog2@rismasystems.com',
  'level_execution': 0,
  'level_controls': 1,
  'level_risk': 0,
  'is_admin': false,
  'lang': null,
  'orgs': ',181,',
  'organisations': [181],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 1,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1479,
  'username': 'GOG12',
  'initials': 'GOG12',
  'fullname': 'Gitte Olsen Gardner',
  'display_name': 'GOG12',
  'email': 'GOG1@risma.dk',
  'level_execution': 0,
  'level_controls': 3,
  'level_risk': 0,
  'is_admin': false,
  'lang': null,
  'orgs': ',173,',
  'organisations': [173],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 51,
  'username': 'HAH',
  'initials': 'HAH',
  'fullname': 'Hanne Hansen',
  'display_name': 'HAH',
  'email': 'hah2@risma.dk',
  'level_execution': 2,
  'level_controls': 0,
  'level_risk': 1,
  'is_admin': false,
  'lang': null,
  'orgs': ',171,',
  'organisations': [171],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 52,
  'username': 'JOJ',
  'initials': 'JOJ',
  'fullname': 'Joachim Jønsson',
  'display_name': 'JOJ',
  'email': 'joj2@risma.dk',
  'level_execution': 1,
  'level_controls': 1,
  'level_risk': 1,
  'is_admin': false,
  'lang': null,
  'orgs': ',170,',
  'organisations': [170],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 53,
  'username': 'KAB',
  'initials': 'KAB',
  'fullname': 'Kasper',
  'display_name': 'KAB',
  'email': 'risma@rismasystems.com',
  'level_execution': 4,
  'level_controls': 4,
  'level_risk': 4,
  'is_admin': true,
  'lang': 'en_GB',
  'orgs': ',186,',
  'organisations': [186],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 4,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 4,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 4,
    'supervisor': 0
  }],
  'policiesAccess': true
}, {
  active: true,
  'objType': 'user',
  'id': 54,
  'username': 'LAL',
  'initials': 'LAL',
  'fullname': 'Lauritz Larsen',
  'display_name': 'LAL',
  'email': 'lal2@risma.dk',
  'level_execution': 1,
  'level_controls': 0,
  'level_risk': 0,
  'is_admin': false,
  'lang': null,
  'orgs': ',60,',
  'organisations': [60],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1491,
  'username': 'NASTEST',
  'initials': '',
  'fullname': 'Nicolai test opret 2',
  'display_name': 'NASTEST',
  'email': 'nnn@risma.dk',
  'level_execution': 3,
  'level_controls': 3,
  'level_risk': 3,
  'is_admin': false,
  'lang': null,
  'orgs': ',,',
  'organisations': [],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1490,
  'username': 'NASTEST3',
  'initials': '',
  'fullname': 'Nicolai test opret',
  'display_name': 'NASTEST3',
  'email': 'nnn3@risma.dk',
  'level_execution': 4,
  'level_controls': 4,
  'level_risk': 4,
  'is_admin': true,
  'lang': null,
  'orgs': ',177,',
  'organisations': [177],
  'organisationsReadonly': [61],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 4,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 4,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 4,
    'supervisor': 0
  }],
  'policiesAccess': true
}, {
  active: true,
  'objType': 'user',
  'id': 55,
  'username': 'NIN',
  'initials': 'NIN',
  'fullname': 'Niels Normann',
  'display_name': 'NIN',
  'email': 'nin2@risma.dk',
  'level_execution': 1,
  'level_controls': 1,
  'level_risk': 1,
  'is_admin': false,
  'lang': null,
  'orgs': ',182,',
  'organisations': [182],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 60,
  'username': 'Nicolaitest æøå',
  'initials': 'IVI',
  'fullname': 'Ivan Iversen',
  'display_name': 'Nicolaitest æøå',
  'email': 'ivi@risma.dk',
  'level_execution': 2,
  'level_controls': 1,
  'level_risk': 1,
  'is_admin': false,
  'lang': null,
  'orgs': ',172,',
  'organisations': [172],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 56,
  'username': 'ORO',
  'initials': 'ORO',
  'fullname': 'Orla Olsen',
  'display_name': 'ORO',
  'email': 'oro2@risma.dk',
  'level_execution': 3,
  'level_controls': 0,
  'level_risk': 0,
  'is_admin': false,
  'lang': null,
  'orgs': ',178,',
  'organisations': [178],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 57,
  'username': 'PEP',
  'initials': 'PEP',
  'fullname': 'Peter Povlsen',
  'display_name': 'PEP',
  'email': 'pep2@risma.dk',
  'level_execution': 2,
  'level_controls': 0,
  'level_risk': 0,
  'is_admin': false,
  'lang': null,
  'orgs': ',171,',
  'organisations': [171],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 39,
  'username': 'PRI',
  'initials': 'PRI',
  'fullname': 'Demo Privileged User',
  'display_name': 'PRI',
  'email': 'pri2@rismasystems.com',
  'level_execution': 2,
  'level_controls': 2,
  'level_risk': 2,
  'is_admin': false,
  'lang': null,
  'orgs': ',197,',
  'organisations': [197],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1478,
  'username': 'PRIL',
  'initials': 'PRIL',
  'fullname': 'Låst privilegeret bruger',
  'display_name': 'PRIL',
  'email': 'PRIL@risma.dk',
  'level_execution': 2,
  'level_controls': 2,
  'level_risk': 2,
  'is_admin': false,
  'lang': null,
  'orgs': ',,',
  'organisations': [],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 38,
  'username': 'REG',
  'initials': 'REG',
  'fullname': 'Demo Regular User',
  'display_name': 'REG',
  'email': 'reg@rismasystems.com',
  'level_execution': 1,
  'level_controls': 1,
  'level_risk': 1,
  'is_admin': false,
  'lang': null,
  'orgs': ',53,197,',
  'organisations': [53, 197],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1109,
  'username': 'REGL',
  'initials': 'REGL',
  'fullname': 'Regular User L',
  'display_name': 'REGL',
  'email': 'regl@risma.dk',
  'level_execution': 1,
  'level_controls': 1,
  'level_risk': 1,
  'is_admin': false,
  'lang': null,
  'orgs': ',,',
  'organisations': [],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1157,
  'username': 'Riskbruger',
  'initials': 'Riskbruger',
  'fullname': 'Risk Bruger',
  'display_name': 'Riskbruger',
  'email': 'riskbruger@risma.dk',
  'level_execution': 0,
  'level_controls': 0,
  'level_risk': 3,
  'is_admin': false,
  'lang': null,
  'orgs': ',170,',
  'organisations': [170],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 40,
  'username': 'SUP',
  'initials': 'SUP',
  'fullname': 'Demo Super User',
  'display_name': 'SUP',
  'email': 'sup2@rismasystems.com',
  'level_execution': 3,
  'level_controls': 3,
  'level_risk': 3,
  'is_admin': false,
  'lang': 'en_GB',
  'orgs': ',53,197,',
  'organisations': [53, 197],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 3,
    'supervisor': 1
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': true
}, {
  active: true,
  'objType': 'user',
  'id': 1477,
  'username': 'SUPL',
  'initials': 'SUPL',
  'fullname': 'Låst superbruger',
  'display_name': 'SUPL',
  'email': 'SUPL@risma.dk',
  'level_execution': 3,
  'level_controls': 3,
  'level_risk': 3,
  'is_admin': false,
  'lang': 'da_DK',
  'orgs': ',,',
  'organisations': [],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 3,
    'supervisor': 1
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': true
}, {
  active: true,
  'objType': 'user',
  'id': 59,
  'username': 'SUS',
  'initials': 'SUS',
  'fullname': 'Susse Skougaard',
  'display_name': 'SUS',
  'email': 'sus2@risma.dk',
  'level_execution': 0,
  'level_controls': 0,
  'level_risk': 0,
  'is_admin': false,
  'lang': null,
  'orgs': ',176,',
  'organisations': [176],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1482,
  'username': 'TO',
  'initials': '',
  'fullname': 'Teser organisation',
  'display_name': 'TO',
  'email': 'test@risma.sk',
  'level_execution': 2,
  'level_controls': 2,
  'level_risk': 2,
  'is_admin': false,
  'lang': null,
  'orgs': ',197,',
  'organisations': [197],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1438,
  'username': 'Test',
  'initials': 'Test',
  'fullname': 'Test',
  'display_name': 'Test',
  'email': 'test11@risma.dk',
  'level_execution': 2,
  'level_controls': 1,
  'level_risk': 1,
  'is_admin': false,
  'lang': 'da_DK',
  'orgs': ',,',
  'organisations': [],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1475,
  'username': 'Test1',
  'initials': 'Test1',
  'fullname': 'Test bruger 1',
  'display_name': 'Test1',
  'email': 'test1@risma.dk',
  'level_execution': 3,
  'level_controls': 3,
  'level_risk': 3,
  'is_admin': false,
  'lang': null,
  'orgs': ',181,182,183,',
  'organisations': [181, 182, 183],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1474,
  'username': 'Testbruger',
  'initials': 'Testbruger',
  'fullname': 'Testbruger',
  'display_name': 'Testbruger',
  'email': 'testtest@risma.dk',
  'level_execution': 3,
  'level_controls': 3,
  'level_risk': 3,
  'is_admin': false,
  'lang': 'da_DK',
  'orgs': ',,',
  'organisations': [],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1165,
  'username': 'USR',
  'initials': 'USR',
  'fullname': 'User Swapper',
  'display_name': 'USR',
  'email': 'sutmin@lige.nu',
  'level_execution': 1,
  'level_controls': 1,
  'level_risk': 1,
  'is_admin': false,
  'lang': null,
  'orgs': ',59,',
  'organisations': [59],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 2,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': true
}, {
  active: true,
  'objType': 'user',
  'id': 48,
  'username': 'controlsbruger',
  'initials': 'controlsbruger',
  'fullname': 'Carl Christian Olesen',
  'display_name': 'controlsbruger',
  'email': 'cco2@risma.dk',
  'level_execution': 0,
  'level_controls': 2,
  'level_risk': 0,
  'is_admin': false,
  'lang': null,
  'orgs': ',61,',
  'organisations': [61],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1441,
  'username': 'dansk',
  'initials': 'dansk',
  'fullname': 'dansk',
  'display_name': 'dansk',
  'email': 'dansk@rismasytems.com',
  'level_execution': 1,
  'level_controls': 1,
  'level_risk': 1,
  'is_admin': false,
  'lang': 'da_DK',
  'orgs': ',,',
  'organisations': [],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 3,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': true
}, {
  active: true,
  'objType': 'user',
  'id': 1476,
  'username': 'exebruger',
  'initials': 'exebruger',
  'fullname': 'Execution bruger',
  'display_name': 'exebruger',
  'email': 'exec@risma.dk',
  'level_execution': 1,
  'level_controls': 0,
  'level_risk': 0,
  'is_admin': false,
  'lang': null,
  'orgs': ',,',
  'organisations': [],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1440,
  'username': 'sprog',
  'initials': 'sprog',
  'fullname': 'sprogtest',
  'display_name': 'sprog',
  'email': 'testa@rimasystems.com',
  'level_execution': 1,
  'level_controls': 1,
  'level_risk': 1,
  'is_admin': false,
  'lang': null,
  'orgs': ',,',
  'organisations': [],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}, {
  active: true,
  'objType': 'user',
  'id': 1437,
  'username': 'ÆØÅ',
  'initials': 'ÆØÅ',
  'fullname': 'ÆØÅ',
  'display_name': 'ÆØÅ',
  'email': 'aeoeaa@risma.dk',
  'level_execution': 1,
  'level_controls': 1,
  'level_risk': 1,
  'is_admin': false,
  'lang': null,
  'orgs': ',,',
  'organisations': [],
  'organisationsReadonly': [],
  'projects': [{
    'objType': 'compliance-project',
    'id': 1,
    'title': 'GDPR',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': 0
  }, {
    'objType': 'compliance-project',
    'id': 3,
    'title': 'ISO27001',
    'type': 2,
    'questionnaireType': 2,
    'reports': {
      'questionnaire': {
        'predefinedSearch': 'Article30',
        'header': 'Questionnaire',
        'table': 'article30',
        'questionnaireType': 'ISO27001'
      }
    },
    'access_level': 0,
    'supervisor': null
  }, {
    'objType': 'compliance-project',
    'id': 4,
    'title': 'Test',
    'type': 1,
    'questionnaireType': 1,
    'reports': {
      'article-30': {
        'predefinedSearch': 'Article30',
        'header': 'Article 30',
        'table': 'article30',
        'questionnaireType': 'GDPR'
      }
    },
    'access_level': 0,
    'supervisor': null
  }],
  'policiesAccess': false
}];
export var mockUsers = [{
  id: 1,
  label: 'User A'
}, {
  id: 2,
  label: 'User B'
}, {
  id: 3,
  label: 'User C'
}, {
  id: 4,
  label: 'User D'
}, {
  id: 5,
  label: 'User E'
}, {
  id: 6,
  label: 'User F'
}, {
  id: 7,
  label: 'User G'
}, {
  id: 8,
  label: 'User H'
}, {
  id: 9,
  label: 'User I'
}, {
  id: 10,
  label: 'User A1'
}, {
  id: 11,
  label: 'User B2'
}, {
  id: 12,
  label: 'User C3'
}, {
  id: 13,
  label: 'User D4'
}, {
  id: 14,
  label: 'User E5'
}, {
  id: 15,
  label: 'User F6'
}, {
  id: 16,
  label: 'User G7'
}, {
  id: 17,
  label: 'User H8'
}, {
  id: 18,
  label: 'User I9'
}];