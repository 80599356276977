import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  style: {
    "position": "relative",
    "height": "10rem",
    "margin-top": "1rem"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_traffic_light_tool_tip = _resolveComponent("traffic-light-tool-tip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_traffic_light_tool_tip, {
    hidden: false,
    type: "gaps"
  })]);
}