export var AdminActivityTypesMixin = {
  computed: {
    editFieldHeader: function editFieldHeader() {
      return this.$trans('Update activity type');
    },
    editFieldSubmit: function editFieldSubmit() {
      return this.$trans('Save');
    },
    createFieldHeader: function createFieldHeader() {
      return this.$trans('Create activity type');
    },
    createFieldSubmit: function createFieldSubmit() {
      return this.$trans('Create');
    }
  },
  methods: {
    showSuccessMessage: function showSuccessMessage(msg) {
      this.notificationSuccess = msg;
      this.notificationError = null;
      if (this.$refs.notifSuccess) {
        this.$refs.notifSuccess.resetDismiss();
      }
    },
    showErrorMessage: function showErrorMessage(msg) {
      this.notificationError = msg;
      this.notificationSuccess = null;
      if (this.$refs.notifError) {
        this.$refs.notifError.resetDismiss();
      }
    }
  }
};