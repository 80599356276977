import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DateFormats } from '@/constants/DateFormats';
dayjs.extend(utc);
export function calculateDifference(dateA, dateB, unitTime) {
  return dayjs(dateA).diff(dayjs(dateB), unitTime);
}
export function calculateUTCDifference(dateA, dateB) {
  return dayjs.utc(dateA).diff(dayjs.utc(dateB));
}
export function calculateDifferenceAsDays(dateA, dateB) {
  return calculateDifference(dateA, dateB, 'days');
}
export function getDateBeforeDate(date, amount, unit) {
  return dayjs(date).subtract(amount, unit);
}
export function getDateBeforeToday(amount, unit) {
  return dayjs().subtract(amount, unit);
}
export function getFormattedDateBeforeToday(amount, unit) {
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DateFormats.DATE_FORMAT;
  return dayjs().subtract(amount, unit).format(format);
}
export function getFormattedUTCDateBeforeToday(amount, unit) {
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DateFormats.DATE_FORMAT;
  return dayjs.utc().subtract(amount, unit).format(format);
}
export function calculateStartOfDateAsDate(startUnit) {
  return dayjs().startOf(startUnit).toDate();
}
export function calculateStartDateBeforeTodayAsDate(value, unit, startUnit) {
  return dayjs().subtract(value, unit).startOf(startUnit).toDate();
}
export function calculatePastEndDateAsDate(value, unit, endUnit) {
  return dayjs().subtract(value, unit).endOf(endUnit).toDate();
}
export function calculateEndDateAsDate(endUnit) {
  return dayjs().endOf(endUnit).toDate();
}
export function calculateFutureEndDateByMonthsAsDate(months) {
  return dayjs().add(months, 'month').endOf('month').toDate();
}
export function getStartOfDateUnitFormatted(unit) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DateFormats.DATE_TIME_FORMAT_FULL;
  return dayjs().startOf(unit).format(format);
}
export function getEndOfDateUnitFormatted(unit) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DateFormats.DATE_TIME_FORMAT_FULL;
  return dayjs().endOf(unit).format(format);
}
export function getFortnightDateRange(format) {
  return {
    startDate: getFormattedDateBeforeToday(2, 'week', format),
    endDate: dayjs().format(format)
  };
}