import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-191fe786"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "input-field"
};
var _hoisted_2 = ["multiple"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("input", {
    multiple: $props.multiple,
    type: "file",
    onChange: _cache[0] || (_cache[0] = function () {
      return $options.handleChange && $options.handleChange.apply($options, arguments);
    })
  }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2)]);
}