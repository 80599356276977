import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-22378018"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 3
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_single_select = _resolveComponent("single-select");
  var _component_static_table = _resolveComponent("static-table");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_no_search_results = _resolveComponent("no-search-results");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [$data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: $data.notificationType
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.notificationMessage), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), _createVNode(_component_single_select, {
        modelValue: $data.mainFilter,
        "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
          return $data.mainFilter = $event;
        }), $options.resetAdditionalFilter],
        title: $data.translate.removeApproval,
        options: $options.options,
        placeholder: $data.translate.selectOne,
        class: "mb-4"
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder", "onUpdate:modelValue"]), $options.isUsersSelectorShown ? (_openBlock(), _createBlock(_component_single_select, {
        key: 1,
        modelValue: $data.selectedUser,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.selectedUser = $event;
        }),
        options: $options.usersOptions,
        placeholder: $data.translate.selectOne,
        class: "mb-4"
      }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])) : _createCommentVNode("v-if", true), $options.isOrganisationsSelectorShown ? (_openBlock(), _createBlock(_component_single_select, {
        key: 2,
        modelValue: $data.selectedOrganisation,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.selectedOrganisation = $event;
        }),
        options: $options.organisationsOptions,
        placeholder: $data.translate.selectOne,
        class: "mb-4"
      }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])) : _createCommentVNode("v-if", true), $options.showTable && $options.filteredRisks.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_static_table, {
        columns: $options.columns,
        dataset: $options.dataset
      }, null, 8 /* PROPS */, ["columns", "dataset"]), _createVNode(_component_risma_button, {
        text: "".concat($data.translate.clear),
        type: "save",
        class: "mt-4 inline-block",
        onClick: $options.handleClear
      }, null, 8 /* PROPS */, ["text", "onClick"])])) : _createCommentVNode("v-if", true), $options.showTable && !$options.filteredRisks.length ? (_openBlock(), _createBlock(_component_no_search_results, {
        key: 4,
        body: ""
      })) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  });
}