export var SortFieldsCompatibility = {
  ACCOUNTABLE_USER_IDS: 'accountableUserIds',
  ACCOUNTS: 'accounts',
  APPROVED: 'approved',
  CPATH: 'cpath',
  CREATED: 'created',
  CREATED_AT: 'createdAt',
  DEADLINE: 'deadline',
  EXPIRATION_DATE: 'endDate',
  DESCRIPTION: 'description',
  INCID_HAPPEND_AT: 'incidentHappenedAt',
  INCID_ARCHIVED_AT: 'updatedAt',
  LIKE_HOOD_SCORE: 'likelihood_score',
  MESSAGE: 'message',
  OBJ_TYPE_IA: 'objType',
  ORG_IDS: 'organisationIds',
  PROJECT_TITLE: 'project.title',
  PROCESSES: 'processes',
  RISKS: 'risks',
  QUEST_ANSWERS: 'values.questions_answered',
  RESPONSIBLE_USER_IDS: 'responsibleUserIds',
  REVIEW_DEADLINE: 'reviewDeadline',
  RISMA_STATE: 'rismaState',
  RNO: 'rno',
  SERVITY: 'severity',
  START: 'start',
  STATUS: 'status',
  TAGS: 'tags',
  TARGET: 'target',
  TITLE: 'title',
  TITLE_IA: 'titleIA',
  USER_IDS_RESPONSIBLE: 'userIds.responsible'
};