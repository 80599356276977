function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { getDocumentTypesAll } from '@/api/admin/documentTypes';
import { getTableContracts } from '@/api/contracts/contracts';
import * as fileAPI from '@/api/risma/file';
import { getWorkflowStatusesByModule } from '@/api/risma/data';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import StaticTable from '@/components/Molecules/StaticTable';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { MODULES } from '@/constants/modules';
import { toLocalDateTimeFull } from '@/utils/date';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { findPropsByIds } from '@/utils/Utils';
import { DocumentTypesIds } from '@/constants/contracts/DocumentTypes';
import { getFiltersQueryString } from '@/api/httpUtils';
export default {
  name: 'ContractsFileDocuments',
  description: 'Component for displaying a table with the Contract\'s Documents',
  token: '<contracts-file-documents />',
  components: {
    RismaButton: RismaButton,
    PreviewModalLink: PreviewModalLink,
    MainWell: MainWell,
    FeatherIcon: FeatherIcon,
    StaticTable: StaticTable,
    InputField: InputField,
    RismaTitle: RismaTitle,
    RismaSelect: RismaSelect,
    RismaTitleWithIcon: RismaTitleWithIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    isContent: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is content page'
    }
  },
  emits: ['dismissModal'],
  data: function data() {
    return {
      files: [],
      searchField: '',
      contractStatuses: [],
      selectedFileTypes: [],
      selectedContractStatuses: [],
      contracts: [],
      searchClicked: false,
      documentTypes: [],
      translate: getTranslate['ContractsFileDocuments'](),
      isLoaded: false,
      isTableDataLoading: false
    };
  },
  computed: {
    columns: function columns() {
      return [this.translate.title, this.translate.contract, this.translate.uploadDate, this.translate.type, this.translate.versions, ''];
    },
    enhancedFiles: function enhancedFiles() {
      var _this = this;
      return this.files.map(function (file) {
        var fileContract = _this.contracts.find(function (contract) {
          return file.moduleId === contract.id;
        });
        return _objectSpread(_objectSpread({}, file), {}, {
          contract: fileContract
        });
      });
    },
    dataset: function dataset() {
      var _this2 = this;
      return this.enhancedFiles.map(function (item) {
        var typeString = findPropsByIds(item.docTypeIds, _this2.fileTypes, 'label', ', ');
        typeString || (typeString = _this2.$trans('Not specified'));
        return {
          title: {
            url: item.url,
            filename: item.filename,
            title: item.title || ''
          },
          contract: {
            activity: item.contract,
            hoverTitle: DatatableRenderer.getItemHoverTitle(item.contract)
          },
          upload: toLocalDateTimeFull(item.created),
          type: typeString,
          versions: item.version,
          download: item
        };
      });
    },
    localStorageKey: function localStorageKey() {
      return this.isContent ? 'contract-content-filters' : 'contract-documents-filters';
    },
    fileTypes: function fileTypes() {
      var _this3 = this;
      var map = this.documentTypes.map(function (type) {
        return {
          id: type.id,
          label: _this3.$trans(type.name)
        };
      });
      map.unshift({
        id: DocumentTypesIds.UNKNOWN,
        label: this.$trans('Not specified')
      });
      return map;
    },
    preparedContractStatuses: function preparedContractStatuses() {
      var _this4 = this;
      return this.contractStatuses.map(function (item) {
        return {
          id: item.id,
          label: _this4.$trans(item.title)
        };
      });
    },
    searchTitle: function searchTitle() {
      return this.$trans(this.isContent ? 'Full text search' : 'Search');
    },
    preparedFilterParams: function preparedFilterParams() {
      var params = '';
      if (this.selectedContractStatuses.length > 0) {
        this.selectedContractStatuses.forEach(function (contractStatus) {
          params += '&filters[workflowStatusId][]=' + contractStatus.id;
        });
      }
      if (this.selectedFileTypes.length > 0) {
        this.selectedFileTypes.forEach(function (fileType) {
          params += '&filters[documentTypeId][]=' + fileType.id;
        });
      }
      if (this.isContent && this.searchField) {
        params += '&filters[content]=' + this.searchField;
      }
      return params;
    },
    preparedSearchParam: function preparedSearchParam() {
      var result = '&filters[type]=file';
      if (!this.isContent && this.searchField) {
        result += '&search=' + this.searchField;
      }
      return result;
    }
  },
  mounted: function mounted() {
    var _this5 = this;
    var storageValue = localStorage.getItem(this.localStorageKey);
    this.getData().then(function () {
      if (storageValue) {
        _this5.getValuesFromStorage(storageValue);
      } else {
        _this5.selectedFileTypes = _this5.fileTypes;
        _this5.selectedContractStatuses = _this5.preparedContractStatuses;
      }
    }).finally(function () {
      return _this5.isLoaded = true;
    });
  },
  methods: {
    getValuesFromStorage: function getValuesFromStorage(storageValue) {
      var _JSON$parse = JSON.parse(storageValue),
        selectedContractStatuses = _JSON$parse.selectedContractStatuses,
        selectedFileTypes = _JSON$parse.selectedFileTypes;
      this.selectedFileTypes = this.fileTypes.filter(function (type) {
        return selectedFileTypes.includes(type.id);
      });
      this.selectedContractStatuses = this.preparedContractStatuses.filter(function (type) {
        return selectedContractStatuses.includes(type.id);
      });
    },
    loadFiles: function loadFiles() {
      var _this6 = this;
      return fileAPI.searchFiles("?module=contracts".concat(this.preparedSearchParam).concat(this.preparedFilterParams)).then(function (_ref) {
        var list = _ref.list;
        return _this6.files = list;
      });
    },
    getData: function getData() {
      return Promise.all([this.getWorkflowStatuses(), this.getDocumentTypes()]);
    },
    getDocumentTypes: function getDocumentTypes() {
      var _this7 = this;
      return getDocumentTypesAll().then(function (_ref2) {
        var list = _ref2.list;
        _this7.documentTypes = list;
      });
    },
    getWorkflowStatuses: function getWorkflowStatuses() {
      var _this8 = this;
      return getWorkflowStatusesByModule(MODULES.CONTRACTS).then(function (_ref3) {
        var list = _ref3.list;
        return _this8.contractStatuses = list;
      });
    },
    getContracts: function getContracts() {
      var _this9 = this;
      var query = this.getContractsFiltersQuery();
      return getTableContracts(query).then(function (_ref4) {
        var list = _ref4.list;
        return _this9.contracts = list;
      });
    },
    getContractsFiltersQuery: function getContractsFiltersQuery() {
      var ids = this.files.map(function (file) {
        return file.moduleId;
      });
      var uniqueIds = _toConsumableArray(new Set(ids));
      return getFiltersQueryString('id', uniqueIds);
    },
    selectContractStatus: function selectContractStatus(e) {
      this.selectedContractStatuses = e;
    },
    selectFileTypes: function selectFileTypes(e) {
      this.selectedFileTypes = e;
    },
    doSearch: function doSearch() {
      var _this10 = this;
      this.isTableDataLoading = true;
      this.saveFiltersToLocalStorage();
      if (this.isContent && !this.searchField) {
        this.searchClicked = true;
        this.isTableDataLoading = false;
        return;
      }
      this.loadFiles().then(function () {
        if (_this10.files.length) return _this10.getContracts();
      }).then(function () {
        _this10.searchClicked = true;
        _this10.isTableDataLoading = false;
      });
    },
    resetFilter: function resetFilter() {
      this.selectedContractStatuses = [];
      this.selectedFileTypes = [];
      this.searchField = '';
      this.saveFiltersToLocalStorage();
    },
    saveFiltersToLocalStorage: function saveFiltersToLocalStorage() {
      var preparedDataToSave = JSON.stringify({
        selectedContractStatuses: this.selectedContractStatuses.map(function (_ref5) {
          var id = _ref5.id;
          return id;
        }),
        selectedFileTypes: this.selectedFileTypes.map(function (_ref6) {
          var id = _ref6.id;
          return id;
        })
      });
      localStorage.setItem(this.localStorageKey, preparedDataToSave);
    }
  }
};