function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { toLocalDateTime } from '@/utils/date';
import { deleteReport, getReports } from '@/api/compliance/reports/outsourcing';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Alert from '@/components/Molecules/Modal/Alert';
import * as Utils from '@/utils/Utils';
export default {
  name: 'ReportList',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    Alert: Alert
  },
  props: {
    projectId: {
      type: [Number, String],
      required: true
    },
    nodeId: {
      type: [Number, String],
      required: false,
      default: null
    },
    reportId: {
      type: [Number, String],
      required: false,
      default: null
    }
  },
  emits: ['error'],
  data: function data() {
    return {
      idToDelete: null,
      showConfirmationAlert: false,
      toLocalDateTime: toLocalDateTime,
      reports: [],
      translate: getTranslate['ReportList']()
    };
  },
  computed: _objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      return Promise.all([this.fetchReports()]);
    },
    fetchReports: function fetchReports() {
      var _this = this;
      getReports(this.projectId, this.nodeId).then(function (response) {
        _this.reports = response.list;
        var isListRoute = _this.$route.name === 'OutsourcingReportList';
        if (!response.list.length && isListRoute) _this.$router.push({
          name: 'OutsourcingReportCreate'
        });
      });
    },
    onDeleteConfirm: function onDeleteConfirm() {
      this.showConfirmationAlert = false;
      deleteReport(this.projectId, this.nodeId, this.idToDelete).then(this.fetchReports);
    },
    deleteReport: function deleteReport(reportId) {
      this.idToDelete = reportId;
      this.showConfirmationAlert = true;
    },
    getUserNameById: function getUserNameById(id) {
      return Utils.findPropById(id, this.users, 'display_name');
    }
  }
};