import * as utils from './utils';
export default {
  name: 'ColumnChart',
  introduction: 'Simple component for Column Chart graph rendering.',
  description: 'It uses window.AmCharts. Few required props. You can see it on Gaps by Domain. https://docs.amcharts.com/3/javascriptcharts/AmPieChart',
  token: '<column-chart dataProvider="this.gapDomain" :colors="this.pieSliceColors"></column-chart>',
  props: {
    dataProvider: {
      type: Array,
      required: true,
      note: 'The data provided to draw the pie slices. Ie. [{area:"test",value1:val...},{...}]'
    },
    colors: {
      type: Array,
      required: true,
      note: 'The colors for the slices. The colors will be applied in the order as the dataProvider array'
    },
    domId: {
      type: String,
      required: false,
      default: null,
      note: 'The CSS ID of the chart'
    },
    width: {
      type: String,
      required: false,
      default: '100%',
      note: 'The width of the element'
    },
    height: {
      type: String,
      required: false,
      default: '350px',
      note: 'The height of the element'
    },
    css: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'If this prop is applied the values will be applied to the element in a key: value order'
    },
    categoryKey: {
      type: String,
      required: false,
      default: 'area',
      note: 'The key in dataProvider which hold the names for the categories needed to be displayed'
    },
    labels: {
      type: Array,
      required: true,
      note: 'Labels for the data which will be displayed. The Labels will be applied in the same order as keys in dataProvider'
    },
    maxVisibleColumns: {
      type: Number,
      required: false,
      default: 0,
      note: 'sets the default shown columns. if set to 0 the chart displays all'
    },
    clickableLegend: {
      type: Boolean,
      default: true,
      note: 'Should the legend be clickable?'
    },
    autoWrapLabel: {
      type: Boolean,
      default: true,
      note: 'Should the legend be wrap automatically?'
    }
  },
  data: function data() {
    return {
      columnFieldWidth: 80,
      //the max size of the field that each column is placed in
      maxAvailableColumns: 0,
      chart: null
    };
  },
  computed: {
    elementId: function elementId() {
      return this.domId || utils.randomDomId();
    },
    cssStyles: function cssStyles() {
      var _this = this;
      var obj = {
        width: this.width,
        height: this.height
      };
      this.css && Object.keys(this.css).forEach(function (key) {
        return obj[key] = _this.css[key];
      });
      return obj;
    },
    graphs: function graphs() {
      var graphs = [];
      var obj = Object.assign({}, this.dataProvider[0]);
      delete obj[this.categoryKey];
      obj = Object.keys(obj);
      for (var i = 0; i < obj.length; i++) {
        graphs.push(this.fillGraphs(this.labels[i], obj[i]));
      }
      return graphs;
    },
    /**
         *  Calculate visible columns
         *
         * determines how many columns the chart should show in one scrollpage
         * if maxVisibleColumns is not set it will default to 0 and show all columns
         *
         * @return {number} index in dataProvider
         */
    calculateVisibleColumns: function calculateVisibleColumns() {
      if (this.maxVisibleColumns === 0) {
        return this.maxAvailableColumns || this.dataProvider.length - 1;
      } else {
        return this.maxVisibleColumns - 1;
      }
    }
  },
  mounted: function mounted() {
    if (!this.maxVisibleColumns) {
      this.updateChartOnResize();
      addEventListener('resize', this.updateChartOnResize);
    }
    var vm = this;
    this.chart = window.AmCharts.makeChart(this.elementId, {
      type: 'serial',
      language: utils.getLanguage(),
      chartScrollbar: {
        resizeEnabled: false,
        backgroundColor: '#EFEFEF',
        selectedBackgroundColor: '#D4D4D4'
      },
      legend: {
        fontSize: 11,
        align: 'center',
        autoMargins: true,
        valueText: '[[close]]',
        labelText: '[[title]]',
        equalWidths: false,
        position: 'bottom',
        switchable: this.clickableLegend
      },
      addClassName: true,
      dataProvider: this.dataProvider,
      colors: this.colors,
      valueAxes: [{
        stackType: 'regular',
        axisAlpha: 0.3,
        gridAlpha: 0
      }],
      listeners: [{
        event: 'init',
        method: function method(amCharts) {
          amCharts.chart.zoomToIndexes(0, vm.calculateVisibleColumns);
        }
      }],
      graphs: this.graphs,
      categoryField: this.categoryKey,
      categoryAxis: {
        gridPosition: 'start',
        autoWrap: this.autoWrapLabel,
        labelFunction: function labelFunction(d) {
          if (!vm.autoWrapLabel && vm.maxVisibleColumns) {
            var maxLength = Math.round(vm.columnFieldWidth / vm.maxVisibleColumns);
            if (d.length > maxLength) {
              return d.substring(0, maxLength) + '...';
            }
          }
          return d;
        }
      },
      export: {
        enabled: true,
        menuReviver: function menuReviver(item, li) {
          return utils.disableExportOptions(item, li);
        }
      }
    });
  },
  beforeUnmount: function beforeUnmount() {
    window.removeEventListener('resize', this.updateChartOnResize);
  },
  methods: {
    fillGraphs: function fillGraphs(title, valueField) {
      return {
        balloonText: '[[category]]',
        fillAlphas: 1,
        lineAlpha: 0,
        labelText: '[[value]]',
        title: this.$trans(title),
        type: 'column',
        color: 'white',
        valueField: valueField,
        fixedColumnWidth: 40
      };
    },
    updateChartOnResize: function updateChartOnResize() {
      var _this$chart;
      var graphWidth = this.$el.clientWidth;
      this.maxAvailableColumns = Math.floor(graphWidth / this.columnFieldWidth);
      (_this$chart = this.chart) === null || _this$chart === void 0 ? void 0 : _this$chart.zoomToIndexes(0, this.maxAvailableColumns);
    }
  }
};