import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  class: "mb-2 border border-gray-350"
};
var _hoisted_3 = ["accept", "onChange"];
var _hoisted_4 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.moduleOptions, function (moduleOption, module) {
    return _openBlock(), _createElementBlock("div", {
      key: module
    }, [$props.modulesEnabled.includes(module) ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createVNode(_component_risma_title, {
      title: moduleOption.title,
      class: "mt-3"
    }, null, 8 /* PROPS */, ["title"]), moduleOption.fileSource ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($data.translate.seeTemplateForExampleDataWithColumnHeadings), 1 /* TEXT */)) : _createCommentVNode("v-if", true), moduleOption.fileSource ? (_openBlock(), _createBlock(_component_risma_title, {
      key: 1,
      title: module.fileType === $props.fileTypesAllowed.xls ? $data.translate.uploadXlsxFile : '',
      type: "medium"
    }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("input", {
      type: "file",
      class: "w-full p-2",
      accept: moduleOption.fileType,
      onClick: _cache[0] || (_cache[0] = function () {
        return $options.dropExistingFile && $options.dropExistingFile.apply($options, arguments);
      }),
      onChange: function onChange($event) {
        return $options.upload($event, module);
      }
    }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_3)]), moduleOption.fileSource ? (_openBlock(), _createElementBlock("a", {
      key: 2,
      href: moduleOption.fileSource
    }, _toDisplayString($data.translate.downloadExcelExampleHere), 9 /* TEXT, PROPS */, _hoisted_4)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))]);
}