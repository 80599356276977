import { Http } from '../../index';
import { handleRequestResponse } from '../../httpUtils';
export function searchNodes(projectId) {
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var scoringIncluded = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var area = 'nodes';
  return handleRequestResponse(Http.get('/compliance/projects/' + projectId + '/viewsearch?area=' + area + '&scoringIncluded=' + scoringIncluded + '&args=' + args));
}
export function searchReports(id) {
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return handleRequestResponse(Http.get("/compliance/projects/".concat(id, "/report").concat(args)));
}