import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_selector_bar = _resolveComponent("risma-selector-bar");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_selector_bar, {
    "project-id": _ctx.rismaSelectorBarProjectId,
    selectors: _ctx.rismaSelectorBarData,
    filter: true,
    orgs: [],
    users: [],
    nodes: [],
    tags: []
  }, null, 8 /* PROPS */, ["project-id", "selectors"])]);
}