function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import MainWell from '@/components/Atoms/Containers/MainWell';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import ToggleMenu from '@/components/Atoms/ToggleMenu/ToggleMenu';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import LevelOfThreat from '@/components/Molecules/Risk/LevelOfThreat';
import Consequence from '@/components/Molecules/Risk/Consequence';
import { prepareNotifyErrors } from '@/utils/handleAPIErrors';
import * as RiskApi from '@/api/risk/risk';
import { RiskStatus } from '@/constants/risks/RiskStatus';
import { ControlValidationMessages } from '@/constants/ControlValidationMessages';
import { defaultProbabilityLabels, defaultConsequenceLabels, defaultFirstColumn, defaultLevelOfThreatOptions } from '@/constants/risks/RiskPeriodDefaultData';
var ENTERPRISE_RISK_MANAGEMENT_TYPE = 1;
var PROBABILITY = {
  CHILDREN_KEY: 'likelihoodColumns',
  LABELS_KEY: 'probabilityLabels'
};
var CONSEQUENCE = {
  CHILDREN_KEY: 'impactColumns',
  LABELS_KEY: 'consequenceLabels'
};
export default {
  name: 'RiskAdminPeriodDescription',
  introduction: 'Risk admin period description component',
  description: '',
  token: '<risk-admin-period-description></risk-admin-period-description>',
  components: {
    MainWell: MainWell,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Notification: Notification,
    LevelOfThreat: LevelOfThreat,
    Consequence: Consequence,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    ToggleMenu: ToggleMenu,
    InputField: InputField
  },
  emits: ['refreshRiskProjects'],
  data: function data() {
    return {
      defaultLevelOfThreatOptions: defaultLevelOfThreatOptions,
      CONSEQUENCE: CONSEQUENCE,
      PROBABILITY: PROBABILITY,
      isNewMode: false,
      isLoaded: false,
      activity: {
        title: ''
      },
      riskProjects: [],
      currentRiskProject: {},
      projectPeriodLatest: {},
      openPeriod: false,
      dataUpdate: {},
      notificationType: 'info',
      notificationMessage: '',
      notificationDetails: '',
      isNotificationShow: false,
      toggleState: false,
      translate: getTranslate['RiskAdminPeriodDescription']()
    };
  },
  computed: {
    isPageDirty: function isPageDirty() {
      // check for empty dataUpdate object
      return !Object.keys(this.dataUpdate).length;
    },
    isPeriodClosed: function isPeriodClosed() {
      return this.activity.status === RiskStatus.CLOSED;
    },
    path: function path() {
      return this.$route.params.path;
    },
    projectId: function projectId() {
      return this.$route.params.projectId;
    },
    savePeriodTitle: function savePeriodTitle() {
      return this.openPeriod && !this.activity.isCurrent ? this.translate.saveAndOpen : this.translate.save;
    },
    isPeriodEditable: function isPeriodEditable() {
      return this.isNewMode || this.isDraft;
    },
    isDraft: function isDraft() {
      return this.activity.status === RiskStatus.DRAFT;
    },
    toggleMenuOptions: function toggleMenuOptions() {
      var _this = this;
      return [{
        id: 1,
        title: "".concat(this.translate.addDefault, " CIA ").concat(this.translate.values),
        icon: 'plus',
        action: function action() {
          _this.changeDataByToggle('CIA');
        }
      }, {
        id: 2,
        title: "".concat(this.translate.addDefault, " FIT ").concat(this.translate.values),
        icon: 'plus',
        action: function action() {
          _this.changeDataByToggle('FIT');
        }
      }];
    }
  },
  beforeMount: function beforeMount() {
    var _this2 = this;
    this.getRiskProjects().then(function () {
      _this2.getAllData();
    });
  },
  methods: {
    getDatafromLabels: function getDatafromLabels(labels) {
      return labels.map(function (item, index) {
        return {
          column: index,
          value: index,
          label: item
        };
      });
    },
    changeDataByToggle: function changeDataByToggle(key) {
      var _this3 = this;
      this.activity.consequenceLevels = defaultConsequenceLabels[key].length;
      this.activity.probabilityLevels = defaultProbabilityLabels[key].length;
      this.activity.probabilityRows = [];
      this.activity.consequenceRows = [];
      this.activity.onlyColumns = [];
      var impactColumns = this.getDatafromLabels(defaultConsequenceLabels[key]);
      var likelihoodColumns = this.getDatafromLabels(defaultProbabilityLabels[key]);
      defaultFirstColumn[key].forEach(function (item, index) {
        var data = {
          id: null,
          label: item,
          index: index
        };
        _this3.activity.probabilityRows.push(_objectSpread({}, data));
        _this3.activity.consequenceRows.push(_objectSpread({}, data));
        _this3.activity.onlyColumns.push(data);
        _this3.activity.consequenceRows[index].impactColumns = impactColumns;
        _this3.activity.probabilityRows[index].likelihoodColumns = likelihoodColumns;
      });
      this.setDefaultDataUpdate();
    },
    getRiskProjects: function getRiskProjects() {
      var _this4 = this;
      this.isLoaded = false;
      return RiskApi.getRiskProjects().then(function (_ref) {
        var list = _ref.list;
        _this4.riskProjects = list.filter(function (item) {
          return item.type !== ENTERPRISE_RISK_MANAGEMENT_TYPE;
        });
        _this4.currentRiskProject = _this4.riskProjects.find(function (item) {
          return +item.id === +_this4.projectId;
        });
        _this4.projectPeriodLatest = _this4.currentRiskProject.projectPeriods.find(function (item) {
          return item.isCurrent;
        });
      }).catch(function (e) {
        throw e;
      });
    },
    updateProperty: function updateProperty(propertyName, value) {
      this.activity[propertyName] = value;
      this.dataUpdate[propertyName] = value;
    },
    getAllData: function getAllData() {
      var _this5 = this;
      this.isNewMode = this.checkIsNew();
      var promises = [];
      if (!this.isNewMode) {
        promises.push(this.getPeriodData());
      }
      this.isLoaded = false;
      return Promise.all(promises).then(function () {
        _this5.isLoaded = true;
        if (_this5.isNewMode) {
          _this5.getNewPeriodData();
          _this5.setDefaultDataUpdate();
          _this5.setTitleFocus();
        }
      }).catch(function (e) {
        return _this5.handleGenericError(e);
      });
    },
    handleGenericError: function handleGenericError(e) {
      var message = this.translate.thereWasAnError;
      if (e && e.response && e.response.error) {
        message += ': ' + e.response.error;
      }
      this.handleShowNotify({
        type: 'error',
        message: message
      });
    },
    onLevelChanged: function onLevelChanged(_ref2) {
      var labelName = _ref2.labelName,
        options = _ref2.options;
      this.activity.threatLevelsLabel = labelName;
      this.dataUpdate.threatLevelsLabel = labelName;
      this.dataUpdate.threatLevels = options.map(function (item) {
        return item.title;
      });
    },
    getNewPeriodData: function getNewPeriodData() {
      this.activity = {
        title: '',
        id: null,
        consequenceLevels: this.projectPeriodLatest.consequenceLevels,
        consequenceRows: _toConsumableArray(this.projectPeriodLatest.consequenceRows),
        consequenceLabel: this.translate.consequence,
        onlyColumns: _toConsumableArray(this.projectPeriodLatest.consequenceRows),
        probabilityRows: _toConsumableArray(this.projectPeriodLatest.probabilityRows),
        probabilityLevels: this.projectPeriodLatest.probabilityLevels,
        probabilityLabel: this.translate.probability,
        threatLevels: this.projectPeriodLatest.threatLevels,
        threatLevelsLabel: this.translate.levelOfThreat
      };
      // No need for passing ids
      this.activity.consequenceRows.map(function (item) {
        item.id = null;
      });
      this.activity.probabilityRows.map(function (item) {
        item.id = null;
      });
    },
    setDefaultDataUpdate: function setDefaultDataUpdate() {
      this.dataUpdate = _objectSpread({}, this.activity);
      if (this.isNewMode) {
        this.dataUpdate.threatLevels = this.defaultLevelOfThreatOptions.map(function (item) {
          return item.title;
        });
      } else {
        this.dataUpdate.threatLevels = this.activity.threatLevels.map(function (item) {
          return item.title;
        });
      }
      this.setDefaultAssessmentRows();
    },
    setDefaultPeriodFields: function setDefaultPeriodFields(period) {
      if (!period.consequenceLabel) {
        period.consequenceLabel = 'Consequence';
      }
      if (!period.probabilityLabel) {
        period.probabilityLabel = 'Probability';
      }
      return period;
    },
    setTitleFocus: function setTitleFocus() {
      var _this6 = this;
      this.$nextTick(function () {
        var titleComponent = _this6.$refs.detailTitle;
        if (titleComponent) titleComponent.$el.focus();
      });
    },
    checkIsNew: function checkIsNew() {
      var result = false;
      if (this.path === 'new') {
        result = true;
      }
      return result;
    },
    getPeriodData: function getPeriodData() {
      var _this7 = this;
      this.isLoaded = false;
      return RiskApi.getPeriod(this.projectId, this.path).then(function (data) {
        _this7.isLoaded = true;
        _this7.activity = data;
        _this7.openPeriod = data.isCurrent;
        _this7.setDefaultPeriodFields(_this7.activity);
      }).catch(function (e) {
        _this7.handleGenericError(e);
        throw e;
      });
    },
    isValidSave: function isValidSave() {
      var result = true;
      var errors = [];

      // Validation for title
      if (!this.activity.title) {
        errors.push(ControlValidationMessages.TITLE_IS_REQUIRED);
      }
      if (errors.length) {
        result = false;
        this.showGeneralValidationError(errors);
      }
      return result;
    },
    showGeneralValidationError: function showGeneralValidationError(errors) {
      var notifyData = prepareNotifyErrors(errors, ControlValidationMessages.PLEASE_CORRECT_THE_ERRORS, this.$trans);
      this.handleShowNotify(notifyData);
    },
    createNewActivity: function createNewActivity() {
      var _this8 = this;
      if (!this.isValidSave()) {
        return;
      }
      this.isLoaded = false;
      var payload = this.preparePayload();
      payload.status = this.prepareStatus();
      RiskApi.createPeriod(this.projectId, payload).then(function () {
        _this8.$emit('refreshRiskProjects');
        _this8.isLoaded = true;
        _this8.returnToPrevPage();
      }).catch(function (e) {
        return _this8.handleError(e);
      });
    },
    updateData: function updateData() {
      this.updateActivity();
    },
    handleError: function handleError(e) {
      this.handleGenericError(e);
      this.isLoaded = true;
    },
    updateActivity: function updateActivity() {
      var _this9 = this;
      if (!this.isValidSave()) {
        return;
      }
      this.isLoaded = false;
      var payload = this.preparePayload();
      payload.status = this.prepareStatus();
      RiskApi.updatePeriod(this.projectId, this.path, payload).then(function () {
        _this9.isLoaded = true;
        _this9.resetPeriod();
      }).catch(function () {
        return _this9.handleError();
      });
    },
    prepareStatus: function prepareStatus() {
      return this.openPeriod ? RiskStatus.OPEN : RiskStatus.DRAFT;
    },
    resetPeriod: function resetPeriod() {
      this.dataUpdate = {};
      this.activity = {
        title: ''
      };
      this.getPeriodData();
    },
    preparePayload: function preparePayload() {
      return _objectSpread({}, this.dataUpdate);
    },
    cancelAction: function cancelAction() {
      this.returnToPrevPage();
    },
    returnToPrevPage: function returnToPrevPage() {
      this.$router.go(-1);
    },
    showNotificationAfterUpdate: function showNotificationAfterUpdate() {
      this.handleShowNotify({
        type: 'success',
        message: this.translate.riskUpdated
      });
    },
    handleShowNotify: function handleShowNotify(_ref3) {
      var type = _ref3.type,
        message = _ref3.message,
        details = _ref3.details;
      this.notificationType = type;
      this.notificationMessage = message;
      this.notificationDetails = details;
      this.isNotificationShow = true;
      this.$scrollTo(document.body, 100);
    },
    handleDismissNotification: function handleDismissNotification() {
      this.notificationMessage = '';
      this.notificationDetails = '';
      this.isNotificationShow = false;
    },
    onProbabilityChanged: function onProbabilityChanged(e) {
      var _this10 = this;
      this.dataUpdate.probabilityLevels = e.selectedScore;
      this.dataUpdate.probabilityLabels = {};
      e.scores.map(function (item) {
        _this10.dataUpdate.probabilityLabels[item.score] = item.description;
      });
    },
    setDefaultAssessmentRows: function setDefaultAssessmentRows() {
      var _this11 = this;
      this.dataUpdate.assessmentRows = [];
      this.activity.consequenceRows.forEach(function (item, index) {
        var data = {
          label: item.label,
          index: item.index,
          consequenceLabels: item.impactColumns.map(function (item) {
            return item.label;
          }),
          probabilityLabels: _this11.activity.probabilityRows[index].likelihoodColumns.map(function (item) {
            return item.label;
          })
        };
        if (item.id) {
          data.id = item.id;
        }
        _this11.dataUpdate.assessmentRows.push(data);
      });
    },
    onDataChanged: function onDataChanged(e, key) {
      this.activity.onlyColumns = _toConsumableArray(e.items);
      this.refreshDataUpdate(e.items, key);
    },
    refreshDataUpdate: function refreshDataUpdate(rows, key) {
      var _this12 = this;
      var childrenLength = rows[0].children.length || 0;
      if (!this.dataUpdate.assessmentRows) {
        this.setDefaultAssessmentRows();
      }
      if (key === CONSEQUENCE.LABELS_KEY) {
        this.dataUpdate.consequenceLevels = childrenLength;
      } else {
        this.dataUpdate.probabilityLevels = childrenLength;
      }
      rows.forEach(function (item, index) {
        var childrenLabels = item.children.map(function (item) {
          return item.value;
        });
        if (_this12.dataUpdate.assessmentRows[index]) {
          if (item.index > _this12.dataUpdate.assessmentRows[index].index) {
            _this12.dataUpdate.assessmentRows.splice(index, 1);
          } else {
            _this12.dataUpdate.assessmentRows[index].label = item.label;
            _this12.dataUpdate.assessmentRows[index][key] = childrenLabels;
          }
        } else {
          var data = {
            label: item.label,
            index: item.index,
            consequenceLabels: childrenLabels,
            probabilityLabels: childrenLabels
          };
          if (item.id) {
            data.id = item.id;
          }
          _this12.dataUpdate.assessmentRows.push(data);
        }
      });
      if (this.dataUpdate.assessmentRows.length > rows.length) {
        this.dataUpdate.assessmentRows.splice(rows.length, this.dataUpdate.assessmentRows.length - rows.length);
      }
    }
  }
};