import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import i18n from '@/i18n';
dayjs.extend(duration);
export function getDateDurationWithToday(endDate) {
  var a = dayjs();
  var b = dayjs.utc(endDate);
  var diffDuration = dayjs.duration(a.diff(b));
  var years = diffDuration.years();
  if (years) {
    return {
      type: years === 1 ? 'year' : 'years',
      value: years
    };
  }
  var months = diffDuration.months();
  if (months) {
    return {
      type: months === 1 ? 'month' : 'months',
      value: months
    };
  }
  var days = diffDuration.days();
  if (days) {
    return {
      type: days === 1 ? 'day' : 'days',
      value: days
    };
  }
  var hours = diffDuration.hours();
  if (hours) {
    return {
      type: hours === 1 ? 'hour' : 'hours',
      value: hours
    };
  }
  var minutes = diffDuration.minutes();
  if (minutes) {
    return {
      type: minutes === 1 ? 'minute' : 'minutes',
      value: minutes
    };
  }
  return {
    value: i18n.t('less than a minute')
  };
}