function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var RismaVueChartMixin = {
  props: {
    datasets: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'the datasets to create the component'
    },
    labels: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'labels that are shown'
    },
    options: {
      required: false,
      type: Object,
      default: function _default() {},
      note: 'the options to create the component'
    },
    showLegend: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Determine if there legend should be displayed or not'
    },
    legendPosition: {
      required: false,
      type: String,
      default: 'bottom',
      validator: function validator(position) {
        return ['top', 'bottom', 'left', 'right'].includes(position);
      }
    },
    useDefaultExport: {
      required: false,
      type: Boolean,
      default: true
    },
    excelExportEnabled: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    chartOptions: function chartOptions() {
      return _objectSpread({
        plugins: {
          legend: {
            display: this.showLegend,
            position: this.legendPosition
          },
          tooltip: {
            enabled: true,
            label: ''
          },
          datalabels: {
            color: '#fff',
            align: 'center',
            anchor: 'center',
            offset: -3,
            font: {
              size: this.datasets.length > 1 ? '9px' : '14px'
            },
            display: function display(context) {
              return +context.dataset.data[context.dataIndex];
            },
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }
          }
        },
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
              maxTicksLimit: 10
            }
          }
        },
        fill: true,
        cubicInterpolationMode: 'monotone',
        responsive: true,
        maintainAspectRatio: false,
        devicePixelRatio: 1
      }, this.options);
    },
    chartData: function chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets
      };
    },
    isExcelExportAvailable: function isExcelExportAvailable() {
      return this.excelExportEnabled;
    }
  },
  methods: {
    excelExportFunction: function excelExportFunction() {
      var dataset = [];
      var columns = {
        0: ''
      };
      if (this.datasets.length > 1) {
        dataset = this.datasets.map(function (row) {
          var result = {
            0: row.label || ''
          };
          row.data && row.data.forEach(function (item, idx) {
            result[idx + 1] = item || '';
          });
          return result;
        });
        this.labels.forEach(function (label, idx) {
          columns[idx + 1] = label || '';
        });
      } else {
        dataset = [Object.assign({}, this.datasets[0].data || [])];
        columns = Object.assign({}, this.labels || []);
      }
      return Promise.resolve({
        dataset: dataset,
        columns: columns
      });
    }
  }
};