export var IncidentsVisibleProps = [{
  key: 'severity',
  text: 'Severity',
  default: true
}, {
  key: 'title',
  // Title with path & trafficLight (always visible)
  text: 'Title',
  default: true
}, {
  key: 'description',
  text: 'Description',
  default: true
}, {
  key: 'rismaState',
  text: 'Status'
}, {
  key: 'createdAt',
  text: 'Created',
  default: true
}, {
  key: 'incidentHappenedAt',
  text: 'Incident occurred'
}, {
  key: 'responsibleUserIds',
  text: 'Responsible'
}, {
  key: 'accountableUserIds',
  text: 'Accountable'
}];