export var prepareOnScrollFunction = function prepareOnScrollFunction(elements) {
  var scrollSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 150;
  return function () {
    var scrollY = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollY > scrollSize) {
      elements.forEach(function (el) {
        return el && el.classList.add('scroll');
      });
    } else {
      elements.forEach(function (el) {
        return el && el.classList.remove('scroll');
      });
    }
  };
};
export var subscribe = function subscribe(fn) {
  window.addEventListener('scroll', fn);
};
export var unsubscribe = function unsubscribe(fn) {
  window.removeEventListener('scroll', fn);
};