import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "governance-review-section"
};
var _hoisted_2 = {
  class: "flex justify-between"
};
var _hoisted_3 = {
  class: "flex"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  class: "ml-2"
};
var _hoisted_6 = {
  key: 1,
  class: "flex align-baseline"
};
var _hoisted_7 = {
  class: "ml-2"
};
var _hoisted_8 = {
  key: 2,
  class: "flex"
};
var _hoisted_9 = {
  class: "ml-2"
};
var _hoisted_10 = {
  key: 3,
  class: "flex"
};
var _hoisted_11 = {
  class: "ml-2"
};
var _hoisted_12 = {
  class: "flex"
};
var _hoisted_13 = {
  class: "ml-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  var _component_governance_review_modal = _resolveComponent("governance-review-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    type: "medium",
    title: $data.translate.governanceReview
  }, null, 8 /* PROPS */, ["title"]), $data.reviewClone.trafficLight ? (_openBlock(), _createBlock(_component_traffic_light, {
    key: 0,
    color: $options.colors.trafficlightColor($data.reviewClone.trafficLight).color,
    width: 20,
    height: 20,
    class: "ml-4"
  }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true)]), !$props.disabled && $options.showReviewEditButton ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "edit",
    class: "cursor-pointer",
    onClick: $options.toggleModal
  }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]), Object.keys($data.reviewClone).length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", null, [$data.reviewClone.canEditReview ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    class: "flex align-baseline",
    to: $data.reviewClone.url
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: "".concat($data.translate.title, ":"),
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_5, _toDisplayString($data.reviewClone.path) + " " + _toDisplayString($data.reviewClone.title), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.title, ":"),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_7, _toDisplayString($data.reviewClone.path) + " " + _toDisplayString($data.reviewClone.title), 1 /* TEXT */)])), $options.latestCompletionUser ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.completedBy, ":"),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_9, _toDisplayString($options.latestCompletionUser), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $data.reviewClone.latestCompletion ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.lastCompletion, ":"),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_11, _toDisplayString($options.formatDate($data.reviewClone.latestCompletion, $data.reviewClone.deadlineTimezone)), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_12, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.nextDeadline, ":"),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_13, _toDisplayString($options.formatDate($data.reviewClone.deadline, $data.reviewClone.deadlineTimezone)), 1 /* TEXT */)])])])) : _createCommentVNode("v-if", true), $data.showModal ? (_openBlock(), _createBlock(_component_governance_review_modal, {
    key: 1,
    "show-modal": $data.showModal,
    review: $props.review,
    "project-name": $props.projectName,
    users: $props.users,
    organisations: $props.organisations,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showModal = false;
    }),
    onAccept: $options.emitReview
  }, null, 8 /* PROPS */, ["show-modal", "review", "project-name", "users", "organisations", "onAccept"])) : _createCommentVNode("v-if", true)]);
}