import i18n from '@/i18n';
export var getTranslate = {
  ResetPassword: function ResetPassword() {
    return {
      returnToLoginPage: i18n.t('Return to login page'),
      resetPassword: i18n.t('Reset password'),
      resetYourPassword: i18n.t('Reset your password'),
      toResetYourPasswordPleaseProvideANewOneA: i18n.t('To reset your password, please provide a new one and verify it'),
      enterNewPassword: i18n.t('Enter new password'),
      password: i18n.t('Password'),
      verifyPassword: i18n.t('Verify Password'),
      congratulationsYourPasswordHasBeenReset: i18n.t('Congratulations, your password has been reset'),
      yourPasswordHasNowBeenResetYouCanNowProc: i18n.t('Your password has now been reset. You can now proceed to the'),
      loginPage: i18n.t('login page'),
      invalidToken: i18n.t('Invalid token'),
      theTokenYouHaveProvidedIsNotValidOrMayHa: i18n.t('The token you have provided is not valid or may have expired.'),
      pleaseFillOutPasswordField: i18n.t('Please fill out password field'),
      repeatTheNewPassword: i18n.t('Repeat the new password')
    };
  }
};