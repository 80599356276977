function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { Colors } from '@/Colors';
export default {
  name: 'GapSchemaNavigation',
  components: {
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight,
    FeatherIcon: FeatherIcon
  },
  props: {
    gaps: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'list of gaps'
    },
    gapActiveId: {
      required: false,
      type: Number || String,
      default: null,
      note: 'active gap id'
    },
    showDomain: {
      required: false,
      type: Boolean,
      default: true,
      note: 'display domain info'
    }
  },
  emits: ['selectGap'],
  data: function data() {
    return {
      indexExpandedView: null
    };
  },
  computed: {
    gapColors: function gapColors() {
      return Colors.gaps;
    },
    gapListByDomain: function gapListByDomain() {
      var _this = this;
      var result = [];
      var domain;
      this.gaps.forEach(function (gap, index) {
        var currentGap = gap.article;
        var previousGap = _this.gaps[index - 1] ? _this.gaps[index - 1].article : gap.article;
        if (previousGap.domain.label !== currentGap.domain.label || index === 0) {
          domain = _objectSpread({}, currentGap.domain);
          result.push(domain);
          domain._gaps = [];
        }
        domain._gaps.push(gap);
      });
      return result;
    }
  },
  watch: {
    gapActiveId: function gapActiveId() {
      this.setIndexExpandedView();
    }
  },
  mounted: function mounted() {
    this.setIndexExpandedView();
  },
  methods: {
    setIndexExpandedView: function setIndexExpandedView() {
      var _this2 = this;
      Object.keys(this.gapListByDomain).forEach(function (key, index) {
        var isActive = _this2.gapListByDomain[key]._gaps.find(function (item) {
          return _this2.gapActiveId === item.articleId;
        });
        if (isActive) return _this2.indexExpandedView = index;
      });
    },
    getGapTitleForSidebar: function getGapTitleForSidebar(gap) {
      return "".concat(gap.article.article, ". ").concat(gap.article.requirement);
    },
    toggleGap: function toggleGap(value) {
      this.indexExpandedView = this.indexExpandedView === value ? null : value;
    },
    startTransition: function startTransition(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    endTransition: function endTransition(el) {
      el.style.height = '';
    }
  }
};