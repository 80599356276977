import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  class: "downloaded-files"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.files, function (file, index) {
    return _openBlock(), _createElementBlock("div", {
      key: "file-".concat(index),
      class: "flex text-gray-550 items-center"
    }, [file.errorExtension ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      icon: "x",
      class: "mr-2 text-orange-50"
    })) : (_openBlock(), _createBlock(_component_feather_icon, {
      key: 1,
      icon: "check",
      class: "mr-2 text-green-150"
    })), _createTextVNode(" " + _toDisplayString(file.filename) + " ", 1 /* TEXT */), _createVNode(_component_feather_icon, {
      icon: "x",
      class: "mx-2 cursor-pointer",
      onClick: function onClick($event) {
        return _ctx.$emit('delete', index);
      }
    }, null, 8 /* PROPS */, ["onClick"])]);
  }), 128 /* KEYED_FRAGMENT */))]);
}