import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$fullUser;
  var _component_notification = _resolveComponent("notification");
  var _component_dep_well = _resolveComponent("dep-well");
  return _openBlock(), _createElementBlock("div", null, [$data.user && !$data.user.active ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "info"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString($data.translate.beAwareThatThisUserHasBeenDeactivated), 1 /* TEXT */)])];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: $data.notificationType,
    onDismiss: $options.handleDismissNotification
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.notificationMessage), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type", "onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_dep_well, {
    "is-loaded": $data.isLoaded,
    item: $options.fullUser,
    "read-only": !((_$options$fullUser = $options.fullUser) !== null && _$options$fullUser !== void 0 && _$options$fullUser.active),
    "dep-header-props": $options.depHeaderProps,
    "is-relation-enabled": false,
    onChangeTab: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changeTab', $event);
    }),
    onPropertyChanged: $options.userPropertyChanged,
    onPropertiesChanged: $options.userPropertiesChanged,
    onProjectChanged: $options.userProjectChanged,
    onSaveStatusClicked: $options.saveStatusClicked,
    onValidationError: $options.handleShowNotification
  }, null, 8 /* PROPS */, ["is-loaded", "item", "read-only", "dep-header-props", "onPropertyChanged", "onPropertiesChanged", "onProjectChanged", "onSaveStatusClicked", "onValidationError"])]);
}