import RismaSwitch from '@/components/Atoms/Inputs/RismaSwitch';
export default {
  name: '',
  components: {
    RismaSwitch: RismaSwitch
  },
  data: function data() {
    return {
      currentState: true,
      switchOptions2: [{
        name: 'Automatic',
        value: false
      }, {
        name: 'Manual',
        value: true
      }]
    };
  },
  methods: {
    showResult: function showResult(name, result) {
      window.alert(name, JSON.stringify(result));
    },
    showUpdatedSwitchState: function showUpdatedSwitchState(state) {
      this.showResult('showUpdatedSwitchState', state);
    }
  }
};