import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getKpiOverview() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return handleRequestResponse(Http.get('kpi/overview' + params));
}
export function getKpiDashboards() {
  return handleRequestResponse(Http.get('kpi/dashboards'));
}
export function createKpiDashboard(newDashboard) {
  return handleRequestResponse(Http.post('kpi/dashboards', newDashboard));
}
export function getKpiDashboardById(id) {
  return handleRequestResponse(Http.get('kpi/dashboards/' + id));
}
export function getKpiData(dashboardId) {
  return handleRequestResponse(Http.get('kpi/data/' + dashboardId));
}
export function updateKpiDashboard(dashboardId, body) {
  return handleRequestResponse(Http.patch('kpi/dashboards/' + dashboardId, body));
}
export function deleteKpiDashboard(dashboardId) {
  return handleRequestResponse(Http.delete('kpi/dashboards/' + dashboardId));
}