import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "max-w-screen-xxl xxl:mx-auto"
};
var _hoisted_2 = {
  class: "w-2/5 m-auto"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  key: 1
};
var _hoisted_6 = {
  key: 2
};
var _hoisted_7 = {
  class: "mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_input_field = _resolveComponent("input-field");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.notificationMessages ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: $data.notificationType
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.notificationMessages), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), _createElementVNode("section", _hoisted_2, [_createElementVNode("img", {
    src: $options.logoSrc,
    class: "w-48 mx-auto"
  }, null, 8 /* PROPS */, _hoisted_3), $data.invalidToken ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.invalidToken + '!',
    class: "text-center",
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.theTokenYouHaveProvidedIsNotValidOrMayHa,
    class: "text-center",
    type: "small"
  }, null, 8 /* PROPS */, ["title"])])) : $data.isResetSuccess ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.congratulationsYourPasswordHasBeenReset,
    truncate: false,
    class: "text-center",
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.yourPasswordHasNowBeenResetYouCanNowProc + ' ' + $data.translate.loginPage,
    truncate: false,
    class: "text-center",
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_button, {
    text: $data.translate.returnToLoginPage,
    type: "save",
    class: "mt-4 ml-auto",
    onClick: $options.goToLogin
  }, null, 8 /* PROPS */, ["text", "onClick"])])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_risma_title, {
    title: $data.translate.resetYourPassword,
    class: "text-center",
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.toResetYourPasswordPleaseProvideANewOneA,
    truncate: false,
    class: "text-center",
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.enterNewPassword,
    type: "medium",
    class: "text-blue-750"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
    modelValue: $data.password,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.password = $event;
    }),
    placeholder: $data.translate.password,
    class: "my-2",
    type: "password"
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"]), _createVNode(_component_input_field, {
    modelValue: $data.verifyPassword,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.verifyPassword = $event;
    }),
    placeholder: $data.translate.verifyPassword,
    class: "my-2",
    type: "password"
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"])]), _createVNode(_component_risma_button, {
    text: $data.translate.resetPassword,
    type: "save",
    class: "w-fit ml-auto",
    onClick: $options.onResetPassword
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "check",
        class: "stroke-2 mr-2"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"])]))])]);
}