export default {
  name: 'LightTabItem',
  introduction: 'this item is used as tabs - to show them all on the page',
  description: 'Used inside the TabList component, can also be used separately',
  token: "<light-tab-item to=\"link\">label</light-tab-item>\n    <light-tab-item isCurrent=\"false\">label</light-tab-item>",
  props: {
    to: {
      type: [String, Object],
      require: false,
      default: null
    },
    isCurrent: {
      type: Boolean,
      require: false,
      default: false
    },
    backgroundClass: {
      type: String,
      require: false,
      default: 'bg-white',
      note: 'background color as tailwind color to hide border bottom on active tab'
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  methods: {
    isSameRoute: function isSameRoute(route) {
      if (!route.name) {
        return false;
      }
      return route.name === this.$router.currentRoute.value.name;
    }
  }
};