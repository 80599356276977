import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-730517ba"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "date-time-picker"
};
var _hoisted_2 = {
  class: "date-picker-footer flex justify-around"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_date_picker = _resolveComponent("date-picker");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.title ? (_openBlock(), _createBlock(_component_risma_label, {
    key: 0,
    title: $props.title
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), _createVNode(_component_date_picker, {
    value: $data.dateValue,
    "onUpdate:value": _cache[1] || (_cache[1] = function ($event) {
      return $data.dateValue = $event;
    }),
    "value-type": "format",
    "default-value": $options.options.defaultDate,
    format: $options.options.format,
    lang: $options.options.locale,
    clearable: $props.deleteEnabled,
    disabled: $props.disabled,
    type: $options.datePickerType,
    "show-time-panel": $data.showTimePanel,
    "append-to-body": false,
    "popup-style": $options.popupStyle,
    "input-attr": {
      tabindex: $props.tabindex
    },
    "disabled-date": $props.disabledDate,
    placeholder: $options.options.format,
    onChange: $options.handleChange,
    onClose: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('close');
    })
  }, {
    "icon-calendar": _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "calendar",
        class: "mx-2 stroke-3 text-blue-750"
      })];
    }),
    footer: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [$options.options.showTodayButton ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "crosshair",
        class: "mx-2 stroke-2 cursor-pointer hover:text-blue-350",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $options.setToday();
        })
      })) : _createCommentVNode("v-if", true), $options.isTimeType ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 1,
        icon: $data.showTimePanel ? 'calendar' : 'clock',
        class: "mx-2 stroke-2 cursor-pointer hover:text-blue-350",
        onClick: $options.toggleTimePanel
      }, null, 8 /* PROPS */, ["icon", "onClick"])) : _createCommentVNode("v-if", true), $options.options.showClear ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 2,
        icon: "trash-2",
        class: "mx-2 stroke-2 cursor-pointer hover:text-blue-350",
        title: $data.translate.resetSelection,
        onClick: $options.resetDate
      }, null, 8 /* PROPS */, ["title", "onClick"])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["value", "default-value", "format", "lang", "clearable", "disabled", "type", "show-time-panel", "popup-style", "input-attr", "disabled-date", "placeholder", "onChange"])]);
}