import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    files: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'file list, example: [{filename: something}, {filename: tmp}]'
    }
  },
  emits: ['delete']
};