function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import HierarchicalList from '@/components/Molecules/HierarchicalList';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { TableCellsTypes } from '@/constants/TableCellsTypes';
import { Colors } from '@/Colors';
import { getTranslate } from '@/components/Molecules/RismaTable/translate';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Http from '@/api';
import { handleRequestResponse } from '@/api/httpUtils';
export default {
  name: 'RismaTableCell',
  description: 'One cell',
  token: '<risma-table-cell :cell-data="{}"/>',
  components: {
    RismaButton: RismaButton,
    PreviewModalLink: PreviewModalLink,
    TrafficLight: TrafficLight,
    HierarchicalList: HierarchicalList,
    FeatherIcon: FeatherIcon
  },
  props: {
    cellData: {
      required: true,
      type: Object,
      note: 'Cell data'
    },
    type: {
      required: false,
      type: String,
      default: TableCellsTypes.TEXT,
      note: 'Cell type'
    }
  },
  emits: ['dismissModal', 'delete', 'starred', 'error', 'reload', 'iconClicked', 'update:loading'],
  data: function data() {
    return {
      TableCellsTypes: TableCellsTypes,
      translate: getTranslate['RismaTableCell']()
    };
  },
  computed: {
    contentWithStripedPseudoTags: function contentWithStripedPseudoTags() {
      if (this.type !== TableCellsTypes.HTML) return '';
      if (!this.cellData.content) return '';
      var regexp = new RegExp('&lt;.+?&gt;', 'gm');
      return this.cellData.content.replace(regexp, '');
    }
  },
  methods: {
    getCellColor: function getCellColor() {
      var _Colors$getTrafficlig;
      return this.cellData.color || ((_Colors$getTrafficlig = Colors.getTrafficlightByNumber(this.cellData.content)) === null || _Colors$getTrafficlig === void 0 ? void 0 : _Colors$getTrafficlig.color) || '';
    },
    getDocumentIcon: function getDocumentIcon(type) {
      /* only strict imports required https://esbuild.github.io/api/#non-analyzable-imports */
      switch (type) {
        case 0:
          return require('@/assets/img/documents-icons/expired.svg');
        case 1:
          return require('@/assets/img/documents-icons/last_month.svg');
        case 2:
          return require('@/assets/img/documents-icons/missing.svg');
        case 3:
          return require('@/assets/img/documents-icons/valid.svg');
        default:
          return require('@/assets/img/documents-icons/expired.svg');
      }
    },
    handleBackendEvent: function handleBackendEvent(cellData) {
      var _this = this;
      var requiredKeys = ['url', 'method'];
      var allowedKeys = ['url', 'method', 'data'];
      var config = Object.fromEntries(Object.entries(cellData).filter(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
          key = _ref2[0];
        return allowedKeys.includes(key);
      }));
      if (!requiredKeys.every(function (key) {
        return Object.keys(config).includes(key);
      })) {
        this.$emit('error', 'Backend event missing required parameters');
        return;
      }
      this.$emit('update:loading', true);
      var promise = handleRequestResponse(Http.request(config));
      promise.catch(function (error) {
        _this.$emit('error', error);
      });
      if (cellData.reload) {
        promise.then(function () {
          _this.$emit('reload');
        });
      }
      promise.finally(function () {
        _this.$emit('update:loading', false);
      });
    }
  }
};