function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getSingleByType, updateByType } from '@/api/compliance/informationAssets';
import { getInformationAssetQuestionnaireForController, getInformationAssetQuestionnaireForSystem, getInformationAssetQuestionnairesForCustomIA, getInformationAssetQuestionnaireForProcessor } from '@/api/compliance/questionnaire';
import { saveStatus } from '@/constants/SaveStatuses';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { MODULES } from '@/constants/modules';
import { AuditGenericData } from '@/constants/AuditGenericData';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { getCustomFieldValues } from '@/utils/CustomFields';
import { updateFileLocally } from '@/utils/Attachments';
import { catch403 } from '@/utils/handleAPIErrors';
export var InformationAssetsEntryMixin = {
  props: {
    id: {
      required: true,
      type: [String, Number]
    }
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  emits: ['accessError'],
  data: function data() {
    return {
      informationAsset: null,
      saveStatus: saveStatus.SAVED,
      updateTimer: null,
      questionnaires: [],
      auditQuestionnaires: [],
      errorMessage: '',
      isPageWithCustomUpdateDirty: false,
      isDirty: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useActivityTypesStore, {
    activityTypes: function activityTypes(state) {
      return state[this.gettersName];
    }
  })), {}, {
    baseLink: function baseLink() {
      return "/compliance/informationassets/".concat(this.informationAssetType, "/").concat(this.id);
    },
    isQuestionnaireShow: function isQuestionnaireShow() {
      return this.$route.path.indexOf('questionnaire') !== -1;
    },
    isQuestionnaireAvailable: function isQuestionnaireAvailable() {
      return this.informationAsset && this.informationAsset.questionnaires && this.informationAsset.questionnaires.length;
    },
    isGapAvailable: function isGapAvailable() {
      return this.informationAsset && this.informationAsset.gapSchemaId;
    },
    loaded: function loaded() {
      return this.informationAsset !== null;
    },
    gettersName: function gettersName() {
      var _gettersConfig;
      var gettersConfig = (_gettersConfig = {}, _defineProperty(_gettersConfig, MODULES.COMPLIANCE_IA_CONTROLLER, 'complianceIAControllerActivityTypes'), _defineProperty(_gettersConfig, MODULES.COMPLIANCE_IA_PROCESSOR, 'complianceIAProcessorActivityTypes'), _defineProperty(_gettersConfig, MODULES.COMPLIANCE_IA_SYSTEM, 'complianceIASystemActivityTypes'), _defineProperty(_gettersConfig, MODULES.COMPLIANCE_INFORMATION_ASSETS, 'complianceIACustomActivityTypes'), _gettersConfig);
      return gettersConfig[this.module];
    },
    depHeaderProps: function depHeaderProps() {
      if (!this.loaded) {
        return {};
      }
      var titleField = this.titleField || 'name';
      var module = this.module;
      var uniqueIdField = this.uniqueIdField || 'uniqueId';
      var data = {
        activityTypeId: this.informationAsset.activityTypeId,
        editableId: this.uniqueIdReadOnly !== undefined ? !this.uniqueIdReadOnly : true,
        editableTitle: true,
        title: this.informationAsset[titleField],
        filesList: this.informationAsset.attachments,
        tabs: this.tabs,
        saveStatus: this.saveStatus,
        idField: uniqueIdField,
        titleField: titleField,
        trafficLightSettings: {
          value: this.informationAsset.trafficLight,
          lightType: 'trafficLights',
          field: 'trafficLight'
        },
        module: module,
        moduleId: this.informationAsset.id,
        users: this.users
      };
      if (this.informationAsset.objType !== ObjectTypes.INFORMATION_ASSET) {
        data.id = this.informationAsset[uniqueIdField] + '';
      }
      return data;
    },
    delegationData: function delegationData() {
      return {
        objType: this.informationAsset.objType,
        organisationIds: this.informationAsset.organisationIds || [],
        responsibleUserIds: this.informationAsset.responsibleUserIds || [],
        accountableUserIds: this.informationAsset.accountableUserIds || [],
        consultedUserIds: this.informationAsset.consultedUserIds || [],
        informedUserIds: this.informationAsset.informedUserIds || [],
        tagIds: this.informationAsset.tagIds || [],
        module: this.module
      };
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.activityTypes.find(function (at) {
        var _this$informationAsse;
        return ((_this$informationAsse = _this.informationAsset) === null || _this$informationAsse === void 0 ? void 0 : _this$informationAsse.activityTypeId) === at.id;
      });
    },
    genericData: function genericData() {
      if (!this.informationAsset || !this.informationAsset.objType) return;
      return AuditGenericData[this.informationAsset.objType];
    },
    activeQuestionnaires: function activeQuestionnaires() {
      var _this$informationAsse2;
      if (!((_this$informationAsse2 = this.informationAsset) !== null && _this$informationAsse2 !== void 0 && (_this$informationAsse2 = _this$informationAsse2.questionnaires) !== null && _this$informationAsse2 !== void 0 && _this$informationAsse2.length)) return [];
      var enabledQuestionnaireIds = this.questionnaires.map(function (q) {
        return q.id;
      });
      return this.informationAsset.questionnaires.filter(function (q) {
        return enabledQuestionnaireIds.includes(q.questionnaireId);
      });
    }
  }),
  created: function created() {
    this.getQuestionnairesByIAType();
  },
  mounted: function mounted() {
    var _this2 = this;
    Promise.all([this.getInformationAsset()]).then(function () {
      _this2.getAuditQuestionnaires();
    });
  },
  methods: {
    getQuestionnairesByIAType: function getQuestionnairesByIAType() {
      var _this3 = this;
      var promise = null;
      switch (this.informationAssetType) {
        case AssetsTypes.CONTROLLERS:
          {
            promise = getInformationAssetQuestionnaireForController();
            break;
          }
        case AssetsTypes.SYSTEMS:
          {
            promise = getInformationAssetQuestionnaireForSystem();
            break;
          }
        case AssetsTypes.INFORMATION_ASSETS_API:
          {
            promise = getInformationAssetQuestionnairesForCustomIA();
            break;
          }
        case AssetsTypes.PROCESSORS:
          {
            promise = getInformationAssetQuestionnaireForProcessor();
            break;
          }
      }
      if (!promise) {
        this.questionnaires = [];
      } else {
        promise.then(function (_ref) {
          var list = _ref.list;
          _this3.questionnaires = list;
        });
      }
    },
    getIsQuestionnaireIdAddedByIATypeAndId: function getIsQuestionnaireIdAddedByIATypeAndId(type, id) {
      return new Promise(function (resolve, reject) {
        getSingleByType(type, id).then(function (res) {
          return resolve(res.questionnaireId && res.questionnaireId > 0);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    informationAssetPropertyChanged: function informationAssetPropertyChanged(_ref2) {
      var property = _ref2.property,
        value = _ref2.value;
      this.isDirty = true;
      this.informationAsset[property] = value;
      if (property === 'questionnaireId') {
        this.setUpdateTimer(1);
      } else {
        this.setUpdateTimer();
      }
    },
    saveChangesImmediately: function saveChangesImmediately(updatedProperty) {
      this.informationAssetPropertyChanged(updatedProperty);
      this.updateItem();
    },
    getAuditQuestionnaires: function getAuditQuestionnaires() {
      var _this4 = this;
      if (!this.genericData) {
        this.auditQuestionnaires = [];
        return;
      }
      return this.genericData.getAllQuestionnairesAPI(this.genericData.questionnaireType).then(function (response) {
        _this4.auditQuestionnaires = response;
      });
    },
    getInformationAsset: function getInformationAsset() {
      var _this5 = this;
      return getSingleByType(this.informationAssetType, this.id, 0).then(function (response) {
        _this5.informationAsset = response;
      }).catch(function () {
        if (_this5.isPreview) {
          _this5.$emit('accessError');
          return;
        }
        catch403(_this5.$router, 'IAOverview');
      });
    },
    getActivityData: function getActivityData() {
      var _this6 = this;
      this.getInformationAsset().then(function () {
        return _this6.saveStatus = saveStatus.SAVED;
      });
    },
    updatingAttachments: function updatingAttachments() {
      this.saveStatus = saveStatus.SAVING;
    },
    setUpdateTimer: function setUpdateTimer(debounceTimeout) {
      debounceTimeout = debounceTimeout || this.$autoSaveDebounceTime;
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(this.updateItem, debounceTimeout);
    },
    prepareSaveData: function prepareSaveData() {
      var customFieldValues = getCustomFieldValues(this.informationAsset);
      return _objectSpread(_objectSpread({}, this.informationAsset), {}, {
        customFieldValues: customFieldValues
      });
    },
    saveStatusClicked: function saveStatusClicked() {
      if (this.saveStatus !== saveStatus.SAVED) {
        this.updateItem();
      }
    },
    updateItem: function updateItem() {
      var _this7 = this;
      clearTimeout(this.updateTimer);
      this.saveStatus = saveStatus.SAVING;
      this.errorMessage = '';
      var timerId = this.updateTimer;
      if (!this.isDirty) return;
      this.showRetry = false;
      var saveData = this.prepareSaveData();
      updateByType(this.informationAssetType, this.id, saveData).then(function (response) {
        if (timerId === _this7.updateTimer) {
          _this7.isDirty = false;
          _this7.informationAsset = response;
        }
        _this7.saveStatus = saveStatus.SAVED;
        _this7.$emit('updated', _objectSpread({}, _this7.informationAsset));
        var tab = _this7.tabs.find(function (tab) {
          return tab.url === _this7.$router.currentRoute.value.path;
        });
        if (!tab && !_this7.isPreview) {
          _this7.$router.push(_this7.tabs[0].url);
        }
      }).catch(function (error) {
        _this7.saveStatus = saveStatus.NOT_SAVED;
        _this7.showRetry = true;
        _this7.errorMessage = _this7.getError(error);
        _this7.retryAction = function () {
          return _this7.updateItem();
        };
      });
    },
    getError: function getError(error) {
      if (error.response) {
        if (error.response.error) {
          return error.response.error;
        }
        return error.response;
      }
      return error;
    },
    onFileUpdate: function onFileUpdate(fileId) {
      var _this$informationAsse3;
      updateFileLocally(fileId, (_this$informationAsse3 = this.informationAsset) === null || _this$informationAsse3 === void 0 ? void 0 : _this$informationAsse3.attachments);
      this.saveStatus = saveStatus.SAVED;
    },
    handleUpdateForCustomPageUpdate: function handleUpdateForCustomPageUpdate(_ref3) {
      var value = _ref3.value;
      this.isPageWithCustomUpdateDirty = value;
      if (value) {
        this.setUpdateTimer();
      } else if (!this.isDirty) {
        this.saveStatus = saveStatus.SAVED;
      }
      if (this.isPreview) {
        this.getInformationAsset();
      }
    }
  }
};