import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "flex flex-col"
};
var _hoisted_2 = {
  class: "flex justify-between pr-2"
};
var _hoisted_3 = {
  class: "w-full"
};
var _hoisted_4 = ["title"];
var _hoisted_5 = {
  key: 1
};
var _hoisted_6 = ["title"];
var _hoisted_7 = {
  class: "flex"
};
var _hoisted_8 = {
  class: "w-full h-full"
};
var _hoisted_9 = {
  key: 0,
  class: "absolute p-2 rounded-full bg-gray-350 text-gray-710 top-0.3rem right-0.3rem text-sm"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_switch = _resolveComponent("risma-switch");
  var _component_export_frame = _resolveComponent("export-frame");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$props.title ? (_openBlock(), _createElementBlock("h3", {
    key: 0,
    title: $props.title,
    class: "text-gray-710 font-semibold uppercase pb-1 truncate"
  }, _toDisplayString($props.title), 9 /* TEXT, PROPS */, _hoisted_4)) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_renderSlot(_ctx.$slots, "title")])), _createElementVNode("h4", {
    title: $props.subTitle,
    class: "text-gray-550 font-semibold pb-2 text-sm truncate"
  }, _toDisplayString($props.subTitle), 9 /* TEXT, PROPS */, _hoisted_6)]), _renderSlot(_ctx.$slots, "menu", {}, function () {
    return [_createElementVNode("div", _hoisted_7, [$props.monthlyDailyOptions.length ? (_openBlock(), _createBlock(_component_risma_switch, {
      key: 0,
      options: $props.monthlyDailyOptions,
      "model-value": $props.monthlyDailyValue,
      "is-labels-show": true,
      class: "mr-2 mb-auto mt-0.3rem",
      "onUpdate:modelValue": $options.onMonthlyDailyChange
    }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true)])];
  })]), _createElementVNode("div", {
    class: _normalizeClass('p-3 rounded w-full flex-grow ' + $props.overriddenOverlayClasses)
  }, [_createVNode(_component_export_frame, {
    class: _normalizeClass(["w-full h-full", $props.overriddenExportFrameClasses]),
    "is-export-on-parent": $props.isExportOnParent,
    "use-export": $props.useDefaultExport,
    "wrapper-class": "w-full"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_8, [_renderSlot(_ctx.$slots, "content")])];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["class", "is-export-on-parent", "use-export"]), _renderSlot(_ctx.$slots, "default"), $props.labelTitle ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString($props.labelTitle), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */)]);
}