export var VatCountryCode = {
  DK: {
    value: 'DK'
  },
  NO: {
    value: 'NO'
  },
  SE: {
    value: 'SE'
  },
  FI: {
    value: 'FI'
  }
};