function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import RismaSwitch from '@/components/Atoms/Inputs/RismaSwitch';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
import DataFlowTypes from '@/constants/compliance/DataFlowTypes';
import OutsourcingTypes from '@/constants/compliance/OutsourcingTypes';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Selector from '@/components/Atoms/Inputs/Selector';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import OptionsList from '@/components/Molecules/OptionsList/OptionsList';
import HelpToolTip from '@/components/Molecules/HelpToolTip';
import QuestionDependencyEditor from '@/components/Molecules/Compliance/QuestionDependency/QuestionDependencyEditor';
import Alert from '@/components/Molecules/Modal/Alert';
import { IATitleMixin } from '@/mixins/IATitleMixin';
import { createQuestion, editQuestion } from '@/api/compliance/questions';
import DPMTypes from '@/constants/compliance/DataProcessorManagement';
import { ProjectTypes, ForbidenToUseIAForTypes } from '@/constants/compliance/ProjectTypes';
import { removeDuplicates } from '@/utils/filterArray';
import { comparePathStrings } from '@/utils/compare';
var QUESTION_NUMBER_VALID_PATTERNS = [/^[\da-z.]{1,15}$/i, /(?=.*?[^a-z])(?=.*?[^.])/i];
export default {
  name: 'QuestionCreation',
  introduction: 'Component which create a question',
  description: 'Component with form of question creation in modal box.',
  token: "\n    <question-creation\n      :questionnaire-id=\"1\"\n      :questionnaire-type=\"1\"\n      :questions=\"[]\" />",
  components: {
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    RismaSwitch: RismaSwitch,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    InputField: InputField,
    OptionsList: OptionsList,
    HelpToolTip: HelpToolTip,
    QuestionDependencyEditor: QuestionDependencyEditor,
    Selector: Selector,
    Alert: Alert
  },
  /**
   * Props should ALWAYS be type casted
   */
  mixins: [IATitleMixin],
  props: {
    inputData: {
      type: [Object],
      required: false,
      default: null
    },
    questionnaireId: {
      type: Number,
      required: true
    },
    questionnaireType: {
      type: Number,
      required: true
    },
    questions: {
      type: [Object, Array],
      required: true
    },
    customAssetsOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'options for custom inf assets'
    },
    isCanceled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['created', 'canceled', 'dismiss'],
  data: function data() {
    var defaults = {
      id: null,
      questionnumber: null,
      headline: '',
      question: '',
      questiontype: 1,
      help: '',
      options: [],
      optionDetails: [],
      allowNewAnswerOption: 0,
      isArticle30: false,
      dataFlowStakeholder: false,
      dataFlowDataType: false,
      dataFlowSystem: false,
      dataFlowLegalBasis: false,
      user_created: 1,
      answerOptionInput: '',
      dependencies: {},
      outsourcingDueDilligence: false,
      outsourcingRiskAssessment: false,
      outsourcingFunctionDescription: false,
      outsourcingPreliminaryAnalysis: false
    };
    return {
      defaults: defaults,
      showConfirmationAlert: false,
      data: _objectSpread(_objectSpread({}, defaults), this.inputData),
      dataflowTypes: DataFlowTypes(this.$trans),
      outsourcingTypes: OutsourcingTypes(this.$trans),
      DPMTypes: DPMTypes(this.$trans),
      errors: [],
      initialData: null,
      selectedAsset: null,
      allQuestionTypes: QuestionTypes(this.$trans),
      saving: false,
      translate: getTranslate['QuestionCreation'](),
      callStackState: true,
      textAreaStyleClasses: 'border-2 border-gray-350 rounded-md text-gray-750 placeholder-gray-550 hover:border-gray-450 focus:outline-none focus:border-blue-450'
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useSettingsStore, ['isInformationAssetsDisabled', 'isSystemsEnabled', 'isDataControllersEnabled', 'isDataProcessorsEnabled', 'isIACustomEnabled'])), {}, {
    dependencyEditorIsEnabled: function dependencyEditorIsEnabled() {
      return this.getSettingValue('feature.dependency_editor') === 1;
    },
    headlines: function headlines() {
      return removeDuplicates(this.questions, 'headline');
    },
    assetsOptions: function assetsOptions() {
      return this.customAssetsOptions.map(function (item) {
        return {
          value: item.id,
          label: item.label
        };
      });
    },
    selectedDataFlowTypes: function selectedDataFlowTypes() {
      var _this = this;
      return this.dataflowTypes.filter(function (v) {
        return _this.data[v.key];
      });
    },
    selectedOutsourcingTypes: function selectedOutsourcingTypes() {
      var _this2 = this;
      return this.outsourcingTypes.filter(function (v) {
        return _this2.data[v.key];
      });
    },
    selectedDPMTypes: function selectedDPMTypes() {
      var _this3 = this;
      return this.DPMTypes.filter(function (v) {
        return _this3.data[v.key];
      });
    },
    isGDPR: function isGDPR() {
      return this.questionnaireType === ProjectTypes.GDPR;
    },
    isOutsourcing: function isOutsourcing() {
      return this.questionnaireType === ProjectTypes.OUTSOURCING;
    },
    isDataProcessor: function isDataProcessor() {
      return this.questionnaireType === ProjectTypes.DATAPROCESSOR;
    },
    isDPM: function isDPM() {
      return this.questionnaireType === ProjectTypes.DPM;
    },
    isDataflowAvailable: function isDataflowAvailable() {
      var _this4 = this;
      var questionTypes = [this.allQuestionTypes.multipleChoice, this.allQuestionTypes.informationAsset, this.allQuestionTypes.informationAssetSystems, this.allQuestionTypes.informationAssetProcessors, this.allQuestionTypes.informationAssetControllers];
      return this.isGDPR && !!questionTypes.find(function (v) {
        return v.id === _this4.data.questiontype;
      });
    },
    dataflowTypesFiltered: function dataflowTypesFiltered() {
      if (this.data.questiontype === this.allQuestionTypes.informationAssetSystems.id) {
        return this.dataflowTypes.filter(function (type) {
          return type.key !== 'dataFlowStakeholder';
        });
      }
      return this.dataflowTypes;
    },
    isArticle30: function isArticle30() {
      return !!this.data.isArticle30;
    },
    allowNewAnswerOption: function allowNewAnswerOption() {
      return !!this.data.allowNewAnswerOption;
    },
    iaQuestionTypesAvailable: function iaQuestionTypesAvailable() {
      var iaQuestionTypes = [];
      if (this.isSystemsEnabled) {
        iaQuestionTypes.push(this.allQuestionTypes.informationAssetSystems);
      }
      if (this.isDataControllersEnabled) {
        iaQuestionTypes.push(this.allQuestionTypes.informationAssetControllers);
      }
      if (this.isDataProcessorsEnabled) {
        iaQuestionTypes.push(this.allQuestionTypes.informationAssetProcessors);
      }
      if (this.isIACustomEnabled) {
        iaQuestionTypes.push(this.allQuestionTypes.customInformationAsset);
      }
      return iaQuestionTypes;
    },
    questionTypes: function questionTypes() {
      var questionTypes = [this.allQuestionTypes.singleChoice, this.allQuestionTypes.multipleChoice, this.allQuestionTypes.freeText, this.allQuestionTypes.attachment, this.allQuestionTypes.attachmentAndFreeText, this.allQuestionTypes.date, this.allQuestionTypes.dateTime];
      if (this.isIAAllowedInQuestionnaire) {
        var _questionTypes;
        (_questionTypes = questionTypes).push.apply(_questionTypes, _toConsumableArray(this.iaQuestionTypesAvailable));
        questionTypes.push(this.allQuestionTypes.processLibrary);
        if (this.data.id) {
          questionTypes.push(this.allQuestionTypes.informationAsset);
        }
      }
      questionTypes = questionTypes.map(function (type) {
        return {
          label: type.option,
          value: type.id
        };
      });
      return questionTypes;
    },
    selectedQuestionTypes: function selectedQuestionTypes() {
      var _this5 = this;
      return this.data.questiontype ? [this.questionTypes.find(function (q) {
        return q.id == _this5.data.questiontype;
      })] : [];
    },
    /**
     * Get the values entered in the form input fields.
     * @returns {{headline: *, questionnumber: *, question: *, questiontype: *, help: *, options: *, questionnaire_id: (props.qid|{required, type}|qid|*), user_created: *, allowNewAnswerOption: *}}
     */
    formData: function formData() {
      var options = [];
      if (this.data.optionDetails) {
        options = this.data.optionDetails.map(function (option) {
          return option.label;
        });
      }
      var data = {
        id: this.data.id,
        headline: this.data.headline,
        questionnumber: this.data.questionnumber,
        question: this.data.question,
        questiontype: this.data.questiontype,
        help: this.data.help,
        options: options,
        questionnaire_id: this.questionnaireId,
        user_created: this.data.user_created,
        isArticle30: this.data.isArticle30,
        allowNewAnswerOption: this.data.allowNewAnswerOption,
        dependencies: this.data.dependencies,
        informationAssetActivityType: this.data.informationAssetActivityType
      };
      if (this.isDataflowAvailable) {
        data = _objectSpread(_objectSpread({}, data), {}, {
          dataFlowStakeholder: this.data.dataFlowStakeholder,
          dataFlowDataType: this.data.dataFlowDataType,
          dataFlowSystem: this.data.dataFlowSystem,
          dataFlowLegalBasis: this.data.dataFlowLegalBasis
        });
      } else if (this.isOutsourcing) {
        data = _objectSpread(_objectSpread({}, data), {}, {
          outsourcingDueDilligence: this.data.outsourcingDueDilligence,
          outsourcingRiskAssessment: this.data.outsourcingRiskAssessment,
          outsourcingFunctionDescription: this.data.outsourcingFunctionDescription,
          outsourcingPreliminaryAnalysis: this.data.outsourcingPreliminaryAnalysis
        });
      }
      if (this.isDataProcessor || this.isDPM) {
        data = _objectSpread(_objectSpread({}, data), {}, {
          dpmAuditReport: this.data.dpmAuditReport,
          dpmRiskAssessment: this.data.dpmRiskAssessment
        });
      }
      return data;
    },
    /**
     * Get the a list of quesitonnumbers.
     * A question cannot be created if the questionnumber already exists
     * @returns {*|null}
     */
    questionnumbers: function questionnumbers() {
      return this.questions && this.questions.map(function (question) {
        return question.questionnumber;
      }) || null;
    },
    isDirty: function isDirty() {
      return JSON.stringify(this.formData) != JSON.stringify(this.initialData);
    },
    answerOptions: function answerOptions() {
      return [{
        value: true,
        name: this.translate.yes
      }, {
        value: false,
        name: this.translate.no
      }];
    },
    defaultSelectedOptions: function defaultSelectedOptions() {
      return [{
        value: true,
        name: this.translate.yes
      }, {
        value: false,
        name: this.translate.no
      }];
    },
    isIAAllowedInQuestionnaire: function isIAAllowedInQuestionnaire() {
      return this.questionnaireType && !ForbidenToUseIAForTypes.includes(this.questionnaireType);
    }
  }),
  watch: {
    isCanceled: function isCanceled(value) {
      if (value) {
        this.cancel();
      }
    }
  },
  mounted: function mounted() {
    this.initialData = JSON.parse(JSON.stringify(this.formData));
  },
  methods: {
    handleDataFlowChanged: function handleDataFlowChanged(state) {
      var _this6 = this;
      this.dataflowTypes.forEach(function (v) {
        _this6.data[v.key] = !!state && !!state.find(function (s) {
          return s.key === v.key;
        });
      });
    },
    handleOutsourcingChanged: function handleOutsourcingChanged(state) {
      var _this7 = this;
      this.outsourcingTypes.forEach(function (v) {
        _this7.data[v.key] = !!state && !!state.find(function (s) {
          return s.key === v.key;
        });
      });
    },
    handleDPMFlagsChanged: function handleDPMFlagsChanged(state) {
      var _this8 = this;
      this.DPMTypes.forEach(function (v) {
        _this8.data[v.key] = !!state && !!state.find(function (s) {
          return s.key === v.key;
        });
      });
    },
    handleAddToOptionsError: function handleAddToOptionsError() {
      var errorMessage = this.translate.questionAnswersCanNotBeBlank;
      if (this.errors.indexOf(errorMessage) === -1) {
        this.handlePushToErrors(errorMessage);
      }
    },
    /**
     * Handle form submission
     * 1. Clear all errors
     * 2. If the value of answerOptionsInput has not been added to options do so
     * 3. Take care of validation
     * 4. Make the API request
     */
    handleOnSubmit: function handleOnSubmit() {
      // Reset error messages
      this.errors = [];

      // Check if the answer option in the input is added to the options array.
      // if (this.data.answerOptionInput.length > 0) {
      //     this.handleAddToOptions();
      // }

      // Handle validation
      if (this.handleValidation(this.formData) === true) {
        // Do the api request
        this.saving = true;
        this.handleApiRequest(this.formData);
      }
    },
    /**
     * Validate the form data
     * @param formData
     * @returns {boolean}
     */
    handleValidation: function handleValidation(formData) {
      var _this9 = this;
      // Headline should be at least one char
      if (formData.headline.length < 1) {
        this.handlePushToErrors(this.translate.questionMustHaveAHeadline);
      }

      // Quesitonnumber should be at least one char
      if (!formData.questionnumber || formData.questionnumber.length < 1) {
        this.handlePushToErrors(this.translate.questionMustHaveAQuestionNumber);
      }

      //questionnumber shouldn't point on itself, callstack exceed error
      if (formData.dependencies && this.isCallStackExceed(formData, this.questions)) {
        //sorting is the same like on questionDependencyEditor.vue
        var sortedDep = Object.keys(formData.dependencies).sort(function (a, b) {
          return comparePathStrings(a, b);
        });
        var index = sortedDep.findIndex(function (item) {
          return item === formData.questionnumber;
        });
        var prefix = index === -1 ? '' : ": row ".concat(index + 1);
        this.handlePushToErrors(this.translate.theQuestionIsInDependencyLoopWhichCanCauseErrors + prefix);
      }

      // Questionnumber should be in appropriate format
      if (formData.questionnumber && formData.questionnumber.length) {
        if (!this.questionNumberValid(formData.questionnumber)) {
          this.handlePushToErrors(this.translate.invalidQuestionNumberOnlyDigitsDotsAndLet);
        }
      }

      // The question should be longer then 1 char
      if (formData.question.length < 1) {
        this.handlePushToErrors(this.translate.questionMustHaveAText);
      }

      // If question type is 1 or 2 there should be at lease two answer options
      if (formData.questiontype === 1 || formData.questiontype === 2) {
        if (this.data.optionDetails.length <= 1) {
          this.handlePushToErrors(this.translate.youHaveToAddAtLeastTwoAnswerOptions);
        }
      }

      // Check if question number doesn't already exist
      this.questions.forEach(function (question) {
        if (formData.id != question.id && formData.questionnumber === question.questionnumber) {
          _this9.handlePushToErrors(_this9.translate.questionNumberAlreadyExists);
        }
      });
      return this.errors.length <= 0;
    },
    isCallStackExceed: function isCallStackExceed(question, questions) {
      var dependencies = Object.keys(question.dependencies);
      if (dependencies.includes(question.questionnumber)) return true;
      this.callStackState = true;
      var newQuestions = _toConsumableArray(questions);
      var prevIndex = newQuestions.findIndex(function (item) {
        return item.id === question.id;
      });
      if (prevIndex === -1) {
        newQuestions.push(question);
      } else {
        newQuestions.splice(prevIndex, 1, question);
      }
      return !this.checkForCallStackLoop(Object.keys(question.dependencies), newQuestions);
    },
    checkForCallStackLoop: function checkForCallStackLoop(dependencies, questions) {
      var _this10 = this;
      var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      dependencies.forEach(function (item) {
        if (data.includes(item)) {
          _this10.callStackState = false;
          return;
        }
        data.push(item);
        var question = questions.find(function (element) {
          return element.questionnumber === item;
        });
        if (!question) return;
        if (question.dependencies) {
          _this10.checkForCallStackLoop(Object.keys(question.dependencies), questions, data);
        }
      });
      return this.callStackState;
    },
    /**
     * Push an error message to the scope for displaying it in the template
     * @param message
     */
    handlePushToErrors: function handlePushToErrors(message) {
      this.errors.push(message);
    },
    /**
     * Make the API Request.
     * Emit data to the parent component
     * Handle clear input fields
     * @param formData
     */
    handleApiRequest: function handleApiRequest(formData) {
      var _this11 = this;
      var promise = formData.id ? editQuestion : createQuestion;
      promise(formData).then(function (response) {
        return _this11.$emit('created', response);
      }).then(function () {
        return _this11.handleResetToDefaultValues();
      }).catch(function (error) {
        return _this11.handlePushToErrors(error.response.error);
      }).finally(function () {
        return _this11.saving = false;
      });
    },
    /**
     * Reset the inputs to default values
     */
    handleResetToDefaultValues: function handleResetToDefaultValues() {
      var _this12 = this;
      Object.keys(this.defaults).forEach(function (propName) {
        _this12.data[propName] = _this12.defaults[propName];
      });
      this.errors = [];
    },
    onCancel: function onCancel() {
      var withReset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showConfirmationAlert = false;
      if (withReset) {
        this.handleResetToDefaultValues();
        this.$emit('dismiss');
        return;
      }
      this.$emit('canceled');
    },
    cancel: function cancel() {
      if (this.isDirty) {
        this.showConfirmationAlert = true;
      } else {
        this.onCancel(true);
      }
    },
    questionNumberValid: function questionNumberValid(number) {
      for (var _i = 0, _QUESTION_NUMBER_VALI = QUESTION_NUMBER_VALID_PATTERNS; _i < _QUESTION_NUMBER_VALI.length; _i++) {
        var regex = _QUESTION_NUMBER_VALI[_i];
        if (!regex.test(number)) {
          return false;
        }
      }
      return true;
    },
    onQuestionChange: function onQuestionChange(e) {
      var answers = e.data.map(function (item) {
        return item.title;
      });
      if (answers.length) {
        if (!this.data.dependencies) {
          this.data.dependencies = {};
        }
        this.data.dependencies[e.key] = answers;
      } else if (this.data.dependencies && e.key in this.data.dependencies) {
        delete this.data.dependencies[e.key];
      }
      this.$forceUpdate();
    }
  }
};