import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "flex justify-between"
};
var _hoisted_2 = {
  class: "w-full mr-4"
};
var _hoisted_3 = {
  class: "w-full ml-4"
};
var _hoisted_4 = {
  class: "flex justify-between"
};
var _hoisted_5 = {
  class: "w-full mr-4"
};
var _hoisted_6 = {
  class: "w-full ml-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: '',
    "button-ok-text": $data.translate.save,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('accept');
    }),
    onDismiss: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [$props.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        onDismiss: _cache[0] || (_cache[0] = function ($event) {
          return $props.errorMessage = '';
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($props.errorMessage), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_input_field, {
        modelValue: $props.modalData.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $props.modalData.name = $event;
        }),
        title: $data.translate.name,
        placeholder: $data.translate.name,
        type: "text",
        class: "mb-4"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_input_field, {
        modelValue: $props.modalData.address,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $props.modalData.address = $event;
        }),
        title: $data.translate.address,
        placeholder: $data.translate.address,
        type: "text",
        class: "mb-4"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_input_field, {
        modelValue: $props.modalData.phone,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $props.modalData.phone = $event;
        }),
        title: $data.translate.phone,
        placeholder: $data.translate.phone,
        type: "text",
        class: "mb-4"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_input_field, {
        modelValue: $props.modalData.email,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $props.modalData.email = $event;
        }),
        title: $data.translate.email,
        placeholder: $data.translate.email,
        type: "text",
        class: "mb-4"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["button-ok-text", "button-dismiss-text"]);
}