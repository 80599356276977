function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import get from 'lodash/get';
import { mapState } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import * as Utils from '@/utils/Utils';
import { getOrganisationsAndFilterByUserAccess, getAccounts as _getAccounts, getProcesses as _getProcesses, getControlRisks as _getControlRisks } from '@/api/risma/data';
import { ControlViews } from '@/constants/ControlViews';
import { UserLevels } from '@/constants/UserLevels';
import { SortFieldsCompatibility } from '@/constants/SortFieldsCompatibility';
import { ControlTypeFields } from '@/constants/ControlTypeFields';
import { getAssociationName } from '@/utils/controls/associations';
import { getAccountsWithChildren } from '@/utils/controls/accounts';
import { sortAlphabetic } from '@/utils/sort';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { checkControlsOrgAccess } from '@/utils/access';
import { addUnitTimeToDate, isDateBefore } from '@/utils/date';
export var controlsListBaseMixin = {
  data: function data() {
    return {
      controls: [],
      isLoaded: false,
      genericError: null,
      filterByUserAccess: null,
      organisations: [],
      accounts: [],
      risks: [],
      processes: [],
      displayViewLocalStateKeys: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useTagsStore, ['tags'])), {}, {
    filtersData: function filtersData() {
      var mothers = this.motherControls.map(function (control) {
        return {
          label: "".concat(control.cpath, " ").concat(control.title),
          value: +control.cpath,
          cpath: control.cpath
        };
      });
      var result = {
        organisations: this.organisations,
        tags: this.tags,
        accounts: this.accounts,
        risks: this.risks,
        processes: this.processes,
        mother: sortAlphabetic(mothers, 'label'),
        deadline: [{
          id: 0,
          label: this.$trans('No filter'),
          value: null
        }, {
          id: 1,
          label: this.$trans('Next 2 days'),
          value: 2
        }, {
          id: 2,
          label: this.$trans('Next 5 days'),
          value: 5
        }, {
          id: 3,
          label: this.$trans('Next 7 days'),
          value: 7
        }, {
          id: 4,
          label: this.$trans('Next 2 weeks'),
          value: 14
        }, {
          id: 5,
          label: this.$trans('Next month'),
          value: 31
        }, {
          id: 6,
          label: this.$trans('Next quarter'),
          value: 90
        }, {
          id: 7,
          label: this.$trans('Next 6 months'),
          value: 183
        }, {
          id: 8,
          label: this.$trans('Next 12 months'),
          value: 365
        }]
      };
      if (this.isControlCatalogue) {
        result = _objectSpread(_objectSpread({}, result), {}, {
          users: this.users.filter(function (user) {
            return checkControlsOrgAccess(user, UserLevels.NORMAL);
          }),
          showDeactivated: true,
          groupByOptions: [{
            label: this.$trans('Not set'),
            value: null
          }, {
            label: this.$trans('Mother controls'),
            value: 'mothercontrolId'
          }, {
            label: this.$trans('Tags'),
            value: 'tags'
          }, {
            label: this.$trans('Responsible'),
            value: 'responsible'
          }, {
            label: this.$trans('Reviewer'),
            value: 'review'
          }, {
            label: this.$trans('Organisations'),
            value: 'organisationIds'
          }, {
            label: this.accountsTitle,
            value: SortFieldsCompatibility.ACCOUNTS
          }, {
            label: this.processesTitle,
            value: SortFieldsCompatibility.PROCESSES
          }, {
            label: this.risksTitle,
            value: SortFieldsCompatibility.RISKS
          }],
          sortByOptions: this.sortOptions
        });
      }
      return result;
    },
    accountsTitle: function accountsTitle() {
      return getAssociationName(this.getSettingValue('risma.account_to_name'), 'Accounts');
    },
    processesTitle: function processesTitle() {
      return getAssociationName(this.getSettingValue('risma.process_to_name'), 'Processes');
    },
    risksTitle: function risksTitle() {
      return getAssociationName(this.getSettingValue('risma.risk_to_name'), 'Control risks');
    },
    sortOptions: function sortOptions() {
      var result = [{
        id: 1,
        label: this.$trans('Control number'),
        value: SortFieldsCompatibility.CPATH
      }];
      if (this.getSettingValue('risma.show_key_control')) {
        result.push({
          id: 2,
          label: this.$trans('Key control'),
          value: ControlTypeFields.KEY_CONTROL
        });
      }
      if (this.getSettingValue('risma.show_automatic_manual')) {
        result.push({
          id: 3,
          label: this.$trans('Manual'),
          value: ControlTypeFields.KEY_AUTOMATIC_MANUAL
        });
      }
      if (this.getSettingValue('risma.show_preventive_detective')) {
        result.push({
          id: 4,
          label: this.$trans('Preventive'),
          value: ControlTypeFields.KEY_PREVENTIVE_DETECTIVE
        });
      }
      var defaultOptions = [{
        id: 5,
        label: this.$trans('Responsible'),
        value: SortFieldsCompatibility.USER_IDS_RESPONSIBLE
      }, {
        id: 6,
        label: this.$trans('Organisation'),
        value: SortFieldsCompatibility.ORG_IDS
      }, {
        id: 7,
        label: this.$trans('Deadline'),
        value: SortFieldsCompatibility.DEADLINE
      }, {
        id: 8,
        label: this.accountsTitle,
        value: SortFieldsCompatibility.ACCOUNTS
      }, {
        id: 9,
        label: this.processesTitle,
        value: SortFieldsCompatibility.PROCESSES
      }, {
        id: 10,
        label: this.risksTitle,
        value: SortFieldsCompatibility.RISKS
      }];
      result = [].concat(_toConsumableArray(result), defaultOptions);
      return result;
    },
    noControls: function noControls() {
      var result = true;
      for (var key in this.controlsGroup) {
        if (this.controlsGroup[key].activities.length) {
          result = false;
        }
      }
      return result;
    }
  }),
  methods: {
    getOrgs: function getOrgs() {
      var _this = this;
      this.filterByUserAccess = !checkControlsOrgAccess(this.currentUser);
      return getOrganisationsAndFilterByUserAccess(this.filterByUserAccess).then(function (_ref) {
        var list = _ref.list;
        _this.organisations = sortAlphabetic(list, 'visible_name');
      });
    },
    getAccounts: function getAccounts() {
      var _this2 = this;
      return _getAccounts().then(function (_ref2) {
        var list = _ref2.list;
        return _this2.accounts = getAccountsWithChildren(list, false);
      });
    },
    getProcesses: function getProcesses() {
      var _this3 = this;
      return _getProcesses().then(function (_ref3) {
        var list = _ref3.list;
        return _this3.processes = list;
      });
    },
    getControlRisks: function getControlRisks() {
      var _this4 = this;
      return _getControlRisks().then(function (_ref4) {
        var list = _ref4.list;
        return _this4.risks = list;
      });
    },
    appendAdditionalOrgs: function appendAdditionalOrgs(data) {
      this.organisations = Utils.removeDuplicates(this.organisations.concat(data));
      this.organisations = sortAlphabetic(this.organisations, 'visible_name');

      // For normal users we don't need orgs without controls (assigned to them)
      if (this.currentUser.level_controls < UserLevels.PRIVILEGED) {
        this.organisations = RismaControlsUtils.findOrgsInControlsOnly(this.controls, this.organisations);
      }
    },
    setCompability: function setCompability(isControlCatalogue) {
      // Compatibility with Risma1 switcher
      if (this.currentView === 'datatable') {
        this.currentView = ControlViews.COMPACT;
      }
      if (!isControlCatalogue && this.currentView === ControlViews.TREE) {
        this.currentView = ControlViews.EXPANDED;
      }
    },
    getEntityValue: function getEntityValue(item, key, data, propName) {
      var names = Utils.findPropsByIds(get(item, key), data, propName);
      return names.split(',');
    },
    controlPassFilterByDeadlineCriteria: function controlPassFilterByDeadlineCriteria(control) {
      var deadlineCutoff = addUnitTimeToDate(this.filters.deadline, 'days');
      return isDateBefore(control.deadline, deadlineCutoff);
    },
    controlPassFilterByAssociationCriteria: function controlPassFilterByAssociationCriteria(control, listName, list) {
      var _control$listName;
      return (_control$listName = control[listName]) === null || _control$listName === void 0 ? void 0 : _control$listName.some(function (item) {
        return list.find(function (element) {
          return element.id === item.id;
        });
      });
    },
    controlPassFilterBySearchCriteria: function controlPassFilterBySearchCriteria(control) {
      return this.searchByEntity("".concat(control.cpath, " ").concat(control.title));
    },
    controlPassFilterByOrganizationCriteria: function controlPassFilterByOrganizationCriteria(control) {
      return Utils.filterArrayByListOfPropValues(this.filters.organisations, control.organisationIds).length;
    },
    controlPassFilterByTagsCriteria: function controlPassFilterByTagsCriteria(control) {
      var tagIds = control.tagIds || control.tags && control.tags.map(function (tag) {
        return tag.id;
      });
      return Utils.filterArrayByListOfPropValues(this.filters.tags, tagIds).length;
    },
    controlPassFilterByResponsibleCriteria: function controlPassFilterByResponsibleCriteria(control) {
      var responsibleIds = control.userIds && control.userIds.responsible && control.userIds.responsible.map(function (id) {
        return +id;
      });
      return Utils.filterArrayByListOfPropValues(this.filters.responsible, responsibleIds).length;
    },
    searchByEntity: function searchByEntity(item) {
      return item.toLowerCase().trim().indexOf(this.filters.search.toLowerCase().trim()) !== -1;
    }
  }
};