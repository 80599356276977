import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
var KPI_DEFAULT = 'kpi/kpi';
export function getKpiList() {
  return handleRequestResponse(Http.get(KPI_DEFAULT));
}
export function getKpiMetaData() {
  return handleRequestResponse(Http.get('kpi/metadata'));
}
export function createKpi(data) {
  return handleRequestResponse(Http.post(KPI_DEFAULT, data));
}
export function updateKpi(kpiId, data) {
  return handleRequestResponse(Http.patch("".concat(KPI_DEFAULT, "/").concat(kpiId), data));
}
export function deleteKpi(kpiId) {
  return handleRequestResponse(Http.delete("".concat(KPI_DEFAULT, "/").concat(kpiId)));
}
export function getKpiPreviewScore(dashboardId) {
  var query = dashboardId ? '?dashboardId=' + dashboardId : '';
  return handleRequestResponse(Http.get('kpi/calculation' + query));
}
export function getKpiPreviewScoreById(id) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return handleRequestResponse(Http.get("kpi/calculation/".concat(id).concat(params)));
}