import { defineStore } from 'pinia';
import { getSolutions } from '@/api/risma/data';
import cloneDeep from 'lodash/cloneDeep';
export var useSolutionsStore = defineStore('solutionsStore', {
  state: function state() {
    return {
      solutions: []
    };
  },
  getters: {
    enabledSolutions: function enabledSolutions() {
      return this.solutions.filter(function (s) {
        return s.enabled;
      });
    }
  },
  actions: {
    load: function load() {
      var _this = this;
      return getSolutions().then(function (_ref) {
        var list = _ref.list;
        _this.solutions = list;
      });
    },
    addSolution: function addSolution(solution) {
      this.solutions.push(solution);
    },
    updateSolution: function updateSolution(solution) {
      this.solutions = this.solutions.map(function (s) {
        return +s.id === +solution.id ? solution : s;
      });
    },
    deleteSolution: function deleteSolution(solutionId) {
      this.solutions = this.solutions.filter(function (s) {
        return +s.id !== solutionId;
      });
    },
    set: function set(solutions) {
      this.solutions = cloneDeep(solutions);
    }
  }
});