import i18n from '@/i18n';
export var getTranslate = {
  DelegationWell: function DelegationWell() {
    return {
      notifications: i18n.t('Notifications'),
      reviewer: i18n.t('Reviewer'),
      escalation: i18n.t('Escalation'),
      activityType: i18n.t('Activity type'),
      organisations: i18n.t('Organisations'),
      managementLevel: i18n.t('Management level'),
      dailyTeam: i18n.t('Daily team'),
      users: i18n.t('Users'),
      tags: i18n.t('Tags'),
      dependingOn: i18n.t('Depending on'),
      precursorTo: i18n.t('Precursor to'),
      dailyRiskOwner: i18n.t('Daily risk owner'),
      primaryRiskOwner: i18n.t('Primary risk owner'),
      addNew: i18n.t('Add new')
    };
  }
};