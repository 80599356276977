import * as Utils from '@/utils/Utils';
function addHyphensBeforeTitle(item, n) {
  var symbolsToAdd = '-',
    hyphenPart = symbolsToAdd;
  if (typeof n === 'number') {
    try {
      while (n) {
        hyphenPart += symbolsToAdd;
        n--;
      }
    } catch (e) {
      // nothing
    }
  }
  if (item.title) {
    item.title = "".concat(hyphenPart, " ").concat(item.title);
  }
}
export function getAccountsWithChildren(accounts) {
  var hasHyphensBeforeTitle = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var result = [];
  accounts.forEach(function (item) {
    var itemCopy = JSON.parse(JSON.stringify(item));
    result.push(itemCopy);
    if (itemCopy.children) {
      Utils.pushChildrenToParent(itemCopy, result, hasHyphensBeforeTitle ? addHyphensBeforeTitle : false, -1);
    }
  });
  return result;
}