export var LIKELIHOOD_OPTIONS = [{
  label: 'Number of likelihood scores',
  $disabled: true
}, {
  label: '5 likelihood scores',
  value: 5
}, {
  label: '6 likelihood scores',
  value: 6
}, {
  label: 'User-defined axis (table calculation)',
  value: 0
}];
export var IMPACT_OPTIONS = [{
  label: 'Number of likelihood scores',
  $disabled: true
}, {
  label: '5 likelihood scores',
  value: 5
}, {
  label: '6 likelihood scores',
  value: 6
}, {
  label: 'User-defined axis (table calculation)',
  value: 0
}];