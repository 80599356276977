import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
import { getPromiseWithCacheTimeout } from '@/api/HttpCache';
var DOC_TYPES_URL = 'admin/documenttypes';
export function getDocumentTypes() {
  return handleRequestResponse(Http.get(DOC_TYPES_URL));
}
export function createDocumentType(data) {
  return handleRequestResponse(Http.post(DOC_TYPES_URL, data));
}
export function updateDocumentType(id, data) {
  return handleRequestResponse(Http.patch("".concat(DOC_TYPES_URL, "/").concat(id), data));
}
export function deleteDocumentType(id) {
  return handleRequestResponse(Http.delete("".concat(DOC_TYPES_URL, "/").concat(id)));
}
export function getDocumentTypesAll() {
  return getPromiseWithCacheTimeout('data/documenttypes');
}