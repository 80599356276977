import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'InfoBox',
  introduction: 'A container',
  description: 'Any information about anything can be displayed in this container. Used in ISMSOverview page',
  token: "<info-box\n              :title=\"Title\"\n              :link=\"/url\">\n              <template v-slot:icon>\n                <feather-icon\n                  icon=\"plus\"\n                  class=\"stroke-2\" />\n              </template>\n              <div>Some text</div>\n            </info-box>",
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    link: {
      type: String,
      require: false,
      default: '',
      note: 'The url that should be used'
    }
  }
};