import i18n from '@/i18n';
export var getTranslate = {
  InformationAssetsSystemDetails: function InformationAssetsSystemDetails() {
    return {
      location: i18n.t('Location'),
      description: i18n.t('Description'),
      purpose: i18n.t('Purpose'),
      email: i18n.t('Email'),
      riskAssessment: i18n.t('Risk assessment'),
      recoveryTimeObjective: i18n.t('Recovery Time Objective'),
      selectRto: i18n.t('Select RTO'),
      recoveryPointObjective: i18n.t('Recovery Point Objective'),
      selectRpo: i18n.t('Select RPO'),
      anErrorOccurred: i18n.t('An error occurred'),
      tryAgain: i18n.t('Try again')
    };
  }
};