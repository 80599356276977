import { Colors } from '@/Colors';
import * as toBlob from 'canvas-to-blob';
import FileSaver from 'file-saver';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'SaveImage',
  introduction: 'Component is needed to save the tree',
  description: 'Component draw canvas from tree data and then save it',
  token: "<save-image\n      :tree=\"myTree\"/>",
  components: {
    FeatherIcon: FeatherIcon
  },
  data: function data() {
    return {
      lowestY: 10,
      highestX: 0,
      rectHeight: 30,
      rectWidth: 280,
      spaceHeight: 10,
      spaceWidth: 50,
      canvasHeight: 0,
      canvasWidth: 0,
      tree: {},
      colors: Colors,
      closedChildrenList: []
    };
  },
  methods: {
    handleClick: function handleClick() {
      this.closedChildrenList = this.getOpenedNodesList();
      this.tree = $('#tree-jquery').tree('getTree');
      //there are bugs with createelement and filling text in it so use getElementById
      var canvas = document.getElementById('canvas');
      var ctx = canvas.getContext('2d');
      this.createCanvas(canvas, ctx);
      this.save(canvas);
      this.resetAll(canvas);
    },
    getOpenedNodesList: function getOpenedNodesList() {
      var list = [];
      $('#tree-jquery').find('.jqtree-closed').each(function () {
        var node = $('#tree-jquery').tree('getNodeByHtmlElement', this);
        list.push(node.id);
      });
      return list;
    },
    createCanvas: function createCanvas(canvas, ctx) {
      if (this.tree.children) {
        this.setSize(this.tree.children, 10);
      }
      canvas.width = this.canvasWidth;
      canvas.height = this.canvasHeight;
      if (this.tree.children) {
        this.drawAll(ctx, this.tree.children, 10);
      }
    },
    save: function save(canvas) {
      toBlob.init();
      canvas.toBlob(function (blob) {
        FileSaver.saveAs(blob, 'tree.png');
      });
    },
    setSize: function setSize(items, x) {
      var _this = this;
      items.forEach(function (el) {
        _this.canvasHeight += _this.rectHeight + _this.spaceHeight;
        _this.canvasWidth <= x ? _this.canvasWidth = x + _this.rectWidth + _this.spaceWidth : '';
        if (el.children && _this.closedChildrenList.indexOf(el.id) === -1) {
          _this.setSize(el.children, x + _this.rectWidth + _this.spaceWidth);
        }
      });
    },
    resetAll: function resetAll(canvas) {
      this.lowestY = 10;
      this.highestX = 0;
      this.canvasHeight = 0;
      this.canvasWidth = 0;
      canvas.width = 0;
      canvas.height = 0;
    },
    drawAll: function drawAll(canvas, items, x) {
      var _this2 = this;
      var parentX = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var parentY = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
      items.forEach(function (el) {
        var pX = x;
        var pY = _this2.lowestY;
        _this2.drawRect(canvas, x, _this2.lowestY);
        _this2.addText(canvas, x + _this2.rectWidth / 10, _this2.lowestY + _this2.rectHeight * 2 / 3, _this2.getText(el.name), !el.checkaccess ? _this2.rectHeight / 6 : !el.checkaccess);
        if (el.traffic_light !== undefined) {
          _this2.drawTrafficLight(canvas, x, _this2.lowestY, _this2.getTrafficLightColor(el.traffic_light));
        }
        _this2.handleLine(canvas, parentX, parentY, x);
        _this2.lowestY += _this2.rectHeight + _this2.spaceHeight;
        _this2.highestX <= x ? _this2.highestX = x + _this2.rectWidth + _this2.spaceWidth : '';
        if (el.children && _this2.closedChildrenList.indexOf(el.id) === -1) {
          _this2.drawAll(canvas, el.children, x + _this2.rectWidth + _this2.spaceWidth, pX, pY);
        }
      });
    },
    drawRect: function drawRect(canvas, x, y) {
      var width = this.rectWidth;
      var height = this.rectHeight;
      var radius = 5;
      canvas.beginPath();
      canvas.fillStyle = 'white';
      canvas.moveTo(x + radius, y);
      canvas.lineTo(x + width - radius, y);
      canvas.quadraticCurveTo(x + width, y, x + width, y + radius);
      canvas.lineTo(x + width, y + height - radius);
      canvas.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
      canvas.lineTo(x + radius, y + height);
      canvas.quadraticCurveTo(x, y + height, x, y + height - radius);
      canvas.lineTo(x, y + radius);
      canvas.quadraticCurveTo(x, y, x + radius, y);
      canvas.closePath();
      canvas.fill();
      canvas.stroke();
    },
    drawLine: function drawLine(canvas, firstX, firstY, lastX, lastY) {
      canvas.lineWidth = 3;
      canvas.beginPath();
      canvas.moveTo(firstX, firstY);
      canvas.lineTo(firstX + (lastX - firstX) / 2, firstY);
      canvas.stroke();
      canvas.beginPath();
      canvas.moveTo(firstX + (lastX - firstX) / 2, firstY);
      canvas.lineTo(firstX + (lastX - firstX) / 2, lastY);
      canvas.stroke();
      canvas.beginPath();
      canvas.moveTo(firstX + (lastX - firstX) / 2, lastY);
      canvas.lineTo(lastX, lastY);
      canvas.stroke();
      canvas.lineWidth = 1;
    },
    drawTrafficLight: function drawTrafficLight(canvas, x, y, color) {
      canvas.beginPath();
      canvas.arc(x + 15, y + this.rectHeight / 2, 5, 0, 2 * Math.PI);
      canvas.fillStyle = color;
      canvas.fill();
      canvas.stroke();
    },
    addText: function addText(canvas, x, y, text) {
      var linethroughY = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      canvas.beginPath();
      canvas.font = '1em Titillium Web sans-serif';
      canvas.fillStyle = 'black';
      canvas.fillText(text, x, y);
      if (linethroughY) {
        var _canvas$measureText = canvas.measureText(text),
          width = _canvas$measureText.width;
        canvas.fillRect(x, y - linethroughY, width, 2);
      }
      canvas.stroke();
    },
    getText: function getText(text) {
      if (text.length > 30) {
        return this.$truncate(text, 30);
      } else {
        return text;
      }
    },
    getTrafficLightColor: function getTrafficLightColor(n) {
      return Colors.getTrafficlightByNumber(n ? n : 0).color;
    },
    handleLine: function handleLine(canvas, parentX, parentY, x) {
      if (parentX && parentY) {
        this.drawLine(canvas, parentX + this.rectWidth, parentY + this.rectHeight / 2, x, this.lowestY + this.rectHeight / 2);
      }
    }
  }
};