function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getAllProcessors, updateByType } from '@/api/compliance/informationAssets';
import { getCVRCompany } from '@/api/external/cvrapi';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import CompanyLinkModal from '@/components/Molecules/Companies/CompanyLinkModal';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InformationAssetCreationModal from '@/components/Molecules/InformationAssets/InformationAssetCreationModal';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import CvrModal from '@/components/Molecules/Modal/CvrModal';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { objTypeToHumanReadable, ObjectTypes, OBJ_TYPE_TO_MODULE } from '@/constants/ObjectTypes';
import { getCVRQueryParam } from '@/utils/format/companies';
import { generateComputed } from '@/utils/base/generateComputed';
import { isEmailValid, isValidVatNo as _isValidVatNo } from '@/utils/validations';
import { compareStrings } from '@/utils/compare';
export default {
  name: 'CompanyDescription',
  components: {
    RismaTitle: RismaTitle,
    InputField: InputField,
    RismaButton: RismaButton,
    RismaLabel: RismaLabel,
    RismaToggle: RismaToggle,
    TinyMceBox: TinyMceBox,
    CompanyLinkModal: CompanyLinkModal,
    NotificationPopUp: NotificationPopUp,
    FeatherIcon: FeatherIcon,
    InformationAssetCreationModal: InformationAssetCreationModal,
    PreviewModalLink: PreviewModalLink,
    CvrModal: CvrModal
  },
  mixins: [NotificationPopUpMixin],
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Company object'
    }
  },
  emits: ['propertyChanged', 'validationError', 'refreshCompany'],
  data: function data() {
    return {
      showIALinkModal: false,
      showAssetCreationModal: false,
      createAssetType: '',
      dataProcessors: [],
      selectedDataProcessorId: null,
      objTypeToHumanReadable: objTypeToHumanReadable,
      modalCVRData: {},
      modalCVRDefaultData: {
        name: '',
        address: '',
        phone: '',
        email: '',
        vatNo: ''
      },
      updatedAssetFromPreview: null,
      showCVRModal: false,
      translate: getTranslate['CompanyDescription'](),
      fieldMaxSymbols: 150
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, generateComputed(['address', 'zipCode', 'city', 'countryCode', 'phone', 'vatNo', 'syncWithExternal'], {
    object: 'data',
    event: 'propertyChanged'
  })), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, ['complianceIACustomActivityTypes'])), {}, {
    informationAssetsName: function informationAssetsName() {
      return this.getSettingValue('feature.information_assets_name') || this.$trans('Assets');
    },
    attachIATitle: function attachIATitle() {
      return this.translate.attach + ' ' + this.informationAssetsName;
    },
    isValidCompanyEmail: function isValidCompanyEmail() {
      if (!this.data.email) return true;
      return isEmailValid(this.data.email);
    },
    isValidVatNo: function isValidVatNo() {
      return _isValidVatNo(this.data.vatNo, this.countryCode);
    },
    isGetInfoDisabled: function isGetInfoDisabled() {
      var _this$countryCode;
      var countryCode = (_this$countryCode = this.countryCode) === null || _this$countryCode === void 0 ? void 0 : _this$countryCode.toLowerCase();
      if (!['dk', 'se', 'no'].includes(countryCode)) {
        return true;
      }
      if (!this.data.vatNo || !this.isValidVatNo) {
        return true;
      }
      return false;
    },
    assets: function assets() {
      var _this = this;
      if (!this.data.connectedInformationAssets) return [];
      var result = [];
      Object.values(this.data.connectedInformationAssets || {}).forEach(function (assets) {
        var _result;
        if (!assets.length) return;
        (_result = result).push.apply(_result, _toConsumableArray(assets));
      });
      result = result.map(function (asset) {
        return _objectSpread(_objectSpread({}, asset), {}, {
          moduleTitle: _this.getAssetModuleTitle(asset)
        });
      });
      return result.sort(function (a1, a2) {
        return compareStrings(a1.moduleTitle, a2.moduleTitle);
      });
    }
  }),
  mounted: function mounted() {
    this.getProcessors();
  },
  methods: {
    getProcessors: function getProcessors() {
      var _this2 = this;
      return getAllProcessors().then(function (_ref) {
        var list = _ref.list;
        return _this2.dataProcessors = _this2.getDataProcessorsFlat(list);
      });
    },
    getDataProcessorsFlat: function getDataProcessorsFlat(processors) {
      return processors.reduce(function (list, processor) {
        var _processor$childrenOb;
        list.push(processor);
        if ((_processor$childrenOb = processor.childrenObjects) !== null && _processor$childrenOb !== void 0 && _processor$childrenOb.length) list.push.apply(list, _toConsumableArray(processor.childrenObjects));
        return list;
      }, []);
    },
    changeDescription: function changeDescription(value) {
      this.$emit('propertyChanged', {
        property: 'description',
        value: value
      });
    },
    updateEmail: function updateEmail(value) {
      this.$emit('propertyChanged', {
        property: 'email',
        value: value
      });
    },
    handleGetInfo: function handleGetInfo() {
      var _this3 = this;
      var params = getCVRQueryParam(this.countryCode);
      return getCVRCompany(this.vatNo, params).then(function (_ref2) {
        var list = _ref2.list;
        return list[0];
      }).then(function (company) {
        _this3.modalCVRData = _objectSpread(_objectSpread({}, _this3.modalCVRDefaultData), {}, {
          vatNo: _this3.vatNo,
          zipCode: company.zipcode
        }, company);
        _this3.showCVRModal = true;
      }).catch(function () {
        _this3.$emit('validationError', 'error', 'No results found! Did you enter valid VAT number?');
      });
    },
    populateInfoFromCVR: function populateInfoFromCVR(info) {
      var _this4 = this;
      var fields = ['name', 'address', 'zipCode', 'city', 'phone', 'email'];
      fields.forEach(function (field) {
        if (!info[field]) return;
        _this4.$emit('propertyChanged', {
          property: field,
          value: info[field]
        });
      });
    },
    onShowAssetCreationModal: function onShowAssetCreationModal(assetType) {
      this.createAssetType = assetType;
      this.showIALinkModal = false;
      this.showAssetCreationModal = true;
    },
    dismissModal: function dismissModal() {
      this.selectedDataProcessorId = null;
      this.showIALinkModal = false;
    },
    onNewAssetCreated: function onNewAssetCreated(data) {
      var _this5 = this;
      this.showAssetCreationModal = false;
      this.updateCompanyLinkToInformationAssets(this.createAssetType, [data.id], this.data.id).then(function () {
        _this5.$emit('refreshCompany');
      });
    },
    unlinkAssetFromPreviewList: function unlinkAssetFromPreviewList(data) {
      var _this6 = this;
      var assetType = this.getAssetTypeByObjectType(data.objType);
      return updateByType(assetType, data.id, {
        companyId: null
      }).then(function (data) {
        _this6.$emit('refreshCompany');
        return data;
      });
    },
    updateCompanyForIAByType: function updateCompanyForIAByType(type, assetId, companyId) {
      return updateByType(type, assetId, {
        companyId: companyId
      });
    },
    updateCompanyLinkToInformationAssets: function updateCompanyLinkToInformationAssets(assetType, assetsIds, companyId) {
      var _this7 = this;
      var promises = [];
      assetsIds.forEach(function (assetId) {
        promises.push(_this7.updateCompanyForIAByType(assetType, assetId, companyId));
      });
      return Promise.all(promises);
    },
    onUpdateIALinks: function onUpdateIALinks(_ref3) {
      var _this8 = this;
      var assetType = _ref3.assetType,
        assetsIds = _ref3.assetsIds,
        module = _ref3.module,
        activityTypeId = _ref3.activityTypeId;
      var idsToLink = [];
      var idsToDelink = [];
      var promises = [];
      var linkedItems = this.data.connectedInformationAssets[module] || [];
      var linkedItemsIds = [];
      if (activityTypeId) {
        linkedItemsIds = linkedItems.filter(function (item) {
          return +item.activityTypeId === +activityTypeId;
        }).map(function (item) {
          return item.id;
        });
      } else {
        linkedItemsIds = linkedItems.map(function (item) {
          return item.id;
        });
      }
      linkedItemsIds.forEach(function (linkedId) {
        if (!assetsIds.includes(linkedId)) {
          idsToDelink.push(linkedId);
        }
      });
      assetsIds.forEach(function (assetId) {
        if (!linkedItemsIds.includes(assetId)) {
          idsToLink.push(assetId);
        }
      });
      if (idsToLink && idsToLink.length) {
        promises.push(this.updateCompanyLinkToInformationAssets(assetType, idsToLink, this.data.id));
      }
      if (idsToDelink && idsToDelink.length) {
        promises.push(this.updateCompanyLinkToInformationAssets(assetType, idsToDelink, null));
      }
      return Promise.all(promises).then(function () {
        if (idsToLink.length) {
          _this8.setNotificationData({
            message: _this8.translate.companyHasBeenLinked
          });
        }
        _this8.dismissModal();
        _this8.$emit('refreshCompany');
      });
    },
    updateAsset: function updateAsset() {
      var _this9 = this;
      if (!this.updatedAssetFromPreview) return;
      var updatedAssetModule = OBJ_TYPE_TO_MODULE[this.updatedAssetFromPreview.objType];
      var assets = this.data.connectedInformationAssets[updatedAssetModule];
      var idx = assets.findIndex(function (asset) {
        return asset.id === _this9.updatedAssetFromPreview.id;
      });
      if (idx !== -1) {
        if (this.updatedAssetFromPreview.companyId !== this.data.id) {
          this.data.connectedInformationAssets[updatedAssetModule].splice(idx, 1); // if asset was unlinked in preview
        } else {
          this.data.connectedInformationAssets[updatedAssetModule][idx] = this.updatedAssetFromPreview;
        }
      }
      this.updatedAssetFromPreview = null;
    },
    resetCVRModal: function resetCVRModal() {
      this.showCVRModal = false;
      this.modalCVRData = _objectSpread({}, this.modalCVRDefaultData);
    },
    onCVRModalSubmit: function onCVRModalSubmit() {
      this.populateInfoFromCVR(this.modalCVRData);
      this.resetCVRModal();
    },
    getAssetTypeByObjectType: function getAssetTypeByObjectType(objType) {
      if (objType.startsWith(ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR)) {
        return AssetsTypes.PROCESSORS;
      }
      if (objType.startsWith(ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER)) {
        return AssetsTypes.CONTROLLERS;
      }
      if (objType.startsWith(ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM)) {
        return AssetsTypes.SYSTEMS;
      }
      if (objType.startsWith(ObjectTypes.INFORMATION_ASSET)) {
        return AssetsTypes.INFORMATION_ASSETS_API;
      }
      return null;
    },
    getAssetModuleTitle: function getAssetModuleTitle(asset) {
      if (asset.objType === ObjectTypes.INFORMATION_ASSET && asset.activityTypeId) {
        var _this$complianceIACus;
        return ((_this$complianceIACus = this.complianceIACustomActivityTypes.find(function (type) {
          return type.id === asset.activityTypeId;
        })) === null || _this$complianceIACus === void 0 ? void 0 : _this$complianceIACus.label) || '';
      }
      return this.$trans(objTypeToHumanReadable[asset.objType]);
    }
  }
};