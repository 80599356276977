import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
export default {
  name: 'UserRightsAdmin',
  introduction: 'admin items for both risks and compliance with admin rights',
  description: '',
  token: '<user-rights-admin />',
  components: {
    NotificationPopUp: NotificationPopUp
  },
  data: function data() {
    return {
      notifications: []
    };
  },
  methods: {
    dismissNotification: function dismissNotification(index) {
      this.notifications.splice(index, 1);
    },
    handleShowNotification: function handleShowNotification(notification) {
      this.notifications.push(notification);
    }
  }
};