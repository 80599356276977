import { Colors } from '@/Colors';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
export default {
  name: 'GapResultRow',
  introduction: 'A table row to display gap results',
  description: 'A table row to display gap search results',
  token: '<gap-result-row />',
  components: {
    Trafficlight: Trafficlight,
    PreviewModalLink: PreviewModalLink,
    RismaTitle: RismaTitle
  },
  props: {
    gap: {
      type: Object,
      required: true,
      note: 'The gap data'
    },
    showRiskAssessment: {
      type: Boolean,
      required: true,
      default: true,
      note: 'defines if risk assessment info should be displayed'
    }
  },
  data: function data() {
    return {
      getPreview: getFieldsFromObjectForPreview,
      translate: {
        thisHasNotBeenAnsweredYet: this.$trans('This has not been answered yet'),
        requirement: this.$trans('Requirement')
      }
    };
  },
  computed: {
    gapColor: function gapColor() {
      var _this = this;
      return Colors.gaps.filter(function (color) {
        return color.number === _this.gap.trafficLight;
      })[0];
    }
  }
};