function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { getTranslate } from './translate';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Alert from '@/components/Molecules/Modal/Alert';
import { RiskStatusText } from '@/constants/risks/RiskStatus';
import * as RiskApi from '@/api/risk/risk';
import { isLockedRiskUser } from '@/utils/access';
export default {
  name: 'RiskAdminPeriods',
  introduction: '',
  description: '',
  token: '<risk-admin-periods></risk-admin-periods>',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    Alert: Alert
  },
  props: {
    filteredRiskProjects: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: ''
    },
    projectId: {
      type: [String, Number],
      required: true,
      note: ''
    }
  },
  emits: ['refreshRiskProjects'],
  data: function data() {
    return {
      idToDelete: null,
      showConfirmationAlert: false,
      cellCssStyles: 'text-left border-b border-gray-450 p-2',
      translate: getTranslate['RiskAdminPeriods']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    isLocked: function isLocked() {
      return isLockedRiskUser(this.currentUser);
    },
    project: function project() {
      var _this = this;
      return this.filteredRiskProjects.find(function (project) {
        return +project.id === +_this.projectId;
      }) || {};
    },
    columns: function columns() {
      return [this.translate.title, this.translate.risks, this.translate.status];
    },
    dataset: function dataset() {
      if (!this.project.projectPeriods) return [];
      var periods = this.getCorrectOrder(this.project.projectPeriods);
      return this.prepareDataset(periods);
    },
    entityMainTitle: function entityMainTitle() {
      return "".concat(this.project.title, " ").concat(this.translate.periods);
    }
  }),
  methods: {
    getCorrectOrder: function getCorrectOrder(data) {
      var result = _toConsumableArray(data).reverse();
      result.map(function (item, index) {
        if (item.isCurrent) {
          result.splice(index, 1);
          result.unshift(item);
        }
      });
      return result;
    },
    prepareDataset: function prepareDataset(data) {
      var _this2 = this;
      return data.map(function (item) {
        return _this2.createDataItem(item);
      });
    },
    createDataItem: function createDataItem(item) {
      var result = {
        id: item.id,
        title: item.title,
        currentState: this.$trans(RiskStatusText[item.status] || ''),
        risks: item.riskCount,
        status: item.status,
        url: this.prepareDetailUrl(item.id)
      };
      return result;
    },
    prepareDetailUrl: function prepareDetailUrl(path) {
      return "/settings/riskadmin/periods/".concat(this.project.id, "/").concat(path);
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var _this3 = this;
      this.showConfirmationAlert = false;
      return RiskApi.deletePeriod(this.project.id, this.idToDelete).then(function () {
        _this3.$emit('refreshRiskProjects');
      }).catch(function (e) {
        throw e;
      });
    },
    deleteEntity: function deleteEntity(id) {
      this.idToDelete = id;
      this.showConfirmationAlert = true;
    }
  }
};