import { getTranslate } from './translate';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import StaticTable from '@/components/Molecules/StaticTable';
import NoSearchResults from '@/components/Pages/Compliance/Reports/NoSearchResults';
import * as Utils from '@/utils/Utils';
import { escapeHtml } from '@/utils';
import { StaticTableThemes } from '@/constants/StaticTableThemes';
export default {
  name: 'AddContactPersonModal',
  description: 'Modal for adding contact person',
  token: '<add-contact-person-modal users="users" />',
  components: {
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    StaticTable: StaticTable,
    NoSearchResults: NoSearchResults
  },
  props: {
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of users to choose from'
    },
    companies: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of companies'
    }
  },
  emits: ['add', 'dismiss'],
  data: function data() {
    return {
      StaticTableThemes: StaticTableThemes,
      translate: getTranslate['AddContactPersonModal']()
    };
  },
  computed: {
    dataset: function dataset() {
      var _this = this;
      return this.users.map(function (user) {
        var _escapeHtml, _escapeHtml2;
        return {
          name: escapeHtml(user.name),
          title: (_escapeHtml = escapeHtml(user.title)) !== null && _escapeHtml !== void 0 ? _escapeHtml : '',
          phone: (_escapeHtml2 = escapeHtml(user.phone)) !== null && _escapeHtml2 !== void 0 ? _escapeHtml2 : '',
          company: Utils.findPropById(user.companyId, _this.companies, 'name')
        };
      });
    },
    columns: function columns() {
      return [this.translate.name, this.translate.title, this.translate.phone, this.translate.contractParty];
    }
  },
  methods: {
    addUser: function addUser(userId) {
      this.$emit('add', userId);
    },
    clickRow: function clickRow(row) {
      var userId = this.users[row].id;
      this.addUser(userId);
    }
  }
};