function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import * as Utils from '@/utils/Utils';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import TrafficLightSelector from '@/components/Organisms/Selectors/TrafficLightSelector';
import Alert from '@/components/Molecules/Modal/Alert';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import InputFieldSelect from '@/components/Atoms/Inputs/InputFieldSelect';
import HelpToolTip from '@/components/Molecules/HelpToolTip';
import { CustomFieldsMixin } from '@/components/Molecules/CustomFieldsMixin';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { Colors } from '@/Colors';
export default {
  name: 'RepeatableFields',
  introduction: 'Repeatable Fields',
  description: 'Repeatable custom fields (on DEP)',
  token: '<repeatable-fields :data="data"/>',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    InputField: InputField,
    TrafficLightSelector: TrafficLightSelector,
    Alert: Alert,
    DateTimePicker: DateTimePicker,
    InputFieldSelect: InputFieldSelect,
    HelpToolTip: HelpToolTip
  },
  mixins: [CustomFieldsMixin],
  props: {
    id: {
      type: Number,
      required: true,
      note: 'Main id of repeatable cf'
    },
    data: {
      type: Object,
      required: true,
      note: 'Repeatable field self (field)'
    },
    customFieldValues: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Saved custom field values'
    },
    allFields: {
      type: Array,
      required: true,
      note: 'All fields (for save)'
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Are custom fields disabled?'
    }
  },
  emits: ['changed'],
  data: function data() {
    return {
      entityGroups: [],
      colors: Colors,
      CustomFieldTypes: CustomFieldTypes,
      confirmDelete: null,
      itemDeleteId: null,
      currentTextarea: null,
      translate: getTranslate['RepeatableFields']()
    };
  },
  beforeMount: function beforeMount() {
    if (this.customFieldValues.length) {
      this.addSelectedValues(this.customFieldValues);
    } else {
      this.addRow();
    }
  },
  methods: {
    addRow: function addRow(e) {
      if (e) {
        e.preventDefault();
      }
      this.entityGroups.push(this.preparePureEntity(this.data.children));
      this.swapRepeatableField();
      this.$forceUpdate();
    },
    swapRepeatableField: function swapRepeatableField() {
      var field = _objectSpread(_objectSpread({}, this.data), {}, {
        selectedValues: _objectSpread({}, this.entityGroups)
      });
      this.swapToActualField(this.allFields, field);
    },
    onChangeBase: function onChangeBase(field, rowNumber) {
      if (field) {
        field.rowNumber = rowNumber;
      }
      this.$forceUpdate();
      this.$emit('changed', field);
    },
    onChangeTextArea: function onChangeTextArea(field, e, rowNumber) {
      if (this.disabled) return;
      field.selectedValue = e.target.value;
      this.onChangeBase(field, rowNumber);
    },
    onChangeRepetableField: function onChangeRepetableField(field, e, rowNumber) {
      field.selectedValue = Utils.hasValue(e) ? this.setMaxlength(e) : '';
      this.onChangeBase(field, rowNumber);
    },
    addSelectedValues: function addSelectedValues() {
      var _this = this;
      this.customFieldValues.map(function (customFieldValue) {
        if (customFieldValue.fieldId === _this.id && customFieldValue.rows) {
          for (var rowNumber in customFieldValue.rows) {
            _this.entityGroups[rowNumber] = _this.addSavedValues(_this.data.children, customFieldValue.rows[rowNumber]);
          }
          _this.swapRepeatableField();
        }
      });
    },
    addSavedValues: function addSavedValues(data, rowData) {
      var newData = this.preparePureEntity(data);
      newData.map(function (dataItem) {
        rowData.map(function (rowItem) {
          if (dataItem.id === rowItem.fieldId) {
            if (dataItem.typeId === CustomFieldTypes.SELECT) {
              var _options$find;
              var options = Utils.getProperSelectedOptions(dataItem.options);
              dataItem.selectedValue = (_options$find = options.find(function (option) {
                return option.id === rowItem.value;
              })) === null || _options$find === void 0 ? void 0 : _options$find.value;
            } else {
              dataItem.selectedValue = rowItem.value;
            }
          }
        });
      });
      return newData;
    },
    onDelete: function onDelete(rowNumber, e) {
      if (e) {
        e.preventDefault();
      }
      this.itemDeleteId = rowNumber;
      this.confirmDelete = this.translate.areYouSureYouWantToDeleteThisRepeatableField;
    },
    hideAlert: function hideAlert() {
      this.confirmDelete = null;
      this.itemDeleteId = null;
    },
    removeEntity: function removeEntity() {
      var _this2 = this;
      if (this.entityGroups[this.itemDeleteId]) {
        var field = this.allFields.find(function (field) {
          return field.id === _this2.data.id;
        });
        this.entityGroups = this.entityGroups.filter(function (_, index) {
          return index !== _this2.itemDeleteId;
        });
        field.selectedValues = _objectSpread({}, this.entityGroups);
        this.hideAlert();
        this.onChangeBase();
        this.$forceUpdate();
      }
    },
    preparePureEntity: function preparePureEntity(data) {
      var result = data.map(function (item) {
        return _objectSpread({}, item);
      });
      return result;
    },
    toggleTextareaSize: function toggleTextareaSize(field) {
      this.currentTextarea = field;
    }
  }
};