import i18n from '@/i18n';
export var getTranslate = {
  Gap: function Gap() {
    return {
      analysis: i18n.t('Analysis'),
      typeHere: i18n.t('Type here'),
      riskAssessment: i18n.t('Risk assessment'),
      addMitigationActivities: i18n.t('Add mitigation activities'),
      addRecommendedActivities: i18n.t('Add recommended activities'),
      questionnaireIsAlreadyApplied: i18n.t('Questionnaire is already applied'),
      clickToAddText: i18n.t('Click to add text'),
      selectGapType: i18n.t('Select gap type'),
      selectGapTypes: i18n.t('Select gap types'),
      gapType: i18n.t('Gap type'),
      gapTypes: i18n.t('Gap types')
    };
  },
  GapSchemaHeatmaps: function GapSchemaHeatmaps() {
    return {
      heatMapForConsequenceParameter: i18n.t('Heat map for consequence parameter'),
      systems: i18n.t('Systems'),
      threats: i18n.t('Threats')
    };
  }
};