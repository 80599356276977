import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4ab6ea3c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "mb-8"
};
var _hoisted_2 = {
  class: "flex justify-between mb-4"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  class: "flex justify-between mb-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_file_upload = _resolveComponent("risma-file-upload");
  var _component_color_picker = _resolveComponent("color-picker");
  return _openBlock(), _createElementBlock("div", null, [$data.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.errorMessage), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    class: "main-title bg-white mb-6",
    title: $data.translate.lookAndFeel
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [_createVNode(_component_risma_title, {
    class: "sector-title",
    title: $data.translate.menuIcon
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.changeTheIconInTheNavigationBar,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])]), _createVNode(_component_risma_button, {
    text: $data.translate.reset,
    type: "util",
    class: "ml-2",
    size: "normal",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.updateSetting($data.logoSettings.SETTING, false);
    })
  }, null, 8 /* PROPS */, ["text"])]), _createElementVNode("div", null, [_createElementVNode("img", {
    src: $options.imgSrc,
    class: "border border-black p-4 logo-image",
    style: _normalizeStyle("background-color: ".concat($options.logoBackgroundColor)),
    width: "400",
    height: "160"
  }, null, 12 /* STYLE, PROPS */, _hoisted_3), _createElementVNode("div", {
    class: "flex items-center cursor-pointer mt-4",
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.uploadClick && $options.uploadClick.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    class: "mr-2",
    width: "30",
    height: "30",
    icon: "upload"
  }), _createVNode(_component_risma_title, {
    title: $data.translate.uploadNewIcon + ' (200 x 40)'
  }, null, 8 /* PROPS */, ["title"])]), _withDirectives(_createVNode(_component_risma_file_upload, {
    multiple: false,
    onUpload: $options.handleLoad
  }, null, 8 /* PROPS */, ["onUpload"]), [[_vShow, false]])])]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", null, [_createVNode(_component_risma_title, {
    class: "text-21px",
    title: $data.translate.menuColorScheme
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.changeTheColorForTheNavigationBar,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])]), _createVNode(_component_risma_button, {
    text: $data.translate.reset,
    type: "util",
    class: "ml-2",
    size: "normal",
    onClick: $options.resetColors
  }, null, 8 /* PROPS */, ["text", "onClick"])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.colorSettings, function (item, key) {
    return _openBlock(), _createElementBlock("div", {
      key: "config-".concat(key),
      class: "flex justify-between items-center setting-item"
    }, [_createVNode(_component_risma_title, {
      title: _ctx.$trans(item.title),
      class: "mr-4 my-4 w-4/5 text-21px"
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_color_picker, {
      color: item.color,
      onInput: function onInput($event) {
        return $options.onColorChanged(item, $event);
      },
      onClose: function onClose($event) {
        return $options.updateSetting(item.settingName, $event);
      }
    }, null, 8 /* PROPS */, ["color", "onInput", "onClose"])]);
  }), 128 /* KEYED_FRAGMENT */))])]);
}