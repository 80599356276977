import i18n from '@/i18n';
export var getTranslate = {
  DelegationList: function DelegationList() {
    return {
      selected: i18n.t('selected'),
      username: i18n.t('Username'),
      fullName: i18n.t('Full name'),
      initials: i18n.t('Initials'),
      email: i18n.t('Email')
    };
  }
};