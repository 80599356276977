function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getUsersChangelog } from '@/api/admin/reports';
import * as Utils from '@/utils/Utils';
import { toLocalDateTimeFull } from '@/utils/date';
import { getTranslatables } from '@/constants/datatable/Translatables';
import { SortDirection } from '@/constants/SortDirection';
import { UserLevelsByValue, UserLevels } from '@/constants/UserLevels';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import MainWell from '@/components/Atoms/Containers/MainWell';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
export default {
  name: 'AdminUsersChangelog',
  components: {
    RismaTitle: RismaTitle,
    MainWell: MainWell,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaDatatable: RismaDatatable
  },
  mixins: [RismaDatatableDefaultMixin],
  data: function data() {
    return {
      isLoaded: false,
      meta: {},
      usersChangelog: [],
      dataset: [],
      defaultSorting: [{
        index: 'date',
        dir: SortDirection.DESC
      }],
      translate: getTranslate['AdminUsersChangelog']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, ['users'])), mapState(useOrganisationsStore, ['organisations'])), {}, {
    showNothingToShowMessage: function showNothingToShowMessage() {
      return !this.datasetSlice.length && this.isLoaded;
    },
    columns: function columns() {
      return {
        user: this.translate.user,
        fieldName: this.translate.fieldName,
        from: this.translate.from,
        to: this.translate.to,
        date: this.translate.date,
        changedBy: this.translate.changedBy
      };
    },
    translatables: function translatables() {
      return getTranslatables('users');
    }
  }),
  watch: {
    dataset: function dataset(newDataset) {
      this.enhanceDataset(newDataset, this.columns);
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      this.isLoaded = false;
      return getUsersChangelog().then(function (data) {
        _this.meta = data.meta;
        _this.dataset = _this.createDataset(data.list);
        _this.isLoaded = true;
      });
    },
    createDataset: function createDataset(list) {
      var _this2 = this;
      return list.map(function (item) {
        return {
          user: {
            field: _this2.getUserNameById(item.cid)
          },
          fieldName: {
            field: _this2.formatFieldName(item)
          },
          from: {
            field: _this2.formatField(item.old_value, item.column)
          },
          to: {
            field: _this2.formatField(item.new_value, item.column)
          },
          date: {
            field: toLocalDateTimeFull(item.created)
          },
          changedBy: {
            field: item.show_user
          }
        };
      });
    },
    formatFieldName: function formatFieldName(item) {
      var fieldName = this.translatables[item.column] || this.$trans(item.column);
      if (item.column === 'access_level' || item.column === 'supervisor') {
        var userProject = this.meta.userProjects.find(function (project) {
          return project.id === item.cid2;
        });
        var project = {
          title: this.translate.unknownProject
        };
        if (userProject && userProject.projectId) {
          project = this.meta.complianceProjects.find(function (project) {
            return project.id === userProject.projectId;
          }) || project;
        } else if (userProject && userProject.riskProjectId) {
          project = this.meta.riskProjects.find(function (project) {
            return project.id === userProject.riskProjectId;
          }) || project;
        }
        return "".concat(project.title, " ").concat(fieldName);
      }
      return fieldName;
    },
    formatField: function formatField(field, key) {
      switch (key) {
        case 'cpassword':
          return field ? this.translate.passwordWasChanged : '';
        case 'level_admin':
          return field ? this.translate.adminAccessLevel : this.translate.nonAdminAccessLevel;
        case 'level_execution':
        case 'level_risk':
        case 'level_controls':
        case 'level_incidents':
        case 'access_level':
          return UserLevelsByValue[field] || UserLevelsByValue[UserLevels.NONE];
        case 'supervisor':
          return +field ? this.translate.enabled : this.translate.disabled;
        case 'orgs':
        case 'orgs_readonly':
          return Utils.findPropsByIds(field, this.organisations, 'visible_name');
        case 'active':
          return field ? this.translate.yes : this.translate.no;
        default:
          return field || '';
      }
    },
    getUserNameById: function getUserNameById(id) {
      var user = this.users.find(function (user) {
        return user.id === id;
      });
      var active = !user || user.active;
      return {
        title: user ? user.display_name : id + '',
        active: active
      };
    }
  }
};