import i18n from '@/i18n';
import { getGapSchemaById } from '@/api/compliance/gapschema';
import GapSchemasTabs from '@/components/Pages/Admin/GapSchemasTabs';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { SETTINGS_ROUTE_NAMES } from '@/constants/routes/settings';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'GapSchemasAdminWrapper',
  components: {
    Notification: Notification,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    GapSchemasTabs: GapSchemasTabs,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    gapSchemaTypeId: {
      default: 0,
      type: [String, Number]
    },
    gapSchemaId: {
      default: 0,
      type: [String, Number],
      note: ''
    }
  },
  data: function data() {
    return {
      notification: {},
      gapSchema: null,
      loading: false,
      translate: {
        complianceAdministration: i18n.t('Compliance administration'),
        back: i18n.t('Back')
      }
    };
  },
  computed: {
    changelogGapSchemaObject: function changelogGapSchemaObject() {
      return {
        id: this.gapSchemaId,
        objType: ObjectTypes.COMPLIANCE_GAP_SCHEMA
      };
    }
  },
  beforeMount: function beforeMount() {
    this.getGapSchema();
  },
  methods: {
    getGapSchema: function getGapSchema() {
      var _this = this;
      this.loading = true;
      return getGapSchemaById(this.gapSchemaId).then(function (data) {
        _this.gapSchema = data;
      }).finally(function () {
        _this.loading = false;
      });
    },
    backToPrevPage: function backToPrevPage() {
      this.$router.push({
        name: SETTINGS_ROUTE_NAMES.gapSchemas,
        query: {
          gapSchemaType: this.gapSchemaTypeId
        }
      });
    },
    setNotification: function setNotification(_ref) {
      var _ref$type = _ref.type,
        type = _ref$type === void 0 ? 'success' : _ref$type,
        message = _ref.message;
      this.notification = {
        type: type,
        message: message
      };
    }
  }
};