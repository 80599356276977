import { getTranslate } from './translate';
import { getActionDashboardsOverview } from '@/api/execution/initiative';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import RismaBarChart from '@/components/Molecules/Charts/RismaBarChart';
import { DateFormats } from '@/constants/DateFormats';
import { RismaHorisontalBarChartMixin } from '@/mixins/RismaHorisontalBarChartMixin';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { getFortnightDateRange } from '@/utils/date';
export default {
  name: 'ActionDashboard',
  introduction: 'Action Dashboard page component',
  description: 'Complex page component, /actions/dashboard',
  token: '<action-dashboard />',
  components: {
    RismaTitle: RismaTitle,
    RismaDateRangePicker: RismaDateRangePicker,
    RismaBarChart: RismaBarChart
  },
  mixins: [RismaHorisontalBarChartMixin],
  setup: function setup() {
    var dateRangeDefault = getFortnightDateRange(DateFormats.DATE_FORMAT);
    return {
      dateRange: getLocalStorageComputed('actions_dahboard', dateRangeDefault, true)
    };
  },
  data: function data() {
    return {
      isLoaded: false,
      charts: null,
      topStatus: {},
      translate: getTranslate['ActionDashboard']()
    };
  },
  computed: {
    totalControl: function totalControl() {
      var _this = this;
      return Object.keys(this.topStatus).map(function (item) {
        return {
          label: item,
          value: _this.topStatus[item]
        };
      });
    }
  },
  mounted: function mounted() {
    this.getActionDashboardOverview();
  },
  methods: {
    getActionDashboardOverview: function getActionDashboardOverview() {
      var _this2 = this;
      this.isLoaded = false;
      return getActionDashboardsOverview(this.dateRange).then(function (_ref) {
        var charts = _ref.charts,
          topStatus = _ref.topStatus;
        _this2.charts = _this2.prepareChartsData(charts);
        _this2.topStatus = topStatus;
        _this2.isLoaded = true;
      });
    },
    changeDatePicker: function changeDatePicker(event) {
      if (event) {
        this.dateRange = {
          startDate: event.startDate,
          endDate: event.endDate
        };
        this.getActionDashboardOverview();
      }
    }
  }
};