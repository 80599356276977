import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2fed0124"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "flex flex-col min-h-12rem"
};
var _hoisted_2 = {
  key: 0,
  class: "mr-1"
};
var _hoisted_3 = {
  class: "flex-grow flex flex-row justify-between overflow-hidden"
};
var _hoisted_4 = {
  class: "flex items-center"
};
var _hoisted_5 = ["href"];
var _hoisted_6 = {
  class: "flex-grow"
};
var _hoisted_7 = {
  key: 2
};
var _hoisted_8 = ["innerHTML"];
var _hoisted_9 = {
  key: 4
};
var _hoisted_10 = ["innerHTML"];
var _hoisted_11 = {
  key: 5
};
var _hoisted_12 = ["innerHTML"];
var _hoisted_13 = {
  class: "relative"
};
var _hoisted_14 = {
  key: 0
};
var _hoisted_15 = {
  key: 1
};
var _hoisted_16 = {
  key: 2
};
var _hoisted_17 = {
  key: 4
};
var _hoisted_18 = {
  key: 5
};
var _hoisted_19 = {
  class: "flex justify-end"
};
var _hoisted_20 = {
  class: "test"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$activity$acco;
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_progress_bar = _resolveComponent("risma-progress-bar");
  return _openBlock(), _createElementBlock("div", {
    class: "relative shadow-lg border-2 px-4 py-2 m-2 bg-gray-75 bg-no-repeat watermark-background",
    style: _normalizeStyle({
      backgroundImage: $props.watermarkImage
    })
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass([{
      red: _ctx.trafficLight === 1
    }, "flex w-full items-center mb-4 overflow-hidden"])
  }, [_ctx.trafficLight !== null ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_traffic_light, {
    color: _ctx.trafficLightColor
  }, null, 8 /* PROPS */, ["color"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_3, [!$props.disablePreview ? (_openBlock(), _createBlock(_component_preview_modal_link, {
    key: 0,
    ref: "preview",
    activity: $props.activity,
    onOnCompleteControl: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('onCompleteControl', $event);
    }),
    onUpdated: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('updateFromPreviewMode', $event);
    }),
    onDeleteItem: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('deleteItem', $props.activity);
    }),
    onReloadRootPage: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('reloadRootPage');
    }),
    onDismissModal: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('onDismissModal');
    })
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_4, [$options.isAction && $props.activity.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "check",
        class: "mr-2 stroke-2"
      })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title_with_icon, {
        class: "font-semibold hover:underline",
        icon: "confidential",
        type: "medium",
        title: $options.title,
        "title-class": $options.activityTitleLineThrough ? 'line-through' : '',
        "title-attribute": $options.hoverTitle,
        "display-icon": $props.activity.confidential
      }, null, 8 /* PROPS */, ["title", "title-class", "title-attribute", "display-icon"]), $options.isControl && $props.activity.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 1,
        icon: "check",
        class: "ml-2 stroke-2"
      })) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"])) : $props.internalLinkEnabled ? (_openBlock(), _createElementBlock("a", {
    key: 1,
    href: $options.activityUrl,
    class: "flex items-center"
  }, [$options.isAction && $props.activity.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "check",
    class: "mr-2 stroke-2"
  })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title_with_icon, {
    icon: "confidential",
    type: "medium",
    title: $options.title,
    "title-class": $options.activityTitleLineThrough ? 'line-through' : '',
    "title-attribute": $options.hoverTitle,
    "display-icon": !!$props.activity.confidential
  }, null, 8 /* PROPS */, ["title", "title-class", "title-attribute", "display-icon"])], 8 /* PROPS */, _hoisted_5)) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "deleteButton", {
    activity: $props.activity
  }, undefined, true)])], 2 /* CLASS */), _createElementVNode("div", _hoisted_6, [$options.assetType ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    title: $options.assetType,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), $props.activity.objType === $data.objectTypes.RISK_RISK || $props.activity.objType === $data.objectTypes.RISK_RISK_LIMITED ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 1,
    title: $options.projectTitle,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), $options.reportType ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $options.reportType,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), $props.activity.comment ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    title: $props.activity.comment,
    truncate: false,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $props.activity.request && $props.activity.request.message ? (_openBlock(), _createElementBlock("div", {
    key: 3,
    class: "description-field",
    innerHTML: _ctx.$truncate($props.activity.request.message, 100)
  }, null, 8 /* PROPS */, _hoisted_8)) : _createCommentVNode("v-if", true), $props.activity.description && $options.showOptionalField('description') ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $data.translate.description + ': ',
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
    class: "description-field",
    innerHTML: $options.descriptionTruncated
  }, null, 8 /* PROPS */, _hoisted_10)])) : _createCommentVNode("v-if", true), $options.status && $options.showOptionalField('status') ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_risma_title, {
    title: $data.translate.status + ': ',
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
    class: "description-field",
    innerHTML: $options.statusTruncated
  }, null, 8 /* PROPS */, _hoisted_12)])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_13, [_ctx.showDeadline ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString("".concat($data.translate.deadline, ": ").concat(_ctx.deadlineFormat)), 1 /* TEXT */)) : $options.isIncident ? (_openBlock(), _createElementBlock("div", _hoisted_15, [(_$props$activity$acco = $props.activity.accountableUserIds) !== null && _$props$activity$acco !== void 0 && _$props$activity$acco.length ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    title: $options.accountableWithLabel,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $options.createdWithLabel,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $options.occurredWithLabel,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), $options.archivedWithLabel ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 1,
    title: $options.archivedWithLabel,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true)])) : $options.isContract ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(_component_risma_title, {
    title: $options.expirationDateWithLabel,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])])) : $props.activity.values && $props.activity.values.questions_count > 0 ? (_openBlock(), _createBlock(_component_risma_progress_bar, {
    key: 3,
    value: $props.activity.values.percentage_questions_answered,
    label: $props.activity.values.questions_answered + ' / ' + $props.activity.values.questions_count + ' ' + $data.translate.questionsAnswered
  }, null, 8 /* PROPS */, ["value", "label"])) : !!($props.activity.project && $props.activity.project.riskApproval) || $props.isApprovalFlowEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createVNode(_component_risma_title, {
    title: "".concat($props.activity.approved ? $data.translate.approved : $data.translate.notApproved),
    class: _normalizeClass({
      'text-red-50': !$props.activity.approved
    }),
    type: "medium"
  }, null, 8 /* PROPS */, ["title", "class"])])) : _createCommentVNode("v-if", true), $props.activity.request ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.dateSent, ": ").concat($props.activity.request.created),
    type: "small"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_19, [_renderSlot(_ctx.$slots, "button", {
    activity: $props.activity
  }, undefined, true)]), _createElementVNode("div", _hoisted_20, [_renderSlot(_ctx.$slots, "footer", {
    activity: $props.activity
  }, undefined, true)])])])], 4 /* STYLE */);
}