import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-947f395a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "previewlist"
};
var _hoisted_2 = {
  class: "previewlist-body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.title ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    style: _normalizeStyle({
      padding: "0 ".concat($props.headerOffset)
    }),
    class: "previewlist-header flex items-center"
  }, [_createElementVNode("div", {
    class: _normalizeClass(["previewlist-title", {
      'truncate': $props.contentOverflowing
    }])
  }, [_createVNode(_component_risma_title, {
    title: $options.fullTitle
  }, null, 8 /* PROPS */, ["title"])], 2 /* CLASS */), $props.hideable ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "previewlist-showstate",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.changeShowState(null);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-down",
    width: "30",
    height: "30",
    class: _normalizeClass(['transform duration-500 text-gray-610 stroke-2', {
      'rotate-180': $data.itemsVisible
    }])
  }, null, 8 /* PROPS */, ["class"]), _createElementVNode("span", null, _toDisplayString($options.showStateTitle), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "nav-buttons", {}, undefined, true)], 4 /* STYLE */)) : _createCommentVNode("v-if", true), _createVNode(_Transition, {
    name: "fade"
  }, {
    default: _withCtx(function () {
      return [_withDirectives(_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 512 /* NEED_PATCH */), [[_vShow, $data.itemsVisible]])];
    }),
    _: 3 /* FORWARDED */
  })]);
}