import { useSolutionsStore } from '@/Store/solutionsStore';
import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
import cloneDeep from 'lodash/cloneDeep';
export function getSolutions() {
  return handleRequestResponse(Http.get('admin/solutions')).then(function (data) {
    useSolutionsStore().set(cloneDeep(data.list));
    return data;
  });
}
export function createSolution(newSolution) {
  return handleRequestResponse(Http.post('admin/solutions', newSolution)).then(function (data) {
    useSolutionsStore().addSolution(data);
    return data;
  });
}
export function updateSolution(solution) {
  return handleRequestResponse(Http.patch("admin/solutions/".concat(solution.id), solution)).then(function (data) {
    useSolutionsStore().updateSolution(data);
    return data;
  });
}
export function deleteSolution(solutionId) {
  return handleRequestResponse(Http.delete("admin/solutions/".concat(solutionId))).then(function () {
    useSolutionsStore().deleteSolution(solutionId);
  });
}
export function runAutoDetectSolutionActivities(solutionName) {
  return handleRequestResponse(Http.post('rismaAdmin/autodetectsolutionactivities', {
    'solutionName': solutionName
  }));
}
export function runCreateDashboardAndKpisService(solutionName) {
  return handleRequestResponse(Http.post('rismaAdmin/createdashboardandkpis', {
    'solutionName': solutionName
  }));
}