import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_delegation_well = _resolveComponent("delegation-well");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_activity_type_selector = _resolveComponent("activity-type-selector");
  var _component_solution_id_relation_details = _resolveComponent("solution-id-relation-details");
  var _component_dep_well = _resolveComponent("dep-well");
  return _openBlock(), _createElementBlock("div", null, [$data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: $data.notificationType,
    onDismiss: $options.handleDismissNotification
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.notificationMessage), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type", "onDismiss"])) : _createCommentVNode("v-if", true), $options.missingRequiredFields.length ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: "error",
    closeable: true
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.formHasRequiredFields), 1 /* TEXT */), _createElementVNode("div", null, _toDisplayString($data.translate.followingFieldsAreMissingValues), 1 /* TEXT */), _createElementVNode("div", null, _toDisplayString($options.missingRequiredFields.join(', ')), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $options.isUserLocked ? (_openBlock(), _createBlock(_component_notification, {
    key: 2,
    closeable: false,
    type: "info"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.thisActivityIsReadonlyAsYourUserIsConfigu), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_dep_well, {
    "is-loaded": $data.isLoaded,
    item: $options.enhancementContract,
    "dep-header-props": $options.depHeaderProps,
    "read-only": $options.isReadOnly,
    "local-storage-key": "relations-contract",
    onUpdatingAttachments: $options.updatingAttachments,
    onFileUpdate: $options.onFileUpdate,
    onPropertyChanged: $options.contractPropertyChanged,
    onSaveStatusClicked: $options.saveStatusClicked,
    onValidationError: _cache[0] || (_cache[0] = function ($event) {
      return $options.handleShowNotification('error', $event);
    }),
    onGetActivityData: $options.getActivityData,
    onDeleteContract: $options.deleteContract,
    onChangeTab: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('changeTab', $event);
    })
  }, {
    delegation: _withCtx(function () {
      return [$data.contract ? (_openBlock(), _createBlock(_component_delegation_well, {
        key: 0,
        "model-value": $options.delegationData,
        "read-only": $options.isReadOnly,
        organisations: _ctx.organisations,
        "current-activity-type": $options.currentActivityType,
        onPropertyChanged: $options.saveChangesImmediately,
        onAddNewItem: _ctx.updateDelegationList
      }, null, 8 /* PROPS */, ["model-value", "read-only", "organisations", "current-activity-type", "onPropertyChanged", "onAddNewItem"])) : _createCommentVNode("v-if", true)];
    }),
    details: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        type: "medium",
        title: $data.translate.activityType
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_activity_type_selector, {
        "activity-types": _ctx.activityTypes,
        "model-value": $data.contract.activityTypeId,
        disabled: $options.isReadOnly,
        "can-be-reset": false,
        "onUpdate:modelValue": $options.activityTypeChanged
      }, null, 8 /* PROPS */, ["activity-types", "model-value", "disabled", "onUpdate:modelValue"]), _createVNode(_component_solution_id_relation_details, {
        class: "mt-5",
        list: $data.contract.solutionIds,
        "read-only": $options.isReadOnly,
        onPropertyChanged: $options.contractPropertyChanged
      }, null, 8 /* PROPS */, ["list", "read-only", "onPropertyChanged"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-loaded", "item", "dep-header-props", "read-only", "onUpdatingAttachments", "onFileUpdate", "onPropertyChanged", "onSaveStatusClicked", "onGetActivityData", "onDeleteContract"])]);
}