import { Colors } from '@/Colors';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'ListOptions',
  introduction: 'buttons!',
  description: 'Wrapper for few fieldsets with toggle view and event of reset of options',
  token: '<list-options cookie-name="uniqString" @reset="fn">{slot}</list-options>',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    isExpanded: {
      type: Boolean,
      required: true
    }
  },
  emits: ['reset', 'toggle'],
  data: function data() {
    return {
      iconColor: Colors.system.primaryDark,
      show: true,
      translate: {
        resetOptions: this.$trans('Reset options')
      }
    };
  },
  methods: {
    resetOptions: function resetOptions() {
      this.$emit('reset');
    }
  }
};