import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
export default {
  name: 'ConfidentialBox',
  components: {
    RismaTitle: RismaTitle,
    RismaToggle: RismaToggle
  },
  props: {
    confidential: {
      type: Boolean,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    confidentialityNames: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['changed'],
  data: function data() {
    return {
      translate: {
        confidentialActivity: this.$trans('Confidential activity'),
        only: this.$trans('Only'),
        canSeeThisActivity: this.$trans('can see this activity')
      }
    };
  },
  computed: {
    confidentialNote: function confidentialNote() {
      return "".concat(this.translate.only, " <b>").concat(this.confidentialityNames, "</b> ").concat(this.translate.canSeeThisActivity);
    }
  }
};