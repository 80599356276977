function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import * as RiskAPI from '@/api/risk/risk';
import * as Utils from '@/utils/Utils';
import * as RisksUtils from '@/components/Pages/Risks/RisksUtils';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import { RiskProjects } from '@/constants/risks/RiskProjects';
export var RiskListMixin = {
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Id of the Risk Project'
    }
  },
  data: function data() {
    return {
      riskProject: {},
      period: null,
      risks: [],
      risksBackup: [],
      threats: [],
      levelOfThreats: [],
      vulnerabilities: [],
      probabilities: [],
      tagSelected: null
    };
  },
  computed: {
    periodId: function periodId() {
      return this.period ? this.period.id : null;
    },
    periodTitle: function periodTitle() {
      return this.period ? this.period.title : '';
    }
  },
  methods: {
    filterByTags: function filterByTags(value) {
      var result = [];
      this.risks = _toConsumableArray(this.risksBackup);
      this.tagSelected = value;
      if (value === null) {
        return;
      }
      this.risks.map(function (risk) {
        if (risk.tags) {
          if (risk.tags.find(function (tag) {
            return tag.id === value;
          })) {
            result.push(risk);
          }
        }
      });
      this.risks = result;
    },
    prepareLinkedEntities: function prepareLinkedEntities(ids, data) {
      return data.filter(function (item) {
        return ids.find(function (id) {
          return id === item.id;
        });
      });
    },
    modifyConsequenceRowScores: function modifyConsequenceRowScores(rows, period) {
      var _this = this;
      var result = [];
      if (rows && rows.length) {
        rows.map(function (row) {
          result.push(_objectSpread({
            _valueAsString: _this.period ? Utils.findLabelValue(row, _this.period.consequenceRows) : Utils.findLabelValue(row, period.consequenceRows)
          }, row));
        });
      }
      return result;
    },
    // Period sends not all the time, please note this when update parameters for this function
    formatRisks: function formatRisks(risks, threats, levelOfThreats, vulnerabilities, probabilities, controls, initiatives, period) {
      var _this2 = this;
      return risks.map(function (risk) {
        return _this2.formatRisk(risk, threats, levelOfThreats, vulnerabilities, probabilities, controls, initiatives, period);
      });
    },
    formatRisk: function formatRisk(risk, threats, levelOfThreats, vulnerabilities, probabilities, controls, initiatives, period) {
      var _linkedControls = risk.links && risk.links.control ? this.prepareLinkedEntities(risk.links.control, controls) : [];
      var _linkedInitiatives = risk.links && risk.links.execution ? this.prepareLinkedEntities(risk.links.execution, initiatives) : [];
      var _linkedActivitiesLength = _linkedControls.length + _linkedInitiatives.length;
      if (risk.userIds) {
        for (var _i = 0, _Object$entries = Object.entries(risk.userIds); _i < _Object$entries.length; _i++) {
          var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            key = _Object$entries$_i[0],
            value = _Object$entries$_i[1];
          if (!risk["".concat(key, "UserIds")]) {
            risk["".concat(key, "UserIds")] = risk.userIds[key] ? [+value] : [];
          }
        }
      }
      return _objectSpread(_objectSpread({
        _expanded: false,
        _threatValueAsString: Utils.getThreatsLabelWithCategories(risk[RiskBaseModelProps.THREAT_IDS], threats),
        _threatLevelValueAsString: Utils.findValueById(levelOfThreats, risk[RiskBaseModelProps.THREAT_LEVEL_VALUE], 'label'),
        _vulnerabilityValueAsString: Utils.findPropsByIds(risk[RiskBaseModelProps.VULNERABILITY_IDS], vulnerabilities, 'label'),
        _probabilityValueAsString: Utils.findValueById(probabilities, risk[RiskBaseModelProps.PROBABILITY_SCORE], 'label'),
        _linkedControls: _linkedControls,
        _linkedInitiatives: _linkedInitiatives,
        _linkedActivitiesLength: _linkedActivitiesLength
      }, risk), {}, {
        residualConsequenceRowScores: this.modifyConsequenceRowScores(risk.residualConsequenceRowScores, period)
      });
    },
    getRiskProjects: function getRiskProjects() {
      var _this3 = this;
      return new Promise(function (resolve, reject) {
        RiskAPI.getRiskProjects().then(function (_ref) {
          var list = _ref.list;
          _this3.riskProject = list.find(function (project) {
            return +project.id === +_this3.projectId;
          });
          if (_this3.riskProject && _this3.riskProject.type !== RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type) {
            _this3.period = _this3.riskProject.projectPeriods.find(function (period) {
              return period.isCurrent;
            });
            _this3.threats = RisksUtils.formatThreats(_this3.riskProject.threats);
            _this3.levelOfThreats = RisksUtils.formatThreatLevels(_this3.period.threatLevels);
            _this3.vulnerabilities = RisksUtils.formatVulnerabilities(_this3.riskProject.vulnerabilities);
            _this3.probabilities = RisksUtils.formatProbabilities(_this3.period.probabilityRows);
          }
          resolve();
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    getRisks: function getRisks(projectId, periodId, filter) {
      return new Promise(function (resolve, reject) {
        RiskAPI.getRisks(projectId, periodId, filter).then(function (_ref2) {
          var list = _ref2.list;
          return resolve(list);
        }).catch(function (error) {
          return reject(error);
        });
      });
    }
  }
};