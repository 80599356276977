function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
var defaultErrorMessage = 'Can not perform this operation. Please, retry again.';
export var AttachmentModalMixin = {
  data: function data() {
    return {
      defaultErrorMessage: defaultErrorMessage,
      attachFileError: false,
      attachFileErrorMessage: ''
    };
  },
  computed: _objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })),
  methods: {
    handleResponseError: function handleResponseError(response) {
      var _response$data;
      var isError = response.status && response.status === 400;
      var error = (_response$data = response.data) === null || _response$data === void 0 ? void 0 : _response$data.error;
      if (isError && error) {
        return "".concat(error, " ").concat(Object.keys(this.getSettingValue('risma.attachments_allowed')).join(', '));
      }
      return this.$trans('An error occurred');
    },
    resetAttachFileError: function resetAttachFileError() {
      this.attachFileErrorMessage = '';
    }
  }
};