function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import isEqual from 'lodash/isEqual';
export function removeDuplicates(array) {
  var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';
  var leaveLastMatch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  if (!array.length) {
    return [];
  }
  if (!leaveLastMatch) {
    var seen = new Set();
    return array.filter(function (item) {
      return seen.has(item[field]) ? false : seen.add(item[field]);
    });
  }
  return _toConsumableArray(new Map(array.map(function (item) {
    return [item[field], item];
  })).values());
}
export function areArraysEqual(arr1, arr2) {
  var arr1Length = arr1.length;
  var arr2Length = arr2.length;
  if (arr1Length !== arr2Length) {
    return false;
  }
  return isEqual(arr1, arr2);
}