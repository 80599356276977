import i18n from '@/i18n';
export var getTranslate = {
  Consequence: function Consequence() {
    return {
      addRow: i18n.t('Add row'),
      addColumn: i18n.t('Add column'),
      na: i18n.t('N/A'),
      deleteRow: i18n.t('Delete row'),
      areYouSureYouWantToDeleteThisRow: i18n.t('Are you sure you want to delete this row?')
    };
  },
  CustomAxisTable: function CustomAxisTable() {
    return {
      result: i18n.t('Result'),
      calculationSetup: i18n.t('Calculation setup'),
      averageValue: i18n.t('Average value'),
      worstcaseValue: i18n.t('Worst-case value'),
      na: i18n.t('N/A'),
      weight: i18n.t('Weight')
    };
  },
  LevelOfThreat: function LevelOfThreat() {
    return {
      dropdownWithValues: i18n.t('Dropdown with values'),
      title: i18n.t('Title'),
      fieldType: i18n.t('Field type'),
      chooseAFieldType: i18n.t('Choose a field type'),
      dropdownOptions: i18n.t('Dropdown options'),
      enterDropdownOption: i18n.t('Enter dropdown option'),
      addOption: i18n.t('Add option'),
      cancel: i18n.t('Cancel'),
      save: i18n.t('Save'),
      editField: i18n.t('Edit field'),
      view: i18n.t('View'),
      edit: i18n.t('Edit'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      enterAnOption: i18n.t('Enter an option'),
      deleteLevelOfThreat: i18n.t('Delete') + ' ' + i18n.t('Level of threat').toLowerCase(),
      areYouSureYouWantToDeleteThisLevelOfThreat: "".concat(i18n.t('Are you sure you want to delete this'), " ").concat(i18n.t('Level of threat').toLowerCase(), "?")
    };
  },
  NetGross: function NetGross() {
    return {
      riskProduct: i18n.t('Risk product'),
      grossValues: i18n.t('Gross values'),
      choose: i18n.t('Choose'),
      toleranceValues: i18n.t('Tolerance values'),
      toleranceGap: i18n.t('Tolerance gap'),
      tolerance: i18n.t('Tolerance'),
      riskExposure: i18n.t('Risk exposure'),
      riskGap: i18n.t('Risk gap'),
      impact: i18n.t('Impact'),
      likelihood: i18n.t('Likelihood'),
      notSet: i18n.t('Not set')
    };
  },
  PreviewRiskList: function PreviewRiskList() {
    return {
      display: i18n.t('Display'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      organisation: i18n.t('Organisation'),
      tags: i18n.t('Tags'),
      approvalStatus: i18n.t('Approval status'),
      thereAreNoRisksToDisplay: i18n.t('There are no risks to display')
    };
  },
  RiskAssessmentRow: function RiskAssessmentRow() {
    return {
      select: i18n.t('Select'),
      threat: i18n.t('Threat'),
      edit: i18n.t('Edit'),
      discardThreat: i18n.t('Discard threat'),
      restoreThreat: i18n.t('Restore threat'),
      inherentRisk: i18n.t('Inherent risk'),
      residualRisk: i18n.t('Residual risk'),
      maxValueFromUnderlyingSystems: i18n.t('Max value from underlying systems'),
      valueIsFromTheParentNode: i18n.t('Value is from the parent node'),
      riskScore: i18n.t('Risk score'),
      inherentScore: i18n.t('Inherent score'),
      residualScore: i18n.t('Residual score')
    };
  },
  RiskErmAssesment: function RiskErmAssesment() {
    return {
      likelihood: i18n.t('Likelihood'),
      selectProbability: i18n.t('Select probability'),
      consequence: i18n.t('Consequence'),
      selectImpact: i18n.t('Select impact'),
      choose: i18n.t('Choose')
    };
  },
  RiskFilters: function RiskFilters() {
    return {
      mainFilters: i18n.t('Main filters'),
      organisations: i18n.t('Organisations'),
      parentRisk: i18n.t('Parent risk'),
      noFiltersApplied: i18n.t('No filters applied'),
      tags: i18n.t('Tags'),
      search: i18n.t('Search'),
      searchForNoOrTitle: i18n.t('Search for No or Title')
    };
  },
  RiskGroupSortBy: function RiskGroupSortBy() {
    return {
      groupAndSort: i18n.t('Group and sort'),
      groupBy: i18n.t('Group by'),
      notSet: i18n.t('Not set'),
      sortBy: i18n.t('Sort by'),
      riskNumber: i18n.t('Risk number'),
      tags: i18n.t('Tags'),
      responsible: i18n.t('Responsible'),
      accountable: i18n.t('Accountable'),
      parentRisk: i18n.t('Parent risk'),
      organisations: i18n.t('Organisations'),
      threats: i18n.t('Threats'),
      vulnerabilities: i18n.t('Vulnerabilities')
    };
  },
  RiskProbabilityAssessment: function RiskProbabilityAssessment() {
    return {
      riskParameters: i18n.t('Risk parameters'),
      threat: i18n.t('Threat'),
      selectThreat: i18n.t('Select threat'),
      vulnerability: i18n.t('Vulnerability'),
      selectVulnerability: i18n.t('Select vulnerability'),
      select: i18n.t('Select')
    };
  },
  ScoringAxisEdit: function ScoringAxisEdit() {
    return {
      useZaxis: i18n.t('Use Z-axis'),
      axisTitle: i18n.t('Axis title'),
      numberOfLevels: i18n.t('Number of levels'),
      levels: i18n.t('levels')
    };
  },
  ScoringAxisEditNew: function ScoringAxisEditNew() {
    return {
      useZaxis: i18n.t('Use Z-axis'),
      axisTitle: i18n.t('Axis title'),
      numberOfLevels: i18n.t('Number of levels'),
      levels: i18n.t('levels')
    };
  }
};