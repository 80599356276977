import Alert from '@/components/Molecules/Modal/Alert';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
export default {
  name: '',
  components: {
    Alert: Alert,
    RismaButton: RismaButton
  },
  data: function data() {
    return {
      isAlertShow: false
    };
  },
  methods: {
    toggleAlert: function toggleAlert() {
      this.isAlertShow = !this.isAlertShow;
    }
  }
};