export default {
  name: 'CompactTabItem',
  introduction: 'this item is used as more compact tabs - to show them all on the page',
  description: 'CompactTabs is wrapper for Atoms/TabItems component which gets compact-tab-item components as slots',
  token: '<compact-tab-item link="link"></compact-tab-item>',
  props: {
    link: {
      type: [String, Object],
      required: true,
      note: 'Link to relocate'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is tab disabled'
    }
  }
};