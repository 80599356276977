function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { getReportsVulnerabilities } from '@/api/risk/risk';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { RiskReportsMixin } from '@/mixins/RiskReportsMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { ReportTypes } from '@/constants/reports';
export default {
  name: 'RiskReportsVulnerabilities',
  description: 'Vulnerabilities tab for risk2 projects',
  token: '<risk-reports-vulnerability />',
  components: {
    RismaDatatable: RismaDatatable,
    PreviewModalLink: PreviewModalLink,
    SaveReport: SaveReport
  },
  mixins: [RiskReportsMixin, RismaDatatableDefaultMixin, SaveReportMixin],
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Id of the Risk Project'
    }
  },
  data: function data() {
    return {
      vulnerabilities: [],
      datasetBig: [],
      translate: getTranslate['RiskReportsVulnerabilities'](),
      reportType: ReportTypes.RISK_VULNERABILITIES,
      stateLocal: "".concat(ReportTypes.RISK_VULNERABILITIES, "-").concat(this.projectId),
      activitySlotNames: ['risks', 'initiatives', 'controls', 'systems']
    };
  },
  computed: {
    columnsBig: function columnsBig() {
      return _objectSpread(_objectSpread({
        vulnerability: this.translate.vulnerability,
        risks: this.translate.risks
      }, this.systemColumn), {}, {
        initiatives: this.translate.initiatives,
        controls: this.translate.controls
      });
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.handleGetVulnerabilities(this.projectId).then(function (response) {
      _this.vulnerabilities = response.list;
      _this.risks = response.risks || [];
      return _this.getLinkedAssetsAndActivities();
    }).then(function (response) {
      _this.handleGetLinkedAssetsAndActivities(response);
      _this.datasetBig = _this.formatDatasetBig(_this.vulnerabilities, _this.risks, _this.controls, _this.initiatives, _this.systems);
      _this.enhanceDataset(_this.datasetBig, _this.columnsBig);
      _this.isLoaded = true;
      _this.loadSavedReport();
    }).catch(function (error) {
      throw new Error(error);
    });
  },
  methods: {
    handleGetVulnerabilities: function handleGetVulnerabilities(projectId) {
      return new Promise(function (resolve, reject) {
        getReportsVulnerabilities(projectId).then(function (responce) {
          return resolve(responce);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    formatDatasetBig: function formatDatasetBig(vulnerabilities, risksAll, controlsAll, initiativesAll, systemsAll) {
      var _this2 = this;
      return vulnerabilities.map(function (vulnerability) {
        var risks = risksAll.filter(function (risk) {
          return risk.vulnerabilityIds.includes(vulnerability.id);
        });
        var risksFormatted = _this2.getFormattedRisks(risks);
        var controlsFormatted = _this2.getFormattedControls(risks, controlsAll);
        var initiativesFormatted = _this2.getFormattedInitiatives(risks, initiativesAll);
        return _objectSpread(_objectSpread({
          vulnerability: {
            field: vulnerability.title
          },
          risks: risksFormatted
        }, _this2.getSystemsCell(risks, systemsAll)), {}, {
          initiatives: initiativesFormatted,
          controls: controlsFormatted
        });
      });
    }
  }
};