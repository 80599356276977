import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "-mt-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'bg-gray-450': $options.riskIndicatorClassName === 'grey'
    }, {
      'bg-green-150': $options.riskIndicatorClassName === 'green'
    }, {
      'bg-green-50': $options.riskIndicatorClassName === 'blue'
    }, {
      'bg-yellow-50': $options.riskIndicatorClassName === 'yellow'
    }, {
      'bg-red-50': $options.riskIndicatorClassName === 'red'
    }])
  }, [_createElementVNode("span", _hoisted_1, _toDisplayString($props.riskLevel), 1 /* TEXT */)], 2 /* CLASS */);
}