function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getAll as getIncidents, getArchived } from '@/api/incident/incident';
import { getControls, getInitiatives, getRisks, getAllTrees, getNodesByProcessTreeId, getInformationAssetsSystems, getNodesFlat, getInformationAssetsProcessors, getInformationAssetsControllers } from '@/api/risma/data';
import { getRiskProjects } from '@/api/risk/risk';
import { getAllCustomInformationAssets } from '@/api/compliance/informationAssets';
import { getContracts } from '@/api/contracts/contracts';
import { getCompanies } from '@/api/admin/companies';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { mapToCommaListWithLinks } from '@/utils/format/mapTo';
import { getUsersFromIds, getOrganisationsFromIds } from '@/utils/format/objectsFromIds';
import * as Utils from '@/utils/Utils';
import { capitalize as _capitalize } from '@/utils';
import * as formatDate from '@/utils/date';
import { getRangesWithNextThreeMonths } from '@/constants/dates/daterangepicker';
import { INCIDENT_STATES } from '@/constants/IncidentStateCodes';
import { SortDirection } from '@/constants/SortDirection';
import { Colors } from '@/Colors';
import { ReportTypes } from '@/constants/reports';
export default {
  name: 'IncidentReport',
  components: {
    PreviewModalLink: PreviewModalLink,
    RismaTitle: RismaTitle,
    RismaDatatable: RismaDatatable,
    Trafficlight: Trafficlight,
    RismaDateRangePicker: RismaDateRangePicker,
    RismaTitleWithIcon: RismaTitleWithIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    SaveReport: SaveReport
  },
  introduction: ' ',
  description: ' ',
  token: '<incident-report></incident-report>',
  mixins: [RismaDatatableDefaultMixin, RaciTitleMixin, ProcessLibraryTitleMixin, SaveReportMixin],
  data: function data() {
    return {
      incidents: null,
      updatedIncident: null,
      decoupleFilterFields: [{
        key: 'responsible'
      }, {
        key: 'accountable'
      }, {
        key: 'consulted'
      }, {
        key: 'informed'
      }, {
        key: 'organisations'
      }, {
        key: 'controllers'
      }, {
        key: 'processors'
      }, {
        key: 'systems'
      }, {
        key: 'initiatives'
      }, {
        key: 'controls'
      }, {
        key: 'risks'
      }, {
        key: 'processes'
      }, {
        key: 'customIA'
      }, {
        key: 'complianceNodes'
      }, {
        key: 'contracts'
      }, {
        key: 'companies'
      }],
      disabledFilterByColumns: ['title', 'description', 'created', 'incidentOccurred', 'attachments'],
      isLoaded: false,
      customFieldshashTabByIncidentId: {},
      customFieldsDefault: {},
      dataset: [],
      constrols: [],
      initiatives: [],
      risks: [],
      nodes: [],
      controllers: [],
      processors: [],
      systems: [],
      complianceNodes: [],
      customIA: [],
      contracts: [],
      companies: [],
      riskProjects: [],
      columnsMeta: [{
        targets: ['title', 'organisations', 'attachments'],
        width: '250px'
      }, {
        targets: ['description'],
        width: '500px'
      }, {
        targets: ['initiatives', 'controls', 'risks', 'controllers', 'processors', 'systems', 'customIA', 'processes', 'complianceNodes', 'contracts', 'companies'],
        width: '220px'
      }, {
        targets: ['created', 'incidentOccurred'],
        width: '180px'
      }, {
        targets: ['id'],
        width: '50px'
      }],
      slotLinkFields: ['initiatives', 'controls', 'risks', 'processes', 'processors', 'controllers', 'systems', 'complianceNodes', 'contracts', 'companies', 'customIA'],
      localStorageRangeKey: 'incident-report-dates-storage-key',
      ranges: getRangesWithNextThreeMonths(),
      initRange: {
        start: '',
        end: ''
      },
      customFieldsColumns: {},
      SortDirection: SortDirection,
      reportType: ReportTypes.INCIDENTS,
      stateLocal: 'incident_report_table_state',
      translate: getTranslate['IncidentReport']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useActivityTypesStore, {
    activities: 'activityTypes'
  })), {}, {
    filteredIncidents: function filteredIncidents() {
      var _this = this;
      if (!this.incidents) return [];
      if (!this.initRange.start || !this.initRange.end) return this.incidents;
      return this.incidents.filter(function (incident) {
        return formatDate.isDateAfter(incident.incidentHappenedAt, _this.initRange.start) && formatDate.isDateBefore(incident.incidentHappenedAt, _this.initRange.end);
      });
    },
    showIdFeatureEnabled: function showIdFeatureEnabled() {
      return this.getSettingValue('feature.show_incident_id');
    },
    columns: function columns() {
      var columns = {
        activity: this.translate.activityType,
        severity: this.translate.severity
      };
      if (this.showIdFeatureEnabled) {
        columns = _objectSpread(_objectSpread({}, columns), {}, {
          id: this.translate.id
        });
      }
      columns = _objectSpread(_objectSpread({}, columns), {}, {
        title: this.translate.title,
        archived: this.translate.archived,
        description: this.translate.description,
        status: this.translate.status,
        confidential: this.translate.confidential,
        created: this.translate.created,
        incidentOccurred: this.translate.incidentOccurred,
        responsible: this.responsibleTitle(true),
        accountable: this.accountableTitle(true),
        consulted: this.consultedTitle(true),
        informed: this.informedTitle(true),
        organisations: this.translate.organisations,
        initiatives: this.translate.linkedActions,
        controls: this.translate.linkedControls,
        risks: this.translate.linkedRisks,
        processes: "".concat(this.translate.linked, " ").concat(this.processLibraryTitle),
        controllers: this.translate.linkedDataControllers,
        processors: this.translate.linkedProcessors,
        systems: this.translate.linkedSystems,
        customIA: this.translate.linkedInformationAssets,
        complianceNodes: this.translate.linkedComplianceNodes,
        contracts: this.translate.linkedContracts,
        companies: this.translate.linkedCompanies,
        attachments: this.translate.attachments
      }, this.customFieldsColumns);
      return columns;
    }
  }),
  mounted: function mounted() {
    this.setInitRange();
    this.getData();
  },
  methods: {
    capitalize: function capitalize(value) {
      return _capitalize(value);
    },
    setInitRange: function setInitRange() {
      var storedRange = localStorage.getItem(this.localStorageRangeKey);
      if (storedRange) {
        var rangeValues = storedRange.split('+');
        var after = rangeValues[0];
        var before = rangeValues[1];
        if (formatDate.isDateValid(after) && formatDate.isDateValid(before)) {
          this.initRange.start = after;
          this.initRange.end = before;
        }
      }
    },
    activitiesToCommaList: function activitiesToCommaList(arr) {
      return arr && arr.length > 0 ? arr.map(function (item) {
        var path = item.path ? item.path + ' ' : '';
        return path + item.title;
      }).join(', ') : '';
    },
    filterByLabel: function filterByLabel(value, columnKey) {
      if (columnKey === 'severity') {
        return this.$trans(_capitalize(Colors.findIncidentSeverity(value, true).label));
      }
      return this.prepareFilterForTrafficLight(value, columnKey);
    },
    getData: function getData() {
      var _this2 = this;
      return Promise.all([getIncidents(), getArchived(), getControls(), getInitiatives(), getRisks('?data[]=scores'), getRiskProjects(), getInformationAssetsControllers(), getInformationAssetsProcessors(), getInformationAssetsSystems(), getNodesFlat(), getAllCustomInformationAssets(), getContracts(), getCompanies(), this.getNodeElementAllData()]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 13),
          incidents = _ref2[0],
          archivedIncidents = _ref2[1],
          controls = _ref2[2],
          initiatives = _ref2[3],
          risks = _ref2[4],
          riskProjects = _ref2[5],
          controllers = _ref2[6],
          processors = _ref2[7],
          systems = _ref2[8],
          complianceNodes = _ref2[9],
          customIA = _ref2[10],
          contracts = _ref2[11],
          companies = _ref2[12];
        _this2.incidents = [].concat(_toConsumableArray(incidents), _toConsumableArray(archivedIncidents));
        _this2.controls = controls.list;
        _this2.initiatives = initiatives.list;
        _this2.risks = risks.list;
        _this2.riskProjects = riskProjects.list;
        _this2.controllers = controllers.list;
        _this2.processors = processors.list;
        _this2.systems = systems.list;
        _this2.complianceNodes = complianceNodes.list;
        _this2.customIA = customIA.list;
        _this2.contracts = contracts.list;
        _this2.companies = companies.list;
        _this2.prepareCustomFieldOptions();
        _this2.$nextTick(function () {
          _this2.prepareTableData();
          _this2.isLoaded = true;
          _this2.loadSavedReport();
        });
      });
    },
    prepareCustomFieldOptions: function prepareCustomFieldOptions() {
      var _this$getPropertiesWi = this.getPropertiesWithCustomFields(this.incidents, this.activities),
        extendedColumns = _this$getPropertiesWi.extendedColumns,
        defaultCustomFields = _this$getPropertiesWi.defaultCustomFields,
        customFieldsValuesByEntity = _this$getPropertiesWi.customFieldsValuesByEntity,
        columnFilterOptions = _this$getPropertiesWi.columnFilterOptions;
      this.customFieldsColumns = extendedColumns;
      this.customFieldsDefault = defaultCustomFields;
      this.customFieldshashTabByIncidentId = customFieldsValuesByEntity;
      this.enhanceColumnFilters(columnFilterOptions, this.disabledFilterByColumns, this.decoupleFilterFields);
    },
    getNodeElementAllData: function getNodeElementAllData() {
      return getAllTrees().then(this.setIdToProccessTreeIdAndGetNodes);
    },
    setIdToProccessTreeIdAndGetNodes: function setIdToProccessTreeIdAndGetNodes(response) {
      var _this3 = this;
      if (response[0].id !== null) {
        this.processTreeId = response[0].id;
      }
      return getNodesByProcessTreeId(this.processTreeId).then(function (response) {
        var processTreeId = _this3.processTreeId;
        Utils.pushChildrenToParent(response, _this3.nodes);
        _this3.nodes = _this3.nodes.map(function (node) {
          return _objectSpread(_objectSpread({}, node), {}, {
            processTreeId: processTreeId
          });
        });
      });
    },
    prepareTableData: function prepareTableData() {
      var _this4 = this;
      this.dataset = this.filteredIncidents.map(function (incident) {
        var _incident$description;
        var activity = _this4.activities.find(function (a) {
          return a.id === incident.activityTypeId;
        });
        var customFields = _objectSpread(_objectSpread({}, _this4.customFieldsDefault), _this4.customFieldshashTabByIncidentId[incident.id]);
        var dataset = {
          activity: {
            field: activity ? activity.label : ''
          },
          severity: incident.severity !== null ? {
            field: DatatableRenderer.prepareSeverityField(incident.severity),
            fieldText: incident.severity
          } : {
            field: {},
            fieldText: ''
          }
        };
        if (_this4.showIdFeatureEnabled) {
          dataset = _objectSpread(_objectSpread({}, dataset), {}, {
            id: {
              field: incident.id
            }
          });
        }
        dataset = _objectSpread(_objectSpread({}, dataset), {}, {
          title: {
            field: incident.title,
            incident: incident,
            fieldText: incident.title,
            hoverTitle: DatatableRenderer.getItemHoverTitle(incident)
          },
          archived: {
            field: _this4.$trans(incident.archived ? 'Yes' : 'No')
          },
          description: {
            field: (_incident$description = incident.description) !== null && _incident$description !== void 0 ? _incident$description : ''
          },
          status: {
            field: _this4.$trans(_this4.getState(incident.rismaState).name)
          },
          confidential: {
            field: _this4.$trans(incident.confidential ? 'Yes' : 'No')
          },
          created: {
            field: formatDate.toLocalDateTime(incident.createdAt)
          },
          incidentOccurred: {
            field: formatDate.toLocalDateTime(incident.incidentHappenedAt) || _this4.translate.notSet
          },
          responsible: {
            field: _this4.getUsers(incident.responsibleUserIds)
          },
          accountable: {
            field: _this4.getUsers(incident.accountableUserIds)
          },
          consulted: {
            field: _this4.getUsers(incident.consultedUserIds)
          },
          informed: {
            field: _this4.getUsers(incident.informedUserIds)
          },
          organisations: {
            field: getOrganisationsFromIds(incident.organisationIds, _this4.organisations).map(function (o) {
              return o.visible_name;
            }).join(', ')
          },
          initiatives: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(incident.links.execution, _this4.initiatives, 'path')),
          controls: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(incident.links.control, _this4.controls, 'path')),
          risks: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(incident.links.risk, _this4.risks, 'rno', _this4.getRiskValues)),
          processes: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(incident.links.compliance_process_tree_node, _this4.nodes)),
          controllers: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(incident.links.compliance_ia_controller, _this4.controllers)),
          processors: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(incident.links.compliance_ia_processor, _this4.processors)),
          systems: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(incident.links.compliance_ia_system, _this4.systems)),
          customIA: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(incident.links.information_assets, _this4.customIA)),
          complianceNodes: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(incident.links.compliance, _this4.complianceNodes)),
          contracts: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(incident.links.contracts, _this4.contracts)),
          companies: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(incident.links.company, _this4.companies, '', null, 'name', function (company) {
            return '/company/' + company.id;
          })),
          attachments: {
            field: mapToCommaListWithLinks(incident.attachments, '', 'filename', 'url', false)
          }
        }, customFields);
        return dataset;
      });
      this.enhanceDataset(this.dataset, this.columns);
    },
    getRiskValues: function getRiskValues(risk) {
      if (this.getSettingValue('feature.show_risk_links_with_levels') !== 1) {
        return '';
      }
      return DatatableRenderer.getRiskValues(risk, this.riskProjects);
    },
    updateFromPreviewMode: function updateFromPreviewMode(item) {
      this.updatedIncident = item;
    },
    onDismissModal: function onDismissModal() {
      var _this5 = this;
      if (!this.updatedIncident) return;
      var index = this.incidents.findIndex(function (incident) {
        return +incident.id === +_this5.updatedIncident.id;
      });
      this.incidents[index] = this.updatedIncident;
      this.updatedIncident = null;
      this.prepareTableData();
    },
    getUsers: function getUsers(ids) {
      return getUsersFromIds(ids, this.users).map(function (u) {
        return u.display_name;
      }).join(', ');
    },
    getState: function getState(state) {
      var stateObject = null;
      Object.keys(INCIDENT_STATES).forEach(function (key) {
        if (INCIDENT_STATES[key].value === state) {
          stateObject = INCIDENT_STATES[key];
        }
      });
      return stateObject;
    },
    changedDates: function changedDates(e) {
      if (e) {
        this.setInitRange();
        this.prepareTableData();
      }
    },
    onDateDiscard: function onDateDiscard() {
      this.initRange.start = '';
      this.initRange.end = '';
      this.prepareTableData();
    }
  }
};