import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tab_item = _resolveComponent("tab-item");
  var _component_tabs = _resolveComponent("tabs");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_tabs, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_tab_item, {
        link: "/settings/reports/login"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.loginReport), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }), _createVNode(_component_tab_item, {
        link: "/settings/reports/users"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.usersChangelog), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }), _createVNode(_component_tab_item, {
        link: "/settings/reports/settings"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.settingsChangelog), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })];
    }),

    _: 1 /* STABLE */
  }), _createVNode(_component_router_view)]);
}