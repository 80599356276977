function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import LoadMoreWrapper from '@/components/Molecules/LoadMoreWrapper';
import ExpandedCard from '@/components/Molecules/Cards/ExpandedCard';
import TileCard from '@/components/Molecules/Cards/TileCard';
import CompactCard from '@/components/Molecules/Cards/CompactCard';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { ControlMyKeys } from '@/constants/ControlMyKeys';
import { viewTypes } from '@/constants/ListViewType';
import { ControlButtonTypes } from '@/constants/ControlButtonTypes';
import { isSignoffPeriodPending as _isSignoffPeriodPending } from '@/utils/controls/utils';
import { MODULES } from '@/constants/modules';
var MODULE_NAME = MODULES.CONTROLS;
export default {
  name: 'ControlsList',
  components: {
    LoadMoreWrapper: LoadMoreWrapper,
    ExpandedCard: ExpandedCard,
    TileCard: TileCard,
    CompactCard: CompactCard,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle
  },
  props: {
    activities: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    filteredOrganisations: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    buttonType: {
      type: String,
      required: false,
      default: null,
      validator: function validator(type) {
        return [ControlButtonTypes.COMPLETE_CONTROL_AGAIN, ControlButtonTypes.REVIEW_CONTROL, ControlButtonTypes.COMPLETE_CONTROL].includes(type);
      }
    },
    additionalFields: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    currentGroup: {
      type: String,
      required: false,
      default: ControlMyKeys.MY_CONTROLS
    },
    viewType: {
      type: String,
      required: false,
      default: viewTypes.TILES,
      validator: function validator(type) {
        return [viewTypes.TILES, viewTypes.EXPANDED, viewTypes.COMPACT].includes(type);
      }
    }
  },
  emits: ['showCompleteModal', 'onDismissModal', 'showReviewModal', 'deleteItem', 'updateFromPreviewMode', 'completeFromPreviewMode', 'reloadRootPage'],
  data: function data() {
    return {
      ControlButtonTypes: ControlButtonTypes,
      viewTypes: viewTypes,
      MODULE_NAME: MODULE_NAME,
      ControlMyKeys: ControlMyKeys,
      translate: getTranslate['ControlsList']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, ['users'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    showCompleteControlButton: function showCompleteControlButton() {
      return this.buttonType === ControlButtonTypes.COMPLETE_CONTROL || this.buttonType === ControlButtonTypes.COMPLETE_CONTROL_AGAIN;
    },
    showReviewControlButton: function showReviewControlButton() {
      return this.buttonType === ControlButtonTypes.REVIEW_CONTROL;
    }
  }),
  methods: {
    isSignoffPeriodPending: function isSignoffPeriodPending(activity) {
      return _isSignoffPeriodPending(activity.signoffStart, activity.frequency, activity.trafficLight, this.getSettingValue);
    },
    onCompleteControl: function onCompleteControl($event) {
      this.$emit('completeFromPreviewMode', $event);
    },
    onUpdateFromPreviewMode: function onUpdateFromPreviewMode($event) {
      this.$emit('updateFromPreviewMode', $event);
    }
  }
};