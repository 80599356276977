function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaOrgSelector from '@/components/Molecules/OrganisationSelector';
import Notification from '@/components/Molecules/Notification';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import * as userAPI from '@/api/admin/user';
import { capitalize } from '@/utils';
import { passwordErrorMessage } from '@/utils/notifications';
import { isPasswordValid } from '@/utils/validations';
export default {
  name: 'UserEditModal',
  introduction: 'Modal that contains on administration page',
  description: 'Component that is used to create a new user or update the old one',
  token: "<user-edit-modal\n      v-show=\"opened\"\n      :type=\"type\"\n      :information=\"information\"\n      @dismiss=\"toogleModal('closed')\"\n      @create=\"createAction\"/>",
  components: {
    Modal: Modal,
    RismaTitle: RismaTitle,
    InputField: InputField,
    RismaOrgSelector: RismaOrgSelector,
    Notification: Notification,
    RismaButton: RismaButton
  },
  props: {
    type: {
      required: true,
      type: String,
      default: 'create',
      note: 'Can be `create|update` (for creating new user or for updating the old one)'
    },
    allOrganisations: {
      required: true,
      type: Array,
      default: function _default() {},
      note: 'all organisations in organisation selector'
    },
    information: {
      required: false,
      type: Object,
      default: function _default() {},
      note: 'all the information that were sent if you need to update User'
    }
  },
  emits: ['createUser', 'updateUser', 'dismiss'],
  data: function data() {
    return {
      headerTitle: '',
      okText: '',
      accessRows: {},
      selectedOrganisations: [],
      selectedUsers: [],
      isLoaded: false,
      password: '',
      usernameError: false,
      initialsError: false,
      passwordError: false,
      fullNameError: false,
      hasErrorFromBackend: false,
      isRismaSelectOpen: false,
      errorsFromBackend: '',
      emailError: false,
      data: {},
      invalidEmail: '',
      defaultObject: {
        username: '',
        initials: '',
        fullname: '',
        email: '',
        organisations: [],
        sendWelcomeEmail: true
      },
      translate: getTranslate['UserEditModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    descriptionOfTheError: function descriptionOfTheError() {
      return this.translate.pleaseFillOutThisField;
    },
    descriptionOfThePasswordError: function descriptionOfThePasswordError() {
      return passwordErrorMessage(this.getSettingValue('feature.enterprise_security'));
    },
    descriptionOfTheEmailError: function descriptionOfTheEmailError() {
      if (this.invalidEmail) {
        return this.invalidEmail + this.translate.isAnInvalidEmailAddress;
      } else {
        return this.translate.pleaseFillOutThisField;
      }
    }
  }),
  mounted: function mounted() {
    this.data = _objectSpread(_objectSpread({}, this.defaultObject), this.information);
    this.fillTheForm();
  },
  methods: {
    disableMfa: function disableMfa() {
      this.updateUserRequest({
        id: this.data.id,
        mfa_type: 0
      }, false);
    },
    createUser: function createUser() {
      if (!this.isValid()) {
        return;
      }
      var data = this.getAllData();
      if (this.type == 'update') {
        this.updateUserRequest(data);
      } else {
        this.createUserRequest(data);
      }
    },
    createUserRequest: function createUserRequest(data) {
      var _this = this;
      userAPI.createNewUser(data).then(function (user) {
        _this.$emit('createUser', user);
        _this.dismissForm();
        _this.setFalseErrorMessages();
      }).catch(function (msg) {
        _this.hasErrorFromBackend = true;
        _this.errorsFromBackend = _this.getValidError(msg.response.error);
      });
    },
    updateUserRequest: function updateUserRequest(data) {
      var _this2 = this;
      var dismiss = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      userAPI.updateUser(data, data.id).then(function (user) {
        _this2.$emit('updateUser', data);
        if (dismiss) {
          _this2.dismissForm();
          _this2.setFalseErrorMessages();
        } else {
          _this2.data = user;
        }
      }).catch(function (msg) {
        _this2.hasErrorFromBackend = true;
        _this2.errorsFromBackend = _this2.getValidError(msg.response.error);
      });
    },
    getValidError: function getValidError(error) {
      var _this3 = this;
      return JSON.parse(error).map(function (err) {
        return _this3.$trans(capitalize(err));
      });
    },
    getAllData: function getAllData() {
      var selOrgs = this.selectedOrganisations.map(function (element) {
        return element = element.id;
      });
      var data = _objectSpread(_objectSpread({}, this.data), {}, {
        organisations: selOrgs,
        orgs: selOrgs.join(',')
      });
      if (this.password.length) {
        data.password = this.password;
      }
      return data;
    },
    isEmpty: function isEmpty(field) {
      return field.length === 0;
    },
    isValid: function isValid() {
      this.usernameError = this.isEmpty(this.data.username);
      this.initialsError = this.isEmpty(this.data.initials);
      this.passwordError = !this.isValidPassword(this.password);
      this.fullNameError = this.isEmpty(this.data.fullname);
      this.emailError = !this.isValidEmail(this.data.email) || this.isEmpty(this.data.email);
      if (this.usernameError || this.initialsError || this.fullNameError || this.emailError || this.passwordError) {
        return false;
      }
      return true;
    },
    isValidPassword: function isValidPassword() {
      if (this.type === 'create' && !this.password.length) {
        return false;
      }
      if (this.getSettingValue('feature.enterprise_security')) {
        return isPasswordValid(this.password) || this.password.length === 0;
      }
      return true;
    },
    isValidEmail: function isValidEmail(email) {
      var regExp = /\S+@\S+\.\S+/;
      if (!regExp.test(String(email).toLowerCase()) && email.length) {
        this.invalidEmail = this.data.email;
        return false;
      }
      this.invalidEmail = '';
      return true;
    },
    dismissForm: function dismissForm() {
      this.setEmpty();
      this.$emit('dismiss');
    },
    fillTheForm: function fillTheForm() {
      if (this.type == 'update') {
        this.headerTitle = this.translate.updateUser;
        this.okText = this.translate.update;
        this.setSelectedOrgs();
      } else {
        this.setEmpty();
        this.headerTitle = this.translate.createUser;
        this.okText = this.translate.create;
      }
      this.setFalseErrorMessages();
    },
    setSelectedOrgs: function setSelectedOrgs() {
      var _this4 = this;
      var orgs = this.data.organisations;
      if (orgs) {
        var filtered = [];
        orgs.forEach(function (element) {
          filtered.push(_this4.allOrganisations.filter(function (item) {
            return item.id == element;
          })[0]);
        });
        this.selectedOrganisations = filtered;
      }
    },
    setEmpty: function setEmpty() {
      this.selectedOrganisations = [];
      this.password = '';
    },
    setFalseErrorMessages: function setFalseErrorMessages() {
      this.fullNameError = false;
      this.usernameError = false;
      this.initialsError = false;
      this.emailError = false;
      this.passwordError = false;
      this.hasErrorFromBackend = false;
    },
    updateOrganisations: function updateOrganisations(e) {
      this.selectedOrganisations = e ? [].concat(e) : [];
    }
  }
};