function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import draggable from 'vuedraggable';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTableCell from '@/components/Molecules/RismaTable/RismaTableCell';
import RismaTableColumnMenu from '@/components/Molecules/RismaTable/RismaTableColumnMenu';
import { SortDirection } from '@/constants/SortDirection';
import { clickOutside } from '@/utils/directives/clickOutside';
export default {
  name: 'RismaTableBody',
  description: 'Tables main part',
  token: '<risma-table-body :columns="[]" :dataset="[]"/>',
  components: {
    draggable: draggable,
    FeatherIcon: FeatherIcon,
    RismaTableCell: RismaTableCell,
    RismaTableColumnMenu: RismaTableColumnMenu
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    columns: {
      required: true,
      type: Array,
      note: 'Array of objects with columns data'
    },
    dataset: {
      required: true,
      type: Array,
      note: 'Array of objects with rows data'
    },
    bodyHeight: {
      type: [String, Number],
      required: false,
      default: ''
    },
    bodyOffset: {
      type: [String, Number],
      required: false,
      default: 300
    }
  },
  emits: ['columnsChange', 'sortByColumn', 'dismissModal', 'delete', 'filtersChange', 'columnResize', 'starred', 'error', 'reload', 'iconClicked', 'update:loading'],
  data: function data() {
    return {
      resizedColumnKey: null,
      $resizedColumn: null,
      // dom obj
      $openedColumn: null,
      startCursorPosition: 0,
      startColumnWidth: 0,
      startTableWidth: 0,
      SortDirection: SortDirection,
      openedColumnMenu: null,
      blockColumnMenu: false,
      tableHeaderHeight: '40px',
      translate: {
        columnsWithAppliedFilters: this.$trans('Columns with applied filters'),
        noRecordsMatching: this.$trans('No records matching')
      }
    };
  },
  computed: {
    heightStyle: function heightStyle() {
      var _this$columnsWithSele;
      if (this.bodyHeight) return "".concat(this.bodyHeight, "px");
      var bodyOffset = this.bodyOffset || 375;
      if ((_this$columnsWithSele = this.columnsWithSelectedFilters) !== null && _this$columnsWithSele !== void 0 && _this$columnsWithSele.length) {
        bodyOffset += 50;
      }
      return "calc(100vh - ".concat(bodyOffset, "px)");
    },
    columnsWithSelectedFilters: function columnsWithSelectedFilters() {
      var result = [];
      this.columns.forEach(function (col) {
        if (col.filters && col.filters.some(function (filter) {
          return filter.active;
        })) {
          result.push(col);
        }
        if (col.filterApi && col.filterApi.activeValues.length > 0) {
          result.push(col);
        }
        if (col.filterFreeText && col.filterFreeText.searchString !== '') {
          result.push(col);
        }
      });
      return result;
    },
    stickyColumnsKeys: function stickyColumnsKeys() {
      if (!this.columns) return [];
      return this.columns.filter(function (col) {
        return col.sticky;
      }).map(function (col) {
        return col.key;
      });
    }
  },
  watch: {
    dataset: function dataset() {
      if (this.openedColumnMenu) {
        this.$nextTick(this.setOpenedColumnMenuPosition);
      }
    }
  },
  unmounted: function unmounted() {
    this.discardResizeEvents();
  },
  methods: {
    onResizerMousedown: function onResizerMousedown(e, elementKey) {
      this.resizedColumnKey = elementKey;
      this.$resizedColumn = e.target.parentElement;
      this.startCursorPosition = e.pageX;
      this.startColumnWidth = this.$resizedColumn.offsetWidth;
      this.startTableWidth = this.$refs.table.offsetWidth;
      this.setupResizeEvents();
    },
    onMouseMove: function onMouseMove(e) {
      if (!this.$resizedColumn) return;
      var cursorOffset = e.pageX - this.startCursorPosition;
      this.$refs.table.style.width = "".concat(this.startTableWidth + cursorOffset, "px");
      this.$resizedColumn.style.minWidth = "".concat(this.startColumnWidth + cursorOffset, "px");
    },
    onMouseUp: function onMouseUp(e) {
      if (!this.$resizedColumn) return;
      if (!e.target.classList.contains('resizer') && this.$resizedColumn.contains(e.target)) {
        this.blockColumnMenu = true;
      }
      this.$emit('columnResize', {
        key: this.resizedColumnKey,
        width: this.$resizedColumn.offsetWidth
      });
      this.resizedColumnKey = null;
      this.$resizedColumn = null;
      this.startCursorPosition = 0;
      this.startColumnWidth = 0;
    },
    setupResizeEvents: function setupResizeEvents() {
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.onMouseUp);
    },
    discardResizeEvents: function discardResizeEvents() {
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.onMouseUp);
    },
    openColumnMenu: function openColumnMenu(evt, column, colIndex) {
      if (!column.sortable) return;
      if (this.blockColumnMenu) {
        this.blockColumnMenu = false;
        return;
      }
      this.$openedColumn = evt.target.closest('th');
      this.openedColumnMenu = _objectSpread(_objectSpread({}, column), {}, {
        colIndex: colIndex
      });
      this.setOpenedColumnMenuPosition();
    },
    setOpenedColumnMenuPosition: function setOpenedColumnMenuPosition() {
      if (!this.$openedColumn) return;
      this.openedColumnMenu = Object.assign(this.openedColumnMenu, {
        offsetLeft: this.$openedColumn.offsetLeft + 'px',
        offsetTop: this.tableHeaderHeight
      });
      this.$nextTick(function () {
        var menuElement = document.getElementsByClassName('column-menu')[0];
        if (!menuElement) return;
        menuElement.scrollIntoView({
          block: 'nearest'
        });
      });
    },
    closeColumnMenu: function closeColumnMenu() {
      this.openedColumnMenu = null;
      this.$openedColumn = null;
    },
    onFiltersChange: function onFiltersChange(filters, columnKey) {
      this.$emit('filtersChange', {
        filters: filters,
        columnKey: columnKey
      });
    },
    deselectAllFiltersFor: function deselectAllFiltersFor(columnKey, isString) {
      var filters = isString ? '' : [];
      this.$emit('filtersChange', {
        filters: filters,
        columnKey: columnKey
      });
    },
    sortByColumn: function sortByColumn(_ref) {
      var index = _ref.index,
        direction = _ref.direction;
      this.$emit('sortByColumn', {
        index: index,
        direction: direction
      });
      this.$nextTick(this.closeColumnMenu);
    },
    onSortIconClick: function onSortIconClick(element, index) {
      var reversedDirection = element.sortDirection === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;
      this.sortByColumn({
        index: index,
        direction: reversedDirection
      });
    },
    getHeaderClasses: function getHeaderClasses(element) {
      if (!element.classes) return '';
      return element.classes.join(' ');
    },
    getHeaderStyles: function getHeaderStyles(element) {
      var result = {};
      if (element.styles) {
        result = _objectSpread({}, element.styles);
      }
      if (element.userSelectedWidth) {
        result = _objectSpread(_objectSpread({}, result), {}, {
          minWidth: element.userSelectedWidth + 'px'
        });
      }
      return result;
    },
    columnsChange: function columnsChange() {
      this.$emit('columnsChange', this.columns);
    }
  }
};