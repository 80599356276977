var getAxisKey = function getAxisKey(value) {
  return value.split(/(?=[A-Z])/).join('_').toLowerCase().replace('label', 'score');
};
export default {
  extendRiskData: function extendRiskData(item, filters) {
    if (!filters.xAxis || !filters.yAxis) return;
    var xKey = getAxisKey(filters.xAxis);
    var yKey = getAxisKey(filters.yAxis);
    item._scores['erm'] = {};
    item._scores['erm'].scores = {
      x: item[xKey],
      y: item[yKey]
    };
    if (item.previousRisk) {
      item._scores['erm'].scoresPrevious = {
        x: item.previousRisk[xKey],
        y: item.previousRisk[yKey]
      };
    } else {
      item._scores['erm'].scoresPrevious = {
        x: 0,
        y: 0
      };
    }
  },
  createGridData: function createGridData(filters, currentPeriod) {
    var gridSizeY;
    var likelihoodLabels = currentPeriod[filters.yAxis + 's'];
    if (likelihoodLabels && likelihoodLabels.length) {
      gridSizeY = Object.values(likelihoodLabels).map(function (item) {
        return {
          label: "".concat(currentPeriod.likelihood_values[item['likelihood_number']], " (").concat(item.description, ")")
        };
      });
    } else {
      gridSizeY = Object.values(currentPeriod.likelihood_values).map(function (item) {
        return {
          label: item
        };
      });
    }
    return [{
      xLabel: currentPeriod.impact_label,
      gridSizeX: Object.values(currentPeriod[filters.xAxis + 's']).map(function (item) {
        return {
          label: typeof item === 'string' ? item : item.value
        };
      }),
      yLabel: currentPeriod.likelihood_label,
      gridSizeY: gridSizeY,
      id: 'erm'
    }];
  },
  filterRiskBySelectedMatrix: function filterRiskBySelectedMatrix(risks, selectedMatrix, filters) {
    var xKey = getAxisKey(filters.xAxis);
    var yKey = getAxisKey(filters.yAxis);
    return risks.filter(function (item) {
      return selectedMatrix.find(function (selected) {
        return item[xKey] === selected.x && item[yKey] === selected.y;
      });
    });
  }
};