import { settingNamesByModule } from '@/constants/admin/settingNamesByModule';
import { MODULES } from '@/constants/modules';
var objectModules = [MODULES.COMPLIANCE_PROCESS_TREE_NODE];
var someModules = [MODULES.RISK];
export function getDisabledModuleFromArrayBySetting(data) {
  var settingFunction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (value) {
    return value;
  };
  var array = [];
  data.forEach(function (item) {
    var settings = settingNamesByModule[item];
    if (settings) {
      if (someModules.indexOf(item) !== -1 && !settings.some(function (setting) {
        return settingFunction(setting) === 1;
      }) || objectModules.indexOf(item) !== -1 && (!settings.main.every(function (setting) {
        return settingFunction(setting) === 1;
      }) || !settings.secondary.some(function (setting) {
        return settingFunction(setting) === 1;
      })) || someModules.indexOf(item) === -1 && objectModules.indexOf(item) === -1 && !settings.every(function (setting) {
        return settingFunction(setting) === 1;
      })) {
        array.push(item);
      }
    }
  });
  return array;
}