import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaSwitch from '@/components/Atoms/Inputs/RismaSwitch';
export default {
  name: 'ProjectQuestionnaireGapSelector',
  components: {
    RismaSwitch: RismaSwitch,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle
  },
  props: {
    questionnaireTypeId: {
      type: Number,
      default: null,
      required: false
    },
    questionnaireTypes: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    questionnaireId: {
      type: Number,
      default: null,
      required: false
    },
    questionnaires: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    gapSchemaId: {
      type: Number,
      default: null,
      required: false
    },
    gapSchemas: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    showText: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:showText', 'update:questionnaireTypeId', 'update:gapSchemaId', 'update:questionnaireId'],
  data: function data() {
    return {
      textShown: this.showText,
      translate: getTranslate['ProjectQuestionnaireGapSelector']()
    };
  },
  computed: {
    selectedQuestionnaireTypeId: {
      get: function get() {
        return this.questionnaireTypeId;
      },
      set: function set(questionnaireTypeId) {
        this.$emit('update:questionnaireTypeId', questionnaireTypeId);
      }
    },
    selectedGapSchemaId: {
      get: function get() {
        return this.gapSchemaId;
      },
      set: function set(gapSchemaId) {
        this.$emit('update:gapSchemaId', gapSchemaId);
      }
    },
    selectedQuestionnaireId: {
      get: function get() {
        return this.questionnaireId;
      },
      set: function set(questionnaireId) {
        this.$emit('update:questionnaireId', questionnaireId);
      }
    },
    switchOptions: function switchOptions() {
      return [{
        name: this.translate.text,
        value: true
      }, {
        name: this.translate.form,
        value: false
      }];
    }
  }
};