import { ObjectTypes } from '@/constants/ObjectTypes';
export function projectsByUser(user, getSettings) {
  if (!user) return [];
  var list = user.projects;
  if (getSettings('app.module_risks2_enabled') !== 1) {
    list = list.filter(function (project) {
      return project.objType !== ObjectTypes.RISK_PROJECT;
    });
  }
  if (getSettings('app.module_compliance_enabled') !== 1) {
    list = list.filter(function (project) {
      return project.objType !== ObjectTypes.COMPLIANCE_PROJECT;
    });
  }
  return list;
}