import i18n from '@/i18n';
export var getTranslate = {
  ReportNodes: function ReportNodes() {
    return {
      searchInAllNodes: i18n.t('Search in all nodes'),
      enterASearchKeywordAndorSelectOrganisations: i18n.t('Enter a search keyword and/or select organisations or users to search in all of your nodes'),
      title: i18n.t('Title'),
      freeTextSearch: i18n.t('Free text search'),
      id: i18n.t('ID'),
      process: i18n.t('Process'),
      description: i18n.t('Description'),
      purpose: i18n.t('Purpose'),
      processStatus: i18n.t('Process status'),
      questionnaire: i18n.t('Questionnaire'),
      completionDegreeIn: i18n.t('Completion degree in %'),
      gapSchemaName: i18n.t('Gap schema name'),
      activityType: i18n.t('Activity type'),
      dataControllers: i18n.t('Data controllers'),
      organisations: i18n.t('Organisations'),
      systems: i18n.t('Systems'),
      actions: i18n.t('Actions'),
      contracts: i18n.t('Contracts'),
      controls: i18n.t('Controls'),
      processors: i18n.t('Processors'),
      incidents: i18n.t('Incidents'),
      processLibrary: i18n.t('Process Library'),
      risks: i18n.t('Risks'),
      dataFlow: i18n.t('Data flow'),
      attachedFiles: i18n.t('Attached files'),
      score: i18n.t('Score'),
      assessmentScoring: i18n.t('Assessment scoring'),
      rto: i18n.t('RTO'),
      rpo: i18n.t('RPO'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      tags: i18n.t('Tags')
    };
  }
};