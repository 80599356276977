import { Http } from '../index';
import { handleRequestResponse, checkHttpStatus, throwError } from '../httpUtils';
export default function getUtilSettings() {
  return handleRequestResponse(Http.get('utils/settings'));
}
export function patchUtilSettings(data) {
  return Http.patch('utils/settings', data).then(function (response) {
    return checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return throwError(err);
  });
}
export function getUtilReports() {
  return handleRequestResponse(Http.get('utils/reports'));
}