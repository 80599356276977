import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
export default {
  name: '',
  components: {
    RismaDateRangePicker: RismaDateRangePicker
  },
  data: function data() {
    return {
      rangeStartDate: '2018-01-01',
      rangeEndDate: '2018-10-10',
      rangeOpens: 'right'
    };
  }
};