function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { createKpi } from '@/api/kpi/kpi';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Modal from '@/components/Molecules/Modal/Modal';
export default {
  name: 'KPICreationModal',
  introduction: 'KPICreationModal multiselect popup',
  description: 'KPICreationModal with selector of KPI',
  token: '<kpi-creation-modal />',
  components: {
    Modal: Modal,
    SingleSelect: SingleSelect
  },
  props: {
    dashboardId: {
      type: [String, Number],
      require: true,
      default: null
    },
    metadata: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      },
      note: 'Array that contains kpi types data'
    }
  },
  emits: ['dismiss', 'added'],
  data: function data() {
    return {
      isFormValid: true,
      data: {
        kpiType: ''
      },
      isButtonDisabled: false,
      translate: getTranslate['KPICreationModal']()
    };
  },
  computed: {
    kpiTypes: function kpiTypes() {
      var _this = this;
      return this.metadata.map(function (item) {
        return {
          value: item.name,
          label: _this.$trans(item.title)
        };
      });
    },
    selectedTypeDescription: function selectedTypeDescription() {
      var _this$selectedKpi;
      if (!this.data.kpiType) return '';
      return ((_this$selectedKpi = this.selectedKpi) === null || _this$selectedKpi === void 0 ? void 0 : _this$selectedKpi.description) || '';
    },
    selectedKpi: function selectedKpi() {
      var _this2 = this;
      return this.metadata.find(function (item) {
        return item.name === _this2.data.kpiType;
      });
    }
  },
  methods: {
    handleCreateKpi: function handleCreateKpi() {
      var _this3 = this;
      if (this.isDataValid()) {
        this.isButtonDisabled = true;
        createKpi(_objectSpread(_objectSpread({}, this.data), {}, {
          title: this.$trans(this.selectedKpi.title),
          description: this.$trans(this.selectedTypeDescription),
          dashboardId: +this.dashboardId
        })).then(function (response) {
          _this3.$emit('added', response);
        }).finally(function () {
          return _this3.isButtonDisabled = false;
        });
      } else {
        this.isFormValid = false;
      }
    },
    isDataValid: function isDataValid() {
      return !!this.selectedKpi;
    }
  }
};