import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
      backgroundColor: $options.backgroundColor,
      color: $props.color,
      borderColor: $props.color
    }),
    class: "text-xl rounded-lg border flex justify-center items-center font-semibold"
  }, [_createElementVNode("p", null, [_renderSlot(_ctx.$slots, "default")])], 4 /* STYLE */);
}