import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-78093e52"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = {
  class: "controls-column-left mr-20"
};
var _hoisted_3 = {
  class: "controls-column-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification_pop_up = _resolveComponent("notification-pop-up");
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_admin_controls_well = _resolveComponent("admin-controls-well");
  return _openBlock(), _createElementBlock("div", null, [_ctx.notificationData ? (_openBlock(), _createBlock(_component_notification_pop_up, {
    key: 0,
    onDismiss: _ctx.dismissNotificationPopUp
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString(_ctx.notificationData.text), 1 /* TEXT */), _createElementVNode("div", null, _toDisplayString(_ctx.notificationData.footer), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onDismiss"])) : _createCommentVNode("v-if", true), $data.showNotification ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    closeable: false,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$trans($data.notificationText)), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    class: "mb-5",
    title: $data.translate.controlsAdministration,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_admin_controls_well, {
    title: $options.accountName,
    items: $data.accounts,
    loading: $data.loadingAccounts,
    "is-accounts-well": true,
    onCreateItem: $options.createNewAccount,
    onDeleteItem: $options.deleteAccount,
    onEditItem: $options.editAccount
  }, null, 8 /* PROPS */, ["title", "items", "loading", "onCreateItem", "onDeleteItem", "onEditItem"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_admin_controls_well, {
    title: $options.processName,
    items: $data.processes,
    loading: $data.loadingProcesses,
    class: "mb-10",
    onCreateItem: $options.createNewProcess,
    onDeleteItem: $options.deleteProcess,
    onEditItem: $options.editProcess
  }, null, 8 /* PROPS */, ["title", "items", "loading", "onCreateItem", "onDeleteItem", "onEditItem"]), _createVNode(_component_admin_controls_well, {
    title: $options.riskName,
    items: $data.risks,
    loading: $data.loadingRisks,
    "risk-types": $options.riskTypesForSingleSelect,
    class: "mb-10",
    onCreateItem: $options.createNewRisk,
    onDeleteItem: $options.deleteRisk,
    onEditItem: $options.editRisk
  }, null, 8 /* PROPS */, ["title", "items", "loading", "risk-types", "onCreateItem", "onDeleteItem", "onEditItem"]), _createVNode(_component_admin_controls_well, {
    title: $data.translate.controlRiskType,
    items: $options.getControlRiskTypesExtended,
    loading: $data.loadingControlRiskTypes,
    onCreateItem: $options.createNewControlRiskType,
    onDeleteItem: $options.deleteControlRiskType,
    onEditItem: $options.editControlRiskType
  }, null, 8 /* PROPS */, ["title", "items", "loading", "onCreateItem", "onDeleteItem", "onEditItem"])])])]);
}