function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import CompleteControl from '@/components/Molecules/Controls/CompleteControl';
import Alert from '@/components/Molecules/Modal/Alert';
export default {
  name: 'CompleteModal',
  introduction: 'A modal that shows latest completion information of control and handle complete functionality',
  description: 'This is a Molecule component for /controls2/controls/:id. Parent component: ControlDetails',
  token: '<complete-modal :cpath="cpath" :id="id" />',
  components: {
    Modal: Modal,
    CompleteControl: CompleteControl,
    Alert: Alert
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
      note: 'An id of a control'
    },
    cpath: {
      type: String,
      required: true,
      note: 'A cpath of a control'
    }
  },
  emits: ['update', 'close'],
  data: function data() {
    return {
      title: '',
      interactionDisabled: false,
      showConfirmation: false,
      translate: getTranslate['CompleteModal'](),
      isUpdated: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    completeModalWide: function completeModalWide() {
      return this.getSettingValue('feature.complete_modal_wide');
    },
    isFilesDisable: function isFilesDisable() {
      return this.getSettingValue('feature.control_complete_modal_files_disabled') ? true : false;
    }
  }),
  methods: {
    handleClose: function handleClose() {
      if (this.interactionDisabled) return;
      this.$emit('close');
    },
    handleAccept: function handleAccept() {
      this.$emit('close');
    },
    toggleDisableInteraction: function toggleDisableInteraction() {
      this.interactionDisabled = !this.interactionDisabled;
    },
    setModalTitle: function setModalTitle(title) {
      this.title = title;
    },
    onDismiss: function onDismiss() {
      if (this.isUpdated) {
        this.showConfirmation = true;
        return;
      }
      this.handleClose();
    }
  }
};