function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import cloneDeep from 'lodash/cloneDeep';
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { UserLevels, LockLevels } from '@/constants/UserLevels';
import { ObjectTypes, ObjectTypeToUserField } from '@/constants/ObjectTypes';
import { RiskProjects } from '@/constants/risks/RiskProjects';
import Notification from '@/components/Molecules/Notification';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
import { buildNavBar } from '@/menus/navbar';
import { generateKey } from '@/utils/Utils';
export default {
  name: 'UserRightsTableNew',
  components: {
    Notification: Notification,
    RismaTitle: RismaTitle,
    Checkboxes: Checkboxes,
    RadioButtons: RadioButtons
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'User obj'
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['notify', 'error', 'propertiesChanged', 'projectChanged'],
  data: function data() {
    var _fieldToLocked;
    return {
      LockLevels: LockLevels,
      UserLevels: UserLevels,
      notificationMessage: '',
      generalRadioOptions: [{
        value: UserLevels.NONE
      }, {
        value: UserLevels.NORMAL
      }, {
        value: UserLevels.PRIVILEGED
      }, {
        value: UserLevels.SUPER
      }],
      supervisorOption: [{
        value: UserLevels.SUPERVISOR
      }],
      adminOption: [{
        value: UserLevels.ADMIN
      }],
      checkedValues: {},
      fieldToLocked: (_fieldToLocked = {}, _defineProperty(_fieldToLocked, ObjectTypeToUserField[ObjectTypes.EXECUTION_INITIATIVE], 'level_e_locked'), _defineProperty(_fieldToLocked, ObjectTypeToUserField[ObjectTypes.RISK_RISK], 'level_r_locked'), _defineProperty(_fieldToLocked, ObjectTypeToUserField[ObjectTypes.CONTROLS_CONTROL], 'level_c_locked'), _defineProperty(_fieldToLocked, ObjectTypeToUserField[ObjectTypes.INCIDENTS_INCIDENT], 'level_incidents_locked'), _defineProperty(_fieldToLocked, ObjectTypeToUserField[ObjectTypes.CONTRACT], 'level_contracts_locked'), _fieldToLocked),
      tableKey: generateKey(),
      translate: getTranslate['UserRightsTableNew'](),
      userObject: {}
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, {
    currentUser: 'currentUser'
  })), mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    getAllSettings: 'getAllSettings'
  })), {}, {
    filteredProjects: function filteredProjects() {
      var _this$userObject;
      if (!((_this$userObject = this.userObject) !== null && _this$userObject !== void 0 && _this$userObject.projects)) return [];
      return this.userObject.projects.filter(function (project) {
        return !(project.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type && project.objType === ObjectTypes.RISK_PROJECT);
      });
    },
    modules: function modules() {
      var structure = buildNavBar(this.getAllSettings, null);
      return [structure.actions, structure.risk, structure.controls, structure.incident, structure.contracts, structure.assets];
    },
    complianceProjectsSettings: function complianceProjectsSettings() {
      if (!this.tableKey) return [];
      var complianceProjects = this.filteredProjects.filter(function (project) {
        return project.objType && project.objType === ObjectTypes.COMPLIANCE_PROJECT;
      });
      return complianceProjects.map(this.getProjectObj);
    },
    riskProjectsSettings: function riskProjectsSettings() {
      if (!this.tableKey) return [];
      var riskProjects = this.filteredProjects.filter(function (project) {
        return project.objType && project.objType === ObjectTypes.RISK_PROJECT;
      });
      return riskProjects.map(this.getProjectObj);
    },
    modulesSettings: function modulesSettings() {
      if (!this.tableKey) return [];
      return this.modules.filter(function (module) {
        return module.rights;
      }).map(this.getModuleObj);
    },
    tableHeaders: function tableHeaders() {
      return [{
        label: this.translate.noAccess,
        level: UserLevels.NONE,
        icon: 'no-access-icon'
      }, {
        label: this.translate.normal,
        level: UserLevels.NORMAL,
        icon: 'normal-access-icon'
      }, {
        label: this.translate.privileged,
        level: UserLevels.PRIVILEGED,
        icon: 'privileged-access-icon'
      }, {
        label: this.translate.super,
        level: UserLevels.SUPER,
        icon: 'super-access-icon'
      }, {
        label: this.translate.supervisor,
        level: UserLevels.SUPERVISOR,
        icon: 'supervisor-access-icon'
      }];
    },
    tableBodies: function tableBodies() {
      var _this$modulesSettings,
        _this = this,
        _this$riskProjectsSet,
        _this$complianceProje;
      var result = [];
      if ((_this$modulesSettings = this.modulesSettings) !== null && _this$modulesSettings !== void 0 && _this$modulesSettings.length) {
        result.push({
          title: 'Basic modules',
          rows: this.modulesSettings,
          adminSetting: {
            checked: this.userObject.level_admin,
            onAdminUpdate: function onAdminUpdate(isChecked) {
              return _this.setModuleRightsTo(isChecked ? UserLevels.ADMIN : UserLevels.NONE);
            }
          }
        });
      }
      if ((_this$riskProjectsSet = this.riskProjectsSettings) !== null && _this$riskProjectsSet !== void 0 && _this$riskProjectsSet.length) {
        result.push({
          title: 'Risk projects',
          rows: this.riskProjectsSettings
        });
      }
      if ((_this$complianceProje = this.complianceProjectsSettings) !== null && _this$complianceProje !== void 0 && _this$complianceProje.length) {
        result.push({
          title: 'Compliance projects',
          rows: this.complianceProjectsSettings
        });
      }
      return result;
    },
    supervisorFeatureEnabled: function supervisorFeatureEnabled() {
      return this.getSettingValue('feature.supervisor_enabled');
    },
    isCurrentUser: function isCurrentUser() {
      return this.currentUser.id === this.userObject.id;
    }
  }),
  watch: {
    data: {
      deep: true,
      handler: function handler() {
        this.userObject = cloneDeep(this.data);
      }
    }
  },
  beforeMount: function beforeMount() {
    this.userObject = cloneDeep(this.data);
  },
  methods: {
    checkIfLockAvailable: function checkIfLockAvailable(accessLevel, field, isModule) {
      var isAdmin = accessLevel === UserLevels.ADMIN;
      var hasAccess = accessLevel > UserLevels.NONE;
      var isLockedField = !!this.fieldToLocked[field];
      return !isAdmin && hasAccess && (!isModule || isLockedField);
    },
    setModuleRightsTo: function setModuleRightsTo() {
      var level = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      if (!this.validateOperation()) return;
      if (level === null) return false;
      var isAdmin = level === UserLevels.ADMIN;
      var resultProps = {};
      if (level !== null) {
        this.modulesSettings.forEach(function (module) {
          resultProps[module.property] = level;
        });
        resultProps.level_admin = +isAdmin;
        resultProps.is_admin = isAdmin;
      }
      if (isAdmin) {
        Object.values(this.fieldToLocked).forEach(function (moduleLockKey) {
          resultProps[moduleLockKey] = LockLevels.UNLOCKED;
        });
      }
      this.$emit('propertiesChanged', _objectSpread(_objectSpread({}, resultProps), props));
    },
    updateModuleRights: function updateModuleRights() {
      var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (!this.validateOperation()) return;
      this.$emit('propertiesChanged', props);
    },
    updateProjectRights: function updateProjectRights(project) {
      var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      if (!this.validateOperation()) return;
      var params = this.prepareProjectParams(project, props);
      var currentVisibleProjectIdx = this.userObject.projects.findIndex(function (item) {
        return item.id === project.id;
      });
      this.userObject.projects[currentVisibleProjectIdx] = _objectSpread(_objectSpread({}, this.userObject.projects[currentVisibleProjectIdx]), params);
      this.$emit('projectChanged', params);
      return;
    },
    getModuleObj: function getModuleObj(module) {
      var field = module.property;
      var row = {
        title: module.title,
        id: null,
        property: field,
        access_level: +this.userObject[field],
        isLockAvailable: this.checkIfLockAvailable(+this.userObject[field], field, true),
        locked: this.userObject[this.fieldToLocked[field]] && !this.userObject.is_admin && this.userObject[field] !== UserLevels.NONE,
        supervisor: 0
      };
      this.checkedValues[row.property] = this.userObject[field];
      return _objectSpread(_objectSpread({}, row), {}, {
        onRightsUpdate: this.onModulestRightsUpdate,
        onLockUpdate: this.onModulesLockUpdate,
        onAdminUpdate: this.onModulesAdminUpdate
      });
    },
    onModulesAdminUpdate: function onModulesAdminUpdate() {
      return this.setModuleRightsTo(UserLevels.ADMIN);
    },
    onModulesLockUpdate: function onModulesLockUpdate(row, isLocked) {
      return this.updateModuleRights(_defineProperty({}, this.fieldToLocked[row.property], isLocked ? LockLevels.LOCKED : LockLevels.UNLOCKED));
    },
    onModulestRightsUpdate: function onModulestRightsUpdate(row, access_level) {
      if (+row.access_level === UserLevels.ADMIN) {
        return this.setModuleRightsTo(UserLevels.NONE, _defineProperty({}, row.property, access_level));
      }
      if (row.isLockAvailable && +access_level === UserLevels.NONE) {
        var _this$updateModuleRig2;
        return this.updateModuleRights((_this$updateModuleRig2 = {}, _defineProperty(_this$updateModuleRig2, row.property, access_level), _defineProperty(_this$updateModuleRig2, this.fieldToLocked[row.property], LockLevels.UNLOCKED), _this$updateModuleRig2));
      }
      return this.updateModuleRights(_defineProperty({}, row.property, access_level));
    },
    getProjectObj: function getProjectObj(project) {
      var row = {
        title: project.title,
        isComplianceProject: project.objType && project.objType === ObjectTypes.COMPLIANCE_PROJECT,
        isRiskProject: project.objType && project.objType === ObjectTypes.RISK_PROJECT,
        hasSupervisor: project.objType && project.objType === ObjectTypes.COMPLIANCE_PROJECT,
        id: project.id,
        property: "".concat(project.objType, "_").concat(project.id),
        access_level: +project.access_level,
        isLockAvailable: this.checkIfLockAvailable(+project.access_level),
        locked: project.locked && +project.access_level !== UserLevels.NONE,
        supervisor: project.supervisor || 0
      };
      this.checkedValues[row.property] = project.supervisor ? UserLevels.SUPERVISOR : project.access_level;
      return _objectSpread(_objectSpread({}, row), {}, {
        onRightsUpdate: this.onProjectRightsUpdate,
        onLockUpdate: this.onProjectLockUpdate,
        onAdminUpdate: this.onProjectAdminUpdate
      });
    },
    onProjectAdminUpdate: function onProjectAdminUpdate(row) {
      return this.updateProjectRights(row, {
        access_level: UserLevels.ADMIN,
        supervisor: false,
        locked: LockLevels.UNLOCKED
      });
    },
    onProjectLockUpdate: function onProjectLockUpdate(row, isLocked) {
      return this.updateProjectRights(row, {
        locked: isLocked ? LockLevels.LOCKED : LockLevels.UNLOCKED
      });
    },
    onProjectRightsUpdate: function onProjectRightsUpdate(row, access_level) {
      var supervisor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      return this.updateProjectRights(row, {
        access_level: access_level,
        supervisor: supervisor
      });
    },
    prepareProjectParams: function prepareProjectParams(project, props) {
      var params = _objectSpread({
        user_id: this.userObject.id,
        project_id: project.id,
        supervisor: project.supervisor,
        access_level: project.access_level,
        locked: project.locked
      }, props);
      if (project.isRiskProject) {
        params = _objectSpread(_objectSpread({}, params), {}, {
          project_id: null,
          r_project_id: project.id
        });
      }
      return params;
    },
    validateOperation: function validateOperation() {
      if (this.isCurrentUser) {
        this.notifyError(this.translate.warningYouCannotPerformThisOperation);
        this.refreshTableKey();
        return false;
      }
      return true;
    },
    getRowCheckboxOption: function getRowCheckboxOption(checked) {
      var disabled = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      return [{
        value: 1,
        checked: checked,
        disabled: disabled
      }];
    },
    notifyError: function notifyError(error) {
      this.notificationMessage = error;
    },
    refreshTableKey: function refreshTableKey() {
      this.tableKey = generateKey();
    }
  }
};