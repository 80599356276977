export var AdminTypesModalMixin = {
  methods: {
    onChangeLabel: function onChangeLabel(e) {
      this.labelName = e;
    },
    beforeOpen: function beforeOpen() {
      this.clearFields();
      this.resetValidation();
    },
    toggleModal: function toggleModal() {
      this.showModal = !this.showModal;
      this.$emit('toggle', this.showModal);
    },
    resetValidation: function resetValidation() {
      this.labelNameValidation = null;
      this.selectOptionsValidation = null;
      this.fieldTypeValidation = null;
    }
  }
};