function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { patchUtilSettings } from '@/api/risma/settings';
import { createWelcomePage as _createWelcomePage, updateWelcomePage as _updateWelcomePage } from '@/api/admin/welcomePage';
import { getCurrentWelcomePage as _getCurrentWelcomePage } from '@/api/welcomepage/welcomePage';
import { cacheItem } from '@/routes/storeCache';
import Notification from '@/components/Molecules/Notification';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
export default {
  name: 'UserGuide',
  components: {
    TinyMceBox: TinyMceBox,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Notification: Notification,
    RismaTitle: RismaTitle
  },
  emits: ['updateHeader'],
  data: function data() {
    return {
      notificationTimeoutId: null,
      notificationShowTime: 5000,
      notificationType: 'success',
      notificationMessage: '',
      isLoading: false,
      userGuideContent: '',
      currentGuide: null,
      userGuideTitleSettingsName: 'app.user_guide_title'
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['isAdmin'])), {}, {
    userGuideTitle: function userGuideTitle() {
      return this.getSettingValue(this.userGuideTitleSettingsName);
    },
    currentGuideId: function currentGuideId() {
      var _this$currentGuide;
      return (_this$currentGuide = this.currentGuide) === null || _this$currentGuide === void 0 ? void 0 : _this$currentGuide.id;
    }
  }),
  mounted: function mounted() {
    this.getCurrentWelcomePage();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useSettingsStore, {
    loadSettings: 'load'
  })), {}, {
    getCurrentWelcomePage: function getCurrentWelcomePage() {
      var _this = this;
      this.isLoading = true;
      return _getCurrentWelcomePage().then(function (response) {
        if (!response) return;
        _this.currentGuide = response;
        _this.userGuideContent = response.contentHtml;
      }).catch(function () {
        _this.currentGuide = null;
      }).finally(function () {
        _this.isLoading = false;
      });
    },
    saveContent: function saveContent(content) {
      if (content === this.userGuideContent) return;
      this.userGuideContent = content;
      if (this.currentGuideId) {
        this.updateWelcomePage(this.currentGuideId, content, this.userGuideTitle);
      } else {
        this.createWelcomePage(content, this.userGuideTitle);
      }
    },
    createWelcomePage: function createWelcomePage(content) {
      var _this2 = this;
      var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      return _createWelcomePage(content, name).then(function (newGuide) {
        _this2.currentGuide = newGuide;
        _this2.showSuccessNotification();
      }).catch(function () {
        _this2.showErrorNotification();
      });
    },
    updateWelcomePage: function updateWelcomePage(id, content) {
      var _this3 = this;
      var name = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      return _updateWelcomePage(id, content, name).then(function (newGuide) {
        _this3.currentGuide = newGuide;
        _this3.showSuccessNotification();
      }).catch(function () {
        _this3.showErrorNotification();
      });
    },
    patchUserGuideTitle: function patchUserGuideTitle(title) {
      var _this4 = this;
      return patchUtilSettings(_defineProperty({}, this.userGuideTitleSettingsName, title)).then(function () {
        cacheItem('settings', function () {
          return _this4.loadSettings();
        }, 180);
      }).then(function () {
        _this4.$emit('updateHeader');
      });
    },
    onTitleChange: function onTitleChange(newTitle) {
      var _this5 = this;
      if (!this.titleValidate(newTitle)) {
        this.showErrorNotification('Title cannot be empty or be more than 20 characters');
        this.currentGuide.name = '';
        this.$nextTick(function () {
          _this5.currentGuide.name = _this5.userGuideTitle;
        });
        return;
      }
      this.saveUserGuideTitle(newTitle);
    },
    titleValidate: function titleValidate(title) {
      return title && title.length && title !== this.userGuideTitle && title.length <= 20;
    },
    saveUserGuideTitle: function saveUserGuideTitle(title) {
      var promises = [this.patchUserGuideTitle(title)];
      if (this.currentGuideId) {
        promises.push(this.updateWelcomePage(this.currentGuideId, this.userGuideContent, title));
      } else {
        promises.push(this.createWelcomePage(this.userGuideContent, title));
      }
      return Promise.all(promises);
    },
    showSuccessNotification: function showSuccessNotification() {
      var successMsg = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Saved';
      this.notificationType = 'success';
      this.showNotification(successMsg);
    },
    showErrorNotification: function showErrorNotification() {
      var errorMsg = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Not saved';
      this.notificationType = 'error';
      this.showNotification(errorMsg);
    },
    showNotification: function showNotification(message) {
      var _this6 = this;
      this.notificationMessage = message;
      clearTimeout(this.notificationTimeoutId);
      this.notificationTimeoutId = setTimeout(function () {
        _this6.notificationMessage = '';
      }, this.notificationShowTime);
    }
  })
};