import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  style: {
    "position": "relative",
    "width": "100%",
    "padding-top": "30px",
    "heigth": "10px",
    "margin": "10px 0 0 0"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_progress_bar = _resolveComponent("risma-progress-bar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_progress_bar, {
    value: 30,
    total: "100"
  })]);
}