import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "py-2 w-full"
};
var _hoisted_2 = {
  key: 0,
  class: "text-center"
};
var _hoisted_3 = {
  class: "w-full"
};
var _hoisted_4 = {
  class: "max-w-100px"
};
var _hoisted_5 = {
  key: 0,
  class: "max-w-100px w-32"
};
var _hoisted_6 = {
  width: "100%",
  class: "max-w-100px"
};
var _hoisted_7 = {
  key: 0,
  class: "text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_collapsible_panel = _resolveComponent("collapsible-panel");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_collapsible_panel, {
    onCollapseChanged: $options.togglePanel
  }, {
    header: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: _ctx.$trans('Relevant activities'),
        class: "pb-2",
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])];
    }),
    content: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [!$data.loaded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_loading_indicator_small)])) : (_openBlock(true), _createElementBlock(_Fragment, {
        key: 1
      }, _renderList([$data.matchedEntries, $data.notMatchedEntries], function (item) {
        return _openBlock(), _createElementBlock("div", {
          key: item.title,
          class: "mb-2"
        }, [_createVNode(_component_risma_title, {
          title: item.title,
          type: "medium",
          class: "pb-2"
        }, null, 8 /* PROPS */, ["title"]), _createElementVNode("table", _hoisted_3, [_createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.activities, function (activity) {
          return _openBlock(), _createElementBlock("tr", {
            key: activity.id
          }, [_createElementVNode("td", _hoisted_4, [activity.uniqueId ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_title, {
            title: activity.uniqueId,
            type: "small",
            class: "mr-2 pb-2"
          }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)]), _createElementVNode("td", _hoisted_6, [activity.url ? (_openBlock(), _createBlock(_component_preview_modal_link, {
            key: 0,
            activity: activity.activity,
            onUpdated: function onUpdated($event) {
              return $options.updatedFromPreview($event, activity);
            }
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_risma_title, {
                title: activity.objTypeName + activity.title,
                type: "small",
                class: "hover:underline pb-2"
              }, null, 8 /* PROPS */, ["title"])];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onUpdated"])) : (_openBlock(), _createBlock(_component_risma_title, {
            key: 1,
            title: activity.objTypeName + activity.title,
            class: "pb-2",
            type: "small"
          }, null, 8 /* PROPS */, ["title"]))])]);
        }), 128 /* KEYED_FRAGMENT */))])]), !item.activities.length ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_risma_title, {
          type: "small",
          class: "pb-2",
          title: _ctx.$trans('Nothing to show')
        }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)]);
      }), 128 /* KEYED_FRAGMENT */))])];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onCollapseChanged"])]);
}