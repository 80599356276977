import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, withModifiers as _withModifiers, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-043ac22e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "user-edit-modal"
};
var _hoisted_2 = {
  name: "usereditform"
};
var _hoisted_3 = {
  class: "input-line mb-3"
};
var _hoisted_4 = {
  key: 0,
  class: "input-line"
};
var _hoisted_5 = {
  class: "flex ml-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_org_selector = _resolveComponent("risma-org-selector");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_modal, {
    header: $data.headerTitle,
    "dismiss-on-click-outside": false,
    "accept-on-enter": !$data.isRismaSelectOpen,
    onAccept: $options.createUser,
    onDismiss: $options.dismissForm
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("form", _hoisted_2, [_createElementVNode("div", {
        class: _normalizeClass([{
          'notification-up': $data.usernameError
        }, "input-line mb-3"])
      }, [$data.hasErrorFromBackend ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errorsFromBackend, function (error, idx) {
            return _openBlock(), _createElementBlock("p", {
              key: idx
            }, _toDisplayString(error), 1 /* TEXT */);
          }), 128 /* KEYED_FRAGMENT */))];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
        modelValue: $data.data.username,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.data.username = $event;
        }),
        title: $data.translate.username,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title"]), $data.usernameError ? (_openBlock(), _createBlock(_component_notification, {
        key: 1,
        closeable: false,
        class: "form-notification",
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.descriptionOfTheError), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
        class: _normalizeClass([{
          'notification-up': $data.initialsError
        }, "input-line mb-3"])
      }, [_createVNode(_component_input_field, {
        modelValue: $data.data.initials,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.data.initials = $event;
        }),
        title: $data.translate.initials,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title"]), $data.initialsError ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        class: "form-notification",
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.descriptionOfTheError), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
        class: _normalizeClass([{
          'notification-up': $data.passwordError
        }, "input-line mb-3"])
      }, [_createVNode(_component_input_field, {
        modelValue: $data.password,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.password = $event;
        }),
        title: $data.translate.password,
        type: "password"
      }, null, 8 /* PROPS */, ["modelValue", "title"]), $data.passwordError ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        class: "form-notification",
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.descriptionOfThePasswordError), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
        class: _normalizeClass([{
          'notification-up': $data.fullNameError
        }, "input-line mb-3"])
      }, [_createVNode(_component_input_field, {
        modelValue: $data.data.fullname,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.data.fullname = $event;
        }),
        title: $data.translate.fullName,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title"]), $data.fullNameError ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        class: "form-notification",
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.descriptionOfTheError), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
        class: _normalizeClass([{
          'notification-up': $data.emailError
        }, "input-line mb-3"])
      }, [_createVNode(_component_input_field, {
        modelValue: $data.data.email,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $data.data.email = $event;
        }),
        title: $data.translate.email,
        type: "email"
      }, null, 8 /* PROPS */, ["modelValue", "title"]), $data.emailError ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        class: "form-notification",
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.descriptionOfTheEmailError), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_org_selector, {
        title: $data.translate.organisation,
        "available-options": $props.allOrganisations,
        "selected-options": $data.selectedOrganisations,
        "open-direction": "top",
        onSelected: $options.updateOrganisations,
        onOpen: _cache[5] || (_cache[5] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[6] || (_cache[6] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["title", "available-options", "selected-options", "onSelected"])]), $props.type === 'create' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_title, {
        title: $data.translate.sendWelcomeEmailToUser,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
          return $data.data.sendWelcomeEmail = $event;
        }),
        checked: true,
        type: "checkbox"
      }, null, 512 /* NEED_PATCH */), [[_vModelCheckbox, $data.data.sendWelcomeEmail]])])) : _createCommentVNode("v-if", true)])];
    }),
    footer: _withCtx(function () {
      return [_createElementVNode("div", {
        class: "modal-footer flex justify-between items-center",
        onKeyup: _cache[8] || (_cache[8] = _withKeys(_withModifiers(function () {}, ["stop"]), ["enter"]))
      }, [_ctx.getSettingValue('feature.google_mfa_enabled') && $data.data.mfa_type ? (_openBlock(), _createBlock(_component_risma_button, {
        key: 0,
        text: $data.translate.disableMfaForUser,
        type: "util",
        onClick: $options.disableMfa
      }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_button, {
        text: $data.translate.dismiss,
        class: "capitalize",
        type: "util",
        onClick: $options.dismissForm
      }, null, 8 /* PROPS */, ["text", "onClick"]), _createVNode(_component_risma_button, {
        text: $data.okText,
        class: "capitalize",
        type: "save",
        onClick: $options.createUser
      }, null, 8 /* PROPS */, ["text", "onClick"])])], 32 /* HYDRATE_EVENTS */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "accept-on-enter", "onAccept", "onDismiss"])]);
}