import StackedBarChart from '@/components/Molecules/Charts/StackedBarChart';
export default {
  components: {
    StackedBarChart: StackedBarChart
  },
  data: function data() {
    return {
      data: [1, 2, 3],
      colors: ['red', 'green', 'blue'],
      labels: ['a', 'b', 'c']
    };
  },
  methods: {
    barClicked: function barClicked(id) {
      alert(id);
    }
  }
};