import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risk_management = _resolveComponent("risk-management");
  return _openBlock(), _createBlock(_component_risk_management, {
    title: $options.title,
    columns: $options.columns,
    dataset: $options.dataset,
    "create-item-fn": $options.createManagementLevel,
    "update-item-fn": $options.updateManagementLevel,
    onDelete: $options.deleteManagementLevel
  }, null, 8 /* PROPS */, ["title", "columns", "dataset", "create-item-fn", "update-item-fn", "onDelete"]);
}