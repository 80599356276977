function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSolutionsStore } from '@/Store/solutionsStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { getAll as _getControls, getAllMotherControls } from '@/api/controls/controls';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import { getTranslate } from '@/components/Pages/Controls/translate';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { ControlLocalStorage } from '@/constants/ControlLocalStorage';
import { findPropsByIds } from '@/utils/Utils';
import { compareNumbers } from '@/utils/compare';
import { sortByIndex } from '@/utils/sort';
import { checkControlsOrgAccess } from '@/utils/access';
export default {
  name: 'MotherControlReports',
  components: {
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaDatatable: RismaDatatable,
    PreviewModalLink: PreviewModalLink,
    RismaToggle: RismaToggle,
    SaveReport: SaveReport
  },
  mixins: [RismaDatatableDefaultMixin, SaveReportMixin],
  setup: function setup() {
    return {
      showDeactivated: getLocalStorageComputed('show_deactivated_mother_report', false, true)
    };
  },
  data: function data() {
    return {
      dataset: [],
      motherControlsByPath: [],
      isLoaded: false,
      toggleDeactivatedTimer: null,
      stateLocal: ControlLocalStorage.MOTHER_REPORTS,
      disabledFilterByColumns: ['description'],
      columnsMeta: [{
        targets: ['cpath'],
        width: '50px'
      }],
      decoupleFilterFields: [{
        key: 'tags'
      }, {
        key: 'solutions'
      }, {
        key: 'daughterControls'
      }],
      columnFiltersSorting: [{
        key: 'cpath',
        sortingFunc: compareNumbers
      }, {
        key: 'daughterControls',
        sortingFunc: sortByIndex
      }],
      reportType: 'mother-controls-report',
      translate: getTranslate['ControlReports']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSolutionsStore, {
    solutions: 'enabledSolutions'
  })), mapState(useTagsStore, ['tags'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    userHasMotherAccess: function userHasMotherAccess() {
      var motherControlUserLevel = this.getSettingValue('feature.create_mothercontrol_user_level');
      return checkControlsOrgAccess(this.currentUser, motherControlUserLevel);
    },
    columns: function columns() {
      return {
        cpath: this.translate.id,
        title: this.translate.title,
        daughterControls: this.translate.daughterControls,
        description: this.translate.description,
        solutions: this.translate.solutionId,
        tags: this.translate.tags
      };
    }
  }),
  mounted: function mounted() {
    var _this = this;
    this.getData().then(function () {
      return _this.loadDataFromQuery();
    });
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      this.isLoaded = false;
      return this.getControls().then(this.getMotherControls).then(function () {
        return _this2.isLoaded = true;
      });
    },
    getControls: function getControls() {
      var _this3 = this;
      return _getControls('?filter[]=inactive&filter[]=pending').then(function (_ref) {
        var list = _ref.list;
        return _this3.motherControlsByPath = _this3.prepareMotherControls(list);
      });
    },
    prepareMotherControls: function prepareMotherControls(controls) {
      var motherControls = {};
      controls.forEach(function (control) {
        if (!control.mothercontrolId) return;
        if (!motherControls[control.mothercontrolId]) motherControls[control.mothercontrolId] = [];
        motherControls[control.mothercontrolId].push(control);
      });
      return motherControls;
    },
    getMotherControls: function getMotherControls() {
      var _this4 = this;
      var motherParams = this.showDeactivated ? '?includeInactive=true' : '';
      return getAllMotherControls(motherParams).then(function (_ref2) {
        var list = _ref2.list;
        var enhancedMotherControls = _this4.getEnhancedMotherControls(list);
        _this4.dataset = _this4.prepareDataset(enhancedMotherControls);
        _this4.enhanceDataset(_this4.dataset, _this4.columns);
      });
    },
    getEnhancedMotherControls: function getEnhancedMotherControls(list) {
      var _this5 = this;
      return list.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          daughterControls: _this5.getSortedDaughterControls(_this5.motherControlsByPath[item.path])
        });
      });
    },
    prepareDataset: function prepareDataset(controls) {
      var _this6 = this;
      return controls.map(function (control) {
        var _control$daughterCont;
        var daughterControls = ((_control$daughterCont = control.daughterControls) === null || _control$daughterCont === void 0 ? void 0 : _control$daughterCont.map(function (c) {
          return c.cpath + ' ' + c.title;
        }).join(', ')) || '';
        return {
          cpath: {
            field: control.cpath
          },
          title: {
            activity: control,
            field: control.title
          },
          description: {
            field: control.description
          },
          daughterControls: {
            controls: control.daughterControls,
            field: daughterControls
          },
          solutions: {
            field: findPropsByIds(control.solutionIds, _this6.solutions, 'title')
          },
          tags: {
            field: findPropsByIds(control.tagIds, _this6.tags, 'tag')
          }
        };
      });
    },
    getSortedDaughterControls: function getSortedDaughterControls(controls) {
      return controls && sortByIndex(_toConsumableArray(controls), 'path');
    },
    toggleDeactivated: function toggleDeactivated() {
      var _this7 = this;
      clearTimeout(this.toggleDeactivatedTimer);
      this.toggleDeactivatedTimer = setTimeout(function () {
        _this7.getData();
      }, 300);
    },
    loadDataFromQuery: function loadDataFromQuery() {
      var _this$$route$query,
        _this8 = this;
      var query = (_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.query;
      if (!query) return;
      this.$nextTick(function () {
        _this8.applySettingsFromLoadedReport(JSON.parse(query));
      });
    }
  }
};