function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import * as ChangelogAPI from '@/api/risma/changelog';
import * as RiskApi from '@/api/risk/risk';
import { getCurrentScoring as _getCurrentScoring } from '@/api/risk/scoring';
import { getAllDomains, getAllGapSchemas } from '@/api/compliance/gapschema';
import { getAllQuestionsIncludingHidden } from '@/api/compliance/questionnaire';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import HierarchicalList from '@/components/Molecules/HierarchicalList';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { RiskUniversalMixin } from '@/components/Pages/Risks/RiskUniversalMixin';
import { capitalize as _capitalize } from '@/utils';
import * as Utils from '@/utils/Utils';
import { toLocalDateTimeFull } from '@/utils/date';
import { getProjectDescriptionLabel, getProjectPurposeLabel, getProjectStatusLabel } from '@/utils/Compliance/complianceProject';
import { RiskModuleTense } from '@/constants/risks/RiskModuleTense';
import { getModuleFromObjType, ObjectTypes } from '@/constants/ObjectTypes';
import { getTranslatables, ChangelogColumns } from '@/constants/datatable/Translatables';
import FieldRendererMapping from '@/constants/datatable/FieldRendererMapping';
import { MODULES } from '@/constants/modules';
import { getIntervalTitleByValue } from '@/constants/contracts/contract';
import { RiskProjects } from '@/constants/risks/RiskProjects';
import { saveStatus as _saveStatus } from '@/constants/SaveStatuses';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { gapFlags } from '@/constants/compliance/GapTypes';
import { questionTypesById } from '@/constants/compliance/QuestionTypes';
export default {
  name: 'Changelog',
  components: {
    RismaDatatable: RismaDatatable,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Trafficlight: Trafficlight,
    HierarchicalList: HierarchicalList
  },
  mixins: [RaciTitleMixin, RismaDatatableDefaultMixin, RiskUniversalMixin],
  props: {
    data: {
      type: Object,
      required: true,
      default: function _default() {},
      Note: 'The activity to fetch changelog for'
    },
    saveStatus: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['getActivityData'],
  data: function data() {
    return {
      title: 'Changelog',
      dataset: [],
      pageLoaded: false,
      slotDataFields: ['old_value', 'new_value'],
      fieldTypes: {
        TRAFFIC_LIGHT: 'trafficLight',
        HIERARCHICAL_LIST: 'hierarchicalList'
      },
      likelihoodValues: [],
      managementLevels: [],
      gapDomains: [],
      isDirty: false,
      trafficLightFields: ['trafficLight', 'traffic_light', 'severity', 'analysis_rag', 'vat_status'],
      translate: getTranslate['Changelog'](),
      questionTypesById: questionTypesById(),
      questions: [],
      allGapSchemas: []
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserComplianceProjectById: 'complianceProjectById'
  })), mapState(useActivityTypesStore, {
    customActivities: 'complianceIACustomActivityTypes',
    activityTypes: 'activityTypes'
  })), {}, {
    complianceProject: function complianceProject() {
      if (this.module === MODULES.COMPLIANCE) {
        return this.currentUserComplianceProjectById(this.data.compliance_projects_id);
      }
      return {};
    },
    module: function module() {
      return getModuleFromObjType(this.data.objType);
    },
    preparedColumns: function preparedColumns() {
      return {
        type: this.translate.type,
        title: this.translate.title,
        old_value: this.translate.from,
        new_value: this.translate.to,
        show_user: this.translate.user,
        created: this.translate.date
      };
    },
    translatables: function translatables() {
      return getTranslatables(this.module);
    },
    cTable2DefaultTitle: function cTable2DefaultTitle() {
      var _ObjectTypes$EXECUTIO;
      return (_ObjectTypes$EXECUTIO = {}, _defineProperty(_ObjectTypes$EXECUTIO, ObjectTypes.EXECUTION_INITIATIVE, 'Action'), _defineProperty(_ObjectTypes$EXECUTIO, ObjectTypes.RISK_RISK, 'Risk'), _defineProperty(_ObjectTypes$EXECUTIO, ObjectTypes.INCIDENTS_INCIDENT, 'Incident'), _defineProperty(_ObjectTypes$EXECUTIO, ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE, 'Process'), _defineProperty(_ObjectTypes$EXECUTIO, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, 'Controller'), _defineProperty(_ObjectTypes$EXECUTIO, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, 'System'), _defineProperty(_ObjectTypes$EXECUTIO, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, 'Processor'), _defineProperty(_ObjectTypes$EXECUTIO, ObjectTypes.INFORMATION_ASSET, 'Asset'), _defineProperty(_ObjectTypes$EXECUTIO, ObjectTypes.CONTROLS_CONTROL, 'Control'), _defineProperty(_ObjectTypes$EXECUTIO, ObjectTypes.COMPLIANCE_NODE, 'Compliance'), _defineProperty(_ObjectTypes$EXECUTIO, ObjectTypes.CONTRACT, 'Contract'), _defineProperty(_ObjectTypes$EXECUTIO, ObjectTypes.COMPANY, 'Company'), _defineProperty(_ObjectTypes$EXECUTIO, ObjectTypes.COMPLIANCE_QUESTIONNAIRE, 'Questionnaire'), _ObjectTypes$EXECUTIO)[this.data.objType];
    },
    filteredActivityTypes: function filteredActivityTypes() {
      var _this = this;
      return this.activityTypes.filter(function (activityType) {
        return activityType.module === _this.module;
      });
    },
    activityTypesNamesByIds: function activityTypesNamesByIds() {
      var entries = new Map(this.filteredActivityTypes.map(function (at) {
        return [at.id, at.label];
      }));
      return Object.fromEntries(entries);
    },
    currentContractActivityType: function currentContractActivityType() {
      var _this2 = this;
      return this.filteredActivityTypes.find(function (at) {
        return +at.id === +_this2.data.activityTypeId;
      }) || {};
    },
    path: function path() {
      return this.data.rno;
    },
    projectId: function projectId() {
      return this.data.projectId;
    },
    periodId: function periodId() {
      return this.data.projectPeriodId;
    },
    isRiskModule: function isRiskModule() {
      return this.module === MODULES.RISK;
    },
    isErmRiskProject: function isErmRiskProject() {
      var _this$data;
      return +((_this$data = this.data) === null || _this$data === void 0 || (_this$data = _this$data.project) === null || _this$data === void 0 ? void 0 : _this$data.type) === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type;
    }
  }),
  watch: {
    saveStatus: function saveStatus(newValue, oldValue) {
      if (oldValue === _saveStatus.SAVING && newValue === _saveStatus.SAVED) {
        this.getChangelogData();
      }
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    capitalize: function capitalize(value) {
      return _capitalize(value);
    },
    isNormalField: function isNormalField(field) {
      return _typeof(field) !== 'object';
    },
    getData: function getData() {
      var _this3 = this;
      var promises = [];
      if (this.isRiskModule) {
        promises.push(this.getRiskProjects(), this.getRiskTypes());
      }
      if (this.module === MODULES.COMPLIANCE_GAP_SCHEMA) {
        promises.push(this.getGapDomains());
      }
      if (this.module === MODULES.COMPLIANCE_QUESTIONNAIRE) {
        promises.push(this.getQuestions());
        promises.push(this.getGapSchemas());
      }
      return Promise.all(promises).then(function () {
        if (!_this3.isRiskModule) return Promise.resolve();
        var currentProject = _this3.riskProjects.find(function (pj) {
          return +pj.id === +_this3.projectId;
        });
        var addedPromises = [];
        if (+currentProject.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type) {
          addedPromises.push(_this3.getCurrentScoring());
          addedPromises.push(_this3.getRiskManagementLevels());
        }
        return Promise.all(addedPromises);
      }).then(this.prepareRiskData).then(this.getChangelogData);
    },
    getChangelogData: function getChangelogData() {
      var _this4 = this;
      this.pageLoaded = false;
      return this.handleFetchChangelogs(this.module, this.data.id).then(function (resp) {
        if (_this4.module === MODULES.EXECUTION) _this4.checkListForCreated(resp);
        _this4.dataset = resp;
        _this4.enhanceDataset(_this4.dataset, _this4.preparedColumns);
        _this4.pageLoaded = true;
      });
    },
    getCurrentScoring: function getCurrentScoring() {
      var _this5 = this;
      return _getCurrentScoring().then(function (_ref) {
        var likelihood_values = _ref.likelihood_values;
        return _this5.likelihoodValues = likelihood_values;
      });
    },
    getRiskManagementLevels: function getRiskManagementLevels() {
      var _this6 = this;
      return RiskApi.getRisksManagementLevels().then(function (_ref2) {
        var list = _ref2.list;
        return _this6.managementLevels = list;
      });
    },
    getGapDomains: function getGapDomains() {
      var _this7 = this;
      return getAllDomains().then(function (_ref3) {
        var list = _ref3.list;
        return _this7.gapDomains = list;
      });
    },
    getQuestions: function getQuestions() {
      var _this8 = this;
      return getAllQuestionsIncludingHidden(this.$route.params.questionnaireId).then(function (_ref4) {
        var questions = _ref4.questions;
        return _this8.questions = questions;
      });
    },
    getGapSchemas: function getGapSchemas() {
      var _this9 = this;
      return getAllGapSchemas('data[]=all').then(function (_ref5) {
        var list = _ref5.list;
        return _this9.allGapSchemas = list;
      });
    },
    prepareRiskData: function prepareRiskData() {
      if (this.isRiskModule) {
        this.prepareData();
        this.afterPrepareData();
      }
    },
    handleFetchChangelogs: function handleFetchChangelogs(type, id) {
      var _this10 = this;
      return ChangelogAPI.getChangelog(type, id).then(function (_ref6) {
        var list = _ref6.list;
        return list.filter(function (row) {
          return row.column !== ChangelogColumns.FLAGS || _this10.module !== MODULES.COMPLIANCE_QUESTIONNAIRE;
        }) // hide flags fields. adjust when RIS-12086 is ready
        .map(function (row) {
          var type = _this10.getRowType(row);
          var title = _this10.translatablesWithRaci(row.column);
          var new_value = _this10.prepareValue(row, 'new_value');
          var old_value = _this10.prepareValue(row, 'old_value');
          var created = _this10.getRowCreated(row);
          return {
            type: {
              field: _this10.checkDefined(type)
            },
            title: {
              field: _this10.checkDefined(title)
            },
            old_value: {
              field: _this10.checkDefined(old_value)
            },
            new_value: {
              field: _this10.checkDefined(new_value)
            },
            show_user: {
              field: _this10.checkDefined(row.show_user)
            },
            created: {
              field: _this10.checkDefined(created)
            }
          };
        });
      });
    },
    getRowType: function getRowType(row) {
      var cTable2 = row.ctable2 || this.cTable2DefaultTitle;
      var type = this.translatables[cTable2] || cTable2;
      if (cTable2 === MODULES.dataFlow) type += " (".concat(row.cid2, ")");
      if (cTable2 === MODULES.COMPLIANCE_QUESTIONS) {
        var _this$questions$find;
        var questionNumber = (_this$questions$find = this.questions.find(function (q) {
          return +q.id === +[row.cid2];
        })) === null || _this$questions$find === void 0 ? void 0 : _this$questions$find.questionnumber;
        type = questionNumber ? type + " ".concat(questionNumber) : this.translate.deletedQuestion;
      }
      if (cTable2 === MODULES.gapAnalysis && this.data.objType === ObjectTypes.COMPLIANCE_NODE) {
        type = this.getGapAnalysisType(type, row);
      }
      return type;
    },
    getRowCreated: function getRowCreated(row) {
      return toLocalDateTimeFull(row.created);
    },
    checkListForCreated: function checkListForCreated(list) {
      var _this11 = this;
      var creator = this.users.find(function (user) {
        return user.id === _this11.data.createdBy;
      });
      var time = this.data.created;
      var item = {
        created: {
          field: toLocalDateTimeFull(time)
        },
        old_value: {
          field: ''
        },
        new_value: {
          field: ''
        },
        title: {
          field: this.translate.created
        },
        show_user: {
          field: creator && creator.display_name || ''
        },
        type: {
          field: this.cTable2DefaultTitle
        }
      };
      list.push(item);
    },
    prepareValue: function prepareValue(row, key) {
      if (row.column.startsWith('likelihood') || row.column.startsWith('impact')) {
        return row[key] || '0';
      }
      if (row.column === ChangelogColumns.STATUS && row.module === MODULES.RISK) {
        return row[key] === '1' ? this.translate.closed : this.translate.open;
      }
      if (row.column === ChangelogColumns.TREE) {
        if (JSON.parse(row[key])) {
          return {
            field: JSON.parse(row[key]),
            fieldType: this.fieldTypes.HIERARCHICAL_LIST
          };
        }
      }
      if (row.column === ChangelogColumns.ACTIVITY_TYPE_ID_V1 || row.column === ChangelogColumns.ACTIVITY_TYPE_ID_V2) {
        var isPreparedValue = isNaN(row[key]);
        var value = isPreparedValue ? row[key] : this.activityTypesNamesByIds[row[key]];
        return value || '';
      }
      if (row.column === ChangelogColumns.PARENT_ID && this.module === MODULES.COMPLIANCE_IA_SYSTEM) {
        return Utils.findValueById(this.data.systems, +row[key], 'title');
      }
      if (row.column === ChangelogColumns.AUTO_RENEW || row.column === ChangelogColumns.ARCHIVED || row.column === ChangelogColumns.CONFIDENTIAL || row.column === ChangelogColumns.IS_ARTICLE_30 || row.column === ChangelogColumns.ALLOW_NEW_ANSWER_OPTION || row.column === ChangelogColumns.HIDDEN) {
        return row[key] && JSON.parse(row[key]) ? this.translate.yes : this.translate.no;
      }
      if (row.column === ChangelogColumns.SYNC_WITH_EXTERNAL || row.column === ChangelogColumns.SELECTABLE) {
        return +row[key] ? this.translate.enabled : this.translate.disabled;
      }
      if (row.column === ChangelogColumns.PAYMENT_INTERVAL) {
        return row[key] && getIntervalTitleByValue(row[key]);
      }
      if (row.column === ChangelogColumns.RISK_VULNERABILITIES_ID) {
        return Utils.findValueById(this.vulnerabilities, +row[key], 'label');
      }
      if (row.column === ChangelogColumns.RISK_THREATS_ID) {
        return Utils.findValueById(this.threats, +row[key], 'label');
      }
      if (row.column === ChangelogColumns.RISK_THREAT_LEVELS_ID) {
        return Utils.findValueById(this.levelOfThreats, +row[key], 'label');
      }
      if (row.column === ChangelogColumns.LIKELIHOOD_SCORE) {
        return this.likelihoodValues[row[key]];
      }
      if (row.column === ChangelogColumns.APPROVED_BY || row.column === ChangelogColumns.DAILY_OWNER || row.column === ChangelogColumns.PRIMARY_OWNER || row.column === ChangelogColumns.USER_ID) {
        return Utils.findValueById(this.users, +row[key], 'display_name');
      }
      if (row.column === ChangelogColumns.APPROVAL_STATUS) {
        return +row[key] ? this.translate.approved : this.translate.unapproved;
      }
      if (row.column === ChangelogColumns.APPROVAL_DATE || row.dataType === ChangelogColumns.DATE) {
        return toLocalDateTimeFull(row[key]);
      }
      if (row.column === ChangelogColumns.RISK_TYPE) {
        return Utils.findValueById(this.riskTypes, +row[key], 'title');
      }
      if (row.column === ChangelogColumns.ORGANIZATIONAL_UNIT) {
        return Utils.findPropsByIds(row[key].split(','), this.organisations, 'visible_name');
      }
      if (row.column === ChangelogColumns.MANAGEMENT_LEVEL) {
        return Utils.findValueById(this.managementLevels, +row[key], 'name');
      }
      if (row.column === ChangelogColumns.FREQUENCY) {
        return RismaControlsUtils.frequency2text(+row[key]) || row[key];
      }
      if (row.column === ChangelogColumns.DOMAIN_ID) {
        return Utils.findValueById(this.gapDomains, +row[key], 'label');
      }
      if (row.column === ChangelogColumns.FLAGS) {
        var _Object$values$find;
        return ((_Object$values$find = Object.values(gapFlags(this.$trans)).find(function (flag) {
          return flag.value === +row[key];
        })) === null || _Object$values$find === void 0 ? void 0 : _Object$values$find.label) || '';
      }
      if (row.column.endsWith(": ".concat(ChangelogColumns.PINNED))) {
        return row[key] === '1' ? this.translate.pinned : this.translate.notPinned;
      }
      if (row.column === ChangelogColumns.INFORMATION_ASSETS_ID) {
        var _this$customActivitie, _this$customActivitie2;
        return (_this$customActivitie = (_this$customActivitie2 = this.customActivities.find(function (at) {
          return +at.id === +row[key];
        })) === null || _this$customActivitie2 === void 0 ? void 0 : _this$customActivitie2.label) !== null && _this$customActivitie !== void 0 ? _this$customActivitie : '';
      }
      if (row.column === ChangelogColumns.DEFAULT_GAP_SCHEMA) {
        var _this$allGapSchemas$f;
        return (_this$allGapSchemas$f = this.allGapSchemas.find(function (gap) {
          return +gap.id === +row[key];
        })) === null || _this$allGapSchemas$f === void 0 ? void 0 : _this$allGapSchemas$f.title;
      }
      if (this.module === MODULES.COMPLIANCE_QUESTIONNAIRE) {
        if (row.column === 'deleted' && key === 'new_value') return '';
        if (row.column === 'created' && key === 'old_value') return '';
      }
      if (!row[key]) return '';
      var fieldRendererKey = row.dataType || row.column;
      var field = FieldRendererMapping[fieldRendererKey] && FieldRendererMapping[fieldRendererKey](row[key]) || row[key];
      if (this.trafficLightFields.includes(fieldRendererKey) && _typeof(field) === 'object') {
        field = _objectSpread(_objectSpread({}, field), {}, {
          fieldType: this.fieldTypes.TRAFFIC_LIGHT
        });
        if (row.column === ChangelogColumns.VAT_STATUS) {
          field.trafficLightSuffix = ": ".concat(this.$trans(field.label));
        }
      }
      if (row.column === ChangelogColumns.TAGS && this.isErmRiskProject) {
        return Utils.findPropsByIds(row[key].split(','), this.tags, 'tag');
      }
      if (row.column === ChangelogColumns.QUESTION_TYPE) {
        return this.questionTypesById[row[key]];
      }
      if (row.column === ChangelogColumns.DEPENDENCIES) {
        return Object.entries(JSON.parse(row[key])).reduce(function (acc, _ref7) {
          var _ref8 = _slicedToArray(_ref7, 2),
            key = _ref8[0],
            value = _ref8[1];
          return "".concat(acc ? acc + '<br>' : '').concat(key, ": ").concat(value.join(', '));
        }, '');
      }
      return field;
    },
    checkDefined: function checkDefined(field) {
      return field ? field : '';
    },
    translatablesWithRaci: function translatablesWithRaci(column) {
      if (column === ChangelogColumns.RESPONSIBLE) {
        return this.currentContractActivityType.responsibleLabel || this.responsibleTitle(this.isRACIUsed(this.data.objType));
      }
      if (column === ChangelogColumns.ACCOUNTABLE) {
        return this.currentContractActivityType.accountableLabel || this.accountableTitle(this.isRACIUsed(this.data.objType));
      }
      if (column === ChangelogColumns.CONSULTED) {
        return this.currentContractActivityType.consultedLabel || this.consultedTitle(this.isRACIUsed(this.data.objType));
      }
      if (column === ChangelogColumns.INFORMED) {
        return this.currentContractActivityType.informedLabel || this.informedTitle(this.isRACIUsed(this.data.objType));
      }
      if (column.startsWith('likelihood')) {
        var tense = RiskModuleTense[column.split('-')[1] - 1]; //risk_index + 1 saves to changelog
        return "".concat(this.translate.likelihood, " ").concat(tense ? "(".concat(this.$trans(tense), ")") : '');
      }
      if (column.startsWith('impact')) {
        var _tense = RiskModuleTense[column.split('-')[1] - 1]; //risk_index + 1 saves to changelog
        return "".concat(this.translate.impact, " ").concat(_tense ? "(".concat(this.$trans(_tense), ")") : '');
      }
      if (this.module === MODULES.COMPLIANCE && column === ChangelogColumns.DESCRIPTION) {
        return getProjectDescriptionLabel(this.complianceProject);
      }
      if (this.module === MODULES.COMPLIANCE && column === ChangelogColumns.STATUS) {
        return getProjectStatusLabel(this.complianceProject);
      }
      if (this.module === MODULES.COMPLIANCE && column === ChangelogColumns.PURPOSE) {
        return getProjectPurposeLabel(this.complianceProject);
      }
      return this.translatables[column] || column;
    },
    getGapAnalysisType: function getGapAnalysisType(title, row) {
      var styledTitle = "<div class=\"font-semibold\">".concat(row.title, "</div>");
      return title + '\n' + styledTitle;
    },
    handleDirty: function handleDirty(data) {
      if (this.data.id === data.id) {
        this.isDirty = true;
      }
    },
    updateFromPreview: function updateFromPreview() {
      if (this.isDirty) {
        this.$router.go(0);
      }
    }
  }
};