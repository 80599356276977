function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import Notification from '@/components/Molecules/Notification';
import { cacheItem } from '@/routes/storeCache';
import { sendEmail } from '@/api/rismaadmin/email';
import { patchUtilSettings } from '@/api/risma/settings';
import { isEmailValid } from '@/utils/validations';
export default {
  name: 'EmailSetup',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    Notification: Notification,
    RismaToggle: RismaToggle
  },
  data: function data() {
    return {
      email: '',
      okmessage: '',
      error: '',
      recipientEmail: '',
      timer: 0,
      loading: false,
      translate: getTranslate['EmailSetup']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    smtp_host: function smtp_host() {
      return this.getSettingValue('risma.smtp.host');
    },
    smtp_port: function smtp_port() {
      return this.getSettingValue('risma.smtp.port');
    },
    smtp_user: function smtp_user() {
      return this.getSettingValue('risma.smtp.user');
    },
    smtp_encryption: function smtp_encryption() {
      return this.getSettingValue('risma.smtp.encryption');
    },
    smtp_disable_delivery: function smtp_disable_delivery() {
      return this.getSettingValue('risma.smtp.disable_delivery');
    },
    overrideRecipientEmail: function overrideRecipientEmail() {
      return this.getSettingValue('risma.override_recipient_email');
    }
  }),
  mounted: function mounted() {
    this.recipientEmail = this.overrideRecipientEmail;
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useSettingsStore, {
    loadSettings: 'load'
  })), {}, {
    updateSettingsValue: function updateSettingsValue(value) {
      return this.patchSetting(_defineProperty({}, 'risma.smtp.disable_delivery', value ? 0 : 1));
    },
    patchSetting: function patchSetting(settingsData) {
      var _this = this;
      return patchUtilSettings(settingsData).then(function () {
        return cacheItem('settings', function () {
          return _this.loadSettings();
        }, 180);
      });
    },
    sendTestEmail: function sendTestEmail() {
      var _this2 = this;
      if (!isEmailValid(this.email)) {
        this.error = this.translate.invalidEmailAddress;
        return;
      }
      sendEmail(this.email).then(function (response) {
        _this2.error = '';
        _this2.okmessage = 'Server response: ' + response;
      }).catch(function (response) {
        _this2.okmessage = '';
        _this2.error = 'Server failed with this message: ' + response.response.error;
      });
    },
    updateEmail: function updateEmail(a) {
      var _this3 = this;
      var timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.$debounceTimeout / 2;
      clearTimeout(this.timer);
      if (this.recipientEmail === this.overrideRecipientEmail) return;
      this.timer = setTimeout(function () {
        _this3.patchSetting(_defineProperty({}, 'risma.override_recipient_email', _this3.recipientEmail));
      }, timeout);
    }
  })
};