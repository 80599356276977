function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getTranslate } from './translate';
import { getDocumentTypes as _getDocumentTypes, updateDocumentType, createDocumentType, deleteDocumentType } from '@/api/admin/documentTypes';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Notification from '@/components/Molecules/Notification';
import Alert from '@/components/Molecules/Modal/Alert';
import StaticTable from '@/components/Molecules/StaticTable';
import DocumentTypeModal from '@/components/Molecules/Modal/DocumentTypeModal';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import { DocumentTypesIds } from '@/constants/contracts/DocumentTypes';
import { compareStrings } from '@/utils/compare';
export default {
  name: 'AdminDocumentTypes',
  description: 'Admin Document Types page',
  components: {
    NotificationPopUp: NotificationPopUp,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    MainWell: MainWell,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    StaticTable: StaticTable,
    DocumentTypeModal: DocumentTypeModal,
    Alert: Alert,
    Notification: Notification
  },
  mixins: [NotificationPopUpMixin],
  data: function data() {
    return {
      documentTypes: [],
      loading: true,
      showDocTypeModal: false,
      documentSelected: {},
      docTypeIdToDelete: null,
      docTypeNameToDelete: null,
      notificationMessage: '',
      notificationType: 'success',
      translate: getTranslate['AdminDocumentTypes']()
    };
  },
  computed: {
    columns: function columns() {
      return [this.translate.title, this.translate.edit, this.translate.delete];
    },
    documentTypesSorted: function documentTypesSorted() {
      return _toConsumableArray(this.documentTypes).sort(function (a, b) {
        return compareStrings(a.name, b.name);
      });
    },
    dataset: function dataset() {
      var typesWithLimitedActions = [DocumentTypesIds.MAIN, DocumentTypesIds.DPA];
      return this.documentTypesSorted.map(function (item) {
        var isLimited = typesWithLimitedActions.indexOf(item.id) !== -1;
        return {
          title: item.name,
          edit: {
            item: isLimited ? null : item
          },
          delete: {
            id: isLimited || item.inUse ? null : item.id,
            name: item.name
          }
        };
      });
    }
  },
  mounted: function mounted() {
    this.getDocumentTypes();
  },
  methods: {
    getDocumentTypes: function getDocumentTypes() {
      var _this = this;
      return _getDocumentTypes().then(function (_ref) {
        var list = _ref.list;
        _this.documentTypes = _this.modifyDocumentTypes(list);
      }).finally(function () {
        _this.loading = false;
      });
    },
    modifyDocumentTypes: function modifyDocumentTypes(docTypes) {
      var _this2 = this;
      return docTypes.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          name: _this2.$trans(item.name)
        });
      });
    },
    toggleEditModal: function toggleEditModal(docType) {
      this.showDocTypeModal = true;
      this.docTypeSelected = docType;
    },
    toggleCreateModal: function toggleCreateModal() {
      this.showDocTypeModal = true;
      this.docTypeSelected = {};
    },
    acceptDocTypeModal: function acceptDocTypeModal(data) {
      if (data.id) {
        return this.updateDocType(data);
      }
      return this.createDocType(data);
    },
    updateDocType: function updateDocType(data) {
      var _this3 = this;
      this.resetNotification();
      return updateDocumentType(data.id, {
        name: data.name
      }).then(function (response) {
        _this3.showSuccessPopup(_this3.translate.documentTypeUpdated, response.name);
        var docTypeIndex = _this3.documentTypes.findIndex(function (item) {
          return item.id === data.id;
        });
        _this3.documentTypes[docTypeIndex] = _objectSpread(_objectSpread({}, _this3.documentTypes[docTypeIndex]), response);
      }).catch(function (error) {
        var _error$response;
        _this3.showNotification(_this3.$trans(error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.error), 'error');
      }).finally(function () {
        return _this3.dismissModal();
      });
    },
    createDocType: function createDocType(data) {
      var _this4 = this;
      this.resetNotification();
      return createDocumentType(data).then(function (response) {
        _this4.documentTypes.push(response);
        _this4.showSuccessPopup(_this4.translate.documentTypeCreated, response.name);
      }).catch(function (error) {
        var _error$response2;
        _this4.showNotification(_this4.$trans(error === null || error === void 0 || (_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.error), 'error');
      }).finally(function () {
        return _this4.dismissModal();
      });
    },
    resetNotification: function resetNotification() {
      this.notificationMessage = '';
    },
    toggleDeleteModal: function toggleDeleteModal(id, name) {
      this.docTypeIdToDelete = id;
      this.docTypeNameToDelete = name;
    },
    dismissModal: function dismissModal() {
      this.showDocTypeModal = false;
      this.docTypeSelected = {};
    },
    handleAcceptAlert: function handleAcceptAlert() {
      var _this5 = this;
      this.resetNotification();
      return deleteDocumentType(this.docTypeIdToDelete).then(function () {
        _this5.showSuccessPopup(_this5.translate.documentTypeDeleted, _this5.docTypeNameToDelete);
        _this5.documentTypes = _this5.documentTypes.filter(function (item) {
          return item.id !== _this5.docTypeIdToDelete;
        });
        _this5.docTypeIdToDelete = null;
        _this5.docTypeNameToDelete = null;
      }).catch(function (error) {
        var _error$response3;
        _this5.showNotification(_this5.$trans(error === null || error === void 0 || (_error$response3 = error.response) === null || _error$response3 === void 0 ? void 0 : _error$response3.error), 'error');
      }).finally(function () {
        _this5.dismissAlert();
      });
    },
    showNotification: function showNotification(message, type) {
      this.notificationType = type;
      this.notificationMessage = message;
    },
    dismissAlert: function dismissAlert() {
      this.docTypeIdToDelete = null;
    },
    showSuccessPopup: function showSuccessPopup(header, message) {
      this.setNotificationData({
        type: 'success',
        header: header,
        message: message
      });
      this.turnAfterRefreshNotification();
    }
  }
};