export var gapTypes = {
  NOT_SET: 0,
  NO_GAP: 1,
  LOW_GAP: 2,
  MEDIUM_GAP: 3,
  HIGH_GAP: 4,
  GAP_NA: 5
};
export var gapFlags = function gapFlags($trans) {
  return {
    OUTSOURCING_RISK_ASSESSMENT: {
      value: 1,
      label: $trans('Outsourcing risk assessment')
    },
    OUTSOURCING_PRELIMINARY_ANALYSIS: {
      value: 2,
      label: $trans('Outsourcing preliminary analysis')
    }
  };
};