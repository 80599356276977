import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InfoBox from '@/components/Atoms/Containers/InfoBox';
export default {
  name: 'LinksBox',
  introduction: 'LinksBox - displays a list of links',
  description: 'This component displays a list of links',
  token: "<links-box :links=\"[\n      {\n          title: 'Some title',\n          subtitlex: 'is not required field',\n          url: '/link/to/the/page'\n      }\n    ]\" />",
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    InfoBox: InfoBox
  },
  props: {
    links: {
      required: true,
      type: Array
    },
    title: {
      type: String,
      default: ''
    }
  }
};