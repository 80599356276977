import { getTranslate } from './translate';
import InputField from '@/components/Atoms/Inputs/InputField';
import Modal from '@/components/Molecules/Modal/Modal';
import Notification from '@/components/Molecules/Notification';
export default {
  name: 'CvrModal',
  token: "<cvr-modal\n      v-if=\"showModal\"\n      modal-data=\"modal-data\" />",
  components: {
    InputField: InputField,
    Modal: Modal,
    Notification: Notification
  },
  props: {
    modalData: {
      required: true,
      type: Object,
      default: function _default() {},
      note: 'modal data'
    },
    errorMessage: {
      required: false,
      type: String,
      default: ''
    }
  },
  emits: ['accept', 'dismiss'],
  data: function data() {
    return {
      translate: getTranslate['CvrModal']()
    };
  }
};