import { UserLevels } from '@/constants/UserLevels';
function buildContractsMenu(user) {
  var menuStructure = [];
  menuStructure.push({
    url: '/contracts/overview/my',
    title: 'My contracts',
    rights: true
  });
  menuStructure.push({
    url: '/contracts/overview/all',
    title: 'Overview',
    rights: true
  });
  menuStructure.push({
    url: '/contracts/reports',
    title: 'Reports',
    rights: user.level_contracts >= UserLevels.PRIVILEGED || user.is_admin
  });
  menuStructure.push({
    url: '/contracts/dashboard',
    title: 'Dashboard',
    rights: user.level_contracts >= UserLevels.PRIVILEGED || user.is_admin
  });
  return menuStructure;
}
export default buildContractsMenu;