function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { mapToCommaList } from '@/utils/format/mapTo';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { Colors } from '@/Colors';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { sortAlphabetic } from '@/utils/sort';
import { destroy } from '@/api/compliance/informationAssets';
import Alert from '@/components/Molecules/Modal/Alert';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import StaticTable from '@/components/Molecules/StaticTable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import * as Utils from '@/utils/Utils';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { InformationAssetsDeleteMixin } from '@/components/Pages/InformationAssets/InformationAssetsDeleteMixin';
export default {
  name: 'InformationAssetsSimpleView',
  components: {
    FeatherIcon: FeatherIcon,
    StaticTable: StaticTable,
    PreviewModalLink: PreviewModalLink,
    Trafficlight: Trafficlight,
    Alert: Alert
  },
  mixins: [InformationAssetsDeleteMixin],
  props: {
    typeId: {
      type: [Number, String],
      required: false,
      default: null,
      note: 'Id of information asset type'
    },
    informationAssets: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of information assets'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of all users'
    },
    activityType: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'current Activity type, needed for custom IA'
    }
  },
  emits: ['refreshData'],
  data: function data() {
    return {
      hoverTitles: {
        actions: 'delete'
      },
      deleteIA: null,
      updated: false,
      translate: getTranslate['InformationAssetsSimpleView']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    informationAssetsFiltered: function informationAssetsFiltered() {
      return this.informationAssets;
    },
    systemsEnabled: function systemsEnabled() {
      return this.getSettingValue('feature.enable_systems') == undefined || this.getSettingValue('feature.enable_systems');
    },
    columns: function columns() {
      var systemsColumn = [];
      switch (this.typeId) {
        case AssetsTypes.PROCESSORS:
          systemsColumn = this.systemsEnabled ? [this.translate.systems] : [];
          return ['', this.translate.id, this.translate.name, this.translate.description, this.translate.responsible, this.translate.completionDegreeIn].concat(_toConsumableArray(systemsColumn), [this.translate.processes, '']);
        case AssetsTypes.SYSTEMS:
          return ['', this.translate.id, this.translate.name, this.translate.responsible, this.translate.completionDegreeIn, this.translate.processors, this.translate.processes, ''];
        case AssetsTypes.CONTROLLERS:
          return ['', this.translate.id, this.translate.name, this.translate.responsible, this.translate.processes, ''];
        default:
          return ['', this.translate.name, this.translate.responsible, this.translate.processes, ''];
      }
    },
    dataset: function dataset() {
      switch (this.typeId) {
        case AssetsTypes.CONTROLLERS:
          return this.getControllersData();
        case AssetsTypes.PROCESSORS:
          return this.getProcessorsData();
        case AssetsTypes.SYSTEMS:
          return this.getSystemsData();
        default:
          return this.getInformationAssetsData();
      }
    }
  }),
  methods: {
    getControllersData: function getControllersData() {
      var _this = this;
      return this.informationAssetsFiltered.map(function (item) {
        var trafficLight = Colors.trafficlightColor(item.trafficLight, true);
        return {
          trafficlight: {
            color: trafficLight.color
          },
          id: item.uniqueId,
          name: {
            data: getFieldsFromObjectForPreview(item),
            name: item.name
          },
          responsible: _this.getResponsible(item.responsibleUserIds),
          processes: item.complianceNodeCount > 0 ? item.complianceNodeCount : 0,
          actions: {
            objType: _this.typeId,
            id: item.id,
            removable: !item.complianceNodeCount
          }
        };
      });
    },
    getProcessorsData: function getProcessorsData() {
      var _this2 = this;
      var result = [];
      this.informationAssetsFiltered.forEach(function (item) {
        var _item$questionnaires;
        if (item.parents.length > 0) {
          return false;
        }
        var trafficLight = Colors.trafficlightColor(item.trafficLight, true);
        var item_data = {
          trafficlight: {
            color: trafficLight.color
          },
          id: item.uniqueId,
          name: {
            data: getFieldsFromObjectForPreview(item),
            name: item.name
          },
          description: Utils.truncate(item.description, 200) || '',
          responsible: _this2.getResponsible(item.responsibleUserIds),
          completionDegree: (_item$questionnaires = item.questionnaires) !== null && _item$questionnaires !== void 0 && _item$questionnaires[0] ? item.questionnaires[0].questionnaireAnsweredPercentage + ' %' : '-',
          systems: item.systems ? item.systems.length : 0,
          processes: item.complianceNodeCount,
          actions: {
            objType: _this2.typeId,
            id: item.id,
            removable: !item.complianceNodeCount
          },
          children: []
        };
        if (!_this2.systemsEnabled) {
          delete item_data.systems;
        }
        if (item.childrenObjects && item.childrenObjects.length > 0) {
          var childrenList = sortAlphabetic(item.childrenObjects, 'name');
          childrenList.forEach(function (child) {
            var trafficLight = Colors.trafficlightColor(child.trafficLight, true);
            var childItem = {
              trafficlight: {
                color: trafficLight.color
              },
              id: child.uniqueId,
              name: {
                data: getFieldsFromObjectForPreview(child),
                name: child.name
              },
              description: Utils.truncate(child.description, 200) || '',
              responsible: _this2.getResponsible(child.responsibleUserIds),
              completionDegree: '-',
              systems: child.systems ? child.systems.length : 0,
              processes: child.complianceNodeCount || 0,
              actions: {
                objType: _this2.typeId,
                id: child.id,
                removable: !child.complianceNodeCount
              }
            };
            if (!_this2.systemsEnabled) {
              delete childItem.systems;
            }
            item_data.children.push(childItem);
          });
        }
        if (item_data.children.length === 0) {
          delete item_data.children;
        }
        result.push(item_data);
      });
      return result;
    },
    getSystemsData: function getSystemsData() {
      var _this3 = this;
      var result = [];
      this.informationAssetsFiltered.forEach(function (item) {
        var _item$questionnaires2, _item$processors;
        if (item.parentId !== null) {
          if (_this3.informationAssetsFiltered.find(function (system) {
            return system.id === item.parentId;
          })) {
            return false;
          }
        }
        var trafficLight = Colors.trafficlightColor(item.trafficLight, true);
        var itemData = {
          trafficlight: {
            color: trafficLight.color
          },
          id: item.uniqueId,
          name: {
            data: getFieldsFromObjectForPreview(item),
            name: item.name
          },
          responsible: _this3.getResponsible(item.responsibleUserIds),
          completionDegree: (_item$questionnaires2 = item.questionnaires) !== null && _item$questionnaires2 !== void 0 && _item$questionnaires2[0] ? item.questionnaires[0].questionnaireAnsweredPercentage + ' %' : '-',
          processors: ((_item$processors = item.processors) === null || _item$processors === void 0 ? void 0 : _item$processors.length) || 0,
          processes: item.complianceNodeCount || 0,
          actions: {
            objType: _this3.typeId,
            id: item.id,
            removable: !(item.complianceNodeCount > 0 || item.children && item.children.length > 0)
          },
          children: []
        };
        if (item.childrenObjects && item.childrenObjects.length > 0) {
          var childrenList = sortAlphabetic(item.childrenObjects, 'name');
          childrenList.forEach(function (child) {
            var trafficLight = Colors.trafficlightColor(child.trafficLight, true);
            itemData.children.push({
              trafficlight: {
                color: trafficLight.color
              },
              id: child.uniqueId,
              name: {
                data: getFieldsFromObjectForPreview(child),
                name: child.name
              },
              responsible: _this3.getResponsible(child.responsibleUserIds),
              processors: child.processors.length,
              processes: child.complianceNodeCount,
              actions: {
                objType: _this3.typeId,
                id: child.id,
                removable: !child.complianceNodeCount
              }
            });
          });
        }
        if (itemData.children.length === 0) {
          delete itemData.children;
        }
        result.push(itemData);
      });
      return result;
    },
    getInformationAssetsData: function getInformationAssetsData() {
      var _this4 = this;
      return this.informationAssetsFiltered.map(function (item) {
        var trafficLight = Colors.trafficlightColor(item.trafficLight, true);
        return {
          trafficlight: {
            color: trafficLight.color
          },
          name: {
            data: getFieldsFromObjectForPreview(item),
            name: item.title
          },
          responsible: _this4.getResponsible(item.responsibleUserIds),
          processes: item.complianceNodeCount > 0 ? item.complianceNodeCount : 0,
          actions: {
            objType: 'informationAssets',
            id: item.id,
            removable: !item.complianceNodeCount
          }
        };
      });
    },
    deleteItem: function deleteItem(item) {
      var _this$activityType;
      this.deleteIA = _objectSpread(_objectSpread({}, item), {}, {
        deleteLabel: ((_this$activityType = this.activityType) === null || _this$activityType === void 0 ? void 0 : _this$activityType.label) || ''
      });
    },
    handleDelete: function handleDelete() {
      var _this5 = this;
      if (!this.deleteIA) return;
      return destroy(this.deleteIA.objType, this.deleteIA.id).then(function () {
        _this5.deleteIA = null;
        _this5.$emit('refreshData');
      });
    },
    getResponsible: function getResponsible(usersIds) {
      if (!usersIds) return [];
      return mapToCommaList(this.users.filter(function (user) {
        return usersIds.indexOf(user.id) !== -1;
      }), 'display_name');
    },
    dismissPreviewModal: function dismissPreviewModal() {
      if (this.updated) {
        this.$emit('refreshData');
        this.updated = false;
      }
    }
  }
};