function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import StaticTable from '@/components/Molecules/StaticTable';
import { ContractGenericTableMixins } from '@/components/Pages/Contracts/ContractGenericTableMixins';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { CONTRACTS_TABLE_COLUMNS } from '@/constants/contracts/contractsTableColumns';
import { StaticTableThemes } from '@/constants/StaticTableThemes';
import { MODULES } from '@/constants/modules';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { editContract } from '@/api/contracts/contracts';
import { toLocalDate } from '@/utils/date';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { getCompanyIdsInArray } from '@/utils/format/companies';
import { escapeHtml } from '@/utils';
export default {
  name: 'ContractGenericTable',
  introduction: 'generic component to show table for contracts',
  description: 'this generic component is used for both contract history and relating contracts',
  components: {
    StaticTable: StaticTable,
    PreviewModalLink: PreviewModalLink,
    FeatherIcon: FeatherIcon,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  mixins: [ContractGenericTableMixins],
  props: {
    inputData: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'Data required for dataset'
    },
    noDataMessage: {
      required: false,
      type: String,
      default: '',
      note: 'The message to display if there is no data'
    },
    workflowStatuses: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of workflow statuses'
    },
    companies: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of companies'
    },
    hiddenColumns: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of columns to hide'
    },
    fixedWidth: {
      required: false,
      type: Boolean,
      default: false,
      note: "Describes if contract table columns should have fixed width. Useful if you try to align several tables with the same column width.\n                Column sizes will get appropriate values according to content"
    },
    removeFunction: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Determine if remove icon should be added to the last column'
    }
  },
  emits: ['updated', 'dismissModal', 'updateContracts', 'delete'],
  data: function data() {
    return {
      allStatuses: [],
      StaticTableThemes: StaticTableThemes,
      translate: {
        delete: this.$trans('Delete')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    isCurrentUserLocked: function isCurrentUserLocked() {
      return this.currentUser.level_contracts_locked;
    },
    dataset: function dataset() {
      var _this = this;
      return this.inputData.map(function (item) {
        var _item$links, _item$links2;
        var result = {
          contractNumber: escapeHtml(item.uniqueId) || '',
          title: {
            url: item.url,
            title: escapeHtml(item.title),
            archived: item.archived,
            confidential: item.confidential,
            hoverTitle: DatatableRenderer.getItemHoverTitle(item)
          },
          titlePreview: {
            data: item,
            hoverTitle: DatatableRenderer.getItemHoverTitle(item)
          },
          controls: ((_item$links = item.links) === null || _item$links === void 0 || (_item$links = _item$links[MODULES.CONTROLS]) === null || _item$links === void 0 ? void 0 : _item$links.length) || '',
          companies: {
            companies: item.companiesData || _this.getCompaniesData((_item$links2 = item.links) === null || _item$links2 === void 0 ? void 0 : _item$links2[MODULES.COMPANY])
          },
          responsible: _this.getResponsible(item.responsibleUserIds),
          activityType: _this.getActivityTypeLabel(item.activityTypeId),
          tags: _this.getTagTitles(item.tags || []),
          start: toLocalDate(item.startDate),
          end: toLocalDate(item.endDate),
          amount: item.contractAmount || '',
          currency: item.contractAmountCurrency || '',
          status: _this.$trans(_this.getStatusById(item.workflowStatusId)),
          starred: {
            id: item.id,
            starred: item.starred
          },
          delete: {
            contractId: item.id
          }
        };
        _this.hiddenColumns.forEach(function (column) {
          return delete result[column];
        });
        return result;
      });
    },
    columns: function columns() {
      var _this2 = this;
      var columns = _objectSpread({}, CONTRACTS_TABLE_COLUMNS);
      this.hiddenColumns.forEach(function (column) {
        return delete columns[column];
      });
      if (!this.removeFunction) {
        delete columns.delete;
      }
      return Object.values(columns).map(function (column) {
        return _this2.$trans(column);
      });
    },
    prepareColumnMaxWidth: function prepareColumnMaxWidth() {
      return this.fixedWidth ? _objectSpread(_objectSpread({}, Object.keys(CONTRACTS_TABLE_COLUMNS).reduce(function (acc, current) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, current, '100px'));
      }, {})), {}, {
        starred: '25px'
      }) : {};
    }
  }),
  methods: {
    getCompaniesData: function getCompaniesData(ids) {
      return getCompanyIdsInArray(ids, this.companies);
    },
    toggleContractStarStatus: function toggleContractStarStatus(data) {
      var _this3 = this;
      if (this.isCurrentUserLocked) return;
      editContract(_objectSpread(_objectSpread({}, data), {}, {
        starred: +!data.starred
      })).then(function () {
        return _this3.$emit('updateContracts');
      });
    }
  }
};