import Attachment from '@/components/Organisms/Attachment/Attachment';
import { itemList as userList } from '@/test/data/user';
export default {
  components: {
    Attachment: Attachment
  },
  data: function data() {
    return {
      userList: userList,
      filesList: [{
        created: '2018-10-09 07:50:03',
        field: null,
        filename: 'Blankt (3) (1).pdf',
        mimeType: 'application/pdf',
        id: 151,
        objType: 'file',
        owner: 1,
        size: 23734,
        type: 'file',
        url: '/api/2.0/files/151?download'
      }, {
        created: '2020-06-29 07:50:03',
        field: 'processingAgreeement',
        filename: 'GDPR default gap schema.csv',
        mimeType: 'application/vnd.ms-excel',
        id: 152,
        objType: 'file',
        owner: 1,
        size: 2373411,
        type: 'file',
        url: '/api/2.0/files/152?download'
      }]
    };
  },
  methods: {
    showUpload: function showUpload(item) {
      alert("file data is - ".concat(JSON.stringify(item)));
    },
    toggleAttachmentModal: function toggleAttachmentModal() {
      this.showModal = !this.showModal;
    }
  }
};