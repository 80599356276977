import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  key: 1,
  class: "mb-4 pb-6 border-b-2 border-gray-250"
};
var _hoisted_2 = {
  key: 3,
  class: "mb-4 pb-6 border-b-2 border-gray-250"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_activity_type_selector = _resolveComponent("activity-type-selector");
  var _component_multiple_questionnaire_selector = _resolveComponent("multiple-questionnaire-selector");
  var _component_single_select = _resolveComponent("single-select");
  var _component_overlying_underlying_information_assets = _resolveComponent("overlying-underlying-information-assets");
  var _component_solution_id_relation_details = _resolveComponent("solution-id-relation-details");
  return _openBlock(), _createElementBlock("div", null, [$data.showUpdateGapSchemaAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    body: $data.translate.updateGapSchemaMessage,
    header: $data.translate.warning,
    "button-ok-text": $data.translate.ok,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.setDefaultGapSchemaForQustionnaire,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showUpdateGapSchemaAlert = false;
    })
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept"])) : _createCommentVNode("v-if", true), $props.activityTypes.length && $props.informationAsset ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    class: "w-11/12",
    type: "medium",
    title: $data.translate.activityType
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_activity_type_selector, {
    "activity-types": $props.activityTypes,
    "model-value": $props.informationAsset.activityTypeId,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('changed', {
        property: 'activityTypeId',
        value: $event
      });
    })
  }, null, 8 /* PROPS */, ["activity-types", "model-value"])])) : _createCommentVNode("v-if", true), $props.informationAsset && $props.questionnaires.length ? (_openBlock(), _createBlock(_component_multiple_questionnaire_selector, {
    key: 2,
    class: "mb-4 pb-6 border-b-2 border-gray-250",
    "questionnaire-options": $props.questionnaires,
    "selected-questionnaires": $options.activeQuestionnaires,
    onAddQuestionnaire: $options.addQuestionnaire,
    onRemoveQuestionnaire: $options.removeQuestionnaire
  }, null, 8 /* PROPS */, ["questionnaire-options", "selected-questionnaires", "onAddQuestionnaire", "onRemoveQuestionnaire"])) : _createCommentVNode("v-if", true), $props.riskAssessmentSchemas.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_single_select, {
    title: $data.translate.gapSchemaVersion,
    options: $props.riskAssessmentSchemas,
    "model-value": $props.informationAsset.gapSchemaId,
    placeholder: $data.translate.choose,
    "with-reset": true,
    "onUpdate:modelValue": $options.changeGapSchema
  }, null, 8 /* PROPS */, ["title", "options", "model-value", "placeholder", "onUpdate:modelValue"])])) : _createCommentVNode("v-if", true), $props.informationAsset.id && $props.items && $props.items.length ? (_openBlock(), _createBlock(_component_overlying_underlying_information_assets, {
    key: 4,
    class: _normalizeClass(["mb-4 border-b-2 border-gray-250", {
      'pb-6': $options.iaHasChildren
    }]),
    children: $props.informationAsset.children,
    parents: $options.parents,
    "data-id": $props.informationAsset.id,
    items: $props.items,
    type: $props.type,
    onPropertyChanged: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('changed', $event);
    }),
    onCreateProcessor: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('createProcessor', $event);
    })
  }, null, 8 /* PROPS */, ["class", "children", "parents", "data-id", "items", "type"])) : _createCommentVNode("v-if", true), _createVNode(_component_solution_id_relation_details, {
    list: $props.informationAsset.solutionIds,
    onPropertyChanged: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('changed', $event);
    })
  }, null, 8 /* PROPS */, ["list"])]);
}