function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { RiskColorName, StatusColorMapping } from '@/constants/risks/RisksReports';
import { RiskMatrixDefaultSettings } from '@/constants/admin/RiskMatrixSettings';
import { getProbabilityLabel, getConsequenceLabel } from '@/utils/risks/riskPeriod';
export var RiskGridMixin = {
  data: function data() {
    var _colorCodes;
    return {
      statusColor: _objectSpread({}, StatusColorMapping),
      colorCodes: (_colorCodes = {}, _defineProperty(_colorCodes, RiskColorName.GREEN, 'background-color: hsl(161, 100%, 34%)'), _defineProperty(_colorCodes, RiskColorName.LIGHTBLUE, 'background-color: hsl(187, 100%, 39%)'), _defineProperty(_colorCodes, RiskColorName.RISMABLUE, 'background-color: hsl(190, 100%, 28%)'), _defineProperty(_colorCodes, RiskColorName.RED, 'background-color: hsl(18, 98%, 40%)'), _defineProperty(_colorCodes, RiskColorName.GREY, 'background-color: hsl(0, 0%, 60%)'), _colorCodes)
    };
  },
  computed: {
    matrixInverted: function matrixInverted() {
      return this.currentRiskProject.matrixInverted;
    },
    startColor: function startColor() {
      return RiskMatrixDefaultSettings.startColor;
    },
    endColor: function endColor() {
      return RiskMatrixDefaultSettings.endColor;
    },
    yLabel: function yLabel() {
      if (this.isErm) {
        return this.scoring.likelihood_label;
      }
      return this.matrixInverted ? getProbabilityLabel(this.periodSelected) : getConsequenceLabel(this.periodSelected);
    },
    xLabel: function xLabel() {
      if (this.isErm) {
        return this.scoring.impact_label;
      }
      return this.matrixInverted ? getConsequenceLabel(this.periodSelected) : getProbabilityLabel(this.periodSelected);
    },
    gridSizeX: function gridSizeX() {
      if (this.isErm) {
        return this.scoring.impact_scores;
      }
      return this.matrixInverted ? this.periodSelected.consequenceLevels : this.periodSelected.probabilityLevels;
    },
    gridSizeY: function gridSizeY() {
      if (this.isErm) {
        return this.scoring.likelihood_scores;
      }
      return this.matrixInverted ? this.periodSelected.probabilityLevels : this.periodSelected.consequenceLevels;
    }
  },
  methods: {
    createEmptyGridFrom: function createEmptyGridFrom(n) {
      return Array(n).fill().map(function () {
        return [];
      });
    },
    getMatrix: function getMatrix(dots) {
      return {
        checked: false,
        yLabel: this.yLabel,
        xLabel: this.xLabel,
        gridSizeX: this.createEmptyGridFrom(this.gridSizeX),
        gridSizeY: this.createEmptyGridFrom(this.gridSizeY),
        colorCodes: this.colorCodes,
        dots: dots
      };
    }
  }
};