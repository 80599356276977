import { Http } from '../index';
import * as HttpUtils from '../httpUtils';

/**
 * Create new DPM request
 *
 * @param dummy
 * @param processorId
 * @param data
 * @returns {Promise<AxiosResponse | string>}
 */
export function createNewRequest(dummy, processorId, data) {
  return Http.post("compliance/informationAssets/dpm/".concat(processorId), data).then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}

/**
 * Get all audit requests (data processor management) on a data processor
 *
 * @param dummy
 * @param processorId
 * @param data
 * @returns {Promise<AxiosResponse<T> | string>}
 */
export function getAllRequests(dummy, processorId) {
  var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  return HttpUtils.handleRequestResponse(Http.get("compliance/informationAssets/dpm/".concat(processorId, "?data[]=").concat(data)));
}
export function updateRequestDeadline(processorId, requestId, deadline, message) {
  var data = {
    deadline: deadline,
    message: message
  };
  return Http.patch("compliance/informationAssets/dpm/".concat(processorId, "/deadline/").concat(requestId), data).then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}
export function resubmitRequest(requestId, deadline, message) {
  var data = {
    message: message,
    deadline: deadline
  };
  return Http.patch("dpm/resubmit/".concat(requestId), data).then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}

/**
 * Load audit data by token
 *
 * @param token
 * @returns {Promise<T>}
 */
export function getByToken(token) {
  var url = "dpm/audit/".concat(token);
  return HttpUtils.handleRequestResponse(Http.get(url));
}

/**
 * Save one answer to a question
 *
 * @param token
 * @param questionAnswer
 * @returns {Promise<T | string>}
 */
export function submitQuestionAnswer(token, questionAnswer) {
  var url = "dpm/auditquestionanswer/".concat(token);
  return HttpUtils.handleRequestResponse(Http.patch(url, questionAnswer));
}

/**
 * Submit questionnaire and close audit request
 *
 * @param token
 * @param questions
 * @returns {Promise<T | string>}
 */
export function submitAudit(token, questions) {
  var url = "dpm/audit/".concat(token);
  return HttpUtils.handleRequestResponse(Http.post(url, questions));
}
export function deleteAudit(processorId, requestId) {
  return HttpUtils.handleRequestResponse(Http.delete("compliance/informationAssets/dpm/".concat(processorId, "/delete/").concat(requestId)));
}