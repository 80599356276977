import { getTranslate } from './translate';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import Modal from '@/components/Molecules/Modal/Modal';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import { DateFormats } from '@/constants/DateFormats';
import { removeHtmlTags } from '@/utils/Utils';
import { formatCurrentDateTime } from '@/utils/date';
export default {
  name: 'ContractArchiveModal',
  components: {
    Modal: Modal,
    TinyMceBox: TinyMceBox,
    SingleSelect: SingleSelect,
    DateTimePicker: DateTimePicker
  },
  props: {
    archived: {
      type: Boolean,
      required: true,
      note: 'If contract is archived'
    },
    workflowStatusId: {
      type: [String, Number],
      required: true,
      note: 'Present workflowStatusId of contract'
    },
    workflowStatuses: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Available workflow statuses'
    },
    contractTitle: {
      type: String,
      required: false,
      default: '',
      note: 'Contracts name'
    },
    expirationDateDefault: {
      type: String,
      required: false,
      default: null,
      note: 'Expiration date'
    }
  },
  emits: ['toggleArchived', 'dismiss'],
  data: function data() {
    return {
      workflowStatusNote: "<p>".concat(formatCurrentDateTime(DateFormats.DATE_TIME_FORMAT), ": </p>"),
      statusId: null,
      expirationDate: this.getDefaultDate(),
      translate: getTranslate['ContractArchiveModal']()
    };
  },
  computed: {
    statusOptions: function statusOptions() {
      var _this = this;
      return this.workflowStatuses.map(function (item) {
        return {
          label: _this.$trans(item.title),
          value: item.id
        };
      });
    },
    archivedModalHeader: function archivedModalHeader() {
      var action = !this.archived ? this.translate.archiving : this.translate.restoring;
      return "".concat(action, " ").concat(this.contractTitle);
    },
    selectStatusTitle: function selectStatusTitle() {
      return !this.archived ? this.translate.rememberToChangeStatusBeforeArchivingContrac : this.translate.rememberToChangeStatusBeforeRestoringContrac;
    },
    isValidNote: function isValidNote() {
      return !!removeHtmlTags(this.workflowStatusNote).trim();
    },
    datePickerOptions: function datePickerOptions() {
      return {
        format: DateFormats.DATE_FORMAT
      };
    }
  },
  mounted: function mounted() {
    this.statusId = this.workflowStatusId;
  },
  methods: {
    getDefaultDate: function getDefaultDate() {
      if (this.archived) {
        return this.expirationDateDefault;
      }
      return this.expirationDateDefault || formatCurrentDateTime();
    },
    handleAccept: function handleAccept() {
      var data = {
        workflowStatusNote: this.workflowStatusNote,
        workflowStatusId: this.statusId,
        endDate: this.expirationDate || null
      };
      this.$emit('toggleArchived', data);
    }
  }
};