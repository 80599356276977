export var RiskGridHelperMixin = {
  methods: {
    rpiToAxisPos: function rpiToAxisPos(rpi) {
      var axisPos = 0;
      switch (rpi) {
        case 1:
        case 2:
        case 3:
          axisPos = 1;
          break;
        case 4:
        case 5:
        case 6:
          axisPos = 2;
          break;
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
          axisPos = 3;
          break;
        case 15:
        case 16:
          axisPos = 4;
          break;
        case 20:
        case 21:
        case 22:
        case 23:
        case 24:
        case 25:
          axisPos = 5;
          break;
        case 30:
        case 36:
          axisPos = 6;
          break;
      }
      return axisPos;
    },
    initiateGrid: function initiateGrid() {
      this.grid = [];
      for (var i = 0; i < this.riskGridData.gridSizeY.length; i++) {
        this.grid.push([]);
        for (var j = 0; j < this.riskGridData.gridSizeX.length; j++) {
          this.grid[i].push([]);
        }
      }
    }
  }
};