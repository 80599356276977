function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { updateFlow as _updateFlow } from '@/api/compliance/dataflow';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import PopUpMenu from '@/components/Atoms/Containers/PopUpMenu';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import Modal from '@/components/Molecules/Modal/Modal';
import Notification from '@/components/Molecules/Notification';
import InputField from '@/components/Atoms/Inputs/InputField';
import Alert from '@/components/Molecules/Modal/Alert';
import { IATitleMixin } from '@/mixins/IATitleMixin';
export default {
  name: 'DataFlowRow',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    PopUpMenu: PopUpMenu,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    TextBox: TextBox,
    Modal: Modal,
    Notification: Notification,
    InputField: InputField,
    Alert: Alert
  },
  mixins: [IATitleMixin],
  props: {
    projectId: {
      type: [Number, String],
      required: true,
      default: null
    },
    nodeId: {
      type: [Number, String],
      required: true,
      default: null
    },
    flow: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    systems: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    stakeholders: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    dataTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    isProjectLocked: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['deleteStakeholder', 'addDataflowToStakeholder', 'deleteDataFlow'],
  data: function data() {
    return {
      RECEIVE_DIRECTION: 'receive',
      DELIVER_DIRECTION: 'deliver',
      showWarningAlert: false,
      displayPopUp: false,
      showChangeSystemTitleModal: false,
      timer: {},
      emptySystemTitleError: '',
      newSystemTitle: '',
      warningText: {
        body: '',
        header: '',
        okButton: ''
      },
      translate: getTranslate['DataFlowRow']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    dataFlowSystemsTitle: function dataFlowSystemsTitle() {
      return this.flow.systemsTitle || this.getSettingValue('feature.data_flow_systems_name') || this.translate.systems;
    },
    showComments: function showComments() {
      return !!this.getSettingValue('feature.data_flow_comments');
    },
    deleteStakeholderMessage: function deleteStakeholderMessage() {
      return "".concat(this.translate.areYouSureYouWantToDeleteThisDataFlowWit, " ").concat(this.informationAssetsTitle(), "?");
    },
    dataFlowStakeholdersTitle: function dataFlowStakeholdersTitle() {
      return this.getSettingValue('feature.data_flow_stakeholders_name') || this.translate.stakeholders;
    },
    flowDirection: function flowDirection() {
      return this.flow.direction === this.DELIVER_DIRECTION ? 'right' : 'left';
    },
    stakeholdersOptions: function stakeholdersOptions() {
      return this.stakeholders.map(function (item) {
        return {
          value: item.uniqueId,
          label: item.text
        };
      });
    }
  }),
  methods: {
    showSystemTitleChangeModal: function showSystemTitleChangeModal() {
      this.newSystemTitle = this.flow.systemsTitle;
      this.showChangeSystemTitleModal = true;
    },
    toggleWarningAlert: function toggleWarningAlert() {
      this.showWarningAlert = !this.showWarningAlert;
      this.changeCallback = null;
    },
    onNewDataFlow: function onNewDataFlow() {
      this.$emit('addDataflowToStakeholder', this.flow.id);
      this.displayPopUp = false;
    },
    onDeleteStakeholder: function onDeleteStakeholder() {
      var _this = this;
      this.toggleWarningAlert();
      this.displayPopUp = false;
      this.warningText = {
        body: this.deleteStakeholderMessage,
        header: this.translate.confirmationNeeded,
        okButton: this.translate.confirm
      };
      this.changeCallback = function () {
        _this.$emit('deleteStakeholder');
        _this.toggleWarningAlert();
      };
    },
    onDeleteDataFlow: function onDeleteDataFlow(idxToDelete) {
      var _this2 = this;
      this.toggleWarningAlert();
      this.warningText = {
        body: this.translate.areYouSureYouWantToDeleteThisDataFlow,
        header: this.translate.deleteDataFlow,
        okButton: this.translate.delete
      };
      this.changeCallback = function () {
        _this2.$emit('deleteDataFlow', idxToDelete);
        _this2.toggleWarningAlert();
      };
    },
    onChangeDataflowDirection: function onChangeDataflowDirection() {
      var _this3 = this;
      this.toggleWarningAlert();
      this.displayPopUp = false;
      this.warningText = {
        body: this.translate.changingTheDirectionOfTheDataflowOrAdding,
        header: this.translate.warning,
        okButton: this.translate.ok
      };
      this.changeCallback = function () {
        var direction = _this3.flow.direction === _this3.RECEIVE_DIRECTION ? _this3.DELIVER_DIRECTION : _this3.RECEIVE_DIRECTION;
        _this3.updateAllFlows(direction, 'direction');
        _this3.toggleWarningAlert();
      };
    },
    onChangeStakeholder: function onChangeStakeholder(stakeholderId) {
      var _this4 = this;
      this.toggleWarningAlert();
      this.warningText = {
        body: this.translate.changingTheDirectionOfTheDataflowOrAdding,
        header: this.translate.warning,
        okButton: this.translate.ok
      };
      this.changeCallback = function () {
        var stakeholder = _this4.stakeholders.find(function (stk) {
          return stk.uniqueId === stakeholderId;
        });
        _this4.updateAllFlows(stakeholder, 'stakeholder');
        _this4.toggleWarningAlert();
      };
    },
    onChangeSystemTitle: function onChangeSystemTitle() {
      if (!this.newSystemTitle) {
        this.emptySystemTitleError = this.translate.titleCannotBeEmpty;
        return;
      }
      this.updateAllFlows(this.newSystemTitle, 'systemsTitle');
      this.newSystemTitle = '';
      this.showChangeSystemTitleModal = false;
    },
    updateAllFlows: function updateAllFlows(value, property) {
      var _this5 = this;
      this.flow.subFlows.forEach(function (subFlow) {
        subFlow[property] = value;
        _this5.updateFlow(subFlow);
      });
    },
    updateSingleFlow: function updateSingleFlow(value, property, flowId) {
      var _this6 = this;
      var flow = this.flow.subFlows.find(function (subFlow) {
        return +subFlow.id === +flowId;
      });
      flow[property] = value;
      if (property === 'comments') {
        clearTimeout(this.timer[flowId]);
        this.timer[flowId] = setTimeout(function () {
          return _this6.updateFlow(flow);
        }, this.$debounceTimeout);
        return;
      }
      this.updateFlow(flow);
    },
    updateFlow: function updateFlow(flow) {
      return _updateFlow(this.projectId, this.nodeId, flow.id, flow);
    }
  }
};