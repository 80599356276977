import { getTranslate } from './translate';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import WizardFlow from '@/components/Molecules/WizardFlow';
import { getAllOptions } from '@/api/compliance/dataflow';
import { removeDuplicates } from '@/utils/filterArray';
import { DataFlowOptionTypes } from '@/constants/compliance/DataFlowTypes';
export default {
  name: 'WizardFlowWrapper',
  description: 'Wrapper component for wizard flow that contains api requests, data converter, WizardFlow and button',
  components: {
    FeatherIcon: FeatherIcon,
    WizardFlow: WizardFlow
  },
  props: {
    nodeId: {
      required: false,
      type: [Number, String],
      default: 0
    },
    projectId: {
      type: Number,
      required: true,
      note: 'project id for the current project'
    }
  },
  data: function data() {
    return {
      currentDataSubject: null,
      stakeholderOptions: [],
      isOpened: false,
      translate: getTranslate['WizardFlowWrapper']()
    };
  },
  computed: {
    items: function items() {
      return [{
        headline: this.translate.welcome,
        headline2: this.translate.introduction,
        icon: 'info',
        component: 'DataFlowWizardStep1'
      }, {
        headline: this.translate.stakeholder,
        headline2: this.translate.chooseStakeholderOrAsset,
        icon: 'user',
        component: 'DataFlowWizardStepDataSubject',
        isLockedNextStep: !this.currentDataSubject,
        lockMessage: this.translate.chooseStakeholderOrAsset
      }, {
        headline: 'Step 3',
        headline2: 'Bla bla bla...',
        icon: 'send',
        component: 'ModalWizardExampleStep1'
      }];
    }
  },
  mounted: function mounted() {
    this.getAllData();
  },
  methods: {
    getAllData: function getAllData() {
      var _this = this;
      return getAllOptions(this.projectId, this.nodeId).then(function (_ref) {
        var stakeholders = _ref.stakeholders;
        var data = removeDuplicates(stakeholders || [], 'uniqueId');
        _this.stakeholderOptions = data.map(function (item) {
          var additionalText = _this.getAdditionalTextByType(item.type);
          return {
            value: item.uniqueId,
            label: additionalText ? "".concat(additionalText, ": ").concat(item.text) : item.text
          };
        });
      });
    },
    getAdditionalTextByType: function getAdditionalTextByType(type) {
      switch (type) {
        case DataFlowOptionTypes.QUESTION_OPTION:
          return this.translate.stakeholder;
        case DataFlowOptionTypes.DATAPROCESSOR_PROCESSOR:
          return this.translate.dataProcessor;
        case DataFlowOptionTypes.DATAPROCESSOR_CONTROLLER:
          return this.translate.dataController;
        case DataFlowOptionTypes.DATAPROCESSOR_SYSTEM:
          return this.translate.system;
        default:
          throw new Error('Could not find option type for step 2');
      }
    }
  }
};