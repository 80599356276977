function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import { ref } from 'vue';
import { capitalize } from '@/utils';
import { getAllByType } from '@/api/compliance/informationAssets';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { InformationAssetsViews } from '@/constants/compliance/InformationAssetsViews';
import InformationAssetsExtendedView from '@/components/Pages/InformationAssets/InformationAssetsExtendedView';
import InformationAssetsSimpleView from '@/components/Pages/InformationAssets/InformationAssetsSimpleView';
import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Pagination from '@/components/Atoms/Pagination';
import RismaPagesize from '@/components/Atoms/RismaDatatable/RismaPagesize';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import InformationAssetCreationModal from '@/components/Molecules/InformationAssets/InformationAssetCreationModal';
import InputField from '@/components/Atoms/Inputs/InputField';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'InformationAssets',
  components: {
    InformationAssetsExtendedView: InformationAssetsExtendedView,
    InformationAssetsSimpleView: InformationAssetsSimpleView,
    MainWell: MainWell,
    FeatherIcon: FeatherIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    Pagination: Pagination,
    RismaPagesize: RismaPagesize,
    NotificationPopUp: NotificationPopUp,
    InformationAssetCreationModal: InformationAssetCreationModal,
    InputField: InputField
  },
  mixins: [NotificationPopUpMixin],
  beforeRouteUpdate: function beforeRouteUpdate(to, from, next) {
    this.getData(to.params.typeId);
    next();
  },
  props: {
    onlyMy: {
      type: Boolean,
      default: false,
      note: 'If show only my IA'
    },
    typeId: {
      type: [Number, String],
      required: false,
      default: null,
      note: 'Id of information asset type'
    },
    activityTypes: {
      type: Array,
      required: true
    },
    initialView: {
      type: String,
      required: false,
      default: '',
      note: 'Which view to display on load if it is passed; or will be set from localstorage'
    }
  },
  setup: function setup(props) {
    var pageView = props.initialView ? ref(props.initialView) : getLocalStorageComputed('information_assets_overview_view', InformationAssetsViews.EXTENDED);
    return {
      pageView: pageView
    };
  },
  data: function data() {
    return {
      isLoaded: false,
      informationAssets: [],
      showCreateModal: false,
      currentItem: 0,
      totalAssets: 0,
      basePagingOptions: [10, 25, 50, 100],
      pageSize: 25,
      sortField: '',
      timer: 0,
      filterString: '',
      debounceTimeout: 1000,
      debounceTimer: null,
      translate: getTranslate['InformationAssets']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useUsersStore, ['users'])), {}, {
    title: function title() {
      var activityTypeName = this.activityType ? capitalize(this.activityType.label) : this.$trans(capitalize(this.typeId));
      return this.onlyMy ? "".concat(this.translate.my, " ").concat(activityTypeName) : "".concat(activityTypeName);
    },
    activityType: function activityType() {
      var _this = this;
      return this.activityTypes.find(function (type) {
        return type.id == _this.typeId;
      });
    },
    pagingOptions: function pagingOptions() {
      return [].concat(_toConsumableArray(this.basePagingOptions), [{
        value: this.totalAssets,
        label: this.translate.showAll
      }]);
    },
    confirmExportMessage: function confirmExportMessage() {
      var partialExport = this.pageSize < this.totalAssets;
      if (!partialExport) return '';
      return "".concat(this.translate.areYouSureYouWantToExport, " ").concat(this.pageSize, " ").concat(this.translate.itemsFrom, " ").concat(this.totalAssets, "?");
    },
    confirmPrintMessage: function confirmPrintMessage() {
      var partialExport = this.pageSize < this.totalAssets;
      if (!partialExport) return '';
      return "".concat(this.translate.areYouSureYouWantToPrint, " ").concat(this.pageSize, " ").concat(this.translate.itemsFrom, " ").concat(this.totalAssets, "?");
    },
    showExtended: function showExtended() {
      return this.pageView === InformationAssetsViews.EXTENDED;
    }
  }),
  mounted: function mounted() {
    this.getData(this.typeId);
  },
  methods: {
    backendSortRequest: function backendSortRequest(state) {
      return this.getInformationAssetsByType(this.typeId, state);
    },
    createIA: function createIA(data) {
      this.toggleCreateModal();
      this.setNotificationData(data);
      this.getData(this.typeId);
    },
    getData: function getData(typeId) {
      var _this2 = this;
      this.isLoaded = false;
      if (this.currentItem != 0) this.currentItem = 0;
      this.getPageSizeLocalState(typeId);
      return this.getInformationAssetsByType(typeId).then(function () {
        _this2.isLoaded = true;
        _this2.turnAfterRefreshNotification();
      });
    },
    getInformationAssetsByType: function getInformationAssetsByType(typeId, sortData) {
      var _this3 = this;
      var type = typeId > 0 ? AssetsTypes.INFORMATION_ASSETS_API : typeId;
      var filterSearchName = typeId > 0 ? 'title' : 'name';
      var sortString = '&sortField=';
      if (sortData) {
        var str = "".concat(filterSearchName, "&sortDir=").concat(sortData.dir);
        sortString += str;
        this.sortField = str;
      } else {
        sortString += filterSearchName;
      }
      var filters = "?data[]=all&limit=".concat(this.pageSize, "&start=").concat(this.currentItem);
      if (this.onlyMy) {
        filters += "&filters[responsible][]=".concat(this.currentUser.id);
      }
      if (this.filterString) {
        filters += "&filters[".concat(filterSearchName, "][]=").concat(this.filterString);
      }
      if (typeId > 0) {
        filters += "&filters[activityType][]=".concat(typeId);
      }
      filters += "".concat(sortString);
      return getAllByType(type, filters).then(function (data) {
        _this3.totalAssets = data.total;
        _this3.adjustAllPerPage();
        _this3.informationAssets = data.list;
      });
    },
    adjustAllPerPage: function adjustAllPerPage() {
      if (![].concat(_toConsumableArray(this.basePagingOptions), [this.totalAssets]).includes(this.pageSize)) this.pageSize = this.totalAssets;
    },
    switchView: function switchView() {
      this.pageView = this.showExtended ? InformationAssetsViews.SIMPLE : InformationAssetsViews.EXTENDED;
    },
    toggleCreateModal: function toggleCreateModal() {
      this.showCreateModal = !this.showCreateModal;
    },
    pageChange: function pageChange(e) {
      if (e.mounted) return;
      this.currentItem = e.start;
      this.getInformationAssetsByType(this.typeId);
    },
    setPageSizeLocalState: function setPageSizeLocalState() {
      localStorage.setItem(this.getLocalStatePageSizeName(this.typeId), JSON.stringify(this.pageSize));
    },
    getPageSizeLocalState: function getPageSizeLocalState(typeId) {
      var localStateName = this.getLocalStatePageSizeName(typeId);
      if (!localStateName) {
        this.pageSize = 25;
        return;
      }
      var pageSize = JSON.parse(localStorage.getItem(localStateName));
      this.pageSize = pageSize || 25;
    },
    getLocalStatePageSizeName: function getLocalStatePageSizeName(typeId) {
      return "information_assets_".concat(typeId, "_page_size");
    },
    onSearch: function onSearch() {
      var _this4 = this;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(function () {
        _this4.getData(_this4.typeId);
      }, this.debounceTimeout);
    }
  }
};