function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getIncidentDashboardsOverview as _getIncidentDashboardsOverview } from '@/api/incident/incident';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import RismaBarChart from '@/components/Molecules/Charts/RismaBarChart';
import Notification from '@/components/Molecules/Notification';
import ActivityTypeSelector from '@/components/Molecules/ActivityTypeSelector';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { DateFormats } from '@/constants/DateFormats';
import { removeDuplicates } from '@/utils/filterArray';
import { RismaHorisontalBarChartMixin } from '@/mixins/RismaHorisontalBarChartMixin';
import { formatTimeToDateFormat, getFortnightDateRange } from '@/utils/date';
var STORAGE_KEY = 'incident_dahboard';
export default {
  name: 'IncidentDashboard',
  introduction: 'Incident Dashboard page component',
  description: 'Complex page component, /incident/dashboard',
  token: '<action-dashboard />',
  components: {
    Notification: Notification,
    RismaTitle: RismaTitle,
    RismaDateRangePicker: RismaDateRangePicker,
    RismaBarChart: RismaBarChart,
    ActivityTypeSelector: ActivityTypeSelector,
    SingleSelect: SingleSelect
  },
  mixins: [RismaHorisontalBarChartMixin],
  data: function data() {
    return {
      isLoaded: false,
      charts: null,
      topStatus: {},
      dateRange: getFortnightDateRange(DateFormats.DATE_FORMAT),
      activityTypeToFilter: null,
      headline: '',
      untrackedIncidentCount: 0,
      currentCustomField: null,
      sumPerOrganisationData: {},
      translate: getTranslate['IncidentDashboard']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingsValue: 'getValue'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'incidentActivityTypes'
  })), mapState(useOrganisationsStore, ['organisations'])), {}, {
    totalControl: function totalControl() {
      var _this = this;
      return Object.keys(this.topStatus).map(function (item) {
        return {
          label: item,
          value: _this.topStatus[item]
        };
      });
    },
    hasNumericalChart: function hasNumericalChart() {
      return this.getSettingsValue('feature.incidents_dashboard_customfield_sum');
    },
    preparedNumericalChartData: function preparedNumericalChartData() {
      var _this2 = this;
      if (!this.currentCustomField) return null;
      var object = this.sumPerOrganisationData;
      var data = {
        labels: [],
        datasets: [{
          backgroundColor: [],
          data: [],
          label: this.translate.sumPerOrganisation
        }]
      };
      var _loop = function _loop(key) {
        var currentData = object[key].find(function (item) {
          return item.customFieldId === _this2.currentCustomField;
        });
        if (currentData) {
          var _this2$charts$;
          var organisation = _this2.organisations.find(function (organisation) {
            return organisation.id === +key;
          });
          data.labels.push(organisation.visible_name);
          data.datasets[0].backgroundColor.push((_this2$charts$ = _this2.charts[0]) === null || _this2$charts$ === void 0 || (_this2$charts$ = _this2$charts$.data[0]) === null || _this2$charts$ === void 0 ? void 0 : _this2$charts$.color);
          data.datasets[0].data.push(currentData.sum);
          data.unit = _this2.findUnit(currentData);
        }
      };
      for (var key in object) {
        _loop(key);
      }
      return data;
    },
    filteredCustomFields: function filteredCustomFields() {
      var _this3 = this;
      var values = Object.values(this.sumPerOrganisationData);
      if (!values.length) return;
      return removeDuplicates(values.flat(), 'customFieldId', false).map(function (item) {
        return {
          label: "".concat(item.customFieldLabel, " (").concat(_this3.findUnit(item), ")"),
          value: item.customFieldId
        };
      });
    },
    additionalUnitOptions: function additionalUnitOptions() {
      var _this4 = this;
      return {
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
              maxTicksLimit: 10,
              callback: function callback(value) {
                return _this4.prepareValueWithXLabelSuffix(value, _this4.preparedNumericalChartData.unit);
              }
            }
          }
        },
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: function label(tooltipItems) {
                return _this4.prepareValueWithXLabelSuffix("".concat(tooltipItems.dataset.label, ": ").concat(tooltipItems.formattedValue), _this4.preparedNumericalChartData.unit);
              }
            }
          },
          legend: {
            display: false
          }
        }
      };
    }
  }),
  mounted: function mounted() {
    var storageValue = localStorage.getItem(STORAGE_KEY);
    if (storageValue) {
      this.getValuesFromStorage(storageValue);
    }
    this.getIncidentDashboardsOverview();
  },
  methods: {
    findUnit: function findUnit(item) {
      var _item$unit;
      if ((_item$unit = item.unit) !== null && _item$unit !== void 0 && _item$unit.length) return item.unit[0];
      var id = item.customFieldId;
      var currentUnitField = null;
      this.activityTypes.some(function (item) {
        var field = item.fields.find(function (customField) {
          return customField.id === id;
        });
        currentUnitField = field;
        return field;
      });
      return currentUnitField.unit || '';
    },
    generateCustomFieldChartData: function generateCustomFieldChartData(key) {
      localStorage.setItem(STORAGE_KEY + '_numerical', key);
    },
    selectCurrentCustomField: function selectCurrentCustomField() {
      var storageValue = localStorage.getItem(STORAGE_KEY + '_numerical');
      if (!storageValue) return;
      var values = Object.values(this.sumPerOrganisationData);

      //check if it exists in new list, otherwise remove it
      if (values.flat().find(function (item) {
        return +item.customFieldId === +storageValue;
      })) {
        this.currentCustomField = +storageValue;
      } else {
        this.currentCustomField = null;
      }
    },
    getValuesFromStorage: function getValuesFromStorage(storageValue) {
      var _JSON$parse = JSON.parse(storageValue),
        startDate = _JSON$parse.startDate,
        endDate = _JSON$parse.endDate,
        activityTypeId = _JSON$parse.activityTypeId;
      this.dateRange = {
        startDate: formatTimeToDateFormat(startDate),
        endDate: formatTimeToDateFormat(endDate)
      };
      this.activityTypeToFilter = activityTypeId || -1;
    },
    getIncidentDashboardsOverview: function getIncidentDashboardsOverview() {
      var _this5 = this;
      this.isLoaded = false;
      return _getIncidentDashboardsOverview(this.dateRange, this.activityTypeToFilter).then(function (_ref) {
        var charts = _ref.charts,
          topStatus = _ref.topStatus,
          headline = _ref.headline,
          incidentsWithOutIncidentDate = _ref.incidentsWithOutIncidentDate,
          sumPerOrganisation = _ref.sumPerOrganisation;
        _this5.untrackedIncidentCount = incidentsWithOutIncidentDate;
        _this5.charts = _this5.prepareChartsData(charts);
        _this5.topStatus = topStatus;
        _this5.headline = headline;
        _this5.isLoaded = true;
        if (_this5.hasNumericalChart && !Array.isArray(_this5.sumPerOrganisation)) {
          _this5.sumPerOrganisationData = sumPerOrganisation;
          _this5.selectCurrentCustomField();
        }
        localStorage.setItem(STORAGE_KEY, JSON.stringify({
          startDate: _this5.dateRange.startDate,
          endDate: _this5.dateRange.endDate,
          activityTypeId: _this5.activityTypeToFilter
        }));
      });
    },
    changeDatePicker: function changeDatePicker(event) {
      if (event) {
        this.dateRange.startDate = event.startDate;
        this.dateRange.endDate = event.endDate;
        this.getIncidentDashboardsOverview();
      }
    },
    onActivityTypeChange: function onActivityTypeChange(newValue) {
      this.activityTypeToFilter = newValue;
      this.getIncidentDashboardsOverview();
    },
    prepareValueWithXLabelSuffix: function prepareValueWithXLabelSuffix(value, suffix) {
      return "".concat(value).concat(suffix ? ' (' + suffix + ')' : '');
    }
  }
};