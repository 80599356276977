import { i18n } from '@/i18n';
export function TrendOptions() {
  return [{
    value: 1,
    label: i18n.t('Falling trend')
  }, {
    value: 2,
    label: i18n.t('Neutral trend')
  }, {
    value: 3,
    label: i18n.t('Rising trend')
  }];
}