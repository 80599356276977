import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1f653958"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "list-none pl-10"
};
var _hoisted_2 = {
  class: "flex justify-between pb-1"
};
var _hoisted_3 = {
  class: "flex justify-between items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_controls_accounts_tree = _resolveComponent("controls-accounts-tree", true);
  return _openBlock(), _createElementBlock("ol", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.preparedList, function (item, index) {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      class: "block relative border-t pt-1"
    }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString(item.title), 1 /* TEXT */), _createElementVNode("div", _hoisted_3, [_createVNode(_component_feather_icon, {
      class: _normalizeClass(["stroke-2 text-blue-350 pr-2 cursor-pointer", {
        'mr-5': item.isInUse
      }]),
      icon: "edit",
      height: "25",
      width: "25",
      onClick: function onClick($event) {
        return _ctx.$emit('editAccount', item);
      }
    }, null, 8 /* PROPS */, ["class", "onClick"]), !item.isInUse ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      class: "stroke-2 text-red-50 cursor-pointer",
      icon: "x",
      height: "20",
      width: "20",
      onClick: function onClick($event) {
        return _ctx.$emit('deleteAccount', item);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)])]), item.children && item.children.length ? (_openBlock(), _createBlock(_component_controls_accounts_tree, {
      key: 0,
      list: item.children,
      onEditAccount: _cache[0] || (_cache[0] = function ($event) {
        return _ctx.$emit('editAccount', $event);
      }),
      onDeleteAccount: _cache[1] || (_cache[1] = function ($event) {
        return _ctx.$emit('deleteAccount', $event);
      })
    }, null, 8 /* PROPS */, ["list"])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))]);
}