import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-09968bdb"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "breadcrumbs"
};
var _hoisted_2 = {
  key: 2,
  class: "divider"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, function (item, index) {
    return _openBlock(), _createElementBlock("li", {
      key: index
    }, [item.url ? (_openBlock(), _createBlock(_component_router_link, {
      key: 0,
      class: "hover:underline",
      to: item.url
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])) : _createCommentVNode("v-if", true), !item.url ? (_openBlock(), _createElementBlock("span", {
      key: 1,
      class: _normalizeClass({
        'line-through': item.url === null
      })
    }, _toDisplayString(item.title), 3 /* TEXT, CLASS */)) : _createCommentVNode("v-if", true), index + 1 < $props.items.length ? (_openBlock(), _createElementBlock("span", _hoisted_2, ">")) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))])]);
}