import { Http } from '../index';
import { handleRequestResponse, objectToQueryString } from '../httpUtils';
export function getLogReports() {
  var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var queryString = objectToQueryString({
    filters: filters
  });
  return handleRequestResponse(Http.get("admin/reports/userlogins?".concat(queryString)));
}
export function getUsersChangelog() {
  return handleRequestResponse(Http.get('changelog/users'));
}
export function getSettingsChangelog() {
  return handleRequestResponse(Http.get('changelog/changelog/settings'));
}