function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import ColorPicker from '@/components/Molecules/ColorPicker';
import Alert from '@/components/Molecules/Modal/Alert';
import { sortNumericByPropComplex } from '@/utils/sort';
import { Colors } from '@/Colors';
export default {
  name: 'QuestionnaireScoringAssessments',
  introduction: '',
  description: '',
  token: '<questionnaire-scoring-assessments />',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    InputField: InputField,
    ColorPicker: ColorPicker,
    Alert: Alert
  },
  props: {
    questionnaire: {
      type: Object,
      required: true
    },
    assessments: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Saved assessments'
    }
  },
  emits: ['changed'],
  data: function data() {
    return {
      itemToDelete: null,
      showConfirmationAlert: false,
      colors: Colors,
      items: [],
      // Get from questionnaire
      possibleCategoryTypes: {
        PERCENT: 'percent',
        VALUE: 'value'
      },
      possibleOperators: {
        GREATER_THAN_OR_EQUAL_TO: '>='
      },
      initialized: false,
      debounceTimer: null,
      debounceTimeout: 500,
      translate: getTranslate['QuestionnaireScoringAssessments']()
    };
  },
  beforeMount: function beforeMount() {
    this.populateItems();
    this.sortByValues(this.items);
  },
  methods: {
    onDataChanged: function onDataChanged() {
      var _this = this;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(function () {
        _this.$emit('changed', _this.prepareDataForEmit());
      }, this.debounceTimeout);
    },
    prepareDataForEmit: function prepareDataForEmit() {
      return {
        categories: this.items.map(function (item) {
          return {
            score: item.score,
            assessmentText: item.assessmentText,
            categoryType: item.categoryType,
            operator: item.operator,
            color: item.color,
            questionnaireId: item.questionnaireId
          };
        })
      };
    },
    addOption: function addOption() {
      var lastElem = this.items[this.items.length - 1];
      var newItem = lastElem ? JSON.parse(JSON.stringify(lastElem)) : this.createDefaultItem();
      if (lastElem) {
        newItem.id = null;
        newItem._uniqueId = this.uniqueId();
      }
      this.items.push(newItem);
    },
    createDefaultItem: function createDefaultItem() {
      return {
        id: null,
        _uniqueId: this.uniqueId(),
        questionnaireId: this.questionnaire.id,
        categoryType: this.possibleCategoryTypes.VALUE,
        operator: this.possibleOperators.GREATER_THAN_OR_EQUAL_TO,
        score: '0',
        assessmentText: '',
        color: ''
      };
    },
    uniqueId: function uniqueId() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'new';
      var today = new Date().getTime();
      return "unique-id-".concat(today, "-").concat(id);
    },
    populateItems: function populateItems() {
      var _this2 = this;
      this.items = _toConsumableArray(this.assessments).map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          _uniqueId: _this2.uniqueId(item.id),
          score: item.score + '',
          categoryType: item.categoryType
        });
      });
    },
    onDeleteConfirm: function onDeleteConfirm() {
      this.showConfirmationAlert = false;
      this.items.splice(this.items.indexOf(this.itemToDelete), 1);
      this.onDataChanged();
    },
    removeOption: function removeOption(item) {
      this.itemToDelete = item;
      this.showConfirmationAlert = true;
    },
    onColorChanged: function onColorChanged(item, e) {
      if (item.color !== e) {
        item.color = e;
        this.onDataChanged();
      }
    },
    sortByValues: function sortByValues(data) {
      if (data && data.length) {
        sortNumericByPropComplex(data, 'score');
      }
    },
    onBlur: function onBlur() {
      this.sortByValues(this.items);
    },
    humanOperator: function humanOperator(value) {
      var result = this.translate.unknownOperator;
      if (value === this.possibleOperators.GREATER_THAN_OR_EQUAL_TO) {
        result = this.translate.from;
      }
      return result;
    }
  }
};