function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { FileFormats, HiddenIconFormats } from '@/constants/FileFormats';
import { toLocalDateTimeFull, getDateDurationWithToday } from '@/utils/date';
export default {
  name: 'AttachmentItem',
  introduction: 'Item attachment uses in AttachmentDropdown',
  description: 'The component displays file information',
  token: '<attachment-item :file={} />',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle
  },
  props: {
    file: {
      type: Object,
      required: true,
      note: 'file info'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If attachments are readonly and can be downloaded'
    },
    hasVersionButtons: {
      type: Boolean,
      required: false,
      default: false,
      note: 'if item has version buttons (process library should have them)'
    },
    hideRemoveIcon: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If you need hide delete icon to implement it somewhere else'
    },
    hideEditIcon: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If you need hide edit icon to implement it somewhere else'
    },
    editFileEnabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'show edit button if attachment is not a link'
    }
  },
  emits: ['onUnpin', 'onCreateNewVersion', 'onAttachmentEdit', 'onShowVersions', 'onDelete'],
  data: function data() {
    return {
      translate: getTranslate['AttachmentItem']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useUsersStore, ['users'])), {}, {
    fileIcon: function fileIcon() {
      var fileName = this.file.mimeType && this.file.filename.split('.').pop();
      var icon = !HiddenIconFormats.includes(fileName) && FileFormats[fileName];
      /* only strict imports required https://esbuild.github.io/api/#non-analyzable-imports */
      switch (icon) {
        case 'csv':
          return require('@/assets/img/file-format/csv.svg');
        case 'doc':
          return require('@/assets/img/file-format/doc.svg');
        case 'docx':
          return require('@/assets/img/file-format/docx.svg');
        case 'pdf':
          return require('@/assets/img/file-format/pdf.svg');
        case 'pptx':
          return require('@/assets/img/file-format/pptx.svg');
        case 'tif':
          return require('@/assets/img/file-format/tif.svg');
        case 'tiff':
          return require('@/assets/img/file-format/tiff.svg');
        case 'txt':
          return require('@/assets/img/file-format/txt.svg');
        case 'xls':
          return require('@/assets/img/file-format/xls.svg');
        case 'xlsx':
          return require('@/assets/img/file-format/xlsx.svg');
        default:
          return require('@/assets/img/file-format/blank.svg');
      }
    },
    user: function user() {
      var _this = this;
      if (this.file.owner === this.currentUser.id) {
        return this.translate.byYou;
      }
      var user = this.users.find(function (user) {
        return user.id === _this.file.owner;
      });
      if (user) {
        return "".concat(user.fullname);
      }
      return '';
    },
    canBeDownloaded: function canBeDownloaded() {
      if (!this.file.url) {
        return false;
      }
      return this.file.url.endsWith('?download') || !this.file.url.toLowerCase().startsWith('http');
    },
    isImage: function isImage() {
      return this.file.mimeType && this.file.mimeType.startsWith('image');
    },
    fileCreatedTitle: function fileCreatedTitle() {
      var _getDateDurationWithT = getDateDurationWithToday(this.file.created),
        value = _getDateDurationWithT.value,
        type = _getDateDurationWithT.type;
      var typeValue = type ? this.$trans(type) + ' ' : '';
      return "".concat(value, " ").concat(typeValue).concat(this.translate.ago);
    },
    fileCreatedTitleHTML: function fileCreatedTitleHTML() {
      return toLocalDateTimeFull(this.file.created);
    },
    isAttachmentEdible: function isAttachmentEdible() {
      if (this.hideEditIcon) return false;
      return this.canBeDownloaded ? this.editFileEnabled : !this.hideEditIcon;
    }
  })
};