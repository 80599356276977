import { contact } from '@/test/data/contacts';
import ContactCard from '@/components/Atoms/ContactCard/ContactCard';
export default {
  name: '',
  components: {
    ContactCard: ContactCard
  },
  data: function data() {
    return {
      contact: contact
    };
  }
};