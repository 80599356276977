import UserEditModal from '@/components/Molecules/UserEditModal';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { getUsers, getOrganisationsAndFilterByUserAccess } from '@/api/risma/data';
export default {
  components: {
    UserEditModal: UserEditModal,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle
  },
  data: function data() {
    return {
      opened: false,
      type: '',
      information: {},
      users: {},
      allUsers: [],
      allOrganisations: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.getData().then(function () {
      return _this.users = _this.allUsers.slice(0, 5);
    });
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      return Promise.all([getUsers().then(function (_ref) {
        var list = _ref.list;
        return _this2.allUsers = list;
      }), getOrganisationsAndFilterByUserAccess().then(function (_ref2) {
        var list = _ref2.list;
        return _this2.allOrganisations = list;
      })]);
    },
    toogleModal: function toogleModal(type, data) {
      this.opened = !this.opened;
      this.type = type;
      this.information = data;
    },
    createAction: function createAction(data) {
      this.toogleModal('closed');
      alert(JSON.stringify(data));
    }
  }
};