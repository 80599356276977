import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  id: "questionnaireSelection",
  class: "my-5"
};
var _hoisted_2 = {
  class: "flex mb-4"
};
var _hoisted_3 = {
  class: "w-80 mr-4"
};
var _hoisted_4 = {
  class: "w-80"
};
var _hoisted_5 = {
  key: 1,
  class: "flex"
};
var _hoisted_6 = {
  class: "w-80 mr-4"
};
var _hoisted_7 = {
  class: "w-80 mr-4"
};
var _hoisted_8 = {
  class: "w-80"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_select = _resolveComponent("risma-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
    title: "".concat(_ctx.informationAssetsTitle(), " ").concat($data.translate.type),
    options: $options.assetTypes,
    "model-value": $data.selectedAssetType,
    "un-sorted": true,
    placeholder: $data.translate.chooseTypes,
    "onUpdate:modelValue": $options.assetTypeSelected
  }, null, 8 /* PROPS */, ["title", "options", "model-value", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
    title: $data.translate.questionnaire,
    options: $data.questionnairesOptions,
    "model-value": $data.selectedQuestionnaireId,
    "un-sorted": true,
    placeholder: $data.translate.selectAQuestionnaire,
    "onUpdate:modelValue": $options.questionnaireSelected
  }, null, 8 /* PROPS */, ["title", "options", "model-value", "placeholder", "onUpdate:modelValue"])])]), !$data.assetsLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_title, {
    title: _ctx.informationAssetsTitle(),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $options.availableAssetOptions,
    "selected-options": $data.selectedAssets,
    placeholder: $data.translate.selectSpecificAssets,
    "label-key": "title",
    onSelected: _cache[0] || (_cache[0] = function ($event) {
      return $data.selectedAssets = $event;
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder"])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.organisation,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $options.availableOrganisations,
    "selected-options": $data.selectedOrganisations,
    placeholder: $data.translate.selectOrganisations,
    "label-key": "label",
    onSelected: _cache[1] || (_cache[1] = function ($event) {
      return $data.selectedOrganisations = $event;
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder"])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: $data.translate.responsible,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $options.availableResponsible,
    "selected-options": $data.selectedResponsible,
    placeholder: $data.translate.selectResponsible,
    "label-key": "label",
    onSelected: _cache[2] || (_cache[2] = function ($event) {
      return $data.selectedResponsible = $event;
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder"])])]))]);
}