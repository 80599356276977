import { COLORS } from '@/constants/risks/RisksHeatMapColors';
export var prepareDefaultHeatMapColors = function prepareDefaultHeatMapColors(arraySize) {
  var colorsList = ['GREEN', 'YELLOW', 'ORANGE', 'RED'];
  var colorsMap = {};
  colorsList.forEach(function (colorName) {
    return colorsMap[colorName] = [];
  });
  var numberOfAvailableColors = 0;
  var colorKey = 1;
  while (COLORS["GREEN_".concat(colorKey)]) {
    numberOfAvailableColors++;
    colorKey++;
  }
  var totalAvailableColors = numberOfAvailableColors * colorsList.length;
  var colorFraction = arraySize / totalAvailableColors;
  var decimal = colorFraction - Math.floor(colorFraction);
  var numberOfRepetitionsOfAllColors = colorFraction - decimal;
  var numberDuplicatedColors = Math.ceil(decimal * totalAvailableColors / colorsList.length);
  for (var i = 1, colorCount = 0; colorCount < arraySize && i < 5; i++) {
    var colorsLeft = arraySize - colorCount;
    addColorsToMap(colorsMap, i, colorsLeft);
    colorCount += colorsLeft < colorsList.length ? colorsLeft : colorsList.length;
    if (numberDuplicatedColors >= i && numberOfRepetitionsOfAllColors) {
      addColorsToMap(colorsMap, i);
      colorCount += colorsList.length;
    }
  }
  return colorsList.map(function (color) {
    return colorsMap[color];
  }).flat();
};
var addColorsToMap = function addColorsToMap(colorsMap, colorIndex) {
  var numberOfColors = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 4;
  var colorsList = ['GREEN', 'RED', 'YELLOW', 'ORANGE'];
  numberOfColors < colorsList.length && colorsList.splice(numberOfColors);
  colorsList.forEach(function (colorName) {
    if (!COLORS["".concat(colorName, "_").concat(colorIndex)]) throw new Error('Color does not exist in the COLORS');
    if (colorName === 'GREEN') {
      colorsMap[colorName].unshift(COLORS["".concat(colorName, "_").concat(colorIndex)]);
    } else {
      colorsMap[colorName].push(COLORS["".concat(colorName, "_").concat(colorIndex)]);
    }
  });
  return colorsMap;
};