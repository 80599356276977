function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "container-full margin-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_delegation_well = _resolveComponent("delegation-well");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_activity_type_selector = _resolveComponent("activity-type-selector");
  var _component_solution_id_relation_details = _resolveComponent("solution-id-relation-details");
  var _component_dep_well = _resolveComponent("dep-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.isUserLocked ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    closeable: false,
    type: "info"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.thisActivityIsReadonlyAsYourUserIsConfigu), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_dep_well, {
    "is-loaded": $options.isLoaded,
    "dep-header-props": $options.depHeaderProps,
    item: $data.incident,
    "read-only": $options.readOnly,
    "is-user-locked": $options.isUserLocked,
    "dep-relations-tabs": $options.depRelationsTabs,
    "local-storage-key": "incident_dep_relations",
    onUpdatingAttachments: $options.updatingAttachments,
    onFileUpdate: $options.onFileUpdate,
    onPropertyChanged: $options.incidentPropertyChanged,
    onSaveStatusClicked: $options.saveStatusClicked,
    onGetActivityData: $options.getActivityData,
    onChangeTab: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('changeTab', $event);
    })
  }, {
    delegation: _withCtx(function () {
      return [_createVNode(_component_delegation_well, {
        "model-value": _objectSpread(_objectSpread({}, $data.incident), {}, {
          module: $data.module
        }),
        organisations: _ctx.organisations,
        "read-only": $options.readOnly,
        "current-activity-type": $options.currentActivityType,
        onPropertyChanged: $options.saveChangesImmediately
      }, null, 8 /* PROPS */, ["model-value", "organisations", "read-only", "current-activity-type", "onPropertyChanged"])];
    }),
    details: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        type: "medium",
        title: $data.translate.activityType
      }, null, 8 /* PROPS */, ["title"]), $data.incident && _ctx.activityTypes.length ? (_openBlock(), _createBlock(_component_activity_type_selector, {
        key: 0,
        modelValue: $data.incident.activityTypeId,
        "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
          return $data.incident.activityTypeId = $event;
        }), _cache[1] || (_cache[1] = function ($event) {
          return $options.incidentPropertyChanged({
            property: 'activityTypeId',
            value: $event
          });
        })],
        "activity-types": _ctx.activityTypes,
        "can-be-reset": false,
        disabled: $options.readOnly,
        "confirm-alert-message": $data.translate.areYouSureYouWantToChangeTheActivityType,
        class: "mb-5"
      }, null, 8 /* PROPS */, ["modelValue", "activity-types", "disabled", "confirm-alert-message"])) : _createCommentVNode("v-if", true), _ctx.getSettings('feature.performance_report_enabled') > 0 ? (_openBlock(), _createBlock(_component_solution_id_relation_details, {
        key: 1,
        list: $data.incident.solutionIds,
        "read-only": $options.readOnly,
        onPropertyChanged: $options.incidentPropertyChanged
      }, null, 8 /* PROPS */, ["list", "read-only", "onPropertyChanged"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-loaded", "dep-header-props", "item", "read-only", "is-user-locked", "dep-relations-tabs", "onUpdatingAttachments", "onFileUpdate", "onPropertyChanged", "onSaveStatusClicked", "onGetActivityData"])]);
}