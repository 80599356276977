var _myPageKeyByObjectTyp;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { ObjectTypes } from '@/constants/ObjectTypes';
import { getModuleTitles } from '@/constants/modules';
export var MypageKeys = {
  SHARED_REPORTS: 'sharedReports',
  MY_REPORTS: 'reports',
  MY_INITIATIVES: 'initiatives',
  MY_CONTROLS: 'controls',
  MY_RISKS: 'risks',
  MY_PROCESS: 'processes',
  MY_INCIDENTS: 'incidents',
  MY_CONTRACTS: 'contracts',
  PROCESS_LIBRARY: 'processTreeNodes',
  INFORMATION_ASSETS: 'informationAssets',
  UNANSWERED_AWARENESS: 'unansweredAwareness'
};
export var getCurrentActivities = function getCurrentActivities() {
  var _ref;
  var ModuleTitles = getModuleTitles();
  return _ref = {}, _defineProperty(_ref, ObjectTypes.INCIDENTS_INCIDENT, {
    title: ModuleTitles.incidents,
    icon: '/assets/svg/ikon_incident.svg'
  }), _defineProperty(_ref, ObjectTypes.INCIDENTS_INCIDENT_LIMITED, {
    title: ModuleTitles.incidents,
    icon: '/assets/svg/ikon_incident.svg'
  }), _defineProperty(_ref, ObjectTypes.CONTROLS_CONTROL, {
    title: ModuleTitles.control,
    icon: '/assets/svg/ikon_controls.svg'
  }), _defineProperty(_ref, ObjectTypes.CONTROLS_CONTROL_LIMITED, {
    title: ModuleTitles.control,
    icon: '/assets/svg/ikon_controls.svg'
  }), _defineProperty(_ref, ObjectTypes.COMPLIANCE_NODE, {
    title: ModuleTitles.compliance,
    icon: '/assets/svg/icon_compliance.svg'
  }), _defineProperty(_ref, ObjectTypes.COMPLIANCE_NODE_LIMITED, {
    title: ModuleTitles.compliance,
    icon: '/assets/svg/icon_compliance.svg'
  }), _defineProperty(_ref, ObjectTypes.RISK_RISK_LIMITED, {
    title: ModuleTitles.risk,
    icon: '/assets/svg/ikon_risk.svg'
  }), _defineProperty(_ref, ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE, {
    title: ModuleTitles.compliance_process_tree_node,
    icon: '/assets/svg/icon_compliance.svg'
  }), _defineProperty(_ref, ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE_LIMITED, {
    title: ModuleTitles.compliance_process_tree_node,
    icon: '/assets/svg/ikon_execution.svg'
  }), _defineProperty(_ref, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, {
    title: ModuleTitles.compliance,
    icon: '/assets/svg/icon_compliance.svg'
  }), _defineProperty(_ref, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, {
    title: ModuleTitles.compliance,
    icon: '/assets/svg/icon_compliance.svg'
  }), _defineProperty(_ref, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, {
    title: ModuleTitles.compliance,
    icon: '/assets/svg/icon_compliance.svg'
  }), _defineProperty(_ref, ObjectTypes.EXECUTION_INITIATIVE, {
    title: ModuleTitles.execution,
    icon: '/assets/svg/ikon_execution.svg'
  }), _defineProperty(_ref, ObjectTypes.EXECUTION_INITIATIVE_LIMITED, {
    title: ModuleTitles.execution,
    icon: '/assets/svg/ikon_execution.svg'
  }), _defineProperty(_ref, ObjectTypes.CONTRACT, {
    title: ModuleTitles.contracts,
    icon: '/assets/svg/ikon_contracts.svg'
  }), _defineProperty(_ref, ObjectTypes.CONTRACT_LIMITED, {
    title: ModuleTitles.contracts,
    icon: '/assets/svg/ikon_contracts.svg'
  }), _ref;
};
export var myPageKeyByObjectType = (_myPageKeyByObjectTyp = {}, _defineProperty(_myPageKeyByObjectTyp, ObjectTypes.RISK_RISK_LIMITED, MypageKeys.MY_RISKS), _defineProperty(_myPageKeyByObjectTyp, ObjectTypes.EXECUTION_INITIATIVE, MypageKeys.MY_INITIATIVES), _defineProperty(_myPageKeyByObjectTyp, ObjectTypes.EXECUTION_INITIATIVE_LIMITED, MypageKeys.MY_INITIATIVES), _defineProperty(_myPageKeyByObjectTyp, ObjectTypes.COMPLIANCE_NODE, MypageKeys.MY_PROCESS), _defineProperty(_myPageKeyByObjectTyp, ObjectTypes.COMPLIANCE_NODE_LIMITED, MypageKeys.MY_PROCESS), _defineProperty(_myPageKeyByObjectTyp, ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE, MypageKeys.PROCESS_LIBRARY), _defineProperty(_myPageKeyByObjectTyp, ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE_LIMITED, MypageKeys.PROCESS_LIBRARY), _defineProperty(_myPageKeyByObjectTyp, ObjectTypes.INCIDENTS_INCIDENT, MypageKeys.MY_INCIDENTS), _defineProperty(_myPageKeyByObjectTyp, ObjectTypes.INCIDENTS_INCIDENT_LIMITED, MypageKeys.MY_INCIDENTS), _defineProperty(_myPageKeyByObjectTyp, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, MypageKeys.INFORMATION_ASSETS), _defineProperty(_myPageKeyByObjectTyp, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, MypageKeys.INFORMATION_ASSETS), _defineProperty(_myPageKeyByObjectTyp, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, MypageKeys.INFORMATION_ASSETS), _defineProperty(_myPageKeyByObjectTyp, ObjectTypes.CONTROLS_CONTROL, MypageKeys.MY_CONTROLS), _defineProperty(_myPageKeyByObjectTyp, ObjectTypes.CONTRACT, MypageKeys.MY_CONTRACTS), _defineProperty(_myPageKeyByObjectTyp, ObjectTypes.CONTRACT_LIMITED, MypageKeys.MY_CONTRACTS), _myPageKeyByObjectTyp);