import i18n from '@/i18n';
export var getTranslate = {
  ProcessNodeAwareness: function ProcessNodeAwareness() {
    return {
      newRequestSignoff: i18n.t('New request sign-off'),
      requestsList: i18n.t('Requests list'),
      requestDocumentSignoff: i18n.t('Request document sign-off'),
      sendReminder: i18n.t('Send reminder'),
      thisRequestHasNotBeenSentClickTheButtonT: i18n.t('This request has not been sent! Click the button to edit or send request to the user list.'),
      requestSignoff: i18n.t('Request sign-off'),
      anErrorOccurred: i18n.t('An error occurred'),
      sent: i18n.t('Sent'),
      draft: i18n.t('Draft'),
      status: i18n.t('Status'),
      sendSignOff: i18n.t('Send sign-off'),
      goToAwarenessAndMonitorWhenTheUsersHave: i18n.t('Go to "Awareness" and monitor when the users have confirmed your request.'),
      duplicateEmailAddress: i18n.t('Duplicate email address'),
      pleaseStateOneOrMoreEmailsInThisField: i18n.t('Please state one or more e-mails in this field'),
      pleaseCheckEmailAddresses: i18n.t('Please, check email addresses'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      atLeastUserIsRequired: i18n.t('At least 1 user is required'),
      theReminderHasBeenSuccessfullySent: i18n.t('The reminder has been successfully sent'),
      theRequestHasBeenSuccessfullySent: i18n.t('The request has been successfully sent')
    };
  },
  ProcessTreePage: function ProcessTreePage() {
    return {
      yourActivityHasBeenCreated: i18n.t('Your activity has been created'),
      noProcessesFound: i18n.t('No processes found'),
      errorDuringCreation: i18n.t('Error during creation'),
      couldNotDeleteNode: i18n.t('Could not delete node')
    };
  },
  ProcessTreeReport: function ProcessTreeReport() {
    return {
      id: i18n.t('ID'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      activityType: i18n.t('Activity type'),
      organisation: i18n.t('Organisation'),
      linkedSystems: i18n.t('Linked systems'),
      linkedInitiatives: i18n.t('Linked initiatives'),
      linkedControls: i18n.t('Linked controls'),
      tags: i18n.t('Tags'),
      governanceReview: i18n.t('Governance review'),
      governanceReviewLastCompleted: i18n.t('Governance review last completed'),
      nextGovernanceReviewDate: i18n.t('Next governance review date'),
      attachedFiles: i18n.t('Attached files'),
      linked: i18n.t('Linked'),
      report: i18n.t('Report'),
      noProcessesFound: i18n.t('No processes found'),
      trafficLight: i18n.t('Traffic light'),
      reportSaved: i18n.t('Report saved')
    };
  }
};