import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "mb-2 border border-gray-350"
};
var _hoisted_3 = ["href"];
var _hoisted_4 = {
  key: 0,
  class: "mt-2"
};
var _hoisted_5 = {
  class: "overflow-x-auto max-h-500px"
};
var _hoisted_6 = {
  class: "min-w-full table-fixed"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$currentPrev;
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.currentModule ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createVNode(_component_risma_title, {
    title: $options.moduleOptions[$data.currentModule].title,
    class: "mt-3"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.translate.seeTemplateForExampleDataWithColumnHeadings), 1 /* TEXT */), _createVNode(_component_risma_title, {
    title: $data.translate.uploadXlsxFile,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [(_openBlock(), _createElementBlock("input", {
    key: $data.currentModule,
    class: "w-full p-2",
    type: "file",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.dropExistingFile && $options.dropExistingFile.apply($options, arguments);
    }),
    onChange: _cache[1] || (_cache[1] = function () {
      return $options.onFilesLoad && $options.onFilesLoad.apply($options, arguments);
    })
  }, null, 32 /* HYDRATE_EVENTS */))]), _createElementVNode("a", {
    href: $options.moduleOptions[$data.currentModule].fileSource
  }, _toDisplayString($data.translate.downloadExcelExampleHere), 9 /* TEXT, PROPS */, _hoisted_3), (_$options$currentPrev = $options.currentPreviewData) !== null && _$options$currentPrev !== void 0 && _$options$currentPrev.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.preview
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_5, [_createElementVNode("table", _hoisted_6, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.currentPreviewFields, function (value, key) {
    return _openBlock(), _createElementBlock("th", {
      key: key,
      class: "whitespace-nowrap p-3 text-left align-top"
    }, _toDisplayString(value), 1 /* TEXT */);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.currentPreviewData, function (item, index) {
    return _openBlock(), _createElementBlock("tr", {
      key: index,
      class: "border-t border-b border-gray-250 border-collapse even:bg-gray-250"
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, function (value, key) {
      return _openBlock(), _createElementBlock(_Fragment, null, [key in $options.currentPreviewFields ? (_openBlock(), _createElementBlock("td", {
        key: key,
        class: _normalizeClass(["p-3 text-left align-top", [$options.normalWhitespaceList.includes(key) ? 'whitespace-normal' : 'whitespace-nowrap', ['description', 'message'].includes(key) ? 'min-w-370px' : 'min-w-150px']])
      }, [key === 'trafficLight' && value ? (_openBlock(), _createBlock(_component_traffic_light, {
        key: 0,
        color: value.color
      }, null, 8 /* PROPS */, ["color"])) : (_openBlock(), _createElementBlock("span", {
        key: 1,
        class: _normalizeClass(key === 'message' ? item.success && item.message === 'ok' ? 'text-green-610 uppercase' : 'whitespace-pre-wrap text-red-610' : '')
      }, _toDisplayString(value), 3 /* TEXT, CLASS */))], 2 /* CLASS */)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
    }), 256 /* UNKEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */))])])]), _createVNode(_component_risma_button, {
    class: "mt-6",
    type: "save",
    text: $data.translate.import,
    onClick: $options.onImportButtonClick
  }, null, 8 /* PROPS */, ["text", "onClick"])])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)]);
}