import i18n from '@/i18n';
export var getTranslate = {
  GapTypesList: function GapTypesList() {
    return {
      gapTypes: i18n.t('Gap types'),
      gapTypeOptions: i18n.t('Gap type options'),
      pleaseEnterAValue: i18n.t('Please enter a value'),
      addGapTypeOption: i18n.t('Add gap type option'),
      areYouSure: i18n.t('Are you sure?'),
      saveGapTypes: i18n.t('Save gap types'),
      changesSaved: i18n.t('Changes saved'),
      somethingWentWrong: i18n.t('Something went wrong while updating'),
      pleaseFillOutAllFields: i18n.t('Please fill out all fields'),
      cancel: i18n.t('Cancel'),
      delete: i18n.t('Delete'),
      areYouSureYouWantToDeleteThisGapType: i18n.t('Are you sure you want to delete this gap type? It will be removed as an option, even if it has been selected')
    };
  },
  AdminControlPanel: function AdminControlPanel() {
    return {
      myActions: i18n.t('My actions'),
      myControls: i18n.t('My controls'),
      myRisks: i18n.t('My risks'),
      compliance: i18n.t('Compliance'),
      incidents: i18n.t('Incidents')
    };
  },
  AdminControls: function AdminControls() {
    return {
      controlsAdministration: i18n.t('Controls administration'),
      controlRiskType: i18n.t('Control risk type'),
      account: i18n.t('Account'),
      process: i18n.t('Process'),
      risk: i18n.t('Risk'),
      cannotDeleteRiskTypesThatAreInUse: i18n.t('Cannot delete risk types that are in use'),
      itemWasSuccessfullyDeleted: i18n.t('item was successfully deleted'),
      wasSuccessfullyCreated: i18n.t('was successfully created'),
      wasSuccessfullyUpdated: i18n.t('was successfully updated')
    };
  },
  AdminDocumentTypes: function AdminDocumentTypes() {
    return {
      documentTypesAdministration: i18n.t('Document types administration'),
      documentTypes: i18n.t('Document types'),
      delete: i18n.t('Delete'),
      edit: i18n.t('Edit'),
      cancel: i18n.t('Cancel'),
      areYouSure: i18n.t('Are you sure?'),
      title: i18n.t('Title'),
      documentTypeCreated: i18n.t('Document type created'),
      documentTypeUpdated: i18n.t('Document type updated'),
      documentTypeDeleted: i18n.t('Document type deleted'),
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      deleteDocumentType: i18n.t('Delete document type')
    };
  },
  AdminLoginsReport: function AdminLoginsReport() {
    return {
      loginReport: i18n.t('Login report'),
      filterByUsername: i18n.t('Filter by Username'),
      chooseUser: i18n.t('Choose user'),
      filterByDateRange: i18n.t('Filter by Date Range'),
      createReport: i18n.t('Create report'),
      nothingToShow: i18n.t('Nothing to show'),
      enterYourSearchCriteriaAboveToViewSearchRe: i18n.t('Enter your search criteria above to view search results'),
      timestamp: i18n.t('Timestamp'),
      username: i18n.t('Username'),
      groups: i18n.t('Groups')
    };
  },
  AdminRiskHeatMap: function AdminRiskHeatMap() {
    return {
      areYouSureYouWantToResetToDefaultColors: i18n.t('Are you sure you want to reset to default colors?'),
      areYouSure: i18n.t('Are you sure?'),
      ok: i18n.t('Ok'),
      cancel: i18n.t('Cancel'),
      riskMatrixSettings: i18n.t('Risk matrix settings'),
      selectProject: i18n.t('Select project'),
      enableCustomHeatmapForThisProject: i18n.t('Enable custom heat-map for this project'),
      toChangeColorInRiskMatrixPleaseClickOnOne: i18n.t('To change color in risk matrix please click on one of the squares'),
      resetOptions: i18n.t('Reset options'),
      saveSettings: i18n.t('Save settings')
    };
  },
  AdminSettingsChangelog: function AdminSettingsChangelog() {
    return {
      settingsChangelog: i18n.t('Settings changelog'),
      nothingToShow: i18n.t('Nothing to show'),
      setting: i18n.t('Setting'),
      from: i18n.t('From'),
      to: i18n.t('To'),
      date: i18n.t('Date'),
      changedBy: i18n.t('Changed by')
    };
  },
  AdminSwitchActivities: function AdminSwitchActivities() {
    return {
      switchActivitiesFromOneUserToAnother: i18n.t('Switch activities from one user to another'),
      activitiesWereAssigned: i18n.t('Activities were assigned'),
      chooseModules: i18n.t('Choose modules'),
      chooseUserToUnassignActivitiesFrom: i18n.t('Choose user to unassign activities from'),
      chooseUserToAssignActivitiesTo: i18n.t('Choose user to assign activities to'),
      submit: i18n.t('Submit'),
      selectdeselectAllActivities: i18n.t('Select/deselect all activities'),
      module: i18n.t('Module'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      attachedAs: i18n.t('Attached as'),
      noRecordsMatching: i18n.t('No records matching'),
      pendingReview: i18n.t('Pending review'),
      reviewRejected: i18n.t('Review rejected'),
      dailyTeam: i18n.t('Daily team'),
      responsible: i18n.t('Responsible'),
      notifications: i18n.t('Notifications'),
      dailyTeamusers: i18n.t('Daily team - Users'),
      dailyRiskOwner: i18n.t('Daily risk owner'),
      primaryRiskOwner: i18n.t('Primary risk owner'),
      removeUser: i18n.t('Remove user'),
      userWasRemovedFromTheActivity: i18n.t('User was removed from the activity')
    };
  },
  AdminSwitchOrganisations: function AdminSwitchOrganisations() {
    return {
      switchActivitiesFromOneOrganisationToAnother: i18n.t('Switch activities from one organisation to another'),
      activitiesWereAssigned: i18n.t('Activities were assigned'),
      chooseModules: i18n.t('Choose modules'),
      chooseOrganisationToUnassignActivitiesFrom: i18n.t('Choose organisation to unassign activities from'),
      chooseOrganisationToAssignActivitiesTo: i18n.t('Choose organisation to assign activities to'),
      submit: i18n.t('Submit'),
      selectdeselectAllActivities: i18n.t('Select/deselect all activities'),
      module: i18n.t('Module'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      noRecordsMatching: i18n.t('No records matching')
    };
  },
  AdminSwitchSolutions: function AdminSwitchSolutions() {
    return {
      updateSolutionIdsOnActivities: i18n.t('Update solution ID’s on activities'),
      activitiesWereAssigned: i18n.t('Activities were assigned'),
      chooseModules: i18n.t('Choose modules'),
      onlyShowActivitiesWithoutSolutionIds: i18n.t('Only show activities without solution ID’s'),
      chooseTheIdsToAddActivitiesTo: i18n.t('Choose the IDs to add activities to'),
      submit: i18n.t('Submit'),
      selectdeselectAllActivities: i18n.t('Select/deselect all activities'),
      module: i18n.t('Module'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      solutionId: i18n.t('Solution ID'),
      noRecordsMatching: i18n.t('No records matching')
    };
  },
  AdminUsersChangelog: function AdminUsersChangelog() {
    return {
      usersChangelog: i18n.t('Users changelog'),
      nothingToShow: i18n.t('Nothing to show'),
      user: i18n.t('User'),
      fieldName: i18n.t('Field name'),
      from: i18n.t('From'),
      to: i18n.t('To'),
      date: i18n.t('Date'),
      changedBy: i18n.t('Changed by'),
      unknownProject: i18n.t('Unknown project'),
      passwordWasChanged: i18n.t('Password was changed'),
      adminAccessLevel: i18n.t('Admin access level'),
      nonAdminAccessLevel: i18n.t('Non admin access level'),
      enabled: i18n.t('Enabled'),
      disabled: i18n.t('Disabled'),
      yes: i18n.t('Yes'),
      no: i18n.t('No')
    };
  },
  Companies: function Companies() {
    return {
      yourActivityHasBeenCreated: i18n.t('Your activity has been created'),
      deleteCompany: i18n.t('Delete company'),
      areYouSure: i18n.t('Are you sure?'),
      delete: i18n.t('Delete'),
      cancel: i18n.t('Cancel'),
      deleteContact: i18n.t('Delete contact'),
      companiesAdministration: i18n.t('Companies administration'),
      search: i18n.t('Search'),
      companies: i18n.t('Companies'),
      bankruptcyList: i18n.t('Bankruptcy list'),
      name: i18n.t('Name'),
      address: i18n.t('Address'),
      city: i18n.t('City'),
      zipcode: i18n.t('Zipcode'),
      countryCode: i18n.t('Country code'),
      vatNumber: i18n.t('VAT number')
    };
  },
  QuestionsAdmin: function QuestionsAdmin() {
    return {
      back: i18n.t('Back'),
      customQuestions: i18n.t('Custom questions'),
      questions: i18n.t('Questions'),
      changelog: i18n.t('Changelog'),
      addQuestion: i18n.t('Add question'),
      unexpectedError: i18n.t('Unexpected error'),
      headline: i18n.t('Headline'),
      questionNumber: i18n.t('Question number'),
      question: i18n.t('Question'),
      options: i18n.t('Options'),
      hideQuestion: i18n.t('Hide question'),
      showQuestion: i18n.t('Show question'),
      editQuestion: i18n.t('Edit question'),
      thisIsUserDefinedQuestionnaire: i18n.t('This is a user defined questionnaire. You can edit question texts and hide questions in the questionnaire.'),
      thisIsOneOfRismasStandardQuestionnaires: i18n.t('This is one of RISMA\'s standard questionnaires. If you want to change the question texts or hide questions, please make your own copy from the questionnaires tab above.'),
      noQuestionnaireIsSelected: i18n.t('No questionnaire is selected'),
      pleaseSelectAQuestionnaireToContinue: i18n.t('Please select a questionnaire to continue'),
      createQuestion: i18n.t('Create question'),
      deleteQuestion: i18n.t('Delete') + ' ' + i18n.t('Question').toLowerCase(),
      areYouSureYouWantToDeleteThisQuestion: "".concat(i18n.t('Are you sure you want to delete this'), " ").concat(i18n.t('Question').toLowerCase(), "?")
    };
  },
  ComplianceAssetsSettings: function ComplianceAssetsSettings() {
    return {
      assets: i18n.t('Assets'),
      name: i18n.t('Name'),
      defaultQuestionnaire: i18n.t('Default questionnaire'),
      selectDefaultQuestionnaire: i18n.t('Select default questionnaire'),
      settingsAreSaved: i18n.t('Settings are saved'),
      notSet: i18n.t('Not set'),
      defaultActivityType: i18n.t('Default activity type')
    };
  },
  ComplianceRiskAssessment: function ComplianceRiskAssessment() {
    return {
      riskAssessmentOnAssets: i18n.t('Risk Assessment on assets'),
      name: i18n.t('Name'),
      enableRiskAssessment: i18n.t('Enable risk assessment'),
      risksCreatedIn: i18n.t('Risks created in'),
      noRiskProject: i18n.t('No risk project'),
      riskAssessmentOnNodes: i18n.t('Risk assessment on nodes'),
      risksAreCreatedIn: i18n.t('Risks are created in'),
      rtoAndRpoFields: i18n.t('RTO and RPO fields'),
      pleaseEnterAValue: i18n.t('Please enter a value'),
      addValue: i18n.t('Add value'),
      settingsAreSaved: i18n.t('Settings are saved')
    };
  },
  ErmPeriodDescription: function ErmPeriodDescription() {
    return {
      period: i18n.t('Period'),
      title: i18n.t('Title'),
      thePeriodShouldConsistOfOnlyLettersAndNumb: i18n.t('The period should consist of only letters and numbers'),
      maxLengthIs30Characters: i18n.t('Max length is 30 characters'),
      otherXaxisParameters: i18n.t('Other X-axis parameters'),
      openThisPeriodAndCloseThePrevious: i18n.t('Open this period and close the previous.'),
      back: i18n.t('Back'),
      delete: i18n.t('Delete'),
      areYouSureYouWantToDeleteThis: i18n.t('Are you sure you want to delete this') + '?',
      saveAndOpen: i18n.t('Save and open'),
      save: i18n.t('Save'),
      otherParameters: i18n.t('Other parameters'),
      masterDataSaved: i18n.t('Master data saved.')
    };
  },
  OrganisationTree: function OrganisationTree() {
    return {
      organisations: i18n.t('Organisations'),
      addNew: i18n.t('Add new'),
      sortInAlphabeticalOrder: i18n.t('Sort in alphabetical order'),
      areYouSureYouWantToDeleteThisOrganisation: i18n.t('Are you sure you want to delete this organisation?'),
      deleteOrganisation: i18n.t('Delete organisation'),
      updateOrganisation: i18n.t('Update organisation'),
      addUnderlyingOrganisation: i18n.t('Add underlying organisation'),
      thisOrganisationHaveBeenRemovedForAllActivi: i18n.t('This organisation have been removed for all activities except following'),
      theOrganisationCannotBeRemovedAutomatically: i18n.t('The organisation cannot be removed automatically.'),
      pleaseContactRismaSupportForAssistance: i18n.t('Please contact RISMA support for assistance'),
      theOrganisationWasSuccessfullyDeleted: i18n.t('The organisation was successfully deleted'),
      theOrganisationHasBeenCreated: i18n.t('The organisation has been created')
    };
  },
  QuestionnaireAdmin: function QuestionnaireAdmin() {
    return {
      questionnaires: i18n.t('Questionnaires'),
      chooseQuestionnaireType: i18n.t('Choose questionnaire type'),
      selectOne: i18n.t('Select one'),
      thereAreNoQuestionnairesOfTheSelectedType: i18n.t('There are no questionnaires of the selected type'),
      name: i18n.t('Name'),
      tabLabel: i18n.t('Tab label'),
      defaultGapSchema: i18n.t('Default gap schema'),
      questionCount: i18n.t('Question count'),
      activated: i18n.t('Activated'),
      questions: i18n.t('Questions'),
      scoring: i18n.t('Scoring'),
      copy: i18n.t('Copy'),
      delete: i18n.t('Delete'),
      edit: i18n.t('Edit'),
      dataProcessorCount: i18n.t('Data Processor count'),
      systemCount: i18n.t('System count'),
      controllersCount: i18n.t('Controllers count'),
      dpmAuditCount: i18n.t('DPM Audit count'),
      auditCount: i18n.t('Audit count'),
      processCount: i18n.t('Process count'),
      theQuestionnaireCantBeDisabledErrorMessage: i18n.t('The questionnaire can’t be disabled because it is used as default questionnaire for this module. Remove it as default questionnaire to deactivate it.'),
      somethingWentWrongWhileDeleting: i18n.t('Something went wrong while deleting'),
      cancel: i18n.t('Cancel'),
      ok: i18n.t('Ok'),
      areYouSure: i18n.t('Are you sure?'),
      enterNewTitle: i18n.t('Enter new title'),
      enterNewTabLabel: i18n.t('Enter new tab label'),
      deleteQuestionnaire: i18n.t('Delete') + ' ' + i18n.t('Questionnaire').toLowerCase(),
      areYouSureYouWantToDeleteThisQuestionnaire: "".concat(i18n.t('Are you sure you want to delete this'), " ").concat(i18n.t('Questionnaire').toLowerCase(), "?")
    };
  },
  WorkflowStatuses: function WorkflowStatuses() {
    return {
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      deleteWorkflowStatus: i18n.t('Delete workflow status'),
      delete: i18n.t('Delete'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      order: i18n.t('Order'),
      selectOrder: i18n.t('Select order'),
      save: i18n.t('Save'),
      create: i18n.t('Create'),
      workflowStatusesAdministration: i18n.t('Workflow statuses administration'),
      statuses: i18n.t('Statuses'),
      module: i18n.t('Module'),
      contracts: i18n.t('Contracts'),
      pleaseFillOutTheTitle: i18n.t('Please fill out the title'),
      titleHaveToBeUnique: i18n.t('The title must be unique'),
      updateWorkflowStatus: i18n.t('Update workflow status'),
      addWorkflowStatus: i18n.t('Add workflow status'),
      noChangesToSave: i18n.t('No changes to save'),
      created: i18n.t('created'),
      updated: i18n.t('updated'),
      deleted: i18n.t('deleted'),
      workflowStatus: i18n.t('Workflow status')
    };
  },
  GapSchemasAdmin: function GapSchemasAdmin() {
    return {
      gapSchemas: i18n.t('Gap schemas'),
      chooseGapSchemaType: i18n.t('Choose gap schema type'),
      selectOne: i18n.t('Select one'),
      thereAreNoGapSchemasOfTheSelectedType: i18n.t('There are no gap schemas of the selected type'),
      name: i18n.t('Name'),
      gapCount: i18n.t('Gap count'),
      processCount: i18n.t('Process count'),
      activated: i18n.t('Activated'),
      copy: i18n.t('Copy'),
      delete: i18n.t('Delete'),
      edit: i18n.t('Edit'),
      ok: i18n.t('Ok'),
      cancel: i18n.t('Cancel'),
      enterNewTitle: i18n.t('Enter new title'),
      deleteGapSchema: i18n.t('Delete') + ' ' + i18n.t('Gap schema').toLowerCase(),
      areYouSureYouWantToDeleteThisGapSchema: "".concat(i18n.t('Are you sure you want to delete this'), " ").concat(i18n.t('Gap schema').toLowerCase(), "?")
    };
  },
  GapSchemasAdminArticles: function GapSchemasAdminArticles() {
    return {
      complianceAdministration: i18n.t('Compliance administration'),
      back: i18n.t('Back'),
      articles: i18n.t('Articles'),
      editArticle: i18n.t('Edit Article'),
      deleteArticle: i18n.t('Delete Article'),
      article: i18n.t('Article'),
      articleTitle: i18n.t('Article Title'),
      requirement: i18n.t('Requirement'),
      domain: i18n.t('Domain'),
      options: i18n.t('Options'),
      areYouSureYouWantToDeleteThisArticle: i18n.t('Are you sure you want to delete this article?'),
      allAnalysesAndLinksWillBeRemoved: i18n.t('All analyses and links will be removed.'),
      thisCannotBeUndone: i18n.t('This cannot be undone.'),
      addArticle: i18n.t('Add article')
    };
  },
  LiaTiaAdmin: function LiaTiaAdmin() {
    return {
      liaAndTiaSettings: i18n.t('LIA and TIA settings'),
      selectOne: i18n.t('Select one'),
      select: i18n.t('Select'),
      noGapSchemaAvailable: i18n.t('No gap schema available'),
      newSetting: i18n.t('New setting'),
      createNewLiatiaSetting: i18n.t('Create new LIA/TIA setting'),
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      createNew: i18n.t('Create new'),
      title: i18n.t('Title'),
      enabled: i18n.t('Enabled'),
      chooseQuestionnaire: i18n.t('Choose questionnaire'),
      chooseQuestion: i18n.t('Choose question'),
      chooseQuestionAnswers: i18n.t('Choose question answers'),
      chooseGapSchema: i18n.t('Choose gap schema'),
      chooseGapArticle: i18n.t('Choose gap article'),
      liatiaQuestionnaire: i18n.t('LIA/TIA questionnaire'),
      pleaseSelectAProjectToContinue: i18n.t('Please select a project to continue')
    };
  },
  ProjectsAdmin: function ProjectsAdmin() {
    return {
      selectOne: i18n.t('Select one'),
      select: i18n.t('Select'),
      title: i18n.t('Title'),
      questionnaire: i18n.t('Questionnaire'),
      gapSchemaName: i18n.t('Gap schema name'),
      solutionId: i18n.t('Solution ID'),
      activityType: i18n.t('Activity type'),
      enableRecommendedActivitiesForGaps: i18n.t('Enable recommended activities for gaps'),
      enableHeatMapsInGapSchema: i18n.t('Enable heat maps in gap schema'),
      enableGapTypes: i18n.t('Enable gap types'),
      riskPrGap: i18n.t('Risk pr. gap'),
      complianceProjectAdministration: i18n.t('Compliance projects'),
      settingsAreSaved: i18n.t('Settings are saved'),
      activated: i18n.t('Activated'),
      deactivated: i18n.t('Deactivated'),
      overallDescriptionTitle: i18n.t('Overall description title'),
      purposeTitle: i18n.t('Purpose title'),
      processStatusTitle: i18n.t('Process status title')
    };
  },
  ProjectsNodeTemplates: function ProjectsNodeTemplates() {
    return {
      templateNodes: i18n.t('Template nodes'),
      selectOne: i18n.t('Select one'),
      title: i18n.t('Title'),
      enable: i18n.t('Enable'),
      selectTemplateNode: i18n.t('Select template node')
    };
  },
  QuestionnaireScoringAssessments: function QuestionnaireScoringAssessments() {
    return {
      scoringAssessments: i18n.t('Scoring assessments'),
      noItems: i18n.t('No items'),
      operator: i18n.t('Operator'),
      score: i18n.t('Score'),
      assessmentText: i18n.t('Assessment text'),
      color: i18n.t('Color'),
      addRow: i18n.t('Add row'),
      areYouSure: i18n.t('Are you sure?'),
      unknownOperator: i18n.t('Unknown operator'),
      from: i18n.t('From'),
      deleteRow: i18n.t('Delete row'),
      areYouSureYouWantToDeleteThisRow: i18n.t('Are you sure you want to delete this row?')
    };
  },
  QuestionRules: function QuestionRules() {
    return {
      rismaLogic: i18n.t('RISMA logic'),
      chooseQuestion: i18n.t('Choose question'),
      chooseAnswer: i18n.t('Choose answer'),
      chooseGapArticle: i18n.t('Choose gap article'),
      chooseResult: i18n.t('Choose result'),
      add: i18n.t('Add'),
      saveAndApply: i18n.t('Save and apply'),
      youMustSelectBothAQuestionnaireAndAGapSch: i18n.t('You must select both a questionnaire and a gap schema'),
      saved: i18n.t('Saved'),
      validationError: i18n.t('Validation error'),
      youMustSelectAllQuestionAnswerGapArticle: i18n.t('You must select all: question, answer, gap article and result')
    };
  },
  TemplateList: function TemplateList() {
    return {
      templates: i18n.t('Templates'),
      create: i18n.t('Create'),
      thereAreNoTemplatesOfTheSelectedType: i18n.t('There are no templates of the selected type'),
      name: i18n.t('Name'),
      type: i18n.t('Type'),
      tabLabel: i18n.t('Tab label'),
      questionCount: i18n.t('Question count'),
      date: i18n.t('Date'),
      gapCount: i18n.t('Gap count')
    };
  },
  DataImport: function DataImport() {
    return {
      importStatus: i18n.t('Import status'),
      activityType: i18n.t('Activity type'),
      contractNo: i18n.t('Contract no.'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      status: i18n.t('Status'),
      startDate: i18n.t('Start date'),
      endDate: i18n.t('End date'),
      contractAmount: i18n.t('Contract amount'),
      contractAmountCurrency: i18n.t('Contract amount currency'),
      renewalAmount: i18n.t('Renewal amount'),
      renewalAmountCurrency: i18n.t('Renewal amount currency'),
      renewalDeadline: i18n.t('Renewal deadline'),
      terminationDeadline: i18n.t('Termination deadline'),
      contractVersion: i18n.t('Contract version'),
      responsible: i18n.t('Responsible'),
      accountable: i18n.t('Accountable'),
      consulted: i18n.t('Consulted'),
      informed: i18n.t('Informed'),
      organisations: i18n.t('Organisations'),
      companies: i18n.t('Companies'),
      contacts: i18n.t('Contacts'),
      type: i18n.t('Type'),
      uniqueId: i18n.t('Unique id'),
      trafficLight: i18n.t('Traffic light'),
      name: i18n.t('Name'),
      address: i18n.t('Address'),
      zipcode: i18n.t('Zipcode'),
      city: i18n.t('City'),
      countryCode: i18n.t('Country code'),
      phoneNumber: i18n.t('Phone number'),
      vatNumber: i18n.t('VAT number'),
      regNumber: i18n.t('Reg. number'),
      contactName: i18n.t('Contact name'),
      email: i18n.t('Email'),
      questionnaire: i18n.t('Questionnaire'),
      purpose: i18n.t('Purpose'),
      location: i18n.t('Location'),
      tags: i18n.t('Tags'),
      dataImport: i18n.t('Data import'),
      informationAssetsImport: i18n.t('Information assets import'),
      contractImport: i18n.t('Contract import'),
      invalidFileTypePleaseUploadAnyOfTheseFile: i18n.t('Invalid file type. Please upload any of these file types'),
      riskProjectName: i18n.t('Risk project'),
      riskNumber: i18n.t('Risk number'),
      primaryOwner: i18n.t('Primary owner'),
      likelihood: i18n.t('Likelihood'),
      impact: i18n.t('Impact'),
      organisation: i18n.t('Organisation'),
      riskImport: i18n.t('Risk import'),
      controlsImport: i18n.t('Controls import'),
      deadline: i18n.t('Deadline'),
      isMotherControl: i18n.t('Is mother control'),
      motherControl: i18n.t('Mother Control'),
      reviewer: i18n.t('Reviewer')
    };
  },
  ExcelDataImport: function ExcelDataImport() {
    return {
      actionsImport: i18n.t('Actions import'),
      seeTemplateForExampleDataWithColumnHeadings: i18n.t('See template for example data with column headings. The basic structure of the sheet must not be changed!'),
      uploadXlsxFile: i18n.t('Upload XLSX file'),
      downloadExcelExampleHere: i18n.t('Download Excel example here'),
      gapAnalysisImport: i18n.t('GAP analysis import'),
      uploadCsvFile: i18n.t('Upload CSV file'),
      complianceNodeImport: i18n.t('Compliance node import'),
      riskImport: i18n.t('Risk import')
    };
  },
  PreviewDataImport: function PreviewDataImport() {
    return {
      controlsImport: i18n.t('Controls import'),
      contractImport: i18n.t('Contract import'),
      seeTemplateForExampleDataWithColumnHeadings: i18n.t('See template for example data with column headings. The basic structure of the sheet must not be changed!'),
      uploadXlsxFile: i18n.t('Upload XLSX file'),
      downloadExcelExampleHere: i18n.t('Download Excel example here'),
      informationAssetsImport: i18n.t('Information assets import'),
      riskImport: i18n.t('Risk import'),
      preview: i18n.t('Preview'),
      import: i18n.t('Import'),
      pleaseCorrectTheErrorsInTheSubmittedImport: i18n.t('Please correct the errors in the submitted import')
    };
  },
  GapSchemasAdminAttributes: function GapSchemasAdminAttributes() {
    return {
      valueShouldBeUnique: i18n.t('Value should be unique'),
      save: i18n.t('Save'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      attributesAreSaved: i18n.t('Attributes are saved'),
      createNewAttribute: i18n.t('Create new attribute'),
      unexpectedError: i18n.t('Unexpected error'),
      noChangesToSave: i18n.t('No changes to save')
    };
  },
  RismaAdmin: function RismaAdmin() {
    return {
      username: i18n.t('Username'),
      login: i18n.t('Login'),
      groups: i18n.t('Groups'),
      emailHasBeenSent: i18n.t('E-mail has been sent.'),
      somethingWentWrong: i18n.t('Something went wrong!'),
      tryAgainOrIfTheIssue: i18n.t('Try again or if this issue persists please contact Risma support.'),
      somethingWentWrongWhileUploading: i18n.t('Something went wrong while updating'),
      changesSaved: i18n.t('Changes saved')
    };
  }
};