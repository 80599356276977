function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { MODULES } from '@/constants/modules';
export default {
  name: 'ExcelDataImport',
  introduction: '',
  description: '',
  token: '<excel-data-import></excel-data-import>',
  components: {
    RismaTitle: RismaTitle
  },
  props: {
    modulesEnabled: {
      type: Array,
      required: false,
      default: function _default() {
        return [MODULES.EXECUTION, MODULES.COMPLIANCE_GAP, MODULES.COMPLIANCE, MODULES.CONTROLS];
      }
    },
    fileTypesAllowed: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['loadData'],
  data: function data() {
    return {
      translate: getTranslate['ExcelDataImport']()
    };
  },
  computed: {
    modules: function modules() {
      return MODULES;
    },
    moduleOptions: function moduleOptions() {
      var _ref;
      return _ref = {}, _defineProperty(_ref, MODULES.EXECUTION, {
        title: this.translate.actionsImport,
        fileSource: '/examples/ActionsImportExample.xlsx',
        fileType: this.fileTypesAllowed.xls
      }), _defineProperty(_ref, MODULES.COMPLIANCE_GAP, {
        title: this.translate.gapAnalysisImport,
        fileSource: '',
        fileType: this.fileTypesAllowed.csv
      }), _defineProperty(_ref, MODULES.COMPLIANCE, {
        title: this.translate.complianceNodeImport,
        fileSource: '/examples/NodeImportExample.xlsx',
        fileType: this.fileTypesAllowed.xls
      }), _ref;
    }
  },
  methods: {
    dropExistingFile: function dropExistingFile(e) {
      if (e.target.value) {
        e.target.value = null;
      }
    },
    upload: function upload(e, module) {
      this.$emit('loadData', {
        files: e.target.files,
        module: module,
        params: {}
      });
    }
  }
};