function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import LinkItem from '@/components/Atoms/Containers/LinkItem';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Alert from '@/components/Molecules/Modal/Alert';
import AttachmentModal from '@/components/Organisms/AttachmentModal';
import { AttachmentModalMixin } from '@/components/Organisms/AttachmentModalMixin';
import * as fileAPI from '@/api/risma/file';
import { getRisma1AttachmentUrl } from '@/utils/regex';
import FilePreviewModal from '@/components/Molecules/FilePreviewModal/FilePreviewModal';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { MODULES } from '@/constants/modules';
export default {
  name: 'AttachmentSelector',
  introduction: 'AttachmentSelector is modal for choosing files & list of choosed files',
  description: "AttachmentSelector gives opportunity to choose file by AttachmentModal and shown a list of uploaded attachments.\n    Could set existed files by property 'files-existed'.\n    UpdatedFiles event will fire with all choosen files (array) previously. This event would be fired for each attempt to add attachment so be careful when updating parent object to avoid duplication of attachments",
  token: '<attachment-selector :files-existed="[{}, {}]" @updatedFiles="fn"/>',
  components: {
    FeatherIcon: FeatherIcon,
    FilePreviewModal: FilePreviewModal,
    LinkItem: LinkItem,
    RismaTitle: RismaTitle,
    AttachmentModal: AttachmentModal,
    Alert: Alert
  },
  mixins: [AttachmentModalMixin],
  props: {
    attachmentsExisted: {
      type: Array,
      required: false,
      note: 'Array of objects of existed attachments. Each object must contains filename, url properties.',
      default: function _default() {
        return [];
      }
    },
    ownerId: {
      type: Number,
      required: true,
      note: 'An id of owner of an attachments'
    },
    module: {
      type: String,
      required: true,
      note: 'A name of module, for example, "control" or "risk"'
    },
    moduleId: {
      type: [String, Number],
      required: false,
      default: '',
      note: 'An id of one of the entities of modules. For example, an cpath of the specific control'
    },
    remote: {
      type: Boolean,
      default: true,
      note: 'If true, the component will upload the attachments to the database instantly. Usually should be true for existing items (controls, incidents etc) and false for new (attachment would be stored locally and uploaded only after parent item creation)'
    },
    removable: {
      type: Boolean,
      default: true,
      required: false,
      note: 'Manage removing buttons in each attachment'
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
      note: 'If not possible to add attachments'
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Gives posibillity to load multiple attachment in one'
    },
    field: {
      type: String,
      required: false,
      note: 'Field for components where more than one attachment section can exist',
      default: ''
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false,
      note: 'If false, "Attachments" title will not be shown'
    },
    previewPdf: {
      type: Boolean,
      default: true,
      required: false,
      note: 'Display "Show preview" link'
    }
  },
  emits: ['updatedFiles'],
  data: function data() {
    return {
      idToDelete: null,
      showConfirmationAlert: false,
      files: [],
      isUploading: false,
      uploadingValue: 0,
      previewFile: null,
      isModalOpened: false,
      translate: {
        showPreview: this.$trans('Show preview'),
        attachments: this.$trans('Attachments'),
        areYouSureYouWantToDeleteThisDocument: this.$trans('Are you sure you want to delete this document?')
      }
    };
  },
  computed: {
    title: function title() {
      return this.translate.attachments;
    }
  },
  watch: {
    attachmentsExisted: function attachmentsExisted() {
      this.files = [].concat(this.attachmentsExisted);
    }
  },
  created: function created() {
    this.files = [].concat(this.attachmentsExisted);
    this.modifyFiles();
  },
  methods: {
    toggleModal: function toggleModal() {
      this.isModalOpened = !this.isModalOpened;
    },
    addAttachmentsRemote: function addAttachmentsRemote(attachments) {
      var _this = this;
      this.isUploading = true;
      fileAPI.uploadFiles(attachments, {
        onUploadProgress: function onUploadProgress(progressEvent) {
          _this.uploadingValue = Math.round(progressEvent.loaded * 100 / progressEvent.total);
        }
      }).then(function (uploadedAttachments) {
        _this.addAttachmentLocally(uploadedAttachments);
        _this.isUploading = false;
      }).catch(function (error) {
        _this.attachFileError = true;
        _this.attachFileErrorMessage = _this.handleResponseError(error);
        _this.isUploading = false;
      });
    },
    addAttachmentLocally: function addAttachmentLocally(attachments) {
      var _this2 = this;
      attachments.forEach(function (attach) {
        var attachFormatted = Object.assign({}, attach, _objectSpread(_objectSpread({
          type: attach.mimeType ? 'file' : 'link'
        }, attach.url ? null : {
          url: '#'
        }), attach.id ? null : {
          id: _this2.files[_this2.files.length - 1] && _this2.files[_this2.files.length - 1].id ? parseInt(_this2.files[_this2.files.length - 1].id) + 1 : 1
        }));
        _this2.files.push(attachFormatted);
      });
      this.modifyFiles();
      this.emitUpdatedFiles();
      this.toggleModal();
    },
    modifyFiles: function modifyFiles() {
      // For Controls only (temp solution, waiting for proper working Risma2 backend)
      if (this.module === MODULES.CONTROLS) {
        this.files.map(function (file) {
          if (file && file.url) {
            file.url = getRisma1AttachmentUrl(file.url);
          }
        });
      }
    },
    handleAttachmentsNew: function handleAttachmentsNew(attachmentsNew) {
      this.attachFileError = false;
      this.attachFileErrorMessage = this.defaultErrorMessage;
      if (!attachmentsNew.length) {
        return;
      }
      if (this.remote) {
        if (!this.moduleId) {
          throw new Error('The property "moduleId" is require for remote mode');
        }
      }
      var attachmentBase = {
        module: this.module,
        moduleId: this.remote ? this.moduleId : '',
        owner: this.ownerId,
        field: this.field
      };
      var attachments = attachmentsNew.map(function (attach) {
        return Object.assign({}, attachmentBase, attach);
      });
      if (this.remote) {
        this.addAttachmentsRemote(attachments);
      } else {
        this.addAttachmentLocally(attachments);
      }
    },
    removeAttachmentRemote: function removeAttachmentRemote(id) {
      return fileAPI.deleteFile(id);
    },
    removeAttachmentLocally: function removeAttachmentLocally(id) {
      this.files = this.files.filter(function (file) {
        return file.id !== id;
      });
      this.emitUpdatedFiles();
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var _this3 = this;
      this.showConfirmationAlert = false;
      if (!this.removable) {
        throw new Error('The property "removable" is off');
      }
      if (this.remote) {
        this.removeAttachmentRemote(this.idToDelete).then(function () {
          return _this3.removeAttachmentLocally(_this3.idToDelete);
        }).catch(function () {
          return _this3.actionError = true;
        });
      } else {
        this.removeAttachmentLocally(this.idToDelete);
      }
    },
    remove: function remove(attachmentId) {
      this.idToDelete = attachmentId;
      this.showConfirmationAlert = true;
    },
    selectFilePreview: function selectFilePreview(file) {
      this.previewFile = {
        fileSrc: file.url,
        fileType: file.mimeType === 'application/pdf' ? 'pdf' : 'image'
      };
    },
    emitUpdatedFiles: function emitUpdatedFiles() {
      this.$emit('updatedFiles', {
        files: this.files,
        field: this.field
      });
    }
  }
};