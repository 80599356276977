import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_code_highlight = _resolveComponent("code-highlight");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_code_highlight, {
    code: "<app>HTML 5 app</app>",
    desc: "It's simple app"
  })]);
}