import { Http } from '../index';
import { checkHttpStatus, throwError, handleRequestResponse } from '../httpUtils';
export function getGapDashboard(projectId, filter) {
  var filterParam = filter ? '&' + filter : '';
  return handleRequestResponse(Http.get("/compliance/projects/".concat(projectId, "/dashboard/gap?").concat(filterParam)));
}
export function getQuestionDashboard(projectId, filter) {
  return Http.get("/compliance/projects/".concat(projectId, "/dashboard/questions?").concat(filter)).then(function (response) {
    return checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return throwError(err);
  });
}