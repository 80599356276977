"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setFrequencies = exports.isSignoffPeriodPending = void 0;
// @ts-ignore
var RismaControlsUtils_1 = require("@/utils/RismaControlsUtils");
// @ts-ignore
var modules_1 = require("@/constants/modules");
// @ts-ignore
var i18n_1 = require("@/i18n");
/**
 * Detect if the complete control buttons should be grey (i.e. disabled)
 *
 * @param signoffStart
 * @param controlFrequency
 * @param trafficLight
 * @param settings
 */
function isSignoffPeriodPending(signoffStart, controlFrequency, trafficLight, settingsFunction) {
    // Short-time frequencies don't make sense for this feature
    if (controlFrequency === RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_DAILY ||
        controlFrequency === RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_BUSINESS_DAYS ||
        controlFrequency === RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_WEEKLY ||
        controlFrequency === RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_BIWEEKLY) {
        return false;
    }
    if (settingsFunction('risma.signoff_period_traffic_light')) {
        if (trafficLight == RismaControlsUtils_1.RismaControlsUtils.trafficLights.red || trafficLight == RismaControlsUtils_1.RismaControlsUtils.trafficLights.yellow) {
            return false;
        }
        return true; // Green TL
    }
    if (!settingsFunction('risma.signoff_period') || !signoffStart) {
        return false;
    }
    var now = Date.now();
    var dateSignoff = new Date(signoffStart).getTime();
    return now < dateSignoff;
}
exports.isSignoffPeriodPending = isSignoffPeriodPending;
function setFrequencies(settingsFunction, module) {
    var result = [
        {
            id: 1,
            label: i18n_1.i18n.t(RismaControlsUtils_1.RismaControlsUtils.frequency2text(RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_DAILY)),
            value: RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_DAILY
        },
        {
            id: 8,
            label: i18n_1.i18n.t(RismaControlsUtils_1.RismaControlsUtils.frequency2text(RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_BUSINESS_DAYS)),
            value: RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_BUSINESS_DAYS
        },
        {
            id: 2,
            label: i18n_1.i18n.t(RismaControlsUtils_1.RismaControlsUtils.frequency2text(RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_WEEKLY)),
            value: RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_WEEKLY
        },
        {
            id: 3,
            label: i18n_1.i18n.t(RismaControlsUtils_1.RismaControlsUtils.frequency2text(RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_BIWEEKLY)),
            value: RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_BIWEEKLY
        },
        {
            id: 4,
            label: i18n_1.i18n.t(RismaControlsUtils_1.RismaControlsUtils.frequency2text(RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_MONTHLY)),
            value: RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_MONTHLY
        },
        {
            id: 5,
            label: i18n_1.i18n.t(RismaControlsUtils_1.RismaControlsUtils.frequency2text(RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_QUARTERLY)),
            value: RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_QUARTERLY
        },
        {
            id: 6,
            label: i18n_1.i18n.t(RismaControlsUtils_1.RismaControlsUtils.frequency2text(RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_BIANNUALLY)),
            value: RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_BIANNUALLY
        },
        {
            id: 7,
            label: i18n_1.i18n.t(RismaControlsUtils_1.RismaControlsUtils.frequency2text(RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_ANNUALLY)),
            value: RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_ANNUALLY
        },
        {
            id: 9,
            label: i18n_1.i18n.t(RismaControlsUtils_1.RismaControlsUtils.frequency2text(RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_TWO_YEARS)),
            value: RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_TWO_YEARS
        },
        {
            id: 10,
            label: i18n_1.i18n.t(RismaControlsUtils_1.RismaControlsUtils.frequency2text(RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_THREE_YEARS)),
            value: RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_THREE_YEARS
        },
        {
            id: 11,
            label: i18n_1.i18n.t(RismaControlsUtils_1.RismaControlsUtils.frequency2text(RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_FOUR_YEARS)),
            value: RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_FOUR_YEARS
        },
        {
            id: 12,
            label: i18n_1.i18n.t(RismaControlsUtils_1.RismaControlsUtils.frequency2text(RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_FIVE_YEARS)),
            value: RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_FIVE_YEARS
        },
        {
            id: 13,
            label: i18n_1.i18n.t(RismaControlsUtils_1.RismaControlsUtils.frequency2text(RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_ONE_TIME)),
            value: RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_ONE_TIME
        },
    ];
    if (settingsFunction('feature.custom_deadlines') && module === modules_1.MODULES.CONTROLS) {
        result.push({
            id: 14,
            label: i18n_1.i18n.t(RismaControlsUtils_1.RismaControlsUtils.frequency2text(RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_CUSTOM)),
            value: RismaControlsUtils_1.RismaControlsUtils.frequencies.FREQ_CUSTOM
        });
    }
    return result;
}
exports.setFrequencies = setFrequencies;
;
