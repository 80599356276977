import i18n from '@/i18n';
export var getTranslate = {
  InformationAssetsDetailsRelations: function InformationAssetsDetailsRelations() {
    return {
      warning: i18n.t('Warning'),
      ok: i18n.t('Ok'),
      cancel: i18n.t('Cancel'),
      activityType: i18n.t('Activity type'),
      gapSchemaVersion: i18n.t('GAP schema version'),
      choose: i18n.t('Choose'),
      updateGapSchemaMessage: i18n.t('You have enabled a new questionnaire, it has a gap schema connected to it. Would you like to change the gap schema as well? NB: By replacing the gap schema, the existing one will be removed with all its data.')
    };
  },
  OverlyingUnderlyingInformationAssets: function OverlyingUnderlyingInformationAssets() {
    return {
      searchOrCreateNewProcessor: i18n.t('Search or create new processor'),
      addThisAsNewProcessor: i18n.t('Add this as new processor'),
      chooseParentSystem: i18n.t('Choose parent system'),
      parentSystem: i18n.t('Parent system'),
      overlyingProcessors: i18n.t('Overlying processors')
    };
  }
};