function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
var _excluded = ["tags"];
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import GovernanceReviewModal from '@/components/Organisms/Compliance/GovernanceReview/GovernanceReviewModal';
import { Colors } from '@/Colors';
import { Timezones } from '@/constants/Timezones';
import { DateFormats } from '@/constants/DateFormats';
import { getTZGuess, getCurrentDateWithMove, formatDateWithTimezone } from '@/utils/date';
export default {
  name: 'GovernanceReviewSection',
  components: {
    TrafficLight: TrafficLight,
    GovernanceReviewModal: GovernanceReviewModal,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon
  },
  introduction: '',
  description: '',
  token: '<governance-review-section></governance-review-section>',
  props: {
    review: {
      required: true,
      type: Object,
      note: 'The review object'
    },
    projectName: {
      type: String,
      required: true,
      note: 'Name of current project. Needed to make locked tag'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma users'
    },
    organisations: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma organisations'
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Is the functionality disabled'
    }
  },
  emits: ['update'],
  data: function data() {
    return {
      showModal: false,
      reviewClone: {},
      translate: getTranslate['GovernanceReviewSection']()
    };
  },
  computed: {
    colors: function colors() {
      return Colors;
    },
    latestCompletionUser: function latestCompletionUser() {
      var _this = this;
      var user = '';
      if (this.reviewClone.latestCompletionUserId !== undefined) {
        user = this.users.find(function (user) {
          return user.id === _this.reviewClone.latestCompletionUserId;
        });
      }
      return user ? user.display_name : '';
    },
    showReviewEditButton: function showReviewEditButton() {
      return !Object.keys(this.reviewClone).length || this.reviewClone.canEditReview;
    }
  },
  watch: {
    review: function review() {
      this.reviewClone = Object.assign({}, this.review);
      this.prepareReview();
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      this.reviewClone = Object.assign({}, this.review);
      this.prepareReview();
    },
    emitReview: function emitReview(event) {
      this.showModal = false;
      this.$emit('update', this.packData(event));
    },
    toggleModal: function toggleModal() {
      this.showModal = !this.showModal;
    },
    formatDate: function formatDate(date, tz) {
      return formatDateWithTimezone(date.split('+')[0], tz, DateFormats.DATE_FORMAT);
    },
    getTimezone: function getTimezone() {
      var tzGuess = getTZGuess();
      var timezone = Timezones.find(function (timezone) {
        return timezone.value === tzGuess;
      });
      return timezone ? tzGuess : 'UTC';
    },
    packData: function packData(_ref) {
      var _ref$tags = _ref.tags,
        tags = _ref$tags === void 0 ? [] : _ref$tags,
        review = _objectWithoutProperties(_ref, _excluded);
      var packedReview = _objectSpread(_objectSpread({}, review), {}, {
        deadlineTimezone: review.deadlineTimezone || this.getTimezone(),
        deadlineTZ: review.deadlineTimezone || this.getTimezone(),
        deadlineBeforeAfter: 'after',
        isMotherControl: 0,
        tagIds: tags.map(function (tag) {
          return tag.id;
        })
      });
      if (typeof packedReview.frequency === 'string') {
        delete packedReview.frequency;
      }
      return packedReview;
    },
    prepareReview: function prepareReview() {
      if (Object.keys(this.review).length > 0) {
        this.reviewClone.userIds.responsible = this.reviewClone.userIds.responsible.map(function (u) {
          return parseInt(u);
        });
        this.reviewClone.userIds.escalation = this.reviewClone.userIds.escalation.map(function (u) {
          return parseInt(u);
        });
        this.reviewClone.userIds.review = this.reviewClone.userIds.review.map(function (u) {
          return parseInt(u);
        });
        this.reviewClone.deadline = formatDateWithTimezone(this.reviewClone.deadline, this.reviewClone.deadlineTimezone, DateFormats.DATE_TIME_FORMAT_FULL);
        this.reviewClone.reviewDeadline = this.reviewClone.userIds.review.length > 0 ? formatDateWithTimezone(this.reviewClone.reviewDeadline, this.reviewClone.deadlineTimezone, DateFormats.DATE_TIME_FORMAT_FULL) : getCurrentDateWithMove(7, 'day');
      }
    }
  }
};