import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { getDateDurationWithToday } from '@/utils/date';
export default {
  name: 'NotificationsModal',
  introduction: 'this component shows the notifications list',
  description: 'this modal is used on user-menu component',
  token: "<notifications-modal\n        v-if=\"showNotifications\"\n        :notifications=\"notifications\"\n        @toggle=\"toggleNotifications\" />",
  components: {
    RismaTitle: RismaTitle,
    RismaButton: RismaButton
  },
  props: {
    notifications: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'list of notifications that user has'
    }
  },
  emits: ['clickNotification', 'markAllNotificationsAsRead', 'toggle'],
  data: function data() {
    return {
      translate: {
        myNotifications: this.$trans('My notifications'),
        markAllAsRead: this.$trans('Mark all as read'),
        ago: this.$trans('ago')
      }
    };
  },
  computed: {
    isUnreadNotificationsExist: function isUnreadNotificationsExist() {
      return this.notifications.some(function (n) {
        return !n.read;
      });
    }
  },
  watch: {
    $route: function $route() {
      this.emitToggle();
    }
  },
  methods: {
    getDate: function getDate(date) {
      var _getDateDurationWithT = getDateDurationWithToday(date),
        value = _getDateDurationWithT.value,
        type = _getDateDurationWithT.type;
      if (type) {
        return "".concat(value, " ").concat(this.$trans(type), " ").concat(this.translate.ago);
      }
      return "".concat(value, " ").concat(this.translate.ago);
    },
    emitToggle: function emitToggle() {
      this.$emit('toggle');
    },
    handleMarkAllNotificationsAsRead: function handleMarkAllNotificationsAsRead() {
      this.$emit('markAllNotificationsAsRead');
    },
    onNotificationLinkClick: function onNotificationLinkClick(notificationId) {
      this.$emit('clickNotification', notificationId);
    }
  }
};