function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { ControlsSettings } from '@/constants/controls/ControlsDashboard';
import { replaceMultipleStringsByMultiple } from '@/utils/replaceStrings';
import { mapToSeparatorList } from '@/utils/format/mapTo';
export var StatusOverviewMixin = {
  data: function data() {
    return {
      columnsTable: ControlsSettings.pieTableColumn
    };
  },
  methods: {
    prepareTableInfoForPie: function prepareTableInfoForPie(data) {
      var _this = this;
      var columns = Array(this.columnsTable.length).fill('');
      var dataset = [];
      data.forEach(function (node, index) {
        var preparedItem = {};
        _this.columnsTable.forEach(function (column) {
          preparedItem[column.key] = _this.prepareCompactHtml(column.key, node, ++index);
        });
        dataset.push(preparedItem);
      });
      return {
        columns: columns,
        dataset: dataset
      };
    },
    prepareCompactHtml: function prepareCompactHtml(column, node, index) {
      var result = '';
      var cleanedTitle = this.cleanupTitle(node.text);
      switch (column) {
        case 'index':
          result = index;
          break;
        case 'text':
          result = {
            url: node.url,
            title: node.text,
            titleParam: node.confidential ? this.getHoverTitleWithOwners(node.owners) : cleanedTitle,
            confidential: node.confidential
          };
          break;
        default:
          result = node[column] || '';
      }
      return result;
    },
    enhanceChartDataByControlTitles: function enhanceChartDataByControlTitles(data) {
      var _this2 = this;
      if (!Array.isArray(data)) return data;
      return data.map(function (item) {
        var cleanControls = _this2.cleanControls(item.controls);
        return _objectSpread(_objectSpread({}, item), {}, {
          controls_titles: mapToSeparatorList(cleanControls, 'text', ',\n')
        });
      });
    },
    cleanControls: function cleanControls(controls) {
      var _this3 = this;
      if (!Array.isArray(controls)) return controls;
      return controls.map(function (control) {
        return _objectSpread(_objectSpread({}, control), {}, {
          text: _this3.cleanupTitle(control.text)
        });
      });
    },
    cleanupTitle: function cleanupTitle(title) {
      var map = {
        '<br/>': ' ',
        '<br>': ' ',
        '&lt;None&gt;': '<None>'
      };
      return replaceMultipleStringsByMultiple(title, map);
    },
    getHoverTitleWithOwners: function getHoverTitleWithOwners(owners) {
      var ownersNumber = owners.split(',').length;
      var note = ownersNumber > 1 ? this.$trans('are able to see and edit this activity') : this.$trans('is able to see and edit this activity');
      return "".concat(this.$trans('Only'), " ").concat(owners, " ").concat(note);
    },
    prepareChartData: function prepareChartData(key, chartData, labelField, dataField) {
      var columnColor = this.datasets[key].colors || chartData[key].map(function (item) {
        return item.color;
      });
      var data = [];
      var labels = [];
      var backgroundColor = [];
      chartData[key].forEach(function (item, idx) {
        backgroundColor.push(columnColor[idx % columnColor.length]);
        data.push(item[dataField]);
        labels.push(item[labelField]);
      });
      return {
        datasets: [{
          data: data,
          backgroundColor: backgroundColor
        }],
        labels: labels
      };
    }
  }
};