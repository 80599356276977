function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useSolutionsStore } from '@/Store/solutionsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import InputField from '@/components/Atoms/Inputs/InputField';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import TrafficLightSelector from '@/components/Organisms/Selectors/TrafficLightSelector';
import SolutionsSelector from '@/components/Molecules/SolutionsSelector';
import ConfidentialBox from '@/components/Molecules/ConfidentialBox';
import Notification from '@/components/Molecules/Notification';
import ToggleMenu from '@/components/Atoms/ToggleMenu/ToggleMenu';
import { CustomFieldsMixin } from '@/mixins/CustomFieldsMixin';
import { INCIDENT_STATES } from '@/constants/IncidentStateCodes';
import { Colors } from '@/Colors';
import { generateComputed } from '@/utils/base/generateComputed';
import { filterAsset } from '@/utils/format/objectsFromIds';
import { isFieldInOptionalFields } from '@/utils/Utils';
import { toLocalDateTime, formatToUtcDateTime } from '@/utils/date';
export default {
  name: 'IncidentDescription',
  components: {
    TinyMceBox: TinyMceBox,
    InputField: InputField,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    DateTimePicker: DateTimePicker,
    ActivityCustomFields: ActivityCustomFields,
    TrafficLightSelector: TrafficLightSelector,
    SolutionsSelector: SolutionsSelector,
    ConfidentialBox: ConfidentialBox,
    Notification: Notification,
    ToggleMenu: ToggleMenu
  },
  mixins: [CustomFieldsMixin],
  introduction: '',
  description: '',
  token: '<incident-description />',
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Incident object'
    },
    includeTitle: {
      type: Boolean,
      required: false,
      default: false
    },
    isCreateMode: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false
    },
    isUserLocked: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['open', 'close', 'propertyChanged'],
  data: function data() {
    return {
      isSubmitDisabled: false,
      solutions: [],
      translate: getTranslate['IncidentDescription']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useUsersStore, ['users'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useSolutionsStore, ['enabledSolutions'])), mapState(useActivityTypesStore, {
    activityTypes: 'incidentActivityTypes'
  })), generateComputed(['title', 'description', 'rismaState', 'severity', 'archived'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.activityTypes.find(function (item) {
        return item.id == _this.data.activityTypeId;
      });
    },
    stateList: function stateList() {
      var _this2 = this;
      return Object.keys(INCIDENT_STATES).map(function (key) {
        return {
          label: _this2.$trans(INCIDENT_STATES[key].name[0].toUpperCase() + INCIDENT_STATES[key].name.slice(1)),
          value: INCIDENT_STATES[key].value
        };
      });
    },
    incidentOccurred: {
      get: function get() {
        return this.data.incidentHappenedAt ? toLocalDateTime(this.data.incidentHappenedAt) : '';
      },
      set: function set(val) {
        var value = val ? formatToUtcDateTime(val) : '';
        this.$emit('propertyChanged', {
          property: 'incidentHappenedAt',
          value: value
        });
      }
    },
    confidentialityUserIds: function confidentialityUserIds() {
      var userIds = [];
      if (this.responsibleUsers.length) {
        userIds = userIds.concat(this.responsibleUsers);
      }
      if (this.accountableUsers.length) {
        userIds = userIds.concat(this.accountableUsers);
      }
      var uniqueUserIds = userIds.filter(function (v, i, a) {
        return a.indexOf(v) === i;
      });
      return uniqueUserIds;
    },
    confidentialityNames: function confidentialityNames() {
      if (this.confidentialityUserIds.length) {
        return this.confidentialityUserIds.map(function (user) {
          return user.display_name;
        }).join(', ');
      }
      return '';
    },
    responsibleUsers: function responsibleUsers() {
      return this.data.responsibleUserIds ? filterAsset(this.users, this.data.responsibleUserIds) : [];
    },
    accountableUsers: function accountableUsers() {
      return this.data.accountableUserIds ? filterAsset(this.users, this.data.accountableUserIds) : [];
    },
    confidentialReadOnly: function confidentialReadOnly() {
      var _this3 = this;
      return this.readOnly || !this.responsibleUsers.find(function (user) {
        return user.id === _this3.currentUser.id;
      });
    },
    archivedMessage: function archivedMessage() {
      return this.translate.thisIncidentIsArchivedAndCantBeEdited;
    },
    toggleMenuOptions: function toggleMenuOptions() {
      var _this4 = this;
      return [{
        id: 1,
        title: this.archiveMenuItemName,
        style: {
          color: Colors.system.warning
        },
        icon: 'archive',
        action: function action() {
          _this4.archived = !_this4.archived;
        }
      }];
    },
    archiveMenuItemName: function archiveMenuItemName() {
      return !this.archived ? this.translate.archiveIncident : this.translate.restoreIncident;
    }
  }),
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this5 = this;
      if (this.currentActivityType.solutionId && this.isCreateMode) {
        var activitySolutionId = this.enabledSolutions.find(function (solution) {
          return solution.id === _this5.currentActivityType.solutionId;
        });
        this.solutions.push({
          value: this.currentActivityType.solutionId,
          title: activitySolutionId.title
        });
      }
    },
    onSolutionsChange: function onSolutionsChange(event) {
      this.solutions = event;
      this.$emit('propertyChanged', {
        property: 'solutions',
        value: event
      });
    },
    isFieldInOptional: function isFieldInOptional(field) {
      return isFieldInOptionalFields(this.currentActivityType, field);
    }
  }
};