import ToolTip from '@/components/Atoms/Containers/ToolTip';
export default {
  name: 'BowTieCircle',
  components: {
    ToolTip: ToolTip
  },
  props: {
    bowStyles: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      hidden: true,
      clicked: false,
      index: null
    };
  },
  methods: {
    show: function show() {
      this.hidden = false;
    },
    hide: function hide() {
      this.hidden = true;
    }
  }
};