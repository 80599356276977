function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import * as Utils from '@/utils/Utils';
export default {
  name: 'RiskProbabilityAssessment',
  introduction: 'Risk Probability Assesment on DEP',
  description: 'Risk probability wrapper on DEP',
  token: "<risk-probability-assessment\n        :activity=\"activity\"\n        :threats=\"threats\"\n        :level-of-threats=\"levelOfThreats\"\n        :vulnerabilities=\"vulnerabilities\"\n        :probabilities=\"probabilities\"\n     />",
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    TinyMceBox: TinyMceBox,
    RismaSelect: RismaSelect
  },
  props: {
    activity: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Current activity data'
    },
    threats: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk threats'
    },
    levelOfThreats: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Level of threats'
    },
    vulnerabilities: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk vulnerabilities'
    },
    probabilities: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk vulnerabilities'
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Is the functionality disabled'
    },
    threatLevelTitle: {
      type: String,
      required: false,
      default: 'Level of threat'
    },
    threatShown: {
      type: Boolean,
      default: true,
      note: 'if the threat shown'
    },
    threatLevelShown: {
      type: Boolean,
      default: true,
      note: 'If the level of threat shown'
    },
    vulnerabilityShown: {
      type: Boolean,
      default: true,
      note: 'Is the vulnerability show'
    }
  },
  emits: ['changed'],
  data: function data() {
    var _comments;
    return {
      threatsSelected: [],
      levelOfThreatSelected: null,
      vulnerabilitiesSelected: [],
      probabilitySelected: null,
      RiskBaseModelProps: RiskBaseModelProps,
      comments: (_comments = {}, _defineProperty(_comments, RiskBaseModelProps.THREAT_COMMENT, this.activity[RiskBaseModelProps.THREAT_COMMENT]), _defineProperty(_comments, RiskBaseModelProps.THREAT_LEVEL_COMMENT, this.activity[RiskBaseModelProps.THREAT_LEVEL_COMMENT]), _defineProperty(_comments, RiskBaseModelProps.VULNERABILITY_COMMENT, this.activity[RiskBaseModelProps.VULNERABILITY_COMMENT]), _defineProperty(_comments, RiskBaseModelProps.PROBABILITY_COMMENT, this.activity[RiskBaseModelProps.PROBABILITY_COMMENT]), _comments),
      unSorted: true,
      timeout: null,
      maxlength: 999,
      translate: getTranslate['RiskProbabilityAssessment']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    keepCommentFieldsExpanded: function keepCommentFieldsExpanded() {
      return this.getSettingValue('feature.risk_comments_field_on_axis');
    },
    isThreatListDisabled: function isThreatListDisabled() {
      return this.disabled || !this.threats.length;
    },
    isLevelOfThreatsListDisabled: function isLevelOfThreatsListDisabled() {
      return this.disabled || !this.levelOfThreats.length;
    },
    isVulnerabilitiesListDisabled: function isVulnerabilitiesListDisabled() {
      return this.disabled || !this.vulnerabilities.length;
    },
    threatLevelPlaceholder: function threatLevelPlaceholder() {
      return this.translate.select + ' ' + this.threatLevelTitle;
    },
    isTitleShown: function isTitleShown() {
      return this.threatShown || this.threatLevelShown || this.vulnerabilityShown;
    }
  }),
  beforeMount: function beforeMount() {
    this.getSelected();
  },
  methods: {
    getSelected: function getSelected() {
      var _this = this;
      this.threatsSelected = this.threats.filter(function (threat) {
        var _this$activity$RiskBa;
        return (_this$activity$RiskBa = _this.activity[RiskBaseModelProps.THREAT_IDS]) === null || _this$activity$RiskBa === void 0 ? void 0 : _this$activity$RiskBa.includes(threat.id);
      });
      this.levelOfThreatSelected = this.findValue(this.levelOfThreats, RiskBaseModelProps.THREAT_LEVEL_VALUE);
      this.vulnerabilitiesSelected = this.vulnerabilities.filter(function (vulnerability) {
        var _this$activity$RiskBa2;
        return (_this$activity$RiskBa2 = _this.activity[RiskBaseModelProps.VULNERABILITY_IDS]) === null || _this$activity$RiskBa2 === void 0 ? void 0 : _this$activity$RiskBa2.includes(vulnerability.id);
      });
      this.probabilitySelected = this.findValue(this.probabilities, RiskBaseModelProps.PROBABILITY_SCORE);
    },
    findValue: function findValue(data, key) {
      var _this2 = this;
      var result = data.find(function (item) {
        return item.id === _this2.activity[key];
      });
      if (result) {
        return result.value;
      }
    },
    onSelectorChanged: function onSelectorChanged() {
      this.emitState();
    },
    onCommentChanged: function onCommentChanged(key, e) {
      this.comments[key] = Utils.setMaxlength(e, this.maxlength);
      this.emitState();
    },
    onTreatSelect: function onTreatSelect(threats) {
      this.threatsSelected = threats;
      this.emitState();
    },
    onVulnerabilitySelect: function onVulnerabilitySelect(vulnerability) {
      this.vulnerabilitiesSelected = vulnerability;
      this.emitState();
    },
    emitState: function emitState() {
      var _this3 = this;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        var _objectSpread2;
        _this3.$emit('changed', _objectSpread((_objectSpread2 = {}, _defineProperty(_objectSpread2, RiskBaseModelProps.THREAT_IDS, _this3.threatsSelected.map(function (threat) {
          return threat.id;
        })), _defineProperty(_objectSpread2, RiskBaseModelProps.VULNERABILITY_IDS, _this3.vulnerabilitiesSelected.map(function (vulnerability) {
          return vulnerability.id;
        })), _defineProperty(_objectSpread2, "threatLevelId", _this3.levelOfThreatSelected), _defineProperty(_objectSpread2, RiskBaseModelProps.PROBABILITY_SCORE, _this3.probabilitySelected), _objectSpread2), _this3.comments));
      }, 100);
    }
  }
};