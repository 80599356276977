import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import RismaColumnSwitcher from '@/components/Atoms/RismaColumnSwitcher/RismaColumnSwitcher';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { Colors } from '@/Colors';
export default {
  name: 'UtilMenuWithToggle',
  introduction: 'UtilMenuWithToggle is used in Mypage /mypage',
  description: 'UtilMenuWithToggle is used to display global settings',
  token: "<util-menu-with-toggle columns=\"[{label: \"one\", visible: true}, {label: \"two\", visible: true}]\" @change=\"somefunc\">\n                <feather-icon icon=\"more-horizontal\" />\n            </util-menu-with-toggle>",
  components: {
    FeatherIcon: FeatherIcon,
    RismaToggle: RismaToggle,
    RismaColumnSwitcher: RismaColumnSwitcher
  },
  props: {
    columns: {
      type: Array,
      required: true,
      note: 'Columns to hide/show'
    }
  },
  emits: ['changed'],
  data: function data() {
    return {
      colors: Colors,
      popupVisible: false,
      translate: {
        off: this.$trans('Off'),
        on: this.$trans('On')
      }
    };
  }
};