import * as Cookies from 'tiny-cookie';
import { exportTypes } from '@/constants/exportTypes';
export var filename = 'Risma';
export function getLanguage() {
  return Cookies.get('risma_lang').split('_')[0];
}
export function randomDomId() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
export function disableExportOptions(item, li) {
  if (item.format === exportTypes.PRINT || item.format === exportTypes.XLSX || item.format === exportTypes.JSON) {
    li.style.display = 'none';
  }
  return li;
}
export function splitLabelToRows(lab) {
  var charsToShow = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
  var charsToWrap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;
  var shortedLabel = lab.length > charsToShow ? lab.slice(0, charsToShow) + '...' : lab;
  var words = shortedLabel.split(' ').reduce(function (words, word) {
    if (word.length <= charsToWrap) {
      words.push(word);
    } else {
      for (var i = 0; i < Math.ceil(word.length / charsToWrap); i++) {
        words.push(word.slice(i * charsToWrap, i * charsToWrap + charsToWrap));
      }
    }
    return words;
  }, []);
  var currentRowIndex = 0;
  var newLabel = words.reduce(function (rows, word) {
    if (!rows[currentRowIndex]) {
      rows[currentRowIndex] = '';
    }
    if (rows[currentRowIndex].length + word.length <= charsToWrap) {
      rows[currentRowIndex] += ' ' + word;
    } else {
      currentRowIndex++;
      rows[currentRowIndex] = word;
    }
    return rows;
  }, []);
  return newLabel;
}