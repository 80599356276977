import ScrollTopButton from '@/components/Atoms/Buttons/ScrollTopButton';
export default {
  name: '',
  components: {
    ScrollTopButton: ScrollTopButton
  },
  mounted: function mounted() {
    window.scrollTo({
      top: 30,
      behavior: 'smooth'
    });
  }
};