import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "flex items-center separate"
};
var _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    title: $props.title || $data.translate.confidentialActivity
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_toggle, {
    "model-value": $props.confidential,
    disabled: $props.readOnly,
    onInput: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changed', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled"])]), $props.confidentialityNames && !!$props.confidential ? (_openBlock(), _createElementBlock("p", {
    key: 0,
    innerHTML: $options.confidentialNote
  }, null, 8 /* PROPS */, _hoisted_2)) : _createCommentVNode("v-if", true)]);
}