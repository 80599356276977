import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
var BASE_URL = '/contracts/dashboard';
export function getSummaryContracts() {
  return handleRequestResponse(Http.get("".concat(BASE_URL, "/summary")));
}
export function getStatusContracts() {
  return handleRequestResponse(Http.get("".concat(BASE_URL, "/status")));
}
export function getRenewalContracts() {
  return handleRequestResponse(Http.get("".concat(BASE_URL, "/renewal")));
}
export function getExpiringContracts() {
  return handleRequestResponse(Http.get("".concat(BASE_URL, "/expiring")));
}