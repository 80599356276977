import i18n from '@/i18n';
export var getTranslate = {
  GapTable: function GapTable() {
    return {
      gapInformation: i18n.t('Gap information'),
      likelihood: i18n.t('Likelihood'),
      beforeafter: i18n.t('Before & After'),
      impact: i18n.t('Impact'),
      rpi: i18n.t('RPI')
    };
  }
};