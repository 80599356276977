function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewRisk from '@/components/Atoms/Preview/PreviewRisk';
import ViewSwitcher from '@/components/Molecules/ViewSwitcher';
import { getScoring } from '@/api/risk/scoring';
import { ControlViews } from '@/constants/ControlViews';
import { STANDARD_VIEWS } from '@/constants/ViewTypes';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'PreviewRiskList',
  introduction: 'Container for list of risks',
  description: 'Handle view and filtering of risks',
  token: '<preview-risk-list :risks="risks" />',
  components: {
    RismaTitle: RismaTitle,
    PreviewRisk: PreviewRisk,
    ViewSwitcher: ViewSwitcher
  },
  props: {
    sections: {
      type: Array,
      required: true,
      note: 'A list of sections with "title" and "list" properties'
    },
    organisations: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isErm: {
      type: Boolean,
      required: false,
      default: false
    },
    isApprovalFlowEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowRiskLinkedItems: {
      type: Boolean,
      default: false
    }
  },
  emits: ['updateFromPreviewMode'],
  setup: function setup() {
    return {
      viewSelected: getLocalStorageComputed('my_risks_display_view', ControlViews.EXPANDED)
    };
  },
  data: function data() {
    return {
      scoring: {},
      scoringLoaded: false,
      STANDARD_VIEWS: STANDARD_VIEWS,
      translate: getTranslate['PreviewRiskList']()
    };
  },
  computed: {
    showCompactHeader: function showCompactHeader() {
      return this.viewSelected === ControlViews.COMPACT;
    },
    sortedSections: function sortedSections() {
      var result = [];
      this.sections.forEach(function (section) {
        result.push({
          title: section.title,
          listShown: _toConsumableArray(section.list.sort(function (a, b) {
            return a.rno - b.rno;
          }))
        });
      });
      return result;
    }
  },
  beforeMount: function beforeMount() {
    var isAnyRisk = this.sections.some(function (section) {
      return section.list.length;
    });
    if (this.isErm && isAnyRisk) {
      this.getScoringForERM();
    }
  },
  methods: {
    getScoringForERM: function getScoringForERM() {
      var _this = this;
      var scoringId = this.sections[0].list[0].scoringId;
      return getScoring(scoringId).then(function (scoring) {
        _this.scoring = scoring;
        _this.scoringLoaded = true;
      }).catch(function (error) {
        return new Error(error);
      });
    }
  }
};