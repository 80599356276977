function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useTagsStore } from '@/Store/tagsStore';
import * as API from '@/api/risma/data';
import { getTreeByProjectId } from '@/api/compliance/tree';
import { getAttributes as _getAttributes } from '@/api/admin/gapschema';
import { getAllGapSchemasByProjectId } from '@/api/compliance/gapschema';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
import { formatNodes as _formatNodes } from '@/utils/format/Compliance';
import { compareStrings } from '@/utils/compare';
import { LiaTiaType, LIA_TIA_TYPES } from '@/constants/compliance/LiaTiaGeneric';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { UserLevels } from '@/constants/UserLevels';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { Colors } from '@/Colors';
export default {
  name: 'ReportSearchForm',
  introduction: 'This component will display a search-form for the compliance reports.',
  description: 'This will display two multi-select dropdowns. One with organisations and one with users. The users will be filtered bases on the organisation selected. If no organisation is selected all users will be displayed.\n\n' + 'This component allows the use of a slot named `inputSlot`. This slot will be place as the first element in the row with multiselect-elements. This slot can be a input-field a multiselect or whatever you would like it to be\n\n' + 'When clicking the `Clear query`-button a `@clearQuery`-event will be fired.\n' + 'When the form submits a `@doSearch`-event will be fired.',
  token: '<report-search-form title="" @doSearch="" @clearQuery=""><template v-slot:inputSlot><input v-model="modelName"/></div></report-search-form>',
  components: {
    RismaToggle: RismaToggle,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    RismaButton: RismaButton,
    RismaSelect: RismaSelect,
    RadioButtons: RadioButtons
  },
  mixins: [RaciTitleMixin],
  props: {
    title: {
      type: String,
      required: true,
      note: 'The title in the search form'
    },
    helperText: {
      type: String,
      default: '',
      note: 'The helper text of the search form. This will only be displayed if the prop is pressent'
    },
    searchBtnText: {
      type: String,
      default: 'Search',
      note: 'The text of the "search" button'
    },
    clearBtnText: {
      type: String,
      default: 'Clear search query',
      note: 'The text of the "clear" button'
    },
    nodesFilterEnabled: {
      type: Boolean,
      default: false,
      note: 'Show filter by node?'
    },
    scoringToggleEnabled: {
      type: Boolean,
      default: false,
      note: 'Show scoring toggler?'
    },
    localStorageKey: {
      type: String,
      required: false,
      default: null,
      note: 'Name of localStorage key'
    },
    selectedGaps: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Selected gaps for saving in localStorage'
    },
    hasLiaTiaFilter: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Lia and Tia filters for GDPR'
    },
    projectId: {
      type: [Number, String],
      required: true,
      note: 'Id of current project'
    },
    initialFilters: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['setQueryInput', 'doSearch', 'clearQuery'],
  data: function data() {
    return {
      nodes: [],
      organisations: [],
      selectedNodes: [],
      selectedUsers: [],
      selectedTags: [],
      selectedOrganisations: [],
      attributes: [],
      scoringIncluded: true,
      includeChildNodes: true,
      Colors: Colors,
      selectedRadio: 0,
      selectedAttributes: [],
      liaTiaSettings: [],
      LiaTiaType: LiaTiaType,
      translate: getTranslate['ReportSearchForm']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useUserStore, {
    currentUserAccessLevelByProjectId: 'complianceAccessLevelByProjectId'
  })), mapState(useTagsStore, ['tags'])), {}, {
    filteredUsers: function filteredUsers() {
      var _this = this;
      return this.users.filter(function (user) {
        var currentProject = user.projects.find(function (project) {
          return project.id === parseInt(_this.projectId) && project.objType == ObjectTypes.COMPLIANCE_PROJECT;
        });
        return currentProject && currentProject.access_level;
      });
    },
    radioButtonsOptions: function radioButtonsOptions() {
      var _this2 = this;
      var options = [{
        value: 0,
        label: this.translate.allNodes
      }];
      var typesInUse = [];
      this.liaTiaSettings.forEach(function (setting) {
        if (!typesInUse.includes(setting.type)) {
          options.push({
            value: setting.type,
            label: _this2.$trans(_this2.LiaTiaType[setting.type].title)
          });
          typesInUse.push(setting.type);
        }
      });
      return options;
    },
    attributesOptions: function attributesOptions() {
      return this.attributes.reduce(function (acc, attribute) {
        var enhancedLabels = attribute.labels.map(function (label) {
          return _objectSpread(_objectSpread({}, label), {}, {
            title: "".concat(attribute.category, ": ").concat(label.label)
          });
        });
        return [].concat(_toConsumableArray(acc), _toConsumableArray(enhancedLabels));
      }, []);
    }
  }),
  watch: {
    initialFilters: function initialFilters(value) {
      value && this.applyInitialFilters();
    }
  },
  mounted: function mounted() {
    var promises = [this.fetchOrganisations(), this.getTiaLiaSettings(), this.getProjectGapAttributes()];
    if (this.nodesFilterEnabled) {
      promises.push(this.fetchNodes());
    }
    Promise.all(promises).then(this.applyInitialFilters);
  },
  methods: {
    fetchNodes: function fetchNodes() {
      var _this3 = this;
      return getTreeByProjectId(this.projectId).then(function (response) {
        return _this3.formatNodes(response.children);
      });
    },
    getTiaLiaSettings: function getTiaLiaSettings() {
      var _this4 = this;
      return API.getTiaLiaSettings().then(function (_ref) {
        var list = _ref.list;
        _this4.liaTiaSettings = list.filter(function (item) {
          return +item.type !== LIA_TIA_TYPES.TIA_PROC;
        });
      });
    },
    fetchOrganisations: function fetchOrganisations() {
      var _this5 = this;
      var filterByUserAccess = this.currentUserAccessLevelByProjectId(this.projectId) && this.currentUserAccessLevelByProjectId(this.projectId) < UserLevels.SUPER;
      API.getOrganisationsAndFilterByUserAccess(filterByUserAccess).then(function (response) {
        _this5.organisations = response.list;
        _this5.organisations.sort(function (a, b) {
          a = a.visible_name;
          b = b.visible_name;
          return compareStrings(a, b);
        });
      });
    },
    getProjectGapAttributes: function getProjectGapAttributes() {
      var _this6 = this;
      return getAllGapSchemasByProjectId(this.projectId).then(function (_ref2) {
        var list = _ref2.list;
        var promises = [];
        list.forEach(function (_ref3) {
          var id = _ref3.id;
          return promises.push(_this6.getAttributes(id));
        });
        return Promise.all(promises);
      });
    },
    getAttributes: function getAttributes(id) {
      var _this7 = this;
      return _getAttributes(id).then(function (_ref4) {
        var _this7$attributes;
        var attributes = _ref4.attributes;
        return (_this7$attributes = _this7.attributes).push.apply(_this7$attributes, _toConsumableArray(attributes));
      });
    },
    applyInitialFilters: function applyInitialFilters() {
      var filters = this.initialFilters;
      if (filters.organisations) {
        this.selectedOrganisations = this.organisations.filter(function (organisation) {
          return filters.organisations.find(function (id) {
            return id == organisation.id;
          });
        });
      }
      if (filters.users) {
        this.selectedUsers = this.users.filter(function (user) {
          return filters.users.find(function (id) {
            return id == user.id;
          });
        });
      }
      if (filters.attributes) {
        this.selectedAttributes = this.attributesOptions.filter(function (attribute) {
          return filters.attributes.find(function (id) {
            return +id === +attribute.id;
          });
        });
      }
      if (filters.tags) this.selectedTags = this.tags.filter(function (tag) {
        return filters.tags.find(function (item) {
          return +item === tag.id;
        });
      });
      if (this.nodesFilterEnabled) {
        this.selectedNodes = this.nodes.filter(function (node) {
          return filters.nodes.find(function (id) {
            return id == node.id;
          });
        });
        this.includeChildNodes = filters.includeChildNodes;
      } else {
        this.scoringIncluded = filters.scoringIncluded;
      }
      var radio = filters.selectedRadio;
      if (radio) {
        var idx = this.radioButtonsOptions.findIndex(function (option) {
          return +option.value === +radio;
        });
        this.selectedRadio = idx !== -1 ? idx : 0;
      }
    },
    nodesLabel: function nodesLabel(node) {
      return node.formattedTitle;
    },
    organisationsLabel: function organisationsLabel(org) {
      return org.visible_name;
    },
    usersLabel: function usersLabel(user) {
      return user.display_name;
    },
    formatNodes: function formatNodes(nodes) {
      this.nodes = _formatNodes(nodes);
    },
    resetFilters: function resetFilters() {
      this.selectedUsers = [];
      this.selectedOrganisations = [];
      this.selectedNodes = [];
      this.selectedTags = [];
      this.selectedAttributes = [];
      this.scoringIncluded = true;
      this.includeChildNodes = false;
      this.$emit('clearQuery');
    },
    handleSearch: function handleSearch() {
      var selectedNodeIds = this.selectedNodes.map(function (node) {
        return node.id;
      });
      this.$emit('doSearch', {
        organisations: this.selectedOrganisations.map(function (org) {
          return org.id;
        }),
        users: this.selectedUsers.map(function (user) {
          return user.id;
        }),
        tags: this.selectedTags.map(function (tag) {
          return tag.id;
        }),
        nodes: selectedNodeIds,
        attributes: this.selectedAttributes.map(function (a) {
          return a.id;
        }),
        scoringIncluded: this.scoringIncluded,
        filterType: this.selectedRadio,
        includeChildNodes: this.includeChildNodes
      });
    },
    handleResetFilters: function handleResetFilters() {
      this.resetFilters();
      if (this.localStorageKey !== null) localStorage.removeItem(this.localStorageKey);
    }
  }
};