import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { compareStrings } from '@/utils/compare';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export default {
  name: 'OverlyingUnderlyingInformationAssets',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect
  },
  props: {
    items: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dataId: {
      type: Number,
      default: 0
    },
    children: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    parents: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    type: {
      type: String,
      required: false,
      default: AssetsTypes.PROCESSORS,
      note: 'type - can be processors or systems'
    }
  },
  emits: ['createProcessor', 'propertyChanged'],
  data: function data() {
    return {
      AssetsTypes: AssetsTypes,
      translate: getTranslate['OverlyingUnderlyingInformationAssets']()
    };
  },
  computed: {
    singleOptions: function singleOptions() {
      var _this = this;
      return this.items.filter(function (item) {
        return item.id !== _this.dataId;
      }).map(function (item) {
        return {
          value: item.id,
          label: item.name,
          parentId: item.parentId
        };
      });
    },
    title: function title() {
      if (this.isParent) {
        return this.$trans("Underlying ".concat(this.$trans(this.type)));
      } else if (this.type === AssetsTypes.SYSTEMS) {
        return this.translate.parentSystem;
      }
      return this.translate.overlyingProcessors;
    },
    isParent: function isParent() {
      if (this.type === AssetsTypes.PROCESSORS) {
        return this.parents.length === 0;
      } else {
        return this.children !== null && this.children.length;
      }
    },
    parentlessItems: function parentlessItems() {
      return this.singleOptions.filter(function (item) {
        return !item.parentId;
      });
    },
    select_options: function select_options() {
      var _this2 = this;
      var items = this.items.filter(function (_ref) {
        var id = _ref.id,
          name = _ref.name,
          children = _ref.children,
          parents = _ref.parents;
        if (!name || id === _this2.dataId || children.length > 0 || parents.length > 0 && parents.indexOf(_this2.dataId) === -1) {
          return false;
        }
        return true;
      });
      items.sort(function (a, b) {
        return compareStrings(a.name, b.name);
      });
      return items;
    },
    multiple_selected_objects: {
      get: function get() {
        var _this3 = this;
        if (this.children === null) {
          return [];
        }
        return this.children.map(function (dataId) {
          var item = _this3.itemById(dataId);
          return {
            id: item.id,
            name: item.name
          };
        });
      },
      set: function set(data) {
        this.$emit('propertyChanged', {
          property: 'children',
          value: data.map(function (_ref2) {
            var id = _ref2.id;
            return id;
          })
        });
      }
    }
  },
  methods: {
    createLabel: function createLabel(option) {
      return option.name;
    },
    itemById: function itemById(id) {
      return this.items.find(function (item) {
        return +item.id === +id;
      });
    }
  }
};