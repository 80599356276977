import i18n from '@/i18n';
export var getTranslate = {
  ControlYearWheelDashboard: function ControlYearWheelDashboard() {
    return {
      annualOverview: i18n.t('Annual overview'),
      completedOnTime: i18n.t('Completed on time'),
      completedAfterDeadline: i18n.t('Completed after deadline'),
      controlNotPerformed: i18n.t('Control not performed'),
      notCompletedYet: i18n.t('Not completed yet'),
      year: i18n.t('year'),
      years: i18n.t('years'),
      anErrorOccurred: i18n.t('An error occurred')
    };
  }
};