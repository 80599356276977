function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { getTranslate } from './translate';
import { getAllArticlesByGapSchemaId, updateGapArticles, createGapArticle, deleteGapArticle, getAllDomains, createDomain } from '@/api/compliance/gapschema';
import StaticTable from '@/components/Molecules/StaticTable';
import GapSchemaArticleModal from '@/components/Molecules/Modal/GapSchemaArticlesModal';
import Notification from '@/components/Molecules/Notification';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Alert from '@/components/Molecules/Modal/Alert';
import { SETTINGS_ROUTE_NAMES } from '@/constants/routes/settings';
import { escapeHtml } from '@/utils';
import { catch403 } from '@/utils/handleAPIErrors';
var EDIT_ERROR_MESSAGE = 'This article is used in an analysis and cannot be edited.';
var DELETE_ERROR_MESSAGE = 'This article is used in an analysis and cannot be deleted.';
export default {
  name: 'GapSchemasAdminArticles',
  introduction: 'Articles list',
  description: 'This component renders the table with articles list',
  token: '<gap-schemas-admin-articles></gap-schemas-admin-articles>',
  components: {
    GapSchemaArticleModal: GapSchemaArticleModal,
    StaticTable: StaticTable,
    Notification: Notification,
    FeatherIcon: FeatherIcon,
    Alert: Alert
  },
  props: {
    gapSchemaId: {
      default: 0,
      type: [String, Number],
      note: ''
    }
  },
  data: function data() {
    return {
      idToDelete: null,
      showConfirmationAlert: false,
      articles: [],
      selectedArticle: {},
      showArticleModal: false,
      domains: [],
      errorMessage: '',
      translate: getTranslate['GapSchemasAdminArticles']()
    };
  },
  computed: {
    columns: function columns() {
      var columns = [this.translate.article, this.translate.articleTitle, this.translate.requirement, this.translate.domain, this.translate.options, ''];
      return columns;
    },
    formattedData: function formattedData() {
      return this.articles.map(function (article) {
        return {
          article: escapeHtml(article.article),
          articleTitle: escapeHtml(article.articleTitle),
          requirement: escapeHtml(article.requirement),
          domain: article.domain.label,
          options: {
            param3: article.id,
            canBeDeleted: article.canBeDeleted
          }
        };
      });
    },
    confirmationMessage: function confirmationMessage() {
      var warning = this.translate.areYouSureYouWantToDeleteThisArticle;
      warning += '\n';
      warning += this.translate.allAnalysesAndLinksWillBeRemoved;
      warning += '\n';
      warning += this.translate.thisCannotBeUndone;
      return warning;
    }
  },
  mounted: function mounted() {
    this.getArticles();
    this.getDomains();
  },
  methods: {
    getArticles: function getArticles() {
      var _this = this;
      getAllArticlesByGapSchemaId(this.gapSchemaId, ['suggestedActivities']).then(function (_ref) {
        var list = _ref.list;
        _this.articles = list;
      }).catch(function () {
        catch403(_this.$router, SETTINGS_ROUTE_NAMES.gapSchemas);
      });
    },
    getDomains: function getDomains() {
      var _this2 = this;
      getAllDomains().then(function (_ref2) {
        var list = _ref2.list;
        _this2.domains = list.map(function (domain) {
          return {
            id: domain.id,
            label: domain.label
          };
        });
      });
    },
    toggleArticleModal: function toggleArticleModal(event, articleId) {
      this.errorMessage = '';
      if (articleId) {
        var article = this.articles.find(function (article) {
          return article.id == articleId;
        });
        this.selectedArticle = JSON.parse(JSON.stringify(article));
        this.selectedArticle.domain = this.setDomainData(this.selectedArticle.domain);
      } else {
        this.selectedArticle = {};
      }
      this.showArticleModal = !this.showArticleModal;
    },
    handleEvents: function handleEvents(_ref3) {
      var _this3 = this;
      var _ref4 = _slicedToArray(_ref3, 3),
        event = _ref4[0],
        action = _ref4[1],
        articleId = _ref4[2];
      var actionHash = {
        edit: function edit() {
          return _this3.toggleArticleModal(event[0], articleId);
        },
        delete: function _delete() {
          return _this3.onDeleteArticle(articleId);
        }
      };
      actionHash[action]();
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var _this4 = this;
      this.showConfirmationAlert = false;
      return deleteGapArticle(this.gapSchemaId, this.idToDelete).then(this.getArticles).catch(function () {
        return _this4.errorMessage = DELETE_ERROR_MESSAGE;
      });
    },
    onDeleteArticle: function onDeleteArticle(id) {
      this.idToDelete = id;
      this.showConfirmationAlert = true;
    },
    onSubmitArticleModal: function onSubmitArticleModal(formData) {
      var _this5 = this;
      var data = {
        article: formData.article,
        articleTitle: formData.articleTitle,
        requirement: formData.requirement,
        domainId: formData.domain.id,
        flags: formData.flags,
        attributeIds: formData.attributeIds || []
      };
      if (this.selectedArticle && this.selectedArticle.id) {
        updateGapArticles(this.gapSchemaId, this.selectedArticle.id, data).then(this.getArticles).then(this.toggleArticleModal).catch(function () {
          return _this5.errorMessage = EDIT_ERROR_MESSAGE;
        });
      } else {
        createGapArticle(this.gapSchemaId, data).then(this.getArticles).then(this.toggleArticleModal);
      }
    },
    setDomainData: function setDomainData(domain) {
      return {
        id: parseInt(domain.id),
        label: domain.label
      };
    },
    onCreateDomain: function onCreateDomain(label) {
      var _this6 = this;
      createDomain({
        label: label
      }).then(function (domain) {
        _this6.selectedArticle.domain = _this6.setDomainData(domain);
        return _this6.getDomains();
      });
    }
  }
};