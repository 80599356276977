import i18n from '@/i18n';
export var getTranslate = {
  InformationAssetsProcessorDetails: function InformationAssetsProcessorDetails() {
    return {
      description: i18n.t('Description'),
      address: i18n.t('Address'),
      countryCode: i18n.t('Country code'),
      email: i18n.t('Email'),
      vatNumber: i18n.t('VAT number'),
      phoneNumber: i18n.t('Phone number'),
      getInfoFromCvr: i18n.t('Get info from CVR'),
      regNumber: i18n.t('Reg. number'),
      contactName: i18n.t('Contact name'),
      riskAssessment: i18n.t('Risk assessment'),
      recoveryTimeObjective: i18n.t('Recovery Time Objective'),
      selectRto: i18n.t('Select RTO'),
      recoveryPointObjective: i18n.t('Recovery Point Objective'),
      selectRpo: i18n.t('Select RPO'),
      dataProcessingAgreement: i18n.t('Data processing agreement'),
      latestDataProcessorManagementAudit: i18n.t('Latest Data Processor Management audit'),
      score: i18n.t('Score'),
      assessmentText: i18n.t('Assessment text'),
      questionnaire: i18n.t('Questionnaire'),
      auditSentTo: i18n.t('Audit sent to'),
      noResultsFoundDidYouEnterValidVatNumber: i18n.t('No results found! Did you enter valid VAT number?')
    };
  }
};