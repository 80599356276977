import { getTranslate } from './translate';
import { moveInitiatives as _moveInitiatives } from '@/api/execution/initiative';
import { DateFormats } from '@/constants/DateFormats';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { calculateDifferenceAsDays, addTimeAndFormatDate, formatTimeToDateFormat } from '@/utils/date';
export default {
  name: 'ActionChangeDatesModal',
  introduction: 'Move initiative and underlying dates modal',
  components: {
    Modal: Modal,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    DateTimePicker: DateTimePicker,
    FeatherIcon: FeatherIcon
  },
  props: {
    initiativeId: {
      required: true,
      type: Number
    },
    originalStart: {
      required: true,
      type: String
    },
    originalDeadline: {
      required: true,
      type: String
    },
    dateTimeOptions: {
      required: true,
      type: Object
    },
    childrenNumber: {
      required: true,
      type: Number
    }
  },
  emits: ['moveInitiatives', 'close'],
  data: function data() {
    return {
      start: '',
      deadline: '',
      linked: true,
      translate: getTranslate['ActionChangeDatesModal']()
    };
  },
  computed: {
    serializedData: function serializedData() {
      return "start=".concat(this.start, "&deadline=").concat(this.deadline, "&id=").concat(this.initiativeId, "&linked=").concat(this.linked);
    },
    changeDatesMessage: function changeDatesMessage() {
      if (this.linked) {
        return "".concat(this.translate.byPressingMoveInitiativesYouWillMoveStartAndDeadline, "\n                    ").concat(this.childrenNumber, " ").concat(this.translate.underlyingInitiatives, "\n                    ").concat(Math.abs(this.moveDaysNumberStart), " ").concat(this.daysForwardBackwardMessage);
      }
      return "".concat(this.translate.byPressingMoveInitiativesYouWillMoveStart, "\n                ").concat(this.childrenNumber, " ").concat(this.translate.underlyingInitiatives, "\n                ").concat(Math.abs(this.moveDaysNumberStart), " ").concat(this.daysForwardBackwardMessage);
    },
    deadlineChangeMessage: function deadlineChangeMessage() {
      return "".concat(this.translate.youWillAlsoMoveTheDeadline, "\n                ").concat(Math.round(Math.abs(this.moveDaysNumberDeadline)), "\n                ").concat(this.deadlineDaysForwardBackwardMessage);
    },
    daysForwardBackwardMessage: function daysForwardBackwardMessage() {
      if (this.moveDaysNumberStart >= 0) return this.translate.daysForward;
      return this.translate.daysBackward;
    },
    deadlineDaysForwardBackwardMessage: function deadlineDaysForwardBackwardMessage() {
      if (this.moveDaysNumberDeadline >= 0) return this.translate.daysForward;
      return this.translate.daysBackward;
    },
    moveDaysNumberStart: function moveDaysNumberStart() {
      return calculateDifferenceAsDays(this.start, this.originalStart);
    },
    moveDaysNumberDeadline: function moveDaysNumberDeadline() {
      return calculateDifferenceAsDays(this.deadline, this.originalDeadline);
    },
    isStartAfterDeadline: function isStartAfterDeadline() {
      return this.start > this.deadline;
    }
  },
  mounted: function mounted() {
    this.start = this.originalStart;
    this.deadline = this.originalDeadline;
  },
  methods: {
    moveInitiatives: function moveInitiatives() {
      var _this = this;
      _moveInitiatives(this.serializedData).then(function (response) {
        _this.$emit('moveInitiatives', _this.start, _this.deadline, response.data.success);
        _this.handleClose();
      }).catch(function () {
        return _this.handleClose();
      });
    },
    handleClose: function handleClose() {
      this.$emit('close');
    },
    changeStartDate: function changeStartDate(date) {
      this.start = formatTimeToDateFormat(date);
      if (this.linked) {
        this.deadline = this.formatDateWithAddedDays(this.originalDeadline, this.moveDaysNumberStart);
      }
    },
    changeDeadlineDate: function changeDeadlineDate(date) {
      this.deadline = formatTimeToDateFormat(date);
      if (this.linked) {
        this.start = this.formatDateWithAddedDays(this.originalStart, this.moveDaysNumberDeadline);
      }
    },
    toggleLinked: function toggleLinked() {
      this.linked = !this.linked;
      if (this.linked) {
        this.deadline = this.formatDateWithAddedDays(this.originalDeadline, this.moveDaysNumberStart);
      }
    },
    formatDateWithAddedDays: function formatDateWithAddedDays(date, moveNumber) {
      return addTimeAndFormatDate(date, moveNumber, 'days', DateFormats.DATE_FORMAT);
    }
  }
};