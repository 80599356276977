import RismaBarChart from '@/components/Molecules/Charts/RismaBarChart';
export default {
  components: {
    RismaBarChart: RismaBarChart
  },
  data: function data() {
    return {
      horizontalBarData: {
        datasets: [{
          backgroundColor: ['#43abb44', '#65ff23'],
          data: [10, 15],
          label: 'dataset label'
        }],
        labels: ['label1', 'label2']
      },
      stackedBarData: {
        labels: ['label1', 'label2'],
        datasets: [{
          label: 'Dataset1',
          data: [5, 7],
          backgroundColor: '#ae63f7'
        }, {
          label: 'Dataset2',
          data: [10, 17],
          backgroundColor: '#ff43ca'
        }]
      }
    };
  }
};