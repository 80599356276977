import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import ToolTip from '@/components/Atoms/Containers/ToolTip';
import { sortAlphabetic } from '@/utils/sort';
export default {
  name: 'DelegationList',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    ToolTip: ToolTip
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    labelKey: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    maxVisibleTags: {
      required: false,
      type: Number,
      default: -1,
      note: 'the max number of selected tags to be displayed. After the number has been reached the select will display: "{number} selected", instead of tags. if prop is -1 it will display all'
    },
    isUser: {
      required: false,
      type: Boolean,
      default: false,
      note: 'user has hover functionality to show user information'
    }
  },
  emits: ['edit'],
  data: function data() {
    return {
      hoverElement: null,
      toolTipPosition: null,
      lableRefs: {},
      translate: getTranslate['DelegationList']()
    };
  },
  computed: {
    isTruncateList: function isTruncateList() {
      return this.sortedList.length > this.maxVisibleTags && this.maxVisibleTags !== -1;
    },
    sortedList: function sortedList() {
      return sortAlphabetic(this.list, this.labelKey);
    }
  },
  watch: {
    list: function list() {
      if (this.list.length !== this.hoverList && this.isUser) {
        this.fillHoverList();
      }
    }
  },
  mounted: function mounted() {
    this.fillHoverList();
  },
  methods: {
    fillHoverList: function fillHoverList() {
      if (!this.isUser) return;
      this.hoverList = [];
      this.hoverList.length = this.list.length;
      this.hoverList.fill(false);
    },
    setHovered: function setHovered(item, index) {
      var _this = this;
      if (!this.isUser) return;
      this.hoverElement = item;
      this.$nextTick(function () {
        var hoveredPosition = _this.lableRefs[index].getBoundingClientRect();
        var toolTipPosition = _this.$refs.tooltip.$el.getBoundingClientRect();
        _this.toolTipPosition = {
          left: "".concat(Math.round(hoveredPosition.left) - Math.round(toolTipPosition.width) - 45, "px"),
          top: "".concat(Math.round(hoveredPosition.top) - 5, "px")
        };
      });
    },
    resetHovered: function resetHovered() {
      this.hoverElement = null;
      this.toolTipPosition = null;
    },
    setLabelRef: function setLabelRef(index) {
      var _this2 = this;
      return function (el) {
        return _this2.lableRefs[index] = el;
      };
    }
  }
};