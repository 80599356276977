import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-ba26ec74"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "deadline-preview"
};
var _hoisted_2 = {
  key: 0,
  class: "deadline-preview-list"
};
var _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.deadlines.length ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.deadlines, function (item, index) {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      innerHTML: item
    }, null, 8 /* PROPS */, _hoisted_3);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)]);
}