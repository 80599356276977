function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { cacheItem } from '@/routes/storeCache';
import { getActionsTree as getInitiatives, deleteAction } from '@/api/execution/initiative';
import * as ContractsApi from '@/api/contracts/contracts';
import * as ControlsApi from '@/api/controls/controls';
import { createRiskProject } from '@/api/risk/risk';
import { setControlsRecent } from '@/api/controls/controls';
import { deleteUser as _deleteUser } from '@/api/rismaadmin/user';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Alert from '@/components/Molecules/Modal/Alert';
import Notification from '@/components/Molecules/Notification';
import TreeList from '@/components/Atoms/TreeList';
import { comparePathStrings } from '@/utils/compare';
import { MODULES } from '@/constants/modules';
var ModelTypes = {
  FLAT: 'flat',
  TREE: 'tree'
};
export default {
  name: 'DangerZone',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    Notification: Notification,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    FeatherIcon: FeatherIcon,
    SingleSelect: SingleSelect,
    Alert: Alert,
    TreeList: TreeList
  },
  data: function data() {
    var _massDelete, _toggleObjects, _deleteFunctions, _showSection;
    return {
      confirmMessage: '',
      confirmCallback: null,
      showConfirmationAlert: false,
      notificationType: '',
      notificationMessage: '',
      loading: true,
      ModelTypes: ModelTypes,
      MODULES: MODULES,
      massDelete: (_massDelete = {}, _defineProperty(_massDelete, MODULES.CONTROLS, {
        label: 'controls',
        entities: [],
        model: {
          id: 'cpath',
          visibleId: 'cpath',
          title: 'title',
          type: ModelTypes.FLAT
        }
      }), _defineProperty(_massDelete, MODULES.MOTHER_CONTROLS, {
        label: 'mother controls',
        entities: [],
        model: {
          id: 'cpath',
          visibleId: 'cpath',
          title: 'title',
          type: ModelTypes.FLAT
        }
      }), _defineProperty(_massDelete, MODULES.EXECUTION, {
        label: 'actions',
        entities: [],
        model: {
          id: 'slug',
          visibleId: 'ipath',
          title: 'title',
          type: ModelTypes.TREE
        }
      }), _defineProperty(_massDelete, MODULES.CONTRACTS, {
        label: 'contracts',
        entities: [],
        model: {
          id: 'id',
          visibleId: 'uniqueId',
          title: 'title',
          type: ModelTypes.FLAT
        }
      }), _massDelete),
      toggleObjects: (_toggleObjects = {}, _defineProperty(_toggleObjects, MODULES.CONTROLS, {}), _defineProperty(_toggleObjects, MODULES.CONTRACTS, {}), _defineProperty(_toggleObjects, MODULES.MOTHER_CONTROLS, {}), _defineProperty(_toggleObjects, MODULES.EXECUTION, {}), _toggleObjects),
      deleteFunctions: (_deleteFunctions = {}, _defineProperty(_deleteFunctions, MODULES.CONTROLS, ControlsApi.deleteControl), _defineProperty(_deleteFunctions, MODULES.CONTRACTS, ContractsApi.deleteContract), _defineProperty(_deleteFunctions, MODULES.MOTHER_CONTROLS, ControlsApi.deleteControl), _defineProperty(_deleteFunctions, MODULES.EXECUTION, deleteAction), _deleteFunctions),
      loadFunctions: _defineProperty({}, MODULES.EXECUTION, this.getActions),
      usersData: {
        deleteUserId: null,
        sendToId: null
      },
      showSection: (_showSection = {}, _defineProperty(_showSection, MODULES.CONTROLS, true), _defineProperty(_showSection, MODULES.CONTRACTS, true), _defineProperty(_showSection, MODULES.EXECUTION, false), _defineProperty(_showSection, "users", true), _showSection),
      translate: getTranslate['DangerZone'](),
      treeEntitiesToDelete: _defineProperty({}, MODULES.EXECUTION, [])
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    allUsers: 'allUsers'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    flatEntitiesToDelete: function flatEntitiesToDelete() {
      var _this = this;
      return Object.keys(this.toggleObjects).reduce(function (acc, cur) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, cur, Object.keys(_this.toggleObjects[cur]).filter(function (key) {
          return _this.toggleObjects[cur][key];
        })));
      }, {});
    },
    deleteUserName: function deleteUserName() {
      var _this2 = this;
      if (!this.usersData.deleteUserId) {
        return '';
      }
      return this.allUsers.find(function (user) {
        return user.id === _this2.usersData.deleteUserId;
      }).display_name;
    },
    allUsersObject: function allUsersObject() {
      var _this3 = this;
      var list = this.allUsers.map(function (user) {
        return {
          value: user.id,
          label: user.display_name + (user.active ? '' : ' (inactive) ')
        };
      });
      return {
        deleteList: list.filter(function (user) {
          return user.value !== _this3.currentUser.id;
        }),
        sendToList: this.usersData.deleteUserId ? list.filter(function (user) {
          return user.value !== _this3.usersData.deleteUserId;
        }) : list
      };
    }
  }),
  mounted: function mounted() {
    var _this4 = this;
    Promise.all([this.getControls(), this.getContracts(), this.getMotherControls(), this.getActions()]).then(function () {
      return _this4.loading = false;
    });
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useUsersStore, {
    loadUsers: 'load'
  })), {}, {
    deleteUser: function deleteUser() {
      var _this5 = this;
      this.confirmMessage = "".concat(this.translate.areYouSureYouWantToDelete, " ").concat(this.deleteUserName, " ").concat(this.translate.permanently, "?");
      this.showConfirmationAlert = true;
      this.confirmCallback = function () {
        return _deleteUser(_this5.usersData.deleteUserId, _this5.usersData.sendToId).then(function () {
          cacheItem('users', function () {
            return _this5.loadUsers();
          }, 180);
          _this5.resetUsersData();
        }).catch(function (error) {
          return _this5.setNotification('error', error.response.error);
        });
      };
    },
    resetUsersData: function resetUsersData() {
      this.usersData = {
        deleteUserId: null,
        sendToId: null
      };
    },
    onAlertConfirm: function onAlertConfirm() {
      this.confirmCallback();
      this.showConfirmationAlert = false;
      this.confirmCallback = null;
    },
    copyRisks: function copyRisks() {
      var _this6 = this;
      this.confirmMessage = this.translate.areYouSure;
      this.showConfirmationAlert = true;
      this.confirmCallback = function () {
        var data = {
          fromRismaAdmin: true
        };
        return createRiskProject(data).then(function (response) {
          _this6.setNotification('success', "Server response: ".concat(response.message));
        }).catch(function (response) {
          _this6.setNotification('error', "Server failed with this message: ".concat(response.message));
        });
      };
    },
    resetControls: function resetControls() {
      var _this7 = this;
      this.confirmMessage = this.translate.areYouSure;
      this.showConfirmationAlert = true;
      this.confirmCallback = function () {
        var data = {
          fromRismaAdmin: true
        };
        setControlsRecent(data).then(function (response) {
          _this7.setNotification('success', "Server response: ".concat(response.message));
        }).catch(function (response) {
          _this7.setNotification('error', "Server failed with this message: ".concat(response.message));
        });
      };
    },
    getContracts: function getContracts() {
      var _this8 = this;
      return ContractsApi.getContractsReport().then(function (_ref) {
        var list = _ref.list;
        _this8.massDelete[MODULES.CONTRACTS].entities = _this8.sortItems(list, 'uniqueId');
        _this8.setToggleObject(MODULES.CONTRACTS);
      });
    },
    getControls: function getControls() {
      var _this9 = this;
      return ControlsApi.getAll().then(function (_ref2) {
        var list = _ref2.list;
        _this9.massDelete[MODULES.CONTROLS].entities = _this9.sortItems(list, 'cpath');
        _this9.setToggleObject(MODULES.CONTROLS);
      });
    },
    getMotherControls: function getMotherControls() {
      var _this10 = this;
      return ControlsApi.getAllMotherControls().then(function (_ref3) {
        var list = _ref3.list;
        _this10.massDelete[MODULES.MOTHER_CONTROLS].entities = _this10.sortItems(list, 'cpath');
        _this10.setToggleObject(MODULES.CONTROLS);
      });
    },
    getActions: function getActions() {
      var _this11 = this;
      return getInitiatives().then(function (_ref4) {
        var children = _ref4.children;
        _this11.massDelete[MODULES.EXECUTION].entities = children;
        _this11.setToggleObject(MODULES.EXECUTION, children, false);
      });
    },
    setToggleObject: function setToggleObject(key) {
      this.setAllValuesTo(key, false);
    },
    sortItems: function sortItems(items, sortBy) {
      return _toConsumableArray(items).sort(function (a, b) {
        return comparePathStrings(a[sortBy], b[sortBy]);
      });
    },
    deleteEntities: function deleteEntities(key, type) {
      var _this12 = this;
      this.confirmMessage = this.translate.areYouSure;
      this.showConfirmationAlert = true;
      this.confirmCallback = function () {
        var arrayOfIds = [];
        var promises = [];
        switch (type) {
          case ModelTypes.FLAT:
            arrayOfIds = _this12.flatEntitiesToDelete[key];
            break;
          case ModelTypes.TREE:
            arrayOfIds = _this12.treeEntitiesToDelete[key];
            break;
        }
        promises = arrayOfIds.map(function (id) {
          return _this12.deleteFunctions[key](id);
        });
        Promise.all(promises).then(function (response) {
          if (_this12.massDelete[key].model.type === _this12.ModelTypes.TREE) {
            _this12.loadFunctions[key]();
            _this12.treeEntitiesToDelete[key] = [];
          } else {
            var deletedIds = response.map(function (item) {
              return +item.id;
            });
            _this12.massDelete[key].entities = _this12.massDelete[key].entities.filter(function (item) {
              return !deletedIds.includes(+item.id);
            });
            _this12.setToggleObject(key);
          }
        }).catch(function (error) {
          _this12.setNotification('error', "Server failed with this message: ".concat(error.message || error.error || error.response.error));
        });
      };
    },
    toggleSelectAll: function toggleSelectAll(key, e) {
      this.setAllValuesTo(key, e.target.checked);
    },
    setAllValuesTo: function setAllValuesTo(key, value) {
      var _this13 = this;
      var modelId = this.massDelete[key].model.id;
      var modelType = this.massDelete[key].model.type;
      var entities = this.massDelete[key].entities;
      this.toggleObjects[key] = {};
      if (modelType === this.ModelTypes.TREE) {
        entities.forEach(function (item) {
          _this13.setToggleObjectForTreeEl(key, item, value);
        });
        this.treeEntitiesToDelete[key] = value ? entities.map(function (item) {
          return item[modelId];
        }) : [];
      }
      if (modelType === this.ModelTypes.FLAT) {
        entities.forEach(function (item) {
          _this13.toggleObjects[key][item[modelId]] = value;
        });
      }
      this.toggleObjects = _objectSpread(_objectSpread({}, this.toggleObjects), {}, _defineProperty({}, key, _objectSpread({}, this.toggleObjects[key])));
      this.$forceUpdate();
    },
    setToggleObjectForTreeEl: function setToggleObjectForTreeEl(key, root, value) {
      var _this14 = this;
      var modelId = this.massDelete[key].model.id;
      this.toggleObjects[key][root[modelId]] = value;
      if (root.children && root.children.length) {
        root.children.forEach(function (item) {
          _this14.setToggleObjectForTreeEl(key, item, value);
        });
      }
    },
    setToggleObjectForItem: function setToggleObjectForItem(key, itemId, value) {
      this.toggleObjects[key][itemId] = value;
    },
    getAllTreeItemChildrenIds: function getAllTreeItemChildrenIds(key, root) {
      var _ref5,
        _this15 = this;
      return (_ref5 = [root[key]]).concat.apply(_ref5, _toConsumableArray((root.children || []).map(function (child) {
        return _this15.getAllTreeItemChildrenIds(key, child);
      })));
    },
    onTreeItemCheck: function onTreeItemCheck(key, item, event) {
      var _this16 = this;
      var modelId = this.massDelete[key].model.id;
      var allSelectedIds = this.getAllTreeItemChildrenIds(modelId, item);
      var value = event.target.checked;
      allSelectedIds.forEach(function (id) {
        return _this16.setToggleObjectForItem(key, id, value);
      });
      this.treeEntitiesToDelete[key] = this.treeEntitiesToDelete[key].filter(function (id) {
        return !allSelectedIds.includes(id);
      });
      if (value) {
        this.treeEntitiesToDelete[key].push(item[modelId]);
      }
    },
    setNotification: function setNotification(type, message) {
      var _this17 = this;
      this.$scrollTo(document.body, 100);
      this.notificationMessage = message;
      this.notificationType = type;
      setTimeout(function () {
        return _this17.notificationMessage = '';
      }, 5000);
    }
  })
};