import AwarenessDetails from '@/components/Pages/Awareness/AwarenessDetails';
import AwarenessDashboard from '@/components/Pages/Awareness/AwarenessDashboard';
import { userHasPoliciesAccess } from '@/routes/auth';
export default function getAwarenessRoutes() {
  return [{
    path: '/awareness/:activityType/:token',
    component: AwarenessDetails,
    props: true,
    meta: {
      skipLoginCheck: true
    }
  }, {
    path: '/awareness/dashboard',
    component: AwarenessDashboard,
    props: true,
    meta: {
      userRightsFunction: userHasPoliciesAccess
    }
  }];
}