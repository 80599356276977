import i18n from '@/i18n';
export var getTranslate = {
  ReportDataflow: function ReportDataflow() {
    return {
      stakeholder: i18n.t('Stakeholder'),
      direction: i18n.t('Direction'),
      dataTypes: i18n.t('Data types'),
      title: i18n.t('title'),
      comments: i18n.t('Comments'),
      process: i18n.t('Process'),
      organisation: i18n.t('Organisation'),
      deliversData: i18n.t('Delivers data'),
      receivesData: i18n.t('Receives data'),
      systems: i18n.t('Systems'),
      tags: i18n.t('Tags')
    };
  },
  ReportSearchForm: function ReportSearchForm() {
    return {
      resetFilter: i18n.t('Reset filter'),
      node: i18n.t('Node'),
      nodes: i18n.t('Nodes'),
      includeUnderlyingNodes: i18n.t('Include underlying nodes'),
      organisation: i18n.t('Organisation'),
      organisations: i18n.t('Organisations'),
      users: i18n.t('Users'),
      includeScoringInReportWillAffectPerformance: i18n.t('Include scoring in report (will affect performance of report)'),
      allNodes: i18n.t('All nodes'),
      tags: i18n.t('Tags'),
      attributes: i18n.t('Attributes')
    };
  },
  ReportTabs: function ReportTabs() {
    return {
      nodes: i18n.t('Nodes'),
      gap: i18n.t('Gap'),
      dataFlow: i18n.t('Data flow'),
      memorandum: i18n.t('Memorandum report')
    };
  }
};