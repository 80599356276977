export default {
  props: {
    isApprovalFlowEnabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    tags: function tags() {
      var result = [];
      if (this.risk.tags && this.risk.tags.length) {
        result = this.risk.tags.map(function (item) {
          return item.tag;
        });
      }
      return result.join(', ');
    }
  }
};