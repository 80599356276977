import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3b3e1442"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["width", "height"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("canvas", {
    width: $options.computedSize.width,
    height: $options.computedSize.height,
    class: "grid-lines"
  }, null, 8 /* PROPS */, _hoisted_1);
}