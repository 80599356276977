import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getTiaLiaSettings() {
  return handleRequestResponse(Http.get('admin/compliance/tialia'));
}
export function deleteTiaLiaSettings(id) {
  return handleRequestResponse(Http.delete("admin/compliance/tialia/".concat(id)));
}
export function updateTiaLiaSettings(id, updatedSetting) {
  return handleRequestResponse(Http.patch("admin/compliance/tialia/".concat(id), updatedSetting));
}
export function createTiaLiaSettings(updatedSetting) {
  return handleRequestResponse(Http.post('admin/compliance/tialia', updatedSetting));
}