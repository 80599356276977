function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { Colors } from '@/Colors';
import { getCurrentUser } from '@/api/risma/data';
import { getAllControlsFromCpaths } from '@/api/controls/controls';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import StaticTabs from '@/components/Atoms/StaticTabs';
import StaticTabItem from '@/components/Atoms/StaticTabItem';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaBarChart from '@/components/Molecules/Charts/RismaBarChart';
import Notification from '@/components/Molecules/Notification';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import RismaSelectorBar from '@/components/Molecules/RismaSelectorBar';
import GapTable from '@/components/Organisms/Compliance/Dashboard/GapTable';
import SupervisorInitiatives from './SupervisorInitiatives';
import SupervisorControls from './SupervisorControls';
import { ComplianceDashboardMixin } from '@/components/Pages/Compliance/ComplianceDashboardMixin';
import { gapsToInitiatives, gapsToControls, nullKeysToString } from '@/utils';
import { getUsersFromIds, getOrganisationsFromIds } from '@/utils/format/objectsFromIds';
import { addGapsToControls } from '@/utils/format/gapListToAcitivitiList';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { RismaSelectorBarMixin } from '@/mixins/RismaSelectorBarMixin';
import { UserLevels } from '@/constants/UserLevels';
import { calcExecutionRights, calcControlsRights } from '@/menus/quicknav';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'SupervisorDashboard',
  components: {
    GapTable: GapTable,
    RismaSelectorBar: RismaSelectorBar,
    RismaDoughnutChart: RismaDoughnutChart,
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    TrafficLight: TrafficLight,
    FeatherIcon: FeatherIcon,
    SupervisorControls: SupervisorControls,
    SupervisorInitiatives: SupervisorInitiatives,
    StaticTabItem: StaticTabItem,
    StaticTabs: StaticTabs,
    Notification: Notification,
    RismaBarChart: RismaBarChart
  },
  mixins: [RaciTitleMixin, ComplianceDashboardMixin, RismaSelectorBarMixin],
  introduction: 'supervisor dashboard page',
  description: 'this page shows the dashboards for the supervisor',
  token: '<supervisor-dashboard></supervisor-dashboard>',
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    var projectId = to.params.projectId;
    getCurrentUser().then(function (user) {
      var project = user.projects.find(function (project) {
        return project.id === +projectId && project.objType === ObjectTypes.COMPLIANCE_PROJECT;
      });
      if (!project || !project.supervisor && !user.level_admin && project.access_level <= UserLevels.SUPER) {
        next('/');
      } else {
        next(function (vm) {
          vm.projectType = project.type;
        });
      }
    });
  },
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Project id from route'
    }
  },
  data: function data() {
    return {
      projectType: null,
      controls: [],
      localStorageKey: "supervisor_dashboard_filters_".concat(this.projectId),
      gapDataDownloadedAtOnce: true,
      predefinedGapFilter: [{
        id: Colors.gaps[3].number,
        label: this.$trans(Colors.gaps[3].label),
        color: Colors.gaps[3].color
      }, {
        id: Colors.gaps[4].number,
        label: this.$trans(Colors.gaps[4].label),
        color: Colors.gaps[4].color
      }],
      translate: getTranslate['SupervisorDashboard']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    getAllSettings: 'getAllSettings'
  })), mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserComplianceProjectById: 'complianceProjectById'
  })), {}, {
    currentProject: function currentProject() {
      return this.currentUserComplianceProjectById(this.projectId);
    },
    enableGapRiskModule: function enableGapRiskModule() {
      return !!this.currentProject.enableGapRiskModule;
    },
    showRiskAssessment: function showRiskAssessment() {
      return !!this.currentProject.showRiskAssessment;
    },
    title: function title() {
      return this.projectType === 1 ? this.translate.dpoDashboard : this.translate.supervisorDashboard;
    },
    gapsAvailable: function gapsAvailable() {
      var gapArticleMap = this.gapDashboardData.gapArticleMap;
      return gapArticleMap && gapArticleMap.size > 0;
    },
    initiatives: function initiatives() {
      if (!this.gapData) return [];
      var initiatives = gapsToInitiatives(this.gapData);
      initiatives = this.addNodeTitleToGap(initiatives);
      return initiatives;
    },
    gapFilter: function gapFilter() {
      // Refactor this function to not use $refs
      var filter = this.$refs.filter;
      if (!filter) {
        return [];
      }
      var gaps = filter.$refs.gaps;
      if (!gaps || !gaps[0]) {
        return [];
      }
      return gaps[0].selected.map(function (selectedGap) {
        return selectedGap.id;
      });
    },
    pieChartData: function pieChartData() {
      var _this = this;
      var labels = [];
      var data = [];
      var backgroundColor = [];
      var total = 0;
      this.filteredOverview.forEach(function (entry) {
        labels.push(_this.$trans(entry.title));
        data.push(entry.value);
        backgroundColor.push(entry.color);
        total += +entry.value;
      });
      return {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: backgroundColor,
          total: total
        }]
      };
    },
    filteredOverview: function filteredOverview() {
      return this.getFilteredOverview(this.gapFilter, this.gapOverview);
    },
    overviewTotal: function overviewTotal() {
      return this.filteredOverview.reduce(function (acc, curr) {
        return acc += curr.value;
      }, 0);
    },
    supervisorTabs: function supervisorTabs() {
      return {
        actions: calcExecutionRights(this.currentUser, this.getAllSettings),
        controls: calcControlsRights(this.currentUser, this.getAllSettings)
      };
    },
    activeTab: function activeTab() {
      if (this.$route.hash) return this.$route.hash;
      if (this.supervisorTabs.actions) return '#actions';
      if (this.supervisorTabs.controls) return '#controls';
      return '';
    },
    selectors: function selectors() {
      return [{
        list: 'gaps',
        label: this.translate.gaps,
        preSelected: this.predefinedGapFilter
      }, {
        list: 'nodes',
        label: this.translate.nodes,
        preSelected: this.predefinedNodesFilter,
        toggle: {
          title: this.translate.includeUnderlyingNodes,
          option: this.includeChildToggleOption,
          event: this.onIncludeChildNodesToggle
        }
      }, {
        list: 'organisations',
        label: this.translate.organisations,
        preSelected: this.predefinedOrganisationsFilter
      }, {
        list: 'users',
        label: this.responsibleTitle(true),
        preSelected: this.predefinedUsersFilter
      }, {
        list: 'tags',
        label: this.translate.tags,
        preSelected: this.predefinedTagsFilter
      }];
    }
  }),
  methods: {
    loadDashboardData: function loadDashboardData(filterString) {
      var _this2 = this;
      var promises = [this.getGapDashboard(filterString), this.getGaps(filterString)];
      this.loading = true;
      return Promise.all(promises).then(this.onDataFetched).then(this.fetchControls).catch(function (error) {
        return _this2.handleDashboardError(error);
      }).finally(function () {
        return _this2.loading = false;
      });
    },
    loadData: function loadData() {
      var _this3 = this;
      return this.getGapSchemas().catch(function (error) {
        return _this3.handleDashboardError(error);
      });
    },
    processLinks: function processLinks(links) {
      var _this4 = this;
      links = links.filter(function (item) {
        return item !== null;
      });
      links.forEach(function (link) {
        link = nullKeysToString(link);
        Object.keys(link.userIds).forEach(function (key) {
          link.userIds[key] = getUsersFromIds(link.userIds[key], _this4.users);
        });
        link.organisationIds = getOrganisationsFromIds(link.organisationIds, _this4.orgs);
      });
    },
    onDataFetched: function onDataFetched() {
      this.handleGapLinks();
    },
    handleGapLinks: function handleGapLinks() {
      var _this5 = this;
      this.gapData.map(function (gap) {
        if (gap.links.execution !== undefined) {
          _this5.processLinks(gap.links.execution);
        }
        if (gap.links.control !== undefined) {
          _this5.processLinks(gap.links.control);
        }
      });
    },
    addNodeTitleToGap: function addNodeTitleToGap(activities) {
      var _this6 = this;
      activities.map(function (activity) {
        activity.gap.map(function (gap) {
          var node = _this6.nodes.find(function (node) {
            return node.id === gap.nodeId;
          });
          gap.nodeTitle = node.title;
          gap.nodeUrl = node.url;
          gap.nodeProjectId = node.compliance_projects_id;
        });
      });
      return activities;
    },
    fetchControls: function fetchControls() {
      var _this7 = this;
      var controls = gapsToControls(this.gapData);
      if (controls.length === 0) {
        this.controls = [];
        return;
      }
      var cpaths = controls.map(function (control) {
        return control.cpath;
      });
      getAllControlsFromCpaths(cpaths, ['organisationIds']).then(function (response) {
        var controls = response.list;
        addGapsToControls(controls, _this7.gapData);
        controls = _this7.addNodeTitleToGap(controls);
        _this7.controls = controls;
      });
    },
    getFilteredOverview: function getFilteredOverview(gapFilter, gapOverview) {
      if (gapFilter.length === 0) {
        return gapOverview;
      }
      return gapOverview.filter(function (gapDataItem) {
        return gapFilter.some(function (selectedGap) {
          return gapDataItem.traffic_light == selectedGap;
        });
      });
    },
    onDismissPreview: function onDismissPreview() {
      this.fetchData(JSON.parse(localStorage.getItem(this.localStorageKey)).filterObj);
    },
    getGapDataByIdx: function getGapDataByIdx(idx) {
      var gapByIndex = this.filteredOverview[idx];
      if (!gapByIndex) return {};
      return {
        label: gapByIndex.title,
        number: gapByIndex.traffic_light,
        color: gapByIndex.color
      };
    }
  }
};