import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: _ctx.$trans('Page title')
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: _ctx.$trans('Section header'),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: _ctx.$trans('Input label'),
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    editable: true,
    title: _ctx.$trans('Page title Editable')
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    editable: true,
    title: _ctx.$trans('Section header Editable'),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    editable: true,
    title: _ctx.$trans('Input label Editable'),
    type: "small"
  }, null, 8 /* PROPS */, ["title"])]);
}