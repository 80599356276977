import i18n from '@/i18n';
export var getTranslate = {
  AppInvitations: function AppInvitations() {
    return {
      sendInvitation: i18n.t('Send invitation'),
      username: i18n.t('Username'),
      fullName: i18n.t('Full name'),
      email: i18n.t('Email'),
      status: i18n.t('Status'),
      invitationDate: i18n.t('Invitation date'),
      incidentAppInvitations: i18n.t('Incident app invitations'),
      controlsAppInvitations: i18n.t('Controls app invitations'),
      invited: i18n.t('Invited'),
      notInvited: i18n.t('Not invited'),
      showInvitedOnly: i18n.t('Show invited only')
    };
  },
  LookFeelPage: function LookFeelPage() {
    return {
      lookAndFeel: i18n.t('Look and feel'),
      menuIcon: i18n.t('Menu icon'),
      changeTheIconInTheNavigationBar: i18n.t('Change the icon in the navigation bar'),
      reset: i18n.t('Reset'),
      uploadNewIcon: i18n.t('Upload new icon'),
      menuColorScheme: i18n.t('Menu color scheme'),
      changeTheColorForTheNavigationBar: i18n.t('Change the color for the navigation bar')
    };
  },
  UserSettingsNotifications: function UserSettingsNotifications() {
    return {
      chooseHowYouWantToReceiveNotifications: i18n.t('Choose how you want to receive notifications'),
      settingsUpdated: i18n.t('Settings updated'),
      somethingWentWrongUserWasNotUpdated: i18n.t('Something went wrong. User was not updated'),
      rismaModule: i18n.t('RISMA module'),
      notification: i18n.t('Notification'),
      rismacomm: i18n.t('RISMAcomm'),
      email: i18n.t('E-mail'),
      save: i18n.t('Save')
    };
  },
  UserSettingsPassword: function UserSettingsPassword() {
    return {
      changePassword: i18n.t('Change password'),
      toChangeYourPassword: i18n.t('To change your current password, please enter your current password, and then the new password. Remember to repeat your new password to confirm.'),
      currentPassword: i18n.t('Current password'),
      newPassword: i18n.t('New password'),
      repeatNewPassword: i18n.t('Repeat new password')
    };
  },
  UserSettingsProfile: function UserSettingsProfile() {
    return {
      twoFactorAuthentication: i18n.t('Two Factor Authentication'),
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      pleaseOpenYourAuthenticatorApplicationOnYour: i18n.t('Please open your authenticator application on your device and scan the QR code or enter configuration key.'),
      configurationKey: i18n.t('Configuration key'),
      profileDetails: i18n.t('Profile details'),
      username: i18n.t('Username'),
      fullName: i18n.t('Full name'),
      initials: i18n.t('Initials'),
      email: i18n.t('E-mail'),
      organisationsYouAreConnectedTo: i18n.t('Organisations you are connected to'),
      language: i18n.t('Language'),
      password: i18n.t('Password'),
      changePassword: i18n.t('Change password'),
      useAnAuthenticatorToGenerateVerificationCode: i18n.t('Use an authenticator to generate verification codes for further protection.'),
      enableAuthentication: i18n.t('Enable authentication'),
      disableAuthentication: i18n.t('Disable authentication'),
      downloadAnAuthenticatorFromAppStoreplayStor: i18n.t('Download an authenticator from app store/play store to get started.')
    };
  }
};