import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { singularIATitles } from '@/constants/compliance/ComplianceControllers';
export var InformationAssetsDeleteMixin = {
  data: function data() {
    return {
      deleteIA: null
    };
  },
  computed: {
    iaTitle: function iaTitle() {
      var title = singularIATitles[this.deleteIA.objType];
      if (title) return this.$trans(title).toLowerCase();
      return this.deleteIA.deleteLabel || singularIATitles[AssetsTypes.CUSTOM];
    },
    deleteHeader: function deleteHeader() {
      return "".concat(this.translate.delete, " ").concat(this.iaTitle, "?");
    },
    deleteBody: function deleteBody() {
      return "".concat(this.translate.areYouSureYouWantToDeleteThis, " ").concat(this.iaTitle, "?");
    }
  }
};