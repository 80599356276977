import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tab_item = _resolveComponent("tab-item");
  var _component_tabs = _resolveComponent("tabs");
  return _openBlock(), _createBlock(_component_tabs, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_tab_item, {
        link: "/settings/bulk/switch"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.activities), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }), $options.isPerformanceReportEnabled ? (_openBlock(), _createBlock(_component_tab_item, {
        key: 0,
        link: "/settings/bulk/solutionid"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.updateSolutionIds), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_tab_item, {
        link: "/settings/bulk/organisation"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.assignOrganisation), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }), _createVNode(_component_tab_item, {
        disabled: true,
        link: "/settings/bulk/ownership"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.ownership), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }), _createVNode(_component_tab_item, {
        disabled: true,
        link: "/settings/bulk/tags"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.assignTags), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })];
    }),

    _: 1 /* STABLE */
  });
}