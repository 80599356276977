import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getJiraProjects() {
  return handleRequestResponse(Http.get('/external/jira/project'));
}
export function createJiraIssue(projectKey, issueType, module, moduleId) {
  var params = {
    projectKey: projectKey,
    issueType: issueType,
    module: module,
    moduleId: moduleId
  };
  return handleRequestResponse(Http.post('/external/jira/issue', params));
}