export var ProcessLibraryVisibleProps = [{
  key: 'title',
  text: 'Title'
}, {
  key: 'description',
  text: 'Description'
}, {
  key: 'organisationIds',
  text: 'Organisations'
}, {
  key: 'responsibleUserIds',
  text: 'Responsible'
}, {
  key: 'accountableUserIds',
  text: 'Accountable'
}];