function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import PrivacyModal from '@/components/Molecules/Modal/PrivacyModal';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { login } from '@/api/login/login';
import { loginFailTypes } from '@/constants/loginFailTypes';
import { LogoSettings } from '@/constants/admin/CustomizationSettings';
import Modal from '@/components/Molecules/Modal/Modal';
import MfaLogin from '@/components/Pages/MFA/MfaLogin';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'WelcomePage',
  components: {
    MfaLogin: MfaLogin,
    Modal: Modal,
    RismaTitle: RismaTitle,
    InputField: InputField,
    RismaButton: RismaButton,
    PrivacyModal: PrivacyModal,
    Notification: Notification,
    FeatherIcon: FeatherIcon
  },
  setup: function setup() {
    return {
      isDefaultLoginShown: getLocalStorageComputed('welcome_page_login_shown_key', false, true)
    };
  },
  data: function data() {
    return {
      data: {
        username: '',
        password: '',
        mfaToken: ''
      },
      showPrivacyModal: false,
      isMounted: false,
      errorFromBackend: '',
      showMFAModal: false,
      translate: getTranslate['WelcomePage']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    isOIDC: function isOIDC() {
      return this.authMode === 'oidc';
    },
    isSAML: function isSAML() {
      return this.authMode === 'saml';
    },
    hideLoginFields: function hideLoginFields() {
      return this.isOIDC && this.getSettingValue('feature.hide_normal_login_fields');
    },
    showSingleSignon: function showSingleSignon() {
      return this.isOIDC || this.isSAML;
    },
    singleSignonUrl: function singleSignonUrl() {
      if (this.isOIDC) {
        return '/adconnect';
      }
      if (this.isSAML) {
        return '/saml/login';
      }
      return '';
    },
    logoSrc: function logoSrc() {
      return this.customersLogo || LogoSettings.DEFAULT_WITH_BLUE_TEXT;
    },
    rismaversion: function rismaversion() {
      return this.getSettingValue('app.version');
    },
    customersLogo: function customersLogo() {
      return this.getSettingValue('app.customer_logo') ? '/assets/customers/' + this.getSettingValue('app.customer_logo') : null;
    },
    hasAutoComplete: function hasAutoComplete() {
      return this.getSettingValue('feature.enterprise_security');
    },
    privacyPolicy: function privacyPolicy() {
      return this.getSettingValue('feature.privacy_policy');
    },
    authMode: function authMode() {
      return this.getSettingValue('app.authentication_mode');
    },
    getYear: function getYear() {
      var date = new Date();
      return date.getFullYear();
    },
    disclaimerTitle: function disclaimerTitle() {
      return "<b>".concat(this.translate.disclaimer, "</b>: ").concat(this.translate.rismasSystemsAndTheContentsIncorporatedIn);
    },
    bottomLineText: function bottomLineText() {
      return this.translate.clickAClasscursorpointerTargetblank;
    },
    loginButtonMouseOver: function loginButtonMouseOver() {
      if (this.getSettingValue('servername')) {
        return 'servername = ' + this.getSettingValue('servername');
      } else {
        return this.translate.logIn;
      }
    }
  }),
  mounted: function mounted() {
    if (this.currentUser && this.currentUser.id) {
      this.handleRedirect();
    } else {
      this.isMounted = true;
      /* load hubspot */
      var script = document.createElement('script');
      script.src = 'https://js.hs-scripts.com/5643563.js';
      script.id = 'hs-script-loader';
      document.body.appendChild(script);
    }
  },
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;
      var username = this.data.username;
      var password = this.data.password;
      var mfaToken = this.data.mfaToken;
      this.errorFromBackend = '';
      login(username, password, mfaToken).then(function (response) {
        return _this.handleRedirect(response);
      }).catch(function (_ref) {
        var response = _ref.response;
        if (response.failType === loginFailTypes.LOGIN_FAIL_PASSWORD_EXPIRED) {
          window.location.href = "/change-password/".concat(response.userId);
        } else if (response.failType === loginFailTypes.LOGIN_FAIL_MISSING_MFA_TOKEN) {
          _this.showMFAModal = true;
          _this.data.mfaToken = '';
        } else {
          _this.errorFromBackend = response.error;
        }
      });
    },
    handleRedirect: function handleRedirect() {
      var urlParams = new URLSearchParams(window.location.search);
      //shouldn`t be router.push -> need to update some missing data, that was already uploaded, skipLoginCheck page
      window.location.href = urlParams.get('returnUrl') || this.getSettingValue('risma.landing_page') || '/mypage';
    },
    handleAzure: function handleAzure() {
      var openedWindow = window.open(this.singleSignonUrl, '', 'width=500,height=500');
      var interval = setInterval(function () {
        if (openedWindow.closed) {
          clearInterval(interval);
          window.location.reload();
        }
      }, 100);
    },
    moveToPassword: function moveToPassword() {
      document.getElementById('password-field').firstChild.focus();
    },
    handleMfaLogin: function handleMfaLogin(token) {
      this.data.mfaToken = token;
      this.handleSubmit();
    },
    closeMfaModal: function closeMfaModal() {
      this.showMFAModal = false;
      this.data.mfaToken = '';
      this.errorFromBackend = '';
    }
  }
};