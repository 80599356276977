function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import Breadcrumbs from '@/components/Atoms/Breadcrumbs';
export default {
  name: 'NodeBreadcrumbs',
  introduction: 'Tabs for node edit',
  description: 'Tabs for node edit',
  token: '<node-breadcrumbs node="{id: 1, title: \'test\'}" project-id=1 />',
  components: {
    Breadcrumbs: Breadcrumbs
  },
  props: {
    node: {
      required: true,
      type: Object,
      note: 'Node object'
    }
  },
  data: function data() {
    return {
      breadcrumbs: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    featureBreadcrumbs: function featureBreadcrumbs() {
      return this.getSettingValue('feature.breadcrumbs');
    }
  }),
  mounted: function mounted() {
    this.breadcrumbs = this.featureBreadcrumbs && this.buildNodePath(this.node, [{
      title: this.node.title
    }]);
  },
  methods: {
    buildNode: function buildNode(node) {
      return {
        title: node.title,
        url: node.checkaccess ? node.url : null
      };
    },
    buildNodePath: function buildNodePath(node) {
      var parents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      if (node.parent) {
        parents.unshift(this.buildNode(node.parent));
        return this.buildNodePath(node.parent, parents);
      }
      return parents;
    }
  }
};