import { getTranslate } from './translate';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
export default {
  name: 'ScoringAxisEdit',
  introduction: 'Edit scoring values',
  description: "Molecule - show and edit labels/values for a risk matrix axis.\n     This component is used in risma2, the old one must be removed when the Risk Period page is completed",
  token: '<scoring-axis-edit/>',
  components: {
    InputField: InputField,
    SingleSelect: SingleSelect
  },
  props: {
    useToggle: {
      type: Boolean,
      default: false,
      note: 'Add a checkbox to enable this axis'
    },
    title: {
      type: String,
      required: true,
      note: 'Main prop - default title for axis'
    },
    scoringId: {
      type: Number,
      required: true,
      note: 'Id of the scoring period to edit'
    },
    scoringAxis: {
      type: String,
      required: true,
      note: 'Type of axis, e.g. X, Y or Z'
    },
    scoringCount: {
      type: Number,
      required: false,
      note: 'Number of levels - default value for select',
      default: 0
    },
    scoringLabels: {
      type: [Object, Array],
      required: true,
      note: 'Values for labels - default values'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['changed'],
  data: function data() {
    return {
      showForm: true,
      vTitle: 'Default title',
      translate: getTranslate['ScoringAxisEditNew']()
    };
  },
  computed: {
    filteredLabels: function filteredLabels() {
      var labelsArray = Object.values(this.scoringLabels);
      if (labelsArray.length < this.scoringCount) {
        for (var i = labelsArray.length; i < this.scoringCount; i++) {
          labelsArray.push({
            value: 'v' + (i + 1)
          });
        }
        return labelsArray;
      }
      return labelsArray.slice(0, this.scoringCount);
    },
    singleSelectOptions: function singleSelectOptions() {
      return [{
        value: 5,
        label: '5 ' + this.translate.levels
      }, {
        value: 6,
        label: '6 ' + this.translate.levels
      }];
    }
  },
  mounted: function mounted() {
    if (this.scoringCount === null) {
      this.showForm = false;
    }
  },
  methods: {
    update: function update() {
      var obj = this.filteredLabels.reduce(function (acc, cur, i) {
        acc[i + 1] = cur;
        return acc;
      }, {});
      this.$emit('changed', 'z_axis_values', obj);
    },
    onScoringCountChange: function onScoringCountChange(value) {
      this.update();
      this.$emit('changed', 'z_axis_scores', value);
    },
    onTitleChange: function onTitleChange(value) {
      this.$emit('changed', 'z_axis_name', value);
    }
  }
};