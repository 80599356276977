import i18n from '@/i18n';
export var getTranslate = {
  PreviewIncident: function PreviewIncident() {
    return {
      status: i18n.t('Status'),
      created: i18n.t('Created'),
      incidentOccurred: i18n.t('Incident occurred'),
      incidentArchived: i18n.t('Incident archived')
    };
  },
  PreviewInitiative: function PreviewInitiative() {
    return {
      status: i18n.t('Status'),
      description: i18n.t('Description'),
      target: i18n.t('Target'),
      deadline: i18n.t('Deadline'),
      start: i18n.t('Start')
    };
  },
  PreviewRiskExpanded: function PreviewRiskExpanded() {
    return {
      approved: i18n.t('Approved'),
      notApproved: i18n.t('Not approved'),
      description: i18n.t('Description'),
      status: i18n.t('Status'),
      linkedControls: i18n.t('Linked controls'),
      parentRisk: i18n.t('Parent risk'),
      underlyingRisks: i18n.t('Underlying risks'),
      riskAssessment: i18n.t('Risk assessment'),
      riskGap: i18n.t('Risk gap'),
      riskParameters: i18n.t('Risk parameters'),
      threat: i18n.t('Threat'),
      levelOfThreat: i18n.t('Level of threat'),
      vulnerability: i18n.t('Vulnerability'),
      tags: i18n.t('Tags'),
      notSet: i18n.t('Not set'),
      hideLinkedItems: i18n.t('Hide linked items'),
      showLinkedItems: i18n.t('Show linked items')
    };
  },
  PreviewRiskTiles: function PreviewRiskTiles() {
    return {
      description: i18n.t('Description'),
      approved: i18n.t('Approved'),
      notApproved: i18n.t('Not approved'),
      tags: i18n.t('Tags')
    };
  }
};