export default {
  name: 'MiniTreeLevelSelector',
  props: {
    modelValue: {
      type: [Number, String],
      default: 1
    },
    maxLevel: {
      type: [Number, String],
      default: 1
    }
  },
  emits: ['update:modelValue'],
  data: function data() {
    return {
      translate: {
        showLevels: this.$trans('Show levels')
      }
    };
  },
  computed: {
    generatedLevels: function generatedLevels() {
      return Array.from({
        length: this.maxLevel
      }, function (_, i) {
        return {
          label: i + 1,
          value: i + 1
        };
      });
    }
  }
};