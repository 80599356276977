import { getAllQuestionnaires } from '@/api/compliance/questionnaire';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { getTranslate } from '@/components/Pages/Admin/translate';
export default {
  name: 'QuestionsAdminWrapper',
  components: {
    RismaTitle: RismaTitle,
    LightTabItem: LightTabItem,
    RismaButton: RismaButton
  },
  props: {
    questionnaireId: {
      type: [String, Number],
      default: null,
      note: 'questionnaire Id'
    },
    questionnaireTypeId: {
      type: [String, Number],
      default: null,
      note: 'default value for questionnaireType'
    }
  },
  data: function data() {
    return {
      allQuestionnaires: [],
      translate: getTranslate['QuestionsAdmin']()
    };
  },
  computed: {
    questionnaire: function questionnaire() {
      var _this = this;
      return this.allQuestionnaires.find(function (questionnaire) {
        return +questionnaire.id === +_this.questionnaireId;
      });
    }
  },
  mounted: function mounted() {
    this.getQuestionnaires();
  },
  methods: {
    backToPrevPage: function backToPrevPage() {
      this.$router.push("/settings/compliancequestionnaires?questionnaireType=".concat(this.questionnaireTypeId));
    },
    getQuestionnaires: function getQuestionnaires() {
      var _this2 = this;
      return getAllQuestionnaires().then(function (_ref) {
        var list = _ref.list;
        return _this2.allQuestionnaires = list;
      });
    }
  }
};