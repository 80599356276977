function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { sortNumericByPropComplex } from '@/utils/sort';
export var CustomFieldValidationMixin = {
  methods: {
    validate: function validate(field) {
      var result = true;
      var errors = {};
      if (!field.label) {
        result = false;
        errors.label = true;
      }
      if ((field === null || field === void 0 ? void 0 : field.typeId) === CustomFieldTypes.SELECT) {
        if (!this.validSelectOptions(field === null || field === void 0 ? void 0 : field.options)) {
          result = false;
          this.selectOptionsIsValid = false;
          errors.options = true;
        }
      } else if ((field === null || field === void 0 ? void 0 : field.typeId) === CustomFieldTypes.ROW_GROUP) {
        if (!field.children || !field.children.length) {
          result = false;
          errors.emptyField = true;
        }
      }
      if ((field === null || field === void 0 ? void 0 : field.typeId) === CustomFieldTypes.NUMERICAL) {
        if (!field.unit.trim()) {
          result = false;
          errors.emptyUnitField = true;
        }
      }
      if (!(field !== null && field !== void 0 && field.typeId)) {
        result = false;
        errors.typeId = true;
      }
      if (result) {
        field.errors = {};
      } else {
        field.errors = errors;
      }
      return result;
    },
    validSelectOptions: function validSelectOptions(options) {
      var result = true;
      if (!(options !== null && options !== void 0 && options.length)) {
        result = false;
      } else {
        options.map(function (item) {
          if (!item.value.trim()) {
            item.invalid = true;
            result = false;
          } else {
            delete item.invalid;
          }
        });
      }
      return result;
    },
    prepeareDataForSaving: function prepeareDataForSaving(field) {
      var data = {
        label: field.label,
        explanatoryText: field.explanatoryText,
        module: field.module
      };
      if (field.id) {
        data.fieldId = field.id;
      } else {
        data.typeId = field.typeId;
      }
      if (field.typeId === CustomFieldTypes.SELECT) {
        data.optionObjects = field.options.map(function (item) {
          return {
            value: item.value,
            id: item.id
          };
        });
      }
      if (field.typeId === CustomFieldTypes.ROW_GROUP) {
        data.children = this.prepareIds(field.children);
      }
      if (field.typeId === CustomFieldTypes.NUMERICAL) {
        data.unit = field.unit;
      }
      return data;
    },
    prepareIds: function prepareIds(children) {
      var data = _toConsumableArray(children),
        result = {};
      sortNumericByPropComplex(data, 'order');

      // New format -> children: {1: 91, 2: 92}
      data = data.map(function (item) {
        result[item.order] = item.id;
      });
      return result;
    }
  }
};