import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "w-60vw"
};
var _hoisted_2 = {
  class: "mb-4"
};
var _hoisted_3 = {
  class: "m-4"
};
var _hoisted_4 = {
  class: "mb-4"
};
var _hoisted_5 = {
  class: "w-full flex justify-around mb-4"
};
var _hoisted_6 = {
  class: "w-1/3"
};
var _hoisted_7 = {
  class: "w-1/3"
};
var _hoisted_8 = {
  key: 0,
  class: "col-12 text-center info-message"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.loginReport
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_single_select, {
    modelValue: $data.selectedUser,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.selectedUser = $event;
    }),
    title: $data.translate.filterByUsername,
    placeholder: $data.translate.chooseUser,
    options: $options.getOptionsUsers,
    "with-search": true,
    "with-reset": true
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options"])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.filterByDateRange,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_date_range_picker, {
    class: "w-full",
    "start-date": $data.dateRange.startDate,
    "end-date": $data.dateRange.endDate,
    "date-format": $data.DateFormats.DATE_TIME_FORMAT_FULL,
    type: "datetime",
    onChanged: $options.changedDates
  }, null, 8 /* PROPS */, ["start-date", "end-date", "date-format", "onChanged"])])]), _createVNode(_component_risma_button, {
    text: $data.translate.createReport,
    type: "save",
    class: "ml-auto",
    onClick: $options.createReport
  }, null, 8 /* PROPS */, ["text", "onClick"])])]), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0,
    class: "load"
  })) : _createCommentVNode("v-if", true), $data.showReport ? (_openBlock(), _createBlock(_component_main_well, {
    key: 1,
    class: _normalizeClass({
      'pb-5': !$data.showNothingToShowMessage
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_datatable, {
        dataset: _ctx.datasetSlice,
        "dataset-total": $data.dataset,
        "dataset-total-sliced": _ctx.datasetSliceTotal,
        order: $data.orderNew,
        "wrapper-height-sticky": 500,
        onPageChange: _ctx.onPageChange,
        onOnStateChanged: _ctx.onStateChanged
      }, null, 8 /* PROPS */, ["dataset", "dataset-total", "dataset-total-sliced", "order", "onPageChange", "onOnStateChanged"]), $data.showNothingToShowMessage ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_risma_title, {
        title: $data.translate.nothingToShow
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.translate.enterYourSearchCriteriaAboveToViewSearchRe), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"])) : _createCommentVNode("v-if", true)]);
}