import i18n from '@/i18n';
export var getTranslate = {
  BowTie: function BowTie() {
    return {
      riskCauses: i18n.t('Risk causes'),
      addCause: i18n.t('Add cause'),
      incidents: i18n.t('Incidents'),
      addLink: i18n.t('Add link'),
      riskConsequences: i18n.t('Risk consequences'),
      addConsequences: i18n.t('Add consequences')
    };
  }
};