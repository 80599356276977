import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-80787476"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "traffic-light-tool-tip"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  key: 0,
  class: "ml-2 whitespace-nowrap cursor-pointer hover:underline"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_tool_tip = _resolveComponent("tool-tip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_tool_tip, {
    hidden: $props.hidden
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.trafficLights, function (trafficLight) {
        return _openBlock(), _createElementBlock("div", {
          key: trafficLight.number,
          class: "flex",
          onClick: function onClick($event) {
            return $options.emitSelected(trafficLight);
          }
        }, [_createVNode(_component_traffic_light, {
          color: trafficLight.color,
          "show-ban": $props.trafficLightsData.showBan
        }, null, 8 /* PROPS */, ["color", "show-ban"]), $props.trafficLightsData.showLabels ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$trans(trafficLight.label)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_2);
      }), 128 /* KEYED_FRAGMENT */))];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["hidden"])]);
}