import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-103be5fe"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "initiatives-breadcrumbs"
};
var _hoisted_2 = {
  key: 0,
  class: "overlying-initiatives"
};
var _hoisted_3 = {
  class: "initiatives-group-title"
};
var _hoisted_4 = {
  key: 1
};
var _hoisted_5 = {
  key: 1,
  class: "underlying-initiatives"
};
var _hoisted_6 = {
  class: "initiatives-group-title"
};
var _hoisted_7 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.reversedVisibleBreadcrumb.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString($data.translate.overlying) + " " + _toDisplayString($data.translate.actions) + ": ", 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.reversedVisibleBreadcrumb, function (item, index) {
    return _openBlock(), _createElementBlock("span", {
      key: index
    }, [_createVNode(_component_router_link, {
      to: item.url,
      class: "underlying-link hover:underline"
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(" #" + _toDisplayString(item.ipath) + ": " + _toDisplayString(item.title), 1 /* TEXT */)];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]), item.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      icon: "check",
      class: "stroke-2 inline-block mr-2"
    })) : _createCommentVNode("v-if", true), index < $options.reversedVisibleBreadcrumb.length - 1 ? (_openBlock(), _createElementBlock("span", _hoisted_4, ">")) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), $options.initiativeChildrenLength ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("p", _hoisted_6, _toDisplayString($data.translate.underlying) + " " + _toDisplayString($data.translate.actions) + ": ", 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.initiative.children, function (item, index) {
    return _openBlock(), _createElementBlock("span", {
      key: index
    }, [_createVNode(_component_router_link, {
      to: item.url,
      class: "underlying-link hover:underline"
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(" #" + _toDisplayString(item.ipath) + ": " + _toDisplayString(item.title), 1 /* TEXT */)];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]), item.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      icon: "check",
      class: "stroke-2 inline-block mr-2"
    })) : _createCommentVNode("v-if", true), index < $options.initiativeChildrenLength - 1 ? (_openBlock(), _createElementBlock("span", _hoisted_7, "|")) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)]);
}