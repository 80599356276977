import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-079d37b3"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "flex justify-around items-start mb-8"
};
var _hoisted_2 = {
  class: "mb-24"
};
var _hoisted_3 = {
  class: "flex absolute bottom-2rem right-0.8rem"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: "Wizard flow",
    "show-buttons": false,
    "dismiss-on-click-outside": false,
    onDismiss: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        onDismiss: _cache[0] || (_cache[0] = function ($event) {
          return $data.errorMessage = '';
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.errorMessage), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: "wizard_flow_step_".concat(index),
          class: _normalizeClass(["text-center flex flex-col justify-center items-center relative", [{
            'arrow-left': index > 0
          }, {
            'arrow-right': index < $props.items.length - 1
          }, {
            'previous-complete': $data.activeIndex >= index
          }, {
            'complete': $data.activeIndex > index
          }]]),
          style: _normalizeStyle({
            width: "calc(100% / ".concat($props.items.length, ")")
          })
        }, [_createVNode(_component_feather_icon, {
          icon: item.icon,
          width: "75",
          height: "75",
          class: _normalizeClass(["p-4 rounded-full mb-4", $data.activeIndex === index ? 'border-blue-350 text-blue-350 border-2' : $data.activeIndex > index ? 'border border-green-350 text-green-350' : 'border border-gray-350 text-gray-350'])
        }, null, 8 /* PROPS */, ["icon", "class"]), _createVNode(_component_risma_title, {
          title: item.headline,
          class: _normalizeClass({
            'font-bold': $data.activeIndex === index
          })
        }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_title, {
          title: item.headline2,
          type: "small",
          class: _normalizeClass(["whitespace-pre-wrap", {
            'font-bold': $data.activeIndex === index
          }])
        }, null, 8 /* PROPS */, ["title", "class"])], 6 /* CLASS, STYLE */);
      }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
        type: "big",
        class: "mb-4",
        title: $props.items[$data.activeIndex].headline
      }, null, 8 /* PROPS */, ["title"]), $props.items[$data.activeIndex].component ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.items[$data.activeIndex].component), {
        key: 0,
        "stakeholder-options": $props.stakeholderOptions,
        "current-data-subject": $props.currentDataSubject,
        "onUpdate:dataSubject": _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('update:dataSubject', $event);
        })
      }, null, 8 /* PROPS */, ["stakeholder-options", "current-data-subject"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_3, [$data.activeIndex !== 0 ? (_openBlock(), _createBlock(_component_risma_button, {
        key: 0,
        text: $data.translate.previous,
        type: "save",
        class: "-ml-0.3rem",
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return $data.activeIndex--;
        })
      }, null, 8 /* PROPS */, ["text"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_button, {
        text: $data.activeIndex === $props.items.length - 1 ? $data.translate.send : $data.translate.next,
        type: "save",
        onClick: $options.handleNextButton
      }, null, 8 /* PROPS */, ["text", "onClick"])])])];
    }),
    _: 1 /* STABLE */
  });
}