export var loginFailTypes = {
  LOGIN_FAIL_UNDEFINED: 0,
  LOGIN_FAIL_WRONG_PASSWORD: 1,
  LOGIN_FAIL_USERNAME_LIMIT_EXCEEDED: 2,
  LOGIN_FAIL_IP_LIMIT_EXCEEDED: 4,
  LOGIN_FAIL_INVALID_USERNAME: 8,
  LOGIN_FAIL_INVALID_TOKEN: 16,
  LOGIN_FAIL_PASSWORD_EXPIRED: 32,
  LOGIN_FAIL_TMP_PASSWORD_EXPIRED: 64,
  LOGIN_FAIL_CONCURRENT_LOGINS_NOT_ALLOWED: 128,
  LOGIN_FAIL_MISSING_MFA_TOKEN: 256,
  LOGIN_FAIL_MFA_TOKEN_INVALID: 512
};