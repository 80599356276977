// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/flags-big.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".flag-icon {\n  width: 40px;\n  height: 30px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 0; }\n\n.flag-icon-gb {\n  background-position: -80px 0px; }\n\n.flag-icon-pl {\n  background-position: -80px -30px; }\n\n.flag-icon-no {\n  background-position: -80px -60px; }\n\n.flag-icon-it {\n  background-position: -40px -60px; }\n\n.flag-icon-fr {\n  background-position: -40px -30px; }\n\n.flag-icon-de {\n  background-position: -40px 0px; }\n\n.flag-icon-se {\n  background-position: 0 -90px; }\n\n.flag-icon-es {\n  background-position: 0 -60px; }\n\n.flag-icon-fi {\n  background-position: 0 -30px; }\n\n.flag-icon-dk {\n  background-position: 0 0; }\n", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
