import Modal from '@/components/Molecules/Modal/Modal';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
export default {
  name: '',
  components: {
    Modal: Modal,
    RismaButton: RismaButton
  },
  data: function data() {
    return {
      isModalShow: false
    };
  },
  methods: {
    toggleModal: function toggleModal() {
      this.isModalShow = !this.isModalShow;
    }
  }
};