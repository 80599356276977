import i18n from '@/i18n';
export var getTranslate = {
  InformationAssetCreationModal: function InformationAssetCreationModal() {
    return {
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      parent: i18n.t('Parent'),
      chooseParentOptional: i18n.t('Choose parent (optional)'),
      new: i18n.t('New'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty')
    };
  }
};