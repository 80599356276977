function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import * as RiskApi from '@/api/risk/risk';
import { getCompanies } from '@/api/admin/companies';
import VerticalMenu from '@/components/Molecules/VerticalMenu/VerticalMenu';
import { userHasProjectAccess } from '@/utils/access';
import { userSettingsNav } from './userSettingsNav';
import { SettingsNav } from '@/constants/menu/navigations';
import { UserLevels } from '@/constants/UserLevels';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { VAT_STATUSES } from '@/constants/companies/vatStatuses';
var ENTERPRISE_RISK_MANAGEMENT_TYPE = 1;
export default {
  name: 'UserSettings',
  introduction: 'Wrapper for user settings pages',
  description: 'Wrapper with menu for user settings pages: profile, notifications',
  token: '<user-settings />',
  components: {
    VerticalMenu: VerticalMenu
  },
  emits: ['updateHeader'],
  data: function data() {
    return {
      allNavItems: [],
      riskProjects: [],
      openedGroups: [],
      translate: {
        period: this.$trans('period')
      },
      bankruptCount: 0
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    servername: function servername() {
      return this.getSettingValue('servername');
    },
    settings: function settings() {
      return {
        'app.module_execution_enabled': this.getSettingValue('app.module_execution_enabled'),
        'app.module_controls_enabled': this.getSettingValue('app.module_controls_enabled'),
        'app.module_risk_enabled': this.getSettingValue('app.module_risk_enabled'),
        'app.module_compliance_enabled': this.getSettingValue('app.module_compliance_enabled'),
        'app.module_risks2_enabled': this.getSettingValue('app.module_risks2_enabled'),
        'app.module_isms_enabled': this.getSettingValue('app.module_isms_enabled'),
        'app.module_contracts_enabled': this.getSettingValue('app.module_contracts_enabled'),
        'app.module_incidents_enabled': this.getSettingValue('app.module_incidents_enabled'),
        'feature.admin_erm_period': this.getSettingValue('feature.admin_erm_period'),
        'servername': this.getSettingValue('servername'),
        'feature.app_settings_enabled': this.getSettingValue('feature.app_settings_enabled'),
        'feature.disable_information_assets': this.getSettingValue('feature.disable_information_assets')
      };
    },
    navItemLists: function navItemLists() {
      var _this = this;
      return this.allNavItems.filter(function (item) {
        return item.rights === true;
      }).map(function (item) {
        var _item$groupItems;
        if (!((_item$groupItems = item.groupItems) !== null && _item$groupItems !== void 0 && _item$groupItems.length)) return item;
        var groupItems = item.groupItems.filter(function (groupItem) {
          return !Object.keys(groupItem).includes('rights') || groupItem.rights === true;
        }).map(function (groupItem) {
          if (groupItem.id === 'bankruptcy-list') {
            return _objectSpread(_objectSpread({}, groupItem), {}, {
              title: "".concat(groupItem.title, " (").concat(_this.bankruptCount, ")")
            });
          }
          return groupItem;
        });
        return _objectSpread(_objectSpread({}, item), {}, {
          groupItems: groupItems
        });
      });
    },
    filteredRiskProjects: function filteredRiskProjects() {
      return this.riskProjects.filter(function (item) {
        return item.type !== ENTERPRISE_RISK_MANAGEMENT_TYPE;
      });
    },
    ermRiskProject: function ermRiskProject() {
      return this.riskProjects.find(function (item) {
        return item.type === ENTERPRISE_RISK_MANAGEMENT_TYPE;
      });
    }
  }),
  watch: {
    '$route': function $route() {
      this.defineOpenedGroup();
    }
  },
  mounted: function mounted() {
    this.init();
    this.getBankruptcyCount();
  },
  methods: {
    init: function init() {
      var _this2 = this;
      this.getRiskProjects().then(function () {
        _this2.prepareNavigation();
      });
    },
    getRiskProjects: function getRiskProjects() {
      var _this3 = this;
      return RiskApi.getAllRiskProjects().then(function (_ref) {
        var list = _ref.list;
        return _this3.riskProjects = list;
      }).catch(function (e) {
        throw e;
      });
    },
    prepareNavigation: function prepareNavigation() {
      this.getAllNavItems();
      this.defineOpenedGroup();
    },
    getAllNavItems: function getAllNavItems() {
      var riskProjectsItems = this.getRiskProjectsItems();
      this.allNavItems = userSettingsNav(this.currentUser, this.settings, riskProjectsItems);
    },
    defineOpenedGroup: function defineOpenedGroup() {
      var path = this.$route.path;
      if (path[path.length - 1] === '/') {
        path = path.slice(0, -1);
      }
      var group = this.allNavItems.find(function (group) {
        return group.groupItems.find(function (item) {
          return item.path === path;
        });
      });
      if (group) {
        this.openedGroups = [group.groupTitle];
      }
    },
    getRiskProjectsItems: function getRiskProjectsItems() {
      var _this4 = this;
      return [{
        path: this.settings['feature.admin_erm_period'] === 1 ? '/settings/ermadmin' : SettingsNav.riskSettingsERM.path,
        title: this.ermRiskProject ? "".concat(this.ermRiskProject.title, " ").concat(this.translate.period) : '',
        rights: this.currentUser.is_admin && this.settings['app.module_risk_enabled'] === 1
      }].concat(_toConsumableArray(this.filteredRiskProjects.filter(function (item) {
        return item.enabled;
      }).map(function (project) {
        return {
          title: "".concat(project.title, " ").concat(_this4.translate.period),
          path: "/settings/riskproject/".concat(project.id),
          rights: userHasProjectAccess(_this4.currentUser, project.id, ObjectTypes.RISK_PROJECT, UserLevels.SUPER)
        };
      })));
    },
    updateBankruptcyCount: function updateBankruptcyCount(difference) {
      if (difference) {
        this.bankruptCount += difference;
        return;
      }
      this.getBankruptcyCount();
    },
    getBankruptcyCount: function getBankruptcyCount() {
      var _this5 = this;
      var query = "filters[vat_status_as_text_translated][]=".concat(VAT_STATUSES.BANKRUPT.value, "&filters[vat_status_as_text_translated][]=").concat(VAT_STATUSES.UNDER_BANKRUPTCY.value, "&format=count");
      getCompanies(query).then(function (count) {
        return _this5.bankruptCount = count;
      });
    }
  }
};