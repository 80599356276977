import i18n from '@/i18n';
export var getTranslate = {
  AuditOverview: function AuditOverview() {
    return {
      approve: i18n.t('Approve'),
      startNewAudit: i18n.t('Start new audit'),
      auditList: i18n.t('Audit list'),
      actions: i18n.t('Actions'),
      extendDeadline: i18n.t('Extend deadline'),
      askForMoreInfo: i18n.t('Ask for more info'),
      nbYouHaveAskedForMoreInfoOnThisQuestionn: i18n.t('NB! You have asked for more info on this questionnaire!'),
      youHaveNotCreatedAnyAuditsYetClickTheBut: i18n.t('You have not created any audits yet. Click the button to get started!'),
      areYouSureYouWantToDeleteThisAudit: i18n.t('Are you sure you want to delete this audit?'),
      theRecipientOfTheAuditWillReceiveAnEmail: i18n.t('The recipient of the audit will receive an e-mail notification that the audit is deleted and will no longer be able to answer the questionnaire.'),
      sent: i18n.t('Sent'),
      questionnaireOpened: i18n.t('Questionnaire opened'),
      questionnaireAnswered: i18n.t('Questionnaire answered'),
      deadlineExceeded: i18n.t('Deadline exceeded'),
      recipient: i18n.t('Recipient'),
      status: i18n.t('Status'),
      extendDeadlineForAudit: i18n.t('Extend deadline for audit'),
      hereYouCanExtendTheDeadlineForTheAuditAnd: i18n.t('Here you can extend the deadline for the audit and send a new reminder regarding the questionnaire'),
      resubmitAuditForMoreInfo: i18n.t('Re-submit audit for more info'),
      hereYouCanAskForMoreInfoRegardingTheAudit: i18n.t('Here you can ask for more info regarding the audit and re-submit the questionnaire for answering'),
      awaitingQuestionnaireToBeOpened: i18n.t('Awaiting questionnaire to be opened'),
      awaitingQuestionnaireAnswer: i18n.t('Awaiting questionnaire answer'),
      questionnaireAnswerNotReceived: i18n.t('Questionnaire answer not received'),
      to: i18n.t('To'),
      companyName: i18n.t('Company name'),
      deadline: i18n.t('Deadline'),
      anErrorOccurred: i18n.t('An error occurred'),
      nothingToShow: i18n.t('Nothing to show')
    };
  },
  DpmActionModal: function DpmActionModal() {
    return {
      send: i18n.t('Send'),
      dismiss: i18n.t('Dismiss'),
      chooseNewDeadline: i18n.t('Choose new deadline'),
      message: i18n.t('Message'),
      pleaseFillOutThis: i18n.t('Please fill out this')
    };
  }
};