import { Http } from '../index';
import { handleRequestResponse, checkHttpStatus, throwError } from '../httpUtils';
export function createFlow(projectId, nodeId, data) {
  return Http.post("compliance/projects/".concat(projectId, "/nodes/").concat(nodeId, "/dataflow/flow"), data).then(function (response) {
    return checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return throwError(err);
  });
}
export function getAllFlows(projectId, nodeId) {
  return handleRequestResponse(Http.get("compliance/projects/".concat(projectId, "/nodes/").concat(nodeId, "/dataflow/flow")));
}
export function getAllOptions(projectId, nodeId) {
  return handleRequestResponse(Http.get("compliance/projects/".concat(projectId, "/nodes/").concat(nodeId, "/dataflow/options")));
}
export function deleteFlow(projectId, nodeId, flowId) {
  return Http.delete("compliance/projects/".concat(projectId, "/nodes/").concat(nodeId, "/dataflow/flow/").concat(flowId)).then(function (response) {
    return checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return throwError(err);
  });
}
export function updateFlow(projectId, nodeId, flowId, data) {
  return Http.patch("compliance/projects/".concat(projectId, "/nodes/").concat(nodeId, "/dataflow/flow/").concat(flowId), data).then(function (response) {
    return checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return throwError(err);
  });
}