function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { TrendOptions } from '@/constants/TrendOptions';
export var ExtendedViewCustomFieldsMixin = {
  data: function data() {
    return {};
  },
  computed: {
    customFields: function customFields() {
      var customFields = [].concat.apply([], this.informationAssets.map(function (ia) {
        return ia.customFields;
      }));
      return _toConsumableArray(new Map(customFields.map(function (item) {
        return [item.id, item];
      })).values());
    },
    customFieldsColumns: function customFieldsColumns() {
      var _this = this;
      var result = {};
      this.customFields.forEach(function (cf) {
        result[_this.getColumnLabelName(cf.id, cf.label)] = cf.label;
        if (cf.typeId === CustomFieldTypes.NUMERICAL) {
          var unitLabel = _this.getColumnLabelName(cf.id, cf.label + _this.customUnitPrefix);
          result[unitLabel] = cf.label + _this.customUnitPrefix;
        }
      });
      return result;
    }
  },
  methods: {
    getColumnLabelName: function getColumnLabelName(id, label) {
      return "cf-".concat(id, "-").concat(label.toLowerCase().replace(/[^a-zA-Z0-9]/g, ''));
    },
    getCustomFieldsData: function getCustomFieldsData(element) {
      var _this2 = this;
      var result = {};
      this.customFields.forEach(function (cf) {
        var customFieldLabel = _this2.getColumnLabelName(cf.id, cf.label);
        _this2.prepareCustomFieldValue(result, element.customFieldValues, cf, customFieldLabel);
      });
      return result;
    },
    getValueFormatted: function getValueFormatted(value, cf) {
      var trendOption;
      switch (cf.fieldtype) {
        case CustomFieldTypes.SELECT:
          for (var key in cf.options) {
            if (cf.options[key].id === value) return {
              field: cf.options[key].value
            };
          }
          break;
        case CustomFieldTypes.TRAFFIC_LIGHT:
          return this.getTrafficLightField(value);
        case CustomFieldTypes.NUMERICAL:
          return {
            field: "".concat(value, " ").concat(cf.unit)
          };
        case CustomFieldTypes.TREND:
          trendOption = TrendOptions().find(function (option) {
            return option.value === value;
          });
          return {
            field: trendOption ? trendOption.label : ''
          };
        case CustomFieldTypes.TEXT_LINE:
        case CustomFieldTypes.TEXT_FIELD:
        case CustomFieldTypes.DATE_TIME:
        default:
          return {
            field: value
          };
      }
    }
  }
};