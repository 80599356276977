export function mapToCommaListWithLinks(arr, url) {
  var field = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'name';
  var linkId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'id';
  var isRouterLink = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  if (!arr || !arr.length) return '';
  return arr.map(function (obj) {
    var linkUrl = obj[url] || "".concat(url).concat(obj[linkId]);
    return isRouterLink ? "<router-link to=\"".concat(linkUrl, "\">").concat(obj[field], "</router-link>") : "<a href=\"".concat(linkUrl, "\">").concat(obj[field], "</a>");
  }).join(', ');
}
export function mapToCommaList(arr, field) {
  return mapToSeparatorList(arr, field);
}
export function mapToSeparatorList(arr) {
  var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  var separator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ', ';
  if (!(arr !== null && arr !== void 0 && arr.length)) return '';
  return arr.map(function (obj) {
    return obj[field];
  }).join(separator);
}