function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import ListOptions from '@/components/Atoms/ListOptions/ListOptions';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import RiskFilters from '@/components/Molecules/Risk/RiskFilters';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import RiskGroupSortBy from '@/components/Molecules/Risk/RiskGroupSortBy';
import CatalogueHeader from '@/components/Molecules/CatalogueHeader/CatalogueHeader';
import PreviewListTile from '@/components/Molecules/PreviewList/PreviewListTile';
import CompactCard from '@/components/Molecules/Cards/CompactCard';
import RiskCreationModal from '@/components/Molecules/Modal/RiskCreationModal';
import ScoringInfoBox from '@/components/Organisms/Risk/ScoringInfoBox';
import PreviewListExpanded from '@/components/Molecules/PreviewList/PreviewListExpanded';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import { RiskListMixin } from '@/components/Pages/Risks/RiskListMixin';
import { STANDARD_VIEWS } from '@/constants/ViewTypes';
import { viewTypes } from '@/constants/ListViewType';
import { MODULES } from '@/constants/modules';
import { UserLevels } from '@/constants/UserLevels';
var MODULE_NAME = MODULES.RISK;
import { getCurrentScoring as _getCurrentScoring } from '@/api/risk/scoring';
import { getDataControls as _getDataControls } from '@/api/controls/controls';
import { getRiskTypes as _getRiskTypes, getRisks } from '@/api/risk/risk';
import { CommonAssetsMixin } from '@/mixins/CommonAssetsMixin';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import { sortBy } from '@/utils/risks/sortRisks';
import { groupBy } from '@/utils/risks/groupRisks';
import { generateKey } from '@/utils/Utils';
import { isLockedRiskUser as _isLockedRiskUser } from '@/utils/access';
import { filterRisksByOrg, filterRisksByParentRisk, filterRisksByTags, searchByTitleAndId } from '@/utils/risks/filterRisks';
import { checkERMByProjectId } from '@/utils/risks/check';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
var OPTION_DEFAULT = {
  search: '',
  filterByTags: [],
  filterByParentRisk: null,
  filterByOrganisations: [],
  sortBy: null,
  groupBy: null
};
export default {
  name: 'RiskOverview',
  introduction: '',
  description: '',
  token: '<risk-overview />',
  components: {
    RismaTitle: RismaTitle,
    ListOptions: ListOptions,
    PreviewList: PreviewList,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RiskGroupSortBy: RiskGroupSortBy,
    RiskFilters: RiskFilters,
    CatalogueHeader: CatalogueHeader,
    RismaButton: RismaButton,
    PreviewListTile: PreviewListTile,
    CompactCard: CompactCard,
    ScoringInfoBox: ScoringInfoBox,
    NotificationPopUp: NotificationPopUp,
    PreviewListExpanded: PreviewListExpanded,
    RiskCreationModal: RiskCreationModal,
    RismaTable: RismaTable
  },
  mixins: [CommonAssetsMixin, RiskListMixin, NotificationPopUpMixin],
  setup: function setup() {
    return {
      viewSelected: getLocalStorageComputed('risk_overview_view_storage', viewTypes.EXPANDED),
      isOverviewOptionsExpanded: getLocalStorageComputed('risk_overview_options', false, true)
    };
  },
  data: function data() {
    return {
      isLoaded: false,
      risksLoadedOnce: false,
      tableGeneratedKey: generateKey(),
      options: Object.assign({}, OPTION_DEFAULT),
      sortBy: null,
      groupBy: null,
      unformattedRisks: [],
      output: [],
      STANDARD_VIEWS: STANDARD_VIEWS,
      viewTypes: viewTypes,
      scoring: {},
      riskTypes: [],
      MODULE_NAME: MODULE_NAME,
      showRiskCreationModal: false,
      translate: getTranslate['RiskOverview']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser', 'isAdmin', 'riskAccessLevelByProjectId'])), {}, {
    superAccessLevel: function superAccessLevel() {
      return this.isERM && this.currentUser.level_risk >= UserLevels.SUPER || this.riskAccessLevelByProjectId(this.projectId) >= UserLevels.SUPER;
    },
    isERM: function isERM() {
      return checkERMByProjectId(this.projectId, this.currentUser);
    },
    isLockedRiskUser: function isLockedRiskUser() {
      return _isLockedRiskUser(this.currentUser);
    },
    showScoringInfoBox: function showScoringInfoBox() {
      return this.getSettingValue('feature.scoring_info_box') && this.isERM && this.isLoaded && (this.currentUser.level_risk >= UserLevels.SUPER || this.currentUser.level_admin);
    },
    parentRisks: function parentRisks() {
      var parentRisks = this.risks.filter(function (risk) {
        return risk.children.length;
      });
      return parentRisks.map(function (risk) {
        return {
          value: risk.id,
          label: "".concat(risk.rno, ". ").concat(risk.title)
        };
      });
    },
    localStorageName: function localStorageName() {
      return "risks_overview_project".concat(this.projectId);
    },
    tableStateKey: function tableStateKey() {
      return "risks_overview_table_state_".concat(this.projectId);
    },
    scoringTitle: function scoringTitle() {
      return this.isERM && this.scoring ? this.scoring.version : '';
    },
    showRismaTable: function showRismaTable() {
      return this.viewSelected === viewTypes.COMPACT && !this.isERM;
    }
  }),
  watch: {
    options: {
      deep: true,
      handler: function handler() {
        localStorage.setItem(this.localStorageName, JSON.stringify(this.options));
        this.applyOptions();
      }
    },
    viewSelected: function viewSelected(newView) {
      if (newView === viewTypes.COMPACT && !this.isERM) {
        return;
      }
      if (!this.risksLoadedOnce) {
        this.loadRisks();
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    var promises = [this.getInitiatives(), this.getRiskProjects()];
    if (this.isERM) {
      promises.push(this.getCurrentScoring());
      promises.push(this.getRiskTypes());
    }
    Promise.all(promises).then(function () {
      return _this.showRismaTable ? Promise.resolve() : _this.loadRisks();
    }).then(function () {
      _this.isLoaded = true;
    }).catch(function (error) {
      console.log('Error on mounted() load:', error); // eslint-disable-line
    });
  },

  methods: {
    getCurrentScoring: function getCurrentScoring() {
      var _this2 = this;
      return _getCurrentScoring().then(function (scoring) {
        _this2.scoring = scoring;
      });
    },
    getDataControls: function getDataControls() {
      var _this3 = this;
      return _getDataControls().then(function (_ref) {
        var list = _ref.list;
        _this3.controls = list;
      });
    },
    getRiskTypes: function getRiskTypes() {
      var _this4 = this;
      return _getRiskTypes().then(function (_ref2) {
        var list = _ref2.list;
        _this4.riskTypes = list;
      });
    },
    getRisksBE: function getRisksBE(params) {
      var fullParams = params ? params + '&' : '?';
      fullParams += 'filters=includeSubrisks';
      return getRisks(this.projectId, this.periodId, fullParams);
    },
    getUnformattedRisks: function getUnformattedRisks() {
      var _this5 = this;
      return getRisks(this.projectId, this.periodId, 'filters=includeSubrisks').then(function (_ref3) {
        var list = _ref3.list;
        _this5.unformattedRisks = list;
      });
    },
    loadRisks: function loadRisks() {
      var _this6 = this;
      this.isLoaded = false;
      return Promise.all([this.getDataControls(), this.getUnformattedRisks()]).then(function () {
        _this6.risks = _this6.formatRisks(_this6.unformattedRisks, _this6.threats, _this6.levelOfThreats, _this6.vulnerabilities, _this6.probabilities, _this6.controls, _this6.initiatives);
        _this6.risksBackup = _toConsumableArray(_this6.risks);
        _this6.initStatesFromCookie();
        _this6.applyOptions();
        _this6.isLoaded = true;
        _this6.risksLoadedOnce = true;
      });
    },
    initStatesFromCookie: function initStatesFromCookie() {
      this.options = _objectSpread(_objectSpread({}, this.options), JSON.parse(localStorage.getItem(this.localStorageName) || '{}'));
    },
    toggleViews: function toggleViews(toggleState) {
      var _this7 = this;
      this.output.forEach(function (section) {
        _this7.$refs[section.title][0].changeShowState(toggleState);
      });
    },
    toggleRiskCreationModal: function toggleRiskCreationModal() {
      this.showRiskCreationModal = !this.showRiskCreationModal;
    },
    applyOptions: function applyOptions() {
      var output = [];
      var risks = _toConsumableArray(this.risksBackup);
      risks = filterRisksByOrg(risks, this.options.filterByOrganisations);
      risks = filterRisksByParentRisk(risks, this.options.filterByParentRisk);
      risks = filterRisksByTags(risks, this.options.filterByTags);
      risks = searchByTitleAndId(risks, this.options.search);
      output = groupBy(risks, this.options.groupBy, {
        users: this.activeUsers,
        organisations: this.organisations,
        threats: this.threats,
        vulnerabilities: this.vulnerabilities
      }, this.$trans);
      output = sortBy(output, this.options.sortBy, {
        users: this.activeUsers
      });
      this.output = output;
    },
    resetOptions: function resetOptions() {
      this.options = Object.assign({}, OPTION_DEFAULT);
    },
    handleFilterByArray: function handleFilterByArray(entity, selectedEntity, labelValue) {
      var entitySimplify = selectedEntity.map(function (item) {
        return _defineProperty({
          id: item.id
        }, labelValue, item[labelValue]);
      });
      this.options[entity] = entitySimplify ? [].concat(entitySimplify) : [];
    },
    updateFromPreviewMode: function updateFromPreviewMode(updatedRisk, index) {
      var updatedRiskIndex = this.output[index].list.findIndex(function (a) {
        return a.id == updatedRisk.id;
      });
      if (updatedRiskIndex === -1) return;
      this.output[index].list[updatedRiskIndex] = _objectSpread(_objectSpread({}, this.output[index].list[updatedRiskIndex]), updatedRisk);
    },
    deleteRiskFromList: function deleteRiskFromList(risk) {
      for (var i = 0; i < this.output.length; i++) {
        var listLength = this.output[i].list.length;
        this.output[i].list = this.output[i].list.filter(function (riskItem) {
          return riskItem.id !== risk.id;
        });
        if (listLength > this.output[i].list.length) {
          break;
        }
      }
      this.output = _toConsumableArray(this.output);
    },
    addRiskToList: function addRiskToList(risk) {
      var formattedRisk = this.formatRisk(risk, this.threats, this.levelOfThreats, this.vulnerabilities, this.probabilities, this.controls, this.initiatives);
      this.risks.push(formattedRisk);
      this.risksBackup = _toConsumableArray(this.risks);
      this.applyOptions();
    },
    handleCreateRisk: function handleCreateRisk(risk) {
      var _risk$responsibleUser, _risk$userIds;
      this.toggleRiskCreationModal();
      var url = null;
      var userId = this.currentUser.id;
      var isUserResponsible = (risk === null || risk === void 0 || (_risk$responsibleUser = risk.responsibleUserIds) === null || _risk$responsibleUser === void 0 ? void 0 : _risk$responsibleUser.includes(userId)) || (risk === null || risk === void 0 || (_risk$userIds = risk.userIds) === null || _risk$userIds === void 0 ? void 0 : _risk$userIds.responsible) === userId;
      var isUserHasAccess = this.isERM || this.superAccessLevel || this.isAdmin || isUserResponsible;
      if (isUserHasAccess) {
        url = risk.url || "/risk2/projects/".concat(risk.projectId, "/periods/").concat(risk.projectPeriodId, "/risk/").concat(risk.rno, "/description");
        this.addRiskToList(risk);
      }
      this.handleInstantNotification({
        title: risk.title,
        url: url
      });
    }
  }
};