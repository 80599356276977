import { defineStore } from 'pinia';
import { getOrganisations } from '@/api/risma/data';
import { sortAlphabetic } from '@/utils/sort';
import cloneDeep from 'lodash/cloneDeep';
export var useOrganisationsStore = defineStore('organisationsStore', {
  state: function state() {
    return {
      organisations: []
    };
  },
  getters: {
    organisationsSortedByVisibleName: function organisationsSortedByVisibleName() {
      return sortAlphabetic(this.organisations, 'visible_name');
    }
  },
  actions: {
    load: function load() {
      var _this = this;
      return getOrganisations().then(function (_ref) {
        var list = _ref.list;
        _this.organisations = list;
      });
    },
    add: function add(organisation) {
      this.organisations.push(organisation);
    },
    update: function update(organisation) {
      this.organisations = this.organisations.map(function (o) {
        return o.id == organisation.id ? organisation : o;
      });
    },
    delete: function _delete(organisation) {
      this.organisations = this.organisations.filter(function (o) {
        return o.id != organisation.id;
      });
    },
    set: function set(organisations) {
      this.organisations = cloneDeep(organisations);
    }
  }
});