function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getUserById } from '@/api/risma/data';
import { getRiskProjects } from '@/api/risk/risk';
import { getCurrentScoring } from '@/api/risk/scoring';
import { getAllNotifications, setNotificationRead, setAllNotificationAsRead } from '@/api/risma/comm';
import { clickOutside } from '@/utils/directives/clickOutside';
import NavItem from '@/components/Atoms/NavItem/NavItem';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import UserMenu from '@/components/Organisms/UserMenu';
import { buildNavBar } from '@/menus/navbar';
import { buildMainNavMenuStructure, getModuleFromPath } from '@/menus';
import { MenuKeys } from '@/constants/menu/MenuKeys';
import { MODULES } from '@/constants/modules';
import { ColorSettings, LogoSettings } from '@/constants/admin/CustomizationSettings';
export default {
  name: 'HeaderNavBar',
  directives: {
    clickOutside: clickOutside
  },
  components: {
    NavItem: NavItem,
    UserMenu: UserMenu,
    FeatherIcon: FeatherIcon
  },
  props: {
    risma2enable: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true,
      default: 0,
      note: 'the id to get all the data about user'
    },
    userMenuEnabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'display user menu component'
    }
  },
  data: function data() {
    return {
      currentPath: '',
      currentUser: {},
      projects: [],
      navBar: [],
      currentSubTitle: null,
      activeNavItem: {},
      adminSubMenuList: [],
      module: null,
      subMenuList: [],
      notifications: [],
      scoring: {},
      logoSettings: LogoSettings,
      hasHamburgerNavigation: false,
      hasCollapsedMenu: false,
      translate: {
        my: this.$trans('My'),
        administration: this.$trans('Administration')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    getAllSettings: 'getAllSettings'
  })), mapState(useActivityTypesStore, ['activityTypes'])), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), {}, {
    hasHamburgerFlag: function hasHamburgerFlag() {
      return this.getSettingValue('feature.burger_menu');
    },
    colorSettings: function colorSettings() {
      var _this = this;
      var settings = {};
      Object.keys(ColorSettings).forEach(function (item) {
        //  '--' helps it to work in css (line 2 adds it to css)
        settings['--' + item] = _this.getSettingValue(ColorSettings[item].settingName) || ColorSettings[item].color;
      });
      return settings;
    },
    imgSrc: function imgSrc() {
      var hasCustomLogo = this.getSettingValue(this.logoSettings.SETTING);
      if (hasCustomLogo) {
        return this.logoSettings.CUSTOM + "?".concat(new Date().getTime()); //date is for forcing upd;
      }

      if (this.currentPath === '/') {
        return this.logoSettings.DEFAULT_WITH_RISMA;
      }
      return this.logoSettings.DEFAULT;
    },
    currentSection: function currentSection() {
      var category = this.activeNavItem.title || this.currentSubTitle;
      var subCategory = this.activeNavItem.title && this.currentSubTitle;
      return category && "".concat(category).concat(subCategory ? ": ".concat(subCategory) : '');
    },
    isAdminSectionActive: function isAdminSectionActive() {
      return this.currentPath && this.currentPath.indexOf('admin') !== -1;
    },
    informationAssetsName: function informationAssetsName() {
      var settingValue = this.getSettingValue('feature.information_assets_name');
      return settingValue === 'Information Assets' ? this.$trans(settingValue) : this.getSettingValue('feature.information_assets_name');
    },
    processLibraryName: function processLibraryName() {
      var settingValue = this.getSettingValue('feature.process_library_name');
      return settingValue === 'Process Library' ? this.$trans(settingValue) : this.getSettingValue('feature.process_library_name');
    }
  }),
  watch: {
    informationAssetsName: function informationAssetsName() {
      if (!this.navBar) return;
      var complianceMenu = this.navBar.find(function (item) {
        return item.key === MODULES.COMPLIANCE;
      });
      if (!complianceMenu || !complianceMenu.children) return;
      var iaMenu = complianceMenu.children.find(function (item) {
        return item.menuKey === MenuKeys.ia;
      });
      if (!iaMenu) return;
      iaMenu.title = this.informationAssetsName;
      if (!iaMenu.children) return;
      var myIaMenu = iaMenu.children.find(function (item) {
        return item.menuKey === MenuKeys.myIA;
      });
      if (!myIaMenu) return;
      myIaMenu.title = "".concat(this.translate.my, " ").concat(this.informationAssetsName);
    },
    processLibraryName: function processLibraryName() {
      if (!this.navBar) return;
      var complianceMenu = this.navBar.find(function (item) {
        return item.key === MODULES.COMPLIANCE;
      });
      if (!complianceMenu || !complianceMenu.children) return;
      var processLibMenu = complianceMenu.children.find(function (item) {
        return item.menuKey === MenuKeys.processLib;
      });
      if (!processLibMenu) return;
      processLibMenu.title = this.processLibraryName;
    },
    $route: function $route(to) {
      this.currentPath = to.path;
      this.module = this.getModuleName(to.path);
      this.setActiveItemOfMainNav(this.module);
      this.subMenuList = this.setSubMenuList();
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.currentPath = window.location.pathname;
    this.module = this.getModuleName(this.currentPath);
    Promise.all([getUserById(this.id).then(function (user) {
      return _this2.currentUser = _objectSpread(_objectSpread({}, _this2.currentUser), user);
    }), getRiskProjects().then(function (_ref) {
      var list = _ref.list;
      return _this2.currentUser = _objectSpread(_objectSpread({}, _this2.currentUser), {}, {
        riskProjects: list
      });
    }), getAllNotifications().then(function (_ref2) {
      var messages = _ref2.messages;
      return _this2.notifications = messages;
    }), this.getScoringForSearchMenu()]).then(function () {
      _this2.prepareNavigation();
      _this2.setActiveItemOfMainNav(_this2.module);
      // updates for risma1
      _this2.adjustCurrentPath(_this2.module);
      _this2.subMenuList = _this2.setSubMenuList();
      if (_this2.hasHamburgerFlag) {
        _this2.$nextTick(function () {
          window.addEventListener('resize', this.getWindowWidth);
          this.getWindowWidth();
        });
      }
    });
  },
  beforeUnmount: function beforeUnmount() {
    if (this.hasHamburgerFlag) {
      window.removeEventListener('resize', this.getWindowWidth);
    }
  },
  methods: {
    handleHamburgerClick: function handleHamburgerClick(links) {
      if (links) {
        links.forEach(function (link) {
          return link.hovered = false;
        });
      }
      this.hasHamburgerNavigation = false;
    },
    getWindowWidth: function getWindowWidth() {
      if (!this.$refs.navBar || !this.$refs.usermenu) return;
      if (this.$refs.navBar.lastElementChild.getBoundingClientRect().right > this.$refs.usermenu.$el.getBoundingClientRect().left) {
        this.hasCollapsedMenu = true;
      } else {
        this.hasCollapsedMenu = false;
        this.hasHamburgerNavigation = false;
      }
    },
    handleHamburgerClickOutside: function handleHamburgerClickOutside(event) {
      if (!event.target || !event.target.classList || !event.target.classList.contains('hamburger-icon') && !event.target.parentElement.classList.contains('hamburger-icon')) {
        this.hasHamburgerNavigation = false;
      }
    },
    getScoringForSearchMenu: function getScoringForSearchMenu() {
      var _this3 = this;
      return getCurrentScoring().then(function (scoring) {
        _this3.scoring = scoring;
      });
    },
    setSubMenuList: function setSubMenuList() {
      if (this.isAdminSectionActive) {
        this.activeNavItem = {};
        this.currentSubTitle = this.translate.administration;
        return this.adminSubMenuList;
      }
      var isActiveNavItem = !!Object.keys(this.activeNavItem).length;
      if (isActiveNavItem && this.isBigMenu(this.activeNavItem)) {
        var currentList = this.getSectionList();
        this.currentSubTitle = currentList && currentList.title;
        return currentList && currentList.children.filter(function (item) {
          return !item.isHiddenInSubMenu;
        });
      }
      this.currentSubTitle = null;
      return isActiveNavItem ? this.activeNavItem.children.filter(function (item) {
        return !item.isHiddenInSubMenu;
      }) : [];
    },
    getSectionList: function getSectionList() {
      var _this4 = this;
      return this.activeNavItem.children.find(function (childtenItem) {
        if (childtenItem.aliasesRegex && _this4.currentPath.match(childtenItem.aliasesRegex)) {
          return true;
        }
        return childtenItem.children.some(function (item) {
          if (item.children) {
            var _item$children;
            return (_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.find(function (item) {
              return item.url === _this4.currentPath || item.aliasesRegex && _this4.currentPath.match(item.aliasesRegex);
            });
          }
          return item.url === _this4.currentPath;
        });
      });
    },
    getModuleName: function getModuleName(currentPath) {
      var moduleName = getModuleFromPath(currentPath, this.getAllSettings);
      if (moduleName === MODULES.EXECUTION) moduleName = MODULES.EXECUTION;
      if (moduleName === MODULES.AWARENESS) moduleName = MODULES.COMPLIANCE;
      if (currentPath.indexOf('admin') !== -1) moduleName = 'admin';
      return moduleName;
    },
    prepareNavigation: function prepareNavigation() {
      var _this5 = this;
      var menuStructure = buildNavBar(this.getAllSettings, this.currentUser);
      var innerStructure = buildMainNavMenuStructure(this.getAllSettings, this.currentUser, this.activityTypes.filter(function (item) {
        return item.module === MODULES.COMPLIANCE_INFORMATION_ASSETS;
      }));
      this.adminSubMenuList = innerStructure && innerStructure.admin;
      Object.keys(menuStructure).forEach(function (item) {
        var menu = menuStructure[item];
        if (!menu.rights) {
          return;
        }
        menu.selected = false;
        menu.hovered = false;
        menu.key = item;
        menu.children = [];
        if (innerStructure[item]) {
          menu.children = innerStructure[item].filter(function (nav) {
            if (nav.children && nav.children.length) {
              nav.children = nav.children.filter(function (navItem) {
                return navItem.rights;
              });
            }
            nav.hovered = false;
            return nav.rights;
          });
        }
        _this5.navBar.push(menu);
      });
    },
    isBigMenu: function isBigMenu(item) {
      if (!item.children) return false;
      return item.children.some(function (item) {
        return item.children && item.children.length > 0;
      });
    },
    setActiveItemOfMainNav: function setActiveItemOfMainNav(module) {
      var activeNav = {};
      this.navBar.forEach(function (item) {
        if (item.key == module) {
          item.active = true;
          activeNav = item;
        } else {
          item.active = false;
        }
      });
      this.activeNavItem = activeNav;
    },
    adjustCurrentPath: function adjustCurrentPath(module) {
      var segmentsList = this.currentPath.split('/');
      if (segmentsList[2] === 'q' && module === MODULES.EXECUTION) {
        var _activeModule = this.navBar.find(function (item) {
          return item.key === MODULES.EXECUTION;
        });
        var activeMenu = _activeModule.children.find(function (item) {
          return item.menuKey === MenuKeys.reports;
        });
        return this.currentPath = activeMenu.url;
      }
      var activeModule = this.navBar.find(function (item) {
        return item.key === module;
      });
      if (segmentsList[3] === 'my-risks' && module === MODULES.RISK) {
        var _activeMenu = activeModule.children[0].children.find(function (item) {
          return item.menuKey === MenuKeys.myRisks;
        });
        return this.currentPath = _activeMenu.url;
      }
      if (segmentsList[2] === 'overview' && module === MODULES.RISK) {
        var _activeMenu2 = activeModule.children[0].children.find(function (item) {
          return item.menuKey === MenuKeys.riskOverview;
        });
        return this.currentPath = _activeMenu2.url;
      }
    },
    getSubMenuStyle: function getSubMenuStyle(link) {
      var isBigMenu = this.isBigMenu(link);
      var xxlStyle = 'submenu-align xxxl:transform xxxl:-translate-x-1/2 xxxl:max-w-screen-xxl';
      var visibleStyle = link.selected ? 'visible' : '';
      var mainStyle = isBigMenu ? "flex font-bold divide-x divide-slate-300 w-full ".concat(xxlStyle) : 'shadow-inner-t bg-gray-250 border-gray-650 flex-col -ml-8 submenu-border';
      return "".concat(mainStyle, " ").concat(visibleStyle);
    },
    selectNavItem: function selectNavItem(event, linkItem) {
      if (!event.target.closest('.main-nav-item')) return this.hideSubNavigation();
      if (!linkItem.selected) {
        this.hideSubNavigation();
      }
      linkItem.selected = !linkItem.selected;
    },
    hideSubNavigation: function hideSubNavigation() {
      this.navBar.forEach(function (link) {
        return link.selected = false;
      });
    },
    onNotificationClick: function onNotificationClick(notificationId) {
      var _this6 = this;
      var index, notification;
      notification = this.notifications.find(function (notification, idx) {
        index = idx;
        return notification.id === notificationId;
      });
      if (notification) {
        if (!notification.read) {
          setNotificationRead(notificationId).then(function () {
            notification.read = true;
            _this6.notifications[index] = notification;
          });
        }
        if (this.$route.path !== notification.regarding_url) {
          this.$router.push(notification.regarding_url);
        }
      }
    },
    handleMarkAllNotificationsAsRead: function handleMarkAllNotificationsAsRead() {
      var _this7 = this;
      setAllNotificationAsRead().then(function (_ref3) {
        var messages = _ref3.messages;
        return _this7.notifications = messages;
      });
    },
    checkActiveSubItem: function checkActiveSubItem(item) {
      if (!item.checkActiveSubItem) return item.url === this.currentPath;
      return item.checkActiveSubItem(this.currentPath);
    }
  }
};