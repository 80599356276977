function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import draggable from 'vuedraggable';
import { Colors } from '@/Colors';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useSolutionsStore } from '@/Store/solutionsStore';
import { createActivityType, editActivityType } from '@/api/risma/activityTypes';
import { MODULES } from '@/constants/modules';
import DragAndDropIcon from '@/components/Atoms/FeatherIcon/DragAndDropIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
import Modal from '@/components/Molecules/Modal/Modal';
import SolutionsSelector from '@/components/Molecules/SolutionsSelector';
import CollapsiblePanel from '@/components/Molecules/CollapsiblePanel/CollapsiblePanel';
import OptionalFieldsTable from '@/components/Molecules/Admin/OptionalFieldsTable';
import { IATitleMixin } from '@/mixins/IATitleMixin';
import { AdminActivityTypesMixin } from '@/components/Organisms/Admin/AdminActivityTypesMixin';
import { AdminTypesModalMixin } from '@/components/Organisms/Admin/AdminTypesModalMixin';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { getDisabledModuleFromArrayBySetting } from '@/utils/format/getDisabledModuleFromArrayBySetting';
import { sortArrayOfObjectsByArrayOfValues } from '@/utils/sort';
import { getCustomFieldTypeText as _getCustomFieldTypeText } from '@/constants/CustomFieldTypes';
import { DragOptions } from '@/constants/DragOptions';
import { ControlsEfficiencyTypes } from '@/constants/ControlFields';
var customStates = {
  DONT_SHOW: 'dont-show',
  ACTIVE: 'active',
  REQUIRED: 'required',
  CONTROL: 'control',
  CONTROL_COMPLETION: 'control-completion'
};
export default {
  name: 'AdminActivityTypesModal',
  introduction: 'Modal with activity types in /business/admin',
  description: 'Modal for creation & edit activity types. Used together with AdminActivityTypesWell component',
  token: '<admin-activity-types-modal></admin-activity-types-modal>',
  components: {
    Modal: Modal,
    InputField: InputField,
    SingleSelect: SingleSelect,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    Checkboxes: Checkboxes,
    RadioButtons: RadioButtons,
    SolutionsSelector: SolutionsSelector,
    CollapsiblePanel: CollapsiblePanel,
    draggable: draggable,
    DragAndDropIcon: DragAndDropIcon,
    OptionalFieldsTable: OptionalFieldsTable
  },
  mixins: [AdminActivityTypesMixin, AdminTypesModalMixin, IATitleMixin, ProcessLibraryTitleMixin],
  props: {
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {},
      note: 'All activity types'
    },
    fields: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Optional and custom fields by module'
    }
  },
  emits: ['edited', 'created'],
  data: function data() {
    var _values;
    return {
      id: null,
      isEditMode: false,
      values: (_values = {}, _defineProperty(_values, MODULES.EXECUTION, {
        'optional': ['title'],
        'custom': {}
      }), _defineProperty(_values, MODULES.INCIDENTS, {
        'optional': [],
        'custom': {}
      }), _defineProperty(_values, MODULES.COMPLIANCE, {
        'optional': [],
        'custom': {}
      }), _defineProperty(_values, MODULES.CONTROLS, {
        'optional': [],
        'custom': {}
      }), _defineProperty(_values, MODULES.RISK, {
        'optional': [],
        'custom': {}
      }), _defineProperty(_values, MODULES.COMPLIANCE_INFORMATION_ASSETS, {
        'optional': [],
        'custom': {}
      }), _defineProperty(_values, MODULES.COMPLIANCE_IA_CONTROLLER, {
        'optional': [],
        'custom': {}
      }), _defineProperty(_values, MODULES.COMPLIANCE_IA_PROCESSOR, {
        'optional': [],
        'custom': {}
      }), _defineProperty(_values, MODULES.COMPLIANCE_IA_SYSTEM, {
        'optional': [],
        'custom': {}
      }), _defineProperty(_values, MODULES.CONTRACTS, {
        'optional': [],
        'custom': {}
      }), _defineProperty(_values, MODULES.COMPLIANCE_PROCESS_TREE_NODE, {
        'optional': [],
        'custom': {}
      }), _values),
      controlsFieldsOptions: [{
        value: customStates.DONT_SHOW
      }, {
        value: customStates.CONTROL
      }, {
        value: customStates.CONTROL_COMPLETION
      }],
      contractsFieldsOptions: [{
        value: customStates.DONT_SHOW
      }, {
        value: customStates.ACTIVE
      }, {
        value: customStates.REQUIRED
      }],
      DragOptions: DragOptions,
      colors: Colors,
      showModal: false,
      showButtons: false,
      labelName: '',
      labelNameValidation: null,
      moduleType: '',
      MODULES: MODULES,
      showDelegationSection: true,
      delegationLabels: {
        responsible: '',
        accountable: '',
        consulted: '',
        informed: ''
      },
      selectedSolution: [],
      activityTypeTitleLength: 50,
      controlsEfficiencyType: ControlsEfficiencyTypes.default,
      translate: getTranslate['AdminActivityTypesModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useSolutionsStore, {
    solutionIds: 'enabledSolutions'
  })), {}, {
    efficiencyAssessmentEnabled: function efficiencyAssessmentEnabled() {
      return !!this.getSettingValue('feature.controls_efficiency_enabled');
    },
    moduleTypes: function moduleTypes() {
      var items = [{
        label: 'RISMAactions',
        value: MODULES.EXECUTION
      }, {
        label: 'RISMAincidents',
        value: MODULES.INCIDENTS
      }, {
        label: 'RISMAcontrols',
        value: MODULES.CONTROLS
      }, {
        label: 'RISMArisk',
        value: MODULES.RISK
      }, {
        label: 'RISMAcompliance',
        value: MODULES.COMPLIANCE
      }, {
        label: 'Contracts',
        value: MODULES.CONTRACTS
      }, {
        label: this.processLibraryTitle,
        value: MODULES.COMPLIANCE_PROCESS_TREE_NODE
      }, {
        label: "".concat(this.informationAssetsTitle(), " - ").concat(this.translate.custom),
        value: MODULES.COMPLIANCE_INFORMATION_ASSETS
      }, {
        label: "".concat(this.informationAssetsTitle(), " - ").concat(this.translate.system),
        value: MODULES.COMPLIANCE_IA_SYSTEM
      }, {
        label: "".concat(this.informationAssetsTitle(), " - ").concat(this.translate.dataController),
        value: MODULES.COMPLIANCE_IA_CONTROLLER
      }, {
        label: "".concat(this.informationAssetsTitle(), " - ").concat(this.translate.dataProcessor),
        value: MODULES.COMPLIANCE_IA_PROCESSOR
      }];
      var values = getDisabledModuleFromArrayBySetting(items.map(function (item) {
        return item.value;
      }), this.getSettingValue);
      values.forEach(function (item) {
        items.splice(items.findIndex(function (element) {
          return element.value === item;
        }), 1);
      });
      return items;
    },
    showCustomFields: function showCustomFields() {
      return this.moduleType === MODULES.INCIDENTS || this.moduleType === MODULES.RISK || this.moduleType === MODULES.COMPLIANCE_INFORMATION_ASSETS || this.moduleType === MODULES.COMPLIANCE_IA_CONTROLLER || this.moduleType === MODULES.COMPLIANCE_IA_PROCESSOR || this.moduleType === MODULES.COMPLIANCE_IA_SYSTEM || this.moduleType === MODULES.CONTRACTS || this.moduleType === MODULES.COMPLIANCE_PROCESS_TREE_NODE || this.moduleType === MODULES.EXECUTION || this.moduleType === MODULES.COMPLIANCE;
    },
    delegationLabelsDefault: function delegationLabelsDefault() {
      return {
        responsible: 'Responsible',
        accountable: 'Accountable',
        consulted: 'Consulted',
        informed: 'Informed'
      };
    },
    isContractModule: function isContractModule() {
      return this.moduleType === MODULES.CONTRACTS;
    },
    optionalFieldsHidden: function optionalFieldsHidden() {
      return this.fields.optionalFields[this.moduleType].reduce(function (acc, next) {
        if (next.hidden) acc.push(next.value);
        return acc;
      }, []);
    },
    customFieldsOptions: function customFieldsOptions() {
      var options = [{
        value: customStates.DONT_SHOW
      }, {
        value: customStates.ACTIVE
      }];
      if (this.moduleType === MODULES.CONTRACTS) {
        options.push({
          value: customStates.REQUIRED
        });
      }
      return options;
    },
    customFieldsIdsByModuleType: function customFieldsIdsByModuleType() {
      return this.fields.customFields[this.moduleType].map(function (item) {
        return item.id;
      });
    },
    controlsEfficiencyOptions: function controlsEfficiencyOptions() {
      return [{
        label: this.translate.disabled,
        value: ControlsEfficiencyTypes.default
      }, {
        label: this.translate.efficiencyAssessmentRed,
        value: ControlsEfficiencyTypes.efficiencyAssessment
      }, {
        label: this.translate.extendedEfficiencyAssessment,
        value: ControlsEfficiencyTypes.extendedEfficiencyAssessment
      }];
    }
  }),
  methods: {
    onCreateOpen: function onCreateOpen(module) {
      this.beforeOpen();
      this.isEditMode = false;
      this.id = null;
      this.moduleType = module || this.moduleTypes[0].value;
      this.controlsEfficiencyType = ControlsEfficiencyTypes.default;
      this.fillAllOptionalFields();
    },
    onEditOpen: function onEditOpen(data) {
      if (typeof this.values[data.module] == 'undefined') {
        alert('unknown module');
        return;
      }
      this.beforeOpen();
      this.isEditMode = true;
      if (data) {
        var selectedSolution = this.solutionIds.find(function (solutionId) {
          return solutionId.id === data.solutionId;
        });
        this.id = data.id;
        this.labelName = data.label;
        this.moduleType = data.module;
        this.controlsEfficiencyType = data.controlsEfficiencyType;
        this.selectedSolution = selectedSolution ? [selectedSolution] : [];
        this.delegationLabels.responsible = data.responsibleLabel || this.delegationLabels.responsible;
        this.delegationLabels.accountable = data.accountableLabel || this.delegationLabels.accountable;
        this.delegationLabels.consulted = data.consultedLabel || this.delegationLabels.consulted;
        this.delegationLabels.informed = data.informedLabel || this.delegationLabels.informed;
      }
      if (data.module === MODULES.CONTROLS) {
        this.fillControlData(data);
        return;
      }
      this.fillActivityData(data);
      this.fillCustomFieldsData(data);
    },
    sortCustomFieldsByFields: function sortCustomFieldsByFields(customFields, fields) {
      var ids = fields.map(function (item) {
        return item.id;
      });
      return sortArrayOfObjectsByArrayOfValues(customFields, ids, 'id');
    },
    fillControlData: function fillControlData(data) {
      var _this = this;
      this.fields.customFields[MODULES.CONTROLS] = this.sortCustomFieldsByFields(this.fields.customFields[MODULES.CONTROLS], data.fields);
      this.fields.customFields[MODULES.CONTROLS].forEach(function (field) {
        var element = data.fields.find(function (element) {
          return element.id === field.id;
        });
        if (!element || !element.activityLinkModule) {
          _this.values[MODULES.CONTROLS].custom[field.id] = customStates.DONT_SHOW;
          return;
        }
        _this.values[MODULES.CONTROLS].custom[field.id] = element.activityLinkModule;
      });
    },
    fillCustomFieldsData: function fillCustomFieldsData(data) {
      var _this2 = this;
      this.fields.customFields[data.module] = this.sortCustomFieldsByFields(this.fields.customFields[data.module], data.fields);
      this.fields.customFields[data.module].forEach(function (field) {
        var element = data.fields.find(function (element) {
          return element.id === field.id;
        });
        if (!element) {
          _this2.values[data.module].custom[field.id] = customStates.DONT_SHOW;
          return;
        }
        if (element.required && data.module === MODULES.CONTRACTS) {
          _this2.values[data.module].custom[field.id] = customStates.REQUIRED;
          return;
        }
        _this2.values[data.module].custom[field.id] = customStates.ACTIVE;
      });
    },
    fillActivityData: function fillActivityData(data) {
      if (data.module === MODULES.CONTRACTS) {
        this.fields.optionalFields[data.module] = sortArrayOfObjectsByArrayOfValues(this.fields.optionalFields[data.module], data.optionalFields, 'value');
      }
      this.values[data.module].optional = data.optionalFields;
    },
    fillAllOptionalFields: function fillAllOptionalFields() {
      var _this3 = this;
      if (!this.fields.optionalFields) return;
      Object.keys(this.fields.optionalFields).forEach(function (moduleType) {
        _this3.values[moduleType].optional = _this3.fields.optionalFields[moduleType].map(function (item) {
          return item.value;
        });
      });
    },
    onChangeModuleType: function onChangeModuleType(e) {
      if (e) {
        this.moduleType = e;
      }
    },
    createActivity: function createActivity() {
      var _this4 = this;
      if (!this.validate()) {
        return;
      }
      var data = this.prepareData();
      createActivityType(data).then(function (data) {
        _this4.$emit('created', data);
      }).catch(function (e) {
        throw e;
      });
      this.toggleModal();
    },
    editActivity: function editActivity() {
      var _this5 = this;
      if (!this.validate()) {
        return;
      }
      var data = this.prepareData();
      editActivityType(this.id, data).then(function (data) {
        _this5.$emit('edited', data);
      }).catch(function (e) {
        throw e;
      });
      this.toggleModal();
    },
    prepareData: function prepareData() {
      var _$id, _;
      var customFields;
      switch (this.moduleType) {
        case MODULES.CONTROLS:
          customFields = this.convertControlCustomFields();
          break;
        default:
          customFields = this.convertCustomFields();
      }
      var customLabels = !this.isContractModule ? {} : {
        responsibleLabel: this.delegationLabels.responsible,
        accountableLabel: this.delegationLabels.accountable,
        consultedLabel: this.delegationLabels.consulted,
        informedLabel: this.delegationLabels.informed
      };
      var solutionIds = !this.moduleType === MODULES.INCIDENTS ? {} : {
        solutionId: (_$id = (_ = (this.selectedSolution || [])[0]) === null || _ === void 0 ? void 0 : _.id) !== null && _$id !== void 0 ? _$id : null
      };
      var requiredFields = this.convertRequiredFields();
      return _objectSpread(_objectSpread(_objectSpread({
        label: this.labelName,
        module: this.moduleType,
        optionalFields: this.values[this.moduleType].optional
      }, customLabels), solutionIds), {}, {
        customFields: customFields,
        requiredFields: requiredFields,
        controlsEfficiencyType: this.controlsEfficiencyType
      });
    },
    convertControlCustomFields: function convertControlCustomFields() {
      var _this6 = this;
      var result = [];
      var controlCustomFields = this.values[MODULES.CONTROLS].custom;
      Object.keys(this.values[MODULES.CONTROLS].custom).sort(function (a, b) {
        return _this6.customFieldsIdsByModuleType.indexOf(+a) - _this6.customFieldsIdsByModuleType.indexOf(+b);
      }).forEach(function (key) {
        var value = controlCustomFields[key] === customStates.DONT_SHOW ? '' : controlCustomFields[key];
        result.push({
          id: +key,
          activityLinkModule: value
        });
      });
      return result;
    },
    convertCustomFields: function convertCustomFields() {
      var _this7 = this;
      return Object.entries(this.values[this.moduleType].custom).sort(function (a, b) {
        return _this7.customFieldsIdsByModuleType.indexOf(+a[0]) - _this7.customFieldsIdsByModuleType.indexOf(+b[0]);
      }).reduce(function (acc, item) {
        if (item[1] === customStates.ACTIVE || item[1] === customStates.REQUIRED) {
          acc.push({
            id: +item[0]
          });
        }
        return acc;
      }, []);
    },
    convertRequiredFields: function convertRequiredFields() {
      return Object.entries(this.values[MODULES.CONTRACTS].custom).filter(function (item) {
        return item[1] === customStates.REQUIRED;
      }).map(function (item) {
        return +item[0];
      });
    },
    validate: function validate() {
      var result = true;
      this.resetValidation();
      if (!this.labelName) {
        result = false;
        this.labelNameValidation = this.translate.pleaseFillOutThisField;
      }
      if (this.labelName.length > this.activityTypeTitleLength) {
        result = false;
        this.labelNameValidation = this.translate.maxLengthIs50Characters;
      }
      var isLabelExist = this.activityTypes.find(this.labelExistCheck);
      if (isLabelExist) {
        result = false;
        this.labelNameValidation = this.translate.theActivityTypeWithThisTitleAlreadyExists;
      }
      return result;
    },
    labelExistCheck: function labelExistCheck(activityType) {
      return activityType.module === this.moduleType && activityType.label === this.labelName && activityType.id !== this.id;
    },
    clearFields: function clearFields() {
      this.labelName = '';
      this.moduleType = '';
      this.values.execution.optional = ['title'];
      this.values.compliance.optional = [];
      Object.values(MODULES).map(this.resetCustomFieldsValuesByModule);
      this.resetDelegationLabels();
    },
    resetCustomFieldsValuesByModule: function resetCustomFieldsValuesByModule(moduleName) {
      var _this$fields$customFi,
        _this8 = this;
      (_this$fields$customFi = this.fields.customFields[moduleName]) === null || _this$fields$customFi === void 0 ? void 0 : _this$fields$customFi.forEach(function (field) {
        _this8.values[moduleName].custom[field.id] = customStates.DONT_SHOW;
      });
    },
    resetDelegationLabels: function resetDelegationLabels() {
      this.delegationLabels = _objectSpread({}, this.delegationLabelsDefault);
    },
    resetValidation: function resetValidation() {
      this.labelNameValidation = null;
    },
    toggleShowDelegationSection: function toggleShowDelegationSection() {
      this.showDelegationSection = !this.showDelegationSection;
    },
    handleBlur: function handleBlur(event) {
      if (!this.delegationLabels[event]) {
        this.delegationLabels[event] = this.delegationLabelsDefault[event];
      }
    },
    getCustomFieldTypeText: function getCustomFieldTypeText(fieldType) {
      return _getCustomFieldTypeText(fieldType);
    }
  }
};