function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import RiskOverview from '@/components/Pages/Risks/RiskOverview';
import MyRisks from '@/components/Pages/Risks/MyRisks';
import RiskEntry from '@/components/Pages/Risks/RiskEntry';
import RiskDescription from '@/components/Pages/Risks/RiskDescription';
import LinksTab from '@/components/Organisms/LinksTab';
import Changelog from '@/components/Molecules/Changelog';
import RiskReportsEntry from '@/components/Pages/Risks/RiskReportsEntry';
import RiskReportsRisks from '@/components/Pages/Risks/RiskReports/RiskReportsRisks';
import RiskReportsThreats from '@/components/Pages/Risks/RiskReportsThreats';
import RiskReportsVulnerabilities from '@/components/Pages/Risks/RiskReportsVulnerabilities';
import RisksDashboard from '@/components/Pages/Risks/RisksDashboard';
import { RiskBaseUrls } from '@/constants/risks/RiskBaseUrls';
import { MODULES } from '@/constants/modules';
import { userHasRiskProjectAccess } from '@/routes/auth';
import { UserLevels } from '@/constants/UserLevels';
import ManagementReport from '@/components/Pages/Risks/RiskReports/ManagementReport';
import { getReportOptionsFromQuery } from '@/utils/report';
export default (function () {
  return [{
    path: "".concat(RiskBaseUrls.projects, "/:projectId/overview"),
    name: 'RiskOverview',
    component: RiskOverview,
    props: true,
    meta: {
      userRightsFunction: userHasRiskProjectAccess(UserLevels.NORMAL)
    }
  }, {
    path: "".concat(RiskBaseUrls.projects, "/:projectId/periods/:periodId/risk/:path"),
    component: RiskEntry,
    props: function props(route) {
      return _objectSpread(_objectSpread({}, route.params), {}, {
        scoringId: route.query.scoringId
      });
    },
    meta: {
      userRightsFunction: userHasRiskProjectAccess(UserLevels.NORMAL)
    },
    children: [{
      path: 'description',
      component: RiskDescription,
      props: function props(route) {
        return _objectSpread(_objectSpread({}, route.params), {}, {
          scoringId: route.query.scoringId
        });
      }
    }, {
      path: 'links',
      component: LinksTab,
      props: {
        module: MODULES.RISK
      }
    }, {
      path: 'changelog',
      component: Changelog
    }]
  }, {
    path: "".concat(RiskBaseUrls.projects, "/:projectId/dashboard"),
    component: RisksDashboard,
    props: true,
    meta: {
      userRightsFunction: userHasRiskProjectAccess(UserLevels.NORMAL)
    }
  }, {
    path: "".concat(RiskBaseUrls.projects, "/:projectId/my-risks"),
    name: 'MyRisks',
    component: MyRisks,
    props: true,
    meta: {
      userRightsFunction: userHasRiskProjectAccess(UserLevels.NORMAL)
    }
  }, {
    path: "".concat(RiskBaseUrls.projects, "/:projectId/reports"),
    name: 'Reports',
    component: RiskReportsEntry,
    props: true,
    meta: {
      userRightsFunction: userHasRiskProjectAccess(UserLevels.NORMAL),
      keyFunction: function keyFunction($route) {
        return $route.path;
      }
    },
    redirect: {
      name: 'RiskReportsRisks'
    },
    children: [{
      path: 'risks',
      name: 'RiskReportsRisks',
      props: function props(route) {
        var savedReportOptions = getReportOptionsFromQuery(route.query);
        return _objectSpread(_objectSpread({}, route.params), {}, {
          savedReportOptions: savedReportOptions
        });
      },
      component: RiskReportsRisks
    }, {
      path: 'threats',
      props: true,
      component: RiskReportsThreats
    }, {
      path: 'vulnerabilities',
      props: true,
      component: RiskReportsVulnerabilities
    }]
  }, {
    path: "".concat(RiskBaseUrls.main, "/reports/pdfmain"),
    name: 'ManagementReport',
    component: ManagementReport
  }];
});