import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Alert from '@/components/Molecules/Modal/Alert';
import { clickOutside } from '@/utils/directives/clickOutside';
import { exportToXlsx } from '@/utils/export/excel';
export default {
  name: 'RismaExcel',
  introduction: '',
  description: '',
  token: '<risma-excel :dataset="dataset" :columns="columns" />',
  components: {
    FeatherIcon: FeatherIcon,
    Alert: Alert
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    dataset: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'Dataset for printing'
    },
    columns: {
      required: false,
      type: Object,
      default: function _default() {},
      note: 'Columns for printing'
    },
    filename: {
      required: false,
      type: String,
      default: 'RISMAbusiness',
      note: 'Excel filename'
    },
    confirmMessage: {
      required: false,
      type: String,
      default: '',
      note: 'Confirmation popup message to display before exporting'
    },
    handler: {
      required: false,
      type: Function,
      default: null,
      note: 'Requires callback with Promise.resolve({dataset, columns, filename = ""})'
    }
  },
  data: function data() {
    return {
      showConfirmationAlert: false
    };
  },
  methods: {
    confirmExport: function confirmExport() {
      if (this.handler) {
        this.callHandler();
        return;
      }
      if (this.confirmMessage) {
        this.showConfirmationAlert = true;
        return;
      }
      this.exportData();
    },
    exportData: function exportData() {
      this.showConfirmationAlert = false;
      return exportToXlsx(this.dataset, this.columns, this.filename);
    },
    callHandler: function callHandler() {
      var _this = this;
      this.handler().then(function (_ref) {
        var dataset = _ref.dataset,
          columns = _ref.columns,
          _ref$filename = _ref.filename,
          filename = _ref$filename === void 0 ? '' : _ref$filename;
        filename = filename || _this.filename;
        exportToXlsx(dataset, columns, filename);
      });
    }
  }
};