import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2440cef8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "ad-settings"
};
var _hoisted_2 = {
  class: "ad-settings-row"
};
var _hoisted_3 = {
  class: "ad-settings-row"
};
var _hoisted_4 = {
  class: "ad-settings-row"
};
var _hoisted_5 = {
  class: "ad-settings-name"
};
var _hoisted_6 = {
  class: "ad-settings-value"
};
var _hoisted_7 = {
  class: "ad-settings-row"
};
var _hoisted_8 = {
  class: "ad-settings-name"
};
var _hoisted_9 = {
  class: "ad-settings-value"
};
var _hoisted_10 = ["checked"];
var _hoisted_11 = {
  class: "ad-settings-row"
};
var _hoisted_12 = {
  class: "ad-settings-name"
};
var _hoisted_13 = {
  class: "ad-settings-value"
};
var _hoisted_14 = {
  class: "ad-settings-row"
};
var _hoisted_15 = {
  class: "ad-settings-name"
};
var _hoisted_16 = {
  class: "ad-settings-value"
};
var _hoisted_17 = {
  key: 0,
  class: "text-sm text-yellow-610 py-1"
};
var _hoisted_18 = {
  class: "ad-settings-row"
};
var _hoisted_19 = {
  class: "ad-settings-name"
};
var _hoisted_20 = {
  class: "ad-settings-value"
};
var _hoisted_21 = {
  class: "ad-settings-row"
};
var _hoisted_22 = {
  class: "ad-settings-name"
};
var _hoisted_23 = {
  class: "ad-settings-value"
};
var _hoisted_24 = {
  key: 0,
  class: "text-sm text-yellow-610 py-1"
};
var _hoisted_25 = {
  class: "ad-settings-row"
};
var _hoisted_26 = {
  class: "ad-settings-name"
};
var _hoisted_27 = {
  class: "ad-settings-value"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$data.errors.length ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    closeable: false,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errors, function (error, i) {
        return _openBlock(), _createElementBlock("p", {
          key: i
        }, _toDisplayString(error), 1 /* TEXT */);
      }), 128 /* KEYED_FRAGMENT */))];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_3, [$data.isSubmitSuccessNotificationShown ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "success"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("p", null, _toDisplayString($data.translate.settingsAreSaved), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.activateAdLogin,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_6, [_withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.adAuthenticationMode = $event;
    }),
    type: "checkbox",
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.handleChange('adAuthenticationMode', !$data.adAuthenticationMode ? 'oidc' : 'local');
    })
  }, null, 512 /* NEED_PATCH */), [[_vModelCheckbox, $data.adAuthenticationMode]])])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: $data.translate.useSecurityGroups,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("input", {
    checked: !$data.ignoreAdPermissions,
    type: "checkbox",
    onChange: _cache[2] || (_cache[2] = function () {
      return $options.onUseSecurityChange && $options.onUseSecurityChange.apply($options, arguments);
    })
  }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_10)])]), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createVNode(_component_risma_title, {
    title: $data.translate.domainName,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_input_field, {
    modelValue: $data.domain,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.domain = $event;
    }),
    placeholder: $data.translate.enterADomainName,
    type: "text",
    onUpdated: _cache[4] || (_cache[4] = function (value) {
      return $options.handleChange('domain', value);
    })
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"])])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createVNode(_component_risma_title, {
    title: $data.translate.endpoint,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_16, [_createVNode(_component_input_field, {
    modelValue: $data.endpoint,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $data.endpoint = $event;
    }),
    placeholder: $data.translate.enterAnEndpoint,
    type: "text",
    onUpdated: _cache[6] || (_cache[6] = function (value) {
      return $options.handleChange('endpoint', value);
    })
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"]), _createVNode(_Transition, null, {
    default: _withCtx(function () {
      return [!$options.isPreferredEndpoint ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString($data.translate.clientUrlForAzureAdShouldBeFormat), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })])]), _createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_createVNode(_component_risma_title, {
    title: $data.translate.clientId,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_20, [_createVNode(_component_input_field, {
    modelValue: $data.clientId,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
      return $data.clientId = $event;
    }),
    placeholder: $data.translate.enterAClientId,
    type: "text",
    onUpdated: _cache[8] || (_cache[8] = function (value) {
      return $options.handleChange('clientId', value);
    })
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"])])]), _createElementVNode("div", _hoisted_21, [_createElementVNode("div", _hoisted_22, [_createVNode(_component_risma_title, {
    title: $data.translate.clientSecret,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_23, [_createVNode(_component_input_field, {
    modelValue: $data.clientSecret,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
      return $data.clientSecret = $event;
    }),
    placeholder: $data.translate.enterAClientSecret,
    type: "text",
    onUpdated: _cache[10] || (_cache[10] = function (value) {
      return $options.handleChange('clientSecret', value);
    })
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"]), _createVNode(_Transition, null, {
    default: _withCtx(function () {
      return [!$options.isClientSecretValid ? (_openBlock(), _createElementBlock("p", _hoisted_24, _toDisplayString($data.translate.theEnteredSecretHasTheFormatOfAnObjectId), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })])]), _createElementVNode("div", _hoisted_25, [_createElementVNode("div", _hoisted_26, [_createVNode(_component_risma_title, {
    title: $data.translate.advancedEmailMapping,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_27, [_createVNode(_component_input_field, {
    modelValue: $data.appOidcEmail,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = function ($event) {
      return $data.appOidcEmail = $event;
    }),
    placeholder: $data.translate.enterAnEmailMappingSetToUpnForDefault,
    type: "text",
    onUpdated: _cache[12] || (_cache[12] = function (value) {
      return $options.handleChange('appOidcEmail', value);
    })
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"])])]), _createElementVNode("div", null, [_createVNode(_component_risma_button, {
    text: $data.translate.update,
    type: "save",
    disabled: $data.isSubmitBtnDisabled,
    onClick: $options.update
  }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])]);
}