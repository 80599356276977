function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Modal from '@/components/Molecules/Modal/Modal';
import Notification from '@/components/Molecules/Notification';
import { getAllQuestionnaires, getAllQuestionsIncludingHidden } from '@/api/compliance/questionnaire';
import { createTiaLiaSettings, deleteTiaLiaSettings, getTiaLiaSettings, updateTiaLiaSettings } from '@/api/admin/tialia';
import { getAllGapSchemasByGapSchemaType } from '@/api/compliance/gapschema';
import { LiaTiaType } from '@/constants/compliance/LiaTiaGeneric';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
export default {
  name: 'LiaTiaAdmin',
  components: {
    RismaToggle: RismaToggle,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    FeatherIcon: FeatherIcon,
    RismaButton: RismaButton,
    Modal: Modal,
    Notification: Notification
  },
  data: function data() {
    return {
      settingRows: [],
      createSettingErrors: '',
      newSettingType: null,
      showCreationModal: false,
      isLoaded: false,
      allQuestionnaires: [],
      allGapSchemas: [],
      questionTypes: QuestionTypes(),
      tdStyles: 'max-width: 200px',
      LiaTiaType: LiaTiaType,
      translate: getTranslate['LiaTiaAdmin']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    rightQuestionTypes: function rightQuestionTypes() {
      return [this.questionTypes.singleChoice.id, this.questionTypes.multipleChoice.id];
    },
    typesAvailableForCreation: function typesAvailableForCreation() {
      return Object.keys(LiaTiaType).map(function (key) {
        return {
          label: LiaTiaType[key].title,
          value: +key
        };
      });
    },
    columns: function columns() {
      return ['', this.translate.title, this.translate.enabled, this.translate.chooseQuestionnaire, this.translate.chooseQuestion, this.translate.chooseQuestionAnswers, this.translate.chooseGapSchema, this.translate.chooseGapArticle, this.translate.liatiaQuestionnaire];
    },
    questionnaireOptions: function questionnaireOptions() {
      return this.allQuestionnaires.map(function (item) {
        return {
          label: item.title,
          value: item.id,
          questionnaireType: item.questionnaireType
        };
      });
    },
    questionnaires: function questionnaires() {
      var data = {};
      this.questionnaireOptions.forEach(function (item) {
        if (!data[item.questionnaireType]) {
          data[item.questionnaireType] = [];
        }
        data[item.questionnaireType].push(item);
      });
      return data;
    }
  }),
  mounted: function mounted() {
    var _this = this;
    Promise.all([this.getQuestionnaires(), this.getAllGapSchemas(ProjectTypes.GDPR), this.getAllGapSchemas(ProjectTypes.DATAPROCESSOR)]).then(function () {
      _this.updateData();
    }).then(function () {
      _this.isLoaded = true;
    });
  },
  methods: {
    onChange: function onChange(elementIdx) {
      var updatedSetting = this.settingRows[elementIdx];
      var dataToSend = {
        type: updatedSetting.type,
        enabled: updatedSetting.enabled,
        gapArticleId: updatedSetting.gapArticleId,
        gapSchemaId: updatedSetting.gapSchemaId,
        questionOptionIds: updatedSetting.questionOptionIds,
        questionId: updatedSetting.questionId,
        questionnaireId: updatedSetting.questionnaireId,
        assessmentQuestionnaireId: updatedSetting.assessmentQuestionnaireId
      };
      return updateTiaLiaSettings(updatedSetting.id, dataToSend);
    },
    createNewSetting: function createNewSetting() {
      var _this2 = this;
      if (this.newSettingHasErrors()) return;
      var newSetting = {
        enabled: 1,
        type: +this.newSettingType,
        questionnaireId: null,
        assessmentQuestionnaireId: null,
        gapArticleId: null
      };
      return createTiaLiaSettings(newSetting).then(function () {
        _this2.showCreationModal = false;
        _this2.updateData();
      });
    },
    newSettingHasErrors: function newSettingHasErrors() {
      this.createSettingErrors = '';
      if (!this.newSettingType) {
        this.createSettingErrors = this.translate.pleaseSelectAProjectToContinue;
      }
      return this.createSettingErrors;
    },
    deleteRow: function deleteRow(itemIdx) {
      var _this3 = this;
      var row = this.settingRows[itemIdx];
      deleteTiaLiaSettings(row.id).then(function () {
        return _this3.updateData();
      });
    },
    onQuestionnaireChange: function onQuestionnaireChange(questionnaireId, itemIdx) {
      var updatedRow = this.settingRows[itemIdx];
      updatedRow.questionnaireId = questionnaireId;
      updatedRow.questionId = null;
      updatedRow.questionOptionIds = [];
      updatedRow.questionAnswers = [];
      updatedRow.questionAnswersSelected = [];
      this.settingRows[itemIdx] = updatedRow;
      this.getQuestions(questionnaireId, itemIdx);
      this.onChange(itemIdx);
    },
    onQuestionChange: function onQuestionChange(questionId, itemIdx) {
      var updatedRow = this.settingRows[itemIdx];
      updatedRow.questionId = questionId;
      updatedRow.questionOptionIds = [];
      updatedRow.questionAnswers = [];
      updatedRow.questionAnswersSelected = [];
      this.setAnswersById(questionId, itemIdx);
      this.onChange(itemIdx);
    },
    onQuestionAnswerChange: function onQuestionAnswerChange(newAnswers, itemIdx) {
      var updatedRow = this.settingRows[itemIdx];
      updatedRow.questionAnswersSelected = newAnswers;
      updatedRow.questionOptionIds = newAnswers.map(function (answer) {
        return answer.id;
      });
      this.onChange(itemIdx);
    },
    onGapSchemaChange: function onGapSchemaChange(gapSchemaId, itemIdx) {
      var updatedRow = this.settingRows[itemIdx];
      updatedRow.gapSchemaId = gapSchemaId;
      updatedRow.gapArticleId = null;
      this.setArticlesById(gapSchemaId, itemIdx);
      this.onChange(itemIdx);
    },
    onGapArticleChange: function onGapArticleChange(gapArticleId, itemIdx) {
      var updatedRow = this.settingRows[itemIdx];
      updatedRow.gapArticleId = gapArticleId;
      this.onChange(itemIdx);
    },
    onAssessmentChange: function onAssessmentChange(assessmentId, itemIdx) {
      var updatedRow = this.settingRows[itemIdx];
      updatedRow.assessmentQuestionnaireId = assessmentId;
      this.onChange(itemIdx);
    },
    onEnabledChange: function onEnabledChange(enabled, itemIdx) {
      var updatedRow = this.settingRows[itemIdx];
      updatedRow.enabled = enabled;
      this.onChange(itemIdx);
    },
    setArticlesById: function setArticlesById(gapSchemaId, itemIdx) {
      var row = this.settingRows[itemIdx];
      var gapSchema = this.allGapSchemas[row.projectType || 1].find(function (item) {
        return +item.id === +gapSchemaId;
      });
      if (gapSchema) {
        row.gapArticleOptions = gapSchema.articles.map(function (item) {
          return {
            label: item.article + ' ' + item.articleTitle,
            value: item.id
          };
        });
      }
    },
    setAnswersById: function setAnswersById(questionId, itemIdx) {
      var row = this.settingRows[itemIdx];
      var question = row.questionOptionsSelect.find(function (item) {
        return +item.id === +questionId;
      });
      var selectedIds = row.questionOptionIds && row.questionOptionIds.map(function (option) {
        return +option;
      });
      if (question) {
        row.questionAnswers = question.optionDetails.map(function (item) {
          return {
            label: item.label,
            value: item.id,
            id: item.id
          };
        });
      }
      if (selectedIds) {
        row.questionAnswersSelected = row.questionAnswers.filter(function (item) {
          return selectedIds.includes(+item.id);
        });
      }
    },
    updateData: function updateData() {
      var _this4 = this;
      return this.getSettingsData().then(function () {
        return Promise.all([_this4.setDefaultQuestions(), _this4.setDefaultGapArticles()]);
      });
    },
    getSettingsData: function getSettingsData() {
      var _this5 = this;
      return getTiaLiaSettings().then(function (_ref) {
        var list = _ref.list;
        _this5.settingRows = list.map(function (row) {
          return _objectSpread(_objectSpread({}, row), _this5.LiaTiaType[row.type]);
        });
      });
    },
    getQuestionnaires: function getQuestionnaires() {
      var _this6 = this;
      return getAllQuestionnaires().then(function (_ref2) {
        var list = _ref2.list;
        _this6.allQuestionnaires = list;
      });
    },
    getQuestions: function getQuestions(questionnaireId, itemIdx) {
      var _this7 = this;
      return getAllQuestionsIncludingHidden(questionnaireId).then(function (response) {
        _this7.settingRows[itemIdx].questionOptionsSelect = response.questions.filter(function (item) {
          return _this7.rightQuestionTypes.includes(item.questiontype);
        }).map(function (item) {
          return _objectSpread(_objectSpread({}, item), {}, {
            label: item.questionnumber + ' ' + item.question,
            value: item.id
          });
        });
        var questionId = _this7.settingRows[itemIdx].questionId;
        if (questionId) {
          _this7.setAnswersById(questionId, itemIdx);
        }
      });
    },
    getAllGapSchemas: function getAllGapSchemas(type) {
      var _this8 = this;
      return getAllGapSchemasByGapSchemaType(type, true).then(function (_ref3) {
        var list = _ref3.list;
        _this8.allGapSchemas[type] = list.map(function (item) {
          return _objectSpread({
            value: item.id,
            label: item.title
          }, item);
        });
      });
    },
    setDefaultGapArticles: function setDefaultGapArticles() {
      var _this9 = this;
      this.settingRows = this.settingRows.map(function (row) {
        return _objectSpread(_objectSpread({}, row), {}, {
          gapArticleOptions: []
        });
      });
      this.settingRows.forEach(function (row, idx) {
        var gapSchemaId = row.gapSchemaId;
        if (gapSchemaId) {
          _this9.setArticlesById(gapSchemaId, idx);
        }
      });
    },
    setDefaultQuestions: function setDefaultQuestions() {
      var _this10 = this;
      this.settingRows = this.settingRows.map(function (row) {
        return _objectSpread(_objectSpread({}, row), {}, {
          questionAnswers: [],
          questionAnswersSelected: [],
          questionOptionsSelect: []
        });
      });
      this.settingRows.forEach(function (row, idx) {
        var questionId = row.questionnaireId;
        if (questionId) {
          _this10.getQuestions(questionId, idx);
        }
      });
    },
    checkEquality: function checkEquality(backupValue, newValue, callback) {
      if (Array.isArray(newValue)) {
        var isEqual = true;
        newValue.forEach(function (item, idx) {
          if (!backupValue[idx] || +backupValue[idx].id !== +item.id) {
            isEqual = false;
          }
        });
        if (!isEqual) {
          callback(newValue);
        }
      } else if (backupValue !== newValue) {
        callback(newValue);
      }
    }
  }
};