import ComplianceGeneralList from '@/components/Organisms/Compliance/Overview/ComplianceGeneralList';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export default {
  components: {
    ComplianceGeneralList: ComplianceGeneralList
  },
  data: function data() {
    return {
      projectId: 1,
      AssetsTypes: AssetsTypes
    };
  }
};