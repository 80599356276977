function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import ToggleMenu from '@/components/Atoms/ToggleMenu/ToggleMenu';
export default {
  name: 'MiniTreeBranch',
  components: {
    ToggleMenu: ToggleMenu
  },
  props: {
    item: {
      required: true,
      type: Object,
      default: function _default() {
        return {};
      }
    },
    path: {
      required: true,
      type: Array
    },
    selectedPath: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    parentId: {
      required: false,
      type: [String, Number],
      default: 0
    },
    searchEnabled: {
      required: false,
      type: Boolean,
      default: false
    },
    openedArray: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    nodeEmits: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['update:selected', 'update:opened', 'update:path'],
  computed: {
    nodeActions: function nodeActions() {
      var _this = this;
      if (!this.nodeEmits.length) return [];
      return this.nodeEmits.map(function (emitData) {
        return _objectSpread(_objectSpread({}, emitData), {}, {
          action: function action() {
            return _this.$emit(emitData.emit, _this.item);
          }
        });
      });
    },
    hasChildren: function hasChildren() {
      var _this$item;
      return (_this$item = this.item) === null || _this$item === void 0 || (_this$item = _this$item.children) === null || _this$item === void 0 ? void 0 : _this$item.length;
    },
    icon: function icon() {
      if (!this.hasChildren) {
        return 'frown';
      }
      return 'fast-forward';
    },
    isElementHighlighted: function isElementHighlighted() {
      return this.isSelected || this.parentId === this.lastIdInSelectedChain || this.searchEnabled;
    },
    level: function level() {
      return this.path.length - 1;
    },
    index: function index() {
      return this.path.slice(-1)[0];
    },
    isSelected: function isSelected() {
      var _this$selectedPath$th;
      if (!this.selectedPath.length) return;
      return ((_this$selectedPath$th = this.selectedPath[this.level]) === null || _this$selectedPath$th === void 0 ? void 0 : _this$selectedPath$th.id) === this.item.id;
    },
    isOpened: function isOpened() {
      return this.openedArray.includes(this.item.id);
    },
    beforeSelected: function beforeSelected() {
      var _this$selectedPath$th2;
      if (!this.selectedPath.length) return;
      var initialPath = this.path.slice(0, this.level);
      var initialSelectedPath = this.selectedPath.map(function (_ref) {
        var index = _ref.index;
        return index;
      }).slice(0, this.level);
      var isInThePath = JSON.stringify(initialPath) === JSON.stringify(initialSelectedPath);
      return isInThePath && ((_this$selectedPath$th2 = this.selectedPath[this.level]) === null || _this$selectedPath$th2 === void 0 ? void 0 : _this$selectedPath$th2.index) >= this.index;
    },
    lastIdInSelectedChain: function lastIdInSelectedChain() {
      if (!this.selectedPath.length) return null;
      return this.selectedPath[this.selectedPath.length - 1].id;
    }
  },
  mounted: function mounted() {
    // check if element's path was changed due to filtering/removing node
    var checkPath = this.selectedPath.map(function (item) {
      return item.index;
    });
    if (this.item.id === this.lastIdInSelectedChain && JSON.stringify(checkPath) !== JSON.stringify(this.path)) {
      this.$emit('update:selected', [{
        id: this.item.id,
        index: this.index
      }]);
    }
  },
  methods: {
    handleClick: function handleClick() {
      this.$emit('update:selected', [{
        id: this.item.id,
        index: this.index
      }]);
    }
  }
};