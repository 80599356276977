import Timezone from '@/components/Molecules/Timezone/Timezone';
export default {
  name: '',
  components: {
    Timezone: Timezone
  },
  data: function data() {
    return {
      timezoneValue: 'Etc/Greenwich'
    };
  },
  methods: {
    timezoneOnSelect: function timezoneOnSelect(e) {
      if (e) {
        this.timezoneValue = e.value;
      }
    }
  }
};