import { Http } from '../index';
import * as HttpUtils from '../httpUtils';
export function getSwitchUserData(modules, userId) {
  var modulesUrl = HttpUtils.objectToQueryString({
    modules: modules
  });
  return HttpUtils.handleRequestResponse(Http.get("/find/useractivities?userId=".concat(userId, "&").concat(modulesUrl)));
}
export function patchActivitiesToAnotherUser(module, activityId, fromUserId, toUserId) {
  var params = {
    module: module,
    activityId: activityId,
    fromUserId: fromUserId,
    toUserId: toUserId
  };
  return HttpUtils.handleRequestResponse(Http.patch('/admin/massupdate', params));
}
export function patchActivitiesToAnotherOrganisation(module, activityId, fromOrganisationId, toOrganisationId) {
  var params = {
    module: module,
    activityId: activityId,
    fromOrganisationId: fromOrganisationId,
    toOrganisationId: toOrganisationId
  };
  return HttpUtils.handleRequestResponse(Http.patch('/admin/massupdate/organisations', params));
}
export function getSolutionActivities(filters) {
  var _filters$modules, _filters$solutionIds;
  var query = '?';
  if (filters !== null && filters !== void 0 && (_filters$modules = filters.modules) !== null && _filters$modules !== void 0 && _filters$modules.length) {
    filters.modules.forEach(function (module) {
      query += "modules[]=".concat(module, "&");
    });
  }
  if (filters !== null && filters !== void 0 && (_filters$solutionIds = filters.solutionIds) !== null && _filters$solutionIds !== void 0 && _filters$solutionIds.length) {
    filters.solutionIds.forEach(function (id) {
      query += "solutionIds[]=".concat(id, "&");
    });
  }
  return HttpUtils.handleRequestResponse(Http.get("find/solutions".concat(query.slice(0, -1))));
}
export function patchSolutionIdsToActivity(module, activityId, addSolutionIds) {
  return HttpUtils.handleRequestResponse(Http.patch('/admin/massupdate/solutions', {
    module: module,
    activityId: activityId,
    addSolutionIds: addSolutionIds
  }));
}
export function patchRemoveUser(module, activityId, userId) {
  return HttpUtils.handleRequestResponse(Http.patch('admin/massupdate/removeuser', {
    module: module,
    activityId: activityId,
    userId: userId
  }));
}