import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mb-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_user_rights_table_new = _resolveComponent("user-rights-table-new");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_single_select, {
    modelValue: $data.selectedUserId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.selectedUserId = $event;
    }),
    options: $options.userOptions,
    placeholder: 'Select user'
  }, null, 8 /* PROPS */, ["modelValue", "options"])]), $data.selectedUserId ? (_openBlock(), _createBlock(_component_user_rights_table_new, {
    key: $data.selectedUserId,
    "user-id": $data.selectedUserId
  }, null, 8 /* PROPS */, ["user-id"])) : _createCommentVNode("v-if", true)]);
}