import MiniTree from '@/components/Molecules/MiniTree/MiniTree';
export default {
  name: '',
  components: {
    MiniTree: MiniTree
  },
  data: function data() {
    return {
      selectedNode: null,
      branch: {
        children: [{
          id: 3,
          title: 'C1',
          children: [{
            id: 4,
            title: 'C2',
            children: [{
              id: 6,
              title: 'C3',
              children: [{
                id: 8,
                title: 'C4565',
                children: [{
                  id: 10,
                  title: 'C5',
                  children: [{
                    id: 12,
                    title: 'C6',
                    children: []
                  }]
                }, {
                  id: 11,
                  title: 'CC5',
                  children: []
                }]
              }, {
                id: 9,
                title: 'CC4',
                children: []
              }]
            }, {
              id: 7,
              title: 'CC3',
              children: []
            }]
          }, {
            id: 5,
            title: 'CC2',
            children: []
          }]
        }, {
          id: 13,
          title: 'Default questionnaire',
          children: []
        }, {
          id: 14,
          title: 'Plesner questionnaire111',
          children: []
        }, {
          id: 15,
          title: 'Questionnaire fully answered',
          children: []
        }, {
          id: 16,
          title: 'Questionnaire and GAP fully answered',
          children: []
        }, {
          id: 45,
          title: 'Test af fil-rettigheder',
          children: []
        }, {
          id: 46,
          title: 'Godkendt spørgeskema',
          children: []
        }]
      }
    };
  }
};