import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "border border-gray-450 rounded px-6 py-5 mb-10"
};
var _hoisted_2 = {
  class: "flex justify-end"
};
var _hoisted_3 = {
  class: "flex mb-4"
};
var _hoisted_4 = {
  class: "w-3/12 mr-6"
};
var _hoisted_5 = {
  class: "flex-grow mr-6"
};
var _hoisted_6 = {
  class: "w-3/12"
};
var _hoisted_7 = {
  key: 1
};
var _hoisted_8 = {
  key: 0
};
var _hoisted_9 = {
  for: "include-data-controller"
};
var _hoisted_10 = {
  class: "mt-3 mb-4"
};
var _hoisted_11 = {
  for: "include-data-flow"
};
var _hoisted_12 = {
  class: "mt-3 mb-4"
};
var _hoisted_13 = {
  for: "include-raci-emails"
};
var _hoisted_14 = {
  class: "mt-3 mb-4"
};
var _hoisted_15 = {
  for: "include-help-text"
};
var _hoisted_16 = {
  key: 3
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_question_selection = _resolveComponent("question-selection");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_button, {
    text: $data.translate.resetFilter,
    type: "util",
    class: "text-right",
    onClick: $options.resetFilters
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        class: "mr-1 stroke-2",
        icon: "rotate-ccw",
        height: "18",
        width: "18"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.selectQuestionnaires,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $options.questionnairesOptions,
    "selected-options": $data.selectedQuestionnaires,
    placeholder: $data.translate.selectAQuestionnaire,
    "label-key": "label",
    "track-by": "value",
    onSelected: $options.handleQuestionnairesSelected
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "onSelected"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.selectNodes + ' (' + $data.translate.noteThatUnderlyingNodesAreAlsoIncludedInTh + ')',
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), !$data.nodesLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.nodesLoaded ? (_openBlock(), _createBlock(_component_risma_select, {
    key: 1,
    options: $options.availableNodesOptions,
    "selected-options": $data.selectedNodes,
    placeholder: $data.translate.selectNodes,
    "label-key": "title",
    onSelected: _cache[0] || (_cache[0] = function ($event) {
      return $event ? $data.selectedNodes = $event : $data.selectedNodes = [];
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_title, {
    title: $data.translate.organisations,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $options.availableOrganisations,
    "selected-options": $data.selectedOrganisations,
    placeholder: $data.translate.selectOrganisation,
    "label-key": "label",
    onSelected: _cache[1] || (_cache[1] = function ($event) {
      return $data.selectedOrganisations = $event;
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder"])])]), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.selectedQuestionnaires.length > 0 && !$data.loading ? (_openBlock(), _createElementBlock("div", _hoisted_7, [!_ctx.isInformationAssetsDisabled ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("label", _hoisted_9, [_withDirectives(_createElementVNode("input", {
    id: "include-data-controller",
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.showControllersAndProcessors = $event;
    }),
    type: "checkbox"
  }, null, 512 /* NEED_PATCH */), [[_vModelCheckbox, $data.showControllersAndProcessors]]), _createTextVNode(" " + _toDisplayString($data.translate.includeDataControllersAndDataProcessors), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_10, [_createElementVNode("label", _hoisted_11, [_withDirectives(_createElementVNode("input", {
    id: "include-data-flow",
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.includeDataFlow = $event;
    }),
    type: "checkbox"
  }, null, 512 /* NEED_PATCH */), [[_vModelCheckbox, $data.includeDataFlow]]), _createTextVNode(" " + _toDisplayString($data.translate.include) + " " + _toDisplayString($data.translate.dataFlow.toLowerCase()), 1 /* TEXT */)])]), _createElementVNode("div", _hoisted_12, [_createElementVNode("label", _hoisted_13, [_withDirectives(_createElementVNode("input", {
    id: "include-raci-emails",
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return $data.includeRaciEmails = $event;
    }),
    type: "checkbox"
  }, null, 512 /* NEED_PATCH */), [[_vModelCheckbox, $data.includeRaciEmails]]), _createTextVNode(" " + _toDisplayString($data.translate.include) + " " + _toDisplayString($data.translate.raciEmails), 1 /* TEXT */)])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("label", _hoisted_15, [_withDirectives(_createElementVNode("input", {
    id: "include-help-text",
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $data.includeHelpText = $event;
    }),
    type: "checkbox"
  }, null, 512 /* NEED_PATCH */), [[_vModelCheckbox, $data.includeHelpText]]), _createTextVNode(" " + _toDisplayString($data.translate.includeQuestionHelpText), 1 /* TEXT */)])])])) : _createCommentVNode("v-if", true), $data.selectedQuestionnaires.length > 0 && !$data.loading ? (_openBlock(), _createBlock(_component_question_selection, {
    key: 2,
    ref: "questionSelection",
    questions: $data.questions,
    "is-gdpr": $options.isGDPR,
    questionnaires: $props.questionnaires,
    "is-answered-only": $data.isAnsweredOnly,
    "onChecked:answered": $options.setAnsweredOnly,
    onQuestionsSelected: $options.questionsSelected
  }, null, 8 /* PROPS */, ["questions", "is-gdpr", "questionnaires", "is-answered-only", "onChecked:answered", "onQuestionsSelected"])) : _createCommentVNode("v-if", true), $data.selectedQuestionnaires.length && $data.selectedQuestions.length ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(_component_risma_button, {
    text: $data.translate.createReport,
    type: "save",
    class: "text-right",
    onClick: $options.handleCreateReport
  }, null, 8 /* PROPS */, ["text", "onClick"])])) : _createCommentVNode("v-if", true)]);
}