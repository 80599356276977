import i18n from '@/i18n';
export var getTranslate = {
  DataTableColumnFilter: function DataTableColumnFilter() {
    return {
      filterBy: i18n.t('Filter by'),
      deselectAll: i18n.t('Deselect all'),
      filteringNotPossibleForThisColumn: i18n.t('Filtering not possible for this column'),
      listIsEmpty: i18n.t('List is empty')
    };
  }
};