export var RisksVisibleProps = [{
  key: 'title',
  text: 'Title',
  default: true
}, {
  key: 'description',
  text: 'Description',
  default: true
}, {
  key: 'status',
  text: 'Status'
}, {
  key: 'likelihood_score',
  text: 'Likelihood'
}, {
  key: 'organisationIds',
  text: 'Organisation',
  default: true
}, {
  key: 'tags',
  text: 'Tags',
  default: true
}];