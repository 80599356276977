import { HttpLegacy } from '@/api/index';
var cachedEtag = null;
function checkUpdate(next, to) {
  var url = '/risma2/modules/chanel.js';
  HttpLegacy.head(url).then(function (response) {
    if (mustRefresh(response.headers.etag)) {
      window.location.assign(to.fullPath);
      return;
    }
    cachedEtag = response.headers.etag;
    return next();
  });
}
function mustRefresh(newEtag) {
  return cachedEtag !== null && cachedEtag !== newEtag;
}
export var jsBundleGuard = function jsBundleGuard(to, from, next) {
  return checkUpdate(next, to);
};