import { getTranslate } from './translate';
import { searchReports } from '@/api/compliance/reports/nodes';
import { getAllByType } from '@/api/compliance/informationAssets';
import TasksList from '@/components/Molecules/Compliance/TasksList';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { IATitles } from '@/constants/compliance/ComplianceControllers';
export default {
  name: 'ComplianceGeneralList',
  introduction: 'use this component as list of tia nodes, lia nodes or processes in Overview page',
  components: {
    TasksList: TasksList,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      default: null,
      note: 'ID of current project'
    },
    assetType: {
      type: String,
      required: true,
      default: '',
      validator: function validator(asset) {
        return [AssetsTypes.PROCESSORS, AssetsTypes.LIA_NODES, AssetsTypes.TIA_NODES].includes(asset);
      },
      note: 'Defines list content'
    }
  },
  emits: ['error', 'created'],
  data: function data() {
    return {
      isLoading: true,
      iaList: [],
      translate: getTranslate['ComplianceGeneralList']()
    };
  },
  computed: {
    listTitle: function listTitle() {
      switch (this.assetType) {
        case AssetsTypes.PROCESSORS:
          return this.$trans(IATitles[AssetsTypes.PROCESSORS]);
        case AssetsTypes.LIA_NODES:
          return this.translate.liaNodes;
        case AssetsTypes.TIA_NODES:
          return this.translate.tiaNodes;
        default:
          return '';
      }
    },
    showCreateButton: function showCreateButton() {
      var typesToShowButton = [AssetsTypes.PROCESSORS];
      return typesToShowButton.includes(this.assetType);
    }
  },
  mounted: function mounted() {
    this.loadListByAssetType();
  },
  methods: {
    loadListByAssetType: function loadListByAssetType() {
      var _this = this;
      var promise = null;
      switch (this.assetType) {
        case AssetsTypes.PROCESSORS:
          promise = this.getIAProcessors();
          break;
        case AssetsTypes.LIA_NODES:
          promise = this.getSpecificNodes('?filters[liaNodes]=1');
          break;
        case AssetsTypes.TIA_NODES:
          promise = this.getSpecificNodes('?filters[tiaNodes]=1');
          break;
        default:
          promise = Promise.resolve();
      }
      this.isLoading = true;
      return promise.then(function () {
        return _this.isLoading = false;
      });
    },
    getSpecificNodes: function getSpecificNodes(query) {
      var _this2 = this;
      return searchReports(this.projectId, query).then(function (_ref) {
        var list = _ref.list;
        return _this2.iaList = list;
      });
    },
    getIAProcessors: function getIAProcessors() {
      var _this3 = this;
      return getAllByType(AssetsTypes.PROCESSORS, '?data[]=links').then(function (_ref2) {
        var list = _ref2.list;
        return _this3.iaList = list;
      });
    },
    handleCreate: function handleCreate(newItem) {
      this.loadListByAssetType();
      this.$emit('created', newItem);
    }
  }
};