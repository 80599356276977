import { Http, HttpLegacy } from '../index';
import { flushControlsCache } from '@/api/risma/data';
import { sortControlsByCpath } from '@/utils/sort';
import { handleRequestResponse, jsonToRismaFilterQueryString, generateDataQueryString, generateFiltersQueryString, objectToQueryString, throwError } from '../httpUtils';
export function getAll(params) {
  var url = '/controls/controls';
  if (params) {
    url += params;
  }
  return handleRequestResponse(Http.get(url));
}
export function getAllAsTree(params) {
  var url = '/controls/tree';
  if (params) {
    url += params;
  }
  return handleRequestResponse(Http.get(url));
}
export function getAllWithTagsFilter(tag) {
  var url = '/controls/controls?data[]=associations&filterTags[]=' + tag;
  return handleRequestResponse(Http.get(url));
}
export function getMyControls(params) {
  var url = '/controls/my';
  if (params) {
    url += params;
  }
  return handleRequestResponse(Http.get(url));
}
export function getControlsReports(params) {
  var url = '/controls/reports';
  if (params) {
    url += params;
  }
  return handleRequestResponse(Http.get(url));
}
export function getSliceControls(url) {
  return handleRequestResponse(Http.get(url));
}
export function getAllMotherControls(params) {
  var url = '/controls/mothercontrols';
  if (params) {
    url += params;
  }
  return handleRequestResponse(Http.get(url));
}
export function getControlById(id) {
  return handleRequestResponse(Http.get("/controls/control/".concat(id)));
}
export function getControlByCpathAndId(cpath, id) {
  return handleRequestResponse(Http.get("/controls/control/".concat(cpath, "?controlId=").concat(id)));
}
export function getControlChangelog(id) {
  return handleRequestResponse(Http.get("/activities/controls/changelog/".concat(id)));
}
export function getControlHistory(id) {
  return handleRequestResponse(Http.get("/activities/controls/history/".concat(id)));
}
export function createNewControl(data) {
  flushControlsCache();
  return handleRequestResponse(Http.post('/controls/control/new', data));
}
export function createNewMotherControl(data) {
  return handleRequestResponse(Http.post('/controls/mothercontrols', data));
}
export function patch(id, data) {
  flushControlsCache();
  return handleRequestResponse(Http.patch("/controls/control/".concat(id), data));
}
export function deleteControl(cpath) {
  flushControlsCache();
  return handleRequestResponse(Http.delete("/controls/control/".concat(cpath)));
}
export function getDataControls() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return handleRequestResponse(Http.get("/data/controls".concat(params)));
}
export function getAccounts() {
  return handleRequestResponse(Http.get('/data/accounts'));
}
export function getProcesses() {
  return handleRequestResponse(Http.get('/data/processes'));
}
export function getRiskRisks() {
  return handleRequestResponse(Http.get('/data/risks'));
}
export function getControlRisks() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return handleRequestResponse(Http.get("/controls/risks".concat(params)));
}
export function removeAttachment(attachmentId) {
  return handleRequestResponse(Http.delete("/controls/attachment/".concat(attachmentId)));
}
export function completeControlNew(cpath, id, data, cb) {
  var config = cb ? {
    onUploadProgress: cb
  } : cb;
  data.controlId = id;
  flushControlsCache();
  return handleRequestResponse(Http.patch("/controls/control/".concat(cpath, "/completion"), data, config));
}
export function skipControl(cpath, data) {
  flushControlsCache();
  return handleRequestResponse(Http.patch("/controls/control/".concat(cpath, "/skip"), data));
}
export function uploadFormDataFiles(cpath, id, data) {
  return handleRequestResponse(Http.post("/controls/control/".concat(cpath, "/addfile/").concat(id), data));
}
export function deleteFormDataFiles(cpath, controlId, fileId) {
  var data = {
    data: {
      fileId: fileId
    }
  };
  return handleRequestResponse(Http.delete("/controls/control/".concat(cpath, "/addfile/").concat(controlId), data));
}

// This must be preserved for the gap tab to work
export function create(data) {
  flushControlsCache();
  return handleRequestResponse(Http.post('/activities/controls', data));
}
export function getControlChangeLog(id) {
  return handleRequestResponse(Http.get("changelog/changelog/control/".concat(id)));
}
export function copy(id, withDaughters) {
  return Http.post("/controls/control/".concat(id, "/copy"), {
    withDaughters: withDaughters
  }).then(function (response) {
    return response;
  }) // We need full response object here
  .catch(function (err) {
    return throwError(err);
  });
}

/**
 * Calculates deadline
 *
 * @param deadline
 * @param deadlineDays
 * @param deadlineBeforeAfter
 * @param freq
 * @param count (default 3)
 * @returns {Promise<T | string>}
 */
export function getDeadlines(data) {
  var params = objectToQueryString(data);
  return handleRequestResponse(Http.get('controls/deadline?' + params));
}
export function getNextDeadline(data) {
  return handleRequestResponse(HttpLegacy.post('/controls/calc_deadline', data));
}
export function createCustomDeadline(data) {
  return handleRequestResponse(Http.post('/controls/deadline', data));
}
export function deleteCustomDeadline(deadlineId) {
  return handleRequestResponse(Http.delete('/controls/deadline/' + deadlineId));
}

/**
 * Will get a list of all controls based on cpath including all reviews and completed controls
 */
export function getAllControlsFromCpaths(cpaths, data) {
  var params = '?';
  cpaths.forEach(function (cpath) {
    params += 'filters[cpath][]=' + cpath + '&';
  });
  data.forEach(function (data) {
    params += 'data[]=' + data + '&';
  });
  return getControlsReports(params);
}
export function controlsReview(cpath, id, action, data) {
  data.controlId = id;
  return handleRequestResponse(Http.patch("/controls/control/".concat(cpath, "/review/").concat(action), data));
}

/**
 * Controls dashboard data
 *
 * @param data
 * @returns {Promise<T | string>}
 */
export function getStatusOverviewControl() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return handleRequestResponse(HttpLegacy({
    method: 'post',
    url: '/controls/dashboards/ajax',
    data: data
  }));
}

/**
 * Controls dashboard data (risma2 endpoint)
 *
 * @param data
 * @returns {Promise<T | string>}
 */
export function getStatusOverviewControlNew() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var dataQuery = jsonToRismaFilterQueryString(data);
  return handleRequestResponse(Http.get('/controls/dashboards/overview' + dataQuery));
}
export function getControlsDashboardCompletionData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var dataQuery = jsonToRismaFilterQueryString(data);
  return handleRequestResponse(Http.get('/controls/dashboards/completion' + dataQuery));
}
export function getControlsDashboardEfficiencyData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var dataQuery = jsonToRismaFilterQueryString(data);
  return handleRequestResponse(Http.get('/controls/dashboards/efficiency' + dataQuery));
}

/**
 * Set all controls recent.
 * Called from the Danger Zone
 *
 * @param data
 * @returns {Promise<AxiosResponse<any> | string>}
 */
export function setControlsRecent(data) {
  return handleRequestResponse(Http.post('/controls/setrecent', data));
}

/**
 * Get controls report data sorted by cpath.
 * Called from the Control Main Report page
 *
 * @param deadlines
 * @param showDeactivated
 * @param sortByCpath
 */
export var getControlReport = function getControlReport() {
  var dateOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var showDeactivated = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var sortByCpath = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var dateAfter = dateOptions.dateAfter,
    dateBefore = dateOptions.dateBefore,
    dateBeforeTime = dateOptions.dateBeforeTime,
    dateType = dateOptions.dateType;
  var dataParams = ['organisationIds', 'associations', 'linkedCompliances', 'dailyTeamIds', 'attachments', 'motherControl', 'reviews', 'completedFiles', 'customFields', 'links'];
  var query = '?' + generateDataQueryString(dataParams);
  var filterParams = {};
  if (dateAfter && dateBefore) {
    filterParams["".concat(dateType, "After")] = dateAfter;
    filterParams["".concat(dateType, "Before")] = dateBefore + '' + dateBeforeTime;
  }
  if (showDeactivated) {
    filterParams.excludeStates = ['deleted', 'mothercontrol'];
  }
  if (Object.keys(filterParams).length) {
    query += '&' + generateFiltersQueryString(filterParams);
  }
  return getControlsReports(query).then(function (_ref) {
    var list = _ref.list;
    return sortByCpath ? sortControlsByCpath(list) : list;
  });
};