import SingleChoice from '@/components/Molecules/Questionnaire/SingleChoice';
export default {
  name: 'SingleChoiceKitchen',
  components: {
    SingleChoice: SingleChoice
  },
  data: function data() {
    return {
      singleChoice: {
        options: [{
          id: 1,
          label: 'Yes'
        }, {
          id: 2,
          label: 'No'
        }],
        answers: {}
      }
    };
  },
  methods: {
    handleAnswer: function handleAnswer(answer) {
      console.log('Answer', answer); // eslint-disable-line
    }
  }
};