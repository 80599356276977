import { Colors } from '@/Colors';
export function prepareTitleWithTrafficLightData(item, linkTo) {
  var trafficLight = item.traffic_light === undefined ? item.trafficLight : item.traffic_light;
  var additionalCSS = trafficLight === 1 ? 'red ' : '';
  var title = item.title || item.name || '';
  return {
    color: Colors.getTrafficlightByNumber(trafficLight).color,
    url: linkTo,
    additionalCSS: additionalCSS,
    title: title
  };
}