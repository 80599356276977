import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7fd3e16c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "min-h-content text-center"
};
var _hoisted_2 = {
  class: "flex justify-between"
};
var _hoisted_3 = {
  key: 0,
  class: "min-h-40vh flex flex-col justify-center"
};
var _hoisted_4 = {
  key: 1,
  style: {
    "margin-bottom": "25vh"
  }
};
var _hoisted_5 = {
  key: 0,
  class: "min-h-40vh"
};
var _hoisted_6 = {
  key: 0,
  class: "mb-10"
};
var _hoisted_7 = {
  key: 2,
  class: "text-left"
};
var _hoisted_8 = {
  class: "lists"
};
var _hoisted_9 = {
  class: "mb-4"
};
var _hoisted_10 = {
  key: 0
};
var _hoisted_11 = {
  class: "mb-4"
};
var _hoisted_12 = {
  key: 0
};
var _hoisted_13 = {
  class: "mb-4"
};
var _hoisted_14 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_dataflow_modal = _resolveComponent("dataflow-modal");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_save_dataflow_as_image = _resolveComponent("save-dataflow-as-image");
  var _component_data_flow_row = _resolveComponent("data-flow-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showModal && $data.options ? (_openBlock(), _createBlock(_component_dataflow_modal, {
    key: 0,
    "project-id": $options.projectId,
    "node-id": $options.nodeId,
    stakeholders: $data.options.stakeholders,
    "data-types": $data.options.dataTypes,
    systems: $data.options.systems,
    "systems-title": $options.dataFlowSystemsTitle,
    onToggleModal: $options.toggleModal,
    onCreateFlow: _cache[0] || (_cache[0] = function ($event) {
      return $options.createFlow($event);
    })
  }, null, 8 /* PROPS */, ["project-id", "node-id", "stakeholders", "data-types", "systems", "systems-title", "onToggleModal"])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [!$data.loaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.loaded ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createElementVNode("div", _hoisted_2, [!$options.currentProject.locked ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "flex cursor-pointer text-left hover:opacity-75",
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.toggleModal && $options.toggleModal.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    height: "40",
    width: "40",
    icon: "plus"
  }), _createVNode(_component_risma_title, {
    title: $data.translate.addANewDataFlow
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), _createVNode(_component_save_dataflow_as_image, {
    "project-id": $options.projectId,
    "node-id": $options.nodeId,
    "is-dataflow-empty": $options.flowsGroupedByStakeholder.length === 0,
    class: "hover:opacity-75 ml-auto"
  }, null, 8 /* PROPS */, ["project-id", "node-id", "is-dataflow-empty"])]), $data.flows.length === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.youHaveNoDataFlowsInThisProcess
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.clickTheQuotAndQuot,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $data.flows.length > 0 && $data.options ? (_openBlock(), _createElementBlock("div", _hoisted_4, [$data.flows.length === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.youHaveNoDataFlowsInThisProcess
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.clickTheQuotAndQuot,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [$options.flowsGroupedByStakeholder.length > 0 && $data.options ? (_openBlock(), _createElementBlock("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.flowsGroupedByStakeholder, function (flow, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "data-lane"
    }, [_createVNode(_component_data_flow_row, {
      flow: flow,
      systems: $data.options.systems,
      stakeholders: $data.options.stakeholders,
      "data-types": $data.options.dataTypes,
      "project-id": $options.projectId,
      "node-id": $options.nodeId,
      "is-project-locked": !!$options.currentProject.locked,
      onAddDataflowToStakeholder: $options.addDataFlowToStakeholder,
      onDeleteStakeholder: function onDeleteStakeholder($event) {
        return $options.deleteFlow(index);
      },
      onDeleteDataFlow: function onDeleteDataFlow($event) {
        return $options.deleteFlow(index, $event);
      }
    }, null, 8 /* PROPS */, ["flow", "systems", "stakeholders", "data-types", "project-id", "node-id", "is-project-locked", "onAddDataflowToStakeholder", "onDeleteStakeholder", "onDeleteDataFlow"])]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)])])) : _createCommentVNode("v-if", true), $data.options ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.thisInformationHasNotYetBeenAddedToAnyDat + ':',
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $options.dataFlowStakeholdersTitle + ':',
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.missingStakeholders, function (stakeholder, index) {
    return _openBlock(), _createElementBlock("span", {
      key: index,
      class: "text-sm text-orange-50"
    }, [index !== 0 ? (_openBlock(), _createElementBlock("span", _hoisted_10, ", ")) : _createCommentVNode("v-if", true), _createTextVNode(" " + _toDisplayString(stakeholder.text), 1 /* TEXT */)]);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_risma_title, {
    title: $data.translate.dataTypes + ':',
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.missingDataTypes, function (dataType, index) {
    return _openBlock(), _createElementBlock("span", {
      key: index,
      class: "text-sm text-orange-50"
    }, [index !== 0 ? (_openBlock(), _createElementBlock("span", _hoisted_12, ", ")) : _createCommentVNode("v-if", true), _createTextVNode(" " + _toDisplayString(dataType.text), 1 /* TEXT */)]);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_risma_title, {
    title: $options.dataFlowSystemsTitle + ':',
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.missingSystems, function (system, index) {
    return _openBlock(), _createElementBlock("span", {
      key: index,
      class: "text-sm text-orange-50"
    }, [index !== 0 ? (_openBlock(), _createElementBlock("span", _hoisted_14, ", ")) : _createCommentVNode("v-if", true), _createTextVNode(" " + _toDisplayString(system.text), 1 /* TEXT */)]);
  }), 128 /* KEYED_FRAGMENT */))])])])])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)])]);
}