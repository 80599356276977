import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Notification from '@/components/Molecules/Notification';
export default {
  name: 'MfaLogin',
  components: {
    InputField: InputField,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    Notification: Notification
  },
  token: '<mfa-login />',
  props: {
    error: {
      type: String,
      required: true
    }
  },
  emits: ['login'],
  data: function data() {
    return {
      code: '',
      notificationType: 'error',
      translate: getTranslate['MfaLogin']()
    };
  },
  methods: {
    onMfaLogin: function onMfaLogin() {
      if (!this.code) {
        return;
      }
      this.$emit('login', this.code);
    }
  }
};