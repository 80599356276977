import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { Colors } from '@/Colors';
import { getTranslate } from './translate';
export default {
  name: 'LinkedCompany',
  description: 'Component contains a link to unlinking of the company and full info about the linked company',
  components: {
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink,
    FeatherIcon: FeatherIcon
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    company: {
      type: Object,
      required: true,
      deafult: function deafult() {
        return {};
      }
    }
  },
  emits: ['unlink', 'edit:company', 'update:company'],
  data: function data() {
    return {
      translate: getTranslate['LinkedCompany']()
    };
  },
  computed: {
    companyUrl: function companyUrl() {
      if (!this.company) return '';
      return "/company/".concat(this.company.id, "/description");
    },
    companyTrafficLight: function companyTrafficLight() {
      return Colors.getCompanyTrafficlightByNumber(this.company.vatStatus);
    }
  }
};