import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4f769fd5"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "mb-10 w-full"
};
var _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_edit_project_modal = _resolveComponent("edit-project-modal");
  var _component_notification_pop_up = _resolveComponent("notification-pop-up");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showEditModal ? (_openBlock(), _createBlock(_component_edit_project_modal, {
    key: 0,
    project: $data.editedProject,
    solutions: _ctx.enabledSolutions,
    "activity-types": _ctx.activityTypes,
    onAccept: $options.onProjectChange,
    onDismiss: $options.toggleEditModal
  }, null, 8 /* PROPS */, ["project", "solutions", "activity-types", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), _ctx.notificationData ? (_openBlock(), _createBlock(_component_notification_pop_up, {
    key: 1,
    type: "success",
    onDismiss: _ctx.dismissNotificationPopUp
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString(_ctx.notificationData.title), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.riskProjects,
    class: "mb-3",
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [$data.riskProjects.length ? (_openBlock(), _createBlock(_component_static_table, {
        key: 0,
        columns: $options.columns,
        dataset: $options.dataset,
        theme: "zebra",
        "is-headers-truncated": false
      }, {
        edit: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [!$options.isUserLocked ? (_openBlock(), _createBlock(_component_feather_icon, {
            key: 0,
            icon: "edit",
            class: "cursor-pointer stroke-2 stroke-blue",
            onClick: function onClick($event) {
              return $options.onEditIconClick(slotData);
            }
          }, null, 8 /* PROPS */, ["onClick"])) : (_openBlock(), _createElementBlock("span", _hoisted_2))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["columns", "dataset"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })]);
}