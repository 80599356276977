import { getInformationAssetsTitle } from '@/utils/InformationAssets';
var DataFlowTypes = function DataFlowTypes($trans) {
  return [{
    key: 'dataFlowStakeholder',
    title: $trans('Stakeholders')
  }, {
    key: 'dataFlowDataType',
    title: $trans('Data type')
  }, {
    key: 'dataFlowLegalBasis',
    title: $trans('Legal basis')
  }, {
    key: 'dataFlowSystem',
    title: getInformationAssetsTitle()
  }];
};
export var DataFlowOptionTypes = {
  QUESTION_OPTION: 'question_option',
  DATAPROCESSOR_PROCESSOR: 'dataprocessor_processor',
  DATAPROCESSOR_CONTROLLER: 'dataprocessor_controller',
  DATAPROCESSOR_SYSTEM: 'dataprocessor_system'
};
export default DataFlowTypes;