import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-42d3b0b4"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["title", "innerHTML"];
var _hoisted_2 = ["title", "innerHTML"];
var _hoisted_3 = ["title", "innerHTML"];
var _hoisted_4 = ["colspan", "title"];
var _hoisted_5 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("tbody", {
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('click', $event);
    })
  }, [_createElementVNode("tr", {
    class: _normalizeClass({
      'empty': $options.isEmpty
    }),
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.toggleChildren && $options.toggleChildren.apply($options, arguments);
    })
  }, [$options.isHasChildren ? (_openBlock(), _createElementBlock("td", {
    key: 0,
    class: _normalizeClass(['flex flex-row flex-nowrap', {
      hidden: $options.isCellHidden(0),
      titleCol: $options.isTitleKey($options.firstColData.key)
    }])
  }, [$props.isChildrenIconShown ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: $options.showChildren ? 'chevron-up' : 'chevron-down',
    class: "text-blue-750 cursor-pointer stroke-3"
  }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, $options.firstColData.key, {
    slotData: $options.firstColData.value
  }, function () {
    return [_createElementVNode("div", null, [_createElementVNode("span", {
      title: $props.additionalRowClassesText || $options.firstColData.key,
      innerHTML: $options.firstColData.value
    }, null, 8 /* PROPS */, _hoisted_1)])];
  }, true)], 2 /* CLASS */)) : _createCommentVNode("v-if", true), !$options.isHasChildren ? (_openBlock(), _createElementBlock("td", {
    key: 1,
    style: _normalizeStyle($options.getCellStyles($options.firstColData.key)),
    class: _normalizeClass({
      hidden: $options.isCellHidden(0),
      titleCol: $options.isTitleKey($options.firstColData.key)
    })
  }, [_renderSlot(_ctx.$slots, $options.firstColData.key, {
    slotData: $options.firstColData.value
  }, function () {
    return [_createElementVNode("div", null, [_createElementVNode("span", {
      title: $props.additionalRowClassesText || $options.firstColData.key,
      innerHTML: $options.firstColData.value
    }, null, 8 /* PROPS */, _hoisted_2)])];
  }, true)], 6 /* CLASS, STYLE */)) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item, function (val, key, index) {
    return _openBlock(), _createElementBlock(_Fragment, null, [key !== 'children' && index !== 0 ? (_openBlock(), _createElementBlock("td", {
      key: index,
      style: _normalizeStyle($options.getCellStyles(key)),
      class: _normalizeClass({
        hidden: $options.isCellHidden(index),
        titleCol: $options.isTitleKey(key)
      })
    }, [_renderSlot(_ctx.$slots, key, {
      slotData: val
    }, function () {
      return [_createElementVNode("div", null, [_createElementVNode("span", {
        title: $options.getHoverTitle(key, val),
        innerHTML: val
      }, null, 8 /* PROPS */, _hoisted_3)])];
    }, true)], 6 /* CLASS, STYLE */)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
  }), 256 /* UNKEYED_FRAGMENT */))], 2 /* CLASS */), $options.showChildren ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList($props.item.children, function (child, index) {
    return _openBlock(), _createElementBlock("tr", {
      key: index,
      class: "child"
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(child, function (v, k, chIndex) {
      return _openBlock(), _createElementBlock("td", {
        key: k,
        colspan: Object.keys(child).length === 1 ? $options.columnsNumber : 0,
        style: _normalizeStyle($options.getCellStyles(k)),
        class: _normalizeClass({
          hidden: $options.isCellHidden(chIndex)
        }),
        title: $options.getHoverTitle(k, v)
      }, [_renderSlot(_ctx.$slots, k, {
        slotData: v
      }, function () {
        return [_createElementVNode("div", {
          innerHTML: v
        }, null, 8 /* PROPS */, _hoisted_5)];
      }, true)], 14 /* CLASS, STYLE, PROPS */, _hoisted_4);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true)]);
}