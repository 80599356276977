import QuestionCreation from '@/components/Organisms/Compliance/QuestionCreation';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
export default {
  name: '',
  components: {
    QuestionCreation: QuestionCreation,
    RismaButton: RismaButton
  },
  data: function data() {
    return {
      editQuestionModalShown: false
    };
  },
  methods: {
    toggleModal: function toggleModal() {
      this.editQuestionModalShown = !this.editQuestionModalShown;
    }
  }
};