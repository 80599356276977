export var deletionControlMixin = {
  data: function data() {
    return {
      isControlDeletedSuccess: false
    };
  },
  methods: {
    handleDeletedControlSuccess: function handleDeletedControlSuccess() {
      this.isControlDeletedSuccess = true;
      var query = Object.assign({}, this.$route.query);
      delete query.successDeletion;
      this.$router.replace({
        query: query
      });
    }
  }
};