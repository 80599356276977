import { Http } from '../index';
import * as HttpUtils from '../httpUtils';
export function createQuestion(data) {
  return HttpUtils.handleRequestResponse(Http.post('/compliance/questions', data));
}
export function editQuestion(data) {
  return HttpUtils.handleRequestResponse(Http.patch('/compliance/questions/' + data.id, data));
}
export function deleteQuestion(id) {
  var system = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var answers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return HttpUtils.handleRequestResponse(Http.delete('/compliance/questions/' + id + '?system=' + (system ? '1' : '0') + '&answers=' + (answers ? '1' : '0')));
}
export function addOption(id, label) {
  return HttpUtils.handleRequestResponse(Http.post('compliance/questions/' + id + '/options', {
    'label': label
  }));
}

/**
 *
 * @param id
 * @param questiontext
 * @param hidden
 * @returns {boolean}
 */
export function updateQuestion(id, questiontext, hidden) {
  var data = {
    'question': questiontext,
    'hidden': hidden
  };
  return Http.patch('/compliance/questions/' + id, data).then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}
export function getAllForNode(projectId, nodeId) {
  return HttpUtils.handleRequestResponse(Http.get("compliance/projects/".concat(projectId, "/nodes/").concat(nodeId, "/questionanswers")));
}