function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getTranslate } from './translate';
import draggable from 'vuedraggable';
import CollapsiblePanel from '@/components/Molecules/CollapsiblePanel/CollapsiblePanel';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
import DragAndDropIcon from '@/components/Atoms/FeatherIcon/DragAndDropIcon';
export default {
  name: 'OptionalFieldsTable',
  components: {
    draggable: draggable,
    DragAndDropIcon: DragAndDropIcon,
    CollapsiblePanel: CollapsiblePanel,
    RismaTitle: RismaTitle,
    RadioButtons: RadioButtons
  },
  props: {
    modelValue: {
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of active optional fields'
    },
    optionalFields: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['update:modelValue'],
  data: function data() {
    return {
      fieldsStatus: {
        dontShow: 'dont-show',
        active: 'active'
      },
      translate: getTranslate['OptionalFieldsTable']()
    };
  },
  computed: {
    optionalFieldsOptions: function optionalFieldsOptions() {
      return [{
        value: this.fieldsStatus.dontShow
      }, {
        value: this.fieldsStatus.active
      }];
    },
    radioBtnValuesMap: function radioBtnValuesMap() {
      var _this = this;
      return this.optionalFields.reduce(function (acc, current) {
        if (_this.modelValue.indexOf(current.value) === -1) {
          acc[current.value] = _this.fieldsStatus.dontShow;
        } else {
          acc[current.value] = _this.fieldsStatus.active;
        }
        return acc;
      }, {});
    }
  },
  methods: {
    onChangeRadio: function onChangeRadio(event, optionFieldName) {
      var _this2 = this;
      if (event === this.radioBtnValuesMap[optionFieldName]) return;
      this.radioBtnValuesMap[optionFieldName] = event;
      var activeFields = _toConsumableArray(this.modelValue);
      if (event === this.fieldsStatus.dontShow) {
        var optionIndex = activeFields.indexOf(optionFieldName);
        activeFields.splice(optionIndex, 1);
      } else {
        activeFields = this.optionalFields.reduce(function (acc, current) {
          if (_this2.radioBtnValuesMap[current.value] === _this2.fieldsStatus.active) {
            acc.push(current.value);
          }
          return acc;
        }, []);
      }
      this.$emit('update:modelValue', activeFields);
    },
    moveItem: function moveItem() {
      var _this3 = this;
      var result = [];
      this.optionalFields.forEach(function (item) {
        if (_this3.modelValue.indexOf(item.value) !== -1) {
          result.push(item.value);
        }
      });
      this.$emit('update:modelValue', result);
    }
  }
};