import i18n from '@/i18n';
export var getTranslate = {
  ActionChangeDatesModal: function ActionChangeDatesModal() {
    return {
      moveActionAndUnderlyingDates: i18n.t('Move action and underlying dates'),
      clickThe: i18n.t('Click the'),
      toChangeDatesIndependently: i18n.t('to change dates independently.'),
      toChangeDatesSynchronously: i18n.t('to change dates synchronously.'),
      startDateCannotBeAfterDeadline: i18n.t('Start date cannot be after deadline'),
      original: i18n.t('Original'),
      start: i18n.t('Start'),
      deadline: i18n.t('Deadline'),
      new: i18n.t('New'),
      moveActions: i18n.t('Move actions'),
      byPressingMoveInitiativesYouWillMoveStartAndDeadline: i18n.t('By pressing \'Move initiatives\' you will move the start and deadline of this and'),
      underlyingInitiatives: i18n.t('underlying initiatives'),
      byPressingMoveInitiativesYouWillMoveStart: i18n.t('By pressing \'Move initiatives\' you will move the start of this and'),
      youWillAlsoMoveTheDeadline: i18n.t('You will also move the deadline'),
      daysForward: i18n.t('days forward'),
      daysBackward: i18n.t('days backward')
    };
  }
};