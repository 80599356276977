import InputFieldSelect from '@/components/Atoms/Inputs/InputFieldSelect';
export default {
  name: '',
  components: {
    InputFieldSelect: InputFieldSelect
  },
  data: function data() {
    return {
      mockData: [{
        options: [{
          value: 1,
          label: 'DKK'
        }],
        selectedValue: '',
        selectedUnit: '',
        defaultPlaceholder: 'Input text here'
      }, {
        options: [{
          value: 1,
          label: 'Euro'
        }, {
          value: 2,
          label: 'Dollar'
        }],
        selectedValue: '',
        selectedUnit: '',
        defaultPlaceholder: 'Input number here',
        type: 'number',
        defaultPlaceholderSelect: 'Choose'
      }, {
        options: [{
          value: 'DKK',
          label: 'DKK'
        }, {
          value: 'Euro',
          label: 'Euro'
        }, {
          value: 'Dollar',
          label: 'Dollar'
        }],
        selectedValue: '',
        selectedUnit: '',
        defaultPlaceholder: 'Input number here',
        type: 'number',
        defaultPlaceholderSelect: 'Choose'
      }]
    };
  }
};