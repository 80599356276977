import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_pie_chart = _resolveComponent("pie-chart");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_pie_chart, {
    "data-provider": _ctx.charts.pie.data,
    colors: _ctx.charts.colors,
    "clickable-legend": true,
    "value-field": "column-1",
    "title-field": "category"
  }, null, 8 /* PROPS */, ["data-provider", "colors"])]);
}