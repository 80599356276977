import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-017263b0"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "risk2-reports-tabs"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tab_item = _resolveComponent("tab-item");
  var _component_tabs = _resolveComponent("tabs");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$options.isERM ? (_openBlock(), _createBlock(_component_tabs, {
    key: 0
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_tab_item, {
        link: "".concat($options.baseLink, "risks")
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.risks), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["link"]), _createVNode(_component_tab_item, {
        link: "".concat($options.baseLink, "threats")
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.threats), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["link"]), _createVNode(_component_tab_item, {
        link: "".concat($options.baseLink, "vulnerabilities")
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.vulnerabilities), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["link"])];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass({
      'risk2-reports-entry-content': !$options.isERM
    })
  }, [_createVNode(_component_router_view, {
    "project-id": $props.projectId
  }, null, 8 /* PROPS */, ["project-id"])], 2 /* CLASS */)]);
}