import AttachmentSection from '@/components/Molecules/AttachmentSection/AttachmentSection';
export default {
  name: '',
  components: {
    AttachmentSection: AttachmentSection
  },
  methods: {
    handlerAttachSection: function handlerAttachSection(files) {
      console.log('handlerAttachSection', files); // eslint-disable-line
    }
  }
};