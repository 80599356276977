import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  class: "my-4"
};
var _hoisted_2 = {
  class: "carousel flex"
};
var _hoisted_3 = {
  class: "flex items-center"
};
var _hoisted_4 = {
  class: "m-auto w-11/12 overflow-hidden"
};
var _hoisted_5 = {
  class: "flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_feather_icon, {
    icon: "chevron-left",
    width: "60",
    height: "60",
    class: _normalizeClass(['mr-2 text-blue-750 arrow-left', {
      'opacity-0 cursor-auto': $options.atHeadOfList
    }, {
      'opacity-1 cursor-pointer': !$options.atHeadOfList
    }]),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.moveCarousel(false);
    })
  }, null, 8 /* PROPS */, ["class"])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("ul", {
    class: "flex transition-transform duration-500 ease-in-out",
    style: _normalizeStyle({
      'transform': "translate(".concat($data.currentOffset, "px, 0)")
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, function (item, index) {
    return _openBlock(), _createElementBlock("li", {
      key: "carousel-".concat(index),
      class: "relative w-1/4 flex-auto0"
    }, [_renderSlot(_ctx.$slots, "default", {
      slide: item,
      class: "h-full"
    })]);
  }), 128 /* KEYED_FRAGMENT */))], 4 /* STYLE */)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_feather_icon, {
    icon: "chevron-right",
    width: "60",
    height: "60",
    class: _normalizeClass(['mr-2 text-blue-750 arrow-rigth', {
      'opacity-0 cursor-auto': $options.atEndOfList
    }, {
      'opacity-1 cursor-pointer': !$options.atEndOfList
    }]),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.moveCarousel(true);
    })
  }, null, 8 /* PROPS */, ["class"])])])]);
}