import { Colors } from '@/Colors';
import i18n from '@/i18n';
import { TableCellsTypes } from '@/constants/TableCellsTypes';
import { removeHtmlTags } from '@/utils/Utils';
import { capitalize } from '@/utils';
export function getCellTextContent(cell, type) {
  var _cell$content;
  var cellContent = (_cell$content = cell.content) !== null && _cell$content !== void 0 ? _cell$content : '';
  switch (type) {
    case TableCellsTypes.USERS_RACI:
      if (!cellContent.length) return '';
      return cellContent.map(function (item) {
        return item.initials;
      }).join(', ');
    case TableCellsTypes.TRAFFIC_LIGHT:
      if (!cellContent) {
        return '';
      }
      if (cell.color) {
        return i18n.t(Colors.getTrafficLabelByColor(cell.color));
      }
      return i18n.t(capitalize(Colors.getTrafficlightByNumber(cellContent).label));
    case TableCellsTypes.BOOL:
    case TableCellsTypes.STAR:
      return cellContent ? i18n.t('Yes') : i18n.t('No');
    case TableCellsTypes.HTML:
      return removeHtmlTags(cellContent);
    case TableCellsTypes.LINKS:
    case TableCellsTypes.ATTACHMENTS:
      return cellContent.map(function (item) {
        return item.title;
      }).join(', ');
    case TableCellsTypes.LIST:
      return cellContent.join(', ');
    case TableCellsTypes.DOCUMENT:
      return 'Document type: ' + cell.docType;
    case TableCellsTypes.TITLE_TEXT:
    case TableCellsTypes.DEADLINE_DATE:
    case TableCellsTypes.DATE:
    default:
      return cellContent + '';
  }
}