import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function uploadNewQuestionnaire(files) {
  return handleRequestResponse(Http.post('/rismaAdmin/questionnaire', files));
}
export function getQuestionnaireTypes() {
  return Http.get('/compliance/questionnairetype');
}
export function getQuestionTypes() {
  return Http.get('/compliance/questiontype');
}