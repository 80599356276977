import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_dpm_send_questionnaire_modal = _resolveComponent("dpm-send-questionnaire-modal");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [$data.showQuestionnaireModal ? (_openBlock(), _createBlock(_component_dpm_send_questionnaire_modal, {
    key: 0,
    ref: "DpmSendQuestionnaireModal",
    "audit-questionnaires": $data.newProcesses,
    onUpdate: $options.getData,
    onDismiss: $options.dismissQuestionnaireModal
  }, null, 8 /* PROPS */, ["audit-questionnaires", "onUpdate", "onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_button, {
    text: "Show Modal",
    onClick: $options.showModal
  }, null, 8 /* PROPS */, ["onClick"])]);
}