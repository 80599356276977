import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DateFormats } from '@/constants/DateFormats';
dayjs.extend(customParseFormat);
export function isDateAfter(dateA, dateB) {
  return dayjs(dateA).isAfter(dateB);
}
export function isDateBefore(dateA, dateB) {
  return dayjs(dateA).isBefore(dateB);
}
export function isDateValid(date, format, isStrictMode) {
  return dayjs(date, format, isStrictMode).isValid();
}
export function getUnixTime(date) {
  return dayjs(date).unix();
}
export function getLocalUnixTimestamp(date) {
  return dayjs.utc(date, DateFormats.DATE_TIME_FORMAT_FULL).local().unix();
}