var timeout;
export default {
  name: 'FreeTextChoice',
  introduction: 'Simple question with text area.',
  description: 'Simple question with text area. The `freeText` is required prop.',
  token: '<free-text-choice free-text="String" @answered="fn" />',
  props: {
    freeText: {
      type: String,
      required: true
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
      note: 'is be read-only'
    }
  },
  emits: ['forceUpdate', 'answered'],
  data: function data() {
    return {
      text: null,
      translate: {
        typeHere: this.$trans('Type here')
      }
    };
  },
  computed: {
    placeholder: function placeholder() {
      return this.readOnly ? '' : "".concat(this.translate.typeHere, "...");
    }
  },
  mounted: function mounted() {
    this.text = this.freeText;
  },
  methods: {
    emitAnswered: function emitAnswered() {
      var trimmed = this.text.trim();
      if (trimmed !== this.freeText) {
        this.$emit('answered', trimmed);
      }
    },
    setFreeText: function setFreeText(event) {
      var _this = this;
      if (this.readOnly) return;
      this.text = event.target.value;
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        _this.emitAnswered();
      }, 400);
    }
  }
};