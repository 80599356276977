function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { capitalize } from '@/utils';
import VerticalMenu from '@/components/Molecules/VerticalMenu/VerticalMenu';
import { IATypeViews } from '@/constants/compliance/InformationAssetsViews';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export default {
  name: 'InformationAssetsWrapper',
  components: {
    VerticalMenu: VerticalMenu
  },
  props: {
    onlyMy: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      isLoaded: false,
      translate: getTranslate['InformationAssetsWrapper']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'complianceIACustomActivityTypes'
  })), {}, {
    menuItems: function menuItems() {
      var baseUrl = '/compliance/informationassets';
      var baseOnlyMyUrl = '/compliance/informationassets/my';
      var pathName = IATypeViews.OVERVIEW;
      var menuItems = [{
        title: this.translate.overview,
        path: "".concat(baseUrl, "/type/all")
      }, {
        title: this.translate.my + ' ' + (this.getSettingValue('feature.information_assets_name') || 'Assets'),
        path: "".concat(baseOnlyMyUrl, "/type")
      }];
      if (this.getSettingValue('feature.enable_data_controllers') == undefined || this.getSettingValue('feature.enable_data_controllers')) {
        menuItems.push({
          title: this.translate.controllers,
          path: {
            name: pathName,
            params: {
              typeId: AssetsTypes.CONTROLLERS
            }
          }
        });
      }
      if (this.getSettingValue('feature.enable_data_processors') == undefined || this.getSettingValue('feature.enable_data_processors')) {
        menuItems.push({
          title: this.translate.processors,
          path: {
            name: pathName,
            params: {
              typeId: AssetsTypes.PROCESSORS
            }
          }
        });
      }
      if (this.getSettingValue('feature.enable_systems') == undefined || this.getSettingValue('feature.enable_systems')) {
        menuItems.push({
          title: this.translate.systems,
          path: {
            name: pathName,
            params: {
              typeId: AssetsTypes.SYSTEMS
            }
          }
        });
      }
      menuItems = menuItems.concat(this.activityTypes.map(function (item) {
        return {
          title: capitalize(item.label),
          path: {
            name: pathName,
            params: {
              typeId: item.id
            }
          }
        };
      }));
      return menuItems;
    }
  })
};