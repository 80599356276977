import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "pl-1 align-middle"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tab_item = _resolveComponent("tab-item");
  var _component_tabs = _resolveComponent("tabs");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_tabs, null, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.tabs, function (tab, index) {
        return _openBlock(), _createBlock(_component_tab_item, {
          key: index,
          link: tab.url,
          selected: tab.selected,
          disabled: tab.disabled
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("span", _hoisted_2, _toDisplayString(tab.label), 1 /* TEXT */)];
          }),

          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["link", "selected", "disabled"]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),

    _: 1 /* STABLE */
  }), _createVNode(_component_router_view)]);
}