import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "flex justify-between items-center"
};
var _hoisted_2 = {
  class: "flex mb-2"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  key: 1,
  class: "w-full"
};
var _hoisted_6 = {
  class: "border-t border-b border-gray-450"
};
var _hoisted_7 = {
  class: "px-2 py-1 text-left"
};
var _hoisted_8 = {
  class: "w-215px px-2 py-1"
};
var _hoisted_9 = {
  class: "w-215px px-2 py-1"
};
var _hoisted_10 = {
  class: "w-215px px-2 py-1"
};
var _hoisted_11 = {
  class: "px-2 py-1"
};
var _hoisted_12 = {
  class: "px-2 py-1 text-center"
};
var _hoisted_13 = {
  class: "px-2 py-1 text-center"
};
var _hoisted_14 = {
  class: "px-2 py-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification_pop_up = _resolveComponent("notification-pop-up");
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_modal = _resolveComponent("modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.notifications, function (notification, index) {
        return _openBlock(), _createBlock(_component_notification_pop_up, {
          key: notification,
          type: notification.notificationType,
          onDismiss: function onDismiss($event) {
            return $options.dismissNotification(index);
          }
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("div", null, _toDisplayString(notification.notificationText), 1 /* TEXT */)];
          }),

          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["type", "onDismiss"]);
      }), 128 /* KEYED_FRAGMENT */)), $data.showCreateModal ? (_openBlock(), _createBlock(_component_modal, {
        key: 0,
        header: $data.translate.createProject,
        "show-dismiss-button": false,
        "show-ok-button": false,
        "overflow-visible": true,
        onDismiss: $options.dismissCreateModal
      }, {
        body: _withCtx(function () {
          return [_createVNode(_component_input_field, {
            modelValue: $data.projectName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $data.projectName = $event;
            }),
            title: $data.translate.projectName,
            type: "text",
            class: "mb-3"
          }, null, 8 /* PROPS */, ["modelValue", "title"]), _createVNode(_component_single_select, {
            modelValue: $data.projectType,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return $data.projectType = $event;
            }),
            title: $data.translate.projectType,
            "un-sorted": true,
            options: $data.projectTypes,
            placeholder: $data.translate.selectProjectType,
            class: "mb-3"
          }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"]), _createVNode(_component_risma_button, {
            type: "save",
            text: $data.translate.save,
            disabled: !$data.projectName.length || $data.projectType === null,
            class: "mt-5 ml-auto",
            onClick: $options.createRiskProject
          }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["header", "onDismiss"])) : _createCommentVNode("v-if", true), $data.showDeletePeriodModal ? (_openBlock(), _createBlock(_component_modal, {
        key: 1,
        header: $data.translate.areYouSureYouWantToDeleteThisPeriod,
        "button-ok-text": $data.translate.yes,
        "button-dismiss-text": $data.translate.cancel,
        onAccept: $options.handleDeletePeriod,
        onDismiss: _cache[2] || (_cache[2] = function ($event) {
          return $data.showDeletePeriodModal = false;
        })
      }, null, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        title: $data.translate.riskProjects,
        class: "mb-4"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_button, {
        type: "save",
        text: $data.translate.createProject,
        onClick: _cache[3] || (_cache[3] = function ($event) {
          return $data.showCreateModal = true;
        })
      }, null, 8 /* PROPS */, ["text"])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.riskProjects, function (project) {
        var _project$projectPerio;
        return _openBlock(), _createElementBlock("div", {
          key: project.id,
          class: "mb-8"
        }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
          title: project.title,
          type: "medium",
          class: "mr-2"
        }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
          class: _normalizeClass(["underline cursor-pointer", project.enabled ? 'text-red-50' : '']),
          onClick: function onClick($event) {
            return $options.toggleProject(project.id, !project.enabled);
          }
        }, _toDisplayString(project.enabled ? $data.translate.disable : $data.translate.enable), 11 /* TEXT, CLASS, PROPS */, _hoisted_3)]), !((_project$projectPerio = project.projectPeriods) !== null && _project$projectPerio !== void 0 && _project$projectPerio.length) ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($data.translate.noPeriods), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("table", _hoisted_5, [_createElementVNode("thead", null, [_createElementVNode("tr", _hoisted_6, [_createElementVNode("th", _hoisted_7, _toDisplayString($data.translate.period), 1 /* TEXT */), _createElementVNode("th", _hoisted_8, _toDisplayString($data.translate.status), 1 /* TEXT */), _createElementVNode("th", _hoisted_9, _toDisplayString($data.translate.riskCount), 1 /* TEXT */), _createElementVNode("th", _hoisted_10, _toDisplayString($data.translate.delete), 1 /* TEXT */)])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(project.projectPeriods, function (period) {
          return _openBlock(), _createElementBlock("tr", {
            key: period.id,
            class: "border-t border-b border-gray-450"
          }, [_createElementVNode("td", _hoisted_11, _toDisplayString(period.title || period.version), 1 /* TEXT */), _createElementVNode("td", _hoisted_12, _toDisplayString($options.statusToText(project.type, period.status)), 1 /* TEXT */), _createElementVNode("td", _hoisted_13, _toDisplayString(period.riskCount), 1 /* TEXT */), _createElementVNode("td", _hoisted_14, [period.status !== 1 ? (_openBlock(), _createBlock(_component_feather_icon, {
            key: 0,
            icon: "x",
            color: "red",
            class: "mx-auto cursor-pointer",
            onClick: function onClick($event) {
              return $options.onDeletePeriod(period.id, project);
            }
          }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)])]);
        }), 128 /* KEYED_FRAGMENT */))])]))]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),

    _: 1 /* STABLE */
  });
}