import { ReportTypes as reportTypes } from '@/constants/reports';
export var getReportTypeByURL = function getReportTypeByURL(reportUrl) {
  if (reportUrl.indexOf('/controls/') !== -1 || reportUrl.indexOf('/controls2/') !== -1) {
    return 'controls';
  }
  if (reportUrl.indexOf('/risk/') !== -1 || reportUrl.indexOf('/risk2/') !== -1) {
    return 'risks';
  }
  if (reportUrl.indexOf('/actions/') !== -1 || reportUrl.indexOf('/execution/') !== -1) {
    return 'actions';
  }
  if (reportUrl.indexOf('/contracts/') !== -1) {
    return 'contracts';
  }

  //compliance and process library have /compliance/ in url
  if (reportUrl.indexOf('/compliance/reports/') !== -1) {
    return 'compliance';
  }
  if (reportUrl.indexOf('/incident/') !== -1) {
    return 'incidents';
  }
  if (reportUrl.indexOf('/processtree/') !== -1) {
    return 'processlibrary';
  }
  return '';
};
export var typeToAccessProperty = function typeToAccessProperty(type) {
  switch (type) {
    case reportTypes.CONTROLS_DATA:
    case reportTypes.CONTROLS:
      return 'level_controls';
    case reportTypes.CONTRACTS:
      return 'level_contracts';
    case reportTypes.ACTIONS:
      return 'level_execution';
    case reportTypes.INCIDENTS:
      return 'level_incidents';
    default:
      return '';
  }
};
export var getReportOptionsFromQuery = function getReportOptionsFromQuery() {
  var _queryValue$query;
  var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var queryValue = query.query ? JSON.parse(query.query) : {};
  return ((_queryValue$query = queryValue.query) === null || _queryValue$query === void 0 ? void 0 : _queryValue$query.reportOptions) || null;
};