import { calcComplianceRights, calcRiskRights, calcExecutionRights, calcControlsRights, calcIncidentsRights, calcContractRights, checkForComplianceProjects } from '@/menus/quicknav';
import { ObjectTypes, ObjectTypeToUserField } from '@/constants/ObjectTypes';
export var buildNavBar = function buildNavBar(settings, user) {
  return {
    'userguide': {
      title: settings['app.user_guide_title'],
      rights: settings['feature.welcome_page_menu'],
      url: '/userguide'
    },
    'compliance': {
      title: 'Compliance',
      rights: calcComplianceRights(user, settings) && (user === null || checkForComplianceProjects(user.projects)) //UserRightsTable has user === null
    },

    'policies': {
      title: settings['feature.process_library_name'] || 'Policies',
      rights: settings['feature.policy_management_menu'] === 1 && (user === null || user === void 0 ? void 0 : user.policiesAccess)
    },
    'assets': {
      title: settings['feature.information_assets_name'] || 'Assets',
      property: ObjectTypeToUserField[ObjectTypes.INFORMATION_ASSET],
      rights: user === null || settings['feature.asset_management_menu'] === 1 && (user === null || user === void 0 ? void 0 : user.iaAccess) && settings['feature.disable_information_assets'] !== 1
    },
    'risk': {
      title: 'Risk',
      trans: false,
      property: ObjectTypeToUserField[ObjectTypes.RISK_RISK],
      rights: calcRiskRights(user, settings)
    },
    'actions': {
      title: 'Actions',
      property: ObjectTypeToUserField[ObjectTypes.EXECUTION_INITIATIVE],
      rights: calcExecutionRights(user, settings)
    },
    'controls': {
      title: 'Controls',
      property: ObjectTypeToUserField[ObjectTypes.CONTROLS_CONTROL],
      rights: calcControlsRights(user, settings)
    },
    'contracts': {
      title: 'Contracts',
      property: ObjectTypeToUserField[ObjectTypes.CONTRACT],
      rights: calcContractRights(user, settings)
    },
    'incident': {
      title: 'Incidents',
      property: ObjectTypeToUserField[ObjectTypes.INCIDENTS_INCIDENT],
      rights: calcIncidentsRights(user, settings)
    }
  };
};