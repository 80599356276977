import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
var baseUrl = 'awareness/request';
export function createNewRequest(data) {
  return handleRequestResponse(Http.post(baseUrl, data));
}
export function updateRequest(id, data) {
  return handleRequestResponse(Http.patch("".concat(baseUrl, "/").concat(id), data));
}
export function deleteRequest(id) {
  return handleRequestResponse(Http.delete("".concat(baseUrl, "/").concat(id)));
}
export function getAll(params) {
  var url = baseUrl;
  if (params) {
    url += params;
  }
  return handleRequestResponse(Http.get(url));
}
export function getByToken(token) {
  var url = "awareness/signoff/".concat(token);
  return handleRequestResponse(Http.get(url));
}
export function acceptRequest(token, data) {
  var url = "awareness/signoff/".concat(token);
  return handleRequestResponse(Http.post(url, data));
}
export function sendReminder(id, data) {
  return handleRequestResponse(Http.patch("awareness/reminder/".concat(id), data));
}
export function getMyAwareness() {
  return handleRequestResponse(Http.get('awareness/my'));
}