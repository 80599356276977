import { storeToRefs } from 'pinia';
import { useUserStore } from '@/Store/userStore';
export var LoginGuardMixin = {
  methods: {
    loginGuard: function loginGuard() {
      var _this = this;
      var userStore = useUserStore();
      var loadLoginInfo = userStore.loadLoginInfo,
        loadCurrentUser = userStore.load;
      var _storeToRefs = storeToRefs(userStore),
        currentUser = _storeToRefs.currentUser;
      return loadLoginInfo().then(function (loginInfo) {
        if (!loginInfo.userIsLoggedIn) {
          return _this.$router.push('/?returnUrl=' + _this.$router.currentRoute.value.path + '&reason=LoginGuardMixin');
        }
        if (+currentUser.value.id !== +loginInfo.userId) {
          return loadCurrentUser();
        }
      });
    }
  }
};