import Fuse from 'fuse.js';
export var CompaniesFuseMixin = {
  data: function data() {
    return {
      fuseSearchOptions: {
        threshold: 0.2,
        keys: ['name', 'address', 'city', 'zipCode', 'vatNo', 'countryCode']
      }
    };
  },
  methods: {
    getFilteredCompaniesList: function getFilteredCompaniesList(companies, searchField) {
      if (!(searchField !== null && searchField !== void 0 && searchField.trim())) return companies;
      var companiesFuse = new Fuse(companies, this.fuseSearchOptions);
      return companiesFuse.search(searchField).map(function (_ref) {
        var item = _ref.item;
        return item;
      });
    }
  }
};