function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-56f48466"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_custom_risk_field = _resolveComponent("custom-risk-field");
  var _component_main_well = _resolveComponent("main-well");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_field_groups_modal = _resolveComponent("field-groups-modal");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [$data.showAlert ? (_openBlock(), _createBlock(_component_alert, {
        key: 0,
        body: _ctx.closeMessage,
        header: $data.translate.areYouSure,
        "button-ok-text": $data.translate.delete,
        "button-dismiss-text": $data.translate.cancel,
        onAccept: $options.deleteCF,
        onDismiss: $options.closeAlert
      }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        title: $data.translate.customRiskFields,
        type: "big"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.translate.theseAreYourOwnCustomRiskFields), 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.fieldGroups, function (group) {
        return _openBlock(), _createBlock(_component_main_well, {
          key: group.id,
          class: "mb-6 z-auto"
        }, {
          default: _withCtx(function () {
            return [_createVNode(_component_risma_title, {
              title: group.name || $data.translate.notGrouped,
              type: "big"
            }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.customFieldsByGroups[group.id], function (customField) {
              return _openBlock(), _createBlock(_component_custom_risk_field, {
                key: customField.id,
                class: "erm-cf",
                "custom-field": customField,
                "read-only": $props.readOnly,
                onEdit: $options.updateCustomField,
                onDelete: function onDelete($event) {
                  return $options.handleDeleteCF(customField.id);
                }
              }, null, 8 /* PROPS */, ["custom-field", "read-only", "onEdit", "onDelete"]);
            }), 128 /* KEYED_FRAGMENT */))];
          }),

          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */);
      }), 128 /* KEYED_FRAGMENT */)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.customFieldsNew, function (customField, index) {
        return _openBlock(), _createBlock(_component_custom_risk_field, {
          key: "new-cf-".concat(index),
          "custom-field": customField,
          "read-only": $props.readOnly,
          onEdit: function onEdit($event) {
            return $options.updateCustomField(_objectSpread({
              index: index
            }, $event));
          },
          onDelete: function onDelete($event) {
            return $options.handleDeleteUngroupedCF(index);
          }
        }, null, 8 /* PROPS */, ["custom-field", "read-only", "onEdit", "onDelete"]);
      }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_button, {
        text: $data.translate.addCustomField,
        type: "save",
        disabled: $props.readOnly,
        onClick: $options.addUngroupedCF
      }, null, 8 /* PROPS */, ["text", "disabled", "onClick"]), !$props.readOnly ? (_openBlock(), _createBlock(_component_risma_button, {
        key: 0,
        text: $options.reorderButtonTitle,
        disabled: !!$options.customFieldsNew.length,
        type: "save",
        onClick: $options.toggleReorderCFModal
      }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])) : _createCommentVNode("v-if", true), $data.showFieldGroupsModal ? (_openBlock(), _createBlock(_component_field_groups_modal, {
        key: 1,
        "field-groups": $props.fieldGroups,
        "scoring-id": $props.scoringId,
        "group-items": $options.customFieldsByGroups,
        onDismiss: $options.toggleReorderCFModal,
        onCreate: $options.onCreateGroup,
        "onMove:groups": $options.moveGroups,
        "onMove:fields": $options.moveFields
      }, null, 8 /* PROPS */, ["field-groups", "scoring-id", "group-items", "onDismiss", "onCreate", "onMove:groups", "onMove:fields"])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  });
}