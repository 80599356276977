function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
var ExpansionTypes = {
  DEFAULT: 'default',
  UTIL: 'util'
};
export default {
  name: 'ExpansionPanel',
  introduction: 'The expansion-panel component is useful for reducing vertical space with large amounts of information.',
  description: 'Use templates with v-slot:header and v-slot:content',
  token: '<expansion-panel>{slot:header}{slot:content}</expansion-panel>',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    startExpanded: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If the panel should start as expanded'
    },
    type: {
      type: String,
      required: false,
      default: 'default',
      validator: function validator(t) {
        return Object.values(ExpansionTypes).includes(t);
      }
    }
  },
  data: function data() {
    return {
      completeIsExpanded: this.startExpanded,
      ExpansionTypes: ExpansionTypes
    };
  },
  computed: {
    classListByType: function classListByType() {
      var _ref;
      return _ref = {}, _defineProperty(_ref, ExpansionTypes.DEFAULT, {
        header: 'bg-blue-250',
        chevron: 'text-white',
        content: 'border border-t-0 border-gray-610'
      }), _defineProperty(_ref, ExpansionTypes.UTIL, {
        header: '',
        chevron: '',
        content: ''
      }), _ref;
    }
  }
};