function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import Modal from '@/components/Molecules/Modal/Modal';
import Notification from '@/components/Molecules/Notification';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { toLocalDateTimeFull } from '@/utils/date';
export default {
  name: 'AttachmentVersionsModal',
  introduction: 'A modal, that handles new file versions of attachments',
  description: 'Allows to add new versions of files and to manage(review/delete) all the file\'s versions',
  token: '<attachment-versions-modal :add-mode="true" :file-id="1" />',
  components: {
    RismaFileUpload: RismaFileUpload,
    RismaTitle: RismaTitle,
    InputField: InputField,
    Modal: Modal,
    FeatherIcon: FeatherIcon,
    Notification: Notification,
    RismaSelect: RismaSelect
  },
  props: {
    addMode: {
      type: Boolean,
      required: true,
      note: 'True: if open modal for adding new file version. False: if only for versions review'
    },
    file: {
      type: Object,
      required: true,
      note: 'File data'
    },
    typeOptions: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: false,
      note: 'The list of options for file types if any'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If attachments are readonly and can\'t be deleted'
    },
    isApplyDisabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['dismiss', 'upload', 'onDelete'],
  data: function data() {
    return {
      fileTitle: '',
      errorMessage: '',
      uploadedFile: null,
      selectedDocTypes: [],
      translate: getTranslate['AttachmentVersionsModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, ['users'])), mapState(useUserStore, ['currentUser'])), {}, {
    orderedRevisions: function orderedRevisions() {
      if (!this.file.revisions || !this.file.revisions.length) return;
      return _toConsumableArray(this.file.revisions).reverse();
    },
    filesForUpload: function filesForUpload() {
      var result = _objectSpread(_objectSpread({}, this.uploadedFile), {}, {
        newVersionOf: this.file.id,
        title: this.fileTitle,
        owner: this.currentUser.id
      });
      if (this.typeOptions && this.typeOptions.length) {
        result.docTypeIds = this.selectedDocTypes.map(function (item) {
          return item.id;
        });
      }
      return [result];
    }
  }),
  mounted: function mounted() {
    this.fileTitle = this.file.title || '';
    this.generateSelectedDocTypes(this.file.docTypeIds);
  },
  methods: {
    dismissModal: function dismissModal() {
      this.$emit('dismiss');
    },
    toLocalTime: function toLocalTime(date) {
      return toLocalDateTimeFull(date);
    },
    generateSelectedDocTypes: function generateSelectedDocTypes(ids) {
      var _this = this;
      this.selectedDocTypes = ids.map(function (item) {
        var docType = _this.typeOptions.find(function (type) {
          return +type.id === +item;
        });
        return {
          id: docType.id,
          label: docType.label
        };
      });
    },
    getDocTypeIdsValues: function getDocTypeIdsValues(document) {
      var _this2 = this;
      return document.docTypeIds.map(function (id) {
        var _this2$typeOptions$fi;
        return (_this2$typeOptions$fi = _this2.typeOptions.find(function (type) {
          return +type.id === +id;
        })) === null || _this2$typeOptions$fi === void 0 ? void 0 : _this2$typeOptions$fi.label;
      }).join(', ');
    },
    onFileUpload: function onFileUpload(files) {
      if (files.length > 1) {
        this.uploadedFile = null;
        this.errorMessage = "".concat(this.translate.uploadIsLimitedToOneFileAtATime);
        return;
      }
      this.uploadedFile = files[0];
    },
    onApply: function onApply() {
      this.errorMessage = '';
      if (!this.uploadedFile) {
        this.errorMessage = this.translate.pleaseSelectAFile;
        return;
      }
      if (!this.fileTitle) {
        this.errorMessage = this.translate.titleCannotBeEmpty;
        return;
      }
      if (!Object.keys(this.uploadedFile).length) {
        return this.dismissModal();
      }
      this.$emit('upload', this.filesForUpload);
    },
    deleteRevision: function deleteRevision(id) {
      this.$emit('onDelete', id);
    },
    getUserById: function getUserById(id) {
      var user = this.users.find(function (user) {
        return user.id == id;
      });
      return user ? user.display_name : '';
    }
  }
};