function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { toLocalDate } from '@/utils/date';
import * as Utils from '@/utils/Utils';
import { UserLevelsByValue } from '@/constants/UserLevels';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { CommonAssetsMixin } from '@/mixins/CommonAssetsMixin';
import { projectsByUser } from '@/utils/projectsByUser';
export default {
  name: 'UserRightsExpanded',
  introduction: 'Rismadatatable with users',
  description: 'This component shows available users with <risma-datatable> inside. Expanded view',
  token: '<user-rights-expanded />',
  components: {
    RismaButton: RismaButton,
    RismaDatatable: RismaDatatable
  },
  mixins: [CommonAssetsMixin, RismaDatatableDefaultMixin],
  data: function data() {
    return {
      columns: [],
      dataset: [],
      fuseSearchOptions: {
        threshold: 0.1,
        location: 0,
        distance: 1000
      },
      decoupleFilterFields: [{
        key: 'activeUsers'
      }, {
        key: 'locked'
      }, {
        key: 'incidents'
      }, {
        key: 'controls'
      }, {
        key: 'risk'
      }, {
        key: 'actions'
      }],
      disabledFilterByColumns: ['email', 'username', 'fullname', 'initials', 'previousLogin', 'organisationsRead', 'organisations'],
      translate: getTranslate['UserRightsExpanded']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    isMfaFeatureEnabled: function isMfaFeatureEnabled() {
      return this.getSettingValue('feature.google_mfa_enabled');
    },
    preparedColumns: function preparedColumns() {
      var optionalColumns = {};
      if (this.isMfaFeatureEnabled) optionalColumns.mfaType = this.translate.mfaStatus;
      return _objectSpread(_objectSpread({
        email: this.translate.email,
        username: this.translate.username,
        fullname: this.translate.fullName,
        initials: this.translate.initials,
        actions: this.translate.rismaactions,
        risk: this.translate.rismarisk,
        controls: this.translate.rismacontrols,
        incidents: this.translate.rismaincidents
      }, this.preparedProjectColumns), {}, {
        locked: this.translate.locked,
        previousLogin: this.translate.previousLogin,
        organisations: this.translate.organisations,
        organisationsRead: this.translate.organisations + this.translate.readonly,
        activeUsers: this.translate.activity
      }, optionalColumns);
    },
    preparedProjectColumns: function preparedProjectColumns() {
      var projectsColumns = {};
      var projects = projectsByUser(this.currentUser, this.getSettingValue);
      var _iterator = _createForOfIteratorHelper(projects),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var project = _step.value;
          projectsColumns[this.getKeyForProject(project)] = project.title;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return projectsColumns;
    }
  }),
  mounted: function mounted() {
    this.dataset = this.prepareDataset(this.users);
    this.enhanceDataset(this.dataset, this.preparedColumns);
  },
  methods: {
    backToPrevPage: function backToPrevPage() {
      this.$router.push('/settings/administration');
    },
    getKeyForProject: function getKeyForProject(project) {
      if (project.objType === ObjectTypes.RISK_PROJECT) {
        return 'r-' + project.id;
      }
      if (project.objType === ObjectTypes.COMPLIANCE_PROJECT) {
        return 'c-' + project.id;
      }
    },
    prepareDataset: function prepareDataset(users) {
      var _this = this;
      var accessText = ['No access', 'Normal', 'Privileged', 'Super', 'Admin'];
      var lockedFields = {
        level_e_locked: 'RISMAactions',
        level_r_locked: 'RISMArisk',
        level_c_locked: 'RISMAcontrols',
        level_incidents_locked: 'RISMAincidents'
      };
      var dataset = users.map(function (user) {
        var lockedText = [];
        Object.keys(lockedFields).forEach(function (field) {
          if (user[field]) {
            lockedText.push(lockedFields[field]);
          }
        });
        var projectAccessLevels = {};
        var projects = projectsByUser(user, _this.getSettingValue);
        projects.forEach(function (project) {
          var key = _this.getKeyForProject(project);
          projectAccessLevels[key] = {
            field: _this.$trans(UserLevelsByValue[project.access_level])
          };
        });
        var optionalFields = {};
        if (_this.isMfaFeatureEnabled) optionalFields.mfaType = {
          field: user.mfa_type ? _this.translate.enabled : _this.translate.disabled
        };
        return _objectSpread(_objectSpread({
          email: {
            field: user.email
          },
          username: {
            field: user.username
          },
          fullname: {
            field: user.fullname
          },
          initials: {
            field: user.initials
          },
          actions: {
            field: _this.$trans(accessText[user.level_execution])
          },
          risk: {
            field: _this.$trans(accessText[user.level_risk])
          },
          controls: {
            field: _this.$trans(accessText[user.level_controls])
          },
          incidents: {
            field: _this.$trans(accessText[user.level_incidents])
          }
        }, projectAccessLevels), {}, {
          locked: {
            field: lockedText.length ? lockedText.join(', ') : _this.translate.none
          },
          previousLogin: {
            field: toLocalDate(user.previous_login)
          },
          organisations: {
            field: Utils.findPropsByIds(user.organisations, _this.organisations, 'visible_name')
          },
          organisationsRead: {
            field: Utils.findPropsByIds(user.organisationsReadonly, _this.organisations, 'visible_name')
          },
          activeUsers: {
            field: user.active ? _this.translate.active : _this.translate.inactive
          }
        }, optionalFields);
      });
      return dataset;
    }
  }
};