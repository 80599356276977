import { getRisksManagementLevels, createRisksManagementLevel, deleteRisksManagementLevel } from '@/api/risk/risk';
import RiskManagement from '@/components/Organisms/RiskAdmin/RiskManagement';
export default {
  name: 'ManagementLevelsWell',
  token: '<management-levels-well />',
  components: {
    RiskManagement: RiskManagement
  },
  props: {
    risks: {
      required: true,
      type: Array,
      note: 'Risks that is needed to count used management levels'
    }
  },
  data: function data() {
    return {
      managementLevels: [],
      translate: {
        level: this.$trans('Level'),
        risks: this.$trans('Risks'),
        managementLevels: this.$trans('Management levels')
      }
    };
  },
  computed: {
    columns: function columns() {
      return [this.translate.level, this.translate.risks, '', ''];
    },
    title: function title() {
      return this.translate.managementLevels;
    },
    dataset: function dataset() {
      var _this = this;
      return this.managementLevels.map(function (level) {
        return {
          title: level.name,
          risks: _this.getRisksNumberByManagementId(level.id),
          delete: {
            id: level.id,
            title: level.name,
            risks: _this.getRisksNumberByManagementId(level.id)
          }
        };
      });
    }
  },
  mounted: function mounted() {
    this.getRiskManagementLevels();
  },
  methods: {
    getRisksNumberByManagementId: function getRisksNumberByManagementId(id) {
      var risks = this.risks.filter(function (risk) {
        return +risk.managementLevelId === +id;
      });
      return risks ? risks.length : 0;
    },
    getRiskManagementLevels: function getRiskManagementLevels() {
      var _this2 = this;
      return getRisksManagementLevels().then(function (_ref) {
        var list = _ref.list;
        return _this2.managementLevels = list;
      });
    },
    createManagementLevel: function createManagementLevel(title) {
      var _this3 = this;
      return createRisksManagementLevel(title).then(function (newLevel) {
        _this3.managementLevels.push(newLevel);
      });
    },
    updateManagementLevel: function updateManagementLevel() {
      return Promise.resolve();
    },
    deleteManagementLevel: function deleteManagementLevel(id) {
      return deleteRisksManagementLevel(id).then(this.getRiskManagementLevels);
    }
  }
};