import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8f2dc382"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "risk-gsb"
};
var _hoisted_2 = {
  class: "risk-gsb-content"
};
var _hoisted_3 = {
  class: "risk-gsb-section"
};
var _hoisted_4 = {
  class: "risk-gsb-section"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_field_set = _resolveComponent("field-set");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_field_set, {
    title: $data.translate.groupAndSort
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
        title: $data.translate.groupBy,
        placeholder: $data.translate.notSet,
        options: $options.groupByOptions,
        "model-value": $props.groupBy,
        "un-sorted": true,
        "with-reset": true,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function (groupBy) {
          return _ctx.$emit('update-groupBy', groupBy);
        })
      }, null, 8 /* PROPS */, ["title", "placeholder", "options", "model-value"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
        title: $data.translate.sortBy,
        placeholder: $data.translate.notSet,
        options: $options.sortByOptions,
        "model-value": $props.sortBy,
        "un-sorted": true,
        "with-reset": true,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function (sortBy) {
          return _ctx.$emit('update-sortBy', sortBy);
        })
      }, null, 8 /* PROPS */, ["title", "placeholder", "options", "model-value"])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"])]);
}