function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { Colors } from '@/Colors';
import { getAllByType } from '@/api/compliance/informationAssets';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Selector from '@/components/Atoms/Inputs/Selector';
import Modal from '@/components/Molecules/Modal/Modal';
import { MODULES, getModuleTitles } from '@/constants/modules';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export default {
  name: 'CompanyLinkModal',
  token: '<company-link-modal :linked-information-assets="{information_assets: []}" />',
  components: {
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    Modal: Modal,
    Selector: Selector,
    Trafficlight: Trafficlight,
    FeatherIcon: FeatherIcon
  },
  props: {
    linkedInformationAssets: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['dismissModal', 'updateLinks', 'showAssetCreationModal'],
  data: function data() {
    return {
      AssetsTypes: AssetsTypes,
      Colors: Colors,
      moduleTitles: getModuleTitles(),
      selectedLinkOption: null,
      itemsByModules: {},
      selectedItems: [],
      translate: getTranslate['CompanyLinkModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, ['complianceIACustomActivityTypes'])), {}, {
    informationAssetsName: function informationAssetsName() {
      return this.getSettingValue('feature.information_assets_name') || this.$trans('Assets');
    },
    selectIALabel: function selectIALabel() {
      return "".concat(this.translate.select, " ").concat(this.informationAssetsName, " ").concat(this.translate.type.toLowerCase());
    },
    linkOptions: function linkOptions() {
      var result = [{
        value: MODULES.COMPLIANCE_IA_PROCESSOR,
        label: this.$trans(this.moduleTitles[MODULES.COMPLIANCE_IA_PROCESSOR])
      }, {
        value: MODULES.COMPLIANCE_IA_CONTROLLER,
        label: this.$trans(this.moduleTitles[MODULES.COMPLIANCE_IA_CONTROLLER])
      }, {
        value: MODULES.COMPLIANCE_IA_SYSTEM,
        label: this.$trans(this.moduleTitles[MODULES.COMPLIANCE_IA_SYSTEM])
      }];
      this.complianceIACustomActivityTypes.forEach(function (activityType) {
        if (!activityType.inUse) return;
        result.push({
          value: "".concat(activityType.module, "-").concat(activityType.id),
          label: activityType.label,
          id: activityType.id
        });
      });
      return result;
    },
    selectLinkLabel: function selectLinkLabel() {
      if (!this.selectedLinkModule) return '';
      return this.translate.select + ' ' + this.getModuleTitle(this.selectedLinkModule);
    },
    selectLinkPlaceholder: function selectLinkPlaceholder() {
      if (!this.selectedLinkModule) return '';
      return this.translate.clickToSelect + ' ' + this.getModuleTitle(this.selectedLinkModule);
    },
    assetTypeBySelectedModule: function assetTypeBySelectedModule() {
      if (!this.selectedLinkModule) return null;
      if (this.selectedLinkModule === MODULES.COMPLIANCE_IA_PROCESSOR) {
        return AssetsTypes.PROCESSORS;
      }
      if (this.selectedLinkModule === MODULES.COMPLIANCE_IA_CONTROLLER) {
        return AssetsTypes.CONTROLLERS;
      }
      if (this.selectedLinkModule === MODULES.COMPLIANCE_IA_SYSTEM) {
        return AssetsTypes.SYSTEMS;
      }
      if (this.selectedLinkModule === MODULES.COMPLIANCE_INFORMATION_ASSETS) {
        return AssetsTypes.INFORMATION_ASSETS_API;
      }
      return null;
    },
    selectedActivityTypeId: function selectedActivityTypeId() {
      var _this = this;
      if (this.selectedLinkOption && this.selectedLinkOption.startsWith(MODULES.COMPLIANCE_INFORMATION_ASSETS)) {
        var selectedActivityType = this.linkOptions.find(function (option) {
          return option.value === _this.selectedLinkOption;
        });
        return selectedActivityType.id;
      }
      return null;
    },
    selectableItems: function selectableItems() {
      var _this2 = this;
      var result = this.itemsByModules[this.assetTypeBySelectedModule] || [];
      if (this.selectedActivityTypeId) {
        result = result.filter(function (asset) {
          return asset.activityTypeId === _this2.selectedActivityTypeId;
        });
      }
      return result.filter(function (item) {
        return !_this2.selectedItems.find(function (selected) {
          return selected.id === item.id;
        });
      });
    },
    selectedItemsIds: function selectedItemsIds() {
      return this.selectedItems.map(function (item) {
        return item.id;
      });
    },
    selectedLinkModule: function selectedLinkModule() {
      if (!this.selectedLinkOption) return null;
      if (this.selectedLinkOption.startsWith(MODULES.COMPLIANCE_INFORMATION_ASSETS)) {
        return MODULES.COMPLIANCE_INFORMATION_ASSETS;
      }
      return this.selectedLinkOption;
    },
    showCreateItemButton: function showCreateItemButton() {
      return this.selectedLinkModule === MODULES.COMPLIANCE_IA_PROCESSOR;
    }
  }),
  watch: {
    assetTypeBySelectedModule: function assetTypeBySelectedModule(newType) {
      if (!newType) return;
      if (!this.itemsByModules[newType]) {
        this.getItemsBySelectedModule();
      }
    },
    selectedLinkOption: function selectedLinkOption() {
      this.setSelectedItems(this.linkedInformationAssets, this.selectedLinkOption, this.selectedActivityTypeId);
    }
  },
  methods: {
    getItemsBySelectedModule: function getItemsBySelectedModule() {
      var _this3 = this;
      return getAllByType(this.assetTypeBySelectedModule).then(function (_ref) {
        var list = _ref.list;
        var flatList = _this3.getItemsFlat(list);
        _this3.itemsByModules[_this3.assetTypeBySelectedModule] = flatList.filter(function (item) {
          return !item.companyId;
        });
      });
    },
    updateLinks: function updateLinks() {
      if (!this.selectedLinkModule) {
        this.$emit('dismissModal');
      } else {
        this.$emit('updateLinks', {
          assetType: this.assetTypeBySelectedModule,
          assetsIds: this.selectedItemsIds,
          module: this.selectedLinkModule,
          activityTypeId: this.selectedActivityTypeId
        });
      }
    },
    setSelectedItems: function setSelectedItems(data, option, id) {
      if (id) {
        var allItems = data[MODULES.COMPLIANCE_INFORMATION_ASSETS].slice(0);
        this.selectedItems = allItems.filter(function (asset) {
          return asset.activityTypeId === id;
        });
      } else {
        var _data$option;
        this.selectedItems = ((_data$option = data[option]) === null || _data$option === void 0 ? void 0 : _data$option.slice(0)) || [];
      }
    },
    onLinkSelect: function onLinkSelect(item) {
      this.selectedItems.push(item);
    },
    onLinkDeselect: function onLinkDeselect(item) {
      this.selectedItems = this.selectedItems.filter(function (selectedItem) {
        return selectedItem.id !== item.id;
      });
    },
    getModuleTitle: function getModuleTitle(module) {
      if (!this.moduleTitles[module]) return '';
      if (this.moduleTitles[module] === this.informationAssetsName) return this.informationAssetsName;
      return this.$trans(this.moduleTitles[module]).toLowerCase();
    },
    getItemsFlat: function getItemsFlat(items) {
      return items.reduce(function (list, item) {
        var _item$childrenObjects;
        list.push(item);
        if ((_item$childrenObjects = item.childrenObjects) !== null && _item$childrenObjects !== void 0 && _item$childrenObjects.length) {
          list.push.apply(list, _toConsumableArray(item.childrenObjects));
        }
        return list;
      }, []);
    }
  }
};