import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["href"];
var _hoisted_2 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");
  return $props.isRisma2Component && $props.url ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    to: $props.url,
    exact: $props.exact
  }, {
    default: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default")];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["to", "exact"])) : !$props.isRisma2Component && $props.url ? (_openBlock(), _createElementBlock("a", {
    key: 1,
    href: $props.url
  }, [_renderSlot(_ctx.$slots, "default")], 8 /* PROPS */, _hoisted_1)) : (_openBlock(), _createElementBlock("span", _hoisted_2, [_renderSlot(_ctx.$slots, "default")]));
}