function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getTranslate } from './translate';
import { getControlsDashboardEfficiencyData, getSliceControls as _getSliceControls } from '@/api/controls/controls';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PaginatedTable from '@/components/Organisms/PaginatedTable';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import ControlsDashboardFilters from '@/components/Pages/Controls/ControlsDashboardFilters';
import { StatusOverviewMixin } from '@/components/Pages/Controls/StatusOverviewMixin';
import { toLocalDateTimeFull } from '@/utils/date';
import { findPropsByIds } from '@/utils/Utils';
export default {
  name: 'ControlsEfficiencyDashboard',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    PaginatedTable: PaginatedTable,
    RismaTitleWithIcon: RismaTitleWithIcon,
    RismaDoughnutChart: RismaDoughnutChart,
    ControlsDashboardFilters: ControlsDashboardFilters
  },
  mixins: [StatusOverviewMixin],
  data: function data() {
    return {
      isLoaded: false,
      pieChartsStaticTable: {},
      charts: [],
      datasets: {},
      tablesCache: {},
      updateTimer: 0,
      translate: getTranslate['ControlsEfficiencyDashboard']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])),
  methods: {
    applyFilters: function applyFilters(searchData) {
      var _this = this;
      this.isLoaded = false;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(function () {
        return _this.getData(searchData);
      }, this.$autoSaveDebounceTime);
    },
    getData: function getData(searchData) {
      var _this2 = this;
      this.isLoaded = false;
      return getControlsDashboardEfficiencyData(searchData).then(function (_ref) {
        var charts = _ref.charts;
        _this2.charts = [];
        var chartData = {};
        charts.forEach(function (chart) {
          chartData[chart.title] = chart.data;
          _this2.charts.push(chart.title);
        });
        _this2.prepareDatasets(chartData);
        _this2.tablesCache = {};
        _this2.isLoaded = true;
      });
    },
    prepareDatasets: function prepareDatasets(data) {
      var _this3 = this;
      this.datasets = {};
      this.pieChartsStaticTable = {};
      Object.keys(data).forEach(function (key) {
        if (!_this3.datasets[key]) _this3.datasets[key] = {
          title: key
        };
        var _this3$prepareChartDa = _this3.prepareChartData(key, data, 'title', 'value'),
          datasets = _this3$prepareChartDa.datasets,
          labels = _this3$prepareChartDa.labels;
        _this3.datasets[key].datasets = datasets;
        _this3.datasets[key].labels = labels;
        _this3.datasets[key].controlsUrl = data[key].map(function (item) {
          return item.controlsUrl;
        });
      });
    },
    clickSlice: function clickSlice(key, sliceNumber) {
      var _this$pieChartsStatic,
        _this4 = this;
      var title = this.datasets[key].labels[sliceNumber];
      if (!title || ((_this$pieChartsStatic = this.pieChartsStaticTable[key]) === null || _this$pieChartsStatic === void 0 ? void 0 : _this$pieChartsStatic.title) === title) {
        this.pieChartsStaticTable = {};
        return;
      }
      var cacheKey = "".concat(key, "-").concat(sliceNumber);
      var cachedTable = this.tablesCache[cacheKey];
      if (cachedTable) {
        this.setPieTable(key, title, cachedTable);
        return;
      }
      return this.getSliceControls(key, sliceNumber).then(function (controls) {
        var table = _this4.prepareTableInfoForPie(controls);
        _this4.setPieTable(key, title, table);
        _this4.tablesCache[cacheKey] = table;
      });
    },
    setPieTable: function setPieTable(key, title, table) {
      this.pieChartsStaticTable = _defineProperty({}, key, {
        title: title,
        table: table
      });
    },
    getSliceControls: function getSliceControls(key, sliceNumber) {
      var _this5 = this;
      return _getSliceControls(this.datasets[key].controlsUrl[sliceNumber]).then(function (_ref2) {
        var list = _ref2.list;
        return list.map(function (control) {
          var _control$userIds;
          return _objectSpread(_objectSpread({}, control), {}, {
            text: "".concat(control.cpath, " ").concat(control.title, " (").concat(toLocalDateTimeFull(control.deadline), ")<br>").concat(_this5.translate.processes, ": ").concat(_this5.getProcessesNames(control.processes)),
            owners: findPropsByIds((_control$userIds = control.userIds) === null || _control$userIds === void 0 ? void 0 : _control$userIds.responsible, _this5.users, 'display_name'),
            organisations: findPropsByIds(control.organisationIds, _this5.organisations, 'visible_name')
          });
        });
      });
    },
    getProcessesNames: function getProcessesNames(processes) {
      if (!processes.length) return "<".concat(this.translate.none, ">");
      return processes.map(function (process) {
        return process.title;
      }).join(', ');
    },
    getPieChartTableSize: function getPieChartTableSize(datasetLength) {
      return datasetLength < 20 ? datasetLength : 20;
    }
  }
};