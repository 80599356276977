import OptionsList from '@/components/Molecules/OptionsList/OptionsList';
export default {
  name: '',
  components: {
    OptionsList: OptionsList
  },
  methods: {
    catchEmittedEvent: function catchEmittedEvent() {
      window.alert('Event emitted');
    }
  }
};