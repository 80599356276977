import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_control_panel_settings = _resolveComponent("control-panel-settings");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.filteredRiskProjects.length ? (_openBlock(), _createBlock(_component_control_panel_settings, {
    key: 0,
    "settings-groups": $options.settingsGroups,
    "filtered-risk-projects": $props.filteredRiskProjects,
    class: "mb-10"
  }, null, 8 /* PROPS */, ["settings-groups", "filtered-risk-projects"])) : _createCommentVNode("v-if", true)]);
}