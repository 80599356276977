function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import cloneDeep from 'lodash/cloneDeep';
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { RiskProjects } from '@/constants/risks/RiskProjects';
export default {
  name: 'EditProjectModal',
  components: {
    Modal: Modal,
    InputField: InputField,
    Notification: Notification,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect
  },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: '',
      note: 'Title to edit from v-model'
    },
    project: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    solutions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    activityTypes: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['accept', 'dismiss', 'update:modelValue'],
  data: function data() {
    return {
      notificationMessage: '',
      currentProject: {},
      isDirty: false,
      translate: getTranslate['EditProjectModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['isAdmin'])), {}, {
    isErm: function isErm() {
      return this.project.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type;
    },
    solutionOptions: function solutionOptions() {
      return this.solutions.map(function (item) {
        return {
          value: item.id,
          label: item.title
        };
      });
    },
    activityTypeOptions: function activityTypeOptions() {
      return this.activityTypes.map(function (item) {
        return {
          value: item.id,
          label: item.label
        };
      });
    }
  }),
  mounted: function mounted() {
    this.currentProject = cloneDeep(this.project);
  },
  methods: {
    updateRiskProject: function updateRiskProject(data, field) {
      this.currentProject[field] = data;
    },
    onAccept: function onAccept() {
      var _this = this;
      if (!this.currentProject.title) {
        this.notificationMessage = this.translate.titleCannotBeEmpty;
        return;
      }
      var dirtyProject = {};
      var keys = ['title', 'solutionId', 'defaultActivityTypeId', 'threatsEnabled', 'accumulatedRisks', 'inherentRisksEnabled', 'matrixInverted'];
      keys.forEach(function (field) {
        if (JSON.stringify(_this.currentProject[field]) !== JSON.stringify(_this.project[field])) {
          dirtyProject[field] = _this.currentProject[field];
        }
      });

      //so it wont send api call if nothing changed
      if (Object.keys(dirtyProject).length) {
        this.$emit('accept', _objectSpread({
          id: this.project.id
        }, dirtyProject));
      } else {
        this.$emit('dismiss');
      }
    }
  }
};