import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  key: 2,
  class: "relative mt-8"
};
var _hoisted_2 = {
  class: "flex items-center"
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  class: "pt-3 border-t border-gray-450 mt-4"
};
var _hoisted_5 = {
  class: "py-2 font-bold text-gray-650"
};
var _hoisted_6 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_control_creation_modal = _resolveComponent("control-creation-modal");
  var _component_create_initiative_modal = _resolveComponent("create-initiative-modal");
  var _component_single_select = _resolveComponent("single-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_trafficlight = _resolveComponent("trafficlight");
  var _component_selector = _resolveComponent("selector");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.links,
    "overflow-visible": true,
    "dismiss-on-click-outside": false,
    "button-ok-text": $data.translate.apply,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: _cache[3] || (_cache[3] = function ($event) {
      return $options.updateLinks($data.project);
    }),
    onDismiss: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.isControlCreationModalShow ? (_openBlock(), _createBlock(_component_control_creation_modal, {
        key: 0,
        onUpdate: $options.addControl,
        onClose: _cache[0] || (_cache[0] = function ($event) {
          return $data.isControlCreationModalShow = false;
        })
      }, null, 8 /* PROPS */, ["onUpdate"])) : _createCommentVNode("v-if", true), $data.isCreationInitiativeModalShow ? (_openBlock(), _createBlock(_component_create_initiative_modal, {
        key: 1,
        initiatives: $options.filteredInitiatives,
        show: true,
        onDismiss: _cache[1] || (_cache[1] = function ($event) {
          return $data.isCreationInitiativeModalShow = false;
        }),
        onAccept: $options.addInitiative
      }, null, 8 /* PROPS */, ["initiatives", "onAccept"])) : _createCommentVNode("v-if", true), _createVNode(_component_single_select, {
        title: $data.translate.selectProjectOrActivity,
        "model-value": $data.project,
        "with-search": true,
        options: $options.filteredProjectsList,
        placeholder: $data.translate.choose,
        disabled: $props.disabledProjectSelect,
        "onUpdate:modelValue": $options.selectProject
      }, null, 8 /* PROPS */, ["title", "model-value", "options", "placeholder", "disabled", "onUpdate:modelValue"]), $data.project ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$options.showAddItemButton ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "absolute right-4 flex cursor-pointer",
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return $options.openCreationModal($data.project);
        })
      }, [_createVNode(_component_feather_icon, {
        icon: "plus"
      }), _createVNode(_component_risma_title, {
        title: $data.translate.createNew,
        type: "small"
      }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), _createVNode(_component_selector, {
        items: $options.sortedSelectableItems,
        title: $data.translate.select + ' ' + $options.linkLabel,
        "search-placeholder": $data.translate.clickToSelect + ' ' + $options.linkLabel,
        "search-fields": $options.searchFields,
        onSelected: $options.selectedLink
      }, {
        displayedName: _withCtx(function (slotProps) {
          return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [!slotProps.item.isMothercontrol && slotProps.item.trafficLight !== undefined && slotProps.item.trafficLight !== null ? (_openBlock(), _createBlock(_component_trafficlight, {
            key: 0,
            color: $data.Colors.getTrafficLightColor(slotProps.item),
            height: 11,
            width: 11,
            class: "inline-block align-baseline mr-4"
          }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true)]), slotProps.item.path || slotProps.item.rno ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(slotProps.item.path || slotProps.item.rno) + ". ", 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(slotProps.item.formattedTitle || slotProps.item.title || slotProps.item.name), 1 /* TEXT */), $data.project === $data.MODULES.EXECUTION && slotProps.item.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
            key: 1,
            icon: "check",
            class: "ml-2"
          })) : _createCommentVNode("v-if", true)])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["items", "title", "search-placeholder", "search-fields", "onSelected"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString($data.translate.addedLinks), 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selectedLinks, function (item) {
        return _openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "flex items-baseline px-1 py-1 rounded-md cursor-default hover:bg-gray-350"
        }, [_createElementVNode("p", {
          class: _normalizeClass(['mr-2 flex items-center', {
            'line-through': $options.getLineThrough(item)
          }])
        }, [!item.isMothercontrol && item.trafficLight !== undefined ? (_openBlock(), _createBlock(_component_trafficlight, {
          key: 0,
          color: $data.Colors.getTrafficLightColor(item),
          height: 11,
          width: 11,
          class: "inline-block align-baseline mr-4"
        }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true), !item.formattedTitle && (item.path || item.rno) ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.path || item.rno) + ". ", 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(item.formattedTitle || item.title || item.name), 1 /* TEXT */), $data.project === $data.MODULES.EXECUTION && item.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
          key: 2,
          icon: "check",
          class: "ml-2"
        })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createVNode(_component_feather_icon, {
          icon: "x",
          width: "12",
          height: "12",
          class: _normalizeClass('stroke-gray stroke-3 hover:stroke-red-50 cursor-pointer'),
          onClick: function onClick($event) {
            return $options.deselect(item);
          }
        }, null, 8 /* PROPS */, ["onClick"])]);
      }), 128 /* KEYED_FRAGMENT */))])])];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text"]);
}