import i18n from '@/i18n';
export var getTranslate = {
  PerformanceReportSettings: function PerformanceReportSettings() {
    return {
      areYouSureYouWantToDeleteThisRow: i18n.t('Are you sure you want to delete this row?'),
      deleteRow: i18n.t('Delete row'),
      cancel: i18n.t('Cancel'),
      addNewKpi: i18n.t('Add new KPI'),
      rename: i18n.t('Rename'),
      deleteDashboard: i18n.t('Delete dashboard'),
      confirmation: i18n.t('Confirmation'),
      ok: i18n.t('Ok'),
      nothingToShow: i18n.t('Nothing to show'),
      enableDashboard: i18n.t('Enable dashboard'),
      enableInMenu: i18n.t('Enable in menu')
    };
  },
  PerformanceSolution: function PerformanceSolution() {
    return {
      theresNoData: i18n.t('There’s no data'),
      eitherThereArentAnyKpisEnabledOrAnySolu: i18n.t('Either, there aren’t any KPI’s enabled or any solution ID’s registered on the activities'),
      kpiPerformance: i18n.t('KPI performance'),
      sortBy: i18n.t('Sort by'),
      today: i18n.t('Today')
    };
  }
};