function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import * as DataAPI from '@/api/risma/data';
import * as adminControlsAPI from '@/api/admin/controls';
import { getControlRisks } from '@/api/controls/controls';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import Notification from '@/components/Molecules/Notification';
import AdminControlsWell from '@/components/Organisms/Admin/AdminControlsWell';
import { MODULES } from '@/constants/modules';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
var notificationTypes = {
  EDIT: 'edit',
  CREATE: 'create',
  DELETE: 'delete'
};
export default {
  name: 'AdminControls',
  description: 'Admin Controls page',
  components: {
    RismaTitle: RismaTitle,
    Notification: Notification,
    AdminControlsWell: AdminControlsWell,
    NotificationPopUp: NotificationPopUp
  },
  mixins: [NotificationPopUpMixin],
  data: function data() {
    return {
      showNotification: false,
      notificationText: '',
      loadingAccounts: true,
      loadingProcesses: true,
      loadingRisks: true,
      loadingControlRiskTypes: true,
      accounts: [],
      processes: [],
      risks: [],
      controlRiskTypes: [],
      timer: 0,
      queryParams: '?data[]=usage',
      translate: getTranslate['AdminControls']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    accountName: function accountName() {
      return this.getSettingValue('risma.account_to_name') || this.translate.account;
    },
    processName: function processName() {
      return this.getSettingValue('risma.process_to_name') || this.translate.process;
    },
    riskName: function riskName() {
      return this.getSettingValue('risma.risk_to_name') || this.translate.risk;
    },
    riskTypesForSingleSelect: function riskTypesForSingleSelect() {
      return this.controlRiskTypes.map(function (item) {
        return {
          value: item.id,
          label: item.title
        };
      });
    },
    getControlRiskTypesExtended: function getControlRiskTypesExtended() {
      var _this = this;
      if (this.loadingControlRiskTypes || this.loadingRisks) return [];
      return this.controlRiskTypes.map(function (controlRiskType) {
        return _objectSpread(_objectSpread({}, controlRiskType), {}, {
          inUse: _this.typeIsInUse(controlRiskType.id)
        });
      });
    }
  }),
  mounted: function mounted() {
    var _this2 = this;
    Promise.all([this.getRisks(), this.getAccounts(), this.getProcesses()]).then(function () {
      return _this2.getControlRiskTypes();
    });
  },
  methods: {
    getAccounts: function getAccounts() {
      var _this3 = this;
      this.loadingAccounts = true;
      DataAPI.getAccounts(this.queryParams).then(function (_ref) {
        var list = _ref.list;
        _this3.accounts = list;
        _this3.loadingAccounts = false;
      }).catch(function (e) {
        _this3.loadingAccounts = false;
        throw e;
      });
    },
    getProcesses: function getProcesses() {
      var _this4 = this;
      this.loadingProcesses = true;
      DataAPI.getProcesses(this.queryParams).then(function (_ref2) {
        var list = _ref2.list;
        _this4.processes = list;
        _this4.loadingProcesses = false;
      }).catch(function (e) {
        _this4.loadingProcesses = false;
        throw e;
      });
    },
    getRisks: function getRisks() {
      var _this5 = this;
      this.loadingRisks = true;
      return getControlRisks(this.queryParams).then(function (_ref3) {
        var list = _ref3.list;
        _this5.risks = list;
        _this5.loadingRisks = false;
      }).catch(function (e) {
        _this5.loadingRisks = false;
        throw e;
      });
    },
    getControlRiskTypes: function getControlRiskTypes() {
      var _this6 = this;
      this.loadingControlRiskTypes = true;
      return adminControlsAPI.getControlRiskTypes().then(function (_ref4) {
        var list = _ref4.list;
        _this6.controlRiskTypes = list.filter(function (item) {
          return item.module === MODULES.CONTROLS;
        });
        _this6.loadingControlRiskTypes = false;
      }).catch(function (e) {
        _this6.loadingControlRiskTypes = false;
        throw e;
      });
    },
    createNewAccount: function createNewAccount(_ref5) {
      var _this7 = this;
      var title = _ref5.title,
        parentId = _ref5.parentId;
      adminControlsAPI.createNewAccountAPI({
        title: title,
        parentId: parentId
      }).then(function () {
        _this7.showSuccessfullNotification(_this7.accountName, title, notificationTypes.CREATE);
        return _this7.getAccounts();
      }).catch(function (err) {
        return _this7.showError(err.response.error);
      });
    },
    editAccount: function editAccount(_ref6) {
      var _this8 = this;
      var id = _ref6.id,
        title = _ref6.title,
        parentId = _ref6.parentId;
      adminControlsAPI.editAccountAPI(id, {
        title: title,
        parentId: parentId
      }).then(function () {
        _this8.showSuccessfullNotification(_this8.accountName, title, notificationTypes.EDIT);
        _this8.getAccounts();
      }).catch(function (err) {
        return _this8.showError(err.response.error);
      });
    },
    deleteAccount: function deleteAccount(_ref7) {
      var _this9 = this;
      var title = _ref7.title,
        id = _ref7.id;
      adminControlsAPI.deleteAccountAPI(id).then(function () {
        _this9.showSuccessfullNotification(_this9.accountName, title, notificationTypes.DELETE);
        return _this9.getAccounts();
      }).catch(function (err) {
        return _this9.showError(err.response.error);
      });
    },
    createNewProcess: function createNewProcess(_ref8) {
      var _this10 = this;
      var title = _ref8.title;
      adminControlsAPI.createNewProcessAPI({
        title: title
      }).then(function () {
        _this10.showSuccessfullNotification(_this10.processName, title, notificationTypes.CREATE);
        return _this10.getProcesses();
      }).catch(function (err) {
        return _this10.showError(err.response.error);
      });
    },
    editProcess: function editProcess(_ref9) {
      var _this11 = this;
      var id = _ref9.id,
        title = _ref9.title;
      adminControlsAPI.editProcessAPI(id, {
        title: title
      }).then(function () {
        _this11.showSuccessfullNotification(_this11.processName, title, notificationTypes.EDIT);
        return _this11.getProcesses();
      }).catch(function (err) {
        return _this11.showError(err.response.error);
      });
    },
    deleteProcess: function deleteProcess(_ref10) {
      var _this12 = this;
      var title = _ref10.title,
        id = _ref10.id;
      adminControlsAPI.deleteProcessAPI(id).then(function () {
        _this12.showSuccessfullNotification(_this12.processName, title, notificationTypes.DELETE);
        return _this12.getProcesses();
      }).catch(function (err) {
        return _this12.showError(err.response.error);
      });
    },
    createNewRisk: function createNewRisk(_ref11) {
      var _this13 = this;
      var title = _ref11.title,
        riskTypeId = _ref11.riskTypeId;
      adminControlsAPI.createNewRiskAPI({
        title: title,
        riskTypeId: riskTypeId
      }).then(function () {
        _this13.showSuccessfullNotification(_this13.riskName, title, notificationTypes.CREATE);
        return _this13.getRisks();
      }).catch(function (err) {
        return _this13.showError(err.response.error);
      });
    },
    editRisk: function editRisk(_ref12) {
      var _this14 = this;
      var id = _ref12.id,
        title = _ref12.title,
        riskTypeId = _ref12.riskTypeId;
      adminControlsAPI.editRiskAPI(id, {
        title: title,
        riskTypeId: riskTypeId
      }).then(function () {
        _this14.showSuccessfullNotification(_this14.riskName, title, notificationTypes.EDIT);
        return _this14.getRisks();
      }).catch(function (err) {
        return _this14.showError(err.response.error);
      });
    },
    deleteRisk: function deleteRisk(_ref13) {
      var _this15 = this;
      var title = _ref13.title,
        id = _ref13.id;
      adminControlsAPI.deleteRiskAPI(id).then(function () {
        _this15.showSuccessfullNotification(_this15.riskName, title, notificationTypes.DELETE);
        return _this15.getRisks();
      }).catch(function (err) {
        return _this15.showError(err.response.error);
      });
    },
    createNewControlRiskType: function createNewControlRiskType(_ref14) {
      var _this16 = this;
      var title = _ref14.title;
      adminControlsAPI.createNewControlRiskTypeAPI({
        title: title
      }).then(function () {
        _this16.showSuccessfullNotification(_this16.translate.controlRiskType, title, notificationTypes.CREATE);
        return _this16.getControlRiskTypes();
      }).catch(function (err) {
        return _this16.showError(err.response.error);
      });
    },
    editControlRiskType: function editControlRiskType(_ref15) {
      var _this17 = this;
      var id = _ref15.id,
        title = _ref15.title;
      adminControlsAPI.editControlRiskTypeAPI(id, {
        title: title
      }).then(function () {
        _this17.showSuccessfullNotification(_this17.translate.controlRiskType, title, notificationTypes.EDIT);
        return Promise.all([_this17.getRisks(), _this17.getControlRiskTypes()]);
      }).catch(function (err) {
        return _this17.showError(err.response.error);
      });
    },
    deleteControlRiskType: function deleteControlRiskType(_ref16) {
      var _this18 = this;
      var title = _ref16.title,
        id = _ref16.id;
      if (this.typeIsInUse(id)) {
        this.showError(this.translate.cannotDeleteRiskTypesThatAreInUse);
        return;
      }
      adminControlsAPI.deleteControlRiskTypeAPI(id).then(function () {
        _this18.showSuccessfullNotification(_this18.translate.controlRiskType, title, notificationTypes.DELETE);
        return _this18.getControlRiskTypes();
      }).catch(function (err) {
        return _this18.showError(err.response.error);
      });
    },
    typeIsInUse: function typeIsInUse(id) {
      return this.risks.some(function (risk) {
        return risk.riskTypeId === id;
      });
    },
    showSuccessfullNotification: function showSuccessfullNotification(groupName, title, type) {
      this.handleInstantNotification({
        text: "".concat(groupName, " ").concat(this.getNotificationTextByType(type)),
        footer: title
      });
    },
    getNotificationTextByType: function getNotificationTextByType(type) {
      switch (type) {
        case notificationTypes.EDIT:
          return this.translate.wasSuccessfullyUpdated;
        case notificationTypes.CREATE:
          return this.translate.wasSuccessfullyCreated;
        case notificationTypes.DELETE:
          return this.translate.itemWasSuccessfullyDeleted;
      }
    },
    showError: function showError(message) {
      this.notificationText = message;
      this.displayNotification();
    },
    displayNotification: function displayNotification() {
      var _this19 = this;
      clearTimeout(this.timer);
      window.scrollTo(0, 0);
      this.showNotification = true;
      this.timer = setTimeout(function () {
        _this19.showNotification = false;
      }, 3000);
    }
  }
};