function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import NewAsset from '@/components/Molecules/Questionnaire/NewAsset';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { UserLevels } from '@/constants/UserLevels';
export default {
  name: 'SingleAssetChoice',
  introduction: 'SingleAssetChoice is list to choose',
  description: 'SingleAssetChoice is select like component what return id in answered event.',
  token: "\n<single-asset-choice\n    :assets=\"informationAssets.systems\"\n    :selected=\"question.informationAssets.systems\"\n    type=\"systems\"\n    @answered=\"getSingleAsset($event, 'systems')\" />\n// Properties look like\nassets: [\n    {id:'1', name:'Hello'},\n    {id:'2', name:'To'},\n],\nselected: [{id:'2', name:'To'},]\n",
  components: {
    RismaTitle: RismaTitle,
    RismaSelect: RismaSelect,
    NewAsset: NewAsset
  },
  props: {
    type: {
      type: String,
      required: true,
      note: 'what type of asset for the title'
    },
    typeId: {
      type: Number,
      required: false,
      default: 0,
      note: 'id of asset type'
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of custom information assets activity types'
    },
    assets: {
      type: Array,
      required: true,
      note: 'all assets for the list'
    },
    myAssets: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    selected: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'the already selected assets'
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
      note: 'is be read-only'
    },
    partial: {
      required: false,
      type: Boolean,
      default: false,
      note: 'If false the component displays as standalone asset choice item'
    }
  },
  emits: ['forceUpdate', 'newAsset', 'answered'],
  data: function data() {
    return {
      translate: getTranslate['SingleAssetChoice'](),
      justCreatedAssets: []
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser', 'userHasAccessLevel'])), {}, {
    typeLabel: function typeLabel() {
      if (this.typeId) return this.getTypeLabelById(this.typeId);
      return this.type;
    },
    capitalizedTypeLabel: function capitalizedTypeLabel() {
      return this.$trans(this.typeLabel[0].toUpperCase() + this.typeLabel.slice(1));
    },
    userHasIaAccess: function userHasIaAccess() {
      return this.currentUser.iaAccess;
    },
    showAddNewAsset: function showAddNewAsset() {
      return this.getSettingValue('feature.add_assets_from_questionnaire');
    },
    getRightType: function getRightType() {
      return this.type === AssetsTypes.INFORMATION_ASSETS_API ? AssetsTypes.CUSTOM : this.type;
    },
    myAssetsIds: function myAssetsIds() {
      return this.myAssets.map(function (asset) {
        return asset.id;
      });
    },
    selectedByOthers: function selectedByOthers() {
      var _this = this;
      if (!this.onlyMyInformationAssets) return [];
      return this.selected.filter(function (item) {
        return !_this.myAssetsIds.includes(item.id);
      });
    },
    selectedByOthersDisplay: function selectedByOthersDisplay() {
      return this.selectedByOthers.map(function (item) {
        return item.title;
      }).join(', ');
    },
    assetsOptions: function assetsOptions() {
      var _this2 = this;
      if (!this.onlyMyInformationAssets) return this.assets;
      return this.assets.filter(function (item) {
        return _this2.myAssetsIds.includes(item.id);
      });
    },
    assetsSelected: function assetsSelected() {
      var _this3 = this;
      if (!this.onlyMyInformationAssets) return this.selected;
      return this.selected.filter(function (item) {
        return _this3.myAssetsIds.includes(item.id);
      });
    },
    onlyMyInformationAssets: function onlyMyInformationAssets() {
      return this.getSettingValue('feature.ia_limit_access_in_question') && !this.userHasAccessLevel('level_information_assets', UserLevels.SUPER);
    }
  }),
  methods: {
    emitChanges: function emitChanges(assets) {
      var data = [].concat(_toConsumableArray(assets), _toConsumableArray(this.selectedByOthers));
      this.$emit('answered', {
        data: data,
        type: this.getRightType
      });
    },
    getTypeLabelById: function getTypeLabelById(id) {
      return this.activityTypes.find(function (item) {
        return item.id === id;
      }).label;
    }
  }
};