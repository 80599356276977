import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { Colors } from '@/Colors';
export default {
  name: 'RismaStepProgressBar',
  introduction: 'RismaStepProgressBar shows progress of the auditflow',
  description: 'After users have send the questionnaire it is outmost important that the users can see the progress/status of the auditflow. ',
  token: '<risma-step-progress-bar :data="information"/>',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    data: {
      required: true,
      type: [Object, Array],
      note: 'needed information to render the element, it looks like data: { firstElement: { state: ``, titles: []} secondElement: {state: ``, titles: []} ...}. State can be "approved|wait|declined',
      default: function _default() {
        return {
          firstElement: {
            state: 'declined',
            titles: ['it`s empty']
          }
        };
      }
    }
  },
  data: function data() {
    return {
      colors: Colors
    };
  },
  methods: {
    getColor: function getColor(element) {
      if (element === 'approved') {
        return this.colors.findIncidentSeverity(1).color;
      } else if (element === 'wait') {
        return this.colors.findIncidentSeverity(0).color;
      } else if (element === 'declined') {
        return this.colors.findIncidentSeverity(3).color;
      }
    },
    getIcon: function getIcon(element) {
      return element == 'approved' ? 'check-circle' : 'x-circle';
    }
  }
};