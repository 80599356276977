import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4412c43b"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "control-notification"
};
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = {
  key: 0,
  class: "mb-4"
};
var _hoisted_5 = {
  key: 1
};
var _hoisted_6 = {
  class: "mb-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$data$control$userId;
  var _component_notification = _resolveComponent("notification");
  var _component_router_link = _resolveComponent("router-link");
  var _component_notification_pop_up = _resolveComponent("notification-pop-up");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_delegation_well = _resolveComponent("delegation-well");
  var _component_control_relations_section_details = _resolveComponent("control-relations-section-details");
  var _component_dep_well = _resolveComponent("dep-well");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [$data.isNotificationShow ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: $data.notificationType,
    onDismiss: $options.handleDismissNotification
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.notificationMessage), 1 /* TEXT */), _createElementVNode("div", {
        innerHTML: $data.notificationDetails
      }, null, 8 /* PROPS */, _hoisted_2)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type", "onDismiss"])) : _createCommentVNode("v-if", true), $options.isLockedControlUser ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    closeable: false,
    type: "info"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($options.lockedUserMessage), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), ((_$data$control$userId = $data.control.userIds) === null || _$data$control$userId === void 0 ? void 0 : _$data$control$userId.responsible.length) === 0 ? (_openBlock(), _createBlock(_component_notification, {
    key: 2,
    closeable: false,
    type: "info"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.controlHasNoOwnerMessage1), 1 /* TEXT */), _createElementVNode("div", {
        innerHTML: $data.translate.controlHasNoOwnerMessage2
      }, null, 8 /* PROPS */, _hoisted_3)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _ctx.notificationData ? (_openBlock(), _createBlock(_component_notification_pop_up, {
    key: 3,
    onDismiss: _ctx.dismissNotificationPopUp
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.success) + "! ", 1 /* TEXT */), _createVNode(_component_router_link, {
        to: _ctx.notificationData.url,
        class: "disabled-hover"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.notificationData.title), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onDismiss"])) : _createCommentVNode("v-if", true)]), _createVNode(_component_dep_well, {
    item: $options.controlExtended,
    "dep-header-props": $options.depHeaderProps,
    "is-loaded": $data.isLoaded,
    "local-storage-key": "relations-control",
    onUpdatingAttachments: $options.updatingAttachments,
    onFileUpdate: $options.onFileUpdate,
    onPropertyChanged: $options.controlPropertyChanged,
    onGetActivityData: $options.getActivityData,
    onSaveStatusClicked: $options.saveStatusClicked,
    onNotify: $options.handleShowNotify,
    onOnCompleteControl: $options.onCompleteControl,
    onShowActiveStateNotify: $options.showActiveStateNotify,
    onChangeTab: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changeTab', $event);
    })
  }, {
    delegation: _withCtx(function () {
      return [$data.control.mothercontrol && $data.control.mothercontrolId || $data.control.isMothercontrol ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_title, {
        title: $data.translate.motherControl,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), $data.control.mothercontrolId ? (_openBlock(), _createBlock(_resolveDynamicComponent($options.isMotherOrDaughterControlsLinkDisable ? 'div' : 'router-link'), {
        key: 0,
        to: $data.control.mothercontrol.url2
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("span", {
            class: _normalizeClass({
              'line-through': !$data.control.mothercontrol.active
            })
          }, _toDisplayString("".concat($data.control.mothercontrol.cpath, " ").concat($data.control.mothercontrol.title)), 3 /* TEXT, CLASS */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $data.control.isMothercontrol ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("p", _hoisted_6, _toDisplayString($data.translate.thisIsAMotherControlItHasNoResponsibleO), 1 /* TEXT */), _createVNode(_component_risma_title, {
        title: $data.translate.daughterControls,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.control.daughterControls, function (daughterControl) {
        return _openBlock(), _createBlock(_resolveDynamicComponent($options.isMotherOrDaughterControlsLinkDisable || !$options.controlIdsAccessibleByUser.includes(+daughterControl.id) ? 'div' : 'router-link'), {
          key: "control".concat(daughterControl.id),
          to: daughterControl.url2,
          title: $options.prepareOrganisationTitles(daughterControl),
          class: "block"
        }, {
          default: _withCtx(function () {
            return [_createVNode(_component_traffic_light, {
              color: $data.Colors.trafficlightColor(daughterControl.trafficLight, true).color,
              class: "inline-block"
            }, null, 8 /* PROPS */, ["color"]), _createElementVNode("span", {
              class: _normalizeClass({
                'line-through': !daughterControl.active
              })
            }, _toDisplayString("".concat(daughterControl.cpath, " ").concat(daughterControl.title)), 3 /* TEXT, CLASS */)];
          }),

          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "title"]);
      }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $options.delegationData ? (_openBlock(), _createBlock(_component_delegation_well, {
        key: 1,
        "model-value": $options.delegationData,
        organisations: _ctx.organisations,
        "read-only": $options.readOnly,
        onPropertyChanged: $options.saveChangesImmediately,
        onAddNewItem: _ctx.updateDelegationList
      }, null, 8 /* PROPS */, ["model-value", "organisations", "read-only", "onPropertyChanged", "onAddNewItem"])) : _createCommentVNode("v-if", true)];
    }),
    details: _withCtx(function () {
      return [_createVNode(_component_control_relations_section_details, {
        "activity-types": _ctx.activityTypes,
        control: $data.control,
        "mother-controls": $data.motherControls,
        accounts: $data.accounts,
        processes: $data.processes,
        risks: $data.risks,
        onPropertyChanged: $options.controlPropertyChanged
      }, null, 8 /* PROPS */, ["activity-types", "control", "mother-controls", "accounts", "processes", "risks", "onPropertyChanged"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["item", "dep-header-props", "is-loaded", "onUpdatingAttachments", "onFileUpdate", "onPropertyChanged", "onGetActivityData", "onSaveStatusClicked", "onNotify", "onOnCompleteControl", "onShowActiveStateNotify"])]);
}