function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapActions } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
import { Colors } from '@/Colors';
import * as tagsAPI from '@/api/tags/tags';
export default {
  name: 'TagCreationModal',
  components: {
    InputField: InputField,
    Notification: Notification,
    Modal: Modal
  },
  props: {
    currentTagId: {
      required: false,
      type: Number,
      default: null,
      note: 'if currentTagId exists it should update current tag instead of creation new one'
    },
    tags: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['dismiss', 'create', 'update'],
  data: function data() {
    return {
      color: Colors,
      updatedTagName: '',
      modalErrorMessage: '',
      translate: getTranslate['TagCreationModal']()
    };
  },
  computed: {
    header: function header() {
      return this.currentTagId ? this.translate.renameTag : this.translate.createTag;
    },
    creationMode: function creationMode() {
      return !this.currentTagId;
    },
    currentTag: function currentTag() {
      var _this = this;
      return this.tags.find(function (tag) {
        return tag.id === _this.currentTagId;
      });
    },
    preparedTagName: function preparedTagName() {
      return this.updatedTagName.trim().replace(/  +/g, ' ');
    }
  },
  mounted: function mounted() {
    var _this$currentTag;
    this.updatedTagName = ((_this$currentTag = this.currentTag) === null || _this$currentTag === void 0 ? void 0 : _this$currentTag.tag) || '';
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTagsStore, {
    newTag: 'newTag',
    updateTag: 'updateTag'
  })), {}, {
    handleAccept: function handleAccept() {
      if (this.currentTagId && this.currentTag.name === this.preparedTagName) {
        this.$emit('dismiss');
        return;
      }
      if (!this.isTagValidToSave()) return;
      if (this.creationMode) {
        this.createNewTag();
      } else {
        this.updateSelectedTag();
      }
    },
    createNewTag: function createNewTag() {
      var _this2 = this;
      var data = {
        tagName: this.preparedTagName
      };
      tagsAPI.create(data).then(function (response) {
        _this2.handleTagSuccess('create', response);
        _this2.newTag(response);
      }).catch(this.handleTagFailure);
    },
    updateSelectedTag: function updateSelectedTag() {
      var _this3 = this;
      var data = {
        name: this.preparedTagName
      };
      tagsAPI.update(this.currentTagId, data).then(function (response) {
        _this3.handleTagSuccess('update', response);
        _this3.updateTag(response);
      }).catch(this.handleTagFailure);
    },
    handleTagSuccess: function handleTagSuccess(emit, response) {
      this.$emit(emit, response);
    },
    handleTagFailure: function handleTagFailure(error) {
      this.modalErrorMessage = error.response;
    },
    isTagValidToSave: function isTagValidToSave() {
      var _this4 = this;
      if (!this.preparedTagName) {
        this.modalErrorMessage = 'Title cannot be empty';
        return false;
      }
      var hasDuplicates = this.tags.some(function (t) {
        return t.tag === _this4.preparedTagName;
      });
      if (hasDuplicates) {
        this.modalErrorMessage = 'Tag exist';
      }
      return !hasDuplicates;
    }
  })
};