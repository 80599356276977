function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import { stripRecognisedHTMLOnly } from '@/utils';
import { getUserLanguage, generateKey } from '@/utils/Utils';
import { focus } from '@/utils/directives/focus';
var inputTypes = ['color', 'date', 'email', 'number', 'password', 'search', 'text', 'url', 'file'];
var MINUS_CHAR_CODE = 45;
export default {
  name: 'InputField',
  introduction: 'for when the user is typing',
  description: 'This is a simple input field that emits an "updated"-event when the value changes',
  token: '<input-field type="text" preText="Already filled out data"/>',
  directives: {
    focus: focus
  },
  components: {
    RismaLabel: RismaLabel
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
      validator: function validator(type) {
        return inputTypes.indexOf(type) !== -1;
      },
      note: "A type of input html tag. Possible values: ".concat(inputTypes.join(', '))
    },
    preText: {
      required: false,
      type: String,
      default: '',
      note: 'text that if any should be written in the input field'
    },
    required: {
      type: Boolean,
      default: false,
      note: 'HTML5 Required input field'
    },
    placeholder: {
      required: false,
      type: String,
      default: '',
      note: 'placeholder text that is written when input is empty'
    },
    modelValue: {
      required: false,
      type: [String, Number],
      //u can use Number for type 'number' (then v-model can work with it)
      default: '',
      note: 'v-model'
    },
    enabled: {
      required: false,
      type: Boolean,
      default: true,
      note: 'Used to enable/disable field, e.g. for showing read-only pages'
    },
    maxlength: {
      required: false,
      type: [Number, String],
      default: null,
      note: 'DOM native maxlength property for input'
    },
    max: {
      required: false,
      type: [Number, String],
      default: null,
      note: 'DOM native max property for input'
    },
    min: {
      required: false,
      type: [Number, String],
      default: null,
      note: 'DOM native min property for input'
    },
    step: {
      required: false,
      type: [Number, String],
      default: null,
      note: 'DOM native step property for input'
    },
    externalStyles: {
      required: false,
      type: String,
      default: '',
      note: 'Styles for input element'
    },
    tabindex: {
      type: [Number, String],
      required: false,
      default: 0,
      note: 'index for tabbing'
    },
    autocomplete: {
      type: String,
      required: false,
      default: undefined,
      note: 'autocomplete if possible'
    },
    enableCurrencyTemplate: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Determine if currency format will be enabled. Use it only with type="text"'
    },
    accept: {
      type: String,
      require: false,
      default: '',
      note: 'to define the types of files that the component can select'
    },
    focusOnMount: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Determine if input will be focused after mounting'
    },
    normalizeFn: {
      type: Function,
      required: false,
      default: null,
      note: 'If specified the value after input changed will be overwritten by returned value'
    },
    invalid: {
      type: Boolean,
      required: false,
      default: null,
      note: 'Use this with true value to add some red borders around input field'
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    inputId: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['updated', 'input', 'inputNative', 'update:modelValue', 'focus', 'blur', 'onEnter', 'chooseFileInit'],
  data: function data() {
    return {
      rawText: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    id: function id() {
      return this.inputId || (this.title ? generateKey() : null);
    },
    editedText: function editedText() {
      if (this.enableCurrencyTemplate) {
        return this.rawText ? this.rawText.toString().split(this.separator.thousand).join('') : '';
      }
      return this.rawText;
    },
    saveFormat: function saveFormat() {
      if (this.enableCurrencyTemplate) {
        var value = this.editedText.split(this.separator.decimal);
        var leftSide = value[0];
        var rightSide = value[1] || '00';
        return leftSide + '.' + rightSide;
      }
      if (this.type === 'number') {
        return this.rawText === '' ? '' : +this.rawText;
      }
      return this.rawText;
    },
    locale: function locale() {
      return getUserLanguage(this.currentUser) || 'en';
    },
    separator: function separator() {
      var tempValue = 1111.1;
      var formatedValue = new Intl.NumberFormat(this.locale).format(tempValue);
      return {
        decimal: formatedValue[formatedValue.length - 2],
        thousand: formatedValue[1]
      };
    },
    decimalCharCode: function decimalCharCode() {
      if (this.enableCurrencyTemplate) {
        return this.separator.decimal.charCodeAt(0);
      }
      return 46;
    },
    baseStyles: function baseStyles() {
      var result = 'w-full border-2';
      result += this.invalid ? ' border-red-50' : ' border-gray-350';
      result += ' px-3 py-2 rounded-md min-h-40px transition duration-300 text-base text-gray-750 placeholder-italic placeholder-gray-550';
      return result;
    },
    interactionStyles: function interactionStyles() {
      var base = 'focus:outline-none disabled:text-gray-650';
      if (this.enableCurrencyTemplate) {
        base += ' lining-nums tracking-wider';
      }
      if (!this.invalid && this.isInputValid) {
        base += ' hover:border-gray-450 focus:border-blue-450';
      } else {
        base += ' focus:border-red-50';
      }
      return base;
    },
    isInputValid: function isInputValid() {
      if (this.type !== 'text' || this.enableCurrencyTemplate) return true;
      return this.rawText === stripRecognisedHTMLOnly((this.editedText || '') + '');
    }
  }),
  watch: {
    preText: {
      handler: function handler() {
        this.setText(this.preText);
      }
    },
    modelValue: function modelValue() {
      if (this.modelValue !== this.saveFormat) {
        this.setText(this.modelValue);
      }
    }
  },
  created: function created() {
    this.setText(this.preText || this.modelValue);
  },
  methods: {
    validateNumber: function validateNumber(evt) {
      //fixes safari bug
      if (this.type !== 'number' && !this.enableCurrencyTemplate) return;
      var charCode = evt.keyCode || evt.key.charCodeAt(0);
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== +this.decimalCharCode && charCode !== MINUS_CHAR_CODE) {
        evt.preventDefault();
        return;
      }
      if (charCode === +this.decimalCharCode && evt.target.value.indexOf(this.separator.decimal) !== -1) {
        evt.preventDefault();
      }
    },
    chooseFileInit: function chooseFileInit(event) {
      this.type === 'file' && this.$emit('chooseFileInit', event);
    },
    formatValue: function formatValue() {
      var _this$editedText;
      this.rawText = (_this$editedText = this.editedText) !== null && _this$editedText !== void 0 ? _this$editedText : '';
    },
    formatCurrency: function formatCurrency() {
      var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      value = value || this.saveFormat;
      var formatedValue = new Intl.NumberFormat(this.locale).format(value);
      if (formatedValue === 'NaN') return;
      if (formatedValue.indexOf(this.separator.decimal) === -1) {
        formatedValue += this.separator.decimal + '00';
      }
      this.rawText = formatedValue || '';
    },
    setText: function setText(text) {
      this.rawText = text !== null && text !== void 0 ? text : '';
      if (this.enableCurrencyTemplate && this.type === 'text') {
        this.formatCurrency(text);
      }
    },
    emit: function emit(event) {
      if (!this.enabled) return;
      if (this.normalizeFn) {
        this.setText(this.normalizeFn(this.rawText));
      }
      this.$emit('inputNative', event);
      if (this.isInputValid) {
        this.emitUpdate();
      }
    },
    emitUpdate: function emitUpdate() {
      this.$emit('update:modelValue', this.saveFormat);
      this.$emit('updated', this.saveFormat);
    },
    onFocus: function onFocus() {
      this.formatValue();
      this.$emit('focus', this.saveFormat);
    },
    onBlur: function onBlur() {
      if (this.enableCurrencyTemplate && this.type === 'text') {
        this.formatCurrency();
      }
      if (!this.isInputValid) {
        this.rawText = stripRecognisedHTMLOnly(this.rawText);
        this.emitUpdate();
      }
      this.$emit('blur', this.saveFormat);
    },
    onClick: function onClick(event) {
      this.chooseFileInit(event);
    },
    onEnter: function onEnter(event) {
      this.chooseFileInit(event);
      this.$emit('onEnter', this.saveFormat);
    }
  }
};