import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-c7bf3c96"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "kitchen-component-header"
};
var _hoisted_2 = {
  class: "introduction"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $props.component.name,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_2, _toDisplayString($props.component.introduction), 1 /* TEXT */)]);
}