import { ObjectTypes } from '@/constants/ObjectTypes';
import { UserLevels } from '@/constants/UserLevels';
export function userHasAccessToComplianceProjects(user, settingValue) {
  var isComplianceOn = settingValue === 1;
  var userHasComplianceAccess = user.projects && user.projects.some(function (project) {
    return project.objType === ObjectTypes.COMPLIANCE_PROJECT && project.access_level > UserLevels.NONE;
  });
  return !!(isComplianceOn && userHasComplianceAccess);
}
export function isLockedControlUser(user) {
  return user.level_c_locked === 1;
}
export function isLockedRiskUser(user) {
  return user.level_r_locked === 1;
}
export function isLockedActionUser(user) {
  return user.level_e_locked === 1;
}
export function isLockedIncidentUser(user) {
  return user.level_incidents_locked === 1;
}
export function isLockedContractUser(user) {
  return user.level_contracts_locked === 1;
}
export function isLockedProcessLibrary(user) {
  var _user$AccessLevels;
  return !!(user !== null && user !== void 0 && (_user$AccessLevels = user.AccessLevels) !== null && _user$AccessLevels !== void 0 && (_user$AccessLevels = _user$AccessLevels.PolicyManagement) !== null && _user$AccessLevels !== void 0 && _user$AccessLevels.locked);
}
export function isBelowSuperUser(user, levelType) {
  return user[levelType] < UserLevels.SUPER;
}
export function isActionsLevelBelowSuperUser(user) {
  return isBelowSuperUser(user, 'level_execution');
}
export function checkControlsOrgAccess(user) {
  var userLevel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : UserLevels.SUPER;
  return !!(user.level_controls >= userLevel || user.level_admin);
}
export function userHasProjectAccess(user, projectId, projectType) {
  var userLevel = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : UserLevels.NORMAL;
  return user.projects && user.projects.some(function (project) {
    return +project.id === +projectId && project.objType === projectType && project.access_level >= userLevel;
  });
}
export function userHasSomeProjectAccess(user, projectType) {
  var userLevel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  return user.projects && user.projects.find(function (project) {
    return project.enabled && project.objType === projectType && project.access_level >= userLevel;
  });
}