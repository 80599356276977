import DevHeader from '@/components/Pages/Dev/DevHeader';
import DevFooter from '@/components/Pages/Dev/DevFooter';
import { camelToKebab } from '@/utils';
export function addKitchenChildren(data, components) {
  var section = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'KitchenSink';
  var result = [];
  data.map(function (item) {
    var mainComponent = components["".concat(item, "Kitchen")];
    var path = camelToKebab(item);
    if (path[0] === '-') {
      path = path.substring(1);
    }
    result.push({
      path: path,
      name: "".concat(section, "_").concat(item, "Kitchen"),
      components: {
        header: DevHeader,
        default: mainComponent,
        footer: DevFooter
      },
      props: {
        header: {
          component: mainComponent.components[item]
        },
        footer: {
          component: mainComponent.components[item]
        }
      }
    });
  });
  return result;
}