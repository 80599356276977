import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "px-4"
};
var _hoisted_2 = {
  key: 2
};
var _hoisted_3 = {
  class: "flex justify-between items-center text-gray-610"
};
var _hoisted_4 = {
  class: "border border-gray-450 rounded rounded-l-none p-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$data$gapSchema;
  var _component_notification = _resolveComponent("notification");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_gap_schemas_tabs = _resolveComponent("gap-schemas-tabs");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.notification.message ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: $data.notification.type,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $options.setNotification({
        message: ''
      });
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.notification.message), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: ((_$data$gapSchema = $data.gapSchema) === null || _$data$gapSchema === void 0 ? void 0 : _$data$gapSchema.title) || $data.translate.complianceAdministration
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_button, {
    text: $data.translate.back,
    type: "util",
    onClick: $options.backToPrevPage
  }, null, 8 /* PROPS */, ["text", "onClick"])])])), _createVNode(_component_gap_schemas_tabs), _createElementVNode("div", _hoisted_4, [_createVNode(_component_router_view, {
    data: $options.changelogGapSchemaObject,
    onSaved: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.notificationMessage = $event;
    }),
    onNotify: $options.setNotification
  }, null, 8 /* PROPS */, ["data", "onNotify"])])]);
}