function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { ChoiceViewTypes } from '@/constants/ChoiceViewTypes';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
export default {
  name: 'MultiChoice',
  introduction: 'MultiChoice is RismaSelect or Checkboxes component',
  description: 'Ability to select multiple values from the drop-down or checkboxes, uses vue-multiselect as base.',
  token: "\n<multi-choice options=\"options\" answers=\"answers\" @answered=\"fn\"/>\n// Properties looks like\nconst options = {\n    9720:'1-1,000',\n    9721:'1,001-10,000',\n    9725:'Above 10,000,000',\n    9726:'Not possible to indicate',\n};\nconst answers = {\n    9720:'1-1,000',\n    9725:'Above 10,000,000',\n},\n",
  components: {
    Checkboxes: Checkboxes,
    RismaSelect: RismaSelect
  },
  props: {
    options: {
      required: true,
      type: [Object, Array],
      note: 'A list of possible answers'
    },
    answers: {
      required: true,
      type: [Object, Array],
      note: 'A list of predefined answers'
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
      note: 'is be read-only'
    }
  },
  emits: ['forceUpdate', 'answered'],
  data: function data() {
    return {
      selected: [],
      values: [],
      cbValue: [],
      isOpen: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    viewTypes: function viewTypes() {
      return ChoiceViewTypes;
    },
    viewType: function viewType() {
      return this.getSettingValue('risma.multiple_choice_as_checkbox') ? ChoiceViewTypes.CHECKBOX : ChoiceViewTypes.DROPDOWN;
    },
    cbOptions: function cbOptions() {
      return this.options.map(function (option) {
        return {
          value: option.id,
          label: option.label
        };
      });
    },
    cbAnswers: function cbAnswers() {
      return Object.keys(this.answers).map(function (i) {
        return +i;
      });
    },
    disabledOptions: function disabledOptions() {
      return this.readOnly ? this.cbOptions : [];
    }
  }),
  watch: {
    options: {
      handler: function handler() {
        this.optionsToValues();
      },
      deep: true
    },
    answers: {
      handler: function handler() {
        this.answersToSelected();
      },
      deep: true
    }
  },
  created: function created() {
    this.optionsToValues();
    this.answersToSelected();
    this.cbValue = Object.keys(this.answers).map(function (i) {
      return +i;
    });
  },
  methods: {
    createLabel: function createLabel(option) {
      return option.option;
    },
    setAnswers: function setAnswers(newVal) {
      var answers = {};
      if (newVal !== null) {
        newVal.forEach(function (v) {
          answers[v.id] = v.option;
        });
      }
      this.$emit('answered', answers);
      if (!this.isOpen) {
        this.$emit('forceUpdate');
      }
    },
    setAnswerRadioButton: function setAnswerRadioButton(answeredOptions) {
      var answers = {};
      if (answeredOptions) {
        answeredOptions.forEach(function (option) {
          return answers[option.value] = option.label;
        });
      }
      this.$emit('answered', answers);
      this.$emit('forceUpdate');
    },
    optionsToValues: function optionsToValues() {
      var values = [];
      for (var key in this.options) {
        var option = this.options[key];
        values.push({
          'id': +option.id,
          'option': option.label,
          'orderBy': +option.orderBy
        });
      }
      this.values = values.sort(function (a, b) {
        return a.orderBy - b.orderBy;
      });
    },
    answersToSelected: function answersToSelected() {
      var _this = this;
      var selected = [];
      if (this.answers.null === undefined) {
        var keys = Object.keys(this.answers);
        this.values.forEach(function (value) {
          var key = keys.find(function (key) {
            return value.id === +key;
          });
          if (key) selected.push({
            'id': +key,
            'option': _this.answers[key]
          });
        });
      }
      this.selected = selected;
    }
  }
};