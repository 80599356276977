function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import cloneDeep from 'lodash/cloneDeep';
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { patchUtilSettings } from '@/api/risma/settings';
import * as RiskAPI from '@/api/risk/risk';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import { cacheItem } from '@/routes/storeCache';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
var APPROVAL_FLOW_RISK_PROJECT_TPL = 'approvalflow_risk_project-';
var CONFIDENTIAL_RISK_PROJECT_TPL = 'confidential_risk_project-';
export default {
  name: 'ControlPanelSettings',
  description: 'Universal section for control panel settings',
  token: '<control-panel-settings>',
  components: {
    RismaToggle: RismaToggle,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    SingleSelect: SingleSelect,
    InputField: InputField,
    RismaButton: RismaButton,
    Notification: Notification
  },
  props: {
    settingsGroups: {
      type: Object,
      required: true,
      note: 'Object with all the settings'
    },
    filteredRiskProjects: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All projects except ERM'
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    responsibleUsers: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['updateHeader'],
  data: function data() {
    return {
      showSaveSuccess: false,
      showSaveError: false,
      tips: [],
      errors: [],
      settings: {},
      translate: getTranslate['ControlPanelSettings']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['isAdmin'])), {}, {
    isReadonly: function isReadonly() {
      return !this.isAdmin;
    },
    responsibleOptions: function responsibleOptions() {
      return this.responsibleUsers.map(function (user) {
        return {
          label: user.display_name,
          value: user.id
        };
      });
    },
    filteredActivityTypes: function filteredActivityTypes() {
      return this.activityTypes.map(function (item) {
        return {
          label: item.label,
          value: item.id
        };
      });
    },
    translatedTips: function translatedTips() {
      var _this = this;
      return this.tips.map(function (tip) {
        var _tip$minValue;
        var name = _this.$trans(tip.name);
        var message = _this.translate.mustBeThan.replace('%s', tip.maxValue ? _this.translate.lower : _this.translate.higher).toLowerCase();
        return "".concat(name, " ").concat(message, " ").concat((_tip$minValue = tip.minValue) !== null && _tip$minValue !== void 0 ? _tip$minValue : tip.maxValue);
      });
    },
    translatedErrors: function translatedErrors() {
      var _this2 = this;
      return this.errors.map(function (error) {
        return _this2.$trans(error);
      });
    }
  }),
  mounted: function mounted() {
    this.prepareSettings();
    var _this$getPreparedData = this.getPreparedData(this.settings),
      data = _this$getPreparedData.data;
    this.validateData(data);
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useSettingsStore, {
    loadSettings: 'load'
  })), mapActions(useUserStore, {
    loadCurrentUser: 'load'
  })), {}, {
    prepareSettings: function prepareSettings() {
      this.settings = cloneDeep(this.settingsGroups);
      for (var group in this.settings) {
        var settingsGroup = this.settings[group];
        for (var settingName in settingsGroup) {
          if (this.getSettingValue(settingName) !== undefined) {
            if (settingsGroup[settingName].type === 'intvalue' && settingsGroup[settingName].dropdown) {
              var _this$getSettingValue;
              this.settings[group][settingName].selectedValue = (_this$getSettingValue = this.getSettingValue(settingName)) === null || _this$getSettingValue === void 0 ? void 0 : _this$getSettingValue.toString();
            } else {
              this.settings[group][settingName].selectedValue = this.getSettingValue(settingName);
            }
          } else {
            this.settings[group][settingName].selectedValue = this.settings[group][settingName].default;
          }
        }
      }
    },
    getSingleSelectArray: function getSingleSelectArray(elements) {
      var translatable = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var hasResetElement = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var allSet = [];
      if (hasResetElement) {
        allSet.push({
          value: '',
          label: this.translate.notSet
        });
      }
      for (var index in elements) {
        allSet.push({
          value: index,
          label: translatable ? elements[index] : this.$trans(elements[index])
        });
      }
      return allSet;
    },
    saveSettings: function saveSettings() {
      var _this3 = this;
      var _this$getPreparedData2 = this.getPreparedData(this.settings),
        data = _this$getPreparedData2.data,
        updateHeader = _this$getPreparedData2.updateHeader;
      this.showSaveError = false;
      this.showSaveSuccess = false;
      if (!this.validateData(data)) return;
      var updRiskProjectsApprovalFlow = this.getAndRemoveRiskProjectsFlowFromSettings(this.filteredRiskProjects.filter(function (item) {
        return item.enabled;
      }), data);
      var dataToSaveInSettings = {};
      Object.keys(data).forEach(function (key) {
        dataToSaveInSettings[key] = data[key].value;
      });
      var promises = [patchUtilSettings(dataToSaveInSettings)];
      updRiskProjectsApprovalFlow.forEach(function (project) {
        promises.push(RiskAPI.updateRiskProject(project.projectId, project));
      });
      Promise.all(promises).then(function () {
        _this3.showSaveSuccess = true;
        _this3.$scrollTo(document.body, 100);
        if (updateHeader) {
          _this3.$emit('updateHeader');
        }
      }).then(function () {
        cacheItem('user', function () {
          return _this3.loadCurrentUser();
        }, 180);
        cacheItem('settings', function () {
          return _this3.loadSettings();
        }, 180);
      }).catch(function () {
        return _this3.showSaveError = true;
      });
    },
    getAndRemoveRiskProjectsFlowFromSettings: function getAndRemoveRiskProjectsFlowFromSettings(riskProjects, settings) {
      var updatedRiskProjectsFlow = [];
      var changeableSettings = [APPROVAL_FLOW_RISK_PROJECT_TPL, CONFIDENTIAL_RISK_PROJECT_TPL];
      var _loop = function _loop(setting) {
        var isChangeableSetting = changeableSettings.some(function (item) {
          return setting.indexOf(item) !== -1;
        });
        if (isChangeableSetting) {
          var flowRiskProjectId = +setting.substring(changeableSettings.find(function (item) {
            return setting.indexOf(item) !== -1;
          }).length);
          updatedRiskProjectsFlow.push(_defineProperty({
            projectId: flowRiskProjectId
          }, settings[setting].settingField, settings[setting].value));
          delete settings[setting];
        }
      };
      for (var setting in settings) {
        _loop(setting);
      }
      return updatedRiskProjectsFlow;
    },
    getPreparedData: function getPreparedData(settings) {
      var _this4 = this;
      var data = {},
        updateHeader = false;
      this.tips = [];
      Object.entries(settings).forEach(function (groupEntry) {
        var _groupEntry = _slicedToArray(groupEntry, 2),
          groupKey = _groupEntry[0],
          settingsGroup = _groupEntry[1];
        Object.entries(settingsGroup).forEach(function (setting) {
          var _setting = _slicedToArray(setting, 2),
            key = _setting[0],
            value = _setting[1];
          var type = value.type,
            selectedValue = value.selectedValue,
            defaultValue = value.default,
            notEmpty = value.notEmpty,
            name = value.name;
          if (type == 'boolvalue') {
            selectedValue = selectedValue ? 1 : 0;
          } else {
            if (notEmpty && !selectedValue) {
              selectedValue = _this4.settingsGroups[groupKey][key].default;
            }
            if (type === 'intvalue' && value.minValue !== undefined && +selectedValue < +value.minValue) {
              selectedValue = value.minValue;
              _this4.tips.push({
                name: name,
                minValue: value.minValue - 1
              });
            }
            if (type === 'intvalue' && value.maxValue !== undefined && +selectedValue > +value.maxValue) {
              selectedValue = value.maxValue;
              _this4.tips.push({
                name: name,
                maxValue: value.maxValue + 1
              });
            }
          }
          if (value.updateHeader) {
            updateHeader = true;
          }
          settingsGroup[key].selectedValue = selectedValue;
          data[key] = {
            default: defaultValue,
            value: selectedValue,
            settingField: value.settingField || ''
          };
          if (value.rules) {
            data[key].rules = value.rules;
          }
        });
      });
      return {
        data: data,
        updateHeader: updateHeader
      };
    },
    validateData: function validateData(data) {
      var _Object$values,
        _this5 = this;
      this.errors = [];
      (_Object$values = Object.values(data)) === null || _Object$values === void 0 ? void 0 : _Object$values.forEach(function (setting) {
        if (!setting.rules || setting.value === setting.default) return;
        setting.rules.forEach(function (rule) {
          var _data$rule$setting;
          var ruleValue = (_data$rule$setting = data[rule.setting]) === null || _data$rule$setting === void 0 ? void 0 : _data$rule$setting.value;
          if (!rule.rule(ruleValue)) {
            _this5.errors.push(rule.warningMessage);
          }
        });
      });
      return !this.errors.length;
    }
  })
};