function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { MenuKeys } from '@/constants/menu/MenuKeys';
import { UserLevels } from '@/constants/UserLevels';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { ObjectTypes } from '@/constants/ObjectTypes';
function getAdminMenuItems(project) {
  return {
    url: '/compliance/tree/' + project.id,
    title: project.title,
    type: project.type,
    id: project.id,
    rights: true,
    class: 'complianceProject'
  };
}
function getReportsChildren(project) {
  var aliasRegex = new RegExp("compliance/reports/".concat(project.id, "/outsourcing/\\d+"));
  var reportsChildren = [];
  reportsChildren.push({
    url: '/compliance/reports/' + project.id + '/nodes'
  });
  reportsChildren.push({
    url: '/compliance/reports/' + project.id + '/gap'
  });
  reportsChildren.push({
    url: '/compliance/reports/' + project.id + '/dataflow'
  });
  reportsChildren.push({
    url: '/compliance/reports/' + project.id + '/outsourcing',
    aliasesRegex: aliasRegex
  });
  Object.keys(project.reports).forEach(function (report) {
    reportsChildren.push({
      url: '/compliance/reports/' + project.id + '/' + report
    });
  });
  return reportsChildren;
}
function showOverviewMenu(project) {
  return project.showOverviewMenuItem;
}
function showDashboardsMenu(project) {
  return project.type !== ProjectTypes.ESG;
}
function getProjectMenuItems(project, user, reportsChildren, i18n, settings) {
  var aliasRegex = new RegExp("compliance/".concat(project.id, "/edit/\\d+/\\w+"));
  var showOverview = showOverviewMenu(project);
  var showDashboards = showDashboardsMenu(project);
  var treeMenuItemTitle = 'Tree';
  var isISO = project.type === ProjectTypes.ISO27001;
  if (isISO) {
    treeMenuItemTitle = 'SoA';
  }
  return {
    url: '/compliance/tree/' + project.id,
    title: project.title,
    translatable: false,
    type: project.type,
    rights: true,
    aliasesRegex: aliasRegex,
    id: project.id,
    class: 'complianceProject',
    children: [{
      url: '/compliance/overview/' + project.id,
      title: 'Overview',
      rights: showOverview
    }, {
      url: '/compliance/tree/' + project.id,
      title: showOverview ? treeMenuItemTitle : 'Overview',
      translatable: isISO && showOverview ? false : true,
      rights: true
    }, {
      url: '/compliance/dashboard/' + project.id,
      title: 'Dashboards',
      rights: (project.access_level >= UserLevels.PRIVILEGED || user.is_admin) && showDashboards,
      children: [{
        url: '/compliance/dashboard/' + project.id + '/gap'
      }, {
        url: '/compliance/dashboard/' + project.id + '/questions'
      }]
    }, {
      url: '/compliance/reports/' + project.id,
      title: 'Reports',
      rights: project.access_level >= UserLevels.PRIVILEGED || user.is_admin,
      children: reportsChildren
    }, {
      url: '/compliance/supervisor/' + project.id,
      title: project.type === 1 ? i18n.t('DPO') : i18n.t('Supervisor'),
      rights: (settings['feature.supervisor_enabled'] ? true : false) && (project.supervisor === 1 || user.is_admin || project.access_level > UserLevels.SUPER) && showDashboards
    }]
  };
}
function addAdditionalMenuItems(menuStructure, user, settings) {
  var customIA = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  var iaBaseUrl = '/compliance/informationassets';
  if (settings['feature.policy_management_menu'] !== 1) {
    menuStructure.main.unshift({
      url: '/awareness/dashboard',
      title: 'Awareness',
      translatable: false,
      rights: settings['feature.awareness_enabled'] === 1 && user.policiesAccess,
      menuKey: MenuKeys.awareness
    });
  }
  var customList = customIA.map(function (item) {
    return {
      url: "".concat(iaBaseUrl, "/type/").concat(item.id),
      title: item.label,
      translatable: false,
      isHiddenInSubMenu: true,
      rights: user.iaAccess === true
    };
  });
  menuStructure.main.unshift({
    url: "".concat(iaBaseUrl, "/type"),
    title: settings['feature.information_assets_name'] || 'Information assets',
    translatable: !settings['feature.information_assets_name'],
    rights: user.iaAccess === true && settings['feature.asset_management_menu'] !== 1 && settings['feature.disable_information_assets'] !== 1,
    aliasesRegex: '/compliance/informationassets/*',
    menuKey: MenuKeys.ia,
    children: [{
      url: "".concat(iaBaseUrl, "/type/all"),
      title: 'Overview',
      rights: user.iaAccess === true
    }, {
      url: "".concat(iaBaseUrl, "/type/controllers"),
      title: 'Controllers',
      isHiddenInSubMenu: true,
      rights: user.iaAccess === true && (settings['feature.enable_data_controllers'] === undefined || settings['feature.enable_data_controllers'])
    }, {
      url: "".concat(iaBaseUrl, "/type/processors"),
      title: 'Processors',
      isHiddenInSubMenu: true,
      rights: user.iaAccess === true && (settings['feature.enable_data_processors'] === undefined || settings['feature.enable_data_processors'])
    }, {
      url: "".concat(iaBaseUrl, "/type/systems"),
      title: 'Systems',
      isHiddenInSubMenu: true,
      rights: user.iaAccess === true && (settings['feature.enable_systems'] === undefined || settings['feature.enable_systems'])
    }].concat(_toConsumableArray(customList), [{
      url: "".concat(iaBaseUrl, "/reports"),
      title: 'Reports',
      rights: user.iaAccess === true,
      children: [{
        url: "".concat(iaBaseUrl, "/reports/questionnaire")
      }, {
        url: "".concat(iaBaseUrl, "/reports/audit")
      }, {
        url: "".concat(iaBaseUrl, "/reports/answered")
      }]
    }])
  });
  menuStructure.main.unshift({
    url: '/compliance/processtree',
    title: settings['feature.process_library_name'] || 'Process Library',
    translatable: !settings['feature.process_library_name'],
    rights: settings['feature.process_tree'] === 1 && user.policiesAccess && settings['feature.policy_management_menu'] !== 1,
    aliasesRegex: /compliance\/processtree\/[\d]+\/[\w]+\/[\d]+\/[\w]+/,
    menuKey: MenuKeys.processLib,
    children: [{
      url: '/compliance/processtree',
      title: 'Overview',
      rights: settings['feature.process_tree'] === 1 && user.policiesAccess
    }, {
      url: '/compliance/processtree/report',
      title: 'Report',
      rights: settings['feature.process_tree_report'] === 1 && user.policiesAccess
    }]
  });
  menuStructure.admin.unshift({
    url: '/awareness/dashboard',
    title: 'Awareness',
    rights: settings['feature.awareness_enabled'] === 1 && user.policiesAccess
  });
  menuStructure.admin.unshift({
    url: '/compliance/informationassets',
    title: settings['feature.information_assets_name'] || 'Information assets',
    translatable: !settings['feature.information_assets_name'],
    rights: user.iaAccess === true
  });
  menuStructure.admin.unshift({
    url: '/compliance/processtree',
    title: settings['feature.process_library_name'] || 'Process Library',
    translatable: !settings['feature.process_library_name'],
    rights: settings['feature.process_tree'] === 1 && user.policiesAccess && settings['feature.policy_management_menu'] !== 1
  });
}
function buildComplianceMenu(projects, user, settings, i18n) {
  var customIA = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
  var menuStructure = {
    main: [],
    admin: []
  };
  projects.forEach(function (project) {
    if (project.access_level <= 0) return;
    if (project.objType !== ObjectTypes.COMPLIANCE_PROJECT) return;
    menuStructure.admin.push(getAdminMenuItems(project, user, settings));
    var reportsChildren = getReportsChildren(project);
    menuStructure.main.push(getProjectMenuItems(project, user, reportsChildren, i18n, settings));
  });
  addAdditionalMenuItems(menuStructure, user, settings, customIA);
  return menuStructure;
}
export default buildComplianceMenu;