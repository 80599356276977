var _excluded = ["options", "unit", "children"];
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import debounce from 'lodash/debounce';
import { getTranslate } from './translate';
import { getScoring as _getScoring, updateScoring, deleteScoring as _deleteScoring, updateTableCalculation, updateScoringTableValue, updateScoringTableFieldWeight } from '@/api/risk/scoring';
import { getFieldGroupsByScoringId as _getFieldGroupsByScoringId } from '@/api/admin/fieldGroups';
import { deleteCustomField } from '@/api/admin/admin';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import MainWell from '@/components/Atoms/Containers/MainWell';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import ScrollTopButton from '@/components/Atoms/Buttons/ScrollTopButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Notification from '@/components/Molecules/Notification';
import Alert from '@/components/Molecules/Modal/Alert';
import RiskMatrixAxisParametersWell from '@/components/Organisms/Admin/RiskMatrixAxisParametersWell';
import CustomRiskFieldsWell from '@/components/Organisms/Admin/CustomRiskFieldsWell';
import ScoringOtherParameters from '@/components/Organisms/Admin/ScoringOtherParameters';
import { ErmRiskStatus } from '@/constants/risks/RiskStatus';
import { CustomFieldValidationMixin } from '@/components/Organisms/Admin/CustomFieldValidationMixin';
import { SETTINGS_ROUTE_NAMES } from '@/constants/routes/settings';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { isOnlyLettersNumbersSpacesDashes } from '@/utils/validations';
export default {
  name: 'ErmPeriodDescription',
  introduction: 'Main edit page for scoring period',
  description: 'Show and edit scoring period. For ERM risks',
  components: {
    Alert: Alert,
    InputField: InputField,
    FeatherIcon: FeatherIcon,
    MainWell: MainWell,
    Notification: Notification,
    RiskMatrixAxisParametersWell: RiskMatrixAxisParametersWell,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    ScoringOtherParameters: ScoringOtherParameters,
    CustomRiskFieldsWell: CustomRiskFieldsWell,
    NotificationPopUp: NotificationPopUp,
    ScrollTopButton: ScrollTopButton
  },
  mixins: [CustomFieldValidationMixin, NotificationPopUpMixin],
  props: {
    scoringId: {
      type: [String, Number],
      require: true,
      default: null
    }
  },
  data: function data() {
    return {
      isLoaded: false,
      showModal: false,
      scoring: {},
      status: false,
      updatedData: {},
      fieldGroups: null,
      fieldDeafultValue: {
        typeId: null,
        options: [],
        unit: '',
        children: [],
        errors: {}
      },
      isDirty: false,
      notification: '',
      notificationType: 'error',
      showDeletionConfirmation: false,
      isTitleValid: true,
      isUpdatingScoring: false,
      isDeletingScoring: false,
      translate: getTranslate['ErmPeriodDescription']()
    };
  },
  computed: {
    isReadonly: function isReadonly() {
      return this.scoring.status === ErmRiskStatus.OPEN || this.scoring.status === ErmRiskStatus.CLOSED;
    },
    isEditingMode: function isEditingMode() {
      return this.scoring.status === ErmRiskStatus.EDITING;
    },
    isPeriodClosed: function isPeriodClosed() {
      return this.scoring.status === ErmRiskStatus.CLOSED;
    },
    savePeriodTitle: function savePeriodTitle() {
      return this.status && !this.scoring.isCurrent ? this.translate.saveAndOpen : this.translate.save;
    },
    scoringFormated: function scoringFormated() {
      var _this = this;
      var _this$scoring = this.scoring,
        z_axis_scores = _this$scoring.z_axis_scores,
        z_axis_name = _this$scoring.z_axis_name,
        z_axis_values = _this$scoring.z_axis_values;
      var result = _objectSpread({
        z_axis_scores: z_axis_scores,
        z_axis_name: z_axis_name,
        z_axis_values: z_axis_values
      }, this.updatedData);
      var _loop = function _loop() {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
          key = _Object$entries$_i[0],
          value = _Object$entries$_i[1];
        if (key === 'z_axis_scores') {
          result.zAxisLevels = value;
          delete result.z_axis_scores;
        }
        if (key === 'z_axis_name') {
          result.zAxisName = value;
          delete result.z_axis_name;
        }
        if (key === 'z_axis_values') {
          result.zAxisLabels = Object.keys(value).map(function (key, index) {
            return _objectSpread(_objectSpread({}, value[key]), {}, {
              score: index + 1,
              label: value[key].value
            });
          });
          delete result.z_axis_values;
        }
        if (key === 'fields') {
          result.fields = _this.prepareFieldsToSave(value);
        }
      };
      for (var _i = 0, _Object$entries = Object.entries(result); _i < _Object$entries.length; _i++) {
        _loop();
      }
      if (this.status !== this.scoring.status) {
        result.status = this.status ? ErmRiskStatus.OPEN : this.scoring.status;
      }
      return result;
    },
    otherParameters: function otherParameters() {
      var _this2 = this;
      return [1, 2].map(function (item) {
        return {
          title: "".concat(_this2.translate.otherParameters, " #").concat(item),
          label: _this2.scoring[_this2.getScoringKey('label', item)],
          labels: _this2.scoring[_this2.getScoringKey('labels', item)],
          inUse: _this2.scoring[_this2.getScoringKey('inUse', item)]
        };
      });
    },
    isSaveBtnDisabled: function isSaveBtnDisabled() {
      return !this.isDirty || this.isUpdatingScoring;
    }
  },
  created: function created() {
    this.onAxisTableChangeDebounced = debounce(this.onAxisTableChange, 1000);
  },
  mounted: function mounted() {
    var _this3 = this;
    Promise.all([this.getFieldGroupsByScoringId(), this.getScoring()]).then(function () {
      return _this3.isLoaded = true;
    }).catch(function (error) {
      _this3.$router.push({
        name: SETTINGS_ROUTE_NAMES.erm_admin,
        params: {
          errorScoring: error
        }
      });
    });
  },
  methods: {
    getScoring: function getScoring() {
      var _this4 = this;
      return _getScoring(this.scoringId).then(function (result) {
        _this4.scoring = result;
        _this4.scoring.fields.forEach(function (item, index) {
          return _this4.scoring.fields[index] = _objectSpread(_objectSpread({}, _this4.fieldDeafultValue), item);
        });
        _this4.status = _this4.scoring.status === ErmRiskStatus.OPEN;
      }).catch(function (error) {
        var _error$response;
        throw error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.error;
      });
    },
    getFieldGroupsByScoringId: function getFieldGroupsByScoringId() {
      var _this5 = this;
      return _getFieldGroupsByScoringId(this.scoringId).then(function (response) {
        _this5.fieldGroups = response.list;
      });
    },
    updateFieldGroups: function updateFieldGroups(groups) {
      this.fieldGroups = groups;
    },
    updateFieldsOrder: function updateFieldsOrder(fields) {
      this.scoring.fields = fields;
      var prearedFields = this.prepareFieldsToSave(fields);
      this.updatePeriod(this.scoringId, {
        fields: prearedFields
      });
    },
    onAxisTableChange: function onAxisTableChange(_ref) {
      var _this6 = this;
      var fieldname = _ref.fieldname,
        value = _ref.value,
        fieldType = _ref.fieldType,
        id = _ref.id;
      var promise;
      if (fieldType === 'fieldweight') {
        promise = updateScoringTableFieldWeight(this.scoring.id, {
          fieldId: id,
          fieldWeight: value
        });
      } else {
        var params = "scoring=".concat(this.scoring.id, "&fieldname=").concat(fieldname, "&fieldvalue=").concat(value);
        promise = updateScoringTableValue(params);
      }
      return promise.then(function () {
        return _this6.getScoring();
      }).catch(function (error) {
        return _this6.setNotificationData({
          title: _this6.$trans(error.response.error),
          type: 'error'
        });
      });
    },
    onCalculationChange: function onCalculationChange(type, id, calculationType) {
      var _this7 = this;
      return updateTableCalculation(id, calculationType).then(function () {
        _this7.scoring[type]['calculationType'] = calculationType;
      });
    },
    deleteScoring: function deleteScoring() {
      var _this8 = this;
      if (this.isDeletingScoring) return;
      this.isDeletingScoring = true;
      return _deleteScoring(this.scoring.id).then(function () {
        _this8.showDeletionConfirmation = false;
        _this8.$router.push('/settings/' + SETTINGS_ROUTE_NAMES.erm_admin);
      }).catch(function (error) {
        var _error$response2;
        _this8.showDeletionConfirmation = false;
        _this8.handleNotification(error.message || (error === null || error === void 0 || (_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.error), 'error');
      });
    },
    showConfirmation: function showConfirmation() {
      this.showDeletionConfirmation = true;
    },
    updateTitle: function updateTitle(value) {
      this.isTitleValid = isOnlyLettersNumbersSpacesDashes(value);
      this.scoring.version = value;
      this.updatedData.version = value;
      this.setIsDirty(true);
    },
    onMatrixAxisParametersChange: function onMatrixAxisParametersChange(key, value) {
      var scoringKey = this.getScoringKey(key) || key;
      if (value !== null && _typeof(value) === 'object' && !Array.isArray(value)) {
        this.scoring[scoringKey] = _objectSpread(_objectSpread({}, this.scoring[scoringKey]), value);
        this.updatedData[scoringKey] = _objectSpread(_objectSpread({}, this.updatedData[scoringKey]), value);
      } else {
        this.scoring[scoringKey] = value;
        this.updatedData[scoringKey] = value;
      }
      this.setIsDirty(true);
    },
    onOtherParametersChange: function onOtherParametersChange(_ref2, index) {
      var key = _ref2.key,
        value = _ref2.value;
      var scoringKey = this.getScoringKey(key, index);
      if (key === 'labels') {
        for (var _i2 = 0, _Object$entries2 = Object.entries(value); _i2 < _Object$entries2.length; _i2++) {
          var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 2),
            objKey = _Object$entries2$_i[0],
            objValue = _Object$entries2$_i[1];
          var updatedKey = this.getOtherParametersKey(key, index, objKey);
          this.updatedData[updatedKey] = objValue;
        }
      } else {
        var _updatedKey = this.getOtherParametersKey(key, index);
        this.updatedData[_updatedKey] = value;
      }
      this.scoring[scoringKey] = value;
      this.setIsDirty(true);
    },
    getScoringKey: function getScoringKey(key, index) {
      var config = {
        label: "impactOther".concat(index, "Label"),
        labels: "impactOther".concat(index, "Labels"),
        inUse: "impactOther".concat(index, "InUse"),
        likelihoodLabels: 'likelihood_labels'
      };
      return config[key];
    },
    getOtherParametersKey: function getOtherParametersKey(key, index, scoreIndex) {
      var config = {
        label: "impact_other".concat(index, "_name"),
        labels: "impact_other".concat(index, "_score").concat(scoreIndex),
        inUse: "impact_other".concat(index, "_inuse")
      };
      return config[key];
    },
    onCustomFieldChange: function onCustomFieldChange(fields) {
      this.scoring.fields = fields;
      this.updatedData.fields = fields;
      this.setIsDirty(true);
    },
    validateCustomFields: function validateCustomFields() {
      var _this9 = this;
      var isCustomFiedlsValid = true;
      this.scoring.fields.forEach(function (item) {
        var isValid = _this9.validate(item);
        if (!isValid) isCustomFiedlsValid = false;
      });
      return isCustomFiedlsValid;
    },
    onUpdateScoring: function onUpdateScoring() {
      var isCFValid = this.validateCustomFields();
      if (!this.isTitleValid) return;
      isCFValid && this.updatePeriod(this.scoringId, this.scoringFormated);
    },
    updatePeriod: function updatePeriod(scoringId, data) {
      var _this10 = this;
      this.isUpdatingScoring = true;
      return updateScoring(scoringId, data).then(function (response) {
        _this10.scoring = response;
        _this10.updatedData = {};
        _this10.setIsDirty(false);
        _this10.setNotificationData({
          title: _this10.translate.masterDataSaved
        });
      }).catch(function (error) {
        return _this10.handleNotification(error.message, 'error');
      }).finally(function () {
        _this10.isUpdatingScoring = false;
      });
    },
    onCustomFieldDelete: function onCustomFieldDelete(id) {
      var _this11 = this;
      return deleteCustomField(id).then(function () {
        return _this11.getScoring();
      });
    },
    prepareFieldsToSave: function prepareFieldsToSave(fields) {
      var _this12 = this;
      return fields.map(function (field) {
        var options = field.options,
          unit = field.unit,
          children = field.children,
          rest = _objectWithoutProperties(field, _excluded);
        delete rest.errors;
        rest.fieldType = field.typeId;
        if (rest.typeId === CustomFieldTypes.SELECT) {
          rest.options = options;
        }
        if (rest.typeId === CustomFieldTypes.ROW_GROUP) {
          rest.children = _this12.prepareIds(children);
        }
        if (rest.typeId === CustomFieldTypes.NUMERICAL) {
          rest.unit = unit;
        }
        return rest;
      });
    },
    setIsDirty: function setIsDirty() {
      var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.isDirty = value;
    },
    backToPrevPage: function backToPrevPage() {
      this.$router.push({
        name: SETTINGS_ROUTE_NAMES.erm_admin
      });
    },
    handleNotification: function handleNotification(notification, type) {
      this.notification = notification;
      this.notificationType = type;
    },
    dismissNotification: function dismissNotification() {
      this.notification = '';
    },
    moveToAdmin: function moveToAdmin() {
      this.$router.push(SETTINGS_ROUTE_NAMES.erm_admin);
    }
  }
};