function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapActions } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { createRiskProject as _createRiskProject, updateRiskProject, getAllRiskProjects, deletePeriod as _deletePeriod } from '@/api/risk/risk';
import { getAllScorings, deleteScoring as _deleteScoring } from '@/api/risk/scoring';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import MainWell from '@/components/Atoms/Containers/MainWell';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import { RiskProjects } from '@/constants/risks/RiskProjects';
export default {
  name: 'CreateRiskProject',
  components: {
    FeatherIcon: FeatherIcon,
    SingleSelect: SingleSelect,
    MainWell: MainWell,
    RismaButton: RismaButton,
    InputField: InputField,
    RismaTitle: RismaTitle,
    Modal: Modal,
    NotificationPopUp: NotificationPopUp
  },
  data: function data() {
    return {
      projectType: null,
      riskProjects: [],
      projectTypes: [{
        value: 1,
        label: 'Enterprise risk management'
      }, {
        value: 2,
        label: 'ISMS risk / Outsourcing'
      }, {
        value: 3,
        label: 'Article 32'
      }],
      projectName: '',
      translate: getTranslate['CreateRiskProject'](),
      showCreateModal: false,
      showDeletePeriodModal: false,
      periodToDelete: {},
      notifications: []
    };
  },
  computed: {
    ermProjectId: function ermProjectId() {
      var _this = this;
      var ermProject = this.riskProjects.find(function (project) {
        return _this.isErm(project);
      });
      return ermProject === null || ermProject === void 0 ? void 0 : ermProject.id;
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useUsersStore, {
    loadUsers: 'load'
  })), {}, {
    getData: function getData() {
      var _this2 = this;
      Promise.all([getAllScorings(), this.getProjects()]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
          scorings = _ref2[0];
        return _this2.setErmPeriods(scorings.list);
      });
    },
    getProjects: function getProjects() {
      var _this3 = this;
      return getAllRiskProjects().then(function (_ref3) {
        var list = _ref3.list;
        return _this3.riskProjects = list;
      });
    },
    createRiskProject: function createRiskProject() {
      var _this4 = this;
      var projectObject = {
        projectName: this.projectName,
        projectType: this.projectType
      };
      return _createRiskProject(projectObject).then(function () {
        _this4.getProjects();
        _this4.handleShowNotification('success', _this4.translate.riskProjectCreated);
        _this4.loadUsers();
      }).catch(function (error) {
        _this4.handleShowNotification('error', error);
      }).finally(function () {
        return _this4.dismissCreateModal();
      });
    },
    setErmPeriods: function setErmPeriods(periods) {
      var _this5 = this;
      var ermProject = this.riskProjects.find(function (project) {
        return _this5.isErm(project);
      });
      if (!ermProject) return;
      ermProject.projectPeriods = periods;
    },
    toggleProject: function toggleProject(projectId, enabled) {
      var _this6 = this;
      var data = {
        projectId: projectId,
        enabled: enabled
      };
      updateRiskProject(projectId, data).then(function (project) {
        _this6.loadUsers();
        return _this6.updateProject(project);
      });
    },
    updateProject: function updateProject(project) {
      var index = this.riskProjects.findIndex(function (p) {
        return +p.id === +project.id;
      });
      if (!this.riskProjects[index]) return;
      this.riskProjects[index].enabled = project.enabled;
      this.handleShowNotification('success', this.translate.riskProjectEnabledDisabled);
    },
    onDeletePeriod: function onDeletePeriod(id, project) {
      this.periodToDelete = {
        id: id,
        project: project
      };
      this.showDeletePeriodModal = true;
    },
    handleDeletePeriod: function handleDeletePeriod() {
      this.showDeletePeriodModal = false;
      this.isErm(this.periodToDelete.project) ? this.deleteScoring(this.periodToDelete.id) : this.deletePeriod(this.periodToDelete.project.id, this.periodToDelete.id);
      this.periodToDelete = {};
    },
    deleteScoring: function deleteScoring(id) {
      var _this7 = this;
      return _deleteScoring(id).then(function () {
        _this7.removePeriodFromList(_this7.ermProjectId, id);
        _this7.handleShowNotification('success', _this7.translate.riskPeriodDeleted);
      }).catch(function () {
        _this7.handleShowNotification('error', _this7.translate.youCannotDeleteThisPeriodAsItContainsDataThatIsStillInUse);
      });
    },
    deletePeriod: function deletePeriod(projectId, id) {
      var _this8 = this;
      return _deletePeriod(projectId, id).then(function () {
        _this8.removePeriodFromList(projectId, id);
        _this8.handleShowNotification('success', _this8.translate.riskPeriodDeleted);
      }).catch(function () {
        _this8.handleShowNotification('error', _this8.translate.youCannotDeleteThisPeriodAsItContainsDataThatIsStillInUse);
      });
    },
    removePeriodFromList: function removePeriodFromList(projectId, id) {
      var project = this.riskProjects.find(function (project) {
        return project.id === projectId;
      });
      if (!project) return;
      project.projectPeriods = project.projectPeriods.filter(function (period) {
        return period.id !== id;
      });
    },
    isErm: function isErm(project) {
      return project.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type;
    },
    dismissCreateModal: function dismissCreateModal() {
      this.showCreateModal = false;
      this.projectName = '';
    },
    handleShowNotification: function handleShowNotification(type, message) {
      this.notifications.push({
        notificationText: message,
        notificationType: type
      });
    },
    dismissNotification: function dismissNotification(index) {
      this.notifications.splice(index, 1);
    },
    statusToText: function statusToText(projectType, periodStatus) {
      if (projectType === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type) {
        switch (periodStatus) {
          case 1:
            return this.translate.open;
          case 2:
            return this.translate.draft;
          case 10:
            return this.translate.closed;
        }
      } else {
        switch (periodStatus) {
          case 0:
            return this.translate.draft;
          case 1:
            return this.translate.open;
          case 2:
            return this.translate.closed;
        }
      }
      return projectType + 'X';
    }
  })
};