import { DatatableRenderer } from '@/utils/DatatableRenderer';
var FieldRendererMapping = {
  risma_state: DatatableRenderer.incidentState,
  trafficLight: DatatableRenderer.prepareTrafficLightField,
  traffic_light: DatatableRenderer.prepareTrafficLightField,
  severity: DatatableRenderer.prepareSeverityField,
  incident_happened_at: DatatableRenderer.datetime,
  deadline_utc: DatatableRenderer.datetime,
  review_deadline: DatatableRenderer.datetime,
  create_remote_tickets: DatatableRenderer.getRemoteTicketStateName,
  analysis_rag: DatatableRenderer.prepareGapColorField,
  vat_status: DatatableRenderer.prepareCompanyColorField
};
export default FieldRendererMapping;