function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import Gap from '@/components/Organisms/Compliance/Gaps/Gap';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import TabList from '@/components/Atoms/TabList';
import Alert from '@/components/Molecules/Modal/Alert';
import StackedBarChart from '@/components/Molecules/Charts/StackedBarChart';
import GapSchemaQuestions from '@/components/Molecules/Compliance/Gap/GapSchemaQuestions';
import GapSchemaNavigation from '@/components/Molecules/Compliance/Gap/GapSchemaNavigation';
import GapSchemaHeatmaps from '@/components/Organisms/Compliance/Gaps/GapSchemaHeatmaps';
import * as Gaps from '@/api/compliance/gaps';
import * as Control from '@/api/controls/controls';
import * as Execution from '@/api/execution/initiative';
import * as ProcessLibrary from '@/api/compliance/processtree';
import * as DataAPI from '@/api/risma/data';
import { sortByIndex, sortNumericByProp } from '@/utils/sort';
import * as RiskApi from '@/api/risk/risk';
import { getRiskAssessmentIdsList } from '@/api/compliance/informationAssets';
import { getQuestionnaireAssessments_TIA_LIA } from '@/api/compliance/questionnaire';
import { Colors } from '@/Colors';
import { saveStatus } from '@/constants/SaveStatuses';
import { getAllArticlesByGapSchemaId } from '@/api/compliance/gapschema';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { MODULES } from '@/constants/modules';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { TABS_SLOT_NAMES } from '@/constants/compliance/GapAnalysis';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { getByUrl } from '@/api/httpUtils';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'GapPage',
  components: {
    GapSchemaQuestions: GapSchemaQuestions,
    Alert: Alert,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Gap: Gap,
    StackedBarChart: StackedBarChart,
    GapSchemaNavigation: GapSchemaNavigation,
    FeatherIcon: FeatherIcon,
    TabList: TabList,
    GapSchemaHeatmaps: GapSchemaHeatmaps
  },
  introduction: '',
  description: '',
  token: '<gap-page></gap-page>',
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    data: {
      required: true,
      type: Object,
      note: 'Current node object'
    },
    articleId: {
      required: false,
      type: [String, Number],
      default: null
    }
  },
  emits: ['propertyChanged', 'updateValue'],
  setup: function setup(props) {
    return {
      currentGapArticleId: getLocalStorageComputed("gap_".concat(props.data.compliance_projects_id, "_").concat(props.data.id), 0, true)
    };
  },
  data: function data() {
    return {
      gaps: null,
      questions: [],
      controls: [],
      initiatives: [],
      allInitiatives: [],
      processes: [],
      saveStatus: saveStatus.SAVED,
      showRetry: false,
      failedGap: null,
      gapsInitialized: 0,
      articles: null,
      loaded: false,
      labels: [],
      colors: [],
      filters: [],
      risks: [],
      projects: [],
      threats: [],
      currentGapIndex: 0,
      riskProjectId: 2,
      questionSwitchOptions: [{
        name: 'Automatic',
        state: true
      }, {
        name: 'Manual',
        state: false
      }],
      currentTab: TABS_SLOT_NAMES.relevantQuestions,
      activePeriod: null,
      risksReport: [],
      systems: [],
      TABS_SLOT_NAMES: TABS_SLOT_NAMES,
      hasQuestionnaires: null,
      translate: getTranslate['GapPage']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserComplianceProjectById: 'complianceProjectById'
  })), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    showFullGapsList: function showFullGapsList() {
      return this.getSettingValue('feature.show_full_list_of_gaps');
    },
    projectId: function projectId() {
      return this.data.compliance_projects_id || null;
    },
    currentProject: function currentProject() {
      return this.currentUserComplianceProjectById(this.projectId);
    },
    currentGap: function currentGap() {
      return this.filteredGaps[this.currentGapIndex];
    },
    visibleGaps: function visibleGaps() {
      if (this.showFullGapsList) {
        return this.filteredGaps;
      }
      return [this.currentGap];
    },
    processAccessEnabled: function processAccessEnabled() {
      return !!this.getSettingValue('feature.process_tree') && this.currentUser.policiesAccess;
    },
    barData: function barData() {
      var barData = [0, 0, 0, 0, 0, 0];
      this.gapsWithArticles.forEach(function (el) {
        return barData[el.analysis_rag || 0]++;
      });
      return barData;
    },
    gapColors: function gapColors() {
      return Colors.gaps;
    },
    filteredGaps: function filteredGaps() {
      var _this = this;
      if (!this.filters.length) {
        return this.gapsWithArticles;
      }
      return this.gapsWithArticles.filter(function (gap) {
        var _gap$analysis_rag;
        var rang = (_gap$analysis_rag = gap.analysis_rag) !== null && _gap$analysis_rag !== void 0 ? _gap$analysis_rag : 0;
        return _this.filters.indexOf(rang) !== -1;
      });
    },
    filteredQuestions: function filteredQuestions() {
      return this.questions.filter(function (question) {
        return Object.keys(question.answers_options).length > 0 || question.freeText || question.informationAssets && Object.values(question.informationAssets).some(function (item) {
          return item.length;
        }) || question.processLibrary && question.processLibrary.length || question.dateTime || question.files && question.files.length;
      });
    },
    gapsWithArticles: function gapsWithArticles() {
      var _this2 = this;
      if (!this.articles || !Array.isArray(this.gaps)) {
        return [];
      }
      return this.articles.map(function (article) {
        var _gap$articleId;
        var gap = _this2.getGapFromArticleId(article.id);
        gap.articleId = (_gap$articleId = gap.articleId) !== null && _gap$articleId !== void 0 ? _gap$articleId : article.id;
        gap.article = article;
        return gap;
      });
    },
    complianceProjectTagName: function complianceProjectTagName() {
      return this.currentProject ? this.currentProject.tag : '';
    },
    isRelevantQuestionActive: function isRelevantQuestionActive() {
      return this.currentTab === this.TABS_SLOT_NAMES.relevantQuestions;
    },
    questionsLists: function questionsLists() {
      return this.isRelevantQuestionActive ? this.relevantQuestions : this.filteredQuestions;
    },
    relevantQuestions: function relevantQuestions() {
      var relevantQuestions = this.currentGap.article.relevantQuestionIds || [];
      if (relevantQuestions.length) {
        return this.filteredQuestions.filter(function (question) {
          return relevantQuestions.indexOf(question.id) !== -1;
        });
      }
      return [];
    },
    gapStats: function gapStats() {
      var totalQuestions = this.barData.reduce(function (total, value) {
        return total + value;
      }, 0);
      var questionsNA = this.barData[5];
      var questionNotSet = this.barData[0];
      var questionAnswered = totalQuestions - questionsNA - questionNotSet;
      return {
        gap_records_na: questionsNA,
        gap_records_answered: questionAnswered,
        percentage_gap_answered: totalQuestions > 0 ? Math.ceil(questionAnswered / totalQuestions * 100 / 5) * 5 : 0
      };
    },
    hasHeatmaps: function hasHeatmaps() {
      return !!this.getSettingValue('feature.risk_assessment_on_systems') && this.currentProject.gap_heatmaps;
    },
    tabsList: function tabsList() {
      var tabs = [{
        name: this.translate.allQuestions,
        slotName: TABS_SLOT_NAMES.questions
      }];
      if (this.relevantQuestions.length) {
        tabs.unshift({
          name: this.translate.relevantQuestions,
          slotName: TABS_SLOT_NAMES.relevantQuestions
        });
      }
      if (this.hasHeatmaps) {
        tabs.push({
          name: this.translate.heatmaps,
          slotName: TABS_SLOT_NAMES.heatmaps
        });
      }
      return tabs;
    },
    questionsClassObject: function questionsClassObject() {
      var _ref;
      var topOffset = this.isPreview ? 'top-0' : 'top-130px';
      return _ref = {}, _defineProperty(_ref, "sticky ".concat(topOffset, " self-start"), this.showFullGapsList), _defineProperty(_ref, 'pt-12 mt-5', !this.showFullGapsList), _defineProperty(_ref, 'min-w-35rem0', this.hasHeatmaps), _ref;
    },
    gapsClassObject: function gapsClassObject() {
      var _ref2;
      var topOffset = this.isPreview ? 'top-0' : 'top-117px';
      return _ref2 = {}, _defineProperty(_ref2, "sticky ".concat(topOffset, " self-start pt-1"), this.showFullGapsList), _defineProperty(_ref2, 'pt-10', !this.showFullGapsList), _ref2;
    },
    scrolledElement: function scrolledElement() {
      var previewModal = document.querySelector('.preview-modal');
      return this.isPreview ? previewModal : document;
    }
  }),
  watch: {
    currentGapIndex: function currentGapIndex() {
      if (this.currentGap) {
        this.currentGapArticleId = this.currentGap.articleId;
        if (!this.isPreview) {
          this.$router.replace({
            params: {
              articleId: this.currentGap.articleId
            }
          });
        }
      }
    },
    articleId: function articleId() {
      var _this3 = this;
      if (this.articleId && this.articleId !== this.currentGap.articleId) {
        this.currentGapIndex = this.articles.findIndex(function (article) {
          return +_this3.articleId === +article.id;
        }) || 0;
      }
    }
  },
  mounted: function mounted() {
    this.loadData();
    this.createBarData();
    this.addScrollListener();
  },
  beforeUnmount: function beforeUnmount() {
    this.removeScrollListener();
  },
  methods: {
    onScrollFn: function onScrollFn() {
      for (var i = 0; i < this.visibleGaps.length; i++) {
        var _gapElement$$el;
        var gap = this.visibleGaps[i];
        var gapElement = this.$refs["gap-".concat(gap.articleId)] && this.$refs["gap-".concat(gap.articleId)][0];
        var elememtBounding = gapElement === null || gapElement === void 0 || (_gapElement$$el = gapElement.$el) === null || _gapElement$$el === void 0 ? void 0 : _gapElement$$el.getBoundingClientRect();
        if (!elememtBounding) return;
        if (elememtBounding.top > 150 || elememtBounding.bottom > 150) {
          this.currentGapIndex = i;
          break;
        }
      }
    },
    addScrollListener: function addScrollListener() {
      if (this.showFullGapsList) {
        this.scrolledElement.addEventListener('scroll', this.onScrollFn);
      }
    },
    removeScrollListener: function removeScrollListener() {
      if (this.showFullGapsList) {
        this.scrolledElement.removeEventListener('scroll', this.onScrollFn);
      }
    },
    setCurrentGapIndex: function setCurrentGapIndex(id) {
      if (!this.filteredGaps.length) return;
      var currentGapIndex = this.filteredGaps.findIndex(function (item) {
        return item.articleId === id;
      }) || 0;
      this.currentGapIndex = currentGapIndex !== -1 ? currentGapIndex : 0;
      if (this.showFullGapsList) this.scrollToCurrentGap(id);
    },
    scrollToCurrentGap: function scrollToCurrentGap(id) {
      this.removeScrollListener();
      var gap = $(".gap[data-gapid=\"".concat(id, "\"]"));
      if (this.isPreview) {
        var previewModal = $('.preview-modal');
        previewModal.animate({
          scrollTop: gap.offset().top - 130 + previewModal.scrollTop()
        }, 1000, this.addScrollListener);
      } else {
        $([document.documentElement, document.body]).animate({
          scrollTop: gap.offset().top - 120
        }, 1000, this.addScrollListener);
      }
    },
    createBarData: function createBarData() {
      var _this4 = this;
      this.gapColors.forEach(function (element) {
        _this4.labels.push(element.label);
        _this4.colors.push(element.color);
      });
    },
    getProcessLibraryNodes: function getProcessLibraryNodes() {
      var _this5 = this;
      return ProcessLibrary.getAllTrees().then(function (trees) {
        if (!(trees[0] && trees[0].id)) {
          return Promise.resolve({
            list: []
          });
        }
        return ProcessLibrary.getNodesByProcessTreeId(trees[0].id).then(function (response) {
          var processArray = [];
          if (response.children !== undefined && Array.isArray(response.children)) {
            var pushChildren = function pushChildren(children) {
              children.forEach(function (child) {
                child.module = MODULES.COMPLIANCE_PROCESS_TREE_NODE;
                processArray.push(child);
                if (child.children) {
                  pushChildren(child.children);
                }
              });
            };
            pushChildren(response.children);
          }
          _this5.processes = processArray;
        });
      });
    },
    loadData: function loadData() {
      var _this6 = this,
        _this$data$questionna;
      this.loaded = false;
      var promises = [this.getGapArticles(), getByUrl(this.data.apiUrl + '/gapanalysis').then(function (response) {
        return _this6.gaps = response.list;
      }), this.getControls(), this.getInitiatives(), this.getProcessLibraryNodes(), this.getRisks(), this.getThreats(), this.getProjects(), this.getQuestionnaireAssessments()];
      if ((_this$data$questionna = this.data.questionnaires) !== null && _this$data$questionna !== void 0 && _this$data$questionna.length) {
        this.data.questionnaires.forEach(function (questionnaire) {
          promises.push(getByUrl(_this6.data.apiUrl + '/questionanswers/' + questionnaire.questionnaireId).then(function (response) {
            var _this6$questions;
            return (_this6$questions = _this6.questions).push.apply(_this6$questions, _toConsumableArray(response.list));
          }));
        });
      }
      return Promise.all(promises).then(function () {
        _this6.getSystemRiskReport();
        _this6.loaded = true;
        if (_this6.articleId) {
          _this6.$nextTick(function () {
            _this6.setCurrentGapIndex(+_this6.articleId);
          });
        }
      });
    },
    getGapArticles: function getGapArticles() {
      var _this7 = this;
      var data = ['relevantQuestionIds', 'suggestedActivities'];
      var gapSchemaId = this.data.gapSchemaId;
      return getAllArticlesByGapSchemaId(gapSchemaId, data).then(function (_ref3) {
        var list = _ref3.list;
        _this7.articles = list;
      });
    },
    getControls: function getControls() {
      var _this8 = this;
      if (this.getSettingValue('feature.filter_gap_initiatives_and_controls_by_tag') === 1) {
        return Control.getAllWithTagsFilter(this.complianceProjectTagName).then(function (response) {
          return _this8.controls = sortByIndex(RismaControlsUtils.cloneEntities(response.list), 'cpath');
        });
      } else {
        return DataAPI.getControls().then(function (response) {
          return _this8.controls = sortByIndex(RismaControlsUtils.cloneEntities(response.list), 'cpath');
        });
      }
    },
    liaTiaForce: function liaTiaForce(data) {
      if (!this.data.questionnaires.find(function (item) {
        return item.questionnaireId === data.questionnaireId;
      })) {
        this.$emit('propertyChanged', {
          property: 'questionnaires',
          value: [].concat(_toConsumableArray(this.data.questionnaires), [data])
        });
      }
    },
    getReportRisk: function getReportRisk() {
      var params = "?filters[projectId]=".concat(this.riskProjectId, "&filters[projectPeriodId]=").concat(this.activePeriod.id, "&data[]=customFields&data[]=comparePeriod&data[]=linkedActivities&data[]=linkedAssets&filters[comparePeriodId]=").concat(this.activePeriod.previousId, "&filters[underlyingrisks]=include");
      return RiskApi.getReports(params);
    },
    getIASystems: function getIASystems() {
      return getRiskAssessmentIdsList(AssetsTypes.SYSTEMS, '?data[]=links');
    },
    getSystemRiskReport: function getSystemRiskReport() {
      var _this9 = this;
      return Promise.all([this.getReportRisk(), this.getIASystems()]).then(function (_ref4) {
        var _ref5 = _slicedToArray(_ref4, 2),
          risks = _ref5[0],
          ids = _ref5[1];
        _this9.risksReport = risks.list.filter(function (risk) {
          return ids.indexOf(risk.id) !== -1;
        });
      });
    },
    getInitiatives: function getInitiatives() {
      var _this10 = this;
      if (this.getSettingValue('feature.filter_gap_initiatives_and_controls_by_tag') === 1) {
        Execution.getAllWithTagsFilter(this.complianceProjectTagName).then(function (response) {
          return _this10.initiatives = RismaControlsUtils.cloneEntities(response.list);
        });
        DataAPI.getInitiatives().then(function (response) {
          return _this10.allInitiatives = sortByIndex(RismaControlsUtils.cloneEntities(response.list), 'path');
        });
      } else {
        DataAPI.getInitiatives().then(function (response) {
          var initiativeList = sortByIndex(RismaControlsUtils.cloneEntities(response.list), 'path');
          _this10.initiatives = initiativeList;
          _this10.allInitiatives = initiativeList;
        });
      }
    },
    getRisks: function getRisks() {
      var _this11 = this;
      var risks = [];
      return DataAPI.getRisks().then(function (_ref6) {
        var list = _ref6.list;
        list.forEach(function (risk) {
          risk.linkParam = risk.url;
          risks.push(risk);
        });
        _this11.risks = sortNumericByProp(risks, 'rno');
      });
    },
    getProjects: function getProjects() {
      var _this12 = this;
      return RiskApi.getRiskProjects().then(function (response) {
        _this12.activePeriod = _this12.getActivePeriod(response.list);
        response.list.forEach(function (project) {
          _this12.projects.push({
            label: project.title,
            value: project.id
          });
        });
      });
    },
    getQuestionnaireAssessments: function getQuestionnaireAssessments() {
      var _this13 = this;
      getQuestionnaireAssessments_TIA_LIA(this.data.apiUrl).then(function (response) {
        _this13.hasQuestionnaires = response.list;
      });
    },
    tryAgain: function tryAgain() {
      this.failedGap && this.updateGap(this.failedGap);
    },
    changeStatusToNotSaved: function changeStatusToNotSaved() {
      this.saveStatus = saveStatus.NOT_SAVED;
    },
    updateGap: function updateGap(gap, changes) {
      var _this14 = this;
      var gapPayload = JSON.parse(JSON.stringify(_objectSpread(_objectSpread({}, gap), changes)));
      if (!changes.links && gapPayload.links) {
        Object.keys(gapPayload.links).forEach(function (title) {
          gapPayload.links[title] = gapPayload.links[title].map(function (_ref7) {
            var id = _ref7.id;
            return id;
          });
        });
      }
      this.saveStatus = saveStatus.SAVING;
      this.failedGap = null;
      this.showRetry = false;
      return Gaps.updateGap(gapPayload, this.data.apiUrl).then(function (response) {
        var updatedGap;
        if (gap.id) {
          updatedGap = _this14.findGapById(gap.id);
          Object.assign(updatedGap, response);
        } else {
          updatedGap = response;
          _this14.gaps.push(updatedGap);
        }
        _this14.saveStatus = saveStatus.SAVED;
        _this14.$emit('updateValue', {
          property: 'values',
          value: _objectSpread(_objectSpread({}, _this14.data.values), _this14.gapStats)
        });
        return updatedGap;
      }).catch(function () {
        _this14.failedGap = gap;
        _this14.showRetry = true;
        _this14.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    findGapById: function findGapById(id) {
      return this.gaps.filter(function (gap) {
        return gap.id === id;
      })[0];
    },
    getGapFromArticleId: function getGapFromArticleId(articleId) {
      if (!Array.isArray(this.gaps)) {
        return {};
      }
      return this.gaps.find(function (gap) {
        return gap.articleId === articleId;
      }) || {};
    },
    barClick: function barClick(e) {
      this.currentGapIndex = 0;
      this.filters.indexOf(e) == -1 ? this.filters.push(e) : this.filters.splice(this.filters.indexOf(e), 1);
    },
    addNewItem: function addNewItem(item) {
      switch (item.objType) {
        case ObjectTypes.CONTROLS_CONTROL:
          this.controls.push(item);
          break;
        case ObjectTypes.EXECUTION_INITIATIVE:
          this.allInitiatives.push(item);
          this.allInitiatives = sortByIndex(this.allInitiatives, 'path');
          break;
      }
    },
    getThreats: function getThreats() {
      var _this15 = this;
      return RiskApi.getThreats('?data[]=all').then(function (_ref8) {
        var list = _ref8.list;
        _this15.threats = list;
      });
    },
    getActivePeriod: function getActivePeriod(projects) {
      var _this16 = this;
      var project = projects.find(function (project) {
        return project.id === _this16.riskProjectId;
      });
      if (project) {
        return project.projectPeriods.find(function (period) {
          return period.id === project.activePeriodId;
        });
      }
      return {};
    }
  }
};