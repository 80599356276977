function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import StaticTable from '@/components/Molecules/StaticTable';
import KpiParameters from '@/components/Organisms/PerformanceReport/KPIParameters';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import InlineSelect from '@/components/Atoms/Inputs/InlineSelect';
import { KpiSortingMixin } from '@/mixins/KpiSortingMixin';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'KPIStaticTable',
  introduction: 'A static table, for KPI data',
  description: 'This component shows KPI data with <static-table> inside.',
  components: {
    FeatherIcon: FeatherIcon,
    InputField: InputField,
    KpiParameters: KpiParameters,
    StaticTable: StaticTable,
    RismaTitle: RismaTitle,
    RismaToggle: RismaToggle,
    TinyMceBox: TinyMceBox,
    InlineSelect: InlineSelect
  },
  mixins: [KpiSortingMixin],
  props: {
    kpis: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    kpisPreviewScore: {
      type: Object,
      default: function _default() {}
    },
    metadata: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['delete', 'update', 'loadScore'],
  setup: function setup() {
    return {
      selectedSortingMethod: getLocalStorageComputed('kpi_static_table_sort', 'hw')
    };
  },
  data: function data() {
    return {
      columnsMaxWidth: {
        parameters: '150px',
        weight: '130px',
        previewScore: '130px',
        enable: '100px',
        '': '100px',
        delete: '100px'
      },
      itemPropertyToShowChildren: 'parameters',
      openSections: {},
      shownScore: {},
      changeTimers: {},
      titleLabel: 'title',
      tooltipsById: [],
      translate: getTranslate['KPIStaticTable']()
    };
  },
  computed: {
    metadataHashMap: function metadataHashMap() {
      return this.metadata.reduce(function (acc, curr) {
        acc[curr.name] = curr;
        return acc;
      }, {});
    },
    sortedKpis: function sortedKpis() {
      return _toConsumableArray(this.kpis).sort(this.sortingFunctions[this.selectedSortingMethod]);
    },
    dataset: function dataset() {
      return this.prepareDataset(this.sortedKpis, this.metadataHashMap, this.openSections);
    },
    columns: function columns() {
      return ['KPI', this.translate.kpiParameters, this.translate.weight, this.translate.previewScore, this.translate.enable, ''];
    }
  },
  methods: {
    showPreviewModal: function showPreviewModal(row) {
      if (!row.isShown) {
        this.$emit('loadScore', row.id);
      }
      this.shownScore[row.id] = !row.isShown;
    },
    prepareDataset: function prepareDataset(kpis, metadataHashMap, openSections) {
      var _this = this;
      return kpis.map(function (item, index) {
        return {
          kpi: {
            title: item.title,
            text: item.description,
            id: item.id
          },
          parameters: {
            id: item.id,
            showChildren: openSections[item.id],
            index: index
          },
          weight: {
            id: item.id,
            value: +item.weight * 100
          },
          previewScore: {
            id: item.id,
            value: _this.getKpiScoreById(_this.kpisPreviewScore, item.id),
            isShown: !!_this.shownScore[item.id]
          },
          enable: {
            id: item.id,
            value: item.enabled
          },
          delete: {
            id: item.id
          },
          children: [{
            parametersView: {
              id: item.id,
              metadata: metadataHashMap[item.kpiType],
              values: item.params
            }
          }]
        };
      });
    },
    getKpiScoreById: function getKpiScoreById(kpi, id) {
      if (kpi[id] === undefined) return '';
      return kpi[id] ? "".concat(kpi[id].kpiValue.toFixed(2), "%") : this.translate.kpiDisabled;
    },
    toggleChildren: function toggleChildren(row) {
      this.openSections[this.dataset[row].parameters.id] = !this.openSections[this.dataset[row].parameters.id];
      this.openSections = _objectSpread({}, this.openSections);
    },
    handleDescriptionChange: function handleDescriptionChange(slotData, property, data) {
      this.handleUpdateKpi(slotData.id, _defineProperty({}, property, data));
    },
    handleChangeData: function handleChangeData(kpiId, propertyName, value) {
      var _this2 = this;
      clearTimeout(this.changeTimers[kpiId]);
      this.changeTimers[kpiId] = setTimeout(function () {
        if (propertyName === 'weight') {
          value = (value / 100).toFixed(2);
        }
        _this2.handleUpdateKpi(kpiId, _defineProperty({}, propertyName, value));
      }, this.$debounceTimeout / 3);
    },
    handleUpdateKpi: function handleUpdateKpi(kpiId, value) {
      this.$emit('update', kpiId, value);
    },
    handleUpdateKpiParameters: function handleUpdateKpiParameters(kpiId, value) {
      this.$emit('update', kpiId, {
        params: value
      });
    },
    weightNormalizeFn: function weightNormalizeFn(value) {
      if (+value <= 0) return 0;
      if (+value >= 1000) return 1000;
      return value;
    },
    handleChangeWeight: function handleChangeWeight(event, data) {
      if (data.value !== event) {
        return this.handleChangeData(data.id, 'weight', event);
      }
    }
  }
};