import { getTranslate } from './translate';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { getInformationAssetsType } from '@/utils/InformationAssets';
import { Colors } from '@/Colors';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'ContactCard',
  introduction: 'ContactCard component',
  description: 'A container for displaying contact information',
  token: '<contact-card type="processors|controllers|systems|custom" info="{}" />',
  components: {
    FeatherIcon: FeatherIcon,
    PreviewModalLink: PreviewModalLink
  },
  props: {
    type: {
      type: String,
      required: true,
      note: 'The type of contact card',
      validator: function validator(value) {
        return [AssetsTypes.PROCESSORS, AssetsTypes.CONTROLLERS, AssetsTypes.SYSTEMS, AssetsTypes.CUSTOM, 'companies'].includes(value);
      }
    },
    info: {
      type: Object,
      required: true,
      note: 'The object with data'
    },
    hasPreviewMode: {
      type: Boolean,
      default: false,
      required: false,
      note: 'if it has previewModalLink instead of routerlink title'
    },
    isCuttedVersion: {
      type: Boolean,
      default: false,
      required: false,
      note: 'sometimes some data should not be presented'
    }
  },
  emits: ['updateFromPreviewMode', 'dismissModal'],
  data: function data() {
    return {
      assetsTypes: AssetsTypes,
      translate: getTranslate['ContactCard']()
    };
  },
  computed: {
    isCompany: function isCompany() {
      return this.info.objType === ObjectTypes.COMPANY;
    },
    transformedType: function transformedType() {
      if (this.isCompany) {
        return getInformationAssetsType(this.info, AssetsTypes.PROCESSORS);
      }
      return getInformationAssetsType(this.info, this.type);
    },
    href: function href() {
      if (this.info.url) {
        return this.info.url;
      }
      return "/compliance/informationassets/".concat(this.type, "/").concat(this.info.id);
    },
    companyStatus: function companyStatus() {
      var _this = this;
      if (this.isCompany) {
        var linkedCompanyTrafficLight = Colors[ObjectTypes.COMPANY].find(function (color) {
          var _this$info;
          return +color.number === ((_this$info = _this.info) === null || _this$info === void 0 ? void 0 : _this$info.vatStatus);
        });
        return linkedCompanyTrafficLight ? this.$trans(linkedCompanyTrafficLight.label) : '';
      }
      return '';
    }
  }
};