import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Alert from '@/components/Molecules/Modal/Alert';
import { clickOutside } from '@/utils/directives/clickOutside';
import { printTable } from '@/utils/export/print';
export default {
  name: 'RismaPrint',
  introduction: '',
  description: '',
  token: '<risma-print :dataset="dataset" :columns="columns" />',
  components: {
    FeatherIcon: FeatherIcon,
    Alert: Alert
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    dataset: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'Dataset for printing'
    },
    columns: {
      required: false,
      type: Object,
      default: function _default() {},
      note: 'Columns for printing'
    },
    headTitle: {
      required: false,
      default: 'RISMAcontrols',
      type: String,
      note: 'Head title'
    },
    mediaPrintCss: {
      required: false,
      default: '',
      type: String,
      note: 'Media print css styles'
    },
    printCss: {
      required: false,
      default: '',
      type: String,
      note: 'Print css styles (h1, table and so on)'
    },
    confirmMessage: {
      required: false,
      type: String,
      default: '',
      note: 'Confirmation popup message to display before exporting'
    },
    handler: {
      required: false,
      type: Function,
      default: null,
      note: 'Requires callback with Promise.resolve({dataset, columns, filename = ""})'
    }
  },
  data: function data() {
    return {
      showConfirmationAlert: false,
      translate: {
        print: this.$trans('Print')
      }
    };
  },
  computed: {
    printButtonLabel: function printButtonLabel() {
      return this.translate.print;
    }
  },
  methods: {
    confirmExport: function confirmExport() {
      if (this.handler) {
        this.callHandler();
        return;
      }
      if (this.confirmMessage) {
        this.showConfirmationAlert = true;
        return;
      }
      this.exportData();
    },
    exportData: function exportData() {
      this.showConfirmationAlert = false;
      var cssProps = {
        mediaPrintCSS: this.mediaPrintCss,
        printCSS: this.printCss
      };
      return printTable(this.dataset, this.columns, this.headTitle, cssProps);
    },
    callHandler: function callHandler() {
      var _this = this;
      this.handler().then(function (_ref) {
        var dataset = _ref.dataset,
          columns = _ref.columns,
          _ref$filename = _ref.filename,
          filename = _ref$filename === void 0 ? '' : _ref$filename;
        filename = filename || _this.headTitle;
        var cssProps = {
          mediaPrintCSS: _this.mediaPrintCss,
          printCSS: _this.printCss
        };
        printTable(dataset, columns, filename, cssProps);
      });
    }
  }
};