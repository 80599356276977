import ControlEntry from '@/components/Pages/Controls/ControlEntry';
import NodePage from '@/components/Pages/Compliance/NodeEdit/NodePage';
import IACont from '@/components/Pages/InformationAssets/Controller/InformationAssetsController';
import IAProc from '@/components/Pages/InformationAssets/Processor/InformationAssetsProcessor';
import IASys from '@/components/Pages/InformationAssets/System/InformationAssetsSystem';
import InformationAssetsIA from '@/components/Pages/InformationAssets/Custom/InformationAssetsIA';
import RiskEntry from '@/components/Pages/Risks/RiskEntry';
import ActionEntry from '@/components/Pages/Actions/ActionEntry';
import IncidentEntry from '@/components/Pages/Incident/IncidentEntry';
import ProcessNodeEntry from '@/components/Pages/ProcessTree/ProcessNodeEntry';
import ContractEntry from '@/components/Pages/Contracts/ContractEntry';
import CompanyEntry from '@/components/Pages/Companies/CompanyEntry';
import UserEntry from '@/components/Pages/User/UserEntry';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default (function (objType) {
  if (!map.has(objType)) {
    throw new Error('Unknown object type: ' + objType);
  }
  return map.get(objType);
});
var controlsFunction = function controlsFunction(activity) {
  return {
    component: ControlEntry,
    params: {
      cpath: activity.cpath,
      isPreview: true
    }
  };
};
var complianceFunction = function complianceFunction(activity) {
  return {
    component: NodePage,
    params: {
      nodeId: activity.id,
      projectId: activity.compliance_projects_id
    }
  };
};
var informationAssetControllerFunction = function informationAssetControllerFunction(activity) {
  return {
    component: IACont,
    params: {
      id: activity.id
    }
  };
};
var informationAssetSystemFunction = function informationAssetSystemFunction(activity) {
  return {
    component: IASys,
    params: {
      id: activity.id
    }
  };
};
var informationAssetProcessorFunction = function informationAssetProcessorFunction(activity) {
  return {
    component: IAProc,
    params: {
      id: activity.id
    }
  };
};
var informationAssetCustomFunction = function informationAssetCustomFunction(activity) {
  return {
    component: InformationAssetsIA,
    params: {
      id: activity.id
    }
  };
};

//risk is not needed props yet, but after some bug fixes it would
var riskFunction = function riskFunction(activity) {
  return {
    component: RiskEntry,
    params: {
      path: activity.rno,
      projectId: activity.projectId,
      periodId: activity.projectPeriodId ? activity.projectPeriodId : 'null',
      scoringId: activity.scoringId || null
    }
  };
};
var actionFunction = function actionFunction(activity) {
  return {
    component: ActionEntry,
    params: {
      slug: activity.slug,
      id: activity.id
    }
  };
};
var incidentFunction = function incidentFunction(activity) {
  return {
    component: IncidentEntry,
    params: {
      id: activity.id
    }
  };
};
var processNodeFunction = function processNodeFunction(activity) {
  return {
    component: ProcessNodeEntry,
    params: {
      processNodeId: activity.id,
      processTreeId: activity.processTreeId
    }
  };
};
var contractFunction = function contractFunction(activity) {
  return {
    component: ContractEntry,
    params: {
      id: activity.id
    }
  };
};
var companyFunction = function companyFunction(activity) {
  return {
    component: CompanyEntry,
    params: {
      id: activity.id
    }
  };
};
var gapAnalysisFunction = function gapAnalysisFunction(activity) {
  return {
    component: NodePage,
    params: {
      nodeId: activity.nodeId,
      projectId: activity.compliance_projects_id
    }
  };
};
var userFunction = function userFunction(user) {
  return {
    component: UserEntry,
    params: {
      id: user.id
    }
  };
};
var RISK_RISK_LIMITED = ObjectTypes.RISK_RISK_LIMITED,
  COMPLIANCE_NODE_LIMITED = ObjectTypes.COMPLIANCE_NODE_LIMITED,
  CONTROLS_CONTROL = ObjectTypes.CONTROLS_CONTROL,
  EXECUTION_INITIATIVE = ObjectTypes.EXECUTION_INITIATIVE,
  INCIDENTS_INCIDENT = ObjectTypes.INCIDENTS_INCIDENT,
  CONTROLS_CONTROL_LIMITED = ObjectTypes.CONTROLS_CONTROL_LIMITED,
  INCIDENTS_INCIDENT_LIMITED = ObjectTypes.INCIDENTS_INCIDENT_LIMITED,
  COMPLIANCE_NODE = ObjectTypes.COMPLIANCE_NODE,
  COMPLIANCE_INFORMATION_ASSET_PROCESSOR = ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR,
  COMPLIANCE_INFORMATION_ASSET_CONTROLLER = ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER,
  COMPLIANCE_INFORMATION_ASSET_SYSTEM = ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM,
  INFORMATION_ASSET = ObjectTypes.INFORMATION_ASSET,
  INFORMATION_ASSET_LIMITED = ObjectTypes.INFORMATION_ASSET_LIMITED,
  EXECUTION_INITIATIVE_LIMITED = ObjectTypes.EXECUTION_INITIATIVE_LIMITED,
  RISK_RISK = ObjectTypes.RISK_RISK,
  COMPLIANCE_PROCESS_TREE_NODE = ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE,
  COMPLIANCE_PROCESS_TREE_NODE_LIMITED = ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE_LIMITED,
  CONTRACT = ObjectTypes.CONTRACT,
  CONTRACT_LIMITED = ObjectTypes.CONTRACT_LIMITED,
  COMPANY = ObjectTypes.COMPANY,
  COMPLIANCE_GAP_ANALYSIS = ObjectTypes.COMPLIANCE_GAP_ANALYSIS,
  USER = ObjectTypes.USER;
var map = new Map([[CONTROLS_CONTROL, controlsFunction], [CONTROLS_CONTROL_LIMITED, controlsFunction], [COMPLIANCE_NODE, complianceFunction], [COMPLIANCE_NODE_LIMITED, complianceFunction], [COMPLIANCE_INFORMATION_ASSET_CONTROLLER, informationAssetControllerFunction], [COMPLIANCE_INFORMATION_ASSET_PROCESSOR, informationAssetProcessorFunction], [COMPLIANCE_INFORMATION_ASSET_SYSTEM, informationAssetSystemFunction], [INFORMATION_ASSET, informationAssetCustomFunction], [INFORMATION_ASSET_LIMITED, informationAssetCustomFunction], [RISK_RISK, riskFunction], [RISK_RISK_LIMITED, riskFunction], [EXECUTION_INITIATIVE, actionFunction], [EXECUTION_INITIATIVE_LIMITED, actionFunction], [INCIDENTS_INCIDENT, incidentFunction], [INCIDENTS_INCIDENT_LIMITED, incidentFunction], [COMPLIANCE_PROCESS_TREE_NODE, processNodeFunction], [COMPLIANCE_PROCESS_TREE_NODE_LIMITED, processNodeFunction], [CONTRACT, contractFunction], [CONTRACT_LIMITED, contractFunction], [COMPANY, companyFunction], [COMPLIANCE_GAP_ANALYSIS, gapAnalysisFunction], [USER, userFunction]]);