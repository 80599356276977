function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RiskReportGrid from '@/components/Organisms/Risk/RiskReportGrid';
import RiskGridDisplay from '@/components/Molecules/Risk/RiskGridDisplay';
import RiskReportsFilters from '@/components/Molecules/Risk/RiskReports/RiskReportsFilters';
import RiskReportsFiltersErm from '@/components/Molecules/Risk/RiskReports/RiskReportsFiltersErm';
import { CommonAssetsMixin } from '@/mixins/CommonAssetsMixin';
import { RiskUniversalMixin } from '@/components/Pages/Risks/RiskUniversalMixin';
import { RiskGridMixin } from '@/mixins/RiskGridMixin';
import * as RiskApi from '@/api/risk/risk';
import { getCurrentScoring as _getCurrentScoring, getAllScorings } from '@/api/risk/scoring';
import * as DataAPI from '@/api/risma/data';
import * as Utils from '@/utils/Utils';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import { UserLevels } from '@/constants/UserLevels';
import { RiskStatus, ErmRiskStatus } from '@/constants/risks/RiskStatus';
import RiskReportUtilsRisk from '@/components/Pages/Risks/RiskReports/RiskReportUtilsRisk';
import RiskReportUtilsErm from '@/components/Pages/Risks/RiskReports/RiskReportUtilsErm';
import { filterKeysMap, filtersIdentifierMap, filterOptionsErm, getOptionsFromScoring, prepareReportOptions, getFiltersFromReportOptions, wrongCustomFields } from '@/components/Pages/Risks/RiskReports/RiskReportUtils';
import { checkERMByProjectId } from '@/utils/risks/check';
import { getNodesForSelectBox } from '@/utils/format/Compliance';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'RiskReportsRisks',
  introduction: 'Main Risk Report page component',
  description: 'Complex page component',
  token: '<risk-report />',
  components: {
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RiskGridDisplay: RiskGridDisplay,
    RiskReportGrid: RiskReportGrid,
    RiskReportsFilters: RiskReportsFilters,
    RiskReportsFiltersErm: RiskReportsFiltersErm
  },
  mixins: [CommonAssetsMixin, RiskUniversalMixin, RiskGridMixin],
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Id of the Risk Project'
    },
    savedReportOptions: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      allRisks: [],
      risks: [],
      riskStateMatrix: [],
      includeUnderlyingRisks: false,
      comparePeriod: null,
      comparePeriodId: {},
      comparePeriodByConsequence: {},
      previousPeriods: [],
      isLoaded: false,
      isReportLoading: false,
      unSorted: true,
      threatSelected: [],
      levelOfThreatSelected: [],
      vulnerabilitySelected: [],
      systems: [],
      linkedSystemsSelected: [],
      organisationGroups: [],
      responsibleSelected: [],
      managementLevels: [],
      tagsSelected: [],
      riskApprovalEnabled: false,
      processLibraryNodes: [],
      complianceNodes: [],
      nothingToShowMessageVisible: false,
      scoring: null,
      filters: {},
      gridData: [],
      riskTypes: [],
      parentRisksByPeriod: {},
      riskGridUpdateKey: 1,
      reportOptions: {},
      translate: getTranslate['RiskReportsRisks']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), mapState(useActivityTypesStore, {
    activityTypes: 'riskActivityTypes'
  })), {}, {
    riskDescription: function riskDescription() {
      return this.translate.specifyYourReportByChoosingAPeriodAndACon;
    },
    isErm: function isErm() {
      return checkERMByProjectId(this.projectId, this.currentUser);
    },
    periodsSelect: function periodsSelect() {
      if (!this.periods) {
        return [];
      }
      var periodPredicate = function periodPredicate(item) {
        return item.status !== RiskStatus.DRAFT;
      };
      if (this.isErm) {
        periodPredicate = function periodPredicate(item) {
          return item.status !== ErmRiskStatus.NEW && item.status !== ErmRiskStatus.EDITING;
        };
      }
      return this.periods.filter(periodPredicate).map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          label: item.title || item.version,
          value: item.id || item.id
        });
      });
    },
    riskUsers: function riskUsers() {
      var _this = this;
      return this.activeUsers.filter(function (user) {
        if (user.is_admin) {
          return true;
        }
        if (_this.isErm && user.level_risk > UserLevels.NONE) {
          return true;
        }
        return user.projects.filter(function (item) {
          return item.objType === ObjectTypes.RISK_PROJECT && item.id === +_this.projectId && item.access_level > UserLevels.NONE;
        }).length > 0;
      });
    },
    riskGridData: function riskGridData() {
      // TODO: Fix riskStateMatrix
      if (!this.currentPeriod) {
        return null;
      }
      var matrix = this.getMatrix([]);
      matrix.grid = this.riskStateMatrix;
      return matrix;
    },
    currentPeriod: function currentPeriod() {
      var _this2 = this;
      return this.periodsSelect.find(function (item) {
        return item.value === _this2.filters.periodId;
      }) || this.periodsSelect[0];
    },
    isCurrentPeriodSelected: function isCurrentPeriodSelected() {
      return !this.isErm ? this.currentPeriod.isCurrent : this.scoring.id === this.currentPeriod.id;
    },
    consequences: function consequences() {
      if (!this.currentPeriod || !this.currentPeriod.consequenceRows) {
        return [];
      }
      return this.currentPeriod.consequenceRows.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          value: item.id
        });
      });
    },
    includeUnderlyingRisksOptions: function includeUnderlyingRisksOptions() {
      return [{
        name: 'Include',
        state: this.filters.includeUnderlyingRisks
      }];
    },
    organisationGroupsSelect: function organisationGroupsSelect() {
      var orgGroup = this.organisationGroups.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          value: item.id + ''
        });
      });
      return [{
        label: this.$trans('All'),
        value: 'all'
      }].concat(orgGroup);
    },
    utils: function utils() {
      // Utils according to project Id;
      return this.isErm ? RiskReportUtilsErm : RiskReportUtilsRisk;
    },
    filterOptions: function filterOptions() {
      if (this.isErm) {
        var obj = _objectSpread({
          periods: this.periodsSelect,
          impact: this.currentPeriod && getOptionsFromScoring(this.currentPeriod, 'impact'),
          likelihood: this.currentPeriod && getOptionsFromScoring(this.currentPeriod, 'likelihood'),
          managementLevels: this.managementLevels,
          tags: this.tags,
          users: this.riskUsers,
          organisationsList: this.organisations,
          organisationGroups: this.organisationGroupsSelect,
          riskTypes: this.riskTypes
        }, filterOptionsErm());
        if (!this.riskApprovalEnabled) {
          delete obj.approval;
        }
        return obj;
      }
      return {
        periods: this.periodsSelect,
        riskAssessments: this.consequences,
        threats: this.threats,
        levelOfThreats: this.levelOfThreatsOptions,
        vulnerabilities: this.vulnerabilities,
        systems: this.systems,
        users: this.riskUsers,
        tags: this.tags,
        organisationsList: this.organisations,
        includeUnderlyingRisks: this.filters.includeUnderlyingRisksOptions,
        parentRisks: this.parentRisksList,
        activityTypes: this.activityTypes
      };
    },
    parentRisksList: function parentRisksList() {
      var _this$filters;
      return this.parentRisksByPeriod[(_this$filters = this.filters) === null || _this$filters === void 0 ? void 0 : _this$filters.periodId] || [];
    },
    levelOfThreatsOptions: function levelOfThreatsOptions() {
      if (this.isErm || !this.currentPeriod || !this.currentPeriod.threatLevels) {
        return [];
      }
      return this.currentPeriod.threatLevels.map(function (item) {
        return {
          label: item.title,
          id: item.id
        };
      });
    },
    levelOfThreatsFull: function levelOfThreatsFull() {
      if (this.isErm) return [];
      var threatLevels = this.periods.reduce(function (acc, curr) {
        acc.push.apply(acc, _toConsumableArray(curr.threatLevels));
        return acc;
      }, []);
      return threatLevels.map(function (item) {
        return {
          label: item.title,
          id: item.id
        };
      });
    },
    probabilitiesByPeriod: function probabilitiesByPeriod() {
      if (this.isErm) return [];
      return this.currentPeriod.probabilityRows.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          value: item.id
        });
      });
    },
    riskAssessmentsMatchLabels: function riskAssessmentsMatchLabels() {
      // table to get row label - [RiskAssessmentid][PeriodId] = Label
      return this.periods.reduce(function (acc, curr) {
        if (curr.consequenceRows) {
          acc[curr.id] = curr.consequenceRows.reduce(function (obj, current) {
            obj[current.id] = current.label;
            return obj;
          }, {});
        }
        return acc;
      }, {});
    },
    inherentRisksEnabled: function inherentRisksEnabled() {
      return this.currentRiskProject.inherentRisksEnabled;
    },
    localStorageKey: function localStorageKey() {
      return "risk".concat(this.projectId, "_report_filters-").concat(this.matrixInverted);
    }
  }),
  watch: {
    savedReportOptions: function savedReportOptions() {
      if (this.savedReportOptions) {
        this.reportOptions = _objectSpread({}, this.savedReportOptions);
        this.fetchReport(this.reportOptions);
      }
    },
    reportOptions: function reportOptions() {
      localStorage.setItem(this.localStorageKey, JSON.stringify(this.reportOptions));
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    handleGetProcessLibraryNodes: function handleGetProcessLibraryNodes() {
      var _this3 = this;
      var processTreeId;
      return new Promise(function (resolve, reject) {
        _this3.getProcessLibraryTree().then(function (response) {
          if (response[0].id !== null) {
            processTreeId = response[0].id;
            return _this3.getNodes(response[0].id);
          } else {
            resolve();
          }
        }).then(function (rootNode) {
          _this3.processLibraryNodes = [];
          Utils.pushChildrenToParent(rootNode, _this3.processLibraryNodes);
          _this3.processLibraryNodes = _this3.processLibraryNodes.map(function (node) {
            return _objectSpread(_objectSpread({}, node), {}, {
              processTreeId: processTreeId
            });
          });
          resolve();
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    getProcessLibraryTree: function getProcessLibraryTree() {
      return new Promise(function (resolve, reject) {
        DataAPI.getAllTrees().then(function (response) {
          return resolve(response);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    getNodes: function getNodes(processLibraryTreeId) {
      return new Promise(function (resolve, reject) {
        DataAPI.getNodesByProcessTreeId(processLibraryTreeId).then(function (response) {
          return resolve(response);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    getComplianceNodes: function getComplianceNodes() {
      var _this4 = this;
      return getNodesForSelectBox().then(function (nodes) {
        _this4.complianceNodes = nodes;
      });
    },
    fetchSavedReport: function fetchSavedReport(savedOptions) {
      this.reportOptions = _objectSpread({}, savedOptions);
      this.fetchReport(this.reportOptions);
    },
    fetchReport: function fetchReport(reportOptions) {
      this.updateFilters(reportOptions);
      this.setDefaultComparePeriod();
      this.setPreviousPeriods();
      return this.createReport();
    },
    updateFilters: function updateFilters(reportOptions) {
      if (this.isErm) {
        var likelihood = this.filterOptions.likelihood ? this.filterOptions.likelihood[0] : getOptionsFromScoring(this.scoring, 'likelihood')[0];
        var impact = this.filterOptions.impact ? this.filterOptions.impact[0] : getOptionsFromScoring(this.currentPeriod, 'impact')[0];
        this.filters = {
          periodId: this.scoring.id,
          yAxis: likelihood ? likelihood.value : '',
          xAxis: impact ? impact.value : '',
          organisationGroups: 'all',
          status: 'all',
          updated: 'all',
          topRisks: 'all',
          managementLevels: this.managementLevels,
          tags: []
        };
      } else {
        this.filters = {
          periodId: reportOptions !== null && reportOptions !== void 0 && reportOptions.periodId && this.periods.find(function (item) {
            return item.id === reportOptions.periodId;
          }) ? reportOptions.periodId : this.periods.find(function (item) {
            return item.isCurrent;
          }).id,
          includeUnderlyingRisks: this.includeUnderlyingRisks,
          tags: []
        };
      }
      var _getFiltersFromReport = getFiltersFromReportOptions(reportOptions, this.filterOptions),
        _getFiltersFromReport2 = _getFiltersFromReport.filters,
        filters = _getFiltersFromReport2 === void 0 ? {} : _getFiltersFromReport2,
        _getFiltersFromReport3 = _getFiltersFromReport.matrix,
        matrix = _getFiltersFromReport3 === void 0 ? [] : _getFiltersFromReport3;
      this.filters = _objectSpread(_objectSpread({}, this.filters), filters);
      this.riskStateMatrix = matrix;
    },
    getData: function getData() {
      var _this5 = this;
      var promises = [this.getRiskProjects(), this.getAssets(), this.getControlsList(), this.getInitiatives(), this.handleGetProcessLibraryNodes(), this.getComplianceNodes()];
      if (this.isErm) {
        promises = promises.concat([this.getScorings(), this.getCurrentScoring(), this.getRiskManagementLevels(), this.getRiskTypes(), this.getOrganisationGroups()]);
      } else {
        promises.push(this.getParentRisksList());
      }
      return Promise.all(promises).then(function () {
        if (_this5.isErm) {
          _this5.currentRiskProject = _this5.riskProjects.find(function (item) {
            return +item.id === +_this5.projectId;
          });
          _this5.riskApprovalEnabled = !!_this5.currentRiskProject.riskApproval;
        } else {
          _this5.prepareData();
          _this5.afterPrepareData();
        }
        if (_this5.savedReportOptions) {
          _this5.reportOptions = _objectSpread({}, _this5.savedReportOptions);
        } else {
          _this5.reportOptions = JSON.parse(localStorage.getItem(_this5.localStorageKey)) || {};
        }
        return _this5.fetchReport(_this5.reportOptions);
      }).then(function () {
        _this5.isLoaded = true;
      });
    },
    getScorings: function getScorings() {
      var _this6 = this;
      return getAllScorings().then(function (periods) {
        _this6.periods = periods.list.reverse();
      });
    },
    getCurrentScoring: function getCurrentScoring() {
      var _this7 = this;
      return _getCurrentScoring().then(function (scoring) {
        _this7.scoring = scoring;
        _this7.filters.periodId = _this7.scoring.id;
      });
    },
    getRiskManagementLevels: function getRiskManagementLevels() {
      var _this8 = this;
      return RiskApi.getRisksManagementLevels().then(function (_ref) {
        var list = _ref.list;
        return _this8.managementLevels = list;
      });
    },
    getRiskTypes: function getRiskTypes() {
      var _this9 = this;
      return RiskApi.getRiskTypes().then(function (_ref2) {
        var list = _ref2.list;
        return _this9.riskTypes = list;
      });
    },
    getOrganisationGroups: function getOrganisationGroups() {
      var _this10 = this;
      return DataAPI.getOrganisationalGroups().then(function (_ref3) {
        var list = _ref3.list;
        return _this10.organisationGroups = list;
      });
    },
    getRiskReports: function getRiskReports(consequenceId) {
      var _this11 = this;
      this.allRisks = [];
      if (!consequenceId) {
        this.risks = [];
      }
      this.isReportLoading = true;
      var params = this.createParams();
      return RiskApi.getReports(params).then(function (_ref4) {
        var list = _ref4.list;
        _this11.isReportLoading = false;
        _this11.allRisks = _toConsumableArray(list);
        if (_this11.isErm) {
          _this11.risks = _this11.extendRiskData(list, _this11.filters);
        } else if (consequenceId) {
          return _this11.changePreviousRiskDataForConsequence(list, consequenceId);
        } else {
          _this11.risks = _this11.extendRiskData(_this11.filterByPeriod(list), _this11.consequences, _this11.riskAssessmentsMatchLabels);
        }
        _this11.risks = _this11.filterRisks(_this11.risks);
      }).catch(function (e) {
        throw e;
      });
    },
    getParentRisksList: function getParentRisksList() {
      var _this12 = this;
      return RiskApi.getParentRisksList(this.projectId).then(function (response) {
        _this12.parentRisksByPeriod = response;
      });
    },
    filterByPeriod: function filterByPeriod(data) {
      var _this13 = this;
      return data.filter(function (item) {
        return item.projectPeriodId === _this13.currentPeriod.id;
      });
    },
    filterRisks: function filterRisks(data) {
      var _this14 = this;
      // Filter by Consequence parameter on UI
      var result = this.isErm ? data : data.filter(function (item) {
        var consequense = item.residualConsequenceRowScores.find(function (item) {
          return _this14.filters.riskAssessments.find(function (it) {
            return it.id === item.rowId;
          }) && item.column.value > 0;
        });
        var probability = item.residualProbabilityRowScores.find(function (item) {
          return _this14.filters.riskAssessments.find(function (it) {
            return it.id === item.rowId;
          }) && item.column.value > 0;
        });
        return consequense && probability;
      });
      // Filter by selected cells in the matrix
      var selectedMatrix = this.findSelectedMatrix();
      if (selectedMatrix.length) {
        result = this.utils.filterRiskBySelectedMatrix(result, selectedMatrix, this.filters, this.matrixInverted);
      }
      return result;
    },
    findSelectedMatrix: function findSelectedMatrix() {
      var result = [];
      this.riskStateMatrix.map(function (row, rowIndex) {
        row.map(function (cell, cellIndex) {
          if (cell && cell.length && cell[0].isActive) {
            if (!result.find(function (item) {
              return item.x === cellIndex && item.y === rowIndex;
            })) {
              result.push({
                x: cellIndex,
                y: rowIndex
              });
            }
          }
        });
      });
      result.map(function (item) {
        ++item.x;
        ++item.y;
      });
      return result;
    },
    extendRiskData: function extendRiskData(data) {
      var _this15 = this;
      for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        rest[_key - 1] = arguments[_key];
      }
      var result = _toConsumableArray(data);
      result.map(function (item) {
        var _this15$utils;
        item._scores = {};
        (_this15$utils = _this15.utils).extendRiskData.apply(_this15$utils, [item].concat(rest));
        if (_this15.matrixInverted) {
          var _this15$utils2;
          (_this15$utils2 = _this15.utils).invertRiskData.apply(_this15$utils2, [item].concat(rest));
        }
      });
      return result;
    },
    createParams: function createParams() {
      var _this$filters$parentR,
        _this16 = this;
      var _this$filters$customF = this.filters.customFields,
        customFields = _this$filters$customF === void 0 ? {} : _this$filters$customF;
      var params = {
        filters: {
          projectId: this.projectId
        },
        custom_fields: {},
        data: ['customFields', 'comparePeriod', 'linkedActivities', 'linkedAssets']
      };
      if ((_this$filters$parentR = this.filters.parentRisks) !== null && _this$filters$parentR !== void 0 && _this$filters$parentR.length && !this.filters.includeUnderlyingRisks) {
        this.filters.includeUnderlyingRisks = true;
      }
      if (this.filters.includeUnderlyingRisks) {
        params.filters['underlyingrisks'] = 'include';
      }
      if (this.comparePeriodId) {
        params.filters['comparePeriodId'] = this.comparePeriodId;
      }
      if (this.isErm) {
        params.filters['scoringId'] = this.currentPeriod.id;
      } else {
        params.filters['projectPeriodId'] = this.currentPeriod.id;
      }
      Object.entries(this.filters).forEach(function (_ref5) {
        var _ref6 = _slicedToArray(_ref5, 2),
          key = _ref6[0],
          value = _ref6[1];
        var filterKey = filterKeysMap[key] || key;
        var exceptions = ['xAxis', 'yAxis', 'periodId', 'includeUnderlyingRisks', 'customFields'].indexOf(filterKey) !== -1;
        if (exceptions || value === 'all') return;
        if (value && Array.isArray(value)) {
          value.forEach(function (item) {
            if (!params.filters[filterKey]) {
              params.filters[filterKey] = [];
            }
            params.filters[filterKey].push(item[filtersIdentifierMap[key] || 'id']);
          });
        } else if (value) {
          params.filters[filterKey] = value;
        }
      });
      Object.entries(customFields).forEach(function (_ref7) {
        var _ref8 = _slicedToArray(_ref7, 2),
          key = _ref8[0],
          value = _ref8[1];
        if (value === 'all') return;
        if (wrongCustomFields[key] && _this16.isErm) {
          var customField = _this16.currentPeriod.fields.find(function (item) {
            return item.label === key;
          });
          var index = Object.keys(customField.options).find(function (item) {
            return customField.options[item].id == value;
          });
          params.filters[wrongCustomFields[key]] = index;
        } else {
          params.custom_fields[key] = value;
        }
      });
      return params;
    },
    handleCreateReport: function handleCreateReport() {
      var _this17 = this;
      this.resetReportSavedOptions();
      this.setDefaultComparePeriod();
      this.setPreviousPeriods();
      this.createReport().then(function () {
        return _this17.reportOptions = prepareReportOptions(_this17.filters, _this17.riskStateMatrix);
      });
    },
    createReport: function createReport() {
      var _this18 = this;
      var isUpdated = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      if (!isUpdated) return Promise.resolve();
      var isValid = this.isErm ? this.filters.yAxis && this.filters.xAxis : this.filters.riskAssessments && this.filters.riskAssessments.length;
      if (!isValid) return Promise.resolve();
      this.nothingToShowMessageVisible = false;
      this.gridData = this.createGridData();
      return this.getRiskReports().then(function () {
        return _this18.nothingToShowMessageVisible = !_this18.risks.length;
      });
    },
    setDefaultComparePeriod: function setDefaultComparePeriod() {
      var _this19 = this;
      if (this.currentPeriod.previousId !== undefined && this.currentPeriod.previousId !== null) {
        this.comparePeriodId = this.currentPeriod.previousId;
        var comparePeriod = this.periodsSelect.find(function (period) {
          return period.id === _this19.comparePeriodId;
        });
        if (this.filters.riskAssessments) {
          this.filters.riskAssessments.forEach(function (row) {
            _this19.comparePeriodByConsequence[row.id] = comparePeriod;
          });
        } else if (this.isErm) {
          this.comparePeriodByConsequence['erm'] = comparePeriod;
        }
      }
    },
    setPreviousPeriods: function setPreviousPeriods() {
      var _this20 = this;
      if (+this.currentPeriod.id === +this.periodsSelect[this.periodsSelect.length - 1].id) {
        this.previousPeriods = [];
        return;
      }
      var index = this.periodsSelect.findIndex(function (period) {
        return +period.id === +_this20.currentPeriod.previousId;
      });
      this.previousPeriods = this.periodsSelect.slice(index);
    },
    onStateChanged: function onStateChanged(e) {
      this.riskStateMatrix = _toConsumableArray(e);
    },
    onPeriodChanged: function onPeriodChanged(_ref9) {
      var consequenceId = _ref9.consequenceId,
        event = _ref9.event;
      this.comparePeriod = this.periodsSelect.filter(function (item) {
        return item.id === event;
      });
      if (this.comparePeriod.length > 0) {
        this.comparePeriod = this.comparePeriod[0];
        this.comparePeriodId = this.comparePeriod.id;
        this.comparePeriodByConsequence[consequenceId] = this.comparePeriod;
      }
      this.comparePeriodByConsequence = _objectSpread({}, this.comparePeriodByConsequence);
      this.getRiskReports(consequenceId);
    },
    changePreviousRiskDataForConsequence: function changePreviousRiskDataForConsequence(data, consequenceId) {
      var _this21 = this;
      var result = _toConsumableArray(data);
      result.map(function (item) {
        var risk = _this21.risks.find(function (risk) {
          return risk.id === item.id;
        });
        if (!risk) return;
        var foundConsequence = item[RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES].find(function (item) {
          return item.rowId === consequenceId;
        });
        var foundProbability = item[RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES].find(function (item) {
          return item.rowId === consequenceId;
        });
        risk._scores[consequenceId].scoresPrevious = _this21.utils.getPreviousScores(item, _this21.riskAssessmentsMatchLabels, foundConsequence, foundProbability);
      });
      // To update arrows on the matrix need to update risks array
      this.risks = _toConsumableArray(this.risks);
    },
    resetReportSavedOptions: function resetReportSavedOptions() {
      if (this.savedReportOptions) {
        this.$router.replace({
          query: {}
        });
      }
    },
    handleResetFilters: function handleResetFilters() {
      this.reportOptions = {};
      this.updateFilters();
      this.riskStateMatrix = [];
      this.riskGridUpdateKey++;
    },
    handleChangePeriod: function handleChangePeriod(key) {
      var axisValue = this.filters[key];
      var periodId = this.filters.periodId;
      this.handleResetFilters();
      this.filters = _objectSpread(_objectSpread({}, this.filters), {}, _defineProperty({
        periodId: periodId
      }, key, axisValue));
    },
    onUpdateFromPreviewMode: function onUpdateFromPreviewMode(updatedData) {
      var updatedRiskIdx = this.risks.findIndex(function (risk) {
        return risk.id === updatedData.id;
      });
      if (updatedRiskIdx === -1) return;
      this.risks[updatedRiskIdx] = _objectSpread(_objectSpread({}, this.risks[updatedRiskIdx]), {}, {
        displayHeadline: updatedData.displayHeadline,
        description: updatedData.description,
        organisationIds: updatedData.organisationIds,
        tagIds: updatedData.tagIds,
        links: updatedData.links,
        approved: updatedData.approved,
        approvalHistory: updatedData.approvalHistory,
        gridStatus: updatedData.gridStatus
      });
    },
    createGridData: function createGridData() {
      var gridDataArray = this.utils.createGridData(this.filters, this.currentPeriod);
      if (!this.matrixInverted) {
        return gridDataArray;
      }
      return gridDataArray.map(function (gridData) {
        return {
          xLabel: gridData.yLabel,
          yLabel: gridData.xLabel,
          gridSizeX: gridData.gridSizeY,
          gridSizeY: gridData.gridSizeX,
          id: gridData.id
        };
      });
    }
  }
};