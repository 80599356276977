import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, mergeProps as _mergeProps, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-9b7073f6"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "collapsible-panel"
};
var _hoisted_2 = {
  key: 0,
  class: "collapsible-panel-content flex overflow-hidden pb-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "collapsible-panel-header cursor-pointer flex items-center",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.toggleCollapse && $options.toggleCollapse.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-down",
    class: _normalizeClass(['transform duration-500 text-blue-750 stroke-2 mr-2 inline-block align-top', {
      'rotate-180': $setup.isExpanded
    }, {
      'order-2': $props.isIconLast
    }])
  }, null, 8 /* PROPS */, ["class"]), _renderSlot(_ctx.$slots, "header", {}, undefined, true)]), _createVNode(_Transition, _mergeProps({
    name: "accordion-item"
  }, _ctx.$attrs, {
    onEnter: $options.startTransition,
    onAfterEnter: $options.endTransition,
    onBeforeLeave: $options.startTransition,
    onAfterLeave: $options.endTransition
  }), {
    default: _withCtx(function () {
      return [$setup.isExpanded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "content", {}, undefined, true)])) : _createCommentVNode("v-if", true)];
    }),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["onEnter", "onAfterEnter", "onBeforeLeave", "onAfterLeave"])]);
}