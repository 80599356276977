function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { DocumentTypesIds } from '@/constants/contracts/DocumentTypes';
import { CONTRACTS_TABLE_COLUMNS } from '@/constants/contracts/contractsTableColumns';
import { MODULES } from '@/constants/modules';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import * as Utils from '@/utils/Utils';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import { getContracts as _getContracts } from '@/api/contracts/contracts';
import { getWorkflowStatusesByModule } from '@/api/risma/data';
import { getCompanyIdsInArray } from '@/utils/format/companies';
import { formatTimeToDateFormat } from '@/utils/date';
import * as fileAPI from '@/api/risma/file';
export default {
  name: 'ContractsDPAOverview',
  description: 'Component for displaying a list that presents all active contracts and requirements regarding DPA for each contract',
  token: '<contracts-dpa-overview />',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaDatatable: RismaDatatable,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  mixins: [RismaDatatableDefaultMixin],
  data: function data() {
    return {
      stateLocal: 'contracts-data-process-agreement',
      columnsMeta: [{
        targets: ['startDate', 'endDate'],
        visible: false
      }],
      disabledFilterByColumns: ['startDate', 'endDate'],
      decoupleFilterFields: [{
        key: 'responsible'
      }, {
        key: 'companies'
      }],
      files: [],
      companies: [],
      workflowStatuses: [],
      contracts: [],
      dataset: [],
      isLoading: false,
      translate: getTranslate['ContractsDPAOverview']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useActivityTypesStore, {
    activityTypes: 'contractActivityTypes'
  })), {}, {
    columns: function columns() {
      return {
        dpa: this.translate.dataProcessorAgreement,
        mainContract: this.translate.mainContract,
        uniqueId: this.$trans(CONTRACTS_TABLE_COLUMNS.contractNumber),
        title: this.$trans(CONTRACTS_TABLE_COLUMNS.titlePreview),
        companies: this.$trans(CONTRACTS_TABLE_COLUMNS.companies),
        activityType: this.$trans(CONTRACTS_TABLE_COLUMNS.activityType),
        status: this.$trans(CONTRACTS_TABLE_COLUMNS.status),
        startDate: this.$trans(CONTRACTS_TABLE_COLUMNS.start),
        endDate: this.$trans(CONTRACTS_TABLE_COLUMNS.end)
      };
    },
    enhancedContracts: function enhancedContracts() {
      var newContracts = this.contracts.map(function (contract) {
        var _contract$links;
        return _objectSpread(_objectSpread({}, contract), {}, {
          hasMainContract: false,
          hasDPA: (contract === null || contract === void 0 || (_contract$links = contract.links) === null || _contract$links === void 0 || (_contract$links = _contract$links.dataprocessor_processor) === null || _contract$links === void 0 ? void 0 : _contract$links.length) || false,
          files: []
        });
      });
      this.files.forEach(function (file) {
        var _file$docTypeIds, _file$docTypeIds2;
        var contractIdx = newContracts.findIndex(function (contract) {
          return +contract.id === +file.moduleId;
        });
        if (contractIdx === -1) return;
        newContracts[contractIdx].files.push(file);
        if ((_file$docTypeIds = file.docTypeIds) !== null && _file$docTypeIds !== void 0 && _file$docTypeIds.includes(DocumentTypesIds.MAIN)) {
          newContracts[contractIdx].hasMainContract = true;
        }
        if ((_file$docTypeIds2 = file.docTypeIds) !== null && _file$docTypeIds2 !== void 0 && _file$docTypeIds2.includes(DocumentTypesIds.DPA)) {
          newContracts[contractIdx].hasDPA = true;
        }
      });
      return newContracts;
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      this.isLoading = true;
      return this.getInitialData().then(function () {
        _this.updateDataset();
        _this.isLoading = false;
      });
    },
    getInitialData: function getInitialData() {
      return Promise.all([this.getCompanies(), this.getFiles(), this.getWorkflowStatuses(), this.getContracts()]);
    },
    getContracts: function getContracts() {
      var _this2 = this;
      return _getContracts().then(function (_ref) {
        var list = _ref.list;
        _this2.contracts = list;
      });
    },
    getFiles: function getFiles() {
      var _this3 = this;
      return fileAPI.searchFiles('?module=contracts').then(function (_ref2) {
        var list = _ref2.list;
        _this3.files = list;
      });
    },
    getCompanies: function getCompanies() {
      var _this4 = this;
      return _getCompanies().then(function (_ref3) {
        var list = _ref3.list;
        return _this4.companies = list;
      });
    },
    getWorkflowStatuses: function getWorkflowStatuses() {
      var _this5 = this;
      return getWorkflowStatusesByModule(MODULES.CONTRACTS).then(function (_ref4) {
        var list = _ref4.list;
        return _this5.workflowStatuses = list;
      });
    },
    getCompaniesList: function getCompaniesList(ids) {
      return getCompanyIdsInArray(ids, this.companies);
    },
    getActivityTypeLabel: function getActivityTypeLabel(id) {
      return Utils.findPropById(id, this.activityTypes, 'label');
    },
    getWorkflowStatusLabel: function getWorkflowStatusLabel(id) {
      return Utils.findPropById(id, this.workflowStatuses, 'title');
    },
    prepareDataset: function prepareDataset() {
      var _this6 = this;
      this.dataset = this.enhancedContracts.map(function (item) {
        var _item$links;
        var companies = _this6.getCompaniesList((item === null || item === void 0 || (_item$links = item.links) === null || _item$links === void 0 ? void 0 : _item$links.company) || []);
        return {
          dpa: {
            field: item.hasDPA ? _this6.translate.yes : _this6.translate.no
          },
          mainContract: {
            field: item.hasMainContract ? _this6.translate.yes : _this6.translate.no
          },
          uniqueId: {
            field: item.uniqueId || ''
          },
          title: {
            activity: item,
            fieldText: item.title,
            hoverTitle: DatatableRenderer.getItemHoverTitle(item)
          },
          companies: {
            field: companies,
            fieldText: companies.map(function (item) {
              return item.name;
            }).join(', ')
          },
          activityType: {
            field: _this6.getActivityTypeLabel(item.activityTypeId)
          },
          status: {
            field: _this6.$trans(_this6.getWorkflowStatusLabel(item.workflowStatusId))
          },
          startDate: {
            field: item.startDate ? formatTimeToDateFormat(item.startDate) : ''
          },
          endDate: {
            field: item.endDate ? formatTimeToDateFormat(item.endDate) : ''
          }
        };
      });
    },
    updateDataset: function updateDataset() {
      this.prepareDataset(), this.enhanceDataset(this.dataset, this.columns);
    },
    reloadContracts: function reloadContracts() {
      return Promise.all([this.getFiles(), this.getContracts()]);
    },
    onPreviewDismiss: function onPreviewDismiss() {
      var _this7 = this;
      this.isLoading = true;
      this.reloadContracts().then(function () {
        _this7.updateDataset();
        _this7.isLoading = false;
      });
    }
  }
};