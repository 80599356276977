import Http, { HttpLegacy } from '@/api/index';
import { useUsersStore } from '@/Store/usersStore';
import { handleRequestResponse, throwError } from '../httpUtils';

// REST API Risma1
export function uploadSettings(serializedForm) {
  return HttpLegacy({
    method: 'post',
    url: '/comm/profile/update-notification-settings',
    data: serializedForm
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return throwError(err);
  });
}
export function changePassword(id, data) {
  return Http.put("/admin/users/user/".concat(id, "/password"), data).then(function (response) {
    return response;
  }).catch(function (err) {
    return throwError(err);
  });
}
export function sendAppInvitation(id, data) {
  return handleRequestResponse(Http.post("/admin/users/user/".concat(id, "/app_invitation"), data)).then(function (data) {
    useUsersStore().update(data);
    return data;
  });
}