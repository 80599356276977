function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "mb-4 border-b-2 border-gray-250"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_delegation_modal = _resolveComponent("delegation-modal");
  var _component_delegation_list = _resolveComponent("delegation-list");
  return $options.isEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$data.showModal ? (_openBlock(), _createBlock(_component_delegation_modal, {
    key: 0,
    "options-list": _ctx.enabledSolutionIds,
    "preselected-items": _toConsumableArray($props.list),
    "modal-settings": $options.modalSettings,
    onUpdateDelegationItem: $options.onApplyChanges,
    onCloseDelegationModal: _cache[0] || (_cache[0] = function ($event) {
      return $data.showModal = false;
    }),
    onCreate: _cache[1] || (_cache[1] = function ($event) {
      return $data.showModal = true;
    })
  }, null, 8 /* PROPS */, ["options-list", "preselected-items", "modal-settings", "onUpdateDelegationItem"])) : _createCommentVNode("v-if", true), _createVNode(_component_delegation_list, {
    title: $data.translate.solutionId,
    "read-only": $props.readOnly,
    list: $options.convertedSelectedSolutionIds,
    "label-key": "title",
    onEdit: $options.editDelegationItem
  }, null, 8 /* PROPS */, ["title", "read-only", "list", "onEdit"])])) : _createCommentVNode("v-if", true);
}