import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.thisIsNew,
    class: "mb-2",
    truncate: false,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.nextStepIsTo,
    class: "mb-2",
    truncate: false,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.ifYouHaveMultipleAssets,
    class: "mb-2",
    truncate: false,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]);
}