import { Http, HttpLegacy } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getUnreadCount() {
  return handleRequestResponse(Http.get('comm/unread'));
}
export function getAllNotifications() {
  return handleRequestResponse(Http.get('comm/getAllMessages'));
}
export function setNotificationRead(messageID) {
  return handleRequestResponse(HttpLegacy.get("/comm/inbox/message-read/".concat(messageID)));
}
export function setAllNotificationAsRead() {
  return handleRequestResponse(Http.patch('/comm/allRead'));
}