import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InformationAssetCreationModal from '@/components/Molecules/InformationAssets/InformationAssetCreationModal';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export default {
  name: 'TasksList',
  description: 'this component uses to display list of information assets in compliance/overview/11',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    InformationAssetCreationModal: InformationAssetCreationModal,
    PreviewModalLink: PreviewModalLink
  },
  props: {
    iaList: {
      type: Array,
      required: true,
      note: 'Array of ia'
    },
    assetType: {
      type: String,
      required: false,
      default: AssetsTypes.SYSTEMS
    },
    title: {
      type: String,
      required: false,
      default: 'Create processors'
    },
    showCreateButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['created', 'refreshList'],
  data: function data() {
    return {
      listStyles: {
        height: 'calc(100% - 100px)',
        'overflow-y': 'auto'
      },
      showCreateModal: false,
      updatedActivity: null,
      AssetsTypes: AssetsTypes,
      translate: {
        totalCountOf: this.$trans('Total count of ')
      }
    };
  },
  methods: {
    toggleCreateModal: function toggleCreateModal() {
      this.showCreateModal = !this.showCreateModal;
    },
    emitNew: function emitNew(newItem) {
      this.toggleCreateModal();
      this.$emit('created', newItem);
    },
    handleUpdate: function handleUpdate(activity) {
      this.updatedActivity = activity;
    },
    dismissPreviewModal: function dismissPreviewModal(ia) {
      var _this = this;
      if (!this.updatedActivity) return;
      var isDirty = ['name', 'description'].some(function (fieldName) {
        return _this.updatedActivity[fieldName] !== ia[fieldName];
      });
      isDirty && this.$emit('refreshList');
    }
  }
};