export var getAnsweredQuestionsByActivity = function getAnsweredQuestionsByActivity(questions) {
  var activityIds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var activityField = arguments.length > 2 ? arguments[2] : undefined;
  return questions.filter(function (question) {
    if (!question.answerCount) {
      return false;
    }
    if (activityIds.length) {
      return question.answers.some(function (answer) {
        return activityIds.indexOf(answer[activityField]) > -1;
      });
    }
    return true;
  });
};