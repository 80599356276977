import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-bd2d291a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "form-recipient-template"
};
var _hoisted_2 = {
  class: "risma-information"
};
var _hoisted_3 = {
  class: "left-corner"
};
var _hoisted_4 = {
  class: "info"
};
var _hoisted_5 = {
  key: 0,
  class: "comment"
};
var _hoisted_6 = {
  class: "right-corner"
};
var _hoisted_7 = {
  class: "questionnaire"
};
var _hoisted_8 = {
  key: 1,
  class: "submitted"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_alert = _resolveComponent("alert");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_questionnaire = _resolveComponent("questionnaire");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    class: "main-title",
    type: "big",
    title: "RISMA Systems"
  }), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $options.rismaFirstTitle,
    truncate: false,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $options.rismaThirdTitle,
    truncate: false,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    truncate: false,
    title: $options.helpText,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])]), $props.comment ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_title, {
    truncate: false,
    title: $props.comment,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_6, [!$data.questionnaireSubmitted ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: _ctx.$trans("Submit"),
    type: "save",
    onClick: $options.onPreSubmit
  }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true)])]), $data.showAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    header: _ctx.$trans("Submit questionnaire"),
    body: _ctx.$trans($data.alertMessage),
    "button-ok-text": _ctx.$trans("Submit"),
    onDismiss: $options.toggleAlert,
    onAccept: $options.onSubmit
  }, null, 8 /* PROPS */, ["header", "body", "button-ok-text", "onDismiss", "onAccept"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_7, [!$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createBlock(_component_main_well, {
    key: 1
  }, {
    default: _withCtx(function () {
      return [$options.questions && $options.questions.length && $data.informationAssets !== null && !$data.questionnaireSubmitted ? (_openBlock(), _createBlock(_component_questionnaire, {
        key: 0,
        questionnaire: $props.questionnaire,
        questions: $options.questions,
        "local-storage-key": "dpm_audit_".concat($props.activity.id, "_questionnaire").concat($props.questionnaire.id),
        "information-assets": $data.informationAssets,
        "node-element": $data.nodeElement,
        title: $options.defaultTitle || '',
        onUpdate: $options.prepareAndUpdateQuestion
      }, null, 8 /* PROPS */, ["questionnaire", "questions", "local-storage-key", "information-assets", "node-element", "title", "onUpdate"])) : _createCommentVNode("v-if", true), $data.questionnaireSubmitted ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_risma_title, {
        title: _ctx.$trans("Questionnaire submitted"),
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)])]);
}