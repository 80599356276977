import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4a77554b"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  class: "multiselect__single"
};
var _hoisted_2 = {
  key: 0,
  class: "multiselect__tag"
};
var _hoisted_3 = ["title", "textContent"];
var _hoisted_4 = ["onKeydown", "onMousedown"];
var _hoisted_5 = {
  key: 1
};
var _hoisted_6 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_vue_multiselect = _resolveComponent("vue-multiselect");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'tag-clickable': $props.tagClickable
    }, "risma-select"])
  }, [$data.showSelectAllModal ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    body: $data.alertBody,
    header: $data.translate.warning + '! ' + $data.translate.youAreTryingToSelectAllItems,
    "button-ok-text": $data.translate.selectAll,
    onAccept: $options.acceptSelectAll,
    onDismiss: $options.dismissSelectAll
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), $data.values && $data.selected ? (_openBlock(), _createBlock(_component_vue_multiselect, {
    key: 1,
    ref: "select",
    "model-value": $data.selected,
    "track-by": $props.trackBy,
    options: $data.values,
    multiple: $props.multiple,
    searchable: $props.searchable,
    "group-select": $props.groupSelect,
    "group-values": $props.groupValues,
    "group-label": $props.groupLabel,
    "close-on-select": $props.closeOnSelect,
    "custom-label": $options.createLabel,
    "deselect-label": $options.deselectLabelWithDefault,
    "select-label": $options.selectLabelWithDefault,
    "selected-label": $options.selectedLabelWithDefault,
    placeholder: $data.selected.length ? '' : $props.placeholder,
    "allow-empty": $props.allowEmpty,
    "open-direction": $props.openDirection,
    disabled: $props.disabled,
    taggable: $props.taggable,
    "tag-placeholder": $props.tagPlaceholder,
    "show-labels": $props.showLabels,
    onMousedown: $options.handleMousedownNative,
    "onUpdate:modelValue": $options.handleSelected,
    onTag: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('tag', $event);
    }),
    onClose: $options.handleClose,
    onOpen: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('open', $event);
    })
  }, _createSlots({
    selection: _withCtx(function (_ref) {
      var valuesList = _ref.values;
      return [valuesList.length > $props.maxVisibleTags && $props.maxVisibleTags !== -1 ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(valuesList.filter(function (item) {
        return item[$props.trackBy] !== '0';
      }).length) + " " + _toDisplayString($data.translate.selected.toLowerCase()), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
    }),
    tag: _withCtx(function (_ref2) {
      var option = _ref2.option;
      return [option[$props.labelKey] !== $data.translate.selectAll ? (_openBlock(), _createElementBlock("span", _hoisted_2, [_renderSlot(_ctx.$slots, "tagCustom", {
        option: option
      }, function () {
        return [_createElementVNode("span", {
          title: option[$props.labelKey],
          textContent: _toDisplayString(option[$props.labelKey])
        }, null, 8 /* PROPS */, _hoisted_3)];
      }, true), !$props.optionsWithoutTagIcon.includes(option[$props.trackBy]) ? (_openBlock(), _createElementBlock("i", {
        key: 0,
        "aria-hidden": "true",
        tabindex: "1",
        class: "multiselect__tag-icon",
        onKeydown: _withKeys(_withModifiers(function ($event) {
          return _ctx.$refs.select.removeElement(option);
        }, ["prevent"]), ["enter"]),
        onMousedown: _withModifiers(function ($event) {
          return _ctx.$refs.select.removeElement(option);
        }, ["prevent"])
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_4)) : _createCommentVNode("v-if", true)])) : (_openBlock(), _createElementBlock("span", _hoisted_5))];
    }),
    noResult: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.noElementsFoundConsiderChangingTheSearchQu), 1 /* TEXT */)];
    }),

    noOptions: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.listIsEmpty), 1 /* TEXT */)];
    }),

    caret: _withCtx(function () {
      return [$props.disabled ? (_openBlock(), _createElementBlock("span", _hoisted_6)) : _createCommentVNode("v-if", true)];
    }),
    _: 2 /* DYNAMIC */
  }, [$options.isSlotOptionPassed ? {
    name: "option",
    fn: _withCtx(function (_ref3) {
      var option = _ref3.option;
      return [_renderSlot(_ctx.$slots, "optionCustom", {
        option: option
      }, undefined, true)];
    })
  } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["model-value", "track-by", "options", "multiple", "searchable", "group-select", "group-values", "group-label", "close-on-select", "custom-label", "deselect-label", "select-label", "selected-label", "placeholder", "allow-empty", "open-direction", "disabled", "taggable", "tag-placeholder", "show-labels", "onMousedown", "onUpdate:modelValue", "onClose"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}