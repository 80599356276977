import { getAllQuestions, getQuestionnairesByQuestionnaireTypeId, getQuestionnaireTypes } from '@/api/compliance/questionnaire';
import { getAllGapSchemasByGapSchemaType } from '@/api/compliance/gapschema';
import { getTranslatables } from '@/constants/datatable/Translatables';
export default {
  name: 'ProjectQuestionGapSelectorMixin',
  data: function data() {
    return {
      questionnaireId: null,
      questionnaires: null,
      gapSchemas: null,
      gapSchemaId: null,
      selectedQuestionnaireTypeId: null,
      showText: true,
      questionId: null,
      articleId: null,
      questions: [],
      questionnaireTypes: []
    };
  },
  computed: {
    allQuestionnaires: function allQuestionnaires() {
      if (!this.questionnaires) {
        return [];
      }
      return this.questionnaires.map(function (questionnaire) {
        return {
          label: questionnaire.title,
          value: questionnaire.id
        };
      });
    },
    allGapSchemas: function allGapSchemas() {
      if (!this.gapSchemas) {
        return [];
      }
      return this.gapSchemas.map(function (gapSchema) {
        return {
          label: gapSchema.title,
          value: gapSchema.id
        };
      });
    },
    allGapArticles: function allGapArticles() {
      if (!this.gapSchema) return [];
      return this.gapSchema.articles.map(function (article) {
        return {
          label: "".concat(article.article, " - ").concat(article.articleTitle),
          value: article.id
        };
      });
    },
    gapSchema: function gapSchema() {
      var _this = this;
      if (this.gapSchemas === null || !this.gapSchemas.length || !this.gapSchemaId) return;
      return this.gapSchemas.find(function (schema) {
        return schema.id === _this.gapSchemaId;
      });
    },
    allQuestionnaireTypes: function allQuestionnaireTypes() {
      var _this2 = this;
      if (!this.questionnaireTypes) {
        return [];
      }
      return this.questionnaireTypes.map(function (questionnaireType) {
        return {
          label: _this2.translateQuestionnaireType(questionnaireType.type),
          value: questionnaireType.id
        };
      });
    },
    showRulesTextMode: function showRulesTextMode() {
      return this.showText;
    },
    questionnaireType: function questionnaireType() {
      var _this3 = this;
      if (!this.questionnaireTypes) {
        return null;
      }
      return this.questionnaireTypes.find(function (questionnaireType) {
        return questionnaireType.id === _this3.selectedQuestionnaireTypeId;
      });
    },
    articleNumber: function articleNumber() {
      var _this4 = this;
      if (!this.gapSchema) return null;
      var article = this.gapSchema.articles.find(function (article) {
        return article.id === _this4.articleId;
      });
      if (article) return article.article;
      return null;
    },
    questionNumber: function questionNumber() {
      var _this5 = this;
      if (!this.questions.length) return;
      var question = this.questions.find(function (question) {
        return question.id === _this5.questionId;
      });
      if (!question) return;
      return question.questionnumber;
    }
  },
  watch: {
    questionnaireType: function questionnaireType() {
      var _this6 = this;
      this.gapSchemaId = null;
      this.questionnaireId = null;
      if (this.questionnaireType) {
        getQuestionnairesByQuestionnaireTypeId(this.questionnaireType.id).then(function (_ref) {
          var list = _ref.list;
          return _this6.questionnaires = list;
        });
        getAllGapSchemasByGapSchemaType(this.questionnaireType.id, true).then(function (response) {
          _this6.gapSchemas = response.list;
        });
      } else {
        this.questionnaires = null;
        this.gapSchemas = null;
      }
    },
    questionnaireId: function questionnaireId() {
      this.getQuestions();
    }
  },
  created: function created() {
    var _this7 = this;
    getQuestionnaireTypes(true, true).then(function (_ref2) {
      var list = _ref2.list;
      _this7.questionnaireTypes = list;
    });
  },
  methods: {
    getQuestions: function getQuestions() {
      var _this8 = this;
      this.questionId = null;
      this.answer = null;
      if (!this.questionnaireId) {
        this.questions = [];
        return;
      }
      this.questionsLoaded = false;
      return getAllQuestions(this.questionnaireId).then(function (response) {
        _this8.questions = response.questions;
        _this8.questionsLoaded = true;
      });
    },
    translateQuestionnaireType: function translateQuestionnaireType(type) {
      return this.$trans(getTranslatables('questionnaireTypes')[type] || type);
    }
  }
};