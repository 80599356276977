function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import ToggleMenu from '@/components/Atoms/ToggleMenu/ToggleMenu';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import ViewSwitcher from '@/components/Molecules/ViewSwitcher';
import Alert from '@/components/Molecules/Modal/Alert';
import HelpToolTip from '@/components/Molecules/HelpToolTip';
import * as awarenessAPI from '@/api/compliance/awareness';
import RismaPieChart from '@/components/Molecules/Charts/RismaPieChart';
import { Colors } from '@/Colors';
import { AWARENESS_DASHBOARDS_VIEWS } from '@/constants/ViewTypes';
import { viewTypes } from '@/constants/ListViewType';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { toLocalDateTimeFull as _toLocalDateTimeFull } from '@/utils/date';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'AwarenessDashboard',
  token: '<awareness-dashboard />',
  components: {
    RismaPieChart: RismaPieChart,
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    ToggleMenu: ToggleMenu,
    RismaDatatable: RismaDatatable,
    ViewSwitcher: ViewSwitcher,
    Alert: Alert,
    HelpToolTip: HelpToolTip,
    FeatherIcon: FeatherIcon,
    NotificationPopUp: NotificationPopUp
  },
  mixins: [RismaDatatableDefaultMixin, ProcessLibraryTitleMixin, NotificationPopUpMixin],
  setup: function setup() {
    return {
      viewSelected: getLocalStorageComputed('awareness_dashboards_view', viewTypes.COMPACT)
    };
  },
  data: function data() {
    return {
      isLoaded: true,
      requests: {},
      awareness: {},
      colors: [Colors.notSet, Colors.answered, Colors.noGap],
      deleteModalVisible: false,
      reminderModalVisible: false,
      requestId: null,
      dataset: [],
      AWARENESS_DASHBOARDS_VIEWS: AWARENESS_DASHBOARDS_VIEWS,
      viewTypes: viewTypes,
      featherIconFields: ['send_reminder', 'delete'],
      translate: getTranslate['AwarenessDashboard']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useUserStore, ['isAdmin'])), {}, {
    columns: function columns() {
      var list = {
        title: this.translate.title,
        process_library_activity: "".concat(this.processLibraryTitle, " ").concat(this.translate.activity),
        sent_by: this.translate.sentBy,
        datetime: this.translate.sent,
        request_count_totally: this.translate.requestCountTotal,
        requests_read: this.translate.requestsRead,
        requests_approved: this.translate.requestsApproved,
        send_reminder: this.translate.sendReminder
      };
      if (this.isAdmin) {
        list.delete = this.translate.delete;
      }
      return list;
    },
    excelColumnsToFilter: function excelColumnsToFilter() {
      return ['send_reminder', 'delete'];
    },
    filterColumnsFn: function filterColumnsFn() {
      var _this = this;
      return {
        columns: function columns(items) {
          return items.filter(function (item) {
            return !_this.excelColumnsToFilter.includes(item.key);
          });
        }
      };
    }
  }),
  mounted: function mounted() {
    this.loadAll();
  },
  methods: {
    isNormalField: function isNormalField(field) {
      return _typeof(field) !== 'object';
    },
    loadAll: function loadAll() {
      var _this2 = this;
      this.isLoaded = false;
      var params = '?data[]=all';
      return awarenessAPI.getAll(params).then(function (data) {
        _this2.requests = _this2.formatRequests(data.list);
        _this2.dataset = _this2.prepareDataset();
        _this2.enhanceDataset(_this2.dataset, _this2.columns);
        _this2.isLoaded = true;
      }).catch(function (e) {
        throw e;
      });
    },
    prepareDataset: function prepareDataset() {
      var _this3 = this;
      return this.requests.map(function (request) {
        var list = {
          title: {
            field: request.title
          },
          process_library_activity: {
            field: _this3.prepareProcessLibraryActivity(request),
            fieldText: request.title
          },
          sent_by: {
            field: _this3.getUserNameById(request.sentBy)
          },
          datetime: {
            field: request.sentDateInLocalTime
          },
          request_count_totally: {
            field: request.users.length
          },
          requests_read: {
            field: request.filteredUsers.requestsRead.length
          },
          requests_approved: {
            field: request.filteredUsers.requestsSigned.length
          },
          send_reminder: {
            field: _this3.prepareSendReminderButton(request.id)
          }
        };
        if (_this3.isAdmin) {
          list.delete = {
            field: _this3.prepareDeleteButton(request.id)
          };
        }
        return list;
      });
    },
    getUserNameById: function getUserNameById(id) {
      var user = this.users.find(function (user) {
        return user.id == id;
      });
      return user ? user.display_name : '';
    },
    prepareProcessLibraryActivity: function prepareProcessLibraryActivity(request) {
      if (!request.links || !request.links.compliance_process_tree_node || !request.links.compliance_process_tree_node[0]) return '';
      if (request.links.compliance_process_tree_node[0].url) {
        return {
          url: request.links.compliance_process_tree_node[0].url,
          title: request.links.compliance_process_tree_node[0].title
        };
      }
      return request.links.compliance_process_tree_node[0].title || '';
    },
    prepareSendReminderButton: function prepareSendReminderButton(id) {
      return {
        icon: 'mail',
        isReminder: true,
        data: id
      };
    },
    prepareDeleteButton: function prepareDeleteButton(id) {
      return {
        icon: 'trash-2',
        additionalCss: 'text-red-50',
        data: id
      };
    },
    formatRequests: function formatRequests(requests) {
      var _this4 = this;
      return requests.map(function (r) {
        var requestsRead = r.users.filter(function (user) {
          return user.firstClick != null && user.signoff == null;
        });
        var requestsSigned = r.users.filter(function (user) {
          return user.signoff != null;
        });
        var requestsSent = r.users.filter(function (user) {
          return user.firstClick == null && user.signoff == null;
        });
        var requestsReadLength = requestsRead.length;
        var requestsSignedLength = requestsSigned.length;
        return _objectSpread({
          requestsRead: requestsReadLength,
          requestsSigned: requestsSignedLength,
          userFilter: null,
          filteredUsers: {
            requestsRead: requestsRead,
            requestsSigned: requestsSigned,
            requestsSent: requestsSent
          },
          labels: [_this4.translate.requestsSent, _this4.translate.requestsRead, _this4.translate.requestsApproved],
          data: [r.users.length - requestsReadLength - requestsSignedLength, requestsReadLength, requestsSignedLength],
          sentDateInLocalTime: _this4.toLocalDateTimeFull(r.sent)
        }, r);
      });
    },
    dataMenuOptions: function dataMenuOptions(requestId) {
      var _this5 = this;
      var result = [{
        id: 1,
        title: this.translate.sendReminder,
        icon: 'mail',
        action: function action() {
          _this5.showReminderConfirm(requestId);
        }
      }];
      if (this.isAdmin) {
        result.push({
          id: 2,
          style: {
            color: Colors.system.warning
          },
          title: this.translate.deleteDashboard,
          icon: 'trash-2',
          action: function action() {
            _this5.showDeleteConfirm(requestId);
          }
        });
      }
      return result;
    },
    showReminderConfirm: function showReminderConfirm(requestId) {
      this.requestId = requestId;
      this.reminderModalVisible = true;
    },
    sendReminder: function sendReminder() {
      var _this6 = this;
      var data = {
        reminderMessage: null // 'Reminder...',
      };

      this.reminderModalVisible = false;
      return awarenessAPI.sendReminder(this.requestId, data).then(function () {
        return _this6.handleInstantNotification({});
      });
    },
    hideReminderAlert: function hideReminderAlert() {
      this.reminderModalVisible = false;
    },
    showDeleteConfirm: function showDeleteConfirm(requestId) {
      this.requestId = requestId;
      this.deleteModalVisible = true;
    },
    deleteDashboard: function deleteDashboard() {
      var _this7 = this;
      awarenessAPI.deleteRequest(this.requestId).then(function () {
        _this7.loadAll();
      });
      this.deleteModalVisible = false;
    },
    hideDeleteAlert: function hideDeleteAlert() {
      this.deleteModalVisible = false;
    },
    clickSlice: function clickSlice(sliceIndex, index) {
      var pieChart = this.requests.find(function (request) {
        return request.id === index;
      });
      switch (sliceIndex) {
        case 0:
          pieChart.userFilter = 'requestsSent';
          break;
        case 1:
          pieChart.userFilter = 'requestsRead';
          break;
        case 2:
          pieChart.userFilter = 'requestsSigned';
          break;
        default:
          pieChart.userFilter = null;
      }
    },
    filteredUsers: function filteredUsers(request) {
      if (!request.userFilter) return;
      var filter = request.userFilter;
      var result = request.filteredUsers[filter];
      return result;
    },
    getUserRequestStatus: function getUserRequestStatus(userRequest) {
      if (userRequest.lastError) {
        return this.translate.failed;
      }
      if (userRequest.sent == '1970-01-01 00:00:01') {
        return this.translate.unknown;
      }
      if (userRequest.sent) {
        return this.translate.sent;
      }
      return this.translate.pending;
    },
    toLocalDateTimeFull: function toLocalDateTimeFull(time) {
      return _toLocalDateTimeFull(time);
    }
  }
};