import i18n from '@/i18n';
export var getTranslate = {
  MultipleQuestionnaireSelector: function MultipleQuestionnaireSelector() {
    return {
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      addQuestionnaire: i18n.t('Add questionnaire'),
      add: i18n.t('Add'),
      close: i18n.t('Close'),
      choose: i18n.t('Choose'),
      questionnaireVersion: i18n.t('Questionnaire version'),
      areYouSureYouWantToRemoveThisQuestionnaire: i18n.t('Are you sure you want to remove this questionnaire?')
    };
  }
};