export function toPrint(id) {
  var html = document.getElementById(id).cloneNode(true);
  html.querySelectorAll('img.dataFlowImage').forEach(function (img) {
    var newImage = document.createElement('img');
    newImage.src = img.src;
    newImage.classList.add('dataFlowImage');
    img.parentNode.replaceChild(newImage, img);
  });
  var newWindow = window.open();
  newWindow.document.write(template(html.innerHTML));
  newWindow.focus();
  newWindow.print();
  newWindow.close();
}
export function printTable(dataset, columns) {
  var headTitle = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var cssProps = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var html = constructHtml(dataset, columns, headTitle);
  var mediaPrintCSS = cssProps.mediaPrintCSS || defaultMediaPrintCSS();
  var printCSS = cssProps.printCSS || defaultPrintCSS();
  var styles = getStyles(mediaPrintCSS, printCSS);
  printHtml(html, styles);
}
function printHtml(html, styles) {
  var iframe = document.createElement('iframe');
  iframe.id = 'printingFrame';
  iframe.name = 'printingFrame';
  iframe.width = '0';
  iframe.sandbox = 'allow-same-origin allow-modals';
  iframe.height = '0';
  iframe.style = 'position: absolute;top: 0;left: 0;width: 0;height: 0;';
  document.body.appendChild(iframe);
  iframe.contentWindow.document.open();
  iframe.contentWindow.document.write('<!DOCTYPE html');
  iframe.contentWindow.document.write('<html><head>');
  iframe.contentWindow.document.write(styles);
  iframe.contentWindow.document.write('</head><body>');
  iframe.contentWindow.document.write(html);
  iframe.contentWindow.document.write('</body></html>');
  iframe.contentWindow.document.close();
  window.frames['printingFrame'].focus();
  window.frames['printingFrame'].print();
  document.body.removeChild(iframe);
}
function constructHtml(dataset, columns) {
  var headTitle = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var result = '';
  if (headTitle) {
    result += "<h1>".concat(headTitle, "</h1>");
  }
  result += '<table>';
  result += '<thead>' + addRow(columns, 'th') + '</thead>';
  result += '<tbody>';
  dataset.map(function (item) {
    result += addRow(item, 'td');
  });
  result += '</tbody>';
  result += '</table>';
  return result;
}
function addRow(data, tag) {
  var str = '<tr>';
  for (var key in data) {
    var dataOut = data[key] === null || data[key] === undefined ? '' : data[key];
    str += "<".concat(tag, ">").concat(dataOut, "</").concat(tag, ">");
  }
  return str + '</tr>';
}
function getStyles(mediaPrintCSS, printCSS) {
  return "<style type=\"text/css\"> ".concat(mediaPrintCSS, " ").concat(printCSS, " '</style>");
}
function css() {
  return "\n      * {\n        -webkit-print-color-adjust: exact;\n      }\n      table {\n        font-family: 'titillium web', 'source sans pro', sans-serif;\n      }\n      tr.odd td {\n        background: #ececec;\n      }\n      tr.even td {\n        background: #ffffff;\n      }\n      th, td {\n        vertical-align: top;\n      }\n      th {\n        color: #000;\n        font-size: 14px;\n        padding: 5px 7px;\n        font-weight: 600;\n        min-width: 70px;\n      }\n      td {\n        font-size: 8pt;\n      }\n\n      td img.dataFlowImage {\n        width: 400px;\n        height: auto;\n      }\n\n      tr td:first-child a {\n        font-size:14px;\n        color: black;\n      }\n      th:first-child, td:first-child {\n        padding-left: 15px;\n      }\n\n      th:last-child, td:last-child {\n        padding-right: 15px;\n      }\n\n      .risma-button {\n        display: none;\n      }\n      ";
}
function printCss() {
  return "@media print {\n  @page {size: A4 landscape;}\n  table { page-break-after:auto }\n  tr    { page-break-inside:avoid; page-break-after:auto }\n  td    { page-break-inside:avoid; page-break-after:auto;border-left: 1px solid rgba(0,0,0,0.1);}\n  th div p { display: none}\n  tbody tr:first-child td { border-top: 1px solid rgba(0,0,0,0.1);}\n  td div p {\n  page-break-inside: avoid;\n  }\n  /*thead { display:table-header-group;}*/\n  tfoot { display:table-footer-group }\n\n  ".concat(css(), "\n}");
}
function defaultPrintCSS() {
  return "\n        body {\n            font-family: 'Titillium Web', sans-serif;\n        }\n        h1 {\n            font-family: 'Titillium Web', sans-serif;\n            color: '#347480';\n            font-weight: 300;\n            font-size: 2.5rem;\n            margin: 0;\n        }\n        table {\n            width: 100%;\n            border-collapse: separate;\n            border-spacing: 0;\n        }\n        td,\n        th {\n            padding: 8px;\n            font-size: 14px;\n            box-sizing: content-box;\n            text-align: left;\n        }\n        th {\n            border-bottom: 1px solid #111;\n        }";
}
function defaultMediaPrintCSS() {
  return "\n        @media print {\n            table { page-break-after:auto }\n            tr    { page-break-inside:avoid; page-break-after:auto }\n            td    { page-break-inside:avoid; page-break-after:auto; }\n        } ";
}
function template(html) {
  return "<html><head><title>RISMAbusiness</title><link href=\"/assets/css/bootstrap4.min.css\" rel=\"stylesheet\"><link href=\"https://fonts.googleapis.com/css?family=Titillium+Web:200,300,600,400,700,900\" rel=\"stylesheet\" type=\"text/css\"><style>".concat(printCss()).concat(css(), "</style></head><body>").concat(html, "</body></html>").replace(/\s{2,}/g, ' ');
}