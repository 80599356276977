function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import GapAttributesList from '@/components/Molecules/Admin/GapAttributesList';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import { getAttributes, setAttributes } from '@/api/admin/gapschema';
import { generateKey } from '@/utils/Utils';
import { getTranslate } from './translate';
export default {
  name: 'GapSchemasAdminAttributes',
  components: {
    RismaButton: RismaButton,
    FeatherIcon: FeatherIcon,
    GapAttributesList: GapAttributesList,
    Notification: Notification
  },
  props: {
    gapSchemaId: {
      type: String,
      required: true
    }
  },
  emits: ['notify'],
  data: function data() {
    return {
      initialAttributes: [],
      attributesArr: [],
      errors: {},
      notificationMessage: '',
      translate: getTranslate['GapSchemasAdminAttributes']()
    };
  },
  mounted: function mounted() {
    var _this = this;
    getAttributes(this.gapSchemaId).then(function (_ref) {
      var attributes = _ref.attributes;
      _this.attributesArr = attributes.map(function (attribute) {
        attribute.key = generateKey();
        return attribute;
      });
      _this.initialAttributes = cloneDeep(_this.attributesArr);
    });
  },
  methods: {
    onAttributeCreate: function onAttributeCreate() {
      this.attributesArr.push({
        category: '',
        labels: [''],
        key: generateKey()
      });
    },
    onUpdateCategory: function onUpdateCategory(newCategory, idx) {
      if (!this.attributesArr[idx]) return;
      this.attributesArr[idx] = _objectSpread(_objectSpread({}, this.attributesArr[idx]), {}, {
        category: newCategory
      });
    },
    onUpdateLabels: function onUpdateLabels(newLabels, idx) {
      if (!this.attributesArr[idx]) return;
      if (!newLabels || !newLabels.length) {
        this.attributesArr.splice(idx, 1);
        return;
      }
      this.attributesArr[idx] = _objectSpread(_objectSpread({}, this.attributesArr[idx]), {}, {
        labels: newLabels
      });
    },
    handleValidation: function handleValidation() {
      this.errors = _objectSpread({}, this.validateAttributes());
      return !Object.keys(this.errors).length;
    },
    validateAttributes: function validateAttributes() {
      var _this2 = this;
      var errors = {};
      var categoryMap = {};
      var categoryErrors = {};
      this.attributesArr.forEach(function (_ref2, categoryIdx) {
        var category = _ref2.category,
          labels = _ref2.labels;
        category = category ? category.toLowerCase() : '';
        var attributesMap = {};
        var attributeErrors = {};
        labels.forEach(function (attr, idx) {
          attr = attr.label ? attr.label.toLowerCase() : '';
          if (!attr) {
            attributeErrors[idx] = _this2.translate.pleaseFillOutThisField;
            return;
          }
          if (attributesMap[attr] !== undefined) {
            var matchIdx = attributesMap[attr];
            attributeErrors[idx] = _this2.translate.valueShouldBeUnique;
            attributeErrors[matchIdx] = _this2.translate.valueShouldBeUnique;
          } else {
            attributesMap[attr] = idx;
          }
        });
        if (Object.values(attributeErrors).length) {
          errors.labels = errors.labels || {};
          errors.labels[categoryIdx] = attributeErrors;
        }
        if (!category) {
          categoryErrors[categoryIdx] = _this2.translate.pleaseFillOutThisField;
          return;
        }
        if (categoryMap[category] !== undefined) {
          var matchIdx = categoryMap[category];
          categoryErrors[categoryIdx] = _this2.translate.valueShouldBeUnique;
          categoryErrors[matchIdx] = _this2.translate.valueShouldBeUnique;
        } else {
          categoryMap[category] = categoryIdx;
        }
      });
      if (Object.values(categoryErrors).length) {
        errors.category = categoryErrors;
      }
      return errors;
    },
    onSave: function onSave() {
      var _this3 = this;
      this.notificationMessage = '';
      if (isEqual(this.initialAttributes, this.attributesArr)) {
        this.notificationMessage = this.translate.noChangesToSave;
        return;
      }
      if (this.handleValidation()) {
        setAttributes(this.gapSchemaId, this.attributesArr).then(function () {
          _this3.$emit('notify', {
            message: _this3.translate.attributesAreSaved
          });
          _this3.initialAttributes = cloneDeep(_this3.attributesArr);
        }).catch(function () {
          _this3.$emit('notify', {
            message: _this3.translate.unexpectedError,
            type: 'error'
          });
        });
      }
    }
  }
};