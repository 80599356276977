export default {
  name: 'RismaSwitch',
  introduction: 'The switch component',
  description: 'Switcher with 2 way binding. Supports v-model',
  token: "<risma-switch options=\"Array(2)\" v-model=\"String|Boolean\"/>\n    // options look like\n    const options = [{\n        name: 'Automatic',\n        value: 'auto',\n    },\n    {\n        name: 'Manual',\n        value: 'manual',\n    }];\n    ",
  props: {
    modelValue: {
      type: [String, Boolean],
      required: true
    },
    options: {
      type: Array,
      required: true,
      note: "Options must be an Array with 2 items only.\n            Each item is Object with properties name and value",
      validator: function validator(val) {
        return !!val && val.length === 2;
      }
    },
    isLabelsShow: {
      type: Boolean,
      default: false,
      note: 'Is show labels with passed `name` property.'
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Set to true to disable switch'
    }
  },
  emits: ['click', 'update:modelValue'],
  computed: {
    state: function state() {
      var _this$options$;
      return ((_this$options$ = this.options[1]) === null || _this$options$ === void 0 ? void 0 : _this$options$.value) === this.modelValue;
    }
  },
  methods: {
    switchState: function switchState(e) {
      var _this$options$2, _this$options$3;
      if (this.disabled) {
        return;
      }
      this.$emit('click', e);
      this.$emit('update:modelValue', this.state ? (_this$options$2 = this.options[0]) === null || _this$options$2 === void 0 ? void 0 : _this$options$2.value : (_this$options$3 = this.options[1]) === null || _this$options$3 === void 0 ? void 0 : _this$options$3.value);
    }
  }
};