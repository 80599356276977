import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
export default {
  name: '',
  components: {
    RismaFileUpload: RismaFileUpload
  },
  methods: {
    showResult: function showResult(name, result) {
      window.alert(name, JSON.stringify(result));
    },
    fileUploaded: function fileUploaded(files) {
      this.showResult('RismaFileUpload.@upload', files);
    }
  }
};