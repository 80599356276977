import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "risma-column-filters relative"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 1,
  class: "p-3"
};
var _hoisted_4 = {
  class: "overflow-auto max-h-300px"
};
var _hoisted_5 = {
  class: "px-3 py-1 hover:bg-blue-250 hover:text-white cursor-pointer flex justify-between items-center"
};
var _hoisted_6 = {
  class: "mr-3",
  style: {
    wordBreak: 'break-all'
  }
};
var _hoisted_7 = ["checked", "onChange"];
var _hoisted_8 = {
  key: 3,
  class: "mt-2 px-3 py-2"
};
var _hoisted_9 = {
  key: 0,
  class: "flex justify-center items-center"
};
var _hoisted_10 = {
  key: 1,
  class: "flex items-center justify-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$options.hideSearchField ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_input_field, {
    modelValue: $data.searchString,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.searchString = $event;
    }),
    type: "text",
    placeholder: "".concat($data.translate.filterBy, "..."),
    class: "m-3 search-field",
    onClick: _cache[1] || (_cache[1] = _withModifiers(function () {}, ["stop"]))
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"])])) : _createCommentVNode("v-if", true), $props.datesFilter ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_date_range_picker, {
    "start-date": $data.dateRange.after,
    "end-date": $data.dateRange.before,
    ranges: $data.ranges,
    opens: $props.columnIndex < 3 ? 'right' : 'left',
    "append-to-body": false,
    onChanged: $options.onDatePickerChange
  }, null, 8 /* PROPS */, ["start-date", "end-date", "ranges", "opens", "onChanged"])])) : _createCommentVNode("v-if", true), $options.checkedValues.length > 0 ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: "pr-3 underline text-right",
    onClick: _cache[2] || (_cache[2] = _withModifiers(function () {
      return $options.deselectAllFilters && $options.deselectAllFilters.apply($options, arguments);
    }, ["stop"]))
  }, _toDisplayString($data.translate.deselectAll), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.sortedFilters, function (item) {
    return _openBlock(), _createElementBlock("div", {
      key: item.value
    }, [_createElementVNode("div", null, [_createElementVNode("label", _hoisted_5, [_createElementVNode("span", _hoisted_6, _toDisplayString(item.label), 1 /* TEXT */), _createElementVNode("input", {
      type: "checkbox",
      class: "cursor-pointer",
      checked: $options.checkedValues.includes(item.value),
      onChange: function onChange($event) {
        return $options.onFiltersChange(item.value, $event.target.checked);
      }
    }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_7)])])]);
  }), 128 /* KEYED_FRAGMENT */))]), $options.showLoadMoreButton || $data.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_8, [$data.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_loading_indicator_small)])) : (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", {
    class: "text-right text-gray-650 italic mr-2",
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return $options.onLoadMoreClick(true);
    })
  }, [_createElementVNode("span", null, _toDisplayString($data.translate.loadAll), 1 /* TEXT */)]), _createElementVNode("div", {
    class: "text-right text-gray-650 italic ml-2",
    onClick: _cache[4] || (_cache[4] = function ($event) {
      return $options.onLoadMoreClick(false);
    })
  }, [_createElementVNode("span", null, _toDisplayString($data.translate.loadMore) + "...", 1 /* TEXT */)])]))])) : _createCommentVNode("v-if", true)]);
}