import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import { isEmailValid } from '@/utils/validations';
var signOffModalModes = {
  DRAFT: 'draft',
  CREATE: 'create',
  REMIND: 'remind'
};
export default {
  name: 'SignOffForm',
  description: 'Sign-off-form is a part of sign-off-modal and uses for preview awareness request',
  token: '<sign-off-form />',
  components: {
    FeatherIcon: FeatherIcon,
    TextBox: TextBox,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    InputField: InputField,
    Notification: Notification
  },
  props: {
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of awareness.users'
    },
    usersRow: {
      type: String,
      default: ''
    },
    isAwarenessSent: {
      type: Boolean,
      required: true,
      default: false
    },
    readonly: {
      type: Boolean,
      required: true,
      default: false
    },
    dashboardTitle: {
      type: String,
      default: ''
    },
    comments: {
      type: String,
      default: ''
    },
    modalType: {
      type: String,
      default: signOffModalModes.CREATE
    },
    activityTitle: {
      type: String,
      required: true
    },
    validationUsers: {
      type: String,
      required: false,
      default: ''
    },
    validationDashboard: {
      type: String,
      required: false,
      default: ''
    },
    validationEmail: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['remove:user', 'update:dashboardTitle', 'update:comments', 'update:usersRow', 'add:newEmail'],
  data: function data() {
    return {
      newEmail: '',
      newEmailError: '',
      signOffModalModes: signOffModalModes,
      translate: getTranslate['SignOffForm']()
    };
  },
  computed: {
    descriptionText: function descriptionText() {
      var result = this.translate.whenYouSendThisRequestToTheSelectedUsers;
      if (this.isAwarenessSent) {
        result = this.translate.thisMessageWillOnlyBeSendToTheUsersWhoHa;
        result += ' ';
        result += this.translate.noteThatYouCanAddMoreRecipients;
      }
      return result;
    },
    documentationDescription: function documentationDescription() {
      var mainTitle = this.translate.theUsersWillBePresentedWithTheContentOf;
      return "".concat(mainTitle, ": ").concat(this.activityTitle);
    }
  },
  methods: {
    addNewEmail: function addNewEmail() {
      if (!this.newEmail) return;
      this.newEmailError = '';
      if (isEmailValid(this.newEmail)) {
        this.$emit('add:newEmail', this.newEmail);
        this.newEmail = '';
      } else {
        this.newEmailError = this.translate.invalidEmailAddress;
      }
    }
  }
};