function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { cacheItem } from '@/routes/storeCache';
import { getRiskProjects as _getRiskProjects } from '@/api/risk/risk';
import { getAll as getAllComplianceProjects, updateProject } from '@/api/compliance/projects';
import { patchUtilSettings } from '@/api/risma/settings';
import { editActivityType } from '@/api/risma/activityTypes';
import { getCustomField, createCustomField, editCustomField } from '@/api/admin/admin';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import InputField from '@/components/Atoms/Inputs/InputField';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { MODULES } from '@/constants/modules';
import { RiskProjects } from '@/constants/risks/RiskProjects';
export default {
  name: 'ComplianceRiskAssessment',
  description: 'Page with compliance assets settings',
  token: '<compliance-risk-assessment />',
  components: {
    RismaTitle: RismaTitle,
    RismaToggle: RismaToggle,
    SingleSelect: SingleSelect,
    RismaButton: RismaButton,
    Notification: Notification,
    InputField: InputField,
    FeatherIcon: FeatherIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  data: function data() {
    return {
      data: {
        'feature.risk_assessment_on_systems': 0,
        'feature.systems_r_projects_id': null,
        'feature.risk_assessment_on_controllers': 0,
        'feature.controllers_r_projects_id': null,
        'feature.risk_assessment_on_processors': 0,
        'feature.processors_r_projects_id': null,
        'feature.isms_rto_field_id': null,
        'feature.isms_rpo_field_id': null
      },
      complianceProjectsDataToSave: {},
      RTO: {
        NAME: 'feature.isms_rto_field_id',
        VALUES_NAME: 'rto',
        LABEL: 'Recovery Time Objective'
      },
      RPO: {
        NAME: 'feature.isms_rpo_field_id',
        VALUES_NAME: 'rpo',
        LABEL: 'Recovery Point Objective'
      },
      rtoRpoGroup: {
        rto: {
          title: 'RTO (Recovery Time Objective)',
          values: [{
            value: ''
          }]
        },
        rpo: {
          title: 'RPO (Recovery Point Objective)',
          values: [{
            value: ''
          }]
        }
      },
      complianceProjects: [],
      riskProjects: [],
      isNotificationShow: false,
      notificationType: 'error',
      notificationMessage: '',
      isLoading: false,
      translate: getTranslate['ComplianceRiskAssessment']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useSettingsStore, ['isInformationAssetsDisabled'])), mapState(useActivityTypesStore, {
    customInformationAssetsModules: 'complianceIACustomActivityTypes'
  })), {}, {
    riskProjectsOptions: function riskProjectsOptions() {
      var values = this.riskProjects.map(function (project) {
        return {
          value: project.id,
          label: project.title,
          type: project.type
        };
      });
      return [{
        value: null,
        label: this.translate.noRiskProject
      }].concat(_toConsumableArray(values));
    },
    riskProjectsOptionsWithoutErm: function riskProjectsOptionsWithoutErm() {
      return this.riskProjectsOptions.filter(function (project) {
        return project.type !== RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type;
      });
    },
    settingsData: function settingsData() {
      return [{
        name: 'System',
        switchSettingName: 'feature.risk_assessment_on_systems',
        riskIdSettingName: 'feature.systems_r_projects_id'
      }, {
        name: 'Controllers',
        switchSettingName: 'feature.risk_assessment_on_controllers',
        riskIdSettingName: 'feature.controllers_r_projects_id'
      }, {
        name: 'Processors',
        switchSettingName: 'feature.risk_assessment_on_processors',
        riskIdSettingName: 'feature.processors_r_projects_id'
      }];
    },
    isRtoRpoValid: function isRtoRpoValid() {
      return this.rtoRpoGroup.rto.values.every(function (_ref) {
        var value = _ref.value;
        return value;
      }) && this.rtoRpoGroup.rpo.values.every(function (_ref2) {
        var value = _ref2.value;
        return value;
      });
    }
  }),
  mounted: function mounted() {
    this.loadData();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useSettingsStore, {
    loadSettings: 'load'
  })), {}, {
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      var promises = [this.getComplianceProjects(), this.getRiskProjects(), this.getValuesFromSettings(), this.getCustomFieldValues(this.RTO.NAME, this.RTO.VALUES_NAME), this.getCustomFieldValues(this.RPO.NAME, this.RPO.VALUES_NAME)];
      return Promise.all(promises).then(function () {
        return _this.isLoading = false;
      });
    },
    getComplianceProjects: function getComplianceProjects() {
      var _this2 = this;
      return getAllComplianceProjects().then(function (_ref3) {
        var list = _ref3.list;
        return _this2.complianceProjects = list;
      });
    },
    getRiskProjects: function getRiskProjects() {
      var _this3 = this;
      return _getRiskProjects().then(function (_ref4) {
        var list = _ref4.list;
        return _this3.riskProjects = list;
      });
    },
    getCustomFieldValues: function getCustomFieldValues(name, valuesName) {
      var _this4 = this;
      var id = this.data[name];
      if (id) {
        return getCustomField(id).then(function (response) {
          _this4.rtoRpoGroup[valuesName].values = Object.values(response.options).map(function (option) {
            return {
              value: option.value,
              id: option.id
            };
          });
        });
      }
    },
    getValuesFromSettings: function getValuesFromSettings() {
      var _this5 = this;
      Object.keys(this.data).forEach(function (key) {
        return _this5.addSettingToData(_this5.getSettingValue(key), key);
      });
    },
    onProjectAssessmentChange: function onProjectAssessmentChange(projectId, riskProjectId) {
      this.complianceProjectsDataToSave[projectId] = riskProjectId;
    },
    addSettingToData: function addSettingToData(value, name) {
      if (value === undefined) return;
      this.data[name] = value;
    },
    saveSettings: function saveSettings() {
      var _this6 = this;
      if (!this.isRtoRpoValid) return;
      return Promise.all([this.saveComplianceProjectsChange(), this.saveSettingsForCustomIA(), this.saveCustomField(this.RTO.LABEL, this.RTO.VALUES_NAME, this.RTO.NAME), this.saveCustomField(this.RPO.LABEL, this.RPO.VALUES_NAME, this.RPO.NAME)]).then(function () {
        return _this6.patchSettings();
      }).then(function () {
        _this6.handleShowNotify({
          type: 'success',
          message: _this6.translate.settingsAreSaved
        });
      }).catch(function (error) {
        _this6.handleShowNotify({
          type: 'error',
          message: _this6.$trans(error.response.error)
        });
      });
    },
    saveComplianceProjectsChange: function saveComplianceProjectsChange() {
      var promises = [];
      Object.entries(this.complianceProjectsDataToSave).forEach(function (_ref5) {
        var _ref6 = _slicedToArray(_ref5, 2),
          projectId = _ref6[0],
          riskProjectId = _ref6[1];
        promises.push(updateProject(projectId, {
          riskProjectId: riskProjectId
        }));
      });
      return Promise.all(promises);
    },
    saveSettingsForCustomIA: function saveSettingsForCustomIA() {
      var promises = [];
      this.customInformationAssetsModules.forEach(function (module) {
        var data = {
          riskEnabled: module.riskEnabled,
          riskProjectId: module.riskProjectId
        };
        promises.push(editActivityType(module.id, data));
      });
      return Promise.all(promises);
    },
    saveCustomField: function saveCustomField(label, valuesName, name) {
      var _this7 = this;
      var data = {
        label: label,
        typeId: CustomFieldTypes.SELECT,
        module: MODULES.COMPLIANCE_IA_SYSTEM,
        optionObjects: this.rtoRpoGroup[valuesName].values.map(function (item) {
          return {
            value: item.value,
            id: item.id
          };
        })
      };
      var id = this.data[name];
      if (id) {
        return editCustomField(id, data);
      } else {
        return createCustomField(data).then(function (_ref7) {
          var id = _ref7.id;
          return _this7.addSettingToData(id, name);
        });
      }
    },
    addInputField: function addInputField(value) {
      this.rtoRpoGroup[value].values.push({
        value: ''
      });
    },
    deleteInputField: function deleteInputField(index, value) {
      if (this.rtoRpoGroup[value].values.length === 1) {
        this.rtoRpoGroup[value].values = [{
          value: ''
        }];
        return;
      }
      this.rtoRpoGroup[value].values.splice(index, 1);
    },
    patchSettings: function patchSettings() {
      var _this8 = this;
      return patchUtilSettings(this.data).then(function () {
        return cacheItem('settings', function () {
          return _this8.loadSettings();
        }, 180);
      });
    },
    handleShowNotify: function handleShowNotify(_ref8) {
      var _this9 = this;
      var type = _ref8.type,
        message = _ref8.message;
      this.notificationType = type;
      this.notificationMessage = message;
      this.isNotificationShow = true;
      setTimeout(function () {
        _this9.handleDismissNotification();
      }, 3000);
    },
    handleDismissNotification: function handleDismissNotification() {
      this.notificationMessage = '';
      this.isNotificationShow = false;
    }
  })
};