import * as utils from './utils';
import { Colors as colors } from '@/Colors';
export default {
  name: 'StackedBarAmChart',
  introduction: 'Simple component for stacked bar chart rendering.',
  description: 'It uses window.AmCharts. https://www.amcharts.com/',
  token: "<stacked-bar-am-chart\n        :data-provider=\"dataProvider\" /> ",
  props: {
    dataProvider: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'data to render amchart'
    },
    graphsInfo: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'objects for each value graphs'
    },
    categoryField: {
      required: false,
      type: String,
      default: 'label',
      note: 'label name for each row'
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    this.setWrapperHeight();
    this.createChart();
  },
  methods: {
    getGraphs: function getGraphs() {
      var _this = this;
      return this.graphsInfo.map(function (item, index) {
        return {
          cornerRadiusTop: 4,
          balloonText: '[[title]] [[value]]',
          fillAlphas: 1,
          labelText: '[[value]]',
          title: _this.$trans(_this.graphsInfo[index].title),
          type: 'column',
          fillColors: _this.graphsInfo[index].color,
          lineAlpha: 0,
          color: colors.white,
          valueField: _this.graphsInfo[index].field
        };
      });
    },
    setWrapperHeight: function setWrapperHeight() {
      var MIN_HEIGHT = 300;
      var height = this.dataProvider.length * 50 + this.graphsInfo.length * 20;
      if (height < MIN_HEIGHT) {
        height = MIN_HEIGHT;
      }
      this.$el.style.height = "".concat(height, "px");
    },
    createChart: function createChart() {
      this.chart = window.AmCharts.makeChart('stacked-bar-am-chart', {
        type: 'serial',
        language: utils.getLanguage(),
        dataProvider: this.dataProvider,
        legend: {
          maxColumns: 1,
          position: 'bottom'
        },
        markerSize: 10,
        color: colors.white,
        valueAxes: [{
          stackType: 'regular',
          axisAlpha: 0,
          gridAlpha: 0
        }],
        graphs: this.getGraphs(),
        categoryField: this.categoryField,
        categoryAxis: {
          gridPosition: 'start',
          axisAlpha: 0,
          gridAlpha: 0,
          position: 'left',
          color: colors.system.black
        },
        export: window.AmCharts.exportCFG,
        rotate: true
      });
    }
  }
};