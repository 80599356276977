function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import SearchableSelector from '@/components/Atoms/Inputs/SearchableSelector';
import { mockUsers } from '@/test/data/user';
export default {
  components: {
    SearchableSelector: SearchableSelector
  },
  data: function data() {
    return {
      selectedItems: [],
      items: _toConsumableArray(mockUsers)
    };
  },
  computed: {
    selectedItemsIds: function selectedItemsIds() {
      return this.selectedItems.map(function (item) {
        return item.id;
      });
    }
  },
  methods: {
    selected: function selected(item) {
      this.selectedItems.push(item);
    },
    apiMock: function apiMock(params) {
      var filteredItems = this.items.filter(function (item) {
        return item.label.toLowerCase().includes(params.q.toLowerCase());
      });
      var slicedItems = filteredItems.slice(params.start, params.start + params.limit);
      return new Promise(function (resolve) {
        setTimeout(function () {
          resolve({
            list: slicedItems,
            count: filteredItems.length
          });
        }, 1000);
      });
    }
  }
};