import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-17451fb9"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  class: "flex lg:hidden"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_traffic_light_tool_tip = _resolveComponent("traffic-light-tool-tip");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative rounded-md", [$props.readOnly ? 'blocked' : 'editable']])
  }, [_createElementVNode("div", {
    class: _normalizeClass({
      'lg:block hidden': $props.responsive
    })
  }, [_createVNode(_component_traffic_light, {
    color: $options.trafficLightColor,
    "show-ban": $props.lightType === 'gaps',
    title: $options.colorTitle,
    onClick: $options.toggleToolTip
  }, null, 8 /* PROPS */, ["color", "show-ban", "title", "onClick"]), _createVNode(_component_traffic_light_tool_tip, {
    "traffic-lights-data": $options.trafficLightsData,
    hidden: !$data.tooltip,
    onSelected: $options.handleSelect
  }, null, 8 /* PROPS */, ["traffic-lights-data", "hidden", "onSelected"])], 2 /* CLASS */), $props.responsive ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.trafficLightsData.lights, function (trafficLight) {
    return _openBlock(), _createBlock(_component_traffic_light, {
      key: trafficLight.number,
      height: 40,
      width: 40,
      class: "mr-4",
      color: trafficLight.color,
      stroke: trafficLight.number === $data.selectedLight ? $data.colors.blueLight : $data.colors.trafficLightStroke,
      "show-ban": $options.trafficLightsData.showBan,
      title: $options.colorTitle,
      onClick: function onClick($event) {
        return $options.handleSelect(trafficLight);
      }
    }, null, 8 /* PROPS */, ["color", "stroke", "show-ban", "title", "onClick"]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)), [[_directive_click_outside, $options.hideTooltip]]);
}