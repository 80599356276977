function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import * as Utils from '@/utils/Utils';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { formatDateToFullFormat } from '@/utils/date';
export var CustomFieldsMixin = {
  data: function data() {
    return {
      defaultPlaceholder: this.$trans('Type here') + '...',
      defaultPlaceholderSelect: this.$trans('Choose'),
      maxlength: 500,
      maxlengthForTextFields: 10000
    };
  },
  methods: {
    getSelectOptions: function getSelectOptions(options) {
      var result = [];
      options = Utils.getProperSelectedOptions(options);
      if (options && options.length) {
        result = options.map(function (item) {
          return _objectSpread(_objectSpread({}, item), {}, {
            label: item.value
          });
        });
      }
      return result;
    },
    getNumericalOptions: function getNumericalOptions(value) {
      return [{
        value: 1,
        label: value
      }];
    },
    onChange: function onChange(field, value) {
      var maxLengthRestriction = this.maxlength;
      if (field.typeId === CustomFieldTypes.TEXT_FIELD) {
        maxLengthRestriction = this.maxlengthForTextFields;
      }
      if (field) {
        field.selectedValue = Utils.hasValue(value) ? this.setMaxlength(value, maxLengthRestriction) : '';
      }
      this.$forceUpdate();
      this.$emit('changed', this.allFields);
    },
    onDateTimeChange: function onDateTimeChange(field, value) {
      field.selectedValue = value ? formatDateToFullFormat(value) : null;
      this.$emit('changed', this.allFields);
    },
    setMaxlength: function setMaxlength(e) {
      var maxLengthRestriction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.maxlength;
      return Utils.setMaxlength(e, maxLengthRestriction);
    },
    swapToActualField: function swapToActualField(data, field) {
      var _this = this;
      data.map(function (allField, index) {
        if (allField.id === field.id) {
          _this.allFields.splice(index, 1, field);
        }
      });
    }
  }
};