import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "alert alert-danger"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_router_link = _resolveComponent("router-link");
  var _component_notification_pop_up = _resolveComponent("notification-pop-up");
  var _component_tree = _resolveComponent("tree");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return _openBlock(), _createElementBlock("section", null, [$data.error !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($data.error), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $options.detailLoadedError ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.detailLoadedError), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _ctx.hasNotificationPopUp ? (_openBlock(), _createBlock(_component_notification_pop_up, {
    key: 2,
    onDismiss: _ctx.dismissNotificationPopUp
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.yourActivityHasBeenCreated), 1 /* TEXT */), _createVNode(_component_router_link, {
        to: _ctx.notificationData.url
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.notificationData.title), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onDismiss"])) : _createCommentVNode("v-if", true), $data.nodes !== null && $data.treeData !== null ? (_openBlock(), _createBlock(_component_tree, {
    key: 3,
    ref: "tree",
    "tree-title": _ctx.processLibraryTitle,
    "tree-data": $data.nodes,
    "do-move": $data.doMove,
    "new-child": $data.newChild,
    "tree-rights": $options.userHasRights,
    "tree-id": $data.treeId,
    "tree-key": $data.treeData.id,
    "level-select": true,
    "delete-node-option": true,
    "lock-tree": $options.lockTree,
    "show-levels-classes": $options.lockTree ? '-top-15px' : 'top-10px',
    "disable-progress-shown": true,
    "add-root-node-without-reloading": true,
    onAddChild: $options.addChildToNode,
    onDoDeleteNode: $options.doDeleteNode,
    onMove: $options.tryMove,
    onMoved: $options.resetMove,
    onReload: $options.reloadData
  }, null, 8 /* PROPS */, ["tree-title", "tree-data", "do-move", "new-child", "tree-rights", "tree-id", "tree-key", "lock-tree", "show-levels-classes", "onAddChild", "onDoDeleteNode", "onMove", "onMoved", "onReload"])) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 4,
    class: "mt-25vh-80"
  }))]);
}