export var CustomFieldTypes = {
  SELECT: 1,
  TEXT_LINE: 2,
  TEXT_FIELD: 3,
  TRAFFIC_LIGHT: 4,
  TREND: 5,
  DATE_TIME: 6,
  NUMERICAL: 7,
  COMBINED: 10,
  ROW_GROUP: 11
};
export function getCustomFieldTypeText(value) {
  switch (value) {
    case CustomFieldTypes.SELECT:
      return 'Dropdown';
    case CustomFieldTypes.TEXT_LINE:
      return 'Text line';
    case CustomFieldTypes.TEXT_FIELD:
      return 'Text field (many lines)';
    case CustomFieldTypes.TRAFFIC_LIGHT:
      return 'Traffic light';
    case CustomFieldTypes.TREND:
      return 'Trend';
    case CustomFieldTypes.ROW_GROUP:
      return 'Repeatable fields';
    case CustomFieldTypes.DATE_TIME:
      return 'Date & Time';
    case CustomFieldTypes.NUMERICAL:
      return 'Numerical';
    default:
      return 'Unknown field type';
  }
}