import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue";
var _hoisted_1 = {
  key: 2,
  class: "border border-gray-450 rounded p-2"
};
var _hoisted_2 = {
  class: "hidden"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_report_search_form = _resolveComponent("report-search-form");
  var _component_no_search_results = _resolveComponent("no-search-results");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_save_report = _resolveComponent("save-report");
  var _component_hierarchical_list = _resolveComponent("hierarchical-list");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_trafficlight = _resolveComponent("trafficlight");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_report_search_form, {
    title: $data.translate.searchInAllNodes,
    "helper-text": $data.translate.enterASearchKeywordAndorSelectOrganisations,
    "scoring-toggle-enabled": true,
    "project-id": $props.projectId,
    "has-lia-tia-filter": +$props.projectId === $data.ProjectTypes.GDPR,
    "local-storage-key": "node_report_filters_".concat($props.projectId),
    "initial-filters": $data.initialFilters,
    onDoSearch: $options.handleDoSearch,
    onClearQuery: $options.handleClearQuery
  }, {
    inputSlot: _withCtx(function (slotData) {
      return [_createVNode(_component_input_field, {
        ref: "reportQuery",
        modelValue: $data.queryInput,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.queryInput = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.freeTextSearch,
        type: "text",
        onKeyup: _withKeys(slotData === null || slotData === void 0 ? void 0 : slotData.handleSearch, ["enter"])
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "onKeyup"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "helper-text", "project-id", "has-lia-tia-filter", "local-storage-key", "initial-filters", "onDoSearch", "onClearQuery"]), $data.noResults ? (_openBlock(), _createBlock(_component_no_search_results, {
    key: 0,
    body: $data.serverError
  }, null, 8 /* PROPS */, ["body"])) : _createCommentVNode("v-if", true), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  })) : _createCommentVNode("v-if", true), $data.dataset.length > 0 && !$data.loading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_save_report, {
    type: $data.reportType,
    "state-local": $data.stateLocal,
    "compliance-projects-id": $props.projectId,
    "report-options": $setup.searchArgs,
    onLoad: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.loadReportByReplacingQueryParams($event);
    })
  }, null, 8 /* PROPS */, ["type", "state-local", "compliance-projects-id", "report-options"]), _createVNode(_component_risma_datatable, {
    ref: "reportsDataTable",
    dataset: _ctx.datasetSlice,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    paging: true,
    "filter-by-column": true,
    "decouple-filter-fields": $data.decoupleFilterFields,
    "disabled-filter-by-columns": $data.disabledFilterByColumns,
    "wrapper-height-sticky": 500,
    "columns-by-default": $options.columns,
    "columns-meta": $options.columnsMeta,
    "column-sorting": $data.columnSorting,
    "state-local": $data.stateLocal,
    "default-sorting": null,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged,
    onUpdated: _cache[4] || (_cache[4] = function ($event) {
      return $data.updatedNode = $event;
    }),
    onDismissModal: $options.onDismissModal
  }, _createSlots({
    process: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_createVNode(_component_hierarchical_list, {
        "has-preview-mode": true,
        "show-children": true,
        "toggle-children": false,
        node: item.node,
        onUpdated: _cache[2] || (_cache[2] = function ($event) {
          return $data.updatedNode = $event;
        }),
        onDismissModal: $options.onDismissModal
      }, null, 8 /* PROPS */, ["node", "onDismissModal"])];
    }),
    _: 2 /* DYNAMIC */
  }, [_renderList($options.linkedColumnsForPreview, function (linkCol) {
    return {
      name: linkCol,
      fn: _withCtx(function (_ref2) {
        var item = _ref2.item;
        return [item && item.activities && item.activities.length ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(linkCol, "_").concat(item.id)
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.activities, function (activity, index) {
          return _openBlock(), _createBlock(_component_preview_modal_link, {
            key: "".concat(activity.fieldText, "_").concat(index),
            class: "hover:underline",
            activity: activity.activity,
            onUpdated: _cache[3] || (_cache[3] = function ($event) {
              return $data.updatedNode = $event;
            }),
            onDismissModal: $options.onDismissModal
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(activity.fieldText), 1 /* TEXT */)];
            }),

            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onDismissModal"]);
        }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("div", {
          key: linkCol
        }))];
      })
    };
  }), _renderList(_ctx.trafficLightRismaDatatableFields, function (trafficLightCol) {
    return {
      name: trafficLightCol,
      fn: _withCtx(function (_ref3) {
        var item = _ref3.item;
        return [item.field.color ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(trafficLightCol)
        }, [_createVNode(_component_trafficlight, {
          color: item.field.color
        }, null, 8 /* PROPS */, ["color"]), _createElementVNode("p", _hoisted_2, _toDisplayString(item.field.value) + "-" + _toDisplayString(item.field.label), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("span", {
          key: "".concat(trafficLightCol)
        }))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset", "dataset-total", "dataset-total-sliced", "decouple-filter-fields", "disabled-filter-by-columns", "columns-by-default", "columns-meta", "column-sorting", "state-local", "onPageChange", "onOnStateChanged", "onDismissModal"])])) : _createCommentVNode("v-if", true)]);
}