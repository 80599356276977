import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "truncate w-full text-2xl font-semibold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["risma-circle cursor-pointer m-2 relative w-24 h-24 leading-10 border-green-410 bg-green-410 rounded-full text-center flex items-center shadow-md", {
      'active': $props.isActive
    }])
  }, [_createElementVNode("p", _hoisted_1, [_renderSlot(_ctx.$slots, "default")])], 2 /* CLASS */);
}