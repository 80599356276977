function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Colors } from '@/Colors';
import i18n from '@/i18n';
export var KpiChartColors = [['rgb(88, 8, 133)', '#decee7'], ['rgb(230, 91, 155)', '#fdeff5'], ['rgb(17, 204, 140)', '#e7faf4'], ['rgb(103, 47, 195)', '#f0eaf9'], ['rgb(254, 147, 112)', '#fff4f1'], ['rgb(0, 193, 190)', '#e6f9f9'], ['rgb(254, 217, 47)', '#fffbea'], ['rgb(0, 172, 233)', '#e6f7fd']];
export var KpiCardColors = ['#e65b9b', '#11cc8c', '#672fc3', '#00c1be', '#feD92f', '#00ace9'];
export var KpiRadarColors = [Colors.greenTea, Colors.blueLight];
export var complianceChartOptions = {
  cutout: '20%',
  borderRadius: {
    innerStart: 1,
    outerStart: 1,
    innerEnd: 360,
    outerEnd: 360
  },
  borderWidth: 3,
  plugins: {
    tooltip: {
      padding: 15,
      boxPadding: 10,
      filter: function filter(ctx) {
        return ctx.datasetIndex && ctx.parsed && ctx.parsed !== 100;
      },
      callbacks: {
        label: function label(ctx) {
          var _ctx$dataset$rawData;
          if (!((_ctx$dataset$rawData = ctx.dataset.rawData) !== null && _ctx$dataset$rawData !== void 0 && _ctx$dataset$rawData.length)) {
            return "".concat(ctx.dataset.title, ": ").concat(ctx.raw, "%");
          }
          return "".concat(ctx.dataset.title, ": ").concat(ctx.dataset.rawData[ctx.dataIndex], "%");
        }
      }
    },
    center: {
      text: '',
      color: '',
      fontStyle: 'bold',
      borderRadius: 5
    }
  }
};
export var projectProgressChartOptions = {
  maintainAspectRatio: false,
  fill: false,
  scales: {
    y: {
      suggestedMin: 0,
      suggestedMax: 100,
      ticks: {
        stepSize: 20
      }
    },
    x: {
      offset: true,
      ticks: {
        color: Colors.lilacGray,
        font: {
          size: 14,
          weight: '600',
          family: 'Raleway, sans-serif'
        }
      }
    }
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        pointStyle: 'circle',
        usePointStyle: true,
        padding: 15
      }
    },
    tooltip: {
      padding: 15,
      boxPadding: 10
    }
  }
};
export var projectStatusChartOptions = {
  cutout: '80%',
  borderRadius: {
    innerStart: 1,
    outerStart: 1,
    innerEnd: 360,
    outerEnd: 360
  },
  plugins: {
    center: {
      fontStyle: 'bold',
      padding: 10,
      borderRadius: 2,
      text: '',
      color: '',
      fillColor: '',
      borderColor: ''
    }
  }
};
export var metricData = {
  data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
  backgroundColor: [Colors.system.white],
  rotation: 18,
  datalabels: {
    labels: {
      display: true
    },
    color: Colors.system.black,
    display: function display(ctx) {
      var idx = ctx.dataIndex;
      return !((idx - 1) % 2);
    },
    formatter: function formatter(value, ctx) {
      var label = value * (ctx.dataIndex + 1);
      return label === 100 ? 0 : label;
    }
  },
  hoverBackgroundColor: Colors.system.white,
  hoverBorderColor: Colors.system.white,
  hoverBorderWidth: 0
};
export var getNewStatusDataObject = function getNewStatusDataObject(data, _ref) {
  var _ref$fontSize = _ref.fontSize,
    fontSize = _ref$fontSize === void 0 ? 25 : _ref$fontSize;
  var newDataObj = _objectSpread(_objectSpread({}, data), {}, {
    datasets: [{
      data: data.data,
      backgroundColor: data.backgroundColor
    }],
    options: JSON.parse(JSON.stringify(projectStatusChartOptions))
  });
  newDataObj.options.plugins.center.maxFontSize = fontSize;
  newDataObj.options.plugins.center.text = data.data[0] + '%' || '';
  newDataObj.options.plugins.center.color = data.backgroundColor[0] || '';
  newDataObj.options.plugins.center.borderColor = data.backgroundColor[0] || '';
  newDataObj.options.plugins.center.fillColor = data.backgroundColor[1] || '';
  return newDataObj;
};
export var generateInsights = function generateInsights(_ref2) {
  var activeKpi = _ref2.activeKpi,
    monthlyScores = _ref2.monthlyScores,
    isAbove = _ref2.isAbove;
  var isAboveText = i18n.t(isAbove ? 'above' : 'below');
  var initialMessages = [{
    value: activeKpi,
    text: i18n.t('The compliance score is based on %s active KPIs')
  }, {
    value: monthlyScores,
    text: i18n.t('The score for the last 30 days was <strong>%s</strong>%')
  }, {
    value: isAboveText,
    text: i18n.t('The current score is %s the average of the previous 30 days')
  }];
  return [{
    description: '',
    icon: 'shield',
    color: '#00acef'
  }, {
    description: '',
    icon: 'award',
    color: '#ffd731'
  }, {
    description: '',
    icon: 'trending-up',
    color: '#00dbb9'
  }].map(function (item, idx) {
    var itemCopy = _objectSpread({}, item),
      options = initialMessages[idx];
    itemCopy.description = options.text.replace('%s', options.value);
    return itemCopy;
  });
};