function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getMy as getMyIncidents, getArchived as getArchivedIncidents } from '@/api/incident/incident';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Notification from '@/components/Molecules/Notification';
import PreviewListTile from '@/components/Molecules/PreviewList/PreviewListTile';
import CompactCard from '@/components/Molecules/Cards/CompactCard';
import ViewSwitcher from '@/components/Molecules/ViewSwitcher';
import PreviewListExpanded from '@/components/Molecules/PreviewList/PreviewListExpanded';
import VerticalMenu from '@/components/Molecules/VerticalMenu/VerticalMenu';
import { STANDARD_VIEWS } from '@/constants/ViewTypes';
import { viewTypes } from '@/constants/ListViewType';
import { SortFieldsCompatibility } from '@/constants/SortFieldsCompatibility';
import { MODULES } from '@/constants/modules';
import { check403AccessError } from '@/utils/handleAPIErrors';
import { getSortFunction, sortByArrayOfFunctions } from '@/utils/sort';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
var IncidentKeys = {
  MY_LIST: 'my',
  ARCHIVED_LIST: 'archived'
};
export default {
  name: 'MyIncidents',
  description: 'List of my incidents as cards',
  components: {
    RismaTitle: RismaTitle,
    PreviewListTile: PreviewListTile,
    CompactCard: CompactCard,
    ViewSwitcher: ViewSwitcher,
    SingleSelect: SingleSelect,
    PreviewListExpanded: PreviewListExpanded,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    VerticalMenu: VerticalMenu,
    Notification: Notification
  },
  props: {
    currentRouteGroup: {
      required: false,
      type: String,
      default: ''
    }
  },
  setup: function setup() {
    var router = useRouter();
    var isMy = computed(function () {
      return router.currentRoute.value.name === 'MyIncidents';
    });
    return {
      isMy: isMy,
      sortBy: getLocalStorageComputed("my_incidents_sort_by".concat(isMy.value ? '' : '_archived'), SortFieldsCompatibility.INCID_HAPPEND_AT),
      viewSelected: getLocalStorageComputed('my_incidents_display_view', viewTypes.TILES)
    };
  },
  data: function data() {
    return {
      incidents: [],
      STANDARD_VIEWS: STANDARD_VIEWS,
      viewTypes: viewTypes,
      MODULE_NAME: MODULES.INCIDENTS,
      WATERMARK_IMAGE: 'url(\'/assets/svg/ikon_incident.svg\')',
      isLoaded: false,
      localKey: 0,
      path: '/incident/my/',
      translate: getTranslate['MyIncidents']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useActivityTypesStore, {
    activityTypes: 'incidentActivityTypes'
  })), {}, {
    sortOptions: function sortOptions() {
      return [{
        id: 1,
        label: this.translate.title,
        value: SortFieldsCompatibility.TITLE
      }, {
        id: 2,
        label: this.translate.created,
        value: SortFieldsCompatibility.CREATED_AT
      }, {
        id: 3,
        label: this.translate.incidentOccurred,
        value: SortFieldsCompatibility.INCID_HAPPEND_AT
      }, {
        id: 9,
        label: this.translate.incidentArchived,
        value: SortFieldsCompatibility.INCID_ARCHIVED_AT
      }, {
        id: 4,
        label: this.translate.severity,
        value: SortFieldsCompatibility.SERVITY
      }, {
        id: 6,
        label: this.translate.accountable,
        value: SortFieldsCompatibility.ACCOUNTABLE_USER_IDS
      }, {
        id: 7,
        label: this.translate.description,
        value: SortFieldsCompatibility.DESCRIPTION
      }, {
        id: 8,
        label: this.translate.status,
        value: SortFieldsCompatibility.RISMA_STATE
      }];
    },
    sortOptionsFiltered: function sortOptionsFiltered() {
      if (!this.isMy) return this.sortOptions;
      return this.sortOptions.filter(function (item) {
        return item.value !== SortFieldsCompatibility.INCID_ARCHIVED_AT;
      });
    },
    filteredIncidents: function filteredIncidents() {
      var _result;
      var result = (_result = {}, _defineProperty(_result, IncidentKeys.MY_LIST, []), _defineProperty(_result, IncidentKeys.ARCHIVED_LIST, []), _result);
      this.incidents.forEach(function (incident) {
        if (incident.archived) {
          result[IncidentKeys.ARCHIVED_LIST].push(incident);
        } else {
          result[IncidentKeys.MY_LIST].push(incident);
        }
      });
      return result;
    },
    incidentGroups: function incidentGroups() {
      var _this = this;
      var activityTypesInUse = this.filteredIncidents[IncidentKeys.MY_LIST].reduce(function (obj, incident) {
        if (incident.archived) return obj;
        var activityTypeId = incident.activityTypeId + '';
        if (obj[activityTypeId]) {
          obj[activityTypeId].items.push(incident);
        } else {
          var _this$activityTypes$f;
          var label = ((_this$activityTypes$f = _this.activityTypes.find(function (type) {
            return +type.id === +activityTypeId;
          })) === null || _this$activityTypes$f === void 0 ? void 0 : _this$activityTypes$f.label) || '';
          obj[activityTypeId] = {
            key: activityTypeId,
            title: label,
            path: _this.path + activityTypeId,
            items: [incident]
          };
        }
        return obj;
      }, {});
      return [{
        key: IncidentKeys.MY_LIST,
        title: this.translate.myIncidents,
        path: this.path,
        items: this.filteredIncidents[IncidentKeys.MY_LIST]
      }].concat(_toConsumableArray(Object.values(activityTypesInUse)), [{
        key: IncidentKeys.ARCHIVED_LIST,
        title: this.translate.archivedIncidents,
        path: '/incident/' + IncidentKeys.ARCHIVED_LIST,
        items: this.filteredIncidents[IncidentKeys.ARCHIVED_LIST]
      }]);
    },
    incidentsSorted: function incidentsSorted() {
      var sortFunction = getSortFunction({
        sortBy: this.sortBy,
        users: this.users,
        organisations: this.organisations
      });
      var ascending = true;
      if (this.sortBy === SortFieldsCompatibility.CREATED_AT) {
        ascending = false;
      }
      return sortByArrayOfFunctions(this.currentGroup.items, [sortFunction], ascending);
    },
    currentGroup: function currentGroup() {
      var _this2 = this;
      if (this.currentRouteGroup) {
        /* incidents/my/archived check (incidents/archived is right path) */
        if (this.currentRouteGroup === IncidentKeys.ARCHIVED_LIST && this.isMy) {
          return null;
        }
        return this.incidentGroups.find(function (group) {
          return group.key === _this2.currentRouteGroup;
        }) || null;
      }
      return this.incidentGroups.find(function (group) {
        return group.key === IncidentKeys.MY_LIST;
      });
    },
    menuItems: function menuItems() {
      if (!this.activityTypes.length || !this.incidentGroups.length) return [];
      return this.incidentGroups.filter(function (group) {
        return group.key !== IncidentKeys.ARCHIVED_LIST;
      }).map(function (group) {
        return {
          title: "".concat(group.title, " (").concat(group.items.length, ")"),
          path: group.path
        };
      });
    },
    getRightApi: function getRightApi() {
      return this.isMy ? getMyIncidents : getArchivedIncidents;
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'incident');
    }
  }),
  watch: {
    currentRouteGroup: function currentRouteGroup() {
      this.localKey++;
    }
  },
  mounted: function mounted() {
    var _this3 = this;
    this.getData().then(function () {
      return _this3.isLoaded = true;
    });
  },
  methods: {
    getData: function getData() {
      var _this4 = this;
      return Promise.all([this.getRightApi().then(function (list) {
        return _this4.incidents = list || [];
      })]);
    },
    updateFromPreviewMode: function updateFromPreviewMode(item) {
      var index = this.incidents.findIndex(function (incident) {
        return +incident.id === +item.id;
      });
      if (index === -1) return;
      this.incidents[index] = item;
    }
  }
};