function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getRiskTypes as _getRiskTypes, createRiskType as _createRiskType, updateRiskType as _updateRiskType, deleteRiskType as _deleteRiskType } from '@/api/risk/risk';
import RiskManagement from '@/components/Organisms/RiskAdmin/RiskManagement';
export default {
  name: 'RiskTypesWell',
  token: '<risk-types-well />',
  components: {
    RiskManagement: RiskManagement
  },
  props: {
    risks: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      riskTypes: [],
      translate: {
        type: this.$trans('Type'),
        risks: this.$trans('Risks')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    riskTypeLabel: function riskTypeLabel() {
      return this.getSettingValue('risma.risktype_name');
    },
    dataset: function dataset() {
      var _this = this;
      return this.riskTypes.map(function (riskType) {
        var _this$risksHash;
        return {
          title: riskType.title,
          risks: ((_this$risksHash = _this.risksHash) === null || _this$risksHash === void 0 ? void 0 : _this$risksHash[riskType.id]) || 0,
          edit: {
            id: riskType.id,
            title: riskType.title
          },
          delete: {
            id: riskType.id,
            title: riskType.title
          }
        };
      });
    },
    risksHash: function risksHash() {
      var obj = {};
      this.risks.forEach(function (risk) {
        if (!obj[risk.riskTypeId]) {
          obj[risk.riskTypeId] = 0;
        }
        obj[risk.riskTypeId] += 1;
      });
      return obj;
    },
    columns: function columns() {
      return [this.translate.type, this.translate.risks, '', ''];
    }
  }),
  mounted: function mounted() {
    this.getRiskTypes();
  },
  methods: {
    getRiskTypes: function getRiskTypes() {
      var _this2 = this;
      return _getRiskTypes().then(function (_ref) {
        var list = _ref.list;
        return _this2.riskTypes = list;
      });
    },
    createRiskType: function createRiskType(title) {
      var _this3 = this;
      return _createRiskType({
        title: title
      }).then(function (response) {
        _this3.riskTypes.push(response);
      });
    },
    updateRiskType: function updateRiskType(title, id) {
      var _this4 = this;
      return _updateRiskType(id, {
        title: title
      }).then(function (response) {
        var index = _this4.riskTypes.findIndex(function (riskType) {
          return riskType.id === id;
        });
        _this4.riskTypes[index] = response;
        _this4.riskTypes = _toConsumableArray(_this4.riskTypes);
      });
    },
    deleteRiskType: function deleteRiskType(id) {
      var _this5 = this;
      return _deleteRiskType(id).then(function () {
        _this5.riskTypes = _this5.riskTypes.filter(function (riskType) {
          return riskType.id !== id;
        });
      });
    }
  }
};