import { getActionBreadcrumb as _getActionBreadcrumb } from '@/api/execution/initiative';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { Colors } from '@/Colors';
export default {
  name: 'ActionBreadcrumbs',
  introduction: 'Action breadcrumps(overlying and underlying) on top of the tabs',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    initiative: {
      required: true,
      type: Object
    }
  },
  data: function data() {
    return {
      breadcrumb: [],
      Colors: Colors,
      translate: {
        overlying: this.$trans('Overlying'),
        actions: this.$trans('Actions').toLowerCase(),
        underlying: this.$trans('Underlying')
      }
    };
  },
  computed: {
    initiativeChildrenLength: function initiativeChildrenLength() {
      return this.initiative.children ? this.initiative.children.length : 0;
    },
    reversedBreadcrumb: function reversedBreadcrumb() {
      if (!this.breadcrumb) return [];
      return this.breadcrumb.slice().reverse();
    },
    reversedVisibleBreadcrumb: function reversedVisibleBreadcrumb() {
      return this.reversedBreadcrumb.filter(function (item) {
        return item.rights;
      });
    }
  },
  mounted: function mounted() {
    this.getActionBreadcrumb();
  },
  methods: {
    getActionBreadcrumb: function getActionBreadcrumb() {
      var _this = this;
      return _getActionBreadcrumb(this.initiative.slug).then(function (_ref) {
        var list = _ref.list;
        return _this.breadcrumb = list;
      });
    }
  }
};