function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Notification from '@/components/Molecules/Notification';
import { resetPassword } from '@/api/risma/password';
import { passwordErrorMessage } from '@/utils/notifications';
import { isPasswordValid } from '@/utils/validations';
import { LogoSettings } from '@/constants/admin/CustomizationSettings';
export default {
  name: 'ResetPassword',
  components: {
    InputField: InputField,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    Notification: Notification
  },
  token: '<reset-password />',
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      isResetSuccess: false,
      password: '',
      verifyPassword: '',
      notificationMessages: '',
      invalidToken: false,
      notificationType: 'error',
      translate: getTranslate['ResetPassword']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    hasEnterpriseSecurity: function hasEnterpriseSecurity() {
      return this.getSettingValue('feature.enterprise_security');
    },
    logoSrc: function logoSrc() {
      return LogoSettings.DEFAULT_WITH_BLUE_TEXT;
    }
  }),
  methods: {
    onResetPassword: function onResetPassword() {
      var _this = this;
      this.notificationMessages = '';
      var password = this.password.trim();
      var verifyPassword = this.verifyPassword.trim();
      if (this.isValidPassword(password, verifyPassword)) {
        resetPassword(this.token, {
          newPassword: password
        }).then(function () {
          _this.isResetSuccess = true;
        }).catch(function (error) {
          if (error.response === 'Invalid token') {
            _this.invalidToken = true;
          } else {
            if (Array.isArray(error.response)) {
              var errors = error.response.map(function (error) {
                return error = error.charAt(0).toUpperCase() + error.slice(1);
              });
              _this.notificationMessages = errors.join(', ');
            } else {
              _this.notificationMessages = error.response;
            }
          }
        });
      }
    },
    isValidPassword: function isValidPassword(password, verifyPassword) {
      if (!password) {
        this.notificationMessages = this.translate.pleaseFillOutPasswordField;
        return false;
      }
      if (this.hasEnterpriseSecurity && !isPasswordValid(password)) {
        this.notificationMessages = this.descriptionOfThePasswordError();
        return false;
      }
      if (password !== verifyPassword) {
        this.notificationMessages = this.translate.repeatTheNewPassword;
        return false;
      }
      return true;
    },
    goToLogin: function goToLogin() {
      this.$router.push('/');
    },
    descriptionOfThePasswordError: function descriptionOfThePasswordError() {
      return passwordErrorMessage(this.hasEnterpriseSecurity);
    }
  }
};