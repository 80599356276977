function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import ControlPanelSettings from '@/components/Organisms/Admin/ControlPanelSettings';
import { RiskProjects } from '@/constants/risks/RiskProjects';
import { SETTINGS_ARRAY } from '@/constants/admin/Settings';
import { checkERMByProjectId } from '@/utils/risks/check';
var APPROVAL_FLOW_RISK_PROJECT_TPL = 'approvalflow_risk_project-';
var CONFIDENTIAL_RISK_PROJECT_TPL = 'confidential_risk_project-';
export default {
  name: 'RiskControlPanel',
  description: 'Page with risk control panel section',
  token: '<risk-control-panel />',
  components: {
    ControlPanelSettings: ControlPanelSettings
  },
  props: {
    filteredRiskProjects: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk projects except ERM'
    }
  },
  data: function data() {
    return {
      translate: {
        enableRiskApprovalFor: this.$trans('Enable risk approval for'),
        allowConfidentialRisksFor: this.$trans('Allow confidential risks for')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    settingsGroups: function settingsGroups() {
      return _defineProperty({}, 'Risk control panel', _objectSpread(_objectSpread({}, SETTINGS_ARRAY['RISMArisk']), this.riskProjectsFlow));
    },
    projectOptions: function projectOptions() {
      return [{
        type: 'boolvalue',
        settingName: APPROVAL_FLOW_RISK_PROJECT_TPL,
        initialName: this.translate.enableRiskApprovalFor + ' ',
        settingField: 'riskApproval'
      }, {
        type: 'boolvalue',
        settingName: CONFIDENTIAL_RISK_PROJECT_TPL,
        initialName: this.translate.allowConfidentialRisksFor + ' ',
        settingField: 'confidentialRisks',
        ermIncluded: false
      }];
    },
    riskProjectsFlow: function riskProjectsFlow() {
      var _this = this;
      var result = {};
      this.filteredRiskProjects.filter(function (item) {
        return item.enabled;
      }).map(function (project) {
        _this.projectOptions.forEach(function (option) {
          var settingName = "".concat(option.settingName).concat(project.id);
          if (!option.ermIncluded && checkERMByProjectId(project.id, _this.currentUser)) {
            return;
          }
          var settingProps = {
            type: option.type,
            name: option.initialName + project.title,
            default: !!project[option.settingField],
            settingField: option.settingField,
            translatableTitle: true,
            link: _this.getLink(project.type, option.settingField)
          };
          result[settingName] = settingProps;
        });
      });
      return result;
    }
  }),
  methods: {
    getLink: function getLink(projectType, settingField) {
      switch (settingField) {
        case 'confidentialRisks':
          {
            if (projectType === RiskProjects.ARTICLE32.type) {
              return 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions#confidentialriskspr';
            }
            return 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions#confidentialrisksesg';
          }
        case 'riskApproval':
          {
            if (projectType === RiskProjects.ARTICLE32.type) {
              return 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions#enableprivacyriskapproval';
            }
            if (projectType === RiskProjects.IRM.type) {
              return 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions#enableesgriskapproval';
            }
            return 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions#riskapprovalERM';
          }
        default:
          {
            return 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions';
          }
      }
    }
  }
};