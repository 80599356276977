function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
export default {
  name: 'RismaOrgSelector',
  introduction: 'A label and an organisation selector',
  description: 'Molecule for choosing organisations',
  token: '<risma-org-selector :available-options="rismaOrgSelectorUsers"\n' + '        :selected-options="rismaOrgSelectorSelectedOptions"\n' + '        title="Responsible (R)"></risma-org-selector>',
  components: {
    RismaSelect: RismaSelect,
    RismaLabel: RismaLabel
  },
  props: {
    title: {
      default: '',
      required: false,
      type: String,
      note: 'the title above the selector'
    },
    availableOptions: {
      required: true,
      type: Array,
      note: 'the elements in the selector'
    },
    selectedOptions: {
      required: false,
      default: function _default() {
        return [];
      },
      type: Array,
      note: 'the selected elements in the selector'
    },
    labelKey: {
      required: false,
      default: 'visible_name',
      type: String,
      note: 'Label key for RismaSelect'
    },
    placeholder: {
      required: false,
      default: 'Organisations',
      type: String,
      note: 'Default placeholder'
    },
    openDirection: {
      required: false,
      default: '',
      type: String,
      note: 'Fixed opening direction (instead of auto). Options are "above"/"top" or "below"/"bottom'
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    maxVisibleTags: function maxVisibleTags() {
      return this.getSettingValue('risma.max_visible_users_orgs');
    }
  })
};