function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
export default {
  name: 'PDFDocument',
  introduction: 'PDFDocument is component for parsing and rendering PDFs using pdfjs-dist',
  token: '<pdf-document :src-pdf="fileSrcPreview" />',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    srcPdf: {
      type: String,
      required: true,
      note: 'The scr pdf file'
    }
  },
  data: function data() {
    return {
      pdfDoc: undefined,
      page: null,
      currentPage: 1,
      pageRendering: false,
      viewport: null,
      pageNumPending: null,
      renderTask: null,
      isLoaded: false,
      resizingDelay: 200,
      resizingTimer: null,
      translate: {
        previous: this.$trans('Previous'),
        next: this.$trans('Next')
      }
    };
  },
  computed: {
    pageCount: function pageCount() {
      return this.pdfDoc ? this.pdfDoc.numPages : 0;
    }
  },
  mounted: function mounted() {
    this.createPage();
    window.addEventListener('resize', this.initDraw);
  },
  beforeUnmount: function beforeUnmount() {
    this.destroyPage(this.page);
    window.removeEventListener('resize', this.initDraw);
  },
  methods: {
    createPage: function createPage() {
      var _this = this;
      Promise.all([import('pdfjs-dist/es5/build/pdf'), import('pdfjs-dist/es5/build/pdf.worker.entry')]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          pdfjsLib = _ref2[0],
          pdfjsWorker = _ref2[1];
        pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
        pdfjsLib.getDocument(_this.srcPdf).promise.then(function (pdfDoc) {
          _this.pdfDoc = pdfDoc;
          _this.renderPage(_this.currentPage);
        });
      });
    },
    renderPage: function renderPage(num) {
      var _this2 = this;
      this.pageRendering = true;
      this.pdfDoc.getPage(num).then(function (page) {
        _this2.page = page;
        _this2.drawPage();
      });
    },
    changeCurrentPage: function changeCurrentPage(pageNumber) {
      if (!pageNumber || pageNumber > this.pageCount) return;
      this.currentPage = pageNumber;
      if (this.pageRendering) {
        this.pageNumPending = pageNumber;
      } else {
        this.renderPage(this.currentPage);
      }
    },
    initDraw: function initDraw() {
      var _this3 = this;
      this.resizingTimer && clearTimeout(this.resizingTimer);
      this.resizingTimer = setTimeout(function () {
        _this3.drawPage();
      }, this.resizingDelay);
    },
    drawPage: function drawPage() {
      var _this4 = this;
      var initialViewport = this.page.getViewport({
        scale: 1
      });
      var scale = this.$refs.container.clientWidth / initialViewport.width;
      this.viewport = this.page.getViewport({
        scale: scale
      });
      var canvas = this.$el.querySelector('canvas');
      canvas.height = this.viewport.height;
      canvas.width = this.viewport.width;
      var renderContext = {
        canvasContext: canvas.getContext('2d'),
        viewport: this.viewport
      };
      this.renderTask = this.page.render(renderContext);
      this.renderTask.promise.then(function () {
        _this4.pageRendering = false;
        if (_this4.pageNumPending !== null) {
          _this4.renderPage(_this4.pageNumPending);
          _this4.pageNumPending = null;
        }
        _this4.isLoaded = true;
      }).catch(function () {
        return _this4.destroyRenderTask();
      });
    },
    destroyRenderTask: function destroyRenderTask() {
      if (!this.renderTask) return;
      this.renderTask.cancel();
    },
    destroyPage: function destroyPage(page) {
      if (page) page._destroy();
      this.destroyRenderTask();
    }
  }
};