import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4e9b8e5e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "mb-10 w-full 2lg:w-3/5"
};
var _hoisted_2 = {
  class: "flex items-center"
};
var _hoisted_3 = {
  class: "my-4 flex items-center"
};
var _hoisted_4 = {
  key: 0,
  class: "w-full mb-8 management-table table-fixed"
};
var _hoisted_5 = {
  key: 0,
  class: "w-1/5 text-left"
};
var _hoisted_6 = {
  class: "flex"
};
var _hoisted_7 = {
  class: "flex"
};
var _hoisted_8 = ["onClick"];
var _hoisted_9 = {
  class: "flex"
};
var _hoisted_10 = ["onUpdate:modelValue", "onChange"];
var _hoisted_11 = ["onClick"];
var _hoisted_12 = {
  class: "ml-4"
};
var _hoisted_13 = ["checked", "disabled", "onChange"];
var _hoisted_14 = {
  class: "flex justify-between"
};
var _hoisted_15 = {
  key: 0,
  class: "w-1/2 pr-2"
};
var _hoisted_16 = {
  class: "text-red-50"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_single_select = _resolveComponent("single-select");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_main_well = _resolveComponent("main-well");
  var _component_risk_admin_modal = _resolveComponent("risk-admin-modal");
  var _component_risk_threat_modal = _resolveComponent("risk-threat-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showConfirmationAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    body: $data.deleteMessageData.body,
    header: $data.deleteMessageData.header,
    "alert-type": "confirmation",
    onAccept: $options.onDeleteConfirm,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showConfirmationAlert = false;
    })
  }, null, 8 /* PROPS */, ["body", "header", "onAccept"])) : _createCommentVNode("v-if", true), _createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
        title: $options.entityMainTitle,
        type: "big"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("a", {
        href: "#",
        class: "flex items-center underline ml-auto",
        onClick: _cache[1] || (_cache[1] = _withModifiers(function () {
          return $options.showImportPopup && $options.showImportPopup.apply($options, arguments);
        }, ["prevent"]))
      }, [_createVNode(_component_feather_icon, {
        class: "text-blue-750",
        icon: "plus"
      }), _createElementVNode("span", null, _toDisplayString($options.importTitle), 1 /* TEXT */)])]), _createElementVNode("p", null, _toDisplayString($options.entityMainDescription), 1 /* TEXT */), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
        title: $data.translate.selectProject,
        class: "min-w-min mr-4",
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_single_select, {
        modelValue: $data.selectedProjectId,
        "onUpdate:modelValue": [_cache[2] || (_cache[2] = function ($event) {
          return $data.selectedProjectId = $event;
        }), $options.createExpandArray],
        options: $options.riskProjects,
        placeholder: $data.translate.selectProject
      }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder", "onUpdate:modelValue"])]), $options.dataset.length ? (_openBlock(), _createElementBlock("table", _hoisted_4, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", {
        class: _normalizeClass([$data.cellCssStyles, $options.isThreat ? 'w-1/3' : 'w-1/2'])
      }, [_createVNode(_component_risma_title, {
        title: $options.rightTitle,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])], 2 /* CLASS */), $options.isThreat ? (_openBlock(), _createElementBlock("th", _hoisted_5, [_createVNode(_component_risma_title, {
        title: $data.translate.category,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $options.isThreatCatalogue ? (_openBlock(), _createElementBlock("th", {
        key: 1,
        class: _normalizeClass($data.cellCssStyles)
      }, [_createVNode(_component_risma_title, {
        title: $data.translate.impact,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])], 2 /* CLASS */)) : _createCommentVNode("v-if", true), $options.isThreatCatalogue ? (_openBlock(), _createElementBlock("th", {
        key: 2,
        class: _normalizeClass($data.cellCssStyles)
      }, [_createVNode(_component_risma_title, {
        title: $data.translate.likelihood,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])], 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createElementVNode("th", {
        class: _normalizeClass([$data.cellCssStyles, 'text-center'])
      }, [_createVNode(_component_risma_title, {
        title: $data.translate.apply,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])], 2 /* CLASS */), _createElementVNode("th", {
        class: _normalizeClass($data.cellCssStyles)
      }, null, 2 /* CLASS */)])]), _createElementVNode("tbody", null, [_createElementVNode("tr", null, [_createElementVNode("td", {
        class: _normalizeClass([$data.cellCssStyles, $data.bottomBorderedItemStyles])
      }, [_createElementVNode("div", _hoisted_6, [_createElementVNode("span", null, _toDisplayString($data.translate.selectAll), 1 /* TEXT */), $options.isThreatCatalogue && $options.getConcequenceParameters($options.dataset[0], $options.selectedProjectIndex) && $options.getConcequenceParameters($options.dataset[0], $options.selectedProjectIndex).length ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "chevron-down",
        class: _normalizeClass(['transform duration-500 text-black cursor-pointer stroke-3 ml-3', {
          'rotate-180': $setup.allExpanded
        }]),
        onClick: $options.expandAll
      }, null, 8 /* PROPS */, ["class", "onClick"])) : _createCommentVNode("v-if", true)])], 2 /* CLASS */), $options.isThreat ? (_openBlock(), _createElementBlock("td", {
        key: 0,
        class: _normalizeClass($data.bottomBorderedItemStyles)
      }, null, 2 /* CLASS */)) : _createCommentVNode("v-if", true), $options.isThreatCatalogue ? (_openBlock(), _createElementBlock("td", {
        key: 1,
        class: _normalizeClass([$data.cellCssStyles, $data.bottomBorderedItemStyles])
      }, null, 2 /* CLASS */)) : _createCommentVNode("v-if", true), $options.isThreatCatalogue ? (_openBlock(), _createElementBlock("td", {
        key: 2,
        class: _normalizeClass([$data.cellCssStyles, $data.bottomBorderedItemStyles])
      }, null, 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createElementVNode("td", {
        class: _normalizeClass([$data.cellCssStyles, 'text-center', $data.bottomBorderedItemStyles])
      }, [_createElementVNode("label", null, [_withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $options.riskProjects[$options.selectedProjectIndex].allSelected = $event;
        }),
        type: "checkbox",
        onChange: _cache[4] || (_cache[4] = function ($event) {
          return $options.handleSelectAll($options.riskProjects[$options.selectedProjectIndex]);
        })
      }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */), [[_vModelCheckbox, $options.riskProjects[$options.selectedProjectIndex].allSelected]])])], 2 /* CLASS */), _createElementVNode("td", {
        class: _normalizeClass([$data.cellCssStyles, $data.bottomBorderedItemStyles, 'text-right'])
      }, [_createElementVNode("button", {
        onClick: _cache[5] || (_cache[5] = function ($event) {
          return $options.deleteEntity('all');
        })
      }, [_createVNode(_component_feather_icon, {
        class: "text-red-50 stroke-2 float-right",
        icon: "x"
      })])], 2 /* CLASS */)])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.dataset, function (field, key) {
        var _$options$getConceque, _$data$expandedArray$, _$options$getConceque2, _$data$expandedArray$2, _$options$getConceque3, _$data$expandedArray$3, _$options$getConceque4, _$data$expandedArray$4, _$options$getConceque5, _$data$expandedArray$5, _$options$getConceque6, _$data$expandedArray$6;
        return _openBlock(), _createElementBlock("tbody", {
          key: key
        }, [_createElementVNode("tr", null, [_createElementVNode("td", {
          class: _normalizeClass([$data.cellCssStyles, !$options.isThreatCatalogue || !((_$options$getConceque = $options.getConcequenceParameters(field, $options.selectedProjectIndex)) !== null && _$options$getConceque !== void 0 && _$options$getConceque.length) || !((_$data$expandedArray$ = $data.expandedArray[key]) !== null && _$data$expandedArray$ !== void 0 && _$data$expandedArray$.isExpanded) ? $data.bottomBorderedItemStyles : ''])
        }, [_createElementVNode("div", _hoisted_7, [_createElementVNode("span", {
          class: _normalizeClass(["underline", $options.isThreat ? 'cursor-pointer' : 'pointer-events-none']),
          onClick: function onClick($event) {
            return $options.showEditModal(field);
          }
        }, _toDisplayString(field.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_8), _createElementVNode("div", null, [$options.getConcequenceParameters(field, $options.selectedProjectIndex) && $options.getConcequenceParameters(field, $options.selectedProjectIndex).length ? (_openBlock(), _createBlock(_component_feather_icon, {
          key: 0,
          icon: "chevron-down",
          class: _normalizeClass(['transform duration-500 text-black cursor-pointer stroke-3 ml-3', {
            'rotate-180': $data.expandedArray[key] && $data.expandedArray[key].isExpanded
          }]),
          onClick: function onClick($event) {
            return $options.switchItem($data.expandedArray, key);
          }
        }, null, 8 /* PROPS */, ["class", "onClick"])) : _createCommentVNode("v-if", true)])])], 2 /* CLASS */), $options.isThreat ? (_openBlock(), _createElementBlock("td", {
          key: 0,
          class: _normalizeClass([$data.cellCssStyles, !$options.isThreatCatalogue || !((_$options$getConceque2 = $options.getConcequenceParameters(field, $options.selectedProjectIndex)) !== null && _$options$getConceque2 !== void 0 && _$options$getConceque2.length) || !((_$data$expandedArray$2 = $data.expandedArray[key]) !== null && _$data$expandedArray$2 !== void 0 && _$data$expandedArray$2.isExpanded) ? $data.bottomBorderedItemStyles : ''])
        }, [_createElementVNode("div", _hoisted_9, _toDisplayString(field.categoryTitle), 1 /* TEXT */)], 2 /* CLASS */)) : _createCommentVNode("v-if", true), $options.isThreatCatalogue ? (_openBlock(), _createElementBlock("td", {
          key: 1,
          class: _normalizeClass([$data.cellCssStyles, !((_$options$getConceque3 = $options.getConcequenceParameters(field, $options.selectedProjectIndex)) !== null && _$options$getConceque3 !== void 0 && _$options$getConceque3.length) || !((_$data$expandedArray$3 = $data.expandedArray[key]) !== null && _$data$expandedArray$3 !== void 0 && _$data$expandedArray$3.isExpanded) ? $data.bottomBorderedItemStyles : ''])
        }, null, 2 /* CLASS */)) : _createCommentVNode("v-if", true), $options.isThreatCatalogue ? (_openBlock(), _createElementBlock("td", {
          key: 2,
          class: _normalizeClass([$data.cellCssStyles, !((_$options$getConceque4 = $options.getConcequenceParameters(field, $options.selectedProjectIndex)) !== null && _$options$getConceque4 !== void 0 && _$options$getConceque4.length) || !((_$data$expandedArray$4 = $data.expandedArray[key]) !== null && _$data$expandedArray$4 !== void 0 && _$data$expandedArray$4.isExpanded) ? $data.bottomBorderedItemStyles : ''])
        }, null, 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createElementVNode("td", {
          class: _normalizeClass([$data.cellCssStyles, 'text-center', !$options.isThreatCatalogue || !((_$options$getConceque5 = $options.getConcequenceParameters(field, $options.selectedProjectIndex)) !== null && _$options$getConceque5 !== void 0 && _$options$getConceque5.length) || !((_$data$expandedArray$5 = $data.expandedArray[key]) !== null && _$data$expandedArray$5 !== void 0 && _$data$expandedArray$5.isExpanded) ? $data.bottomBorderedItemStyles : ''])
        }, [_createElementVNode("label", null, [_withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return field.projects[$options.selectedProjectIndex].isActive = $event;
          },
          type: "checkbox",
          onChange: function onChange($event) {
            return $options.handleChange(field, field.projects[$options.selectedProjectIndex]);
          }
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_10), [[_vModelCheckbox, field.projects[$options.selectedProjectIndex].isActive]])])], 2 /* CLASS */), _createElementVNode("td", {
          class: _normalizeClass([$data.cellCssStyles, !$options.isThreatCatalogue || !((_$options$getConceque6 = $options.getConcequenceParameters(field, $options.selectedProjectIndex)) !== null && _$options$getConceque6 !== void 0 && _$options$getConceque6.length) || !((_$data$expandedArray$6 = $data.expandedArray[key]) !== null && _$data$expandedArray$6 !== void 0 && _$data$expandedArray$6.isExpanded) ? $data.bottomBorderedItemStyles : ''])
        }, [field.id ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: "#",
          onClick: _withModifiers(function ($event) {
            return $options.deleteEntity(field.id, field.objType);
          }, ["prevent"])
        }, [_createVNode(_component_feather_icon, {
          class: "text-red-50 stroke-2 float-right",
          icon: "x"
        })], 8 /* PROPS */, _hoisted_11)) : _createCommentVNode("v-if", true)], 2 /* CLASS */)]), $setup.allExpanded && $data.expandedArray[key] && $data.expandedArray[key].isExpanded && $options.isThreatCatalogue && $options.getConcequenceParameters(field, $options.selectedProjectIndex) ? (_openBlock(true), _createElementBlock(_Fragment, {
          key: 0
        }, _renderList($options.getConcequenceParameters(field, $options.selectedProjectIndex), function (parameter, i) {
          return _openBlock(), _createElementBlock("tr", {
            key: "consequence-parameter-".concat(i)
          }, [_createElementVNode("td", {
            class: _normalizeClass([$data.cellCssStyles, i === $options.getConcequenceParameters(field, $options.selectedProjectIndex).length - 1 ? $data.bottomBorderedItemStyles : ''])
          }, [_createElementVNode("span", _hoisted_12, _toDisplayString(parameter.label), 1 /* TEXT */)], 2 /* CLASS */), _createElementVNode("td", {
            class: _normalizeClass([$data.cellCssStyles, i === $options.getConcequenceParameters(field, $options.selectedProjectIndex).length - 1 ? $data.bottomBorderedItemStyles : ''])
          }, null, 2 /* CLASS */), $options.isThreatCatalogue ? (_openBlock(), _createElementBlock("td", {
            key: 0,
            class: _normalizeClass([$data.cellCssStyles, i === $options.getConcequenceParameters(field, $options.selectedProjectIndex).length - 1 ? $data.bottomBorderedItemStyles : ''])
          }, [_createVNode(_component_single_select, {
            options: $options.impactLevels,
            "model-value": $options.getRightSelect(field, parameter, 'impact'),
            disabled: !field.projects[$options.selectedProjectIndex].isActive || !$options.findRightAssessment(field.assessments, parameter),
            "onUpdate:modelValue": function onUpdateModelValue($event) {
              return $options.onChangeSelect(field, parameter, 'impact', field.projects[$options.selectedProjectIndex], $event);
            }
          }, null, 8 /* PROPS */, ["options", "model-value", "disabled", "onUpdate:modelValue"])], 2 /* CLASS */)) : _createCommentVNode("v-if", true), $options.isThreatCatalogue ? (_openBlock(), _createElementBlock("td", {
            key: 1,
            class: _normalizeClass([$data.cellCssStyles, i === $options.getConcequenceParameters(field, $options.selectedProjectIndex).length - 1 ? $data.bottomBorderedItemStyles : ''])
          }, [_createVNode(_component_single_select, {
            options: $options.likelihoodLevels,
            "model-value": $options.getRightSelect(field, parameter, 'likelihood'),
            disabled: !field.projects[$options.selectedProjectIndex].isActive || !$options.findRightAssessment(field.assessments, parameter),
            "onUpdate:modelValue": function onUpdateModelValue($event) {
              return $options.onChangeSelect(field, parameter, 'likelihood', field.projects[$options.selectedProjectIndex], $event);
            }
          }, null, 8 /* PROPS */, ["options", "model-value", "disabled", "onUpdate:modelValue"])], 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createElementVNode("td", {
            class: _normalizeClass([$data.cellCssStyles, 'text-center', i === $options.getConcequenceParameters(field, $options.selectedProjectIndex).length - 1 ? $data.bottomBorderedItemStyles : ''])
          }, [_createElementVNode("label", null, [_createElementVNode("input", {
            checked: $options.findRightAssessment(field.assessments, parameter),
            type: "checkbox",
            disabled: !field.projects[$options.selectedProjectIndex].isActive,
            onChange: function onChange($event) {
              return $options.handleChangeParams(field, parameter, field.projects[$options.selectedProjectIndex], $event.target.checked);
            }
          }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_13)])], 2 /* CLASS */), _createElementVNode("td", {
            class: _normalizeClass([$data.cellCssStyles, i === $options.getConcequenceParameters(field, $options.selectedProjectIndex).length - 1 ? $data.bottomBorderedItemStyles : ''])
          }, null, 2 /* CLASS */)]);
        }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true)]);
      }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_14, [!$options.isThreat ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createElementVNode("div", null, [_createVNode(_component_input_field, {
        modelValue: $data.newEntity,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
          return $data.newEntity = $event;
        }),
        required: true,
        placeholder: $options.entityPlaceholderText,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "placeholder"])]), _createElementVNode("div", _hoisted_16, _toDisplayString($data.newEntityValidation), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_button, {
        text: $options.createNewEntityButtonText,
        type: "save",
        class: "ml-auto",
        onClick: $options.onCreateEntity
      }, null, 8 /* PROPS */, ["text", "onClick"])])];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_risk_admin_modal, {
    ref: "modal",
    "page-name": $props.pageName,
    onCreated: $options.onModalUpdated
  }, null, 8 /* PROPS */, ["page-name", "onCreated"]), $data.threadSelected ? (_openBlock(), _createBlock(_component_risk_threat_modal, {
    key: 1,
    "is-edit-mode": !!$data.threadSelected.title,
    categories: $props.threatCategories,
    title: $data.threadSelected.title,
    "preselected-category-id": $data.threadSelected.categoryId,
    onCreate: $options.createThreat,
    onUpdate: $options.updateThreat,
    onDismiss: $options.dismissThreatModal,
    "onCreate:category": _cache[7] || (_cache[7] = function ($event) {
      return _ctx.$emit('create:category', $event);
    })
  }, null, 8 /* PROPS */, ["is-edit-mode", "categories", "title", "preselected-category-id", "onCreate", "onUpdate", "onDismiss"])) : _createCommentVNode("v-if", true)]);
}