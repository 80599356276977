function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapActions } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { cacheItem } from '@/routes/storeCache';
import { getTranslate } from './translate';
import * as OrganisationApi from '@/api/admin/organisations';
import Tree from '@/components/Atoms/Tree';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Notification from '@/components/Molecules/Notification';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { AdminNotifications } from '@/constants/admin/AdminNotifications';
import { buildOrganisationTree, getOrganisationLevel, setDisableAddAction, insertOrganisationToTree } from '@/utils/organisations/buildTree';
import { compareStrings } from '@/utils/compare';
import { getLinksModalSettings } from '@/constants/links/LinksModalSettings';
import { RismaSupport } from '@/constants/admin/RismaSupport';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'OrganisationTree',
  components: {
    Notification: Notification,
    Tree: Tree,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    NotificationPopUp: NotificationPopUp,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  setup: function setup() {
    return {
      isSorted: getLocalStorageComputed('org_tree_sort', false, true)
    };
  },
  data: function data() {
    return {
      treeId: 'organisation_tree',
      treeData: {},
      newChild: {},
      notificationError: '',
      notificationSuccess: '',
      successTimer: null,
      timeOut: 0,
      organisationsListTree: [],
      updateTimer: 0,
      organisationsListFlat: [],
      modalSettings: getLinksModalSettings(),
      RismaSupport: RismaSupport,
      isLoaded: false,
      treeKey: 0,
      // fix, so each inc it would remount <tree component, its super slot without this functionality,
      translate: getTranslate['OrganisationTree'](),
      notifications: []
    };
  },
  mounted: function mounted() {
    this.getOrganisationTree();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useUsersStore, {
    loadUsers: 'load'
  })), {}, {
    getOrganisationTree: function getOrganisationTree() {
      var _this = this;
      return OrganisationApi.getAllOrganisations().then(function (response) {
        _this.organisationsListFlat = JSON.parse(JSON.stringify(response.list));
        _this.organisationsListTree = buildOrganisationTree(response.list);
        _this.setTreeData(_this.organisationsListTree);
      }).catch(function () {
        return _this.treeData = {};
      }).finally(function () {
        return _this.isLoaded = true;
      });
    },
    addChild: function addChild(node) {
      var _this2 = this;
      var params = {
        name: node.title
      };
      if (node.appendTo !== undefined) {
        params.parent = node.appendTo;
      }
      OrganisationApi.createOrganisation(params).then(function (response) {
        var orgLevel = getOrganisationLevel(response);
        setDisableAddAction(response, orgLevel);
        var newNode = response;
        newNode.title = response.name;
        newNode.checkaccess = true;
        newNode.children = [];
        _this2.organisationsListFlat.push(newNode);
        if (node.appendTo !== undefined) {
          _this2.onAddNewChild(newNode);
        } else {
          _this2.onAddNewChildToRoot(newNode);
        }
        _this2.setNotification(_this2.translate.theOrganisationHasBeenCreated, 'success', newNode.title);
      }).catch(function (error) {
        var _this2$$refs;
        _this2.setNotification(_this2.$trans(AdminNotifications.CREATE_ORGANISATION_ERROR));
        if ((_this2$$refs = _this2.$refs) !== null && _this2$$refs !== void 0 && _this2$$refs.tree) _this2.$refs.tree.loading = false;
        throw error;
      });
    },
    onAddNewChild: function onAddNewChild(newNode) {
      var _parent$children;
      var sibling = {};
      var parent = this.getParentOrg(newNode, this.treeData.children);
      if (!((_parent$children = parent.children) !== null && _parent$children !== void 0 && _parent$children.length) || !this.isSorted) {
        parent.children = [newNode];
        return this.newChild = newNode;
      }
      insertOrganisationToTree(newNode, parent.children, 0, parent.children.length - 1, sibling);
      this.treeKey += 1;
    },
    onAddNewChildToRoot: function onAddNewChildToRoot(newNode) {
      var sibling = {};
      if (this.isSorted) {
        insertOrganisationToTree(newNode, this.treeData.children, 0, this.treeData.children.length - 1, sibling);
      } else {
        sibling.id = this.treeData.children[this.treeData.children.length - 1].id;
        this.treeData.children.push(newNode);
      }
      this.treeKey += 1;
    },
    updateNode: function updateNode(params) {
      var _this3 = this;
      return OrganisationApi.updateOrganisational(params.nodeId, {
        name: params.title
      }).then(function (response) {
        var sibling = {};
        var parent = _this3.getParentOrg(response, _this3.treeData.children) || _this3.treeData;
        var oldNodeIndex = parent.children.findIndex(function (item) {
          return item.id !== response.id;
        });
        var node = _objectSpread(_objectSpread(_objectSpread({}, parent.children[oldNodeIndex - 1]), response), {}, {
          title: response.name
        });
        _this3.updateOrgListFlat(response);
        if (node && node.id && _this3.isSorted) {
          parent.children.splice(oldNodeIndex - 1, 1);
          insertOrganisationToTree(node, parent.children, 0, parent.children.length - 1, sibling);
          if (sibling.id) {
            return _this3.$refs.tree.moveNode(node.id, sibling.id);
          } else {
            return _this3.$refs.tree.moveNode(node.id, parent.children[0].id, 'before');
          }
        } else {
          parent.children.splice(oldNodeIndex - 1, 1, node);
        }
      }).catch(function (error) {
        var _this3$$refs;
        _this3.setNotification(_this3.$trans(AdminNotifications.CREATE_ORGANISATION_ERROR));
        if ((_this3$$refs = _this3.$refs) !== null && _this3$$refs !== void 0 && _this3$$refs.tree) _this3.$refs.tree.loading = false;
        throw error;
      });
    },
    getOrganisationActivities: function getOrganisationActivities(id) {
      return OrganisationApi.getOrganisationActivities(id);
    },
    getDeleteErrorMessage: function getDeleteErrorMessage(activities) {
      var _this4 = this;
      var message = '';
      Object.keys(activities).forEach(function (modal) {
        if (_this4.modalSettings[modal] && activities[modal].length) {
          message += "\n\n".concat(_this4.modalSettings[modal].label, ":\n");
          var modalList = activities[modal].map(function (item) {
            return "".concat(item.path ? item.path + '-' : '', " ").concat(item.title);
          });
          message += "".concat(modalList.join('\n'));
        }
      });
      var result = "".concat(this.translate.thisOrganisationHaveBeenRemovedForAllActivi, ": ").concat(message, "\n\n                ").concat(this.translate.theOrganisationCannotBeRemovedAutomatically, "\n                ").concat(this.translate.pleaseContactRismaSupportForAssistance, ": ") + "<a class=\"hover:underline\" href=\"mailto:".concat(this.RismaSupport.email, "\">").concat(this.RismaSupport.email, "</a> / <a class=\"hover:underline\" href=\"tel:").concat(this.RismaSupport.tel, "\">").concat(this.RismaSupport.telTitle, "</a>");
      return result;
    },
    deleteOrganisation: function deleteOrganisation(_ref) {
      var _this5 = this;
      var nodeId = _ref.nodeId;
      this.notificationError = '';
      return OrganisationApi.deleteOrganisation(nodeId).then(function (response) {
        var parentOrg = _this5.getParentOrg(response, _this5.treeData.children) || _this5.treeData;
        parentOrg.children = parentOrg.children.filter(function (node) {
          return node.id !== nodeId;
        });
        _this5.organisationsListFlat = _this5.organisationsListFlat.filter(function (node) {
          return node.id !== nodeId;
        });
        _this5.showDeleteNotification();
        cacheItem('users', function () {
          return _this5.loadUsers();
        }, 180);
      }).catch(function (error) {
        if (error.response.error === AdminNotifications.SERVER_DELETE_ERROR) {
          _this5.notificationError = _this5.$trans(AdminNotifications.DELETE_ORGANISATION_ERROR);
        } else if (error.response.error === AdminNotifications.SERVER_DELETE_ORG_ERROR) {
          _this5.getErrorNotificationByActivities(nodeId).then(function (message) {
            return _this5.notificationError = message;
          });
        } else {
          _this5.notificationError = _this5.$trans(AdminNotifications.DELETE_ORGANISATION_IN_USE_ERROR);
        }
        window.scrollTo(0, 0);
        return Promise.reject(error.response.error);
      });
    },
    getErrorNotificationByActivities: function getErrorNotificationByActivities(orgId) {
      var _this6 = this;
      return this.getOrganisationActivities(orgId).then(function (organisation) {
        return _this6.getDeleteErrorMessage(organisation);
      });
    },
    updateOrgListFlat: function updateOrgListFlat(organisation) {
      var _this7 = this;
      var oldNode = {};
      this.organisationsListFlat.forEach(function (item, index) {
        if (+item.id === +organisation.id) {
          oldNode = _objectSpread({}, item);
          _this7.organisationsListFlat[index] = _objectSpread(_objectSpread(_objectSpread({}, item), organisation), {}, {
            title: organisation.name
          });
        }
        if (oldNode.visible_name) {
          if (item.visible_name.startsWith(oldNode.visible_name)) {
            item.visible_name = item.visible_name.replace(oldNode.visible_name, organisation.visible_name);
            item.visible_name.split(' > ').forEach(function (name, index) {
              return item["level".concat(index + 1)] = name;
            });
          }
        }
      });
    },
    getParentOrg: function getParentOrg(org, orgArray) {
      var names = org.visible_name.split(' > ');
      names.splice(-1);
      var parent;
      names.forEach(function (name, index) {
        parent = orgArray.find(function (item) {
          return item.name === name;
        });
        if (parent) {
          if (names[index + 1]) {
            orgArray = parent.children;
          }
        }
      });
      return parent;
    },
    addChildOnNode: function addChildOnNode() {
      this.$refs.tree.openCreateNodeModal();
      var treeContainer = this.$el.querySelector('#tree-jquery');
      treeContainer.scrollTop = treeContainer.scrollHeight;
    },
    hideErrorNotification: function hideErrorNotification() {
      var _this8 = this;
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(function () {
        return _this8.notificationError = '';
      }, 3000);
    },
    sortByAlphabet: function sortByAlphabet() {
      this.setUpdateTimer();
    },
    setUpdateTimer: function setUpdateTimer() {
      var _this9 = this;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(function () {
        _this9.organisationsListTree = buildOrganisationTree(_this9.organisationsListFlat);
        _this9.setTreeData(_this9.organisationsListTree);
      }, 300);
    },
    sortTree: function sortTree(array) {
      var _this10 = this;
      if (!array.length) return;
      array.forEach(function (item) {
        if (item.children && item.children.length) {
          item.children = _this10.sortTree(item.children);
        }
      });
      array.sort(function (a, b) {
        return compareStrings(a.name.toLowerCase(), b.name.toLowerCase());
      });
      return array;
    },
    setTreeData: function setTreeData(list) {
      var newList = _toConsumableArray(list);
      if (this.isSorted) {
        this.sortTree(newList);
      }
      this.treeData = {
        children: newList
      };
    },
    showDeleteNotification: function showDeleteNotification() {
      var _this11 = this;
      clearTimeout(this.successTimer);
      this.notificationSuccess = this.translate.theOrganisationWasSuccessfullyDeleted;
      this.successTimer = setTimeout(function () {
        _this11.notificationSuccess = '';
      }, 5000);
    },
    setNotification: function setNotification(message) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      var organisationName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      this.notifications.push({
        notificationText: message,
        notificationType: type,
        organisationName: organisationName
      });
    },
    dismissNotification: function dismissNotification(index) {
      this.notifications.splice(index, 1);
    }
  })
};