import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-f9446fb8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "risma-print relative"
};
var _hoisted_2 = {
  class: "print-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showConfirmationAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    body: $props.confirmMessage,
    "alert-type": "confirmation",
    onAccept: $options.exportData,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showConfirmationAlert = false;
    })
  }, null, 8 /* PROPS */, ["body", "onAccept"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: "print-label flex text-blue-750",
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.confirmExport && $options.confirmExport.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "printer"
  }), _createElementVNode("span", _hoisted_2, _toDisplayString($options.printButtonLabel), 1 /* TEXT */)])]);
}