import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "user-rights-admin w-full"
};
var _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");
  var _component_notification_pop_up = _resolveComponent("notification-pop-up");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.notifications, function (notification, index) {
    return _openBlock(), _createBlock(_component_notification_pop_up, {
      key: notification,
      type: notification.type,
      onDismiss: function onDismiss($event) {
        return $options.dismissNotification(index);
      }
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("div", null, _toDisplayString(notification.header || ''), 1 /* TEXT */), notification.url ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: notification.url
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(notification.message), 1 /* TEXT */)];
          }),

          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])) : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(notification.message || ''), 1 /* TEXT */))];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["type", "onDismiss"]);
  }), 128 /* KEYED_FRAGMENT */)), _createVNode(_component_router_view, null, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        onShowNotification: $options.handleShowNotification
      }, null, 8 /* PROPS */, ["onShowNotification"]))];
    }),
    _: 1 /* STABLE */
  })]);
}