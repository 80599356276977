function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import FilesList from '@/components/Atoms/FilesList';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
import { isValidString, isValidAsUrlAndLocalPath } from '@/utils/validations';
import { ATTACHMENT_MODAL_MODES } from '@/constants/Attachments';
export default {
  name: 'AttachmentModal',
  introduction: 'A modal, that handles attachments',
  description: "this organism is derived from the modal molecule.\n    It's designed to handle attachment for various projects",
  token: '<attachment-modal :show-footer-buttons="false" @upload="habdlerFn(fileObject)" />',
  components: {
    RismaFileUpload: RismaFileUpload,
    RismaTitle: RismaTitle,
    InputField: InputField,
    Modal: Modal,
    Notification: Notification,
    FilesList: FilesList,
    FeatherIcon: FeatherIcon,
    RismaSelect: RismaSelect
  },
  props: {
    documentTypeId: {
      type: Number,
      default: null,
      required: false,
      note: 'default selected document type id'
    },
    serverErrorMessage: {
      type: String,
      required: false,
      default: '',
      note: 'error message what shows in attach file section'
    },
    singleFileLoad: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Loading only one file, on reload will replace the file'
    },
    hasPin: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Has pin/unpin functionality'
    },
    pinnedByDefault: {
      type: Boolean,
      default: false,
      required: false,
      note: 'is document pinned by default'
    },
    hasTitle: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Has title field'
    },
    typeOptions: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: false,
      note: 'List of types if any'
    },
    isApplyDisabled: {
      type: Boolean,
      default: false,
      required: false
    },
    modalMode: {
      type: String,
      default: ATTACHMENT_MODAL_MODES.FULL,
      required: false,
      validator: function validator(mode) {
        return Object.values(ATTACHMENT_MODAL_MODES).indexOf(mode) !== -1;
      }
    }
  },
  emits: ['dismiss', 'upload'],
  data: function data() {
    return {
      isLinkSaveFail: false,
      linkURL: '',
      linkTitle: '',
      existingUrls: [],
      files: [],
      attachFileErrorMessage: '',
      documentTypes: [],
      pinned: this.pinnedByDefault,
      ATTACHMENT_MODAL_MODES: ATTACHMENT_MODAL_MODES,
      translate: getTranslate['AttachmentModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    attachmentsAllowed: function attachmentsAllowed() {
      if (this.getSettingValue('risma.attachments_allowed')) {
        return Object.keys(this.getSettingValue('risma.attachments_allowed')).join(', ');
      } else {
        return '';
      }
    },
    fileErrorMessage: function fileErrorMessage() {
      return this.attachFileErrorMessage || this.serverErrorMessage;
    },
    fileEnabled: function fileEnabled() {
      return this.modalMode === this.ATTACHMENT_MODAL_MODES.FULL || this.modalMode === this.ATTACHMENT_MODAL_MODES.FILE;
    },
    linkEnabled: function linkEnabled() {
      return this.modalMode === this.ATTACHMENT_MODAL_MODES.FULL || this.modalMode === this.ATTACHMENT_MODAL_MODES.LINK;
    }
  }),
  watch: {
    files: {
      deep: true,
      handler: function handler() {
        var errorLoad = this.files.find(function (item) {
          return item.errorExtension;
        });
        if (errorLoad) {
          this.attachFileErrorMessage = "".concat(this.translate.invalidFileTypePleaseUploadAnyOfTheseFile, ": ").concat(this.attachmentsAllowed);
        } else {
          this.attachFileErrorMessage = '';
        }
      }
    }
  },
  methods: {
    toggleModal: function toggleModal() {
      this.$emit('dismiss');
    },
    isLinkDataValid: function isLinkDataValid(url, title) {
      return isValidAsUrlAndLocalPath(url) && isValidString(title);
    },
    fileUploaded: function fileUploaded(files) {
      var _this = this;
      if (this.singleFileLoad && files.length > 1) {
        this.files = [];
        this.$nextTick(function () {
          _this.attachFileErrorMessage = "".concat(_this.translate.uploadIsLimitedToOneFileAtATime);
        });
        return;
      }
      this.attachFileErrorMessage = '';
      this.files = files.map(function (file) {
        return _objectSpread(_objectSpread({}, file), {}, {
          title: file.filename.split('.').slice(0, -1).join('.')
        });
      });
    },
    onApply: function onApply() {
      this.attachFileErrorMessage = '';
      this.isLinkSaveFail = false;
      var _ref = [this.files.length, this.linkURL.length, this.linkTitle.length],
        fileLength = _ref[0],
        linkUrlLength = _ref[1],
        linkTitleLength = _ref[2];
      var files = this.files.filter(function (file) {
        return !file.errorExtension;
      });
      if ([fileLength, linkUrlLength, linkTitleLength].every(function (item) {
        return !item;
      })) {
        this.attachFileErrorMessage = this.fileEnabled ? this.translate.fileHasntBeenUploaded : this.translate.linkHasntBeenAdded;
        return;
      }
      var uploaded = [];
      if ([linkUrlLength, linkTitleLength].some(function (item) {
        return item;
      })) {
        if (this.isLinkDataValid(this.linkURL, this.linkTitle)) {
          uploaded.push({
            filename: this.linkTitle,
            url: this.linkURL
          });
        } else {
          this.isLinkSaveFail = true;
        }
      }
      if (files.length && this.hasPin) files[0].pinned = this.pinned;
      if (files.length && this.typeOptions.length) {
        files[0].docTypeIds = this.documentTypes.map(function (item) {
          return item.id;
        });
      }
      if (!this.isLinkSaveFail) {
        files.forEach(function (item) {
          return item.title = item.title || item.filename;
        });
        this.$emit('upload', [].concat(_toConsumableArray(files), uploaded));
      }
    }
  }
};