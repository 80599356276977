import RismaUserSelector from '@/components/Molecules/UserSelector';
export default {
  name: '',
  components: {
    RismaUserSelector: RismaUserSelector
  },
  data: function data() {
    return {
      rismaUserSelectorUsers: [{
        display_name: 'AAN',
        email: 'aan@risma.dk',
        fullname: 'Arne Andersen',
        id: 45,
        initials: 'AAN',
        is_admin: false,
        lang: null,
        level_controls: 1,
        level_execution: 1,
        level_risk: 1,
        objType: 'user',
        organisations: [179],
        organisationsReadonly: [],
        orgs: ',179,',
        policiesAccess: false,
        projects: [{
          access_level: 0,
          id: 1,
          objType: 'compliance-project',
          questionnaireType: 1,
          reports: {
            'article-30': {
              header: 'Article 30',
              predefinedSearch: 'Article30',
              questionnaireType: 'GDPR',
              table: 'article30'
            }
          },
          supervisor: 0,
          title: 'GDPR',
          type: 1
        }],
        username: 'AAN'
      }, {
        display_name: 'AAN2',
        email: 'aan2@risma.dk',
        fullname: 'Arne Andersen 2',
        id: 46,
        initials: 'AAN2',
        is_admin: false,
        lang: null,
        level_controls: 1,
        level_execution: 1,
        level_risk: 1,
        objType: 'user',
        organisations: [179],
        organisationsReadonly: [],
        orgs: ',179,',
        policiesAccess: false,
        projects: [{
          access_level: 0,
          id: 1,
          objType: 'compliance-project',
          questionnaireType: 1,
          reports: {
            'article-30': {
              header: 'Article 30',
              predefinedSearch: 'Article30',
              questionnaireType: 'GDPR',
              table: 'article30'
            }
          },
          supervisor: 0,
          title: 'GDPR',
          type: 1
        }],
        username: 'AAN'
      }],
      rismaUserSelectorSelected: [],
      dailyTeamOptions: [{
        label: 'Organisations',
        items: [{
          _name: 'Accounting',
          groups: null,
          id: 177,
          level1: 'Finance and administration',
          level2: 'Accounting',
          level3: null,
          objType: 'organisation',
          visible_name: 'Accounting'
        }, {
          _name: 'CSR Team',
          groups: null,
          id: 172,
          level1: 'CSR Team',
          level2: null,
          level3: null,
          objType: 'organisation',
          visible_name: 'CSR Team'
        }]
      }, {
        label: 'Users',
        items: [{
          _name: 'AAN',
          display_name: 'AAN',
          email: 'aan2@risma.dk',
          fullname: 'Arne Andersen',
          id: 45,
          initials: 'AAN'
        }, {
          _name: 'ADM',
          display_name: 'ADM',
          email: 'template@rismasystems.com',
          fullname: 'BusinessDemo Template',
          id: 1,
          initials: 'NAS'
        }]
      }],
      dailyTeamSelectedOptions: [{
        _name: 'ADM',
        display_name: 'ADM',
        email: 'template@rismasystems.com',
        fullname: 'BusinessDemo Template',
        id: 1,
        initials: 'NAS'
      }]
    };
  }
};