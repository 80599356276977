import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1b5778fb"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "risk-grid"
};
var _hoisted_2 = {
  key: 0,
  class: "zoom-actions"
};
var _hoisted_3 = {
  class: "current-zoom"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_matrix = _resolveComponent("matrix");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.enableZoom ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString($data.currentZoomPercentage) + " %", 1 /* TEXT */), _createVNode(_component_risma_button, {
    class: _normalizeClass({
      '_active': $data.currentZoomPercentage === $data.minZoomPercentage
    }),
    text: "-",
    type: "util",
    onClick: $options.decreaseSize
  }, null, 8 /* PROPS */, ["class", "onClick"]), _createVNode(_component_risma_button, {
    class: _normalizeClass({
      '_active': $data.currentZoomPercentage === $data.maxZoomPercentage
    }),
    text: "+",
    type: "util",
    onClick: $options.increaseSize
  }, null, 8 /* PROPS */, ["class", "onClick"]), _createVNode(_component_risma_button, {
    class: _normalizeClass({
      '_active': $data.currentZoomPercentage === 100
    }),
    text: $data.translate.reset,
    type: "util",
    onClick: $options.restoreSize
  }, null, 8 /* PROPS */, ["class", "text", "onClick"])])) : _createCommentVNode("v-if", true), _createVNode(_component_matrix, {
    ref: "matrix",
    matrix: $data.grid,
    "color-codes": $props.riskGridData.colorCodes,
    "matrix-color": $props.colorDirection,
    labels: $options.computedLabels,
    size: $data.gridBoxSizeUser,
    checked: $data.checked,
    periods: $props.periods,
    "show-previous-position-label": $props.showPreviousPositionLabel,
    "zoom-range": $props.zoomRange,
    "zoom-step": $props.zoomStep,
    "enable-prev-position": $props.enablePrevPosition,
    "is-matrix-clickable": $props.isGridClickable,
    "dot-label-hover-function": $props.dotLabelHoverFunction,
    "start-color": $props.startColor,
    "end-color": $props.endColor,
    "custom-heatmap-options": $props.customHeatmapOptions,
    "dot-connector-enabled": $props.dotConnectorEnabled,
    onZoom: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('zoom', $event);
    }),
    onOnPeriodChanged: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('onPeriodChanged', $event);
    }),
    onOnStateChanged: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('onStateChanged', $event);
    })
  }, null, 8 /* PROPS */, ["matrix", "color-codes", "matrix-color", "labels", "size", "checked", "periods", "show-previous-position-label", "zoom-range", "zoom-step", "enable-prev-position", "is-matrix-clickable", "dot-label-hover-function", "start-color", "end-color", "custom-heatmap-options", "dot-connector-enabled"])]);
}