function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import * as Cookie from 'tiny-cookie';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import RismaExcel from '@/components/Atoms/Excel/RismaExcel';
import RismaSelectorBar from '@/components/Molecules/RismaSelectorBar';
import RismaBarChart from '@/components/Molecules/Charts/RismaBarChart';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import Notification from '@/components/Molecules/Notification';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import GapTable from '@/components/Organisms/Compliance/Dashboard/GapTable';
import { RismaSelectorBarMixin } from '@/mixins/RismaSelectorBarMixin';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { ComplianceDashboardMixin } from '@/components/Pages/Compliance/ComplianceDashboardMixin';
export default {
  name: 'GapDashboard',
  components: {
    PreviewModalLink: PreviewModalLink,
    GapTable: GapTable,
    RismaSelectorBar: RismaSelectorBar,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    FeatherIcon: FeatherIcon,
    Trafficlight: Trafficlight,
    RismaExcel: RismaExcel,
    Notification: Notification,
    RismaDoughnutChart: RismaDoughnutChart,
    RismaBarChart: RismaBarChart
  },
  mixins: [RaciTitleMixin, ComplianceDashboardMixin, RismaSelectorBarMixin],
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Project id from route'
    },
    stripped: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      gapByDomain: [],
      domains: [],
      sliceSelected: {},
      localStorageKey: "gap_dashboard_filters_".concat(this.projectId),
      gapFilter: [1, 2, 3, 4, 5],
      exportFileName: 'General GAP summary',
      updatedInPreview: false,
      translate: getTranslate['GapDashboard']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, {
    currentUserComplianceProjectById: 'complianceProjectById',
    currentUserShowGapByProjectId: 'showGapByProjectId',
    isAdmin: 'isAdmin'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'complianceActivityTypes'
  })), {}, {
    filteredActivityTypeIds: function filteredActivityTypeIds() {
      return this.activityTypes.reduce(function (list, activityType) {
        var _activityType$optiona;
        if ((activityType === null || activityType === void 0 || (_activityType$optiona = activityType.optionalFields) === null || _activityType$optiona === void 0 ? void 0 : _activityType$optiona.indexOf('gap_schema')) !== -1) list.push(activityType.id);
        return list;
      }, []);
    },
    userHasGapSchemaAccess: function userHasGapSchemaAccess() {
      return this.isAdmin || this.currentUserShowGapByProjectId(this.currentProject.id);
    },
    currentProject: function currentProject() {
      return this.currentUserComplianceProjectById(this.projectId);
    },
    enableGapRiskModule: function enableGapRiskModule() {
      return !!this.currentProject.enableGapRiskModule;
    },
    showRiskAssessment: function showRiskAssessment() {
      return !!this.currentProject.showRiskAssessment;
    },
    showGapArticleTypes: function showGapArticleTypes() {
      return !!this.currentProject.showGapArticleTypes;
    },
    exportColumns: function exportColumns() {
      return {
        'businessarea': this.translate.businessArea,
        'questionnaire': this.translate.questionnaire,
        'assessments': this.translate.totalNumberOfAssessments,
        'notset': this.translate.notSet,
        'nogap': this.translate.noGap,
        'gaplow': this.translate.gapLow,
        'gapmeduim': this.translate.gapMedium,
        'gaphigh': this.translate.gapHigh,
        'gapna': this.translate.na
      };
    },
    selectors: function selectors() {
      return [{
        list: 'nodes',
        label: this.translate.nodes,
        preSelected: this.predefinedNodesFilter,
        toggle: {
          title: this.translate.includeUnderlyingNodes,
          option: this.includeChildToggleOption,
          event: this.onIncludeChildNodesToggle
        }
      }, {
        list: 'organisations',
        label: this.translate.organisations,
        preSelected: this.predefinedOrganisationsFilter
      }, {
        list: 'users',
        label: this.responsibleTitle(true),
        preSelected: this.predefinedUsersFilter
      }, {
        list: 'tags',
        label: this.translate.tags,
        preSelected: this.predefinedTagsFilter
      }];
    },
    filteredNodes: function filteredNodes() {
      var _this = this;
      var result = this.nodes;

      // Filter nodes that don't have the gap tab visible (via activity type) and don't have gap schema
      result = result.filter(function (item) {
        return item.gapSchemaId && (item.activityTypeId ? _this.filteredActivityTypeIds.includes(item.activityTypeId) : _this.userHasGapSchemaAccess);
      });
      if (this.selectedNodeIds && this.selectedNodeIds.length) {
        var selectedNodeIdsWithChild = [].concat(_toConsumableArray(this.selectedNodeIds), _toConsumableArray(this.childNodeIds));
        result = result.filter(function (node) {
          return selectedNodeIdsWithChild.includes(node.id);
        });
      }
      if (this.selectedOrganisationIds && this.selectedOrganisationIds.length) {
        result = result.filter(function (node) {
          return node.organisationIds.find(function (id) {
            return _this.selectedOrganisationIds.includes(id);
          });
        });
      }
      if (this.selectedUserIds && this.selectedUserIds.length) {
        result = result.filter(function (node) {
          return node.responsibleUserIds.find(function (id) {
            return _this.selectedUserIds.includes(id);
          });
        });
      }
      if (this.selectedTagIds && this.selectedTagIds.length) {
        result = result.filter(function (node) {
          return node.tagIds.find(function (id) {
            return _this.selectedTagIds.includes(id);
          });
        });
      }
      return result;
    },
    exportDataset: function exportDataset() {
      return this.prepareExportDataset(this.filteredNodes);
    },
    filteredNodesTotals: function filteredNodesTotals() {
      var result = [0, 0, 0, 0, 0, 0, 0];
      this.filteredNodes.forEach(function (node) {
        result[0] += node.values.gap_records_count;
        result[1] += parseInt(node.values.gap_records_not_set);
        result[2] += parseInt(node.values.gap_records_no_gap);
        result[3] += parseInt(node.values.gap_records_low_gap);
        result[4] += parseInt(node.values.gap_records_medium_gap);
        result[5] += parseInt(node.values.gap_records_high_gap);
        result[6] += parseInt(node.values.gap_records_na);
      });
      return result;
    }
  }),
  mounted: function mounted() {
    Cookie.set('lastTab-' + this.projectId, 'gap', {
      samesite: 'strict'
    });
  },
  methods: {
    prepareExportDataset: function prepareExportDataset(nodes) {
      return nodes.map(function (node) {
        return {
          'businessarea': node.title,
          'questionnaire': node.questionnaire ? node.questionnaire.title : 'N/A',
          'assessments': node.values.gap_records_count,
          'notset': node.values.gap_records_not_set,
          'nogap': node.values.gap_records_no_gap,
          'gaplow': node.values.gap_records_low_gap,
          'gapmeduim': node.values.gap_records_medium_gap,
          'gaphigh': node.values.gap_records_high_gap,
          'gapna': node.values.gap_records_na
        };
      });
    },
    loadData: function loadData() {
      var _this2 = this;
      return this.getGapSchemas().catch(function (error) {
        return _this2.handleDashboardError(error);
      });
    },
    loadDashboardData: function loadDashboardData(filterString) {
      var _this3 = this;
      this.loadingStats = true;
      return this.getGapDashboard(filterString).then(function () {
        return _this3.loadingStats = false;
      });
    },
    onDismissPreview: function onDismissPreview() {
      if (this.updatedInPreview) {
        this.fetchData(localStorage.getItem(this.localStorageKey));
        this.updatedInPreview = false;
      }
    }
  }
};