function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { flushControlAssociationsCache } from '@/api/risma/data';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import RismaTags from '@/components/Molecules/Tags/RismaTags';
import { getAssociationName, getAssociationPlaceholder } from '@/utils/controls/associations';
export default {
  name: 'ControlDetailsAssociations',
  components: {
    RismaTitle: RismaTitle,
    RismaSelect: RismaSelect,
    RismaTags: RismaTags
  },
  props: {
    accounts: {
      type: Object,
      required: true
    },
    processes: {
      type: Object,
      required: true
    },
    risks: {
      type: Object,
      required: true
    },
    isDaughter: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['changed'],
  data: function data() {
    return {
      data: {
        accounts: [],
        processes: [],
        risks: []
      },
      dataAsTags: {
        accounts: [],
        processes: [],
        risks: []
      },
      labelKey: 'title',
      unSorted: true,
      translate: {
        associations: this.$trans('Associations'),
        valuesAreInheritedFromTheMotherControl: this.$trans('Values are inherited from the Mother Control')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    accountTitle: function accountTitle() {
      return getAssociationName(this.getSettingValue('risma.account_to_name'), 'Accounts');
    },
    accountPlaceholder: function accountPlaceholder() {
      return getAssociationPlaceholder(this.getSettingValue('risma.account_to_name'), 'accounts');
    },
    processesTitle: function processesTitle() {
      return getAssociationName(this.getSettingValue('risma.process_to_name'), 'Processes');
    },
    processesPlaceholder: function processesPlaceholder() {
      return getAssociationPlaceholder(this.getSettingValue('risma.process_to_name'), 'processes');
    },
    risksTitle: function risksTitle() {
      return getAssociationName(this.getSettingValue('risma.risk_to_name'), 'Control risks');
    },
    risksPlaceholder: function risksPlaceholder() {
      return getAssociationPlaceholder(this.getSettingValue('risma.risk_to_name'), 'control risks');
    },
    associationsTitle: function associationsTitle() {
      if (this.isDaughter) {
        return this.translate.associations + ' (' + this.translate.valuesAreInheritedFromTheMotherControl + ')';
      } else {
        return this.translate.associations;
      }
    },
    inputsDisabled: function inputsDisabled() {
      return this.isDaughter || this.disabled;
    }
  }),
  mounted: function mounted() {
    for (var key in this.data) {
      this.copyInitialValues(key);
      this.prepareTags(key);
    }
  },
  methods: {
    copyInitialValues: function copyInitialValues(type) {
      var _this = this;
      this[type].selectedItems.forEach(function (el) {
        _this.data[type].push(Object.assign({}, el));
      });
    },
    prepareTags: function prepareTags(type) {
      this.dataAsTags[type] = this.data[type].map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          name: item.title,
          url: ''
        });
      });
    },
    handleSelected: function handleSelected(field, e) {
      flushControlAssociationsCache();
      this.data[field] = e ? [].concat(e) : [];
      this.$emit('changed', this.data);
    }
  }
};