import { getTranslate } from './translate';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import PreviewRiskExpanded from '@/components/Atoms/Preview/PreviewRiskExpanded';
import MyRisksList from '@/components/Molecules/Risk/MyRisksList';
import MyProcessLibraryList from '@/components/Molecules/ProcessLibrary/MyProcessLibraryList';
import MyProcessesList from '@/components/Molecules/Processes/MyProcessesList';
import MyInformationAssetsList from '@/components/Molecules/InformationAssets/MyInformationAssetsList';
import MyIncidentsList from '@/components/Molecules/Incidents/MyIncidentsList';
import PreviewListExpanded from '@/components/Molecules/PreviewList/PreviewListExpanded';
import CardControl from '@/components/Organisms/General/Cards/CardControl';
export default {
  name: 'SearchModal',
  introduction: 'SearchModal is the modal to show search results',
  description: 'this component is used on user-menu component',
  token: "<search-modal\n            v-if=\"showSearchModal\"\n            @toggle=\"toggleSearch\"/>",
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    MainWell: MainWell,
    PreviewRiskExpanded: PreviewRiskExpanded,
    CardControl: CardControl,
    MyRisksList: MyRisksList,
    MyProcessLibraryList: MyProcessLibraryList,
    MyProcessesList: MyProcessesList,
    MyInformationAssetsList: MyInformationAssetsList,
    MyIncidentsList: MyIncidentsList,
    PreviewListExpanded: PreviewListExpanded
  },
  props: {
    data: {
      required: false,
      type: Object,
      default: function _default() {},
      note: 'search data'
    },
    scoring: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Scoring period for ERM risks'
    },
    organisations: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma organisations'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of users'
    },
    currentUser: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'current user'
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of activity types (systems)'
    },
    workflowStatuses: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of workflow statuses for contracts'
    }
  },
  emits: ['toggle'],
  data: function data() {
    return {
      oneLineItems: 'flex items-center',
      translate: getTranslate['SearchModal']()
    };
  },
  computed: {
    searchResultLength: function searchResultLength() {
      var _this = this;
      var sum = 0;
      Object.values(this.loopData).forEach(function (iterationItem) {
        if (_this.data[iterationItem.dataProperty] && _this.data[iterationItem.dataProperty].length) {
          sum += _this.data[iterationItem.dataProperty].length;
        }
      });
      return sum;
    },
    loopData: function loopData() {
      return {
        initiatives: {
          dataProperty: 'initiatives',
          component: 'PreviewListExpanded',
          props: {
            title: this.translate.initiatives,
            activities: this.data.initiatives || []
          }
        },
        controls: {
          dataProperty: 'controls',
          component: 'PreviewListExpanded',
          props: {
            activities: this.data.controls || [],
            title: this.translate.controls
          }
        },
        risks: {
          dataProperty: 'risks',
          component: 'MyRisksList',
          props: {
            scoring: this.scoring,
            risks: this.data.risks || [],
            title: this.translate.risks
          }
        },
        processTreeNodes: {
          dataProperty: 'processTreeNodes',
          component: 'MyProcessLibraryList',
          props: {
            user: this.currentUser,
            processTreeNodes: this.data.processTreeNodes || []
          }
        },
        processes: {
          dataProperty: 'processes',
          component: 'MyProcessesList',
          props: {
            title: this.translate.processes,
            processes: this.data.processes || []
          }
        },
        informationAssets: {
          dataProperty: 'informationAssets',
          component: 'MyInformationAssetsList',
          props: {
            informationAssets: this.data.informationAssets || [],
            activityTypes: this.activityTypes
          }
        },
        incidents: {
          dataProperty: 'incidents',
          component: 'PreviewListExpanded',
          props: {
            title: this.translate.incidents,
            activities: this.data.incidents || []
          }
        },
        contracts: {
          dataProperty: 'contracts',
          component: 'PreviewListExpanded',
          props: {
            title: this.translate.contracts,
            activities: this.data.contracts || [],
            watermarkImage: 'url(\'/assets/svg/ikon_contracts.svg\')',
            workflowStatuses: this.workflowStatuses
          }
        }
      };
    }
  },
  watch: {
    $route: function $route() {
      this.emitToggle();
    }
  },
  methods: {
    emitToggle: function emitToggle() {
      this.$emit('toggle');
    }
  }
};