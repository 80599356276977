import { STANDARD_VIEWS } from '@/constants/ViewTypes';
import ViewSwitcher from '@/components/Molecules/ViewSwitcher';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'CatalogueHeader',
  introduction: 'Tile view of an activity',
  description: 'Shows a shadowed box of one activity with description, e.g. a control',
  token: '<catalogue-header />',
  components: {
    FeatherIcon: FeatherIcon,
    ViewSwitcher: ViewSwitcher
  },
  props: {
    viewTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return STANDARD_VIEWS;
      },
      note: 'An array of type of view. Check it all in "app/frontend/constants/ViewTypes.js"'
    },
    viewType: {
      type: String,
      required: true,
      note: 'A name of view type (display as) by default'
    },
    isOverviewOptionsAvailable: {
      type: Boolean,
      required: false,
      default: true,
      note: 'if the Show Overview options section is available'
    },
    overviewOptionsState: {
      type: Boolean,
      required: false,
      default: false,
      note: 'A state of the section "Overview options". Possible: true - open, false - hide'
    }
  },
  emits: ['update:display', 'update:overviewoption', 'update:viewType'],
  data: function data() {
    return {
      translate: {
        display: this.$trans('Display')
      }
    };
  },
  computed: {
    overviewOptionsIcon: function overviewOptionsIcon() {
      return 'chevron-down';
    },
    overviewOptionsTitle: function overviewOptionsTitle() {
      return this.$trans("".concat(this.overviewOptionsState ? 'Hide' : 'Show', " overview options"));
    }
  }
};