import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_compact_title_data_component = _resolveComponent("compact-title-data-component");
  var _component_static_table = _resolveComponent("static-table");
  var _component_card_information_assets = _resolveComponent("card-information-assets");
  var _component_load_more_wrapper = _resolveComponent("load-more-wrapper");
  var _component_preview_list = _resolveComponent("preview-list");
  return _openBlock(), _createBlock(_component_preview_list, {
    length: $props.informationAssets.length,
    title: _ctx.informationAssetsTitle(),
    class: "information_assets",
    "cookie-hidden-name": "information_assets",
    onChangeShowState: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changeShowState', $event);
    })
  }, {
    default: _withCtx(function () {
      return [$props.viewMode === $data.viewTypes.COMPACT ? (_openBlock(), _createBlock(_component_static_table, {
        key: 0,
        "visibility-switch": true,
        dataset: $options.dataset,
        columns: $options.columnsTrans
      }, {
        name: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_createVNode(_component_compact_title_data_component, {
            "slot-data": slotData
          }, null, 8 /* PROPS */, ["slot-data"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset", "columns"])) : (_openBlock(), _createBlock(_component_load_more_wrapper, {
        key: 1,
        activities: $props.informationAssets,
        class: "`information-assets-list information-assets-list-${viewMode}`"
      }, {
        default: _withCtx(function (_ref2) {
          var activitiesLimited = _ref2.activitiesLimited;
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activitiesLimited, function (informationAsset, index) {
            return _openBlock(), _createElementBlock("div", {
              key: "preview-info-asset".concat(index),
              class: _normalizeClass("preview information-assets-item-".concat($props.viewMode))
            }, [_createVNode(_component_card_information_assets, {
              "truncate-length": $options.truncateLength,
              "information-asset": informationAsset,
              "asset-type": $options.getAssetType(informationAsset)
            }, null, 8 /* PROPS */, ["truncate-length", "information-asset", "asset-type"])], 2 /* CLASS */);
          }), 128 /* KEYED_FRAGMENT */))];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["activities"]))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["length", "title"]);
}