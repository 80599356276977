function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getSettingsChangelog } from '@/api/admin/reports';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import MainWell from '@/components/Atoms/Containers/MainWell';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { SETTINGS_ARRAY } from '@/constants/admin/Settings';
import { SortDirection } from '@/constants/SortDirection';
import { toLocalDateTimeFull } from '@/utils/date';
export default {
  components: {
    RismaTitle: RismaTitle,
    MainWell: MainWell,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaDatatable: RismaDatatable
  },
  mixins: [RismaDatatableDefaultMixin],
  data: function data() {
    return {
      isLoaded: false,
      meta: {},
      defaultSorting: [{
        index: 'date',
        dir: SortDirection.DESC
      }],
      dataset: [],
      settingsData: Object.values(SETTINGS_ARRAY),
      translate: getTranslate['AdminSettingsChangelog']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, ['users'])), mapState(useOrganisationsStore, ['organisations'])), {}, {
    columns: function columns() {
      return {
        setting: this.translate.setting,
        from: this.translate.from,
        to: this.translate.to,
        date: this.translate.date,
        changedBy: this.translate.changedBy
      };
    },
    showNothingToShowMessage: function showNothingToShowMessage() {
      return !this.datasetSlice.length && this.isLoaded;
    }
  }),
  watch: {
    dataset: function dataset(newDataset) {
      this.enhanceDataset(newDataset, this.columns);
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      this.isLoaded = false;
      return getSettingsChangelog().then(function (_ref) {
        var list = _ref.list;
        _this.dataset = _this.createDataset(list);
        _this.isLoaded = true;
      });
    },
    createDataset: function createDataset(list) {
      var _this2 = this;
      var result = [];
      list.forEach(function (item) {
        var datasetItem = {
          setting: {
            field: _this2.formatFieldSetting(item)
          },
          from: {
            field: item.old_value
          },
          to: {
            field: item.new_value
          },
          date: {
            field: toLocalDateTimeFull(item.created)
          },
          changedBy: {
            field: _this2.getUserNameById(item.user_id)
          }
        };
        if (datasetItem.setting.field) {
          result.push(datasetItem);
        }
      });
      return result;
    },
    getUserNameById: function getUserNameById(id) {
      var user = this.users.find(function (user) {
        return user.id === id;
      });
      var active = !user || user.active;
      return {
        title: user ? user.display_name : id,
        active: active
      };
    },
    formatFieldSetting: function formatFieldSetting(item) {
      var setting = item.column.split(' ')[0];
      var settingGroup = this.settingsData.find(function (group) {
        return group[setting];
      });
      if (!settingGroup) return null;
      return settingGroup[setting].name;
    }
  }
};