import * as HttpUtils from '../httpUtils';
export function getRecentActivities() {
  var days = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 30;
  return HttpUtils.getByUrlAndParams('/activities/recent', {
    days: days
  }).then(function (_ref) {
    var list = _ref.list;
    return list;
  });
}
export function getCurrentActivities(daysTillDeadline) {
  return HttpUtils.getByUrlAndParams('/activities/current', {
    daysTillDeadline: daysTillDeadline
  }).then(function (_ref2) {
    var list = _ref2.list;
    return list;
  });
}