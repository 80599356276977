import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import { MODULES } from '@/constants/modules';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { Colors } from '@/Colors';
export default {
  name: 'RismaTreeBranch',
  token: "<risma-tree-branch\n          :modelValue=\"item\"\n          :is-child=\"isChild\"\n          :disable-item-menu=\"disableItemMenu\"\n          :enable-add-button=\"enableAddButton\"\n          @dragStart=\"onMove\" />",
  components: {
    FeatherIcon: FeatherIcon,
    TrafficLight: TrafficLight
  },
  props: {
    modelValue: {
      required: true,
      type: Object,
      default: function _default() {},
      note: 'object data with treedata child. modelValue should have children, needed for drag and drop'
    },
    isChild: {
      required: true,
      type: Boolean,
      default: true,
      note: 'depth > 0?'
    },
    disableItemMenu: {
      type: Boolean,
      default: false,
      required: false
    },
    enableAddButton: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['dragStart'],
  data: function data() {
    return {
      objectTypes: ObjectTypes,
      isOpened: false
    };
  },
  computed: {
    trafficLightColor: function trafficLightColor() {
      var trafficLight = this.modelValue.traffic_light || this.modelValue.trafficLight;
      if (trafficLight !== undefined) {
        return Colors.getTrafficlightByNumber(trafficLight).color;
      }
      return false;
    },
    hasChildren: function hasChildren() {
      return this.modelValue.children && this.modelValue.children.length;
    },
    rightTitle: function rightTitle() {
      var item = this.modelValue;
      switch (item.objType) {
        case MODULES.CONTROLS:
          return "".concat(item.cpath, ". ").concat(item.title);
        case MODULES.EXECUTION:
          return "".concat(item.ipath, " ").concat(item.title);
        default:
          return item.title || item.name;
      }
    }
  }
};