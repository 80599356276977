import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risk_grid = _resolveComponent("risk-grid");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risk_grid, {
    "scoring-id": 8,
    "enable-zoom": true,
    "zoom-range": [30, 120],
    "grid-box-size": 180,
    "risk-grid-data": _ctx.riskGridDataSmaller
  }, null, 8 /* PROPS */, ["risk-grid-data"]), _createVNode(_component_risk_grid, {
    "scoring-id": 8,
    "grid-box-size": 150,
    "zoom-range": [40, 140],
    "zoom-step": 20,
    "enable-zoom": true,
    "risk-grid-data": _ctx.riskGridDataSmaller,
    "color-direction": "horizontal"
  }, null, 8 /* PROPS */, ["risk-grid-data"]), _createVNode(_component_risk_grid, {
    "scoring-id": 8,
    "grid-box-size": 130,
    "enable-zoom": true,
    "risk-grid-data": _ctx.riskGridDataSmaller,
    "color-direction": "vertical"
  }, null, 8 /* PROPS */, ["risk-grid-data"])]);
}