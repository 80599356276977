function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { MODULES, getModuleTitles } from '@/constants/modules';
import { buildNavBar } from '@/menus/navbar';
export var getLinksModalSettings = function getLinksModalSettings() {
  var _ref;
  var settings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var user = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var navBar = buildNavBar(settings, user);
  var ModuleTitles = getModuleTitles();
  return _ref = {}, _defineProperty(_ref, MODULES.EXECUTION, {
    label: ModuleTitles[MODULES.EXECUTION],
    value: MODULES.EXECUTION,
    rights: navBar.actions.rights
  }), _defineProperty(_ref, MODULES.CONTRACTS, {
    label: ModuleTitles[MODULES.CONTRACTS],
    value: MODULES.CONTRACTS,
    rights: navBar.contracts.rights
  }), _defineProperty(_ref, MODULES.CONTROLS, {
    label: ModuleTitles[MODULES.CONTROLS],
    value: MODULES.CONTROLS,
    rights: navBar.controls.rights
  }), _defineProperty(_ref, MODULES.RISK, {
    label: ModuleTitles[MODULES.RISK],
    value: MODULES.RISK,
    rights: navBar.risk.rights
  }), _defineProperty(_ref, MODULES.COMPLIANCE, {
    label: ModuleTitles[MODULES.COMPLIANCE],
    value: MODULES.COMPLIANCE,
    rights: navBar.compliance.rights
  }), _defineProperty(_ref, MODULES.INCIDENTS, {
    label: ModuleTitles[MODULES.INCIDENTS],
    value: MODULES.INCIDENTS,
    rights: navBar.incident.rights
  }), _defineProperty(_ref, MODULES.COMPLIANCE_PROCESS_TREE_NODE, {
    label: ModuleTitles[MODULES.COMPLIANCE_PROCESS_TREE_NODE],
    value: MODULES.COMPLIANCE_PROCESS_TREE_NODE,
    rights: user === null || user === void 0 ? void 0 : user.policiesAccess
  }), _defineProperty(_ref, MODULES.COMPLIANCE_IA_SYSTEM, {
    label: ModuleTitles[MODULES.COMPLIANCE_IA_SYSTEM],
    value: MODULES.COMPLIANCE_IA_SYSTEM,
    rights: (user === null || user === void 0 ? void 0 : user.iaAccess) === true && (settings['feature.enable_systems'] === undefined || settings['feature.enable_systems'])
  }), _defineProperty(_ref, MODULES.COMPLIANCE_IA_PROCESSOR, {
    label: ModuleTitles[MODULES.COMPLIANCE_IA_PROCESSOR],
    value: MODULES.COMPLIANCE_IA_PROCESSOR,
    rights: (user === null || user === void 0 ? void 0 : user.iaAccess) === true && (settings['feature.enable_data_processors'] === undefined || settings['feature.enable_data_processors'])
  }), _defineProperty(_ref, MODULES.COMPLIANCE_IA_CONTROLLER, {
    label: ModuleTitles[MODULES.COMPLIANCE_IA_CONTROLLER],
    value: MODULES.COMPLIANCE_IA_CONTROLLER,
    rights: (user === null || user === void 0 ? void 0 : user.iaAccess) === true && (settings['feature.enable_data_controllers'] === undefined || settings['feature.enable_data_controllers'])
  }), _defineProperty(_ref, MODULES.COMPLIANCE_INFORMATION_ASSETS, {
    label: ModuleTitles[MODULES.COMPLIANCE_INFORMATION_ASSETS],
    value: MODULES.COMPLIANCE_INFORMATION_ASSETS,
    rights: (user === null || user === void 0 ? void 0 : user.iaAccess) === true
  }), _defineProperty(_ref, MODULES.RISK_ASSESSMENT, {
    label: ModuleTitles[MODULES.RISK_ASSESSMENT],
    value: MODULES.RISK_ASSESSMENT,
    rights: true
  }), _defineProperty(_ref, MODULES.COMPANY, {
    label: ModuleTitles[MODULES.COMPANY],
    value: MODULES.COMPANY,
    rights: (user === null || user === void 0 ? void 0 : user.level_company) || (user === null || user === void 0 ? void 0 : user.is_admin)
  }), _ref;
};
export var getProjectRiskAssessmentLinksTab = function getProjectRiskAssessmentLinksTab() {
  var projects = getLinksModalSettings();
  return projects[MODULES.RISK_ASSESSMENT];
};
export var getProjectsOptionsLinksTab = function getProjectsOptionsLinksTab(settings, user) {
  var projects = getLinksModalSettings(settings, user);
  return [projects[MODULES.CONTRACTS], projects[MODULES.EXECUTION], projects[MODULES.CONTROLS], projects[MODULES.RISK], projects[MODULES.COMPLIANCE_PROCESS_TREE_NODE], projects[MODULES.COMPLIANCE_IA_SYSTEM], projects[MODULES.COMPLIANCE_IA_CONTROLLER], projects[MODULES.COMPLIANCE_IA_PROCESSOR], projects[MODULES.COMPLIANCE], projects[MODULES.INCIDENTS], projects[MODULES.COMPLIANCE_INFORMATION_ASSETS], projects[MODULES.COMPANY]];
};
export var getGapProjectsOptions = function getGapProjectsOptions(settings, user) {
  var projects = getLinksModalSettings(settings, user);
  var data = [projects[MODULES.EXECUTION], projects[MODULES.CONTROLS], projects[MODULES.COMPLIANCE_PROCESS_TREE_NODE]];
  if (settings['app.module_risks2_enabled']) {
    data.push(projects[MODULES.RISK]);
  }
  return data;
};
export var getAssessmentProjectsOptions = function getAssessmentProjectsOptions(settings, user) {
  var projects = getLinksModalSettings(settings, user);
  return [projects[MODULES.EXECUTION], projects[MODULES.CONTROLS]];
};
export var GovernanceReviewOptionsLinksTab = {
  label: 'Governance review',
  value: 'governanceReviewLinks'
};