import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "px-4"
};
var _hoisted_2 = {
  class: "flex -mb-px"
};
var _hoisted_3 = {
  class: "border border-gray-450 rounded rounded-l-none p-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$questionnai, _$options$questionnai2;
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_light_tab_item = _resolveComponent("light-tab-item");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.customQuestions, " - ").concat((_$options$questionnai = (_$options$questionnai2 = $options.questionnaire) === null || _$options$questionnai2 === void 0 ? void 0 : _$options$questionnai2.title) !== null && _$options$questionnai !== void 0 ? _$options$questionnai : '')
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_button, {
    text: $data.translate.back,
    type: "util",
    class: "w-fit",
    onClick: $options.backToPrevPage
  }, null, 8 /* PROPS */, ["text", "onClick"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_light_tab_item, {
    to: "admin"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.questions), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }), _createVNode(_component_light_tab_item, {
    to: "changelog"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.changelog), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })]), _createElementVNode("div", _hoisted_3, [$options.questionnaire ? (_openBlock(), _createBlock(_component_router_view, {
    key: 0,
    questionnaire: $options.questionnaire,
    data: $options.questionnaire
  }, null, 8 /* PROPS */, ["questionnaire", "data"])) : _createCommentVNode("v-if", true)])]);
}