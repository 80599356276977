function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TabItem from '@/components/Atoms/TabItem/TabItem';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import SaveButton from '@/components/Atoms/Buttons/SaveButton';
import TrafficLightSelector from '@/components/Organisms/Selectors/TrafficLightSelector';
import Tabs from '@/components/Molecules/Tabs/Tabs';
import Attachment from '@/components/Organisms/Attachment/Attachment';
import { MODULES } from '@/constants/modules';
export default {
  name: 'DepHeader',
  introduction: 'Header of Data Entry Page',
  description: 'The component displays basic information of the entity',
  token: "<dep-header\n              :id=\"44\"\n              :editableId=\"true\"\n              :title=\"Entity title\"\n              :editableTitle=\"true\"\n              :tabs=[\n                { url: '', label: this.translate.home, icon: 'home' },\n                { url: '', label: this.translate.links, icon: 'link' }]\n              :files-list=[] />",
  components: {
    Attachment: Attachment,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    SaveButton: SaveButton,
    TabItem: TabItem,
    Tabs: Tabs,
    TrafficLightSelector: TrafficLightSelector
  },
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: '',
      note: 'Entity id'
    },
    idMaxLength: {
      required: false,
      type: Number,
      default: 255
    },
    editableId: {
      required: false,
      default: false,
      type: Boolean,
      note: 'A boolean to determine if the id, should be editable'
    },
    title: {
      type: String,
      required: true,
      default: '',
      note: 'Entity title'
    },
    editableTitle: {
      required: false,
      default: false,
      type: Boolean,
      note: 'A boolean to determine if the title, should be editable'
    },
    trafficLightSettings: {
      required: false,
      default: function _default() {
        return {};
      },
      type: Object,
      note: 'Settings for traffic light'
    },
    tabs: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      },
      note: 'Available tabs for this entity'
    },
    filesList: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of files'
    },
    saveStatus: {
      type: String,
      required: false,
      default: '',
      note: 'if saveStatus is empty, the saveStatus will be hidden'
    },
    module: {
      type: String,
      required: false,
      default: '',
      note: 'A name of module, for example, "control" or "risk"'
    },
    moduleId: {
      type: [String, Number],
      required: false,
      default: '',
      note: 'An id of one of the entities of modules. For example, an cpath of the specific control'
    },
    editableAttachments: {
      required: false,
      default: true,
      type: Boolean,
      note: 'if attachments are editable. Can only be downloaded'
    },
    showCheckmark: {
      required: false,
      default: false,
      type: Boolean,
      note: 'If to show checkmark after title'
    },
    lineThroughTitle: {
      required: false,
      default: false,
      type: Boolean,
      note: 'If the title has strikethrough line'
    },
    hasVersionButtons: {
      type: Boolean,
      required: false,
      default: false,
      note: 'if item has version buttons (process library should have them)'
    },
    singleFileLoad: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Attachments loading only one file, on reload will replace the file'
    },
    attachmentHasPin: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Attachments has pin/unpin functionality'
    },
    attachmentHasTitle: {
      type: Boolean,
      default: true,
      required: false,
      note: 'Attachments has title field'
    },
    fileTypeOptions: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: false,
      note: 'The list of options for file types if any'
    },
    isAttachmentEnabled: {
      type: Boolean,
      default: true,
      required: false,
      note: 'Attachments enabled'
    },
    attachmentsFilesAndLinksSeparated: {
      type: Boolean,
      default: true,
      required: false,
      note: 'In Attachments add attachment and add link are shown separately'
    },
    attachmentFileTypesEnabled: {
      type: Boolean,
      default: false,
      required: false,
      note: 'In Attachments show selector with file types'
    },
    attachmentEditFileEnabled: {
      type: Boolean,
      default: true,
      required: false,
      note: 'In Attachments show edit button on files'
    },
    allowEmptyTitle: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['saveStatusClicked', 'changeTrafficLight', 'changeId', 'changeTitle', 'getActivityData', 'fileUpdate', 'updatingAttachments'],
  data: function data() {
    return {
      translate: {
        home: this.$trans('Home'),
        links: this.$trans('Links')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    displayIdInTitle: function displayIdInTitle() {
      switch (this.module) {
        case MODULES.COMPLIANCE:
          return !!this.getSettingValue('feature.show_node_id');
        case MODULES.INCIDENTS:
          return !!this.getSettingValue('feature.show_incident_id');
        case MODULES.USERS:
        case MODULES.COMPANY:
          return false;
        default:
          return true;
      }
    }
  })
};