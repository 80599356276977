function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import Tabs from '@/components/Molecules/Tabs/Tabs';
import TabItem from '@/components/Atoms/TabItem/TabItem';
import { checkERMByProjectId } from '@/utils/risks/check';
import { RiskBaseUrls } from '@/constants/risks/RiskBaseUrls';
export default {
  name: 'RiskReportsEntry',
  description: 'Common page for risk2 reports',
  token: '<risk-reports-entry />',
  components: {
    Tabs: Tabs,
    TabItem: TabItem
  },
  props: {
    projectId: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      translate: {
        risks: this.$trans('Risks'),
        threats: this.$trans('Threats'),
        vulnerabilities: this.$trans('Vulnerabilities')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    baseLink: function baseLink() {
      return "".concat(RiskBaseUrls.projects, "/").concat(this.projectId, "/reports/");
    },
    isERM: function isERM() {
      return checkERMByProjectId(this.projectId, this.currentUser);
    }
  }),
  methods: {
    handleRoute: function handleRoute(to, from, next) {
      var isChildSection = to.matched.length > 1;

      // Relocate to default section
      if (!isChildSection) {
        next({
          path: "".concat(this.baseLink, "risks"),
          query: Object.assign({}, to.query)
        });
      } else {
        next();
      }
    }
  }
};