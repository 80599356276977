export var userActivityDelegationFieldsToRaciTitle = {
  responsibleUserIds: 'responsibleTitle',
  accountableUserIds: 'accountableTitle',
  consultedUserIds: 'consultedTitle',
  informedUserIds: 'informedTitle'
};
export var userActivityContractDelegationFields = {
  responsibleUserIds: 'responsibleLabel',
  accountableUserIds: 'accountableLabel',
  consultedUserIds: 'consultedLabel',
  informedUserIds: 'informedLabel'
};
export var userActivityControlDelegationFields = {
  responsible: 'Responsible',
  escalation: 'Escalation',
  review: 'Review'
};