function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { focus } from '@/utils/directives/focus';
import { Colors } from '@/Colors';
import { RiskAdminPages } from '@/constants/risks/RiskAdminPages';
export default {
  name: 'RiskAdminModal',
  introduction: 'Risk Admin Modal',
  description: 'Admin modal component (mass import for threat or vulnerabilities))',
  token: '<risk-admin-modal></risk-admin-modal>',
  directives: {
    focus: focus
  },
  components: {
    RismaButton: RismaButton,
    Modal: Modal
  },
  props: {
    pageName: {
      type: String,
      required: true,
      note: 'threat or vulnerability'
    }
  },
  emits: ['created'],
  data: function data() {
    return {
      colors: Colors,
      showModal: false,
      showButtons: false,
      rowData: '',
      rows: 20,
      cols: 50,
      validation: null,
      translate: getTranslate['RiskAdminModal']()
    };
  },
  computed: {
    modalHeaderTitle: function modalHeaderTitle() {
      return this.pageName === RiskAdminPages.THREAT ? this.translate.importThreats : this.translate.importVulnerabilities;
    },
    modalHeaderDescription: function modalHeaderDescription() {
      if (this.pageName === RiskAdminPages.THREAT) {
        return this.translate.youCanMassImportAListIntoTheBelowFieldThreats;
      } else {
        return this.translate.youCanMassImportAListIntoTheBelowFieldVulnerabilities;
      }
    }
  },
  methods: {
    beforeOpen: function beforeOpen() {
      this.rowData = '';
      this.resetValidation();
    },
    toggleModal: function toggleModal() {
      this.showModal = !this.showModal;
    },
    cancelCreation: function cancelCreation() {
      this.toggleModal();
    },
    onInput: function onInput() {
      if (this.validation) {
        this.validation = null;
      }
    },
    create: function create() {
      var _this = this;
      var data = this.purifyData(this.rowData.split('\n'));
      data = data.map(function (item) {
        if (_this.pageName === RiskAdminPages.THREAT && item.includes(' > ')) {
          var _item$split = item.split(' > '),
            _item$split2 = _slicedToArray(_item$split, 2),
            category = _item$split2[0],
            title = _item$split2[1];
          return {
            category: category,
            title: title
          };
        }
        return {
          title: item
        };
      });
      if (this.validate(data)) {
        this.$emit('created', data);
        this.toggleModal();
      }
    },
    purifyData: function purifyData(data) {
      var result = _toConsumableArray(data).map(function (item) {
        return item.trim();
      });
      result = result.filter(function (item) {
        return item;
      });
      return Array.from(new Set(result));
    },
    resetValidation: function resetValidation() {
      this.validation = null;
    },
    validate: function validate(data) {
      var result = true;
      this.resetValidation();
      if (!data.length) {
        result = false;
        this.validation = this.translate.pleaseFillOutThisField;
      }
      return result;
    }
  }
};