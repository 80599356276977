import ContractTimeline from '@/components/Organisms/Contracts/ContractTimeline';
export default {
  components: {
    ContractTimeline: ContractTimeline
  },
  data: function data() {
    return {
      dates: [{
        'label': 'Start date',
        'bgColor': 'bg-green-250',
        'textColor': 'text-green-250',
        'data': [{
          'date': '2023-02-01 00:00:00',
          'id': 1
        }],
        'showDateTitle': true
      }, {
        'label': 'Renewal deadline',
        'bgColor': 'bg-yellow-60',
        'textColor': 'text-yellow-60',
        'data': [{
          'date': '2023-02-19 00:00:00',
          'notification': null,
          'notificationLabel': 'Renewal deadline notification',
          'id': 3
        }]
      }, {
        'label': 'Termination deadline',
        'bgColor': 'bg-blue-150',
        'textColor': 'text-blue-150',
        'data': [{
          'date': '2023-02-09 00:00:00',
          'notification': null,
          'notificationLabel': 'Termination deadline notification',
          'id': 4
        }]
      }, {
        'label': 'End date',
        'bgColor': 'bg-red-60',
        'textColor': 'text-red-60',
        'data': [{
          'date': '2023-02-27 00:00:00',
          'notification': null,
          'notificationLabel': 'End date notification',
          'id': 2
        }],
        'showDateTitle': true
      }]
    };
  }
};