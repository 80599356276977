import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-74aa0d17"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "matrix"
};
var _hoisted_2 = {
  class: "matrix-inner relative"
};
var _hoisted_3 = {
  class: "horizontal-wrapper"
};
var _hoisted_4 = {
  key: 0,
  class: "toggleLines"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_axis_label = _resolveComponent("axis-label");
  var _component_grid = _resolveComponent("grid");
  var _component_grid_lines = _resolveComponent("grid-lines");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$data.display ? (_openBlock(), _createBlock(_component_axis_label, {
    key: 0,
    labels: $props.labels.yLabels,
    "main-label": $props.labels.yAxisLabel,
    "grid-box-size": $props.size,
    "show-arrows": $props.showArrows,
    "arrow-width": $options.arrowWidthY,
    "axis-width": $options.axisWidthY,
    axis: "vertical",
    class: "absolute bottom-0"
  }, null, 8 /* PROPS */, ["labels", "main-label", "grid-box-size", "show-arrows", "arrow-width", "axis-width"])) : _createCommentVNode("v-if", true), _createVNode(_component_grid, {
    ref: "grid",
    grid: $props.matrix,
    checked: $props.checked,
    "color-codes": $props.colorCodes,
    "box-size": $props.size,
    "color-arrangement": $props.matrixColor,
    "is-grid-clickable": $props.isMatrixClickable,
    "dot-label-hover-function": $props.dotLabelHoverFunction,
    "start-color": $props.startColor,
    "end-color": $props.endColor,
    "custom-heatmap-options": $props.customHeatmapOptions,
    "dot-connector-enabled": $props.dotConnectorEnabled,
    onDisplay: _cache[0] || (_cache[0] = function ($event) {
      return $data.display = true;
    }),
    onCell: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$refs.lines.cell = $event;
    }),
    onDot: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$refs.lines.dot = $event;
    }),
    onOnStateChanged: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('onStateChanged', $event);
    })
  }, null, 8 /* PROPS */, ["grid", "checked", "color-codes", "box-size", "color-arrangement", "is-grid-clickable", "dot-label-hover-function", "start-color", "end-color", "custom-heatmap-options", "dot-connector-enabled"]), $data.showLines ? (_openBlock(), _createBlock(_component_grid_lines, {
    key: 1,
    ref: "lines",
    "grid-box-size": $props.size,
    grid: $props.matrix,
    onGetCell: _ctx.$refs.grid.getCell,
    onGetDot: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$refs.grid.getDOMDot($event);
    })
  }, null, 8 /* PROPS */, ["grid-box-size", "grid", "onGetCell"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_3, [$data.display ? (_openBlock(), _createBlock(_component_axis_label, {
    key: 0,
    labels: $props.labels.xLabels,
    "main-label": $props.labels.xAxisLabel,
    "grid-box-size": $props.size,
    "show-arrows": $props.showArrows,
    "arrow-width": $options.arrowWidthX,
    "axis-width": $options.axisWidthX,
    axis: "horizontal"
  }, null, 8 /* PROPS */, ["labels", "main-label", "grid-box-size", "show-arrows", "arrow-width", "axis-width"])) : _createCommentVNode("v-if", true)]), $props.enablePrevPosition ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_toggle, {
    modelValue: $data.showLines,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $data.showLines = $event;
    })
  }, null, 8 /* PROPS */, ["modelValue"]), _createVNode(_component_risma_title, {
    title: $props.showPreviousPositionLabel,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), $props.periods ? (_openBlock(), _createBlock(_component_single_select, {
    key: 0,
    modelValue: $props.periods.selected,
    "onUpdate:modelValue": [_cache[6] || (_cache[6] = function ($event) {
      return $props.periods.selected = $event;
    }), $options.onPeriodChange],
    disabled: false,
    options: $props.periods.options,
    "un-sorted": true,
    class: "select-periods"
  }, null, 8 /* PROPS */, ["modelValue", "options", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]);
}