export var RiskCookies = {
  OVERVIEW_VIEW_SELECTED: 'risk_overview_view_selected',
  MY_RISKS_VIEW_SELECTED: 'risk_my_risks_overview_view_selected',
  OVERVIEW_SEARCH: 'risk_overview_search',
  OVERVIEW_FILTERS_TAGS: 'risk_overview_filters_tag',
  OVERVIEW_FILTERS_ORGANISATIONS: 'risk_overview_filters_organisations',
  OVERVIEW_FILTERS_PARENT_RISKS: 'risk_overview_filters_parent_risks',
  OVERVIEW_GROUPBY: 'risk_overview_group_by',
  OVERVIEW_SORTBY: 'risk_overview_sort_by',
  LIST_COMPACT_HIDDEN: 'risk_hidden_columns',
  RISK_REPORT_DATATABLE: 'risk_report_datatable_state'
};