import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';

/**
 * Get all project entries
 * @returns {Promise.<TResult>}
 */

var projectPromise = null;
export function getAll() {
  var forceNew = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var query = arguments.length > 1 ? arguments[1] : undefined;
  if (projectPromise && !forceNew) {
    return projectPromise;
  } else {
    return projectPromise = handleRequestResponse(Http.get("compliance/projects".concat(query ? "?".concat(query) : '')));
  }
}
export function getSingle(id) {
  var forceNew = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return getAll(forceNew).then(function (response) {
    var project = null;
    response.list.forEach(function (item) {
      if (parseInt(item.id) === parseInt(id)) {
        project = item;
      }
    });
    return project;
  });
}
export function getProjectTypes() {
  return handleRequestResponse(Http.get('/compliance/projecttype'));
}
export function updateProject(projectId, data) {
  return handleRequestResponse(Http.patch("compliance/projects/".concat(projectId), data));
}