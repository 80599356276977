function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import draggable from 'vuedraggable';
import { createNewGroup, updateFieldGroups } from '@/api/admin/fieldGroups';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Modal from '@/components/Molecules/Modal/Modal';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'FieldGroupsModal',
  components: {
    InputField: InputField,
    Modal: Modal,
    FeatherIcon: FeatherIcon,
    RismaButton: RismaButton,
    draggable: draggable
  },
  props: {
    fieldGroups: {
      type: Array,
      default: function _default() {
        return [];
      },
      require: false
    },
    groupItems: {
      type: Object,
      default: null,
      require: false
    },
    scoringId: {
      type: [String, Number],
      default: null,
      require: true
    }
  },
  emits: ['create', 'dismiss', 'move:groups', 'move:fields'],
  data: function data() {
    return {
      groupName: '',
      showAddGroup: false,
      updatedData: {},
      translate: getTranslate['FieldGroupsModal']()
    };
  },
  computed: {
    tasksGroupNested: function tasksGroupNested() {
      var _this = this;
      return this.fieldGroups.map(function (item) {
        item.tasks = _this.groupItems[item.id] || [];
        return item;
      });
    }
  },
  methods: {
    dismissModal: function dismissModal() {
      this.$emit('dismiss');
    },
    showAddGroupBlock: function showAddGroupBlock() {
      this.showAddGroup = !this.showAddGroup;
    },
    startTransition: function startTransition(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    endTransition: function endTransition(el) {
      el.style.height = '';
      this.groupName = '';
    },
    addGroup: function addGroup() {
      var _this2 = this;
      return createNewGroup(this.scoringId, {
        name: this.groupName
      }).then(function (response) {
        var newGroup = _objectSpread({}, response);
        delete newGroup._debug;
        _this2.$emit('create', newGroup);
        _this2.showAddGroupBlock();
      });
    },
    moveGroup: function moveGroup(_ref) {
      var _this3 = this;
      var moved = _ref.moved;
      var fieldGroups = this.changeOrderLocal(this.fieldGroups, moved.oldIndex, moved.newIndex);
      return updateFieldGroups(this.scoringId, {
        fieldGroups: fieldGroups
      }).then(function (response) {
        _this3.$emit('move:groups', response);
      });
    },
    changeOrderLocal: function changeOrderLocal(array, from, to) {
      array.splice(to, 0, array.splice(from, 1)[0]);
      return array;
    },
    onMoveFields: function onMoveFields(groupId, fields) {
      fields.forEach(function (field, index) {
        field.sorting = ++index;
        field.fieldGroup = groupId;
      });
      this.updatedData[groupId] = fields;
    },
    moveFields: function moveFields() {
      this.$emit('move:fields', this.updatedData);
      this.updatedData = {};
    }
  }
};