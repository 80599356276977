function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import i18n from '@/i18n';
import { Colors } from '@/Colors';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { purifyItemField } from '@/utils/Utils';
import { isHexColor } from '@/utils/regex';
import { capitalize } from '@/utils';

/**
 * Generate validated data that is ready to be exported
 * @param dataset Dataset from RismaDatatable component
 * @param columnsToExport Set of columns that should be exported
 * @param isGap determins if gap is the initiator, necessary for traffic light fields
 */
export var prepareDatasetToExport = function prepareDatasetToExport(dataset) {
  var columnsToExport = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var isGap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var result = [];
  dataset.forEach(function (item) {
    var row = {};
    for (var key in columnsToExport) {
      if (item[key] !== undefined) {
        var field = item[key].field;
        var isTraficLight = field && typeof field === 'string' && (field.indexOf('class="traffic-light"') !== -1 || field.indexOf('trafficlight') !== -1);
        isTraficLight = isTraficLight || field && _typeof(field) === 'object' && field.color;
        if (key === 'trafficLight' || isTraficLight) {
          var _item$key;
          var value = purifyTrafficLightFiled(item[key], isGap);
          row[key] = (_item$key = item[key]) !== null && _item$key !== void 0 && (_item$key = _item$key.field) !== null && _item$key !== void 0 && _item$key.trafficLightSuffix ? value + item[key].field.trafficLightSuffix : value;
          continue;
        }
        row[key] = purifyItemField(item[key]);
      }
    }
    result.push(row);
  });
  return result;
};
function purifyTrafficLightFiled(item, isGap) {
  var value = item.fieldText === undefined ? item.field.color : item.fieldText;
  if (isHexColor(value)) {
    return i18n.t(capitalize(Colors.getTrafficLabelByColor(value)));
  }
  if (isGap) {
    return i18n.t(Colors.gapColor(value).label);
  }
  if (item.label === i18n.t('Severity')) {
    return i18n.t(capitalize(Colors.findIncidentSeverity(value, true).label));
  }
  return i18n.t(RismaControlsUtils.getTrafficLightLabel(value));
}