function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import MainWell from '@/components/Atoms/Containers/MainWell';
import InputField from '@/components/Atoms/Inputs/InputField';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import TabList from '@/components/Atoms/TabList';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Alert from '@/components/Molecules/Modal/Alert';
import { getTranslate } from './translate';
import { getAvailableGapSchemas, getAvailableProjects, getAvailableQuestionnaires, getSyncDatabaseStatus, getSyncedGapSchemas, getSyncedProjects, getSyncedQuestionnaires, synchroniseProjects } from '@/api/rismaadmin/sync';
var CONNECTION_STATUS_SUCCESS = 'OK';
export default {
  name: 'SyncAdmin',
  components: {
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    TabList: TabList,
    RismaTable: RismaTable,
    MainWell: MainWell,
    InputField: InputField,
    NotificationPopUp: NotificationPopUp,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Alert: Alert
  },
  data: function data() {
    return {
      confirmationMessage: '',
      showConfirmationAlert: false,
      notificationType: 'error',
      notificationText: '',
      connectionStatus: '',
      translate: getTranslate['SyncAdmin'](),
      currentTab: '',
      availableProjects: null,
      selectedTargetProject: null,
      selectedSourceProject: null,
      tabNames: {
        questionnaire: 'questionnaire',
        gapSchema: 'gapSchema',
        project: 'project'
      },
      loading: false,
      statusLoaded: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    dbName: function dbName() {
      return this.getSettingValue('app.sync_db_name');
    },
    tabs: function tabs() {
      return [{
        name: this.translate.questionnaires,
        slotName: this.tabNames.questionnaire
      }, {
        name: this.translate.syncedGapSchemas,
        slotName: this.tabNames.gapSchema
      }, {
        name: this.translate.syncedProjects,
        slotName: this.tabNames.project
      }];
    },
    sourceProjects: function sourceProjects() {
      var _this$availableProjec;
      if (!((_this$availableProjec = this.availableProjects) !== null && _this$availableProjec !== void 0 && _this$availableProjec.sourceProjects)) {
        return [];
      }
      return this.availableProjects.sourceProjects.map(function (project) {
        return {
          label: project.title,
          value: project.id
        };
      });
    },
    targetProjects: function targetProjects() {
      var _this$availableProjec2;
      if (!((_this$availableProjec2 = this.availableProjects) !== null && _this$availableProjec2 !== void 0 && _this$availableProjec2.targetProjects)) {
        return [];
      }
      return this.availableProjects.targetProjects.map(function (project) {
        return {
          label: project.title,
          value: project.id
        };
      });
    },
    connectionStatusIsSuccessful: function connectionStatusIsSuccessful() {
      return this.connectionStatus === CONNECTION_STATUS_SUCCESS;
    }
  }),
  mounted: function mounted() {
    var _this = this;
    this.currentTab = this.tabNames.questionnaire;
    getSyncDatabaseStatus().then(function (response) {
      _this.connectionStatus = response;
      _this.statusLoaded = true;
      _this.getAvailableProjects();
    });
  },
  methods: {
    setNotification: function setNotification(notification) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      this.notificationText = notification;
      this.notificationType = type;
    },
    getSyncedQuestionnaireData: function getSyncedQuestionnaireData(params) {
      var _this2 = this;
      return this.getSyncedData('syncedQuestionnaires', params).catch(function (err) {
        var _err$response;
        _this2.setNotification(err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.error);
        throw err;
      });
    },
    getAvailableQuestionnaireData: function getAvailableQuestionnaireData(params) {
      var _this3 = this;
      return this.getSyncedData('availableQuestionnaires', params).catch(function (err) {
        var _err$response2;
        _this3.setNotification(err === null || err === void 0 || (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.error);
        throw err;
      });
    },
    getAvailableGapSchemas: function getAvailableGapSchemas(params) {
      var _this4 = this;
      return this.getSyncedData('availableGapSchemas', params).catch(function (err) {
        var _err$response3;
        _this4.setNotification(err === null || err === void 0 || (_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : _err$response3.error);
        throw err;
      });
    },
    getSyncedGapSchemas: function getSyncedGapSchemas(params) {
      var _this5 = this;
      return this.getSyncedData('syncedGapSchemas', params).catch(function (err) {
        var _err$response4;
        _this5.setNotification(err === null || err === void 0 || (_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : _err$response4.error);
        throw err;
      });
    },
    getSyncedProjects: function getSyncedProjects(params) {
      var _this6 = this;
      return this.getSyncedData('syncedProjects', params).catch(function (err) {
        var _err$response5;
        _this6.setNotification(err === null || err === void 0 || (_err$response5 = err.response) === null || _err$response5 === void 0 ? void 0 : _err$response5.error);
        throw err;
      });
    },
    getAvailableProjects: function getAvailableProjects() {
      var _this7 = this;
      this.getSyncedData('availableProjects').then(function (response) {
        _this7.availableProjects = response;
      });
    },
    onSynchronizeConfirm: function onSynchronizeConfirm() {
      var _this8 = this;
      this.showConfirmationAlert = false;
      this.loading = true;
      synchroniseProjects({
        sourceProjectId: this.selectedSourceProject,
        targetProjectId: this.selectedTargetProject
      }).then(function (response) {
        _this8.setNotification(response);
        _this8.reloadProjectTable();
      }).catch(function (err) {
        _this8.setNotification(err.response.error);
      }).finally(function () {
        return _this8.loading = false;
      });
    },
    synchroniseProject: function synchroniseProject() {
      var _this9 = this;
      if (!this.selectedTargetProject || !this.selectedSourceProject) {
        this.setNotification('You must select two projects');
        return;
      }
      var sourceProjects = this.availableProjects.sourceProjects;
      var targetProjects = this.availableProjects.targetProjects;
      var sourceProject = sourceProjects.find(function (project) {
        return project.id === _this9.selectedSourceProject;
      });
      var targetProject = targetProjects.find(function (project) {
        return project.id === _this9.selectedTargetProject;
      });
      this.confirmationMessage = "Are you sure you want to synchronise ".concat(sourceProject.title, " to ").concat(targetProject.title, "?\n\n            ").concat(targetProject.nodes_count, " nodes will be replaced with ").concat(sourceProject.nodes_count, " from the template!\n            ");
      this.showConfirmationAlert = true;
    },
    getSyncedData: function getSyncedData(entity, params) {
      if (!this.connectionStatusIsSuccessful) return Promise.resolve([]);
      var entities = {
        syncedQuestionnaires: getSyncedQuestionnaires(params),
        availableQuestionnaires: getAvailableQuestionnaires(params),
        availableGapSchemas: getAvailableGapSchemas(params),
        syncedGapSchemas: getSyncedGapSchemas(params),
        availableProjects: getAvailableProjects(),
        syncedProjects: getSyncedProjects(params)
      };
      return entities[entity];
    },
    reloadQuestionnaireTables: function reloadQuestionnaireTables() {
      this.$refs.syncedQuestionnaires.getData();
      this.$refs.availableQuestionnaires.getData();
    },
    reloadGapTables: function reloadGapTables() {
      this.$refs.syncedGapSchemas.getData();
      this.$refs.availableGapSchemas.getData();
    },
    reloadProjectTable: function reloadProjectTable() {
      this.$refs.syncedProjects.getData();
    }
  }
};