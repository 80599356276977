import ViewSwitcher from '@/components/Molecules/ViewSwitcher';
export default {
  name: '',
  components: {
    ViewSwitcher: ViewSwitcher
  },
  data: function data() {
    return {
      currentSwitcherView: 'expanded',
      views: [{
        title: 'Tiles view',
        name: 'tiles'
      }, {
        title: 'Compact view',
        name: 'compact'
      }, {
        title: 'Expanded view',
        name: 'expanded'
      }]
    };
  }
};