import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-358a0f7a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "column-menu bg-white cursor-pointer min-w-150px max-w-500px"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  class: "whitespace-nowrap"
};
var _hoisted_4 = {
  class: "whitespace-nowrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_table_column_filters = _resolveComponent("risma-table-column-filters");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.sortable ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", {
    class: "flex justify-between items-center px-3 py-2 hover:bg-gray-250",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.onSortClick($data.SortDirection.DESC);
    })
  }, [_createElementVNode("span", _hoisted_3, _toDisplayString($data.translate.sortDescending), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    icon: "triangle",
    class: "ml-3 text-gray-650",
    fill: "black",
    height: "8",
    width: "8"
  })]), _createElementVNode("div", {
    class: "flex justify-between items-center px-3 py-2 hover:bg-gray-250 border-b border-b-gray-650",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.onSortClick($data.SortDirection.ASC);
    })
  }, [_createElementVNode("span", _hoisted_4, _toDisplayString($data.translate.sortAscending), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    icon: "triangle",
    class: "ml-3 text-gray-650 rotate-180",
    fill: "black",
    height: "8",
    width: "8"
  })])])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_table_column_filters, {
    "filter-api": $props.filterApi,
    "filter-free-text": $props.filterFreeText,
    filters: $props.filters,
    "column-index": $props.columnIndex,
    "dates-filter": $props.datesFilter,
    onDeselectAllFilters: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('deselectAllFilters');
    }),
    onFiltersChange: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('filtersChange', $event);
    })
  }, null, 8 /* PROPS */, ["filter-api", "filter-free-text", "filters", "column-index", "dates-filter"])]);
}