import CompactTabItem from '@/components/Atoms/TabItem/CompactTabItem';
import CompactTabs from '@/components/Molecules/Tabs/CompactTabs';
import MainWell from '@/components/Atoms/Containers/MainWell';
export default {
  name: '',
  components: {
    CompactTabItem: CompactTabItem,
    CompactTabs: CompactTabs,
    MainWell: MainWell
  }
};