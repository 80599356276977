import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-e436f20a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = {
  class: "mt-4"
};
var _hoisted_4 = {
  for: "org-sort"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_notification_pop_up = _resolveComponent("notification-pop-up");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_tree = _resolveComponent("tree");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.notificationError ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    class: "notification-wrap whitespace-pre-line",
    type: "error",
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.notificationError = '';
    })
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("p", {
        innerHTML: $data.notificationError
      }, null, 8 /* PROPS */, _hoisted_2)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.notificationSuccess ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    closeable: false,
    type: "success"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.notificationSuccess), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.notifications, function (notification, index) {
    return _openBlock(), _createBlock(_component_notification_pop_up, {
      key: notification,
      type: notification.notificationType,
      onDismiss: function onDismiss($event) {
        return $options.dismissNotification(index);
      }
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("div", null, _toDisplayString(notification.notificationText), 1 /* TEXT */), _createElementVNode("div", null, _toDisplayString(notification.organisationName), 1 /* TEXT */)];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["type", "onDismiss"]);
  }), 128 /* KEYED_FRAGMENT */)), _createVNode(_component_risma_title, {
    title: $data.translate.organisations,
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_button, {
    text: $data.translate.addNew,
    type: "save",
    onClick: $options.addChildOnNode
  }, null, 8 /* PROPS */, ["text", "onClick"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("label", _hoisted_4, [_withDirectives(_createElementVNode("input", {
    id: "org-sort",
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $setup.isSorted = $event;
    }),
    type: "checkbox",
    onChange: _cache[2] || (_cache[2] = function () {
      return $options.sortByAlphabet && $options.sortByAlphabet.apply($options, arguments);
    })
  }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */), [[_vModelCheckbox, $setup.isSorted]]), _createTextVNode(" " + _toDisplayString($data.translate.sortInAlphabeticalOrder), 1 /* TEXT */)])]), Object.keys($data.treeData).length !== 0 ? (_openBlock(), _createBlock(_component_tree, {
    ref: "tree",
    key: "tree_refresh_key_".concat($data.treeKey),
    "level-select": false,
    copy: false,
    "tree-rights": false,
    "tree-data": $data.treeData,
    "do-move": false,
    "dataflow-toolbar": false,
    "tree-id": $data.treeId,
    "ability-to-add": true,
    "create-article30-report-option": false,
    "disable-item-menu": false,
    "disable-progress-shown": true,
    "lock-tree": true,
    "delete-node-option": true,
    "new-child": $data.newChild,
    "delete-node-function": $options.deleteOrganisation,
    "update-node-function": $options.updateNode,
    "delete-node-alert-message": $data.translate.areYouSureYouWantToDeleteThisOrganisation,
    "delete-node-label": $data.translate.deleteOrganisation,
    "update-node-label": $data.translate.updateOrganisation,
    "add-sub-node-label": $data.translate.addUnderlyingOrganisation,
    "is-organisation": true,
    "links-structure-by-module": null,
    "show-levels-classes": "right-25px -top-55px mt-2",
    onCreateChild: $options.addChild
  }, null, 8 /* PROPS */, ["tree-data", "tree-id", "new-child", "delete-node-function", "update-node-function", "delete-node-alert-message", "delete-node-label", "update-node-label", "add-sub-node-label", "onCreateChild"])) : _createCommentVNode("v-if", true), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 3,
    class: "mt-25vh-80"
  })) : _createCommentVNode("v-if", true)]);
}