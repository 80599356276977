import i18n from '@/i18n';
export var getTranslate = {
  MfaLogin: function MfaLogin() {
    return {
      logIn: i18n.t('Log in'),
      twoFactorAuthenticationRequired: i18n.t('Two Factor Authentication required'),
      enterADigitCodeBelowFromYourAuthenticator: i18n.t('Enter a digit code below, from your authenticator application in order to continue log in.'),
      enterLoginCode: i18n.t('Enter login code')
    };
  }
};