function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import * as RiskApi from '@/api/risk/risk';
import { getCurrentScoring as _getCurrentScoring } from '@/api/risk/scoring';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LinkItem from '@/components/Atoms/Containers/LinkItem';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Notification from '@/components/Molecules/Notification';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import DelegationWell from '@/components/Organisms/General/coreWells/DelegationWell';
import RiskRelationsSectionDetails from '@/components/Organisms/Risk/RiskRelationsSectionDetails';
import SolutionIdRelationDetails from '@/components/Molecules/SolutionIdRelationDetails';
import ActivityTypeSelector from '@/components/Molecules/ActivityTypeSelector';
import { createTabsList } from '@/utils/base/tabs';
import { getLinksLabel } from '@/utils/links';
import { getCustomFieldValues } from '@/utils/CustomFields';
import { removeDuplicates } from '@/utils/filterArray';
import { DelegationWellMixin } from '@/mixins/DelegationWellMixin';
import { RiskBaseUrls } from '@/constants/risks/RiskBaseUrls';
import { saveStatus } from '@/constants/SaveStatuses';
import { UserLevels } from '@/constants/UserLevels';
import { ControlValidationMessages } from '@/constants/ControlValidationMessages';
import { MODULES } from '@/constants/modules';
import { TabTypes, TabsDepRelations } from '@/constants/Tabs';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { onMounted, ref } from 'vue';
import { risksForParentRisk } from './compositions/RiskEntry';
import { updateFileLocally } from '@/utils/Attachments';
import { checkERMByProjectId } from '@/utils/risks/check';
import { catch403 } from '@/utils/handleAPIErrors';
export default {
  name: 'RiskEntry',
  description: 'Parent component for Risk Data Entry Page',
  token: '<risk-entry />',
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    Notification: Notification,
    DepWell: DepWell,
    LinkItem: LinkItem,
    DelegationWell: DelegationWell,
    RiskRelationsSectionDetails: RiskRelationsSectionDetails,
    SolutionIdRelationDetails: SolutionIdRelationDetails,
    ActivityTypeSelector: ActivityTypeSelector
  },
  mixins: [DelegationWellMixin],
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    path: {
      required: true,
      type: [String, Number],
      default: ''
    },
    projectId: {
      required: true,
      type: [String, Number],
      default: ''
    },
    periodId: {
      required: true,
      type: [String, Number],
      default: ''
    },
    scoringId: {
      required: false,
      type: [String, Number],
      default: null
    }
  },
  emits: ['changeTab', 'updated', 'deleteItem', 'accessError'],
  setup: function setup(props) {
    var risk = ref({});
    var _risksForParentRisk = risksForParentRisk(props, risk),
      getRisks = _risksForParentRisk.getRisks,
      risks = _risksForParentRisk.risks,
      risksFormatted = _risksForParentRisk.risksFormatted;
    onMounted(getRisks);
    return {
      risk: risk,
      risks: risks,
      risksFormatted: risksFormatted
    };
  },
  data: function data() {
    return {
      // For header
      filesList: [],
      // For relations
      tabsDepRelations: TabsDepRelations(),
      // type of mode
      isModeCreation: false,
      notificationType: 'info',
      notificationMessage: '',
      notificationDetails: '',
      isNotificationShow: false,
      isLoaded: false,
      saveStatus: saveStatus.SAVED,
      managementLevels: [],
      riskTypes: [],
      currentScoring: null,
      isCurrentProjectPeriod: false,
      loadedWithError: false,
      currentProject: null,
      newestUrl: '',
      translate: getTranslate['RiskEntry']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserIsAdmin: 'isAdmin'
  })), mapState(useUsersStore, ['activeUsers'])), mapState(useOrganisationsStore, ['organisations'])), mapState(useActivityTypesStore, {
    activityTypes: 'riskActivityTypes'
  })), {}, {
    users: function users() {
      if (this.isERM) {
        return this.activeUsers.filter(function (user) {
          return user.level_risk > UserLevels.NONE || user.is_admin;
        });
      } else {
        return this.activeUsers;
      }
    },
    delegationData: function delegationData() {
      if (!this.isLoaded) {
        return {};
      }
      var data = {
        projectId: this.risk.projectId,
        objType: this.risk.objType,
        accountableUserIds: (this.isERM ? [this.risk.userIds.accountable] : this.risk.accountableUserIds) || [],
        responsibleUserIds: (this.isERM ? [this.risk.userIds.responsible] : this.risk.responsibleUserIds) || [],
        organisationIds: this.risk.organisationIds || [],
        tagIds: this.risk.tagIds || [],
        module: MODULES.RISK
      };
      if (this.isERM) {
        data.managementLevelId = [this.risk.managementLevelId] || [];
        data.dailyTeamUsersIds = (this.risk.dailyTeamIds ? this.risk.dailyTeamIds.users : []) || [];
        data.dailyTeamOrganisationsIds = (this.risk.dailyTeamIds ? this.risk.dailyTeamIds.organisations : []) || [];
      } else {
        data.consultedUserIds = this.risk.consultedUserIds || [];
        data.informedUserIds = this.risk.informedUserIds || [];
      }
      return data;
    },
    isERM: function isERM() {
      return checkERMByProjectId(this.projectId, this.currentUser);
    },
    isRiskLocked: function isRiskLocked() {
      return this.isLoaded && (this.isERM ? !!this.currentUser.level_r_locked : !!this.userProjectData.locked);
    },
    userProjectData: function userProjectData() {
      var _this$currentUser$pro,
        _this = this;
      return ((_this$currentUser$pro = this.currentUser.projects) === null || _this$currentUser$pro === void 0 ? void 0 : _this$currentUser$pro.find(function (project) {
        return project.id === +_this.projectId && project.objType === ObjectTypes.RISK_PROJECT;
      })) || {};
    },
    isReadOnlyAccess: function isReadOnlyAccess() {
      return this.isLoaded && (this.isERM ? !!this.risk.closed || !!this.currentUser.level_r_locked || !this.isCurrentScoring : !!this.risk.closed || !!this.userProjectData.locked || !this.isCurrentProjectPeriod);
    },
    tabs: function tabs() {
      var _this2 = this;
      var tabs = createTabsList([TabTypes.DESCRIPTION, TabTypes.LINKS, TabTypes.CHANGELOG], this.baseRiskLink, getLinksLabel(this.risk));
      if (this.scoringId) {
        tabs.forEach(function (tab) {
          return tab.url += "?scoringId=".concat(_this2.scoringId);
        });
      }
      return tabs;
    },
    baseRiskLink: function baseRiskLink() {
      return "".concat(RiskBaseUrls.projects, "/").concat(this.projectId, "/periods/").concat(this.periodId, "/risk/").concat(this.path);
    },
    depHeaderProps: function depHeaderProps() {
      if (!this.isLoaded) {
        return {};
      }
      return {
        id: this.risk.rno + '',
        editableId: this.isERM && !this.isReadOnlyAccess && (this.currentUserIsAdmin || this.currentUser.level_risk >= UserLevels.SUPER),
        idMaxLength: this.isERM ? 9 : 255,
        editableTitle: !this.isReadOnlyAccess,
        title: this.risk.title,
        filesList: this.risk.attachments,
        tabs: this.tabs,
        saveStatus: this.saveStatus,
        idField: 'rno',
        titleField: 'title',
        module: MODULES.RISK,
        moduleId: this.risk.id,
        editableAttachments: !this.isReadOnlyAccess,
        users: this.users
      };
    },
    riskFormatted: function riskFormatted() {
      var customFieldValues = getCustomFieldValues(this.risk);
      return _objectSpread(_objectSpread({}, this.risk), {}, {
        parent: this.risk.parent || 0,
        tags: this.risk.tags.map(function (tag) {
          return tag.id;
        }),
        threatComment: this.risk.threatComment || '',
        vulnerabilityComment: this.risk.vulnerabilityComment || '',
        threatLevelComment: this.risk.threatLevelComment || '',
        customFieldValues: customFieldValues
      });
    },
    isCurrentScoring: function isCurrentScoring() {
      if (!this.scoringId) return true;
      return this.currentScoring && this.currentScoring.id === +this.scoringId;
    },
    showClosedPeriodNotification: function showClosedPeriodNotification() {
      return this.isERM ? !this.isCurrentScoring : !this.isCurrentProjectPeriod;
    },
    infoNotification: function infoNotification() {
      return this.showClosedPeriodNotification ? this.translate.riskIsClosed + this.newestRiskMessage : this.translate.riskIsClosedPleaseReopenBeforeEditing;
    },
    newestRiskMessage: function newestRiskMessage() {
      var url = "<a class=\"uppercase underline font-semibold\" href=\"".concat(this.newestUrl, "\">").concat(this.translate.here, "</a>");
      return this.newestUrl ? ' ' + this.translate.clickSToAccessTheActiveRisk.replace('%s', url) : '';
    },
    currentActivityType: function currentActivityType() {
      var _this3 = this;
      return this.activityTypes.find(function (at) {
        return _this3.risk.activityTypeId === at.id;
      });
    },
    isParentRiskAvailable: function isParentRiskAvailable() {
      var _this4 = this;
      if (!this.risk.parent) return true;
      return !!this.risks.find(function (risk) {
        return risk.rno === _this4.risk.parent;
      });
    },
    isOldRisk: function isOldRisk() {
      return this.isErm ? !this.isCurrentScoring : !this.isCurrentProjectPeriod;
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this5 = this;
      this.isLoaded = false;
      var promises = [this.getRiskData(), this.getRiskManagementLevels(), this.getRiskTypes(), this.getCurrentRiskProject()];
      if (this.isERM) {
        promises.push(this.getCurrentScoring());
      }
      return Promise.all(promises).then(this.getNewestRisk).then(function () {
        return _this5.isLoaded = true;
      });
    },
    getNewestRisk: function getNewestRisk() {
      var _this$currentScoring,
        _this6 = this;
      if (!this.isOldRisk) return;
      var query = (_this$currentScoring = this.currentScoring) !== null && _this$currentScoring !== void 0 && _this$currentScoring.id ? "?scoringId=".concat(this.currentScoring.id) : '';
      return RiskApi.getRiskData(this.projectId, this.currentProject.activePeriodId, this.path, query).then(function (risk) {
        if (!risk.closed) _this6.newestUrl = risk.url;
      }).catch(function (e) {
        if (e.status === '404') _this6.newestUrl = '';
      });
    },
    getRiskManagementLevels: function getRiskManagementLevels() {
      var _this7 = this;
      return RiskApi.getRisksManagementLevels().then(function (_ref) {
        var list = _ref.list;
        return _this7.managementLevels = list;
      });
    },
    getRiskTypes: function getRiskTypes() {
      var _this8 = this;
      return RiskApi.getRiskTypes().then(function (_ref2) {
        var list = _ref2.list;
        return _this8.riskTypes = list;
      });
    },
    getRiskData: function getRiskData() {
      var _this9 = this;
      var query = this.scoringId ? "?scoringId=".concat(this.scoringId) : '';
      return RiskApi.getRiskData(this.projectId, this.periodId, this.path, query, true).then(function (data) {
        _this9.risk = _objectSpread(_objectSpread({}, data), {}, {
          customFieldValues: removeDuplicates(data.customFieldValues, 'fieldId', false)
        });
        _this9.filesList = data.attachments;
        if (!_this9.isERM) {
          _this9.isCurrentProjectPeriod = data.projectPeriod.isCurrent;
        }
      }).catch(function () {
        if (_this9.isPreview) {
          _this9.$emit('accessError');
          return;
        }
        catch403(_this9.$router, 'MyRisks', {
          projectId: _this9.projectId
        });
      });
    },
    getCurrentRiskProject: function getCurrentRiskProject() {
      var _this10 = this;
      return RiskApi.getRiskProjects().then(function (_ref3) {
        var list = _ref3.list;
        _this10.currentProject = list.find(function (item) {
          return +item.id === +_this10.projectId;
        });
      }).catch(function (e) {
        throw e;
      });
    },
    getCurrentScoring: function getCurrentScoring() {
      var _this11 = this;
      return _getCurrentScoring().then(function (currentScoring) {
        _this11.currentScoring = currentScoring;
      });
    },
    riskPropertyChanged: function riskPropertyChanged(arg) {
      var property = arg.property,
        value = arg.value;
      if (property === undefined) {
        this.risk = Object.assign(this.risk, arg);
      } else if (property === 'dailyTeamUsersIds') {
        this.risk.dailyTeamIds.users = value;
      } else if (property === 'dailyTeamOrganisationsIds') {
        this.risk.dailyTeamIds.organisations = value;
      } else if (property === 'responsibleUserIds') {
        if (!value.length && this.risk.confidential) {
          this.risk.confidential = 0;
        }
        this.risk[property] = value;
      } else if (property !== 'attachments') {
        this.risk[property] = value;
      } else {
        this.saveUpdates();
        return;
      }
      this.setUpdateTimer();
    },
    setUpdateTimer: function setUpdateTimer() {
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(this.saveUpdates, this.$autoSaveDebounceTime);
    },
    saveChangesImmediately: function saveChangesImmediately(updatedProperty) {
      this.riskPropertyChanged(updatedProperty);
      this.saveStatusClicked();
    },
    saveUpdates: function saveUpdates() {
      var _this12 = this;
      if (!this.isValidSave()) return;
      this.saveStatus = saveStatus.SAVING;
      var timerId = this.updateTimer;
      RiskApi.updateRisk(this.projectId, this.periodId, this.path, this.riskFormatted).then(function (data) {
        if (_this12.risk.confidential && !_this12.risk.responsibleUserIds.includes(_this12.currentUser.id)) {
          _this12.$router.push("".concat(RiskBaseUrls.projects, "/").concat(_this12.projectId, "/my-risks"));
          return;
        }
        // Parent updated? -> reload route
        if (data.rno != _this12.path && !_this12.isPreview) {
          _this12.$router.push({
            path: data.url
          });
        }
        _this12.saveStatus = saveStatus.SAVED;
        // update the risk if there were no new changes during the save time
        if (timerId === _this12.updateTimer) {
          _this12.risk = _objectSpread(_objectSpread({}, data), {}, {
            customFieldValues: removeDuplicates(data.customFieldValues, 'fieldId', false)
          });
        }
        _this12.$emit('updated', _objectSpread({}, _this12.risk));
        _this12.handleDismissNotification();
      }).catch(function (e) {
        if (e && e.response && e.response.error) {
          _this12.handleShowNotify({
            type: 'error',
            message: _this12.$trans(e.response.error)
          });
        }
        _this12.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    isValidSave: function isValidSave() {
      var _this$risk$rno;
      if (!this.risk.title) {
        this.handleShowNotify({
          type: 'error',
          message: ControlValidationMessages.TITLE_IS_REQUIRED
        });
        return false;
      }
      if (!this.depHeaderProps.editableId) return true;
      var parsedRno = parseInt(this.risk.rno);
      var sameLength = parsedRno.toString().length === ((_this$risk$rno = this.risk.rno) === null || _this$risk$rno === void 0 ? void 0 : _this$risk$rno.toString().length);
      if (isNaN(parsedRno) || !sameLength) {
        this.handleShowNotify({
          type: 'error',
          message: this.translate.riskNumberNotNumeric
        });
        return false;
      }
      return true;
    },
    handleShowNotify: function handleShowNotify(_ref4) {
      var type = _ref4.type,
        message = _ref4.message,
        details = _ref4.details;
      this.notificationType = type;
      this.notificationMessage = message;
      this.notificationDetails = details;
      this.isNotificationShow = true;
      this.$scrollTo(document.body, 100);
    },
    handleDismissNotification: function handleDismissNotification() {
      this.notificationMessage = '';
      this.notificationDetails = '';
      this.isNotificationShow = false;
    },
    saveStatusClicked: function saveStatusClicked() {
      if (this.saveStatus !== saveStatus.SAVED) {
        clearTimeout(this.updateTimer);
        this.saveUpdates();
      }
    },
    onFileUpdate: function onFileUpdate(fileId) {
      var _this$risk;
      updateFileLocally(fileId, (_this$risk = this.risk) === null || _this$risk === void 0 ? void 0 : _this$risk.attachments);
    },
    onDeleteRisk: function onDeleteRisk() {
      this.$emit('deleteItem');
    },
    updateUnderlyingRisk: function updateUnderlyingRisk(updatedRisk) {
      var _this$risk2;
      var index = (_this$risk2 = this.risk) === null || _this$risk2 === void 0 ? void 0 : _this$risk2.children.findIndex(function (child) {
        return +child.id === +updatedRisk.id;
      });
      if (index === undefined) return;
      this.risk.children[index] = updatedRisk;
    }
  }
};