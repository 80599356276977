function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useUserStore } from '@/Store/userStore';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { getProcesses as _getProcesses, getControlRisks as _getControlRisks } from '@/api/controls/controls';
import { getAccounts as _getAccounts, getOrganisationsAndFilterByUserAccess } from '@/api/risma/data';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { UserLevels } from '@/constants/UserLevels';
import { sortAlphabetic } from '@/utils/sort';
import { getAccountsWithChildren } from '@/utils/controls/accounts';
import { getAssociationName } from '@/utils/controls/associations';
import { toPureDateFormat, getDateBeforeToday } from '@/utils/date';
export default {
  name: 'ControlsDashboardFilters',
  components: {
    RismaTitle: RismaTitle,
    RismaDateRangePicker: RismaDateRangePicker,
    RismaSelect: RismaSelect,
    SingleSelect: SingleSelect,
    FeatherIcon: FeatherIcon
  },
  props: {
    localStorageKey: {
      type: String,
      required: false,
      default: 'controls_dashboard_filters'
    }
  },
  emits: ['applyFilters', 'applyFiltersOnLoad'],
  setup: function setup(props) {
    return {
      localStorageData: getLocalStorageComputed(props.localStorageKey, null, true),
      showFilters: getLocalStorageComputed("".concat(props.localStorageKey, "_show_filters"), false, true)
    };
  },
  data: function data() {
    return {
      dateRange: {
        dateStart: toPureDateFormat(getDateBeforeToday(2, 'week')),
        dateEnd: toPureDateFormat(new Date())
      },
      dateFilterSelected: 0,
      selectedUsers: [],
      selectedOrganisations: [],
      selectedActivityTypes: [],
      processes: [],
      selectedProcesses: [],
      accounts: [],
      selectedAccounts: [],
      risks: [],
      selectedRisks: [],
      selectedTags: [],
      isLoaded: false,
      translate: getTranslate['ControlsDashboardFilters']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useActivityTypesStore, {
    activityTypes: 'controlActivityTypes'
  })), mapState(useTagsStore, ['tags'])), mapState(useUserStore, ['currentUser'])), {}, {
    processesName: function processesName() {
      return getAssociationName(this.getSettingValue('risma.process_to_name'), 'Processes');
    },
    accountTitle: function accountTitle() {
      return getAssociationName(this.getSettingValue('risma.account_to_name'), 'Accounts');
    },
    risksTitle: function risksTitle() {
      return getAssociationName(this.getSettingValue('risma.risk_to_name'), 'Control risks');
    },
    showSwitchTitle: function showSwitchTitle() {
      var action = this.showFilters ? this.translate.hide : this.translate.show;
      return "".concat(action, " ").concat(this.translate.filters.toLowerCase());
    },
    dateFilterOptions: function dateFilterOptions() {
      return [{
        value: 0,
        label: this.translate.deadlinedDate
      }, {
        value: 1,
        label: this.translate.completedDate
      }];
    },
    accountsWithChildren: function accountsWithChildren() {
      return getAccountsWithChildren(this.accounts);
    },
    appliedFilters: function appliedFilters() {
      var filters = [];
      if (this.selectedUsers.length) filters.push(this.translate.owner);
      if (this.selectedOrganisations.length) filters.push(this.translate.organisations);
      if (this.selectedActivityTypes.length) filters.push(this.translate.activityType);
      if (this.selectedProcesses.length) filters.push(this.processesName);
      if (this.selectedAccounts.length) filters.push(this.accountTitle);
      if (this.selectedRisks.length) filters.push(this.risksTitle);
      if (this.selectedTags.length) filters.push(this.translate.tags);
      return filters;
    }
  }),
  mounted: function mounted() {
    var _this = this;
    this.getData().then(function () {
      _this.getValuesFromStorage();
      _this.$emit('applyFiltersOnLoad', _this.getSearchData());
      _this.isLoaded = true;
    });
  },
  methods: {
    getData: function getData() {
      return Promise.all([this.getProcesses(), this.getAccounts(), this.getControlRisks(), this.getOrganisations()]);
    },
    getProcesses: function getProcesses() {
      var _this2 = this;
      return _getProcesses().then(function (processes) {
        _this2.processes = sortAlphabetic(processes.list, 'title');
        _this2.processes.unshift({
          id: 0,
          title: _this2.translate.controlsWithNoProcesSet
        });
      });
    },
    getAccounts: function getAccounts() {
      var _this3 = this;
      return _getAccounts().then(function (_ref) {
        var list = _ref.list;
        return _this3.accounts = list;
      });
    },
    getControlRisks: function getControlRisks() {
      var _this4 = this;
      return _getControlRisks().then(function (_ref2) {
        var list = _ref2.list;
        return _this4.risks = list;
      });
    },
    getOrganisations: function getOrganisations() {
      var _this5 = this;
      var filterByUserAccess = this.currentUser.level_controls === UserLevels.PRIVILEGED;
      return getOrganisationsAndFilterByUserAccess(filterByUserAccess).then(function (_ref3) {
        var list = _ref3.list;
        return _this5.organisations = list;
      });
    },
    changeDatePicker: function changeDatePicker(event) {
      if (!event) return;
      this.dateRange.dateStart = event.startDate;
      this.dateRange.dateEnd = event.endDate;
      this.applyFilters();
    },
    applyFilters: function applyFilters() {
      var searchData = this.getSearchData();
      this.localStorageData = searchData;
      this.$emit('applyFilters', searchData);
    },
    getSearchData: function getSearchData() {
      var searchData = {
        active: 1
      };
      var dateStartParam = this.dateFilterSelected ? 'completedAfter' : 'deadlineAfter';
      var dateEndParam = this.dateFilterSelected ? 'completedBefore' : 'deadlineBefore';
      searchData[dateStartParam] = this.dateRange.dateStart;
      searchData[dateEndParam] = this.dateRange.dateEnd;
      if (this.selectedUsers.length) searchData.responsible = this.selectedUsers.map(function (item) {
        return item.id;
      });
      if (this.selectedOrganisations.length) searchData.organisationIds = this.selectedOrganisations.map(function (item) {
        return item.id;
      });
      if (this.selectedActivityTypes.length) searchData.activityTypeIds = this.selectedActivityTypes.map(function (item) {
        return item.id;
      });
      if (this.selectedProcesses.length) searchData.processIds = this.selectedProcesses.map(function (item) {
        return item.id;
      });
      if (this.selectedAccounts.length) searchData.accountIds = this.selectedAccounts.map(function (item) {
        return item.id;
      });
      if (this.selectedRisks.length) searchData.riskIds = this.selectedRisks.map(function (item) {
        return item.id;
      });
      if (this.selectedTags.length) searchData.tagIds = this.selectedTags.map(function (item) {
        return item.id;
      });
      return searchData;
    },
    getValuesFromStorage: function getValuesFromStorage() {
      if (!this.localStorageData) return;
      var _this$localStorageDat = this.localStorageData,
        deadlineAfter = _this$localStorageDat.deadlineAfter,
        deadlineBefore = _this$localStorageDat.deadlineBefore,
        completedAfter = _this$localStorageDat.completedAfter,
        completedBefore = _this$localStorageDat.completedBefore,
        responsible = _this$localStorageDat.responsible,
        organisationIds = _this$localStorageDat.organisationIds,
        activityTypeIds = _this$localStorageDat.activityTypeIds,
        processIds = _this$localStorageDat.processIds,
        accountIds = _this$localStorageDat.accountIds,
        riskIds = _this$localStorageDat.riskIds,
        tagIds = _this$localStorageDat.tagIds;
      try {
        if (deadlineAfter && deadlineBefore) {
          this.dateRange.dateStart = toPureDateFormat(deadlineAfter);
          this.dateRange.dateEnd = toPureDateFormat(deadlineBefore);
          this.dateFilterSelected = 0;
        }
        if (completedAfter && completedBefore) {
          this.dateRange.dateStart = toPureDateFormat(completedAfter);
          this.dateRange.dateEnd = toPureDateFormat(completedBefore);
          this.dateFilterSelected = 1;
        }
        if (responsible) this.selectedUsers = this.users.filter(function (item) {
          return responsible.includes(item.id);
        });
        if (organisationIds) this.selectedOrganisations = this.organisations.filter(function (item) {
          return organisationIds.includes(item.id);
        });
        if (activityTypeIds) this.selectedActivityTypes = this.activityTypes.filter(function (item) {
          return activityTypeIds.includes(item.id);
        });
        if (processIds) this.selectedProcesses = this.processes.filter(function (item) {
          return processIds.includes(item.id);
        });
        if (accountIds) this.selectedAccounts = this.accountsWithChildren.filter(function (item) {
          return accountIds.includes(item.id);
        });
        if (riskIds) this.selectedRisks = this.risks.filter(function (item) {
          return riskIds.includes(item.id);
        });
        if (tagIds) this.selectedTags = this.tags.filter(function (item) {
          return tagIds.includes(item.id);
        });
      } catch (_unused) {
        this.resetFilter();
      }
    },
    resetFilter: function resetFilter() {
      this.localStorageData = null;
      this.selectedUsers = [];
      this.selectedOrganisations = [];
      this.selectedActivityTypes = [];
      this.selectedProcesses = [];
      this.selectedAccounts = [];
      this.selectedRisks = [];
      this.selectedTags = [];
      this.dateFilterSelected = 0;
      this.applyFilters();
    },
    resetDateRange: function resetDateRange() {
      var _this6 = this;
      this.isLoaded = false;
      this.dateRange = {
        dateStart: toPureDateFormat(getDateBeforeToday(2, 'week')),
        dateEnd: toPureDateFormat(new Date())
      };
      this.$nextTick(function () {
        return _this6.isLoaded = true;
      });
    }
  }
};