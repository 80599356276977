import dayjs from 'dayjs';
import { DateFormats } from '@/constants/DateFormats';
export function formatTime(dateTime, format) {
  return dayjs(dateTime).format(format);
}
export function formatCurrentTime(format) {
  return dayjs().format(format);
}
export function formatDateWithFormat(dateTime, dateFormat, format) {
  return dayjs(dateTime, dateFormat).format(format);
}
export function formatTimeUtc(dateTime, format) {
  return dayjs.utc(dateTime).format(format);
}
export function formatToUtcDateTime(date) {
  return dayjs(date, DateFormats.DATE_TIME_FORMAT).utc().format(DateFormats.DATE_TIME_FORMAT_FULL);
}
export function formatUtcToLocalDate(dateTime, format) {
  return dayjs.utc(dateTime).local().format(format);
}
export function toLocalDate(date) {
  if (!date) return '';
  return formatUtcToLocalDate(date, DateFormats.DATE_FORMAT);
}
export function toLocalDateTime(time) {
  if (!time) return '';
  return formatUtcToLocalDate(time, DateFormats.DATE_TIME_FORMAT);
}
export function toLocalDateTimeFull(time) {
  if (!time) return '';
  return formatUtcToLocalDate(time, DateFormats.DATE_TIME_FORMAT_FULL);
}
export function toDateFormat(date) {
  if (!date) return '';
  return formatTimeUtc(date, DateFormats.DATE_FORMAT);
}
export function toPureDateFormat(date) {
  if (!date) return '';
  return dayjs(date).format(DateFormats.DATE_FORMAT);
}
export function toLongLocalDate(date) {
  if (!date) return '';
  return formatUtcToLocalDate(date, DateFormats.DATE_LONG_FORMAT);
}
export function toLongLocalDateTranslated(date) {
  var $trans = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (text) {
    return text;
  };
  var dateStr = toLongLocalDate(date).split(' ');
  dateStr = dateStr.map(function (item) {
    if (isNaN(item)) {
      return $trans(item);
    }
    return item;
  });
  return dateStr.join(' ');
}
export function formatTimeToDateFormat(date) {
  return formatTime(date, DateFormats.DATE_FORMAT);
}
export function formatTimeToDateTimeFormat(date) {
  return formatTime(date, DateFormats.DATE_TIME_FORMAT);
}
export function formatCurrentDateTime() {
  var format = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DateFormats.DATE_FORMAT;
  return formatTime(undefined, format);
}
export function formatDateToFullFormat(date) {
  return formatTime(date, DateFormats.DATE_TIME_FORMAT_FULL);
}
export function addUnitTimeToDate(number) {
  var unitTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'days';
  return dayjs().add(number, unitTime);
}
export function formatCurrentDateWithCustomValues(year, month, date) {
  var dateFormat = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : DateFormats.DATE_TIME_FORMAT_FULL;
  return dayjs().year(year).month(month).date(date).format(dateFormat);
}
export function getCurrentDateWithMove(moveNumber, moveUtil) {
  var dateFormat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DateFormats.DATE_TIME_FORMAT_FULL_LAST;
  return dayjs().add(moveNumber, moveUtil).format(dateFormat);
}
export function addTimeAndFormatDate(date, moveNumber, moveUtil) {
  var dateFormat = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : DateFormats.DATE_TIME_FORMAT;
  return dayjs(date).add(moveNumber, moveUtil).format(dateFormat);
}