import { clickOutside } from '@/utils/directives/clickOutside';
export default {
  name: 'PopUpMenu',
  directives: {
    clickOutside: clickOutside
  },
  introduction: 'a little menu',
  description: 'A pop up menu which you can call an array of menuItems into slots.',
  token: '<pop-up-menu><feather-icon class="menuItem" icon=\'address-card\' :size=\'1\' color=\'black\' /> <feather-icon icon=\'save\' class="menuItem" :size=\'1\' color=\'red\' /> </pop-up-menu>',
  emits: ['dismiss'],
  data: function data() {
    return {
      isMountCall: true
    };
  },
  methods: {
    dismiss: function dismiss() {
      if (this.isMountCall) {
        this.isMountCall = false;
      } else {
        this.$emit('dismiss');
      }
    }
  }
};