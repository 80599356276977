function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { i18n } from '@/i18n';
export function catch403(router, routeName) {
  var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  router.push({
    name: routeName,
    params: _objectSpread(_objectSpread({}, params), {}, {
      detailLoadedError: true
    })
  });
}
function capitalize(string) {
  return string[0].toUpperCase() + string.slice(1);
}
export function check403AccessError(route, entityName) {
  var isDetailLoadedError = route && route.params && route.params.detailLoadedError;
  if (isDetailLoadedError) {
    return "".concat(i18n.t("".concat(capitalize(entityName), " not found")), " / ").concat(i18n.t("You do not have permission to view this ".concat(entityName)));
  }
  return null;
}
export function prepareNotifyErrors(errors, defaultErrorMsg, $trans) {
  var notifyData = {
    type: 'error'
  };
  if (errors.length) {
    var errorHtml = '';
    notifyData.message = $trans(defaultErrorMsg);
    errors.map(function (error) {
      if (typeof error === 'string') {
        errorHtml += "<li>".concat($trans(error), "</li>\n");
      } else if (_typeof(error) === 'object') {
        errorHtml += "<li>".concat($trans(error.text), " ").concat(error.value, "</li>\n");
      }
    });
    notifyData.details = "<ul>".concat(errorHtml, "</ul>");
  }
  return notifyData;
}
export function getErrorMessage(error) {
  //lots of errors, but they all have different structure!
  if (error.response) {
    var resp = error.response;
    if (resp.error) {
      return resp.error + ' ' + (resp.message || '');
    }
    if (resp.data && resp.data.error) {
      return resp.data.error;
    }
    return resp;
  }
  if (error.error) {
    return error.error;
  }
  if (error.fullError) {
    if (error.fullError.response) {
      if (error.fullError.response.error) {
        return error.fullError.response.error;
      }
      return error.fullError.response;
    }
    return error.fullError;
  }
  return error;
}