import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3f184687"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  class: "flex items-center mb-3 pl-2 flex-wrap"
};
var _hoisted_2 = {
  class: "mr-3"
};
var _hoisted_3 = {
  class: "mr-5"
};
var _hoisted_4 = {
  ref: "table",
  class: "min-w-full m-0 p-0 border-separate bg-white"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
  class: "whitespace-nowrap"
};
var _hoisted_7 = ["onClick"];
var _hoisted_8 = ["onMousedown"];
var _hoisted_9 = {
  class: "m-0 p-0 border-0"
};
var _hoisted_10 = {
  key: 1
};
var _hoisted_11 = ["colspan"];
var _hoisted_12 = {
  class: "font-semibold inline-block text-center sticky left-1/2 -translate-x-1/2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$columnsWith, _$data$openedColumnMe2, _$props$columns$$data, _$props$columns$$data2, _$props$columns$$data3, _$props$columns$$data4;
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_draggable = _resolveComponent("draggable");
  var _component_risma_table_column_menu = _resolveComponent("risma-table-column-menu");
  var _component_risma_table_cell = _resolveComponent("risma-table-cell");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", null, [(_$options$columnsWith = $options.columnsWithSelectedFilters) !== null && _$options$columnsWith !== void 0 && _$options$columnsWith.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($data.translate.columnsWithAppliedFilters) + ": ", 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.columnsWithSelectedFilters, function (col) {
    return _openBlock(), _createElementBlock("div", {
      key: col.key,
      class: "flex items-center px-3 py-2 mr-2 mb-2 rounded-md bg-blue-750 text-white"
    }, [_createElementVNode("p", _hoisted_3, _toDisplayString(col.label), 1 /* TEXT */), _createVNode(_component_feather_icon, {
      icon: "x",
      width: "16",
      height: "16",
      class: "stroke-2 text-white cursor-pointer",
      onClick: function onClick($event) {
        return $options.deselectAllFiltersFor(col.key, !!col.filterFreeText);
      }
    }, null, 8 /* PROPS */, ["onClick"])]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: "risma-table-wrapper overflow-x-auto relative z-1",
    style: _normalizeStyle({
      height: $options.heightStyle
    })
  }, [_createElementVNode("table", _hoisted_4, [_createElementVNode("thead", {
    class: "m-0 p-0 border-0 sticky top-0 z-2",
    style: _normalizeStyle({
      height: $data.tableHeaderHeight
    })
  }, [_createVNode(_component_draggable, {
    modelValue: $props.columns,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $props.columns = $event;
    }),
    "component-data": {
      name: 'table-columns',
      tag: 'tr'
    },
    filter: ".non-draggable",
    "prevent-on-filter": true,
    "item-key": "id",
    tag: "tr",
    class: "m-0 p-0 border-0",
    animation: 400,
    onChange: $options.columnsChange
  }, {
    item: _withCtx(function (_ref) {
      var _$data$openedColumnMe;
      var element = _ref.element,
        index = _ref.index;
      return [element.visible || element.static ? (_openBlock(), _createElementBlock("th", {
        key: 0,
        style: _normalizeStyle($options.getHeaderStyles(element)),
        class: _normalizeClass(["relative m-0 pl-3 pr-8 py-2 border-r-2 bg-cultured border-white text-left text-gray-650 font-semibold", [{
          'cursor-pointer': element.sortable,
          'th-selected': ((_$data$openedColumnMe = $data.openedColumnMenu) === null || _$data$openedColumnMe === void 0 ? void 0 : _$data$openedColumnMe.key) === element.key,
          'th-sorted': element.sorted
        }, element.static ? 'non-draggable' : 'cursor-pointer', $options.getHeaderClasses(element), element.sticky ? 'sticky left-0 z-2' : '']]),
        onClick: function onClick($event) {
          return $options.openColumnMenu($event, element, index);
        }
      }, [_createElementVNode("span", _hoisted_6, _toDisplayString(element.label), 1 /* TEXT */), element.sorted ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "absolute right-2 top-1/2 transform -translate-y-1/2 w-6 h-6",
        onClick: _withModifiers(function ($event) {
          return $options.onSortIconClick(element, index);
        }, ["stop"])
      }, [element.sortDirection === $data.SortDirection.ASC ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "arrow-up",
        class: "w-full stroke-2"
      })) : (_openBlock(), _createBlock(_component_feather_icon, {
        key: 1,
        icon: "arrow-down",
        class: "w-full stroke-2"
      }))], 8 /* PROPS */, _hoisted_7)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
        class: "resizer absolute top-0 bottom-0 right-0",
        onMousedown: _withModifiers(function ($event) {
          return $options.onResizerMousedown($event, element.key);
        }, ["prevent"]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(function () {}, ["stop"]))
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_8)], 14 /* CLASS, STYLE, PROPS */, _hoisted_5)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onChange"])], 4 /* STYLE */), _createElementVNode("tbody", _hoisted_9, [_withDirectives(_createElementVNode("tr", {
    class: "sticky h-0 z-2",
    style: _normalizeStyle({
      top: ((_$data$openedColumnMe2 = $data.openedColumnMenu) === null || _$data$openedColumnMe2 === void 0 ? void 0 : _$data$openedColumnMe2.offsetTop) || 0
    })
  }, [$data.openedColumnMenu && $data.openedColumnMenu.key ? _withDirectives((_openBlock(), _createBlock(_component_risma_table_column_menu, {
    key: 0,
    class: "absolute",
    style: _normalizeStyle({
      left: $data.openedColumnMenu.offsetLeft
    }),
    "column-index": $data.openedColumnMenu.colIndex,
    sortable: $props.columns[$data.openedColumnMenu.colIndex].sortable,
    filters: ((_$props$columns$$data = $props.columns[$data.openedColumnMenu.colIndex]) === null || _$props$columns$$data === void 0 ? void 0 : _$props$columns$$data.filters) || [],
    "filter-api": ((_$props$columns$$data2 = $props.columns[$data.openedColumnMenu.colIndex]) === null || _$props$columns$$data2 === void 0 ? void 0 : _$props$columns$$data2.filterApi) || null,
    "filter-free-text": ((_$props$columns$$data3 = $props.columns[$data.openedColumnMenu.colIndex]) === null || _$props$columns$$data3 === void 0 ? void 0 : _$props$columns$$data3.filterFreeText) || null,
    "dates-filter": ((_$props$columns$$data4 = $props.columns[$data.openedColumnMenu.colIndex]) === null || _$props$columns$$data4 === void 0 ? void 0 : _$props$columns$$data4.datesFilter) || null,
    onFiltersChange: _cache[2] || (_cache[2] = function ($event) {
      return $options.onFiltersChange($event, $data.openedColumnMenu.key);
    }),
    onDeselectAllFilters: _cache[3] || (_cache[3] = function ($event) {
      return $options.deselectAllFiltersFor($data.openedColumnMenu.key);
    }),
    onSortByColumn: $options.sortByColumn
  }, null, 8 /* PROPS */, ["style", "column-index", "sortable", "filters", "filter-api", "filter-free-text", "dates-filter", "onSortByColumn"])), [[_directive_click_outside, $options.closeColumnMenu]]) : _createCommentVNode("v-if", true)], 4 /* STYLE */), [[_vShow, $data.openedColumnMenu && $data.openedColumnMenu.key]]), $props.dataset.length ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList($props.dataset, function (row, index) {
    return _openBlock(), _createElementBlock("tr", {
      key: index,
      name: "table-columns",
      class: "m-0 p-0 border-0 browser-default"
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, function (cell, key) {
      return _openBlock(), _createElementBlock("td", {
        key: "".concat(key, "_").concat(index),
        class: _normalizeClass(["align-top", $options.stickyColumnsKeys.includes(key) ? 'sticky left-0 z-1 bg-inherit' : ''])
      }, [_createVNode(_component_risma_table_cell, {
        "cell-data": cell,
        type: cell.type,
        onError: _cache[4] || (_cache[4] = function ($event) {
          return _ctx.$emit('error', $event);
        }),
        onReload: _cache[5] || (_cache[5] = function ($event) {
          return _ctx.$emit('reload');
        }),
        onDismissModal: _cache[6] || (_cache[6] = function ($event) {
          return _ctx.$emit('dismissModal');
        }),
        onDelete: _cache[7] || (_cache[7] = function ($event) {
          return _ctx.$emit('delete', $event);
        }),
        onStarred: _cache[8] || (_cache[8] = function ($event) {
          return _ctx.$emit('starred', $event);
        }),
        onIconClicked: _cache[9] || (_cache[9] = function ($event) {
          return _ctx.$emit('iconClicked', $event);
        }),
        "onUpdate:loading": _cache[10] || (_cache[10] = function ($event) {
          return _ctx.$emit('update:loading', $event);
        })
      }, null, 8 /* PROPS */, ["cell-data", "type"])], 2 /* CLASS */);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */)) : (_openBlock(), _createElementBlock("tr", _hoisted_10, [_createElementVNode("td", {
    colspan: $props.columns.length,
    class: "pt-4 relative"
  }, [_createElementVNode("span", _hoisted_12, _toDisplayString($data.translate.noRecordsMatching), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_11)]))])], 512 /* NEED_PATCH */)], 4 /* STYLE */)]);
}