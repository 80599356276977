function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import * as tagsAPI from '@/api/tags/tags';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import TagCreationModal from '@/components/Molecules/Modal/TagCreationModal';
import Alert from '@/components/Molecules/Modal/Alert';
import { Colors } from '@/Colors';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
export default {
  name: 'AdminTagsFieldWell',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    Alert: Alert,
    FeatherIcon: FeatherIcon,
    TagCreationModal: TagCreationModal,
    NotificationPopUp: NotificationPopUp
  },
  mixins: [NotificationPopUpMixin],
  data: function data() {
    return {
      color: Colors,
      tagCreationName: '',
      showCreateModal: false,
      showModifyModal: false,
      deleteModal: false,
      selectedItemTag: '',
      modalErrorMessage: '',
      tableCellClasses: 'px-3 py-2 border-b-2 border-slate-200 text-left break-words',
      translate: getTranslate['AdminTagsFieldWell']()
    };
  },
  computed: _objectSpread({}, mapState(useTagsStore, ['tags'])),
  methods: _objectSpread(_objectSpread({}, mapActions(useTagsStore, {
    removeFromStore: 'deleteTag'
  })), {}, {
    toggleCreateModal: function toggleCreateModal() {
      this.showCreateModal = true;
      this.showModifyModal = false;
    },
    toggleUpdateSelectedModal: function toggleUpdateSelectedModal(tagId) {
      this.selectedItemTag = tagId;
      this.showModifyModal = true;
      this.showCreateModal = false;
    },
    toggleDeleteModalAndAddSelected: function toggleDeleteModalAndAddSelected(tagId) {
      this.selectedItemTag = tagId;
      this.showCreateModal = false;
      this.showModifyModal = false;
      this.deleteModal = !this.deleteModal;
    },
    toggleModal: function toggleModal() {
      this.showCreateModal = false;
      this.showModifyModal = false;
    },
    onCreateTag: function onCreateTag() {
      this.toggleModal();
      this.setNotificationData({
        title: this.translate.tagHasBeenCreated,
        type: 'success'
      });
    },
    onModifyTag: function onModifyTag() {
      this.toggleModal();
      this.setNotificationData({
        title: this.translate.tagHasBeenUpdated,
        type: 'success'
      });
    },
    deleteTag: function deleteTag(tagId) {
      var _this = this;
      tagsAPI.remove(tagId).then(function (response) {
        return _this.handleDeleteTagSuccess(response, tagId);
      }).catch(this.handleDeleteTagFailure);
    },
    handleDeleteTagSuccess: function handleDeleteTagSuccess(response, tagId) {
      if (response === 'OK') {
        this.removeFromStore(tagId);
      }
      this.toggleDeleteModalAndAddSelected('');
      this.setNotificationData({
        title: this.translate.tagHasBeenDeleted,
        type: 'success'
      });
    },
    handleDeleteTagFailure: function handleDeleteTagFailure(error) {
      this.toggleDeleteModalAndAddSelected('');
      this.setNotificationData({
        title: error.response.error,
        type: 'error'
      });
    }
  })
};