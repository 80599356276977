var _DelegationModalSetti;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var DelegationTypes = {
  users: 'users',
  organisations: 'organisations',
  tags: 'tags',
  initiativeTypes: 'initiativeTypes',
  initiatives: 'initiatives',
  riskManagementLevels: 'riskManagementLevels',
  solutionId: 'solutionId'
};
export var DelegationItemTypes = {
  INITIATIVE_TYPE: 'initiativeType',
  RESPONSIBLE_USER: 'responsibleUserIds',
  REVIEWER_USER: 'reviewersUserIds',
  ESCALATION_USER: 'escalationUserIds',
  ACCOUNTABLE_USER: 'accountableUserIds',
  CONSULTED_USER: 'consultedUserIds',
  INFORMED_USER: 'informedUserIds',
  NOTIFICATION_USER: 'notificationsUserIds',
  ORGANISATION: 'organisationIds',
  MANAGEMENT_LEVEL: 'managementLevelId',
  DAILY_TEAM_USER: 'dailyTeamUsersIds',
  DAILY_TEAM_ORGANISATION: 'dailyTeamOrganisationsIds',
  TAG: 'tagIds',
  DEPENDING: 'dependingOn'
};
export var DelegationModalSettings = (_DelegationModalSetti = {}, _defineProperty(_DelegationModalSetti, DelegationTypes.users, {
  title: 'Users',
  label: 'Add new users',
  searchPlaceholder: 'Search users',
  searchFields: ['name', 'username', 'initials', 'email', 'fullname'],
  subtitle: 'Added users',
  titleField: 'fullname',
  shortTitleField: 'initials',
  labelKey: 'display_name',
  enableAssignToMe: true,
  type: DelegationTypes.users
}), _defineProperty(_DelegationModalSetti, DelegationTypes.organisations, {
  title: 'Organisations',
  label: 'Add new organisations',
  searchPlaceholder: 'Search organisations',
  searchFields: ['name', 'visible_name'],
  subtitle: 'Added organisations',
  titleField: 'visible_name',
  labelKey: 'visible_name',
  type: DelegationTypes.organisations
}), _defineProperty(_DelegationModalSetti, DelegationTypes.tags, {
  title: 'Tags',
  label: 'Add new tags',
  searchPlaceholder: 'Search tags',
  searchFields: ['tag'],
  subtitle: 'Added tags',
  titleField: 'tag',
  labelKey: 'tag',
  type: DelegationTypes.tags
}), _defineProperty(_DelegationModalSetti, DelegationTypes.initiativeTypes, {
  title: 'Activity type',
  label: 'Choose activity type',
  searchPlaceholder: 'Search activity types',
  searchFields: ['label'],
  subtitle: 'Activity type',
  titleField: 'label',
  labelKey: 'label',
  isMultiple: false,
  type: DelegationTypes.initiativeTypes
}), _defineProperty(_DelegationModalSetti, DelegationTypes.initiatives, {
  title: 'Depending on',
  label: 'Add new depending on',
  searchPlaceholder: 'Search actions',
  searchFields: ['label'],
  subtitle: 'Add depending on',
  titleField: 'label',
  labelKey: 'label',
  type: DelegationTypes.initiatives
}), _defineProperty(_DelegationModalSetti, DelegationTypes.riskManagementLevels, {
  title: 'Management level',
  label: 'Choose management level',
  searchPlaceholder: 'Search management levels',
  searchFields: ['name'],
  subtitle: 'Management level',
  titleField: 'name',
  labelKey: 'name',
  isMultiple: false,
  type: DelegationTypes.riskManagementLevels
}), _defineProperty(_DelegationModalSetti, DelegationTypes.solutionId, {
  title: 'Add solution ID',
  label: 'Add solution ID',
  searchPlaceholder: 'Search on solution ID\'s',
  searchFields: ['title'],
  subtitle: 'Added solution ID\'s',
  titleField: 'title',
  labelKey: 'title',
  isMultiple: true,
  type: DelegationTypes.solutionId
}), _DelegationModalSetti);