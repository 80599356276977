function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getTranslate } from './translate';
import { getWorkflowStatusesByModule, updateWorkflowStatusesByModule, updateWorkflowStatus as _updateWorkflowStatus } from '@/api/admin/workflow';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Alert from '@/components/Molecules/Modal/Alert';
import Notification from '@/components/Molecules/Notification';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import { getWorkflowStatusTitle } from '@/constants/admin/WorkflowStatuses';
import { MODULES } from '@/constants/modules';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
export default {
  name: 'WorkflowStatuses',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    InputField: InputField,
    SingleSelect: SingleSelect,
    Alert: Alert,
    Notification: Notification,
    NotificationPopUp: NotificationPopUp
  },
  mixins: [NotificationPopUpMixin],
  data: function data() {
    return {
      workflowStatuses: [],
      showWorkflowStatusModal: false,
      showDeleteWorkflowStatusAlert: false,
      initialTitle: '',
      initialOrder: null,
      workflowStatus: {
        id: null,
        title: '',
        module: MODULES.CONTRACTS,
        order: ''
      },
      getWorkflowStatusTitle: getWorkflowStatusTitle,
      notificationError: '',
      translate: getTranslate['WorkflowStatuses']()
    };
  },
  computed: {
    creatingMode: function creatingMode() {
      var _this$workflowStatus;
      return !((_this$workflowStatus = this.workflowStatus) !== null && _this$workflowStatus !== void 0 && _this$workflowStatus.id);
    },
    otherStatuses: function otherStatuses() {
      var _this = this;
      var allStatuses = this.workflowStatuses;
      if (!this.creatingMode) {
        allStatuses = allStatuses.filter(function (status) {
          return status.id !== _this.workflowStatus.id;
        });
      }
      return allStatuses.map(function (status) {
        return {
          id: status.id,
          title: status.title
        };
      });
    },
    modalHeaderText: function modalHeaderText() {
      return this.creatingMode ? this.translate.addWorkflowStatus : this.translate.updateWorkflowStatus;
    },
    okButtonText: function okButtonText() {
      return this.creatingMode ? this.translate.create : this.translate.save;
    },
    orderOptions: function orderOptions() {
      var totalNumber = this.workflowStatus.id ? this.workflowStatuses.length : this.workflowStatuses.length + 1;
      return _toConsumableArray(Array(totalNumber).keys()).map(function (value, index) {
        return {
          label: index + 1 + '',
          value: index + 1
        };
      });
    }
  },
  mounted: function mounted() {
    this.getWorkflowStatuses();
  },
  methods: {
    handleAddWorkflowStatus: function handleAddWorkflowStatus() {
      this.resetWorkFlowStatus();
      this.toggleWorkflowStatusModal();
    },
    handleEditWorkflowStatus: function handleEditWorkflowStatus(id) {
      var workflowStatus = this.workflowStatuses.find(function (status) {
        return status.id === id;
      });
      this.workflowStatus = _objectSpread(_objectSpread({}, workflowStatus), {}, {
        title: getWorkflowStatusTitle(workflowStatus.title)
      });
      this.initialTitle = this.workflowStatus.title;
      this.initialOrder = this.workflowStatus.order;
      this.toggleWorkflowStatusModal();
    },
    resetWorkFlowStatus: function resetWorkFlowStatus() {
      this.workflowStatus = {
        id: null,
        title: '',
        module: MODULES.CONTRACTS,
        order: ''
      };
    },
    handleSaveWorkflowStatus: function handleSaveWorkflowStatus() {
      if (!this.validateStatus()) {
        return;
      }
      this.creatingMode ? this.addWorkflowStatus() : this.updateWorkflowStatus();
    },
    handleDeleteStatus: function handleDeleteStatus(status) {
      this.workflowStatus = status;
      this.showDeleteWorkflowStatusAlert = true;
    },
    toggleWorkflowStatusModal: function toggleWorkflowStatusModal() {
      this.showWorkflowStatusModal = !this.showWorkflowStatusModal;
      this.notificationError = '';
    },
    closeDeleteWorkflowStatusAlert: function closeDeleteWorkflowStatusAlert() {
      this.showDeleteWorkflowStatusAlert = false;
    },
    getWorkflowStatuses: function getWorkflowStatuses() {
      var _this2 = this;
      return getWorkflowStatusesByModule(MODULES.CONTRACTS) //temporary it is needed to be only from contracts module
      .then(function (_ref) {
        var list = _ref.list;
        return _this2.workflowStatuses = list;
      });
    },
    updateWorkflowStatus: function updateWorkflowStatus() {
      var _this3 = this;
      _updateWorkflowStatus(MODULES.CONTRACTS, this.workflowStatus).then(function () {
        _this3.handleInstantNotification({
          message: "".concat(_this3.translate.workflowStatus, " ").concat(_this3.translate.updated),
          footer: _this3.workflowStatus.title
        });
        return _this3.getWorkflowStatuses();
      }).then(function () {
        return _this3.toggleWorkflowStatusModal();
      });
    },
    addWorkflowStatus: function addWorkflowStatus() {
      var _this4 = this;
      var _this$workflowStatus2 = this.workflowStatus,
        order = _this$workflowStatus2.order,
        module = _this$workflowStatus2.module,
        title = _this$workflowStatus2.title;
      var workflowStatuses = this.otherStatuses.slice(0);
      if (!order) {
        workflowStatuses.push({
          title: title
        });
      } else {
        workflowStatuses.splice(order - 1, 0, {
          title: title
        });
      }
      updateWorkflowStatusesByModule(module, {
        workflowStatuses: workflowStatuses
      }).then(function (_ref2) {
        var list = _ref2.list;
        _this4.handleInstantNotification({
          message: "".concat(_this4.translate.workflowStatus, " ").concat(_this4.translate.created.toLowerCase()),
          footer: title
        });
        _this4.workflowStatuses = list;
        _this4.toggleWorkflowStatusModal();
      });
    },
    deleteWorkflowStatus: function deleteWorkflowStatus() {
      var _this5 = this;
      var _this$workflowStatus3 = this.workflowStatus,
        module = _this$workflowStatus3.module,
        title = _this$workflowStatus3.title;
      updateWorkflowStatusesByModule(module, {
        workflowStatuses: this.otherStatuses
      }).then(function (_ref3) {
        var list = _ref3.list;
        _this5.handleInstantNotification({
          message: "".concat(_this5.translate.workflowStatus, " ").concat(_this5.translate.deleted),
          footer: title
        });
        _this5.workflowStatuses = list;
        _this5.closeDeleteWorkflowStatusAlert();
      });
    },
    validateStatus: function validateStatus() {
      this.notificationError = '';
      var _this$workflowStatus4 = this.workflowStatus,
        title = _this$workflowStatus4.title,
        order = _this$workflowStatus4.order;
      if (!title) {
        this.notificationError = this.translate.pleaseFillOutTheTitle;
        return false;
      }
      if (this.otherStatuses.map(function (st) {
        return st.title;
      }).includes(title)) {
        this.notificationError = this.translate.titleHaveToBeUnique;
        return false;
      }
      if (!this.creatingMode && this.initialTitle === title && this.initialOrder === order) {
        this.notificationError = this.translate.noChangesToSave;
        return false;
      }
      return true;
    }
  }
};