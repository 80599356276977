function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
var _excluded = ["activityTypeId", "activityType"];
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { filterArrayByListOfPropValues } from '@/utils/Utils';
var KPI_TYPES = {
  multiSelect: 'multi_select',
  singleSelect: 'single_select'
};
export default {
  name: 'KPIParameters',
  introduction: 'Component shows kpi parameters',
  description: 'This component is part of KPITable',
  components: {
    RismaLabel: RismaLabel,
    RismaSelect: RismaSelect,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect
  },
  props: {
    metadata: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    values: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['update'],
  data: function data() {
    return {
      TYPES: KPI_TYPES,
      translate: {
        select: this.$trans('Select')
      }
    };
  },
  computed: {
    selectedOptions: function selectedOptions() {
      var data = {};
      for (var key in this.values) {
        var _this$metadata$parame, _this$metadata$parame2;
        if (((_this$metadata$parame = this.metadata.parameters[key]) === null || _this$metadata$parame === void 0 ? void 0 : _this$metadata$parame.type) === this.TYPES.multiSelect) {
          data[key] = this.getSelectedArray(this.metadata.parameters[key].options, this.values[key]);
        }
        if (((_this$metadata$parame2 = this.metadata.parameters[key]) === null || _this$metadata$parame2 === void 0 ? void 0 : _this$metadata$parame2.type) === this.TYPES.singleSelect) {
          data[key] = this.values[key];
        }
      }
      return data;
    }
  },
  methods: {
    handleSelect: function handleSelect(selectedValues, paramName) {
      var ids = selectedValues.map(function (item) {
        return +item.value;
      });
      this.updateData(ids, paramName);
    },
    getSelectedArray: function getSelectedArray(list, value) {
      if (!value) return [];
      if (Array.isArray(value)) {
        return filterArrayByListOfPropValues(list, value, 'value');
      }
      return filterArrayByListOfPropValues(list, [value], 'value');
    },
    updateData: function updateData(value, paramName) {
      var _this$values = this.values,
        activityTypeId = _this$values.activityTypeId,
        activityType = _this$values.activityType,
        rest = _objectWithoutProperties(_this$values, _excluded);
      this.$emit('update', _objectSpread(_objectSpread({}, rest), {}, _defineProperty({
        activityType: activityType || undefined,
        activityTypeId: activityTypeId || []
      }, paramName, value)));
    }
  }
};