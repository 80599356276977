export default {
  name: 'InlineSelect',
  props: {
    modelValue: {
      type: [Number, String],
      required: true,
      note: 'v-model'
    },
    options: {
      type: Array,
      required: true
    },
    valueField: {
      type: String,
      required: false,
      default: 'value'
    },
    optionStyles: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['update:modelValue'],
  methods: {
    onInput: function onInput(event) {
      this.$emit('update:modelValue', event.target.value);
    }
  }
};