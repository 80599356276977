import { defineStore } from 'pinia';
export var useMainStore = defineStore('mainStore', {
  state: function state() {
    return {
      openPreviewIds: new Map()
    };
  },
  actions: {
    openPreviewModal: function openPreviewModal(id) {
      this.openPreviewIds.set(id, true);
    },
    closePreviewModalById: function closePreviewModalById(id) {
      this.openPreviewIds.delete(id);
    },
    closeAllPreviews: function closeAllPreviews() {
      this.openPreviewIds.clear();
    }
  },
  getters: {
    previewGlobalStatus: function previewGlobalStatus() {
      var _this = this;
      return function (id) {
        return !!_this.openPreviewIds.get(id);
      };
    }
  }
});