import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-baec7dfc"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "checkboxes"
};
var _hoisted_2 = {
  key: 0,
  class: "italic font-bold text-gray-610 mr-3 flex-grow"
};
var _hoisted_3 = {
  class: "checkbox-wrapper mr-3 flex items-center justify-center relative"
};
var _hoisted_4 = ["disabled", "checked", "onChange"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, function (option) {
    return _openBlock(), _createElementBlock("div", {
      key: "".concat(option.value).concat(option.label),
      class: "flex items-center m-2"
    }, [$props.hidden.indexOf(option.value) === -1 ? (_openBlock(), _createElementBlock("label", {
      key: 0,
      class: _normalizeClass(["flex items-center w-full", {
        'flex-row-reverse': $props.labelPosition === $data.LabelPositions.RIGHT,
        'cursor-pointer': !option.disabled && $props.disabled.indexOf(option.value) === -1
      }])
    }, [option.label ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(option.label), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_3, [_createElementVNode("input", {
      style: _normalizeStyle($options.checkboxStyles),
      disabled: option.disabled || $props.disabled.indexOf(option.value) !== -1,
      type: "checkbox",
      checked: $props.modelValue && $props.modelValue.indexOf(option.value) !== -1 || option.checked,
      onChange: function onChange($event) {
        return $options.handleChange(option.value, $event.target.checked);
      }
    }, null, 44 /* STYLE, PROPS, HYDRATE_EVENTS */, _hoisted_4), _createVNode(_component_feather_icon, {
      icon: $props.checkIcon,
      width: $props.checkboxSize,
      height: $props.checkboxSize,
      class: "check-icon absolute top-0 left-0 text-white opacity-0 stroke-2"
    }, null, 8 /* PROPS */, ["icon", "width", "height"])])], 2 /* CLASS */)) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))]);
}