import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-75931e91"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "cards-wrapper"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 1,
  class: "cards-wrapper-compact"
};
var _hoisted_4 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_compact_title_data_component = _resolveComponent("compact-title-data-component");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_router_link = _resolveComponent("router-link");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.isCardShows ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("h2", null, [_createTextVNode(_toDisplayString(_ctx.$trans($props.module.label)) + " " + _toDisplayString($data.activities.length > -1 ? '(' + $data.activities.length + ')' : '') + " ", 1 /* TEXT */), _createElementVNode("span", {
    class: "toggle-btn header-inline",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.toggleVisibility();
    })
  }, [_createVNode(_component_feather_icon, {
    class: "inline-block",
    icon: 'chevron-' + ($props.module.hidden ? 'down' : 'up')
  }, null, 8 /* PROPS */, ["icon"]), _createTextVNode(" " + _toDisplayString($props.module.hidden ? $data.translate.show : $data.translate.hide), 1 /* TEXT */)])]), !$props.module.hidden && ($props.module.view !== $data.ControlViews.TILES || $props.module.view !== $data.ControlViews.EXPANDED) ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass({
      'row': $props.module.name !== $data.MODULES.CONTROLS
    })
  }, [_createCommentVNode(" Use dynamic components to catch module card "), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.cardsDataset, function (activity, index) {
    return _openBlock(), _createBlock(_resolveDynamicComponent('card-' + $props.module.name), {
      key: index,
      view: $props.module.view,
      "button-type": $props.module.buttonType,
      "additional-fields": $props.module.additionalFields,
      activity: activity,
      organisations: $props.organisations,
      class: _normalizeClass($options.moduleCSS),
      onButtonClick: $options.buttonClick
    }, null, 8 /* PROPS */, ["view", "button-type", "additional-fields", "activity", "organisations", "class", "onButtonClick"]);
  }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */)) : _createCommentVNode("v-if", true), !$props.module.hidden && $props.module.view === $data.ControlViews.COMPACT ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_static_table, {
    "visibility-switch": true,
    "visibility-storage-name": $options.getVisibilityStorageName(),
    "visibility-default-columns": $options.cardsCompactDefaultColumns,
    dataset: $options.cardsCompactDataset,
    columns: $options.cardsCompactColumnTitles,
    "visibility-hidden-options": $options.cardsCompactColumnVisibilityHiddenOptions
  }, {
    "_custom-title": _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createVNode(_component_compact_title_data_component, {
        "slot-data": slotData
      }, null, 8 /* PROPS */, ["slot-data"])];
    }),
    "_custom-button": _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [!$props.module.buttonType ? (_openBlock(), _createElementBlock("div", _hoisted_4)) : $props.module.buttonType === $data.ControlButtonTypes.REVIEW_CONTROL ? (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        to: slotData.url
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_risma_button, {
            text: slotData.text,
            type: "save"
          }, null, 8 /* PROPS */, ["text"])];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])) : _ctx.isCustomButton ? (_openBlock(), _createBlock(_component_risma_button, {
        key: 2,
        text: slotData.text,
        "data-control-id": slotData.id,
        "data-control-cpath": slotData.cpath,
        "data-action": "completeControl",
        "data-complete-again": slotData.completeAgain,
        type: "save"
      }, null, 8 /* PROPS */, ["text", "data-control-id", "data-control-cpath", "data-complete-again"])) : (_openBlock(), _createBlock(_component_risma_button, {
        key: 3,
        text: slotData.title,
        type: "util",
        disabled: "disabled"
      }, null, 8 /* PROPS */, ["text"]))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visibility-storage-name", "visibility-default-columns", "dataset", "columns", "visibility-hidden-options"])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]);
}