function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import i18n from '@/i18n';
export var filterKeysMap = {
  threat: 'threats',
  levelOfThreats: 'levelOfThreat',
  tags: 'tag',
  linkedSystems: 'systems',
  managementLevelIds: 'managementLevel',
  managementLevels: 'managementLevel',
  riskTypes: 'riskType',
  approval: 'approvalStatus',
  organisationGroups: 'organisationGroup',
  organisationsList: 'organisationIds',
  topRisks: 'topRPI',
  parentRisks: 'parentRiskId',
  activityTypes: 'activityTypeIds'
};
export var filtersIdentifierMap = {
  parentRisks: 'rno'
};
export var wrongCustomFields = {
  'Z-axis': 'zAxis',
  'Impact': 'impact'
};
export var filterOptionsErm = function filterOptionsErm() {
  return {
    status: [{
      label: i18n.t('All'),
      value: 'all',
      id: 'all'
    }, {
      label: i18n.t('Open'),
      value: '0',
      id: '0'
    }, {
      label: i18n.t('Closed'),
      value: '1',
      id: '1'
    }],
    approval: [{
      label: i18n.t('Not approved'),
      value: '0',
      id: '0'
    }, {
      label: i18n.t('Approved'),
      value: '1',
      id: '1'
    }],
    updated: [{
      label: i18n.t('All'),
      value: 'all',
      id: 'all'
    }, {
      label: i18n.t('Not updated'),
      value: '0',
      id: '0'
    }, {
      label: i18n.t('Updated'),
      value: '1',
      id: '1'
    }],
    topRisks: [{
      label: i18n.t('All'),
      value: 'all',
      id: 'all'
    }, {
      label: i18n.t('Top 10 RPI'),
      value: '10',
      id: '10'
    }, {
      label: i18n.t('Top 15 RPI'),
      value: '15',
      id: '15'
    }]
  };
};
export var getOptionsFromScoring = function getOptionsFromScoring(scoring, label) {
  var result = [{
    label: scoring["".concat(label, "_label")],
    value: "".concat(label, "_label")
  }];
  var num = 1;
  while (scoring["".concat(label, "Other").concat(num, "Label")]) {
    result.push({
      label: scoring["".concat(label, "Other").concat(num, "Label")],
      value: "".concat(label, "Other").concat(num, "Label")
    });
    num++;
  }
  return result;
};
export var prepareReportOptions = function prepareReportOptions(filterObjValues, riskStateMatrix) {
  var result = {};
  Object.entries(filterObjValues).forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    if (Array.isArray(value)) {
      result[key] = value.map(function (item) {
        return item.id || item;
      });
    } else {
      result[key] = value;
    }
  });
  return _objectSpread(_objectSpread({}, result), {}, {
    matrix: riskStateMatrix
  });
};
export var getFiltersFromReportOptions = function getFiltersFromReportOptions(storageValues, optionsObj) {
  var period = storageValues && optionsObj.periods.find(function (period) {
    return period.id === storageValues.periodId;
  });
  if (!storageValues || !storageValues.periodId || !period) return {};
  var filters = {};
  var matrix = storageValues.matrix;
  Object.entries(storageValues).forEach(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      key = _ref4[0],
      value = _ref4[1];
    if (Array.isArray(value)) {
      var isUsers = ['dailyOwner', 'primaryOwner', 'responsible'].indexOf(key) !== -1;
      var optionsList = isUsers ? optionsObj.users : optionsObj[key] || [];
      filters[key] = optionsList.filter(function (item) {
        return value.indexOf(item.id) !== -1;
      });
    } else {
      filters[key] = value;
    }
  });
  return {
    filters: filters,
    matrix: matrix
  };
};