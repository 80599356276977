function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import ControlPanelSettings from '@/components/Organisms/Admin/ControlPanelSettings';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { SETTINGS_ARRAY } from '@/constants/admin/Settings';
export default {
  name: 'AdminControlPanel',
  description: 'Page with general control panel section',
  token: '<admin-control-panel />',
  components: {
    ControlPanelSettings: ControlPanelSettings
  },
  mixins: [ProcessLibraryTitleMixin],
  emits: ['updateHeader'],
  data: function data() {
    return {
      translate: getTranslate['AdminControlPanel']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useSettingsStore, ['isInformationAssetsDisabled'])), {}, {
    settingsGroups: function settingsGroups() {
      var settingsGroups = _defineProperty({}, 'Control panel', _objectSpread({}, SETTINGS_ARRAY['General']));
      if (this.getSettingValue('app.module_execution_enabled')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/actions/my'] = this.translate.myActions;
      }
      if (this.getSettingValue('app.module_controls_enabled')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/controls2/my'] = this.translate.myControls;
      }
      if (this.getSettingValue('app.module_risk_enabled')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/risk2/projects/1/my-risks'] = this.translate.myRisks + ' (ERM)';
      }
      if (this.getSettingValue('app.module_compliance_enabled')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/compliance'] = this.translate.compliance;
      }
      if (this.getSettingValue('feature.process_tree')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/compliance/processtree'] = this.processLibraryTitle;
      }
      if (this.getSettingValue('app.module_incidents_enabled')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/incident/list'] = this.translate.incidents;
      }
      if (this.getSettingValue('feature.performance_report_enabled')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/performance-report/dashboard'] = 'GRC Intelligence Center';
      }
      if (this.getSettingValue('feature.welcome_page_menu')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/userguide'] = this.getSettingValue('app.user_guide_title');
      }
      if (this.isInformationAssetsDisabled) {
        delete settingsGroups['Control panel']['feature.asset_management_menu'];
      }
      return settingsGroups;
    }
  })
};