import LinkItem from '@/components/Atoms/Containers/LinkItem';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'RismaTags',
  introduction: 'List of tags',
  description: 'Molecule - list of LinkItem components',
  token: '<risma-tags items="data" />',
  components: {
    LinkItem: LinkItem,
    FeatherIcon: FeatherIcon
  },
  props: {
    items: {
      type: Array,
      required: true,
      note: 'Main prop - array of tags'
    },
    module: {
      type: String,
      default: 'file',
      note: 'Which module is the link from. Chooses the icon if asTag is false'
    },
    asTag: {
      type: Boolean,
      default: true,
      note: 'A little different UI (without icons)'
    },
    asRouterLink: {
      type: Boolean,
      default: false,
      note: 'A vue router-link in stead of a href'
    },
    hasDeleteIcon: {
      required: false,
      type: Boolean,
      default: false,
      note: 'If you need to get a list of component with delete sign'
    }
  }
};