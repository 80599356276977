import i18n from '@/i18n';
export var getTranslate = {
  ProcessLibraryNodeCreationModal: function ProcessLibraryNodeCreationModal() {
    return {
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      newProcessLibraryNode: "".concat(i18n.t('New'), " ").concat(i18n.t('Process library node'))
    };
  }
};