/**
 * isValidUrl
 * @param {String} url - Non-empty string
 */
export function isValidUrl(url) {
  // eslint-disable-next-line no-useless-escape
  var urlRegExp = /^([a-z0-9]+:\/\/www\.|[a-z0-9]+:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*(:[0-9]{1,5})?(?!\/\/)(\/.*)?$/gmi;
  return urlRegExp.test(url);
}
export function isDomainValid(domain) {
  // eslint-disable-next-line no-useless-escape
  return /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/.test(domain);
}
export function isEndpointValid(endpoint) {
  // eslint-disable-next-line no-useless-escape
  var regExp = new RegExp(/^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\/$/g);
  return regExp.test(endpoint);
}
export function isValidLocalPath(path) {
  // eslint-disable-next-line no-useless-escape
  var regExp = /(^(file:(\/\/\/?))?(?:([a-zA-Z]:)?|(\\\\|\/\/)[\w\.]+(\\|\/)[\w.$]+)((\\|\/)|(\\\\|\/\/))(?:[\w ]+(\\|\/))*\w([\w. ])+[\.][a-zA-Z]+$)/;
  return regExp.test(path);
}
export function isValidAsUrlAndLocalPath(link) {
  return isValidUrl(link) || isValidLocalPath(link);
}

/**
 * isValidString checks that string non-empty
 * @param {String} string - String
 */
export function isValidString(string) {
  var result = false;
  if (typeof string !== 'string') {
    return result;
  }
  if (string.trim() === '') {
    return result;
  }
  return true;
}
export function isFileAllowed(file, attachmentsAllowed) {
  var allowed = false;
  Object.keys(attachmentsAllowed).forEach(function (ext) {
    var parts = file.filename.split('.');
    var fileExt = parts[parts.length - 1];
    if (fileExt.toLowerCase() === ext.toLowerCase()) {
      allowed = true;
    }
  });
  return allowed;
}
export function isTextAreaFilled(textPassed) {
  var text = textPassed ? textPassed.replace(/↵/g, '') : '';
  return text.trim() !== '';
}

/**
 * Enterprise security password validation
 *
 * @param password
 * @returns {boolean}
 */
export function isPasswordValid(password) {
  var regExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{14,})');
  return regExp.test(password);
}
export function isEmailValid(email) {
  var emailReqExp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return !!email.trim().match(emailReqExp);
}
export function isOnlyLettersNumbersSpacesDashes(str) {
  return /^[A-Za-z0-9 -]*$/.test(str);
}
export function isValidVatNo(vatNo, countryCode) {
  if (!vatNo) {
    return true;
  }
  countryCode = countryCode && countryCode.toLowerCase() || '';
  if (!['dk', 'de', 'se', 'no'].includes(countryCode)) {
    return true;
  }
  var regexes = {
    dk: /^[0-9]{8}$/,
    de: /^[0-9]{9}$/,
    se: /^[0-9]{10}(0\d)?$/,
    no: /^[0-9]{9}(MVA)?$/i
  };
  return regexes[countryCode].test(vatNo);
}