import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 1,
  class: "mb-4 pb-6 border-b-2 border-gray-250"
};
var _hoisted_4 = {
  key: 2,
  class: "mb-4 pb-6 border-b-2 border-gray-250"
};
var _hoisted_5 = {
  key: 4,
  class: "flex items-baseline mb-4"
};
var _hoisted_6 = ["checked"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_activity_type_selector = _resolveComponent("activity-type-selector");
  var _component_single_select = _resolveComponent("single-select");
  var _component_solution_id_relation_details = _resolveComponent("solution-id-relation-details");
  var _component_control_type = _resolveComponent("control-type");
  var _component_control_details_associations = _resolveComponent("control-details-associations");
  return $props.control.id ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$data.isMotherConfirmationShow ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_alert, {
    body: $data.translate.youAreAboutToChangeThisControlToADaughter,
    header: $data.translate.areYouSure,
    "button-ok-text": $data.translate.confirm,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.acceptMotherConfirmation,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.isMotherConfirmationShow = false;
    })
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept"])])) : _createCommentVNode("v-if", true), $props.activityTypes.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
    class: "w-11/12",
    type: "medium",
    title: $data.translate.activityType
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_activity_type_selector, {
    "activity-types": $props.activityTypes,
    "model-value": $props.control.activityTypeId,
    disabled: $options.inputsDisabled,
    "onUpdate:modelValue": $options.activityTypeChanged
  }, null, 8 /* PROPS */, ["activity-types", "model-value", "disabled", "onUpdate:modelValue"])])) : _createCommentVNode("v-if", true), !$props.control.isMothercontrol ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_single_select, {
    title: $data.translate.motherControl,
    "model-value": $props.control.mothercontrolId,
    options: $options.controlsFormatted,
    "un-sorted": true,
    "with-reset": !$options.inputsDisabled,
    placeholder: $data.translate.choose,
    disabled: $options.inputsDisabled,
    "onUpdate:modelValue": $options.handleMotherControlChange
  }, null, 8 /* PROPS */, ["title", "model-value", "options", "with-reset", "placeholder", "disabled", "onUpdate:modelValue"])])) : _createCommentVNode("v-if", true), _createVNode(_component_solution_id_relation_details, {
    list: $props.control.solutionIds,
    "read-only": $options.inputsDisabled,
    onPropertyChanged: $options.propertyChanged
  }, null, 8 /* PROPS */, ["list", "read-only", "onPropertyChanged"]), _ctx.showControlType ? (_openBlock(), _createBlock(_component_control_type, {
    key: 3,
    control: $props.control,
    disabled: $options.inputsDisabled || $options.isDaughter,
    class: "mb-3 pb-3 border-b-2 border-gray-250",
    onChanged: $options.propertyChanged
  }, null, 8 /* PROPS */, ["control", "disabled", "onChanged"])) : _createCommentVNode("v-if", true), _ctx.getSettingValue('feature.control_comment_format') ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("input", {
    checked: $props.control.completedCommentsFormat,
    type: "checkbox",
    class: "mr-2 cursor-pointer",
    onChange: _cache[1] || (_cache[1] = function ($event) {
      return $options.propertyChanged({
        property: 'completedCommentsFormat',
        value: $event.target.checked
      });
    })
  }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_6), _createVNode(_component_risma_title, {
    type: "medium",
    title: $data.translate.showCommentsPreformatted
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), _createVNode(_component_control_details_associations, {
    accounts: $options.associationAccounts,
    processes: $options.associationProcesses,
    risks: $options.associationRisks,
    "is-daughter": $options.isDaughter,
    disabled: $options.inputsDisabled || $options.isDaughter,
    onChanged: $options.associationsChanged
  }, null, 8 /* PROPS */, ["accounts", "processes", "risks", "is-daughter", "disabled", "onChanged"])])) : _createCommentVNode("v-if", true);
}