function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import isEqual from 'lodash/isEqual';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import ActivityTypeSelector from '@/components/Molecules/ActivityTypeSelector';
import { getTranslate } from './translate';
export default {
  name: 'EditComplianceProjectModal',
  components: {
    Modal: Modal,
    InputField: InputField,
    RismaLabel: RismaLabel,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    ActivityTypeSelector: ActivityTypeSelector
  },
  props: {
    project: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Project data to update'
    },
    questionnaires: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    gapSchemas: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    solutions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    activityTypes: {
      type: Array,
      required: true
    }
  },
  emits: ['accept', 'dismiss'],
  data: function data() {
    return {
      errors: {},
      isDirty: false,
      projectClone: {},
      translate: getTranslate['EditComplianceProjectModal']()
    };
  },
  computed: {
    selectedSolutions: function selectedSolutions() {
      var _this = this;
      return this.solutions.filter(function (solution) {
        return _this.project.solutionIds.includes(solution.id);
      });
    }
  },
  mounted: function mounted() {
    this.projectClone = _objectSpread({}, this.project);
  },
  methods: {
    setSolutionId: function setSolutionId(values) {
      this.project.solutionIds = values.map(function (solution) {
        return solution.id;
      });
    },
    submitModal: function submitModal() {
      if (isEqual(this.project, this.projectClone)) {
        return this.$emit('dismiss');
      }
      if (this.validateProject(this.project)) {
        this.prepareRenameValues(this.project, this.projectClone);
        this.$emit('accept', this.project);
      }
    },
    validateProject: function validateProject(project) {
      var _this2 = this;
      this.errors = {};
      ['title', 'descriptionLabel', 'purposeLabel', 'statusLabel'].forEach(function (fieldName) {
        if (!project[fieldName]) {
          _this2.errors[fieldName] = true;
        }
      });
      return Object.keys(this.errors).length === 0;
    },
    prepareRenameValues: function prepareRenameValues(project, clone) {
      var data = {
        descriptionLabel: this.translate.overallDescription,
        purposeLabel: this.translate.purpose,
        statusLabel: this.translate.processStatus
      };
      for (var property in data) {
        if (project[property] === data[property] && clone[property] === project[property]) project[property] = null;
      }
    },
    handleCheckBoxChange: function handleCheckBoxChange(value, property) {
      this.project[property] = value;
    }
  }
};