import ForgotPassword from '@/components/Pages/ForgotPassword/ForgotPassword';
import ResetPassword from '@/components/Pages/ResetPassword/ResetPassword';
export default (function () {
  return [{
    path: '/forgot-password',
    component: ForgotPassword,
    meta: {
      skipLoginCheck: true
    }
  }, {
    path: '/reset-password',
    component: ResetPassword,
    props: function props(route) {
      return {
        token: route.query.token
      };
    },
    meta: {
      skipLoginCheck: true
    }
  }];
});