function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getRisks as _getRisks } from '@/api/risk/risk';
import RiskPeriodsWell from '@/components/Organisms/RiskAdmin/RiskPeriodsWell';
import RiskTypesWell from '@/components/Organisms/RiskAdmin/RiskTypesWell';
import ManagementLevelsWell from '@/components/Organisms/RiskAdmin/ManagementLevelsWell';
import ApprovalStatusWell from '@/components/Organisms/RiskAdmin/ApprovalStatusWell';
import Notification from '@/components/Molecules/Notification';
import { RiskProjects } from '@/constants/risks/RiskProjects';
export default {
  name: 'AdminRiskScoring',
  introduction: 'ERM admin settings component',
  description: 'Includes risk periods, risk types, management levels and approval status wells',
  token: '<admin-risk-scoring />',
  components: {
    RiskPeriodsWell: RiskPeriodsWell,
    RiskTypesWell: RiskTypesWell,
    ManagementLevelsWell: ManagementLevelsWell,
    ApprovalStatusWell: ApprovalStatusWell,
    Notification: Notification
  },
  props: {
    errorScoring: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      loaded: false,
      riskTypesRisksHash: {},
      risks: [],
      errorMessage: this.errorScoring || ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    showApprovalStatusWell: function showApprovalStatusWell() {
      return this.getSettingValue('risma.riskapproval_enabled');
    }
  }),
  mounted: function mounted() {
    this.getRisks();
  },
  methods: {
    getRisks: function getRisks() {
      var _this = this;
      return _getRisks(RiskProjects.ENTERPRISE_RISK_MANAGEMENT.id, null).then(function (_ref) {
        var list = _ref.list;
        _this.risks = list;
        _this.loaded = true;
      }).catch(function (error) {
        return error;
      });
    },
    updateApprovedRisks: function updateApprovedRisks(ids) {
      var _this2 = this;
      ids.forEach(function (id) {
        var risk = _this2.risks.find(function (risk) {
          return risk.id === id;
        });
        risk.approved = false;
      });
      this.risks = _toConsumableArray(this.risks);
    }
  }
};