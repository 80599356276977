function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getAnonymousIncidentActivityType as _getAnonymousIncidentActivityType, createAnonymousIncident as _createAnonymousIncident } from '@/api/incident/incident';
import InputField from '@/components/Atoms/Inputs/InputField';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import TrafficLightSelector from '@/components/Organisms/Selectors/TrafficLightSelector';
import Notification from '@/components/Molecules/Notification';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import { getCustomFieldValues as prepareCustomFieldParams } from '@/utils/CustomFields';
import { CustomFieldsMixin } from '@/mixins/CustomFieldsMixin';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import { getErrorMessage } from '@/utils/handleAPIErrors';
import { formatToUtcDateTime } from '@/utils/date';
export default {
  name: 'IncidentAnonymousCreationPage',
  components: {
    Notification: Notification,
    TrafficLightSelector: TrafficLightSelector,
    DateTimePicker: DateTimePicker,
    RismaTitle: RismaTitle,
    TinyMceBox: TinyMceBox,
    InputField: InputField,
    RismaButton: RismaButton,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    ActivityCustomFields: ActivityCustomFields,
    NotificationPopUp: NotificationPopUp
  },
  mixins: [CustomFieldsMixin, NotificationPopUpMixin],
  data: function data() {
    return {
      title: '',
      description: '',
      incidentHappenedAt: null,
      severity: 0,
      defaultActivityType: null,
      customFields: [],
      errors: {},
      isLoading: false,
      translate: getTranslate['IncidentAnonymousCreationPage']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    descriptionInOptional: function descriptionInOptional() {
      var _this$defaultActivity;
      if (!this.defaultActivityType) return false;
      return (_this$defaultActivity = this.defaultActivityType) === null || _this$defaultActivity === void 0 || (_this$defaultActivity = _this$defaultActivity.optionalFields) === null || _this$defaultActivity === void 0 ? void 0 : _this$defaultActivity.includes('description');
    },
    severityInOptional: function severityInOptional() {
      var _this$defaultActivity2;
      if (!this.defaultActivityType) return false;
      return (_this$defaultActivity2 = this.defaultActivityType) === null || _this$defaultActivity2 === void 0 || (_this$defaultActivity2 = _this$defaultActivity2.optionalFields) === null || _this$defaultActivity2 === void 0 ? void 0 : _this$defaultActivity2.includes('severity');
    },
    errorMessages: function errorMessages() {
      return Object.values(this.errors);
    },
    isCreationSettingEnabled: function isCreationSettingEnabled() {
      return !!this.getSettingValue('feature.incident_anonymous_creation_enabled');
    },
    descriptionTextByRows: function descriptionTextByRows() {
      return [this.translate.welcomeToOurCreationPage, this.translate.ifYouCreateAnIncidentOnThisPage, this.translate.regardlessOfWhetherTheIncidentIsAn, this.translate.rememberThatMoreInformationYouProvide, this.translate.sinceYourReportingIsAnonymous];
    }
  }),
  mounted: function mounted() {
    this.getAnonymousIncidentActivityType();
  },
  methods: {
    getAnonymousIncidentActivityType: function getAnonymousIncidentActivityType() {
      var _this = this;
      this.isLoading = true;
      return _getAnonymousIncidentActivityType().then(function (activityType) {
        _this.defaultActivityType = activityType;
      }).catch(function (error) {
        _this.errors = {
          'noAccess': getErrorMessage(error) || _this.translate.noAccess
        };
      }).finally(function () {
        _this.isLoading = false;
      });
    },
    createAnonymousIncident: function createAnonymousIncident() {
      var _this2 = this;
      if (!this.validateItem()) return;
      var customFieldParsed = this.getCustomFieldsValues(this.customFields, []);
      var customFieldValues = prepareCustomFieldParams({
        customFieldValues: customFieldParsed
      });
      var incidentData = {
        title: this.title,
        incidentHappenedAt: '',
        customFieldValues: customFieldValues
      };
      if (this.incidentHappenedAt) {
        incidentData.incidentHappenedAt = formatToUtcDateTime(this.incidentHappenedAt);
      }
      if (this.descriptionInOptional) {
        incidentData.description = this.description;
      }
      if (this.severityInOptional) {
        incidentData.severity = this.severity;
      }
      this.isLoading = true;
      return _createAnonymousIncident(incidentData).then(function (incidentInfo) {
        _this2.setNotificationData({
          activityTitle: incidentInfo.title,
          title: _this2.translate.yourActivityHasBeenCreated,
          type: 'success'
        });
        _this2.resetFields();
      }).catch(function () {
        _this2.setNotificationData({
          title: _this2.translate.somethingWentWrongWhileUploading,
          type: 'error'
        });
      }).finally(function () {
        _this2.isLoading = false;
      });
    },
    resetFields: function resetFields() {
      this.title = '';
      this.description = '';
      this.incidentHappenedAt = null;
      this.severity = 0;
    },
    handleCustomFieldsChange: function handleCustomFieldsChange(e) {
      this.customFields = e;
    },
    validateItem: function validateItem() {
      this.errors = {};
      if (!this.title.trim()) {
        this.errors['title'] = this.translate.titleCannotBeEmpty;
      }
      return !Object.keys(this.errors).length;
    }
  }
};