import * as Cookies from 'tiny-cookie';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TileCard from '@/components/Molecules/Cards/TileCard';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadMoreWrapper from '@/components/Molecules/LoadMoreWrapper';
export default {
  name: 'PreviewListTile',
  introduction: 'Container for tile list',
  description: 'Has show / hide feature and saves state to cookie',
  token: '<preview-list-tile title="All list"> <slot /> </preview-list-tile>',
  components: {
    FeatherIcon: FeatherIcon,
    TileCard: TileCard,
    RismaTitle: RismaTitle,
    LoadMoreWrapper: LoadMoreWrapper
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
      note: 'A title of a list'
    },
    cookieHiddenName: {
      type: String,
      required: false,
      default: '',
      note: 'Save last state of show/hide feature in cookie'
    },
    activities: {
      type: Array,
      required: true,
      note: 'All activities'
    },
    watermarkImage: {
      type: [String, Function],
      required: false,
      default: null,
      note: 'Url of background watermark image'
    },
    disablePreview: {
      required: false,
      type: Boolean,
      default: false,
      note: 'if PreviewModalLink is disable (example: Reports)'
    },
    internalLinkEnabled: {
      required: false,
      type: Boolean,
      default: false,
      note: 'if TileCard will have title as internal link'
    },
    isApprovalFlowEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isControlShownFromParent: {
      type: Boolean,
      required: false,
      default: false
    },
    workflows: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'A list of all activity types'
    }
  },
  emits: ['changeShowState'],
  data: function data() {
    return {
      isShowAll: false,
      translate: {
        hide: this.$trans('Hide'),
        show: this.$trans('Show')
      }
    };
  },
  computed: {
    fullTitle: function fullTitle() {
      return this.title + " (".concat(this.activities.length, ")");
    },
    showStateTitle: function showStateTitle() {
      return this.isShowAll ? this.translate.hide : this.translate.show;
    }
  },
  watch: {
    isControlShownFromParent: function isControlShownFromParent(changed) {
      this.isShowAll = changed;
    }
  },
  mounted: function mounted() {
    var cookieValue = Cookies.getCookie(this.cookieHiddenName);
    var isHidden = cookieValue || cookieValue === 'hidden';
    this.isShowAll = !isHidden;
    this.$emit('changeShowState', this.isShowAll);
  },
  methods: {
    changeShowState: function changeShowState(action) {
      this.isShowAll = action !== null ? action : !this.isShowAll;
      if (this.isShowAll) {
        Cookies.removeCookie(this.cookieHiddenName);
      } else {
        Cookies.setCookie(this.cookieHiddenName, 'hidden', {
          samesite: 'strict'
        });
      }
      this.$emit('changeShowState', this.isShowAll);
    },
    getActivityStatus: function getActivityStatus(activity) {
      var status = null;
      if (this.workflows && this.workflows.length && activity.workflowStatusId) {
        status = this.workflows.find(function (workflow) {
          return workflow.id === activity.workflowStatusId;
        });
        if (status) {
          status = status.title;
        }
      }
      return status || '';
    }
  }
};