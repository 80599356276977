import { getTranslate } from './translate';
import Alert from '@/components/Molecules/Modal/Alert';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Questionnaire from '@/components/Organisms/Compliance/questionnaire';
import * as dpmAPI from '@/api/compliance/dpm';
export default {
  name: 'DpmAuditQuestionnaire',
  introduction: '',
  description: 'It is of grave importance that the recipient of the questionnaire understands what the heck is going on. Imagine a world where you didn`t have any knowledge of RISMA Systems and its functionality. If you where in the receiving end of this questionnaire, what would you like to know? You would definitely like to know what you should do and what options you have. A lot of this is covered in the commentary field that is included in both the email and the html form.So the main task is to display a simply html form that the recipient can use to answer the question types and understand that when they are done they should click one simple button - Submit! ',
  token: "<dpm-audit-questionnaire\n      v-if=\"questionnaire !== null\"\n      :questionnaire=\"questionnaire\"\n      :activity=\"testActivity\"\n      comment=\"some text in comments need to be rendered to someone. Just the text. Answer my questions please\"\n      @handleSubmit=\"something\"/>",
  components: {
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    MainWell: MainWell,
    Questionnaire: Questionnaire,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Alert: Alert
  },
  props: {
    activity: {
      require: true,
      type: Object,
      default: null,
      note: 'The Activity object, could be data processor, contract, system og data controller'
    },
    questionnaire: {
      require: true,
      type: [Object, Array],
      default: function _default() {
        return {};
      },
      note: 'The questionnaire selected from when the sender sends out the questionnaire'
    },
    dpmRequest: {
      require: true,
      type: Object,
      default: function _default() {
        return {};
      },
      note: 'The data processor management request'
    },
    token: {
      require: true,
      type: String,
      default: ''
    },
    comment: {
      require: false,
      type: String,
      default: '',
      note: 'Some comments to the questionnaire'
    }
  },
  emits: ['handleSubmit', 'updated'],
  data: function data() {
    return {
      informationAssets: [],
      processTreeId: null,
      nodeElement: {},
      isLoaded: false,
      lastUpdatedQuestion: null,
      questionnaireSubmitted: false,
      showAlert: false,
      alertMessage: 'Are you sure you want to submit the questionnaire? Please note that you will no longer be able to answer the questionnaire after the questionnaire has been submitted.',
      translate: getTranslate['DpmAuditQuestionnaire'](),
      questionTimers: {}
    };
  },
  computed: {
    helpText: function helpText() {
      return this.translate.yourAnswersAreAutomaticallySavedAfterAFewS;
    },
    rismaFirstTitle: function rismaFirstTitle() {
      var title = this.translate.youHaveBeenAskedToAnswerAQuestionnaireFor + this.defaultTitle;
      var expiresFormatted = this.dpmRequest && this.dpmRequest.expires ? this.dpmRequest.expires.substring(0, 10) : '';
      return title + '. ' + this.translate.thisLinkExpiresOnThe + expiresFormatted + '.';
    },
    rismaSecondTitle: function rismaSecondTitle() {
      return '';
    },
    rismaThirdTitle: function rismaThirdTitle() {
      return this.translate.whenYouAreFinishedFillingOutTheQuestionnair;
    },
    defaultTitle: function defaultTitle() {
      return this.activity.name || this.activity.title || this.translate.noTitleYet;
    },
    questions: function questions() {
      if (this.questionnaire) {
        return this.questionnaire.questionAnswers;
      }
      return [];
    }
  },
  mounted: function mounted() {
    this.isLoaded = true;
  },
  methods: {
    toggleAlert: function toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    onPreSubmit: function onPreSubmit() {
      this.lastUpdatedQuestion && this.updateQuestion(this.lastUpdatedQuestion);
      this.toggleAlert();
    },
    onSubmit: function onSubmit() {
      this.questionnaireSubmitted = true;
      this.$emit('handleSubmit', this.questions);
      this.toggleAlert();
    },
    prepareAndUpdateQuestion: function prepareAndUpdateQuestion(question) {
      var preparedQuestion = this.prepareQuestionForUpdate(question);
      if (this.questions) {
        var index = this.questions.findIndex(function (elem) {
          return elem.id == preparedQuestion.id;
        });
        this.questions[index] = preparedQuestion;
        this.lastUpdatedQuestion = preparedQuestion;
      }
      this.updateQuestion(preparedQuestion, this.$debounceTimeout);
    },
    updateQuestion: function updateQuestion(question) {
      var _this = this;
      var timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var id = question.id;
      if (Object.hasOwnProperty.call(this.questionTimers, id)) {
        clearTimeout(this.questionTimers[id]);
      }
      this.questionTimers[id] = setTimeout(function () {
        return dpmAPI.submitQuestionAnswer(_this.token, question).then(function () {
          return _this.$emit('updated', question);
        }).catch(function (error) {
          return console.log(error);
        }) // eslint-disable-line
        .finally(function () {
          return delete _this.questionTimers[id];
        });
      }, timeout);
    },
    prepareQuestionForUpdate: function prepareQuestionForUpdate(question) {
      if (question.questiontype === 12) {
        question.processLibraryId = [];
        // Check for empty answers_options
        if (Object.keys(question.answers_options)[0] !== 'null') {
          Object.keys(question.answers_options).forEach(function (element) {
            question.processLibraryId.push(element);
          });
        }
        delete question.answers_options;
      }
      return question;
    }
  }
};