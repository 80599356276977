import RiskGridDisplay from '@/components/Molecules/Risk/RiskGridDisplay';
export default {
  name: '',
  components: {
    RiskGridDisplay: RiskGridDisplay
  },
  data: function data() {
    return {
      riskGridData: {
        checked: false,
        yLabel: this.$trans('Consequence'),
        xLabel: this.$trans('Probability'),
        gridSizeY: [{
          label: ''
        }, {
          label: ''
        }, {
          label: ''
        }, {
          label: ''
        }, {
          label: ''
        }, {
          label: ''
        }],
        gridSizeX: [{
          label: ''
        }, {
          label: ''
        }, {
          label: ''
        }, {
          label: ''
        }, {
          label: ''
        }],
        colorCodes: {},
        dots: []
      }
    };
  }
};