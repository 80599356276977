export default {
  name: 'TabItem',
  introduction: 'TabItem for tabs',
  description: 'Tab item is a part of Tabs components.',
  token: '<tab-item link="uri-to-navigate-to">Text to be displayed</tab-item>',
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    link: {
      type: [String, Object],
      required: true,
      note: 'Link to relocate'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is tab disabled'
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is used when in preview mode, to show selected tab'
    }
  },
  emits: ['changeTab'],
  computed: {
    event: function event() {
      return this.isPreview ? '' : undefined;
    }
  },
  methods: {
    isSameRoute: function isSameRoute(route) {
      if (!route.name) {
        return false;
      }
      return route.name === this.$router.currentRoute.value.name;
    }
  }
};