function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Selector from '@/components/Atoms/Inputs/Selector';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import ControlCreationModal from '@/components/Molecules/Controls/ControlCreationModal';
import CreateInitiativeModal from '@/components/Organisms/CreateInitiativeModal';
import { Colors } from '@/Colors';
import { getModuleTitles, MODULES } from '@/constants/modules';
import { create as createInitiative } from '@/api/execution/initiative';
import { comparePathStrings, compareStrings } from '@/utils/compare';
export default {
  name: 'LinksModal',
  introduction: 'LinkModal is used to update links',
  description: 'LinksModal with  selector and list of selected items',
  token: '<links-modal :projects-list=[] :links-options=[] :preselected-items=[] />',
  components: {
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    Selector: Selector,
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    Trafficlight: Trafficlight,
    ControlCreationModal: ControlCreationModal,
    CreateInitiativeModal: CreateInitiativeModal
  },
  props: {
    projectsList: {
      type: Array,
      required: true,
      note: 'List of all available projects'
    },
    linksOptions: {
      type: Array,
      required: true,
      note: 'List of links activities'
    },
    preselectedLinks: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of already selected delegation items'
    },
    initiatives: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of all initiatives'
    },
    disabledProjectSelect: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If project selector should be disabled'
    }
  },
  emits: ['selectProject', 'updateLinks', 'addNewItem', 'dismiss'],
  data: function data() {
    var _additionalSearchFiel;
    return {
      selectedLinks: [],
      selectedProject: [],
      project: null,
      isControlCreationModalShow: false,
      isCreationInitiativeModalShow: false,
      Colors: Colors,
      MODULES: MODULES,
      ModuleTitles: getModuleTitles(),
      additionalSearchFields: (_additionalSearchFiel = {}, _defineProperty(_additionalSearchFiel, MODULES.CONTROLS, ['cpath']), _defineProperty(_additionalSearchFiel, MODULES.EXECUTION, ['ipath']), _defineProperty(_additionalSearchFiel, MODULES.RISK, ['rno']), _defineProperty(_additionalSearchFiel, MODULES.COMPANY, ['name']), _additionalSearchFiel),
      translate: getTranslate['LinksModal']()
    };
  },
  computed: {
    filteredProjectsList: function filteredProjectsList() {
      return this.projectsList.filter(function (item) {
        return item.rights;
      });
    },
    selectableItems: function selectableItems() {
      var _this = this;
      return this.linksOptions.filter(function (item) {
        return !_this.selectedLinks.find(function (selected) {
          return selected.id == item.id;
        });
      });
    },
    filteredInitiatives: function filteredInitiatives() {
      return this.initiatives.filter(function (item) {
        return !item.closed;
      });
    },
    sortedSelectableItems: function sortedSelectableItems() {
      var items = this.selectableItems.slice();
      switch (this.project) {
        case MODULES.EXECUTION:
          return this.sortByPath(items, 'ipath');
        case MODULES.COMPLIANCE_PROCESS_TREE_NODE:
          return this.sortByTitle(items, 'formattedTitle');
        case MODULES.CONTRACTS:
          return this.sortByTitle(items, 'title');
        default:
          return items;
      }
    },
    linkLabel: function linkLabel() {
      var _this$project$split = this.project.split('-'),
        _this$project$split2 = _slicedToArray(_this$project$split, 1),
        module = _this$project$split2[0];
      return this.$trans(this.ModuleTitles[module]).toLowerCase();
    },
    selectedLinkIds: function selectedLinkIds() {
      return this.selectedLinks.map(function (_ref) {
        var id = _ref.id;
        return id;
      });
    },
    showAddItemButton: function showAddItemButton() {
      return this.project === MODULES.CONTROLS || this.project === MODULES.EXECUTION;
    },
    searchFields: function searchFields() {
      var additionalFields = this.additionalSearchFields[this.project] || [];
      return ['title'].concat(_toConsumableArray(additionalFields));
    }
  },
  watch: {
    project: function project() {
      this.selectedLinks = [];
    },
    preselectedLinks: {
      deep: true,
      immediate: true,
      handler: function handler(value) {
        this.selectedLinks = value;
      }
    }
  },
  methods: {
    selectedLink: function selectedLink(item) {
      this.selectedLinks.push(item);
    },
    deselect: function deselect(item) {
      this.selectedLinks = this.selectedLinks.filter(function (selectedItem) {
        return selectedItem.id != item.id;
      });
    },
    selectProject: function selectProject(project) {
      this.project = project;
      this.$emit('selectProject', this.project);
    },
    updateLinks: function updateLinks(module) {
      this.$emit('updateLinks', {
        module: module,
        links: this.selectedLinkIds
      });
    },
    openCreationModal: function openCreationModal(project) {
      if (project == MODULES.CONTROLS) this.isControlCreationModalShow = true;
      if (project == MODULES.EXECUTION) this.isCreationInitiativeModalShow = true;
    },
    addControl: function addControl(newControl) {
      this.$emit('addNewItem', newControl);
      this.selectedLinks.push(newControl);
    },
    addInitiative: function addInitiative(newInitiative) {
      var _this2 = this;
      createInitiative(newInitiative).then(function (response) {
        response.id = +response.id;
        _this2.selectedLinks.push(response);
        _this2.$emit('addNewItem', response);
      });
      this.isCreationInitiativeModalShow = false;
    },
    sortByPath: function sortByPath(data, key) {
      if (data[0] && data[0][key]) {
        return data.sort(function (a, b) {
          return comparePathStrings(a[key], b[key]);
        });
      }
      return data;
    },
    sortByTitle: function sortByTitle(data, key) {
      if (data[0] && data[0][key]) {
        return data.sort(function (a, b) {
          return compareStrings(a[key], b[key]);
        });
      }
      return data;
    },
    getLineThrough: function getLineThrough(item) {
      return this.project === MODULES.CONTROLS && !item.active;
    }
  }
};