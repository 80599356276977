export var ChartMixin = {
  data: function data() {
    return {
      cursorPadding: 16,
      tooltipVisible: false,
      tooltip: {
        el: null,
        top: null,
        left: null
      }
    };
  },
  mounted: function mounted() {
    this.$el.addEventListener('click', this.handleClickOnCSV);
  },
  unmounted: function unmounted() {
    this.$el.addEventListener('click', this.handleClickOnCSV);
  },
  methods: {
    handleClickOnCSV: function handleClickOnCSV(event) {
      var _event$target, _event$target2;
      var root = this.$el.querySelector('.amcharts-export-menu');
      var isChild = event.target.closest('.amcharts-export-menu');
      var menuElements = root.querySelectorAll('li');
      if (!isChild || ((_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.innerHTML) === 'CSV' || ((_event$target2 = event.target) === null || _event$target2 === void 0 || (_event$target2 = _event$target2.childNodes[0]) === null || _event$target2 === void 0 ? void 0 : _event$target2.innerHTML) === 'CSV') {
        menuElements.forEach(function (el) {
          return el.classList.remove('active');
        });
      }

      //workaround so export menu dissapear, next tick and less ms doesn`t work (due to disapear small animation)
      root.style.display = 'none';
      setTimeout(function () {
        root.style.display = 'block';
      }, 20);
    },
    getTooltip: function getTooltip() {
      this.tooltip.el = this.$el.querySelector('.tooltip-pure');
    },
    refreshTooltip: function refreshTooltip(e) {
      this.tooltip.left = e.offsetY + this.cursorPadding;
      this.tooltip.top = e.offsetX + this.cursorPadding;
      this.tooltip.el.style.display = 'block';
      this.tooltip.el.style.left = "".concat(this.tooltip.left, "px");
      this.tooltip.el.style.bottom = "".concat(this.tooltip.top, "px");
      this.tooltip.el.textContent = e.target.getAttribute('data-text');
    },
    refreshColumnTooltip: function refreshColumnTooltip(e, item) {
      this.tooltip.left = e.offsetY + this.cursorPadding;
      this.tooltip.top = e.offsetX + this.cursorPadding;
      this.tooltip.el.style.display = 'block';
      this.tooltip.el.style.left = "".concat(this.tooltip.top, "px");
      this.tooltip.el.style.top = "".concat(this.tooltip.left, "px");

      // Border color
      if (item && item.props && item.props.color) {
        this.tooltip.el.style.borderColor = item.props.color;
      }
      this.tooltip.el.textContent = this.prepareColumnTooltipText(item);
    },
    hideTooltip: function hideTooltip() {
      this.tooltip.el.style.display = 'none';
      this.tooltip.left = null;
      this.tooltip.top = null;
    },
    prepareColumnTooltipText: function prepareColumnTooltipText(item) {
      return "".concat(item.value);
    },
    getMaxValue: function getMaxValue(data) {
      return Math.max.apply(Math, data.map(function (item) {
        return item.value;
      }));
    },
    dropExistingAnnotations: function dropExistingAnnotations() {
      var drawingContext = this.chart.export.drawing;
      if (drawingContext.enabled) {
        drawingContext.handler.done();
      }
    }
  }
};