import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-931aad50"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "action-reports-wrap"
};
var _hoisted_2 = {
  class: "w-full"
};
var _hoisted_3 = {
  class: "hidden"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_gantt_chart = _resolveComponent("gantt-chart");
  var _component_collapsible_panel = _resolveComponent("collapsible-panel");
  var _component_save_report = _resolveComponent("save-report");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_trafficlight = _resolveComponent("trafficlight");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.mainReport,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_collapsible_panel, {
    class: "py-4",
    onAfterEnter: _cache[2] || (_cache[2] = function ($event) {
      return $data.showGanttChart = true;
    }),
    onBeforeLeave: $options.beforeLeavePanel
  }, {
    header: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.showAsGanttRoadmap), 1 /* TEXT */)];
    }),

    content: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [$data.showGanttLoader ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : _createCommentVNode("v-if", true), $data.showGanttChart ? (_openBlock(), _createBlock(_component_gantt_chart, {
        key: 1,
        "has-preview": true,
        "is-sorting-visible": false,
        "chart-data": $options.ganttChartData,
        "zoom-end-index": $options.ganttChartData.length,
        "chart-scroll-enabled": false,
        "period-cookie": "action-report-gantt-period",
        onLoaded: _cache[0] || (_cache[0] = function ($event) {
          return $data.showGanttLoader = false;
        }),
        onLoading: _cache[1] || (_cache[1] = function ($event) {
          return $data.showGanttLoader = true;
        })
      }, null, 8 /* PROPS */, ["chart-data", "zoom-end-index"])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onBeforeLeave"]), _createVNode(_component_save_report, {
    type: $data.reportType,
    "state-local": $data.stateLocal,
    onLoad: $options.onLoadReport
  }, null, 8 /* PROPS */, ["type", "state-local", "onLoad"]), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_risma_datatable, {
    key: 1,
    ref: "reportsDataTable",
    dataset: _ctx.datasetSlice,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    paging: true,
    "page-length": _ctx.pageLength,
    "state-local": $data.stateLocal,
    "wrapper-height-sticky": 400,
    "decouple-filter-fields": $options.decoupleFilterFields,
    "disabled-filter-by-columns": $data.disabledFilterByColumns,
    "filter-by-column": true,
    "columns-meta": $data.columnsMeta,
    "filter-by-render-func": _ctx.prepareFilterForTrafficLight,
    "column-sorting": $data.columnSorting,
    "custom-export": $options.customExport,
    class: "action-table",
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged
  }, _createSlots({
    title: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_createVNode(_component_preview_modal_link, {
        activity: item.activity,
        onUpdated: $options.updateFromPreviewMode,
        onDeleteItem: $options.onItemDelete,
        onDismissModal: $options.onDismissModal
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(item.fieldText), 1 /* TEXT */)];
        }),

        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onUpdated", "onDeleteItem", "onDismissModal"])];
    }),
    _: 2 /* DYNAMIC */
  }, [_renderList(_ctx.trafficLightRismaDatatableFields, function (trafficLightCol) {
    return {
      name: trafficLightCol,
      fn: _withCtx(function (_ref2) {
        var item = _ref2.item;
        return [item.field.color ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(trafficLightCol)
        }, [_createVNode(_component_trafficlight, {
          color: item.field.color
        }, null, 8 /* PROPS */, ["color"]), _createElementVNode("p", _hoisted_3, _toDisplayString(item.field.value) + "-" + _toDisplayString(item.field.label), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("span", {
          key: "".concat(trafficLightCol)
        }))];
      })
    };
  }), _renderList(_ctx.linkedColumnsForPreview, function (linkCol) {
    return {
      name: linkCol,
      fn: _withCtx(function (_ref3) {
        var item = _ref3.item;
        return [item.activities && item.activities.length ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(linkCol, "_").concat(item.id)
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.activities, function (activity, index) {
          return _openBlock(), _createBlock(_component_preview_modal_link, {
            key: "".concat(activity.fieldText, "_").concat(index),
            class: "hover:underline",
            activity: activity.activity
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(activity.fieldText), 1 /* TEXT */)];
            }),

            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"]);
        }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("div", {
          key: linkCol
        }))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset", "dataset-total", "dataset-total-sliced", "page-length", "state-local", "decouple-filter-fields", "disabled-filter-by-columns", "columns-meta", "filter-by-render-func", "column-sorting", "custom-export", "onPageChange", "onOnStateChanged"]))]);
}