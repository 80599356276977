function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { copyQuestionnaire, enableQuestionnaire, changeFieldOnQuestionnaire, deleteQuestionnaire, getAllQuestionnaires, getAllQuestionnaireTemplates } from '@/api/compliance/questionnaire';
import { getAllGapSchemas } from '@/api/compliance/gapschema';
import { getAll as getAllProjects } from '@/api/compliance/projects';
import { getAvailableQuestionnaireTypes } from '@/utils/questionnaires';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import StaticTable from '@/components/Molecules/StaticTable';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import TemplateList from '@/components/Pages/Admin/TemplateList';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Notification from '@/components/Molecules/Notification';
import Alert from '@/components/Molecules/Modal/Alert';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import { getTranslatables } from '@/constants/datatable/Translatables';
import { QuestionnaireTypes } from '@/constants/compliance/QuestionnaireTypes';
import { questionnaireAdminFields } from '@/constants/compliance/ComplianceAdmin';
import { check403AccessError } from '@/utils/handleAPIErrors';
export default {
  name: 'QuestionnaireAdmin',
  introduction: '',
  description: '',
  token: '<questionnaire-admin></questionnaire-admin>',
  components: {
    TemplateList: TemplateList,
    RismaTitle: RismaTitle,
    StaticTable: StaticTable,
    SingleSelect: SingleSelect,
    FeatherIcon: FeatherIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Notification: Notification,
    Alert: Alert,
    Modal: Modal,
    InputField: InputField
  },
  data: function data() {
    return {
      projects: [],
      questionnaireType: this.$route.query && this.$route.query.questionnaireType ? +this.$route.query.questionnaireType : 0,
      allQuestionnaireTypes: [],
      allQuestionnaires: [],
      templates: [],
      slotNames: {
        eventFields: [questionnaireAdminFields.TITLE, questionnaireAdminFields.TAB_LABEL, questionnaireAdminFields.COPY, questionnaireAdminFields.DELETE],
        linkFields: [questionnaireAdminFields.DOWNLOAD_CSV, questionnaireAdminFields.DOWNLOAD_EXCEL],
        routerLinkFields: [questionnaireAdminFields.EDIT, questionnaireAdminFields.SCORING]
      },
      allGapSchemas: [],
      isLoading: false,
      saveChangesError: '',
      translate: getTranslate['QuestionnaireAdmin'](),
      showConfirmDeleteAlert: false,
      showTitleModal: false,
      titleModalHeader: '',
      newTitle: '',
      bufferFunction: null
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, {
    customIAModules: 'complianceIACustomActivityTypes'
  })), {}, {
    columns: function columns() {
      return [this.translate.name, this.translate.tabLabel, this.availableGapSchemasLength ? this.translate.defaultGapSchema : null, this.translate.questionCount, this.nodeCountTitle(), this.translate.activated, this.translate.questions, this.scoringEnabled ? this.translate.scoring : null, this.translate.copy, this.translate.delete].filter(function (item) {
        return item !== null;
      });
    },
    questionnaireTypes: function questionnaireTypes() {
      var _this = this;
      var questionnaireTypeIds = this.allQuestionnaires.map(function (questionnaire) {
        return questionnaire.questionnaireType;
      });
      var templateTypeIds = this.templates.map(function (template) {
        return template.type;
      });
      var allTypeIds = [].concat(_toConsumableArray(questionnaireTypeIds), _toConsumableArray(templateTypeIds));
      return this.allQuestionnaireTypes.filter(function (questionnaireType) {
        return allTypeIds.indexOf(questionnaireType.id) !== -1;
      }).map(function (item) {
        return {
          label: _this.translateQuestionnaireType(item.type),
          value: item.id
        };
      });
    },
    questionnaires: function questionnaires() {
      var _this2 = this;
      return this.allQuestionnaires.filter(function (questionnaire) {
        return questionnaire.questionnaireType == _this2.questionnaireType;
      });
    },
    gapSchemasBytype: function gapSchemasBytype() {
      var result = {};
      this.allGapSchemas.forEach(function (schema) {
        var type = schema.gapSchemaType;
        var option = {
          value: schema.id,
          label: schema.title
        };
        if (!result[type]) {
          result[type] = [];
        }
        result[type].push(option);
      });
      return result;
    },
    formattedData: function formattedData() {
      var _this3 = this;
      var format = [];
      this.questionnaires.forEach(function (questionnaire) {
        var _obj;
        var obj = (_obj = {}, _defineProperty(_obj, questionnaireAdminFields.TITLE, {
          disableEvent: questionnaire.readonly,
          href: '#questionnaire-admin',
          dataId: questionnaire.id,
          dataMethod: 'title',
          action: 'title',
          id: questionnaire.id,
          currentTitle: questionnaire.title,
          text: questionnaire.title
        }), _defineProperty(_obj, questionnaireAdminFields.TAB_LABEL, {
          href: '#questionnaire-admin',
          dataId: questionnaire.id,
          dataMethod: 'title',
          action: 'tabLabel',
          id: questionnaire.id,
          currentTitle: questionnaire.tabLabel,
          text: questionnaire.tabLabel
        }), _defineProperty(_obj, questionnaireAdminFields.DEFAULT_GAP_SCHEMA, {
          schemaId: questionnaire.defaultGapSchemaId,
          questionnaireId: questionnaire.id,
          options: _this3.gapSchemasBytype[questionnaire.questionnaireType]
        }), _defineProperty(_obj, questionnaireAdminFields.QUESTION_COUNT, questionnaire.questionCount), _defineProperty(_obj, questionnaireAdminFields.USAGE, questionnaire.nodeUsageCount), _defineProperty(_obj, questionnaireAdminFields.ENABLED, {
          action: 'enabled',
          isChecked: questionnaire.selectable == 1,
          isDisabled: questionnaire.nodeUsageCount > 0,
          dataId: questionnaire.id,
          selectable: !questionnaire.selectable,
          id: questionnaire.id,
          currentTitle: questionnaire.title
        }), _defineProperty(_obj, questionnaireAdminFields.EDIT, {
          disableRouterLink: questionnaire.readonly,
          url: "/compliance/admin/questionnaires/".concat(_this3.questionnaireType, "/").concat(questionnaire.id, "/questions"),
          text: _this3.translate.edit
        }), _defineProperty(_obj, questionnaireAdminFields.SCORING, {
          url: {
            path: "/compliance/admin/questionnaires/".concat(questionnaire.id, "/scoring"),
            query: {
              questionnaireType: _this3.questionnaireType
            }
          },
          text: _this3.translate.scoring
        }), _defineProperty(_obj, questionnaireAdminFields.COPY, {
          href: '#',
          dataId: questionnaire.id,
          dataMethod: 'copy',
          action: 'copy',
          id: questionnaire.id,
          currentTitle: questionnaire.title,
          text: '',
          hasIcon: true,
          icon: 'copy'
        }), _defineProperty(_obj, questionnaireAdminFields.DELETE, {
          disableEvent: questionnaire.nodeUsageCount !== 0,
          href: '#',
          dataId: questionnaire.id,
          dataMethod: 'delete',
          action: 'delete',
          id: questionnaire.id,
          currentTitle: null,
          hasIcon: true,
          icon: 'trash',
          text: ''
        }), _defineProperty(_obj, questionnaireAdminFields.DOWNLOAD_CSV, {
          url: "/api/2.0/compliance/questionnaire/".concat(questionnaire.id, "/download?format=csv"),
          icon: 'file-text'
        }), _defineProperty(_obj, questionnaireAdminFields.DOWNLOAD_EXCEL, {
          url: "/api/2.0/compliance/questionnaire/".concat(questionnaire.id, "/download?format=excel"),
          icon: 'download'
        }), _obj);
        if (!_this3.scoringEnabled) {
          delete obj[questionnaireAdminFields.SCORING];
        }
        if (!_this3.availableGapSchemasLength) {
          delete obj[questionnaireAdminFields.DEFAULT_GAP_SCHEMA];
        }
        format.push(obj);
      });
      return format;
    },
    scoringEnabled: function scoringEnabled() {
      return this.getSettingValue('feature.questionnaire_scoring') === 1;
    },
    availableGapSchemasLength: function availableGapSchemasLength() {
      var _this$gapSchemasBytyp;
      return ((_this$gapSchemasBytyp = this.gapSchemasBytype) === null || _this$gapSchemasBytyp === void 0 || (_this$gapSchemasBytyp = _this$gapSchemasBytyp[this.questionnaireType]) === null || _this$gapSchemasBytyp === void 0 ? void 0 : _this$gapSchemasBytyp.length) || 0;
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'questionnaire');
    }
  }),
  watch: {
    questionnaireType: function questionnaireType(_questionnaireType) {
      this.$router.push({
        query: {
          questionnaireType: _questionnaireType
        }
      });
      this.saveChangesError = '';
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this4 = this;
      var promises = [this.getQuestionnaireTemplates(), this.getQuestionnaireTypes(), this.getQuestionnaires(), this.getGapSchemas(), this.getProjectsWithDefaultQuestionnaires()];
      this.isLoading = true;
      return Promise.all(promises).finally(function () {
        _this4.isLoading = false;
      });
    },
    getProjectsWithDefaultQuestionnaires: function getProjectsWithDefaultQuestionnaires() {
      var _this5 = this;
      return getAllProjects(true, 'data[]=defaultQuestionnaireId').then(function (_ref) {
        var list = _ref.list;
        return _this5.projects = list;
      });
    },
    getQuestionnaireTypes: function getQuestionnaireTypes() {
      var _this6 = this;
      return getAvailableQuestionnaireTypes().then(function (list) {
        return _this6.allQuestionnaireTypes = list;
      });
    },
    getQuestionnaires: function getQuestionnaires() {
      var _this7 = this;
      return getAllQuestionnaires().then(function (_ref2) {
        var list = _ref2.list;
        return _this7.allQuestionnaires = list;
      });
    },
    getQuestionnaireTemplates: function getQuestionnaireTemplates() {
      var _this8 = this;
      return getAllQuestionnaireTemplates('questionnaire').then(function (_ref3) {
        var list = _ref3.list;
        return _this8.templates = list;
      });
    },
    handleEvents: function handleEvents(_ref4, event) {
      var _this9 = this;
      var action = _ref4.action,
        id = _ref4.id,
        currentTitle = _ref4.currentTitle,
        selectable = _ref4.selectable;
      this.saveChangesError = '';
      this.newTitle = currentTitle;
      switch (action) {
        case 'enabled':
          this.onQuestionnaireEnableDisable(id, currentTitle, selectable, event);
          return;
        case 'delete':
          this.bufferFunction = function () {
            return deleteQuestionnaire(id).then(_this9.getQuestionnaires).catch(function () {
              _this9.saveChangesError = _this9.translate.somethingWentWrongWhileDeleting;
            });
          };
          this.showConfirmDeleteAlert = true;
          return;
        case 'copy':
          this.titleModalHeader = "".concat(this.translate.enterNewTitle, ":");
          this.bufferFunction = function (title) {
            return copyQuestionnaire(id, title).then(_this9.getQuestionnaires);
          };
          this.showTitleModal = true;
          return;
        case 'title':
          this.titleModalHeader = "".concat(this.translate.enterNewTitle, ":");
          this.bufferFunction = function (title) {
            return changeFieldOnQuestionnaire(id, title).then(_this9.getQuestionnaires);
          };
          this.showTitleModal = true;
          return;
        case 'tabLabel':
          this.titleModalHeader = "".concat(this.translate.enterNewTabLabel, ":");
          this.bufferFunction = function (title) {
            return changeFieldOnQuestionnaire(id, title, 'tabLabel').then(_this9.getQuestionnaires);
          };
          this.showTitleModal = true;
          return;
      }
    },
    doConfirm: function doConfirm() {
      this.bufferFunction(this.newTitle);
      this.closePopups();
    },
    closePopups: function closePopups() {
      this.newTitle = '';
      this.showTitleModal = false;
      this.showConfirmDeleteAlert = false;
      this.bufferFunction = null;
    },
    onQuestionnaireEnableDisable: function onQuestionnaireEnableDisable(id, currentTitle, selectable, event) {
      var usedAsDefaultForProject = this.isQuestionnaireUsedAsDefault(id);
      if (usedAsDefaultForProject && selectable === false) {
        this.saveChangesError = this.translate.theQuestionnaireCantBeDisabledErrorMessage;
        event.preventDefault();
        return;
      }
      return enableQuestionnaire(id, currentTitle, selectable).then(this.getQuestionnaires);
    },
    isQuestionnaireUsedAsDefault: function isQuestionnaireUsedAsDefault(questionnaireId) {
      switch (this.questionnaireType) {
        case QuestionnaireTypes.DATAPROCESSOR:
          return +this.getSettingValue('feature.processors_default_questionnaire_id') === +questionnaireId;
        case QuestionnaireTypes.SYSTEM:
          return +this.getSettingValue('feature.systems_default_questionnaire_id') === +questionnaireId;
        case QuestionnaireTypes.DATACONTROLLER:
          return +this.getSettingValue('feature.controllers_default_questionnaire_id') === +questionnaireId;
        case QuestionnaireTypes.IA_CUSTOM:
          return this.customIAModules.find(function (module) {
            return module.defaultQuestionnaireId === questionnaireId;
          });
        default:
          return this.projects.find(function (project) {
            return project.defaultQuestionnaireId === questionnaireId;
          });
      }
    },
    translateQuestionnaireType: function translateQuestionnaireType(type) {
      return this.$trans(getTranslatables('questionnaireTypes')[type] || type);
    },
    nodeCountTitle: function nodeCountTitle() {
      switch (this.questionnaireType) {
        case QuestionnaireTypes.DATAPROCESSOR:
          return this.translate.dataProcessorCount;
        case QuestionnaireTypes.SYSTEM:
          return this.translate.systemCount;
        case QuestionnaireTypes.DATACONTROLLER:
          return this.translate.controllersCount;
        case QuestionnaireTypes.DPM:
          return this.translate.dpmAuditCount;
        case QuestionnaireTypes.IAAUDIT:
          return this.translate.auditCount;
        default:
          return this.translate.processCount;
      }
    },
    getGapSchemas: function getGapSchemas() {
      var _this10 = this;
      return getAllGapSchemas('data[]=all').then(function (_ref5) {
        var list = _ref5.list;
        _this10.allGapSchemas = list;
      });
    },
    addDefaultGapSchema: function addDefaultGapSchema(gapSchemaId, questionnaireId) {
      return changeFieldOnQuestionnaire(questionnaireId, gapSchemaId, 'defaultGapSchemaId').then(this.getQuestionnaires);
    }
  }
};