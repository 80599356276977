import { Http } from '@/api/index';
import { handleRequestResponse } from '../../httpUtils';
export function getTopLevelNodes(projectId) {
  return handleRequestResponse(Http.get("/compliance/projects/".concat(projectId, "/report/outsourcing/toplevelnodes")));
}
export function getSuggestedDataForTopLevelNode(projectId, nodeId) {
  return handleRequestResponse(Http.get("/compliance/projects/".concat(projectId, "/report/outsourcing/").concat(nodeId, "/childnodes")));
}
export function createReport(projectId, nodeId, data) {
  return handleRequestResponse(Http.post("/compliance/projects/".concat(projectId, "/report/outsourcing/").concat(nodeId, "/reports"), data));
}
export function getReport(projectId, nodeId, reportId) {
  return handleRequestResponse(Http.get("/compliance/projects/".concat(projectId, "/report/outsourcing/").concat(nodeId, "/reports/").concat(reportId)));
}
export function saveReport(projectId, nodeId, reportId, editorJSData) {
  var url = "/compliance/projects/".concat(projectId, "/report/outsourcing/").concat(nodeId, "/reports/").concat(reportId);
  var data = {
    editorJSData: editorJSData
  };
  return handleRequestResponse(Http.patch(url, data));
}
export function deleteReport(projectId, nodeId, reportId) {
  return handleRequestResponse(Http.delete("/compliance/projects/".concat(projectId, "/report/outsourcing/").concat(nodeId, "/reports/").concat(reportId)));
}
export function getReports(projectId, nodeId) {
  return handleRequestResponse(Http.get("/compliance/projects/".concat(projectId, "/report/outsourcing/").concat(nodeId, "/reports")));
}