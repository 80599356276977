import RismaTitle from '../Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
export default {
  name: 'HierarchicalList',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink
  },
  introduction: 'a list for hierarchical elements',
  description: "this is a box list, that can list a set of hierarchical elements, and enables the user to expand and collapse all items.\n                  If the items should link to somewhere, each item need to have an url attribute with it's url",
  token: '<hierarchical-list v-for="node in nodes" :node="node"></hierarchical-list>',
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    node: {
      required: true,
      type: Object,
      note: 'a node with minimum an array of children'
    },
    depth: {
      type: Number,
      default: 0,
      note: 'the depth of current elements. will be calculated automatically'
    },
    showChildren: {
      type: Boolean,
      default: false,
      note: 'should the list be expanded from the start?'
    },
    toggleChildren: {
      type: Boolean,
      default: true,
      note: 'should user be able to show/hide children?'
    },
    hasPreviewMode: {
      required: false,
      type: Boolean,
      default: false,
      note: 'if it has previewModalLink instead of routerlink title'
    },
    isFirstLevel: {
      required: false,
      type: Boolean,
      default: true
    },
    isLastLevel: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['dismissModal', 'updated', 'updated:governanceReview'],
  data: function data() {
    return {
      showChild: false
    };
  },
  computed: {
    indent: function indent() {
      return {
        'margin-left': "".concat(this.depth, "rem")
      };
    },
    toggleIcon: function toggleIcon() {
      return this.showChild ? 'chevron-up' : 'chevron-down';
    }
  },
  created: function created() {
    this.showChild = this.showChildren;
  }
};