import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
var FIELD_GROUP_BASE_URL = '/admin/fieldgroups';
export function getFieldGroupsByScoringId(id) {
  return handleRequestResponse(Http.get("".concat(FIELD_GROUP_BASE_URL, "/scoring/").concat(id)));
}
export function createNewGroup(scoringId, data) {
  return handleRequestResponse(Http.post("".concat(FIELD_GROUP_BASE_URL, "/scoring/").concat(scoringId, "/fieldgroup"), data));
}
export function updateFieldGroups(scoringId, fieldGroups) {
  return handleRequestResponse(Http.patch("".concat(FIELD_GROUP_BASE_URL, "/scoring/").concat(scoringId), fieldGroups));
}