import { getTranslate } from './translate';
import { clickOutside } from '@/utils/directives/clickOutside';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaPagesize from '@/components/Atoms/RismaDatatable/RismaPagesize';
import RismaPrint from '@/components/Atoms/Print/RismaPrint';
import RismaExcel from '@/components/Atoms/Excel/RismaExcel';
export default {
  name: 'RismaTableHeader',
  description: 'Top part of RismaTable',
  token: '<risma-table-header/>',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    RismaToggle: RismaToggle,
    RismaDateRangePicker: RismaDateRangePicker,
    InputField: InputField,
    RismaPagesize: RismaPagesize,
    RismaPrint: RismaPrint,
    RismaExcel: RismaExcel
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    columns: {
      required: true,
      type: Array,
      note: 'Array with columns data'
    },
    pagingOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [10, 25, 50, 100];
      },
      note: 'Paging options'
    },
    preselectedPageSize: {
      type: Number,
      required: false,
      default: null
    },
    printTitle: {
      required: false,
      type: String,
      default: '',
      note: 'Title of table in print mode'
    },
    showSearchField: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Show search field in top right corner'
    },
    exportHandler: {
      required: false,
      type: Function,
      default: null
    },
    searchString: {
      required: false,
      type: String,
      default: ''
    }
  },
  emits: ['changeVisibleColumns', 'pageSizeChange', 'resetFormating', 'onGlobalSearch'],
  data: function data() {
    return {
      showTopBar: false,
      showDeactivatedOption: false,
      selectedPageSize: null,
      showColumnVisibility: false,
      filteredColumnsKeys: [],
      visibleColumns: [],
      translate: getTranslate['RismaTableHeader']()
    };
  },
  computed: {
    isAllChecked: function isAllChecked() {
      var columnKeys = this.columns.filter(function (col) {
        return !col.static;
      }).map(function (col) {
        return col.key;
      });
      return this.visibleColumns.length === columnKeys.length;
    }
  },
  watch: {
    columns: function columns() {
      this.initData();
    },
    preselectedPageSize: function preselectedPageSize(newPageSize) {
      if (this.selectedPageSize !== newPageSize) {
        this.selectedPageSize = this.preselectedPageSize;
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      this.visibleColumns = this.columns.filter(function (col) {
        return col.visible && !col.static;
      }).map(function (col) {
        return col.key;
      });
      this.selectedPageSize = this.preselectedPageSize || this.pagingOptions[0];
    },
    displayColumnsVisibility: function displayColumnsVisibility() {
      this.showColumnVisibility = true;
    },
    closeColumnVisibility: function closeColumnVisibility() {
      this.showColumnVisibility = false;
    },
    changeVisibleColumns: function changeVisibleColumns() {
      this.$emit('changeVisibleColumns', this.visibleColumns);
    },
    selectDeselectAll: function selectDeselectAll() {
      var columnKeys = this.columns.filter(function (col) {
        return !col.static;
      }).map(function (col) {
        return col.key;
      });
      this.visibleColumns = this.isAllChecked ? [] : columnKeys;
      this.$emit('changeVisibleColumns', this.visibleColumns);
    },
    onPageSizeChange: function onPageSizeChange(newPageSize) {
      this.$emit('pageSizeChange', newPageSize);
    },
    resetFormating: function resetFormating() {
      this.initData();
      this.$emit('resetFormating');
    }
  }
};