import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "question-selector w-full p-1"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["onClick"];
var _hoisted_4 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.expandedQuestions, function (question, key) {
    return _openBlock(), _createElementBlock("div", {
      key: key,
      class: "relative"
    }, [question.hasHeadline ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "headline-wrapper flex flex-row mt-4 ml-1 items-center cursor-pointer",
      onClick: function onClick($event) {
        return $options.handleClosedHeadlines(question.headline);
      }
    }, [_createVNode(_component_risma_title, {
      title: question.headline,
      class: "ml-2 text-21px",
      type: "big"
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
      class: "mx-2",
      icon: $setup.closedHeadlines.includes(question.headline) ? 'chevron-up' : 'chevron-down'
    }, null, 8 /* PROPS */, ["icon"])], 8 /* PROPS */, _hoisted_2)) : _createCommentVNode("v-if", true), !$setup.closedHeadlines.includes(question.headline) ? (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass(['truncate font-bold py-1 mb-1 px-4', {
        'text-gray-350': !question.answeredDependencies
      }, {
        'answered': question.isAnswered
      }, {
        'cursor-pointer': question.isClickable
      }]),
      onClick: function onClick($event) {
        return $options.changeQuestion(question.id);
      }
    }, [question.isAnswered ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      class: "float-left pt-1",
      icon: "check"
    })) : _createCommentVNode("v-if", true), _createElementVNode("span", {
      title: "".concat(question.questionnumber, ". ").concat(question.question)
    }, _toDisplayString(question.questionnumber) + ". " + _toDisplayString(question.question), 9 /* TEXT, PROPS */, _hoisted_4)], 10 /* CLASS, PROPS */, _hoisted_3)) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))]);
}