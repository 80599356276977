import TextSelector from '@/components/Atoms/Inputs/TextSelector';
export default {
  name: 'TextSelectorKitchen',
  components: {
    TextSelector: TextSelector
  },
  data: function data() {
    return {
      value: 1
    };
  }
};