import i18n from '@/i18n';
export var getTranslate = {
  QuestionDependencyEditor: function QuestionDependencyEditor() {
    return {
      questionDependency: i18n.t('Question dependency'),
      questions: i18n.t('Questions'),
      answerOptions: i18n.t('Answer options'),
      addNewDependency: i18n.t('Add new dependency')
    };
  }
};