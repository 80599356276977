function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import { RiskReportsFiltersMixin } from '@/components/Molecules/Risk/RiskReports/RiskReportsFiltersMixin';
import { wrongCustomFields } from '@/components/Pages/Risks/RiskReports/RiskReportUtils';
export default {
  name: 'RiskReportsFiltersErm',
  description: 'Filters section for risk reports(erm)',
  token: '<risk-reports-filters-erm />',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect
  },
  mixins: [RiskReportsFiltersMixin],
  props: {
    options: {
      required: true,
      type: Object,
      note: 'Object with options for selectors'
    },
    modelValue: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      },
      note: 'Object with selected filters values'
    },
    currentPeriod: {
      required: true,
      type: Object
    }
  },
  emits: ['changePeriod'],
  data: function data() {
    return {
      unSorted: true,
      xAxisValidation: '',
      managementLevelsValidation: '',
      translate: getTranslate['RiskReportsFiltersErm']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    riskTypeLabel: function riskTypeLabel() {
      return this.getSettingValue('risma.risktype_name');
    },
    customFields: function customFields() {
      var SPECIAL_TYPE = 10;
      return this.currentPeriod.fields.filter(function (field) {
        return field.fieldtype === SPECIAL_TYPE && field.options;
      });
    }
  }),
  methods: {
    prepareOptionsList: function prepareOptionsList(options) {
      return Object.values(options).reduce(function (acc, curr) {
        acc.push(_objectSpread(_objectSpread({}, curr), {}, {
          label: curr.value,
          value: curr.id + ''
        }));
        return acc;
      }, [{
        label: this.translate.all,
        value: 'all',
        id: 'all'
      }]);
    },
    setCustomFields: function setCustomFields(property, field, value) {
      var fieldsValue = {};
      if (wrongCustomFields[field.label]) {
        fieldsValue[field.label] = value;
      } else {
        fieldsValue[field.id] = value;
      }
      var customFields = _objectSpread(_objectSpread({}, this.modelValue[property]), fieldsValue);
      this.setFilterValue(property, _objectSpread({}, customFields));
    },
    validate: function validate() {
      var result = true;
      this.xAxisValidation = '';
      this.managementLevelsValidation = '';
      if (!this.modelValue.xAxis) {
        result = false;
        this.xAxisValidation = this.translate.pleaseSelectOne;
      }
      if (!this.modelValue.managementLevels || !(this.modelValue.managementLevels && this.modelValue.managementLevels.length)) {
        result = false;
        this.managementLevelsValidation = this.translate.pleaseSelectOne;
      }
      return result;
    }
  }
};