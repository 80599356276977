export var ProjectsTab = {
  GUESTIONNAIRETYPE: 'questionnaireType',
  GAPSCHEMATYPE: 'gapSchemaType'
};
export var GapsTrafficLights = function GapsTrafficLights(trans) {
  return [{
    label: trans('High'),
    value: 'HIGH'
  }, {
    label: trans('Medium'),
    value: 'MEDIUM'
  }, {
    label: trans('Low'),
    value: 'LOW'
  }, {
    label: trans('No gap'),
    value: 'NOGAP'
  }, {
    label: trans('Not applicable'),
    value: 'NA'
  }];
};