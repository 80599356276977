import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "well-wrapper"
};
var _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", null, [$options.isParent ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $options.title,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), $props.type !== $data.AssetsTypes.SYSTEMS ? (_openBlock(), _createBlock(_component_risma_select, {
    key: 0,
    options: $options.select_options,
    "selected-options": $options.multiple_selected_objects,
    taggable: true,
    placeholder: $data.translate.searchOrCreateNewProcessor,
    "tag-placeholder": $data.translate.addThisAsNewProcessor,
    class: "mb-4",
    "label-key": "name",
    "track-by": "id",
    onSelected: _cache[0] || (_cache[0] = function ($event) {
      return $options.multiple_selected_objects = $event;
    }),
    onTag: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('createProcessor', $event);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "tag-placeholder"])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.children, function (childSelected) {
    return _openBlock(), _createBlock(_component_router_link, {
      key: childSelected,
      class: "block",
      to: "/compliance/informationassets/".concat($props.type, "/").concat(childSelected)
    }, {
      default: _withCtx(function () {
        return [_createVNode(_component_feather_icon, {
          class: "inline",
          icon: "link"
        }), _createTextVNode(" " + _toDisplayString($options.itemById(childSelected).name), 1 /* TEXT */)];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]);
  }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $options.title,
    class: "mb-4",
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), $props.type === $data.AssetsTypes.SYSTEMS ? (_openBlock(), _createBlock(_component_single_select, {
    key: 0,
    "model-value": $props.parents[0],
    options: $options.parentlessItems,
    placeholder: $data.translate.chooseParentSystem,
    "un-sorted": true,
    "with-reset": true,
    class: "mb-4",
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('propertyChanged', {
        property: 'parentId',
        value: $event
      });
    })
  }, null, 8 /* PROPS */, ["model-value", "options", "placeholder"])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.parents, function (parent) {
    return _openBlock(), _createBlock(_component_router_link, {
      key: parent,
      class: "block",
      to: "/compliance/informationassets/".concat($props.type, "/").concat(parent)
    }, {
      default: _withCtx(function () {
        return [_createVNode(_component_feather_icon, {
          class: "inline",
          icon: "link"
        }), _createTextVNode(" " + _toDisplayString($options.itemById(parent).name), 1 /* TEXT */)];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]);
  }), 128 /* KEYED_FRAGMENT */))]))]);
}