function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getCustomFields, getCustomField, deleteCustomField } from '@/api/admin/admin';
import getUtilSettings from '@/api/risma/settings';
import { AdminCustomFieldsMixin } from '@/components/Organisms/Admin/AdminCustomFieldsMixin';
import { getCustomFieldTypeText } from '@/constants/CustomFieldTypes';
import { getModuleTitles, MODULES } from '@/constants/modules';
import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import AdminCustomFieldsModal from '@/components/Organisms/Admin/AdminCustomFieldsModal';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import Notification from '@/components/Molecules/Notification';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import Alert from '@/components/Molecules/Modal/Alert';
import { getDisabledModuleFromArrayBySetting } from '@/utils/format/getDisabledModuleFromArrayBySetting';
import { compareStrings } from '@/utils/compare';
export default {
  name: 'AdminCustomFieldsWell',
  introduction: 'Well with custom fields in /business/admin',
  description: 'Well with a list of custom fields. Used together with AdminCustomFieldsModal component.',
  token: '<admin-custom-fields-well></admin-custom-fields-well>',
  components: {
    MainWell: MainWell,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    AdminCustomFieldsModal: AdminCustomFieldsModal,
    Notification: Notification,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    RismaSelect: RismaSelect,
    RismaLabel: RismaLabel,
    Alert: Alert
  },
  mixins: [AdminCustomFieldsMixin],
  data: function data() {
    return {
      isLoaded: false,
      showConfirmationAlert: false,
      idToDelete: null,
      fields: [],
      notificationSuccess: null,
      notificationError: null,
      settings: {},
      moduleTitles: getModuleTitles(),
      selectedModules: [],
      translate: getTranslate['AdminCustomFieldsWell']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    filteredIds: function filteredIds() {
      return [this.getSettingValue('feature.isms_rpo_field_id'), this.getSettingValue('feature.isms_rto_field_id')];
    },
    modulesOptions: function modulesOptions() {
      var _this = this;
      var uniqueModules = _toConsumableArray(new Set(this.fields.map(function (item) {
        return item.module;
      })));
      return uniqueModules.map(function (module) {
        return {
          label: _this.moduleTitles[module],
          id: module
        };
      }).sort(function (a, b) {
        return compareStrings(a.label, b.label);
      });
    },
    sortedFields: function sortedFields() {
      var _this2 = this;
      return _toConsumableArray(this.fields).sort(function (a, b) {
        return compareStrings(a.label, b.label);
      }).sort(function (a, b) {
        return compareStrings(_this2.prepareModuleName(a.module), _this2.prepareModuleName(b.module));
      });
    },
    filteredFields: function filteredFields() {
      if (!this.selectedModules.length) return this.sortedFields;
      var moduleIds = this.selectedModules.map(function (item) {
        return item.id;
      });
      return this.sortedFields.filter(function (field) {
        return moduleIds.indexOf(field.module) !== -1;
      });
    }
  }),
  mounted: function mounted() {
    var _this3 = this;
    this.getSettings().then(function () {
      _this3.getData();
    });
  },
  methods: {
    getSettings: function getSettings() {
      var _this4 = this;
      return getUtilSettings().then(function (response) {
        return response.list.forEach(function (setting) {
          return _this4.settings[setting.settingname] = setting.value;
        });
      });
    },
    getData: function getData() {
      var _this5 = this;
      this.isLoaded = false;
      var params = '?data[]=hasValues&data[]=children';
      getCustomFields(params).then(function (_ref) {
        var list = _ref.list;
        _this5.isLoaded = true;
        _this5.fields = _this5.addProps(_this5.filterFields(list));
      }).catch(function () {
        _this5.isLoaded = true;
      });
    },
    filterFields: function filterFields(data) {
      var _this6 = this;
      var fields = data.filter(function (item) {
        return item.module !== MODULES.RISK_PROJECT_PERIOD && !_this6.filteredIds.includes(item.id);
      });
      var disabledModules = getDisabledModuleFromArrayBySetting(fields.map(function (item) {
        return item.module;
      }), this.getSettingValue);
      return fields.filter(function (item) {
        return !disabledModules.includes(item.module);
      });
    },
    addProps: function addProps(data) {
      var _this7 = this;
      data.map(function (item) {
        if (!item.fieldtype_text) {
          item.fieldtype_text = _this7.$trans(getCustomFieldTypeText(item.typeId));
        }
      });
      return data;
    },
    createCustomField: function createCustomField(e) {
      e.preventDefault();
      if (this.selectedModules.length === 1) {
        this.$refs.modal.onCreateOpen(this.selectedModules[0].id);
      } else {
        this.$refs.modal.onCreateOpen();
      }
      this.$refs.modal.toggleModal();
    },
    editCustomField: function editCustomField(id) {
      var _this8 = this;
      getCustomField(id).then(function (data) {
        _this8.$refs.modal.onEditOpen(data);
        _this8.$refs.modal.toggleModal();
      }).catch(function (e) {
        throw e;
      });
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var _this9 = this;
      this.showConfirmationAlert = false;
      deleteCustomField(this.idToDelete).then(function () {
        _this9.removeCustomFieldById(_this9.idToDelete);
        _this9.showSuccessMessage(_this9.translate.fieldDeleted);
      }).catch(function (data) {
        if (data) {
          if (data.response && data.response.data && data.response.data.error) {
            _this9.showErrorMessage(data.response.data.error);
          }
          throw data;
        }
      });
    },
    deleteCustomField: function deleteCustomField(id) {
      this.idToDelete = id;
      this.showConfirmationAlert = true;
    },
    removeCustomFieldById: function removeCustomFieldById(id) {
      var _this10 = this;
      this.fields.map(function (field, index) {
        if (field.id === id) {
          _this10.fields.splice(index, 1);
        }
      });
    },
    onCreated: function onCreated() {
      this.showSuccessMessage(this.translate.fieldWasCreated);
      this.getData();
    },
    onEdit: function onEdit() {
      this.$refs.modal.toggleModal();
      this.showSuccessMessage(this.translate.fieldWasUpdated);
      this.getData();
    },
    prepareModuleName: function prepareModuleName(data) {
      switch (data) {
        case MODULES.COMPLIANCE_IA_CONTROLLER:
          return 'IA: Data Controller';
        case MODULES.COMPLIANCE_IA_PROCESSOR:
          return 'IA: Data Processor';
        case MODULES.COMPLIANCE_IA_SYSTEM:
          return 'IA: System';
        default:
          return this.moduleTitles[data] || data;
      }
    }
  }
};