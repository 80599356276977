import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(tz);
export function getLocaleDateFormatString() {
  var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'en';
  var localLocale = dayjs();
  localLocale.locale(locale);
  return localLocale.localeData().longDateFormat('L');
}
export function getTZGuess() {
  return dayjs.tz.guess();
}
export function getCurrentMonthIndex() {
  return dayjs().month();
}
export function getMonthsNames(number) {
  if (number === undefined) return dayjs.months();
  return dayjs.months()[number];
}
export function getShortMonthNames() {
  return dayjs.monthsShort();
}
export function getMonthIndex(date) {
  return dayjs(date).month();
}
export function getMonthName(date) {
  return dayjs.months()[getMonthIndex(date)];
}
export function setYear(date, format, year) {
  return dayjs(date, format).set('year', year);
}
export function getCurrentYear() {
  return dayjs().year();
}
export function getYear(date) {
  return dayjs(date).year();
}
export function getYearAfterAddingYears(number) {
  return dayjs().add(number, 'years').year();
}