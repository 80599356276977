function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["innerHTML"];
var _hoisted_2 = {
  key: 0,
  class: "mb-4 pb-6 border-b-2 border-gray-250"
};
var _hoisted_3 = {
  key: 2,
  class: "mb-4 pb-6 border-b-2 border-gray-250"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_notification = _resolveComponent("notification");
  var _component_node_breadcrumbs = _resolveComponent("node-breadcrumbs");
  var _component_delegation_well = _resolveComponent("delegation-well");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_activity_type_selector = _resolveComponent("activity-type-selector");
  var _component_solution_id_relation_details = _resolveComponent("solution-id-relation-details");
  var _component_governance_review_section = _resolveComponent("governance-review-section");
  var _component_multiple_questionnaire_selector = _resolveComponent("multiple-questionnaire-selector");
  var _component_single_select = _resolveComponent("single-select");
  var _component_dep_well = _resolveComponent("dep-well");
  return $data.isLoaded ? (_openBlock(), _createElementBlock("div", {
    key: $props.nodeId
  }, [$data.showConfirmationAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    body: $data.confirmMessage,
    "alert-type": "confirmation",
    onAccept: $options.onConfirm,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showConfirmationAlert = false;
    })
  }, null, 8 /* PROPS */, ["body", "onAccept"])) : _createCommentVNode("v-if", true), $data.isNotificationShow ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: $data.notificationType,
    onDismiss: $options.handleDismissNotification
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.notificationMessage), 1 /* TEXT */), _createElementVNode("div", {
        innerHTML: $data.notificationDetails
      }, null, 8 /* PROPS */, _hoisted_1)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type", "onDismiss"])) : _createCommentVNode("v-if", true), $options.currentProject.locked ? (_openBlock(), _createBlock(_component_notification, {
    key: 2,
    closeable: false,
    type: "info"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.thisActivityIsReadonlyAsYourUserIsConfigu), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createBlock(_component_node_breadcrumbs, {
    key: 3,
    node: $data.node
  }, null, 8 /* PROPS */, ["node"])) : _createCommentVNode("v-if", true), _createVNode(_component_dep_well, {
    "is-loaded": $data.isLoaded,
    item: $data.node,
    "dep-header-props": $options.depHeaderProps,
    "local-storage-key": "relations-compliance",
    onUpdatingAttachments: $options.updatingAttachments,
    onFileUpdate: $options.onFileUpdate,
    onSaveStatusClicked: $options.saveStatusClicked,
    onPropertyChanged: $options.nodePropertyChanged,
    onGetActivityData: $options.getActivityData,
    onUpdateValue: $options.updateValue,
    onDeleteNode: $options.deleteNode,
    onChangeTab: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('changeTab', $event);
    }),
    "onUpdate:isPageWithCustomUpdateDirty": $options.handleUpdateForCustomPageUpdate,
    onQuestionAnswered: $options.questionAnswered
  }, {
    delegation: _withCtx(function () {
      return [$data.node ? (_openBlock(), _createBlock(_component_delegation_well, {
        key: 0,
        "model-value": _objectSpread(_objectSpread({}, $data.node), {}, {
          module: $data.module
        }),
        "read-only": !!$options.currentProject.locked,
        organisations: _ctx.organisations,
        "current-activity-type": $options.currentActivityType,
        onPropertyChanged: $options.saveChangesImmediately
      }, null, 8 /* PROPS */, ["model-value", "read-only", "organisations", "current-activity-type", "onPropertyChanged"])) : _createCommentVNode("v-if", true)];
    }),
    details: _withCtx(function () {
      return [$options.showActivityTypeSelector ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
        type: "medium",
        title: $data.translate.activityType
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_activity_type_selector, {
        modelValue: $data.node.activityTypeId,
        "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
          return $data.node.activityTypeId = $event;
        }), _cache[2] || (_cache[2] = function (id) {
          return $options.nodePropertyChanged({
            property: 'activityTypeId',
            value: id
          });
        })],
        "activity-types": _ctx.activityTypes,
        disabled: !!$options.currentProject.locked,
        "confirm-alert-message": $data.translate.thisWillChangeActivityTypeAndPossiblyRemove
      }, null, 8 /* PROPS */, ["modelValue", "activity-types", "disabled", "confirm-alert-message"])])) : _createCommentVNode("v-if", true), _createVNode(_component_solution_id_relation_details, {
        list: $data.node.solutionIds,
        "read-only": !!$options.currentProject.locked,
        onPropertyChanged: $options.nodePropertyChanged
      }, null, 8 /* PROPS */, ["list", "read-only", "onPropertyChanged"]), _createVNode(_component_governance_review_section, {
        class: "mb-4 pb-6 border-b-2 border-gray-250",
        review: _ctx.review,
        "project-name": $data.project.title,
        users: _ctx.users,
        organisations: _ctx.organisations,
        disabled: !!$options.currentProject.locked,
        onUpdate: _ctx.changeReview
      }, null, 8 /* PROPS */, ["review", "project-name", "users", "organisations", "disabled", "onUpdate"]), $options.showQuestionnaire && $data.node.id && $data.questionnaires.length ? (_openBlock(), _createBlock(_component_multiple_questionnaire_selector, {
        key: 1,
        class: "mb-4 pb-6 border-b-2 border-gray-250",
        "questionnaire-options": $data.questionnaires,
        disabled: !!$options.currentProject.locked,
        "selected-questionnaires": $data.node.questionnaires,
        onAddQuestionnaire: $options.addQuestionnaire,
        onRemoveQuestionnaire: $options.removeQuestionnaire
      }, null, 8 /* PROPS */, ["questionnaire-options", "disabled", "selected-questionnaires", "onAddQuestionnaire", "onRemoveQuestionnaire"])) : _createCommentVNode("v-if", true), $options.gapAnalysisEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_3, [$data.node.id && $data.gaps !== null ? (_openBlock(), _createBlock(_component_single_select, {
        key: 0,
        title: $data.translate.gapSchemaVersion,
        options: $data.gaps,
        "model-value": $data.node.gapSchemaId,
        disabled: !!$options.currentProject.locked,
        placeholder: $data.translate.selectOne,
        "with-reset": true,
        "onUpdate:modelValue": $options.setGapSchema
      }, null, 8 /* PROPS */, ["title", "options", "model-value", "disabled", "placeholder", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-loaded", "item", "dep-header-props", "onUpdatingAttachments", "onFileUpdate", "onSaveStatusClicked", "onPropertyChanged", "onGetActivityData", "onUpdateValue", "onDeleteNode", "onUpdate:isPageWithCustomUpdateDirty", "onQuestionAnswered"])])) : _createCommentVNode("v-if", true);
}