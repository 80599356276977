function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getTranslate } from './translate';
import isEqual from 'lodash/isEqual';
import Selector from '@/components/Atoms/Inputs/Selector';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
export default {
  name: 'AddContractsModal',
  introduction: 'Multiselect popup',
  description: 'Modal with group selector, contracts selector and list of selected items',
  token: '<add-contracts-modal :preselected-contracts=[] :contracts=[] />',
  components: {
    Selector: Selector,
    FeatherIcon: FeatherIcon,
    Modal: Modal
  },
  props: {
    preselectedContracts: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of already selected contract items'
    },
    contracts: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of all available contracts'
    }
  },
  emits: ['update', 'closeModal'],
  data: function data() {
    return {
      selectedContracts: [],
      searchFields: ['title'],
      isContractValid: true,
      translate: getTranslate['AddContractsModal']()
    };
  },
  computed: {
    selectableItems: function selectableItems() {
      var _this = this;
      return this.contracts.filter(function (contract) {
        return !_this.selectedContracts.find(function (selected) {
          return selected.id === contract.id;
        });
      });
    }
  },
  watch: {
    selectedContracts: {
      deep: true,
      handler: function handler() {
        this.isContractValid = true;
      }
    }
  },
  mounted: function mounted() {
    this.selectedContracts = _toConsumableArray(this.preselectedContracts);
  },
  methods: {
    selected: function selected(item) {
      this.selectedContracts.push(item);
    },
    deselect: function deselect(item) {
      this.selectedContracts = this.selectedContracts.filter(function (selected) {
        return item.id !== selected.id;
      });
    },
    onAccept: function onAccept() {
      if (!isEqual(this.selectedContracts, this.preselectedContracts)) {
        return this.$emit('update', this.selectedContracts);
      }
      if (!this.selectedContracts.length) {
        return this.isContractValid = false;
      }
      return this.$emit('closeModal');
    }
  }
};