function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getMyInitiatives as _getMyInitiatives, getMyDailyInitiatives as _getMyDailyInitiatives } from '@/api/execution/initiative';
import { MY_ACTIONS_VIEWS } from '@/constants/ViewTypes';
import { InitiativesViews } from '@/constants/InitiativesViews';
import { compareDates, comparePathStrings } from '@/utils/compare';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Notification from '@/components/Molecules/Notification';
import PreviewListTile from '@/components/Molecules/PreviewList/PreviewListTile';
import PreviewListExpanded from '@/components/Molecules/PreviewList/PreviewListExpanded';
import CatalogueHeader from '@/components/Molecules/CatalogueHeader/CatalogueHeader';
import GanttChart from '@/components/Molecules/Charts/GanttChart';
import { CommonAssetsMixin } from '@/mixins/CommonAssetsMixin';
import { check403AccessError } from '@/utils/handleAPIErrors';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'MyActions',
  introduction: 'List of my actions.',
  description: 'This is Page component for /actions/my/',
  token: '<my-actions></my-actions>',
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    Notification: Notification,
    PreviewListTile: PreviewListTile,
    CatalogueHeader: CatalogueHeader,
    GanttChart: GanttChart,
    PreviewListExpanded: PreviewListExpanded
  },
  mixins: [CommonAssetsMixin],
  props: {
    isHeaderVisible: {
      required: false,
      type: Boolean,
      default: true,
      note: 'Display sorting and diplay view or not'
    },
    isOnlyMyInitiatives: {
      required: false,
      type: Boolean,
      default: false,
      note: 'to display only my actions, not groups'
    },
    viewMode: {
      required: false,
      type: String,
      default: '',
      note: 'if parent component have viewTypes and want to handle this component by them'
    }
  },
  setup: function setup() {
    return {
      currentView: getLocalStorageComputed('my_actions_display_view', InitiativesViews.TILES),
      sortBy: getLocalStorageComputed('my_actions_sort_by', 'ipath'),
      overviewOptionsState: getLocalStorageComputed('my_actions_overview', false, true)
    };
  },
  data: function data() {
    return {
      isLoaded: false,
      unSorted: true,
      MY_ACTIONS_VIEWS: MY_ACTIONS_VIEWS,
      InitiativesViews: InitiativesViews,
      initiativesGroups: {},
      translate: getTranslate['MyActions']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useActivityTypesStore, {
    activityTypes: 'executionActivityTypes'
  })), {}, {
    currentUserId: function currentUserId() {
      return this.currentUser.id;
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'action');
    },
    sortOptions: function sortOptions() {
      return [{
        id: 1,
        label: this.translate.actionsNumber,
        value: 'ipath'
      }, {
        id: 2,
        label: this.translate.deadline,
        value: 'deadline'
      }];
    }
  }),
  watch: {
    viewMode: function viewMode() {
      this.setView(this.viewMode);
    }
  },
  beforeMount: function beforeMount() {
    this.initInitiativeGroups();
  },
  mounted: function mounted() {
    var _this = this;
    this.getData().then(function () {
      _this.setSettings();
      _this.isLoaded = true;
    });
  },
  methods: {
    initInitiativeGroups: function initInitiativeGroups() {
      this.initiativesGroups = {
        myInitiatives: {
          id: 1,
          name: 'action',
          label: 'My actions',
          activities: [],
          hidden: false,
          view: this.currentView,
          cookieName: 'rismahider-my-initiatives'
        },
        myDailyTeamInitiatives: {
          id: 2,
          name: 'action',
          label: 'My daily team actions',
          activities: [],
          hidden: false,
          view: this.currentView,
          cookieName: 'rismahider-my-daily-initiatives'
        }
      };
    },
    setSettings: function setSettings() {
      var viewType = this.viewMode.length ? this.viewMode : this.currentView;
      this.setView(viewType);
      this.sortInitiatives(this.sortBy);
    },
    getData: function getData() {
      return Promise.all([this.getMyInitiatives(), this.getMyDailyInitiatives()]);
    },
    getMyInitiatives: function getMyInitiatives() {
      var _this2 = this;
      return _getMyInitiatives().then(function (_ref) {
        var list = _ref.list;
        _this2.populateMyInitiatives(list);
      });
    },
    getMyDailyInitiatives: function getMyDailyInitiatives() {
      var _this3 = this;
      if (!this.isOnlyMyInitiatives) {
        return _getMyDailyInitiatives().then(function (_ref2) {
          var list = _ref2.list;
          _this3.populateMyDailyTeamInitiatives(list);
        });
      }
    },
    populateMyInitiatives: function populateMyInitiatives(list) {
      this.initiativesGroups.myInitiatives.activities = list;
    },
    populateMyDailyTeamInitiatives: function populateMyDailyTeamInitiatives(list) {
      this.initiativesGroups.myDailyTeamInitiatives.activities = list;
    },
    sortInitiatives: function sortInitiatives(value) {
      var compareFn;
      if (value === 'ipath') {
        compareFn = comparePathStrings;
      } else if (value === 'deadline') {
        compareFn = compareDates;
      }
      this.sortBy = value;
      for (var key in this.initiativesGroups) {
        this.initiativesGroups[key].activities.sort(function (a, b) {
          return compareFn(a[value], b[value]);
        });
      }
    },
    setView: function setView(viewType) {
      this.currentView = viewType;
      this.updateActionsView();
    },
    updateActionsView: function updateActionsView() {
      for (var key in this.initiativesGroups) {
        this.initiativesGroups[key].view = this.currentView;
      }
    },
    updateFromPreviewMode: function updateFromPreviewMode(updatedAction, groupId) {
      var _Object$values$find;
      var activities = (_Object$values$find = Object.values(this.initiativesGroups).find(function (group) {
        return group.id === groupId;
      })) === null || _Object$values$find === void 0 ? void 0 : _Object$values$find.activities;
      if (!(activities !== null && activities !== void 0 && activities.length)) return;
      var updatedActionIndex = activities.findIndex(function (a) {
        return a.id == updatedAction.id;
      });
      if (updatedActionIndex === -1) return;
      activities[updatedActionIndex] = updatedAction;
    }
  }
};