import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["onClick"];
var _hoisted_2 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  var _component_preview_list = _resolveComponent("preview-list");
  var _component_pdf_report_edit_modal = _resolveComponent("pdf-report-edit-modal");
  var _component_file_preview_modal = _resolveComponent("file-preview-modal");
  var _component_alert = _resolveComponent("alert");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_preview_list, {
    hideable: false,
    title: $data.translate.pdfReportGenerator
  }, {
    "nav-buttons": _withCtx(function () {
      return [_createElementVNode("div", {
        class: "ml-auto cursor-pointer h-10",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $options.editModalInit();
        })
      }, [_createVNode(_component_feather_icon, {
        class: "text-blue-750 stroke-3 h-full w-10",
        icon: "plus"
      })])];
    }),
    default: _withCtx(function () {
      return [_createVNode(_component_static_table, {
        dataset: $options.dataset,
        columns: $options.columns,
        "filter-by-column": true
      }, {
        title: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_createElementVNode("div", null, _toDisplayString(slotData), 1 /* TEXT */)];
        }),

        preview: _withCtx(function (_ref2) {
          var slotData = _ref2.slotData;
          return [_createElementVNode("div", {
            class: "cursor-pointer",
            onClick: function onClick($event) {
              return $options.showPreview(slotData.token);
            }
          }, _toDisplayString($data.translate.showPreview), 9 /* TEXT, PROPS */, _hoisted_1)];
        }),
        download: _withCtx(function (_ref3) {
          var slotData = _ref3.slotData;
          return [_createElementVNode("a", {
            href: $data.pdfReportLink + slotData.token,
            download: ""
          }, _toDisplayString($data.translate.download), 9 /* TEXT, PROPS */, _hoisted_2)];
        }),
        actions: _withCtx(function (_ref4) {
          var slotData = _ref4.slotData;
          return [_createVNode(_component_feather_icon, {
            class: "cursor-pointer inline-block",
            icon: "edit",
            onClick: function onClick($event) {
              return $options.editModalInit(slotData.report.id);
            }
          }, null, 8 /* PROPS */, ["onClick"]), _createVNode(_component_feather_icon, {
            class: "cursor-pointer inline-block ml-2",
            icon: "trash-2",
            onClick: function onClick($event) {
              return $data.reportToDelete = slotData.report;
            }
          }, null, 8 /* PROPS */, ["onClick"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset", "columns"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]), $data.editModalShown ? (_openBlock(), _createBlock(_component_pdf_report_edit_modal, {
    key: 0,
    "current-report-id": $data.currentReportId,
    onUpdateReport: $options.updateReport,
    onCreateReport: $options.createReport,
    onDismiss: $options.closeEditModal
  }, null, 8 /* PROPS */, ["current-report-id", "onUpdateReport", "onCreateReport", "onDismiss"])) : _createCommentVNode("v-if", true), $data.previewFile ? (_openBlock(), _createBlock(_component_file_preview_modal, {
    key: 1,
    downloadable: false,
    "file-src": $data.previewFile.fileSrc,
    "file-src-type": $data.previewFile.fileType,
    "pdf-modal-width": "80%",
    "pdf-modal-height": "70%",
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return $data.previewFile = null;
    })
  }, null, 8 /* PROPS */, ["file-src", "file-src-type"])) : _createCommentVNode("v-if", true), $data.reportToDelete ? (_openBlock(), _createBlock(_component_alert, {
    key: 2,
    header: $data.translate.areYouSure,
    body: "".concat($data.reportToDelete.title, " ").concat($data.translate.willBeRemoved),
    "button-ok-text": $data.translate.ok,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.removePdfReport,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return $data.reportToDelete = null;
    })
  }, null, 8 /* PROPS */, ["header", "body", "button-ok-text", "button-dismiss-text", "onAccept"])) : _createCommentVNode("v-if", true)]);
}