import { defineStore } from 'pinia';
import { get as getTags } from '../api/tags/tags';
import cloneDeep from 'lodash/cloneDeep';
export var useTagsStore = defineStore('tagsStore', {
  state: function state() {
    return {
      tags: {}
    };
  },
  getters: {
    validTags: function validTags() {
      return this.tags.filter(function (tag) {
        return tag.tag;
      });
    }
  },
  actions: {
    set: function set(tags) {
      this.tags = cloneDeep(tags);
    },
    load: function load() {
      var _this = this;
      return getTags().then(function (_ref) {
        var list = _ref.list;
        _this.tags = list;
      });
    },
    newTag: function newTag(tag) {
      this.tags.push(tag);
    },
    updateTag: function updateTag(tag) {
      this.tags = this.tags.map(function (t) {
        return +t.id === +tag.id ? tag : t;
      });
    },
    deleteTag: function deleteTag(tagId) {
      this.tags = this.tags.filter(function (t) {
        return t.id !== tagId;
      });
    }
  }
});