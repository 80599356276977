import { getTranslate } from './translate';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import BowTieEllipse from '@/components/Organisms/BowTie/BowTieEllipse';
export default {
  name: 'BowTie',
  description: 'Component displays all the various threats than can lead to a risk scenario, and all the various consequences it can lead to.',
  components: {
    BowTieEllipse: BowTieEllipse,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle
  },
  data: function data() {
    return {
      columnWidth: null,
      ellipseWidth: 144,
      causes: [{
        title: 'Fire',
        controls: ['Control 1', 'Control 2', 'Control 3'],
        color: 'red-50',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting\n                     industry. Lorem Ipsum has been the industry's standard dummy text ever since \n                     the 1500s, when an unknown printer took a galley of type and scrambled it to"
      }, {
        title: 'Hacking',
        color: 'green-150',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting\n                     industry. Lorem Ipsum has been the industry's standard dummy text ever since "
      }, {
        title: 'Earthquake',
        controls: ['Control 1', 'Control 2', 'Control 3'],
        color: 'gray-450'
      }, {
        title: 'Hacking',
        color: 'green-150'
      }, {
        title: 'Fire',
        color: 'red-50'
      }, {
        title: 'Hacking',
        color: 'green-150'
      }],
      incidents: [{
        title: 'Unavailable IT system',
        color: 'red-50'
      }, {
        title: 'Incident 2',
        color: 'green-150'
      }, {
        title: 'Incident 2',
        color: 'yellow-50'
      }],
      consequences: [{
        title: 'Consequence 1',
        color: 'yellow-50',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting\n                     industry. Lorem Ipsum has been the industry's standard dummy text ever since \n                     the 1500s, when an unknown printer took a galley of type and scrambled it to\n                     make a type specimen book. It has survived not only five centuries, but also the leap into electronic\n                     typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of \n                     Letraset sheets containing Lorem Ipsum passages, and more recently with desktop\n                     publishing software like Aldus PageMaker including versions of Lorem Ipsum."
      }, {
        title: 'Consequence 2',
        color: 'green-150',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting\n                     industry. Lorem Ipsum has been the industry's standard dummy text ever since \n                     the 1500s, when an unknown printer took a galley of type and scrambled it to\n                     make a type specimen book. It has survived not only five centuries, but also the leap into electronic\n                     typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of \n                     Letraset sheets containing Lorem Ipsum passages, and more recently with desktop\n                     publishing software like Aldus PageMaker including versions of Lorem Ipsum."
      }, {
        title: 'Consequence 3',
        color: 'gray-450'
      }, {
        title: 'Consequence 4',
        color: 'green-150'
      }, {
        title: 'Consequence 5',
        color: 'red-50'
      }, {
        title: 'Consequence 6',
        color: 'green-150'
      }, {
        title: 'Consequence 1',
        color: 'red-50'
      }, {
        title: 'Consequence 2',
        color: 'green-150'
      }, {
        title: 'Consequence 3',
        controls: 3,
        color: 'gray-450'
      }, {
        title: 'Consequence 4',
        color: 'green-150'
      }],
      translate: getTranslate['BowTie']()
    };
  },
  computed: {
    causesGrid: function causesGrid() {
      var columnGrid = this.prepareColumns(this.causes);
      if (columnGrid.length > this.columnWidth / this.ellipseWidth) {
        return [this.causes];
      }
      return columnGrid;
    },
    consequencesGrid: function consequencesGrid() {
      var columnGrid = this.prepareColumns(this.consequences);
      if (columnGrid.length > this.columnWidth / this.ellipseWidth) {
        return [this.consequences];
      }
      return columnGrid;
    }
  },
  mounted: function mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeUnmount: function beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize: function handleResize() {
      this.columnWidth = this.$el.querySelector('.js-ellipses-box').clientWidth;
    },
    prepareColumns: function prepareColumns(array) {
      var count = 1;
      var result = [];
      var columns = [];
      array.forEach(function (item, index) {
        columns.push(item);
        if (columns.length === count) {
          result.push(columns);
          columns = [];
          count += 1;
        } else if (index === array.length - 1) {
          result.push(columns);
        }
      });
      return result;
    }
  }
};