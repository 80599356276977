import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { Colors } from '@/Colors';
import { getDataFlowImageUrl } from '@/utils/Compliance/DataFlow';
import FeatherIcon from '../Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'SaveDataflowAsImage',
  introduction: 'Component is needed to save the dataflow tree',
  description: 'Component get canvas from tree data from backend',
  token: "<save-dataflow-as-image\n        :dataflow=\"formattedFlows\" />",
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle
  },
  props: {
    projectId: {
      required: true,
      type: Number,
      note: 'ID of current project'
    },
    nodeId: {
      required: true,
      type: Number,
      note: 'Current id for node object'
    },
    isDataflowEmpty: {
      required: true,
      type: Boolean,
      note: 'whether it has some dataflows to draw'
    }
  },
  data: function data() {
    return {
      colors: Colors,
      translate: {
        exportDataFlow: this.$trans('Export data flow')
      }
    };
  },
  computed: {
    url: function url() {
      return getDataFlowImageUrl(this.projectId, this.nodeId);
    }
  }
};