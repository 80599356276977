function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
import { IATitles } from '@/constants/compliance/ComplianceControllers';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { formatTimeToDateFormat, formatTimeToDateTimeFormat } from '@/utils/date';
import { Colors } from '@/Colors';
export default {
  name: 'GapSchemaQuestions',
  components: {
    RismaTitle: RismaTitle
  },
  props: {
    questions: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function data() {
    return {
      allColors: Colors,
      expandedQuestions: {},
      questionTypes: QuestionTypes()
    };
  },
  watch: {
    questions: {
      deep: true,
      handler: function handler() {
        this.questionsToExpandedQuestions();
      }
    }
  },
  mounted: function mounted() {
    this.questionsToExpandedQuestions();
  },
  methods: {
    fullLengthQuestion: function fullLengthQuestion(event) {
      event.target.classList.remove('truncate');
    },
    truncateQuestion: function truncateQuestion(event) {
      event.target.classList.add('truncate');
    },
    toggleAnswer: function toggleAnswer(questionId) {
      this.expandedQuestions[questionId] = !this.expandedQuestions[questionId];
    },
    questionsToExpandedQuestions: function questionsToExpandedQuestions() {
      var _this = this;
      this.questions.forEach(function (question) {
        _this.expandedQuestions[question.id] = true;
      });
    },
    getAnswerOptions: function getAnswerOptions(question) {
      if (question.objType === ObjectTypes.COMPLIANCE_QUESTION_ANSWER) {
        switch (question.questiontype) {
          case this.questionTypes.informationAsset.id:
          case this.questionTypes.informationAssetSystems.id:
          case this.questionTypes.informationAssetProcessors.id:
          case this.questionTypes.informationAssetControllers.id:
          case this.questionTypes.customInformationAsset.id:
            return this.prepareIaQuestionAnswer(question);
          case this.questionTypes.processLibrary.id:
            return question.processLibrary.map(function (item) {
              return item.title;
            });
          case this.questionTypes.date.id:
            return [formatTimeToDateFormat(question.dateTime)];
          case this.questionTypes.dateTime.id:
            return [formatTimeToDateTimeFormat(question.dateTime)];
          case this.questionTypes.attachment.id:
          case this.questionTypes.attachmentAndFreeText.id:
            return question.files.map(function (file) {
              return file.filename;
            });
          default:
            return question.answers_options;
        }
      }
      if (question.objType === ObjectTypes.COMPLIANCE_QUESTION) {
        return question.questionAnswers.filter(function (answer) {
          return answer.complianceQuestionoptionsId !== null;
        }).map(function (answer) {
          return question.options[answer.complianceQuestionoptionsId];
        });
      }
      return [];
    },
    getRightTextByAnswer: function getRightTextByAnswer(answer) {
      if (_typeof(answer) === 'object') {
        return "".concat(this.$trans(answer.title), ": ").concat(answer.items);
      }
      return answer;
    },
    prepareIaQuestionAnswer: function prepareIaQuestionAnswer(question) {
      var data = {};
      Object.keys(question.informationAssets).forEach(function (iaItem) {
        if (question.informationAssets[iaItem].length) {
          data[iaItem] = {
            title: IATitles[iaItem] || iaItem,
            items: question.informationAssets[iaItem].map(function (element) {
              return element.name || element.title;
            }).join(', ')
          };
        }
      });
      return data;
    },
    getFreeText: function getFreeText(question) {
      if (question.objType === ObjectTypes.COMPLIANCE_QUESTION_ANSWER) {
        return question.freeText;
      }
      if (question.objType === ObjectTypes.COMPLIANCE_QUESTION) {
        if (question.questionAnswers.length === 1) {
          return question.questionAnswers[0].freeText;
        }
      }
    }
  }
};