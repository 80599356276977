// if it is not the same component for button that opens something and element with clickoutside then use stopPropagation (click.stop) to open the component
export var clickOutside = {
  beforeMount: function beforeMount(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('mousedown', el.clickOutsideEvent);
  },
  unmounted: function unmounted(el) {
    document.body.removeEventListener('mousedown', el.clickOutsideEvent);
  }
};