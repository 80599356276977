function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import Modal from '@/components/Molecules/Modal/Modal';
import Notification from '@/components/Molecules/Notification';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import HelpToolTip from '@/components/Molecules/HelpToolTip';
import { DateFormats } from '@/constants/DateFormats';
import { isEmailValid } from '@/utils/validations';
import { escapeHtml } from '@/utils';
import { getUserLanguage } from '@/utils/Utils';
import { addTimeAndFormatDate, formatTime, getLocaleDateFormatString } from '@/utils/date';
export default {
  name: 'DpmSendQuestionnaireModal',
  introduction: 'Component for displaying a Modal dialog',
  description: 'This modal ensures that the user understands the different aspects of the process. This means that they are informed about what the data they are presenting, are gonna do and how the flow will be from when they send the audit. ',
  token: "<dpm-send-questionnaire-modal\n    :audit-questionnaires=\"newProcesses\"/>",
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    InputField: InputField,
    TextBox: TextBox,
    Modal: Modal,
    Notification: Notification,
    DateTimePicker: DateTimePicker,
    HelpToolTip: HelpToolTip
  },
  props: {
    auditQuestionnaires: {
      required: true,
      type: [Array, Object],
      note: 'Questionnaire names for data processor management',
      default: function _default() {}
    },
    prefillCompany: {
      required: false,
      type: String,
      note: 'Prefill for modal form',
      default: ''
    },
    prefillEmail: {
      required: false,
      type: String,
      note: 'Prefill for modal form',
      default: ''
    },
    prefillName: {
      required: false,
      type: String,
      note: 'Prefill for modal form',
      default: ''
    }
  },
  emits: ['dismiss', 'update'],
  data: function data() {
    return {
      selectedOptionProcessor: null,
      showModal: false,
      commentsWritten: '',
      nameWritten: this.prefillName,
      companyWritten: this.prefillCompany || '',
      emailWritten: this.prefillEmail || '',
      questionnaireError: false,
      nameError: false,
      companyError: false,
      emailError: '',
      deadlineError: false,
      today: new Date(),
      deadlineValue: this.getCurrentDatePlusOneMonth(),
      datePickerOptions: {
        format: DateFormats.DATE_FORMAT,
        showTodayButton: false
      },
      translate: getTranslate['DpmSendQuestionnaireModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    descriptionOfTheError: function descriptionOfTheError() {
      return this.translate.pleaseFillOutThisField;
    },
    dateFormat: function dateFormat() {
      return getLocaleDateFormatString(getUserLanguage(this.currentUser));
    },
    deadlineHint: function deadlineHint() {
      var todayFormatted = formatTime(this.today, this.dateFormat);
      var yearFromNowFormatted = formatTime(this.oneYearFromNow, this.dateFormat);
      return this.translate.youMustSelectADateBetween.replace('%s1', todayFormatted).replace('%s2', yearFromNowFormatted);
    },
    oneYearFromNow: function oneYearFromNow() {
      return new Date().setFullYear(this.today.getFullYear() + 1);
    }
  }),
  methods: {
    onSelectDataProcessor: function onSelectDataProcessor(changed) {
      this.selectedOptionProcessor = changed;
    },
    toggleModal: function toggleModal() {
      this.$emit('dismiss');
    },
    sendInformation: function sendInformation() {
      this.setEmptyComponents();
      if (this.isValid()) {
        this.toggleModal();
        this.sendData();
      }
    },
    isValid: function isValid() {
      return this.isNotEmpty(this.selectedOptionProcessor) && this.isNotEmpty(this.nameWritten) && this.isNotEmpty(this.emailWritten) && this.isNotEmpty(this.deadlineValue) && isEmailValid(this.emailWritten);
    },
    getEmailErrorMessage: function getEmailErrorMessage() {
      if (!this.isNotEmpty(this.emailWritten)) {
        return this.descriptionOfTheError;
      } else if (!isEmailValid(this.emailWritten)) {
        return this.emailWritten + this.translate.isAnInvalidEmailAddress;
      } else {
        return '';
      }
    },
    isNotEmpty: function isNotEmpty(field) {
      return typeof field === 'number' || typeof field === 'string' && field.length > 0;
    },
    sendData: function sendData() {
      var data = {
        questionnaireId: this.selectedOptionProcessor,
        name: escapeHtml(this.nameWritten),
        company: escapeHtml(this.companyWritten),
        email: this.emailWritten,
        deadline: this.deadlineValue,
        message: escapeHtml(this.commentsWritten)
      };
      this.$emit('update', data);
    },
    setEmptyComponents: function setEmptyComponents() {
      this.questionnaireError = !this.isNotEmpty(this.selectedOptionProcessor);
      this.nameError = !this.isNotEmpty(this.nameWritten);
      this.companyError = !this.isNotEmpty(this.companyWritten);
      this.emailError = this.getEmailErrorMessage();
      this.deadlineError = !this.isNotEmpty(this.deadlineValue);
    },
    disabledDate: function disabledDate(date) {
      return date < this.today || date > this.oneYearFromNow;
    },
    getCurrentDatePlusOneMonth: function getCurrentDatePlusOneMonth() {
      return addTimeAndFormatDate(undefined, 1, 'month', DateFormats.DATE_FORMAT);
    }
  }
};