function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import i18n from '@/i18n';
export function getDateOptions() {
  var singularLabel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var pluralLabel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var $trans = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (text) {
    return text;
  };
  var options = [];
  for (var dayNo = 1; dayNo <= 7; dayNo++) {
    options.push({
      value: dayNo,
      label: "".concat(dayNo, " ").concat(dayNo > 1 ? $trans(pluralLabel) : $trans(singularLabel))
    });
  }
  for (var weekNo = 2; weekNo <= 12; weekNo++) {
    var _dayNo = weekNo * 7;
    options.push({
      value: _dayNo,
      label: "".concat(_dayNo, " ").concat($trans(pluralLabel))
    });
  }
  return options;
}
export function getContractDateOptions() {
  var ADDITIONAL_DAYS = [180, 365, 545, 730];
  var axillaryLabelFn = function axillaryLabelFn(days) {
    var monthDivider = 365 / 12;
    return "(".concat(Math.round(days / monthDivider), " ").concat(i18n.t('months'), ")");
  };
  var singularLabel = 'day before',
    pluralLabel = 'days before';
  return [].concat(_toConsumableArray(getDateOptions(singularLabel, pluralLabel, i18n.t)), _toConsumableArray(getCustomOptions(ADDITIONAL_DAYS, '', pluralLabel, axillaryLabelFn, i18n.t)));
}
function getCustomOptions() {
  var days = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var singularLabel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var pluralLabel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var axillaryLabelFn = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var $trans = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : function (text) {
    return text;
  };
  return days.map(function (day) {
    var axillaryLabel = axillaryLabelFn ? axillaryLabelFn(day) : '';
    return {
      value: day,
      label: "".concat(day, " ").concat(day > 1 ? $trans(pluralLabel) : $trans(singularLabel), " ").concat(axillaryLabel)
    };
  });
}