import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "px-5 pt-3 pb-6 border-2 border-gray-350 rounded-md"
};
var _hoisted_2 = {
  class: "flex justify-between mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_router_link = _resolveComponent("router-link");
  var _component_notification_pop_up = _resolveComponent("notification-pop-up");
  var _component_alert = _resolveComponent("alert");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_create_company = _resolveComponent("create-company");
  var _component_risma_table = _resolveComponent("risma-table");
  return _openBlock(), _createElementBlock("div", {
    key: "bankruptcy-".concat($props.isBankruptcy),
    class: "w-full"
  }, [$options.detailLoadedError ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.detailLoadedError), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    closeable: false,
    type: $data.notificationType
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.notificationMessage), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), _ctx.hasNotificationPopUp ? (_openBlock(), _createBlock(_component_notification_pop_up, {
    key: 2,
    onDismiss: _ctx.dismissNotificationPopUp
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.yourActivityHasBeenCreated), 1 /* TEXT */), _createVNode(_component_router_link, {
        to: "/company/".concat(_ctx.notificationData.id)
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.notificationData.name), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onDismiss"])) : _createCommentVNode("v-if", true), $data.showDeleteCompanyAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 3,
    body: "".concat($data.translate.deleteCompany, " ").concat($data.selectedCompanyTitle, "?"),
    header: $data.translate.areYouSure,
    "button-ok-text": $data.translate.delete,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.deleteCompany,
    onDismiss: $options.toggleDeleteCompanyAlert
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $options.tableHeader
  }, null, 8 /* PROPS */, ["title"]), !$props.isBankruptcy ? (_openBlock(), _createBlock(_component_create_company, {
    key: 0,
    onShowNotification: $options.showNotification,
    onCreateCompany: $options.createCompany
  }, null, 8 /* PROPS */, ["onShowNotification", "onCreateCompany"])) : _createCommentVNode("v-if", true)]), (_openBlock(), _createBlock(_component_risma_table, {
    key: $data.tableGeneratedKey,
    "print-title": $options.tableHeader,
    "state-key": $options.companiesStateKey,
    "show-search-field": true,
    api: $options.getCompaniesBE,
    onDelete: $options.handleDeleteCompany,
    onDismissModal: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('updateBankruptcyCount');
    })
  }, null, 8 /* PROPS */, ["print-title", "state-key", "api", "onDelete"]))])])]);
}