function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { generateComputed } from '@/utils/base/generateComputed';
import { isEmailValid as _isEmailValid, isPasswordValid as _isPasswordValid } from '@/utils/validations';
import { passwordErrorMessage as _passwordErrorMessage } from '@/utils/notifications';
import InputField from '@/components/Atoms/Inputs/InputField';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Alert from '@/components/Molecules/Modal/Alert';
import RismaOrgSelector from '@/components/Molecules/OrganisationSelector';
export default {
  name: 'UserDescription',
  introduction: '',
  description: '',
  token: '',
  components: {
    InputField: InputField,
    RismaOrgSelector: RismaOrgSelector,
    RismaButton: RismaButton,
    Alert: Alert,
    Checkboxes: Checkboxes
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'User obj'
    }
  },
  emits: ['propertyChanged'],
  data: function data() {
    return {
      showDeactivateAlert: false,
      translate: getTranslate['UserDescription']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useOrganisationsStore, ['organisations'])), generateComputed(['username', 'password', 'initials', 'email', 'phone'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    checkboxOptions: function checkboxOptions() {
      var value = 1;
      var disabled = [];
      if (!this.data.active) {
        disabled.push(value);
      }
      return {
        disabled: disabled,
        grc: [{
          checked: !!this.data.performance_report_access,
          value: value
        }],
        legalhub: [{
          checked: !!this.data.legal_hub_access,
          value: value
        }]
      };
    },
    selectedOrganisations: function selectedOrganisations() {
      var _this = this;
      if (!this.data.organisations) return [];
      var result = [];
      this.data.organisations.forEach(function (orgId) {
        var selectedOrg = _this.organisations.find(function (item) {
          return item.id === orgId;
        });
        if (selectedOrg) {
          result.push(selectedOrg);
        }
      });
      return result;
    },
    activeStatusText: function activeStatusText() {
      var result = '';
      if (this.data.active) {
        result += this.translate.areYouSureYouWantToDeactivate;
      } else {
        result += this.translate.areYouSureYouWantToActivate;
      }
      return "".concat(result, " ").concat(this.data.display_name, " ?");
    },
    isEmailValid: function isEmailValid() {
      return this.email && _isEmailValid(this.email);
    },
    isPasswordValid: function isPasswordValid() {
      return !this.password || _isPasswordValid(this.password);
    },
    passwordErrorMessage: function passwordErrorMessage() {
      return _passwordErrorMessage(this.getSettingValue('feature.enterprise_security'));
    },
    isPerformanceReportEnabled: function isPerformanceReportEnabled() {
      return this.getSettingValue('feature.performance_report_enabled');
    }
  }),
  methods: {
    onOrganisationsChange: function onOrganisationsChange(newOrganisationsList) {
      var ids = newOrganisationsList.map(function (org) {
        return org.id;
      });
      this.$emit('propertyChanged', {
        property: 'organisations',
        value: ids
      });
    },
    onCheckboxChange: function onCheckboxChange(property, _ref) {
      var isChecked = _ref.isChecked;
      var value = isChecked ? 1 : 0;
      this.$emit('propertyChanged', {
        property: property,
        value: value
      });
    },
    onActiveStatusChange: function onActiveStatusChange() {
      this.showDeactivateAlert = false;
      this.$emit('propertyChanged', {
        property: 'active',
        value: !this.data.active
      });
    },
    onDisableMfaClick: function onDisableMfaClick() {
      this.$emit('propertyChanged', {
        property: 'mfa_type',
        value: 0
      });
    }
  }
};