export var AdminNotifications = {
  CREATE_ORGANISATION_ERROR: 'An organisation with this name already exists',
  DELETE_ORGANISATION_ERROR: 'You cannot delete organisations with children',
  CREATE_ORG_GROUP_ERROR: 'An organisation group with this name already exists',
  CONFIRM_MESSAGE: 'Are you sure you want to delete this organisation',
  CONFIRM_DELETE_GROUP_MESSAGE: 'Are you sure you want to delete this group',
  DELETE_ORG_GROUP_ERROR: 'This organisational group in use',
  DELETE_ORGANISATION_IN_USE_ERROR: 'You cannot delete organisations that are in use in activities',
  SERVER_DELETE_ERROR: 'Cannot delete organisation with children',
  SERVER_DELETE_ORG_ERROR: 'Cannot delete organisation with remaining links'
};