function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import UserSettings from '@/components/Pages/UserSettings/UserSettings';
import UserSettingsProfile from '@/components/Pages/UserSettings/UserSettingsProfile';
import UserSettingsPassword from '@/components/Pages/UserSettings/UserSettingsPassword';
import UserSettingsNotifications from '@/components/Pages/UserSettings/UserSettingsNotifications';
import OrganisationTree from '@/components/Pages/Admin/OrganisationTree';
import Companies from '@/components/Pages/Admin/Companies';
import SecurityGroups from '@/components/Pages/Compliance/Admin/SecurityGroups';
import AdminControlPanel from '@/components/Pages/Admin/AdminControlPanel';
import AdminLoginsReport from '@/components/Pages/Admin/AdminLoginsReport';
import BulkUpdatesEntry from '@/components/Pages/Admin/BulkUpdatesEntry';
import AdminSwitchOrganisations from '@/components/Pages/Admin/AdminSwitchOrganisations';
import AdminSwitchSolutions from '@/components/Pages/Admin/AdminSwitchSolutions';
import AdminSwitchActivities from '@/components/Pages/Admin/AdminSwitchActivities';
import AdminUsersChangelog from '@/components/Pages/Admin/AdminUsersChangelog';
import AdminSettingsChangelog from '@/components/Pages/Admin/AdminSettingsChangelog';
import AdminAuditReportsEntry from '@/components/Pages/Admin/AdminAuditReportsEntry';
import AdminControls from '@/components/Pages/Admin/AdminControls';
import RiskAdminPeriods from '@/components/Organisms/RiskAdmin/RiskAdminPeriods';
import AdminRisksThreatsVulnerability from '@/components/Pages/Admin/AdminRisksThreatsVulnerability';
import RiskControlPanel from '@/components/Pages/Admin/RiskControlPanel';
import AdminRiskHeatMap from '@/components/Pages/Admin/AdminRiskHeatMap';
import AdminRiskScoring from '@/components/Pages/Admin/AdminRiskScoring';
import RismaAdminEntry from '@/components/Pages/Admin/RismaAdminEntry';
import UserRightsAdmin from '@/components/Pages/Admin/UserRightsAdmin';
import AdminActivityTypes from '@/components/Pages/Admin/AdminActivityTypes';
import ActionControlPanel from '@/components/Pages/Admin/ActionControlPanel';
import ControlControlPanel from '@/components/Pages/Admin/ControlControlPanel';
import ContractsControlPanel from '@/components/Pages/Admin/ContractsControlPanel';
import AppControlPanel from '@/components/Pages/Admin/AppControlPanel';
import IncidentsControlPanel from '@/components/Pages/Admin/IncidentsControlPanel';
import AdminTagsFieldWell from '@/components/Organisms/Admin/AdminTagsFieldWell';
import AdminNonWorkingDaysWell from '@/components/Organisms/Admin/AdminNonWorkingDaysWell';
import ProjectsAdmin from '@/components/Pages/Admin/ProjectsAdmin';
import ComplianceControlPanel from '@/components/Pages/Admin/ComplianceControlPanel';
import ComplianceAssetsSettings from '@/components/Pages/Admin/ComplianceAssetsSettings';
import ComplianceRiskAssessment from '@/components/Pages/Admin/ComplianceRiskAssessment';
import ProjectsNodeTemplates from '@/components/Pages/Admin/ProjectsNodeTemplates';
import QuestionnaireAdmin from '@/components/Pages/Admin/QuestionnaireAdmin';
import LiaTiaAdmin from '@/components/Pages/Admin/LiaTiaAdmin';
import GapSchemasAdmin from '@/components/Pages/Admin/GapSchemasAdmin';
import QuestionRules from '@/components/Pages/Admin/QuestionRules';
import RelevantQuestions from '@/components/Pages/Compliance/Admin/RelevantQuestions';
import WorkflowStatuses from '@/components/Pages/Admin/WorkflowStatuses';
import LookFeelPage from '@/components/Pages/UserSettings/LookFeelPage';
import DataImport from '@/components/Pages/Admin/DataImport';
import PreviewDataImport from '@/components/Pages/Admin/PreviewDataImport';
import ExcelDataImport from '@/components/Pages/Admin/ExcelDataImport';
import RiskAdminPeriodDescription from '@/components/Organisms/RiskAdmin/RiskAdminPeriodDescription';
import ErmPeriodDescription from '@/components/Pages/Admin/ErmPeriodDescription';
import UserRightsTable from '@/components/Organisms/UserRightsTable';
import UserRightsExpanded from '@/components/Organisms/UserRightsExpanded';
import AppInvitations from '@/components/Pages/UserSettings/AppInvitations';
import AppInvitationsEntry from '@/components/Pages/Admin/AppInvitationsEntry';
import AdminSuggestedActivities from '@/components/Pages/Admin/SuggestedActivities/AdminSuggestedActivities';
import AdminDocumentTypes from '@/components/Pages/Admin/AdminDocumentTypes';
import { isAdmin, userHasCompaniesAccess, userHasLevelControls, userHasLevelRisk, userHasLevelExecution, featureFlagAccess, userHasLevelContracts, userHasLevelIncident, userHasAnyComplianceProjectAccess, userHasControlPanelAccess, userHasRiskProjectAccess } from '@/routes/auth';
import { UserLevels } from '@/constants/UserLevels';
import { SETTINGS_ROUTE_NAMES } from '@/constants/routes/settings';
import rismaadmin from '@/routes/rismaadmin';
import RiskAdminProjects from '@/components/Organisms/RiskAdmin/RiskAdminProjects';
export default (function () {
  var routes = [{
    path: '/settings',
    component: UserSettings,
    meta: {
      keyFunction: function keyFunction() {
        return '/settings';
      }
    },
    props: true,
    children: [{
      path: 'user',
      component: UserSettingsProfile,
      name: 'UserSettingsProfile'
    }, {
      path: 'change-password',
      component: UserSettingsPassword
    }, {
      path: 'notifications',
      component: UserSettingsNotifications
    }, {
      path: 'administration',
      component: UserRightsAdmin,
      meta: {
        userRightsFunction: isAdmin
      },
      children: [{
        path: '',
        component: UserRightsTable,
        name: 'UserRightsTable'
      }, {
        path: 'users-view',
        component: UserRightsExpanded
      }]
    }, {
      path: 'controlpanel',
      component: AdminControlPanel,
      name: 'AdminControlPanel',
      meta: {
        userRightsFunction: function userRightsFunction() {
          return userHasControlPanelAccess(UserLevels.SUPER);
        }
      }
    }, {
      path: 'controlsadmin',
      component: AdminControls,
      name: 'AdminControls',
      meta: {
        userRightsFunction: userHasLevelControls(UserLevels.SUPER)
      }
    }, {
      path: 'threatsvulnerability',
      component: AdminRisksThreatsVulnerability,
      name: 'AdminRisksThreatsVulnerability',
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'ermadmin',
      component: AdminRiskScoring,
      name: SETTINGS_ROUTE_NAMES.erm_admin,
      props: function props(route) {
        return {
          errorScoring: route.params.errorScoring
        };
      },
      meta: {
        userRightsFunction: userHasLevelRisk(UserLevels.SUPER)
      }
    }, {
      path: 'ermadmin/scoring/:scoringId',
      component: ErmPeriodDescription,
      props: true,
      meta: {
        userRightsFunction: userHasLevelRisk(UserLevels.SUPER)
      }
    }, {
      path: 'riskadmin/periods/:projectId/:path',
      component: RiskAdminPeriodDescription,
      meta: {
        userRightsFunction: userHasRiskProjectAccess(UserLevels.SUPER)
      }
    }, {
      path: 'complianceprojects',
      component: ProjectsAdmin,
      name: 'ProjectsAdmin',
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'complianceriskassessment',
      component: ComplianceRiskAssessment,
      name: 'ComplianceRiskAssessment',
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'complianceassets',
      component: ComplianceAssetsSettings,
      name: 'ComplianceAssetsSettings',
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'compliancecp',
      component: ComplianceControlPanel,
      name: 'ComplianceControlPanel',
      meta: {
        userRightsFunction: function userRightsFunction() {
          return userHasAnyComplianceProjectAccess(UserLevels.SUPER);
        }
      }
    }, {
      path: 'templatenodes',
      component: ProjectsNodeTemplates,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'compliancequestionnaires',
      name: 'QuestionnaireAdmin',
      component: QuestionnaireAdmin,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'liatia',
      component: LiaTiaAdmin,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'gapschemas',
      component: GapSchemasAdmin,
      name: SETTINGS_ROUTE_NAMES.gapSchemas,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'rismarules',
      component: QuestionRules,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'relevantquestions',
      component: RelevantQuestions,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'workflowstatuses',
      component: WorkflowStatuses,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'importdata',
      component: DataImport,
      redirect: {
        name: 'ExcelDataImport'
      },
      meta: {
        userRightsFunction: isAdmin
      },
      children: [{
        path: 'data',
        component: ExcelDataImport,
        name: 'ExcelDataImport'
      }, {
        path: ':module',
        component: PreviewDataImport,
        props: function props(route) {
          return {
            routeModule: route.params.module || ''
          };
        }
      }]
    }, {
      path: 'organisations',
      component: OrganisationTree,
      name: 'OrganisationTree',
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'companies',
      component: Companies,
      name: 'Companies',
      meta: {
        userRightsFunction: function userRightsFunction() {
          return userHasCompaniesAccess() || isAdmin();
        }
      }
    }, {
      path: 'bankruptcy',
      component: Companies,
      name: 'Bankruptcy',
      meta: {
        userRightsFunction: function userRightsFunction() {
          return userHasCompaniesAccess() || isAdmin();
        }
      },
      props: {
        isBankruptcy: true
      }
    }, {
      path: 'adsettings',
      component: SecurityGroups,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'rismaadmin',
      component: RismaAdminEntry,
      meta: {
        userRightsFunction: isAdmin
      },
      children: _toConsumableArray(rismaadmin())
    }, {
      path: 'activitytypes',
      component: AdminActivityTypes,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'tags',
      component: AdminTagsFieldWell,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'nonworkingdays',
      component: AdminNonWorkingDaysWell,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'bulk',
      component: BulkUpdatesEntry,
      meta: {
        userRightsFunction: isAdmin
      },
      children: [{
        path: 'switch',
        component: AdminSwitchActivities,
        meta: {
          userRightsFunction: isAdmin
        }
      }, {
        path: 'solutionid',
        component: AdminSwitchSolutions,
        meta: {
          userRightsFunction: isAdmin && featureFlagAccess('feature.performance_report_enabled')
        }
      }, {
        path: 'organisation',
        component: AdminSwitchOrganisations,
        meta: {
          userRightsFunction: isAdmin
        }
      }]
    }, {
      path: 'reports',
      redirect: {
        name: 'AdminLoginsReport'
      },
      component: AdminAuditReportsEntry,
      meta: {
        userRightsFunction: isAdmin
      },
      children: [{
        path: 'login',
        name: 'AdminLoginsReport',
        component: AdminLoginsReport
      }, {
        path: 'users',
        component: AdminUsersChangelog
      }, {
        path: 'settings',
        component: AdminSettingsChangelog
      }]
    }, {
      path: 'customization',
      component: LookFeelPage,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'actioncp',
      component: ActionControlPanel,
      name: 'ActionControlPanel',
      meta: {
        userRightsFunction: userHasLevelExecution(UserLevels.SUPER)
      }
    }, {
      path: 'controlcp',
      component: ControlControlPanel,
      meta: {
        userRightsFunction: userHasLevelControls(UserLevels.SUPER)
      }
    }, {
      path: 'documenttypes',
      component: AdminDocumentTypes
    }, {
      path: 'riskprojects',
      component: RiskAdminProjects,
      name: 'RiskAdminProjects',
      meta: {
        userRightsFunction: userHasLevelRisk(UserLevels.SUPER)
      }
    }, {
      path: 'riskproject/:projectId',
      component: RiskAdminPeriods,
      props: true,
      meta: {
        userRightsFunction: userHasRiskProjectAccess(UserLevels.SUPER)
      }
    }, {
      path: 'riskcp',
      component: RiskControlPanel,
      meta: {
        userRightsFunction: userHasLevelRisk(UserLevels.SUPER)
      }
    }, {
      path: 'riskheatmap',
      component: AdminRiskHeatMap,
      meta: {
        userRightsFunction: userHasLevelRisk(UserLevels.SUPER)
      }
    }, {
      path: 'appinvitations',
      redirect: '/settings/appinvitations/incident',
      component: AppInvitationsEntry,
      props: true,
      meta: {
        userRightsFunction: featureFlagAccess('feature.app_settings_enabled')
      },
      name: 'AppInvitations',
      children: [{
        path: ':app',
        component: AppInvitations,
        props: true
      }]
    }, {
      path: 'appcontrolpanel',
      component: AppControlPanel,
      name: 'AppControlPanel',
      meta: {
        userRightsFunction: featureFlagAccess('feature.app_settings_enabled')
      }
    }, {
      path: 'contractscontrolpanel',
      component: ContractsControlPanel,
      name: 'ContractsControlPanel',
      meta: {
        userRightsFunction: userHasLevelContracts(UserLevels.SUPER)
      }
    }, {
      path: 'incidentscontrolpanel',
      component: IncidentsControlPanel,
      name: 'IncidentsControlPanel',
      meta: {
        userRightsFunction: userHasLevelIncident(UserLevels.SUPER)
      }
    }, {
      path: 'suggestedactivities',
      component: AdminSuggestedActivities,
      meta: {
        userRightsFunction: isAdmin
      }
    }]
  }];
  return routes;
});