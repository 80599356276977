import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", {
    class: "save-image",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.handleClick && $options.handleClick.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "download"
  })]);
}