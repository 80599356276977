import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_compact_title_data_component = _resolveComponent("compact-title-data-component");
  var _component_static_table = _resolveComponent("static-table");
  var _component_card_compliance = _resolveComponent("card-compliance");
  var _component_load_more_wrapper = _resolveComponent("load-more-wrapper");
  var _component_preview_list = _resolveComponent("preview-list");
  return _openBlock(), _createBlock(_component_preview_list, {
    length: $props.processes.length || '0',
    title: _ctx.$trans($props.title),
    class: "process",
    "cookie-hidden-name": "hidden_cards_wrapper_compliance_my_processes",
    onChangeShowState: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changeShowState', $event);
    })
  }, {
    default: _withCtx(function () {
      return [$props.viewMode === $data.viewTypes.COMPACT ? (_openBlock(), _createBlock(_component_static_table, {
        key: 0,
        "visibility-switch": true,
        dataset: $options.dataset,
        columns: $options.columns
      }, {
        title: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_createVNode(_component_compact_title_data_component, {
            "slot-data": slotData
          }, null, 8 /* PROPS */, ["slot-data"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset", "columns"])) : (_openBlock(), _createBlock(_component_load_more_wrapper, {
        key: 1,
        activities: $props.processes
      }, {
        default: _withCtx(function (_ref2) {
          var activitiesLimited = _ref2.activitiesLimited;
          return [_createElementVNode("div", {
            class: _normalizeClass("processes-".concat($props.viewMode, "-list"))
          }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activitiesLimited, function (process) {
            return _openBlock(), _createElementBlock("div", {
              key: "preview-process".concat(process.id),
              class: _normalizeClass("processes-".concat($props.viewMode, "-item"))
            }, [_createVNode(_component_card_compliance, {
              activity: process,
              "is-router-use": true
            }, null, 8 /* PROPS */, ["activity"])], 2 /* CLASS */);
          }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["activities"]))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["length", "title"]);
}