function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Bar as BarChart } from 'vue-chartjs';
import { RismaVueChartMixin } from './RismaVueChartMixin';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import ExportFrame from '@/components/Atoms/Containers/ExportFrame';
import { splitLabelToRows } from '@/components/Molecules/Charts/utils';
import { generateKey } from '@/utils/Utils';
var chartTypes = {
  HORIZONTAL: 'Horizontal',
  STACKED: 'Stacked'
};
export default {
  name: 'RismaBarChart',
  introduction: 'Bar chart component based on ChartJS lib',
  description: "Includes next chart types: ".concat(Object.values(chartTypes).join(', ')),
  token: "<risma-bar-chart \n        :labels=\"['label1', 'label2']\n        :show-legend=\"true\"\n        :show-data-labels=\"true\"\n        :datasets=\"datasets: [{\n            backgroundColor: ['#43abb44', '#65ff23'],\n            data: [10, 15],\n            label: 'dataset label'\n        }],\"/>",
  components: {
    BarChart: BarChart,
    ExportFrame: ExportFrame,
    FeatherIcon: FeatherIcon
  },
  mixins: [RismaVueChartMixin],
  props: {
    height: {
      type: Number,
      required: false,
      default: 400
    },
    type: {
      type: String,
      required: false,
      default: 'Horizontal',
      validator: function validator(type) {
        return Object.values(chartTypes).includes(type);
      }
    },
    minBarLength: {
      required: false,
      type: Number,
      default: 0,
      note: 'Determine the minimum bar length that should be applied'
    },
    yOffset: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Determine if there y offest should be applied'
    },
    showDataLabels: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Determine if there data labels should be displayed or not'
    },
    showZeroValues: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Determine if zero values should be displayed or not'
    },
    itemsPerScroll: {
      required: false,
      type: Number,
      default: 0,
      note: 'Determine if there scroll should be applied'
    },
    yStepSize: {
      required: false,
      type: Number,
      default: null,
      note: 'To control the step size of the Y-axis scale'
    }
  },
  data: function data() {
    return {
      showAll: false,
      currentSectionIndex: 0,
      translate: {
        showLess: this.$trans('Show fewer'),
        showMore: this.$trans('Show more')
      },
      chartKey: generateKey()
    };
  },
  computed: {
    pluginsInUse: function pluginsInUse() {
      var plugins = [];
      if (this.showDataLabels) {
        plugins.push(ChartDataLabels);
      }
      return plugins;
    },
    chartData: function chartData() {
      var _this = this;
      var result = {
        datasets: [],
        labels: this.labels || []
      };
      if (!this.datasets.length) return result;
      result.datasets = this.datasets.map(function (dataset, index) {
        return _objectSpread(_objectSpread({}, dataset), {}, {
          data: dataset.data.map(function (value) {
            if (!_this.showZeroValues && +value === 0) return null;
            return value;
          }),
          minBarLength: _this.minBarLength * (index + 1)
        });
      });
      result.datasets[0].datalabels = {
        //necessary to add offset for first bar to avoid overlapping
        align: 'start'
      };
      return result;
    },
    dataToShow: function dataToShow() {
      var _this2 = this;
      if (!this.itemsPerScroll || this.showAll) return this.chartData;
      var startIndex = this.currentSectionIndex * this.itemsPerScroll;
      var slicedData = {
        labels: this.labels.slice(startIndex, startIndex + this.itemsPerScroll),
        datasets: []
      };
      this.chartData.datasets.forEach(function (dataset) {
        slicedData.datasets.push(_objectSpread(_objectSpread({}, dataset), {}, {
          data: dataset.data.slice(startIndex, startIndex + _this2.itemsPerScroll)
        }));
      });
      return slicedData;
    },
    dataLength: function dataLength() {
      var _this$datasets;
      return ((_this$datasets = this.datasets) === null || _this$datasets === void 0 || (_this$datasets = _this$datasets[0]) === null || _this$datasets === void 0 ? void 0 : _this$datasets.data.length) || 0;
    },
    totalSectionsNumber: function totalSectionsNumber() {
      return Math.ceil(this.dataLength / this.itemsPerScroll);
    },
    scrollStyles: function scrollStyles() {
      if (!this.itemsPerScroll) return '';
      if (this.showAll) return {
        width: '100%'
      };
      return {
        width: "".concat(100 / this.totalSectionsNumber, "%"),
        left: "".concat(this.currentSectionIndex * 100 / this.totalSectionsNumber, "%")
      };
    },
    zoomTitle: function zoomTitle() {
      return this.showAll ? this.translate.showLess : this.translate.showMore;
    },
    barChartOptions: function barChartOptions() {
      var _this3 = this;
      var optionsByType = {
        devicePixelRatio: 1
      };
      switch (this.type) {
        case chartTypes.HORIZONTAL:
          {
            optionsByType.indexAxis = 'y';
            break;
          }
        case chartTypes.STACKED:
          {
            optionsByType.scales = {
              x: {
                stacked: true,
                ticks: {
                  font: {
                    size: 16
                  },
                  autoSkip: false,
                  callback: function callback(labelIndex) {
                    var charsToWrap = 15;
                    var charsToShow = 70;
                    var labelsForCurrentSection = _this3.labels;
                    if (_this3.currentSectionIndex && _this3.itemsPerScroll && !_this3.showAll) {
                      labelsForCurrentSection = labelsForCurrentSection.slice(_this3.currentSectionIndex * _this3.itemsPerScroll);
                    }
                    var lab = labelsForCurrentSection[labelIndex];
                    return splitLabelToRows(lab, charsToShow, charsToWrap);
                  }
                }
              },
              y: {
                stacked: true,
                offset: this.yOffset,
                ticks: {
                  stepSize: this.yStepSize
                }
              }
            };
            break;
          }
        default:
          break;
      }
      return _objectSpread(_objectSpread({}, this.chartOptions), optionsByType);
    }
  },
  watch: {
    height: function height() {
      this.chartKey = generateKey();
    }
  },
  methods: {
    showPrevSection: function showPrevSection() {
      if (this.currentSectionIndex <= 0 || this.showAll) return;
      this.currentSectionIndex -= 1;
    },
    showNextSection: function showNextSection() {
      if (this.currentSectionIndex >= this.totalSectionsNumber - 1 || this.showAll) return;
      this.currentSectionIndex += 1;
    }
  }
};