import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_attachment_modal = _resolveComponent("attachment-modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_button, {
    type: 'util',
    text: 'Toggle Modal',
    onClick: $options.toggleAttachmentModal
  }, null, 8 /* PROPS */, ["onClick"]), $data.showModal ? (_openBlock(), _createBlock(_component_attachment_modal, {
    key: 0,
    onUpload: $options.showUpload,
    onDismiss: $options.toggleAttachmentModal
  }, null, 8 /* PROPS */, ["onUpload", "onDismiss"])) : _createCommentVNode("v-if", true)]);
}