function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import cloneDeep from 'lodash/cloneDeep';
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import { updateUser as _updateUser, changeUserActiveStatus } from '@/api/admin/user';
import { setUserProjectLevel } from '@/api/risma/data';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import Notification from '@/components/Molecules/Notification';
import { TabTypes } from '@/constants/Tabs';
import { createTabsList } from '@/utils/base/tabs';
import { saveStatus } from '@/constants/SaveStatuses';
import { MODULES } from '@/constants/modules';
import { isEmailValid, isPasswordValid } from '@/utils/validations';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'UserEntry',
  components: {
    DepWell: DepWell,
    Notification: Notification
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    id: {
      type: [String, Number],
      require: true,
      default: null,
      note: 'user id'
    }
  },
  emits: ['changeTab', 'updated'],
  data: function data() {
    return {
      isLoaded: false,
      user: null,
      userStatusChanged: false,
      userChanges: false,
      projectChanges: {},
      saveStatus: saveStatus.SAVED,
      updateTimer: 0,
      notificationMessage: '',
      notificationType: '',
      dirtyUser: {},
      translate: getTranslate['UserEntry']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    tabs: function tabs() {
      var baseUrl = "/user/".concat(this.id);
      return createTabsList([TabTypes.DESCRIPTION, TabTypes.RIGHTS], baseUrl);
    },
    fullUser: function fullUser() {
      return _objectSpread(_objectSpread({}, this.user), this.dirtyUser);
    },
    depHeaderProps: function depHeaderProps() {
      if (!this.fullUser) return {};
      return {
        id: this.fullUser.id,
        title: this.fullUser.fullname,
        editableTitle: true,
        tabs: this.tabs,
        isAttachmentEnabled: false,
        saveStatus: this.saveStatus,
        titleField: 'fullname',
        module: MODULES.USERS
      };
    }
  }),
  beforeMount: function beforeMount() {
    this.user = this.getUserById(this.id);
    if (!this.user) {
      this.handleShowNotification('error', this.translate.userDoesntExist);
    }
    this.isLoaded = true;
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useUserStore, {
    setCurrentUser: 'set'
  })), mapActions(useUsersStore, {
    updateUserInStore: 'update',
    getUserById: 'getUserById'
  })), {}, {
    setUpdateTimer: function setUpdateTimer() {
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(this.saveUpdates, this.$debounceTimeout);
    },
    userProjectChanged: function userProjectChanged(updatedProject) {
      var property = updatedProject.project_id ? "compliance_".concat(updatedProject.project_id) : "risk_".concat(updatedProject.r_project_id);
      this.projectChanges[property] = updatedProject;
      this.setUpdateTimer();
    },
    userPropertiesChanged: function userPropertiesChanged(properties) {
      if (Object.keys(this.dirtyUser).length === 0) {
        this.dirtyUser = cloneDeep(this.user);
      }
      this.dirtyUser = _objectSpread(_objectSpread({}, this.dirtyUser), properties);
      this.userChanges = true;
      this.setUpdateTimer();
    },
    userPropertyChanged: function userPropertyChanged(_ref) {
      var property = _ref.property,
        value = _ref.value;
      if (property === 'active') {
        this.userStatusChanged = true;
      } else {
        this.userChanges = true;
      }
      if (Object.keys(this.dirtyUser).length === 0) {
        this.dirtyUser = cloneDeep(this.user);
      }
      this.dirtyUser[property] = value;
      this.setUpdateTimer();
    },
    saveUpdates: function saveUpdates() {
      var _this = this;
      this.saveStatus = saveStatus.SAVING;
      if (!this.isValid()) {
        this.saveStatus = saveStatus.NOT_SAVED;
        return;
      }
      var promises = [this.updateUser(), this.updateUserStatus(), this.updateProjects()];
      return Promise.all(promises).then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 3),
          user = _ref3[0],
          userStatus = _ref3[1],
          projects = _ref3[2];
        if (projects.length) {
          projects.forEach(_this.updateUserRightsByProject);
        }
        if (user) {
          _this.user = user;
        }
        if (userStatus) {
          _this.user.active = userStatus.active;
        }
        _this.handleDismissNotification();
        _this.$emit('updated', _this.fullUser);
      }).catch(function (error) {
        var _error$response;
        var errorMessage = (error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.error) || _this.translate.anErrorOccurred;
        _this.handleShowNotification('error', errorMessage);
      }).finally(function () {
        _this.dirtyUser = {};
        _this.saveStatus = saveStatus.SAVED;
      });
    },
    updateUser: function updateUser() {
      var _this2 = this;
      if (!this.userChanges) return Promise.resolve(null);
      return _updateUser(this.fullUser, this.fullUser.id).then(function (responce) {
        _this2.userChanges = false;
        return responce;
      });
    },
    updateUserStatus: function updateUserStatus() {
      var _this3 = this;
      if (!this.userStatusChanged) return Promise.resolve(null);
      return changeUserActiveStatus(this.fullUser.id, !this.fullUser.active ? {} : {
        undelete: 1
      }, this.fullUser).then(function (responce) {
        _this3.userStatusChanged = false;
        return responce;
      });
    },
    updateProjects: function updateProjects() {
      var _this4 = this;
      var promises = [];
      Object.values(this.projectChanges).forEach(function (params) {
        promises.push(setUserProjectLevel(params));
      });
      return Promise.all(promises).then(function () {
        var responce = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        _this4.projectChanges = [];
        return responce;
      });
    },
    updateUserRightsByProject: function updateUserRightsByProject(project) {
      var isRiskProject = !!project.riskProjectId;
      var idx = this.fullUser.projects.findIndex(function (item) {
        return (project.riskProjectId || project.projectId) === item.id && item.objType === (isRiskProject ? ObjectTypes.RISK_PROJECT : ObjectTypes.COMPLIANCE_PROJECT);
      });
      if (idx === -1) return;
      this.fullUser.projects[idx] = _objectSpread(_objectSpread({}, this.fullUser.projects[idx]), {}, {
        access_level: project.accessLevel,
        locked: project.locked,
        supervisor: project.supervisor
      });
      this.updateUserInStore(this.fullUser);
    },
    handleDismissNotification: function handleDismissNotification() {
      this.notificationMessage = '';
    },
    handleShowNotification: function handleShowNotification(type, message) {
      this.notificationType = type;
      this.notificationMessage = this.$trans(message);
    },
    saveStatusClicked: function saveStatusClicked() {
      if (this.saveStatus !== saveStatus.SAVED) {
        clearTimeout(this.updateTimer);
        this.saveUpdates();
      }
    },
    isValid: function isValid() {
      return this.fullUser && isEmailValid(this.fullUser.email) && (!this.fullUser.password || isPasswordValid(this.fullUser.password)) && this.fullUser.username && this.fullUser.initials;
    }
  })
};