import i18n from '@/i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
export var loginGuard = function loginGuard(to, from, next) {
  if (skipLoginCheck(to)) {
    return next();
  }
  var userStore = useUserStore();
  var loadLoginInfo = userStore.loadLoginInfo,
    loadCurrentUser = userStore.load;
  var _storeToRefs = storeToRefs(userStore),
    currentUser = _storeToRefs.currentUser;
  loadLoginInfo().then(function (loginInfo) {
    if (!loginInfo.userIsLoggedIn) {
      return next('/?returnUrl=' + to.fullPath + '&reason=loginGuard');
    }
    if (+currentUser.id !== +loginInfo.userId) {
      loadCurrentUser().then(function () {
        next();
      });
    } else {
      next();
    }
  });
};
export var userRightsGuard = function userRightsGuard(to, from, next) {
  var passed = to.matched.filter(function (record) {
    return typeof record.meta.userRightsFunction === 'function';
  }).every(function (matchedRecord) {
    return matchedRecord.meta.userRightsFunction(to, from, next);
  });
  if (!passed) {
    not();
    next(from.fullPath);
  } else {
    next();
  }
};
var not = function not() {
  return window.alert(i18n.t('Sorry, you don\'t have access to this page'));
};
export var userHasIAAccess = function userHasIAAccess() {
  return useUserStore().currentUser.iaAccess > 0;
};
export var userHasPoliciesAccess = function userHasPoliciesAccess() {
  return useUserStore().currentUser.policiesAccess > 0;
};
export var userHasLevelControls = function userHasLevelControls(level) {
  return function () {
    return useUserStore().userHasAccessLevel('level_controls', level);
  };
};
export var userHasLevelExecution = function userHasLevelExecution(level) {
  return function () {
    return useUserStore().userHasAccessLevel('level_execution', level);
  };
};
export var userHasLevelIncident = function userHasLevelIncident(level) {
  return function () {
    return useUserStore().userHasAccessLevel('level_incidents', level);
  };
};
export var userHasLevelRisk = function userHasLevelRisk(level) {
  return function () {
    return useUserStore().userHasAccessLevel('level_risk', level);
  };
};
export var userHasLevelContracts = function userHasLevelContracts(level) {
  return function () {
    return useUserStore().userHasAccessLevel('level_contracts', level);
  };
};
export var userHasComplianceProjectAccess = function userHasComplianceProjectAccess(level) {
  return function (to) {
    return useUserStore().complianceAccessLevelByProjectId(to.params.projectId) >= level;
  };
};
export var userHasRiskProjectAccess = function userHasRiskProjectAccess(level) {
  return function (to) {
    return useUserStore().riskAccessLevelByProjectId(to.params.projectId) >= level;
  };
};
export var userHasAnyComplianceProjectAccess = function userHasAnyComplianceProjectAccess(level) {
  return function () {
    return useUserStore().complianceProjects.find(function (project) {
      return project.access_level >= level;
    });
  };
};
export var isAdmin = function isAdmin() {
  return useUserStore().isAdmin;
};
export var userHasCompaniesAccess = function userHasCompaniesAccess() {
  return useUserStore().currentUser.level_company;
};
export var featureFlagAccess = function featureFlagAccess(settingName) {
  return function () {
    return useSettingsStore().settings[settingName];
  };
};
export var userHasControlPanelAccess = function userHasControlPanelAccess(level) {
  return userHasLevelExecution(level)() || userHasLevelControls(level)() || userHasLevelRisk(level)() || userHasLevelIncident(level)() || userHasAnyComplianceProjectAccess(level)();
};
export var userHasPerformanceReportAccess = function userHasPerformanceReportAccess() {
  return useUserStore().currentUser.performance_report_access && featureFlagAccess('feature.performance_report_enabled')();
};
function skipLoginCheck(to) {
  return to.matched.some(function (record) {
    return record.meta.skipLoginCheck;
  });
}