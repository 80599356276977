import { getTranslate } from './translate';
import * as Cookie from 'tiny-cookie';
import GanttChartJs from '@/components/Molecules/Charts/GanttChartJs';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { Colors } from '@/Colors';
export default {
  name: 'GanttChartCommon',
  description: 'This component takes a list of Objects and displays them as a gantt chart, there is also a sorting',
  components: {
    GanttChartJs: GanttChartJs,
    PreviewModalLink: PreviewModalLink,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect
  },
  props: {
    activities: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    },
    isSortingVisible: {
      required: false,
      type: Boolean,
      default: true,
      note: 'Display sorting or not'
    },
    sortCookie: {
      required: false,
      type: String,
      default: '',
      note: 'A string with that name of the cookie which the chart should save and load its sort state from'
    }
  },
  emits: ['dismissModal'],
  data: function data() {
    return {
      selectedIndex: null,
      sortKey: '',
      translate: getTranslate['GanttChartCommon']()
    };
  },
  computed: {
    startDate: function startDate() {
      var startDate = '';
      this.activities.forEach(function (data) {
        startDate === '' ? startDate = data.start : null;
        startDate > data.start ? startDate = data.start : null;
      });
      return startDate;
    },
    endDate: function endDate() {
      var endDate = '';
      this.activities.forEach(function (data) {
        endDate === '' ? endDate = data.deadline : null;
        endDate < data.deadline ? endDate = data.deadline : null;
      });
      return endDate;
    },
    timeUnit: function timeUnit() {
      var yearStart = this.startDate.split('-')[0];
      var yearEnd = this.endDate.split('-')[0];
      return yearEnd - yearStart > 3 ? 'year' : 'month';
    },
    sortKeys: function sortKeys() {
      return [{
        value: 'path',
        label: this.translate.number
      }, {
        value: 'deadline',
        label: this.translate.deadline
      }, {
        value: 'title',
        label: this.translate.title
      }];
    },
    selectedActivity: function selectedActivity() {
      return this.activitiesSorted[this.selectedIndex] || {};
    },
    chartData: function chartData() {
      var _this = this;
      var labels = [];
      var datasets = {
        data: [],
        backgroundColor: [],
        barThickness: 25
      };
      this.activitiesSorted.forEach(function (item) {
        labels.push("".concat(item.path, " ").concat(item.title));
        datasets.data.push([new Date(item.start), new Date(item.deadline)]);
        datasets.backgroundColor.push(_this.getBarColor(item));
      });
      return {
        labels: labels,
        data: [datasets]
      };
    },
    activitiesSorted: function activitiesSorted() {
      if (this.isSortingVisible) {
        return this.sortDataBySortKey(this.activities);
      }
      return this.activities;
    },
    ganttChartHeight: function ganttChartHeight() {
      var _this$chartData$label;
      if ((_this$chartData$label = this.chartData.labels) !== null && _this$chartData$label !== void 0 && _this$chartData$label.length) {
        var _this$chartData$label2;
        return ((_this$chartData$label2 = this.chartData.labels) === null || _this$chartData$label2 === void 0 ? void 0 : _this$chartData$label2.length) * 40 + 50;
      }
      return 400;
    }
  },
  watch: {
    sortKey: function sortKey(value) {
      if (this.sortCookie !== '') {
        Cookie.set(this.sortCookie, value, {
          samesite: 'strict'
        });
      }
    }
  },
  mounted: function mounted() {
    this.setSortKeyDefault();
  },
  methods: {
    setSortKeyDefault: function setSortKeyDefault() {
      var sortKeyId = null;
      if (this.isSortingVisible) {
        if (this.sortCookie !== '') {
          sortKeyId = Cookie.get(this.sortCookie);
        }
        var sortValue = this.sortKeys.find(function (item) {
          return item.value === sortKeyId;
        });
        if (sortValue) {
          this.sortKey = sortValue.value;
        } else {
          this.sortKey = this.sortKeys[0].value;
        }
      }
    },
    getBarColor: function getBarColor(item) {
      if (item.closed) return Colors.blueLight;
      return item.trafficLight !== undefined ? Colors.trafficLights[item.trafficLight].color : Colors.noTrafficLight;
    },
    showPreviewModal: function showPreviewModal(index) {
      this.selectedIndex = index;
      this.$refs.preview.showModal();
    },
    sortDataBySortKey: function sortDataBySortKey(data) {
      var _this2 = this;
      return data.slice(0).sort(function (a, b) {
        return a[_this2.sortKey] < b[_this2.sortKey] ? -1 : a[_this2.sortKey] > b[_this2.sortKey] ? 1 : 0;
      });
    }
  }
};