import i18n from '@/i18n';
export var getTranslate = {
  CompleteControl: function CompleteControl() {
    return {
      descriptionFromMotherControl: i18n.t('Description from Mother Control'),
      expectedEvidenceFromMotherControl: i18n.t('Expected evidence from Mother Control'),
      description: i18n.t('Description'),
      expectedEvidence: i18n.t('Expected evidence'),
      previousCompletions: i18n.t('Previous completions'),
      showHistory: i18n.t('Show history'),
      date: i18n.t('Date'),
      action: i18n.t('Action'),
      user: i18n.t('User'),
      comments: i18n.t('Comments'),
      newDeadline: i18n.t('New deadline'),
      urlattachments: i18n.t('URL & attachments'),
      reviewHistory: i18n.t('Review history'),
      addAttachment: i18n.t('Add attachment'),
      addComment: i18n.t('Add comment'),
      startReview: i18n.t('Start review'),
      completingThisControlWillStartTheReviewWork: i18n.t('Completing this control will start the review workflow and give notice to these reviewers'),
      saveAndClose: i18n.t('Save and close'),
      fileUploadInProgress: i18n.t('File upload in progress'),
      submit: i18n.t('Submit'),
      completedWithActions: i18n.t('Completed with actions'),
      skip: i18n.t('Skip'),
      addAFileCommentOrLinkInclLinkTitleTo: i18n.t('Add a file, comment or link (incl. link title) to complete control'),
      pleaseAddACommentToPerformACompletionWith: i18n.t('Please add a comment to perform a completion with action'),
      pleaseFillLinkToFieldsCorrectly: i18n.t('Please fill Link to fields correctly'),
      pleaseChooseTheFilesWithCorrectFiletypes: i18n.t('Please choose the files with correct filetypes'),
      completeControl: i18n.t('Complete control'),
      theFileWasNotUploadedPleaseTryAgain: i18n.t('The file was not uploaded. Please try again.'),
      filesUploadingCompleted: i18n.t('Files uploading completed'),
      controlEfficiencyAssessment: i18n.t('Control efficiency assessment'),
      currentControlEfficiency: i18n.t('Current control efficiency'),
      previousControlEfficiencyAssessments: i18n.t('Previous control efficiency assessments'),
      assessment: i18n.t('Assessment'),
      comment: i18n.t('Comment')
    };
  },
  ControlActions: function ControlActions() {
    return {
      initiatives: i18n.t('Initiatives'),
      selectInitiatives: i18n.t('Select initiatives'),
      controls: i18n.t('Controls'),
      selectControls: i18n.t('Select controls'),
      risks: i18n.t('Risks'),
      selectRisks: i18n.t('Select risks')
    };
  },
  ControlCreationModal: function ControlCreationModal() {
    return {
      createControl: i18n.t('Create control'),
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      pleaseCorrectTheErrorsAndTryAgain: i18n.t('Please correct the errors and try again.'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      motherControl: i18n.t('Mother Control'),
      responsible: i18n.t('Responsible'),
      organisations: i18n.t('Organisations'),
      activityType: i18n.t('Activity type'),
      createDaughterControl: i18n.t('Create daughter control'),
      createMotherControl: i18n.t('Create Mother Control'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      responsibleIsRequired: i18n.t('Responsible is required'),
      atLeastOneOrganisationIsRequired: i18n.t('At least one organisation is required')
    };
  },
  ControlFrequency: function ControlFrequency() {
    return {
      warning: i18n.t('Warning'),
      change: i18n.t('Change'),
      cancel: i18n.t('Cancel'),
      addCustomDeadline: i18n.t('Add custom deadline'),
      frequency: i18n.t('Frequency'),
      trafficLightTurnsYellow: i18n.t('Traffic light turns yellow'),
      choosePeriod: i18n.t('Choose period'),
      frequencyPreview: i18n.t('Frequency preview'),
      enableDeadlineCalculation: i18n.t('Enable deadline calculation'),
      deadlineIs: i18n.t('Deadline is'),
      workingDay: i18n.t('working day'),
      fromTheBeginningOfTheMonth: i18n.t('From the beginning of the month'),
      beforeTheEndOfTheMonth: i18n.t('Before the end of the month'),
      youAreInTheProcessOfDeletingDeadlineDate: i18n.t('You are in the process of deleting the last deadline date. This will mean that the control will change frequency from "Custom dates" to One-time Control.'),
      toContinuePressOk: i18n.t('To continue press OK.'),
      numberOfDaysBetweendeadlineAndReviewDeadline: i18n.t('Number of days between deadline and review deadline')
    };
  },
  ControlMainGaps: function ControlMainGaps() {
    return {
      nameOfBusinessProcess: i18n.t('Name of business process'),
      analysis: i18n.t('Analysis'),
      riskAssessment: i18n.t('Risk assessment'),
      riskAssessmentBefore: i18n.t('Risk assessment before'),
      riskAssessmentAfter: i18n.t('Risk assessment after')
    };
  },
  ControlType: function ControlType() {
    return {
      controlType: i18n.t('Control type'),
      keyControl: i18n.t('Key control'),
      automatic: i18n.t('Automatic'),
      manual: i18n.t('Manual'),
      preventive: i18n.t('Preventive'),
      detective: i18n.t('Detective'),
      highComplexity: i18n.t('High complexity'),
      lowComplexity: i18n.t('Low complexity')
    };
  },
  ControlAddDeadlineModal: function ControlAddDeadlineModal() {
    return {
      addDeadline: i18n.t('Add deadline'),
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      controlDeadline: i18n.t('Control deadline'),
      addDate: i18n.t('Add date'),
      customDeadlines: i18n.t('Custom deadlines'),
      youCantSelectDateFromThePast: i18n.t('You can\'t select date from the past'),
      youCantAddDatesWhichAlreadyExist: i18n.t('You can\'t add dates which already exist')
    };
  },
  CompleteModal: function CompleteModal() {
    return {
      closeCompletionReviewWindow: i18n.t('Close completion/review window'),
      areYouSureYouWishToCloseTheWindowAllEnteredDataWillBeDeleted: i18n.t('Are you sure you wish to close the window. All entered data will be deleted.')
    };
  },
  ControlReviewModal: function ControlReviewModal() {
    return {
      closeCompletionReviewWindow: i18n.t('Close completion/review window'),
      areYouSureYouWishToCloseTheWindowAllEnteredDataWillBeDeleted: i18n.t('Are you sure you wish to close the window. All entered data will be deleted.')
    };
  }
};