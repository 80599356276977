import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Tabs from '@/components/Molecules/Tabs/Tabs';
import TabItem from '@/components/Atoms/TabItem/TabItem';
import { Colors } from '@/Colors';
import { camelToKebab } from '@/utils';
export default {
  name: 'DevGeneric',
  components: {
    MainWell: MainWell,
    FeatherIcon: FeatherIcon,
    Tabs: Tabs,
    TabItem: TabItem
  },
  props: {
    listComponents: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      },
      note: 'Array of components (names)'
    },
    baseUrl: {
      type: String,
      required: true
    },
    tabs: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      colors: Colors,
      timer: null,
      timeout: 100
    };
  },
  mounted: function mounted() {
    document.querySelector('.navigation-kitchen').focus();
  },
  methods: {
    getUrl: function getUrl(url) {
      var result = camelToKebab(url);
      if (result[0] === '-') {
        result = result.substring(1);
      }
      return "".concat(this.baseUrl).concat(result);
    },
    goBack: function goBack() {
      var _this = this;
      var index = this.getRouteIndex(this.$route.name.split('Kitchen')[0]);
      if (index > 0) {
        index -= 1;
      } else {
        index = this.listComponents.length - 1;
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        _this.goToRoute(index);
      }, this.timeout);
    },
    goForward: function goForward() {
      var _this2 = this;
      var index = this.getRouteIndex(this.$route.name.split('Kitchen')[0]);
      if (index < this.listComponents.length - 1) {
        index += 1;
      } else {
        index = 0;
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        _this2.goToRoute(index);
      }, this.timeout);
    },
    getRouteIndex: function getRouteIndex(name) {
      return this.listComponents.indexOf(name);
    },
    goToRoute: function goToRoute(index) {
      var routeName = this.listComponents[index];
      var url = this.getUrl(routeName);
      this.$router.push({
        path: url
      });
    }
  }
};