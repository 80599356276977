import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-f99d8cf4"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "border-gray-140 border-b-2"
};
var _hoisted_2 = {
  key: 0,
  class: "mr-8 pt-2"
};
var _hoisted_3 = {
  key: 0,
  class: "mt-3 flex flex-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$data$selectedData, _$data$selectedData2;
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_tag_item = _resolveComponent("tag-item");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [$props.openedState ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_input_field, {
    modelValue: $data.filterDataSelected[$data.dataFields.TITLE],
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.filterDataSelected[$data.dataFields.TITLE] = $event;
    }),
    title: $data.translate.title,
    placeholder: $data.translate.titlePressEnterToSearch,
    type: "text",
    onOnEnter: _cache[1] || (_cache[1] = function ($event) {
      return $options.updateFiltersData($data.filterDataSelected);
    })
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createVNode(_component_risma_title, {
    type: "medium",
    class: "mt-2",
    title: $data.translate.organisations
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: _ctx.organisations,
    "selected-options": $data.filterDataSelected[$data.dataFields.ORGANISATIONS],
    placeholder: $data.translate.noFiltersApplied,
    "label-key": "visible_name",
    onSelected: _cache[2] || (_cache[2] = function ($event) {
      return $options.setSelected($event, $data.dataFields.ORGANISATIONS);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder"]), _createVNode(_component_risma_title, {
    type: "medium",
    class: "mt-2",
    title: _ctx.responsibleTitle(true)
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $options.responsible,
    "selected-options": $data.filterDataSelected[$data.dataFields.RESPONSIBLE],
    placeholder: $data.translate.noFiltersApplied,
    "label-key": "display_name",
    onSelected: _cache[3] || (_cache[3] = function ($event) {
      return $options.setSelected($event, $data.dataFields.RESPONSIBLE);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder"]), _createVNode(_component_risma_title, {
    type: "medium",
    class: "mt-2",
    title: $data.translate.tags
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    placeholder: $data.translate.noFiltersApplied,
    options: _ctx.tags,
    "selected-options": $data.filterDataSelected[$data.dataFields.TAGS],
    "label-key": "tag",
    onSelected: _cache[4] || (_cache[4] = function ($event) {
      return $options.setSelected($event, $data.dataFields.TAGS);
    })
  }, null, 8 /* PROPS */, ["placeholder", "options", "selected-options"]), _createVNode(_component_risma_title, {
    type: "medium",
    class: "mt-2",
    title: $data.translate.activityTypes
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    placeholder: $data.translate.noFiltersApplied,
    options: $props.filterOptionsData.activityTypes,
    "selected-options": $data.filterDataSelected[$data.dataFields.ACTIVITY_TYPE],
    "un-sorted": true,
    "label-key": "label",
    onSelected: _cache[5] || (_cache[5] = function ($event) {
      return $options.setSelected($event, $data.dataFields.ACTIVITY_TYPE);
    })
  }, null, 8 /* PROPS */, ["placeholder", "options", "selected-options"]), (_$data$selectedData = $data.selectedData) !== null && _$data$selectedData !== void 0 && (_$data$selectedData = _$data$selectedData.closedHeadlines) !== null && _$data$selectedData !== void 0 && _$data$selectedData.length ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $data.translate.reset,
    class: "mt-3",
    type: "util",
    onClick: $options.resetFilter
  }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]), !$props.openedState && (_$data$selectedData2 = $data.selectedData) !== null && _$data$selectedData2 !== void 0 && (_$data$selectedData2 = _$data$selectedData2.closedHeadlines) !== null && _$data$selectedData2 !== void 0 && _$data$selectedData2.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selectedData.closedHeadlines, function (item, index) {
    return _openBlock(), _createBlock(_component_tag_item, {
      key: "closed-headline-".concat(index),
      class: "!bg-blue-920 !w-fit flex items-center !rounded-lg !text-sm !mr-2 whitespace-nowrap",
      title: item.title
    }, {
      default: _withCtx(function () {
        return [_createVNode(_component_feather_icon, {
          icon: "x",
          class: "text-white cursor-pointer",
          onClick: function onClick($event) {
            return $options.removeHeadline(item.field);
          }
        }, null, 8 /* PROPS */, ["onClick"])];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["title"]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)]);
}