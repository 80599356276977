import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "flex mb-2"
};
var _hoisted_2 = {
  key: 0,
  class: "flex"
};
var _hoisted_3 = {
  key: 0,
  class: "border border-gray-450 p-6 pt-1 mb-3"
};
var _hoisted_4 = {
  class: "p-2 font-bold text-lg"
};
var _hoisted_5 = {
  class: "flex flex-wrap justify-between"
};
var _hoisted_6 = {
  class: "w-8/25 mb-2"
};
var _hoisted_7 = {
  class: "w-8/25 mb-2"
};
var _hoisted_8 = {
  class: "w-8/25 mb-2"
};
var _hoisted_9 = {
  class: "w-8/25 mb-2"
};
var _hoisted_10 = {
  class: "w-8/25 mb-2"
};
var _hoisted_11 = {
  class: "w-8/25 mb-2"
};
var _hoisted_12 = {
  class: "w-8/25 mb-2"
};
var _hoisted_13 = {
  class: "w-8/25 mb-2"
};
var _hoisted_14 = {
  class: "w-8/25 mb-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_select = _resolveComponent("risma-select");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [!$setup.showFilters && $options.appliedFilters.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.appliedFilters, ":"),
    type: "small",
    class: "mr-1"
  }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.appliedFilters, function (filter, index) {
    return _openBlock(), _createBlock(_component_risma_title, {
      title: "".concat(filter).concat(index === $options.appliedFilters.length - 1 ? '' : ', '),
      type: "small",
      class: "mr-1"
    }, null, 8 /* PROPS */, ["title"]);
  }), 256 /* UNKEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: "flex items-center ml-auto cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $setup.showFilters = !$setup.showFilters;
    })
  }, [_createVNode(_component_risma_title, {
    title: $options.showSwitchTitle,
    type: "medium",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
    class: "stroke-2",
    icon: $setup.showFilters ? 'chevron-down' : 'chevron-up'
  }, null, 8 /* PROPS */, ["icon"])])]), $setup.showFilters ? (_openBlock(), _createElementBlock("fieldset", _hoisted_3, [_createElementVNode("legend", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.filters,
    type: "medium",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_title, {
    title: $data.translate.choosePeriod,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), $data.isLoaded ? (_openBlock(), _createBlock(_component_risma_date_range_picker, {
    key: 0,
    "start-date": $data.dateRange.dateStart,
    "end-date": $data.dateRange.dateEnd,
    opens: "right",
    class: "w-full",
    onChanged: $options.changeDatePicker
  }, null, 8 /* PROPS */, ["start-date", "end-date", "onChanged"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_single_select, {
    modelValue: $data.dateFilterSelected,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
      return $data.dateFilterSelected = $event;
    }), $options.applyFilters],
    title: $data.translate.dateFilter,
    options: $options.dateFilterOptions,
    placeholder: $data.translate.select
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: $data.translate.responsible,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: _ctx.users,
    "selected-options": $data.selectedUsers,
    placeholder: $data.translate.select,
    "label-key": "display_name",
    onSelected: _cache[2] || (_cache[2] = function ($event) {
      return $data.selectedUsers = $event;
    }),
    "onUpdate:modelValue": $options.applyFilters
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $data.translate.organisations,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: _ctx.organisations,
    "selected-options": $data.selectedOrganisations,
    placeholder: $data.translate.select,
    "label-key": "visible_name",
    onSelected: _cache[3] || (_cache[3] = function ($event) {
      return $data.selectedOrganisations = $event;
    }),
    "onUpdate:modelValue": $options.applyFilters
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_risma_title, {
    title: $data.translate.activityType,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: _ctx.activityTypes,
    "selected-options": $data.selectedActivityTypes,
    placeholder: $data.translate.select,
    "un-sorted": true,
    "label-key": "label",
    onSelected: _cache[4] || (_cache[4] = function ($event) {
      return $data.selectedActivityTypes = $event;
    }),
    "onUpdate:modelValue": $options.applyFilters
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_risma_title, {
    title: $options.processesName,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $data.processes,
    "selected-options": $data.selectedProcesses,
    placeholder: $data.translate.select,
    "un-sorted": true,
    "label-key": "title",
    onSelected: _cache[5] || (_cache[5] = function ($event) {
      return $data.selectedProcesses = $event;
    }),
    "onUpdate:modelValue": $options.applyFilters
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_risma_title, {
    title: $options.accountTitle,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $options.accountsWithChildren,
    "selected-options": $data.selectedAccounts,
    placeholder: $data.translate.select,
    "label-key": "title",
    onSelected: _cache[6] || (_cache[6] = function ($event) {
      return $data.selectedAccounts = $event;
    }),
    "onUpdate:modelValue": $options.applyFilters
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_risma_title, {
    title: $options.risksTitle,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $data.risks,
    "selected-options": $data.selectedRisks,
    placeholder: $data.translate.select,
    "label-key": "title",
    onSelected: _cache[7] || (_cache[7] = function ($event) {
      return $data.selectedRisks = $event;
    }),
    "onUpdate:modelValue": $options.applyFilters
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_risma_title, {
    title: $data.translate.tags,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: _ctx.tags,
    "selected-options": $data.selectedTags,
    placeholder: $data.translate.select,
    "label-key": "tag",
    onSelected: _cache[8] || (_cache[8] = function ($event) {
      return $data.selectedTags = $event;
    }),
    "onUpdate:modelValue": $options.applyFilters
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "onUpdate:modelValue"])])])])) : _createCommentVNode("v-if", true), $setup.showFilters ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "flex mb-6 cursor-pointer w-min",
    onClick: _cache[9] || (_cache[9] = function () {
      return $options.resetFilter && $options.resetFilter.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "rotate-ccw",
    class: "text-gray-610 stroke-2 mr-1"
  }), _createVNode(_component_risma_title, {
    title: $data.translate.resetFilter,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)]);
}