function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import debounce from 'lodash/debounce';
import { getSecurityGroups } from '@/api/compliance/admin';
import { sendEmail } from '@/api/rismaadmin/email';
import { getDatabaseSize as _getDatabaseSize } from '@/api/rismaadmin/database';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import StaticTable from '@/components/Molecules/StaticTable';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import { StaticTableThemes } from '@/constants/StaticTableThemes';
import { getTranslate } from '@/components/Pages/Admin/translate';
export default {
  name: 'Rismaadmin',
  components: {
    MainWell: MainWell,
    RismaButton: RismaButton,
    StaticTable: StaticTable,
    RismaTitle: RismaTitle,
    NotificationPopUp: NotificationPopUp,
    RismaToggle: RismaToggle
  },
  mixins: [NotificationPopUpMixin],
  data: function data() {
    return {
      email: '',
      loginLog: [],
      tableThema: StaticTableThemes.zebra,
      humioLoginFeatureName: 'feature.humio_logging',
      handleHumioLoginDebounce: null,
      translate: getTranslate['RismaAdmin']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    emailSetting: function emailSetting() {
      return this.getSettingValue('risma.override_recipient_email');
    },
    rismaversion: function rismaversion() {
      return this.getSettingValue('app.version');
    },
    siteurl: function siteurl() {
      return this.getSettingValue('app.url');
    },
    phpversion: function phpversion() {
      return this.getSettingValue('phpversion');
    },
    databaseUsername: function databaseUsername() {
      return this.getSettingValue('databaseusername');
    },
    databaseName: function databaseName() {
      return this.getSettingValue('databasename');
    },
    serverName: function serverName() {
      return this.getSettingValue('servername');
    },
    databaseVersion: function databaseVersion() {
      return this.getSettingValue('databaseversion');
    },
    databasehost: function databasehost() {
      return this.getSettingValue('databasehost');
    },
    wkhtmltopdfversion: function wkhtmltopdfversion() {
      return this.getSettingValue('wkhtmltopdfversion');
    },
    dbAttachmentsDisabled: function dbAttachmentsDisabled() {
      return this.getSettingValue('app.db_attachments_disabled');
    },
    minioHost: function minioHost() {
      return this.getSettingValue('minio_host');
    },
    overrideRecipientEmail: function overrideRecipientEmail() {
      return this.getSettingValue('risma.override_recipient_email');
    },
    humioLoginValue: function humioLoginValue() {
      var settingValue = this.getSettingValue(this.humioLoginFeatureName);
      return settingValue === undefined || settingValue === 1;
    },
    columns: function columns() {
      return [this.translate.username, this.translate.login, this.translate.groups];
    },
    dataset: function dataset() {
      return this.loginLog.map(function (_ref) {
        var username = _ref.username,
          loginTime = _ref.loginTime,
          groups = _ref.groups;
        return {
          username: username,
          loginTime: loginTime,
          groups: groups.map(function (item) {
            return item.name;
          }).join(', ')
        };
      });
    }
  }),
  mounted: function mounted() {
    var _this = this;
    getSecurityGroups().then(function (_ref2) {
      var log = _ref2.log;
      return _this.loginLog = log;
    });
    this.email = this.emailSetting;
    this.handleHumioLoginDebounce = debounce(this.handleHumioLoginChange, 500);
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useSettingsStore, ['updateSettings'])), {}, {
    sendTestEmail: function sendTestEmail() {
      var _this2 = this;
      sendEmail(this.email).then(function () {
        return _this2.handleInstantNotification({
          message: _this2.translate.emailHasBeenSent,
          type: 'success'
        });
      }).catch(function () {
        return _this2.handleInstantNotification({
          message: _this2.translate.somethingWentWrong,
          footer: _this2.translate.tryAgainOrIfTheIssue,
          type: 'error'
        });
      });
    },
    getDatabaseSize: function getDatabaseSize() {
      var _this3 = this;
      _getDatabaseSize().then(function (data) {
        alert(data.databaseSize + ' MB in database');
        if (_this3.dbAttachmentsDisabled) {
          alert(data.minioSize + ' MB in minio');
        }
      });
    },
    handleHumioLoginChange: function handleHumioLoginChange(value) {
      var _this4 = this;
      var newValue = value ? 1 : 0;
      return this.updateSettings(_defineProperty({}, this.humioLoginFeatureName, newValue)).then(function () {
        return _this4.handleInstantNotification({
          message: _this4.translate.changesSaved,
          type: 'success'
        });
      }).catch(function () {
        return _this4.handleInstantNotification({
          message: _this4.translate.somethingWentWrongWhileUploading,
          type: 'error'
        });
      });
    }
  })
};