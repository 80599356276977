import { getTranslate } from './translate';
import GanttChartCommon from '@/components/Molecules/Charts/GanttChartCommon';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { getInitiativesFromIds } from '@/utils/format/objectsFromIds';
import { getItemGaps } from './SupervisorUtils';
import { customizeColumnsWithGapsFn, customizeDatasetWithGapsFn } from '@/components/Atoms/RismaDatatable/ExportUtils';
import * as DataAPI from '@/api/risma/data';
import { Colors } from '@/Colors';
export default {
  name: 'SupervisorInitiatives',
  introduction: 'supervisor initiatives dashboard',
  description: 'this sub-page show the supervisor initiatives dashboards',
  token: '<supervisor-initiatives></supervisor-initiatives>',
  components: {
    RismaDatatable: RismaDatatable,
    PreviewModalLink: PreviewModalLink,
    Trafficlight: Trafficlight,
    GanttChartCommon: GanttChartCommon
  },
  mixins: [RismaDatatableDefaultMixin],
  props: {
    initiatives: {
      required: true,
      type: Array
    },
    gapArticleMap: {
      type: Map,
      required: true,
      note: 'A list of gap articles'
    }
  },
  emits: ['dismissModal'],
  data: function data() {
    return {
      allInitiatives: [],
      translate: getTranslate['SupervisorInitiatives']()
    };
  },
  computed: {
    columns: function columns() {
      return {
        trafficlight: this.translate.trafficLight,
        number: '#',
        title: this.translate.title,
        description: this.translate.description,
        gaps: this.translate.gaps,
        organisation: this.translate.organisations,
        responsible: this.translate.responsible,
        status: this.translate.presentStatus,
        target: this.translate.target,
        deadline: this.translate.startdeadline,
        depending: this.translate.dependingOn,
        percentage: this.translate.completionPercentage
      };
    },
    dataset: function dataset() {
      var _this = this;
      return this.initiatives.map(function (initiative) {
        var objectList = getInitiativesFromIds(initiative.dependingOn, _this.allInitiatives);
        return {
          trafficlight: {
            field: {
              color: Colors.trafficLights[initiative.trafficLight].color
            },
            fieldText: Colors.trafficLights[initiative.trafficLight].label
          },
          number: {
            field: initiative.ipath
          },
          title: {
            activity: getFieldsFromObjectForPreview(initiative),
            fieldText: initiative.title
          },
          description: {
            field: initiative.description
          },
          gaps: {
            activities: getItemGaps(initiative.gap, _this.gapArticleMap)
          },
          organisation: {
            field: initiative.organisationIds.map(function (org) {
              return org.visible_name;
            }).join(', ')
          },
          responsible: {
            field: initiative.userIds.responsible.map(function (user) {
              return user.display_name;
            }).join(', ')
          },
          status: {
            field: initiative.status
          },
          target: {
            field: initiative.target
          },
          deadline: {
            field: initiative.start + '<b>/</b>' + initiative.deadline
          },
          depending: {
            field: objectList.map(function (initiative) {
              return {
                url: initiative.url,
                title: "".concat(initiative.ipath, " - ").concat(initiative.title)
              };
            }),
            fieldText: objectList.map(function (initiative) {
              return "".concat(initiative.ipath, " - ").concat(initiative.title);
            }).join(', ')
          },
          percentage: {
            field: initiative.completionPercentage + '%'
          }
        };
      });
    },
    customExport: function customExport() {
      return {
        columns: customizeColumnsWithGapsFn,
        dataset: customizeDatasetWithGapsFn(this.gapArticleMap)
      };
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.$forceUpdate();
    this.getAllInitiatives().then(function () {
      return _this2.enhanceDataset(_this2.dataset, _this2.columns);
    });
  },
  methods: {
    getAllInitiatives: function getAllInitiatives() {
      var _this3 = this;
      return DataAPI.getInitiatives().then(function (_ref) {
        var list = _ref.list;
        return _this3.allInitiatives = list;
      });
    }
  }
};