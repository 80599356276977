import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-10f899c1"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "hierarchical-list"
};
var _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_router_link = _resolveComponent("router-link");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_hierarchical_list = _resolveComponent("hierarchical-list", true);
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(['indent-' + $props.depth, {
      'firstLevel': $props.isFirstLevel
    }, {
      'lastLevels': $props.isLastLevel || $props.isFirstLevel && (!$props.node.children || $props.node.children.length === 0)
    }])
  }, [_createElementVNode("div", {
    style: _normalizeStyle($options.indent)
  }, [$props.node.url && $props.hasPreviewMode && !$options.isPreview ? (_openBlock(), _createBlock(_component_preview_modal_link, {
    key: 0,
    activity: $props.node,
    onUpdated: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('updated', $event);
    }),
    "onUpdated:governanceReview": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('updated:governanceReview', $event);
    }),
    onDismissModal: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('dismissModal');
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($props.node.title), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"])) : $props.node.url ? (_openBlock(), _createBlock(_component_router_link, {
    key: 1,
    to: $props.node.url
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        type: "small",
        title: $props.node.title
      }, null, 8 /* PROPS */, ["title"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : (_openBlock(), _createBlock(_component_risma_title, {
    key: 2,
    type: "small",
    title: $props.node.title
  }, null, 8 /* PROPS */, ["title"])), $props.node.children && $props.node.children.length !== 0 && $props.toggleChildren ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 3,
    class: "feather-chevron-icon",
    icon: $options.toggleIcon,
    size: 2,
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return $data.showChild = !$data.showChild;
    })
  }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true)], 4 /* STYLE */), $props.depth > 1 ? (_openBlock(), _createElementBlock("hr", _hoisted_2)) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.node.children, function (child, key) {
    return _withDirectives((_openBlock(), _createBlock(_component_hierarchical_list, {
      key: key,
      "show-children": $data.showChild,
      "toggle-children": $props.toggleChildren,
      "has-preview-mode": $props.hasPreviewMode,
      node: child,
      depth: $props.depth + 1,
      "is-first-level": false,
      "is-last-level": !child.children || child.children === 0,
      onUpdated: _cache[4] || (_cache[4] = function ($event) {
        return _ctx.$emit('updated', $event);
      }),
      onDismissModal: _cache[5] || (_cache[5] = function ($event) {
        return _ctx.$emit('dismissModal');
      })
    }, null, 8 /* PROPS */, ["show-children", "toggle-children", "has-preview-mode", "node", "depth", "is-last-level"])), [[_vShow, $data.showChild]]);
  }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */)]);
}