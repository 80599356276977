import RiskGrid from '@/components/Organisms/RiskGrid';
export default {
  name: '',
  components: {
    RiskGrid: RiskGrid
  },
  data: function data() {
    return {
      riskGridDataSmaller: {
        checked: false,
        xLabel: 'X label',
        yLabel: 'Y label',
        gridSizeY: [{
          label: '10'
        }, {
          label: '20'
        }, {
          label: '30'
        }, {
          label: '40'
        }, {
          label: '50'
        }],
        gridSizeX: [{
          label: 'lavest'
        }, {
          label: 'lav'
        }, {
          label: 'mellem'
        }, {
          label: 'høj'
        }, {
          label: 'højest'
        }],
        colorCodes: {
          green: 'background-color: hsl(180, 100%, 20%)',
          /* Closed */
          yellow: 'background-color: hsl(50, 100%, 50%)',
          /* Not used in risk matrix... */
          lightblue: 'background-color: hsl(187, 100%, 39%)',
          /* Not updated */
          rismablue: 'background-color: hsl(190, 100%, 28%)',
          /* Updated */
          red: 'background-color: hsl(18, 98%, 40%)',
          /* New */
          grey: 'background-color: hsl(0, 0%, 60%)' /* Not approved */
        },

        dots: [{
          id: 1,
          title: 'titel1',
          rpi: 2,
          color: 'lightblue',
          status: 'ikke opdateret',
          y: 1,
          x: 3,
          yBefore: 1,
          xBefore: 5
        }, {
          id: 2,
          title: 'titel2',
          rpi: 30,
          color: 'red',
          status: 'ny',
          y: 2,
          x: 1,
          yBefore: 1,
          xBefore: 2
        }, {
          id: 3,
          title: 'titel3',
          rpi: 16,
          color: 'lightblue',
          status: 'ikke opdateret',
          y: 4,
          x: 4,
          yBefore: 4,
          xBefore: 3
        }, {
          id: 4,
          title: 'titel4',
          rpi: 2,
          color: 'red',
          status: 'lukket',
          y: 1,
          x: 1,
          yBefore: 1,
          xBefore: 3
        }, {
          id: 5,
          title: 'titel5',
          rpi: 10,
          color: 'rismablue',
          status: 'opdateret',
          y: 3,
          x: 3,
          yBefore: 3,
          xBefore: 2
        }, {
          id: 6,
          title: 'titel6',
          rpi: 6,
          color: 'red',
          status: 'ny',
          y: 1,
          x: 4,
          yBefore: 3,
          xBefore: 1
        }, {
          id: 7,
          title: 'titel7',
          rpi: 30,
          color: 'green',
          status: 'lukket',
          y: 3,
          x: 4,
          yBefore: 1,
          xBefore: 5
        }, {
          id: 8,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 36,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 5,
          xBefore: 4
        }, {
          id: 9,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 10,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 11,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 12,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 13,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 14,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 15,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 16,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 17,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 18,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 19,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 20,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 21,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 22,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 23,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 24,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 25,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 26,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 27,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 28,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 29,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 30,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 31,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 32,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 33,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 34,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }, {
          id: 35,
          title: 'titel8',
          rpi: 30,
          color: 'grey',
          status: 'ikke godkendt',
          y: 3,
          x: 4,
          yBefore: 4,
          xBefore: 5
        }],
        XXXgridWithData: [[{
          id: 1,
          title: 'titel1',
          rpi: 2,
          status: 'ikke opdateret',
          y: 1,
          x: 3,
          yBefore: 1,
          xBefore: 7
        }, {}, {}, {}, {}], [{}, {}, {
          id: 2,
          title: 'titel2',
          rpi: 2,
          status: 'ikke opdateret',
          y: 1,
          x: 2,
          yBefore: 1,
          xBefore: 7
        }, {}, {}], [{}, {}, {}, {}, {
          id: 3,
          title: 'titel3',
          rpi: 2,
          status: 'ikke opdateret',
          y: 3,
          x: 3,
          yBefore: 1,
          xBefore: 7
        }], [{}, {
          id: 4,
          title: 'titel4',
          rpi: 2,
          status: 'ikke opdateret',
          y: 1,
          x: 6,
          yBefore: 1,
          xBefore: 7
        }, {}, {}, {}], [{}, {}, {
          id: 5,
          title: 'titel5',
          rpi: 2,
          status: 'ikke opdateret',
          y: 4,
          x: 3,
          yBefore: 1,
          xBefore: 7
        }, {}, {}]],
        grid: [[{}, {}, {}, {}, {}], [{}, {}, {}, {}, {}], [{}, {}, {}, {}, {}], [{}, {}, {}, {}, {}], [{}, {}, {}, {}, {}]]
      }
    };
  }
};