function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useTagsStore } from '@/Store/tagsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import TagItem from '@/components/Atoms/Containers/TagItem';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { objectToQueryString } from '@/api/httpUtils';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
var DATA_FIELDS = {
  TITLE: 'search',
  ORGANISATIONS: 'organisations',
  RESPONSIBLE: 'responsible',
  TAGS: 'tags',
  ACTIVITY_TYPE: 'activityTypes'
};
export default {
  name: 'MiniTreeFilters',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    InputField: InputField,
    RismaSelect: RismaSelect,
    TagItem: TagItem,
    RismaButton: RismaButton
  },
  mixins: [RaciTitleMixin],
  props: {
    filterOptionsData: {
      required: false,
      type: Object,
      default: function _default() {
        return {
          users: [],
          activityTypes: []
        };
      }
    },
    openedState: {
      type: Boolean,
      required: true
    },
    localStorageUniqueKey: {
      type: String,
      required: false,
      default: null,
      note: 'project id for localStorage'
    }
  },
  emits: ['search'],
  setup: function setup(props) {
    var localStorageKey = "mini_tree_filters_".concat(props.localStorageUniqueKey);
    return {
      selectedStorage: getLocalStorageComputed(localStorageKey, {}, true)
    };
  },
  data: function data() {
    return {
      filterDataSelected: null,
      oldFilterDataSelected: null,
      dataFields: DATA_FIELDS,
      filterData: {},
      translate: getTranslate['MiniTreeFilters'](),
      selectedData: {
        filterString: '',
        closedHeadlines: []
      },
      filterString: ''
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useTagsStore, ['tags'])), {}, {
    itemDataToHeadline: function itemDataToHeadline() {
      var _ref;
      return _ref = {}, _defineProperty(_ref, DATA_FIELDS.TITLE, this.translate.title), _defineProperty(_ref, DATA_FIELDS.ORGANISATIONS, this.translate.organisations), _defineProperty(_ref, DATA_FIELDS.RESPONSIBLE, this.responsibleTitle(true)), _defineProperty(_ref, DATA_FIELDS.TAGS, this.translate.tags), _defineProperty(_ref, DATA_FIELDS.ACTIVITY_TYPE, this.translate.activityTypes), _ref;
    },
    responsible: function responsible() {
      return this.filterOptionsData.users;
    },
    activityTypes: function activityTypes() {
      return this.filterOptionsData.activityTypes || [];
    }
  }),
  watch: {
    filterDataSelected: {
      deep: true,
      handler: function handler(value) {
        var cutValue = _objectSpread(_objectSpread({}, value), {}, _defineProperty({}, DATA_FIELDS.TITLE, ''));
        if (JSON.stringify(cutValue) !== JSON.stringify(this.oldFilterDataSelected)) {
          this.updateFiltersData(value);
        }
        this.setOldFilterSelected(value);
      }
    },
    openedState: function openedState() {
      this.updateFiltersData(this.filterDataSelected);
    }
  },
  mounted: function mounted() {
    this.resetFilter();
    this.setOldFilterSelected(this.filterDataSelected);
    this.loadLocalStorageData();
  },
  methods: {
    resetFilter: function resetFilter() {
      var _this$filterDataSelec;
      this.filterDataSelected = (_this$filterDataSelec = {}, _defineProperty(_this$filterDataSelec, DATA_FIELDS.TITLE, ''), _defineProperty(_this$filterDataSelec, DATA_FIELDS.ORGANISATIONS, []), _defineProperty(_this$filterDataSelec, DATA_FIELDS.RESPONSIBLE, []), _defineProperty(_this$filterDataSelec, DATA_FIELDS.TAGS, []), _defineProperty(_this$filterDataSelec, DATA_FIELDS.ACTIVITY_TYPE, []), _this$filterDataSelec);
    },
    loadLocalStorageData: function loadLocalStorageData() {
      try {
        this.setPreselectedData(this.selectedStorage);
        this.updateFiltersData(this.filterDataSelected, true);
      } catch (_unused) {
        this.selectedStorage = {};
      }
    },
    setPreselectedData: function setPreselectedData(data) {
      var _this = this;
      var keys = Object.values(DATA_FIELDS);
      Object.keys(data).forEach(function (key) {
        if (!keys.includes(key)) return;
        var value = _this.getPreselectedValue(data[key], key);
        _this.filterDataSelected[key] = value;
      });
    },
    getPreselectedValue: function getPreselectedValue(data, key) {
      var _this2 = this;
      if (key === DATA_FIELDS.TITLE) {
        return data;
      }
      if (!this[key]) return;
      var result = [];
      data.forEach(function (id) {
        var item = _this2[key].find(function (element) {
          return element.id === id;
        });
        if (item) result.push(item);
      });
      return result;
    },
    setOldFilterSelected: function setOldFilterSelected(value) {
      this.oldFilterDataSelected = _objectSpread(_objectSpread({}, value), {}, _defineProperty({}, DATA_FIELDS.TITLE, ''));
    },
    setSelected: function setSelected(list, key) {
      this.filterDataSelected[key] = list === null ? [] : list;
    },
    removeHeadline: function removeHeadline(item) {
      if (item === DATA_FIELDS.TITLE) {
        this.filterDataSelected[item] = '';
        this.updateFiltersData(this.filterDataSelected);
      } else {
        this.filterDataSelected[item] = [];
      }
    },
    updateFiltersData: function updateFiltersData(data) {
      var _this3 = this;
      var firstEnter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var filterString = '',
        closedHeadlines = [];
      this.selectedStorage = {};
      Object.keys(data).forEach(function (key) {
        if (_this3.isFieldUsed(data, key)) {
          closedHeadlines.push({
            field: key,
            title: _this3.itemDataToHeadline[key]
          });
          if (filterString.length) {
            filterString += '&';
          } else {
            filterString += '?';
          }
          var _this3$prepareFilterD = _this3.prepareFilterDataByType(data, key),
            string = _this3$prepareFilterD.string,
            value = _this3$prepareFilterD.value;
          _this3.selectedStorage = _objectSpread(_objectSpread({}, _this3.selectedStorage), {}, _defineProperty({}, key, value));
          filterString += string;
        }
      });
      this.selectedData = {
        filterString: filterString,
        closedHeadlines: closedHeadlines
      };
      if (this.filterString !== filterString || firstEnter) {
        this.filterString = filterString;
        this.$emit('search', filterString, firstEnter);
      }
    },
    isFieldUsed: function isFieldUsed(data, key) {
      return !!data[key].length;
    },
    prepareFilterDataByType: function prepareFilterDataByType(data, key) {
      if (key === DATA_FIELDS.TITLE) {
        return {
          string: "".concat(key, "=").concat(this.filterDataSelected[key]),
          value: this.filterDataSelected[key]
        };
      }
      var value = data[key].map(function (item) {
        return item.id;
      });
      return {
        string: objectToQueryString({
          filters: _defineProperty({}, key, value)
        }),
        value: value
      };
    }
  }
};