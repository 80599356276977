import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_tree = _resolveComponent("risma-tree");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.treeData !== null ? (_openBlock(), _createBlock(_component_risma_tree, {
    key: 0,
    "tree-data": $data.treeData.children,
    "disable-item-menu": true,
    "enable-add-button": false,
    "lock-tree": false,
    onMove: $options.move
  }, null, 8 /* PROPS */, ["tree-data", "onMove"])) : _createCommentVNode("v-if", true)]);
}