import PreviewRiskTiles from './PreviewRiskTiles';
import PreviewRiskCompact from './PreviewRiskCompact';
import PreviewRiskExpanded from './PreviewRiskExpanded';
export default {
  name: 'PreviewRisk',
  introduction: 'Handle view of a risk',
  description: 'Handle view of a risk. Uses only for tiles or expanded view with a property `mode`',
  token: '<preview-risk :risk="risk" :scoring="scoring" isRisk2="isRisk2" :mode="mode" />',
  components: {
    PreviewRiskTiles: PreviewRiskTiles,
    PreviewRiskCompact: PreviewRiskCompact,
    PreviewRiskExpanded: PreviewRiskExpanded
  },
  props: {
    risk: {
      type: Object,
      default: function _default() {}
    },
    scoring: {
      type: Object,
      default: function _default() {}
    },
    isRisk2: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'expanded',
      required: false
    },
    organisations: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isApprovalFlowEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowLinkedItems: {
      type: Boolean,
      default: false
    }
  },
  emits: ['updateFromPreviewMode'],
  computed: {
    currentTypeView: function currentTypeView() {
      var capitalizeMode = "".concat(this.mode.charAt(0).toUpperCase()).concat(this.mode.slice(1));
      return "PreviewRisk".concat(capitalizeMode);
    }
  }
};