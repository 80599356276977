import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function createKpis(dashboardIndex) {
  var params = {
    dashboardIndex: dashboardIndex
  };
  return handleRequestResponse(Http.post('/rismaAdmin/defaultkpi', params));
}
export function runKpis() {
  return handleRequestResponse(Http.post('/rismaAdmin/runkpis'));
}
export function getDashboards() {
  return handleRequestResponse(Http.get('/rismaAdmin/defaultkpi'));
}