import RismaColumnSwitcher from '@/components/Atoms/RismaColumnSwitcher/RismaColumnSwitcher';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { Colors } from '@/Colors';
export default {
  name: '',
  components: {
    RismaColumnSwitcher: RismaColumnSwitcher,
    FeatherIcon: FeatherIcon
  },
  data: function data() {
    return {
      colors: Colors,
      columns: [{
        label: 'one',
        visible: true
      }, {
        label: 'two',
        visible: true
      }]
    };
  },
  methods: {
    onChangeVisibility: function onChangeVisibility(column) {
      column.visible = !column.visible;
    }
  }
};