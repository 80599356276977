function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getSingle as getIncident, update as updateIncidents } from '@/api/incident/incident';
import { TabTypes, TabsDepRelations, DepRelationsTabDelegation, DepRelationsTabDetails } from '@/constants/Tabs';
import { saveStatus } from '@/constants/SaveStatuses';
import { createTabsList } from '@/utils/base/tabs';
import { getLinksLabel } from '@/utils/links';
import { getCustomFieldValues } from '@/utils/CustomFields';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import DelegationWell from '@/components/Organisms/General/coreWells/DelegationWell';
import SolutionIdRelationDetails from '@/components/Molecules/SolutionIdRelationDetails';
import ActivityTypeSelector from '@/components/Molecules/ActivityTypeSelector';
import { MODULES } from '@/constants/modules';
import { INCIDENT } from '@/constants/routes/incident';
import { UserLevels } from '@/constants/UserLevels';
import Notification from '@/components/Molecules/Notification';
import { isFieldInOptionalFields } from '@/utils/Utils';
import { isLockedIncidentUser } from '@/utils/access';
import { updateFileLocally } from '@/utils/Attachments';
import { catch403 } from '@/utils/handleAPIErrors';
export default {
  name: 'IncidentEntry',
  description: 'Common page for show incident',
  token: '<incident-entry />',
  components: {
    DepWell: DepWell,
    Notification: Notification,
    DelegationWell: DelegationWell,
    SolutionIdRelationDetails: SolutionIdRelationDetails,
    ActivityTypeSelector: ActivityTypeSelector,
    RismaTitle: RismaTitle
  },
  props: {
    id: {
      required: true,
      type: [String, Number],
      note: 'Id of the incident or "new" for creation an incident'
    }
  },
  emits: ['changeTab', 'updated'],
  data: function data() {
    return {
      incident: null,
      saveStatus: saveStatus.SAVED,
      updateTimer: 0,
      module: MODULES.INCIDENTS,
      tabsDepRelations: TabsDepRelations(),
      translate: {
        thisActivityIsReadonlyAsYourUserIsConfigu: this.$trans('This activity is read-only as your user is configured to only have read-only access'),
        activityType: this.$trans('Activity type'),
        areYouSureYouWantToChangeTheActivityType: this.$trans('Are you sure you want to change the activity type?')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettings: 'getValue'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'incidentActivityTypes'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useUsersStore, {
    unfilteredUsers: 'activeUsers'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    users: function users() {
      return this.unfilteredUsers.filter(function (user) {
        return user.level_incidents > UserLevels.NONE || user.is_admin;
      });
    },
    isLoaded: function isLoaded() {
      return this.incident !== null;
    },
    isUserLocked: function isUserLocked() {
      return isLockedIncidentUser(this.currentUser);
    },
    readOnly: function readOnly() {
      var _this$incident;
      return this.isUserLocked || !!((_this$incident = this.incident) !== null && _this$incident !== void 0 && _this$incident.archived);
    },
    tabs: function tabs() {
      var tabs = [TabTypes.DESCRIPTION, TabTypes.LINKS, TabTypes.CHANGELOG];
      return createTabsList(tabs, "/incident/".concat(this.id), getLinksLabel(this.incident));
    },
    depHeaderProps: function depHeaderProps() {
      if (!this.isLoaded) {
        return {};
      }
      return {
        editableTitle: !this.readOnly,
        title: this.incident.title,
        filesList: this.incident.attachments,
        tabs: this.tabs,
        saveStatus: this.saveStatus,
        titleField: 'title',
        trafficLightSettings: this.incident.severity !== null ? {
          value: this.incident.severity,
          lightType: 'incidents',
          field: 'severity',
          readOnly: this.readOnly
        } : {},
        id: this.incident.id + '',
        moduleId: this.incident.id,
        module: this.module,
        editableAttachments: !this.readOnly,
        users: this.users
      };
    },
    depRelationsTabs: function depRelationsTabs() {
      return [DepRelationsTabDelegation(), DepRelationsTabDetails(this.hasRelationsTab)];
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.isLoaded && this.activityTypes.find(function (at) {
        return _this.incident.activityTypeId === at.id;
      });
    },
    hasRelationsTab: function hasRelationsTab() {
      return this.getSettings['feature.performance_report_enabled'] > 0 || this.incident && this.activityTypes.length;
    }
  }),
  mounted: function mounted() {
    this.getIncidentData(this.id);
  },
  methods: {
    getIncidentData: function getIncidentData(id) {
      var _this2 = this;
      return getIncident(id).then(function (response) {
        _this2.incident = response;
        if (_this2.incident.incidentHappenedAt === null) {
          _this2.incident.incidentHappenedAt = '';
        }
      }).catch(function () {
        return catch403(_this2.$router, 'MyIncidents');
      });
    },
    incidentPropertyChanged: function incidentPropertyChanged(_ref) {
      var property = _ref.property,
        value = _ref.value;
      if (property === 'responsibleUserIds') {
        if (!value.length && this.incident.confidential) {
          this.incident.confidential = 0;
        }
        this.incident[property] = value;
      } else {
        this.incident[property] = value;
      }
      this.setUpdateTimer();
    },
    saveChangesImmediately: function saveChangesImmediately(updatedProperty) {
      this.incidentPropertyChanged(updatedProperty);
      this.saveStatusClicked();
    },
    setUpdateTimer: function setUpdateTimer() {
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(this.saveUpdates, this.$autoSaveDebounceTime);
    },
    saveStatusClicked: function saveStatusClicked() {
      if (this.saveStatus !== saveStatus.SAVED) {
        clearTimeout(this.updateTimer);
        this.saveUpdates();
      }
    },
    saveUpdates: function saveUpdates() {
      var _this3 = this;
      var timerId = this.updateTimer;
      this.saveStatus = saveStatus.SAVING;
      var customFieldValues = getCustomFieldValues(this.incident);
      var activityType = this.activityTypes.find(function (item) {
        return item.id === _this3.incident.activityTypeId;
      });
      var data = _objectSpread(_objectSpread({}, this.incident), {}, {
        customFieldValues: customFieldValues
      });
      var optionalFields = ['severity', 'description'];
      optionalFields.forEach(function (field) {
        if (!isFieldInOptionalFields(activityType, field) || _this3.incident[field] === null) {
          delete data[field];
          if (field === 'severity') {
            delete data.trafficLight;
          }
        }
      });
      updateIncidents(data).then(function (response) {
        if (!Object.values(response).length) {
          return _this3.$router.push({
            name: INCIDENT.incidentList
          });
        }
        // update the incident if there were no new changes during the save time
        if (timerId === _this3.updateTimer) {
          _this3.incident = response;
        }
        _this3.$emit('updated', _objectSpread({}, _this3.incident));
        _this3.saveStatus = saveStatus.SAVED;
      }).catch(function () {
        _this3.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    onFileUpdate: function onFileUpdate(fileId) {
      var _this$incident2;
      updateFileLocally(fileId, (_this$incident2 = this.incident) === null || _this$incident2 === void 0 ? void 0 : _this$incident2.attachments);
      this.saveStatus = saveStatus.SAVED;
    },
    updatingAttachments: function updatingAttachments() {
      this.saveStatus = saveStatus.SAVING;
    },
    getActivityData: function getActivityData() {
      var _this4 = this;
      this.getIncidentData(this.id).then(function () {
        return _this4.saveStatus = saveStatus.SAVED;
      });
    }
  }
};