import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { clickOutside } from '@/utils/directives/clickOutside';
export default {
  name: 'RismaColumnSwitcher',
  introduction: 'RismaColumnSwitcher for RismaDatatable',
  description: 'RismaColumnSwitcher is a part of RismaDatatable components.',
  token: '<risma-column-switcher columns="[{label: "one", visible: true}, {label: "two", visible: true}]" @change="somefunc"></risma-column-switcher>',
  components: {
    FeatherIcon: FeatherIcon
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    visibilitySwitchLabel: {
      type: String,
      required: false,
      default: 'Column visibility',
      note: 'Default switch label'
    },
    columns: {
      type: Array,
      required: true,
      note: 'Columns to hide/show'
    },
    hasAllSwitcher: {
      type: Boolean,
      required: false,
      default: true,
      note: 'switcher for all columns'
    },
    alwaysVisibleColumns: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Should some columns be always visible?'
    },
    alwaysVisibleColumnsKey: {
      type: String,
      required: false,
      default: 'key',
      note: 'always visibility key'
    }
  },
  emits: ['changed'],
  data: function data() {
    return {
      popupVisible: false,
      mainSwitch: true,
      translate: {
        selectdeselectAll: this.$trans('Select/deselect all'),
        alwaysVisibleColumn: this.$trans('Always visible column')
      }
    };
  },
  watch: {
    columns: {
      deep: true,
      handler: function handler() {
        var _this = this;
        if (!this.hasAllSwitcher) return;
        this.mainSwitch = this.columns.some(function (column) {
          return column.visible && !_this.isAlwaysVisible(column);
        });
      }
    }
  },
  mounted: function mounted() {
    this.setAlwaysVisibleColumns();
  },
  methods: {
    setAlwaysVisibleColumns: function setAlwaysVisibleColumns() {
      var _this2 = this;
      if (!this.alwaysVisibleColumns.length) {
        return;
      }
      this.columns.forEach(function (element) {
        if (_this2.isAlwaysVisible(element) && element.visible === false) {
          _this2.$emit('changed', element);
        }
      });
    },
    isAlwaysVisible: function isAlwaysVisible(item) {
      return this.alwaysVisibleColumns.indexOf(item[this.alwaysVisibleColumnsKey]) !== -1;
    },
    handleSwitchClick: function handleSwitchClick() {
      this.popupVisible = !this.popupVisible;
    },
    setVisibility: function setVisibility(item) {
      if (this.isAlwaysVisible(item)) {
        return;
      }
      this.$emit('changed', item);
    },
    setAllVisibility: function setAllVisibility() {
      var _this3 = this;
      this.mainSwitch = !this.mainSwitch;
      this.columns.forEach(function (element) {
        if (element.visible !== _this3.mainSwitch && !_this3.isAlwaysVisible(element)) {
          _this3.$emit('changed', element);
        }
      });
    },
    hideVisibilitySwitcher: function hideVisibilitySwitcher(event) {
      event.stopPropagation();
      if (this.popupVisible) {
        this.popupVisible = false;
      }
    }
  }
};