function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { updateNodeByProjectIdAndNodeId } from '@/api/compliance/node';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import Alert from '@/components/Molecules/Modal/Alert';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RiskAssessmentsIsms from '@/components/Organisms/Risk/RiskAssessmentsIsms';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import QuestionnaireStats from '@/components/Molecules/Compliance/QuestionnaireStats';
import RiskGrid from '@/components/Organisms/RiskGrid';
import WizardFlowWrapper from '@/components/Organisms/WizardFlowWrapper';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { UserLevels } from '@/constants/UserLevels';
import { Colors } from '@/Colors';
import { NodeFields } from '@/constants/compliance/NodeFields';
import { MODULES } from '@/constants/modules';
import { toLocalDate } from '@/utils/date';
import { generateComputed } from '@/utils/base/generateComputed';
import { getProjectDescriptionLabel, getProjectPurposeLabel, getProjectStatusLabel } from '@/utils/Compliance/complianceProject';
import { prepareRiskDots } from '@/components/Pages/Risks/RiskReports/RiskReportUtilsRisk';
import { CustomFieldsMixin } from '@/mixins/CustomFieldsMixin';
import { RiskAssessmentMixin } from '@/mixins/RiskAssessmentMixin';
import { RiskGridMixin } from '@/mixins/RiskGridMixin';
import { ActivityTypeMixin } from '@/mixins/ActivityTypeMixin';
export default {
  name: 'DescriptionPage',
  introduction: '',
  description: '',
  token: '<description-page></description-page>',
  components: {
    FeatherIcon: FeatherIcon,
    RismaDoughnutChart: RismaDoughnutChart,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    TinyMceBox: TinyMceBox,
    Alert: Alert,
    ActivityCustomFields: ActivityCustomFields,
    RiskAssessmentsIsms: RiskAssessmentsIsms,
    SingleSelect: SingleSelect,
    QuestionnaireStats: QuestionnaireStats,
    RiskGrid: RiskGrid,
    WizardFlowWrapper: WizardFlowWrapper
  },
  mixins: [CustomFieldsMixin, RiskAssessmentMixin, RiskGridMixin, ActivityTypeMixin],
  props: {
    nodeId: {
      required: true,
      type: Number,
      note: 'The node that needs to be edited'
    },
    data: {
      required: true,
      type: Object,
      note: 'Current node object'
    },
    projectId: {
      type: Number,
      required: true,
      note: 'project id for the current project'
    }
  },
  emits: ['deleteNode', 'questionnaire'],
  data: function data() {
    return {
      UserLevels: UserLevels,
      updateTimer: 0,
      showDeleteAlert: false,
      retryAction: null,
      showRetry: false,
      alertBody: '',
      module: MODULES.COMPLIANCE,
      pieSliceColors: [Colors.answered, Colors.unanswered, Colors.na],
      toLocalDate: toLocalDate,
      isRiskDataLoaded: false,
      gridBoxSize: 45,
      translate: getTranslate['DescriptionPage'](),
      NodeFields: NodeFields
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useUserStore, {
    currentUserComplianceProjectById: 'complianceProjectById',
    currentUserAccessLevelByProjectId: 'complianceAccessLevelByProjectId',
    currentUser: 'currentUser'
  })), generateComputed(['description', 'purpose', 'status'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    currentProject: function currentProject() {
      return this.currentUserComplianceProjectById(this.projectId);
    },
    userHasAccessToGapAnalysis: function userHasAccessToGapAnalysis() {
      return this.currentUserAccessLevelByProjectId(this.projectId) >= this.getSettingValue('feature.gap_visible_for_user_level') || this.currentUser.is_admin;
    },
    hasWizardFlowModal: function hasWizardFlowModal() {
      return this.getSettingValue('feature.dataflow_wizard');
    },
    isCSRD: function isCSRD() {
      var _this$currentProject;
      return ((_this$currentProject = this.currentProject) === null || _this$currentProject === void 0 ? void 0 : _this$currentProject.type) === ProjectTypes.CSRD;
    },
    canDeleteNode: function canDeleteNode() {
      return !this.isCSRD && (this.currentUserAccessLevelByProjectId(this.projectId) >= this.UserLevels.PRIVILEGED || this.currentUser.is_admin) && !this.currentProject.locked;
    },
    showPurpose: function showPurpose() {
      return this.isFieldInOptionalFields('process_purpose') || !this.hasActivityTypeWithOptionalFields;
    },
    showStatus: function showStatus() {
      return this.isFieldInOptionalFields('process_status') || !this.hasActivityTypeWithOptionalFields;
    },
    showQuestionnaireStats: function showQuestionnaireStats() {
      var _this$data;
      return (this.isFieldInOptionalFields('questionnaire') || !this.hasActivityTypeWithOptionalFields) && ((_this$data = this.data) === null || _this$data === void 0 || (_this$data = _this$data.questionnaires) === null || _this$data === void 0 ? void 0 : _this$data.length);
    },
    showGapAnalysis: function showGapAnalysis() {
      var _this$currentActivity;
      if (((_this$currentActivity = this.currentActivityType) === null || _this$currentActivity === void 0 || (_this$currentActivity = _this$currentActivity.optionalFields) === null || _this$currentActivity === void 0 ? void 0 : _this$currentActivity.indexOf('gap_schema')) === -1) return false;
      return this.userHasAccessToGapAnalysis && this.data.gapSchemaId;
    },
    hasActivityTypeWithOptionalFields: function hasActivityTypeWithOptionalFields() {
      var _this$currentActivity2;
      return (_this$currentActivity2 = this.currentActivityType) === null || _this$currentActivity2 === void 0 ? void 0 : _this$currentActivity2.optionalFields;
    },
    gapAnalysisChartData: function gapAnalysisChartData() {
      var labels = [this.translate.answered, this.translate.notAnswered, this.translate.na];
      var data = [this.data.values.gap_records_answered, this.data.values.gap_records_count - this.data.values.gap_records_answered - this.data.values.gap_records_na, this.data.values.gap_records_na];
      return {
        datasets: [{
          data: data,
          backgroundColor: this.pieSliceColors
        }],
        labels: labels
      };
    },
    riskProjectId: function riskProjectId() {
      return this.currentProject.riskProjectId || null;
    },
    showRiskAssessment: function showRiskAssessment() {
      if (!this.getSettingValue('feature.compliance_nodes_risk_assessment')) return false;
      return this.hasActivityTypeWithOptionalFields && this.isFieldInOptionalFields('risk_assessment');
    },
    rtoRpoReadOnly: function rtoRpoReadOnly() {
      return !!this.getSettingValue('feature.nodes_inherit_rpo_rto_from_systems') || this.currentProject.locked;
    },
    showRtoRpo: function showRtoRpo() {
      return this.getSettingValue('feature.compliance_nodes_risk_assessment') && this.hasActivityTypeWithOptionalFields && this.isFieldInOptionalFields('rto_rpo');
    },
    matrix: function matrix() {
      var dots = prepareRiskDots(this.risk, this.currentPeriod, this.statusColor, this.inherentRisksEnabled, this.matrixInverted);
      return this.getMatrix(dots);
    },
    descriptionLabel: function descriptionLabel() {
      return getProjectDescriptionLabel(this.currentProject);
    },
    purposeLabel: function purposeLabel() {
      return getProjectPurposeLabel(this.currentProject);
    },
    statusLabel: function statusLabel() {
      return getProjectStatusLabel(this.currentProject);
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      this.getRtoFields();
      this.getRpoFields();
      this.getRisksData().then(function () {
        return _this.isRiskDataLoaded = true;
      });
    },
    confirmDelete: function confirmDelete() {
      this.deleteAlertMessage = this.translate.areYouSureYouWantToDelete + ' "' + this.data.title + '" ' + this.translate.andUnderlyingProcesses + '?';
      this.toggleDeleteAlert();
    },
    deleteNode: function deleteNode() {
      this.toggleDeleteAlert();
      this.$emit('deleteNode');
    },
    toggleDeleteAlert: function toggleDeleteAlert() {
      this.showDeleteAlert = !this.showDeleteAlert;
    },
    updateItem: function updateItem() {
      var _this2 = this;
      clearTimeout(this.updateTimer);
      this.showRetry = false;
      this.alertBody = '';
      this.retryAction = null;
      return updateNodeByProjectIdAndNodeId(this.projectId, this.data.id, this.data).then(function (_ref) {
        var questionnaire = _ref.questionnaire,
          activityType = _ref.activityType,
          values = _ref.values;
        if (questionnaire && questionnaire.id !== _this2.data.questionnaireId) {
          _this2.$emit('questionnaire', questionnaire);
          _this2.data.questionnaire = questionnaire;
          _this2.data.values = values;
        }
        if (activityType && _this2.data.activityType) {
          if (activityType.id !== _this2.data.activityType.id) {
            _this2.data.activityType = activityType;
          }
        } else {
          _this2.data.activityType = activityType;
        }
      }).catch(function () {
        _this2.showRetry = true;
        if (_this2.getSettingValue('feature.compliance_raci_all_can_edit')) {
          _this2.alertBody = _this2.translate.saveFailed;
        } else {
          _this2.alertBody = _this2.translate.saveFailedYouMustBeResponsibleOrAccountabl;
        }
        _this2.retryAction = function () {
          return _this2.updateItem();
        };
      });
    },
    dotHoverFunction: function dotHoverFunction(dot) {
      return dot.title;
    },
    isFieldReadyOnly: function isFieldReadyOnly(field) {
      var _this$currentProject$;
      return !!this.currentProject.locked || ((_this$currentProject$ = this.currentProject.lockedFields) === null || _this$currentProject$ === void 0 ? void 0 : _this$currentProject$.includes(field));
    }
  }
};