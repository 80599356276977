function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import * as DataAPI from '@/api/risma/data';
import { getRiskProjects as _getRiskProjects } from '@/api/risk/risk';
import { getProcessLibraryTitle } from '@/utils/TitleFromSetting';
import { getNodesForSelectBox } from '@/utils/format/Compliance';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { MODULES } from '@/constants/modules';
import * as Utils from '@/utils/Utils';
export var LinkedActivitiesForReportMixin = {
  data: function data() {
    return {
      risks: [],
      riskProjects: [],
      initiatives: [],
      incidents: [],
      controls: [],
      systems: [],
      dataProcessors: [],
      processLibraryNodes: [],
      complianceNodes: [],
      processTreeId: null,
      fieldsKeyLabel: 'linkedActivities.'
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useSettingsStore, ['isDataProcessorsEnabled', 'isSystemsEnabled'])), {}, {
    isControlsEnabled: function isControlsEnabled() {
      return this.getSettingValue('app.module_controls_enabled') === 1;
    },
    isIncidentsEnabled: function isIncidentsEnabled() {
      return this.getSettingValue('app.module_incidents_enabled') === 1;
    },
    activitiesSettings: function activitiesSettings() {
      var _ref;
      return _ref = {}, _defineProperty(_ref, MODULES.COMPLIANCE_IA_SYSTEM, this.isSystemsEnabled), _defineProperty(_ref, MODULES.COMPLIANCE_IA_PROCESSOR, this.isDataProcessorsEnabled), _defineProperty(_ref, MODULES.INCIDENTS, this.isIncidentsEnabled), _defineProperty(_ref, MODULES.CONTROLS, this.isControlsEnabled), _ref;
    },
    tableHeaders: function tableHeaders() {
      var _ref2;
      return _ref2 = {}, _defineProperty(_ref2, MODULES.RISK, this.$trans('Linked risks')), _defineProperty(_ref2, MODULES.CONTROLS, this.$trans('Linked controls')), _defineProperty(_ref2, MODULES.INCIDENTS, this.$trans('Linked incidents')), _defineProperty(_ref2, MODULES.COMPLIANCE_IA_SYSTEM, this.$trans('Linked systems')), _defineProperty(_ref2, MODULES.COMPLIANCE_IA_PROCESSOR, this.$trans('Linked Data processors')), _defineProperty(_ref2, MODULES.COMPLIANCE_PROCESS_TREE_NODE, 'Linked ' + getProcessLibraryTitle()), _defineProperty(_ref2, MODULES.COMPLIANCE, this.$trans('Linked compliance nodes')), _ref2;
    },
    linkedColumnsForPreview: function linkedColumnsForPreview() {
      var _this = this;
      return this.avaliableActivities.map(function (moduleName) {
        return _this.fieldsKeyLabel + moduleName;
      });
    },
    avaliableActivities: function avaliableActivities() {
      var _this2 = this;
      return Object.keys(this.tableHeaders).filter(function (module) {
        if (_this2.activitiesSettings[module] === undefined || _this2.activitiesSettings[module]) {
          return true;
        }
        return false;
      });
    },
    decoupleLinkedActivitiesFilterFields: function decoupleLinkedActivitiesFilterFields() {
      var _this3 = this;
      return this.avaliableActivities.map(function (moduleName) {
        return {
          key: _this3.fieldsKeyLabel + moduleName
        };
      });
    }
  }),
  methods: {
    getLinkedActivitiesRequests: function getLinkedActivitiesRequests() {
      var _this4 = this;
      var requests = this.avaliableActivities.map(function (module) {
        return _this4.getRequestByModule(module);
      });
      if (this.getSettingValue('feature.show_risk_links_with_levels') === 1) {
        requests.push(this.getRiskProjects());
      }
      return Promise.all(requests);
    },
    getLinkedActivitiesColumns: function getLinkedActivitiesColumns() {
      var _this5 = this;
      var columns = {};
      this.avaliableActivities.forEach(function (module) {
        columns["".concat(_this5.fieldsKeyLabel).concat(module)] = _this5.tableHeaders[module];
      });
      return columns;
    },
    getRequestByModule: function getRequestByModule(module) {
      switch (module) {
        case MODULES.RISK:
          return this.getRisks();
        case MODULES.CONTROLS:
          return this.getControls();
        case MODULES.INCIDENTS:
          return this.getIncidents();
        case MODULES.COMPLIANCE_IA_SYSTEM:
          return this.getInformationAssetsSystems();
        case MODULES.COMPLIANCE_IA_PROCESSOR:
          return this.getInformationAssetsProcessors();
        case MODULES.COMPLIANCE_PROCESS_TREE_NODE:
          return this.getNodeElementAllData();
        case MODULES.COMPLIANCE:
          return this.getComplianceNodes();
      }
    },
    getRisks: function getRisks() {
      var _this6 = this;
      return DataAPI.getRisks('?data[]=scores').then(function (_ref3) {
        var list = _ref3.list;
        return _this6.risks = list;
      });
    },
    getControls: function getControls() {
      var _this7 = this;
      return DataAPI.getControls().then(function (_ref4) {
        var list = _ref4.list;
        return _this7.controls = list;
      });
    },
    getInitiatives: function getInitiatives() {
      var _this8 = this;
      return DataAPI.getInitiatives().then(function (_ref5) {
        var list = _ref5.list;
        return _this8.initiatives = list;
      });
    },
    getNodeElementAllData: function getNodeElementAllData() {
      return DataAPI.getAllTrees().then(this.setIdToProccessTreeIdAndGetNodes);
    },
    setIdToProccessTreeIdAndGetNodes: function setIdToProccessTreeIdAndGetNodes(response) {
      var _this9 = this;
      if (response[0].id !== null) {
        this.processTreeId = response[0].id;
      }
      return DataAPI.getNodesByProcessTreeId(this.processTreeId).then(function (response) {
        var processTreeId = _this9.processTreeId;
        Utils.pushChildrenToParent(response, _this9.processLibraryNodes);
        _this9.processLibraryNodes = _this9.processLibraryNodes.map(function (node) {
          return _objectSpread(_objectSpread({}, node), {}, {
            processTreeId: processTreeId
          });
        });
      });
    },
    getRiskProjects: function getRiskProjects() {
      var _this10 = this;
      return _getRiskProjects().then(function (_ref6) {
        var list = _ref6.list;
        return _this10.riskProjects = list;
      });
    },
    getInformationAssetsSystems: function getInformationAssetsSystems() {
      var _this11 = this;
      return DataAPI.getInformationAssetsSystems().then(function (_ref7) {
        var list = _ref7.list;
        return _this11.systems = list;
      });
    },
    getInformationAssetsProcessors: function getInformationAssetsProcessors() {
      var _this12 = this;
      return DataAPI.getInformationAssetsProcessors().then(function (_ref8) {
        var list = _ref8.list;
        return _this12.dataProcessors = list;
      });
    },
    getIncidents: function getIncidents() {
      var _this13 = this;
      //doestn`t have analogue in dataapi
      return DataAPI.getIncidents().then(function (_ref9) {
        var list = _ref9.list;
        return _this13.incidents = list;
      });
    },
    getComplianceNodes: function getComplianceNodes() {
      var _this14 = this;
      return getNodesForSelectBox().then(function (nodes) {
        _this14.complianceNodes = nodes;
      });
    },
    getLinkedActivitiesFields: function getLinkedActivitiesFields(id, links) {
      var _this15 = this;
      var fields = {};
      this.avaliableActivities.forEach(function (module) {
        var field = _this15.prepareLinkedActivitiesField(id, links, module);
        fields[_this15.fieldsKeyLabel + module] = _objectSpread({
          field: field
        }, field);
      });
      return fields;
    },
    prepareLinkedActivitiesField: function prepareLinkedActivitiesField(id) {
      var links = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var key = arguments.length > 2 ? arguments[2] : undefined;
      var result = {
        activities: []
      };
      switch (key) {
        case MODULES.CONTROLS:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(links[MODULES.CONTROLS], this.controls, 'path')), {}, {
            id: id
          });
          break;
        case MODULES.RISK:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(links[MODULES.RISK], this.risks, 'rno', this.getRiskValues)), {}, {
            id: id
          });
          break;
        case MODULES.INCIDENTS:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(links[MODULES.INCIDENTS], this.incidents)), {}, {
            id: id
          });
          break;
        case MODULES.COMPLIANCE_IA_PROCESSOR:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(links[MODULES.COMPLIANCE_IA_PROCESSOR], this.dataProcessors)), {}, {
            id: id
          });
          break;
        case MODULES.COMPLIANCE_IA_SYSTEM:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(links[MODULES.COMPLIANCE_IA_SYSTEM], this.systems)), {}, {
            id: id
          });
          break;
        case MODULES.COMPLIANCE_PROCESS_TREE_NODE:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(links[MODULES.COMPLIANCE_PROCESS_TREE_NODE], this.processLibraryNodes)), {}, {
            id: id
          });
          break;
        case MODULES.COMPLIANCE:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(links[MODULES.COMPLIANCE], this.complianceNodes)), {}, {
            id: id
          });
          break;
      }
      return result;
    },
    getRiskValues: function getRiskValues(risk) {
      if (this.getSettingValue('feature.show_risk_links_with_levels') !== 1) {
        return '';
      }
      return DatatableRenderer.getRiskValues(risk, this.riskProjects);
    }
  }
};