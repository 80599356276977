import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_switch = _resolveComponent("risma-switch");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("p", null, [_createVNode(_component_risma_switch, {
    modelValue: $data.currentState,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $data.currentState = $event;
    }), $options.showUpdatedSwitchState],
    options: $data.switchOptions2,
    "is-labels-show": true
  }, null, 8 /* PROPS */, ["modelValue", "options", "onUpdate:modelValue"])]), _createElementVNode("p", null, [_createVNode(_component_risma_switch, {
    modelValue: $data.currentState,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
      return $data.currentState = $event;
    }), $options.showUpdatedSwitchState],
    options: $data.switchOptions2,
    "is-labels-show": true,
    disabled: true
  }, null, 8 /* PROPS */, ["modelValue", "options", "onUpdate:modelValue"])])]);
}