export function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(',')[1]);
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  var bb = new Blob([ab], {
    type: mimeString
  });
  return bb;
}
export function loadBlobAsURI(blob, fn) {
  var reader = new FileReader();
  reader.onload = function (e) {
    fn(e.target.result);
  };
  reader.readAsDataURL(blob);
}