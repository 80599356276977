import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'KpiInsights',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    insights: {
      type: Array,
      required: true
    }
  }
};