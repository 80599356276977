function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getProcessLibraryTitle } from '@/utils/TitleFromSetting';
import { getInformationAssetsTitle } from '@/utils/InformationAssets';
export var MODULES = {
  dataFlow: 'data_flow',
  gapAnalysis: 'gap_analysis',
  RISK: 'risk',
  RISK_ASSESSMENT: 'risk_assessment',
  COMPLIANCE_PROCESS_TREE: 'compliance_process_tree',
  COMPLIANCE_INFORMATION_ASSETS: 'information_assets',
  REVIEW: 'review',
  COMPLIANCE: 'compliance',
  COMPLIANCE_QUESTION: 'compliance_question',
  EXECUTION: 'execution',
  AWARENESS: 'awareness',
  INCIDENTS: 'incidents',
  COMPLIANCE_IA_SYSTEM: 'compliance_ia_system',
  CONTROL_COMPLETION: 'control-completion',
  SCORING_ZAXIS: 'scoring-zaxis',
  COMPLIANCE_IA_PROCESSOR: 'compliance_ia_processor',
  COMPLIANCE_PROCESS_TREE_NODE: 'compliance_process_tree_node',
  RISK_PROJECT_PERIOD: 'risk_project_period',
  COMPLIANCE_GAP: 'compliance_gap',
  COMPLIANCE_GAP_SCHEMA: 'compliance_gap_schema',
  COMPLIANCE_IA_PROCESSOR_DPM: 'compliance_ia_processor_dpm',
  COMPLIANCE_IA_CONTROLLER: 'compliance_ia_controller',
  CONTROLS: 'control',
  MOTHER_CONTROLS: 'mother_controls',
  CONTRACTS: 'contracts',
  COMPANY_CONTACT: 'company_contact',
  COMPANY: 'company',
  USERS: 'users',
  COMPLIANCE_QUESTIONNAIRE: 'compliance_questionnaire',
  COMPLIANCE_QUESTIONS: 'compliance_questions'
};
export var getModuleTitles = function getModuleTitles() {
  var _ref;
  return _ref = {}, _defineProperty(_ref, MODULES.COMPLIANCE, 'Compliance'), _defineProperty(_ref, MODULES.COMPLIANCE_IA_CONTROLLER, 'Data controllers'), _defineProperty(_ref, MODULES.COMPLIANCE_IA_PROCESSOR, 'Data processors'), _defineProperty(_ref, MODULES.COMPLIANCE_IA_SYSTEM, 'Systems'), _defineProperty(_ref, MODULES.COMPLIANCE_PROCESS_TREE_NODE, getProcessLibraryTitle()), _defineProperty(_ref, MODULES.CONTROLS, 'Controls'), _defineProperty(_ref, MODULES.EXECUTION, 'Actions'), _defineProperty(_ref, MODULES.RISK, 'Risks'), _defineProperty(_ref, MODULES.INCIDENTS, 'Incidents'), _defineProperty(_ref, MODULES.COMPLIANCE_INFORMATION_ASSETS, getInformationAssetsTitle()), _defineProperty(_ref, MODULES.CONTRACTS, 'Contracts'), _defineProperty(_ref, MODULES.AWARENESS, 'Awareness'), _defineProperty(_ref, MODULES.COMPLIANCE_QUESTION, 'Audits'), _defineProperty(_ref, MODULES.RISK_ASSESSMENT, 'Risk assessment'), _defineProperty(_ref, MODULES.COMPANY, 'Company'), _ref;
};
export var itemTitleByModule = function itemTitleByModule($trans) {
  var _ref2;
  return _ref2 = {}, _defineProperty(_ref2, MODULES.COMPLIANCE, $trans('Compliance')), _defineProperty(_ref2, MODULES.COMPLIANCE_IA_CONTROLLER, $trans('Data controller')), _defineProperty(_ref2, MODULES.COMPLIANCE_IA_PROCESSOR, $trans('Data processor')), _defineProperty(_ref2, MODULES.COMPLIANCE_IA_SYSTEM, $trans('System')), _defineProperty(_ref2, MODULES.COMPLIANCE_PROCESS_TREE_NODE, $trans('Process library node')), _defineProperty(_ref2, MODULES.CONTROLS, $trans('Control')), _defineProperty(_ref2, MODULES.EXECUTION, $trans('Action')), _defineProperty(_ref2, MODULES.RISK, $trans('Risk')), _defineProperty(_ref2, MODULES.INCIDENTS, $trans('Incident')), _defineProperty(_ref2, MODULES.COMPLIANCE_INFORMATION_ASSETS, $trans('Information asset')), _defineProperty(_ref2, MODULES.CONTRACTS, $trans('Contract')), _defineProperty(_ref2, MODULES.AWARENESS, $trans('Awareness')), _defineProperty(_ref2, MODULES.COMPLIANCE_QUESTION, $trans('Audit')), _defineProperty(_ref2, MODULES.RISK_ASSESSMENT, $trans('Risk assessment')), _defineProperty(_ref2, MODULES.COMPANY, $trans('Company')), _ref2;
};