export function disableScrolling(position) {
  disableScrollingCounter.increment();
  //to disable scrolling (safari fix, hidden is not working there)
  document.body.style.overflow = 'hidden';
  document.body.style.position = 'fixed';
  document.body.style.top = "-".concat(position, "px");
  document.body.style.width = '100%';
}
export function enableScrolling(position) {
  disableScrollingCounter.decrement();
  if (disableScrollingCounter.value() === 0) {
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('position');
    document.body.style.removeProperty('top');
    document.body.style.removeProperty('width');
    window.scrollTo(0, position);
  }
}
export function getScrollPosition() {
  return window.pageYOffset;
}
var disableScrollingCounter = function () {
  var counter = 0;
  function changeBy(val) {
    counter += val;
  }
  return {
    increment: function increment() {
      changeBy(1);
    },
    decrement: function decrement() {
      changeBy(-1);
    },
    value: function value() {
      return counter;
    }
  };
}();