import i18n from '@/i18n';
export var getTranslate = {
  WelcomePage: function WelcomePage() {
    return {
      logInToYourAccount: i18n.t('Log in to your account'),
      singleSignon: i18n.t('Single sign-on'),
      orLogInWithUsername: i18n.t('Or log in with username'),
      username: i18n.t('Username'),
      enterUsername: i18n.t('Enter username'),
      password: i18n.t('Password'),
      forgotYourPassword: i18n.t('Forgot your password?'),
      enterPassword: i18n.t('Enter password'),
      logIn: i18n.t('Log in'),
      privacyPolicy: i18n.t('Privacy Policy'),
      news: i18n.t('News'),
      csrdSolution: i18n.t('CSRD solution'),
      rightColumnSecondLineText: i18n.t('Break down the complexity. Implement a structured process for compliant reporting towards the CSRD disclosure requirements.'),
      disclaimer: i18n.t('Disclaimer'),
      rismasSystemsAndTheContentsIncorporatedIn: i18n.t('RISMA\'s systems, and the contents incorporated into RISMA\'s systems, are protected by copyright.<br/><br/>By logging into and using RISMA\'s systems, you agree to have been notified that it is illegal - and that you are unauthorised - to sell, pass on, sublicense, distribute, reproduce or otherwise multiply RISMA\'s systems and the contents incorporated into RISMA\'s systems.'),
      clickAClasscursorpointerTargetblank: i18n.t('Click <a class=\'cursor-pointer\' target=\'_blank\' rel=\'noopener noreferrer\' href=\'https://support.rismasystems.com/new-csrd-solution\'><u>HERE</u></a> if you want to know more')
    };
  }
};