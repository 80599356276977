import i18n from '@/i18n';
export var getTranslate = {
  QuestionnaireSelection: function QuestionnaireSelection() {
    return {
      resetFilter: i18n.t('Reset filter'),
      selectQuestionnaires: i18n.t('Select questionnaires'),
      selectAQuestionnaire: i18n.t('Select a questionnaire'),
      selectNodes: i18n.t('Select nodes'),
      selectOrganisation: i18n.t('Select organisations'),
      noteThatUnderlyingNodesAreAlsoIncludedInTh: i18n.t('Note that underlying nodes are also included in the report'),
      includeDataControllersAndDataProcessors: i18n.t('Include data controllers and data processors'),
      include: i18n.t('Include'),
      dataFlow: i18n.t('Data flow'),
      raciEmails: i18n.t('RACI e-mails'),
      includeQuestionHelpText: i18n.t('Include question help text'),
      createReport: i18n.t('Create report'),
      organisations: i18n.t('Organisations')
    };
  },
  QuestionSelection: function QuestionSelection() {
    return {
      selectQuestions: i18n.t('Select questions'),
      clickToShowhide: i18n.t('Click to show/hide'),
      onlyDisplayAnsweredQuestions: i18n.t('Only display answered questions'),
      noneSelected: i18n.t('None selected'),
      selectAllQuestions: i18n.t('Select all questions'),
      selectDefaultQuestions: i18n.t('Select default questions'),
      selectWhichQuestionsBelowThatYouWouldLikeToIncludeInArticle30: i18n.t('Select which questions below that you would like to include in the Article 30 report. Questions marked with bold is predefined to be included in the Article 30 report.'),
      selectWhichQuestionsBelowThatYouWouldLikeToIncludeInQuestionnaire: i18n.t('Select which questions below that you would like to include in the questionnaire report.'),
      whenYouAreDoneClickTheCreateReportButtonToShowTheReport: i18n.t('When you are done click the Create report button to show the report.')
    };
  }
};