function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import { getStatusOverviewControlNew } from '@/api/controls/controls';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import CollapsiblePanel from '@/components/Molecules/CollapsiblePanel/CollapsiblePanel';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import StaticTable from '@/components/Molecules/StaticTable';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import { StatusOverviewMixin } from '@/components/Pages/Controls/StatusOverviewMixin';
import { DateFormats } from '@/constants/DateFormats';
import { ControlsSettings, ChartsKey } from '@/constants/controls/ControlsDashboard';
import { formatTimeToDateFormat, getFortnightDateRange } from '@/utils/date';
export default {
  name: 'ComplianceStatusOverview',
  description: "This component displays a list of GDPR compliance projects RismaDoughtnutCharts.\n        It is utilized on the Compliance Overview Page",
  components: {
    CollapsiblePanel: CollapsiblePanel,
    RismaTitle: RismaTitle,
    RismaDateRangePicker: RismaDateRangePicker,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    StaticTable: StaticTable,
    FeatherIcon: FeatherIcon,
    RismaDoughnutChart: RismaDoughnutChart
  },
  mixins: [StatusOverviewMixin],
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'ID of current project'
    },
    tagName: {
      type: String,
      default: 'ISMS',
      required: true,
      note: 'tag name'
    }
  },
  data: function data() {
    return {
      overviewStorageKey: 'gdpr-status',
      isChartsLoaded: false,
      pieCharts: [],
      dateRange: getFortnightDateRange(DateFormats.DATE_FORMAT),
      pieChartsStaticTable: null,
      translate: {
        statusOverview: this.$trans('Status overview'),
        choosePeriod: this.$trans('Choose period'),
        noControlsFound: this.$trans('No controls found'),
        controls: this.$trans('Controls')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useTagsStore, ['tags'])), {}, {
    tag: function tag() {
      var tagNameInLowerCase = this.tagName.toLowerCase();
      return this.tags.find(function (tag) {
        return tag.tag.toLowerCase() === tagNameInLowerCase;
      });
    },
    note: function note() {
      return this.$trans("Only controls with the tag \"".concat(this.tag.tag, "\" are included on this dashboard"));
    },
    chartsData: function chartsData() {
      var _this = this;
      return this.pieCharts.map(function (chart) {
        var isValueExist = chart.data.some(function (_ref) {
          var value = _ref.value;
          return value > 0;
        });
        var _this$getChartDataset = _this.getChartDataset(chart.data),
          data = _this$getChartDataset.data,
          labels = _this$getChartDataset.labels,
          controls = _this$getChartDataset.controls;
        return {
          title: chart.title,
          labels: labels,
          datasets: [{
            backgroundColor: chart.colors,
            data: data
          }],
          isValueExist: isValueExist,
          controls: controls
        };
      });
    }
  }),
  mounted: function mounted() {
    this.setValuesFromStore();
    this.getStatusOverviewDashboard();
  },
  methods: {
    getStatusOverviewDashboard: function getStatusOverviewDashboard() {
      if (this.tag) {
        this.getStatusOverviewControl();
      } else {
        console.log('Tag \'' + this.tagName + '\' not found. Not showing status overview!'); // eslint-disable-line
      }
    },
    getStatusOverviewControl: function getStatusOverviewControl() {
      var _this2 = this;
      this.isChartsLoaded = false;
      var searchData = {
        dateStart: this.dateRange.startDate,
        dateEnd: this.dateRange.endDate,
        organisationIds: [],
        processIds: [],
        tagIds: this.tag.id
      };
      this.pieCharts = [];
      return getStatusOverviewControlNew(searchData).then(function (response) {
        var charts = response.charts;
        [ChartsKey.CONTROL_STATUS, ChartsKey.OVERALL_REVIEWS, ChartsKey.OVERALL_STATUS, ChartsKey.OVERALL_COMPLETED].forEach(function (item, index) {
          var colors = ControlsSettings.charts[item].colors || charts[index].data.map(function (chart) {
            return chart.color;
          });
          _this2.pieCharts.push(_objectSpread(_objectSpread({}, charts[index]), {}, {
            colors: colors
          }));
        });
        _this2.isChartsLoaded = true;
      });
    },
    changeDatePicker: function changeDatePicker(event) {
      if (event) {
        this.dateRange.startDate = event.startDate;
        this.dateRange.endDate = event.endDate;
        localStorage.setItem(this.overviewStorageKey, JSON.stringify(this.dateRange));
        this.getStatusOverviewControl();
      }
    },
    setValuesFromStore: function setValuesFromStore() {
      var storageValue = localStorage.getItem(this.overviewStorageKey);
      if (!storageValue) return;
      var _JSON$parse = JSON.parse(storageValue),
        startDate = _JSON$parse.startDate,
        endDate = _JSON$parse.endDate;
      this.dateRange = {
        startDate: startDate ? formatTimeToDateFormat(startDate) : this.dateRange.startDate,
        endDate: endDate ? formatTimeToDateFormat(endDate) : this.dateRange.endDate
      };
    },
    clickSlice: function clickSlice(event, chart) {
      this.pieChartsStaticTable = _objectSpread({
        title: chart.title
      }, this.prepareTableInfoForPie(chart.controls[event]));
    },
    hideTable: function hideTable() {
      this.pieChartsStaticTable = null;
    },
    getChartDataset: function getChartDataset(chart) {
      return chart.reduce(function (acc, item) {
        acc.data.push(item.value);
        acc.labels.push(item.title);
        acc.controls.push(item.controls);
        return acc;
      }, {
        data: [],
        labels: [],
        controls: []
      });
    }
  }
};