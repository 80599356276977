import AttachmentItem from '@/components/Atoms/Attachment/AttachmentItem';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { ATTACHMENT_MODAL_MODES } from '@/constants/Attachments';
export default {
  name: 'AttachmentDropdown',
  components: {
    AttachmentItem: AttachmentItem,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle
  },
  props: {
    filesList: {
      type: Array,
      required: true,
      note: 'List of files'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If attachments are readonly and can be downloaded'
    },
    hasVersionButtons: {
      type: Boolean,
      required: false,
      default: false,
      note: 'if item has version buttons (process library should have them)'
    },
    addLinkButtonEnabled: {
      type: Boolean,
      required: true,
      note: 'if Add links button enabled'
    },
    editFileEnabled: {
      type: Boolean,
      required: true,
      note: 'show edit button on files'
    }
  },
  emits: ['triggerModal'],
  data: function data() {
    return {
      translate: {
        addAttachment: this.$trans('Add attachment'),
        addLink: this.$trans('Add Link')
      },
      ATTACHMENT_MODAL_MODES: ATTACHMENT_MODAL_MODES
    };
  }
};