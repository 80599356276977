import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_contract_generic_table = _resolveComponent("contract-generic-table");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_main_well, {
    class: "mb-4"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.currentContract,
        type: "big",
        class: "mr-4"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_contract_generic_table, {
        "input-data": [$props.data],
        "hidden-columns": $data.hiddenColumns,
        "workflow-statuses": $data.workflowStatuses
      }, null, 8 /* PROPS */, ["input-data", "hidden-columns", "workflow-statuses"])];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.contractHistory,
        type: "big",
        class: "mr-4"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_contract_generic_table, {
        "input-data": $options.historyData,
        "hidden-columns": $data.hiddenColumns,
        "workflow-statuses": $data.workflowStatuses,
        "no-data-message": $data.translate.theContractHasNotBeenRenewedAndHoldsTheref
      }, null, 8 /* PROPS */, ["input-data", "hidden-columns", "workflow-statuses", "no-data-message"])];
    }),
    _: 1 /* STABLE */
  })]);
}