function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import compliance from '@/routes/compliance';
import control from '@/routes/control';
import incident from '@/routes/incident';
import risk from '@/routes/risk';
import actions from '@/routes/actions';
import contracts from '@/routes/contracts';
import companies from '@/routes/companies';
import users from '@/routes/users';
import userPassword from '@/routes/userPassword';
import performanceReport from '@/routes/performanceReport';
import notFound from '@/routes/notFound';
import { createWebHistory, createRouter } from 'vue-router';
import login from '@/routes/login';
import dev from 'risma-dev-router';
import getMypageRoute from '@/routes/mypage';
import getAwarenessRoutes from '@/routes/awareness';
import getDpmRoutes from '@/routes/dpm';
import getSettingsRoutes from '@/routes/settings';
import { buildMenu } from '@/menus';
import { loginGuard, userRightsGuard } from '@/routes/auth';
import { jsBundleGuard } from '@/routes/jsBundle';
import { refreshExpiredCaches } from '@/routes/storeCache';
import emitter from 'tiny-emitter/instance';
var scrollBehavior = function scrollBehavior(to, from, savedPosition) {
  return new Promise(function (resolve) {
    emitter.once('emit-scroll', function () {
      if (to.hash && document.querySelector(to.hash)) {
        resolve({
          selector: to.hash
        });
      } else if (savedPosition) {
        resolve(savedPosition);
      } else {
        resolve({
          x: 0,
          y: 0
        });
      }
    });
  });
};
var router;
export function getRouter() {
  if (router) {
    return router;
  }
  var complianceRoutes = compliance();
  var controlRoutes = control();
  var incidentRoutes = incident();
  var riskRoutes = risk();
  var actionsRoutes = actions();
  var contractRoutes = contracts();
  var settingsRoutes = getSettingsRoutes();
  var devRoutes = dev();
  var userPasswordRoutes = userPassword();
  var notFoundRoutes = notFound();
  var mypageRoute = getMypageRoute();
  var awarenessRoutes = getAwarenessRoutes();
  var dpmRoutes = getDpmRoutes();
  var loginRoutes = login();
  var performanceReportRoute = performanceReport();
  var companiesRoutes = companies();
  var usersRoutes = users();
  var routes = [].concat(_toConsumableArray(mypageRoute), _toConsumableArray(complianceRoutes), _toConsumableArray(controlRoutes), _toConsumableArray(incidentRoutes), _toConsumableArray(riskRoutes), _toConsumableArray(actionsRoutes), _toConsumableArray(contractRoutes), _toConsumableArray(settingsRoutes), _toConsumableArray(awarenessRoutes), _toConsumableArray(dpmRoutes), _toConsumableArray(devRoutes), _toConsumableArray(userPasswordRoutes), _toConsumableArray(performanceReportRoute), _toConsumableArray(notFoundRoutes), _toConsumableArray(loginRoutes), _toConsumableArray(companiesRoutes), _toConsumableArray(usersRoutes));
  router = createRouter({
    mode: 'history',
    fallback: false,
    history: createWebHistory(),
    routes: routes,
    scrollBehavior: scrollBehavior
  });
  router.isKnownRoute = function (url) {
    var matchResult = router.resolve(url);
    if (!matchResult.matched || matchResult.matched.length === 0) {
      return false;
    }
    return matchResult.matched[0].path !== '*';
  };
  router.beforeEach(function (to, from, next) {
    if (router.isKnownRoute(Object.assign({}, to))) {
      next();
    } else if (window.location.pathname !== to.path) {
      window.location.href = to.fullPath;
    } else {
      next();
    }
  });
  router.beforeEach(jsBundleGuard);
  router.beforeEach(loginGuard);
  router.beforeEach(userRightsGuard);
  router.beforeEach(function (to, from, next) {
    refreshExpiredCaches().then(function () {
      return next();
    });
  });
  router.afterEach(function (to) {
    var pathSegments = to.fullPath.split('/');
    var firstSegment = pathSegments[1] || '';
    var navName = 'business';
    if (firstSegment === 'controls') {
      navName = 'controls';
    } else if (firstSegment === 'controls2') {
      navName = 'controls';
    } else if (firstSegment === 'execution') {
      navName = 'actions';
    } else if (firstSegment === 'actions') {
      navName = 'actions';
    } else if (firstSegment === 'contracts') {
      navName = 'contracts';
    } else if (firstSegment === 'risk') {
      navName = 'risk';
    } else if (firstSegment === 'risk2') {
      navName = 'risks';
    } else if (firstSegment === 'compliance') {
      navName = 'compliance';
    } else if (firstSegment === 'incident') {
      navName = 'incidents';
    } else if (firstSegment === 'business2') {
      navName = 'business';
    } else if (firstSegment === 'awareness') {
      navName = 'awareness';
    } else if (firstSegment === 'dpm') {
      navName = 'compliance';
    }
    window.document.title = 'RISMA' + navName;
  });
  router.afterEach(function () {
    buildMenu(router.currentRoute.path);
  });
  return router;
}