import { GradientTypes } from '@/constants/admin/RiskMatrixSettings';
export default {
  name: 'AxisLabel',
  description: 'The description and labels for a risk matrix vue component',
  props: {
    labels: {
      note: 'Contains an array with Strings',
      type: Array,
      required: true
    },
    axis: {
      note: 'Contains vertical or horizontal to decide direction of the component',
      type: String,
      required: true
    },
    mainLabel: {
      note: 'Contains the main label for the axis',
      type: String,
      required: true
    },
    gridBoxSize: {
      type: Number,
      required: true,
      note: 'size of grid boxes'
    },
    showArrows: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Show x and y arrows?'
    },
    arrowWidth: {
      type: [Number, String],
      default: 250
    },
    axisWidth: {
      type: Number,
      required: false,
      default: null,
      note: 'Axis width directly used to set the width of the entire vertical axis'
    }
  },
  data: function data() {
    return {
      GradientTypes: GradientTypes
    };
  },
  computed: {
    labelWidth: function labelWidth() {
      return {
        width: "".concat(this.gridBoxSize, "px")
      };
    },
    x1: function x1() {
      return (this.axisWidth - this.arrowWidth) / 2;
    },
    x2: function x2() {
      return this.axisWidth - this.x1;
    }
  }
};