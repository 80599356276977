import { Http } from '../index';
import { handleRequestResponse, objectToQueryString } from '../httpUtils';
import { useOrganisationsStore } from '@/Store/organisationsStore';
export function getAllOrganisations() {
  return handleRequestResponse(Http.get('/admin/organisations/organisation'));
}
export function createOrganisation(params) {
  return handleRequestResponse(Http.post('/admin/organisations/organisation', params)).then(function (data) {
    useOrganisationsStore().add(data);
    return data;
  });
}
export function updateOrganisational(organisationId, params) {
  return handleRequestResponse(Http.patch("/admin/organisations/organisation/".concat(organisationId), params)).then(function (data) {
    useOrganisationsStore().update(data);
    return data;
  });
}
export function deleteOrganisation(organisationId) {
  return handleRequestResponse(Http.delete("/admin/organisations/organisation/".concat(organisationId))).then(function (data) {
    useOrganisationsStore().delete(data);
    return data;
  });
}
export function getOrganisationalGroups() {
  return handleRequestResponse(Http.get('/admin/organisations/group'));
}
export function addOrganisationalGroup(params) {
  return handleRequestResponse(Http.post('/admin/organisations/group', params));
}
export function deleteOrganisationalGroup(groupId) {
  return handleRequestResponse(Http.delete("/admin/organisations/group/".concat(groupId)));
}
export function getActivitiesInfo(params) {
  return handleRequestResponse(Http.get("/find/search?".concat(params)));
}
export function getOrganisationActivities(organisationId) {
  var modules = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'all';
  var modulesUrl = '';
  if (Array.isArray(modules)) {
    modulesUrl = objectToQueryString({
      modules: modules
    });
  } else {
    modulesUrl = "modules[]=".concat(modules);
  }
  return handleRequestResponse(Http.get("/find/organisationactivities?organisationId=".concat(organisationId, "&").concat(modulesUrl)));
}