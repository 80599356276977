import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_solutions_selector = _resolveComponent("solutions-selector");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.createAction,
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    "overflow-visible": true,
    "accept-on-enter": !$data.isRismaSelectOpen,
    onAccept: $options.handleOnSubmit,
    onDismiss: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [!!$data.errorMessages ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.errorMessages), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        modelValue: $data.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.title = $event;
        }),
        title: $data.translate.title,
        "focus-on-mount": true,
        "pre-text": $data.title,
        placeholder: $data.translate.enterTitle,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "pre-text", "placeholder"])]), _createVNode(_component_solutions_selector, {
        "selected-options": $data.selectedSolutions,
        onSelected: _cache[1] || (_cache[1] = function ($event) {
          return $data.selectedSolutions = $event;
        }),
        onOpen: _cache[2] || (_cache[2] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[3] || (_cache[3] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["selected-options"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "accept-on-enter", "onAccept"]);
}