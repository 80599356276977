function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getTranslate } from './translate';
import cloneDeep from 'lodash/cloneDeep';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import InputField from '@/components/Atoms/Inputs/InputField';
import ControlsAccountsTree from '@/components/Molecules/Admin/ControlsAccountsTree';
import Alert from '@/components/Molecules/Modal/Alert';
import Modal from '@/components/Molecules/Modal/Modal';
import * as Utils from '@/utils/Utils';
export default {
  name: 'AdminControlsWell',
  description: 'Component for AdminControls. One of the sections(accounts, processes, risks, control risk types)',
  token: '<admin-controls-well :title="" :items="[]" :loading="true"></admin-controls-well>',
  components: {
    ControlsAccountsTree: ControlsAccountsTree,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    Modal: Modal,
    FeatherIcon: FeatherIcon,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    InputField: InputField,
    Alert: Alert
  },
  props: {
    title: {
      type: String,
      required: true,
      note: 'Component title displayed in header'
    },
    items: {
      type: Array,
      required: true,
      note: 'List of items'
    },
    loading: {
      type: Boolean,
      required: true,
      note: 'If items being loading'
    },
    riskTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of risk types'
    },
    isAccountsWell: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If component is for accounts'
    }
  },
  emits: ['deleteItem', 'editItem', 'createItem'],
  data: function data() {
    return {
      itemToDelete: null,
      showConfirmationAlert: false,
      showModal: false,
      modalType: 'edit',
      defaultItem: {
        title: '',
        id: null,
        riskTypeId: null,
        accountParentId: null
      },
      modalItem: {},
      translate: getTranslate['AdminControlsWell']()
    };
  },
  computed: {
    accountsForSingleSelect: function accountsForSingleSelect() {
      var result = [];
      var copy = JSON.parse(JSON.stringify(this.items));
      var idsToFilter = this.modalItem.id ? [this.modalItem.id] : [];
      copy.forEach(function (item) {
        result.push(item);
        if (item.children) {
          Utils.pushChildrenToParent(item, result, Utils.addHyphensBeforeTitle, -1);
        }
      });
      result = result.filter(function (item) {
        if (idsToFilter.includes(item.id)) {
          var _item$children;
          item === null || item === void 0 || (_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.forEach(function (child) {
            idsToFilter.push(child.id);
          });
          return false;
        }
        return true;
      });
      result = result.map(function (item) {
        return {
          value: item.id,
          label: item.title
        };
      });
      result = [{
        value: -1,
        label: this.translate.noParent
      }].concat(_toConsumableArray(result));
      return result;
    },
    riskTypesFofSingleSelect: function riskTypesFofSingleSelect() {
      return [{
        value: -1,
        label: this.translate.noType
      }].concat(_toConsumableArray(this.riskTypes));
    },
    deleteHeader: function deleteHeader() {
      return "".concat(this.translate.delete, " ").concat(this.title, " ").concat(this.translate.typeLow, "?");
    },
    deleteMessage: function deleteMessage() {
      return "".concat(this.translate.areYouSureYouWantToDeleteThis, " ").concat(this.title, " ").concat(this.translate.typeLow, "?");
    },
    modalHeader: function modalHeader() {
      var sufix = this.modalType === 'edit' ? this.translate.edit : this.translate.create;
      return "".concat(sufix, " ").concat(this.title);
    }
  },
  methods: {
    closeModal: function closeModal() {
      this.showModal = false;
      this.modalItem = {};
    },
    openCreateForm: function openCreateForm() {
      this.modalItem = cloneDeep(this.defaultItem);
      this.modalType = 'create';
      this.showModal = true;
    },
    handleModalAccept: function handleModalAccept() {
      var data = {
        title: this.modalItem.title,
        riskTypeId: this.modalItem.riskTypeId !== -1 ? this.modalItem.riskTypeId : null,
        parentId: this.modalItem.accountParentId !== -1 ? this.modalItem.accountParentId : null
      };
      if (this.modalType === 'edit') data.id = this.modalItem.id;
      this.$emit(this.modalType === 'edit' ? 'editItem' : 'createItem', data);
      this.closeModal();
    },
    openEditItem: function openEditItem(item) {
      this.modalItem = cloneDeep(item);
      this.modalType = 'edit';
      this.showModal = true;
    },
    onDeleteConfirm: function onDeleteConfirm() {
      this.$emit('deleteItem', this.itemToDelete);
      this.showConfirmationAlert = false;
    },
    deleteItem: function deleteItem(data) {
      this.itemToDelete = data;
      this.showConfirmationAlert = true;
    }
  }
};