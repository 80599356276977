import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import PreviewRiskViewMixin from './PreviewRiskViewMixin';
import * as Utils from '@/utils/Utils';
import { getOrganisationsFromIds } from '@/utils/format/objectsFromIds';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
export default {
  name: 'PreviewRiskCompact',
  components: {
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  mixins: [PreviewRiskViewMixin],
  props: {
    risk: {
      type: Object,
      default: function _default() {}
    },
    organisations: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['updateFromPreviewMode', 'deleteItem'],
  data: function data() {
    return {
      translate: {
        approved: this.$trans('Approved'),
        notApproved: this.$trans('Not approved')
      }
    };
  },
  computed: {
    organisationsList: function organisationsList() {
      var result = [];
      if (this.risk.organisationIds && this.organisations) {
        getOrganisationsFromIds(this.risk.organisationIds, this.organisations).forEach(function (organisation) {
          return result.push(organisation.visible_name);
        });
      }
      return result.join(', ');
    },
    hoverTitle: function hoverTitle() {
      return DatatableRenderer.getItemHoverTitle(this.risk);
    }
  },
  methods: {
    truncateHtml: function truncateHtml(text, maxSymbols) {
      return Utils.truncateHtml(text, maxSymbols, {
        html: true,
        maxLines: 3
      });
    }
  }
};