import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, Transition as _Transition, normalizeStyle as _normalizeStyle, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-11d4186e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "dep-relations flex flex-row h-full"
};
var _hoisted_2 = {
  key: 0,
  class: "w-80 bg-white relations-wrapper border-2 border-solid border-gray-250 shadow-3xl"
};
var _hoisted_3 = {
  class: "relations-tabs flex flex-row relative top-0.13rem p-2 pb-0"
};
var _hoisted_4 = {
  class: "slot-data mt-6 mx-2 p-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_tab_list = _resolveComponent("tab-list");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(['expand-icon pt-10 px-2 right-0', $setup.isExpanded ? 'static' : 'absolute'])
  }, [_createElementVNode("button", {
    class: "rounded-full p-1 bg-gray-250 border-2 border-transparent hover:border-gray-510 active:border-gray-510 focus:outline-none shadow-box-sm",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.changeViewMode && $options.changeViewMode.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: $options.IconClass,
    class: _normalizeClass(['text-blue-750 inline-block stroke-2'])
  }, null, 8 /* PROPS */, ["icon"])])], 2 /* CLASS */), _createVNode(_Transition, {
    style: _normalizeStyle($options.transitionVars),
    name: "slide"
  }, {
    default: _withCtx(function () {
      return [$setup.isExpanded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$setup.isExpanded ? (_openBlock(), _createBlock(_component_tab_list, {
        key: 0,
        class: "relations-tabs flex flex-row relative top-0.13rem p-2 pb-0 w-full",
        tabs: $options.tabsTranslated,
        "local-storage-key": $props.localStorageKey + $data.currentTabKey,
        onUpdate: _cache[1] || (_cache[1] = function ($event) {
          return $data.currentTab = $event;
        })
      }, null, 8 /* PROPS */, ["tabs", "local-storage-key"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, $data.currentTab, {
        class: "m-2"
      }, undefined, true)])])) : _createCommentVNode("v-if", true)];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["style"])]);
}