function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getProcessLibraryTitle } from '@/utils/TitleFromSetting';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { MODULES } from '@/constants/modules';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
export var getCustomIAModules = function getCustomIAModules() {
  if (!useSettingsStore().isIACustomEnabled) {
    return {};
  }
  var customModules = useActivityTypesStore().complianceIACustomActivityTypes;
  var modulesToExport = {};
  customModules.forEach(function (module) {
    modulesToExport['information-assets-' + module.id] = {
      name: module.label,
      objType: ObjectTypes.INFORMATION_ASSET_LIMITED,
      activityTypeId: module.id,
      defaultLanguage: true
    };
  });
  return modulesToExport;
};
export var getUserActivityModules = function getUserActivityModules() {
  var _ref;
  return _ref = {}, _defineProperty(_ref, MODULES.COMPLIANCE, {
    name: 'Compliance',
    objType: ObjectTypes.COMPLIANCE_NODE_LIMITED
  }), _defineProperty(_ref, MODULES.COMPLIANCE_IA_CONTROLLER, {
    name: 'Data controllers',
    objType: ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER
  }), _defineProperty(_ref, MODULES.COMPLIANCE_IA_PROCESSOR, {
    name: 'Data processors',
    objType: ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR
  }), _defineProperty(_ref, MODULES.COMPLIANCE_IA_SYSTEM, {
    name: 'Systems',
    objType: ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM
  }), _defineProperty(_ref, MODULES.COMPLIANCE_PROCESS_TREE_NODE, {
    name: getProcessLibraryTitle(),
    objType: ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE_LIMITED,
    defaultLanguage: true
  }), _defineProperty(_ref, MODULES.CONTROLS, {
    name: 'Controls',
    objType: ObjectTypes.CONTROLS_CONTROL_LIMITED
  }), _defineProperty(_ref, MODULES.EXECUTION, {
    name: 'Actions',
    objType: ObjectTypes.EXECUTION_INITIATIVE_LIMITED
  }), _defineProperty(_ref, MODULES.RISK, {
    name: 'Risk',
    objType: ObjectTypes.RISK_RISK_LIMITED
  }), _defineProperty(_ref, MODULES.CONTRACTS, {
    name: 'Contracts',
    objType: ObjectTypes.CONTRACT_LIMITED
  }), _defineProperty(_ref, MODULES.INCIDENTS, {
    name: 'Incidents',
    objType: ObjectTypes.INCIDENTS_INCIDENT_LIMITED
  }), _ref;
};