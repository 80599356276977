import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-b117420a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  class: "mypage-list mypage-list-columns"
};
var _hoisted_2 = {
  key: 1,
  class: "mypage-list mypage-list-columns"
};
var _hoisted_3 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_preview_incident = _resolveComponent("preview-incident");
  var _component_router_link = _resolveComponent("router-link");
  var _component_static_table = _resolveComponent("static-table");
  var _component_card_incidents = _resolveComponent("card-incidents");
  var _component_load_more_wrapper = _resolveComponent("load-more-wrapper");
  var _component_preview_list = _resolveComponent("preview-list");
  return _openBlock(), _createBlock(_component_preview_list, {
    length: $props.incidents.length || '0',
    title: $data.translate.myIncidents,
    class: "incidents",
    "cookie-hidden-name": "rismahider-my-page-incidents",
    onChangeShowState: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changeShowState', $event);
    })
  }, {
    default: _withCtx(function () {
      return [$props.viewMode == $data.incidentViews.TILES ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.incidents, function (incident) {
        return _openBlock(), _createBlock(_component_preview_incident, {
          key: "preview-incident".concat(incident.id),
          incident: incident
        }, null, 8 /* PROPS */, ["incident"]);
      }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), $props.viewMode == $data.incidentViews.COMPACT ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_static_table, {
        "visibility-switch": true,
        dataset: $options.dataset,
        columns: $options.columns
      }, {
        severity: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_createElementVNode("span", {
            class: "traffic-light",
            style: _normalizeStyle({
              background: slotData.color
            })
          }, null, 4 /* STYLE */)];
        }),

        title: _withCtx(function (_ref2) {
          var slotData = _ref2.slotData;
          return [_createVNode(_component_router_link, {
            to: slotData.url
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(slotData.title), 1 /* TEXT */)];
            }),

            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset", "columns"])])) : _createCommentVNode("v-if", true), $props.viewMode == $data.incidentViews.EXPANDED ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_load_more_wrapper, {
        activities: $props.incidents,
        class: "mypage-list mypage-list-columns bg-slate-50 bg-no-repeat watermark-background"
      }, {
        default: _withCtx(function (_ref3) {
          var activitiesLimited = _ref3.activitiesLimited;
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activitiesLimited, function (incident) {
            return _openBlock(), _createBlock(_component_card_incidents, {
              key: "card-incident".concat(incident.id),
              incident: incident
            }, null, 8 /* PROPS */, ["incident"]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["activities"])])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["length", "title"]);
}