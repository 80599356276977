function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Line as LineChart } from 'vue-chartjs';
import { RismaVueChartMixin } from './RismaVueChartMixin';
import ExportFrame from '@/components/Atoms/Containers/ExportFrame';
export default {
  name: 'RismaLineChart',
  components: {
    LineChart: LineChart,
    ExportFrame: ExportFrame
  },
  mixins: [RismaVueChartMixin],
  props: {
    localStorageKey: {
      required: false,
      type: String,
      default: '',
      note: 'determine local storage key to safe state of chart'
    }
  },
  computed: {
    lineChartData: function lineChartData() {
      var storedState = localStorage.getItem(this.localStorageKey);
      var result = {
        labels: this.chartData.labels,
        datasets: this.chartData.datasets.map(function (dataset) {
          return _objectSpread({}, dataset);
        })
      };
      if (storedState) {
        storedState = JSON.parse(storedState);
        Array.isArray(storedState) && storedState.forEach(function (state) {
          if (!state.visible) {
            var idx = result.datasets.findIndex(function (item) {
              return item.label === state.label;
            });
            if (idx !== -1) {
              result.datasets[idx].hidden = true;
            }
          }
        });
      }
      return result;
    },
    lineChartOptions: function lineChartOptions() {
      var _this = this;
      var defaultOptions = this.chartOptions;
      if (this.localStorageKey) {
        defaultOptions.plugins.legend.onClick = function (_, legendItem, legend) {
          var index = legendItem.datasetIndex;
          var ci = legend.chart;
          var itemsList = [];
          ci.isDatasetVisible(index) ? ci.hide(index) : ci.show(index);
          legendItem.hidden = !legendItem.hidden;
          ci._metasets.forEach(function (item) {
            itemsList.push({
              label: item.label,
              visible: item.visible
            });
          });
          localStorage.setItem(_this.localStorageKey, JSON.stringify(itemsList));
        };
      }
      return defaultOptions;
    }
  }
};