/**
 * Get the html content of a element by it's css ID
 * @param id
 * @returns {Node}
 */
export function getHtmlContent(id) {
  var html = document.getElementById(id).cloneNode(true);
  html.querySelectorAll('ul').forEach(function (ul) {
    var parentNode = ul.parentNode;
    var string = separateUlLiBySemi(ul);
    if (string.length > 0 && parentNode !== null) {
      ul.outerHTML = string;
    }
  });
  return html;
}

/**
 * This will look for all ul li and implode the text of the li's and separate them by a semicolon
 * @param ul
 * @returns {string}
 */
function separateUlLiBySemi(ul) {
  var string = '';
  ul.querySelectorAll('li').forEach(function (li) {
    if (string.length > 0) {
      string = string + '; ' + li.textContent;
    } else {
      string = li.textContent;
    }
  });
  return string;
}