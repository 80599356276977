import { ref, computed } from 'vue';
export function getLocalStorageComputed(key, defaultValue, isJson) {
  var valueFromStorage = key ? getValueFromStorage(key, isJson) : null;
  var value = ref(valueFromStorage !== null && valueFromStorage !== void 0 ? valueFromStorage : defaultValue);
  return computed({
    get: function get() {
      return value.value;
    },
    set: function set(newValue) {
      value.value = newValue;
      if (key) localStorage.setItem(key, isJson ? JSON.stringify(newValue) : newValue);
    }
  });
}
function getValueFromStorage(key, isJson) {
  var localStorageItem = localStorage.getItem(key);
  return isJson ? JSON.parse(localStorageItem) : localStorageItem;
}