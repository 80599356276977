import { RiskProjects } from '@/constants/risks/RiskProjects';
import { ObjectTypes } from '@/constants/ObjectTypes';
export function checkERMByProjectId(id, currentUser) {
  if (!id || !currentUser || !currentUser.projects || !currentUser.projects.length) {
    return false;
  }
  var project = currentUser.projects.find(function (item) {
    return item.id === +id && item.objType === ObjectTypes.RISK_PROJECT;
  });
  if (!project) {
    return false;
  }
  return project.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type;
}