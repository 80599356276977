import { getTranslate } from './translate';
import ControlsReview from '@/components/Pages/Controls/ControlsReview';
import Modal from '@/components/Molecules/Modal/Modal';
import Alert from '@/components/Molecules/Modal/Alert';
export default {
  name: 'ControlReviewModal',
  components: {
    ControlsReview: ControlsReview,
    Modal: Modal,
    Alert: Alert
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
      note: 'An id of a control'
    },
    cpath: {
      type: String,
      required: true,
      note: 'A cpath of a control'
    }
  },
  emits: ['close', 'update'],
  data: function data() {
    return {
      translate: getTranslate['ControlReviewModal'](),
      isUpdated: false,
      showConfirmation: false
    };
  },
  methods: {
    onDismiss: function onDismiss() {
      if (this.isUpdated) {
        this.showConfirmation = true;
        return;
      }
      this.$emit('close');
    }
  }
};