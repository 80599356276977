function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import * as RiskApi from '@/api/risk/risk';
import { getAllByType } from '@/api/compliance/informationAssets';
import { getInformationAssetsSystems } from '@/api/risma/data';
import * as Utils from '@/utils/Utils';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { RiskProjects } from '@/constants/risks/RiskProjects';
export var RiskUniversalMixin = {
  data: function data() {
    return {
      riskProjects: [],
      currentRiskProject: {},
      periods: [],
      periodSelected: null,
      threats: [],
      levelOfThreats: [],
      vulnerabilities: [],
      probabilities: [],
      riskTypes: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useTagsStore, ['tags'])), {}, {
    processes: function processes() {
      if (!this.currentUser.iaAccess || !this.complianceNodes) {
        return [];
      }
      var processes = [];
      var systems = this.systems;
      for (var j = 0; j < systems.length; j++) {
        var system = systems[j];
        processes[system.id] = [];
        var nodes = this.complianceNodes;
        for (var i = 0; i < nodes.length; i++) {
          var node = nodes[i];
          if (system.complianceNodes && system.complianceNodes.indexOf(node.id) !== -1) {
            processes[system.id].push({
              title: node.title,
              link: node.url
            });
          }
        }
      }
      return processes;
    }
  }),
  methods: {
    prepareData: function prepareData() {
      var _this = this;
      this.currentRiskProject = this.riskProjects.find(function (item) {
        return +item.id === +_this.projectId;
      });
      this.riskApprovalEnabled = !!this.currentRiskProject.riskApproval;
      this.periods = _toConsumableArray(this.currentRiskProject.projectPeriods).reverse();
      if (this.periodId) {
        this.periodSelected = this.periods.find(function (item) {
          return item.id === +_this.periodId;
        });
      } else {
        this.periodSelected = this.periods.find(function (item) {
          return item.isCurrent;
        });
      }
      if (typeof this.periodSelected == 'undefined') {
        this.periodSelected = {};
        this.periodSelected.threatLevels = [];
        this.periodSelected.probabilityLabels = [];
        this.periodSelected.consequenceRows = [];
      }
    },
    afterPrepareData: function afterPrepareData() {
      this.getThreats();
      this.getLevelOfThreats();
      this.getVulnerabilities();
      this.getProbabilities();
    },
    getRiskProjects: function getRiskProjects() {
      var _this2 = this;
      return RiskApi.getRiskProjects().then(function (_ref) {
        var list = _ref.list;
        _this2.riskProjects = _this2.adjustRiskProjects(list);
      }).catch(function (e) {
        throw e;
      });
    },
    adjustRiskProjects: function adjustRiskProjects(projects) {
      return projects.map(function (project) {
        if (project.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type) return project;
        var projectPeriods = project.projectPeriods.map(function (period) {
          var consequenceRows = period.consequenceRows.map(function (cRow) {
            return _objectSpread(_objectSpread({}, cRow), {}, {
              impactColumns: cRow.impactColumns.splice(0, period.consequenceLevels)
            });
          });
          var probabilityRows = period.probabilityRows.map(function (pRow) {
            return _objectSpread(_objectSpread({}, pRow), {}, {
              likelihoodColumns: pRow.likelihoodColumns.splice(0, period.probabilityLevels)
            });
          });
          return _objectSpread(_objectSpread({}, period), {}, {
            consequenceRows: consequenceRows,
            probabilityRows: probabilityRows
          });
        });
        return _objectSpread(_objectSpread({}, project), {}, {
          projectPeriods: projectPeriods
        });
      });
    },
    getThreats: function getThreats() {
      this.threats = this.currentRiskProject.threats.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          label: Utils.getThreatLabelWithCategory(item),
          value: item.id
        });
      });
    },
    getLevelOfThreats: function getLevelOfThreats() {
      this.levelOfThreats = this.periodSelected.threatLevels.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          label: item.title,
          value: item.id
        });
      });
    },
    getVulnerabilities: function getVulnerabilities() {
      this.vulnerabilities = this.currentRiskProject.vulnerabilities.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          label: item.title,
          value: item.id
        });
      });
    },
    getProbabilities: function getProbabilities() {
      if (this.periodSelected.probabilityRows) {
        this.probabilities = this.periodSelected.probabilityRows.map(function (item) {
          return {
            id: item.index,
            label: item.label,
            value: item.index
          };
        });
      }
    },
    getAssets: function getAssets() {
      var _this3 = this;
      var promise;
      if (!this.currentUser.iaAccess) {
        promise = getInformationAssetsSystems();
      } else {
        promise = getAllByType(AssetsTypes.SYSTEMS, '?data[]=questionnaire_associations');
      }
      return promise.then(function (_ref2) {
        var list = _ref2.list;
        _this3.systems = list;
      });
    },
    getRiskTypes: function getRiskTypes() {
      var _this4 = this;
      return RiskApi.getRiskTypes().then(function (_ref3) {
        var list = _ref3.list;
        return _this4.riskTypes = list;
      });
    }
  }
};