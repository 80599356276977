import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-634c6b1a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["innerHTML"];
var _hoisted_2 = {
  class: "hidden"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_trafficlight = _resolveComponent("trafficlight");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return _openBlock(), _createElementBlock("div", null, [$data.isLoaded ? (_openBlock(), _createBlock(_component_risma_datatable, {
    key: 0,
    dataset: _ctx.datasetSlice,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "state-local": "information_assets_extended_view_".concat($props.typeId),
    "columns-meta": $options.columnsMeta,
    paging: $data.usePagination,
    "page-length": _ctx.pageLength,
    "with-global-search": $data.usePagination,
    "show-pagination": $data.usePagination,
    "confirm-export-message": $props.confirmExportMessage,
    "confirm-print-message": $props.confirmPrintMessage,
    "disabled-filter-by-columns": $options.disabledFilterByColumns,
    "wrapper-height-sticky": 430,
    "first-init": $data.firstInit,
    "header-cell-style": "text-left",
    "data-cell-styles": "align-top",
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged,
    onUpdated: _cache[2] || (_cache[2] = function ($event) {
      return $data.updatedFromPreviewModal = true;
    }),
    onDismissModal: $options.dismissPreviewModal,
    onBackendSort: _ctx.backendSort
  }, _createSlots({
    name: _withCtx(function (_ref) {
      var item = _ref.item;
      return [item.field.activity ? (_openBlock(), _createBlock(_component_preview_modal_link, {
        key: 0,
        activity: item.field.activity,
        onUpdated: _cache[0] || (_cache[0] = function ($event) {
          return $data.updatedFromPreviewModal = true;
        }),
        onDismissModal: $options.dismissPreviewModal
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(item.field.title), 1 /* TEXT */)];
        }),

        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onDismissModal"])) : (_openBlock(), _createElementBlock("div", {
        key: 1,
        innerHTML: item.field
      }, null, 8 /* PROPS */, _hoisted_1))];
    }),
    children: _withCtx(function (_ref2) {
      var item = _ref2.item;
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.field, function (dataItem, index) {
        return _openBlock(), _createBlock(_component_preview_modal_link, {
          key: index,
          activity: dataItem.activity,
          class: "hover:underline",
          onUpdated: _cache[1] || (_cache[1] = function ($event) {
            return $data.updatedFromPreviewModal = true;
          }),
          onDismissModal: $options.dismissPreviewModal
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(dataItem.title), 1 /* TEXT */)];
          }),

          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onDismissModal"]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),

    _: 2 /* DYNAMIC */
  }, [_renderList(_ctx.trafficLightRismaDatatableFields, function (trafficLightCol) {
    return {
      name: trafficLightCol,
      fn: _withCtx(function (_ref3) {
        var item = _ref3.item;
        return [item.field.color ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(trafficLightCol)
        }, [_createVNode(_component_trafficlight, {
          color: item.field.color
        }, null, 8 /* PROPS */, ["color"]), _createElementVNode("p", _hoisted_2, _toDisplayString(item.field.value) + "-" + _toDisplayString(item.field.label), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("span", {
          key: "".concat(trafficLightCol)
        }))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset", "dataset-total", "dataset-total-sliced", "state-local", "columns-meta", "paging", "page-length", "with-global-search", "show-pagination", "confirm-export-message", "confirm-print-message", "disabled-filter-by-columns", "first-init", "onPageChange", "onOnStateChanged", "onDismissModal", "onBackendSort"])) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  }))]);
}