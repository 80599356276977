import { createI18n } from 'vue-i18n/index';
import * as Cookies from 'tiny-cookie';
var lang = Cookies.get('risma_lang');

/* only strict imports required https://esbuild.github.io/api/#non-analyzable-imports */
function importMessages(language) {
  switch (language) {
    case 'da_DK':
      return import( /* webpackChunkName: "lang-da_DK" */'../../locale/da_DK.po');
    case 'de_DE':
      return import( /* webpackChunkName: "lang-de_DE" */'../../locale/de_DE.po');
    case 'es_ES':
      return import( /* webpackChunkName: "lang-es_ES" */'../../locale/es_ES.po');
    case 'fi_FI':
      return import( /* webpackChunkName: "lang-fi_FI" */'../../locale/fi_FI.po');
    case 'fr_FR':
      return import( /* webpackChunkName: "lang-fr_FR" */'../../locale/fr_FR.po');
    case 'it_IT':
      return import( /* webpackChunkName: "lang-it_IT" */'../../locale/it_IT.po');
    case 'nb_NO':
      return import( /* webpackChunkName: "lang-nb_NO" */'../../locale/nb_NO.po');
    case 'pl_PL':
      return import( /* webpackChunkName: "lang-pl_PL" */'../../locale/pl_PL.po');
    case 'sv_SE':
      return import( /* webpackChunkName: "lang-sv_SE" */'../../locale/sv_SE.po');
  }
}

// warnings are legit only for development; 
// If webpack is not updated (by php updateDependencies) it would be false (so it won`t cause warnings on prod anyway)
/* eslint-disable */
var state;
if (typeof PRODUCTION === 'undefined' || lang === 'en_GB') {
  state = true;
} else {
  state = PRODUCTION;
}
var i18nFunc = new createI18n({
  locale: lang,
  // set locale
  messages: {},
  // set locale messages
  silentTranslationWarn: state,
  silentFallbackWarn: true
});
i18nFunc.t = function (string, values) {
  if (!string || typeof string !== 'string') {
    return '';
  }

  //if its called on webpack bundle (some constants has generic logic), you can get some unexpected errors
  if (!Object.keys(i18nFunc.global.messages).length) return string;
  return i18nFunc.global.t(string, values);
};
export var i18n = i18nFunc;
var consoleWarning = console.warn;
console.warn = function (value) {
  if (value.startsWith('[vue-i18n]')) {
    console.log(value);
    // some small fix for vue warnings, Code works fine, so it should be fixed after implementing vue 3
  } else if (!value.startsWith('[Vue warn]')) {
    consoleWarning(value);
  }
};
/* eslint-enable */

// Use import to only load the one language being used!
export function loadi18nMessages() {
  if (Cookies.get('risma_lang') && lang !== 'en_GB') {
    return importMessages(lang).then(function (langMessages) {
      i18n.global.setLocaleMessage(lang, langMessages.default ? langMessages.default : langMessages);
    });
  } else {
    return Promise.resolve();
  }
}
export function setLocaleLanguage(language) {
  if (language === 'en_GB') {
    i18n.global.setLocaleMessage('en_GB', {});
    i18n.global.locale = 'en_GB';
    return Promise.resolve();
  }
  return importMessages(language).then(function (langMessages) {
    i18n.global.setLocaleMessage(language, langMessages.default ? langMessages.default : langMessages);
    i18n.global.locale = language;
  });
}
export default i18n;