import RiskModule from '@/components/Organisms/RiskModule';
export default {
  name: '',
  components: {
    RiskModule: RiskModule
  },
  data: function data() {
    return {
      riskTestData: [{
        id: 1,
        module: 'test',
        moduleId: 1,
        riskIndex: 0,
        likelihood: 2,
        impact: 5,
        rpi: 10
      }, {
        id: 2,
        module: 'test',
        moduleId: 1,
        riskIndex: 1,
        likelihood: 3,
        impact: 1,
        rpi: 3
      }]
    };
  },
  methods: {
    calcRPI: function calcRPI(event) {
      event.rpi = event.impact * event.likelihood;
      var temp = Object.assign([], this.riskTestData);
      temp[event.riskIndex] = event;
      this.riskTestData = temp;
    }
  }
};