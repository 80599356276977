function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7c97983c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "ml-6"
};
var _hoisted_2 = {
  key: 4
};
var _hoisted_3 = {
  key: 2
};
var _hoisted_4 = {
  class: "sections flex"
};
var _hoisted_5 = {
  class: "left-section mr-4 w-2/5 relative"
};
var _hoisted_6 = {
  class: "right-section w-3/5 border-l-2 border-solid border-gray-350 flex justify-center"
};
var _hoisted_7 = {
  key: 0,
  class: "w-4/5 h-fit mb-20vh"
};
var _hoisted_8 = {
  key: 1
};
var _hoisted_9 = {
  key: 0
};
var _hoisted_10 = {
  key: 1
};
var _hoisted_11 = {
  key: 2
};
var _hoisted_12 = {
  key: 3,
  class: "text-center m-8"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tab_list = _resolveComponent("tab-list");
  var _component_alert = _resolveComponent("alert");
  var _component_links_modal = _resolveComponent("links-modal");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_notification = _resolveComponent("notification");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_selector_tags = _resolveComponent("selector-tags");
  var _component_action_gap = _resolveComponent("action-gap");
  var _component_links_dep_preview = _resolveComponent("links-dep-preview");
  var _component_question_preview_list = _resolveComponent("question-preview-list");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.hasTabs ? (_openBlock(), _createBlock(_component_tab_list, {
    key: 0,
    class: "w-2/5 flex mt-6 mb-6",
    tabs: $options.linkTabs,
    "background-class": "bg-zinc-50",
    onUpdate: $options.setCurrentTab
  }, null, 8 /* PROPS */, ["tabs", "onUpdate"])) : _createCommentVNode("v-if", true), $data.itemDeletionData ? (_openBlock(), _createBlock(_component_alert, {
    key: 1,
    body: $data.itemDeletionData.body,
    header: $data.itemDeletionData.header,
    "button-ok-text": $data.translate.remove,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: _cache[0] || (_cache[0] = function ($event) {
      return $options.handleDeleteLink($data.itemDeletionData.module, $data.itemDeletionData.linkId);
    }),
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return $data.itemDeletionData = null;
    })
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text"])) : _createCommentVNode("v-if", true), $data.showLinksModal ? (_openBlock(), _createBlock(_component_links_modal, {
    key: 2,
    "projects-list": $data.projectsOptions,
    "links-options": $data.linksOptions,
    "preselected-links": $data.preselectedLinks,
    initiatives: $data.dataset[$data.MODULES.EXECUTION],
    onSelectProject: $options.setLinksOption,
    onUpdateLinks: $options.onUpdateLinks,
    onAddNewItem: $options.addNewItem,
    onDismiss: $options.toggleLinksModal
  }, null, 8 /* PROPS */, ["projects-list", "links-options", "preselected-links", "initiatives", "onSelectProject", "onUpdateLinks", "onAddNewItem", "onDismiss"])) : _createCommentVNode("v-if", true), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 3
  })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [$data.hasErrorFromBackend ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error",
    closeable: true,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return $data.hasErrorFromBackend = false;
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.errorMessage), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.currentTab === $data.tabTypes.LINKS && !$options.isReadOnlyAccess ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 1,
    text: $data.translate.addLink,
    type: "save",
    onClick: $options.toggleLinksModal
  }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true), $options.sortedItems.size ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $options.listTitle,
    type: "big",
    class: "data-title underline pl-8 py-8 relative text-21px"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.sortedItems, function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      linkModule = _ref2[0],
      linkValue = _ref2[1];
    return _openBlock(), _createElementBlock("div", {
      key: linkModule,
      class: "py-4 pl-12 pr-12 section-item relative"
    }, [_createVNode(_component_selector_tags, {
      module: linkValue.module || linkModule,
      title: linkValue.moduleTitle,
      tags: (linkValue === null || linkValue === void 0 ? void 0 : linkValue.items) || [],
      "has-traffic-lights": linkValue.hasTrafficLight,
      "has-delete-options": !!linkValue.hasDeleteOption,
      "selected-tag": $data.selectedTag,
      onDelete: function onDelete($event) {
        return $options.deleteLink(linkModule, $event);
      },
      onClick: $options.handlePreview
    }, null, 8 /* PROPS */, ["module", "title", "tags", "has-traffic-lights", "has-delete-options", "selected-tag", "onDelete", "onClick"])]);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_6, [$data.previewData.data !== null ? (_openBlock(), _createElementBlock("div", _hoisted_7, [$data.isLoadingPreview ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0,
    "disable-centering": true,
    class: "mt-10vh mb-20vh"
  })) : (_openBlock(), _createElementBlock("div", _hoisted_8, [$data.previewData.type === $data.LinkTypes.GAP_ANALYSIS ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_action_gap, {
    data: $data.previewData.list,
    "gap-list": [$data.previewData.list.gapAnalysis]
  }, null, 8 /* PROPS */, ["data", "gap-list"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.previewData.data, function (activity, idx) {
    return _openBlock(), _createBlock(_component_links_dep_preview, {
      key: idx,
      organisations: _ctx.organisations,
      users: _ctx.users,
      data: activity,
      scoring: $data.scoring
    }, null, 8 /* PROPS */, ["organisations", "users", "data", "scoring"]);
  }), 128 /* KEYED_FRAGMENT */))])) : $data.previewData.type === $data.LinkTypes.MITIGATING_ACTIVITIES ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_links_dep_preview, {
    organisations: _ctx.organisations,
    users: _ctx.users,
    data: $data.previewData.data,
    scoring: $data.scoring
  }, null, 8 /* PROPS */, ["organisations", "users", "data", "scoring"]), $data.previewData.list !== null ? (_openBlock(), _createBlock(_component_action_gap, {
    key: 0,
    class: "mb-20vh",
    data: $data.previewData.list,
    "gap-list": $data.previewData.list.gapList
  }, null, 8 /* PROPS */, ["data", "gap-list"])) : _createCommentVNode("v-if", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_links_dep_preview, {
    organisations: _ctx.organisations,
    users: _ctx.users,
    data: $data.previewData.data,
    scoring: $data.scoring
  }, null, 8 /* PROPS */, ["organisations", "users", "data", "scoring"]), $data.previewData.list !== null && $data.currentTab === $data.tabTypes.QUESTIONNAIRE ? (_openBlock(), _createBlock(_component_question_preview_list, {
    key: 0,
    data: $data.previewData.list
  }, null, 8 /* PROPS */, ["data"])) : _createCommentVNode("v-if", true)]))]))])) : _createCommentVNode("v-if", true)])])])) : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString($data.translate.youDontHaveAnyLinksToOrFromThisActivity), 1 /* TEXT */))]))]);
}