function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useTagsStore } from '@/Store/tagsStore';
import { getNodeById as _getNodeById, updateNodeById } from '@/api/compliance/processtree';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import DelegationWell from '@/components/Organisms/General/coreWells/DelegationWell';
import GovernanceReviewSection from '@/components/Organisms/Compliance/GovernanceReview/GovernanceReviewSection';
import ActivityTypeSelector from '@/components/Molecules/ActivityTypeSelector';
import SolutionIdRelationDetails from '@/components/Molecules/SolutionIdRelationDetails';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { GovernanceReviewMixin } from '@/mixins/GovernanceReviewMixin';
import { TabTypes } from '@/constants/Tabs';
import { saveStatus } from '@/constants/SaveStatuses';
import { MODULES } from '@/constants/modules';
import { createTabsList } from '@/utils/base/tabs';
import { getLinksLabel } from '@/utils/links';
import { getCustomFieldValues } from '@/utils/CustomFields';
import { updateFileLocally } from '@/utils/Attachments';
import { catch403 } from '@/utils/handleAPIErrors';
export default {
  name: 'ProcessNodeEntry',
  description: 'Common page for show process node',
  token: '<process-node-entry />',
  components: {
    RismaTitle: RismaTitle,
    SolutionIdRelationDetails: SolutionIdRelationDetails,
    DepWell: DepWell,
    DelegationWell: DelegationWell,
    GovernanceReviewSection: GovernanceReviewSection,
    ActivityTypeSelector: ActivityTypeSelector
  },
  mixins: [GovernanceReviewMixin, ProcessLibraryTitleMixin],
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    processTreeId: {
      required: true,
      type: [String, Number],
      note: 'Process tree Id from route'
    },
    processNodeId: {
      required: true,
      type: [String, Number],
      note: 'Process note Id from route'
    }
  },
  emits: ['changeTab', 'updated', 'accessError'],
  data: function data() {
    return {
      processNode: null,
      saveStatus: saveStatus.SAVED,
      updateTimer: 0,
      module: MODULES.COMPLIANCE_PROCESS_TREE_NODE,
      moduleId: this.processNodeId,
      translate: {
        areYouSure: this.$trans('Are you sure?'),
        activityType: this.$trans('Activity type')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useTagsStore, ['tags'])), mapState(useActivityTypesStore, ['processNodeActivityTypes'])), {}, {
    usersWithPoliciesAccess: function usersWithPoliciesAccess() {
      return this.users.filter(function (user) {
        return user.policiesAccess === true;
      });
    },
    isLoaded: function isLoaded() {
      return this.processNode != null;
    },
    awarenessEnabled: function awarenessEnabled() {
      return this.getSettingValue('feature.awareness_enabled') === 1;
    },
    tabs: function tabs() {
      var tabs = [TabTypes.DESCRIPTION, TabTypes.LINKS, TabTypes.CHANGELOG];
      if (this.awarenessEnabled) {
        tabs.splice(tabs.length - 1, 0, TabTypes.AWARENESS);
      }
      return createTabsList(tabs, "/compliance/processtree/".concat(this.processTreeId, "/nodes/").concat(this.processNodeId), getLinksLabel(this.processNode));
    },
    depHeaderProps: function depHeaderProps() {
      if (!this.isLoaded) {
        return {};
      }
      return {
        id: this.processNode.uniqueId || '',
        title: this.processNode.title,
        editableId: true,
        moduleId: this.moduleId,
        module: this.module,
        editableTitle: true,
        titleField: 'title',
        idField: 'uniqueId',
        filesList: this.processNode.attachments,
        users: this.usersWithPoliciesAccess,
        tabs: this.tabs,
        saveStatus: this.saveStatus,
        trafficLightSettings: {
          value: this.processNode.trafficLight,
          lightType: 'trafficLights',
          field: 'trafficLight'
        },
        hasVersionButtons: true
      };
    },
    processNodeFormatted: function processNodeFormatted() {
      if (!this.processNode) return {};
      var customFieldValues = getCustomFieldValues(this.processNode);
      return _objectSpread(_objectSpread({}, this.processNode), {}, {
        customFieldValues: customFieldValues,
        module: this.module
      });
    }
  }),
  mounted: function mounted() {
    this.getNodeById(this.processTreeId, this.processNodeId);
  },
  methods: {
    getNodeById: function getNodeById(treeId, nodeId) {
      var _this = this;
      return _getNodeById(treeId, nodeId).then(function (response) {
        if (response.status && response.status === 403) {
          if (_this.isPreview) {
            _this.$emit('accessError');
            return;
          }
          catch403(_this.$router, 'ComplianceProcessTree');
        }
        if (response.rootNodeId === undefined) {
          _this.$router.replace({
            name: 'ComplianceProcessTree'
          });
        }
        _this.processNode = response;
      }).catch(function () {
        if (_this.isPreview) {
          _this.$emit('accessError');
          return;
        }
        catch403(_this.$router, 'ComplianceProcessTree');
      });
    },
    processNodePropertyChanged: function processNodePropertyChanged(_ref) {
      var property = _ref.property,
        value = _ref.value;
      if (property === 'traffic_light') {
        this.processNode = _objectSpread(_objectSpread({}, this.processNode), {}, {
          'trafficLight': value
        });
      } else {
        this.processNode = _objectSpread(_objectSpread({}, this.processNode), {}, _defineProperty({}, property, value));
      }
      this.setUpdateTimer();
    },
    setUpdateTimer: function setUpdateTimer() {
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(this.saveUpdates, this.$autoSaveDebounceTime);
    },
    saveStatusClicked: function saveStatusClicked() {
      if (this.saveStatus !== saveStatus.SAVED) {
        clearTimeout(this.updateTimer);
        this.saveUpdates();
      }
    },
    saveChangesImmediately: function saveChangesImmediately(updatedProperty) {
      this.processNodePropertyChanged(updatedProperty);
      this.saveStatusClicked();
    },
    saveUpdates: function saveUpdates() {
      var _this2 = this;
      this.saveStatus = saveStatus.SAVING;
      var timerId = this.updateTimer;
      updateNodeById(this.processTreeId, this.processNodeId, this.processNodeFormatted).then(function (response) {
        _this2.saveStatus = saveStatus.SAVED;
        // update the processNode if there were no new changes during the save time
        if (timerId === _this2.updateTimer) {
          _this2.processNode = response;
        }
        _this2.$emit('updated', _objectSpread({}, _this2.processNode));
      }).catch(function () {
        _this2.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    onFileUpdate: function onFileUpdate(fileId) {
      var _this$processNode;
      updateFileLocally(fileId, (_this$processNode = this.processNode) === null || _this$processNode === void 0 ? void 0 : _this$processNode.attachments);
      this.saveStatus = saveStatus.SAVED;
    },
    getActivityData: function getActivityData() {
      var _this3 = this;
      this.getNodeById(this.processTreeId, this.processNodeId).then(function () {
        return _this3.saveStatus = saveStatus.SAVED;
      });
    },
    updatingAttachments: function updatingAttachments() {
      this.saveStatus = saveStatus.SAVING;
    },
    handleChangeReview: function handleChangeReview(event) {
      this.$emit('updated', _objectSpread({}, this.processNode));
      this.changeReview(event);
    }
  }
};