import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import SingleAssetChoice from '@/components/Molecules/Questionnaire/SingleAssetChoice';
export default {
  name: 'InformationAssetsChoice',
  components: {
    SingleAssetChoice: SingleAssetChoice
  },
  props: {
    assets: {
      required: true,
      type: Object,
      default: function _default() {}
    },
    selectedAssets: {
      required: true,
      type: [Array, Object]
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Question is read-only'
    }
  },
  emits: ['forceUpdate', 'newAsset', 'answered'],
  data: function data() {
    return {
      AssetsTypes: AssetsTypes
    };
  },
  methods: {
    addNewAsset: function addNewAsset(asset, type) {
      this.$emit('newAsset', {
        data: asset,
        type: type
      });
    }
  }
};