import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
import { areArraysEqual } from '@/utils/filterArray';
import { OPTION_VALUES } from '@/constants/compliance/questionSelection';
export default {
  name: 'QuestionSelection',
  components: {
    FeatherIcon: FeatherIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    RadioButtons: RadioButtons
  },
  props: {
    questions: {
      type: Array,
      required: true
    },
    isGDPR: {
      type: Boolean,
      required: false,
      default: false
    },
    questionnaires: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    isAnsweredOnly: {
      type: Boolean,
      required: false,
      default: undefined
    }
  },
  emits: ['checked:answered', 'questionsSelected'],
  data: function data() {
    return {
      selectedQuestions: [],
      loading: false,
      isCollapsed: false,
      selectedQuestionsOption: OPTION_VALUES.none,
      OPTION_VALUES: OPTION_VALUES,
      translate: getTranslate['QuestionSelection']()
    };
  },
  computed: {
    groupedQuestions: function groupedQuestions() {
      var grouped = {};
      this.questions.forEach(function (question) {
        var key = question.questionnaire_id;
        grouped[key] = grouped[key] || [];
        grouped[key].push(question);
      });
      return grouped;
    },
    groupedQuestionnaires: function groupedQuestionnaires() {
      var grouped = {};
      this.questionnaires.forEach(function (questionnaire) {
        var key = questionnaire.id;
        grouped[key] = questionnaire;
      });
      return grouped;
    },
    defaultQuestions: function defaultQuestions() {
      var result = [];
      this.questions.forEach(function (question) {
        if (question.isArticle30 === true) {
          result.push(question.id);
        }
      });
      return result;
    },
    selectedQuestionsOptions: function selectedQuestionsOptions() {
      var options = [{
        value: OPTION_VALUES.none,
        label: this.translate.noneSelected
      }, {
        value: OPTION_VALUES.all,
        label: this.translate.selectAllQuestions
      }];
      if (this.defaultQuestions.length) {
        options.push({
          value: OPTION_VALUES.default,
          label: this.translate.selectDefaultQuestions
        });
      }
      return options;
    }
  },
  watch: {
    selectedQuestions: {
      deep: true,
      handler: function handler() {
        this.$emit('questionsSelected', this.selectedQuestions);
        this.setSelectedQuestionsOption(this.selectedQuestions);
      }
    }
  },
  methods: {
    toggleCollapse: function toggleCollapse() {
      return this.isCollapsed = !this.isCollapsed;
    },
    selectAllQuestions: function selectAllQuestions() {
      var questionIds = this.questions.map(function (question) {
        return question.id;
      });
      this.setSelectedQuestions(questionIds);
    },
    toggleAnsweredQuestions: function toggleAnsweredQuestions(event) {
      event.target.blur();
      this.$emit('checked:answered', event.target.checked);
    },
    selectDefault: function selectDefault() {
      if (this.defaultQuestions.length) {
        this.setSelectedQuestions(this.defaultQuestions);
      }
    },
    setSelectedQuestions: function setSelectedQuestions(questions) {
      this.selectedQuestions = questions;
    },
    handleQuestionOptionChange: function handleQuestionOptionChange(_ref) {
      var value = _ref.value;
      if (!value || this.selectedOption === value) return;
      this.selectedOption = value;
      if (value === this.OPTION_VALUES.none) {
        this.setSelectedQuestions([]);
      } else if (value === this.OPTION_VALUES.all) {
        this.selectAllQuestions();
      } else {
        this.selectDefault();
      }
    },
    setSelectedQuestionsOption: function setSelectedQuestionsOption(selectedQuestions) {
      if (selectedQuestions.length === this.questions.length) {
        return this.selectedQuestionsOption = this.OPTION_VALUES.all;
      }
      if (areArraysEqual(selectedQuestions, this.defaultQuestions)) {
        return this.selectedQuestionsOption = this.OPTION_VALUES.default;
      }
      if (!selectedQuestions.length) {
        return this.selectedQuestionsOption = this.OPTION_VALUES.none;
      }
      this.selectedQuestionsOption = this.OPTION_VALUES.empty;
    }
  }
};