import i18n from '@/i18n';
export var getTranslate = {
  AddContractsModal: function AddContractsModal() {
    return {
      otherRelatedContracts: i18n.t('Other related contracts'),
      apply: i18n.t('Apply'),
      cancel: i18n.t('Cancel'),
      chooseActivityType: i18n.t('Choose activity type'),
      choose: i18n.t('Choose'),
      chooseContract: i18n.t('Choose contract'),
      clickToSelectContracts: i18n.t('Click to select contracts'),
      pleaseSelectAContract: i18n.t('Please select a contract'),
      addedContracts: i18n.t('Added contracts')
    };
  }
};