function buildAssetsMenu(settings, user, customIA) {
  var menuStructure = [];
  var iaBaseUrl = '/compliance/informationassets';
  menuStructure.push({
    url: "".concat(iaBaseUrl, "/type/all"),
    title: 'Overview',
    exact: false,
    rights: user.iaAccess === true
  });
  menuStructure.push({
    url: "".concat(iaBaseUrl, "/type/controllers"),
    title: 'Controllers',
    isHiddenInSubMenu: true,
    rights: user.iaAccess === true && (settings['feature.enable_data_controllers'] === undefined || settings['feature.enable_data_controllers'])
  });
  menuStructure.push({
    url: "".concat(iaBaseUrl, "/type/processors"),
    title: 'Processors',
    isHiddenInSubMenu: true,
    rights: user.iaAccess === true && (settings['feature.enable_data_processors'] === undefined || settings['feature.enable_data_processors'])
  });
  menuStructure.push({
    url: "".concat(iaBaseUrl, "/type/systems"),
    title: 'Systems',
    isHiddenInSubMenu: true,
    rights: user.iaAccess === true && (settings['feature.enable_systems'] === undefined || settings['feature.enable_systems'])
  });
  customIA.forEach(function (item) {
    menuStructure.push({
      url: "".concat(iaBaseUrl, "/type/").concat(item.id),
      title: item.label,
      translatable: false,
      isHiddenInSubMenu: true,
      rights: user.iaAccess === true
    });
  });
  menuStructure.push({
    url: "".concat(iaBaseUrl, "/reports"),
    title: 'Reports',
    rights: user.iaAccess === true
  });
  return menuStructure;
}
export default buildAssetsMenu;