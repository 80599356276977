export var RiskColorName = {
  GREEN: 'green',
  LIGHTBLUE: 'lightblue',
  RISMABLUE: 'rismablue',
  RED: 'red',
  GREY: 'grey',
  YELLOW: 'yellow'
};
export var StatusColorMapping = {
  closed: RiskColorName.GREEN,
  not_updated: RiskColorName.LIGHTBLUE,
  updated: RiskColorName.RISMABLUE,
  new: RiskColorName.RED,
  not_approved: RiskColorName.GREY
};
export var RiskStatusLabel = {
  closed: 'Closed',
  not_updated: 'Not updated',
  updated: 'Updated',
  new: 'New',
  not_approved: 'Not approved'
};