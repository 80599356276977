import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "block w-full"
};
var _hoisted_2 = {
  key: 1,
  class: "grid grid-cols-2 gap-4"
};
var _hoisted_3 = {
  key: 0,
  class: "grid gap-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risk_periods_well = _resolveComponent("risk-periods-well");
  var _component_risk_types_well = _resolveComponent("risk-types-well");
  var _component_management_levels_well = _resolveComponent("management-levels-well");
  var _component_approval_status_well = _resolveComponent("approval-status-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error",
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.errorMessage = '';
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.errorMessage), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.loaded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risk_periods_well, {
    class: "admin-well"
  }), $data.loaded ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risk_types_well, {
    class: "admin-well",
    risks: $data.risks
  }, null, 8 /* PROPS */, ["risks"]), _createVNode(_component_management_levels_well, {
    risks: $data.risks,
    class: "admin-well"
  }, null, 8 /* PROPS */, ["risks"]), $options.showApprovalStatusWell ? (_openBlock(), _createBlock(_component_approval_status_well, {
    key: 0,
    class: "admin-well min-h-300px",
    risks: $data.risks,
    onApproved: $options.updateApprovedRisks
  }, null, 8 /* PROPS */, ["risks", "onApproved"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]);
}