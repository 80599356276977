import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_link_item = _resolveComponent("link-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_link_item, {
    title: _ctx.$trans('this is a test link'),
    link: "https://google.dk"
  }, {
    end: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "x",
        color: "white"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]), _createVNode(_component_link_item, {
    "remote-link": true,
    title: _ctx.$trans('link to out side / Translate'),
    link: "https://translate.google.com/",
    module: "link"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_link_item, {
    "as-tag": true,
    title: _ctx.$trans('this is a tags ui'),
    link: "https://translate.google.com/"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_link_item, {
    "as-tag": true,
    "as-router-link": true,
    title: _ctx.$trans('this is an asRouterLink'),
    link: "/controls2"
  }, null, 8 /* PROPS */, ["title"])]);
}