import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import * as Utils from '@/utils/Utils';
export var ControlDetailsCustomFieldsMixin = {
  methods: {
    getCustomFieldsValues: function getCustomFieldsValues(customFieldValues) {
      var _this = this;
      var result = {};
      customFieldValues.forEach(function (field) {
        switch (field.typeId) {
          case CustomFieldTypes.SELECT:
            if (field.selectedValue) {
              result[field.id] = Utils.findSelectedOptionId(field);
            } else {
              result[field.id] = null;
            }
            return;
          case CustomFieldTypes.ROW_GROUP:
            if (field.selectedValues) result[field.id] = _this.prepareRepeatableField(field);
            return;
          default:
            result[field.id] = field.selectedValue;
        }
      });
      return result;
    },
    prepareRepeatableField: function prepareRepeatableField(field) {
      var result = {};
      var _loop = function _loop(key) {
        field.selectedValues[key].forEach(function (childField) {
          if (!Utils.hasValue(childField.selectedValue)) return;
          if (!result[key]) result[key] = {};
          if (childField.typeId === CustomFieldTypes.SELECT) {
            result[key][childField.id] = Utils.findSelectedOptionId(childField);
          } else {
            result[key][childField.id] = childField.selectedValue;
          }
        });
      };
      for (var key in field.selectedValues) {
        _loop(key);
      }
      return result;
    }
  }
};