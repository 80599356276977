import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "p-6 mx-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");
  var _component_description_modal = _resolveComponent("description-modal");
  var _component_header_nav_bar = _resolveComponent("header-nav-bar");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'pt-120px': _ctx.userIsLoggedIn
    })
  }, [_createElementVNode("div", _hoisted_1, [(_openBlock(), _createBlock(_component_router_view, {
    key: $options.keyField,
    onUpdateHeader: _cache[0] || (_cache[0] = function ($event) {
      return $data.index++;
    })
  }))]), $options.updatesForPath ? (_openBlock(), _createBlock(_component_description_modal, {
    key: 0,
    header: $options.updatesForPath.header,
    body: $options.updatesForPath.body,
    "update-date": $options.updatesForPath.date || '',
    "show-more-field": $options.updatesForPath.showMore,
    "show-update-date": !!$options.updatesForPath.date,
    "key-field": $options.updatesForPath.path,
    "show-button": true,
    changes: $options.updatesForPath.changes
  }, null, 8 /* PROPS */, ["header", "body", "update-date", "show-more-field", "show-update-date", "key-field", "changes"])) : _createCommentVNode("v-if", true), _ctx.userIsLoggedIn ? (_openBlock(), _createBlock(_component_header_nav_bar, {
    id: _ctx.currentUser.id,
    key: "key_".concat($data.index),
    risma2enable: true,
    "user-menu-enabled": _ctx.userIsLoggedIn
  }, null, 8 /* PROPS */, ["id", "user-menu-enabled"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}