import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "flex justify-between mb-4"
};
var _hoisted_2 = {
  key: 0,
  class: "w-fit divide-y divide-slate-300"
};
var _hoisted_3 = {
  class: "text-2xl text-center"
};
var _hoisted_4 = {
  class: "flex"
};
var _hoisted_5 = {
  class: "text-sm"
};
var _hoisted_6 = {
  class: "text-blue-250 font-bold text-2xl text-center"
};
var _hoisted_7 = {
  key: 1,
  class: "flex flex-wrap -mx-6"
};
var _hoisted_8 = {
  key: 0,
  class: "w-1/2 px-6 mb-6"
};
var _hoisted_9 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_bar_chart = _resolveComponent("risma-bar-chart");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.overview,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("h2", _hoisted_3, _toDisplayString($data.translate.controlsCount), 1 /* TEXT */), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.totalControls, function (total, index) {
    return _openBlock(), _createElementBlock("div", {
      key: total.label + index,
      class: "p-2"
    }, [_createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$trans(total.label)), 1 /* TEXT */), _createElementVNode("p", _hoisted_6, _toDisplayString(total.value), 1 /* TEXT */)]);
  }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true)]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.charts, function (chartKey, i) {
    var _$data$datasets$chart, _$data$datasets$chart2;
    return _openBlock(), _createElementBlock(_Fragment, {
      key: "chart-".concat(i)
    }, [$data.datasets[chartKey] ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_risma_title, {
      title: _ctx.$trans($data.datasets[chartKey].title),
      class: "mb-4"
    }, null, 8 /* PROPS */, ["title"]), $data.datasets[chartKey].empty ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString($data.translate.nothingToShow), 1 /* TEXT */)) : _createCommentVNode("v-if", true), ((_$data$datasets$chart = $data.datasets[chartKey]) === null || _$data$datasets$chart === void 0 ? void 0 : _$data$datasets$chart.type) === 'columnChart' ? (_openBlock(), _createBlock(_component_risma_bar_chart, {
      key: 1,
      labels: $data.datasets[chartKey].labels,
      datasets: $data.datasets[chartKey].datasets,
      height: $data.datasets[chartKey].chartHeight
    }, null, 8 /* PROPS */, ["labels", "datasets", "height"])) : _createCommentVNode("v-if", true), ((_$data$datasets$chart2 = $data.datasets[chartKey]) === null || _$data$datasets$chart2 === void 0 ? void 0 : _$data$datasets$chart2.type) === 'stackedBarChart' ? (_openBlock(), _createBlock(_component_risma_bar_chart, {
      key: 2,
      labels: $data.datasets[chartKey].labels,
      datasets: $data.datasets[chartKey].datasets,
      "show-legend": true,
      "show-data-labels": true,
      "min-bar-length": 5,
      type: "Stacked"
    }, null, 8 /* PROPS */, ["labels", "datasets"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
  }), 128 /* KEYED_FRAGMENT */))]))]);
}