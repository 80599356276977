import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_expanded_card = _resolveComponent("expanded-card");
  var _component_load_more_wrapper = _resolveComponent("load-more-wrapper");
  var _component_preview_list = _resolveComponent("preview-list");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_preview_list, {
    ref: "previewList",
    length: $props.activities.length || '0',
    title: $props.title,
    "header-offset": $props.headerOffset,
    "cookie-hidden-name": $props.cookieHiddenName
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_load_more_wrapper, {
        activities: $props.activities
      }, {
        default: _withCtx(function (_ref) {
          var activitiesLimited = _ref.activitiesLimited;
          return [_createVNode(_TransitionGroup, {
            name: "fade",
            tag: "div",
            appear: ""
          }, {
            default: _withCtx(function () {
              return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activitiesLimited, function (activity) {
                return _openBlock(), _createBlock(_component_expanded_card, _mergeProps({
                  key: "expand-card-".concat(activity.id),
                  "preview-mode": $props.previewMode,
                  activity: activity,
                  users: $props.users,
                  organisations: $props.organisations,
                  "workflow-statuses": $props.workflowStatuses,
                  "additional-fields": $props.additionalFields,
                  scoring: $props.scoring,
                  period: $props.period,
                  "activity-types": $props.activityTypes,
                  "watermark-image": $props.watermarkImage
                }, _ctx.$attrs), {
                  button: _withCtx(function (slotProps) {
                    return [_renderSlot(_ctx.$slots, "button", {
                      activity: slotProps.activity
                    }, undefined, true)];
                  }),
                  _: 2 /* DYNAMIC */
                }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["preview-mode", "activity", "users", "organisations", "workflow-statuses", "additional-fields", "scoring", "period", "activity-types", "watermark-image"]);
              }), 128 /* KEYED_FRAGMENT */))];
            }),

            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)];
        }),

        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["activities"])];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["length", "title", "header-offset", "cookie-hidden-name"])]);
}