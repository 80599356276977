import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6c86bc3a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["title"];
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  key: 1,
  class: "flex justify-center mb-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_router_link = _resolveComponent("router-link");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", null, [$options.dataset.length ? (_openBlock(), _createBlock(_component_static_table, {
    key: 0,
    dataset: $options.dataset,
    columns: $options.columns,
    "columns-max-width": $options.prepareColumnMaxWidth,
    theme: $data.StaticTableThemes.zebra,
    onUpdated: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('updated');
    }),
    onDeleteItem: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('updated');
    }),
    onDismissModal: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('dismissModal');
    })
  }, {
    title: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createVNode(_component_router_link, {
        to: slotData.url
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_risma_title_with_icon, {
            icon: "confidential",
            title: slotData.title,
            class: _normalizeClass(slotData.archived ? 'line-through' : ''),
            "title-attribute": slotData.hoverTitle,
            "display-icon": slotData.confidential
          }, null, 8 /* PROPS */, ["title", "class", "title-attribute", "display-icon"])];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])];
    }),
    titlePreview: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [_createVNode(_component_preview_modal_link, {
        activity: slotData.data,
        onUpdated: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('updated');
        }),
        onDeleteItem: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('updated');
        }),
        onDismissModal: _cache[2] || (_cache[2] = function ($event) {
          return _ctx.$emit('dismissModal');
        })
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_risma_title_with_icon, {
            icon: "confidential",
            class: _normalizeClass(slotData.data.archived ? 'line-through' : ''),
            title: slotData.data.title,
            "title-attribute": slotData.hoverTitle,
            "display-icon": slotData.data.confidential
          }, null, 8 /* PROPS */, ["class", "title", "title-attribute", "display-icon"])];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"])];
    }),
    companies: _withCtx(function (_ref3) {
      var _slotData$companies;
      var slotData = _ref3.slotData;
      return [(_slotData$companies = slotData.companies) !== null && _slotData$companies !== void 0 && _slotData$companies.length ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 0
      }, _renderList(slotData.companies, function (activity, key) {
        return _openBlock(), _createBlock(_component_preview_modal_link, {
          key: "company_".concat(key),
          class: "w-fit",
          activity: activity
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("span", {
              title: activity.name,
              class: "hover:underline"
            }, _toDisplayString(activity.name), 9 /* TEXT, PROPS */, _hoisted_1)];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"]);
      }), 128 /* KEYED_FRAGMENT */)) : (_openBlock(), _createElementBlock("div", _hoisted_2))];
    }),
    starred: _withCtx(function (_ref4) {
      var slotData = _ref4.slotData;
      return [_createVNode(_component_feather_icon, {
        class: _normalizeClass(["stroke-3", [slotData.starred ? 'text-green-150' : 'text-gray-650', {
          'cursor-pointer': !$options.isCurrentUserLocked
        }]]),
        icon: "star",
        height: "20",
        width: "20",
        onClick: function onClick($event) {
          return $options.toggleContractStarStatus(slotData);
        }
      }, null, 8 /* PROPS */, ["class", "onClick"])];
    }),
    delete: _withCtx(function (_ref5) {
      var slotData = _ref5.slotData;
      return [_createVNode(_component_feather_icon, {
        class: "text-red-50 stroke-2 cursor-pointer",
        icon: "x",
        title: $data.translate.delete,
        onClick: function onClick($event) {
          return _ctx.$emit('delete', slotData.contractId);
        }
      }, null, 8 /* PROPS */, ["title", "onClick"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset", "columns", "columns-max-width", "theme"])) : _createCommentVNode("v-if", true), !$options.dataset.length && $props.noDataMessage ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.noDataMessage), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]);
}