function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import Notification from '@/components/Molecules/Notification';
import * as Utils from '@/utils/Utils';
export default {
  name: 'AttachmentSection',
  introduction: 'A section that handles attachments',
  description: "Section for upload attachments (files / title & url), uses inside AttachmentModal component.\n        If you want to reset link attributes (link's title & url) use vm.resetLinkData() function.",
  token: '<attachment-section @input="handlerFn(attachment)" />',
  components: {
    InputField: InputField,
    RismaFileUpload: RismaFileUpload,
    Notification: Notification
  },
  props: {
    linkTitlePredifined: {
      type: String,
      required: false,
      note: 'Predefined title of a link',
      default: ''
    },
    linkUrlPredifined: {
      type: String,
      required: false,
      note: 'Predefined url of a link',
      default: ''
    },
    allowDnd: {
      type: Boolean,
      default: false
    },
    uploadingValue: {
      type: Number,
      required: false,
      default: 0,
      note: 'uploaded value for progress bar'
    },
    uploadingMax: {
      type: Number,
      required: false,
      default: 0,
      note: 'total value to be loaded for progress bar'
    },
    interactionDisabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'disables choosing the files and saving or completing control during uploading'
    },
    isFilesDisabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is files upload section disabled'
    }
  },
  emits: ['input', 'update', 'uploadFiles'],
  data: function data() {
    return {
      showProgressBar: false,
      linkTitle: '',
      linkUrl: '',
      isLinkSaveFail: false,
      isLinkSaveSuccess: false,
      maxlength: 2000,
      // limit for urls,
      translate: getTranslate['AttachmentSection']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    attachments_allowed: function attachments_allowed() {
      if (this.getSettingValue('risma.attachments_allowed')) {
        return Object.keys(this.getSettingValue('risma.attachments_allowed')).join(', ');
      } else {
        return '';
      }
    },
    useFormData: function useFormData() {
      return this.getSettingValue('feature.form_data_upload');
    }
  }),
  created: function created() {
    this.linkTitle = this.linkTitlePredifined;
    this.linkUrl = this.linkUrlPredifined;
  },
  methods: {
    fileUploaded: function fileUploaded(files) {
      this.$emit('input', files);
      this.$refs.uploadForm.reset();
    },
    uploadFiles: function uploadFiles(formData) {
      this.$emit('uploadFiles', formData);
      this.$refs.uploadForm.reset();
    },
    prepareLinkData: function prepareLinkData() {
      return {
        url: this.linkUrl,
        filename: this.linkTitle
      };
    },
    hideAllLinkNotification: function hideAllLinkNotification() {
      if (this.$refs.linkFailNotification) {
        this.$refs.linkFailNotification.resetDismiss();
      }
      this.isLinkSaveFail = false;
      if (this.$refs.linkSuccessNotification) {
        this.$refs.linkSuccessNotification.resetDismiss();
      }
      this.isLinkSaveSuccess = false;
    },
    resetLinkData: function resetLinkData() {
      this.linkTitle = '';
      this.linkUrl = '';
    },
    submitUpload: function submitUpload(event) {
      event.preventDefault();
    },
    updateLinkData: function updateLinkData(e, field) {
      var value = this.setMaxlength(e);
      if (field === 'url') {
        this.linkUrl = value;
      } else if (field === 'title') {
        this.linkTitle = value;
      }
      var data = this.prepareLinkData();
      this.$emit('update', data);
    },
    setMaxlength: function setMaxlength(e) {
      return Utils.setMaxlength(e, this.maxlength);
    },
    toggleShowProgress: function toggleShowProgress() {
      this.showProgressBar = !this.showProgressBar;
    }
  }
};