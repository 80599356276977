function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { getRiskProjects as _getRiskProjects, updateRiskProject as _updateRiskProject, setHeatmapColors as _setHeatmapColors } from '@/api/risk/risk';
import { getCurrentScoring as _getCurrentScoring } from '@/api/risk/scoring';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RiskHeatMapSelector from '@/components/Molecules/Risk/RiskHeatMapSelector';
import Notification from '@/components/Molecules/Notification';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Alert from '@/components/Molecules/Modal/Alert';
import { RiskProjects as RiskProjectsConstants } from '@/constants/risks/RiskProjects';
import { DefaultHeatMapColors } from '@/constants/risks/RisksHeatMapColors';
import { prepareDefaultHeatMapColors } from '@/utils/heatMap';
export default {
  name: 'AdminRiskHeatMap',
  description: 'Page with risk heat map settings',
  token: '<admin-risk-heat-map />',
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    FeatherIcon: FeatherIcon,
    RismaToggle: RismaToggle,
    RismaButton: RismaButton,
    RiskHeatMapSelector: RiskHeatMapSelector,
    Notification: Notification,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Alert: Alert
  },
  data: function data() {
    return {
      projectId: null,
      riskProjects: [],
      toggleState: false,
      currentScoring: {},
      heatMapColors: {},
      notificationType: null,
      notificationText: '',
      showResetAlert: false,
      translate: getTranslate['AdminRiskHeatMap']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['isAdmin'])), {}, {
    isReadonly: function isReadonly() {
      return !this.isAdmin;
    },
    riskProjectsOptions: function riskProjectsOptions() {
      return this.riskProjects.map(function (project) {
        return {
          value: project.id,
          label: project.title
        };
      });
    },
    project: function project() {
      var _this = this;
      return this.riskProjects.find(function (project) {
        return project.id === _this.projectId;
      });
    },
    isErm: function isErm() {
      var _this$project;
      return +((_this$project = this.project) === null || _this$project === void 0 ? void 0 : _this$project.type) === RiskProjectsConstants.ENTERPRISE_RISK_MANAGEMENT.type;
    },
    currentPeriod: function currentPeriod() {
      var _this$project2;
      return (_this$project2 = this.project) === null || _this$project2 === void 0 || (_this$project2 = _this$project2.projectPeriods) === null || _this$project2 === void 0 ? void 0 : _this$project2.find(function (period) {
        return period.isCurrent;
      });
    },
    consequenceScores: function consequenceScores() {
      var _this$currentScoring, _this$currentPeriod;
      return this.isErm ? (_this$currentScoring = this.currentScoring) === null || _this$currentScoring === void 0 ? void 0 : _this$currentScoring.likelihood_scores : (_this$currentPeriod = this.currentPeriod) === null || _this$currentPeriod === void 0 ? void 0 : _this$currentPeriod.consequenceLevels;
    },
    probabilityScores: function probabilityScores() {
      var _this$currentScoring2, _this$currentPeriod2;
      return this.isErm ? (_this$currentScoring2 = this.currentScoring) === null || _this$currentScoring2 === void 0 ? void 0 : _this$currentScoring2.impact_scores : (_this$currentPeriod2 = this.currentPeriod) === null || _this$currentPeriod2 === void 0 ? void 0 : _this$currentPeriod2.probabilityLevels;
    },
    numberOfRows: function numberOfRows() {
      var _this$project3;
      return (_this$project3 = this.project) !== null && _this$project3 !== void 0 && _this$project3.matrixInverted ? this.probabilityScores : this.consequenceScores;
    },
    numberOfColumns: function numberOfColumns() {
      var _this$project4;
      return (_this$project4 = this.project) !== null && _this$project4 !== void 0 && _this$project4.matrixInverted ? this.consequenceScores : this.probabilityScores;
    },
    defaultHeatMapColors: function defaultHeatMapColors() {
      var colorsArraySize = this.numberOfColumns + this.numberOfRows;
      if (colorsArraySize > DefaultHeatMapColors.length) {
        return prepareDefaultHeatMapColors(colorsArraySize);
      }
      return DefaultHeatMapColors;
    },
    defaultColors: function defaultColors() {
      var result = {};
      var maxGridSize = (this.defaultHeatMapColors.length + 1) / 2;
      var xShift = Math.floor((maxGridSize - this.numberOfColumns) / 2);
      var yShift = Math.floor((maxGridSize - this.numberOfRows) / 2);
      var totalShift = xShift + yShift;
      var numberOfCells = this.numberOfRows * this.numberOfColumns;
      for (var i = 0; i < numberOfCells; i++) {
        var index = i % this.numberOfColumns + Math.floor(i / this.numberOfColumns) + totalShift;
        result[i + 1] = this.defaultHeatMapColors[index];
      }
      return result;
    },
    dataset: function dataset() {
      var rows = [];
      for (var row = this.numberOfRows; row > 0; row--) {
        var cells = [];
        for (var col = 1; col <= this.numberOfColumns; col++) {
          var value = (row - 1) * this.numberOfColumns + col;
          cells.push({
            backgroundColor: this.heatMapColors[value],
            value: value
          });
        }
        rows.push(cells);
      }
      return rows;
    },
    isLoading: function isLoading() {
      return !this.dataset.length;
    }
  }),
  mounted: function mounted() {
    this.getData().then(this.setData);
  },
  methods: {
    getData: function getData() {
      return Promise.all([this.getRiskProjects(), this.getCurrentScoring()]);
    },
    getRiskProjects: function getRiskProjects() {
      var _this2 = this;
      return _getRiskProjects().then(function (_ref) {
        var list = _ref.list;
        return _this2.riskProjects = list;
      });
    },
    getCurrentScoring: function getCurrentScoring() {
      var _this3 = this;
      return _getCurrentScoring().then(function (scoring) {
        return _this3.currentScoring = scoring;
      });
    },
    setData: function setData() {
      var _this$riskProjects$;
      this.projectId = (_this$riskProjects$ = this.riskProjects[0]) === null || _this$riskProjects$ === void 0 ? void 0 : _this$riskProjects$.id;
      this.setProjectData();
    },
    setProjectData: function setProjectData() {
      var _this$project5;
      this.setDefaultColors();
      this.setColorsFromProject();
      this.toggleState = !!((_this$project5 = this.project) !== null && _this$project5 !== void 0 && _this$project5.customHeatmapEnabled);
    },
    setDefaultColors: function setDefaultColors() {
      this.heatMapColors = _objectSpread({}, this.defaultColors);
    },
    setColorsFromProject: function setColorsFromProject() {
      var _this4 = this;
      this.project.heatmapOptions.forEach(function (_ref2) {
        var number = _ref2.number,
          hexCode = _ref2.hexCode;
        _this4.heatMapColors[number] = "#".concat(hexCode);
      });
    },
    setColor: function setColor(value, color) {
      this.heatMapColors[value] = color;
    },
    resetToDefaultColours: function resetToDefaultColours() {
      this.setDefaultColors();
      this.saveSettings();
      this.showResetAlert = false;
    },
    saveSettings: function saveSettings() {
      var _this5 = this;
      var promises = [];
      if (!!this.project.customHeatmapEnabled !== !!this.toggleState) {
        promises.push(this.updateRiskProject());
      }
      var heatmapOptions = [];
      Object.entries(this.heatMapColors).forEach(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
          number = _ref4[0],
          value = _ref4[1];
        var hexCode = value.slice(1);
        heatmapOptions.push({
          number: number,
          hexCode: hexCode
        });
      });
      this.setHeatmapColors(heatmapOptions);
      Promise.all(promises).then(function () {
        return _this5.showSavedNotification();
      });
    },
    setHeatmapColors: function setHeatmapColors(heatmapOptions) {
      var _this6 = this;
      return _setHeatmapColors(this.projectId, {
        heatmapOptions: heatmapOptions
      }).then(function (response) {
        return _this6.setColorInProject(response.list);
      });
    },
    updateRiskProject: function updateRiskProject() {
      var _this7 = this;
      var data = {
        projectId: this.projectId,
        customHeatmapEnabled: this.toggleState
      };
      return _updateRiskProject(this.projectId, data).then(function (response) {
        _this7.project.customHeatmapEnabled = response.customHeatmapEnabled;
      });
    },
    setColorInProject: function setColorInProject(heatmapOptions) {
      var _this8 = this;
      var project = this.riskProjects.find(function (project) {
        return +project.id === +_this8.projectId;
      });
      heatmapOptions.forEach(function (_ref5) {
        var number = _ref5.number,
          hexCode = _ref5.hexCode;
        var heatmapOption = project.heatmapOptions.find(function (option) {
          return +option.number === +number;
        });
        if (heatmapOption) {
          heatmapOption.hexCode = hexCode;
          return;
        }
        project.heatmapOptions.push({
          number: number,
          hexCode: hexCode
        });
      });
    },
    showNotification: function showNotification(type, text) {
      var _this9 = this;
      this.notificationType = type;
      this.notificationText = text;
      setTimeout(function () {
        _this9.notificationText = '';
      }, this.$debounceTimeout);
    },
    showSavedNotification: function showSavedNotification() {
      this.showNotification('success', 'Settings are saved');
    }
  }
};