function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import buildComplianceMenu from '@/menus/compliance';
import { i18n } from '@/i18n';
import buildControlsMenu from '@/menus/controls';
import buildRisksMenu from '@/menus/risks';
import buildIncidentsMenu from '@/menus/incidents';
import buildActionsMenu from '@/menus/actions';
import buildContractsMenu from '@/menus/contracts';
import buildPoliciesMenu from '@/menus/policies';
import buildAssetsMenu from '@/menus/assets';
import { RiskBaseUrls } from '@/constants/risks/RiskBaseUrls';
import { MenuKeys } from '@/constants/menu/MenuKeys';
var projects;
var menuStructure = {};
var _mainMenu;
var _subMenu;
export function buildMenuStructure(settings, currentUser, mainMenu, subMenu, currentPath) {
  _mainMenu = mainMenu;
  _subMenu = subMenu;
  projects = currentUser.projects;
  menuStructure['compliance'] = buildComplianceMenu(projects, currentUser, settings, i18n);
  menuStructure['controls'] = buildControlsMenu(currentUser, settings);
  menuStructure['risk'] = buildRisksMenu(settings, currentUser);
  menuStructure['incident'] = buildIncidentsMenu(settings, currentUser);
  menuStructure['awareness'] = buildComplianceMenu(projects, currentUser, settings, i18n);
  menuStructure['actions'] = buildActionsMenu(currentUser, settings);
  buildMenu(currentPath, settings);
}
export function setCurrent(module) {
  for (var key in menuStructure) {
    if (key == module) {
      menuStructure[key].current = true;
    } else {
      menuStructure[key].current = false;
    }
  }
}
function getCurrent() {
  for (var key in menuStructure) {
    if (menuStructure[key].current) {
      return menuStructure[key];
    }
  }
  return {};
}
export function getCurrentMenuItem(currentPath) {
  if (!currentPath) {
    return undefined;
  }
  var items = getCurrentMenuItems(currentPath);
  var filteredItems = items.filter(function (item) {
    var found = currentPath === item.url || item.aliasesRegex && currentPath.match(item.aliasesRegex);
    if (!found && item.children) {
      found = childrenHasUrl(item.children, currentPath);
    }
    return found;
  });
  var isERM = currentPath.startsWith(RiskBaseUrls.projects + '/1');
  // If filtered items have more than 1 element than we find it by our currentPath not to change it
  if (items.length > 1 && currentPath.startsWith(RiskBaseUrls.projects) && !isERM) {
    filteredItems = checkRisksUrls(filteredItems, currentPath);
  }
  return filteredItems[0];
}
function checkRisksUrls(filteredItems, currentPath) {
  return filteredItems.filter(function (item) {
    var start = RiskBaseUrls.projects.length + 1; //start of the slicing
    var curEnd = currentPath.indexOf('/', RiskBaseUrls.projects.length + 1);
    var itemEnd = item.url.indexOf('/', RiskBaseUrls.projects.length + 1);
    return item.url.slice(start, itemEnd) === currentPath.slice(start, curEnd);
  });
}
export function buildMenu(currentPath) {
  var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!_mainMenu || !_subMenu) {
    return;
  }
  var module = getModuleFromPath(currentPath, settings);
  setCurrent(module);
  var mainMenuNav = _mainMenu.$children[0];
  var subMenuNav = _subMenu.$children[0];
  var currentMenuItem = getCurrentMenuItem(currentPath);
  var currentMenuItems = getCurrentMenuItems();
  if (currentMenuItem && currentMenuItem.children) {
    subMenuNav.addItems(currentMenuItem.children);
  } else {
    subMenuNav.addItems([]);
  }
  currentMenuItems = getRenderedItems(currentMenuItems);
  mainMenuNav.addItems(currentMenuItems);
  mainMenuNav.setSelectedUrl();
}
function isRender(elem) {
  if (elem.render === undefined || elem.render !== false) {
    return true;
  }
  return false;
}
function getRenderedItems(items) {
  return items.filter(function (item) {
    return isRender(item);
  });
}
export function getModuleFromPath(path, settings) {
  var splitPath = path.split('/');
  if (settings['feature.policy_management_menu'] === 1 && (splitPath[2] === 'processtree' || splitPath[1] === 'awareness')) {
    return 'policies';
  }
  if (settings['feature.asset_management_menu'] == 1 && splitPath[2] === 'informationassets') {
    return 'assets';
  }
  return splitPath[1].replace(/[0-9]/g, '');
}
function childrenHasUrl(children, currentPath) {
  var found = false;
  children.forEach(function (child) {
    if (currentPath === child.url || child.aliasesRegex && currentPath.match(child.aliasesRegex)) {
      found = true;
    }
    if (!found && child.children) {
      found = childrenHasUrl(child.children, currentPath);
    }
    if (found) {
      return false; // break
    }
  });

  return found;
}
function getCurrentMenuItems() {
  var menuItems = getCurrent();
  var items = [];
  if (Object.keys(menuItems).length !== 0) {
    items = menuItems.main;
  }
  return items;
}
function changeOrderingAccordingKeyList(list, keyList) {
  keyList.map(function (key, index) {
    var itemToMove;
    list.forEach(function (item, index) {
      if (item.menuKey === key) {
        return itemToMove = list.splice(index, 1);
      }
    });
    list.splice(index, 0, itemToMove[0]);
  });
  return list;
}
function changeToOneLevelMenu(list) {
  return list.reduce(function (a, b) {
    return b.children ? a.concat(b.children) : a.concat(b);
  }, []);
}
function updateComplianceItems(list) {
  list.forEach(function (item, index) {
    if (item.menuKey === MenuKeys.awareness) {
      var processLib = list.find(function (item) {
        return item.menuKey === MenuKeys.processLib;
      });
      processLib.children.push(item);
      list.splice(index, 1);
    }
  });
  // Move Process Lib and InformationAssets to end of the list
  var firstEl = list.splice(0, 2);
  return [].concat(_toConsumableArray(list), _toConsumableArray(firstEl));
}
function changeRiskNavItems(list) {
  list.forEach(function (item) {
    item.children = changeOrderingAccordingKeyList(item.children, [MenuKeys.myRisks]);
  });
  return list;
}
export function buildMainNavMenuStructure(settings, currentUser) {
  var customIA = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var mainNavStructure = {};
  var complianceMenu = buildComplianceMenu(currentUser.projects, currentUser, settings, i18n, customIA);
  var controlsMenu = buildControlsMenu(currentUser, settings);
  controlsMenu = changeToOneLevelMenu(controlsMenu.main);
  var incidentMenu = buildIncidentsMenu(settings, currentUser);
  var actionsMenu = buildActionsMenu(currentUser, settings);
  var risksMenu = buildRisksMenu(settings, currentUser);
  mainNavStructure['risk'] = changeRiskNavItems(risksMenu.main);
  mainNavStructure['actions'] = changeOrderingAccordingKeyList(actionsMenu.main, [MenuKeys.myInitiatives, MenuKeys.initiatives, MenuKeys.reports]);
  mainNavStructure['controls'] = changeOrderingAccordingKeyList(controlsMenu, [MenuKeys.myControls, MenuKeys.controls, MenuKeys.reports, MenuKeys.statusOverview, MenuKeys.annualOverview]);
  mainNavStructure['incident'] = changeOrderingAccordingKeyList(incidentMenu.main, [MenuKeys.myIncidents, MenuKeys.incidents, MenuKeys.incidentsArchived, MenuKeys.reports]);
  mainNavStructure['compliance'] = updateComplianceItems(complianceMenu.main);
  mainNavStructure['contracts'] = buildContractsMenu(currentUser);
  mainNavStructure['policies'] = buildPoliciesMenu(settings, currentUser);
  mainNavStructure['assets'] = buildAssetsMenu(settings, currentUser, customIA);
  return mainNavStructure;
}