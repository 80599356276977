import i18n from '@/i18n';
export var getTranslate = {
  SavedReports: function SavedReports() {
    return {
      shareWithAll: i18n.t('Share with all'),
      theSavedReportWillBeDeleted: i18n.t('The saved report will be deleted'),
      defaultReports: i18n.t('Default reports'),
      sharedReports: i18n.t('Shared reports'),
      myReports: i18n.t('My reports'),
      completed: i18n.t('Completed'),
      controlRejected: i18n.t('Control rejected'),
      pendingReview: i18n.t('Pending review'),
      completedControls: i18n.t('Completed controls'),
      thisReportOnlyShowsTheCompletedControls: i18n.t('This report only shows the completed controls'),
      completedControlsPastTheDeadline: i18n.t('Completed controls past the deadline'),
      thisReportOnlyShowsControlsThatWasCompleted: i18n.t('This report only shows controls that was completed after the deadline'),
      pendingControlsPastTheDeadline: i18n.t('Pending controls past the deadline'),
      thisReportOnlyShowsTheControlsThatArePast: i18n.t('This report only shows the controls that are past deadline and not yet completed'),
      pending: i18n.t('Pending'),
      noSavedReports: i18n.t('No saved reports'),
      deleteReport: i18n.t('Delete report'),
      delete: i18n.t('Delete')
    };
  },
  ReportSavingModal: function ReportSavingModal() {
    return {
      enterYourOwnTitleForThisReportHere: i18n.t('Enter your own title for this report here'),
      enterYourCommentsHere: i18n.t('Enter your comments here'),
      saveReport: i18n.t('Save report'),
      saveCurrentReport: i18n.t('Save current report'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      comments: i18n.t('Comments')
    };
  }
};