import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["width", "height", "innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "currentColor",
    width: "".concat($props.width, "px"),
    height: "".concat($props.height, "px"),
    class: _normalizeClass($props.fillClass),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('click', $event);
    }),
    innerHTML: $options.svg
  }, null, 10 /* CLASS, PROPS */, _hoisted_1);
}