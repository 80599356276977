function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { sortAlphabetic } from '@/utils/sort';
import { ObjectTypes } from '@/constants/ObjectTypes';
export var InformationasstesOverviewMixin = {
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    systemsEnabled: function systemsEnabled() {
      return this.getSettingValue('feature.enable_systems') == undefined || this.getSettingValue('feature.enable_systems');
    }
  }),
  methods: {
    filterByObjTypes: function filterByObjTypes(informationAssets, activityTypes) {
      var iaListByObjType = [];
      var MAX_CONST_ID = 3;
      if (this.getSettingValue('feature.enable_data_controllers') == undefined || this.getSettingValue('feature.enable_data_controllers')) {
        var _informationAssets$co, _informationAssets$co2;
        iaListByObjType.push({
          id: 1,
          label: AssetsTypes.CONTROLLERS,
          type: AssetsTypes.CONTROLLERS,
          objType: ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER,
          list: ((_informationAssets$co = informationAssets.controllers) === null || _informationAssets$co === void 0 ? void 0 : _informationAssets$co.list) || [],
          total: ((_informationAssets$co2 = informationAssets.controllers) === null || _informationAssets$co2 === void 0 ? void 0 : _informationAssets$co2.total) || 0
        });
      }
      if (this.getSettingValue('feature.enable_data_processors') == undefined || this.getSettingValue('feature.enable_data_processors')) {
        var _informationAssets$pr, _informationAssets$al, _informationAssets$pr2;
        iaListByObjType.push({
          id: 2,
          label: AssetsTypes.PROCESSORS,
          type: AssetsTypes.PROCESSORS,
          objType: ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR,
          list: ((_informationAssets$pr = informationAssets.processors) === null || _informationAssets$pr === void 0 ? void 0 : _informationAssets$pr.list) || [],
          fullList: ((_informationAssets$al = informationAssets.allProcessors) === null || _informationAssets$al === void 0 ? void 0 : _informationAssets$al.list) || [],
          total: ((_informationAssets$pr2 = informationAssets.processors) === null || _informationAssets$pr2 === void 0 ? void 0 : _informationAssets$pr2.total) || 0
        });
      }
      if (this.systemsEnabled) {
        var _informationAssets$sy, _informationAssets$sy2;
        iaListByObjType.push({
          id: MAX_CONST_ID,
          label: AssetsTypes.SYSTEMS,
          type: AssetsTypes.SYSTEMS,
          objType: ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM,
          list: ((_informationAssets$sy = informationAssets.systems) === null || _informationAssets$sy === void 0 ? void 0 : _informationAssets$sy.list) || [],
          total: ((_informationAssets$sy2 = informationAssets.systems) === null || _informationAssets$sy2 === void 0 ? void 0 : _informationAssets$sy2.total) || 0
        });
      }
      if (activityTypes && activityTypes.length) {
        var activityTypesLength = 1;
        activityTypes.forEach(function (activityType) {
          var _informationAssets$cu;
          iaListByObjType.push({
            id: MAX_CONST_ID + activityTypesLength,
            label: activityType.label,
            type: AssetsTypes.INFORMATION_ASSETS,
            objType: activityType.module,
            activityTypeId: activityType.id,
            list: ((_informationAssets$cu = informationAssets.customIA) === null || _informationAssets$cu === void 0 ? void 0 : _informationAssets$cu.filter(function (ia) {
              return +ia.activityTypeId === +activityType.id;
            })) || [],
            defaultQuestionnaireId: activityType.defaultQuestionnaireId || ''
          });
          activityTypesLength++;
        });
      }
      return iaListByObjType;
    },
    updateFilteredList: function updateFilteredList(filteredList) {
      this.activityTypes.forEach(function (item) {
        return filteredList[item.label] = [];
      });
    },
    formatColumnsIA: function formatColumnsIA(objType) {
      var withAction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var columns = [];
      if (objType === AssetsTypes.PROCESSORS) {
        columns = ['', this.$trans('ID'), this.$trans('Name')];
        if (this.systemsEnabled) {
          columns.push(this.$trans('Systems'));
        }
        columns.push(this.$trans('Processes'));
      } else if (objType === AssetsTypes.SYSTEMS) {
        columns = ['', this.$trans('ID'), this.$trans('Name'), this.$trans('Procs.'), this.$trans('Processes')];
      } else if (objType === AssetsTypes.CONTROLLERS) {
        columns = ['', this.$trans('ID'), this.$trans('Name'), this.$trans('Processes')];
      } else if (objType === AssetsTypes.INFORMATION_ASSETS) {
        columns = ['', this.$trans('Name'), this.$trans('Processes')];
      }
      if (withAction) {
        columns.push('');
      }
      return columns;
    },
    getProcessorItem: function getProcessorItem(item, objType, withAction) {
      var _this = this;
      var item_data = {
        'trafficlight': DatatableRenderer.prepareTrafficLightField(item.trafficLight),
        'id': item.uniqueId,
        name: {
          data: getFieldsFromObjectForPreview(item),
          name: item.name
        },
        'systems': item.systems ? item.systems.length : 0,
        'processes': item.complianceNodeCount,
        'actions': {
          disableAction: item.complianceNodeCount > 0,
          objType: objType,
          id: item.id
        },
        'children': []
      };
      if (!this.systemsEnabled) {
        delete item_data.systems;
      }
      if (!withAction) {
        delete item_data.actions;
      }
      if (item.children.length > 0) {
        var childrenList = sortAlphabetic(item.childrenObjects, 'name');
        childrenList.forEach(function (child, index) {
          var childItem = {
            'trafficlight': DatatableRenderer.prepareTrafficLightField(child.trafficLight),
            'id': child.uniqueId,
            name: {
              data: getFieldsFromObjectForPreview(child),
              name: child.name
            },
            'systems': child.systems ? child.systems.length : 0,
            'processes': child.complianceNodeCount,
            'actions': {
              disableAction: child.complianceNodeCount > 0,
              objType: objType,
              id: child.id
            }
          };
          if (!_this.systemsEnabled) {
            delete childItem.systems;
          }
          item_data.children.push(childItem);
          if (!withAction) {
            delete item_data.children[index].actions;
          }
        });
      } else {
        delete item_data.children;
      }
      return item_data;
    },
    getSystemItem: function getSystemItem(item, objType, withAction) {
      var itemData = {
        'trafficlight': DatatableRenderer.prepareTrafficLightField(item.trafficLight),
        'id': item.uniqueId,
        name: {
          data: getFieldsFromObjectForPreview(item),
          name: item.name
        },
        'processors': item.processors.length,
        'processes': item.complianceNodeCount,
        'actions': {
          disableAction: item.complianceNodeCount > 0 || item.children && item.children.length > 0,
          objType: objType,
          id: item.id
        },
        'children': []
      };
      if (!withAction) {
        delete itemData.actions;
      }
      if (item.childrenObjects && item.childrenObjects.length) {
        var childrenList = sortAlphabetic(item.childrenObjects, 'name');
        childrenList.forEach(function (child, index) {
          itemData.children.push({
            'trafficlight': DatatableRenderer.prepareTrafficLightField(child.trafficLight),
            'id': child.uniqueId,
            name: {
              data: getFieldsFromObjectForPreview(child),
              name: child.name
            },
            'processors': child.processors.length,
            'processes': child.complianceNodeCount,
            'actions': {
              disableAction: child.complianceNodeCount > 0,
              objType: objType,
              id: child.id
            }
          });
          if (!withAction) {
            delete itemData.children[index].actions;
          }
        });
      }
      if (itemData.children.length === 0) {
        delete itemData.children;
      }
      return itemData;
    },
    getControllerItem: function getControllerItem(item, objType, withAction) {
      var itemData = {
        'trafficlight': DatatableRenderer.prepareTrafficLightField(item.trafficLight),
        'id': item.uniqueId,
        name: {
          data: getFieldsFromObjectForPreview(item),
          name: item.name
        },
        'processes': item.complianceNodeCount > 0 ? item.complianceNodeCount : 0,
        'actions': {
          disableAction: item.complianceNodeCount > 0,
          objType: objType,
          id: item.id
        }
      };
      if (!withAction) {
        delete itemData.actions;
      }
      return itemData;
    },
    getInformationAssetItem: function getInformationAssetItem(item) {
      var _this$activityTypes$f;
      return {
        'trafficlight': DatatableRenderer.prepareTrafficLightField(item.trafficLight),
        name: {
          data: getFieldsFromObjectForPreview(item),
          name: item.name || item.title
        },
        'processes': item.complianceNodeCount > 0 ? item.complianceNodeCount : 0,
        'actions': {
          disableAction: item.complianceNodeCount > 0,
          objType: 'informationAssets',
          id: item.id,
          deleteLabel: ((_this$activityTypes$f = this.activityTypes.find(function (activityType) {
            return activityType.id === item.activityTypeId;
          })) === null || _this$activityTypes$f === void 0 ? void 0 : _this$activityTypes$f.label) || ''
        }
      };
    },
    formatDataIA: function formatDataIA(items, objType) {
      var _this2 = this;
      var withAction = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var data = [];
      items.forEach(function (item) {
        if (objType === AssetsTypes.PROCESSORS) {
          data.push(_this2.getProcessorItem(item, objType, withAction));
        } else if (objType === AssetsTypes.SYSTEMS) {
          data.push(_this2.getSystemItem(item, objType, withAction));
        } else if (objType === AssetsTypes.CONTROLLERS) {
          data.push(_this2.getControllerItem(item, objType, withAction));
        } else if (objType === AssetsTypes.INFORMATION_ASSETS) {
          data.push(_this2.getInformationAssetItem(item));
        }
      });
      return data.filter(Boolean);
    },
    getActivityTypeByProperty: function getActivityTypeByProperty(propertyName, value) {
      return this.activityTypes.find(function (type) {
        return type[propertyName] === value;
      });
    },
    getObjectType: function getObjectType(label) {
      var activityType = this.getActivityTypeByProperty('label', label);
      if (activityType) {
        return "informationAssets/".concat(activityType.id);
      }
      return label;
    }
  }
};