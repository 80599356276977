function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
export default {
  name: 'Pagination',
  introduction: 'Show pagination for arrays',
  description: "Pagination gets length of array as total property and amount of items per page.\n        When the component mounted it emit an event \"change\" with object of indexes.\n        The object contains {start: Number, end: Number} indexes of begin / end extraction from full array.\n        For example, you have an array [11] and 5 items per page. If user click on second page the component emit\n        the event with {start: 5, end: 10}.\n        Don't slice an origin array, use filtred result - https://vuejs.org/v2/guide/list.html#Displaying-Filtered-Sorted-Results",
  token: "<pagination :total=\"Number\" :per-page=\"Number\" @change=\"handleShownList\" />\n        function handleShownList(indexes) {\n            return originArray.slice(indexes.start, indexes.end + 1);\n        }",
  props: {
    total: {
      type: Number,
      required: true,
      note: 'Number of amount items. For example, array.length'
    },
    perPage: {
      type: Number,
      required: true,
      note: 'How many items for per page'
    },
    maxLength: {
      required: false,
      type: Number,
      default: 0,
      note: 'Max length (filtered from)'
    },
    showPrevNextButtons: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Show Previous/Next buttons?'
    },
    stateSession: {
      type: String,
      default: '',
      note: 'The name of a key in sessionStorage'
    },
    additionalClasses: {
      type: String,
      default: '',
      note: 'Additional classes'
    },
    activePageNumber: {
      type: Number,
      default: 1
    }
  },
  emits: ['totalChanged', 'change'],
  data: function data() {
    return {
      currentPage: this.activePageNumber,
      step: 4,
      translate: getTranslate['Pagination']()
    };
  },
  computed: {
    pages: function pages() {
      return Math.ceil(this.total / this.perPage);
    },
    // Index starts from 0
    showItemsStartIndex: function showItemsStartIndex() {
      return this.currentPage * this.perPage - this.perPage;
    },
    // Index ends with length of array - 1
    showItemsEndIndex: function showItemsEndIndex() {
      var possibilityEndItems = this.perPage * this.currentPage;
      return (possibilityEndItems > this.total ? this.total : possibilityEndItems) - 1;
    },
    paginationText: function paginationText() {
      var result = '';
      var start = 0;
      var end = 0;
      var total = 0;
      if (this.pages >= 1) {
        start = this.showItemsStartIndex + 1;
        end = this.showItemsEndIndex + 1;
        total = this.total;
      }
      result = this.translate.showingStartToEndOfTotalRecords.replace('_START_', start).replace('_END_', end).replace('_TOTAL_', total);
      return result;
    },
    paginationFilteredFromText: function paginationFilteredFromText() {
      var result = '';
      if (!this.maxLength) {
        return result;
      }
      if (this.maxLength > this.total) {
        result = this.translate.filteredFromMaxRecords.replace('_MAX_', this.maxLength);
      }
      return result;
    }
  },
  watch: {
    activePageNumber: function activePageNumber(value) {
      if (this.currentPage != value) {
        this.currentPage = value;
      }
    },
    perPage: function perPage() {
      this.currentPage = 1;
      this.$emit('change', {
        start: this.showItemsStartIndex,
        end: this.showItemsEndIndex,
        currentPage: this.currentPage
      });
    },
    total: function total() {
      this.changePage(1);
      this.$emit('totalChanged', {
        start: this.showItemsStartIndex,
        end: this.showItemsEndIndex
      });
    }
  },
  mounted: function mounted() {
    this.$emit('change', {
      start: this.showItemsStartIndex,
      end: this.showItemsEndIndex,
      currentPage: this.currentPage,
      mounted: true
    });
    this.setFromStorage();
  },
  methods: {
    setFromStorage: function setFromStorage() {
      if (!this.stateSession) return;
      var savedSession = JSON.parse(sessionStorage.getItem(this.stateSession));
      if (savedSession !== null && savedSession !== void 0 && savedSession.page) this.changePage(+savedSession.page);
    },
    changePage: function changePage(page) {
      if (page < 1 || page > this.pages) {
        return;
      }
      this.currentPage = page;
      this.$emit('change', {
        start: this.showItemsStartIndex,
        end: this.showItemsEndIndex,
        currentPage: this.currentPage
      });
      this.saveToStorage();
    },
    saveToStorage: function saveToStorage() {
      if (!this.stateSession) return;
      var savedSession = JSON.parse(sessionStorage.getItem(this.stateSession)) || {};
      sessionStorage.setItem(this.stateSession, JSON.stringify(_objectSpread(_objectSpread({}, savedSession), {}, {
        page: this.currentPage
      })));
    },
    resetPage: function resetPage() {
      if (this.stateSession) {
        this.setFromStorage();
        return;
      }
      this.currentPage = 1;
    },
    canBeShown: function canBeShown(page) {
      var result = true;
      if (!this.showPrevNextButtons) {
        return result;
      }
      if (this.pages > this.step && page !== 1 && page !== this.pages) {
        if (page === this.currentPage) {
          result = true;
        } else result = page === this.currentPage - 1 || page === this.currentPage + 1;
      }
      return result;
    }
  }
};