function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { Colors } from '@/Colors';
import { INCIDENT_STATES } from '@/constants/IncidentStateCodes';
import { RemoteTicketStates } from '@/constants/RemoteTicketStates';
import i18n from '@/i18n';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { findPropsByIds } from '@/utils/Utils';
import { RiskProjects } from '@/constants/risks/RiskProjects';
import { useUsersStore } from '@/Store/usersStore';
import { getProbabilityLabel, getConsequenceLabel } from '@/utils/risks/riskPeriod';
import { toLocalDateTimeFull } from '@/utils/date';
var getAllUsers = function getAllUsers() {
  return useUsersStore().users;
};
var stateMapping = {};
Object.keys(INCIDENT_STATES).forEach(function (key) {
  stateMapping[INCIDENT_STATES[key].value] = INCIDENT_STATES[key].name;
});
var prepareColorField = function prepareColorField(value, color) {
  return {
    value: value,
    color: color.color,
    label: color.label
  };
};
export var DatatableRenderer = {
  prepareTrafficLightField: function prepareTrafficLightField(value) {
    if (Number.isNaN(parseInt(value))) return value;
    var color = Colors.getTrafficlightByNumber(value);
    return prepareColorField(value, color);
  },
  prepareGapColorField: function prepareGapColorField(value) {
    var color = Colors.gapColor(value);
    return prepareColorField(value, color);
  },
  prepareSeverityField: function prepareSeverityField(value) {
    var color = Colors.findIncidentSeverity(value);
    return prepareColorField(value, color);
  },
  prepareCompanyColorField: function prepareCompanyColorField(value) {
    var color = Colors.getCompanyTrafficlightByNumber(+value);
    return prepareColorField(value, color);
  },
  prepareLinks: function prepareLinks(value, vm) {
    window.$router = vm.$router; //jquery access to router, didn`t find other way
    var output = '';
    if (value.length > 0) {
      value.forEach(function (link) {
        if (!link.link) {
          output += "<p>".concat(link.title, "</p>");
        } else if (link.isRouterLinkWithClick) {
          var onClick = "onClick=\"if(event.ctrlKey === false && event.metaKey === false) {window.$router.push('".concat(link.link, "'); return false;}\"");
          output += "<p><a ".concat(onClick, " href=\"").concat(link.link, "\">").concat(link.title, "</a></p>");
        } else if (link.isRouterLink) {
          output += "<p><router-link to=\"".concat(link.link, "\">").concat(link.title, "</router-link></p>");
        } else {
          output += "<p><a href=\"".concat(link.link, "\">").concat(link.title, "</a></p>");
        }
      });
    }
    return output;
  },
  datetime: function datetime(value) {
    return toLocalDateTimeFull(value);
  },
  incidentState: function incidentState(value) {
    return i18n.t(stateMapping[value]);
  },
  getRemoteTicketStateName: function getRemoteTicketStateName(value) {
    var state = RemoteTicketStates.find(function (state) {
      return state.id === +value;
    });
    return state ? state.value : value;
  },
  getActivityTypeName: function getActivityTypeName(value, activityTypes) {
    if (value == null || value == '') {
      return '';
    }
    var activityType = activityTypes.find(function (item) {
      return item.id == value;
    });
    if (activityType === undefined) {
      return '';
    }
    return activityType.label;
  },
  getLinkedItemsAsRouterLink: function getLinkedItemsAsRouterLink(idList, allItems) {
    var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    var postfixFn = arguments.length > 3 ? arguments[3] : undefined;
    var titleColumn = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'title';
    var urlFn = arguments.length > 5 ? arguments[5] : undefined;
    if (!idList) return {
      field: '',
      fieldText: ''
    };
    var fieldList = [];
    var fieldTextList = [];
    var list = Array.isArray(idList) ? idList : Object.values(idList);
    list.forEach(function (id) {
      var item = allItems.find(function (i) {
        return i.id == id;
      });
      if (!item) return;
      var postfix = postfixFn ? postfixFn(item) : '';
      var title = prefix ? "".concat(item[prefix], " ").concat(item[titleColumn]) : item[titleColumn];
      title = "".concat(title).concat(postfix);
      fieldList.push({
        url: urlFn ? urlFn(item) : item.url,
        title: item[titleColumn]
      });
      fieldTextList.push(title);
    });
    return {
      field: fieldList,
      fieldText: fieldTextList
    };
  },
  getLinkedItemsAsPreview: function getLinkedItemsAsPreview(idList, allItems) {
    var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    var postfixFn = arguments.length > 3 ? arguments[3] : undefined;
    var separator = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : ', ';
    if (!idList) return {
      activities: [],
      fieldText: ''
    };
    var activities = [];
    var allItemsText = [];
    var list = Array.isArray(idList) ? idList : Object.values(idList);
    list.forEach(function (id) {
      var item = allItems.find(function (i) {
        return +i.id === +id;
      });
      if (!item) return;
      var postfix = postfixFn ? postfixFn(item) : '';
      var title = prefix ? "".concat(item[prefix], " ").concat(item.title) : item.title;
      title = "".concat(title).concat(postfix);
      allItemsText.push(title);
      activities.push({
        activity: getFieldsFromObjectForPreview(item),
        fieldText: title
      });
    });
    allItemsText = allItemsText.join(separator);
    return {
      activities: activities,
      fieldText: allItemsText
    };
  },
  getRiskValues: function getRiskValues(risk, riskProjects) {
    var _residualConsequenceR, _residualProbabilityR;
    var project = riskProjects.find(function (project) {
      return +project.id === +risk.projectId;
    });
    if (project && project.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type) {
      return " (".concat(i18n.t('Impact'), ": ").concat(risk.impact_score, ", ").concat(i18n.t('Likelihood'), ": ").concat(risk.likelihood_score, ")");
    }
    var period = project === null || project === void 0 ? void 0 : project.projectPeriods.find(function (period) {
      return +period.id === +risk.projectPeriodId;
    });
    var residualConsequenceRowScore = risk.residualConsequenceRowScores && risk.residualConsequenceRowScores[0];
    var residualProbabilityRowScore = risk.residualProbabilityRowScores && risk.residualProbabilityRowScores[0];
    if (!period || !(residualConsequenceRowScore !== null && residualConsequenceRowScore !== void 0 && residualConsequenceRowScore.column) || !(residualProbabilityRowScore !== null && residualProbabilityRowScore !== void 0 && residualProbabilityRowScore.column)) return '';
    var periodLabel = period.consequenceRows[0].label;
    var riskImpact = (_residualConsequenceR = residualConsequenceRowScore.column) === null || _residualConsequenceR === void 0 ? void 0 : _residualConsequenceR.value;
    var riskProbability = (_residualProbabilityR = residualProbabilityRowScore.column) === null || _residualProbabilityR === void 0 ? void 0 : _residualProbabilityR.value;
    return " (".concat(periodLabel, " ").concat(getProbabilityLabel(period).toLowerCase(), ": ").concat(riskImpact, ", ").concat(periodLabel, " ").concat(getConsequenceLabel(period).toLowerCase(), ": ").concat(riskProbability, ")");
  },
  getItemHoverTitle: function getItemHoverTitle(item) {
    var defaultTitle = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var hoverTitle = defaultTitle || item.title || '';
    if (item.confidential) {
      var _item$userIds, _item$userIds2;
      var responsible = (item === null || item === void 0 ? void 0 : item.responsibleUserIds) || ((_item$userIds = item.userIds) === null || _item$userIds === void 0 ? void 0 : _item$userIds.responsible) || [];
      var otherUsers = (item === null || item === void 0 ? void 0 : item.accountableUserIds) || ((_item$userIds2 = item.userIds) === null || _item$userIds2 === void 0 ? void 0 : _item$userIds2.review) || [];
      var users = [].concat(_toConsumableArray(responsible), _toConsumableArray(otherUsers));
      var uniqueUsersById = _toConsumableArray(new Set(users));
      var confidentialityNames = findPropsByIds(uniqueUsersById, getAllUsers(), 'display_name');
      var note = uniqueUsersById.length > 1 ? i18n.t('are able to see and edit this activity') : i18n.t('is able to see and edit this activity');
      hoverTitle = "".concat(i18n.t('Only'), " ").concat(confidentialityNames, " ").concat(note);
    }
    return hoverTitle;
  }
};