import { getTranslate } from './translate';
import VueMultiselect from 'vue-multiselect';
import { sortAlphabetic } from '@/utils/sort';
import Alert from '@/components/Molecules/Modal/Alert';
var MULTISELECT_TAG_CSS = 'multiselect__tag';
var MULTISELECT_TAGS_CSS = 'multiselect__tags';
var MULTISELECT_TAG_ICON_CSS = 'multiselect__tag-icon';
export default {
  name: 'RismaSelect',
  introduction: 'Select for selecting things',
  description: "Risma-select creates a select from options and selected options given.\n        When selecting items in the select, it will emit a \"selected\" event, with the selected items.\n        To make a custom markup of option need to pass '<template v-slot:slot:optionCustom=\"props\">HTML</template>. '",
  token: "<risma-select :options='testOptions' :selectedOptions='testSelected' labelKey=\"option\" placeholder=\"Test\" @selected=\"someFunc\"/>'\n        testOptions: [\n            {id:'1', option:'Hello'},\n            {id:'2', option:'To'},\n            {id:'3', option:'You'}\n        ],\n        testSelected:[\n            {id:'2', option:'To'}'\n        ]",
  components: {
    VueMultiselect: VueMultiselect,
    Alert: Alert
  },
  props: {
    options: {
      type: Array,
      required: true,
      note: 'All options given to the select'
    },
    enableSelectAll: {
      type: Boolean,
      default: true,
      note: 'Boolean to determine if show `Select All` feature in select'
    },
    confirmSelectAll: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If show confirmation popup on `Select All`'
    },
    selectedOptions: {
      type: [Array, Object],
      default: function _default() {
        return [];
      },
      note: 'The already selected options'
    },
    trackBy: {
      required: false,
      type: String,
      default: 'id',
      note: 'Key to compare options by'
    },
    labelKey: {
      type: String,
      required: true,
      note: 'The key to create the labels by'
    },
    labelFunction: {
      type: Function,
      default: null,
      note: 'Function to generate label'
    },
    deselectLabel: {
      type: String,
      default: null,
      note: 'The label shown to deselect a option'
    },
    selectedLabel: {
      type: String,
      default: null,
      note: 'The label shown on selected options'
    },
    selectLabel: {
      type: String,
      default: null,
      note: 'The label shown to select an option'
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
      note: 'Label that is visible when no options are selected'
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
      note: 'Boolean to determine if the select should close when an option is selected'
    },
    modelValue: {
      type: Array,
      required: false,
      default: null,
      note: 'V-model support. Kind of. Only works one-way'
    },
    maxVisibleTags: {
      required: false,
      type: Number,
      default: 6,
      note: 'the max number of selected tags to be displayed. After the number has been reached the select will display: "{number} selected", instead of tags. if prop is -1 it will display all'
    },
    allowEmpty: {
      required: false,
      type: Boolean,
      default: true,
      note: 'Allows to remove all selected values. Otherwise one must be left selected.'
    },
    searchable: {
      required: false,
      default: true,
      type: Boolean,
      note: 'should the list e searchable?'
    },
    tagClickable: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Enable tag click functionality?'
    },
    groupSelect: {
      type: Boolean,
      required: false,
      default: false,
      note: 'group-select is used to define if selecting the group label should select/unselect all values in the group, or do nothing.'
    },
    groupValues: {
      type: String,
      required: false,
      default: '',
      note: 'group-values should point to the group’s option list.'
    },
    groupLabel: {
      type: String,
      required: false,
      default: '',
      note: 'The key to create the labels by'
    },
    unSorted: {
      required: false,
      default: false,
      type: Boolean,
      note: 'Do not sort items by label - keep in the order that was given'
    },
    openDirection: {
      required: false,
      default: '',
      type: String,
      note: 'Fixed opening direction (instead of auto). Options are "above"/"top" or "below"/"bottom'
    },
    selectedOnTop: {
      required: false,
      default: true,
      type: Boolean,
      note: 'If selected options should be shown on top of the list in dropdown'
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Set to true to disable switch'
    },
    optionsWithoutTagIcon: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of ids for options that should be without tag icon'
    },
    taggable: {
      type: Boolean,
      required: false,
      default: false,
      note: 'taggable'
    },
    tagPlaceholder: {
      type: String,
      required: false,
      default: ''
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true
    },
    showLabels: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Decide whether to show labels on highlighted options'
    },
    isSelectedSorted: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Decide whether we should show selected values sorted or not, used on dataflow'
    }
  },
  emits: ['selected', 'update:modelValue', 'tag', 'initialized', 'close', 'tagClicked', 'open'],
  data: function data() {
    return {
      selected: [],
      values: null,
      showSelectAllModal: false,
      alertBody: '',
      event: null,
      translate: getTranslate['RismaSelect']()
    };
  },
  computed: {
    deselectLabelWithDefault: function deselectLabelWithDefault() {
      if (this.deselectLabel === null) {
        return this.translate.pressEnterToRemove;
      }
      return this.deselectLabel;
    },
    selectLabelWithDefault: function selectLabelWithDefault() {
      if (this.selectLabel === null) {
        return this.translate.pressEnterToSelect;
      }
      return this.selectLabel;
    },
    selectedLabelWithDefault: function selectedLabelWithDefault() {
      if (this.selectedLabel === null) {
        return this.translate.selected;
      }
      return this.selectedLabel;
    },
    selectAllObj: function selectAllObj() {
      var selectAll = {};
      selectAll[this.trackBy] = '0';
      selectAll[this.labelKey] = this.translate.selectAll;
      return selectAll;
    },
    isSlotOptionPassed: function isSlotOptionPassed() {
      return !!this.$slots.optionCustom;
    },
    hasGroups: function hasGroups() {
      return this.groupValues !== '' && this.groupLabel !== '';
    },
    isSelectAllAvailable: function isSelectAllAvailable() {
      return this.multiple && this.enableSelectAll;
    }
  },
  watch: {
    options: {
      deep: true,
      handler: function handler() {
        this.refreshValues();
      }
    },
    selectedOptions: {
      deep: true,
      handler: function handler() {
        var _this = this;
        if (this.selectedOptions) {
          this.selected = this.getSelectedOptions();
        } else {
          this.selected = [];
        }
        this.$nextTick(function () {
          return _this.refreshValues();
        });
      }
    }
  },
  created: function created() {
    this.selected = this.getSelectedOptions();
    this.refreshValues();
  },
  mounted: function mounted() {
    this.$emit('initialized', this.$el);
  },
  methods: {
    getSelectedOptions: function getSelectedOptions() {
      return this.isSelectedSorted ? sortAlphabetic(this.selectedOptions.slice(0), this.labelKey) : this.selectedOptions.slice(0);
    },
    refreshValues: function refreshValues() {
      this.values = this.groupValues ? this.sortGroupValuesBySelected() : this.sortValuesBySelected();
      if (this.hasGroups || !this.isSelectAllAvailable) return;
      if (this.values.length > 0 && this.values[0][this.labelKey] !== this.translate.selectAll) {
        this.values.unshift(this.selectAllObj);
      }
      if (this.selected.length === this.values.length - 1) {
        this.selected.unshift(this.selectAllObj);
      }
    },
    sortGroupValuesBySelected: function sortGroupValuesBySelected() {
      var _this2 = this;
      var labelKeys = this.selected.map(function (item) {
        return item[_this2.labelKey];
      });
      var selectedValues = [];
      var groups = this.options.slice(0);
      var sortedGroups = [];
      groups.forEach(function (group) {
        var notSelectedValues = [];
        var values = group.items.slice(0);
        values.forEach(function (value) {
          if (labelKeys.find(function (item) {
            return item === value[_this2.labelKey];
          })) {
            selectedValues.push(value);
          } else {
            notSelectedValues.push(value);
          }
        });
        sortedGroups.push({
          label: group.label,
          items: notSelectedValues
        });
      });
      return [{
        label: 'selected',
        items: selectedValues
      }].concat(sortedGroups);
    },
    sortValuesBySelected: function sortValuesBySelected() {
      var _this3 = this;
      var values = this.unSorted ? this.options.slice(0) : sortAlphabetic(this.options.slice(0), this.labelKey);
      if (!this.selectedOnTop) return values;
      var labelKeys = this.selected.map(function (item) {
        return item[_this3.labelKey];
      });
      var selectedValues = [];
      var notSelectedValues = [];
      values.forEach(function (value) {
        if (labelKeys.find(function (item) {
          return item === value[_this3.labelKey];
        })) {
          selectedValues.push(value);
        } else {
          notSelectedValues.push(value);
        }
      });
      return [].concat(selectedValues, notSelectedValues);
    },
    /**
     * return label of option
     * @param option
     */
    createLabel: function createLabel(option) {
      if (this.labelFunction === null) {
        return option[this.labelKey];
      } else {
        return this.labelFunction(option);
      }
    },
    /**
     * handles emitting of new selected objects
     * @param event
     */
    handleSelected: function handleSelected(event) {
      var isSelectedAllChosen = false;
      if (event && !Array.isArray(event)) {
        event = [event];
      }
      if (event && event.length) {
        isSelectedAllChosen = event ? event.length > 0 && event[event.length - 1][this.trackBy] === '0' : false;
      }
      if (this.confirmSelectAll && isSelectedAllChosen) {
        this.event = event;
        this.showSelectAllAlert();
        return;
      }
      this.processSelectedEvent(event);
    },
    processSelectedEvent: function processSelectedEvent(event) {
      var oldSelected = this.selected.slice(0);
      this.selected = event ? event : [];
      var selectAll = false;
      var wasSelectAll = false;
      if (Array.isArray(this.selected)) {
        selectAll = this.checkSelectAll(this.selected);
        wasSelectAll = this.checkSelectAll(oldSelected);
        // No selectAll support for options grouping yet (it has a lot of bugs)
        if (!this.groupValues) {
          selectAll = this.handleSelectAll(selectAll, wasSelectAll);
        }
      }
      this.emit(selectAll);
    },
    showSelectAllAlert: function showSelectAllAlert() {
      var numberOfItemsToAdd = this.options.length - this.selected.length;
      this.alertBody = "".concat(this.translate.areYouSureYouWantToAdd, "\n                ").concat(numberOfItemsToAdd, " ").concat(this.translate.newItems, "?");
      this.showSelectAllModal = true;
    },
    dismissSelectAll: function dismissSelectAll() {
      this.showSelectAllModal = false;
    },
    acceptSelectAll: function acceptSelectAll() {
      this.processSelectedEvent(this.event);
      this.showSelectAllModal = false;
    },
    handleClose: function handleClose() {
      this.$emit('close');
    },
    /**
     * Handles mousedown native (click works bad with focus)
     */
    handleMousedownNative: function handleMousedownNative(e) {
      // Tag link support
      if (this.tagClickable) {
        e.stopPropagation();
        e.preventDefault();
        var target = e ? e.target : null;
        var entity = null;
        if (!target) {
          return;
        }
        if (this.isRemoveElement(target)) {
          return;
        }
        if (this.isTagElement(target) || this.isTagChildElement(target)) {
          entity = this.findTag(target, this.selected);
          this.$emit('tagClicked', entity);
        }
        if (target.classList.contains(MULTISELECT_TAGS_CSS)) {
          this.$refs.select.toggle();
        }
      }
    },
    findTag: function findTag(target, selected) {
      var tagElement = this.isTagElement(target) ? target : target.parentNode;
      var index = $(this.$el).find(tagElement).index();
      return selected[index];
    },
    isRemoveElement: function isRemoveElement(target) {
      return target.classList.contains(MULTISELECT_TAG_ICON_CSS);
    },
    isTagElement: function isTagElement(target) {
      return target.classList.contains(MULTISELECT_TAG_CSS);
    },
    isTagChildElement: function isTagChildElement(target) {
      return target.parentNode && target.parentNode.classList.contains(MULTISELECT_TAG_CSS);
    },
    checkSelectAll: function checkSelectAll(selected) {
      var selectAll = false;
      for (var i = 0; i < selected.length; i++) {
        //determines if Select all has been selected
        if (selected[i][this.labelKey] === this.translate.selectAll) {
          selectAll = true;
          break;
        }
      }
      return selectAll;
    },
    handleSelectAll: function handleSelectAll(selectAll, wasSelectAll) {
      // if enableSelectAll is true means values + 'Select All';
      var valuesCount = this.isSelectAllAvailable ? this.values.length - 1 : this.values.length;
      if (this.selected.length === valuesCount) {
        //all items are selecting, therefore set select all
        if (!selectAll && !wasSelectAll) {
          selectAll = true;
        }
        //select all was disabled, therefore clear selection
        else if (!selectAll && wasSelectAll) {
          this.selected = [];
        }
        //an item was deselected, therefore deselect select all also
        else if (selectAll && wasSelectAll) {
          for (var i = 0; i < this.selected.length; i++) {
            if (this.selected[i][this.labelKey] === this.translate.selectAll) {
              this.selected.splice(i, 1);
              break;
            }
          }
          selectAll = false;
        }
      }
      return selectAll;
    },
    emit: function emit(selectAll) {
      if (selectAll) {
        this.selected = this.values.slice(0);
        this.$emit('selected', this.selected.slice(1));
        this.$emit('update:modelValue', this.selected.slice(1));
      } else {
        this.$emit('selected', this.selected);
        this.$emit('update:modelValue', this.selected);
      }
    }
  }
};