import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "flex justify-between mb-2"
};
var _hoisted_2 = {
  class: "flex items-center"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  class: "grid grid-cols-3 gap-4"
};
var _hoisted_5 = ["innerHTML"];
var _hoisted_6 = {
  key: 7,
  class: "mt-8"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$currentActi;
  var _component_alert = _resolveComponent("alert");
  var _component_contract_archive_modal = _resolveComponent("contract-archive-modal");
  var _component_contract_renew_modal = _resolveComponent("contract-renew-modal");
  var _component_contract_workflow_status_modal = _resolveComponent("contract-workflow-status-modal");
  var _component_contract_outsourcing_modal = _resolveComponent("contract-outsourcing-modal");
  var _component_notification = _resolveComponent("notification");
  var _component_confidential_box = _resolveComponent("confidential-box");
  var _component_risma_split_button = _resolveComponent("risma-split-button");
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  var _component_contract_timeline = _resolveComponent("contract-timeline");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_single_select = _resolveComponent("single-select");
  var _component_input_field_select = _resolveComponent("input-field-select");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_activity_custom_fields = _resolveComponent("activity-custom-fields");
  return _openBlock(), _createElementBlock("div", null, [$data.showArchiveAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    body: $data.translate.thereAreStillSomeRelatedContractsAttachedTo,
    header: $data.translate.warning,
    "button-dismiss-text": "",
    onAccept: _cache[0] || (_cache[0] = function ($event) {
      return $data.showArchiveAlert = false;
    }),
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return $data.showArchiveAlert = false;
    })
  }, null, 8 /* PROPS */, ["body", "header"])) : _createCommentVNode("v-if", true), $data.showArchiveModal ? (_openBlock(), _createBlock(_component_contract_archive_modal, {
    key: 1,
    archived: _ctx.archived,
    "workflow-status-id": _ctx.workflowStatusId,
    "workflow-statuses": $data.workflowStatuses,
    "contract-title": $props.data.title,
    "expiration-date-default": $data.endDate,
    onToggleArchived: $options.toggleArchived,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return $data.showArchiveModal = false;
    })
  }, null, 8 /* PROPS */, ["archived", "workflow-status-id", "workflow-statuses", "contract-title", "expiration-date-default", "onToggleArchived"])) : _createCommentVNode("v-if", true), $data.showRenewModal ? (_openBlock(), _createBlock(_component_contract_renew_modal, {
    key: 2,
    "notification-options": $options.notificationOptions,
    "status-list": $data.workflowStatuses,
    "currency-options": $data.currencyOptions,
    data: $props.data,
    onDismiss: _cache[3] || (_cache[3] = function ($event) {
      return $data.showRenewModal = false;
    }),
    onUpdate: $options.updateDataFromModal
  }, null, 8 /* PROPS */, ["notification-options", "status-list", "currency-options", "data", "onUpdate"])) : _createCommentVNode("v-if", true), $data.showWorkFlowModal ? (_openBlock(), _createBlock(_component_contract_workflow_status_modal, {
    key: 3,
    "workflow-status-id": $data.workflowStatusTemporaryId,
    onDismiss: _cache[4] || (_cache[4] = function ($event) {
      return $data.showWorkFlowModal = false;
    }),
    onAccept: $options.acceptNewWorkflowStatusNote
  }, null, 8 /* PROPS */, ["workflow-status-id", "onAccept"])) : _createCommentVNode("v-if", true), $data.showOutsourcingModal ? (_openBlock(), _createBlock(_component_contract_outsourcing_modal, {
    key: 4,
    "contract-title": $props.data.title,
    "contract-companies": $options.contractPartyIds,
    "outsourcing-projects": $data.outsourcingProjects,
    onDismiss: _cache[5] || (_cache[5] = function ($event) {
      return $data.showOutsourcingModal = false;
    }),
    onNodeCreated: $options.bindComplianceNodeToContract
  }, null, 8 /* PROPS */, ["contract-title", "contract-companies", "outsourcing-projects", "onNodeCreated"])) : _createCommentVNode("v-if", true), $data.showDeleteAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 5,
    body: $data.translate.areYouSureYouWishToPermanentlyDeleteThisC,
    header: $data.translate.deleteContract,
    "button-ok-text": $data.translate.delete,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.$emit('deleteContract');
    }),
    onDismiss: _cache[7] || (_cache[7] = function ($event) {
      return $data.showDeleteAlert = false;
    })
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text"])) : _createCommentVNode("v-if", true), _ctx.archived ? (_openBlock(), _createBlock(_component_notification, {
    key: 6,
    type: "error",
    closeable: false
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($options.archivedMessage), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [!!_ctx.getSettingValue('feature.contracts_confidential_enabled') ? (_openBlock(), _createBlock(_component_confidential_box, {
    key: 0,
    title: $data.translate.confidentialContract,
    confidential: !!_ctx.confidential,
    "read-only": $options.confidentialReadOnly,
    "confidentiality-names": $options.usersAllowed,
    onChanged: _cache[8] || (_cache[8] = function ($event) {
      return _ctx.$emit('propertyChanged', {
        property: 'confidential',
        value: +$event
      });
    })
  }, null, 8 /* PROPS */, ["title", "confidential", "read-only", "confidentiality-names"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_split_button, {
    text: $options.statusTitle,
    class: "mr-4",
    "no-hover": true,
    disabled: $props.readOnly,
    "disabled-dropdown": $props.readOnly
  }, {
    dropContent: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.workflowStatuses, function (status, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "p-1 px-2 rounded-md hover:bg-gray-350 cursor-pointer",
          onClick: function onClick($event) {
            return $options.onSetWorkflowStatus(status.id);
          }
        }, [_createElementVNode("button", null, _toDisplayString(_ctx.$trans(status.title)), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_3);
      }), 128 /* KEYED_FRAGMENT */))];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "disabled", "disabled-dropdown"]), $options.toggleMenuOptions.length ? (_openBlock(), _createBlock(_component_toggle_menu, {
    key: 0,
    options: $options.toggleMenuOptions,
    "menu-width": 270
  }, null, 8 /* PROPS */, ["options"])) : _createCommentVNode("v-if", true)])]), _createVNode(_component_contract_timeline, {
    data: $props.data,
    dates: $options.timelineDates,
    "contract-id": $props.data.id
  }, null, 8 /* PROPS */, ["data", "dates", "contract-id"]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_$options$currentActi = $options.currentActivityType) === null || _$options$currentActi === void 0 ? void 0 : _$options$currentActi.optionalFields, function (optionalField, optionalFieldIndex) {
    return _openBlock(), _createElementBlock("div", {
      key: optionalField,
      class: _normalizeClass({
        'col-span-3': optionalField === $data.OptionalFields.DESCRIPTION
      })
    }, [optionalField === $data.OptionalFields.DESCRIPTION ? (_openBlock(), _createBlock(_component_tiny_mce_box, {
      key: 0,
      "initial-content": _ctx.description,
      headline: $data.translate.description,
      "read-only": $props.readOnly,
      onChanged: _cache[9] || (_cache[9] = function ($event) {
        return _ctx.description = $event;
      })
    }, null, 8 /* PROPS */, ["initial-content", "headline", "read-only"])) : _createCommentVNode("v-if", true), optionalField === $data.OptionalFields.STARTDATE ? (_openBlock(), _createBlock(_component_date_time_picker, {
      key: 1,
      modelValue: $data.startDate,
      "onUpdate:modelValue": [_cache[10] || (_cache[10] = function ($event) {
        return $data.startDate = $event;
      }), _cache[11] || (_cache[11] = function ($event) {
        return $options.dataValidate('startDate', $event);
      })],
      title: $data.translate.startDate,
      "plugin-options": $options.datePickerOptions,
      tabindex: optionalFieldIndex,
      disabled: $props.readOnly,
      "delete-enabled": true,
      class: "mb-2"
    }, null, 8 /* PROPS */, ["modelValue", "title", "plugin-options", "tabindex", "disabled"])) : _createCommentVNode("v-if", true), optionalField === $data.OptionalFields.EXPIRATIONDATE ? (_openBlock(), _createBlock(_component_date_time_picker, {
      key: 2,
      modelValue: $data.endDate,
      "onUpdate:modelValue": [_cache[12] || (_cache[12] = function ($event) {
        return $data.endDate = $event;
      }), _cache[13] || (_cache[13] = function ($event) {
        return $options.dataValidate('endDate', $event);
      })],
      title: $data.translate.expirationDate,
      "plugin-options": $options.datePickerOptions,
      tabindex: optionalFieldIndex,
      disabled: $props.readOnly,
      "delete-enabled": true,
      class: "mb-2"
    }, null, 8 /* PROPS */, ["modelValue", "title", "plugin-options", "tabindex", "disabled"])) : _createCommentVNode("v-if", true), optionalField === $data.OptionalFields.NOTIFICATION_UPON_EXPIRATION_DATE ? (_openBlock(), _createBlock(_component_single_select, {
      key: 3,
      modelValue: _ctx.endDateNotification,
      "onUpdate:modelValue": _cache[14] || (_cache[14] = function ($event) {
        return _ctx.endDateNotification = $event;
      }),
      placeholder: $data.translate.notificationUponExpirationDate,
      options: $options.notificationOptions,
      title: $data.translate.notificationUponExpirationDate,
      disabled: $props.readOnly,
      "un-sorted": true,
      "with-reset": !$props.readOnly,
      tabindex: optionalFieldIndex,
      class: "mb-2"
    }, null, 8 /* PROPS */, ["modelValue", "placeholder", "options", "title", "disabled", "with-reset", "tabindex"])) : _createCommentVNode("v-if", true), optionalField === $data.OptionalFields.RENEWAL_DEADLINE ? (_openBlock(), _createBlock(_component_date_time_picker, {
      key: 4,
      modelValue: $data.renewalDate,
      "onUpdate:modelValue": [_cache[15] || (_cache[15] = function ($event) {
        return $data.renewalDate = $event;
      }), _cache[16] || (_cache[16] = function ($event) {
        return $options.dataValidate('renewalDate', $event);
      })],
      title: $data.translate.renewalDeadline,
      "plugin-options": $options.datePickerOptions,
      tabindex: optionalFieldIndex,
      disabled: $props.readOnly,
      "delete-enabled": true,
      class: "mb-2"
    }, null, 8 /* PROPS */, ["modelValue", "title", "plugin-options", "tabindex", "disabled"])) : _createCommentVNode("v-if", true), optionalField === $data.OptionalFields.NOTIFICATION_UPON_RENEWAL_DEADLINE ? (_openBlock(), _createBlock(_component_single_select, {
      key: 5,
      modelValue: _ctx.renewalDateNotification,
      "onUpdate:modelValue": _cache[17] || (_cache[17] = function ($event) {
        return _ctx.renewalDateNotification = $event;
      }),
      title: $data.translate.notificationUponRenewalDeadline,
      placeholder: $data.translate.notificationUponRenewalDeadline,
      options: $options.notificationOptions,
      disabled: $props.readOnly,
      "un-sorted": true,
      "with-reset": !$props.readOnly,
      tabindex: optionalFieldIndex,
      class: "mb-2"
    }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options", "disabled", "with-reset", "tabindex"])) : _createCommentVNode("v-if", true), optionalField === $data.OptionalFields.RENEWAL_AMOUNT ? (_openBlock(), _createBlock(_component_input_field_select, {
      key: 6,
      title: $data.translate.renewalAmount,
      "input-value": _ctx.renewalAmount,
      "option-value": _ctx.renewalAmountCurrency,
      "input-placeholder": $data.translate.renewalAmount,
      options: $data.currencyOptions,
      "input-enabled": !$props.readOnly,
      "option-enabled": !$props.readOnly,
      "un-sorted": true,
      tabindex: optionalFieldIndex,
      "enable-currency-template": true,
      type: "text",
      class: "mb-2",
      onInputChange: _cache[18] || (_cache[18] = function ($event) {
        return _ctx.renewalAmount = $event;
      }),
      onOptionChange: _cache[19] || (_cache[19] = function ($event) {
        return _ctx.renewalAmountCurrency = $event;
      })
    }, null, 8 /* PROPS */, ["title", "input-value", "option-value", "input-placeholder", "options", "input-enabled", "option-enabled", "tabindex"])) : _createCommentVNode("v-if", true), optionalField === $data.OptionalFields.TERMINATION_DEADLINE ? (_openBlock(), _createBlock(_component_date_time_picker, {
      key: 7,
      modelValue: $data.bindingDate,
      "onUpdate:modelValue": [_cache[20] || (_cache[20] = function ($event) {
        return $data.bindingDate = $event;
      }), _cache[21] || (_cache[21] = function ($event) {
        return $options.dataValidate('bindingDate', $event);
      })],
      title: $data.translate.terminationDeadline,
      "plugin-options": $options.datePickerOptions,
      tabindex: optionalFieldIndex,
      disabled: $props.readOnly,
      "delete-enabled": true,
      class: "mb-2"
    }, null, 8 /* PROPS */, ["modelValue", "title", "plugin-options", "tabindex", "disabled"])) : _createCommentVNode("v-if", true), optionalField === $data.OptionalFields.NOTIFICATION_UPON_TERMINATION_DEADLINE ? (_openBlock(), _createBlock(_component_single_select, {
      key: 8,
      modelValue: _ctx.bindingDateNotification,
      "onUpdate:modelValue": _cache[22] || (_cache[22] = function ($event) {
        return _ctx.bindingDateNotification = $event;
      }),
      title: $data.translate.notificationUponTerminationDeadline,
      placeholder: $data.translate.notificationUponTerminationDeadline,
      options: $options.notificationOptions,
      disabled: $props.readOnly,
      "un-sorted": true,
      "with-reset": !$props.readOnly,
      tabindex: optionalFieldIndex,
      class: "mb-2"
    }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options", "disabled", "with-reset", "tabindex"])) : _createCommentVNode("v-if", true), optionalField === $data.OptionalFields.AUTO_RENEWAL ? (_openBlock(), _createBlock(_component_single_select, {
      key: 9,
      modelValue: _ctx.autoRenew,
      "onUpdate:modelValue": _cache[23] || (_cache[23] = function ($event) {
        return _ctx.autoRenew = $event;
      }),
      class: "mb-2",
      title: $data.translate.autoRenewal,
      placeholder: $data.translate.choose,
      disabled: $props.readOnly,
      options: $options.autoRenewOptions,
      "un-sorted": true,
      "with-reset": !$props.readOnly,
      tabindex: optionalFieldIndex
    }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "disabled", "options", "with-reset", "tabindex"])) : _createCommentVNode("v-if", true), optionalField === $data.OptionalFields.AMOUNT ? (_openBlock(), _createBlock(_component_input_field_select, {
      key: 10,
      title: $data.translate.amount,
      "input-value": _ctx.contractAmount,
      "option-value": _ctx.contractAmountCurrency,
      "input-placeholder": $data.translate.amount,
      options: $data.currencyOptions,
      "input-enabled": !$props.readOnly,
      "option-enabled": !$props.readOnly,
      "un-sorted": true,
      tabindex: optionalFieldIndex,
      "enable-currency-template": true,
      type: "text",
      class: "mb-2",
      onInputChange: _cache[24] || (_cache[24] = function ($event) {
        return _ctx.contractAmount = $event;
      }),
      onOptionChange: _cache[25] || (_cache[25] = function ($event) {
        return _ctx.contractAmountCurrency = $event;
      })
    }, null, 8 /* PROPS */, ["title", "input-value", "option-value", "input-placeholder", "options", "input-enabled", "option-enabled", "tabindex"])) : _createCommentVNode("v-if", true), optionalField === $data.OptionalFields.PAYMENT_INTERVAL ? (_openBlock(), _createBlock(_component_single_select, {
      key: 11,
      modelValue: _ctx.paymentInterval,
      "onUpdate:modelValue": _cache[26] || (_cache[26] = function ($event) {
        return _ctx.paymentInterval = $event;
      }),
      title: $data.translate.paymentInterval,
      placeholder: $data.translate.choose,
      disabled: $props.readOnly,
      options: $data.paymentIntervalOptions,
      "un-sorted": true,
      "with-reset": !$props.readOnly,
      tabindex: optionalFieldIndex
    }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "disabled", "options", "with-reset", "tabindex"])) : _createCommentVNode("v-if", true), optionalField === $data.OptionalFields.CONTRACT_VERSION ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 12
    }, [_createCommentVNode(" change to v-model after backend implementation "), _createVNode(_component_input_field, {
      "model-value": _ctx.contractVersion || 1,
      title: $data.translate.contractVersion,
      enabled: false
    }, null, 8 /* PROPS */, ["model-value", "title"])], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */)) : _createCommentVNode("v-if", true), optionalField === $data.OptionalFields.STATUS_CHANGENOTE ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 13
    }, [_createVNode(_component_risma_title, {
      title: $data.translate.statusChangeNote,
      type: "medium"
    }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
      class: "mb-4",
      innerHTML: _ctx.workflowStatusNote || ''
    }, null, 8 /* PROPS */, _hoisted_5)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */))]), $options.currentActivityType ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_activity_custom_fields, {
    "activity-type": $options.currentActivityType,
    "custom-field-values": $props.data.customFieldValues,
    disabled: $props.readOnly,
    onChanged: _ctx.onCustomFieldsChange
  }, null, 8 /* PROPS */, ["activity-type", "custom-field-values", "disabled", "onChanged"])])) : _createCommentVNode("v-if", true)]);
}