function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { createApp } from 'vue';
import 'jqtree';
import emitter from 'tiny-emitter/instance'; //event bus

import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import i18n from '@/i18n';
import * as Cookies from 'tiny-cookie';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import Alert from '@/components/Molecules/Modal/Alert';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import SaveImage from '@/components/Molecules/SaveImage';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import TreeItemMenu from '@/components/Atoms/TreeItemMenu';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import ProcessLibraryNodeCreationModal from '@/components/Molecules/ProcessLibrary/ProcessLibraryNodeCreationModal';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import ComplianceCreationModal from '@/components/Molecules/Modal/ComplianceCreationModal';
import { Tabs } from '@/constants/Tabs';
import { OBJ_TYPE_TO_MODULE, ObjectTypes } from '@/constants/ObjectTypes';
import { MODULES } from '@/constants/modules';
import { getOrganisationsFromIds } from '@/utils/format/objectsFromIds';
import { Colors } from '@/Colors';
export default {
  name: 'Tree',
  components: {
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    RismaToggle: RismaToggle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Alert: Alert,
    SaveImage: SaveImage,
    PreviewModalLink: PreviewModalLink,
    ProcessLibraryNodeCreationModal: ProcessLibraryNodeCreationModal,
    RismaSelect: RismaSelect,
    Modal: Modal,
    InputField: InputField,
    Notification: Notification,
    ComplianceCreationModal: ComplianceCreationModal
  },
  props: {
    treeId: {
      type: String,
      required: true
    },
    projectId: {
      type: [Number, String],
      required: false,
      default: null
    },
    treeKey: {
      type: [Number, String],
      required: false,
      default: null
    },
    treeTitle: {
      type: String,
      required: false,
      default: ''
    },
    levelSelect: {
      type: Boolean,
      required: false,
      default: true
    },
    dataflowToolbar: {
      type: Boolean,
      required: false,
      default: false
    },
    treeData: {
      required: true,
      type: Object
    },
    newChild: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      }
    },
    doMove: {
      required: true,
      type: Boolean
    },
    copy: {
      required: false,
      type: Boolean,
      default: true
    },
    copyData: {
      required: false,
      type: Boolean,
      default: true
    },
    deleteNodeOption: {
      required: false,
      type: Boolean,
      default: false
    },
    createReportOption: {
      required: false,
      type: Boolean,
      default: false
    },
    isGdpr: {
      required: false,
      type: Boolean,
      default: false
    },
    showMemorandumReportLink: {
      required: false,
      type: Boolean,
      default: false
    },
    treeRights: {
      required: true,
      type: Boolean
    },
    lockTree: {
      required: false,
      type: Boolean,
      default: true
    },
    disableItemMenu: {
      type: Boolean,
      default: false,
      required: false
    },
    disableProgressShown: {
      type: Boolean,
      default: false,
      required: false
    },
    updateNodeLabel: {
      type: String,
      default: '',
      required: false
    },
    addSubNodeLabel: {
      type: String,
      default: '',
      required: false
    },
    deleteNodeLabel: {
      type: String,
      default: '',
      required: false
    },
    deleteNodeFunction: {
      type: Function,
      default: null,
      required: false
    },
    updateNodeFunction: {
      type: Function,
      default: null,
      required: false,
      note: 'Function that checks if the node can be deleted'
    },
    deleteNodeAlertMessage: {
      type: String,
      default: '',
      required: false
    },
    enableAddButton: {
      type: Boolean,
      default: false,
      required: false
    },
    allLevels: {
      type: Array,
      required: false,
      default: function _default() {
        return [{
          label: '1',
          value: '1'
        }, {
          label: '2',
          value: '2'
        }, {
          label: '3',
          value: '3'
        }, {
          label: '4',
          value: '4'
        }, {
          label: '5',
          value: '5'
        }, {
          label: i18n.t('All'),
          value: 'all'
        }];
      }
    },
    classNameToTree: {
      type: String,
      required: false,
      default: '',
      note: 'additional class to #myTree'
    },
    isOrganisation: {
      type: Boolean,
      default: false,
      note: 'Specific updates for organisation tree - removing width for main-container and scroll to the left'
    },
    showLevelsClasses: {
      type: String,
      required: false,
      default: '',
      note: 'Classes for custom positioning of Show levels section'
    },
    templateId: {
      type: Number,
      required: false,
      default: null,
      note: 'node Id which children should function as templates'
    },
    addRootNodeWithoutReloading: {
      type: Boolean,
      default: false,
      note: 'to prevent reloading the whole tree when creating a new root node'
    },
    onCanMove: {
      type: Function,
      default: null
    },
    onCanMoveTo: {
      type: Function,
      default: null
    },
    helperFunctions: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    hasUnderlyingModalFeature: {
      type: Boolean,
      default: false,
      note: 'if underlying node should be created from modal above tree'
    },
    linksStructureByModule: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    hasOrganisationsSelector: {
      required: false,
      type: Boolean,
      default: false
    },
    selectedOrganisationIds: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['addLinkFromMenu', 'moved', 'deleteItem', 'addChild', 'createChild', 'doDeleteNode', 'move', 'reload', 'addUnderlyingFromModal', 'addProcess', 'update:organisations'],
  data: function data() {
    return {
      dataflowIconShown: false,
      level: '',
      root: null,
      treeWidth: null,
      abilityToAdd: false,
      canvasWidth: 0,
      loading: true,
      treeReady: false,
      parentId: null,
      copyNode: false,
      movingEvent: null,
      showMoveAlert: false,
      showDeleteAlert: false,
      deleteAlertMessage: '',
      nodeToBeDeleted: null,
      selectedActivity: {},
      gapMode: false,
      showProcessNodeCreationModal: false,
      showComplianceNodeCreationModal: false,
      instances: [],
      selectedOrganisations: [],
      translate: getTranslate['Tree'](),
      newNodeTitle: '',
      params: {},
      showEditNodeModal: false,
      isCreateMode: false,
      updatedTitle: '',
      showUpdateTitleEmptyNotification: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useOrganisationsStore, ['organisations'])), {}, {
    copySettings: function copySettings() {
      return this.getSettingValue('feature.copy_nodes') && this.getSettingValue('feature.copy_nodes') === 1;
    },
    isNodeIdInName: function isNodeIdInName() {
      return !!this.getSettingValue('feature.show_node_id');
    },
    templateSubmenuItems: function templateSubmenuItems() {
      if (!this.templateId) return [];
      var templateNode = this.getTreeNode(this.templateId);
      var templateChildren = templateNode && templateNode.children;
      if (!templateChildren) return [];
      return templateChildren.map(function (child) {
        return {
          id: child.id,
          name: child.name
        };
      });
    },
    noResults: function noResults() {
      var _this$treeData;
      return !((_this$treeData = this.treeData) !== null && _this$treeData !== void 0 && (_this$treeData = _this$treeData.children) !== null && _this$treeData !== void 0 && _this$treeData.length);
    }
  }),
  watch: {
    $route: function $route() {
      this.reload();
    },
    selectedOrganisationIds: function selectedOrganisationIds() {
      this.toggleLevelsToShow();
    },
    noResults: function noResults(_, prevValue) {
      if (prevValue) {
        this.init();
      }
    },
    level: function level() {
      this.toggleLevelsToShow();
    },
    newChild: function newChild() {
      if (this.newChild.newOverallAction) {
        var _this$treeData2;
        if (((_this$treeData2 = this.treeData) === null || _this$treeData2 === void 0 ? void 0 : _this$treeData2.children.length) === 1 && $('#tree-jquery').tree('getNodeById', this.newChild.id)) {
          return;
        }
        var newNode = this.convertNode(this.newChild);
        $('#tree-jquery').tree('appendNode', newNode);
        this.appendButton();
        return;
      } else if (this.newChild.underlyingId) {
        this.addNewNodeAsSibling(this.newChild, this.newChild.underlyingId);
        this.appendButton();
      }
      if (!this.copyNode) {
        var node = $('#tree-jquery').tree('getNodeById', this.parentId);
        var _newNode = this.convertNode(this.newChild);
        if (node === null) {
          var _this$treeData3;
          if (this.addRootNodeWithoutReloading && ((_this$treeData3 = this.treeData) === null || _this$treeData3 === void 0 || (_this$treeData3 = _this$treeData3.children) === null || _this$treeData3 === void 0 ? void 0 : _this$treeData3.length) > 1) {
            $('#tree-jquery').tree('appendNode', _newNode);
          } else {
            this.reload();
          }
        } else {
          $('#tree-jquery').tree('appendNode', _newNode, node);
          this.appendButton();
          $('#tree-jquery').tree('openNode', node);
        }
      } else {
        this.addNewNodeAsSibling(this.newChild, this.parentId);
        this.copyNode = false;
      }
    },
    doMove: function doMove() {
      if (this.doMove) {
        this.movingEvent.move_info.do_move();
        this.$emit('moved', null);
      }
    },
    treeData: function treeData(value) {
      var data = this.convertNode(value);
      $('#tree-jquery').tree('loadData', data.children);
    }
  },
  mounted: function mounted() {
    var _this = this;
    emitter.on('actionClicked', this.handleDropdownAction);
    if (this.isOrganisation) {
      this.removeStyleForFullWidth();
    }
    if (this.selectedOrganisationIds) {
      this.selectedOrganisations = getOrganisationsFromIds(this.selectedOrganisationIds, this.organisations);
    }
    setTimeout(function () {
      _this.init();
    }, 0);
  },
  unmounted: function unmounted() {
    emitter.off('actionClicked', this.handleDropdownAction);
    this.instances.forEach(function (el) {
      el.unmount();
    });
  },
  methods: {
    deleteItem: function deleteItem(id) {
      var node = this.getTreeNode(id);
      $('#tree-jquery').tree('removeNode', node);
      this.$emit('deleteItem', id);
      this.appendButton();
    },
    onPreviewModalDismiss: function onPreviewModalDismiss() {
      this.selectedActivity = {};
    },
    removeStyleForFullWidth: function removeStyleForFullWidth() {
      var mainContent = document.querySelector('.main-content');
      if (mainContent) {
        mainContent.removeAttribute('style');
        mainContent.classList.remove('preventOverlap');
      }
    },
    /**
     * Extracts "checkbox" values from event.value and serves the toggleQuestionsGaps function
     */
    toggleQG: function toggleQG(event) {
      this.toggleQuestionsGaps(event.value);
    },
    /**
     * Sets the new level of nodes to show
     * Saves a cookie for personal preferences
     */
    setNewLevel: function setNewLevel(event) {
      if (event !== null) {
        this.level = event.id;
      }
    },
    /**
     * Resets the level of nodes to show
     * Changes cookie to default level again (0)
     */
    resetLevel: function resetLevel() {
      this.selectedLevel = '';
    },
    init: function init() {
      var _this2 = this;
      var vm = this;
      this.loading = true;
      if (this.treeData !== null) {
        this.root = this.convertNode(this.treeData);
      }
      if (!('children' in this.treeData) || this.treeData.children.length === 0) {
        this.loading = false;
        return;
      }
      var nodes = Object.keys(this.root.children).map(function (key, i) {
        return _this2.root.children[i];
      });
      this.handleDrawTree(nodes);
      if (!this.lockTree || this.abilityToAdd === true) {
        this.appendButton();
        $('#tree-jquery').on('click', 'span.jqtree-addchild', this.addChild);
        $('#tree-jquery').bind('tree.move', this.preMove);
        this.addMouseDownEventListener();
      }
      setTimeout(function () {
        $('#tree-jquery').css({
          'width': vm.computedWidth + 'px'
        });
        var cookie = _this2.getCoordinateCookie();
        _this2.$el.querySelector('#mytree').scrollTop = cookie.y;
        if (!_this2.isOrganisation) {
          _this2.$el.querySelector('#mytree').scrollLeft = cookie.x;
        }
      }, 700);
      this.resizeCanvas();
      if (Cookies.get(this.treeId + '_scroll') === null) {
        this.setCoordinateCookie();
      }
      this.$el.querySelector('#mytree').addEventListener('scroll', function () {
        _this2.setCoordinateCookie();
      });
    },
    appendButton: function appendButton() {
      if (!this.enableAddButton) return;
      var items = $('#tree-jquery').find('.jqtree-element');
      if (items.attr('data-depth')) {
        items = this.filterWithDepth(items);
      }
      if (items.attr('closed')) {
        items = items.filter(function () {
          return !parseInt($(this).attr('closed'));
        });
      }
      items.append('<span class=\'jqtree-addchild\'></span>');
    },
    filterWithDepth: function filterWithDepth(items) {
      var depth = this.getSettingValue('risma.max_levels') || 6;
      return items.filter(function () {
        return parseInt($(this).attr('data-depth')) < depth;
      });
    },
    setCoordinateCookie: function setCoordinateCookie() {
      var coordinates = JSON.stringify({
        x: this.$el.querySelector('#mytree').scrollLeft,
        y: this.$el.querySelector('#mytree').scrollTop
      });
      Cookies.set(this.treeId + '_scroll', coordinates, {
        samesite: 'strict'
      });
    },
    getCoordinateCookie: function getCoordinateCookie() {
      var cookie = JSON.parse(Cookies.get(this.treeId + '_scroll'));
      return cookie;
    },
    toggleLevelsToShow: function toggleLevelsToShow() {
      var level = this.level;
      if (level != '0') {
        $('#tree-jquery').find('.jqtree-title').each(function () {
          var node = $('#tree-jquery').tree('getNodeByHtmlElement', this);
          if (level == 'all') {
            $('#tree-jquery').tree('openNode', node);
          } else {
            if (node.level < level) $('#tree-jquery').tree('openNode', node);else $('#tree-jquery').tree('closeNode', node);
          }
        });
      }
    },
    handleDrawTree: function handleDrawTree(nodes) {
      if (nodes.length == 0) {
        this.loading = false;
        return;
      }
      var vm = this;
      $('#tree-jquery').on('tree.init', function () {
        vm.loading = false;
        vm.treeReady = true;
      }).tree({
        data: nodes,
        autoOpen: true,
        dragAndDrop: !vm.lockTree,
        onCanMove: this.onCanMove,
        onCanMoveTo: this.onCanMoveTo,
        saveState: vm.treeId,
        onCreateLi: function onCreateLi(node, $li) {
          node.dropDownAdded = false;
          var jqtreeEl = $li.find('.jqtree-element');
          jqtreeEl.attr('data-node-id', node.id);
          jqtreeEl.attr('title', node.name);
          jqtreeEl.attr('closed', node.closed || 0);
          if (node.ipath) {
            jqtreeEl.attr('data-depth', node.ipath.split('.').length);
          }
          if (node.isLink) {
            $li.attr('data-is-link', true);
            if (node.checkaccess) {
              var _vm$linksStructureByM;
              vm.wrapLi(node, $li);
              var module = OBJ_TYPE_TO_MODULE[node.objType];
              var hasLinks = (_vm$linksStructureByM = vm.linksStructureByModule[module]) === null || _vm$linksStructureByM === void 0 || (_vm$linksStructureByM = _vm$linksStructureByM.nextLevelLinks) === null || _vm$linksStructureByM === void 0 ? void 0 : _vm$linksStructureByM.length;
              if (hasLinks) {
                vm.appendDropdownButtonToLi(node, $li);
              }
            } else {
              vm.addClosedIcon($li);
            }
          } else {
            vm.abilityToAdd = node.checkaccess;
            if (vm.abilityToAdd === true) {
              node.url && vm.wrapLi(node, $li);
              if (vm.lockTree === false || vm.abilityToAdd === true) {
                if (!vm.disableItemMenu) {
                  vm.appendDropdownButtonToLi(node, $li);
                }
              }
              if (vm.dataflowToolbar && node.dataFlows && node.dataFlows.length > 0) {
                vm.appendDataflowStatusIcon($li);
              }
            } else {
              vm.addClosedIcon($li);
            }
          }
          jqtreeEl.parent().attr('data-node-id', node.id);
          if (node.cpath) {
            jqtreeEl.parent().attr('data-node-cpath', node.cpath);
          }
          if ($.isNumeric(node.traffic_light || node.trafficLight)) {
            vm.addTrafficLightOnLi(node, jqtreeEl);
          }
          if ('values' in node) {
            var progress = 0;
            var className = '';
            if (vm.gapMode) {
              if ('percentage_gap_answered' in node.values) {
                progress = vm.determinateProgress(node.values.percentage_gap_answered);
              }
              className = 'progGap';
            } else {
              if ('percentage_questions_answered' in node.values) {
                progress = vm.determinateProgress(node.values.percentage_questions_answered);
              }
              className = 'prog';
            }
            jqtreeEl.addClass(className + progress).attr('title', node.name);
          }
          if (node.objType === ObjectTypes.EXECUTION_INITIATIVE_LIMITED || node.objType === ObjectTypes.EXECUTION_INITIATIVE) {
            jqtreeEl.addClass('prog' + vm.determinateProgress(node.completionPercentage));
          }
          if (node.objType === ObjectTypes.ORGANISATION) {
            vm.appendFolderIcon($li);
          }
          if (node.closed) vm.appendCheckIcon($li);
          $li.addClass('level-' + node.getLevel());
          node.level = node.getLevel();
          if (vm.treeReady) {
            node.level === 1 && vm.resizeCanvas();
            vm.loading = false;
          }
        }
      }).on('tree.init', function () {
        $('#tree-jquery').tree('setState');
      }).on('click', '.jqtree-toggler', function () {
        vm.resetLevel();
      });
      vm.setCanvasWidth();
    },
    setSelectedOrgs: function setSelectedOrgs(data) {
      this.$emit('update:organisations', data);
    },
    getSelectedRoute: function getSelectedRoute(activity) {
      if (activity.url) {
        var splittedUrl = activity.url.split('/');
        var TabUrls = Object.keys(Tabs).map(function (tab) {
          return Tabs[tab].url;
        });
        var firstPart = splittedUrl[splittedUrl.length - 1];
        var secondPart = splittedUrl[splittedUrl.length - 2];
        if (firstPart && TabUrls.indexOf(firstPart) !== -1) {
          return {
            tab: firstPart
          };
        }
        if (secondPart && TabUrls.indexOf(secondPart) !== -1) {
          return {
            tab: secondPart
          };
        }
      }
      return null;
    },
    convertNode: function convertNode(node) {
      node.name = node.title;
      node.id = +node.id;
      for (var i in node.children) {
        this.convertNode(node.children[i]);
      }
      return node;
    },
    addChild: function addChild(event) {
      var nodeId = $(event.target).parent().parent().attr('data-node-id');
      if (this.hasUnderlyingModalFeature) {
        this.$emit('addUnderlyingFromModal', nodeId);
      } else {
        this.addNode(nodeId);
      }
    },
    addTrafficLightOnLi: function addTrafficLightOnLi(node, jqtreeEl) {
      var color;
      if (node.objType === ObjectTypes.INCIDENTS_INCIDENT_LIMITED) {
        color = Colors.findIncidentSeverity(node.severity);
      } else {
        color = Colors.trafficlightColor(node.traffic_light || node.trafficLight, true);
      }
      jqtreeEl.addClass('traffic ' + color.label);
    },
    addClosedIcon: function addClosedIcon($li) {
      $li.find('.jqtree-title').addClass('closed');
      $li.find('.jqtree-title').addClass('line-through');
    },
    updateNode: function updateNode(nodeId) {
      var node = this.getTreeNode(nodeId);
      this.params = node;
      this.updatedTitle = node.name;
      this.isCreateMode = false;
      this.showCreateModalByTreeObjType();
    },
    openCreateNodeModal: function openCreateNodeModal() {
      this.updatedTitle = '';
      this.isCreateMode = true;
      this.showCreateModalByTreeObjType();
    },
    doCopyNode: function doCopyNode(nodeId) {
      var copyWithChildren = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var copyData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      var node = this.getTreeNode(nodeId);
      this.copyNode = true;
      this.setCreateNodeParams(nodeId, node, copyWithChildren, copyData);
      this.showCreateModalByTreeObjType();
    },
    addNode: function addNode(nodeId) {
      this.setCreateNodeParams(nodeId);
      this.showCreateModalByTreeObjType();
    },
    showCreateModalByTreeObjType: function showCreateModalByTreeObjType() {
      if (!this.treeData.objType) {
        this.showEditNodeModal = true;
        return;
      }
      switch (this.treeData.objType) {
        case ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE:
          this.showProcessNodeCreationModal = true;
          break;
        case ObjectTypes.COMPLIANCE_NODE:
          this.showComplianceNodeCreationModal = true;
          break;
        default:
          this.showEditNodeModal = true;
      }
    },
    setCreateNodeParams: function setCreateNodeParams(parentId) {
      var node = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var copyWithChildren = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      var copyData = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
      this.parentId = parentId;
      this.isCreateMode = true;
      this.params = {
        appendTo: parentId
      };
      if (node) {
        var uniquePrefix = '';
        if (this.isNodeIdInName) {
          uniquePrefix = (node.uniqueId || node.id || '') + ' ';
        }
        this.newNodeTitle = "".concat(this.translate.copyOf, " ").concat(node.title.replace(uniquePrefix, ''));
        this.params = {
          copyFrom: node.id,
          insertCopyAfter: node.id,
          copyWithChildren: copyWithChildren,
          copyData: copyData
        };
      }
    },
    onNodeCreate: function onNodeCreate(newNode) {
      this.$emit('addChild', {
        newNode: newNode,
        params: this.params
      });
      this.dismissNodeCreateModal();
    },
    dismissNodeCreateModal: function dismissNodeCreateModal() {
      this.newNodeTitle = '';
      this.params = {};
      this.showComplianceNodeCreationModal = false;
      this.showProcessNodeCreationModal = false;
    },
    insertNodeFromTemplate: function insertNodeFromTemplate(nodeId, templateId) {
      var node = this.getTreeNode(templateId);
      var params = {
        copyFrom: templateId,
        appendCopyTo: nodeId,
        title: node.name,
        copyWithChildren: 1,
        copyData: 1
      };
      this.parentId = nodeId;
      this.$emit('createChild', params);
    },
    confirmDelete: function confirmDelete(nodeId) {
      this.nodeToBeDeleted = nodeId;
      var nodeTitle = $('.jqtree-element[data-node-id=\'' + nodeId + '\'] .jqtree-title').text();
      var message = this.translate.areYouSureYouWantToDelete + ' "' + nodeTitle + '" ' + this.translate.andUnderlyingNodesPleaseNoteThatLinksToAn;
      this.deleteAlertMessage = this.deleteNodeAlertMessage || message;
      this.toggleDeleteAlert();
    },
    deleteNode: function deleteNode() {
      var _this3 = this;
      var nodeId = this.nodeToBeDeleted;
      var params = {
        nodeId: nodeId
      };
      this.toggleDeleteAlert();
      var node = $('#tree-jquery').tree('getNodeById', nodeId);
      if (this.nodeToBeDeleted !== null) {
        if (this.deleteNodeFunction) {
          return this.deleteNodeFunction(params).then(function () {
            _this3.handleDeleteSuccess(node);
          });
        } else {
          this.$emit('doDeleteNode', params);
          this.handleDeleteSuccess(node);
        }
      }
    },
    handleDeleteSuccess: function handleDeleteSuccess(node) {
      this.nodeToBeDeleted = null;
      this.deleteAlertMessage = '';
      $('#tree-jquery').tree('removeNode', node);
      var cookie = this.getCoordinateCookie();
      this.$el.querySelector('#mytree').scrollTop = cookie.y;
      if (!this.isOrganisation) {
        this.$el.querySelector('#mytree').scrollLeft = cookie.x;
      }
    },
    toggleDeleteAlert: function toggleDeleteAlert() {
      this.showDeleteAlert = !this.showDeleteAlert;
    },
    wrapLi: function wrapLi(node, $li) {
      var limitedNode = _objectSpread({}, node);
      delete limitedNode.children;
      delete limitedNode.parent;
      delete limitedNode.tree;
      var a = $('<a>');
      a.on('click', this.getClickHandler(limitedNode));
      a.addClass('node-title');
      a.prop('href', node.url);
      $li.find('.jqtree-title').wrap(a);
    },
    getClickHandler: function getClickHandler(node) {
      var _this4 = this;
      return function (event) {
        if (Object.keys(_this4.selectedActivity).length !== 0) {
          return false;
        }
        if (event.ctrlKey === false && event.metaKey === false) {
          _this4.selectedActivity = _this4.getNodeForPreview(node);
          _this4.$refs.preview.showModal(event);
          event.preventDefault();
          event.stopPropagation();
        }
      };
    },
    getNodeForPreview: function getNodeForPreview(node) {
      if (!node.isLink) return node;
      return _objectSpread(_objectSpread({}, node), {}, {
        id: node.realId
      });
    },
    addMouseDownEventListener: function addMouseDownEventListener() {
      var vm = this;
      $('#mytree .jqtree-element').off('mousedown');
      $('#tree-jquery').off('mouseup');
      !this.isOrganisation && $('#mytree .jqtree-element').on('mousedown', function () {
        vm.isMoving();
      });
      $('#tree-jquery').on('mouseup', function () {
        $('#tree-jquery').off('mousemove');
      });
    },
    isMoving: function isMoving() {
      var vm = this;
      var hotspotX = window.innerWidth * 0.10;
      var hotspotY = $('#mytree').innerHeight * 0.10;
      $('#tree-jquery').on('mousemove', function (event) {
        var _this5 = this;
        setTimeout(function () {
          if (event.pageY > $('#mytree').innerHeight - hotspotY || event.pageY > $('#tree-jquery').innerHeight - 100) {
            $('#mytree').stop().animate({
              scrollTop: '+=50'
            }, 100, 'linear');
          }
          if (event.pageX > window.innerWidth - hotspotX || event.pageX > $('#tree-jquery').innerWidth() - 100) {
            //-800 to take into account node width
            if ($('#tree-jquery').width() - 800 < 0) {
              vm.setCanvasWidth();
              vm.resizeCanvas();
            }
            if (event.pageX + 800 > $('#tree-jquery').width() - 800) {
              vm.setCanvasWidth();
              vm.resizeCanvas();
            }
            if (!_this5.isOrganisation) {
              $('#mytree').stop().animate({
                scrollLeft: '+=50'
              }, 100, 'linear');
            }
          }
          //250px takes into account of side menu
          if (event.pageX < hotspotX + 250) {
            $('#mytree').stop().animate({
              scrollLeft: '-=50'
            }, 100, 'linear');
          }
          if (event.pageY < hotspotY + 100) {
            $('#mytree').stop().animate({
              scrollTop: '-=50'
            }, 100, 'linear');
          }
        }, 500);
      });
    },
    preMove: function preMove(event) {
      event.preventDefault();
      this.movingEvent = event;
      this.showMoveAlert = true;
    },
    onMoveDismiss: function onMoveDismiss() {
      this.movingEvent = null;
      this.showMoveAlert = false;
    },
    onMoveConfirm: function onMoveConfirm() {
      var child = this.movingEvent.move_info.moved_node;
      var target = this.movingEvent.move_info.target_node;
      var position = this.movingEvent.move_info.position;
      var children = target.children.filter(function (c) {
        return !c.isLink;
      });
      var params = {};
      //new x position will always be less than 200 if placed "outside" canvas
      if (child.element.getBoundingClientRect().x < 200) {
        this.canvasWidth = this.canvasWidth + 450;
        this.resizeCanvas();
      }
      if (position === 'inside') {
        if (children.length) {
          // Insert before first child
          params = {
            'insertBefore': children[0].id
          };
        } else {
          // Append child
          params = {
            'appendTo': target.id
          };
        }
      } else if (position === 'after') {
        // Insert after target
        params = {
          'insertAfter': target.id
        };
      } else if (position == 'before') {
        //Insert before parent node unto level 1
        params = {
          'insertBefore': target.id
        };
      }
      var childParam = child.objType === ObjectTypes.EXECUTION_INITIATIVE_LIMITED || child.objType === ObjectTypes.EXECUTION_INITIATIVE ? child.slug : child.id;
      this.$emit('move', {
        child: childParam,
        options: params
      });
      this.showMoveAlert = false;
    },
    resizeCanvas: function resizeCanvas() {
      $('#tree-jquery').css({
        'width': this.canvasWidth + 800 + 'px'
      });
      if ($('#tree-jquery').width() < $('#mytree').width()) {
        $('#tree-jquery').css({
          'width': $('#mytree').width() + 'px'
        });
      }
    },
    determinateProgress: function determinateProgress(percentage_answered) {
      return typeof percentage_answered === 'number' ? Math.floor(percentage_answered / 5) * 5 : 0;
    },
    gapProgress: function gapProgress(nodes) {
      var that = this;
      $.each(nodes, function (index) {
        var node = nodes[index];
        if (node.values && $.isNumeric(node.values['percentage_gap_answered'])) {
          var progress = parseInt(node.values['percentage_gap_answered'] / 5) * 5;
        }
        $('.jqtree-element[data-node-id="' + node.id + '"]').removeClass(function (index, className) {
          return (className.match(/(^|\s)prog\S+/g) || []).join(' ');
        });
        $('.jqtree-element[data-node-id="' + node.id + '"]').addClass('progGap' + progress);
        that.gapProgress(node.children);
      });
    },
    questionProgress: function questionProgress(nodes) {
      var that = this;
      $.each(nodes, function (index) {
        var node = nodes[index];
        if (node.values && $.isNumeric(node.values['percentage_questions_answered'])) {
          var progress = parseInt(node.values['percentage_questions_answered'] / 5) * 5;
        }
        $('.jqtree-element[data-node-id="' + node.id + '"]').removeClass(function (index, className) {
          return (className.match(/(^|\s)prog\S+/g) || []).join(' ');
        });
        $('.jqtree-element[data-node-id="' + node.id + '"]').addClass('prog' + progress);
        that.questionProgress(node.children);
      });
    },
    toggleQuestionsGaps: function toggleQuestionsGaps(checked) {
      this.gapMode = checked;
      var nodes = [];
      var that = this;
      for (var i in that.root.children) {
        nodes.push(that.root.children[i]);
      }
      if (checked) {
        this.gapProgress(nodes);
      } else {
        this.questionProgress(nodes);
      }
      Cookies.set(this.treeId + '_gapProgress', checked, {
        expires: 14,
        samesite: 'strict'
      });
      return true;
    },
    toggleDataflow: function toggleDataflow(event) {
      this.dataflowIconShown = event.value;
    },
    appendDataflowStatusIcon: function appendDataflowStatusIcon($li) {
      var _div$classList;
      var dataflowIconRendered = this.getIcon('git-pull-request', '13');
      this.instances.push(dataflowIconRendered);
      var div = document.createElement('div');
      var iconClasses = ['absolute', 'stroke-2', 'top-0', 'bottom-0', 'h-full', 'dataflow-icon'];
      (_div$classList = div.classList).add.apply(_div$classList, iconClasses);
      $li.find('.jqtree-element').append(div);
      dataflowIconRendered.mount(div);
    },
    appendDropdownButtonToLi: function appendDropdownButtonToLi(node, $li) {
      var instance = this.createMenuElement(node);
      this.instances.push(instance);
      var iconClasses = ['absolute', 'top-0', 'right-6', 'h-full', 'cursor-pointer', 'flex', 'items-center', 'dropdown-button-li'];
      $li.find('.jqtree-element').each(function () {
        var _div$classList2;
        var div = document.createElement('div');
        (_div$classList2 = div.classList).add.apply(_div$classList2, iconClasses);
        $(this).append(div);
        instance.mount(div);
      });
    },
    createMenuElement: function createMenuElement(node) {
      var menuItemsSettings = {
        updateNodeFunction: this.updateNodeFunction,
        updateNodeLabel: this.updateNodeLabel,
        addSubNodeLabel: this.addSubNodeLabel,
        createReportOption: this.createReportOption,
        isGdpr: this.isGdpr,
        showMemorandumReportLink: node.children && node.children.length && this.showMemorandumReportLink,
        copy: this.copy,
        copySettings: this.copySettings,
        copyData: this.copyData,
        deleteNodeOption: this.deleteNodeOption,
        deleteNodeLabel: this.deleteNodeLabel,
        disableAddAction: node.disableAddAction,
        isProcessNode: node.objType === ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE,
        isLink: node.isLink,
        linkType: node.objType,
        uniqueTreeId: node.uniqueTreeId
      };
      if (this.templateId) {
        menuItemsSettings.templateSubmenuItems = this.templateSubmenuItems;
      }
      var instance = createApp(TreeItemMenu, {
        nodeId: +node.id,
        menuItemsSettings: menuItemsSettings,
        transFunction: this.$trans,
        linksStructureByModule: this.linksStructureByModule
      });
      return instance;
    },
    appendFolderIcon: function appendFolderIcon($li) {
      var _div$classList3;
      var folderIconRendered = this.getIcon('folder');
      this.instances.push(folderIconRendered);
      var div = document.createElement('div');
      var iconClasses = ['inline-flex', 'stroke-2', 'mb-4'];
      (_div$classList3 = div.classList).add.apply(_div$classList3, iconClasses);
      $li.find('.jqtree-element').prepend(div);
      folderIconRendered.mount(div);
    },
    appendCheckIcon: function appendCheckIcon($li) {
      var _div$classList4;
      var checkIconRendered = this.getIcon('check', 15);
      this.instances.push(checkIconRendered);
      var iconClasses = ['inline-flex', 'stroke-2', 'mb-4'];
      var div = document.createElement('div');
      (_div$classList4 = div.classList).add.apply(_div$classList4, iconClasses);
      $li.find('.jqtree-element').prepend(div);
      checkIconRendered.mount(div);
    },
    handleDropdownAction: function handleDropdownAction(nodeId, action, data) {
      /*
          The API endpoints needed would be a variant of the the ones used to create nodes:
              copyFrom: node id to copy from
              copyData: comma separated list of: all | answers | gaps
              copyWithChildren: 1 or 0, 1 for recursive copy
          */

      var routeQuery = this.isGdpr ? '/article-30?fromNode=' : '/questionnaire?fromNode=';
      switch (action) {
        case 'add':
        case 'addProcessNode':
          this.addNode(nodeId);
          break;
        case 'delete':
          this.confirmDelete(nodeId);
          break;
        case 'duplicate':
          this.doCopyNode(nodeId, 0, 0);
          break;
        case 'duplicate with sub items':
          this.doCopyNode(nodeId, 1, 0);
          break;
        case 'duplicate with sub items and data':
          this.doCopyNode(nodeId, 1, 1);
          break;
        case 'report option':
          this.$router.push('/compliance/reports/' + this.projectId + routeQuery + nodeId);
          break;
        case 'memorandum report':
          this.$router.push('/compliance/reports/' + this.projectId + '/outsourcing/' + nodeId);
          break;
        case 'update':
          this.updateNode(nodeId);
          break;
        case 'add from template':
          this.insertNodeFromTemplate(nodeId, data);
          break;
        case 'add link':
          this.addLinkFromMenu(nodeId, data);
          break;
        default:
          return false;
      }
    },
    addLinkFromMenu: function addLinkFromMenu(nodeId, _ref) {
      var uniqueTreeId = _ref.uniqueTreeId,
        linkModule = _ref.linkModule;
      var node = nodeId ? this.getTreeNode(nodeId) : $('#tree-jquery').tree('getNodeByCallback', function (node) {
        return node.uniqueTreeId == uniqueTreeId;
      });
      this.$emit('addLinkFromMenu', {
        node: node,
        linkModule: linkModule
      });
    },
    getTreeNode: function getTreeNode(nodeId) {
      return $('#tree-jquery').tree('getNodeById', nodeId);
    },
    addNewNodeAsSibling: function addNewNodeAsSibling(response, nodeId) {
      $('#tree-jquery').tree('addNodeAfter', this.convertNode(response), this.getTreeNode(nodeId));
    },
    addNewNodeBeforeSibling: function addNewNodeBeforeSibling(response, nodeId) {
      $('#tree-jquery').tree('addNodeBefore', this.convertNode(response), this.getTreeNode(nodeId));
    },
    moveNode: function moveNode(nodeId, targetNodeId) {
      var insert = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'after';
      var node = this.getTreeNode(nodeId);
      var targetNode = this.getTreeNode(targetNodeId);
      $('#tree-jquery').tree('moveNode', node, targetNode, insert);
    },
    onNodeEdit: function onNodeEdit() {
      var _this6 = this;
      if (!this.updatedTitle) {
        this.showUpdateTitleEmptyNotification = true;
        return;
      }
      if (this.isCreateMode) {
        this.$emit('createChild', _objectSpread({
          title: this.updatedTitle
        }, this.params));
        this.dismissEditNodeModal();
        return;
      }
      var params = {
        title: this.updatedTitle,
        nodeId: this.params.id
      };
      this.updateNodeFunction(params).then(function () {
        $('#tree-jquery').tree('updateNode', _this6.params, _this6.updatedTitle);
        _this6.dismissEditNodeModal();
      });
    },
    dismissEditNodeModal: function dismissEditNodeModal() {
      this.updatedTitle = '';
      this.params = {};
      this.showEditNodeModal = false;
      this.showUpdateTitleEmptyNotification = false;
    },
    updateFromPreviewModalLink: function updateFromPreviewModalLink(data) {
      if (OBJ_TYPE_TO_MODULE[this.treeData.objType] === MODULES.COMPLIANCE) {
        this.updateForCompliance(data);
        return;
      }
      this.updateNodesFromPreview(data);
    },
    updateForCompliance: function updateForCompliance(data) {
      var _this7 = this;
      this.helperFunctions.getRecursiveLinks().then(function () {
        if (data.objType !== ObjectTypes.COMPLIANCE_NODE) {
          _this7.updateLinkedActivityFromPreview(data);
          return;
        }
        _this7.updateNodesFromPreview(data);
      });
    },
    updateNodesFromPreview: function updateNodesFromPreview(data) {
      if (this.selectedActivity.id === data.id) {
        this.selectedActivity = data;
      }
      var node = data.cpath ? $('#tree-jquery').tree('getNodeByCallback', function (node) {
        return node.cpath == data.cpath;
      }) : this.getTreeNode(data.id);
      if (!node && data.id) {
        node = $('#tree-jquery').tree('getNodeByCallback', function (node) {
          return node.id == data.id;
        });
      }
      var children = data.objType === ObjectTypes.COMPLIANCE_NODE ? this.updateNodesChildren(node, data) : node.children;
      var showComplianceId = this.getSettingValue('feature.show_node_id') && data.objType === ObjectTypes.COMPLIANCE_NODE;
      var compliancePrefix = showComplianceId ? data.id : null;
      var prefix = data.ipath || data.cpath || data.uniqueId || compliancePrefix;
      var titleWithPrefix = "".concat(prefix ? prefix + ' ' : '').concat(data.title);
      var updatedNode = _objectSpread(_objectSpread(_objectSpread({}, node), data), {}, {
        name: titleWithPrefix,
        traffic_light: data.traffic_light || data.trafficLight,
        children: children
      });
      $('#tree-jquery').tree('updateNode', node, updatedNode);
    },
    updateNodesChildren: function updateNodesChildren(node, data) {
      var _this$linksStructureB,
        _this8 = this;
      var childrenExcludingLinks = node.children.filter(function (child) {
        return !child.isLink;
      });
      var children = ((_this$linksStructureB = this.linksStructureByModule[MODULES.COMPLIANCE]) === null || _this$linksStructureB === void 0 ? void 0 : _this$linksStructureB.nextLevelLinks.map(function (child) {
        return _this8.helperFunctions.getLinkedActivities(data, child);
      }).flat()) || [];
      return [].concat(_toConsumableArray(children), _toConsumableArray(childrenExcludingLinks));
    },
    updateLinkedActivityFromPreview: function updateLinkedActivityFromPreview(data) {
      var _this9 = this;
      var treeData = $('#tree-jquery').tree('getTree');
      var itemTreeIds = this.getItemsInTreeById(treeData, data.id);
      var module = OBJ_TYPE_TO_MODULE[data.objType];
      var linksName = this.linksStructureByModule[module].name;
      var prefix = this.linksStructureByModule[module].prefix;
      itemTreeIds.forEach(function (id) {
        var node = $('#tree-jquery').tree('getNodeByCallback', function (node) {
          return node.uniqueTreeId === id;
        });
        var children = _this9.linksStructureByModule[module].nextLevelLinks.map(function (child) {
          return _this9.helperFunctions.getLinkedActivities(data, child);
        }).flat();
        var preTitle = prefix ? "".concat(data[prefix], " ") : '';
        var updatedNode = _objectSpread(_objectSpread({}, node), {}, {
          name: "".concat(_this9.$trans(linksName), ": ").concat(preTitle).concat(data.name || data.title),
          title: "".concat(_this9.$trans(linksName), ": ").concat(preTitle).concat(data.name || data.title),
          traffic_light: data.trafficLight,
          slug: data.slug,
          links: data.links,
          severity: data.severity,
          children: children
        });
        $('#tree-jquery').tree('updateNode', node, updatedNode);
      });
    },
    getItemsInTreeById: function getItemsInTreeById(tree, id) {
      var _tree$children,
        _this10 = this;
      if (!(tree !== null && tree !== void 0 && (_tree$children = tree.children) !== null && _tree$children !== void 0 && _tree$children.length)) return [];
      var result = [];
      tree.children.forEach(function (child) {
        if (child.isLink && +child.realId === +id) {
          result.push(child.uniqueTreeId);
          return;
        }
        result = [].concat(_toConsumableArray(result), _toConsumableArray(_this10.getItemsInTreeById(child, id)));
      });
      return result;
    },
    setCanvasWidth: function setCanvasWidth() {
      var allLis = $('#mytree li:not(.jqtree-folder)');
      var vm = this;
      Object.keys(allLis).forEach(function (index) {
        if (Number.isNaN(parseInt(index)) === false) {
          var rectX = allLis[index].getBoundingClientRect().x;
          if (rectX > vm.canvasWidth) {
            vm.canvasWidth = rectX * 3;
          }
        }
      });
    },
    reload: function reload() {
      this.canvasWidth = 0;
      $('#tree-jquery').tree('destroy');
      this.$emit('reload');
    },
    getIcon: function getIcon(iconName) {
      var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '20';
      return createApp(FeatherIcon, {
        icon: iconName,
        width: size,
        height: size
      });
    }
  }
};