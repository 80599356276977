import i18n from '@/i18n';
export var getTranslate = {
  CreateCompany: function CreateCompany() {
    return {
      createCompanyViaVatNumber: i18n.t('Create company via VAT number'),
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      countryCode: i18n.t('Country code'),
      vatNumber: i18n.t('VAT number'),
      pressEnterToStartSearch: i18n.t('Press enter to start search'),
      chooseTheCompanyYouWishToCreate: i18n.t('Choose the company you wish to create'),
      createCompany: i18n.t('Create company'),
      name: i18n.t('Name'),
      createViaVatNumber: i18n.t('Create via VAT number'),
      createManually: i18n.t('Create manually'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      companyName: i18n.t('Company name'),
      searchingByNameIsNotAvailableForSeCompani: '(' + i18n.t('Searching by name is not available for SE companies') + ')',
      orCompanyName: i18n.t('or company name'),
      dataProcessorWithTheSameVatNumberOrNameAl: i18n.t('Data processor with the same VAT number or name already exists in the system.'),
      noCompaniesFound: i18n.t('No companies found'),
      anErrorOccurred: i18n.t('An error occurred')
    };
  },
  CompanyLinkModal: function CompanyLinkModal() {
    return {
      links: i18n.t('Links'),
      apply: i18n.t('Apply'),
      cancel: i18n.t('Cancel'),
      type: i18n.t('Type'),
      createNew: i18n.t('Create new'),
      chooseDataProcessor: i18n.t('Choose data processor'),
      select: i18n.t('Select'),
      clickToSelect: i18n.t('Click to select'),
      addedLinks: i18n.t('Added links'),
      attach: i18n.t('Attach')
    };
  }
};