import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0ea7487c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    id: $options.elementId,
    style: _normalizeStyle($options.cssStyles)
  }, null, 12 /* STYLE, PROPS */, _hoisted_1), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]);
}