function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Timezones } from '@/constants/Timezones';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
export default {
  name: 'Timezone',
  introduction: 'Timezone clickable component',
  description: 'Output clickable element (value) with a nested popup',
  token: '<timezone :model-value="value" @onSelect="fn"></timezone>',
  components: {
    SingleSelect: SingleSelect
  },
  props: {
    modelValue: {
      type: String,
      required: true,
      note: 'Choosed timezone string value'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['onSelect'],
  data: function data() {
    return {
      options: [],
      selected: {}
    };
  },
  created: function created() {
    this.options = this.prepareTimezones(Timezones);
    this.selected = this.findSelected(this.modelValue);
  },
  methods: {
    findSelected: function findSelected(value) {
      var result = '';
      if (value) {
        var option = this.options.find(function (option) {
          return value === option.value;
        });
        if (option) {
          result = option;
        }
      }
      return result;
    },
    prepareTimezones: function prepareTimezones(timezones) {
      var result = [];
      if (timezones && timezones.length) {
        timezones.map(function (item) {
          result.push(_objectSpread({
            label: item.text
          }, item));
        });
      }
      return result;
    },
    onSelect: function onSelect(e) {
      this.selected = this.findSelected(e);
      this.$emit('onSelect', e);
    }
  }
};