import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "ia-processor"
};
var _hoisted_2 = {
  class: "font-semibold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_delegation_well = _resolveComponent("delegation-well");
  var _component_information_assets_details_relations = _resolveComponent("information-assets-details-relations");
  var _component_dep_well = _resolveComponent("dep-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error",
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.errorMessage = '';
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.errorMessage), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.companyStatusNotification ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString($data.translate.beAwareThatThisCompanyHasStatus), 1 /* TEXT */), _createElementVNode("span", _hoisted_2, _toDisplayString($data.companyStatusNotification), 1 /* TEXT */)])];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_dep_well, {
    item: $options.preparedInformationAssetsItem,
    "dep-header-props": _ctx.depHeaderProps,
    "is-loaded": _ctx.loaded,
    "local-storage-key": "IA-processor",
    onUpdatingAttachments: _ctx.updatingAttachments,
    onFileUpdate: _ctx.onFileUpdate,
    onPropertyChanged: _ctx.informationAssetPropertyChanged,
    onSaveStatusClicked: _ctx.saveStatusClicked,
    onGetActivityData: _ctx.getActivityData,
    onChangeTab: $options.onChangeTab,
    onCompanyStatusChange: _cache[1] || (_cache[1] = function ($event) {
      return $data.companyStatusNotification = $event;
    }),
    "onUpdate:isPageWithCustomUpdateDirty": _ctx.handleUpdateForCustomPageUpdate,
    "onSilentUpdate:attachments": _cache[2] || (_cache[2] = function ($event) {
      return _ctx.informationAsset.attachments = $event;
    })
  }, {
    delegation: _withCtx(function () {
      return [_ctx.informationAsset ? (_openBlock(), _createBlock(_component_delegation_well, {
        key: 0,
        "model-value": _ctx.delegationData,
        organisations: _ctx.organisations,
        "current-activity-type": _ctx.currentActivityType,
        onPropertyChanged: _ctx.saveChangesImmediately
      }, null, 8 /* PROPS */, ["model-value", "organisations", "current-activity-type", "onPropertyChanged"])) : _createCommentVNode("v-if", true)];
    }),
    details: _withCtx(function () {
      return [_ctx.informationAsset ? (_openBlock(), _createBlock(_component_information_assets_details_relations, {
        key: 0,
        "risk-assessment-schemas": $options.isDataProcessorManagementEnabled ? $data.riskAssessmentSchemas : [],
        "information-asset": _ctx.informationAsset,
        "activity-types": _ctx.activityTypes,
        questionnaires: _ctx.questionnaires,
        items: $data.processors,
        type: "processors",
        onChanged: _ctx.informationAssetPropertyChanged,
        onCreateProcessor: $options.addProcessor
      }, null, 8 /* PROPS */, ["risk-assessment-schemas", "information-asset", "activity-types", "questionnaires", "items", "onChanged", "onCreateProcessor"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["item", "dep-header-props", "is-loaded", "onUpdatingAttachments", "onFileUpdate", "onPropertyChanged", "onSaveStatusClicked", "onGetActivityData", "onChangeTab", "onUpdate:isPageWithCustomUpdateDirty"])]);
}