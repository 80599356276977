function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
export default {
  name: 'ScoringOtherParameters',
  token: '<scoring-other-parameters />',
  description: 'This component is used on the Risk(ERM) Period Description page',
  components: {
    InputField: InputField,
    RismaLabel: RismaLabel,
    RismaTitle: RismaTitle,
    RadioButtons: RadioButtons
  },
  props: {
    title: {
      type: String,
      require: true,
      default: ''
    },
    label: {
      type: String,
      require: true,
      default: ''
    },
    labels: {
      type: Object,
      require: false,
      default: function _default() {}
    },
    inUse: {
      type: Boolean,
      require: true,
      default: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['change'],
  data: function data() {
    return {
      selectedOption: this.inUse ? 1 : 0,
      translate: {
        doYouWantToUseOtherParameters: this.$trans('Do you want to use other parameters?'),
        no: this.$trans('No'),
        yes: this.$trans('Yes')
      }
    };
  },
  computed: {
    options: function options() {
      return [{
        value: 0,
        label: this.translate.no
      }, {
        value: 1,
        label: this.translate.yes
      }];
    }
  },
  methods: {
    changeInUsed: function changeInUsed(value) {
      this.$emit('change', {
        key: 'inUse',
        value: !!value
      });
    },
    changeLabel: function changeLabel(value) {
      this.$emit('change', {
        key: 'label',
        value: value
      });
    },
    changeLabels: function changeLabels(value, key) {
      var labels = _objectSpread(_objectSpread({}, this.labels), {}, _defineProperty({}, key, value));
      this.$emit('change', {
        key: 'labels',
        value: labels
      });
    }
  }
};