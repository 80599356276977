import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_controls_review = _resolveComponent("controls-review");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: "",
    "show-buttons": false,
    "modal-fixed-width": "70vw",
    onDismiss: $options.onDismiss
  }, {
    body: _withCtx(function () {
      return [$data.showConfirmation ? (_openBlock(), _createBlock(_component_alert, {
        key: 0,
        header: $data.translate.closeCompletionReviewWindow,
        body: $data.translate.areYouSureYouWishToCloseTheWindowAllEnteredDataWillBeDeleted,
        onAccept: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('close');
        }),
        onDismiss: _cache[1] || (_cache[1] = function ($event) {
          return $data.showConfirmation = false;
        })
      }, null, 8 /* PROPS */, ["header", "body"])) : _createCommentVNode("v-if", true), _createVNode(_component_controls_review, {
        id: $props.id,
        cpath: $props.cpath,
        onDataUpdated: _cache[2] || (_cache[2] = function ($event) {
          return $data.isUpdated = true;
        }),
        onUpdate: _cache[3] || (_cache[3] = function ($event) {
          return _ctx.$emit('update', $event);
        }),
        onClose: _cache[4] || (_cache[4] = function ($event) {
          return _ctx.$emit('close');
        })
      }, null, 8 /* PROPS */, ["id", "cpath"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onDismiss"]);
}