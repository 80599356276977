import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import Notification from '@/components/Molecules/Notification';
import InputField from '@/components/Atoms/Inputs/InputField';
export default {
  name: 'KPICreationModal',
  introduction: 'KPICreationModal multiselect popup',
  description: 'KPICreationModal with selector of KPI',
  token: '<kpi-creation-modal />',
  components: {
    Modal: Modal,
    Notification: Notification,
    InputField: InputField
  },
  props: {
    titleCharLimit: {
      type: Number,
      required: false,
      default: 0
    }
  },
  emits: ['dismiss', 'create'],
  data: function data() {
    return {
      newItem: {
        title: ''
      },
      errorMessage: '',
      translate: getTranslate['DashboardCreationModal']()
    };
  },
  methods: {
    onDashboardCreate: function onDashboardCreate() {
      if (!this.isDataValid()) return;
      this.$emit('create', this.newItem);
    },
    isDataValid: function isDataValid() {
      this.errorMessage = '';
      if (!this.newItem.title) {
        this.errorMessage = this.translate.titleCannotBeEmpty;
      }
      return !this.errorMessage;
    }
  }
};