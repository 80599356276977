import { CONTROLS_VIEWS } from '@/constants/ViewTypes';
import CatalogueHeader from '@/components/Molecules/CatalogueHeader/CatalogueHeader';
export default {
  name: '',
  components: {
    CatalogueHeader: CatalogueHeader
  },
  data: function data() {
    return {
      viewType: CONTROLS_VIEWS[0].name,
      viewTypes: CONTROLS_VIEWS,
      overviewOptionsState: false
    };
  },
  methods: {
    updateDisplayState: function updateDisplayState(state) {
      this.viewType = state;
      console.log("New display as / vue state: ".concat(state)); // eslint-disable-line
    },
    updateOverviewOptions: function updateOverviewOptions(state) {
      this.overviewOptionsState = state;
      console.log("New toggle state of Overview options: ".concat(state)); // eslint-disable-line
    }
  }
};