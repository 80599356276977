import { TitleTypes } from '@/constants/TitleTypes';
import { ModalDismissTypes } from '@/constants/ModalDismissTypes';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { disableScrolling, enableScrolling, getScrollPosition } from '@/utils/setScrolling';
import TrapFocusBoundary from '@/components/Atoms/Containers/TrapFocusBoundary';
export default {
  name: 'Modal',
  introduction: 'Component for displaying a Modal dialog',
  description: 'Uses in parent <attachment-modal> component.',
  token: "<modal\n    :header=\"translate.attachFiles\"\n    @accept=\"toggleModal\"\n    @dismiss=\"toggleModal\"\n    :buttonOkText=\"translate.okButton\">\n</modal>",
  components: {
    TrapFocusBoundary: TrapFocusBoundary,
    FeatherIcon: FeatherIcon,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle
  },
  props: {
    header: {
      required: true,
      type: String
    },
    headerType: {
      type: String,
      required: false,
      default: TitleTypes.BIG
    },
    headerTruncate: {
      type: Boolean,
      required: false,
      default: true
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: true
    },
    buttonOkText: {
      type: String,
      required: false,
      default: 'OK'
    },
    buttonDismissText: {
      type: String,
      required: false,
      default: 'Dismiss'
    },
    dismissOnEscKey: {
      required: false,
      type: Boolean,
      default: true
    },
    dismissOnClickOutside: {
      required: false,
      type: Boolean,
      default: true
    },
    showModalContainerMask: {
      required: false,
      type: Boolean,
      default: false
    },
    showDismissButton: {
      type: Boolean,
      required: false,
      default: true
    },
    showOkButton: {
      type: Boolean,
      required: false,
      default: true
    },
    type: {
      type: String,
      required: false,
      default: 'large',
      validator: function validator(val) {
        return ['small', 'large', 'big', 'wide'].includes(val);
      }
    },
    dismissButtonDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    okButtonDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    overflowVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    disableScrolling: {
      type: Boolean,
      required: false,
      default: true,
      note: 'to disable scrolling'
    },
    modalFixedWidth: {
      type: String,
      required: false,
      default: '',
      note: 'If we need the popup modal to be particular width'
    },
    modalFixedHeight: {
      type: String,
      required: false,
      default: '',
      note: 'If we need the popup modal to be particular height'
    },
    acceptOnEnter: {
      type: Boolean,
      required: false,
      default: true,
      note: 'accept modal on enter hit'
    },
    bodyFullHeight: {
      type: Boolean,
      required: false,
      default: null,
      note: 'Determine if body section should take all empty space'
    }
  },
  emits: ['dismiss', 'accept'],
  data: function data() {
    return {
      modalDismissTypes: ModalDismissTypes,
      scrollPosition: 0,
      translate: {
        fileUploadInProgress: this.$trans('File upload in progress'),
        attachFiles: this.$trans('Attach files'),
        okButton: this.$trans('Ok')
      }
    };
  },
  mounted: function mounted() {
    document.querySelector('body').classList.add('modal-open');
    if (this.dismissOnEscKey) {
      document.body.addEventListener('keyup', this.onEscClick);
    }
    if (this.disableScrolling) {
      this.scrollPosition = getScrollPosition();
      disableScrolling(this.scrollPosition);
    }
  },
  unmounted: function unmounted() {
    document.querySelector('body').classList.remove('modal-open');
    if (this.dismissOnEscKey) {
      document.body.removeEventListener('keyup', this.onEscClick);
    }
    if (this.disableScrolling) {
      enableScrolling(this.scrollPosition);
    }
  },
  methods: {
    dismissWhenClickedOutside: function dismissWhenClickedOutside() {
      if (this.dismissOnClickOutside) {
        this.$emit('dismiss', 'icon');
      }
    },
    onEnterClick: function onEnterClick() {
      if (this.acceptOnEnter) {
        this.$emit('accept');
      }
    },
    onEscClick: function onEscClick(event) {
      if (event.code === 'Escape') {
        this.$emit('dismiss', 'icon');
      }
    }
  }
};