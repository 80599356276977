import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
export default {
  name: 'EditDashboardModal',
  components: {
    Modal: Modal,
    InputField: InputField
  },
  props: {
    currentTitle: {
      type: String,
      required: false,
      default: ''
    },
    titleCharLimit: {
      type: Number,
      required: false,
      default: 0
    }
  },
  emits: ['dismiss', 'accept'],
  data: function data() {
    return {
      title: this.currentTitle,
      translate: getTranslate['EditDashboardModal']()
    };
  }
};