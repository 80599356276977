function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { create } from '@/api/execution/initiative';
import { Colors } from '@/Colors';
import { DateFormats } from '@/constants/DateFormats';
import * as Utils from '@/utils/Utils';
import { CustomFieldsMixin } from '@/mixins/CustomFieldsMixin';
import { clickOutside } from '@/utils/directives/clickOutside';
import { generateComputed } from '@/utils/base/generateComputed';
import Alert from '@/components/Molecules/Modal/Alert';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import ToggleMenu from '@/components/Atoms/ToggleMenu/ToggleMenu';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import Notification from '@/components/Molecules/Notification';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import ActionChangeDatesModal from '@/components/Molecules/Actions/ActionChangeDatesModal';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import AttachmentSelector from '@/components/Organisms/AttachmentSelector/AttachmentSelector';
import { TinyMceBoxTypes } from '@/utils/base/TinyMceBoxConfigs';
import { getLocalUnixTimestamp } from '@/utils/date';
export default {
  name: 'ActionDescription',
  introduction: '',
  description: 'This is the Page component for /actions/i/-/:slug/:id/description',
  token: '<action-description></action-description>',
  components: {
    RismaToggle: RismaToggle,
    Alert: Alert,
    DateTimePicker: DateTimePicker,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    TinyMceBox: TinyMceBox,
    Notification: Notification,
    ActivityCustomFields: ActivityCustomFields,
    InputField: InputField,
    ToggleMenu: ToggleMenu,
    AttachmentSelector: AttachmentSelector,
    ActionChangeDatesModal: ActionChangeDatesModal
  },
  directives: {
    clickOutside: clickOutside
  },
  mixins: [CustomFieldsMixin],
  props: {
    data: {
      required: true,
      type: Object,
      default: function _default() {}
    }
  },
  emits: ['deleteAction', 'notify', 'statusChanged', 'silentUpdate:attachments'],
  data: function data() {
    return {
      TinyMceBoxTypes: TinyMceBoxTypes,
      isLoaded: false,
      showAddNumericalUnitPopUp: false,
      dateTimeOptions: {
        locale: Utils.getLanguage(),
        format: DateFormats.DATE_FORMAT,
        saveFormat: DateFormats.DATE_FORMAT,
        widgetPositioning: {
          horizontal: 'right',
          vertical: 'bottom'
        }
      },
      inherited: false,
      numericalUnits: [{
        title: 'Budget',
        date: '23-02-2017 16:16',
        amount: '2500',
        unit: 'DKK',
        total: '2,500',
        show: false
      }, {
        title: 'Forbrug',
        date: '23-02-2017 16:16',
        amount: '3500',
        unit: 'DKK',
        total: '3,500',
        show: false
      }, {
        title: 'Forventet antal timer',
        date: '23-02-2017 16:16',
        amount: '500',
        unit: 'Timer',
        total: '500',
        show: false
      }, {
        title: 'Forbrug antal timer',
        date: '23-02-2017 16:16',
        amount: '400',
        unit: 'Timer',
        total: '400',
        show: false
      }],
      defaultTitleLabel: '_titleWithPath',
      Colors: Colors,
      showDatesModal: false,
      showDeleteAlert: false,
      attachments: {},
      translate: getTranslate['ActionDescription']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), mapState(useActivityTypesStore, {
    initiativeTypes: 'executionActivityTypes'
  })), {}, {
    isUserLocked: function isUserLocked() {
      return this.currentUser.level_e_locked === 1;
    }
  }, generateComputed(['status', 'description', 'target', 'completionPercentage', 'comment', 'start', 'deadline', 'activityType', 'tags', 'tagIds', 'dependingOn'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    defaultActivityType: function defaultActivityType() {
      return this.getSettingValue('feature.actions_default_activity_type_id');
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.initiativeTypes.find(function (item) {
        return item.id === _this.data.activityType;
      });
    },
    timestamp: function timestamp() {
      return getLocalUnixTimestamp(this.data.changed);
    },
    inputsDisabled: function inputsDisabled() {
      return !!(this.data.closed || this.isUserLocked);
    },
    reversedActionStatus: function reversedActionStatus() {
      return this.data.closed ? 0 : 1;
    },
    changeCloseStatusTitle: function changeCloseStatusTitle() {
      var childrenNumber;
      if (!this.data || !this.data.children) {
        childrenNumber = 'all';
      } else {
        childrenNumber = this.data.children.length;
      }
      if (this.data.closed === 0) {
        return this.translate.closeActionAnd + ' ' + childrenNumber + ' ' + (childrenNumber == 1 ? this.translate.underlyingAction : this.translate.underlyingActions);
      } else if (childrenNumber !== 0) {
        return this.translate.reopenActionAnd + ' ' + childrenNumber + ' ' + (childrenNumber == 1 ? this.translate.underlyingAction : this.translate.underlyingActions);
      } else {
        return this.translate.reopenAction;
      }
    },
    toggleMenuOptions: function toggleMenuOptions() {
      var _this2 = this;
      return [{
        id: 1,
        icon: 'plus',
        style: {
          color: Colors.system.black
        },
        hidden: this.inputsDisabled,
        title: this.translate.createUnderlyingAction,
        action: function action() {
          var params = {
            parent: _this2.data.id,
            title: _this2.data.title
          };
          if (_this2.defaultActivityType) {
            params['activityType'] = _this2.defaultActivityType;
          }
          _this2.createInitiative(params);
        }
      }, {
        id: 2,
        icon: 'plus',
        style: {
          color: Colors.system.black
        },
        hidden: this.inputsDisabled,
        title: this.translate.createSubsequentActionAtThisLevel,
        action: function action() {
          var _this2$data$parent$id, _this2$data$parent;
          var params = {
            parent: (_this2$data$parent$id = (_this2$data$parent = _this2.data.parent) === null || _this2$data$parent === void 0 ? void 0 : _this2$data$parent.id) !== null && _this2$data$parent$id !== void 0 ? _this2$data$parent$id : -1,
            title: _this2.data.title
          };
          if (_this2.defaultActivityType) {
            params['activityType'] = _this2.defaultActivityType;
          }
          _this2.createInitiative(params);
        }
      }, {
        id: 3,
        icon: 'copy',
        style: {
          color: Colors.system.black
        },
        hidden: this.inputsDisabled,
        title: this.translate.copyActionUnderlying,
        action: function action() {
          _this2.$router.push("/execution/i/-/".concat(_this2.data.id, "/copy/").concat(_this2.timestamp));
        }
      }, {
        id: 4,
        icon: 'calendar',
        style: {
          color: Colors.system.black
        },
        hidden: this.inputsDisabled,
        title: this.translate.shiftTheDatesOfThisAction,
        action: function action() {
          _this2.showDatesModal = true;
        }
      }, {
        id: 5,
        icon: 'x',
        style: {
          color: Colors.system.warning
        },
        hidden: this.hideReopenCloseAction,
        title: this.changeCloseStatusTitle,
        action: function action() {
          _this2.$emit('statusChanged', _this2.reversedActionStatus);
        }
      }, {
        id: 7,
        icon: 'x',
        style: {
          color: Colors.system.warning
        },
        hidden: this.inputsDisabled,
        title: this.translate.deleteActionAndAllUnderlyingActions,
        action: function action() {
          _this2.toggleDeleteAlert();
        }
      }, {
        id: 8,
        icon: 'hash',
        style: {
          color: Colors.system.warning
        },
        hidden: this.inputsDisabled,
        title: this.translate.updateSequenceNumber,
        action: function action() {
          _this2.$router.push("/execution/i/-/".concat(_this2.data.id, "/newno/").concat(_this2.timestamp));
        }
      }];
    },
    hideReopenCloseAction: function hideReopenCloseAction() {
      return this.data.parent && this.data.parent.closed;
    },
    showToggleMenu: function showToggleMenu() {
      return this.toggleMenuOptions.some(function (item) {
        return !item.hidden;
      });
    }
  }),
  watch: {
    'data.attachments': function dataAttachments() {
      this.updateAttachments();
    }
  },
  mounted: function mounted() {
    this.handleInitiative();
  },
  methods: {
    handleInitiative: function handleInitiative() {
      this.updateAttachments();
      this.inherited = !!this.data.numericalUnitInheritance;
    },
    addDefaultTitleLabel: function addDefaultTitleLabel(list, label) {
      var _this3 = this;
      return list.map(function (item) {
        item[_this3.defaultTitleLabel] = "".concat(item[label], "  ").concat(item.title);
        return item;
      });
    },
    createInitiative: function createInitiative(params) {
      var _this4 = this;
      return create(params).then(function (response) {
        return _this4.$router.push("/actions/i/-/".concat(response.slug, "/").concat(response.id, "/description"));
      });
    },
    isFieldInOptionalFields: function isFieldInOptionalFields(field) {
      if (!this.currentActivityType) return true;
      return this.currentActivityType.optionalFields.find(function (item) {
        return item == field;
      });
    },
    toggleShowAddNumericalUnitPopUp: function toggleShowAddNumericalUnitPopUp() {
      this.showAddNumericalUnitPopUp = !this.showAddNumericalUnitPopUp;
    },
    toggleShowNumericalUnit: function toggleShowNumericalUnit(unitNumber) {
      this.numericalUnits[unitNumber].show = !this.numericalUnits[unitNumber].show;
    },
    toggleDeleteAlert: function toggleDeleteAlert() {
      this.showDeleteAlert = !this.showDeleteAlert;
    },
    showSuccessMoveMessage: function showSuccessMoveMessage(message) {
      if (message) {
        this.$emit('notify', {
          type: 'success',
          message: this.$trans(message)
        });
      }
    },
    onCompletePercentageBlur: function onCompletePercentageBlur(e) {
      if (e > 100) this.completionPercentage = 100;
      if (e < 0) this.completionPercentage = 0;
    },
    moveInitiatives: function moveInitiatives(start, deadline, message) {
      this.start = start;
      this.deadline = deadline;
      this.showSuccessMoveMessage(message);
    },
    updateAttachments: function updateAttachments() {
      var _this5 = this;
      this.attachments = {
        status: [],
        description: [],
        target: [],
        comment: [],
        attachments: []
      };
      this.data.attachments && this.data.attachments.forEach(function (attachment) {
        _this5.attachments[attachment.field || 'attachments'].push(attachment);
      });
    },
    getAllFiles: function getAllFiles() {
      var _this$data;
      // get all attachments as array with new elements
      var allAttachments = Object.values(this.attachments).flat();

      // prepare them in array -> sort like this.data.attachment without deleted elements and with newly created ones
      var data = (_this$data = this.data) === null || _this$data === void 0 ? void 0 : _this$data.attachments.reduce(function (acc, next) {
        var index = allAttachments.findIndex(function (item) {
          return item.id === next.id;
        });
        if (index !== -1) {
          acc.push(next);
          allAttachments.splice(index, 1);
        }
        return acc;
      }, []);
      data = [].concat(_toConsumableArray(data), _toConsumableArray(allAttachments));
      return data;
    },
    handleFileChange: function handleFileChange(_ref) {
      var files = _ref.files,
        field = _ref.field;
      this.attachments[field] = files;
      this.$emit('silentUpdate:attachments', this.getAllFiles());
    }
  }
};