import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_tag_item = _resolveComponent("tag-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_tag_item, {
    title: _ctx.$trans('this is a test tag')
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        style: {
          "float": "right"
        },
        icon: "x",
        color: "white"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"])]);
}