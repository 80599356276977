import IncidentList from '@/components/Pages/Incident/IncidentList';
import IncidentReport from '@/components/Pages/Incident/IncidentReport';
import IncidentEntry from '@/components/Pages/Incident/IncidentEntry';
import IncidentDescription from '@/components/Pages/Incident/IncidentDescription';
import LinksTab from '@/components/Organisms/LinksTab';
import Changelog from '@/components/Molecules/Changelog';
import IncidentTypeExpanded from '@/components/Pages/Incident/IncidentTypeExpanded';
import MyIncidents from '@/components/Pages/Incident/MyIncidents';
import IncidentDashboard from '@/components/Pages/Incident/IncidentDashboard';
import IncidentAnonymousCreationPage from '@/components/Pages/Incident/IncidentAnonymousCreationPage';
import { MODULES } from '@/constants/modules';
import { UserLevels } from '@/constants/UserLevels';
import { userHasLevelIncident } from './auth';
import { INCIDENT } from '@/constants/routes/incident';
export default (function () {
  var routes = [{
    path: '/incident/list',
    name: INCIDENT.incidentList,
    component: IncidentList,
    meta: {
      userRightsFunction: userHasLevelIncident(UserLevels.NORMAL)
    }
  }, {
    path: '/incident/my/:group?',
    name: 'MyIncidents',
    component: MyIncidents,
    meta: {
      userRightsFunction: userHasLevelIncident(UserLevels.NORMAL),
      keyFunction: function keyFunction() {
        return '/incident/my';
      }
    },
    props: function props(route) {
      return {
        currentRouteGroup: route.params.group || 'my'
      };
    }
  }, {
    path: '/incident/archived',
    name: 'IncidentsArchived',
    component: MyIncidents,
    meta: {
      userRightsFunction: userHasLevelIncident(UserLevels.SUPER)
    },
    props: {
      currentRouteGroup: 'archived'
    }
  }, {
    path: '/incident/report',
    name: 'IncidentReport',
    component: IncidentReport,
    meta: {
      userRightsFunction: userHasLevelIncident(UserLevels.NORMAL)
    }
  }, {
    path: '/incident/dashboard',
    name: 'IncidentDashboard',
    component: IncidentDashboard,
    meta: {
      userRightsFunction: userHasLevelIncident(UserLevels.NORMAL)
    }
  }, {
    path: '/incident/:id',
    props: true,
    component: IncidentEntry,
    meta: {
      userRightsFunction: userHasLevelIncident(UserLevels.NORMAL)
    },
    children: [{
      path: 'description',
      name: 'IncidentDescription',
      props: true,
      component: IncidentDescription
    }, {
      path: 'changelog',
      name: 'IncidentChangelog',
      component: Changelog
    }, {
      path: 'links',
      component: LinksTab,
      props: {
        module: MODULES.INCIDENTS
      }
    }, {
      path: '',
      name: 'IncidentEntry',
      redirect: {
        name: 'IncidentDescription'
      }
    }]
  }, {
    path: '/incident/type/:typeId/expanded',
    name: 'IncidentTypeExpanded',
    component: IncidentTypeExpanded,
    props: true,
    meta: {
      userRightsFunction: userHasLevelIncident(UserLevels.NORMAL)
    }
  }, {
    path: '/incident/anonymous',
    component: IncidentAnonymousCreationPage,
    props: true,
    meta: {
      skipLoginCheck: true
    }
  }];
  return routes;
});