import ColorPicker from '@/components/Molecules/ColorPicker';
export default {
  components: {
    ColorPicker: ColorPicker
  },
  data: function data() {
    return {
      color: '#ffffff'
    };
  }
};