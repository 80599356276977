import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = ["title"];
var _hoisted_2 = ["title"];
var _hoisted_3 = ["title"];
var _hoisted_4 = ["title", "maxlength"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return !$props.editable && $props.type === $data.TitleTypes.BIG ? (_openBlock(), _createElementBlock("h1", {
    key: 0,
    class: _normalizeClass(['text-2xl text-blue-750 font-semibold', {
      'truncate': $props.truncate
    }]),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('click', $event);
    })
  }, [_createElementVNode("span", {
    title: $options.titleHTML
  }, _toDisplayString($props.title), 9 /* TEXT, PROPS */, _hoisted_1)], 2 /* CLASS */)) : !$props.editable && $props.type === $data.TitleTypes.MEDIUM ? (_openBlock(), _createElementBlock("h2", {
    key: 1,
    class: _normalizeClass(['text-base text-gray-750 font-bold', {
      'truncate': $props.truncate
    }]),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('click', $event);
    })
  }, [_createElementVNode("span", {
    title: $options.titleHTML
  }, _toDisplayString($props.title), 9 /* TEXT, PROPS */, _hoisted_2)], 2 /* CLASS */)) : !$props.editable && $props.type === $data.TitleTypes.SMALL ? (_openBlock(), _createElementBlock("p", {
    key: 2,
    class: _normalizeClass(['text-title-small text-gray-750', {
      'truncate': $props.truncate
    }]),
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('click', $event);
    })
  }, [_createElementVNode("span", {
    title: $options.titleHTML
  }, _toDisplayString($props.title), 9 /* TEXT, PROPS */, _hoisted_3)], 2 /* CLASS */)) : _withDirectives((_openBlock(), _createElementBlock("input", {
    key: 3,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.editedTitle = $event;
    }),
    class: _normalizeClass([[{
      'truncate': $props.truncate
    }, {
      'text-3xl text-blue-750': $props.type == 'big'
    }, {
      'text-lg text-gray-750': $props.type == 'medium'
    }, {
      'text-sm text-gray-750': $props.type == 'small'
    }, {
      'border-blue-150': $options.isEmpty
    }, !$options.isEditedTitleValid ? 'border-red-50' : 'border-transparent', !$options.isEditedTitleValid ? 'focus:border-red-50' : 'focus:border-blue-150'], "box-content font-semibold leading-9 border-2 rounded-lg px-2 bg-transparent focus:bg-white focus:outline-none cursor-pointer"]),
    title: $data.editedTitle,
    type: "text",
    maxlength: $props.maxLength,
    onBlur: _cache[4] || (_cache[4] = function () {
      return $options.handleBlur && $options.handleBlur.apply($options, arguments);
    }),
    onInput: _cache[5] || (_cache[5] = function () {
      return $options.handleChange && $options.handleChange.apply($options, arguments);
    }),
    onChange: _cache[6] || (_cache[6] = function () {
      return $options.handleChangeLazy && $options.handleChangeLazy.apply($options, arguments);
    }),
    onClick: _cache[7] || (_cache[7] = function ($event) {
      return _ctx.$emit('click', $event);
    })
  }, null, 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_4)), [[_vModelText, $data.editedTitle, void 0, {
    trim: true
  }]]);
}