import i18n from '@/i18n';
export var getTranslate = {
  InformationAssetsControllerDetails: function InformationAssetsControllerDetails() {
    return {
      description: i18n.t('Description'),
      address: i18n.t('Address'),
      countryCode: i18n.t('Country code'),
      phoneNumber: i18n.t('Phone number'),
      vatNumber: i18n.t('VAT number'),
      regNumber: i18n.t('Reg. number'),
      email: i18n.t('Email'),
      riskAssessment: i18n.t('Risk assessment'),
      recoveryTimeObjective: i18n.t('Recovery Time Objective'),
      selectRto: i18n.t('Select RTO'),
      recoveryPointObjective: i18n.t('Recovery Point Objective'),
      selectRpo: i18n.t('Select RPO')
    };
  }
};