export var ReportTypes = {
  CONTROLS: 'controls-report',
  CONTROLS_DATA: 'controls-data-report',
  CONTRACTS: 'contracts-report',
  ACTIONS: 'actions-report',
  COMPLIANCE_NODE: 'compliance-nodes-report',
  COMPLIANCE_GAP: 'compliance-gap-report',
  COMPLIANCE_ARTICLE30: 'compliance-article30-report',
  COMPLIANCE_QUESTIONNAIRE: 'compliance-questionnaire-report',
  COMPLIANCE_DATAFLOW: 'compliance-dataflow-report',
  RISK: 'risk-report',
  RISK_THREATS: 'risk-threats-report',
  RISK_VULNERABILITIES: 'risk-vulnerabilities-report',
  PROCESS_LIBRARY: 'compliance-process-tree-report',
  INCIDENTS: 'incidents-report'
};