function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { getLogReports } from '@/api/admin/reports';
import { DateFormats } from '@/constants/DateFormats';
import { SortDirection } from '@/constants/SortDirection';
import { getFortnightDateRange, formatTimeUtc } from '@/utils/date';
export default {
  components: {
    RismaTitle: RismaTitle,
    MainWell: MainWell,
    RismaDatatable: RismaDatatable,
    RismaButton: RismaButton,
    RismaDateRangePicker: RismaDateRangePicker,
    SingleSelect: SingleSelect,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  mixins: [RismaDatatableDefaultMixin],
  data: function data() {
    return {
      loading: null,
      showReport: false,
      showNothingToShowMessage: false,
      dataset: {},
      selectedUser: null,
      dateRange: getFortnightDateRange(DateFormats.DATE_TIME_FORMAT_FULL),
      orderNew: [{
        index: 'loginTime',
        dir: SortDirection.DESC
      }],
      DateFormats: DateFormats,
      translate: getTranslate['AdminLoginsReport']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    allUsers: 'activeUsers'
  })), {}, {
    columns: function columns() {
      return {
        loginTime: this.translate.timestamp,
        username: this.translate.username,
        groups: this.translate.groups
      };
    },
    getOptionsUsers: function getOptionsUsers() {
      return this.allUsers.map(function (user, index) {
        return {
          value: index,
          label: user.username
        };
      });
    }
  }),
  methods: {
    createDataset: function createDataset(data) {
      var _this = this;
      var dataset = [];
      data.forEach(function (element) {
        dataset.push({
          loginTime: {
            field: formatTimeUtc(element.loginTime, DateFormats.DATE_TIME_FORMAT_FULL)
          },
          username: {
            field: element.username
          },
          groups: _objectSpread({}, _this.getGroup(element.groups))
        });
      });
      return dataset;
    },
    getGroup: function getGroup(array) {
      var field = '';
      var data = {
        field: '',
        fieldText: ''
      };
      if (array.length) {
        field += '<ul>';
        array.forEach(function (el) {
          field += '<li>' + el.name + '</li>';
        });
        field += '</ul>';
        data = {
          field: field,
          fieldText: array.map(function (elem) {
            return elem.name;
          }).join(', ')
        };
      }
      return data;
    },
    setReports: function setReports(response) {
      this.dataset = this.createDataset(response.list);
      this.enhanceDataset(this.dataset, this.columns);
      this.setState('complete');
    },
    changedDates: function changedDates(e) {
      if (e) {
        this.dateRange.startDate = e.startDate;
        this.dateRange.endDate = e.endDate;
      }
    },
    createReport: function createReport() {
      this.setState('loading');
      var filters = this.getFilters();
      getLogReports(filters).then(this.setReports);
    },
    getFilters: function getFilters() {
      var filters = {};
      filters['dateAfter'] = this.dateRange.startDate;
      filters['dateBefore'] = this.dateRange.endDate;
      if (this.selectedUser !== null) {
        filters['username'] = this.allUsers[this.selectedUser].username;
      }
      return filters;
    },
    setState: function setState(state) {
      switch (state) {
        case 'loading':
          this.showReport = false;
          this.loading = true;
          this.showNothingToShowMessage = false;
          break;
        case 'complete':
          this.showReport = true;
          this.loading = false;
          if (!this.datasetSlice.length) {
            this.showNothingToShowMessage = true;
          }
          break;
      }
    }
  }
};