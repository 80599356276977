import { getTranslate } from './translate';
import GapResultRow from './GapResultRow';
export default {
  name: 'GapResults',
  introduction: 'A table to display gap search results',
  description: 'A table to display gap search results',
  token: '<gap-results :dataset="{}"/>',
  components: {
    GapResultRow: GapResultRow
  },
  props: {
    dataset: {
      type: [Object, Array],
      required: true,
      default: function _default() {},
      note: 'The data set for the table'
    },
    enableExport: {
      type: Boolean,
      default: true,
      note: 'Should it be possible to export the data to excel'
    },
    showRiskAssessment: {
      type: Boolean,
      required: true,
      default: true,
      note: 'defines if risk assessment info should be displayed'
    }
  },
  data: function data() {
    return {
      translate: getTranslate['GapResults']()
    };
  }
};