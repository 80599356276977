import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-34fd0042"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "router-links"
};
var _hoisted_2 = {
  class: "kitchen-fast-navigation"
};
var _hoisted_3 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tab_item = _resolveComponent("tab-item");
  var _component_tabs = _resolveComponent("tabs");
  var _component_router_link = _resolveComponent("router-link");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_view = _resolveComponent("router-view");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_tabs, null, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, function (tab, idx) {
        return _openBlock(), _createBlock(_component_tab_item, {
          key: idx,
          link: tab.link
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(tab.title), 1 /* TEXT */)];
          }),

          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["link"]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),

    _: 1 /* STABLE */
  }), _createVNode(_component_main_well, {
    class: "kitchensink-wrap"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("ul", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.listComponents, function (item, key) {
        return _openBlock(), _createElementBlock("li", {
          key: key
        }, [_createVNode(_component_router_link, {
          to: $options.getUrl(item),
          class: "navigation-kitchen focus:outline-none",
          onKeyup: [_withKeys($options.goBack, ["left"]), _withKeys($options.goForward, ["right"])]
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(key + 1) + ". " + _toDisplayString(item), 1 /* TEXT */)];
          }),

          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "onKeyup"])]);
      }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_2, [_createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
          return $options.goBack && $options.goBack.apply($options, arguments);
        }, ["prevent"]))
      }, [_createVNode(_component_feather_icon, {
        class: "inline-block",
        color: $data.colors.system.darkGray,
        icon: "chevrons-left"
      }, null, 8 /* PROPS */, ["color"])]), _createElementVNode("a", {
        href: "#",
        onClick: _cache[1] || (_cache[1] = _withModifiers(function () {
          return $options.goForward && $options.goForward.apply($options, arguments);
        }, ["prevent"]))
      }, [_createVNode(_component_feather_icon, {
        class: "inline-block",
        color: $data.colors.system.darkGray,
        icon: "chevrons-right"
      }, null, 8 /* PROPS */, ["color"])])]), _createElementVNode("section", null, [_createVNode(_component_router_view, {
        name: "header"
      }), _createVNode(_component_router_view, {
        name: "default"
      }), _createVNode(_component_router_view, {
        name: "footer"
      }), _ctx.$route.matched.length === 1 ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$trans('Please select a component')), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  })]);
}