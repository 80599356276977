import Http from '@/api/index';
import { handleRequestResponse } from '../httpUtils';
import { flushCache } from '@/api/HttpCache';
function flushAccountsCache() {
  flushCache('data/accounts');
  flushCache('data/accounts?data[]=usage');
}
function flushProcessesCache() {
  flushCache('data/processes');
  flushCache('data/processes?data[]=usage');
}
function flushRisksCache() {
  flushCache('controls/risks');
  flushCache('controls/risks?data[]=usage');
}
export function getControlRiskTypes() {
  return handleRequestResponse(Http.get('/controls/risktypes'));
}
export function createNewAccountAPI(data) {
  return handleRequestResponse(Http.post('/data/accounts', data)).then(function (data) {
    flushAccountsCache();
    return data;
  });
}
export function editAccountAPI(id, data) {
  return handleRequestResponse(Http.patch("/data/accounts/".concat(id), data)).then(function (data) {
    flushAccountsCache();
    return data;
  });
}
export function deleteAccountAPI(id) {
  return handleRequestResponse(Http.delete("/data/accounts/".concat(id))).then(function (data) {
    flushAccountsCache();
    return data;
  });
}
export function createNewProcessAPI(data) {
  return handleRequestResponse(Http.post('/data/processes', data)).then(function (data) {
    flushProcessesCache();
    return data;
  });
}
export function editProcessAPI(id, data) {
  return handleRequestResponse(Http.patch("/data/processes/".concat(id), data)).then(function (data) {
    flushProcessesCache();
    return data;
  });
}
export function deleteProcessAPI(id) {
  return handleRequestResponse(Http.delete("/data/processes/".concat(id))).then(function (data) {
    flushProcessesCache();
    return data;
  });
}
export function createNewRiskAPI(data) {
  return handleRequestResponse(Http.post('/controls/risks', data)).then(function (data) {
    flushRisksCache();
    return data;
  });
}
export function editRiskAPI(id, data) {
  return handleRequestResponse(Http.patch("/controls/risks/".concat(id), data)).then(function (data) {
    flushRisksCache();
    return data;
  });
}
export function deleteRiskAPI(id) {
  return handleRequestResponse(Http.delete("/controls/risks/".concat(id))).then(function (data) {
    flushRisksCache();
    return data;
  });
}
export function createNewControlRiskTypeAPI(data) {
  return handleRequestResponse(Http.post('/controls/risktypes', data));
}
export function editControlRiskTypeAPI(id, data) {
  return handleRequestResponse(Http.patch("/controls/risktypes/".concat(id), data));
}
export function deleteControlRiskTypeAPI(id) {
  return handleRequestResponse(Http.delete("/controls/risktypes/".concat(id)));
}