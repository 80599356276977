import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-29b428d0"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "modal-alert-wrap"
};
var _hoisted_2 = {
  class: "mt-2"
};
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = {
  key: 1
};
var _hoisted_5 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_modal, {
    header: $options.headerText,
    "header-truncate": false,
    "header-type": $data.TitleTypes.MEDIUM,
    "button-ok-text": $options.okText,
    "button-dismiss-text": $options.dismissText,
    type: $props.type,
    onAccept: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('accept', $event);
    }),
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [!Array.isArray($options.bodyText) ? (_openBlock(), _createElementBlock("p", {
        key: 0,
        innerHTML: $options.bodyText
      }, null, 8 /* PROPS */, _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.bodyText, function (message, idx) {
        return _openBlock(), _createElementBlock("p", {
          key: idx,
          innerHTML: message
        }, null, 8 /* PROPS */, _hoisted_5);
      }), 128 /* KEYED_FRAGMENT */))]))])];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "header-type", "button-ok-text", "button-dismiss-text", "type"])]);
}