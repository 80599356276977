import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import TrafficLightSelector from '@/components/Organisms/Selectors/TrafficLightSelector';
import RepeatableFields from '@/components/Molecules/RepeatableFields';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import StaticTable from '@/components/Molecules/StaticTable';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import RismaSplitButton from '@/components/Atoms/Buttons/RismaSplitButton';
import HelpToolTip from '@/components/Molecules/HelpToolTip';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { TrendOptions } from '@/constants/TrendOptions';
import { CustomFieldsMixin } from '@/components/Molecules/CustomFieldsMixin';
import { DateFormats } from '@/constants/DateFormats';
import { Colors } from '@/Colors';
import * as Utils from '@/utils/Utils';
import TextBox from '@/components/Atoms/Inputs/TextBox';
export default {
  name: 'ActivityCustomFields',
  introduction: 'Activity Custom Fields',
  description: 'Custom fields of an active activity type',
  token: '<activity-custom-fields/>',
  components: {
    TextBox: TextBox,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    InputField: InputField,
    TrafficLightSelector: TrafficLightSelector,
    TrafficLight: TrafficLight,
    RepeatableFields: RepeatableFields,
    StaticTable: StaticTable,
    DateTimePicker: DateTimePicker,
    RismaSplitButton: RismaSplitButton,
    HelpToolTip: HelpToolTip
  },
  mixins: [CustomFieldsMixin],
  props: {
    activityType: {
      type: Object,
      required: true,
      note: 'Activity type'
    },
    customFieldValues: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Saved custom field values'
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Are custom fields disabled?'
    },
    readonly: {
      type: Boolean,
      default: false,
      note: 'Are custom fields readonly (In Complete modal)?'
    },
    growingFields: {
      type: Boolean,
      default: false,
      note: 'Define if custom fields should be expandable when there is empty space'
    },
    responsive: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Determine if responsive design should be enabled/disabled'
    },
    dateOptions: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Options for date fields'
    }
  },
  data: function data() {
    return {
      CustomFieldTypes: CustomFieldTypes,
      filteredCustomFieldValues: [],
      allFields: [],
      currentTextarea: null,
      trendOptions: TrendOptions(),
      dateSaveFormat: DateFormats.DATE_TIME_FORMAT_FULL,
      requiredClasses: 'border-2 border-red-50 rounded-md'
    };
  },
  watch: {
    activityType: function activityType() {
      this.addFieldTypesSelected(this.activityType);
    },
    customFieldValues: {
      deep: true,
      handler: function handler() {
        this.filteredCustomFieldValues = this.getCustomFieldsFiltered(this.customFieldValues);
      }
    }
  },
  beforeMount: function beforeMount() {
    this.addFieldTypesSelected(this.activityType);
    this.filteredCustomFieldValues = this.getCustomFieldsFiltered(this.customFieldValues);
  },
  methods: {
    addFieldTypesSelected: function addFieldTypesSelected(data) {
      if (data.fields === undefined) {
        return;
      }
      this.addFieldTypesDefaultSelected(data);

      // If already saved custom fields
      if (this.customFieldValues) {
        this.addFieldTypesSelectedFromCustomField(data);
      }
      return data;
    },
    getCustomFieldsFiltered: function getCustomFieldsFiltered(data) {
      var result = [];
      if (!data || !data.length || this.activityType === undefined) {
        return result;
      }
      this.activityType.fields.map(function (item) {
        data.map(function (dataItem) {
          if (item.id === dataItem.fieldId) {
            result.push(dataItem);
          }
        });
      });
      return result;
    },
    addFieldTypesDefaultSelected: function addFieldTypesDefaultSelected(data) {
      var _this = this;
      data.fields.map(function (field) {
        if (field.typeId === CustomFieldTypes.TRAFFIC_LIGHT) {
          field.selectedValue = 0;
        } else {
          field.selectedValue = '';
        }
        _this.allFields.push(field);
      });
    },
    addFieldTypesSelectedFromCustomField: function addFieldTypesSelectedFromCustomField(data) {
      var _this2 = this;
      this.customFieldValues.map(function (customFieldValue) {
        data.fields.map(function (field) {
          if (field.id === customFieldValue.fieldId) {
            if (field.typeId === CustomFieldTypes.SELECT) {
              var options = Utils.getProperSelectedOptions(field.options);
              var found = options.find(function (option) {
                return option.id === customFieldValue.value;
              });
              field.selectedValue = found ? found.value : undefined;
            } else {
              if (customFieldValue) {
                field.selectedValue = customFieldValue.value;
              }
            }
            _this2.swapToActualField(_this2.allFields, field);
          }
        });
      });
    },
    isSimpleComponent: function isSimpleComponent(field) {
      return field.typeId === CustomFieldTypes.TEXT_LINE || field.typeId === CustomFieldTypes.TEXT_FIELD || field.typeId === CustomFieldTypes.SELECT || field.typeId === CustomFieldTypes.TREND;
    },
    findTrafficColor: function findTrafficColor(value) {
      return Colors.trafficlightColor(value, true).color;
    },
    prepareColumns: function prepareColumns(field) {
      var result = [];
      if (field.children) {
        field.children.map(function (child) {
          return result.push(child.label);
        });
      }
      return result;
    },
    prepareDataset: function prepareDataset(field) {
      var _this3 = this;
      var result = [];
      var record = {};
      this.filteredCustomFieldValues.map(function (customFieldValue) {
        if (customFieldValue.fieldId === field.id && customFieldValue.rows) {
          for (var rowNumber in customFieldValue.rows) {
            record = _this3.prepareRecord(field.children, customFieldValue.rows[rowNumber]);
            result.push(record);
          }
        }
      });
      return result;
    },
    prepareRecord: function prepareRecord(data, rowData) {
      var _this4 = this;
      var result = {};

      // Init labels
      data.map(function (field) {
        return result[field.label] = '';
      });

      // Populating exist values
      data.map(function (dataItem) {
        rowData.map(function (rowItem) {
          if (dataItem.id === rowItem.fieldId) {
            if (dataItem.typeId === CustomFieldTypes.SELECT) {
              var options = Utils.getProperSelectedOptions(dataItem.options);
              result[dataItem.label] = options.find(function (option) {
                return option.id === rowItem.value;
              }).value;
            } else if (dataItem.typeId === CustomFieldTypes.TRAFFIC_LIGHT) {
              result[dataItem.label] = _this4.prepareTrafficComponent(rowItem.value);
            } else {
              result[dataItem.label] = rowItem.value;
            }
          }
        });
      });
      return result;
    },
    prepareTrafficComponent: function prepareTrafficComponent(data) {
      var bgColor = Colors.getTrafficlightByNumber(data).color;
      return "<span class=\"traffic-light\" style=\"background: ".concat(bgColor, "\"></span>");
    },
    hasSavedValue: function hasSavedValue(field) {
      var result = false;
      if (field.typeId === CustomFieldTypes.ROW_GROUP) {
        this.filteredCustomFieldValues.map(function (customFieldValue) {
          if (customFieldValue.fieldId === field.id && Object.keys(customFieldValue.rows).length) {
            result = true;
          }
        });
      } else {
        if (field.selectedValue) {
          result = true;
        }
      }
      return result;
    },
    toggleTextareaSize: function toggleTextareaSize(field) {
      this.currentTextarea = field;
    }
  }
};