function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import PreviewRisk from '@/components/Atoms/Preview/PreviewRisk';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import StaticTable from '@/components/Molecules/StaticTable';
import * as Utils from '@/utils/Utils';
import { checkERMByProjectId } from '@/utils/risks/check';
import { RisksVisibleProps } from '@/constants/risks/RisksVisibleProps';
import { RiskCookies } from '@/constants/risks/RiskCookies';
import { viewTypes as MypageViews } from '@/constants/ListViewType';
export default {
  name: 'MyRisksList',
  introduction: 'My risks for MyPage',
  description: 'My risks for MyPage',
  token: '<my-risks-list />',
  components: {
    PreviewRisk: PreviewRisk,
    PreviewList: PreviewList,
    StaticTable: StaticTable
  },
  props: {
    risks: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'all risks for component'
    },
    scoring: {
      required: true,
      type: Object,
      default: function _default() {}
    },
    viewMode: {
      required: false,
      type: String,
      default: 'compact',
      note: 'View type'
    },
    organisations: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of all organisations'
    },
    title: {
      required: false,
      type: String,
      default: 'My risks',
      note: 'title'
    }
  },
  emits: ['changeShowState'],
  data: function data() {
    return {
      risksVisibleProps: RisksVisibleProps,
      mypageViews: MypageViews,
      checkERMByProjectId: checkERMByProjectId,
      translate: {
        approvalStatus: this.$trans('Approval status'),
        approved: this.$trans('Approved'),
        notApproved: this.$trans('Not approved')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    dataset: function dataset() {
      var _this = this;
      var result = [];
      result = this.risks.map(function (risk) {
        var preparedItem = {};
        _this.risksVisibleProps.forEach(function (column) {
          preparedItem[column.key] = _this.prepareCompactHtml(column.key, risk);
        });
        if (_this.isSomeRiskAppovalEnabled) {
          preparedItem['approved'] = risk.project.riskApproval ? _this.prepareCompactHtml('approved', risk) : '';
        }
        return preparedItem;
      });
      return result;
    },
    columns: function columns() {
      var _this2 = this;
      var columns = this.risksVisibleProps.map(function (item) {
        return _this2.$trans(item.text);
      });
      if (this.isSomeRiskAppovalEnabled) {
        columns.push(this.translate.approvalStatus);
      }
      return columns;
    },
    visibilityDefaultColumns: function visibilityDefaultColumns() {
      var _this3 = this;
      return this.risksVisibleProps.filter(function (item) {
        return item.default;
      }).map(function (item) {
        return _this3.$trans(item.text);
      });
    },
    isSomeRiskAppovalEnabled: function isSomeRiskAppovalEnabled() {
      return this.risks.some(function (risk) {
        return !!risk.project.riskApproval;
      });
    }
  }),
  methods: {
    prepareCompactHtml: function prepareCompactHtml(column, risk) {
      var result = '';
      switch (column) {
        case 'title':
          result = this.prepareTitle(risk);
          break;
        case 'organisationIds':
          result = Utils.findPropsByIds(risk[column], this.organisations, 'visible_name');
          break;
        case 'likelihood_score':
          result = !this.getSettingValue('risma.likelihood_percentage_disabled') && risk[column] ? this.scoring.likelihood_values[risk.likelihood_score + 1] : '';
          break;
        case 'tags':
          result = this.prepareTags(risk);
          break;
        case 'approved':
          result = risk.approved ? this.translate.approved : this.translate.notApproved;
          break;
        default:
          result = risk[column] || '';
      }
      return result;
    },
    prepareTitle: function prepareTitle(risk) {
      return {
        url: risk.url,
        additionalClasses: "".concat(!risk.open ? 'risk-closed' : ''),
        title: "".concat(risk.rno, ". ").concat(risk.title)
      };
    },
    prepareTags: function prepareTags(risk) {
      var result = [];
      if (risk.tags && risk.tags.length) {
        result = risk.tags.map(function (item) {
          return item.tag;
        });
      }
      return result.join(', ');
    },
    getVisibilityStorageName: function getVisibilityStorageName() {
      return 'myPage_' + RiskCookies.LIST_COMPACT_HIDDEN;
    }
  }
};