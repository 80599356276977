import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title_with_icon, {
    title: "Example 1",
    "title-attribute": "hover title 1",
    icon: "confidential",
    "display-icon": true
  }), _createVNode(_component_risma_title_with_icon, {
    title: "Example 2",
    type: "big",
    "title-attribute": "hover title 2",
    icon: "confidential",
    "display-icon": true
  }), _createVNode(_component_risma_title_with_icon, {
    title: "No icon",
    type: "big",
    "title-attribute": "hover title 3"
  }), _createVNode(_component_risma_title_with_icon, {
    title: "With custom icon",
    type: "medium",
    "title-attribute": "hover title 3",
    icon: {
      icon: 'activity'
    },
    "display-icon": true
  })]);
}