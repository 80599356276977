import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_create_initiative_modal = _resolveComponent("create-initiative-modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_button, {
    type: 'util',
    text: 'Toggle Modal',
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.showCreateInitiative = true;
    })
  }), _createVNode(_component_create_initiative_modal, {
    ref: "createInitiativeModal",
    show: _ctx.showCreateInitiative,
    users: $options.users,
    initiatives: $options.initiatives,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.showCreateInitiative = false;
    }),
    onAccept: $options.alert
  }, null, 8 /* PROPS */, ["show", "users", "initiatives", "onAccept"])]);
}