import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-307a98ba"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["width", "height"];
var _hoisted_2 = ["fill", "stroke"];
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = ["stroke"];
var _hoisted_6 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    width: $props.width + 'px',
    height: $props.height + 'px',
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    "xmlns:xlink": "http://www.w3.org/1999/xlink",
    x: "0px",
    y: "0px",
    viewBox: "0 0 455.871 456.71",
    "xml:space": "preserve",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('click', $event);
    })
  }, [_createElementVNode("circle", {
    fill: $props.color,
    stroke: $props.stroke,
    "stroke-width": "50",
    "stroke-miterlimit": "10",
    cx: "228.066",
    cy: "229.354",
    r: "201.614"
  }, [$props.title ? (_openBlock(), _createElementBlock("title", _hoisted_3, _toDisplayString($props.title), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_2), $options.isBan ? (_openBlock(), _createElementBlock("g", _hoisted_4, [_createElementVNode("circle", {
    fill: "none",
    "stroke-width": "30",
    "stroke-miterlimit": "10",
    stroke: $data.colors.gaps[4].color,
    cx: "230",
    cy: "230",
    r: "140"
  }, null, 8 /* PROPS */, _hoisted_5), _createElementVNode("line", {
    "stroke-width": "30",
    fill: "red",
    stroke: $data.colors.gaps[4].color,
    x1: "150",
    y1: "320",
    x2: "330",
    y2: "110"
  }, null, 8 /* PROPS */, _hoisted_6)])) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_1);
}