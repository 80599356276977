function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getQuestionnaireTypes } from '@/api/compliance/questionnaire';
import { getUserActivityModules } from '@/constants/admin/userActivityModules';
import { QuestionnaireTypes } from '@/constants/compliance/QuestionnaireTypes';
import { MODULES } from '@/constants/modules';
import { useSettingsStore } from '@/Store/settingsStore';
export var getQuestionnaireTypesAccess = function getQuestionnaireTypesAccess() {
  var _ref;
  var settingsStore = useSettingsStore();
  return _ref = {}, _defineProperty(_ref, QuestionnaireTypes.SYSTEM, settingsStore.isSystemsEnabled), _defineProperty(_ref, QuestionnaireTypes.SYSTEM_AUDIT, settingsStore.isSystemsEnabled), _defineProperty(_ref, QuestionnaireTypes.DATACONTROLLER, settingsStore.isDataControllersEnabled), _defineProperty(_ref, QuestionnaireTypes.DATACONTROLLER_AUDIT, settingsStore.isDataControllersEnabled), _defineProperty(_ref, QuestionnaireTypes.DATAPROCESSOR, settingsStore.isDataProcessorsEnabled), _defineProperty(_ref, QuestionnaireTypes.IA_CUSTOM, settingsStore.isIACustomEnabled), _defineProperty(_ref, QuestionnaireTypes.SUBPROCESSOR, !settingsStore.isInformationAssetsDisabled), _defineProperty(_ref, QuestionnaireTypes.DPM, !settingsStore.isInformationAssetsDisabled), _ref;
};
export function getAvailableQuestionnaireTypes() {
  var QuestionnaireTypesAccess = getQuestionnaireTypesAccess();
  return getQuestionnaireTypes().then(function (_ref2) {
    var list = _ref2.list;
    return list.filter(function (questionnaireType) {
      if (QuestionnaireTypesAccess[questionnaireType.id] !== undefined) {
        return QuestionnaireTypesAccess[questionnaireType.id];
      }
      return true;
    });
  });
}
export function getAvailableUserActivityModules() {
  var _ModulesAccess;
  var modules = getUserActivityModules();
  var settingsStore = useSettingsStore();
  var ModulesAccess = (_ModulesAccess = {}, _defineProperty(_ModulesAccess, MODULES.COMPLIANCE_IA_SYSTEM, settingsStore.isSystemsEnabled), _defineProperty(_ModulesAccess, MODULES.COMPLIANCE_IA_CONTROLLER, settingsStore.isDataControllersEnabled), _defineProperty(_ModulesAccess, MODULES.COMPLIANCE_IA_PROCESSOR, settingsStore.isDataProcessorsEnabled), _ModulesAccess);
  var filterModulesByAccess = function filterModulesByAccess(modules, accessConfig) {
    var filteredModulesAsArray = Object.entries(modules).filter(function (_ref3) {
      var _ref4 = _slicedToArray(_ref3, 1),
        module = _ref4[0];
      if (accessConfig[module] !== undefined) {
        return accessConfig[module];
      }
      return true;
    });
    return Object.fromEntries(filteredModulesAsArray);
  };
  return filterModulesByAccess(modules, ModulesAccess);
}
export function getQuestionByNumber(number, data) {
  if (data instanceof Map) {
    return data.get(number);
  }
  return data.find(function (question) {
    return question.questionnumber === number;
  });
}
export function checkForQuestionAnswers(arr, haystack) {
  if (arr instanceof Array) {
    return arr.some(function (v) {
      return haystack.indexOf(v) >= 0;
    });
  } else {
    return false;
  }
}
export function questionHasSomeDependencyChosen(question, questions) {
  var entries = Object.entries(question.dependencies || {});
  return entries.length === 0 || entries.some(function (_ref5) {
    var _getQuestionByNumber;
    var _ref6 = _slicedToArray(_ref5, 2),
      number = _ref6[0],
      question = _ref6[1];
    var answerOptions = ((_getQuestionByNumber = getQuestionByNumber(number, questions)) === null || _getQuestionByNumber === void 0 ? void 0 : _getQuestionByNumber.answers_options) || {};
    return checkForQuestionAnswers(question, Object.values(answerOptions));
  });
}