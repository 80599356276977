var _charts, _overviewCharts, _completionCharts;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Colors } from '@/Colors';
var red = '#c6060f',
  yellow = '#ffc103',
  green = '#57aa74';
var blue1 = '#3a8d96',
  blue2 = '#003d46',
  blue3 = '#003dff';
export var ChartsKey = {
  CONTROL_STATUS: 'control_status',
  OVERALL_STATUS: 'overall_status',
  OVERDUE_CONTROLS_PR_EMPLOYEE: 'overdue_controls_pr_employee',
  OVERALL_REVIEWS: 'overall_reviews',
  OVERALL_COMPLETED: 'overall_completed',
  OVERDUE_REVIEWS_PR_EMPLOYEE: 'overdue_reviews_pr_employee',
  STATUS_PR_PROCESS: 'status_pr_process'
};
var graphsInfo = [{
  color: Colors.getTrafficlightByNumber(1).color,
  field: 'red_traffic_light',
  title: 'Red traffic light'
}, {
  color: Colors.getTrafficlightByNumber(2).color,
  field: 'yellow_traffic_light',
  title: 'Yellow traffic light'
}, {
  color: Colors.getTrafficlightByNumber(3).color,
  field: 'green_traffic_light',
  title: 'Green traffic light'
}, {
  color: 'blue',
  field: 'controls_rejected',
  title: 'Rejected controls'
}];
export var ControlsSettings = {
  pieTableColumn: [{
    key: 'index',
    text: ''
  }, {
    key: 'text',
    text: ''
  }, {
    key: 'owners',
    text: ''
  }, {
    key: 'organisations',
    text: ''
  }],
  charts: (_charts = {}, _defineProperty(_charts, ChartsKey.CONTROL_STATUS, {
    title: 'Control status',
    colors: [green, yellow, blue1, blue2, blue3, red]
  }), _defineProperty(_charts, ChartsKey.OVERALL_STATUS, {
    title: 'Pending controls',
    colors: [green, yellow, red, blue1]
  }), _defineProperty(_charts, ChartsKey.OVERDUE_CONTROLS_PR_EMPLOYEE, {
    title: 'Overdue controls per employee',
    colors: [red]
  }), _defineProperty(_charts, ChartsKey.OVERALL_REVIEWS, {
    title: 'Reviews'
  }), _defineProperty(_charts, ChartsKey.OVERALL_COMPLETED, {
    title: 'Completed controls'
  }), _defineProperty(_charts, ChartsKey.OVERDUE_REVIEWS_PR_EMPLOYEE, {
    title: 'Overdue reviews per employee',
    colors: [red]
  }), _defineProperty(_charts, ChartsKey.STATUS_PR_PROCESS, {
    title: 'Pending controls per process',
    graphsInfo: graphsInfo
  }), _charts),
  overviewCharts: (_overviewCharts = {}, _defineProperty(_overviewCharts, ChartsKey.OVERDUE_CONTROLS_PR_EMPLOYEE, {
    title: 'Overdue controls per employee',
    colors: [red]
  }), _defineProperty(_overviewCharts, ChartsKey.OVERDUE_REVIEWS_PR_EMPLOYEE, {
    title: 'Overdue reviews per employee',
    colors: [red]
  }), _defineProperty(_overviewCharts, ChartsKey.STATUS_PR_PROCESS, {
    title: 'Pending controls per process',
    graphsInfo: graphsInfo
  }), _overviewCharts),
  completionCharts: (_completionCharts = {}, _defineProperty(_completionCharts, ChartsKey.CONTROL_STATUS, {
    title: 'Control status',
    colors: [green, yellow, blue1, blue2, blue3, red]
  }), _defineProperty(_completionCharts, ChartsKey.OVERALL_STATUS, {
    title: 'Pending controls',
    colors: [green, yellow, red, blue1]
  }), _defineProperty(_completionCharts, ChartsKey.OVERALL_REVIEWS, {
    title: 'Reviews'
  }), _defineProperty(_completionCharts, ChartsKey.OVERALL_COMPLETED, {
    title: 'Completed controls'
  }), _completionCharts)
};
export var ControlsStates = {
  UPCOMING: 'upcoming',
  EXCEEDED: 'exceeded'
};