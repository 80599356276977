import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { clickOutside } from '@/utils/directives/clickOutside';
export default {
  name: 'ToggleMenu',
  components: {
    FeatherIcon: FeatherIcon
  },
  introduction: 'Toggler with menu items.',
  description: 'Flexible toggle menu component. You can find it here: /controls2/controls/1',
  token: '<toggle-menu :options="dataEntryMenuOptions"></toggle-menu>',
  directives: {
    clickOutside: clickOutside
  },
  props: {
    options: {
      type: Array,
      required: true,
      note: 'Array of menu items'
    },
    menuWidth: {
      type: Number,
      required: false,
      default: 220
    },
    position: {
      type: String,
      required: false,
      default: 'absolute',
      validator: function validator(value) {
        return ['absolute', 'fixed'].includes(value);
      }
    },
    menuStyle: {
      type: String,
      required: false,
      default: 'classic',
      validator: function validator(value) {
        return ['classic', 'minimal'].includes(value);
      }
    }
  },
  data: function data() {
    return {
      isVisible: false,
      positionLeft: 'auto',
      positionTop: '0px',
      positionRight: '0px'
    };
  },
  computed: {
    visibleOptions: function visibleOptions() {
      return this.options.filter(function (option) {
        return !option.hidden;
      });
    }
  },
  methods: {
    toggleVisibility: function toggleVisibility(event) {
      var borderMargin = 70;
      var windowHeight = window.innerHeight || Math.max(document.documentElement.clientHeight, document.body.clientHeight);
      var modalHeight = this.visibleOptions.length * 25 + 50;
      if (this.position === 'fixed') {
        var leftMargin = 70;
        var rect = event.target.getBoundingClientRect();
        var positionTop = rect.top;
        var windowCross = windowHeight - positionTop - modalHeight;
        if (windowCross < borderMargin) {
          positionTop = positionTop + windowCross - borderMargin;
        }
        this.positionLeft = rect.left + leftMargin + 'px';
        this.positionTop = positionTop + 'px';
        this.positionRight = 'auto';
      }
      if (this.position === 'absolute') {
        this.positionLeft = 'auto';
        this.positionTop = '0px';
        this.positionRight = '0px';
      }
      this.isVisible = !this.isVisible;
    },
    closeMenu: function closeMenu() {
      this.isVisible = false;
    }
  }
};