export default [{
  value: 'EUR',
  label: 'EUR'
}, {
  value: 'USD',
  label: 'USD'
}, {
  value: 'DKK',
  label: 'DKK'
}, {
  value: 'NOK',
  label: 'NOK'
}, {
  value: 'SEK',
  label: 'SEK'
}, {
  value: 'GBP',
  label: 'GBP'
}, {
  value: 'CHF',
  label: 'CHF'
}, {
  value: 'PLN',
  label: 'PLN'
}];