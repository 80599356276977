import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "border border-gray-350 h-375px relative"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_tasks_list = _resolveComponent("tasks-list");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0,
    class: "absolute inset-1/2 -translate-x-6 -translate-y-7"
  })) : (_openBlock(), _createBlock(_component_tasks_list, {
    key: 1,
    class: "h-375px",
    title: $options.listTitle,
    "ia-list": $data.iaList,
    "asset-type": $props.assetType,
    "show-create-button": $options.showCreateButton,
    onCreated: $options.handleCreate,
    onRefreshList: $options.loadListByAssetType
  }, null, 8 /* PROPS */, ["title", "ia-list", "asset-type", "show-create-button", "onCreated", "onRefreshList"]))]);
}