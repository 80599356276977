import { getTranslate } from './translate';
import { getCompanies, deleteCompany as _deleteCompany } from '@/api/admin/companies';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import Alert from '@/components/Molecules/Modal/Alert';
import Notification from '@/components/Molecules/Notification';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import CreateCompany from '@/components/Molecules/Companies/CreateCompany';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import { VAT_STATUSES } from '@/constants/companies/vatStatuses';
import { generateKey } from '@/utils/Utils';
import { check403AccessError } from '@/utils/handleAPIErrors';
export default {
  name: 'Companies',
  components: {
    RismaTitle: RismaTitle,
    Alert: Alert,
    Notification: Notification,
    RismaTable: RismaTable,
    CreateCompany: CreateCompany,
    NotificationPopUp: NotificationPopUp
  },
  mixins: [NotificationPopUpMixin],
  props: {
    isBankruptcy: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Show only bankrupt companies. vatStatus 2 or 4'
    }
  },
  emits: ['updateBankruptcyCount'],
  data: function data() {
    return {
      showDeleteCompanyAlert: false,
      bankruptcyInit: false,
      notificationMessage: '',
      notificationType: 'error',
      timer: 0,
      selectedCompanyId: null,
      selectedCompanyTitle: '',
      tableGeneratedKey: generateKey(),
      translate: getTranslate['Companies']()
    };
  },
  computed: {
    companiesStateKey: function companiesStateKey() {
      return this.isBankruptcy ? 'companies_table_bankruptcy' : 'companies_table';
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'company');
    },
    tableHeader: function tableHeader() {
      return this.isBankruptcy ? this.translate.bankruptcyList : this.translate.companies;
    }
  },
  watch: {
    isBankruptcy: function isBankruptcy() {
      this.bankruptcyInit = false;
    }
  },
  methods: {
    handleDeleteCompany: function handleDeleteCompany(_ref) {
      var id = _ref.id,
        title = _ref.title;
      this.selectedCompanyId = id;
      this.selectedCompanyTitle = title;
      this.toggleDeleteCompanyAlert();
    },
    toggleDeleteCompanyAlert: function toggleDeleteCompanyAlert() {
      this.showDeleteCompanyAlert = !this.showDeleteCompanyAlert;
    },
    showNotification: function showNotification(message) {
      var _this = this;
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      this.notificationMessage = this.$trans(message);
      this.notificationType = type;
      clearTimeout(this.timer);
      this.$nextTick(function () {
        window.scrollTo(0, 0);
      });
      this.timer = setTimeout(function () {
        _this.notificationMessage = '';
      }, 3000);
    },
    getCompaniesBE: function getCompaniesBE(params) {
      var _this2 = this;
      var separator = params ? '&' : '';
      var filterParams = '';
      if (this.isBankruptcy && !this.bankruptcyInit) {
        filterParams = "".concat(separator, "filters[vat_status_as_text_translated][]=").concat(VAT_STATUSES.BANKRUPT.value, "\n                    &filters[vat_status_as_text_translated][]=").concat(VAT_STATUSES.UNDER_BANKRUPTCY.value);
        this.bankruptcyInit = true;
      }
      return getCompanies("".concat(params).concat(filterParams)).catch(function (error) {
        _this2.showNotification(error.fullError.message);
        throw error;
      });
    },
    deleteCompany: function deleteCompany() {
      var _this3 = this;
      _deleteCompany(this.selectedCompanyId).then(function (company) {
        _this3.refreshCompanyTable();
        _this3.toggleDeleteCompanyAlert();
        _this3.updateBankruptcyCount(company, -1);
      }).catch(function (error) {
        _this3.toggleDeleteCompanyAlert();
        _this3.showNotification(error.fullError.message);
      });
    },
    createCompany: function createCompany(company) {
      this.refreshCompanyTable();
      this.setNotificationData(company);
      this.turnAfterRefreshNotification();
      this.updateBankruptcyCount(company, 1);
    },
    updateBankruptcyCount: function updateBankruptcyCount(company, difference) {
      if (![VAT_STATUSES.BANKRUPT.value, VAT_STATUSES.UNDER_BANKRUPTCY.value].includes(company.vatStatus)) return;
      this.$emit('updateBankruptcyCount', difference);
    },
    refreshCompanyTable: function refreshCompanyTable() {
      this.tableGeneratedKey = generateKey();
    }
  }
};