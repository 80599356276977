import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
export default {
  name: 'DashboardTabs',
  components: {
    LightTabItem: LightTabItem
  },
  data: function data() {
    return {
      translate: {
        questions: this.$trans('Questions'),
        gap: this.$trans('Gap')
      }
    };
  }
};