import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3420d238"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 3
};
var _hoisted_2 = {
  class: "mb-2"
};
var _hoisted_3 = {
  class: "mb-2"
};
var _hoisted_4 = {
  class: "flex items-center"
};
var _hoisted_5 = {
  class: "mr-3"
};
var _hoisted_6 = {
  class: "flex items-center relative"
};
var _hoisted_7 = {
  key: 0,
  class: "text-red-50 pl-8"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_notification = _resolveComponent("notification");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_drag_and_drop_icon = _resolveComponent("drag-and-drop-icon");
  var _component_input_field = _resolveComponent("input-field");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_draggable = _resolveComponent("draggable");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [$data.showDeleteGapTypeAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    body: $data.translate.areYouSureYouWantToDeleteThisGapType,
    header: $data.translate.areYouSure,
    "button-ok-text": $data.translate.delete,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.onDeleteAlertAccept,
    onDismiss: $options.dismissDeleteAlert
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.gapTypes,
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), $data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: $data.notificationType,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.notificationMessage = '';
    })
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", null, _toDisplayString($data.notificationMessage), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.gapTypeOptions,
    type: "medium",
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"]), $data.gapTypesLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 2
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_draggable, _mergeProps({
    modelValue: $data.gapTypes,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.gapTypes = $event;
    }),
    tag: "div",
    "item-key": "id",
    handle: ".drag-icon"
  }, $data.DragOptions, {
    onChange: $options.recalculateGapTypeNumbers
  }), {
    item: _withCtx(function (_ref) {
      var element = _ref.element;
      return [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_drag_and_drop_icon, {
        class: "drag-icon"
      })]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_input_field, {
        modelValue: element.label,
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return element.label = $event;
        },
        type: "text",
        style: {
          width: '300px'
        },
        class: _normalizeClass({
          'is-not-valid': !element.label && $data.notificationMessage
        })
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "class"]), _createVNode(_component_feather_icon, {
        icon: "x",
        class: "stroke-3 text-red-50 cursor-pointer ml-2",
        onClick: function onClick($event) {
          return $options.onGapTypeDelete(element);
        }
      }, null, 8 /* PROPS */, ["onClick"])])])]), !element.label && $data.notificationMessage ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($data.translate.pleaseEnterAValue), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["modelValue", "onChange"])]), _createElementVNode("div", {
    class: "flex items-center cursor-pointer",
    onClick: _cache[2] || (_cache[2] = function () {
      return $options.onGapTypeAdd && $options.onGapTypeAdd.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-3 mr-2"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.addGapTypeOption), 1 /* TEXT */)])])), _createVNode(_component_risma_button, {
    text: $data.translate.saveGapTypes,
    type: "save",
    class: "mt-6",
    onClick: $options.onSaveGapTypes
  }, null, 8 /* PROPS */, ["text", "onClick"])]);
}