function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import OverlyingUnderlyingInformationAssets from '@/components/Organisms/InformationAssets/OverlyingUnderlyingInformationAssets';
import MultipleQuestionnaireSelector from '@/components/Molecules/QuestionnaireSelector/MultipleQuestionnaireSelector';
import SolutionIdRelationDetails from '@/components/Molecules/SolutionIdRelationDetails';
import Alert from '@/components/Molecules/Modal/Alert';
import ActivityTypeSelector from '@/components/Molecules/ActivityTypeSelector';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export default {
  name: 'InformationAssetsDetailsRelations',
  components: {
    SolutionIdRelationDetails: SolutionIdRelationDetails,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    OverlyingUnderlyingInformationAssets: OverlyingUnderlyingInformationAssets,
    MultipleQuestionnaireSelector: MultipleQuestionnaireSelector,
    Alert: Alert,
    ActivityTypeSelector: ActivityTypeSelector
  },
  props: {
    informationAsset: {
      required: true,
      type: Object,
      default: function _default() {}
    },
    type: {
      required: true,
      type: String,
      default: AssetsTypes.SYSTEMS,
      note: 'type - can be systems|processors|custom'
    },
    activityTypes: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    questionnaires: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    items: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    riskAssessmentSchemas: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    showGapAnalysis: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['changed', 'createProcessor'],
  data: function data() {
    return {
      showUpdateGapSchemaAlert: false,
      translate: getTranslate['InformationAssetsDetailsRelations']()
    };
  },
  computed: {
    parents: function parents() {
      if (this.type === AssetsTypes.SYSTEMS) {
        return this.informationAsset.parentId === null ? [] : [this.informationAsset.parentId];
      }
      return this.informationAsset.parents;
    },
    activeQuestionnaires: function activeQuestionnaires() {
      var _this$informationAsse;
      if (!((_this$informationAsse = this.informationAsset) !== null && _this$informationAsse !== void 0 && (_this$informationAsse = _this$informationAsse.questionnaires) !== null && _this$informationAsse !== void 0 && _this$informationAsse.length)) return [];
      var enabledQuestionnaireIds = this.questionnaires.map(function (q) {
        return q.id;
      });
      return this.informationAsset.questionnaires.filter(function (q) {
        return enabledQuestionnaireIds.includes(q.questionnaireId);
      });
    }
  },
  methods: {
    addQuestionnaire: function addQuestionnaire(id) {
      var questionnaires = this.informationAsset.questionnaires || [];
      var questionnaire = this.questionnaires.find(function (q) {
        return q.id == id;
      });
      if (questionnaire.defaultGapSchemaId && questionnaire.defaultGapSchemaId !== this.informationAsset.gapSchemaId) {
        this.showUpdateGapSchemaAlert = true;
      }
      this.$emit('changed', {
        property: 'questionnaires',
        value: [].concat(_toConsumableArray(questionnaires), [{
          questionnaireId: id,
          tabLabel: questionnaire.tabLabel,
          url: ''
        }])
      });
    },
    changeGapSchema: function changeGapSchema(gapSchemaId) {
      if (this.informationAsset.gapSchemaId === gapSchemaId) {
        return false;
      }
      this.$emit('changed', {
        property: 'gapSchemaId',
        value: gapSchemaId
      });
    },
    setDefaultGapSchemaForQustionnaire: function setDefaultGapSchemaForQustionnaire() {
      var _this$informationAsse2;
      this.showUpdateGapSchemaAlert = false;
      var questionnaireId = (_this$informationAsse2 = this.informationAsset.questionnaires.at(-1)) === null || _this$informationAsse2 === void 0 ? void 0 : _this$informationAsse2.questionnaireId;
      var questionnaire = this.questionnaires.find(function (item) {
        return +item.id === +questionnaireId;
      });
      if (questionnaire && questionnaire.defaultGapSchemaId) {
        this.changeGapSchema(questionnaire.defaultGapSchemaId);
      }
    },
    removeQuestionnaire: function removeQuestionnaire(id) {
      this.$emit('changed', {
        property: 'questionnaires',
        value: this.informationAsset.questionnaires.filter(function (q) {
          return q.questionnaireId != id;
        })
      });
    },
    iaHasChildren: function iaHasChildren() {
      var _this$informationAsse3;
      return !!((_this$informationAsse3 = this.informationAsset) !== null && _this$informationAsse3 !== void 0 && (_this$informationAsse3 = _this$informationAsse3.children) !== null && _this$informationAsse3 !== void 0 && _this$informationAsse3.length);
    }
  }
};