import { getTranslate } from './translate';
import VerticalMenu from '@/components/Molecules/VerticalMenu/VerticalMenu';
export default {
  name: 'ContractsOverviewWrapper',
  description: 'Wrapper with vertical menu for contracts overview page',
  token: '<contracts-overview-wrapper/>',
  components: {
    VerticalMenu: VerticalMenu
  },
  data: function data() {
    return {
      translate: getTranslate['ContractsOverviewWrapper']()
    };
  },
  computed: {
    menuItems: function menuItems() {
      return [{
        'path': '/contracts/overview/my',
        'title': this.translate.myContracts
      }, {
        'path': '/contracts/overview/mycompanies',
        'title': this.translate.myCompanies
      }, {
        'path': '/contracts/overview/all',
        'title': this.translate.overview
      }, {
        'path': '/contracts/overview/expired',
        'title': this.translate.expiredContracts
      }, {
        'path': '/contracts/overview/dpa',
        'title': this.translate.dataProcessorAgreements
      }, {
        'path': '/contracts/overview/documents',
        'title': this.translate.searchDocuments
      }, {
        'path': '/contracts/overview/content',
        'title': this.translate.searchContent
      }];
    }
  }
};