import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3e600c15"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "filter-tags-wrap"
};
var _hoisted_2 = {
  key: 0,
  class: "filter-tags-content"
};
var _hoisted_3 = {
  class: "filter-tags-label"
};
var _hoisted_4 = {
  class: "filter-tags"
};
var _hoisted_5 = {
  class: "filter-name"
};
var _hoisted_6 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.items.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString($options.filterTagsLabel) + ": ", 1 /* TEXT */), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items, function (item, key) {
    return _openBlock(), _createElementBlock("div", {
      key: key,
      class: "filter-tag inline-flex items-center bg-blue-750"
    }, [_createElementVNode("span", _hoisted_5, _toDisplayString(item.name), 1 /* TEXT */), _createElementVNode("span", {
      onClick: function onClick($event) {
        return $options.removeItem(item);
      }
    }, [_createVNode(_component_feather_icon, {
      class: "text-white cursor-pointer",
      icon: "x"
    })], 8 /* PROPS */, _hoisted_6)]);
  }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true)]);
}