import { uploadNewQuestionnaire, getQuestionTypes } from '@/api/rismaadmin/questionnaire';
import { getAvailableQuestionnaireTypes } from '@/utils/questionnaires';
import { ForbidenToUseIAForTypes, IAQuestionTypes } from '@/constants/compliance/ProjectTypes';
import { getTranslate } from './translate';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import StaticTable from '@/components/Molecules/StaticTable';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Notification from '@/components/Molecules/Notification';
export default {
  name: 'Uploadquestionnaire',
  components: {
    MainWell: MainWell,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    StaticTable: StaticTable,
    RismaButton: RismaButton,
    InputField: InputField,
    SingleSelect: SingleSelect,
    RismaFileUpload: RismaFileUpload,
    RismaTitle: RismaTitle,
    Notification: Notification
  },
  data: function data() {
    return {
      origins: [{
        value: 'Customer',
        label: 'Customer'
      }, {
        value: 'Plesner',
        label: 'Plesner'
      }],
      IAQuestionTypesIds: Object.values(IAQuestionTypes),
      questionTypes: [],
      questionnaireTypes: [],
      questionnaireTypeId: null,
      origin: null,
      parsedQuestions: null,
      questionnaireTitle: '',
      files: null,
      csvVerified: false,
      loading: false,
      notificationType: 'info',
      notificationMessage: [],
      questionnaireTabLabel: 'Questionnaire',
      columnsMaxWidth: {
        question: '300px'
      },
      translate: getTranslate['UploadQuestionnaire']()
    };
  },
  computed: {
    columns: function columns() {
      return [this.translate.ordering, this.translate.questionNumber, this.translate.headline, this.translate.question, this.translate.questionDependency, this.translate.questionType, this.translate.flags, this.translate.status];
    },
    IAQuestions: function IAQuestions() {
      var _this = this;
      if (!this.parsedQuestions.length) return [];
      var result = [];
      this.parsedQuestions.forEach(function (question) {
        if (result.includes(question.questionType)) return;
        if (_this.IAQuestionTypesIds.includes(question.questionTypeId)) {
          result.push(question.questionType);
        }
      });
      return result;
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    getAvailableQuestionnaireTypes().then(function (list) {
      _this2.questionnaireTypes = list.map(function (qt) {
        return {
          value: qt.id,
          label: qt.type
        };
      });
    });
    getQuestionTypes().then(function (response) {
      _this2.questionTypes = response.data.list;
    });
  },
  methods: {
    saveQuestionnaire: function saveQuestionnaire() {
      var _this3 = this;
      if (this.handleValidation() && this.files) {
        var createQuestionnaireObject = this.files[0];
        createQuestionnaireObject.questionnaireTitle = this.questionnaireTitle;
        createQuestionnaireObject.questionnaireTabLabel = this.questionnaireTabLabel;
        createQuestionnaireObject.origin = this.origin;
        createQuestionnaireObject.questionnaireType = this.questionnaireTypeId;
        createQuestionnaireObject.createQuestionnaire = true;
        this.loading = true;
        uploadNewQuestionnaire(createQuestionnaireObject).then(function () {
          _this3.loading = false;
          _this3.handleShowNotification('success', _this3.translate.questionnaireUploaded);
        }).catch(function (error) {
          var _error$response;
          _this3.loading = false;
          _this3.handleShowNotification('error', (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.error);
        });
      }
    },
    handleValidation: function handleValidation() {
      var error = [];
      if (!this.questionnaireTitle) {
        error.push(this.translate.pleaseStateTheQuestionnaireTitle);
      }
      if (!this.questionnaireTabLabel) {
        error.push(this.translate.pleaseSelectQuestionnaireTabLabel);
      }
      if (!this.questionnaireTypeId) {
        error.push(this.translate.pleaseSelectQuestionnaireType);
      }
      if (!this.origin) {
        error.push(this.translate.pleaseSelectQuestionnaireOrigin);
      }
      if (ForbidenToUseIAForTypes.includes(this.questionnaireTypeId) && this.IAQuestions.length) {
        error.push(this.translate.youCantUseNextQuestionTypes.replace('%s', this.IAQuestions.join(', ')));
      }
      this.handleShowNotification('error', error);
      return !error.length;
    },
    uploadFiles: function uploadFiles(files) {
      var _this4 = this;
      var fileObject = files[files.length - 1];
      if (!fileObject) {
        return;
      }
      this.files = files;
      this.loading = true;
      return uploadNewQuestionnaire(fileObject).then(function (response) {
        var _response$title;
        _this4.loading = false;
        if (!response.list.length) {
          _this4.csvVerified = false;
          _this4.parsedQuestions = null;
          return _this4.handleShowNotification('error', _this4.translate.fileIsEmptyPleaseUploadSomethingMoreSubsta);
        }
        // Header values
        if ((_response$title = response.title) !== null && _response$title !== void 0 && _response$title.trim()) {
          _this4.questionnaireTitle = response.title;
        }
        var questionnaireType = _this4.questionnaireTypes.find(function (qtype) {
          return qtype.label == response.type;
        });
        if (questionnaireType) {
          _this4.questionnaireTypeId = questionnaireType.value;
        }
        if (response.origin && _this4.origins.some(function (item) {
          return item.value === response.origin;
        })) {
          _this4.origin = response.origin;
        }
        // Questions
        var parsedQuestions = response.list;
        var formattedParsedQuestions = [];
        parsedQuestions.forEach(function (question) {
          var questionTypeObject = _this4.questionTypes.find(function (questionType) {
            return questionType.id == question.questiontype;
          });
          formattedParsedQuestions.push({
            orderBy: question.orderBy,
            questionNumber: question.questionnumber,
            headline: question.headline,
            question: question.question,
            dependency: question.dependency,
            questionType: questionTypeObject.type,
            questionTypeId: questionTypeObject.id,
            flags: question.flags,
            status: question.status
          });
        });
        _this4.parsedQuestions = formattedParsedQuestions;
        _this4.csvVerified = !response.hasErrors;
        if (!_this4.csvVerified) {
          _this4.handleShowNotification('error', _this4.translate.theQuestionnaireContainsErrors);
        } else {
          _this4.handleDismissNotification();
        }
      }).catch(function (error) {
        _this4.loading = false;
        _this4.parsedQuestions = null;
        _this4.csvVerified = false;
        if (error.status === 500) {
          return _this4.handleShowNotification('error', "".concat(_this4.translate.fileContainsErrorsPleaseCheckTheContentAnd));
        }
        if (error.response === undefined) {
          _this4.handleShowNotification('error', error);
        } else {
          var errorMessage = typeof error.response == 'string' ? error.response : error.response.error + ' - ' + error.response.message;
          _this4.handleShowNotification('error', 'Error during upload: ' + errorMessage);
        }
      });
    },
    handleShowNotification: function handleShowNotification(type, message) {
      var _this5 = this;
      this.notificationType = type;
      if (message.constructor !== Array) {
        this.notificationMessage = [message];
      } else {
        this.notificationMessage = message;
      }
      this.$scrollTo(document.body, 100);
      if (type !== 'error') {
        setTimeout(function () {
          return _this5.notificationMessage = [];
        }, 10000);
      }
    },
    handleDismissNotification: function handleDismissNotification() {
      this.notificationMessage = '';
    }
  }
};