import { getTranslate } from './translate';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { clickOutside } from '@/utils/directives/clickOutside';
import { SortDirection } from '@/constants/SortDirection';
export default {
  name: 'DataTableColumnFilter',
  introduction: 'Modal for column filtering',
  description: 'Column filtering component for DataTable',
  token: '<data-table-column-filter></data-table-column-filter>',
  directives: {
    clickOutside: clickOutside
  },
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false
    },
    filterData: {
      required: false,
      type: Object,
      default: function _default() {}
    },
    filterOptions: {
      required: false,
      type: Object,
      default: function _default() {}
    }
  },
  emits: ['sort', 'clickOutside', 'filterOptionSearch', 'filterOptionSelect', 'deselectAll'],
  data: function data() {
    return {
      sortDir: SortDirection.ASC,
      columnIndex: null,
      positionTop: null,
      positionLeft: null,
      positionRight: null,
      filterText: '',
      filteringDisabled: false,
      listMaxHeight: '',
      translate: getTranslate['DataTableColumnFilter']()
    };
  },
  computed: {
    sortLabel: function sortLabel() {
      var result;
      if (this.sortDir === SortDirection.ASC) {
        result = 'Sort ascending';
      } else {
        result = 'Sort descending';
      }
      return this.$trans(result);
    },
    iconSort: function iconSort() {
      return this.sortDir === SortDirection.DESC ? 'chevron-down' : 'chevron-up';
    },
    filteringDisabledLabel: function filteringDisabledLabel() {
      return this.translate.filteringNotPossibleForThisColumn;
    },
    showNoResultsLabel: function showNoResultsLabel() {
      return !this.filteringDisabled && !this.filterOptions.some(function (option) {
        return !option.isHidden;
      });
    }
  },
  watch: {
    filterData: function filterData(newData) {
      this.updateData(newData);
      this.filterText = '';
    }
  },
  mounted: function mounted() {
    this.updateData(this.filterData);
  },
  methods: {
    sortColumn: function sortColumn() {
      this.toggleSort();
      this.$emit('sort', {
        index: this.columnIndex,
        dir: this.sortDir
      });
    },
    toggleSort: function toggleSort() {
      if (this.sortDir === SortDirection.ASC) {
        this.sortDir = SortDirection.DESC;
      } else {
        this.sortDir = SortDirection.ASC;
      }
    },
    updateData: function updateData(data) {
      this.columnIndex = data.index;
      if (data.rect) {
        // new header hasn`t got topwrapper
        var offsetH = document.querySelector('.topwrapper') ? document.querySelector('.topwrapper').offsetHeight : document.querySelector('nav').offsetHeight;
        this.updatePosition(data.rect, offsetH);
      }
      this.filteringDisabled = data.disabledFilterBy;
    },
    setHeight: function setHeight(height) {
      var itemHeight = 200;
      var windowHeight = window.innerHeight || Math.max(document.documentElement.clientHeight, document.body.clientHeight);
      this.listMaxHeight = windowHeight - height - itemHeight + 'px';
    },
    updatePosition: function updatePosition(rect, topHeader) {
      var topMargin = 20;
      var filterWidth = this.$el.clientWidth || 250;
      var windowWidth = window.innerWidth;
      if (!rect) {
        return;
      }
      var isFilterOutOfTheWindow = rect.left + filterWidth > windowWidth;
      this.positionLeft = isFilterOutOfTheWindow ? 'auto' : rect.left + 'px';
      this.positionTop = rect.top - topMargin;
      this.positionRight = isFilterOutOfTheWindow ? '0' : 'auto';
      this.setHeight(rect.top);
      if (topHeader) {
        this.positionTop += topHeader;
      }
      this.positionTop += 'px';
    },
    handleClickOutside: function handleClickOutside(event) {
      if (this.isVisible && !event.target.hasAttribute('data-column-index')) {
        this.$emit('clickOutside');
      }
    }
  }
};