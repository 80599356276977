import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-f7cd66ec"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["href"];
var _hoisted_2 = {
  class: "mx-1 border-solid border w-8 h-8 rounded-full flex items-center justify-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("a", {
    href: $options.url,
    class: _normalizeClass(['save-image', {
      'disabled': $props.isDataflowEmpty
    }]),
    download: "dataflow.png"
  }, [_createVNode(_component_risma_title, {
    title: $data.translate.exportDataFlow
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_feather_icon, {
    icon: "download"
  })])], 10 /* CLASS, PROPS */, _hoisted_1);
}