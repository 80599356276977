function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getTranslate } from './translate';
import VueMultiselect from 'vue-multiselect';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import { gapFlags } from '@/constants/compliance/GapTypes';
import { getAttributes } from '@/api/admin/gapschema';
export default {
  name: 'GapSchemaArticleModal',
  introduction: 'Component which createor update a article',
  description: 'Component with form of article creation in modal box.',
  token: '<gap-schema-article-modal />',
  components: {
    RismaSelect: RismaSelect,
    RismaLabel: RismaLabel,
    Modal: Modal,
    InputField: InputField,
    VueMultiselect: VueMultiselect,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    TextBox: TextBox
  },
  props: {
    article: {
      type: Object,
      default: null
    },
    domains: {
      type: [Object, Array],
      required: true
    },
    gapSchemaId: {
      type: [String, Number],
      required: true
    }
  },
  emits: ['onSubmit', 'toggleModal', 'createOption', 'close', 'open'],
  data: function data() {
    return {
      showModal: false,
      errors: {},
      attributesArr: [],
      domain: '',
      formData: this.getFormData(),
      selectedLabelsPerAttributes: new Map(),
      translate: getTranslate['GapSchemaArticlesModal'](),
      preselectedLabelOptions: {},
      labelOptions: {}
    };
  },
  computed: {
    flagOptions: function flagOptions() {
      var flags = gapFlags(this.$trans);
      return Object.keys(flags).map(function (key) {
        return flags[key];
      });
    },
    selectedFlags: function selectedFlags() {
      var _this = this;
      return this.flagOptions.filter(function (option) {
        return option.value & _this.formData.flags;
      });
    }
  },
  watch: {
    domains: {
      deep: true,
      handler: function handler() {
        this.formData.domain = this.article.domain;
      }
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    getAttributes(this.gapSchemaId).then(function (_ref) {
      var attributes = _ref.attributes;
      _this2.attributesArr = attributes;
      _this2.attributesArr.forEach(function (attribute) {
        _this2.selectedLabelsPerAttributes.set(attribute.id, []);
        _this2.preselectedLabelOptions[attribute.id] = _this2.createPreselectedOptions(attribute);
        _this2.labelOptions[attribute.id] = _this2.createOptionsFromLabels(attribute === null || attribute === void 0 ? void 0 : attribute.labels);
      });
    });
  },
  methods: {
    getFormData: function getFormData() {
      if (this.article.id) {
        return {
          domain: this.article.domain,
          article: this.article.article,
          articleTitle: this.article.articleTitle,
          requirement: this.article.requirement,
          flags: this.article.flags
        };
      }
      return {
        domain: '',
        article: '',
        articleTitle: '',
        requirement: '',
        flags: 0
      };
    },
    toggleModal: function toggleModal() {
      this.$emit('toggleModal');
    },
    handleOnSubmit: function handleOnSubmit() {
      if (this.handleValidation()) {
        var attributeIds = [];
        var _iterator = _createForOfIteratorHelper(this.selectedLabelsPerAttributes.values()),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var ids = _step.value;
            attributeIds = [].concat(_toConsumableArray(attributeIds), _toConsumableArray(ids));
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        this.$emit('onSubmit', _objectSpread(_objectSpread({}, this.formData), {}, {
          attributeIds: attributeIds
        }));
      }
    },
    handleValidation: function handleValidation() {
      this.errors = _objectSpread({}, this.validateGeneralFields());
      return !Object.keys(this.errors).length;
    },
    validateGeneralFields: function validateGeneralFields() {
      var _this3 = this;
      var errors = {};
      var fieldsTocheck = ['article', 'articleTitle', 'requirement'];
      fieldsTocheck.forEach(function (item) {
        if (_this3.formData[item].length < 1) {
          errors[item] = _this3.translate.pleaseFillOutThisField;
        }
      });
      if (!this.formData.domain.id) {
        errors.domain = this.translate.pleaseFillOutThisField;
      }
      return errors;
    },
    addNewOption: function addNewOption(label) {
      this.$emit('createOption', label);
    },
    onSelectDomain: function onSelectDomain(domain) {
      this.formData.domain = domain || '';
      this.$forceUpdate();
    },
    onSelectFlags: function onSelectFlags(flags) {
      if (!Array.isArray(flags)) {
        flags = [flags];
      }
      var newFlagValue = flags.reduce(function (acc, flag) {
        return acc + flag.value;
      }, 0);
      this.formData.flags = newFlagValue;
    },
    createOptionsFromLabels: function createOptionsFromLabels() {
      var labels = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return labels.map(function (item) {
        return {
          label: item.label,
          value: item.id
        };
      });
    },
    createPreselectedOptions: function createPreselectedOptions(attribute) {
      var _this$article;
      var options = [];
      var labelIds = [];
      var preselectedAttributes = ((_this$article = this.article) === null || _this$article === void 0 ? void 0 : _this$article.attributes) || {};
      var preselectedLabels = preselectedAttributes[attribute.category];
      if (preselectedLabels && preselectedLabels.length) {
        preselectedLabels.forEach(function (preselectedLabel) {
          var selectedLabel = attribute.labels.find(function (attributeLabelItem) {
            return preselectedLabel === attributeLabelItem.label;
          });
          if (!selectedLabel) return;
          labelIds.push(selectedLabel.id);
          options.push({
            value: selectedLabel.id,
            label: selectedLabel.label
          });
        });
      }
      this.selectedLabelsPerAttributes.set(attribute.id, labelIds);
      return options;
    },
    onLabelsUpdate: function onLabelsUpdate(attributeId, labels) {
      this.selectedLabelsPerAttributes.set(attributeId, labels.map(function (label) {
        return label.value;
      }));
    }
  }
};