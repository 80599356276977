import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tree_list = _resolveComponent("tree-list", true);
  return _openBlock(), _createElementBlock("div", null, [_renderSlot(_ctx.$slots, "title", {
    item: $props.item,
    parent: $props.parent
  }), $props.item.children && $props.item.children.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item.children, function (child, i) {
    return _openBlock(), _createBlock(_component_tree_list, {
      key: child.id || i,
      style: _normalizeStyle({
        marginLeft: "".concat($props.margin, "px"),
        paddingLeft: "".concat($props.padding, "px")
      }),
      item: child,
      parent: $options.itemWithoutChildren,
      class: "p-2 border-l-2"
    }, {
      title: _withCtx(function (scope) {
        return [_renderSlot(_ctx.$slots, "title", _normalizeProps(_guardReactiveProps(scope)))];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["style", "item", "parent"]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)]);
}