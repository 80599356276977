import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "text-red-50 font-semibold"
};
var _hoisted_2 = {
  class: "pt-3 border-t border-gray-450"
};
var _hoisted_3 = {
  class: "py-2 font-bold text-gray-650"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_selector = _resolveComponent("selector");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.otherRelatedContracts,
    "button-ok-text": $data.translate.apply,
    "button-dismiss-text": $data.translate.cancel,
    "overflow-visible": true,
    onAccept: $options.onAccept,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('closeModal');
    })
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_selector, {
        items: $options.selectableItems,
        title: $data.translate.chooseContract,
        "search-placeholder": $data.translate.clickToSelectContracts,
        "search-fields": $data.searchFields,
        "is-valid": $data.isContractValid,
        onSelected: $options.selected
      }, {
        displayedName: _withCtx(function (slotProps) {
          return [_createElementVNode("span", {
            class: _normalizeClass(slotProps.item.archived ? 'line-through' : '')
          }, _toDisplayString(slotProps.item.title), 3 /* TEXT, CLASS */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["items", "title", "search-placeholder", "search-fields", "is-valid", "onSelected"]), !$data.isContractValid ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($data.translate.pleaseSelectAContract), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString($data.translate.addedContracts), 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selectedContracts, function (item) {
        return _openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "flex items-center px-2 py-1 rounded-md cursor-default hover:bg-gray-350"
        }, [_createElementVNode("p", {
          class: _normalizeClass(["mr-1", item.archived ? 'line-through' : ''])
        }, _toDisplayString(item.title), 3 /* TEXT, CLASS */), _createVNode(_component_feather_icon, {
          icon: "x",
          width: "12",
          height: "12",
          class: _normalizeClass('stroke-gray stroke-3 hover:stroke-red-50 cursor-pointer'),
          onClick: function onClick($event) {
            return $options.deselect(item);
          }
        }, null, 8 /* PROPS */, ["onClick"])]);
      }), 128 /* KEYED_FRAGMENT */))])];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}