import Breadcrumbs from '@/components/Atoms/Breadcrumbs';
export default {
  name: '',
  components: {
    Breadcrumbs: Breadcrumbs
  },
  data: function data() {
    return {
      breadcrumbs: [{
        'title': 'Root',
        'url': '/compliance/1/edit/1/description'
      }, {
        'title': 'C1',
        'url': '/compliance/1/edit/2/description'
      }, {
        'title': 'C2'
      }]
    };
  }
};