import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 2,
  class: "my-10 text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.usersChangelog,
        class: "mb-3"
      }, null, 8 /* PROPS */, ["title"]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createBlock(_component_risma_datatable, {
        key: 1,
        dataset: _ctx.datasetSlice,
        "dataset-total": $data.dataset,
        "dataset-total-sliced": _ctx.datasetSliceTotal,
        "wrapper-height-sticky": 420,
        order: $data.defaultSorting,
        "state-local": "users-changelog",
        onPageChange: _ctx.onPageChange,
        onOnStateChanged: _ctx.onStateChanged
      }, {
        user: _withCtx(function (_ref) {
          var item = _ref.item;
          return [_createElementVNode("span", {
            class: _normalizeClass({
              'line-through': !item.field.active
            })
          }, _toDisplayString(item.field.title), 3 /* TEXT, CLASS */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset", "dataset-total", "dataset-total-sliced", "order", "onPageChange", "onOnStateChanged"])), $options.showNothingToShowMessage ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
        title: $data.translate.nothingToShow
      }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })]);
}