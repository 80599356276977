import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-d97c7fbe"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = {
  class: "w-1/2 mr-3"
};
var _hoisted_3 = {
  key: 0,
  class: "text-red-50"
};
var _hoisted_4 = {
  class: "flex-grow"
};
var _hoisted_5 = {
  class: "flex items-center"
};
var _hoisted_6 = {
  key: 0,
  class: "text-red-50"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", null, [$data.showDeleteAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    body: $data.alertTitle,
    header: $data.translate.delete,
    "button-ok-text": $data.translate.delete,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.onAlertSubmit,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showDeleteAlert = false;
    })
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.attributeCategory,
    type: "small",
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
    type: "text",
    "pre-text": $props.category,
    class: _normalizeClass({
      'is-not-valid': $props.categoryError
    }),
    maxlength: "255",
    onUpdated: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('updateCategory', $event);
    })
  }, null, 8 /* PROPS */, ["pre-text", "class"]), $props.categoryError ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.categoryError), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.attributeValues,
    type: "small",
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.localLabels, function (attribute, idx) {
    return _openBlock(), _createElementBlock("div", {
      key: idx,
      class: "mb-2"
    }, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_input_field, {
      type: "text",
      class: _normalizeClass(["flex-grow", {
        'is-not-valid': $props.labelErrors && $props.labelErrors[idx]
      }]),
      "pre-text": attribute.label,
      maxlength: "255",
      onUpdated: function onUpdated($event) {
        return $options.onLabelUpdate(idx, $event);
      }
    }, null, 8 /* PROPS */, ["class", "pre-text", "onUpdated"]), _createVNode(_component_feather_icon, {
      icon: "x",
      class: "stroke-3 text-red-50 cursor-pointer ml-2",
      onClick: function onClick($event) {
        return $options.onLabelDelete(idx);
      }
    }, null, 8 /* PROPS */, ["onClick"])]), $props.labelErrors && $props.labelErrors[idx] ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($props.labelErrors[idx]), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", {
    class: "flex items-center cursor-pointer",
    onClick: _cache[2] || (_cache[2] = function () {
      return $options.onLabelCreate && $options.onLabelCreate.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-3 mr-2"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.addOption), 1 /* TEXT */)])])])]);
}