function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { clickOutside } from '@/utils/directives/clickOutside';
import { UserLevels } from '@/constants/UserLevels';
import { Colors } from '@/Colors';
import { ObjectTypes, ObjectTypeToUserField } from '@/constants/ObjectTypes';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'RismaModulesRightsPicker',
  introduction: 'module that helps with access levels for users',
  token: '<risma-modules-rights-picker :user="user" :field="field" @updateModule="$emit(`updateModule`, $event)"/>',
  directives: {
    clickOutside: clickOutside
  },
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: function _default() {},
      note: 'User object for the user to change permissions on'
    },
    field: {
      type: String,
      required: true,
      default: '',
      note: 'The name of the field that has that value (to emit it and so on)'
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['updateModule', 'update:opened'],
  data: function data() {
    var _fieldToLocked;
    return {
      isModalOpened: false,
      userLevels: UserLevels,
      fieldToLocked: (_fieldToLocked = {}, _defineProperty(_fieldToLocked, ObjectTypeToUserField[ObjectTypes.EXECUTION_INITIATIVE], 'level_e_locked'), _defineProperty(_fieldToLocked, ObjectTypeToUserField[ObjectTypes.RISK_RISK], 'level_r_locked'), _defineProperty(_fieldToLocked, ObjectTypeToUserField[ObjectTypes.CONTROLS_CONTROL], 'level_c_locked'), _defineProperty(_fieldToLocked, ObjectTypeToUserField[ObjectTypes.INCIDENTS_INCIDENT], 'level_incidents_locked'), _defineProperty(_fieldToLocked, ObjectTypeToUserField[ObjectTypes.CONTRACT], 'level_contracts_locked'), _fieldToLocked),
      ObjectTypes: ObjectTypes,
      ObjectTypeToUserField: ObjectTypeToUserField,
      colors: Colors,
      translate: getTranslate['RismaModulesRightsPicker']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    currentLevelIcon: function currentLevelIcon() {
      return 'icon-risma-' + this.levelToIcon(this.user[this.field]);
    },
    allowAdmin: function allowAdmin() {
      return this.currentUser.level_admin === 1 || this.currentUser.is_admin === true;
    }
  }),
  methods: {
    closeModal: function closeModal() {
      if (this.isModalOpened) this.$emit('update:opened');
      this.isModalOpened = false;
    },
    toggleModal: function toggleModal(event) {
      if (this.user.is_admin || this.readonly) return;
      if (!event.target.classList.contains('locked-item')) {
        this.isModalOpened = !this.isModalOpened;
        this.$emit('update:opened');
      }
    },
    levelToIcon: function levelToIcon(accessLevel) {
      var icons = ['noAccess', 'user', 'privUser', 'superUser', 'admin'];
      if (this.user.is_admin === true) {
        return 'admin';
      }
      return icons[accessLevel];
    },
    setLevel: function setLevel(accessLevel, field) {
      if (!this.allowAdmin) {
        return;
      }
      this.$emit('updateModule', {
        id: this.user.id,
        field: field,
        value: accessLevel
      });
    }
  }
};