import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_dpm_audit_questionnaire = _resolveComponent("dpm-audit-questionnaire");
  return _openBlock(), _createElementBlock("div", null, [$data.questionnaire !== null ? (_openBlock(), _createBlock(_component_dpm_audit_questionnaire, {
    key: 0,
    questionnaire: $data.questionnaire,
    activity: {
      title: 'title'
    },
    processor: $data.testProcessor,
    "dpm-request": $data.testRequest,
    comment: "some text in comments need to be rendered to someone. Just the text. Answer my questions please",
    onHandleSubmit: $options.something
  }, null, 8 /* PROPS */, ["questionnaire", "processor", "dpm-request", "onHandleSubmit"])) : _createCommentVNode("v-if", true)]);
}