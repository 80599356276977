import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-73760272"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "paginated-table flex flex-col justify-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_static_table = _resolveComponent("static-table");
  var _component_pagination = _resolveComponent("pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_static_table, {
    dataset: $data.datasetSlice,
    columns: $props.columns,
    "visibility-storage-name": $props.visibilityStorageName,
    "visibility-default-columns": $props.visibilityDefaultColumns,
    "visibility-hidden-options": $props.visibilityHiddenOptions,
    "visibility-switch": $props.visibilitySwitch,
    "visibility-switch-label": $props.visibilitySwitchLabel,
    "hover-titles": $props.hoverTitles,
    "columns-max-width": $props.columnsMaxWidth,
    "columns-min-width": $props.columnsMinWidth,
    "show-child-default-value": $props.showChildDefaultValue
  }, _createSlots({
    _: 2 /* DYNAMIC */
  }, [_renderList(_ctx.$slots, function (_, name) {
    return {
      name: name,
      fn: _withCtx(function (slotData) {
        return [_renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(slotData)), undefined, true)];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset", "columns", "visibility-storage-name", "visibility-default-columns", "visibility-hidden-options", "visibility-switch", "visibility-switch-label", "hover-titles", "columns-max-width", "columns-min-width", "show-child-default-value"]), _createVNode(_component_pagination, {
    ref: "pagination",
    total: $props.total || $props.dataset.length,
    "per-page": $props.pageSize,
    "show-prev-next-buttons": true,
    onChange: $options.handleShownList
  }, null, 8 /* PROPS */, ["total", "per-page", "onChange"])]);
}