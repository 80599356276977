import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "border border-gray-450 rounded p-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_save_report = _resolveComponent("save-report");
  var _component_hierarchical_list = _resolveComponent("hierarchical-list");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_save_report, {
    type: $data.reportType,
    "state-local": $data.stateLocal,
    "compliance-projects-id": $props.projectId,
    onLoad: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.applySettingsFromLoadedReport($event);
    })
  }, null, 8 /* PROPS */, ["type", "state-local", "compliance-projects-id"]), $data.readyForRender ? (_openBlock(), _createBlock(_component_risma_datatable, {
    key: 0,
    ref: "reportsDataTable",
    dataset: _ctx.datasetSlice,
    order: $data.order,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "filter-by-column": true,
    "page-length": _ctx.pageLength,
    "decouple-filter-fields": $data.decoupleFilterFields,
    "do-resizing": true,
    "columns-by-default": $options.columns,
    "wrapper-height-sticky": 400,
    "state-local": $data.stateLocal,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged,
    onDismissModal: $options.getData
  }, {
    process: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_createVNode(_component_hierarchical_list, {
        "has-preview-mode": true,
        node: item.field.node,
        "show-children": true,
        "toggle-children": false,
        onDismissModal: $options.getData
      }, null, 8 /* PROPS */, ["node", "onDismissModal"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset", "order", "dataset-total", "dataset-total-sliced", "page-length", "decouple-filter-fields", "columns-by-default", "state-local", "onPageChange", "onOnStateChanged", "onDismissModal"])) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  }))]);
}