import RiskRow from '@/components/Molecules/RiskRow';
export default {
  name: '',
  components: {
    RiskRow: RiskRow
  },
  data: function data() {
    return {
      rismaRisks: [{
        id: 1,
        module: 'test',
        moduleId: 1,
        riskIndex: 0,
        likelihood: 2,
        impact: 5,
        rpi: 10
      }, {
        id: 2,
        module: 'test',
        moduleId: 1,
        riskIndex: 1,
        likelihood: 3,
        impact: 1,
        rpi: 3
      }],
      rismaTense: ['Before', 'After', '']
    };
  }
};