import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import { ControlValidationMessages } from '@/constants/ControlValidationMessages';
export default {
  name: 'ReportSavingModal',
  components: {
    InputField: InputField,
    Modal: Modal,
    Notification: Notification
  },
  emits: ['save', 'dismiss'],
  data: function data() {
    return {
      errors: {},
      saveReportTitle: '',
      saveReportDescription: '',
      saveReportTitleValidationMsg: '',
      saveReportDescriptionValidationMsg: '',
      translate: getTranslate['ReportSavingModal']()
    };
  },
  methods: {
    updateSaveReportTitle: function updateSaveReportTitle(value) {
      this.saveReportTitle = value;
      delete this.errors['title'];
    },
    updateSaveReportDescription: function updateSaveReportDescription(value) {
      this.saveReportDescription = value;
      delete this.errors['description'];
    },
    isValid: function isValid() {
      this.errors = {};
      if (!this.saveReportTitle) {
        this.errors['title'] = ControlValidationMessages.TITLE_IS_REQUIRED;
      }
      if (this.saveReportTitle.length > 40) {
        this.errors['title'] = ControlValidationMessages.SHOULD_HAVE_LIMIT_40_CHARS;
      }
      if (this.saveReportDescription.length > 100) {
        this.errors['description'] = ControlValidationMessages.SHOULD_HAVE_LIMIT_100_CHARS;
      }
      return !Object.values(this.errors).length;
    },
    doSaveReport: function doSaveReport() {
      if (!this.isValid()) return;
      this.$emit('save', this.saveReportTitle, this.saveReportDescription);
      this.saveReportTitle = '';
      this.saveReportDescription = '';
    }
  }
};