import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "flex -mb-px"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_light_tab_item = _resolveComponent("light-tab-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_light_tab_item, {
    to: "/compliance/reports/".concat(_ctx.$route.params.projectId, "/nodes")
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.nodes), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"]), _createVNode(_component_light_tab_item, {
    to: "/compliance/reports/".concat(_ctx.$route.params.projectId, "/gap")
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.gap), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.reports, function (report, index) {
    return _openBlock(), _createBlock(_component_light_tab_item, {
      key: index,
      to: "/compliance/reports/".concat(_ctx.$route.params.projectId, "/").concat(index)
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString(_ctx.$trans(report.header)), 1 /* TEXT */)];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]);
  }), 128 /* KEYED_FRAGMENT */)), $options.showDataflow ? (_openBlock(), _createBlock(_component_light_tab_item, {
    key: 0,
    to: "/compliance/reports/".concat(_ctx.$route.params.projectId, "/dataflow")
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.dataFlow), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $options.showMemorandumTab ? (_openBlock(), _createBlock(_component_light_tab_item, {
    key: 1,
    to: "/compliance/reports/".concat(_ctx.$route.params.projectId, "/outsourcing")
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.memorandum), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true)]);
}