function buildPoliciesMenu(settings, user) {
  var menuStructure = [];
  menuStructure.push({
    url: '/compliance/processtree',
    title: 'Overview',
    exact: true,
    rights: settings['feature.process_tree'] === 1 && user.policiesAccess
  });
  menuStructure.push({
    url: '/compliance/processtree/report',
    title: 'Report',
    rights: settings['feature.process_tree_report'] === 1 && user.policiesAccess
  });
  menuStructure.push({
    url: '/awareness/dashboard',
    title: 'Awareness',
    translatable: false,
    rights: settings['feature.awareness_enabled'] === 1 && user.policiesAccess
  });
  return menuStructure;
}
export default buildPoliciesMenu;