function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { getTranslate } from './translate';
import * as dateUtils from '@/utils/date';
import { getAuditFollowup } from '@/api/compliance/reports/questionnaire';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import StaticTable from '@/components/Molecules/StaticTable';
export default {
  name: 'AuditFollowup',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    StaticTable: StaticTable,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  data: function data() {
    return {
      isLoaded: false,
      audits: [],
      dataset: [],
      error: null,
      translate: getTranslate['AuditFollowup']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), {}, {
    columns: function columns() {
      return [this.translate.created, this.translate.createdBy, this.translate.recipient, this.translate.assets, this.translate.firstClick, this.translate.deadline];
    }
  }),
  mounted: function mounted() {
    var _this = this;
    getAuditFollowup().then(function (response) {
      _this.audits = response.data.list;
      _this.dataset = _this.formatAudits(_this.audits, _this.users);
      _this.isLoaded = true;
    }).catch(function (error) {
      _this.error = error;
      _this.isLoaded = true;
    });
  },
  methods: {
    createTableAuditRecord: function createTableAuditRecord(audit, users) {
      var creator = users.find(function (user) {
        return user.id === audit.createdBy;
      });
      return {
        created: dateUtils.toLocalDateTimeFull(audit.created),
        creator: creator.display_name,
        fullNameAndEmail: "".concat(audit.fullname, " (").concat(audit.email, ")"),
        questionnaireTitle: audit.title,
        firstClick: audit.firstClick ? dateUtils.toLocalDateTimeFull(audit.firstClick) : '',
        expiresDate: dateUtils.toLocalDate(audit.expires)
      };
    },
    formatAudits: function formatAudits(audits, users) {
      var _this2 = this;
      var filteredAudits = audits.filter(function (audit) {
        return audit.answered === null || audit.answered.length === 0;
      });
      return filteredAudits.map(function (audit) {
        return _this2.createTableAuditRecord(audit, users);
      });
    }
  }
};