import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "border border-gray-350 px-4 py-2"
};
var _hoisted_2 = {
  class: "flex justify-between items-baseline"
};
var _hoisted_3 = {
  class: "border border-gray-350 rounded-full mr-1 hover:border-blue-750"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $props.title
  }, null, 8 /* PROPS */, ["title"]), $props.link ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    to: $props.link
  }, {
    default: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "icon", {}, function () {
        return [_createElementVNode("div", _hoisted_3, [_createVNode(_component_feather_icon, {
          icon: "arrow-right",
          class: "stroke-2"
        })])];
      })];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true)]), _renderSlot(_ctx.$slots, "default")]);
}