function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import * as Cookies from 'tiny-cookie';
import get from 'lodash/get';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import CardCompliance from '@/components/Organisms/General/Cards/CardCompliance';
import CardControl from '@/components/Organisms/General/Cards/CardControl';
import CardExecution from '@/components/Organisms/General/Cards/CardExecution';
import CardRisk from '@/components/Organisms/General/Cards/CardRisk';
import StaticTable from '@/components/Molecules/StaticTable';
import Tree from '@/components/Atoms/Tree';
import CompactTitleDataComponent from '@/components/Atoms/CompactTitleDataComponent';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import * as Utils from '@/utils/Utils';
import { isSignoffPeriodPending } from '@/utils/controls/utils';
import { ControlViews } from '@/constants/ControlViews';
import { ControlCookies } from '@/constants/ControlCookies';
import { DateFormats } from '@/constants/DateFormats';
import { ControlVisibleProps } from '@/constants/ControlVisibleProps';
import { ControlTypeFields } from '@/constants/ControlTypeFields';
import { ControlButtonTypes } from '@/constants/ControlButtonTypes';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { prepareDateTimezone } from '@/utils/date';
import { Colors } from '@/Colors';
import { MODULES } from '@/constants/modules';
var ASSOCIATIONS = [{
  key: 'accounts',
  settingName: 'risma.account_to_name',
  default: 'Accounts'
}, {
  key: 'processes',
  settingName: 'risma.process_to_name',
  default: 'Process'
}, {
  key: 'risks',
  settingName: 'risma.risk_to_name',
  default: 'Risk'
}];
var getValidValue = function getValidValue(value, defaultValue) {
  return value ? value : defaultValue;
};
export default {
  name: 'CardsWrapper',
  introduction: 'Cards Wrapper, for cards on My page for example.',
  description: 'Cards Wrapper holder for module activities',
  token: '<cards-wrapper :module="cardsWrapperModule" :other-id="cardsWrapperOtherId"></cards-wrapper>',
  components: {
    CardCompliance: CardCompliance,
    CardControl: CardControl,
    CardExecution: CardExecution,
    CardRisk: CardRisk,
    StaticTable: StaticTable,
    Tree: Tree,
    FeatherIcon: FeatherIcon,
    CompactTitleDataComponent: CompactTitleDataComponent,
    RismaButton: RismaButton
  },
  props: {
    otherId: {
      type: Number,
      required: false,
      default: 0,
      note: 'User id of other user'
    },
    module: {
      type: Object,
      required: true,
      note: 'Module object'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma users'
    },
    organisations: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma organisations'
    },
    initialEntriesLimit: {
      type: Number,
      required: false,
      default: 0,
      note: 'Count of entries which would be shown immediately. 0 - no limit'
    }
  },
  emits: ['changeShowState', 'buttonClick'],
  data: function data() {
    return {
      ControlViews: ControlViews,
      ControlCookies: ControlCookies,
      cardCompactColumns: [],
      loopDelay: 1000,
      activities: [],
      MODULES: MODULES,
      ControlButtonTypes: ControlButtonTypes,
      translate: getTranslate['CardsWrapper']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isCardShows: function isCardShows() {
      return this.activities.length && !(this.module.name == MODULES.COMPLIANCE && this.otherId > 0);
    },
    moduleCSS: function moduleCSS() {
      // No need for .col-* classes in Controls
      if (this.module.name === MODULES.CONTROLS) {
        return '';
      } else {
        return module.name == MODULES.RISK ? 'col-12 my-page-module' : 'col-12 col-md-6 col-lg-4 col-xl-4 my-page-module';
      }
    },
    cardsCompactColumns: function cardsCompactColumns() {
      var result = [];
      if (this.module.view === ControlViews.COMPACT) {
        result = this.prepareCompactFilteredColumns();
      }
      return result;
    },
    cardsCompactColumnTitles: function cardsCompactColumnTitles() {
      var _this = this;
      var result = [];
      if (this.cardsCompactColumns.length) {
        result = this.cardsCompactColumns.map(function (item) {
          return _this.$trans(item.text);
        });
      }
      return result;
    },
    cardsCompactColumnVisibilityHiddenOptions: function cardsCompactColumnVisibilityHiddenOptions() {
      return this.module.immutableVisibilityColumns || [];
    },
    cardsCompactDefaultColumns: function cardsCompactDefaultColumns() {
      var _this2 = this;
      var result = [];
      if (this.cardsCompactColumns.length) {
        result = this.cardsCompactColumns.filter(function (item) {
          return item.default === true;
        }).map(function (item) {
          return _this2.$trans(item.text);
        });
      }
      return result;
    },
    cardsCompactDataset: function cardsCompactDataset() {
      var result = [];
      if (this.module.view === ControlViews.COMPACT) {
        result = this.prepareCompactDataset(this.module.activities);
      }
      return result;
    },
    cardsDataset: function cardsDataset() {
      if (this.module.view === ControlViews.EXPANDED) {
        return this.prepareExpandedDataset(this.activities);
      }
      return this.activities;
    }
  }),
  watch: {
    'module.activities': {
      deep: true,
      handler: function handler() {
        this.resetActivities();
      }
    }
  },
  mounted: function mounted() {
    this.resetActivities();
    this.setVisibilityFromCookie();
  },
  methods: {
    resetActivities: function resetActivities() {
      if (this.initialEntriesLimit) {
        this.activities = _toConsumableArray(this.module.activities).slice(0, this.initialEntriesLimit);
        this.loadRestEntries();
      } else {
        this.activities = _toConsumableArray(this.module.activities);
      }
    },
    loadRestEntries: function loadRestEntries() {
      var _this3 = this;
      return new Promise(function (resolve) {
        setTimeout(function () {
          _this3.activities = _toConsumableArray(_this3.module.activities);
          resolve();
        }, _this3.loopDelay);
      });
    },
    getVisibilityStorageName: function getVisibilityStorageName() {
      return ControlCookies.LIST_COMPACT_HIDDEN + this.module.label.toLowerCase().replace(/\s/g, '');
    },
    setVisibilityFromCookie: function setVisibilityFromCookie() {
      /* use conditional for casting boolean */
      this.module.hidden = Cookies.getCookie(this.getWrapperCookieName()) === 'true';
      this.emitVisibilityState();
    },
    toggleVisibility: function toggleVisibility() {
      this.module.hidden = this.module.hidden ? false : true;
      Cookies.setCookie(this.getWrapperCookieName(), this.module.hidden, {
        samesite: 'strict'
      });
      this.emitVisibilityState();
    },
    emitVisibilityState: function emitVisibilityState() {
      this.$emit('changeShowState', !this.module.hidden);
    },
    getWrapperCookieName: function getWrapperCookieName() {
      var cookieName = 'hidden_cards_wrapper_' + this.module.name;
      // More specific name (with label)
      cookieName += '_' + this.module.label.toLowerCase().replace(/\s/g, '_');
      return cookieName;
    },
    setAssociationsTitle: function setAssociationsTitle(columsOptions) {
      var _this4 = this;
      columsOptions.forEach(function (prop) {
        var association = ASSOCIATIONS.find(function (association) {
          return association.key === prop.key;
        });
        if (association) {
          prop.text = getValidValue(_this4.getSettingValue(association.settingName), association.default);
        }
      });
    },
    prepareCompactFilteredColumns: function prepareCompactFilteredColumns() {
      var result = JSON.parse(JSON.stringify(ControlVisibleProps));
      this.setAssociationsTitle(result);
      if (this.getSettingValue('risma.show_expected_evidence') !== 1) {
        var exptectedEvidence = result.find(function (item) {
          return item.key === 'expectedEvidence';
        });
        result.splice(result.indexOf(exptectedEvidence), 1);
      }
      if (this.module.isControlsCatalogue) {
        var actions = result.find(function (item) {
          return item.key === '_custom-button';
        });
        result.splice(result.indexOf(actions), 1);
      }
      if (!this.getSettingValue('risma.show_key_control')) {
        var keyControl = result.find(function (item) {
          return item.key === ControlTypeFields.KEY_CONTROL;
        });
        result.splice(result.indexOf(keyControl), 1);
      }
      if (!this.getSettingValue('risma.show_automatic_manual')) {
        var automaticManual = result.find(function (item) {
          return item.key === ControlTypeFields.KEY_AUTOMATIC_MANUAL;
        });
        result.splice(result.indexOf(automaticManual), 1);
      }
      if (!this.getSettingValue('risma.show_preventive_detective')) {
        var preventiveDetective = result.find(function (item) {
          return item.key === ControlTypeFields.KEY_PREVENTIVE_DETECTIVE;
        });
        result.splice(result.indexOf(preventiveDetective), 1);
      }
      if (!this.getSettingValue('risma.show_high_complexity_low_complexity')) {
        var complexity = result.find(function (item) {
          return item.key === ControlTypeFields.KEY_HIGH_LOW_COMPLEXITY;
        });
        result.splice(result.indexOf(complexity), 1);
      }
      return result;
    },
    prepareExpandedDataset: function prepareExpandedDataset(data) {
      var _this5 = this;
      return data.map(function (item) {
        if (item.userIds) {
          return _objectSpread(_objectSpread({}, item), {}, {
            userIdsInfo: {
              responsible: Utils.findPropsByIds(item.userIds.responsible, _this5.users, 'display_name'),
              review: Utils.findPropsByIds(item.userIds.review, _this5.users, 'display_name')
            }
          });
        }
        return item;
      });
    },
    prepareCompactDataset: function prepareCompactDataset(data) {
      var _this6 = this;
      var result = [];
      result = data.map(function (item) {
        var preparedItem = {};
        _this6.cardsCompactColumns.map(function (column) {
          preparedItem[column.key] = _this6.prepareCompactHtml(column.key, item);
        });
        return preparedItem;
      });
      return result;
    },
    prepareCompactHtml: function prepareCompactHtml(column, item) {
      // lodash.get for keys like 'userIds.responsible'
      var value = get(item, column);
      var result = value ? value : '';

      // Formatted data
      switch (column) {
        case '_custom-title':
          result = this.prepareCompactTitle(item);
          break;
        case ControlTypeFields.KEY_CONTROL:
          result = result === 1 ? this.translate.yes : this.translate.no;
          break;
        case ControlTypeFields.KEY_AUTOMATIC_MANUAL:
          result = result === 1 ? this.translate.automatic : this.translate.manual;
          break;
        case ControlTypeFields.KEY_PREVENTIVE_DETECTIVE:
          result = result === 1 ? this.translate.preventive : this.translate.detective;
          break;
        case ControlTypeFields.KEY_HIGH_LOW_COMPLEXITY:
          result = result === 1 ? this.translate.high : this.translate.low;
          break;
        case 'frequency':
          result = this.$trans(RismaControlsUtils.frequency2text(item.frequency));
          break;
        case 'userIds.responsible':
        case 'userIds.review':
        case 'userIds.escalation':
          result = Utils.findPropsByIds(result, this.users, 'display_name');
          break;
        case 'organisationIds':
          result = Utils.findPropsByIds(result, this.organisations, 'visible_name');
          break;
        case 'deadline':
        case 'reviewDeadline':
          result = prepareDateTimezone(result, item.deadlineTimezone, DateFormats.DATE_TIME_FORMAT);
          break;
        case 'rismaState':
          result = this.$trans(RismaControlsUtils.state2text(item.rismaState));
          break;
        case 'accounts':
        case 'risks':
        case 'processes':
          result = Utils.findProps(result, 'title');
          if (!result) {
            result = this.translate.none;
          }
          break;
        case 'tags':
          result = Utils.findProps(result, 'tag');
          break;
        case '_custom-button':
          result = this.prepareCompactButton(item);
          break;
      }
      return result;
    },
    prepareCompactTitle: function prepareCompactTitle(item) {
      var trafficColor = Colors.getTrafficlightByNumber(item.trafficLight).color;
      var hasTrafficLight = !item._withoutTrafficLight; // Custom prop
      var additionalCSS = item.trafficLight === 1 && hasTrafficLight ? 'red ' : '';
      additionalCSS += !item.active ? 'closed' : '';
      return {
        color: trafficColor,
        hasTrafficLight: hasTrafficLight,
        additionalCSS: additionalCSS,
        url: RismaControlsUtils.getControlLink(item),
        title: RismaControlsUtils.getControlTitle(item)
      };
    },
    prepareCompactButtonReview: function prepareCompactButtonReview(item) {
      var text = this.translate.review;
      var url = this.getSettingValue('app.module_controls2_review_enabled') !== 1 ? RismaControlsUtils.prepareReviewLink(item) : RismaControlsUtils.prepareControlsTwoReviewLink(item);
      return {
        url: url,
        title: text
      };
    },
    prepareCompactButtonComplete: function prepareCompactButtonComplete(item) {
      var isCompleteAgain = this.module.buttonType === ControlButtonTypes.COMPLETE_CONTROL_AGAIN ? 1 : 0;
      return {
        text: this.translate.completion,
        id: item.id,
        cpath: item.cpath,
        completeAgain: isCompleteAgain,
        isCustomButton: true
      };
    },
    prepareCompactButtonSignoffPending: function prepareCompactButtonSignoffPending() {
      var text = this.translate.signoffPeriodPending;
      return {
        title: text
      };
    },
    prepareCompactButton: function prepareCompactButton(item) {
      if (!this.module.buttonType) {
        return '';
      }
      var btn;
      var isReview = this.module.buttonType === ControlButtonTypes.REVIEW_CONTROL;
      if (isReview) {
        btn = this.prepareCompactButtonReview(item);
      } else {
        if (!isSignoffPeriodPending(item.signoffStart, item.frequency, item.trafficLight, this.getSettingValue)) {
          btn = this.prepareCompactButtonComplete(item);
        } else {
          btn = this.prepareCompactButtonSignoffPending();
        }
      }
      return btn;
    },
    buttonClick: function buttonClick(event) {
      if (event) {
        this.$emit('buttonClick', event);
      }
    }
  }
};