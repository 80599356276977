import { ObjectTypes } from '@/constants/ObjectTypes';
export var RaciTitleMixin = {
  methods: {
    responsibleTitle: function responsibleTitle(hasSetting) {
      if (hasSetting && this.getSettingValue('feature.raci_names_responsible')) {
        return this.getSettingValue('feature.raci_names_responsible');
      }
      return 'Responsible';
    },
    accountableTitle: function accountableTitle(hasSetting) {
      if (hasSetting && this.getSettingValue('feature.raci_names_accountable')) {
        return this.getSettingValue('feature.raci_names_accountable');
      }
      return 'Accountable';
    },
    consultedTitle: function consultedTitle(hasSetting) {
      if (hasSetting && this.getSettingValue('feature.raci_names_consulted')) {
        return this.getSettingValue('feature.raci_names_consulted');
      }
      return 'Consulted';
    },
    informedTitle: function informedTitle(hasSetting) {
      if (hasSetting && this.getSettingValue('feature.raci_names_informed')) {
        return this.getSettingValue('feature.raci_names_informed');
      }
      return 'Informed';
    },
    isRACIUsed: function isRACIUsed(objType) {
      return this.ObjectTypesWithRaci.includes(objType);
    }
  },
  data: function data() {
    return {
      ObjectTypesWithRaci: [ObjectTypes.COMPLIANCE_PROJECT, ObjectTypes.COMPLIANCE_NODE, ObjectTypes.COMPLIANCE_NODE_LIMITED, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE_LIMITED, ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE, ObjectTypes.INFORMATION_ASSET, ObjectTypes.INCIDENTS_INCIDENT]
    };
  }
};