import i18n from '@/i18n';
export var getTranslate = {
  AttachmentSection: function AttachmentSection() {
    return {
      urlIsSaved: i18n.t('URL is saved'),
      pleaseFillBothFieldsCorrectly: i18n.t('Please fill both fields correctly'),
      linkTo: i18n.t('Link to'),
      insertLinkToFileOrDestination: i18n.t('Insert link to file or destination'),
      insertLinkTitle: i18n.t('Insert link title')
    };
  }
};