import GroupsObserverMixin from '@/components/Molecules/VueNestable/GroupsObserverMixin';
export default {
  name: 'VueNestableHandle',
  mixins: [GroupsObserverMixin],
  inject: ['group', 'onDragEnd'],
  props: {
    item: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  methods: {
    dragstart: function dragstart(event) {
      var item = this.item || this.$parent.item;
      this.notifyDragStart(this.group, event, item);
    },
    touchend: function touchend(event) {
      this.onDragEnd(event);
    },
    touchmove: function touchmove(event) {
      this.notifyMouseMove(this.group, event);
    }
  }
};