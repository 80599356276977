import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "flex items-center justify-between mb-3"
};
var _hoisted_2 = {
  class: "font-bold"
};
var _hoisted_3 = {
  class: "w-64 mr-4"
};
var _hoisted_4 = {
  key: 1,
  class: "flex justify-between mb-3"
};
var _hoisted_5 = {
  class: "font-bold w-1/2"
};
var _hoisted_6 = {
  class: "w-64 mr-4"
};
var _hoisted_7 = {
  class: "flex justify-between mb-5"
};
var _hoisted_8 = {
  class: "font-bold"
};
var _hoisted_9 = {
  class: "w-64 mr-4"
};
var _hoisted_10 = {
  key: 0
};
var _hoisted_11 = {
  key: 2,
  class: "mb-4"
};
var _hoisted_12 = {
  class: "font-bold"
};
var _hoisted_13 = {
  key: 1
};
var _hoisted_14 = {
  key: 0
};
var _hoisted_15 = {
  key: 0,
  class: "ml-1"
};
var _hoisted_16 = {
  key: 3,
  class: "mb-4"
};
var _hoisted_17 = {
  key: 4,
  class: "flex mb-1"
};
var _hoisted_18 = {
  class: "font-bold mr-4"
};
var _hoisted_19 = {
  key: 5
};
var _hoisted_20 = {
  class: "flex items-center mb-3"
};
var _hoisted_21 = {
  class: "mr-2"
};
var _hoisted_22 = {
  class: "w-32 mr-2"
};
var _hoisted_23 = {
  class: "w-80"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_single_select = _resolveComponent("single-select");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_control_add_deadline_modal = _resolveComponent("control-add-deadline-modal");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  return _openBlock(), _createElementBlock("div", null, [$data.showChangeFrequencyModal ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    body: $options.changeFrequencyMessage,
    header: $data.translate.warning,
    "button-ok-text": $data.translate.change,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.changeFrequencyToOneTime,
    onDismiss: $options.toggleChangeFrequencyModal
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString($data.translate.frequency) + ":", 1 /* TEXT */), _createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
    options: $options.frequencyOptions,
    "model-value": $data.frequencySelectedOption.value,
    "un-sorted": $data.unSorted,
    placeholder: $data.translate.frequency,
    disabled: $options.isFrequencySelectDisabled,
    "onUpdate:modelValue": $options.onSelectFrequency
  }, null, 8 /* PROPS */, ["options", "model-value", "un-sorted", "placeholder", "disabled", "onUpdate:modelValue"])])]), $props.control.isMothercontrol || $options.controlHasReviewer ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString($data.translate.numberOfDaysBetweendeadlineAndReviewDeadline) + ": ", 1 /* TEXT */), _createElementVNode("div", _hoisted_6, [_createVNode(_component_single_select, {
    options: $options.optionsDaysBetweenDeadlineAndReview,
    "model-value": $props.control.reviewOffset,
    placeholder: $data.translate.choosePeriod,
    disabled: $props.disabled,
    "un-sorted": true,
    "onUpdate:modelValue": $options.onSelectReviewOffset
  }, {
    option: _withCtx(function (_ref) {
      var option = _ref.option;
      return [_createElementVNode("span", {
        class: _normalizeClass({
          'font-bold text-blue-750': option.value === null
        })
      }, _toDisplayString(option.label), 3 /* TEXT, CLASS */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["options", "model-value", "placeholder", "disabled", "onUpdate:modelValue"])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_7, [_createElementVNode("p", _hoisted_8, _toDisplayString($data.translate.trafficLightTurnsYellow) + ": ", 1 /* TEXT */), _createElementVNode("div", _hoisted_9, [$props.isDaughter ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString($data.trafficLightIntervalAsString), 1 /* TEXT */)) : (_openBlock(), _createBlock(_component_single_select, {
    key: 1,
    options: $data.trafficLightIntervalOptions,
    "model-value": $data.trafficLightIntervalValueSelected,
    placeholder: $data.translate.choosePeriod,
    disabled: $props.disabled,
    "un-sorted": true,
    "onUpdate:modelValue": $options.onSelectTrafficLight
  }, null, 8 /* PROPS */, ["options", "model-value", "placeholder", "disabled", "onUpdate:modelValue"]))])]), $options.showFrequencyPreview ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("span", _hoisted_12, _toDisplayString($data.translate.frequencyPreview), 1 /* TEXT */), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 0
  })) : _createCommentVNode("v-if", true), !$props.withoutPreview ? (_openBlock(), _createElementBlock("div", _hoisted_13, [!$data.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_14, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.deadlinesToDisplay, function (deadlineObj, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "flex items-center"
    }, [_createElementVNode("p", null, _toDisplayString(deadlineObj.deadline), 1 /* TEXT */), $options.isCustomMode ? (_openBlock(), _createElementBlock("div", _hoisted_15, [!$props.disabled ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      icon: "x",
      class: "stroke-3 text-red-50 cursor-pointer",
      onClick: function onClick($event) {
        return $options.onCustomDeadlineDelete(deadlineObj.id);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $options.isCustomMode && !$props.disabled ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createElementVNode("div", {
    class: "flex items-center cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.toggleAddDeadlineModal && $options.toggleAddDeadlineModal.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-3 mr-2"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.addCustomDeadline), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [$data.showAddDeadlineModal ? (_openBlock(), _createBlock(_component_control_add_deadline_modal, {
    key: 0,
    deadlines: $options.deadlinesToDisplay,
    control: $props.control,
    onDeadlinesChanged: $options.onDeadlinesChange,
    onDismiss: $options.toggleAddDeadlineModal
  }, null, 8 /* PROPS */, ["deadlines", "control", "onDeadlinesChanged", "onDismiss"])) : _createCommentVNode("v-if", true)]), $props.control.isMothercontrol !== 1 && !$options.isCustomMode ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createElementVNode("span", _hoisted_18, _toDisplayString($data.translate.enableDeadlineCalculation), 1 /* TEXT */), _createVNode(_component_risma_toggle, {
    modelValue: $data.calculatedDeadline,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.calculatedDeadline = $event;
    }),
    disabled: $props.disabled,
    onInput: $options.onCalculateDeadlineChange
  }, null, 8 /* PROPS */, ["modelValue", "disabled", "onInput"])])) : _createCommentVNode("v-if", true), $props.control.calculatedDeadline && $props.control.isMothercontrol !== 1 ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, [_createElementVNode("span", _hoisted_21, _toDisplayString($data.translate.deadlineIs), 1 /* TEXT */), _createElementVNode("div", _hoisted_22, [_createVNode(_component_single_select, {
    options: $data.workingDays,
    "model-value": $data.workingSelectedDay.value,
    disabled: $props.disabled,
    "un-sorted": $data.unSorted,
    "onUpdate:modelValue": $options.onSelectWorkingDay
  }, null, 8 /* PROPS */, ["options", "model-value", "disabled", "un-sorted", "onUpdate:modelValue"])]), _createElementVNode("div", null, _toDisplayString($data.translate.workingDay), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_23, [_createVNode(_component_single_select, {
    options: $data.afterOptions,
    "model-value": $data.afterSelectedOption.value,
    disabled: $props.disabled,
    "un-sorted": $data.unSorted,
    "onUpdate:modelValue": $options.onSelectAfter
  }, null, 8 /* PROPS */, ["options", "model-value", "disabled", "un-sorted", "onUpdate:modelValue"])])])) : _createCommentVNode("v-if", true)]);
}