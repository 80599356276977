var _AuditGenericData;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getSelectableQuestionnairesByQuestionnaireTypeId } from '@/api/compliance/questionnaire';
import { createNewRequest, updateRequestDeadline, resubmitRequest, getAllRequests } from '@/api/compliance/dpm';
import { createNewAudit, getAllAudits } from '@/api/compliance/audit';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { QuestionnaireTypesAudit } from '@/constants/compliance/QuestionnaireTypes';
export var AuditGenericData = (_AuditGenericData = {}, _defineProperty(_AuditGenericData, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, {
  titleSuffix: 'Audit',
  questionnaireType: QuestionnaireTypesAudit[ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER],
  getAllQuestionnairesAPI: getSelectableQuestionnairesByQuestionnaireTypeId,
  createNewRequestAPI: createNewAudit,
  extendDeadlineAPI: updateRequestDeadline,
  askForMoreInfoAPI: resubmitRequest,
  getAllRequestsAPI: getAllAudits
}), _defineProperty(_AuditGenericData, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, {
  titleSuffix: 'Data Processor Management',
  questionnaireType: QuestionnaireTypesAudit[ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR],
  getAllQuestionnairesAPI: getSelectableQuestionnairesByQuestionnaireTypeId,
  createNewRequestAPI: createNewRequest,
  extendDeadlineAPI: updateRequestDeadline,
  askForMoreInfoAPI: resubmitRequest,
  getAllRequestsAPI: getAllRequests
}), _defineProperty(_AuditGenericData, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, {
  titleSuffix: 'Audit',
  questionnaireType: QuestionnaireTypesAudit[ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM],
  getAllQuestionnairesAPI: getSelectableQuestionnairesByQuestionnaireTypeId,
  createNewRequestAPI: createNewAudit,
  extendDeadlineAPI: updateRequestDeadline,
  askForMoreInfoAPI: resubmitRequest,
  getAllRequestsAPI: getAllAudits
}), _defineProperty(_AuditGenericData, ObjectTypes.CONTRACT, {
  titleSuffix: 'Audit',
  questionnaireType: QuestionnaireTypesAudit[ObjectTypes.CONTRACT],
  getAllQuestionnairesAPI: getSelectableQuestionnairesByQuestionnaireTypeId,
  createNewRequestAPI: createNewAudit,
  extendDeadlineAPI: updateRequestDeadline,
  askForMoreInfoAPI: resubmitRequest,
  getAllRequestsAPI: getAllAudits
}), _defineProperty(_AuditGenericData, ObjectTypes.INFORMATION_ASSET, {
  titleSuffix: 'Send audit',
  questionnaireType: QuestionnaireTypesAudit[ObjectTypes.INFORMATION_ASSET],
  getAllQuestionnairesAPI: getSelectableQuestionnairesByQuestionnaireTypeId,
  createNewRequestAPI: createNewAudit,
  extendDeadlineAPI: updateRequestDeadline,
  askForMoreInfoAPI: resubmitRequest,
  getAllRequestsAPI: getAllAudits
}), _AuditGenericData);