import TinyMceBox from '@/components/Molecules/TinyMceBox';
export default {
  name: '',
  components: {
    TinyMceBox: TinyMceBox
  },
  data: function data() {
    return {
      content: "<ol style=\"list-style-type: upper-roman;\" data-mce-style=\"list-style-type: upper-roman;\">\n                <li>Helo world</li>\n                <li>Helo world Helo world 2</li>\n                <li>Helo world Helo world Helo world 3</li>\n                <li>Helo world Helo world Helo world Helo world 4</li>\n                <li>Helo world Helo world Helo world Helo world Helo world 5</li>\n                <li>Helo world</li>\n                </ol>"
    };
  }
};