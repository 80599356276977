function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getActivityType, deleteActivityType } from '@/api/risma/activityTypes';
import { getCustomFields, getOptionalFields } from '@/api/admin/admin';
import { MODULES, getModuleTitles } from '@/constants/modules';
import { IATitleMixin } from '@/mixins/IATitleMixin';
import { AdminActivityTypesMixin } from '@/components/Organisms/Admin/AdminActivityTypesMixin';
import AdminActivityTypesModal from '@/components/Organisms/Admin/AdminActivityTypesModal';
import MainWell from '@/components/Atoms/Containers/MainWell';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import Notification from '@/components/Molecules/Notification';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import Alert from '@/components/Molecules/Modal/Alert';
import { getDisabledModuleFromArrayBySetting } from '@/utils/format/getDisabledModuleFromArrayBySetting';
import { compareStrings } from '@/utils/compare';
export default {
  name: 'AdminActivityTypesWell',
  introduction: 'Well with activity types in /business/admin',
  description: 'Well with a list of activity types. Used together with AdminActivityTypesModal component.',
  token: '<admin-activity-types-well></admin-activity-types-well>',
  components: {
    AdminActivityTypesModal: AdminActivityTypesModal,
    MainWell: MainWell,
    FeatherIcon: FeatherIcon,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    Notification: Notification,
    RismaTitle: RismaTitle,
    RismaSelect: RismaSelect,
    RismaLabel: RismaLabel,
    Alert: Alert
  },
  mixins: [AdminActivityTypesMixin, IATitleMixin],
  data: function data() {
    var _customFields;
    return {
      isLoaded: false,
      fields: {
        optionalFields: [],
        customFields: (_customFields = {}, _defineProperty(_customFields, MODULES.EXECUTION, []), _defineProperty(_customFields, MODULES.CONTROLS, []), _defineProperty(_customFields, MODULES.INCIDENTS, []), _defineProperty(_customFields, MODULES.COMPLIANCE_INFORMATION_ASSETS, []), _defineProperty(_customFields, MODULES.RISK, []), _defineProperty(_customFields, MODULES.COMPLIANCE_IA_SYSTEM, []), _defineProperty(_customFields, MODULES.COMPLIANCE_IA_PROCESSOR, []), _defineProperty(_customFields, MODULES.COMPLIANCE_IA_CONTROLLER, []), _defineProperty(_customFields, MODULES.COMPLIANCE, []), _defineProperty(_customFields, MODULES.CONTRACTS, []), _customFields)
      },
      moduleTitles: getModuleTitles(),
      showModal: false,
      showConfirmationAlert: false,
      idToDelete: null,
      notificationSuccess: null,
      notificationError: null,
      selectedModules: [],
      translate: getTranslate['AdminActivityTypesWell']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, ['activityTypes'])), {}, {
    filteredIds: function filteredIds() {
      return [this.getSettingValue('feature.isms_rpo_field_id'), this.getSettingValue('feature.isms_rto_field_id')];
    },
    modulesOptions: function modulesOptions() {
      var _this = this;
      var uniqueModules = _toConsumableArray(new Set(this.activities.map(function (item) {
        return item.module;
      })));
      return uniqueModules.map(function (module) {
        return {
          label: _this.moduleTitles[module],
          id: module
        };
      }).sort(function (a, b) {
        return compareStrings(a.label, b.label);
      });
    },
    sortedActivities: function sortedActivities() {
      var _this2 = this;
      return _toConsumableArray(this.activities).sort(function (a, b) {
        return compareStrings(a.label, b.label);
      }).sort(function (a, b) {
        return compareStrings(_this2.prepareModuleName(a.module), _this2.prepareModuleName(b.module));
      });
    },
    filteredActivities: function filteredActivities() {
      if (!this.selectedModules.length) return this.sortedActivities;
      var moduleIds = this.selectedModules.map(function (item) {
        return item.id;
      });
      return this.sortedActivities.filter(function (activity) {
        return moduleIds.indexOf(activity.module) !== -1;
      });
    },
    activities: function activities() {
      var disabledModules = getDisabledModuleFromArrayBySetting(this.activityTypes.map(function (item) {
        return item.module;
      }), this.getSettingValue);
      return this.activityTypes.filter(function (item) {
        return !disabledModules.includes(item.module);
      });
    }
  }),
  mounted: function mounted() {
    this.getInitData();
  },
  methods: {
    getInitData: function getInitData() {
      var _this3 = this;
      return Promise.all([this.getAllCustomFields(), this.getAllOptionalFields()]).then(function () {
        _this3.isLoaded = true;
      });
    },
    getAllCustomFields: function getAllCustomFields() {
      var _this4 = this;
      return getCustomFields().then(function (_ref) {
        var list = _ref.list;
        _this4.sortFieldsByModule(_this4.filterFields(list));
      }).catch(function (e) {
        throw e;
      });
    },
    getAllOptionalFields: function getAllOptionalFields() {
      var _this5 = this;
      return getOptionalFields(this.getSettingValue).then(function (result) {
        var optionalFields = {};
        for (var prop in result) {
          if (Object.prototype.hasOwnProperty.call(result, prop)) {
            optionalFields[prop] = result[prop].map(function (option) {
              return Object.assign({}, option, {
                label: _this5.$trans(option.label)
              });
            });
          }
        }
        _this5.fields.optionalFields = optionalFields;
      }).catch(function (e) {
        throw e;
      });
    },
    filterFields: function filterFields(data) {
      var _this6 = this;
      return data.filter(function (item) {
        return item.module !== MODULES.RISK_PROJECT_PERIOD && !_this6.filteredIds.includes(item.id);
      });
    },
    sortFieldsByModule: function sortFieldsByModule(data) {
      var _this$fields$customFi,
        _this7 = this;
      this.fields.customFields = (_this$fields$customFi = {}, _defineProperty(_this$fields$customFi, MODULES.EXECUTION, []), _defineProperty(_this$fields$customFi, MODULES.CONTROLS, []), _defineProperty(_this$fields$customFi, MODULES.INCIDENTS, []), _defineProperty(_this$fields$customFi, MODULES.COMPLIANCE_INFORMATION_ASSETS, []), _defineProperty(_this$fields$customFi, MODULES.RISK, []), _defineProperty(_this$fields$customFi, MODULES.COMPLIANCE, []), _defineProperty(_this$fields$customFi, MODULES.CONTRACTS, []), _defineProperty(_this$fields$customFi, MODULES.COMPLIANCE_IA_SYSTEM, []), _defineProperty(_this$fields$customFi, MODULES.COMPLIANCE_IA_PROCESSOR, []), _defineProperty(_this$fields$customFi, MODULES.COMPLIANCE_IA_CONTROLLER, []), _defineProperty(_this$fields$customFi, MODULES.COMPLIANCE_PROCESS_TREE_NODE, []), _this$fields$customFi);
      data.forEach(function (item) {
        _this7.fields.customFields[item.module].push(item);
      });
    },
    createActivityType: function createActivityType(e) {
      var _this8 = this;
      e.preventDefault();
      this.getAllCustomFields().then(function () {
        if (_this8.selectedModules.length === 1) {
          _this8.$refs.modal.onCreateOpen(_this8.selectedModules[0].id);
        } else {
          _this8.$refs.modal.onCreateOpen();
        }
        _this8.$refs.modal.toggleModal();
      });
    },
    editActivityType: function editActivityType(id) {
      var _this9 = this;
      var data = {};
      Promise.all([this.getAllCustomFields(), getActivityType(id).then(function (activity) {
        return data = activity;
      })]).then(function () {
        _this9.$refs.modal.onEditOpen(data);
        _this9.$refs.modal.toggleModal();
      });
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var _this10 = this;
      this.showConfirmationAlert = false;
      deleteActivityType(this.idToDelete).then(function () {
        _this10.removeActivityTypeById(_this10.idToDelete);
        _this10.showSuccessMessage(_this10.translate.activityTypeDeleted);
      }).catch(function (data) {
        if (data) {
          if (data.response && data.response.data && data.response.data.error) {
            _this10.showErrorMessage(data.response.data.error);
          }
          throw data;
        }
      });
    },
    deleteActivityType: function deleteActivityType(id) {
      this.showConfirmationAlert = true;
      this.idToDelete = id;
    },
    removeActivityTypeById: function removeActivityTypeById(id) {
      var _this11 = this;
      this.activities.map(function (field, index) {
        if (field.id === id) {
          _this11.activities.splice(index, 1);
        }
      });
    },
    onCreated: function onCreated() {
      this.showSuccessMessage(this.translate.activityTypeCreated);
      this.getAllCustomFields();
    },
    onEdit: function onEdit() {
      this.showSuccessMessage(this.translate.activityTypeWasSuccessfullyUpdated);
      this.getAllCustomFields();
    },
    onToggle: function onToggle(e) {
      this.showModal = e;
    },
    prepareModuleName: function prepareModuleName(data) {
      switch (data) {
        case MODULES.COMPLIANCE_INFORMATION_ASSETS:
          data = "".concat(this.informationAssetsTitle(), " - ").concat(this.translate.custom);
          break;
        case MODULES.COMPLIANCE_IA_SYSTEM:
          data = "".concat(this.informationAssetsTitle(), " - ").concat(this.translate.system);
          break;
        case MODULES.COMPLIANCE_IA_PROCESSOR:
          data = "".concat(this.informationAssetsTitle(), " - ").concat(this.translate.dataProcessor);
          break;
        case MODULES.COMPLIANCE_IA_CONTROLLER:
          data = "".concat(this.informationAssetsTitle(), " - ").concat(this.translate.dataController);
          break;
        default:
          data = this.moduleTitles[data] || data;
      }
      return data;
    }
  }
};