function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Alert from '@/components/Molecules/Modal/Alert';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import StaticTable from '@/components/Molecules/StaticTable';
import GapTypesList from '@/components/Pages/Admin/GapTypesList';
import Notification from '@/components/Molecules/Notification';
import { getAllGapSchemas, updateGapSchema, copyGapSchema, deleteGapSchema } from '@/api/compliance/gapschema';
import { getAll as getAllComplianceProjects } from '@/api/compliance/projects';
import { getAvailableGapSchemasTypes as getGapSchemasTypes } from '@/utils/gapSchemas';
import { getAllQuestionnaireTemplates } from '@/api/compliance/questionnaire';
import TemplateList from '@/components/Pages/Admin/TemplateList';
import { getTranslatables } from '@/constants/datatable/Translatables';
import { check403AccessError } from '@/utils/handleAPIErrors';
export default {
  name: 'GapSchemasAdmin',
  introduction: 'Gap Schema Admin',
  description: 'This component renders the table with gaps schema list',
  token: '<gap-schemas-admin></gap-schemas-admin>',
  components: {
    TemplateList: TemplateList,
    RismaTitle: RismaTitle,
    StaticTable: StaticTable,
    SingleSelect: SingleSelect,
    FeatherIcon: FeatherIcon,
    GapTypesList: GapTypesList,
    Alert: Alert,
    Modal: Modal,
    InputField: InputField,
    Notification: Notification
  },
  data: function data() {
    return {
      gapSchemaType: this.$route.query && this.$route.query.gapSchemaType ? +this.$route.query.gapSchemaType : 0,
      allGapSchemaTypes: [],
      projects: [],
      allGapSchema: [],
      templates: [],
      eventFields: ['title', 'copy', 'delete'],
      translate: getTranslate['GapSchemasAdmin'](),
      showConfirmDeleteAlert: false,
      showTitleModal: false,
      newTitle: '',
      bufferFunction: null,
      errorNotification: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    columns: function columns() {
      var columns = [this.translate.name, this.translate.gapCount, this.translate.processCount, this.translate.activated, this.translate.copy, this.translate.delete];
      if (this.getSettingValue('feature.gap_schemas_article_editor')) {
        columns.splice(4, 0, this.translate.edit);
      }
      return columns;
    },
    formattedData: function formattedData() {
      var _this = this;
      var format = [];
      this.gapSchemas.forEach(function (gapSchema) {
        var obj = {
          title: {
            disableEvent: gapSchema.user_id === null,
            dataId: gapSchema.id,
            dataMethod: 'title',
            param2: 'title',
            param3: false,
            param4: gapSchema.id,
            param5: gapSchema.title,
            text: gapSchema.title,
            removeIcon: true
          },
          questionCount: gapSchema.gapCount,
          usage: gapSchema.nodeUsageCount,
          enabled: {
            isChecked: gapSchema.selectable == 1,
            isDisabled: gapSchema.nodeUsageCount > 0,
            dataId: gapSchema.id,
            param3: !gapSchema.selectable,
            param4: gapSchema.id,
            param5: gapSchema.title
          }
        };
        if (_this.getSettingValue('feature.gap_schemas_article_editor')) {
          obj.edit = {
            url: "/compliance/admin/gapschemas/".concat(_this.gapSchemaType, "/").concat(gapSchema.id, "/articles")
          };
        }
        obj.copy = {
          dataId: gapSchema.id,
          dataMethod: 'copy',
          param2: 'copy',
          param3: false,
          param4: gapSchema.id,
          param5: gapSchema.title,
          icon: 'copy',
          text: ''
        };
        obj.delete = {
          disableEvent: gapSchema.nodeUsageCount > 0,
          dataId: gapSchema.id,
          dataMethod: 'delete',
          param2: 'delete',
          param3: false,
          param4: gapSchema.id,
          param5: null,
          icon: 'trash',
          text: ''
        };
        obj.download = {
          url: "/api/2.0/compliance/gapschema/".concat(gapSchema.id, "/download")
        };
        format.push(obj);
      });
      return format;
    },
    gapSchemaTypes: function gapSchemaTypes() {
      var _this2 = this;
      var gapSchemaIds = this.allGapSchema.map(function (gapSchema) {
        return gapSchema.gapSchemaType;
      });
      var templateTypeIds = this.templates.map(function (template) {
        return template.type;
      });
      var allTypeIds = [].concat(_toConsumableArray(gapSchemaIds), _toConsumableArray(templateTypeIds));
      return this.allGapSchemaTypes.filter(function (type) {
        return allTypeIds.indexOf(type.id) !== -1;
      }).map(function (gapSchema) {
        return {
          label: _this2.translateGapSchemaType(gapSchema.type),
          value: gapSchema.id
        };
      });
    },
    gapSchemas: function gapSchemas() {
      var _this3 = this;
      return this.allGapSchema.filter(function (schema) {
        return schema.gapSchemaType == _this3.gapSchemaType;
      });
    },
    projectBySelectedType: function projectBySelectedType() {
      var _this4 = this;
      if (!this.gapSchemaType) return null;
      return this.projects.find(function (project) {
        return project.type === _this4.gapSchemaType;
      });
    },
    showGapArticleTypesForSelectedProject: function showGapArticleTypesForSelectedProject() {
      return !!(this.projectBySelectedType && this.projectBySelectedType.showGapArticleTypes);
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'gap');
    }
  }),
  watch: {
    gapSchemaType: function gapSchemaType(_gapSchemaType) {
      this.$router.push({
        query: {
          gapSchemaType: _gapSchemaType
        }
      });
    }
  },
  mounted: function mounted() {
    var _this5 = this;
    this.getQuestionnaireTemplates();
    getGapSchemasTypes().then(function (_ref) {
      var list = _ref.list;
      _this5.allGapSchemaTypes = list;
    });
    this.getGapSchemas();
    this.getComplianceProjects();
  },
  methods: {
    handleEvents: function handleEvents(eventValue) {
      var _this6 = this;
      var _eventValue$slice = eventValue.slice(1),
        _eventValue$slice2 = _slicedToArray(_eventValue$slice, 4),
        action = _eventValue$slice2[0],
        selectable = _eventValue$slice2[1],
        gapSchemaId = _eventValue$slice2[2],
        currentTitle = _eventValue$slice2[3];
      this.newTitle = currentTitle;
      switch (action) {
        case 'enabled':
          updateGapSchema(gapSchemaId, {
            selectable: selectable
          }).then(this.getGapSchemas);
          return;
        case 'delete':
          this.bufferFunction = function () {
            return deleteGapSchema(gapSchemaId).then(_this6.getGapSchemas).catch(function (e) {
              return _this6.errorNotification = e.response.error;
            });
          };
          this.showConfirmDeleteAlert = true;
          return;
        case 'copy':
          this.bufferFunction = function (title) {
            return copyGapSchema(gapSchemaId, title).then(_this6.getGapSchemas);
          };
          this.showTitleModal = true;
          return;
        case 'title':
          this.bufferFunction = function (title) {
            return updateGapSchema(gapSchemaId, {
              title: title
            }).then(_this6.getGapSchemas);
          };
          this.showTitleModal = true;
          return;
      }
    },
    doConfirm: function doConfirm() {
      this.bufferFunction(this.newTitle);
      this.closePopups();
    },
    closePopups: function closePopups() {
      this.newTitle = '';
      this.showTitleModal = false;
      this.showConfirmDeleteAlert = false;
      this.bufferFunction = null;
    },
    getComplianceProjects: function getComplianceProjects() {
      var _this7 = this;
      return getAllComplianceProjects().then(function (_ref2) {
        var list = _ref2.list;
        _this7.projects = list;
      });
    },
    getGapSchemas: function getGapSchemas() {
      var _this8 = this;
      return getAllGapSchemas('data[]=all').then(function (_ref3) {
        var list = _ref3.list;
        _this8.allGapSchema = list;
      });
    },
    getQuestionnaireTemplates: function getQuestionnaireTemplates() {
      var _this9 = this;
      return getAllQuestionnaireTemplates('gap').then(function (_ref4) {
        var list = _ref4.list;
        _this9.templates = list;
      });
    },
    translateGapSchemaType: function translateGapSchemaType(type) {
      return this.$trans(getTranslatables('questionnaireTypes')[type] || type);
    }
  }
};