function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Alert from '@/components/Molecules/Modal/Alert';
import { sortNumericByPropComplex } from '@/utils/sort';
import { MODULES } from '@/constants/modules';
import { CustomFieldTypes, getCustomFieldTypeText } from '@/constants/CustomFieldTypes';
export default {
  name: 'CustomFieldTypes',
  token: '<custom-field-types />',
  description: 'This is a selector with types of custom field and their optional parameters',
  components: {
    InputField: InputField,
    FeatherIcon: FeatherIcon,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    Alert: Alert
  },
  props: {
    field: {
      type: Object,
      require: true,
      default: function _default() {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    moduleType: {
      type: String,
      require: true,
      default: ''
    },
    customFields: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All possible custom fields'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['edit'],
  data: function data() {
    return {
      itemToDelete: null,
      showConfirmationAlert: false,
      customFieldTypes: CustomFieldTypes,
      customFieldsFiltered: [],
      MODULES: MODULES,
      fieldTypes: this.prepareFieldTypes(),
      availableOrder: [],
      unSorted: true,
      translate: getTranslate['CustomFieldTypes']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    hasActiveField: function hasActiveField() {
      return this.customFieldsFiltered.filter(function (item) {
        return item.isActive;
      }).length > 0;
    },
    fieldTypesAll: function fieldTypesAll() {
      var _this = this;
      var result = _toConsumableArray(this.fieldTypes);
      result = result.filter(function (item) {
        return item.value !== _this.customFieldTypes.COMBINED;
      });
      if (this.moduleType !== MODULES.CONTROLS || this.getSettingValue('feature.repeatable_fields') !== 1) {
        result = result.filter(function (item) {
          return item.value !== _this.customFieldTypes.ROW_GROUP;
        });
      }
      if (this.moduleType === MODULES.CONTROLS || this.moduleType === MODULES.EXECUTION || this.moduleType === MODULES.INCIDENTS || this.moduleType === MODULES.CONTRACTS) {
        result = result.filter(function (item) {
          return item.value !== _this.customFieldTypes.TREND;
        });
      }
      return result;
    },
    fieldType: function fieldType() {
      return this.field.typeId;
    },
    errors: function errors() {
      return this.field.errors || {};
    }
  }),
  mounted: function mounted() {
    var _this$field;
    if (!((_this$field = this.field) !== null && _this$field !== void 0 && _this$field.typeId)) {
      this.$emit('edit', 'typeId', CustomFieldTypes.SELECT);
      this.addOption();
      this.prepareCustomFieldsFiltered();
    } else {
      if (this.field.children) {
        this.prepareCustomFieldsFiltered();
        this.updateChosenCustomFields(this.field.children);
        this.prepareAvailableOrder();
        this.orders = this.getOrders();
      }
    }
  },
  methods: {
    onChangeFieldType: function onChangeFieldType(value) {
      if (value) {
        this.$emit('edit', 'typeId', value);
      }
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var options = _toConsumableArray(this.field.options);
      options.splice(options.indexOf(this.itemToDelete), 1);
      this.$emit('edit', 'options', options);
      this.showConfirmationAlert = false;
      this.itemToDelete = null;
    },
    removeOption: function removeOption(item) {
      this.itemToDelete = item;
      this.showConfirmationAlert = true;
    },
    addOption: function addOption(e) {
      if (e) {
        e.preventDefault();
      }
      this.$emit('edit', 'options', [].concat(_toConsumableArray(this.field.options || []), [{
        value: ''
      }]));
    },
    updateDropdownOption: function updateDropdownOption() {
      this.$emit('edit', 'options', this.field.options);
    },
    onChangeChosenCustomFieldOrder: function onChangeChosenCustomFieldOrder(field, e) {
      if (e) {
        this.swapOrder(field.order, e);
        field.order = e;
        this.$forceUpdate();
        this.sortByOrder(this.customFieldsFiltered);
        this.updateRepeatableFields();
      }
    },
    swapOrder: function swapOrder(value, e) {
      var prevCustomField = this.customFieldsFiltered.find(function (field) {
        return field.order === e;
      });
      if (prevCustomField) {
        prevCustomField.order = value;
      }
    },
    sortByOrder: function sortByOrder(data) {
      if (data && data.length) {
        sortNumericByPropComplex(data, 'order');
      }
    },
    getOrders: function getOrders() {
      var length = this.customFieldsFiltered.filter(function (item) {
        return item.isActive;
      }).length;
      return Array.from({
        length: length
      }, function (v, k) {
        return {
          label: k + 1 + '',
          value: k + 1
        };
      });
    },
    refreshFieldOrders: function refreshFieldOrders() {
      var _this2 = this;
      var index = 0;
      this.customFieldsFiltered.map(function (field) {
        if (field.isActive) {
          field.order = _this2.availableOrder[index];
          index++;
        } else {
          field.order = null;
        }
      });
    },
    prepareAvailableOrder: function prepareAvailableOrder() {
      var length = this.customFieldsFiltered.filter(function (item) {
        return item.isActive;
      }).length;
      if (length) {
        this.availableOrder = Array.from({
          length: length
        }, function (v, k) {
          return ++k;
        });
      }
    },
    findNextAvailableOrder: function findNextAvailableOrder() {
      var result = this.availableOrder.slice(-1).pop();
      if (result) {
        result += 1;
      } else {
        result = 1;
      }
      this.availableOrder.push(result);
      return result;
    },
    updateChosenCustomFields: function updateChosenCustomFields(data) {
      var _this3 = this;
      if (data) {
        data.map(function (item, index) {
          _this3.customFieldsFiltered.map(function (field) {
            if (item.id === field.id) {
              field.isActive = true;
              field.order = ++index;
            }
          });
        });
      }
      this.sortByOrder(this.customFieldsFiltered);
    },
    prepareCustomFieldsFiltered: function prepareCustomFieldsFiltered() {
      var _this4 = this;
      this.customFieldsFiltered = this.customFields.filter(function (item) {
        return item.typeId !== _this4.customFieldTypes.ROW_GROUP && item.module === MODULES.CONTROLS;
      });
      this.customFieldsFiltered.map(function (field) {
        field.isActive = false;
        field.order = null;
      });
    },
    prepareFieldTypes: function prepareFieldTypes() {
      var result = [];
      for (var key in CustomFieldTypes) {
        result.push({
          label: this.$trans(getCustomFieldTypeText(CustomFieldTypes[key])),
          value: CustomFieldTypes[key]
        });
      }
      return result;
    },
    setChosenCustomFieldActive: function setChosenCustomFieldActive(field, e) {
      var _this5 = this;
      if (e) {
        if (!field.order) {
          field.order = this.findNextAvailableOrder();
        }
      }
      this.orders = this.getOrders();
      this.prepareAvailableOrder();
      this.refreshFieldOrders();
      this.$forceUpdate();
      this.$nextTick(function () {
        _this5.sortByOrder(_this5.customFieldsFiltered);
      });
      this.updateRepeatableFields();
    },
    updateUnit: function updateUnit(value) {
      this.$emit('edit', 'unit', value);
    },
    updateRepeatableFields: function updateRepeatableFields() {
      var children = this.customFieldsFiltered.filter(function (item) {
        return item.isActive;
      });
      this.$emit('edit', 'children', children);
    }
  }
};