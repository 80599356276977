import { getTranslate } from './translate';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Modal from '@/components/Molecules/Modal/Modal';
import { isEmailValid } from '@/utils/validations';
export default {
  name: 'CompanyContactModal',
  components: {
    InputField: InputField,
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    Modal: Modal
  },
  props: {
    contactData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    companiesOptions: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['save', 'dismiss'],
  data: function data() {
    return {
      selectedCompanyId: this.companiesOptions.length === 1 ? this.companiesOptions[0].value : '',
      isValidContactName: true,
      isValidContactEmail: true,
      isValidCompany: true,
      translate: getTranslate['CompanyContactModal']()
    };
  },
  methods: {
    validateContact: function validateContact() {
      this.isValidContactName = !!this.contactData.name;
      this.isValidContactEmail = this.validateEmail(this.contactData.email);
      this.isValidCompany = this.validateCompany(this.companiesOptions, this.selectedCompanyId);
      return this.isValidContactName && this.isValidContactEmail && this.isValidCompany;
    },
    handleSaveContact: function handleSaveContact() {
      if (!this.validateContact()) return;
      this.$emit('save', this.contactData, this.selectedCompanyId);
    },
    validateEmail: function validateEmail(email) {
      if (!email) return true;
      return isEmailValid(email);
    },
    validateCompany: function validateCompany(companyList, companyId) {
      if (companyList.length) {
        return companyId !== '';
      }
      return true;
    }
  }
};