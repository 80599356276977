import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-center", {
      'm-auto': !$props.disableCentering
    }])
  }, [_createVNode(_component_feather_icon, {
    height: "48",
    width: "48",
    class: "animate-spin-slow inline",
    icon: "loader"
  }), _createElementVNode("h5", null, _toDisplayString($data.translate.loading), 1 /* TEXT */)], 2 /* CLASS */);
}