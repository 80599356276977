import i18n from '@/i18n';
export var getTranslate = {
  AttachmentItem: function AttachmentItem() {
    return {
      unpin: i18n.t('Unpin'),
      download: i18n.t('Download'),
      link: i18n.t('Link'),
      edit: i18n.t('Edit'),
      newVersion: i18n.t('New version'),
      delete: i18n.t('Delete'),
      showVersions: i18n.t('Show versions'),
      byYou: i18n.t('By you'),
      ago: i18n.t('ago')
    };
  }
};