function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import CustomRiskField from '@/components/Organisms/Admin/CustomRiskField';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Alert from '@/components/Molecules/Modal/Alert';
import FieldGroupsModal from '@/components/Molecules/Modal/FieldGroupsModal';
import { CustomFieldTypes as CF_TYPES } from '@/constants/CustomFieldTypes';
import * as Utils from '@/utils/Utils';
import { MODULES } from '@/constants/modules';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'CustomRiskFieldsWell',
  components: {
    Alert: Alert,
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    FieldGroupsModal: FieldGroupsModal,
    CustomRiskField: CustomRiskField
  },
  props: {
    scoringId: {
      type: Number,
      required: true
    },
    fields: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    },
    fieldGroups: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['edit', 'delete', 'move:groups', 'move:fields'],
  data: function data() {
    return {
      CustomFieldTypes: CF_TYPES,
      fieldDeafultValue: {
        typeId: null,
        options: [],
        unit: '',
        children: [],
        errors: {}
      },
      showFieldGroupsModal: false,
      showAlert: false,
      idToDelete: null,
      ungroupedCFIndexToDelete: null,
      translate: getTranslate['CustomRiskFieldsWell']()
    };
  },
  computed: {
    customFieldsNew: function customFieldsNew() {
      return this.fields.filter(function (item) {
        return item.id === undefined;
      });
    },
    groupedCFLength: function groupedCFLength() {
      return this.fields.length - this.customFieldsNew.length;
    },
    filteredFilds: function filteredFilds() {
      var _this = this;
      return this.fields.filter(function (item) {
        return item.typeId !== _this.CustomFieldTypes.COMBINED;
      }).sort(function (fieldA, fieldB) {
        return (fieldA === null || fieldA === void 0 ? void 0 : fieldA.sorting) - (fieldB === null || fieldB === void 0 ? void 0 : fieldB.sorting);
      });
    },
    combinedFields: function combinedFields() {
      var _this2 = this;
      return this.fields.filter(function (item) {
        return item.fieldtype === _this2.CustomFieldTypes.COMBINED;
      }) || [];
    },
    customFieldsByGroups: function customFieldsByGroups() {
      var _this3 = this;
      var map = {};
      this.filteredFilds.forEach(function (field) {
        if (field.id === undefined) return;
        if (!map[field.fieldGroup]) {
          map[field.fieldGroup] = [];
        }
        field.options = field.options ? Utils.getProperSelectedOptions(field.options) : [];
        map[field.fieldGroup].push(_objectSpread(_objectSpread({}, _this3.fieldDeafultValue), field));
      });
      return map;
    },
    reorderButtonTitle: function reorderButtonTitle() {
      return this.customFieldsNew.length ? this.translate.savePeriodToChangeCustomFieldOrder : this.translate.reorderCustomFields;
    },
    notGroupedId: function notGroupedId() {
      var group = this.fieldGroups.find(function (group) {
        return group.name === '';
      });
      return (group === null || group === void 0 ? void 0 : group.id) || 0;
    }
  },
  methods: {
    emitChangeFields: function emitChangeFields(fields) {
      this.$emit('edit', fields);
    },
    addUngroupedCF: function addUngroupedCF() {
      var baseFields = _objectSpread({
        calculationType: 1,
        fieldWeight: -1,
        fieldGroup: this.notGroupedId,
        module: MODULES.RISK,
        objType: ObjectTypes.CUSTOM_FIELDS_FIELD,
        scoringId: this.scoringId,
        label: ''
      }, this.fieldDeafultValue);
      var fields = [].concat(_toConsumableArray(this.fields), [baseFields]);
      this.emitChangeFields(fields);
    },
    deleteCF: function deleteCF() {
      if (this.ungroupedCFIndexToDelete !== null) {
        this.fields.splice(this.groupedCFLength + this.ungroupedCFIndexToDelete, 1);
        this.emitChangeFields(this.fields);
      }
      if (this.idToDelete !== null) {
        this.$emit('delete', this.idToDelete);
      }
      this.closeAlert();
    },
    handleDeleteCF: function handleDeleteCF(id) {
      this.idToDelete = id;
      this.showAlert = true;
    },
    handleDeleteUngroupedCF: function handleDeleteUngroupedCF(index) {
      this.ungroupedCFIndexToDelete = index;
      this.showAlert = true;
    },
    closeAlert: function closeAlert() {
      this.showAlert = false;
      this.ungroupedCFIndexToDelete = this.idToDelete = null;
    },
    updateCustomField: function updateCustomField(_ref) {
      var id = _ref.id,
        index = _ref.index,
        event = _ref.event;
      if (!event) return;
      var fieldIndex = null;
      var fields = _toConsumableArray(this.fields);
      var _event = _slicedToArray(event, 2),
        key = _event[0],
        value = _event[1];
      if (id !== undefined) {
        fieldIndex = fields.findIndex(function (field) {
          return field.id === id;
        });
      } else {
        fieldIndex = this.groupedCFLength + index;
      }
      fields[fieldIndex][key] = value;
      this.emitChangeFields(fields);
    },
    toggleReorderCFModal: function toggleReorderCFModal() {
      this.showFieldGroupsModal = !this.showFieldGroupsModal;
    },
    onCreateGroup: function onCreateGroup(newField) {
      this.fieldGroups.push(newField);
    },
    moveGroups: function moveGroups(fieldGroups) {
      this.$emit('move:groups', fieldGroups);
    },
    moveFields: function moveFields(data) {
      var _this4 = this;
      var fieldsList = this.fieldGroups.reduce(function (accumulator, current) {
        if (data[current.id]) {
          accumulator.push.apply(accumulator, _toConsumableArray(data[+current.id]));
        } else {
          var fields = _this4.customFieldsByGroups[current.id] || [];
          accumulator.push.apply(accumulator, _toConsumableArray(fields));
        }
        return accumulator;
      }, []);
      this.$emit('move:fields', [].concat(_toConsumableArray(fieldsList), _toConsumableArray(this.combinedFields)));
    }
  }
};