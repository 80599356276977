import { getTranslate } from './translate';
import { createRiskThreatCategory } from '@/api/risk/risk';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
export default {
  name: 'CategoryModal',
  introduction: 'Modal to create category',
  description: 'Modal contains an input field and button',
  token: '<category-modal />',
  components: {
    InputField: InputField,
    Notification: Notification,
    Modal: Modal
  },
  emits: ['dismiss', 'create'],
  data: function data() {
    return {
      isTitleValid: true,
      modalErrorMessage: '',
      title: '',
      translate: getTranslate['CategoryModal']()
    };
  },
  computed: {
    header: function header() {
      return this.translate.createCategory;
    }
  },
  methods: {
    handleAccept: function handleAccept() {
      this.isTitleValid = this.title !== '';
      if (this.isTitleValid) {
        this.createCategory();
      }
    },
    createCategory: function createCategory() {
      return createRiskThreatCategory({
        title: this.title
      }).then(this.handleCreateCategorySuccess).catch(this.handleCreateCategoryFailure);
    },
    handleCreateCategorySuccess: function handleCreateCategorySuccess(response) {
      this.modalErrorMessage = '';
      this.$emit('create', response);
    },
    handleCreateCategoryFailure: function handleCreateCategoryFailure(error) {
      this.modalErrorMessage = this.$trans(error.response);
      this.showModalError = true;
    }
  }
};