function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import { useUserStore } from '@/Store/userStore';
import { getDataflowReport } from '@/api/compliance/reports/dataflows';
import * as API from '@/api/risma/data';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import HierarchicalList from '@/components/Molecules/HierarchicalList';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { IATitleMixin } from '@/mixins/IATitleMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { getHierarchicalList, buildHierarchicalStructure } from '@/utils/format/hierarchicalList';
import { filterAsset } from '@/utils/format/objectsFromIds';
import { mapToCommaList } from '@/utils/format/mapTo';
import { SortDirection } from '@/constants/SortDirection';
import { UserLevels } from '@/constants/UserLevels';
import { ReportTypes } from '@/constants/reports';
export default {
  name: 'ReportDataflow',
  introduction: 'A wrapper for the compliance dataflow report',
  description: 'A wrapper for the compliance report to handle search in Dataflow.',
  token: '<report-dataflow />',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaDatatable: RismaDatatable,
    HierarchicalList: HierarchicalList,
    SaveReport: SaveReport
  },
  mixins: [RismaDatatableDefaultMixin, IATitleMixin, SaveReportMixin],
  props: {
    projectId: {
      required: true,
      type: [String, Number],
      note: 'ID of current project'
    }
  },
  data: function data() {
    return {
      order: [{
        index: 'stakeholder',
        dir: SortDirection.ASC
      }],
      organisations: [],
      nodes: null,
      readyForRender: false,
      dataset: [],
      decoupleFilterFields: [{
        key: 'dataTypes'
      }, {
        key: 'informationAssets'
      }, {
        key: 'organisation'
      }],
      reportType: ReportTypes.COMPLIANCE_DATAFLOW,
      stateLocal: "report_dataflow_state_".concat(this.projectId),
      translate: getTranslate['ReportDataflow']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useTagsStore, ['tags'])), mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserAccessLevelByProjectId: 'complianceAccessLevelByProjectId'
  })), {}, {
    columns: function columns() {
      return {
        stakeholder: this.translate.stakeholder,
        direction: this.translate.direction,
        dataTypes: this.translate.dataTypes,
        informationAssets: this.informationAssetsTitle(),
        systemsTitle: "".concat(this.informationAssetsTitle(), " ").concat(this.translate.title),
        comments: this.translate.comments,
        process: this.translate.process,
        organisation: this.translate.organisation,
        tags: this.translate.tags
      };
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      Promise.all([this.getReportData(), this.fetchOrganisations()]).then(this.createTable);
    },
    createTable: function createTable() {
      var _this = this;
      this.readyForRender = true;
      this.$nextTick(function () {
        _this.dataset = _this.prepareDataset(_this.nodes);
        _this.enhanceDataset(_this.dataset, _this.columns);
        _this.loadSavedReport();
      });
    },
    prepareDataset: function prepareDataset(data) {
      var _this2 = this;
      var tmpArr = [];
      data.forEach(function (node) {
        node.dataFlows.forEach(function (element) {
          tmpArr.push(_this2.extractData(element, node));
        });
      });
      return tmpArr;
    },
    getReportData: function getReportData() {
      return getDataflowReport(this.projectId).then(this.setNodes);
    },
    setNodes: function setNodes(response) {
      this.nodes = response.list;
    },
    fetchOrganisations: function fetchOrganisations() {
      var _this3 = this;
      var filterByUserAccess = this.currentUserAccessLevelByProjectId(this.projectId) && this.currentUserAccessLevelByProjectId(this.projectId) < UserLevels.SUPER;
      return API.getOrganisationsAndFilterByUserAccess(filterByUserAccess).then(function (response) {
        return _this3.organisations = response.list;
      });
    },
    lookupOrganisation: function lookupOrganisation(id) {
      var org = this.organisations.find(function (item) {
        return item.id === parseInt(id);
      });
      return org ? org.visible_name : '';
    },
    extractData: function extractData(element, node) {
      var _this4 = this;
      return {
        stakeholder: {
          field: element.stakeholder.text
        },
        direction: {
          field: element.direction === 'deliver' ? this.translate.deliversData : this.translate.receivesData
        },
        dataTypes: {
          field: element.dataTypes.map(function (item) {
            return item.text;
          }).join(', ')
        },
        informationAssets: {
          field: element.systems.map(function (item) {
            return item.text;
          }).join(', ')
        },
        systemsTitle: {
          field: element.systemsTitle !== null && element.systemsTitle !== '' ? element.systemsTitle : this.translate.systems
        },
        comments: {
          field: element.comments
        },
        process: {
          field: {
            node: buildHierarchicalStructure(node)
          },
          fieldText: getHierarchicalList(node)
        },
        organisation: {
          field: node.organisationIds.map(function (item) {
            return _this4.lookupOrganisation(item);
          }).join(', ')
        },
        tags: {
          field: this.getTagsNames(this.tags, node.tagIds)
        }
      };
    },
    getTagsNames: function getTagsNames(tags, ids) {
      return mapToCommaList(filterAsset(tags, ids), 'tag');
    }
  }
};