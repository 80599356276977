import { saveStatus } from '@/constants/SaveStatuses';
import { getNodesFlat } from '@/api/risma/data';
import * as riskAPI from '@/api/risma/riskAssessments';
export var InformationAssetsCommonMixin = {
  computed: {
    filteredComplianceNodes: function filteredComplianceNodes() {
      var _this = this;
      if (!this.data.complianceNodes) {
        return [];
      }
      return this.complianceNodes.filter(function (node) {
        return node.checkaccess && _this.data.complianceNodes.indexOf(node.id) !== -1;
      });
    },
    riskAssessmentsModuleName: function riskAssessmentsModuleName() {
      return "dataprocessor_".concat(this.type.slice(0, -1));
    }
  },
  data: function data() {
    return {
      complianceNodes: [],
      riskData: null,
      updateTimer: 0
    };
  },
  methods: {
    getComplianceNodes: function getComplianceNodes() {
      var _this2 = this;
      getNodesFlat().then(function (_ref) {
        var list = _ref.list;
        return _this2.complianceNodes = list;
      });
    },
    setUpdateTimer: function setUpdateTimer(debounceTimeout) {
      debounceTimeout = debounceTimeout || this.$debounceTimeout;
      this.saveStatus = saveStatus.NOT_SAVED;
      this.dirty = true;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(this.updateItem, debounceTimeout);
    },
    getRiskData: function getRiskData() {
      var _this3 = this;
      return riskAPI.getAll(this.riskAssessmentsModuleName, this.id).then(function (response) {
        var riskDataTemplate = function riskDataTemplate(riskIndex) {
          return {
            module: _this3.riskAssessmentsModuleName,
            moduleId: _this3.id,
            riskIndex: riskIndex,
            impact: 0,
            likelihood: 0,
            rpi: 0
          };
        };
        var riskData = [riskDataTemplate(0), riskDataTemplate(1)];
        if (response.length > 0) response.forEach(function (item) {
          return riskData[item.riskIndex] = item;
        });
        _this3.riskData = riskData;
      });
    },
    updateRisk: function updateRisk(event) {
      var _this4 = this;
      var params = event;
      this.saveStatus = saveStatus.SAVING;
      this.showRetry = false;
      this.retryAction = null;
      if (typeof this.riskData[event.riskIndex].id === 'undefined') {
        riskAPI.create(params).then(function (response) {
          _this4.riskData[response.riskIndex] = response;
          _this4.saveStatus = saveStatus.SAVED;
        }).catch(function () {
          _this4.saveStatus = saveStatus.NOT_SAVED;
          _this4.showRetry = true;
          _this4.retryAction = function () {
            return _this4.updateRisk(event);
          };
        });
      } else {
        riskAPI.update(params).then(function (response) {
          _this4.riskData[response.riskIndex] = response;
          _this4.saveStatus = saveStatus.SAVED;
        }).catch(function () {
          _this4.saveStatus = saveStatus.NOT_SAVED;
          _this4.showRetry = true;
          _this4.retryAction = function () {
            return _this4.updateRisk(event);
          };
        });
      }
    }
  }
};