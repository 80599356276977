import { compareStrings } from '@/utils/compare';
var LEVEL_PROP = 'level';
export var getOrganisationLevel = function getOrganisationLevel(organisation) {
  var level = 1;
  while (organisation["".concat(LEVEL_PROP).concat(level)]) {
    level++;
  }
  return level - 1;
};
var getUniqueKey = function getUniqueKey(organisation) {
  var level = 1;
  var uniqueKey = '';
  while (organisation["".concat(LEVEL_PROP).concat(level)]) {
    uniqueKey += "".concat(uniqueKey ? '-' : '').concat(organisation["".concat(LEVEL_PROP).concat(level)]);
    level++;
  }
  return uniqueKey;
};
var getParentUniqueKey = function getParentUniqueKey(organisation) {
  var level = 1;
  var parentId = '';
  while (organisation["".concat(LEVEL_PROP).concat(level)] && organisation["".concat(LEVEL_PROP).concat(level + 1)]) {
    parentId += "".concat(parentId ? '-' : '').concat(organisation["".concat(LEVEL_PROP).concat(level)]);
    level++;
  }
  return parentId;
};
export var setDisableAddAction = function setDisableAddAction(organisation, level) {
  if (level === 3) {
    organisation.disableAddAction = true;
  }
};
export var buildOrganisationTree = function buildOrganisationTree(organisations) {
  var hashTable = {};
  var tree = [];
  organisations.forEach(function (organisation) {
    var uniqueKey = getUniqueKey(organisation);
    hashTable['org' + uniqueKey] = organisation;
  });
  for (var orgName in hashTable) {
    if (Object.keys(hashTable).includes(orgName)) {
      var organisation = hashTable[orgName];
      organisation.title = organisation.name;
      organisation.children = [];
      organisation.checkaccess = true;
      var orgLevel = getOrganisationLevel(organisation);
      if (orgLevel > 1) {
        setDisableAddAction(organisation, orgLevel);
        var uniqueKey = getParentUniqueKey(organisation, true);
        hashTable['org' + uniqueKey].children.push(organisation);
      } else {
        tree.push(organisation);
      }
    }
  }
  return tree;
};
export function insertOrganisationToTree(value, array, startVal, endVal, sibling) {
  var length = array.length;
  var start = startVal === undefined ? 0 : startVal;
  var end = endVal === undefined ? length - 1 : endVal;
  var middle = start + Math.floor((end - start) / 2);
  var valueName = value.name.toLowerCase();
  if (length === 0) {
    array.push(value);
    return;
  }
  if (compareStrings(valueName, array[end].name.toLowerCase()) === 1) {
    sibling.id = array[end].id;
    array.splice(end + 1, 0, value);
    return;
  }
  if (compareStrings(valueName, array[start].name.toLowerCase()) === -1) {
    sibling.id = array[start - 1] && array[start - 1].id;
    array.splice(start, 0, value);
    return;
  }
  if (compareStrings(start, end) === 0) {
    return;
  }
  if (compareStrings(valueName, array[middle].name.toLowerCase()) === -1) {
    insertOrganisationToTree(value, array, start, middle - 1, sibling);
    return;
  }
  if (compareStrings(valueName, array[middle].name.toLowerCase()) === 1) {
    insertOrganisationToTree(value, array, middle + 1, end, sibling);
    return;
  }
}