import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = ["title", "innerHTML"];
var _hoisted_3 = ["title"];
var _hoisted_4 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$iconData;
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass($props.titleClass)
  }, [$props.titleIsRawHtml ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    title: $props.titleAttribute,
    innerHTML: $props.title
  }, null, 8 /* PROPS */, _hoisted_2)) : $props.type ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 1,
    truncate: $props.truncate,
    title: $props.title,
    type: $props.type,
    "title-attribute": $props.titleAttribute
  }, null, 8 /* PROPS */, ["truncate", "title", "type", "title-attribute"])) : (_openBlock(), _createElementBlock("span", {
    key: 2,
    title: $props.titleAttribute
  }, _toDisplayString($props.title), 9 /* TEXT, PROPS */, _hoisted_3))], 2 /* CLASS */), $props.displayIcon && $options.iconData ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    title: $props.titleAttribute,
    class: "flex"
  }, [_createVNode(_component_feather_icon, {
    icon: $options.iconData.icon,
    class: _normalizeClass(((_$options$iconData = $options.iconData) === null || _$options$iconData === void 0 ? void 0 : _$options$iconData.class) || '')
  }, null, 8 /* PROPS */, ["icon", "class"])], 8 /* PROPS */, _hoisted_4)) : _createCommentVNode("v-if", true)]);
}