import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-57ac75a4"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "border-separate -ml-45px"
};
var _hoisted_2 = {
  class: "text-left"
};
var _hoisted_3 = {
  class: "text-left"
};
var _hoisted_4 = {
  class: "text-left"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [$data.isNotificationShow ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: $data.notificationType,
    onDismiss: $options.handleDismissNotification
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.notificationMessage), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type", "onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.assets,
    type: "big",
    class: "mb-4"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("table", _hoisted_1, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.name,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.defaultQuestionnaire,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.defaultActivityType,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])])])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.settingsData, function (item, index) {
    return _openBlock(), _createElementBlock("tr", {
      key: index
    }, [_createElementVNode("td", null, [_createVNode(_component_risma_title, {
      title: _ctx.$trans(item.name),
      type: "small"
    }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("td", null, [_createVNode(_component_single_select, {
      modelValue: $data.data[item.questionnaireId],
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $data.data[item.questionnaireId] = $event;
      },
      options: item.questionnairesOptions,
      "with-reset": $data.data[item.questionnaireId] !== null,
      "un-sorted": true,
      placeholder: $data.translate.selectDefaultQuestionnaire,
      class: "min-w-300px"
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options", "with-reset", "placeholder"])]), _createElementVNode("td", null, [_createVNode(_component_single_select, {
      modelValue: $data.data[item.defaultActivityType],
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $data.data[item.defaultActivityType] = $event;
      },
      options: item.activityTypeOptions,
      "with-reset": true,
      placeholder: $data.translate.notSet,
      class: "min-w-300px"
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options", "placeholder"])])]);
  }), 128 /* KEYED_FRAGMENT */)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customInformationAssetsModules, function (item) {
    return _openBlock(), _createElementBlock("tr", {
      key: item.id
    }, [_createElementVNode("td", null, [_createVNode(_component_risma_title, {
      title: item.label,
      type: "small"
    }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("td", null, [_createVNode(_component_single_select, {
      modelValue: item.defaultQuestionnaireId,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return item.defaultQuestionnaireId = $event;
      },
      options: $options.customQuestionnaireOptions,
      "with-reset": item.defaultQuestionnaireId !== null,
      "un-sorted": true,
      placeholder: $data.translate.selectDefaultQuestionnaire,
      class: "min-w-300px"
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options", "with-reset", "placeholder"])])]);
  }), 128 /* KEYED_FRAGMENT */))])]), _createVNode(_component_risma_button, {
    text: _ctx.$trans("Save settings"),
    type: "save",
    class: "ml-auto mt-8 mr-10",
    onClick: $options.saveSettings
  }, null, 8 /* PROPS */, ["text", "onClick"])]);
}