import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_dep_header = _resolveComponent("dep-header");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: "The title can be changed",
    type: "medium",
    class: "mb-3"
  }), _createVNode(_component_dep_header, {
    id: $data.id,
    title: $data.title,
    "editable-title": true,
    tabs: $data.tabs,
    "files-list": $data.filesList,
    "traffic-light-settings": $data.trafficLightSettingsFixed,
    onChangeTitle: $options.changeValue
  }, null, 8 /* PROPS */, ["id", "title", "tabs", "files-list", "traffic-light-settings", "onChangeTitle"]), _createVNode(_component_risma_title, {
    title: "The title, Id and traffic light can be changed",
    type: "medium",
    class: "my-10"
  }), _createVNode(_component_dep_header, {
    id: $data.id,
    "editable-id": true,
    title: $data.title,
    "editable-title": true,
    tabs: $data.tabs,
    "files-list": $data.filesList,
    "traffic-light-settings": $data.trafficLightSettingsCanChange,
    onChangeTrafficLight: $options.changeValue,
    onChangeId: $options.changeValue,
    onChangeTitle: $options.changeValue
  }, null, 8 /* PROPS */, ["id", "title", "tabs", "files-list", "traffic-light-settings", "onChangeTrafficLight", "onChangeId", "onChangeTitle"]), _createVNode(_component_risma_title, {
    title: "The title and Id can not be changed, no traffic light",
    type: "medium",
    class: "my-10"
  }), _createVNode(_component_dep_header, {
    id: $data.id,
    title: $data.title,
    tabs: $data.tabs,
    "files-list": $data.filesList
  }, null, 8 /* PROPS */, ["id", "title", "tabs", "files-list"])]);
}