function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import LinksBox from '@/components/Molecules/LinksBox';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { checkERMByProjectId } from '@/utils/risks/check';
export default {
  name: 'ComplianceReportsBox',
  introduction: 'ComplianceReportsBox - displays a list of reports in Overview page',
  description: "This component displays a list of reports based on the type of the compliance project. \n        It is utilized as a separate box on the Compliance Overview Page",
  components: {
    LinksBox: LinksBox
  },
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'ID of current project'
    }
  },
  data: function data() {
    return {
      translate: {
        reports: this.$trans('Reports'),
        viewTheCurrentStatusOfYourSoa: this.$trans('View the current status of your SoA'),
        threats: this.$trans('Threats'),
        aHeatmapOfAllAssessedThreats: this.$trans('A heatmap of all assessed threats'),
        completeStatusListOfMitigatedControls: this.$trans('Complete status list of mitigated controls'),
        nodes: this.$trans('Nodes')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserComplianceProjectById: 'complianceProjectById'
  })), {}, {
    currentProject: function currentProject() {
      return this.currentUserComplianceProjectById(this.projectId);
    },
    reports: function reports() {
      if (this.currentProject.type === ProjectTypes.ISO27001) {
        return this.getIsoReports();
      } else if (this.currentProject.type === ProjectTypes.GDPR) {
        return this.getGdprReports();
      }
      return [];
    }
  }),
  methods: {
    getIsoReports: function getIsoReports() {
      var reports = [];
      reports.push({
        title: 'SoA',
        subtitle: this.translate.viewTheCurrentStatusOfYourSoa,
        url: "/compliance/reports/".concat(this.projectId, "/gap")
      });
      var isThreatValueShown = this.getSettingValue('feature.risk_assessment_on_systems') === 1 && this.getSettingValue('feature.systems_r_projects_id') && !checkERMByProjectId(this.getSettingValue('feature.systems_r_projects_id'), this.currentUser);
      if (isThreatValueShown) {
        reports.push({
          title: this.translate.threats,
          subtitle: this.translate.aHeatmapOfAllAssessedThreats,
          url: "/risk2/projects/".concat(this.getSettingValue('feature.systems_r_projects_id'), "/reports/threats")
        });
      }
      reports.push({
        title: 'Controls',
        subtitle: this.translate.completeStatusListOfMitigatedControls,
        url: '/controls2/reports/main'
      });
      return reports;
    },
    getGdprReports: function getGdprReports() {
      var _this = this;
      var reports = [];
      reports.push({
        title: this.translate.nodes,
        url: "/compliance/reports/".concat(this.projectId, "/nodes")
      });
      if (this.currentProject.reports) {
        Object.keys(this.currentProject.reports).forEach(function (item) {
          reports.push({
            title: _this.$trans(_this.currentProject.reports[item].header),
            url: "/compliance/reports/".concat(_this.projectId, "/").concat(item)
          });
        });
      }
      return reports;
    }
  }
};