export default {
  name: 'AssessmentIndicators',
  props: {
    modelValue: {
      type: Array,
      required: true,
      note: 'The main array of data for building an indicator'
    },
    totalScore: {
      type: Number,
      required: true
    }
  },
  computed: {
    progressInPercent: function progressInPercent() {
      var result = 0;
      var total = this.totalScore;
      this.modelValue.map(function (item) {
        if (item.isFilled) {
          result += item.width;
        } else {
          if (total > item.minScore && total < item.score) {
            var scoreDifference = item.score - item.minScore;
            var interval = total - item.minScore;
            result += item.width * (interval / scoreDifference);
          }
        }
      });
      return result;
    }
  }
};