import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-f8b58d44"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "preview preview-incident"
};
var _hoisted_2 = {
  class: "preview-cont"
};
var _hoisted_3 = {
  class: "preview-header"
};
var _hoisted_4 = {
  class: "preview-header-trafficlight"
};
var _hoisted_5 = {
  class: "preview-header-title"
};
var _hoisted_6 = {
  class: "preview-body"
};
var _hoisted_7 = {
  class: "preview-body-section"
};
var _hoisted_8 = {
  class: "preview-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_traffic_light, {
    color: $options.severity.color
  }, null, 8 /* PROPS */, ["color"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_router_link, {
    to: $props.incident.url
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $props.incident.title
      }, null, 8 /* PROPS */, ["title"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.status),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createTextVNode(" " + _toDisplayString(_ctx.$trans($options.status.name)), 1 /* TEXT */)])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: $options.createdWithLabel,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $options.occurredWithLabel,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), $options.archivedWithLabel ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    title: $options.archivedWithLabel,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true)])])]);
}