import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "control-reports-tabs"
};
var _hoisted_2 = {
  class: "border-2 p-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tab_item = _resolveComponent("tab-item");
  var _component_tabs = _resolveComponent("tabs");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_tabs, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_tab_item, {
        link: $options.baseControlLink + 'main'
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.mainReport), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["link"]), _createVNode(_component_tab_item, {
        link: $options.baseControlLink + 'mothercontrols'
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.motherControls), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["link"]), $options.dataReportIsVisible ? (_openBlock(), _createBlock(_component_tab_item, {
        key: 0,
        link: $options.baseControlLink + 'data'
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.dataReport), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["link"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view)])]);
}