function _objectDestructuringEmpty(obj) { if (obj == null) throw new TypeError("Cannot destructure " + obj); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'CompactTitleDataComponent',
  description: 'compact is used to show component taken from prepareCompactTitleData data',
  components: {
    TrafficLight: TrafficLight,
    FeatherIcon: FeatherIcon,
    PreviewModalLink: PreviewModalLink,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  props: {
    slotData: {
      required: true,
      type: Object,
      note: 'slot Data'
    }
  },
  computed: {
    attrs: function attrs() {
      var _ref = this.$attrs || {},
        listeners = _extends({}, (_objectDestructuringEmpty(_ref), _ref));
      delete listeners.class;
      return listeners;
    },
    titleClass: function titleClass() {
      var _this$slotData$active;
      return ((_this$slotData$active = this.slotData.active) !== null && _this$slotData$active !== void 0 ? _this$slotData$active : true) ? '' : 'line-through';
    },
    isControl: function isControl() {
      return this.slotData.objType === ObjectTypes.CONTROLS_CONTROL || this.slotData.objType === ObjectTypes.CONTROLS_CONTROL_LIMITED;
    },
    isAction: function isAction() {
      return this.slotData.objType === ObjectTypes.EXECUTION_INITIATIVE || this.slotData.objType === ObjectTypes.EXECUTION_INITIATIVE_LIMITED;
    }
  }
};