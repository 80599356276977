function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import * as DashboardAPI from '@/api/contracts/dashboard';
import { getWorkflowStatusesByModule } from '@/api/risma/data';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import InfoBox from '@/components/Atoms/Containers/InfoBox';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import PieChart from '@/components/Molecules/Charts/PieChart';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import ContractGenericTable from '@/components/Pages/Contracts/ContractGenericTable';
import { ContractGenericTableMixins } from '@/components/Pages/Contracts/ContractGenericTableMixins';
import { compareDates } from '@/utils/compare';
import { CONTRACT_STATUSES } from '@/constants/contracts/contract';
import { SUMMERIES_PROPS } from '@/constants/contracts/dashboard';
import { MODULES } from '@/constants/modules';
import { capitalize } from '@/utils/capitalize';
import { getMonthsNames } from '@/utils/date';
import { Colors } from '@/Colors';
export default {
  name: 'ContractsDashboard',
  introduction: 'Contracts Dashboard page',
  description: 'This page shows the dashboards for contracts',
  components: {
    ContractGenericTable: ContractGenericTable,
    FeatherIcon: FeatherIcon,
    InfoBox: InfoBox,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    PieChart: PieChart,
    RismaTitle: RismaTitle,
    LightTabItem: LightTabItem,
    RismaDoughnutChart: RismaDoughnutChart
  },
  mixins: [ContractGenericTableMixins],
  data: function data() {
    var _monthlyDataset;
    return {
      topPanels: {},
      monthIndex: null,
      statuses: CONTRACT_STATUSES,
      pieColors: [Colors.system.blueMain, Colors.greenTea, Colors.red, Colors.dullGreen, Colors.lightRed, Colors.yellow, Colors.lightYellow, Colors.system.lightGray, Colors.system.lightGray2, Colors.system.blueGray, Colors.system.blueGrayActive, Colors.system.primaryLight, Colors.system.primaryDark],
      chartDatasets: [],
      chartLabels: [],
      monthlyDataset: (_monthlyDataset = {}, _defineProperty(_monthlyDataset, CONTRACT_STATUSES.renewal, {}), _defineProperty(_monthlyDataset, CONTRACT_STATUSES.expiring, {}), _monthlyDataset),
      isLoaded: false,
      renewal: [],
      expiring: [],
      contracts: [],
      workflowStatuses: [],
      companies: [],
      translate: getTranslate['ContractsDashboard']()
    };
  },
  computed: {
    months: function months() {
      return getMonthsNames();
    },
    tableTitle: function tableTitle() {
      return this.months[this.monthIndex] ? "".concat(this.$trans("".concat(capitalize(this.status), " in")), " ").concat(this.$trans(this.months[this.monthIndex])) : '';
    },
    sortedContracts: function sortedContracts() {
      return _toConsumableArray(this.contracts).sort(function (a, b) {
        return compareDates(a.endDate, b.endDate);
      });
    }
  },
  beforeMount: function beforeMount() {
    this.initTopPanels();
  },
  mounted: function mounted() {
    var _this = this;
    Promise.all([this.getExpiringContracts(), this.getRenewalContracts(), this.getWorkflowStatuses(), this.getStatusContracts(), this.getSummaryContracts(), this.getCompanies()]).finally(function () {
      _this.isLoaded = true;
    });
  },
  methods: {
    initTopPanels: function initTopPanels() {
      var _this$topPanels;
      this.topPanels = (_this$topPanels = {}, _defineProperty(_this$topPanels, SUMMERIES_PROPS.contracts, {
        title: this.translate.contracts,
        value: 0,
        icon: 'file-text',
        iconStyle: 'text-blue-750'
      }), _defineProperty(_this$topPanels, SUMMERIES_PROPS.annual_cost, {
        title: this.translate.annualCost,
        value: 0,
        icon: 'dollar-sign',
        iconStyle: 'text-green-410 '
      }), _defineProperty(_this$topPanels, SUMMERIES_PROPS.expiring, {
        title: this.translate.expirationOfContractsWithin30Days,
        value: 0,
        icon: 'clock',
        iconStyle: 'text-red-50'
      }), _this$topPanels);
    },
    getCompanies: function getCompanies() {
      var _this2 = this;
      return _getCompanies().then(function (_ref) {
        var list = _ref.list;
        return _this2.companies = list;
      });
    },
    getWorkflowStatuses: function getWorkflowStatuses() {
      var _this3 = this;
      return getWorkflowStatusesByModule(MODULES.CONTRACTS).then(function (_ref2) {
        var list = _ref2.list;
        _this3.workflowStatuses = list;
      });
    },
    setStatusAndMonth: function setStatusAndMonth(status, month, year) {
      this.status = status;
      this.monthIndex = month;
      this.contracts = this[status]["".concat(year, "-").concat(month + 1)] || [];
    },
    getSummaryContracts: function getSummaryContracts() {
      var _this4 = this;
      return DashboardAPI.getSummaryContracts().then(function (response) {
        var summaries = response.summaries;
        Object.keys(_this4.topPanels).forEach(function (item) {
          _this4.topPanels[item].value = summaries[item];
        });
      });
    },
    getStatusContracts: function getStatusContracts() {
      var _this5 = this;
      return DashboardAPI.getStatusContracts().then(function (response) {
        var status = response.status;
        var statuses = Object.keys(status);
        var data = statuses.map(function (item) {
          return status[item].length;
        });
        var backgroundColor = _this5.pieColors;
        _this5.chartLabels = statuses;
        _this5.chartDatasets = [{
          data: data,
          backgroundColor: backgroundColor
        }];
      });
    },
    getRenewalContracts: function getRenewalContracts() {
      var _this6 = this;
      return DashboardAPI.getRenewalContracts().then(function (response) {
        var renewing = response.renewing;
        _this6.renewal = renewing;
        _this6.prepareMonthlyDatasetByStatus(renewing, CONTRACT_STATUSES.renewal);
      });
    },
    getExpiringContracts: function getExpiringContracts() {
      var _this7 = this;
      return DashboardAPI.getExpiringContracts().then(function (response) {
        var expiring = response.expiring;
        _this7.expiring = expiring;
        _this7.prepareMonthlyDatasetByStatus(expiring, CONTRACT_STATUSES.expiring);
      });
    },
    prepareMonthlyDatasetByStatus: function prepareMonthlyDatasetByStatus(data, status) {
      for (var _i = 0, _Object$entries = Object.entries(data); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
          key = _Object$entries$_i[0],
          value = _Object$entries$_i[1];
        var _key$split = key.split('-'),
          _key$split2 = _slicedToArray(_key$split, 2),
          year = _key$split2[0],
          month = _key$split2[1];
        if (!this.monthlyDataset[status][year]) {
          this.monthlyDataset[status][year] = Array(12).fill(0);
        }
        this.monthlyDataset[status][year][month - 1] = value.length;
      }
    },
    changeTab: function changeTab() {
      this.contracts = [];
      this.monthIndex = null;
    }
  }
};