function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Colors } from '@/Colors';
import { capitalize } from '@/utils';
import { isHexColor } from '@/utils/regex';
import { getOrganisations } from '@/api/risma/data';
export var RismaControlsUtils = {
  trafficLights: {
    red: 1,
    yellow: 2,
    green: 3,
    grey: 0
  },
  states: {
    STATE_UNKNOWN: 0,
    STATE_PENDING: 1,
    STATE_COMPLETED: 2,
    STATE_PARTIALLY_COMPLETED: 3,
    STATE_READY_FOR_REVIEW: 4,
    STATE_REVIEW_REJECTED: 5,
    STATE_INACTIVE: 6,
    STATE_DELETED: 7,
    STATE_MOTHERCONTROL: 8,
    STATE_NOT_COMPLETED: 9,
    STATE_NOT_REVIEWED: 10,
    STATE_PARTIALLY_READY_FOR_REVIEW: 11,
    STATE_PARTIALLY_REVIEW_REJECTED: 12,
    STATE_NOT_PERFORMED: 13,
    STATE_REVIEW_NOT_PERFORMED: 14
  },
  frequencies: {
    FREQ_WEEKLY: 1,
    FREQ_BIWEEKLY: 2,
    FREQ_MONTHLY: 3,
    FREQ_QUARTERLY: 4,
    FREQ_BIANNUALLY: 5,
    FREQ_ANNUALLY: 6,
    FREQ_DAILY: 7,
    FREQ_BUSINESS_DAYS: 8,
    FREQ_TWO_YEARS: 9,
    FREQ_THREE_YEARS: 10,
    FREQ_FOUR_YEARS: 11,
    FREQ_FIVE_YEARS: 12,
    FREQ_ONE_TIME: 13,
    FREQ_CUSTOM: 14
  },
  reviews: {
    COMPLETED: 'completed',
    REJECTED: 'rejected',
    APPROVED: 'approved',
    PENDING: 'pending',
    NOT_PERFORMED: 'review_not_performed',
    PARTIALLY_COMPLETED: 'partially_completed',
    PARTIALLY_REJECTED: 'partially_rejected',
    PARTIALLY_APPROVED: 'partially_approved',
    CONTROL_NOT_PERFORMED: 'control_not_performed'
  },
  /**
   * @param frequency
   * @returns {string}
   */
  frequency2text: function frequency2text(frequency) {
    switch (frequency) {
      case RismaControlsUtils.frequencies.FREQ_DAILY:
        return 'Daily';
      case RismaControlsUtils.frequencies.FREQ_WEEKLY:
        return 'Weekly';
      case RismaControlsUtils.frequencies.FREQ_BIWEEKLY:
        return 'Biweekly';
      case RismaControlsUtils.frequencies.FREQ_MONTHLY:
        return 'Monthly';
      case RismaControlsUtils.frequencies.FREQ_QUARTERLY:
        return 'Quarterly';
      case RismaControlsUtils.frequencies.FREQ_BIANNUALLY:
        return 'Biannually';
      case RismaControlsUtils.frequencies.FREQ_ANNUALLY:
        return 'Annually';
      case RismaControlsUtils.frequencies.FREQ_BUSINESS_DAYS:
        return 'Business days';
      case RismaControlsUtils.frequencies.FREQ_TWO_YEARS:
        return 'Every two years';
      case RismaControlsUtils.frequencies.FREQ_THREE_YEARS:
        return 'Every three years';
      case RismaControlsUtils.frequencies.FREQ_FOUR_YEARS:
        return 'Every four years';
      case RismaControlsUtils.frequencies.FREQ_FIVE_YEARS:
        return 'Every five years';
      case RismaControlsUtils.frequencies.FREQ_ONE_TIME:
        return 'One-time Control';
      case RismaControlsUtils.frequencies.FREQ_CUSTOM:
        return 'Custom dates';
    }
  },
  /**
   * @param state
   * @returns {string}
   */
  state2text: function state2text(state) {
    switch (state) {
      case RismaControlsUtils.states.STATE_UNKNOWN:
        return 'Unknown control state';
      case RismaControlsUtils.states.STATE_PENDING:
        return 'Pending';
      case RismaControlsUtils.states.STATE_COMPLETED:
        return 'Completed';
      case RismaControlsUtils.states.STATE_READY_FOR_REVIEW:
      case RismaControlsUtils.states.STATE_PARTIALLY_READY_FOR_REVIEW:
        return 'Awaiting review';
      case RismaControlsUtils.states.STATE_REVIEW_REJECTED:
        return 'Control rejected';
      case RismaControlsUtils.states.STATE_PARTIALLY_REVIEW_REJECTED:
        return 'Control rejected';
      case RismaControlsUtils.states.STATE_PARTIALLY_COMPLETED:
        return 'Completed with actions';
      case RismaControlsUtils.states.STATE_INACTIVE:
        return 'Control is not active';
      case RismaControlsUtils.states.STATE_DELETED:
        return 'Control is archived / deleted';
      case RismaControlsUtils.states.STATE_MOTHERCONTROL:
        return 'Control is a mother control';
      case RismaControlsUtils.states.STATE_NOT_COMPLETED:
        return 'Control was not completed before deadline';
      case RismaControlsUtils.states.STATE_NOT_REVIEWED:
        return 'Control was not reviewed before deadline';
      case RismaControlsUtils.states.STATE_NOT_PERFORMED:
        return 'Control not performed';
      case RismaControlsUtils.states.STATE_REVIEW_NOT_PERFORMED:
        return 'Review not performed';
    }
    return 'Not found';
  },
  /**
   * @param controls
   * @param allow_state
   * @returns {*}
   * Filter controls by RISMAstate - returns matches
   */
  filterControlsByStatus: function filterControlsByStatus(controls, allow_state) {
    return controls.filter(function (control) {
      return control.rismaState == allow_state;
    });
  },
  /**
   * @param controls
   * @param criteria
   * @returns {*}
   * Filter controls by completion datetime - overdue or within deadline
   */
  filterControlsByCompletion: function filterControlsByCompletion(controls, criteria) {
    if (criteria === 'ok') {
      return controls.filter(function (control) {
        return Date.parse(control.completed) <= Date.parse(control.deadline);
      });
    }
    if (criteria === 'overdue') {
      return controls.filter(function (control) {
        return Date.parse(control.completed) > Date.parse(control.deadline);
      });
    }
  },
  /**
   * @param controls
   * @param traffic_light
   * @returns {*}
   * Filter controls by traffic light color - returns controls with the requested traffic light
   */
  filterControlsByTrafficLight: function filterControlsByTrafficLight(controls, traffic_light) {
    return controls.filter(function (control) {
      return control.trafficLight == traffic_light;
    });
  },
  getTrafficLightLabel: function getTrafficLightLabel(value) {
    var useExtendedTraffics = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var valueToString = value + '';
    var result = '';
    if (isHexColor(valueToString)) {
      return capitalize(Colors.getTrafficLabelByColor(valueToString));
    }
    switch (valueToString) {
      case '1':
      case '2':
      case '3':
        if (useExtendedTraffics) {
          var _Colors$getControlTra;
          result = capitalize((_Colors$getControlTra = Colors.getControlTrafficLightByNumber(valueToString)) === null || _Colors$getControlTra === void 0 ? void 0 : _Colors$getControlTra.label);
        } else {
          var _Colors$getTrafficlig;
          result = capitalize((_Colors$getTrafficlig = Colors.getTrafficlightByNumber(valueToString)) === null || _Colors$getTrafficlig === void 0 ? void 0 : _Colors$getTrafficlig.label);
        }
        break;
      case 'Red':
      case 'Yellow':
      case 'Green':
        result = valueToString;
        break;
      default:
        result = 'Grey';
        break;
    }
    return result;
  },
  cloneEntities: function cloneEntities(items, titleFn) {
    var _this = this;
    var result = [];
    if (items && items.length) {
      items.map(function (item) {
        result.push(_objectSpread(_objectSpread({}, item), {}, {
          _titleWithPath: typeof titleFn === 'function' ? titleFn(item) : _this.addTitleWithpath(item)
        }));
      });
    }
    return result;
  },
  addTitleWithpath: function addTitleWithpath(item) {
    return "".concat(item.path, " ").concat(item.title);
  },
  addTitleWithRno: function addTitleWithRno(item) {
    return "".concat(item.rno, " ").concat(item.title);
  },
  getControlTitle: function getControlTitle(item) {
    return "".concat(item.cpath, " ").concat(item.title);
  },
  getControlLink: function getControlLink(item) {
    return "/controls2/controls/".concat(item.cpath, "/description");
  },
  humanReadableAction: function humanReadableAction(action) {
    switch (action) {
      case RismaControlsUtils.reviews.APPROVED:
        return 'Approved';
      case RismaControlsUtils.reviews.COMPLETED:
        return 'Completed';
      case RismaControlsUtils.reviews.PARTIALLY_REJECTED:
      case RismaControlsUtils.reviews.REJECTED:
        return 'Rejected';
      case RismaControlsUtils.reviews.PARTIALLY_APPROVED:
        return 'Partially approved';
      case RismaControlsUtils.reviews.REVIEW_NOT_PERFORMED:
        return 'Review not performed';
      case RismaControlsUtils.reviews.PARTIALLY_COMPLETED:
        return 'Completed with actions';
      case RismaControlsUtils.reviews.CONTROL_NOT_PERFORMED:
        return 'Control not performed';
      case 'skipped':
        return 'Skipped';
    }
    return action;
  },
  getTrafficLightTurnsYellowLabel: function getTrafficLightTurnsYellowLabel(day) {
    var $trans = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (text) {
      return text;
    };
    return "".concat(day, " ").concat(day > 1 ? $trans('days before deadline') : $trans('day before deadline'));
  },
  prepareReviewLink: function prepareReviewLink(item) {
    return "/controls/my/reviews/".concat(item.cpath, "/").concat(item.id, "?returnUrl=/controls2/my");
  },
  prepareControlsTwoReviewLink: function prepareControlsTwoReviewLink(item) {
    return "/controls2/review/".concat(item.cpath, "/").concat(item.id);
  },
  prepareCompleteButtonEvent: function prepareCompleteButtonEvent(type, item) {
    return {
      type: type,
      cpath: item.cpath,
      id: item.id
    };
  },
  addMissingOrgs: function addMissingOrgs(controls) {
    var _this2 = this;
    return new Promise(function (resolve) {
      var missingOrgs = [];
      getOrganisations().then(function (_ref) {
        var list = _ref.list;
        missingOrgs = _this2.findOrgsInControlsOnly(controls, list);
        resolve(missingOrgs);
      }).catch(function () {
        resolve(missingOrgs);
      });
    });
  },
  findOrgsInControlsOnly: function findOrgsInControlsOnly(controls, organisations) {
    var result = [];
    controls.map(function (control) {
      if (control.organisationIds) {
        control.organisationIds.map(function (controlOrgId) {
          organisations.map(function (org) {
            if (controlOrgId === org.id && result.indexOf(org) === -1) {
              result.push(org);
            }
          });
        });
      }
      // Need to support dailyTeamIds as well (backend ticket: RIS-1510)
    });

    return result;
  }
};