import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'RismaSelectKitchen',
  components: {
    RismaSelect: RismaSelect,
    FeatherIcon: FeatherIcon
  },
  data: function data() {
    return {
      RismaSelectOptions: [{
        id: '1',
        option: 'Hello'
      }, {
        id: '2',
        option: 'To'
      }, {
        id: '3',
        option: 'You'
      }, {
        id: '4',
        option: 'Me'
      }, {
        id: '5',
        option: 'And'
      }, {
        id: '6',
        option: 'Irene'
      }],
      RismaSelectOptionsSelected: [{
        id: '2',
        option: 'To'
      }],
      RismaSelectCustomlabel: [{
        id: 1,
        title: 'It\'s first option',
        icon: 'arrow-left'
      }, {
        id: 2,
        title: 'It\'s second option',
        icon: 'arrow-right'
      }],
      RismaSelectCustomlabelSelected: []
    };
  },
  methods: {
    showSelected: function showSelected($event) {
      this.showResult('RismaSelect.@selected', $event);
    },
    showResult: function showResult(name, result) {
      window.alert(name, JSON.stringify(result));
    }
  }
};