function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import CustomAxisTable from '@/components/Molecules/Risk/CustomAxisTable';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import * as Utils from '@/utils/Utils';
export default {
  name: 'RiskErmAssesment',
  introduction: 'Risk assessment on ERM DEP',
  description: 'Risk assessment wrapper on DEP',
  token: "<risk-erm-assessment\n        :risk=\"activity\"\n        :scoring=\"scoring\"\n     />",
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    TinyMceBox: TinyMceBox,
    CustomAxisTable: CustomAxisTable
  },
  props: {
    risk: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Current risk data'
    },
    scoring: {
      type: Object,
      required: true,
      default: function _default() {},
      note: 'Scoring data'
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Is read only mode switch on'
    }
  },
  emits: ['updateCustom', 'changed'],
  data: function data() {
    var _comments;
    return {
      RiskBaseModelProps: RiskBaseModelProps,
      comments: (_comments = {}, _defineProperty(_comments, RiskBaseModelProps.LIKELIHOOD_COMMENT, this.risk[RiskBaseModelProps.LIKELIHOOD_COMMENT] || ''), _defineProperty(_comments, RiskBaseModelProps.IMPACT_COMMENT, this.risk[RiskBaseModelProps.IMPACT_COMMENT] || ''), _defineProperty(_comments, RiskBaseModelProps.Z_AXIS_COMMENT, this.risk[RiskBaseModelProps.Z_AXIS_COMMENT] || ''), _defineProperty(_comments, RiskBaseModelProps.IMPACT_OTHER1_COMMENT, this.risk[RiskBaseModelProps.IMPACT_OTHER1_COMMENT] || ''), _defineProperty(_comments, RiskBaseModelProps.IMPACT_OTHER2_COMMENT, this.risk[RiskBaseModelProps.IMPACT_OTHER2_COMMENT] || ''), _comments),
      maxlength: 999,
      translate: getTranslate['RiskErmAssesment']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    probabilitiesOptions: function probabilitiesOptions() {
      var _this = this;
      return this.scoring.likelihood_labels.map(function (item) {
        return {
          label: item.likelihood_number + ': ' + _this.scoring.likelihood_values[item.likelihood_number * 1] + (item.description !== '' ? ' (' + item.description + ')' : ''),
          value: item.likelihood_number * 1
        };
      });
    },
    impactOptions: function impactOptions() {
      var options = [];
      Object.values(this.scoring.impact_labels).forEach(function (item, index) {
        options.push({
          label: index + 1 + ': ' + item,
          value: index + 1
        });
      });
      return options;
    },
    impactOther1Options: function impactOther1Options() {
      var options = [];
      Object.values(this.scoring.impactOther1Labels).forEach(function (item, index) {
        options.push({
          label: item,
          value: index + 1
        });
      });
      return options;
    },
    impactOther2Options: function impactOther2Options() {
      var options = [];
      Object.values(this.scoring.impactOther2Labels).forEach(function (item, index) {
        options.push({
          label: item,
          value: index + 1
        });
      });
      return options;
    },
    zAxisOptions: function zAxisOptions() {
      var options = [];
      if (this.scoring.z_axis_values === undefined) {
        return options;
      }
      Object.values(this.scoring.z_axis_values).forEach(function (item, index) {
        options.push({
          label: index + 1 + ': ' + item.value,
          value: index + 1
        });
      });
      return options;
    },
    riskOptions: function riskOptions() {
      return {
        likelihood_score: this.risk.likelihood_score,
        impact_score: this.risk.impact_score,
        impact_other1_score: this.risk.impact_other1_score,
        impact_other2_score: this.risk.impact_other2_score,
        z_axis_score: this.risk.z_axis_score
      };
    },
    keepCommentFieldsExpanded: function keepCommentFieldsExpanded() {
      return !!this.getSettingValue('feature.risk_comments_field_on_axis');
    }
  }),
  methods: {
    onCommentChanged: function onCommentChanged(key, e) {
      this.comments[key] = Utils.setMaxlength(e, this.maxlength);
      this.$emit('changed', {
        property: key,
        value: e
      });
    },
    onStateChanged: function onStateChanged(e) {
      if (e.value === null) {
        e.value = 0;
      }
      this.$emit('changed', e);
    },
    getAxisRows: function getAxisRows(data) {
      var _this2 = this;
      data.forEach(function (item) {
        var res = _this2.risk.customFieldValues.find(function (field) {
          return field.fieldId === item.id;
        });
        item.value = res !== undefined ? res.value : 0;
      });
      return data;
    }
  }
};