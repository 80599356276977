function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { RiskReportsFiltersMixin } from '@/components/Molecules/Risk/RiskReports/RiskReportsFiltersMixin';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
export default {
  name: 'RiskReportsFilters',
  description: 'Filters section for risk reports',
  token: '<risk-reports-filters />',
  components: {
    RismaToggle: RismaToggle,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    FeatherIcon: FeatherIcon
  },
  mixins: [RiskReportsFiltersMixin],
  props: {
    options: {
      required: true,
      type: Object,
      note: 'Object with options for selectors'
    },
    modelValue: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      },
      note: 'Object with selected filters values'
    }
  },
  emits: ['changePeriod'],
  data: function data() {
    return {
      unSorted: true,
      selectedValues: _objectSpread({
        includeUnderlyingRisks: false
      }, this.modelValue),
      riskAssessmentsValidation: '',
      translate: getTranslate['RiskReportsFilters']()
    };
  },
  computed: _objectSpread({}, mapState(useSettingsStore, ['isSystemsEnabled'])),
  methods: {
    validate: function validate() {
      var result = true;
      this.riskAssessmentsValidation = '';
      if (!(this.modelValue.riskAssessments && this.modelValue.riskAssessments.length)) {
        result = false;
        this.riskAssessmentsValidation = this.translate.pleaseSelectOne;
      }
      return result;
    }
  }
};