import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
export default {
  name: 'DataFlowWizardStepDataSubject',
  components: {
    RadioButtons: RadioButtons
  },
  props: {
    stakeholderOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'stakeholder options that are usually used for Choose data subject step'
    },
    currentDataSubject: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  emits: ['update:dataSubject'],
  methods: {
    onOptionChange: function onOptionChange(data) {
      this.$emit('update:dataSubject', data);
    }
  }
};