function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaSwitch from '@/components/Atoms/Inputs/RismaSwitch';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Alert from '@/components/Molecules/Modal/Alert';
import TextBox from '@/components/Atoms/Inputs/TextBox';
export default {
  name: 'DataflowModal',
  introduction: 'A modal, that handles dataflows',
  description: 'this organism is derived from the modal molecule.',
  token: '<dataflow-modal/>',
  components: {
    RismaSelect: RismaSelect,
    RismaSwitch: RismaSwitch,
    SingleSelect: SingleSelect,
    Modal: Modal,
    Alert: Alert,
    TextBox: TextBox
  },
  props: {
    projectId: {
      required: true,
      type: Number,
      note: 'ID of current project'
    },
    nodeId: {
      required: true,
      type: Number,
      note: 'ID of current node'
    },
    stakeholders: {
      type: Array,
      required: true,
      note: 'Stakeholders'
    },
    dataTypes: {
      type: Array,
      required: true,
      note: 'Data types'
    },
    systems: {
      type: Array,
      required: true,
      note: 'Systems'
    },
    systemsTitle: {
      type: String,
      required: false,
      default: 'Systems',
      note: 'Systems title'
    }
  },
  emits: ['toggleModal', 'createFlow'],
  data: function data() {
    return {
      showModal: false,
      invalid: false,
      selectedDirection: 'deliver',
      directions: [],
      selectedStakeholderId: null,
      selected: {
        dataTypes: [],
        systems: []
      },
      showAlert: false,
      comments: '',
      translate: getTranslate['DataflowModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isCommentsEnabled: function isCommentsEnabled() {
      return this.getSettingValue('feature.data_flow_comments');
    },
    stakeholderOptions: function stakeholderOptions() {
      return this.stakeholders.map(function (s) {
        return {
          value: s.uniqueId,
          label: s.text
        };
      });
    },
    dataFlowStakeholdersTitle: function dataFlowStakeholdersTitle() {
      return this.getSettingValue('feature.data_flow_stakeholders_name') || this.translate.stakeholders;
    }
  }),
  beforeMount: function beforeMount() {
    this.initDirections();
  },
  methods: {
    initDirections: function initDirections() {
      this.directions = [{
        name: this.translate.receives,
        value: 'receive'
      }, {
        name: this.translate.delivers,
        value: 'deliver'
      }];
    },
    handleSelected: function handleSelected(field, e) {
      this.selected[field] = e ? e : [];
    },
    selectStakeholder: function selectStakeholder(stakeholderId) {
      this.selectedStakeholderId = stakeholderId;
      this.invalid = false;
    },
    formatSelected: function formatSelected() {
      var _this = this;
      return {
        stakeholder: this.stakeholders.find(function (s) {
          return s.uniqueId === _this.selectedStakeholderId;
        }),
        direction: this.selectedDirection,
        dataTypes: this.selected.dataTypes,
        systems: this.selected.systems,
        comments: this.comments
      };
    },
    addDataflow: function addDataflow() {
      this.invalid = false;
      if (!this.selectedStakeholderId) {
        this.invalid = true;
        return false;
      }
      this.$emit('createFlow', this.formatSelected());
    },
    handleDismiss: function handleDismiss() {
      this.showAlert = true;
    },
    acceptAlert: function acceptAlert() {
      this.$emit('toggleModal');
    }
  }
};