function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var QUESTIONNAIRE_TYPE_IA_DPM = 105;
import { getTranslate } from './translate';
import MainWell from '@/components/Atoms/Containers/MainWell';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import QuestionSelection from '@/components/Pages/Compliance/Reports/PredefinedReports/QuestionSelection';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import Article30Table from '@/components/Pages/Compliance/Reports/PredefinedReports/article30/Table';
import { getSelectableQuestionnairesByQuestionnaireTypeId, getAllQuestions } from '@/api/compliance/questionnaire';
import { getAllProcessors } from '@/api/compliance/informationAssets';
import { getAnsweredAudits } from '@/api/compliance/reports/questionnaire';
import { mapQuestionAnswers } from '@/components/Pages/Compliance/Reports/PredefinedReports/utils';
import { QuestionnaireTypesAudit } from '@/constants/compliance/QuestionnaireTypes';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'IAAnsweredProcessorAudits',
  components: {
    MainWell: MainWell,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    QuestionSelection: QuestionSelection,
    RismaButton: RismaButton,
    Article30Table: Article30Table,
    Notification: Notification
  },
  data: function data() {
    return {
      isLoaded: false,
      questionnaires: [],
      processors: [],
      selectedQuestionnaireTypeId: QuestionnaireTypesAudit[ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR],
      selectedQuestionnaireId: null,
      questions: [],
      selectedQuestions: [],
      selectedProcessorIds: [],
      rows: [],
      showReport: false,
      showQuestions: false,
      showSelectProcessors: true,
      errorFromBackend: '',
      ObjectTypes: ObjectTypes,
      QuestionnaireTypesAudit: QuestionnaireTypesAudit,
      localStateName: 'ia_answered_processor_audits_table',
      translate: getTranslate['IAAnsweredProcessorAudits']()
    };
  },
  computed: {
    nameColumnTitle: function nameColumnTitle() {
      var _QuestionnaireTypesAu;
      return (_QuestionnaireTypesAu = {}, _defineProperty(_QuestionnaireTypesAu, QuestionnaireTypesAudit[ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER], this.translate.dataController), _defineProperty(_QuestionnaireTypesAu, QuestionnaireTypesAudit[ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR], this.translate.dataProcessor), _defineProperty(_QuestionnaireTypesAu, QuestionnaireTypesAudit[ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM], this.translate.system), _defineProperty(_QuestionnaireTypesAu, QuestionnaireTypesAudit[ObjectTypes.CONTRACT], this.translate.contract), _defineProperty(_QuestionnaireTypesAu, QuestionnaireTypesAudit[ObjectTypes.INFORMATION_ASSET], this.translate.informationAsset), _QuestionnaireTypesAu)[this.selectedQuestionnaireTypeId];
    },
    questionnairesOptions: function questionnairesOptions() {
      return this.questionnaires.map(function (questionnaire) {
        return {
          value: questionnaire.id,
          label: questionnaire.title
        };
      });
    },
    filteredProcessors: function filteredProcessors() {
      if (!this.selectedQuestionnaireId) return [];
      return this.processors;
      // TO ADD: should be filtered by selectedQuestionnaireId
      // return this.processors.filter(processor => processor.questionnaireId === this.selectedQuestionnaireId);
    },
    processorsOptions: function processorsOptions() {
      return this.filteredProcessors.map(function (processor) {
        return {
          id: processor.id,
          title: processor.name
        };
      });
    },
    questionnaireTypesOptions: function questionnaireTypesOptions() {
      return [{
        value: this.QuestionnaireTypesAudit[this.ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM],
        label: this.translate.systems
      }, {
        value: this.QuestionnaireTypesAudit[this.ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR],
        label: this.translate.dataProcessors
      }, {
        value: this.QuestionnaireTypesAudit[this.ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER],
        label: this.translate.dataControllers
      }, {
        value: this.QuestionnaireTypesAudit[this.ObjectTypes.INFORMATION_ASSET],
        label: this.translate.customAssets
      }, {
        value: this.QuestionnaireTypesAudit[this.ObjectTypes.CONTRACT],
        label: this.translate.contracts
      }];
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.getAll().then(function () {
      return _this.isLoaded = true;
    });
  },
  methods: {
    getAll: function getAll() {
      var _this2 = this;
      return getSelectableQuestionnairesByQuestionnaireTypeId(QUESTIONNAIRE_TYPE_IA_DPM).then(function (response) {
        return _this2.questionnaires = response;
      }).then(getAllProcessors).then(function (response) {
        _this2.processors = response.list;
      });
    },
    onQuestionnaireTypeSelect: function onQuestionnaireTypeSelect(id) {
      var _this3 = this;
      this.selectedQuestionnaireTypeId = id;
      this.selectedQuestionnaireId = null;
      this.selectedProcessorIds = [];
      this.selectedQuestions = [];
      this.showSelectProcessors = false;
      this.selectedQuestions = [];
      this.showReport = false;
      return getSelectableQuestionnairesByQuestionnaireTypeId(id).then(function (response) {
        return _this3.questionnaires = response;
      }).then(getAllProcessors).then(function (response) {
        if (id === _this3.QuestionnaireTypesAudit[_this3.ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR]) {
          _this3.processors = response.list;
          _this3.showSelectProcessors = true;
        }
      });
    },
    onQuestionnaireSelect: function onQuestionnaireSelect(id) {
      if (this.selectedQuestionnaireId === id) return;
      this.selectedQuestionnaireId = id;
      this.selectedQuestions = [];
      this.getQuestions(id);
    },
    getQuestions: function getQuestions(id) {
      var _this4 = this;
      this.showQuestions = false;
      getAllQuestions(id).then(function (response) {
        _this4.questions = response.questions;
        _this4.showQuestions = true;
      });
    },
    onSelectProcessors: function onSelectProcessors(processors) {
      this.selectedProcessorIds = processors.map(function (item) {
        return item.id;
      });
    },
    onQuestionsSelect: function onQuestionsSelect(selectedQuestions) {
      this.selectedQuestions = selectedQuestions;
    },
    onCreateReport: function onCreateReport() {
      var _this5 = this;
      this.showReport = false;
      getAnsweredAudits(this.selectedQuestionnaireId, this.selectedQuestions, this.selectedProcessorIds, 'processorIds').then(function (_ref) {
        var data = _ref.data;
        _this5.createRows(data);
        _this5.showReport = true;
        _this5.errorFromBackend = '';
      }).catch(function (error) {
        return _this5.errorFromBackend = error.response.error;
      });
    },
    createRows: function createRows(data) {
      var _this6 = this;
      this.rows = [];
      data.entities.forEach(function (item) {
        if (!item.answered) return;
        var row = {
          name: item.title,
          fullname: item.fullname,
          email: item.email,
          id: item.dataProcessor && item.dataProcessor.id,
          send: item.created,
          answered: item.answered,
          firstClick: item.firstClick,
          requestExpired: item.requestExpired,
          cells: mapQuestionAnswers(data.questions, data.informationAssets, data.answers[item.id], data.files)
        };
        _this6.rows.push(row);
      });
    }
  }
};