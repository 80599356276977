function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { getQuestionnaireAssessments } from '@/api/compliance/questionnaire';
import { getNodesFlat } from '@/api/risma/data';
import { getAllRequests as _getAllRequests } from '@/api/compliance/dpm';
import { getCVRCompany } from '@/api/external/cvrapi';
import { getAudit } from '@/api/compliance/audit';
import AttachmentSelector from '@/components/Organisms/AttachmentSelector/AttachmentSelector';
import RiskAssessmentsIsms from '@/components/Organisms/Risk/RiskAssessmentsIsms';
import RiskModule from '@/components/Organisms/RiskModule';
import RiskGrid from '@/components/Organisms/RiskGrid';
import AssessmentIndicators from '@/components/Molecules/Compliance/AssessmentIndicators';
import ComplianceScoreTable from '@/components/Molecules/InformationAssets/ComplianceScoreTable';
import LinkedCompany from '@/components/Molecules/LinkedCompany/LinkedCompany';
import LinkCompany from '@/components/Molecules/LinkedCompany/LinkCompany';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import Notification from '@/components/Molecules/Notification';
import QuestionnaireStats from '@/components/Molecules/Compliance/QuestionnaireStats';
import CvrModal from '@/components/Molecules/Modal/CvrModal';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { ActivityTypeMixin } from '@/mixins/ActivityTypeMixin';
import { CustomFieldsMixin } from '@/mixins/CustomFieldsMixin';
import { InformationAssetsCommonMixin } from '../InformationAssetsCommonMixin';
import { RiskAssessmentMixin } from '@/mixins/RiskAssessmentMixin';
import { LinkedCompanyMixin } from '@/mixins/LinkedCompanyMixin';
import InformationAssetsRiskGridMixin from '@/components/Pages/InformationAssets/InformationAssetsRiskGridMixin';
import { sortNumericByPropComplex } from '@/utils/sort';
import { generateComputed } from '@/utils/base/generateComputed';
import { MODULES } from '@/constants/modules';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export default {
  name: 'InformationAssetsProcessorDetails',
  components: {
    RismaTitle: RismaTitle,
    InputField: InputField,
    RiskModule: RiskModule,
    AttachmentSelector: AttachmentSelector,
    ComplianceScoreTable: ComplianceScoreTable,
    AssessmentIndicators: AssessmentIndicators,
    ActivityCustomFields: ActivityCustomFields,
    TinyMceBox: TinyMceBox,
    RismaButton: RismaButton,
    CvrModal: CvrModal,
    Notification: Notification,
    QuestionnaireStats: QuestionnaireStats,
    RiskAssessmentsIsms: RiskAssessmentsIsms,
    SingleSelect: SingleSelect,
    RiskGrid: RiskGrid,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    LinkedCompany: LinkedCompany,
    LinkCompany: LinkCompany
  },
  mixins: [InformationAssetsCommonMixin, ActivityTypeMixin, CustomFieldsMixin, RiskAssessmentMixin, InformationAssetsRiskGridMixin, LinkedCompanyMixin],
  props: {
    id: {
      required: true,
      type: [String, Number],
      note: 'Data processor id'
    },
    data: {
      required: true,
      type: Object
    }
  },
  emits: ['propertyChanged', 'companyStatusChange', 'silentUpdate:attachments'],
  data: function data() {
    return {
      pageLoaded: false,
      type: AssetsTypes.PROCESSORS,
      currentAssessments: [],
      assessmentIndicatorData: null,
      latestDpmAssessment: null,
      questionnaire: null,
      processors: {},
      module: MODULES.COMPLIANCE_IA_PROCESSOR,
      modalDefaultData: {
        name: '',
        address: '',
        phone: '',
        email: '',
        vatNo: ''
      },
      modalData: {},
      showModal: false,
      errorMessage: '',
      showLinkCompanyModal: false,
      selectedCompanyId: null,
      titleField: 'title',
      translate: getTranslate['InformationAssetsProcessorDetails']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), generateComputed(['name', 'trafficLight', 'address', 'description', 'countryCode', 'email', 'phoneNumber', 'vatNo', 'regNo', 'contactName', 'companyId'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    ownerId: function ownerId() {
      return this.currentUser.id;
    },
    showRiskOnProcessors: function showRiskOnProcessors() {
      return !!this.getSettingValue('feature.risk_assessment_on_processors');
    },
    riskProjectId: function riskProjectId() {
      return this.getSettingValue('feature.processors_r_projects_id');
    },
    showNodesAndScores: function showNodesAndScores() {
      return this.getSettingValue('feature.ia_processor_risk_analysis_enabled');
    },
    noFieldAttachments: function noFieldAttachments() {
      return this.data.attachments.filter(function (item) {
        return !item.field;
      });
    },
    dpaFiles: function dpaFiles() {
      return this.data.attachments.filter(function (item) {
        return item.field === 'processingAgreeement';
      });
    }
  }),
  watch: {
    $route: function $route(to) {
      if (to.path.indexOf('/informationassets/processors') !== -1) {
        this.getData();
      }
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getComplianceNodes: function getComplianceNodes() {
      var _this = this;
      getNodesFlat(['questionnaireScore']).then(function (_ref) {
        var list = _ref.list;
        return _this.complianceNodes = list;
      });
    },
    getData: function getData() {
      var _this2 = this;
      this.pageLoaded = false;
      var promises = [this.getRiskData(), this.getRisksData(), this.getComplianceNodes(), this.getCompany()];
      if (this.getSettingValue('feature.data_processor_management')) {
        promises.push(this.getAllRequests());
      }
      if (this.showRtoRpoOnAssets) {
        promises.push(this.getRtoFields(), this.getRpoFields());
      }
      return Promise.all(promises).then(function () {
        return _this2.pageLoaded = true;
      });
    },
    getCurrentDpmAssessment: function getCurrentDpmAssessment(questionnaireId, totalScore) {
      var _this3 = this;
      return getQuestionnaireAssessments(questionnaireId).then(function (_ref2) {
        var list = _ref2.list;
        _this3.latestDpmAssessment = {};
        _this3.latestDpmAssessment.assessmentIndicatorData = [];
        var assessmentIndicatorData = list.map(function (item) {
          return _objectSpread(_objectSpread({}, item), {}, {
            score: item.score + ''
          });
        });
        if (assessmentIndicatorData.length) {
          assessmentIndicatorData = sortNumericByPropComplex(assessmentIndicatorData, 'score');
          _this3.latestDpmAssessment.assessmentIndicatorData = _this3.prepareIndicatorData(assessmentIndicatorData, totalScore);
        }
      });
    },
    prepareIndicatorData: function prepareIndicatorData(assessmentData, totalScore) {
      var result = [];
      assessmentData.forEach(function (item, index) {
        if (index === 0) return;
        var nextIndex = index + 1;
        var next = assessmentData[nextIndex];
        var oneStepInPercents = 100 / (assessmentData.length - 1);
        result.push({
          title: next ? item.score : ">= ".concat(item.score),
          score: +item.score,
          minScore: assessmentData[index - 1].score,
          color: item.color,
          isFilled: +item.score <= totalScore,
          width: oneStepInPercents
        });
      });
      return result;
    },
    getAllRequests: function getAllRequests() {
      var _this4 = this;
      return _getAllRequests(null, this.id, 'questionnaireScore').then(function (response) {
        var currentAudit = response.list.find(function (item) {
          return item.answered !== null;
        }); // .first();
        if (currentAudit) {
          return getAudit('compliance_ia_processor', _this4.id, currentAudit.id);
        }
      }).then(function (currentAudit) {
        if (currentAudit !== undefined) {
          _this4.getCurrentDpmAssessment(currentAudit.questionnaire.id, currentAudit.questionnaireScore.totalScore).then(function () {
            _this4.latestDpmAssessment.data = currentAudit;
          });
        }
      });
    },
    scoreHelpTextStyle: function scoreHelpTextStyle() {
      if (!this.data && this.data.questionnaireScore) {
        return '';
      }
      return 'color: ' + this.data.questionnaireScore.helpText.color;
    },
    searchVatNo: function searchVatNo() {
      var _this5 = this;
      return getCVRCompany(this.vatNo).then(function (_ref3) {
        var list = _ref3.list;
        if (!Array.isArray(list) || list.length < 1) {
          throw {};
        }
        var company = list[0];
        _this5.modalData = _objectSpread(_objectSpread({}, _this5.modalDefaultData), {}, {
          vatNo: _this5.vatNo,
          zipCode: company.zipcode
        }, company);
        _this5.showModal = true;
      }).catch(function () {
        _this5.errorMessage = _this5.translate.noResultsFoundDidYouEnterValidVatNumber;
        setTimeout(function () {
          _this5.errorMessage = '';
        }, 5000);
      });
    },
    onModalSubmit: function onModalSubmit() {
      this.updateDataFromModal(this.modalData);
      this.resetModal();
    },
    updateDataFromModal: function updateDataFromModal(data) {
      if (data.name) {
        this.name = data.name;
      }
      var addressLine = "<div>".concat(data.address, "</div>");
      if (data.zipCode || data.city) {
        addressLine += "<div>".concat(data.zipCode, " ").concat(data.city, "</div>");
      }
      this.address = addressLine;
      this.phoneNumber = data.phone || '';
      this.email = data.email || '';
    },
    resetModal: function resetModal() {
      this.showModal = false;
      this.modalData = _objectSpread({}, this.modalDefaultData);
    },
    handleFileChange: function handleFileChange(_ref4) {
      var files = _ref4.files;
      var attachments = [].concat(_toConsumableArray(this.noFieldAttachments), _toConsumableArray(files));
      this.$emit('silentUpdate:attachments', attachments);
    },
    companyFilter: function companyFilter(company) {
      var _company$processor;
      return !((_company$processor = company.processor) !== null && _company$processor !== void 0 && _company$processor.id);
    }
  }
};