function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import RiskGrid from '@/components/Organisms/RiskGrid';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { RiskColorName, StatusColorMapping } from '@/constants/risks/RisksReports';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import { getConsequenceLabel, getProbabilityLabel } from '@/utils/risks/riskPeriod';
export default {
  name: 'GapSchemaHeatmaps',
  description: 'this component uses in /compliance/${nodeId}/edit/${projectId}/gap',
  components: {
    SingleSelect: SingleSelect,
    RiskGrid: RiskGrid,
    RismaTitle: RismaTitle
  },
  inject: {
    isPreview: {
      default: false
    },
    depRelationEnabled: {
      default: false
    }
  },
  props: {
    period: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Current period'
    },
    threats: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of threats'
    },
    risks: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of risks'
    }
  },
  data: function data() {
    var _colorCodes;
    return {
      statusColor: _objectSpread({}, StatusColorMapping),
      consequenceId: '',
      threatsDots: {},
      risksDots: {},
      colorCodes: (_colorCodes = {}, _defineProperty(_colorCodes, RiskColorName.GREEN, 'background-color: hsl(161, 100%, 34%)'), _defineProperty(_colorCodes, RiskColorName.LIGHTBLUE, 'background-color: hsl(187, 100%, 39%)'), _defineProperty(_colorCodes, RiskColorName.RISMABLUE, 'background-color: hsl(190, 100%, 28%)'), _defineProperty(_colorCodes, RiskColorName.RED, 'background-color: hsl(18, 98%, 40%)'), _defineProperty(_colorCodes, RiskColorName.GREY, 'background-color: hsl(0, 0%, 60%)'), _colorCodes),
      threatColor: 'black',
      gridBoxSize: 50,
      resizeTimer: null,
      translate: getTranslate['GapSchemaHeatmaps']()
    };
  },
  computed: {
    consequenceOptions: function consequenceOptions() {
      return (this.period.consequenceRows || []).map(function (item) {
        return {
          label: item.label,
          value: item.id
        };
      });
    },
    consequenceSelected: function consequenceSelected() {
      var _this = this;
      return (this.period.consequenceRows || []).find(function (item) {
        return item.id === _this.consequenceId;
      }) || {};
    },
    matrices: function matrices() {
      return [{
        title: this.translate.systems,
        data: _objectSpread(_objectSpread({}, this.gridData), {}, {
          colorCodes: this.colorCodes,
          dots: this.risksDots[this.consequenceId]
        })
      }, {
        title: this.translate.threats,
        data: _objectSpread(_objectSpread({}, this.gridData), {}, {
          colorCodes: _defineProperty({}, this.threatColor, 'background-color: #000'),
          dots: this.threatsDots[this.consequenceId]
        })
      }];
    },
    gridData: function gridData() {
      return {
        checked: false,
        xLabel: getProbabilityLabel(this.period),
        yLabel: getConsequenceLabel(this.period),
        gridSizeY: this.createEmptyGridFrom(this.period.consequenceLevels),
        gridSizeX: this.createEmptyGridFrom(this.period.probabilityLevels)
      };
    }
  },
  watch: {
    depRelationEnabled: function depRelationEnabled() {
      this.setGridBoxSize();
    }
  },
  mounted: function mounted() {
    this.consequenceId = this.consequenceOptions[0] ? this.consequenceOptions[0].value : '', this.threatsDots[this.consequenceId] = this.prepareThreatsDots(this.threats, this.consequenceSelected.label);
    this.risksDots[this.consequenceId] = this.prepareRiskDots(this.risks);
    this.setGridBoxSize();
    window.addEventListener('resize', this.onWindowResize);
  },
  beforeUnmount: function beforeUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  methods: {
    onConsequenceChanged: function onConsequenceChanged() {
      if (!this.threatsDots[this.consequenceSelected.id]) {
        this.threatsDots[this.consequenceSelected.id] = this.prepareThreatsDots(this.threats, this.consequenceSelected.label);
      }
      if (!this.risksDots[this.consequenceSelected.id]) {
        this.risksDots[this.consequenceSelected.id] = this.prepareRiskDots(this.risks);
      }
    },
    onWindowResize: function onWindowResize() {
      var _this2 = this;
      if (this.resizeTimer) {
        clearTimeout(this.resizeTimer);
      }
      this.resizeTimer = setTimeout(function () {
        _this2.setGridBoxSize();
        _this2.resizeTimer = null;
      }, 50);
    },
    prepareThreatsDots: function prepareThreatsDots(threats, consequenceLabel) {
      var _this3 = this;
      var dots = [];
      threats.forEach(function (threat) {
        if (threat.assessments) {
          var assessment = threat.assessments.find(function (assessment) {
            return assessment.label === consequenceLabel;
          });
          if (assessment) {
            dots.push(_this3.getThreatDotValue(threat, assessment, _this3.threatColor));
          }
        }
      });
      return dots;
    },
    getThreatDotValue: function getThreatDotValue(threat, assessment, color) {
      return {
        id: threat.id,
        color: color,
        title: threat.title,
        x: assessment.likelihood,
        y: assessment.impact,
        xBefore: 0,
        yBefore: 0
      };
    },
    createEmptyGridFrom: function createEmptyGridFrom(n) {
      return Array(n).fill([]);
    },
    prepareRiskDots: function prepareRiskDots(risks) {
      var _this4 = this;
      return risks.map(function (risk) {
        return _this4.getRiskDotValue(risk, _this4.statusColor);
      });
    },
    getRiskDotValue: function getRiskDotValue(risk, colorConfig) {
      var _this5 = this;
      var consequence, probability;
      if (Array.isArray(risk[RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES])) {
        consequence = risk[RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES].find(function (item) {
          return item.rowId === _this5.consequenceSelected.id;
        });
      }
      if (Array.isArray(risk[RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES])) {
        probability = risk[RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES].find(function (item) {
          return item.rowId === _this5.consequenceSelected.id;
        });
      }
      return {
        id: risk.rno,
        color: colorConfig[risk.gridStatus],
        title: risk.title,
        status: risk.status,
        x: probability ? probability.column.value : 0,
        y: consequence ? consequence.column.value : 0,
        xBefore: 0,
        yBefore: 0
      };
    },
    setGridBoxSize: function setGridBoxSize() {
      var displayWidthRatio = 420;
      var basePercentageQualifier = 10;
      var dynamicPercentageQualifier = 40;
      var heatmapNormalizeRatio = Math.max((displayWidthRatio - this.$el.clientWidth) / displayWidthRatio, 0);
      var auxiliaryRatio = this.depRelationEnabled ? .7 : 1;
      this.gridBoxSize = Math.floor(auxiliaryRatio * this.$el.clientWidth / (this.gridData.gridSizeX.length + 1)) * (100 - basePercentageQualifier - dynamicPercentageQualifier * heatmapNormalizeRatio) / 100;
    },
    dotHoverFunction: function dotHoverFunction(dot) {
      return dot.title;
    }
  }
};