export default {
  name: 'StaticTabItem',
  introduction: 'Static tabs',
  description: 'This is a static tab item. Use it inside the static tab component',
  token: '<static-tab-item>This is a tab</static-tab-item>',
  props: {
    to: {
      required: true,
      type: String,
      note: 'the url that the tab needs to trigger'
    },
    active: {
      required: false,
      type: Boolean,
      default: false,
      note: 'this bool sets the active class if needed'
    }
  },
  emits: ['click'],
  methods: {
    setActive: function setActive(event) {
      //getting tab item siblings
      var siblings = event.target.parentNode.parentNode.childNodes;
      siblings.forEach(function (sibling) {
        if (sibling.firstChild !== null) {
          sibling.firstChild.classList.remove('link-active');
        }
      });
      event.target.classList.add('link-active');

      //emit event outside if custom functionality is necessary
      this.$emit('click', event);
    }
  }
};