function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { TableCellsTypes } from '@/constants/TableCellsTypes';
import { SortDirection } from '@/constants/SortDirection';
import { compareStrings, compareDates } from '@/utils/compare';
import { formatToUtcDateTime } from '@/utils/date';
var headers = {
  text: {
    label: 'Text example',
    fieldName: 'text',
    fieldType: TableCellsTypes.TEXT,
    sortable: true,
    sorted: true,
    sortDirection: SortDirection.ASC,
    filters: []
  },
  name: {
    label: 'Title example',
    fieldName: 'name',
    fieldType: TableCellsTypes.TITLE,
    sortable: true,
    sorted: false,
    filters: [],
    sticky: true
  },
  deadline: {
    label: 'Deadline example',
    fieldName: 'deadline',
    fieldType: TableCellsTypes.DEADLINE_DATE,
    sortable: true,
    sorted: false,
    filterName: 'deadline',
    datesFilter: {
      before: '',
      after: ''
    },
    filters: []
  },
  date: {
    label: 'Date example',
    fieldName: 'date',
    fieldType: TableCellsTypes.DATE,
    sortable: true,
    sorted: false,
    filterName: 'date',
    datesFilter: {
      before: '',
      after: ''
    },
    filters: []
  },
  traffic: {
    label: 'Traffic light example',
    fieldName: 'traffic',
    fieldType: TableCellsTypes.TRAFFIC_LIGHT,
    sortable: true,
    sorted: false,
    filters: [{
      label: 'Red',
      value: '1',
      active: false
    }, {
      label: 'Yellow',
      value: '2',
      active: false
    }, {
      label: 'Green',
      value: '3',
      active: false
    }]
  },
  document: {
    label: 'Document example',
    fieldName: 'document',
    fieldType: TableCellsTypes.DOCUMENT,
    sortable: false,
    sorted: false,
    filters: []
  },
  html: {
    label: 'Html example',
    fieldName: 'html',
    fieldType: TableCellsTypes.HTML,
    sortable: true,
    sorted: false,
    filters: []
  },
  links: {
    label: 'Links example',
    fieldName: 'links',
    fieldType: TableCellsTypes.LINKS,
    sortable: true,
    sorted: false,
    filters: [{
      label: 'Initiative 1',
      value: '43',
      active: false
    }, {
      label: 'Initiative 2',
      value: '89',
      active: false
    }]
  },
  attachments: {
    label: 'Attachments example',
    fieldName: 'attachments',
    fieldType: TableCellsTypes.ATTACHMENTS,
    sortable: true,
    sorted: false,
    filters: []
  },
  list: {
    label: 'List example',
    fieldName: 'list',
    fieldType: TableCellsTypes.LIST,
    sortable: true,
    sorted: false,
    filters: [{
      label: 'list item 1',
      value: 'list item 1',
      active: false
    }, {
      label: 'list item 2',
      value: 'list item 2',
      active: false
    }, {
      label: 'list item 3',
      value: 'list item 3',
      active: false
    }]
  },
  bool: {
    label: 'Bool example',
    fieldName: 'bool',
    fieldType: TableCellsTypes.BOOL,
    sortable: false,
    sorted: false,
    filters: []
  },
  deleteButton: {
    label: 'Delete example',
    fieldName: 'deleteButton',
    fieldType: TableCellsTypes.DELETE_BUTTON,
    sortable: false,
    sorted: false,
    filters: []
  },
  selected: {
    label: 'Starred',
    fieldName: 'selected',
    fieldType: TableCellsTypes.STAR,
    sortable: true,
    sorted: false,
    filters: []
  },
  svgIcon: {
    label: 'Svg icon',
    fieldName: 'svgIcon',
    fieldType: TableCellsTypes.ICON,
    sortable: false,
    sorted: false,
    filters: []
  },
  featherIcon: {
    label: 'Feather icon',
    fieldName: 'featherIcon',
    fieldType: TableCellsTypes.ICON,
    sortable: false,
    sorted: false,
    filters: []
  }
};
export var getData = function getData(query) {
  var params = query.endsWith('&') ? parseQuery(query.slice(0, -1)) : parseQuery(query);
  var itemsNumber = params.limit === 'all' ? 20 : params.limit || 10;
  var data = [];
  var localHeaders = JSON.parse(JSON.stringify(headers));
  for (var i = 0; i < itemsNumber; i++) {
    data.push(generateItem(i));
  }
  if (params.sortField && params.sortOrder) {
    var prop = params.sortField;
    var reversed = params.sortOrder === SortDirection.ASC;
    switch (prop) {
      case 'deadline':
      case 'date':
        {
          data.sort(function (a, b) {
            return compareDates(a[prop].content, b[prop].content, reversed);
          });
          break;
        }
      case 'traffic':
        {
          data.sort(function (a, b) {
            return (a[prop].content - b[prop].content) * (reversed ? 1 : -1);
          });
          break;
        }
      default:
        {
          data.sort(function (a, b) {
            return compareStrings(a[prop].content, b[prop].content, reversed);
          });
        }
    }
    localHeaders.text.sorted = false;
    localHeaders[prop].sorted = true;
    localHeaders[prop].sortDirection = params.sortOrder;
  }
  if (params.filters) {
    var _loop = function _loop(filterKey) {
      var elements = params.filters[filterKey];
      elements.forEach(function (filterValue) {
        localHeaders[filterKey].filters = localHeaders[filterKey].filters.map(function (initialFilter) {
          if (initialFilter.value == filterValue) {
            initialFilter.active = true;
          }
          return initialFilter;
        });
      });
    };
    for (var filterKey in params.filters) {
      _loop(filterKey);
    }
  }
  return {
    data: data,
    headers: localHeaders,
    options: {
      count: 300,
      limit: itemsNumber,
      offset: params.start
    }
  };
};
var generateItem = function generateItem(idx) {
  var generatedObj = {
    text: {
      type: TableCellsTypes.TEXT,
      content: 'Text ' + idx
    },
    name: {
      type: TableCellsTypes.TITLE,
      content: 'Name' + idx
    },
    deadline: {
      type: TableCellsTypes.DEADLINE_DATE,
      content: randomDate(),
      color: randomColor()
    },
    date: {
      type: TableCellsTypes.DATE,
      content: randomDate()
    },
    traffic: {
      type: TableCellsTypes.TRAFFIC_LIGHT,
      content: idx % 3 + 1
    },
    document: {
      type: TableCellsTypes.DOCUMENT,
      docType: idx % 4
    },
    html: {
      type: TableCellsTypes.HTML,
      content: '<b><i>Hello world</i></b> ' + idx
    },
    links: {
      type: TableCellsTypes.LINKS,
      content: [{
        id: 43,
        objType: 'execution-initiative',
        title: 'Initiative 1',
        url: '/actions/i/-/1-initiativ/43'
      }, {
        id: 89,
        objType: 'execution-initiative',
        title: 'Initiative 2',
        url: '/actions/i/-/2-3-processor-initiative/89'
      }]
    },
    attachments: {
      type: TableCellsTypes.ATTACHMENTS,
      content: [{
        title: 'Google',
        url: 'https://google.com'
      }, {
        title: 'Youtube',
        url: 'https://www.youtube.com/watch?v=EGLxVI84evM&ab_channel=JAZZ%26BLUES'
      }, {
        title: 'Test',
        url: 'https://test.com'
      }]
    },
    list: {
      type: TableCellsTypes.LIST,
      content: ['list item 1', 'list item 2', 'list item 3']
    },
    deleteButton: {
      type: TableCellsTypes.DELETE_BUTTON,
      deletable: true,
      id: 1 + idx
    },
    bool: {
      type: TableCellsTypes.BOOL,
      content: idx % 2 === 0
    },
    selected: {
      type: TableCellsTypes.STAR,
      content: false,
      id: 1 + idx
    },
    svgIcon: _objectSpread({
      type: TableCellsTypes.ICON,
      id: 1 + idx,
      content: "svg icon ".concat(1 + idx)
    }, svgIcons[Math.abs(idx % 3)]),
    featherIcon: _objectSpread({
      type: TableCellsTypes.ICON,
      id: 1 + idx,
      content: "feather icon ".concat(1 + idx)
    }, featherIcons[Math.abs(idx % 4)])
  };
  return generatedObj;
};
var randomDate = function randomDate() {
  var start = new Date(2012, 0, 1);
  var end = new Date();
  return formatToUtcDateTime(new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())));
};
var randomColor = function randomColor() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
};
var parseQuery = function parseQuery(query) {
  var result = JSON.parse('{"' + decodeURI(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
  result.filters = {};
  var queryParams = query.split('&');
  queryParams.forEach(function (queryParam) {
    var match = _toConsumableArray(queryParam.matchAll(/filters\[(\w+)\]\[\]=(.+)/g))[0];
    if (!match) return;
    var filterKey = match[1];
    var filterValue = match[2];
    if (!result.filters[filterKey]) {
      result.filters[filterKey] = [];
    }
    result.filters[filterKey].push(filterValue);
  });
  return result;
};
var svgIcons = [{
  svgName: 'icon_user',
  color: '#012454'
}, {
  svgName: 'icon_user',
  color: '#fff',
  backgroundColor: '#012454'
}, {
  svgName: 'icon_noAccess',
  color: '#e53e3e'
}];
var featherIcons = [{
  name: 'check-circle',
  class: 'text-green-150'
}, {
  name: 'x-circle',
  class: 'text-red-50'
}, {
  name: 'bell',
  class: 'text-blue-510'
}, {
  name: 'bell',
  class: 'text-purple-450 fill-current'
}];