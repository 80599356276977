export default {
  name: 'RismaProgressBar',
  introduction: 'Simple progress bar',
  description: 'Simple progress bar that can be included in CardCompliance/CardExecution and etc.',
  token: '<risma-progress-bar :value="30" total="200"/>',
  props: {
    value: {
      required: true,
      type: Number,
      note: 'Value in percentage (0-100)'
    },
    label: {
      required: false,
      type: String,
      default: '',
      note: 'Text label'
    }
  }
};