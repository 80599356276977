function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import { formatNodes } from '@/utils/format/Compliance';
export default {
  name: 'ProcessLibraryChoice',
  introduction: 'ProcessLibraryChoice is a RismaSelect component',
  description: 'Ability to select multiple values from a drop-down to get links with other nodes',
  components: {
    RismaSelect: RismaSelect
  },
  props: {
    nodeElement: {
      required: true,
      type: [Object, Array],
      note: 'A list of all nodes',
      default: function _default() {
        return {};
      }
    },
    selected: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'a list of selected nodes'
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
      note: 'is be read-only'
    }
  },
  emits: ['forceUpdate', 'answered'],
  data: function data() {
    return {
      selectedOptions: [],
      values: [],
      onlyMyValues: [],
      translate: getTranslate['ProcessLibraryChoice']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    onlyMyProccessLibraryChoices: function onlyMyProccessLibraryChoices() {
      return this.getSettingValue('feature.pl_nodes_limit_access_in_question');
    },
    myProcessLibraryIds: function myProcessLibraryIds() {
      return this.onlyMyValues.map(function (node) {
        return node.id;
      });
    },
    selectedByOthers: function selectedByOthers() {
      var _this = this;
      if (!this.onlyMyProccessLibraryChoices) return [];
      return this.selected.filter(function (item) {
        return !_this.myProcessLibraryIds.includes(item.id);
      });
    },
    selectedByOthersDisplay: function selectedByOthersDisplay() {
      return this.selectedByOthers.map(function (item) {
        return item.title;
      }).join(', ');
    },
    nodesOptions: function nodesOptions() {
      var _this2 = this;
      if (!this.onlyMyProccessLibraryChoices) return this.values;
      return this.values.filter(function (item) {
        return _this2.myProcessLibraryIds.includes(item.id);
      });
    },
    nodesSelected: function nodesSelected() {
      var _this3 = this;
      if (!this.onlyMyProccessLibraryChoices) return this.selectedOptions;
      return this.selectedOptions.filter(function (item) {
        return _this3.myProcessLibraryIds.includes(item.id);
      });
    }
  }),
  created: function created() {
    this.getAllNodesInValues(this.nodeElement);
    this.choosePreselected();
  },
  methods: {
    //to get throught all children from Policies Root Node and dont push Root node title
    getAllNodesInValues: function getAllNodesInValues(nodeObject) {
      var _this4 = this;
      if (!nodeObject.children) return;
      formatNodes(nodeObject.children).forEach(function (element) {
        _this4.getInsideObjectNodes(element);
      });
    },
    getInsideObjectNodes: function getInsideObjectNodes(nodeObject) {
      var _this5 = this;
      this.pushElement(nodeObject.formattedTitle, nodeObject.id, this.onlyMyProccessLibraryChoices && nodeObject.checkaccess);
      if (nodeObject.children) {
        nodeObject.children.forEach(function (element) {
          _this5.getInsideObjectNodes(element);
        });
      }
    },
    pushElement: function pushElement(option, id, access) {
      var object = {
        id: id,
        option: option
      };
      if (access) {
        this.onlyMyValues.push(object);
      }
      this.values.push(object);
    },
    emitChanges: function emitChanges(newVal) {
      var node = {};
      var newValWithSelectedByOthers = [].concat(_toConsumableArray(newVal), _toConsumableArray(this.selectedByOthers));
      if (newValWithSelectedByOthers !== null) {
        newValWithSelectedByOthers.forEach(function (v) {
          node[v.id] = v.option;
        });
      }
      this.$emit('answered', node);
    },
    choosePreselected: function choosePreselected() {
      var selected = this.selected.map(function (s) {
        return +s.id;
      });
      this.selectedOptions = this.values.filter(function (v) {
        return selected.includes(+v.id);
      });
    }
  }
};