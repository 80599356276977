var OutsourcingTypes = function OutsourcingTypes($trans) {
  return [{
    key: 'outsourcingDueDilligence',
    title: $trans('Due dilligence')
  }, {
    key: 'outsourcingRiskAssessment',
    title: $trans('Risk assessment')
  }, {
    key: 'outsourcingFunctionDescription',
    title: $trans('Function description')
  }, {
    key: 'outsourcingPreliminaryAnalysis',
    title: $trans('Preliminary analysis')
  }];
};
export default OutsourcingTypes;