import DpmAuditQuestionnaire from '@/components/Organisms/DpmAuditQuestionnaire';
import { getById } from '@/api/compliance/node';
export default {
  components: {
    DpmAuditQuestionnaire: DpmAuditQuestionnaire
  },
  data: function data() {
    return {
      questionnaire: null,
      testRequest: {
        expires: '2020-01-01 00:00:00'
      },
      testProcessor: {
        name: 'Test processor name'
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    getById(1, 1).then(function (response) {
      return _this.questionnaire = response;
    });
  },
  methods: {
    something: function something(data) {
      alert(JSON.stringify(data));
    }
  }
};