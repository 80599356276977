export default {
  name: 'Dot',
  props: {
    dot: {
      note: 'Contains the whole DOT Object',
      type: Object,
      required: true
    },
    colorCodes: {
      note: 'Contains the color codes for every status',
      type: Object,
      required: true
    },
    dotLabelHoverFunction: {
      type: Function,
      default: null,
      note: 'Function to generate dot label on hover'
    }
  },
  data: function data() {
    return {
      isHovered: false
    };
  },
  computed: {
    setColor: function setColor() {
      return this.colorCodes[this.dot.color] + ';';
    },
    setSize: function setSize() {
      var _this$dot;
      if (!((_this$dot = this.dot) !== null && _this$dot !== void 0 && _this$dot.id)) return '';
      switch (this.dot.id.toString().length) {
        case 5:
          return 'font-size: .5rem; line-height: 265%;';
        case 4:
          return 'font-size: .6rem; line-height: inherit;';
        case 3:
          return 'font-size: .7rem; line-height: 150%;';
        default:
          return 'font-size: .9rem; line-height: 125%;';
      }
    },
    setStyle: function setStyle() {
      return "".concat(this.setSize, " ").concat(this.setColor);
    },
    labelOnHover: function labelOnHover() {
      return this.dotLabelHoverFunction ? this.dotLabelHoverFunction(this.dot) : "".concat(this.dot.id, " ").concat(this.dot.title);
    }
  },
  methods: {
    onMouseOver: function onMouseOver() {
      this.isHovered = true;
    },
    onMouseLeave: function onMouseLeave() {
      this.isHovered = false;
    }
  }
};