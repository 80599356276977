function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { Http } from '../index';
import * as HttpUtils from '../httpUtils';
import { getPromiseWithCacheTimeout, flushCache } from '@/api/HttpCache';
import { MODULES } from '@/constants/modules';
import { flushSystemsCache } from '@/api/risma/data';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';

/**
 * Create a information asset entry
 * @param type
 * @param data
 * @returns {Promise.<TResult>}
 */
export function create(type, data) {
  return Http.post("compliance/informationAssets/".concat(type), data).then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}

/**
 * Get a single InformationAsset by type and id
 * @param type
 * @param id
 * @param cacheTimeInSeconds
 */
export function getSingleByType(type, id) {
  var cacheTimeInSeconds = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 5;
  return getPromiseWithCacheTimeout("compliance/informationAssets/".concat(type, "/").concat(id), cacheTimeInSeconds).catch(function (err) {
    if (err && err.response && err.response.data && err.response.data.error === 'No nodes found') {
      return err.response.data;
    } else {
      return HttpUtils.throwError(err);
    }
  });
}

/**
 * Get all information asset entries
 * @returns {Promise.<TResult>}
 */
export function getAll() {
  var url = 'compliance/informationAssets';
  return HttpUtils.handleRequestResponse(Http.get(url));
}
export function getMy() {
  var url = 'compliance/informationAssets/my';
  return HttpUtils.handleRequestResponse(Http.get(url));
}

/**
 * Get all processors and sub processors
 * @returns {Promise.<TResult>}
 */
export function getAllProcessors() {
  return HttpUtils.handleRequestResponse(Http.get('compliance/informationAssets/processors'));
}
export function getAllLightWeightProcessors() {
  var url = 'data/compliance/informationAssets/processors';
  return HttpUtils.handleRequestResponse(Http.get(url));
}

/**
 * Get all controllers and sub controllers
 * @returns {Promise.<TResult>}
 */
export function getAllControllers() {
  return HttpUtils.handleRequestResponse(Http.get('compliance/informationAssets/controllers'));
}
export function getAllCustomInformationAssets() {
  var url = 'compliance/informationAssets/informationAssets';
  return HttpUtils.handleRequestResponse(Http.get(url));
}

/**
 * Delete a information asset entry
 * @param type
 * @param id
 * @returns {Promise.<TResult>}
 */
export function destroy(type, id) {
  return Http.delete("compliance/informationAssets/".concat(type, "/").concat(id)).then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}

/**
 * Update a information asset entry by type and id
 * @param type
 * @param id
 * @param data
 * @returns {Promise.<TResult>}
 */
export function updateByType(type, id, data) {
  if (type === AssetsTypes.SYSTEMS) flushSystemsCache();
  return HttpUtils.handleRequestResponse(Http.patch("compliance/informationAssets/".concat(type, "/").concat(id), data));
}
export function getAllByType(type) {
  var parameters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var url = "compliance/informationAssets/".concat(type).concat(parameters);
  return getPromiseWithCacheTimeout(url);
}
export function updateIAQuestionByTypeIdAndQuestionId(type, id, questionId, data) {
  flushCache("compliance/informationAssets/".concat(type, "/").concat(id));
  return HttpUtils.handleRequestResponse(Http.patch("compliance/informationAssets/".concat(type, "/").concat(id, "/questionanswers/").concat(questionId), data));
}
export function getIAQuestionnaireByType(type, id) {
  return HttpUtils.handleRequestResponse(Http.get("compliance/informationAssets/".concat(type, "/").concat(id, "/questionanswers")));
}
export function getIAGapanalysisByType(type, id) {
  return HttpUtils.handleRequestResponse(Http.get("compliance/informationAssets/".concat(type, "/").concat(id, "/gapanalysis")));
}
export function updateIAGapanalysisByType(type, id, data) {
  return HttpUtils.handleRequestResponse(Http.patch("compliance/informationAssets/".concat(type, "/").concat(id, "/gapanalysis"), data));
}
export function getRiskAssessmentIdsList(type, params) {
  return getAllByType(type, params).then(function (_ref) {
    var list = _ref.list;
    var ids = [];
    list.forEach(function (system) {
      if (system.links[MODULES.RISK_ASSESSMENT]) {
        ids.push.apply(ids, _toConsumableArray(system.links[MODULES.RISK_ASSESSMENT]));
      }
    });
    return ids;
  });
}