function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Colors } from '@/Colors';
import InlineSelect from '@/components/Atoms/Inputs/InlineSelect';
export default {
  name: 'SelectableLegend',
  token: "<selectable-legend\n                v-model=\"adjusted by legend dataset\"\n                :applied-colors=\"colorts from scoped slot #legend from the chart\"\n                :total-dataset=\"[{label: 'dataset1', data: [1, 3]}, [{label: 'dataset2', data: [1,5,8]}]\"\n            />",
  introduction: 'Legend for charts',
  description: "Selectable legend for charts.\n    You can pass array of objects and arrays. Arrays will be considered as selectable option.\n    ",
  components: {
    InlineSelect: InlineSelect
  },
  props: {
    appliedColors: {
      type: Array,
      required: false,
      default: function _default() {
        return [Colors.notSet];
      }
    },
    modelValue: {
      type: Array,
      required: true
    },
    totalDataset: {
      type: Array,
      required: true,
      note: 'Arrays of objects or arrays. Objects will be standalone options, arrays will be selectable'
    }
  },
  emits: ['update:modelValue'],
  data: function data() {
    return {
      toggledDatasets: new Array(this.totalDataset.length).fill(true),
      selectedMultipleDatasets: new Array(this.totalDataset.length).fill(0)
    };
  },
  computed: {
    optionStyles: function optionStyles() {
      return {
        fontWeight: 700,
        textTransform: 'uppercase',
        color: Colors.system.darkGray
      };
    },
    combinedDataset: function combinedDataset() {
      var _this = this;
      return this.totalDataset.map(function (dataset, idx) {
        var copiedDataset = _objectSpread({}, dataset);
        if (Array.isArray(dataset)) {
          copiedDataset = dataset[_this.selectedMultipleDatasets[idx]];
        }
        if (!_this.toggledDatasets[idx]) {
          copiedDataset = _objectSpread(_objectSpread({}, copiedDataset), {}, {
            data: []
          });
        }
        return copiedDataset;
      });
    }
  },
  watch: {
    combinedDataset: {
      handler: function handler() {
        this.$emit('update:modelValue', this.combinedDataset);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    toggleDataset: function toggleDataset(idx) {
      var reversedValue = !this.toggledDatasets[idx];
      this.toggledDatasets.splice(idx, 1, reversedValue);
    },
    selectMultipleDataset: function selectMultipleDataset(value, idx) {
      this.selectedMultipleDatasets.splice(idx, 1, +value);
    }
  }
};