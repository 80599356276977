import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RiskIndicator from '@/components/Atoms/RiskIndicator';
export default {
  name: 'RiskRow',
  introduction: 'A row of risk assessment',
  description: "this Molecule is solely used for the RiskModule organism.\n        This molecule will display one row of a risk assessment",
  token: '<risk-row />',
  components: {
    SingleSelect: SingleSelect,
    RiskIndicator: RiskIndicator
  },
  props: {
    impact: {
      required: false,
      default: 0,
      type: Number,
      note: 'the impact value'
    },
    likelihood: {
      required: false,
      default: 0,
      type: Number,
      note: 'the likelihood value'
    },
    tense: {
      required: false,
      type: String,
      default: '',
      note: 'A string to put in parenthesis after label'
    },
    riskLevel: {
      required: false,
      type: Number,
      default: 0,
      note: 'A number indicating the current risk level'
    }
  },
  data: function data() {
    return {
      riskValues: [{
        label: '0',
        value: '0'
      }, {
        label: '1',
        value: '1'
      }, {
        label: '2',
        value: '2'
      }, {
        label: '3',
        value: '3'
      }, {
        label: '4',
        value: '4'
      }, {
        label: '5',
        value: '5'
      }],
      translate: {
        likelihood: this.$trans('Likelihood'),
        impact: this.$trans('Impact'),
        risk: this.$trans('Risk')
      }
    };
  },
  computed: {
    selectedImpact: function selectedImpact() {
      return this.impact.toString();
    },
    selectedLikelihood: function selectedLikelihood() {
      return this.likelihood.toString();
    }
  },
  methods: {
    emitSelect: function emitSelect(eventType, event) {
      if (event === null) {
        event = '0';
      }
      this.$emit(eventType, event);
    }
  }
};