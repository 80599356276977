import AdminCustomFieldsWell from '@/components/Organisms/Admin/AdminCustomFieldsWell';
import AdminActivityTypesWell from '@/components/Organisms/Admin/AdminActivityTypesWell';
export default {
  name: 'AdminActivityTypes',
  description: 'Page with activity types and custom fields sections',
  token: '<admin-activity-types-custom-fields />',
  components: {
    AdminCustomFieldsWell: AdminCustomFieldsWell,
    AdminActivityTypesWell: AdminActivityTypesWell
  }
};