import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function createReview(module, moduleId, review) {
  return handleRequestResponse(Http.put("/reviews/".concat(module, "/").concat(moduleId), review));
}
export function updateReview(module, moduleId, review) {
  return handleRequestResponse(Http.patch("/reviews/".concat(module, "/").concat(moduleId), review));
}
export function getReview(module, moduleId) {
  return handleRequestResponse(Http.get("/reviews/".concat(module, "/").concat(moduleId)));
}