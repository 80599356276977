function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { getIAReport } from '@/api/compliance/reports/questionnaire';
import { getAllQuestions } from '@/api/compliance/questionnaire';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Article30Table from '@/components/Pages/Compliance/Reports/PredefinedReports/article30/Table';
import QuestionSelection from '@/components/Pages/Compliance/Reports/PredefinedReports/QuestionSelection';
import IAQuestionnaireSelection from './IAQuestionnaireSelection';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { mapQuestionAnswers } from '@/components/Pages/Compliance/Reports/PredefinedReports/utils';
import { getAnsweredQuestionsByActivity } from '@/utils/reports/questionnaire';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export default {
  name: 'IAQuestionnaireReport',
  components: {
    Article30Table: Article30Table,
    IAQuestionnaireSelection: IAQuestionnaireSelection,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    QuestionSelection: QuestionSelection,
    RismaButton: RismaButton
  },
  data: function data() {
    return {
      loading: false,
      showReport: false,
      rows: [],
      assets: [],
      selectedQuestions: [],
      questions: [],
      selectedAssets: [],
      error: '',
      report: null,
      selectedAssetType: null,
      selectedQuestionnaireId: null,
      reportTable: 'article30-table',
      isAnsweredOnly: false,
      noAvailableAssets: false,
      localStateName: 'ia_questionnaire_report_table',
      translate: getTranslate['IAQuestionnaireReport']()
    };
  },
  computed: {
    nameColumnTitle: function nameColumnTitle() {
      var _AssetsTypes$CONTROLL;
      return (_AssetsTypes$CONTROLL = {}, _defineProperty(_AssetsTypes$CONTROLL, AssetsTypes.CONTROLLERS, this.translate.dataController), _defineProperty(_AssetsTypes$CONTROLL, AssetsTypes.PROCESSORS, this.translate.dataProcessor), _defineProperty(_AssetsTypes$CONTROLL, AssetsTypes.SYSTEMS, this.translate.system), _defineProperty(_AssetsTypes$CONTROLL, AssetsTypes.INFORMATION_ASSETS_API, this.translate.informationAsset), _AssetsTypes$CONTROLL)[this.selectedAssetType];
    }
  },
  methods: {
    selectionChanged: function selectionChanged(selectedQuestionnaireId, assets, selectedAssetType) {
      this.selectedAssetType = selectedAssetType;
      this.selectedQuestionnaireId = selectedQuestionnaireId;
      this.assets = assets;
      this.selectedAssets = [];
      this.selectedQuestions = [];
      this.showReport = false;
      this.isAnsweredOnly = false;
      selectedQuestionnaireId && this.handleFetchQuestions();
    },
    assetsSelected: function assetsSelected(selectedAssets) {
      this.noAvailableAssets = !selectedAssets;
      this.selectedAssets = selectedAssets ? selectedAssets.map(function (n) {
        return n.id;
      }) : [];
      this.showReport = false;
    },
    handleFetchQuestions: function handleFetchQuestions() {
      var _this = this;
      this.loading = true;
      return getAllQuestions(this.selectedQuestionnaireId).then(function (response) {
        _this.questions = response.questions;
        _this.loading = false;
        _this.$forceUpdate();
      }).then(function () {
        _this.$refs.questionSelection && _this.$refs.questionSelection.selectDefault();
      });
    },
    questionsSelected: function questionsSelected(selectedQuestions) {
      this.selectedQuestions = selectedQuestions;
    },
    handleCreateReport: function handleCreateReport() {
      var _this2 = this;
      if (this.noAvailableAssets) {
        this.showReport = true;
        return;
      }
      this.showReport = false;
      this.loading = true;
      this.error = '';
      return getIAReport(this.selectedQuestionnaireId, this.selectedQuestions, this.selectedAssets).then(function (resp) {
        var _mapping;
        var mapping = (_mapping = {}, _defineProperty(_mapping, AssetsTypes.CONTROLLERS, 'controller_id'), _defineProperty(_mapping, AssetsTypes.PROCESSORS, 'processor_id'), _defineProperty(_mapping, AssetsTypes.SYSTEMS, 'system_id'), _defineProperty(_mapping, AssetsTypes.INFORMATION_ASSETS_API, 'information_asset_id'), _mapping);
        var questions = _this2.isAnsweredOnly ? getAnsweredQuestionsByActivity(resp.questions, _this2.selectedAssets, mapping[_this2.selectedAssetType]) : resp.questions;
        _this2.rows = [];
        _this2.assets.filter(function (asset) {
          var _asset$questionnaires;
          return ((_asset$questionnaires = asset.questionnaires) === null || _asset$questionnaires === void 0 ? void 0 : _asset$questionnaires.some(function (item) {
            return item.questionnaireId === _this2.selectedQuestionnaireId;
          })) && (!_this2.selectedAssets.length || _this2.selectedAssets.indexOf(asset.id) !== -1);
        }).forEach(function (asset) {
          var parentsInfo = asset.parents && asset.parents.map(function (id) {
            return _this2.assets.find(function (item) {
              return item.id === id;
            });
          });
          var row = {
            name: asset.name || asset.title,
            parents: parentsInfo,
            id: asset.id,
            url: asset.url,
            objType: asset.objType,
            cells: mapQuestionAnswers(questions, resp.informationAssets, resp.answers[asset.id], resp.files, resp.processTreeNodes)
          };
          _this2.rows.push(row);
        });
        _this2.noAvailableAssets = !_this2.rows.length;
        _this2.error = '';
        _this2.showReport = true;
        _this2.loading = false;
      }).catch(function (resp) {
        _this2.showReport = false;
        _this2.loading = false;
        if (resp.response.error !== undefined) {
          _this2.error = _this2.$trans(resp.response.error);
        }
      });
    },
    setAnsweredOnly: function setAnsweredOnly(value) {
      this.isAnsweredOnly = value;
    }
  }
};