function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { create } from '@/api/compliance/informationAssets';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import SolutionsSelector from '@/components/Molecules/SolutionsSelector';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Notification from '@/components/Molecules/Notification';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { sortAlphabetic } from '@/utils/sort';
export default {
  name: 'InformationAssetCreationModal',
  description: 'Component for creating information assets',
  token: '<information-asset-creation-modal/>',
  components: {
    Modal: Modal,
    InputField: InputField,
    SolutionsSelector: SolutionsSelector,
    SingleSelect: SingleSelect,
    Notification: Notification
  },
  props: {
    type: {
      type: [Number, String],
      required: true,
      note: 'The type of information asset. Can be either'
    },
    activityTypeId: {
      type: Number,
      required: false,
      default: null,
      note: 'Activity type id'
    },
    setCurrentUserAsOwner: {
      type: Boolean,
      required: false,
      default: false,
      note: 'User must be set as responsible on new assets'
    },
    dataProcessors: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of data processors'
    },
    defaultQuestionnaireId: {
      type: [String, Number],
      required: false,
      default: '',
      note: 'Custom IA default quesitonnaire id'
    }
  },
  emits: ['dismiss', 'created'],
  data: function data() {
    var _assetTypeNames, _settingValues;
    return {
      AssetsTypes: AssetsTypes,
      newInformationAsset: {
        title: '',
        solutions: [],
        parentId: 0
      },
      assetTypeNames: (_assetTypeNames = {}, _defineProperty(_assetTypeNames, AssetsTypes.CONTROLLERS, 'controller'), _defineProperty(_assetTypeNames, AssetsTypes.PROCESSORS, 'processor'), _defineProperty(_assetTypeNames, AssetsTypes.SYSTEMS, 'system'), _defineProperty(_assetTypeNames, AssetsTypes.INFORMATION_ASSETS, 'information asset'), _assetTypeNames),
      settingValues: (_settingValues = {}, _defineProperty(_settingValues, AssetsTypes.SYSTEMS, 'feature.systems_default_questionnaire_id'), _defineProperty(_settingValues, AssetsTypes.PROCESSORS, 'feature.processors_default_questionnaire_id'), _defineProperty(_settingValues, AssetsTypes.CONTROLLERS, 'feature.controllers_default_questionnaire_id'), _settingValues),
      errorNotification: '',
      timer: null,
      translate: getTranslate['InformationAssetCreationModal'](),
      isCreating: false,
      isRismaSelectOpen: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingsValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    filteredProcessors: function filteredProcessors() {
      var result = this.dataProcessors.filter(function (item) {
        var _item$parents;
        return item.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR && !((_item$parents = item.parents) !== null && _item$parents !== void 0 && _item$parents.length);
      });
      sortAlphabetic(result, 'name');
      return result.map(function (item) {
        return {
          value: item.id,
          label: item.name
        };
      });
    },
    modalHeader: function modalHeader() {
      var type = this.assetTypeNames[this.type] || this.assetTypeNames[AssetsTypes.INFORMATION_ASSETS];
      return "".concat(this.translate.new, " ").concat(this.$trans(type));
    },
    isCustomInformationAsset: function isCustomInformationAsset() {
      return ![AssetsTypes.CONTROLLERS, AssetsTypes.PROCESSORS, AssetsTypes.SYSTEMS].includes(this.type);
    },
    defaultActivityTypeByModule: function defaultActivityTypeByModule() {
      var _ref;
      return _ref = {}, _defineProperty(_ref, AssetsTypes.CONTROLLERS, this.getSettingsValue('feature.data_controllers_default_activity_type_id')), _defineProperty(_ref, AssetsTypes.PROCESSORS, this.getSettingsValue('feature.data_processors_default_activity_type_id')), _defineProperty(_ref, AssetsTypes.SYSTEMS, this.getSettingsValue('feature.systems_default_activity_type_id')), _ref;
    }
  }),
  methods: {
    onCreate: function onCreate() {
      var _this = this;
      if (!this.validateNewInformationAsset()) return;
      this.isCreating = true;
      var data = this.getData();
      var type = this.isCustomInformationAsset ? 'informationAssets' : this.type;
      create(type, data).then(function (_ref2) {
        var data = _ref2.data;
        _this.$emit('created', data);
      }).catch(function (error) {
        var _error$response;
        if ((_error$response = error.response) !== null && _error$response !== void 0 && _error$response.error) {
          var _error$response2;
          _this.errorNotification = _this.$trans(((_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.error) || error);
          _this.timer && clearTimeout(_this.timer);
          _this.timer = setTimeout(function () {
            _this.errorNotification = '';
          }, _this.$debounceTimeout);
        }
      }).finally(function () {
        _this.isCreating = false;
      });
    },
    validateNewInformationAsset: function validateNewInformationAsset() {
      if (!this.newInformationAsset.title.trim()) {
        this.errorNotification = this.translate.titleCannotBeEmpty;
        return false;
      }
      this.errorNotification = '';
      return true;
    },
    getData: function getData() {
      var _this$newInformationA;
      var responsibleUserIds = this.setCurrentUserAsOwner ? [this.currentUser.id] : [];
      var solutionIds = (_this$newInformationA = this.newInformationAsset.solutions) === null || _this$newInformationA === void 0 ? void 0 : _this$newInformationA.map(function (s) {
        return s.id;
      });
      var data = {
        responsibleUserIds: responsibleUserIds,
        solutionIds: solutionIds,
        name: this.newInformationAsset.title
      };
      var defaultActivityTypeId = this.defaultActivityTypeByModule[this.type];
      if (defaultActivityTypeId) {
        data.activityTypeId = defaultActivityTypeId;
      }
      var questionnaireId = this.isCustomInformationAsset ? this.defaultQuestionnaireId : this.getSettingsValue(this.settingValues[this.type]);
      if (questionnaireId) {
        var param = this.type === AssetsTypes.PROCESSORS ? 'questionnaire_id' : 'questionnaireId';
        data[param] = questionnaireId;
      }
      if (this.isCustomInformationAsset) {
        data.activityTypeId = this.activityTypeId;
        data.title = this.newInformationAsset.title;
        data.questionnaires = questionnaireId ? [{
          questionnaireId: questionnaireId
        }] : [];
      } else {
        data.parentId = this.newInformationAsset.parentId;
        data.name = this.newInformationAsset.title;
      }
      return data;
    }
  }
};