import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import DepHeader from '@/components/Organisms/DepHeader/DepHeader';
export default {
  name: '',
  components: {
    DepHeader: DepHeader,
    RismaTitle: RismaTitle
  },
  data: function data() {
    return {
      id: '44',
      title: 'Missing access restriction',
      tabs: [{
        url: '/dev/kitchensink/atoms',
        label: this.$trans('Home'),
        icon: 'home'
      }, {
        url: '/dev/kitchensink/organisms/dep-header',
        label: this.$trans('Questionnaire'),
        icon: 'help-circle'
      }, {
        url: '/dev/kitchensink/atoms',
        label: this.$trans('History'),
        icon: 'clock'
      }, {
        url: '/dev/kitchensink/atoms',
        label: this.$trans('Assessment'),
        icon: 'sliders'
      }, {
        url: '/dev/kitchensink/atoms',
        label: this.$trans('Gap analysis'),
        icon: 'crosshair'
      }, {
        url: '/dev/kitchensink/atoms',
        label: this.$trans('Data flow'),
        icon: 'git-pull-request',
        style: 'transform scale-x-100 -scale-y-100 rotate-90'
      }, {
        url: '/dev/kitchensink/atoms',
        label: this.$trans('Links'),
        icon: 'link'
      }, {
        url: '/dev/kitchensink/atoms',
        label: this.$trans('Change log'),
        icon: 'layers',
        disabled: true
      }],
      filesList: [{
        objType: 'file',
        id: 421,
        'field': null,
        filename: 'Blankt.pdf',
        mimeType: null,
        size: 144484,
        url: '',
        owner: 1,
        created: '2020-03-03 07:39:34'
      }, {
        objType: 'file',
        id: 422,
        'field': null,
        filename: 'test',
        mimeType: null,
        size: null,
        url: '',
        owner: 1,
        created: '2020-03-03 07:39:38'
      }],
      trafficLightSettingsFixed: {
        value: 1,
        lightType: 'trafficLights',
        readOnly: true
      },
      trafficLightSettingsCanChange: {
        value: 1,
        lightType: 'trafficLights'
      }
    };
  },
  methods: {
    changeValue: function changeValue(event) {
      console.log(event); // eslint-disable-line
    }
  }
};