import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "flex flex-wrap py-4"
};
var _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_circle = _resolveComponent("risma-circle");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.preparedMonthsDefault, function (month, index) {
    return _openBlock(), _createElementBlock("div", {
      key: month,
      class: "text-center w-1/6",
      onClick: function onClick($event) {
        return $options.toggleCircle(index);
      }
    }, [_createElementVNode("span", null, _toDisplayString(month), 1 /* TEXT */), _createVNode(_component_risma_circle, {
      class: "mx-auto",
      "is-active": index === $data.activeIndex
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString($props.dataset[index] || 0), 1 /* TEXT */)];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["is-active"])], 8 /* PROPS */, _hoisted_2);
  }), 128 /* KEYED_FRAGMENT */))]);
}