function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { getAllTrees, updateNodeById, deleteNodeById, getNodesByProcessTreeId } from '@/api/compliance/processtree';
import Tree from '@/components/Atoms/Tree';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import Notification from '@/components/Molecules/Notification';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import { check403AccessError } from '@/utils/handleAPIErrors';
import { UserLevels } from '@/constants/UserLevels';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'ProcessTreePage',
  components: {
    Tree: Tree,
    NotificationPopUp: NotificationPopUp,
    Notification: Notification,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  mixins: [ProcessLibraryTitleMixin, NotificationPopUpMixin],
  data: function data() {
    return {
      treeId: '',
      nodes: null,
      treeData: null,
      newChild: {},
      doMove: false,
      error: '',
      appendTo: null,
      translate: getTranslate['ProcessTreePage']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    userHasRights: function userHasRights() {
      return !this.lockTree || this.currentUser.is_admin;
    },
    lockTree: function lockTree() {
      var userProjectLevels = this.currentUser.projects ? this.currentUser.projects.filter(function (project) {
        return project.objType === ObjectTypes.COMPLIANCE_PROJECT;
      }).map(function (item) {
        return +item.access_level;
      }) : [];
      var result = true;
      if (userProjectLevels.length && userProjectLevels.some(function (item) {
        return item >= UserLevels.SUPER;
      })) {
        result = false;
      }
      return result;
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'process library node');
    }
  }),
  mounted: function mounted() {
    var _this = this;
    getAllTrees().then(function (response) {
      if (response[0].id !== null) {
        _this.treeData = response[0];
        _this.treeId = 'process_tree_page_' + _this.treeData.id;
        getNodesByProcessTreeId(_this.treeData.id).then(function (response) {
          _this.nodes = response;
          _this.prepareTitles(_this.nodes.children);
        });
      } else {
        _this.error = _this.translate.noProcessesFound;
      }
    });
  },
  methods: {
    prepareTitles: function prepareTitles(nodes) {
      var _this2 = this;
      nodes.forEach(function (node) {
        if (node.uniqueId) {
          node.title = node.uniqueId + ' ' + node.title;
        }
        node.children && _this2.prepareTitles(node.children);
      });
    },
    addChildToNode: function addChildToNode(_ref) {
      var newNode = _ref.newNode,
        params = _ref.params;
      var title = newNode.uniqueId ? "".concat(newNode.uniqueId, " ").concat(newNode.title) : newNode.title;
      this.newChild = _objectSpread(_objectSpread({}, newNode), {}, {
        title: title
      });
      this.setNotificationData(newNode);
      this.turnAfterRefreshNotification();
      if (+params.appendTo === +this.nodes.id) {
        this.nodes.children.push(this.newChild);
        return;
      }
      if (params.insertCopyAfter !== undefined) {
        var idx = this.nodes.children.findIndex(function (child) {
          return child.id === params.insertCopyAfter;
        });
        if (idx !== -1) {
          this.insertCopyToRoot(idx);
        }
      }
    },
    insertCopyToRoot: function insertCopyToRoot(index) {
      if (1 + index === this.nodes.children.length) {
        return this.nodes.children.push(this.newChild);
      }
      return this.nodes.children.splice(index, 0, this.newChild);
    },
    doDeleteNode: function doDeleteNode(params) {
      var _this3 = this;
      deleteNodeById(this.treeData.id, params.nodeId).then(function (response) {
        if (typeof response.headers !== 'undefined') {
          _this3.error = _this3.translate.couldNotDeleteNode;
        }
      });
    },
    resetMove: function resetMove() {
      this.doMove = false;
    },
    tryMove: function tryMove(params) {
      var _this4 = this;
      updateNodeById(this.treeData.id, params.child, params.options).then(function (response) {
        if (response.id) {
          _this4.doMove = true;
        }
      });
    },
    reloadData: function reloadData() {
      var _this5 = this;
      getNodesByProcessTreeId(this.treeData.id).then(function (response) {
        _this5.nodes = response;
      }).then(function () {
        _this5.$refs.tree && _this5.$refs.tree.init();
        _this5.turnAfterRefreshNotification();
      });
    },
    toggleShowAddModal: function toggleShowAddModal() {
      this.showAddModal = !this.showAddModal;
    }
  }
};