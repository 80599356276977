import { Colors } from '@/Colors';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { ComplianceControllers } from '@/constants/compliance/ComplianceControllers';
export default {
  name: 'CardInformationAssets',
  introduction: '',
  description: '',
  token: '<card-information-assets/>',
  components: {
    trafficlight: TrafficLight,
    RismaTitle: RismaTitle
  },
  props: {
    informationAsset: {
      required: true,
      type: Object
    },
    truncateLength: {
      required: false,
      type: Number,
      default: 0
    },
    assetType: {
      required: false,
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      Colors: Colors
    };
  },
  computed: {
    assetTypeUrl: function assetTypeUrl() {
      return ComplianceControllers[this.informationAsset.objType] || 'informationassets';
    },
    truncatedDescription: function truncatedDescription() {
      if (this.truncateLength) {
        return this.$truncate(this.informationAsset.description, this.truncateLength);
      }
      return this.informationAsset.description;
    }
  }
};