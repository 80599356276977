function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getTranslate } from './translate';
import { getSelectableQuestionnairesByQuestionnaireTypeId } from '@/api/compliance/questionnaire';
import { getAllByType } from '@/api/compliance/informationAssets';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import { IATitleMixin } from '@/mixins/IATitleMixin';
import { QuestionnaireTypes } from '@/constants/compliance/QuestionnaireTypes';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export default {
  name: 'IAQuestionnaireSelection',
  components: {
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    RismaTitle: RismaTitle,
    RismaSelect: RismaSelect,
    SingleSelect: SingleSelect
  },
  mixins: [IATitleMixin],
  emits: ['assetsSelected', 'selectionChanged'],
  data: function data() {
    return {
      selectedQuestionnaireId: null,
      selectedAssetType: null,
      assets: [],
      questionnaires: [],
      questionnairesOptions: [],
      questions: [],
      assetsLoaded: true,
      selectedAssets: [],
      selectedOrganisations: [],
      selectedResponsible: [],
      translate: getTranslate['IAQuestionnaireSelection']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), mapState(useUsersStore, ['users'])), mapState(useOrganisationsStore, ['organisations'])), {}, {
    usersOptions: function usersOptions() {
      return this.users.map(function (user) {
        return {
          id: user.id,
          label: user.display_name
        };
      });
    },
    organisationsOptions: function organisationsOptions() {
      return this.organisations.map(function (item) {
        return {
          id: item.id,
          label: item.name
        };
      });
    },
    assetsFilteredByQuestionnaire: function assetsFilteredByQuestionnaire() {
      var _this = this;
      if (!this.selectedQuestionnaireId) return [];
      return this.assets.filter(function (a) {
        return a.questionnaires.some(function (item) {
          return item.questionnaireId === _this.selectedQuestionnaireId;
        });
      });
    },
    availableResponsible: function availableResponsible() {
      var _this2 = this;
      var result = {};
      this.assetsFilteredByQuestionnaire.forEach(function (asset) {
        var _asset$responsibleUse;
        if (!(asset !== null && asset !== void 0 && (_asset$responsibleUse = asset.responsibleUserIds) !== null && _asset$responsibleUse !== void 0 && _asset$responsibleUse.length)) return;
        asset.responsibleUserIds.forEach(function (userId) {
          if (result[userId]) return;
          result[userId] = _this2.usersOptions.find(function (user) {
            return user.id === userId;
          });
        });
      });
      return Object.values(result);
    },
    availableOrganisations: function availableOrganisations() {
      var _this3 = this;
      var result = {};
      this.assetsFilteredByQuestionnaire.forEach(function (asset) {
        var _asset$organisationId;
        if (!(asset !== null && asset !== void 0 && (_asset$organisationId = asset.organisationIds) !== null && _asset$organisationId !== void 0 && _asset$organisationId.length)) return;
        asset.organisationIds.forEach(function (organisationId) {
          if (result[organisationId]) return;
          result[organisationId] = _this3.organisationsOptions.find(function (org) {
            return org.id === organisationId;
          });
        });
      });
      return Object.values(result);
    },
    availableAssetOptions: function availableAssetOptions() {
      var _this$selectedOrganis, _this$selectedRespons;
      var result = this.assetsFilteredByQuestionnaire;
      if ((_this$selectedOrganis = this.selectedOrganisations) !== null && _this$selectedOrganis !== void 0 && _this$selectedOrganis.length) {
        var selectedIds = this.selectedOrganisations.map(function (org) {
          return org.id;
        });
        result = result.filter(function (asset) {
          return asset.organisationIds.some(function (id) {
            return selectedIds.includes(id);
          });
        });
      }
      if ((_this$selectedRespons = this.selectedResponsible) !== null && _this$selectedRespons !== void 0 && _this$selectedRespons.length) {
        var _selectedIds = this.selectedResponsible.map(function (user) {
          return user.id;
        });
        result = result.filter(function (asset) {
          return asset.responsibleUserIds.some(function (id) {
            return _selectedIds.includes(id);
          });
        });
      }
      return result;
    },
    assetTypes: function assetTypes() {
      var assetTypes = [];
      if (this.getSettingValue('feature.enable_systems')) {
        assetTypes.push({
          value: AssetsTypes.SYSTEMS,
          label: this.translate.systems
        });
      }
      if (this.getSettingValue('feature.enable_data_processors')) {
        assetTypes.push({
          value: AssetsTypes.PROCESSORS,
          label: this.translate.dataProcessors
        });
      }
      if (this.getSettingValue('feature.enable_data_controllers')) {
        assetTypes.push({
          value: AssetsTypes.CONTROLLERS,
          label: this.translate.dataControllers
        });
      }
      if (this.currentUser.iaAccess) {
        assetTypes.push({
          value: AssetsTypes.INFORMATION_ASSETS_API,
          label: this.translate.customAssets
        });
      }
      return assetTypes;
    }
  }),
  watch: {
    questionnaires: {
      deep: true,
      handler: function handler() {
        if (this.questionnaires.length === 1) {
          this.selectedQuestionnaireId = this.questionnaires[0].id;
        }
      }
    },
    availableAssetOptions: function availableAssetOptions(newAssets) {
      var newAssetsIds = newAssets.map(function (asset) {
        return asset.id;
      });
      this.selectedAssets = this.selectedAssets.filter(function (asset) {
        return newAssetsIds.includes(asset.id);
      });
    },
    selectedAssetType: function selectedAssetType() {
      this.selectedQuestionnaireId = null;
    },
    selectedQuestionnaireId: function selectedQuestionnaireId() {
      this.resetSelectors();
    },
    selectedOrganisations: function selectedOrganisations() {
      this.emitSelectedAssets();
    },
    selectedResponsible: function selectedResponsible() {
      this.emitSelectedAssets();
    },
    selectedAssets: function selectedAssets() {
      this.emitSelectedAssets();
    }
  },
  methods: {
    assetTypeSelected: function assetTypeSelected(selectedAssetType) {
      var _this4 = this;
      if (this.selectedAssetType == selectedAssetType) {
        return;
      }
      this.selectedAssetType = selectedAssetType;
      this.assetsLoaded = false;
      if (this.selectedAssetType) {
        var _mapping;
        var mapping = (_mapping = {}, _defineProperty(_mapping, AssetsTypes.SYSTEMS, QuestionnaireTypes.SYSTEM), _defineProperty(_mapping, AssetsTypes.PROCESSORS, QuestionnaireTypes.DATAPROCESSOR), _defineProperty(_mapping, AssetsTypes.CONTROLLERS, QuestionnaireTypes.DATACONTROLLER), _defineProperty(_mapping, AssetsTypes.INFORMATION_ASSETS_API, QuestionnaireTypes.IA_CUSTOM), _mapping);
        var params = '?data[]=questionnaire&data[]=users&data[]=organisations&filters[underlying]=include';
        this.getSelectableQuestionnaires(mapping[selectedAssetType]);
        getAllByType(this.selectedAssetType, params).then(function (_ref) {
          var list = _ref.list;
          _this4.assets = _this4.processAssets(list, _this4.selectedAssetType);
          _this4.assetsLoaded = true;
          _this4.selectionChanged();
        });
      }
    },
    questionnaireSelected: function questionnaireSelected(selectedQuestionnaireId) {
      if (this.selectedQuestionnaireId == selectedQuestionnaireId) {
        return;
      }
      this.selectedQuestionnaireId = selectedQuestionnaireId;
      if (this.selectedQuestionnaireId) {
        this.selectionChanged();
      }
    },
    processAssets: function processAssets(assets, type) {
      if (type !== AssetsTypes.SYSTEMS) {
        return assets;
      }
      return assets.map(function (asset) {
        var parents = asset.parentId ? [asset.parentId] : [];
        return _objectSpread(_objectSpread({}, asset), {}, {
          parents: parents
        });
      });
    },
    getSelectableQuestionnaires: function getSelectableQuestionnaires(questionnaireTypeId) {
      var _this5 = this;
      return getSelectableQuestionnairesByQuestionnaireTypeId(questionnaireTypeId).then(function (response) {
        _this5.questionnairesOptions = response.map(function (q) {
          return {
            value: q.id,
            label: q.title
          };
        });
      });
    },
    resetSelectors: function resetSelectors() {
      this.selectedAssets = [];
      this.selectedOrganisations = [];
      this.selectedResponsible = [];
      this.selectedQuestions = [];
    },
    emitSelectedAssets: function emitSelectedAssets() {
      var _this$selectedAssets, _this$selectedOrganis2, _this$selectedRespons2;
      if ((_this$selectedAssets = this.selectedAssets) !== null && _this$selectedAssets !== void 0 && _this$selectedAssets.length) {
        this.$emit('assetsSelected', this.selectedAssets);
        return;
      }
      if ((_this$selectedOrganis2 = this.selectedOrganisations) !== null && _this$selectedOrganis2 !== void 0 && _this$selectedOrganis2.length || (_this$selectedRespons2 = this.selectedResponsible) !== null && _this$selectedRespons2 !== void 0 && _this$selectedRespons2.length) {
        var _this$availableAssetO;
        var emitData = (_this$availableAssetO = this.availableAssetOptions) !== null && _this$availableAssetO !== void 0 && _this$availableAssetO.length ? this.availableAssetOptions : null;
        this.$emit('assetsSelected', emitData);
        return;
      }
      this.$emit('assetsSelected', []); // empty [] == all items
    },
    selectionChanged: function selectionChanged() {
      this.$emit('selectionChanged', this.selectedQuestionnaireId, this.assets, this.selectedAssetType);
    }
  }
};