import { Colors } from '@/Colors';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { TrendOptions } from '@/constants/TrendOptions';
import { toLocalDateTime } from '@/utils/date';
import * as Utils from '@/utils/Utils';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
export function getValueOfCustomField(customField, customFieldValue) {
  var hasRowGroupActivated = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var value = {};
  if (customField.typeId === CustomFieldTypes.SELECT) {
    var options = Utils.getProperSelectedOptions(customField.options);
    var option = options.find(function (option) {
      return option.id === customFieldValue.value;
    });
    value.field = option ? option.value : customFieldValue.value;
  }
  if (customField.typeId === CustomFieldTypes.TEXT_LINE || customField.typeId === CustomFieldTypes.TEXT_FIELD) {
    value.field = customFieldValue.value;
  }
  if (customField.typeId === CustomFieldTypes.TRAFFIC_LIGHT) {
    value.field = DatatableRenderer.prepareTrafficLightField(+customFieldValue.value, true);
    value.fieldType = CustomFieldTypes.TRAFFIC_LIGHT;
    value.fieldText = "".concat(Colors.getTrafficlightByNumber(customFieldValue.value).number);
  }
  if (customField.typeId === CustomFieldTypes.TREND) {
    var _option = TrendOptions().find(function (item) {
      return item.value === customFieldValue.value;
    });
    if (_option !== undefined) {
      value.field = _option.label;
    } else {
      value.field = '';
    }
  }
  if (customField.typeId === CustomFieldTypes.DATE_TIME) {
    value.field = toLocalDateTime(customFieldValue.value);
  }
  if (customField.typeId === CustomFieldTypes.NUMERICAL) {
    value.field = "".concat(customFieldValue.value, " ").concat(customField.unit);
  }
  if (customField.typeId === CustomFieldTypes.ROW_GROUP && hasRowGroupActivated) {
    value.data = [];
    value.fieldText = '';
    customFieldValue.rows.forEach(function (row, index) {
      var rowData = {};
      customField.children.forEach(function (child) {
        value.fieldText += "".concat(index, " - ");
        var data = getRightRowGroupFieldValue(child, row);
        data.textDescription = child.label;
        if (child.typeId === CustomFieldTypes.TRAFFIC_LIGHT) {
          data.fieldText = Colors.getTrafficlightByNumber(+data.fieldText || 0).label;
        }
        rowData[child.id] = data;
        value.fieldText += "".concat(child.label, ": ").concat(data.fieldText || data.field, "; ");
      });
      value.data.push(rowData);
    });
  }
  return value;
}
function getRightRowGroupFieldValue(field, fieldValues) {
  var data = fieldValues && fieldValues.find(function (cfv) {
    return cfv.fieldId === field.id;
  });
  if (!data) {
    return {
      field: ''
    };
  }
  return getValueOfCustomField(field, data, false, true);
}
export function getColumnLabelName(label) {
  return "cf-".concat(label.toString().toLowerCase().replace(/[^a-zA-Z0-9]/g, ''));
}
export function getCustomFieldValues(item) {
  var customFieldValues = {};
  if (item.customFieldValues && item.customFieldValues.length) {
    item.customFieldValues.forEach(function (customFieldValue) {
      if (customFieldValue.rows && customFieldValue.rows.length) {
        customFieldValues[customFieldValue.fieldId] = prepareRows(customFieldValue.rows);
      } else if (Object.keys(customFieldValue).includes('value')) {
        customFieldValues[customFieldValue.fieldId] = customFieldValue.value;
      }
    });
  }
  return customFieldValues;
}
function prepareRows(rows) {
  var result = {};
  rows.map(function (item, index) {
    result[index] = {};
    item.map(function (childField) {
      result[index][childField.fieldId] = childField.value;
    });
  });
  return result;
}
export function customFieldHasValue(customField, type) {
  var value = customField === null || customField === void 0 ? void 0 : customField.value;
  if (value === undefined || value === null) return false;
  if (type === 7) return +value;
  return value;
}