import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_risma_select = _resolveComponent("risma-select");
  return $options.isPerformanceReportEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$props.title ? (_openBlock(), _createBlock(_component_risma_label, {
    key: 0,
    title: _ctx.$trans($props.title)
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_select, {
    options: $options.options,
    multiple: $props.multiple,
    "selected-options": $props.selectedOptions,
    placeholder: _ctx.$trans($props.placeholder),
    "open-direction": $props.openDirection,
    "label-key": $props.labelKey,
    onSelected: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('selected', $event);
    }),
    onOpen: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('open');
    }),
    onClose: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('close');
    })
  }, null, 8 /* PROPS */, ["options", "multiple", "selected-options", "placeholder", "open-direction", "label-key"])])) : _createCommentVNode("v-if", true);
}