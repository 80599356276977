function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'QuestionnaireNavigation',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon
  },
  props: {
    questions: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'questions to navigate on'
    },
    visibilityStorageKey: {
      type: String,
      required: false,
      default: 'questionnaire_navigation_hidden_columns',
      note: 'local storage key to store hidden columns'
    },
    processors: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'processors including/excluding underlying'
    }
  },
  emits: ['changeQuestion'],
  setup: function setup(props) {
    return {
      closedHeadlines: getLocalStorageComputed(props.visibilityStorageKey, [], true)
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    expandedQuestions: function expandedQuestions() {
      var _this = this;
      return this.questions.map(function (question, key) {
        return _objectSpread(_objectSpread({}, question), {}, {
          hasHeadline: _this.hasHeadline(question, key),
          isAnswered: _this.isAnswered(question),
          isClickable: !_this.showFullQuestionsList || question.answeredDependencies
        });
      });
    },
    includeSubprocessors: function includeSubprocessors() {
      return this.getSettingValue('feature.ia_questions_include_subprocessors') !== 0;
    },
    allProcessors: function allProcessors() {
      if (this.includeSubprocessors) {
        return this.processors;
      }
      return this.processors.filter(function (processor) {
        return !processor.parents.length;
      });
    },
    showFullQuestionsList: function showFullQuestionsList() {
      return this.getSettingValue('feature.show_full_list_of_questionnaires');
    }
  }),
  methods: {
    changeQuestion: function changeQuestion(id) {
      this.$emit('changeQuestion', id);
    },
    isAnswered: function isAnswered(question) {
      if (+question.questiontype === QuestionTypes().processLibrary.id) {
        return question.processLibrary && question.processLibrary.length > 0;
      }
      var answersLength = _typeof(question.answers_options) === 'object' ? Object.keys(question.answers_options).length : question.answers_options.length;
      return answersLength > 0 || !!question.dateTime || !!question.freeText || !!(question.files && question.files.length > 0) || this.hasIAAnswer(question);
    },
    hasIAAnswer: function hasIAAnswer(question) {
      var _this2 = this;
      if (!question.informationAssets) {
        return false;
      }
      var iaArray = Object.keys(question.informationAssets || {});
      var procIndex = iaArray.indexOf(AssetsTypes.PROCESSORS);
      if (this.includeSubprocessors || procIndex === -1) {
        return Object.values(question.informationAssets).some(function (item) {
          return item.length > 0;
        });
      }
      iaArray.splice(procIndex, 1);
      return question.informationAssets[AssetsTypes.PROCESSORS].length && question.informationAssets[AssetsTypes.PROCESSORS].some(function (item) {
        return _this2.allProcessors.find(function (processor) {
          return +processor.id === +item.id;
        });
      }) || iaArray.some(function (item) {
        return question.informationAssets[item].length > 0;
      });
    },
    handleClosedHeadlines: function handleClosedHeadlines(headline) {
      if (this.closedHeadlines.includes(headline)) {
        this.closedHeadlines = this.closedHeadlines.filter(function (h) {
          return h !== headline;
        });
        return;
      }
      this.closedHeadlines = [].concat(_toConsumableArray(this.closedHeadlines), [headline]);
    },
    hasHeadline: function hasHeadline(question, key) {
      return key - 1 === -1 || this.questions[key - 1].headline !== question.headline;
    }
  }
};