import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { createJiraIssue, getJiraProjects } from '@/api/external/jira';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
export default {
  name: 'SendToJiraModal',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    Modal: Modal
  },
  props: {
    module: {
      required: true,
      type: String
    },
    moduleId: {
      required: true,
      type: String
    }
  },
  emits: ['dismiss'],
  data: function data() {
    return {
      loading: true,
      projects: [],
      selectedProjectId: null,
      selectedIssueType: null,
      newIssueKey: null,
      translate: getTranslate['SendToJiraModal']()
    };
  },
  computed: {
    projectOptions: function projectOptions() {
      return this.projects.map(function (project) {
        return {
          value: project.id,
          label: project.name
        };
      });
    },
    selectedProject: function selectedProject() {
      var _this = this;
      return this.projects.find(function (project) {
        return project.id = _this.selectedProjectId;
      }) || {};
    },
    issueTypes: function issueTypes() {
      if (!this.selectedProjectId) {
        return [];
      }
      return (this.selectedProject && this.selectedProject.issueTypes || []).filter(function (issueType) {
        return !issueType.isSubTask;
      }).map(function (issueType) {
        return {
          value: issueType.name,
          label: issueType.name
        };
      });
    },
    showButtons: function showButtons() {
      return !this.loading && this.newIssueKey === null;
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.loading = true;
    getJiraProjects().then(function (response) {
      _this2.projects = response.list;
      if (_this2.projects.length === 1) {
        _this2.selectedProjectId = _this2.projects[0].id;
      }
      _this2.loading = false;
    });
  },
  methods: {
    sendToJira: function sendToJira() {
      var _this3 = this;
      this.loading = true;
      createJiraIssue(this.selectedProject.key, this.selectedIssueType, this.module, this.moduleId).then(function (response) {
        _this3.newIssueKey = response.key;
        _this3.loading = false;
      });
    }
  }
};