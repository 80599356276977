function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getAllQuestionnaires() {
  var url = '/compliance/questionnaire?data[]=usage';
  return handleRequestResponse(Http.get(url));
}
export function getSelectableQuestionnairesByQuestionnaireTypeId(questionnaireTypeId) {
  return getQuestionnairesByQuestionnaireTypeId(questionnaireTypeId).then(function (response) {
    return response.list.filter(function (q) {
      return !!q.selectable;
    });
  });
}
export function getQuestionnairesByQuestionnaireTypeId(questionnaireTypeId) {
  var url = '/compliance/questionnaire' + "?filter[questionnaireTypeId]=".concat(questionnaireTypeId);
  return handleRequestResponse(Http.get(url));
}
export function getQuestionnaireAssessments_TIA_LIA(baseUrl) {
  var url = baseUrl + '/tialia';
  return handleRequestResponse(Http.get(url));
}

/**
 * Fetch all questions based on a projectId and a questionnaireId
 *
 * @param projectId
 * @param questionnaireId
 */
export function getAllQuestionsForProject(projectId, questionnaireId) {
  var url = "/compliance/projects/".concat(projectId, "/questionnaire/").concat(questionnaireId);
  return handleRequestResponse(Http.get(url));
}
export function getAllQuestions(questionnaireId) {
  return handleRequestResponse(Http.get("/compliance/questionnaire/".concat(questionnaireId)));
}
export function getAllQuestionsIncludingHidden(questionnaireId) {
  return handleRequestResponse(Http.get("/compliance/questionnaire/".concat(questionnaireId, "?filter[]=includeHiddenQuestions")));
}
export function updateQuestionForNode(projectId, nodeId, question) {
  return handleRequestResponse(Http.patch("compliance/projects/".concat(projectId, "/nodes/").concat(nodeId, "/questionanswers/").concat(question.id), question));
}
export function getAllQuestionAnswersForNode(projectId, nodeId) {
  var questionnaireId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  questionnaireId ? questionnaireId = '/' + questionnaireId : '';
  return handleRequestResponse(Http.get("compliance/projects/".concat(projectId, "/nodes/").concat(nodeId, "/questionanswers").concat(questionnaireId)));
}
export function getInformationAssetQuestionnaire(processorId) {
  return handleRequestResponse(Http.get("compliance/informationAssets/processors/".concat(processorId, "/questionanswers")));
}
export function getInformationAssetQuestionnaireForProcessor() {
  return handleRequestResponse(Http.get('compliance/informationAssets/questionnaire/ia-processor'));
}
export function getInformationAssetQuestionnairesForCustomIA() {
  return handleRequestResponse(Http.get('compliance/informationAssets/questionnaire/ia'));
}
export function getInformationAssetQuestionnaireForSystem() {
  return handleRequestResponse(Http.get('compliance/informationAssets/questionnaire/ia-system'));
}
export function getInformationAssetQuestionnaireForController() {
  return handleRequestResponse(Http.get('compliance/informationAssets/questionnaire/ia-controller'));
}
export function getInformationAssetQuestionAnswersByType(type, id, questionnaireId) {
  questionnaireId ? questionnaireId = '/' + questionnaireId : '';
  return handleRequestResponse(Http.get("compliance/informationAssets/".concat(type, "/").concat(id, "/questionanswers").concat(questionnaireId)));
}
export function getQuestionAnswersByBaseUrl(baseUrl, questionnaireId) {
  questionnaireId = questionnaireId ? '/' + questionnaireId : '';
  return handleRequestResponse(Http.get("".concat(baseUrl, "/questionanswers").concat(questionnaireId)));
}
export function getInformationAssetQuestionAnswersByType2(type, id) {
  var flags = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var flagParam = '';
  flags.forEach(function (flag) {
    flagParam = "flags[]=".concat(flag, "&");
  });
  if (flagParam) {
    flagParam = '?' + flagParam;
  }
  return handleRequestResponse(Http.get("compliance/informationAssets/".concat(type, "/").concat(id, "/questionanswers2").concat(flagParam)));
}

/**
 * Copy questionnaire to a new questionnaire
 *
 * @param questionnaireId
 * @param title
 */
export function copyQuestionnaire(questionnaireId, title) {
  var data = {
    'title': title,
    'copyFromQuestionnaireId': questionnaireId
  };
  return handleRequestResponse(Http.post('/compliance/questionnaire', data));
}

/**
 * Set 'selectable' on a questionnaire
 *
 * @param questionnaireId
 * @param title
 * @param selectable
 * @returns {boolean}
 */
export function enableQuestionnaire(questionnaireId, title, selectable) {
  var data = {
    'questionnaireId': questionnaireId,
    'selectable': selectable
  };
  return handleRequestResponse(Http.patch('/compliance/questionnaire', data));
}

/**
 * Delete questionnaire if possible
 *
 * @param questionnaireId
 * @returns {Promise<T | string>}
 */
export function deleteQuestionnaire(questionnaireId) {
  return handleRequestResponse(Http.delete('/compliance/questionnaire/' + questionnaireId));
}

/**
 * Update field on a questionnaire
 *
 * @param questionnaireId
 * @param data
 * @param field
 * @returns {boolean}
 */
export function changeFieldOnQuestionnaire(questionnaireId, title) {
  var field = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'title';
  var data = _defineProperty({
    'questionnaireId': questionnaireId
  }, field, title);
  return handleRequestResponse(Http.patch('/compliance/questionnaire', data));
}
export function getQuestionnaireTypes() {
  var onlyExisting = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var onlyWithMatchingGap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var url = '/compliance/questionnairetype';
  var params = [];
  if (onlyExisting) {
    params.push('onlyExisting=1');
  }
  if (onlyWithMatchingGap) {
    params.push('onlyWithMatchingGap=1');
  }
  if (params.length > 0) {
    url += '?' + params.join('&');
  }
  return handleRequestResponse(Http.get(url));
}
export function getQuestionnaireScores(id) {
  return handleRequestResponse(Http.get("compliance/questionoptionsscore/".concat(id)));
}
export function updateQuestionnaireScores(id, payload) {
  return handleRequestResponse(Http.patch("compliance/questionoptionsscore/".concat(id), payload));
}
export function getQuestionnaireAssessments(id) {
  return handleRequestResponse(Http.get("compliance/questionnairescoringcategories/".concat(id)));
}
export function updateQuestionnaireAssessments(id, payload) {
  return handleRequestResponse(Http.patch("compliance/questionnairescoringcategories/".concat(id), payload));
}
export function getAllQuestionnaireTemplates(type) {
  return handleRequestResponse(Http.get("admin/compliance/questionnairetemplate?type=".concat(type)));
}
export function createQuestionnaireFromTemplate(templateId) {
  return handleRequestResponse(Http.post("admin/compliance/questionnairetemplate/".concat(templateId)));
}