import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_button, {
    text: $data.translate.back,
    type: "save",
    class: "w-fit",
    onClick: $options.backToPrevPage
  }, null, 8 /* PROPS */, ["text", "onClick"]), $data.dataset.length ? (_openBlock(), _createBlock(_component_risma_datatable, {
    key: 0,
    dataset: _ctx.datasetSlice,
    "filter-by-column": true,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "wrapper-height-sticky": 350,
    "decouple-filter-fields": $data.decoupleFilterFields,
    "disabled-filter-by-columns": $data.disabledFilterByColumns,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged
  }, null, 8 /* PROPS */, ["dataset", "dataset-total", "dataset-total-sliced", "decouple-filter-fields", "disabled-filter-by-columns", "onPageChange", "onOnStateChanged"])) : _createCommentVNode("v-if", true)]);
}