export var ControlDetailsCreateMixin = {
  data: function data() {
    return {
      isDeleted: false
    };
  },
  methods: {
    getMenuCopyTitle: function getMenuCopyTitle(currentKind, allKinds) {
      var result = 'Copy control';
      if (currentKind === allKinds.MOTHER) {
        result = 'Copy mother control';
      } else if (currentKind === allKinds.DAUGHTER) {
        result = 'Copy daughter control';
      }
      return result;
    },
    getMenuDeleteTitle: function getMenuDeleteTitle(currentKind, allKinds) {
      var result = 'Delete control';
      if (currentKind === allKinds.MOTHER) {
        result = 'Delete mother control';
      } else if (currentKind === allKinds.DAUGHTER) {
        result = 'Delete control';
      }
      return result;
    }
  }
};