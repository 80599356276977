function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getControlDataReport } from '@/api/reports/reports';
import { ControlLocalStorage } from '@/constants/ControlLocalStorage';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { getRangesWithNextThreeMonths } from '@/constants/dates/daterangepicker';
import { getControlsDataReportFields, getDisabledFiltersByColumns, ControlObjectProps, getDecoupleFilterFields, ControlsDataReportColumnsOrder, columnsMeta } from '@/constants/ControlsReportProps';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import { ControlReportsMixin } from '@/components/Pages/Controls/ControlReportsMixin';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { getCustomFieldsColsFromActivityTypes } from '@/utils/Utils';
export default {
  name: 'ControlDataReports',
  introduction: 'Control Data Reports',
  description: 'This is Page component for /controls2/reports/data',
  token: '<control-reports></control-reports>',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    RismaDateRangePicker: RismaDateRangePicker,
    RismaDatatable: RismaDatatable,
    SaveReport: SaveReport,
    PreviewModalLink: PreviewModalLink,
    Trafficlight: Trafficlight
  },
  mixins: [ControlReportsMixin, RismaDatatableDefaultMixin],
  data: function data() {
    return {
      isLoaded: false,
      localStorageRangeKey: ControlLocalStorage.REPORTS_DATA_RANGE,
      stateLocal: ControlLocalStorage.DATA_REPORTS,
      columnsMeta: columnsMeta,
      decoupleFilterFields: getDecoupleFilterFields(),
      reportType: 'controls-data-report',
      ranges: getRangesWithNextThreeMonths(),
      translate: {
        dataReport: this.$trans('Data report'),
        none: this.$trans('None'),
        reportSaved: this.$trans('Report saved'),
        deadlineInterval: this.$trans('Deadline interval')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    showOrganisationalGroupsSetting: function showOrganisationalGroupsSetting() {
      return !!this.getSettingValue('risma.organisational_groups');
    },
    showExpectedEvidenceSetting: function showExpectedEvidenceSetting() {
      return !!this.getSettingValue('risma.show_expected_evidence');
    },
    disabledFilterByColumns: function disabledFilterByColumns() {
      var disableFilterForCustomFieldTypes = [CustomFieldTypes.TEXT_LINE, CustomFieldTypes.TEXT_FIELD, CustomFieldTypes.TRAFFIC_LIGHT];
      var result = getDisabledFiltersByColumns();
      Object.entries(this.customFields).forEach(function (field) {
        var _field = _slicedToArray(field, 2),
          key = _field[0],
          value = _field[1];
        if (disableFilterForCustomFieldTypes.includes(value.fieldType)) {
          result.push(key);
        }
      });
      return result;
    },
    reportFields: function reportFields() {
      var result = getControlsDataReportFields();
      if (!this.showOrganisationalGroupsSetting) {
        delete result[ControlObjectProps.ORGANISATION_GROUPS];
      }
      if (!this.showExpectedEvidenceSetting) {
        delete result[ControlObjectProps.EXPECTED_EVIDENCE];
      }
      return result;
    },
    customFields: function customFields() {
      return getCustomFieldsColsFromActivityTypes(this.activityTypes, this.customUnitPrefix);
    },
    columns: function columns() {
      var _this = this;
      var translatedReportFields = {};
      var translatedCustomFields = {};
      Object.entries(this.reportFields).forEach(function (field) {
        var _field2 = _slicedToArray(field, 2),
          key = _field2[0],
          value = _field2[1];
        translatedReportFields[key] = _this.$trans(value);
      });
      Object.entries(this.customFields).forEach(function (field) {
        var _field3 = _slicedToArray(field, 2),
          key = _field3[0],
          value = _field3[1];
        translatedCustomFields[key] = _this.$trans(value.label);
      });
      return _objectSpread(_objectSpread({}, translatedReportFields), translatedCustomFields);
    },
    columnsOrder: function columnsOrder() {
      var result = ControlsDataReportColumnsOrder;
      if (!this.showOrganisationalGroupsSetting) {
        delete result[ControlObjectProps.ORGANISATION_GROUPS];
      }
      if (!this.showExpectedEvidenceSetting) {
        delete result[ControlObjectProps.EXPECTED_EVIDENCE];
      }
      return result;
    },
    trafficLightKeys: function trafficLightKeys() {
      var _this2 = this;
      var list = ['trafficLight'];
      Object.keys(this.customFields).forEach(function (fieldKey) {
        if (_this2.customFields[fieldKey].fieldType === CustomFieldTypes.TRAFFIC_LIGHT) {
          list.push(fieldKey);
        }
      });
      return list;
    }
  }),
  mounted: function mounted() {
    if (location.search && location.search.indexOf(this.queryString) === 0) {
      this.loadDataFromQuery();
    } else {
      this.refreshTable();
    }
  },
  methods: {
    refreshTable: function refreshTable() {
      this.getInitRange();
      return this.getData();
    },
    getData: function getData() {
      var _this3 = this;
      this.isLoaded = false;
      var deadlines = {
        deadlineAfter: this.deadlineAfter,
        deadlineBefore: this.deadlineBefore,
        deadlineBeforeTime: this.deadlineBeforeTime
      };
      return getControlDataReport(deadlines).then(function (_ref) {
        var list = _ref.list;
        _this3.dataset = _this3.prepareDataset(list);
        _this3.enhanceDataset(_this3.dataset, _this3.columns);
      }).catch(function () {}).finally(function () {
        _this3.isLoaded = true;
      });
    },
    prepareDataset: function prepareDataset(controls) {
      var _this4 = this;
      var result = [];
      controls.forEach(function (control) {
        var _control$rowsData;
        var baseControl = _this4.prepareItemBaseFields(control);
        if (!((_control$rowsData = control.rowsData) !== null && _control$rowsData !== void 0 && _control$rowsData.length)) return;
        control.rowsData.forEach(function (row) {
          var rowFields = _this4.prepareRowDataFields(row);
          result.push(_objectSpread(_objectSpread({}, baseControl), rowFields));
        });
      });
      return result;
    },
    prepareRowDataFields: function prepareRowDataFields(row) {
      var resultFields = {};
      for (var key in this.customFields) {
        var id = key.split('-')[1];
        if (key.endsWith(this.customUnitPrefix.toLowerCase())) continue;
        var value = row[id] || '';
        if (this.customFields[key].fieldType === CustomFieldTypes.TRAFFIC_LIGHT) {
          value = DatatableRenderer.prepareTrafficLightField(value || 0);
        }
        resultFields[key] = {
          field: value
        };
      }
      return resultFields;
    },
    prepareFilterByLabel: function prepareFilterByLabel(value, columnKey) {
      var result = value;
      var isTrafficCustomField = this.customFields[columnKey] && this.customFields[columnKey].fieldType === CustomFieldTypes.TRAFFIC_LIGHT;
      if (columnKey === 'trafficLight' || isTrafficCustomField) {
        result = this.$trans(RismaControlsUtils.getTrafficLightLabel(value, !isTrafficCustomField));
      }
      return result;
    }
  }
};