import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2f7b5a5d"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "w-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_admin_bulk_tab_items = _resolveComponent("admin-bulk-tab-items");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_admin_bulk_tab_items), _createElementVNode("div", null, [$data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_router_view, {
    key: 1,
    "compliance-projects": $data.complianceProjects,
    "risk-projects": $data.riskProjects
  }, null, 8 /* PROPS */, ["compliance-projects", "risk-projects"]))])]);
}