import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.sendToJira,
    "button-ok-text": $data.translate.sendToJira,
    "show-buttons": $options.showButtons,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('dismiss');
    }),
    onAccept: $options.sendToJira
  }, {
    body: _withCtx(function () {
      return [$data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : _createCommentVNode("v-if", true), !$data.loading && $data.newIssueKey === null ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_single_select, {
        modelValue: $data.selectedProjectId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.selectedProjectId = $event;
        }),
        title: $data.translate.selectProject,
        options: $options.projectOptions,
        placeholder: $data.translate.selectProject
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"]), _createVNode(_component_single_select, {
        modelValue: $data.selectedIssueType,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.selectedIssueType = $event;
        }),
        title: $data.translate.selectIssueType,
        options: $options.issueTypes,
        placeholder: $data.translate.selectIssueType
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])])) : _createCommentVNode("v-if", true), $data.newIssueKey !== null ? (_openBlock(), _createBlock(_component_risma_title, {
        key: 2,
        title: $data.translate.issueCreatedInJiraWithKey + $data.newIssueKey,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "show-buttons", "onAccept"]);
}