import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
var _hoisted_2 = {
  class: "mb-3"
};
var _hoisted_3 = {
  key: 1,
  class: "mb-3"
};
var _hoisted_4 = {
  key: 2,
  class: "flex justify-between max-w-500px mb-3"
};
var _hoisted_5 = ["checked"];
var _hoisted_6 = {
  key: 3,
  class: "flex justify-between max-w-500px mb-3"
};
var _hoisted_7 = ["checked"];
var _hoisted_8 = {
  key: 4,
  class: "flex justify-between max-w-500px mb-3"
};
var _hoisted_9 = ["checked"];
var _hoisted_10 = {
  key: 5,
  class: "flex justify-between max-w-500px"
};
var _hoisted_11 = ["checked"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.updateProject,
    "overflow-visible": true,
    "dismiss-on-click-outside": false,
    "button-ok-text": $data.translate.save,
    "button-dismiss-text": $data.translate.cancel,
    type: "small",
    onAccept: $options.onAccept,
    onDismiss: _cache[8] || (_cache[8] = function ($event) {
      return _ctx.$emit('dismiss', $event);
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        onDismiss: _cache[0] || (_cache[0] = function ($event) {
          return $data.notificationMessage = '';
        })
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", null, _toDisplayString($data.notificationMessage), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        modelValue: $data.currentProject.title,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.currentProject.title = $event;
        }),
        title: $data.translate.title,
        enabled: _ctx.isAdmin,
        placeholder: $data.translate.title,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "enabled", "placeholder"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
        title: $data.translate.solutionId,
        "model-value": $data.currentProject.solutionId,
        options: $options.solutionOptions,
        "un-sorted": true,
        placeholder: $data.translate.selectOne,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $options.updateRiskProject($event, 'solutionId');
        })
      }, null, 8 /* PROPS */, ["title", "model-value", "options", "placeholder"])]), !$options.isErm ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_single_select, {
        title: $data.translate.defaultActivityType,
        "model-value": $data.currentProject.defaultActivityTypeId,
        options: $options.activityTypeOptions,
        placeholder: $data.translate.notSet,
        "with-reset": true,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $options.updateRiskProject($event, 'defaultActivityTypeId');
        })
      }, null, 8 /* PROPS */, ["title", "model-value", "options", "placeholder"])])) : _createCommentVNode("v-if", true), !$options.isErm ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_title, {
        title: $data.translate.displayRelevantThreatsOnAssessments,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("input", {
        checked: $data.currentProject.threatsEnabled === 1,
        type: "checkbox",
        class: "outline-none",
        onChange: _cache[4] || (_cache[4] = function ($event) {
          return $options.updateRiskProject(+$event.target.checked, 'threatsEnabled');
        })
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_5)])) : _createCommentVNode("v-if", true), !$options.isErm ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_risma_title, {
        title: $data.translate.enableAccumulatedRisk,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("input", {
        checked: $data.currentProject.accumulatedRisks,
        type: "checkbox",
        class: "outline-none",
        onChange: _cache[5] || (_cache[5] = function ($event) {
          return $options.updateRiskProject(+$event.target.checked, 'accumulatedRisks');
        })
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_7)])) : _createCommentVNode("v-if", true), !$options.isErm ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_risma_title, {
        title: $data.translate.enableInherentVsResidualRisk,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("input", {
        checked: $data.currentProject.inherentRisksEnabled,
        type: "checkbox",
        class: "outline-none",
        onChange: _cache[6] || (_cache[6] = function ($event) {
          return $options.updateRiskProject(+$event.target.checked, 'inherentRisksEnabled');
        })
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_9)])) : _createCommentVNode("v-if", true), !$options.isErm ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_risma_title, {
        title: $data.translate.enableMatrixInverted,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("input", {
        checked: $props.project.matrixInverted,
        type: "checkbox",
        class: "outline-none",
        onChange: _cache[7] || (_cache[7] = function ($event) {
          return $options.updateRiskProject(+$event.target.checked, 'matrixInverted');
        })
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_11)])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}