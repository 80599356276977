import i18n from '@/i18n';
export var getTranslate = {
  NewChoice: function NewChoice() {
    return {
      addNewAnswer: i18n.t('Add new answer'),
      newAnswer: i18n.t('New answer'),
      add: i18n.t('Add'),
      youCantSubmitABlankAnswer: i18n.t('You can\'t submit a blank answer'),
      answerAlreadyExists: i18n.t('Answer already exists')
    };
  },
  SingleAssetChoice: function SingleAssetChoice() {
    return {
      isAreAlreadyAddedByADifferentUser: i18n.t('is/are already added by a different user'),
      youCantSeeOrEditTheseAssetBecauseYourUserRightInhibitsYouToInteractWithIt: i18n.t('You can\'t see or edit these asset(s) because your user right inhibits you to interact with it')
    };
  },
  ProcessLibraryChoice: function ProcessLibraryChoice() {
    return {
      isAreAlreadyAddedByADifferentUser: i18n.t('is/are already added by a different user'),
      youCantSeeOrEditTheseProcessLibraryBecauseYourUserRightInhibitsYouToInteractWithIt: i18n.t('You can\'t see or edit these process library nodes because your user right inhibits you to interact with it')
    };
  }
};