function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import * as Utils from './utils';
import * as Cookie from 'tiny-cookie';
import { Colors } from '@/Colors';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import ToolTip from '@/components/Atoms/Containers/ToolTip';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { DateFormats } from '@/constants/DateFormats';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'GanttChart',
  components: {
    ToolTip: ToolTip,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink
  },
  introduction: 'A gantt chart',
  description: 'This component takes a list of Objects and displays them as a gantt chart.',
  token: '<gantt-chart></gantt-chart>',
  props: {
    chartData: {
      required: true,
      type: Array,
      note: 'A list of Objects. All objects must have a start, deadline and a title property'
    },
    periodCookie: {
      required: false,
      type: String,
      default: '',
      note: 'A string with that name of the cookie which the chart should save and load its period state from'
    },
    sortCookie: {
      required: false,
      type: String,
      default: '',
      note: 'A string with that name of the cookie which the chart should save and load its sort state from'
    },
    domId: {
      required: false,
      type: String,
      default: function _default() {
        return Utils.randomDomId();
      },
      note: 'For testing purposes. Always leave it at default in implementation'
    },
    hasPreview: {
      required: false,
      type: Boolean,
      default: false,
      note: 'required for preview'
    },
    isSortingVisible: {
      required: false,
      type: Boolean,
      default: true,
      note: 'Display sorting or not'
    },
    isResizeEnabled: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Can or cannot change selection scope of the chart scroll bar'
    },
    zoomEndIndex: {
      required: false,
      type: Number,
      default: 15,
      note: 'End index uses to zoom the chart by the index of the category'
    },
    chartScrollEnabled: {
      required: false,
      type: Boolean,
      default: true,
      note: 'If chart scroll enabled'
    }
  },
  emits: ['dismissModal', 'loading', 'loaded'],
  data: function data() {
    return {
      chart: null,
      sortKey: '',
      isLoaded: false,
      selectedActivity: {},
      hoverText: '',
      tooltipY: 0,
      tooltipX: 0,
      ellipsis: '...',
      translate: getTranslate['GanttChart']()
    };
  },
  computed: {
    graphDuration: function graphDuration() {
      return this.dateDiffInDays(this.start, this.end);
    },
    start: function start() {
      var startDate = '';
      this.chartData.forEach(function (data) {
        startDate === '' ? startDate = data.start : null;
        startDate > data.start ? startDate = data.start : null;
      });
      return startDate;
    },
    end: function end() {
      var endDate = '';
      this.chartData.forEach(function (data) {
        endDate === '' ? endDate = data.deadline : null;
        endDate < data.deadline ? endDate = data.deadline : null;
      });
      return endDate;
    },
    sortKeys: function sortKeys() {
      return [{
        value: 0,
        label: this.translate.number,
        sortKey: 'path'
      }, {
        value: 1,
        label: this.translate.deadline,
        sortKey: 'deadline'
      }, {
        value: 2,
        label: this.translate.title,
        sortKey: 'title'
      }];
    },
    formattedData: function formattedData() {
      var _this = this;
      var chartData = this.sortDataBySortKey(this.chartData);
      return chartData.map(function (item) {
        var title = '';
        if (item.path) {
          title += item.path + ' ';
        }
        title += item.title;
        var previewFields = getFieldsFromObjectForPreview(item);
        return {
          category: title,
          segments: [_objectSpread(_objectSpread({}, previewFields), {}, {
            start: _this.dateDiffInDays(_this.start, item.start),
            duration: _this.dateDiffInDays(item.start, item.deadline),
            color: _this.getBarColor(item),
            startDate: item.start,
            endDate: item.deadline,
            title: item.title,
            path: item.path,
            url: item.url
          })]
        };
      });
    },
    chartHeight: function chartHeight() {
      return this.formattedData.length * 25 + 200 + 'px';
    },
    closedItems: function closedItems() {
      return this.chartData.filter(function (dataItem) {
        return dataItem.objType === ObjectTypes.EXECUTION_INITIATIVE && dataItem.closed;
      }).map(function (dataItem) {
        return dataItem.displayHeadline;
      });
    }
  },
  watch: {
    sortKey: function sortKey() {
      this.sort();
    },
    chartData: {
      deep: true,
      handler: function handler() {
        if (this.chart !== null) {
          this.updateChartData();
        }
      }
    }
  },
  created: function created() {
    var sortKeyId = null;
    if (this.isSortingVisible) {
      if (this.sortCookie !== '') {
        sortKeyId = Cookie.get(this.sortCookie);
      }
      if (this.sortKeys[sortKeyId] !== undefined) {
        this.sortKey = parseInt(sortKeyId);
      } else if (this.sortKey === '') {
        this.sortKey = this.sortKeys[0].value;
      }
    }
  },
  mounted: function mounted() {
    this.$emit('loading');
    this.isLoaded = false;
    this.createChart();
  },
  methods: {
    createChart: function createChart() {
      var vm = this;
      this.chart = window.AmCharts.makeChart(this.domId, {
        type: 'gantt',
        language: Utils.getLanguage(),
        addClassNames: true,
        theme: 'dark',
        period: 'DD',
        dataDateFormat: DateFormats.DATE_FORMAT,
        columnWidth: 0.5,
        autoMargins: false,
        marginLeft: 270,
        marginTop: 60,
        marginRight: 0,
        zoomOutText: '',
        valueScrollbar: {
          autoGridCount: false,
          oppositeAxis: false,
          offset: 20,
          backgroundColor: Colors.system.gray,
          selectedBackgroundColor: Colors.system.blueMain
        },
        chartScrollbar: {
          enabled: this.chartScrollEnabled,
          resizeEnabled: this.isResizeEnabled,
          backgroundColor: '#000000'
        },
        valueAxis: {
          dateFormats: [{
            period: 'fff',
            format: DateFormats.DATE_DAY_FORMAT
          }, {
            period: 'ss',
            format: DateFormats.DATE_DAY_FORMAT
          }, {
            period: 'mm',
            format: DateFormats.DATE_DAY_FORMAT
          }, {
            period: 'hh',
            format: DateFormats.DATE_DAY_FORMAT
          }, {
            period: 'DD',
            format: DateFormats.DATE_DAY_FORMAT
          }, {
            period: 'WW',
            format: DateFormats.DATE_DAY_FORMAT
          }, {
            period: 'MM',
            format: DateFormats.DATE_MONTH_YEAR_FORMAT
          }, {
            period: 'YYYY',
            format: DateFormats.DATE_YEAR_FORMAT
          }],
          type: 'date',
          minimum: 0,
          maximum: this.graphDuration,
          position: 'top',
          axisAlpha: 0,
          labelsEnabled: this.graphDuration == 1 ? false : true,
          listeners: [{
            event: 'axisZoomed',
            method: function method(e) {
              if (vm.periodCookie !== '') {
                Cookie.set(vm.periodCookie, JSON.stringify({
                  start: e.startValue,
                  end: e.endValue
                }), null, {
                  samesite: 'strict'
                });
              }
            }
          }]
        },
        categoryAxis: {
          axisAlpha: 0,
          gridPosition: 'start',
          labelOffset: 0,
          labelFunction: function labelFunction(valueText) {
            var result = valueText.length > 35 ? valueText.substring(0, 35) + vm.ellipsis : valueText;
            return vm.closedItems.includes(valueText) ? "\u2713" + result : result;
          },
          listeners: [{
            event: 'clickItem',
            method: function method(e) {
              var itemValue = e.serialDataItem.category;
              var item = vm.formattedData.filter(function (data) {
                return data.category === itemValue;
              })[0];
              if (vm.hasPreview) {
                vm.selectedActivity = item.segments[0];
                vm.$nextTick(function () {
                  vm.$refs.preview.showModal(event);
                });
                event.preventDefault();
                event.stopPropagation();
              } else {
                var _e$event;
                if (e !== null && e !== void 0 && (_e$event = e.event) !== null && _e$event !== void 0 && _e$event.ctrlKey) {
                  return window.open(item.segments[0].url, '_blank');
                }
                window.location.href = item.segments[0].url;
              }
            }
          }, {
            event: 'rollOverItem',
            method: function method(e) {
              var fullStr = e.serialDataItem.category;
              var elCords = e.target.node.getBoundingClientRect();
              if (e.value.endsWith(vm.ellipsis)) {
                vm.hoverText = fullStr;
                vm.tooltipY = elCords.top + document.documentElement.scrollTop - 5;
                vm.tooltipX = elCords.left + elCords.width - 5;
              }
            }
          }, {
            event: 'rollOutItem',
            method: function method() {
              if (vm.hoverText) {
                vm.hoverText = '';
              }
            }
          }]
        },
        graph: {
          fillAlphas: 1,
          balloonText: "<span>".concat(vm.translate.from, " [[startDate]] - ").concat(vm.translate.to, " [[endDate]]</span>"),
          cornerRadiusTop: 1,
          title: 'title'
        },
        balloon: {
          textAlign: 'left',
          maxWidth: 400,
          disableMouseEvents: false,
          fadeOutDuration: 1,
          fixedPosition: false,
          fillAlpha: 1
        },
        listeners: [{
          event: 'init',
          method: function method(amCharts) {
            amCharts.chart.zoomToIndexes(0, vm.zoomEndIndex);
            var period = null;
            if (vm.periodCookie !== '') {
              period = Cookie.get(vm.periodCookie);
            }
            if (period === null) {
              var pastYear = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
              var now = new Date(Date.now());
              amCharts.chart.valueAxes[0].zoomToValues(now, pastYear);
            } else {
              period = JSON.parse(period);
              amCharts.chart.valueAxes[0].zoomToValues(period.start, period.end);
            }
            if (!vm.chartScrollEnabled) amCharts.chart.div.style.height = vm.chartHeight;
          }
        }, {
          event: 'dataUpdated',
          method: function method() {
            vm.isLoaded = true;
            vm.$emit('loaded');
          }
        }],
        rotate: true,
        categoryField: 'category',
        segmentsField: 'segments',
        colorField: 'color',
        startDate: this.start,
        startField: 'start',
        endField: 'end',
        durationField: 'duration',
        dataProvider: this.formattedData,
        export: window.AmCharts.exportCFG
      });
    },
    dateDiffInDays: function dateDiffInDays(date1, date2) {
      var end = new Date(date2);
      var start = new Date(date1);
      return parseInt((end.getTime() - start.getTime()) / (24 * 3600 * 1000));
    },
    updateChartData: function updateChartData() {
      var _this2 = this;
      this.isLoaded = false;
      this.$emit('loading');
      this.chart.startDate = this.start;
      this.chart.dataProvider = this.formattedData;
      this.chart.zoomToIndexes(0, this.zoomEndIndex);
      if (!this.chartScrollEnabled) {
        this.chart.div.style.height = this.chartHeight;
      }
      setTimeout(function () {
        return _this2.chart.validateData();
      }, 300);
    },
    sort: function sort() {
      if (this.sortCookie !== '') {
        Cookie.set(this.sortCookie, this.sortKey, {
          samesite: 'strict'
        });
      }
      if (!this.isSortingVisible) {
        this.chartData = this.sortDataBySortKey(this.chartData);
      }
      if (this.chart !== null) {
        this.updateChartData();
      }
    },
    sortDataBySortKey: function sortDataBySortKey(data) {
      var sortKey = this.getSortKey(this.sortKey);
      return data.slice(0).sort(function (a, b) {
        return a[sortKey] < b[sortKey] ? -1 : a[sortKey] > b[sortKey] ? 1 : 0;
      });
    },
    getSortKey: function getSortKey(sortVal) {
      var sortKey = '';
      switch (sortVal) {
        case 0:
          sortKey = 'path';
          break;
        case 1:
          sortKey = 'deadline';
          break;
        case 2:
          sortKey = 'title';
          break;
      }
      return sortKey;
    },
    getBarColor: function getBarColor(item) {
      if (item.closed) return Colors.blueLight;
      return item.trafficLight !== undefined ? Colors.trafficLights[item.trafficLight].color : Colors.noTrafficLight;
    }
  }
};