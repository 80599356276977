import { RiskGridMixin } from '@/mixins/RiskGridMixin';
import { prepareRiskDots } from '@/components/Pages/Risks/RiskReports/RiskReportUtilsRisk';
export default {
  mixins: [RiskGridMixin],
  data: function data() {
    return {
      gridBoxSize: 45
    };
  },
  computed: {
    matrix: function matrix() {
      var dots = prepareRiskDots(this.risk, this.currentPeriod, this.statusColor, this.inherentRisksEnabled, this.matrixInverted);
      return this.getMatrix(dots);
    }
  },
  methods: {
    dotHoverFunction: function dotHoverFunction(dot) {
      return dot.title;
    }
  }
};