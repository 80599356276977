import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "relative"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: _ctx.$trans($data.LiaTiaModalText[$options.type].headline),
    "button-ok-text": $data.translate.apply,
    "button-dismiss-text": $data.translate.dismiss,
    "overflow-visible": true,
    onAccept: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('accept');
    }),
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        truncate: false,
        title: $options.firstParagraphText,
        type: "medium",
        class: "mb-6"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
        truncate: false,
        title: _ctx.$trans($data.LiaTiaModalText[$options.type].secondParagraph),
        type: "medium",
        class: "mb-6"
      }, null, 8 /* PROPS */, ["title"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text"]);
}