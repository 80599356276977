import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-29055356"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "bg-gray-125"
};
var _hoisted_2 = {
  key: 0,
  class: "table-bg-blue p-1 rounded-lg"
};
var _hoisted_3 = {
  key: 1,
  class: "my-4 flex items-end"
};
var _hoisted_4 = {
  class: "w-64"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("table", {
    class: _normalizeClass([{
      'colum-0-hidden': !$props.showColumn0
    }, "w-full"])
  }, [_createElementVNode("thead", null, [_createElementVNode("tr", _hoisted_1, [_createElementVNode("th", {
    class: _normalizeClass(['border-b-0', $data.cellCssStyles])
  }, null, 2 /* CLASS */), _createElementVNode("th", {
    class: _normalizeClass($data.cellCssStyles)
  }, null, 2 /* CLASS */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.rowLength, function (level) {
    return _openBlock(), _createElementBlock("th", {
      key: level,
      class: _normalizeClass($data.cellCssStyles)
    }, [_createVNode(_component_risma_title, {
      title: level - 1 + '',
      type: "medium"
    }, null, 8 /* PROPS */, ["title"])], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("tr", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.fullSecondColumn, function (item, key) {
    return _openBlock(), _createElementBlock("th", {
      key: key,
      class: _normalizeClass([{
        'border-t-0 bg-gray-125': key === 0
      }, $data.cellCssStyles])
    }, [$options.defaultSecondColumn.length <= key && $props.editable ? (_openBlock(), _createBlock(_component_input_field, {
      key: 0,
      modelValue: $props.tableSecondColumn[key - $options.defaultSecondColumn.length].value,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $props.tableSecondColumn[key - $options.defaultSecondColumn.length].value = $event;
      },
      enabled: !$props.readOnly,
      type: "text",
      onUpdated: function onUpdated($event) {
        return $options.emitChangeField($event, $props.tableSecondColumn[key - $options.defaultSecondColumn.length], 'fieldheader');
      }
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "enabled", "onUpdated"])) : (_openBlock(), _createBlock(_component_risma_title, {
      key: 1,
      title: item,
      type: "medium"
    }, null, 8 /* PROPS */, ["title"]))], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.rows, function (item, key) {
    return _openBlock(), _createElementBlock("tr", {
      key: key,
      class: "odd:bg-gray-125"
    }, [_createElementVNode("td", {
      class: _normalizeClass($data.cellCssStyles)
    }, [!$props.editable ? (_openBlock(), _createBlock(_component_risma_title, {
      key: 0,
      title: item.label,
      truncate: true,
      type: "small",
      class: "text-left pl-2"
    }, null, 8 /* PROPS */, ["title"])) : (_openBlock(), _createBlock(_component_input_field, {
      key: 1,
      modelValue: item.label,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return item.label = $event;
      },
      type: "text",
      enabled: !$props.readOnly,
      onUpdated: function onUpdated($event) {
        return $options.emitChangeField($event, item, 'fieldlabel');
      }
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "enabled", "onUpdated"]))], 2 /* CLASS */), _createElementVNode("td", {
      class: _normalizeClass($data.cellCssStyles)
    }, [!$props.editable ? (_openBlock(), _createBlock(_component_risma_title, {
      key: 0,
      title: item.fieldWeight + '',
      type: "medium"
    }, null, 8 /* PROPS */, ["title"])) : (_openBlock(), _createBlock(_component_input_field, {
      key: 1,
      modelValue: item.fieldWeight,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return item.fieldWeight = $event;
      },
      type: "number",
      min: "0",
      enabled: !$props.readOnly,
      onUpdated: function onUpdated($event) {
        return $options.emitChangeFieldWeigth($event, item, 'fieldweight');
      }
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "enabled", "onUpdated"]))], 2 /* CLASS */), _createElementVNode("td", {
      class: _normalizeClass([$data.cellCssStyles, $props.readOnly ? 'cursor-not-allowed' : 'cursor-pointer'])
    }, [_createVNode(_component_risma_title, {
      title: $options.notApplicableLabel,
      truncate: true,
      type: "small",
      class: _normalizeClass([{
        'table-bg-blue': !item.value
      }, 'text-center p-1 rounded-lg']),
      onClick: function onClick($event) {
        return $options.onLabelClick(key, null);
      }
    }, null, 8 /* PROPS */, ["title", "class", "onClick"])], 2 /* CLASS */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, function (option, optIndex) {
      return _openBlock(), _createElementBlock("td", {
        key: optIndex,
        class: _normalizeClass([$data.cellCssStyles, $props.readOnly ? 'cursor-not-allowed' : 'cursor-pointer'])
      }, [!$props.editable ? (_openBlock(), _createBlock(_component_risma_title, {
        key: 0,
        title: option.value,
        truncate: true,
        type: "small",
        class: _normalizeClass([{
          'table-bg-blue': option.id === item.value
        }, 'text-center p-1 rounded-lg']),
        onClick: function onClick($event) {
          return $options.onLabelClick(key, option.id);
        }
      }, null, 8 /* PROPS */, ["title", "class", "onClick"])) : (_openBlock(), _createBlock(_component_input_field, {
        key: 1,
        modelValue: option.value,
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return option.value = $event;
        },
        type: "text",
        enabled: !$props.readOnly,
        onUpdated: function onUpdated($event) {
          return $options.emitChangeField($event, option, 'fieldvalue');
        }
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "enabled", "onUpdated"]))], 2 /* CLASS */);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */)), $props.showResult ? (_openBlock(), _createElementBlock("tr", {
    key: 0,
    class: _normalizeClass({
      'bg-gray-125': $data.rows.length % 2 === 0
    })
  }, [_createElementVNode("td", {
    class: _normalizeClass($data.cellCssStyles)
  }, [_createVNode(_component_risma_title, {
    title: $data.translate.result,
    type: "medium",
    class: "text-left pl-2"
  }, null, 8 /* PROPS */, ["title"])], 2 /* CLASS */), _createElementVNode("td", {
    class: _normalizeClass($data.cellCssStyles)
  }, null, 2 /* CLASS */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.rowLength, function (child, childIndex) {
    return _openBlock(), _createElementBlock("td", {
      key: childIndex,
      class: _normalizeClass($data.cellCssStyles)
    }, [$props.finalAxisScore === childIndex - 1 ? (_openBlock(), _createElementBlock("span", _hoisted_2, " x ")) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */)) : _createCommentVNode("v-if", true)])], 2 /* CLASS */), !$props.editable ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    title: "".concat($data.translate.calculationSetup, ": ").concat($options.getCalcSetupTitle),
    type: "small",
    class: "ml-4"
  }, null, 8 /* PROPS */, ["title"])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.calculationSetup, ":"),
    type: "medium",
    class: "mr-4 font-semibold"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
    modelValue: $props.calculationType,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $props.calculationType = $event;
    }),
    options: $options.calculationTypeOptions,
    "un-sorted": true,
    disabled: $props.readOnly,
    placeholder: "Select"
  }, null, 8 /* PROPS */, ["modelValue", "options", "disabled"])])]))]);
}