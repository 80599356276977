import i18n from '@/i18n';
export var getTranslate = {
  Mypage: function Mypage() {
    return {
      success: i18n.t('Success'),
      welcome: i18n.t('Welcome'),
      contentVisibility: i18n.t('Content visibility'),
      sortBy: i18n.t('Sort by'),
      choose: i18n.t('Choose'),
      theReportWasSuccessfullyDeleted: i18n.t('The report was successfully deleted'),
      deleteReport: i18n.t('Delete report'),
      delete: i18n.t('Delete'),
      theSavedReportWillBeDeleted: i18n.t('The saved report will be deleted')
    };
  }
};