function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import ControlAddDeadlineModal from '@/components/Molecules/Controls/ControlAddDeadlineModal';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Alert from '@/components/Molecules/Modal/Alert';
import * as ControlsAPI from '@/api/controls/controls';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { DateFormats } from '@/constants/DateFormats';
import { setFrequencies } from '@/utils/controls/utils';
import { getDateOptions } from '@/utils/date/getDateOptions';
import { compareDates } from '@/utils/compare';
import { MODULES } from '@/constants/modules';
import { getCurrentYear, getCurrentMonthIndex, calculateDifference, getMonthIndex, getMonthsNames, formatCurrentDateWithCustomValues, formatTimeToDateTimeFormat, formatCurrentDateTime } from '@/utils/date';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
var AFTER_SELECTOR_FROM_VALUE = 'after';
var AFTER_SELECTOR_BEFORE_VALUE = 'before';
var FREQUENCY_API_KEY_DEADLINE = 'deadline';
var FREQUENCY_API_KEY_STARTDATE = 'startdate';
var FREQUENCY_API_KEY_DEADLINE_DAYS = 'deadlineDays';
var FREQUENCY_API_KEY_DEADLINE_BEFORE_AFTER = 'deadlineBeforeAfter';
var FREQUENCY_API_KEY_FREQ = 'freq';
var FREQUENCY_API_KEY_CONTROL_ID = 'controlId';
var FREQUENCY_API_KEY_TIMEZONE = 'timezone';
var MAX_WORKING_DAYS = 28;
export default {
  name: 'ControlFrequency',
  introduction: 'A component for manipulations with Control Frequency.',
  description: 'This is a Molecule component for /controls2/controls/:id. Parent component: ControlDetails',
  token: '<control-frequency :control="control"></control-frequency>',
  components: {
    RismaToggle: RismaToggle,
    SingleSelect: SingleSelect,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    ControlAddDeadlineModal: ControlAddDeadlineModal,
    FeatherIcon: FeatherIcon,
    Alert: Alert
  },
  props: {
    control: {
      type: Object,
      required: true,
      note: 'Main control data object.'
    },
    withoutPreview: {
      type: Boolean,
      required: false,
      default: false,
      note: 'This prop is needed mainly for jest testing'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is could be values changes'
    },
    isDaughter: {
      type: Boolean,
      required: true,
      note: 'Is daughter control?'
    },
    reviewDaysSettingDefaultValue: {
      type: [Number, String],
      required: true,
      note: 'The default value for review days setting'
    }
  },
  emits: ['turnYellowChanged', 'changed', 'toggleInnerModal', 'updateControlDeadline', 'updateReviewOffset'],
  data: function data() {
    var _storeFrequencyObj;
    return {
      isLoading: false,
      storeFrequencyObj: (_storeFrequencyObj = {}, _defineProperty(_storeFrequencyObj, FREQUENCY_API_KEY_DEADLINE, ''), _defineProperty(_storeFrequencyObj, FREQUENCY_API_KEY_STARTDATE, ''), _defineProperty(_storeFrequencyObj, FREQUENCY_API_KEY_DEADLINE_DAYS, null), _defineProperty(_storeFrequencyObj, FREQUENCY_API_KEY_DEADLINE_BEFORE_AFTER, ''), _defineProperty(_storeFrequencyObj, FREQUENCY_API_KEY_FREQ, null), _defineProperty(_storeFrequencyObj, FREQUENCY_API_KEY_CONTROL_ID, null), _defineProperty(_storeFrequencyObj, FREQUENCY_API_KEY_TIMEZONE, null), _storeFrequencyObj),
      deadlines: [],
      dateFormat: DateFormats.DATE_FORMAT,
      deadline: null,
      startDate: null,
      calculatedDeadline: false,
      frequencySelectedOption: {},
      workingDefaultDay: {
        id: 0,
        value: 0
      },
      workingDays: [],
      workingSelectedDay: {},
      unSorted: true,
      afterOptions: [],
      afterSelectedOption: {},
      months: [],
      selectedMonth: {},
      trafficLightIntervalAsString: '',
      trafficLightIntervalValueSelected: '',
      trafficLightIntervalOptions: [],
      translate: getTranslate['ControlFrequency'](),
      showAddDeadlineModal: false,
      showChangeFrequencyModal: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    changeFrequencyMessage: function changeFrequencyMessage() {
      return [this.translate.youAreInTheProcessOfDeletingDeadlineDate, this.translate.toContinuePressOk];
    },
    allOptions: function allOptions() {
      return setFrequencies(this.getSettingValue, MODULES.CONTROLS);
    },
    isCustomMode: function isCustomMode() {
      return this.isCustomDeadlineEnabled && this.control.frequency === RismaControlsUtils.frequencies.FREQ_CUSTOM;
    },
    optionsFiltered: function optionsFiltered() {
      return this.allOptions.filter(function (item) {
        return item.value === RismaControlsUtils.frequencies.FREQ_MONTHLY || item.value === RismaControlsUtils.frequencies.FREQ_QUARTERLY;
      });
    },
    frequencyOptions: function frequencyOptions() {
      return this.control.calculatedDeadline ? this.optionsFiltered : this.allOptions;
    },
    showFrequencyPreview: function showFrequencyPreview() {
      return (this.control.isMothercontrol !== 1 || this.control.frequency === RismaControlsUtils.frequencies.FREQ_CUSTOM) && this.control.frequency !== RismaControlsUtils.frequencies.FREQ_ONE_TIME;
    },
    deadlinesToDisplay: function deadlinesToDisplay() {
      var deadlines = this.deadlines.slice(0).map(function (dl) {
        dl.deadline = formatTimeToDateTimeFormat(dl.deadline);
        return dl;
      });
      if (this.control.frequency !== RismaControlsUtils.frequencies.FREQ_CUSTOM) {
        deadlines.unshift({
          deadline: this.control.deadline
        });
      }
      return deadlines.sort(function (date1, date2) {
        return compareDates(date1.deadline, date2.deadline);
      });
    },
    controlHasReviewer: function controlHasReviewer() {
      var _this$control$userIds;
      return !!((_this$control$userIds = this.control.userIds) !== null && _this$control$userIds !== void 0 && _this$control$userIds.review.length);
    },
    isFrequencySelectDisabled: function isFrequencySelectDisabled() {
      return this.disabled || this.isCustomMode && !!this.deadlines.length;
    },
    isCustomDeadlineEnabled: function isCustomDeadlineEnabled() {
      return !!this.getSettingValue('feature.custom_deadlines');
    },
    optionsDaysBetweenDeadlineAndReview: function optionsDaysBetweenDeadlineAndReview() {
      var _this$control$motherc;
      var options = getDateOptions('day', 'days', this.$trans);
      var defaultValue = this.reviewDaysSettingDefaultValue;
      if ((_this$control$motherc = this.control.mothercontrol) !== null && _this$control$motherc !== void 0 && _this$control$motherc.reviewOffset) {
        defaultValue = this.control.mothercontrol.reviewOffset;
      }
      return [{
        value: null,
        label: "".concat(this.$trans('Default'), " (").concat(defaultValue, " ").concat(defaultValue > 1 ? this.$trans('days') : this.$trans('day'), ")")
      }, {
        value: 0,
        label: '0 ' + this.$trans('days')
      }].concat(_toConsumableArray(options));
    }
  }),
  watch: {
    'control.deadline': function controlDeadline() {
      this.updateStoreFrequency();
      this.getFrequencyPreview();
    }
  },
  mounted: function mounted() {
    this.getAll();
  },
  methods: {
    getAll: function getAll() {
      this.initTrafficLightTurnsYellow();
      this.workingDays = this.prepareWorkingDays(MAX_WORKING_DAYS);
      this.afterOptions = this.prepareAfterOptions();
      this.months = this.prepareMonths();

      // Default 'Daily' when empty
      if (!this.control.frequency) {
        this.control.frequency = RismaControlsUtils.frequencies.FREQ_DAILY;
      }
      this.getAllSelectedInitial();
      this.updateStoreFrequency();
      this.getFrequencyPreview();
    },
    initTrafficLightTurnsYellow: function initTrafficLightTurnsYellow() {
      this.fillTrafficLightIntervalOptions();
      this.setTrafficLightIntervalValueSelected();
      var selected = this.getTrafficLightIntervalOptionSelected();
      this.trafficLightIntervalAsString = selected.label;
    },
    getTrafficLightIntervalOptionSelected: function getTrafficLightIntervalOptionSelected() {
      var _this = this;
      var selected = this.trafficLightIntervalOptions.filter(function (item) {
        return item.value === _this.control.turnYellowDaysBeforeDeadLine;
      })[0];
      return selected ? selected : {};
    },
    setTrafficLightIntervalValueSelected: function setTrafficLightIntervalValueSelected() {
      var selected = this.getTrafficLightIntervalOptionSelected();
      this.trafficLightIntervalValueSelected = selected.value || '';
    },
    fillTrafficLightIntervalOptions: function fillTrafficLightIntervalOptions() {
      this.trafficLightIntervalOptions = getDateOptions('day before deadline', 'days before deadline', this.$trans);
    },
    onSelectTrafficLight: function onSelectTrafficLight(selectedValue) {
      this.trafficLightIntervalValueSelected = selectedValue ? selectedValue : 1;
      this.$emit('turnYellowChanged', selectedValue);
    },
    onSelectReviewOffset: function onSelectReviewOffset(selectedValue) {
      this.$emit('updateReviewOffset', {
        reviewOffset: selectedValue
      });
    },
    getAllSelectedInitial: function getAllSelectedInitial() {
      this.frequencySelectedOption = this.findFrequencySelected(this.control.frequency);
      this.calculatedDeadline = this.isCalcDeadlineActive();
      this.workingSelectedDay = this.findWorkingSelected();
      this.afterSelectedOption = this.findAfterSelected();
      this.selectedMonth = this.findMonthSelected();
    },
    getFrequencyPreview: function getFrequencyPreview() {
      return this.makeApiCall(this.storeFrequencyObj);
    },
    updateStoreFrequency: function updateStoreFrequency() {
      var _this$storeFrequencyO;
      this.deadline = this.findDeadline();
      this.startDate = this.findStartDate(this.calculatedDeadline);
      this.storeFrequencyObj = (_this$storeFrequencyO = {}, _defineProperty(_this$storeFrequencyO, FREQUENCY_API_KEY_DEADLINE, this.deadline), _defineProperty(_this$storeFrequencyO, FREQUENCY_API_KEY_STARTDATE, this.startDate), _defineProperty(_this$storeFrequencyO, FREQUENCY_API_KEY_DEADLINE_DAYS, this.workingSelectedDay.value), _defineProperty(_this$storeFrequencyO, FREQUENCY_API_KEY_DEADLINE_BEFORE_AFTER, this.afterSelectedOption.value), _defineProperty(_this$storeFrequencyO, FREQUENCY_API_KEY_FREQ, this.frequencySelectedOption.value), _defineProperty(_this$storeFrequencyO, FREQUENCY_API_KEY_CONTROL_ID, this.control.id), _defineProperty(_this$storeFrequencyO, FREQUENCY_API_KEY_TIMEZONE, this.control.deadlineTimezone), _this$storeFrequencyO);
    },
    handleCalculateAfterUpdates: function handleCalculateAfterUpdates() {
      var _this2 = this;
      this.updateStoreFrequency();
      this.getFrequencyPreview().then(function () {
        return _this2.baseChange();
      });
    },
    makeApiCall: function makeApiCall(obj) {
      var _this3 = this;
      if (!obj) {
        return;
      }
      this.isLoading = true;
      return ControlsAPI.getDeadlines(_objectSpread(_objectSpread({}, obj), {}, {
        format: 'object'
      })).then(function (data) {
        _this3.deadlines = data.list;
      }).finally(function () {
        _this3.isLoading = false;
      });
    },
    onCalculateDeadlineChange: function onCalculateDeadlineChange(data) {
      this.control.calculatedDeadline = data;
      // If frequency !== Monthly or Quarterly
      if (this.control.calculatedDeadline) {
        if (this.control.frequency !== RismaControlsUtils.frequencies.FREQ_MONTHLY && this.control.frequency !== RismaControlsUtils.frequencies.FREQ_QUARTERLY) {
          this.setDefaultFrequency();
        }
      }

      // Recalculates
      this.workingSelectedDay = this.findWorkingSelected(true);
      this.afterSelectedOption = this.findAfterSelected(true);
      this.selectedMonth = this.findMonthSelected(true);
      this.updateStoreFrequency();
      this.getFrequencyPreview();
      this.baseChange();
    },
    findFrequencySelected: function findFrequencySelected(frequency) {
      return this.allOptions.filter(function (item) {
        return item.value === frequency;
      })[0];
    },
    findDeadline: function findDeadline() {
      return this.control.deadline;
    },
    findStartDate: function findStartDate(calculated) {
      var result = this.control.startdate; // Default from Control model

      if (calculated) {
        var currentYear = getCurrentYear();
        var month = this.selectedMonth.value;
        var initialDate = 1;
        result = formatCurrentDateWithCustomValues(currentYear, month, initialDate, DateFormats.DATE_TIME_FORMAT_FULL);
      }
      return result;
    },
    findWorkingSelected: function findWorkingSelected(calculateChanged) {
      var _this4 = this;
      var result = [];
      if (this.calculatedDeadline) {
        result = Object.assign({}, this.workingDays[0]);
        if (calculateChanged) {
          return result;
        }

        // If present in Control
        if (this.control.deadlineDays) {
          result = this.workingDays.filter(function (item) {
            return item.value === _this4.control.deadlineDays;
          })[0];
        }
      } else {
        // Always restore to workingDefaultDay.value === 0;
        result = Object.assign({}, this.workingDefaultDay);
      }
      return result;
    },
    findAfterSelected: function findAfterSelected(calculateChanged) {
      var _this5 = this;
      var defaultOption = Object.assign({}, this.afterOptions[1]);
      var result = defaultOption; // Default: before

      if (calculateChanged) {
        return result;
      }
      result = this.afterOptions.filter(function (item) {
        return item.value === _this5.control.deadlineBeforeAfter;
      })[0];

      // Fallback if this.control.deadlineBeforeAfter === null
      if (!result) {
        result = defaultOption;
      }
      return result;
    },
    findMonthSelected: function findMonthSelected(calculateChanged) {
      var monthIndex = getCurrentMonthIndex(),
        result;
      if (!calculateChanged) {
        if (this.calculatedDeadline) {
          monthIndex = getMonthIndex(this.control.startdate);
        }
      }
      result = this.months.filter(function (item) {
        return item.id === monthIndex;
      })[0];
      return result;
    },
    setDefaultFrequency: function setDefaultFrequency() {
      this.control.frequency = RismaControlsUtils.frequencies.FREQ_MONTHLY;
      this.frequencySelectedOption = this.findFrequencySelected(this.control.frequency);
    },
    isCalcDeadlineActive: function isCalcDeadlineActive() {
      return this.control.calculatedDeadline;
    },
    prepareWorkingDays: function prepareWorkingDays(n) {
      return Array.from(new Array(n), function (val, index) {
        return index + 1;
      }).map(function (item) {
        return {
          label: item + '',
          value: item
        };
      });
    },
    prepareAfterOptions: function prepareAfterOptions() {
      return [{
        id: 1,
        label: this.translate.fromTheBeginningOfTheMonth,
        value: AFTER_SELECTOR_FROM_VALUE
      }, {
        id: 2,
        label: this.translate.beforeTheEndOfTheMonth,
        value: AFTER_SELECTOR_BEFORE_VALUE
      }];
    },
    prepareMonths: function prepareMonths() {
      // this.months need to be refactored this.months
      var result = getMonthsNames();
      result = result.map(function (item, index) {
        return {
          id: index,
          value: index,
          label: item
        };
      });
      return result;
    },
    onSelectFrequency: function onSelectFrequency(changed) {
      var selected = this.frequencyOptions.find(function (option) {
        return option.value === changed;
      });
      if (selected) {
        this.control.frequency = changed;
        this.frequencySelectedOption = selected;
      } else {
        this.setDefaultFrequency();
      }
      this.updateStoreFrequency();
      this.getFrequencyPreview();
      this.baseChange();
    },
    onSelectWorkingDay: function onSelectWorkingDay(changed) {
      var selected = this.workingDays.find(function (option) {
        return option.value === changed;
      });
      if (selected) {
        this.workingSelectedDay = selected;
      } else {
        this.workingSelectedDay = this.workingDays[0];
      }
      this.handleCalculateAfterUpdates();
    },
    onSelectAfter: function onSelectAfter(changed) {
      var selected = this.afterOptions.find(function (option) {
        return option.value === changed;
      });
      if (selected) {
        this.afterSelectedOption = selected;
      } else {
        this.selectedMonth = this.afterOptions[1];
      }
      this.handleCalculateAfterUpdates();
    },
    baseChange: function baseChange() {
      var data = {
        calculatedDeadline: this.calculatedDeadline,
        deadlineDays: this.workingSelectedDay.value,
        deadlineBeforeAfter: this.afterSelectedOption.value,
        frequency: this.frequencySelectedOption.value,
        startdate: this.startDate
      };
      this.$emit('changed', data);
    },
    onCustomDeadlineDelete: function onCustomDeadlineDelete(id) {
      if (this.deadlines.length > 1) {
        this.deleteDeadlines([id]);
      } else {
        this.toggleChangeFrequencyModal();
      }
    },
    onDeadlinesChange: function onDeadlinesChange(newDeadlineDates) {
      var deadlineIds = [];
      var newDeadlinesToCreate = [];
      var deadlinesToDelete = [];
      if (this.deadlines.length && !newDeadlineDates.length) {
        this.toggleChangeFrequencyModal();
        return;
      }
      newDeadlineDates.forEach(function (obj) {
        obj.id ? deadlineIds.push(obj.id) : newDeadlinesToCreate.push(obj.deadline);
      });
      this.deadlines.forEach(function (obj) {
        if (!deadlineIds.includes(obj.id)) {
          deadlinesToDelete.push(obj.id);
        }
      });
      if (newDeadlinesToCreate.length) {
        this.createDeadlines(newDeadlinesToCreate);
      }
      if (deadlinesToDelete.length) {
        this.deleteDeadlines(deadlinesToDelete);
      }
    },
    createDeadlines: function createDeadlines(deadlines) {
      var _this6 = this;
      var promises = [];
      deadlines.forEach(function (deadline) {
        promises.push(_this6.createDeadline(deadline));
      });
      return Promise.all(promises).then(function (newDeadlines) {
        var _this6$deadlines;
        (_this6$deadlines = _this6.deadlines).push.apply(_this6$deadlines, _toConsumableArray(newDeadlines));
        _this6.$nextTick(function () {
          _this6.afterDeadlinesChange();
        });
      });
    },
    createDeadline: function createDeadline(deadline) {
      return ControlsAPI.createCustomDeadline({
        controlId: this.control.id,
        deadline: new Date(deadline).toUTCString()
      });
    },
    deleteDeadlines: function deleteDeadlines(idsToDelete) {
      var _this7 = this;
      var promises = [];
      idsToDelete.forEach(function (id) {
        promises.push(_this7.deleteDeadline(id));
      });
      return Promise.all(promises).then(function () {
        _this7.deadlines = _this7.deadlines.filter(function (_ref) {
          var id = _ref.id;
          return !idsToDelete.includes(id);
        });
        _this7.$nextTick(function () {
          _this7.afterDeadlinesChange();
        });
      });
    },
    afterDeadlinesChange: function afterDeadlinesChange() {
      if (!this.deadlines.length) return;
      var firstDeadline = this.deadlinesToDisplay[0].deadline;
      if (calculateDifference(firstDeadline, this.control.deadline) !== 0) {
        this.$emit('updateControlDeadline', firstDeadline);
      }
    },
    changeFrequencyToOneTime: function changeFrequencyToOneTime() {
      if (this.deadlines.length) {
        this.deleteAllCustomDeadlines();
      }
      this.toggleChangeFrequencyModal();
      this.onSelectFrequency(RismaControlsUtils.frequencies.FREQ_ONE_TIME);
      this.$emit('updateControlDeadline', formatCurrentDateTime(DateFormats.DATE_TIME_FORMAT));
    },
    deleteAllCustomDeadlines: function deleteAllCustomDeadlines() {
      var deadlinesToDelete = [];
      this.deadlines.forEach(function (obj) {
        deadlinesToDelete.push(obj.id);
      });
      this.deleteDeadlines(deadlinesToDelete);
    },
    deleteDeadline: function deleteDeadline(id) {
      return ControlsAPI.deleteCustomDeadline(id);
    },
    toggleAddDeadlineModal: function toggleAddDeadlineModal() {
      this.showAddDeadlineModal = !this.showAddDeadlineModal;
      this.$emit('toggleInnerModal', this.showAddDeadlineModal);
    },
    toggleChangeFrequencyModal: function toggleChangeFrequencyModal() {
      this.showChangeFrequencyModal = !this.showChangeFrequencyModal;
      this.$emit('toggleInnerModal', this.showChangeFrequencyModal);
    }
  }
};