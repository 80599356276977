function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { getRisksDashboardsOverview as _getRisksDashboardsOverview, getRiskProjects } from '@/api/risk/risk';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaBarChart from '@/components/Molecules/Charts/RismaBarChart';
import RismaLineChart from '@/components/Molecules/Charts/RismaLineChart';
import { RiskStatus } from '@/constants/risks/RiskStatus';
import { RismaHorisontalBarChartMixin } from '@/mixins/RismaHorisontalBarChartMixin';
import { checkERMByProjectId } from '@/utils/risks/check';
export default {
  name: 'RisksDashboard',
  introduction: 'Risk Dashboard page component',
  description: 'Complex page component, /risk2/projects/{n}/dashboard',
  token: '<risk-dashboard />',
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    RismaLineChart: RismaLineChart,
    RismaBarChart: RismaBarChart
  },
  mixins: [RismaHorisontalBarChartMixin],
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Id of the Risk Project'
    }
  },
  data: function data() {
    return {
      charts: [],
      topStatus: {},
      periods: [],
      periodSelectedValue: null,
      isLoaded: false,
      translate: getTranslate['RisksDashboard']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    isERM: function isERM() {
      return checkERMByProjectId(this.projectId, this.currentUser);
    },
    totalControl: function totalControl() {
      var _this = this;
      return Object.keys(this.topStatus).map(function (item) {
        return {
          label: item,
          value: _this.topStatus[item]
        };
      });
    },
    periodsSelect: function periodsSelect() {
      return this.periods.map(function (item) {
        return {
          label: item.title,
          value: item.id
        };
      });
    }
  }),
  watch: {
    periodSelectedValue: function periodSelectedValue() {
      this.getRisksDashboardsOverview(this.projectId, this.periodSelectedValue);
    },
    $route: function $route() {
      var _this2 = this;
      this.getData().then(function () {
        return _this2.isLoaded = true;
      });
    }
  },
  mounted: function mounted() {
    var _this3 = this;
    this.getData().then(function () {
      return _this3.isLoaded = true;
    });
  },
  methods: {
    getComponent: function getComponent(type) {
      if (type === 'bar') {
        return 'RismaBarChart';
      } else if (type === 'line') {
        return 'RismaLineChart';
      }
      return '';
    },
    getData: function getData() {
      if (this.isERM) {
        return this.getRisksDashboardsOverview(this.projectId);
      }
      return this.getRisksProjects();
    },
    getRisksDashboardsOverview: function getRisksDashboardsOverview(projectId, periodId) {
      var _this4 = this;
      return _getRisksDashboardsOverview(projectId, periodId).then(function (_ref) {
        var charts = _ref.charts,
          topStatus = _ref.topStatus;
        _this4.charts = _this4.prepareChartsData(charts);
        _this4.topStatus = topStatus;
      });
    },
    getRisksProjects: function getRisksProjects() {
      var _this5 = this;
      return getRiskProjects().then(function (_ref2) {
        var list = _ref2.list;
        var currentRiskProject = list.find(function (item) {
          return +item.id === +_this5.projectId;
        });
        _this5.periods = currentRiskProject.projectPeriods.filter(function (period) {
          return period.status !== RiskStatus.DRAFT;
        });
        _this5.periodSelectedValue = _this5.periods.find(function (item) {
          return item.isCurrent;
        }).id;
      });
    },
    // doesn't break RismaLineChart - it doesn't have height parameter
    calculateHeights: function calculateHeights(chart) {
      var minBarLength = 25;
      var length = chart._labels.length;
      var defaultHeight = 400;
      return Math.max(defaultHeight, minBarLength * length);
    }
  }
};