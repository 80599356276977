import { Http } from '../index';
import { generateDataQueryString, generateFiltersQueryString, handleRequestResponse } from '@/api/httpUtils';
export function getCustomFieldsReport(params) {
  var url = '/customfields/report';
  if (params) {
    url += params;
  }
  return handleRequestResponse(Http.get(url));
}
export function getReports(params) {
  var url = '/utils/reports';
  if (params) {
    url += params;
  }
  return handleRequestResponse(Http.get(url));
}
export function saveReport(data) {
  return handleRequestResponse(Http.post('/utils/reports', data));
}
export function deleteReport(id) {
  return handleRequestResponse(Http.delete("/utils/reports/".concat(id)));
}
export function editReport(id, data) {
  return handleRequestResponse(Http.patch("/utils/reports/".concat(id), data));
}
export function getControlDataReport() {
  var deadlines = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var deadlineAfter = deadlines.deadlineAfter,
    deadlineBefore = deadlines.deadlineBefore,
    deadlineBeforeTime = deadlines.deadlineBeforeTime;
  var dataParams = ['organisationIds', 'associations', 'dailyTeamIds', 'attachments', 'reviews', 'completedFiles'];
  var query = '?' + generateDataQueryString(dataParams);
  if (deadlineAfter && deadlineBefore) {
    var filterParams = {
      dateAfter: deadlineAfter,
      dateBefore: deadlineBefore + '' + deadlineBeforeTime
    };
    query += '&' + generateFiltersQueryString(filterParams);
  }
  return getCustomFieldsReport(query);
}