import { getTranslate } from './translate';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
export default {
  name: 'ScoringInfoBox',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle
  },
  description: 'Shows changes since last scoring period: new/moved/closed',
  token: '<scoring-info-box :scoring="{}"><scoring-info-box/>',
  props: {
    scoring: {
      type: Object,
      required: true,
      note: 'Scoring period'
    }
  },
  data: function data() {
    return {
      showScoring: false,
      translate: getTranslate['ScoringInfoBox']()
    };
  },
  computed: {
    newRisksTitle: function newRisksTitle() {
      return "".concat(this.scoring.newRisks.length, " ").concat(this.scoring.newRisks.length !== 1 ? this.translate.newRisks : this.translate.newRisk);
    },
    movedRisksTitle: function movedRisksTitle() {
      return "".concat(this.scoring.movedRisks.length, " ").concat(this.scoring.movedRisks.length !== 1 ? this.translate.movedRisks : this.translate.movedRisk);
    },
    closedRisksTitle: function closedRisksTitle() {
      return "".concat(this.scoring.closedRisks.length, " ").concat(this.scoring.closedRisks.length !== 1 ? this.translate.closedRisks : this.translate.closedRisk);
    }
  },
  methods: {
    getRiskFullTitle: function getRiskFullTitle(risk) {
      var withRpi = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var rpi = withRpi ? " (RPI ".concat(risk.rpiCurrent > risk.rpiPrevious ? this.translate.increasedWith : this.translate.decreasedWith, " ").concat(risk.rpiDelta, ")") : '';
      return "".concat(risk.rno, ". ").concat(risk.title).concat(rpi);
    }
  }
};