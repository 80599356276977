import { compareStrings } from '@/utils/compare';
export var KpiSortingMixin = {
  data: function data() {
    var _this = this;
    return {
      weightLabel: 'weight',
      titleLabel: 'cardHeader',
      sortingFunctions: {
        hw: function hw(a, b) {
          return parseFloat(b[_this.weightLabel]) - parseFloat(a[_this.weightLabel]);
        },
        lw: function lw(a, b) {
          return parseFloat(a[_this.weightLabel]) - parseFloat(b[_this.weightLabel]);
        },
        az: function az(a, b) {
          return compareStrings(a[_this.titleLabel], b[_this.titleLabel]);
        },
        za: function za(a, b) {
          return compareStrings(a[_this.titleLabel], b[_this.titleLabel], true);
        }
      },
      sortingOptions: [{
        label: this.$trans('Highest weighted'),
        value: 'hw'
      }, {
        label: this.$trans('Lowest weighted'),
        value: 'lw'
      }, {
        label: this.$trans('Alphabetically (A-Z)'),
        value: 'az'
      }, {
        label: this.$trans('Alphabetically (Z-A)'),
        value: 'za'
      }]
    };
  }
};