function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import { capitalize } from '@/utils';
import { getAllByType } from '@/api/compliance/informationAssets';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import InformationAssetCreationModal from '@/components/Molecules/InformationAssets/InformationAssetCreationModal';
import Notification from '@/components/Molecules/Notification';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import { generateKey } from '@/utils/Utils';
export default {
  name: 'InformationAssetsNew',
  description: 'Component to display info about all IA: processors, systems, controllers and custom ia',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    NotificationPopUp: NotificationPopUp,
    InformationAssetCreationModal: InformationAssetCreationModal,
    Notification: Notification,
    RismaTable: RismaTable
  },
  mixins: [NotificationPopUpMixin],
  props: {
    onlyMy: {
      type: Boolean,
      default: false,
      note: 'If show only my IA'
    },
    typeId: {
      type: [Number, String],
      required: false,
      default: null,
      note: 'Id of information asset type'
    },
    activityTypes: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      showCreateModal: false,
      notificationMessage: '',
      notificationType: 'error',
      timer: 0,
      debounceTimeout: 1000,
      tableGeneratedKey: generateKey(),
      translate: getTranslate['InformationAssets']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useUsersStore, ['users'])), {}, {
    tableStateKey: function tableStateKey() {
      return 'companies_table_' + this.typeId;
    },
    title: function title() {
      var activityTypeName = this.activityType ? capitalize(this.activityType.label) : this.$trans(capitalize(this.typeId));
      return this.onlyMy ? "".concat(this.translate.my, " ").concat(activityTypeName) : "".concat(activityTypeName);
    },
    activityType: function activityType() {
      var _this = this;
      return this.activityTypes.find(function (type) {
        return type.id == _this.typeId;
      });
    }
  }),
  watch: {
    typeId: function typeId() {
      this.refreshTable();
    }
  },
  methods: {
    getDataBE: function getDataBE(query) {
      var type = this.typeId > 0 ? AssetsTypes.INFORMATION_ASSETS_API : this.typeId;
      var q = this.onlyMy ? '?onlyMy=true&' : '?';
      return getAllByType(type, q + query);
    },
    createIA: function createIA(data) {
      this.toggleCreateModal();
      this.setNotificationData(data);
      this.getDataBE();
    },
    toggleCreateModal: function toggleCreateModal() {
      this.showCreateModal = !this.showCreateModal;
    },
    showError: function showError(e) {
      var _this2 = this;
      this.toggleCreateModal();
      this.notificationType = 'error';
      this.notificationMessage = e;
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        _this2.notificationMessage = '';
      }, this.$debounceTimeout);
    },
    refreshTable: function refreshTable() {
      this.tableGeneratedKey = generateKey();
    }
  }
};