import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0bb1ac38"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "risma-step-progress-bar"
};
var _hoisted_2 = {
  class: "icon-element"
};
var _hoisted_3 = {
  key: 0,
  class: "risma-progress-bar-total move-right"
};
var _hoisted_4 = {
  key: 1,
  class: "risma-progress-bar-total move-left"
};
var _hoisted_5 = {
  class: "text-element"
};
var _hoisted_6 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, function (item, indexName, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "working-element"
    }, [_createElementVNode("div", _hoisted_2, [index > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : _createCommentVNode("v-if", true), _createVNode(_component_feather_icon, {
      icon: $options.getIcon(item.state),
      color: $options.getColor(item.state),
      height: "70",
      width: "60",
      class: "stroke-3"
    }, null, 8 /* PROPS */, ["icon", "color"]), index < Object.keys($props.data).length - 1 ? (_openBlock(), _createElementBlock("div", _hoisted_4)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.titles, function (element, elementIndex) {
      return _openBlock(), _createElementBlock("div", {
        key: elementIndex,
        class: _normalizeClass({
          'text-center': item.titles.length == 1
        })
      }, [_createElementVNode("p", {
        innerHTML: element
      }, null, 8 /* PROPS */, _hoisted_6)], 2 /* CLASS */);
    }), 128 /* KEYED_FRAGMENT */))])]);
  }), 128 /* KEYED_FRAGMENT */))]);
}