import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full flex justify-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_admin_custom_fields_well = _resolveComponent("admin-custom-fields-well");
  var _component_admin_activity_types_well = _resolveComponent("admin-activity-types-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_admin_custom_fields_well, {
    class: "flex-grow mr-10"
  }), _createVNode(_component_admin_activity_types_well, {
    class: "flex-grow"
  })]);
}