import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1a7bcc5b"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vertical_menu = _resolveComponent("vertical-menu");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_vertical_menu, {
    items: $options.menuItems,
    class: "mr-8"
  }, null, 8 /* PROPS */, ["items"]), _createVNode(_component_router_view, {
    class: "flex-grow"
  })]);
}