function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import HeaderNavBar from '@/components/Organisms/HeaderNavBar';
import { defineAsyncComponent } from 'vue';
export default {
  el: '#risma',
  name: 'Chanel',
  components: {
    HeaderNavBar: HeaderNavBar,
    DescriptionModal: defineAsyncComponent(function () {
      return import( /* webpackChunkName: "updates" */'@/components/Molecules/Modal/DescriptionModal');
    })
  },
  data: function data() {
    return {
      updatesByPages: [],
      index: 0
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser', 'userIsLoggedIn'])), {}, {
    keyField: function keyField() {
      var _this = this;
      var key = this.$route.fullPath;
      this.$route.matched.forEach(function (route) {
        if (route.meta.keyFunction) {
          key = route.meta.keyFunction(_this.$route);
        }
      });
      return key;
    },
    updatesForPath: function updatesForPath() {
      var _this2 = this;
      return this.updatesByPages.find(function (item) {
        return !item.fuzzyMatch ? item.path === _this2.keyField : _this2.keyField.startsWith(item.path);
      });
    }
  })
};