import { Colors } from '@/Colors';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaProgressBar from '@/components/Atoms/ProgressBar/RismaProgressBar';
export default {
  name: 'CardExecution',
  introduction: '',
  description: '',
  token: '<card-execution :card="card"></card-execution>',
  components: {
    trafficlight: TrafficLight,
    RismaTitle: RismaTitle,
    RismaProgressBar: RismaProgressBar
  },
  props: {
    activity: {
      type: Object,
      required: true,
      note: 'Initiative object'
    }
  },
  data: function data() {
    return {
      Colors: Colors,
      translate: {
        deadline: this.$trans('Deadline'),
        done: this.$trans('done')
      }
    };
  }
};