import CompactTitleDataComponent from '@/components/Atoms/CompactTitleDataComponent';
import StaticTable from '@/components/Molecules/StaticTable';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import LoadMoreWrapper from '@/components/Molecules/LoadMoreWrapper';
import CardCompliance from '@/components/Organisms/General/Cards/CardCompliance';
import { viewTypes } from '@/constants/ListViewType';
import { prepareTitleWithTrafficLightData } from '@/utils/format/templateWrappers';
export default {
  name: 'MyProcesses',
  token: '<my-processes-list />',
  components: {
    PreviewList: PreviewList,
    CardCompliance: CardCompliance,
    StaticTable: StaticTable,
    LoadMoreWrapper: LoadMoreWrapper,
    CompactTitleDataComponent: CompactTitleDataComponent
  },
  props: {
    viewMode: {
      required: false,
      type: String,
      default: ''
    },
    visibleProps: {
      required: false,
      type: Array,
      default: function _default() {
        return [{
          key: 'title',
          text: 'Title'
        }, {
          key: 'description',
          text: 'Description'
        }, {
          key: 'project',
          text: 'Project'
        }, {
          key: 'values',
          text: 'Questions Answered'
        }];
      }
    },
    processes: {
      required: true,
      type: Array
    },
    title: {
      required: false,
      type: String,
      default: 'My processes',
      note: 'list`s title'
    }
  },
  emits: ['changeShowState'],
  data: function data() {
    return {
      viewTypes: viewTypes
    };
  },
  computed: {
    columns: function columns() {
      var _this = this;
      return this.visibleProps.map(function (item) {
        return _this.$trans(item.text);
      });
    },
    dataset: function dataset() {
      var _this2 = this;
      var result = [];
      result = this.processes.map(function (process) {
        var preparedItem = {};
        _this2.visibleProps.forEach(function (_ref) {
          var key = _ref.key;
          preparedItem[key] = _this2.prepareCompactHtml(key, process);
        });
        return preparedItem;
      });
      return result;
    }
  },
  methods: {
    prepareCompactHtml: function prepareCompactHtml(column, process) {
      var result = '';
      switch (column) {
        case 'title':
          return result = prepareTitleWithTrafficLightData(process, process.url);
        case 'description':
          return result = process[column];
        case 'project':
          return result = process[column].title;
        case 'values':
          return result = "".concat(process[column].questions_answered, " / ").concat(process[column].questions_count);
        default:
          result = process[column] || '';
      }
      return result;
    }
  }
};