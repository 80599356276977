import { getInformationAssetsTitle } from '@/utils/InformationAssets';
import { getProcessLibraryTitle } from '@/utils/TitleFromSetting';

// it can be called without trans, sometimes you need only id, so no need to double work
var QuestionTypes = function QuestionTypes() {
  var trans = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (text) {
    return text;
  };
  return {
    singleChoice: {
      id: 1,
      option: trans('Single choice')
    },
    multipleChoice: {
      id: 2,
      option: trans('Multiple choice')
    },
    freeText: {
      id: 3,
      option: trans('Free text')
    },
    attachment: {
      id: 5,
      option: trans('Attachment')
    },
    attachmentAndFreeText: {
      id: 7,
      option: trans('Attachment and free text')
    },
    informationAsset: {
      id: 4,
      option: getInformationAssetsTitle()
    },
    informationAssetSystems: {
      id: 9,
      option: "".concat(getInformationAssetsTitle(), " - ").concat(trans('Systems'))
    },
    informationAssetProcessors: {
      id: 10,
      option: "".concat(getInformationAssetsTitle(), " - ").concat(trans('Processors'))
    },
    informationAssetControllers: {
      id: 11,
      option: "".concat(getInformationAssetsTitle(), " - ").concat(trans('Controllers'))
    },
    processLibrary: {
      id: 12,
      option: getProcessLibraryTitle()
    },
    customInformationAsset: {
      id: 13,
      option: "".concat(getInformationAssetsTitle(), " - ").concat(trans('Custom'))
    },
    date: {
      id: 14,
      option: trans('Date')
    },
    dateTime: {
      id: 15,
      option: trans('Date & Time')
    }
  };
};
export default QuestionTypes;
export var questionTypesById = function questionTypesById(trans) {
  var allQuestionTypes = QuestionTypes(trans);
  var values = Object.values(allQuestionTypes);
  var entries = new Map(values.map(function (_ref) {
    var id = _ref.id,
      option = _ref.option;
    return [id, option];
  }));
  return Object.fromEntries(entries);
};