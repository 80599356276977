import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "new-asset relative flex w-64 mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_popup = _resolveComponent("risma-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_button, {
    text: $options.addNewLabel,
    disabled: $data.readOnly,
    type: "util",
    onClick: _withModifiers($options.toggleModal, ["stop"])
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "plus",
        class: "stroke-2 mr-2"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "disabled", "onClick"]), _createVNode(_component_risma_popup, {
    class: "new-modal well w-64",
    "is-hidden": !$data.showModal,
    onHide: $options.hideModal
  }, {
    default: _withCtx(function () {
      return [$data.showNotification && !$options.isAssetLabelValid ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.addAssetWarning), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
        modelValue: $data.assetLabel,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.assetLabel = $event;
        }),
        class: "mb-3",
        title: $options.modalLabel,
        "focus-on-mount": true,
        type: "text",
        onOnEnter: $options.addAsset
      }, null, 8 /* PROPS */, ["modelValue", "title", "onOnEnter"]), _createVNode(_component_risma_button, {
        text: $data.translate.add,
        type: "save",
        onClick: $options.addAsset
      }, null, 8 /* PROPS */, ["text", "onClick"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-hidden", "onHide"])]);
}