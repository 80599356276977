import i18n from '@/i18n';
export var getTranslate = {
  CompanyContacts: function CompanyContacts() {
    return {
      companyContacts: i18n.t('Company contacts'),
      deleteContact: i18n.t('Delete contact'),
      areYouSure: i18n.t('Are you sure?'),
      name: i18n.t('Name'),
      title: i18n.t('Title'),
      email: i18n.t('Email'),
      phone: i18n.t('Phone')
    };
  },
  CompanyDescription: function CompanyDescription() {
    return {
      links: i18n.t('Links'),
      apply: i18n.t('Apply'),
      cancel: i18n.t('Cancel'),
      chooseDataProcessor: i18n.t('Choose data processor'),
      createNew: i18n.t('Create new'),
      vatNumber: i18n.t('VAT number'),
      invalidVatNumber: i18n.t('Invalid VAT number'),
      getData: i18n.t('Get data'),
      syncDataWithExternalSource: i18n.t('Sync data with external source'),
      address: i18n.t('Address'),
      zipcode: i18n.t('Zipcode'),
      city: i18n.t('City'),
      phone: i18n.t('Phone'),
      countryCode: i18n.t('Country code'),
      email: i18n.t('E-mail'),
      invalidEmailAddress: i18n.t('Invalid e-mail address'),
      description: i18n.t('Description'),
      linkDataProcessor: i18n.t('Link data processor'),
      assets: i18n.t('Assets'),
      name: i18n.t('Name'),
      type: i18n.t('Type'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      attach: i18n.t('Attach'),
      companyHasBeenLinked: i18n.t('Company has been linked')
    };
  }
};