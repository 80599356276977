import CompanyEntry from '@/components/Pages/Companies/CompanyEntry';
import CompanyDescription from '@/components/Pages/Companies/CompanyDescription';
import LinksTab from '@/components/Organisms/LinksTab';
import Changelog from '@/components/Molecules/Changelog';
import CompanyContacts from '@/components/Pages/Companies/CompanyContacts';
import { TabTypes } from '@/constants/Tabs';
import { MODULES } from '@/constants/modules';
import { isAdmin, userHasCompaniesAccess } from '@/routes/auth';
export default (function () {
  return [{
    path: '/company/:id/',
    component: CompanyEntry,
    redirect: {
      name: 'CompanyDescription'
    },
    meta: {
      userRightsFunction: function userRightsFunction() {
        return userHasCompaniesAccess() || isAdmin();
      }
    },
    props: true,
    children: [{
      path: TabTypes.DESCRIPTION,
      name: 'CompanyDescription',
      component: CompanyDescription
    }, {
      path: TabTypes.CONTACTS,
      component: CompanyContacts
    }, {
      path: TabTypes.LINKS,
      component: LinksTab,
      props: {
        module: MODULES.COMPANY
      }
    }, {
      path: TabTypes.CHANGELOG,
      component: Changelog
    }]
  }];
});