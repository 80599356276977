import AttachmentModal from '@/components/Organisms/AttachmentModal';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
export default {
  name: '',
  components: {
    AttachmentModal: AttachmentModal,
    RismaButton: RismaButton
  },
  data: function data() {
    return {
      showModal: false
    };
  },
  methods: {
    showUpload: function showUpload(item) {
      alert("file data is - ".concat(JSON.stringify(item)));
    },
    toggleAttachmentModal: function toggleAttachmentModal() {
      this.showModal = !this.showModal;
    }
  }
};