function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import createFocusTrap from '@/utils/createFocusTrap';
export default {
  name: 'TrapFocusBoundary',
  props: {
    active: {
      type: Boolean,
      required: false,
      default: true
    },
    groupSelectors: {
      type: String,
      required: false,
      default: '',
      note: 'Selectors of containers which should be grouped into one trap module.'
    }
  },
  data: function data() {
    return {
      trapContext: null,
      domObserverList: [],
      observerConfig: {
        childList: true,
        subtree: true
      },
      updatingDelay: 500,
      updatingTimer: null,
      groupedElements: []
    };
  },
  watch: {
    groupSelectors: function groupSelectors() {
      this.disconnect();
      this.trapContext.remove();
      this.connect();
    }
  },
  beforeUnmount: function beforeUnmount() {
    this.disconnect();
    this.trapContext.remove();
  },
  mounted: function mounted() {
    var _this = this;
    this.$watch('active', function (value) {
      if (value) {
        _this.connect();
      } else {
        _this.disconnect();
      }
    }, {
      immediate: true
    });
  },
  methods: {
    findTrappingElements: function findTrappingElements() {
      var trapElements = [];
      if (this.groupSelectors) {
        var elements = document.querySelectorAll(this.groupSelectors);
        if (elements) {
          trapElements.push.apply(trapElements, _toConsumableArray(elements));
        }
      }
      trapElements.push(this.$el);
      this.groupedElements = trapElements;
    },
    updateTrapping: function updateTrapping() {
      var _this2 = this;
      this.updatingTimer && clearTimeout(this.updatingTimer);
      this.updatingTimer = setTimeout(function () {
        _this2.trapContext.update();
        _this2.updatingTimer = null;
      }, this.updatingDelay);
    },
    disconnect: function disconnect() {
      this.trapContext && this.trapContext.deactivate();
      this.domObserverList.forEach(function (observer) {
        observer.disconnect();
      });
    },
    connect: function connect() {
      this.findTrappingElements();
      this.trapContext = createFocusTrap(document.body, this.groupedElements);
      this.trapContext.activate();
      this.updateObservers();
    },
    updateObservers: function updateObservers() {
      this.prepareObserverList();
      this.activateObservers();
    },
    prepareObserverList: function prepareObserverList() {
      this.domObserverList = new Array(this.groupedElements.length).fill(new MutationObserver(this.updateTrapping));
    },
    activateObservers: function activateObservers() {
      var _this3 = this;
      this.domObserverList.forEach(function (observer, i) {
        observer.observe(_this3.groupedElements[i], _this3.observerConfig);
      });
    }
  }
};