import { renderSlot as _renderSlot, withKeys as _withKeys, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _directive_click_outside = _resolveDirective("click-outside");
  return !$props.isHidden ? _withDirectives((_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass([, 'pop-up']),
    onKeyup: _cache[0] || (_cache[0] = _withKeys(function () {
      return $options.hide && $options.hide.apply($options, arguments);
    }, ["esc"]))
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 32 /* HYDRATE_EVENTS */)), [[_directive_click_outside, $options.hide]]) : _createCommentVNode("v-if", true);
}