import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import { DateFormats } from '@/constants/DateFormats';
dayjs.extend(tz);
export function formatDateWithTimezone(date, timezone) {
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DateFormats.DATE_TIME_FORMAT_FULL;
  return dayjs(date).tz(timezone).format(format);
}
export function convertDateToUtcFormat(value, timezone) {
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DateFormats.DATE_TIME_FORMAT;
  return dayjs.tz(value, timezone).utc().format(format);
}
export function getEndOfDayInUtc(timezone) {
  return dayjs().tz(timezone).endOf('day').utc().format(DateFormats.DATE_TIME_FORMAT);
}
export function formatDateToUTCTimezone(date, timezone) {
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DateFormats.DATE_TIME_FORMAT;
  return dayjs.utc(date).tz(timezone).format(format);
}
export function toDateTimeFormatWithTimezone(date, timezone) {
  if (!date) return '';
  return formatDateToUTCTimezone(date, timezone);
}
export function prepareDateTimezone(date, timezone, format) {
  var result = '';
  if (date) {
    result = formatDateWithTimezone(date, timezone, format);
  }
  return result;
}
export function formatDateInTimeZoneWithOffset(date, offsetInDays, timezone) {
  return dayjs.utc(date).add(offsetInDays, 'day').tz(timezone).format(DateFormats.DATE_TIME_FORMAT);
}