function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Selector from '@/components/Atoms/Inputs/Selector';
import SearchableSelector from '@/components/Atoms/Inputs/SearchableSelector';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import { DelegationModalSettings, DelegationTypes } from '@/constants/relations/DelegationModal';
import { compareStrings } from '@/utils/compare';
import { sortAlphabetic } from '@/utils/sort';
import { checkERMByProjectId } from '@/utils/risks/check';
import { MODULES } from '@/constants/modules';
import { getUsersWithoutTimeout } from '@/api/risma/data';
export default {
  name: 'DelegationModal',
  introduction: 'DelegationModal multiselect popup',
  description: 'DelegationModal with  selector and list of selected items',
  token: '<delegation-modal :options-list=[] :preselected-items=[] />',
  components: {
    Selector: Selector,
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    RismaTitle: RismaTitle,
    SearchableSelector: SearchableSelector
  },
  props: {
    optionsList: {
      type: Array,
      required: true,
      note: 'List of all delegation options to choose from (users, organisations)'
    },
    delegationListLabel: {
      type: String,
      required: false,
      default: '',
      note: 'Display delegation list label that initiated modal for editing'
    },
    preselectedItems: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of already selected delegation items'
    },
    modalSettings: {
      type: Object,
      required: false,
      default: function _default() {
        return DelegationModalSettings.users;
      },
      note: 'Fields settings for delegation modal'
    },
    isMultiple: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Can multiple entities be assigned'
    },
    showAddItem: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Display add item button or not'
    },
    withReset: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Display remove icon next to selected items'
    },
    accessRights: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['updateDelegationItem', 'closeDelegationModal', 'create', 'update:optionsList'],
  data: function data() {
    return {
      selectedItemIds: [],
      isSelectExpanded: false,
      DelegationTypes: DelegationTypes,
      translate: getTranslate['DelegationModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    userAPIAdditionalParameters: function userAPIAdditionalParameters() {
      return {
        filters: this.getRightFiltersByAccess(this.accessRights)
      };
    },
    sortedOptionsList: function sortedOptionsList() {
      var _this = this;
      return _toConsumableArray(this.optionsList).sort(function (a, b) {
        return compareStrings(a[_this.modalSettings.labelKey], b[_this.modalSettings.labelKey]);
      });
    },
    selectedItems: function selectedItems() {
      var _this2 = this;
      return this.sortedOptionsList.filter(function (item) {
        return _this2.selectedItemIds.includes(item.id);
      });
    },
    selectableItems: function selectableItems() {
      var _this3 = this;
      return this.sortedOptionsList.filter(function (item) {
        return !_this3.selectedItemIds.includes(item.id);
      });
    },
    optionsLabel: function optionsLabel() {
      return this.delegationListLabel || this.$trans(this.modalSettings.label);
    },
    isCurrentUserSelected: function isCurrentUserSelected() {
      return this.selectedItemIds.includes(+this.currentUser.id);
    },
    showAssignToMeButton: function showAssignToMeButton() {
      return this.modalSettings.enableAssignToMe && !this.isCurrentUserSelected;
    }
  }),
  // so it would send selectedItemIds before searchableSelector is mounted;
  created: function created() {
    this.selectedItemIds = this.preselectedItems;
  },
  methods: {
    getRightFiltersByAccess: function getRightFiltersByAccess(data) {
      var module = data.module,
        projectId = data.projectId;
      switch (module) {
        case MODULES.RISK:
          return checkERMByProjectId(projectId, this.currentUser) ? {
            'hasAccessToModule': module
          } : {
            'hasAccessToRiskProject': projectId
          };
        case MODULES.COMPLIANCE:
          return {
            'hasAccessToComplianceProject': projectId
          };
        case MODULES.COMPLIANCE_PROCESS_TREE_NODE:
          return {
            'hasAccessToProcessLibrary': projectId
          };
        default:
          return {
            'hasAccessToModule': module
          };
      }
    },
    selected: function selected(item) {
      var updateOptionsList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (this.isMultiple) {
        this.selectedItemIds.push(item.id);
      } else {
        this.selectedItemIds = [item.id];
      }
      if (updateOptionsList) {
        this.$emit('update:optionsList', item);
      }
    },
    getUsersAPI: function getUsersAPI(params) {
      return getUsersWithoutTimeout(params).then(function (response) {
        response.list = sortAlphabetic(response.list, 'display_name');
        return Promise.resolve(response);
      });
    },
    deselect: function deselect(item) {
      this.selectedItemIds = this.selectedItemIds.filter(function (selectedId) {
        return item.id !== selectedId;
      });
    },
    openCreationModal: function openCreationModal() {
      this.$emit('create');
    },
    assignToMe: function assignToMe() {
      this.selected(this.currentUser, true);
    },
    toggleSelect: function toggleSelect(isExpanded) {
      this.isSelectExpanded = isExpanded;
    }
  }
};