import { getTranslate } from './translate';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import Alert from '@/components/Molecules/Modal/Alert';
export default {
  name: 'GapAttributesList',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    InputField: InputField,
    Alert: Alert
  },
  props: {
    category: {
      type: String,
      required: true,
      default: null
    },
    labels: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    labelErrors: {
      type: Object,
      required: false,
      default: function _default() {}
    },
    categoryError: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['updateCategory', 'updateLabels'],
  data: function data() {
    return {
      localLabels: [],
      isMounted: false,
      updateTimer: null,
      alertTitle: '',
      showDeleteAlert: false,
      idxToDelete: null,
      updateTime: 300,
      translate: getTranslate['GapAttributesList']()
    };
  },
  watch: {
    localLabels: {
      deep: true,
      handler: function handler() {
        if (!this.isMounted) return;
        this.emitLabels();
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.localLabels = this.labels.map(function (item) {
      return {
        label: item.label,
        id: item.id
      };
    });
    this.$nextTick(function () {
      _this.isMounted = true;
    });
  },
  methods: {
    onLabelUpdate: function onLabelUpdate(idx, value) {
      this.localLabels[idx].label = value;
    },
    onLabelDelete: function onLabelDelete(idx) {
      this.showDeleteAlert = true;
      this.idxToDelete = idx;
      this.alertTitle = this.localLabels.length <= 1 ? this.translate.areYouSureYouWantToDeleteAttributeCategory : this.translate.areYouSureYouWantToDeleteAttributeValue;
    },
    onAlertSubmit: function onAlertSubmit() {
      if (this.localLabels.length <= 1) {
        this.$emit('updateLabels', []);
      } else {
        this.localLabels.splice(this.idxToDelete, 1);
      }
      this.idxToDelete = null;
      this.showDeleteAlert = false;
    },
    onLabelCreate: function onLabelCreate() {
      this.localLabels.push({
        label: ''
      });
    },
    emitLabels: function emitLabels() {
      var _this2 = this;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(function () {
        _this2.$emit('updateLabels', _this2.localLabels);
      }, this.updateTime);
    }
  }
};