import ToggleMenu from '@/components/Atoms/ToggleMenu/ToggleMenu';
import { Colors } from '@/Colors';
export default {
  components: {
    ToggleMenu: ToggleMenu
  },
  data: function data() {
    return {
      toggleMenuOptions: [{
        icon: 'plus',
        title: 'Add new',
        style: {
          color: Colors.system.black
        },
        action: function action() {
          return alert('plus button clicked');
        }
      }, {
        title: 'disabled element',
        hidden: true
      }, {
        title: 'title without an icon',
        action: function action() {
          return alert('"title without an icon" clicked');
        }
      }]
    };
  }
};