import * as Cookies from 'tiny-cookie';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'PreviewList',
  introduction: 'Container for list',
  description: 'Accept slot, has show / hide feature',
  token: '<preview-list title="All list"> <slot /> </preview-list>',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon
  },
  props: {
    title: {
      type: String,
      required: true,
      note: 'A title of a list'
    },
    hideable: {
      type: Boolean,
      required: false,
      default: true
    },
    cookieHiddenName: {
      type: String,
      required: false,
      default: '',
      note: 'Save last state of show/hide feature in cookie'
    },
    length: {
      type: [Number, String],
      required: false,
      default: null,
      note: 'Show a sum of items in a list'
    },
    headerOffset: {
      type: String,
      required: false,
      default: '15px',
      note: 'Header side offset'
    },
    contentOverflowing: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If content can overflow and should be adjusted. Relevant for dynamic content'
    }
  },
  emits: ['changeShowState'],
  data: function data() {
    return {
      itemsVisible: !this.hideable,
      translate: {
        hide: this.$trans('Hide'),
        show: this.$trans('Show')
      }
    };
  },
  computed: {
    fullTitle: function fullTitle() {
      var count = this.length !== null && this.length !== '' ? "(".concat(this.length, ")") : '';
      if (!count) return this.title;
      return this.contentOverflowing ? "".concat(count, " ").concat(this.title) : "".concat(this.title, " ").concat(count);
    },
    showStateTitle: function showStateTitle() {
      return this.itemsVisible ? this.translate.hide : this.translate.show;
    }
  },
  mounted: function mounted() {
    var cookieValue = Cookies.getCookie(this.cookieHiddenName);
    var isHidden = cookieValue || cookieValue === 'hidden';
    this.itemsVisible = !this.hideable || !isHidden;
    this.$emit('changeShowState', this.itemsVisible);
  },
  methods: {
    changeShowState: function changeShowState(action) {
      if (this.hideable) {
        this.itemsVisible = action !== null ? action : !this.itemsVisible;
        if (this.itemsVisible) {
          Cookies.removeCookie(this.cookieHiddenName);
        } else {
          Cookies.setCookie(this.cookieHiddenName, 'hidden', {
            samesite: 'strict'
          });
        }
        this.$emit('changeShowState', this.itemsVisible);
      }
    }
  }
};