import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-da8020cc"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "fieldset"
};
var _hoisted_2 = {
  class: "fieldset_legend"
};
var _hoisted_3 = {
  class: "fieldset-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("fieldset", _hoisted_1, [_createElementVNode("legend", _hoisted_2, _toDisplayString($props.title), 1 /* TEXT */), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])]);
}