function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import i18n from '@/i18n';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { addOption as _addOption } from '@/api/compliance/questions';
import HelpText from '@/components/Atoms/HelpText/HelpText';
import NewChoice from '@/components/Molecules/Questionnaire/NewChoice';
import SingleAssetChoice from '@/components/Molecules/Questionnaire/SingleAssetChoice';
import SingleChoice from '@/components/Molecules/Questionnaire/SingleChoice';
import MultiChoice from '@/components/Molecules/Questionnaire/MultiChoice';
import ProcessLibraryChoice from '@/components/Molecules/Questionnaire/ProcessLibraryChoice';
import AttachmentChoice from '@/components/Molecules/Questionnaire/AttachmentChoice';
import FreeTextChoice from '@/components/Molecules/Questionnaire/FreeTextChoice';
import InformationAssetsChoice from '@/components/Molecules/Questionnaire/InformationAssetsChoice';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import { DateFormats } from '@/constants/DateFormats';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { formatTimeUtc } from '@/utils/date';
export default {
  name: 'Question',
  introduction: 'Question is common sketch from various questions types',
  description: 'Create new question based on props.',
  nodeElements: {},
  token: "\n<question :question=\"question.question\" :information-assets=\"question.informationAssets\" />\n// Look at KitchenSink for properties\n",
  components: {
    HelpText: HelpText,
    RismaTitle: RismaTitle,
    NewChoice: NewChoice,
    SingleAssetChoice: SingleAssetChoice,
    SingleChoice: SingleChoice,
    MultiChoice: MultiChoice,
    AttachmentChoice: AttachmentChoice,
    FreeTextChoice: FreeTextChoice,
    InformationAssetsChoice: InformationAssetsChoice,
    ProcessLibraryChoice: ProcessLibraryChoice,
    DateTimePicker: DateTimePicker
  },
  props: {
    question: {
      type: Object,
      required: true,
      note: 'Properties for question is id, question-number, headline, questiontype, help, question, informationAssets.'
    },
    informationAssets: {
      type: Object,
      required: true,
      note: 'Properties for answers depends on type of question.'
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of custom information assets activity types'
    },
    showHeadline: {
      type: Boolean,
      required: true,
      note: 'Should we add a RismaTitle with the question headline'
    },
    nodeElement: {
      required: false,
      type: Object,
      note: 'A list of all nodes',
      default: function _default() {
        return {};
      }
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Questionn is be read-only'
    },
    myInformationAssetsByType: {
      type: Object,
      default: function _default() {
        return {};
      },
      required: false
    }
  },
  emits: ['forceUpdate', 'newAsset', 'update'],
  data: function data() {
    return {
      t: {
        'question': i18n.t('Question'),
        'answers': i18n.t('Answers')
      },
      toggleQuestion: true,
      AssetsTypes: AssetsTypes
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    currentQuestion: function currentQuestion() {
      return JSON.parse(JSON.stringify(this.question));
    },
    filteredCustomIA: function filteredCustomIA() {
      var _this$question,
        _this$informationAsse,
        _this = this;
      if (!((_this$question = this.question) !== null && _this$question !== void 0 && (_this$question = _this$question.questionobject) !== null && _this$question !== void 0 && _this$question.informationAssetActivityType)) return [];
      return ((_this$informationAsse = this.informationAssets) === null || _this$informationAsse === void 0 ? void 0 : _this$informationAsse.custom.filter(function (ia) {
        return _this.question.questionobject.informationAssetActivityType === ia.activityTypeId;
      })) || [];
    },
    myFilteredCustomIA: function myFilteredCustomIA() {
      var _this$question2,
        _this$myInformationAs,
        _this2 = this;
      if (!((_this$question2 = this.question) !== null && _this$question2 !== void 0 && (_this$question2 = _this$question2.questionobject) !== null && _this$question2 !== void 0 && _this$question2.informationAssetActivityType)) return [];
      return ((_this$myInformationAs = this.myInformationAssetsByType) === null || _this$myInformationAs === void 0 ? void 0 : _this$myInformationAs.informationAssets.filter(function (ia) {
        return _this2.question.questionobject.informationAssetActivityType === ia.activityTypeId;
      })) || [];
    },
    includeSubprocessors: function includeSubprocessors() {
      return this.getSettingValue('feature.ia_questions_include_subprocessors') !== 0;
    },
    showFullQuestionsList: function showFullQuestionsList() {
      return this.getSettingValue('feature.show_full_list_of_questionnaires');
    },
    myFilteredProcessorsIds: function myFilteredProcessorsIds() {
      var _this$myInformationAs2;
      return this.getFilteredProcessors((_this$myInformationAs2 = this.myInformationAssetsByType) === null || _this$myInformationAs2 === void 0 ? void 0 : _this$myInformationAs2.processors) || [];
    },
    filteredProcessors: function filteredProcessors() {
      var _this$informationAsse2;
      return this.getFilteredProcessors((_this$informationAsse2 = this.informationAssets) === null || _this$informationAsse2 === void 0 ? void 0 : _this$informationAsse2.processors);
    },
    informationAssetsWithFilteredProcessors: function informationAssetsWithFilteredProcessors() {
      return _objectSpread(_objectSpread({}, this.informationAssets), {}, {
        processors: this.filteredProcessors
      });
    },
    filteredProcessorsQuestion: function filteredProcessorsQuestion() {
      var _this$question4;
      if (this.includeSubprocessors) {
        var _this$question3;
        return ((_this$question3 = this.question) === null || _this$question3 === void 0 || (_this$question3 = _this$question3.informationAssets) === null || _this$question3 === void 0 ? void 0 : _this$question3.processors) || [];
      }
      return ((_this$question4 = this.question) === null || _this$question4 === void 0 || (_this$question4 = _this$question4.informationAssets) === null || _this$question4 === void 0 ? void 0 : _this$question4.processors.filter(function (processor) {
        var _processor$parents;
        return !((_processor$parents = processor.parents) !== null && _processor$parents !== void 0 && _processor$parents.length);
      })) || [];
    },
    filteredIAQuestion: function filteredIAQuestion() {
      return _objectSpread(_objectSpread({}, this.question.informationAssets), {}, {
        processors: this.filteredProcessorsQuestion
      });
    },
    selectedCustomIA: function selectedCustomIA() {
      var _this$question$inform;
      return ((_this$question$inform = this.question.informationAssets) === null || _this$question$inform === void 0 ? void 0 : _this$question$inform.custom.map(function (ia) {
        ia.name = ia.title;
        return ia;
      })) || [];
    },
    selectedSystems: function selectedSystems() {
      var _this$question5;
      return ((_this$question5 = this.question) === null || _this$question5 === void 0 || (_this$question5 = _this$question5.informationAssets) === null || _this$question5 === void 0 ? void 0 : _this$question5.systems) || [];
    },
    selectedControllers: function selectedControllers() {
      var _this$question6;
      return ((_this$question6 = this.question) === null || _this$question6 === void 0 || (_this$question6 = _this$question6.informationAssets) === null || _this$question6 === void 0 ? void 0 : _this$question6.controllers) || [];
    },
    dateFormat: function dateFormat() {
      return this.question.questiontype === 14 ? DateFormats.DATE_FORMAT : DateFormats.DATE_TIME_FORMAT;
    },
    dateTime: function dateTime() {
      return this.question.dateTime ? formatTimeUtc(this.question.dateTime, this.dateFormat) : '';
    },
    datePickerOptions: function datePickerOptions() {
      return {
        format: this.dateFormat,
        widgetPositioning: {
          vertical: 'bottom',
          horizontal: 'left'
        }
      };
    }
  }),
  methods: {
    getAnswers: function getAnswers(answer) {
      if (Object.keys(answer).length === 0) {
        this.currentQuestion.answers_options = {
          'null': 'undefined'
        };
      } else {
        this.currentQuestion.answers_options = Object.assign({}, answer);
      }
      this.$emit('update', this.currentQuestion);
      Object.keys(answer).length === 0 ? this.question.answers_options = {} : null;
    },
    getSingleAnswers: function getSingleAnswers(event) {
      this.getAnswers(event);
      this.$emit('forceUpdate');
    },
    getFreeText: function getFreeText(freeText) {
      this.currentQuestion.freeText = freeText;
      this.$emit('update', this.currentQuestion);
    },
    getIA: function getIA(_ref) {
      var data = _ref.data,
        type = _ref.type;
      this.currentQuestion.informationAssets[type] = data;
      this.$emit('update', this.currentQuestion);
    },
    getFiles: function getFiles(files) {
      this.currentQuestion.files = files;
      this.$emit('update', this.currentQuestion);
      this.$emit('forceUpdate');
    },
    getDate: function getDate(date) {
      this.currentQuestion.dateTime = date;
      this.$emit('update', this.currentQuestion);
    },
    addOption: function addOption(optionLabel) {
      var _this3 = this;
      return _addOption(this.question.id, optionLabel).then(function (data) {
        var id = data.id,
          label = data.label;
        if (_this3.currentQuestion.questiontype === 1 || typeof _this3.currentQuestion.answers_options.length !== 'undefined') {
          _this3.currentQuestion.answers_options = {};
        }
        _this3.currentQuestion.options[id] = label;
        _this3.currentQuestion.optionDetails.push(data);
        _this3.currentQuestion.answers_options[id] = label;
        _this3.$forceUpdate();
        _this3.$emit('update', _this3.currentQuestion);
      });
    },
    getFilteredProcessors: function getFilteredProcessors(processors) {
      if (this.includeSubprocessors) {
        return processors || [];
      }
      return processors.filter(function (processor) {
        var _processor$parents2;
        return !((_processor$parents2 = processor.parents) !== null && _processor$parents2 !== void 0 && _processor$parents2.length);
      }) || [];
    }
  }
};