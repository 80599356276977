function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { accessLevels } from '@/constants/compliance/SecurityGroups';
import { UserLevels } from '@/constants/UserLevels';
import { typesAccessProjects } from '@/constants/compliance/TypesAccessProjects';
import { RiskProjects } from '@/constants/risks/RiskProjects';
import { getRiskProjects as getRiskProjectsAPI } from '@/api/risk/risk';
import { getAll as getComplianceProjectsAPI } from '@/api/compliance/projects';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import Modal from '@/components/Molecules/Modal/Modal';
export default {
  name: 'SecurityGroupsModal',
  components: {
    Modal: Modal,
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    LoadingIndicatorSmall: LoadingIndicatorSmall
  },
  props: {
    typeAccessProjects: {
      type: String,
      required: true,
      validator: function validator(value) {
        return Object.values(typesAccessProjects).indexOf(value) !== -1;
      },
      note: 'There are 2 options: "compliance" or "risk"'
    },
    securityGroupId: {
      type: Number,
      required: true,
      note: 'An id of security group'
    },
    projectAccessId: {
      type: Number,
      required: false,
      default: null,
      note: 'An id of a project of existing group if it updating. Null if it is creating a new project access.'
    },
    projectId: {
      type: Number,
      required: false,
      default: null,
      note: 'An id of one of the project (risk or compliance). Null if it is creating a project'
    },
    accessLevel: {
      type: Number,
      required: false,
      default: UserLevels.NORMAL,
      note: 'An id of access level'
    }
  },
  emits: ['close', 'submit'],
  data: function data() {
    return {
      isLoaded: false,
      accessLevels: accessLevels,
      projects: [],
      projectIdActive: 0,
      accessLevelActive: this.accessLevel,
      translate: {
        project: this.$trans('Project')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    submitText: function submitText() {
      return this.projectId ? 'Edit the project' : 'Create a new project';
    }
  }),
  mounted: function mounted() {
    var _this = this;
    var projectsPromise;
    if (this.typeAccessProjects === typesAccessProjects.compliance) {
      projectsPromise = this.handleGetComplianceProjects();
    } else if (this.typeAccessProjects === typesAccessProjects.risk) {
      projectsPromise = this.handleGetRiskProjects();
    }
    projectsPromise.then(function (projects) {
      _this.projects = projects;
      _this.projectIdActive = _this.projectId ? _this.projectId : projects[0] && projects[0].value;
      _this.isLoaded = true;
    });
  },
  methods: {
    formatEntryProjects: function formatEntryProjects(list) {
      return list.map(function (project) {
        return {
          value: project.id,
          label: project.title
        };
      });
    },
    handleGetComplianceProjects: function handleGetComplianceProjects() {
      var _this2 = this;
      return getComplianceProjectsAPI(true).then(function (response) {
        return _this2.formatEntryProjects(response.list);
      });
    },
    handleGetRiskProjects: function handleGetRiskProjects() {
      var _this3 = this;
      return getRiskProjectsAPI().then(function (response) {
        var riskProjects = response.list.filter(function (project) {
          return project.type !== RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type;
        });
        return _this3.formatEntryProjects(riskProjects);
      });
    },
    handleSubmit: function handleSubmit() {
      var data = {
        projectId: this.projectIdActive,
        accessLevel: this.accessLevelActive
      };
      if (this.projectAccessId) {
        data.id = this.projectAccessId;
      }
      this.$emit('submit', this.securityGroupId, this.typeAccessProjects, data);
    }
  }
};