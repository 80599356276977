import { getTranslate } from './translate';
import FieldSet from '@/components/Atoms/FieldSet/FieldSet';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
export default {
  name: 'RiskGroupSortBy',
  introduction: 'Fieldset of group by & sort by for ISMS risks',
  description: 'Fieldset of group by & sort by for ISMS risks',
  token: "<risk-group-sort-by\n      :sort-by=\"sortBy\"\n      :group-by=\"groupBy\"\n      @update-groupBy=\"handleGroupBy\"\n      @update-sortBy=\"handleSortBy\" />",
  components: {
    FieldSet: FieldSet,
    SingleSelect: SingleSelect
  },
  props: {
    isErm: {
      type: Boolean,
      required: false,
      default: false
    },
    groupBy: {
      required: true,
      validator: function validator(prop) {
        return typeof prop === 'string' || prop === null;
      }
    },
    sortBy: {
      required: true,
      validator: function validator(prop) {
        return typeof prop === 'string' || prop === null;
      }
    }
  },
  emits: ['update-sortBy', 'update-groupBy'],
  data: function data() {
    return {
      translate: getTranslate['RiskGroupSortBy']()
    };
  },
  computed: {
    sortByOptions: function sortByOptions() {
      return [{
        label: this.translate.riskNumber,
        value: null
      }, {
        label: this.translate.tags,
        value: 'tags'
      }, {
        label: this.translate.responsible,
        value: 'responsible'
      }, {
        label: this.translate.accountable,
        value: 'accountable'
      }];
    },
    groupByOptions: function groupByOptions() {
      var options = [{
        label: this.translate.notSet,
        value: null
      }, {
        label: this.translate.parentRisk,
        value: 'prisk'
      }, {
        label: this.translate.tags,
        value: 'tags'
      }, {
        label: this.translate.responsible,
        value: 'responsible'
      }, {
        label: this.translate.accountable,
        value: 'accountable'
      }, {
        label: this.translate.organisations,
        value: 'organisationIds'
      }, {
        label: this.translate.threats,
        value: 'threats'
      }, {
        label: this.translate.vulnerabilities,
        value: 'vulnerabilities'
      }];
      if (this.isErm) {
        options.splice(1, 1);
        options.splice(5, 2);
      }
      return options;
    }
  }
};