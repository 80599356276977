import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "relative"
};
var _hoisted_2 = {
  class: "flex items-center group"
};
var _hoisted_3 = {
  class: "px-2 align-middle text-blue-750"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_attachment_dropdown = _resolveComponent("attachment-dropdown");
  var _component_edit_attachment = _resolveComponent("edit-attachment");
  var _component_attachment_modal = _resolveComponent("attachment-modal");
  var _component_attachment_versions_modal = _resolveComponent("attachment-versions-modal");
  var _component_alert = _resolveComponent("alert");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass(["rounded-full border-2 border-gray-250 p-1", [$options.isToggleDropdownActive ? 'cursor-pointer hover:border-blue-750' : '']]),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.isToggleDropdownActive ? $data.isDropdownOpen = !$data.isDropdownOpen : null;
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "paperclip",
    class: "transform rotate-135 text-blue-750 stroke-2"
  })], 2 /* CLASS */), _createElementVNode("span", _hoisted_3, _toDisplayString($options.attachmentTitle), 1 /* TEXT */)]), $data.isDropdownOpen ? (_openBlock(), _createBlock(_component_attachment_dropdown, {
    key: 0,
    "files-list": $options.sortedFilesList,
    "read-only": $props.readOnly,
    "has-version-buttons": $props.hasVersionButtons,
    "add-link-button-enabled": $props.filesAndLinksSeparated,
    "edit-file-enabled": $props.editFileEnabled,
    onTriggerModal: _cache[1] || (_cache[1] = function ($event) {
      return $options.toggleModal($event, true);
    }),
    onOnDelete: $options.openConfirmModal,
    onOnCreateNewVersion: $options.onCreateNewVersion,
    onOnShowVersions: $options.onShowVersions,
    onOnAttachmentEdit: $options.openEditModal,
    onOnUnpin: $options.togglePin
  }, null, 8 /* PROPS */, ["files-list", "read-only", "has-version-buttons", "add-link-button-enabled", "edit-file-enabled", "onOnDelete", "onOnCreateNewVersion", "onOnShowVersions", "onOnAttachmentEdit", "onOnUnpin"])) : _createCommentVNode("v-if", true)]), $data.isEditModalActive ? (_openBlock(), _createBlock(_component_edit_attachment, {
    key: 0,
    "edited-file-data": $data.editedFile,
    "type-options": $props.typeOptions,
    "file-types-enabled": $props.fileTypesEnabled,
    onEditFile: $options.onFileEdit,
    onDismissEdit: _cache[2] || (_cache[2] = function ($event) {
      return $data.isEditModalActive = false;
    })
  }, null, 8 /* PROPS */, ["edited-file-data", "type-options", "file-types-enabled", "onEditFile"])) : _createCommentVNode("v-if", true), $data.isModalOpen ? (_openBlock(), _createBlock(_component_attachment_modal, {
    key: 1,
    "server-error-message": _ctx.attachFileErrorMessage,
    "single-file-load": $props.singleFileLoad,
    "has-pin": $props.hasPin,
    "pinned-by-default": $data.pinnedByDefault,
    "has-title": $props.hasTitle,
    "type-options": $props.typeOptions,
    "document-type-id": $data.documentType,
    "is-apply-disabled": $data.isApplyDisabled,
    "modal-mode": $data.attachmentModalMode,
    onUpload: $options.handleAttachmentsNew,
    onDismiss: $options.closeAttachmentModal
  }, null, 8 /* PROPS */, ["server-error-message", "single-file-load", "has-pin", "pinned-by-default", "has-title", "type-options", "document-type-id", "is-apply-disabled", "modal-mode", "onUpload", "onDismiss"])) : _createCommentVNode("v-if", true), $data.isVersionsModalOpen ? (_openBlock(), _createBlock(_component_attachment_versions_modal, {
    key: 2,
    file: $data.file,
    "add-mode": $data.fileVersionsAddNewMode,
    "type-options": $props.typeOptions,
    "read-only": $props.readOnly,
    "is-apply-disabled": $data.isApplyDisabled,
    onUpload: _cache[3] || (_cache[3] = function ($event) {
      return $options.handleAttachmentsNew($event, true);
    }),
    onOnDelete: $options.openConfirmModal,
    onDismiss: $options.dismissVersionModal
  }, null, 8 /* PROPS */, ["file", "add-mode", "type-options", "read-only", "is-apply-disabled", "onOnDelete", "onDismiss"])) : _createCommentVNode("v-if", true), $data.selectedFileId ? (_openBlock(), _createBlock(_component_alert, {
    key: 3,
    "alert-type": "confirmation",
    header: $data.translate.deleteAttachment,
    body: $data.translate.areYouSureYouWantToDeleteThisAttachment,
    onAccept: $options.removeAttachment,
    onDismiss: $options.closeConfirmModal
  }, null, 8 /* PROPS */, ["header", "body", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, $options.hide]]);
}