import { Colors } from '@/Colors';
export default {
  name: 'TrafficLight',
  introduction: 'For trafficlight and gap colors',
  description: 'Trafficlight are used in various places in the system',
  token: '<trafficlight color="#57aa74"/>',
  props: {
    color: {
      type: String,
      required: true,
      note: 'the color'
    },
    stroke: {
      type: String,
      default: Colors.trafficLightStroke,
      note: 'the stroke color'
    },
    title: {
      type: String,
      note: 'a title which will be shown on hover',
      default: ''
    },
    width: {
      type: Number,
      default: 17,
      note: 'the width of the traffic light'
    },
    height: {
      type: Number,
      default: 17,
      note: 'the height of the traffic light'
    },
    showBan: {
      type: Boolean,
      default: false,
      note: 'Set this to true for N/A gaps'
    }
  },
  emits: ['click'],
  data: function data() {
    return {
      colors: Colors
    };
  },
  computed: {
    isBan: function isBan() {
      return this.color === this.colors.gaps[5].color && this.showBan;
    }
  }
};