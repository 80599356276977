import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "mb-6"
};
var _hoisted_2 = {
  key: 3
};
var _hoisted_3 = {
  key: 0,
  class: "mb-2 w-80"
};
var _hoisted_4 = {
  key: 0,
  class: "w-80"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  class: "mb-1 ml-2"
};
var _hoisted_8 = {
  class: "cursor-pointer font-bold"
};
var _hoisted_9 = ["onInput"];
var _hoisted_10 = {
  class: "ml-1 pl-1"
};
var _hoisted_11 = {
  key: 0,
  class: "ml-2"
};
var _hoisted_12 = {
  class: "cursor-pointer flex items-center"
};
var _hoisted_13 = ["disabled", "checked", "onChange"];
var _hoisted_14 = {
  class: "ml-1"
};
var _hoisted_15 = {
  key: 0
};
var _hoisted_16 = {
  key: 1
};
var _hoisted_17 = {
  class: "cursor-pointer flex items-center"
};
var _hoisted_18 = ["onUpdate:modelValue"];
var _hoisted_19 = {
  class: "ml-1"
};
var _hoisted_20 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_single_select = _resolveComponent("single-select");
  var _component_tree_list = _resolveComponent("tree-list");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [$data.showConfirmationAlert ? (_openBlock(), _createBlock(_component_alert, {
        key: 0,
        body: $data.confirmMessage,
        "alert-type": "confirmation",
        onAccept: $options.onAlertConfirm,
        onDismiss: _cache[0] || (_cache[0] = function ($event) {
          return $data.showConfirmationAlert = false;
        })
      }, null, 8 /* PROPS */, ["body", "onAccept"])) : _createCommentVNode("v-if", true), $data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 1,
        type: $data.notificationType
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.notificationMessage), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        title: $data.translate.dangerZone
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_button, {
        text: $data.translate.copyAllEnterpriseRisksToANewRiskProject,
        type: "save",
        onClick: $options.copyRisks
      }, null, 8 /* PROPS */, ["text", "onClick"]), _createVNode(_component_risma_button, {
        text: $data.translate.setAllControlsRecent,
        type: "save",
        onClick: $options.resetControls
      }, null, 8 /* PROPS */, ["text", "onClick"])]), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 2
      })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_ctx.allUsers.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", {
        class: "flex items-center mb-2 cursor-pointer",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return $data.showSection.users = !$data.showSection.users;
        })
      }, [_createVNode(_component_risma_title, {
        title: $data.translate.hardDeleteUser,
        class: "mr-4"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
        icon: "".concat($data.showSection.users ? 'chevron-down' : 'chevron-up'),
        width: "25",
        height: "25",
        class: "cursor-pointer"
      }, null, 8 /* PROPS */, ["icon"])]), $data.showSection.users && _ctx.allUsers ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_single_select, {
        modelValue: $data.usersData.deleteUserId,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.usersData.deleteUserId = $event;
        }),
        title: $data.translate.userToDelete,
        class: "mb-4",
        options: $options.allUsersObject.deleteList,
        placeholder: $data.translate.userToDelete
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"]), _createVNode(_component_single_select, {
        modelValue: $data.usersData.sendToId,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.usersData.sendToId = $event;
        }),
        title: $data.translate.moveChangelogEntriesTo,
        class: "mb-4",
        options: $options.allUsersObject.sendToList,
        disabled: !$data.usersData.deleteUserId,
        placeholder: $data.translate.moveChangelogEntriesTo
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "disabled", "placeholder"]), _createVNode(_component_risma_button, {
        disabled: !$data.usersData.sendToId && !$data.usersData.deleteUserId,
        text: $data.translate.delete,
        type: "save",
        onClick: $options.deleteUser
      }, null, 8 /* PROPS */, ["disabled", "text", "onClick"])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.massDelete, function (value, key) {
        return _openBlock(), _createElementBlock("div", {
          key: key
        }, [_createElementVNode("div", {
          class: "flex items-center mb-2 cursor-pointer w-30rem",
          onClick: function onClick($event) {
            return $data.showSection[key] = !$data.showSection[key];
          }
        }, [_createVNode(_component_risma_title, {
          title: _ctx.$trans('Mass delete ' + value.label),
          class: "mr-4"
        }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
          icon: "".concat($data.showSection[key] ? 'chevron-down' : 'chevron-up'),
          width: "25",
          height: "25"
        }, null, 8 /* PROPS */, ["icon"])], 8 /* PROPS */, _hoisted_5), $data.showSection[key] ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("label", _hoisted_8, [_createElementVNode("input", {
          type: "checkbox",
          class: "cursor-pointer align-middle",
          onInput: function onInput($event) {
            return $options.toggleSelectAll(key, $event);
          }
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_9), _createElementVNode("span", _hoisted_10, _toDisplayString($data.translate.selectAll), 1 /* TEXT */)])]), value.model.type === $data.ModelTypes.TREE ? (_openBlock(), _createElementBlock("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value.entities, function (root) {
          return _openBlock(), _createBlock(_component_tree_list, {
            key: root.id,
            item: root
          }, {
            title: _withCtx(function (_ref) {
              var _item$value$model$vis;
              var item = _ref.item,
                parent = _ref.parent;
              return [_createElementVNode("label", _hoisted_12, [_createElementVNode("input", {
                type: "checkbox",
                disabled: parent && $data.toggleObjects[key][parent[value.model.id]],
                checked: $data.toggleObjects[key][item[value.model.id]],
                class: "cursor-pointer align-middle mr-2",
                onChange: function onChange($event) {
                  return $options.onTreeItemCheck(key, item, $event);
                }
              }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_13), _createElementVNode("div", _hoisted_14, [(_item$value$model$vis = item[value.model.visibleId]) !== null && _item$value$model$vis !== void 0 && _item$value$model$vis.trim() ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(item[value.model.visibleId] + '.'), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(item[value.model.title]), 1 /* TEXT */)])])];
            }),

            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["item"]);
        }), 128 /* KEYED_FRAGMENT */)), _createVNode(_component_risma_button, {
          text: _ctx.$trans('Delete all selected ' + value.label),
          type: "save",
          class: "mt-4",
          disabled: !$data.treeEntitiesToDelete[key].length,
          onClick: function onClick($event) {
            return $options.deleteEntities(key, $data.ModelTypes.TREE);
          }
        }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])) : _createCommentVNode("v-if", true), value.model.type === $data.ModelTypes.FLAT ? (_openBlock(), _createElementBlock("div", _hoisted_16, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value.entities, function (entity) {
          var _entity$value$model$v;
          return _openBlock(), _createElementBlock("div", {
            key: entity[value.model.id],
            class: "mb-1 ml-2"
          }, [_createElementVNode("label", _hoisted_17, [_withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": function onUpdateModelValue($event) {
              return $data.toggleObjects[key][entity[value.model.id]] = $event;
            },
            type: "checkbox",
            class: "cursor-pointer align-middle"
          }, null, 8 /* PROPS */, _hoisted_18), [[_vModelCheckbox, $data.toggleObjects[key][entity[value.model.id]]]]), _createElementVNode("div", _hoisted_19, [(_entity$value$model$v = entity[value.model.visibleId]) !== null && _entity$value$model$v !== void 0 && _entity$value$model$v.trim() ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(entity[value.model.visibleId] + '.'), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(entity[value.model.title]), 1 /* TEXT */)])])]);
        }), 128 /* KEYED_FRAGMENT */)), _createVNode(_component_risma_button, {
          text: _ctx.$trans('Delete all selected ' + value.label),
          type: "save",
          class: "mt-4",
          disabled: !$options.flatEntitiesToDelete[key].length,
          onClick: function onClick($event) {
            return $options.deleteEntities(key, $data.ModelTypes.FLAT);
          }
        }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]);
      }), 128 /* KEYED_FRAGMENT */))]))];
    }),

    _: 1 /* STABLE */
  });
}