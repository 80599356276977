import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-d6d55962"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "risk-description"
};
var _hoisted_2 = {
  class: "risk-main-wrap row"
};
var _hoisted_3 = {
  class: "risk-description-content col-9"
};
var _hoisted_4 = {
  key: 1,
  class: "risk-description-data-entry-section"
};
var _hoisted_5 = {
  class: "risk-description-data-entry-header"
};
var _hoisted_6 = {
  class: "flex w-full justify-between"
};
var _hoisted_7 = {
  class: "w-9/12"
};
var _hoisted_8 = {
  class: "risk-description-data-scoring mb-4"
};
var _hoisted_9 = {
  key: 1
};
var _hoisted_10 = {
  key: 0
};
var _hoisted_11 = {
  key: 1
};
var _hoisted_12 = {
  key: 0,
  class: "risk-description-section justify-between"
};
var _hoisted_13 = {
  class: "w-2/3"
};
var _hoisted_14 = {
  class: "risk-description-textarea"
};
var _hoisted_15 = {
  key: 0,
  class: "risk-description-approval-status"
};
var _hoisted_16 = {
  class: "risk-description-approval-status-body"
};
var _hoisted_17 = {
  key: 0
};
var _hoisted_18 = {
  key: 1,
  class: "risk-description-approval-status-unapprove"
};
var _hoisted_19 = {
  key: 0
};
var _hoisted_20 = {
  key: 1,
  class: "risk-status-body"
};
var _hoisted_21 = {
  class: "risk-status-textarea"
};
var _hoisted_22 = {
  key: 2,
  class: "risk-probability-section"
};
var _hoisted_23 = {
  key: 1,
  class: "risk-consequence-section relative"
};
var _hoisted_24 = {
  key: 3
};
var _hoisted_25 = {
  class: "flex justify-between"
};
var _hoisted_26 = {
  key: 0
};
var _hoisted_27 = {
  class: "approval-history"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  var _component_confidential_box = _resolveComponent("confidential-box");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_risk_probability_assessment = _resolveComponent("risk-probability-assessment");
  var _component_risk_grid = _resolveComponent("risk-grid");
  var _component_risk_erm_assesment = _resolveComponent("risk-erm-assesment");
  var _component_risk_assessments_isms = _resolveComponent("risk-assessments-isms");
  var _component_net_gross = _resolveComponent("net-gross");
  var _component_activity_custom_fields = _resolveComponent("activity-custom-fields");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_alert = _resolveComponent("alert");
  var _component_static_table = _resolveComponent("static-table");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [!$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("section", _hoisted_4, [_createElementVNode("div", _hoisted_5, [$options.showToggleMenu ? (_openBlock(), _createBlock(_component_toggle_menu, {
    key: 0,
    options: $options.isErm ? $options.menuOptionsERM : $options.menuOptionsISMS,
    class: "toggle-menu"
  }, null, 8 /* PROPS */, ["options"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [!!$options.currentProject.confidentialRisks ? (_openBlock(), _createBlock(_component_confidential_box, {
    key: 0,
    class: "mb-4",
    confidential: !!$props.data.confidential,
    "read-only": $options.confidentialReadOnly,
    "confidentiality-names": $options.confidentialityNames,
    onChanged: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('propertyChanged', {
        property: 'confidential',
        value: +$event
      });
    })
  }, null, 8 /* PROPS */, ["confidential", "read-only", "confidentiality-names"])) : _createCommentVNode("v-if", true), $options.isFieldInOptionalFields('period') ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $data.translate.period,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), $options.isErm ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString($data.scoring.version), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.periodSelected.title), 1 /* TEXT */))])) : _createCommentVNode("v-if", true)]), $options.isFieldInOptionalFields('description') ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createVNode(_component_tiny_mce_box, {
    headline: $data.translate.description,
    "initial-content": $props.data.description,
    "read-only": $props.readOnly,
    onChanged: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.description = $event;
    })
  }, null, 8 /* PROPS */, ["headline", "initial-content", "read-only"])])]), _ctx.currentRiskProject.riskApproval ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createElementVNode("p", null, [_createTextVNode(_toDisplayString($data.translate.thisRiskIs) + " ", 1 /* TEXT */), $props.data.approved ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString($data.translate.approved.toLowerCase()), 1 /* TEXT */)) : _createCommentVNode("v-if", true), !$props.data.approved ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString($data.translate.unapproved), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), $props.data.approved && $props.data.approvedByUserId && $props.data.approvedDate ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString($options.approvedBy.display_name) + " " + _toDisplayString($data.translate.on) + " " + _toDisplayString($options.approvedDate), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("p", {
    class: "risk-description-approval-history",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $data.showApprovalHistory = true;
    })
  }, _toDisplayString($data.translate.showApprovalHistory), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $options.isErm ? (_openBlock(), _createElementBlock("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createVNode(_component_tiny_mce_box, {
    headline: $data.translate.status,
    "initial-content": $props.data.status,
    "read-only": $props.readOnly,
    onChanged: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.status = $event;
    })
  }, null, 8 /* PROPS */, ["headline", "initial-content", "read-only"])])])) : (_openBlock(), _createElementBlock("div", _hoisted_22, [_ctx.probabilities.length ? (_openBlock(), _createBlock(_component_risk_probability_assessment, {
    key: 0,
    activity: $props.data,
    threats: _ctx.threats,
    "level-of-threats": _ctx.levelOfThreats,
    "threat-level-title": _ctx.periodSelected.threatLevelsLabel,
    vulnerabilities: _ctx.vulnerabilities,
    probabilities: _ctx.probabilities,
    disabled: $props.readOnly,
    "threat-shown": $options.isFieldInOptionalFields('threat'),
    "threat-level-shown": $options.isFieldInOptionalFields('threatLevel'),
    "vulnerability-shown": $options.isFieldInOptionalFields('vulnerability'),
    onChanged: $options.onStateChanged
  }, null, 8 /* PROPS */, ["activity", "threats", "level-of-threats", "threat-level-title", "vulnerabilities", "probabilities", "disabled", "threat-shown", "threat-level-shown", "vulnerability-shown", "onChanged"])) : _createCommentVNode("v-if", true)]))]), _createVNode(_component_risk_grid, {
    class: "pl-10 pt-12",
    "risk-grid-data": $options.matrix,
    "without-z-axis": true,
    "grid-box-size": $data.gridBoxSize,
    "enable-prev-position": false,
    "is-grid-clickable": false,
    "start-color": _ctx.startColor,
    "end-color": _ctx.endColor,
    "custom-heatmap-options": _ctx.currentRiskProject.customHeatmapEnabled ? _ctx.currentRiskProject.heatmapOptions : [],
    "dot-label-hover-function": $options.dotHoverFunction,
    "axis-with-labels": false,
    "dot-connector-enabled": $options.inherentRisksEnabled
  }, null, 8 /* PROPS */, ["risk-grid-data", "grid-box-size", "start-color", "end-color", "custom-heatmap-options", "dot-label-hover-function", "dot-connector-enabled"])]), $options.isErm ? (_openBlock(), _createBlock(_component_risk_erm_assesment, {
    key: 0,
    risk: $props.data,
    scoring: $data.scoring,
    "read-only": $props.readOnly,
    onChanged: $options.onStateChanged,
    onUpdateCustom: $options.onErmTableAxisChange
  }, null, 8 /* PROPS */, ["risk", "scoring", "read-only", "onChanged", "onUpdateCustom"])) : _createCommentVNode("v-if", true), !$options.isErm ? (_openBlock(), _createElementBlock("div", _hoisted_23, [_createVNode(_component_risk_assessments_isms, {
    activity: $props.data,
    "obj-type": $props.data.objType,
    period: _ctx.periodSelected,
    threats: $options.threatsWithCategoryLabel,
    "read-only": $props.readOnly,
    "custom-threats": $data.customThreats,
    "custom-threats-enabled": !!$options.customThreatsEnabled,
    "inherent-risks-enabled": $options.inherentRisksEnabled,
    onUpdateUnderlyingRisk: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('updateUnderlyingRisk', $event);
    }),
    onChanged: $options.onStateChanged,
    onUpdateCustomThreat: $options.onUpdateCustomThreat
  }, null, 8 /* PROPS */, ["activity", "obj-type", "period", "threats", "read-only", "custom-threats", "custom-threats-enabled", "inherent-risks-enabled", "onChanged", "onUpdateCustomThreat"])])) : _createCommentVNode("v-if", true), $options.isErm ? (_openBlock(), _createBlock(_component_net_gross, {
    key: 2,
    risk: $props.data,
    scoring: $data.scoring,
    "read-only": $props.readOnly,
    onUpdate: $options.onStateChanged
  }, null, 8 /* PROPS */, ["risk", "scoring", "read-only", "onUpdate"])) : _createCommentVNode("v-if", true), $options.customFieldsShown ? (_openBlock(), _createElementBlock("div", _hoisted_24, [_createVNode(_component_risma_title, {
    title: $data.translate.customFields,
    class: "mt-4",
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_activity_custom_fields, {
    "activity-type": $options.preparedCurrentActivityType,
    "custom-field-values": $options.preparedCustomFieldValues,
    disabled: $props.readOnly,
    onChanged: _ctx.onCustomFieldsChange
  }, null, 8 /* PROPS */, ["activity-type", "custom-field-values", "disabled", "onChanged"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_25, [_ctx.currentRiskProject.riskApproval && !$props.readOnly ? (_openBlock(), _createElementBlock("div", _hoisted_26, [!$props.data.approved ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $data.translate.approveRisk,
    type: "save",
    onClick: _cache[5] || (_cache[5] = function ($event) {
      return $options.changeApproveStatus(1);
    })
  }, null, 8 /* PROPS */, ["text"])) : (_openBlock(), _createBlock(_component_risma_button, {
    key: 1,
    text: $data.translate.unapproveRisk,
    type: "danger",
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return $options.changeApproveStatus(0);
    })
  }, null, 8 /* PROPS */, ["text"]))])) : _createCommentVNode("v-if", true)]), $data.deleteModalVisible ? (_openBlock(), _createBlock(_component_alert, {
    key: 4,
    body: $options.deleteRiskTitle,
    header: $data.translate.deleteRisk,
    "button-ok-text": $data.translate.delete,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.deleteRisk,
    onDismiss: $options.hideDeleteAlert
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), $data.copyModalVisible ? (_openBlock(), _createBlock(_component_alert, {
    key: 5,
    body: $options.copyRiskTitle,
    header: $data.translate.copyRisk,
    "button-ok-text": $data.translate.copy,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.copyRisk,
    onDismiss: $options.hideCopyAlert
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), $data.closeModalVisible ? (_openBlock(), _createBlock(_component_alert, {
    key: 6,
    body: $options.closeRiskTitle,
    header: $data.translate.closeRisk,
    "button-ok-text": $data.translate.close,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.closeRisk,
    onDismiss: $options.hideCloseAlert
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), $data.closeWithChildrenModalVisible ? (_openBlock(), _createBlock(_component_alert, {
    key: 7,
    body: $options.closeWithChildrenRiskTitle,
    header: $data.translate.closeRiskWithChildren,
    "button-ok-text": $data.translate.close,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.closeRiskWithChildren,
    onDismiss: $options.hideCloseWithChildrenAlert
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), $data.openWithChildrenModalVisible ? (_openBlock(), _createBlock(_component_alert, {
    key: 8,
    body: $options.openWithChildrenRiskTitle,
    header: $data.translate.openRiskWithChildren,
    "button-ok-text": $data.translate.open,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.openRiskWithChildren,
    onDismiss: $options.hideOpenWithChildrenAlert
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true)]))])]), $data.showApprovalHistory ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $data.translate.approvalHistory,
    "show-dismiss-button": false,
    onDismiss: _cache[7] || (_cache[7] = function ($event) {
      return $data.showApprovalHistory = false;
    }),
    onAccept: _cache[8] || (_cache[8] = function ($event) {
      return $data.showApprovalHistory = false;
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_27, [$options.approvalHistoryData.length > 0 ? (_openBlock(), _createBlock(_component_static_table, {
        key: 0,
        columns: $options.approvalHistoryColumns,
        dataset: $options.approvalHistoryData
      }, {
        status: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_createElementVNode("p", {
            class: _normalizeClass(['status', slotData.additionalCss])
          }, _toDisplayString(slotData.title), 3 /* TEXT, CLASS */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["columns", "dataset"])) : (_openBlock(), _createBlock(_component_risma_title, {
        key: 1,
        class: "text-center",
        type: 'medium',
        title: $data.translate.thisRiskHasNotPreviouslyBeenApproved,
        truncate: false
      }, null, 8 /* PROPS */, ["title"]))])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header"])) : _createCommentVNode("v-if", true)]);
}