function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import Question from '@/components/Molecules/Questionnaire/Question';
import Notification from '@/components/Molecules/Notification';
import QuestionnaireNavigation from '@/components/Molecules/Compliance/QuestionnaireNavigation';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { questionHasSomeDependencyChosen } from '@/utils/questionnaires';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'Questionnaire',
  components: {
    Notification: Notification,
    Question: Question,
    QuestionnaireNavigation: QuestionnaireNavigation,
    FeatherIcon: FeatherIcon
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    questionnaire: {
      required: true,
      type: [Object, Array],
      note: 'Questionnaire data'
    },
    questions: {
      type: Array,
      required: true
    },
    informationAssets: {
      type: Array,
      required: true
    },
    myInformationAssetsByType: {
      type: Object,
      default: function _default() {
        return {};
      },
      required: false
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of custom information assets activity types'
    },
    nodeElement: {
      type: Object,
      default: function _default() {},
      required: false
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Questionnaire is be read-only'
    },
    title: {
      required: true,
      type: String,
      default: '',
      note: 'questionnaire title'
    },
    localStorageKey: {
      type: String,
      default: '',
      required: false,
      note: 'local storage for number'
    }
  },
  emits: ['forceUpdate', 'update', 'newAsset'],
  setup: function setup(props) {
    return {
      currentQuestionId: getLocalStorageComputed("questionnaire_".concat(props.localStorageKey), 0, true)
    };
  },
  data: function data() {
    return {
      errors: [],
      questionIndex: 0,
      AssetsTypes: AssetsTypes,
      showNonApplicable: false,
      translate: {
        showNonApplicable: this.$trans('Show non applicable'),
        hideNonApplicable: this.$trans('Hide non applicable'),
        questionWithQuestionumber: this.$trans('Question with questionumber:'),
        doesNotExistLogicIsIncorrectOn: this.$trans(', does not exist. Logic is incorrect on')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    visibleQuestions: function visibleQuestions() {
      if (this.questions == null) {
        return [];
      }
      return this.questions.filter(function (q) {
        return !q.hidden;
      });
    },
    expandedVisibleQuestions: function expandedVisibleQuestions() {
      var _this = this;
      return this.visibleQuestions.map(function (question, idx) {
        var result = _objectSpread(_objectSpread({}, question), {}, {
          answeredDependencies: _this.answeredDependencies(question),
          showHeadline: true
        });
        if (_this.showFullQuestionsList && idx !== 0) {
          var prevQuestion = _this.visibleQuestions[idx - 1];
          result.showHeadline = prevQuestion && prevQuestion.headline !== question.headline;
        }
        return result;
      });
    },
    filteredAnsweredDependenciesQuestions: function filteredAnsweredDependenciesQuestions() {
      return this.expandedVisibleQuestions.filter(function (q) {
        return q.answeredDependencies;
      });
    },
    indexOfFirstAnsweredDependenciesQuestion: function indexOfFirstAnsweredDependenciesQuestion() {
      var arrayLength = this.expandedVisibleQuestions.length;
      return this.expandedVisibleQuestions.reduce(function (result, value, index) {
        if (result < arrayLength) return result;
        if (value.answeredDependencies) return index;
        return arrayLength;
      }, arrayLength);
    },
    indexOfLastAnsweredDependenciesQuestion: function indexOfLastAnsweredDependenciesQuestion() {
      return this.expandedVisibleQuestions.reduce(function (result, value, index) {
        if (value.answeredDependencies) return index;
        if (result >= 0) return result;
        return -1;
      }, -1);
    },
    questionMap: function questionMap() {
      var map = this.questions.map(function (question) {
        return [question.questionnumber, question];
      });
      return new Map(map);
    },
    questionsList: function questionsList() {
      var _this$expandedVisible;
      if (!((_this$expandedVisible = this.expandedVisibleQuestions) !== null && _this$expandedVisible !== void 0 && _this$expandedVisible.length)) {
        return [];
      }
      if (this.showFullQuestionsList) {
        return this.showNonApplicable ? this.expandedVisibleQuestions : this.filteredAnsweredDependenciesQuestions;
      }
      var element = this.expandedVisibleQuestions[this.questionIndex];
      if (element) {
        return [element];
      }
      return [this.expandedVisibleQuestions[0]];
    },
    showFullQuestionsList: function showFullQuestionsList() {
      return this.getSettingValue('feature.show_full_list_of_questionnaires');
    },
    informationAssetsByType: function informationAssetsByType() {
      return this.sortInformationAssetsByType(this.informationAssets);
    },
    showNotApplicableToggle: function showNotApplicableToggle() {
      return this.getSettingValue('feature.show_not_applicable_toggle');
    }
  }),
  watch: {
    questionIndex: function questionIndex() {
      if (this.questionIndex !== -1) {
        this.currentQuestionId = this.visibleQuestions[this.questionIndex].id;
      }
    }
  },
  mounted: function mounted() {
    this.getQuestionsErrors();
    if (this.currentQuestionId && !this.showFullQuestionsList) {
      this.changeQuestion(this.currentQuestionId);
    }
    !this.showFullQuestionsList && window.addEventListener('keydown', this.onKeyDown);
  },
  unmounted: function unmounted() {
    window.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    getQuestionsErrors: function getQuestionsErrors() {
      var _this2 = this;
      var errors = [];
      this.questions.forEach(function (question) {
        if (question.dependencies === null) return;
        Object.keys(question.dependencies).forEach(function (number) {
          var dependantQuestion = _this2.questionMap.get(number);
          if (!dependantQuestion || !Object.keys(dependantQuestion).length) {
            errors.push(_this2.getErrorMessage(number, question.questionnumber));
          }
        });
      });
      this.errors = errors;
    },
    getErrorMessage: function getErrorMessage(number, questionNumber) {
      return "".concat(this.translate.questionWithQuestionumber, " ").concat(number).concat(this.translate.doesNotExistLogicIsIncorrectOn, " ").concat(questionNumber);
    },
    changeQuestion: function changeQuestion(id) {
      this.questionIndex = this.visibleQuestions.findIndex(function (item) {
        return item.id === id;
      });
      if (!this.showFullQuestionsList) return;
      var question = $(".question[question-id=\"".concat(id, "\"]"));
      if (!question.length) return;
      if (this.isPreview) {
        var previewModal = $('.preview-modal');
        previewModal.animate({
          scrollTop: question.offset().top - 140 + previewModal.scrollTop()
        }, 1000);
      } else {
        $('body, html').animate({
          scrollTop: question.offset().top - 130
        }, 1000);
      }
    },
    enchanceValueWithName: function enchanceValueWithName(value) {
      if (!value.name) value.name = value.title;
      return value;
    },
    answeredDependencies: function answeredDependencies(question) {
      return questionHasSomeDependencyChosen(question, this.questionMap);
    },
    update: function update(question) {
      this.$emit('update', question);
    },
    onKeyDown: function onKeyDown(event) {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'BODY' || event.target.closest('button.js-navigate-arrow')) {
        var _event$path;
        if ((_event$path = event.path) !== null && _event$path !== void 0 && _event$path.find(function (element) {
          var _element$className;
          return element === null || element === void 0 || (_element$className = element.className) === null || _element$className === void 0 ? void 0 : _element$className.includes('newChoice');
        })) return;
        if (event.key === 'ArrowRight' && this.questionIndex < this.indexOfLastAnsweredDependenciesQuestion) {
          this.setQuestionIndex(+1);
        }
        if (event.key === 'ArrowLeft' && this.questionIndex > this.indexOfFirstAnsweredDependenciesQuestion) {
          this.setQuestionIndex(-1);
        }
      }
    },
    sortInformationAssetsByType: function sortInformationAssetsByType(assets) {
      var _result,
        _this3 = this;
      var items = assets || [];
      var result = (_result = {}, _defineProperty(_result, AssetsTypes.PROCESSORS, []), _defineProperty(_result, AssetsTypes.SYSTEMS, []), _defineProperty(_result, AssetsTypes.CONTROLLERS, []), _defineProperty(_result, AssetsTypes.CUSTOM, []), _result);
      items.forEach(function (value) {
        if (value.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR) {
          result[AssetsTypes.PROCESSORS].push(value);
        } else if (value.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM) {
          result[AssetsTypes.SYSTEMS].push(value);
        } else if (value.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER) {
          result[AssetsTypes.CONTROLLERS].push(value);
        } else if (value.objType.startsWith(ObjectTypes.INFORMATION_ASSET)) {
          value = _this3.enchanceValueWithName(value);
          result[AssetsTypes.CUSTOM].push(value);
        }
      });
      return result;
    },
    setQuestionIndex: function setQuestionIndex(value) {
      var newValue = this.questionIndex + value;
      var newQuestion = this.expandedVisibleQuestions[newValue];
      if (!newQuestion) return;
      if (newQuestion.answeredDependencies) {
        this.questionIndex = newValue;
        return;
      }
      this.setQuestionIndex(value > 0 ? ++value : --value);
    }
  }
};