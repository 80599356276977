import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "border-b border-gray-450"
};
var _hoisted_3 = {
  class: "text-left"
};
var _hoisted_4 = {
  class: "pb-2"
};
var _hoisted_5 = {
  class: "text-xs"
};
var _hoisted_6 = {
  class: "pb-2"
};
var _hoisted_7 = {
  class: "text-xs"
};
var _hoisted_8 = {
  class: "pb-2"
};
var _hoisted_9 = {
  class: "text-xs"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_gap_result_row = _resolveComponent("gap-result-row");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("table", _hoisted_1, [_createElementVNode("thead", null, [_createElementVNode("tr", _hoisted_2, [_createElementVNode("th", _hoisted_3, _toDisplayString($data.translate.gapInformation), 1 /* TEXT */), $props.showRiskAssessment ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createElementVNode("th", _hoisted_4, [_createElementVNode("p", null, _toDisplayString($data.translate.likelihood), 1 /* TEXT */), _createElementVNode("p", _hoisted_5, _toDisplayString($data.translate.beforeafter), 1 /* TEXT */)]), _createElementVNode("th", _hoisted_6, [_createElementVNode("p", null, _toDisplayString($data.translate.impact), 1 /* TEXT */), _createElementVNode("p", _hoisted_7, _toDisplayString($data.translate.beforeafter), 1 /* TEXT */)]), _createElementVNode("th", _hoisted_8, [_createElementVNode("p", null, _toDisplayString($data.translate.rpi), 1 /* TEXT */), _createElementVNode("p", _hoisted_9, _toDisplayString($data.translate.beforeafter), 1 /* TEXT */)])], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.dataset, function (data, index) {
    return _openBlock(), _createBlock(_component_gap_result_row, {
      key: index,
      "show-risk-assessment": $props.showRiskAssessment,
      gap: data
    }, null, 8 /* PROPS */, ["show-risk-assessment", "gap"]);
  }), 128 /* KEYED_FRAGMENT */))])])]);
}