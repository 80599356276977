function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Alert from '@/components/Molecules/Modal/Alert';
import { sortAlphabetic } from '@/utils/sort';
export default {
  name: 'ActivityTypeSelector',
  introduction: 'Activity Type Selector',
  description: 'A selector with activity types (Data Entry Page and others)',
  token: '<activity-type-selector :activity-types="activityTypes"/>',
  components: {
    SingleSelect: SingleSelect,
    Alert: Alert
  },
  props: {
    activityTypes: {
      type: Array,
      required: true,
      note: 'Array of activity types'
    },
    modelValue: {
      type: [Number, String],
      required: false,
      default: null,
      note: 'Current typeId'
    },
    confirmAlertMessage: {
      type: String,
      required: false,
      default: '',
      note: 'Alert message to be displayed in the body of alert popup'
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Is the functionality disabled'
    },
    canBeReset: {
      required: false,
      type: Boolean,
      default: true,
      node: 'if user can reset it (incidents cant be reseted)'
    },
    resetValueTitle: {
      required: false,
      type: String,
      default: 'No activity type',
      node: 'reset value title'
    }
  },
  emits: ['update:modelValue', 'changed'],
  data: function data() {
    return {
      options: [],
      unSorted: true,
      selectedValue: this.modelValue,
      showActivityTypeConfirm: false,
      translate: {
        changeActivityType: this.$trans('Change activity type'),
        change: this.$trans('Change'),
        dismiss: this.$trans('Dismiss')
      }
    };
  },
  computed: {
    activityTypePlaceholder: function activityTypePlaceholder() {
      return this.$trans(this.resetValueTitle);
    },
    sortedActivityTypes: function sortedActivityTypes() {
      return sortAlphabetic(_toConsumableArray(this.activityTypes), 'label');
    }
  },
  mounted: function mounted() {
    this.prepareData();
  },
  methods: {
    prepareData: function prepareData() {
      var _this = this;
      this.options = [];
      if (this.canBeReset) {
        this.options.push({
          id: -1,
          value: -1,
          label: this.$trans(this.resetValueTitle)
        });
      }
      this.sortedActivityTypes.forEach(function (item) {
        _this.options.push(_objectSpread(_objectSpread({}, item), {}, {
          value: item.id,
          label: item.label
        }));
      });
    },
    onChangeType: function onChangeType(e) {
      if (e) {
        var _this$findById;
        this.selectedValue = (_this$findById = this.findById(e)) === null || _this$findById === void 0 ? void 0 : _this$findById.id;
      } else {
        this.selectedValue = -1;
      }
      if (this.modelValue && this.modelValue !== -1 || this.selectedValue && this.selectedValue !== -1) {
        this.confirmAlertMessage ? this.showActivityTypeConfirm = true : this.emitChanges();
      }
    },
    findById: function findById(id) {
      return this.options.find(function (item) {
        return item.id === id;
      });
    },
    emitChanges: function emitChanges() {
      this.$emit('changed', this.selectedValue);
      this.$emit('update:modelValue', this.selectedValue);
    },
    changeActivityType: function changeActivityType() {
      this.emitChanges();
      this.showActivityTypeConfirm = false;
    },
    keepActivityType: function keepActivityType() {
      this.selectedValue = this.modelValue;
      this.showActivityTypeConfirm = false;
    }
  }
};