import InputField from '@/components/Atoms/Inputs/InputField';
import RismaSplitButton from '@/components/Atoms/Buttons/RismaSplitButton';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import { generateKey } from '@/utils/Utils';
export default {
  name: 'InputFieldSelect',
  introduction: 'Use it to combine input field with options',
  description: "This is a custom input field with options that emits an \"input-change\"-event when input is changed \n        and \"option-change\"-event when option is changed",
  token: "\n<input-field-select\n  type=\"number\"\n  :options=\"options\" :option-value=\"selectedOption\" \n  :input-value=\"selectedInput\" :input-enabled=\"false\" \n  :input-placeholder=\"translate.textHere\" :option-placeholder=\"translate.choose\"\n  @input-change=\"selectedInput = $event\"\n  @option-change=\"selectedOption = $event\"/>/>\ndata() {\n    return {\n        options: [\n            {value: 1, label:'Hello'},\n            {value: 2, label:'To'},\n            {value: 3, label:'You'}\n        ],\n        selectedOption: 2,\n        selectedInput: '',\n    }\n}",
  components: {
    InputField: InputField,
    RismaSplitButton: RismaSplitButton,
    SingleSelect: SingleSelect,
    RismaLabel: RismaLabel
  },
  props: {
    type: {
      required: false,
      type: String,
      default: 'text',
      note: 'type for inputField component'
    },
    inputPlaceholder: {
      required: false,
      type: String,
      default: '',
      note: 'placeholder text that is written when input is empty'
    },
    optionPlaceholder: {
      required: false,
      type: String,
      default: 'Choose',
      note: 'placeholder text that is written when option is not selected'
    },
    inputValue: {
      required: false,
      type: [String, Number],
      default: '',
      note: 'v-model for input field'
    },
    optionValue: {
      required: false,
      type: [String, Boolean, Number],
      default: '',
      note: 'v-model. Selected option'
    },
    inputEnabled: {
      required: false,
      type: Boolean,
      default: true,
      note: 'Used to enable/disable input field, e.g. for showing read-only pages'
    },
    optionEnabled: {
      required: false,
      type: Boolean,
      default: true,
      note: 'Used to enable/disable options'
    },
    options: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'Available options. Each option is an object {value: Boolean|Number|String, label: String}'
    },
    withReset: {
      required: false,
      default: false,
      type: Boolean,
      note: 'Add possibility to reset chosen option'
    },
    unSorted: {
      required: false,
      default: false,
      type: Boolean,
      note: 'Do not sort items by label - keep in the order that was given'
    },
    tabindex: {
      type: [Number, String],
      required: false,
      default: 0,
      note: 'index for tabbing'
    },
    enableCurrencyTemplate: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Determine if currency format will be enabled. Use it only with type="text"'
    },
    title: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['inputChange', 'optionChange'],
  data: function data() {
    return {
      translate: {
        textHere: this.$trans('Type here'),
        choose: this.$trans('Choose')
      }
    };
  },
  computed: {
    inputId: function inputId() {
      return this.title ? generateKey() : null;
    },
    selectedOptionLabel: function selectedOptionLabel() {
      var _this = this;
      if (!this.optionValue) {
        return '';
      }
      var option = this.options.find(function (option) {
        return option.value === _this.optionValue;
      });
      return option.label;
    }
  }
};