import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5c673742"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "dataflow-modal"
};
var _hoisted_2 = {
  class: "text-left"
};
var _hoisted_3 = {
  key: 0,
  class: "w-full text-red-50",
  role: "alert"
};
var _hoisted_4 = {
  class: "flex mt-2 -mx-4"
};
var _hoisted_5 = {
  class: "w-3/12 px-2 flex flex-col"
};
var _hoisted_6 = {
  class: "w-3/12 px-2"
};
var _hoisted_7 = {
  class: "w-3/12 px-4"
};
var _hoisted_8 = {
  key: 1,
  class: "w-full mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_switch = _resolveComponent("risma-switch");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_text_box = _resolveComponent("text-box");
  var _component_modal = _resolveComponent("modal");
  var _component_alert = _resolveComponent("alert");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_modal, {
    header: $data.translate.addDataFlow,
    "button-ok-text": $data.translate.add,
    "button-dismiss-text": $data.translate.cancel,
    "dismiss-on-esc-key": false,
    "overflow-visible": true,
    type: "wide",
    onDismiss: $options.handleDismiss,
    onAccept: $options.addDataflow
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [$data.invalid ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($data.translate.pleaseSelectAStakeholder), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        class: _normalizeClass([{
          'invalid': $data.invalid
        }, 'w-3/12 px-2'])
      }, [_createElementVNode("b", null, _toDisplayString($options.dataFlowStakeholdersTitle), 1 /* TEXT */), _createVNode(_component_single_select, {
        options: $options.stakeholderOptions,
        "model-value": $data.selectedStakeholderId,
        placeholder: $data.translate.selectOne,
        "onUpdate:modelValue": $options.selectStakeholder
      }, null, 8 /* PROPS */, ["options", "model-value", "placeholder", "onUpdate:modelValue"])], 2 /* CLASS */), _createElementVNode("div", _hoisted_5, [_createElementVNode("b", null, _toDisplayString($data.translate.direction), 1 /* TEXT */), _createVNode(_component_risma_switch, {
        modelValue: $data.selectedDirection,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.selectedDirection = $event;
        }),
        options: $data.directions,
        "is-labels-show": true,
        class: "flex-wrap my-auto"
      }, null, 8 /* PROPS */, ["modelValue", "options"])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("b", null, _toDisplayString($data.translate.dataTypes), 1 /* TEXT */), _createVNode(_component_risma_select, {
        title: "".concat($data.translate.dataTypes, ":"),
        options: $props.dataTypes,
        placeholder: $data.translate.select,
        "selected-options": $data.selected.dataTypes,
        "track-by": "uniqueId",
        "label-key": "text",
        "select-label": "",
        "deselect-label": "",
        type: "small",
        onSelected: _cache[1] || (_cache[1] = function ($event) {
          return $options.handleSelected('dataTypes', $event);
        })
      }, null, 8 /* PROPS */, ["title", "options", "placeholder", "selected-options"])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("b", null, _toDisplayString($props.systemsTitle), 1 /* TEXT */), _createVNode(_component_risma_select, {
        title: $props.systemsTitle + ':',
        options: $props.systems,
        placeholder: $data.translate.select,
        "selected-options": $data.selected.systems,
        "track-by": "uniqueId",
        "label-key": "text",
        "selected-label": "",
        "select-label": "",
        "deselect-label": "",
        type: "small",
        onSelected: _cache[2] || (_cache[2] = function ($event) {
          return $options.handleSelected('systems', $event);
        })
      }, null, 8 /* PROPS */, ["title", "options", "placeholder", "selected-options"])])]), $options.isCommentsEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("b", null, _toDisplayString($data.translate.comments), 1 /* TEXT */), _createVNode(_component_text_box, {
        class: "w-full",
        onUpdated: _cache[3] || (_cache[3] = function ($event) {
          return $data.comments = $event;
        })
      })])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onDismiss", "onAccept"]), $data.showAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    header: $data.translate.areYouSure,
    body: $data.translate.ifYouDeleteThisDraftTheEnteredDataWillNo,
    class: "dismiss-alert",
    onAccept: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('toggleModal');
    }),
    onDismiss: _cache[5] || (_cache[5] = function ($event) {
      return $data.showAlert = false;
    })
  }, null, 8 /* PROPS */, ["header", "body"])) : _createCommentVNode("v-if", true)]);
}