import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import * as Utils from '@/utils/Utils';
import PreviewRiskViewMixin from './PreviewRiskViewMixin';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
export default {
  name: 'PreviewRiskTiles',
  components: {
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  mixins: [PreviewRiskViewMixin],
  props: {
    risk: {
      type: Object,
      default: function _default() {}
    },
    isRisk2: {
      type: Boolean,
      default: false
    }
  },
  emits: ['updateFromPreviewMode', 'deleteItem'],
  data: function data() {
    return {
      translate: getTranslate['PreviewRiskTiles']()
    };
  },
  computed: {
    hoverTitle: function hoverTitle() {
      return DatatableRenderer.getItemHoverTitle(this.risk);
    }
  },
  methods: {
    truncateHtml: function truncateHtml(text, maxSymbols) {
      return Utils.truncateHtml(text, maxSymbols, {
        html: true,
        maxLines: 3
      });
    }
  }
};