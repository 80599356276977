import WizardFlow from '@/components/Molecules/WizardFlow';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
export default {
  components: {
    WizardFlow: WizardFlow,
    RismaButton: RismaButton
  },
  data: function data() {
    return {
      currentDataSubject: null,
      stakeholderOptions: [{
        'value': '2590a2d2b9c6991a35e71ab3975f0692',
        'label': 'Applicants'
      }, {
        'value': '5b99a80f22067f1cd562413296f79683',
        'label': 'Employees'
      }, {
        'value': '85058f819a20c815ddece81ee230421d',
        'label': '[LINK TO CONTACT CARD]'
      }, {
        'value': 'fa8af73ac7cb7766ce8982616e756ead',
        'label': '[LINK TO CONTACT CARD]'
      }],
      isOpened: false,
      //its kitchensink, so I don't use our rules for translate
      translate: {
        welcome: this.$trans('Welcome'),
        introduction: this.$trans('Introduction'),
        stakeholder: this.$trans('Stakeholder'),
        chooseStakeholderOrAsset: this.$trans('Choose stakeholder or asset')
      }
    };
  },
  computed: {
    items: function items() {
      return [{
        headline: this.translate.welcome,
        headline2: this.translate.introduction,
        icon: 'info',
        component: 'DataFlowWizardStep1'
      }, {
        headline: this.translate.stakeholder,
        headline2: this.translate.chooseStakeholderOrAsset,
        icon: 'user',
        component: 'DataFlowWizardStepDataSubject',
        isLockedNextStep: !this.currentDataSubject,
        lockMessage: this.translate.chooseStakeholderOrAsset
      }, {
        headline: 'Step 3',
        headline2: 'Bla bla bla...',
        icon: 'send',
        component: 'ModalWizardExampleStep1'
      }];
    }
  },
  methods: {
    alertUpdate: function alertUpdate(data) {
      this.currentDataSubject = data.value;
      alert("Updated - ".concat(data.label));
    }
  }
};