function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import { updateUser, changeUserActiveStatus, getUsers } from '@/api/admin/user';
import Alert from '@/components/Molecules/Modal/Alert';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import UserEditModal from '@/components/Molecules/UserEditModal';
import RismaUserRightsPicker from '@/components/Molecules/Compliance/RismaUserRightsPicker';
import RismaModulesRightsPicker from '@/components/Molecules/Compliance/RismaModulesRightsPicker';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import Modal from '@/components/Molecules/Modal/Modal';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import { Colors } from '@/Colors';
import { UserLevels } from '@/constants/UserLevels';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { buildNavBar } from '@/menus/navbar';
import { RiskProjects } from '@/constants/risks/RiskProjects';
import { SortDirection } from '@/constants/SortDirection';
import { TinyMceBoxTypes } from '@/utils/base/TinyMceBoxConfigs';
import { searchByFields } from '@/utils/search';
import { projectsByUser } from '@/utils/projectsByUser';
import { generateKey } from '@/utils/Utils';
import { CommonAssetsMixin } from '@/mixins/CommonAssetsMixin';
export default {
  name: 'UserRightsTable',
  introduction: 'Static table with users',
  description: 'This component shows available users with <static-table> inside. No props. Have this.$emit("error")',
  token: '<user-rights-table @error="setError"></user-rights-table>',
  components: {
    RismaToggle: RismaToggle,
    Alert: Alert,
    UserEditModal: UserEditModal,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    RismaUserRightsPicker: RismaUserRightsPicker,
    RismaModulesRightsPicker: RismaModulesRightsPicker,
    PreviewModalLink: PreviewModalLink,
    RismaTable: RismaTable,
    Modal: Modal,
    TinyMceBox: TinyMceBox
  },
  mixins: [CommonAssetsMixin],
  emits: ['dismissModal', 'showNotification'],
  data: function data() {
    return {
      userChange: {},
      showDeactivateAlert: false,
      showAllUserEmailsModal: false,
      openModal: false,
      colors: Colors,
      type: '',
      filteringParameters: {
        search: '',
        showDeactivated: false
      },
      inputBaseStyles: 'border-2 border-gray-350 px-3 py-2 rounded-md min-h-40px transition duration-300 text-base text-gray-750 placeholder-italic placeholder-gray-550',
      inputInteractionStyles: 'hover:border-gray-450 focus:outline-none focus:border-blue-450 disabled:text-gray-550',
      sortBy: 'display_name',
      sortOrder: SortDirection.ASC,
      SortDirection: SortDirection,
      tableGeneratedKey: generateKey(),
      stateKey: 'users_rights_table_key',
      TinyMceBoxTypes: TinyMceBoxTypes,
      userEmails: '',
      currentOpened: {},
      translate: getTranslate['UserRightsTable']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    getAllSettings: 'getAllSettings'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    copyEmailsMessage: function copyEmailsMessage() {
      return this.translate.copiedEmailsToClipboard + '(Ctrl+A, Ctrl+C)';
    },
    filteredProjects: function filteredProjects() {
      if (!this.currentUser || !this.currentUser.projects) return [];
      return this.getFilteredProjects(this.currentUser.projects);
    },
    staticColumns: function staticColumns() {
      var column = [{
        title: ''
      }, {
        title: ''
      }, {
        title: this.translate.username,
        sortingKey: 'display_name'
      }, {
        title: this.translate.fullName,
        sortingKey: 'fullname',
        style: {
          whiteSpace: 'nowrap'
        }
      }, {
        title: this.translate.initials,
        sortingKey: 'initials'
      }, {
        title: this.translate.email,
        sortingKey: 'email'
      }];
      if (this.isPerformanceReportEnabled) {
        column.push({
          title: 'GRC Intelligence Center',
          style: {
            marginTop: '-60px',
            width: '100px'
          }
        });
      }
      column.push({
        title: 'Legalhub'
      });
      return column;
    },
    moduleSettings: function moduleSettings() {
      var structure = buildNavBar(this.getAllSettings, null);
      return [structure.actions, structure.risk, structure.controls, structure.incident, structure.contracts, structure.assets];
    },
    rightsTitles: function rightsTitles() {
      var list = [{
        title: 'Admin',
        style: {
          left: '3.4rem'
        },
        class: 'activity-header-cell'
      }];
      this.moduleSettings.forEach(function (item) {
        if (item.rights) {
          list.push({
            title: item.title,
            class: 'activity-header-cell'
          });
        }
      });
      return list;
    },
    moduleFields: function moduleFields() {
      var array = [];
      this.moduleSettings.forEach(function (item) {
        if (item.rights) {
          array.push(item.property);
        }
      });
      return array;
    },
    projects: function projects() {
      return projectsByUser(this.currentUser, this.getSettingValue);
    },
    filteredUserList: function filteredUserList() {
      var users = _toConsumableArray(this.users);
      if (this.filteringParameters.search.length) {
        var fields = ['display_name', 'email', 'fullname', 'initials', 'userLicenses'];
        users = searchByFields(users, fields, this.filteringParameters.search);
      }
      if (!this.filteringParameters.showDeactivated) {
        users = users.filter(function (user) {
          return user.active !== 0;
        });
      }
      return users;
    },
    sortedUserList: function sortedUserList() {
      var _this = this;
      if (this.sortBy) {
        return _toConsumableArray(this.filteredUserList).sort(function (a, b) {
          var fieldA = (a[_this.sortBy] || '').toLowerCase();
          var fieldB = (b[_this.sortBy] || '').toLowerCase();
          var sortValue = new Intl.Collator().compare(fieldA, fieldB);
          return _this.sortOrder === SortDirection.ASC ? sortValue : -sortValue;
        });
      }
      return this.filteredUserList;
    },
    projectColumns: function projectColumns() {
      var _this2 = this;
      return this.filteredProjects.map(function (project) {
        var icon = project.objType === ObjectTypes.COMPLIANCE_PROJECT ? 'icon_compliance' : 'ikon_risk';
        return {
          title: project.title,
          style: {
            background: "url(/assets/svg/".concat(icon, ".svg) 10% center / 25px 25px no-repeat ").concat(_this2.colors.system.blueMain),
            padding: '.4rem 2rem .2rem 3rem'
          },
          class: 'activity-header-cell'
        };
      });
    },
    columns: function columns() {
      // column element has title, style properties
      return [].concat(_toConsumableArray(this.staticColumns), _toConsumableArray(this.projectColumns), _toConsumableArray(this.rightsTitles));
    },
    isPerformanceReportEnabled: function isPerformanceReportEnabled() {
      return this.getSettingValue('feature.performance_report_enabled');
    },
    isUserPreviewModalEnabled: function isUserPreviewModalEnabled() {
      return this.getSettingValue('feature.user_preview_modal_enable');
    },
    isUserRightsTableNewEnabled: function isUserRightsTableNewEnabled() {
      return this.getSettingValue('feature.thin_user_rights_table_enabled');
    }
  }),
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useUserStore, {
    setCurrentUser: 'set'
  })), mapActions(useUsersStore, {
    updateUsers: 'update'
  })), {}, {
    setOpened: function setOpened(columnIndex, rowIndex, type) {
      if (this.currentOpened.type === type && this.currentOpened.columnIndex === columnIndex && this.currentOpened.rowIndex === rowIndex) {
        this.currentOpened = {};
        return;
      }
      this.currentOpened = {
        columnIndex: columnIndex,
        rowIndex: rowIndex,
        type: type
      };
    },
    getReadOnlyState: function getReadOnlyState(user) {
      return !!user.ad_objectid && !this.getSettingValue('app.ignore_ad_permissions') || !user.active;
    },
    buildEmails: function buildEmails() {
      this.userEmails = this.users.map(function (user) {
        return user.email;
      }).join(' ');
      this.showAllUserEmailsModal = true;
    },
    getUsersBE: function getUsersBE(params) {
      var _this3 = this;
      return getUsers(params).catch(function (error) {
        _this3.notifyError(error.fullError.message);
      });
    },
    updateUserModule: function updateUserModule(data) {
      var id = data.id,
        value = data.value;
      if (id === this.currentUser.id) {
        // you can`t remove rights from yourself
        value.target.checked = true;
        this.notifyError(this.translate.warningYouCannotPerformThisOperation);
        return;
      }
      this.updateUserFields(data);
    },
    updateUserFields: function updateUserFields(data) {
      var _this4 = this;
      var id = data.id,
        field = data.field,
        value = data.value;
      var user = _objectSpread({}, this.users.find(function (user) {
        return user.id === id;
      }));
      if (field === 'all') {
        user.level_admin = value.target.checked ? 1 : 0;
        user.is_admin = value.target.checked;
        this.moduleFields.forEach(function (mField) {
          user[mField] = value.target.checked ? UserLevels.ADMIN : UserLevels.NONE;
        });
      } else {
        user[field] = value;
      }
      updateUser(user, user.id).then(function (user) {
        if (id === _this4.currentUser.id) {
          _this4.setCurrentUser(user);
        }
      }).catch(function (error) {
        return _this4.notifyError(error.response.error);
      });
    },
    getMaxIcon: function getMaxIcon(user) {
      var classTags = ['modal-icon icon-risma-noAccess', 'modal-icon icon-risma-user', 'modal-icon icon-risma-privUser', 'modal-icon icon-risma-superUser', 'modal-icon icon-risma-admin'];
      var max = Math.max(+user.level_execution, +user.level_risk, +user.level_controls, +user.level_incidents, +user.level_contracts, +user.level_information_assets);
      return classTags[max];
    },
    notifyError: function notifyError(error) {
      this.$emit('showNotification', {
        type: 'error',
        message: error
      });
    },
    toggleModal: function toggleModal(userId) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'update';
      this.openModal = !this.openModal;
      this.information = userId !== -1 ? this.users.find(function (user) {
        return user.id === userId;
      }) : undefined;
      this.type = type;
    },
    changeUser: function changeUser(updatedUser) {
      var user = this.users.find(function (user) {
        return user.id === updatedUser.id;
      });
      var completeUser = Object.assign({}, user, updatedUser);
      this.updateUsers(completeUser);
    },
    changeActiveStatus: function changeActiveStatus(userId) {
      if (userId === this.currentUser.id) {
        this.notifyError(this.translate.warningYouCannotPerformThisOperation);
        return;
      }
      this.userChange = this.users.find(function (user) {
        return user.id === userId;
      });
      this.showDeactivateAlert = true;
    },
    handleChangeActiveStatus: function handleChangeActiveStatus() {
      var _this5 = this;
      var data = this.userChange.active ? {} : {
        undelete: 1
      };
      changeUserActiveStatus(this.userChange.id, data, this.userChange).then(function (response) {
        var message = _this5.$trans("User ".concat(response.display_name, " ").concat(response.active ? 'activated' : 'deactivated'));
        _this5.$emit('showNotification', {
          type: 'success',
          message: message
        });
        _this5.showDeactivateAlert = false;
        _this5.userChange = {};
      }).catch(function (error) {
        _this5.notifyError(error.response.error);
        _this5.showDeactivateAlert = false;
        _this5.userChange = {};
      });
    },
    sortByField: function sortByField(field) {
      if (field !== this.sortBy) {
        this.sortBy = field;
        this.sortOrder = SortDirection.ASC;
      } else {
        this.sortOrder = this.sortOrder === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
      }
    },
    getFilteredProjects: function getFilteredProjects(projects) {
      return projects.filter(function (project) {
        return !(project.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type && project.objType === ObjectTypes.RISK_PROJECT);
      });
    },
    notifyCreateUser: function notifyCreateUser(user) {
      if (this.isUserRightsTableNewEnabled) {
        this.$emit('showNotification', {
          type: 'success',
          message: user.display_name,
          header: "".concat(this.translate.user, " ").concat(this.translate.created),
          url: '/user/' + user.id
        });
        return;
      }
      this.$emit('showNotification', {
        type: 'success',
        message: "".concat(this.translate.user, " ").concat(user.display_name, " ").concat(this.translate.created)
      });
    }
  })
};