import RismaPopup from '@/components/Atoms/Containers/RismaPopup';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import InputField from '@/components/Atoms/Inputs/InputField';
export default {
  name: '',
  components: {
    RismaPopup: RismaPopup,
    RismaButton: RismaButton,
    InputField: InputField
  },
  data: function data() {
    return {
      isHiddenPopup: true
    };
  },
  methods: {
    showPopup: function showPopup() {
      this.isHiddenPopup = false;
    },
    hidePopup: function hidePopup() {
      this.isHiddenPopup = true;
    }
  }
};