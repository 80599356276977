function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import * as ControlsApi from '@/api/controls/controls';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LinkItem from '@/components/Atoms/Containers/LinkItem';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaSplitButton from '@/components/Atoms/Buttons/RismaSplitButton';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import ExpansionPanel from '@/components/Atoms/ExpansionPanel/ExpansionPanel';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import AttachmentItem from '@/components/Atoms/Attachment/AttachmentItem';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import CollapsiblePanel from '@/components/Molecules/CollapsiblePanel/CollapsiblePanel';
import Notification from '@/components/Molecules/Notification';
import AttachmentSection from '@/components/Molecules/AttachmentSection/AttachmentSection';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import StaticTable from '@/components/Molecules/StaticTable';
import TrafficLightSelector from '@/components/Organisms/Selectors/TrafficLightSelector';
import { DateFormats } from '@/constants/DateFormats';
import { ActivityLinkModules } from '@/constants/ActivityLinkModules';
import { ControlDetailsCustomFieldsMixin } from '@/components/Pages/Controls/ControlDetailsCustomFieldsMixin';
import * as Utils from '@/utils/Utils';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { isFileAllowed } from '@/utils/validations';
import { Colors } from '@/Colors';
import { ControlsEfficiencyTypes } from '@/constants/ControlFields';
import { formatDateWithTimezone } from '@/utils/date';
import { ControlActions } from '@/constants/ControlActions';
export default {
  name: 'CompleteControl',
  introduction: 'A modal that shows latest completion information of control and handle complete functionality',
  description: 'This is a Molecule component for /controls2/controls/:id. Parent component: ControlDetails',
  token: '<complete-control :cpath="cpath" :id="id" />',
  components: {
    LinkItem: LinkItem,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    RismaSplitButton: RismaSplitButton,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    Notification: Notification,
    AttachmentSection: AttachmentSection,
    ActivityCustomFields: ActivityCustomFields,
    ExpansionPanel: ExpansionPanel,
    TextBox: TextBox,
    FeatherIcon: FeatherIcon,
    AttachmentItem: AttachmentItem,
    CollapsiblePanel: CollapsiblePanel,
    TrafficLightSelector: TrafficLightSelector,
    TrafficLight: TrafficLight,
    RismaLabel: RismaLabel,
    StaticTable: StaticTable
  },
  mixins: [ControlDetailsCustomFieldsMixin],
  props: {
    id: {
      type: [Number, String],
      required: true,
      note: 'An id of a control'
    },
    cpath: {
      type: String,
      required: true,
      note: 'A cpath of a control'
    },
    isFilesDisable: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is files upload section disabled'
    }
  },
  emits: ['updateModalTitel', 'close', 'toggleDisableInteraction', 'update', 'dataUpdated'],
  data: function data() {
    return {
      isLoaded: false,
      activeHistoryItem: {},
      control: {},
      completedComment: '',
      completedLinkUrl: '',
      completedLinkTitle: '',
      completedLinkNotSaved: false,
      interactionDisabled: false,
      loadingFiles: {},
      uploadingMax: 0,
      uploadingValue: 0,
      notificationMessage: '',
      notificationType: 'error',
      completedEfficiency: null,
      completedEfficiencyComment: '',
      colors: Colors,
      isCompletedEfficiencyDirty: false,
      translate: getTranslate['CompleteControl']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, ['users'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'controlActivityTypes'
  })), mapState(useActivityTypesStore, ['controlActivityTypesWithFilteredFields'])), {}, {
    activityTypes: function activityTypes() {
      return this.controlActivityTypesWithFilteredFields(function (field) {
        return field.activityLinkModule === ActivityLinkModules.CONTROL_COMPLETION;
      });
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.activityTypes.find(function (item) {
        return item.id === _this.control.activityTypeId;
      });
    },
    completedCommentPlaceholder: function completedCommentPlaceholder() {
      return this.translate.addComment + '...';
    },
    prevCompletions: function prevCompletions() {
      // Latest three completions
      return this.control.history.slice(0, 3);
    },
    reviewers: function reviewers() {
      var result = '';
      if (this.control.userIds && this.control.userIds.review) {
        result = Utils.findPropsByIds(this.control.userIds.review, this.users, 'display_name');
      }
      return result;
    },
    completeError: function completeError() {
      return this.translate.addAFileCommentOrLinkInclLinkTitleTo;
    },
    completePartiallyError: function completePartiallyError() {
      return this.translate.pleaseAddACommentToPerformACompletionWith;
    },
    emptyUrlField: function emptyUrlField() {
      return this.translate.pleaseFillLinkToFieldsCorrectly;
    },
    completeFiletypesError: function completeFiletypesError() {
      return this.translate.pleaseChooseTheFilesWithCorrectFiletypes;
    },
    fileUploadingWarning: function fileUploadingWarning() {
      return this.translate.fileUploadInProgress;
    },
    isPartiallyFlowOn: function isPartiallyFlowOn() {
      return !!this.getSettingValue('risma.partially_completed_controls');
    },
    hasHistory: function hasHistory() {
      return this.control && this.control.history && this.control.history.length > 0;
    },
    hasReviews: function hasReviews() {
      return this.control && this.control.reviews && this.control.reviews.length > 0;
    },
    hasFeatureFormData: function hasFeatureFormData() {
      return this.getSettingValue('feature.form_data_upload');
    },
    showSkipButton: function showSkipButton() {
      var settingEnabled = this.getSettingValue('feature.skip_control_button');
      return !this.control.completed && settingEnabled;
    },
    filesList: function filesList() {
      var files = this.control.completedFiles || [];
      files.forEach(function (file) {
        file.filename = file.name;
        file.url = "/api/2.0/controls/files/".concat(file.id);
      });
      return files;
    },
    efficiencyAssessmentVisible: function efficiencyAssessmentVisible() {
      var _this$currentActivity;
      return !!this.getSettingValue('feature.controls_efficiency_enabled') && !!((_this$currentActivity = this.currentActivityType) !== null && _this$currentActivity !== void 0 && _this$currentActivity.controlsEfficiencyType);
    },
    completedEfficiencyTrafficLights: function completedEfficiencyTrafficLights() {
      var _this$currentActivity2;
      var trafficLights = _toConsumableArray(this.colors.trafficLights);
      if (((_this$currentActivity2 = this.currentActivityType) === null || _this$currentActivity2 === void 0 ? void 0 : _this$currentActivity2.controlsEfficiencyType) === ControlsEfficiencyTypes.efficiencyAssessment) {
        trafficLights.shift();
        return trafficLights;
      }
      return trafficLights;
    },
    efficiencyAssessmentsColumns: function efficiencyAssessmentsColumns() {
      return [this.translate.date, this.translate.user, this.translate.assessment, this.translate.comment];
    },
    previousControlEfficiencyAssessments: function previousControlEfficiencyAssessments() {
      return this.control.history.filter(function (item) {
        return item.completedEfficiency !== undefined && item.completedEfficiency !== null;
      });
    },
    efficiencyAssessmentsDataset: function efficiencyAssessmentsDataset() {
      var _this2 = this;
      return this.previousControlEfficiencyAssessments.map(function (item) {
        var _this2$completedEffic;
        return {
          date: _this2.formattedDate(item.completed, _this2.control.deadlineTimezone, DateFormats.DATE_FORMAT),
          user: _this2.findUserNameById(item.completedUserid),
          assessment: {
            color: (_this2$completedEffic = _this2.completedEfficiencyTrafficLights.find(function (option) {
              return +option.number === +item.completedEfficiency;
            })) === null || _this2$completedEffic === void 0 ? void 0 : _this2$completedEffic.color
          },
          comment: item.completedEfficiencyComment
        };
      });
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this3 = this;
      this.isLoaded = false;
      var promises = [];
      promises.push(this.getControlByCpath(this.cpath).then(function (data) {
        _this3.handleGetControlByCpath(data);
        _this3.$emit('updateModalTitel', "".concat(_this3.translate.completeControl, " \"").concat(_this3.cpath || '', " ").concat(data.title || '', "\""));
      }));
      Promise.all(promises).then(function () {
        _this3.isLoaded = true;
      });
    },
    close: function close() {
      this.$emit('close');
    },
    getControlByCpath: function getControlByCpath() {
      return ControlsApi.getControlByCpathAndId(this.cpath, this.id);
    },
    handleGetControlByCpath: function handleGetControlByCpath(data) {
      var _this4 = this;
      this.control = _objectSpread(_objectSpread({}, this.control), data);
      this.completedComment = this.control.completedComments ? this.control.completedComments : '';
      this.completedLinkUrl = this.control.completedUrl ? this.control.completedUrl : '';
      this.completedLinkTitle = this.control.completedUrlTitle ? this.control.completedUrlTitle : '';
      this.completedEfficiency = this.getCompletedEfficiency();
      this.completedEfficiencyComment = this.getCompletedEfficiencyComment();
      if (!this.completedEfficiencyTrafficLights.find(function (item) {
        return item.number === _this4.completedEfficiency;
      })) {
        this.completedEfficiency = this.completedEfficiencyTrafficLights[0].number;
      }
      if (this.control.history && this.control.history.length) {
        this.showFullHistory(this.control.history[0]);
      }
      if (this.control.reviews) {
        this.enhanceAndSortReviews(this.control.reviews);
      }
    },
    getCompletedEfficiency: function getCompletedEfficiency() {
      var _this$getLatestNonSki;
      if (this.control.completedEfficiency !== null) {
        return this.control.completedEfficiency;
      }
      return (_this$getLatestNonSki = this.getLatestNonSkippedHistoryEntry()) === null || _this$getLatestNonSki === void 0 ? void 0 : _this$getLatestNonSki.completedEfficiency;
    },
    getCompletedEfficiencyComment: function getCompletedEfficiencyComment() {
      var _this$getLatestNonSki2;
      if (this.control.completedEfficiencyComment) {
        return this.control.completedEfficiencyComment;
      }
      return ((_this$getLatestNonSki2 = this.getLatestNonSkippedHistoryEntry()) === null || _this$getLatestNonSki2 === void 0 ? void 0 : _this$getLatestNonSki2.completedEfficiencyComment) || '';
    },
    getLatestNonSkippedHistoryEntry: function getLatestNonSkippedHistoryEntry() {
      return (this.control.history || []).find(function (historyEntry) {
        return historyEntry.rismaState !== RismaControlsUtils.states.STATE_NOT_PERFORMED;
      });
    },
    isExistedAndFilled: function isExistedAndFilled(field) {
      return !!(field && field.trim() !== '');
    },
    isExpectedEvidenceShow: function isExpectedEvidenceShow(expectEvidence) {
      return this.getSettingValue('risma.show_expected_evidence') && this.isExistedAndFilled(expectEvidence);
    },
    formattedDate: function formattedDate(date, timezone) {
      var dataFormat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DateFormats.DATE_TIME_FORMAT_FULL;
      return formatDateWithTimezone(date, timezone, dataFormat);
    },
    changedCompletionLink: function changedCompletionLink(attachment) {
      this.completedLinkUrl = attachment.url;
      this.completedLinkTitle = attachment.filename;
    },
    uploadSavedAttachment: function uploadSavedAttachment(attachment) {
      var _this5 = this;
      return new Promise(function (resolve, reject) {
        _this5.uploadFile(attachment, resolve, reject);
      });
    },
    uploadFile: function uploadFile(attachment, resolve, reject) {
      ControlsApi.completeControlNew(this.cpath, this.id, {
        addFile: _objectSpread({}, attachment)
      }, this.showUploadProgress.bind(this, attachment.filename)).then(function (data) {
        resolve(data);
      }).catch(function (error) {
        return reject(error);
      });
    },
    showUploadProgress: function showUploadProgress(name, progressEvent) {
      this.loadingFiles[name] = {
        uploadingMax: progressEvent.total,
        uploadingValue: progressEvent.loaded
      };
      this.uploadingMax = 0;
      this.uploadingValue = 0;
      for (var key in this.loadingFiles) {
        this.uploadingMax += this.loadingFiles[key].uploadingMax;
        this.uploadingValue += this.loadingFiles[key].uploadingValue;
      }
    },
    uploadSavedAttachments: function uploadSavedAttachments(attachments) {
      var _this6 = this;
      var uploadPromises = [];
      attachments.forEach(function (attachment) {
        uploadPromises.push(_this6.uploadSavedAttachment(attachment));
      });
      Promise.all(uploadPromises).then(function (responses) {
        // uploadPromises are executed asynchronously
        // and that's why we need to search by the maximum number of files
        // instead of by last index in array
        var lastResponse = responses.reduce(function (prev, current) {
          return prev.completedFiles.length > current.completedFiles.length ? prev : current;
        });
        _this6.control.completedFiles = lastResponse.completedFiles;
        _this6.toggleDisableInteraction();
        _this6.resetAttachments();
        _this6.hideProgressBar();
      });
    },
    isValidAttachments: function isValidAttachments(attachments) {
      var _this7 = this;
      var valid = true;
      attachments.forEach(function (attachment) {
        if (!isFileAllowed(attachment, _this7.getSettingValue('risma.attachments_allowed'))) {
          valid = false;
        }
      });
      return valid;
    },
    setErrorMessage: function setErrorMessage(error) {
      this.notificationType = 'error';
      this.notificationMessage = error;
    },
    handleAddedAttach: function handleAddedAttach(attachments) {
      if (!this.isValidAttachments(attachments)) {
        this.setErrorMessage(this.completeFiletypesError);
        return;
      }
      // checks attachment for exist, IE has an issue
      if (attachments) {
        if (Array.isArray(attachments)) {
          this.toggleDisableInteraction();
          this.resetAttachments();
          this.uploadSavedAttachments(attachments);
        }
      }
    },
    uploadFormDataFiles: function uploadFormDataFiles(formData) {
      var _this8 = this;
      this.showFileUploadingMessage('warning');
      this.toggleDisableInteraction();
      if (!(formData instanceof FormData) || !formData.entries().next().value) {
        //check if formData is empty, cause bug on the backend
        this.setErrorMessage(this.translate.theFileWasNotUploadedPleaseTryAgain);
        this.toggleDisableInteraction(false);
      } else {
        ControlsApi.uploadFormDataFiles(this.cpath, this.id, formData).then(function (data) {
          _this8.control.completedFiles = data.completedFiles;
          _this8.showFileUploadingMessage('success');
          _this8.toggleDisableInteraction();
        }).catch(function (error) {
          _this8.showFileUploadingMessage('error', error.response.data);
          _this8.toggleDisableInteraction();
        });
      }
    },
    showFileUploadingMessage: function showFileUploadingMessage(type, err) {
      this.notificationType = type;
      switch (type) {
        case 'warning':
          this.notificationMessage = this.translate.fileUploadInProgress;
          break;
        case 'success':
          this.notificationMessage = this.translate.filesUploadingCompleted;
          break;
        default:
          this.notificationMessage = "".concat(err.error, ": ").concat(err.message);
      }
    },
    toggleDisableInteraction: function toggleDisableInteraction() {
      var disableNotificationHandle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.interactionDisabled = !this.interactionDisabled;
      this.$emit('toggleDisableInteraction');
      if (disableNotificationHandle) {
        if (this.interactionDisabled && !this.hasFeatureFormData) {
          this.notificationType = 'warning';
          this.notificationMessage = this.fileUploadingWarning;
        } else {
          this.notificationMessage = '';
        }
      }
    },
    resetAttachments: function resetAttachments() {
      this.loadingFiles = {};
      this.uploadingMax = 0;
      this.uploadingValue = 0;
    },
    hideProgressBar: function hideProgressBar() {
      this.$refs.attachmentSection.toggleShowProgress();
    },
    handleLinkUpdate: function handleLinkUpdate(e) {
      this.$emit('dataUpdated');
      this.completedLinkNotSaved = true;
      this.changedCompletionLink(e);
    },
    removeAttachment: function removeAttachment(id) {
      return ControlsApi.removeAttachment(id);
    },
    removeAttachmentLocally: function removeAttachmentLocally(id) {
      this.control.completedFiles = this.control.completedFiles.filter(function (file) {
        return file.id !== id;
      });
    },
    handleRemoveAttachment: function handleRemoveAttachment(id) {
      this.removeAttachment(id).then(this.removeAttachmentLocally(id));
    },
    isControlReadyForSave: function isControlReadyForSave() {
      var isCommentAdded = this.isExistedAndFilled(this.completedComment);
      var isLinkAdded = this.isExistedAndFilled(this.completedLinkTitle) && this.isExistedAndFilled(this.completedLinkUrl);
      var isAttachmentsAdded = this.control.completedFiles && this.control.completedFiles.length > 0;
      return isCommentAdded || isLinkAdded || isAttachmentsAdded;
    },
    getDataToComplete: function getDataToComplete(isPartial, isSave) {
      var _this$currentActivity3;
      var data = {
        comments: this.completedComment,
        attachmentUrl: this.completedLinkUrl,
        attachmentUrlTitle: this.completedLinkTitle
      };
      if ((_this$currentActivity3 = this.currentActivityType) !== null && _this$currentActivity3 !== void 0 && _this$currentActivity3.controlsEfficiencyType && this.isCompletedEfficiencyDirty) {
        data.completedEfficiency = this.completedEfficiency;
        data.completedEfficiencyComment = this.completedEfficiencyComment;
      }

      // CF support
      if (this.control.customFieldValues) {
        data.customFieldValues = this.getCustomFieldsValues(this.control.customFieldValues);
      }
      if (!isSave) {
        data.complete = true;
        if (isPartial) {
          data.partiallyCompleted = true;
        }
      }
      return data;
    },
    handleCompleteControl: function handleCompleteControl(e, isPartial, isSave) {
      var _this9 = this;
      this.notificationMessage = '';
      if (isPartial) {
        var isValid = this.isExistedAndFilled(this.completedComment);
        if (!isValid) {
          this.setErrorMessage(this.completePartiallyError);
          return;
        }
      } else if (!isSave) {
        var isSwitchOnRequiredFields = this.getSettingValue('risma.mandatory_documentation');
        var _isValid = isSwitchOnRequiredFields ? this.isControlReadyForSave() : true;
        if (!_isValid) {
          this.setErrorMessage(this.completeError);
          return;
        }
      }
      if (this.completedLinkTitle.length && (!this.completedLinkUrl || !this.completedLinkUrl.length || this.completedLinkUrl === 'http://')) {
        this.setErrorMessage(this.emptyUrlField);
        return;
      }
      var data = this.getDataToComplete(isPartial, isSave);
      this.isLoaded = false;
      ControlsApi.completeControlNew(this.cpath, this.id, data).then(function () {
        _this9.isLoaded = true;
        _this9.$emit('update', {
          state: ControlActions.complete,
          isSave: isSave,
          updatedControlId: _this9.id
        });
        _this9.close();
      }).catch(function (error) {
        _this9.setErrorMessage(error.response.data.error || error.response.data);
        _this9.isLoaded = true;
      });
    },
    handleSkipControl: function handleSkipControl() {
      var _this10 = this;
      this.isLoaded = false;
      var data = {
        comments: this.completedComment,
        attachmentUrl: this.completedLinkUrl,
        attachmentUrlTitle: this.completedLinkTitle
      };
      this.notificationMessage = '';
      ControlsApi.skipControl(this.cpath, data).then(function () {
        _this10.isLoaded = true;
        _this10.$emit('update', {
          state: ControlActions.skip,
          isSave: false
        });
        _this10.close();
      }).catch(function (error) {
        _this10.setErrorMessage(_this10.$trans(error.response.data));
        _this10.isLoaded = true;
      });
    },
    showFullHistory: function showFullHistory(prevCompletion) {
      this.activeHistoryItem = _objectSpread({}, prevCompletion);
      this.enhanceAndSortReviews(this.activeHistoryItem.reviews);
    },
    enhanceAndSortReviews: function enhanceAndSortReviews(reviews) {
      var _this11 = this;
      reviews.map(function (item) {
        item._actionHuman = _this11.$trans(RismaControlsUtils.humanReadableAction(item.action));
      });
      reviews.sort(function (a, b) {
        return new Date(b.created) - new Date(a.created);
      });
    },
    findUserNameById: function findUserNameById(userId) {
      return Utils.findPropsByIds([userId], this.users, 'display_name');
    },
    commentUpdated: function commentUpdated(e) {
      this.$emit('dataUpdated');
      this.completedComment = e;
    },
    onActivityCustomFieldsChanged: function onActivityCustomFieldsChanged(e) {
      this.$emit('dataUpdated');
      this.control.customFieldValues = e;
    },
    updateCompletedEfficiency: function updateCompletedEfficiency(value) {
      this.completedEfficiency = value;
      this.isCompletedEfficiencyDirty = true;
    },
    updateCompletedEfficiencyComment: function updateCompletedEfficiencyComment(value) {
      this.completedEfficiencyComment = value;
      this.isCompletedEfficiencyDirty = true;
    }
  }
};