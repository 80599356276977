import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fixed bottom-6 right-6 transition z-100", {
      'invisible': !$data.isVisible
    }])
  }, [_createElementVNode("button", {
    class: "p-2 font-semibold h-10 w-10 rounded-full shadow-box-360 text-white bg-blue-750 hover:bg-blue-250",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.scrollToTop && $options.scrollToTop.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-up",
    class: "stroke-2 cursor-pointer text-white m-auto"
  })])], 2 /* CLASS */);
}