export var TableCellsTypes = {
  TEXT: 'TEXT',
  TITLE: 'TITLE',
  PREVIEW_MODAL: 'PREVIEW_MODAL',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  DEADLINE_DATE: 'DEADLINE_DATE',
  ORGANISATION: 'ORGANISATION',
  DOCUMENT: 'DOCUMENT',
  TRAFFIC_LIGHT: 'TRAFFIC_LIGHT',
  HIERARCHICAL_LIST: 'HIERARCHICALLIST',
  DELETE_BUTTON: 'DELETE_BUTTON',
  BUTTON: 'BUTTON',
  HTML: 'HTML',
  LINKS: 'LINKS',
  LINK: 'LINK',
  LIST: 'LIST',
  ATTACHMENTS: 'ATTACHMENTS',
  BOOL: 'BOOL',
  STAR: 'STAR',
  ICON: 'ICON'
};
export var DocumentsTypes = {
  0: 'expired',
  1: 'last_month',
  2: 'missing',
  3: 'valid'
};