function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-e26da4b2"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "hidden"
};
var _hoisted_2 = {
  class: "whitespace-pre"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _createSlots2;
  var _component_save_report = _resolveComponent("save-report");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_trafficlight = _resolveComponent("trafficlight");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_save_report, {
    type: $data.reportType,
    "state-local": $data.stateLocal,
    "risk-project-id": $props.projectId,
    "report-options": $props.reportOptions,
    onLoad: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.loadReportByReplacingQueryParams($event);
    })
  }, null, 8 /* PROPS */, ["type", "state-local", "risk-project-id", "report-options"]), _createVNode(_component_risma_datatable, {
    ref: "reportsDataTable",
    dataset: _ctx.datasetSlice,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "page-length": $data.pageLength,
    "filter-by-column": true,
    "disabled-filter-by-columns": $data.disabledFilterByColumns,
    "decouple-filter-fields": $data.decoupleFilterFields,
    "column-filters-sorting": $data.columnFiltersSorting,
    "column-sorting": $data.columnSorting,
    "columns-meta": $data.columnsMeta,
    order: $data.orderDefault,
    "wrapper-height-sticky": 500,
    "state-local": $data.stateLocal,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged,
    onDismissModal: $options.onDismissModal
  }, _createSlots((_createSlots2 = {
    title: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_createVNode(_component_preview_modal_link, {
        activity: item.activity,
        onUpdated: $options.onEntityUpdated,
        onDismissModal: $options.onDismissModal
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_risma_title_with_icon, {
            icon: "confidential",
            title: item.fieldText,
            "title-attribute": item.hoverTitle,
            "display-icon": item.confidential
          }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onUpdated", "onDismissModal"])];
    })
  }, _defineProperty(_createSlots2, $data.RiskBaseModelProps.THREAT_IDS, _withCtx(function (_ref2) {
    var item = _ref2.item;
    return [_createElementVNode("span", _hoisted_2, _toDisplayString(item.field), 1 /* TEXT */)];
  })), _defineProperty(_createSlots2, "_", 2), _createSlots2), [_renderList($data.linkedColumnsForPreview, function (linkCol) {
    return {
      name: linkCol,
      fn: _withCtx(function (_ref3) {
        var item = _ref3.item;
        return [item.activities && item.activities.length ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(linkCol, "_").concat(item.id)
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.activities, function (activity, index) {
          return _openBlock(), _createBlock(_component_preview_modal_link, {
            key: "".concat(activity.fieldText, "_").concat(index),
            class: "hover:underline",
            activity: activity.activity,
            onUpdated: $options.onEntityUpdated,
            onDismissModal: $options.onDismissModal
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(activity.fieldText), 1 /* TEXT */)];
            }),

            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onUpdated", "onDismissModal"]);
        }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("div", {
          key: linkCol
        }))];
      })
    };
  }), _renderList(_ctx.trafficLightRismaDatatableFields, function (trafficLightCol) {
    return {
      name: trafficLightCol,
      fn: _withCtx(function (_ref4) {
        var item = _ref4.item;
        return [item.field.color ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(trafficLightCol)
        }, [_createVNode(_component_trafficlight, {
          color: item.field.color
        }, null, 8 /* PROPS */, ["color"]), _createElementVNode("p", _hoisted_1, _toDisplayString(item.field.value) + "-" + _toDisplayString(item.field.label), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("span", {
          key: "".concat(trafficLightCol)
        }))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset", "dataset-total", "dataset-total-sliced", "page-length", "disabled-filter-by-columns", "decouple-filter-fields", "column-filters-sorting", "column-sorting", "columns-meta", "order", "state-local", "onPageChange", "onOnStateChanged", "onDismissModal"])]);
}