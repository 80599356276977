import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_wizard_flow = _resolveComponent("wizard-flow");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [$data.isOpened ? (_openBlock(), _createBlock(_component_wizard_flow, {
    key: 0,
    items: $options.items,
    "stakeholder-options": $data.stakeholderOptions,
    "current-data-subject": $data.currentDataSubject,
    onAccept: _cache[0] || (_cache[0] = function ($event) {
      return $data.isOpened = false;
    }),
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return $data.isOpened = false;
    }),
    "onUpdate:dataSubject": $options.alertUpdate
  }, null, 8 /* PROPS */, ["items", "stakeholder-options", "current-data-subject", "onUpdate:dataSubject"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_button, {
    text: "Click me",
    type: "save",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $data.isOpened = true;
    })
  })]);
}