import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = ["id"];
var _hoisted_2 = ["data-index"];
var _hoisted_3 = {
  class: "flex flex-col"
};
var _hoisted_4 = {
  key: 1,
  class: "text-center text-sm mt-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_DoughnutChart = _resolveComponent("DoughnutChart");
  var _component_export_frame = _resolveComponent("export-frame");
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: "mx-auto w-full",
    style: _normalizeStyle({
      maxWidth: $props.chartMaxWidth,
      minWidth: $props.chartMinWidth
    })
  }, [_createVNode(_component_export_frame, {
    "is-export-on-parent": $props.isExportOnParent,
    "use-export": $props.useDefaultExport,
    "excel-export-function": $options.isExcelExportAvailable ? $options.excelExportFunction : null
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, [_createVNode(_component_DoughnutChart, {
        ref: "doughnut",
        data: $options.chartData,
        options: $options.defaultOptions,
        class: _normalizeClass(["flex justify-center items-center", {
          '!h-full !w-full': !$props.cover || $props.chartMaxWidth !== '100%'
        }]),
        plugins: $options.defaultPlugins
      }, null, 8 /* PROPS */, ["data", "options", "class", "plugins"]), $props.customLegendEnabled ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: $options.customLegendId,
        class: "p-2 flex flex-wrap"
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.customLabels, function (label, index) {
        return _openBlock(), _createElementBlock("div", {
          key: label,
          class: "flex py-1 w-fit min-w-150px"
        }, [_createElementVNode("span", {
          "data-index": index,
          class: "flex cursor-pointer"
        }, [_createElementVNode("span", {
          class: "w-12 h-4 block mr-2",
          style: _normalizeStyle({
            'background-color': label.color
          })
        }, null, 4 /* STYLE */), _createElementVNode("span", {
          class: _normalizeClass(['decoration-gray-600', {
            'line-through': label.hidden
          }])
        }, _toDisplayString(label.label), 3 /* TEXT, CLASS */)], 8 /* PROPS */, _hoisted_2)]);
      }), 128 /* KEYED_FRAGMENT */))], 8 /* PROPS */, _hoisted_1)) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-export-on-parent", "use-export", "excel-export-function"])], 4 /* STYLE */), _createElementVNode("div", _hoisted_3, [$props.title ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    class: "text-center text-sm mt-2 break-words",
    truncate: false,
    type: $props.titleType,
    title: $props.title
  }, null, 8 /* PROPS */, ["type", "title"])) : _createCommentVNode("v-if", true), $props.description ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($props.description), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])]);
}