export function sheet_to_array_buffer(s) {
  if (typeof ArrayBuffer !== 'undefined') {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  } else {
    var _buf = new Array(s.length);
    for (var _i = 0; _i !== s.length; ++_i) _buf[_i] = s.charCodeAt(_i) & 0xFF;
    return _buf;
  }
}