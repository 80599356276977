import debounce from 'lodash/debounce';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'ScrollTopButton',
  components: {
    FeatherIcon: FeatherIcon
  },
  introduction: 'Scroll Back To Top Button',
  description: 'The button that appears when you start scrolling and on the click smoothly scrolls you to the top of the page.',
  token: '<scroll-top-button />',
  data: function data() {
    return {
      handleDebouncedScroll: null,
      isVisible: false
    };
  },
  mounted: function mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100);
    window.addEventListener('scroll', this.handleDebouncedScroll);
  },
  beforeUnmount: function beforeUnmount() {
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  },
  methods: {
    handleScroll: function handleScroll() {
      this.isVisible = window.scrollY > 0;
    },
    scrollToTop: function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
};