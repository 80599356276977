import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "min-h-220px flex flex-col"
};
var _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  var _component_no_search_results = _resolveComponent("no-search-results");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.addContactPerson,
    "show-buttons": false,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [$props.users.length ? (_openBlock(), _createBlock(_component_static_table, {
        key: 0,
        dataset: $options.dataset,
        columns: $options.columns,
        "is-row-clickable": true,
        theme: $data.StaticTableThemes.zebra,
        "onClick:row": $options.clickRow
      }, {
        add: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_createElementVNode("button", {
            class: "focus:outline-none",
            onClick: function onClick($event) {
              return $options.addUser(slotData.id);
            }
          }, [_createVNode(_component_feather_icon, {
            class: "text-blue-350 stroke-2 cursor-pointer",
            icon: "plus-circle"
          })], 8 /* PROPS */, _hoisted_2)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset", "columns", "theme", "onClick:row"])) : (_openBlock(), _createBlock(_component_no_search_results, {
        key: 1,
        body: "",
        class: "m-auto"
      }))])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header"]);
}