function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import { CustomFieldsMixin } from '@/mixins/CustomFieldsMixin';
import { generateComputed } from '@/utils/base/generateComputed';
export default {
  name: 'ProcessNodePage',
  components: {
    RismaTitle: RismaTitle,
    TinyMceBox: TinyMceBox,
    ActivityCustomFields: ActivityCustomFields
  },
  mixins: [CustomFieldsMixin],
  props: {
    data: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Process node object'
    },
    processTreeId: {
      required: true,
      type: [String, Number, Boolean],
      note: 'processTreeId from route'
    },
    processNodeId: {
      required: true,
      type: [String, Number, Boolean],
      note: 'processNoteId from route'
    },
    readonly: {
      required: false,
      default: false,
      type: Boolean,
      note: 'Readonly (via Awareness email)'
    },
    nodeDataOuter: {
      required: false,
      default: null,
      type: Object
    }
  },
  data: function data() {
    return {
      error: '',
      translate: {
        description: this.$trans('Description')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useActivityTypesStore, ['processNodeActivityTypes'])), generateComputed(['description'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.processNodeActivityTypes.find(function (item) {
        return +item.id === +_this.data.activityTypeId;
      });
    },
    titleUniqueId: function titleUniqueId() {
      var _this$nodeDataOuter;
      var uId = ((_this$nodeDataOuter = this.nodeDataOuter) === null || _this$nodeDataOuter === void 0 ? void 0 : _this$nodeDataOuter.uniqueId) || '';
      return uId.trim() === '' ? 'Id' : uId;
    },
    nodeTitle: function nodeTitle() {
      var _this$nodeDataOuter2;
      return ((_this$nodeDataOuter2 = this.nodeDataOuter) === null || _this$nodeDataOuter2 === void 0 ? void 0 : _this$nodeDataOuter2.title) || '';
    }
  })
};