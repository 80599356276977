import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "attachment-section"
};
var _hoisted_2 = {
  class: "attachment-section-body"
};
var _hoisted_3 = {
  key: 0,
  class: "attachment-section-files"
};
var _hoisted_4 = {
  class: "attachment-section-input-file"
};
var _hoisted_5 = ["max", "value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_file_upload = _resolveComponent("risma-file-upload");
  var _component_input_field = _resolveComponent("input-field");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.isLinkSaveSuccess ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    ref: "linkSuccessNotification",
    type: "success"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.urlIsSaved), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */)) : _createCommentVNode("v-if", true), $data.isLinkSaveFail ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    ref: "linkFailNotification",
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.pleaseFillBothFieldsCorrectly), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [!$props.isFilesDisabled ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("form", {
    ref: "uploadForm",
    onSubmit: _cache[0] || (_cache[0] = function () {
      return $options.submitUpload && $options.submitUpload.apply($options, arguments);
    })
  }, [_createVNode(_component_risma_file_upload, {
    "show-progress-bar": false,
    "show-uploaded-files": false,
    "use-form-data": $options.useFormData,
    onInput: $options.fileUploaded,
    onUploadFiles: $options.uploadFiles,
    onStartLoading: $options.toggleShowProgress
  }, null, 8 /* PROPS */, ["use-form-data", "onInput", "onUploadFiles", "onStartLoading"])], 544 /* HYDRATE_EVENTS, NEED_PATCH */)])])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [_createVNode(_component_input_field, {
    modelValue: $data.linkUrl,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.linkUrl = $event;
    }),
    placeholder: $data.translate.insertLinkToFileOrDestination + ' (http, https, ftp...)',
    title: $data.translate.linkTo,
    type: "url",
    onUpdated: _cache[2] || (_cache[2] = function ($event) {
      return $options.updateLinkData($event, 'url');
    })
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "title"]), _createVNode(_component_input_field, {
    modelValue: $data.linkTitle,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.linkTitle = $event;
    }),
    class: "mt-3",
    placeholder: $data.translate.insertLinkTitle,
    type: "text",
    onUpdated: _cache[4] || (_cache[4] = function ($event) {
      return $options.updateLinkData($event, 'title');
    })
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"])]), $data.showProgressBar ? (_openBlock(), _createElementBlock("progress", {
    key: 1,
    max: $props.uploadingMax,
    value: $props.uploadingValue,
    class: "w-full"
  }, null, 8 /* PROPS */, _hoisted_5)) : _createCommentVNode("v-if", true)])])]);
}