import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, withCtx as _withCtx, vShow as _vShow, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7e441390"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  class: "flex my-auto bg-inherit"
};
var _hoisted_2 = {
  key: 0,
  class: "p-2"
};
var _hoisted_3 = {
  href: "https://support.rismasystems.com/",
  target: "_blank",
  rel: "noopener noreferrer"
};
var _hoisted_4 = {
  key: 1,
  class: "p-2 relative"
};
var _hoisted_5 = {
  key: 0,
  class: "absolute flex items-center justify-center w-6 h-6 bg-red-610 right-0 top-0 rounded-full"
};
var _hoisted_6 = {
  key: 2,
  class: "p-2"
};
var _hoisted_7 = ["placeholder"];
var _hoisted_8 = {
  key: 4,
  class: "p-2"
};
var _hoisted_9 = {
  class: "p-2 relative"
};
var _hoisted_10 = {
  class: "user-section-item"
};
var _hoisted_11 = {
  class: "user-section-item"
};
var _hoisted_12 = {
  class: "user-section-item"
};
var _hoisted_13 = {
  class: "menu-item",
  href: "/logout"
};
var _hoisted_14 = {
  key: 0,
  class: "user-section-item"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_notifications_modal = _resolveComponent("notifications-modal");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_search_modal = _resolveComponent("search-modal");
  var _component_router_link = _resolveComponent("router-link");
  var _directive_click_outside = _resolveDirective("click-outside");
  return $data.loaded ? _withDirectives((_openBlock(), _createElementBlock("nav", _hoisted_1, [!$props.oldVersion ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", null, [_createElementVNode("a", _hoisted_3, [_createVNode(_component_feather_icon, {
    icon: "help-circle",
    width: "34",
    height: "34",
    class: "text-inherit stroke-2 inline-block"
  })])])])) : _createCommentVNode("v-if", true), !$props.oldVersion ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_feather_icon, {
    icon: "message-circle",
    width: "34",
    height: "34",
    class: "text-inherit stroke-2 inline-block cursor-pointer",
    onClick: $options.toggleNotifications
  }, null, 8 /* PROPS */, ["onClick"]), $options.unreadNotificationsCount ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($options.unreadNotificationsCount), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $data.showNotifications ? (_openBlock(), _createBlock(_component_notifications_modal, {
    key: 1,
    notifications: $props.notifications,
    onToggle: $options.toggleNotifications,
    onClickNotification: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('clickNotification', $event);
    }),
    onMarkAllNotificationsAsRead: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('markAllNotificationsAsRead');
    })
  }, null, 8 /* PROPS */, ["notifications", "onToggle"])) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, $options.hideNotifications]]) : _createCommentVNode("v-if", true), $props.oldVersion ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", {
    onClick: _cache[2] || (_cache[2] = function () {
      return $options.emitSearch && $options.emitSearch.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "search",
    width: "34",
    height: "34",
    class: "text-inherit stroke-2 inline-block m-auto"
  })])])) : _createCommentVNode("v-if", true), !$props.oldVersion ? (_openBlock(), _createElementBlock("div", {
    key: 3,
    class: _normalizeClass(['p-2 relative h-12', $data.isSearchShow ? 'w-40rem' : 'box-content w-8'])
  }, [_createElementVNode("div", null, [$data.isSearchShow ? _withDirectives((_openBlock(), _createElementBlock("input", {
    key: 0,
    ref: "globalSearch",
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.searchField = $event;
    }),
    placeholder: $data.translate.search,
    type: "text",
    class: "h-12 w-40rem text-black absolute top-0 left-0 rounded-full py-2 px-4 outline-none focus:ring pr-8",
    onKeyup: _cache[4] || (_cache[4] = _withKeys(function () {
      return $options.onSearch && $options.onSearch.apply($options, arguments);
    }, ["enter"]))
  }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_7)), [[_vModelText, $data.searchField]]) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [!$data.isSearchLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 0,
    class: _normalizeClass(["absolute top-0.8rem right-0.8rem z-10", $data.isSearchShow ? 'text-blue-750' : 'text-white'])
  }, null, 8 /* PROPS */, ["class"])) : _createCommentVNode("v-if", true)]), _createElementVNode("span", {
    class: _normalizeClass(["".concat($data.isSearchLoaded ? 'bg-inherit' : 'bg-transparent'), 'w-8 h-8 rounded-full cursor-pointer absolute', $data.isSearchShow && 'w-4 h-4 right-0 top-auto mr-2 '])
  }, [_createVNode(_component_feather_icon, {
    icon: "search",
    width: "34",
    height: "34",
    class: _normalizeClass(['text-inherit stroke-2 inline-block', $data.isSearchShow && 'w-6 h-6 transform -translate-x-1/2 -translate-y-1/2 absolute inset-1/2']),
    onClick: $options.showSearchBar
  }, null, 8 /* PROPS */, ["class", "onClick"]), $data.showSearchModal ? (_openBlock(), _createBlock(_component_search_modal, {
    key: 0,
    data: $data.searchDataset,
    scoring: $props.scoring,
    users: $props.users,
    organisations: $props.organisations,
    "current-user": _ctx.currentUser,
    "activity-types": $props.activityTypes,
    "workflow-statuses": $data.workflowStatuses,
    onToggle: $options.toggleSearch
  }, null, 8 /* PROPS */, ["data", "scoring", "users", "organisations", "current-user", "activity-types", "workflow-statuses", "onToggle"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)])], 2 /* CLASS */)) : _createCommentVNode("v-if", true), $options.isPerformanceReportEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_router_link, {
    to: "/performance-report"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "bar-chart",
        width: "34",
        height: "34",
        class: "text-inherit stroke-2 inline-block"
      })];
    }),
    _: 1 /* STABLE */
  })])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_9, [_withDirectives(_createElementVNode("div", {
    class: "cursor-pointer",
    onClick: _cache[5] || (_cache[5] = function () {
      return $options.toggleUserSection && $options.toggleUserSection.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "settings",
    width: "34",
    height: "34",
    class: "text-inherit stroke-2 inline-block"
  })], 512 /* NEED_PATCH */), [[_vShow, $options.showAvatar || !$props.oldVersion]]), _withDirectives(_createElementVNode("div", {
    class: "cursor-pointer",
    onClick: _cache[6] || (_cache[6] = function () {
      return $options.toggleUserSection && $options.toggleUserSection.apply($options, arguments);
    })
  }, _toDisplayString($options.getAvatar), 513 /* TEXT, NEED_PATCH */), [[_vShow, !$options.showAvatar && $props.oldVersion]]), _withDirectives(_createElementVNode("div", {
    class: "user-section absolute bg-gray-250 z-10 r-0 absolute right-0 mt-2 py-2 px-6 rounded-md border border-gray-350 whitespace-nowrap",
    onClick: _cache[7] || (_cache[7] = function () {
      return $options.toggleUserSection && $options.toggleUserSection.apply($options, arguments);
    })
  }, [_createElementVNode("div", _hoisted_10, [_createVNode(_component_router_link, {
    to: {
      name: $data.SettingsNav.personal.routerName
    },
    class: "menu-item"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.personalSettings), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])]), _createElementVNode("div", _hoisted_11, [$data.SettingsNav.controlPanel.rights($data.user, $data.settings) ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    class: "menu-item",
    to: {
      name: $data.SettingsNav.general.rights($data.user) ? $data.SettingsNav.general.routerName : $data.SettingsNav.controlPanel.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.general), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $data.SettingsNav.actionsSettings.rights($data.user, $data.settings) ? (_openBlock(), _createBlock(_component_router_link, {
    key: 1,
    class: "menu-item",
    to: {
      name: $data.SettingsNav.actionsSettings.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.actionsSettings), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $data.SettingsNav.contractsControlPanel.rights($data.user, $data.settings) ? (_openBlock(), _createBlock(_component_router_link, {
    key: 2,
    class: "menu-item",
    to: {
      name: $data.SettingsNav.contract.rights($data.user, $data.settings) ? $data.SettingsNav.contract.routerName : $data.SettingsNav.contractsControlPanel.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.contract), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $data.SettingsNav.controlsSettings.rights($data.user, $data.settings) ? (_openBlock(), _createBlock(_component_router_link, {
    key: 3,
    class: "menu-item",
    to: {
      name: $data.SettingsNav.controlsSettings.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.controlsSettings), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $data.SettingsNav.complianceControlPanel.rights($data.user, $data.settings) ? (_openBlock(), _createBlock(_component_router_link, {
    key: 4,
    class: "menu-item",
    to: {
      name: $data.SettingsNav.complianceSettings.rights($data.user, $data.settings) ? $data.SettingsNav.complianceSettings.routerName : $data.SettingsNav.complianceControlPanel.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.complianceSettings), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $data.SettingsNav.riskSettings.rights($data.user, $data.settings) || $data.SettingsNav.riskPeriod.rights($data.user, $data.settings) ? (_openBlock(), _createBlock(_component_router_link, {
    key: 5,
    class: "menu-item",
    to: $data.SettingsNav.riskSettings.rights($data.user, $data.settings) ? {
      name: $data.SettingsNav.riskProjects.routerName
    } : $options.riskPeriodUrl
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.riskSettings), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $data.SettingsNav.incident.rights($data.user, $data.settings) ? (_openBlock(), _createBlock(_component_router_link, {
    key: 6,
    class: "menu-item",
    to: {
      name: $data.SettingsNav.incident.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.incident), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $data.SettingsNav.companiesSettings.rights() ? (_openBlock(), _createBlock(_component_router_link, {
    key: 7,
    class: "menu-item",
    to: {
      name: $data.SettingsNav.companiesSettings.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.companiesSettings), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $data.SettingsNav.appSettings.rights($data.user, $data.settings) ? (_openBlock(), _createBlock(_component_router_link, {
    key: 8,
    class: "menu-item",
    to: {
      name: $data.SettingsNav.appSettings.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.appSettings), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("a", _hoisted_13, _toDisplayString($data.translate.logout), 1 /* TEXT */)]), $data.settings['servername'] && $data.user.is_admin ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_component_router_link, {
    class: "menu-item",
    to: {
      name: $data.SettingsNav.admin.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$trans($data.SettingsNav.admin.title)), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])])) : _createCommentVNode("v-if", true)], 512 /* NEED_PATCH */), [[_vShow, $data.isUserSectionExpanded]])])])), [[_directive_click_outside, $options.hide]]) : _createCommentVNode("v-if", true);
}