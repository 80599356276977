var _excluded = ["deleteItem"];
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getCurrentInstance } from 'vue';
import { mapActions, mapState } from 'pinia';
import { useMainStore } from '@/Store/mainStore';
import { useUserStore } from '@/Store/userStore';
import { refreshExpiredCaches } from '@/routes/storeCache';
import { logNavigation } from '@/utils/navigationLog';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Modal from '@/components/Molecules/Modal/Modal';
import Notification from '@/components/Molecules/Notification';
import NodePage from '@/components/Pages/Compliance/NodeEdit/NodePage';
import { TabTypes } from '@/constants/Tabs';
import ComponentByObjectType from '@/utils/base/ComponentByObjectType';
import { disableScrolling, enableScrolling, getScrollPosition } from '@/utils/setScrolling';
import { ObjectTypes } from '@/constants/ObjectTypes';
import TrapFocusBoundary from '@/components/Atoms/Containers/TrapFocusBoundary';
import { LoginGuardMixin } from '@/mixins/LoginGuardMixin';
import { checkERMByProjectId } from '@/utils/risks/check';
export default {
  name: 'PreviewModalLink',
  components: {
    TrapFocusBoundary: TrapFocusBoundary,
    NodePage: NodePage,
    Modal: Modal,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    Notification: Notification
  },
  mixins: [LoginGuardMixin],
  provide: {
    isPreview: true
  },
  props: {
    activity: {
      type: Object,
      required: true
    },
    selectedRoute: {
      required: false,
      type: Object,
      default: null,
      note: 'if it should be opened with special tab, format: {tab, id}'
    }
  },
  emits: ['deleteItem', 'dismissModal'],
  data: function data() {
    return {
      modalVisible: false,
      fullViewLink: '',
      trapFocusSelector: '[data-trap="headerNavbar"]',
      id: getCurrentInstance().uid,
      accessErrorMessage: '',
      locallyUpdatedUrl: '',
      translate: {
        goToFullView: this.$trans('Go to full view'),
        youdonthaveAccess: this.$trans('Sorry, you don\'t have access to this page')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useMainStore, {
    previewGlobalStatus: function previewGlobalStatus(store) {
      return store.previewGlobalStatus(this.id);
    }
  })), mapState(useUserStore, ['currentUser'])), {}, {
    attrs: function attrs() {
      // eslint-disable-next-line no-unused-vars
      var _ref = this.$attrs || {},
        deleteItem = _ref.deleteItem,
        listeners = _objectWithoutProperties(_ref, _excluded);
      delete listeners.class;
      return listeners;
    },
    fullViewUrl: function fullViewUrl() {
      var _this$selectedRoute;
      if (this.fullViewLink) return this.fullViewLink;
      if (this.activity.objType === ObjectTypes.USER) {
        return '/user/' + this.activity.id; // temporary while user doesnt have url prop
      }

      var url = this.locallyUpdatedUrl || this.activity.url;
      if (this.selectedRoute && ((_this$selectedRoute = this.selectedRoute) === null || _this$selectedRoute === void 0 ? void 0 : _this$selectedRoute.tab) === TabTypes.QUESTIONNAIRE && !this.activity.questionnaireId) {
        url = url.replace(TabTypes.QUESTIONNAIRE, TabTypes.DESCRIPTION);
      }
      if (this.activity.objType === ObjectTypes.RISK_RISK && checkERMByProjectId(this.activity.projectId, this.currentUser)) {
        return url += "?scoringId=".concat(this.activity.scoringId);
      }
      return url;
    },
    component: function component() {
      var activity = this.activity;
      return ComponentByObjectType(activity.objType)(activity);
    }
  }),
  watch: {
    previewGlobalStatus: function previewGlobalStatus() {
      if (!this.previewGlobalStatus && this.modalVisible) {
        this.dismissModal();
      }
    }
  },
  beforeUnmount: function beforeUnmount() {
    if (this.modalVisible === true) {
      this.scrollPosition = 0;
      this.dismissModal();
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useMainStore, ['openPreviewModal', 'closePreviewModalById'])), {}, {
    changeTab: function changeTab(link) {
      this.fullViewLink = link;
    },
    showModal: function showModal(event) {
      var _this = this;
      if (event !== null && event !== void 0 && event.ctrlKey) {
        return window.open(this.activity.url, '_blank');
      }
      this.loginGuard().then(refreshExpiredCaches).then(function () {
        if (!_this.modalVisible) {
          _this.scrollPosition = getScrollPosition();
          disableScrolling(_this.scrollPosition);
          logNavigation(_this.$router.currentRoute.value.fullPath, _this.activity.url, true);
        }
        _this.openPreviewModal(_this.id);
        _this.modalVisible = true;
      });
    },
    dismissModal: function dismissModal() {
      var _this2 = this;
      this.loginGuard().then(function () {
        logNavigation(_this2.activity.url, _this2.$router.currentRoute.value.fullPath, true);
        _this2.modalVisible = false;
        _this2.fullViewLink = '';
        _this2.closePreviewModalById(_this2.id);
        _this2.$emit('dismissModal');
        enableScrolling(_this2.scrollPosition);
      });
    },
    onUrlChange: function onUrlChange(newUrl) {
      this.locallyUpdatedUrl = newUrl;
    },
    deleteItem: function deleteItem(event) {
      this.$emit('deleteItem', event);
      this.dismissModal();
    },
    showAccessError: function showAccessError() {
      this.accessErrorMessage = this.translate.youdonthaveAccess;
    }
  })
};