import { computed, ref, toRefs } from 'vue';
import * as RiskApi from '@/api/risk/risk';
export function risksForParentRisk(props, risk) {
  var risks = ref([]);
  var _toRefs = toRefs(props),
    projectId = _toRefs.projectId,
    periodId = _toRefs.periodId;
  var getRisks = function getRisks() {
    return RiskApi.getRisks(projectId.value, periodId.value).then(function (_ref) {
      var list = _ref.list;
      return risks.value = list;
    }).catch(function (e) {
      throw e;
    });
  };
  var risksFormatted = computed(function () {
    var filteredRisks = risks.value.filter(function (item) {
      return item.rno !== risk.value.rno;
    });
    return filteredRisks.map(function (item) {
      return {
        label: item.rno + '. ' + item.title,
        value: item.rno
      };
    });
  });
  return {
    getRisks: getRisks,
    risks: risks,
    risksFormatted: risksFormatted
  };
}