import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_text_box = _resolveComponent("text-box");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_text_box, {
    text: "Here are some lines of text"
  }), _createVNode(_component_text_box, {
    enabled: false,
    text: "This text-box is read-only\nbut still has text"
  })]);
}