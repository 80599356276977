"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var Node = /** @class */ (function () {
    function Node(inputData) {
        var _this = this;
        var data = JSON.parse(JSON.stringify(inputData));
        Object.keys(data).forEach(function (key) {
            if (key === 'children') {
                data[key] = data[key].map(function (childNode) {
                    return new Node(childNode);
                });
            }
            ;
            _this[key] = data[key];
        });
    }
    Node.prototype.getQuestionnaireIdsFromTree = function () {
        var questionnaireIds = [this.questionnaireId];
        this.children.forEach(function (childNode) {
            questionnaireIds = __spreadArray(__spreadArray([], questionnaireIds, true), childNode.getQuestionnaireIdsFromTree(), true);
        });
        var setOfNumbers = new Set(questionnaireIds);
        return Array.from(setOfNumbers).filter(Boolean);
    };
    return Node;
}());
exports.default = Node;
