import { Colors } from '@/Colors';
export default {
  name: 'RismaPagesize',
  introduction: '',
  description: '',
  token: '<risma-pagesize v-model="tableState.pageLength" />',
  props: {
    modelValue: {
      type: [Number, String],
      required: false,
      default: 50,
      note: 'Selected value'
    },
    pagingOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [10, 25, 50, 100];
      },
      note: 'Paging options'
    }
  },
  emits: ['update:modelValue'],
  data: function data() {
    return {
      colors: Colors,
      translate: {
        displayMenuRecordsPerPage: this.$trans('Display _MENU_ records per page')
      }
    };
  },
  computed: {
    perPageLabel: function perPageLabel() {
      return this.translate.displayMenuRecordsPerPage.split('_MENU_');
    }
  },
  methods: {
    emitChanges: function emitChanges($event) {
      this.$emit('update:modelValue', parseInt($event.target.value));
    }
  }
};