import i18n from '@/i18n';
export var getTranslate = {
  CompanyContactModal: function CompanyContactModal() {
    return {
      editCompanyContact: i18n.t('Edit company contact'),
      addCompanyContact: i18n.t('Add company contact'),
      name: i18n.t('Name'),
      nameCannotBeEmpty: i18n.t('Name cannot be empty'),
      email: i18n.t('E-mail'),
      invalidEmailAddress: i18n.t('Invalid e-mail address'),
      phone: i18n.t('Phone'),
      title: i18n.t('Title'),
      companies: i18n.t('Companies'),
      selectCompany: i18n.t('Select company'),
      pleaseSelectCompany: i18n.t('Please select company'),
      save: i18n.t('Save')
    };
  }
};