function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getAllActionMainReport } from '@/api/execution/initiative';
import { getInitiativesFromIds } from '@/utils/format/objectsFromIds';
import GanttChart from '@/components/Molecules/Charts/GanttChart';
import CollapsiblePanel from '@/components/Molecules/CollapsiblePanel/CollapsiblePanel';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { purifyItemField } from '@/utils/Utils';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { LinkedActivitiesForReportMixin } from '@/mixins/LinkedActivitiesForReportMixin';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import * as Utils from '@/utils/Utils';
import { compareStrings } from '@/utils/compare';
import { Colors } from '@/Colors';
import { ReportTypes } from '@/constants/reports';
export default {
  name: 'ActionMainReport',
  introduction: 'Action Main Report',
  description: 'This is Page component for /actions/reports/',
  token: '<action-main-report/>',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    CollapsiblePanel: CollapsiblePanel,
    GanttChart: GanttChart,
    RismaDatatable: RismaDatatable,
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink,
    Trafficlight: Trafficlight,
    SaveReport: SaveReport
  },
  mixins: [RismaDatatableDefaultMixin, SaveReportMixin, LinkedActivitiesForReportMixin],
  data: function data() {
    return {
      isReportSaved: false,
      actions: [],
      updatedAction: null,
      dataset: [],
      columnsMeta: [{
        targets: ['deadline', 'title'],
        width: '170px'
      }, {
        targets: ['description'],
        width: '600px'
      }],
      columnSorting: [{
        key: 'trafficLight',
        sortingFunc: this.trafficLightSort
      }],
      disabledFilterByColumns: ['path', 'description', 'status', 'deadline', 'percentage', 'target', 'comment'],
      showGanttChart: false,
      showGanttLoader: true,
      stateLocal: 'action_main_report_',
      customFieldsColumns: [],
      defaultCustomFieldsValues: {},
      customFieldsValuesByActionId: {},
      reportType: ReportTypes.ACTIONS,
      translate: getTranslate['ActionMainReport'](),
      isLoading: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), mapState(useOrganisationsStore, ['organisations'])), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useTagsStore, ['tags'])), mapState(useActivityTypesStore, {
    activityTypes: 'executionActivityTypes'
  })), {}, {
    dependingOnTitle: function dependingOnTitle() {
      return this.getSettingValue('risma.depending_on_name') || this.translate.dependingOn;
    },
    precursorToTitle: function precursorToTitle() {
      return this.getSettingValue('risma.precursor_to_name') || this.translate.precursorTo;
    },
    columns: function columns() {
      return _objectSpread(_objectSpread({
        trafficLight: this.translate.trafficLight,
        path: this.translate.path,
        title: this.translate.title,
        description: this.translate.description,
        status: this.translate.status,
        openClosed: this.translate.open + '/' + this.translate.closed,
        deadline: this.translate.start + '/' + this.translate.deadline,
        organisations: this.translate.organisations,
        responsible: this.translate.responsible,
        notifications: this.translate.notifications,
        tags: this.translate.tags,
        dependingOn: this.dependingOnTitle,
        precursorTo: this.precursorToTitle,
        percentage: this.translate.completionPercentage,
        target: this.translate.target,
        comment: this.translate.otherComments
      }, this.customFieldsColumns), this.getLinkedActivitiesColumns());
    },
    ganttChartData: function ganttChartData() {
      var _this = this;
      return this.datasetSlice.map(function (item) {
        return _this.actions.find(function (action) {
          return item.title.fieldText === action.title && item.path.field === action.ipath;
        });
      });
    },
    customFields: function customFields() {
      var customFields = [];
      this.actions.forEach(function (action) {
        action.customFields.forEach(function (field) {
          if (!customFields.find(function (cf) {
            return field.id === cf.id;
          })) {
            customFields.push(field);
          }
        });
      });
      return customFields;
    },
    customExport: function customExport() {
      var _this2 = this;
      return {
        columns: function columns(_columns) {
          var index = _columns.findIndex(function (item) {
            return item.key === 'deadline';
          });
          if (index === -1) return _columns;
          var customizedColumns = _toConsumableArray(_columns);
          var splitColumns = [{
            key: 'start',
            label: _this2.translate.start
          }, {
            key: 'deadline',
            label: _this2.translate.deadline
          }];
          customizedColumns.splice.apply(customizedColumns, [index, 1].concat(splitColumns));
          return customizedColumns;
        },
        dataset: function dataset(_dataset) {
          return _dataset.map(function (row) {
            var customizedRow = _objectSpread({}, row);
            var _ref = customizedRow.deadline.fieldText ? customizedRow.deadline.fieldText.split('/') : [],
              _ref2 = _slicedToArray(_ref, 2),
              _ref2$ = _ref2[0],
              startDate = _ref2$ === void 0 ? '' : _ref2$,
              _ref2$2 = _ref2[1],
              deadlineDate = _ref2$2 === void 0 ? '' : _ref2$2;
            customizedRow.start = {
              field: startDate,
              fieldText: startDate,
              label: _this2.translate.start
            };
            customizedRow.deadline = {
              field: deadlineDate,
              fieldText: deadlineDate,
              label: _this2.translate.deadline
            };
            return customizedRow;
          });
        }
      };
    },
    decoupleFilterFields: function decoupleFilterFields() {
      return [{
        key: 'organisations'
      }, {
        key: 'responsible'
      }, {
        key: 'notifications'
      }, {
        key: 'tags'
      }, {
        key: 'dependingOn'
      }, {
        key: 'precursorTo'
      }].concat(_toConsumableArray(this.decoupleLinkedActivitiesFilterFields));
    }
  }),
  mounted: function mounted() {
    Promise.all([this.getAllActionReport(), this.getLinkedActivitiesRequests()]).then(this.refreshDatatable);
  },
  methods: {
    refreshDatatable: function refreshDatatable() {
      var _this$getPropertiesWi = this.getPropertiesWithCustomFields(this.actions, this.activityTypes),
        extendedColumns = _this$getPropertiesWi.extendedColumns,
        customFieldsValuesByEntity = _this$getPropertiesWi.customFieldsValuesByEntity,
        defaultCustomFields = _this$getPropertiesWi.defaultCustomFields,
        columnFilterOptions = _this$getPropertiesWi.columnFilterOptions;
      this.defaultCustomFieldsValues = defaultCustomFields;
      this.customFieldsValuesByActionId = customFieldsValuesByEntity;
      this.customFieldsColumns = extendedColumns;
      this.enhanceColumnFilters(columnFilterOptions, this.disabledFilterByColumns, this.decoupleFilterFields);
      this.prepareDataset(this.actions);
      this.isLoading = false;
      this.loadSavedReport();
    },
    beforeLeavePanel: function beforeLeavePanel() {
      this.showGanttChart = false;
      this.showGanttLoader = true;
    },
    updateFromPreviewMode: function updateFromPreviewMode(item) {
      this.updatedAction = item;
    },
    onDismissModal: function onDismissModal() {
      var _this3 = this;
      if (!this.updatedAction) return;
      var index = this.actions.findIndex(function (action) {
        return +action.id === +_this3.updatedAction.id;
      });
      this.actions[index] = this.updatedAction;
      this.updatedAction = null;
      this.prepareDataset(this.actions);
    },
    onItemDelete: function onItemDelete() {
      this.getAllActionReport().then(this.refreshDatatable);
    },
    getAllActionReport: function getAllActionReport() {
      var _this4 = this;
      this.isLoading = true;
      var params = '?data[]=customFields&filters[status]=1,0&data[]=links';
      return getAllActionMainReport(params).then(function (_ref3) {
        var list = _ref3.list;
        _this4.actions = list.sort(function (a, b) {
          return compareStrings(a.ipath, b.ipath);
        });
      });
    },
    prepareDataset: function prepareDataset(rows) {
      var _this5 = this;
      this.dataset = rows.map(function (node) {
        var customFields = _objectSpread(_objectSpread({}, _this5.defaultCustomFieldsValues), _this5.customFieldsValuesByActionId[node.id]);
        var dependingActivitiesList = [];
        var precursorActivitiesList = [];
        if (node.dependingOn && node.dependingOn.length) {
          dependingActivitiesList = getInitiativesFromIds(node.dependingOn, _this5.actions);
        }
        if (node.precursorTo && node.precursorTo.length) {
          precursorActivitiesList = getInitiativesFromIds(node.precursorTo, _this5.actions);
        }
        var trafficLightField = DatatableRenderer.prepareTrafficLightField(node.trafficLight);
        return _objectSpread(_objectSpread({
          trafficLight: {
            color: trafficLightField.color,
            field: trafficLightField,
            fieldText: trafficLightField.color
          },
          path: {
            field: node.ipath
          },
          title: {
            activity: node,
            fieldText: node.title
          },
          description: {
            field: node.description || '',
            fieldText: node.description || ''
          },
          status: {
            field: _this5.checkFieldForNull(node.status)
          },
          openClosed: {
            field: node.closed ? _this5.translate.closed : _this5.translate.open
          },
          deadline: {
            field: node.start + '<b>/</b>' + node.deadline,
            fieldText: "".concat(node.start, "/").concat(node.deadline)
          },
          organisations: {
            field: Utils.findPropsByIds(node.organisationIds, _this5.organisations, 'visible_name')
          },
          responsible: {
            field: Utils.findPropsByIds(node.userIds.responsible, _this5.users, 'display_name')
          },
          notifications: {
            field: Utils.findPropsByIds(node.userIds.notifications, _this5.users, 'display_name')
          },
          tags: {
            field: Utils.findPropsByIds(node.tagIds, _this5.tags, 'tag')
          },
          dependingOn: {
            field: DatatableRenderer.prepareLinks(dependingActivitiesList.map(function (item) {
              return {
                link: item.url,
                title: "".concat(item.ipath, " - ").concat(item.title),
                isRouterLinkWithClick: true
              };
            }), _this5),
            fieldText: dependingActivitiesList.map(function (item) {
              return "".concat(item.ipath, " - ").concat(item.title);
            }).join(', ')
          },
          precursorTo: {
            field: DatatableRenderer.prepareLinks(precursorActivitiesList.map(function (item) {
              return {
                link: item.url,
                title: "".concat(item.ipath, " - ").concat(item.title),
                isRouterLinkWithClick: true
              };
            }), _this5),
            fieldText: precursorActivitiesList.map(function (item) {
              return "".concat(item.ipath, " - ").concat(item.title);
            }).join(', ')
          },
          percentage: {
            field: node.completionPercentage + '%'
          },
          target: {
            field: _this5.checkFieldForNull(node.target)
          },
          comment: {
            field: _this5.checkFieldForNull(node.comment)
          }
        }, customFields), _this5.getLinkedActivitiesFields(node.id, node.links));
      });
      this.enhanceDataset(this.dataset, this.columns);
    },
    trafficLightSort: function trafficLightSort(data, index) {
      return data.sort(function (prev, current) {
        var _ref4 = [purifyItemField(prev[index], true), purifyItemField(current[index], true)],
          prevColor = _ref4[0],
          currentColor = _ref4[1];
        var _ref5 = [Colors.getTrafficlightByLabel(prevColor).number, Colors.getTrafficlightByLabel(currentColor).number],
          prevNumericValue = _ref5[0],
          currentNumericValue = _ref5[1];
        return currentNumericValue - prevNumericValue;
      });
    },
    checkFieldForNull: function checkFieldForNull(field) {
      return field === null ? '' : field;
    },
    onLoadReport: function onLoadReport(data) {
      this.applySettingsFromLoadedReport(data);
    },
    loadSavedReport: function loadSavedReport() {
      var _this$$route$query,
        _this6 = this;
      if (!((_this$$route$query = this.$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.query)) return;
      this.$nextTick(function () {
        return _this6.applySettingsFromLoadedReport(JSON.parse(_this6.$route.query.query));
      });
    }
  }
};