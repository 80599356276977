function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import Notification from '@/components/Molecules/Notification';
import CreateInitiativeModal from '@/components/Organisms/CreateInitiativeModal';
import ControlCreationModal from '@/components/Molecules/Controls/ControlCreationModal';
import { MODULES, getModuleTitles } from '@/constants/modules';
import { create as _createInitiative } from '@/api/execution/initiative';
export default {
  name: 'LinksRecommendedModal',
  introduction: 'LinksRecommendedModal is used to update links for gap',
  description: 'LinksRecommendedModal is the modal from where the users can add mitigation activities',
  token: '<links-recommended-modal />',
  components: {
    Notification: Notification,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    CreateInitiativeModal: CreateInitiativeModal,
    ControlCreationModal: ControlCreationModal
  },
  props: {
    article: {
      type: Object,
      required: true,
      note: 'article info'
    },
    initiatives: {
      required: true,
      type: Array,
      note: 'List of all initiatives'
    }
  },
  emits: ['addRecommendedActivity', 'dismiss'],
  data: function data() {
    return {
      ModuleTitles: getModuleTitles(),
      notificationMessage: '',
      notificationType: 'success',
      showCreateControl: false,
      showCreateInitiative: false,
      preset: {
        title: '',
        description: ''
      },
      translate: getTranslate['LinksRecommendedModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), {}, {
    suggestedActivities: function suggestedActivities() {
      return this.article.suggestedActivities;
    },
    subTitle: function subTitle() {
      return this.translate.clickAddInOrderToApplyTheRecommendedActi;
    }
  }),
  methods: {
    addRecommendedItem: function addRecommendedItem(module, activity) {
      this.preset.title = activity.activity_header;
      this.preset.description = activity.activity_description;
      if (module === MODULES.CONTROLS) {
        this.showCreateControl = true;
      } else {
        this.showCreateInitiative = true;
      }
    },
    createControl: function createControl(newControl) {
      this.$emit('addRecommendedActivity', newControl);
      this.showNotificationAfterCreate(newControl.title);
      this.showCreateControl = false;
    },
    createInitiative: function createInitiative(newInitiative) {
      var _this = this;
      this.showCreateInitiative = false;
      _createInitiative(newInitiative).then(function (response) {
        _this.$emit('addRecommendedActivity', response);
        _this.showNotificationAfterCreate(response.title);
      });
    },
    showNotificationAfterCreate: function showNotificationAfterCreate(name) {
      this.handleShowNotify({
        type: 'success',
        message: name + ' ' + this.translate.added
      });
    },
    handleShowNotify: function handleShowNotify(_ref) {
      var type = _ref.type,
        message = _ref.message;
      this.notificationType = type;
      this.notificationMessage = message;
      setTimeout(this.handleDismissNotification, 3000);
    },
    handleDismissNotification: function handleDismissNotification() {
      this.notificationMessage = '';
    }
  }
};