import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-68d316f3"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "pop-up-menu"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "menu", {}, undefined, true)])), [[_directive_click_outside, $options.dismiss]]);
}