import i18n from '@/i18n';
export var getTranslate = {
  AdminActivityTypesModal: function AdminActivityTypesModal() {
    return {
      title: i18n.t('Title'),
      module: i18n.t('Module'),
      roleTitle: i18n.t('Role title'),
      optionalFields: i18n.t('Optional fields'),
      customFields: i18n.t('Custom fields'),
      fieldType: i18n.t('Field type'),
      dontShow: i18n.t('Don\'t show'),
      descriptionPage: i18n.t('Description page'),
      completionWindow: i18n.t('Completion window'),
      active: i18n.t('Active'),
      required: i18n.t('Required'),
      cancel: i18n.t('Cancel'),
      create: i18n.t('Create'),
      save: i18n.t('Save'),
      custom: i18n.t('Custom'),
      system: i18n.t('System'),
      dataController: i18n.t('Data controller'),
      dataProcessor: i18n.t('Data processor'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      maxLengthIs50Characters: i18n.t('Max length is 50 characters'),
      theActivityTypeWithThisTitleAlreadyExists: i18n.t('The activity type with this title already exists'),
      disabled: i18n.t('Disabled'),
      efficiencyAssessment: i18n.t('Efficiency assessment'),
      efficiencyAssessmentRed: i18n.t('Efficiency assessment (Red, Yellow and Green assessment scale)'),
      extendedEfficiencyAssessment: i18n.t('Extended efficiency assessment (Red, Yellow, Green and Grey assessment scale)')
    };
  },
  ControlPanelSettings: function ControlPanelSettings() {
    return {
      settingsAreSaved: i18n.t('Settings are saved'),
      somethingWentWrongWhileUploading: i18n.t('Something went wrong while uploading'),
      notSet: i18n.t('Not set'),
      mustBeThan: i18n.t('must be %s than'),
      lower: i18n.t('Lower'),
      higher: i18n.t('Higher')
    };
  },
  AdminActivityTypesWell: function AdminActivityTypesWell() {
    return {
      activityTypes: i18n.t('Activity types'),
      title: i18n.t('Title'),
      module: i18n.t('Module'),
      areYouSureYouWantToDeleteThisActivityType: i18n.t('Are you sure you want to delete this activity type?'),
      activityTypeDeleted: i18n.t('Activity type deleted'),
      activityTypeCreated: i18n.t('Activity type created'),
      activityTypeWasSuccessfullyUpdated: i18n.t('Activity type was successfully updated'),
      custom: i18n.t('Custom'),
      system: i18n.t('System'),
      dataProcessor: i18n.t('Data processor'),
      dataController: i18n.t('Data controller'),
      filterByModule: i18n.t('Filter by module'),
      noFiltersApplied: i18n.t('No filters applied')
    };
  },
  AdminBulkTabItems: function AdminBulkTabItems() {
    return {
      activities: i18n.t('Activities'),
      updateSolutionIds: i18n.t('Update solution ID`s'),
      assignOrganisation: i18n.t('Assign organisation'),
      ownership: i18n.t('Ownership'),
      assignTags: i18n.t('Assign tags')
    };
  },
  AdminControlsWell: function AdminControlsWell() {
    return {
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      parent: i18n.t('Parent'),
      chooseParentOptional: i18n.t('Choose parent (optional)'),
      type: i18n.t('Type'),
      chooseType: i18n.t('Choose type'),
      create: i18n.t('Create'),
      noParent: i18n.t('No parent'),
      noType: i18n.t('No type'),
      edit: i18n.t('Edit'),
      areYouSureYouWantToDeleteThis: i18n.t('Are you sure you want to delete this'),
      delete: i18n.t('Delete'),
      typeLow: i18n.t('Type').toLowerCase()
    };
  },
  AdminCustomFieldsModal: function AdminCustomFieldsModal() {
    return {
      title: i18n.t('Title'),
      module: i18n.t('Module'),
      cancel: i18n.t('Cancel'),
      save: i18n.t('Save'),
      create: i18n.t('Create'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      custom: i18n.t('Custom'),
      dataController: i18n.t('Data controller'),
      dataProcessor: i18n.t('Data processor'),
      system: i18n.t('System'),
      explanatoryText: i18n.t('Explanatory text')
    };
  },
  AdminCustomFieldsWell: function AdminCustomFieldsWell() {
    return {
      customFields: i18n.t('Custom fields'),
      title: i18n.t('Title'),
      fieldType: i18n.t('Field type'),
      module: i18n.t('Module'),
      areYouSureYouWantToDeleteThisCustomField: i18n.t('Are you sure you want to delete this custom field and all it\'s values?'),
      fieldDeleted: i18n.t('Field deleted'),
      fieldWasCreated: i18n.t('Field was created'),
      fieldWasUpdated: i18n.t('Field was updated'),
      filterByModule: i18n.t('Filter by module'),
      noFiltersApplied: i18n.t('No filters applied')
    };
  },
  AdminNonWorkingDaysWell: function AdminNonWorkingDaysWell() {
    return {
      nonWorkingDays: i18n.t('Non-working days'),
      chooseTitle: i18n.t('Choose title'),
      choose: i18n.t('Choose'),
      title: i18n.t('Title'),
      date: i18n.t('Date'),
      create: i18n.t('Create'),
      noKeepDeadlines: i18n.t('No, keep deadlines'),
      theFollowingControlsHaveDeadlineOnANonwork: i18n.t('The following controls have deadline on a non-working day'),
      doYouWantToMoveTheControlsToTheNextAvail: i18n.t('Do you want to move the controls to the next available working day?'),
      yesMoveDeadlines: i18n.t('Yes, move deadlines'),
      enterTitle: i18n.t('Enter title'),
      deleteNonWorkingDay: i18n.t('Delete non-working day'),
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      nonWorkingDayCreated: i18n.t('The non-working day is created'),
      nonWorkingDayDeleted: i18n.t('The non-working day was successfully deleted')
    };
  },
  AdminTagsFieldWell: function AdminTagsFieldWell() {
    return {
      title: i18n.t('Title'),
      deleteTag: i18n.t('Delete') + ' ' + i18n.t('Tag').toLowerCase(),
      areYouSureYouWantToDeleteThisTag: "".concat(i18n.t('Are you sure you want to delete this'), " ").concat(i18n.t('Tag').toLowerCase(), "?"),
      tagHasBeenCreated: i18n.t('Tag has been created'),
      tagHasBeenDeleted: i18n.t('Tag has been deleted'),
      tagHasBeenUpdated: i18n.t('Tag has been updated')
    };
  },
  CreationMatrixAxisModal: function CreationMatrixAxisModal() {
    return {
      title: i18n.t('Title'),
      numberOfScores: i18n.t('Number of scores'),
      select: i18n.t('Select'),
      parametersInTable: i18n.t('Parameters in table'),
      create: i18n.t('Create'),
      createNewRiskMatrixAxis: i18n.t('Create new risk matrix axis'),
      pleaseCompleteThisFormToCreateANewTableba: i18n.t('Please complete this form to create a new table-based axis.')
    };
  },
  CustomRiskFieldsWell: function CustomRiskFieldsWell() {
    return {
      areYouSure: i18n.t('Are you sure?'),
      delete: i18n.t('Delete'),
      customRiskFields: i18n.t('Custom risk fields'),
      theseAreYourOwnCustomRiskFields: i18n.t('These are your own custom risk fields'),
      notGrouped: i18n.t('Not grouped'),
      addCustomField: i18n.t('Add custom field'),
      savePeriodToChangeCustomFieldOrder: i18n.t('Save period to change custom field order'),
      reorderCustomFields: i18n.t('Reorder custom fields')
    };
  },
  RiskMatrixAxisParametersWell: function RiskMatrixAxisParametersWell() {
    return {
      riskMatrixAxisParameters: i18n.t('Risk matrix axis parameters'),
      verticalAxisLikelihood: i18n.t('Vertical axis, Likelihood'),
      select: i18n.t('Select'),
      scoresForLikelihood: i18n.t('Scores for likelihood'),
      likelihood: i18n.t('Likelihood'),
      horizontalAxisImpact: i18n.t('Horizontal axis, Impact'),
      financialImpactUnit: i18n.t('Financial impact unit'),
      denominationAndCurrencyExMioUsd: i18n.t('Denomination and currency (Ex. Mio USD)?'),
      financialImpact: i18n.t('Financial impact'),
      maxForScore: i18n.t('Max for score'),
      impact: i18n.t('Impact'),
      thirdAxisZaxis: i18n.t('Third axis (Z-axis)'),
      riskMatrixPreview: i18n.t('Risk matrix preview')
    };
  },
  SuggestedActivitiesModal: function SuggestedActivitiesModal() {
    return {
      addNew: i18n.t('Add new'),
      article: i18n.t('Article'),
      selectArticle: i18n.t('Select article'),
      chooseActivityType: i18n.t('Choose activity type'),
      choose: i18n.t('Choose'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      save: i18n.t('Save')
    };
  }
};