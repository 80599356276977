import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex flex-col h-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_radar = _resolveComponent("radar");
  var _component_export_frame = _resolveComponent("export-frame");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_export_frame, {
    class: "flex-grow flex justify-center w-full",
    "wrapper-class": "flex justify-center w-full",
    "use-export": $props.useDefaultExport
  }, {
    default: _withCtx(function () {
      return [$props.data.length ? (_openBlock(), _createBlock(_component_radar, {
        key: 0,
        class: "!h-500px",
        data: $options.chartData,
        options: $data.options
      }, null, 8 /* PROPS */, ["data", "options"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["use-export"]), _renderSlot(_ctx.$slots, "legend", {
    class: "mt-2",
    appliedColors: $props.colors
  })]);
}