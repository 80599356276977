import * as Utils from '@/utils/Utils';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
export var CustomFieldsMixin = {
  methods: {
    getCustomFieldsValues: function getCustomFieldsValues(fields, customFieldValues) {
      var result = JSON.parse(JSON.stringify(customFieldValues));
      fields.forEach(function (field) {
        var cf = result.find(function (cf) {
          return cf.fieldId === field.id;
        });
        if (!cf) {
          cf = {
            fieldId: field.id
          };
          result.push(cf);
        }
        if (field.typeId === CustomFieldTypes.SELECT) {
          cf.value = Utils.findSelectedOptionId(field);
        } else {
          cf.value = field.selectedValue;
        }
      });
      return result;
    },
    onCustomFieldsChange: function onCustomFieldsChange(e) {
      var value = this.getCustomFieldsValues(e, this.data.customFieldValues);
      this.$emit('propertyChanged', {
        property: 'customFieldValues',
        value: value
      });
    }
  }
};