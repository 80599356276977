import { Http } from '../index';
import { handleRequestResponse, objectToQueryString } from '../httpUtils';
export function login(username, password, mfaToken) {
  var params = {
    username: username,
    password: password
  };
  if (mfaToken) {
    params['mfaToken'] = mfaToken;
  }
  var data = objectToQueryString(params);
  return handleRequestResponse(Http.post('/login', data), true);
}