function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getTranslate } from './translate';
import Http from '@/api';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import { handleRequestResponse, getFiltersQueryString } from '@/api/httpUtils';
import { getRangesWithNextThreeMonths } from '@/constants/dates/daterangepicker';
import { isDateValid } from '@/utils/date';
export default {
  name: 'RismaTableColumnFilters',
  components: {
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    InputField: InputField,
    RismaDateRangePicker: RismaDateRangePicker
  },
  props: {
    columnIndex: {
      type: [String, Number],
      required: true
    },
    filters: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    filterApi: {
      type: Object,
      required: false,
      default: null
    },
    filterFreeText: {
      type: Object,
      required: false,
      default: null
    },
    datesFilter: {
      type: Object,
      required: false,
      default: null
    }
  },
  emits: ['filtersChange', 'deselectAllFilters'],
  data: function data() {
    return {
      searchString: '',
      filtersFromApi: [],
      isLoading: false,
      loadLimit: 20,
      loadStart: 0,
      lastLoadedItemsNumber: 0,
      debounceTimeout: null,
      ranges: null,
      dateRange: {
        before: '',
        after: ''
      },
      translate: getTranslate['RismaTableColumnMenu']()
    };
  },
  computed: {
    sortedFilters: function sortedFilters() {
      var _this = this;
      return _toConsumableArray(this.availableFilters).sort(function (a, b) {
        var aChecked = _this.checkedValues.includes(a.value);
        var bChecked = _this.checkedValues.includes(b.value);
        return bChecked - aChecked;
      });
    },
    availableFilters: function availableFilters() {
      var _this2 = this;
      if (this.filterApi) {
        return this.filtersFromApi;
      }
      if (!this.searchString) return this.filters;
      return this.filters.filter(function (filterItem) {
        return filterItem.active || filterItem.label.toLowerCase().startsWith(_this2.searchString.toLowerCase());
      });
    },
    showLoadMoreButton: function showLoadMoreButton() {
      return this.lastLoadedItemsNumber === this.loadLimit;
    },
    hideSearchField: function hideSearchField() {
      var _this$filters;
      return !(this.filterApi || (_this$filters = this.filters) !== null && _this$filters !== void 0 && _this$filters.length || this.filterFreeText);
    },
    filterApiUrl: function filterApiUrl() {
      if (!this.filterApi || !this.filterApi.url) return null;
      var _this$filterApi = this.filterApi,
        activeValues = _this$filterApi.activeValues,
        url = _this$filterApi.url,
        urlParams = _this$filterApi.urlParams,
        searchField = _this$filterApi.searchField;
      var excludesQuery = '';
      if (!this.searchString) {
        excludesQuery = getFiltersQueryString('id', activeValues, false);
      }
      var searchParams = new URLSearchParams(excludesQuery || '');
      urlParams && Object.entries(urlParams).forEach(function (entry) {
        var _entry = _slicedToArray(entry, 2),
          key = _entry[0],
          val = _entry[1];
        searchParams.append(key, val);
      });
      searchParams.append('limit', this.loadLimit);
      searchParams.append('start', this.loadStart);
      searchParams.append(searchField, this.searchString);
      return url + '?' + searchParams.toString();
    },
    checkedValues: function checkedValues() {
      var _this$filterApi2;
      if (!this.filters && !this.filterApi) return [];
      if ((_this$filterApi2 = this.filterApi) !== null && _this$filterApi2 !== void 0 && _this$filterApi2.activeValues) {
        return this.filterApi.activeValues;
      }
      return this.filters.filter(function (filterItem) {
        return filterItem.active;
      }).map(function (_ref) {
        var value = _ref.value;
        return value;
      });
    }
  },
  watch: {
    searchString: function searchString() {
      var _this3 = this;
      if (this.filterApi) {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.initFiltersWithApi, 200);
      }
      if (this.filterFreeText) {
        if (this.filterFreeText.searchString === this.searchString) {
          return;
        }
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(function () {
          return _this3.$emit('filtersChange', _this3.searchString);
        }, 500);
      }
    }
  },
  beforeMount: function beforeMount() {
    if (this.datesFilter) {
      var _this$datesFilter = this.datesFilter,
        before = _this$datesFilter.before,
        after = _this$datesFilter.after;
      if (isDateValid(before) && isDateValid(after)) {
        this.dateRange.before = before;
        this.dateRange.after = after;
      }
      this.ranges = getRangesWithNextThreeMonths();
    }
  },
  mounted: function mounted() {
    if (this.filterApi) {
      this.initFiltersWithApi();
    }
    if (this.filterFreeText) {
      this.searchString = this.filterFreeText.searchString;
    }
  },
  methods: {
    initFiltersWithApi: function initFiltersWithApi() {
      var _this4 = this;
      this.isLoading = true;
      var promises = [this.getFiltersFromApi()];
      this.loadStart = 0;
      if (!this.searchString) {
        promises.push(this.getSelectedFiltersFromApi());
      }
      Promise.all(promises).then(function () {
        for (var _len = arguments.length, filters = new Array(_len), _key = 0; _key < _len; _key++) {
          filters[_key] = arguments[_key];
        }
        _this4.filtersFromApi = _toConsumableArray(filters.flat(2));
        _this4.isLoading = false;
      });
    },
    onDatePickerChange: function onDatePickerChange(event) {
      if (!event) return;
      this.dateRange.after = event.startDate;
      this.dateRange.before = event.endDate;
      this.$emit('filtersChange', [this.dateRange.after, this.dateRange.before]);
    },
    onLoadMoreClick: function onLoadMoreClick() {
      var _this5 = this;
      var loadAll = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.loadStart += this.loadLimit;
      this.isLoading = true;
      this.loadLimit = loadAll ? 10000 : 20;
      return this.getFiltersFromApi().then(function (filters) {
        var _this5$filtersFromApi;
        (_this5$filtersFromApi = _this5.filtersFromApi).push.apply(_this5$filtersFromApi, _toConsumableArray(filters));
        _this5.isLoading = false;
      });
    },
    getSelectedFiltersFromApi: function getSelectedFiltersFromApi() {
      var _this6 = this;
      if (!this.filterApi || this.filterApi.activeValues.length === 0) {
        return Promise.resolve([]);
      }
      var url = this.filterApi.url;
      var query = '?' + getFiltersQueryString('id', this.filterApi.activeValues);
      return handleRequestResponse(Http.get(url + query)).then(function (response) {
        if (!(response !== null && response !== void 0 && response.list)) return [];
        return _this6.mapListToFilters(response.list);
      });
    },
    getFiltersFromApi: function getFiltersFromApi() {
      var _this7 = this;
      if (!this.filterApiUrl) {
        return Promise.resolve([]);
      }
      return handleRequestResponse(Http.get(this.filterApiUrl)).then(function (response) {
        if (!(response !== null && response !== void 0 && response.list)) return [];
        _this7.lastLoadedItemsNumber = response.count || 0;
        return _this7.mapListToFilters(response.list);
      });
    },
    onFiltersChange: function onFiltersChange(value, isChecked) {
      var checkedValues = [];
      if (isChecked) {
        checkedValues = [].concat(_toConsumableArray(this.checkedValues), [value]);
      } else {
        checkedValues = this.checkedValues.filter(function (v) {
          return v !== value;
        });
      }
      this.$emit('filtersChange', checkedValues);
    },
    deselectAllFilters: function deselectAllFilters() {
      this.$emit('filtersChange', []);
    },
    mapListToFilters: function mapListToFilters(list) {
      var _this$filterApi3 = this.filterApi,
        labelField = _this$filterApi3.labelField,
        valueField = _this$filterApi3.valueField;
      return list.map(function (item) {
        return {
          label: item[labelField],
          value: item[valueField]
        };
      });
    }
  }
};