import MultiChoice from '@/components/Molecules/Questionnaire/MultiChoice';
export default {
  name: '',
  components: {
    MultiChoice: MultiChoice
  },
  data: function data() {
    return {
      multichoice: {
        options: {
          9720: '1-1,000',
          9721: '1,001-10,000',
          9722: '10,001-100,000',
          9723: '100,001-1,000,000',
          9724: '1,000,001-10,000,000',
          9725: 'Above 10,000,000',
          9726: 'Not possible to indicate'
        },
        answers: {
          9720: '1-1,000',
          9724: '1,000,001-10,000,000'
        }
      }
    };
  }
};