import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import ToolTip from '@/components/Atoms/Containers/ToolTip';
import { clickOutside } from '@/utils/directives/clickOutside';
export default {
  name: 'HelpToolTip',
  introduction: 'if you need a helping hand',
  description: 'this icon will display a tooltip, when the cursor hovers over it',
  token: '<help-tool-tip>Boy! Isn\'t this helpful?</help-tool-tip>',
  components: {
    FeatherIcon: FeatherIcon,
    ToolTip: ToolTip
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    arrowDirection: {
      type: String,
      default: 'left',
      note: 'determine tooltip`s arrow direction',
      validator: function validator(val) {
        return ['left', 'top'].includes(val);
      }
    },
    tooltipClasses: {
      type: String,
      required: false,
      default: '',
      note: 'additional classes for tooltip'
    },
    reactOnClick: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      hidden: true,
      clicked: false
    };
  },
  methods: {
    show: function show() {
      if (this.reactOnClick) return;
      this.hidden = false;
    },
    hide: function hide() {
      if (this.reactOnClick) return;
      this.hidden = true;
    },
    onTooltipClick: function onTooltipClick() {
      if (!this.reactOnClick) return;
      this.hidden = !this.hidden;
    },
    onClickOutside: function onClickOutside() {
      if (!this.reactOnClick) return;
      this.hidden = true;
    }
  }
};