function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getValueOfCustomField, getColumnLabelName } from '@/utils/CustomFields';
import RismaDataSetHelperClass from '@/components/Atoms/RismaDatatable/RismaDataSetHelperClass';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { capitalize } from '@/utils';
import { isHexColor } from '@/utils/regex';
import { Colors } from '@/Colors';
export var RismaDatatableDefaultMixin = {
  data: function data() {
    return {
      datasetSlice: [],
      datasetSliceTotal: [],
      datasetHelper: {},
      pageLength: 10,
      usePagination: true,
      fuseSearchOptions: {
        threshold: 0.2,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [],
        ignoreLocation: true
      },
      hasBackendSorting: false,
      customUnitPrefix: 'Unit',
      fieldTypeIdsAvoidCustomFilter: [CustomFieldTypes.TEXT_LINE, CustomFieldTypes.TEXT_FIELD]
    };
  },
  computed: {
    trafficLightRismaDatatableFields: function trafficLightRismaDatatableFields() {
      var _this = this;
      if (!this.dataset || !this.dataset.length) return [];
      var list = ['trafficLight', 'severity', 'completedEfficiency'];
      Object.keys(this.dataset[0]).forEach(function (item) {
        var _this$dataset$0$item;
        if (((_this$dataset$0$item = _this.dataset[0][item]) === null || _this$dataset$0$item === void 0 ? void 0 : _this$dataset$0$item.fieldType) === CustomFieldTypes.TRAFFIC_LIGHT) {
          list.push(item);
        }
      });
      return list;
    }
  },
  methods: {
    prepareFilterForTrafficLight: function prepareFilterForTrafficLight(value, columnKey) {
      var trafficLightKeys = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var trafficLightFields = trafficLightKeys !== null && trafficLightKeys !== void 0 ? trafficLightKeys : this.trafficLightRismaDatatableFields;
      var result = value;
      if (trafficLightFields.includes(columnKey) && value) {
        if (isHexColor(value)) {
          result = this.$trans(capitalize(Colors.getTrafficLabelByColor(value)));
        }
        if (!isNaN(+value)) {
          var _Colors$getTrafficlig;
          result = this.$trans(capitalize((_Colors$getTrafficlig = Colors.getTrafficlightByNumber(value)) === null || _Colors$getTrafficlig === void 0 ? void 0 : _Colors$getTrafficlig.label));
        }
      }
      return result;
    },
    enhanceDataset: function enhanceDataset(dataset, columns) {
      this.extendDatasetWithLabels(dataset, columns);
      this.fuseSearchOptions.keys = this.prepareFuseKeys(columns);
      if (!this.usePagination) {
        this.pageLength = this.dataset.length;
      }
      this.datasetHelper = new RismaDataSetHelperClass(dataset, this.fuseSearchOptions).pagesize(this.pageLength);
      this.datasetSlice = this.datasetHelper.data;
      this.datasetSliceTotal = _toConsumableArray(dataset);
    },
    onPageChange: function onPageChange(indexes) {
      this.datasetSlice = this.datasetHelper.page(indexes.currentPage).data;
      if (typeof this.afterOnStateChanged === 'function') {
        this.afterOnStateChanged();
      }
    },
    onStateChanged: function onStateChanged(data) {
      var value = '';
      if (!data.state) {
        return;
      }
      if (data.state.search && data.state.search.search) {
        value = data.state.search.search.trim();
      }

      // This more actual for Reports (when prepareDataTable waits before ajax complete)
      if (!this.datasetHelper.pagesize) {
        return;
      }
      this.datasetSlice = this.datasetHelper.pagesize(data.state.pageLength).page(1).columns(data.state.columns).search(value).resetSortBy() // Support only one sort per time ?
      .sortBy(data.state.columns, this.hasBackendSorting).data;
      this.pageLength = data.state.pageLength;
      this.datasetSliceTotal = this.datasetHelper.dataTotal;
      if (typeof this.afterOnStateChanged === 'function') {
        this.afterOnStateChanged();
      }
    },
    backendSort: function backendSort(state) {
      this.firstInit = false;
      this.$emit('backendSort', state);
    },
    prepareFuseKeys: function prepareFuseKeys(columns) {
      var _ref;
      var result = Object.keys(columns);
      result = result.map(function (item) {
        return ["".concat(item, ".field"), "".concat(item, ".fieldText")];
      });
      return (_ref = []).concat.apply(_ref, _toConsumableArray(result));
    },
    extendDatasetWithLabels: function extendDatasetWithLabels(data, columns) {
      return data.map(function (item) {
        for (var key in item) {
          if (!item[key]) continue;
          item[key].label = columns[key];
        }
      });
    },
    getPropertiesWithCustomFields: function getPropertiesWithCustomFields(entities, activityTypes) {
      var _this2 = this;
      var activityTypesAdded = [];
      var extendedColumns = {};
      var columnFilterOptions = {};
      var defaultCustomFields = {};
      var customFieldsValuesByEntity = {};
      entities.forEach(function (entity) {
        var activityTypeId = entity.activityTypeId,
          activityType = entity.activityType,
          customFieldValues = entity.customFieldValues,
          id = entity.id;
        var activityId = activityTypeId !== null && activityTypeId !== void 0 ? activityTypeId : activityType;
        var isActivityTypeAlreadyAdded = activityTypesAdded.includes(activityId);
        var activity = activityTypes.find(function (activityItem) {
          return activityItem.id === activityId;
        });
        if (activity) {
          if (!isActivityTypeAlreadyAdded) {
            var _this2$getExtendedCol = _this2.getExtendedColumns(activity),
              columnsWithCustomFields = _this2$getExtendedCol.columnsWithCustomFields,
              columnsWithDisabledFilter = _this2$getExtendedCol.columnsWithDisabledFilter;
            extendedColumns = _objectSpread(_objectSpread({}, extendedColumns), columnsWithCustomFields);
            columnFilterOptions = _objectSpread(_objectSpread({}, columnFilterOptions), columnsWithDisabledFilter);
            activityTypesAdded.push(activityTypeId);
          }
          var _this2$prepareCustomF = _this2.prepareCustomFields(activity, customFieldValues, id),
            customFieldValuesByEntityId = _this2$prepareCustomF.customFieldValuesByEntityId,
            customFieldsDefault = _this2$prepareCustomF.customFieldsDefault;
          defaultCustomFields = _objectSpread(_objectSpread({}, defaultCustomFields), customFieldsDefault);
          customFieldsValuesByEntity = _objectSpread(_objectSpread({}, customFieldsValuesByEntity), customFieldValuesByEntityId);
        }
      });
      return {
        extendedColumns: extendedColumns,
        columnFilterOptions: columnFilterOptions,
        defaultCustomFields: defaultCustomFields,
        customFieldsValuesByEntity: customFieldsValuesByEntity
      };
    },
    getExtendedColumns: function getExtendedColumns(activity) {
      var _this3 = this;
      var fieldsIdsAdded = [];
      var columnsWithCustomFields = {};
      var columnsWithDisabledFilter = {};
      if (activity && !activity.deleted) {
        activity.fields && activity.fields.forEach(function (field) {
          var isFieldAlreadyAdded = fieldsIdsAdded.includes(field.id);
          if (!isFieldAlreadyAdded) {
            var label = getColumnLabelName(field.id);
            columnsWithCustomFields[label] = field.label;
            if (field.typeId === CustomFieldTypes.NUMERICAL) {
              var unityLabel = getColumnLabelName(field.id + _this3.customUnitPrefix);
              columnsWithCustomFields[unityLabel] = field.label + ' ' + _this3.customUnitPrefix;
            }
            columnsWithDisabledFilter[label] = _this3.fieldTypeIdsAvoidCustomFilter.includes(field.typeId);
            fieldsIdsAdded.push(field.id);
          }
        });
      }
      return {
        columnsWithCustomFields: columnsWithCustomFields,
        columnsWithDisabledFilter: columnsWithDisabledFilter
      };
    },
    enhanceColumnFilters: function enhanceColumnFilters(filterOptions, disabledFilters, decoupledFilterFields) {
      for (var key in filterOptions) {
        if (filterOptions[key]) {
          disabledFilters.push(key);
        } else {
          decoupledFilterFields.push({
            key: key
          });
        }
      }
    },
    prepareCustomFields: function prepareCustomFields(activity, customFieldValues, id) {
      var _this4 = this;
      var customFieldsDefault = {};
      var customFieldValuesByEntityId = {};
      activity.fields && activity.fields.forEach(function (field) {
        var customFieldLabel = getColumnLabelName(field.id);
        if (!customFieldValuesByEntityId[id]) {
          customFieldValuesByEntityId[id] = _defineProperty({}, customFieldLabel, {});
        }
        customFieldsDefault[customFieldLabel] = {
          field: '',
          fieldType: field.typeId
        };
        _this4.prepareCustomFieldValue(customFieldValuesByEntityId[id], customFieldValues, field);
        if (field.typeId === CustomFieldTypes.NUMERICAL) {
          var unitLabel = getColumnLabelName(field.id + _this4.customUnitPrefix);
          customFieldsDefault[unitLabel] = {
            field: ''
          };
        }
      });
      return {
        customFieldsDefault: customFieldsDefault,
        customFieldValuesByEntityId: customFieldValuesByEntityId
      };
    },
    prepareCustomFieldValue: function prepareCustomFieldValue(valuesToChange, customFieldValues, field) {
      var fieldLabel = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var hasRowGroupActivated = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var customFieldLabel = fieldLabel || getColumnLabelName(field.id);
      var customFieldValue = customFieldValues && customFieldValues.find(function (cfv) {
        return cfv.fieldId === field.id;
      });
      var valueOfCustomField = customFieldValue && (customFieldValue.value || customFieldValue.rows) ? getValueOfCustomField(field, customFieldValue, hasRowGroupActivated) : {
        fieldType: field.fieldtype,
        field: ''
      };
      if (field.typeId === CustomFieldTypes.NUMERICAL) {
        var unitLabel = fieldLabel ? fieldLabel + this.customUnitPrefix.toLowerCase() : getColumnLabelName(field.id + this.customUnitPrefix);
        var _valueOfCustomField$f = valueOfCustomField.field.split(' '),
          _valueOfCustomField$f2 = _slicedToArray(_valueOfCustomField$f, 2),
          rawFieldValue = _valueOfCustomField$f2[0],
          rawUnitValue = _valueOfCustomField$f2[1];
        valuesToChange[customFieldLabel] = {
          field: rawFieldValue
        };
        valuesToChange[unitLabel] = {
          field: rawUnitValue || ''
        };
      } else {
        valuesToChange[customFieldLabel] = valueOfCustomField;
      }
      return valueOfCustomField;
    },
    prepareListField: function prepareListField(attributes) {
      var result = '';
      attributes.forEach(function (attr) {
        result += "<p class='mb-2'>".concat(attr, "</p>");
      });
      return {
        field: result
      };
    }
  }
};