function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import * as ReportsApi from '@/api/reports/reports';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Alert from '@/components/Molecules/Modal/Alert';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { isBelowSuperUser } from '@/utils/access';
import { typeToAccessProperty } from '@/utils/report';
import { ReportTypes as reportTypes } from '@/constants/reports';
export default {
  name: 'SavedReports',
  introduction: 'Saved reports (content inside a modal)',
  description: 'This is a Molecule component for Reports',
  token: '<saved-reports />',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    Alert: Alert
  },
  props: {
    type: {
      required: true,
      type: String,
      note: 'Type from report-schema.json (controls-report or controls-data-report)'
    }
  },
  emits: ['load', 'reportShared', 'reportDeleted'],
  data: function data() {
    return {
      currentDeleteItem: null,
      showAlert: false,
      defaultReports: [],
      allReports: [],
      translate: getTranslate['SavedReports']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser', 'isAdmin'])), {}, {
    defaultReportsTitle: function defaultReportsTitle() {
      return this.translate.defaultReports;
    },
    isAnyReportAvailable: function isAnyReportAvailable() {
      return this.reportGroups.some(function (group) {
        return group.reports.length;
      }) || this.defaultReports.length;
    },
    reportGroups: function reportGroups() {
      return [{
        title: this.translate.sharedReports,
        reports: this.sharedReports,
        access: !isBelowSuperUser(this.currentUser, typeToAccessProperty(this.type))
      }, {
        title: this.translate.myReports,
        reports: this.myReports,
        shareOption: true,
        access: true
      }];
    },
    sharedReports: function sharedReports() {
      return this.allReports.filter(function (report) {
        return report.shared;
      });
    },
    myReports: function myReports() {
      return this.allReports.filter(function (report) {
        return !report.shared;
      });
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      if (this.type === reportTypes.CONTROLS) {
        this.prepareDefaultReports();
      }
      return this.prepareReports(this.type);
    },
    prepareReports: function prepareReports(type) {
      var _this = this;
      var params = "?types[]=".concat(type);
      return ReportsApi.getReports(params).then(function (_ref) {
        var list = _ref.list;
        _this.allReports = _toConsumableArray(list);
      });
    },
    prepareDefaultReports: function prepareDefaultReports() {
      var statuses = "^".concat(this.translate.completed, "$|^").concat(this.translate.controlRejected, "$|^").concat(this.translate.pendingReview, "$");
      this.defaultReports = [{
        title: this.translate.completedControls,
        comment: this.translate.thisReportOnlyShowsTheCompletedControls,
        query: {
          filters: {
            status: statuses
          }
        }
      }, {
        title: this.translate.completedControlsPastTheDeadline,
        comment: this.translate.thisReportOnlyShowsControlsThatWasCompleted,
        query: {
          filters: {
            status: statuses,
            trafficLight: '1'
          }
        }
      }, {
        title: this.translate.pendingControlsPastTheDeadline,
        comment: this.translate.thisReportOnlyShowsTheControlsThatArePast,
        query: {
          filters: {
            status: "^".concat(this.translate.pending, "$"),
            trafficLight: '1'
          }
        }
      }];
    },
    goToReport: function goToReport(data) {
      this.$emit('load', data);
    },
    deleteReport: function deleteReport() {
      var _this2 = this;
      if (this.currentDeleteItem) {
        return ReportsApi.deleteReport(this.currentDeleteItem.id).then(function () {
          _this2.currentDeleteItem = null;
          _this2.getData();
          _this2.toggleDeleteAlert();
          _this2.$emit('reportDeleted');
        });
      }
    },
    editReport: function editReport(report) {
      var _this3 = this;
      return ReportsApi.editReport(report.id, {
        shared: true
      }).then(function (updatedReport) {
        var updatedReportIdx = _this3.allReports.findIndex(function (rep) {
          return rep.id === report.id;
        });
        if (updatedReportIdx !== -1) {
          _this3.allReports[updatedReportIdx] = updatedReport;
        }
        _this3.$emit('reportShared');
      });
    },
    tryToDelete: function tryToDelete(item) {
      this.currentDeleteItem = item;
      this.toggleDeleteAlert();
    },
    toggleDeleteAlert: function toggleDeleteAlert() {
      this.showAlert = !this.showAlert;
    }
  }
};