import i18n from '@/i18n';
export var getTranslate = {
  AdSettings: function AdSettings() {
    return {
      settingsAreSaved: i18n.t('Settings are saved'),
      activateAdLogin: i18n.t('Activate AD login'),
      useSecurityGroups: i18n.t('Use security groups'),
      domainName: i18n.t('Domain name'),
      enterADomainName: i18n.t('Enter a domain name'),
      endpoint: i18n.t('Endpoint'),
      enterAnEndpoint: i18n.t('Enter an endpoint'),
      clientId: i18n.t('Client id'),
      enterAClientId: i18n.t('Enter a client id'),
      clientSecret: i18n.t('Client secret'),
      enterAClientSecret: i18n.t('Enter a client secret'),
      advancedEmailMapping: i18n.t('Advanced: e-mail mapping'),
      enterAnEmailMappingSetToUpnForDefault: i18n.t('Enter an e-mail mapping. Set to upn for default'),
      update: i18n.t('Update'),
      clientUrlForAzureAdShouldBeFormat: i18n.t('Client url for Azure AD should be format https://sts.windows.net/UUID/'),
      theEnteredSecretHasTheFormatOfAnObjectId: i18n.t('The entered secret has the format of an Object ID. Please re-check the value')
    };
  },
  ProjectQuestionnaireGapSelector: function ProjectQuestionnaireGapSelector() {
    return {
      chooseQuestionnaireType: i18n.t('Choose questionnaire type'),
      chooseQuestionnaire: i18n.t('Choose questionnaire'),
      chooseGapSchema: i18n.t('Choose gap schema'),
      mode: i18n.t('Mode'),
      text: i18n.t('Text'),
      form: i18n.t('Form')
    };
  }
};