function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { createFlow as _createFlow, deleteFlow as _deleteFlow, getAllFlows as _getAllFlows, getAllOptions as _getAllOptions } from '@/api/compliance/dataflow';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import SaveDataflowAsImage from '@/components/Molecules/SaveDataflowAsImage';
import DataFlowRow from '@/components/Organisms/Compliance/DataFlowRow';
import DataflowModal from '@/components/Organisms/DataflowModal';
import { compareStrings } from '@/utils/compare';
import { removeDuplicates } from '@/utils/filterArray';
export default {
  name: 'DataFlow',
  introduction: '',
  description: '',
  token: '<data-flow />',
  components: {
    FeatherIcon: FeatherIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    DataflowModal: DataflowModal,
    DataFlowRow: DataFlowRow,
    SaveDataflowAsImage: SaveDataflowAsImage
  },
  props: {
    data: {
      required: true,
      type: Object,
      note: 'Current node object'
    }
  },
  data: function data() {
    return {
      flows: [],
      options: null,
      loaded: false,
      showModal: false,
      translate: getTranslate['DataFlow']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, {
    currentUserComplianceProjectById: 'complianceProjectById'
  })), {}, {
    irrelevantDataTypes: function irrelevantDataTypes() {
      return ['Not applicable', 'Ikke relevant', 'Ikke gjeldende', this.translate.notApplicable];
    },
    dataFlowSystemsTitle: function dataFlowSystemsTitle() {
      return this.getSettingValue('feature.data_flow_systems_name') || this.translate.systems;
    },
    dataFlowStakeholdersTitle: function dataFlowStakeholdersTitle() {
      return this.getSettingValue('feature.data_flow_stakeholders_name') || this.translate.stakeholders;
    },
    projectId: function projectId() {
      return this.data.compliance_projects_id;
    },
    currentProject: function currentProject() {
      return this.currentUserComplianceProjectById(this.projectId);
    },
    nodeId: function nodeId() {
      return this.data.id;
    },
    missingStakeholders: function missingStakeholders() {
      var stakeholdersInUse = removeDuplicates(this.flows.map(function (_ref) {
        var stakeholder = _ref.stakeholder;
        return stakeholder;
      }), 'uniqueId').map(function (stakeholder) {
        return stakeholder.uniqueId;
      });
      return this.options.stakeholders.filter(function (stakeholder) {
        return !stakeholdersInUse.includes(stakeholder.uniqueId);
      });
    },
    missingDataTypes: function missingDataTypes() {
      var dataTypesInUse = removeDuplicates(this.flows.flatMap(function (_ref2) {
        var dataTypes = _ref2.dataTypes;
        return dataTypes;
      }), 'uniqueId').map(function (dataType) {
        return dataType.uniqueId;
      });
      return this.options.dataTypes.filter(function (dataType) {
        return !dataTypesInUse.includes(dataType.uniqueId);
      });
    },
    missingSystems: function missingSystems() {
      var systemsInUse = removeDuplicates(this.flows.flatMap(function (_ref3) {
        var systems = _ref3.systems;
        return systems;
      }), 'uniqueId').map(function (system) {
        return system.uniqueId;
      });
      return this.options.systems.filter(function (system) {
        return !systemsInUse.includes(system.uniqueId);
      });
    },
    flowsGroupedByStakeholder: function flowsGroupedByStakeholder() {
      var _this = this;
      var result = [];
      var sortedFlows = _toConsumableArray(this.flows).sort(function (a, b) {
        return compareStrings(a.stakeholder.text, b.stakeholder.text);
      });
      sortedFlows.forEach(function (flow) {
        var findFlowPredicate = function findFlowPredicate(curFlow) {
          return flow.stakeholder.uniqueId === curFlow.stakeholder.uniqueId && flow.direction === curFlow.direction;
        };
        var addedFlow = result.find(findFlowPredicate);
        if (addedFlow) {
          addedFlow.subFlows.push(flow);
        } else {
          result.push(_this.formatFlow(flow));
        }
      });
      return result;
    }
  }),
  mounted: function mounted() {
    var _this2 = this;
    Promise.all([this.getAllFlows(), this.getAllOptions()]).then(function () {
      return _this2.loaded = true;
    });
  },
  methods: {
    getAllFlows: function getAllFlows() {
      var _this3 = this;
      return _getAllFlows(this.projectId, this.nodeId).then(function (response) {
        _this3.flows = response.list;
      });
    },
    getAllOptions: function getAllOptions() {
      var _this4 = this;
      return _getAllOptions(this.projectId, this.nodeId).then(function (response) {
        _this4.options = _objectSpread(_objectSpread({}, response), {}, {
          stakeholders: removeDuplicates(response.stakeholders || [], 'uniqueId'),
          systems: removeDuplicates(response.systems || [], 'uniqueId'),
          dataTypes: response.dataTypes.filter(function (o) {
            return !_this4.irrelevantDataTypes.includes(o.text);
          })
        });
      });
    },
    createFlow: function createFlow(newFlow) {
      var _this5 = this;
      return _createFlow(this.projectId, this.nodeId, newFlow).then(function (response) {
        _this5.flows.push(response.data);
        if (_this5.showModal) {
          _this5.toggleModal();
        }
      });
    },
    deleteFlow: function deleteFlow(index) {
      var _this6 = this;
      var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var idsToDelete = id ? [id] : this.flowsGroupedByStakeholder[index].subFlows.map(function (subFlow) {
        return subFlow.id;
      });
      idsToDelete.forEach(function (flowId) {
        return _deleteFlow(_this6.projectId, _this6.nodeId, flowId);
      });
      this.flows = this.flows.filter(function (flow) {
        return !idsToDelete.includes(flow.id);
      });
    },
    addDataFlowToStakeholder: function addDataFlowToStakeholder(flowId) {
      var flow = this.flows.find(function (flow) {
        return +flow.id === +flowId;
      });
      var newFlow = _objectSpread(_objectSpread({}, flow), {}, {
        dataTypes: [],
        systems: [],
        systemsTitle: (flow === null || flow === void 0 ? void 0 : flow.systemsTitle) || this.dataFlowSystemsTitle,
        comments: ''
      });
      this.createFlow(newFlow);
    },
    formatFlow: function formatFlow(flow) {
      return {
        id: flow.id,
        systemsTitle: flow.systemsTitle,
        stakeholder: flow.stakeholder,
        direction: flow.direction,
        subFlows: [flow]
      };
    },
    toggleModal: function toggleModal() {
      this.showModal = !this.showModal;
    }
  }
};