import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_static_table, {
    dataset: _ctx.testData,
    columns: _ctx.testColumns2,
    onTrigger: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('table', $event);
    })
  }, null, 8 /* PROPS */, ["dataset", "columns"])]);
}