import { getTranslate } from './translate';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import SecurityGroupProjects from '@/components/Molecules/Compliance/SecurityGroupProjects/SecurityGroupProjects';
import AdSettings from '@/components/Organisms/Compliance/Admin/AdSettings';
import SecurityGroupNew from '@/components/Organisms/Compliance/Admin/SecurityGroups/SecurityGroupNew';
import SecurityGroupsModal from '@/components/Organisms/Compliance/Admin/SecurityGroups/SecurityGroupsModal';
import { columns, adminLevels, accessLevels, accessLevelsBase } from '@/constants/compliance/SecurityGroups';
import { getSecurityGroups as getSecurityGroupsAPI, updateSecurityGroup as updateSecurityGroupAPI } from '@/api/compliance/admin';
import { typesAccessProjects } from '@/constants/compliance/TypesAccessProjects';
export default {
  name: 'SecurityGroups',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    SecurityGroupNew: SecurityGroupNew,
    SecurityGroupsModal: SecurityGroupsModal,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    SecurityGroupProjects: SecurityGroupProjects,
    AdSettings: AdSettings
  },
  data: function data() {
    return {
      columns: columns(),
      adminLevels: adminLevels(),
      accessLevels: accessLevels,
      accessLevelsBase: accessLevelsBase,
      isLoading: true,
      groups: [],
      isModalShow: false,
      modalData: {},
      translate: getTranslate['SecurityGroups']()
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.handleGetSecurityGroups().then(function (groups) {
      _this.groups = _this.formatSecurityGroups(groups);
      _this.typesAccessProjects = typesAccessProjects;
      _this.isLoading = false;
    });
  },
  methods: {
    getNumber: function getNumber(value) {
      return value ? value : 0;
    },
    formatSecurityGroup: function formatSecurityGroup(group) {
      var groupFormatted = Object.assign({}, {
        id: group.id,
        name: group.name,
        objectId: group.objectId,
        levelAdmin: this.getNumber(group.levelAdmin),
        levelControls: this.getNumber(group.levelControls),
        levelExecution: this.getNumber(group.levelExecution),
        levelIncidents: this.getNumber(group.levelIncidents),
        levelRisk: this.getNumber(group.levelRisk),
        levelContracts: this.getNumber(group.levelContracts),
        levelInformationAssets: this.getNumber(group.levelInformationAssets),
        lockedFields: group.lockedFields ? true : false,
        complianceProjects: [],
        riskProjects: [],
        updated: false,
        isProjectsShown: false
      });
      group.projects.forEach(function (project) {
        if (project.complianceProjectsId) {
          groupFormatted.complianceProjects.push(Object.assign({}, project));
        } else if (project.riskProjectsId) {
          groupFormatted.riskProjects.push(Object.assign({}, project));
        }
      });
      return groupFormatted;
    },
    formatSecurityGroups: function formatSecurityGroups(groups) {
      var _this2 = this;
      return groups.map(function (group) {
        return _this2.formatSecurityGroup(group);
      });
    },
    handleGetSecurityGroups: function handleGetSecurityGroups() {
      return new Promise(function (resolve, reject) {
        getSecurityGroupsAPI().then(function (res) {
          return resolve(res.groups);
        }).catch(function (err) {
          return reject(err);
        });
      });
    },
    handleCreateNewGroup: function handleCreateNewGroup() {
      var _this3 = this;
      this.isLoading = true;
      return this.handleGetSecurityGroups().then(function (groups) {
        _this3.groups = _this3.formatSecurityGroups(groups);
        _this3.isLoading = false;
      });
    },
    handleSubmitModal: function handleSubmitModal(groupId, projectsType, data) {
      var _this4 = this;
      this.isModalShow = false;
      this.isLoading = true;
      var group = this.groups.find(function (group) {
        return group.id === groupId;
      });
      var groupUpdated = this.unformateGroup(group);
      if (!data.id) {
        if (projectsType === typesAccessProjects.compliance) {
          groupUpdated.complianceProjects.push(data);
        } else if (projectsType === typesAccessProjects.risk) {
          groupUpdated.riskProjects.push(data);
        }
      } else {
        if (projectsType === typesAccessProjects.compliance) {
          groupUpdated.complianceProjects.map(function (project) {
            if (data.id === project.id) {
              project.accessLevel = data.accessLevel;
              project.projectsId = data.projectId;
            }
            return project;
          });
        } else if (projectsType === typesAccessProjects.risk) {
          groupUpdated.riskProjects.map(function (project) {
            if (data.id === project.id) {
              project.accessLevel = data.accessLevel;
              project.projectsId = data.projectId;
            }
            return project;
          });
        }
      }
      this.handleUpdate(groupUpdated).then(function () {
        _this4.modalData = {};
        return _this4.handleGetSecurityGroups();
      }).then(function (groups) {
        _this4.groups = _this4.formatSecurityGroups(groups);
        _this4.isLoading = false;
      });
    },
    handleUpdate: function handleUpdate(group) {
      return new Promise(function (resolve, reject) {
        updateSecurityGroupAPI(group.id, group).then(function (res) {
          return resolve(res);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    getProjectShowTitle: function getProjectShowTitle(isShow) {
      return isShow ? this.translate.hideProjects : this.translate.showProjects;
    },
    getUpdatedGroups: function getUpdatedGroups() {
      var updatedGroups = [];
      this.groups.forEach(function (group) {
        if (group.updated) {
          var updatedGroup = Object.assign({}, group, {
            lockedFields: group.lockedFields ? 1 : 0
          });
          delete updatedGroup.updated;
          delete updatedGroup.isProjectsShown;
          updatedGroups.push(updatedGroup);
        }
      });
      return updatedGroups;
    },
    update: function update() {
      var _this5 = this;
      var updatedGroups = this.getUpdatedGroups();
      var promises = updatedGroups.map(function (group) {
        return _this5.handleUpdate(group);
      });
      this.isLoading = true;
      this.newGroupIsError = false;
      return Promise.all(promises).then(function () {
        _this5.groups.map(function (group) {
          return group.updated = false;
        });
        _this5.isLoading = false;
      }).catch(function () {
        _this5.isLoading = false;
      });
    },
    updateField: function updateField(groupId, type, newValue) {
      var group = this.groups.find(function (g) {
        return g.id === groupId;
      });
      group[type] = newValue;
      group.updated = true;
    },
    closeModal: function closeModal() {
      this.isModalShow = false;
    },
    openModal: function openModal(_ref) {
      var projectId = _ref.projectId,
        securitygroupId = _ref.securitygroupId,
        projectAccessId = _ref.projectAccessId,
        accessLevel = _ref.accessLevel,
        type = _ref.type;
      this.modalData = {
        type: type,
        projectId: projectId,
        accessLevel: accessLevel,
        securitygroupId: securitygroupId,
        projectAccessId: projectAccessId
      };
      this.isModalShow = true;
    },
    unformateGroup: function unformateGroup(group) {
      var groupUnformatted = {};
      Object.assign(groupUnformatted, group, {
        lockedFields: group.lockedFields ? 1 : 0,
        complianceProjects: group.complianceProjects.map(function (project) {
          return Object.assign({}, project);
        }),
        riskProjects: group.riskProjects.map(function (project) {
          return Object.assign({}, project);
        })
      });
      delete groupUnformatted.updated;
      delete groupUnformatted.isProjectsShown;
      return groupUnformatted;
    },
    handleOpenModal: function handleOpenModal(data) {
      this.openModal({
        projectId: data.projectId,
        securitygroupId: data.securityGroupId,
        accessLevel: data.accessLevel,
        projectAccessId: data.projectAccessId,
        type: data.typeAccessProjects
      });
    }
  }
};