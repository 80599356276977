function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getAllGapSchemasByGapSchemaType } from '@/api/compliance/gapschema';
import { create, getAllLightWeightProcessors } from '@/api/compliance/informationAssets';
import Notification from '@/components/Molecules/Notification';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import DelegationWell from '@/components/Organisms/General/coreWells/DelegationWell';
import InformationAssetsDetailsRelations from '@/components/Organisms/InformationAssets/InformationAssetsDetailsRelations';
import { InformationAssetsEntryMixin } from '../InformationAssetsEntryMixin';
import { TabTypes, Tabs } from '@/constants/Tabs';
import { MODULES } from '@/constants/modules';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { createTabsList } from '@/utils/base/tabs';
import { saveStatus } from '@/constants/SaveStatuses';
import { getLinksLabel } from '@/utils/links';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export default {
  name: 'IAProc',
  components: {
    DelegationWell: DelegationWell,
    DepWell: DepWell,
    InformationAssetsDetailsRelations: InformationAssetsDetailsRelations,
    Notification: Notification
  },
  mixins: [InformationAssetsEntryMixin],
  emits: ['changeTab'],
  data: function data() {
    return {
      informationAssetType: AssetsTypes.PROCESSORS,
      module: MODULES.COMPLIANCE_IA_PROCESSOR,
      riskAssessmentSchemas: [],
      processors: null,
      companyStatusNotification: '',
      titleField: 'name',
      translate: {
        beAwareThatThisCompanyHasStatus: this.$trans('Be aware that this company has status')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isRiskAnalysisEnabled: function isRiskAnalysisEnabled() {
      return !!this.getSettingValue('feature.ia_processor_risk_analysis_enabled');
    },
    tabs: function tabs() {
      var arr = [TabTypes.DESCRIPTION];
      if (this.isQuestionnaireAvailable) {
        this.activeQuestionnaires.forEach(function (questionnaire) {
          arr.push(_objectSpread(_objectSpread({}, Tabs[TabTypes.QUESTIONNAIRE]), {}, {
            url: questionnaire.url || "".concat(Tabs[TabTypes.QUESTIONNAIRE].url, "/").concat(questionnaire.questionnaireId),
            slicedUrl: "".concat(Tabs[TabTypes.QUESTIONNAIRE].url, "/").concat(questionnaire.questionnaireId),
            label: questionnaire.tabLabel || Tabs[TabTypes.QUESTIONNAIRE].label
          }));
        });
      }
      if (this.isGapAvailable) {
        arr.push(TabTypes.GAPANALYSIS);
      }
      if (this.isDataProcessorManagementEnabled) {
        arr.push(TabTypes.DPM);
      }
      arr.push(TabTypes.LINKS, TabTypes.CHANGELOG);
      return createTabsList(arr, this.baseLink, getLinksLabel(this.informationAsset));
    },
    preparedInformationAssetsItem: function preparedInformationAssetsItem() {
      return _objectSpread(_objectSpread({}, this.informationAsset), {}, {
        auditQuestionnaires: this.auditQuestionnaires
      });
    },
    isDataProcessorManagementEnabled: function isDataProcessorManagementEnabled() {
      return !!this.getSettingValue('feature.data_processor_management') && this.auditQuestionnaires && this.auditQuestionnaires.length;
    },
    uniqueIdReadOnly: function uniqueIdReadOnly() {
      return !!this.getSettingValue('feature.processor_use_db_id_as_unique_id');
    },
    uniqueIdField: function uniqueIdField() {
      return this.uniqueIdReadOnly ? 'id' : 'uniqueId';
    }
  }),
  created: function created() {
    this.getAllSchemas();
    this.getNeededInformationAssets();
  },
  methods: {
    getAllSchemas: function getAllSchemas() {
      var _this = this;
      var mapFunction = function mapFunction(_ref) {
        var id = _ref.id,
          title = _ref.title;
        return {
          label: title,
          value: id
        };
      };
      var onFulFilled = function onFulFilled(_ref2) {
        var list = _ref2.list;
        return _this.riskAssessmentSchemas = list.map(mapFunction);
      };
      return getAllGapSchemasByGapSchemaType(ProjectTypes.DATAPROCESSOR).then(onFulFilled);
    },
    getNeededInformationAssets: function getNeededInformationAssets() {
      var _this2 = this;
      return getAllLightWeightProcessors().then(function (response) {
        _this2.processors = response.list;
      });
    },
    addProcessor: function addProcessor(tagname) {
      var _this3 = this;
      create(this.informationAssetType, {
        name: tagname,
        parent_id: this.informationAsset.id
      }).then(function (response) {
        var value = [].concat(_toConsumableArray(_this3.informationAsset.children), [response.data.id]);
        var property = 'children';
        _this3.informationAssetPropertyChanged({
          property: property,
          value: value
        });
        _this3.processors.push(response.data);
      }).catch(function (error) {
        if (error.response.error) {
          _this3.errorMessage = _this3.$trans(error.response.error);
        }
      });
    },
    onChangeTab: function onChangeTab(event) {
      if (this.saveStatus === saveStatus.SAVED) {
        this.getInformationAsset();
      }
      return this.$emit('changeTab', event);
    }
  }
};