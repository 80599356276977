import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-095a5e6c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "inline-block align-bottom"
};
var _hoisted_2 = ["href", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("li", _hoisted_1, [!$options.isPreview ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    custom: "",
    to: $props.link
  }, {
    default: _withCtx(function (_ref) {
      var navigate = _ref.navigate,
        href = _ref.href,
        route = _ref.route,
        isActive = _ref.isActive;
      return [_createElementVNode("a", {
        href: href,
        class: _normalizeClass(['inline-block rounded-full py-2 px-3 text-sm', {
          'text-gray-250 cursor-default pointer-events-none': $props.disabled
        }, {
          'router-link-active': $options.isSameRoute(route) || isActive
        }]),
        onClick: navigate
      }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 10 /* CLASS, PROPS */, _hoisted_2)];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $options.isPreview ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    tabindex: "0",
    class: _normalizeClass(['cursor-pointer inline-block rounded-full py-2 px-3 text-sm', {
      'text-gray-250 cursor-default pointer-events-none': $props.disabled
    }, {
      active: $props.selected
    }]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
      return _ctx.$emit('changeTab', $props.link);
    }, ["prevent"])),
    onKeyup: _cache[1] || (_cache[1] = _withKeys(function ($event) {
      return _ctx.$emit('changeTab', $props.link);
    }, ["enter"]))
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 34 /* CLASS, HYDRATE_EVENTS */)) : _createCommentVNode("v-if", true)]);
}