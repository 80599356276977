export default {
  name: 'InputFieldFile',
  introduction: 'inputs for choosing file / files',
  description: 'This is a file input field that emits an "updated"-event when the file choosen',
  token: '<input-field-file @changed=""/>',
  props: {
    multiple: {
      type: Boolean,
      requred: false,
      default: false,
      note: 'Is there is possibility to choose few files by one action'
    }
  },
  emits: ['input'],
  methods: {
    handleChange: function handleChange(e) {
      this.$emit('input', e.target.files);
    }
  }
};