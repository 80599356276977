import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 3
};
var _hoisted_2 = {
  class: "flex items-center mb-10"
};
var _hoisted_3 = {
  class: "flex items-center mb-12"
};
var _hoisted_4 = {
  href: "https://support.rismasystems.com",
  target: "_blank",
  class: "mr-2"
};
var _hoisted_5 = {
  key: 0,
  class: "flex mt-10"
};
var _hoisted_6 = {
  class: "font-bold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_alert = _resolveComponent("alert");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_Large = _resolveComponent("loading-indicator-Large");
  var _component_single_select = _resolveComponent("single-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_risk_heat_map_selector = _resolveComponent("risk-heat-map-selector");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [$data.notificationText ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    closeable: false,
    type: $data.notificationType
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$trans($data.notificationText)), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), $data.showResetAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 1,
    body: $data.translate.areYouSureYouWantToResetToDefaultColors,
    header: $data.translate.areYouSure,
    "button-ok-text": $data.translate.ok,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.resetToDefaultColours,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showResetAlert = false;
    })
  }, null, 8 /* PROPS */, ["body", "header", "button-ok-text", "button-dismiss-text", "onAccept"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.riskMatrixSettings,
    class: "mb-10"
  }, null, 8 /* PROPS */, ["title"]), $options.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_Large, {
    key: 2
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.selectProject,
    type: "medium",
    class: "mr-4 w-full"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_single_select, {
    modelValue: $data.projectId,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
      return $data.projectId = $event;
    }), $options.setProjectData],
    options: $options.riskProjectsOptions,
    class: "min-w-370px"
  }, null, 8 /* PROPS */, ["modelValue", "options", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("a", _hoisted_4, [_createVNode(_component_feather_icon, {
    icon: "help-circle",
    class: "stroke-3 text-blue-750"
  })]), _createVNode(_component_risma_title, {
    title: $data.translate.enableCustomHeatmapForThisProject,
    type: "medium",
    class: "mr-4 w-full"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_toggle, {
    modelValue: $data.toggleState,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.toggleState = $event;
    }),
    disabled: $options.isReadonly
  }, null, 8 /* PROPS */, ["modelValue", "disabled"])]), _createVNode(_component_risma_title, {
    title: $data.translate.toChangeColorInRiskMatrixPleaseClickOnOne,
    type: "small",
    class: "mb-6"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risk_heat_map_selector, {
    dataset: $options.dataset,
    "is-readonly": $options.isReadonly,
    onSetColor: $options.setColor
  }, null, 8 /* PROPS */, ["dataset", "is-readonly", "onSetColor"]), !$options.isReadonly ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", {
    class: "flex items-center ml-auto mr-6 cursor-pointer",
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return $data.showResetAlert = true;
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "rotate-ccw",
    class: "text-gray-610 stroke-2 mr-1"
  }), _createElementVNode("span", _hoisted_6, _toDisplayString($data.translate.resetOptions), 1 /* TEXT */)]), _createVNode(_component_risma_button, {
    text: $data.translate.saveSettings,
    type: "save",
    onClick: $options.saveSettings
  }, null, 8 /* PROPS */, ["text", "onClick"])])) : _createCommentVNode("v-if", true)]))]);
}