export default {
  name: 'ViewSwitcher',
  introduction: 'Switcher for card views',
  description: 'View switcher that takes list of views as a parameter',
  token: '<view-switcher :view="currentView" :views="listOfViews" @changed="setView" />',
  props: {
    views: {
      type: Array,
      required: true,
      note: 'List of views'
    },
    localStorageKey: {
      type: String,
      required: false,
      default: null,
      note: 'Name of localStorage key'
    },
    view: {
      type: String,
      required: true,
      note: 'Current view'
    }
  },
  emits: ['update:view']
};