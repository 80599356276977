import i18n from '@/i18n';
export var getTranslate = {
  PreviewListCurrentActivity: function PreviewListCurrentActivity() {
    return {
      activitiesThatHaveADeadlineWithin: i18n.t('Activities that have a deadline within'),
      days: i18n.t('days'),
      andNewActivitiesAssignedToYouInTheLast: i18n.t('And new activities assigned to you in the last'),
      fantasticYouHaveNoOutstandingActivitiesFor: i18n.t('Fantastic! You have no outstanding activities for this period'),
      currentActivities: i18n.t('Current activities')
    };
  }
};