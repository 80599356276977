import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.renameDashboard,
    "button-ok-text": $data.translate.rename,
    type: "small",
    "button-dismiss-text": $data.translate.cancel,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('dismiss');
    }),
    onAccept: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('accept', $data.title);
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [_createVNode(_component_input_field, {
        modelValue: $data.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.title = $event;
        }),
        title: $data.translate.title,
        "focus-on-mount": true,
        maxlength: $props.titleCharLimit ? $props.titleCharLimit : '',
        placeholder: $data.translate.enterTitle,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "maxlength", "placeholder"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text"]);
}