import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  style: {
    "position": "relative",
    "height": "5rem",
    "width": "10rem"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_save_status = _resolveComponent("save-status");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_save_status, {
    status: "Saved"
  })]);
}