function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { setUserProjectLevel } from '@/api/risma/data';
import { UserLevels } from '@/constants/UserLevels';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { clickOutside } from '@/utils/directives/clickOutside';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'RismaUserRightsPicker',
  token: '<risma-user-rights-picker :readonly="isReadonly" :projectProperty="project" :userProperty="user"/>',
  components: {
    FeatherIcon: FeatherIcon
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    userProperty: {
      type: Object,
      required: true,
      note: 'User object for the user to change permissions on'
    },
    projectProperty: {
      type: Object,
      required: true,
      default: function _default() {},
      note: 'Project object with information about the project to change permissions for'
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['error', 'updateUser', 'update:opened'],
  data: function data() {
    return {
      UserLevels: UserLevels,
      hideModal: true,
      project: {},
      isLocked: false,
      translate: getTranslate['RismaUserRightsPicker']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserComplianceAccessLevelByProjectId: 'complianceAccessLevelByProjectId',
    currentUserRiskAccessLevelByProjectId: 'riskAccessLevelByProjectId'
  })), {}, {
    supervisorFeatureEnabled: function supervisorFeatureEnabled() {
      return this.getSettingValue('feature.supervisor_enabled') && this.project.objType === ObjectTypes.COMPLIANCE_PROJECT;
    },
    supervisorText: function supervisorText() {
      return this.project.supervisor ? this.translate.unassignAsSupervisor : this.translate.supervisor;
    },
    currentLevelIcon: function currentLevelIcon() {
      return 'icon-risma-' + this.levelToIcon(this.project.access_level);
    },
    supervisorYellowIcon: function supervisorYellowIcon() {
      return this.project.supervisor ? 'icon-risma-superUser-yellow' : '';
    },
    allowAdmin: function allowAdmin() {
      return this.currentUser.level_admin == 1;
    },
    allowEdit: function allowEdit() {
      var currentUser = this.currentUser;
      return (this.projectProperty.objType === ObjectTypes.RISK_PROJECT ? this.currentUserRiskAccessLevelByProjectId(this.projectProperty.id) === UserLevels.ADMIN : this.currentUserComplianceAccessLevelByProjectId(this.projectProperty.id) === UserLevels.ADMIN && currentUser.id != this.userProperty.id && this.projectProperty.access_level < UserLevels.ADMIN) || currentUser.level_admin == 1;
    },
    isLockedPossible: function isLockedPossible() {
      return this.projectProperty.access_level !== UserLevels.NONE && this.projectProperty.access_level !== UserLevels.ADMIN;
    }
  }),
  mounted: function mounted() {
    this.project = this.projectProperty;
    this.isLocked = this.project.locked ? true : false;
  },
  methods: {
    closeModal: function closeModal() {
      if (!this.hideModal) this.$emit('update:opened');
      this.hideModal = true;
    },
    toggleModal: function toggleModal() {
      if (!this.allowEdit || this.readonly) {
        return;
      }
      this.hideModal = !this.hideModal;
      this.$emit('update:opened');
    },
    levelToIcon: function levelToIcon(accessLevel) {
      var icons = ['noAccess', 'user', 'privUser', 'superUser', 'admin'];
      return icons[accessLevel];
    },
    setLevel: function setLevel(accessLevel, supervisor) {
      var _this = this;
      var locked = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var accessLevelNew = accessLevel === undefined ? this.projectProperty.access_level : accessLevel;
      var userId = this.userProperty.id;
      if (accessLevelNew !== UserLevels.SUPER) {
        supervisor = 0;
      }
      if (accessLevelNew > 3 && !this.allowAdmin) {
        return;
      }
      var projectId = this.project.objType === ObjectTypes.COMPLIANCE_PROJECT ? this.project.id : null;
      var riskProjectId = this.project.objType === ObjectTypes.RISK_PROJECT ? this.project.id : null;
      var params = {
        user_id: userId,
        project_id: projectId,
        r_project_id: riskProjectId,
        access_level: accessLevelNew,
        supervisor: supervisor,
        locked: locked ? 1 : 0
      };
      setUserProjectLevel(params).then(function () {
        _this.project.access_level = params.access_level;
        _this.project.supervisor = params.supervisor;
        _this.project.locked = params.locked;
        _this.isLocked = _this.project.locked ? true : false;
        _this.closeModal();
        var userUpdate = JSON.parse(JSON.stringify(_this.userProperty));
        _this.updateUserRightsByProject(userUpdate, _this.project);
        _this.$emit('updateUser', userUpdate);
      }).catch(function (err) {
        var _err$response;
        _this.$emit('error', ((_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.error) || _this.translate.somethingWentWrongUserWasNotUpdated);
        window.scrollTo(0, 0);
      });
    },
    updateUserRightsByProject: function updateUserRightsByProject(user, project) {
      var projectIndex = user.projects.findIndex(function (item) {
        return project.id === item.id && project.objType === item.objType;
      });
      user.projects[projectIndex] = project;
    }
  }
};