function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getAllQuestionsIncludingHidden } from '@/api/compliance/questionnaire';
import { updateQuestion, deleteQuestion } from '@/api/compliance/questions';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import StaticTable from '@/components/Molecules/StaticTable';
import Modal from '@/components/Molecules/Modal/Modal';
import Notification from '@/components/Molecules/Notification';
import QuestionCreation from '@/components/Organisms/Compliance/QuestionCreation';
import Alert from '@/components/Molecules/Modal/Alert';
import { getTranslatables } from '@/constants/datatable/Translatables';
import { actionTypes, complianceAdminFields } from '@/constants/compliance/ComplianceAdmin';
import { catch403 } from '@/utils/handleAPIErrors';
export default {
  name: 'QuestionsAdmin',
  components: {
    FeatherIcon: FeatherIcon,
    RismaButton: RismaButton,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    StaticTable: StaticTable,
    Modal: Modal,
    Notification: Notification,
    QuestionCreation: QuestionCreation,
    Alert: Alert
  },
  props: {
    questionnaireId: {
      type: [String, Number],
      default: null,
      note: 'questionnaire Id'
    },
    questionnaireTypeId: {
      type: [String, Number],
      default: null,
      note: 'default value for questionnaireType'
    },
    questionnaire: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      idToDelete: null,
      showConfirmationAlert: false,
      questions: [],
      editQuestionModalShown: false,
      questionData: {},
      notificationError: null,
      listOfLineThroughSlots: [complianceAdminFields.HEADLINE, complianceAdminFields.QUESTION_NUMBER, complianceAdminFields.QUESTION],
      listOfEventSlots: [complianceAdminFields.ACTION_HIDE, complianceAdminFields.ACTION_EDIT, complianceAdminFields.ACTION_DELETE],
      isCanceled: false,
      isLoading: false,
      translate: getTranslate['QuestionsAdmin']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useActivityTypesStore, {
    allCustomActivities: 'complianceIACustomActivityTypes'
  })), {}, {
    columns: function columns() {
      var _this$questionnaire;
      var col = [this.translate.headline, this.translate.questionNumber, this.translate.question];
      if (!!((_this$questionnaire = this.questionnaire) !== null && _this$questionnaire !== void 0 && _this$questionnaire.readonly) === false) {
        col.push(this.translate.options);
        col.push('');
      }
      return col;
    },
    formattedData: function formattedData() {
      var _this = this;
      var format = [];
      if (this.questions !== null) {
        this.questions.forEach(function (question) {
          var obj = _this.formatQuestion(question);
          format.push(obj);
        });
      }
      return format;
    },
    modalTitle: function modalTitle() {
      return this.questionData.id ? this.translate.editQuestion : this.translate.createQuestion;
    }
  }),
  watch: {
    questionnaireId: function questionnaireId() {
      this.getQuestions();
    }
  },
  mounted: function mounted() {
    this.getQuestions();
  },
  methods: {
    getQuestions: function getQuestions() {
      var _this2 = this;
      var scrollPosition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var element = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      this.isLoading = true;
      return getAllQuestionsIncludingHidden(this.questionnaireId).then(function (_ref) {
        var questions = _ref.questions;
        return _this2.questions = questions;
      }).catch(function () {
        catch403(_this2.$router, 'QuestionnaireAdmin');
      }).finally(function () {
        _this2.isLoading = false;
        if (!scrollPosition || !element) return;
        _this2.$nextTick(function () {
          element.scrollTop = scrollPosition || 0;
        });
      });
    },
    getScrollParams: function getScrollParams() {
      var element = document.getElementsByClassName('scrolling-component')[0];
      var scrollPosition = element === null || element === void 0 ? void 0 : element.scrollTop;
      return {
        element: element,
        scrollPosition: scrollPosition
      };
    },
    handleTriggerEvent: function handleTriggerEvent(_ref2) {
      var _this3 = this;
      var action = _ref2.action,
        id = _ref2.id,
        showHide = _ref2.showHide;
      if (this.isLoading) return;
      var params;
      switch (action) {
        case actionTypes.HIDE:
          this.isLoading = true;
          params = this.getScrollParams();
          return updateQuestion(id, null, showHide).then(function () {
            return _this3.getQuestions(params.scrollPosition, params.element);
          }).finally(function () {
            return _this3.isLoading = false;
          });
        case actionTypes.EDIT:
          this.questionData = this.questions.find(function (question) {
            return question.id === id;
          });
          this.questionData.options = Object.values(this.questionData.options);
          this.editQuestionModalShown = true;
          break;
        case actionTypes.DELETE:
          this.notificationError = null;
          this.showConfirmationAlert = true;
          this.idToDelete = id;
      }
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var _this4 = this;
      this.showConfirmationAlert = false;
      this.isLoading = true;
      var params = this.getScrollParams();
      return deleteQuestion(this.idToDelete).then(function () {
        return _this4.getQuestions(params.scrollPosition, params.element);
      }).catch(function (_ref3) {
        var response = _ref3.response;
        _this4.notificationError = response;
        _this4.isLoading = false;
      });
    },
    formatQuestion: function formatQuestion(question) {
      var _newQuestion, _this$questionnaire2;
      var newQuestion = (_newQuestion = {}, _defineProperty(_newQuestion, complianceAdminFields.HEADLINE, {
        text: question.headline,
        isLineThroughRemoved: question.hidden === false
      }), _defineProperty(_newQuestion, complianceAdminFields.QUESTION_NUMBER, {
        isLineThroughRemoved: question.hidden === false,
        text: "".concat(question.questionnumber, " ").concat(question.user_created === 1 ? '*' : '')
      }), _defineProperty(_newQuestion, complianceAdminFields.QUESTION, {
        isLineThroughRemoved: question.hidden === false,
        text: question.question
      }), _newQuestion);
      if (!!((_this$questionnaire2 = this.questionnaire) !== null && _this$questionnaire2 !== void 0 && _this$questionnaire2.readonly) === false) {
        /*eslint-disable */
        newQuestion[complianceAdminFields.ACTION_HIDE] = {
          action: actionTypes.HIDE,
          id: question.id,
          showHide: question.hidden === false ? true : false,
          icon: question.hidden === false ? 'eye-off' : 'eye',
          iconClasses: 'inline-block',
          text: question.hidden === false ? this.translate.hideQuestion : this.translate.showQuestion
        };
        newQuestion[complianceAdminFields.ACTION_EDIT] = {
          action: actionTypes.EDIT,
          id: question.id,
          showHide: null,
          icon: 'edit',
          iconClasses: 'inline-block',
          text: this.translate.editQuestion
        };
        newQuestion[complianceAdminFields.ACTION_DELETE] = {
          action: actionTypes.DELETE,
          id: question.id,
          showHide: null,
          icon: 'x',
          iconClasses: 'stroke-2 text-red-50',
          text: ''
        };
      }
      return newQuestion;
    },
    toggleModal: function toggleModal() {
      this.editQuestionModalShown = !this.editQuestionModalShown;
      this.questionData = {};
      this.isCanceled = false;
    },
    hideDeleteModal: function hideDeleteModal() {
      this.notificationError = null;
    },
    update: function update() {
      this.editQuestionModalShown = false;
      this.questionData = {};
      var params = this.getScrollParams();
      this.getQuestions(params.scrollPosition, params.element);
    },
    translateQuestionnaireType: function translateQuestionnaireType(type) {
      return this.$trans(getTranslatables('questionnaireTypes')[type] || type);
    },
    abortHandler: function abortHandler() {
      this.isCanceled = true;
    }
  }
};