import { Chrome } from '@ckpack/vue-color';
import InputField from '@/components/Atoms/Inputs/InputField';
import { clickOutside } from '@/utils/directives/clickOutside';
export default {
  name: 'ColorPicker',
  introduction: 'Color Picker Risma',
  description: 'Input with a picker (vue-color third-party library)',
  token: '<color-picker :color="model @input="updFn" @close="closeFn" />',
  directives: {
    clickOutside: clickOutside
  },
  components: {
    InputField: InputField,
    //  More pickers: https://github.com/ckpack/vue-color
    'chrome-picker': Chrome
  },
  props: {
    color: {
      type: String,
      required: false,
      default: '#000',
      note: 'Main color string'
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['input', 'close'],
  data: function data() {
    return {
      colors: {
        hex: '#000000'
      },
      colorValue: '',
      displayPicker: false
    };
  },
  watch: {
    color: {
      handler: function handler(val) {
        if (val) {
          this.setColor(val);
        }
      },
      immediate: true
    },
    colorValue: function colorValue(val) {
      if (val) {
        this.updateColors(val);
        this.$emit('input', val);
      }
    }
  },
  methods: {
    setColor: function setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors: function updateColors(color) {
      if (color.slice(0, 1) === '#') {
        this.colors = {
          hex: color
        };
      } else if (color.slice(0, 4) === 'rgba') {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
          hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3]
        };
      }
    },
    showPicker: function showPicker() {
      this.displayPicker = true;
    },
    hidePicker: function hidePicker() {
      if (this.displayPicker) {
        this.displayPicker = false;
        this.$emit('close', this.colorValue);
      }
    },
    togglePicker: function togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput: function updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker: function updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a === 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
    }
  }
};