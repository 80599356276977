import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_attachment_selector = _resolveComponent("attachment-selector");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_attachment_selector, {
    "attachments-existed": _ctx.attachmentsExisted,
    "owner-id": 1,
    "module-id": 2,
    remote: false,
    module: "controls",
    onUpdatedFiles: $options.showUpdatedFiles
  }, null, 8 /* PROPS */, ["attachments-existed", "onUpdatedFiles"])]);
}