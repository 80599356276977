import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-155e4776"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["href", "download", "title"];
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = ["disabled", "title", "onClick"];
var _hoisted_4 = ["title", "onClick"];
var _hoisted_5 = {
  key: 1
};
var _hoisted_6 = ["title", "onClick"];
var _hoisted_7 = {
  key: 1
};
var _hoisted_8 = ["title", "onClick"];
var _hoisted_9 = {
  key: 1
};
var _hoisted_10 = ["href", "download", "title"];
var _hoisted_11 = ["title", "onClick"];
var _hoisted_12 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_preview_list = _resolveComponent("preview-list");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_preview_list, {
    title: $data.translate.documents,
    "cookie-hidden-name": "contract-".concat($props.data.id, "-document"),
    class: "mb-10"
  }, _createSlots({
    default: _withCtx(function () {
      return [!$options.dataset.length ? (_openBlock(), _createBlock(_component_risma_title, {
        key: 0,
        type: "big",
        class: "ml-4",
        title: $data.translate.noDocumentsHaveBeenUploadedYet
      }, null, 8 /* PROPS */, ["title"])) : (_openBlock(), _createBlock(_component_risma_datatable, {
        key: 1,
        ref: "reportsDataTable",
        dataset: _ctx.datasetSlice,
        "dataset-total": $options.dataset,
        "dataset-total-sliced": _ctx.datasetSliceTotal,
        paging: true,
        "page-length": _ctx.pageLength,
        "wrapper-height-sticky": 400,
        "filter-by-column": true,
        order: $data.defaultOrder,
        "has-top-navigation": false,
        "is-draggable": false,
        "disabled-filter-by-columns": $data.disabledFilterByColumns,
        "disabled-sort-by-columns": $data.disabledSortByColumns,
        "decouple-filter-fields": [{
          key: 'type'
        }],
        "columns-meta": $data.columnsMeta,
        "full-height": true,
        onPageChange: _ctx.onPageChange,
        onOnStateChanged: _ctx.onStateChanged
      }, {
        docTitle: _withCtx(function (_ref) {
          var item = _ref.item;
          return [_createElementVNode("a", {
            class: "flex items-center docTitle",
            href: item.url,
            download: item.filename,
            title: item.filename,
            target: "_self",
            type: "download"
          }, _toDisplayString(item.title), 9 /* TEXT, PROPS */, _hoisted_1)];
        }),
        type: _withCtx(function (_ref2) {
          var item = _ref2.item;
          return [_createElementVNode("div", {
            innerHTML: item.field
          }, null, 8 /* PROPS */, _hoisted_2)];
        }),
        pinned: _withCtx(function (_ref3) {
          var item = _ref3.item;
          return [_createElementVNode("button", {
            disabled: $props.readOnly,
            class: _normalizeClass(["flex items-center focus:outline-none", {
              'cursor-default': $props.readOnly
            }]),
            title: _ctx.$trans(item.data.pinned ? 'Pinned' : 'Pin'),
            onClick: function onClick($event) {
              return $options.togglePin(item.data.id);
            }
          }, [_createVNode(_component_feather_icon, {
            class: _normalizeClass(["mr-2 stroke-2", {
              'text-gray-620': !item.data.pinned,
              'text-green-150': item.data.pinned
            }]),
            icon: "award"
          }, null, 8 /* PROPS */, ["class"])], 10 /* CLASS, PROPS */, _hoisted_3)];
        }),
        showVersions: _withCtx(function (_ref4) {
          var item = _ref4.item;
          return [!$props.readOnly ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "flex items-center focus:outline-none",
            title: $data.translate.showVersions,
            onClick: function onClick($event) {
              return $options.showVersions(item.data);
            }
          }, [_createVNode(_component_feather_icon, {
            class: "mr-2 stroke-2 cursor-pointer",
            icon: "copy"
          })], 8 /* PROPS */, _hoisted_4)) : (_openBlock(), _createElementBlock("div", _hoisted_5))];
        }),
        newVersion: _withCtx(function (_ref5) {
          var item = _ref5.item;
          return [!$props.readOnly ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "flex items-center focus:outline-none",
            title: $data.translate.newVersion,
            onClick: function onClick($event) {
              return $options.addNewVersion(item.data);
            }
          }, [_createVNode(_component_feather_icon, {
            class: "mr-2 stroke-2 cursor-pointer",
            icon: "plus"
          })], 8 /* PROPS */, _hoisted_6)) : (_openBlock(), _createElementBlock("div", _hoisted_7))];
        }),
        edit: _withCtx(function (_ref6) {
          var item = _ref6.item;
          return [!$props.readOnly ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "flex items-center focus:outline-none",
            title: $data.translate.edit,
            onClick: function onClick($event) {
              return $options.onEditFile(item.data);
            }
          }, [_createVNode(_component_feather_icon, {
            icon: "edit",
            class: "text-blue-750 stroke-2 cursor-pointer hover:underline"
          })], 8 /* PROPS */, _hoisted_8)) : (_openBlock(), _createElementBlock("div", _hoisted_9))];
        }),
        download: _withCtx(function (_ref7) {
          var item = _ref7.item;
          return [_createElementVNode("a", {
            class: "flex cursor-pointer hover:underline w-16",
            href: item.data.url,
            download: item.data.filename,
            title: item.data.filename,
            target: "_self",
            type: "download"
          }, [_createVNode(_component_feather_icon, {
            icon: "download",
            class: "text-blue-750 stroke-2"
          })], 8 /* PROPS */, _hoisted_10)];
        }),
        delete: _withCtx(function (_ref8) {
          var item = _ref8.item;
          return [!$props.readOnly ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "flex items-center focus:outline-none",
            title: $data.translate.delete,
            onClick: function onClick($event) {
              return $options.deleteFile(item.data.id);
            }
          }, [_createVNode(_component_feather_icon, {
            class: "mr-2 text-red-50 stroke-2 cursor-pointer",
            icon: "x"
          })], 8 /* PROPS */, _hoisted_11)) : (_openBlock(), _createElementBlock("div", _hoisted_12))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset", "dataset-total", "dataset-total-sliced", "page-length", "order", "disabled-filter-by-columns", "disabled-sort-by-columns", "columns-meta", "onPageChange", "onOnStateChanged"]))];
    }),
    _: 2 /* DYNAMIC */
  }, [!$props.readOnly ? {
    name: "nav-buttons",
    fn: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "plus",
        width: "34",
        height: "34",
        class: "mr-2 ml-auto stroke-2 cursor-pointer",
        onClick: _withModifiers($options.toggleCreateModal, ["stop"])
      }, null, 8 /* PROPS */, ["onClick"])];
    })
  } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "cookie-hidden-name"])]);
}