import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, withKeys as _withKeys, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-02683c54"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["title"];
var _hoisted_2 = ["tabindex"];
var _hoisted_3 = {
  class: "flex justify-between items-center"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  key: 0
};
var _hoisted_6 = {
  key: 1,
  class: "flex"
};
var _hoisted_7 = ["innerHTML"];
var _hoisted_8 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_editor = _resolveComponent("editor");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", null, [_renderSlot(_ctx.$slots, "headline", {}, function () {
    return [_createElementVNode("h2", {
      class: "text-title-small text-gray-750 font-bold truncate",
      title: $props.headline
    }, _toDisplayString($props.headline), 9 /* TEXT, PROPS */, _hoisted_1)];
  }, true), _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tinymce-box", $props.editorWrapperClass]),
    tabindex: $props.readOnly ? -1 : 0,
    onKeyup: _cache[3] || (_cache[3] = _withKeys(_withModifiers(function () {
      return $options.toggleTinyEditor && $options.toggleTinyEditor.apply($options, arguments);
    }, ["stop"]), ["enter"]))
  }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, [_renderSlot(_ctx.$slots, "header", {}, undefined, true)]), !$props.readOnly && $props.showControlButtons ? (_openBlock(), _createElementBlock("div", _hoisted_4, [!$data.isEditMode ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_button, {
    text: $data.translate.edit,
    type: "util",
    onClick: $options.showTinyEditor
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "edit",
        class: "text-gray-610 stroke-2 mr-1"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"])])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_risma_button, {
    text: $data.translate.cancel,
    class: "mr-2",
    type: "util",
    onClick: $options.cancelChanges
  }, null, 8 /* PROPS */, ["text", "onClick"]), _createVNode(_component_risma_button, {
    text: $data.translate.save,
    type: "save",
    onClick: $options.saveChanges
  }, null, 8 /* PROPS */, ["text", "onClick"])]))])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", null, [$options.showPreviewData ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(["tinymce-box-content", {
      'open': $data.isEditMode
    }]),
    onClick: _cache[1] || (_cache[1] = _withModifiers(function () {
      return $options.onContentClick && $options.onContentClick.apply($options, arguments);
    }, ["stop"]))
  }, [$options.textData ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: _normalizeClass(["break-words", {
      'cursor-pointer': !$props.readOnly && !$props.showControlButtons
    }]),
    innerHTML: $options.shortenedData
  }, null, 10 /* CLASS, PROPS */, _hoisted_7)) : _createCommentVNode("v-if", true), _createElementVNode("span", {
    class: "cursor-pointer font-semibold italic text-blue-750",
    onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
      return $options.onExpansionLabelClick && $options.onExpansionLabelClick.apply($options, arguments);
    }, ["stop"]))
  }, _toDisplayString($options.expansionLabel), 1 /* TEXT */)], 2 /* CLASS */)) : _createCommentVNode("v-if", true), ($data.isEditorInit || $props.alwaysOpen) && $data.tinymceModulesLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_withDirectives(_createElementVNode("div", null, [_createVNode(_component_editor, {
    id: $data.id,
    modelValue: $data.content,
    "onUpdate:modelValue": [_cache[2] || (_cache[2] = function ($event) {
      return $data.content = $event;
    }), $options.onChange],
    init: $options.init,
    disabled: $props.readOnly,
    "api-key": "no-api-key",
    "output-format": "html"
  }, null, 8 /* PROPS */, ["id", "modelValue", "init", "disabled", "onUpdate:modelValue"])], 512 /* NEED_PATCH */), [[_vShow, !$options.showPreviewData]])])) : _createCommentVNode("v-if", true)])], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_2)), [[_directive_click_outside, $options.onOutsideClick]])]);
}