function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getAllByType } from '@/api/compliance/informationAssets';
import * as RiskApi from '@/api/risk/risk';
import { getRisks } from '@/api/risma/data';
import { searchNodes } from '@/api/compliance/reports/nodes';
import InfoBox from '@/components/Atoms/Containers/InfoBox';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { MODULES } from '@/constants/modules';
var STATS_TYPE = {
  noComplianceNodes: 'noComplianceNodes',
  noRiskAssessed: 'noRiskAssessed',
  riskAssessedSystemChart: 'riskAssessedSystemChart'
};
export default {
  name: 'ComplianceStatsWidget',
  description: 'General component that use in Compliance Overview page to show data as chart or just some data',
  components: {
    InfoBox: InfoBox,
    RismaDoughnutChart: RismaDoughnutChart,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    statsType: {
      type: String,
      default: STATS_TYPE.noComplianceNodes,
      validator: function validator(type) {
        return Object.values(STATS_TYPE).includes(type);
      }
    },
    projectId: {
      required: true,
      type: [Number, String],
      note: 'ID of current project'
    }
  },
  data: function data() {
    return {
      isLoaded: false,
      complianceNodesCount: 0,
      systems: [],
      riskAssessedPieColors: ['#ffc103', '#57aa74'],
      riskProjects: [],
      currentRiskProject: {},
      approvedRisksNumber: 0,
      translate: {
        riskAssessedSystems: this.$trans('Risk assessed systems'),
        of: this.$trans('of'),
        systemsHaveBeenRiskAssessed: this.$trans('systems have been risk assessed'),
        noOfRiskAssessments: this.$trans('No of risk assessments'),
        numberOfComplianceNodes: this.$trans('Number of compliance nodes'),
        ofYourRisksAssessmentsHaveBeenApproved: this.$trans('of your risks assessments have been approved')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isRiskAssessedSystem: function isRiskAssessedSystem() {
      return this.statsType === STATS_TYPE.riskAssessedSystemChart;
    },
    isNoRiskAssessed: function isNoRiskAssessed() {
      return this.statsType === STATS_TYPE.noRiskAssessed;
    },
    isNoComplianceNodes: function isNoComplianceNodes() {
      return this.statsType === STATS_TYPE.noComplianceNodes;
    },
    riskAssessedPieChart: function riskAssessedPieChart() {
      return {
        datasets: [{
          backgroundColor: this.riskAssessedPieColors,
          data: [this.systems.length - this.riskAssessedSystems.length, this.riskAssessedSystems.length]
        }]
      };
    },
    riskAssessedSystems: function riskAssessedSystems() {
      return this.systems.filter(function (system) {
        return system.links && system.links[MODULES.RISK_ASSESSMENT] && system.links[MODULES.RISK_ASSESSMENT].length;
      });
    },
    riskApprovalEnabled: function riskApprovalEnabled() {
      var _this$currentRiskProj;
      return ((_this$currentRiskProj = this.currentRiskProject) === null || _this$currentRiskProj === void 0 ? void 0 : _this$currentRiskProj.riskApproval) === 1;
    },
    infoBoxTitle: function infoBoxTitle() {
      if (this.isRiskAssessedSystem) {
        return this.translate.riskAssessedSystems;
      }
      if (this.isNoRiskAssessed) {
        return this.translate.noOfRiskAssessments;
      }
      if (this.isNoComplianceNodes) {
        return this.translate.numberOfComplianceNodes;
      }
      return '';
    },
    infoBoxLink: function infoBoxLink() {
      if (this.isRiskAssessedSystem) {
        return '/compliance/informationassets/type/systems';
      }
      return '';
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      var promises = [];
      if (this.isNoComplianceNodes) {
        promises.push(this.getNodes());
      }
      if (this.isRiskAssessedSystem || this.isNoRiskAssessed) {
        promises = [this.prepareSys(), this.getRiskProjects()];
      }
      return Promise.all(promises).finally(function () {
        return _this.isLoaded = true;
      });
    },
    prepareSys: function prepareSys() {
      var _this2 = this;
      return this.getIASystems().then(function () {
        var riskIds = _this2.riskAssessedSystems.reduce(function (acc, system) {
          acc.push.apply(acc, _toConsumableArray(system.links[MODULES.RISK_ASSESSMENT]));
          return acc;
        }, []);
        if (riskIds.length) {
          return _this2.getRiskRisks("?idList=".concat(riskIds.join(',')));
        }
      });
    },
    getNodes: function getNodes() {
      var _this3 = this;
      return searchNodes(this.projectId).then(function (_ref) {
        var list = _ref.list;
        return _this3.complianceNodesCount = list.length - 1;
      }); // first item is a root compliance
    },
    getIASystems: function getIASystems() {
      var _this4 = this;
      return getAllByType(AssetsTypes.SYSTEMS, '?data[]=links').then(function (_ref2) {
        var list = _ref2.list;
        return _this4.systems = list;
      });
    },
    getRiskRisks: function getRiskRisks(params) {
      var _this5 = this;
      return getRisks(params).then(function (_ref3) {
        var list = _ref3.list;
        var risksApproved = list.filter(function (item) {
          return item.approved;
        });
        _this5.approvedRisksNumber = risksApproved.length;
      });
    },
    getRiskProjects: function getRiskProjects() {
      var _this6 = this;
      return RiskApi.getRiskProjects().then(function (_ref4) {
        var list = _ref4.list;
        _this6.riskProjects = list;
        _this6.currentRiskProject = _this6.riskProjects.find(function (item) {
          return +item.id === +_this6.getSettingValue('feature.systems_r_projects_id');
        });
      }).catch(function (e) {
        throw e;
      });
    }
  }
};