var _settingNamesByModule;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { MODULES } from '@/constants/modules';
export var settingNamesByModule = (_settingNamesByModule = {}, _defineProperty(_settingNamesByModule, MODULES.EXECUTION, ['app.module_execution_enabled']), _defineProperty(_settingNamesByModule, MODULES.INCIDENTS, ['app.module_incidents_enabled']), _defineProperty(_settingNamesByModule, MODULES.COMPLIANCE, ['app.module_compliance_enabled']), _defineProperty(_settingNamesByModule, MODULES.CONTROLS, ['app.module_controls_enabled']), _defineProperty(_settingNamesByModule, MODULES.RISK, ['app.module_risk_enabled', 'app.module_risks2_enabled']), _defineProperty(_settingNamesByModule, MODULES.COMPLIANCE_INFORMATION_ASSETS, ['app.module_compliance_enabled', 'feature.enable_ia_custom']), _defineProperty(_settingNamesByModule, MODULES.COMPLIANCE_IA_CONTROLLER, ['app.module_compliance_enabled', 'feature.enable_data_controllers']), _defineProperty(_settingNamesByModule, MODULES.COMPLIANCE_IA_PROCESSOR, ['app.module_compliance_enabled', 'feature.enable_data_processors']), _defineProperty(_settingNamesByModule, MODULES.COMPLIANCE_IA_SYSTEM, ['feature.enable_systems', 'app.module_compliance_enabled']), _defineProperty(_settingNamesByModule, MODULES.CONTRACTS, ['app.module_contracts_enabled']), _defineProperty(_settingNamesByModule, MODULES.COMPLIANCE_PROCESS_TREE_NODE, {
  // sometimes its as policies and its not related to compliance, sometimes its compliance
  main: ['feature.process_tree'],
  secondary: ['app.module_compliance_enabled', 'feature.policy_management_menu']
}), _settingNamesByModule);