function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { sendAppInvitation } from '@/api/risma/userSettings';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import StaticTable from '@/components/Molecules/StaticTable';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import { StaticTableThemes } from '@/constants/StaticTableThemes';
import { sortAlphabetic } from '@/utils/sort';
import { toLocalDateTime } from '@/utils/date';
export default {
  name: 'AppInvitations',
  components: {
    RismaTitle: RismaTitle,
    StaticTable: StaticTable,
    RismaButton: RismaButton,
    RismaToggle: RismaToggle
  },
  props: {
    app: {
      type: String,
      require: true,
      default: null
    }
  },
  data: function data() {
    return {
      tableThema: StaticTableThemes.zebra,
      invitedOnly: false,
      translate: getTranslate['AppInvitations']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), {}, {
    headline: function headline() {
      return {
        incident: this.translate.incidentAppInvitations,
        controls: this.translate.controlsAppInvitations
      };
    },
    columns: function columns() {
      return [this.translate.username, this.translate.fullName, this.translate.email, this.translate.status, this.translate.invitationDate, ''];
    },
    dataset: function dataset() {
      var _this = this;
      return this.filteredUsers.map(function (user) {
        return {
          username: user.username,
          fullname: user.fullname,
          email: user.email,
          status: _this.getInvitationStatus(user),
          invited: _this.getInvitationDate(user),
          sendInvitation: {
            userId: user.id,
            disabled: false
          }
        };
      });
    },
    filteredUsers: function filteredUsers() {
      var _this2 = this;
      if (!this.invitedOnly) {
        return this.usersInAlphabeticalOrder;
      }
      return this.usersInAlphabeticalOrder.filter(function (user) {
        var status = _this2.getInvitationStatus(user);
        return status === _this2.translate.invited;
      });
    },
    usersInAlphabeticalOrder: function usersInAlphabeticalOrder() {
      return sortAlphabetic(this.users, 'display_name');
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions(useUsersStore, {
    loadUsers: 'load'
  })), {}, {
    sendInvitation: function sendInvitation(slotData) {
      var _this3 = this;
      slotData.disabled = true;
      return sendAppInvitation(slotData.userId, {
        app: this.app
      }).then(function () {
        return _this3.loadUsers();
      }).finally(function () {
        return slotData.disabled = false;
      });
    },
    getInvitationStatus: function getInvitationStatus(user) {
      var invitationsByApp = this.getInvitationsByApp(user);
      if (invitationsByApp.length > 0) {
        return this.translate.invited;
      }
      return this.translate.notInvited;
    },
    getInvitationDate: function getInvitationDate(user) {
      var invitationsByApp = this.getInvitationsByApp(user);
      if (invitationsByApp.length > 0) {
        return toLocalDateTime(invitationsByApp.pop().created_at);
      }
      return this.translate.notInvited;
    },
    getInvitationsByApp: function getInvitationsByApp(user) {
      var _this4 = this;
      return user === null || user === void 0 ? void 0 : user.appInvitations.filter(function (item) {
        return item.app === _this4.app;
      });
    },
    toggleInvitedFilter: function toggleInvitedFilter(event) {
      this.invitedOnly = event.value;
    }
  })
};