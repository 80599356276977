import AttachmentSelector from '@/components/Organisms/AttachmentSelector/AttachmentSelector';
export default {
  name: '',
  components: {
    AttachmentSelector: AttachmentSelector
  },
  data: function data() {
    return {
      attachmentsExisted: [{
        filename: 'File',
        url: '/api/2.0/files/141?download',
        module: 'file'
      }, {
        filename: 'Link outside',
        url: 'https://translate.google.com',
        module: 'link'
      }]
    };
  },
  methods: {
    showUpdatedFiles: function showUpdatedFiles(_ref) {
      var files = _ref.files;
      console.log('showUpdatedFiles', files); // eslint-disable-line
    }
  }
};