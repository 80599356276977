import i18n from '@/i18n';
export var getTranslate = {
  ContractDescription: function ContractDescription() {
    return {
      thereAreStillSomeRelatedContractsAttachedTo: i18n.t('There are still some related contracts attached to this contract'),
      warning: i18n.t('Warning'),
      areYouSureYouWishToPermanentlyDeleteThisC: i18n.t('Are you sure you wish to permanently delete this contract?'),
      deleteContract: i18n.t('Delete contract'),
      delete: i18n.t('Delete'),
      cancel: i18n.t('Cancel'),
      confidentialContract: i18n.t('Confidential contract'),
      description: i18n.t('Description'),
      startDate: i18n.t('Start date'),
      expirationDate: i18n.t('Expiration date'),
      notificationUponExpirationDate: i18n.t('Notification upon expiration date'),
      renewalDeadline: i18n.t('Renewal deadline'),
      notificationUponRenewalDeadline: i18n.t('Notification upon renewal deadline'),
      renewalAmount: i18n.t('Renewal amount'),
      terminationDeadline: i18n.t('Termination deadline'),
      notificationUponTerminationDeadline: i18n.t('Notification upon termination deadline'),
      autoRenewal: i18n.t('Auto renewal'),
      choose: i18n.t('Choose'),
      amount: i18n.t('Amount'),
      paymentInterval: i18n.t('Payment interval'),
      contractVersion: i18n.t('Contract version'),
      statusChangeNote: i18n.t('Status change note'),
      contractExpiresAfterExpirationDate: i18n.t('Contract expires after expiration date'),
      contractContinuesAfterExpirationDate: i18n.t('Contract continues after expiration date'),
      thisContractIsArchivedAndCantBeChanged: i18n.t('This contract is archived and can’t be changed'),
      archiveContract: i18n.t('Archive contract'),
      restoreContract: i18n.t('Restore contract'),
      areYouSureYouWantToArchiveContract: i18n.t('Are you sure you want to archive contract?'),
      areYouSureYouWantToRestoreContract: i18n.t('Are you sure you want to restore contract?'),
      rememberToEditContractDatesAndorContractSt: i18n.t('Remember to edit contract dates and/or contract status.'),
      renewContract: i18n.t('Renew contract'),
      addOutsourcing: i18n.t('Add outsourcing'),
      copyOnlyContract: i18n.t('Copy only contract'),
      copyContractAndControls: i18n.t('Copy contract and controls'),
      anErrorOccurred: i18n.t('An error occurred')
    };
  },
  ContractDocuments: function ContractDocuments() {
    return {
      documents: i18n.t('Documents'),
      noDocumentsHaveBeenUploadedYet: i18n.t('No documents have been uploaded yet.'),
      newVersion: i18n.t('New version'),
      edit: i18n.t('Edit'),
      delete: i18n.t('Delete'),
      title: i18n.t('Title'),
      uploaderName: i18n.t('Uploader name'),
      uploadDate: i18n.t('Upload date'),
      type: i18n.t('Type'),
      versions: i18n.t('Versions'),
      notSpecified: i18n.t('Not specified'),
      showVersions: i18n.t('Show versions')
    };
  },
  ContractEntry: function ContractEntry() {
    return {
      formHasRequiredFields: i18n.t('Form has required fields.'),
      followingFieldsAreMissingValues: i18n.t('Following fields are missing values:'),
      thisActivityIsReadonlyAsYourUserIsConfigu: i18n.t('This activity is read-only as your user is configured to only have read-only access'),
      activityType: i18n.t('Activity type')
    };
  },
  ContractParty: function ContractParty() {
    return {
      delete: i18n.t('Delete'),
      name: i18n.t('Name'),
      address: i18n.t('Address'),
      city: i18n.t('City'),
      zipcode: i18n.t('Zipcode'),
      phone: i18n.t('Phone'),
      vatNumber: i18n.t('VAT number'),
      title: i18n.t('Title'),
      email: i18n.t('E-mail'),
      nothingToShow: i18n.t('Nothing to show'),
      youNeedToAddContractPartyBeforeAddingConta: i18n.t('You need to add contract party before adding contact persons.'),
      selectedContractPartyDoesntHaveAnyAvailable: i18n.t('Selected contract party doesn`t have any available contact persons.'),
      yourActivityHasBeenCreated: i18n.t('Your activity has been created')
    };
  },
  ContractRelatedContracts: function ContractRelatedContracts() {
    return {
      otherRelatedContracts: i18n.t('Other related contracts'),
      nothingToShow: i18n.t('Nothing to show'),
      relatedContractsFor: i18n.t('Related contracts for'),
      relatedContracts: i18n.t('Related contracts')
    };
  },
  ContractsDashboard: function ContractsDashboard() {
    return {
      expiring: i18n.t('Expiring'),
      renewal: i18n.t('Renewal'),
      statusOverview: i18n.t('Status overview'),
      contracts: i18n.t('Contracts'),
      annualCost: i18n.t('Annual cost'),
      expirationOfContractsWithin30Days: i18n.t('Expiration of contracts within 30 days'),
      nothingToShow: i18n.t('Nothing to show')
    };
  },
  ContractsDPAOverview: function ContractsDPAOverview() {
    return {
      dataProcessorAgreements: i18n.t('Data processor agreements'),
      dataProcessorAgreement: i18n.t('Data processor agreement'),
      mainContract: i18n.t('Main contract'),
      yes: i18n.t('Yes'),
      no: i18n.t('No')
    };
  },
  ContractsFileDocuments: function ContractsFileDocuments() {
    return {
      search: i18n.t('Search'),
      contractStatus: i18n.t('Contract status'),
      select: i18n.t('Select'),
      documentTypes: i18n.t('Document types'),
      resetFilter: i18n.t('Reset filter'),
      documents: i18n.t('Documents'),
      nothingToShow: i18n.t('Nothing to show'),
      theSelectedSearchCriteriaHaveNoResultsToDi: i18n.t('The selected search criteria have no results to display'),
      title: i18n.t('Title'),
      contract: i18n.t('Contract'),
      uploadDate: i18n.t('Upload date'),
      type: i18n.t('Type'),
      versions: i18n.t('Versions')
    };
  },
  ContractsOverview: function ContractsOverview() {
    return {
      controls: i18n.t('Controls'),
      awaiting: i18n.t('Awaiting'),
      exceeded: i18n.t('Exceeded'),
      contracts: i18n.t('Contracts'),
      searchAfterContract: i18n.t('Search after contract...'),
      display: i18n.t('Display'),
      contractNo: i18n.t('Contract no.'),
      title: i18n.t('Title'),
      contractParty: i18n.t('Contract party'),
      contactPerson: i18n.t('Contact person'),
      activityType: i18n.t('Activity type'),
      amount: i18n.t('Amount'),
      currency: i18n.t('Currency'),
      paymentInterval: i18n.t('Payment interval'),
      renewalAmount: i18n.t('Renewal amount'),
      renewalAmountCurrency: i18n.t('Renewal amount currency'),
      startDate: i18n.t('Start date'),
      expirationDate: i18n.t('Expiration date'),
      terminationDeadline: i18n.t('Termination deadline'),
      renewalDeadline: i18n.t('Renewal deadline'),
      status: i18n.t('Status'),
      archived: i18n.t('Archived'),
      description: i18n.t('Description'),
      responsible: i18n.t('Responsible'),
      accountable: i18n.t('Accountable'),
      consulted: i18n.t('Consulted'),
      informed: i18n.t('Informed'),
      organisation: i18n.t('Organisation'),
      tags: i18n.t('Tags'),
      starredContracts: i18n.t('Starred contracts'),
      myContracts: i18n.t('My contracts'),
      expiredContracts: i18n.t('Expired contracts'),
      overview: i18n.t('Overview'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      contractHasBeenCreated: i18n.t('Contract has been created'),
      youDoNotHavePermissionToEditThisContract: i18n.t('You do not have permission to edit this contract'),
      noRecordsMatching: i18n.t('No records matching')
    };
  },
  ContractsOverviewWrapper: function ContractsOverviewWrapper() {
    return {
      myContracts: i18n.t('My contracts'),
      myCompanies: i18n.t('My companies'),
      overview: i18n.t('Overview'),
      expiredContracts: i18n.t('Expired contracts'),
      dataProcessorAgreements: i18n.t('Data processor agreements'),
      searchDocuments: i18n.t('Search documents'),
      searchContent: i18n.t('Search content')
    };
  },
  ContractsReport: function ContractsReport() {
    return {
      report: i18n.t('Report'),
      expirationDate: i18n.t('Expiration date'),
      deadlineInterval: i18n.t('Deadline interval'),
      contractNo: i18n.t('Contract no.'),
      title: i18n.t('Title'),
      contractParty: i18n.t('Contract party'),
      contactPerson: i18n.t('Contact person'),
      activityType: i18n.t('Activity type'),
      amount: i18n.t('Amount'),
      currency: i18n.t('Currency'),
      renewalAmount: i18n.t('Renewal amount'),
      renewalAmountCurrency: i18n.t('Renewal amount currency'),
      startDate: i18n.t('Start date'),
      notificationUponExpirationDate: i18n.t('Notification upon expiration date'),
      terminationDeadline: i18n.t('Termination deadline'),
      notificationUponTerminationDeadline: i18n.t('Notification upon termination deadline'),
      renewalDeadline: i18n.t('Renewal deadline'),
      notificationUponRenewalDeadline: i18n.t('Notification upon renewal deadline'),
      paymentInterval: i18n.t('Payment interval'),
      confidential: i18n.t('Confidential'),
      status: i18n.t('Status'),
      archived: i18n.t('Archived'),
      description: i18n.t('Description'),
      responsible: i18n.t('Responsible'),
      accountable: i18n.t('Accountable'),
      consulted: i18n.t('Consulted'),
      informed: i18n.t('Informed'),
      organisation: i18n.t('Organisation'),
      tags: i18n.t('Tags'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      contracts: i18n.t('Contracts'),
      startAndExpirationDate: i18n.t('Start- and expiration date')
    };
  }
};