import { getTranslate } from './translate';
import * as TreeApi from '@/api/compliance/tree';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import Modal from '@/components/Molecules/Modal/Modal';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
export default {
  name: 'ContractOutsourcingModal',
  components: {
    InputField: InputField,
    Modal: Modal,
    SingleSelect: SingleSelect
  },
  props: {
    contractTitle: {
      type: String,
      required: false,
      default: ''
    },
    contractCompanies: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of contract companie`s ids'
    },
    outsourcingProjects: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['dismiss', 'nodeCreated'],
  data: function data() {
    return {
      projectId: null,
      mainTitle: '',
      initialParentIds: {},
      companies: [],
      translate: getTranslate['ContractOutsourcingModal']()
    };
  },
  computed: {
    formattedProjects: function formattedProjects() {
      return this.outsourcingProjects.map(function (project) {
        return {
          label: project.title,
          value: project.id
        };
      });
    },
    initialParentId: function initialParentId() {
      return this.initialParentIds[this.projectId];
    },
    showProjectSelect: function showProjectSelect() {
      return this.formattedProjects.length > 1;
    },
    contraPartyTitles: function contraPartyTitles() {
      var _this = this;
      if (!this.contractCompanies || !this.contractCompanies.length) return '';
      var filteredCompanies = this.companies.filter(function (company) {
        return _this.contractCompanies.includes(company.id);
      });
      return filteredCompanies.map(function (company) {
        return company.name;
      }).join(', ');
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    var promises = [this.getOutsourcingNodes()];
    if (this.contractCompanies && this.contractCompanies.length) {
      promises.push(this.getCompanies());
    }
    Promise.all(promises).then(function () {
      _this2.setInitialValues();
    });
  },
  methods: {
    setInitialValues: function setInitialValues() {
      var _this$outsourcingProj;
      this.mainTitle = this.contractTitle;
      this.projectId = (_this$outsourcingProj = this.outsourcingProjects[0]) === null || _this$outsourcingProj === void 0 ? void 0 : _this$outsourcingProj.id;
      if (this.contraPartyTitles) {
        this.mainTitle += " - ".concat(this.contraPartyTitles);
      }
    },
    getCompanies: function getCompanies() {
      var _this3 = this;
      return _getCompanies().then(function (_ref) {
        var list = _ref.list;
        _this3.companies = list;
      });
    },
    getOutsourcingNodes: function getOutsourcingNodes() {
      var _this4 = this;
      var promises = this.outsourcingProjects.map(function (project) {
        return TreeApi.getTreeByProjectId(project.id);
      });
      Promise.all(promises).then(function (response) {
        _this4.outsourcingProjects.forEach(function (project, index) {
          _this4.initialParentIds[project.id] = response[index].id;
        });
      });
    },
    saveNode: function saveNode() {
      var _this5 = this;
      var params = {
        title: this.mainTitle,
        appendTo: this.initialParentId
      };
      TreeApi.addChildToNode(this.projectId, params).then(function (response) {
        _this5.$emit('nodeCreated', response.id);
      });
    }
  }
};