import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-c7f6fb34"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "control-details-body row"
};
var _hoisted_2 = {
  class: "col-4 control-details-body-column mb-3"
};
var _hoisted_3 = {
  class: "col-4 control-details-body-column mb-3"
};
var _hoisted_4 = {
  class: "col-4 control-details-body-column"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_risma_tags = _resolveComponent("risma-tags");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'inherited-from-mother': $props.isDaughter
    }, "control-details-associations"])
  }, [_createVNode(_component_risma_title, {
    title: $options.associationsTitle,
    type: "medium",
    class: "mb-1"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $options.accountTitle,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), $props.accounts.items && !$options.inputsDisabled ? (_openBlock(), _createBlock(_component_risma_select, {
    key: 0,
    "enable-select-all": true,
    "label-key": $data.labelKey,
    options: $props.accounts.items,
    "selected-options": $data.data.accounts,
    "un-sorted": $data.unSorted,
    placeholder: $options.accountPlaceholder,
    onSelected: _cache[0] || (_cache[0] = function ($event) {
      return $options.handleSelected('accounts', $event);
    })
  }, null, 8 /* PROPS */, ["label-key", "options", "selected-options", "un-sorted", "placeholder"])) : _createCommentVNode("v-if", true), $data.dataAsTags.accounts && $options.inputsDisabled ? (_openBlock(), _createBlock(_component_risma_tags, {
    key: 1,
    items: $data.dataAsTags.accounts,
    "as-tag": true
  }, null, 8 /* PROPS */, ["items"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $options.processesTitle,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), $props.processes.items && !$options.inputsDisabled ? (_openBlock(), _createBlock(_component_risma_select, {
    key: 0,
    "enable-select-all": true,
    "label-key": $data.labelKey,
    options: $props.processes.items,
    "selected-options": $data.data.processes,
    placeholder: $options.processesPlaceholder,
    onSelected: _cache[1] || (_cache[1] = function ($event) {
      return $options.handleSelected('processes', $event);
    })
  }, null, 8 /* PROPS */, ["label-key", "options", "selected-options", "placeholder"])) : _createCommentVNode("v-if", true), $data.dataAsTags.processes && $options.inputsDisabled ? (_openBlock(), _createBlock(_component_risma_tags, {
    key: 1,
    items: $data.dataAsTags.processes,
    "as-tag": true
  }, null, 8 /* PROPS */, ["items"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $options.risksTitle,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), $props.risks.items && !$options.inputsDisabled ? (_openBlock(), _createBlock(_component_risma_select, {
    key: 0,
    "enable-select-all": true,
    "label-key": $data.labelKey,
    options: $props.risks.items,
    "selected-options": $data.data.risks,
    placeholder: $options.risksPlaceholder,
    onSelected: _cache[2] || (_cache[2] = function ($event) {
      return $options.handleSelected('risks', $event);
    })
  }, null, 8 /* PROPS */, ["label-key", "options", "selected-options", "placeholder"])) : _createCommentVNode("v-if", true), $data.dataAsTags.risks && $options.inputsDisabled ? (_openBlock(), _createBlock(_component_risma_tags, {
    key: 1,
    items: $data.dataAsTags.risks,
    "as-tag": true
  }, null, 8 /* PROPS */, ["items"])) : _createCommentVNode("v-if", true)])])], 2 /* CLASS */);
}