import GroupsObserverMixin from '@/components/Molecules/VueNestable/GroupsObserverMixin';
export default {
  name: 'Placeholder',
  mixins: [GroupsObserverMixin],
  inject: ['listId', 'group'],
  props: {
    index: {
      type: Number,
      required: false,
      default: null
    },
    options: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    isDragging: function isDragging() {
      var dragItem = this.options.dragItem;
      return dragItem;
    }
  },
  methods: {
    onMouseEnter: function onMouseEnter(event) {
      if (!this.options.dragItem) return;
      this.notifyMouseEnter(this.group, event, this.listId, null);
    }
  }
};