import { UserLevels } from '@/constants/UserLevels';
import { ObjectTypes } from '@/constants/ObjectTypes';
export function calcExecutionRights(user, settings) {
  if (settings['app.module_execution_enabled'] !== 1) {
    return false;
  }
  if (!user) {
    // if user and his level not needed for calcRights
    return true;
  }
  return user.level_execution > UserLevels.NONE || user.is_admin;
}
export function calcControlsRights(user, settings) {
  var level = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  if (settings['app.module_controls_enabled'] !== 1) {
    return false;
  }
  if (!user) {
    // if user and his level not needed for calcRights
    return true;
  }
  return user.level_controls >= level || user.is_admin;
}
export function calcRiskRights(user, settings) {
  var _user$projects;
  var levelRisk = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  if (settings['app.module_risk_enabled'] !== 1 && settings['app.module_risks2_enabled'] !== 1) {
    return false;
  }
  if (!user) {
    // if user and his level not needed for calcRights
    return true;
  }
  return user.level_risk >= levelRisk || user.is_admin || ((_user$projects = user.projects) === null || _user$projects === void 0 ? void 0 : _user$projects.find(function (element) {
    return element.access_level > levelRisk && element.objType == ObjectTypes.RISK_PROJECT;
  })) !== undefined;
}
export function calcComplianceRights(user, settings) {
  var level = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  if (settings['app.module_compliance_enabled'] !== 1) {
    return false;
  }
  if (!user) {
    // if user and his level not needed for calcRights
    return true;
  }
  return user.is_admin || user.iaAccess || user.projects.find(function (element) {
    return element.access_level >= level && element.objType == ObjectTypes.COMPLIANCE_PROJECT;
  }) !== undefined;
}
export function calcIncidentsRights(user, settings) {
  var levelIncidents = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  if (settings['app.module_incidents_enabled'] !== 1) {
    return false;
  }
  if (!user) {
    // if user and his level not needed for calcRights
    return true;
  }
  return user.level_incidents >= levelIncidents || user.is_admin;
}
export function calcContractRights(user, settings) {
  var level = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  if (settings['app.module_contracts_enabled'] !== 1) {
    return false;
  }
  if (!user) {
    // if user and his level not needed for calcRights
    return true;
  }
  return user.level_contracts >= level || user.is_admin;
}
export function checkForComplianceProjects(projects) {
  for (var i = 0; i < projects.length; i++) {
    if (projects[i].access_level > UserLevels.NONE && projects[i].objType === ObjectTypes.COMPLIANCE_PROJECT) {
      return true;
    }
  }
  return false;
}