export var filterRisksByOrg = function filterRisksByOrg(risks, orgs) {
  var orgsSelectedId = orgs.map(function (org) {
    return org.id;
  });
  if (orgsSelectedId.length) {
    return risks.filter(function (risk) {
      return risk.organisationIds.some(function (orgId) {
        return orgsSelectedId.indexOf(+orgId) !== -1;
      });
    });
  } else {
    return risks;
  }
};
export var filterRisksByParentRisk = function filterRisksByParentRisk(risks, parentRisk) {
  if (parentRisk) {
    return risks.filter(function (risk) {
      return risk.id === parentRisk || risk.parentRisk && risk.parentRisk.id === parentRisk;
    });
  } else {
    return risks;
  }
};
export var filterRisksByTags = function filterRisksByTags(risks, tags) {
  var tagsSelectedId = tags.map(function (tag) {
    return tag.id;
  });
  if (tagsSelectedId.length) {
    return risks.filter(function (risk) {
      var riskTagsId = risk.tags.map(function (tag) {
        return tag.id;
      });
      return riskTagsId.some(function (riskTagId) {
        return tagsSelectedId.indexOf(riskTagId) !== -1;
      });
    });
  } else {
    return risks;
  }
};
export var searchByTitleAndId = function searchByTitleAndId(risks, query) {
  if (query) {
    var queryInLowerCase = query.toLowerCase();
    return risks.filter(function (risk) {
      var isFoundInTitle = risk.title.toLowerCase().indexOf(queryInLowerCase) !== -1;
      var idToString = risk.rno.toString();
      return idToString.indexOf(queryInLowerCase) !== -1 || isFoundInTitle;
    });
  } else {
    return risks;
  }
};