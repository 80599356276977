function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import cloneDeep from 'lodash/cloneDeep';
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useSolutionsStore } from '@/Store/solutionsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import EditProjectModal from '@/components/Molecules/Modal/EditProjectModal';
import MainWell from '@/components/Atoms/Containers/MainWell';
import StaticTable from '@/components/Molecules/StaticTable';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import * as RiskAPI from '@/api/risk/risk';
import { RiskProjects } from '@/constants/risks/RiskProjects';
import { isLockedRiskUser } from '@/utils/access';
import { setHeatmapColors } from '@/api/risk/risk';
export default {
  name: 'RiskAdminProjects',
  components: {
    EditProjectModal: EditProjectModal,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    MainWell: MainWell,
    StaticTable: StaticTable,
    NotificationPopUp: NotificationPopUp
  },
  mixins: [NotificationPopUpMixin],
  emits: ['updateHeader', 'refreshMenuWithRiskProjects'],
  data: function data() {
    return {
      riskProjects: [],
      editedProject: null,
      showEditModal: false,
      RiskProjects: RiskProjects,
      translate: getTranslate['RiskAdminProjects']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser', 'isAdmin'])), mapState(useActivityTypesStore, {
    activityTypes: 'riskActivityTypes'
  })), mapState(useSolutionsStore, ['enabledSolutions'])), {}, {
    columns: function columns() {
      return [this.translate.title, this.translate.solutionId, this.translate.defaultActivityType, this.translate.displayRelevantThreatsOnAssessments, this.translate.enableAccumulatedRisk, this.translate.enableInherentVsResidualRisk, this.translate.enableMatrixInverted];
    },
    dataset: function dataset() {
      var _this = this;
      return this.riskProjects.map(function (project) {
        var _this$enabledSolution, _this$activityTypes$f;
        var isErm = project.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type;
        return {
          title: project.title,
          solution: ((_this$enabledSolution = _this.enabledSolutions.find(function (solution) {
            return solution.id === project.solutionId;
          })) === null || _this$enabledSolution === void 0 ? void 0 : _this$enabledSolution.title) || _this.translate.notSet,
          defaultActivityType: isErm ? '' : ((_this$activityTypes$f = _this.activityTypes.find(function (activityType) {
            return activityType.id === project.defaultActivityTypeId;
          })) === null || _this$activityTypes$f === void 0 ? void 0 : _this$activityTypes$f.label) || _this.translate.notSet,
          displayRelevantThreats: isErm ? '' : project.threatsEnabled ? _this.translate.yes : _this.translate.no,
          accumulatedRisks: isErm ? '' : project.accumulatedRisks ? _this.translate.yes : _this.translate.no,
          inherentRisksEnabled: isErm ? '' : project.inherentRisksEnabled ? _this.translate.yes : _this.translate.no,
          enableMatrixInverted: isErm ? '' : project.matrixInverted ? _this.translate.yes : _this.translate.no,
          edit: project
        };
      });
    },
    isUserLocked: function isUserLocked() {
      return isLockedRiskUser(this.currentUser) || !this.isAdmin;
    }
  }),
  mounted: function mounted() {
    this.getRiskProjects();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useUserStore, {
    loadCurrentUser: 'load'
  })), {}, {
    onProjectChange: function onProjectChange(data) {
      var params = _objectSpread({
        projectId: data.id
      }, data);
      this.updateRiskProject(data.id, params);
    },
    onEditIconClick: function onEditIconClick(project) {
      this.editedProject = cloneDeep(project);
      this.toggleEditModal();
    },
    toggleEditModal: function toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    getRiskProjects: function getRiskProjects() {
      var _this2 = this;
      return RiskAPI.getRiskProjects().then(function (_ref) {
        var list = _ref.list;
        return _this2.riskProjects = list;
      });
    },
    updateRiskProject: function updateRiskProject(id, body) {
      var _this3 = this;
      if (!this.handleMatrixInverted(id, body)) {
        return;
      }
      this.toggleEditModal();
      RiskAPI.updateRiskProject(id, _objectSpread({
        projectId: id
      }, body)).then(function () {
        _this3.handleInstantNotification({
          title: _this3.translate.riskProjectUpdated
        });
        return _this3.getRiskProjects();
      }).then(function () {
        _this3.$emit('updateHeader');
        _this3.$emit('refreshMenuWithRiskProjects');
        _this3.loadCurrentUser();
      });
    },
    handleMatrixInverted: function handleMatrixInverted(id, body) {
      if (!Object.hasOwn(body, 'matrixInverted')) {
        return true;
      }
      if (!confirm(this.translate.confirmResetOfColors)) {
        return false;
      }
      setHeatmapColors(id, {
        heatmapOptions: []
      });
      return true;
    }
  })
};