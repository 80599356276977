function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { createRisk, getReports, getRiskProjects, getThreats, updateRisk } from '@/api/risk/risk';
import { getCustomField } from '@/api/admin/admin';
import { createCustomThreat as _createCustomThreat, getCustomThreats as _getCustomThreats, updateCustomThreat as _updateCustomThreat } from '@/api/risma/riskAssessments';
import { checkERMByProjectId } from '@/utils/risks/check';
import { MODULES } from '@/constants/modules';
import { RiskProjects } from '@/constants/risks/RiskProjects';
import { compareStrings } from '@/utils/compare';
import * as Utils from '@/utils/Utils';
import { sortAlphabetic } from '@/utils/sort';
export var RiskAssessmentMixin = {
  emits: ['getActivityData', 'saveStatusClicked'],
  data: function data() {
    return {
      risk: {},
      currentPeriod: null,
      threatsByRiskLabels: [],
      rtoCustomFieldOptions: [],
      rpoCustomFieldOptions: [],
      customThreats: [],
      currentRiskProject: {},
      periodSelected: null
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    rpoFieldId: function rpoFieldId() {
      return this.getSettingValue('feature.isms_rpo_field_id');
    },
    rtoFieldId: function rtoFieldId() {
      return this.getSettingValue('feature.isms_rto_field_id');
    },
    showRiskAssessment: function showRiskAssessment() {
      return !this.getSettingValue('feature.hide_ia_risk_assessments');
    },
    showRtoRpoOnAssets: function showRtoRpoOnAssets() {
      return !!this.getSettingValue('feature.show_rto_rpo_on_assets');
    },
    displayRtoRpoOptions: function displayRtoRpoOptions() {
      return this.showRtoRpoOnAssets && this.rtoOptions && this.rtoOptions.length;
    },
    rtoOptions: function rtoOptions() {
      return this.rtoCustomFieldOptions.filter(function (option) {
        return option.value;
      }).map(function (option) {
        return {
          value: option.id,
          label: option.value
        };
      });
    },
    rtoValue: function rtoValue() {
      var _this = this;
      if (!this.data.customFieldValues) return null;
      var valueObject = this.data.customFieldValues.find(function (f) {
        return +f.fieldId === +_this.rtoFieldId;
      });
      if (valueObject) return valueObject.value;
      return null;
    },
    rpoOptions: function rpoOptions() {
      return this.rpoCustomFieldOptions.filter(function (option) {
        return option.value;
      }).map(function (option) {
        return {
          value: option.id,
          label: option.value
        };
      });
    },
    rpoValue: function rpoValue() {
      var _this2 = this;
      if (!this.data.customFieldValues) return null;
      var valueObject = this.data.customFieldValues.find(function (f) {
        return +f.fieldId === +_this2.rpoFieldId;
      });
      if (valueObject) return valueObject.value;
      return null;
    },
    customThreatsEnabled: function customThreatsEnabled() {
      return !!this.getSettingValue('feature.custom_risk_assessment_threats');
    },
    threatsSortedByCategories: function threatsSortedByCategories() {
      var threats = _toConsumableArray(this.threatsByRiskLabels);
      sortAlphabetic(threats, 'title', true);
      return threats.sort(function (a, b) {
        var _a$category, _b$category;
        return !a.category ? -1 : !b.category ? 1 : compareStrings((_a$category = a.category) === null || _a$category === void 0 ? void 0 : _a$category.title, (_b$category = b.category) === null || _b$category === void 0 ? void 0 : _b$category.title);
      });
    },
    threatsWithCategoryLabel: function threatsWithCategoryLabel() {
      var threats = _toConsumableArray(this.threatsSortedByCategories);
      threats.forEach(function (threat) {
        threat.title = Utils.getThreatLabelWithCategory(threat);
      });
      return threats;
    },
    inherentRisksEnabled: function inherentRisksEnabled() {
      var _this$currentRiskProj;
      return (_this$currentRiskProj = this.currentRiskProject) === null || _this$currentRiskProj === void 0 ? void 0 : _this$currentRiskProj.inherentRisksEnabled;
    },
    isErm: function isErm() {
      return checkERMByProjectId(this.riskProjectId, this.currentUser);
    }
  }),
  methods: {
    getRisksData: function getRisksData() {
      var _this3 = this;
      var promises = [];
      if (this.riskProjectId && !this.isErm) {
        promises.push(this.getCurrentRiskProject().then(function (riskProject) {
          if (!riskProject) return Promise.resolve();
          _this3.currentRiskProject = riskProject;
          _this3.periodSelected = _this3.currentPeriod = riskProject.projectPeriods.find(function (item) {
            return item.isCurrent;
          });
          return _this3.getDataForRiskAssessments();
        }));
      }
      return Promise.all(promises).then(function () {
        return _this3.onRiskAssesmentsInit();
      });
    },
    getDataForRiskAssessments: function getDataForRiskAssessments() {
      var _this4 = this;
      var promises = [];
      if (this.data.links && this.data.links[MODULES.RISK_ASSESSMENT] && this.data.links[MODULES.RISK_ASSESSMENT][0]) {
        promises.push(this.getRiskAssessment(this.data.links[MODULES.RISK_ASSESSMENT][0]));
      }
      return Promise.all(promises).then(function () {
        var consequenceRowsLabels = _this4.currentPeriod.consequenceRows.map(function (item) {
          return item.label;
        });
        return _this4.getThreatsByRiskLabels(consequenceRowsLabels);
      }).then(this.getCustomThreats);
    },
    getCurrentRiskProject: function getCurrentRiskProject() {
      var _this5 = this;
      return getRiskProjects().then(function (_ref) {
        var list = _ref.list;
        return list.filter(function (item) {
          return +item.type !== RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type;
        }).find(function (item) {
          return +item.id === +_this5.riskProjectId;
        });
      });
    },
    getRiskAssessment: function getRiskAssessment(id) {
      var _this6 = this;
      var params = "?filters[id]=".concat(id, "&filters[underlyingrisks]=include&data[]=riskAssessmentRelation");
      return getReports(params).then(function (_ref2) {
        var list = _ref2.list;
        return _this6.risk = list.length ? list[0] : {};
      });
    },
    getThreatsByRiskLabels: function getThreatsByRiskLabels() {
      var _this7 = this;
      var labelsList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var params = labelsList.join('&label[]=');
      return getThreats('?data[]=all&label[]=' + params).then(function (_ref3) {
        var list = _ref3.list;
        return _this7.threatsByRiskLabels = list;
      });
    },
    getCustomThreats: function getCustomThreats() {
      var _this$risk,
        _this8 = this;
      if (!this.customThreatsEnabled || !((_this$risk = this.risk) !== null && _this$risk !== void 0 && _this$risk.rno)) return;
      return _getCustomThreats(this.riskProjectId, this.currentPeriod.id, this.risk.rno).then(function (_ref4) {
        var list = _ref4.list;
        return _this8.customThreats = list;
      });
    },
    onUpdateCustomThreat: function onUpdateCustomThreat(data, rowId) {
      var _this$risk2,
        _this9 = this;
      var promise = (_this$risk2 = this.risk) !== null && _this$risk2 !== void 0 && _this$risk2.rno ? Promise.resolve() : this.createRiskAssessment();
      return promise.then(function () {
        return _this9.handleUpdateCustomThreat(data, rowId);
      });
    },
    handleUpdateCustomThreat: function handleUpdateCustomThreat(data, rowId) {
      var promise = data.customThreatId ? this.updateCustomThreat : this.createCustomThreat;
      return promise(data, rowId).then(this.updateCustomThreatsList);
    },
    updateCustomThreat: function updateCustomThreat(data) {
      var updateData = {
        impact: data.impact === undefined ? data.consequence : data.impact,
        likelihood: data.likelihood === undefined ? data.probability : data.likelihood,
        relevant: +data.relevant
      };
      return _updateCustomThreat(this.riskProjectId, this.currentPeriod.id, this.risk.rno, data.customThreatId, updateData);
    },
    createCustomThreat: function createCustomThreat(data, rowId) {
      var createData = {
        riskAssessmentRowId: rowId,
        threatId: data.id,
        impact: data.impact === undefined ? data.consequence : data.impact,
        likelihood: data.likelihood === undefined ? data.probability : data.likelihood,
        relevant: +data.relevant
      };
      return _createCustomThreat(this.riskProjectId, this.currentPeriod.id, this.risk.rno, createData);
    },
    updateCustomThreatsList: function updateCustomThreatsList(threat) {
      var index = this.customThreats.findIndex(function (t) {
        return t.id === threat.id;
      });
      var customThreat = index === -1 ? this.customThreats.length : index;
      this.customThreats[customThreat] = threat;
    },
    onRiskAssesmentsInit: function onRiskAssesmentsInit() {
      var _this$data, _this$data$links$MODU;
      if ((_this$data = this.data) !== null && _this$data !== void 0 && _this$data.links && (_this$data$links$MODU = this.data.links[MODULES.RISK_ASSESSMENT]) !== null && _this$data$links$MODU !== void 0 && _this$data$links$MODU.length) {
        return;
      }
      return this.createRiskAssessment();
    },
    onRiskAssesmentsChange: function onRiskAssesmentsChange(value) {
      var _this$data$links$MODU2;
      if (this.data.links && (_this$data$links$MODU2 = this.data.links[MODULES.RISK_ASSESSMENT]) !== null && _this$data$links$MODU2 !== void 0 && _this$data$links$MODU2.length) {
        this.updateRiskAssessment(value);
        return;
      }
      this.createRiskAssessment(value);
    },
    createRiskAssessment: function createRiskAssessment(value) {
      var _this10 = this;
      this.$emit('saveStatusClicked');
      var data = _objectSpread({
        title: this.data.title,
        riskAssessmentLink: {
          module: this.module,
          id: this.data.id
        }
      }, value);
      if (this.currentPeriod) {
        return createRisk(this.riskProjectId, this.currentPeriod.id, data).then(function (risk) {
          _this10.risk = risk;
          _this10.$emit('getActivityData');
        });
      }
    },
    updateRiskAssessment: function updateRiskAssessment(value) {
      var _this11 = this;
      return updateRisk(this.riskProjectId, this.currentPeriod.id, this.risk.rno, value).then(function (risk) {
        return _this11.risk = risk;
      });
    },
    getRtoFields: function getRtoFields() {
      var _this12 = this;
      if (!this.rtoFieldId) return Promise.resolve();
      return getCustomField(this.rtoFieldId).then(function (_ref5) {
        var options = _ref5.options;
        return _this12.rtoCustomFieldOptions = Object.values(options);
      });
    },
    getRpoFields: function getRpoFields() {
      var _this13 = this;
      if (!this.rpoFieldId) return Promise.resolve();
      return getCustomField(this.rpoFieldId).then(function (_ref6) {
        var options = _ref6.options;
        return _this13.rpoCustomFieldOptions = Object.values(options);
      });
    },
    selectRecoveryValue: function selectRecoveryValue(value, id) {
      var fieldValue = {
        value: value,
        fieldId: id
      };
      var customFieldValues = this.data.customFieldValues.map(function (v) {
        if (+v.fieldId === +id) return fieldValue;
        return v;
      });
      if (!customFieldValues.find(function (item) {
        return +item.fieldId === +id;
      })) customFieldValues.push(fieldValue);
      this.$emit('propertyChanged', {
        property: 'customFieldValues',
        value: customFieldValues
      });
    }
  }
};