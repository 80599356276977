import { Colors } from '@/Colors';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'PreviewRiskCustomFields',
  components: {
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight,
    FeatherIcon: FeatherIcon
  },
  props: {
    riskFields: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    scoringFields: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      translate: {
        customRiskFields: this.$trans('Custom risk fields'),
        notSet: this.$trans('Not set')
      }
    };
  },
  computed: {
    notSetMessage: function notSetMessage() {
      return this.translate.notSet;
    },
    fields: function fields() {
      var _this = this;
      var fields = [];
      this.riskFields.forEach(function (rfield) {
        var scoringField = _this.scoringFields.find(function (sfield) {
          return sfield.id === rfield.fieldId;
        });
        if (rfield.fieldType === _this.customFieldTypes.TEXT_FIELD) {
          rfield.fieldValue = rfield.fieldValue.trim();
        }
        fields.push(Object.assign({}, rfield, {
          fieldType: scoringField.fieldtype
        }));
      });
      return fields;
    },
    customFieldTypes: function customFieldTypes() {
      return CustomFieldTypes;
    }
  },
  methods: {
    getTrafficLightColor: function getTrafficLightColor(n) {
      return Colors.getTrafficlightByNumber(n);
    },
    formatTenders: function formatTenders(value) {
      if (value === 1) {
        return 'arrow-down-right';
      } else if (value === 2) {
        return 'arrow-right';
      } else if (value === 3) {
        return 'arrow-up-right';
      }
    }
  }
};