import i18n from '@/i18n';
export var getTranslate = {
  ForgotPassword: function ForgotPassword() {
    return {
      enterEmailAddress: i18n.t('Enter email address'),
      emailAddress: i18n.t('Email address'),
      youHaveRequestedANewPasswordAnEmailWithI: i18n.t('You have requested a new password. An email with instructions have been sent to'),
      newPasswordRequested: i18n.t('New password requested'),
      returnToLoginPage: i18n.t('Return to login page'),
      forgotYourPassword: i18n.t('Forgot your password?'),
      pleaseEnterYourEmailAddressAnEmailExplaini: i18n.t('Please enter your email address. An email explaining how to reset your password will be sent to you.'),
      resetPassword: i18n.t('Reset password'),
      isAnInvalidEmailAddress: i18n.t(' is an invalid email address.'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.')
    };
  }
};