function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import cloneDeep from 'lodash/cloneDeep';
import PerformanceCard from '@/components/Molecules/Cards/PerformanceCard';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import RismaLineChart from '@/components/Molecules/Charts/RismaLineChart';
import { getNewStatusDataObject, metricData, complianceChartOptions, projectProgressChartOptions } from '@/constants/performance/dashboard';
export default {
  name: 'PerformanceDashboard',
  description: 'Dashboard page of performance module',
  components: {
    PerformanceCard: PerformanceCard,
    RismaDoughnutChart: RismaDoughnutChart,
    RismaLineChart: RismaLineChart
  },
  props: {
    performanceData: {
      required: true,
      type: Array,
      note: 'define performance data using to build doughnut chars'
    },
    performanceLineData: {
      required: true,
      type: Object,
      note: 'define performance data using to build line chart'
    },
    monthlyDailyValue: {
      required: false,
      type: String,
      default: ''
    }
  },
  emits: ['progressTypeChanged'],
  data: function data() {
    return {
      metricData: metricData,
      projectProgressChartOptions: projectProgressChartOptions,
      rawComplianceOptions: complianceChartOptions,
      lineChartStorageKey: 'performance-dashboard-line-chart',
      translate: {
        daily: this.$trans('Daily'),
        monthly: this.$trans('Monthly')
      }
    };
  },
  computed: {
    monthlyDailyOptions: function monthlyDailyOptions() {
      return [{
        name: this.translate.daily,
        value: 'daily'
      }, {
        name: this.translate.monthly,
        value: 'monthly'
      }];
    },
    complianceDatasets: function complianceDatasets() {
      return [metricData].concat(_toConsumableArray(this.performanceData));
    },
    complianceOptions: function complianceOptions() {
      var newOptions = this.rawComplianceOptions;
      newOptions.plugins.center.text = "".concat(this.averagePerformanceValue, "%");
      newOptions.plugins.center.padding = 5;
      newOptions.plugins.center.color = 'rgb(122, 214, 159)';
      return newOptions;
    },
    projectStatusDatasets: function projectStatusDatasets() {
      return this.performanceData.map(getNewStatusDataObject);
    },
    averagePerformanceValue: function averagePerformanceValue() {
      var average = this.performanceData.reduce(function (a, b) {
        return a + b.data[0];
      }, 0) / this.performanceData.length;
      return this.performanceData.length ? Math.round(average) : 0;
    },
    modifiedLineChartOptions: function modifiedLineChartOptions() {
      var _this = this;
      var optionsCopy = cloneDeep(this.projectProgressChartOptions);
      optionsCopy.plugins.tooltip = _objectSpread(_objectSpread({}, optionsCopy.plugins.tooltip), {}, {
        callbacks: _objectSpread(_objectSpread({}, optionsCopy.plugins.tooltip.callbacks), {}, {
          title: function title(value) {
            return _this.performanceLineData.labels.customLabels[value[0].dataIndex];
          },
          label: function label(value) {
            return "".concat(value.dataset.label, " ").concat(value.raw, "%");
          }
        })
      });
      return optionsCopy;
    }
  }
};