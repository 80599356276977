import RismaSelectorBar from '@/components/Molecules/RismaSelectorBar';
import { Colors } from '@/Colors';
export default {
  name: '',
  components: {
    RismaSelectorBar: RismaSelectorBar
  },
  data: function data() {
    return {
      rismaSelectorBarData: [{
        list: 'gaps',
        label: 'Gaps',
        preSelected: [{
          id: Colors.gaps[3].number,
          label: Colors.gaps[3].label,
          color: Colors.gaps[3].color
        }, {
          id: Colors.gaps[4].number,
          label: Colors.gaps[4].label,
          color: Colors.gaps[4].color
        }]
      }],
      rismaSelectorBarProjectId: 0
    };
  }
};