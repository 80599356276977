function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import VerticalMenuItem from '@/components/Atoms/VerticalMenuItem/VerticalMenuItem';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'VerticalMenu',
  introduction: 'Vertical menu',
  description: 'Vertical menu',
  token: "<vertical-menu\n    items=[{\n        groupTitle: 'Group A Title',\n        groupItems: [\n           {\n            path: '/dev/kitchensink/atoms',\n            title: 'Atoms',\n            color: '#000', // optional property\n        }, {\n            path: '/dev/kitchensink/molecules',\n            title: 'Molecules',\n            color: 'green', // optional property\n        }],\n    }, {\n        path: '/dev/kitchensink/organisms'\n        title: 'Organisms'\n    }]\n/>",
  components: {
    VerticalMenuItem: VerticalMenuItem,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle
  },
  props: {
    items: {
      type: Array,
      required: true,
      note: 'Array of objects with required fields: path, title'
    },
    selectedId: {
      type: [String, Number],
      required: false,
      default: '',
      note: 'Is used when in preview mode, to show selected item'
    },
    visibilityStorageKey: {
      type: String,
      required: false,
      default: 'vertical_menu_hidden_groups',
      note: 'local storage key to store hidden groups'
    },
    removable: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Manage removing buttons in each menu'
    },
    openedGroups: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: false,
      note: 'List of opened groups on load'
    },
    exactMatch: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Determine if router link should be exactly matched with url'
    }
  },
  emits: ['changeItem', 'removeItem'],
  setup: function setup(props) {
    return {
      closedGroups: getLocalStorageComputed(props.visibilityStorageKey, [], true)
    };
  },
  watch: {
    openedGroups: {
      deep: true,
      handler: function handler() {
        this.setGroupsStatus();
      }
    }
  },
  mounted: function mounted() {
    this.setGroupsStatus();
  },
  methods: {
    setGroupsStatus: function setGroupsStatus() {
      var _this = this;
      if (this.openedGroups.length) {
        this.closedGroups = this.items.map(function (item) {
          return item.groupTitle;
        }).filter(function (item) {
          return !_this.openedGroups.includes(item);
        });
      }
    },
    handleGroupToggle: function handleGroupToggle(groupTitle) {
      if (this.closedGroups.includes(groupTitle)) {
        this.closedGroups = this.closedGroups.filter(function (group) {
          return group !== groupTitle;
        });
        return;
      }
      this.closedGroups = [].concat(_toConsumableArray(this.closedGroups), [groupTitle]);
    },
    isGroupClosed: function isGroupClosed(groupTitle) {
      return this.closedGroups.includes(groupTitle);
    },
    changeItem: function changeItem(id) {
      this.$emit('changeItem', id);
    },
    removeItem: function removeItem(id) {
      this.$emit('removeItem', id);
    }
  }
};