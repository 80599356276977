function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import { patchUtilSettings as _patchUtilSettings } from '@/api/risma/settings';
import { isValidString, isDomainValid, isEndpointValid } from '@/utils/validations';
export default {
  name: 'AdSettings',
  components: {
    RismaTitle: RismaTitle,
    InputField: InputField,
    RismaButton: RismaButton,
    Notification: Notification
  },
  data: function data() {
    return {
      domain: '',
      endpoint: '',
      clientId: '',
      clientSecret: '',
      appOidcEmail: '',
      errors: [],
      ignoreAdPermissions: true,
      adAuthenticationMode: false,
      isSubmitBtnDisabled: false,
      isSubmitSuccessNotificationShown: false,
      data: {},
      dataUpdate: {},
      translate: getTranslate['AdSettings']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isPreferredEndpoint: function isPreferredEndpoint() {
      // eslint-disable-next-line no-useless-escape
      var regExp = /^(https:\/\/sts.windows.net\/)[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\//;
      return regExp.test(this.endpoint);
    },
    isClientSecretValid: function isClientSecretValid() {
      var isValid = true;
      var regExp = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
      if (this.clientSecret.trim() !== '') {
        isValid = !regExp.test(this.clientSecret);
      }
      return isValid;
    }
  }),
  mounted: function mounted() {
    this.init();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useSettingsStore, {
    loadSettings: 'load'
  })), {}, {
    init: function init() {
      this.dataUpdate = {};
      this.domain = this.getSettingValue('app.oidc.domainname');
      this.endpoint = this.getSettingValue('app.oidc.endpoint');
      this.clientId = this.getSettingValue('app.oidc.client_id');
      this.appOidcEmail = this.getSettingValue('app.oidc.email') || 'upn';
      this.ignoreAdPermissions = !!this.getSettingValue('app.ignore_ad_permissions');
      this.adAuthenticationMode = this.getSettingValue('app.authentication_mode') === 'oidc';
      this.data = {
        adAuthenticationMode: {
          value: this.getSettingValue('app.authentication_mode') === 'oidc',
          propName: 'app.authentication_mode'
        },
        ignoreAdPermissions: {
          value: !!this.getSettingValue('app.ignore_ad_permissions'),
          propName: 'app.ignore_ad_permissions'
        },
        domain: {
          value: this.getSettingValue('app.oidc.domainname'),
          checkFn: function checkFn(domain) {
            return isDomainValid(domain);
          },
          error: 'Enter a valid domain',
          propName: 'app.oidc.domainname'
        },
        endpoint: {
          value: this.getSettingValue('app.oidc.endpoint'),
          checkFn: function checkFn(endpoint) {
            return isEndpointValid(endpoint);
          },
          error: 'Enter a valid endpoint',
          propName: 'app.oidc.endpoint'
        },
        clientId: {
          value: this.getSettingValue('app.oidc.client_id'),
          error: 'Enter a valid client id',
          checkFn: function checkFn(value) {
            return value.trim().length > 0;
          },
          propName: 'app.oidc.client_id'
        },
        clientSecret: {
          value: '',
          error: 'Enter a valid client secret',
          checkFn: function checkFn(value) {
            return value.trim().length > 0;
          },
          propName: 'app.oidc.client_secret'
        },
        appOidcEmail: {
          value: this.getSettingValue('app.oidc.email') || 'upn',
          checkFn: function checkFn(value) {
            return isValidString(value);
          },
          error: 'Enter a valid e-mail mapping',
          propName: 'app.oidc.email'
        }
      };
    },
    update: function update() {
      var _this = this;
      var data = {};
      this.errors = [];
      this.isSubmitBtnDisabled = true;
      this.isSubmitSuccessNotificationShown = false;
      Object.keys(this.dataUpdate).forEach(function (prop) {
        var propObjData = _this.data[prop];
        if (propObjData.checkFn && !propObjData.checkFn(_this.dataUpdate[prop])) {
          _this.errors.push(_this.$trans("".concat(propObjData.error)));
        }
        data[_this.data[prop].propName] = _this.dataUpdate[prop];
      });
      if (this.errors.length) {
        this.isSubmitBtnDisabled = false;
        return;
      }
      this.patchUtilSettings(data).then(function () {
        return _this.loadSettings();
      }).then(function () {
        return _this.isSubmitSuccessNotificationShown = true;
      }).catch(function () {
        return _this.init();
      }).finally(function () {
        return _this.isSubmitBtnDisabled = false;
      });
    },
    patchUtilSettings: function patchUtilSettings(data) {
      return new Promise(function (resolve, reject) {
        _patchUtilSettings(data).then(function (response) {
          return resolve(response);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    onUseSecurityChange: function onUseSecurityChange(event) {
      var checked = event.target.checked;
      this.ignoreAdPermissions = !checked;
      this.handleChange('ignoreAdPermissions', this.ignoreAdPermissions);
    },
    handleChange: function handleChange(propName, value) {
      var isPropExisted = !!this.dataUpdate[propName];
      var isPropChanged = !(this.data[propName] === value);
      if (isPropChanged) {
        this.dataUpdate[propName] = value;
      } else if (!isPropChanged && isPropExisted) {
        delete this.dataUpdate[propName];
      }
    }
  })
};