import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_radio_buttons = _resolveComponent("radio-buttons");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_radio_buttons, {
    modelValue: $data.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.value = $event;
    }),
    options: $data.options,
    onUpdated: $options.handleChange
  }, null, 8 /* PROPS */, ["modelValue", "options", "onUpdated"]), _createElementVNode("p", null, "V-model value: " + _toDisplayString($data.value), 1 /* TEXT */)]);
}