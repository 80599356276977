import TrafficLightSelector from '@/components/Organisms/Selectors/TrafficLightSelector';
export default {
  components: {
    TrafficLightSelector: TrafficLightSelector
  },
  data: function data() {
    return {
      values: [0, 0]
    };
  }
};