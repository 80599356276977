function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import Notification from '@/components/Molecules/Notification';
import { isValidAsUrlAndLocalPath } from '@/utils/validations';
export default {
  name: 'EditAttachment',
  description: 'Component for editing contracts documents',
  token: '<edit-attachment/>',
  components: {
    Modal: Modal,
    RismaTitle: RismaTitle,
    InputField: InputField,
    Notification: Notification,
    RismaSelect: RismaSelect
  },
  props: {
    editedFileData: {
      type: Object,
      required: false,
      default: null,
      note: 'Files data'
    },
    typeOptions: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: false,
      note: 'The list of options for file types if any'
    },
    fileTypesEnabled: {
      type: Boolean,
      required: false,
      default: true,
      note: 'show select with file types'
    }
  },
  emits: ['editFile', 'dismissEdit'],
  data: function data() {
    return {
      editedFile: {},
      isValid: true,
      selectedDocTypes: [],
      translate: getTranslate['EditAttachment']()
    };
  },
  computed: {
    isLink: function isLink() {
      if (!this.editedFileData.url) {
        return false;
      }
      return this.editedFileData.url.startsWith('http') || !this.editedFileData.url.endsWith('?download');
    },
    editModalHeader: function editModalHeader() {
      return !this.isLink ? 'Edit attachment' : 'Edit link';
    },
    errorMessage: function errorMessage() {
      return this.isLink ? this.translate.pleaseFillLinkToFieldsCorrectly : this.translate.titleCannotBeEmpty;
    }
  },
  mounted: function mounted() {
    var _this$editedFile;
    this.editedFile = _objectSpread({}, this.editedFileData);
    if ((_this$editedFile = this.editedFile) !== null && _this$editedFile !== void 0 && (_this$editedFile = _this$editedFile.docTypeIds) !== null && _this$editedFile !== void 0 && _this$editedFile.length) {
      this.generateSelectedDocTypes(this.editedFile.docTypeIds);
    }
  },
  methods: {
    editFile: function editFile() {
      if (this.isValidForm()) {
        this.$emit('editFile', this.editedFile);
      }
    },
    dismissEdit: function dismissEdit() {
      this.$emit('dismissEdit');
    },
    generateSelectedDocTypes: function generateSelectedDocTypes(ids) {
      var _this = this;
      this.selectedDocTypes = ids.map(function (item) {
        var docType = _this.typeOptions.find(function (type) {
          return +type.id === +item;
        });
        return {
          id: docType.id,
          label: docType.label
        };
      });
    },
    selectDocTypes: function selectDocTypes(values) {
      this.selectedDocTypes = values;
      this.editedFile.docTypeIds = values.map(function (item) {
        return item.id;
      });
    },
    isValidForm: function isValidForm() {
      this.isValid = this.isLink ? isValidAsUrlAndLocalPath(this.editedFile.url) : this.editedFile.title;
      return this.isValid;
    }
  }
};