export default {
  name: 'RismaToggle',
  introduction: 'A toggle button',
  description: 'risma-toggle is a toggle button that either returns true or false in a "toggle"-event',
  token: '<risma-toggle />',
  props: {
    modelValue: {
      type: [Boolean, Number],
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['toggle', 'input', 'update:modelValue'],
  computed: {
    checkedValue: {
      get: function get() {
        return !!this.modelValue;
      },
      set: function set(value) {
        if (this.disabled) return;
        var params = {
          target: this.$el,
          value: value
        };
        this.$emit('update:modelValue', value);
        this.$emit('toggle', params);
        this.$emit('input', value);
      }
    }
  }
};