var _excluded = ["id"];
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState, mapActions } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUserStore } from '@/Store/userStore';
import { useSolutionsStore } from '@/Store/solutionsStore';
import { getAllGapSchemas } from '@/api/compliance/gapschema';
import { getAllQuestionnaires } from '@/api/compliance/questionnaire';
import { getAll, updateProject as _updateProject } from '@/api/compliance/projects';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import MainWell from '@/components/Atoms/Containers/MainWell';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import EditComplianceProjectModal from '@/components/Molecules/Modal/EditComplianceProjectModal';
import StaticTable from '@/components/Molecules/StaticTable';
import { ProjectsTab } from '@/constants/compliance/Admin';
import * as Utils from '@/utils/Utils';
import { getTranslate } from './translate';
import { getProjectDescriptionLabel, getProjectPurposeLabel, getProjectStatusLabel } from '@/utils/Compliance/complianceProject';
export default {
  name: 'ProjectsAdmin',
  introduction: 'Compliance Project Admin',
  description: 'This component renders the table with compliance projects list',
  token: '<projects-admin />',
  components: {
    EditComplianceProjectModal: EditComplianceProjectModal,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    NotificationPopUp: NotificationPopUp,
    StaticTable: StaticTable,
    MainWell: MainWell
  },
  data: function data() {
    return {
      questionnaires: {},
      gapSchemas: {},
      projects: [],
      projectToUpdate: {},
      showEditModal: false,
      notificationMessage: '',
      notificationType: 'success',
      translate: getTranslate['ProjectsAdmin']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useActivityTypesStore, {
    activityTypes: 'complianceActivityTypes'
  })), mapState(useSolutionsStore, {
    solutions: 'enabledSolutions'
  })), {}, {
    columnsStyles: function columnsStyles() {
      return {
        gapHeatmaps: 'width: 10%;',
        recommendedQuestions: 'width: 10%;',
        enableGapRiskModule: 'width: 10%'
      };
    },
    columns: function columns() {
      return [this.translate.title, this.translate.questionnaire, this.translate.gapSchemaName, this.translate.solutionId, this.translate.activityType, this.translate.overallDescriptionTitle, this.translate.purposeTitle, this.translate.processStatusTitle, this.translate.enableRecommendedActivitiesForGaps, this.translate.enableHeatMapsInGapSchema, this.translate.enableGapTypes, this.translate.riskPrGap, ''];
    },
    dataset: function dataset() {
      var _this = this;
      return this.projects.map(function (project) {
        return {
          title: project.title,
          questionnaire: _this.getQuestionnaireByProjectQuestionnaireType(project),
          gapSchemaName: Utils.findValueById(_this.gapSchemas[project.type] || [], project.defaultGapSchemaId, 'label'),
          solutionId: Utils.findPropsByIds(project.solutionIds, _this.solutionIds, 'label'),
          activityTypes: Utils.findValueById(_this.activityTypes, project.defaultActivityTypeId, 'label'),
          descriptionLabel: getProjectDescriptionLabel(project),
          purposeLabel: getProjectPurposeLabel(project),
          statusLabel: getProjectStatusLabel(project),
          recommendedQuestions: project.recommended_questions ? _this.translate.activated : _this.translate.deactivated,
          gapHeatmaps: project.gap_heatmaps ? _this.translate.activated : _this.translate.deactivated,
          showGapArticleTypes: project.showGapArticleTypes ? _this.translate.activated : _this.translate.deactivated,
          enableGapRiskModule: project.enableGapRiskModule ? _this.translate.activated : _this.translate.deactivated,
          edit: project
        };
      });
    },
    solutionIds: function solutionIds() {
      return this.solutions.map(function (solution) {
        return {
          value: solution.id,
          label: solution.title,
          id: solution.id
        };
      });
    },
    questionnairesOptions: function questionnairesOptions() {
      return this.questionnaires[this.projectToUpdate[ProjectsTab.GUESTIONNAIRETYPE]] || [];
    },
    gapSchemasOptions: function gapSchemasOptions() {
      return this.gapSchemas[this.projectToUpdate.type] || [];
    }
  }),
  mounted: function mounted() {
    var _this2 = this;
    Promise.all([getAllQuestionnaires(), getAllGapSchemas(), getAll(true, 'data[]=defaultQuestionnaireId&data[]=defaultGapSchemaId&data[]=defaultActivityTypeId')]).then(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 3),
        questionnaires = _ref2[0],
        gapSchemas = _ref2[1],
        projects = _ref2[2];
      _this2.questionnaires = _this2.createHashForOptions(questionnaires.list, ProjectsTab.GUESTIONNAIRETYPE);
      _this2.gapSchemas = _this2.createHashForOptions(gapSchemas.list, ProjectsTab.GAPSCHEMATYPE);
      _this2.projects = projects.list;
    });
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useUserStore, {
    loadCurrentUser: 'load'
  })), {}, {
    createHashForOptions: function createHashForOptions(array, hashKey) {
      var hashObj = {};
      array.forEach(function (item) {
        if (!item.selectable) return;
        var type = item[hashKey];
        var option = {
          value: item.id,
          label: item.title,
          id: item.id
        };
        if (hashObj[type]) {
          return hashObj[type].push(option);
        }
        return hashObj[type] = [option];
      });
      return hashObj;
    },
    openEditModal: function openEditModal(project) {
      this.setProjectToUpdate(project);
      this.toggleEditModal();
    },
    dismissEditModal: function dismissEditModal() {
      this.projectToUpdate = {};
      this.toggleEditModal();
    },
    updateProject: function updateProject(project) {
      var _this3 = this;
      this.toggleEditModal();
      var _this$prepareProjectT = this.prepareProjectToSave(project),
        id = _this$prepareProjectT.id,
        dataToSave = _objectWithoutProperties(_this$prepareProjectT, _excluded);
      return _updateProject(id, dataToSave).then(function (updatedProject) {
        var idx = _this3.projects.findIndex(function (p) {
          return +p.id === +updatedProject.id;
        });
        if (idx === -1) return;
        _this3.projects[idx] = updatedProject;
        _this3.loadCurrentUser();
        _this3.setNotification(_this3.translate.settingsAreSaved, 'success');
      });
    },
    toggleEditModal: function toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    prepareProjectToSave: function prepareProjectToSave(project) {
      return {
        id: project.id,
        title: project.title,
        defaultQuestionnaireId: project.questionnaireId,
        defaultGapSchemaId: project.gapSchemaId,
        solutionIds: project.solutionIds,
        defaultActivityTypeId: project.activityTypeId,
        descriptionLabel: project.descriptionLabel,
        purposeLabel: project.purposeLabel,
        statusLabel: project.statusLabel,
        recommended_questions: project.recommendedQuestions ? 1 : 0,
        gap_heatmaps: project.gapHeatmaps ? 1 : 0,
        showGapArticleTypes: project.showGapArticleTypes,
        enableGapRiskModule: project.enableGapRiskModule ? 1 : 0,
        showRiskAssessment: project.enableGapRiskModule ? 1 : 0
      };
    },
    setProjectToUpdate: function setProjectToUpdate(project) {
      this.projectToUpdate = _defineProperty({
        id: project.id,
        title: project.title,
        questionnaireId: project.defaultQuestionnaireId,
        gapSchemaId: project.defaultGapSchemaId,
        solutionIds: project.solutionIds,
        activityTypeId: project.defaultActivityTypeId,
        descriptionLabel: getProjectDescriptionLabel(project),
        purposeLabel: getProjectPurposeLabel(project),
        statusLabel: getProjectStatusLabel(project),
        recommendedQuestions: project.recommended_questions,
        gapHeatmaps: project.gap_heatmaps,
        showGapArticleTypes: project.showGapArticleTypes,
        enableGapRiskModule: project.enableGapRiskModule,
        type: project.type
      }, ProjectsTab.GUESTIONNAIRETYPE, project[ProjectsTab.GUESTIONNAIRETYPE]);
    },
    setNotification: function setNotification(message, type) {
      this.notificationMessage = message;
      this.notificationType = type;
    },
    getQuestionnaireByProjectQuestionnaireType: function getQuestionnaireByProjectQuestionnaireType(project) {
      if (!this.questionnaires[project[ProjectsTab.GUESTIONNAIRETYPE]]) {
        return '';
      }
      return Utils.findValueById(this.questionnaires[project[ProjectsTab.GUESTIONNAIRETYPE]], project.defaultQuestionnaireId, 'label');
    }
  })
};