function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import { getCurrentUser } from '@/api/risma/data';
import { updateUser } from '@/api/admin/user';
import { UserNotificationSettings } from '@/constants/UserNotificationSettings';
export default {
  name: 'UserSettingsNotifications',
  components: {
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    Notification: Notification
  },
  introduction: 'Current user settings of notifications',
  description: 'Current user settings of notifications',
  token: '<user-settings-notifications />',
  data: function data() {
    return {
      saveFail: false,
      saveSuccess: false,
      user: {},
      isUserLoaded: false,
      translate: getTranslate['UserSettingsNotifications']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    notificationSettings: function notificationSettings() {
      return UserNotificationSettings(this.getSettingValue);
    }
  }),
  mounted: function mounted() {
    var _this = this;
    getCurrentUser(true).then(function (user) {
      _this.user = user;
      _this.notifications = _this.enhanceNotifications(_this.notificationSettings, user.notifications);
      _this.isUserLoaded = true;
    });
  },
  methods: {
    hideAllNotifications: function hideAllNotifications() {
      this.saveFail = false;
      this.saveSuccess = false;
    },
    enhanceNotifications: function enhanceNotifications(notifications, userNotifications) {
      return notifications.map(function (notification) {
        var isSet = userNotifications.find(function (id) {
          return id === notification.id;
        });
        return _objectSpread(_objectSpread({}, notification), {}, {
          isSet: !!isSet
        });
      });
    },
    save: function save() {
      var _this2 = this;
      this.hideAllNotifications();
      var notifications = this.notifications.filter(function (n) {
        return n.isSet;
      }).map(function (n) {
        return n.id;
      });
      updateUser({
        notifications: notifications
      }, this.currentUser.id).then(function (result) {
        _this2.saveSuccess = true;
        _this2.notifications = _this2.enhanceNotifications(_this2.notificationSettings, result.notifications);
      }).catch(function () {
        return _this2.saveFail = true;
      });
    }
  }
};