import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
export default {
  name: 'SuggestedActivitiesCell',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    TinyMceBox: TinyMceBox
  },
  props: {
    activities: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['update', 'delete'],
  methods: {
    handleDescriptionChange: function handleDescriptionChange(newValue, oldValue, activityId) {
      if (newValue === oldValue) {
        return;
      }
      this.$emit('update', newValue, activityId, 'activity_description');
    }
  }
};