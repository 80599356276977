import { MenuKeys } from '@/constants/menu/MenuKeys';
import { UserLevels } from '@/constants/UserLevels';
function buildActionsMenu(user) {
  var menuStructure = {
    main: [],
    admin: []
  };
  menuStructure.main.push({
    url: '/actions/actions',
    title: 'Overview',
    rights: user.level_execution >= UserLevels.PRIVILEGED || user.is_admin,
    menuKey: MenuKeys.initiatives
  });
  menuStructure.main.push({
    url: '/actions/my',
    title: 'My actions',
    rights: true,
    menuKey: MenuKeys.myInitiatives
  });
  menuStructure.main.push({
    url: '/actions/reports',
    title: 'Main report',
    rights: true
  });
  menuStructure.main.push({
    url: '/execution/report',
    title: 'Gantt/roadmap report',
    rights: true,
    menuKey: MenuKeys.reports
  });
  menuStructure.main.push({
    url: '/actions/dashboard',
    title: 'Dashboards',
    rights: true
  });
  return menuStructure;
}
export default buildActionsMenu;