import CardCompliance from '@/components/Organisms/General/Cards/CardCompliance';
export default {
  name: '',
  components: {
    CardCompliance: CardCompliance
  },
  data: function data() {
    return {
      cardComplianceActivity: {
        accesslevel: 'user is admin',
        activity_type: 0,
        checkaccess: true,
        children: [],
        datacontroller: null,
        description: '',
        gapSchemaId: 1,
        id: 9,
        objType: 'compliance-node',
        orgs: ['61'],
        purpose: '',
        questionnaireId: 4,
        responsible: ['1'],
        status: '',
        title: 'subsub 3',
        traffic_light: 0,
        url: '/compliance/edit/9',
        values: {
          gap_records_answered: 4,
          gap_records_count: 55,
          percentage_gap_answered: 10,
          percentage_questions_answered: 60,
          questions_answered: 6,
          questions_answers_calculated: '6',
          questions_answers_pr_headline: 'a:11:{i:0;a:3:{s:4:"area";s:17:"Basic information";s:8:"answered";i:0;s:10:"unanswered";i:0;}i:1;a:3:{s:4:"area";s:11:"Legal basis";s:8:"answered";i:0;s:10:"unanswered";i:0;}i:2;a:3:{s:4:"area";s:18:"General principles";s:8:"answered";i:0;s:10:"unanswered";i:0;}i:3;a:3:{s:4:"area";s:12:"Transparency";s:8:"answered";i:0;s:10:"unanswered";i:0;}i:4;a:3:{s:4:"area";s:22:"Collection and sharing";s:8:"answered";i:0;s:10:"unanswered";i:0;}i:5;a:3:{s:4:"area";s:23:"International transfers";s:8:"answered";i:0;s:10:"unanswered";i:0;}i:6;a:3:{s:4:"area";s:15:"Data processors";s:8:"answered";i:0;s:10:"unanswered";i:0;}i:7;a:3:{s:4:"area";s:13:"Data security";s:8:"answered";i:0;s:10:"unanswered";i:0;}i:8;a:3:{s:4:"area";s:33:"Data Protection Impact Assessment";s:8:"answered";i:0;s:10:"unanswered";i:0;}i:9;a:3:{s:4:"area";s:7:"Testing";s:8:"answered";i:4;s:10:"unanswered";i:3;}i:10;a:3:{s:4:"area";s:10:"IA Testing";s:8:"answered";i:2;s:10:"unanswered";i:1;}}',
          questions_count: 10,
          questions_count_calculated: '10'
        },
        project: {
          title: 'project title'
        }
      }
    };
  }
};