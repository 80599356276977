export default {
  name: 'RismaLabel',
  introduction: 'Label, RISMA style',
  description: 'The risma-label component is used to make labels, usually for selects',
  token: '<risma-label title="Label for XXX"/>',
  props: {
    title: {
      required: true,
      type: String,
      note: 'The text for the label'
    }
  }
};