import i18n from '@/i18n';
export var getTranslate = {
  gap: function gap() {
    return {
      searchInGaps: i18n.t('Search in Gaps'),
      hereYouCanSearchForGapsByTheGap: i18n.t('Here you can search for gaps by the gap'),
      gaps: i18n.t('Gaps'),
      pressEnterToRemove: i18n.t('Press enter to remove'),
      selected: i18n.t('Selected'),
      pressEnterToSelect: i18n.t('Press enter to select'),
      print: i18n.t('Print'),
      process: i18n.t('Process'),
      id: i18n.t('ID'),
      domain: i18n.t('Domain'),
      parentProcess: i18n.t('Parent process'),
      trafficLight: i18n.t('Traffic light'),
      headline: i18n.t('Headline'),
      subHeadline: i18n.t('Sub headline'),
      analysis: i18n.t('Analysis'),
      riskAssessment: i18n.t('Risk assessment'),
      linkedControls: i18n.t('Linked controls'),
      linkedInitiatives: i18n.t('Linked initiatives'),
      linkedRisks: i18n.t('Linked risks'),
      gapId: i18n.t('Gap id'),
      gapSchemaName: i18n.t('Gap schema name'),
      organisation: i18n.t('Organisation'),
      requirement: i18n.t('Requirement'),
      article: i18n.t('Article'),
      impactBefore: i18n.t('Impact before'),
      likelihoodBefore: i18n.t('Likelihood before'),
      impactAfter: i18n.t('Impact after'),
      likelihoodAfter: i18n.t('Likelihood after'),
      rpi: i18n.t('RPI'),
      before: i18n.t('before'),
      after: i18n.t('after'),
      areYouSureYouWantToExport: i18n.t('Are you sure you want to export'),
      rowsToExcel: i18n.t('rows to excel'),
      areYouSureYouWantToPrint: i18n.t('Are you sure you want to print'),
      rows: i18n.t('rows'),
      tags: i18n.t('Tags'),
      errorOccurredDuringSearchPleaseDoublecheck: i18n.t('Error occurred during search. Please double-check your search terms.'),
      selectGapType: i18n.t('Select gap type'),
      selectGapTypes: i18n.t('Select gap types'),
      gapType: i18n.t('Gap type'),
      gapTypes: i18n.t('Gap types'),
      attributes: i18n.t('Attributes')
    };
  },
  GapResults: function GapResults() {
    return {
      gapInformation: i18n.t('Gap information'),
      likelihood: i18n.t('Likelihood'),
      beforeafter: i18n.t('Before & After'),
      impact: i18n.t('Impact'),
      rpi: i18n.t('RPI')
    };
  }
};