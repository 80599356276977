import * as utils from './utils';
import { ChartMixin } from '@/components/Molecules/Charts/ChartMixin';
export default {
  name: 'PieChart',
  introduction: 'Simple component for PieChart graph rendering.',
  description: "It uses window.AmCharts. A lot of not required props.\n    A component to display a PieChart with AmCharts. https://docs.amcharts.com/3/javascriptcharts/AmPieChart",
  token: "\n<pie-chart\n    title-field=\"category\"\n    value-field=\"column-1\"\n    data-provider=\"chart.data\"\n    colors=\"chart.colors\">\n</pie-chart>\n\n// Chart data looks like\nconst chart = {\n    data: [\n        {\n            'category': 'category 1',\n            'column-1': 8\n        },\n        {\n            'category': 'category 2',\n            'column-1': 6\n        },\n        {\n            'category': 'category 3',\n            'column-1': 2\n        }\n    ],\n    colors: ['#fff', '#000', '#ccc'],\n},\n",
  mixins: [ChartMixin],
  props: {
    domId: {
      type: String,
      default: null,
      note: 'The CSS ID of the chart'
    },
    dataProvider: {
      type: Array,
      required: true,
      note: 'The data provided to draw the pie slices'
    },
    colors: {
      type: Array,
      required: true,
      note: 'The colors for the slices. The colors will be applied in the order as the dataProvider array'
    },
    width: {
      type: String,
      default: '100%',
      note: 'The width of the element'
    },
    height: {
      type: String,
      default: '350px',
      note: 'The height of the element'
    },
    css: {
      type: Object,
      default: function _default() {
        return {};
      },
      required: false,
      note: 'If this prop is applied the values will be applied to the element in a key: value order'
    },
    valueField: {
      type: String,
      default: 'value',
      note: 'The value field in the dataProvider'
    },
    titleField: {
      type: String,
      default: 'title',
      note: 'The title field in the dataProvider'
    },
    labelText: {
      type: String,
      default: '[[value]]',
      note: 'The field that will be displayed as the label text'
    },
    exportEnabled: {
      type: Boolean,
      default: true,
      note: 'Should it be posible to export the chart'
    },
    radius: {
      type: String,
      default: '50%',
      note: 'The radius of the chart'
    },
    innerRadius: {
      type: String,
      default: '35%',
      note: 'The inner radius of the chart'
    },
    sliceTextColor: {
      type: String,
      default: 'white',
      note: 'The color of the text of each slice'
    },
    sliceTextSize: {
      type: Number,
      default: 16,
      note: 'The font size of the text of each slice'
    },
    legendWidth: {
      type: String,
      default: '100%',
      note: 'Width of legend'
    },
    clickableLegend: {
      type: Boolean,
      default: false,
      note: 'Should the legend be clickable?'
    },
    balloonEnabled: {
      type: Boolean,
      default: true,
      note: 'Use this property to disable balloons for certain value axes.'
    },
    pullOutRadius: {
      type: [String, Number],
      default: 0,
      note: 'Pull out radius, in pixels or percents'
    },
    legendPosition: {
      type: String,
      default: 'bottom',
      note: 'Position of legend'
    },
    legendValueText: {
      type: String,
      default: 'close',
      note: 'Field for value to display in legend'
    },
    legendEnabled: {
      type: Boolean,
      default: true,
      note: 'Should the legend be shown'
    },
    exportFields: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Define which columns should be exported'
    }
  },
  emits: ['clickSlice'],
  data: function data() {
    return {
      chart: null
    };
  },
  computed: {
    elementId: function elementId() {
      return this.domId || utils.randomDomId();
    },
    cssStyles: function cssStyles() {
      var _this = this;
      var obj = {
        width: this.width,
        height: this.height
      };
      this.css && Object.keys(this.css).forEach(function (key) {
        return obj[key] = _this.css[key];
      });
      return obj;
    },
    columnsToExport: function columnsToExport() {
      return this.exportFields.length && this.exportFields || this.dataProvider.length && Object.keys(this.dataProvider[0]) || [];
    }
  },
  watch: {
    dataProvider: {
      deep: true,
      handler: function handler() {
        if (this.chart) {
          this.chart.dataProvider = this.dataProvider;
          this.chart.validateData();
          this.dropExistingAnnotations();
        }
      }
    },
    colors: {
      deep: true,
      handler: function handler() {
        if (this.chart) {
          this.chart.colors = this.colors;
          this.chart.validateData();
        }
      }
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.chart = window.AmCharts.makeChart(this.elementId, {
      type: 'pie',
      language: utils.getLanguage(),
      colors: this.colors,
      dataProvider: this.dataProvider,
      labelRadius: -25,
      color: this.sliceTextColor,
      fontSize: this.sliceTextSize,
      labelText: this.labelText,
      titleField: this.titleField,
      valueField: this.valueField,
      radius: this.radius,
      innerRadius: this.innerRadius,
      balloon: {
        enabled: this.balloonEnabled
      },
      addClassName: true,
      legend: {
        enabled: this.legendEnabled,
        fontSize: 11,
        align: 'center',
        autoMargins: true,
        valueText: "[[".concat(this.legendValueText, "]]"),
        labelText: '[[title]]',
        equalWidths: true,
        position: this.legendPosition,
        width: this.legendWidth,
        switchable: this.clickableLegend
      },
      pullOutRadius: this.pullOutRadius,
      startDuration: 0,
      export: {
        enabled: this.exportEnabled,
        fileName: utils.filename,
        exportFields: this.columnsToExport,
        menuReviver: function menuReviver(item, li) {
          return utils.disableExportOptions(item, li);
        }
      },
      numberFormatter: {
        thousandsSeparator: ''
      },
      listeners: [{
        event: 'clickSlice',
        /**
         * takes the graph item click and checks that it's not a legend item, before emitting the items dataItem
         * @param e
         */
        method: function method(e) {
          var DOMElement = _this2.getClosest(e.event.target, '.amcharts-legend-div');
          if (DOMElement === null) {
            _this2.$emit('clickSlice', {
              item: e.dataItem
            });
          }
        }
      }]
    });
  },
  methods: {
    //polyfill doesn't want to work, local implementation:
    getClosest: function getClosest(el, s) {
      {
        if (!document.documentElement.contains(el)) {
          return null;
        }
        do {
          if (el.matches(s)) {
            return el;
          }
          el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
      }
    }
  }
};