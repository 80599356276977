import Http from '@/api/index';
import * as HttpUtils from '@/api/httpUtils';
var promises = {};
var cacheTTL = 10000;

/**
 * Fetch data from a GET url given as param
 *
 * @param url
 * @param cacheTTLparm - cache timeout in seconds
 * @returns {*}
 */
export function getPromiseWithCacheTimeout(url, cacheTTLparm) {
  if (!promises[url]) {
    promises[url] = Http.get(url).then(function (response) {
      return HttpUtils.checkHttpStatus(response);
    }).then(function (response) {
      return response.data;
    }).catch(function (err) {
      return HttpUtils.throwError(err);
    });
    setTimeout(function () {
      promises[url] = null;
    }, cacheTTLparm * 1000);
  }
  return promises[url].then(function (response) {
    return JSON.parse(JSON.stringify(response));
  });
}
export function getPromise(url) {
  return getPromiseWithCacheTimeout(url, cacheTTL / 1000);
}
export function flushCache(url) {
  promises[url] = null;
}