var _QuestionnaireTypesAu;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { ObjectTypes } from '@/constants/ObjectTypes';
export var QuestionnaireTypes = {
  GDPR: 1,
  ISO27001: 2,
  ISO9001: 3,
  AML: 4,
  ABC: 5,
  PATENT: 6,
  GENERIC: 7,
  OUTSOURCING: 11,
  DATACONTROLLER: 101,
  DATACONTROLLER_AUDIT: 109,
  DATAPROCESSOR: 102,
  SYSTEM: 103,
  SYSTEM_AUDIT: 108,
  SUBPROCESSOR: 104,
  DPM: 105,
  IA_CUSTOM: 106,
  IAAUDIT: 110
};
export var QuestionnaireTypesAudit = (_QuestionnaireTypesAu = {}, _defineProperty(_QuestionnaireTypesAu, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, 109), _defineProperty(_QuestionnaireTypesAu, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, 105), _defineProperty(_QuestionnaireTypesAu, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, 108), _defineProperty(_QuestionnaireTypesAu, ObjectTypes.CONTRACT, 107), _defineProperty(_QuestionnaireTypesAu, ObjectTypes.INFORMATION_ASSET, 110), _QuestionnaireTypesAu);