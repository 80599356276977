import i18n from '@/i18n';
export var getTranslate = {
  MiniTreeFilters: function MiniTreeFilters() {
    return {
      title: i18n.t('Title'),
      organisations: i18n.t('Organisations'),
      noFiltersApplied: i18n.t('No filters applied'),
      activityTypes: i18n.t('Activity types'),
      tags: i18n.t('Tags'),
      applyFilters: i18n.t('Apply filters'),
      titlePressEnterToSearch: i18n.t('Title (press enter to search)'),
      reset: i18n.t('Reset')
    };
  },
  MiniTreeExpandedCard: function MiniTreeExpandedCard() {
    return {
      subNodes: i18n.t('Sub nodes'),
      gapInclSubnodes: i18n.t('Gap incl. subnodes'),
      gaps: i18n.t('Gaps'),
      selectedNode: i18n.t('Selected node'),
      addSubNode: i18n.t('Add sub node')
    };
  }
};