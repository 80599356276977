import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'LoadingIndicatorLarge',
  components: {
    FeatherIcon: FeatherIcon
  },
  introduction: 'Loading indicator for page loading',
  description: 'This loading indicator is design to use while waiting for page or modal load. DO NOT use it for anything else',
  token: '<loading-indicator-large v-if="loading"/>',
  props: {
    disableCentering: {
      required: false,
      type: Boolean,
      default: false,
      note: 'delete margin auto from component'
    }
  },
  data: function data() {
    return {
      translate: {
        loading: this.$trans('Loading')
      }
    };
  }
};