import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.createNewDashboard,
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    "overflow-visible": true,
    "dismiss-on-click-outside": false,
    onAccept: $options.onDashboardCreate,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.errorMessage), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
        modelValue: $data.newItem.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.newItem.title = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.title,
        "focus-on-mount": true,
        maxlength: $props.titleCharLimit ? $props.titleCharLimit : '',
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "maxlength"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}