function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import * as projectApi from '@/api/compliance/projects';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import * as gapAdminApi from '@/api/rismaadmin/gap';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import MainWell from '@/components/Atoms/Containers/MainWell';
import Notification from '@/components/Molecules/Notification';
export default {
  name: 'UploadGap',
  components: {
    MainWell: MainWell,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaToggle: RismaToggle,
    RismaFileUpload: RismaFileUpload,
    RismaButton: RismaButton,
    InputField: InputField,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    Notification: Notification,
    RismaDatatable: RismaDatatable
  },
  mixins: [RismaDatatableDefaultMixin],
  data: function data() {
    return {
      projects: [],
      projectId: null,
      gapSchemaType: null,
      gapTitle: '',
      files: [],
      updateAllNodes: false,
      origins: [{
        value: 'Customer',
        label: 'Customer'
      }, {
        value: 'Plesner',
        label: 'Plesner'
      }],
      origin: null,
      loading: false,
      notificationType: 'info',
      notificationMessage: '',
      previewDataset: null,
      columnsMeta: [{
        targets: ['suggestedActions', 'suggestedControls'],
        width: '300px'
      }, {
        targets: ['attributes'],
        width: '200px'
      }],
      translate: getTranslate['UploadGap']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, ['isDataProcessorsEnabled'])), {}, {
    gapSchemaTypes: function gapSchemaTypes() {
      var gapTypes = [{
        value: null,
        label: 'Compliance project'
      }];
      if (this.isDataProcessorsEnabled) {
        gapTypes.push({
          value: 102,
          label: 'Data processor'
        });
      }
      return gapTypes;
    },
    columns: function columns() {
      return {
        orderBy: this.translate.ordering,
        article: this.translate.article,
        articleTitle: this.translate.articleTitle,
        domain: this.translate.domain,
        requirement: this.translate.requirement,
        flags: this.translate.flags,
        suggestedActions: this.translate.suggestedActions,
        suggestedControls: this.translate.suggestedControls,
        attributes: this.translate.attributes
      };
    }
  }),
  mounted: function mounted() {
    var _this = this;
    projectApi.getAll().then(function (response) {
      _this.projects = response.list.map(function (project) {
        return {
          value: project.id,
          label: project.title
        };
      });
    });
  },
  methods: {
    createGapSchema: function createGapSchema() {
      var _this2 = this;
      var params = this.getParams();
      gapAdminApi.uploadGap(params).then(function () {
        _this2.handleShowNotification('success', 'Upload ok');
      }).catch(function (error) {
        if (error.response === undefined) {
          return;
        }
        var errorMessage = typeof error.response === 'string' ? error.response : "".concat(error.response.error, " - ").concat(error.response.message);
        _this2.handleShowNotification('error', 'Error during upload: ' + errorMessage);
      });
    },
    getParams: function getParams() {
      return {
        projectId: this.gapSchemaType === null ? this.projectId : null,
        gapSchemaType: this.gapSchemaType,
        file: this.files[0],
        updateAllNodes: this.updateAllNodes,
        gapTitle: this.gapTitle,
        origin: this.origin
      };
    },
    loadPreviewTable: function loadPreviewTable() {
      var _this3 = this;
      this.loading = true;
      var params = this.getParams();
      var lastArticle = null;
      params.dryRun = true;
      gapAdminApi.uploadGap(params).then(function (response) {
        var formattedParsedArticles = [];
        response.list.forEach(function (article) {
          lastArticle = article;
          formattedParsedArticles.push({
            orderBy: {
              field: article.orderBy
            },
            article: {
              field: article.article
            },
            articleTitle: {
              field: article.articleTitle
            },
            domain: {
              field: article.domain
            },
            requirement: {
              field: article.requirement
            },
            flags: {
              field: article.flags
            },
            suggestedActions: _this3.prepareListField(article.suggestedActions || []),
            suggestedControls: _this3.prepareListField(article.suggestedControls || []),
            attributes: _this3.prepareListField(article.attributes || [])
          });
        });
        _this3.previewDataset = formattedParsedArticles;
        _this3.enhanceDataset(_this3.previewDataset, _this3.columns);
        _this3.loading = false;
      }).catch(function (error) {
        var errorMessage = '';
        if (error instanceof TypeError) {
          errorMessage = error + '. On article: ' + lastArticle.article + ' ' + lastArticle.articleTitle;
        } else {
          errorMessage = typeof error.response === 'string' ? error.response : "".concat(error.response.error, " - ").concat(error.response.message);
        }
        _this3.handleShowNotification('error', 'Error during upload: ' + errorMessage);
      });
    },
    handleShowNotification: function handleShowNotification(type, message) {
      var _this4 = this;
      this.notificationType = type;
      this.notificationMessage = message;
      this.$scrollTo(document.body, 100);
      if (type !== 'error') {
        setTimeout(function () {
          return _this4.notificationMessage = '';
        }, 10000);
      }
    },
    handleDismissNotification: function handleDismissNotification() {
      this.notificationMessage = '';
    }
  }
};