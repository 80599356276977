// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/access-types/icon_noAccess.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/access-types/icon_user.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/access-types/icon_privUser.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/img/access-types/icon_superUser.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/img/access-types/icon_supervisor.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../assets/img/access-types/icon_admin.svg");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../../assets/img/access-types/icon_lock.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-d1f1d4e4]  .checkbox-wrapper {\n  margin: 0;\n}\n.user-rights-table thead[data-v-d1f1d4e4] {\n  box-shadow: 0px 1px 0px #807c79;\n}\n.user-rights-table td[data-v-d1f1d4e4], .user-rights-table th[data-v-d1f1d4e4] {\n  min-width: 100px;\n  width: 100px;\n  height: 40px;\n}\n.user-rights-table th[data-v-d1f1d4e4] {\n  font-weight: normal;\n  font-size: 14px;\n}\n.user-rights-table th[data-v-d1f1d4e4]:first-child, .user-rights-table td[data-v-d1f1d4e4]:first-child {\n  min-width: 270px;\n}\n.user-rights-table tr:first-child td[data-v-d1f1d4e4] {\n  padding-top: 16px;\n}\n.user-rights-table tbody[data-v-d1f1d4e4]:not(:last-child) {\n  border-bottom: 1px solid #807c79;\n}\n.user-rights-table tbody:not(:last-child) tr:last-child td[data-v-d1f1d4e4] {\n    height: 60px;\n    padding-bottom: 1rem;\n}\n.icon[data-v-d1f1d4e4] {\n  display: block;\n  width: 35px;\n  height: 35px;\n  background-size: 35px;\n  background-position: center;\n  background-repeat: no-repeat;\n}\n.no-access-icon[data-v-d1f1d4e4] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.normal-access-icon[data-v-d1f1d4e4] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.privileged-access-icon[data-v-d1f1d4e4] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.super-access-icon[data-v-d1f1d4e4] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n.supervisor-access-icon[data-v-d1f1d4e4] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n.admin-access-icon[data-v-d1f1d4e4] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n}\n.locked-access-icon[data-v-d1f1d4e4] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ");\n}\n", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
