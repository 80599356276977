import i18n from '@/i18n';
export var getTranslate = {
  CategoryModal: function CategoryModal() {
    return {
      ok: i18n.t('Ok'),
      close: i18n.t('Close'),
      title: i18n.t('Title'),
      chooseTitle: i18n.t('Choose title'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      createCategory: i18n.t('Create category')
    };
  },
  RiskThreatModal: function RiskThreatModal() {
    return {
      close: i18n.t('Close'),
      title: i18n.t('Title'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      createNew: i18n.t('Create new'),
      selectCategory: i18n.t('Select category'),
      clickToSelectCategory: i18n.t('Click to select category'),
      addedCategory: i18n.t('Added category'),
      editThreat: i18n.t('Edit threat'),
      createThreat: i18n.t('Create threat'),
      save: i18n.t('Save'),
      create: i18n.t('Create')
    };
  }
};