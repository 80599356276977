import { clickOutside } from '@/utils/directives/clickOutside';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaPopup from '@/components/Atoms/Containers/RismaPopup';
import Notification from '@/components/Molecules/Notification';
import InputField from '@/components/Atoms/Inputs/InputField';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { create as createInformationAsset } from '@/api/compliance/informationAssets';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { Colors } from '@/Colors';
export default {
  name: 'NewAsset',
  introduction: 'Create a new asset',
  description: 'Provide a button to show a popup with create a new asset form with checks for blank and doubled.',
  token: "\n    <new-asset\n      :type=\"type\"\n      :assets-options=\"assetsOptions\"\n      @newAsset=\"addNewAsset\"/>",
  components: {
    RismaButton: RismaButton,
    RismaPopup: RismaPopup,
    Notification: Notification,
    InputField: InputField,
    FeatherIcon: FeatherIcon
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    type: {
      type: String,
      required: true,
      note: 'The type of information asset. Can be either: processors, systems or controllers'
    },
    typeId: {
      type: Number,
      required: false,
      default: 0,
      note: 'id of asset type'
    },
    typeLabel: {
      type: String,
      required: false,
      default: '',
      note: 'title of asset type'
    },
    assetsOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: ''
    }
  },
  emits: ['newAsset'],
  data: function data() {
    return {
      assetLabel: '',
      showModal: false,
      Colors: Colors,
      showNotification: false,
      readOnly: false,
      translate: {
        add: this.$trans('Add'),
        theAssetWithThisTitleAlreadyExists: this.$trans('The asset with this title already exists'),
        pleaseFillOutTheTitle: this.$trans('Please fill out the title')
      }
    };
  },
  computed: {
    addNewLabel: function addNewLabel() {
      return this.$trans('Add new ' + this.type.slice(0, -1));
    },
    modalLabel: function modalLabel() {
      return this.$trans('New ' + this.type.slice(0, -1));
    },
    label: function label() {
      if (this.typeId) return this.typeLabel;
      return this.$trans(this.type.slice(0, -1));
    },
    isAssetLabelDoubled: function isAssetLabelDoubled() {
      var _this = this;
      return this.assetsOptions.map(function (option) {
        return option.name;
      }).find(function (name) {
        return _this.assetLabel === name;
      });
    },
    isAssetLabelValid: function isAssetLabelValid() {
      return this.assetLabel !== '' && !this.isAssetLabelDoubled;
    },
    addAssetWarning: function addAssetWarning() {
      return this.isAssetLabelDoubled ? this.translate.theAssetWithThisTitleAlreadyExists : this.translate.pleaseFillOutTheTitle;
    }
  },
  methods: {
    toggleModal: function toggleModal() {
      this.resetModal();
      this.showModal = !this.showModal;
    },
    hideModal: function hideModal() {
      this.resetModal();
      this.showModal = false;
    },
    resetModal: function resetModal() {
      this.showNotification = false;
      this.assetLabel = '';
    },
    addAsset: function addAsset() {
      if (!this.isAssetLabelValid) {
        this.showNotification = true;
        return;
      }
      var data = this.prepareData();
      this.createIA(data);
      this.hideModal();
    },
    prepareData: function prepareData() {
      if (this.type !== AssetsTypes.INFORMATION_ASSETS_API) return {
        name: this.assetLabel,
        parentId: 0
      };
      return {
        activityTypeId: this.typeId,
        title: this.assetLabel
      };
    },
    createIA: function createIA(data) {
      var _this2 = this;
      createInformationAsset(this.type, data).then(function (response) {
        return _this2.processResponse(response);
      });
    },
    processResponse: function processResponse(response) {
      var data = this.enchanceValueWithName(response.data);
      this.$emit('newAsset', data);
    },
    enchanceValueWithName: function enchanceValueWithName(value) {
      if (!value.name) value.name = value.title;
      return value;
    }
  }
};