import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-03584094"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "flex justify-between"
};
var _hoisted_2 = {
  key: 0,
  class: "max-h-500px overflow-y-auto mt-5"
};
var _hoisted_3 = {
  class: "flex items-center text-white bg-blue-250 mb-2 py-2 px-3"
};
var _hoisted_4 = {
  key: 1,
  class: "text-center"
};
var _hoisted_5 = {
  key: 0
};
var _hoisted_6 = {
  class: "flex"
};
var _hoisted_7 = {
  key: 1
};
var _hoisted_8 = {
  class: "hidden"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_doughnut_chart = _resolveComponent("risma-doughnut-chart");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_trafficlight = _resolveComponent("trafficlight");
  var _component_static_table = _resolveComponent("static-table");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.chartData, function (chart) {
    return _openBlock(), _createElementBlock("div", {
      key: chart.title,
      class: "w-6/25 max-w-500px"
    }, [_createVNode(_component_risma_title, {
      title: chart.title
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_doughnut_chart, {
      datasets: chart.datasets,
      labels: chart.labels,
      "show-data-labels": true,
      "percentage-value": false,
      onChartClicked: function onChartClicked($event) {
        return $options.showData(chart, $event);
      }
    }, null, 8 /* PROPS */, ["datasets", "labels", "onChartClicked"])]);
  }), 128 /* KEYED_FRAGMENT */))]), $data.showDataControls.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_feather_icon, {
    icon: "x",
    class: "text-white stroke-3 cursor-pointer mr-2",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.showDataControls = [];
    })
  }), _createElementVNode("span", null, _toDisplayString($data.showDataControlstitle), 1 /* TEXT */)]), _createVNode(_component_static_table, {
    dataset: $data.showDataControls,
    columns: [$data.translate.trafficLight, $data.translate.controls, $data.translate.deadline]
  }, {
    title: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createVNode(_component_preview_modal_link, {
        activity: slotData.data
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(slotData.cpath) + ". " + _toDisplayString(slotData.title), 1 /* TEXT */)];
        }),

        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"])];
    }),
    trafficlight: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [_createVNode(_component_trafficlight, {
        color: slotData.color
      }, null, 8 /* PROPS */, ["color"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset", "columns"])])) : $data.showEmptyText ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($data.translate.noRecordsMatchingTheFilter), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createVNode(_component_risma_datatable, {
    dataset: _ctx.datasetSlice,
    "dataset-total": $options.tableData,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "page-length": 50,
    "column-sorting": $data.columnSorting,
    "wrapper-height-sticky": 500,
    "custom-export": $options.customExport,
    class: "mt-12",
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged
  }, {
    title: _withCtx(function (_ref3) {
      var item = _ref3.item;
      return [_createVNode(_component_preview_modal_link, {
        activity: item.activity,
        onDismissModal: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('dismissModal');
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(item.fieldText), 1 /* TEXT */)];
        }),

        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"])];
    }),
    gaps: _withCtx(function (_ref4) {
      var item = _ref4.item;
      return [item.activities && item.activities.length ? (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.activities, function (activity, index) {
        return _openBlock(), _createBlock(_component_preview_modal_link, {
          key: "".concat(activity.fieldText, "_").concat(index),
          class: "hover:underline",
          activity: activity.activity,
          onDismissModal: _cache[2] || (_cache[2] = function ($event) {
            return _ctx.$emit('dismissModal');
          })
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("div", _hoisted_6, [_createVNode(_component_trafficlight, {
              class: "flex-shrink-0",
              color: activity.color
            }, null, 8 /* PROPS */, ["color"]), _createElementVNode("span", null, _toDisplayString(activity.fieldText), 1 /* TEXT */)])];
          }),

          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"]);
      }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("div", _hoisted_7))];
    }),
    trafficlight: _withCtx(function (_ref5) {
      var item = _ref5.item;
      return [_createVNode(_component_trafficlight, {
        color: item.field.color
      }, null, 8 /* PROPS */, ["color"]), _createElementVNode("p", _hoisted_8, _toDisplayString(item.field.value) + "-" + _toDisplayString(_ctx.$trans(item.field.label)), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset", "dataset-total", "dataset-total-sliced", "column-sorting", "custom-export", "onPageChange", "onOnStateChanged"])]);
}