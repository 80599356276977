import { Colors } from '@/Colors';
import SelectableLegend from '@/components/Molecules/SelectableLegend/SelectableLegend';
export default {
  name: '',
  components: {
    SelectableLegend: SelectableLegend
  },
  data: function data() {
    return {
      colors: [Colors.greenTea, Colors.blueLight],
      combinedDataset: [],
      dataset: [{
        label: 'Today',
        data: [5, 1, 6, 6, 2, 4]
      }, [{
        label: 'Test dataset 1',
        data: [1, 5, 7, 2, 3, 4]
      }, {
        label: 'Test dataset 2',
        data: [5, 1, 6, 6, 2, 2]
      }]]
    };
  }
};