function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import draggable from 'vuedraggable';
import { mapActions } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { createGapType as _createGapType, patchGapType as _patchGapType, deleteGapType as _deleteGapType, getGapTypes as _getGapTypes } from '@/api/compliance/gaps';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import DragAndDropIcon from '@/components/Atoms/FeatherIcon/DragAndDropIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Alert from '@/components/Molecules/Modal/Alert';
import Notification from '@/components/Molecules/Notification';
import { DragOptions } from '@/constants/DragOptions';
export default {
  components: {
    Alert: Alert,
    Notification: Notification,
    InputField: InputField,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    DragAndDropIcon: DragAndDropIcon,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    draggable: draggable,
    RismaButton: RismaButton
  },
  props: {
    complianceProjectId: {
      type: [String, Number],
      required: true,
      default: null
    }
  },
  data: function data() {
    return {
      DragOptions: DragOptions,
      notificationMessage: '',
      notificationType: 'error',
      gapTypes: [],
      initialGapTypes: [],
      removedGapTypesIds: [],
      itemToDelete: null,
      showDeleteGapTypeAlert: false,
      gapTypesLoading: false,
      translate: getTranslate['GapTypesList']()
    };
  },
  computed: {
    isGapTypesValid: function isGapTypesValid() {
      return !this.gapTypes.find(function (gapType) {
        return !gapType.label;
      });
    }
  },
  watch: {
    complianceProjectId: function complianceProjectId(projectId) {
      this.getGapTypes(projectId);
    }
  },
  mounted: function mounted() {
    if (this.complianceProjectId) {
      this.getGapTypes(this.complianceProjectId);
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useUserStore, {
    loadCurrentUser: 'load'
  })), {}, {
    getGapTypes: function getGapTypes(projectId) {
      var _this = this;
      if (!projectId) return;
      this.gapTypesLoading = true;
      return _getGapTypes(projectId).then(function (_ref) {
        var list = _ref.list;
        var sortedTypes = list.sort(function (a, b) {
          return a.number - b.number;
        });
        _this.gapTypes = _this.copyValue(sortedTypes);
        _this.initialGapTypes = _this.copyValue(sortedTypes);
      }).finally(function () {
        _this.gapTypesLoading = false;
      });
    },
    patchGapType: function patchGapType(gapType) {
      if (!this.complianceProjectId) return;
      return _patchGapType(this.complianceProjectId, gapType);
    },
    deleteGapType: function deleteGapType(gapTypeId) {
      if (!this.complianceProjectId) return;
      return _deleteGapType(this.complianceProjectId, gapTypeId);
    },
    createGapType: function createGapType(gapType) {
      if (!this.complianceProjectId) return;
      return _createGapType(this.complianceProjectId, gapType);
    },
    dismissDeleteAlert: function dismissDeleteAlert() {
      this.showDeleteGapTypeAlert = false;
      this.itemToDelete = null;
    },
    onDeleteAlertAccept: function onDeleteAlertAccept() {
      this.removedGapTypesIds.push(this.itemToDelete.id);
      this.removeGapTypeLocally();
      this.dismissDeleteAlert();
    },
    recalculateGapTypeNumbers: function recalculateGapTypeNumbers() {
      this.gapTypes = this.gapTypes.map(function (gapType, index) {
        gapType.number = index + 1;
        return gapType;
      });
    },
    onGapTypeAdd: function onGapTypeAdd() {
      var newGapNumber = this.gapTypes.length + 1;
      this.gapTypes.push({
        label: '',
        number: newGapNumber
      });
    },
    onGapTypeDelete: function onGapTypeDelete(itemToDelete) {
      this.itemToDelete = itemToDelete;
      if (itemToDelete.id) {
        this.showDeleteGapTypeAlert = true;
      } else {
        this.removeGapTypeLocally();
      }
    },
    removeGapTypeLocally: function removeGapTypeLocally() {
      var _this2 = this;
      var idx = this.gapTypes.findIndex(function (gapType) {
        return gapType.number === _this2.itemToDelete.number;
      });
      this.gapTypes.splice(idx, 1);
      this.recalculateGapTypeNumbers();
    },
    onSaveGapTypes: function onSaveGapTypes() {
      var _this3 = this;
      this.notificationMessage = '';
      if (!this.isGapTypesValid) {
        this.notificationType = 'error';
        this.notificationMessage = this.translate.pleaseFillOutAllFields;
        return;
      }
      var promises = [];
      this.gapTypesLoading = true;
      this.removedGapTypesIds.forEach(function (removedId) {
        promises.push(_this3.deleteGapType(removedId));
      });
      this.removedGapTypesIds = [];
      this.gapTypes.forEach(function (gapType) {
        if (!gapType.id) {
          promises.push(_this3.createGapType(gapType));
          return;
        }
        var initialGapType = _this3.initialGapTypes.find(function (initialItem) {
          return initialItem.id === gapType.id;
        });
        if (!initialGapType) return;
        if (initialGapType.number !== gapType.number || initialGapType.label !== gapType.label) {
          promises.push(_this3.patchGapType(gapType));
        }
      });
      return Promise.all(promises).then(function () {
        return _this3.getGapTypes(_this3.complianceProjectId);
      }).then(function () {
        _this3.notificationType = 'success';
        _this3.notificationMessage = _this3.translate.changesSaved;
      }).then(function () {
        _this3.loadCurrentUser();
      }).catch(function () {
        _this3.notificationType = 'error';
        _this3.notificationMessage = _this3.translate.somethingWentWrong;
      }).finally(function () {
        _this3.gapTypesLoading = false;
      });
    },
    copyValue: function copyValue(value) {
      return JSON.parse(JSON.stringify(value));
    }
  })
};