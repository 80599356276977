import Selector from '@/components/Atoms/Inputs/Selector';
export default {
  components: {
    Selector: Selector
  },
  data: function data() {
    return {
      items: [{
        id: 1,
        name: 'User A',
        initials: 'A',
        fullname: 'User A'
      }, {
        id: 2,
        name: 'User B',
        initials: 'B',
        fullname: 'User B'
      }, {
        id: 3,
        name: 'User C',
        initials: 'C',
        fullname: 'User C'
      }, {
        id: 4,
        name: 'User D',
        initials: 'D',
        fullname: 'User D'
      }, {
        id: 5,
        name: 'User E',
        initials: 'E',
        fullname: 'User E'
      }, {
        id: 6,
        name: 'User F',
        initials: 'F',
        fullname: 'User F'
      }, {
        id: 7,
        name: 'User G',
        initials: 'G',
        fullname: 'User G'
      }, {
        id: 8,
        name: 'User H',
        initials: 'H',
        fullname: 'User H'
      }, {
        id: 9,
        name: 'User I',
        initials: 'I',
        fullname: 'User I'
      }]
    };
  },
  methods: {
    selected: function selected(item) {
      alert(item.name);
    }
  }
};