import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function get() {
  return handleRequestResponse(Http.get('data/holidays'));
}
export function create(data) {
  return handleRequestResponse(Http.post('data/holidays', data));
}
export function remove(data) {
  return handleRequestResponse(Http.delete('/data/holidays/' + data));
}