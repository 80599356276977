import i18n from '@/i18n';
export var getTranslate = {
  Table: function Table() {
    return {
      exportToExcel: i18n.t('Export to excel'),
      printTheTable: i18n.t('Print the table'),
      annexA: i18n.t('Annex A'),
      description: i18n.t('Description'),
      maturityLevel: i18n.t('Maturity level'),
      analysis: i18n.t('Analysis'),
      riskAssessment: i18n.t('Risk assessment'),
      linkedProcesses: i18n.t('Linked processes'),
      linkedControls: i18n.t('Linked controls'),
      linkedInitiatives: i18n.t('Linked initiatives')
    };
  }
};