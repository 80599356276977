export var CONTRACTS_TABLE_COLUMNS = {
  contractNumber: 'Contract no.',
  title: 'Title',
  titlePreview: 'Title',
  controls: 'Controls',
  companies: 'Contract parties',
  responsible: 'Responsible',
  activityType: 'Activity type',
  tags: 'Tags',
  start: 'Start date',
  end: 'Expiration date',
  amount: 'Amount',
  currency: 'Currency',
  status: 'Status',
  starred: '',
  delete: ''
};