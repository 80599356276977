function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { MODULES } from '@/constants/modules';
import { OptionalFields, OptionalFieldsLabels } from '@/constants/OptionalFields';
export var OPTIONAL_FIELDS = function OPTIONAL_FIELDS(getSettingValue) {
  var _ref;
  var compliaceAdditionalFields = getSettingValue('feature.compliance_nodes_risk_assessment') ? [{
    value: 'risk_assessment',
    label: 'Risk assessment'
  }, {
    value: 'rto_rpo',
    label: 'RTO & RPO'
  }] : [];
  return _ref = {}, _defineProperty(_ref, MODULES.EXECUTION, [
  //revert - its always true, but if its in optionalFields in activity type -> it should be removed
  {
    value: 'title',
    label: 'Title',
    hidden: true
  }, {
    value: 'description',
    label: 'Description'
  }, {
    value: 'status',
    label: 'Status'
  }, {
    value: 'target',
    label: 'Target'
  }, {
    value: 'project_status',
    label: 'Project status'
  }, {
    value: 'comment',
    label: 'Other comments'
  }, {
    value: 'notifications',
    label: 'Notifications'
  }, {
    value: 'daily_users',
    label: 'Daily team - Users'
  }, {
    value: 'daily_organisations',
    label: 'Daily team - Organisations'
  }, {
    value: 'depending_on',
    label: 'Depending on'
  }]), _defineProperty(_ref, MODULES.COMPLIANCE, [{
    value: 'questionnaire',
    label: 'Questionnaire (Tab)'
  }, {
    value: 'gap_schema',
    label: 'Gap analysis (Tab)'
  }, {
    value: 'assessment',
    label: 'Assessment (Tab)'
  }, {
    value: 'process_purpose',
    label: 'Purpose'
  }, {
    value: 'process_status',
    label: 'Process status'
  }].concat(compliaceAdditionalFields)), _defineProperty(_ref, MODULES.INCIDENTS, [{
    value: 'description',
    label: 'Description'
  }, {
    value: 'severity',
    label: 'Severity'
  }]), _defineProperty(_ref, MODULES.RISK, [{
    value: 'period',
    label: 'Period'
  }, {
    value: 'description',
    label: 'Description'
  }, {
    value: 'threat',
    label: 'Threat'
  }, {
    value: 'threatLevel',
    label: 'Level of threat'
  }, {
    value: 'vulnerability',
    label: 'Vulnerability'
  }]), _defineProperty(_ref, MODULES.COMPLIANCE_IA_SYSTEM, [{
    value: 'location',
    label: 'Location'
  }, {
    value: 'description',
    label: 'Description'
  }, {
    value: 'purpose',
    label: 'Purpose'
  }, {
    value: 'email',
    label: 'Email'
  }]), _defineProperty(_ref, MODULES.COMPLIANCE_IA_PROCESSOR, [{
    value: 'description',
    label: 'Description'
  }, {
    value: 'address',
    label: 'Address'
  }, {
    value: 'countryCode',
    label: 'Country code'
  }, {
    value: 'phone',
    label: 'Phone number'
  }, {
    value: 'vatNo',
    label: 'VAT number'
  }, {
    value: 'regNo',
    label: 'Reg. number'
  }, {
    value: 'email',
    label: 'Email'
  }, {
    value: 'contactName',
    label: 'Contact name'
  }]), _defineProperty(_ref, MODULES.COMPLIANCE_IA_CONTROLLER, [{
    value: 'description',
    label: 'Description'
  }, {
    value: 'address',
    label: 'Address'
  }, {
    value: 'countryCode',
    label: 'Country code'
  }, {
    value: 'phone',
    label: 'Phone number'
  }, {
    value: 'vatNo',
    label: 'VAT number'
  }, {
    value: 'regNo',
    label: 'Reg. number'
  }, {
    value: 'email',
    label: 'Email'
  }]), _defineProperty(_ref, MODULES.COMPLIANCE_INFORMATION_ASSETS, [{
    value: 'description',
    label: 'Description'
  }, {
    value: 'address',
    label: 'Address'
  }, {
    value: 'postcode',
    label: 'Zipcode'
  }, {
    value: 'city',
    label: 'City'
  }, {
    value: 'email',
    label: 'Email address'
  }]), _defineProperty(_ref, MODULES.CONTRACTS, [{
    value: OptionalFields.DESCRIPTION,
    label: OptionalFieldsLabels[OptionalFields.DESCRIPTION]
  }, {
    value: OptionalFields.STARTDATE,
    label: OptionalFieldsLabels[OptionalFields.STARTDATE]
  }, {
    value: OptionalFields.EXPIRATIONDATE,
    label: OptionalFieldsLabels[OptionalFields.EXPIRATIONDATE]
  }, {
    value: OptionalFields.NOTIFICATION_UPON_EXPIRATION_DATE,
    label: OptionalFieldsLabels[OptionalFields.NOTIFICATION_UPON_EXPIRATION_DATE]
  }, {
    value: OptionalFields.RENEWAL_DEADLINE,
    label: OptionalFieldsLabels[OptionalFields.RENEWAL_DEADLINE]
  }, {
    value: OptionalFields.NOTIFICATION_UPON_RENEWAL_DEADLINE,
    label: OptionalFieldsLabels[OptionalFields.NOTIFICATION_UPON_RENEWAL_DEADLINE]
  }, {
    value: OptionalFields.RENEWAL_AMOUNT,
    label: OptionalFieldsLabels[OptionalFields.RENEWAL_AMOUNT]
  }, {
    value: OptionalFields.TERMINATION_DEADLINE,
    label: OptionalFieldsLabels[OptionalFields.TERMINATION_DEADLINE]
  }, {
    value: OptionalFields.NOTIFICATION_UPON_TERMINATION_DEADLINE,
    label: OptionalFieldsLabels[OptionalFields.NOTIFICATION_UPON_TERMINATION_DEADLINE]
  }, {
    value: OptionalFields.AUTO_RENEWAL,
    label: OptionalFieldsLabels[OptionalFields.AUTO_RENEWAL]
  }, {
    value: OptionalFields.AMOUNT,
    label: OptionalFieldsLabels[OptionalFields.AMOUNT]
  }, {
    value: OptionalFields.PAYMENT_INTERVAL,
    label: OptionalFieldsLabels[OptionalFields.PAYMENT_INTERVAL]
  }, {
    value: OptionalFields.CONTRACT_VERSION,
    label: OptionalFieldsLabels[OptionalFields.CONTRACT_VERSION]
  }, {
    value: OptionalFields.STATUS_CHANGENOTE,
    label: OptionalFieldsLabels[OptionalFields.STATUS_CHANGENOTE]
  }]), _ref;
};