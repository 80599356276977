import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
export default {
  components: {
    NotificationPopUp: NotificationPopUp,
    RismaButton: RismaButton
  },
  mixins: [NotificationPopUpMixin],
  methods: {
    showPopUpFunction: function showPopUpFunction(state) {
      switch (state) {
        case 'normal':
          this.handleInstantNotification({
            title: this.$trans('Your activity has been created'),
            url: '22 Some activity name'
          });
          break;
        case 'superLarge':
          this.handleInstantNotification({
            title: 'This is a super large title that supposed to break popup, some more random text written here, some more random text written here',
            url: 'This is a super large url that supposed to break popup'
          });
          break;
        case 'large':
          this.handleInstantNotification({
            title: 'This is a large title that supposed to break popup',
            url: 'This is a large url that supposed to break popup'
          });
          break;
        case 'small':
          this.handleInstantNotification({
            title: 'tiny title',
            url: 'tiny url'
          });
          break;
        case 'error':
          this.handleInstantNotification({
            title: 'error title',
            type: 'error'
          });
          break;
      }
    }
  }
};