import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_LineChart = _resolveComponent("LineChart");
  var _component_export_frame = _resolveComponent("export-frame");
  return _openBlock(), _createBlock(_component_export_frame, {
    "use-export": _ctx.useDefaultExport,
    "excel-export-function": _ctx.isExcelExportAvailable ? _ctx.excelExportFunction : null
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_LineChart, {
        data: $options.lineChartData,
        class: "!h-400px",
        options: $options.lineChartOptions
      }, null, 8 /* PROPS */, ["data", "options"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["use-export", "excel-export-function"]);
}