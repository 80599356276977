import { Http } from '../index';
import * as HttpUtils from '../httpUtils';

/**
 * Get all the question rules
 *
 * @param q_id
 * @param g_id
 * @returns {Promise<AxiosResponse | string>}
 */
export function getQuestionnaireRules(q_id, g_id) {
  return Http.get('/compliance/rules/questionnaire?questionnaireId=' + q_id + '&gapSchemaId=' + g_id).then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}

/**
 * Patch (create or update) question rules to server
 *
 * @param q_id
 * @param g_id
 * @param data
 * @returns {Promise<AxiosResponse | string>}
 */
export function saveQuestionnaireRules(q_id, g_id, data) {
  var patchData = {
    'questionnaireId': q_id,
    'gapSchemaId': g_id,
    'rules': data
  };
  return Http.patch('/compliance/rules/questionnaire', patchData).then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}

/**
 * Validates the question rules - only formatting of rules for now!
 *
 * @param data String question rules
 */
export function validateQuestionnaireRules(data) {
  var formData = new FormData();
  formData.append('rules', data);
  return Http.post('/compliance/rules/questionnaire', formData).then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}
export function getSecurityGroups() {
  return Http.get('/utils/adsettings').then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response.data;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}
export function updateSecurityGroup(id, params) {
  return Http.patch("/utils/adsettings/".concat(id), params).then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}
export function createSecurityGroup(data) {
  return Http.post('/utils/adsettings', data).then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}