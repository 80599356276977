import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mb-8"
};
var _hoisted_2 = {
  style: {
    "max-width": "600px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [$data.hasNotification ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: $data.notificationType
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.notificationMessages.join(',\n')), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.changePassword
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_1, _toDisplayString($data.translate.toChangeYourPassword), 1 /* TEXT */), _createElementVNode("div", _hoisted_2, [_createVNode(_component_input_field, {
    modelValue: $data.passwordData.passwordCurrent,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.passwordData.passwordCurrent = $event;
    }),
    title: $data.translate.currentPassword,
    class: "mb-4",
    type: "password"
  }, null, 8 /* PROPS */, ["modelValue", "title"]), _createVNode(_component_input_field, {
    modelValue: $data.passwordData.passwordNew,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.passwordData.passwordNew = $event;
    }),
    title: $data.translate.newPassword,
    class: "mb-4",
    type: "password"
  }, null, 8 /* PROPS */, ["modelValue", "title"]), _createVNode(_component_input_field, {
    modelValue: $data.passwordData.passwordNewRepeat,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.passwordData.passwordNewRepeat = $event;
    }),
    title: $data.translate.repeatNewPassword,
    class: "mb-4",
    type: "password"
  }, null, 8 /* PROPS */, ["modelValue", "title"]), _createVNode(_component_risma_button, {
    type: "save",
    class: "float-right",
    text: $data.translate.changePassword,
    onClick: $options.changePassword
  }, null, 8 /* PROPS */, ["text", "onClick"])])]);
}