import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2527d062"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  class: "mypage-list mypage-list-process-library mypage-list-columns"
};
var _hoisted_2 = {
  key: 1,
  class: "mypage-list mypage-list-columns"
};
var _hoisted_3 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_preview_process_tree_node = _resolveComponent("preview-process-tree-node");
  var _component_router_link = _resolveComponent("router-link");
  var _component_static_table = _resolveComponent("static-table");
  var _component_card_process_library = _resolveComponent("card-process-library");
  var _component_load_more_wrapper = _resolveComponent("load-more-wrapper");
  var _component_preview_list = _resolveComponent("preview-list");
  return _openBlock(), _createBlock(_component_preview_list, {
    length: $props.processTreeNodes.length || '0',
    title: _ctx.processLibraryTitle,
    class: "process-library",
    "cookie-hidden-name": "hidden_cards_wrapper_compliance_my_process_library",
    onChangeShowState: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changeShowState', $event);
    })
  }, {
    default: _withCtx(function () {
      return [$props.viewMode == $data.views.TILES ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.processTreeNodes, function (node) {
        return _openBlock(), _createBlock(_component_preview_process_tree_node, {
          key: "preview-process-tree-node".concat(node.id),
          node: node
        }, null, 8 /* PROPS */, ["node"]);
      }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), $props.viewMode == $data.views.COMPACT ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_static_table, {
        "visibility-switch": true,
        dataset: $options.dataset,
        columns: $options.columns
      }, {
        title: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_createVNode(_component_router_link, {
            to: slotData.url
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(slotData.title), 1 /* TEXT */)];
            }),

            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset", "columns"])])) : _createCommentVNode("v-if", true), $props.viewMode == $data.views.EXPANDED ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_load_more_wrapper, {
        activities: $props.processTreeNodes,
        class: "mypage-list mypage-list-columns"
      }, {
        default: _withCtx(function (_ref2) {
          var activitiesLimited = _ref2.activitiesLimited;
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activitiesLimited, function (node) {
            return _openBlock(), _createBlock(_component_card_process_library, {
              key: "preview-process-tree-node".concat(node.id),
              node: node,
              "all-organisations": $props.organisations,
              users: $props.users
            }, null, 8 /* PROPS */, ["node", "all-organisations", "users"]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["activities"])])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["length", "title"]);
}