import i18n from '@/i18n';
export var getTranslate = {
  CardAction: function CardAction() {
    return {
      status: i18n.t('Status'),
      deadline: i18n.t('Deadline'),
      start: i18n.t('Start'),
      description: i18n.t('Description'),
      target: i18n.t('Target'),
      dependingOn: i18n.t('Depending on'),
      precursorTo: i18n.t('Precursor to'),
      customFields: i18n.t('Custom fields'),
      responsible: i18n.t('Responsible'),
      organisations: i18n.t('Organisations'),
      notifications: i18n.t('Notifications'),
      dailyTeam: i18n.t('Daily team'),
      initiativeType: i18n.t('Initiative type'),
      comments: i18n.t('Comments'),
      tags: i18n.t('Tags'),
      risks: i18n.t('Risks'),
      controls: i18n.t('Controls')
    };
  },
  CardControl: function CardControl() {
    return {
      completion: i18n.t('Completion'),
      signoffPeriodPending: i18n.t('Signoff period pending'),
      review: i18n.t('Review'),
      daughterControls: i18n.t('Daughter controls'),
      controlDeactivated: i18n.t('Control deactivated'),
      reviewer: i18n.t('Reviewer'),
      responsible: i18n.t('Responsible'),
      notSet: i18n.t('Not set'),
      deadline: i18n.t('Deadline'),
      completed: i18n.t('Completed'),
      reviewDeadline: i18n.t('Review deadline')
    };
  },
  CardIncidents: function CardIncidents() {
    return {
      incidentOccurred: i18n.t('Incident occurred'),
      incidentArchived: i18n.t('Incident archived'),
      status: i18n.t('Status'),
      notSet: i18n.t('Not set'),
      created: i18n.t('Created'),
      accountable: i18n.t('Accountable')
    };
  },
  CardProcessLibrary: function CardProcessLibrary() {
    return {
      description: i18n.t('Description'),
      responsible: i18n.t('Responsible'),
      accountable: i18n.t('Accountable'),
      organisations: i18n.t('Organisations')
    };
  },
  CardRisk: function CardRisk() {
    return {
      approved: i18n.t('Approved'),
      notApproved: i18n.t('Not approved'),
      description: i18n.t('Description'),
      status: i18n.t('Status'),
      riskAssesment: i18n.t('Risk assesment'),
      customFields: i18n.t('Custom fields')
    };
  }
};