import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-57fb0324"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "security-group-new"
};
var _hoisted_2 = {
  class: "security-group-new-row"
};
var _hoisted_3 = {
  class: "security-group-new-row"
};
var _hoisted_4 = {
  class: "security-group-new-column"
};
var _hoisted_5 = {
  class: "security-group-new-column"
};
var _hoisted_6 = {
  class: "security-group-new-column"
};
var _hoisted_7 = {
  class: "security-group-new-column"
};
var _hoisted_8 = {
  class: "security-group-new-column"
};
var _hoisted_9 = {
  class: "security-group-new-column"
};
var _hoisted_10 = {
  class: "security-group-new-column"
};
var _hoisted_11 = {
  class: "security-group-new-column"
};
var _hoisted_12 = {
  class: "security-group-new-column"
};
var _hoisted_13 = {
  class: "security-group-new-column security-group-new-column-lockedfields"
};
var _hoisted_14 = {
  class: "security-group-new-actions"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.isError ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$trans($data.errorMessage)), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.columns, function (column, i) {
    return _openBlock(), _createBlock(_component_risma_title, {
      key: "security-group-new-column".concat(i),
      title: column,
      type: "medium",
      class: "security-group-new-column"
    }, null, 8 /* PROPS */, ["title"]);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.group.objectId = $event;
    }),
    class: "form-control",
    type: "text"
  }, null, 512 /* NEED_PATCH */), [[_vModelText, $data.group.objectId, void 0, {
    trim: true
  }]])]), _createElementVNode("div", _hoisted_5, [_withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.group.name = $event;
    }),
    class: "form-control",
    type: "text"
  }, null, 512 /* NEED_PATCH */), [[_vModelText, $data.group.name, void 0, {
    trim: true
  }]])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelAdmin,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.group.levelAdmin = $event;
    }),
    options: $data.adminLevels
  }, null, 8 /* PROPS */, ["modelValue", "options"])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelControls,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.group.levelControls = $event;
    }),
    options: $data.accessLevels
  }, null, 8 /* PROPS */, ["modelValue", "options"])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelExecution,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return $data.group.levelExecution = $event;
    }),
    options: $data.accessLevels
  }, null, 8 /* PROPS */, ["modelValue", "options"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelIncidents,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $data.group.levelIncidents = $event;
    }),
    options: $data.accessLevels
  }, null, 8 /* PROPS */, ["modelValue", "options"])]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelRisk,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $data.group.levelRisk = $event;
    }),
    options: $data.accessLevels
  }, null, 8 /* PROPS */, ["modelValue", "options"])]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelContracts,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
      return $data.group.levelContracts = $event;
    }),
    options: $data.accessLevels
  }, null, 8 /* PROPS */, ["modelValue", "options"])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelInformationAssets,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
      return $data.group.levelInformationAssets = $event;
    }),
    options: $data.accessLevelsBase
  }, null, 8 /* PROPS */, ["modelValue", "options"])]), _createElementVNode("div", _hoisted_13, [_withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
      return $data.group.lockedFields = $event;
    }),
    class: "security-group-new-value-checkbox",
    type: "checkbox"
  }, null, 512 /* NEED_PATCH */), [[_vModelCheckbox, $data.group.lockedFields]])])]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_risma_button, {
    text: "".concat($data.translate.create),
    type: "save",
    onClick: $options.create
  }, null, 8 /* PROPS */, ["text", "onClick"])])]);
}