function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { getContracts as _getContracts, getContract } from '@/api/contracts/contracts';
import { getWorkflowStatusesByModule } from '@/api/risma/data';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import ContractGenericTable from '@/components/Pages/Contracts/ContractGenericTable';
import AddContractsModal from '@/components/Molecules/Contracts/AddContractsModal';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { compareStrings } from '@/utils/compare';
import { removeDuplicates } from '@/utils/filterArray';
import { MODULES } from '@/constants/modules';
export default {
  name: 'ContractRelatedContracts',
  description: 'This is the Page component for /contracts/contract/:id/related',
  token: '<contract-related-contracts :data="data"/>',
  components: {
    FeatherIcon: FeatherIcon,
    ContractGenericTable: ContractGenericTable,
    AddContractsModal: AddContractsModal,
    PreviewList: PreviewList,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Contract object'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['propertyChanged'],
  data: function data() {
    return {
      isLoaded: false,
      showAddContractsModal: false,
      contracts: [],
      workflowStatuses: [],
      relatedCompanies: [],
      relatedContracts: {
        byCompany: [],
        byLink: []
      },
      companies: [],
      translate: getTranslate['ContractRelatedContracts']()
    };
  },
  computed: {
    nothingToShowMessage: function nothingToShowMessage() {
      return this.translate.nothingToShow;
    },
    relatedContractsTitle: function relatedContractsTitle() {
      if (this.companyName) {
        return "".concat(this.translate.relatedContractsFor, " ").concat(this.companyName);
      }
      return this.translate.relatedContracts;
    },
    availableToChooseContracts: function availableToChooseContracts() {
      var _this = this;
      return this.contracts.filter(function (contract) {
        return contract.id !== _this.data.id;
      });
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      return Promise.all([this.getContracts().then(this.getRelatedData), this.getWorkflowStatuses(), this.getCompanies()]).then(function () {
        _this2.getRelatedCompanies();
        _this2.isLoaded = true;
      });
    },
    getRelatedData: function getRelatedData() {
      var _this3 = this;
      return getContract(this.data.id, '?data[]=related').then(function (_ref) {
        var related = _ref.related;
        return _this3.setRelated(related);
      });
    },
    setRelated: function setRelated(related) {
      related.byCompany = removeDuplicates(related.byCompany);
      related.byLink = this.addLinks(related.byLink);
      this.relatedContracts = related;
    },
    getContracts: function getContracts() {
      var _this4 = this;
      var IncludeArchived = true;
      return _getContracts(IncludeArchived).then(function (_ref2) {
        var list = _ref2.list;
        return _this4.contracts = list;
      });
    },
    getWorkflowStatuses: function getWorkflowStatuses() {
      var _this5 = this;
      return getWorkflowStatusesByModule(MODULES.CONTRACTS).then(function (_ref3) {
        var list = _ref3.list;
        return _this5.workflowStatuses = list;
      });
    },
    getCompanies: function getCompanies() {
      var _this6 = this;
      return _getCompanies().then(function (_ref4) {
        var list = _ref4.list;
        _this6.companies = list;
      });
    },
    getRelatedCompanies: function getRelatedCompanies() {
      var _this$data,
        _this7 = this;
      this.relatedCompanies = [];
      if ((_this$data = this.data) !== null && _this$data !== void 0 && (_this$data = _this$data.links) !== null && _this$data !== void 0 && (_this$data = _this$data.company) !== null && _this$data !== void 0 && _this$data.length) {
        this.data.links.company.forEach(function (id) {
          var relatedCompany = _this7.companies.find(function (company) {
            return +id === +company.id;
          });
          if (relatedCompany) {
            _this7.relatedCompanies.push({
              name: relatedCompany.name,
              id: relatedCompany.id,
              contracts: _this7.getContractsByCompany(relatedCompany)
            });
          }
        });
      }
    },
    getContractsByCompany: function getContractsByCompany(company) {
      var _company$links,
        _this8 = this;
      var relatedContractsByCompany = [];
      if (company !== null && company !== void 0 && (_company$links = company.links) !== null && _company$links !== void 0 && _company$links.contracts) {
        company.links.contracts.forEach(function (id) {
          var relatedContract = _this8.relatedContracts.byCompany.find(function (contract) {
            return +id === +contract.id;
          });
          if (relatedContract) {
            relatedContractsByCompany.push(relatedContract);
          }
        });
      }
      return relatedContractsByCompany;
    },
    getRelatedCompanyTitle: function getRelatedCompanyTitle(companyName) {
      if (companyName) {
        return "".concat(this.translate.relatedContractsFor, " ").concat(companyName);
      }
      return this.translate.relatedContracts;
    },
    toggleAddContractsModal: function toggleAddContractsModal() {
      if (this.readOnly) {
        return;
      }
      this.showAddContractsModal = !this.showAddContractsModal;
    },
    updateSelectedContracts: function updateSelectedContracts(contracts) {
      this.relatedContracts.byLink = contracts;
      this.addContracts(contracts);
      this.toggleAddContractsModal();
    },
    addContracts: function addContracts(contracts) {
      var ids = contracts.map(function (contract) {
        return contract.id;
      }) || [];
      var linksValue = ids.sort(function (a, b) {
        return a - b;
      });
      this.emitUpdateLinks(_objectSpread(_objectSpread({}, this.data.links), {}, {
        contracts: linksValue
      }));
    },
    deleteItem: function deleteItem(itemId) {
      var links = this.data.links.contracts.filter(function (item) {
        return item !== itemId;
      });
      this.relatedContracts.byLink = this.relatedContracts.byLink.filter(function (item) {
        return item.id !== itemId;
      });
      this.emitUpdateLinks(_objectSpread(_objectSpread({}, this.data.links), {}, {
        contracts: links
      }));
    },
    emitUpdateLinks: function emitUpdateLinks(value) {
      this.$emit('propertyChanged', {
        property: 'links',
        value: value
      });
    },
    addLinks: function addLinks(contracts) {
      var _this9 = this;
      return contracts.map(function (contract) {
        var _this9$contracts$find;
        return _objectSpread(_objectSpread({}, contract), {}, {
          links: (_this9$contracts$find = _this9.contracts.find(function (c) {
            return +c.id === +contract.id;
          })) === null || _this9$contracts$find === void 0 ? void 0 : _this9$contracts$find.links
        });
      });
    },
    sortContractsByNo: function sortContractsByNo(contracts) {
      return _toConsumableArray(contracts).sort(function (a, b) {
        return compareStrings(a.uniqueId, b.uniqueId, false, 'en');
      });
    }
  }
};