import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = {
  class: "flex flex-grow w-3/4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vertical_menu = _resolveComponent("vertical-menu");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.navItemLists.length ? (_openBlock(), _createBlock(_component_vertical_menu, {
    key: 0,
    items: $options.navItemLists,
    "opened-groups": $data.openedGroups,
    class: "flex-grow mr-4 pr-4 min-w-210px"
  }, null, 8 /* PROPS */, ["items", "opened-groups"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view, null, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        "filtered-risk-projects": $options.filteredRiskProjects,
        onUpdateBankruptcyCount: $options.updateBankruptcyCount,
        onRefreshMenu: $options.prepareNavigation,
        onRefreshMenuWithRiskProjects: $options.init,
        onRefreshRiskProjects: $options.getRiskProjects,
        onUpdateHeader: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('updateHeader');
        })
      }, null, 8 /* PROPS */, ["filtered-risk-projects", "onUpdateBankruptcyCount", "onRefreshMenu", "onRefreshMenuWithRiskProjects", "onRefreshRiskProjects"]))];
    }),
    _: 1 /* STABLE */
  })])]);
}