function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { addContact, getCompanies as _getCompanies, getContacts as _getContacts } from '@/api/admin/companies';
import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import Notification from '@/components/Molecules/Notification';
import StaticTable from '@/components/Molecules/StaticTable';
import AddContactPersonModal from '@/components/Organisms/Contracts/AddContactPersonModal';
import CompanyContactModal from '@/components/Organisms/Companies/CompanyContactModal';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import CreateCompany from '@/components/Molecules/Companies/CreateCompany';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import { escapeHtml } from '@/utils';
import { compareStrings } from '@/utils/compare';
import { getCompanyWithUrl } from '@/utils/format/companies';
import { MODULES } from '@/constants/modules';
export default {
  name: 'ContractParty',
  description: 'This is the Page component for /contracts/contract/:id/contractParty',
  token: '<contract-party data="" />',
  components: {
    MainWell: MainWell,
    FeatherIcon: FeatherIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    PreviewList: PreviewList,
    StaticTable: StaticTable,
    AddContactPersonModal: AddContactPersonModal,
    RismaTitle: RismaTitle,
    CompanyContactModal: CompanyContactModal,
    Notification: Notification,
    PreviewModalLink: PreviewModalLink,
    CreateCompany: CreateCompany,
    NotificationPopUp: NotificationPopUp
  },
  mixins: [NotificationPopUpMixin],
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Contract object'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['propertyChanged'],
  data: function data() {
    var _dataset;
    return {
      isLoaded: false,
      MODULES: MODULES,
      basicGroupedData: {},
      selectedGroup: '',
      dataset: (_dataset = {}, _defineProperty(_dataset, MODULES.COMPANY, []), _defineProperty(_dataset, MODULES.COMPANY_CONTACT, []), _dataset),
      companies: [],
      showContactModal: false,
      errorNotification: '',
      translate: getTranslate['ContractParty']()
    };
  },
  computed: {
    groupedData: function groupedData() {
      var _this = this;
      if (!this.isLoaded) return {};
      var groups = _objectSpread({}, this.basicGroupedData);
      Object.keys(this.basicGroupedData).forEach(function (module) {
        groups[module].dataset = _this.prepareData(module, _this.data.links[module], _this.dataset[module]).sort(function (a, b) {
          return compareStrings(a.name, b.name, false, 'en');
        });
      });
      return groups;
    },
    availableContacts: function availableContacts() {
      var linkedCompanies = this.data.links[MODULES.COMPANY] || [];
      var linkedContacts = this.data.links[MODULES.COMPANY_CONTACT] || [];
      var allContacts = this.dataset[MODULES.COMPANY_CONTACT];
      if (!linkedCompanies || !linkedCompanies.length) return [];
      return allContacts.filter(function (_ref) {
        var companyId = _ref.companyId,
          id = _ref.id;
        return linkedCompanies.includes(companyId) && !linkedContacts.includes(id);
      });
    },
    contractsOptions: function contractsOptions() {
      var _this2 = this;
      return this.data.links[MODULES.COMPANY].reduce(function (acc, companyId) {
        var company = _this2.dataset[MODULES.COMPANY].find(function (company) {
          return +company.id === +companyId;
        });
        if (company) {
          acc.push({
            value: company.id,
            label: company.name
          });
          return acc;
        }
        return acc;
      }, []);
    }
  },
  beforeMount: function beforeMount() {
    this.initBasicGroupedData();
  },
  mounted: function mounted() {
    var _this3 = this;
    this.getAllData().then(function () {
      _this3.isLoaded = true;
    });
  },
  methods: {
    initBasicGroupedData: function initBasicGroupedData() {
      var _this$basicGroupedDat;
      this.basicGroupedData = (_this$basicGroupedDat = {}, _defineProperty(_this$basicGroupedDat, MODULES.COMPANY, {
        title: 'Contract party',
        columns: [this.translate.name, this.translate.address, this.translate.city, this.translate.zipcode, this.translate.phone, this.translate.vatNumber, ''],
        isAddBtnExist: true
      }), _defineProperty(_this$basicGroupedDat, MODULES.COMPANY_CONTACT, {
        title: 'Contacts',
        columns: [this.translate.name, this.translate.title, this.translate.phone, this.translate.email],
        isAddBtnExist: true
      }), _this$basicGroupedDat);
    },
    nothingToShowMessage: function nothingToShowMessage(type) {
      if (type === MODULES.COMPANY) {
        return this.translate.nothingToShow;
      }
      if (type === MODULES.COMPANY_CONTACT) {
        if (!this.availableContacts.length) {
          if (!this.groupedData[MODULES.COMPANY].dataset.length) {
            this.basicGroupedData[MODULES.COMPANY_CONTACT].isAddBtnExist = false;
            return this.translate.youNeedToAddContractPartyBeforeAddingConta;
          } else {
            this.basicGroupedData[MODULES.COMPANY_CONTACT].isAddBtnExist = true;
            return this.translate.selectedContractPartyDoesntHaveAnyAvailable;
          }
        }
        this.basicGroupedData[MODULES.COMPANY_CONTACT].isAddBtnExist = true;
        return this.translate.nothingToShow;
      }
      return '';
    },
    getAllData: function getAllData() {
      return Promise.all([this.getCompanies(), this.getContacts()]);
    },
    getLinkItems: function getLinkItems() {
      var items = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      return items.map(function (item) {
        return list.find(function (l) {
          return l.id === item;
        });
      }).filter(Boolean);
    },
    getContacts: function getContacts() {
      var _this4 = this;
      return _getContacts().then(function (response) {
        _this4.dataset[MODULES.COMPANY_CONTACT] = response.list;
      });
    },
    getCompanies: function getCompanies() {
      var _this5 = this;
      return _getCompanies().then(function (response) {
        _this5.dataset[MODULES.COMPANY] = response.list;
      });
    },
    addNewCompany: function addNewCompany(company) {
      this.setNotificationData(company);
      this.turnAfterRefreshNotification();
      this.dataset[MODULES.COMPANY].push(company);
      this.addItem(company.id, MODULES.COMPANY);
    },
    prepareData: function prepareData(module, list, dataset) {
      if (list !== null && list !== void 0 && list.length) {
        var linksItems = this.getLinkItems(list, dataset);
        return this.prepareDatasetByModule(module, linksItems);
      }
      return [];
    },
    prepareDatasetByModule: function prepareDatasetByModule(module, list) {
      switch (module) {
        case MODULES.COMPANY_CONTACT:
          return this.prepareContactsDataset(list);
        case MODULES.COMPANY:
          return this.prepareContractPartyDataset(list);
      }
    },
    prepareContractPartyDataset: function prepareContractPartyDataset(list) {
      return list.map(function (item) {
        return {
          name: {
            activity: getCompanyWithUrl(item)
          },
          address: escapeHtml(item.address) || '',
          city: escapeHtml(item.city) || '',
          zCode: escapeHtml(item.zipCode) || '',
          phone: escapeHtml(item.phone) || '',
          vatNo: escapeHtml(item.vatNo) || '',
          delete: {
            id: item.id,
            type: MODULES.COMPANY
          }
        };
      });
    },
    prepareContactsDataset: function prepareContactsDataset(list) {
      return list.map(function (item) {
        return {
          name: item.name,
          title: escapeHtml(item.title),
          phone: escapeHtml(item.phone),
          email: item.email,
          delete: {
            id: item.id,
            type: MODULES.COMPANY_CONTACT
          }
        };
      });
    },
    toggleCreateModal: function toggleCreateModal(type) {
      if (this.readOnly) {
        return;
      }
      if (!this.availableContacts.length && type === MODULES.COMPANY_CONTACT) {
        this.showContactModal = true;
        return;
      }
      this.selectedGroup = type;
    },
    closeModal: function closeModal() {
      this.selectedGroup = '';
      this.showContactModal = false;
    },
    addItem: function addItem(id, group) {
      var ids = this.data.links[group] || [];
      var linksValue = [].concat(_toConsumableArray(ids), [id]).sort(function (a, b) {
        return a - b;
      });
      this.emitUpdateLinks(_objectSpread(_objectSpread({}, this.data.links), {}, _defineProperty({}, group, linksValue)));
      this.closeModal();
    },
    deleteItem: function deleteItem(itemId, objectType) {
      var links = this.data.links[objectType].filter(function (item) {
        return item !== itemId;
      });
      this.emitUpdateLinks(_objectSpread(_objectSpread({}, this.data.links), {}, _defineProperty({}, objectType, links)));
    },
    emitUpdateLinks: function emitUpdateLinks(value) {
      this.$emit('propertyChanged', {
        property: 'links',
        value: value
      });
    },
    handleCreateContact: function handleCreateContact(contactData, companyId) {
      var _this6 = this;
      return addContact(companyId, contactData).then(function (response) {
        var company = _this6.dataset[MODULES.COMPANY].find(function (company) {
          return company.id === companyId;
        });
        response.companyId = companyId;
        company.links[MODULES.COMPANY_CONTACT] = company.links[MODULES.COMPANY_CONTACT] || [];
        company.links[MODULES.COMPANY_CONTACT].push(response.id);
        _this6.dataset[MODULES.COMPANY_CONTACT].push(response);
        _this6.addItem(response.id, MODULES.COMPANY_CONTACT);
      }).catch(function (error) {
        _this6.errorNotification = error;
        _this6.closeModal();
      });
    }
  }
};