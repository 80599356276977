/**
 * ControlFieldsCompatibility is a list of backend values
 * and their equivalent on client part
 * (names of fields and human-ready values)
 *
 * Uses for: Control Changelog,
 */

export default {
  traffic_light: 'Traffic light',
  title: 'Title',
  deadline_utc: 'Deadline',
  deadline_tz: 'Timezone',
  description: 'Description',
  risk_id: 'Risk',
  accounts_id: 'Accounts',
  process_id: 'Processes',
  organisation: 'Organisations',
  organisations: 'Organisations',
  frequency: 'Frequency',
  type_1: 'Key control',
  type_2: 'Manual vs automated',
  type_3: 'Preventive vs detective',
  type_4: 'Complexity',
  owners: 'Responsible',
  escalation: 'Escalation',
  review_ready: 'Reviewers',
  daily_team: 'Daily team',
  activity_type_id: 'Activity type',
  trafficlight_custom: 'Traffic light turns yellow',
  tags: 'Tags',
  created: 'Created',
  deadline_days: 'Calulated deadline day',
  deadline_before_after: 'Calculated deadline before/after',
  create_remote_tickets: 'Create remote tickets',
  review_deadline: 'Review deadline',
  dataprocessor_processor: 'Data processor',
  activityTypeId: 'Activity type',
  dailyTeamIds: 'Daily team',
  userIds: 'Responsible, reviewer or escalation',
  type1: 'Key control',
  type2: 'Manual vs automated',
  type3: 'Preventive vs detective',
  type4: 'Complexity',
  turnYellowDaysBeforeDeadLine: 'Traffic light turns yellow',
  risks: 'Risk',
  accounts: 'Accounts',
  processes: 'Processes'
};