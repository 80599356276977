var _excluded = ["gaps", "gapTypeIds"];
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useTagsStore } from '@/Store/tagsStore';
import VueMultiselect from 'vue-multiselect';
import { searchGaps } from '@/api/compliance/reports/gap';
import { getTreeByProjectId } from '@/api/compliance/tree';
import { getSingle as getComplianceProjectsById } from '@/api/compliance/projects';
import { getRiskProjects } from '@/api/risk/risk';
import { Colors } from '@/Colors';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { filterAsset, getOrganisationsFromIds, getUsersFromIds } from '@/utils/format/objectsFromIds';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { prepareDatasetToExport } from '@/utils/export/prepareToExport';
import { printTable } from '@/utils/export/print';
import { exportToXlsx } from '@/utils/export/excel';
import { mapToCommaList } from '@/utils/format/mapTo';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaPagesize from '@/components/Atoms/RismaDatatable/RismaPagesize';
import Pagination from '@/components/Atoms/Pagination';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import Alert from '@/components/Molecules/Modal/Alert';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import { GapResults, NoSearchResults, ReportSearchForm } from '../index';
import GapAnalysis from '@/models/compliance/GapAnalysis';
import { gapReportColumns } from '@/constants/compliance/GapReportDatatableColumns';
import { ReportTypes } from '@/constants/reports';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'GapReport',
  components: {
    NoSearchResults: NoSearchResults,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    VueMultiselect: VueMultiselect,
    GapResults: GapResults,
    ReportSearchForm: ReportSearchForm,
    RismaDatatable: RismaDatatable,
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink,
    Trafficlight: Trafficlight,
    Pagination: Pagination,
    RismaPagesize: RismaPagesize,
    FeatherIcon: FeatherIcon,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    Alert: Alert,
    SaveReport: SaveReport
  },
  mixins: [RismaDatatableDefaultMixin, RaciTitleMixin, ProcessLibraryTitleMixin, SaveReportMixin],
  props: {
    projectId: {
      type: [Number, String],
      required: true,
      note: 'Id of current project'
    },
    savedReportOptions: {
      type: Object,
      default: null
    }
  },
  setup: function setup(props) {
    var localStorageKey = "gap_report_filters_".concat(props.projectId);
    return {
      reportOptions: getLocalStorageComputed(localStorageKey, {}, true)
    };
  },
  data: function data() {
    return {
      confirmCallback: null,
      confirmMessage: '',
      showConfirmationAlert: false,
      filenameToExport: 'RISMAbusiness',
      printHeadTitle: 'RISMAcompliance',
      datasetToExport: [],
      pageStart: 0,
      pageSize: 25,
      totalOutput: 0,
      gapNumberToShowConfirmModal: 1000,
      pagingOptions: [10, 25, 50, 100],
      sorting: {
        sortField: '',
        sortDir: ''
      },
      output: [],
      dataset: [],
      loading: false,
      lights: Colors.gaps,
      selectedGAPS: [],
      selectedUsers: [],
      selectedNodes: [],
      selectedTags: [],
      selectedOrganisations: [],
      selectedAttributes: [],
      extendedView: true,
      noResults: false,
      columnsMeta: [{
        targets: ['gapSchemaTitle', 'processOwner', 'rpiGross', 'rpiNet'],
        visible: false
      }, {
        targets: ['domain', 'tags'],
        width: '200px'
      }, {
        targets: ['attributes'],
        width: '400px'
      }, {
        targets: ['analysis', 'analysisControlsInitiatives', 'linkedControls', 'linkedInitiatives', 'processLibrary', 'linkedRisks'],
        width: '600px'
      }, {
        targets: ['nodeTitle', 'rootNodeTitle', 'subHeadline', 'requirements', 'organisation'],
        width: '300px'
      }],
      alwaysVisibleExportColumns: ['id', 'nodeId', 'organisation', 'requirements', 'articleId'],
      tree: null,
      project: {},
      searchArgs: {},
      searchError: '',
      riskProjects: [],
      slotVForFields: ['linkedControls', 'linkedInitiatives', 'processLibrary', 'linkedRisks'],
      hasBackendSorting: true,
      firstInit: true,
      showDefaultPagination: false,
      disabledFilterByColumns: ['rootNodeTitle', 'analysis', 'RiskAssessment', 'analysisControlsInitiatives', 'requirements', 'articleId'],
      translate: getTranslate['gap'](),
      includeChildNodes: false,
      gapTypesSelected: [],
      gapTypeIdSelected: null,
      reportType: ReportTypes.COMPLIANCE_GAP,
      initialFilters: {
        nodes: [],
        includeChildNodes: false,
        scoringIncluded: true
      },
      stateLocal: "gap_report_table_formatting_".concat(this.projectId)
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useTagsStore, ['tags'])), {}, {
    columns: function columns() {
      var columns = {
        nodeId: this.translate.process + ' ' + this.translate.id,
        domain: this.translate.domain,
        nodeTitle: this.translate.process,
        rootNodeTitle: this.translate.parentProcess,
        trafficLight: this.translate.trafficLight,
        headline: this.translate.headline,
        subHeadline: this.translate.subHeadline,
        analysis: this.translate.analysis,
        attributes: this.translate.attributes,
        linkedControls: this.translate.linkedControls,
        linkedInitiatives: this.translate.linkedInitiatives,
        processLibrary: this.processLibraryTitle,
        linkedRisks: this.translate.linkedRisks,
        id: this.translate.gapId,
        gapSchemaTitle: this.translate.gapSchemaName,
        organisation: this.translate.organisation,
        processOwner: this.responsibleTitle(true),
        requirements: this.translate.requirement,
        tags: this.translate.tags,
        articleId: this.translate.article + ' ID'
      };
      if (this.project.enableGapRiskModule) {
        columns.analysisControlsInitiatives = this.translate.riskAssessment, columns.impactBefore = this.translate.impactBefore;
        columns.likelihoodBefore = this.translate.likelihoodBefore;
        columns.impactAfter = this.translate.impactAfter;
        columns.likelihoodAfter = this.translate.likelihoodAfter;
        columns.rpiBefore = this.translate.rpi + ' ' + this.translate.before;
        columns.rpiAfter = this.translate.rpi + ' ' + this.translate.after;
      }
      if (this.showGapArticlesTypes) columns.gapTypes = this.translate.gapTypes;
      return columns;
    },
    columnsOrder: function columnsOrder() {
      var gapRiskModuleColumns = this.project.enableGapRiskModule ? gapReportColumns.gapRiskModuleColumns : [];
      return [].concat(_toConsumableArray(gapReportColumns.baseColumns), _toConsumableArray(gapRiskModuleColumns), _toConsumableArray(gapReportColumns.extraColumns));
    },
    filters: function filters() {
      var _this$selectedAttribu;
      var filters = {};
      if (this.selectedGAPS.length > 0) {
        var ids = this.selectedGAPS.map(function (sGAP) {
          return sGAP.number;
        });
        filters['traffic_light'] = ids;
      }
      if (this.selectedNodes.length > 0) {
        filters['id'] = this.selectedNodes;
      }
      if (this.selectedUsers.length > 0) {
        filters['responsible'] = this.selectedUsers;
      }
      if ((_this$selectedAttribu = this.selectedAttributes) !== null && _this$selectedAttribu !== void 0 && _this$selectedAttribu.length) {
        filters['labelIds'] = this.selectedAttributes;
      }
      if (this.selectedOrganisations.length > 0) {
        filters['organisationIds'] = this.selectedOrganisations;
      }
      if (this.selectedTags.length) {
        filters['tagIds'] = this.selectedTags;
      }
      if (this.includeChildNodes) {
        filters['includeUnderlyingNodes'] = true;
      }
      if (this.showGapArticlesTypes) {
        filters['gapTypeIds'] = this.gapTypeIds;
      }
      return filters;
    },
    gapTypeIds: function gapTypeIds() {
      return this.enabledGapTypeMultiselect ? this.gapTypesSelected.map(function (type) {
        return type.id;
      }) : [this.gapTypeIdSelected];
    },
    confirmExportMessage: function confirmExportMessage() {
      if (this.totalOutput < this.gapNumberToShowConfirmModal) return '';
      return "".concat(this.translate.areYouSureYouWantToExport, " ").concat(this.totalOutput, " ").concat(this.translate.rowsToExcel, "?");
    },
    confirmPrintMessage: function confirmPrintMessage() {
      if (this.totalOutput < this.gapNumberToShowConfirmModal) return '';
      return "".concat(this.translate.areYouSureYouWantToPrint, " ").concat(this.totalOutput, " ").concat(this.translate.rows, "?");
    },
    enabledGapTypeMultiselect: function enabledGapTypeMultiselect() {
      return this.getSettingValue('feature.gap_type_multiselect');
    },
    showGapArticlesTypes: function showGapArticlesTypes() {
      return !!this.project.showGapArticleTypes;
    },
    gapTypesOptions: function gapTypesOptions() {
      var _this$project$gapType;
      var types = (_this$project$gapType = this.project.gapTypes) !== null && _this$project$gapType !== void 0 ? _this$project$gapType : [];
      return types.map(function (type) {
        return _objectSpread(_objectSpread({}, type), {}, {
          value: type.id
        });
      });
    }
  }),
  watch: {
    savedReportOptions: function savedReportOptions() {
      if (this.savedReportOptions) {
        this.setAndLoadSavedReport(this.savedReportOptions);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    Promise.all([getTreeByProjectId(this.projectId), getComplianceProjectsById(this.projectId, true), getRiskProjects()]).then(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 3),
        tree = _ref2[0],
        project = _ref2[1],
        riskProjects = _ref2[2];
      _this.tree = tree;
      _this.project = project;
      _this.riskProjects = riskProjects.list;
    }).then(this.setInitialState);
  },
  methods: {
    setInitialState: function setInitialState() {
      if (this.savedReportOptions) {
        this.setAndLoadSavedReport(this.savedReportOptions);
      } else {
        this.setInitialValues(this.reportOptions);
      }
    },
    createDataForTable: function createDataForTable(output) {
      var _this2 = this;
      var result = [];
      output.forEach(function (gapRow) {
        var organisationsName = gapRow.nodeOrganisations.map(function (iOrg) {
          return iOrg.visible_name;
        });
        var responsibleUsers = gapRow.nodeResponsible.map(function (user) {
          return user.display_name;
        });
        var row = {
          nodeId: {
            field: gapRow.nodeId
          },
          domain: {
            field: gapRow.domain || ''
          },
          nodeTitle: {
            field: {
              activity: getFieldsFromObjectForPreview(gapRow),
              title: gapRow.nodeTitle
            },
            fieldText: gapRow.nodeTitle
          },
          rootNodeTitle: {
            field: gapRow.rootNodeTitle
          },
          trafficLight: {
            field: DatatableRenderer.prepareGapColorField(gapRow.trafficLight),
            fieldText: gapRow.trafficLight
          },
          headline: {
            field: gapRow.headline
          },
          subHeadline: {
            field: gapRow.subHeadline
          },
          analysis: {
            field: gapRow.analysis
          },
          attributes: {
            attributes: gapRow.attributes,
            field: ''
          },
          linkedControls: {
            field: gapRow.linkedControls.filter(function (link) {
              return link;
            }).map(function (link) {
              return {
                activity: getFieldsFromObjectForPreview(link),
                title: "".concat(link.cpath, " ").concat(link.title)
              };
            }),
            fieldText: gapRow.linkedControls.filter(function (item) {
              return item;
            }).map(function (link) {
              return "".concat(link.cpath, " ").concat(link.title);
            }).join(','),
            id: gapRow.nodeId
          },
          linkedInitiatives: {
            field: gapRow.linkedInitiatives.filter(function (link) {
              return link;
            }).map(function (link) {
              return {
                activity: getFieldsFromObjectForPreview(link),
                title: "".concat(link.ipath, " ").concat(link.title)
              };
            }),
            fieldText: gapRow.linkedInitiatives.filter(function (item) {
              return item;
            }).map(function (link) {
              return "".concat(link.ipath, " ").concat(link.title);
            }).join(','),
            id: gapRow.nodeId
          },
          processLibrary: {
            field: gapRow.processLibrary.filter(function (item) {
              return item;
            }).map(function (link) {
              return {
                activity: getFieldsFromObjectForPreview(link),
                title: link.title
              };
            }),
            fieldText: gapRow.processLibrary.filter(function (item) {
              return item;
            }).map(function (link) {
              return link.title;
            }).join(','),
            id: gapRow.nodeId
          },
          linkedRisks: {
            field: gapRow.linkedRisks.filter(function (link) {
              return link;
            }).map(function (link) {
              return {
                activity: getFieldsFromObjectForPreview(link),
                title: "".concat(link.rno, " ").concat(link.title, " ").concat(_this2.getRiskValues(link))
              };
            }),
            fieldText: gapRow.linkedRisks.filter(function (item) {
              return item;
            }).map(function (link) {
              return "".concat(link.rno, " ").concat(link.title);
            }).join(','),
            id: gapRow.nodeId
          },
          id: {
            field: gapRow.id
          },
          articleId: {
            field: gapRow.articleId
          },
          gapSchemaTitle: {
            field: gapRow.gapSchemaTitle
          },
          organisation: {
            field: organisationsName.join('; ')
          },
          processOwner: {
            field: responsibleUsers.join(', ')
          },
          requirements: {
            field: gapRow.requirement
          },
          tags: {
            field: _this2.getTagsNames(_this2.tags, gapRow.tags)
          }
        };
        if (_this2.project.enableGapRiskModule) {
          row.analysisControlsInitiatives = {
            field: gapRow.analysisControlsInitiatives
          };
          row.likelihoodBefore = {
            field: gapRow.likelihoodGross
          };
          row.impactBefore = {
            field: gapRow.impactGross
          };
          row.likelihoodAfter = {
            field: gapRow.likelihoodNet
          };
          row.impactAfter = {
            field: gapRow.impactNet
          };
          row.rpiAfter = {
            field: gapRow.rpiNet
          };
          row.rpiBefore = {
            field: gapRow.rpiGross
          };
        }
        if (_this2.showGapArticlesTypes) {
          row.gapTypes = {
            field: mapToCommaList(filterAsset(_this2.gapTypesOptions, gapRow.gapTypeIds || []), 'label')
          };
        }
        result.push(row);
      });
      return result;
    },
    getRiskValues: function getRiskValues(risk) {
      if (this.getSettingValue('feature.show_risk_links_with_levels') !== 1) {
        return '';
      }
      return DatatableRenderer.getRiskValues(risk, this.riskProjects);
    },
    gapLabels: function gapLabels(light) {
      return this.$trans(light.label);
    },
    handleDoSearch: function handleDoSearch(_ref3) {
      var _this3 = this;
      var _ref3$organisations = _ref3.organisations,
        organisations = _ref3$organisations === void 0 ? [] : _ref3$organisations,
        _ref3$users = _ref3.users,
        users = _ref3$users === void 0 ? [] : _ref3$users,
        _ref3$nodes = _ref3.nodes,
        nodes = _ref3$nodes === void 0 ? [] : _ref3$nodes,
        _ref3$tags = _ref3.tags,
        tags = _ref3$tags === void 0 ? [] : _ref3$tags,
        _ref3$attributes = _ref3.attributes,
        attributes = _ref3$attributes === void 0 ? [] : _ref3$attributes,
        _ref3$includeChildNod = _ref3.includeChildNodes,
        includeChildNodes = _ref3$includeChildNod === void 0 ? true : _ref3$includeChildNod;
      var resetQuery = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      this.searchArgs = {
        organisations: organisations,
        users: users,
        nodes: nodes,
        tags: tags,
        attributes: attributes,
        includeChildNodes: includeChildNodes
      };
      this.output = [];
      this.datasetSlice = [];
      this.selectedOrganisations = organisations;
      this.selectedUsers = users;
      this.selectedAttributes = attributes;
      this.selectedNodes = nodes;
      this.selectedTags = tags;
      this.includeChildNodes = includeChildNodes;
      this.noResults = false;
      this.searchError = '';
      this.loading = true;
      resetQuery && this.resetSavedReportQuery();
      this.updateReportOptions();
      var promise = searchGaps(this.projectId, this.filters, this.pageSize, this.pageStart, this.sorting);
      return promise.then(function (response) {
        _this3.totalOutput = response.total;
        _this3.output = _this3.prepareOutput(response);
        _this3.dataset = _this3.createDataForTable(_this3.output);
        _this3.enhanceDataset(_this3.dataset, _this3.columns);
        _this3.loading = false;
        _this3.dataset.length && _this3.loadSavedReport();
        if (_this3.output.length === 0) {
          _this3.noResults = true;
        }
      }).catch(function () {
        _this3.loading = false;
        _this3.noResults = true;
        _this3.searchError = _this3.translate.errorOccurredDuringSearchPleaseDoublecheck;
      });
    },
    updateReportOptions: function updateReportOptions() {
      this.reportOptions = _objectSpread(_objectSpread({}, this.searchArgs), {}, {
        gaps: this.selectedGAPS.map(function (item) {
          return item.number;
        }),
        gapTypeIds: this.gapTypeIds
      });
    },
    getUnlimitedGaps: function getUnlimitedGaps() {
      return searchGaps(this.projectId, this.filters, null, 0, this.sorting);
    },
    onConfirm: function onConfirm() {
      this.confirmCallback();
      this.showConfirmationAlert = false;
      this.confirmCallback = null;
    },
    onExcelExportClick: function onExcelExportClick(columnsToExport) {
      var _this4 = this;
      var excelExportFunc = function excelExportFunc() {
        return _this4.prepareDataToExport(columnsToExport).then(function () {
          exportToXlsx(_this4.datasetToExport, columnsToExport, _this4.filenameToExport);
        });
      };
      if (this.confirmExportMessage) {
        this.confirmCallback = excelExportFunc;
        this.confirmMessage = this.confirmExportMessage;
        this.showConfirmationAlert = true;
        return;
      }
      return excelExportFunc();
    },
    onPrintClick: function onPrintClick(columnsToExport) {
      var _this5 = this;
      var printFunc = function printFunc() {
        return _this5.prepareDataToExport(columnsToExport).then(function () {
          printTable(_this5.datasetToExport, columnsToExport, _this5.printHeadTitle);
        });
      };
      if (this.confirmPrintMessage) {
        this.confirmCallback = printFunc;
        this.confirmMessage = this.confirmPrintMessage;
        this.showConfirmationAlert = true;
        return;
      }
      return printFunc();
    },
    prepareDataToExport: function prepareDataToExport(columnsToExport) {
      var _this6 = this;
      return this.getUnlimitedGaps().then(function (response) {
        var exportOutput = _this6.prepareOutput(response);
        var exportDataset = _this6.createDataForTable(exportOutput);
        _this6.datasetToExport = prepareDatasetToExport(exportDataset, columnsToExport, true);
      });
    },
    prepareOutput: function prepareOutput(gapRows) {
      var _this7 = this;
      var output = [];
      var allNodes = gapRows.meta.nodes;
      var node = {
        id: null
      };
      gapRows.list && gapRows.list.forEach(function (gapData) {
        var _gapArticle$domain;
        if (!node.id || node.id !== gapData.nodeId) {
          node = allNodes.find(function (n) {
            return n.id === gapData.nodeId;
          });
        }
        var gapSchema = gapRows.meta.gapSchemas[node.gapSchemaId];
        var gapArticle = gapSchema && gapSchema.articles.find(function (art) {
          return art.id === gapData.articleId;
        });
        var gap = _this7.getGapAnalysis(gapData.articleId, gapData);
        if (_this7.filters['traffic_light'] && !_this7.filters['traffic_light'].includes(gap.analysis_rag)) {
          return;
        }
        var gapRow = {
          nodeId: node.id,
          domain: gapArticle === null || gapArticle === void 0 || (_gapArticle$domain = gapArticle.domain) === null || _gapArticle$domain === void 0 ? void 0 : _gapArticle$domain.label,
          trafficLight: gap.analysis_rag,
          nodeTitle: node.title,
          rootNodeTitle: _this7.getRootNodeTitle(node),
          nodeUrl: node.url,
          nodeOrganisations: getOrganisationsFromIds(node.organisationIds, _this7.organisations),
          nodeProjectId: node.compliance_projects_id,
          objType: gap.objType,
          nodeResponsible: getUsersFromIds(node.responsibleUserIds, _this7.users),
          gapSchemaTitle: gapSchema.title,
          id: gap.id,
          headline: gapArticle.article,
          subHeadline: gapArticle.articleTitle,
          analysis: gap.analysis,
          attributes: gapArticle.attributes,
          analysisControlsInitiatives: gap.analysisControlsInitiatives,
          linkedControls: gap.links.control || [],
          linkedInitiatives: gap.links.execution || [],
          processLibrary: gap.links.compliance_process_tree_node || [],
          linkedRisks: gap.links.risk || [],
          requirement: gapArticle.requirement,
          tags: node.tagIds,
          likelihoodNet: gap.likelihood_net,
          likelihoodGross: gap.likelihood_gross,
          impactNet: gap.impact_net,
          impactGross: gap.impact_gross,
          rpiNet: gap.rpi_net,
          rpiGross: gap.rpi_gross,
          articleId: gap.articleId,
          gapTypeIds: gap.types
        };
        output.push(gapRow);
      });
      return output;
    },
    getGapAnalysis: function getGapAnalysis(articleId, gapData) {
      return new GapAnalysis(articleId, gapData);
    },
    pageChange: function pageChange(e) {
      this.pageLength = this.pageSize;
      if (e.mounted) return;
      this.pageStart = e.start;
      this.refreshTable(false).then(function () {
        document.body.scrollTop = document.body.scrollHeight;
      });
    },
    backendSortRequest: function backendSortRequest(_ref4) {
      var index = _ref4.index,
        dir = _ref4.dir;
      this.sorting = {
        sortField: index,
        sortDir: dir
      };
      this.refreshTable().then(function () {
        document.body.scrollTop = document.body.scrollHeight;
      });
    },
    onPagesizeChange: function onPagesizeChange(newPageSize) {
      this.pageSize = newPageSize;
    },
    handleClearQuery: function handleClearQuery() {
      this.selectedNodes = [];
      this.selectedUsers = [];
      this.selectedOrganisations = [];
      this.selectedGAPS = [];
      this.selectedAttributes = [];
      this.gapTypesSelected = [];
      this.reportOptions = {};
      this.resetSavedReportQuery();
    },
    getRootNodeTitle: function getRootNodeTitle(node) {
      if (!node.visibleRootNodeId) return '';
      var visibleRootNode = this.tree.children.find(function (visibleRootNode) {
        return visibleRootNode.id === node.visibleRootNodeId;
      });
      if (!visibleRootNode) return '';
      return visibleRootNode.title;
    },
    setSelectedGaps: function setSelectedGaps(event) {
      this.selectedGAPS = event;
    },
    refreshTable: function refreshTable() {
      var resetQuery = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      return this.handleDoSearch(this.searchArgs, resetQuery);
    },
    getTagsNames: function getTagsNames(tags, ids) {
      return mapToCommaList(filterAsset(tags, ids), 'tag');
    },
    setAndLoadSavedReport: function setAndLoadSavedReport(initialOptions) {
      var _this8 = this;
      this.setInitialValues(initialOptions);
      return this.handleDoSearch(initialOptions, false).then(function () {
        var _this8$datasetSlice;
        return ((_this8$datasetSlice = _this8.datasetSlice) === null || _this8$datasetSlice === void 0 ? void 0 : _this8$datasetSlice.length) && _this8.loadSavedReport();
      });
    },
    setInitialValues: function setInitialValues(_ref5) {
      var gaps = _ref5.gaps,
        gapTypeIds = _ref5.gapTypeIds,
        rest = _objectWithoutProperties(_ref5, _excluded);
      this.initialFilters = _objectSpread(_objectSpread({}, this.initialFilters), rest);
      if (gaps !== null && gaps !== void 0 && gaps.length) {
        this.selectedGAPS = this.lights.filter(function (item) {
          return gaps.includes(item.number);
        });
      }
      if (this.enabledGapTypeMultiselect && gapTypeIds) {
        this.gapTypesSelected = this.gapTypesOptions.filter(function (type) {
          return gapTypeIds.includes(type.id);
        });
      }
      if (!this.enabledGapTypeMultiselect && gapTypeIds) {
        this.gapTypeIdSelected = gapTypeIds[0];
      }
    }
  }
};