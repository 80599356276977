import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  class: "flex mb-3"
};
var _hoisted_3 = {
  class: "w-1/3 pr-6"
};
var _hoisted_4 = {
  class: "w-2/3"
};
var _hoisted_5 = {
  class: "flex mb-3"
};
var _hoisted_6 = {
  class: "w-1/3 pr-6"
};
var _hoisted_7 = {
  class: "w-2/3"
};
var _hoisted_8 = {
  class: "flex mb-3"
};
var _hoisted_9 = {
  class: "w-1/3 pr-6"
};
var _hoisted_10 = {
  class: "w-2/3"
};
var _hoisted_11 = {
  class: "flex mb-3"
};
var _hoisted_12 = {
  class: "w-1/3 pr-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_risma_button = _resolveComponent("risma-button");
  return !$data.loading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.generateNewReport,
    type: "medium",
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.registerNode,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_single_select, {
    modelValue: $data.registerNodeId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.registerNodeId = $event;
    }),
    placeholder: $data.translate.selectRegisterNode,
    options: $options.childrenNodesForSelect,
    "un-sorted": true
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "options"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.registerQuestions,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    modelValue: $data.selectedRegisterQuestions,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.selectedRegisterQuestions = $event;
    }),
    "selected-options": $data.selectedRegisterQuestions,
    options: $options.registerQuestions,
    "label-key": "question",
    "label-function": $options.questionLabelFunction,
    "un-sorted": true
  }, null, 8 /* PROPS */, ["modelValue", "selected-options", "options", "label-function"])])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_title, {
    title: $data.translate.preliminaryAnalysisNode,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_single_select, {
    modelValue: $data.preliminaryAnalysisNodeId,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.preliminaryAnalysisNodeId = $event;
    }),
    placeholder: $data.translate.selectPreliminaryAnalysisNode,
    options: $options.childrenNodesForSelect,
    "un-sorted": true
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "options"])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.preliminaryAnalysisGaps,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    modelValue: $data.selectedPreliminaryAnalysisGapArticles,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.selectedPreliminaryAnalysisGapArticles = $event;
    }),
    "selected-options": $data.selectedPreliminaryAnalysisGapArticles,
    options: $options.preliminaryAnalysisNodeGapArticles,
    "label-key": "article",
    "label-function": $options.gapLabelFunction,
    "un-sorted": true
  }, null, 8 /* PROPS */, ["modelValue", "selected-options", "options", "label-function"])])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $data.translate.assessmentNode,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_single_select, {
    modelValue: $data.assessmentNodeId,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return $data.assessmentNodeId = $event;
    }),
    placeholder: $data.translate.selectAssessmentNode,
    options: $options.childrenNodesForSelect,
    "un-sorted": true
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "options"])]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_risma_title, {
    title: $data.translate.assessmentGaps,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    modelValue: $data.selectedAssessmentNodeGapArticles,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $data.selectedAssessmentNodeGapArticles = $event;
    }),
    "selected-options": $data.selectedAssessmentNodeGapArticles,
    options: $options.assessmentNodeGapArticles,
    "label-key": "article",
    "label-function": $options.gapLabelFunction,
    "un-sorted": true
  }, null, 8 /* PROPS */, ["modelValue", "selected-options", "options", "label-function"])])]), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createVNode(_component_risma_title, {
    title: $data.translate.importantOrCriticalNode,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_single_select, {
    modelValue: $data.importantOrCriticalNodeId,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $data.importantOrCriticalNodeId = $event;
    }),
    placeholder: $data.translate.selectImportOrCriticalNode,
    options: $options.childrenNodesForSelect,
    "un-sorted": true
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "options"])])]), _createVNode(_component_risma_button, {
    text: $data.translate.createNewReport,
    type: "save",
    onClick: $options.createReport
  }, null, 8 /* PROPS */, ["text", "onClick"])])) : _createCommentVNode("v-if", true);
}