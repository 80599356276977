export default {
  name: 'PageNotFound',
  created: function created() {
    if (!history.state.back) {
      //so old pages could work properly
      window.location.href = '/notfound?url=' + encodeURI(window.location.pathname);
    } else {
      document.location.reload();
    }
  }
};