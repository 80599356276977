import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "dpm"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_dpm_audit_questionnaire = _resolveComponent("dpm-audit-questionnaire");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showError ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$trans($data.errorMessage)), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.questionnaire !== null ? (_openBlock(), _createBlock(_component_dpm_audit_questionnaire, {
    key: 1,
    ref: "auditQuestionnaire",
    questionnaire: $data.questionnaire,
    activity: $data.dpmActivity,
    "dpm-request": $data.dpmData,
    token: $props.token,
    onUpdated: $options.loadAll,
    onHandleSubmit: $options.handleSubmit
  }, null, 8 /* PROPS */, ["questionnaire", "activity", "dpm-request", "token", "onUpdated", "onHandleSubmit"])) : _createCommentVNode("v-if", true)]);
}