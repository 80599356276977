import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "flex flex-col justify-center items-center"
};
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = {
  class: "text-white font-semibold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_renderSlot(_ctx.$slots, "default", {
    activitiesLimited: $options.activitiesLimited
  }), $options.hiddenActivities > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", {
    class: "py-3",
    innerHTML: $options.loadInfo
  }, null, 8 /* PROPS */, _hoisted_2), _createElementVNode("button", {
    class: "bg-blue-250 active:outline-none rounded-large px-6 py-2 mb-3",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.loadMore && $options.loadMore.apply($options, arguments);
    })
  }, [_createElementVNode("span", _hoisted_3, _toDisplayString($options.loadMoreLabel), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true)]);
}