import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-9d1d4fa0"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  class: "mb-3"
};
var _hoisted_3 = {
  class: "flex items-end justify-between"
};
var _hoisted_4 = {
  class: "hidden"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_save_report = _resolveComponent("save-report");
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_router_link = _resolveComponent("router-link");
  var _component_trafficlight = _resolveComponent("trafficlight");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.incidentReport
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_save_report, {
    type: $data.reportType,
    "state-local": $data.stateLocal,
    onLoad: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.applySettingsFromLoadedReport($event);
    })
  }, null, 8 /* PROPS */, ["type", "state-local"]), _createVNode(_component_risma_date_range_picker, {
    "cancel-label": "Clear",
    "clear-values-on-cancel": true,
    "start-date": $data.initRange.start,
    "end-date": $data.initRange.end,
    "local-storage-key": $data.localStorageRangeKey,
    ranges: $data.ranges,
    onChanged: $options.changedDates,
    onDiscard: $options.onDateDiscard
  }, null, 8 /* PROPS */, ["start-date", "end-date", "local-storage-key", "ranges", "onChanged", "onDiscard"])])]), _createVNode(_component_risma_datatable, {
    ref: "reportsDataTable",
    dataset: _ctx.datasetSlice,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    paging: true,
    "page-length": _ctx.pageLength,
    "filter-by-column": true,
    "disabled-filter-by-columns": $data.disabledFilterByColumns,
    "decouple-filter-fields": $data.decoupleFilterFields,
    "filter-by-render-func": $options.filterByLabel,
    order: [{
      index: 'title',
      dir: $data.SortDirection.ASC
    }],
    "wrapper-height-sticky": 350,
    "columns-meta": $data.columnsMeta,
    "columns-by-default": $options.columns,
    "state-local": $data.stateLocal,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged
  }, _createSlots({
    title: _withCtx(function (slotData) {
      return [_createVNode(_component_preview_modal_link, {
        activity: slotData.item.incident,
        onUpdated: $options.updateFromPreviewMode,
        onDismissModal: $options.onDismissModal
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_risma_title_with_icon, {
            icon: "confidential",
            title: slotData.item.fieldText,
            "title-attribute": slotData.item.hoverTitle,
            "display-icon": slotData.item.incident.confidential
          }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onUpdated", "onDismissModal"])];
    }),
    _: 2 /* DYNAMIC */
  }, [_renderList($data.slotLinkFields, function (linkCol) {
    return {
      name: linkCol,
      fn: _withCtx(function (_ref) {
        var item = _ref.item;
        return [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.field, function (linkItem, index) {
          return _openBlock(), _createBlock(_component_router_link, {
            key: "".concat(linkCol, "_").concat(index),
            class: "hover:underline",
            to: linkItem.url
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("div", null, _toDisplayString(linkItem.title) + _toDisplayString(index === item.field.length - 1 ? '' : ','), 1 /* TEXT */)];
            }),

            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]);
        }), 128 /* KEYED_FRAGMENT */))])];
      })
    };
  }), _renderList(_ctx.trafficLightRismaDatatableFields, function (trafficLightCol) {
    return {
      name: trafficLightCol,
      fn: _withCtx(function (_ref2) {
        var item = _ref2.item;
        return [item.field.color ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(trafficLightCol)
        }, [_createVNode(_component_trafficlight, {
          color: item.field.color
        }, null, 8 /* PROPS */, ["color"]), _createElementVNode("p", _hoisted_4, _toDisplayString(item.field.value) + "-" + _toDisplayString(item.field.label), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("span", {
          key: "".concat(trafficLightCol)
        }))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset", "dataset-total", "dataset-total-sliced", "page-length", "disabled-filter-by-columns", "decouple-filter-fields", "filter-by-render-func", "order", "columns-meta", "columns-by-default", "state-local", "onPageChange", "onOnStateChanged"])])) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  }));
}