import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_bar_chart = _resolveComponent("bar-chart");
  var _component_export_frame = _resolveComponent("export-frame");
  return _openBlock(), _createBlock(_component_export_frame, {
    "use-export": $props.useDefaultExport
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_bar_chart, {
        data: $options.chartData,
        options: $options.defaultOptions,
        height: $props.height
      }, null, 8 /* PROPS */, ["data", "options", "height"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["use-export"]);
}