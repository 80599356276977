import i18n from '@/i18n';
export var getTranslate = {
  InformationAssetsIAChangelog: function InformationAssetsIAChangelog() {
    return {
      changelog: i18n.t('changelog'),
      nothingToShow: i18n.t('Nothing to show'),
      fieldName: i18n.t('Field name'),
      from: i18n.t('From'),
      to: i18n.t('To'),
      date: i18n.t('Date'),
      changedBy: i18n.t('Changed by'),
      trafficLight: i18n.t('Traffic light'),
      responsible: i18n.t('Responsible'),
      accountable: i18n.t('Accountable'),
      consulted: i18n.t('Consulted'),
      informed: i18n.t('Informed')
    };
  },
  InformationAssetsIADetails: function InformationAssetsIADetails() {
    return {
      description: i18n.t('Description'),
      address: i18n.t('Address'),
      zipcode: i18n.t('Zipcode'),
      city: i18n.t('City'),
      emailAddress: i18n.t('Email address'),
      recoveryTimeObjective: i18n.t('Recovery Time Objective'),
      selectRto: i18n.t('Select RTO'),
      recoveryPointObjective: i18n.t('Recovery Point Objective'),
      selectRpo: i18n.t('Select RPO')
    };
  }
};