import PercentageBox from '@/components/Atoms/PercentageBox';
import { Colors } from '@/Colors';
export default {
  name: '',
  components: {
    PercentageBox: PercentageBox
  },
  data: function data() {
    return {
      color: Colors.primaryGreen
    };
  }
};