function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import * as ControlsApi from '@/api/controls/controls';
import * as DataAPI from '@/api/risma/data';
import ControlRelationsSectionDetails from '@/components/Organisms/Controls/ControlRelationsSectionDetails';
import Notification from '@/components/Molecules/Notification';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import DelegationWell from '@/components/Organisms/General/coreWells/DelegationWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import { ControlCreation } from '@/constants/ControlCreation';
import { DateFormats } from '@/constants/DateFormats';
import { TabTypes } from '@/constants/Tabs';
import { Timezones } from '@/constants/Timezones';
import { saveStatus } from '@/constants/SaveStatuses';
import { ControlValidationMessages } from '@/constants/ControlValidationMessages';
import { Colors } from '@/Colors';
import { UserLevels } from '@/constants/UserLevels';
import { ControlDetailsCustomFieldsMixin } from '@/components/Pages/Controls/ControlDetailsCustomFieldsMixin';
import { DelegationWellMixin } from '@/mixins/DelegationWellMixin';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { createTabsList } from '@/utils/base/tabs';
import { getLinksLabel } from '@/utils/links';
import { compareDates } from '@/utils/compare';
import { checkControlsOrgAccess, isLockedControlUser as _isLockedControlUser } from '@/utils/access';
import { updateObjProp } from '@/utils/format/updateObject';
import { catch403 } from '@/utils/handleAPIErrors';
import { isTextAreaFilled } from '@/utils/validations';
import * as Utils from '@/utils/Utils';
import { MODULES } from '@/constants/modules';
import { CONTROL_ACTIONS_MESSAGES } from '@/constants/ControlActions';
import { updateFileLocally } from '@/utils/Attachments';
import { isDateValid, isDateAfter, formatDateWithTimezone, convertDateToUtcFormat } from '@/utils/date';
export default {
  name: 'ControlEntry',
  description: 'Common page for show control',
  token: '<control-entry />',
  components: {
    Notification: Notification,
    DepWell: DepWell,
    ControlRelationsSectionDetails: ControlRelationsSectionDetails,
    DelegationWell: DelegationWell,
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight,
    NotificationPopUp: NotificationPopUp
  },
  mixins: [ControlDetailsCustomFieldsMixin, DelegationWellMixin, NotificationPopUpMixin],
  props: {
    cpath: {
      type: [String, Number],
      required: true
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false,
      note: 'needed for mothercontrol change'
    }
  },
  emits: ['onCompleteControl', 'updated', 'reloadRootPage', 'changeTab', 'urlChanged', 'accessError'],
  data: function data() {
    return {
      notificationType: 'info',
      notificationMessage: '',
      notificationDetails: '',
      isNotificationShow: false,
      // type of mode
      isModeCreation: false,
      titleModeCreation: '',
      startPage: {},
      isLoaded: false,
      control: {},
      dateFormat: DateFormats.DATE_TIME_FORMAT,
      accounts: [],
      processes: [],
      risks: [],
      updateTimer: 0,
      saveStatus: saveStatus.SAVED,
      dataUpdate: {},
      controls: [],
      Colors: Colors,
      motherControls: [],
      translate: getTranslate['ControlEntry']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), mapState(useOrganisationsStore, ['organisations'])), mapState(useUsersStore, {
    users: 'activeUsers',
    unfilteredUsers: 'users'
  })), mapState(useTagsStore, ['tags'])), mapState(useActivityTypesStore, {
    activityTypes: 'controlActivityTypes'
  })), {}, {
    controlExtended: function controlExtended() {
      return _objectSpread(_objectSpread({}, this.control), {}, {
        users: this.unfilteredUsers,
        startPage: this.startPage
      });
    },
    baseControlLink: function baseControlLink() {
      return "/controls2/controls/".concat(this.cpath);
    },
    tabs: function tabs() {
      return createTabsList([TabTypes.DESCRIPTION, TabTypes.HISTORY, TabTypes.LINKS, TabTypes.CHANGELOG], this.baseControlLink, getLinksLabel(this.control));
    },
    depHeaderProps: function depHeaderProps() {
      if (!this.isLoaded) {
        return {};
      }
      return {
        id: this.control.cpath + '',
        editableId: false,
        editableTitle: !this.readOnly,
        title: this.control.title,
        filesList: this.attachments,
        tabs: this.tabs,
        titleField: 'title',
        trafficLightSettings: this.trafficLightSettings,
        users: this.users,
        moduleId: this.control.cpath,
        module: MODULES.CONTROLS,
        saveStatus: this.saveStatus,
        lineThroughTitle: !this.control.active,
        editableAttachments: !this.readOnly
      };
    },
    chosenTimezone: function chosenTimezone() {
      return Utils.findTimeOffset(Timezones, this.control.deadlineTimezone);
    },
    trafficLightSettings: function trafficLightSettings() {
      return {
        value: this.control.isMothercontrol ? undefined : this.control.trafficLight,
        lightType: 'trafficLights',
        field: 'trafficLight',
        readOnly: true
      };
    },
    attachments: function attachments() {
      var attachments = _toConsumableArray(this.control.attachments);
      return attachments.sort(function (a, b) {
        return compareDates(a.created, b.created, true);
      });
    },
    delegationData: function delegationData() {
      if (this.control.isMothercontrol) {
        return {
          tagIds: this.control.tagIds || []
        };
      }
      return {
        organisationIds: this.control.organisationIds || [],
        objType: this.control.objType,
        responsibleUserIds: (this.control.userIds ? this.control.userIds.responsible : []) || [],
        reviewersUserIds: (this.control.userIds ? this.control.userIds.review : []) || [],
        escalationUserIds: (this.control.userIds ? this.control.userIds.escalation : []) || [],
        dailyTeamUsersIds: (this.control.dailyTeamIds ? this.control.dailyTeamIds.users : []) || [],
        dailyTeamOrganisationsIds: (this.control.dailyTeamIds ? this.control.dailyTeamIds.organisations : []) || [],
        tagIds: this.control.tagIds || [],
        module: MODULES.CONTROLS
      };
    },
    controlFormatted: function controlFormatted() {
      var _this = this;
      var result = {};
      var propertyTableToUpdate = {
        deadline: function deadline() {
          return _this.convertToUtc(_this.control.deadline);
        },
        reviewDeadline: function reviewDeadline() {
          return _this.convertToUtc(_this.control.reviewDeadline);
        },
        deadlineDays: function deadlineDays() {
          return _this.control.deadlineDays || null;
        },
        accounts: function accounts() {
          return _this.control.accounts.map(function (account) {
            return account.id;
          });
        },
        processes: function processes() {
          return _this.control.processes.map(function (process) {
            return process.id;
          });
        },
        risks: function risks() {
          return _this.control.risks.map(function (risk) {
            return risk.id;
          });
        },
        expectedEvidence: function expectedEvidence() {
          return isTextAreaFilled(_this.control.expectedEvidence) ? _this.control.expectedEvidence : '';
        },
        customFieldValues: function customFieldValues() {
          return _this.getCustomFieldsValues(_this.control.customFieldValues);
        }
      };
      Object.entries(this.dataUpdate).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];
        if (key in propertyTableToUpdate) {
          result[key] = propertyTableToUpdate[key]();
        } else {
          result[key] = value;
        }
      });
      return result;
    },
    userHasMotherAccess: function userHasMotherAccess() {
      var motherControlUserLevel = this.getSettingValue('feature.create_mothercontrol_user_level');
      return checkControlsOrgAccess(this.currentUser, motherControlUserLevel);
    },
    isMotherOrDaughterControlsLinkDisable: function isMotherOrDaughterControlsLinkDisable() {
      return !this.userHasMotherAccess || this.currentUser.level_c_locked === 1 && this.currentUser.level_controls !== UserLevels.SUPER;
    },
    isLockedControlUser: function isLockedControlUser() {
      return _isLockedControlUser(this.currentUser);
    },
    readOnly: function readOnly() {
      return !this.control.active || this.isLockedControlUser;
    },
    lockedUserMessage: function lockedUserMessage() {
      var message = this.getSettingValue('risma.unlock_description') ? 'The input fields on this control are locked. You can only update the description or complete the control' : 'This activity is read-only as your user is configured to only have read-only access. The control can only be completed';
      return this.$trans(message);
    },
    controlIdsAccessibleByUser: function controlIdsAccessibleByUser() {
      var _this2 = this;
      if (checkControlsOrgAccess(this.currentUser, UserLevels.SUPER)) {
        return this.controls.map(function (control) {
          return +control.id;
        });
      }
      return this.controls.filter(function (control) {
        return control.userIds.responsible.find(function (id) {
          return +id === +_this2.currentUser.id;
        });
      }).map(function (control) {
        return +control.id;
      });
    }
  }),
  mounted: function mounted() {
    this.handleModeCreate(this.cpath);
    this.getControlData();
    this.getAccounts();
    this.getProcesses();
    this.getControlRisks();
    this.getControls();
    this.getMotherControls();
  },
  updated: function updated() {
    this.handleModeCreate(this.cpath);
  },
  methods: {
    handleShowNotify: function handleShowNotify(_ref3) {
      var _this3 = this;
      var type = _ref3.type,
        message = _ref3.message,
        details = _ref3.details;
      this.notificationType = type;
      this.notificationMessage = message;
      this.notificationDetails = details;
      this.isNotificationShow = true;
      this.$scrollTo(document.body, 100);
      setTimeout(function () {
        _this3.handleDismissNotification();
      }, 5000);
    },
    handleDismissNotification: function handleDismissNotification() {
      this.notificationMessage = '';
      this.notificationDetails = '';
      this.isNotificationShow = false;
    },
    handleModeCreate: function handleModeCreate(cpath) {
      var result = false;
      if (cpath === ControlCreation.NEW_DAUGHTER_CONTROL) {
        this.titleModeCreation = this.translate.createNewDaughterControl;
        result = true;
      }
      this.isModeCreation = result;
    },
    handleControlTimezoneProper: function handleControlTimezoneProper() {
      this.plusTimeOffset();
    },
    plusTimeOffset: function plusTimeOffset() {
      this.addTimeOffset(this.control.deadline, 'deadline');
      this.addTimeOffset(this.control.reviewDeadline, 'reviewDeadline');
    },
    addTimeOffset: function addTimeOffset(date, field) {
      if (isDateValid(date)) {
        this.control[field] = formatDateWithTimezone(date, this.chosenTimezone.value, this.dateFormat);
      }
    },
    getControlData: function getControlData(updatedControlId) {
      var _this4 = this;
      return ControlsApi.getControlById(this.cpath).then(function (data) {
        _this4.control = data;
        _this4.$emit('updated', _objectSpread(_objectSpread({}, _this4.control), {}, {
          updatedControlId: updatedControlId
        }));
      }).then(function () {
        _this4.control['_titleWithPath'] = RismaControlsUtils.addTitleWithpath(_this4.control);
        _this4.prepareControlData();
        _this4.isLoaded = true;
      }).catch(function () {
        if (_this4.isPreview) {
          _this4.$emit('accessError');
          return;
        }
        catch403(_this4.$router, 'ControlsMy');
      });
    },
    getAccounts: function getAccounts() {
      var _this5 = this;
      DataAPI.getAccounts().then(function (_ref4) {
        var list = _ref4.list;
        return _this5.accounts = list;
      });
    },
    getProcesses: function getProcesses() {
      var _this6 = this;
      DataAPI.getProcesses().then(function (_ref5) {
        var list = _ref5.list;
        return _this6.processes = list;
      });
    },
    getControlRisks: function getControlRisks() {
      var _this7 = this;
      DataAPI.getControlRisks().then(function (_ref6) {
        var list = _ref6.list;
        return _this7.risks = list;
      });
    },
    getControls: function getControls() {
      var _this8 = this;
      return ControlsApi.getAll('?data[]=organisationIds').then(function (_ref7) {
        var list = _ref7.list;
        return _this8.controls = list;
      });
    },
    getMotherControls: function getMotherControls() {
      var _this9 = this;
      return ControlsApi.getAllMotherControls().then(function (_ref8) {
        var list = _ref8.list;
        list.forEach(function (control) {
          return control.isMothercontrol = 1;
        });
        _this9.motherControls = _this9.motherControls.concat(list);
      }).catch(function () {}); // For example: "Insufficient rights"
    },
    controlPropertyChanged: function controlPropertyChanged(arg) {
      var property = arg.property,
        value = arg.value;
      if (property === undefined) {
        this.control = Object.assign(this.control, arg);
        this.dataUpdate = Object.assign(this.dataUpdate, arg);
      } else if (property === 'responsibleUserIds') {
        if (!value.length && this.control.confidential) {
          this.control.confidential = 0;
        }
        this.control.userIds.responsible = value;
        this.setDataUpdate('userIds.responsible', value);
      } else if (property === 'reviewersUserIds') {
        this.control.userIds.review = value;
        this.setDataUpdate('userIds.review', value);
      } else if (property === 'escalationUserIds') {
        this.control.userIds.escalation = value;
        this.setDataUpdate('userIds.escalation', value);
      } else if (property === 'dailyTeamUsersIds') {
        this.control.dailyTeamIds.users = value;
        this.setDataUpdate('dailyTeamIds.users', value);
      } else if (property === 'dailyTeamOrganisationsIds') {
        this.control.dailyTeamIds.organisations = value;
        this.setDataUpdate('dailyTeamIds.organisations', value);
      } else {
        this.control[property] = value;
        this.setDataUpdate(property, value);
      }
      this.dataUpdate = _objectSpread({}, this.dataUpdate); //after you reload page and do changes controlFormatted doesn`t see dataupdate changes for first change
      this.setUpdateTimer();
    },
    saveChangesImmediately: function saveChangesImmediately(updatedProperty) {
      this.controlPropertyChanged(updatedProperty);
      this.saveStatusClicked();
    },
    setDataUpdate: function setDataUpdate(path, value) {
      updateObjProp(this.dataUpdate, path, value);
    },
    setUpdateTimer: function setUpdateTimer() {
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      if (!this.isValidSave()) return;
      this.updateTimer = setTimeout(this.saveUpdates, this.$autoSaveDebounceTime);
    },
    saveStatusClicked: function saveStatusClicked() {
      if (this.saveStatus !== saveStatus.SAVED) {
        clearTimeout(this.updateTimer);
        if (!this.isValidSave()) return;
        this.saveUpdates();
      }
    },
    saveUpdates: function saveUpdates() {
      var _this$controlFormatte,
        _this10 = this;
      this.saveStatus = saveStatus.SAVING;
      if (((_this$controlFormatte = this.controlFormatted.userIds) === null || _this$controlFormatte === void 0 || (_this$controlFormatte = _this$controlFormatte.responsible) === null || _this$controlFormatte === void 0 ? void 0 : _this$controlFormatte.length) === 0) {
        this.controlFormatted.confidential = 0;
      }
      var timerId = this.updateTimer;
      return ControlsApi.patch(this.cpath, this.controlFormatted).then(function (data) {
        if (!data && !_this10.isPreview) {
          return _this10.$router.push({
            path: '/controls2/controls'
          });
        }
        if (!data && _this10.isPreview) {
          return _this10.$emit('reloadRootPage');
        }
        if (_this10.control.url !== data.url) {
          if (!_this10.isPreview) {
            _this10.$router.push(data.url);
          } else {
            _this10.$emit('urlChanged', data.url);
          }
        }
        _this10.dataUpdate = {};
        // update the control if there were no new changes during the save time
        if (timerId === _this10.updateTimer) {
          _this10.control = data;
          _this10.control['_titleWithPath'] = RismaControlsUtils.addTitleWithpath(data);
          _this10.prepareControlData();
        }
        _this10.saveStatus = saveStatus.SAVED;
        _this10.$emit('updated', _this10.control);
      }).catch(function () {
        _this10.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    isValidSave: function isValidSave() {
      var message = '';
      // Validation for reviewDeadline
      if (this.control.reviewDeadline && this.control.userIds.review && this.control.userIds.review.length) {
        if (isDateAfter(this.control.deadline, this.control.reviewDeadline)) {
          message = ControlValidationMessages.REVIEW_DEADLINE_AFTER_CONTROL_DEADLINE;
        }
      }
      if (this.control.userIds.responsible.length === 0 && this.control.isMothercontrol === 0 && !this.getSettingValue('feature.controls_allow_no_owners')) {
        message = ControlValidationMessages.RESPONSIBLE_IS_REQUIRED;
      }
      // Validation for Delegation Section
      if (this.control.organisationIds.length === 0 && this.control.isMothercontrol === 0) {
        message = ControlValidationMessages.ORGANISATIONS_IS_REQUIRED;
      }
      if (message) this.handleShowNotify({
        type: 'error',
        message: message
      });
      return !message;
    },
    convertToUtc: function convertToUtc(date) {
      return convertDateToUtcFormat(date, this.chosenTimezone.value, "".concat(this.dateFormat, "Z"));
    },
    prepareControlData: function prepareControlData() {
      if (!isTextAreaFilled(this.control.expectedEvidence)) {
        this.control.expectedEvidence = '';
      }
      this.handleControlTimezoneProper();
      if (this.control.mothercontrol && this.control.mothercontrol.attachments.length > 0) {
        this.control.mothercontrol.attachments.forEach(function (attachment) {
          if (!attachment.size) {
            attachment.module = 'link';
          } else {
            attachment.module = 'file';
          }
        });
      }
    },
    prepareOrganisationTitles: function prepareOrganisationTitles(_ref9) {
      var id = _ref9.id;
      var control = this.controls.find(function (control) {
        return control.id === id;
      });
      var organisationIds = control && control.organisationIds || [];
      return Utils.findPropsByIds(organisationIds, this.organisations, 'visible_name');
    },
    onCompleteControl: function onCompleteControl(data) {
      if (!data.isSave) {
        this.handleInstantNotification({
          url: this.control.url,
          title: this.$trans(CONTROL_ACTIONS_MESSAGES[data.state])
        });
      }
      this.getControlData(data.updatedControlId);
      this.$emit('onCompleteControl', data);
    },
    showActiveStateNotify: function showActiveStateNotify(newState) {
      var message = newState ? 'Control re-opened' : 'The control is deactivated. Click on the menu to re-open it';
      var notification = {
        type: newState ? 'success' : 'info',
        message: this.$trans(message)
      };
      this.handleShowNotify(notification);
    },
    onFileUpdate: function onFileUpdate(fileId) {
      var _this$control;
      updateFileLocally(fileId, (_this$control = this.control) === null || _this$control === void 0 ? void 0 : _this$control.attachments);
      this.saveStatus = saveStatus.SAVED;
    },
    updatingAttachments: function updatingAttachments() {
      this.saveStatus = saveStatus.SAVING;
    },
    getActivityData: function getActivityData() {
      var _this11 = this;
      this.getControlData().then(function () {
        return _this11.saveStatus = saveStatus.SAVED;
      });
    }
  }
};