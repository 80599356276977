import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "flex justify-between"
};
var _hoisted_3 = {
  class: "flex items-center"
};
var _hoisted_4 = {
  class: "mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    class: "mr-2",
    type: "big",
    title: $options.headline[$props.app]
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_toggle, {
    class: "mr-2",
    onToggle: $options.toggleInvitedFilter
  }, null, 8 /* PROPS */, ["onToggle"]), _createElementVNode("p", null, _toDisplayString($data.translate.showInvitedOnly), 1 /* TEXT */)])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_static_table, {
    columns: $options.columns,
    dataset: $options.dataset,
    theme: $data.tableThema
  }, {
    sendInvitation: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createVNode(_component_risma_button, {
        text: $data.translate.sendInvitation,
        type: "save",
        disabled: slotData.disabled,
        onClick: function onClick($event) {
          return $options.sendInvitation(slotData);
        }
      }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["columns", "dataset", "theme"])])]);
}