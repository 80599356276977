export var ragToLabel = {
  0: 'Not set',
  1: 'No gap',
  2: 'Gap Low',
  3: 'Gap Medium',
  4: 'Gap High',
  5: 'Gap N/A'
};
export var domainTemplate = {
  0: 'Not set',
  1: 'No gap',
  2: 'Gap Low',
  3: 'Gap Medium',
  4: 'Gap High',
  5: 'Gap N/A'
};