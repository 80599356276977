import { Colors } from '@/Colors';
import RadarChart from '@/components/Molecules/Charts/RadarChart';
export default {
  name: '',
  components: {
    RadarChart: RadarChart
  },
  data: function data() {
    return {
      labels: ['point 1', 'point 2', 'point 3'],
      colors: [Colors.greenTea, Colors.dullGreen, Colors.blueLight],
      data: [{
        label: 'Dataset 1',
        data: [1, 5, 2]
      }, {
        label: 'Dataset 2',
        data: [8, 3, 5]
      }, {
        label: 'Dataset 3',
        data: [2, 5, 5]
      }]
    };
  }
};