import { Http } from '../index';
import * as HttpUtils from '../httpUtils';

/**
 * Create new audit
 *
 * @param module
 * @param activityId
 * @param data
 * @returns {Promise<AxiosResponse | string>}
 */
export function createNewAudit(module, activityId, data) {
  return Http.post("compliance/audit/".concat(module, "/").concat(activityId), data).then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}

/**
 * Get all audits for a given activity
 *
 * @param module
 * @param activityId
 * @param data
 * @returns {Promise<AxiosResponse<T> | string>}
 */
export function getAllAudits(module, activityId) {
  var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  return HttpUtils.handleRequestResponse(Http.get("compliance/audit/".concat(module, "/").concat(activityId, "?data[]=").concat(data)));
}
export function getAudit(module, activityId, auditId) {
  return HttpUtils.handleRequestResponse(Http.get("compliance/audit/".concat(module, "/").concat(activityId, "/request/").concat(auditId)));
}