import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-b1d5276a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  class: "alert alert-danger"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_activity_custom_fields = _resolveComponent("activity-custom-fields");
  return _openBlock(), _createElementBlock("section", null, [$data.error !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($data.error), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass([{
      'readonly': $props.readonly
    }, "main-process-node-page"])
  }, [$props.readonly ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: "".concat($options.titleUniqueId, " ").concat($options.nodeTitle),
    type: "big"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.description,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), !$props.readonly ? (_openBlock(), _createBlock(_component_tiny_mce_box, {
    key: 1,
    "initial-content": _ctx.description,
    onChanged: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.description = $event;
    })
  }, null, 8 /* PROPS */, ["initial-content"])) : _createCommentVNode("v-if", true), $props.readonly ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: "break-word mb-4",
    innerHTML: $props.nodeDataOuter.description
  }, null, 8 /* PROPS */, _hoisted_3)) : _createCommentVNode("v-if", true), $options.currentActivityType ? (_openBlock(), _createBlock(_component_activity_custom_fields, {
    key: 3,
    "activity-type": $options.currentActivityType,
    "custom-field-values": $props.data.customFieldValues || [],
    disabled: $props.readonly,
    class: "mt-4",
    onChanged: _ctx.onCustomFieldsChange
  }, null, 8 /* PROPS */, ["activity-type", "custom-field-values", "disabled", "onChanged"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)]);
}