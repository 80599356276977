import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-9743d01c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  class: "stacked-bar-chart-wrapper"
};
var _hoisted_2 = {
  class: "bar-line h-5"
};
var _hoisted_3 = ["onMouseover", "onMouseleave", "onClick"];
var _hoisted_4 = {
  key: 0,
  class: "bar-item__text"
};
var _hoisted_5 = {
  key: 0,
  class: "bar-line labels h-5"
};
var _hoisted_6 = ["onClick"];
var _hoisted_7 = {
  class: "bar-label-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return $data.loaded ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dataset, function (item) {
    return _openBlock(), _createElementBlock("div", {
      key: item.id,
      class: _normalizeClass(['bar-item', {
        'disabled': !item.isActive
      }]),
      style: _normalizeStyle($options.getStyles(item)),
      onMouseover: function onMouseover($event) {
        return $options.onMouseOver(item);
      },
      onMouseleave: function onMouseleave($event) {
        return $options.onMouseLeave(item);
      },
      onClick: function onClick($event) {
        return _ctx.$emit('clicked', item.id);
      }
    }, [item.data && $props.labels.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(item.isHovered ? item.data : $options.getWidth(item)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 46 /* CLASS, STYLE, PROPS, HYDRATE_EVENTS */, _hoisted_3);
  }), 128 /* KEYED_FRAGMENT */))]), $props.labels.length ? (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dataset, function (item) {
    return _openBlock(), _createElementBlock("div", {
      key: item.id,
      class: _normalizeClass(['bar-label', {
        'disabled': !item.isActive
      }]),
      onClick: function onClick($event) {
        return $options.filterLabels(item);
      }
    }, [_createElementVNode("span", {
      style: _normalizeStyle({
        background: item.color
      }),
      class: "bar-label__square"
    }, [!item.isActive ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      color: $data.col.system.white,
      width: "16px",
      height: "16px",
      icon: "lock"
    }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true)], 4 /* STYLE */), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$trans(item.label)), 1 /* TEXT */)], 10 /* CLASS, PROPS */, _hoisted_6);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true);
}