function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getAll as getIncidents } from '@/api/incident/incident';
import { getControls, getInitiatives, getRisks, getNodesFlat } from '@/api/risma/data';
import { getRiskProjects } from '@/api/risk/risk';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { getUsersFromIds, getOrganisationsFromIds } from '@/utils/format/objectsFromIds';
import { getColumnLabelName } from '@/utils/CustomFields';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { capitalize } from '@/utils';
import * as formatDate from '@/utils/date';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { INCIDENT_STATES } from '@/constants/IncidentStateCodes';
import { Colors } from '@/Colors';
import { INCIDENT } from '@/constants/routes/incident';
import * as Utils from '@/utils/Utils';
export default {
  name: 'IncidentTypeExpanded',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    RismaDatatable: RismaDatatable,
    Trafficlight: Trafficlight,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  mixins: [RaciTitleMixin, RismaDatatableDefaultMixin, ProcessLibraryTitleMixin],
  token: '<incident-type-expanded :typeId="3"></incident-type><script>',
  props: {
    typeId: {
      required: true,
      type: [Number, String],
      note: 'The id of the type that needs expanding'
    }
  },
  data: function data() {
    return {
      incidents: null,
      controls: null,
      initiatives: null,
      risks: null,
      nodes: null,
      riskProjects: [],
      decoupleFilterFields: [{
        key: 'organisations'
      }, {
        key: 'responsible'
      }, {
        key: 'accountable'
      }, {
        key: 'consulted'
      }, {
        key: 'informed'
      }, {
        key: 'initiatives'
      }, {
        key: 'controls'
      }, {
        key: 'risks'
      }, {
        key: 'processes'
      }],
      disabledFilterByColumns: ['activity', 'description'],
      columnsMeta: [{
        targets: ['risks'],
        width: '600px'
      }, {
        targets: ['id'],
        width: '50px'
      }],
      slotLinkFields: ['initiatives', 'controls', 'risks', 'processes'],
      customFieldsDefault: {},
      customFieldshashTabByIncidentId: {},
      customFieldsColumns: {},
      dataset: [],
      translate: getTranslate['IncidentTypeExpanded']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'incidentActivityTypes'
  })), {}, {
    activity: function activity() {
      var _this = this;
      return this.activityTypes.find(function (x) {
        return x.id === parseInt(_this.typeId);
      });
    },
    isLoaded: function isLoaded() {
      return this.incidents && this.activity && this.users && this.organisations && this.controls && this.initiatives && this.risks && this.nodes;
    },
    enhancedActivity: function enhancedActivity() {
      return _objectSpread(_objectSpread({}, this.activity), {}, {
        severityIncluded: Utils.isFieldInOptionalFields(this.activity, 'severity'),
        descriptionIncluded: Utils.isFieldInOptionalFields(this.activity, 'description')
      });
    },
    columns: function columns() {
      var columns = {
        activity: this.translate.activityType
      };
      if (this.enhancedActivity.severityIncluded) {
        columns.severity = this.translate.severity;
      }
      if (this.showIdFeatureEnabled) {
        columns = _objectSpread(_objectSpread({}, columns), {}, {
          id: this.translate.id
        });
      }
      columns = _objectSpread(_objectSpread({}, columns), {}, {
        title: this.translate.title,
        description: this.enhancedActivity.descriptionIncluded ? this.translate.description : undefined,
        status: this.translate.status,
        confidential: this.translate.confidential,
        created: this.translate.created,
        incidentOccurred: this.translate.incidentOccurred,
        responsible: this.responsibleTitle(true),
        accountable: this.accountableTitle(true),
        consulted: this.consultedTitle(true),
        informed: this.informedTitle(true),
        organisations: this.translate.organisations,
        initiatives: this.translate.linkedInitiatives,
        controls: this.translate.linkedControls,
        risks: this.translate.linkedRisks,
        processes: "".concat(this.translate.linked, " ").concat(this.processLibraryTitle)
      }, this.customFieldsColumns);
      return columns;
    },
    columnsAsTrafficLight: function columnsAsTrafficLight() {
      var _this$activity;
      if (!((_this$activity = this.activity) !== null && _this$activity !== void 0 && (_this$activity = _this$activity.fields) !== null && _this$activity !== void 0 && _this$activity.length)) return [];
      return this.activity.fields.filter(function (field) {
        return field.typeId === CustomFieldTypes.TRAFFIC_LIGHT;
      }).map(function (field) {
        return getColumnLabelName(field.id);
      });
    },
    baseLocationName: function baseLocationName() {
      return INCIDENT.incidentList;
    },
    showIdFeatureEnabled: function showIdFeatureEnabled() {
      return this.getSettingValue('feature.show_incident_id');
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      return Promise.all([getIncidents().then(function (response) {
        return _this2.incidents = response.filter(function (x) {
          return x.activityTypeId === parseInt(_this2.typeId);
        });
      }), getControls().then(function (_ref) {
        var list = _ref.list;
        return _this2.controls = list;
      }), getInitiatives().then(function (_ref2) {
        var list = _ref2.list;
        return _this2.initiatives = list;
      }), getRisks('?data[]=scores').then(function (_ref3) {
        var list = _ref3.list;
        return _this2.risks = list;
      }), getNodesFlat().then(function (_ref4) {
        var list = _ref4.list;
        return _this2.nodes = list;
      }), getRiskProjects().then(function (_ref5) {
        var list = _ref5.list;
        return _this2.riskProjects = list;
      })]).then(function () {
        _this2.prepareCustomFieldOptions();
        _this2.dataset = _this2.prepareDataset();
        _this2.enhanceDataset(_this2.dataset, _this2.columns);
      });
    },
    getState: function getState(state) {
      var stateObject = null;
      Object.keys(INCIDENT_STATES).forEach(function (key) {
        if (INCIDENT_STATES[key].value === state) {
          stateObject = INCIDENT_STATES[key];
        }
      });
      return stateObject;
    },
    prepareCustomFieldOptions: function prepareCustomFieldOptions() {
      var _this$getPropertiesWi = this.getPropertiesWithCustomFields(this.incidents, [this.activity]),
        extendedColumns = _this$getPropertiesWi.extendedColumns,
        defaultCustomFields = _this$getPropertiesWi.defaultCustomFields,
        customFieldsValuesByEntity = _this$getPropertiesWi.customFieldsValuesByEntity,
        columnFilterOptions = _this$getPropertiesWi.columnFilterOptions;
      this.customFieldsColumns = extendedColumns;
      this.customFieldsDefault = defaultCustomFields;
      this.customFieldshashTabByIncidentId = customFieldsValuesByEntity;
      this.enhanceColumnFilters(columnFilterOptions, this.disabledFilterByColumns, this.decoupleFilterFields);
    },
    prepareDataset: function prepareDataset() {
      var _this3 = this;
      return this.incidents.map(function (x) {
        var incidentOccurred = formatDate.toLocalDateTime(x.incidentHappenedAt) || _this3.translate.notSet;
        var customFields = _objectSpread(_objectSpread({}, _this3.customFieldsDefault), _this3.customFieldshashTabByIncidentId[x.id]);
        var severity = x.severity !== null ? Colors.findIncidentSeverity(x.severity, true) : undefined;
        var dataset = {
          activity: {
            field: _this3.activity ? _this3.activity.label : ''
          }
        };
        if (severity) {
          dataset.severity = {
            field: {
              color: severity.color,
              value: x.severity,
              label: severity.label
            },
            fieldText: x.severity
          };
        }
        if (_this3.showIdFeatureEnabled) {
          dataset = _objectSpread(_objectSpread({}, dataset), {}, {
            id: {
              field: x.id
            }
          });
        }
        dataset = _objectSpread(_objectSpread({}, dataset), {}, {
          title: {
            field: {
              url: "/incident/".concat(x.id, "/description"),
              title: x.title,
              confidential: x.confidential,
              hoverTitle: DatatableRenderer.getItemHoverTitle(x)
            },
            fieldText: x.title
          },
          description: x.description !== null ? {
            field: x.description
          } : undefined,
          status: {
            field: _this3.$trans(_this3.getState(x.rismaState).name)
          },
          confidential: {
            field: x.confidential ? _this3.translate.yes : _this3.translate.no
          },
          created: {
            field: formatDate.toLocalDateTime(x.createdAt)
          },
          incidentOccurred: {
            field: incidentOccurred
          },
          responsible: {
            field: getUsersFromIds(x.responsibleUserIds, _this3.users).map(function (u) {
              return u.display_name;
            }).join(', ')
          },
          accountable: {
            field: getUsersFromIds(x.accountableUserIds, _this3.users).map(function (u) {
              return u.display_name;
            }).join(', ')
          },
          consulted: {
            field: getUsersFromIds(x.consultedUserIds, _this3.users).map(function (u) {
              return u.display_name;
            }).join(', ')
          },
          informed: {
            field: getUsersFromIds(x.informedUserIds, _this3.users).map(function (u) {
              return u.display_name;
            }).join(', ')
          },
          organisations: {
            field: getOrganisationsFromIds(x.organisationIds, _this3.organisations).map(function (o) {
              return o.visible_name;
            }).join(', ')
          },
          initiatives: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(x.links.execution, _this3.initiatives, 'path')),
          controls: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(x.links.control, _this3.controls, 'path')),
          risks: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(x.links.risk, _this3.risks, 'rno', _this3.getRiskValues)),
          processes: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(x.links.compliance_process_tree_node, _this3.nodes))
        }, customFields);
        return dataset;
      });
    },
    getRiskValues: function getRiskValues(risk) {
      if (this.getSettingValue('feature.show_risk_links_with_levels') !== 1) {
        return '';
      }
      return DatatableRenderer.getRiskValues(risk, this.riskProjects);
    },
    prepareFilterByLabel: function prepareFilterByLabel(value, columnKey) {
      if (columnKey === 'severity') {
        return this.$trans(capitalize(Colors.findIncidentSeverity(value, true).label));
      }
      return this.prepareFilterForTrafficLight(value, columnKey, this.trafficLightRismaDatatableFields);
    }
  }
};