function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6fb34816"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "flex relative"
};
var _hoisted_2 = {
  class: "w-2/5 lg:pr-10 xxl:pr-20"
};
var _hoisted_3 = {
  class: "sticky top-130px min-w-0 h-70vh rounded border-solid border border-gray-350 overflow-auto"
};
var _hoisted_4 = {
  class: "ml-3 px-4 text-2xl text-blue-750 font-semibold truncate w-full"
};
var _hoisted_5 = {
  class: "flex flex-col relative w-3/5 pl-12 pr-5 pt-4"
};
var _hoisted_6 = {
  key: 1,
  class: "navigate-arrows flex justify-between mt-6 relative"
};
var _hoisted_7 = {
  class: "mr-2 hover:underline"
};
var _hoisted_8 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_questionnaire_navigation = _resolveComponent("questionnaire-navigation");
  var _component_notification = _resolveComponent("notification");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_question = _resolveComponent("question");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("fieldset", _hoisted_3, [_createElementVNode("legend", _hoisted_4, _toDisplayString($props.title), 1 /* TEXT */), _createVNode(_component_questionnaire_navigation, {
    questions: $options.expandedVisibleQuestions,
    "visibility-storage-key": "questionnaire_navigation_hidden_".concat($props.questionnaire && $props.questionnaire.questionnaireType || $props.questionnaire.questionnaireId || 0, "_").concat($props.questionnaire && $props.questionnaire.id || 0),
    processors: $options.informationAssetsByType[$data.AssetsTypes.PROCESSORS] || [],
    onChangeQuestion: $options.changeQuestion
  }, null, 8 /* PROPS */, ["questions", "visibility-storage-key", "processors", "onChangeQuestion"])])]), _createElementVNode("div", _hoisted_5, [$data.errors.length !== 0 ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errors, function (error, key) {
        return _openBlock(), _createElementBlock("p", {
          key: key,
          class: "m-2"
        }, _toDisplayString(error), 1 /* TEXT */);
      }), 128 /* KEYED_FRAGMENT */))];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), !$options.showFullQuestionsList ? (_openBlock(), _createElementBlock("div", _hoisted_6, [$data.questionIndex > $options.indexOfFirstAnsweredDependenciesQuestion ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    class: "navigate-arrow z-10 js-navigate-arrow flex cursor-pointer absolute -left-12 rounded-full focus:outline-none",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.setQuestionIndex(-1);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-left",
    class: "stroke-2",
    height: "40",
    width: "40"
  })])) : _createCommentVNode("v-if", true), $data.questionIndex < $options.indexOfLastAnsweredDependenciesQuestion ? (_openBlock(), _createElementBlock("button", {
    key: 1,
    class: "navigate-arrow z-10 js-navigate-arrow flex cursor-pointer absolute right-0 rounded-full focus:outline-none",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.setQuestionIndex(1);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-right",
    class: "stroke-2",
    width: "40",
    height: "40"
  })])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $options.showFullQuestionsList && $options.showNotApplicableToggle ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: "flex items-center ml-auto cursor-pointer",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $data.showNonApplicable = !$data.showNonApplicable;
    })
  }, [_createElementVNode("p", _hoisted_7, _toDisplayString($data.showNonApplicable ? $data.translate.hideNonApplicable : $data.translate.showNonApplicable), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    class: "mr-1 stroke-2",
    icon: $data.showNonApplicable ? 'eye' : 'eye-off',
    height: "18",
    width: "18"
  }, null, 8 /* PROPS */, ["icon"])])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.questionsList, function (question) {
    return _openBlock(), _createElementBlock("div", {
      id: question.id,
      key: question.id,
      class: "relative mb-20"
    }, [_createVNode(_component_question, {
      question: question,
      "node-element": $props.nodeElement,
      "information-assets": $options.informationAssetsByType,
      "my-information-assets-by-type": $props.myInformationAssetsByType,
      "activity-types": $props.activityTypes,
      "show-headline": question.showHeadline,
      "read-only": $props.readOnly,
      onUpdate: $options.update,
      onNewAsset: function onNewAsset($event) {
        return _ctx.$emit('newAsset', _objectSpread(_objectSpread({}, $event), {}, {
          questionId: question.id
        }));
      },
      onForceUpdate: _cache[3] || (_cache[3] = function ($event) {
        return _ctx.$emit('forceUpdate');
      })
    }, null, 8 /* PROPS */, ["question", "node-element", "information-assets", "my-information-assets-by-type", "activity-types", "show-headline", "read-only", "onUpdate", "onNewAsset"])], 8 /* PROPS */, _hoisted_8);
  }), 128 /* KEYED_FRAGMENT */))])]);
}