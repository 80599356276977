import SaveButton from '@/components/Atoms/Buttons/SaveButton';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import { saveStatus } from '@/constants/SaveStatuses';
export default {
  name: '',
  components: {
    SaveButton: SaveButton,
    RismaToggle: RismaToggle
  },
  data: function data() {
    return {
      saveButtonShown: false,
      saveStatus: saveStatus.NOT_SAVED
    };
  },
  methods: {
    showResult: function showResult(name, result) {
      window.alert(name, JSON.stringify(result));
    }
  }
};