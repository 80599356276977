import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-869dc600"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "sign-off-content"
};
var _hoisted_2 = {
  class: "sign-off-box description"
};
var _hoisted_3 = {
  class: "sign-off-box documentation"
};
var _hoisted_4 = {
  key: 1,
  class: "sign-off-box users-wrap"
};
var _hoisted_5 = {
  class: "validation-error"
};
var _hoisted_6 = {
  key: 2,
  class: "sign-off-box users-wrap"
};
var _hoisted_7 = {
  class: "overflow-y-auto w-fit max-h-140px mb-4"
};
var _hoisted_8 = {
  class: "w-fit mr-4"
};
var _hoisted_9 = {
  class: "mr-4"
};
var _hoisted_10 = {
  key: 0,
  class: "flex items-center w-96"
};
var _hoisted_11 = {
  class: "sign-off-box comments-wrap"
};
var _hoisted_12 = {
  class: "comments-block"
};
var _hoisted_13 = {
  class: "sign-off-box dashboard-wrap"
};
var _hoisted_14 = {
  class: "dashboard-title-wrap"
};
var _hoisted_15 = {
  class: "additional-title"
};
var _hoisted_16 = {
  class: "validation-error"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_notification = _resolveComponent("notification");
  var _component_text_box = _resolveComponent("text-box");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($options.descriptionText), 1 /* TEXT */), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.documentation,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createTextVNode(" " + _toDisplayString($options.documentationDescription), 1 /* TEXT */)]), $props.validationEmail || $data.newEmailError ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("p", null, _toDisplayString($props.validationEmail || $data.newEmailError), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $props.modalType === $data.signOffModalModes.CREATE ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.users,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
    class: _normalizeClass([{
      'error': $props.validationUsers
    }, "users-block input-field-container"])
  }, [_createVNode(_component_text_box, {
    text: $props.usersRow,
    cols: 60,
    class: "w-full",
    enabled: !$props.readonly,
    placeholder: $data.translate.enterTheEmailAddressesOfThePersonsWhoHave,
    onUpdated: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('update:usersRow', $event);
    })
  }, null, 8 /* PROPS */, ["text", "enabled", "placeholder"]), _createElementVNode("span", _hoisted_5, _toDisplayString($props.validationUsers), 1 /* TEXT */)], 2 /* CLASS */)])) : _createCommentVNode("v-if", true), $props.modalType === $data.signOffModalModes.DRAFT || $props.modalType === $data.signOffModalModes.REMIND ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_risma_title, {
    title: $data.translate.users,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.users, function (user, index) {
    return _openBlock(), _createElementBlock("div", {
      key: "sign-off-modal-sent-user-".concat(index),
      class: "flex items-center w-full justify-between"
    }, [_createElementVNode("span", _hoisted_9, _toDisplayString(user.email) + " " + _toDisplayString(user.fullname ? "(".concat(user.fullname, ")") : ''), 1 /* TEXT */), user.signoff === null ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      class: "cursor-pointer",
      icon: "x",
      onClick: function onClick($event) {
        return _ctx.$emit('remove:user', user);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))])]), $props.modalType !== $data.signOffModalModes.CREATE ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_input_field, {
    modelValue: $data.newEmail,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.newEmail = $event;
    }),
    placeholder: $data.translate.enterEmailAddress,
    class: _normalizeClass([$props.validationUsers ? 'input-validation-error' : '', "w-full"]),
    type: "text",
    onOnEnter: $options.addNewEmail
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "class", "onOnEnter"]), _createVNode(_component_risma_button, {
    text: $data.translate.add,
    type: "secondary",
    disabled: !$data.newEmail.length,
    onClick: $options.addNewEmail
  }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_11, [_createVNode(_component_risma_title, {
    title: $props.modalType === $data.signOffModalModes.REMIND ? $data.translate.reminderComment : $data.translate.comments,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_text_box, {
    text: $props.comments,
    cols: 60,
    enabled: !$props.readonly,
    class: "w-full",
    placeholder: $data.translate.anyCommentsWillBeIncludedInTheEmailToThe,
    onUpdated: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('update:comments', $event);
    })
  }, null, 8 /* PROPS */, ["text", "enabled", "placeholder"])])]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createVNode(_component_risma_title, {
    title: $data.translate.dashboardTitle,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("span", _hoisted_15, "(" + _toDisplayString($data.translate.maxLengthIs30Characters) + ")", 1 /* TEXT */)]), _createElementVNode("div", {
    class: _normalizeClass([{
      'error': $props.validationDashboard
    }, "dashboard-block input-field-container"])
  }, [_createVNode(_component_input_field, {
    modelValue: $props.dashboardTitle,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $props.dashboardTitle = $event;
    }),
    placeholder: $data.translate.stateTheTitleOfTheDashboardThatWillBeCrea,
    maxlength: 30,
    enabled: !$props.isAwarenessSent && !$props.readonly,
    type: "text",
    onUpdated: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('update:dashboardTitle', $event);
    })
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "enabled"]), _createElementVNode("span", _hoisted_16, _toDisplayString($props.validationDashboard), 1 /* TEXT */)], 2 /* CLASS */)])]);
}