import RismaSelect from '../Atoms/Inputs/RismaSelect';
import RismaTitle from '../Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import { formatNodes as _formatNodes } from '@/utils/format/Compliance';
import { Colors } from '@/Colors';
export default {
  name: 'RismaSelectorBar',
  components: {
    RismaTitle: RismaTitle,
    RismaSelect: RismaSelect,
    FeatherIcon: FeatherIcon,
    RismaToggle: RismaToggle
  },
  introduction: 'Parent component for <risma-select>',
  description: '',
  token: '<risma-selector-bar :selectors="rismaSelectorBarData" />',
  props: {
    selectors: {
      required: true,
      type: Array,
      note: 'an Object Array determines which lists to show, all item must have a list key, label key and a preSelected key ie. [{list: "organisations", label:"Orgs", preSelected:[]},{list: "users", label:"Responsible", preSelected:[{user}, {user}]}]'
    },
    projectId: {
      required: false,
      type: [String, Number],
      default: 0,
      note: 'projectId is used to filter lists, so only items with connection or access to a project is visible. Required if nodes selector is passed'
    },
    filter: {
      type: Boolean,
      default: false,
      note: 'Should the ListBar return a filter string on change?'
    },
    orgs: {
      type: Array,
      required: true,
      note: 'List of organisations'
    },
    users: {
      type: Array,
      required: true,
      note: 'List of users'
    },
    nodes: {
      type: Array,
      required: true,
      note: 'List of nodes'
    },
    tags: {
      type: Array,
      required: true,
      note: 'List of nodes'
    }
  },
  emits: ['listChange'],
  data: function data() {
    var _this = this;
    return {
      listOptions: {
        gaps: {
          list: Colors.gaps.map(function (gap) {
            return {
              id: gap.number,
              color: gap.color,
              label: _this.$trans(gap.label)
            };
          }),
          display: 'label'
        },
        nodes: null,
        controls: null,
        initiatives: null,
        users: {
          list: [],
          display: 'display_name'
        },
        organisations: {
          list: [],
          display: 'visible_name'
        },
        tags: {
          list: [],
          display: 'tag'
        }
      },
      formattedNodes: [],
      numbersInitialized: 0,
      translate: {
        noFiltersApplied: this.$trans('No filters applied'),
        resetFilter: this.$trans('Reset filter'),
        selectAll: this.$trans('Select all')
      }
    };
  },
  computed: {
    colNumber: function colNumber() {
      var number = Math.floor(Math.round(12 / this.selectors.length));
      if (number < 3) {
        number = 3;
      }
      if (number === 12) {
        number = 4;
      }
      return number;
    }
  },
  created: function created() {
    this.listOptions.users.list = this.users;
    this.listOptions.organisations.list = this.orgs;
    this.formattedNodes = this.formatNodes(this.nodes);
    this.listOptions.nodes = {
      display: 'title',
      list: this.formattedNodes
    };
    this.listOptions.tags.list = this.tags;
  },
  methods: {
    formatPreSelected: function formatPreSelected(selector) {
      var mapping = {
        organisations: {
          list: this.orgs,
          display_name: 'visible_name'
        },
        users: {
          list: this.users,
          display_name: 'display_name'
        },
        nodes: {
          list: this.formattedNodes,
          display_name: 'title'
        },
        tags: {
          list: this.tags,
          display_name: 'tag'
        }
      };
      if (!mapping[selector.list]) {
        return selector.preSelected;
      }
      selector.preSelected.forEach(function (item) {
        var entry = mapping[selector.list].list.find(function (e) {
          return e.id === item.id;
        });
        if (entry) {
          item[mapping[selector.list].display_name] = entry[mapping[selector.list].display_name];
        }
      });
      return selector.preSelected;
    },
    formatNodes: function formatNodes(nodes) {
      return _formatNodes(nodes, '', [], true).map(function (item) {
        return {
          id: item.id,
          title: item.title
        };
      });
    },
    resetAll: function resetAll() {
      var _this2 = this;
      Object.keys(this.$refs).forEach(function (filterName) {
        _this2.$refs[filterName][0].selected = [];
      });
      this.$forceUpdate();
      this.emit();
    },
    initialized: function initialized(val, selector) {
      this.numbersInitialized++;
      this.change(val, selector);
    },
    change: function change(val, selector) {
      var _this3 = this;
      var classPrefixes = {
        gaps: {
          selectorPrefix: '.gaps',
          elClassPrefix: 'gap'
        },
        nodes: {
          selectorPrefix: '.nodes',
          elClassPrefix: 'node'
        },
        organisations: {
          selectorPrefix: '.organisations',
          elClassPrefix: 'organisation'
        },
        users: {
          selectorPrefix: '.users',
          elClassPrefix: 'user'
        },
        tags: {
          selectorPrefix: '.tags',
          elClassPrefix: 'tag'
        }
      };
      if (!classPrefixes[selector.list]) {
        return;
      }
      (selector === null || selector === void 0 ? void 0 : selector.list) === 'gaps' && this.$nextTick(function () {
        var tags = _this3.$el.querySelectorAll(classPrefixes[selector.list].selectorPrefix + ' .multiselect__tags-wrap')[0].querySelectorAll(classPrefixes[selector.list].selectorPrefix + ' span.multiselect__tag');
        if (tags.length === 7) {
          tags = [].slice.call(tags, 1);
        }
        tags.forEach(function (el, index) {
          [].forEach.call(el.classList, function (className) {
            if (className.startsWith(classPrefixes[selector.list].elClassPrefix + '_tag__')) {
              el.classList.remove(className);
            }
          });
          if (typeof val[index] !== 'undefined') {
            el.classList.add(classPrefixes[selector.list].elClassPrefix + '_tag__' + val[index].id);
          }
        });
      });
      if (this.numbersInitialized == this.selectors.length) {
        this.emit();
      }
    },
    emit: function emit() {
      var _this4 = this;
      var returnObject = {};
      var filterString = this.makeFilterString();
      Object.keys(this.$refs).forEach(function (key) {
        returnObject[key] = _this4.$refs[key][0].selected;
      });
      this.$emit('listChange', {
        filterString: filterString.substr(0, filterString.length - 1),
        filter: returnObject
      });
    },
    makeFilterString: function makeFilterString() {
      var _this5 = this;
      var filter = '';
      var map = {
        gaps: 'analysisRAG',
        organisations: 'organisationIds',
        nodes: 'nodeIds',
        users: 'responsibleUserIds'
      };
      //forEach selected attribute in every refs object
      Object.keys(this.$refs).forEach(function (filterName) {
        var refObj = _this5.$refs[filterName][0];
        refObj.selected.forEach(function (item) {
          if (item.label === _this5.translate.selectAll) return;
          if (filterName === 'gaps') filter += 'filter[' + map[filterName] + '][]=' + Colors.gaps[item.id].label + '&';else if (filterName === 'tags') filter += 'filter[tagIds][]=' + item.id + '&';else filter += 'filter[' + map[filterName] + '][]=' + item.id + '&';
        });
      });
      return filter;
    }
  }
};