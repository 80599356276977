import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'RismaCarousel',
  description: 'RismaCarousel is component for going through elements like a carousel.',
  token: '<risma-carousel :list="[]"></risma-carousel>',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    list: {
      type: Array,
      required: true,
      note: 'Array of items that will be display in carousel'
    }
  },
  data: function data() {
    return {
      currentOffset: 0,
      offsetSlides: 0,
      slideWidth: 0,
      visibleSlides: 4
    };
  },
  computed: {
    atHeadOfList: function atHeadOfList() {
      return this.currentOffset === 0;
    },
    atEndOfList: function atEndOfList() {
      var listLength = this.list.length;
      return this.offsetSlides === listLength - this.visibleSlides || listLength <= this.visibleSlides;
    }
  },
  watch: {
    list: {
      deep: true,
      handler: function handler() {
        if (this.offsetSlides + this.visibleSlides >= this.list.length && this.list.length > this.visibleSlides) {
          this.offsetSlides = this.list.length - this.visibleSlides;
          this.currentOffset = -(this.slideWidth * this.offsetSlides);
        } else if (this.visibleSlides >= this.list.length) {
          this.offsetSlides = 0;
          this.currentOffset = 0;
        }
      }
    }
  },
  mounted: function mounted() {
    window.addEventListener('resize', this.handleResize);
    this.slideWidth = this.getSlideWidth();
  },
  beforeUnmount: function beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    moveCarousel: function moveCarousel(isAhead) {
      var slideWidth = this.getSlideWidth();
      if (isAhead && !this.atEndOfList) {
        this.currentOffset -= slideWidth;
        this.offsetSlides++;
      } else if (!isAhead && !this.atHeadOfList) {
        this.currentOffset += slideWidth;
        this.offsetSlides--;
      }
    },
    getSlideWidth: function getSlideWidth() {
      var rect = this.$el.querySelector('li').getBoundingClientRect();
      return rect.width;
    },
    handleResize: function handleResize() {
      this.slideWidth = this.getSlideWidth();
      this.currentOffset = -(this.slideWidth * this.offsetSlides);
    }
  }
};