import Modal from '@/components/Molecules/Modal/Modal';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { LiaTiaModalText, LiaTiaType } from '@/constants/compliance/LiaTiaGeneric';
export default {
  name: 'ImpactAssessmentModal',
  token: '<impact-assessment-modal type="tia" />',
  components: {
    Modal: Modal,
    RismaTitle: RismaTitle
  },
  props: {
    questionnaireAssessment: {
      type: Object,
      required: true,
      note: 'Assessment metadata'
    },
    gapArticle: {
      type: String,
      required: true,
      note: 'Gap article'
    }
  },
  emits: ['accept', 'dismiss'],
  data: function data() {
    return {
      LiaTiaModalText: LiaTiaModalText,
      translate: {
        apply: this.$trans('Apply'),
        dismiss: this.$trans('Dismiss'),
        unknown: this.$trans('Unknown')
      }
    };
  },
  computed: {
    type: function type() {
      return LiaTiaType[this.questionnaireAssessment.type].key;
    },
    questionNumber: function questionNumber() {
      var _this$questionnaireAs;
      return ((_this$questionnaireAs = this.questionnaireAssessment) === null || _this$questionnaireAs === void 0 || (_this$questionnaireAs = _this$questionnaireAs.question) === null || _this$questionnaireAs === void 0 ? void 0 : _this$questionnaireAs.questionnumber) || this.translate.unknown;
    },
    firstParagraphText: function firstParagraphText() {
      return "".concat(this.$trans(this.LiaTiaModalText[this.type].firstParagraph1), " ").concat(this.questionNumber, " ").concat(this.$trans(this.LiaTiaModalText[this.type].firstParagraph2), " ").concat(this.type === 'lia' ? this.gapArticle : '');
    }
  }
};