import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "px-5 pt-3 pb-6 border-2 border-gray-350 rounded-md"
};
var _hoisted_2 = {
  class: "flex justify-between mb-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");
  var _component_notification_pop_up = _resolveComponent("notification-pop-up");
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_information_asset_creation_modal = _resolveComponent("information-asset-creation-modal");
  var _component_risma_table = _resolveComponent("risma-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.hasNotificationPopUp ? (_openBlock(), _createBlock(_component_notification_pop_up, {
    key: 0,
    onDismiss: _ctx.dismissNotificationPopUp
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.yourActivityHasBeenCreated), 1 /* TEXT */), _createVNode(_component_router_link, {
        to: _ctx.notificationData.url
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.notificationData.title), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onDismiss"])) : _createCommentVNode("v-if", true), $data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: $data.notificationType,
    closeable: false
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.notificationMessage), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $options.title
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
    icon: "plus",
    height: "30",
    width: "30",
    class: "cursor-pointer stroke-2 text-blue-750",
    onClick: _withModifiers($options.toggleCreateModal, ["stop"])
  }, null, 8 /* PROPS */, ["onClick"])]), $data.showCreateModal ? (_openBlock(), _createBlock(_component_information_asset_creation_modal, {
    key: 2,
    type: $props.typeId,
    "information-assets": _ctx.informationAssets,
    "activity-type-id": $options.activityType && $options.activityType.id,
    "default-questionnaire-id": $options.activityType && $options.activityType.defaultQuestionnaireId || '',
    "set-current-user-as-owner": $props.onlyMy,
    onDismiss: $options.toggleCreateModal,
    onCreated: $options.createIA,
    onError: $options.showError
  }, null, 8 /* PROPS */, ["type", "information-assets", "activity-type-id", "default-questionnaire-id", "set-current-user-as-owner", "onDismiss", "onCreated", "onError"])) : _createCommentVNode("v-if", true), (_openBlock(), _createBlock(_component_risma_table, {
    key: $data.tableGeneratedKey,
    "print-title": $data.translate.processors,
    "state-key": $options.tableStateKey,
    "show-search-field": true,
    api: $options.getDataBE,
    onDelete: _cache[0] || (_cache[0] = function () {})
  }, null, 8 /* PROPS */, ["print-title", "state-key", "api"]))]);
}