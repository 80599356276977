export default {
  name: 'RismaCircle',
  introduction: 'Just circle with number',
  description: 'The RismaCircle component is used as part of the MonthlyOverview',
  token: '<risma-circle>2</risma-circle>',
  props: {
    isActive: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};