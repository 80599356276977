function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
import { getSingle } from '@/api/compliance/projects';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { UserLevels } from '@/constants/UserLevels';
export default {
  name: 'ComplianceReportTabs',
  components: {
    LightTabItem: LightTabItem
  },
  data: function data() {
    return {
      showSoa: false,
      showArticle30: false,
      project: {
        reports: []
      },
      translate: getTranslate['ReportTabs']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, {
    isAdmin: 'isAdmin',
    currentUserAccessLevelByProjectId: 'complianceAccessLevelByProjectId'
  })), {}, {
    showMemorandumTab: function showMemorandumTab() {
      var userHasSuperLevelAccess = this.isAdmin || this.currentUserAccessLevelByProjectId(this.$route.params.projectId) >= UserLevels.SUPER;
      return this.project.type === ProjectTypes.OUTSOURCING && userHasSuperLevelAccess;
    },
    showDataflow: function showDataflow() {
      return this.project.type === ProjectTypes.GDPR && this.getSettingValue('feature.dataflow');
    },
    reports: function reports() {
      return this.project.reports;
    }
  }),
  mounted: function mounted() {
    var _this = this;
    getSingle(this.$route.params.projectId).then(function (response) {
      return _this.project = response;
    });
  }
};