function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import ExpansionPanel from '@/components/Atoms/ExpansionPanel/ExpansionPanel';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
export default {
  name: 'RiskAssessmentRow',
  description: 'This component contains selectors with consequences and probabilities and list of relevant threats',
  components: {
    ExpansionPanel: ExpansionPanel,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    TinyMceBox: TinyMceBox,
    FeatherIcon: FeatherIcon,
    PreviewModalLink: PreviewModalLink
  },
  inject: {
    isPreview: {
      default: false
    },
    depRelationEnabled: {
      default: false
    }
  },
  props: {
    objType: {
      type: String,
      require: true,
      default: ''
    },
    rowData: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    row: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Is read only mode switch on'
    },
    startExpanded: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If the panel should start as expanded'
    },
    riskAssessmentGroups: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'data to show under RISK Assessment CIA'
    },
    customThreatsEnabled: {
      type: Boolean,
      default: false,
      required: false
    },
    inherentRisksEnabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['onValueChange', 'updateCustomThreat', 'updateUnderlyingRisk'],
  data: function data() {
    var _this$rowData$inheren, _this$rowData$inheren2, _this$rowData$residua, _this$rowData$residua2;
    return {
      inherentConsequenceSelected: (_this$rowData$inheren = this.rowData.inherentConsequence) !== null && _this$rowData$inheren !== void 0 ? _this$rowData$inheren : 0,
      inherentProbabilitySelected: (_this$rowData$inheren2 = this.rowData.inherentProbability) !== null && _this$rowData$inheren2 !== void 0 ? _this$rowData$inheren2 : 0,
      residualConsequenceSelected: (_this$rowData$residua = this.rowData.residualConsequence) !== null && _this$rowData$residua !== void 0 ? _this$rowData$residua : 0,
      residualProbabilitySelected: (_this$rowData$residua2 = this.rowData.residualProbability) !== null && _this$rowData$residua2 !== void 0 ? _this$rowData$residua2 : 0,
      showDiscarded: false,
      editingThreatsIds: [],
      RiskBaseModelProps: RiskBaseModelProps,
      translate: getTranslate['RiskAssessmentRow']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    keepCommentFieldsExpanded: function keepCommentFieldsExpanded() {
      return this.getSettingValue('feature.risk_comments_field_on_axis');
    },
    consequenceTitle: function consequenceTitle() {
      var inherentConsequenceTitle = "".concat(this.translate.inherentRisk, " - ").concat(this.row.consequenceLabel.toLowerCase());
      var residualConsequenceTitle = "".concat(this.translate.residualRisk, " - ").concat(this.row.consequenceLabel.toLowerCase());
      var consequenceTitle = this.inherentRisksEnabled ? residualConsequenceTitle : this.row.consequenceLabel;
      return {
        inherent: inherentConsequenceTitle + this.suffixString(this.row.consequenceOrigin),
        residual: consequenceTitle + this.suffixString(this.row.consequenceOrigin)
      };
    },
    probabilityTitle: function probabilityTitle() {
      var inherentProbabilityTitle = "".concat(this.translate.inherentRisk, " - ").concat(this.row.probabilityLabel.toLowerCase());
      var residualProbabilityTitle = "".concat(this.translate.residualRisk, " - ").concat(this.row.probabilityLabel.toLowerCase());
      var probabilityTitle = this.inherentRisksEnabled ? residualProbabilityTitle : this.row.probabilityLabel;
      return {
        inherent: inherentProbabilityTitle + this.suffixString(this.row.probabilityOrigin),
        residual: probabilityTitle + this.suffixString(this.row.probabilityOrigin)
      };
    },
    inherentScore: function inherentScore() {
      return this.inherentConsequenceSelected * this.inherentProbabilitySelected;
    },
    residualScore: function residualScore() {
      return this.residualConsequenceSelected * this.residualProbabilitySelected;
    },
    collapsedHeaderLabel: function collapsedHeaderLabel() {
      if (!this.inherentRisksEnabled) return "".concat(this.translate.riskScore, ": ").concat(this.residualScore);
      return "".concat(this.translate.inherentScore, ": ").concat(this.inherentScore, ", ").concat(this.translate.residualScore, ": ").concat(this.residualScore);
    },
    probabilityReadOnly: function probabilityReadOnly() {
      return this.readOnly || this.row.probabilityOrigin === 1 && this.objType === ObjectTypes.COMPLIANCE_NODE;
    },
    consequenceReadOnly: function consequenceReadOnly() {
      return this.readOnly || this.row.consequenceOrigin === 2 && this.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM;
    },
    consequenceOptionsExtended: function consequenceOptionsExtended() {
      return [{
        label: '0. N/A',
        value: 0
      }].concat(_toConsumableArray(this.row.consequences));
    },
    probabilityOptionsExtended: function probabilityOptionsExtended() {
      return [{
        label: '0. N/A',
        value: 0
      }].concat(_toConsumableArray(this.row.probabilities));
    },
    consequenceOptionsCustom: function consequenceOptionsCustom() {
      return this.row.consequences.map(function (option) {
        return _objectSpread(_objectSpread({}, option), {}, {
          label: option.value.toString()
        });
      });
    },
    probabilityOptionsCustom: function probabilityOptionsCustom() {
      return this.row.probabilities.map(function (option) {
        return _objectSpread(_objectSpread({}, option), {}, {
          label: option.value.toString()
        });
      });
    },
    discardedThreatsPresent: function discardedThreatsPresent() {
      var _this$riskAssessmentG;
      return (_this$riskAssessmentG = this.riskAssessmentGroups.find(function (group) {
        return group.isCurrentRisk;
      })) === null || _this$riskAssessmentG === void 0 ? void 0 : _this$riskAssessmentG.items.find(function (item) {
        return !item.relevant;
      });
    }
  }),
  methods: {
    suffixString: function suffixString(origin) {
      var suffix = '';
      if (origin === 1 && this.objType === ObjectTypes.COMPLIANCE_NODE) {
        suffix = ' (' + this.translate.maxValueFromUnderlyingSystems + ')';
      }
      if (origin === 2 && this.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM) {
        suffix = ' (' + this.translate.valueIsFromTheParentNode + ')';
      }
      return suffix;
    },
    onValueChange: function onValueChange(value, type) {
      this.$emit('onValueChange', {
        label: this.row.label,
        id: this.row.id,
        value: value || 0,
        comment: this.rowData["".concat(type, "Comment")]
      }, type);
    },
    onCommentChange: function onCommentChange(value, type) {
      this.$emit('onValueChange', {
        label: this.row.label,
        id: this.row.id,
        value: this["".concat(type, "Selected")],
        comment: value
      }, type);
    },
    updateCustomThreat: function updateCustomThreat(data) {
      this.$emit('updateCustomThreat', data, this.row.id, true);
    },
    toggleRelevant: function toggleRelevant(data) {
      this.updateCustomThreat(_objectSpread(_objectSpread({}, data), {}, {
        relevant: !data.relevant
      }));
    },
    toggleEditingThreat: function toggleEditingThreat(id) {
      this.editingThreatsIds = this.editingThreatsIds.includes(id) ? this.editingThreatsIds.filter(function (threatId) {
        return threatId !== id;
      }) : [].concat(_toConsumableArray(this.editingThreatsIds), [id]);
    }
  }
};