import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_single_select = _resolveComponent("single-select");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", null, [$data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.notificationMessage), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_single_select, {
    modelValue: $options.selectedNodeId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $options.selectedNodeId = $event;
    }),
    title: $data.translate.selectOutsourcingArrangement,
    options: $data.topLevelNodes,
    class: "select-top-level-node"
  }, null, 8 /* PROPS */, ["modelValue", "title", "options"]), _createVNode(_component_router_view, null, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        class: "mt-4",
        onError: $options.handleNewError
      }, null, 8 /* PROPS */, ["onError"]))];
    }),
    _: 1 /* STABLE */
  })]);
}