import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, mergeProps as _mergeProps, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "relative"
};
var _hoisted_2 = {
  class: "flex items-center"
};
var _hoisted_3 = {
  class: "flex justify-between"
};
var _hoisted_4 = {
  class: "pl-1 align-middle"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_save_button = _resolveComponent("save-button");
  var _component_traffic_light_selector = _resolveComponent("traffic-light-selector");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_tab_item = _resolveComponent("tab-item");
  var _component_tabs = _resolveComponent("tabs");
  var _component_attachment = _resolveComponent("attachment");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.saveStatus ? (_openBlock(), _createBlock(_component_save_button, {
    key: 0,
    "save-status": $props.saveStatus,
    onSaveStatusClicked: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('saveStatusClicked');
    })
  }, null, 8 /* PROPS */, ["save-status"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [$props.trafficLightSettings.value !== undefined ? (_openBlock(), _createBlock(_component_traffic_light_selector, {
    key: 0,
    "model-value": $props.trafficLightSettings.value,
    "light-type": $props.trafficLightSettings.lightType,
    "light-with-attribute": $props.trafficLightSettings.lightWithAttribute,
    "read-only": $props.trafficLightSettings.readOnly,
    class: "self-center",
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('changeTrafficLight', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "light-type", "light-with-attribute", "read-only"])) : _createCommentVNode("v-if", true), $options.displayIdInTitle && ($props.id || $props.editableId) ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 1,
    title: $props.id + '',
    editable: $props.editableId,
    truncate: false,
    "max-length": $props.idMaxLength,
    "min-input-width": $props.editableId ? 10 : 100,
    class: _normalizeClass(['max-w-220px mx-3 lining-nums', {
      'line-through': $props.lineThroughTitle
    }]),
    "allow-empty": true,
    onTitleChange: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('changeId', $event);
    })
  }, null, 8 /* PROPS */, ["title", "editable", "max-length", "min-input-width", "class"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $props.title,
    editable: $props.editableTitle,
    truncate: true,
    "allow-empty": $props.allowEmptyTitle,
    class: _normalizeClass([{
      'px-2 border-r-2 border-l-2 border-transparent': !$props.editableTitle,
      'line-through': $props.lineThroughTitle
    }, $options.displayIdInTitle && ($props.id || $props.editableId) ? 'max-w-[calc(100%-350px)]' : 'max-w-[calc(100%-250px)]']),
    onTitleChange: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('changeTitle', $event);
    })
  }, null, 8 /* PROPS */, ["title", "editable", "allow-empty", "class"]), $props.showCheckmark ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 2,
    icon: "check",
    height: "25",
    width: "25",
    class: "stroke-3 self-center"
  })) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_tabs, null, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, function (tab, index) {
        return _openBlock(), _createBlock(_component_tab_item, _mergeProps({
          key: index,
          link: tab.url,
          selected: tab.selected,
          disabled: tab.disabled
        }, _ctx.$attrs), {
          default: _withCtx(function () {
            return [_createElementVNode("span", null, [_createVNode(_component_feather_icon, {
              icon: tab.icon,
              class: _normalizeClass(['text-blue-750 inline-block stroke-2', tab.style, {
                'text-gray-250': tab.disabled
              }])
            }, null, 8 /* PROPS */, ["icon", "class"]), _createElementVNode("span", _hoisted_4, _toDisplayString(tab.label), 1 /* TEXT */)])];
          }),

          _: 2 /* DYNAMIC */
        }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["link", "selected", "disabled"]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),

    _: 1 /* STABLE */
  }), $props.isAttachmentEnabled ? (_openBlock(), _createBlock(_component_attachment, {
    key: 0,
    ref: "attachment",
    "files-list": $props.filesList,
    module: $props.module,
    "module-id": $props.moduleId,
    "read-only": !$props.editableAttachments,
    "has-version-buttons": $props.hasVersionButtons,
    "single-file-load": $props.singleFileLoad,
    "has-pin": $props.attachmentHasPin,
    "has-title": $props.attachmentHasTitle,
    "type-options": $props.fileTypeOptions,
    "files-and-links-separated": $props.attachmentsFilesAndLinksSeparated,
    "file-types-enabled": $props.attachmentFileTypesEnabled,
    "edit-file-enabled": $props.attachmentEditFileEnabled,
    class: "my-auto",
    onUpdatingAttachments: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('updatingAttachments');
    }),
    onGetActivityData: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('getActivityData');
    }),
    onFileUpdate: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.$emit('fileUpdate', $event);
    })
  }, null, 8 /* PROPS */, ["files-list", "module", "module-id", "read-only", "has-version-buttons", "single-file-load", "has-pin", "has-title", "type-options", "files-and-links-separated", "file-types-enabled", "edit-file-enabled"])) : _createCommentVNode("v-if", true)])]);
}