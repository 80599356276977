export default {
  name: 'TabItemNew',
  introduction: 'TabItemNew for tabs (temporary core component, later we will replace the existing one)',
  description: 'Tab itemNew is a part of Tabs components.',
  token: '<tab-item-new link="uri-to-navigate-to">Text to be displayed</tab-item>',
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    link: {
      type: [String, Object],
      required: false,
      default: '',
      note: 'Link to relocate'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is tab disabled'
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is used when in preview mode, to show selected tab'
    }
  },
  emits: ['click', 'changeTab'],
  computed: {
    event: function event() {
      return this.isPreview ? '' : undefined;
    }
  }
};