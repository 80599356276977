import i18n from '@/i18n';
export var getTranslate = {
  InformationAssets: function InformationAssets() {
    return {
      yourActivityHasBeenCreated: i18n.t('Your activity has been created'),
      search: i18n.t('Search'),
      my: i18n.t('My'),
      showAll: i18n.t('Show all'),
      areYouSureYouWantToExport: i18n.t('Are you sure you want to export'),
      itemsFrom: i18n.t('items from'),
      areYouSureYouWantToPrint: i18n.t('Are you sure you want to print'),
      processors: i18n.t('Processors')
    };
  },
  InformationAssetsExtendedView: function InformationAssetsExtendedView() {
    return {
      completionDegreeIn: i18n.t('Completion degree in %'),
      questionnaireScoring: i18n.t('Questionnaire scoring'),
      assessmentScoring: i18n.t('Assessment scoring'),
      tags: i18n.t('Tags'),
      name: i18n.t('Name'),
      address: i18n.t('Address'),
      countryCode: i18n.t('Country code'),
      phoneNumber: i18n.t('Phone number'),
      vatNumber: i18n.t('VAT number'),
      regNumber: i18n.t('Reg. number'),
      email: i18n.t('Email'),
      contactName: i18n.t('Contact name'),
      nodesLinkedThroughQuestionnaire: i18n.t('Nodes linked through questionnaire'),
      description: i18n.t('Description'),
      likelihood: i18n.t('Likelihood'),
      before: i18n.t('Before'),
      impact: i18n.t('Impact'),
      rpi: i18n.t('RPI'),
      after: i18n.t('After'),
      trafficLight: i18n.t('Traffic Light'),
      linkedSystems: i18n.t('Linked systems'),
      questionnaire: i18n.t('Questionnaire'),
      processLibrary: i18n.t('Process Library'),
      organisations: i18n.t('Organisations'),
      linkedControls: i18n.t('Linked controls'),
      linkedActions: i18n.t('Linked actions'),
      underlyingProcessors: i18n.t('Underlying processors'),
      attachments: i18n.t('Attachments'),
      underlyingSystems: i18n.t('Underlying systems'),
      location: i18n.t('Location'),
      purpose: i18n.t('Purpose'),
      linkedProcessors: i18n.t('Linked processors'),
      risks: i18n.t('Risks'),
      zipcode: i18n.t('Zipcode'),
      city: i18n.t('City'),
      companyName: i18n.t('Company name'),
      vatStatus: i18n.t('Vat status'),
      activityType: i18n.t('Activity type'),
      gapSchema: i18n.t('Gap schema'),
      solutionId: i18n.t('Solution ID')
    };
  },
  InformationAssetsOverview: function InformationAssetsOverview() {
    return {
      yourActivityHasBeenCreated: i18n.t('Your activity has been created'),
      search: i18n.t('Search'),
      delete: i18n.t('Delete'),
      cancel: i18n.t('Cancel'),
      areYouSureYouWantToDeleteThis: i18n.t('Are you sure you want to delete this')
    };
  },
  InformationAssetsSimpleView: function InformationAssetsSimpleView() {
    return {
      systems: i18n.t('Systems'),
      id: i18n.t('ID'),
      name: i18n.t('Name'),
      description: i18n.t('Description'),
      responsible: i18n.t('Responsible'),
      completionDegreeIn: i18n.t('Completion degree in %'),
      processes: i18n.t('Processes'),
      processors: i18n.t('Processors'),
      delete: i18n.t('Delete'),
      cancel: i18n.t('Cancel'),
      areYouSureYouWantToDeleteThis: i18n.t('Are you sure you want to delete this')
    };
  },
  InformationAssetsWrapper: function InformationAssetsWrapper() {
    return {
      overview: i18n.t('Overview'),
      my: i18n.t('My'),
      controllers: i18n.t('Controllers'),
      processors: i18n.t('Processors'),
      systems: i18n.t('Systems')
    };
  }
};