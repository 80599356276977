import ControlEntry from '@/components/Pages/Controls/ControlEntry';
import ControlsWrapper from '@/components/Pages/Controls/ControlsWrapper';
import ControlDetails from '@/components/Pages/Controls/ControlDetails';
import ControlHistory from '@/components/Pages/Controls/ControlHistory';
import LinksTab from '@/components/Organisms/LinksTab';
import Changelog from '@/components/Molecules/Changelog';
import MyControlsWrapper from '@/components/Pages/Controls/MyControlsWrapper';
import ControlReportsEntry from '@/components/Pages/Controls/ControlReportsEntry';
import ControlReports from '@/components/Pages/Controls/ControlReports';
import MotherControlReports from '@/components/Pages/Controls/MotherControlReports';
import ControlDataReports from '@/components/Pages/Controls/ControlDataReports';
import ControlsReview from '@/components/Pages/Controls/ControlsReview';
import ControlsDashboardWrapper from '@/components/Pages/Controls/ControlsDashboardWrapper';
import ControlsOverviewDashboard from '@/components/Pages/Controls/ControlsOverviewDashboard';
import ControlsCompletionDashboard from '@/components/Pages/Controls/ControlsCompletionDashboard';
import ControlsEfficiencyDashboard from '@/components/Pages/Controls/ControlsEfficiencyDashboard';
import ControlYearWheelDashboard from '@/components/Organisms/Controls/dashboards/ControlYearWheelDashboard';
import { userHasLevelControls } from '@/routes/auth';
import { MODULES } from '@/constants/modules';
import { UserLevels } from '@/constants/UserLevels';
import { useSettingsStore } from '@/Store/settingsStore';
export default (function () {
  var routes = [{
    path: '/controls2/controls',
    name: 'ControlsList',
    component: ControlsWrapper,
    meta: {
      userRightsFunction: userHasLevelControls(UserLevels.NORMAL)
    },
    props: function props(route) {
      return {
        successDeletion: route.query.successDeletion
      };
    }
  }, {
    path: '/controls2/my/:group?',
    name: 'ControlsMy',
    component: MyControlsWrapper,
    meta: {
      userRightsFunction: userHasLevelControls(UserLevels.NORMAL),
      keyFunction: function keyFunction() {
        return '/controls2/my';
      }
    },
    props: function props(route) {
      return {
        successDeletion: route.query.successDeletion,
        currentRouteGroup: route.params.group || ''
      };
    }
  }, {
    path: '/controls2/reports',
    name: 'ControlReportsEntry',
    component: ControlReportsEntry,
    redirect: {
      name: 'ControlReports'
    },
    meta: {
      userRightsFunction: userHasLevelControls(UserLevels.NORMAL)
    },
    children: [{
      path: 'main',
      name: 'ControlReports',
      component: ControlReports
    }, {
      path: 'mothercontrols',
      component: MotherControlReports
    }, {
      path: 'data',
      component: ControlDataReports
    }]
  }, {
    path: '/controls2/controls/:cpath',
    component: ControlEntry,
    props: true,
    meta: {
      userRightsFunction: userHasLevelControls(UserLevels.NORMAL)
    },
    redirect: {
      name: 'ControlDescription'
    },
    children: [{
      path: 'description',
      name: 'ControlDescription',
      component: ControlDetails
    }, {
      path: 'history',
      component: ControlHistory,
      props: true
    }, {
      path: 'changelog',
      component: Changelog,
      props: true
    }, {
      path: 'links',
      component: LinksTab,
      props: {
        module: MODULES.CONTROLS
      }
    }]
  }, {
    path: '/controls2/dashboards/annual-overview',
    name: 'ControlYearWheelDashboard',
    meta: {
      userRightsFunction: userHasLevelControls(UserLevels.PRIVILEGED)
    },
    component: ControlYearWheelDashboard
  }, {
    path: '/controls2/dashboards',
    component: ControlsDashboardWrapper,
    redirect: {
      name: 'ControlsOverviewDashboard'
    },
    meta: {
      userRightsFunction: userHasLevelControls(UserLevels.PRIVILEGED)
    },
    children: [{
      path: 'overview',
      name: 'ControlsOverviewDashboard',
      component: ControlsOverviewDashboard,
      meta: {
        userRightsFunction: userHasLevelControls(UserLevels.PRIVILEGED)
      }
    }, {
      path: 'completion',
      name: 'ControlsCompletionDashboard',
      component: ControlsCompletionDashboard,
      meta: {
        userRightsFunction: userHasLevelControls(UserLevels.PRIVILEGED)
      }
    }, {
      path: 'efficiency',
      name: 'ControlsEfficiencyDashboard',
      component: ControlsEfficiencyDashboard,
      meta: {
        userRightsFunction: function userRightsFunction() {
          return userHasLevelControls(UserLevels.PRIVILEGED) && useSettingsStore().getValue('feature.controls_efficiency_enabled');
        }
      }
    }]
  }, {
    path: '/controls2/review/:cpath/:id',
    name: 'ControlsReview',
    component: ControlsReview,
    props: true,
    meta: {
      userRightsFunction: userHasLevelControls(UserLevels.NORMAL)
    }
  }];
  return routes;
});