export default {
  name: 'TextBox',
  introduction: '',
  description: '',
  token: '<text-box></text-box>',
  props: {
    rows: {
      required: false,
      default: 5,
      type: Number,
      note: 'The number of rows in the textbox'
    },
    cols: {
      required: false,
      default: 35,
      type: Number,
      note: 'Number of columns in the textbox'
    },
    text: {
      required: false,
      default: '',
      type: String,
      note: 'The text needed to be displayed.'
    },
    placeholder: {
      required: false,
      default: '',
      type: String,
      note: 'A placeholder text'
    },
    enabled: {
      required: false,
      type: Boolean,
      default: true,
      note: 'Used to enable/disable text area, e.g. for showing read-only pages'
    },
    maxLength: {
      required: false,
      type: Number,
      default: undefined,
      note: 'maximum of characters in the text box'
    }
  },
  emits: ['updated', 'blur'],
  data: function data() {
    return {
      editedText: '',
      timeout: 0
    };
  },
  watch: {
    text: {
      handler: function handler() {
        this.editedText = this.text;
      }
    }
  },
  mounted: function mounted() {
    this.editedText = this.text;
  },
  methods: {
    pushEvent: function pushEvent() {
      if (!this.enabled) return;
      this.$emit('updated', this.editedText);
    }
  }
};