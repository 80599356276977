import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-03a9eb10"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "preview-title"
};
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = {
  class: "preview-organisations"
};
var _hoisted_4 = {
  class: "preview-tags"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['preview-risk-compact', {
      'preview-risk-closed': !$props.risk.open
    }])
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_preview_modal_link, {
    activity: $props.risk,
    class: "font-semibold hover:underline",
    onUpdated: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('updateFromPreviewMode', $event);
    }),
    onDeleteItem: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('deleteItem', $props.risk);
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title_with_icon, {
        class: "font-semibold hover:underline",
        icon: "confidential",
        type: "big",
        title: $props.risk.displayHeadline,
        "title-class": $props.risk.closed ? 'line-through' : '',
        "title-attribute": $options.hoverTitle,
        "display-icon": !!$props.risk.confidential
      }, null, 8 /* PROPS */, ["title", "title-class", "title-attribute", "display-icon"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"])]), _createElementVNode("p", {
    class: "preview-description",
    innerHTML: $options.truncateHtml($props.risk.description, 255)
  }, null, 8 /* PROPS */, _hoisted_2), _createElementVNode("p", _hoisted_3, _toDisplayString($options.organisationsList), 1 /* TEXT */), _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.tags), 1 /* TEXT */), _ctx.isApprovalFlowEnabled ? (_openBlock(), _createElementBlock("p", {
    key: 0,
    class: _normalizeClass(['preview-approval-flow', {
      'is-approved': $props.risk.approved
    }])
  }, [_createVNode(_component_risma_title, {
    title: "".concat($props.risk.approved ? $data.translate.approved : $data.translate.notApproved),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])], 2 /* CLASS */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}