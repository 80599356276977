import { Colors } from '@/Colors';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { percentageNormalizer } from '@/utils/percentageNormalizer';
export default {
  name: 'StackedBarChart',
  introduction: 'simple stacked bar in one line',
  description: '',
  token: "<stacked-bar-chart\n      :data=\"data\"\n      :colors=\"colors\"\n      :labels=\"labels\"\n      @clicked=\"barClicked\"/>",
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    data: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'values of the data'
    },
    colors: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'colors for each item'
    },
    labels: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'labels of each item'
    }
  },
  emits: ['clicked'],
  data: function data() {
    return {
      dataset: [],
      col: Colors,
      loaded: false
    };
  },
  computed: {
    isActiveDataset: function isActiveDataset() {
      return this.dataset.filter(function (el) {
        return el.isActive && el.data > 0;
      });
    },
    normalizedDataset: function normalizedDataset() {
      return percentageNormalizer(this.dataset, 2, 'data', 'isActive');
    }
  },
  watch: {
    data: {
      deep: true,
      handler: function handler() {
        this.updateData();
      }
    }
  },
  mounted: function mounted() {
    this.dataset = this.createDataset();
    this.loaded = true;
  },
  methods: {
    createDataset: function createDataset() {
      var _this = this;
      var dataset = [];
      this.data.forEach(function (item, index) {
        dataset.push({
          id: index,
          data: item,
          color: _this.colors[index],
          label: _this.labels[index],
          isActive: true,
          isHovered: false
        });
      });
      return dataset;
    },
    updateData: function updateData() {
      var _this2 = this;
      this.dataset.forEach(function (item, index) {
        item.data = _this2.data[index];
      });
    },
    onMouseOver: function onMouseOver(item) {
      item.isHovered = true;
    },
    onMouseLeave: function onMouseLeave(item) {
      item.isHovered = false;
    },
    getStyles: function getStyles(item) {
      var style = '';
      if (this.isActiveDataset.length) {
        style = this.getFistLastStyles(item, '');
      }
      return "width: ".concat(this.getWidth(item), "; background: ").concat(item.color, "; ").concat(style);
    },
    getFistLastStyles: function getFistLastStyles(item, style) {
      if (item.id == this.isActiveDataset[0].id) {
        style += 'border-top-left-radius: 20px; border-bottom-left-radius: 20px;';
      }
      if (item.id == this.isActiveDataset[this.isActiveDataset.length - 1].id) {
        style += 'border-top-right-radius: 20px; border-bottom-right-radius: 20px;';
      }
      return style;
    },
    getWidth: function getWidth(item) {
      return this.normalizedDataset.get(item) || 0;
    },
    filterLabels: function filterLabels(item) {
      item.isActive = !item.isActive;
    }
  }
};