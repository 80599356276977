import HierarchicalList from '@/components/Molecules/HierarchicalList';
export default {
  name: '',
  components: {
    HierarchicalList: HierarchicalList
  },
  data: function data() {
    return {
      nodes: [{
        title: 'root 1',
        children: [{
          title: 'Node 2',
          children: [{
            title: 'Node 10',
            children: [{
              title: 'Node 11'
            }]
          }]
        }, {
          title: 'Node 3',
          children: [{
            title: 'Node 5'
          }]
        }, {
          title: 'Node 4'
        }]
      }, {
        title: 'root 2',
        children: [{
          title: 'Node 6'
        }, {
          title: 'Node 7',
          children: [{
            title: 'Node 9'
          }]
        }, {
          title: 'Node 8'
        }]
      }]
    };
  }
};