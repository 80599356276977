import { Http } from '../../index';
import { handleRequestResponse, objectToQueryString } from '../../httpUtils';
export function searchGaps(projectId) {
  var filters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 25;
  var start = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  var sorting = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  var queryString = '';
  if (sorting.sortField && sorting.sortDir) {
    queryString += "sortField=".concat(sorting.sortField, "&");
    queryString += "sortDir=".concat(sorting.sortDir, "&");
  }
  if (limit) {
    queryString += "limit=".concat(limit, "&");
  }
  queryString += "start=".concat(start, "&");
  queryString += objectToQueryString({
    filters: filters
  });
  return handleRequestResponse(Http.get("/compliance/projects/".concat(projectId, "/report/gap?").concat(queryString)));
}