import { Colors } from '@/Colors';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaProgressBar from '@/components/Atoms/ProgressBar/RismaProgressBar';
export default {
  name: 'CardCompliance',
  introduction: '',
  description: '',
  token: '<card-compliance :activity="cardComplianceActivity"></card-compliance>',
  components: {
    trafficlight: TrafficLight,
    RismaTitle: RismaTitle,
    RismaProgressBar: RismaProgressBar
  },
  props: {
    activity: {
      type: Object,
      required: true,
      note: 'Process object'
    },
    isRouterUse: {
      type: Boolean,
      required: false,
      default: false,
      note: 'By default uses "a" tag. Set true to use "router-link"'
    }
  },
  data: function data() {
    return {
      Colors: Colors,
      translate: {
        questionsAnswered: this.$trans('questions answered')
      }
    };
  },
  methods: {
    stripHtml: function stripHtml(html) {
      var tmp = document.createElement('DIV');
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || '';
    }
  }
};