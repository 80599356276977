function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FilesList from '@/components/Atoms/FilesList';
import ProgressBar from '@/components/Atoms/ProgressBar/ProgressBar';
import { getBase64Content } from '@/utils/Utils';
import { FileFormats } from '@/constants/FileFormats';
export default {
  name: 'RismaFileUpload',
  introduction: 'FileUploader with progress bar',
  description: 'This is a FileUploader input field and progress bar, InputField & LoadingIndicatorSmall components.',
  token: '<risma-file-upload @upload="fileUploaded"/>',
  components: {
    RismaTitle: RismaTitle,
    FilesList: FilesList,
    ProgressBar: ProgressBar
  },
  props: {
    showUploadedFiles: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Show list of uploaded files'
    },
    showProgressBar: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Show progress bar'
    },
    useFormData: {
      type: Boolean,
      default: false,
      required: false,
      note: 'If to use formData format for files'
    },
    multiple: {
      type: Boolean,
      default: true,
      required: false,
      note: 'Allows multiple files to be selected for upload.'
    },
    singleFileLoad: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Loading only one file, on reload will replace the file'
    }
  },
  emits: ['input', 'upload', 'uploadFiles', 'startLoading', 'upload:supported', 'upload:unsupported'],
  data: function data() {
    return {
      uploading: false,
      uploadingMax: 0,
      uploadingValue: 0,
      isDragOver: false,
      existingFiles: [],
      translate: getTranslate['RismaFileUpload']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    attachmentsAllowed: function attachmentsAllowed() {
      if (this.getSettingValue('risma.attachments_allowed')) {
        return Object.keys(this.getSettingValue('risma.attachments_allowed')).join(', ');
      } else {
        return '';
      }
    },
    supportExtensionTitle: function supportExtensionTitle() {
      return "".concat(this.translate.weSupport, " ").concat(this.attachmentsAllowed);
    }
  }),
  mounted: function mounted() {
    var dndArea = this.$el.querySelector('.dnd-area');
    dndArea.addEventListener('dragenter', this.toggleModalContainerMask);
    dndArea.addEventListener('dragover', this.dragoverDummy);
    dndArea.addEventListener('dragleave', this.toggleModalContainerMask);
    dndArea.addEventListener('drop', this.attachFilesOnDrop);
  },
  beforeUnmount: function beforeUnmount() {
    var dndArea = this.$el.querySelector('.dnd-area');
    dndArea.removeEventListener('dragenter', this.toggleModalContainerMask);
    dndArea.removeEventListener('dragover', this.dragoverDummy);
    dndArea.removeEventListener('dragleave', this.toggleModalContainerMask);
    dndArea.removeEventListener('drop', this.attachFilesOnDrop);
  },
  methods: {
    toggleModalContainerMask: function toggleModalContainerMask(e) {
      this.isDragOver = !this.isDragOver;
      e.preventDefault();
    },
    dragoverDummy: function dragoverDummy(e) {
      e.preventDefault();
    },
    attachFilesOnDrop: function attachFilesOnDrop(e) {
      this.toggleModalContainerMask(e);
      var files = e.dataTransfer.files;
      this.handleLoad(files);
    },
    handleLoad: function handleLoad(files) {
      this.useFormData ? this.loadFilesWithMultipartEndpoint(files) : this.loadFile(files);
    },
    clickInput: function clickInput() {
      this.$el.querySelector('.input-load').click();
    },
    loadFilesWithMultipartEndpoint: function loadFilesWithMultipartEndpoint(eTargetFiles) {
      var formData = new FormData();
      for (var i = 0; i < eTargetFiles.length; i++) {
        var file = eTargetFiles[i];
        formData.append("files[".concat(i, "]"), file, file.name);
      }
      this.$emit('uploadFiles', formData);
    },
    emit: function emit() {
      this.$emit('upload', this.existingFiles);
      this.$emit('upload:supported', this.existingFiles.filter(function (item) {
        return !item.errorExtension;
      }));
      this.$emit('upload:unsupported', this.existingFiles.filter(function (item) {
        return item.errorExtension;
      }));
    },
    loadFile: function loadFile(eTargetFiles) {
      var _this = this;
      this.$emit('startLoading');
      this.uploading = true;
      var files = [];
      var loadedFiles = 0;
      var numOfFiles = Array.from(eTargetFiles).length;
      if (numOfFiles === 0) {
        this.uploading = false;
        return;
      }
      Array.from(eTargetFiles).forEach(function (file) {
        var fileExtension = file.name.split('.').pop();
        var fileObj = {
          filename: file.name
        };
        if (file.type !== '') {
          fileObj['mimeType'] = file.type;
        } else if (fileExtension === FileFormats.csv) {
          fileObj['mimeType'] = 'text/csv';
        } else if (fileExtension === FileFormats.msg) {
          fileObj['mimeType'] = 'application/vnd.bbf.usp.msg';
        } else {
          fileObj['mimeType'] = '';
        }
        if (fileExtension !== undefined && _this.attachmentsAllowed.indexOf(fileExtension.toLowerCase()) === -1) {
          fileObj.errorExtension = true;
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = function () {
          _this.uploadingMax = 0;
          _this.uploadingValue = 0;
        };
        reader.onload = function (e) {
          fileObj['content'] = getBase64Content(e);
          files.push(fileObj);
        };
        reader.onloadend = function () {
          _this.uploading = false;
          loadedFiles++;
          if (loadedFiles == numOfFiles) {
            _this.existingFiles = _this.singleFileLoad ? files : _this.existingFiles.concat(files);
            _this.emit();
            _this.$emit('input', files);
          }
        };
        reader.onprogress = function (e) {
          if (e.lengthComputable) {
            _this.uploadingMax = e.total;
            _this.uploadingValue = e.loaded;
          }
        };
      });
    },
    deleteFile: function deleteFile(index) {
      this.existingFiles.splice(index, 1);
      this.$refs.fileInput.value = null;
      this.emit();
    }
  }
};