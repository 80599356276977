import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_selectable_legend = _resolveComponent("selectable-legend");
  return _openBlock(), _createBlock(_component_selectable_legend, {
    modelValue: $data.combinedDataset,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.combinedDataset = $event;
    }),
    "total-dataset": $data.dataset,
    "applied-colors": $data.colors
  }, null, 8 /* PROPS */, ["modelValue", "total-dataset", "applied-colors"]);
}