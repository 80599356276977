import AuditOverview from '@/components/Pages/Tabs/AuditOverview';
import ContractEntry from '@/components/Pages/Contracts/ContractEntry';
import ContractDescription from '@/components/Pages/Contracts/ContractDescription';
import ContractParty from '@/components/Pages/Contracts/ContractParty';
import ContractsOverviewWrapper from '@/components/Pages/Contracts/ContractsOverviewWrapper';
import ContractsFileDocuments from '@/components/Pages/Contracts/ContractsFileDocuments';
import ContractsDPAOverview from '@/components/Pages/Contracts/ContractsDPAOverview';
import ContractsDashboard from '@/components/Pages/Contracts/ContractsDashboard';
import ContractsMonthlyOverview from '@/components/Organisms/Contracts/ContractsMonthlyOverview';
import ContractHistory from '@/components/Pages/Contracts/ContractHistory';
import ContractRelatedContracts from '@/components/Pages/Contracts/ContractRelatedContracts';
import ContractDocuments from '@/components/Pages/Contracts/ContractDocuments';
import MyCompanies from '@/components/Pages/Contracts/MyCompanies';
import LinksTab from '@/components/Organisms/LinksTab';
import Changelog from '@/components/Molecules/Changelog';
import { userHasLevelContracts } from '@/routes/auth';
import { MODULES } from '@/constants/modules';
import { TabTypes, Tabs } from '@/constants/Tabs';
import { CONTRACT_STATUSES } from '@/constants/contracts/contract';
import { UserLevels } from '@/constants/UserLevels';
import { COMPANY } from '@/constants/routes/company';
import { useSettingsStore } from '@/Store/settingsStore';
var ContractsOverviewComponent = function ContractsOverviewComponent() {
  if (useSettingsStore().getValue('feature.thin_contracts_overview_enable')) {
    return import('@/components/Pages/Contracts/ContractsThinOverview');
  }
  return import('@/components/Pages/Contracts/ContractsOverview');
};
export default (function () {
  return [{
    path: '/contracts/overview',
    component: ContractsOverviewWrapper,
    meta: {
      userRightsFunction: userHasLevelContracts(UserLevels.NORMAL)
    },
    redirect: {
      name: 'ContractsOverview'
    },
    children: [{
      path: 'all',
      name: 'ContractsOverview',
      component: ContractsOverviewComponent
    }, {
      path: 'my',
      name: 'MyContracts',
      component: ContractsOverviewComponent,
      props: function props() {
        return {
          isMy: true
        };
      }
    }, {
      path: 'mycompanies',
      component: MyCompanies,
      name: COMPANY.MyCompanies
    }, {
      path: 'expired',
      name: 'ExpiredContracts',
      component: ContractsOverviewComponent,
      props: function props() {
        return {
          isExpired: true
        };
      }
    }, {
      path: 'dpa',
      component: ContractsDPAOverview
    }, {
      path: 'documents',
      component: ContractsFileDocuments
    }, {
      path: 'content',
      component: ContractsFileDocuments,
      props: function props() {
        return {
          isContent: true
        };
      }
    }]
  }, {
    path: '/contracts/reports',
    name: 'ContractsReport',
    meta: {
      userRightsFunction: userHasLevelContracts(UserLevels.PRIVILEGED)
    },
    component: function component() {
      if (useSettingsStore().getValue('feature.thin_contracts_report_enable')) {
        return import('@/components/Pages/Contracts/ContractsThinReport');
      }
      return import('@/components/Pages/Contracts/ContractsReport');
    }
  }, {
    path: '/contracts/dashboard',
    name: 'ContractsDashboard',
    component: ContractsDashboard,
    redirect: {
      path: "/contracts/dashboard/".concat(CONTRACT_STATUSES.expiring)
    },
    meta: {
      keyFunction: function keyFunction() {
        return '/contracts/dashboard';
      },
      userRightsFunction: userHasLevelContracts(UserLevels.PRIVILEGED)
    },
    children: [{
      path: ':status',
      props: true,
      component: ContractsMonthlyOverview
    }]
  }, {
    path: '/contracts/contract/:id',
    component: ContractEntry,
    redirect: {
      name: 'ContractDescription'
    },
    props: true,
    meta: {
      keyFunction: function keyFunction($route) {
        return "/contracts/contract/".concat($route.params.id);
      }
    },
    children: [{
      path: TabTypes.DESCRIPTION,
      name: 'ContractDescription',
      component: ContractDescription
    }, {
      path: 'dpm/:idItem?',
      name: 'ContractAudit',
      props: function props(route) {
        return {
          id: route.params.id,
          module: MODULES.CONTRACTS,
          idItem: route.params.idItem
        };
      },
      component: AuditOverview
    }, {
      path: TabTypes.LINKS,
      component: LinksTab,
      props: {
        module: MODULES.CONTRACTS
      }
    }, {
      path: TabTypes.CONTRACT_PARTY,
      component: ContractParty
    }, {
      path: Tabs[TabTypes.RELATED_CONTRACTS].url,
      component: ContractRelatedContracts
    }, {
      path: TabTypes.HISTORY,
      component: ContractHistory
    }, {
      path: TabTypes.DOCUMENTS,
      component: ContractDocuments
    }, {
      path: TabTypes.CHANGELOG,
      component: Changelog
    }]
  }];
});