import draggable from 'vuedraggable';
import { getCurrentInstance } from 'vue';
import InputField from '@/components/Atoms/Inputs/InputField';
import TagItem from '@/components/Atoms/Containers/TagItem';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { DragOptions } from '@/constants/DragOptions';
export default {
  name: 'OptionsList',
  components: {
    InputField: InputField,
    TagItem: TagItem,
    draggable: draggable,
    FeatherIcon: FeatherIcon
  },
  introduction: 'Editable list of options',
  description: 'This component allows add and delete items from list',
  token: '<options-list options="[]" label="Answer options" />',
  props: {
    placeholder: {
      required: false,
      type: String,
      default: '',
      note: 'Placeholder text'
    },
    options: {
      required: false,
      default: function _default() {
        return [];
      },
      type: Array,
      note: 'Array of predefined values'
    },
    draggable: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Is drag and drop enable'
    }
  },
  emits: ['error'],
  data: function data() {
    return {
      uid: null,
      optionInput: '',
      dragOptions: DragOptions,
      translate: {
        answerOptions: this.$trans('Answer options')
      }
    };
  },
  mounted: function mounted() {
    this.id = getCurrentInstance().uid;
  },
  methods: {
    handleAddOption: function handleAddOption() {
      if (this.optionInput === '') {
        this.$emit('error');
        return;
      }
      this.options.push({
        label: this.optionInput
      });
      this.optionInput = '';
    },
    // new elements doesn`t have id, so it checks by label;
    handleDeleteOption: function handleDeleteOption(label) {
      var index = this.options.findIndex(function (item) {
        return item.label === label;
      });
      this.options.splice(index, 1);
      this.$forceUpdate();
    }
  }
};