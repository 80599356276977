export default {
  name: 'LoadMoreWrapper',
  description: 'Component-wrapper for showing more data',
  token: '<load-more-wrapper><slot :activities-limited="activitiesLimited"/></load-more-wrapper>',
  props: {
    activities: {
      type: Array,
      required: true,
      note: 'All activities'
    },
    limitBy: {
      type: Number,
      require: false,
      default: 50
    }
  },
  data: function data() {
    return {
      activitiesToShow: this.limitBy,
      translate: {
        loadMore: this.$trans('Load more'),
        showing: this.$trans('Showing'),
        outOf: this.$trans('out of')
      }
    };
  },
  computed: {
    loadMoreLabel: function loadMoreLabel() {
      return this.translate.loadMore;
    },
    loadInfo: function loadInfo() {
      return "".concat(this.translate.showing, " <b>").concat(this.activitiesToShow, "</b> ").concat(this.translate.outOf, " <b>").concat(this.activities.length, "</b>");
    },
    hiddenActivities: function hiddenActivities() {
      return this.activities.length - this.activitiesToShow;
    },
    activitiesLimited: function activitiesLimited() {
      return this.activities.slice(0, this.activitiesToShow);
    }
  },
  methods: {
    loadMore: function loadMore() {
      if (this.hiddenActivities > this.limitBy) {
        this.activitiesToShow += this.limitBy;
      } else {
        this.activitiesToShow += this.hiddenActivities;
      }
    }
  }
};