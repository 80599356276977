function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import InputFieldSelect from '@/components/Atoms/Inputs/InputFieldSelect';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Notification from '@/components/Molecules/Notification';
import currency from '@/constants/contracts/currency';
import { DateFormats } from '@/constants/DateFormats';
import { ERROR_MESSAGES } from '@/constants/contracts/errorMessages';
import { isDateAfter, isDateBefore, addTimeAndFormatDate, formatCurrentDateTime } from '@/utils/date';
export default {
  name: 'ContractRenewModal',
  token: "<contract-renew-modal\n      v-if=\"showRenewModal\"\n      :notification-options=\"notificationOptions\"\n      :status-list=\"workflowStatuses\"\n      :data=\"data\"\n      @dismiss=\"showRenewModal = false\"\n      @update=\"updateDataFromModal\" />",
  components: {
    Modal: Modal,
    InputFieldSelect: InputFieldSelect,
    DateTimePicker: DateTimePicker,
    SingleSelect: SingleSelect,
    Notification: Notification
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: function _default() {}
    },
    notificationOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    statusList: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    currencyOptions: {
      type: Array,
      required: false,
      default: currency,
      note: 'Better to send it as prop (so it would be 100% the same as on desc page)'
    }
  },
  emits: ['dismiss', 'update'],
  data: function data() {
    return {
      modalData: {},
      datePickerOptions: {
        format: DateFormats.DATE_FORMAT
      },
      fieldUpdated: 'startDate',
      translate: getTranslate['ContractRenewModal']()
    };
  },
  computed: {
    statuses: function statuses() {
      var _this = this;
      return this.statusList.map(function (item) {
        return {
          label: _this.$trans(item.title),
          value: item.id
        };
      });
    },
    renewHeader: function renewHeader() {
      return this.translate.renew + ' "' + this.data.title + '"';
    },
    errorMessage: function errorMessage() {
      if (this.modalData.startDate && this.modalData.endDate && isDateAfter(this.modalData.startDate, this.modalData.endDate)) {
        return this.$trans(ERROR_MESSAGES[this.fieldUpdated]);
      }
      if (this.isNotInRange(this.modalData.renewalDate)) {
        return this.$trans(ERROR_MESSAGES.renewalDate);
      }
      if (this.isNotInRange(this.modalData.bindingDate)) {
        return this.$trans(ERROR_MESSAGES.bindingDate);
      }
      return '';
    }
  },
  mounted: function mounted() {
    this.populateFields(this.data);
  },
  methods: {
    populateFields: function populateFields(data) {
      var startDate = data.endDate ? data.endDate : formatCurrentDateTime(DateFormats.DATE_TIME_FORMAT_FULL);
      var endDate = addTimeAndFormatDate(startDate, 1, 'year', DateFormats.DATE_TIME_FORMAT_FULL);
      this.modalData = _objectSpread(_objectSpread({}, data), {}, {
        startDate: startDate,
        endDate: endDate,
        renewalDate: null,
        bindingDate: null,
        contractAmount: data.renewalAmount ? data.renewalAmount : data.contractAmount,
        contractNumber: data.contractNumber + 1,
        contractAmountCurrency: data.renewalAmountCurrency ? data.renewalAmountCurrency : data.contractAmountCurrency
      });
    },
    isNotInRange: function isNotInRange(date) {
      if (!date) return;
      return this.modalData.startDate && isDateBefore(date, this.modalData.startDate) || this.modalData.endDate && isDateAfter(date, this.modalData.endDate);
    }
  }
};