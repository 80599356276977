function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import i18n from '@/i18n';
import { calculateStartOfDateAsDate, calculateStartDateBeforeTodayAsDate, calculateEndDateAsDate, calculatePastEndDateAsDate, calculateFutureEndDateByMonthsAsDate } from '@/utils/date';
export var getBaseRanges = function getBaseRanges() {
  return [{
    text: i18n.t('Today'),
    range: [calculateStartOfDateAsDate('day'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('Last week'),
    range: [calculateStartDateBeforeTodayAsDate(6, 'days', 'day'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('This month'),
    range: [calculateStartOfDateAsDate('month'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('Last month'),
    range: [calculateStartDateBeforeTodayAsDate(1, 'month', 'month'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('Last 3 months'),
    range: [calculateStartDateBeforeTodayAsDate(3, 'month', 'month'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('Last 6 months'),
    range: [calculateStartDateBeforeTodayAsDate(6, 'month', 'month'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('This year'),
    range: [calculateStartOfDateAsDate('year'), calculateEndDateAsDate('year')]
  }, {
    text: i18n.t('Year-to-date'),
    range: [calculateStartOfDateAsDate('year'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('Last year'),
    range: [calculateStartDateBeforeTodayAsDate(1, 'year', 'year'), calculatePastEndDateAsDate(1, 'year', 'year')]
  }];
};
export var getRangesWithNextThreeMonths = function getRangesWithNextThreeMonths() {
  var baseRange = getBaseRanges();
  return [].concat(_toConsumableArray(baseRange), [{
    text: i18n.t('Next 3 months'),
    range: [calculateStartOfDateAsDate('day'), calculateFutureEndDateByMonthsAsDate(3)]
  }]);
};