function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import * as Cookies from 'tiny-cookie';
import { userHasAccessToComplianceProjects } from '@/utils/access';
import { getControlReport } from '@/api/controls/controls';
import * as DataAPI from '@/api/risma/data';
import { getRiskProjects as _getRiskProjects } from '@/api/risk/risk';
import { getControlsReportFields, getDisabledFiltersByColumns, columnsMeta, fieldsByTypes, getDecoupleFilterFields, ControlObjectProps } from '@/constants/ControlsReportProps';
import { ControlCookies } from '@/constants/ControlCookies';
import { ControlLocalStorage } from '@/constants/ControlLocalStorage';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { getRangesWithNextThreeMonths } from '@/constants/dates/daterangepicker';
import { UserLevels } from '@/constants/UserLevels';
import { ObjectTypes } from '@/constants/ObjectTypes';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import { ControlReportsMixin } from '@/components/Pages/Controls/ControlReportsMixin';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { pushChildrenToParent, getCustomFieldsColsFromActivityTypes } from '@/utils/Utils';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'ControlReports',
  introduction: 'Control Reports',
  description: 'This is Page component for /controls2/reports/main',
  token: '<control-reports></control-reports>',
  components: {
    RismaToggle: RismaToggle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    RismaDateRangePicker: RismaDateRangePicker,
    RismaDatatable: RismaDatatable,
    FeatherIcon: FeatherIcon,
    SaveReport: SaveReport,
    PreviewModalLink: PreviewModalLink,
    Trafficlight: Trafficlight,
    RismaTitleWithIcon: RismaTitleWithIcon,
    SingleSelect: SingleSelect
  },
  mixins: [ControlReportsMixin, RismaDatatableDefaultMixin],
  setup: function setup() {
    return {
      selectedDateType: getLocalStorageComputed('controls2_reports_date_type', 'deadline')
    };
  },
  data: function data() {
    return {
      localStorageRangeKey: ControlLocalStorage.REPORTS_RANGE,
      stateLocal: ControlLocalStorage.REPORTS,
      toggleDeactivatedTimer: null,
      showDeactivated: false,
      columnsMeta: columnsMeta,
      isLoaded: false,
      allControls: [],
      initiatives: [],
      risks: [],
      processLibraryNodes: [],
      systems: [],
      iaDataProcessors: [],
      riskProjects: [],
      complianceProjects: [],
      reportType: 'controls-report',
      ranges: getRangesWithNextThreeMonths(),
      translate: getTranslate['ControlReports']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    disabledFilterByColumns: function disabledFilterByColumns() {
      var disableFilterForCustomFieldTypes = [CustomFieldTypes.TEXT_LINE, CustomFieldTypes.TEXT_FIELD, CustomFieldTypes.TRAFFIC_LIGHT];
      var result = getDisabledFiltersByColumns();
      Object.entries(this.customFields).forEach(function (field) {
        var _field = _slicedToArray(field, 2),
          key = _field[0],
          value = _field[1];
        if (disableFilterForCustomFieldTypes.includes(value.fieldType)) {
          result.push(key);
        }
      });
      if (this.efficiencyAssessmentEnabled) result.push(ControlObjectProps.COMPLETED_EFFICIENCY_COMMENT);
      return result;
    },
    reportFields: function reportFields() {
      var result = getControlsReportFields();
      if (this.getSettingValue('risma.organisational_groups') !== 1) {
        delete result[ControlObjectProps.ORGANISATION_GROUPS];
      }
      if (this.getSettingValue('risma.show_expected_evidence') !== 1) {
        delete result[ControlObjectProps.EXPECTED_EVIDENCE];
        delete result[ControlObjectProps.MOTHERCONTROL_EXPECTED_EVIDENCE];
      }
      if (!this.getSettingValue('risma.show_key_control')) {
        delete result[ControlObjectProps.KEY_CONTROL];
      }
      if (!this.getSettingValue('risma.show_automatic_manual')) {
        delete result[ControlObjectProps.KEY_AUTOMATIC_MANUAL];
      }
      if (!this.getSettingValue('risma.show_preventive_detective')) {
        delete result[ControlObjectProps.KEY_PREVENTIVE_DETECTIVE];
      }
      if (!this.getSettingValue('risma.show_high_complexity_low_complexity')) {
        delete result[ControlObjectProps.KEY_HIGH_LOW_COMPLEXITY];
      }
      if (!this.efficiencyAssessmentEnabled) {
        delete result[ControlObjectProps.COMPLETED_EFFICIENCY];
        delete result[ControlObjectProps.COMPLETED_EFFICIENCY_COMMENT];
      }
      return result;
    },
    customFields: function customFields() {
      return getCustomFieldsColsFromActivityTypes(this.activityTypes, this.customUnitPrefix);
    },
    complianceGapsFields: function complianceGapsFields() {
      var _this = this;
      if (!userHasAccessToComplianceProjects(this.currentUser, this.getSettingValue('app.module_compliance_enabled'))) return {};
      var result = {};
      var filteredProjects = this.currentUser.projects.filter(function (project) {
        return project.objType === ObjectTypes.COMPLIANCE_PROJECT && +project.access_level > UserLevels.NONE;
      });
      filteredProjects.forEach(function (project) {
        var title = _this.prepareComplianceTitle(project.id);
        if (title) {
          result["linkedCompliances-".concat(project.id)] = title;
        }
      });
      return result;
    },
    columns: function columns() {
      var _this2 = this;
      var translatedReportFields = {};
      var translatedCustomFields = {};
      Object.entries(this.reportFields).forEach(function (field) {
        var _field2 = _slicedToArray(field, 2),
          key = _field2[0],
          value = _field2[1];
        translatedReportFields[key] = _this2.$trans(value);
      });
      Object.entries(this.customFields).forEach(function (field) {
        var _field3 = _slicedToArray(field, 2),
          key = _field3[0],
          value = _field3[1];
        translatedCustomFields[key] = _this2.$trans(value.label);
      });
      return _objectSpread(_objectSpread(_objectSpread({}, translatedReportFields), translatedCustomFields), this.complianceGapsFields);
    },
    linkedColumnsForPreview: function linkedColumnsForPreview() {
      var asLinksList = fieldsByTypes.asLinksList;
      var complianceGapsKeys = Object.keys(this.complianceGapsFields);
      return [].concat(_toConsumableArray(asLinksList), complianceGapsKeys);
    },
    decoupleFilterFields: function decoupleFilterFields() {
      var complianceGapsFilters = Object.keys(this.complianceGapsFields).map(function (key) {
        return {
          key: key
        };
      });
      return [].concat(_toConsumableArray(getDecoupleFilterFields()), _toConsumableArray(complianceGapsFilters));
    },
    dateTypeOptions: function dateTypeOptions() {
      var _this3 = this;
      var dateTypeMapping = {
        deadline: 'Deadline date',
        completed: 'Completion date'
      };
      return Object.entries(dateTypeMapping).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          value = _ref2[0],
          label = _ref2[1];
        return {
          value: value,
          label: _this3.$trans(label)
        };
      });
    },
    efficiencyAssessmentEnabled: function efficiencyAssessmentEnabled() {
      return !!this.getSettingValue('feature.controls_efficiency_enabled');
    }
  }),
  watch: {
    selectedDateType: function selectedDateType() {
      this.refreshTable();
    }
  },
  mounted: function mounted() {
    var _this4 = this;
    this.getDeactivatedState();
    Promise.all([this.getRiskProjects(), this.getRisksWithScores(), this.getControls(), this.getInitiatives(), this.getNodeElementAllData(), this.getInformationAssetsSystems(), this.getInformationAssetsProcessors(), this.getComplianceProjects()]).then(function () {
      if (location.search && location.search.indexOf(_this4.queryString) === 0) {
        _this4.loadDataFromQuery();
      } else {
        _this4.refreshTable();
      }
    }).catch(function () {});
  },
  methods: {
    refreshTable: function refreshTable() {
      this.getInitRange();
      return this.getData();
    },
    getData: function getData() {
      var _this5 = this;
      this.isLoaded = false;
      var dateOptions = {
        dateAfter: this.deadlineAfter,
        dateBefore: this.deadlineBefore,
        dateBeforeTime: this.deadlineBeforeTime,
        dateType: this.selectedDateType
      };
      var SortByCpath = true;
      return getControlReport(dateOptions, this.showDeactivated, SortByCpath).then(function (list) {
        _this5.dataset = _this5.prepareDataset(list);
        _this5.enhanceDataset(_this5.dataset, _this5.columns);
      }).catch(function () {}).finally(function () {
        _this5.isLoaded = true;
      });
    },
    getControls: function getControls() {
      var _this6 = this;
      return DataAPI.getControls().then(function (_ref3) {
        var list = _ref3.list;
        return _this6.allControls = list;
      });
    },
    getInitiatives: function getInitiatives() {
      var _this7 = this;
      return DataAPI.getInitiatives().then(function (_ref4) {
        var list = _ref4.list;
        return _this7.initiatives = list;
      });
    },
    getNodeElementAllData: function getNodeElementAllData() {
      var _this8 = this;
      return DataAPI.getAllTrees().then(function (response) {
        return _this8.setIdToProccessTreeIdAndGetNodes(response);
      });
    },
    setIdToProccessTreeIdAndGetNodes: function setIdToProccessTreeIdAndGetNodes(response) {
      var _this9 = this;
      var processTreeId = null;
      if (response[0].id !== null) {
        processTreeId = response[0].id;
      } else {
        return [];
      }
      return DataAPI.getNodesByProcessTreeId(processTreeId).then(function (response) {
        var result = [];
        pushChildrenToParent(response, result);
        _this9.processLibraryNodes = result.map(function (node) {
          return _objectSpread(_objectSpread({}, node), {}, {
            processTreeId: processTreeId
          });
        });
      });
    },
    getRiskProjects: function getRiskProjects() {
      var _this10 = this;
      return _getRiskProjects().then(function (_ref5) {
        var list = _ref5.list;
        return _this10.riskProjects = list;
      });
    },
    getRisksWithScores: function getRisksWithScores() {
      var _this11 = this;
      return DataAPI.getRisks('?data[]=scores').then(function (_ref6) {
        var list = _ref6.list;
        return _this11.risks = list;
      });
    },
    getInformationAssetsSystems: function getInformationAssetsSystems() {
      var _this12 = this;
      return DataAPI.getInformationAssetsSystems().then(function (_ref7) {
        var list = _ref7.list;
        return _this12.systems = list;
      });
    },
    getInformationAssetsProcessors: function getInformationAssetsProcessors() {
      var _this13 = this;
      return DataAPI.getInformationAssetsProcessors().then(function (_ref8) {
        var list = _ref8.list;
        return _this13.iaDataProcessors = list;
      });
    },
    getComplianceProjects: function getComplianceProjects() {
      var _this14 = this;
      return DataAPI.getComplianceProjects().then(function (_ref9) {
        var list = _ref9.list;
        return _this14.complianceProjects = list;
      });
    },
    prepareDataset: function prepareDataset(controls) {
      var _this15 = this;
      var result = [];
      controls.forEach(function (control) {
        var baseControl = _this15.prepareItemBaseFields(control);
        var customFields = _this15.prepareItemCustomFields(control);
        var gapsFields = _this15.prepareItemGapsFields(control);
        result.push(_objectSpread(_objectSpread(_objectSpread({}, baseControl), customFields), gapsFields));
      });
      return result;
    },
    getDeactivatedState: function getDeactivatedState() {
      var cookieVal = Cookies.get(ControlCookies.REPORTS_DEACTIVATED);
      if (cookieVal) {
        try {
          this.showDeactivated = JSON.parse(cookieVal);
        } catch (e) {
          // nothing
        }
      }
    },
    toggleDeactivated: function toggleDeactivated() {
      var _this16 = this;
      Cookies.set(ControlCookies.REPORTS_DEACTIVATED, this.showDeactivated, {
        samesite: 'strict'
      });
      clearTimeout(this.toggleDeactivatedTimer);
      this.toggleDeactivatedTimer = setTimeout(function () {
        _this16.refreshTable();
      }, 300);
    },
    prepareFilterByLabel: function prepareFilterByLabel(value, columnKey) {
      var result = value;
      if (columnKey === 'trafficLight' || columnKey === 'completedEfficiency') {
        result = this.$trans(RismaControlsUtils.getTrafficLightLabel(value, true));
      }
      return result;
    },
    prepareComplianceTitle: function prepareComplianceTitle(id) {
      var complianceProject = this.complianceProjects.find(function (item) {
        return +item.id === +id;
      });
      var result = null;
      if (complianceProject && complianceProject.title) {
        result = "".concat(complianceProject.title, " gaps");
      }
      return result;
    },
    switchToLegacyReports: function switchToLegacyReports() {
      window.location.assign('/controls/reports');
    }
  }
};