function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import tinymce from 'tinymce';
import 'tinymce/skins/ui/oxide/skin.min.css';

// pluging that are used in specific types of TinyMceBox moved to different chunks: such as link, code, table, advlist, lists and paste
var plugins = {
  print: function print() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/print');
  },
  preview: function preview() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/preview');
  },
  importcss: function importcss() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/importcss');
  },
  searchreplace: function searchreplace() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/searchreplace');
  },
  autolink: function autolink() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/autolink');
  },
  autosave: function autosave() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/autosave');
  },
  save: function save() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/save');
  },
  directionality: function directionality() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/directionality');
  },
  visualblocks: function visualblocks() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/visualblocks');
  },
  visualchars: function visualchars() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/visualchars');
  },
  fullscreen: function fullscreen() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/fullscreen');
  },
  image: function image() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/image');
  },
  link: function link() {
    return import( /*webpackChunkName: 'tinymce-plugins-link'*/'tinymce/plugins/link');
  },
  media: function media() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/media');
  },
  template: function template() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/template');
  },
  code: function code() {
    return import( /*webpackChunkName: 'tinymce-plugins-code'*/'tinymce/plugins/code');
  },
  table: function table() {
    return import( /*webpackChunkName: 'tinymce-plugins-table'*/'tinymce/plugins/table');
  },
  charmap: function charmap() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/charmap');
  },
  hr: function hr() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/hr');
  },
  nonbreaking: function nonbreaking() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/nonbreaking');
  },
  anchor: function anchor() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/anchor');
  },
  insertdatetime: function insertdatetime() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/insertdatetime');
  },
  advlist: function advlist() {
    return import( /*webpackChunkName: 'tinymce-plugins-advlist'*/'tinymce/plugins/advlist');
  },
  lists: function lists() {
    return import( /*webpackChunkName: 'tinymce-plugins-lists'*/'tinymce/plugins/lists');
  },
  wordcount: function wordcount() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/wordcount');
  },
  help: function help() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/help');
  },
  quickbars: function quickbars() {
    return import( /*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/quickbars');
  },
  paste: function paste() {
    return import( /*webpackChunkName: 'tinymce-plugins-paste'*/'tinymce/plugins/paste');
  }
};
import Editor from '@tinymce/tinymce-vue';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { getCurrentInstance } from 'vue';
import { removeHtmlTags } from '@/utils/Utils';
import { clickOutside } from '@/utils/directives/clickOutside';
import { configs, configKeys } from '@/utils/base/TinyMceBoxConfigs';
export default {
  name: 'TinyMceBox',
  components: {
    Editor: Editor,
    RismaButton: RismaButton,
    FeatherIcon: FeatherIcon
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    initialContent: {
      type: String,
      required: false,
      default: '',
      note: 'Content to appear in the textarea from start'
    },
    config: {
      type: Object,
      required: false,
      default: null,
      note: ''
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Whether to instantiate the editor to read-only mode'
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto',
      note: 'Width of the editor box'
    },
    height: {
      type: [Number, String],
      required: false,
      default: 300,
      note: 'Height of the editor box'
    },
    showFullContentState: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Determines if data should be shortened'
    },
    showControlButtons: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Determines if edit/cancel/save buttons will be displayed'
    },
    maxSymbolsPreview: {
      type: Number,
      required: false,
      default: null,
      note: 'Determines maximum symbols that will be displayed on preview show'
    },
    alwaysOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'default',
      validator: function validator(type) {
        return Object.keys(configs).includes(type);
      },
      note: 'Configs with predefined toolbar and plugin sets'
    },
    editorWrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    debounceTimeout: {
      type: Number,
      require: false,
      default: 200
    },
    headline: {
      type: String,
      required: false,
      default: ''
    },
    focusOnMount: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['changed', 'saved', 'canceled'],
  data: function data() {
    return {
      timer: 0,
      isEditMode: false,
      isFullContentShown: false,
      lastSavedChanges: '',
      content: '',
      importedPlugins: [],
      configs: configs,
      id: null,
      isEditorInit: false,
      tinymceModulesLoaded: false,
      translate: getTranslate['TinyMceBox']()
    };
  },
  computed: {
    init: function init() {
      var _this = this;
      var vm = this;
      return _objectSpread({
        height: this.height,
        width: this.width,
        skin: false,
        content_css: false,
        paste_block_drop: true,
        setup: function setup(editor) {
          editor.on('init', function () {
            if (_this.focusOnMount) {
              editor.focus();
              editor.selection.select(editor.getBody(), true);
              editor.selection.collapse(false);
            }
          });
        },
        init_instance_callback: function init_instance_callback(editor) {
          editor.on('OpenWindow', function () {
            var dialog = document.querySelector('.tox-dialog');
            _this.replaceTextNode(dialog);
          });
        },
        relative_urls: false,
        target_list: false,
        extended_valid_elements: 'a[href|target=_blank]',
        paste_data_images: false,
        paste_preprocess: function paste_preprocess(_, args) {
          var allowedTags = ['a', 'b', 'i', 'u', 'ul', 'li', 'p', 'br', 'ol'];
          var tagsToRegexp = allowedTags.reduce(function (result, tag) {
            return result += "(".concat(tag, ")|");
          }, '');
          if (tagsToRegexp) {
            tagsToRegexp = tagsToRegexp.slice(0, -1);
          }
          var regexp = new RegExp("<(?!/?(".concat(tagsToRegexp, ")).+?>"), 'gm');
          var strippedContent = vm.removeAttributes(args.content.replace(regexp, ''));
          args.content = strippedContent;
        },
        branding: false,
        anchor_top: false,
        anchor_bottom: false,
        link_title: false,
        content_style: this.contentStyle
      }, this.selectedConfig);
    },
    contentStyle: function contentStyle() {
      var styles = "@import url(\"https://fonts.googleapis.com/css2?family=Raleway&display=swap\");\n                html { font-family: Raleway, sans-serif; } p { margin: 0 } ol, ul { padding-left: 4rem; }";
      return styles;
    },
    showFullContent: function showFullContent() {
      return !this.content || this.content.length < this.maxSymbolsToShow || this.showFullContentState || this.isFullContentShown;
    },
    editTitle: function editTitle() {
      if (this.type === configKeys.COMMENT) {
        return this.translate.addComment;
      }
      return this.translate.clickToEdit;
    },
    maxSymbolsToShow: function maxSymbolsToShow() {
      var _this$selectedConfig;
      return this.maxSymbolsPreview || ((_this$selectedConfig = this.selectedConfig) === null || _this$selectedConfig === void 0 ? void 0 : _this$selectedConfig.maxSymbolsPreview) || 500;
    },
    showEditLabel: function showEditLabel() {
      return !this.textData && !this.readOnly && !this.showControlButtons;
    },
    showExpansionLabel: function showExpansionLabel() {
      return !this.showFullContentState && this.textData && this.content.length > this.maxSymbolsToShow;
    },
    selectedConfig: function selectedConfig() {
      return this.configs[this.type];
    },
    editor: function editor() {
      return tinymce.get(this.id);
    },
    textData: function textData() {
      if (!this.content) return '';
      return removeHtmlTags(this.content).trim();
    },
    showPreviewData: function showPreviewData() {
      return (!this.isEditMode || this.readOnly) && !this.alwaysOpen;
    },
    shortenedData: function shortenedData() {
      if (!this.showPreviewData) return '';
      var result = this.content;
      if (!this.showFullContent) {
        result = this.textData.slice(0, this.maxSymbolsToShow) + '... ';
      }
      return result;
    },
    expansionLabel: function expansionLabel() {
      if (this.showEditLabel) {
        return this.editTitle;
      }
      if (this.showExpansionLabel) {
        if (this.showFullContent) {
          return this.translate.clickToShowLess;
        } else {
          return this.translate.clickToShowAll;
        }
      }
      return '';
    }
  },
  beforeMount: function beforeMount() {
    var _this2 = this;
    Promise.all([this.loadTinymcePlugins(), this.loadTinymce()]).then(function () {
      return _this2.tinymceModulesLoaded = true;
    });
  },
  mounted: function mounted() {
    this.content = this.initialContent;
    this.lastSavedChanges = this.initialContent;
    this.id = getCurrentInstance().uid.toString();
  },
  methods: {
    loadTinymce: function loadTinymce() {
      return Promise.all([import( /*webpackChunkName: 'tinymce-themes'*/'tinymce/themes/silver'), import( /*webpackChunkName: 'tinymce-themes'*/'tinymce/icons/default')]);
    },
    loadTinymcePlugins: function loadTinymcePlugins() {
      if (!this.selectedConfig.plugins || !this.selectedConfig.plugins.length) return;
      var requiredPlugins = this.selectedConfig.plugins.split(' ');
      return requiredPlugins.map(function (plugin) {
        return plugins[plugin]();
      });
    },
    onChange: function onChange(rawData) {
      var _this3 = this;
      if (!this.isEditMode && !this.alwaysOpen) return;
      if (!this.textData) {
        rawData = '';
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        if (!_this3.showControlButtons) {
          _this3.lastSavedChanges = rawData;
        }
        _this3.$emit('changed', rawData, _this3.textData);
      }, this.debounceTimeout);
    },
    onOutsideClick: function onOutsideClick(event) {
      if (this.showControlButtons) return;
      if (!event.target.closest('.tox')) {
        this.isEditMode = false;
      }
    },
    onExpansionLabelClick: function onExpansionLabelClick() {
      var _this4 = this;
      if (!this.textData && !this.readOnly) {
        this.isEditMode = true;
        this.isEditorInit = true;
        this.$nextTick(function () {
          var _this4$editor;
          (_this4$editor = _this4.editor) === null || _this4$editor === void 0 ? void 0 : _this4$editor.focus();
        });
        return;
      }
      this.isFullContentShown = !this.isFullContentShown;
    },
    onContentClick: function onContentClick() {
      var _this5 = this;
      if (this.showControlButtons || this.readOnly) return;
      this.isEditMode = true;
      this.isEditorInit = true;
      this.$nextTick(function () {
        var _this5$editor, _this5$editor2, _this5$editor3, _this5$editor4;
        (_this5$editor = _this5.editor) === null || _this5$editor === void 0 ? void 0 : _this5$editor.focus();
        (_this5$editor2 = _this5.editor) === null || _this5$editor2 === void 0 ? void 0 : _this5$editor2.selection.select((_this5$editor3 = _this5.editor) === null || _this5$editor3 === void 0 ? void 0 : _this5$editor3.getBody(), true);
        (_this5$editor4 = _this5.editor) === null || _this5$editor4 === void 0 ? void 0 : _this5$editor4.selection.collapse(false);
      });
    },
    saveChanges: function saveChanges() {
      this.lastSavedChanges = this.content;
      this.isEditMode = false;
      this.$emit('saved', this.content, this.textData);
    },
    cancelChanges: function cancelChanges() {
      var _this6 = this;
      clearTimeout(this.timer);
      this.content = this.lastSavedChanges;
      this.$emit('canceled', this.content, this.textData);
      this.$nextTick(function () {
        _this6.isEditMode = false;
      });
    },
    showTinyEditor: function showTinyEditor() {
      this.isEditMode = true;
      this.isEditorInit = true;
    },
    toggleTinyEditor: function toggleTinyEditor() {
      if (this.readOnly) return;
      this.isEditMode = !this.isEditMode;
      this.isEditorInit = true;
    },
    replaceTextNode: function replaceTextNode(node) {
      var _this7 = this;
      node.childNodes.forEach(function (childNode) {
        if (childNode.nodeType === 3) {
          if (childNode.nodeValue) {
            childNode.nodeValue = _this7.$trans(childNode.nodeValue);
          }
        } else {
          _this7.replaceTextNode(childNode);
        }
      });
    },
    // removes data-* attributes and unnecessary lines
    removeAttributes: function removeAttributes(text) {
      var element = document.createElement('div');
      element.innerHTML = text;
      this.removeAttributesRecursive(element);
      return element.innerHTML;
    },
    removeAttributesRecursive: function removeAttributesRecursive(element) {
      var _this8 = this;
      var dataset = element.dataset;
      for (var key in dataset) {
        element.removeAttribute('data-' + key.split(/(?=[A-Z])/).join('-').toLowerCase());
        element.removeAttribute('aria-setsize');
      }
      element.childNodes.forEach(function (item) {
        return _this8.removeAttributesRecursive(item);
      });
    }
  }
};