function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export default {
  methods: {
    // ––––––––––––––––––––––––––––––––––––
    // Getter methods
    // ––––––––––––––––––––––––––––––––––––
    getPathById: function getPathById(id) {
      var _this = this;
      var items = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.modelValue;
      var path = [];
      items.every(function (item, i) {
        if (item[_this.keyProp] === id) {
          path.push(i);
        } else if (item[_this.childrenProp]) {
          var childrenPath = _this.getPathById(id, item[_this.childrenProp]);
          if (childrenPath.length) {
            path = path.concat(i).concat(childrenPath);
          }
        }
        return path.length === 0;
      });
      return path;
    },
    getItemByPath: function getItemByPath(path) {
      var _this2 = this;
      var items = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.modelValue;
      var item = null;
      path.forEach(function (index) {
        var list = item && item[_this2.childrenProp] ? item[_this2.childrenProp] : items;
        item = list[index];
      });
      return item;
    },
    getItemDepth: function getItemDepth(item) {
      var level = 1;
      if (item[this.childrenProp] && item[this.childrenProp].length > 0) {
        var childrenDepths = item[this.childrenProp].map(this.getItemDepth);
        level += Math.max.apply(Math, _toConsumableArray(childrenDepths));
      }
      return level;
    },
    getSplicePath: function getSplicePath(path) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var splicePath = {};
      var numToRemove = options.numToRemove || 0;
      var itemsToInsert = options.itemsToInsert || [];
      var lastIndex = path.length - 1;
      var currentPath = splicePath;
      path.forEach(function (index, i) {
        if (i === lastIndex) {
          currentPath.$splice = [[index, numToRemove].concat(_toConsumableArray(itemsToInsert))];
        } else {
          var nextPath = {};
          currentPath[index] = _defineProperty({}, options.childrenProp, nextPath);
          currentPath = nextPath;
        }
      });
      return splicePath;
    },
    getRealNextPath: function getRealNextPath(prevPath, nextPath) {
      var ppLastIndex = prevPath.length - 1;
      var npLastIndex = nextPath.length - 1;
      if (prevPath.length < nextPath.length) {
        // move into deep
        var wasShifted = false;
        return nextPath.map(function (nextIndex, i) {
          if (wasShifted) {
            return i === npLastIndex ? nextIndex + 1 : nextIndex;
          }
          if (typeof prevPath[i] !== 'number') {
            return nextIndex;
          }
          if (nextPath[i] > prevPath[i] && i === ppLastIndex) {
            wasShifted = true;
            return nextIndex - 1;
          }
          return nextIndex;
        });
      } else if (prevPath.length === nextPath.length) {
        // if move bottom + move to item with children => make it a first child instead of swap
        if (nextPath[npLastIndex] > prevPath[npLastIndex]) {
          var target = this.getItemByPath(nextPath);
          if (target[this.childrenProp] && target[this.childrenProp].length && !this.isCollapsed(target)) {
            return nextPath.slice(0, -1).concat(nextPath[npLastIndex] - 1).concat(0);
          }
        }
      }
      return nextPath;
    }
  }
};