import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-44f169ac"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "column-switcher"
};
var _hoisted_2 = {
  class: "column-switcher-text"
};
var _hoisted_3 = {
  key: 0,
  class: "column-switcher-popup"
};
var _hoisted_4 = {
  class: "column-switcher-list"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", {
    class: "column-switcher-label flex text-blue-750",
    onMousedown: _cache[0] || (_cache[0] = _withModifiers(function () {
      return $options.handleSwitchClick && $options.handleSwitchClick.apply($options, arguments);
    }, ["stop"]))
  }, [_renderSlot(_ctx.$slots, "default", {}, function () {
    return [_createVNode(_component_feather_icon, {
      icon: "columns"
    }), _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$trans($props.visibilitySwitchLabel)), 1 /* TEXT */)];
  }, true)], 32 /* HYDRATE_EVENTS */), $data.popupVisible ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("ul", _hoisted_4, [$props.hasAllSwitcher && $props.columns.length > 1 ? (_openBlock(), _createElementBlock("li", {
    key: 0,
    class: _normalizeClass([{
      'removed': !$data.mainSwitch
    }, "bg-blue-250"]),
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.setAllVisibility && $options.setAllVisibility.apply($options, arguments);
    })
  }, _toDisplayString($data.translate.selectdeselectAll), 3 /* TEXT, CLASS */)) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, function (item, index) {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      class: _normalizeClass([{
        'removed': !item.visible && !$options.isAlwaysVisible(item)
      }, $options.isAlwaysVisible(item) ? 'bg-gray-610 cursor-default' : 'bg-blue-250 cursor-pointer']),
      onClick: function onClick($event) {
        return $options.setVisibility(item);
      }
    }, [_renderSlot(_ctx.$slots, "option", {
      option: item
    }, function () {
      return [_createElementVNode("span", {
        title: $options.isAlwaysVisible(item) ? $data.translate.alwaysVisibleColumn : undefined
      }, _toDisplayString(item.label), 9 /* TEXT, PROPS */, _hoisted_6)];
    }, true)], 10 /* CLASS, PROPS */, _hoisted_5);
  }), 128 /* KEYED_FRAGMENT */))])])), [[_directive_click_outside, $options.hideVisibilitySwitcher]]) : _createCommentVNode("v-if", true)]);
}