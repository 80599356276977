import i18n from '@/i18n';
import { UserLevels } from '@/constants/UserLevels';
var accessLevelsBase = [{
  value: UserLevels.NONE,
  label: 'NONE'
}, {
  value: UserLevels.NORMAL,
  label: 'NORMAL'
}, {
  value: UserLevels.PRIVILEGED,
  label: 'PRIVILEGED'
}, {
  value: UserLevels.SUPER,
  label: 'SUPER'
}];
var accessLevels = [].concat(accessLevelsBase, [{
  value: UserLevels.ADMIN,
  label: 'ADMIN'
}]);
var adminLevels = function adminLevels() {
  return [{
    value: 0,
    label: i18n.t('No')
  }, {
    value: 1,
    label: i18n.t('Yes')
  }];
};
var columns = function columns() {
  return [i18n.t('Object Id'), i18n.t('Name'), i18n.t('Admin'), 'Controls', 'Actions', 'Incidents', 'Risks', 'Contracts', 'Information assets', i18n.t('Locked fields')];
};
export { columns, adminLevels, accessLevelsBase, accessLevels };