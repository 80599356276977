function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useSolutionsStore } from '@/Store/solutionsStore';
import { create as createActionChild } from '@/api/execution/initiative';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
import SolutionsSelector from '@/components/Molecules/SolutionsSelector';
export default {
  name: 'ActionCreationModal',
  introduction: 'ActionCreationModal multiselect popup',
  description: 'ActionCreationModal with selector and list of selected items',
  token: '<action-creation-modal />',
  components: {
    Modal: Modal,
    InputField: InputField,
    Notification: Notification,
    SolutionsSelector: SolutionsSelector
  },
  props: {
    nodeId: {
      type: [Number, String],
      required: false,
      default: null
    }
  },
  emits: ['dismiss', 'create'],
  data: function data() {
    return {
      title: '',
      errorMessages: '',
      selectedSolutions: [],
      isRismaSelectOpen: false,
      translate: getTranslate['ActionCreationModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useSolutionsStore, {
    solutions: 'enabledSolutions'
  })),
  methods: {
    handleOnSubmit: function handleOnSubmit() {
      var _this = this;
      var activityType = this.getSettingValue('feature.actions_default_activity_type_id');
      var data = {
        title: this.title,
        solutionIds: this.selectedSolutions.map(function (item) {
          return item.id;
        }),
        parent: -1
      };
      if (this.nodeId) {
        data.appendTo = this.nodeId;
      }
      if (activityType) {
        data.activityType = activityType;
      }
      if (!this.handleValidation(data)) {
        return;
      }
      createActionChild(data).then(function (res) {
        return _this.$emit('create', res);
      }).catch(function (error) {
        return _this.errorMessages = error && error.error;
      });
    },
    handleValidation: function handleValidation(data) {
      this.errorMessages = '';
      if (!data.title) {
        this.errorMessages = this.translate.titleCannotBeEmpty;
      }
      return !this.errorMessages;
    }
  }
};