import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  style: {
    "margin-top": "2rem"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_user_selector = _resolveComponent("risma-user-selector");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_user_selector, {
    "available-options": _ctx.rismaUserSelectorUsers,
    "selected-options": _ctx.rismaUserSelectorSelected,
    title: _ctx.$trans('Responsible (R)')
  }, null, 8 /* PROPS */, ["available-options", "selected-options", "title"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_user_selector, {
    "available-options": _ctx.dailyTeamOptions,
    "selected-options": _ctx.dailyTeamSelectedOptions,
    "enable-select-all": false,
    "group-select": false,
    title: _ctx.$trans('Daily team (C)'),
    "label-key": "_name",
    "group-values": "items",
    "group-label": "label"
  }, null, 8 /* PROPS */, ["available-options", "selected-options", "title"])])]);
}