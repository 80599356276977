import { clickOutside } from '@/utils/directives/clickOutside';
export default {
  name: 'RismaPopup',
  introduction: 'Unblocking popup',
  description: "Unblocking popup for actions like creation of tag.\n    Component uses position as absolute and doesn't control visibility automatically.\n    For control visibility listen an event hide and change the property 'isHidden'.\n    Attention! use StopPropagation (click.stop) on element when you want to open the popup!",
  token: "\n<risma-popup\n    :is-hidden=\"false\"\n    @hide=\"fn\">\n    <!-- SLOT -->\n    <input-field\n        type=\"text\" />\n    <risma-button\n        text=\"Save\"\n        type=\"save\" />\n    <!-- SLOT ENDS -->\n</risma-popup>",
  directives: {
    clickOutside: clickOutside
  },
  props: {
    isHidden: {
      type: Boolean,
      default: true,
      required: true,
      note: 'Should popup be visible'
    }
  },
  emits: ['hide'],
  methods: {
    hide: function hide() {
      if (!this.isHidden) {
        this.$emit('hide');
      }
    }
  }
};