function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import * as fileAPI from '@/api/risma/file';
import { clickOutside } from '@/utils/directives/clickOutside';
import { isLockedControlUser as _isLockedControlUser } from '@/utils/access';
import { AttachmentModalMixin } from '@/components/Organisms/AttachmentModalMixin';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import AttachmentDropdown from '@/components/Molecules/AttachmentSection/AttachmentDropdown';
import AttachmentModal from '@/components/Organisms/AttachmentModal';
import EditAttachment from '@/components/Organisms/Contracts/EditAttachment';
import AttachmentVersionsModal from '@/components/Organisms/AttachmentVersionsModal';
import Alert from '@/components/Molecules/Modal/Alert';
import { MODULES } from '@/constants/modules';
import { ATTACHMENT_MODAL_MODES } from '@/constants/Attachments';
import { compareDates } from '@/utils/compare';
export default {
  name: 'Attachment',
  introduction: 'AttachmentSection contains all components to work with load files',
  description: 'The component contains AttachmentDropdown and AttachmentModal',
  token: "<attachement\n              :files-list=[] />",
  components: {
    FeatherIcon: FeatherIcon,
    AttachmentDropdown: AttachmentDropdown,
    AttachmentModal: AttachmentModal,
    AttachmentVersionsModal: AttachmentVersionsModal,
    EditAttachment: EditAttachment,
    Alert: Alert
  },
  directives: {
    clickOutside: clickOutside
  },
  mixins: [AttachmentModalMixin],
  props: {
    filesList: {
      type: Array,
      required: true,
      note: 'List of files'
    },
    module: {
      type: String,
      required: true,
      note: 'A name of module, for example, "control" or "risk"'
    },
    moduleId: {
      type: [String, Number],
      required: false,
      default: '',
      note: 'An id of one of the entities of modules. For example, an cpath of the specific control'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If attachments are readonly and can be downloaded'
    },
    hasVersionButtons: {
      type: Boolean,
      required: false,
      default: false,
      note: 'if item has version buttons (process library should have them)'
    },
    singleFileLoad: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Loading only one file, on reload will replace the file'
    },
    hasPin: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Has pin/unpin functionality'
    },
    hasTitle: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Has title field'
    },
    typeOptions: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: false,
      note: 'The list of options for file types if any'
    },
    filesAndLinksSeparated: {
      type: Boolean,
      required: true,
      note: 'a mode where you can add files and links separately'
    },
    fileTypesEnabled: {
      type: Boolean,
      required: true,
      note: 'show selector with file types'
    },
    editFileEnabled: {
      type: Boolean,
      required: true,
      note: 'show edit button on files'
    }
  },
  emits: ['fileUpdate', 'getActivityData', 'updatingAttachments'],
  data: function data() {
    return {
      isDropdownOpen: false,
      isConfirmOpen: false,
      isModalOpen: false,
      isVersionsModalOpen: false,
      fileVersionsAddNewMode: true,
      remote: true,
      selectedFileId: false,
      file: null,
      documentType: 0,
      isEditModalActive: false,
      editedFile: null,
      pinnedByDefault: false,
      translate: {
        areYouSureYouWantToDeleteThisAttachment: "\n                    ".concat(this.$trans('Are you sure you want to delete this'), " ").concat(this.$trans('Attachment').toLowerCase(), "?\n                "),
        deleteAttachment: this.$trans('Delete') + ' ' + this.$trans('Attachment').toLowerCase()
      },
      isApplyDisabled: false,
      attachmentModalMode: ATTACHMENT_MODAL_MODES.FULL
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    attachmentTitle: function attachmentTitle() {
      var filesLength = this.filteredFilesList.length;
      return filesLength.toString() + ' ' + this.$trans(filesLength === 1 ? 'Attachment' : 'Attachments').toLowerCase();
    },
    ownerId: function ownerId() {
      return this.currentUser.id;
    },
    isLockedControlUser: function isLockedControlUser() {
      return _isLockedControlUser(this.currentUser);
    },
    hasVisibleFiles: function hasVisibleFiles() {
      return this.module === MODULES.CONTRACTS ? this.filesList.filter(function (file) {
        return file.pinned;
      }).length : this.filesList.length;
    },
    isToggleDropdownActive: function isToggleDropdownActive() {
      return this.hasVisibleFiles || !this.readOnly;
    },
    filteredFilesList: function filteredFilesList() {
      if (!this.hasPin) return this.filesList;
      return this.filesList.filter(function (file) {
        return !file.mimeType || file.pinned;
      });
    },
    sortedFilesList: function sortedFilesList() {
      return _toConsumableArray(this.filteredFilesList).sort(function (a, b) {
        return compareDates(a.created, b.created, true);
      });
    }
  }),
  methods: {
    hide: function hide() {
      if (this.isConfirmOpen || this.isModalOpen) return;
      this.isDropdownOpen = false;
    },
    togglePin: function togglePin(id) {
      var file = this.filesList.find(function (attachment) {
        return +attachment.id === +id;
      });
      if (file) {
        var updatedFile = {
          id: file.id,
          pinned: !file.pinned
        };
        this.updateFile(updatedFile);
      }
    },
    openEditModal: function openEditModal(file) {
      this.editedFile = file;
      this.isEditModalActive = true;
    },
    onFileEdit: function onFileEdit(file) {
      this.isEditModalActive = false;
      this.updateFile(file);
    },
    updateFile: function updateFile(file) {
      var _this = this;
      this.$emit('updatingAttachments');
      fileAPI.editFile(file).then(function (response) {
        _this.$emit('fileUpdate', response.id);
      });
    },
    closeAttachmentModal: function closeAttachmentModal() {
      this.dismissModal();
      this.attachFileErrorMessage = '';
      this.dismissVersionModal();
    },
    dismissModal: function dismissModal() {
      this.isModalOpen = false;
      this.documentType = 0;
    },
    toggleModal: function toggleModal(modalMode) {
      var pinnedByDefault = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.pinnedByDefault = pinnedByDefault;
      this.isModalOpen = !this.isModalOpen;
      if (!this.filesAndLinksSeparated) return;
      this.attachmentModalMode = modalMode;
    },
    toggleVersionsModal: function toggleVersionsModal(file) {
      this.file = file;
      this.fileVersionsAddNewMode = true;
      this.isVersionsModalOpen = !this.isVersionsModalOpen;
    },
    addAttachmentsRemote: function addAttachmentsRemote(attachments) {
      var _this2 = this;
      this.$emit('updatingAttachments');
      this.isApplyDisabled = true;
      fileAPI.uploadFiles(attachments).then(function () {
        _this2.$emit('getActivityData');
        _this2.closeAttachmentModal();
      }).catch(function (error) {
        _this2.attachFileErrorMessage = _this2.handleResponseError(error);
      }).finally(function () {
        return _this2.isApplyDisabled = false;
      });
    },
    handleAttachmentsNew: function handleAttachmentsNew(attachmentsNew) {
      var newVersion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (!attachmentsNew.length) {
        return;
      }
      var fileAttachmentsCount = attachmentsNew.reduce(function (count, attachment) {
        return attachment.url ? count : count + 1;
      }, 0);
      var attachmentBase = {
        owner: this.ownerId
      };
      if (this.module === MODULES.CONTRACTS && fileAttachmentsCount > 1) {
        return;
      }
      if (!newVersion) {
        attachmentBase.module = this.module;
        attachmentBase.moduleId = this.moduleId;
      }
      var attachments = attachmentsNew.map(function (attach) {
        return Object.assign({}, attachmentBase, attach);
      });
      this.addAttachmentsRemote(attachments);
    },
    openConfirmModal: function openConfirmModal(id) {
      this.selectedFileId = id;
      this.toggleConfirmModal();
    },
    closeConfirmModal: function closeConfirmModal() {
      this.selectedFileId = null;
      this.toggleConfirmModal();
    },
    toggleConfirmModal: function toggleConfirmModal() {
      this.isConfirmOpen = !this.isConfirmOpen;
    },
    removeAttachment: function removeAttachment() {
      var _this3 = this;
      this.$emit('updatingAttachments');
      return fileAPI.deleteFile(this.selectedFileId).then(function (_ref) {
        var id = _ref.id;
        _this3.$emit('getActivityData');
        _this3.file && _this3.afterDeletion(id);
        _this3.closeConfirmModal();
      });
    },
    onCreateNewVersion: function onCreateNewVersion(id) {
      var _this4 = this;
      this.getFileById(id).then(function () {
        _this4.fileVersionsAddNewMode = true;
        _this4.isVersionsModalOpen = true;
      });
    },
    onShowVersions: function onShowVersions(id) {
      var _this5 = this;
      this.getFileById(id).then(function () {
        _this5.fileVersionsAddNewMode = false;
        _this5.isVersionsModalOpen = true;
      });
    },
    afterDeletion: function afterDeletion(id) {
      this.file.revisions = this.file.revisions.filter(function (revision) {
        return revision.id != id;
      });
    },
    getFileById: function getFileById(id) {
      var _this6 = this;
      return fileAPI.getFileById(id).then(function (data) {
        _this6.file = data;
      });
    },
    dismissVersionModal: function dismissVersionModal() {
      this.isVersionsModalOpen = false;
      this.file = null;
    }
  }
};