function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useTagsStore } from '@/Store/tagsStore';
import { getReport2, getReport2Url } from '@/api/compliance/reports/predefinedReport';
import { getQuestionnairesByQuestionnaireTypeId as _getQuestionnairesByQuestionnaireTypeId } from '@/api/compliance/questionnaire';
import { getSingle } from '@/api/compliance/projects';
import { getAllControllers, getAllProcessors } from '@/api/compliance/informationAssets';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import SoaTable from '@/components/Pages/Compliance/Reports/PredefinedReports/soa/Table';
import Article30Table from '@/components/Pages/Compliance/Reports/PredefinedReports/article30/Table';
import QuestionnaireSelection from '@/components/Pages/Compliance/Reports/PredefinedReports/QuestionnaireSelection';
import { mapQuestionAnswers } from '@/components/Pages/Compliance/Reports/PredefinedReports/utils';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { getDataFlowImageUrl } from '@/utils/Compliance/DataFlow';
import { getAnsweredQuestionsByActivity } from '@/utils/reports/questionnaire';
import { filterAsset } from '@/utils/format/objectsFromIds';
import { mapToCommaList } from '@/utils/format/mapTo';
import { MODULES } from '@/constants/modules';
import { getCompanyWithUrl } from '@/utils/format/companies';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { ReportTypes } from '@/constants/reports';
export default {
  name: 'QuestionnaireReport',
  components: {
    Article30Table: Article30Table,
    SoaTable: SoaTable,
    QuestionnaireSelection: QuestionnaireSelection,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle
  },
  props: {
    projectId: {
      type: [Number, String],
      required: true,
      note: 'The ID of the project'
    },
    reportType: {
      type: String,
      required: true,
      note: 'The type of report to display'
    },
    savedReportOptions: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      loading: false,
      showReport: false,
      rows: [],
      questions: [],
      answers: [],
      entities: [],
      questionnaires: [],
      selectedQuestionnaire: null,
      error: '',
      report: null,
      reportTable: null,
      mode: null,
      informationAssets: null,
      reportUrl: '',
      article30: 'article30',
      processTreeNodes: [],
      allDataController: [],
      allDataProcessors: [],
      allCompanies: [],
      companiesMap: new Map(),
      updatedContactCard: null,
      includeDataFlow: false,
      isAnsweredOnly: false,
      includeRaciEmails: false,
      includeHelpText: false,
      reportArgs: {},
      isExtendedViewOnLoad: false,
      translate: {
        nothingToShow: this.$trans('Nothing to show'),
        enterYourSearchCriteriaAboveToViewSearchRe: this.$trans('Enter your search criteria above to view search results')
      },
      initialFilters: null
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useOrganisationsStore, ['organisations'])), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useTagsStore, ['tags'])), {}, {
    showNothingToShowMessage: function showNothingToShowMessage() {
      return this.mode === this.article30 && !this.entities.length && this.showReport;
    },
    localStateName: function localStateName() {
      return 'questionnaire_report_results_' + this.projectId;
    },
    savedReportType: function savedReportType() {
      if (this.reportType === 'article-30') {
        return ReportTypes.COMPLIANCE_ARTICLE30;
      }
      return ReportTypes.COMPLIANCE_QUESTIONNAIRE;
    }
  }),
  watch: {
    reportType: function reportType() {
      this.init();
    },
    savedReportOptions: function savedReportOptions() {
      if (this.savedReportOptions) {
        this.isExtendedViewOnLoad = true;
        this.initialFilters = this.savedReportOptions;
      }
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      this.handleClearTable();
      if (this.savedReportOptions) {
        this.isExtendedViewOnLoad = true;
        this.initialFilters = this.savedReportOptions;
      }
      return Promise.all([getAllControllers().then(function (_ref) {
        var list = _ref.list;
        _this.allDataController = list;
      }), this.getProcessors(), this.getCompanies(), getSingle(this.projectId).then(function (project) {
        _this.report = project.reports[_this.reportType];
        if (_this.report === undefined) {
          // Unknown report type. Slice is off and go back to the first report available
          var newRoute = _this.$route.path.split('/').slice(0, -1).join('/');
          _this.$router.replace(newRoute);
          return;
        }
        _this.reportTable = _this.report.table + '-table';
        _this.mode = _this.report.table;
      }).then(this.getQuestionnairesByQuestionnaireTypeId)]);
    },
    getQuestionnairesByQuestionnaireTypeId: function getQuestionnairesByQuestionnaireTypeId() {
      var _this2 = this;
      return _getQuestionnairesByQuestionnaireTypeId(this.report.questionnaireTypeId).then(function (response) {
        return _this2.questionnaires = response.list;
      });
    },
    getCompanies: function getCompanies() {
      var _this3 = this;
      return _getCompanies().then(function (_ref2) {
        var list = _ref2.list;
        return _this3.allCompanies = list;
      });
    },
    getProcessors: function getProcessors() {
      var _this4 = this;
      return getAllProcessors().then(function (_ref3) {
        var list = _ref3.list;
        return _this4.allDataProcessors = list;
      });
    },
    handleCreateReport: function handleCreateReport(_ref4) {
      var _this5 = this;
      var questionnaireIds = _ref4.questionnaireIds,
        questionNumbers = _ref4.questionNumbers,
        nodeIds = _ref4.nodeIds,
        showControllersAndProcessors = _ref4.showControllersAndProcessors,
        includeDataFlow = _ref4.includeDataFlow,
        includeRaciEmails = _ref4.includeRaciEmails,
        isAnsweredOnly = _ref4.isAnsweredOnly,
        includeHelpText = _ref4.includeHelpText,
        organisationIds = _ref4.organisationIds,
        _ref4$resetQuery = _ref4.resetQuery,
        resetQuery = _ref4$resetQuery === void 0 ? true : _ref4$resetQuery;
      this.showReport = false;
      this.loading = true;
      this.questions = [];
      this.error = '';
      this.includeDataFlow = includeDataFlow;
      this.includeRaciEmails = includeRaciEmails;
      this.includeHelpText = includeHelpText;
      this.isAnsweredOnly = isAnsweredOnly;
      this.showControllersAndProcessors = showControllersAndProcessors;
      this.reportArgs = {
        questionnaireIds: questionnaireIds,
        questionNumbers: questionNumbers,
        nodeIds: nodeIds,
        showControllersAndProcessors: showControllersAndProcessors,
        includeDataFlow: includeDataFlow,
        includeRaciEmails: includeRaciEmails,
        organisationIds: organisationIds
      };
      if (this.savedReportOptions && resetQuery) {
        this.$router.replace({
          query: {}
        });
      }
      this.reportUrl = getReport2Url(this.projectId, questionnaireIds, questionNumbers, nodeIds);
      getReport2(this.projectId, questionnaireIds, questionNumbers, nodeIds, this.report.predefinedSearch).then(function (resp) {
        _this5.tree = resp.tree;
        _this5.questions = isAnsweredOnly ? getAnsweredQuestionsByActivity(resp.questions, nodeIds, 'compliance_nodes_id') : resp.questions;
        _this5.answers = resp.answers;
        _this5.entities = Object.values(resp.entities);
        _this5.informationAssets = resp.informationAssets;
        _this5.files = resp.files;
        _this5.rows = [];
        _this5.error = '';
        _this5.processTreeNodes = resp.processTreeNodes;
        var nodeIdLocal = _toConsumableArray(nodeIds);
        _this5.handleMapNodes(_this5.tree, null, questionnaireIds, nodeIdLocal);
        _this5.showReport = true;
        _this5.loading = false;
      }).catch(function (resp) {
        _this5.showReport = false;
        _this5.loading = false;
        if (resp.response) {
          if (resp.response.error !== undefined) {
            _this5.error = _this5.$trans(resp.response.error);
          }
        } else {
          _this5.error = 'Unhandled error: ' + resp.message;
        }
      });
    },
    handleMapNodes: function handleMapNodes(node, parent, questionnaireIds, nodeIds) {
      var _node$questionnaires,
        _this6 = this;
      // Root node will have empty parent and we don't want to show the root node
      var isNotRootNode = parent != null;
      var nodeQuestionnaireIds = (_node$questionnaires = node.questionnaires) === null || _node$questionnaires === void 0 ? void 0 : _node$questionnaires.map(function (q) {
        return q.questionnaireId;
      });
      var hasQuestionnaireId = questionnaireIds.some(function (qId) {
        return nodeQuestionnaireIds.includes(qId);
      });
      var isThisNodeFiltered = nodeIds.length > 0 && nodeIds.indexOf(node.id) === -1;
      var tags = mapToCommaList(filterAsset(this.tags, node.tagIds), 'tag');
      if (isNotRootNode && hasQuestionnaireId && !isThisNodeFiltered) {
        nodeQuestionnaireIds.forEach(function (qId) {
          var _this6$questionnaires;
          var row = {
            title: node.title,
            parent: node.parent,
            nodeId: node.id,
            objType: node.objType,
            url: node.url,
            compliance_projects_id: node.compliance_projects_id,
            status: node.status,
            traffic_light: node.traffic_light,
            values: node.values,
            purpose: node.purpose,
            description: node.description,
            questionnaireId: node.questionnaireId,
            questionnaire: (_this6$questionnaires = _this6.questionnaires.find(function (q) {
              return +q.id === +qId;
            })) === null || _this6$questionnaires === void 0 ? void 0 : _this6$questionnaires.title,
            cells: _this6.prepareCells(node.id, qId),
            dataFlowUrl: node.hasDataFlow ? getDataFlowImageUrl(_this6.projectId, node.id) : null
          };
          if (_this6.report.table === _this6.article30) {
            _this6.addSelectedUsersAndOrganisations(row, node);
            row.tags = tags;
          }
          var checkEntityPresence = _this6.entities.find(function (item) {
            return item.id == node.id;
          });
          if (checkEntityPresence) {
            _this6.rows.push(row);
          }
        });
      }
      if (node.children) {
        node.children.forEach(function (childNode) {
          if (isNotRootNode) {
            childNode.parent = node;
          }
          if (!isThisNodeFiltered) {
            nodeIds.push(childNode.id);
          }
          _this6.handleMapNodes(childNode, node, questionnaireIds, nodeIds);
        });
      }
    },
    addSelectedUsersAndOrganisations: function addSelectedUsersAndOrganisations(to, value) {
      to.responsibleUsers = this.getResponsible(value);
      to.accountableUsers = this.getAccountable(value);
      to.informedUsers = this.getInformed(value);
      to.consultedUsers = this.getConsulted(value);
      to.organisations = this.getOrgs(value);
      if (this.showControllersAndProcessors) {
        to.dataControllers = this.getDataControllers(value);
        var _this$getDataProcesso = this.getDataProcessorsAndLinkedCompanies(value);
        var _this$getDataProcesso2 = _slicedToArray(_this$getDataProcesso, 2);
        to.dataProcessors = _this$getDataProcesso2[0];
        to.companiesLinkedToDataProcessors = _this$getDataProcesso2[1];
      }
      if (this.includeRaciEmails) {
        to.responsibleEmails = this.getResponsible(value, 'email');
        to.accountableEmails = this.getAccountable(value, 'email');
        to.informedEmails = this.getInformed(value, 'email');
        to.consultedEmails = this.getConsulted(value, 'email');
      }
    },
    getResponsible: function getResponsible(value) {
      var propertyName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'display_name';
      if (!value.responsibleUserIds) return [];
      var filtered = this.users.filter(function (el) {
        return value.responsibleUserIds.includes(el.id);
      });
      return filtered.map(function (el) {
        return el[propertyName];
      });
    },
    getAccountable: function getAccountable(value) {
      var propertyName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'display_name';
      if (!value.accountableUserIds) return [];
      var filtered = this.users.filter(function (el) {
        return value.accountableUserIds.includes(el.id);
      });
      return filtered.map(function (el) {
        return el[propertyName];
      });
    },
    getConsulted: function getConsulted(value) {
      var propertyName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'display_name';
      if (!value.consultedUserIds) return [];
      var filtered = this.users.filter(function (el) {
        return value.consultedUserIds.includes(el.id);
      });
      return filtered.map(function (el) {
        return el[propertyName];
      });
    },
    getInformed: function getInformed(value) {
      var propertyName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'display_name';
      if (!value.informedUserIds) return [];
      var filtered = this.users.filter(function (el) {
        return value.informedUserIds.includes(el.id);
      });
      return filtered.map(function (el) {
        return el[propertyName];
      });
    },
    getOrgs: function getOrgs(value) {
      if (!value.organisationIds) return [];
      var filtered = this.organisations.filter(function (el) {
        return value.organisationIds.includes(el.id);
      });
      return filtered.map(function (el) {
        return el.visible_name;
      });
    },
    prepareCells: function prepareCells(nodeId, qId) {
      return mapQuestionAnswers(this.questions, this.informationAssets, this.answers[nodeId], this.files, this.processTreeNodes, qId, this.getCompanyLinkedToDataProcessor);
    },
    getDataControllers: function getDataControllers(value) {
      if (!value.links || !value.links[MODULES.COMPLIANCE_IA_CONTROLLER]) return [];
      return this.allDataController.filter(function (controller) {
        return value.links[MODULES.COMPLIANCE_IA_CONTROLLER].includes(controller.id);
      });
    },
    getDataProcessors: function getDataProcessors(value) {
      if (!value.links || !value.links[MODULES.COMPLIANCE_IA_PROCESSOR]) return [];
      return this.allDataProcessors.filter(function (processor) {
        return value.links[MODULES.COMPLIANCE_IA_PROCESSOR].includes(processor.id);
      });
    },
    getDataProcessorsAndLinkedCompanies: function getDataProcessorsAndLinkedCompanies(value) {
      var _this7 = this;
      if (!value.links || !value.links[MODULES.COMPLIANCE_IA_PROCESSOR]) return [[], []];
      var companies = [];
      var processors = this.allDataProcessors.filter(function (processor) {
        if (value.links[MODULES.COMPLIANCE_IA_PROCESSOR].includes(processor.id)) {
          if (processor.companyId) {
            companies.push(_this7.getCompanyLinkedToDataProcessor(processor));
            return false;
          }
          return true;
        }
        return false;
      });
      return [processors, companies];
    },
    getCompanyLinkedToDataProcessor: function getCompanyLinkedToDataProcessor(processor) {
      var companyId = processor.companyId;
      if (!this.companiesMap.has(companyId)) {
        var company = this.prepareCompanyData(this.allCompanies.find(function (company) {
          return company.id === companyId;
        }), processor);
        this.companiesMap.set(companyId, company);
      }
      return this.companiesMap.get(companyId);
    },
    prepareCompanyData: function prepareCompanyData(company, processor) {
      company = getCompanyWithUrl(company);
      return {
        name: company.name,
        id: company.id,
        objType: company.objType,
        address: company.address,
        city: company.city,
        countryCode: company.countryCode,
        phone: company.phone,
        url: company.url,
        vatStatus: company.vatStatus,
        contactName: processor.contactName,
        email: processor.email
      };
    },
    handleClearTable: function handleClearTable() {
      this.showReport = false;
      this.questions = [];
      this.error = '';
    },
    refreshTable: function refreshTable() {
      this.handleCreateReport(this.reportArgs);
    },
    updateFromContactCards: function updateFromContactCards(value) {
      this.updatedContactCard = value;
    },
    dismissContractCardModal: function dismissContractCardModal() {
      if (this.updatedContactCard) {
        var _this$updatedContactC, _this$updatedContactC2;
        if (((_this$updatedContactC = this.updatedContactCard) === null || _this$updatedContactC === void 0 ? void 0 : _this$updatedContactC.objType) === ObjectTypes.COMPANY) {
          this.updateCompany(this.updatedContactCard);
        }
        if (((_this$updatedContactC2 = this.updatedContactCard) === null || _this$updatedContactC2 === void 0 ? void 0 : _this$updatedContactC2.objType) === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR) {
          this.getProcessors();
        }
        this.refreshTable();
        this.updatedContactCard = null;
      }
    },
    updateCompany: function updateCompany(company) {
      var companyOld = this.companiesMap.get(company.id);
      var processor = {
        contactName: companyOld.contactName,
        email: companyOld.email
      };
      var companyUpdated = this.prepareCompanyData(company, processor);
      this.companiesMap.set(company.id, companyUpdated);
    }
  }
};