function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getAll as getActions, getActionsTree, create as createActionChild, updateSingleInitiative } from '@/api/execution/initiative';
import Tree from '@/components/Atoms/Tree';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Pagination from '@/components/Atoms/Pagination';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import ActionCreationModal from '@/components/Molecules/Modal/ActionCreationModal';
import PreviewListTile from '@/components/Molecules/PreviewList/PreviewListTile';
import PreviewListExpanded from '@/components/Molecules/PreviewList/PreviewListExpanded';
import CatalogueHeader from '@/components/Molecules/CatalogueHeader/CatalogueHeader';
import CompactCard from '@/components/Molecules/Cards/CompactCard';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import GanttChart from '@/components/Molecules/Charts/GanttChart';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { MODULES } from '@/constants/modules';
import { OVERALL_INITIATIVES_VIEWS } from '@/constants/ViewTypes';
import { InitiativesViews } from '@/constants/InitiativesViews';
import { UserLevels } from '@/constants/UserLevels';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { filterAsset } from '@/utils/format/objectsFromIds';
import { isLockedActionUser as _isLockedActionUser, isActionsLevelBelowSuperUser } from '@/utils/access';
import { CommonAssetsMixin } from '@/mixins/CommonAssetsMixin';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'ActionsList',
  description: 'This is Page component for /actions/actions',
  token: '<actions-list />',
  components: {
    CompactCard: CompactCard,
    Tree: Tree,
    PreviewListTile: PreviewListTile,
    RismaTitle: RismaTitle,
    CatalogueHeader: CatalogueHeader,
    RismaButton: RismaButton,
    GanttChart: GanttChart,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Pagination: Pagination,
    PreviewListExpanded: PreviewListExpanded,
    NotificationPopUp: NotificationPopUp,
    ActionCreationModal: ActionCreationModal
  },
  mixins: [CommonAssetsMixin, NotificationPopUpMixin],
  setup: function setup() {
    return {
      currentView: getLocalStorageComputed('actions_catalogue_view', OVERALL_INITIATIVES_VIEWS[0].name)
    };
  },
  data: function data() {
    return {
      MODULES: MODULES,
      OVERALL_INITIATIVES_VIEWS: OVERALL_INITIATIVES_VIEWS,
      InitiativesViews: InitiativesViews,
      overviewOptionsState: true,
      activities: [],
      treeId: 'initiatives_tree',
      treeTitle: '',
      isLoaded: false,
      treeData: {},
      newChild: {},
      showGanttLoader: true,
      itemsPerPage: 25,
      isActivitiesLoaded: false,
      paginationPage: {
        start: 0,
        end: 0
      },
      nodeId: null,
      showActionCreationModal: false,
      translate: {
        yourActivityHasBeenCreated: this.$trans('Your activity has been created'),
        overview: this.$trans('Overview'),
        createOverallAction: this.$trans('Create overall action')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserIsAdmin: 'isAdmin'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'executionActivityTypes'
  })), {}, {
    ganttStyles: function ganttStyles() {
      return {
        'minHeight': this.activitiesSlice.length * 28 + 'px'
      };
    },
    isLockedActionUser: function isLockedActionUser() {
      return _isLockedActionUser(this.currentUser);
    },
    showCreateBtn: function showCreateBtn() {
      return !this.isLockedActionUser && !isActionsLevelBelowSuperUser(this.currentUser);
    },
    activitiesSlice: function activitiesSlice() {
      return this.activities.slice(this.paginationPage.start, this.paginationPage.end + 1);
    },
    userHasLockAccess: function userHasLockAccess() {
      return !this.isLockedActionUser && this.currentUser.level_execution >= UserLevels.SUPER || this.currentUserIsAdmin;
    },
    compactDataset: function compactDataset() {
      var _this = this;
      return this.activities.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          dependingOn: DatatableRenderer.prepareLinks(filterAsset(_this.activities, item.dependingOn).map(function (item) {
            return {
              title: item.displayHeadline,
              link: item.url
            };
          }), _this)
        });
      });
    }
  }),
  mounted: function mounted() {
    var _this2 = this;
    var promise;
    if (this.$route.query.view) {
      this.currentView = this.$route.query.view;
    }
    if (this.currentView === this.InitiativesViews.TREE) {
      promise = this.handleGetActionsTree;
    } else {
      promise = this.handleGetActionsList;
    }
    promise().then(function () {
      return _this2.isLoaded = true;
    });
  },
  methods: {
    updateDisplayState: function updateDisplayState(state) {
      var _this3 = this;
      this.isLoaded = false;
      this.currentView = state;
      var promise;
      if (this.currentView === this.InitiativesViews.TREE) {
        promise = Object.keys(this.treeData).length ? Promise.resolve() : this.handleGetActionsTree();
      } else {
        promise = this.isActivitiesLoaded ? Promise.resolve() : this.handleGetActionsList();
      }
      promise.then(function () {
        return _this3.isLoaded = true;
      });
    },
    handleGetActionsList: function handleGetActionsList() {
      var _this4 = this;
      return getActions().then(function (_ref) {
        var list = _ref.list;
        _this4.activities = list;
        _this4.isActivitiesLoaded = true;
      });
    },
    handleGetActionsTree: function handleGetActionsTree() {
      var _this5 = this;
      return getActionsTree().then(function (response) {
        _this5.treeData = null;
        _this5.treeData = _this5.formatTree(response);
      });
    },
    formatTree: function formatTree(tree) {
      tree.children = this.formatTreeChildren(tree.children);
      return tree;
    },
    formatTreeChildren: function formatTreeChildren(children) {
      var _this6 = this;
      if (!children || !children.length) return [];
      children.map(function (child) {
        child = _this6.formatTreeChild(child);
        if (child.children && child.children.length) _this6.formatTreeChildren(child.children);
        return child;
      });
      return children;
    },
    formatTreeChild: function formatTreeChild(child) {
      child.checkaccess = true;
      child.traffic_light = child.trafficLight;
      child.objectType = child.objType;
      child.title = child.ipath + ' ' + child.title;
      child.ipath = child.ipath + '';
      return child;
    },
    addTreeChild: function addTreeChild(_ref2) {
      var _this7 = this;
      var title = _ref2.title,
        appendTo = _ref2.appendTo;
      createActionChild({
        title: title,
        parent: appendTo
      }).then(function (response) {
        _this7.appendChildToActivities(response, appendTo);
        _this7.newChild = _this7.formatTreeChild(response);
        _this7.appendChildToTreeData(_this7.newChild, appendTo);
        _this7.handleInstantNotification(response);
      });
    },
    appendChildToActivities: function appendChildToActivities(child, parentId) {
      if (!this.isActivitiesLoaded) return;
      this.activities.push(child);
      var parent = this.activities.find(function (activity) {
        return activity.id == parentId;
      });
      parent.children ? parent.children.push(child) : parent.children = [child];
    },
    appendChildToTreeData: function appendChildToTreeData(child, parentId) {
      var parent = this.findItemById(this.treeData.children, parentId);
      if (!parent) return;
      parent.children.push(_objectSpread(_objectSpread({}, child), {}, {
        children: []
      }));
    },
    findItemById: function findItemById(children, id) {
      var childrenOfChildren = [];
      var childrenLength = children.length;
      for (var i = 0; i < childrenLength; i++) {
        if (children[i].id == id) return children[i];
        if (children[i].children) childrenOfChildren = [].concat(_toConsumableArray(childrenOfChildren), _toConsumableArray(children[i].children));
      }
      if (!childrenOfChildren.length) return;
      return this.findItemById(childrenOfChildren, id);
    },
    handleCreateAction: function handleCreateAction(newItem) {
      var nodeId = this.nodeId;
      this.toggleActionCreationModal();
      this.activities.push(newItem);
      this.newChild = this.formatTreeChild(_objectSpread(_objectSpread({}, newItem), {}, {
        newOverallAction: !nodeId,
        underlyingId: nodeId
      }));
      if (nodeId) {
        if (this.treeData.children) this.addActionToTheTreeByNodeId(this.newChild, nodeId, this.treeData.children);
      } else {
        if (this.treeData.children) this.treeData.children.push(this.newChild);
      }
      this.handleInstantNotification(newItem);
    },
    handleShownList: function handleShownList(number) {
      this.paginationPage = number;
    },
    deleteAction: function deleteAction(action) {
      var actionAndChildrenIds = this.getActionAndChildrenIds(action.id);
      this.activities = this.activities.filter(function (activity) {
        return !actionAndChildrenIds.includes(+activity.id);
      });
      if (this.treeData.children) {
        this.treeData.children = this.removeActionFromTree(this.treeData.children, action.id);
      }
    },
    deleteActionFromTree: function deleteActionFromTree(id) {
      this.treeData.children = this.removeActionFromTree(this.treeData.children, id);
      if (this.activities.length) {
        var actionAndChildrenIds = this.getActionAndChildrenIds(id);
        this.activities = this.activities.filter(function (activity) {
          return !actionAndChildrenIds.includes(+activity.id);
        });
      }
    },
    addActionToTheTreeByNodeId: function addActionToTheTreeByNodeId(action, nodeId) {
      var _this8 = this;
      var children = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      children.forEach(function (item) {
        if (item.id === nodeId) {
          if (item.children && item.children.length) {
            item.children.push(action);
          } else {
            item.children = [action];
          }
        }
        if (item.children && item.children.length) {
          _this8.addActionToTheTreeByNodeId(action, nodeId, item.children);
        }
      });
    },
    removeActionFromTree: function removeActionFromTree(children, id) {
      var _this9 = this;
      children.forEach(function (child) {
        if (child.children) child.children = _this9.removeActionFromTree(child.children, id);
      });
      return children.filter(function (child) {
        return child.id != id;
      });
    },
    getActionAndChildrenIds: function getActionAndChildrenIds(id) {
      var parent = this.activities.find(function (action) {
        return action.id == id;
      });
      return this.getAllIds(parent);
    },
    getAllIds: function getAllIds(parent) {
      var _this10 = this;
      var result = [+parent.id];
      if (parent.children && parent.children.length) {
        parent.children.forEach(function (child) {
          result = [].concat(_toConsumableArray(result), _toConsumableArray(_this10.getAllIds(_this10.activities.find(function (a) {
            return a.id == child.id;
          }))));
        });
      }
      return result;
    },
    tryMove: function tryMove(params) {
      var _this11 = this;
      return updateSingleInitiative(params.child, params.options).then(function (response) {
        if (response.id) {
          _this11.$refs.tree && _this11.$refs.tree.reload();
        }
      });
    },
    reloadData: function reloadData() {
      var _this12 = this;
      Promise.resolve(this.handleGetActionsTree()).then(function () {
        return _this12.$refs.tree && _this12.$refs.tree.init();
      });
    },
    updateFromPreviewMode: function updateFromPreviewMode(updatedAction) {
      var updatedActionIndex = this.activities.findIndex(function (a) {
        return a.id == updatedAction.id;
      });
      if (updatedActionIndex === -1) return;
      this.activities[updatedActionIndex] = updatedAction;
    },
    toggleActionCreationModal: function toggleActionCreationModal(nodeId) {
      this.nodeId = typeof nodeId === 'number' || typeof nodeId === 'string' ? nodeId : null;
      this.showActionCreationModal = !this.showActionCreationModal;
    }
  }
};