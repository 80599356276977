import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "flex-col my-auto"
};
var _hoisted_2 = {
  class: "pr-2"
};
var _hoisted_3 = {
  class: "flex-grow overflow-hidden"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_router_link = _resolveComponent("router-link");
  var _component_info_box = _resolveComponent("info-box");
  return _openBlock(), _createBlock(_component_info_box, {
    title: $props.title,
    class: "bg-blue-30 flex flex-col flex-grow"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.links, function (link) {
        return _openBlock(), _createElementBlock("div", {
          key: link.title,
          class: "flex items-center py-2"
        }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_feather_icon, {
          icon: "git-commit",
          class: "stroke-2 transform rotate-90"
        })]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
          title: link.title,
          type: "medium",
          class: "text-blue-750"
        }, null, 8 /* PROPS */, ["title"]), link.subtitle ? (_openBlock(), _createBlock(_component_risma_title, {
          key: 0,
          title: link.subtitle,
          type: "small",
          class: "text-blue-750"
        }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true)]), _createVNode(_component_router_link, {
          to: link.url,
          class: "border border-gray-350 rounded-full mr-1 hover:border-blue-750"
        }, {
          default: _withCtx(function () {
            return [_createVNode(_component_feather_icon, {
              icon: "arrow-right",
              class: "stroke-2"
            })];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])]);
      }), 128 /* KEYED_FRAGMENT */))])];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]);
}