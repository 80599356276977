function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { addCompany } from '@/api/admin/companies';
import { getCVRCompany } from '@/api/external/cvrapi';
import { getAllProcessors, create as createInformationAsset } from '@/api/compliance/informationAssets';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import RismaSplitButton from '@/components/Atoms/Buttons/RismaSplitButton';
import Modal from '@/components/Molecules/Modal/Modal';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import StaticTable from '@/components/Molecules/StaticTable';
import AddContractPartyModal from '@/components/Organisms/Contracts/AddContractPartyModal';
import { VatCountryCode } from '@/constants/admin/VatCountryCode';
import { getCVRQueryParam } from '@/utils/format/companies';
import { CreateCompanyModes } from '@/constants/companies/companiesColumns';
export default {
  name: 'CreateCompany',
  components: {
    InputField: InputField,
    Notification: Notification,
    RismaSplitButton: RismaSplitButton,
    Modal: Modal,
    SingleSelect: SingleSelect,
    StaticTable: StaticTable,
    AddContractPartyModal: AddContractPartyModal
  },
  props: {
    allCompanies: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Needed for external button'
    },
    linkedCompanies: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    creationMode: {
      type: String,
      required: false,
      default: 'create',
      validator: function validator(type) {
        return type === 'create' || type === 'add';
      }
    }
  },
  emits: ['createCompany', 'showNotification', 'addInternalCompany'],
  data: function data() {
    return {
      showCreateViaVatModal: false,
      showManuallyCreateCompanyModal: false,
      showAddViaVatModule: false,
      companyName: '',
      countryCode: VatCountryCode.DK.value,
      companySearchFieldValue: '',
      companyDataset: [],
      selectedCompanyIndex: null,
      checkedCreateAsDataProcessorIndex: null,
      createCompanyNotification: {
        message: '',
        type: null
      },
      createCompanyTimer: 0,
      foundCompanies: null,
      dataProcessors: [],
      isCompanyNameValid: true,
      translate: getTranslate['CreateCompany']()
    };
  },
  computed: {
    createAsDataProcessorSelected: function createAsDataProcessorSelected() {
      return this.checkedCreateAsDataProcessorIndex !== null && this.checkedCreateAsDataProcessorIndex === this.selectedCompanyIndex;
    },
    isCountryCodeSE: function isCountryCodeSE() {
      return this.countryCode === VatCountryCode.SE.value;
    },
    searchFieldLabel: function searchFieldLabel() {
      return this.isCountryCodeSE ? this.translate.searchingByNameIsNotAvailableForSeCompani : "".concat(this.translate.vatNumber, " ").concat(this.translate.orCompanyName);
    },
    selectedCompanyToCreate: function selectedCompanyToCreate() {
      if (!this.foundCompanies) return {};
      var company = this.foundCompanies[this.selectedCompanyIndex];
      var countryCode = company.countryCode.toUpperCase();
      var vatNo = "".concat(company.vatNo);
      return _objectSpread(_objectSpread({}, company), {}, {
        countryCode: countryCode,
        vatNo: vatNo
      });
    },
    countryCodeOptions: function countryCodeOptions() {
      return Object.values(VatCountryCode).map(function (_ref) {
        var value = _ref.value;
        return {
          value: value,
          label: value
        };
      });
    },
    companyColumns: function companyColumns() {
      var _this = this;
      var columns = ['Choose', 'Name', 'Address', 'City', 'VAT number', 'Also create data processor'];
      return columns.map(function (column) {
        return _this.$trans(column);
      });
    },
    selectedDataProcessorExists: function selectedDataProcessorExists() {
      var _this2 = this;
      var processorExists = this.dataProcessors.find(function (processor) {
        return processor.vatNo === _this2.selectedCompanyToCreate.vatNo || processor.name === _this2.selectedCompanyToCreate.name;
      });
      return this.createAsDataProcessorSelected && processorExists;
    },
    isCreateMode: function isCreateMode() {
      return this.creationMode === 'create';
    },
    content: function content() {
      return CreateCompanyModes(this.$trans)[this.creationMode];
    }
  },
  mounted: function mounted() {
    this.getProcessors();
  },
  methods: {
    getProcessors: function getProcessors() {
      var _this3 = this;
      return getAllProcessors().then(function (_ref2) {
        var list = _ref2.list;
        return _this3.dataProcessors = _this3.getDataProcessorsFlat(list);
      });
    },
    getDataProcessorsFlat: function getDataProcessorsFlat(processors) {
      return processors.reduce(function (list, processor) {
        var _processor$childrenOb;
        list.push(processor);
        if ((_processor$childrenOb = processor.childrenObjects) !== null && _processor$childrenOb !== void 0 && _processor$childrenOb.length) list.push.apply(list, _toConsumableArray(processor.childrenObjects));
        return list;
      }, []);
    },
    resetCreateAsDataProcessorIndex: function resetCreateAsDataProcessorIndex(value) {
      if (value === this.selectedCompanyIndex) return;
      this.checkedCreateAsDataProcessorIndex = null;
    },
    openCreateViaVatModal: function openCreateViaVatModal() {
      if (this.isCreateMode) {
        this.showCreateViaVatModal = true;
        return;
      }
      this.showAddViaVatModule = true;
    },
    openManuallyCreateCompanyModal: function openManuallyCreateCompanyModal() {
      this.showManuallyCreateCompanyModal = true;
    },
    hideCreateViaVatModal: function hideCreateViaVatModal() {
      this.showCreateViaVatModal = false;
      this.resetCreateViaVatModalData();
    },
    hideManuallyCreateCompanyModal: function hideManuallyCreateCompanyModal() {
      this.showManuallyCreateCompanyModal = false;
      this.companyName = '';
      this.isCompanyNameValid = true;
    },
    manuallyCreateCompany: function manuallyCreateCompany() {
      var _this4 = this;
      this.isCompanyNameValid = !!this.companyName;
      if (!this.isCompanyNameValid) return;
      var data = {
        name: this.companyName
      };
      this.createCompany(data).then(function (company) {
        if (_this4.isCreateMode) {
          company && _this4.$router.push({
            name: 'CompanyDescription',
            params: {
              id: company.id
            }
          });
        } else {
          _this4.hideManuallyCreateCompanyModal();
          _this4.$emit('createCompany', company, true);
        }
      });
    },
    createCompany: function createCompany(data) {
      var _this5 = this;
      return addCompany(data).catch(function (_ref3) {
        var response = _ref3.response;
        _this5.$emit('showNotification', _this5.$trans(response === null || response === void 0 ? void 0 : response.error));
      });
    },
    resetCreateViaVatModalData: function resetCreateViaVatModalData() {
      this.countryCode = VatCountryCode.DK.value;
      this.companySearchFieldValue = '';
      this.resetSearchResults();
    },
    resetSearchResults: function resetSearchResults() {
      this.companyDataset = [];
      this.selectedCompanyIndex = null;
      this.checkedCreateAsDataProcessorIndex = null;
    },
    createCompanyViaVat: function createCompanyViaVat() {
      var _this6 = this;
      if (this.selectedDataProcessorExists) {
        this.showCreateCompanyNotification(this.translate.dataProcessorWithTheSameVatNumberOrNameAl);
        return;
      }
      this.createCompany(this.selectedCompanyToCreate).then(function (companyData) {
        if (!companyData) return;
        _this6.$emit('createCompany', companyData);
        if (_this6.createAsDataProcessorSelected) {
          _this6.createDataProcessor(companyData);
        }
      }).finally(function () {
        return _this6.hideCreateViaVatModal();
      });
    },
    showCreateCompanyNotification: function showCreateCompanyNotification(message) {
      var _this7 = this;
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      this.createCompanyNotification.message = message;
      this.createCompanyNotification.type = type;
      clearTimeout(this.createCompanyTimer);
      this.timer = setTimeout(function () {
        _this7.createCompanyNotification.message = '';
      }, this.$debounceTimeout);
    },
    searchCompany: function searchCompany() {
      var _this8 = this;
      if (!this.companySearchFieldValue) return;
      this.resetSearchResults();
      var params = getCVRQueryParam(this.countryCode);
      getCVRCompany(this.companySearchFieldValue, params).then(function (_ref4) {
        var list = _ref4.list;
        _this8.foundCompanies = list;
        _this8.companyDataset = _this8.createCompanyDataset();
      }).catch(function (error) {
        if (error.status === 404) {
          _this8.showCreateCompanyNotification(_this8.translate.noCompaniesFound);
        } else {
          _this8.showCreateCompanyNotification(_this8.translate.anErrorOccurred);
        }
      });
    },
    createCompanyDataset: function createCompanyDataset() {
      return this.foundCompanies.map(function (item, index) {
        return {
          selected: index,
          name: item.name,
          address: item.address,
          city: item.city,
          vatNumber: item.vatNo,
          dataProcessor: index
        };
      });
    },
    toggleCheckedAsDataProcessor: function toggleCheckedAsDataProcessor(index, value) {
      if (value) {
        this.checkedCreateAsDataProcessorIndex = index;
        return;
      }
      this.checkedCreateAsDataProcessorIndex = null;
    },
    createDataProcessor: function createDataProcessor(_ref5) {
      var _this9 = this;
      var name = _ref5.name,
        vatNo = _ref5.vatNo,
        id = _ref5.id;
      var data = {
        name: name,
        vatNo: vatNo,
        companyId: id
      };
      return createInformationAsset('processors', data).then(function (data) {
        return _this9.dataProcessors.push(data);
      });
    },
    onAddContractParty: function onAddContractParty(company) {
      this.showAddViaVatModule = false;
      this.$emit('createCompany', company);
    },
    addInternalCompany: function addInternalCompany(companyId) {
      this.showAddViaVatModule = false;
      this.$emit('addInternalCompany', companyId);
    }
  }
};