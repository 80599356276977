export default {
  name: 'FieldSet',
  introduction: 'Fieldset',
  description: 'Wrapper for list of form elements',
  token: '<fieldset title="title">{slot}</fieldset>',
  props: {
    title: {
      type: String,
      required: true,
      note: 'Untranslated title of fieldset'
    }
  }
};