import { UserLevels } from '@/constants/UserLevels';
import { MenuKeys } from '@/constants/menu/MenuKeys';
function buildControlsMenu(user, settings) {
  var menuStructure = {
    main: [],
    admin: []
  };
  menuStructure.main.push({
    url: '/controls2/controls',
    title: 'Overview',
    rights: true,
    // Add access check
    menuKey: MenuKeys.controls
  });
  menuStructure.main.push({
    url: '/controls2/my',
    title: 'My controls',
    rights: true,
    // Add access check
    menuKey: MenuKeys.myControls,
    checkActiveSubItem: function checkActiveSubItem(url) {
      return url.startsWith('/controls2/my');
    }
  });
  menuStructure.main.push({
    url: '/controls2/reports',
    title: 'Reports',
    rights: true,
    // Add access check
    menuKey: MenuKeys.reports
  });
  var hasControlDashboardAccess = user.level_controls > UserLevels.NORMAL;
  var annualOverviewUrl = '/controls2/dashboards/annual-overview';
  var statusOverviewUrl = settings['feature.module_control2_dashboard_enabled'] ? '/controls2/dashboards/overview' : '/controls/dashboards/status-overview';
  menuStructure.main.push({
    url: statusOverviewUrl,
    title: 'Dashboards',
    rights: hasControlDashboardAccess,
    menuKey: MenuKeys.dashboards,
    children: [{
      url: statusOverviewUrl,
      title: 'Dashboard',
      exact: true,
      rights: hasControlDashboardAccess,
      menuKey: MenuKeys.statusOverview
    }, {
      url: annualOverviewUrl,
      title: 'Annual overview',
      exact: true,
      rights: hasControlDashboardAccess,
      menuKey: MenuKeys.annualOverview
    }]
  });
  return menuStructure;
}
export default buildControlsMenu;