function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import PreviewListExpanded from '@/components/Molecules/PreviewList/PreviewListExpanded';
import PreviewListTile from '@/components/Molecules/PreviewList/PreviewListTile';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import CompactCard from '@/components/Molecules/Cards/CompactCard';
import { ControlMyKeys } from '@/constants/ControlMyKeys';
import { viewTypes } from '@/constants/ListViewType';
import { ControlButtonTypes } from '@/constants/ControlButtonTypes';
import { MODULES } from '@/constants/modules';
var MODULE_NAME = MODULES.CONTROLS;
export default {
  name: 'ControlsListPreview',
  components: {
    PreviewListExpanded: PreviewListExpanded,
    PreviewListTile: PreviewListTile,
    PreviewList: PreviewList,
    CompactCard: CompactCard
  },
  props: {
    activities: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    filteredOrganisations: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    groupLabel: {
      type: String,
      required: true,
      default: ''
    },
    cookieHiddenKey: {
      type: String,
      required: true,
      default: ''
    },
    additionalFields: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    viewType: {
      type: String,
      required: false,
      default: viewTypes.TILES,
      validator: function validator(type) {
        return [viewTypes.TILES, viewTypes.EXPANDED, viewTypes.COMPACT, viewTypes.RISMA_TABLE].includes(type);
      }
    },
    isMotherControls: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['reloadRootPage', 'updateFromPreviewMode', 'dismissModal', 'onCompleteControl'],
  data: function data() {
    return {
      ControlButtonTypes: ControlButtonTypes,
      viewTypes: viewTypes,
      MODULE_NAME: MODULE_NAME,
      ControlMyKeys: ControlMyKeys
    };
  },
  computed: _objectSpread({}, mapState(useUsersStore, ['users'])),
  methods: {
    onUpdateFromPreviewMode: function onUpdateFromPreviewMode($event) {
      this.$emit('updateFromPreviewMode', $event);
    },
    onCompleteControl: function onCompleteControl($event) {
      this.$emit('onCompleteControl', $event);
    }
  }
};