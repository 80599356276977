import { getTranslate } from './translate';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { getCurrentActivities } from '@/constants/mypage/MypageKeys';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { getDateDurationWithToday } from '@/utils/date';
import { Colors } from '@/Colors';
import * as Utils from '@/utils/Utils';
import { TileCardMixin } from '@/mixins/TileCardMixin';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
export default {
  name: 'ActivityFeedCard',
  introduction: 'ActivityFeedCard is used in Mypage /mypage',
  description: 'ActivityFeedCard is used to display current activity',
  token: '<activity-feed-card :activity="{}" :projects="[]" />',
  components: {
    PreviewModalLink: PreviewModalLink,
    Trafficlight: Trafficlight,
    RismaTitle: RismaTitle
  },
  mixins: [TileCardMixin],
  props: {
    activity: {
      type: Object,
      required: true,
      note: 'Activity objects - must all be same type'
    },
    projects: {
      type: Array,
      required: true,
      note: 'List of all projects (compliance and risks)'
    }
  },
  emits: ['updateFromPreviewMode', 'showCompletionModal', 'showReviewModal'],
  data: function data() {
    return {
      Colors: Colors,
      CurrentActivities: getCurrentActivities(),
      translate: getTranslate['ActivityFeedCard']()
    };
  },
  computed: {
    moduleTitle: function moduleTitle() {
      var _ref,
        _this$activity$compli,
        _this = this;
      var projectId = (_ref = (_this$activity$compli = this.activity.compliance_projects_id) !== null && _this$activity$compli !== void 0 ? _this$activity$compli : this.activity.projectId) !== null && _ref !== void 0 ? _ref : null;
      var project = projectId && this.projects.find(function (project) {
        var projectType = project.objType.split('-')[0];
        var activityType = _this.activity.objType.split('-')[0];
        return +project.id === +projectId && projectType === activityType;
      });
      return "".concat(this.CurrentActivities[this.activity.objType].title).concat(project ? '-' + project.title : '');
    },
    iconPath: function iconPath() {
      return this.CurrentActivities[this.activity.objType] ? this.CurrentActivities[this.activity.objType].icon : null;
    },
    title: function title() {
      return Utils.truncate(this.activity.title, 50);
    },
    description: function description() {
      return Utils.truncateHtml(this.activity.description, 70, {
        html: true,
        maxLines: 2
      });
    },
    daysToDeadline: function daysToDeadline() {
      if (!this.activity.deadline) return;
      var _getDateDurationWithT = getDateDurationWithToday(this.activity.deadline),
        value = _getDateDurationWithT.value,
        type = _getDateDurationWithT.type;
      if (value > 0) {
        return "".concat(this.translate.deadlineWas, " ").concat(value, " ").concat(this.$trans(type), " ").concat(this.translate.ago);
      } else {
        return "".concat(Math.abs(value), " ").concat(this.$trans(type), " ").concat(this.translate.toDeadline);
      }
    },
    controlCanBeCompleted: function controlCanBeCompleted() {
      return this.activity.objType === ObjectTypes.CONTROLS_CONTROL && (this.activity.rismaState === RismaControlsUtils.states.STATE_PENDING || this.activity.rismaState === RismaControlsUtils.states.STATE_PARTIALLY_REVIEW_REJECTED);
    },
    controlCanBeReviewed: function controlCanBeReviewed() {
      return this.activity.objType === ObjectTypes.CONTROLS_CONTROL && (this.activity.rismaState === RismaControlsUtils.states.STATE_READY_FOR_REVIEW || this.activity.rismaState === RismaControlsUtils.states.STATE_PARTIALLY_READY_FOR_REVIEW);
    },
    approvedStatus: function approvedStatus() {
      if (this.activity.objType !== ObjectTypes.RISK_RISK_LIMITED) return null;
      return this.activity.approved ? this.translate.approved : this.translate.notApproved;
    },
    isContract: function isContract() {
      return this.activity.objType === ObjectTypes.CONTRACT_LIMITED || this.activity.objType === ObjectTypes.CONTRACT;
    }
  },
  methods: {
    showCompletionModal: function showCompletionModal() {
      this.$emit('showCompletionModal', this.activity);
    },
    showReviewModal: function showReviewModal() {
      this.$emit('showReviewModal', this.activity);
    }
  }
};