import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import PreviewIncident from '@/components/Atoms/Preview/PreviewIncident';
import StaticTable from '@/components/Molecules/StaticTable';
import LoadMoreWrapper from '@/components/Molecules/LoadMoreWrapper';
import CardIncidents from '@/components/Organisms/General/Cards/CardIncidents';
import { viewTypes as IncidentViews } from '@/constants/ListViewType';
import { IncidentsVisibleProps } from '@/constants/IncidentsVisibleProps';
import { Colors } from '@/Colors';
import { getIncidentStateName } from '@/utils/incident';
import * as Utils from '@/utils/Utils';
import * as formatDate from '@/utils/date';
export default {
  components: {
    PreviewList: PreviewList,
    PreviewIncident: PreviewIncident,
    StaticTable: StaticTable,
    CardIncidents: CardIncidents,
    LoadMoreWrapper: LoadMoreWrapper
  },
  props: {
    incidents: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    viewMode: {
      type: String,
      required: false,
      default: 'compact'
    },
    users: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['changeShowState'],
  data: function data() {
    return {
      incidentViews: IncidentViews,
      incidentsVisibleProps: IncidentsVisibleProps,
      translate: {
        myIncidents: this.$trans('My incidents'),
        notSet: this.$trans('Not set')
      }
    };
  },
  computed: {
    dataset: function dataset() {
      var _this = this;
      var result = [];
      result = this.incidents.map(function (incident) {
        var preparedItem = {};
        _this.incidentsVisibleProps.forEach(function (column) {
          preparedItem[column.key] = _this.prepareCompactHtml(column.key, incident);
        });
        return preparedItem;
      });
      return result;
    },
    columns: function columns() {
      var _this2 = this;
      return this.incidentsVisibleProps.map(function (item) {
        return _this2.$trans(item.text);
      });
    }
  },
  methods: {
    prepareCompactHtml: function prepareCompactHtml(column, incident) {
      var result = '';
      switch (column) {
        case 'severity':
          result = this.prepareSeverityComponent(incident[column]);
          break;
        case 'title':
          result = this.prepareTitle(incident);
          break;
        case 'rismaState':
          result = this.$trans(getIncidentStateName(incident.rismaState).name);
          break;
        case 'incidentHappenedAt':
          result = formatDate.toLocalDateTime(incident[column]) || this.translate.notSet;
          break;
        case 'responsibleUserIds':
        case 'accountableUserIds':
          result = Utils.findPropsByIds(incident[column], this.users, 'display_name');
          break;
        default:
          result = incident[column] || '';
      }
      return result;
    },
    prepareSeverityComponent: function prepareSeverityComponent(data) {
      return {
        color: Colors.findIncidentSeverity(data).color
      };
    },
    prepareTitle: function prepareTitle(incident) {
      return {
        url: "/incident/".concat(incident.id, "/description"),
        title: incident.title
      };
    }
  }
};