import StaticTable from '@/components/Molecules/StaticTable';
export default {
  name: 'ComplianceScoreTable',
  description: 'this component uses in /compliance/informationassets/processors/:id/details',
  token: '<compliance-score-table />',
  components: {
    StaticTable: StaticTable
  },
  props: {
    nodes: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      columns: [{
        key: 'title',
        text: 'Title'
      }, {
        key: 'totalScore',
        text: 'Score'
      }, {
        key: 'assessmentText',
        text: 'Assessment text'
      }]
    };
  },
  computed: {
    columnsTrans: function columnsTrans() {
      var _this = this;
      return this.columns.map(function (item) {
        return _this.$trans(item.text);
      });
    },
    dataset: function dataset() {
      var _this2 = this;
      return this.nodes.map(function (node) {
        var preparedItem = {};
        _this2.columns.forEach(function (_ref) {
          var key = _ref.key;
          preparedItem[key] = _this2.prepareCompactHtml(key, node);
        });
        return preparedItem;
      });
    }
  },
  methods: {
    prepareCompactHtml: function prepareCompactHtml(column, node) {
      var result = '';
      switch (column) {
        case 'title':
          return result = this.prepareTitle(node);
        case 'totalScore':
          if (!node.questionnaireScore) return '';
          return result = typeof node.questionnaireScore[column] === 'number' ? node.questionnaireScore[column] : '';
        case 'assessmentText':
          if (!node.questionnaireScore) return '';
          return result = node.questionnaireScore.helpText[column] || '';
        default:
          result = '';
      }
      return result;
    },
    prepareTitle: function prepareTitle(node) {
      return {
        url: node.url,
        title: node.title
      };
    }
  }
};