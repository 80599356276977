import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getTreeByProjectId(processTreeId) {
  return handleRequestResponse(Http.get("/compliance/processtree/".concat(processTreeId)));
}
export function getMyTreeByProjectId(processTreeId) {
  return handleRequestResponse(Http.get("/compliance/processtree/".concat(processTreeId, "/my")));
}
export function getAllTrees() {
  return handleRequestResponse(Http.get('/compliance/processtree'));
}
export function getNodesByProcessTreeId(processTreeId) {
  return handleRequestResponse(Http.get("/compliance/processtree/".concat(processTreeId, "/nodes")));
}
export function getReportById(processTreeId) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var url = "/compliance/processtree/".concat(processTreeId, "/reports?data[]=all").concat(params);
  return handleRequestResponse(Http.get(url));
}
export function getNodeById(processTreeId, nodeId) {
  return handleRequestResponse(Http.get("/compliance/processtree/".concat(processTreeId, "/nodes/").concat(nodeId)));
}
export function updateNodeById(processTreeId, nodeId, params) {
  return handleRequestResponse(Http.patch("/compliance/processtree/".concat(processTreeId, "/nodes/").concat(nodeId), params));
}
export function addChildToNode(processTreeId, params) {
  return handleRequestResponse(Http.post("/compliance/processtree/".concat(processTreeId, "/nodes"), params));
}
export function deleteNodeById(processTreeId, nodeId) {
  return handleRequestResponse(Http.delete("/compliance/processtree/".concat(processTreeId, "/nodes/").concat(nodeId)));
}