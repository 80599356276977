import RismaOrgSelector from '@/components/Molecules/OrganisationSelector';
export default {
  name: '',
  components: {
    RismaOrgSelector: RismaOrgSelector
  },
  data: function data() {
    return {
      rismaOrgSelectorUsers: [{
        groups: null,
        id: 177,
        level1: 'Finance and administration',
        level2: 'Accounting',
        level3: null,
        objType: 'organisation',
        visible_name: 'Accounting'
      }, {
        groups: null,
        id: 172,
        level1: 'CSR Team',
        level2: null,
        level3: null,
        objType: 'organisation',
        visible_name: 'CSR Team'
      }],
      rismaOrgSelectorSelectedOptions: [{
        groups: null,
        id: 172,
        level1: 'CSR Team',
        level2: null,
        level3: null,
        objType: 'organisation',
        visible_name: 'CSR Team'
      }]
    };
  }
};