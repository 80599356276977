function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { Colors } from '@/Colors';
import * as Utils from '@/utils/Utils';
import PreviewRiskViewMixin from './PreviewRiskViewMixin';
import { RiskDegreeTitleMixin } from '@/mixins/RiskDegreeTitleMixin';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewRiskCustomFields from '@/components/Atoms/Preview/PreviewRiskCustomFields';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { getProbabilityLabel, getConsequenceLabel } from '@/utils/risks/riskPeriod';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import { MODULES } from '@/constants/modules';
export default {
  name: 'PreviewRiskExpanded',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    PreviewRiskCustomFields: PreviewRiskCustomFields,
    PreviewModalLink: PreviewModalLink,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  mixins: [PreviewRiskViewMixin, RiskDegreeTitleMixin],
  props: {
    risk: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    scoring: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    period: {
      type: Object,
      default: null,
      note: 'Project period data'
    },
    isRisk2: {
      type: Boolean,
      default: false
    },
    isShowLinkedItems: {
      type: Boolean,
      default: false
    }
  },
  emits: ['updateFromPreviewMode', 'deleteItem'],
  data: function data() {
    return {
      MODULES: MODULES,
      isRiskExpanded: false,
      Colors: Colors,
      cellCssStyles: 'p-2 border border-gray-610 w-1/3 text-left',
      updatedUnderlyingRisks: null,
      translate: getTranslate['PreviewRiskExpanded']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    areRisk2ParametersSet: function areRisk2ParametersSet() {
      return this.risk._threatValueAsString || this.risk._threatLevelValueAsString || this.risk._vulnerabilityValueAsString;
    },
    notSet: function notSet() {
      return this.translate.notSet;
    },
    hoverTitle: function hoverTitle() {
      return DatatableRenderer.getItemHoverTitle(this.risk);
    },
    foundCustomFields: function foundCustomFields() {
      var _this = this;
      if (!this.risk.customFieldValues) {
        return [];
      }
      return this.risk.customFieldValues.filter(function (field) {
        return _this.scoring.fields.find(function (item) {
          return item.id === field.fieldId && item.module !== MODULES.SCORING_ZAXIS && item.fieldWeight === -1 && _this.isRightField(field, _this.scoring);
        });
      });
    },
    riskImpactScore: function riskImpactScore() {
      var impactScore = this.risk[RiskBaseModelProps.IMPACT_SCORE] - 1;
      if (this.scoring.customImpactAxis > 0) {
        var values = Object.values(this.scoring[RiskBaseModelProps.CUSTOM_IMPACT].options);
        return "".concat(impactScore + 1, " (").concat(impactScore === -1 ? this.notSet : values[impactScore].value, ")");
      }
      return impactScore + 1;
    },
    riskLikelihoodScore: function riskLikelihoodScore() {
      var likeLihoodScore = this.risk[RiskBaseModelProps.LIKELIHOOD_SCORE] - 1;
      if (this.scoring.customLikelihoodAxis > 0) {
        var values = Object.values(this.scoring[RiskBaseModelProps.CUSTOM_LIKELIHOOD].options);
        return "".concat(likeLihoodScore + 1, " (").concat(likeLihoodScore === -1 ? this.notSet : values[likeLihoodScore].value, ")");
      }
      return likeLihoodScore + 1;
    },
    titleToggleLink: function titleToggleLink() {
      return this.isRiskExpanded ? this.translate.hideLinkedItems : this.translate.showLinkedItems;
    },
    toggleIcon: function toggleIcon() {
      return this.isRiskExpanded ? 'chevron-up' : 'chevron-down';
    },
    titleLinkedExecutions: function titleLinkedExecutions() {
      return this.isShowLinkedItems ? 'Mitigation plans' : 'Linked initiatives';
    },
    scoringLikelihoodDescription: function scoringLikelihoodDescription() {
      if (!this.scoring.likelihood_labels[this.risk.likelihood_score - 1]) {
        throw new Error("Could not find likelihood label option for ".concat(this.risk.title));
      }
      return this.scoring.likelihood_labels[this.risk.likelihood_score - 1].description;
    },
    consequenceLabel: function consequenceLabel() {
      return getConsequenceLabel(this.period);
    },
    probabilityLabel: function probabilityLabel() {
      return getProbabilityLabel(this.period);
    }
  }),
  methods: {
    onLinkedItemUpdate: function onLinkedItemUpdate(_ref) {
      var _propsToModules;
      var module = _ref.module,
        item = _ref.item;
      var propsToModules = (_propsToModules = {}, _defineProperty(_propsToModules, MODULES.CONTROLS, '_linkedControls'), _defineProperty(_propsToModules, MODULES.EXECUTION, '_linkedInitiatives'), _propsToModules);
      if (!propsToModules[module] || !this.risk[propsToModules[module]]) return;
      var items = JSON.parse(JSON.stringify(this.risk[propsToModules[module]]));
      var idx = items.findIndex(function (i) {
        return i.id === item.id;
      });
      if (idx !== -1) {
        items[idx] = item;
        this.$emit('updateFromPreviewMode', _objectSpread(_objectSpread({}, this.risk), {}, _defineProperty({}, propsToModules[module], items)));
      }
    },
    isRightField: function isRightField(field, scoring) {
      return field.fieldId !== scoring.customImpactAxis && field.fieldId !== scoring.customLikelihoodAxis;
    },
    truncateHtml: function truncateHtml(text, maxSymbols) {
      return Utils.truncateHtml(text, maxSymbols, {
        html: true,
        maxLines: 3
      });
    },
    prepareTrafficColor: function prepareTrafficColor(number) {
      return Colors.getTrafficlightByNumber(number).color;
    },
    getAxisRows: function getAxisRows(data) {
      var _this2 = this;
      data.forEach(function (item) {
        var res = _this2.risk.customFieldValues.find(function (field) {
          return field.fieldId === item.id;
        });
        item.value = res !== undefined ? res.value : 0;
      });
      return data;
    },
    getPeriodProbabilityRowLabel: function getPeriodProbabilityRowLabel(id) {
      var row = this.period.probabilityRows.find(function (row) {
        return row.id == id;
      });
      return row ? row.label : '';
    },
    getProbabilityValue: function getProbabilityValue(id) {
      var _row$column, _row$column2;
      var row = this.risk.residualProbabilityRowScores.find(function (score) {
        var _score$column;
        return ((_score$column = score.column) === null || _score$column === void 0 ? void 0 : _score$column.rowId) == id;
      });
      return row ? "".concat((_row$column = row.column) === null || _row$column === void 0 ? void 0 : _row$column.value, ": ").concat((_row$column2 = row.column) === null || _row$column2 === void 0 ? void 0 : _row$column2.label) : '';
    },
    updateUnderlyingRisk: function updateUnderlyingRisk(risk) {
      this.updatedUnderlyingRisks = risk;
    },
    dismissUnderlyingRiskPreview: function dismissUnderlyingRiskPreview() {
      var _this3 = this;
      if (this.updatedUnderlyingRisks) {
        var riskToUpdate = this.risk.children.find(function (risk) {
          return risk.id === _this3.updatedUnderlyingRisks.id;
        });
        riskToUpdate.title = this.updatedUnderlyingRisks.title;
        this.updatedUnderlyingRisks = null;
      }
    }
  }
};