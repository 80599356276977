import { getComplianceProjects as _getComplianceProjects } from '@/api/risma/data';
import { getAllRiskProjects } from '@/api/risk/risk';
import AdminBulkTabItems from '@/components/Organisms/Admin/AdminBulkTabItems';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
export default {
  name: 'BulkUpdatesEntry',
  components: {
    AdminBulkTabItems: AdminBulkTabItems,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  data: function data() {
    return {
      complianceProjects: [],
      riskProjects: [],
      isLoading: false
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      var promises = [this.getComplianceProjects(), this.getRiskProjects()];
      return Promise.all(promises).then(function () {
        _this.isLoading = false;
      });
    },
    getComplianceProjects: function getComplianceProjects() {
      var _this2 = this;
      return _getComplianceProjects().then(function (_ref) {
        var list = _ref.list;
        _this2.complianceProjects = list;
      });
    },
    getRiskProjects: function getRiskProjects() {
      var _this3 = this;
      return getAllRiskProjects().then(function (_ref2) {
        var list = _ref2.list;
        _this3.riskProjects = list;
      });
    }
  }
};