function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { createCustomField as _createCustomField, editCustomField as _editCustomField } from '@/api/admin/admin';
import { CustomFieldTypes as CF_TYPES } from '@/constants/CustomFieldTypes';
import { MODULES } from '@/constants/modules';
import { Colors } from '@/Colors';
import Modal from '@/components/Molecules/Modal/Modal';
import CustomFieldTypes from '@/components/Molecules/CustomFieldTypes';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import { CustomFieldValidationMixin } from '@/components/Organisms/Admin/CustomFieldValidationMixin';
import { IATitleMixin } from '@/mixins/IATitleMixin';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { AdminCustomFieldsMixin } from '@/components/Organisms/Admin/AdminCustomFieldsMixin';
import { AdminTypesModalMixin } from '@/components/Organisms/Admin/AdminTypesModalMixin';
import { getDisabledModuleFromArrayBySetting } from '@/utils/format/getDisabledModuleFromArrayBySetting';
import * as Utils from '@/utils/Utils';
export default {
  name: 'AdminCustomFieldsModal',
  introduction: 'Modal with custom fields in /business/admin',
  description: 'Modal for creation & edit custom fields. Used together with AdminCustomFieldsWell component',
  token: '<admin-custom-fields-modal></admin-custom-fields-modal>',
  components: {
    Modal: Modal,
    InputField: InputField,
    SingleSelect: SingleSelect,
    RismaButton: RismaButton,
    Notification: Notification,
    CustomFieldTypes: CustomFieldTypes
  },
  mixins: [AdminCustomFieldsMixin, AdminTypesModalMixin, IATitleMixin, CustomFieldValidationMixin, ProcessLibraryTitleMixin],
  props: {
    settings: {
      type: Object,
      required: true,
      note: 'Settings from /utils/settings'
    },
    customFields: {
      type: Array,
      required: false,
      default: function _default() {},
      note: 'All possible custom fields'
    }
  },
  emits: ['created', 'edited'],
  data: function data() {
    return {
      isEditMode: false,
      colors: Colors,
      customFieldTypes: CF_TYPES,
      showModal: false,
      showButtons: false,
      notificationError: null,
      notificationSuccess: null,
      orders: [],
      availableOrder: [],
      MODULES: MODULES,
      fieldDeafultValue: {
        label: '',
        explanatoryText: '',
        module: '',
        typeId: '',
        options: [],
        unit: '',
        children: [],
        errors: {}
      },
      field: {},
      translate: getTranslate['AdminCustomFieldsModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    labelErrorNotification: function labelErrorNotification() {
      var errors = this.field.errors;
      return errors && errors.label ? this.translate.pleaseFillOutThisField : '';
    },
    moduleTypes: function moduleTypes() {
      var items = [{
        label: 'RISMAactions',
        value: MODULES.EXECUTION
      }, {
        label: 'RISMAincidents',
        value: MODULES.INCIDENTS
      }, {
        label: 'RISMAcontrols',
        value: MODULES.CONTROLS
      }, {
        label: 'RISMArisk',
        value: MODULES.RISK
      }, {
        label: 'RISMAcompliance',
        value: MODULES.COMPLIANCE
      }, {
        label: 'Contracts',
        value: MODULES.CONTRACTS
      }, {
        label: this.processLibraryTitle,
        value: MODULES.COMPLIANCE_PROCESS_TREE_NODE
      }, {
        label: this.informationAssetsTitle() + ': ' + this.translate.custom,
        value: MODULES.COMPLIANCE_INFORMATION_ASSETS
      }, {
        label: this.informationAssetsTitle() + ': ' + this.translate.dataController,
        value: MODULES.COMPLIANCE_IA_CONTROLLER
      }, {
        label: this.informationAssetsTitle() + ': ' + this.translate.dataProcessor,
        value: MODULES.COMPLIANCE_IA_PROCESSOR
      }, {
        label: this.informationAssetsTitle() + ': ' + this.translate.system,
        value: MODULES.COMPLIANCE_IA_SYSTEM
      }];
      var values = getDisabledModuleFromArrayBySetting(items.map(function (item) {
        return item.value;
      }), this.getSettingValue);
      values.forEach(function (item) {
        items.splice(items.findIndex(function (element) {
          return element.value === item;
        }), 1);
      });
      return items;
    }
  }),
  methods: {
    onCreateOpen: function onCreateOpen(module) {
      this.beforeOpen();
      this.isEditMode = false;
      this.field.module = module || this.moduleTypes[0].value;
    },
    onEditOpen: function onEditOpen(data) {
      this.beforeOpen();
      this.isEditMode = true;
      this.field = _objectSpread({}, this.fieldDeafultValue);

      // Populate values from server
      if (data) {
        this.field = _objectSpread(_objectSpread({}, this.field), data);
        if (data.options) {
          this.field.options = Utils.getProperSelectedOptions(data.options);
        }
        if (data.children) {
          this.field.children = data.children.map(function (child, index) {
            return _objectSpread(_objectSpread({}, child), {}, {
              order: ++index
            });
          });
        }
      }
    },
    onChangeModuleType: function onChangeModuleType(e) {
      if (e) {
        this.field.module = e;
        if (this.field.module !== MODULES.CONTROLS && this.field.typeId === this.customFieldTypes.ROW_GROUP) {
          this.field.typeId = null;
        }
      }
    },
    saveField: function saveField() {
      if (this.validate(this.field)) {
        var data = this.prepeareDataForSaving(this.field);
        return this.field.id ? this.editCustomField(this.field.id, data) : this.createCustomField(data);
      }
    },
    createCustomField: function createCustomField(data) {
      var _this = this;
      return _createCustomField(data).then(function (data) {
        _this.clearFields();
        _this.toggleModal();
        _this.$emit('created', data);
      }).catch(function (e) {
        if (e && e.response && e.response.data && e.response.data.error) {
          _this.showErrorMessage(e.response.data.error);
        }
        throw e;
      });
    },
    editCustomField: function editCustomField(id, data) {
      var _this2 = this;
      return _editCustomField(id, data).then(function (data) {
        _this2.$emit('edited', data);
      }).catch(function (e) {
        throw e;
      });
    },
    clearFields: function clearFields() {
      this.notificationError = '';
      this.field = _objectSpread({}, this.fieldDeafultValue);
    },
    editCustomFieldType: function editCustomFieldType(key, value) {
      this.field = _objectSpread(_objectSpread({}, this.field), {}, _defineProperty({}, key, value));
    }
  }
};