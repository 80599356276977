import Question from '@/components/Molecules/Questionnaire/Question';
export default {
  name: '',
  components: {
    Question: Question
  },
  data: function data() {
    return {
      question: {
        question: {
          id: 'aJNnasID',
          questionnumber: 4,
          headline: 'Question Headline',
          questiontype: 9,
          help: 'Question Help',
          question: 'Question of a question',
          informationAssets: {
            systems: [{
              id: '2',
              name: 'To'
            }]
          }
        },
        informationAssets: {
          systems: [{
            id: '1',
            name: 'Hello'
          }, {
            id: '2',
            name: 'To'
          }, {
            id: '3',
            name: 'My'
          }, {
            id: '4',
            name: 'Little'
          }, {
            id: '5',
            name: 'Friend'
          }, {
            id: '6',
            name: 'Say'
          }]
        }
      }
    };
  }
};