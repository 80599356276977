import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'HorizontalResizeBox',
  description: 'Component that cuts box in half with option to resize left and right columns',
  token: "\n    <horizontal-resize-box\n      :local-storage-key=\"localStorageKey\">\n      <template v-slot:leftColumn>\n      </template>\n      <template v-slot:rightColumn>\n      </template>\n    </horizontal-resize-box>",
  props: {
    localStorageKey: {
      type: String,
      required: false,
      default: 'horizontal_resize_box'
    }
  },
  setup: function setup(props) {
    return {
      leftColumnWidth: getLocalStorageComputed(props.localStorageKey, 400, true)
    };
  },
  data: function data() {
    return {
      rightColumnWidth: 0
    };
  },
  methods: {
    initialiseResize: function initialiseResize() {
      window.addEventListener('mousemove', this.startResizing, false);
      window.addEventListener('mouseup', this.stopResizing, false);
    },
    stopResizing: function stopResizing() {
      window.removeEventListener('mousemove', this.startResizing, false);
      window.removeEventListener('mouseup', this.stopResizing, false);
    },
    startResizing: function startResizing(e) {
      e.preventDefault();
      this.leftColumnWidth = e.clientX;
    }
  }
};