function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export function escapeRegex(val) {
  // Proudly stolen from DataTable library
  var reEscapeRegex = new RegExp('(\\' + ['/', '.', '*', '+', '?', '|', '(', ')', '[', ']', '{', '}', '\\', '$', '^', '-'].join('|\\') + ')', 'g');
  return val.replace(reEscapeRegex, '\\$1');
}
export function unEscapeRegex(string) {
  return string.replace(/\\(.)/g, '$1');
}
export function isHexColor(str) {
  var regex = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/g;
  return regex.test(str);
}
export function getRisma1AttachmentUrl(url) {
  var regex = /^\/api\/2\.0\/files\/([0-9]*)\?download/;
  var id = null;
  var regexMatch = url.match(regex);
  if (url && regexMatch && regexMatch[1]) {
    id = regexMatch[1];
    // Switch to Risma1 attachments
    url = "/attachments/get/".concat(id);
  }
  return url;
}
export function prepareFilterRegex(item) {
  var space = '\\s*';
  var oneOrMoreSpaces = '\\s+';
  var comma = '(,)+';
  var semicolon = '(;)+';
  var twoOrMore = '{2,}';
  var exactMatch = "^".concat(item, "$"); // 100% match
  var commaBefore = "".concat(comma).concat(space).concat(item);
  var commaAfter = "".concat(item).concat(comma).concat(space);
  var semicolonBefore = "".concat(semicolon).concat(space).concat(item);
  var semicolonAfter = "".concat(item).concat(semicolon).concat(space);
  var twoOrMoreMatch = "(".concat(item).concat(oneOrMoreSpaces, ")").concat(twoOrMore);

  // Please modify filterString carefully:
  // don't forget to change delta in removeFilterRegex as well if add additional |
  return "".concat(exactMatch, "|").concat(commaBefore, "|").concat(semicolonBefore, "|").concat(commaAfter, "|").concat(semicolonAfter, "|").concat(twoOrMoreMatch, "|");
}
export function removeFilterRegex(data) {
  var SYMBOL_BEGIN = '^';
  var SYMBOL_LAST = '$';
  var delta = 6;
  var result = removeExcessParts(data, delta);
  result = result.map(function (el) {
    if (el) {
      el = unEscapeRegex(el);
      if (el[0] === SYMBOL_BEGIN) {
        el = el.substring(1, el.length);
      }
      if (el[el.length - 1] === SYMBOL_LAST) {
        el = el.substring(0, el.length - 1);
      }
      return el;
    }
  });
  return result;
}
export function removeExcessParts(data, delta) {
  var result = [];
  if (data.length % delta === 0) {
    for (var i = 0; i < data.length; i = i + delta) {
      result.push(data[i]);
    }
  } else {
    result = _toConsumableArray(data);
  }
  return result;
}