function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Doughnut as DoughnutChart } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartCenterPlugin from '@/utils/plugins/chart-js-center-plugin';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import ExportFrame from '@/components/Atoms/Containers/ExportFrame';
import { htmlLegendPlugin } from '@/components/Molecules/Charts/chartPlugins';
export default {
  name: 'RismaDoughnutChart',
  introduction: 'simple DoughnutChart with using the vue-chartjs',
  description: '',
  token: "<risma-doughnut-chart\n      :data=\"chartData\"\n      :labels=\"chartLabels\"\n      :options=\"chartOptions\"\n      :colors=\"colors\"\n      @chartClicked=\"clickCh\"/> ",
  components: {
    DoughnutChart: DoughnutChart,
    RismaTitle: RismaTitle,
    ExportFrame: ExportFrame
  },
  props: {
    datasets: {
      required: true,
      type: Array,
      default: function _default() {},
      note: 'the data to create the component'
    },
    labels: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'labels that are shown'
    },
    options: {
      required: false,
      type: Object,
      default: function _default() {}
    },
    titleType: {
      required: false,
      type: String,
      default: 'medium'
    },
    title: {
      required: false,
      type: String,
      default: ''
    },
    description: {
      required: false,
      type: String,
      default: ''
    },
    chartMaxWidth: {
      required: false,
      type: String,
      default: '100%'
    },
    chartMinWidth: {
      required: false,
      type: String,
      default: 'auto'
    },
    showDataLabels: {
      required: false,
      type: Boolean,
      default: false
    },
    dataLabelsColor: {
      required: false,
      type: String,
      default: 'white'
    },
    percentageValue: {
      required: false,
      type: Boolean,
      default: true
    },
    legendPosition: {
      required: false,
      type: String,
      default: 'bottom',
      validator: function validator(position) {
        return ['top', 'bottom', 'left', 'right'].includes(position);
      }
    },
    cover: {
      required: false,
      type: Boolean,
      default: true,
      note: 'Should doughnut chart cover entire place?'
    },
    useDefaultExport: {
      required: false,
      type: Boolean,
      default: true
    },
    isExportOnParent: {
      type: Boolean,
      required: false,
      default: false
    },
    customLegendEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    excelExportEnabled: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['chartClicked'],
  data: function data() {
    return {
      legendItems: [],
      customLabels: [],
      hiddenDatasetIndexes: new Map()
    };
  },
  computed: {
    defaultPlugins: function defaultPlugins() {
      var plugins = [ChartDataLabels, ChartCenterPlugin];
      if (this.customLegendEnabled) {
        plugins.push(htmlLegendPlugin);
      }
      return plugins;
    },
    isExcelExportAvailable: function isExcelExportAvailable() {
      return this.excelExportEnabled && this.datasets.length === 1 && this.labels.length;
    },
    chartData: function chartData() {
      var _this = this;
      return {
        labels: this.labels,
        datasets: this.datasets.map(function (dataset) {
          return _objectSpread(_objectSpread({}, dataset), {}, {
            rawData: dataset.data.slice(0),
            data: _this.roundToMinValue(dataset.data, _this.legendItems) // method to keep minimal values visible
          });
        })
      };
    },
    customLegendId: function customLegendId() {
      return Math.random().toString(20).substring(7);
    },
    defaultOptions: function defaultOptions() {
      var _this2 = this;
      var defaultPlugins = {
        datalabels: {
          labels: {
            value: {
              display: this.showDataLabels,
              color: this.dataLabelsColor,
              formatter: function formatter(value, ctx) {
                var rawValue = ctx.dataset.rawData[ctx.dataIndex];
                return value >= 3 ? rawValue : '';
              }
            }
          }
        },
        legend: {
          display: !this.customLegendEnabled,
          position: this.legendPosition,
          onClick: this.legendClickEvent
        },
        tooltip: {
          padding: 15,
          boxPadding: 10,
          callbacks: {
            label: function label(ctx) {
              var dataset = ctx.dataset,
                label = ctx.label,
                dataIndex = ctx.dataIndex;
              var curValue = dataset.rawData[dataIndex];
              if (_this2.percentageValue) {
                return curValue + '%';
              }
              var total = (dataset === null || dataset === void 0 ? void 0 : dataset.total) || dataset.rawData.reduce(function (total, val) {
                return total + +val;
              }, 0);
              return "".concat(label, ": ").concat((curValue / total * 100).toFixed(2), "% (").concat(curValue, ")");
            }
          },
          filter: function filter(item) {
            if (_this2.percentageValue) {
              return _this2.percentageValue && item.parsed && item.parsed !== 100;
            }
            return true;
          }
        }
      };
      var defaultOptions = _objectSpread({
        onClick: this.handleClick,
        responsive: true,
        maintainAspectRatio: !this.cover,
        devicePixelRatio: 1,
        cutout: '35%'
      }, this.options);
      if (this.customLegendEnabled) {
        this.setHtmlPlugin(defaultPlugins);
      }
      defaultOptions.plugins = defaultPlugins;
      if (this.options && this.options.plugins) {
        Object.assign(defaultOptions.plugins, this.options.plugins || {});
      }
      return defaultOptions;
    }
  },
  watch: {
    labels: {
      handler: function handler() {
        var _this3 = this;
        if (!this.customLegendEnabled) return;
        this.customLabels = this.labels.map(function (label, index) {
          return {
            label: label,
            color: _this3.datasets[0].backgroundColor[index],
            hidden: false
          };
        });
        if (this.$refs.doughnut) {
          this.resetHiddenDataset();
        }
      },
      immediate: true
    }
  },
  methods: {
    legendClickEvent: function legendClickEvent(e, legendItem, legend) {
      //same logic but last line added
      legend.chart.toggleDataVisibility(legendItem.index);
      legend.chart.update();
      this.legendItems = legend.legendItems;
    },
    handleClick: function handleClick(point, event) {
      if (event.length) {
        this.$emit('chartClicked', event[0].index);
      }
    },
    roundToMinValue: function roundToMinValue(data, totalArray) {
      var isFirstEnter = !totalArray.length;
      var total = data.reduce(function (acc, value, index) {
        return isFirstEnter ? acc + +value : totalArray[index].hidden ? acc : acc + +value;
      }, 0);
      return data.map(function (value, index) {
        return +value || +value && !isFirstEnter && !totalArray[index].hidden ? Math.max(+value / total * 100, 3) : 0;
      }); // 1 - each slice will have minimum 1% width and if hidden -> 0%
    },
    setHtmlPlugin: function setHtmlPlugin(defaultPlugins) {
      var self = this;
      defaultPlugins.htmlLegend = {
        containerId: this.customLegendId,
        callback: function callback(index) {
          self.customLabels[index].hidden = !self.customLabels[index].hidden;
          self.hiddenDatasetIndexes.set(index, self.customLabels[index].hidden);
        }
      };
    },
    resetHiddenDataset: function resetHiddenDataset() {
      var _iterator = _createForOfIteratorHelper(this.hiddenDatasetIndexes),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _step$value = _slicedToArray(_step.value, 2),
            key = _step$value[0],
            value = _step$value[1];
          if (value) {
            this.$refs.doughnut.chartInstance.toggleDataVisibility(key);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      this.hiddenDatasetIndexes.clear();
    },
    excelExportFunction: function excelExportFunction() {
      var data = Object.assign({}, this.datasets[0].data || []);
      var columns = Object.assign({}, this.labels || []);
      return Promise.resolve({
        dataset: [data],
        columns: columns
      });
    }
  }
};