import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2b553183"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "flex items-end mb-3"
};
var _hoisted_2 = {
  class: "m-0.3rem ml-0"
};
var _hoisted_3 = {
  class: "flex-grow m-0.3rem"
};
var _hoisted_4 = {
  class: "mb-2"
};
var _hoisted_5 = ["value", "onClick"];
var _hoisted_6 = ["checked", "disabled", "onChange"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_add_contract_party_modal = _resolveComponent("add-contract-party-modal");
  var _component_notification = _resolveComponent("notification");
  var _component_single_select = _resolveComponent("single-select");
  var _component_input_field = _resolveComponent("input-field");
  var _component_static_table = _resolveComponent("static-table");
  var _component_modal = _resolveComponent("modal");
  var _component_risma_split_button = _resolveComponent("risma-split-button");
  return _openBlock(), _createElementBlock("div", null, [$data.showAddViaVatModule ? (_openBlock(), _createBlock(_component_add_contract_party_modal, {
    key: 0,
    "linked-companies": $props.linkedCompanies,
    "all-companies": $props.allCompanies,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showAddViaVatModule = false;
    }),
    onAddNewCompany: $options.onAddContractParty,
    onAddInternalCompany: $options.addInternalCompany
  }, null, 8 /* PROPS */, ["linked-companies", "all-companies", "onAddNewCompany", "onAddInternalCompany"])) : _createCommentVNode("v-if", true), $data.showCreateViaVatModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 1,
    header: $data.translate.createCompanyViaVatNumber,
    "dismiss-on-click-outside": false,
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    "overflow-visible": true,
    "show-buttons": !!$data.companyDataset.length,
    "accept-on-enter": false,
    "ok-button-disabled": $data.selectedCompanyIndex === null,
    onAccept: $options.createCompanyViaVat,
    onDismiss: $options.hideCreateViaVatModal
  }, {
    body: _withCtx(function () {
      return [$data.createCompanyNotification.message ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: $data.createCompanyNotification.type
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.createCompanyNotification.message), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
        modelValue: $data.countryCode,
        "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
          return $data.countryCode = $event;
        }), $options.resetSearchResults],
        title: $data.translate.countryCode,
        options: $options.countryCodeOptions
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_input_field, {
        modelValue: $data.companySearchFieldValue,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.companySearchFieldValue = $event;
        }),
        placeholder: $data.translate.pressEnterToStartSearch,
        "focus-on-mount": true,
        title: $options.searchFieldLabel,
        type: "text",
        class: "mr-10",
        onOnEnter: $options.searchCompany
      }, null, 8 /* PROPS */, ["modelValue", "placeholder", "title", "onOnEnter"])])]), $data.companyDataset.length ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createElementVNode("p", _hoisted_4, _toDisplayString($data.translate.chooseTheCompanyYouWishToCreate), 1 /* TEXT */), _createVNode(_component_static_table, {
        dataset: $data.companyDataset,
        columns: $options.companyColumns
      }, {
        selected: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
              return $data.selectedCompanyIndex = $event;
            }),
            value: slotData,
            type: "radio",
            class: "cursor-pointer",
            onClick: function onClick($event) {
              return $options.resetCreateAsDataProcessorIndex(slotData);
            }
          }, null, 8 /* PROPS */, _hoisted_5), [[_vModelRadio, $data.selectedCompanyIndex]])];
        }),
        dataProcessor: _withCtx(function (_ref2) {
          var slotData = _ref2.slotData;
          return [_createElementVNode("input", {
            checked: $data.checkedCreateAsDataProcessorIndex === slotData,
            disabled: $data.selectedCompanyIndex !== slotData,
            type: "checkbox",
            class: _normalizeClass({
              'cursor-pointer': $data.selectedCompanyIndex === slotData
            }),
            onChange: function onChange($event) {
              return $options.toggleCheckedAsDataProcessor(slotData, $event.target.checked);
            }
          }, null, 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_6)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset", "columns"])], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "show-buttons", "ok-button-disabled", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), $data.showManuallyCreateCompanyModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 2,
    header: $options.content.createCompanyPopup.header,
    "dismiss-on-click-outside": false,
    "modal-fixed-width": "500px",
    "button-ok-text": $options.content.createCompanyPopup.okBtn,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.manuallyCreateCompany,
    onDismiss: $options.hideManuallyCreateCompanyModal
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [!$data.isCompanyNameValid ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.titleCannotBeEmpty), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
        modelValue: $data.companyName,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $data.companyName = $event;
        }),
        title: $data.translate.name,
        "focus-on-mount": true,
        placeholder: $options.content.createCompanyPopup.inputPlaceholder,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_split_button, {
    text: $options.content.splitBtnLabel,
    onClick: $options.openCreateViaVatModal
  }, {
    dropContent: _withCtx(function () {
      return [_createElementVNode("div", {
        class: "p-0.5 px-1 rounded-md hover:bg-gray-350 cursor-pointer",
        onClick: _cache[5] || (_cache[5] = function () {
          return $options.openManuallyCreateCompanyModal && $options.openManuallyCreateCompanyModal.apply($options, arguments);
        })
      }, [_createElementVNode("span", null, _toDisplayString($options.content.manualBtnLable), 1 /* TEXT */)])];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"])]);
}