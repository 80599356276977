import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-23236246"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "risk-filters"
};
var _hoisted_2 = {
  class: "risk-filters-content"
};
var _hoisted_3 = {
  class: "risk-filters-section risk-filters-section-orgs"
};
var _hoisted_4 = {
  key: 0,
  class: "risk-filters-section risk-filters-section-prisk"
};
var _hoisted_5 = {
  class: "risk-filters-section risk-filters-section-tags"
};
var _hoisted_6 = {
  class: "risk-filters-section risk-filters-section-search"
};
var _hoisted_7 = {
  class: "search-input-wrap"
};
var _hoisted_8 = ["placeholder", "value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_org_selector = _resolveComponent("risma-org-selector");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_field_set = _resolveComponent("field-set");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_field_set, {
    title: $data.translate.mainFilters
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
        title: $data.translate.organisations,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_org_selector, {
        "available-options": $props.organisations,
        "selected-options": $props.organisationsSelected,
        onSelected: _cache[0] || (_cache[0] = function (orgs) {
          return _ctx.$emit('update-orgs', orgs);
        })
      }, null, 8 /* PROPS */, ["available-options", "selected-options"])]), $props.parentRisks.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_single_select, {
        title: $data.translate.parentRisk,
        placeholder: $data.translate.noFiltersApplied,
        options: $props.parentRisks,
        "model-value": $props.parentRiskSelected,
        "un-sorted": true,
        "with-reset": true,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function (parentRisks) {
          return _ctx.$emit('update-prisk', parentRisks);
        })
      }, null, 8 /* PROPS */, ["title", "placeholder", "options", "model-value"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
        title: $data.translate.tags,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
        placeholder: $data.translate.tags,
        options: $props.tags,
        "selected-options": $props.tagsSelected,
        "label-key": "tag",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function (tag) {
          return _ctx.$emit('update-tag', tag);
        })
      }, null, 8 /* PROPS */, ["placeholder", "options", "selected-options"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_title, {
        title: $data.translate.search,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_7, [_createElementVNode("input", {
        placeholder: "".concat($data.translate.searchForNoOrTitle),
        value: $props.search,
        type: "search",
        class: "controls-search-input",
        onInput: _cache[3] || (_cache[3] = function (e) {
          return _ctx.$emit('update-search', e.target.value.trim());
        })
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_8)])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"])]);
}