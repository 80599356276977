import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "relative w-fit"
};
var _hoisted_2 = {
  class: "flex mb-0.5"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  key: 0,
  class: "absolute right-0 top-1/2 z-2 transform translate-x-full -translate-y-1/2 p-1.5 bg-white border-2 rounded-sm border-blue-750"
};
var _hoisted_5 = {
  class: "flex mb-1.5 last:mb-0"
};
var _hoisted_6 = {
  class: "pr-2 font-semibold"
};
var _hoisted_7 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.dataset, function (row, rowIndex) {
    return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, function (cell, colIndex) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["grid place-items-center mr-0.5 w-12 h-12", {
          'border-2 border-black': rowIndex == $data.selectedRow && colIndex == $data.selectedColumn,
          'cursor-pointer': !$props.isReadonly
        }]),
        style: _normalizeStyle({
          'background-color': cell.backgroundColor
        }),
        onClick: _withModifiers(function ($event) {
          return $options.openColorSelector(rowIndex, colIndex);
        }, ["stop"])
      }, [_createElementVNode("span", null, _toDisplayString($data.RowNumberByColor[cell.backgroundColor]), 1 /* TEXT */), $data.selectedColumn === colIndex && $data.selectedRow === rowIndex ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.ColorPickerColors, function (colorRow, index) {
        return _openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("span", _hoisted_6, _toDisplayString(index + 1), 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(colorRow, function (color) {
          return _openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["w-6 h-6 mr-1.5 last:mr-0 bg-green-300 rounded-full", {
              'border-2 border-black': color === cell.backgroundColor
            }]),
            style: _normalizeStyle({
              'background-color': color
            }),
            onClick: _withModifiers(function ($event) {
              return $options.setColor(cell.value, color);
            }, ["stop"])
          }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_7);
        }), 256 /* UNKEYED_FRAGMENT */))]);
      }), 256 /* UNKEYED_FRAGMENT */))])), [[_directive_click_outside, $options.closeColorSelector]]) : _createCommentVNode("v-if", true)], 14 /* CLASS, STYLE, PROPS */, _hoisted_3);
    }), 256 /* UNKEYED_FRAGMENT */))])]);
  }), 256 /* UNKEYED_FRAGMENT */))]);
}