import { Http } from '../index';
import * as HttpUtils from '../httpUtils';
export function getAvailableQuestionnaires(params) {
  return HttpUtils.handleRequestResponse(Http.get('/rismaAdmin/sync/questionnaires?localOrRemote=remote&' + params));
}
export function getSyncedQuestionnaires(params) {
  return HttpUtils.handleRequestResponse(Http.get('/rismaAdmin/sync/questionnaires?localOrRemote=local&' + params));
}
export function getAvailableGapSchemas(params) {
  return HttpUtils.handleRequestResponse(Http.get('/rismaAdmin/sync/gapschemas?localOrRemote=remote&' + params));
}
export function getSyncedGapSchemas(params) {
  return HttpUtils.handleRequestResponse(Http.get('/rismaAdmin/sync/gapschemas?localOrRemote=local&' + params));
}
export function getSyncDatabaseStatus() {
  return HttpUtils.handleRequestResponse(Http.get('/rismaAdmin/sync/checkConnection'));
}
export function getSyncedProjects(params) {
  return HttpUtils.handleRequestResponse(Http.get('/rismaAdmin/sync/projects/synced?' + params));
}
export function getAvailableProjects() {
  return HttpUtils.handleRequestResponse(Http.get('/rismaAdmin/sync/projects/available'));
}
export function synchroniseProjects(params) {
  return HttpUtils.handleRequestResponse(Http.patch('/rismaAdmin/sync/projects/synchronise', params));
}