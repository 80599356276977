function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import emitter from 'tiny-emitter/instance';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import * as ControlsApi from '@/api/controls/controls';
import { controlsListBaseMixin } from '@/components/Pages/Controls/controlsListBaseMixin';
import { deletionControlMixin } from '@/components/Pages/Controls/deletionControlMixin';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import Tree from '@/components/Atoms/Tree';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import ControlCreationModal from '@/components/Molecules/Controls/ControlCreationModal';
import Notification from '@/components/Molecules/Notification';
import CatalogueHeader from '@/components/Molecules/CatalogueHeader/CatalogueHeader';
import ControlFilters from '@/components/Organisms/Controls/ControlFilters';
import ControlsListPreview from '@/components/Organisms/Controls/ControlsListPreview';
import { ControlValidationMessages } from '@/constants/ControlValidationMessages';
import { ControlViews } from '@/constants/ControlViews';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { checkControlsOrgAccess, isLockedControlUser } from '@/utils/access';
import { check403AccessError } from '@/utils/handleAPIErrors';
import { SortFieldsCompatibility } from '@/constants/SortFieldsCompatibility';
import { getSortFunction } from '@/utils/sort';
import { comparePathStrings } from '@/utils/compare';
import { ControlFields } from '@/constants/ControlFields';
import { CONTROLS_VIEWS } from '@/constants/ViewTypes';
import { ControlLocalStorage } from '@/constants/ControlLocalStorage';
import { MODULES } from '@/constants/modules';
import { UserLevels } from '@/constants/UserLevels';
import { calcControlsRights } from '@/menus/quicknav';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
var MODULE_NAME = MODULES.CONTROLS;
export default {
  name: 'ControlsWrapper',
  introduction: 'wrapper for list of all controls.',
  description: 'This is Page component for /controls2/controls/',
  token: '<controls-wrapper></controls-wrapper>',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    Notification: Notification,
    Tree: Tree,
    CatalogueHeader: CatalogueHeader,
    RismaButton: RismaButton,
    ControlCreationModal: ControlCreationModal,
    ControlFilters: ControlFilters,
    NotificationPopUp: NotificationPopUp,
    ControlsListPreview: ControlsListPreview,
    RismaTable: RismaTable
  },
  mixins: [controlsListBaseMixin, deletionControlMixin, NotificationPopUpMixin],
  setup: function setup() {
    return {
      currentView: getLocalStorageComputed('controls_catalogue_view', ControlViews.EXPANDED)
    };
  },
  data: function data() {
    return {
      controls: [],
      motherControls: [],
      treeData: {},
      treeAllLevels: [{
        label: '1',
        value: '1'
      }, {
        label: '2',
        value: '2'
      }, {
        label: '3',
        value: '3'
      }, {
        label: '4',
        value: '4'
      }],
      CONTROLS_VIEWS: CONTROLS_VIEWS,
      stateLocal: ControlLocalStorage.CATALOGUE_FILTERS,
      MODULE_NAME: MODULE_NAME,
      ControlViews: ControlViews,
      updatedControlFromPreview: null,
      isMotherCreationMode: false,
      isCreationModalShow: false,
      isFiltersDataLoaded: false,
      isControlCatalogue: true,
      //used to add sortBy and groupBy
      filters: {
        organisations: [],
        tags: [],
        mother: null,
        responsible: [],
        accounts: [],
        risks: [],
        processes: [],
        search: '',
        deadline: null,
        showDeactivated: false,
        groupBy: null,
        sortBy: null,
        showOverviewOptions: false
      },
      tableStateKey: 'controls_overview_table',
      tableGeneratedKey: this.generateKey(),
      translate: getTranslate['ControlsWrapper'](),
      updatedControlId: null,
      isLoaded: true,
      controlsLoadedOnce: false,
      treeDataLoadedOnce: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    getAllSettings: 'getAllSettings'
  })), mapState(useUserStore, ['currentUser'])), mapState(useUsersStore, {
    users: 'activeUsers'
  })), {}, {
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'control');
    },
    notificationObj: function notificationObj() {
      if (this.detailLoadedError) {
        return {
          type: 'error',
          message: this.detailLoadedError
        };
      }
      if (this.genericError) {
        return {
          type: 'error',
          message: this.genericError
        };
      }
      if (this.isControlDeletedSuccess) {
        return {
          type: 'success',
          message: this.translate.controlDeleted
        };
      }
      return null;
    },
    allowedToCreateControls: function allowedToCreateControls() {
      return !isLockedControlUser(this.currentUser);
    },
    allowedToCreateMother: function allowedToCreateMother() {
      return this.userHasMotherAccess && this.allowedToCreateControls;
    },
    controlsWithoutOwners: function controlsWithoutOwners() {
      if (!this.userHasSuperAccess) return [];
      return this.filteredAndSortedControls.filter(function (control) {
        return !control.userIds.responsible.length;
      });
    },
    controlsWithOwners: function controlsWithOwners() {
      return this.filteredAndSortedControls.filter(function (control) {
        return control.userIds.responsible.length;
      });
    },
    userHasMotherAccess: function userHasMotherAccess() {
      var motherControlUserLevel = this.getSettingValue('feature.create_mothercontrol_user_level');
      return checkControlsOrgAccess(this.currentUser, motherControlUserLevel);
    },
    userHasSuperAccess: function userHasSuperAccess() {
      return calcControlsRights(this.currentUser, this.getAllSettings, UserLevels.SUPER);
    },
    sortedControls: function sortedControls() {
      var sortedControls = this.controls.slice(0).sort(function (a, b) {
        return comparePathStrings(a.path, b.path);
      });
      var sortBy = this.filters.sortBy;
      if (sortBy && sortBy !== SortFieldsCompatibility.CPATH) {
        var sortFunction = getSortFunction({
          sortBy: sortBy,
          users: this.users,
          organisations: this.organisations
        });
        sortedControls.sort(sortFunction);
      }
      return sortedControls;
    },
    filteredMotherControls: function filteredMotherControls() {
      var _this = this;
      var filteredControls = this.motherControls.slice(0);
      var _this$filters = this.filters,
        mother = _this$filters.mother,
        search = _this$filters.search,
        tags = _this$filters.tags,
        showDeactivated = _this$filters.showDeactivated,
        accounts = _this$filters.accounts,
        processes = _this$filters.processes,
        risks = _this$filters.risks;
      filteredControls = filteredControls.filter(function (control) {
        var filterPass = true;
        if (search) {
          filterPass = filterPass && _this.searchByEntity("".concat(control.cpath, " ").concat(control.title));
        }
        if (!showDeactivated) {
          filterPass = filterPass && control.active;
        }
        if (tags.length > 0) {
          filterPass = filterPass && _this.controlPassFilterByTagsCriteria(control);
        }
        if (mother) {
          filterPass = filterPass && control.cno === mother;
        }
        if (accounts !== null && accounts !== void 0 && accounts.length) {
          filterPass = filterPass && _this.controlPassFilterByAssociationCriteria(control, 'accounts', accounts);
        }
        if (processes !== null && processes !== void 0 && processes.length) {
          filterPass = filterPass && _this.controlPassFilterByAssociationCriteria(control, 'processes', processes);
        }
        if (risks !== null && risks !== void 0 && risks.length) {
          filterPass = filterPass && _this.controlPassFilterByAssociationCriteria(control, 'risks', risks);
        }
        return filterPass;
      });
      return filteredControls;
    },
    filteredAndSortedControls: function filteredAndSortedControls() {
      var _this2 = this;
      var filteredControls = this.sortedControls.slice(0);
      var _this$filters2 = this.filters,
        mother = _this$filters2.mother,
        deadline = _this$filters2.deadline,
        search = _this$filters2.search,
        organisations = _this$filters2.organisations,
        tags = _this$filters2.tags,
        showDeactivated = _this$filters2.showDeactivated,
        responsible = _this$filters2.responsible,
        accounts = _this$filters2.accounts,
        processes = _this$filters2.processes,
        risks = _this$filters2.risks;
      filteredControls = filteredControls.filter(function (control) {
        var filterPass = true;
        if (mother && control.mothercontrolId != mother) {
          return false;
        }
        if (deadline) {
          filterPass = filterPass && _this2.controlPassFilterByDeadlineCriteria(control);
        }
        if (search) {
          filterPass = filterPass && _this2.controlPassFilterBySearchCriteria(control);
        }
        if (organisations.length) {
          filterPass = filterPass && _this2.controlPassFilterByOrganizationCriteria(control);
        }
        if (accounts !== null && accounts !== void 0 && accounts.length) {
          filterPass = filterPass && _this2.controlPassFilterByAssociationCriteria(control, 'accounts', accounts);
        }
        if (processes !== null && processes !== void 0 && processes.length) {
          filterPass = filterPass && _this2.controlPassFilterByAssociationCriteria(control, 'processes', processes);
        }
        if (risks !== null && risks !== void 0 && risks.length) {
          filterPass = filterPass && _this2.controlPassFilterByAssociationCriteria(control, 'risks', risks);
        }
        if (tags.length) {
          filterPass = filterPass && _this2.controlPassFilterByTagsCriteria(control);
        }
        if (_this2.filtersData.showDeactivated && !showDeactivated) {
          filterPass = filterPass && control.active;
        }
        if (_this2.filtersData.users && responsible.length) {
          filterPass = filterPass && _this2.controlPassFilterByResponsibleCriteria(control);
        }
        return filterPass;
      });
      return filteredControls;
    },
    controlsGroup: function controlsGroup() {
      var hasNoOwnerGroup = this.getSettingValue('feature.controls_allow_no_owners') && this.userHasSuperAccess;
      var result = {
        motherControls: {
          id: 2,
          isMotherControls: true,
          name: MODULE_NAME,
          label: this.translate.motherControls,
          activities: [],
          hidden: false,
          additionalFields: []
        }
      };
      if (hasNoOwnerGroup) {
        result['no-owner-group'] = {
          id: 'no_owner',
          isMotherControls: false,
          label: this.translate.controlsWithoutOwners,
          isNoOwnerGroup: true,
          name: MODULE_NAME,
          activities: this.controlsWithoutOwners
        };
      }
      var controls = hasNoOwnerGroup ? this.controlsWithOwners : this.filteredAndSortedControls;
      if (this.filters.groupBy) {
        var extraGroups = this.getExtraGroupsForGroupBy(this.filters.groupBy, controls);
        result = _objectSpread(_objectSpread({}, extraGroups), result);
      } else {
        result['allControls'] = {
          id: 1,
          isMotherControls: false,
          name: MODULE_NAME,
          label: this.translate.allControls,
          activities: controls,
          hidden: false,
          additionalFields: [ControlFields.RESPONSIBLE]
        };
      }
      if (this.userHasMotherAccess) {
        var _this$filters$respons, _this$filters$organis;
        result.motherControls.activities = (_this$filters$respons = this.filters.responsible) !== null && _this$filters$respons !== void 0 && _this$filters$respons.length || (_this$filters$organis = this.filters.organisations) !== null && _this$filters$organis !== void 0 && _this$filters$organis.length ? [] : this.filteredMotherControls;
      }
      return result;
    },
    controlsGroupArray: function controlsGroupArray() {
      var groups = Object.values(this.controlsGroup),
        result = [];
      var motherGroup = groups.find(function (item) {
        return item.isMotherControls;
      });
      var noOwnerGroup = groups.find(function (item) {
        return item.isNoOwnerGroup;
      });
      groups.forEach(function (item) {
        if (!item.isMotherControls && item.activities && item.activities.length && !item.isNoOwnerGroup) {
          result.push(item);
        }
      });
      motherGroup && result.push(motherGroup);
      noOwnerGroup && result.unshift(noOwnerGroup);
      return result;
    },
    showFilters: function showFilters() {
      return this.currentView !== ControlViews.RISMA_TABLE && this.currentView !== ControlViews.TREE && this.isLoaded;
    }
  }),
  watch: {
    currentView: {
      handler: function handler(newView) {
        if (newView === ControlViews.RISMA_TABLE) {
          return;
        }
        if (newView === ControlViews.TREE && !this.treeDataLoadedOnce) {
          this.loadControlsTree();
        } else if (!this.controlsLoadedOnce) {
          this.loadControls();
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    var _this3 = this;
    Promise.all([this.getOrgs(), this.getAccounts(), this.getProcesses(), this.getControlRisks()]).then(function () {
      return _this3.isFiltersDataLoaded = true;
    });
    if (this.$route.query.successDeletion) {
      this.handleDeletedControlSuccess();
    }
  },
  methods: {
    loadControls: function loadControls() {
      var _this4 = this;
      this.isLoaded = false;
      return this.getAllControls().then(function () {
        _this4.setCompability(true);
        setTimeout(function () {
          emitter.emit('emit-scroll');
        }, 800);
      }).catch(function () {
        _this4.genericError = _this4.$trans(ControlValidationMessages.SOME_ERROR_OCCURRED);
      }).finally(function () {
        _this4.isLoaded = true;
        _this4.controlsLoadedOnce = true;
        _this4.turnAfterRefreshNotification();
      });
    },
    loadControlsTree: function loadControlsTree() {
      var _this5 = this;
      this.isLoaded = false;
      return this.getControlsTree().then(function () {
        _this5.isLoaded = true;
        _this5.treeDataLoadedOnce = true;
      });
    },
    getAllControls: function getAllControls() {
      return Promise.all([this.getMotherControls(), this.getControls()]);
    },
    getControls: function getControls() {
      var _this6 = this;
      var params = this.prepareParams();
      return ControlsApi.getAll(params).then(function (_ref) {
        var list = _ref.list;
        _this6.controls = list.filter(function (control) {
          return !control.isMothercontrol;
        });
        if (!_this6.filterByUserAccess) return;
        return _this6.addMissingOrgs(_this6.controls);
      }).catch(function () {
        return _this6.controls = [];
      });
    },
    getControlsBE: function getControlsBE(params) {
      return ControlsApi.getAll('?' + params);
    },
    getMotherControls: function getMotherControls() {
      var _this7 = this;
      var motherParams = '?includeInactive=true';
      return ControlsApi.getAllMotherControls(motherParams).then(function (_ref2) {
        var list = _ref2.list;
        _this7.motherControls = list.map(function (control) {
          control.isMothercontrol = 1;
          return control;
        });
      }).catch(function () {
        return _this7.motherControls = [];
      }); // For example: "Insufficient rights"
    },
    getControlsTree: function getControlsTree() {
      var _this8 = this;
      return ControlsApi.getAllAsTree().then(function (data) {
        _this8.treeData = _objectSpread(_objectSpread({}, data), {}, {
          children: data.children.filter(function (item) {
            return item.children && item.children.length;
          })
        });
      }).catch(function () {
        return _this8.treeData = {};
      });
    },
    addMissingOrgs: function addMissingOrgs(controls) {
      var _this9 = this;
      return RismaControlsUtils.addMissingOrgs(controls).then(function (data) {
        if (data.length) {
          _this9.appendAdditionalOrgs(data);
        }
      });
    },
    prepareParams: function prepareParams() {
      var neededData = ['organisationIds', 'associations'];
      var result = '?';
      neededData.forEach(function (item) {
        result += "data[]=".concat(item, "&");
      });
      result += 'filter[]=inactive&filter[]=pending';
      return result;
    },
    onNewControlCreate: function onNewControlCreate(data) {
      var id = this.currentUser.id + '';
      var hasAccess = this.userHasSuperAccess || data.userIds.responsible.includes(id);
      this.setNotificationData(_objectSpread(_objectSpread({}, data), {}, {
        url: hasAccess ? data.url : null
      }));
      this.turnAfterRefreshNotification();
      data.id = +data.id;
      data.isMothercontrol = +data.isMothercontrol;
      if (+data.isMothercontrol) {
        this.motherControls.push(data);
      } else {
        this.controls.push(data);
      }
      if (this.currentView === ControlViews.TREE) {
        this.getControlsTree();
      }
    },
    getExtraGroupsForGroupBy: function getExtraGroupsForGroupBy(groupBy, items) {
      var result = {};
      switch (groupBy) {
        case 'tags':
          result = this.prepareGroupByTags(items);
          break;
        case 'mothercontrolId':
          result = this.prepareGroupByMother(items);
          break;
        case 'responsible':
          result = this.prepareGroupByUser(items, 'responsible');
          break;
        case 'review':
          result = this.prepareGroupByUser(items, 'review');
          break;
        case 'organisationIds':
          result = this.prepareGroupByOrganisation(items);
          break;
        case SortFieldsCompatibility.ACCOUNTS:
        case SortFieldsCompatibility.PROCESSES:
        case SortFieldsCompatibility.RISKS:
          result = this.prepareGroupByAssociation(items, groupBy);
          break;
      }
      return result;
    },
    prepareGroupByAssociation: function prepareGroupByAssociation(controls, groupBy) {
      var _this10 = this;
      var result = {};
      this[groupBy].forEach(function (association) {
        var items = controls.filter(function (item) {
          var _item$groupBy;
          return (_item$groupBy = item[groupBy]) === null || _item$groupBy === void 0 ? void 0 : _item$groupBy.find(function (element) {
            return element.id === association.id;
          });
        });
        result[association.id] = _this10.getNewGroup(items, association.title, association.id);
      });
      var noAssociationItems = controls.filter(function (control) {
        return control[groupBy] ? !control[groupBy].length : false;
      });
      var noAssociationPropName = 'no-association-group';
      result[noAssociationPropName] = this.getNewGroup(noAssociationItems, "".concat(this.translate.no, " ").concat(this[groupBy + 'Title']), noAssociationPropName);
      return result;
    },
    prepareGroupByTags: function prepareGroupByTags(controls) {
      var _this11 = this;
      var result = {};
      this.tags.forEach(function (tag) {
        var items = _this11.getFilteredByTag(controls, tag.id);
        var label = tag.tag;
        var servicePropName = tag.id;
        result[servicePropName] = _this11.getNewGroup(items, label, servicePropName);
      });
      var noTagItems = this.getFilteredNoTags(controls);
      var noTagLabel = this.translate.noTags;
      var noTagServicePropName = 'no-tags-group';
      result[noTagServicePropName] = this.getNewGroup(noTagItems, noTagLabel, noTagServicePropName);
      return result;
    },
    prepareGroupByMother: function prepareGroupByMother(controls) {
      var _this12 = this;
      var result = {};
      this.motherControls.forEach(function (activity) {
        var items = _this12.getFilteredByMother(controls, activity.cno);
        var label = activity.cpath + ' ' + activity.title;
        var servicePropName = activity.cno;
        result[servicePropName] = _this12.getNewGroup(items, label, servicePropName);
      });
      var noMotherItems = this.getFilteredNoMother(controls);
      var noMotherLabel = this.translate.noMotherControls;
      var noMotherServicePropName = 'no-mother-group';
      result[noMotherServicePropName] = this.getNewGroup(noMotherItems, noMotherLabel, noMotherServicePropName);
      return result;
    },
    prepareGroupByUser: function prepareGroupByUser(controls, key) {
      var _this13 = this;
      var result = {};
      this.users.forEach(function (user) {
        var items = _this13.getFilteredByUser(controls, user.id.toString(), key);
        var label = user.display_name;
        var servicePropName = user.id;
        result[servicePropName] = _this13.getNewGroup(items, label, servicePropName);
      });
      var noUserItems = this.getFilteredNoUser(controls, key);
      var noUserLabel = key === 'review' ? this.translate.noReviewer : this.translate.noResponsible;
      var noUserServicePropName = "no-".concat(key, "-group");
      result[noUserServicePropName] = this.getNewGroup(noUserItems, noUserLabel, noUserServicePropName);
      return result;
    },
    prepareGroupByOrganisation: function prepareGroupByOrganisation(controls) {
      var _this14 = this;
      var result = {};
      this.organisations.forEach(function (organisation) {
        var items = _this14.getFilteredByOrg(controls, organisation.id);
        var label = organisation.visible_name;
        var servicePropName = organisation.id;
        result[servicePropName] = _this14.getNewGroup(items, label, servicePropName);
      });
      return result;
    },
    getNewGroup: function getNewGroup(items, label, key) {
      return {
        name: MODULE_NAME,
        label: label,
        activities: items.slice(0),
        additionalFields: [ControlFields.RESPONSIBLE],
        hidden: false,
        id: key
      };
    },
    getFilteredByTag: function getFilteredByTag(controls, key) {
      return controls.filter(function (item) {
        var _item$tagIds;
        return (_item$tagIds = item.tagIds) === null || _item$tagIds === void 0 ? void 0 : _item$tagIds.includes(key);
      });
    },
    getFilteredNoTags: function getFilteredNoTags(controls) {
      return controls.filter(function (item) {
        var _item$tagIds2;
        return !((_item$tagIds2 = item.tagIds) !== null && _item$tagIds2 !== void 0 && _item$tagIds2.length);
      });
    },
    getFilteredByMother: function getFilteredByMother(controls, cno) {
      return controls.filter(function (item) {
        return item.mothercontrolId && item.mothercontrolId === cno;
      });
    },
    getFilteredNoMother: function getFilteredNoMother(controls) {
      return controls.filter(function (item) {
        return !item.mothercontrolId;
      });
    },
    getFilteredByUser: function getFilteredByUser(controls, id, key) {
      return controls.filter(function (item) {
        var _item$userIds;
        return item === null || item === void 0 || (_item$userIds = item.userIds) === null || _item$userIds === void 0 ? void 0 : _item$userIds[key].includes(id);
      });
    },
    getFilteredNoUser: function getFilteredNoUser(controls, key) {
      return controls.filter(function (item) {
        var _item$userIds2;
        return !(item !== null && item !== void 0 && (_item$userIds2 = item.userIds) !== null && _item$userIds2 !== void 0 && _item$userIds2[key].length);
      });
    },
    getFilteredByOrg: function getFilteredByOrg(controls, id) {
      return controls.filter(function (item) {
        return item === null || item === void 0 ? void 0 : item.organisationIds.includes(id);
      });
    },
    resetFilters: function resetFilters() {
      this.filters = {
        organisations: [],
        tags: [],
        mother: null,
        responsible: [],
        search: '',
        deadline: null,
        accounts: [],
        risks: [],
        processes: [],
        showDeactivated: false,
        groupBy: null,
        showOverviewOptions: true
      };
    },
    setView: function setView(view) {
      this.currentView = view;
    },
    showCreationModal: function showCreationModal() {
      var isMotherControl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.isCreationModalShow = true;
      this.isMotherCreationMode = isMotherControl;
    },
    updateFromPreviewMode: function updateFromPreviewMode(event) {
      var _this$updatedControlI, _this$controls$activi;
      this.updatedControlFromPreview = event;
      if (this.updatedControlFromPreview.isMothercontrol) return;
      var id = (_this$updatedControlI = this.updatedControlId) !== null && _this$updatedControlI !== void 0 ? _this$updatedControlI : this.updatedControlFromPreview.id;
      var activityIdx = this.controls.findIndex(function (control) {
        return control.id === id;
      });
      if (activityIdx === -1) return;
      if (((_this$controls$activi = this.controls[activityIdx]) === null || _this$controls$activi === void 0 ? void 0 : _this$controls$activi.cpath) !== event.cpath) {
        this.controls[activityIdx].cpath = event.cpath;
      }
    },
    onCompleteControl: function onCompleteControl(_ref3) {
      var updatedControlId = _ref3.updatedControlId;
      this.updatedControlId = updatedControlId;
    },
    dismissPreviewMode: function dismissPreviewMode() {
      var _this$updatedControlI2;
      if (!this.updatedControlFromPreview) return;
      var isMother = this.updatedControlFromPreview.isMothercontrol;
      var activities = isMother ? this.motherControls : this.controls;
      var id = (_this$updatedControlI2 = this.updatedControlId) !== null && _this$updatedControlI2 !== void 0 ? _this$updatedControlI2 : this.updatedControlFromPreview.id;
      var activityIdx = activities.findIndex(function (control) {
        return control.id === id;
      });
      if (activityIdx === -1) return;
      if (isMother) {
        this.motherControls[activityIdx] = this.updatedControlFromPreview;
      } else {
        this.controls[activityIdx] = this.updatedControlFromPreview;
      }
      this.updatedControlFromPreview = null;
      this.updatedControlId = null;
    },
    generateKey: function generateKey() {
      return Math.random().toString(20);
    }
  }
};