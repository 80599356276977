var _OptionalFieldsLabels;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var OptionalFields = {
  DESCRIPTION: 'description',
  STARTDATE: 'startDate',
  EXPIRATIONDATE: 'expirationDate',
  NOTIFICATION_UPON_EXPIRATION_DATE: 'notificationUponExpirationDate',
  RENEWAL_DEADLINE: 'renewalDeadline',
  NOTIFICATION_UPON_RENEWAL_DEADLINE: 'notificationUponRenewalDeadline',
  RENEWAL_AMOUNT: 'renewalAmount',
  TERMINATION_DEADLINE: 'terminationDeadline',
  NOTIFICATION_UPON_TERMINATION_DEADLINE: 'notificationUponTerminationDeadline',
  AUTO_RENEWAL: 'autoRenewal',
  AMOUNT: 'amount',
  PAYMENT_INTERVAL: 'paymentInterval',
  CONTRACT_VERSION: 'contractVersion',
  STATUS_CHANGENOTE: 'statusChangeNote'
};
export var OptionalFieldsLabels = (_OptionalFieldsLabels = {}, _defineProperty(_OptionalFieldsLabels, OptionalFields.DESCRIPTION, 'Description'), _defineProperty(_OptionalFieldsLabels, OptionalFields.STARTDATE, 'Start date'), _defineProperty(_OptionalFieldsLabels, OptionalFields.EXPIRATIONDATE, 'Expiration date'), _defineProperty(_OptionalFieldsLabels, OptionalFields.NOTIFICATION_UPON_EXPIRATION_DATE, 'Notification upon expiration date'), _defineProperty(_OptionalFieldsLabels, OptionalFields.RENEWAL_DEADLINE, 'Renewal deadline'), _defineProperty(_OptionalFieldsLabels, OptionalFields.NOTIFICATION_UPON_RENEWAL_DEADLINE, 'Notification upon renewal deadline'), _defineProperty(_OptionalFieldsLabels, OptionalFields.RENEWAL_AMOUNT, 'Renewal amount'), _defineProperty(_OptionalFieldsLabels, OptionalFields.TERMINATION_DEADLINE, 'Termination deadline'), _defineProperty(_OptionalFieldsLabels, OptionalFields.NOTIFICATION_UPON_TERMINATION_DEADLINE, 'Notification upon termination deadline'), _defineProperty(_OptionalFieldsLabels, OptionalFields.AUTO_RENEWAL, 'Auto renewal'), _defineProperty(_OptionalFieldsLabels, OptionalFields.AMOUNT, 'Amount'), _defineProperty(_OptionalFieldsLabels, OptionalFields.PAYMENT_INTERVAL, 'Payment interval'), _defineProperty(_OptionalFieldsLabels, OptionalFields.CONTRACT_VERSION, 'Contract version'), _defineProperty(_OptionalFieldsLabels, OptionalFields.STATUS_CHANGENOTE, 'Status change note'), _OptionalFieldsLabels);