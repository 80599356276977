import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
export default {
  name: '',
  components: {
    RadioButtons: RadioButtons
  },
  data: function data() {
    return {
      options: [{
        value: 1,
        label: 'Yes'
      }, {
        value: 2,
        label: 'No'
      }, {
        value: 3,
        label: 'Maybe yes, maybe no'
      }],
      value: false
    };
  },
  methods: {
    handleChange: function handleChange(value) {
      console.log('RadioButtons handler', value); // eslint-disable-line
    }
  }
};