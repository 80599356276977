import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1f69e91e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "flex items-end my-2"
};
var _hoisted_2 = {
  class: "mb-2"
};
var _hoisted_3 = {
  key: 0,
  class: "italic"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  class: "text-center"
};
var _hoisted_6 = {
  class: "text-center"
};
var _hoisted_7 = {
  class: "text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_trafficlight = _resolveComponent("trafficlight");
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("tr", null, [_createElementVNode("td", null, [_createVNode(_component_preview_modal_link, {
    activity: $data.getPreview($props.gap)
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($props.gap.nodeTitle), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_trafficlight, {
    color: $options.gapColor.color,
    title: _ctx.$trans($options.gapColor.label),
    class: "mr-1"
  }, null, 8 /* PROPS */, ["color", "title"]), _createElementVNode("span", null, _toDisplayString($props.gap.headline) + " " + _toDisplayString($props.gap.subHeadline), 1 /* TEXT */)]), _createElementVNode("p", _hoisted_2, [_createElementVNode("span", null, _toDisplayString($props.gap.analysis), 1 /* TEXT */), !$props.gap.analysis ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString($data.translate.thisHasNotBeenAnsweredYet), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("small", null, [_createTextVNode(_toDisplayString($data.translate.requirement) + ": ", 1 /* TEXT */), _createElementVNode("span", null, [_createTextVNode(_toDisplayString($props.gap.requirement.substr(0, 200)) + " ", 1 /* TEXT */), $props.gap.requirement.length > 200 ? (_openBlock(), _createElementBlock("span", _hoisted_4, "...")) : _createCommentVNode("v-if", true)])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.gap.attributes, function (attributes, key) {
    return _openBlock(), _createElementBlock("div", {
      key: key,
      class: "flex flex-wrap items-center"
    }, [_createVNode(_component_risma_title, {
      title: key + ':',
      class: "font-semibold mr-1",
      type: "small"
    }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attributes, function (attribute, idx) {
      return _openBlock(), _createElementBlock("span", {
        key: idx,
        class: "text-title-small text-gray-750"
      }, _toDisplayString(attribute + (attributes.length > idx + 1 ? ', ' : '')), 1 /* TEXT */);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */))]), $props.showRiskAssessment ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createElementVNode("td", _hoisted_5, _toDisplayString($props.gap.likelihoodGross) + " / " + _toDisplayString($props.gap.likelihoodNet), 1 /* TEXT */), _createElementVNode("td", _hoisted_6, _toDisplayString($props.gap.impactGross) + " / " + _toDisplayString($props.gap.impactNet), 1 /* TEXT */), _createElementVNode("td", _hoisted_7, _toDisplayString($props.gap.rpiGross) + " / " + _toDisplayString($props.gap.rpiNet), 1 /* TEXT */)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)]);
}