import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex justify-between mb-2"
};
var _hoisted_2 = {
  class: "flex justify-end relative"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");
  var _component_notification_pop_up = _resolveComponent("notification-pop-up");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_pagesize = _resolveComponent("risma-pagesize");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_information_asset_creation_modal = _resolveComponent("information-asset-creation-modal");
  var _component_information_assets_simple_view = _resolveComponent("information-assets-simple-view");
  var _component_information_assets_extended_view = _resolveComponent("information-assets-extended-view");
  var _component_pagination = _resolveComponent("pagination");
  var _component_main_well = _resolveComponent("main-well");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return _openBlock(), _createElementBlock("div", null, [_ctx.hasNotificationPopUp ? (_openBlock(), _createBlock(_component_notification_pop_up, {
    key: 0,
    onDismiss: _ctx.dismissNotificationPopUp
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.yourActivityHasBeenCreated), 1 /* TEXT */), _createVNode(_component_router_link, {
        to: _ctx.notificationData.url
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.notificationData.title), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $options.title
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
    modelValue: $data.filterString,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $data.filterString = $event;
    }), $options.onSearch],
    placeholder: $data.translate.search,
    type: "text",
    class: "w-200px"
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "onUpdate:modelValue"])]), $data.isLoaded ? (_openBlock(), _createBlock(_component_main_well, {
    key: 1,
    class: "h-auto"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", _hoisted_2, [_createVNode(_component_risma_pagesize, {
        modelValue: $data.pageSize,
        "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
          return $data.pageSize = $event;
        }), $options.setPageSizeLocalState],
        "paging-options": $options.pagingOptions,
        class: "mr-auto mb-2"
      }, null, 8 /* PROPS */, ["modelValue", "paging-options", "onUpdate:modelValue"]), _createVNode(_component_feather_icon, {
        icon: "maximize-2",
        height: "30",
        width: "30",
        class: "cursor-pointer",
        onClick: $options.switchView
      }, null, 8 /* PROPS */, ["onClick"]), _createVNode(_component_feather_icon, {
        icon: "plus",
        height: "30",
        width: "30",
        class: "cursor-pointer",
        onClick: _withModifiers($options.toggleCreateModal, ["stop"])
      }, null, 8 /* PROPS */, ["onClick"]), $data.showCreateModal ? (_openBlock(), _createBlock(_component_information_asset_creation_modal, {
        key: 0,
        type: $props.typeId,
        "data-processors": $data.informationAssets,
        "activity-type-id": $options.activityType && $options.activityType.id,
        "default-questionnaire-id": $options.activityType && $options.activityType.defaultQuestionnaireId || '',
        "set-current-user-as-owner": $props.onlyMy,
        onDismiss: $options.toggleCreateModal,
        onCreated: $options.createIA
      }, null, 8 /* PROPS */, ["type", "data-processors", "activity-type-id", "default-questionnaire-id", "set-current-user-as-owner", "onDismiss", "onCreated"])) : _createCommentVNode("v-if", true)]), !$options.showExtended ? (_openBlock(), _createBlock(_component_information_assets_simple_view, {
        key: 0,
        "type-id": $props.typeId,
        "information-assets": $data.informationAssets,
        users: _ctx.users,
        "activity-type": $options.activityType,
        onRefreshData: _cache[2] || (_cache[2] = function ($event) {
          return $options.getData($props.typeId);
        }),
        onSearch: $options.onSearch
      }, null, 8 /* PROPS */, ["type-id", "information-assets", "users", "activity-type", "onSearch"])) : (_openBlock(), _createBlock(_component_information_assets_extended_view, {
        key: 1,
        "type-id": $props.typeId,
        "information-assets": $data.informationAssets,
        users: _ctx.users,
        "confirm-export-message": $options.confirmExportMessage,
        "confirm-print-message": $options.confirmPrintMessage,
        class: "mt-5",
        onRefreshData: _cache[3] || (_cache[3] = function ($event) {
          return $options.getData($props.typeId);
        }),
        onBackendSort: $options.backendSortRequest
      }, null, 8 /* PROPS */, ["type-id", "information-assets", "users", "confirm-export-message", "confirm-print-message", "onBackendSort"])), _createVNode(_component_pagination, {
        total: $data.totalAssets,
        "per-page": $data.pageSize,
        "show-prev-next-buttons": true,
        onChange: $options.pageChange
      }, null, 8 /* PROPS */, ["total", "per-page", "onChange"])];
    }),
    _: 1 /* STABLE */
  })) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 2
  }))]);
}