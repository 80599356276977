import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex -mb-px"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_light_tab_item = _resolveComponent("light-tab-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_light_tab_item, {
    to: "/compliance/dashboard/".concat(_ctx.$route.params.projectId, "/questions")
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.questions), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"]), _createVNode(_component_light_tab_item, {
    to: "/compliance/dashboard/".concat(_ctx.$route.params.projectId, "/gap")
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.gap), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])]);
}