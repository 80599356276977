export function gapsToInitiatives(gapList) {
  var initiativeList = [];
  gapList.forEach(function (gap) {
    if (gap.links.execution !== undefined) {
      gap.links.execution.forEach(function (initiative) {
        if (!isInList(initiative, initiativeList)) {
          initiativeList.push(initiative);
          initiativeList[initiativeList.length - 1].gap = findGaps(initiative, gapList);
        }
      });
    }
  });
  return initiativeList.filter(function (item) {
    return item !== null;
  });
}
export function gapsToControls(gapList) {
  var controlList = [];
  gapList.forEach(function (gap) {
    if (gap.links.control !== undefined) {
      gap.links.control.forEach(function (control) {
        if (!isInList(control, controlList)) {
          controlList.push(control);
        }
      });
    }
  });
  return controlList.filter(function (item) {
    return item !== null;
  });
}
export function addGapsToControls(controls, gapList) {
  controls.forEach(function (control) {
    control.gap = findGaps(control, gapList);
  });
}
function isInList(activity, list) {
  var bool = false;
  if (activity === null) {
    return false;
  }
  list = list.filter(function (item) {
    return item !== null;
  });
  list.forEach(function (item) {
    if (item.ipath !== undefined && item.ipath === activity.ipath || item.id !== undefined && item.id === activity.id) {
      bool = true;
    }
  });
  return bool;
}
function findGaps(activity, gapList) {
  return gapList.filter(function (item) {
    var bool = false;
    if (item.links.execution !== undefined) {
      item.links.execution.forEach(function (initiative) {
        if (initiative.ipath === activity.ipath) {
          bool = true;
        }
      });
    }
    if (item.links.control !== undefined) {
      item.links.control.forEach(function (control) {
        if (control.cpath === activity.cpath) {
          bool = true;
        }
      });
    }
    return bool;
  });
}
export function nullKeysToString(myObject) {
  Object.keys(myObject).map(function (key) {
    myObject[key] = myObject[key] === null ? '' : myObject[key];
  });
  return myObject;
}