export var htmlLegendPlugin = {
  id: 'htmlLegend',
  afterInit: function afterInit(chart, args, options) {
    var legendContainer = document.getElementById(options.containerId);
    legendContainer.onclick = function (event) {
      var dataIndex = event.target.dataset.index || event.target.parentNode.dataset.index;
      if (dataIndex === undefined) return;
      chart.toggleDataVisibility(dataIndex);
      chart.update();
      options.callback(dataIndex);
    };
  }
};