import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "p-4 border border-gray-450"
};
var _hoisted_2 = {
  class: "flex justify-between items-center"
};
var _hoisted_3 = {
  key: 0,
  class: "flex flex-wrap mt-3 -mb-6"
};
var _hoisted_4 = {
  class: "mr-5/100 mb-6 min-w-35rem0 border-b border-gray-350"
};
var _hoisted_5 = {
  class: "max-h-250px overflow-auto"
};
var _hoisted_6 = {
  class: "mr-5/100 mb-6 min-w-35rem0 border-b border-gray-350"
};
var _hoisted_7 = {
  class: "max-h-250px overflow-auto"
};
var _hoisted_8 = {
  class: "min-w-35rem0 mb-6 border-b border-gray-350"
};
var _hoisted_9 = {
  class: "max-h-250px overflow-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.changesSinceLastScoringPeriod, " (").concat($props.scoring.previousName, ")"),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
    icon: $data.showScoring ? 'chevron-up' : 'chevron-down',
    class: "stroke-blue stroke-3 cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.showScoring = !$data.showScoring;
    })
  }, null, 8 /* PROPS */, ["icon"])]), $data.showScoring ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $options.newRisksTitle,
    type: "medium",
    class: "pb-1 border-b border-gray-350"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("ul", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.scoring.newRisks, function (risk, index) {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      class: "pr-2 hover:underline"
    }, [_createVNode(_component_router_link, {
      to: risk.url
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString($options.getRiskFullTitle(risk)), 1 /* TEXT */)];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])]);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_title, {
    title: $options.movedRisksTitle,
    type: "medium",
    class: "pb-1 border-b border-gray-350"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("ul", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.scoring.movedRisks, function (risk, index) {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      class: "pr-2 hover:underline"
    }, [_createVNode(_component_router_link, {
      to: risk.url
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString($options.getRiskFullTitle(risk, true)), 1 /* TEXT */)];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])]);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: $options.closedRisksTitle,
    type: "medium",
    class: "pb-1 border-b border-gray-350"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("ul", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.scoring.closedRisks, function (risk, index) {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      class: "pr-2 hover:underline"
    }, [_createVNode(_component_router_link, {
      to: risk.url
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString($options.getRiskFullTitle(risk)), 1 /* TEXT */)];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])]);
  }), 128 /* KEYED_FRAGMENT */))])])])) : _createCommentVNode("v-if", true)]);
}