import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "flex"
};
var _hoisted_3 = {
  key: 1,
  class: "table-tmp"
};
var _hoisted_4 = {
  class: "m-2 w-full table-fixed"
};
var _hoisted_5 = {
  class: "border-t border-gray-350"
};
var _hoisted_6 = {
  class: "m-auto w-9.5rem text-center p-2"
};
var _hoisted_7 = {
  class: "w-32 p-2 text-left border-t border-gray-350"
};
var _hoisted_8 = {
  class: "p-2 text-left"
};
var _hoisted_9 = {
  class: "break-words p-2 text-left"
};
var _hoisted_10 = {
  class: "m-auto w-32 text-center p-2"
};
var _hoisted_11 = ["onUpdate:modelValue", "onClick"];
var _hoisted_12 = {
  class: "w-32 p-2"
};
var _hoisted_13 = {
  class: "p-2"
};
var _hoisted_14 = {
  key: 1
};
var _hoisted_15 = {
  class: "break-words p-2"
};
var _hoisted_16 = ["innerHTML"];
var _hoisted_17 = {
  key: 2,
  class: "text-center font-semibold my-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_notification = _resolveComponent("notification");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      var _ctx$notification;
      return [_createVNode(_component_risma_title, {
        title: $data.translate.switchActivitiesFromOneOrganisationToAnother
      }, null, 8 /* PROPS */, ["title"]), (_ctx$notification = _ctx.notification) !== null && _ctx$notification !== void 0 && _ctx$notification.message ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: _ctx.notification.type,
        class: "whitespace-pre-line",
        onDismiss: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.notification.message = '';
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.notification.message), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_select, {
        modelValue: _ctx.selectedModules,
        "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
          return _ctx.selectedModules = $event;
        }), $options.findData],
        class: "w-full mx-3",
        options: _ctx.allModules,
        placeholder: $data.translate.chooseModules,
        "label-key": "option"
      }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder", "onUpdate:modelValue"]), _createVNode(_component_single_select, {
        modelValue: $data.selectedOrganisationId,
        "onUpdate:modelValue": [_cache[2] || (_cache[2] = function ($event) {
          return $data.selectedOrganisationId = $event;
        }), $options.findData],
        class: "mx-3",
        options: $options.allOrganisations,
        disabled: $options.organisationLock,
        "with-search": true,
        placeholder: $data.translate.chooseOrganisationToUnassignActivitiesFrom
      }, null, 8 /* PROPS */, ["modelValue", "options", "disabled", "placeholder", "onUpdate:modelValue"]), _createVNode(_component_single_select, {
        modelValue: $data.selectedToOrganisationId,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.selectedToOrganisationId = $event;
        }),
        options: $options.filteredOrganisations,
        disabled: $options.organisationToLock,
        "with-search": true,
        placeholder: $data.translate.chooseOrganisationToAssignActivitiesTo
      }, null, 8 /* PROPS */, ["modelValue", "options", "disabled", "placeholder"]), _createVNode(_component_risma_button, {
        text: $data.translate.submit,
        disabled: $options.submitButtonLock,
        type: "save",
        onClick: $options.updateData
      }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])]), _ctx.tableData.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("table", _hoisted_4, [_createElementVNode("thead", null, [_createElementVNode("tr", _hoisted_5, [_createElementVNode("th", _hoisted_6, [_withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return _ctx.selectAllActivities = $event;
        }),
        class: "mr-5 p-2 bg-gray-450 w-5 h-5 appearance-none",
        type: "checkbox",
        onClick: _cache[5] || (_cache[5] = function ($event) {
          return _ctx.toggleAll($event.target.checked);
        })
      }, null, 512 /* NEED_PATCH */), [[_vModelCheckbox, _ctx.selectAllActivities]]), _createTextVNode(" " + _toDisplayString($data.translate.selectdeselectAllActivities), 1 /* TEXT */)]), _createElementVNode("th", _hoisted_7, [_createVNode(_component_risma_title, {
        title: $data.translate.module,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_8, [_createVNode(_component_risma_title, {
        title: $data.translate.title,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_9, [_createVNode(_component_risma_title, {
        title: $data.translate.description,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])])])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, function (item, index) {
        return _openBlock(), _createElementBlock("tr", {
          key: "table-item-".concat(index),
          class: "border-t border-gray-350"
        }, [_createElementVNode("td", _hoisted_10, [_withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return _ctx.checkGroup[index] = $event;
          },
          class: "mr-5 p-2 bg-gray-450 w-5 h-5 appearance-none",
          type: "checkbox",
          onClick: function onClick($event) {
            return _ctx.onActivityToggle($event.target.checked, index);
          }
        }, null, 8 /* PROPS */, _hoisted_11), [[_vModelCheckbox, _ctx.checkGroup[index]]])]), _createElementVNode("td", _hoisted_12, _toDisplayString(item.moduleTitle), 1 /* TEXT */), _createElementVNode("td", _hoisted_13, [item.url ? (_openBlock(), _createBlock(_component_preview_modal_link, {
          key: 0,
          activity: item,
          onUpdated: _cache[6] || (_cache[6] = function ($event) {
            return _ctx.itemToUpdate = $event;
          }),
          onDeleteItem: _ctx.deleteFromPreviewModal,
          onDismissModal: _ctx.updateFromPreviewModal
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(_ctx.getTitle(item)), 1 /* TEXT */)];
          }),

          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onDeleteItem", "onDismissModal"])) : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.getTitle(item)), 1 /* TEXT */))]), _createElementVNode("td", _hoisted_15, [_createElementVNode("div", {
          innerHTML: _ctx.$truncate(item.description, 100)
        }, null, 8 /* PROPS */, _hoisted_16)])]);
      }), 128 /* KEYED_FRAGMENT */))])])])) : _createCommentVNode("v-if", true), _ctx.hasNoRecordsTitle ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString($data.translate.noRecordsMatching), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })]);
}