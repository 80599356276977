import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-49a48d1b"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_static_table = _resolveComponent("static-table");
  return !!$props.selectedType ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.templates,
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), $options.formattedTemplates.length ? (_openBlock(), _createBlock(_component_static_table, {
    key: 0,
    columns: $options.templateColumns,
    dataset: $options.formattedTemplates
  }, {
    create: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createVNode(_component_risma_button, {
        text: $data.translate.create,
        onClick: function onClick($event) {
          return $options.createQuestionnaireFromTemplate(slotData);
        }
      }, null, 8 /* PROPS */, ["text", "onClick"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["columns", "dataset"])) : (_openBlock(), _createBlock(_component_risma_title, {
    key: 1,
    title: $data.translate.thereAreNoTemplatesOfTheSelectedType,
    type: "medium",
    class: "empty-message"
  }, null, 8 /* PROPS */, ["title"]))])) : _createCommentVNode("v-if", true);
}