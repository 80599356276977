import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_static_table, {
    "visibility-switch": false,
    dataset: $options.dataset,
    columns: $options.columnsTrans
  }, {
    title: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createVNode(_component_router_link, {
        to: slotData.url
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(slotData.title), 1 /* TEXT */)];
        }),

        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset", "columns"])]);
}