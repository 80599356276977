import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getAllWelcomePages() {
  return handleRequestResponse(Http.get('/admin/welcomepages'));
}
export function createWelcomePage(contentHtml, name) {
  return handleRequestResponse(Http.post('/admin/welcomepages', {
    contentHtml: contentHtml,
    name: name
  }));
}
export function updateWelcomePage(id, contentHtml, name) {
  return handleRequestResponse(Http.patch("/admin/welcomepages/".concat(id), {
    contentHtml: contentHtml,
    name: name
  }));
}