export default {
  name: 'TextSelector',
  description: 'Dropdown selector from text',
  token: '<text-selector v-model="value" :options="options">{{ }}</text-selector>',
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
      required: false
    },
    options: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data: function data() {
    return {
      selectActive: false
    };
  },
  computed: {
    filterValue: {
      get: function get() {
        return this.modelValue;
      },
      set: function set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  methods: {
    activateSelect: function activateSelect() {
      var _this = this;
      this.selectActive = true;
      this.$nextTick(function () {
        _this.$refs.select.focus();
      });
    },
    deactivateSelect: function deactivateSelect() {
      this.selectActive = false;
    }
  }
};