function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { Colors } from '@/Colors';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { ControlButtonTypes } from '@/constants/ControlButtonTypes';
import { ControlFields } from '@/constants/ControlFields';
import { ControlViews } from '@/constants/ControlViews';
import { DateFormats } from '@/constants/DateFormats';
import * as Utils from '@/utils/Utils';
import { prepareDateTimezone } from '@/utils/date';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { isSignoffPeriodPending as _isSignoffPeriodPending } from '@/utils/controls/utils';
export default {
  name: 'CardControl',
  introduction: 'Control card component',
  description: 'Single control activitiy. Can have 3 view: tiles, compact or expanded.',
  token: '<card-controls :activity="item" :view="expanded"></card-controls>',
  components: {
    TrafficLight: TrafficLight,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton
  },
  props: {
    activity: {
      type: Object,
      required: true,
      note: 'Control object'
    },
    view: {
      type: String,
      default: ControlViews.TILES,
      required: false,
      note: 'Support 3 view type: tiles, compact or expanded'
    },
    buttonType: {
      type: String,
      default: '',
      required: false,
      note: 'Control button (Complete control or Review control)'
    },
    additionalFields: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: false,
      note: 'Additional fields to display (Reviewers, Completed, Review Deadline, etc..)'
    },
    organisations: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma organisations'
    }
  },
  emits: ['buttonClick'],
  data: function data() {
    return {
      Colors: Colors,
      ControlButtonTypes: ControlButtonTypes,
      ControlViews: ControlViews,
      ControlFields: ControlFields,
      isMotherControl: false,
      defaultTrafficLight: Colors.getTrafficlightByNumber(0).color,
      completeControlType: 'completeControl',
      completeControlAgainType: 'completeControlAgain',
      translate: getTranslate['CardControl']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isRed: function isRed() {
      return this.activity.trafficLight === 1 && !this.isMotherControl && !this.activity._withoutTrafficLight;
    },
    classObject: function classObject() {
      return {
        'card-controls-wrap': true,
        'inactive': !this.activity.active,
        'red': this.isRed
      };
    },
    controlTitle: function controlTitle() {
      return "".concat(this.activity.cpath, " ").concat(this.activity.title);
    },
    controlLink: function controlLink() {
      return "/controls2/controls/".concat(this.activity.cpath, "/description");
    },
    deadlineFormat: function deadlineFormat() {
      var timezone = this.getTimezoneProperty(this.activity);
      return prepareDateTimezone(this.activity.deadline, timezone, DateFormats.DATE_FORMAT);
    },
    reviewersWithLabel: function reviewersWithLabel() {
      return this.activity.userIdsInfo.review ? "".concat(this.translate.reviewer, ": ").concat(this.activity.userIdsInfo.review) : '';
    },
    responsiblesWithLabel: function responsiblesWithLabel() {
      return this.activity.userIdsInfo.responsible ? "".concat(this.translate.responsible, ": ").concat(this.activity.userIdsInfo.responsible) : '';
    },
    completedFormat: function completedFormat() {
      if (this.activity.completed) {
        var timezone = this.getTimezoneProperty(this.activity);
        return prepareDateTimezone(this.activity.completed, timezone, DateFormats.DATE_TIME_FORMAT);
      } else {
        return '';
      }
    },
    reviewDeadlineFormat: function reviewDeadlineFormat() {
      if (this.activity.reviewDeadline) {
        var timezone = this.getTimezoneProperty(this.activity);
        return prepareDateTimezone(this.activity.reviewDeadline, timezone, DateFormats.DATE_TIME_FORMAT);
      } else {
        return this.translate.notSet;
      }
    },
    deadlineWithLabel: function deadlineWithLabel() {
      return "".concat(this.translate.deadline, ": ").concat(this.deadlineFormat);
    },
    completedWithLabel: function completedWithLabel() {
      return "".concat(this.translate.completed, ": ").concat(this.completedFormat);
    },
    reviewDeadlineWithLabel: function reviewDeadlineWithLabel() {
      return "".concat(this.translate.reviewDeadline, ": ").concat(this.reviewDeadlineFormat);
    },
    daughterControls: function daughterControls() {
      var result = [];
      if (this.isMotherControl && this.activity.daughterControls) {
        result = this.activity.daughterControls.map(function (item) {
          return "".concat(item.cpath, " ").concat(item.title);
        });
      }
      return result.join(', ');
    },
    trafficLight: function trafficLight() {
      if (this.activity.isMothercontrol) return Colors.getTrafficlightByNumber(0).color;
      return Colors.getTrafficlightByNumber(this.activity.trafficLight).color;
    },
    organisationNames: function organisationNames() {
      var _this = this;
      var result = [];
      if (this.activity.organisationIds) {
        this.activity.organisationIds.map(function (id) {
          _this.organisations.map(function (org) {
            if (id === org.id) {
              result.push(org.visible_name);
            }
          });
        });
      }
      return result.join(', ');
    },
    isSignoffPeriodPending: function isSignoffPeriodPending() {
      return _isSignoffPeriodPending(this.activity.signoffStart, this.activity.frequency, this.activity.trafficLight, this.getSettingValue);
    }
  }),
  mounted: function mounted() {
    this.isMotherControl = this.findIsMotherControl();
  },
  methods: {
    findIsMotherControl: function findIsMotherControl() {
      return this.activity.isMothercontrol === 1;
    },
    getTimezoneProperty: function getTimezoneProperty(item) {
      return item.deadlineTimezone;
    },
    reviewControl: function reviewControl() {
      if (this.getSettingValue('app.module_controls2_review_enabled') !== 1) {
        var _url = RismaControlsUtils.prepareReviewLink(this.activity);
        return this.$router.push({
          path: _url
        });
      }
      var url = RismaControlsUtils.prepareControlsTwoReviewLink(this.activity);
      return this.$router.push({
        path: url
      });
    },
    buttonClick: function buttonClick(type, item) {
      if (type === this.completeControlType || type === this.completeControlAgainType) {
        var completeEvent = RismaControlsUtils.prepareCompleteButtonEvent(type, item);
        this.$emit('buttonClick', completeEvent);
      }
    },
    truncateHtml: function truncateHtml(text, maxSymbols) {
      return Utils.truncateHtml(text, maxSymbols, {
        html: true,
        maxLines: 3
      });
    }
  }
};