export default {
  name: 'ReportClearResult',
  introduction: 'A simple component to display a button for clearing search results.',
  description: 'When clicking the button in this component. A event (handleClearResults) will emit an event to the parent component.',
  token: '<report-clear-result/>',
  emits: ['handleClearResults'],
  data: function data() {
    return {
      translate: {
        clearSearchResults: this.$trans('Clear search results')
      }
    };
  },
  methods: {
    handleClearResults: function handleClearResults() {
      this.$emit('handleClearResults');
    }
  }
};