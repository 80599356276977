function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { MODULES } from '@/constants/modules';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
export default {
  name: 'PreviewDataImport',
  components: {
    TrafficLight: TrafficLight,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton
  },
  props: {
    previewFields: {
      type: Object,
      required: true
    },
    previewData: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    routeModule: {
      type: String,
      required: true
    }
  },
  emits: ['loadData', 'error'],
  data: function data() {
    return {
      translate: getTranslate['PreviewDataImport'](),
      currentModule: null,
      files: null
    };
  },
  computed: {
    normalWhitespaceList: function normalWhitespaceList() {
      var initial = ['message', 'description', 'responsible', 'accountable', 'consulted', 'informed', 'organisations'];
      return [].concat(initial, _toConsumableArray(Object.keys(this.currentPreviewFields).filter(function (field) {
        return field.startsWith('c-');
      })));
    },
    currentPreviewFields: function currentPreviewFields() {
      return this.previewFields[this.currentModule] || [];
    },
    currentPreviewData: function currentPreviewData() {
      return this.previewData[this.currentModule] || [];
    },
    importIsPossible: function importIsPossible() {
      return this.currentPreviewData.every(function (item) {
        return item.success;
      });
    },
    modules: function modules() {
      return MODULES;
    },
    moduleOptions: function moduleOptions() {
      var _ref;
      return _ref = {}, _defineProperty(_ref, MODULES.CONTROLS, {
        title: this.translate.controlsImport,
        fileSource: '/examples/ControlsImportExample.xlsx'
      }), _defineProperty(_ref, MODULES.CONTRACTS, {
        title: this.translate.contractImport,
        fileSource: '/examples/ContractImportExample.xlsx'
      }), _defineProperty(_ref, MODULES.COMPLIANCE_INFORMATION_ASSETS, {
        title: this.translate.informationAssetsImport,
        fileSource: '/examples/AssetsImportExample.xlsx'
      }), _defineProperty(_ref, MODULES.RISK, {
        title: this.translate.riskImport,
        fileSource: '/examples/RisksImportExample.xlsx'
      }), _ref;
    }
  },
  watch: {
    routeModule: function routeModule() {
      this.checkModuleExistence();
      this.$emit('loadData', null);
    }
  },
  created: function created() {
    this.checkModuleExistence();
  },
  methods: {
    onImportButtonClick: function onImportButtonClick() {
      if (!this.files) return;
      this.upload(this.files, false);
    },
    onFilesLoad: function onFilesLoad(event) {
      var _event$target;
      if (!((_event$target = event.target) !== null && _event$target !== void 0 && _event$target.files)) return;
      this.files = event.target.files;
      this.upload(this.files);
    },
    upload: function upload(files) {
      var preview = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (!preview && !this.importIsPossible) {
        this.$emit('error', this.translate.pleaseCorrectTheErrorsInTheSubmittedImport);
      } else {
        this.$emit('loadData', {
          module: this.currentModule,
          files: files,
          params: {
            preview: preview
          }
        });
      }
    },
    dropExistingFile: function dropExistingFile(e) {
      if (e.target.value) {
        e.target.value = null;
      }
    },
    checkModuleExistence: function checkModuleExistence() {
      if (this.routeModule in this.moduleOptions) {
        this.currentModule = this.routeModule;
      } else {
        this.$router.replace({
          name: 'ExcelDataImport'
        });
      }
    }
  }
};