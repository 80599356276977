import { defineStore } from 'pinia';
import { getCurrentUser, getLoginInfo } from '../api/risma/data';
import { ObjectTypes } from '../constants/ObjectTypes';
import { RiskProjects } from '@/constants/risks/RiskProjects';
export var useUserStore = defineStore('userStore', {
  state: function state() {
    return {
      currentUser: {},
      userIsLoggedIn: false
    };
  },
  getters: {
    accessLevelByModule: function accessLevelByModule() {
      var _this = this;
      return function (moduleName) {
        return Object.assign({}, _this.currentUser.AccessLevels[moduleName]);
      };
    },
    accessLevelByProjectId: function accessLevelByProjectId() {
      var _this2 = this;
      return function (projectId) {
        return _this2.currentUser.projects.find(function (project) {
          return project.id === +projectId;
        }).access_level;
      };
    },
    showGapByProjectId: function showGapByProjectId() {
      var _this3 = this;
      return function (projectId) {
        return _this3.complianceProjects.find(function (project) {
          return project.id === +projectId;
        }).show_gap;
      };
    },
    complianceProjects: function complianceProjects() {
      return this.currentUser.projects.filter(function (project) {
        return project.objType === ObjectTypes.COMPLIANCE_PROJECT;
      });
    },
    complianceProjectById: function complianceProjectById() {
      var _this4 = this;
      return function (projectId) {
        return _this4.complianceProjects.find(function (project) {
          return project.id === +projectId;
        }) || {};
      };
    },
    complianceAccessLevelByProjectId: function complianceAccessLevelByProjectId() {
      var _this5 = this;
      return function (projectId) {
        return _this5.complianceProjects.find(function (project) {
          return project.id === +projectId;
        }).access_level;
      };
    },
    riskProjects: function riskProjects() {
      return this.currentUser.projects.filter(function (project) {
        return project.objType === ObjectTypes.RISK_PROJECT;
      });
    },
    riskProjectById: function riskProjectById() {
      var _this6 = this;
      return function (projectId) {
        return _this6.riskProjects.find(function (project) {
          return project.id === +projectId;
        }) || {};
      };
    },
    riskAccessLevelByProjectId: function riskAccessLevelByProjectId() {
      var _this7 = this;
      return function (projectId) {
        var project = _this7.riskProjects.find(function (project) {
          return project.id === +projectId;
        });
        if (project.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type) {
          return _this7.currentUser.level_risk;
        }
        return project.access_level;
      };
    },
    isAdmin: function isAdmin() {
      return this.currentUser.is_admin;
    },
    isMfaEnabled: function isMfaEnabled() {
      return this.currentUser.mfa_type;
    },
    userHasAccessLevel: function userHasAccessLevel() {
      var _this8 = this;
      return function (field, level) {
        return _this8.currentUser[field] >= level || _this8.isAdmin;
      };
    }
  },
  actions: {
    set: function set(user) {
      this.currentUser = user;
    },
    setUserIsLoggedIn: function setUserIsLoggedIn(userIsLoggedIn) {
      this.userIsLoggedIn = userIsLoggedIn;
    },
    loadLoginInfo: function loadLoginInfo() {
      var _this9 = this;
      return getLoginInfo().then(function (response) {
        _this9.userIsLoggedIn = response.userIsLoggedIn;
        return response;
      });
    },
    updateUser: function updateUser(user) {
      // Check the arguments
      if (+user.id === +this.currentUser.id) {
        this.currentUser = user;
      }
    },
    load: function load() {
      var _this10 = this;
      return getCurrentUser(true).then(function (user) {
        _this10.currentUser = user;
      });
    }
  }
});