import LinksBox from '@/components/Molecules/LinksBox';
export default {
  name: '',
  components: {
    LinksBox: LinksBox
  },
  data: function data() {
    return {
      links: [{
        title: 'Why do we use it?',
        subtitle: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
        url: '/dev/kitchensink/molecules'
      }, {
        title: 'Where does it come from?',
        subtitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        url: '/dev/kitchensink/molecules'
      }, {
        title: 'Where can I get some?',
        url: '/dev/kitchensink/molecules'
      }]
    };
  }
};