import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "card-view-switcher-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_view_switcher = _resolveComponent("view-switcher");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_view_switcher, {
    view: _ctx.currentSwitcherView,
    "onUpdate:view": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.currentSwitcherView = $event;
    }),
    views: _ctx.views
  }, null, 8 /* PROPS */, ["view", "views"]), _createElementVNode("span", null, "View: " + _toDisplayString(_ctx.currentSwitcherView), 1 /* TEXT */)]);
}