import { getFileById } from '@/api/risma/file';
export function updateFileLocally(attachmentId, attachments) {
  if (!attachmentId || !attachments) return;
  getFileById(attachmentId).then(function (response) {
    var index = attachments.findIndex(function (attachment) {
      return +attachment.id === +response.id;
    });
    if (index !== -1) {
      attachments.splice(index, 1, response);
    } else {
      attachments.push(response);
    }
  });
}