import PerformanceReport from '@/components/Pages/PerformanceReport/PerformanceReport';
import PerformanceDashboard from '@/components/Pages/PerformanceReport/PerformanceDashboard';
import PerformanceReportSettings from '@/components/Pages/PerformanceReport/PerformanceReportSettings';
import PerformanceSolution from '@/components/Pages/PerformanceReport/PerformanceSolution';
import { userHasPerformanceReportAccess } from '@/routes/auth';
export default (function () {
  return [{
    path: '/performance-report',
    redirect: '/performance-report/dashboard',
    component: PerformanceReport,
    meta: {
      userRightsFunction: userHasPerformanceReportAccess,
      keyFunction: function keyFunction() {
        return '/performance-report';
      }
    },
    children: [{
      path: 'dashboard',
      component: PerformanceDashboard
    }, {
      path: 'dashboard/:dashboardId',
      props: true,
      component: PerformanceSolution
    }, {
      path: 'settings/:dashboardId?',
      component: PerformanceReportSettings,
      name: 'PerformanceReportSettings',
      props: true,
      meta: {
        keyFunction: function keyFunction() {
          return '/performance-report';
        }
      }
    }]
  }];
});