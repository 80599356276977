import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_complete_control = _resolveComponent("complete-control");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.title,
    "show-buttons": false,
    "dismiss-button-disabled": $data.interactionDisabled,
    "dismiss-on-click-outside": false,
    "overflow-visible": true,
    type: $options.completeModalWide ? 'wide' : undefined,
    onDismiss: $options.onDismiss
  }, {
    body: _withCtx(function () {
      return [$data.showConfirmation ? (_openBlock(), _createBlock(_component_alert, {
        key: 0,
        header: $data.translate.closeCompletionReviewWindow,
        body: $data.translate.areYouSureYouWishToCloseTheWindowAllEnteredDataWillBeDeleted,
        onAccept: $options.handleClose,
        onDismiss: _cache[0] || (_cache[0] = function ($event) {
          return $data.showConfirmation = false;
        })
      }, null, 8 /* PROPS */, ["header", "body", "onAccept"])) : _createCommentVNode("v-if", true), _createVNode(_component_complete_control, {
        id: $props.id,
        cpath: $props.cpath,
        "is-files-disable": $options.isFilesDisable,
        onToggleDisableInteraction: $options.toggleDisableInteraction,
        onDataUpdated: _cache[1] || (_cache[1] = function ($event) {
          return $data.isUpdated = true;
        }),
        onClose: $options.handleClose,
        onUpdate: _cache[2] || (_cache[2] = function ($event) {
          return _ctx.$emit('update', $event);
        }),
        onUpdateModalTitel: $options.setModalTitle
      }, null, 8 /* PROPS */, ["id", "cpath", "is-files-disable", "onToggleDisableInteraction", "onClose", "onUpdateModalTitel"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "dismiss-button-disabled", "type", "onDismiss"]);
}