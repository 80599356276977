import { columns, adminLevels, accessLevels, accessLevelsBase } from '@/constants/compliance/SecurityGroups';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Notification from '@/components/Molecules/Notification';
import { createSecurityGroup as createSecurityGroupAPI } from '@/api/compliance/admin';
var errorMessageRequired = 'Please fill both fields correctly';
export default {
  name: 'SecurityGroupNew',
  components: {
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    Notification: Notification
  },
  emits: ['created'],
  data: function data() {
    return {
      columns: columns(),
      adminLevels: adminLevels(),
      accessLevels: accessLevels,
      accessLevelsBase: accessLevelsBase,
      group: {
        name: '',
        objectId: '',
        levelAdmin: 0,
        levelControls: 0,
        levelExecution: 0,
        levelIncidents: 0,
        levelRisk: 0,
        levelContracts: 0,
        levelInformationAssets: 0,
        lockedFields: false
      },
      isError: false,
      errorMessage: this.$trans(errorMessageRequired),
      translate: {
        create: this.$trans('Create')
      }
    };
  },
  methods: {
    resetNewGroup: function resetNewGroup() {
      this.group = {
        name: '',
        objectId: '',
        levelAdmin: 0,
        levelControls: 0,
        levelExecution: 0,
        levelIncidents: 0,
        levelRisk: 0,
        levelContracts: 0,
        lockedFields: false
      };
    },
    handleCreate: function handleCreate() {
      var _this = this;
      var newGroup = Object.assign({}, this.group, {
        lockedFields: this.group.lockedFields ? 1 : 0
      });
      return createSecurityGroupAPI(newGroup).then(function () {
        _this.$emit('created');
        _this.resetNewGroup();
      }).catch(function (error) {
        _this.errorMessage = error;
        _this.isError = true;
      });
    },
    isRequiredFieldFilled: function isRequiredFieldFilled() {
      var isNameFilled = this.group.name !== '';
      var isObjectIdFilled = this.group.objectId !== '';
      return isNameFilled && isObjectIdFilled;
    },
    create: function create() {
      this.isError = false;
      if (this.isRequiredFieldFilled()) {
        this.handleCreate();
      } else {
        this.errorMessage = this.$trans(errorMessageRequired);
        this.isError = true;
      }
    }
  }
};