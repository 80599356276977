function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import StaticTable from '@/components/Molecules/StaticTable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { COMPANIES_TABLE_COLUMNS } from '@/constants/companies/companiesColumns';
import { sortAlphabetic } from '@/utils/sort';
import { getCompanyWithUrl as _getCompanyWithUrl } from '@/utils/format/companies';
export default {
  name: 'CompanyGenericTable',
  introduction: 'generic component to show table for companies',
  components: {
    PreviewModalLink: PreviewModalLink,
    StaticTable: StaticTable,
    RismaTitle: RismaTitle
  },
  props: {
    companies: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of companies'
    },
    hiddenColumns: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of columns to hide'
    }
  },
  emits: ['update', 'dismiss'],
  computed: {
    columns: function columns() {
      var _this = this;
      var columns = _objectSpread({}, COMPANIES_TABLE_COLUMNS);
      this.hiddenColumns.forEach(function (column) {
        return delete columns[column];
      });
      return Object.values(columns).map(function (column) {
        return _this.$trans(column);
      });
    },
    sortedCompanies: function sortedCompanies() {
      return sortAlphabetic(this.companies, 'name');
    },
    dataset: function dataset() {
      var _this2 = this;
      return this.sortedCompanies.map(function (company) {
        var result = {
          name: {
            company: company
          },
          address: company.address || '',
          city: company.city || '',
          zipCode: company.zipCode || '',
          constryCode: company.countryCode || '',
          vatNo: company.vatNo || ''
        };
        _this2.hiddenColumns.forEach(function (column) {
          return delete result[column];
        });
        return result;
      });
    }
  },
  methods: {
    getCompanyWithUrl: function getCompanyWithUrl(company) {
      return _getCompanyWithUrl(company);
    }
  }
};