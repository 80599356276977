import i18n from '@/i18n';
export var getTranslate = {
  ActionCreationModal: function ActionCreationModal() {
    return {
      createAction: i18n.t('Create action'),
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      enterTitle: i18n.t('Enter title'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty')
    };
  },
  ContractArchiveModal: function ContractArchiveModal() {
    return {
      confirm: i18n.t('Confirm'),
      cancel: i18n.t('Cancel'),
      stateTheReasonForStatusChange: i18n.t('State the reason for status change'),
      expirationDate: i18n.t('Expiration date'),
      archiving: i18n.t('Archiving'),
      restoring: i18n.t('Restoring'),
      rememberToChangeStatusBeforeArchivingContrac: i18n.t('Remember to change status before archiving contract'),
      rememberToChangeStatusBeforeRestoringContrac: i18n.t('Remember to change status before restoring contract')
    };
  },
  ContractOutsourcingModal: function ContractOutsourcingModal() {
    return {
      addOutsourcingArrangement: i18n.t('Add outsourcing arrangement'),
      cancel: i18n.t('Cancel'),
      add: i18n.t('Add'),
      outsourcingProject: i18n.t('Outsourcing project'),
      title: i18n.t('Title')
    };
  },
  ContractRenewModal: function ContractRenewModal() {
    return {
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      newStatus: i18n.t('New status'),
      newContractAmount: i18n.t('New contract amount'),
      contractAmount: i18n.t('Contract amount'),
      startDate: i18n.t('Start date'),
      expirationDate: i18n.t('Expiration date'),
      terminationDeadline: i18n.t('Termination deadline'),
      renewalDeadline: i18n.t('Renewal deadline'),
      renew: i18n.t('Renew')
    };
  },
  CvrModal: function CvrModal() {
    return {
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      name: i18n.t('Name'),
      address: i18n.t('Address'),
      phone: i18n.t('Phone'),
      email: i18n.t('Email')
    };
  },
  DocumentTypeModal: function DocumentTypeModal() {
    return {
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      updateDocumentType: i18n.t('Update document type'),
      addDocumentType: i18n.t('Add document type'),
      title: i18n.t('Title'),
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      create: i18n.t('Create'),
      theDocumentTypeWithThisNameAlreadyExists: i18n.t('The document type with this name already exists')
    };
  },
  DpmSendQuestionnaireModal: function DpmSendQuestionnaireModal() {
    return {
      sendQuestionnaire: i18n.t('Send questionnaire'),
      send: i18n.t('Send'),
      cancel: i18n.t('Cancel'),
      selectQuestionnaire: i18n.t('Select questionnaire'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      isAnInvalidEmailAddress: i18n.t(' is an invalid email address.'),
      questionnaire: i18n.t('Questionnaire'),
      whenYouSendThisRequestToTheSelectedUser: i18n.t('When you send this request to the selected user, the user will receive an e-mail with a link and your comment. The questionnaire will be presented, when they enter the link in the e-mail. The link will only be active for as long as you have defined. From there the user will be able to answer the questionnaire and finally send the questionnaire back to you, where you will be notified via. e-mail.'),
      recipientName: i18n.t('Recipient Name'),
      enterTheNameOftheRecipient: i18n.t('Enter the name of the recipient'),
      recipientCompanyName: i18n.t('Recipient Company name'),
      enterTheNameOfTheCompany: i18n.t('Enter the name of the company'),
      recipientEmail: i18n.t('Recipient e-mail'),
      enterTheEmailAddressOfTheRecipient: i18n.t('Enter the e-mail address of the recipient'),
      deadline: i18n.t('Deadline'),
      comments: i18n.t('Comments'),
      youMustSelectADateBetween: i18n.t('You must select a date between %s1 and %s2'),
      anyCommentsWillBeIncludedInTheEmail: i18n.t('Any comments will be included in the email to the selected user')
    };
  },
  EditDashboardModal: function EditDashboardModal() {
    return {
      renameDashboard: i18n.t('Rename dashboard'),
      rename: i18n.t('Rename'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      enterTitle: i18n.t('Enter title')
    };
  },
  EditProjectModal: function EditProjectModal() {
    return {
      updateProject: i18n.t('Edit project'),
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      solutionId: i18n.t('Solution ID'),
      displayRelevantThreatsOnAssessments: i18n.t('Display relevant threats on assessments'),
      enableAccumulatedRisk: i18n.t('Enable accumulated risk'),
      enableInherentVsResidualRisk: i18n.t('Enable inherent vs. residual risk'),
      defaultActivityType: i18n.t('Default activity type'),
      notSet: i18n.t('Not set'),
      selectOne: i18n.t('Select one'),
      enableMatrixInverted: i18n.t('Invert matrix axes')
    };
  },
  EditComplianceProjectModal: function EditComplianceProjectModal() {
    return {
      editProject: i18n.t('Edit project'),
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      questionnaire: i18n.t('Questionnaire'),
      gapSchemaName: i18n.t('Gap schema name'),
      solutionId: i18n.t('Solution ID'),
      activityType: i18n.t('Activity type'),
      enableRecommendedActivitiesForGaps: i18n.t('Enable recommended activities for gaps'),
      enableHeatMapsInGapSchema: i18n.t('Enable heat maps in gap schema'),
      enableGapTypes: i18n.t('Enable gap types'),
      riskPrGap: i18n.t('Risk pr. gap'),
      selectOne: i18n.t('Select one'),
      renameTitleOverallDescription: i18n.t('Rename title "Overall description"'),
      renameTitlePurpose: i18n.t('Rename title "Purpose"'),
      renameTitleProcessStatus: i18n.t('Rename title "Process status"'),
      overallDescriptionCannotBeEmpty: i18n.t('Overall description cannot be empty'),
      purposeCannotBeEmpty: i18n.t('Purpose cannot be empty'),
      processStatusCannotBeEmpty: i18n.t('Process status cannot be empty'),
      overallDescription: i18n.t('Overall description'),
      purpose: i18n.t('Purpose'),
      processStatus: i18n.t('Process status')
    };
  },
  FieldGroupsModal: function FieldGroupsModal() {
    return {
      changeSorting: i18n.t('Change sorting'),
      notGrouped: i18n.t('Not grouped'),
      groupName: i18n.t('Group name'),
      save: i18n.t('Save'),
      addNewGroup: i18n.t('Add new group')
    };
  },
  GapSchemaArticlesModal: function GapSchemaArticlesModal() {
    return {
      editArticle: i18n.t('Edit Article'),
      addArticle: i18n.t('Add article'),
      article: i18n.t('Article'),
      typeHere: i18n.t('Type here'),
      articleTitle: i18n.t('Article Title'),
      requirement: i18n.t('Requirement'),
      domain: i18n.t('Domain'),
      pressEnterToRemove: i18n.t('Press enter to remove'),
      selected: i18n.t('Selected'),
      pressEnterToSelect: i18n.t('Press enter to select'),
      flags: i18n.t('Flags'),
      cancel: i18n.t('Cancel'),
      attributes: i18n.t('Attributes'),
      valueShouldBeUnique: i18n.t('Value should be unique'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      createNewAttribute: i18n.t('Create new attribute')
    };
  },
  PdfReportEditModal: function PdfReportEditModal() {
    return {
      saveReport: i18n.t('Save report'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      enterTitle: i18n.t('Enter title'),
      subtitle: i18n.t('Subtitle'),
      enterSubtitle: i18n.t('Enter subtitle'),
      chooseBaseline: i18n.t('Choose baseline'),
      chooseManagementLevels: i18n.t('Choose management levels'),
      chooseExtraColumnsForOverviewTable: i18n.t('Choose extra columns for overview table'),
      chooseOrganisationsForReport: i18n.t('Choose organisations for report'),
      includePage2ForEachRisk: i18n.t('Include page 2 for each risk?'),
      includeNonapprovedRisksInReport: i18n.t('Include non-approved risks in report?'),
      chooseBackgroundImage: i18n.t('Choose background image'),
      baseline: i18n.t('Baseline'),
      managementLevels: i18n.t('Management levels'),
      organisations: i18n.t('Organisations'),
      pleaseFillOutThis: i18n.t('Please fill out this'),
      pleaseSelectOne: i18n.t('Please select one'),
      createPDFReport: i18n.t('Create PDF report'),
      editPDFReport: i18n.t('Edit PDF report'),
      areYouSure: i18n.t('Are you sure?'),
      allDataWillBeLost: i18n.t('All data will be lost')
    };
  },
  RiskCreationModal: function RiskCreationModal() {
    return {
      createRisk: i18n.t('Create risk'),
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      enterTitle: i18n.t('Enter title'),
      dailyRiskOwner: i18n.t('Daily risk owner'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty')
    };
  },
  SearchModal: function SearchModal() {
    return {
      searchResults: i18n.t('Search results'),
      initiatives: i18n.t('Initiatives'),
      controls: i18n.t('Controls'),
      risks: i18n.t('Risks'),
      processes: i18n.t('Processes'),
      incidents: i18n.t('Incidents'),
      contracts: i18n.t('Contracts')
    };
  },
  TagCreationModal: function TagCreationModal() {
    return {
      renameTag: i18n.t('Rename tag'),
      createTag: i18n.t('Create tag'),
      create: i18n.t('Create'),
      title: i18n.t('Title'),
      chooseTagName: i18n.t('Choose tag name')
    };
  },
  ComplianceCreationModal: function ComplianceCreationModal() {
    return {
      createComplianceNode: i18n.t('Create compliance node'),
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      couldNotCreateNode: i18n.t('Could not create node'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty')
    };
  }
};