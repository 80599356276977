export var FileFormats = {
  csv: 'csv',
  doc: 'doc',
  docx: 'docx',
  pdf: 'pdf',
  pptx: 'pptx',
  tif: 'tif',
  tiff: 'tiff',
  txt: 'txt',
  xls: 'xls',
  xlsx: 'xlsx',
  msg: 'msg',
  image: 'image'
};
export var HiddenIconFormats = [FileFormats.msg, FileFormats.image];