import Tabs from '@/components/Molecules/Tabs/Tabs';
import TabItem from '@/components/Atoms/TabItem/TabItem';
export default {
  name: 'AdminAuditReportsEntry',
  components: {
    Tabs: Tabs,
    TabItem: TabItem
  },
  data: function data() {
    return {
      translate: {
        loginReport: this.$trans('Login report'),
        usersChangelog: this.$trans('Users changelog'),
        settingsChangelog: this.$trans('Settings changelog')
      }
    };
  }
};