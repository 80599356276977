import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { focus } from '@/utils/directives/focus';
import { Colors } from '@/Colors';
var typeConfigMap = {
  success: {
    borderColor: 'border-green-150',
    iconColor: 'text-green-150',
    icon: 'check-circle'
  },
  error: {
    borderColor: 'border-red-50',
    iconColor: 'text-red-50',
    icon: 'alert-circle'
  }
};
export default {
  name: 'NotificationPopUp',
  introduction: 'This is a successful message',
  description: 'Its a small popup message that carousel from top, use notificationPopUpMixin to show/dismiss it',
  token: '<notification-pop-up v-if="show"> Some text here </notification-pop-up>',
  directives: {
    focus: focus
  },
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    type: {
      type: String,
      default: 'success',
      validator: function validator(value) {
        return Object.keys(typeConfigMap).indexOf(value) !== -1;
      },
      note: 'the type of notification popup. Possibility values: "error" or "success"'
    }
  },
  emits: ['dismiss'],
  data: function data() {
    return {
      showElement: false,
      //animation purpose
      Colors: Colors,
      timeoutMounted: 0,
      timeoutMain: 0,
      timeoutDismiss: 0
    };
  },
  computed: {
    stylesByType: function stylesByType() {
      return typeConfigMap[this.type];
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.timeoutMounted = setTimeout(function () {
      _this.showElement = true;
      _this.timeoutMain = setTimeout(function () {
        _this.dismiss();
      }, 8000);
    }, 100);
  },
  unmounted: function unmounted() {
    clearTimeout(this.timeoutMounted);
    clearTimeout(this.timeoutMain);
    clearTimeout(this.timeoutDismiss);
  },
  methods: {
    dismiss: function dismiss() {
      var _this2 = this;
      this.showElement = false;
      this.timeoutDismiss = setTimeout(function () {
        _this2.$emit('dismiss');
      }, 500);
    }
  }
};