function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getControlByCpathAndId, controlsReview, uploadFormDataFiles, deleteFormDataFiles } from '@/api/controls/controls';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import AttachmentItem from '@/components/Atoms/Attachment/AttachmentItem';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import CollapsiblePanel from '@/components/Molecules/CollapsiblePanel/CollapsiblePanel';
import Notification from '@/components/Molecules/Notification';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import { ControlActions } from '@/constants/ControlActions';
import { ActivityLinkModules } from '@/constants/ActivityLinkModules';
import * as Utils from '@/utils/Utils';
import * as formatDate from '@/utils/date';
var ACTIONS = {
  PENDING: 'pending',
  APPROVE: 'approve',
  REJECT: 'reject'
};
export default {
  name: 'ControlsReview',
  description: 'Page for review controls',
  components: {
    Notification: Notification,
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    RismaDatatable: RismaDatatable,
    RismaFileUpload: RismaFileUpload,
    FeatherIcon: FeatherIcon,
    TextBox: TextBox,
    CollapsiblePanel: CollapsiblePanel,
    AttachmentItem: AttachmentItem,
    ActivityCustomFields: ActivityCustomFields
  },
  mixins: [RismaDatatableDefaultMixin],
  props: {
    cpath: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  emits: ['update', 'dataUpdated', 'close'],
  data: function data() {
    return {
      isLoaded: false,
      control: {},
      dataset: [],
      stateLocal: 'controls-review',
      comment: '',
      uploadedFiles: [],
      notificationMessage: null,
      alertInformation: null,
      notificationType: 'error',
      translate: getTranslate['ControlsReview'](),
      tempId: 0.5,
      formData: new FormData(),
      filesToDelete: []
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, ['users'])), mapState(useActivityTypesStore, {
    activityTypes: 'controlActivityTypes'
  })), {}, {
    columns: function columns() {
      return {
        date: this.translate.date,
        action: this.translate.action,
        user: this.translate.user,
        commentsAndUrl: this.translate.commentsurl,
        attachments: this.translate.attachments
      };
    },
    filteredReviews: function filteredReviews() {
      var _this$control$reviews, _this$control;
      return (_this$control$reviews = (_this$control = this.control) === null || _this$control === void 0 ? void 0 : _this$control.reviews.filter(function (review) {
        return review.action !== ACTIONS.PENDING;
      })) !== null && _this$control$reviews !== void 0 ? _this$control$reviews : [];
    },
    loadDatatables: function loadDatatables() {
      return this.isLoaded && this.filteredReviews.length;
    },
    title: function title() {
      return "".concat(this.translate.reviewsFor, " \"").concat(this.control.cpath, " ").concat(this.control.title, "\"");
    },
    latestComment: function latestComment() {
      var filteredReviewsLength = this.filteredReviews.length;
      if (!filteredReviewsLength) return;
      return this.filteredReviews[filteredReviewsLength - 1].comment;
    },
    information: function information() {
      if (this.control.rismaState == 2 || this.control.rismaState == 3) {
        return this.translate.thisReviewHasAlreadyBeenCompleted;
      }
      return '';
    },
    completedCommentsFormat: function completedCommentsFormat() {
      return this.control.completedCommentsFormat ? 'pre' : 'p';
    },
    rightActivityType: function rightActivityType() {
      var _this = this;
      if (!this.activityTypes.length && !this.control.activityTypeId) {
        return null;
      }
      var result = _objectSpread({}, this.activityTypes.find(function (item) {
        return item.id === _this.control.activityTypeId;
      }));
      if (result.fields && result.fields.length) {
        result.fields = result.fields.filter(function (field) {
          return field.activityLinkModule === ActivityLinkModules.CONTROL_COMPLETION;
        });
      }
      return result;
    }
  }),
  mounted: function mounted() {
    var _this2 = this;
    this.getControl().then(function () {
      _this2.createDataset();
      _this2.isLoaded = true;
    });
  },
  methods: {
    getControl: function getControl() {
      var _this3 = this;
      return getControlByCpathAndId(this.cpath, this.id).then(function (data) {
        var _data$reviews;
        _this3.control = data;
        var pendingReview = data === null || data === void 0 || (_data$reviews = data.reviews) === null || _data$reviews === void 0 ? void 0 : _data$reviews.find(function (review) {
          return review.action === ACTIONS.PENDING;
        });
        _this3.comment = pendingReview === null || pendingReview === void 0 ? void 0 : pendingReview.comment;
        _this3.uploadedFiles = _this3.prepareFilesList(pendingReview === null || pendingReview === void 0 ? void 0 : pendingReview.files);
      });
    },
    handleRemoveAttachment: function handleRemoveAttachment(id) {
      var fileIndex = this.uploadedFiles.findIndex(function (file) {
        return file.id === id;
      });
      if (fileIndex === -1) return;
      if (!this.uploadedFiles[fileIndex].isCurrent) this.filesToDelete.push(id);
      this.uploadedFiles.splice(fileIndex, 1);
      this.formData.delete("files[".concat(id, "]"));
    },
    deleteFiles: function deleteFiles() {
      var _this4 = this;
      this.filesToDelete.forEach(function (id) {
        return deleteFormDataFiles(_this4.cpath, _this4.id, id);
      });
    },
    findUserNameById: function findUserNameById(userId) {
      return Utils.findPropsByIds([userId], this.users, 'display_name');
    },
    createDataset: function createDataset() {
      var _this5 = this;
      this.filteredReviews.forEach(function (review) {
        _this5.dataset.push({
          date: {
            field: _this5.toLocalDateTime(review.created)
          },
          action: {
            field: _this5.createTranslatedAction(review.action)
          },
          user: {
            field: _this5.findUserNameById(review.userId)
          },
          commentsAndUrl: {
            field: {
              textBefore: review.comment || '',
              url: !review.url || review.url === 'http://' ? '' : review.url,
              title: review.urlTitle || review.url
            },
            fieldText: review.comment
          },
          attachments: {
            field: review.files ? review.files.map(function (file) {
              return {
                fileName: file.name,
                url: "/api/2.0/controls/files/".concat(file.id)
              };
            }) : [],
            fieldText: review.files ? review.files.map(function (file) {
              return file.name;
            }).join(', ') : ''
          }
        });
      });
      this.enhanceDataset(this.dataset, this.columns);
    },
    createTranslatedAction: function createTranslatedAction(action) {
      if (action === 'partially_completed') {
        return this.translate.partialCompletion;
      }
      return this.$trans(action);
    },
    toLocalDate: function toLocalDate(date) {
      return formatDate.toLocalDate(date);
    },
    toLocalDateTime: function toLocalDateTime(time) {
      return formatDate.toLocalDateTime(time);
    },
    prepareFilesList: function prepareFilesList() {
      var files = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return files.map(function (file) {
        return _objectSpread(_objectSpread({}, file), {}, {
          filename: file.name,
          url: "/api/2.0/controls/files/".concat(file.id)
        });
      });
    },
    uploadFormDataFiles: function uploadFormDataFiles(formData) {
      var _this6 = this;
      if (!(formData instanceof FormData) || this.isFormDataEmpty(formData)) {
        this.notificationMessage = this.translate.theFileWasNotUploadedPleaseTryAgain;
        this.notificationType = 'error';
      }
      this.$emit('dataUpdated');
      var readers = [];
      var _iterator = _createForOfIteratorHelper(formData.values()),
        _step;
      try {
        var _loop = function _loop() {
          var file = _step.value;
          var id = ++_this6.tempId;
          _this6.formData.append("files[".concat(id, "]"), file, file.name);
          var reader = new FileReader();
          reader.onloadend = function () {
            _this6.uploadedFiles.push({
              id: id,
              filename: file.name,
              mimeType: file.type,
              url: reader.result,
              isCurrent: true
            });
          };
          reader.readAsDataURL(file);
          readers.push(reader);
        };
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return readers;
    },
    doSelectedAction: function doSelectedAction(action, callBackFn) {
      var _this7 = this;
      var data = {
        comment: this.comment
      };
      return controlsReview(this.cpath, this.id, action, data).then(function (response) {
        if (typeof response.objType !== 'undefined') {
          callBackFn();
          return response;
        }
        _this7.alertInformation = response.message;
      }).then(function (response) {
        var _response$recentRevie;
        if (_this7.filesToDelete.length) _this7.deleteFiles();
        if (_this7.isFormDataEmpty(_this7.formData)) return;
        var id = response === null || response === void 0 || (_response$recentRevie = response.recentReview) === null || _response$recentRevie === void 0 ? void 0 : _response$recentRevie.id;
        if (!id) return;
        _this7.formData.append('reviewId', id);
        return uploadFormDataFiles(_this7.cpath, _this7.id, _this7.formData);
      }).catch(function (error) {
        _this7.notificationMessage = error;
        _this7.notificationType = 'error';
      });
    },
    saveReview: function saveReview() {
      var _this8 = this;
      var callBackFn = function callBackFn() {
        return _this8.$emit('close');
      };
      return this.doSelectedAction(ACTIONS.PENDING, callBackFn);
    },
    approveReview: function approveReview() {
      var _this9 = this;
      var callBackFn = function callBackFn() {
        return _this9.$emit('update', {
          state: ControlActions.review
        });
      };
      return this.doSelectedAction(ACTIONS.APPROVE, callBackFn);
    },
    rejectReview: function rejectReview() {
      var _this10 = this;
      var callBackFn = function callBackFn() {
        return _this10.$emit('update', {
          state: ControlActions.reject
        });
      };
      return this.doSelectedAction(ACTIONS.REJECT, callBackFn);
    },
    commentUpdated: function commentUpdated(e) {
      this.$emit('dataUpdated');
      this.comment = e;
    },
    isFormDataEmpty: function isFormDataEmpty(formData) {
      return !formData.entries().next().value;
    }
  }
};