import DepRelations from '@/components/Organisms/DepRelations';
import { TabsDepRelations } from '@/constants/Tabs';
export default {
  components: {
    DepRelations: DepRelations
  },
  data: function data() {
    return {
      tabsDepRelations: TabsDepRelations()
    };
  }
};