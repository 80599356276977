function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import RiskGrid from '@/components/Organisms/RiskGrid';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewRiskList from '@/components/Molecules/Risk/PreviewRiskList';
import RiskReportDatatable from './RiskReportDatatable';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import ExportFrame from '@/components/Atoms/Containers/ExportFrame';
import { RiskListMixin } from '@/components/Pages/Risks/RiskListMixin';
import { ControlViews } from '@/constants/ControlViews';
import { RiskColorName, StatusColorMapping } from '@/constants/risks/RisksReports';
import { getDotTitleOnHover } from '@/components/Pages/Risks/RiskReports/RiskReportUtilsRisk';
var InherentType = 1;
var ResidualType = 2;
export default {
  name: 'RiskReportGrid',
  introduction: '',
  description: '',
  token: '<risk-report-grid />',
  components: {
    RiskGrid: RiskGrid,
    RismaTitle: RismaTitle,
    PreviewRiskList: PreviewRiskList,
    RiskReportDatatable: RiskReportDatatable,
    RismaToggle: RismaToggle,
    ExportFrame: ExportFrame
  },
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Id of the Risk Project'
    },
    currentPeriod: {
      required: true,
      type: Object,
      note: 'Current risk period'
    },
    isCurrentPeriodSelected: {
      type: Boolean,
      required: false,
      default: true,
      note: 'If it is current period or scoring selected'
    },
    consequenceList: {
      required: true,
      type: Array,
      note: 'List of selected consequences'
    },
    risks: {
      required: true,
      type: Array,
      note: 'Array of risks (from /risks/reports)'
    },
    periods: {
      required: true,
      type: Array,
      note: 'Array of periods'
    },
    comparePeriod: {
      required: false,
      type: Object,
      default: null
    },
    previousPeriods: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    riskApprovalEnabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is Risk Approval feature enabled?'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma users'
    },
    organisations: {
      type: Array,
      required: true,
      note: 'list of all selectable organisations'
    },
    threats: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk threats'
    },
    levelOfThreats: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Level of threats'
    },
    vulnerabilities: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk vulnerabilities'
    },
    probabilities: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk vulnerabilities'
    },
    controls: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All controls, uses for linked activities'
    },
    initiatives: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All initiatives, uses for linked activities'
    },
    systems: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All systems, uses for linked assets'
    },
    processLibraryNodes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All process library nodes, uses for linked assets'
    },
    complianceNodes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All compliance nodes, uses for linked assets'
    },
    tags: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'tags to preview risk list to choose from'
    },
    matrixData: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of matrix data'
    },
    isErm: {
      type: Boolean,
      required: false,
      default: false
    },
    managementLevels: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'managementLevels list'
    },
    riskTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All riskTypes, uses in risk table'
    },
    startColor: {
      type: String,
      required: true
    },
    endColor: {
      type: String,
      required: true
    },
    parentRisks: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All parent risks'
    },
    customHeatmapOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    inherentRisksEnabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is inherent vs. residual risk enabled'
    },
    reportOptions: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['onPeriodChanged', 'dismissModal', 'updateFromPreviewMode', 'fetchSavedReport'],
  data: function data() {
    var _colorCodes;
    return {
      gridBoxSize: 80,
      riskGridData: {
        checked: false,
        colorCodes: (_colorCodes = {}, _defineProperty(_colorCodes, RiskColorName.GREEN, 'background-color: hsl(161, 100%, 34%)'), _defineProperty(_colorCodes, RiskColorName.LIGHTBLUE, 'background-color: hsl(187, 100%, 39%)'), _defineProperty(_colorCodes, RiskColorName.RISMABLUE, 'background-color: hsl(190, 100%, 28%)'), _defineProperty(_colorCodes, RiskColorName.RED, 'background-color: hsl(18, 98%, 40%)'), _colorCodes)
      },
      enableZoom: true,
      zoomRange: [80, 260],
      zoomStep: 20,
      controlViews: ControlViews,
      customFieldsDefault: {},
      statusColor: _objectSpread({}, StatusColorMapping),
      showInherentMatrixIds: {},
      matrixState: null,
      translate: getTranslate['RiskReportGrid']()
    };
  },
  computed: {
    filteredPeriods: function filteredPeriods() {
      return this.previousPeriods.filter(function (period) {
        return period.status !== 0;
      });
    },
    filteredRisks: function filteredRisks() {
      if (!this.matrixState) return [];
      var result = [];
      var selectedMatrix = this.findSelected(this.matrixState);
      if (selectedMatrix.length) {
        this.risks.forEach(function (element) {
          return selectedMatrix.find(function (item) {
            return item.id == element.rno;
          }) ? result.push(element) : '';
        });
      }
      return result;
    },
    risksSelectedSections: function risksSelectedSections() {
      if (!this.matrixState || !this.filteredRisks.length) {
        return [{
          title: '',
          list: []
        }];
      }
      var risksSelected = RiskListMixin.methods.formatRisks(this.filteredRisks, this.threats, this.levelOfThreats, this.vulnerabilities, this.probabilities, this.controls, this.initiatives, this.currentPeriod);
      return [{
        title: '',
        list: risksSelected
      }];
    },
    labelNames: function labelNames() {
      var _ref;
      return _ref = {}, _defineProperty(_ref, RiskColorName.GREEN, this.translate.closed), _defineProperty(_ref, RiskColorName.LIGHTBLUE, this.translate.notUpdated), _defineProperty(_ref, RiskColorName.RISMABLUE, this.translate.updated), _defineProperty(_ref, RiskColorName.RED, this.translate.new), _defineProperty(_ref, RiskColorName.GREY, this.translate.notApproved), _ref;
    }
  },
  mounted: function mounted() {
    this.updateInitSettings();
  },
  methods: {
    updateInitSettings: function updateInitSettings() {
      if (this.riskApprovalEnabled) {
        this.riskGridData.colorCodes[RiskColorName.GREY] = 'background-color: hsl(0, 0%, 60%)';
      } else {
        this.statusColor.not_approved = this.statusColor.updated;
      }
    },
    prevPeriods: function prevPeriods(consequenceId) {
      return {
        selected: this.comparePeriod[consequenceId] ? this.comparePeriod[consequenceId].id : this.currentPeriod.id,
        options: _toConsumableArray(this.filteredPeriods)
      };
    },
    createConsequenceLabels: function createConsequenceLabels(consequenceId) {
      var result = [];
      var currentConsequenceRow = this.currentPeriod.consequenceRows.find(function (item) {
        return item.id === consequenceId;
      });
      if (currentConsequenceRow && currentConsequenceRow.options) {
        result = Object.values(currentConsequenceRow.options).map(function (item) {
          return {
            label: item.value
          };
        });
      }
      return result;
    },
    createProbabilityLabels: function createProbabilityLabels() {
      return this.currentPeriod.probabilityLabels.map(function (item) {
        return {
          label: item
        };
      });
    },
    prepareDots: function prepareDots(gridId) {
      var _this = this;
      var dots = [];
      this.risks.forEach(function (risk) {
        dots.push.apply(dots, _toConsumableArray(_this.setDotValue(risk, gridId)));
      });
      return dots;
    },
    setDotValue: function setDotValue(risk, gridId) {
      var dotData = {
        id: risk.rno,
        color: this.statusColor[risk.gridStatus],
        status: risk.status,
        xBefore: risk._scores[gridId] && risk._scores[gridId].scoresPrevious.x,
        yBefore: risk._scores[gridId] && risk._scores[gridId].scoresPrevious.y
      };
      var result = [_objectSpread(_objectSpread({}, dotData), {}, {
        type: ResidualType,
        title: this.inherentRisksEnabled ? getDotTitleOnHover(risk.title, 'residual') : risk.title,
        x: risk._scores[gridId] && risk._scores[gridId].scores.x,
        y: risk._scores[gridId] && risk._scores[gridId].scores.y
      })];
      if (this.showInherentMatrixIds[gridId]) {
        result.push(_objectSpread(_objectSpread({}, dotData), {}, {
          type: InherentType,
          title: this.inherentRisksEnabled ? getDotTitleOnHover(risk.title, 'inherent') : risk.title,
          x: risk._scores[gridId] && risk._scores[gridId].scores.xInherent,
          y: risk._scores[gridId] && risk._scores[gridId].scores.yInherent
        }));
      }
      return result;
    },
    findSelected: function findSelected(matrix) {
      var result = [];
      matrix.forEach(function (row) {
        row.forEach(function (cell) {
          if (cell && cell.length && cell[0].isActive) {
            result = result.concat(cell.slice(1));
          }
        });
      });
      return result;
    },
    prepareRiskGridData: function prepareRiskGridData(data) {
      return _objectSpread(_objectSpread(_objectSpread({}, this.riskGridData), data), {}, {
        dots: this.prepareDots(data.id)
      });
    }
  }
};