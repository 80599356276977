export default {
  name: 'Breadcrumbs',
  description: 'Breadcrumbs',
  token: '<breadcrumbs :items="[{title:\'test\', url=\'#\'}]"/>',
  props: {
    items: {
      required: true,
      type: Array,
      note: 'Array of items to be displayed'
    }
  }
};