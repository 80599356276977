import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1a67f295"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "pdf-box-shadow-fix flex justify-between wrapper rounded-large border border-gray-350 bg-blue-10 py-4 mx-auto divide-x divide-gray-510 mb-5"
};
var _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.insights, function (insight, i) {
    return _openBlock(), _createElementBlock("div", {
      key: i,
      style: _normalizeStyle({
        width: 100 / $props.insights.length + '%'
      }),
      class: "text-center text-gray-620 px-5"
    }, [_createVNode(_component_feather_icon, {
      class: "inline-block mb-4",
      stroke: insight.color,
      width: "35",
      height: "35",
      "stroke-width": "2",
      icon: insight.icon
    }, null, 8 /* PROPS */, ["stroke", "icon"]), _createElementVNode("div", {
      class: "text-left insight-description",
      innerHTML: insight.description
    }, null, 8 /* PROPS */, _hoisted_2)], 4 /* STYLE */);
  }), 128 /* KEYED_FRAGMENT */))]);
}