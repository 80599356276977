export var treeData = {
  'id': 7,
  'title': 'Policies Root Node',
  'url': '/compliance/processtree/1/nodes/7/edit',
  'objType': 'compliance-process-tree-node',
  'description': '',
  'trafficLight': 0,
  'version': '',
  'approvedByUserId': null,
  'approvedAt': '',
  'deleted': 0,
  'uniqueId': null,
  'processTreeId': 1,
  'checkaccess': true,
  'children': [{
    'id': 14,
    'title': 'ProcessTreeNode',
    'url': '/compliance/processtree/1/nodes/14/edit',
    'objType': 'compliance-process-tree-node',
    'description': '',
    'trafficLight': 0,
    'version': '',
    'approvedByUserId': null,
    'approvedAt': '',
    'deleted': 0,
    'uniqueId': null,
    'processTreeId': 1,
    'checkaccess': true,
    'organisationIds': [],
    'responsibleUserIds': [],
    'accountableUserIds': [],
    'consultedUserIds': [],
    'informedUserIds': [],
    'children': [],
    'name': 'ProcessTreeNode'
  }, {
    'id': 34,
    'title': 'REG access test',
    'url': '/compliance/processtree/1/nodes/34/edit',
    'objType': 'compliance-process-tree-node',
    'description': '',
    'trafficLight': 0,
    'version': '',
    'approvedByUserId': null,
    'approvedAt': '',
    'deleted': 0,
    'uniqueId': null,
    'processTreeId': 1,
    'checkaccess': true,
    'organisationIds': [],
    'responsibleUserIds': [7],
    'accountableUserIds': [],
    'consultedUserIds': [],
    'informedUserIds': [],
    'children': [],
    'name': 'REG access test'
  }, {
    'id': 21,
    'title': 'Politik 1',
    'url': '/compliance/processtree/1/nodes/21/edit',
    'objType': 'compliance-process-tree-node',
    'description': '',
    'trafficLight': 0,
    'version': '',
    'approvedByUserId': null,
    'approvedAt': '',
    'deleted': 0,
    'uniqueId': null,
    'processTreeId': 1,
    'checkaccess': true,
    'children': [{
      'id': 25,
      'title': 'test PRI organization',
      'url': '/compliance/processtree/1/nodes/25/edit',
      'objType': 'compliance-process-tree-node',
      'description': '<div>test</div>',
      'trafficLight': 0,
      'version': '',
      'approvedByUserId': null,
      'approvedAt': '',
      'deleted': 0,
      'uniqueId': 'test',
      'processTreeId': 1,
      'checkaccess': true,
      'organisationIds': [58],
      'responsibleUserIds': [1, 53],
      'accountableUserIds': [1, 53],
      'consultedUserIds': [1, 53],
      'informedUserIds': [1, 53],
      'children': [],
      'name': 'test PRI organization'
    }],
    'organisationIds': [],
    'responsibleUserIds': [],
    'accountableUserIds': [],
    'consultedUserIds': [],
    'informedUserIds': [],
    'name': 'Politik 1'
  }, {
    'id': 29,
    'title': 'Politik 2',
    'url': '/compliance/processtree/1/nodes/29/edit',
    'objType': 'compliance-process-tree-node',
    'description': '',
    'trafficLight': 0,
    'version': '',
    'approvedByUserId': null,
    'approvedAt': '',
    'deleted': 0,
    'uniqueId': null,
    'processTreeId': 1,
    'checkaccess': true,
    'children': [{
      'id': 33,
      'title': 'test PRI access test',
      'url': '/compliance/processtree/1/nodes/33/edit',
      'objType': 'compliance-process-tree-node',
      'description': '<div>test</div>',
      'trafficLight': 0,
      'version': '',
      'approvedByUserId': null,
      'approvedAt': '',
      'deleted': 0,
      'uniqueId': 'test',
      'processTreeId': 1,
      'checkaccess': true,
      'organisationIds': [20],
      'responsibleUserIds': [1],
      'accountableUserIds': [8],
      'consultedUserIds': [53],
      'informedUserIds': [56],
      'children': [],
      'name': 'test PRI access test'
    }],
    'organisationIds': [],
    'responsibleUserIds': [],
    'accountableUserIds': [],
    'consultedUserIds': [],
    'informedUserIds': [],
    'name': 'Politik 2'
  }, {
    'id': 35,
    'title': 'Policy 3',
    'url': '/compliance/processtree/1/nodes/35/edit',
    'objType': 'compliance-process-tree-node',
    'description': '',
    'trafficLight': 0,
    'version': '',
    'approvedByUserId': null,
    'approvedAt': '',
    'deleted': 0,
    'uniqueId': null,
    'processTreeId': 1,
    'checkaccess': true,
    'organisationIds': [],
    'responsibleUserIds': [],
    'accountableUserIds': [],
    'consultedUserIds': [],
    'informedUserIds': [],
    'children': [],
    'name': 'Policy 3'
  }, {
    'id': 36,
    'title': 'Policy 4',
    'url': '/compliance/processtree/1/nodes/36/edit',
    'objType': 'compliance-process-tree-node',
    'description': '',
    'trafficLight': 0,
    'version': '',
    'approvedByUserId': null,
    'approvedAt': '',
    'deleted': 0,
    'uniqueId': null,
    'processTreeId': 1,
    'checkaccess': true,
    'organisationIds': [],
    'responsibleUserIds': [],
    'accountableUserIds': [],
    'consultedUserIds': [],
    'informedUserIds': [],
    'children': [],
    'name': 'Policy 4'
  }, {
    'id': 37,
    'title': 'Policy 5',
    'url': '/compliance/processtree/1/nodes/37/edit',
    'objType': 'compliance-process-tree-node',
    'description': '',
    'trafficLight': 0,
    'version': '',
    'approvedByUserId': null,
    'approvedAt': '',
    'deleted': 0,
    'uniqueId': null,
    'processTreeId': 1,
    'checkaccess': true,
    'organisationIds': [],
    'responsibleUserIds': [],
    'accountableUserIds': [],
    'consultedUserIds': [],
    'informedUserIds': [],
    'children': [],
    'name': 'Policy 5'
  }, {
    'id': 38,
    'title': 'Policy 6',
    'url': '/compliance/processtree/1/nodes/38/edit',
    'objType': 'compliance-process-tree-node',
    'description': '',
    'trafficLight': 0,
    'version': '',
    'approvedByUserId': null,
    'approvedAt': '',
    'deleted': 0,
    'uniqueId': null,
    'processTreeId': 1,
    'checkaccess': true,
    'organisationIds': [],
    'responsibleUserIds': [],
    'accountableUserIds': [],
    'consultedUserIds': [],
    'informedUserIds': [],
    'children': [],
    'name': 'Policy 6'
  }, {
    'id': 39,
    'title': 'Policy 7',
    'url': '/compliance/processtree/1/nodes/39/edit',
    'objType': 'compliance-process-tree-node',
    'description': '',
    'trafficLight': 0,
    'version': '',
    'approvedByUserId': null,
    'approvedAt': '',
    'deleted': 0,
    'uniqueId': null,
    'processTreeId': 1,
    'checkaccess': true,
    'organisationIds': [],
    'responsibleUserIds': [],
    'accountableUserIds': [],
    'consultedUserIds': [],
    'informedUserIds': [],
    'children': [],
    'name': 'Policy 7'
  }, {
    'id': 40,
    'title': 'Policy 8',
    'url': '/compliance/processtree/1/nodes/40/edit',
    'objType': 'compliance-process-tree-node',
    'description': '',
    'trafficLight': 0,
    'version': '',
    'approvedByUserId': null,
    'approvedAt': '',
    'deleted': 0,
    'uniqueId': null,
    'processTreeId': 1,
    'checkaccess': true,
    'organisationIds': [],
    'responsibleUserIds': [],
    'accountableUserIds': [],
    'consultedUserIds': [],
    'informedUserIds': [],
    'children': [],
    'name': 'Policy 8'
  }],
  'organisationIds': [],
  'responsibleUserIds': [],
  'accountableUserIds': [],
  'consultedUserIds': [],
  'informedUserIds': [],
  '_debug': {
    'requestLog': {
      'type': 'request',
      'method': 'GET',
      'url': '/api/2.0/compliance/processtree/1/nodes',
      'ref': 'https://localhost:4444/compliance/processtree',
      'browser': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.77 Safari/537.36',
      'requestLength': '',
      'controller': 'Risma2\\Controller\\Compliance\\Processtree\\ProcessTreeNodeController',
      'controllerPath': '/api/2.0/compliance/processtree/{processTreeId}/nodes',
      'startTime': 1623158067.828397,
      'serveTime': 0.042263031005859375,
      'items': 1,
      'params': [],
      'dbConnections': 3,
      'dbPrepares': 9,
      'maxMemory': 3470808,
      'statusCode': 200,
      'dbPrepareTime': 0.0019638538360595703,
      'dbExecutes': 9,
      'dbExecuteTime': 0.0015697479248046875,
      'dbExecutesPerItem': 9,
      'dbQueries': 0,
      'dbQueryTime': 0,
      'dbStatements': [],
      'vaultCacheHits': 0,
      'vaultCacheMisses': 0,
      'dbCacheHits': 0,
      'dbCacheMisses': 0,
      'dbCacheObjects': 0,
      'hydratedModelsCount': 156,
      'hydratedModels': {
        'Risma2\\Model\\Settings': 129,
        "Risma2\\Model\\User": 1,
        'Risma2\\Model\\Compliance\\ProcessTreeNode': 13,
        "Risma2\\Model\\User\\Link": 13
      }
    }
  },
  'name': 'Policies Root Node'
};