import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getWorkflowStatuses() {
  return handleRequestResponse(Http.get('admin/workflowstatus'));
}
export function getWorkflowStatusesByModule(module) {
  return handleRequestResponse(Http.get("admin/workflowstatus/".concat(module)));
}
export function updateWorkflowStatus(module, data) {
  return handleRequestResponse(Http.patch("admin/workflowstatus/".concat(module, "/status/").concat(data.id), data));
}
export function updateWorkflowStatusesByModule(module, data) {
  return handleRequestResponse(Http.patch("admin/workflowstatus/".concat(module), data));
}