function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { getCompany as _getCompany, editCompany } from '@/api/admin/companies';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import Notification from '@/components/Molecules/Notification';
import { createTabsList } from '@/utils/base/tabs';
import { getLinksLabel } from '@/utils/links';
import { catch403 } from '@/utils/handleAPIErrors';
import { saveStatus } from '@/constants/SaveStatuses';
import { TabTypes } from '@/constants/Tabs';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { MODULES } from '@/constants/modules';
import { VAT_STATUSES, vatStatusLabelByValue } from '@/constants/companies/vatStatuses';
import { isEmailValid, isValidVatNo } from '@/utils/validations';
export default {
  name: 'CompanyEntry',
  components: {
    DepWell: DepWell,
    Notification: Notification
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    id: {
      type: [String, Number],
      require: true,
      default: null,
      note: 'company id'
    }
  },
  emits: ['changeTab', 'updated', 'editContact', 'accessError'],
  data: function data() {
    return {
      isLoaded: false,
      company: {},
      saveStatus: saveStatus.SAVED,
      updateTimer: 0,
      notificationTimer: 0,
      notificationMessage: '',
      notificationType: '',
      translate: {
        beAwareThatThisCompanyHasStatus: this.$trans('Be aware that this company has status')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser', 'isAdmin'])), {}, {
    tabs: function tabs() {
      var baseUrl = "/company/".concat(this.id);
      return createTabsList([TabTypes.DESCRIPTION, TabTypes.CONTACTS, TabTypes.LINKS, TabTypes.CHANGELOG], baseUrl, getLinksLabel(this.company));
    },
    depHeaderProps: function depHeaderProps() {
      return {
        id: this.company.id,
        title: this.company.name,
        editableTitle: true,
        tabs: this.tabs,
        module: MODULES.COMPANY,
        isAttachmentEnabled: false,
        saveStatus: this.saveStatus,
        titleField: 'name',
        trafficLightSettings: this.trafficLightSettings
      };
    },
    trafficLightSettings: function trafficLightSettings() {
      return {
        value: this.company.vatStatus,
        lightType: ObjectTypes.COMPANY,
        field: 'vatStatus',
        lightWithAttribute: true
      };
    },
    routeBackName: function routeBackName() {
      var config = {
        contracts: 'MyCompanies',
        settings: 'Companies'
      };
      return config[this.routeBackKey];
    },
    statusNotificationMessage: function statusNotificationMessage() {
      return this.company.vatStatus > VAT_STATUSES.NORMAL.value ? ": ".concat(this.$trans(vatStatusLabelByValue[this.company.vatStatus])) : '';
    }
  }),
  mounted: function mounted() {
    var _this$currentUser,
      _this = this;
    if (this.isAdmin || (_this$currentUser = this.currentUser) !== null && _this$currentUser !== void 0 && _this$currentUser.level_company) {
      this.getCompany().finally(function () {
        return _this.isLoaded = true;
      });
    } else {
      this.$emit('accessError');
    }
  },
  methods: {
    setUpdateTimer: function setUpdateTimer() {
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(this.saveUpdates, this.$debounceTimeout);
    },
    getCompany: function getCompany() {
      var _this2 = this;
      var query = '?data[]=connected'; // get connected IA

      return _getCompany(this.id, query).then(function (response) {
        _this2.company = response;
        _this2.$emit('updated', response);
      }).catch(function () {
        catch403(_this2.$router, 'Companies');
      });
    },
    companyPropertyChanged: function companyPropertyChanged(_ref) {
      var property = _ref.property,
        value = _ref.value;
      this.company[property] = value;
      this.setUpdateTimer();
    },
    saveUpdates: function saveUpdates() {
      var _this3 = this;
      this.saveStatus = saveStatus.SAVING;
      if (!this.isValid()) {
        this.saveStatus = saveStatus.NOT_SAVED;
        return;
      }
      return editCompany(this.company).then(function (response) {
        _this3.company = _objectSpread(_objectSpread({}, _this3.company), response);
        _this3.saveStatus = saveStatus.SAVED;
        _this3.$emit('updated', _this3.company);
      }).catch(function (error) {
        var _error$response;
        var errorMessage = (error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.error) || _this3.$trans('An error occurred');
        _this3.handleShowNotification('error', errorMessage);
        _this3.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    handleDismissNotification: function handleDismissNotification() {
      this.notificationMessage = '';
    },
    handleShowNotification: function handleShowNotification(type, message) {
      this.notificationType = type;
      this.notificationMessage = message;
      clearTimeout(this.notificationTimer);
      this.notificationTimer = setTimeout(this.handleDismissNotification, 10000);
    },
    saveStatusClicked: function saveStatusClicked() {
      if (this.saveStatus !== saveStatus.SAVED) {
        clearTimeout(this.updateTimer);
        this.saveUpdates();
      }
    },
    isValid: function isValid() {
      return (!this.company.email || isEmailValid(this.company.email)) && isValidVatNo(this.company.vatNo, this.company.countryCode);
    }
  }
};