import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-19787644"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "tab-list-light relative"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_light_tab_item = _resolveComponent("light-tab-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, function (tab) {
    return _openBlock(), _createBlock(_component_light_tab_item, {
      key: "relations-".concat(tab.slotName),
      "is-current": tab.slotName === $setup.currentTab,
      "background-class": $props.backgroundClass,
      onClick: function onClick($event) {
        return $setup.setCurrentTab(tab.slotName);
      }
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString(tab.name), 1 /* TEXT */)];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["is-current", "background-class", "onClick"]);
  }), 128 /* KEYED_FRAGMENT */))]);
}