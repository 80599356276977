function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { computed, shallowRef } from 'vue';
import { logNavigation } from '@/utils/navigationLog';
import { TabTypes, Tabs } from '@/constants/Tabs';
import { ObjectTypes } from '@/constants/ObjectTypes';
import MainWell from '@/components/Atoms/Containers/MainWell';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import DepHeader from '@/components/Organisms/DepHeader/DepHeader';
import DepRelations from '@/components/Organisms/DepRelations';
import { LoginGuardMixin } from '@/mixins/LoginGuardMixin';
export default {
  name: 'DepWell',
  components: {
    MainWell: MainWell,
    DepHeader: DepHeader,
    DepRelations: DepRelations,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  mixins: [LoginGuardMixin],
  inject: {
    isPreview: {
      default: false
    }
  },
  provide: function provide() {
    var _this = this;
    return {
      depRelationEnabled: computed(function () {
        return _this.depRelationsEnabled;
      })
    };
  },
  props: {
    item: {
      type: Object,
      required: false,
      default: null
    },
    depHeaderProps: {
      type: Object,
      required: true
    },
    depRelationsTabs: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    isLoaded: {
      type: Boolean,
      required: true
    },
    localStorageKey: {
      type: String,
      required: false,
      default: null
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    isRelationEnabled: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['saveStatusClicked', 'getActivityData', 'fileUpdate', 'changeTab', 'propertyChanged', 'updatingAttachments'],
  data: function data() {
    return {
      currentRoute: null,
      depRelationsEnabled: false
    };
  },
  computed: {
    previewComponentProps: function previewComponentProps() {
      if (!this.isPreview) return {};
      var parentProps = this.$parent.$props;
      var props = {};
      if (this.currentRoute && this.currentRoute.additionalPath && this.currentRoute.path.startsWith(Tabs[TabTypes.QUESTIONNAIRE].url)) {
        props = {
          questionnaireId: this.currentRoute.additionalPath
        };
      }
      if (this.currentRoute && this.currentRoute.additionalPath && this.currentRoute.path.startsWith(Tabs[TabTypes.GAPANALYSIS].url)) {
        props = {
          articleId: this.currentRoute.additionalPath
        };
      }
      if (this.currentRoute && this.item.objType === ObjectTypes.CONTRACT) {
        props = {
          contractModel: this.currentRoute.path
        };
      }
      if (this.depHeaderProps && this.depHeaderProps.module) {
        props.module = this.depHeaderProps.module;
      }
      return _objectSpread(_objectSpread({}, parentProps), props);
    },
    childRoutes: function childRoutes() {
      var _this2 = this;
      if (!this.$router) {
        return [];
      }
      return this.$router.options.routes.find(function (route) {
        return route.component && route.component.name === _this2.$parent.$options.name;
      }).children;
    },
    // so we wont send it in props for 7+ components
    selectedRoute: function selectedRoute() {
      return this.$parent && this.$parent.$attrs['selected-route'] || '';
    },
    depHeaderProperties: function depHeaderProperties() {
      var _this3 = this;
      if (!this.isPreview || !this.currentRoute) {
        return this.depHeaderProps;
      }
      var currentPath = this.currentRoute.path;
      var tabs = this.depHeaderProps.tabs.map(function (tab) {
        var url, selected;
        if (tab.slicedUrl) {
          //if path with couple elements splitted by /
          url = tab.slicedUrl;
        } else {
          url = tab.url.slice(tab.url.lastIndexOf('/') + 1);
        }
        var splittedCurrentPath = currentPath.split('/');
        if (_this3.currentRoute.additionalPath) {
          //if path with couple elements splitted by /
          var splittedUrl = url.split('/');
          selected = splittedUrl[0] === splittedCurrentPath[0] && splittedUrl[1] === _this3.currentRoute.additionalPath;
        } else {
          selected = url.split(/[/?]+/)[0] === splittedCurrentPath[0];
        }
        return _objectSpread(_objectSpread({}, tab), {}, {
          url: url,
          selected: selected
        });
      });
      return _objectSpread(_objectSpread({}, this.depHeaderProps), {
        tabs: tabs
      });
    },
    filteredTabs: function filteredTabs() {
      if (!this.depRelationsTabs.length) return undefined;
      return this.depRelationsTabs.filter(function (tab) {
        return tab.isEnabled;
      });
    }
  },
  watch: {
    'depHeaderProps.tabs': {
      deep: true,
      handler: function handler() {
        var valueChanged = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        var oldValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        if (valueChanged.length < oldValue.length && this.isPreview) {
          var selectedTab = this.depHeaderProperties.tabs.find(function (item) {
            return item.selected;
          });
          if (!selectedTab) {
            this.currentRoute = shallowRef(this.childRoutes[0]);
            this.$emit('changeTab', this.currentRoute.url);
          }
        }
      }
    }
  },
  mounted: function mounted() {
    this.currentRoute = shallowRef(this.getCurrentRoute());
  },
  methods: {
    getCurrentRoute: function getCurrentRoute() {
      var _this$selectedRoute,
        _this4 = this;
      if (!((_this$selectedRoute = this.selectedRoute) !== null && _this$selectedRoute !== void 0 && _this$selectedRoute.tab)) {
        return this.childRoutes[0];
      }
      if (this.selectedRoute.tab === TabTypes.QUESTIONNAIRE && this.item.questionnaireId) {
        var currentRoute = this.childRoutes.find(function (item) {
          return item.path.includes(Tabs[TabTypes.QUESTIONNAIRE].url);
        });
        return currentRoute ? _objectSpread(_objectSpread({}, currentRoute), {}, {
          additionalPath: this.item.questionnaireId
        }) : this.childRoutes[0];
      }
      if (this.selectedRoute.tab === TabTypes.GAPANALYSIS && this.item.gapSchemaId) {
        var _currentRoute = this.childRoutes.find(function (item) {
          return item.path.includes(Tabs[TabTypes.GAPANALYSIS].url);
        });
        return _currentRoute ? _objectSpread(_objectSpread({}, _currentRoute), {}, {
          additionalPath: this.selectedRoute.id
        }) : this.childRoutes[0];
      }
      return this.childRoutes.find(function (item) {
        return item.path === _this4.selectedRoute;
      }) || this.childRoutes[0];
    },
    propertyChanged: function propertyChanged(changedData) {
      this.$emit('propertyChanged', changedData);
    },
    changeTab: function changeTab(path) {
      var _this5 = this;
      this.loginGuard().then(function () {
        var fullPath = _this5.getFullPass(path);
        logNavigation(_this5.getFullPass(_this5.currentRoute.path), fullPath, true);
        var splittedPath = _this5.getSplittedPath(path);
        var currentRoute = _this5.childRoutes.find(function (route) {
          return route.path === splittedPath.pathes[0] || route.path.startsWith(splittedPath.pathes[0]);
        });
        _this5.currentRoute = shallowRef(currentRoute);
        if (splittedPath.pathes[1]) {
          //if path with couple elements splitted by /
          currentRoute.additionalPath = splittedPath.pathes[1];
        } else {
          currentRoute.additionalPath = null;
        }
        if (_this5.isPreview) _this5.$emit('changeTab', fullPath);
      });
    },
    getFullPass: function getFullPass(path) {
      var _this6 = this;
      var splittedPath = this.getSplittedPath(path);
      return this.depHeaderProps.tabs.find(function (tab) {
        var splittedTab = _this6.getSplittedPath(tab.url);
        var lastTabIdx = splittedTab.pathes.length - 1;
        return splittedTab.pathes[lastTabIdx] === splittedPath.pathes[0] || splittedTab.pathes[lastTabIdx - 1] === splittedPath.pathes[0]; //if path with couple elements splitted by /
      }).url;
    },
    getSplittedPath: function getSplittedPath(path) {
      var splittedPath = {
        pathes: path.split('/'),
        query: path.split('?')[1] || null
      };
      var lastPathIdx = splittedPath.pathes.length - 1;
      splittedPath.pathes[lastPathIdx] = splittedPath.pathes[lastPathIdx].match(/(:?\w+[-])*(:?\w*)/)[0]; // remove search query
      return splittedPath;
    },
    triggerAttachmentMethod: function triggerAttachmentMethod(methodName, args) {
      this.$refs.depHeader.$refs.attachment[methodName](args);
    }
  }
};