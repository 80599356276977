function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function groupByParentField(risks) {
  var output = {};
  var parentRisks = risks.filter(function (risk) {
    return risk.children.length;
  });
  var noParentRisks = [];
  risks.forEach(function (risk) {
    if (risk.children.length) return;
    if (!risk.parent) {
      noParentRisks.push(risk);
      return;
    }
    var parent = parentRisks.find(function (parentRisk) {
      return parentRisk.rno === risk.parent;
    });
    if (!parent) {
      noParentRisks.push(risk);
      return;
    }
    if (output[parent.rno]) {
      output[parent.rno].list.push(risk);
      return;
    }
    output[parent.rno] = {
      list: [risk],
      title: "".concat(parent.rno, " ").concat(parent.title)
    };
  });
  var result = [];
  if (parentRisks.length) {
    result.push({
      title: 'Parent risks',
      list: parentRisks
    });
  }
  result.push.apply(result, _toConsumableArray(Object.values(output)));
  if (noParentRisks.length) {
    result.push({
      title: 'No parent risks',
      list: noParentRisks
    });
  }
  return result;
}
function groupByRule(risks, propName, noMatchTitle) {
  var getItemValueFn = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function () {};
  var output = {};
  var outputWithout = {
    list: [],
    title: noMatchTitle
  };
  risks.forEach(function (risk) {
    var addedToGroup = false;
    if (!risk[propName] || !risk[propName].length) {
      outputWithout.list.push(risk);
      return;
    }
    risk[propName].forEach(function (item) {
      var groupByValue = getItemValueFn(item);
      if (!groupByValue) return;
      addedToGroup = true;
      if (output[groupByValue]) {
        output[groupByValue].list.push(risk);
        return;
      }
      output[groupByValue] = {
        list: [risk],
        title: groupByValue
      };
    });
    if (!addedToGroup) {
      outputWithout.list.push(risk);
    }
  });
  var result = Object.values(output);
  if (outputWithout.list.length) {
    result.push(outputWithout);
  }
  return result;
}
function groupByDefault(risks, trans) {
  return [{
    title: trans('All risks'),
    list: _toConsumableArray(risks)
  }];
}
export var groupBy = function groupBy(risks, _groupBy, assets, trans) {
  var getItemValueFn = function getItemValueFn(item) {
    return item;
  };
  switch (_groupBy) {
    case 'prisk':
      return groupByParentField(risks);
    case 'tags':
      getItemValueFn = function getItemValueFn(tag) {
        return tag.tag;
      };
      return groupByRule(risks, 'tags', 'No tags', getItemValueFn);
    case 'responsible':
      getItemValueFn = function getItemValueFn(itemId) {
        var _assets$users$find;
        return ((_assets$users$find = assets.users.find(function (user) {
          return user.id === itemId;
        })) === null || _assets$users$find === void 0 ? void 0 : _assets$users$find.display_name) || null;
      };
      return groupByRule(risks, 'responsibleUserIds', 'No responsible', getItemValueFn);
    case 'accountable':
      getItemValueFn = function getItemValueFn(itemId) {
        var _assets$users$find2;
        return ((_assets$users$find2 = assets.users.find(function (user) {
          return user.id === itemId;
        })) === null || _assets$users$find2 === void 0 ? void 0 : _assets$users$find2.display_name) || null;
      };
      return groupByRule(risks, 'accountableUserIds', 'No accountable', getItemValueFn);
    case 'organisationIds':
      getItemValueFn = function getItemValueFn(itemId) {
        var _assets$organisations;
        return ((_assets$organisations = assets.organisations.find(function (org) {
          return org.id === +itemId;
        })) === null || _assets$organisations === void 0 ? void 0 : _assets$organisations.visible_name) || null;
      };
      return groupByRule(risks, 'organisationIds', 'No organisations', getItemValueFn);
    case 'threats':
      getItemValueFn = function getItemValueFn(itemId) {
        var _assets$threats$find;
        return ((_assets$threats$find = assets.threats.find(function (threat) {
          return threat.id === itemId;
        })) === null || _assets$threats$find === void 0 ? void 0 : _assets$threats$find.title) || null;
      };
      return groupByRule(risks, 'threatIds', 'No threats', getItemValueFn);
    case 'vulnerabilities':
      getItemValueFn = function getItemValueFn(itemId) {
        var _assets$vulnerabiliti;
        return ((_assets$vulnerabiliti = assets.vulnerabilities.find(function (vulnerability) {
          return vulnerability.id === itemId;
        })) === null || _assets$vulnerabiliti === void 0 ? void 0 : _assets$vulnerabiliti.title) || null;
      };
      return groupByRule(risks, 'vulnerabilityIds', 'No vulnerability', getItemValueFn);
    default:
      return groupByDefault(risks, trans);
  }
};