var _configs;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var TinyMceBoxTypes = {
  COMMENT: 'comment'
};
export var configs = (_configs = {
  mini: {
    toolbar: 'bold italic underline strikethrough link | undo redo',
    plugins: 'paste link',
    menubar: false
  },
  default: {
    toolbar: 'bold italic underline strikethrough | bullist numlist link | undo redo',
    plugins: 'advlist lists link paste',
    menubar: false
  },
  expanded: {
    toolbar: 'image | bold italic | bullist numlist link | alignleft aligncenter alignright alignjustify | undo redo',
    plugins: 'advlist code link lists table paste',
    menubar: false
  }
}, _defineProperty(_configs, TinyMceBoxTypes.COMMENT, {
  toolbar: [],
  plugins: 'paste',
  menubar: false,
  maxSymbolsPreview: 70
}), _defineProperty(_configs, "all", {
  quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote',
  quickbars_insert_toolbar: false,
  toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify |  numlist bullist link | forecolor backcolor removeformat | fullscreen preview print | ltr rtl | code',
  menubar: 'file edit format tools table tc help',
  plugins: 'print preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template code table charmap hr nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars paste'
}), _configs);
export var configKeys = {};
Object.keys(configs).forEach(function (key) {
  configKeys[key.toUpperCase()] = key;
});