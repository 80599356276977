import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 1
};
var _hoisted_2 = {
  class: "flex justify-between"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  var _component_company_contact_modal = _resolveComponent("company-contact-modal");
  var _component_alert = _resolveComponent("alert");
  return _openBlock(), _createElementBlock("div", null, [!$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.companyContacts,
    class: "mb-3 mr-12"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
    icon: "plus",
    height: "35",
    width: "35",
    class: "stroke-3 text-blue-350 cursor-pointer",
    onClick: $options.handleAddContact
  }, null, 8 /* PROPS */, ["onClick"])]), _createVNode(_component_static_table, {
    columns: $options.columns,
    dataset: $options.dataset,
    "columns-max-width": $data.columnsMaxWidth
  }, {
    name: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createElementVNode("button", {
        class: "focus:outline-none",
        onClick: function onClick($event) {
          return $options.handleEditContact(slotData.id);
        }
      }, [_createVNode(_component_risma_title, {
        title: slotData.name,
        type: "medium",
        class: "mb-2 hover:underline"
      }, null, 8 /* PROPS */, ["title"])], 8 /* PROPS */, _hoisted_3)];
    }),
    delete: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [_createElementVNode("button", {
        class: "focus:outline-none",
        onClick: function onClick($event) {
          return $options.handleDeleteContact(slotData.id);
        }
      }, [_createVNode(_component_feather_icon, {
        icon: "x",
        class: "stroke-2 text-red-50 cursor-pointer"
      })], 8 /* PROPS */, _hoisted_4)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["columns", "dataset", "columns-max-width"])])), $data.showContactModal ? (_openBlock(), _createBlock(_component_company_contact_modal, {
    key: 2,
    "contact-data": $data.contactToEdit,
    onDismiss: $options.toggleShowContactModal,
    onSave: $options.handleSaveContact
  }, null, 8 /* PROPS */, ["contact-data", "onDismiss", "onSave"])) : _createCommentVNode("v-if", true), $data.showDeleteContactAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 3,
    body: "".concat($data.translate.deleteContact, " ").concat($data.contactToDelete.name, "?"),
    header: $data.translate.areYouSure,
    "button-ok-text": "Delete",
    onAccept: $options.deleteContact,
    onDismiss: $options.toggleDeleteContactAlert
  }, null, 8 /* PROPS */, ["body", "header", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true)]);
}