import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-15e8acb0"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "incident-description"
};
var _hoisted_2 = {
  class: "flex"
};
var _hoisted_3 = {
  key: 1,
  class: "mb-3"
};
var _hoisted_4 = {
  class: "flex flex-wrap"
};
var _hoisted_5 = {
  key: 4,
  class: "my-8"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_confidential_box = _resolveComponent("confidential-box");
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_traffic_light_selector = _resolveComponent("traffic-light-selector");
  var _component_single_select = _resolveComponent("single-select");
  var _component_solutions_selector = _resolveComponent("solutions-selector");
  var _component_activity_custom_fields = _resolveComponent("activity-custom-fields");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.archived ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "info",
    closeable: false
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($options.archivedMessage), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [!$props.isCreateMode && !!_ctx.getSettingValue('feature.incident_confidential_enabled') ? (_openBlock(), _createBlock(_component_confidential_box, {
    key: 0,
    confidential: !!$props.data.confidential,
    "read-only": $options.confidentialReadOnly,
    "confidentiality-names": $options.confidentialityNames,
    onChanged: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('propertyChanged', {
        property: 'confidential',
        value: +$event
      });
    })
  }, null, 8 /* PROPS */, ["confidential", "read-only", "confidentiality-names"])) : _createCommentVNode("v-if", true), $options.toggleMenuOptions.length && !$props.isUserLocked && !$props.isCreateMode ? (_openBlock(), _createBlock(_component_toggle_menu, {
    key: 1,
    options: $options.toggleMenuOptions,
    class: "ml-auto"
  }, null, 8 /* PROPS */, ["options"])) : _createCommentVNode("v-if", true)]), $props.includeTitle ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_input_field, {
    modelValue: _ctx.title,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.title = $event;
    }),
    title: $data.translate.title,
    "focus-on-mount": $props.includeTitle && $props.isCreateMode,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "focus-on-mount"])])) : _createCommentVNode("v-if", true), !$props.isCreateMode && $options.isFieldInOptional('description') ? (_openBlock(), _createBlock(_component_tiny_mce_box, {
    key: 2,
    "initial-content": _ctx.description,
    "read-only": $props.readOnly,
    onChanged: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.description = $event;
    })
  }, {
    headline: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.description,
        type: "medium",
        class: _normalizeClass($props.isCreateMode ? 'mb-3' : 'mt-8')
      }, null, 8 /* PROPS */, ["title", "class"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["initial-content", "read-only"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
    class: _normalizeClass(["min-w-250px w-31/100 mr-5", $props.isCreateMode ? 'mb-3' : 'mb-5 mt-8'])
  }, [_createVNode(_component_date_time_picker, {
    modelValue: $options.incidentOccurred,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $options.incidentOccurred = $event;
    }),
    title: $data.translate.incidentOccurred,
    disabled: $props.readOnly,
    "delete-enabled": true,
    class: "w-full"
  }, null, 8 /* PROPS */, ["modelValue", "title", "disabled"])], 2 /* CLASS */), _ctx.severity !== null && _ctx.severity !== undefined && $options.isFieldInOptional('severity') ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(["min-w-180px w-31/100 mr-5", $props.isCreateMode ? 'mb-3' : 'mb-5 mt-8'])
  }, [_createVNode(_component_risma_title, {
    title: $data.translate.severity,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_traffic_light_selector, {
    modelValue: _ctx.severity,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return _ctx.severity = $event;
    }),
    "read-only": $props.readOnly,
    class: "min-h-40px flex items-center",
    "light-type": "incidents"
  }, null, 8 /* PROPS */, ["modelValue", "read-only"])], 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass(["min-w-180px w-31/100 mr-5", $props.isCreateMode ? 'mb-3' : 'mb-5 mt-8'])
  }, [_createVNode(_component_single_select, {
    modelValue: _ctx.rismaState,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return _ctx.rismaState = $event;
    }),
    title: $data.translate.status,
    disabled: $props.readOnly,
    "un-sorted": true,
    options: $options.stateList
  }, null, 8 /* PROPS */, ["modelValue", "title", "disabled", "options"])], 2 /* CLASS */)]), $props.isCreateMode ? (_openBlock(), _createBlock(_component_solutions_selector, {
    key: 3,
    "selected-options": $data.solutions || [],
    onSelected: $options.onSolutionsChange,
    onOpen: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.$emit('open');
    }),
    onClose: _cache[7] || (_cache[7] = function ($event) {
      return _ctx.$emit('close');
    })
  }, null, 8 /* PROPS */, ["selected-options", "onSelected"])) : _createCommentVNode("v-if", true), !$props.isCreateMode && _ctx.activityTypes.length && $props.data.activityTypeId ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_activity_custom_fields, {
    "activity-type": $options.currentActivityType,
    "custom-field-values": $props.data.customFieldValues,
    disabled: $props.readOnly,
    onChanged: _ctx.onCustomFieldsChange
  }, null, 8 /* PROPS */, ["activity-type", "custom-field-values", "disabled", "onChanged"])])) : _createCommentVNode("v-if", true)]);
}