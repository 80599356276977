import { TitleTypes } from '@/constants/TitleTypes';
import Modal from '@/components/Molecules/Modal/Modal';
var AlertTypes = {
  DEFAULT: 'default',
  CONFIRMATION: 'confirmation'
};
export default {
  name: 'Alert',
  introduction: 'Component for displaying an Alert (instead of native browser confirm())',
  description: 'Simplified version of Modal component. Can be seen on Control Details page.',
  token: "<alert\n    :header=\"translate.areYouSure\"\n    @accept=\"toggleModal\"\n    @dismiss=\"toggleModal\"\n    buttonOkText=\"Delete\">\n</alert>",
  components: {
    Modal: Modal
  },
  props: {
    header: {
      required: false,
      type: String,
      default: ''
    },
    body: {
      required: false,
      type: [String, Array],
      default: ''
    },
    buttonOkText: {
      type: String,
      required: false,
      default: ''
    },
    buttonDismissText: {
      type: String,
      required: false,
      default: ''
    },
    addToRoot: {
      type: Boolean,
      required: false,
      default: false
    },
    abovePopup: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'large',
      validator: function validator(val) {
        return ['large', 'small', 'wide'].includes(val);
      }
    },
    alertType: {
      type: String,
      required: false,
      default: 'default',
      validator: function validator(val) {
        return ['default', 'confirmation'].includes(val);
      }
    }
  },
  emits: ['dismiss', 'accept'],
  data: function data() {
    return {
      TitleTypes: TitleTypes,
      translate: {
        areYouSure: this.$trans('Are you sure?'),
        cancel: this.$trans('Cancel'),
        confirmationNeeded: this.$trans('Confirmation needed'),
        confirm: this.$trans('Confirm')
      }
    };
  },
  computed: {
    dismissText: function dismissText() {
      return this.buttonDismissText || this.translate.cancel;
    },
    okText: function okText() {
      return this.alertType === AlertTypes.CONFIRMATION ? this.buttonOkText || this.translate.confirm : this.buttonOkText || 'OK';
    },
    headerText: function headerText() {
      return this.alertType === AlertTypes.CONFIRMATION ? this.header || this.translate.confirmationNeeded : this.header;
    },
    bodyText: function bodyText() {
      return this.alertType === AlertTypes.CONFIRMATION ? this.body || this.translate.areYouSure : this.body;
    }
  },
  mounted: function mounted() {
    if (this.addToRoot) {
      this.$root.$el.appendChild(this.$el);
    }
  },
  unmounted: function unmounted() {
    if (this.abovePopup) {
      document.body.classList.add('modal-open');
    }
  }
};