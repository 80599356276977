import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import AssessmentIndicators from '@/components/Molecules/Compliance/AssessmentIndicators';
export default {
  name: 'QuestionnaireStats',
  description: 'Component for listing all questionnaires with scorings',
  token: '<questionnaire-stats />',
  components: {
    RismaTitle: RismaTitle,
    AssessmentIndicators: AssessmentIndicators
  },
  props: {
    questionnaires: {
      required: true,
      type: Array
    }
  },
  data: function data() {
    return {
      translate: getTranslate['QuestionnaireStats']()
    };
  },
  methods: {
    prepareIndicatorData: function prepareIndicatorData(assessmentData, totalScore) {
      var result = [];
      assessmentData.forEach(function (item, index) {
        if (index === 0) return;
        var nextIndex = index + 1;
        var next = assessmentData[nextIndex];
        var oneStepInPercents = 100 / (assessmentData.length - 1);
        result.push({
          title: next ? item.score : ">= ".concat(item.score),
          score: +item.score,
          minScore: assessmentData[index - 1].score,
          color: item.color,
          isFilled: +item.score <= totalScore,
          width: oneStepInPercents
        });
      });
      return result;
    }
  }
};