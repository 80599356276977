import ColumnChart from '@/components/Molecules/Charts/ColumnChart';
import { Colors } from '@/Colors';
export default {
  name: '',
  components: {
    ColumnChart: ColumnChart
  },
  data: function data() {
    return {
      charts: {
        colors: [Colors.unanswered, Colors.answered, Colors.na],
        colorsNew: ['rgb(198, 6, 15)', 'rgb(0, 61, 70)'],
        pie: {
          data: [{
            'category': 'category 1',
            'column-1': 8
          }, {
            'category': 'category 2',
            'column-1': 6
          }, {
            'category': 'category 3',
            'column-1': 2
          }]
        },
        column: {
          data: [{
            'category': 'category 1',
            'value': 8
          }, {
            'category': 'category 2',
            'value': 4
          }, {
            'category': 'category 3',
            'value': 12
          }, {
            'category': 'category 4',
            'value': 33
          }, {
            'category': 'category 5',
            'value': 120
          }, {
            'category': 'category 6',
            'value': 150
          }, {
            'category': 'category 7',
            'value': 72
          }, {
            'category': 'category 8',
            'value': 83
          }],
          labels: ['category 1', 'category 2']
        }
      }
    };
  }
};