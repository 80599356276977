import PreviewProcessTreeNode from '@/components/Atoms/Preview/PreviewProcessTreeNode';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import StaticTable from '@/components/Molecules/StaticTable';
import LoadMoreWrapper from '@/components/Molecules/LoadMoreWrapper';
import CardProcessLibrary from '@/components/Organisms/General/Cards/CardProcessLibrary';
import { ProcessLibraryVisibleProps } from '@/constants/processLibraryVisibleProps';
import * as Utils from '@/utils/Utils';
import { viewTypes as allViews } from '@/constants/ListViewType';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
export default {
  components: {
    PreviewProcessTreeNode: PreviewProcessTreeNode,
    PreviewList: PreviewList,
    StaticTable: StaticTable,
    CardProcessLibrary: CardProcessLibrary,
    LoadMoreWrapper: LoadMoreWrapper
  },
  mixins: [ProcessLibraryTitleMixin],
  props: {
    processTreeNodes: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'list of data'
    },
    viewMode: {
      required: false,
      type: String,
      default: 'compact',
      note: 'selected view type'
    },
    user: {
      required: true,
      type: Object,
      default: function _default() {},
      note: 'user with policiesAccess or is_admin property'
    },
    organisations: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of all organisations'
    },
    users: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      },
      note: 'List of all users'
    }
  },
  emits: ['changeShowState'],
  data: function data() {
    return {
      views: allViews,
      processLibraryVisibleProps: ProcessLibraryVisibleProps
    };
  },
  computed: {
    dataset: function dataset() {
      var _this = this;
      var result = [];
      result = this.processTreeNodes.map(function (node) {
        var preparedItem = {};
        _this.processLibraryVisibleProps.forEach(function (column) {
          preparedItem[column.key] = _this.prepareCompactHtml(column.key, node);
        });
        return preparedItem;
      });
      return result;
    },
    columns: function columns() {
      var _this2 = this;
      return this.processLibraryVisibleProps.map(function (item) {
        return _this2.$trans(item.text);
      });
    }
  },
  methods: {
    prepareCompactHtml: function prepareCompactHtml(column, node) {
      var result = '';
      switch (column) {
        case 'title':
          result = this.prepareTitle(node);
          break;
        case 'responsibleUserIds':
        case 'accountableUserIds':
          result = Utils.findPropsByIds(node[column], this.users, 'display_name');
          break;
        case 'organisationIds':
          result = Utils.findPropsByIds(node[column], this.organisations, 'visible_name');
          break;
        default:
          result = node[column] || '';
      }
      return result;
    },
    prepareTitle: function prepareTitle(node) {
      return {
        url: "/compliance/processtree/1/nodes/".concat(node.id, "/edit"),
        title: "".concat(node.uniqueId ? node.uniqueId : '', " ").concat(node.title)
      };
    }
  }
};