function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getInformationAssets } from '@/api/risma/data';
import { getAll as getControls } from '@/api/controls/controls';
import { getAll as getInitiatives } from '@/api/execution/initiative';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { ObjectTypes } from '@/constants/ObjectTypes';
export var RiskReportsMixin = {
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    projectId: {
      type: String,
      required: true,
      note: 'Id of the Risk Project'
    }
  },
  data: function data() {
    return {
      isLoaded: false,
      risks: [],
      systems: [],
      controls: [],
      initiatives: [],
      decoupleFilterFields: [{
        key: 'risks'
      }, {
        key: 'systems'
      }, {
        key: 'initiatives'
      }, {
        key: 'controls'
      }]
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, ['isSystemsEnabled'])), {}, {
    systemColumn: function systemColumn() {
      return this.isSystemsEnabled ? {
        systems: this.$trans('Systems')
      } : {};
    }
  }),
  methods: {
    getLinkedAssetsAndActivities: function getLinkedAssetsAndActivities() {
      return new Promise(function (resolve, reject) {
        Promise.all([getControls(), getInitiatives(), getInformationAssets()]).then(function (responce) {
          resolve(responce);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    handleGetLinkedAssetsAndActivities: function handleGetLinkedAssetsAndActivities(response) {
      this.controls = response[0].list;
      this.initiatives = response[1].list;
      this.systems = response[2].list.filter(function (item) {
        return item.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM;
      });
    },
    getFormattedRisks: function getFormattedRisks(risks) {
      var formattedTitleRisks = risks.map(function (risk) {
        return _objectSpread(_objectSpread({}, risk), {}, {
          formattedTitle: "".concat(risk.rno, ". ").concat(risk.title)
        });
      });
      return this.getFormattedField(formattedTitleRisks);
    },
    getFormattedControls: function getFormattedControls(risks, controlsAll) {
      var filteredControls = this.getFilteredActivities(risks, controlsAll, 'control');
      var formattedTitleControls = filteredControls.map(function (control) {
        return _objectSpread(_objectSpread({}, control), {}, {
          formattedTitle: "".concat(control.cpath, ". ").concat(control.title)
        });
      });
      return this.getFormattedField(formattedTitleControls);
    },
    getFormattedInitiatives: function getFormattedInitiatives(risks, initiativesAll) {
      var filteredInitiatives = this.getFilteredActivities(risks, initiativesAll, 'execution');
      var formattedTitleInitiatives = filteredInitiatives.map(function (initiative) {
        return _objectSpread(_objectSpread({}, initiative), {}, {
          formattedTitle: "".concat(initiative.ipath, ". ").concat(initiative.title)
        });
      });
      return this.getFormattedField(formattedTitleInitiatives);
    },
    getFormattedSystems: function getFormattedSystems(risks, systemsAll) {
      var filteredSystems = this.getFilteredActivities(risks, systemsAll, 'dataprocessor_system');
      var sortedSystems = filteredSystems.sort(function (a, b) {
        try {
          return +a.uniqueId - +b.uniqueId;
        } catch (_unused) {
          return undefined;
        }
      });
      var formattedTitleSystems = sortedSystems.map(function (system) {
        var prefix = system.uniqueId ? "".concat(system.uniqueId, ". ") : '';
        var formattedTitle = "".concat(prefix).concat(system.name);
        return _objectSpread(_objectSpread({}, system), {}, {
          formattedTitle: formattedTitle
        });
      });
      return this.getFormattedField(formattedTitleSystems);
    },
    getSystemsCell: function getSystemsCell(risks, systems) {
      if (this.isSystemsEnabled) {
        var systemsFormatted = this.getFormattedSystems(risks, systems);
        return {
          systems: systemsFormatted
        };
      }
      return {};
    },
    getFilteredActivities: function getFilteredActivities(risks, activities, linksName) {
      var activitiesIds = risks.reduce(function (activitiesIds, risk) {
        if (risk.linkedActivities && risk.linkedActivities[linksName]) {
          return activitiesIds.concat(risk.linkedActivities[linksName]);
        }
        if (risk.links && risk.links[linksName]) {
          return activitiesIds.concat(risk.links[linksName]);
        }
        return activitiesIds;
      }, []);
      return activities.filter(function (activity) {
        return activitiesIds.indexOf(activity.id) !== -1;
      });
    },
    getFormattedField: function getFormattedField(items) {
      return {
        activities: items,
        field: {},
        fieldText: items.map(function (item) {
          return item.formattedTitle;
        }).join(', ')
      };
    }
  }
};