function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import { getTranslate } from './translate';
import RismaPopup from '@/components/Atoms/Containers/RismaPopup';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'NewChoice',
  introduction: 'Possible to create a new question',
  description: 'Provide a button to show a popup with create an anwer form with checks for blank and dublet.',
  token: "\n<new-choice options=\"options\" @new=\"fn\"/>\n// Properties looks like\noptions: ['What are is?', 'Where are pirates?'],\n",
  components: {
    RismaPopup: RismaPopup,
    RismaButton: RismaButton,
    InputField: InputField,
    Notification: Notification,
    FeatherIcon: FeatherIcon
  },
  props: {
    options: {
      type: [Array, Object],
      required: true,
      note: 'Predefined answers what will checks on dublet'
    }
  },
  emits: ['new'],
  data: function data() {
    return {
      choiceLabel: '',
      isHiddenPopup: true,
      errorMessage: '',
      translate: getTranslate['NewChoice']()
    };
  },
  methods: {
    togglePopup: function togglePopup() {
      this.isHiddenPopup = !this.isHiddenPopup;
      this.choiceLabel = '';
    },
    handleNewAnswer: function handleNewAnswer() {
      this.errorMessage = '';
      var oldOptions = _typeof(this.options) === 'object' ? Object.values(this.options) : this.options;
      if (!this.choiceLabel.length) {
        this.setErrorMessage(this.translate.youCantSubmitABlankAnswer);
        return;
      }
      if (oldOptions.indexOf(this.choiceLabel) !== -1 || oldOptions.indexOf(this.choiceLabel.charAt(0).toUpperCase() + this.choiceLabel.slice(1)) !== -1) {
        this.setErrorMessage(this.translate.answerAlreadyExists);
        return;
      }
      this.$emit('new', this.choiceLabel);
      this.choiceLabel = '';
      this.togglePopup();
    },
    setErrorMessage: function setErrorMessage(message) {
      this.errorMessage = message;
    }
  }
};