import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "flex mb-2 items-center"
};
var _hoisted_2 = {
  class: "flex"
};
var _hoisted_3 = {
  key: 1
};
var _hoisted_4 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_save_report = _resolveComponent("save-report");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.motherControls,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_save_report, {
    type: $data.reportType,
    "state-local": $data.stateLocal,
    onLoad: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.applySettingsFromLoadedReport($event);
    })
  }, null, 8 /* PROPS */, ["type", "state-local"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_toggle, {
    modelValue: $setup.showDeactivated,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $setup.showDeactivated = $event;
    }),
    class: "mr-1",
    onInput: $options.toggleDeactivated
  }, null, 8 /* PROPS */, ["modelValue", "onInput"]), _createVNode(_component_risma_title, {
    title: $data.translate.displayDeactivatedMotherControls,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])])]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_risma_datatable, {
    key: 1,
    ref: "reportsDataTable",
    dataset: _ctx.datasetSlice,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "page-length": _ctx.pageLength,
    "disabled-filter-by-columns": $data.disabledFilterByColumns,
    "columns-meta": $data.columnsMeta,
    "decouple-filter-fields": $data.decoupleFilterFields,
    "column-filters-sorting": $data.columnFiltersSorting,
    "wrapper-height-sticky": 550,
    "filter-by-column": true,
    "state-local": $data.stateLocal,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged
  }, {
    title: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_createElementVNode("div", {
        class: _normalizeClass({
          'line-through': !item.activity.active
        })
      }, [$options.userHasMotherAccess ? (_openBlock(), _createBlock(_component_preview_modal_link, {
        key: 0,
        activity: item.activity,
        onDismissModal: $options.getData
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(item.activity.title), 1 /* TEXT */)];
        }),

        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onDismissModal"])) : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(item.activity.title), 1 /* TEXT */))], 2 /* CLASS */)];
    }),

    daughterControls: _withCtx(function (_ref2) {
      var _item$controls;
      var item = _ref2.item;
      return [(_item$controls = item.controls) !== null && _item$controls !== void 0 && _item$controls.length ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 0
      }, _renderList(item.controls, function (control) {
        return _openBlock(), _createBlock(_component_preview_modal_link, {
          key: control.id,
          class: _normalizeClass({
            'line-through': !control.active
          }),
          activity: control,
          onDismissModal: $options.getData
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString("".concat(control.cpath, " ").concat(control.title)), 1 /* TEXT */)];
          }),

          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "activity", "onDismissModal"]);
      }), 128 /* KEYED_FRAGMENT */)) : (_openBlock(), _createElementBlock("div", _hoisted_4))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset", "dataset-total", "dataset-total-sliced", "page-length", "disabled-filter-by-columns", "columns-meta", "decouple-filter-fields", "column-filters-sorting", "state-local", "onPageChange", "onOnStateChanged"]))]);
}