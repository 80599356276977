import i18n from '@/i18n';
export var getTranslate = {
  Table: function Table() {
    return {
      excludeNodesWithoutAnswersInReport: i18n.t('Exclude nodes without answers in report?'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      exportToExcel: i18n.t('Export to excel'),
      exportToWord: i18n.t('Export to Word'),
      printTheTable: i18n.t('Print the table'),
      name: i18n.t('Name'),
      email: i18n.t('Email'),
      sent: i18n.t('Sent'),
      questionnaireOpened: i18n.t('Questionnaire opened'),
      questionnaireAnswered: i18n.t('Questionnaire answered'),
      description: i18n.t('Description'),
      purpose: i18n.t('Purpose'),
      questionnaire: i18n.t('Questionnaire'),
      dataProcessors: i18n.t('Data processors'),
      dataControllers: i18n.t('Data controllers'),
      dataFlow: i18n.t('Data flow'),
      deadlineExceeded: i18n.t('Deadline exceeded'),
      questionnaireAnswerNotReceived: i18n.t('Questionnaire answer not received'),
      youHaveNoDataFlowsInThisProcess: i18n.t('You have no data flows in this process'),
      status: i18n.t('Status'),
      organisations: i18n.t('Organisations'),
      type: i18n.t('Type'),
      address: i18n.t('Address'),
      regNumber: i18n.t('Reg. number'),
      vatNumber: i18n.t('VAT number'),
      tags: i18n.t('Tags')
    };
  },
  node: function node() {
    return {
      node: i18n.t('Node')
    };
  }
};