import i18n from '@/i18n';
export var getTranslate = {
  ManagementReport: function ManagementReport() {
    return {
      pdfReportGenerator: i18n.t('PDF report generator'),
      showPreview: i18n.t('Show preview'),
      download: i18n.t('Download'),
      areYouSure: i18n.t('Are you sure?'),
      willBeRemoved: i18n.t('will be removed'),
      ok: i18n.t('Ok'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      changed: i18n.t('Changed'),
      previewFile: i18n.t('Preview file')
    };
  },
  RiskReportsRisks: function RiskReportsRisks() {
    return {
      riskReport: i18n.t('Risk report'),
      nothingToShow: i18n.t('Nothing to show'),
      tryAdjustingTheFiltrationParameters: i18n.t('Try adjusting the filtration parameters'),
      specifyYourReportByChoosingAPeriodAndACon: i18n.t('Specify your report by choosing a period and a consequence parameter. You can also use the additional filters to narrow your search.')
    };
  }
};