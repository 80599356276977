function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import StaticTableRow from './StaticTableRow';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaColumnSwitcher from '@/components/Atoms/RismaColumnSwitcher/RismaColumnSwitcher';
import { clickOutside } from '@/utils/directives/clickOutside';
import { StaticTableThemes } from '@/constants/StaticTableThemes';
export default {
  name: 'StaticTable',
  introduction: 'A static table, for static data',
  description: 'This table take a dataset and column-values to generate a table. each item in the dataset can have a children array.' + 'This array will generate collapsable child rows. You are able to pass feather-icon components to a row, by using the html string ' + 'of the component. You can also make a click event that emits a trigger event, like shown. The trigger event will ' + 'travel all the way up to static-table, where it will be caught. To find out which element that was clicked you can use event.path[0].',
  token: "<static-table\n             :dataset=\"testData\"\n              :columns=\"testColumns2\">\n              <template v-slot:slotName=\"{slotData}\">\n              ....\n              </template></static-table>\n        testData:[\n          {\n              title: \"TestItem1\",\n              desc: \"this is a test item, you know\",\n              type: \"TestItem\"\n              button: \"\",\n              slotName: {\n                  id: \"item id\"\n              }\n          },\n          {\n              title: \"TestItem2\",\n              desc: \"this is a test item, you know\",\n              type: \"TestItem\"\n              button: \"<feather-icon icon=\"x\" size=\"2\" color=\"red\" @click=\"$emit(\"trigger\",$event)\"/>\",\n              slotName: {\n                  id: \"item id\"\n              }\n          },\n          {\n              title: \"TestItem3\",\n              desc: \"this is a test item, you know\",\n              type: \"TestItem\",\n              button: \"\",\n              slotName: {\n                  id: \"item id\"\n              }\n              children: [\n                  {\n                      title: \"SubTestItem1\",\n                      desc: \"this is a subtest item, you know\",\n                      type: \"SubTestItem\",\n                      button: \"\"\n                  },\n                  {\n                      title: \"SubTestItem2\",\n                      desc: \"this is a subtest item, you know\",\n                      type: \"SubTestItem\",\n                      button: \"\"\n                  }\n              ]\n          }\n          ],\n          testColumns2: [\n          \"Title\", \"Description\", \"Type\", \"\"\n          ]",
  components: {
    FeatherIcon: FeatherIcon,
    StaticTableRow: StaticTableRow,
    RismaTitle: RismaTitle,
    RismaColumnSwitcher: RismaColumnSwitcher
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    dataset: {
      type: Array,
      required: true,
      note: 'An array with objects, that represents the data which should be displayed'
    },
    columns: {
      type: Array,
      required: true,
      note: 'An array with column names as strings'
    },
    hoverTitles: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Describes which columns will have dynamic or static hover titles according to content'
    },
    visibilitySwitch: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Add possibility to change columns visibility'
    },
    visibilityStorageName: {
      type: String,
      required: false,
      default: 'static_table_hidden_columns',
      note: 'Storage name to store hidden columns'
    },
    visibilitySwitchLabel: {
      type: String,
      required: false,
      default: 'Column visibility',
      note: 'Default switch label'
    },
    visibilityDefaultColumns: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'An array with default visible columns'
    },
    visibilityHiddenOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'An array with names of columns what will be hidden in visibility switcher'
    },
    watermarkImage: {
      type: String,
      required: false,
      default: 'none',
      note: 'Url of background watermark image'
    },
    preventFullPageUpdates: {
      type: Boolean,
      required: false,
      default: false,
      note: 'needed to prevent random full page updates'
    },
    theme: {
      type: String,
      require: false,
      default: '',
      validator: function validator(type) {
        return type ? Object.values(StaticTableThemes).indexOf(type) !== -1 : true;
      },
      note: "Type of title. Possible values: ".concat(Object.values(StaticTableThemes).join(', '))
    },
    isRowClickable: {
      type: Boolean,
      require: false,
      default: false,
      note: 'adds cursour class to the row'
    },
    columnsMaxWidth: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Describes which columns must have max width value'
    },
    columnsMinWidth: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Describe which columns must have min width value. Useful if you have scrollable table'
    },
    additionalRowClassesText: {
      type: String,
      required: false,
      default: '',
      note: 'if you have additionalRowClasses and this row should have mouseover text'
    },
    isChildrenIconShown: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Show icon in front of first cell'
    },
    isHeadersTruncated: {
      type: Boolean,
      required: false,
      default: true,
      note: 'If headers cells truncated'
    },
    itemPropsToShowChildren: {
      type: String,
      require: false,
      default: '',
      note: 'Property name that should contain showChildren property that will control children displaying'
    },
    showChildDefaultValue: {
      type: Boolean,
      require: false,
      default: false
    },
    columnsStyles: {
      type: Object,
      require: false,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['click:row'],
  data: function data() {
    return {
      popupVisible: false,
      visibleColumns: [],
      hiddenColumns: []
    };
  },
  computed: {
    preparedDataset: function preparedDataset() {
      var data = this.dataset.slice();
      if (!data || !data.length) {
        return [];
      } else if (!Object.prototype.hasOwnProperty.call(data[0], 'additionalRowClasses')) {
        return data;
      }
      return data.map(function (item) {
        // its necessary to add it as new object, otherwice it would remove additionalRowClasses from dataset
        var newItem = _objectSpread({}, item);
        delete newItem.additionalRowClasses;
        return newItem;
      });
    }
  },
  mounted: function mounted() {
    if (this.visibilitySwitch) {
      this.visibleColumns = this.createVisibleColumns(this.columns);
      this.changeVisibilityAll();
    }
  },
  methods: {
    createVisibleColumns: function createVisibleColumns(columns) {
      var _this = this;
      var result = [];
      var storageValue = localStorage.getItem(this.visibilityStorageName);
      var hiddenColumns = [];
      var allVisible = false;
      if (storageValue) {
        hiddenColumns = storageValue.split('-');
        hiddenColumns = hiddenColumns.map(function (index) {
          return +index;
        });
      } else {
        if (storageValue === '') {
          // Empty storage but exists
          allVisible = true;
        }
      }
      this.hiddenColumns = hiddenColumns;
      columns.forEach(function (item, index) {
        result.push({
          index: index,
          visible: allVisible ? true : _this.checkVisibleColumn(index, item, hiddenColumns),
          label: item
        });
      });
      return result;
    },
    checkVisibleColumn: function checkVisibleColumn(index, item, hiddenColumns, isHiddenColumnFeatureDisabled) {
      var result = true;

      // Get from default
      if (!hiddenColumns.length && !isHiddenColumnFeatureDisabled) {
        if (this.visibilityDefaultColumns.length) {
          result = this.visibilityDefaultColumns.indexOf(item) !== -1;
        }
      } else {
        result = hiddenColumns.indexOf(index) === -1;
      }
      return result;
    },
    handleSwitchClick: function handleSwitchClick() {
      if (this.popupVisible) {
        this.$refs.staticTable.click();
        this.popupVisible = false;
      } else {
        this.$refs.staticTable.click();
        this.popupVisible = true;
      }
    },
    setVisibility: function setVisibility(item) {
      this.visibleColumns.forEach(function (column) {
        if (column.label === item.label) {
          column.visible = !column.visible;
        }
      });
      this.changeVisibilityAll();
    },
    changeVisibilityAll: function changeVisibilityAll() {
      var hiddenColumns = [];
      this.visibleColumns.forEach(function (item) {
        if (!item.visible) {
          hiddenColumns.push(item.index);
        }
      });
      this.hiddenColumns = hiddenColumns;
      this.setVisibilityStorage(hiddenColumns);
    },
    setVisibilityStorage: function setVisibilityStorage(data) {
      var saveData = data.join('-');
      localStorage.setItem(this.visibilityStorageName, saveData, {
        samesite: 'strict'
      });
    },
    getColumnWidth: function getColumnWidth(index) {
      var key = Object.keys(this.preparedDataset[0] || {})[index];
      var maxWidthValue = this.columnsMaxWidth[key];
      var minWidthValue = this.columnsMinWidth[key];
      var styles = this.columnsStyles[key] || '';
      if (!maxWidthValue && !minWidthValue) {
        return styles;
      }
      return "width: ".concat(minWidthValue || maxWidthValue, ";\n                ").concat(minWidthValue ? "min-width: ".concat(minWidthValue, ";") : '', "\n                ").concat(maxWidthValue ? "max-width: ".concat(maxWidthValue, ";") : '', "\n                ").concat(styles);
    }
  }
};