import ScoringInfoBox from '@/components/Organisms/Risk/ScoringInfoBox';
import { singleItem } from '@/test/data/scoringPeriod';
export default {
  components: {
    ScoringInfoBox: ScoringInfoBox
  },
  data: function data() {
    return {
      scoring: singleItem
    };
  }
};