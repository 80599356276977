function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { getTranslate } from './translate';
import { deleteAudit as _deleteAudit } from '@/api/compliance/dpm';
import { getInformationAssets } from '@/api/risma/data';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import VerticalMenu from '@/components/Molecules/VerticalMenu/VerticalMenu';
import DpmSendQuestionnaireModal from '@/components/Molecules/Modal/DpmSendQuestionnaireModal';
import RismaStepProgressBar from '@/components/Molecules/RismaStepProgressBar';
import Alert from '@/components/Molecules/Modal/Alert';
import DpmActionModal from '@/components/Pages/Tabs/DpmActionModal';
import questionnaire from '@/components/Organisms/Compliance/questionnaire';
import Notification from '@/components/Molecules/Notification';
import * as Utils from '@/utils/Utils';
import { DateFormats } from '@/constants/DateFormats';
import { AuditGenericData } from '@/constants/AuditGenericData';
import { getAudit } from '@/api/compliance/audit';
export default {
  name: 'AuditOverview',
  components: {
    RismaTitle: RismaTitle,
    questionnaire: questionnaire,
    RismaButton: RismaButton,
    DpmSendQuestionnaireModal: DpmSendQuestionnaireModal,
    RismaStepProgressBar: RismaStepProgressBar,
    VerticalMenu: VerticalMenu,
    Alert: Alert,
    DpmActionModal: DpmActionModal,
    Notification: Notification
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    id: {
      type: [String, Number],
      required: true,
      note: 'id'
    },
    module: {
      type: String,
      required: true,
      note: 'module name'
    },
    idItem: {
      type: String,
      required: false,
      default: '',
      note: 'Data Processor Management id'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      pageLoaded: false,
      allAudits: [],
      auditIdToDelete: null,
      progressData: {
        firstItem: null,
        secondItem: null,
        thirdItem: null
      },
      menuItems: [],
      currentAudit: {},
      dateOptions: {
        locale: Utils.getLanguage(),
        format: DateFormats.DATE_FORMAT,
        widgetPositioning: {
          vertical: 'bottom',
          horizontal: 'auto'
        }
      },
      notificationError: null,
      questions: null,
      informationAssets: null,
      showQuestionnaireModal: false,
      showAlert: false,
      dpmActionType: 'extendDeadline',
      isModalOpened: false,
      translate: getTranslate['AuditOverview']()
    };
  },
  computed: {
    dataQuestionnaire: function dataQuestionnaire() {
      if (typeof this.data.questionnaire === 'undefined') {
        return [];
      }
      return this.data.questionnaire;
    },
    existingAudits: function existingAudits() {
      var _this = this;
      var result = [];
      this.allAudits.forEach(function (item) {
        var statusText = _this.translate.sent;
        if (item.firstClick) {
          statusText = _this.translate.questionnaireOpened;
        }
        if (item.answered) {
          statusText = _this.translate.questionnaireAnswered;
        }
        if (item.requestExpired) {
          statusText = _this.translate.deadlineExceeded;
        }
        var selectItem = {
          value: item.id,
          label: '<b>' + item.questionnaire.title + '</b><br/>' + _this.translate.recipient + ': ' + item.fullname + '<br/>' + _this.translate.status + ': ' + statusText,
          requestExpired: item.requestExpired
        };
        result.push(selectItem);
      });
      return result;
    },
    genericData: function genericData() {
      return AuditGenericData[this.data.objType];
    },
    showActionPanel: function showActionPanel() {
      var _this$progressData$se, _this$progressData$th;
      return !this.data.archived && !this.readOnly && (((_this$progressData$se = this.progressData.secondItem) === null || _this$progressData$se === void 0 ? void 0 : _this$progressData$se.state) === 'declined' || ((_this$progressData$th = this.progressData.thirdItem) === null || _this$progressData$th === void 0 ? void 0 : _this$progressData$th.state) === 'approved');
    },
    dpmActionGroup: function dpmActionGroup() {
      return {
        extendDeadline: {
          header: this.translate.extendDeadlineForAudit,
          mainText: this.translate.hereYouCanExtendTheDeadlineForTheAuditAnd,
          accept: this.extendDeadline
        },
        askForMoreInfo: {
          header: this.translate.resubmitAuditForMoreInfo,
          mainText: this.translate.hereYouCanAskForMoreInfoRegardingTheAudit,
          accept: this.askForMoreInfo
        }
      };
    },
    modalQuestionnaires: function modalQuestionnaires() {
      if (this.data.auditQuestionnaires) {
        return this.data.auditQuestionnaires.map(function (q) {
          return {
            value: q.id,
            label: q.title
          };
        });
      }
      return [];
    },
    auditTitle: function auditTitle() {
      var result = this.data.name || this.data.title;
      if (this.genericData && this.genericData.titleSuffix) {
        result += ': ' + this.$trans(this.genericData.titleSuffix);
      }
      return result;
    }
  },
  watch: {
    idItem: {
      handler: function handler() {
        this.progressData = {
          firstItem: null,
          secondItem: null,
          thirdItem: null
        };
        this.goToFirstIfIdNotDefined();
        this.idItem && this.loadAudit(+this.idItem);
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    var promises = [this.getAllRequests(), this.getAllInformationAssets()];
    Promise.all(promises).then(function () {
      _this2.goToFirstIfIdNotDefined();
      _this2.prepareMenuItems(_this2.existingAudits);
      _this2.pageLoaded = true;
    });
  },
  methods: {
    goToFirstIfIdNotDefined: function goToFirstIfIdNotDefined() {
      if (this.idItem === '' && this.existingAudits.length && !this.isPreview) {
        var questionnaireLastId = this.existingAudits[this.existingAudits.length - 1].value;
        this.$router.replace("".concat(this.$router.currentRoute.value.path, "/").concat(questionnaireLastId));
        return true;
      }
      return false;
    },
    getUrlWithoutDPMId: function getUrlWithoutDPMId(path) {
      var dpmTitle = 'dpm';
      var dpmIndex = path.indexOf(dpmTitle);
      return path.substring(0, dpmIndex + dpmTitle.length);
    },
    prepareMenuItems: function prepareMenuItems(existingAudits) {
      var menu = [];
      var urlWithoutDPMId = !this.isPreview ? this.getUrlWithoutDPMId(this.$router.currentRoute.value.path) : '';
      existingAudits.map(function (audit) {
        menu.push({
          id: audit.value,
          title: audit.label,
          path: "".concat(urlWithoutDPMId, "/").concat(audit.value),
          color: audit.requestExpired === true ? 'red' : 'green'
        });
      });
      menu.reverse();
      this.menuItems = menu;
    },
    loadAudit: function loadAudit(e) {
      var _this3 = this;
      return getAudit(this.module, this.id, e).then(function (response) {
        _this3.currentAudit = response;
        _this3.createProgressData(_this3.currentAudit);
      });
    },
    createProgressData: function createProgressData(data) {
      var _this4 = this;
      this.progressData.firstItem = {
        state: '',
        titles: []
      };
      this.progressData.secondItem = {
        state: 'wait',
        titles: [this.translate.awaitingQuestionnaireToBeOpened]
      };
      this.progressData.thirdItem = {
        state: 'wait',
        titles: [this.translate.awaitingQuestionnaireAnswer]
      };
      this.updateFirstItem('approved');
      if (data.firstClick) {
        this.progressData.secondItem = {
          state: 'approved',
          titles: [this.translate.questionnaireOpened + ': ' + data.firstClick.substring(0, 10)]
        };
      }
      this.questions = null;
      if (data.answered) {
        this.progressData.thirdItem = {
          state: 'approved',
          titles: [this.translate.questionnaireAnswered + ':' + data.answered.substring(0, 10)]
        };
        this.$nextTick(function () {
          _this4.questions = data.questionnaire.questionAnswers;
        });
      } else if (data.requestExpired) {
        this.progressData.secondItem = {
          state: 'declined',
          titles: [this.translate.deadlineExceeded]
        };
        this.progressData.thirdItem = {
          state: 'declined',
          titles: [this.translate.deadlineExceeded, this.translate.questionnaireAnswerNotReceived]
        };
      }
    },
    getAllInformationAssets: function getAllInformationAssets() {
      var _this5 = this;
      return getInformationAssets().then(function (_ref) {
        var list = _ref.list;
        return _this5.informationAssets = list;
      });
    },
    getAllRequests: function getAllRequests() {
      var _this6 = this;
      return this.genericData.getAllRequestsAPI(this.module, this.id).then(function (response) {
        _this6.allAudits = response.list;
        if (_this6.isPreview && _this6.existingAudits.length > 0) {
          return _this6.loadAudit(_this6.existingAudits[_this6.existingAudits.length - 1].value);
        }
      });
    },
    handleShowNewRequestModal: function handleShowNewRequestModal() {
      this.$refs.DpmSendQuestionnaireModal.toggleModal();
    },
    createNewDpmRequest: function createNewDpmRequest(formData) {
      var _this7 = this;
      formData.title = this.data.name || this.data.title;
      this.genericData.createNewRequestAPI(this.module, this.id, formData).then(function (response) {
        return Promise.all([response, _this7.getAllRequests()]);
      }).then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 1),
          response = _ref3[0];
        if (!_this7.isPreview) {
          var urlWithoutDPMId = _this7.getUrlWithoutDPMId(_this7.$router.currentRoute.value.path);
          _this7.$router.replace("".concat(urlWithoutDPMId, "/").concat(response.data.id));
        }
      }).then(function () {
        _this7.prepareMenuItems(_this7.existingAudits);
      });
    },
    showActionModal: function showActionModal(type) {
      this.isModalOpened = true;
      this.dpmActionType = type;
    },
    askForMoreInfo: function askForMoreInfo(data) {
      var _this8 = this;
      this.genericData.askForMoreInfoAPI(this.currentAudit.id, data.expires, data.message).then(function (response) {
        _this8.updateFirstItem('approved');
        _this8.updateAllAuditsLocaly(response.data);
        _this8.loadAudit(_this8.currentAudit.id);
      }).catch(function (_ref4) {
        var response = _ref4.response;
        _this8.notificationError = response.error || _this8.translate.anErrorOccurred;
      }).finally(function () {
        _this8.isModalOpened = false;
      });
    },
    extendDeadline: function extendDeadline(data) {
      var _this9 = this;
      this.genericData.extendDeadlineAPI(this.id, this.currentAudit.id, data.expires, data.message).then(function (_ref5) {
        var data = _ref5.data;
        _this9.updateFirstItem('approved');
        if (!data.requestExpired) {
          _this9.progressData.secondItem.state = 'wait';
          _this9.progressData.thirdItem.state = 'wait';
        }
        _this9.updateAllAuditsLocaly(data);
        _this9.prepareMenuItems(_this9.existingAudits);
        _this9.loadAudit(_this9.currentAudit.id);
      }).catch(function (_ref6) {
        var response = _ref6.response;
        _this9.notificationError = response.error || _this9.translate.anErrorOccurred;
      }).finally(function () {
        _this9.isModalOpened = false;
      });
    },
    updateFirstItem: function updateFirstItem(state) {
      this.progressData.firstItem.state = state;
      this.progressData.firstItem.titles = [this.translate.sent + ': ' + this.currentAudit.created.substring(0, 10), this.translate.to + ': ' + this.currentAudit.fullname + ' (' + this.currentAudit.email + ')', this.translate.companyName + ': ' + this.currentAudit.company, this.translate.deadline + ': ' + this.currentAudit.expires.substring(0, 10)];
    },
    updateAllAuditsLocaly: function updateAllAuditsLocaly(audit) {
      var auditIndex = this.allAudits.findIndex(function (item) {
        return item.id === audit.id;
      });
      this.allAudits[auditIndex] = _objectSpread(_objectSpread({}, this.allAudits[auditIndex]), audit);
    },
    dismissQuestionnaireModal: function dismissQuestionnaireModal() {
      this.showQuestionnaireModal = false;
    },
    deleteAudit: function deleteAudit() {
      var _this10 = this;
      return _deleteAudit(this.id, this.auditIdToDelete).then(function () {
        _this10.allAudits = _this10.allAudits.filter(function (item) {
          return item.id !== _this10.auditIdToDelete;
        });
        _this10.menuItems = _this10.menuItems.filter(function (item) {
          return item.id !== _this10.auditIdToDelete;
        });
        if (!_this10.isPreview && +_this10.auditIdToDelete === +_this10.idItem) {
          _this10.currentAudit = {};
          _this10.questions = null;
          _this10.redirectToLastAudit();
        }
        if (_this10.isPreview && +_this10.auditIdToDelete === +_this10.currentAudit.id) {
          _this10.currentAudit = {};
          _this10.questions = null;
          var auditToShow = _this10.existingAudits[_this10.existingAudits.length - 1];
          auditToShow && _this10.loadAudit(+auditToShow.value);
        }
        _this10.dismissDeletion();
      });
    },
    redirectToLastAudit: function redirectToLastAudit() {
      var _this$existingAudits;
      var urlWithoutDPMId = this.getUrlWithoutDPMId(this.$router.currentRoute.value.path);
      var questionnaireLastId = (_this$existingAudits = this.existingAudits[this.existingAudits.length - 1]) === null || _this$existingAudits === void 0 ? void 0 : _this$existingAudits.value;
      if (questionnaireLastId) {
        return this.$router.replace("".concat(urlWithoutDPMId, "/").concat(questionnaireLastId));
      }
      return this.$router.replace("".concat(urlWithoutDPMId));
    },
    showDeleteAlert: function showDeleteAlert(id) {
      this.auditIdToDelete = id;
      this.showAlert = true;
    },
    dismissDeletion: function dismissDeletion() {
      this.showAlert = false;
      this.auditIdToDelete = null;
    }
  }
};