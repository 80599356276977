function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getTreeByProjectId as _getTreeByProjectId } from '@/api/compliance/tree';
import { getIdsFromTree } from '@/utils/tree';
import { formatNodes } from '@/utils/format/Compliance';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { UserLevels } from '@/constants/UserLevels';
var getUsersProject = function getUsersProject(users, projectId) {
  return users.filter(function (user) {
    return user.projects.some(function (project) {
      return project.id === +projectId && project.objType === ObjectTypes.COMPLIANCE_PROJECT && project.access_level > UserLevels.NONE;
    });
  });
};
export var RismaSelectorBarMixin = {
  data: function data() {
    return {
      users: [],
      isLoadedStaticData: false,
      allNodeIds: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    storeUsers: 'activeUsers'
  })), mapState(useOrganisationsStore, {
    orgs: 'organisations'
  })),
  mounted: function mounted() {
    this.loadStaticData();
  },
  methods: {
    loadStaticData: function loadStaticData() {
      var _this = this;
      this.isLoadedStaticData = false;
      return Promise.all([this.setUsers(this.projectId), this.getTreeByProjectId(this.projectId), this.loadData()]).then(function () {
        _this.setFiltersFromLocalStorage();
        _this.isLoadedStaticData = true;
      });
    },
    setUsers: function setUsers() {
      var projectId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      this.users = projectId === 0 ? this.storeUsers : getUsersProject(this.storeUsers, projectId);
    },
    getTreeByProjectId: function getTreeByProjectId(projectId) {
      var _this2 = this;
      return _getTreeByProjectId(projectId).then(function (_ref) {
        var children = _ref.children;
        _this2.nodes = formatNodes(children);
        _this2.allNodeIds = getIdsFromTree(children);
      });
    },
    saveState: function saveState(objectToSave) {
      return localStorage.setItem(this.localStorageKey, JSON.stringify(objectToSave));
    },
    loadState: function loadState() {
      var state;
      try {
        state = JSON.parse(localStorage.getItem(this.localStorageKey));
      } catch (_unused) {
        state = null;
      }
      return state;
    },
    setFiltersFromLocalStorage: function setFiltersFromLocalStorage() {
      var storageObj = this.loadState();
      if (!storageObj) return;
      if (storageObj.filterObj) {
        this.lastAppliedFilters = storageObj.filterObj;
      }
      if (storageObj.includeChildNodes !== undefined) {
        this.includeChildToggleOption = storageObj.includeChildNodes;
      }
    },
    loadData: function loadData() {
      return Promise.resolve(); //for cases when any of dashboards don`t need to load any data
    }
  }
};