var _UserLevelsByValue;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var UserLevels = {
  NONE: 0,
  NORMAL: 1,
  PRIVILEGED: 2,
  SUPER: 3,
  SUPERVISOR: '3++',
  ADMIN: 4
};
export var LockLevels = {
  LOCKED: 1,
  UNLOCKED: 0
};
export var UserLevelsByValue = (_UserLevelsByValue = {}, _defineProperty(_UserLevelsByValue, UserLevels.NONE, 'None'), _defineProperty(_UserLevelsByValue, UserLevels.NORMAL, 'Normal'), _defineProperty(_UserLevelsByValue, UserLevels.PRIVILEGED, 'Privileged'), _defineProperty(_UserLevelsByValue, UserLevels.SUPER, 'Super'), _defineProperty(_UserLevelsByValue, UserLevels.ADMIN, 'Admin'), _UserLevelsByValue);