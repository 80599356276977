function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { destroy, getAllByType, getAllLightWeightProcessors } from '@/api/compliance/informationAssets';
import Alert from '@/components/Molecules/Modal/Alert';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import PaginatedTable from '@/components/Organisms/PaginatedTable';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import Trafficlight from '@/components/Atoms/Trafficlight/Trafficlight';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { InformationAssetsFuseMixin } from '@/components/Pages/InformationAssets/InformationAssetsFuseMixin';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import InformationAssetCreationModal from '@/components/Molecules/InformationAssets/InformationAssetCreationModal';
import Notification from '@/components/Molecules/Notification';
import { InformationAssetsViews, IATypeViews } from '@/constants/compliance/InformationAssetsViews';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { check403AccessError } from '@/utils/handleAPIErrors';
import { InformationAssetsDeleteMixin } from '@/components/Pages/InformationAssets/InformationAssetsDeleteMixin';
import { InformationasstesOverviewMixin } from '@/mixins/InformationAssetsOverview';
var PAGE_SIZE = 25;
export default {
  name: 'IAOverview',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    InputField: InputField,
    PaginatedTable: PaginatedTable,
    FeatherIcon: FeatherIcon,
    NotificationPopUp: NotificationPopUp,
    PreviewModalLink: PreviewModalLink,
    Trafficlight: Trafficlight,
    InformationAssetCreationModal: InformationAssetCreationModal,
    Notification: Notification,
    Alert: Alert
  },
  mixins: [NotificationPopUpMixin, InformationAssetsFuseMixin, InformationasstesOverviewMixin, InformationAssetsDeleteMixin],
  props: {
    onlyMy: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update'],
  data: function data() {
    return {
      new: null,
      filterString: '',
      isLoaded: false,
      updated: false,
      controllers: {},
      processors: {},
      allProcessors: {},
      systems: {},
      customIA: [],
      createModal: -1,
      getFunctionsByType: {
        controllers: this.getControllers,
        processors: this.getProcessors,
        systems: this.getSystems
      },
      AssetsTypes: AssetsTypes,
      nonCustomAssetsTypes: [AssetsTypes.CONTROLLERS, AssetsTypes.PROCESSORS, AssetsTypes.SYSTEMS],
      debounceTimeout: 1000,
      debounceTimer: null,
      timer: 0,
      PAGE_SIZE: PAGE_SIZE,
      translate: getTranslate['InformationAssetsOverview']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser', 'accessLevelByModule'])), mapState(useActivityTypesStore, {
    activityTypes: 'complianceIACustomActivityTypes'
  })), {}, {
    data: function data() {
      return this.filterByObjTypes({
        controllers: this.controllers,
        processors: this.processors,
        allProcessors: this.allProcessors,
        systems: this.systems,
        customIA: this.customIA
      }, this.activityTypes);
    },
    dataset: function dataset() {
      var _this = this;
      var dataset = [];
      var _this$accessLevelByMo = this.accessLevelByModule('InformationAssets'),
        canDelete = _this$accessLevelByMo.canDelete;
      this.data.forEach(function (section) {
        var data = _this.filterString && !_this.nonCustomAssetsTypes.includes(section.type) ? _this.getFilteredInformationAssets(section.list, _this.filterString) : section.list;
        dataset.push({
          id: section.id,
          list: _this.formatDataIA(data, section.type, canDelete),
          total: section.total
        });
      });
      return dataset;
    },
    myIaExtention: function myIaExtention() {
      return this.onlyMy ? '-my' : '';
    },
    showAllChildren: function showAllChildren() {
      return !!this.filterString.length;
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'information asset');
    },
    wellStyles: function wellStyles() {
      return this.data.length === 1 ? 'min-w-3/10 max-w-full' : this.data.length === 2 ? 'w-49/100' : 'w-8/25';
    }
  }),
  watch: {
    onlyMy: function onlyMy() {
      this.getData();
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData(skipCustom) {
      var _this2 = this;
      this.isLoaded = false;
      var customIAParams = this.onlyMy ? "&filters[responsible][]=".concat(this.currentUser.id) : '';
      var promises = [this.getAllProcessors()];
      if (!skipCustom) promises.push(this.getCustomInformationAssets(customIAParams));
      Promise.all(promises).then(function () {
        _this2.isLoaded = true;
        _this2.turnAfterRefreshNotification();
      });
    },
    getControllers: function getControllers(additionalParams) {
      var _this3 = this;
      var parameters = "?data[]=questionnaire_associations".concat(additionalParams);
      return getAllByType(AssetsTypes.CONTROLLERS, parameters).then(function (_ref) {
        var list = _ref.list,
          total = _ref.total;
        _this3.controllers = {
          list: list,
          total: total
        };
      });
    },
    getProcessors: function getProcessors(additionalParams) {
      var _this4 = this;
      var parameters = "?data[]=relations&data[]=systems&data[]=questionnaire_associations".concat(additionalParams);
      return getAllByType(AssetsTypes.PROCESSORS, parameters).then(function (_ref2) {
        var list = _ref2.list,
          total = _ref2.total;
        _this4.processors = {
          list: list,
          total: total
        };
      });
    },
    getAllProcessors: function getAllProcessors() {
      var _this5 = this;
      return getAllLightWeightProcessors().then(function (_ref3) {
        var list = _ref3.list,
          total = _ref3.total;
        _this5.allProcessors = {
          list: list,
          total: total
        };
      });
    },
    getSystems: function getSystems(additionalParams) {
      var _this6 = this;
      var parameters = "?data[]=relations&data[]=processors&data[]=questionnaire_associations".concat(additionalParams);
      return getAllByType(AssetsTypes.SYSTEMS, parameters).then(function (_ref4) {
        var list = _ref4.list,
          total = _ref4.total;
        _this6.systems = {
          list: list,
          total: total
        };
      });
    },
    getCustomInformationAssets: function getCustomInformationAssets(additionalParams) {
      var _this7 = this;
      var parameters = "?data[]=questionnaire_associations".concat(additionalParams);
      return getAllByType(AssetsTypes.INFORMATION_ASSETS_API, parameters).then(function (_ref5) {
        var list = _ref5.list;
        _this7.customIA = list;
      });
    },
    update: function update(data) {
      this.setNotificationData(data);
      this.getData();
      this.dismissCreateModal();
      this.$emit('update');
    },
    capitalize: function capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    deleteItem: function deleteItem(item) {
      this.deleteIA = item;
    },
    handleDelete: function handleDelete() {
      var _this8 = this;
      return destroy(this.deleteIA.objType, this.deleteIA.id).then(function () {
        _this8.deleteIA = null;
        _this8.getData();
        _this8.$emit('update');
      });
    },
    getExtendedViewLink: function getExtendedViewLink(section) {
      return {
        name: this.onlyMy ? IATypeViews.MY : IATypeViews.OVERVIEW,
        params: {
          typeId: section.activityTypeId || section.type,
          initialView: InformationAssetsViews.EXTENDED
        }
      };
    },
    dismissCreateModal: function dismissCreateModal() {
      this.createModal = -1;
    },
    dismissPreviewModal: function dismissPreviewModal() {
      if (this.updated) {
        this.getData();
        this.updated = false;
      }
    },
    changePage: function changePage(e, type) {
      if (!this.getFunctionsByType[type]) return;
      var additionalParams = "&limit=".concat(this.PAGE_SIZE, "&start=").concat(e.start, "&filters[name][]=").concat(this.filterString);
      additionalParams = this.onlyMy ? "".concat(additionalParams, "&filters[responsible][]=").concat(this.currentUser.id) : additionalParams;
      this.getFunctionsByType[type](additionalParams);
    },
    onSearch: function onSearch() {
      var _this9 = this;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(function () {
        _this9.getData(true);
      }, this.debounceTimeout);
    }
  }
};