import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "text-xs"
};
var _hoisted_2 = {
  key: 0,
  class: "absolute z-2 w-17rem -right-20px top-0.7rem bg-white border border-gray-450"
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  key: 0
};
var _hoisted_6 = {
  key: 1
};
var _hoisted_7 = {
  class: "with-submenu"
};
var _hoisted_8 = {
  key: 0,
  class: "absolute left-full top-0 w-fit bg-white border border-gray-450"
};
var _hoisted_9 = ["onClick"];
var _hoisted_10 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_feather_icon, {
    icon: "more-horizontal",
    class: "text-blue-750 stroke-1 h-full",
    onClick: $options.openMenu
  }, null, 8 /* PROPS */, ["onClick"]), $data.showMenu ? (_openBlock(), _createElementBlock("div", _hoisted_2, [!$props.menuItemsSettings.isLink ? (_openBlock(), _createElementBlock("div", _hoisted_3, [$props.menuItemsSettings.updateNodeFunction ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "flex items-center p-1.5 hover:bg-blue-250",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.menuItemClicked('update');
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "edit-2",
    class: "stroke-blue stroke-2 mr-1"
  }), _createElementVNode("span", null, _toDisplayString($props.menuItemsSettings.updateNodeLabel || $options.rightTransFunction('Update node')), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), !$props.menuItemsSettings.disableAddAction && !$props.menuItemsSettings.isProcessNode ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "flex items-center p-1.5 hover:bg-blue-250",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.menuItemClicked('add');
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-blue stroke-2 mr-1"
  }), _createElementVNode("span", null, _toDisplayString($props.menuItemsSettings.addSubNodeLabel || $options.rightTransFunction('Add sub node')), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getNextLevelLinks($props.menuItemsSettings.linkType), function (linkModule, index) {
    return _openBlock(), _createElementBlock("div", {
      key: "tree-menu-item".concat(index),
      class: "flex items-center p-1.5 hover:bg-blue-250",
      onClick: function onClick($event) {
        return $options.menuItemClicked('add link', {
          linkModule: linkModule
        });
      }
    }, [_createVNode(_component_feather_icon, {
      icon: "plus",
      class: "stroke-blue stroke-2 mr-1"
    }), _createElementVNode("span", null, _toDisplayString("".concat($options.rightTransFunction('Add'), " ").concat($props.linksStructureByModule[linkModule].name)), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_4);
  }), 128 /* KEYED_FRAGMENT */)), $props.menuItemsSettings.isProcessNode ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: "flex items-center p-1.5 hover:bg-blue-250",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $options.menuItemClicked('addProcessNode');
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-blue stroke-2 mr-1"
  }), _createElementVNode("span", null, _toDisplayString($options.rightTransFunction('Add sub node')), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.menuItemsSettings.createReportOption ? (_openBlock(), _createElementBlock("div", {
    key: 3,
    class: "flex items-center p-1.5 hover:bg-blue-250",
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return $options.menuItemClicked('report option');
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "list",
    class: "stroke-blue stroke-2 mr-1"
  }), $props.menuItemsSettings.isGdpr ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($options.rightTransFunction('Create Article 30 report')), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($options.rightTransFunction('Questionnaire report')), 1 /* TEXT */))])) : _createCommentVNode("v-if", true), $props.menuItemsSettings.showMemorandumReportLink ? (_openBlock(), _createElementBlock("div", {
    key: 4,
    class: "flex items-center p-1.5 hover:bg-blue-250",
    onClick: _cache[4] || (_cache[4] = function ($event) {
      return $options.menuItemClicked('memorandum report');
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "list",
    class: "stroke-blue stroke-2 mr-1"
  }), _createElementVNode("span", null, _toDisplayString($options.rightTransFunction('Memorandum report')), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.menuItemsSettings.copy && $props.menuItemsSettings.copySettings ? (_openBlock(), _createElementBlock("div", {
    key: 5,
    class: "flex items-center p-1.5 hover:bg-blue-250",
    onClick: _cache[5] || (_cache[5] = function ($event) {
      return $options.menuItemClicked('duplicate');
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "file",
    class: "stroke-blue stroke-2 mr-1"
  }), _createElementVNode("span", null, _toDisplayString($options.rightTransFunction('Duplicate node')), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.menuItemsSettings.copy && $props.menuItemsSettings.copySettings ? (_openBlock(), _createElementBlock("div", {
    key: 6,
    class: "flex items-center p-1.5 hover:bg-blue-250",
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return $options.menuItemClicked('duplicate with sub items');
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "file-plus",
    class: "stroke-blue stroke-2 mr-1"
  }), _createElementVNode("span", null, _toDisplayString($options.rightTransFunction('Duplicate node with sub nodes')), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.menuItemsSettings.copy && $props.menuItemsSettings.copyData && $props.menuItemsSettings.copySettings ? (_openBlock(), _createElementBlock("div", {
    key: 7,
    class: "flex items-center p-1.5 hover:bg-blue-250",
    onClick: _cache[7] || (_cache[7] = function ($event) {
      return $options.menuItemClicked('duplicate with sub items and data');
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "file-plus",
    class: "stroke-blue stroke-2 mr-1"
  }), _createElementVNode("span", null, _toDisplayString($options.rightTransFunction('Duplicate node with sub nodes and data')), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.menuItemsSettings.deleteNodeOption ? (_openBlock(), _createElementBlock("div", {
    key: 8,
    class: "flex items-center p-1.5 hover:bg-blue-250",
    onClick: _cache[8] || (_cache[8] = function ($event) {
      return $options.menuItemClicked('delete');
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "x",
    class: "stroke-blue stroke-2 mr-1"
  }), _createElementVNode("span", null, _toDisplayString($props.menuItemsSettings.deleteNodeLabel || $options.rightTransFunction('Delete node')), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.menuItemsSettings.templateSubmenuItems && $props.menuItemsSettings.templateSubmenuItems.length ? (_openBlock(), _createElementBlock("div", {
    key: 9,
    class: "flex items-center relative p-1.5 hover:bg-blue-250",
    onClick: _cache[9] || (_cache[9] = function () {
      return $options.toggleAddFromTemplateSubmenu && $options.toggleAddFromTemplateSubmenu.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus-circle",
    class: "stroke-blue stroke-2 mr-1"
  }), _createElementVNode("span", _hoisted_7, _toDisplayString($options.rightTransFunction('Add from template')), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    icon: $data.showAddFromTemplateSubmenu ? 'chevron-left' : 'chevron-right',
    class: "stroke-blue stroke-1 ml-auto"
  }, null, 8 /* PROPS */, ["icon"]), $data.showAddFromTemplateSubmenu ? (_openBlock(), _createElementBlock("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menuItemsSettings.templateSubmenuItems, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "p-1.5 hover:bg-blue-250",
      onClick: function onClick($event) {
        return $options.menuItemClicked('add from template', item.id);
      }
    }, _toDisplayString(item.name), 9 /* TEXT, PROPS */, _hoisted_9);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)])) : (_openBlock(true), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList($options.getNextLevelLinks($props.menuItemsSettings.linkType), function (linkModule, index) {
    return _openBlock(), _createElementBlock("div", {
      key: "tree-menu-item-else-".concat(index),
      class: "flex items-center p-1.5 hover:bg-blue-250",
      onClick: function onClick($event) {
        return $options.menuItemClicked('add link', {
          uniqueTreeId: $props.menuItemsSettings.uniqueTreeId,
          linkModule: linkModule
        });
      }
    }, [_createVNode(_component_feather_icon, {
      icon: "plus",
      class: "stroke-blue stroke-2 mr-1"
    }), _createElementVNode("span", null, _toDisplayString("".concat($options.rightTransFunction('Add'), " ").concat($props.linksStructureByModule[linkModule].name)), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_10);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, $options.closeMenu]]);
}