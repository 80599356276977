function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapActions } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { getAll as _getAllProjects, getProjectTypes as _getProjectTypes, updateProject } from '@/api/compliance/projects';
import { createComplianceProject as _createComplianceProject } from '@/api/rismaadmin/complianceProject';
import { getAllQuestionnaires as _getAllQuestionnaires } from '@/api/compliance/questionnaire';
import { getAvailableQuestionnaireTypes } from '@/utils/questionnaires';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import Notification from '@/components/Molecules/Notification';
import MainWell from '@/components/Atoms/Containers/MainWell';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import StaticTable from '@/components/Molecules/StaticTable';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaSwitch from '@/components/Atoms/Inputs/RismaSwitch';
import { StaticTableThemes } from '@/constants/StaticTableThemes';
export default {
  name: 'CreateComplianceProject',
  components: {
    SingleSelect: SingleSelect,
    MainWell: MainWell,
    RismaButton: RismaButton,
    InputField: InputField,
    RismaTitle: RismaTitle,
    StaticTable: StaticTable,
    FeatherIcon: FeatherIcon,
    Notification: Notification,
    RismaSwitch: RismaSwitch
  },
  data: function data() {
    return {
      questionnaires: [],
      origin: [],
      questionnaireType: null,
      questionnaireTypes: [],
      defaultQuestionnaire: null,
      projectType: null,
      projectTypes: [],
      projectName: '',
      projects: [],
      StaticTableThemes: StaticTableThemes,
      notification: {
        type: 'error',
        message: ''
      },
      treeComponentValues: {
        TREE: 'default',
        MINI_TREE: 'leftTree'
      },
      treeComponentValidTypes: [ProjectTypes.FINANCIAL_CONTROLS, ProjectTypes.ENTERPRISE_RISK_MANAGEMENT, ProjectTypes.CONTROL_SELF_ASSESSMENT, ProjectTypes.GDPR_NY],
      treeComponentSettingProperties: ['treeShowActions', 'treeShowControls', 'treeShowPolicies', 'treeShowPolicies', 'treeShowSystems'],
      translate: getTranslate['CreateComplianceProject']()
    };
  },
  computed: {
    questionnaireTypesForSingleSelect: function questionnaireTypesForSingleSelect() {
      return this.questionnaireTypes.map(function (item) {
        return {
          value: item.id,
          label: item.type
        };
      });
    },
    projectTypesToShowOverviewPage: function projectTypesToShowOverviewPage() {
      return [ProjectTypes.GDPR, ProjectTypes.ISO27001, ProjectTypes.CSRD];
    },
    switchTreeComponentOptions: function switchTreeComponentOptions() {
      return [{
        name: this.translate.default,
        value: this.treeComponentValues.TREE
      }, {
        name: this.translate.detailed,
        value: this.treeComponentValues.MINI_TREE
      }];
    },
    switchOverviewPageOptions: function switchOverviewPageOptions() {
      return [{
        name: this.translate.yes,
        value: true
      }, {
        name: this.translate.no,
        value: false
      }];
    },
    columns: function columns() {
      return [this.translate.name, this.translate.projectType, this.translate.questionnaireType, this.translate.defaultQuestionnaire, this.translate.showOverviewPage, this.translate.treeView, this.translate.enabledisable];
    },
    dataset: function dataset() {
      var _this = this;
      return this.projects.map(function (item) {
        return {
          title: item.title,
          projectType: _this.getProjectTypeById(item.type),
          questionnaireType: _this.getQuestionnaireTypeById(item.questionnaireType),
          defaultQuestionnaire: _this.getDefaultQuestionnaireById(item.defaultQuestionnaireId),
          showOverviewMenuItem: item,
          display: item,
          toggleEnable: item
        };
      });
    },
    questionnairesOptions: function questionnairesOptions() {
      var _this2 = this;
      return this.questionnaires.filter(function (questionnaire) {
        return +questionnaire.questionnaireType === +_this2.questionnaireType;
      }).map(function (questionnaire) {
        return {
          value: questionnaire.id,
          label: questionnaire.title
        };
      });
    },
    projectTypesOptions: function projectTypesOptions() {
      return this.projectTypes.map(function (item) {
        return {
          value: item.id,
          label: item.type
        };
      });
    }
  },
  mounted: function mounted() {
    this.getAllProjects();
    this.getProjectTypes();
    this.getQuestionnaireTypes();
    this.getAllQuestionnaires();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useUsersStore, {
    loadUsers: 'load'
  })), {}, {
    getAllProjects: function getAllProjects() {
      var _this3 = this;
      return _getAllProjects(true, 'data[]=defaultQuestionnaireId&filters[]=includeDisabled').then(function (_ref) {
        var list = _ref.list;
        _this3.projects = list;
      });
    },
    getProjectTypes: function getProjectTypes() {
      var _this4 = this;
      return _getProjectTypes().then(function (_ref2) {
        var list = _ref2.list;
        _this4.projectTypes = list;
      });
    },
    getQuestionnaireTypes: function getQuestionnaireTypes() {
      var _this5 = this;
      return getAvailableQuestionnaireTypes().then(function (list) {
        return _this5.questionnaireTypes = list;
      });
    },
    getAllQuestionnaires: function getAllQuestionnaires() {
      var _this6 = this;
      return _getAllQuestionnaires().then(function (_ref3) {
        var list = _ref3.list;
        _this6.questionnaires = list;
      });
    },
    createComplianceProject: function createComplianceProject() {
      var _this7 = this;
      if (!this.defaultQuestionnaire) {
        this.showNotification(this.translate.pleaseSelectADefaultQuestionnaire);
        return;
      }
      var projectObject = {
        projectName: this.projectName,
        projectType: this.projectType,
        defaultQuestionnaire: this.defaultQuestionnaire,
        questionnaireType: this.questionnaireType
      };
      return _createComplianceProject(projectObject).then(function () {
        _this7.showNotification(_this7.translate.projectCreated, 'success');
        _this7.resetFields();
        _this7.loadUsers();
      }).then(this.getAllProjects).catch(function (error) {
        _this7.showNotification(error);
      });
    },
    resetFields: function resetFields() {
      this.projectName = '';
      this.projectType = null;
      this.defaultQuestionnaire = null;
      this.questionnaireType = null;
    },
    handleClickProjectSetting: function handleClickProjectSetting(project, data, field) {
      var _this8 = this;
      updateProject(project.id, data).then(function (response) {
        var project = _this8.projects.find(function (project) {
          return +project.id === +response.id;
        });
        if (project) project[field] = response[field];
        if (field === 'enabled') {
          _this8.loadUsers();
        }
      });
    },
    getQuestionnaireTypeById: function getQuestionnaireTypeById(id) {
      var _this$questionnaireTy;
      return (_this$questionnaireTy = this.questionnaireTypes.find(function (type) {
        return +type.id === +id;
      })) === null || _this$questionnaireTy === void 0 ? void 0 : _this$questionnaireTy.type;
    },
    getDefaultQuestionnaireById: function getDefaultQuestionnaireById(id) {
      var _this$questionnaires$;
      return (_this$questionnaires$ = this.questionnaires.find(function (questionnaire) {
        return +questionnaire.id === +id;
      })) === null || _this$questionnaires$ === void 0 ? void 0 : _this$questionnaires$.title;
    },
    getProjectTypeById: function getProjectTypeById(id) {
      var _this$projectTypes$fi;
      return (_this$projectTypes$fi = this.projectTypes.find(function (project) {
        return +project.id === +id;
      })) === null || _this$projectTypes$fi === void 0 ? void 0 : _this$projectTypes$fi.type;
    },
    showNotification: function showNotification(message) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      this.notification.message = message;
      this.notification.type = type;
    }
  })
};