import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_table = _resolveComponent("risma-table");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("p", null, " Last query: " + _toDisplayString($data.query), 1 /* TEXT */), _createElementVNode("p", null, " Number of calls: " + _toDisplayString($data.numberOfCalls), 1 /* TEXT */)]), (_openBlock(), _createBlock(_component_risma_table, {
    key: $data.tableGeneratedKey,
    "print-title": "Test",
    "state-key": $data.stateKey,
    "show-search-field": true,
    api: $options.apiMock,
    onStarred: $options.onDataStarred,
    onIconClicked: $options.onIconClicked
  }, null, 8 /* PROPS */, ["state-key", "api", "onStarred", "onIconClicked"]))]);
}