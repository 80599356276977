function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { ControlTypeFields } from '@/constants/ControlTypeFields';
import { checkERMByProjectId } from '@/utils/risks/check';
export var ActionVisibleProps = [{
  key: '_custom-title',
  // Title with path & trafficLight (always visible)
  text: 'Title',
  default: true
}, {
  key: 'status',
  text: 'Present status',
  default: true
}, {
  key: 'description',
  text: 'Description',
  default: true
}, {
  key: 'target',
  text: 'Target'
}, {
  key: 'comment',
  text: 'Comment'
}, {
  key: 'start',
  text: 'Start',
  default: true
}, {
  key: 'deadline',
  text: 'Deadline',
  default: true
}, {
  key: 'responsible',
  text: 'Responsible',
  default: true
}, {
  key: 'organisations',
  text: 'Organisations'
}, {
  key: 'tags',
  text: 'Tags'
}, {
  key: 'dailyTeam',
  text: 'Daily team'
}, {
  key: 'dependingOn',
  text: 'Depending on'
}, {
  key: 'activityType',
  text: 'Initiative type',
  default: true
}, {
  key: 'completionPercentage',
  text: 'Completion percentage'
}
// Not implemented yet
// {
//     key: 'budget',
//     text: 'Budget'
// },
// {
//     key: 'forbrug',
//     text: 'Forbrug'
// },
// {
//     key: 'forventet',
//     text: 'Forventet antal timer'
// },
// {
//     key: 'forbrugAntalTimer',
//     text: 'Forbrug antal timer'
// },
];

export var IncidentVisibleProps = [{
  key: 'activityType',
  text: 'Activity type',
  default: true
}, {
  key: '_custom-title',
  text: 'Title',
  default: true
}, {
  key: 'severity',
  text: 'Severity',
  default: true
}, {
  key: 'createdAt',
  text: 'Created',
  default: true
}, {
  key: 'incidentHappenedAt',
  text: 'Incident occurred',
  default: true
}, {
  key: 'incidentArchived',
  text: 'Incident archived',
  default: true
}, {
  key: 'rismaState',
  text: 'Status',
  default: true
}, {
  key: 'description',
  text: 'Description',
  default: true
}, {
  key: 'accountableUserIds',
  text: 'Accountable',
  default: true
}, {
  key: 'responsibleUserIds',
  text: 'Responsible',
  default: true
}, {
  key: 'informedUserIds',
  text: 'Informed',
  default: true
}];
export var MotherControlsVisibleProps = [{
  key: '_custom-title',
  text: 'Title',
  default: true
}, {
  key: 'description',
  text: 'Description',
  default: true
}];
export function getControlVisibleProps(getSettingValue) {
  var additionColumns = [];
  if (getSettingValue('risma.show_key_control')) additionColumns.push({
    key: ControlTypeFields.KEY_CONTROL,
    text: 'Key control'
  });
  if (getSettingValue('risma.show_automatic_manual')) additionColumns.push({
    key: ControlTypeFields.KEY_AUTOMATIC_MANUAL,
    text: 'Manual vs automated'
  });
  if (getSettingValue('risma.show_preventive_detective')) additionColumns.push({
    key: ControlTypeFields.KEY_PREVENTIVE_DETECTIVE,
    text: 'Preventive vs detective'
  });
  if (getSettingValue('risma.show_high_complexity_low_complexity')) additionColumns.push({
    key: ControlTypeFields.KEY_HIGH_LOW_COMPLEXITY,
    text: 'Complexity'
  });
  return [{
    key: '_custom-title',
    text: 'Title',
    default: true
  }, {
    key: 'description',
    text: 'Description',
    default: true
  }, {
    key: 'accounts',
    text: getSettingValue('risma.account_to_name') || 'Accounts',
    default: true
  }, {
    key: 'risks',
    text: getSettingValue('risma.risk_to_name') || 'Risk',
    default: true
  }, {
    key: 'processes',
    text: getSettingValue('risma.process_to_name') || 'Process',
    default: true
  }].concat(additionColumns, [{
    key: 'frequency',
    text: 'Frequency'
  }, {
    key: 'responsible',
    text: 'Responsible',
    default: true
  }, {
    key: 'reviewers',
    text: 'Reviewers'
  }, {
    key: 'escalation',
    text: 'Escalation'
  }, {
    key: 'organisations',
    text: 'Organisations'
  }, {
    key: 'controlDeadline',
    text: 'Deadline',
    default: true
  }, {
    key: 'reviewDeadline',
    text: 'Review deadline'
  }, {
    key: 'rismaState',
    text: 'Control status'
  }, {
    key: 'tags',
    text: 'Tags',
    default: true
  }, {
    key: '_custom-button',
    text: 'Action',
    default: true
  }]);
}
export var RiskVisibleProps = [{
  key: '_custom-title',
  text: 'Title',
  default: true
}, {
  key: 'description',
  text: 'Description',
  default: true
}, {
  key: 'organisations',
  text: 'Organisations',
  default: true
}, {
  key: 'tags',
  text: 'Tags',
  default: true
}, {
  key: 'approvalStatus',
  text: 'Approved',
  default: true
}];
var getRiskERMAAdditionalProps = function getRiskERMAAdditionalProps(getSettingValue) {
  return [{
    key: 'dailyOwner',
    text: 'Daily risk owner',
    default: true
  }, {
    key: 'primaryOwner',
    text: 'Primary risk owner',
    default: true
  }, {
    key: 'likelihood',
    text: 'Likelihood',
    default: true
  }, {
    key: 'riskType',
    text: getSettingValue('risma.risktype_name'),
    default: true
  }];
};
var getRiskAdditionalProps = function getRiskAdditionalProps() {
  return [{
    key: 'responsibleUserIds',
    text: 'Responsible',
    default: true
  }, {
    key: 'threat',
    text: 'Threat',
    default: true
  }, {
    key: 'levelOfThreat',
    text: 'Level of threat',
    default: true
  }, {
    key: 'vulnerability',
    text: 'Vulnerability',
    default: true
  }];
};
export var getRiskVisibleProps = function getRiskVisibleProps(getSettingValue, projectId, currentUser) {
  if (checkERMByProjectId(projectId, currentUser)) {
    var additionProps = getRiskERMAAdditionalProps(getSettingValue);
    return [].concat(RiskVisibleProps, _toConsumableArray(additionProps));
  } else {
    var additionalProps = getRiskAdditionalProps();
    return [].concat(RiskVisibleProps, _toConsumableArray(additionalProps));
  }
};