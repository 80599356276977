function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import LinkItem from '@/components/Atoms/Containers/LinkItem';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import { Colors } from '@/Colors';
import { DateFormats } from '@/constants/DateFormats';
import { ActivityLinkModules } from '@/constants/ActivityLinkModules';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { MODULES } from '@/constants/modules';
import { getProperSelectedOptions } from '@/utils/Utils';
import { prepareDateTimezone } from '@/utils/date';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { Export, capitalize } from '@/utils';
var HTTP_USELESS_LINK = 'http://';
export default {
  name: 'ControlHistory',
  components: {
    LinkItem: LinkItem,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight,
    ActivityCustomFields: ActivityCustomFields
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Control object'
    }
  },
  data: function data() {
    return {
      Colors: Colors,
      RismaControlsUtils: RismaControlsUtils,
      record: this.data.history && this.data.history.length && this.data.history[0],
      mainColumns: {
        title: this.$trans('Title'),
        light: this.$trans('Traffic light'),
        deadlineText: this.$trans('Deadline text')
      },
      translate: getTranslate['ControlHistory']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'controlActivityTypes'
  })), {}, {
    commonColumns: function commonColumns() {
      var columns = {
        date: this.translate.date,
        action: this.translate.action,
        user: this.translate.user,
        attachments: this.translate.attachments,
        comment: this.translate.comment
      };
      if (this.assessmentsVisible) {
        columns.assessment = this.translate.efficiencyAssessment;
        columns.assessmentComment = this.translate.efficiencyAssessmentComment;
      }
      return columns;
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.activityTypes.find(function (item) {
        return item.id === _this.data.activityTypeId;
      });
    },
    assessmentsVisible: function assessmentsVisible() {
      var _this$currentActivity;
      return !!this.getSettingValue('feature.controls_efficiency_enabled') && !!((_this$currentActivity = this.currentActivityType) !== null && _this$currentActivity !== void 0 && _this$currentActivity.controlsEfficiencyType);
    }
  }),
  methods: {
    reverseReviewsOrder: function reverseReviewsOrder(reviews) {
      return reviews.slice().reverse();
    },
    getActivityByIdAndModule: function getActivityByIdAndModule(id) {
      var result = _objectSpread({}, this.activityTypes.find(function (item) {
        return item.id === id;
      }));
      if (result.fields && result.fields.length) {
        result.fields = result.fields.filter(function (field) {
          return field.activityLinkModule === ActivityLinkModules.CONTROL_COMPLETION;
        });
      }
      return result;
    },
    getUserById: function getUserById(id) {
      var user = this.data.users.find(function (user) {
        return user.id == id;
      });
      return user ? user.display_name : '';
    },
    formatedDate: function formatedDate(date, timezone) {
      return prepareDateTimezone(date, timezone, DateFormats.DATE_TIME_FORMAT);
    },
    getTrafficlightByNumber: function getTrafficlightByNumber(number) {
      return Colors.getControlTrafficLightByNumber(number).color;
    },
    getTrafficLightLabelByNumber: function getTrafficLightLabelByNumber(number) {
      return capitalize(Colors.getControlTrafficLightByNumber(number).label);
    },
    getBaseTrafficlightByNumber: function getBaseTrafficlightByNumber(number) {
      return Colors.getTrafficlightByNumber(number);
    },
    getTrafficTitle: function getTrafficTitle(title) {
      if (title === 'red') return this.translate.completedAfterDeadline;
      return this.translate.completedOnTime;
    },
    getFullTrafficTitle: function getFullTrafficTitle(title) {
      if (title === 'red') return this.translate.theControlWasCompletedAfterDeadline;
      return this.translate.theControlWasCompletedOnTime;
    },
    getHumanReadableAction: function getHumanReadableAction(action, userId) {
      if (action === 'control_not_performed' && userId) {
        return this.$trans(RismaControlsUtils.humanReadableAction('skipped'));
      }
      return this.$trans(RismaControlsUtils.humanReadableAction(action));
    },
    showCustomFields: function showCustomFields(record) {
      if (!this.activityTypes.length || !record.activityTypeId || !record.customFieldValues || !this.getActivityByIdAndModule(record.activityTypeId).fields.length) return;
      var result = false;
      record.customFieldValues.forEach(function (field) {
        if (field.model === MODULES.CONTROL_COMPLETION && (field.value || field.rows)) {
          result = true;
        }
      });
      return result;
    },
    handleExportToExcel: function handleExportToExcel() {
      var _this2 = this;
      var ws_data = [];
      this.data.history.forEach(function (record) {
        var title = _this2.translate.deadline + ' ' + _this2.formatedDate(record.deadline, record.deadlineTimezone);
        ws_data.push({
          title: {
            v: title
          }
        });
        ws_data.push({
          light: {
            v: _this2.$trans(_this2.getTrafficLightLabelByNumber(record.trafficLight))
          },
          deadlineText: {
            v: _this2.getTrafficTitle(record.trafficLightText)
          }
        });
        var mainFields = {};
        for (var key in _this2.commonColumns) {
          mainFields[key] = {
            v: _this2.commonColumns[key]
          };
        }
        ws_data.push(mainFields);
        var reviews = _this2.prepareReviews(record.reviews, record.deadlineTimezone);
        ws_data = [].concat(_toConsumableArray(ws_data), _toConsumableArray(reviews));
        if (record.customFieldValues && record.customFieldValues.length > 0) {
          ws_data.push({
            title: {
              v: _this2.translate.customFields
            }
          });
          record.customFieldValues.forEach(function (customFieldValue) {
            var _selectedOption$value, _selectedOption;
            var activityType = _this2.getActivityByIdAndModule(record.activityTypeId);
            var customField = activityType.fields.find(function (f) {
              return f.id === customFieldValue.fieldId;
            });
            if (customField) {
              var value,
                customFieldRows = [],
                selectedOption,
                ColumnLabels,
                idRowOrders = [],
                rowdata,
                preparedValues,
                valueR,
                selectedOptionR,
                customFieldR,
                selectedOptions = getProperSelectedOptions(customField.options);
              switch (customField.typeId) {
                case CustomFieldTypes.SELECT:
                  selectedOption = selectedOptions.find(function (opt) {
                    return opt.id === customFieldValue.value;
                  });
                  value = (_selectedOption$value = (_selectedOption = selectedOption) === null || _selectedOption === void 0 ? void 0 : _selectedOption.value) !== null && _selectedOption$value !== void 0 ? _selectedOption$value : '';
                  break;
                case CustomFieldTypes.TRAFFIC_LIGHT:
                  value = Colors.trafficlightColor(customFieldValue.value).label;
                  break;
                case CustomFieldTypes.ROW_GROUP:
                  ColumnLabels = {};
                  customFieldRows = Array.isArray(customFieldValue.rows) ? customFieldValue.rows : Object.values(customFieldValue.rows);
                  customField.children.forEach(function (child) {
                    idRowOrders.push(child.id);
                    ColumnLabels = _objectSpread(_objectSpread({}, ColumnLabels), {}, _defineProperty({}, 'label' + child.id, {
                      v: child.label
                    }));
                  });
                  ws_data.push({
                    name: {
                      v: customField.label + ':'
                    }
                  });
                  ws_data.push(ColumnLabels);
                  customFieldRows.forEach(function (row) {
                    rowdata = {};
                    preparedValues = {};
                    row.forEach(function (fieldValueR) {
                      var fieldSelectedOptions;
                      customFieldR = customField.children.find(function (f) {
                        return f.id === fieldValueR.fieldId;
                      });
                      if (customFieldR) {
                        switch (customFieldR.typeId) {
                          case CustomFieldTypes.SELECT:
                            fieldSelectedOptions = getProperSelectedOptions(customFieldR.options);
                            selectedOptionR = fieldSelectedOptions.find(function (opt) {
                              return opt.id === fieldValueR.value;
                            });
                            valueR = selectedOptionR.value;
                            break;
                          case CustomFieldTypes.TRAFFIC_LIGHT:
                            valueR = Colors.trafficlightColor(fieldValueR.value).label;
                            break;
                          default:
                            valueR = fieldValueR.value;
                            break;
                        }
                        preparedValues = _objectSpread(_objectSpread({}, preparedValues), {}, _defineProperty({}, customFieldR.id, valueR));
                      }
                    });
                    idRowOrders.forEach(function (item) {
                      var _preparedValues$item;
                      rowdata['value' + item] = {
                        v: (_preparedValues$item = preparedValues[item]) !== null && _preparedValues$item !== void 0 ? _preparedValues$item : ''
                      };
                    });
                    ws_data.push(rowdata);
                  });
                  return;
                default:
                  value = customFieldValue.value;
                  break;
              }
              ws_data.push({
                name: {
                  v: customField.label
                },
                value: {
                  v: value
                }
              });
            }
          });
        }
        ws_data.push([]); //empty line
      });

      Export.json2Excel(ws_data, [].concat(_toConsumableArray(Object.values(this.mainColumns)), _toConsumableArray(Object.values(this.commonColumns))));
    },
    prepareReviews: function prepareReviews(list, timezone) {
      var _this3 = this;
      var records = [];
      list.forEach(function (item) {
        var lines = [];
        var user = _this3.data.users.find(function (user) {
          return user.id == item.userId;
        });
        lines.push({
          date: {
            v: _this3.formatedDate(item.created, timezone)
          },
          action: {
            v: _this3.$trans(RismaControlsUtils.humanReadableAction(item.action))
          },
          user: {
            v: user ? user.display_name : item.userId
          },
          attachments: {
            v: item.urlTitle || ''
          },
          comment: {
            v: item.comment || ''
          }
        });
        if (_this3.assessmentsVisible && item.completedEfficiency !== undefined && item.completedEfficiency !== null) {
          var _this3$getBaseTraffic;
          lines[lines.length - 1].assessment = {
            v: _this3.$trans(capitalize(((_this3$getBaseTraffic = _this3.getBaseTrafficlightByNumber(item.completedEfficiency)) === null || _this3$getBaseTraffic === void 0 ? void 0 : _this3$getBaseTraffic.label) || ''))
          };
          lines[lines.length - 1].assessmentComment = {
            v: item.completedEfficiencyComment
          };
        }
        if (item.files) {
          item.files.forEach(function (file, index) {
            var attachments = {
              v: file.name
            };
            if (!item.urlTitle && index === 0) {
              lines[lines.length - 1].attachments = attachments;
            } else {
              lines.push({
                date: {
                  v: ''
                },
                action: {
                  v: ''
                },
                user: {
                  v: ''
                },
                attachments: attachments,
                comment: {
                  v: ''
                }
              });
            }
          });
        }
        records = [].concat(_toConsumableArray(records), lines);
      });
      return records;
    },
    isCorrectUrl: function isCorrectUrl(url) {
      return url && url !== HTTP_USELESS_LINK;
    }
  }
};