function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import { RiskDegreeTitleMixin } from '@/mixins/RiskDegreeTitleMixin';
import { toLocalDateTimeFull } from '@/utils/date';
export default {
  name: 'NetGross',
  introduction: 'Component for risk2 RiskDescription',
  token: '<net-gross :risk-calculations="{}"/>',
  components: {
    TinyMceBox: TinyMceBox,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect
  },
  mixins: [RiskDegreeTitleMixin],
  props: {
    risk: {
      type: Object,
      required: true,
      note: 'Risk data'
    },
    scoring: {
      type: Object,
      default: function _default() {}
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Is read only mode switch on'
    }
  },
  emits: ['update'],
  data: function data() {
    return {
      data: {},
      localData: {},
      translate: getTranslate['NetGross']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    keepCommentFieldsExpanded: function keepCommentFieldsExpanded() {
      return !!this.getSettingValue('feature.risk_comments_field_on_axis');
    },
    grossUpdatedUTCToLocal: function grossUpdatedUTCToLocal() {
      if (!this.data.grossUpdated) return '';
      return toLocalDateTimeFull(this.data.grossUpdated);
    },
    impactLabel: function impactLabel() {
      return this.scoring.impact_label || this.translate.impact;
    },
    likelihoodLabel: function likelihoodLabel() {
      return this.scoring.likelihood_label || this.translate.likelihood;
    },
    riskCalculationsEnabled: function riskCalculationsEnabled() {
      return this.risk.riskCalculations !== undefined;
    },
    impactOptions: function impactOptions() {
      if (!this.scoring.impact_values) return [];
      var result = [{
        value: 0,
        label: this.translate.notSet
      }];
      for (var key in this.scoring.impact_values) {
        var label = this.scoring.impact_values[key];
        result.push({
          value: +key,
          label: key + ': ' + label
        });
      }
      return result;
    },
    likelihoodOptions: function likelihoodOptions() {
      var _this = this;
      if (!this.scoring.likelihood_labels) return [];
      var result = [{
        value: 0,
        label: this.translate.notSet
      }];
      this.scoring.likelihood_labels.forEach(function (value) {
        result.push({
          value: +value.likelihood_number,
          label: value.likelihood_number + ': ' + "".concat(_this.scoring.likelihood_values[value.likelihood_number], " ").concat(value.description)
        });
      });
      return result;
    },
    formulaExplanationTolerance: function formulaExplanationTolerance() {
      if (this.getSettingValue('risma.risk_calculation_formula') === 0) {
        return ': (' + this.data.toleranceImpact + '×' + this.data.toleranceImpact + ')/2 × ' + this.data.toleranceLikelihood;
      }
      if (this.getSettingValue('risma.risk_calculation_formula') === 1) {
        return ': (' + this.data.toleranceLikelihood + '×' + this.data.toleranceLikelihood + ')/2 × ' + this.data.toleranceImpact;
      }
      return ': ' + this.data.toleranceLikelihood + ' × ' + this.data.toleranceImpact;
    },
    formulaExplanationExposure: function formulaExplanationExposure() {
      if (this.getSettingValue('risma.risk_calculation_formula') === 0) {
        return ': (' + this.data.impact_score + '×' + this.data.impact_score + ')/2 × ' + this.data.likelihood_score;
      }
      if (this.getSettingValue('risma.risk_calculation_formula') === 1) {
        return ': (' + this.data.likelihood_score + '×' + this.data.likelihood_score + ')/2 × ' + this.data.impact_score;
      }
      return ': ' + this.data.likelihood_score + ' × ' + this.data.impact_score;
    },
    hideTolerance: function hideTolerance() {
      return this.getSettingValue('feature.risk_hide_tolerance');
    }
  }),
  watch: {
    risk: function risk() {
      this.updateCalculations();
    }
  },
  beforeMount: function beforeMount() {
    this.updateCalculations();
  },
  methods: {
    updateCalculations: function updateCalculations() {
      this.data = _objectSpread(_objectSpread({}, this.risk.riskCalculations), {}, {
        impact_score: this.risk.impact_score,
        likelihood_score: this.risk.likelihood_score,
        rpi: this.risk.rpi
      });
      this.localData = _objectSpread({}, this.data);
    },
    onCommentChanged: function onCommentChanged(comment) {
      this.localData.calculationsComment = comment;
      this.update();
    },
    update: function update() {
      this.$emit('update', {
        riskCalculations: this.localData
      });
    }
  }
};