import { getTranslate } from './translate';
import { getAllScorings, createScoring } from '@/api/risk/scoring';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import StaticTable from '@/components/Molecules/StaticTable';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import { ErmRiskStatusText, ErmRiskStatus } from '@/constants/risks/RiskStatus';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
export default {
  name: 'RiskPeriodsWell',
  token: '<risk-periods-well />',
  components: {
    FeatherIcon: FeatherIcon,
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    StaticTable: StaticTable,
    NotificationPopUp: NotificationPopUp
  },
  mixins: [NotificationPopUpMixin],
  data: function data() {
    return {
      scorings: [],
      showAlert: false,
      isBtnDisabled: false,
      translate: getTranslate['RiskPeriodsWell']()
    };
  },
  computed: {
    columns: function columns() {
      return [this.translate.period, this.translate.risks, this.translate.status];
    },
    dataset: function dataset() {
      var _this = this;
      return this.scorings.map(function (scoring) {
        return {
          title: {
            title: scoring.version,
            link: "/settings/ermadmin/scoring/".concat(scoring.id)
          },
          risks: scoring.riskCount || 0,
          status: _this.$trans(ErmRiskStatusText[scoring.status])
        };
      });
    },
    openScoring: function openScoring() {
      return this.scorings.find(function (scoring) {
        return scoring.status === ErmRiskStatus.OPEN;
      });
    },
    editingScoring: function editingScoring() {
      return this.scorings.find(function (scoring) {
        return scoring.status === ErmRiskStatus.EDITING;
      });
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    getAllScorings().then(function (response) {
      _this2.scorings = response.list;
      _this2.scorings.reverse();
    });
  },
  methods: {
    createNewScoring: function createNewScoring() {
      var _this3 = this;
      if (this.editingScoring) {
        return this.setNotificationData({
          title: this.translate.itIsOnlyPossibleToHaveOneScoringPeriodWit,
          type: 'error'
        });
      }
      var titleWithVersion = this.getScoringName(this.openScoring.version);
      return createScoring({
        version: titleWithVersion
      }).then(function (scoring) {
        _this3.scorings.unshift(scoring);
        _this3.$router.push("/settings/ermadmin/scoring/".concat(scoring.id));
      }).catch(function (error) {
        return _this3.setNotificationData({
          title: _this3.$trans(error.response.error),
          type: 'error'
        });
      }).finally(function () {
        _this3.isBtnDisabled = false;
      });
    },
    getScoringName: function getScoringName(periodName) {
      var maxLength = 30;
      var versionIdentifier = ' v';
      var defaultVersion = 2;
      var versionIncrement = 1;
      var versionRegex = / v(\d+)$/;
      var matches = periodName.match(versionRegex);
      if (matches) {
        var currentVersion = parseInt(matches[1]);
        var newVersion = versionIdentifier + (currentVersion + versionIncrement);
        if (newVersion.length > matches[0].length && periodName.length >= maxLength) {
          return periodName.substring(0, maxLength - newVersion.length) + newVersion;
        }
        return periodName.replace(versionRegex, newVersion);
      }
      if (periodName.length + versionIdentifier.length + 1 < maxLength) {
        return periodName + versionIdentifier + defaultVersion;
      }
      var baseName = periodName.substring(0, maxLength - versionIdentifier.length - 1);
      return baseName + versionIdentifier + defaultVersion;
    }
  }
};