import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TabItem from '@/components/Atoms/TabItem/TabItem';
import TabItemNew from '@/components/Atoms/TabItem/TabItemNew';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: '',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    TabItem: TabItem,
    TabItemNew: TabItemNew
  }
};