import { stripHtml } from 'string-strip-html';
export function stripRecognisedHTMLOnly(string) {
  return stripHtml(string, {
    stripRecognisedHTMLOnly: true
  }).result;
}
export function escapeHtml(text) {
  if (!text) {
    return '';
  }
  var map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    '\'': '&#039;'
  };
  return text.replace(/[&<>"']/g, function (m) {
    return map[m];
  });
}