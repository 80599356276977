import MonthlyOverview from '@/components/Molecules/MonthlyOverview';
export default {
  name: 'ContractsMonthlyOverview',
  components: {
    MonthlyOverview: MonthlyOverview
  },
  props: {
    status: {
      type: String,
      require: true,
      default: ''
    },
    datasets: {
      type: Object,
      require: true,
      default: function _default() {},
      note: 'Dataset for monthly overview'
    }
  },
  emits: ['active'],
  computed: {
    dataset: function dataset() {
      return this.datasets[this.status][this.currentYear] || [];
    },
    currentYear: function currentYear() {
      return new Date().getFullYear();
    }
  },
  methods: {
    setActiveMonth: function setActiveMonth(index) {
      this.$emit('active', this.status, index, this.currentYear);
    }
  }
};