function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { updateApprovalStatus } from '@/api/risk/risk';
import MainWell from '@/components/Atoms/Containers/MainWell';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import StaticTable from '@/components/Molecules/StaticTable';
import Notification from '@/components/Molecules/Notification';
import NoSearchResults from '@/components/Pages/Compliance/Reports/NoSearchResults';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import { toLocalDate } from '@/utils/date';
import * as Utils from '@/utils/Utils';
var SELECT_VALUES = {
  all: 'all',
  user: 'user',
  organisation: 'organisation'
};
export default {
  name: 'ApprovalStatusWell',
  token: '<approval-status-well />',
  components: {
    MainWell: MainWell,
    NoSearchResults: NoSearchResults,
    Notification: Notification,
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    StaticTable: StaticTable
  },
  props: {
    risks: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['approved'],
  data: function data() {
    return {
      mainFilter: '',
      selectedUser: '',
      selectedOrganisation: '',
      notificationMessage: '',
      notificationType: 'error',
      timer: null,
      translate: getTranslate['ApprovalStatusWell']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), {}, {
    columns: function columns() {
      return [this.translate.title, this.translate.primaryOwner, this.translate.organisation, this.translate.approvalDate];
    },
    filteredRisks: function filteredRisks() {
      if (this.selectedUser) return this.filterRiskByUserId(this.risksApproved, this.selectedUser);
      if (this.selectedOrganisation) return this.filterRiskByOrganisationId(this.risksApproved, this.selectedOrganisation);
      if (this.mainFilter === SELECT_VALUES.all) return this.risksApproved;
      return [];
    },
    dataset: function dataset() {
      var _this = this;
      return this.filteredRisks.map(function (risk) {
        return {
          title: risk.title,
          primaryOwner: Utils.findValueById(_this.users, +risk[RiskBaseModelProps.USER_IDS][RiskBaseModelProps.ACCOUNTABLE], 'display_name'),
          organisation: Utils.findPropsByIds(risk[RiskBaseModelProps.ORGANISATION_IDS], _this.organisations, 'visible_name'),
          approvalDate: toLocalDate(risk.approvedDate)
        };
      });
    },
    isUsersSelectorShown: function isUsersSelectorShown() {
      return this.mainFilter === SELECT_VALUES.user;
    },
    isOrganisationsSelectorShown: function isOrganisationsSelectorShown() {
      return this.mainFilter === SELECT_VALUES.organisation;
    },
    showTable: function showTable() {
      if (this.mainFilter === SELECT_VALUES.all) return true;
      return this.mainFilter !== SELECT_VALUES.all && (this.selectedUser || this.selectedOrganisation);
    },
    options: function options() {
      return [{
        label: this.translate.all,
        value: SELECT_VALUES.all
      }, {
        label: this.translate.user,
        value: SELECT_VALUES.user
      }, {
        label: this.translate.organisation,
        value: SELECT_VALUES.organisation
      }];
    },
    usersOptions: function usersOptions() {
      return this.users.map(function (user) {
        return {
          label: user.display_name,
          value: user.id
        };
      });
    },
    organisationsOptions: function organisationsOptions() {
      return this.organisations.map(function (organisation) {
        return {
          label: organisation.name,
          value: organisation.id
        };
      });
    },
    risksApproved: function risksApproved() {
      return this.risks.filter(function (risk) {
        return risk.approved;
      });
    }
  }),
  methods: {
    filterRiskByUserId: function filterRiskByUserId(risks, userId) {
      return risks.filter(function (risk) {
        return +risk.userIds.accountable === +userId;
      });
    },
    filterRiskByOrganisationId: function filterRiskByOrganisationId(risks, organisationId) {
      var orgIdString = organisationId.toString();
      return risks.filter(function (risk) {
        return risk.organisationIds.indexOf(orgIdString) !== -1;
      });
    },
    handleClear: function handleClear() {
      var _this2 = this;
      var riskIds = this.filteredRisks.map(function (risk) {
        return risk.id;
      });
      updateApprovalStatus({
        riskIds: riskIds,
        approve: false
      }).then(function () {
        var seccessMessage = _this2.getSuccessNotification(_this2.filteredRisks.length);
        _this2.resetFilters();
        _this2.setNotification(seccessMessage, 'success');
        _this2.dismissNotification();
        _this2.$emit('approved', riskIds);
      });
    },
    resetAdditionalFilter: function resetAdditionalFilter() {
      this.selectedUser = '';
      this.selectedOrganisation = '';
    },
    resetFilters: function resetFilters() {
      this.mainFilter = '';
      this.resetAdditionalFilter();
    },
    setNotification: function setNotification(message) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      this.notificationMessage = message;
      this.notificationType = type;
    },
    getSuccessNotification: function getSuccessNotification(number) {
      return "".concat(number, " ").concat(this.translate.risksHadTheirApprovalRemoved);
    },
    dismissNotification: function dismissNotification() {
      var _this3 = this;
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        _this3.setNotification('');
      }, 3000);
    }
  }
};