import { getTranslate } from './translate';
import StaticTable from '@/components/Molecules/StaticTable';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { createQuestionnaireFromTemplate as _createQuestionnaireFromTemplate } from '@/api/compliance/questionnaire';
import { toLocalDateTime } from '@/utils/date';
export default {
  name: 'TemplateList',
  components: {
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    StaticTable: StaticTable
  },
  props: {
    type: {
      type: String,
      required: true
    },
    templates: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    selectedType: {
      type: [String, Number],
      required: false,
      default: ''
    }
  },
  emits: ['created'],
  data: function data() {
    return {
      translate: getTranslate['TemplateList']()
    };
  },
  computed: {
    templateColumns: function templateColumns() {
      if (this.type === 'questionnaire') {
        return [this.translate.name, this.translate.type, this.translate.tabLabel, this.translate.questionCount, this.translate.date, this.translate.create];
      }
      if (this.type === 'gap') {
        return [this.translate.name, this.translate.type, this.translate.gapCount, this.translate.date, this.translate.create];
      }
      return [];
    },
    filteredTemplates: function filteredTemplates() {
      var _this = this;
      return this.templates.filter(function (template) {
        return +template.type === +_this.selectedType;
      });
    },
    formattedTemplates: function formattedTemplates() {
      var _this2 = this;
      return this.filteredTemplates.map(function (template) {
        if (_this2.type === 'questionnaire') {
          return {
            title: template.title,
            type: template.typeString,
            tabLabel: template.tabLabel,
            questionCount: template.questionCount,
            date: toLocalDateTime(template.date),
            create: template.id
          };
        }
        if (_this2.type === 'gap') {
          return {
            title: template.title,
            type: template.typeString,
            questionCount: template.gapCount,
            date: toLocalDateTime(template.date),
            create: template.id
          };
        }
      });
    }
  },
  methods: {
    createQuestionnaireFromTemplate: function createQuestionnaireFromTemplate(id) {
      var _this3 = this;
      _createQuestionnaireFromTemplate(id).then(function () {
        _this3.$emit('created');
      });
    }
  }
};