import { Colors } from '@/Colors';
import { convertHexToRgba } from '@/utils/colors';
export default {
  name: 'PercentageBox',
  introduction: 'Component to display numeric values',
  description: 'Allow to display numeric values in the colored box. Percentages are preferable but not required. Color should be in #hex format',
  token: '<percentage-box color="#eee">50%</percentage-box>',
  props: {
    color: {
      type: String,
      required: false,
      default: Colors.notSet,
      note: 'should be HEX'
    }
  },
  computed: {
    backgroundColor: function backgroundColor() {
      return convertHexToRgba(this.color, .25);
    }
  }
};