export default {
  name: 'SaveStatus',
  introduction: 'Save status',
  description: 'This loading Indicator is used to show save status on a page. It will display as a little grey box in the top right corner',
  token: '<save-status :status="Saved" />',
  props: {
    status: {
      required: true,
      type: String,
      note: 'A string representing the current save status'
    }
  },
  data: function data() {
    return {};
  }
};