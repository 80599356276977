import RismaCarousel from '@/components/Molecules/RismaCarousel/RismaCarousel';
export default {
  components: {
    RismaCarousel: RismaCarousel
  },
  data: function data() {
    return {
      list: [{
        id: 1,
        text: 'Some title 1',
        style: 'bg-blue-60 mx-4 text-center p-4'
      }, {
        id: 2,
        text: 'Some title 2',
        style: 'bg-blue-610 mx-4 text-center p-4'
      }, {
        id: 3,
        text: 'Some title 3',
        style: 'bg-blue-110 mx-4 text-center p-4'
      }, {
        id: 4,
        text: 'Some title 4',
        style: 'bg-blue-350 mx-4 text-center p-4'
      }, {
        id: 5,
        text: 'Some title 5',
        style: 'bg-blue-450 mx-4 text-center p-4'
      }, {
        id: 6,
        text: 'Some title 6',
        style: 'bg-blue-210 mx-4 text-center p-4'
      }, {
        id: 7,
        text: 'Some title 7',
        style: 'bg-blue-450 mx-4 text-center p-4'
      }, {
        id: 8,
        text: 'Some title 8',
        style: 'bg-blue-60 mx-4 text-center p-4'
      }]
    };
  }
};