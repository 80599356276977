import AttachmentChoice from '@/components/Molecules/Questionnaire/AttachmentChoice';
export default {
  name: '',
  components: {
    AttachmentChoice: AttachmentChoice
  },
  data: function data() {
    return {
      attachmentchoice: {
        files: []
      }
    };
  }
};