import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6d959e12"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "risk-erm-assessment"
};
var _hoisted_2 = {
  class: "grid grid-cols-4 gap-4"
};
var _hoisted_3 = {
  key: 0,
  class: "w-full mb-4 mr-4"
};
var _hoisted_4 = {
  key: 1,
  class: "w-full mb-4 mr-4"
};
var _hoisted_5 = {
  key: 2,
  class: "w-full mb-4 mr-4"
};
var _hoisted_6 = {
  key: 3,
  class: "w-full mb-4 mr-4"
};
var _hoisted_7 = {
  key: 4,
  class: "w-full mb-4"
};
var _hoisted_8 = {
  key: 0,
  class: "w-full mb-4 mr-4"
};
var _hoisted_9 = {
  key: 1,
  class: "w-full mb-4 mr-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_custom_axis_table = _resolveComponent("custom-axis-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.scoring.customLikelihoodAxis == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_single_select, {
    modelValue: $options.riskOptions.likelihood_score,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $options.riskOptions.likelihood_score = $event;
    }), _cache[1] || (_cache[1] = function ($event) {
      return $options.onStateChanged({
        property: 'likelihood_score',
        value: $event
      });
    })],
    title: $data.translate.likelihood,
    placeholder: $data.translate.selectProbability,
    options: $options.probabilitiesOptions,
    disabled: $props.readOnly,
    "un-sorted": true,
    "with-reset": true
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options", "disabled"]), _createVNode(_component_tiny_mce_box, {
    type: "comment",
    height: "250px",
    "initial-content": $data.comments[$data.RiskBaseModelProps.LIKELIHOOD_COMMENT],
    "read-only": $props.readOnly,
    "always-open": $options.keepCommentFieldsExpanded,
    onChanged: _cache[2] || (_cache[2] = function ($event) {
      return $options.onCommentChanged($data.RiskBaseModelProps.LIKELIHOOD_COMMENT, $event);
    })
  }, null, 8 /* PROPS */, ["initial-content", "read-only", "always-open"])])) : _createCommentVNode("v-if", true), $props.scoring.customImpactAxis == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_single_select, {
    modelValue: $options.riskOptions.impact_score,
    "onUpdate:modelValue": [_cache[3] || (_cache[3] = function ($event) {
      return $options.riskOptions.impact_score = $event;
    }), _cache[4] || (_cache[4] = function ($event) {
      return $options.onStateChanged({
        property: 'impact_score',
        value: $event
      });
    })],
    title: $data.translate.consequence,
    placeholder: $data.translate.selectImpact,
    options: $options.impactOptions,
    disabled: $props.readOnly,
    "un-sorted": true,
    "with-reset": true
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options", "disabled"]), _createVNode(_component_tiny_mce_box, {
    type: "comment",
    height: "250px",
    "initial-content": $data.comments[$data.RiskBaseModelProps.IMPACT_COMMENT],
    "read-only": $props.readOnly,
    "always-open": $options.keepCommentFieldsExpanded,
    onChanged: _cache[5] || (_cache[5] = function ($event) {
      return $options.onCommentChanged($data.RiskBaseModelProps.IMPACT_COMMENT, $event);
    })
  }, null, 8 /* PROPS */, ["initial-content", "read-only", "always-open"])])) : _createCommentVNode("v-if", true), $props.scoring.impactOther1InUse ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_single_select, {
    modelValue: $options.riskOptions.impact_other1_score,
    "onUpdate:modelValue": [_cache[6] || (_cache[6] = function ($event) {
      return $options.riskOptions.impact_other1_score = $event;
    }), _cache[7] || (_cache[7] = function ($event) {
      return $options.onStateChanged({
        property: 'impact_other1_score',
        value: $event
      });
    })],
    title: $props.scoring.impactOther1Label,
    placeholder: $data.translate.choose,
    options: $options.impactOther1Options,
    disabled: $props.readOnly,
    "un-sorted": true,
    "with-reset": true
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options", "disabled"]), _createVNode(_component_tiny_mce_box, {
    type: "comment",
    height: "250px",
    "initial-content": $data.comments[$data.RiskBaseModelProps.IMPACT_OTHER1_COMMENT],
    "read-only": $props.readOnly,
    "always-open": $options.keepCommentFieldsExpanded,
    onChanged: _cache[8] || (_cache[8] = function ($event) {
      return $options.onCommentChanged($data.RiskBaseModelProps.IMPACT_OTHER1_COMMENT, $event);
    })
  }, null, 8 /* PROPS */, ["initial-content", "read-only", "always-open"])])) : _createCommentVNode("v-if", true), $props.scoring.impactOther2InUse ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_single_select, {
    modelValue: $options.riskOptions.impact_other2_score,
    "onUpdate:modelValue": [_cache[9] || (_cache[9] = function ($event) {
      return $options.riskOptions.impact_other2_score = $event;
    }), _cache[10] || (_cache[10] = function ($event) {
      return $options.onStateChanged({
        property: 'impact_other2_score',
        value: $event
      });
    })],
    title: $props.scoring.impactOther2Label,
    placeholder: $data.translate.choose,
    options: $options.impactOther2Options,
    disabled: $props.readOnly,
    "un-sorted": true,
    "with-reset": true
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options", "disabled"]), _createVNode(_component_tiny_mce_box, {
    type: "comment",
    height: "250px",
    "initial-content": $data.comments[$data.RiskBaseModelProps.IMPACT_OTHER2_COMMENT],
    "read-only": $props.readOnly,
    "always-open": $options.keepCommentFieldsExpanded,
    onChanged: _cache[11] || (_cache[11] = function ($event) {
      return $options.onCommentChanged($data.RiskBaseModelProps.IMPACT_OTHER2_COMMENT, $event);
    })
  }, null, 8 /* PROPS */, ["initial-content", "read-only", "always-open"])])) : _createCommentVNode("v-if", true), $props.scoring.z_axis_name && $props.scoring.z_axis_name !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_single_select, {
    modelValue: $options.riskOptions.z_axis_score,
    "onUpdate:modelValue": [_cache[12] || (_cache[12] = function ($event) {
      return $options.riskOptions.z_axis_score = $event;
    }), _cache[13] || (_cache[13] = function ($event) {
      return $options.onStateChanged({
        property: 'z_axis_score',
        value: $event
      });
    })],
    title: $props.scoring.z_axis_name,
    placeholder: $data.translate.choose,
    options: $options.zAxisOptions,
    disabled: $props.readOnly,
    "un-sorted": true,
    "with-reset": true
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options", "disabled"]), _createVNode(_component_tiny_mce_box, {
    type: "comment",
    height: "250px",
    "initial-content": $data.comments[$data.RiskBaseModelProps.Z_AXIS_COMMENT],
    "read-only": $props.readOnly,
    "always-open": $options.keepCommentFieldsExpanded,
    onChanged: _cache[14] || (_cache[14] = function ($event) {
      return $options.onCommentChanged($data.RiskBaseModelProps.Z_AXIS_COMMENT, $event);
    })
  }, null, 8 /* PROPS */, ["initial-content", "read-only", "always-open"])])) : _createCommentVNode("v-if", true)]), $props.scoring.customLikelihoodAxis > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: $props.scoring.customLikelihood.label,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_custom_axis_table, {
    "axis-rows": $options.getAxisRows($props.scoring[$data.RiskBaseModelProps.CUSTOM_LIKELIHOOD].combinedFields),
    "table-second-column": Object.values($props.scoring[$data.RiskBaseModelProps.CUSTOM_LIKELIHOOD].options),
    "calculation-type": $props.scoring[$data.RiskBaseModelProps.CUSTOM_LIKELIHOOD].calculationType || 1,
    "read-only": $props.readOnly,
    "show-column0": true,
    "final-axis-score": $options.riskOptions.likelihood_score - 1,
    onChanged: _cache[15] || (_cache[15] = function ($event) {
      return _ctx.$emit('updateCustom', $event);
    })
  }, null, 8 /* PROPS */, ["axis-rows", "table-second-column", "calculation-type", "read-only", "final-axis-score"]), _createVNode(_component_tiny_mce_box, {
    type: "comment",
    height: "200px",
    "initial-content": $data.comments[$data.RiskBaseModelProps.LIKELIHOOD_COMMENT],
    "read-only": $props.readOnly,
    "always-open": $options.keepCommentFieldsExpanded,
    onChanged: _cache[16] || (_cache[16] = function ($event) {
      return $options.onCommentChanged($data.RiskBaseModelProps.LIKELIHOOD_COMMENT, $event);
    })
  }, null, 8 /* PROPS */, ["initial-content", "read-only", "always-open"])])) : _createCommentVNode("v-if", true), $props.scoring.customImpactAxis > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $props.scoring.customImpact.label,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_custom_axis_table, {
    "axis-rows": $options.getAxisRows($props.scoring[$data.RiskBaseModelProps.CUSTOM_IMPACT].combinedFields),
    "table-second-column": Object.values($props.scoring[$data.RiskBaseModelProps.CUSTOM_IMPACT].options),
    "calculation-type": $props.scoring[$data.RiskBaseModelProps.CUSTOM_IMPACT].calculationType || 1,
    "read-only": $props.readOnly,
    "show-column0": true,
    "final-axis-score": $options.riskOptions.impact_score - 1,
    onChanged: _cache[17] || (_cache[17] = function ($event) {
      return _ctx.$emit('updateCustom', $event);
    })
  }, null, 8 /* PROPS */, ["axis-rows", "table-second-column", "calculation-type", "read-only", "final-axis-score"]), _createVNode(_component_tiny_mce_box, {
    type: "comment",
    height: "200px",
    "initial-content": $data.comments[$data.RiskBaseModelProps.IMPACT_COMMENT],
    "read-only": $props.readOnly,
    "always-open": $options.keepCommentFieldsExpanded,
    onChanged: _cache[18] || (_cache[18] = function ($event) {
      return $options.onCommentChanged($data.RiskBaseModelProps.IMPACT_COMMENT, $event);
    })
  }, null, 8 /* PROPS */, ["initial-content", "read-only", "always-open"])])) : _createCommentVNode("v-if", true)]);
}