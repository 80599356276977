function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { ChoiceViewTypes } from '@/constants/ChoiceViewTypes';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
export default {
  name: 'SingleChoice',
  introduction: 'Single choice is list with checkboxes or dropdown view',
  description: 'Single choice is list with checkboxes or dropdown view based on feature flag - risma.single_choice_as_dropdown.',
  token: "\n<single-choice options=\"options\" answers=\"answers\" @answered=\"fn\"/>\n// Properties looks like\noptions: { 1: 'Yes', 2: 'No' }\nanswers: { 1: 'Yes' }\n",
  components: {
    SingleSelect: SingleSelect,
    RadioButtons: RadioButtons
  },
  props: {
    deselectable: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Allow deselect choice'
    },
    options: {
      type: Array,
      required: true,
      note: 'A list of items'
    },
    answers: {
      type: [Array, Object],
      required: true,
      note: 'Predefined answers'
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Questionn is be read-only'
    },
    radioButtonFocus: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Determine whether selected or first button will be focused after mounting'
    }
  },
  emits: ['answered'],
  data: function data() {
    return {
      value: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    viewTypes: function viewTypes() {
      return ChoiceViewTypes;
    },
    viewType: function viewType() {
      return this.getSettingValue('risma.single_choice_as_dropdown') ? this.viewTypes.DROPDOWN : this.viewTypes.RADIO_BUTTONS;
    },
    optionsArray: function optionsArray() {
      return this.options.map(function (option) {
        return {
          value: option.id,
          label: option.label
        };
      });
    }
  }),
  watch: {
    answers: {
      deep: true,
      handler: function handler(answerUpdated) {
        this.value = +Object.keys(answerUpdated)[0] || false;
      }
    }
  },
  created: function created() {
    this.value = +Object.keys(this.answers)[0] || false;
  },
  methods: {
    handleUpdate: function handleUpdate(valueAnswered) {
      var answer = {};
      var answerOption = this.optionsArray.find(function (option) {
        return option.value === valueAnswered;
      });
      if (answerOption) {
        answer[answerOption.value] = answerOption.label;
      }
      this.$emit('answered', answer);
    }
  }
};