export default {
  name: 'NoSearchResults',
  introduction: 'A simple component for displaying a message if the search has no results',
  description: '',
  props: {
    body: {
      type: String,
      required: false,
      default: 'Enter your search criteria above to view search results'
    }
  },
  data: function data() {
    return {
      translate: {
        nothingToShow: this.$trans('Nothing to show')
      }
    };
  },
  token: '<no-search-results />'
};