import ActionEntry from '@/components/Pages/Actions/ActionEntry';
import ActionDescription from '@/components/Pages/Actions/ActionDescription';
import LinksTab from '@/components/Organisms/LinksTab';
import Changelog from '@/components/Molecules/Changelog';
import MyActions from '@/components/Pages/Actions/MyActions';
import ActionsList from '@/components/Pages/Actions/ActionsList';
import ActionMainReport from '@/components/Pages/Actions/ActionMainReport';
import ActionDashboard from '@/components/Pages/Actions/ActionDashboard';
import { MODULES } from '@/constants/modules';
import { userHasLevelExecution } from '@/routes/auth';
import { UserLevels } from '@/constants/UserLevels';
export default (function () {
  var routes = [{
    path: '/actions/i/-/:slug/:id',
    redirect: {
      name: 'ActionDescription'
    },
    name: 'ActionEntry',
    component: ActionEntry,
    props: true,
    meta: {
      userRightsFunction: userHasLevelExecution(UserLevels.NORMAL)
    },
    children: [{
      path: 'description',
      name: 'ActionDescription',
      component: ActionDescription
    }, {
      path: 'changelog',
      component: Changelog,
      props: function props(route) {
        return {
          type: MODULES.EXECUTION,
          cTable2DefaultTitle: 'Action',
          id: route.params.id
        };
      }
    }, {
      path: 'links',
      component: LinksTab,
      props: {
        module: MODULES.EXECUTION
      }
    }]
  }, {
    path: '/actions/my',
    name: 'ActionsMy',
    component: MyActions,
    meta: {
      userRightsFunction: userHasLevelExecution(UserLevels.NORMAL)
    }
  }, {
    path: '/actions/actions',
    name: 'Overview',
    component: ActionsList,
    meta: {
      userRightsFunction: userHasLevelExecution(UserLevels.PRIVILEGED)
    }
  }, {
    path: '/actions/reports',
    name: 'Main report',
    component: ActionMainReport,
    meta: {
      userRightsFunction: userHasLevelExecution(UserLevels.NORMAL)
    }
  }, {
    path: '/actions/dashboard',
    component: ActionDashboard,
    meta: {
      userRightsFunction: userHasLevelExecution(UserLevels.NORMAL)
    }
  }];
  return routes;
});