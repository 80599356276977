import i18n from '@/i18n';
export var getTranslate = {
  ApprovalStatusWell: function ApprovalStatusWell() {
    return {
      removeApproval: i18n.t('Remove approval'),
      selectOne: i18n.t('Select one'),
      clear: i18n.t('Clear'),
      all: i18n.t('All'),
      user: i18n.t('User'),
      organisation: i18n.t('Organisation'),
      title: i18n.t('Title'),
      primaryOwner: i18n.t('Primary owner'),
      approvalDate: i18n.t('Approval date'),
      risksHadTheirApprovalRemoved: i18n.t('risks had their approval removed')
    };
  },
  RiskManagement: function RiskManagement() {
    return {
      areYouSure: i18n.t('Are you sure?'),
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      create: i18n.t('Create'),
      update: i18n.t('Update')
    };
  },
  RiskManagementModal: function RiskManagementModal() {
    return {
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      chooseTitle: i18n.t('Choose title'),
      save: i18n.t('Save')
    };
  },
  RiskPeriodsWell: function RiskPeriodsWell() {
    return {
      riskPeriods: i18n.t('Risk periods'),
      period: i18n.t('Period'),
      risks: i18n.t('Risks'),
      status: i18n.t('Status'),
      itIsOnlyPossibleToHaveOneScoringPeriodWit: i18n.t('It is only possible to have one scoring period with status editing')
    };
  },
  RiskAdminManagement: function RiskAdminManagement() {
    return {
      selectProject: i18n.t('Select project'),
      category: i18n.t('Category'),
      impact: i18n.t('Impact'),
      likelihood: i18n.t('Likelihood'),
      apply: i18n.t('Apply'),
      selectAll: i18n.t('Select all'),
      threat: i18n.t('Threat'),
      vulnerability: i18n.t('Vulnerability'),
      threatCatalogue: i18n.t('Threat catalogue'),
      vulnerabilityCatalogue: i18n.t('Vulnerability catalogue'),
      theThreatCatalogueIsAGlobalCatalogueButCa: i18n.t('The threat catalogue is a global catalogue, but can be customized to each local project. Check off the boxes between a threat and the project in order to activate it.'),
      theVulnerabilityCatalogueIsAGlobalCatalogue: i18n.t('The vulnerability catalogue is a global catalogue, but can be customized to each local project. Check off the boxes between a vulnerability and the project in order to activate it.'),
      importThreats: i18n.t('Import threats'),
      importVulnerabilities: i18n.t('Import vulnerabilities'),
      typeThreatTitle: i18n.t('Type threat title'),
      typeVulnerabilityTitle: i18n.t('Type vulnerability title'),
      createNewThreat: i18n.t('Create new threat'),
      createNewVulnerability: i18n.t('Create new vulnerability'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      areYouSureYouWantToDeleteThis: i18n.t('Are you sure you want to delete this'),
      areYouSureYouWantToDeleteAllTheseItems: i18n.t('Are you sure you want to delete all these items?'),
      delete: i18n.t('Delete'),
      deleteAll: i18n.t('Delete') + ' ' + i18n.t('All').toLowerCase()
    };
  },
  RiskAdminModal: function RiskAdminModal() {
    return {
      cancel: i18n.t('Cancel'),
      create: i18n.t('Create'),
      importThreats: i18n.t('Import threats'),
      importVulnerabilities: i18n.t('Import vulnerabilities'),
      youCanMassImportAListIntoTheBelowFieldThreats: i18n.t('You can mass import a list into the below field. Each line will be created as individual threats. In order to import threat category and threat, use the following format: Category > Threat'),
      youCanMassImportAListIntoTheBelowFieldVulnerabilities: i18n.t('You can mass import a list into the below field. Each line will be created as individual vulnerabilities.'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.')
    };
  },
  RiskAdminPeriodDescription: function RiskAdminPeriodDescription() {
    return {
      probability: i18n.t('Probability'),
      consequence: i18n.t('Consequence'),
      openThisPeriodAndCloseThePrevious: i18n.t('Open this period and close the previous.'),
      cancel: i18n.t('Cancel'),
      saveAndOpen: i18n.t('Save and open'),
      save: i18n.t('Save'),
      addDefault: i18n.t('Add default'),
      values: i18n.t('values'),
      thereWasAnError: i18n.t('There was an error'),
      levelOfThreat: i18n.t('Level of threat'),
      riskUpdated: i18n.t('Risk updated')
    };
  },
  RiskAdminPeriods: function RiskAdminPeriods() {
    return {
      createNewPeriod: i18n.t('Create new period'),
      title: i18n.t('Title'),
      risks: i18n.t('Risks'),
      status: i18n.t('Status'),
      periods: i18n.t('periods'),
      deleteRiskPeriod: i18n.t('Delete') + ' ' + i18n.t('Risk period').toLowerCase(),
      areYouSureYouWantToDeleteThisRiskPeriod: "".concat(i18n.t('Are you sure you want to delete this'), " ").concat(i18n.t('Risk period').toLowerCase(), "?")
    };
  },
  RiskAdminProjects: function RiskAdminProjects() {
    return {
      riskProjects: i18n.t('Risk projects'),
      selectOne: i18n.t('Select one'),
      title: i18n.t('Title'),
      solutionId: i18n.t('Solution ID'),
      displayRelevantThreatsOnAssessments: i18n.t('Display relevant threats on assessments'),
      enableAccumulatedRisk: i18n.t('Enable accumulated risk'),
      enableInherentVsResidualRisk: i18n.t('Enable inherent vs. residual risk'),
      enableMatrixInverted: i18n.t('Invert matrix axes'),
      defaultActivityType: i18n.t('Default activity type'),
      notSet: i18n.t('Not set'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      riskProjectUpdated: i18n.t('Risk project updated'),
      confirmResetOfColors: i18n.t('This will reset any custom matrix colors in this project')
    };
  }
};