export default {
  name: 'NavItem',
  description: 'Component uses to display item inside <a>, <router-link> or <span> tag depending on props',
  props: {
    isRisma2Component: {
      type: Boolean,
      required: true,
      note: 'Is router-link available'
    },
    exact: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is item in a exact match mode (for links items)'
    },
    url: {
      type: String,
      required: false,
      default: '',
      note: 'url of item'
    }
  }
};