function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { Colors } from '@/Colors';
import * as Utils from '@/utils/Utils';
import { comparePathStrings, compareDates, compareNumbers } from '@/utils/compare';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { sortByIndex } from '@/utils/sort';
import { getRisma1AttachmentUrl } from '@/utils/regex';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { ControlObjectProps } from '@/constants/ControlsReportProps';
import { DateFormats } from '@/constants/DateFormats';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { MODULES } from '@/constants/modules';
import { TabTypes } from '@/constants/Tabs';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { isDateValid, getEndOfDateUnitFormatted, getStartOfDateUnitFormatted, prepareDateTimezone, toDateTimeFormatWithTimezone } from '@/utils/date';
var Protocols = {
  HTTP: 'http://',
  HTTPS: 'https://'
};
export var ControlReportsMixin = {
  mixins: [SaveReportMixin],
  data: function data() {
    return {
      isReportSaved: false,
      dataset: [],
      initRange: {
        start: null,
        end: null
      },
      deadlineBefore: null,
      deadlineBeforeTime: 'T23:59',
      deadlineAfter: null,
      storedRange: null,
      queryString: '?query=',
      columnFiltersSorting: [{
        key: 'cpath',
        sortingFunc: this.sortingByCpath
      }, {
        key: ControlObjectProps.MOTHERCONTROL_CPATH,
        sortingFunc: compareNumbers
      }],
      columnSorting: [{
        key: 'cpath',
        sortingFunc: this.sortingByCpathObject
      }],
      completedReviewActions: ['completed', 'partially_completed']
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, ['users'])), mapState(useOrganisationsStore, ['organisations'])), mapState(useTagsStore, ['tags'])), mapState(useActivityTypesStore, {
    activityTypes: 'controlActivityTypes'
  })),
  methods: {
    getInitRange: function getInitRange() {
      this.storedRange = localStorage.getItem(this.localStorageRangeKey);
      var initRangeStartDefault = getStartOfDateUnitFormatted('month', DateFormats.DATE_FORMAT);
      var initRangeEndDefault = getEndOfDateUnitFormatted('month', DateFormats.DATE_FORMAT);

      // Default (current month)
      if (!this.storedRange) {
        this.deadlineAfter = initRangeStartDefault;
        this.deadlineBefore = initRangeEndDefault;
        this.initRange.start = this.deadlineAfter;
        this.initRange.end = this.deadlineBefore;
      } else {
        var rangeValues = this.storedRange.split('+');
        this.deadlineAfter = rangeValues[0];
        this.deadlineBefore = rangeValues[1];
        if (isDateValid(this.deadlineAfter) && isDateValid(this.deadlineBefore)) {
          this.initRange.start = this.deadlineAfter;
          this.initRange.end = this.deadlineBefore;
        }
      }
    },
    changedDates: function changedDates(e) {
      if (e) {
        this.refreshTable();
      }
    },
    onLoadReport: function onLoadReport(data) {
      var _this = this;
      this.refreshTable().then(function () {
        return _this.applySettingsFromLoadedReport(data);
      });
    },
    prepareItemBaseFields: function prepareItemBaseFields(item) {
      var resultFields = {};
      for (var key in this.reportFields) {
        var value = Utils.getDeep(item, key);
        var result = value ? value : '';
        var isCompletedAfterDeadline = false;
        switch (key) {
          case ControlObjectProps.TRAFFIC_LIGHT:
            isCompletedAfterDeadline = item.completed && compareDates(item.completed, item.deadline) === 1;
            result = this.prepareTrafficLightField(item.trafficLight, isCompletedAfterDeadline);
            break;
          case ControlObjectProps.COMPLETED_EFFICIENCY:
            result = this.prepareEfficiencyAssessment(item.completedEfficiency);
            break;
          case ControlObjectProps.TITLE:
            result = this.prepareTitleField(item);
            break;
          case ControlObjectProps.STATUS:
            result = this.prepareStatusField(item);
            break;
          case ControlObjectProps.KEY_CONTROL:
            result = {
              field: value ? this.translate.yes : this.translate.no
            };
            break;
          case ControlObjectProps.KEY_AUTOMATIC_MANUAL:
            result = {
              field: value ? this.translate.automatic : this.translate.manual
            };
            break;
          case ControlObjectProps.KEY_PREVENTIVE_DETECTIVE:
            result = {
              field: value ? this.translate.preventive : this.translate.detective
            };
            break;
          case ControlObjectProps.KEY_HIGH_LOW_COMPLEXITY:
            result = {
              field: value ? this.translate.high : this.translate.low
            };
            break;
          case ControlObjectProps.CONFIDENTIAL:
            result = {
              field: item.confidential ? this.translate.yes : this.translate.no
            };
            break;
          case ControlObjectProps.FREQUENCY:
            result = {
              field: this.$trans(RismaControlsUtils.frequency2text(item.frequency))
            };
            break;
          case ControlObjectProps.USER_IDS_RESPONSIBLE:
          case ControlObjectProps.USER_IDS_REVIEW:
          case ControlObjectProps.USER_IDS_ESCALATION:
            result = this.prepareUsersField(result);
            break;
          case ControlObjectProps.REVIEW_USER_ID:
            result = this.prepareReviewUserIdField(item);
            break;
          case ControlObjectProps.ACTIVITY_TYPE_ID:
            result = this.prepareActivityTypeField(result);
            break;
          case ControlObjectProps.ORGANISATION_IDS:
            result = {
              field: Utils.findPropsByIds(result, this.organisations, 'visible_name', '; ')
            };
            break;
          case ControlObjectProps.ORGANISATION_GROUPS:
            result = this.prepareOrganisationGroupsField(item);
            break;
          case ControlObjectProps.DAILY_TEAM_IDS:
            result = this.prepareDailyTeamField(item, key);
            break;
          case ControlObjectProps.DEADLINE:
            result = {
              field: toDateTimeFormatWithTimezone(result, item.deadlineTimezone, DateFormats.DATE_TIME_FORMAT)
            };
            break;
          case ControlObjectProps.COMPLETED:
            result = this.prepareCompletedDateField(item);
            break;
          case ControlObjectProps.COMPLETED_USER_ID:
            result = this.prepareCompletedUserIdField(item);
            break;
          case ControlObjectProps.COMPLETED_COMMENTS:
            result = this.prepareCompletedCommentsField(item);
            break;
          case ControlObjectProps.COMPLETED_FILE_NAME:
            result = this.prepareCompletedUrlField(item);
            break;
          case ControlObjectProps.REVIEW_DEADLINE:
            result = this.prepareReviewDeadlineField(item);
            break;
          case ControlObjectProps.REVIEW_APPROVED:
            result = this.prepareReviewApprovedField(item);
            break;
          case ControlObjectProps.REVIEW_COMMENTS:
            result = this.prepareReviewCommentsField(item);
            break;
          case ControlObjectProps.REVIEW_URL:
            result = this.prepareReviewUrlField(item);
            break;
          case ControlObjectProps.ACCOUNTS:
          case ControlObjectProps.RISKS:
          case ControlObjectProps.PROCESSES:
            result = {
              field: Utils.findProps(result, 'title') || this.translate.none
            };
            break;
          case ControlObjectProps.TAGS:
            result = this.prepareTagField(item);
            break;
          case ControlObjectProps.ATTACHMENTS:
          case ControlObjectProps.MOTHERCONTROL_ATTACHMENTS:
            result = this.prepareAttachmentsField(value);
            break;
          case ControlObjectProps.LINKED_ACTIVITIES_CONTROL:
          case ControlObjectProps.LINKED_ACTIVITIES_RISK:
          case ControlObjectProps.LINKED_ACTIVITIES_EXECUTION:
          case ControlObjectProps.LINKED_DATAPROCESSOR_PROCESSOR:
          case ControlObjectProps.LINKED_SYSTEM:
          case ControlObjectProps.LINKED_PROCESSOR_TREE_NODE:
            result = this.prepareLinkedActivitiesField(item, key);
            break;
        }
        resultFields[key] = _objectSpread({
          field: result
        }, result);
      }
      return resultFields;
    },
    prepareItemCustomFields: function prepareItemCustomFields(item) {
      var resultFields = {};
      for (var key in this.customFields) {
        if (key.endsWith(this.customUnitPrefix.toLowerCase())) {
          if (resultFields[key]) continue;
        }
        var result = this.prepareCustomField(item, key);
        resultFields[key] = _objectSpread({
          field: result
        }, result);
      }
      return resultFields;
    },
    prepareItemGapsFields: function prepareItemGapsFields(item) {
      var resultFields = {};
      for (var key in this.complianceGapsFields) {
        var result = this.prepareLinkedCompliances(item, key);
        resultFields[key] = _objectSpread({
          field: result
        }, result);
      }
      return resultFields;
    },
    prepareTitleField: function prepareTitleField(item) {
      return {
        activity: getFieldsFromObjectForPreview(item),
        isActive: item.active,
        fieldText: item.title,
        confidential: item.confidential || false,
        hoverTitle: DatatableRenderer.getItemHoverTitle(item)
      };
    },
    prepareTrafficLightField: function prepareTrafficLightField() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var useExtendedTraffics = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var result = {
        color: '',
        fieldText: ''
      };
      result.color = useExtendedTraffics ? Colors.getControlTrafficLightByNumber(data || 0).color : Colors.getTrafficlightByNumber(data || 0).color;
      result.fieldText = result.color;
      return result;
    },
    prepareEfficiencyAssessment: function prepareEfficiencyAssessment(completedEfficiency) {
      var result = {
        color: '',
        fieldText: ''
      };
      if (completedEfficiency === null || completedEfficiency === undefined) {
        return result;
      }
      result.color = Colors.getTrafficlightByNumber(completedEfficiency).color;
      result.fieldText = result.color;
      return result;
    },
    prepareDailyTeamField: function prepareDailyTeamField(item, key) {
      var _item$key$users;
      var result = '',
        users = '';
      if (item[key] && item[key].organisations) {
        result = Utils.findPropsByIds(item[key].organisations, this.organisations, 'visible_name');
      }
      if (item[key] && (_item$key$users = item[key].users) !== null && _item$key$users !== void 0 && _item$key$users.length) {
        users = Utils.findPropsByIds(item[key].users, this.users, 'display_name');
        if (result) {
          result += ', ';
        }
        result += users;
      }
      return {
        field: result
      };
    },
    prepareStatusField: function prepareStatusField(item) {
      var result = this.$trans(RismaControlsUtils.state2text(item.rismaState));
      if (item.rismaState === RismaControlsUtils.states.STATE_COMPLETED && item.reviewStatus === 'partially_completed') {
        result = this.$trans('Completed with actions');
      }
      if (item.rismaState === RismaControlsUtils.states.STATE_PARTIALLY_READY_FOR_REVIEW || item.rismaState === RismaControlsUtils.states.STATE_PARTIALLY_REVIEW_REJECTED) {
        result += ' *';
      }
      return {
        field: result
      };
    },
    prepareUsersField: function prepareUsersField(userIds) {
      if (!userIds || !userIds.length) return {
        field: '',
        fieldText: ''
      };
      var userIdsModified = userIds.map(function (id) {
        return +id;
      });
      var filteredUsers = this.users.filter(function (user) {
        return userIdsModified.includes(+user.id);
      });
      var result = [];
      var allItemsText = [];
      filteredUsers.forEach(function (user) {
        user.active ? result.push(user.display_name) : result.push(Utils.prepareInactiveHtml(user.display_name));
        allItemsText.push(user.display_name);
      });
      return {
        field: result.join(', '),
        fieldText: allItemsText.join(', ')
      };
    },
    prepareCustomField: function prepareCustomField(item, key) {
      var _this$customFields, _item$customFields;
      var result = {
        field: '',
        fieldText: '',
        fieldType: ((_this$customFields = this.customFields) === null || _this$customFields === void 0 ? void 0 : _this$customFields[key].fieldType) || null
      };
      if (!((_item$customFields = item.customFields) !== null && _item$customFields !== void 0 && _item$customFields.length)) return result;
      var id = key.split('-')[1];
      if (key.endsWith(this.customUnitPrefix.toLowerCase())) {
        id = id.split(this.customUnitPrefix.toLowerCase())[0];
      }
      var customField = item.customFields.find(function (cf) {
        return +cf.id === +id;
      });
      if (!customField) return result;
      this.prepareCustomFieldValue(result, item.customFieldValues, customField);
      return _objectSpread({}, result[key]);
    },
    prepareAttachmentsField: function prepareAttachmentsField(attachments) {
      if (!attachments || !attachments.length) {
        return {
          field: '',
          fieldText: ''
        };
      }
      var activities = '';
      var allItemsText = [];
      attachments.forEach(function (item) {
        activities += Utils.prepareLink(getRisma1AttachmentUrl(item.url), item.filename);
        allItemsText.push(item.filename);
      });
      return {
        field: activities,
        fieldText: allItemsText.join(', ')
      };
    },
    prepareTagField: function prepareTagField(item) {
      var tags = this.tags.filter(function (t) {
        return item.tagIds.includes(t.id);
      }).map(function (t) {
        return t.tag;
      }).join(', ');
      return {
        field: tags,
        fieldText: tags
      };
    },
    prepareActivityTypeField: function prepareActivityTypeField(activityTypeId) {
      var activityType = this.activityTypes.find(function (a) {
        return a.id === activityTypeId;
      });
      return {
        field: activityType ? activityType.label : ''
      };
    },
    prepareOrganisationGroupsField: function prepareOrganisationGroupsField(data) {
      if (!data.organisationGroups || !data.organisationGroups.length) return {
        field: ''
      };
      return {
        field: data.organisationGroups.map(function (group) {
          return group.label;
        }).join('; ')
      };
    },
    prepareCompletedDateField: function prepareCompletedDateField(item) {
      var createdAt = this.getCompletedLastField(item, 'created') || item.completed || '';
      return {
        field: prepareDateTimezone(createdAt, item.deadlineTimezone, DateFormats.DATE_TIME_FORMAT)
      };
    },
    prepareCompletedUserIdField: function prepareCompletedUserIdField(item) {
      var userId = this.getCompletedLastField(item, 'userId') || item.completedUserid;
      return this.prepareUsersField([userId]);
    },
    prepareCompletedCommentsField: function prepareCompletedCommentsField(item) {
      var completeComment = this.getCompletedLastField(item, 'comment') || item.completedComments || '';
      return {
        field: completeComment,
        fieldText: completeComment
      };
    },
    prepareCompletedUrlField: function prepareCompletedUrlField(item) {
      var _this2 = this;
      var lastCompleted = this.getCompletedLastField(item);
      var allItemsText = [];
      var result = '';
      if (!lastCompleted) {
        return {
          field: '',
          fieldText: ''
        };
      }
      if (lastCompleted.url && lastCompleted.url !== Protocols.HTTP && lastCompleted.url !== Protocols.HTTPS) {
        result += Utils.prepareLink(lastCompleted.url, lastCompleted.urlTitle || lastCompleted.url);
        allItemsText.push(lastCompleted.urlTitle || lastCompleted.url);
      }
      if (lastCompleted.files && lastCompleted.files.length) {
        lastCompleted.files.forEach(function (file) {
          result += Utils.prepareLink(_this2.prepareAttachmentUrl(file.id), file.name);
          allItemsText.push(file.name);
        });
      }
      return {
        field: result,
        fieldText: allItemsText.join(', ')
      };
    },
    prepareLinkedCompliances: function prepareLinkedCompliances(item, key) {
      var _this3 = this;
      var projectId = key.split('-')[1];
      var result = {
        activities: [],
        fieldText: '',
        id: item.id
      };
      if (!item.linkedCompliances) return result;
      var compliancesByProject = item === null || item === void 0 ? void 0 : item.linkedCompliances.filter(function (item) {
        return +item.compliance.projectId === +projectId;
      });
      if (!compliancesByProject || !compliancesByProject.length) return result;
      compliancesByProject.forEach(function (item) {
        var node = item.compliance;
        var article = item.compliance.article;
        var gapAnalysis = {
          id: "".concat(node.nodeId, "_").concat(article.id),
          title: article.article + article.articleTitle,
          url: _this3.getLinkToNodeGapTab(projectId, node.nodeId, article.id),
          objType: ObjectTypes.COMPLIANCE_GAP_ANALYSIS,
          compliance_projects_id: +projectId,
          nodeId: node.nodeId,
          route: {
            tab: TabTypes.GAPANALYSIS,
            id: article.id
          }
        };
        result.activities.push({
          activity: gapAnalysis,
          fieldText: gapAnalysis.title
        });
      });
      result.fieldText = result.activities.map(function (activity) {
        return activity.fieldText;
      }).join(', ');
      return result;
    },
    //review fields
    prepareReviewApprovedField: function prepareReviewApprovedField(item) {
      if (item.rismaState === RismaControlsUtils.states.STATE_REVIEW_NOT_PERFORMED) {
        return {
          fieldText: '',
          field: ''
        };
      }
      var createdAt = this.getReviewLastField(item, 'created') || item.reviewApproved || '';
      return {
        field: prepareDateTimezone(createdAt, item.deadlineTimezone, DateFormats.DATE_TIME_FORMAT)
      };
    },
    prepareReviewUserIdField: function prepareReviewUserIdField(item) {
      if (item.rismaState === RismaControlsUtils.states.STATE_REVIEW_NOT_PERFORMED) {
        return {
          fieldText: '',
          field: ''
        };
      }
      var userId = this.getReviewLastField(item, 'userId') || item.reviewUserId || '';
      return this.prepareUsersField([userId]);
    },
    prepareReviewUrlField: function prepareReviewUrlField(item) {
      var _this4 = this;
      var lastReview = this.getReviewLastField(item);
      var allItemsText = [];
      var result = '';
      if (!lastReview) {
        return {
          field: '',
          fieldText: ''
        };
      }
      if (lastReview.url && lastReview.url !== Protocols.HTTP && lastReview.url !== Protocols.HTTPS) {
        result += Utils.prepareLink(lastReview.url, lastReview.urlTitle || lastReview.url);
        allItemsText.push(lastReview.urlTitle || lastReview.url);
      }
      if (lastReview.files && lastReview.files.length) {
        lastReview.files.forEach(function (file) {
          result += Utils.prepareLink(_this4.prepareAttachmentUrl(file.id), file.name);
          allItemsText.push(file.name);
        });
      }
      return {
        field: result,
        fieldText: allItemsText.join(', ')
      };
    },
    prepareReviewCommentsField: function prepareReviewCommentsField(item) {
      if (item.rismaState === RismaControlsUtils.states.STATE_REVIEW_NOT_PERFORMED) {
        return {
          field: '',
          fieldText: ''
        };
      }
      var comment = this.getReviewLastField(item, 'comment') || '';
      return {
        field: comment,
        fieldText: comment
      };
    },
    prepareReviewDeadlineField: function prepareReviewDeadlineField(item) {
      var reviewDate = this.getReviewLastField(item, 'reviewDeadline') || item.reviewDeadline || '';
      var result = toDateTimeFormatWithTimezone(reviewDate, item.deadlineTimezone, DateFormats.DATE_TIME_FORMAT);
      return {
        field: result,
        fieldText: result
      };
    },
    //general
    prepareLinkedActivitiesField: function prepareLinkedActivitiesField(item, key) {
      var result = {
        activities: []
      };
      if (!Object.keys(item.links).length) return result;
      switch (key) {
        case ControlObjectProps.LINKED_ACTIVITIES_EXECUTION:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links[MODULES.EXECUTION], this.initiatives, 'path')), {}, {
            id: item.id
          });
          break;
        case ControlObjectProps.LINKED_ACTIVITIES_CONTROL:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links[MODULES.CONTROLS], this.allControls, 'path')), {}, {
            id: item.id
          });
          break;
        case ControlObjectProps.LINKED_ACTIVITIES_RISK:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links[MODULES.RISK], this.risks, 'rno', this.getRiskValues)), {}, {
            id: item.id
          });
          break;
        case ControlObjectProps.LINKED_DATAPROCESSOR_PROCESSOR:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links[MODULES.COMPLIANCE_IA_PROCESSOR], this.iaDataProcessors)), {}, {
            id: item.id
          });
          break;
        case ControlObjectProps.LINKED_SYSTEM:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links[MODULES.COMPLIANCE_IA_SYSTEM], this.systems)), {}, {
            id: item.id
          });
          break;
        case ControlObjectProps.LINKED_PROCESSOR_TREE_NODE:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links[MODULES.COMPLIANCE_PROCESS_TREE_NODE], this.processLibraryNodes)), {}, {
            id: item.id
          });
          break;
      }
      return result;
    },
    getReviewLastField: function getReviewLastField(item) {
      var _this5 = this;
      var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var result = '';
      if (!item.reviews || !item.reviews.length) return result;
      var reversedReviews = item.reviews.slice(0).reverse();
      var mostRecentReview = reversedReviews.find(function (review) {
        return !_this5.completedReviewActions.includes(review.action);
      });
      if (mostRecentReview) {
        result = field ? mostRecentReview[field] : mostRecentReview;
      }
      return result;
    },
    getCompletedLastField: function getCompletedLastField(item) {
      var _this6 = this;
      var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var result = null;
      if (!item.reviews || !item.reviews.length) return result;
      var reversedReviews = item.reviews.slice(0).reverse();
      var mostRecentItem = reversedReviews.find(function (review) {
        return _this6.completedReviewActions.includes(review.action);
      });
      if (mostRecentItem) {
        result = field ? mostRecentItem[field] : mostRecentItem;
      }
      return result;
    },
    getRiskValues: function getRiskValues(risk) {
      if (this.getSettingValue('feature.show_risk_links_with_levels') !== 1) {
        return '';
      }
      return DatatableRenderer.getRiskValues(risk, this.riskProjects);
    },
    prepareAttachmentUrl: function prepareAttachmentUrl(id) {
      return "/api/2.0/controls/files/".concat(id);
    },
    sortingByCpath: function sortingByCpath(data) {
      return sortByIndex(data);
    },
    sortingByCpathObject: function sortingByCpathObject(data, key) {
      return data.sort(function (a, b) {
        var x = Utils.purifyItemField(a[key], true);
        var y = Utils.purifyItemField(b[key], true);
        return comparePathStrings(x, y);
      });
    },
    loadDataFromQuery: function loadDataFromQuery() {
      var _this$$route$query,
        _this7 = this;
      var query = (_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.query;
      if (!query) return;
      this.$nextTick(function () {
        _this7.onLoadReport(JSON.parse(query));
      });
    },
    getLinkToNodeGapTab: function getLinkToNodeGapTab(projectId, nodeId, articleId) {
      return "/compliance/".concat(projectId, "/edit/").concat(nodeId, "/gap/").concat(articleId);
    }
  }
};