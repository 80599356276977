export default {
  name: 'GridLines',
  introduction: 'line overlay for matrix',
  description: "this component is a canvas that draws lines for the matrix component. be mindful that this component is\n                  VERY dependant on the matrix component, and cannot be used outside of it",
  token: '<grid-lines :grid-size="100" :grid="[[{...},{...}],[{...}],[...]]"/>',
  props: {
    gridBoxSize: {
      type: Number,
      required: true,
      note: 'the size of the grid boxes in pixels. Controlled by Matrix'
    },
    grid: {
      type: Array,
      required: true,
      note: '2d matrix of the grid'
    }
  },
  emits: ['getCell', 'getDot'],
  data: function data() {
    return {
      cell: null,
      dot: null,
      dots: [],
      ctx: null
    };
  },
  computed: {
    computedSize: function computedSize() {
      return {
        height: this.grid.length * this.gridBoxSize,
        width: this.grid[0].length * this.gridBoxSize
      };
    }
  },
  watch: {
    computedSize: {
      handler: function handler(newValue, oldValue) {
        var _this = this;
        // to prevent extra updates when changing the previous position
        if (newValue.width !== oldValue.width || newValue.height !== oldValue.height) {
          this.$nextTick(function () {
            return _this.drawAll();
          });
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.ctx = this.$el.getContext('2d');
    this.grid.forEach(function (row) {
      return row.forEach(function (column) {
        return column.forEach(function (dot) {
          return _this2.dots.push(dot);
        });
      });
    });
    this.drawAll();
  },
  methods: {
    canvasArrow: function canvasArrow(context, fromX, fromY, toX, toY) {
      var headlen = 10; // length of head in pixels
      var angle = Math.atan2(toY - fromY, toX - fromX);
      context.beginPath();
      context.moveTo(fromX, fromY);
      context.lineTo(toX, toY);
      context.lineTo(toX - headlen * Math.cos(angle - Math.PI / 6), toY - headlen * Math.sin(angle - Math.PI / 6));
      context.moveTo(toX, toY);
      context.lineTo(toX - headlen * Math.cos(angle + Math.PI / 6), toY - headlen * Math.sin(angle + Math.PI / 6));
      context.stroke();
    },
    drawAll: function drawAll() {
      var _this3 = this;
      this.dots.forEach(function (dot) {
        _this3.drawLine(dot);
      });
    },
    drawLine: function drawLine(dot) {
      this.$emit('getCell', dot.xBefore, dot.yBefore);
      this.$emit('getDot', dot.id);
      var offsetHeight = dot.yBefore > dot.y ? 0 : dot.yBefore === dot.y ? this.dot.offsetHeight / 2 : this.dot.offsetHeight / 1.5;
      var offsetWidth = dot.xBefore > dot.x ? this.dot.offsetWidth : dot.xBefore === dot.x ? this.dot.offsetWidth / 2 : 0;
      if (this.cell && (dot.xBefore !== dot.xNow || dot.yBefore !== dot.yNow) && (dot.xBefore !== dot.x || dot.yBefore !== dot.y)) {
        this.canvasArrow(this.ctx, this.cell.offsetLeft + this.cell.offsetWidth / 2, this.cell.offsetTop + this.cell.offsetHeight / 2, this.dot.offsetParent.offsetLeft + this.dot.offsetLeft + offsetWidth, this.dot.offsetParent.offsetTop + this.dot.offsetTop + offsetHeight);
      }
    }
  }
};