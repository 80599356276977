import { useSettingsStore } from '@/Store/settingsStore';
import { getGapSchemasTypes } from '@/api/compliance/gapschema';
var IAGapSchemaTypes = {
  IA_DATACONTROLLER: 101,
  IA_DATAPROCESSOR: 102,
  IA_SYSTEM: 103,
  IA_SUBPROCESSOR: 104,
  IA_DPM: 105,
  IA: 106
};
export var getAvailableGapSchemasTypes = function getAvailableGapSchemasTypes() {
  return getGapSchemasTypes().then(function (_ref) {
    var list = _ref.list;
    if (useSettingsStore().isInformationAssetsDisabled) {
      var typesIdsToRemove = Object.values(IAGapSchemaTypes);
      var result = list.filter(function (type) {
        return typesIdsToRemove.indexOf(type.id) === -1;
      });
      return {
        list: result
      };
    }
    return {
      list: list
    };
  });
};