import NewChoice from '@/components/Molecules/Questionnaire/NewChoice';
export default {
  name: '',
  components: {
    NewChoice: NewChoice
  },
  data: function data() {
    return {
      newChoice: {
        options: ['What are is?', 'Where are pirates?']
      }
    };
  }
};