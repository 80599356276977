function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { saveStatus as _saveStatus } from '@/constants/SaveStatuses';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { getAllTrees, getNodesByProcessTreeId, getInformationAssets } from '@/api/risma/data';
import { getAllQuestionAnswersForNode, updateQuestionForNode, getInformationAssetQuestionAnswersByType } from '@/api/compliance/questionnaire';
import { getAllByType, getSingleByType, updateIAQuestionByTypeIdAndQuestionId } from '@/api/compliance/informationAssets';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Questionnaire from '@/components/Organisms/Compliance/questionnaire';
import Notification from '@/components/Molecules/Notification';
import { UserLevels } from '@/constants/UserLevels';
export default {
  name: 'QuestionnairePage',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Questionnaire: Questionnaire,
    Notification: Notification
  },
  introduction: 'This component is just a simple page for the questionnaire',
  description: 'Use this component on questionnaire pages',
  token: '<questionnaire-page></questionnaire-page>',
  props: {
    data: {
      required: false,
      type: Object,
      default: function _default() {},
      note: 'Current node object'
    },
    questionnaireId: {
      required: false,
      type: [String, Number],
      default: '',
      note: 'Current questionnaireId'
    },
    saveStatus: {
      type: String,
      required: false,
      default: _saveStatus.SAVED
    }
  },
  emits: ['update:isPageWithCustomUpdateDirty', 'updateValue', 'saveStatusClicked', 'questionAnswered'],
  data: function data() {
    var _myInformationAssetsB;
    return {
      questions: [],
      questionsSnap: [],
      errorDetails: '',
      queue: [],
      informationAssets: null,
      myInformationAssetsByType: (_myInformationAssetsB = {}, _defineProperty(_myInformationAssetsB, AssetsTypes.SYSTEMS, []), _defineProperty(_myInformationAssetsB, AssetsTypes.CONTROLLERS, []), _defineProperty(_myInformationAssetsB, AssetsTypes.PROCESSORS, []), _defineProperty(_myInformationAssetsB, AssetsTypes.INFORMATION_ASSETS_API, []), _myInformationAssetsB),
      isLoaded: false,
      nodeElement: {},
      processTreeId: null,
      item: this.data,
      isQueueStopped: false,
      questionTypes: QuestionTypes(),
      translate: {
        thereWasAnErrorSavingYourAnswerToQuestion: this.$trans('There was an error saving your answer to question number: ')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserComplianceProjectById: 'complianceProjectById',
    userHasAccessLevel: 'userHasAccessLevel'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'complianceIACustomActivityTypes'
  })), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    rightQuestionnaire: function rightQuestionnaire() {
      var _this = this;
      var questionnaire = this.item.questionnaires.find(function (item) {
        return item.questionnaireId === +_this.questionnaireId;
      });
      if (!questionnaire) questionnaire = this.item.questionnaire;
      return questionnaire;
    },
    currentProject: function currentProject() {
      return this.data.compliance_projects_id ? this.currentUserComplianceProjectById(this.data.compliance_projects_id) : {};
    },
    isComplianceNode: function isComplianceNode() {
      return this.data.objType === ObjectTypes.COMPLIANCE_NODE;
    },
    getQuestionnaireAPI: function getQuestionnaireAPI() {
      return this.isComplianceNode ? this.getComplianceQuestionnaire : this.getIAQuestionnaire;
    },
    updateQuestionAPI: function updateQuestionAPI() {
      return this.isComplianceNode ? this.updateComplianceQuestion : this.updateIAQuestion;
    },
    pageType: function pageType() {
      var objType = this.data.objType;
      switch (objType) {
        case ObjectTypes.COMPLIANCE_NODE:
          return 'compliance';
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM:
          return AssetsTypes.SYSTEMS;
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR:
          return AssetsTypes.PROCESSORS;
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER:
          return AssetsTypes.CONTROLLERS;
        case ObjectTypes.INFORMATION_ASSET:
          return AssetsTypes.INFORMATION_ASSETS_API;
        default:
          throw new Error('Unsupported objType');
      }
    },
    needMyInformationAssets: function needMyInformationAssets() {
      return this.getSettingValue('feature.ia_limit_access_in_question') && !this.userHasAccessLevel('level_information_assets', UserLevels.SUPER);
    }
  }),
  watch: {
    questionnaireId: function questionnaireId() {
      var _this2 = this;
      this.isLoaded = false;
      this.getAll().then(function () {
        _this2.isLoaded = true;
      });
    },
    saveStatus: function saveStatus(value) {
      if (value === _saveStatus.SAVING) {
        this.isQueueStopped = false;
        this.runQueue();
      } else if (value === _saveStatus.NOT_SAVED) {
        this.isQueueStopped = true;
      }
    }
  },
  mounted: function mounted() {
    var _this3 = this;
    this.getAll().then(function () {
      _this3.isLoaded = true;
    });
  },
  methods: {
    getAll: function getAll() {
      var promises = [this.getQuestionnaire(), this.getAllInformationAssets(), this.getNodeElementAllData()];
      if (this.needMyInformationAssets) promises.push(this.getMyInformationAssets());
      return Promise.all(promises);
    },
    getDataForIA: function getDataForIA() {
      var _this4 = this;
      return getSingleByType(this.pageType, this.data.id).then(function (response) {
        return _this4.item = response;
      });
    },
    getNodeElementAllData: function getNodeElementAllData() {
      return getAllTrees().then(this.setIdToProcessTreeIdAndGetNodes);
    },
    setIdToProcessTreeIdAndGetNodes: function setIdToProcessTreeIdAndGetNodes(response) {
      var _this5 = this;
      if (response[0].id !== null) {
        this.processTreeId = response[0].id;
      }
      return getNodesByProcessTreeId(this.processTreeId).then(function (response) {
        return _this5.nodeElement = response;
      });
    },
    getMyInformationAssets: function getMyInformationAssets() {
      var promises = [this.getMyInformationAssetsByType(AssetsTypes.SYSTEMS), this.getMyInformationAssetsByType(AssetsTypes.CONTROLLERS), this.getMyInformationAssetsByType(AssetsTypes.PROCESSORS), this.getMyInformationAssetsByType(AssetsTypes.INFORMATION_ASSETS_API)];
      return Promise.all(promises);
    },
    getMyInformationAssetsByType: function getMyInformationAssetsByType(type) {
      var _this6 = this;
      return getAllByType(type).then(function (_ref) {
        var list = _ref.list;
        return _this6.myInformationAssetsByType[type] = list;
      });
    },
    getAllInformationAssets: function getAllInformationAssets() {
      var _this7 = this;
      return getInformationAssets().then(function (_ref2) {
        var list = _ref2.list;
        return _this7.informationAssets = list;
      });
    },
    getQuestionnaire: function getQuestionnaire() {
      var _this8 = this;
      return this.getQuestionnaireAPI().then(function (_ref3) {
        var list = _ref3.list;
        _this8.questions = list;
        _this8.questionsSnap = _toConsumableArray(list);
      });
    },
    getComplianceQuestionnaire: function getComplianceQuestionnaire() {
      return getAllQuestionAnswersForNode(this.data.compliance_projects_id, this.data.id, this.questionnaireId);
    },
    getIAQuestionnaire: function getIAQuestionnaire() {
      return getInformationAssetQuestionAnswersByType(this.pageType, this.data.id, this.questionnaireId);
    },
    handleNewAsset: function handleNewAsset(_ref4) {
      var data = _ref4.data,
        type = _ref4.type,
        questionId = _ref4.questionId;
      this.informationAssets = [].concat(_toConsumableArray(this.informationAssets), [data]);
      var questionIdx = this.questions.findIndex(function (qu) {
        return +qu.id === +questionId;
      });
      if (questionIdx !== -1) {
        var updatedQuestion = JSON.parse(JSON.stringify(this.questions[questionIdx]));
        updatedQuestion.informationAssets[type].push(data);
        this.questions[questionIdx] = updatedQuestion;
        this.prepareAndUpdateQuestion(updatedQuestion);
      }
    },
    prepareAndUpdateQuestion: function prepareAndUpdateQuestion(question) {
      /* questionnaire would always show answered questions, but if something wrong -> take previous version of the answer from questionSnap */
      var index = this.questions.findIndex(function (item) {
        return item.id === question.id;
      });
      this.questions[index] = _objectSpread({}, question);
      this.questions.splice(); //doesn`t see question upd

      var preparedQuestion = this.prepareQuestionForUpdate(question);
      this.queue = this.prepareQueue(this.queue, preparedQuestion);
      var isForced = question.questiontype !== this.questionTypes.multipleChoice.id && question.questiontype !== this.questionTypes.informationAsset.id && question.questiontype !== this.questionTypes.informationAssetSystems.id && question.questiontype !== this.questionTypes.informationAssetProcessors.id && question.questiontype !== this.questionTypes.informationAssetControllers.id && question.questiontype !== this.questionTypes.processLibrary.id && question.questiontype !== this.questionTypes.customInformationAsset.id && question.questiontype !== this.questionTypes.date.id && question.questiontype !== this.questionTypes.freeText.id && question.questiontype !== this.questionTypes.attachmentAndFreeText.id && question.questiontype !== this.questionTypes.dateTime.id;
      this.$emit('update:isPageWithCustomUpdateDirty', {
        value: true,
        isForced: isForced
      });
    },
    prepareQueue: function prepareQueue(queue, inputItem) {
      var mapQueue = new Map(queue.map(function (item) {
        return [item.id, item];
      }));
      mapQueue.set(inputItem.id, inputItem);
      return _toConsumableArray(mapQueue.values());
    },
    runQueue: function runQueue() {
      if (this.queue.length > 0) {
        var preparedQuestion = this.queue.shift();
        this.updateQuestionFromQueue(preparedQuestion);
      } else {
        this.$emit('update:isPageWithCustomUpdateDirty', {
          value: false
        });
      }
    },
    prepareQuestionForUpdate: function prepareQuestionForUpdate(question) {
      var preparedQuestion = {};
      preparedQuestion.id = question.id;
      preparedQuestion.answers_options = question.answers_options;
      if (question.questiontype === 12) {
        preparedQuestion.processLibraryId = [];
        if (Object.keys(preparedQuestion.answers_options)[0] !== 'null') {
          // check for empty answers_options
          Object.keys(preparedQuestion.answers_options).forEach(function (element) {
            preparedQuestion.processLibraryId.push(element);
          });
        }
        delete preparedQuestion.answers_options;
      }
      if (question.informationAssets) {
        preparedQuestion.informationAssets = {};
        Object.keys(question.informationAssets).forEach(function (iaKey) {
          preparedQuestion.informationAssets[iaKey] = question.informationAssets[iaKey].map(function (ia) {
            return +ia.id;
          });
        });
      }
      if (question.freeText !== undefined) {
        preparedQuestion.freeText = question.freeText;
      }
      preparedQuestion.files = question.files;
      preparedQuestion.questionnumber = question.questionnumber;
      preparedQuestion.dateTime = question.dateTime;
      return preparedQuestion;
    },
    updateQuestionFromQueue: function updateQuestionFromQueue(question) {
      var _this9 = this;
      this.updateQuestion(question).then(function () {
        if (!_this9.isQueueStopped) {
          //if new answer came while upd
          _this9.runQueue();
        }
      }).catch(function (error) {
        var text = error.response && error.response.error || error;
        _this9.errorDetails = _this9.translate.thereWasAnErrorSavingYourAnswerToQuestion + question.questionnumber + '.<br>' + text;
        setTimeout(function () {
          _this9.errorDetails = '';
        }, _this9.$debounceTimeout * 3);
      });
    },
    updateComplianceQuestion: function updateComplianceQuestion(question) {
      return updateQuestionForNode(this.data.compliance_projects_id, this.data.id, question);
    },
    updateIAQuestion: function updateIAQuestion(question) {
      return updateIAQuestionByTypeIdAndQuestionId(this.pageType, this.item.id, question.id, question);
    },
    updateQuestion: function updateQuestion(question) {
      var _this10 = this;
      var index = this.questions.findIndex(function (item) {
        return item.id === question.id;
      });
      return this.updateQuestionAPI(question).then(function (response) {
        _this10.questions[index] = response;
        _this10.questionsSnap[index] = response;
        _this10.$emit('questionAnswered');
        if (response.node && response.node.values) {
          _this10.$emit('updateValue', {
            property: 'values',
            value: response.node.values
          });
        }
      }).catch(function (error) {
        _this10.$emit('update:isPageWithCustomUpdateDirty', {
          value: !!_this10.queue.length
        });
        _this10.questions[index] = _objectSpread({}, _this10.questionsSnap[index]);
        _this10.questions.splice();
        throw error;
      });
    }
  }
};