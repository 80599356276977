function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { MODULES, getModuleTitles } from '@/constants/modules';
import { Colors } from '@/Colors';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'SelectorTags',
  components: {
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight,
    FeatherIcon: FeatherIcon,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  props: {
    module: {
      required: true,
      type: String,
      default: MODULES.INCIDENTS,
      note: 'selector module'
    },
    tags: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'selector tags'
    },
    hasTrafficLights: {
      required: false,
      type: [Boolean, Function],
      default: false,
      note: 'selector tags'
    },
    title: {
      required: false,
      type: String,
      default: '',
      note: 'tags title'
    },
    hasDeleteOptions: {
      required: false,
      type: Boolean,
      default: true,
      note: 'enable/disable delete option'
    },
    selectedTag: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      },
      note: 'Selected tag object'
    }
  },
  emits: ['click', 'delete'],
  data: function data() {
    return {
      moduleTitles: getModuleTitles(),
      MODULES: MODULES
    };
  },
  computed: {
    icon: function icon() {
      switch (this.module) {
        case MODULES.EXECUTION:
          return {
            icon: 'check',
            class: 'ml-2'
          };
        case MODULES.INCIDENTS:
        case MODULES.CONTROLS:
        case MODULES.CONTRACTS:
          return 'confidential';
        default:
          return '';
      }
    },
    dataset: function dataset() {
      var _this = this;
      return this.tags.map(function (tag) {
        return _objectSpread(_objectSpread({}, tag), {}, {
          fullTitle: _this.getTitle(tag),
          withLineThrough: _this.getLineThrough(tag),
          trafficLightColor: _this.getTrafficLight(tag),
          displayIcon: _this.getDisplayIcon(tag)
        });
      });
    }
  },
  methods: {
    getTitle: function getTitle(item) {
      if (this.module.substr(0, 4) === MODULES.RISK) {
        return "".concat(item.rno, ". ").concat(item.title);
      }
      switch (this.module) {
        case MODULES.CONTROLS:
          return "".concat(item.cpath, ". ").concat(item.title);
        case MODULES.EXECUTION:
          return "".concat(item.ipath, " ").concat(item.title);
        default:
          return item.title || item.name;
      }
    },
    getTrafficLight: function getTrafficLight(item) {
      if (item.trafficLightColor) {
        return item.trafficLightColor;
      }
      if (item.objType === ObjectTypes.INCIDENTS_INCIDENT || item.objType === ObjectTypes.INCIDENTS_INCIDENT_LIMITED) {
        return Colors.findIncidentSeverity(item.severity).color;
      }
      if (item.objType === ObjectTypes.COMPANY) {
        return Colors.getCompanyTrafficlightByNumber(item.vatStatus).color;
      }
      var trafficLight = item.trafficLight || item.traffic_light || 0;
      return Colors.getTrafficlightByNumber(trafficLight).color;
    },
    getLineThrough: function getLineThrough(item) {
      if (this.module === MODULES.CONTROLS && !item.active) {
        return true;
      }
      return false;
    },
    getDisplayIcon: function getDisplayIcon(tag) {
      switch (this.module) {
        case MODULES.EXECUTION:
          return tag.closed;
        case MODULES.INCIDENTS:
        case MODULES.CONTROLS:
        case MODULES.CONTRACTS:
          return tag.confidential;
        default:
          return false;
      }
    },
    checkSelectedTag: function checkSelectedTag(_ref) {
      var id = _ref.id,
        objType = _ref.objType,
        nodeId = _ref.nodeId;
      return (this.selectedTag.id !== undefined && this.selectedTag.id === id || this.selectedTag.nodeId !== undefined && this.selectedTag.nodeId === nodeId) && this.selectedTag.objType === objType;
    }
  }
};