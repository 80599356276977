function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { sheet_to_array_buffer, handleSave } from './index';
import { getIsoDate, isDateValid } from '@/utils/date';
import { DateFormats } from '@/constants/DateFormats';
var CellTypes = {
  TEXT: 's',
  BOOLEAN: 'b',
  ERROR: 'e',
  NUMBER: 'n',
  DATE: 'd',
  STUB: 'z',
  GENERAL: ''
};
var SSF = {
  14: 'm/d/yy',
  22: 'm/d/yy h:mm'
};
/**
 * Save a excel file by the content of a table
 *
 * All <ul> with children <li> will be by a ; (semicolon) when saved to the excel file
 *
 * Please note that only function exported is toExcel. The magic will happen from here
 *
 * EXAMPLE:
 *
 * import {Export} form'@/utils
 * or
 * import {toExcel} form'@/utils/export
 * ...
 * Export.toExcel('myTableId')
 * Export.toExcel('myTableId', 'MyOwnFilename', 'MyOwnSheetName')
 *
 * @param id Required! The CSS ID of the table you would like to export
 * @param filename - default RISMAbusiness - The filename you would like to save the file as.
 * @param sheetName - default RISMAbusiness - The name of the sheet in the Excel file
 * @param addDateToFilename - default true - Should the date be appended to the filename
 * @returns {*}
 */
export function toExcel(htmlContent) {
  var filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'RISMAbusiness';
  var sheetName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'RISMAbusiness';
  var addDateToFilename = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  return export_table_to_excel(htmlContent, getFilename(filename, addDateToFilename), sheetName);
}
export function toExcelRaw(id) {
  var filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'RISMAbusiness';
  var sheetName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'RISMAbusiness';
  var addDateToFilename = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  return export_table_to_excel(document.getElementById(id).cloneNode(true), getFilename(filename, addDateToFilename), sheetName);
}

/**
 * Export json to Excel
 *
 * @param {*} data - json
 * @param filename - default RISMAbusiness - The filename you would like to save the file as.
 * @param sheetName - default RISMAbusiness - The name of the sheet in the Excel file
 * @param addDateToFilename - default true - Should the date be appended to the filename
 */
export function json2Excel(data) {
  var columns = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var filename = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'RISMAbusiness';
  var sheetName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'RISMAbusiness';
  var addDateToFilename = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  filename = getFilename(filename, addDateToFilename) + '.xlsx';
  import('xlsx').then(function (XLSX) {
    var max_character_number_to_show = 75;
    var min_characher_number_to_show = 10;
    var date_characher_number_to_show = 20;
    var cols = columns || Object.values(data[0]).map(function (header) {
      return header.v;
    });
    var worksheet = XLSX.utils.json_to_sheet(data, {
      cellDates: true,
      raw: true
    });
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    /* fix headers */
    XLSX.utils.sheet_add_aoa(worksheet, [cols], {
      origin: 'A1'
    });
    /* calculate column width */
    var wsColsSettings = [];
    data.forEach(function (row) {
      var colIndex = 0;
      Object.values(row).forEach(function (_ref) {
        var v = _ref.v,
          t = _ref.t;
        var rowLength;
        if (t === CellTypes.DATE) {
          rowLength = date_characher_number_to_show;
        } else {
          rowLength = v.length;
        }
        if (!wsColsSettings[colIndex]) {
          wsColsSettings[colIndex] = {
            wch: min_characher_number_to_show
          };
        }
        wsColsSettings[colIndex] = {
          wch: Math.min(Math.max(rowLength, wsColsSettings[colIndex].wch), max_character_number_to_show)
        };
        colIndex++;
      });
    });
    worksheet['!cols'] = wsColsSettings;
    XLSX.writeFile(workbook, filename, {
      compression: true,
      cellStyles: true,
      cellDates: true
    });
  });
}
export function exportToXlsx(dataset, columns, filename) {
  var resultDataset = prepareDataset(dataset);
  var resultColumns = Object.values(columns);
  json2Excel(resultDataset, resultColumns, filename);
}
/**
 * Generate the data out of html
 * @param html The html nodes of the table
 * @param filename Filename that the file will be saved as
 * @param sheetName - The name of the sheet in the workbook
 * @param fileType - default XLSX - The filetype you would like to export to
 */
function export_table_to_excel(html, filename, sheetName) {
  var fileType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'xlsx';
  var filenameAndType = "".concat(filename, ".").concat(fileType);
  return import('xlsx').then(function (XLSX) {
    var wb = XLSX.utils.table_to_book(html, {
      sheet: sheetName,
      raw: true
    });
    var ws = XLSX.write(wb, {
      bookType: fileType,
      type: 'binary'
    });
    var blob = new Blob([sheet_to_array_buffer(ws)], {
      type: 'application/octet-stream'
    });
    return handleSave(filenameAndType, blob);
  });
}
function getFilename(filename, addDate) {
  var Filename = addDate === true ? "RISMAbusiness ".concat(filename, " - ").concat(getIsoDate()) : filename;
  return Filename.replace(/\s/g, '_');
}
function detectDateFormatForElsx(date) {
  var possibleFormats = [DateFormats.DATE_TIME_FORMAT, DateFormats.DATE_TIME_FORMAT_FULL, DateFormats.DATE_TIME_Z_FORMAT];
  var dateFormat = null;
  possibleFormats.forEach(function (format) {
    if (isDateValid(date, format, true)) {
      dateFormat = format;
    }
  });
  return dateFormat;
}
function prepareDataset(data) {
  var result = [];
  var max_cell_length = 32766;
  var columnsTypes = {};
  data.forEach(function (row) {
    var preparedRow = {};
    Object.entries(row).map(function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
        key = _ref3[0],
        value = _ref3[1];
      value = value == undefined ? '' : value.toString();
      value = value ? value.slice(0, max_cell_length) + '' : '';
      preparedRow[key] = {
        v: htmlDecode(value)
      };
      if (!columnsTypes[key]) {
        if (value.indexOf(', ') !== -1) {
          columnsTypes[key] = CellTypes.TEXT;
        } else if (!value.match(/^\d+$/gm) && value.match(/^[0-9]+([\s:-][0-9]+)*$/gm)) {
          columnsTypes[key] = CellTypes.DATE;
        }
      }
      if (columnsTypes[key]) {
        if (columnsTypes[key] === CellTypes.DATE) {
          var dateFormat = detectDateFormatForElsx(preparedRow[key].v);
          switch (dateFormat) {
            case DateFormats.DATE_TIME_FORMAT:
            case DateFormats.DATE_TIME_FORMAT_FULL:
            case DateFormats.DATE_TIME_Z_FORMAT:
              preparedRow[key].z = SSF[22];
              break;
            default:
              preparedRow[key].z = SSF[14];
          }
        } else {
          preparedRow[key].t = columnsTypes[key];
        }
      }
    });
    result.push(preparedRow);
  });
  return result;
}

// decode html special characters to user view (&oslash - ø, &aring - å, etc.)
function htmlDecode() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  value = value.replaceAll('&amp;', '&');
  return $('<textarea/>').html(value).text();
}