import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_modal = _resolveComponent("modal");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [_ctx.isModalShow ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_modal, {
    header: _ctx.$trans('Attach files'),
    onAccept: $options.toggleModal,
    onDismiss: $options.toggleModal
  }, null, 8 /* PROPS */, ["header", "onAccept", "onDismiss"])])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_button, {
    text: "Show Modal",
    onClick: $options.toggleModal
  }, null, 8 /* PROPS */, ["onClick"])]);
}