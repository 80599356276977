import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("p", {
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('click', $event);
    })
  }, [_createTextVNode(_toDisplayString($options.formattedString) + " ", 1 /* TEXT */), $props.text.length > $props.maxChars ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $options.readMoreTitle,
    type: "small",
    class: "read-more-link text-blue-750 inline italic ml-2 cursor-pointer",
    onClick: _withModifiers($options.triggerReadMore, ["stop"])
  }, null, 8 /* PROPS */, ["title", "onClick"])])) : _createCommentVNode("v-if", true)]);
}