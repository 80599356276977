import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "max-w-screen-xxl xxl:mx-auto"
};
var _hoisted_2 = {
  class: "w-35rem m-auto"
};
var _hoisted_3 = {
  class: "mt-4 flex justify-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_notification, {
    ref: "notification",
    class: "m-auto",
    type: $data.notificationType
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$trans($props.error)), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type"]), [[_vShow, $props.error]]), _createElementVNode("section", _hoisted_2, [_createElementVNode("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.twoFactorAuthenticationRequired,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.enterADigitCodeBelowFromYourAuthenticator,
    truncate: false,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_input_field, {
    modelValue: $data.code,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.code = $event;
    }),
    "focus-on-mount": true,
    placeholder: $data.translate.enterLoginCode,
    class: "my-2",
    type: "text",
    onOnEnter: $options.onMfaLogin
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "onOnEnter"]), _createVNode(_component_risma_button, {
    text: $data.translate.logIn,
    type: "save",
    class: "w-fit ml-auto",
    onClick: $options.onMfaLogin
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "check",
        class: "stroke-2 mr-2"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"])])])])]);
}