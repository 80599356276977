function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getTranslate } from './translate';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import Notification from '@/components/Molecules/Notification';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import Alert from '@/components/Molecules/Modal/Alert';
import * as riskAPI from '@/api/risk/risk';
import * as dataAPI from '@/api/risma/data';
import { dataURItoBlob, loadBlobAsURI } from '@/utils/blobUtils';
import { ErmRiskStatus } from '@/constants/risks/RiskStatus';
export default {
  name: 'PdfReportEditModal',
  components: {
    RismaFileUpload: RismaFileUpload,
    RismaToggle: RismaToggle,
    RismaSelect: RismaSelect,
    Modal: Modal,
    RismaLabel: RismaLabel,
    InputField: InputField,
    Notification: Notification,
    FeatherIcon: FeatherIcon,
    Alert: Alert
  },
  props: {
    currentReportId: {
      type: Number,
      required: false,
      default: null
    }
  },
  emits: ['dismiss', 'updateReport', 'createReport'],
  data: function data() {
    return {
      draft: {
        title: '',
        subTitle: '',
        baselineScoringId: null,
        managementLevelIds: [],
        fieldIds: [],
        organisationIds: [],
        page2: 0,
        includeUnapprovedRisks: 0
      },
      fileBlobData: null,
      initVersion: null,
      managementLevels: [],
      openScorings: [],
      errorMessages: [],
      imgSrc: null,
      isOkButtonDisabled: false,
      showAlert: false,
      translate: getTranslate['PdfReportEditModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useOrganisationsStore, ['organisations'])), {}, {
    fieldTitlesToValidation: function fieldTitlesToValidation() {
      return {
        title: this.translate.title,
        subTitle: this.translate.subtitle,
        baselineScoringId: this.translate.baseline,
        managementLevelIds: this.translate.managementLevels,
        organisationIds: this.translate.organisations
      };
    },
    validationRules: function validationRules() {
      return {
        notFalse: {
          validationPattern: function validationPattern(value) {
            return !!value;
          },
          validationNotification: this.translate.pleaseFillOutThis,
          validationFields: ['title']
        },
        notNullish: {
          validationPattern: function validationPattern(value) {
            var result = value !== null && value !== void 0 ? value : false;
            return value === result;
          },
          validationNotification: this.translate.pleaseSelectOne,
          validationFields: ['baselineScoringId']
        },
        notEmpty: {
          validationPattern: function validationPattern(value) {
            return value.length;
          },
          validationNotification: this.translate.pleaseSelectOne,
          validationFields: ['managementLevelIds', 'organisationIds']
        }
      };
    },
    modalHeader: function modalHeader() {
      return this.currentReportId === null ? this.translate.createPDFReport : this.translate.editPDFReport;
    },
    allExtraColumns: function allExtraColumns() {
      var _this = this;
      var currentScoring = this.openScorings.find(function (item) {
        return item.id === _this.draft.baselineScoringId && item.module !== 'scoring-zaxis';
      });
      return (currentScoring === null || currentScoring === void 0 ? void 0 : currentScoring.fields) || [];
    },
    preSelectedOptions: function preSelectedOptions() {
      var _this2 = this;
      return {
        currentScoring: this.getSelectOptions(this.openScorings, 'version').filter(function (scoring) {
          return scoring.value === _this2.draft.baselineScoringId;
        }),
        managementLevelIds: this.getSelectOptions(this.managementLevels, 'name').filter(function (ml) {
          return _this2.draft.managementLevelIds.includes(ml.value);
        }),
        extraColumns: this.getSelectOptions(this.allExtraColumns, 'label').filter(function (ec) {
          return _this2.draft.fieldIds.includes(ec.value);
        }),
        organisations: this.getSelectOptions(this.organisations, 'visible_name').filter(function (org) {
          return _this2.draft.organisationIds.includes(org.value);
        })
      };
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    handleDismiss: function handleDismiss() {
      if (this.isDirty()) {
        this.showAlert = true;
        return;
      }
      this.$emit('dismiss');
    },
    isDirty: function isDirty() {
      return !isEqual(this.initVersion, this.draft) || this.fileBlobData;
    },
    getData: function getData() {
      var _this3 = this;
      var promises = [this.getManagementLevels(), this.getScorings(), this.getReportPreview()];
      if (this.currentReportId !== null) {
        promises.push(this.getCurrentReport());
      }
      Promise.allSettled(promises).then(function () {
        return _this3.initVersion = cloneDeep(_this3.draft);
      });
    },
    getSelectOptions: function getSelectOptions(data, nameField) {
      return data.map(function (item) {
        return {
          value: item.id,
          label: item[nameField]
        };
      });
    },
    getManagementLevels: function getManagementLevels() {
      var _this4 = this;
      return riskAPI.getRisksManagementLevels().then(function (_ref) {
        var list = _ref.list;
        return _this4.managementLevels = list;
      });
    },
    getScorings: function getScorings() {
      var _this5 = this;
      return dataAPI.getAllScorings().then(function (_ref2) {
        var list = _ref2.list;
        _this5.openScorings = list.filter(function (scoring) {
          return scoring.status !== ErmRiskStatus.EDITING;
        });
      });
    },
    getReportPreview: function getReportPreview() {
      var _this6 = this;
      if (this.currentReportId) {
        return riskAPI.getManagementReportPreview(this.currentReportId).then(function (blob) {
          return loadBlobAsURI(blob, function (uri) {
            _this6.imgSrc = uri;
          });
        });
      }
    },
    updateDraft: function updateDraft(property, value) {
      if (Array.isArray(value)) {
        value = value.map(function (item) {
          return item.value;
        });
      }
      this.draft = _objectSpread(_objectSpread({}, this.draft), {}, _defineProperty({}, property, value));
    },
    validateDraft: function validateDraft() {
      var _this7 = this;
      var errorMessages = [];
      Object.values(this.validationRules).forEach(function (rule) {
        rule.validationFields.forEach(function (field) {
          if (!rule.validationPattern(_this7.draft[field])) {
            errorMessages.push("".concat(rule.validationNotification, ": ").concat(_this7.fieldTitlesToValidation[field]));
          }
        });
      });
      this.errorMessages = errorMessages;
      return !errorMessages.length;
    },
    getCurrentReport: function getCurrentReport() {
      var _this8 = this;
      return riskAPI.getManagementReportById(this.currentReportId).then(function (item) {
        _this8.draft = {
          //looks like a test data has title with an empty space in some titles
          title: item.title.trim(),
          subTitle: item.subTitle,
          baselineScoringId: item.baselineScoringId,
          managementLevelIds: item.managementLevelIds,
          fieldIds: item.fieldIds,
          organisationIds: item.organisationIds,
          page2: item.page2,
          includeUnapprovedRisks: item.includeUnapprovedRisks
        };
      });
    },
    initReportSaving: function initReportSaving() {
      if (this.validateDraft()) {
        if (this.isDirty()) {
          this.isOkButtonDisabled = true;
          if (this.currentReportId !== null) {
            this.$emit('updateReport', {
              draft: this.draft,
              file: this.fileBlobData
            });
          } else {
            this.$emit('createReport', {
              draft: this.draft,
              file: this.fileBlobData
            });
          }
          return;
        }
        this.$emit('dismiss');
      }
    },
    handleLoadFile: function handleLoadFile(fileList) {
      var file = fileList.slice(fileList.length - 1);
      this.imgSrc = "data:".concat(file[0].mimeType, ";base64,").concat(file[0].content);
      this.fileBlobData = dataURItoBlob(this.imgSrc);
    },
    initFileHandling: function initFileHandling() {
      this.$refs.fileUploadItem.clickInput();
    }
  }
};