import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [_ctx.isAlertShow ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_alert, {
    body: _ctx.$trans('The Control incl. completed controls will be permanently deleted.'),
    header: "Are you sure?",
    "button-ok-text": "Delete",
    onAccept: $options.toggleAlert,
    onDismiss: $options.toggleAlert
  }, null, 8 /* PROPS */, ["body", "onAccept", "onDismiss"])])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_button, {
    text: "Show Alert",
    onClick: $options.toggleAlert
  }, null, 8 /* PROPS */, ["onClick"])]);
}