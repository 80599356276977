import ExportFrame from '@/components/Atoms/Containers/ExportFrame';
import RismaSwitch from '@/components/Atoms/Inputs/RismaSwitch';
export default {
  name: 'PerformanceCard',
  description: 'Wrapper for performance page items',
  token: "<performance-card\n      title=\"Project progress\" /> ",
  components: {
    ExportFrame: ExportFrame,
    RismaSwitch: RismaSwitch
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    subTitle: {
      type: String,
      required: false,
      default: '',
      note: 'Goes after main title'
    },
    labelTitle: {
      type: String,
      required: false,
      default: ''
    },
    overriddenOverlayClasses: {
      type: String,
      required: false,
      default: ''
    },
    overriddenExportFrameClasses: {
      type: String,
      required: false,
      default: ''
    },
    useDefaultExport: {
      type: Boolean,
      required: false,
      default: true
    },
    hasMonthlyDailySwitch: {
      type: Boolean,
      required: false,
      default: false
    },
    monthlyDailyOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    isExportOnParent: {
      type: Boolean,
      required: false,
      default: false
    },
    monthlyDailyValue: {
      required: false,
      type: String,
      default: ''
    }
  },
  emits: ['progressTypeChanged'],
  methods: {
    onMonthlyDailyChange: function onMonthlyDailyChange(data) {
      this.$emit('progressTypeChanged', data);
    }
  }
};