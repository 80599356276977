import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mb-1"
};
var _hoisted_2 = {
  key: 3
};
var _hoisted_3 = {
  class: "mb-6"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  class: "text-center"
};
var _hoisted_6 = {
  key: 1,
  class: "flex flex-wrap justify-center lg:flex-nowrap mb-12 -mx-4"
};
var _hoisted_7 = {
  class: "px-4 lg:w-1/3 w-650px"
};
var _hoisted_8 = {
  class: "mb-6 text-white bg-blue-250 py-2 px-3"
};
var _hoisted_9 = {
  class: "w-350px m-auto mb-4"
};
var _hoisted_10 = {
  class: "px-4 lg:flex-grow w-650px min-h-375px"
};
var _hoisted_11 = {
  class: "text-white bg-blue-250 py-2 px-3"
};
var _hoisted_12 = {
  key: 2
};
var _hoisted_13 = {
  class: "flex items-center text-white bg-blue-250 mb-2 py-2 px-3"
};
var _hoisted_14 = {
  key: 0
};
var _hoisted_15 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_notification = _resolveComponent("notification");
  var _component_risma_selector_bar = _resolveComponent("risma-selector-bar");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_doughnut_chart = _resolveComponent("risma-doughnut-chart");
  var _component_risma_bar_chart = _resolveComponent("risma-bar-chart");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_gap_table = _resolveComponent("gap-table");
  var _component_static_tab_item = _resolveComponent("static-tab-item");
  var _component_static_tabs = _resolveComponent("static-tabs");
  var _component_supervisor_initiatives = _resolveComponent("supervisor-initiatives");
  var _component_supervisor_controls = _resolveComponent("supervisor-controls");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $options.title
  }, null, 8 /* PROPS */, ["title"]), _ctx.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error",
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.errorMessage = '';
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$trans(_ctx.errorMessage)), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _ctx.isLoadedStaticData ? (_openBlock(), _createBlock(_component_risma_selector_bar, {
    key: 1,
    ref: "filter",
    "project-id": $props.projectId,
    selectors: $options.selectors,
    orgs: _ctx.orgs,
    users: _ctx.users,
    nodes: _ctx.nodes,
    tags: _ctx.tags,
    onListChange: _ctx.fetchData
  }, null, 8 /* PROPS */, ["project-id", "selectors", "orgs", "users", "nodes", "tags", "onListChange"])) : _createCommentVNode("v-if", true), _ctx.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 2
  })) : _createCommentVNode("v-if", true), !_ctx.loading ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [!$options.gapsAvailable ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.nothingToShow
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.translate.editYourSearchCriteriasAboveToViewSearchRe), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), $options.gapsAvailable ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("h2", _hoisted_8, _toDisplayString($data.translate.gapOverview), 1 /* TEXT */), _createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_doughnut_chart, {
    datasets: $options.pieChartData.datasets,
    labels: $options.pieChartData.labels,
    "show-data-labels": true,
    "percentage-value": false,
    onChartClicked: _ctx.handleClickSlice
  }, null, 8 /* PROPS */, ["datasets", "labels", "onChartClicked"])])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("h2", _hoisted_11, _toDisplayString($data.translate.overviewPrDomain), 1 /* TEXT */), _ctx.gapDomains.datasets ? (_openBlock(), _createBlock(_component_risma_bar_chart, {
    key: 0,
    labels: _ctx.gapDomains.labels,
    datasets: _ctx.gapDomains.datasets,
    "show-legend": true,
    "show-data-labels": true,
    "items-per-scroll": 10,
    type: "Stacked"
  }, null, 8 /* PROPS */, ["labels", "datasets"])) : _createCommentVNode("v-if", true)])])) : _createCommentVNode("v-if", true), _ctx.isTableShown && $options.gapsAvailable ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("h2", _hoisted_13, [_createVNode(_component_traffic_light, {
    color: _ctx.sliceSelected.color,
    title: _ctx.$trans(_ctx.sliceSelected.label),
    class: "mr-1"
  }, null, 8 /* PROPS */, ["color", "title"]), _createElementVNode("span", null, _toDisplayString(_ctx.$trans(_ctx.sliceSelected.label)), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    icon: "x",
    class: "text-white stroke-3 cursor-pointer ml-auto",
    onClick: _ctx.clearSliceSelected
  }, null, 8 /* PROPS */, ["onClick"])]), _createVNode(_component_gap_table, {
    gaps: _ctx.formattedGaps,
    "gap-article-map": _ctx.gapDashboardData.gapArticleMap,
    "show-risk-assessment": $options.enableGapRiskModule && $options.showRiskAssessment
  }, null, 8 /* PROPS */, ["gaps", "gap-article-map", "show-risk-assessment"])])) : _createCommentVNode("v-if", true)]), !$options.supervisorTabs.actions && !$options.supervisorTabs.controls ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("b", null, _toDisplayString($data.translate.noteUserDoesNotHaveAccessToSeeControlsOr), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $options.gapsAvailable ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createVNode(_component_static_tabs, null, {
    default: _withCtx(function () {
      return [$options.supervisorTabs.actions ? (_openBlock(), _createBlock(_component_static_tab_item, {
        key: 0,
        active: $options.activeTab === '#actions',
        to: "#actions"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.actions), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["active"])) : _createCommentVNode("v-if", true), $options.supervisorTabs.controls ? (_openBlock(), _createBlock(_component_static_tab_item, {
        key: 1,
        active: $options.activeTab === '#controls',
        to: "#controls"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.controls), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["active"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }), $options.supervisorTabs.actions && $options.activeTab === '#actions' ? (_openBlock(), _createBlock(_component_supervisor_initiatives, {
    key: 0,
    id: "initiatives",
    initiatives: $options.initiatives,
    "gap-article-map": _ctx.gapDashboardData.gapArticleMap,
    class: "border p-4",
    onDismissModal: $options.onDismissPreview
  }, null, 8 /* PROPS */, ["initiatives", "gap-article-map", "onDismissModal"])) : _createCommentVNode("v-if", true), $options.supervisorTabs.controls && $options.activeTab === '#controls' ? (_openBlock(), _createBlock(_component_supervisor_controls, {
    key: 1,
    id: "controls",
    controls: $data.controls,
    users: _ctx.users,
    organisations: _ctx.orgs,
    "gap-article-map": _ctx.gapDashboardData.gapArticleMap,
    class: "border p-4",
    onDismissModal: $options.onDismissPreview
  }, null, 8 /* PROPS */, ["controls", "users", "organisations", "gap-article-map", "onDismissModal"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]);
}