import { MenuKeys } from '@/constants/menu/MenuKeys';
import { UserLevels } from '@/constants/UserLevels';
import { calcIncidentsRights } from '@/menus/quicknav';
function buildIncidentsMenu(settings, user) {
  var menuStructure = {
    main: [],
    admin: []
  };
  menuStructure.main.push({
    url: '/incident/list',
    title: 'Overview',
    rights: true,
    menuKey: MenuKeys.incidents
  }, {
    url: '/incident/my',
    title: 'My incidents',
    rights: true,
    menuKey: MenuKeys.myIncidents,
    checkActiveSubItem: function checkActiveSubItem(url) {
      return url.startsWith('/incident/my');
    }
  }, {
    url: '/incident/archived',
    title: 'Archived incidents',
    rights: calcIncidentsRights(user, settings, UserLevels.SUPER),
    menuKey: MenuKeys.incidentsArchived
  }, {
    url: '/incident/report',
    title: 'Reports',
    exact: true,
    rights: true,
    menuKey: MenuKeys.reports
  }, {
    url: '/incident/dashboard',
    title: 'Dashboard',
    rights: true
  });
  return menuStructure;
}
export default buildIncidentsMenu;