function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import * as ControlsApi from '@/api/controls/controls';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import UserSelector from '@/components/Molecules/UserSelector';
import OrganisationSelector from '@/components/Molecules/OrganisationSelector';
import Notification from '@/components/Molecules/Notification';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import Modal from '@/components/Molecules/Modal/Modal';
import SolutionsSelector from '@/components/Molecules/SolutionsSelector';
import { getCustomFieldValues } from '@/utils/CustomFields';
import { UserLevels } from '@/constants/UserLevels';
export default {
  name: 'ControlCreationModal',
  introduction: 'ControlCreationModal multiselect popup',
  description: 'ControlCreationModal with  selector and list of selected items',
  token: '<control-creation-modal />',
  components: {
    Modal: Modal,
    InputField: InputField,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    OrganisationSelector: OrganisationSelector,
    UserSelector: UserSelector,
    Notification: Notification,
    TextBox: TextBox,
    SolutionsSelector: SolutionsSelector
  },
  props: {
    isMotherMode: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is Mother control'
    },
    motherControl: {
      type: Object,
      required: false,
      default: Object,
      note: 'Mother control data object.'
    },
    preset: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      },
      note: 'Preset fields from activity'
    },
    showAllFields: {
      required: false,
      type: Boolean,
      default: false,
      note: 'If to show all available fields'
    },
    motherControlPreselectedMatch: {
      required: false,
      type: String,
      default: '',
      note: 'String for checking preselected mother control'
    }
  },
  emits: ['close', 'update'],
  data: function data() {
    return {
      data: {
        title: '',
        activityTypeId: null,
        description: '',
        organisations: [],
        solutions: [],
        responsible: []
      },
      errorMessages: [],
      motherControlData: {},
      motherControls: [],
      motherControlChosen: null,
      isRismaSelectOpen: false,
      translate: getTranslate['ControlCreationModal'](),
      isCreating: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useActivityTypesStore, ['controlActivityTypes'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    controlUsers: function controlUsers() {
      return this.users.filter(function (user) {
        return user.level_controls > UserLevels.NONE || user.is_admin || user.level_admin;
      });
    },
    titleModal: function titleModal() {
      if (this.motherControlId) {
        return this.translate.createDaughterControl;
      }
      if (this.isMotherMode) {
        return this.translate.createMotherControl;
      }
      return this.translate.createControl;
    },
    activityTypeOptions: function activityTypeOptions() {
      return this.controlActivityTypes.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          value: item.id
        });
      });
    },
    montherControlsOptions: function montherControlsOptions() {
      return this.motherControls.map(function (item) {
        return {
          value: item.cpath,
          label: item.cpath + ' - ' + item.title
        };
      });
    },
    motherControlId: function motherControlId() {
      return this.motherControlData.cpath;
    },
    formatedData: function formatedData() {
      var baseData = {
        title: this.data.title,
        activityTypeId: this.data.activityTypeId,
        solutionIds: this.data.solutions.map(function (item) {
          return item.id;
        }),
        description: this.data.description
      };
      if (this.isMotherMode) {
        return _objectSpread(_objectSpread({}, baseData), {}, {
          isMothercontrol: 1
        });
      }
      if (this.motherControlId) {
        baseData = _objectSpread(_objectSpread({}, baseData), this.inheritedData);
      }
      return _objectSpread(_objectSpread({}, baseData), {}, {
        organisationIds: this.data.organisations.map(function (item) {
          return item.id;
        }),
        userIds: {
          responsible: this.data.responsible.map(function (item) {
            return item.id;
          })
        },
        isMothercontrol: 0
      });
    },
    inheritedData: function inheritedData() {
      var data = {};
      var customFieldValues = this.motherControlData.customFieldValues;
      var isActivityTypeMatched = this.motherControlData.activityTypeId === this.data.activityTypeId;
      if (isActivityTypeMatched && customFieldValues && customFieldValues.length) {
        data.customFieldValues = getCustomFieldValues(this.motherControlData);
      }
      return _objectSpread(_objectSpread({}, data), {}, {
        mothercontrolId: this.motherControlId,
        accounts: this.motherControlData.accounts.map(function (el) {
          return el.id;
        }),
        processes: this.motherControlData.processes.map(function (el) {
          return el.id;
        }),
        risks: this.motherControlData.risks.map(function (el) {
          return el.id;
        })
      });
    },
    creationMethod: function creationMethod() {
      return this.isMotherMode ? 'createNewMotherControl' : 'createNewControl';
    },
    preselectedActivityTypeId: function preselectedActivityTypeId() {
      var setting = this.isMotherMode ? 'feature.mother_controls_default_activity_type_id' : 'feature.controls_default_activity_type_id';
      return this.getSettingValue(setting);
    }
  }),
  mounted: function mounted() {
    this.setPreset();
    this.assignMotherControl();
    this.getMotherControls();
  },
  methods: {
    assignMotherControl: function assignMotherControl() {
      this.motherControlData = this.motherControl;
    },
    getMotherControls: function getMotherControls() {
      var _this = this;
      if (!this.showAllFields) return;
      return ControlsApi.getAllMotherControls().then(function (_ref) {
        var list = _ref.list;
        _this.motherControls = list;
        _this.checkMatchingMotherControl();
      });
    },
    checkMatchingMotherControl: function checkMatchingMotherControl() {
      var _this2 = this;
      if (!this.showAllFields) return;
      var motherControl = this.motherControls.find(function (control) {
        return control.title.startsWith(_this2.motherControlPreselectedMatch);
      });
      if (motherControl) {
        this.motherControlChosen = motherControl.cpath;
      }
    },
    setPreset: function setPreset() {
      if (this.preset.title) this.propertyChange(this.preset.title, 'title');
      if (this.preset.description) this.propertyChange(this.preset.description, 'description');
      if (this.preselectedActivityTypeId) this.propertyChange(this.preselectedActivityTypeId, 'activityTypeId');
    },
    propertyChange: function propertyChange(value, property) {
      this.data[property] = value;
    },
    submitModal: function submitModal() {
      if (this.handleValidation(this.data)) {
        this.handleApiRequest();
      }
    },
    handleValidation: function handleValidation(data) {
      this.errorMessages = [];
      if (!data.title || !data.title.trim()) {
        this.errorMessages.push(this.translate.titleCannotBeEmpty);
      }
      if (!this.isMotherMode && (!data.responsible || !data.responsible.length) && !this.getSettingValue('feature.controls_allow_no_owners')) {
        this.errorMessages.push(this.translate.responsibleIsRequired);
      }
      if (!this.isMotherMode && (!data.organisations || !data.organisations.length)) {
        this.errorMessages.push(this.translate.atLeastOneOrganisationIsRequired);
      }
      return !this.errorMessages.length;
    },
    handleApiRequest: function handleApiRequest() {
      var _this3 = this;
      this.errorMessages = [];
      this.isCreating = true;
      this.getSelectedMotherControl().then(this.createControl).finally(function () {
        return _this3.isCreating = false;
      });
    },
    setDescription: function setDescription(e) {
      this.propertyChange(e, 'description');
    },
    getSelectedMotherControl: function getSelectedMotherControl() {
      var _this4 = this;
      if (!this.motherControlChosen) return Promise.resolve();
      return ControlsApi.getControlById(this.motherControlChosen).then(function (response) {
        return _this4.motherControlData = response;
      });
    },
    createControl: function createControl() {
      var _this5 = this;
      return ControlsApi[this.creationMethod](this.formatedData).then(function (newControl) {
        newControl.id = +newControl.id;
        newControl.isMothercontrol = +newControl.isMothercontrol;
        _this5.$emit('update', newControl);
        _this5.$emit('close');
      }).catch(function (e) {
        _this5.errorMessages.push(e.response.error);
      });
    }
  }
};