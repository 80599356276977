function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import * as Utils from '@/utils/Utils';
import { getCustomFieldValues } from '@/utils/CustomFields';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import Notification from '@/components/Molecules/Notification';
import PaginatedTable from '@/components/Organisms/PaginatedTable';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import StaticTable from '@/components/Molecules/StaticTable';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import ViewSwitcher from '@/components/Molecules/ViewSwitcher';
import Alert from '@/components/Molecules/Modal/Alert';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { getAll as _getIncidents, remove as removeIncident } from '@/api/incident/incident';
import { Colors } from '@/Colors';
import { INCIDENTS_VIEWS } from '@/constants/ViewTypes';
import { INCIDENT_STATES } from '@/constants/IncidentStateCodes';
import { UserLevels } from '@/constants/UserLevels';
import Modal from '../../Molecules/Modal/Modal';
import IncidentDescription from './IncidentDescription';
import { create } from '../../../api/incident/incident';
import { toLocalDateTime, isDateAfter } from '@/utils/date';
import { NotificationPopUpMixin } from '@/components/Atoms/NotificationPopUpMixin';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { isFieldInOptionalFields } from '@/utils/Utils';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
var VIEWS = {
  TILES: 'tiles',
  COMPACT: 'compact'
};
export default {
  name: 'IncidentList',
  components: {
    PreviewModalLink: PreviewModalLink,
    IncidentDescription: IncidentDescription,
    Modal: Modal,
    FeatherIcon: FeatherIcon,
    InputField: InputField,
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    Notification: Notification,
    PaginatedTable: PaginatedTable,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    StaticTable: StaticTable,
    RismaSelect: RismaSelect,
    ViewSwitcher: ViewSwitcher,
    Alert: Alert,
    NotificationPopUp: NotificationPopUp,
    TrafficLight: TrafficLight,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  description: 'Incidents overview page',
  token: '<incident-list />',
  mixins: [NotificationPopUpMixin],
  setup: function setup() {
    return {
      selectedStatus: getLocalStorageComputed('incidents_filtered_status', [], true),
      incidentsVisibleTypes: getLocalStorageComputed('incidents_visible_types', [], true),
      currentView: getLocalStorageComputed('incidents_display_view', INCIDENTS_VIEWS[0].name)
    };
  },
  data: function data() {
    return {
      isRismaSelectOpen: false,
      pageLoaded: false,
      incidents: null,
      updatedIncident: null,
      activityTypes: null,
      filterString: '',
      currentActivity: null,
      errors: [],
      createErrors: [],
      systemColors: Colors.system,
      INCIDENTS_VIEWS: INCIDENTS_VIEWS,
      VIEWS: VIEWS,
      deletedIncidentId: null,
      newIncident: null,
      columnsMaxWidth: {
        name: '200px'
      },
      hoverTitles: {
        name: function name(value) {
          return value.title;
        }
      },
      translate: getTranslate['IncidentList'](),
      creatingIncident: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, ['incidentActivityTypes'])), {}, {
    hasActivityTypes: function hasActivityTypes() {
      return this.activityTypes && this.activityTypes.length > 0;
    },
    columnsHashMap: function columnsHashMap() {
      var _this = this;
      var hashMap = new Map();
      this.activityTypes.forEach(function (activityType) {
        hashMap.set(activityType.id, _this.prepareColumnsForActivityType(activityType));
      });
      return hashMap;
    },
    groupedIncidents: function groupedIncidents() {
      var _this2 = this;
      var groups = {};
      if (this.filterString === '') {
        this.activityTypes.forEach(function (activityType) {
          groups[activityType.label] = {};
          groups[activityType.label].activities = [];
          groups[activityType.label].columns = _this2.columnsHashMap.get(activityType.id);
        });
      }
      this.incidents.forEach(function (incident) {
        var activityType = _this2.activityTypeById(incident.activityTypeId);
        var isSearchedAndFound = _this2.searchFound(incident, _this2.filterString);
        if (activityType && isSearchedAndFound) {
          if (groups[activityType.label] === undefined) {
            groups[activityType.label] = {};
          }
          groups[activityType.label].columns = _this2.columnsHashMap.get(activityType.id);
          if (groups[activityType.label].activities === undefined) {
            groups[activityType.label].activities = [];
          }
          if (_this2.selectedStatus.length && !_this2.selectedStatus.find(function (item) {
            return item.value == incident.rismaState;
          })) return;
          incident.hoverTitle = DatatableRenderer.getItemHoverTitle(incident);
          groups[activityType.label].activities.push(incident);
        }
      });
      return groups;
    },
    isUserLocked: function isUserLocked() {
      return this.currentUser.level_incidents_locked === 1;
    },
    statusFilterOptions: function statusFilterOptions() {
      var _this3 = this;
      return Object.keys(INCIDENT_STATES).map(function (key) {
        return {
          name: _this3.$trans(INCIDENT_STATES[key].name),
          value: INCIDENT_STATES[key].value
        };
      });
    },
    currentTable: function currentTable() {
      return this.currentView === VIEWS.TILES ? 'paginated-table' : 'static-table';
    },
    isIncidentCanBeDeleted: function isIncidentCanBeDeleted() {
      return this.currentUser.level_admin || this.currentUser.level_incidents >= UserLevels.SUPER && !this.isUserLocked;
    },
    showIdFeatureEnabled: function showIdFeatureEnabled() {
      return this.getSettingValue('feature.show_incident_id');
    }
  }),
  created: function created() {
    var _this4 = this;
    this.activityTypes = this.enhanceActivityTypes(this.incidentActivityTypes);
    this.getIncidents().then(function () {
      return _this4.pageLoaded = true;
    });
  },
  methods: {
    getIncidents: function getIncidents() {
      var _this5 = this;
      return _getIncidents().then(function (response) {
        return _this5.incidents = response;
      });
    },
    formatIncidents: function formatIncidents(list) {
      return list.sort(function (a, b) {
        return isDateAfter(a.createdAt, b.createdAt) ? -1 : 1;
      }).map(this.prepareTableRow);
    },
    updateFromPreviewMode: function updateFromPreviewMode(item) {
      var _this6 = this;
      this.updatedIncident = item;
      var index = this.incidents.findIndex(function (incident) {
        return +incident.id === +_this6.updatedIncident.id;
      });
      this.incidents[index] = this.updatedIncident;
      this.updatedIncident = null;
    },
    prepareTableRow: function prepareTableRow(incident) {
      var data = {};
      if (incident.severity !== null) {
        data.severity = DatatableRenderer.prepareSeverityField(incident.severity);
      }
      if (this.showIdFeatureEnabled) {
        data.id = incident.id;
      }
      data = _objectSpread(_objectSpread({}, data), {}, {
        name: incident,
        status: this.getStateName(incident.rismaState),
        created: toLocalDateTime(incident.createdAt)
      });
      if (this.currentView !== VIEWS.TILES) {
        data.responsible = Utils.findPropsByIds(incident.responsibleUserIds, this.users, 'display_name');
      }
      if (this.isIncidentCanBeDeleted) {
        data.actions = {
          id: incident.id
        };
      }
      return data;
    },
    prepareColumnsForActivityType: function prepareColumnsForActivityType(activityType) {
      var columns = [];
      if (activityType.severityIncluded) {
        columns.push(this.translate.severity);
      }
      if (this.showIdFeatureEnabled) {
        columns.push(this.translate.id);
      }
      columns = columns.concat([this.translate.title, this.translate.status, this.translate.created]);
      if (this.currentView === VIEWS.COMPACT) {
        columns.push(this.translate.responsible);
      }
      return [].concat(_toConsumableArray(columns), ['']);
    },
    activityTypeById: function activityTypeById(id) {
      var type = this.activityTypes.find(function (type) {
        return type.id === id;
      });
      if (type) return type;
      this.errors.push(this.translate.anIncidentHasCorruptDataPleaseContactSuppo);
      return null;
    },
    activityTypeByLabel: function activityTypeByLabel(label) {
      var type = this.activityTypes.find(function (type) {
        return type.label === label;
      });
      if (type) return type;
      this.errors.push(this.translate.anActivityTypeCanNotBeFound);
      return null;
    },
    getStateName: function getStateName(state) {
      return this.statusFilterOptions.find(function (item) {
        return item.value === state;
      }).name;
    },
    enhanceActivityTypes: function enhanceActivityTypes(list) {
      var _this7 = this;
      return list.map(function (item) {
        var _this7$incidentsVisib;
        var storageValue = ((_this7$incidentsVisib = _this7.incidentsVisibleTypes) === null || _this7$incidentsVisib === void 0 ? void 0 : _this7$incidentsVisib.length) && _this7.incidentsVisibleTypes.find(function (type) {
          return type.id === item.id;
        });
        var isVisible = storageValue ? storageValue.visible : true;
        return _objectSpread(_objectSpread({}, item), {}, {
          severityIncluded: isFieldInOptionalFields(item, 'severity'),
          visible: isVisible
        });
      });
    },
    toggleVisibility: function toggleVisibility(group) {
      var activityType = this.activityTypes.find(function (type) {
        return type.label == group;
      });
      activityType.visible = !activityType.visible;
      this.incidentsVisibleTypes = this.activityTypes.map(function (item) {
        return {
          id: item.id,
          visible: item.visible
        };
      });
    },
    showCurrentTable: function showCurrentTable(group) {
      if (this.currentView === VIEWS.TILES) return true;
      return this.activityTypeByLabel(group).visible;
    },
    setStatusFilter: function setStatusFilter(e) {
      this.selectedStatus = e;
    },
    deleteIncident: function deleteIncident() {
      var _this8 = this;
      removeIncident(this.deletedIncidentId).then(function () {
        _this8.incidents = _this8.incidents.filter(function (incident) {
          return incident.id !== _this8.deletedIncidentId;
        });
        _this8.hideAlertModal();
      });
    },
    onDeleteIncident: function onDeleteIncident(incidentId) {
      this.deletedIncidentId = incidentId;
    },
    hideAlertModal: function hideAlertModal() {
      this.deletedIncidentId = null;
    },
    showCreateNewIncidentModal: function showCreateNewIncidentModal(activityTypeId) {
      this.createErrors = [];
      this.newIncident = {
        activityTypeId: activityTypeId,
        title: '',
        description: '',
        rismaState: 0,
        customFieldValues: [],
        severity: 0
      };
    },
    handleValidation: function handleValidation(data) {
      this.createErrors = [];
      if (!data.title || !data.title.trim()) {
        this.createErrors.push(this.translate.titleCannotBeEmpty);
      }
      return this.createErrors.length;
    },
    createIncident: function createIncident() {
      var _this9 = this;
      if (this.creatingIncident) return;
      this.creatingIncident = true;
      var customFieldValues = getCustomFieldValues(this.newIncident);
      var data = _objectSpread(_objectSpread({}, this.newIncident), {}, {
        customFieldValues: customFieldValues,
        responsibleUserIds: [this.currentUser.id]
      });
      if (this.newIncident.solutions) {
        data.solutionIds = this.newIncident.solutions.map(function (item) {
          return item.id;
        });
        delete data.solutions;
      }
      if (this.handleValidation(data)) {
        this.creatingIncident = false;
        return;
      }
      create(data).then(function (newIncident) {
        _this9.handleInstantNotification(newIncident);
        _this9.newIncident = null;
        return _this9.getIncidents();
      }).catch(function (_ref) {
        var response = _ref.response;
        var errorMessage = _this9.translate.errorCreatingIncident;
        if (response.error) {
          errorMessage += ': ' + _this9.$trans(response.error);
        }
        _this9.createErrors.push(errorMessage);
      }).finally(function () {
        return _this9.creatingIncident = false;
      });
    },
    searchFound: function searchFound(incident, searchString) {
      var searchFields = ['title'];
      var searchStringLowerCase = searchString.toLowerCase();
      if (this.showIdFeatureEnabled) {
        searchFields.push('id');
      }
      return searchFields.some(function (field) {
        var fieldValue = incident[field] + '';
        return fieldValue.toLowerCase().includes(searchStringLowerCase);
      });
    }
  }
};