import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
export default {
  name: 'PreviewProcessTreeNode',
  components: {
    RismaTitle: RismaTitle
  },
  props: {
    node: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      translate: {
        description: this.$trans('Description')
      }
    };
  }
};