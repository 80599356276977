export var actionTypes = {
  HIDE: 'hide',
  EDIT: 'edit',
  DELETE: 'delete'
};
export var complianceAdminFields = {
  HEADLINE: 'headline',
  QUESTION_NUMBER: 'questionnumber',
  QUESTION: 'question',
  ACTION_HIDE: 'hide',
  ACTION_EDIT: 'edit',
  ACTION_DELETE: 'delete'
};
export var questionnaireAdminFields = {
  TITLE: 'title',
  TAB_LABEL: 'tabLabel',
  DEFAULT_GAP_SCHEMA: 'defaultGapSchema',
  QUESTION_COUNT: 'questionCount',
  USAGE: 'usage',
  ENABLED: 'enabled',
  EDIT: 'edit',
  SCORING: 'scoring',
  COPY: 'copy',
  DELETE: 'delete',
  DOWNLOAD_CSV: 'downloadCSV',
  DOWNLOAD_EXCEL: 'downloadExcel'
};