import i18n from '@/i18n';
export var getTranslate = {
  SaveReport: function SaveReport() {
    return {
      savedReports: i18n.t('Saved reports'),
      saveCurrentReport: i18n.t('Save current report'),
      reportCreated: i18n.t('Report created'),
      reportShared: i18n.t('Report shared'),
      theReportWasSuccessfullyDeleted: i18n.t('The report was successfully deleted')
    };
  }
};