import i18n from '@/i18n';
export var getTranslate = {
  AwarenessDashboard: function AwarenessDashboard() {
    return {
      rismaawarenessDashboard: i18n.t('RISMAawareness dashboard'),
      howDoICreateANewRequestForADocumentSign: i18n.t('How do I create a new request for a document sign-off?'),
      clickOn: i18n.t('Click on'),
      atTheTopNavigationBar: i18n.t('at the top navigation bar.'),
      clickOnTheNodeYouWantToSendASignoffOn: i18n.t('Click on the node you want to send a sign-off on.'),
      thisIsTheStatisticsOnYourAwarenessRequests: i18n.t('This is the statistics on your awareness requests'),
      requestsSentInTotal: i18n.t('Requests sent in total'),
      on: i18n.t('on'),
      linkTo: i18n.t('Link to'),
      user: i18n.t('User'),
      status: i18n.t('Status'),
      requestRead: i18n.t('Request read'),
      latestReminder: i18n.t('Latest reminder'),
      requestApproved: i18n.t('Request approved'),
      areYouSureYouWantToSendRemindersToTheUse: i18n.t('Are you sure you want to send reminders to the users that have not approved?'),
      sendReminder: i18n.t('Send reminder'),
      send: i18n.t('Send'),
      areYouSureYouWantToDeleteThisDashboard: i18n.t('Are you sure you want to delete this dashboard?'),
      deleteDashboard: i18n.t('Delete dashboard'),
      delete: i18n.t('Delete'),
      title: i18n.t('Title'),
      activity: i18n.t('activity'),
      sentBy: i18n.t('Sent by'),
      sent: i18n.t('Sent'),
      requestCountTotal: i18n.t('Request count total'),
      requestsRead: i18n.t('Requests read'),
      requestsApproved: i18n.t('Requests approved'),
      requestsSent: i18n.t('Requests sent'),
      failed: i18n.t('Failed'),
      unknown: i18n.t('Unknown'),
      pending: i18n.t('Pending'),
      clickOnThreeDotsAtTheUpperRightCornerClickOnRequest: i18n.t('Click on the three dots at the upper right corner of the node, and click on \'Request document sign-off\'.'),
      theReminderHasBeenSuccessfullySent: i18n.t('The reminder has been successfully sent')
    };
  },
  AwarenessDetails: function AwarenessDetails() {
    return {
      welcomeToRismaawareness: i18n.t('Welcome to RISMAawareness'),
      youHaveBeenAskedToSignOffThisDocument: i18n.t('You have been asked to sign off this document'),
      theDocumentHasAlreadyBeenSignedOffOn: i18n.t('The document has already been signed off on'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      yesIHaveReadAndIAccept: i18n.t('Yes, I have read and I accept'),
      nothingToShow: i18n.t('Nothing to show')
    };
  }
};