import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  class: "pb-4 flex justify-between"
};
var _hoisted_2 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification_pop_up = _resolveComponent("notification-pop-up");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  var _component_static_table = _resolveComponent("static-table");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [_ctx.notificationData ? (_openBlock(), _createBlock(_component_notification_pop_up, {
        key: 0,
        type: _ctx.notificationData.type,
        onDismiss: _ctx.dismissNotificationPopUp
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", null, _toDisplayString(_ctx.notificationData.title), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["type", "onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        title: $data.translate.riskPeriods
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("button", {
        class: "focus:outline-none",
        disabled: $data.isBtnDisabled,
        onClick: _cache[0] || (_cache[0] = function () {
          return $options.createNewScoring && $options.createNewScoring.apply($options, arguments);
        })
      }, [_createVNode(_component_feather_icon, {
        icon: "plus",
        height: "35",
        width: "35",
        class: "ml-auto stroke-3 text-blue-350 cursor-pointer"
      })], 8 /* PROPS */, _hoisted_2)]), _createVNode(_component_static_table, {
        columns: $options.columns,
        dataset: $options.dataset
      }, {
        title: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_createVNode(_component_router_link, {
            to: slotData.link
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(slotData.title), 1 /* TEXT */)];
            }),

            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["columns", "dataset"])];
    }),
    _: 1 /* STABLE */
  });
}