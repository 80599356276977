import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_datatable, {
    dataset: _ctx.datasetSlice,
    "dataset-total": $data.datasetBig,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "columns-meta": $data.columnsMeta,
    "state-local": $data.stateLocalRismaDatatable,
    "page-length": $data.pageLength,
    "paging-options": $data.pagingOptions,
    order: $data.orderNew,
    "excel-filename": $data.excelFilename,
    "filter-by-column": $data.filterByColumn,
    "column-filters-sorting": $data.columnFiltersSorting,
    "column-sorting": $data.columnSorting,
    "disabled-filter-by-columns": $data.disabledFilterByColumns,
    "decouple-filter-fields": $data.decoupleFilterFields,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged
  }, null, 8 /* PROPS */, ["dataset", "dataset-total", "dataset-total-sliced", "columns-meta", "state-local", "page-length", "paging-options", "order", "excel-filename", "filter-by-column", "column-filters-sorting", "column-sorting", "disabled-filter-by-columns", "decouple-filter-fields", "onPageChange", "onOnStateChanged"])]);
}