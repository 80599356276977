function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createElementBlock as _createElementBlock, renderSlot as _renderSlot, renderList as _renderList, createSlots as _createSlots } from "vue";
var _hoisted_1 = {
  class: "flex relative min-h-content"
};
var _hoisted_2 = {
  key: 1,
  class: "flex flex-col pt-8 w-screen max-w-screen-xxl xxl:mx-auto min-w-0"
};
var _hoisted_3 = {
  key: 2,
  class: "sticky top-25px min-h-full-160"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_dep_header = _resolveComponent("dep-header");
  var _component_router_view = _resolveComponent("router-view");
  var _component_main_well = _resolveComponent("main-well");
  var _component_dep_relations = _resolveComponent("dep-relations");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$props.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $props.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_dep_header, _mergeProps({
    ref: "depHeader"
  }, $options.depHeaderProperties, {
    onUpdatingAttachments: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('updatingAttachments');
    }),
    onChangeTrafficLight: _cache[1] || (_cache[1] = function ($event) {
      return $options.propertyChanged({
        property: $props.depHeaderProps.trafficLightSettings.field,
        value: $event
      });
    }),
    onChangeId: _cache[2] || (_cache[2] = function ($event) {
      return $options.propertyChanged({
        property: $props.depHeaderProps.idField,
        value: $event
      });
    }),
    onChangeTitle: _cache[3] || (_cache[3] = function ($event) {
      return $options.propertyChanged({
        property: $props.depHeaderProps.titleField,
        value: $event
      });
    }),
    onSaveStatusClicked: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('saveStatusClicked');
    }),
    onGetActivityData: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('getActivityData');
    }),
    onChangeTab: $options.changeTab,
    onFileUpdate: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.$emit('fileUpdate', $event);
    })
  }), null, 16 /* FULL_PROPS */, ["onChangeTab"]), _createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [!$options.isPreview ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0
      }, {
        default: _withCtx(function (_ref) {
          var Component = _ref.Component;
          return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), _mergeProps({
            data: $props.item,
            "save-status": $props.depHeaderProps.saveStatus,
            "read-only": $props.readOnly
          }, _ctx.$attrs, {
            onPropertyChanged: $options.propertyChanged,
            onGetActivityData: _cache[7] || (_cache[7] = function ($event) {
              return _ctx.$emit('getActivityData');
            }),
            onSaveStatusClicked: _cache[8] || (_cache[8] = function ($event) {
              return _ctx.$emit('saveStatusClicked');
            }),
            onTriggerAttachmentMethod: $options.triggerAttachmentMethod
          }), null, 16 /* FULL_PROPS */, ["data", "save-status", "read-only", "onPropertyChanged", "onTriggerAttachmentMethod"]))];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), $options.isPreview && $data.currentRoute ? (_openBlock(), _createBlock(_resolveDynamicComponent($data.currentRoute.component), _mergeProps({
        key: 1,
        data: $props.item,
        "save-status": $props.depHeaderProps.saveStatus,
        "read-only": $props.readOnly
      }, _objectSpread(_objectSpread({}, _ctx.$attrs), $options.previewComponentProps), {
        onPropertyChanged: $options.propertyChanged,
        onSaveStatusClicked: _cache[9] || (_cache[9] = function ($event) {
          return _ctx.$emit('saveStatusClicked');
        }),
        onGetActivityData: _cache[10] || (_cache[10] = function ($event) {
          return _ctx.$emit('getActivityData');
        }),
        onTriggerAttachmentMethod: $options.triggerAttachmentMethod
      }), null, 16 /* FULL_PROPS */, ["data", "save-status", "read-only", "onPropertyChanged", "onTriggerAttachmentMethod"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })])) : _createCommentVNode("v-if", true), $props.isLoaded && $props.isRelationEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_dep_relations, {
    class: "-mr-6",
    tabs: $options.filteredTabs,
    "local-storage-key": $props.localStorageKey,
    onChangedViewMode: _cache[11] || (_cache[11] = function ($event) {
      return $data.depRelationsEnabled = $event;
    })
  }, _createSlots({
    _: 2 /* DYNAMIC */
  }, [_renderList(_ctx.$slots, function (index, name) {
    return {
      name: name,
      fn: _withCtx(function () {
        return [_renderSlot(_ctx.$slots, name)];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["tabs", "local-storage-key"])])) : _createCommentVNode("v-if", true)]);
}