import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_options_list = _resolveComponent("options-list");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_options_list, {
    options: [{
      label: 'test',
      id: 1
    }],
    onError: $options.catchEmittedEvent
  }, null, 8 /* PROPS */, ["onError"])]);
}