import i18n from '@/i18n';
export var getTranslate = {
  IncidentDashboard: function IncidentDashboard() {
    return {
      thereAre: i18n.t('There are'),
      incidentsWithoutIncidentDateUs: i18n.t('incident(s) without incident date. Use the reports page to find them.'),
      period: i18n.t('Period'),
      activityType: i18n.t('Activity type'),
      incidentsCount: i18n.t('Incidents count'),
      noResultsFoundYouMayWantToWidenYourSearc: i18n.t('No results found. You may want to widen your search criteria'),
      sumOfCustomFieldPerOrganisation: i18n.t('Sum of custom field per organisation'),
      customField: i18n.t('Custom field'),
      sumPerOrganisation: i18n.t('Sum per organisation'),
      selectCustomField: i18n.t('Select custom field')
    };
  },
  IncidentDescription: function IncidentDescription() {
    return {
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      incidentOccurred: i18n.t('Incident occurred'),
      severity: i18n.t('Severity'),
      status: i18n.t('Status'),
      thisIncidentIsArchivedAndCantBeEdited: i18n.t('This incident is archived and can\'t be edited'),
      archiveIncident: i18n.t('Archive incident'),
      restoreIncident: i18n.t('Restore incident')
    };
  },
  IncidentList: function IncidentList() {
    return {
      yourActivityHasBeenCreated: i18n.t('Your activity has been created'),
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete?'),
      cancel: i18n.t('Cancel'),
      youHaveNoActivityTypesToCreateIncidentsFro: i18n.t('You have no activity types to create incidents from'),
      adminCanCreateActivityTypesInAdministration: i18n.t('Admin can create activity types in administration. Enter administration at the gear-menu in the top right corner.'),
      search: i18n.t('Search'),
      status: i18n.t('Status'),
      noFilter: i18n.t('No filter'),
      display: i18n.t('Display'),
      hide: i18n.t('Hide'),
      show: i18n.t('Show'),
      create: i18n.t('Create'),
      severity: i18n.t('Severity'),
      id: i18n.t('ID'),
      title: i18n.t('Title'),
      created: i18n.t('Created'),
      responsible: i18n.t('Responsible'),
      anIncidentHasCorruptDataPleaseContactSuppo: i18n.t('An incident has corrupt data. Please contact support'),
      anActivityTypeCanNotBeFound: i18n.t('An activity type can not be found'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      errorCreatingIncident: i18n.t('Error creating incident'),
      nothingToShow: i18n.t('Nothing to show'),
      theSelectedSearchCriteriaHaveNoResults: i18n.t('The selected search criteria have no results to display'),
      deleteIncident: i18n.t('Delete') + ' ' + i18n.t('Incident').toLowerCase(),
      areYouSureYouWantToDeleteThisIncident: "".concat(i18n.t('Are you sure you want to delete this'), " ").concat(i18n.t('Incident').toLowerCase(), "?")
    };
  },
  IncidentReport: function IncidentReport() {
    return {
      incidentReport: i18n.t('Incident report'),
      activityType: i18n.t('Activity type'),
      severity: i18n.t('Severity'),
      id: i18n.t('ID'),
      title: i18n.t('Title'),
      archived: i18n.t('Archived'),
      description: i18n.t('Description'),
      status: i18n.t('Status'),
      confidential: i18n.t('Confidential'),
      created: i18n.t('Created'),
      incidentOccurred: i18n.t('Incident occurred'),
      organisations: i18n.t('Organisations'),
      linkedActions: i18n.t('Linked actions'),
      linkedControls: i18n.t('Linked controls'),
      linkedRisks: i18n.t('Linked risks'),
      linkedDataControllers: i18n.t('Linked data controllers'),
      linkedProcessors: i18n.t('Linked processors'),
      linkedInformationAssets: i18n.t('Linked information assets'),
      linkedSystems: i18n.t('Linked systems'),
      linkedComplianceNodes: i18n.t('Linked compliance nodes'),
      linkedContracts: i18n.t('Linked contracts'),
      linkedCompanies: i18n.t('Linked companies'),
      linked: i18n.t('Linked'),
      attachments: i18n.t('Attachments'),
      notSet: i18n.t('Not set')
    };
  },
  IncidentTypeExpanded: function IncidentTypeExpanded() {
    return {
      activityType: i18n.t('Activity type'),
      severity: i18n.t('Severity'),
      id: i18n.t('ID'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      status: i18n.t('Status'),
      confidential: i18n.t('Confidential'),
      created: i18n.t('Created'),
      incidentOccurred: i18n.t('Incident occurred'),
      organisations: i18n.t('Organisations'),
      linkedInitiatives: i18n.t('Linked initiatives'),
      linkedControls: i18n.t('Linked controls'),
      linkedRisks: i18n.t('Linked risks'),
      linked: i18n.t('Linked'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      notSet: i18n.t('Not set'),
      nothingToShow: i18n.t('Nothing to show')
    };
  },
  MyIncidents: function MyIncidents() {
    return {
      sortBy: i18n.t('Sort by'),
      title: i18n.t('Title'),
      created: i18n.t('Created'),
      incidentOccurred: i18n.t('Incident occurred'),
      incidentArchived: i18n.t('Incident archived'),
      severity: i18n.t('Severity'),
      accountable: i18n.t('Accountable'),
      description: i18n.t('Description'),
      status: i18n.t('Status'),
      myIncidents: i18n.t('My incidents'),
      archivedIncidents: i18n.t('Archived incidents'),
      nothingToShow: i18n.t('Nothing to show')
    };
  },
  IncidentAnonymousCreationPage: function IncidentAnonymousCreationPage() {
    return {
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      incidentOccurred: i18n.t('Incident occurred'),
      severity: i18n.t('Severity'),
      status: i18n.t('Status'),
      submit: i18n.t('Submit'),
      noAccess: i18n.t('No access'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      somethingWentWrongWhileUploading: i18n.t('Something went wrong while uploading'),
      yourActivityHasBeenCreated: i18n.t('Your activity has been created'),
      thisIncidentCreationPageHasntBeenEnabled: i18n.t('This incident creation page has not been enabled by the company administrator'),
      welcomeToOurCreationPage: i18n.t('Welcome to our anonymous Incident creation page!'),
      ifYouCreateAnIncidentOnThisPage: i18n.t('If you create an Incident on this page, your identity will be hidden from your employer.'),
      regardlessOfWhetherTheIncidentIsAn: i18n.t('Regardless of whether the incident is an IT-related security breach, technical problems, violations of internal policies, or something else entirely, you can communicate about it safely and discreetly here.'),
      rememberThatMoreInformationYouProvide: i18n.t('Remember that the more information you provide, the easier it is for your organization to deal with the Incident afterwards.'),
      sinceYourReportingIsAnonymous: i18n.t('Since your reporting is anonymous, you will not be notified subsequently about further work on it.')
    };
  }
};