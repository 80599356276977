import StaticTable from '@/components/Molecules/StaticTable';
import Pagination from '@/components/Atoms/Pagination';
import * as Cookies from 'tiny-cookie';
export default {
  name: 'PaginatedTable',
  introduction: 'A static table, with pagination',
  description: "This component behaves the same way that StaticTable does, but also have pagination.\n                  Use this instead of StaticTable, when there are many data points in a staticTable",
  token: '<paginated-table :dataset="testData" :columns="testColumns2"/>\n\n' + 'testData:[\n' + '                    {\n' + '                        title: \'TestItem1\',\n' + '                        desc: \'this is a test item, you know\',\n' + '                        type: \'TestItem\'\n' + '                        button: \'\'\n' + '                    },\n' + '                    {\n' + '                        title: \'TestItem2\',\n' + '                        desc: \'this is a test item, you know\',\n' + '                        type: \'TestItem\'\n' + '                        button: \'<feather-icon icon="x" width="20px" height="20px" color="red" @click="$emit(\'trigger\',$event)"/>\'\n' + '                    },\n' + '                    {\n' + '                        title: \'TestItem3\',\n' + '                        desc: \'this is a test item, you know\',\n' + '                        type: \'TestItem\',\n' + '                        button: \'\'\n' + '                        children: [\n' + '                            {\n' + '                                title: \'SubTestItem1\',\n' + '                                desc: \'this is a subtest item, you know\',\n' + '                                type: \'SubTestItem\',\n' + '                                button: \'\'\n' + '                            },\n' + '                            {\n' + '                                title: \'SubTestItem2\',\n' + '                                desc: \'this is a subtest item, you know\',\n' + '                                type: \'SubTestItem\',\n' + '                                button: \'\'\n' + '                            }\n' + '                        ]\n' + '                    }\n' + '                ],\n' + '                testColumns2: [\n' + '                    \'Title\', \'Description\', \'Type\'\n' + '                ]',
  components: {
    StaticTable: StaticTable,
    Pagination: Pagination
  },
  props: {
    dataset: {
      type: Array,
      required: true,
      note: 'An array with objects, that represents the data which should be displayed'
    },
    columns: {
      type: Array,
      required: true,
      note: 'An array with column names as strings'
    },
    visibilitySwitch: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Add possibility to change columns visibility'
    },
    visibilityStorageName: {
      type: String,
      required: false,
      default: 'static_table_hidden_columns',
      note: 'Storage name to store hidden columns'
    },
    visibilitySwitchLabel: {
      type: String,
      required: false,
      default: 'Column visibility',
      note: 'Default switch label'
    },
    visibilityDefaultColumns: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'An array with default visible columns'
    },
    visibilityHiddenOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'An array with names of columns what will be hidden in visibility switcher'
    },
    pageSize: {
      type: Number,
      default: 20,
      note: 'Amount of items before pagination. Must be positive'
    },
    populateWithEmpty: {
      type: Boolean,
      default: true,
      note: 'Add empty columns if amount of data less that pageSize'
    },
    sessionCookieName: {
      type: String,
      default: '',
      note: 'The name of a key in session cookie'
    },
    hoverTitles: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Describes which columns will have dynamic or static hover titles according to content'
    },
    columnsMaxWidth: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Describes which columns must have max width value'
    },
    columnsMinWidth: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Describes which columns must have min width value. Useful if you have scrollable table'
    },
    total: {
      type: Number,
      required: false,
      default: 0,
      note: 'Total number of items used with backend pagination'
    },
    backendPagination: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If backend pagination is used'
    },
    showChildDefaultValue: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  emits: ['changePage'],
  data: function data() {
    return {
      datasetSlice: this.backendPagination ? this.dataset : this.prepareDatasetSlice(0, this.pageSize, false),
      page: 1
    };
  },
  computed: {
    emptyObject: function emptyObject() {
      var obj = {};
      this.dataset.length > 0 && Object.keys(this.dataset[0]).forEach(function (key) {
        if (key !== 'children') {
          obj[key] = '';
        }
      });
      return obj;
    }
  },
  watch: {
    dataset: {
      deep: true,
      handler: function handler() {
        var _this = this;
        if (this.backendPagination) {
          this.datasetSlice = this.dataset;
          return;
        }
        this.datasetSlice = this.prepareDatasetSlice(0, this.pageSize, this.populateWithEmpty);
        this.$nextTick(function () {
          _this.resetPage();
        });
      }
    }
  },
  created: function created() {
    if (this.backendPagination) return;
    this.page = +Cookies.getCookie(this.sessionCookieName) || 1;
  },
  mounted: function mounted() {
    if (this.backendPagination) return;
    this.$refs.pagination.changePage(this.page);
  },
  methods: {
    prepareDatasetSlice: function prepareDatasetSlice(start, end, populateWithEmpty) {
      var result = this.dataset.slice(start, end);
      if (populateWithEmpty) {
        if (this.pageSize !== 0 && result.length > 0 && result.length < this.pageSize) {
          for (var i = result.length; i < this.pageSize; i++) {
            result.push(this.emptyObject);
          }
        }
      }
      return result;
    },
    handleShownList: function handleShownList(indexes) {
      this.$emit('changePage', indexes);
      if (this.backendPagination) return;
      Cookies.setCookie(this.sessionCookieName, indexes.currentPage, {
        expires: '',
        samesite: 'strict'
      });
      this.datasetSlice = this.prepareDatasetSlice(indexes.start, indexes.end + 1, this.populateWithEmpty);
    },
    resetPage: function resetPage() {
      if (this.$refs && this.$refs.pagination) {
        this.$refs.pagination.resetPage();
      }
    }
  }
};