import { getMyContractsCompanies } from '@/api/contracts/contracts';
import CompanyGenericTable from '@/components/Organisms/Companies/CompanyGenericTable';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { CompaniesFuseMixin } from '@/mixins/CompaniesFuseMixin';
export default {
  name: 'MyCompanies',
  components: {
    CompanyGenericTable: CompanyGenericTable,
    InputField: InputField,
    RismaTitle: RismaTitle
  },
  mixins: [CompaniesFuseMixin],
  data: function data() {
    return {
      companies: [],
      searchField: '',
      selectedCompanyId: null,
      updatedCompany: null,
      translate: {
        search: this.$trans('Search'),
        myCompanies: this.$trans('My companies'),
        aListOfAllCompaniesWhichArePartOfYourCon: this.$trans('A list of all companies which are part of your contracts, that you are responsible for')
      }
    };
  },
  computed: {
    filteredCompanies: function filteredCompanies() {
      return this.getFilteredCompaniesList(this.companies, this.searchField);
    }
  },
  mounted: function mounted() {
    this.getCompanies();
  },
  methods: {
    getCompanies: function getCompanies() {
      var _this = this;
      return getMyContractsCompanies().then(function (response) {
        _this.companies = response.list;
      });
    },
    selectCompany: function selectCompany(id) {
      this.selectedCompanyId = id;
    },
    dismissModal: function dismissModal() {
      var _this2 = this;
      if (this.updatedCompany) {
        var companyIndex = this.companies.findIndex(function (company) {
          return company.id === _this2.updatedCompany.id;
        });
        this.companies.splice(companyIndex, 1, this.updatedCompany);
        this.updatedCompany = null;
      }
      this.selectedCompanyId = null;
    },
    updateFromPreviewMode: function updateFromPreviewMode(item) {
      this.updatedCompany = item;
    }
  }
};