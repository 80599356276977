export var getIdsFromTree = function getIdsFromTree(tree) {
  var children = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'children';
  var result = [];
  tree.forEach(function (item) {
    result.push(item.id);
    var childTreeIds = [];
    if (item[children]) {
      childTreeIds = getIdsFromTree(item[children], children);
    }
    result = result.concat(childTreeIds);
  });
  return result;
};