import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  class: "flex mb-3"
};
var _hoisted_2 = {
  class: "w-1/2 mr-8"
};
var _hoisted_3 = {
  class: "w-1/2"
};
var _hoisted_4 = {
  class: "flex mb-3"
};
var _hoisted_5 = {
  class: "w-1/2 mr-8"
};
var _hoisted_6 = ["placeholder"];
var _hoisted_7 = {
  class: "w-1/2"
};
var _hoisted_8 = {
  class: "flex mb-3"
};
var _hoisted_9 = {
  class: "w-1/2 mr-8"
};
var _hoisted_10 = ["placeholder"];
var _hoisted_11 = {
  key: 2,
  class: "flex items-center mb-1"
};
var _hoisted_12 = {
  class: "w-1/2 mr-8 flex"
};
var _hoisted_13 = {
  class: "flex items-center mb-3"
};
var _hoisted_14 = {
  class: "w-1/2 mr-8 flex"
};
var _hoisted_15 = {
  key: 3,
  class: "flex items-center mb-3"
};
var _hoisted_16 = {
  key: 4,
  class: "flex items-center mb-3"
};
var _hoisted_17 = {
  key: 5
};
var _hoisted_18 = {
  key: 0,
  class: "flex items-center mb-3"
};
var _hoisted_19 = {
  class: "flex justify-between mt-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_alert = _resolveComponent("alert");
  var _component_selector = _resolveComponent("selector");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_options_list = _resolveComponent("options-list");
  var _component_help_tool_tip = _resolveComponent("help-tool-tip");
  var _component_risma_switch = _resolveComponent("risma-switch");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_question_dependency_editor = _resolveComponent("question-dependency-editor");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  return _openBlock(), _createElementBlock("div", null, [$data.showConfirmationAlert ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    body: $data.translate.areYouSureYouWantToCancelTheEnteredInfor,
    "alert-type": "confirmation",
    onAccept: _cache[0] || (_cache[0] = function ($event) {
      return $options.onCancel(true);
    }),
    onDismiss: $options.onCancel
  }, null, 8 /* PROPS */, ["body", "onDismiss"])) : _createCommentVNode("v-if", true), $data.errors.length ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList($data.errors, function (error, i) {
    return _openBlock(), _createElementBlock("div", {
      key: i,
      class: "mb-2 p-2 bg-red-210"
    }, _toDisplayString(error), 1 /* TEXT */);
  }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_selector, {
    items: $options.headlines,
    title: $data.translate.headline,
    "search-placeholder": $data.translate.typeHere,
    "search-fields": ['headline'],
    "search-value-prop": $data.data.headline,
    "change-focus-on-hover": false,
    "reset-value-feature": false,
    onSelected: _cache[1] || (_cache[1] = function ($event) {
      return $data.data.headline = $event.headline;
    }),
    "onUpdate:searchValueProp": _cache[2] || (_cache[2] = function ($event) {
      return $data.data.headline = $event;
    })
  }, {
    displayedName: _withCtx(function (slotProps) {
      return [_createElementVNode("span", null, _toDisplayString(slotProps.item.headline), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["items", "title", "search-placeholder", "search-value-prop"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_input_field, {
    modelValue: $data.data.questionnumber,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.data.questionnumber = $event;
    }),
    title: $data.translate.questionNumber,
    required: true,
    placeholder: $data.translate.typeHere,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.questionText,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _withDirectives(_createElementVNode("textarea", {
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return $data.data.question = $event;
    }),
    placeholder: $data.translate.typeHere,
    required: "",
    class: _normalizeClass(["w-full px-3 py-2", $data.textAreaStyleClasses])
  }, null, 10 /* CLASS, PROPS */, _hoisted_6), [[_vModelText, $data.data.question]])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_single_select, {
    modelValue: $data.data.questiontype,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $data.data.questiontype = $event;
    }),
    title: $data.translate.questionType,
    options: $options.questionTypes
  }, null, 8 /* PROPS */, ["modelValue", "title", "options"]), $data.data.questiontype === 13 ? (_openBlock(), _createBlock(_component_single_select, {
    key: 0,
    modelValue: $data.data.informationAssetActivityType,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $data.data.informationAssetActivityType = $event;
    }),
    options: $options.assetsOptions,
    placeholder: "".concat($data.translate.chooseCustom, " ").concat(_ctx.informationAssetsTitle())
  }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])) : _createCommentVNode("v-if", true)])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $data.translate.helpText,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _withDirectives(_createElementVNode("textarea", {
    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
      return $data.data.help = $event;
    }),
    placeholder: $data.translate.typeHere,
    required: "",
    class: _normalizeClass(["w-full px-3 py-2", $data.textAreaStyleClasses])
  }, null, 10 /* CLASS, PROPS */, _hoisted_10), [[_vModelText, $data.data.help]])]), $data.data.questiontype <= 2 ? (_openBlock(), _createBlock(_component_options_list, {
    key: 0,
    options: $data.data.optionDetails,
    placeholder: $data.translate.typeHere,
    draggable: true,
    class: "w-1/2",
    onError: $options.handleAddToOptionsError
  }, null, 8 /* PROPS */, ["options", "placeholder", "onError"])) : _createCommentVNode("v-if", true)]), _createVNode(_component_risma_title, {
    title: $data.translate.questionSettings,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), $data.data.questiontype <= 2 ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createVNode(_component_risma_title, {
    title: $data.translate.allowCreationOfNewAnswers,
    type: "small",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_help_tool_tip, null, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.ifYouAllowCreationOfNewAnswers), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })]), _createVNode(_component_risma_switch, {
    "model-value": $options.allowNewAnswerOption,
    options: $options.answerOptions,
    "is-labels-show": true,
    class: "w-1/2",
    "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
      return $data.data.allowNewAnswerOption = $event;
    })
  }, null, 8 /* PROPS */, ["model-value", "options"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createVNode(_component_risma_title, {
    title: $options.isGDPR ? $data.translate.article30Report : $data.translate.questionnaireReport,
    type: "small",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_help_tool_tip, null, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.isGDPR ? $data.translate.byActivatingThisFunctionalityArticle30 : $data.translate.byActivatingThisFunctionalityQuestionnaireReport), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })]), _createVNode(_component_risma_switch, {
    "model-value": $options.isArticle30,
    options: $options.defaultSelectedOptions,
    "is-labels-show": true,
    class: "w-1/2",
    "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
      return $data.data.isArticle30 = $event;
    })
  }, null, 8 /* PROPS */, ["model-value", "options"])]), $options.isDataflowAvailable && !_ctx.isInformationAssetsDisabled ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createVNode(_component_risma_title, {
    title: $data.translate.allowAnswerOptionsToBeIncludedInDataFlow,
    type: "medium",
    class: "flex-shrink-0 mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_help_tool_tip, null, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.theAnswersProvidedFromTheCurrentLinkedToDataFlows), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }), _createVNode(_component_risma_select, {
    options: $options.dataflowTypesFiltered,
    "selected-options": $options.selectedDataFlowTypes,
    placeholder: $data.translate.select,
    "track-by": "key",
    "label-key": "title",
    class: "flex-grow ml-8",
    onSelected: $options.handleDataFlowChanged
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "onSelected"])])) : _createCommentVNode("v-if", true), $options.isOutsourcing ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(_component_risma_title, {
    title: $data.translate.allowAnswerOptionsToBeIncludedInOutsourcing,
    type: "medium",
    class: "flex-shrink-0 mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_help_tool_tip, null, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.theAnswersProvidedFromTheCurrentLinkedToOutcoursingReport), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }), _createVNode(_component_risma_select, {
    options: $data.outsourcingTypes,
    "selected-options": $options.selectedOutsourcingTypes,
    placeholder: $data.translate.select,
    "track-by": "key",
    "label-key": "title",
    class: "flex-grow ml-8",
    onSelected: $options.handleOutsourcingChanged
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "onSelected"])])) : _createCommentVNode("v-if", true), _createCommentVNode(" legacy, temporary hidden  "), false ? (_openBlock(), _createElementBlock("div", _hoisted_17, [$options.isDataProcessor || $options.isDPM ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createVNode(_component_risma_title, {
    title: $data.translate.allowAnswerOptionsToBeIncludedInDataProces,
    type: "medium",
    class: "flex-shrink-0 mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_help_tool_tip, null, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.theAnswersProvidedFromTheCurrentAssistRiskAssessment), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }), _createVNode(_component_risma_select, {
    options: $data.DPMTypes,
    "selected-options": $options.selectedDPMTypes,
    placeholder: $data.translate.select,
    "track-by": "key",
    "label-key": "title",
    class: "flex-grow ml-8",
    onSelected: $options.handleDPMFlagsChanged
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "onSelected"])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $options.dependencyEditorIsEnabled ? (_openBlock(), _createBlock(_component_question_dependency_editor, {
    key: 6,
    "model-value": $data.data,
    questions: $props.questions,
    onChanged: $options.onQuestionChange
  }, null, 8 /* PROPS */, ["model-value", "questions", "onChanged"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_19, [_createVNode(_component_risma_button, {
    text: $data.translate.cancel,
    type: "util",
    onClick: $options.cancel
  }, null, 8 /* PROPS */, ["text", "onClick"]), $data.saving ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 0
  })) : _createCommentVNode("v-if", true), !$data.saving ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 1,
    text: $data.data.id ? $data.translate.save : $data.translate.create,
    type: "save",
    func: "submit",
    onClick: $options.handleOnSubmit
  }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true)])]);
}