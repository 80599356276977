import Http from '@/api';
import { handleRequestResponse } from '@/api/httpUtils';
function getUrl(gapSchemaId, questionnaireId) {
  return "/compliance/relevant_questions?gapSchemaId=".concat(gapSchemaId, "&questionnaireId=").concat(questionnaireId);
}
export function getRelevantQuestions(gapSchemaId, questionnaireId) {
  var url = getUrl(gapSchemaId, questionnaireId);
  return handleRequestResponse(Http.get(url));
}
export function saveRelevantQuestions(gapSchemaId, questionnaireId, relevantQuestions) {
  var url = getUrl(gapSchemaId, questionnaireId);
  return handleRequestResponse(Http.patch(url, {
    gapSchemaId: gapSchemaId,
    questionnaireId: questionnaireId,
    relevantQuestions: relevantQuestions
  }));
}