import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "max-w-sm"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_attachment = _resolveComponent("attachment");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_attachment, {
    "files-list": $data.filesList,
    users: $data.userList,
    "files-and-links-separated": false,
    "file-types-enabled": true,
    "edit-file-enabled": true,
    module: "control",
    "module-id": "27"
  }, null, 8 /* PROPS */, ["files-list", "users"])]);
}