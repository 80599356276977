import RismaTags from '@/components/Molecules/Tags/RismaTags';
export default {
  name: '',
  components: {
    RismaTags: RismaTags
  },
  data: function data() {
    return {
      rismaTags: [{
        id: 1,
        name: 'First tag',
        url: ''
      }, {
        id: 2,
        name: 'Second tag',
        url: ''
      }]
    };
  }
};