import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "attachment-choice"
};
var _hoisted_2 = {
  class: "flex justify-between 2lg:flex-row flex-col"
};
var _hoisted_3 = {
  key: 0,
  class: "pr-8 2lg:w-6/12 relative"
};
var _hoisted_4 = {
  class: "my-4 2lg:text-left text-center"
};
var _hoisted_5 = {
  class: "url"
};
var _hoisted_6 = {
  class: "2lg:w-6/12"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_input_field = _resolveComponent("input-field");
  var _component_attachment_item = _resolveComponent("attachment-item");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.error ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.error), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [!$props.readOnly ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", null, [_createVNode(_component_risma_button, {
    class: "2lg:m-0 mx-auto",
    text: $data.t.attach,
    type: "util",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.$refs.inputfile.click();
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "paperclip",
        class: "stroke-blue inline-block stroke-2 mr-2"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text"]), _createElementVNode("input", {
    ref: "inputfile",
    multiple: true,
    type: "file",
    class: "w-0 h-0 opacity-0",
    onInput: _cache[1] || (_cache[1] = function () {
      return $options.onInputFile && $options.onInputFile.apply($options, arguments);
    })
  }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */)]), _createElementVNode("p", _hoisted_4, " --- " + _toDisplayString($data.t.or) + " --- ", 1 /* TEXT */), _createElementVNode("div", _hoisted_5, [_createVNode(_component_input_field, {
    modelValue: $data.newURL.filename,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.newURL.filename = $event;
    }),
    title: $data.t.link,
    placeholder: $data.t.name,
    type: "text",
    class: "w-full mb-1"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createVNode(_component_input_field, {
    modelValue: $data.newURL.url,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.newURL.url = $event;
    }),
    placeholder: "http://",
    type: "url",
    class: "w-full mb-1"
  }, null, 8 /* PROPS */, ["modelValue"]), _createVNode(_component_risma_button, {
    text: $data.t.add,
    type: "util",
    onClick: _cache[4] || (_cache[4] = function ($event) {
      return $options.addAttachment($event, 'url');
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "plus",
        class: "stroke-blue inline-block stroke-2 mr-2"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text"])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.loadedFiles, function (file, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index
    }, [_createVNode(_component_attachment_item, {
      file: file,
      "read-only": $props.readOnly,
      "hide-edit-icon": true,
      onOnDelete: $options.deleteAttachment
    }, null, 8 /* PROPS */, ["file", "read-only", "onOnDelete"])]);
  }), 128 /* KEYED_FRAGMENT */)), $data.fileLoad ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 0
  })) : _createCommentVNode("v-if", true)])])]);
}