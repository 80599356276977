function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { defineStore } from 'pinia';
import { getAll as getActivityTypes } from '../api/risma/activityTypes';
import { MODULES } from '@/constants/modules';
import cloneDeep from 'lodash/cloneDeep';
export var useActivityTypesStore = defineStore('activityTypesStore', {
  state: function state() {
    return {
      activityTypes: []
    };
  },
  getters: {
    getActivityTypesByModule: function getActivityTypesByModule(state) {
      return function (module) {
        return state.activityTypes.filter(function (activityType) {
          return activityType.module === module;
        });
      };
    },
    getActivityTypeById: function getActivityTypeById(state) {
      return function (id) {
        return state.activityTypes.find(function (activityType) {
          return activityType.id === id;
        });
      };
    },
    controlActivityTypes: function controlActivityTypes() {
      return this.getActivityTypesByModule(MODULES.CONTROLS);
    },
    controlActivityTypesWithFilteredFields: function controlActivityTypesWithFilteredFields() {
      var _this = this;
      return function (filterFn) {
        return _this.getActivityTypesByModule(MODULES.CONTROLS).map(function (at) {
          var _at$fields;
          var fields = (_at$fields = at.fields) === null || _at$fields === void 0 ? void 0 : _at$fields.filter(filterFn);
          return _objectSpread(_objectSpread({}, at), {}, {
            fields: fields
          });
        });
      };
    },
    complianceActivityTypes: function complianceActivityTypes() {
      return this.getActivityTypesByModule(MODULES.COMPLIANCE);
    },
    complianceIAControllerActivityTypes: function complianceIAControllerActivityTypes() {
      return this.getActivityTypesByModule(MODULES.COMPLIANCE_IA_CONTROLLER);
    },
    complianceIAProcessorActivityTypes: function complianceIAProcessorActivityTypes() {
      return this.getActivityTypesByModule(MODULES.COMPLIANCE_IA_PROCESSOR);
    },
    complianceIASystemActivityTypes: function complianceIASystemActivityTypes() {
      return this.getActivityTypesByModule(MODULES.COMPLIANCE_IA_SYSTEM);
    },
    complianceIACustomActivityTypes: function complianceIACustomActivityTypes() {
      return this.getActivityTypesByModule(MODULES.COMPLIANCE_INFORMATION_ASSETS);
    },
    riskActivityTypes: function riskActivityTypes() {
      return this.getActivityTypesByModule(MODULES.RISK);
    },
    executionActivityTypes: function executionActivityTypes() {
      return this.getActivityTypesByModule(MODULES.EXECUTION);
    },
    incidentActivityTypes: function incidentActivityTypes() {
      return this.getActivityTypesByModule(MODULES.INCIDENTS);
    },
    contractActivityTypes: function contractActivityTypes() {
      return this.getActivityTypesByModule(MODULES.CONTRACTS);
    },
    processNodeActivityTypes: function processNodeActivityTypes() {
      return this.getActivityTypesByModule(MODULES.COMPLIANCE_PROCESS_TREE_NODE);
    },
    multipleActivityTypes: function multipleActivityTypes() {
      var _this2 = this;
      return function () {
        for (var _len = arguments.length, modules = new Array(_len), _key = 0; _key < _len; _key++) {
          modules[_key] = arguments[_key];
        }
        return modules.reduce(function (acc, module) {
          return [].concat(_toConsumableArray(acc), [_this2.getActivityTypesByModule(module)]);
        }, []);
      };
    }
  },
  actions: {
    load: function load() {
      var _this3 = this;
      var params = '?data[]=optionalfields&data[]=fields';
      return getActivityTypes(params).then(function (_ref) {
        var list = _ref.list;
        _this3.activityTypes = list;
      });
    },
    set: function set(activityTypes) {
      this.activityTypes = cloneDeep(activityTypes);
    },
    add: function add(activityType) {
      this.activityTypes.push(activityType);
    },
    update: function update(activityType) {
      this.activityTypes = this.activityTypes.map(function (a) {
        return a.id == activityType.id ? activityType : a;
      });
    },
    delete: function _delete(activityType) {
      this.activityTypes = this.activityTypes.filter(function (a) {
        return a.id != activityType.id;
      });
    }
  }
});