import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-e1e91940"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "flex justify-between"
};
var _hoisted_2 = {
  class: "mb-3"
};
var _hoisted_3 = {
  key: 0,
  class: "pl-3 text-sm text-red-50"
};
var _hoisted_4 = {
  class: "mb-3"
};
var _hoisted_5 = {
  key: 0,
  class: "pl-3 text-sm text-red-50"
};
var _hoisted_6 = {
  class: "flex justify-between mb-3"
};
var _hoisted_7 = {
  class: "mb-3"
};
var _hoisted_8 = {
  key: 0,
  class: "mb-3"
};
var _hoisted_9 = {
  key: 0,
  class: "pl-3 text-sm text-red-50"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $props.contactData.id ? $data.translate.editCompanyContact : $data.translate.addCompanyContact,
    "show-buttons": false,
    "dismiss-button-disabled": true,
    "dismiss-on-click-outside": true,
    "overflow-visible": true,
    "modal-fixed-width": "550px",
    onDismiss: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_input_field, {
        modelValue: $props.contactData.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $props.contactData.name = $event;
        }),
        title: $data.translate.name,
        placeholder: $data.translate.name,
        invalid: !$data.isValidContactName,
        type: "text",
        class: "w-215px"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "invalid"]), !$data.isValidContactName ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($data.translate.nameCannotBeEmpty), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_input_field, {
        modelValue: $props.contactData.email,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $props.contactData.email = $event;
        }),
        title: $data.translate.email,
        placeholder: $data.translate.email,
        invalid: !$data.isValidContactEmail,
        type: "text",
        class: "w-215px"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "invalid"]), !$data.isValidContactEmail ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($data.translate.invalidEmailAddress), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", null, [_createVNode(_component_input_field, {
        modelValue: $props.contactData.phone,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $props.contactData.phone = $event;
        }),
        title: $data.translate.phone,
        placeholder: $data.translate.phone,
        type: "text",
        class: "w-215px"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_input_field, {
        modelValue: $props.contactData.title,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $props.contactData.title = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.title,
        type: "text",
        class: "w-215px"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])])]), $props.companiesOptions.length ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_single_select, {
        modelValue: $data.selectedCompanyId,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $data.selectedCompanyId = $event;
        }),
        title: $data.translate.companies,
        options: $props.companiesOptions,
        class: _normalizeClass({
          required: !$data.isValidCompany
        }),
        placeholder: $data.translate.selectCompany
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "class", "placeholder"]), !$data.isValidCompany ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString($data.translate.pleaseSelectCompany), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_button, {
        text: $data.translate.save,
        type: "save",
        class: "w-215px ml-auto save-button",
        onClick: $options.handleSaveContact
      }, null, 8 /* PROPS */, ["text", "onClick"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header"]);
}