import emitter from 'tiny-emitter/instance'; //event bus

import { clickOutside } from '@/utils/directives/clickOutside';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { getModuleFromObjType } from '@/constants/ObjectTypes';
export default {
  name: 'TreeItemMenu',
  introduction: 'Tree dot menu for items in Tree.vue tree. ',
  token: '<tree-item-menu :node-id="1" :menu-items-settings="{}"/>',
  components: {
    FeatherIcon: FeatherIcon
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    nodeId: {
      type: Number,
      required: true,
      note: 'Id of the tree node'
    },
    menuItemsSettings: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Settings for creating menu'
    },
    transFunction: {
      type: Function,
      required: false,
      default: function _default() {},
      note: 'translate function for vue tree, callstacktrace exceed (due a lot of bindings)'
    },
    linksStructureByModule: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['actionClicked'],
  data: function data() {
    return {
      showMenu: false,
      showAddFromTemplateSubmenu: false
    };
  },
  computed: {
    rightTransFunction: function rightTransFunction() {
      return this.$trans || this.transFunction;
    }
  },
  methods: {
    menuItemClicked: function menuItemClicked(action, data) {
      this.closeMenu();
      this.$emit('actionClicked', this.nodeId, action, data);
      emitter.emit('actionClicked', this.nodeId, action, data);
    },
    openMenu: function openMenu() {
      this.showMenu = true;
    },
    closeMenu: function closeMenu() {
      this.showMenu = false;
      this.showAddFromTemplateSubmenu = false;
    },
    toggleAddFromTemplateSubmenu: function toggleAddFromTemplateSubmenu() {
      this.showAddFromTemplateSubmenu = !this.showAddFromTemplateSubmenu;
    },
    getNextLevelLinks: function getNextLevelLinks(linkType) {
      var _this$linksStructureB;
      if (!this.linksStructureByModule) return [];
      var nextLevelLinks = (_this$linksStructureB = this.linksStructureByModule[getModuleFromObjType(linkType)]) === null || _this$linksStructureB === void 0 ? void 0 : _this$linksStructureB.nextLevelLinks;
      return nextLevelLinks || [];
    }
  }
};