import { prepareDateTimezone, formatTimeToDateFormat } from '@/utils/date';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { DateFormats } from '@/constants/DateFormats';
export var TileCardMixin = {
  computed: {
    activityIdTitle: function activityIdTitle() {
      if (this.activity.objType === ObjectTypes.EXECUTION_INITIATIVE_LIMITED) {
        return this.activity.ipath;
      }
      if (this.activity.objType === ObjectTypes.RISK_RISK) {
        return this.activity.rno;
      }
      if (this.activity.objType === ObjectTypes.INFORMATION_ASSET || this.activity.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR || this.activity.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM || this.activity.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER || this.activity.objType === ObjectTypes.COMPLIANCE_NODE || this.activity.objType === ObjectTypes.COMPLIANCE_NODE_LIMITED || this.activity.objType === ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE || this.activity.reportUrl) {
        return '';
      }
      return this.activity.path;
    },
    showDeadline: function showDeadline() {
      return this.activity.objType === ObjectTypes.EXECUTION_INITIATIVE_LIMITED || this.activity.objType === ObjectTypes.CONTROLS_CONTROL || this.activity.objType === ObjectTypes.EXECUTION_INITIATIVE;
    },
    showExpirationDate: function showExpirationDate() {
      return this.activity.objType === ObjectTypes.CONTRACT || this.activity.objType === ObjectTypes.CONTRACT_LIMITED;
    },
    expirationFormat: function expirationFormat() {
      var endDateTime = this.$trans('Not set');
      if (this.activity.endDate) {
        endDateTime = formatTimeToDateFormat(this.activity.endDate);
      }
      return endDateTime;
    },
    deadlineFormat: function deadlineFormat() {
      var timezone = this.activity.deadlineTimezone;
      if (timezone) {
        return prepareDateTimezone(this.activity.deadline, timezone, DateFormats.DATE_TIME_FORMAT);
      }
      return this.activity.deadline;
    },
    trafficLight: function trafficLight() {
      var _ref, _this$activity$traffi;
      var trafficLight = (_ref = (_this$activity$traffi = this.activity.traffic_light) !== null && _this$activity$traffi !== void 0 ? _this$activity$traffi : this.activity.trafficLight) !== null && _ref !== void 0 ? _ref : null;
      return this.activity.isMothercontrol ? null : trafficLight;
    },
    trafficLightColor: function trafficLightColor() {
      if (this.activity.objType === ObjectTypes.CONTROLS_CONTROL) {
        var trafficLightNumber = this.activity.isMothercontrol ? 0 : this.activity.trafficLight;
        return this.Colors.trafficlightColor(trafficLightNumber, true).color;
      }
      if (this.activity.objType === ObjectTypes.COMPLIANCE_NODE || this.activity.objType === ObjectTypes.COMPLIANCE_NODE_LIMITED) {
        return this.Colors.getTrafficlightByNumber(this.activity.traffic_light).color;
      }
      if (this.activity.objType === ObjectTypes.INCIDENTS_INCIDENT) {
        return this.Colors.findIncidentSeverity(this.activity.severity).color;
      }
      if (this.activity.objType === ObjectTypes.INFORMATION_ASSET || this.activity.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR || this.activity.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM || this.activity.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER || this.activity.objType === ObjectTypes.EXECUTION_INITIATIVE || this.activity.objType === ObjectTypes.EXECUTION_INITIATIVE_LIMITED || this.activity.objType === ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE) {
        return this.Colors.getTrafficlightByNumber(this.activity.trafficLight).color;
      }
      return '';
    }
  }
};