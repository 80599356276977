function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Alert from '@/components/Molecules/Modal/Alert';
import { sortByIndex } from '@/utils/sort';
import { Colors } from '@/Colors';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
export default {
  name: 'QuestionDependencyEditor',
  introduction: '',
  description: '',
  token: '<question-dependency-editor />',
  components: {
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    Alert: Alert
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
      note: 'Main data model'
    },
    questions: {
      type: [Object, Array],
      required: true
    }
  },
  emits: ['changed'],
  data: function data() {
    return {
      idToDelete: null,
      showConfirmationAlert: false,
      colors: Colors,
      dependencies: [],
      translate: getTranslate['QuestionDependencyEditor']()
    };
  },
  computed: {
    questionsSelect: function questionsSelect() {
      var _this = this;
      var result = [];
      var allowTypes = [QuestionTypes().singleChoice.id, QuestionTypes().multipleChoice.id];
      this.filteredQuestions.forEach(function (item) {
        if (allowTypes.includes(item.questionType)) {
          if (item.options && Object.keys(item.options).length && item.id !== _this.modelValue.id) {
            result.push({
              label: item.questionnumber,
              value: item.questionnumber
            });
          }
        }
      });
      return result;
    },
    filteredQuestions: function filteredQuestions() {
      var ids = this.getDependentQuestionIds(this.questions, this.modelValue.questionnumber);
      return this.questions.filter(function (item) {
        return !ids.includes(item.questionnumber);
      });
    },
    selectedDependencyKeys: function selectedDependencyKeys() {
      return this.dependencies.map(function (item) {
        return item.key;
      });
    },
    hasOptions: function hasOptions() {
      var _this2 = this;
      return this.questionsSelect.filter(function (question) {
        return !_this2.selectedDependencyKeys.includes(question.value);
      }).length > 0;
    }
  },
  beforeMount: function beforeMount() {
    this.dependencies = this.getDependenciesAsArray(this.modelValue.dependencies);
    sortByIndex(this.dependencies, 'key');
  },
  methods: {
    getDependenciesAsArray: function getDependenciesAsArray(data) {
      var result = [];
      if (data) {
        for (var key in data) {
          result.push({
            key: key,
            values: data[key],
            isDisabled: true
          });
        }
      }
      return result;
    },
    prepareOptions: function prepareOptions(key) {
      var result = [];
      var question = this.questions.find(function (item) {
        return item.questionnumber === key;
      });
      if (question && Object.keys(question.options).length) {
        for (var _key in question.options) {
          result.push({
            key: _key,
            title: question.options[_key]
          });
        }
      }
      return result;
    },
    prepareSelectedOptions: function prepareSelectedOptions(key, data) {
      var allOptions = this.prepareOptions(key);
      return allOptions.filter(function (item) {
        return data.includes(item.title);
      });
    },
    onQuestionChanged: function onQuestionChanged(item, val) {
      var existItem = this.dependencies.find(function (dependency) {
        return dependency.key === val;
      });
      if (!existItem) {
        item.key = val;
        item.values = [];
      }
    },
    onSelectAnswers: function onSelectAnswers(data, key) {
      this.$emit('changed', {
        data: data,
        key: key
      });
      var current = this.dependencies.find(function (item) {
        return item.key === key;
      });
      current.values = data.map(function (item) {
        return item.title;
      });
      current.isDisabled = current.values.length > 0;
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var _this3 = this;
      this.showConfirmationAlert = false;
      this.$emit('changed', {
        key: this.idToDelete,
        data: []
      });
      this.dependencies = this.dependencies.filter(function (item) {
        return item.key !== _this3.idToDelete;
      });
    },
    deleteDependency: function deleteDependency(data) {
      this.idToDelete = data.key;
      if (data.isDisabled) {
        this.showConfirmationAlert = true;
        return;
      }
      this.onDeleteConfirm();
    },
    addOption: function addOption() {
      var _this4 = this;
      var firstAvailable = {
        key: null,
        values: []
      };
      var _loop = function _loop(i) {
        if (!_this4.dependencies.find(function (item) {
          return item.key === _this4.questionsSelect[i].label;
        })) {
          firstAvailable = {
            key: _this4.questionsSelect[i].label,
            values: []
          };
          return 1; // break
        }
      };
      for (var i = 0; i < this.questionsSelect.length; i++) {
        if (_loop(i)) break;
      }
      this.dependencies.push(_objectSpread(_objectSpread({}, firstAvailable), {}, {
        isDisabled: false
      }));
    },
    getDependentQuestionIds: function getDependentQuestionIds(questions, questionnumber) {
      var _this5 = this;
      var dependencies = questions.filter(function (item) {
        if (!item.dependencies) {
          return false;
        }
        return Object.prototype.hasOwnProperty.call(item.dependencies, questionnumber);
      });
      var questionnumbers = dependencies.map(function (item) {
        return item.questionnumber;
      });
      questionnumbers.forEach(function (questionNumb) {
        if (questionNumb !== _this5.modelValue.questionnumber) {
          questionnumbers.push.apply(questionnumbers, _toConsumableArray(_this5.getDependentQuestionIds(questions, questionNumb)));
        }
      });
      return questionnumbers;
    }
  }
};