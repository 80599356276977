import { Http } from '../index';
import { OPTIONAL_FIELDS } from '@/constants/AdminActivityTypesOptionalFields';
import * as HttpUtils from '../httpUtils';
var CUSTOM_FIELD_URL = '/customfields';
export function getCustomFields(params) {
  var url = CUSTOM_FIELD_URL;
  if (params) {
    url += params;
  }
  return HttpUtils.handleRequestResponse(Http.get(url));
}

// In case we move it to backend. At the moment returns hardcoded fields.
export function getOptionalFields(getSettingValue) {
  return new Promise(function (resolve) {
    resolve(OPTIONAL_FIELDS(getSettingValue));
  });
}
export function getCustomField(id) {
  var url = "".concat(CUSTOM_FIELD_URL, "/").concat(id);
  return HttpUtils.handleRequestResponse(Http.get(url));
}
export function editCustomField(id, data) {
  var url = "".concat(CUSTOM_FIELD_URL, "/").concat(id);
  return HttpUtils.handleRequestResponse(Http.patch(url, data));
}
export function createCustomField(data) {
  var url = CUSTOM_FIELD_URL;
  return HttpUtils.handleRequestResponse(Http.post(url, data));
}
export function deleteCustomField(id) {
  var url = "".concat(CUSTOM_FIELD_URL, "/").concat(id);
  return HttpUtils.handleRequestResponse(Http.delete(url));
}