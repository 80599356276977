import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import ModalWizardExampleStep1 from '@/components/Atoms/WizardFlow/ModalWizardExampleStep1';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
import DataFlowWizardStep1 from '@/components/Organisms/Compliance/DataFlow/DataFlowWizardStep1';
import DataFlowWizardStepDataSubject from '@/components/Organisms/Compliance/DataFlow/DataFlowWizardStepDataSubject';
export default {
  name: 'WizardFlow',
  introduction: 'WizardFlow component',
  description: 'wizard-flow with next/previous buttons',
  token: '<wizard-flow :items="items />',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    Modal: Modal,
    RismaButton: RismaButton,
    Notification: Notification,
    ModalWizardExampleStep1: ModalWizardExampleStep1,
    DataFlowWizardStep1: DataFlowWizardStep1,
    DataFlowWizardStepDataSubject: DataFlowWizardStepDataSubject
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      },
      note: 'items that should be shown'
    },
    stakeholderOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'stakeholder options that are usually used for Choose data subject step'
    },
    currentDataSubject: {
      type: [String, Number],
      required: false,
      default: null,
      note: 'current data subject that is needed for step 2'
    }
  },
  emits: ['accept', 'dismiss', 'update:dataSubject'],
  data: function data() {
    return {
      activeIndex: 0,
      data: {
        email: '',
        password: ''
      },
      errorMessage: '',
      translate: {
        send: this.$trans('Send'),
        previous: this.$trans('Previous'),
        next: this.$trans('Next')
      }
    };
  },
  computed: {
    currentItem: function currentItem() {
      return this.items[this.activeIndex];
    }
  },
  methods: {
    handleNextButton: function handleNextButton() {
      this.errorMessage = '';
      if (this.activeIndex === this.items.length - 1) {
        if (confirm('Are you sure you want to send WizardFlow?')) this.$emit('accept');
        return;
      }
      if (this.currentItem.isLockedNextStep) {
        this.errorMessage = this.currentItem.lockMessage;
        return;
      }
      this.activeIndex++;
    }
  }
};