import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8ec5c5b2"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "flex items-center w-full"
};
var _hoisted_2 = {
  class: "mb"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_help_tool_tip = _resolveComponent("help-tool-tip");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_text_box = _resolveComponent("text-box");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.sendQuestionnaire,
    "button-ok-text": $data.translate.send,
    "button-dismiss-text": $data.translate.cancel,
    "accept-on-enter": false,
    onAccept: $options.sendInformation,
    onDismiss: $options.toggleModal
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.whenYouSendThisRequestToTheSelectedUser,
        truncate: false,
        class: "italic",
        type: "small"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
        class: _normalizeClass([{
          'notification-up': $data.questionnaireError
        }, "mb"])
      }, [_createVNode(_component_single_select, {
        title: $data.translate.questionnaire,
        options: $props.auditQuestionnaires,
        placeholder: $data.translate.selectQuestionnaire,
        "model-value": $data.selectedOptionProcessor,
        "onUpdate:modelValue": $options.onSelectDataProcessor
      }, null, 8 /* PROPS */, ["title", "options", "placeholder", "model-value", "onUpdate:modelValue"]), $data.questionnaireError ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.descriptionOfTheError), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
        class: _normalizeClass([{
          'notification-up': $data.nameError
        }, "mb"])
      }, [_createVNode(_component_input_field, {
        modelValue: $data.nameWritten,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.nameWritten = $event;
        }),
        title: $data.translate.recipientName,
        placeholder: $data.translate.enterTheNameOftheRecipient,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), $data.nameError ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.descriptionOfTheError), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
        class: _normalizeClass([{
          'notification-up': $data.nameError
        }, "mb"])
      }, [_createVNode(_component_input_field, {
        modelValue: $data.companyWritten,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.companyWritten = $event;
        }),
        title: $data.translate.recipientCompanyName,
        placeholder: $data.translate.enterTheNameOfTheCompany,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), $data.companyError ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.descriptionOfTheError), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
        class: _normalizeClass([{
          'notification-up': $data.emailError
        }, "mb"])
      }, [_createVNode(_component_input_field, {
        modelValue: $data.emailWritten,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.emailWritten = $event;
        }),
        title: $data.translate.recipientEmail,
        placeholder: $data.translate.enterTheEmailAddressOfTheRecipient,
        type: "email"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), $data.emailError ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.emailError), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
        class: _normalizeClass([{
          'notification-up': $data.deadlineError
        }, "mb"])
      }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        title: $data.translate.deadline,
        class: "mr-2",
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_help_tool_tip, {
        class: "flex-grow"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.deadlineHint), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })]), _createVNode(_component_date_time_picker, {
        modelValue: $data.deadlineValue,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.deadlineValue = $event;
        }),
        "plugin-options": $data.datePickerOptions,
        "use-locale-format": true,
        "disabled-date": $options.disabledDate
      }, null, 8 /* PROPS */, ["modelValue", "plugin-options", "disabled-date"]), $data.deadlineError ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.descriptionOfTheError), 1 /* TEXT */)];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
        title: $data.translate.comments,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_text_box, {
        placeholder: $data.translate.anyCommentsWillBeIncludedInTheEmail,
        text: $data.commentsWritten,
        rows: 5,
        class: "w-full",
        onUpdated: _cache[4] || (_cache[4] = function ($event) {
          return $data.commentsWritten = $event;
        })
      }, null, 8 /* PROPS */, ["placeholder", "text"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"]);
}