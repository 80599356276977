import FileSaver from 'file-saver';

/**
 * Saves the file
 * @param filename
 * @param blob
 */
export function handleSave(filename, blob) {
  try {
    FileSaver.saveAs(blob, filename);
  } catch (e) {
    if (typeof console !== 'undefined') {
      // eslint-disable-next-line no-console
      console.log(e, filename, blob);
    }
  }
}