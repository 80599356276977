import PaginatedTable from '@/components/Organisms/PaginatedTable';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
export default {
  name: '',
  components: {
    PaginatedTable: PaginatedTable,
    SingleSelect: SingleSelect
  },
  emits: ['table'],
  data: function data() {
    return {
      paginatedTableOptions: [{
        label: 'No data',
        value: 0
      }, {
        label: '3 pages',
        value: 1
      }, {
        label: '4 pages',
        value: 2
      }, {
        label: 'Many pages',
        value: 3
      }],
      paginationSelected: 1,
      paginatedTestData: [{
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem2',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem3',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem4',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem5',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem6',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem7',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem8',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem9',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem10',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem11',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem12',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem13',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem14',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem15',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem16',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem17',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem18',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem19',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem20',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem21',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem22',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem23',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem24',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem25',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem26',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }, {
        title: 'TestItem1',
        desc: 'this is a test item, you know',
        type: 'TestItem',
        button: ''
      }],
      paginatedColumns: ['Title', 'Description', 'Type']
    };
  },
  computed: {
    paginationData: function paginationData() {
      if (this.paginationSelected === 0) {
        return [];
      } else if (this.paginationSelected === 1) {
        return this.paginatedTestData.slice(0, 50);
      } else if (this.paginationSelected === 2) {
        return this.paginatedTestData.slice(0, 70);
      } else if (this.paginationSelected === 3) {
        return this.paginatedTestData;
      }
      return [];
    }
  }
};