function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { getTranslate } from './translate';
import { createRisk } from '@/api/risk/risk';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
import SolutionsSelector from '@/components/Molecules/SolutionsSelector';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import { userHasProjectAccess } from '@/utils/access';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { UserLevels } from '@/constants/UserLevels';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { checkERMByProjectId } from '@/utils/risks/check';
export default {
  name: 'RiskCreationModal',
  introduction: 'RiskCreationModal multiselect popup',
  description: 'RiskCreationModal with selector and list of selected items',
  token: '<risk-creation-modal />',
  components: {
    RismaSelect: RismaSelect,
    Modal: Modal,
    InputField: InputField,
    Notification: Notification,
    RismaLabel: RismaLabel,
    SolutionsSelector: SolutionsSelector
  },
  mixins: [RaciTitleMixin],
  props: {
    projectId: {
      type: [String, Number],
      required: true
    },
    periodId: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  emits: ['dismiss', 'create'],
  data: function data() {
    return {
      title: '',
      errorMessages: '',
      selectedSolutions: [],
      isRismaSelectOpen: false,
      responsibleUserIds: [],
      createInProgress: false,
      translate: getTranslate['RiskCreationModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useUserStore, ['currentUser', 'riskProjectById'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    filteredUsers: function filteredUsers() {
      var _this = this;
      if (this.isERM) {
        return this.users.filter(function (user) {
          return user.level_risk >= UserLevels.NORMAL;
        });
      }
      return this.users.filter(function (user) {
        return userHasProjectAccess(user, _this.projectId, ObjectTypes.RISK_PROJECT) && user.level_risk >= UserLevels.NORMAL;
      });
    },
    preparedUserOptions: function preparedUserOptions() {
      return this.filteredUsers.map(function (item) {
        return {
          name: item.display_name,
          value: item.id
        };
      });
    },
    preselectedResponsibleUsers: function preselectedResponsibleUsers() {
      return [{
        name: this.currentUser.display_name,
        value: this.currentUser.id
      }];
    },
    isERM: function isERM() {
      return checkERMByProjectId(this.projectId, this.currentUser);
    },
    responsibleListTitle: function responsibleListTitle() {
      if (this.isERM) {
        return this.translate.dailyRiskOwner;
      }
      return this.$trans(this.responsibleTitle(true));
    }
  }),
  mounted: function mounted() {
    this.responsibleUserIds = this.preselectedResponsibleUsers.map(function (item) {
      return item.value;
    });
  },
  methods: {
    handleOnSubmit: function handleOnSubmit() {
      var _this2 = this;
      var project = this.riskProjectById(this.projectId);
      var data = {
        title: this.title,
        solutionIds: this.selectedSolutions.map(function (item) {
          return item.id;
        }),
        responsibleUserIds: this.responsibleUserIds,
        activityTypeId: project.defaultActivityTypeId
      };
      if (!this.handleValidation(data)) {
        return;
      }
      this.createInProgress = true;
      createRisk(this.projectId, this.periodId, data).then(function (response) {
        _this2.$emit('create', response);
      }).catch(function (error) {
        return _this2.errorMessages = error || error.error;
      }).finally(function () {
        return _this2.createInProgress = false;
      });
    },
    handleValidation: function handleValidation(data) {
      this.errorMessages = '';
      if (!data.title) {
        this.errorMessages = this.translate.titleCannotBeEmpty;
      }
      return !this.errorMessages;
    },
    onUserSelected: function onUserSelected(users) {
      this.responsibleUserIds = users.map(function (item) {
        return item.value;
      });
    }
  }
};