import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0f4e7e4f"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 1,
  class: "hover-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$dot;
  return _openBlock(), _createElementBlock("div", {
    class: "dot-wrapper",
    onMouseover: _cache[0] || (_cache[0] = function () {
      return $options.onMouseOver && $options.onMouseOver.apply($options, arguments);
    }),
    onMouseleave: _cache[1] || (_cache[1] = function () {
      return $options.onMouseLeave && $options.onMouseLeave.apply($options, arguments);
    })
  }, [((_$props$dot = $props.dot) === null || _$props$dot === void 0 ? void 0 : _$props$dot.id) !== undefined ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    style: _normalizeStyle($options.setStyle),
    class: "dot"
  }, _toDisplayString($props.dot.label || $props.dot.id), 5 /* TEXT, STYLE */)) : _createCommentVNode("v-if", true), $data.isHovered && $options.labelOnHover ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($options.labelOnHover), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 32 /* HYDRATE_EVENTS */);
}