import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "tags-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_link_item = _resolveComponent("link-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, function (tag) {
    return _openBlock(), _createBlock(_component_link_item, {
      key: tag.id,
      "as-tag": $props.asTag,
      "as-router-link": $props.asRouterLink,
      title: tag.name,
      tooltip: tag.tooltip,
      link: tag.url,
      module: $props.module
    }, {
      default: _withCtx(function () {
        return [$props.hasDeleteIcon ? (_openBlock(), _createBlock(_component_feather_icon, {
          key: 0
        })) : _createCommentVNode("v-if", true)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["as-tag", "as-router-link", "title", "tooltip", "link", "module"]);
  }), 128 /* KEYED_FRAGMENT */))]);
}