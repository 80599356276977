import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1934ca36"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "pdf-document text-center"
};
var _hoisted_2 = {
  key: 0,
  class: "pb-2"
};
var _hoisted_3 = ["disabled"];
var _hoisted_4 = {
  class: "px-4"
};
var _hoisted_5 = ["disabled"];
var _hoisted_6 = {
  key: 1,
  class: "loader"
};
var _hoisted_7 = {
  ref: "container"
};
var _hoisted_8 = {
  class: "border mx-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.pageCount > 1 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("button", {
    disabled: !!($data.currentPage === 1),
    onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
      return $options.changeCurrentPage($data.currentPage - 1);
    }, ["prevent"]))
  }, _toDisplayString($data.translate.previous), 9 /* TEXT, PROPS */, _hoisted_3), _createElementVNode("span", _hoisted_4, _toDisplayString($data.currentPage) + "/" + _toDisplayString($options.pageCount), 1 /* TEXT */), _createElementVNode("button", {
    disabled: !!($data.currentPage === $options.pageCount),
    onClick: _cache[1] || (_cache[1] = _withModifiers(function ($event) {
      return $options.changeCurrentPage($data.currentPage + 1);
    }, ["prevent"]))
  }, _toDisplayString($data.translate.next), 9 /* TEXT, PROPS */, _hoisted_5)])) : _createCommentVNode("v-if", true), !$data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_loading_indicator_large)])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_7, [_withDirectives(_createElementVNode("canvas", _hoisted_8, null, 512 /* NEED_PATCH */), [[_vShow, $data.isLoaded]])], 512 /* NEED_PATCH */)]);
}