import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5fe18823"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "flex text-white mr-6 my-auto absolute h-full top"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  class: "main-nav-item flex py-6"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
  class: "font-normal"
};
var _hoisted_7 = {
  key: 0,
  class: "absolute left-0 top-80px z-10 bg-white border border-gray-250 cursor-pointer"
};
var _hoisted_8 = {
  class: "shadow-header-hamburger-shadow"
};
var _hoisted_9 = ["onMouseover", "onMouseleave"];
var _hoisted_10 = {
  class: "text-lg w-full"
};
var _hoisted_11 = {
  key: 0,
  class: "absolute -top-px bg-white border border-gray-250 left-full shadow-header-hamburger-shadow"
};
var _hoisted_12 = ["onMouseover", "onMouseleave", "onClick"];
var _hoisted_13 = {
  key: 0,
  class: "absolute -top-px bg-white border border-gray-250 left-full shadow-header-hamburger-shadow"
};
var _hoisted_14 = ["onClick"];
var _hoisted_15 = {
  class: "text-lg w-full px-4 py-3"
};
var _hoisted_16 = {
  class: "text-title-small"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_nav_item = _resolveComponent("nav-item");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_user_menu = _resolveComponent("user-menu");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", {
    "data-trap": "headerNavbar",
    class: "fixed w-full top-0 z-100",
    style: _normalizeStyle($options.colorSettings)
  }, [_createElementVNode("div", {
    class: "flex px-6 min-h-5rem relative",
    style: _normalizeStyle("background-color: ".concat($options.colorSettings['--settingsBgColor']))
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_nav_item, {
    "is-risma2-component": $props.risma2enable,
    url: "/mypage",
    class: "leading-tight m-auto"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("img", {
        src: $options.imgSrc,
        class: "logo-image"
      }, null, 8 /* PROPS */, _hoisted_2)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-risma2-component"]), $data.hasCollapsedMenu ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    width: "45",
    height: "45",
    icon: "menu",
    style: _normalizeStyle({
      color: $options.colorSettings['--settingsTextColor']
    }),
    class: "hamburger-icon text-white m-auto ml-8 cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.hasHamburgerNavigation = !$data.hasHamburgerNavigation;
    })
  }, null, 8 /* PROPS */, ["style"])) : _createCommentVNode("v-if", true)]), _createCommentVNode(" invisible is important to calculate position for CollapsedMenu (v-if or v-show or d-none makes it 0 always) "), _withDirectives((_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass([[{
      'invisible': $data.hasCollapsedMenu
    }, $options.hasHamburgerFlag ? 'mx-auto' : 'lg:mx-64 2xl:mx-auto'], "flex-grow max-w-screen-xl w-full"])
  }, [_createElementVNode("ul", {
    ref: "navBar",
    class: _normalizeClass(['flex justify-around lg:m-auto', $data.navBar.length > 3 ? 'xxl:justify-between' : 'xxl:justify-center'])
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.navBar, function (link, index) {
    return _openBlock(), _createElementBlock("li", {
      key: "".concat(link.url, "-").concat(index),
      class: "flex-none inline-block px-4 text-xl"
    }, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_nav_item, {
      "is-risma2-component": $props.risma2enable,
      url: link.url,
      exact: link.exact,
      class: _normalizeClass(["relative group flex font-raleway text-sm border-t-2 border-b-2 py-0 items-center main-nav-link m-auto leading-6", {
        'main-nav-link__active': link.active
      }])
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("button", {
          class: "border-2 border-transparent focus-visible:border-black focus:outline-none uppercase font-medium",
          onClick: function onClick($event) {
            return $options.selectNavItem($event, link);
          }
        }, [_createElementVNode("span", null, _toDisplayString(link.title), 1 /* TEXT */), link.children && link.children.length ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["inline-block align-middle transform transition-transform duration-500 ml-1", {
            'rotate-180': link.selected
          }])
        }, [_createCommentVNode(" opacity is important so they would disappear without lag "), _createVNode(_component_feather_icon, {
          class: _normalizeClass([{
            'opacity-0': $data.hasCollapsedMenu
          }, "transform duration-500 text-white stroke-3"]),
          style: _normalizeStyle({
            color: $options.colorSettings['--settingsTextColor']
          }),
          icon: "chevron-down"
        }, null, 8 /* PROPS */, ["class", "style"])], 2 /* CLASS */)) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_4), _createElementVNode("div", {
          class: "nav-link-bar -bottom-0.3rem absolute border-2 border-transparent",
          style: _normalizeStyle({
            width: "calc(100% - ".concat(link.children && link.children.length ? '25px' : '0px', ")")
          })
        }, null, 4 /* STYLE */)];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["is-risma2-component", "url", "exact", "class"])]), link.children && link.children.length ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass(["sublist bg-gray-250 shadow-inner-t absolute h-auto z-2", [$options.isBigMenu(link) ? 'left-0 w-full border-gray-650 submenu-border' : '', link.selected ? 'visible' : 'invisible']])
    }, [_createElementVNode("ul", {
      class: _normalizeClass(["h-auto flex rounded-b-sm p-3 z-100 text-lg text-gray-650 cursor-default group-hover:visible", $options.getSubMenuStyle(link)]),
      onClick: function onClick($event) {
        return $options.selectNavItem($event, link);
      }
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(link.children, function (linkInner, linkIndex) {
      return _openBlock(), _createElementBlock("li", {
        key: "".concat(linkInner.url, "-").concat(linkIndex),
        class: "py-1 px-3 relative"
      }, [_createVNode(_component_nav_item, {
        "is-risma2-component": $props.risma2enable,
        url: linkInner.children ? '' : linkInner.url,
        exact: linkInner.exact,
        class: _normalizeClass(["ml-5", linkInner.children ? 'cursor-default' : 'border-b border-gray-250 hover:border-gray-710'])
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(linkInner.translatable === false ? linkInner.title : _ctx.$trans(linkInner.title)), 1 /* TEXT */)];
        }),

        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["is-risma2-component", "url", "exact", "class"]), _createElementVNode("ul", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(linkInner.children, function (linkItem, key) {
        return _openBlock(), _createElementBlock("li", {
          key: "".concat(linkItem.url, "-").concat(key),
          class: "py-1 relative"
        }, [_createVNode(_component_nav_item, {
          "is-risma2-component": $props.risma2enable,
          url: linkItem.url,
          exact: linkItem.exact,
          class: "ml-6 border-b border-gray-250 hover:border-gray-710"
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(linkItem.translatable === false ? linkItem.title : _ctx.$trans(linkItem.title)), 1 /* TEXT */)];
          }),

          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["is-risma2-component", "url", "exact"])]);
      }), 128 /* KEYED_FRAGMENT */))])]);
    }), 128 /* KEYED_FRAGMENT */))], 10 /* CLASS, PROPS */, _hoisted_5)], 2 /* CLASS */)) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */)], 2 /* CLASS */)), [[_directive_click_outside, $options.hideSubNavigation]]), $data.hasHamburgerNavigation ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("ul", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.navBar, function (link, index) {
    return _openBlock(), _createElementBlock("li", {
      key: "".concat(link.url, "-").concat(index),
      class: "relative px-4 py-3 hover:bg-gray-250",
      onMouseover: function onMouseover($event) {
        return link.hovered = true;
      },
      onMouseleave: function onMouseleave($event) {
        return link.hovered = false;
      }
    }, [_createVNode(_component_nav_item, {
      "is-risma2-component": $props.risma2enable,
      url: link.url,
      exact: link.exact,
      class: _normalizeClass(["flex items-center", {
        'underline': link.active
      }])
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("span", _hoisted_10, _toDisplayString(link.title), 1 /* TEXT */), _createVNode(_component_feather_icon, {
          icon: "chevron-right",
          class: _normalizeClass(["transform duration-500 text-white stroke-3 text-gray-450 ml-4", {
            'rotate-90': link.hovered
          }])
        }, null, 8 /* PROPS */, ["class"])];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["is-risma2-component", "url", "exact", "class"]), link.hovered ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(link.children, function (linkInner, linkIndex) {
      return _openBlock(), _createElementBlock("li", {
        key: "".concat(linkInner.url, "-").concat(linkIndex),
        class: "relative hover:bg-gray-250",
        onMouseover: function onMouseover($event) {
          return linkInner.hovered = true;
        },
        onMouseleave: function onMouseleave($event) {
          return linkInner.hovered = false;
        },
        onClick: function onClick($event) {
          return linkInner.children ? '' : $options.handleHamburgerClick([link, linkInner]);
        }
      }, [_createVNode(_component_nav_item, {
        "is-risma2-component": $props.risma2enable,
        url: linkInner.children ? '' : linkInner.url,
        exact: linkInner.exact,
        class: "flex items-center px-4 py-3"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("span", {
            class: _normalizeClass(["text-lg w-full", {
              'mr-4': linkInner.children
            }])
          }, _toDisplayString(linkInner.translatable === false ? linkInner.title : _ctx.$trans(linkInner.title)), 3 /* TEXT, CLASS */), linkInner.children ? (_openBlock(), _createBlock(_component_feather_icon, {
            key: 0,
            icon: "chevron-right",
            class: _normalizeClass(["transform duration-500 text-white stroke-3 text-gray-450 ml-4", {
              'rotate-90': linkInner.hovered
            }])
          }, null, 8 /* PROPS */, ["class"])) : _createCommentVNode("v-if", true)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["is-risma2-component", "url", "exact"]), linkInner.children && linkInner.hovered ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(linkInner.children, function (linkInnerChildren, linkInnerIndex) {
        return _openBlock(), _createElementBlock("li", {
          key: "".concat(linkInnerChildren.url, "-").concat(linkInnerIndex),
          class: "hover:bg-gray-250",
          onClick: function onClick($event) {
            return $options.handleHamburgerClick([link, linkInner]);
          }
        }, [_createVNode(_component_nav_item, {
          "is-risma2-component": $props.risma2enable,
          url: linkInnerChildren.url,
          exact: linkInnerChildren.exact,
          class: "flex items-center"
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("span", _hoisted_15, _toDisplayString(linkInnerChildren.translatable === false ? linkInnerChildren.title : _ctx.$trans(linkInnerChildren.title)), 1 /* TEXT */)];
          }),

          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["is-risma2-component", "url", "exact"])], 8 /* PROPS */, _hoisted_14);
      }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)], 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_12);
    }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)], 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_9);
  }), 128 /* KEYED_FRAGMENT */))])])), [[_directive_click_outside, $options.handleHamburgerClickOutside]]) : _createCommentVNode("v-if", true), $props.userMenuEnabled ? (_openBlock(), _createBlock(_component_user_menu, {
    key: 1,
    id: $props.id,
    ref: "usermenu",
    notifications: $data.notifications,
    "old-version": false,
    scoring: $data.scoring,
    users: _ctx.users,
    organisations: _ctx.organisations,
    "activity-types": _ctx.activityTypes,
    style: _normalizeStyle({
      color: $options.colorSettings['--settingsTextColor'],
      backgroundColor: 'inherit'
    }),
    class: "flex-none right-4 top-4 absolute",
    onClickNotification: $options.onNotificationClick,
    onMarkAllNotificationsAsRead: $options.handleMarkAllNotificationsAsRead
  }, null, 8 /* PROPS */, ["id", "notifications", "scoring", "users", "organisations", "activity-types", "style", "onClickNotification", "onMarkAllNotificationsAsRead"])) : _createCommentVNode("v-if", true)], 4 /* STYLE */), $data.subMenuList && $data.subMenuList.length ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "font-raleway text-white relative px-2 submenu",
    style: _normalizeStyle("background-color: ".concat($options.colorSettings['--settingsSubBgColor']))
  }, [_createElementVNode("div", {
    class: "absolute py-2 pl-4",
    style: _normalizeStyle({
      color: $options.colorSettings['--settingsSubTextColor']
    })
  }, _toDisplayString($options.currentSection), 5 /* TEXT, STYLE */), _createElementVNode("div", {
    class: "flex justify-center",
    style: _normalizeStyle("background-color: ".concat($options.colorSettings['--settingsSubBgColor']))
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.subMenuList, function (item) {
    return _openBlock(), _createElementBlock("div", {
      key: item.title
    }, [_createVNode(_component_nav_item, {
      "is-risma2-component": $props.risma2enable,
      url: item.url,
      exact: item.exact,
      class: _normalizeClass([{
        'router-link-active': $options.checkActiveSubItem(item)
      }, "relative px-4 py-2 inline-block uppercase font-medium hover:underline"])
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$trans(item.title)), 1 /* TEXT */)];
      }),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["is-risma2-component", "url", "exact", "class"])]);
  }), 128 /* KEYED_FRAGMENT */))], 4 /* STYLE */)], 4 /* STYLE */)) : _createCommentVNode("v-if", true)], 4 /* STYLE */);
}