import i18n from '@/i18n';
export var getTranslate = {
  CardsWrapper: function CardsWrapper() {
    return {
      show: i18n.t('Show'),
      hide: i18n.t('Hide'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      automatic: i18n.t('Automatic'),
      manual: i18n.t('Manual'),
      preventive: i18n.t('Preventive'),
      detective: i18n.t('Detective'),
      high: i18n.t('High'),
      low: i18n.t('Low'),
      none: i18n.t('None'),
      review: i18n.t('Review'),
      completion: i18n.t('Completion'),
      signoffPeriodPending: i18n.t('Signoff period pending')
    };
  }
};