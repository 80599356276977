import TinyMceBox from '@/components/Molecules/TinyMceBox';
import Modal from '@/components/Molecules/Modal/Modal';
import { DateFormats } from '@/constants/DateFormats';
import { removeHtmlTags } from '@/utils/Utils';
import { formatCurrentDateTime } from '@/utils/date';
export default {
  name: 'ContractWorkflowStatusModal',
  components: {
    Modal: Modal,
    TinyMceBox: TinyMceBox
  },
  props: {
    workflowStatusId: {
      type: [String, Number],
      required: true,
      default: 0,
      note: 'workflowStatusId, needed for accept emit'
    }
  },
  emits: ['accept', 'dismiss'],
  data: function data() {
    return {
      workflowStatusNote: "<p>".concat(formatCurrentDateTime(DateFormats.DATE_TIME_FORMAT), ": </p>"),
      translate: {
        statusChangeNote: this.$trans('Status change note'),
        save: this.$trans('Save'),
        cancel: this.$trans('Cancel'),
        stateTheReasonForStatusChange: this.$trans('State the reason for status change')
      }
    };
  },
  computed: {
    isValidNote: function isValidNote() {
      return !!removeHtmlTags(this.workflowStatusNote).trim();
    }
  }
};