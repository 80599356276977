function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import { changePassword as _changePassword } from '@/api/risma/userSettings';
export default {
  name: 'UserSettingsPassword',
  components: {
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    InputField: InputField,
    Notification: Notification
  },
  data: function data() {
    return {
      passwordData: {
        passwordCurrent: '',
        passwordNew: '',
        passwordNewRepeat: ''
      },
      notificationMessages: [],
      hasNotification: false,
      notificationType: 'error',
      translate: getTranslate['UserSettingsPassword']()
    };
  },
  computed: _objectSpread({}, mapState(useUserStore, ['currentUser'])),
  methods: {
    changePassword: function changePassword() {
      var _this = this;
      return _changePassword(this.currentUser.id, this.passwordData).then(function (response) {
        return _this.prepareSuccess(response);
      }).catch(function (error) {
        return _this.prepareError(error);
      });
    },
    prepareSuccess: function prepareSuccess(response) {
      var _this2 = this;
      this.hasNotification = true;
      this.notificationType = 'success';
      this.notificationMessages = [response.data];
      setTimeout(function () {
        _this2.hasNotification = false;
      }, 4000);
    },
    prepareError: function prepareError(error) {
      var _this3 = this;
      this.hasNotification = true;
      this.notificationType = 'error';
      if (error.response.error.startsWith('[')) {
        var errors = JSON.parse(error.response.error);
        this.notificationMessages = errors.map(function (error) {
          return _this3.$trans(error.charAt(0).toUpperCase() + error.slice(1));
        });
      } else {
        this.notificationMessages = [error.response.error];
      }
    }
  }
};