export var singleItem = {
  'id': 55,
  'path': '1',
  'title': 'Expansion of warehouse facility',
  'description': '',
  'objType': 'execution-initiative',
  'parent': null,
  'ino': 1,
  'ipath': '1',
  'slug': '1-expansion-of-warehouse-facility',
  'level': 1,
  'target': 'Storage capacity has to be increased with at least 20% before end of 2017.',
  'status': '',
  'trafficLight': 3,
  'closed': 0,
  'tagIds': ['1', '4'],
  'organisationIds': ['53', '59', '179'],
  'userIds': {
    'responsible': ['49', '58']
  },
  'firstDeadline': '2017-09-01',
  'start': '2016-07-01',
  'deadline': '2017-09-01',
  'created': '2015-08-09 09:09:28',
  'changed': '2017-05-09 14:52:21',
  'dependingOn': ['54'],
  'dependingNotiTo': null,
  'numericalUnitInheritance': 1,
  'completionPercentage': 0,
  'activityType': 3
};
export var itemList = [{
  'id': 55,
  'path': '1',
  'title': 'Expansion of warehouse facility',
  'url': '/actions/i/-/1-expansion-of-warehouse-facility',
  'description': '',
  'displayHeadline': '1 Expansion of warehouse facility',
  'objType': 'execution-initiative',
  'parent': null,
  'ino': 1,
  'ipath': '1',
  'slug': '1-expansion-of-warehouse-facility',
  'level': 1,
  'target': 'Storage capacity has to be increased with at least 20% before end of 2017.',
  'status': '',
  'trafficLight': 3,
  'trafficLightText': 'red',
  'closed': 0,
  'tagIds': ['21', '33', '31'],
  'organisationIds': ['53', '59', '181'],
  'userIds': {
    'responsible': ['49', '51']
  },
  'firstDeadline': '2017-09-01',
  'start': '2016-07-01',
  'deadline': '2017-09-01',
  'created': '2015-08-09 09:09:28',
  'changed': '2017-05-09 14:52:21',
  'dependingOn': ['54'],
  'dependingNotiTo': null,
  'numericalUnitInheritance': 1,
  'completionPercentage': 0,
  'activityType': 3,
  'customFields': []
}, {
  'id': 54,
  'path': '1.1',
  'title': 'Re-structure finance',
  'url': '/actions/i/-/1-1-re-structure-finance',
  'description': '',
  'displayHeadline': '1.1 Re-structure finance',
  'objType': 'execution-initiative',
  'parent': 55,
  'ino': 1,
  'ipath': '1.1',
  'slug': '1-1-re-structure-finance',
  'level': 2,
  'target': 'Revise organisational structure in finance department before end of Q3 2016',
  'status': 'A proposal is presently being drafted.',
  'trafficLight': 3,
  'trafficLightText': 'red',
  'closed': 0,
  'tagIds': ['21', '20', '28'],
  'organisationIds': ['53', '59'],
  'userIds': {
    'responsible': ['58']
  },
  'firstDeadline': '2016-05-01',
  'start': '2015-01-01',
  'deadline': '2016-05-01',
  'created': '2015-08-09 09:09:04',
  'changed': '2017-04-18 12:41:03',
  'dependingOn': [],
  'dependingNotiTo': null,
  'numericalUnitInheritance': 1,
  'completionPercentage': 0,
  'activityType': null,
  'customFields': []
}, {
  'id': 60,
  'path': '1.1.2',
  'title': 'Pre-study ERP',
  'url': '/actions/i/-/1-1-2-pre-study-erp',
  'description': '',
  'displayHeadline': '1.1.2 Pre-study ERP',
  'objType': 'execution-initiative',
  'parent': 54,
  'ino': 2,
  'ipath': '1.1.2',
  'slug': '1-1-2-pre-study-erp',
  'level': 2,
  'target': 'Complete pre-study before end of January 2016.\r\n\r\nFICATIONS!\r\nEXECUTION!\r\nNotifications for initiatives changelog\r\n- Since 2017-05-02 06:00:01\r\n\r\nMail counter:0\r\nCONTROLS!\r\nNotifications for controls changelog\r\nNotifications for control owners in organization #0 \r\n- Since 2017-05-02 06:00:01\r\n--->>WARNINGS!\r\nEXECUTION!\r\nExecution mail counter: 0\r\nCONTROLS!\r\nControls mail counter: 2\r\n---------------------------------------------------------\r\nHub id: 159 - ramboll\r\n--->>NOTIFICATIONS!\r\nEXECUTION!\r\nNotifications for initiatives changelog\r\n- Since 2017-05-02 06:00:01\r\n\r\nMail counter:0\r\nCONTROLS!\r\nNotifications for controls changelog\r\nNotifications for control owners in organization #0 \r\n- Since 2017-05-02 06:00:01\r\n--->>WARNINGS!\r\nEXECUTION!\r\nExecution mail counter: 0\r\nCONTROLS!\r\nControls mail counter: 0\r\n---------------------------------------------------------\r\nHub id: 160 - plesner\r\n--->>NOTIFICATIONS!',
  'status': 'mkllk\r\n\r\nMail counter:0\r\nCONTROLS!\r\nNotifications for controls changelog\r\nNotifications for control owners in organization #0 \r\n- Since 2017-05-02 06:00:01\r\n--->>WARNINGS!\r\nEXECUTION!\r\nExecution mail counter: 0\r\nCONTROLS!\r\nControls mail counter: 2\r\n---------------------------------------------------------\r\nHub id: 158 - byoghavn2\r\n--->>NOTIFICATIONS!\r\nEXECUTION!\r\nNotifications for initiatives changelog\r\n- Since 2017-05-02 06:00:01\r\n\r\nMail counter:0\r\nCONTROLS!\r\nNotifications for controls changelog\r\nNotifications for control owners in organization #0 \r\n- Since 2017-05-02 06:00:01\r\n--->>WARNINGS!\r\nEXECUTION!\r\nExecution mail counter: 0\r\nCONTROLS!\r\nControls mail counter: 2\r\n---------------------------------------------------------\r\nHub id: 159 - ramboll\r\n--->>NOTIFICATIONS!\r\nEXECUTION!\r\nNotifications for initiatives changelog\r\n- Since 2017-05-02 06:00:01\r\n\r\nMail counter:0\r\nCONTROLS!\r\nNotifications for controls changelog\r\nNotifications for control owners in organization #0 \r\n- Since 2017-05-02 06:00:01\r\n--->>WARNINGS!\r\nEXECUTION!\r\nExecution mail counter: 0\r\nCONTROLS!\r\nControls mail counter: 0\r\n---------------------------------------------------------\r\nHub id: 160 - plesner\r\n--->>NOTIFICATIONS!\r\n',
  'trafficLight': 2,
  'trafficLightText': 'red',
  'closed': 0,
  'tagIds': ['21', '30', '22'],
  'organisationIds': ['53', '59'],
  'userIds': {
    'responsible': ['1', '43']
  },
  'firstDeadline': '2016-04-29',
  'start': '2015-03-02',
  'deadline': '2016-04-29',
  'created': '2015-08-09 09:35:41',
  'changed': '2017-05-05 11:50:02',
  'dependingOn': ['55'],
  'dependingNotiTo': '[]',
  'numericalUnitInheritance': 1,
  'completionPercentage': 18,
  'activityType': 2,
  'customFields': []
}, {
  'id': 64,
  'path': '1.1.2.1',
  'title': 'ERP requirements Logistics',
  'url': '/actions/i/-/1-1-2-1-erp-requirements-logistics',
  'description': '',
  'displayHeadline': '1.1.2.1 ERP requirements Logistics',
  'objType': 'execution-initiative',
  'parent': 60,
  'ino': 1,
  'ipath': '1.1.2.1',
  'slug': '1-1-2-1-erp-requirements-logistics',
  'level': 3,
  'target': 'Logistics should submit requirements to the ERP system no later than 15 December 2015.',
  'status': 'Will be delayed. Expected mid-February.',
  'trafficLight': 2,
  'trafficLightText': 'red',
  'closed': 0,
  'tagIds': ['21', '20', '27'],
  'organisationIds': ['173'],
  'userIds': {
    'responsible': ['51']
  },
  'firstDeadline': '2016-05-04',
  'start': '2016-03-26',
  'deadline': '2016-05-04',
  'created': '2015-08-09 10:18:14',
  'changed': '2017-04-18 12:41:03',
  'dependingOn': [],
  'dependingNotiTo': '[]',
  'numericalUnitInheritance': 1,
  'completionPercentage': 0,
  'activityType': null,
  'customFields': []
}];
export var initiatives = {
  'count': 4,
  'list': itemList
};
export var preparedList = [{
  'id': 55,
  'url': '/actions/i/-/1-expansion-of-warehouse-facility',
  'path': '1',
  'title': '1 Expansion of warehouse facility',
  'description': '',
  'displayHeadline': '1 Expansion of warehouse facility',
  'objType': 'execution-initiative',
  'parent': null,
  'ino': 1,
  'ipath': '1',
  'slug': '1-expansion-of-warehouse-facility',
  'level': 1,
  'target': 'Storage capacity has to be increased with at least 20% before end of 2017.',
  'status': '',
  'trafficLight': 3,
  'trafficLightText': 'red',
  'closed': 0,
  'tagIds': ['21', '33', '31'],
  'organisationIds': ['53', '59', '181'],
  'userIds': {
    'responsible': ['49', '51']
  },
  'firstDeadline': '2017-09-01',
  'start': '2016-07-01',
  'deadline': '2017-09-01',
  'created': '2015-08-09 09:09:28',
  'changed': '2017-05-09 14:52:21',
  'dependingOn': ['54'],
  'dependingNotiTo': null,
  'numericalUnitInheritance': 1,
  'completionPercentage': 0,
  'activityType': 3,
  'linkParam': '/actions/i/-/1-expansion-of-warehouse-facility',
  'customFields': []
}, {
  'id': 54,
  'path': '1.1',
  'title': '1.1 Re-structure finance',
  'url': '/actions/i/-/1-1-re-structure-finance',
  'description': '',
  'displayHeadline': '1.1 Re-structure finance',
  'objType': 'execution-initiative',
  'parent': 55,
  'ino': 1,
  'ipath': '1.1',
  'slug': '1-1-re-structure-finance',
  'level': 2,
  'target': 'Revise organisational structure in finance department before end of Q3 2016',
  'status': 'A proposal is presently being drafted.',
  'trafficLight': 3,
  'trafficLightText': 'red',
  'closed': 0,
  'tagIds': ['21', '20', '28'],
  'organisationIds': ['53', '59'],
  'userIds': {
    'responsible': ['58']
  },
  'firstDeadline': '2016-05-01',
  'start': '2015-01-01',
  'deadline': '2016-05-01',
  'created': '2015-08-09 09:09:04',
  'changed': '2017-04-18 12:41:03',
  'dependingOn': [],
  'dependingNotiTo': null,
  'numericalUnitInheritance': 1,
  'completionPercentage': 0,
  'activityType': null,
  'linkParam': '/actions/i/-/1-1-re-structure-finance',
  'customFields': []
}, {
  'id': 60,
  'path': '1.1.2',
  'title': '1.1.2 Pre-study ERP',
  'url': '/actions/i/-/1-1-2-pre-study-erp',
  'description': '',
  'displayHeadline': '1.1.2 Pre-study ERP',
  'objType': 'execution-initiative',
  'parent': 54,
  'ino': 2,
  'ipath': '1.1.2',
  'slug': '1-1-2-pre-study-erp',
  'level': 2,
  'target': 'Complete pre-study before end of January 2016.\r\n\r\nFICATIONS!\r\nEXECUTION!\r\nNotifications for initiatives changelog\r\n- Since 2017-05-02 06:00:01\r\n\r\nMail counter:0\r\nCONTROLS!\r\nNotifications for controls changelog\r\nNotifications for control owners in organization #0 \r\n- Since 2017-05-02 06:00:01\r\n--->>WARNINGS!\r\nEXECUTION!\r\nExecution mail counter: 0\r\nCONTROLS!\r\nControls mail counter: 2\r\n---------------------------------------------------------\r\nHub id: 159 - ramboll\r\n--->>NOTIFICATIONS!\r\nEXECUTION!\r\nNotifications for initiatives changelog\r\n- Since 2017-05-02 06:00:01\r\n\r\nMail counter:0\r\nCONTROLS!\r\nNotifications for controls changelog\r\nNotifications for control owners in organization #0 \r\n- Since 2017-05-02 06:00:01\r\n--->>WARNINGS!\r\nEXECUTION!\r\nExecution mail counter: 0\r\nCONTROLS!\r\nControls mail counter: 0\r\n---------------------------------------------------------\r\nHub id: 160 - plesner\r\n--->>NOTIFICATIONS!',
  'status': 'mkllk\r\n\r\nMail counter:0\r\nCONTROLS!\r\nNotifications for controls changelog\r\nNotifications for control owners in organization #0 \r\n- Since 2017-05-02 06:00:01\r\n--->>WARNINGS!\r\nEXECUTION!\r\nExecution mail counter: 0\r\nCONTROLS!\r\nControls mail counter: 2\r\n---------------------------------------------------------\r\nHub id: 158 - byoghavn2\r\n--->>NOTIFICATIONS!\r\nEXECUTION!\r\nNotifications for initiatives changelog\r\n- Since 2017-05-02 06:00:01\r\n\r\nMail counter:0\r\nCONTROLS!\r\nNotifications for controls changelog\r\nNotifications for control owners in organization #0 \r\n- Since 2017-05-02 06:00:01\r\n--->>WARNINGS!\r\nEXECUTION!\r\nExecution mail counter: 0\r\nCONTROLS!\r\nControls mail counter: 2\r\n---------------------------------------------------------\r\nHub id: 159 - ramboll\r\n--->>NOTIFICATIONS!\r\nEXECUTION!\r\nNotifications for initiatives changelog\r\n- Since 2017-05-02 06:00:01\r\n\r\nMail counter:0\r\nCONTROLS!\r\nNotifications for controls changelog\r\nNotifications for control owners in organization #0 \r\n- Since 2017-05-02 06:00:01\r\n--->>WARNINGS!\r\nEXECUTION!\r\nExecution mail counter: 0\r\nCONTROLS!\r\nControls mail counter: 0\r\n---------------------------------------------------------\r\nHub id: 160 - plesner\r\n--->>NOTIFICATIONS!\r\n',
  'trafficLight': 2,
  'trafficLightText': 'red',
  'closed': 0,
  'tagIds': ['21', '30', '22'],
  'organisationIds': ['53', '59'],
  'userIds': {
    'responsible': ['1', '43']
  },
  'firstDeadline': '2016-04-29',
  'start': '2015-03-02',
  'deadline': '2016-04-29',
  'created': '2015-08-09 09:35:41',
  'changed': '2017-05-05 11:50:02',
  'dependingOn': ['55'],
  'dependingNotiTo': '[]',
  'numericalUnitInheritance': 1,
  'completionPercentage': 18,
  'activityType': 2,
  'linkParam': '/actions/i/-/1-1-2-pre-study-erp',
  'customFields': []
}, {
  'id': 64,
  'path': '1.1.2.1',
  'title': '1.1.2.1 ERP requirements Logistics',
  'url': '/actions/i/-/1-1-2-1-erp-requirements-logistics',
  'description': '',
  'displayHeadline': '1.1.2.1 ERP requirements Logistics',
  'objType': 'execution-initiative',
  'parent': 60,
  'ino': 1,
  'ipath': '1.1.2.1',
  'slug': '1-1-2-1-erp-requirements-logistics',
  'level': 3,
  'target': 'Logistics should submit requirements to the ERP system no later than 15 December 2015.',
  'status': 'Will be delayed. Expected mid-February.',
  'trafficLight': 2,
  'trafficLightText': 'red',
  'closed': 0,
  'tagIds': ['21', '20', '27'],
  'organisationIds': ['173'],
  'userIds': {
    'responsible': ['51']
  },
  'firstDeadline': '2016-05-04',
  'start': '2016-03-26',
  'deadline': '2016-05-04',
  'created': '2015-08-09 10:18:14',
  'changed': '2017-04-18 12:41:03',
  'dependingOn': [],
  'dependingNotiTo': '[]',
  'numericalUnitInheritance': 1,
  'completionPercentage': 0,
  'activityType': null,
  'linkParam': '/actions/i/-/1-1-2-1-erp-requirements-logistics',
  'customFields': []
}];
export var initiativesTree = {
  'id': 138,
  'path': '1',
  'title': 'Test root',
  'description': '',
  'objType': 'execution-initiative-limited',
  'ipath': '1',
  'displayHeadline': '1 Test root',
  'slug': '1-test-root',
  'url': '/actions/i/-/1-test-root/138',
  'trafficLight': 0,
  'trafficLightText': 'grey',
  'completionPercentage': 0,
  'closed': 0,
  'closedBy': null,
  'closedAt': null,
  'deadline': '2023-08-23',
  'solutionIds': [],
  'userIds': {
    'responsible': [],
    'notifications': []
  },
  'confidential': 0,
  'children': [{
    'id': 139,
    'path': '1.1',
    'title': 'test children 1',
    'description': '',
    'objType': 'execution-initiative-limited',
    'ipath': '1.1',
    'displayHeadline': '1.1 test children 1',
    'slug': '1-1-test-children-1',
    'url': '/actions/i/-/1-1-test-children-1/139',
    'trafficLight': 0,
    'trafficLightText': 'grey',
    'completionPercentage': 0,
    'closed': 0,
    'closedBy': null,
    'closedAt': null,
    'deadline': '2023-08-23',
    'solutionIds': [],
    'userIds': {
      'responsible': [],
      'notifications': []
    },
    'confidential': 0,
    'children': [{
      'id': 141,
      'path': '1.1.1',
      'title': 'test children 1.1',
      'description': '',
      'objType': 'execution-initiative-limited',
      'ipath': '1.1.1',
      'displayHeadline': '1.1.1 test children 1.1',
      'slug': '1-1-1-test-children-1-1',
      'url': '/actions/i/-/1-1-1-test-children-1-1/141',
      'trafficLight': 0,
      'trafficLightText': 'grey',
      'completionPercentage': 0,
      'closed': 0,
      'closedBy': null,
      'closedAt': null,
      'deadline': '2023-08-23',
      'solutionIds': [],
      'userIds': {
        'responsible': [],
        'notifications': []
      },
      'confidential': 0,
      'children': []
    }]
  }, {
    'id': 140,
    'path': '1.2',
    'title': 'test children 2',
    'description': '',
    'objType': 'execution-initiative-limited',
    'ipath': '1.2',
    'displayHeadline': '1.2 test children 2',
    'slug': '1-2-test-children-2',
    'url': '/actions/i/-/1-2-test-children-2/140',
    'trafficLight': 0,
    'trafficLightText': 'grey',
    'completionPercentage': 0,
    'closed': 0,
    'closedBy': null,
    'closedAt': null,
    'deadline': '2023-08-23',
    'solutionIds': [],
    'userIds': {
      'responsible': [],
      'notifications': []
    },
    'confidential': 0,
    'children': [{
      'id': 142,
      'path': '1.2.1',
      'title': 'test children 2.1',
      'description': '',
      'objType': 'execution-initiative-limited',
      'ipath': '1.2.1',
      'displayHeadline': '1.2.1 test children 2.1',
      'slug': '1-2-1-test-children-2-1',
      'url': '/actions/i/-/1-2-1-test-children-2-1/142',
      'trafficLight': 0,
      'trafficLightText': 'grey',
      'completionPercentage': 0,
      'closed': 0,
      'closedBy': null,
      'closedAt': null,
      'deadline': '2023-08-23',
      'solutionIds': [],
      'userIds': {
        'responsible': [],
        'notifications': []
      },
      'confidential': 0,
      'children': []
    }]
  }]
};