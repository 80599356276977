import { getTranslate } from './translate';
import { createTableCalculation, getTableCalculationById } from '@/api/risk/scoring';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
export default {
  name: 'CreationMatrixAxisModal',
  token: '<creation-matrix-axis-modal />',
  components: {
    InputField: InputField,
    Modal: Modal,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    RismaLabel: RismaLabel,
    RismaButton: RismaButton
  },
  props: {
    scoringId: {
      type: [String, Number],
      require: true,
      default: null
    },
    axis: {
      type: String,
      require: true,
      default: ''
    }
  },
  emits: ['dismiss', 'create'],
  data: function data() {
    return {
      header: this.$trans('Create new risk matrix axis'),
      subHeader: this.$trans('Please complete this form to create a new table-based axis.'),
      title: '',
      scoresNumber: '',
      paramInTable: '',
      translate: getTranslate['CreationMatrixAxisModal']()
    };
  },
  computed: {
    isValid: function isValid() {
      return this.title && this.scoresNumber && this.paramInTable;
    },
    scoresNumberOptions: function scoresNumberOptions() {
      return ['5', '6'].map(function (item) {
        return {
          label: item,
          value: item
        };
      });
    },
    paramInTableOptions: function paramInTableOptions() {
      var result = [];
      var number = 1;
      while (number !== 9) {
        result.push({
          label: number + '',
          value: number + ''
        });
        number++;
      }
      return result;
    }
  },
  methods: {
    dismissModal: function dismissModal() {
      this.$emit('dismiss');
    },
    createMatrix: function createMatrix() {
      var _this = this;
      var data = "scoringid=".concat(this.scoringId, "&axis=").concat(this.axis, "&title=").concat(this.title, "&levels=").concat(this.scoresNumber, "&fields=").concat(this.paramInTable);
      return createTableCalculation(data).then(function (_ref) {
        var result = _ref.result;
        var id = result.id;
        if (id) {
          return getTableCalculationById(_this.scoringId, id);
        }
        throw new Error('Table was not created');
      }).then(function () {
        _this.$emit('create');
        _this.dismissModal();
      });
    }
  }
};