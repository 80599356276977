import HelpText from '@/components/Atoms/HelpText/HelpText';
export default {
  name: '',
  components: {
    HelpText: HelpText
  },
  data: function data() {
    return {
      textLong: 'Originally a Viking fishing village established in the 10th century in the vicinity of what is now Gammel Strand, Copenhagen became the capital of Denmark in the early 15th century. Beginning in the 17th century it consolidated its position as a regional centre of power with its institutions, defences and armed forces.',
      textShort: 'The city was founded in 1654 and after a humble beginning as a small fortress grew to be a major centre of Ukrainian industry.'
    };
  }
};