import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
var _hoisted_2 = {
  key: 1,
  class: "mb-3"
};
var _hoisted_3 = {
  key: 2,
  class: "mb-3"
};
var _hoisted_4 = {
  key: 3,
  class: "mb-3"
};
var _hoisted_5 = {
  key: 4,
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_text_box = _resolveComponent("text-box");
  var _component_single_select = _resolveComponent("single-select");
  var _component_user_selector = _resolveComponent("user-selector");
  var _component_organisation_selector = _resolveComponent("organisation-selector");
  var _component_solutions_selector = _resolveComponent("solutions-selector");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $options.titleModal,
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    "overflow-visible": true,
    "dismiss-on-click-outside": false,
    "accept-on-enter": !$data.isRismaSelectOpen,
    "ok-button-disabled": $data.isCreating,
    onAccept: $options.submitModal,
    onDismiss: _cache[12] || (_cache[12] = function ($event) {
      return _ctx.$emit('close');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.errorMessages.length ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.pleaseCorrectTheErrorsAndTryAgain) + " ", 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errorMessages, function (error) {
            return _openBlock(), _createElementBlock("div", {
              key: error
            }, _toDisplayString(error), 1 /* TEXT */);
          }), 128 /* KEYED_FRAGMENT */))];
        }),

        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        modelValue: $data.data.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.data.title = $event;
        }),
        title: $data.translate.title,
        "focus-on-mount": true,
        "pre-text": $props.preset.title,
        enabled: !$props.preset.title,
        placeholder: $data.translate.title,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "pre-text", "enabled", "placeholder"])]), $props.showAllFields ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
        title: $data.translate.description,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_text_box, {
        text: $data.data.description,
        rows: 2,
        placeholder: $data.translate.description,
        class: "w-full",
        onUpdated: $options.setDescription
      }, null, 8 /* PROPS */, ["text", "placeholder", "onUpdated"])])) : _createCommentVNode("v-if", true), $props.showAllFields ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_single_select, {
        modelValue: $data.motherControlChosen,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.motherControlChosen = $event;
        }),
        title: $data.translate.motherControl,
        options: $options.montherControlsOptions,
        placeholder: $data.translate.motherControl,
        "un-sorted": true
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])])) : _createCommentVNode("v-if", true), !$props.isMotherMode ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_user_selector, {
        "available-options": $options.controlUsers,
        "selected-options": $data.data.responsible,
        title: $data.translate.responsible,
        onSelected: _cache[2] || (_cache[2] = function ($event) {
          return $options.propertyChange($event, 'responsible');
        }),
        onOpen: _cache[3] || (_cache[3] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[4] || (_cache[4] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["available-options", "selected-options", "title"])])) : _createCommentVNode("v-if", true), !$props.isMotherMode ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_title, {
        title: $data.translate.organisations,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_organisation_selector, {
        "available-options": _ctx.organisations,
        "selected-options": $data.data.organisations,
        onSelected: _cache[5] || (_cache[5] = function ($event) {
          return $options.propertyChange($event, 'organisations');
        }),
        onOpen: _cache[6] || (_cache[6] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[7] || (_cache[7] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["available-options", "selected-options"])])) : _createCommentVNode("v-if", true), _createVNode(_component_solutions_selector, {
        class: "mb-3",
        "selected-options": $data.data.solutions || [],
        onSelected: _cache[8] || (_cache[8] = function ($event) {
          return $options.propertyChange($event, 'solutions');
        }),
        onOpen: _cache[9] || (_cache[9] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[10] || (_cache[10] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["selected-options"]), _createVNode(_component_single_select, {
        modelValue: $data.data.activityTypeId,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = function ($event) {
          return $data.data.activityTypeId = $event;
        }),
        title: $data.translate.activityType,
        options: $options.activityTypeOptions,
        placeholder: $data.translate.activityType,
        "with-reset": true,
        "un-sorted": true
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "accept-on-enter", "ok-button-disabled", "onAccept"]);
}