import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-b0e21394"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  style: {
    "position": "relative",
    "width": "100%",
    "height": "120px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_popup = _resolveComponent("risma-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_button, {
    text: "Show Popup",
    type: "util",
    onClick: _withModifiers($options.showPopup, ["stop"])
  }, null, 8 /* PROPS */, ["onClick"]), _createVNode(_component_risma_popup, {
    "is-hidden": $data.isHiddenPopup,
    onHide: $options.hidePopup
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_input_field, {
        type: "text"
      }), _createVNode(_component_risma_button, {
        text: "Save",
        type: "save"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-hidden", "onHide"])]);
}