function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getWorkflowStatusesByModule } from '@/api/risma/data';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import ContractGenericTable from '@/components/Pages/Contracts/ContractGenericTable';
import MainWell from '@/components/Atoms/Containers/MainWell';
import { TabTypes } from '@/constants/Tabs';
import { MODULES } from '@/constants/modules';
export default {
  name: 'ContractHistory',
  description: 'This is the Page component for /contracts/contract/:id/history',
  token: '<contract-history :data="data"/>',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    ContractGenericTable: ContractGenericTable
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Contract object'
    }
  },
  data: function data() {
    return {
      hiddenColumns: ['controls', 'titlePreview', 'companies', 'responsible', 'delete', 'starred', 'tags'],
      TabTypes: TabTypes,
      showRelating: true,
      showOther: true,
      contracts: [],
      workflowStatuses: [],
      updated: false,
      companies: [],
      translate: {
        currentContract: this.$trans('Current contract'),
        contractHistory: this.$trans('Contract history'),
        theContractHasNotBeenRenewedAndHoldsTheref: this.$trans('The contract has not been renewed and holds therefore no history.')
      }
    };
  },
  computed: {
    historyData: function historyData() {
      return _toConsumableArray(this.data.history).reverse();
    }
  },
  mounted: function mounted() {
    var _this = this;
    getWorkflowStatusesByModule(MODULES.CONTRACTS).then(function (_ref) {
      var list = _ref.list;
      return _this.workflowStatuses = list;
    });
  }
};