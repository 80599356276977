import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  key: 1
};
var _hoisted_2 = {
  class: "text-center"
};
var _hoisted_3 = {
  key: 4,
  class: "w-full text-center mb-10"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_questionnaire_selection = _resolveComponent("questionnaire-selection");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", null, [$data.questionnaires.length > 0 ? (_openBlock(), _createBlock(_component_questionnaire_selection, {
    key: 0,
    questionnaires: $data.questionnaires,
    "project-id": Number($props.projectId),
    "initial-filters": $data.initialFilters,
    onCreateReport: $options.handleCreateReport,
    onHideReport: $options.handleClearTable
  }, null, 8 /* PROPS */, ["questionnaires", "project-id", "initial-filters", "onCreateReport", "onHideReport"])) : _createCommentVNode("v-if", true), $data.error ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h3", _hoisted_2, _toDisplayString($data.error), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 2
  })) : _createCommentVNode("v-if", true), $data.showReport === true && $data.rows.length ? (_openBlock(), _createBlock(_resolveDynamicComponent($data.reportTable), {
    key: 3,
    mode: $data.mode,
    rows: $data.rows,
    "report-url": $data.reportUrl,
    "report-options": $data.reportArgs,
    "report-type": $options.savedReportType,
    "show-controllers-and-processors": _ctx.showControllersAndProcessors,
    "include-data-flow": $data.includeDataFlow,
    "is-answered-only": $data.isAnsweredOnly,
    "include-raci-emails": $data.includeRaciEmails,
    "include-help-text": $data.includeHelpText,
    "is-extended-view-on-load": $data.isExtendedViewOnLoad,
    "project-id": $props.projectId,
    "state-local": $options.localStateName,
    onSetExtendedView: _cache[0] || (_cache[0] = function ($event) {
      return $data.isExtendedViewOnLoad = $event;
    }),
    onDismissModal: $options.refreshTable,
    onUpdateFromContactCards: $options.updateFromContactCards,
    onDismissContractCardModal: $options.dismissContractCardModal
  }, null, 8 /* PROPS */, ["mode", "rows", "report-url", "report-options", "report-type", "show-controllers-and-processors", "include-data-flow", "is-answered-only", "include-raci-emails", "include-help-text", "is-extended-view-on-load", "project-id", "state-local", "onDismissModal", "onUpdateFromContactCards", "onDismissContractCardModal"])) : _createCommentVNode("v-if", true), $options.showNothingToShowMessage ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.nothingToShow
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.translate.enterYourSearchCriteriaAboveToViewSearchRe), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]);
}