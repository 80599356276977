import ExpandedCard from '@/components/Molecules/Cards/ExpandedCard';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import LoadMoreWrapper from '@/components/Molecules/LoadMoreWrapper';
export default {
  name: 'PreviewListExpanded',
  introduction: 'Container for expanded list',
  token: '<preview-list-expanded title="All list"><slot /> </preview-list-expanded>',
  components: {
    ExpandedCard: ExpandedCard,
    LoadMoreWrapper: LoadMoreWrapper,
    PreviewList: PreviewList
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
      note: 'A title of a list'
    },
    activities: {
      type: Array,
      required: true,
      note: 'All activities'
    },
    users: {
      type: Array,
      required: true,
      note: 'A list of all users'
    },
    organisations: {
      type: Array,
      required: true,
      note: 'A list of all organisations'
    },
    additionalFields: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: false,
      note: 'Additional fields to display (Reviewers, Completed, Review Deadline, etc..)'
    },
    watermarkImage: {
      type: String,
      required: false,
      default: 'url(\'/assets/svg/ikon_controls.svg\')',
      note: 'Url of background watermark image'
    },
    cookieHiddenName: {
      type: String,
      required: false,
      default: '',
      note: 'Save last state of show/hide feature in cookie'
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'A list of all activity types'
    },
    workflowStatuses: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'A list of workflow statuses for contracts'
    },
    scoring: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Scoring period for ERM risks'
    },
    period: {
      type: Object,
      default: null,
      note: 'Project period data'
    },
    isApprovalFlowEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    previewMode: {
      type: Boolean,
      required: false,
      default: false
    },
    headerOffset: {
      type: String,
      required: false,
      default: '15px',
      note: 'Header side offset'
    }
  },
  methods: {
    changeShowState: function changeShowState(toggleState) {
      // used in Risk Overview to toggle all views
      this.$refs.previewList.changeShowState(toggleState);
    }
  }
};