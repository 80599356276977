import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Selector from '@/components/Atoms/Inputs/Selector';
import InputField from '@/components/Atoms/Inputs/InputField';
import CategoryModal from '@/components/Organisms/Admin/ThreatAndVulnerability/CategoryModal';
export default {
  name: 'RiskThreatModal',
  introduction: 'Modal to create or update risk threat',
  description: 'Modal for creation & edit risk threat. Used together with CategoryModal component',
  token: '<risk-threat-modal />',
  components: {
    InputField: InputField,
    RismaTitle: RismaTitle,
    Selector: Selector,
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    CategoryModal: CategoryModal
  },
  props: {
    isEditMode: {
      type: Boolean,
      require: true
    },
    title: {
      type: String,
      require: true,
      default: ''
    },
    preselectedCategoryId: {
      type: Number,
      default: null
    },
    categories: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['dismiss', 'create', 'update', 'create:category'],
  data: function data() {
    return {
      categoryModalShown: false,
      selectedCategoryId: this.preselectedCategoryId,
      isTitleValid: true,
      translate: getTranslate['RiskThreatModal']()
    };
  },
  computed: {
    selectableCategories: function selectableCategories() {
      var _this = this;
      if (!this.selectedCategory) {
        return this.categories;
      }
      return this.categories.filter(function (item) {
        return _this.selectedCategoryId !== item.id;
      });
    },
    selectedCategory: function selectedCategory() {
      var _this2 = this;
      return this.categories.find(function (item) {
        return _this2.selectedCategoryId === item.id;
      });
    },
    modalHeaderTitle: function modalHeaderTitle() {
      return this.isEditMode ? this.translate.editThreat : this.translate.createThreat;
    },
    buttonOkLabel: function buttonOkLabel() {
      return this.isEditMode ? this.translate.save : this.translate.create;
    }
  },
  methods: {
    selectCategory: function selectCategory(category) {
      this.selectedCategoryId = category.id;
    },
    openCreationModal: function openCreationModal() {
      this.categoryModalShown = true;
    },
    handleCreateCategory: function handleCreateCategory(category) {
      this.categoryModalShown = false;
      this.selectedCategoryId = category.id;
      this.$emit('create:category', category);
    },
    handleAccept: function handleAccept() {
      this.isTitleValid = !!this.title;
      if (!this.isTitleValid) return;
      if (this.isEditMode) {
        this.$emit('update', {
          title: this.title,
          categoryId: this.selectedCategoryId
        });
      } else {
        this.$emit('create', {
          title: this.title,
          categoryId: this.selectedCategoryId
        });
      }
    },
    deselect: function deselect() {
      this.selectedCategoryId = null;
    }
  }
};