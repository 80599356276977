function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useSolutionsStore } from '@/Store/solutionsStore';
import { DelegationModalSettings, DelegationTypes } from '@/constants/relations/DelegationModal';
import DelegationModal from '@/components/Molecules/DelegationModal';
import DelegationList from '@/components/Atoms/DelegationList/DelegationList';
export default {
  name: 'SolutionIdRelationDetails',
  components: {
    DelegationModal: DelegationModal,
    DelegationList: DelegationList
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['propertyChanged'],
  data: function data() {
    return {
      showModal: false,
      translate: {
        solutionId: this.$trans('Solution ID')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useSolutionsStore, {
    solutionIds: 'solutions',
    enabledSolutionIds: 'enabledSolutions'
  })), {}, {
    modalSettings: function modalSettings() {
      return DelegationModalSettings[DelegationTypes.solutionId];
    },
    convertedSelectedSolutionIds: function convertedSelectedSolutionIds() {
      var _this = this;
      if (!this.solutionIds || !this.solutionIds.length) return [];
      var result = [];
      this.list.forEach(function (id) {
        var solution = _this.solutionIds.find(function (item) {
          return item.id === id;
        });
        if (solution) {
          result.push({
            id: id,
            title: solution.title
          });
        }
      });
      return result;
    },
    isEnabled: function isEnabled() {
      return this.getSettingValue('feature.performance_report_enabled') > 0;
    }
  }),
  methods: {
    toggleModal: function toggleModal() {
      this.showModal = !this.showModal;
    },
    editDelegationItem: function editDelegationItem() {
      this.showModal = true;
    },
    onApplyChanges: function onApplyChanges(value) {
      this.$emit('propertyChanged', {
        property: 'solutionIds',
        value: value
      });
      this.showModal = false;
    }
  }
};