import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_button, {
    text: "Save",
    type: "save"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "save",
        class: "mr-2"
      })];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_risma_button, {
    text: "Util",
    type: "util"
  }), _createVNode(_component_risma_button, {
    disabled: true,
    text: "(s)disabled",
    type: "save"
  }), _createVNode(_component_risma_button, {
    disabled: true,
    text: "(u)disabled",
    type: "util"
  }), _createVNode(_component_risma_button, {
    disabled: true,
    text: "(c)disabled",
    type: "danger"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "alert-circle",
        class: "mr-2"
      })];
    }),
    _: 1 /* STABLE */
  })]);
}