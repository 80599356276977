function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { getTranslate } from './translate';
import VueNestableHandle from '@/components/Molecules/VueNestable/VueNestableHandle';
import VueNestable from '@/components/Molecules/VueNestable/VueNestable';
import Alert from '@/components/Molecules/Modal/Alert';
import RismaTreeBranch from '@/components/Atoms/RismaTreeBranch';
export default {
  name: 'RismaTree',
  introduction: 'RismaTree VueNestable component',
  description: 'This is a component written on vue.js VueNestable node module',
  token: "<risma-tree\n        v-if=\"treeData !== null\"\n        v-bind=\"$props\"\n        :tree-data=\"treeData.children\" />",
  components: {
    RismaTreeBranch: RismaTreeBranch,
    VueNestable: VueNestable,
    VueNestableHandle: VueNestableHandle,
    Alert: Alert
  },
  props: {
    projectId: {
      required: false,
      type: [String, Number],
      default: 1 // change after full implementation
    },

    treeData: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'structured tree data, !IMPORTANT: nodes should have children, if don`t -> children = [], needed for drag and drop'
    },
    disableItemMenu: {
      type: Boolean,
      default: false,
      required: false
    },
    enableAddButton: {
      type: Boolean,
      default: false,
      required: false
    },
    lockTree: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  emits: ['move'],
  data: function data() {
    return {
      localValue: _toConsumableArray(this.treeData),
      children: [],
      startDrag: false,
      makeClone: false,
      removeDrag: true,
      wholeData: [],
      movedData: [],
      clonePath: null,
      isEscape: false,
      showAlert: false,
      itemToMoveId: null,
      dragOptions: null,
      translate: getTranslate['RismaTree']()
    };
  },
  watch: {
    treeData: {
      deep: true,
      handler: function handler() {
        this.localValue = JSON.parse(JSON.stringify(this.treeData));
      }
    }
  },
  mounted: function mounted() {
    // @keydown && @keypress .esc is not working
    document.addEventListener('keydown', this.escapeFunction);
  },
  beforeUnmount: function beforeUnmount() {
    document.removeEventListener('keydown', this.escapeFunction);
  },
  methods: {
    handleEsc: function handleEsc() {
      if (!this.isEscape) {
        return;
      }
      this.localValue = JSON.parse(JSON.stringify(this.wholeData));
      this.resetMoveData();
      this.isEscape = false;
    },
    escapeFunction: function escapeFunction(event) {
      if (event.key === 'Escape' && this.wholeData.length && this.startDrag) {
        this.isEscape = true;
      }
    },
    checkMove: function checkMove(a) {
      if (!this.startDrag) {
        return false;
      }
      if (this.clonePath) {
        //if its clone`s child
        if (JSON.stringify(this.clonePath) === JSON.stringify(a.pathTo.slice(0, this.clonePath.length))) {
          return false;
        }

        // if its near clone
        if (this.comparePathes(this.clonePath, a.pathTo, 0, 1)) {
          return false;
        }
      }

      // create clone on first available cloning
      if (this.makeClone) {
        var path = this.getPathById(a.dragItem.id, this.localValue);
        if (this.comparePathes(path, a.pathTo, 0, 1)) {
          return false;
        }
        this.clonePath = path;
        this.createClone(a.dragItem, path[path.length - 1], path);
        this.makeClone = false;
      }
    },
    comparePathes: function comparePathes(pathA, pathB, min, max) {
      var cloneLastIndex = pathA[pathA.length - 1];
      var cloneSliced = pathA.slice(0, -1);
      var toLastIndex = pathB[pathB.length - 1];
      var toArraySliced = pathB.slice(0, -1);
      return JSON.stringify(cloneSliced) === JSON.stringify(toArraySliced) && toLastIndex - cloneLastIndex <= max && toLastIndex - cloneLastIndex >= min;
    },
    getPathById: function getPathById(id, items) {
      var _this = this;
      var path = [];
      items.every(function (item, i) {
        if (item.id === id) {
          path.push(i);
        } else if (item.children) {
          var childrenPath = _this.getPathById(id, item.children);
          if (childrenPath.length) {
            path = path.concat(i).concat(childrenPath);
          }
        }
        return path.length === 0;
      });
      return path;
    },
    onMove: function onMove(a) {
      if (!this.startDrag && !this.lockTree) {
        this.wholeData = JSON.parse(JSON.stringify(this.localValue));
        this.children = a.children;
        this.removeDrag = false;
        a.isGhost = true;
        a.children = [];
        this.makeClone = true;
      }
      this.startDrag = true;
    },
    createClone: function createClone(a, index, path) {
      var parent = this.getElementParentByPath(path);
      parent.children.splice(index + 1, 0, _objectSpread(_objectSpread({}, a), {}, {
        id: a.id + '_isClone',
        children: this.expandCloneChildren(this.children),
        isClone: true,
        isGhost: false
      }));
    },
    expandCloneChildren: function expandCloneChildren(list) {
      var _this2 = this;
      return list.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          id: item.id + '_isClone',
          children: _this2.expandCloneChildren(item.children),
          isClone: true,
          isGhost: false
        });
      });
    },
    removeClone: function removeClone(path, list) {
      var parent = this.getElementParentByPath(path, list);
      parent.children.splice(path[path.length - 1], 1);
    },
    onChangeData: function onChangeData(a, optionsDrag) {
      if (!this.startDrag) {
        return;
      }
      a.isGhost = false;
      a.children = this.children;
      if (!optionsDrag.pathTo) {
        this.resetMoveData();
        return;
      }
      this.movedData = JSON.parse(JSON.stringify(optionsDrag.items));
      this.itemToMoveId = a.id;
      this.dragOptions = optionsDrag;
      this.showAlert = true;
      this.localValue = _toConsumableArray(this.wholeData);
      this.resetMoveData();
    },
    onChangeConfirm: function onChangeConfirm() {
      var options = this.getRightOptions(this.dragOptions.pathTo);
      if (this.clonePath) {
        // if displaced by future place
        var lastIndex = this.dragOptions.pathTo[this.dragOptions.pathTo.length - 1];
        if (JSON.stringify(this.dragOptions.pathTo.slice(0, -1)) === JSON.stringify(this.clonePath.slice(0, this.dragOptions.pathTo.length - 1)) && lastIndex <= this.clonePath[this.dragOptions.pathTo.length - 1]) {
          this.clonePath[this.dragOptions.pathTo.length - 1]++;
        }
        this.removeClone(this.clonePath, this.movedData);
      }
      this.$emit('move', {
        child: this.itemToMoveId,
        options: options,
        newTreeData: this.movedData
      });
      this.showAlert = false;
    },
    getElementParentByPath: function getElementParentByPath(path) {
      var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.localValue;
      var value = {
        children: list
      };
      if (path.length === 1) {
        return value;
      }
      path.forEach(function (item, index) {
        if (index !== path.length - 1) {
          value = value.children[item];
        }
      });
      return value;
    },
    resetMoveData: function resetMoveData() {
      this.startDrag = false;
      this.removeDrag = true;
      this.children = [];
      this.wholeData = [];
      this.clonePath = null;
    },
    getRightOptions: function getRightOptions(path) {
      var parent = this.getElementParentByPath(path);
      var index = path[path.length - 1];
      var options = {};
      if (parent.children.length === 1) {
        options.appendTo = parent.id;
      } else if (index > 0) {
        options.insertAfter = parent.children[index - 1].id;
      } else {
        options.insertBefore = parent.children[index + 1].id;
      }
      return options;
    }
  }
};