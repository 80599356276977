var BASE_HEIGHT = 400;
var ITEM_HEIGHT = 30;
export var RismaHorisontalBarChartMixin = {
  methods: {
    prepareChartsData: function prepareChartsData(charts) {
      var _this = this;
      return charts.map(function (chart) {
        if (chart.data.length) {
          chart.data = chart.data.filter(function (item) {
            return item.value;
          });
        }
        if (!chart.data.length) return chart;
        var _this$getDataForBarPr = _this.getDataForBarProps(chart),
          labels = _this$getDataForBarPr.labels,
          datasets = _this$getDataForBarPr.datasets;
        chart._labels = labels;
        chart._datasets = datasets;
        return chart;
      });
    },
    getDataForBarProps: function getDataForBarProps(chart) {
      var _this2 = this;
      var labels = [];
      var backgroundColor = [];
      var data = [];
      chart.data.forEach(function (item) {
        labels.push(_this2.$trans(item.title));
        backgroundColor.push(item.color);
        data.push(item.value);
      });
      return {
        labels: labels,
        datasets: [{
          label: chart.title,
          backgroundColor: backgroundColor,
          data: data
        }]
      };
    },
    setHeight: function setHeight(datasets) {
      return datasets.length > 12 ? ITEM_HEIGHT * datasets.length : BASE_HEIGHT;
    }
  }
};