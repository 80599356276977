import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
import { flushCache } from '@/api/HttpCache';
function flushTagsCache() {
  flushCache('data/tags');
}
export function get() {
  return handleRequestResponse(Http.get('/data/tags'));
}
export function create(data) {
  flushTagsCache();
  return handleRequestResponse(Http.post('/data/tags', data));
}
export function update(id, data) {
  flushTagsCache();
  return handleRequestResponse(Http.patch('/data/tags/' + id, data));
}
export function remove(id) {
  flushTagsCache();
  return handleRequestResponse(Http.delete('/data/tags/' + id));
}