export var COMPANIES_TABLE_COLUMNS = {
  title: 'Name',
  address: 'Address',
  city: 'City',
  zipCode: 'Zipcode',
  constryCode: 'Country code',
  vatNo: 'VAT number',
  edit: ''
};
export var CreateCompanyModes = function CreateCompanyModes(trans) {
  return {
    create: {
      splitBtnLabel: trans('Create via VAT number'),
      manualBtnLable: trans('Create manually'),
      createCompanyPopup: {
        header: trans('Create company'),
        inputPlaceholder: trans('Company name'),
        okBtn: trans('Create')
      }
    },
    add: {
      splitBtnLabel: trans('Add via Vat number'),
      manualBtnLable: trans('Add manually'),
      createCompanyPopup: {
        header: trans('Add contract party'),
        inputPlaceholder: trans('Contract party name'),
        okBtn: trans('Add')
      }
    }
  };
};