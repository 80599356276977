import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "pb-4"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  class: "mb-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_radio_buttons = _resolveComponent("radio-buttons");
  var _component_input_field = _resolveComponent("input-field");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    type: "medium",
    title: $props.title
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$trans("Risma offers you the opportunity to measure impact of parameters other than direct financial impact. This could e.g. be \"image\" or \"personal safety\". Type in information in the text fields below if you wish to meassure the impact on such parameters.")), 1 /* TEXT */), _createVNode(_component_risma_label, {
    title: $data.translate.doYouWantToUseOtherParameters
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_radio_buttons, {
    modelValue: $data.selectedOption,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $data.selectedOption = $event;
    }), $options.changeInUsed],
    options: $options.options,
    disabled: $props.readOnly
  }, null, 8 /* PROPS */, ["modelValue", "options", "disabled", "onUpdate:modelValue"]), $data.selectedOption ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, [_createVNode(_component_input_field, {
    "model-value": $props.label,
    type: "text",
    class: "max-w-xl",
    enabled: !$props.readOnly,
    "onUpdate:modelValue": $options.changeLabel
  }, null, 8 /* PROPS */, ["model-value", "enabled", "onUpdate:modelValue"])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.labels, function (item, index) {
    return _openBlock(), _createElementBlock("p", {
      key: "label-".concat(index),
      class: "mb-4"
    }, [_createVNode(_component_input_field, {
      "model-value": item,
      type: "text",
      class: "max-w-xl",
      enabled: !$props.readOnly,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.changeLabels($event, index);
      }
    }, null, 8 /* PROPS */, ["model-value", "enabled", "onUpdate:modelValue"])]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)]);
}