import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.statusChangeNote,
    "overflow-visible": true,
    "button-ok-text": $data.translate.save,
    "button-dismiss-text": $data.translate.cancel,
    "ok-button-disabled": !$options.isValidNote,
    onAccept: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('accept', {
        workflowStatusNote: $data.workflowStatusNote,
        id: $props.workflowStatusId
      });
    }),
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_tiny_mce_box, {
        type: "mini",
        headline: $data.translate.stateTheReasonForStatusChange,
        "initial-content": $data.workflowStatusNote,
        "always-open": true,
        "debounce-timeout": 0,
        "focus-on-mount": true,
        "editor-wrapper-class": "mt-4",
        onChanged: _cache[0] || (_cache[0] = function ($event) {
          return $data.workflowStatusNote = $event;
        })
      }, null, 8 /* PROPS */, ["headline", "initial-content"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "ok-button-disabled"]);
}