import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-795b6a30"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["innerHTML"];
var _hoisted_2 = {
  class: "admin-period-main-wrap w-4/5"
};
var _hoisted_3 = {
  key: 1
};
var _hoisted_4 = {
  class: "mb-8 flex justify-between"
};
var _hoisted_5 = {
  class: "flex w-4/5 max-w-900px"
};
var _hoisted_6 = {
  class: "mb-8"
};
var _hoisted_7 = {
  class: "mb-8"
};
var _hoisted_8 = {
  class: "w-64"
};
var _hoisted_9 = {
  class: "mb-8"
};
var _hoisted_10 = {
  class: "w-64"
};
var _hoisted_11 = {
  key: 0,
  class: "text-right mb-2"
};
var _hoisted_12 = {
  class: "flex justify-end"
};
var _hoisted_13 = {
  key: 0
};
var _hoisted_14 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  var _component_level_of_threat = _resolveComponent("level-of-threat");
  var _component_input_field = _resolveComponent("input-field");
  var _component_consequence = _resolveComponent("consequence");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [$data.isNotificationShow ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: $data.notificationType,
    onDismiss: $options.handleDismissNotification
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.notificationMessage), 1 /* TEXT */), _createElementVNode("div", {
        innerHTML: $data.notificationDetails
      }, null, 8 /* PROPS */, _hoisted_1)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type", "onDismiss"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [!$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createElementBlock("section", _hoisted_3, [_createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
        ref: "detailTitle",
        title: $data.activity.title,
        editable: !$options.isPeriodClosed,
        onTitleChange: _cache[0] || (_cache[0] = function ($event) {
          return $options.updateProperty('title', $event);
        })
      }, null, 8 /* PROPS */, ["title", "editable"])]), !$options.isPeriodClosed ? (_openBlock(), _createBlock(_component_toggle_menu, {
        key: 0,
        options: $options.toggleMenuOptions
      }, null, 8 /* PROPS */, ["options"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_level_of_threat, {
        activity: $data.activity,
        readonly: $options.isPeriodClosed,
        "default-options": $data.defaultLevelOfThreatOptions,
        onChanged: $options.onLevelChanged
      }, null, 8 /* PROPS */, ["activity", "readonly", "default-options", "onChanged"])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_input_field, {
        modelValue: $data.activity.probabilityLabel,
        "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
          return $data.activity.probabilityLabel = $event;
        }), _cache[2] || (_cache[2] = function ($event) {
          return $options.updateProperty('probabilityLabel', $event);
        })],
        enabled: $options.isPeriodEditable,
        type: "text",
        maxlength: "50"
      }, null, 8 /* PROPS */, ["modelValue", "enabled"])]), _createVNode(_component_consequence, {
        class: "pt-8",
        "consequence-rows": $data.activity.probabilityRows || [],
        "consequence-levels": $data.activity.probabilityLevels || 0,
        readonly: !$options.isPeriodEditable,
        "identical-columns": $data.activity.onlyColumns,
        "row-key": "likelihoodColumns",
        onChanged: _cache[3] || (_cache[3] = function ($event) {
          return $options.onDataChanged($event, $data.PROBABILITY.LABELS_KEY);
        })
      }, null, 8 /* PROPS */, ["consequence-rows", "consequence-levels", "readonly", "identical-columns"])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createVNode(_component_input_field, {
        modelValue: $data.activity.consequenceLabel,
        "onUpdate:modelValue": [_cache[4] || (_cache[4] = function ($event) {
          return $data.activity.consequenceLabel = $event;
        }), _cache[5] || (_cache[5] = function ($event) {
          return $options.updateProperty('consequenceLabel', $event);
        })],
        enabled: $options.isPeriodEditable,
        type: "text",
        maxlength: "50"
      }, null, 8 /* PROPS */, ["modelValue", "enabled"])]), _createVNode(_component_consequence, {
        class: "pt-8",
        "consequence-rows": $data.activity.consequenceRows || [],
        "consequence-levels": $data.activity.consequenceLevels || 0,
        readonly: !$options.isPeriodEditable,
        "identical-columns": $data.activity.onlyColumns,
        onChanged: _cache[6] || (_cache[6] = function ($event) {
          return $options.onDataChanged($event, $data.CONSEQUENCE.LABELS_KEY);
        })
      }, null, 8 /* PROPS */, ["consequence-rows", "consequence-levels", "readonly", "identical-columns"])]), _createElementVNode("div", null, [$options.isDraft ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("label", null, [_createElementVNode("span", null, _toDisplayString($data.translate.openThisPeriodAndCloseThePrevious), 1 /* TEXT */), _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
          return $data.openPeriod = $event;
        }),
        type: "checkbox"
      }, null, 512 /* NEED_PATCH */), [[_vModelCheckbox, $data.openPeriod]])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_12, [_createVNode(_component_risma_button, {
        text: $data.translate.cancel,
        type: "util",
        onClick: $options.cancelAction
      }, null, 8 /* PROPS */, ["text", "onClick"]), $data.isNewMode && !$options.isPeriodClosed ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createVNode(_component_risma_button, {
        text: $options.savePeriodTitle,
        type: "save",
        onClick: $options.createNewActivity
      }, null, 8 /* PROPS */, ["text", "onClick"])])) : _createCommentVNode("v-if", true), !$data.isNewMode && !$options.isPeriodClosed ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_component_risma_button, {
        text: $options.savePeriodTitle,
        type: "save",
        disabled: $options.isPageDirty && $data.activity.isCurrent,
        onClick: $options.updateData
      }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])) : _createCommentVNode("v-if", true)])])];
    }),
    _: 1 /* STABLE */
  })])) : _createCommentVNode("v-if", true)])])]);
}