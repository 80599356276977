var _RowNumberByColor;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var COLORS = {
  GREEN_1: '#7DDE70',
  GREEN_2: '#61C256',
  GREEN_3: '#43A63C',
  GREEN_4: '#228B22',
  YELLOW_1: '#EFF25F',
  YELLOW_2: '#ECEC37',
  YELLOW_3: '#FFE141',
  YELLOW_4: '#FDDA25',
  ORANGE_1: '#ECB937',
  ORANGE_2: '#FD9125',
  ORANGE_3: '#FF8000',
  ORANGE_4: '#FF6B00',
  RED_1: '#E11717',
  RED_2: '#CE2D2D',
  RED_3: '#C11414',
  RED_4: '#A11111',
  BLUE_1: '#9EE3F9',
  BLUE_2: '#9ED3F9',
  BLUE_3: '#9EC2F9',
  BLUE_4: '#2D7EF6'
};
export var DefaultHeatMapColors = [COLORS.GREEN_4, COLORS.GREEN_3, COLORS.GREEN_2, COLORS.GREEN_1, COLORS.YELLOW_1, COLORS.YELLOW_2, COLORS.YELLOW_4, COLORS.ORANGE_2, COLORS.ORANGE_4, COLORS.RED_2, COLORS.RED_4];
export var ColorPickerColors = [[COLORS.GREEN_1, COLORS.YELLOW_1, COLORS.ORANGE_1, COLORS.RED_1, COLORS.BLUE_1], [COLORS.GREEN_2, COLORS.YELLOW_2, COLORS.ORANGE_2, COLORS.RED_2, COLORS.BLUE_2], [COLORS.GREEN_3, COLORS.YELLOW_3, COLORS.ORANGE_3, COLORS.RED_3, COLORS.BLUE_3], [COLORS.GREEN_4, COLORS.YELLOW_4, COLORS.ORANGE_4, COLORS.RED_4, COLORS.BLUE_4]];
export var RowNumberByColor = (_RowNumberByColor = {}, _defineProperty(_RowNumberByColor, COLORS.GREEN_1, '1'), _defineProperty(_RowNumberByColor, COLORS.GREEN_2, '2'), _defineProperty(_RowNumberByColor, COLORS.GREEN_3, '3'), _defineProperty(_RowNumberByColor, COLORS.GREEN_4, '4'), _defineProperty(_RowNumberByColor, COLORS.YELLOW_1, '1'), _defineProperty(_RowNumberByColor, COLORS.YELLOW_2, '2'), _defineProperty(_RowNumberByColor, COLORS.YELLOW_3, '3'), _defineProperty(_RowNumberByColor, COLORS.YELLOW_4, '4'), _defineProperty(_RowNumberByColor, COLORS.ORANGE_1, '1'), _defineProperty(_RowNumberByColor, COLORS.ORANGE_2, '2'), _defineProperty(_RowNumberByColor, COLORS.ORANGE_3, '3'), _defineProperty(_RowNumberByColor, COLORS.ORANGE_4, '4'), _defineProperty(_RowNumberByColor, COLORS.RED_1, '1'), _defineProperty(_RowNumberByColor, COLORS.RED_2, '2'), _defineProperty(_RowNumberByColor, COLORS.RED_3, '3'), _defineProperty(_RowNumberByColor, COLORS.RED_4, '4'), _defineProperty(_RowNumberByColor, COLORS.BLUE_1, '1'), _defineProperty(_RowNumberByColor, COLORS.BLUE_2, '2'), _defineProperty(_RowNumberByColor, COLORS.BLUE_3, '3'), _defineProperty(_RowNumberByColor, COLORS.BLUE_4, '4'), _RowNumberByColor);