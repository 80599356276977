import { dataURItoBlob } from '@/utils/blobUtils';
import TrafficLight from '@/components/Atoms/Trafficlight/Trafficlight';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'LinkItem',
  introduction: 'box for displaying links',
  description: "This component is used to display links of all sort.\n    The component can be infused with a displayed title, a link / url and a module that the link is a part of ie. file, compliance, risk, execution...",
  token: '<link-item title="this is a test link" link="https://google.dk">',
  components: {
    FeatherIcon: FeatherIcon,
    TrafficLight: TrafficLight
  },
  props: {
    link: {
      type: String,
      required: true,
      note: 'The url that should be used'
    },
    title: {
      type: String,
      required: true,
      note: 'the displayed text on the linkItem'
    },
    tooltip: {
      type: String,
      required: false,
      default: '',
      note: 'Tooltip if needed'
    },
    module: {
      type: String,
      default: 'file',
      note: 'Which module is the link from. Chooses the icon if asTag is false'
    },
    asTag: {
      type: Boolean,
      default: false,
      note: 'A little different UI (without icons)'
    },
    asRouterLink: {
      type: Boolean,
      default: false,
      note: 'A vue router-link in stead of a href'
    },
    remoteLink: {
      type: Boolean,
      default: false,
      note: 'Open link in new tab in the browser'
    },
    enabled: {
      type: Boolean,
      default: true,
      note: 'should linking be enabled?'
    },
    downloadFileName: {
      type: String,
      required: false,
      default: '',
      note: 'Name of the file downloaded by link'
    },
    hasTrafficLight: {
      required: false,
      type: Boolean,
      default: false,
      note: 'if link has traffic light ( not the module img )'
    },
    trafficLightColor: {
      required: false,
      type: String,
      default: '',
      note: 'traffic light color if needed'
    },
    hasCheckIcon: {
      required: false,
      type: Boolean,
      default: false,
      note: 'icon checked, especially for closed initiatives'
    }
  },
  computed: {
    cssClass: function cssClass() {
      return "icon icon-risma-".concat(this.module);
    },
    linkRel: function linkRel() {
      return this.remoteLink ? 'noopener noreferrer' : '';
    },
    linkTarget: function linkTarget() {
      return this.remoteLink ? '_blank' : '_self';
    },
    asLink: function asLink() {
      return !this.asRouterLink && this.enabled;
    }
  },
  methods: {
    onBoxClick: function onBoxClick() {
      if (this.asLink && this.$refs.clickableLink) {
        this.$refs.clickableLink.click();
      }
    },
    clickFileLink: function clickFileLink(e) {
      if (this.linkIsData(this.link) && window.navigator && window.navigator.msSaveOrOpenBlob) {
        e.preventDefault();
        var blob = dataURItoBlob(this.link);
        var fileName = this.downloadFileName;
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      }
    },
    linkIsData: function linkIsData(link) {
      return link.slice(0, 5, '') === 'data:';
    }
  }
};