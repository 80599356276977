import CreateInitiativeModal from '@/components/Organisms/CreateInitiativeModal';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { itemList as _users } from '@/test/data/user';
import { itemList as _initiatives } from '@/test/data/initiative';
export default {
  name: 'CreateInitiativeModalKitchen',
  components: {
    CreateInitiativeModal: CreateInitiativeModal,
    RismaButton: RismaButton
  },
  data: function data() {
    return {
      showCreateInitiative: false
    };
  },
  computed: {
    users: function users() {
      return _users;
    },
    initiatives: function initiatives() {
      return _initiatives;
    }
  },
  methods: {
    alert: function (_alert) {
      function alert(_x) {
        return _alert.apply(this, arguments);
      }
      alert.toString = function () {
        return _alert.toString();
      };
      return alert;
    }(function (event) {
      alert(JSON.stringify(event));
    })
  }
};