function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import i18n from '@/i18n';
import { Colors } from '@/Colors';
var GAPS_TRAFFIC_KEY = 'gapsTraffic';
export var customizeColumnsWithGapsFn = function customizeColumnsWithGapsFn(columns) {
  var gapsColumnIndex = columns.findIndex(function (item) {
    return item.key === 'gaps';
  });
  if (gapsColumnIndex === -1) return columns;
  var customizedColumns = _toConsumableArray(columns);
  customizedColumns.splice(gapsColumnIndex, 0, {
    key: GAPS_TRAFFIC_KEY,
    label: i18n.t('GAP traffic light')
  });
  return customizedColumns;
};
export var customizeDatasetWithGapsFn = function customizeDatasetWithGapsFn(gapArticleMap) {
  return function (dataset, columns) {
    var result = [];
    dataset.forEach(function (item) {
      if (columns.gaps && item.gaps && item.gaps.rawGaps && item.gaps.rawGaps.length) {
        item.gaps.rawGaps.forEach(function (gap) {
          var _objectSpread2;
          result.push(_objectSpread(_objectSpread({}, item), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, GAPS_TRAFFIC_KEY, {
            field: i18n.t(Colors.gaps[gap.analysis_rag].label)
          }), _defineProperty(_objectSpread2, "gaps", _objectSpread(_objectSpread({}, item.gaps), {}, {
            fieldText: gap.nodeTitle + ' - ' + gapArticleMap.get(gap.articleId).article
          })), _objectSpread2)));
        });
      } else {
        result.push(_objectSpread(_objectSpread({}, item), {}, _defineProperty({}, GAPS_TRAFFIC_KEY, {
          field: ''
        })));
      }
    });
    return result;
  };
};