function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
export default {
  name: 'ControlTraffic',
  introduction: 'Component for manipulations with traffic lights',
  description: 'Traffic light selector on Control DEP ',
  token: '<control-traffic :control="control" />',
  components: {
    RismaToggle: RismaToggle
  },
  props: {
    control: {
      type: Object,
      required: true,
      note: 'Main control data object.'
    },
    isDaughter: {
      type: Boolean,
      required: true,
      note: 'Is daughter control?'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is could be values changes'
    }
  },
  emits: ['changedCreateRemoteTickets'],
  data: function data() {
    return {
      trafficLightIntervalOptions: [],
      trafficLightIntervalValueSelected: '',
      translate: {
        createRemoteTickets: this.$trans('Create remote tickets')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    createRemoteTickets: function createRemoteTickets() {
      return !!this.control.createRemoteTickets;
    },
    showCreateRemoteTickets: function showCreateRemoteTickets() {
      return !!this.getSettingValue('feature.create_jira_issue_yellow_controls');
    },
    disableCreateRemoteTickets: function disableCreateRemoteTickets() {
      return this.isDaughter;
    }
  }),
  methods: {
    onCreateRemoteTicketsChange: function onCreateRemoteTicketsChange(data) {
      this.$emit('changedCreateRemoteTickets', data);
    }
  }
};