var _scoresNameByField, _columnsTypeByScoresN;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var RiskBaseModelProps = {
  THREAT_COMMENT: 'threatComment',
  THREAT_LEVEL_COMMENT: 'threatLevelComment',
  VULNERABILITY_COMMENT: 'vulnerabilityComment',
  PROBABILITY_COMMENT: 'probabilityComment',
  THREAT_VALUE: 'threatValue',
  THREAT_LEVEL_VALUE: 'threatLevelValue',
  VULNERABILITY_VALUE: 'vulnerabilityValue',
  THREAT_IDS: 'threatIds',
  VULNERABILITY_IDS: 'vulnerabilityIds',
  PROBABILITY_SCORE: 'probabilityScore',
  CONSEQUENCE_COMMENT: 'consequenceComment',
  INHERENT_CONSEQUENCE: 'inherentConsequence',
  INHERENT_PROBABILITY: 'inherentProbability',
  RESIDUAL_CONSEQUENCE: 'residualConsequence',
  RESIDUAL_PROBABILITY: 'residualProbability',
  INHERENT_CONSEQUENCE_ROW_SCORES: 'inherentConsequenceRowScores',
  INHERENT_PROBABILITY_ROW_SCORES: 'inherentProbabilityRowScores',
  RESIDUAL_CONSEQUENCE_ROW_SCORES: 'residualConsequenceRowScores',
  RESIDUAL_PROBABILITY_ROW_SCORES: 'residualProbabilityRowScores',
  ACCOUNTABLE_USER_IDS: 'accountableUserIds',
  CONSULTED_USER_IDS: 'consultedUserIds',
  INFORMED_USER_IDS: 'informedUserIds',
  RESPONSIBLE_USER_IDS: 'responsibleUserIds',
  ORGANISATION_IDS: 'organisationIds',
  TAGS: 'tags',
  APPROVAL_STATUS: 'approved',
  APPROVAL_DATE: 'approvedDate',
  APPROVED_BY_USER_ID: 'approvedByUserId',
  LIKELIHOOD_COMMENT: 'likelihood_comment',
  IMPACT_COMMENT: 'impact_comment',
  Z_AXIS_COMMENT: 'z_axis_comment',
  CUSTOM_IMPACT: 'customImpact',
  CUSTOM_LIKELIHOOD: 'customLikelihood',
  IMPACT_SCORE: 'impact_score',
  IMPACT_OTHER1_SCORE: 'impact_other1_score',
  IMPACT_OTHER2_SCORE: 'impact_other2_score',
  IMPACT_OTHER1_COMMENT: 'impact_other1_comment',
  IMPACT_OTHER2_COMMENT: 'impact_other2_comment',
  USER_IDS: 'userIds',
  RESPONSIBLE: 'responsible',
  ACCOUNTABLE: 'accountable',
  MANAGEMENT_LEVEL_ID: 'managementLevelId',
  STATUS: 'status',
  GROSS_LIKELIHOOD: 'grossLikelihood',
  TOLERANCE_LIKELIHOOD: 'toleranceLikelihood',
  GROSS_IMPACT: 'grossImpact',
  TOLERANCE_IMPACT: 'toleranceImpact',
  CALCULATION_COMMENT: 'calculationsComment',
  LIKELIHOOD_SCORE: 'likelihood_score',
  RISK_GAP: 'riskGap',
  RPI: 'rpi',
  CLOSED: 'closed',
  PREVIOUS_RISK: 'previousRisk',
  RISK_TYPE_ID: 'riskTypeId',
  GRID_STATUS: 'gridStatus',
  PARENT: 'parent'
};
export var scoresNameByField = (_scoresNameByField = {}, _defineProperty(_scoresNameByField, RiskBaseModelProps.INHERENT_CONSEQUENCE, RiskBaseModelProps.INHERENT_CONSEQUENCE_ROW_SCORES), _defineProperty(_scoresNameByField, RiskBaseModelProps.INHERENT_PROBABILITY, RiskBaseModelProps.INHERENT_PROBABILITY_ROW_SCORES), _defineProperty(_scoresNameByField, RiskBaseModelProps.RESIDUAL_CONSEQUENCE, RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES), _defineProperty(_scoresNameByField, RiskBaseModelProps.RESIDUAL_PROBABILITY, RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES), _scoresNameByField);
export var columnsTypeByScoresName = (_columnsTypeByScoresN = {}, _defineProperty(_columnsTypeByScoresN, RiskBaseModelProps.INHERENT_CONSEQUENCE_ROW_SCORES, 'impactColumns'), _defineProperty(_columnsTypeByScoresN, RiskBaseModelProps.INHERENT_PROBABILITY_ROW_SCORES, 'likelihoodColumns'), _defineProperty(_columnsTypeByScoresN, RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES, 'impactColumns'), _defineProperty(_columnsTypeByScoresN, RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES, 'likelihoodColumns'), _columnsTypeByScoresN);