import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-02f10635"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "mb-3"
};
var _hoisted_2 = {
  class: "mb-3"
};
var _hoisted_3 = {
  class: "mb-3"
};
var _hoisted_4 = {
  class: "mb-3"
};
var _hoisted_5 = {
  class: "mb-3"
};
var _hoisted_6 = {
  class: "mb-3"
};
var _hoisted_7 = {
  class: "mb-3"
};
var _hoisted_8 = {
  class: "mb-3"
};
var _hoisted_9 = {
  class: "max-w-500px"
};
var _hoisted_10 = {
  class: "mb-3 flex justify-between"
};
var _hoisted_11 = ["checked"];
var _hoisted_12 = {
  class: "mb-3 flex justify-between"
};
var _hoisted_13 = ["checked"];
var _hoisted_14 = {
  class: "mb-3 flex justify-between"
};
var _hoisted_15 = ["checked"];
var _hoisted_16 = {
  class: "flex justify-between"
};
var _hoisted_17 = ["checked"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_activity_type_selector = _resolveComponent("activity-type-selector");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.editProject,
    "overflow-visible": true,
    "dismiss-on-click-outside": false,
    "button-ok-text": $data.translate.save,
    "button-dismiss-text": $data.translate.cancel,
    type: "large",
    onAccept: $options.submitModal,
    onDismiss: _cache[11] || (_cache[11] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        modelValue: $props.project.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $props.project.title = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.title,
        class: _normalizeClass({
          'input-error': $data.errors.title
        }),
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "class"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
        modelValue: $props.project.questionnaireId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $props.project.questionnaireId = $event;
        }),
        title: $data.translate.questionnaire,
        options: $props.questionnaires,
        placeholder: $data.translate.selectOne
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
        modelValue: $props.project.gapSchemaId,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $props.project.gapSchemaId = $event;
        }),
        title: $data.translate.gapSchemaName,
        options: $props.gapSchemas,
        placeholder: $data.translate.selectOne
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_label, {
        title: $data.translate.solutionId
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
        options: $props.solutions,
        "selected-options": $options.selectedSolutions,
        "un-sorted": true,
        placeholder: $data.translate.select,
        "label-key": "title",
        class: "min-w-300px",
        "onUpdate:modelValue": $options.setSolutionId
      }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_label, {
        title: $data.translate.activityType
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_activity_type_selector, {
        modelValue: $props.project.activityTypeId,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $props.project.activityTypeId = $event;
        }),
        "activity-types": $props.activityTypes
      }, null, 8 /* PROPS */, ["modelValue", "activity-types"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_input_field, {
        modelValue: $props.project.descriptionLabel,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $props.project.descriptionLabel = $event;
        }),
        title: $data.translate.renameTitleOverallDescription,
        placeholder: $data.translate.renameTitleOverallDescription,
        class: _normalizeClass({
          'input-error': $data.errors.descriptionLabel
        }),
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "class"])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_input_field, {
        modelValue: $props.project.purposeLabel,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
          return $props.project.purposeLabel = $event;
        }),
        title: $data.translate.renameTitlePurpose,
        placeholder: $data.translate.renameTitlePurpose,
        class: _normalizeClass({
          'input-error': $data.errors.purposeLabel
        }),
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "class"])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_input_field, {
        modelValue: $props.project.statusLabel,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
          return $props.project.statusLabel = $event;
        }),
        title: $data.translate.renameTitleProcessStatus,
        placeholder: $data.translate.renameTitleProcessStatus,
        class: _normalizeClass({
          'input-error': $data.errors.statusLabel
        }),
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "class"])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createVNode(_component_risma_label, {
        title: $data.translate.enableRecommendedActivitiesForGaps
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("input", {
        type: "checkbox",
        checked: !!$props.project.recommendedQuestions,
        onChange: _cache[7] || (_cache[7] = function ($event) {
          return $options.handleCheckBoxChange($event.target.checked, 'recommendedQuestions');
        })
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_11)]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_risma_label, {
        title: $data.translate.enableHeatMapsInGapSchema
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("input", {
        type: "checkbox",
        checked: !!$props.project.gapHeatmaps,
        onChange: _cache[8] || (_cache[8] = function ($event) {
          return $options.handleCheckBoxChange($event.target.checked, 'gapHeatmaps');
        })
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_13)]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_risma_label, {
        title: $data.translate.enableGapTypes
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("input", {
        type: "checkbox",
        checked: !!$props.project.showGapArticleTypes,
        onChange: _cache[9] || (_cache[9] = function ($event) {
          return $options.handleCheckBoxChange($event.target.checked, 'showGapArticleTypes');
        })
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_15)]), _createElementVNode("div", _hoisted_16, [_createVNode(_component_risma_label, {
        title: $data.translate.riskPrGap
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("input", {
        type: "checkbox",
        checked: !!$props.project.enableGapRiskModule,
        onChange: _cache[10] || (_cache[10] = function ($event) {
          return $options.handleCheckBoxChange($event.target.checked, 'enableGapRiskModule');
        })
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_17)])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}