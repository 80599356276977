import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_contact_card = _resolveComponent("contact-card");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_contact_card, {
    info: $data.contact,
    type: "systems"
  }, null, 8 /* PROPS */, ["info"]), _createVNode(_component_contact_card, {
    info: $data.contact,
    type: "processors"
  }, null, 8 /* PROPS */, ["info"]), _createVNode(_component_contact_card, {
    info: $data.contact,
    type: "controllers"
  }, null, 8 /* PROPS */, ["info"])]);
}