import { saveStatus as SaveStatuses } from '@/constants/SaveStatuses';
import * as OnScroll from '@/utils/applyOnScrollStyles';
export default {
  name: 'SaveButton',
  description: 'Save button which represents current save status. Subscribed to scroll event',
  token: '<save-button save-status="Saved"  @saveStatusClicked="fn" />',
  props: {
    saveStatus: {
      type: String,
      required: true,
      note: 'Current save status'
    },
    wrapperClass: {
      type: String,
      required: false,
      default: 'wrapper',
      note: 'Classname of wrapper'
    }
  },
  emits: ['saveStatusClicked'],
  data: function data() {
    return {
      onScrollFn: null,
      SaveStatuses: SaveStatuses
    };
  },
  computed: {
    isActiveClass: function isActiveClass() {
      return this.saveStatus !== SaveStatuses.SAVED;
    },
    autoSaveClass: function autoSaveClass() {
      return {
        'cursor-pointer active:border-gray-510': this.isActiveClass,
        'absolute top-0 right-16': true,
        'font-semibold text-lg text-center leading-5': true,
        'rounded-full focus:outline-none border-2 border-transparent': true,
        'cursor-default min-w-110px inline-block py-2 px-4 bg-gray-250 z-1': true
      };
    }
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      this.applyOnScroll();
    });
  },
  unmounted: function unmounted() {
    this.onScrollFn && OnScroll.unsubscribe(this.onScrollFn);
  },
  methods: {
    applyOnScroll: function applyOnScroll() {
      var wrapperEl = document.getElementsByClassName(this.wrapperClass)[0];
      this.onScrollFn = OnScroll.prepareOnScrollFunction([this.$el, wrapperEl], 55);
      OnScroll.subscribe(this.onScrollFn);
      this.onScrollFn();
    }
  }
};